import BaseModel from "./base/baseModel";
import DadosAdicionaisFichaTecnicaModel from "./dadosAdicionaisFichaTecnicaModel";

export default class DadosAdicionaisFichaTecnicaResultadoModel extends BaseModel {
    id?: number = null;
    fichaTecnicaId?: number = null;
    dadosAdicionaisId?: number = null;
    dadosAdicionais?: DadosAdicionaisFichaTecnicaModel = null;
    validado = false;
    observacao: string = null;
}
