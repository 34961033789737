import Vue from "vue";
import { mapGetters } from "vuex";

import checkboxComponent from "@/components/child/form/checkbox.vue";
import comboComponent from "@/components/child/form/combo.vue";
import dataTooltipComponent from "@/components/child/form/datatooltip.vue";
import moedaComponent from "@/components/child/form/moeda.vue";
import { Component, Watch } from "@/decorators";
import ConfiguracaoFranquiaModel from "@/models/configuracaoFranquia/configuracaoFranquiaModel";
import Configuracoes from "@/models/enum/configuracao/configuracoes";
import Especie from "@/models/enum/especiePagamento";
import ContaCorrenteModel from "@/models/financeiro/contaCorrenteModel";
import FormaRecebimentoModel from "@/models/financeiro/formaRecebimentoModel";
import PaginationModel from "@/models/paginationModel";
import PdvModel from "@/models/pdv/pdvModel";
import SessaoCaixaModel from "@/models/pdv/sessaoCaixaModel";
import ContaCorrenteService from "@/services/financeiro/contaCorrenteService";
import FormaRecebimentoService from "@/services/financeiro/formaRecebimentoService";
import PdvService from "@/services/pdv/pdvService";
import SessaoCaixaService from "@/services/pdv/sessaoCaixaService";
import { Getters, SessionActions } from "@/store/store";
import Bus from "@/utils/events/bus";

import "../crud/crud.scss";

@Component({
    components: {
        checkboxComponent,
        dataTooltipComponent,
        moedaComponent,
        comboComponent,
    },
    computed: {
        ...mapGetters(["GET_CONFIG_FRANQUIA"] as Getters),
    },
})
export default class SessaoCaixaEditComponent extends Vue {
    // State computed props
    GET_CONFIG_FRANQUIA: (configuracao: Configuracoes) => ConfiguracaoFranquiaModel;

    private service = new SessaoCaixaService();

    model: SessaoCaixaModel = new SessaoCaixaModel();
    modelId = 0;
    saldoSugestao = 0;

    constructor() {
        super();

        this.model.gerarDiferencaSaldo = true;
        this.model.saldoInicial = 0;
    }

    pdvOptions: Array<Object> = [];

    private async loadPdvs() {
        try {
            const data = await new PdvService().combo().then(r => r.json() as Promise<PaginationModel<PdvModel>>);
            this.pdvOptions = data.list.map(item => ({
                value: item.id,
                text: item.descricao,
                saldoAtual: item.saldoAtual,
                contaCorrenteId: item.contaCorrenteId,
            }));
            if (this.pdvOptions.length == 1) {
                this.model.pdvId = this.pdvOptions[0]["value"];
            }
        } catch {}
    }

    contaCorrenteOptions: Array<Object> = [];
    contaCorrenteOptionsSemFiltro: Array<Object> = [];

    private async loadContasCorrentes() {
        try {
            const data = await new ContaCorrenteService()
                .combo()
                .then(r => r.json() as Promise<PaginationModel<ContaCorrenteModel>>);

            this.contaCorrenteOptionsSemFiltro = data.list.map(item => ({
                value: item.id,
                text: item.descricao,
                interna: item.interna,
            }));

            this.filtraConta();
        } catch {}
    }

    private filtraConta() {
        if (this.model.pdvId <= 0) {
            this.contaCorrenteOptions = this.contaCorrenteOptionsSemFiltro;
        } else {
            const contaPdv = this.pdvOptions.filter(p => p["value"] == this.model.pdvId)[0]["contaCorrenteId"];
            this.contaCorrenteOptions = this.contaCorrenteOptionsSemFiltro.filter(p => p["value"] != contaPdv);
        }
    }

    formaRecebimentoOptionsSemFiltro: Array<Object> = [];
    formaRecebimentoOptions: Array<Object> = [];

    private async loadFormasRecebimento() {
        try {
            const data = await new FormaRecebimentoService()
                .comboByEspecie(Especie.Transferencia)
                .resolveWithJSON<PaginationModel<FormaRecebimentoModel>>();
            this.formaRecebimentoOptionsSemFiltro = data.list.map(item => ({
                value: item.id,
                text: item.descricao,
                especie: item.especie,
                juros: item.juros,
                desconto: item.desconto,
                bandeira: item.bandeiraId,
                metodosXForma: item.metodoPagamentoXForma,
                contaCorrenteOrigemId: item.contaCorrenteOrigemId,
            }));
            this.filtraFormaRecebimento();
        } catch {}
    }

    private filtraFormaRecebimento() {
        let contaPdv = 0;
        if (this.model.pdvId >= 0) {
            contaPdv = this.pdvOptions.filter(p => p["value"] == this.model.pdvId)[0]["contaCorrenteId"];
        }

        if (this.model.saldoInicial - this.saldoSugestao > 0 && contaPdv > 0) {
            this.formaRecebimentoOptions = this.formaRecebimentoOptionsSemFiltro.filter(
                p => p["contaCorrenteOrigemId"] != contaPdv,
            );
        } else if (this.model.saldoInicial - this.saldoSugestao < 0 && contaPdv > 0) {
            this.formaRecebimentoOptions = this.formaRecebimentoOptionsSemFiltro.filter(
                p => p["contaCorrenteOrigemId"] == contaPdv,
            );
        } else {
            this.formaRecebimentoOptions = this.formaRecebimentoOptionsSemFiltro;
        }
    }

    private async load() {
        this.model = new SessaoCaixaModel();

        if (this.modelId > 0) {
            try {
                const data = await this.service
                    .get(this.modelId)
                    .withLoading()
                    .then(r => r.json() as Promise<SessaoCaixaModel>);

                this.model.updateFrom(data);
            } catch {
                this.$router.back();
            }
        }
    }

    private async save() {
        try {
            const isValid = await this.$validator.validateAll();
            if (isValid) {
                //se diferenca for maior q zero eh reforco, se menor eh sangria .
                this.model.valorDiferenca = this.model.saldoInicial - this.saldoSugestao;

                const sucesso = await this.service.insert(this.model).withLoading().resolveWithoutJSON();

                if (sucesso) {
                    await this.$showSuccess("Abertura de Caixa!", "Abertura de Caixa registrada com sucesso.");

                    Bus.$emit("sessao-caixa-status", true);
                    this.$router.back();
                }
            }
        } catch {}
    }

    private cancel() {
        this.$router.back();
    }

    // @ts-ignore
    @Watch("model.pdvId")
    private onPdvChanged(newValue: number) {
        const pdv = this.pdvOptions.find(p => p["value"] == newValue);
        if (pdv) {
            this.saldoSugestao = pdv["saldoAtual"];
            this.model.saldoInicial = parseFloat(this.saldoSugestao.toFixed(2));
            this.model.contaCorrenteDestinoId = pdv["contaCorrenteId"];
        }

        this.filtraConta();
    }

    // @ts-ignore
    @Watch("model.saldoInicial")
    private onSaldoInicialChanged() {
        this.filtraFormaRecebimento();
    }

    private async mounted() {
        await this.$store.dispatch(SessionActions.LOAD_USUARIO_LOGADO);

        const controlarSessaoCaixaPor = this.GET_CONFIG_FRANQUIA(Configuracoes.ControlarSessaoCaixaPor).valor;
        if (!controlarSessaoCaixaPor) {
            this.$showError(
                "Erro",
                "Para abrir o caixa é necessário configurar o controle de sessão de caixa na configuração da franquia!",
            );

            return this.$router.back();
        }

        return Promise.all([this.loadPdvs(), this.loadContasCorrentes(), this.loadFormasRecebimento()])
            .withLoading()
            .then(() => {})
            .catch(() => {});
    }
}
