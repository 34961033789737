import Vue from "vue";

import Grid from "@/components/child/grid/grid";
import gridComponent from "@/components/child/grid/grid.vue";
import { GridColumn, GridColumnType } from "@/components/child/grid/gridColumn";
import { Component, Watch } from "@/decorators";
import MoldeModel from "@/models/moldeModel";
import PaginationModel from "@/models/paginationModel";
import MoldeService from "@/services/moldeService";

import "../crud.scss";

@Component({
    components: {
        gridComponent,
    },
})
export default class MoldeListComponent extends Vue {
    private service = new MoldeService();
    private gridcomponent: Grid = null;

    gridFilterKey = "";
    gridSortKey = "descricao";
    gridSortOrder = "asc";
    gridData: Array<MoldeModel> = [];
    get gridColumns(): Array<GridColumn> {
        return [
            new GridColumn("descricao", "Descrição", GridColumnType.String),
            new GridColumn("volumeInterno", "Volume", GridColumnType.Decimal),
            new GridColumn("ativo", "Ativo", GridColumnType.Boolean),
        ];
    }

    pageIndex = 1;
    pageSize = 20;
    total = 0;

    pags = false;
    tipoDocGerar: number = null;

    private async load() {
        try {
            const data = await this.service
                .list(
                    this.gridFilterKey,
                    this.gridSortKey,
                    this.gridSortOrder,
                    this.pags ? 1 : this.pageIndex,
                    this.pags ? 999999 : this.pageSize,
                )
                .withLoading()
                .resolveWithJSON<PaginationModel<MoldeModel>>();

            this.gridData = data.list;
            this.total = data.total;

            if (this.pags) {
                if (this.tipoDocGerar == 0) {
                    setTimeout(() => {
                        this.gridcomponent.gerarXls();
                    }, 2000);
                }

                if (this.tipoDocGerar == 1) {
                    setTimeout(() => {
                        this.gridcomponent.gerarPdf();
                    }, 2000);
                }

                if (this.tipoDocGerar == 2) {
                    setTimeout(() => {
                        this.gridcomponent.gerarImpressao();
                    }, 2000);
                }

                setTimeout(() => {
                    this.pags = false;
                }, 2000);
            }
        } catch {}
    }

    private onEditItem(model: MoldeModel) {
        this.$router.push("/molde-edicao/" + model.id);
    }

    private async onRemoveItem(model: MoldeModel) {
        const response = await this.$showExclusaoQuestion(`${this.$t("__.ts.desejaExcl")}${model.descricao}?`);

        if (response) {
            try {
                const sucesso = await this.service.delete(model.id).withLoading().resolveWithoutJSON();
                if (sucesso) {
                    this.load();
                    this.$showExclusao();
                }
            } catch {}
        }
    }

    private onCreateItem() {
        this.$router.push("/molde-edicao");
    }

    private onChangeFilterKey(filterKey: string) {
        this.gridFilterKey = filterKey;
        this.load();
    }

    private onChangeSort(sortKey: string, sortOrder: string) {
        this.gridSortKey = sortKey;
        this.gridSortOrder = sortOrder;
        this.load();
    }

    private onChangePage(pageIndex: number) {
        this.pageIndex = pageIndex;
        this.load();
    }

    @Watch("pags")
    private onPags() {
        this.load();
    }

    private mounted() {
        this.gridcomponent = this.$refs.gridcomponent as Grid;
        this.load();
    }
}
