import comboComponent from "@/components/child/form/combo.vue";
import dateTimePickerComponent from "@/components/child/form/datetimepicker.vue";
import { GridColumn, GridColumnType } from "@/components/child/grid/gridColumn";
import { Component, Watch, Prop } from "@/decorators";
import StatusContas from "@/models/enum/statusContas";
import ContaGerencialModel from "@/models/financeiro/contaGerencialModel";
import ContaReceberModel, { ContaReceberListParameters } from "@/models/financeiro/contaReceberModel";
import PaginationModel from "@/models/paginationModel";
import ContaGerencialService from "@/services/financeiro/contaGerencialService";
import ContaReceberService from "@/services/financeiro/contaReceberService";
import { convertToDateInput } from "@/utils/common/date";

import ListComponentBase from "../listComponentBase";

import "./list.scss";

@Component({
    components: {
        comboComponent,
        dateTimePickerComponent,
    },
})
export default class ContaReceberListComponent extends ListComponentBase<ContaReceberModel> {
    @Prop({ type: String, default: "" }) FilterKeyId: string;
    @Prop({ type: Number, default: StatusContas.Aberto }) FilterStatusConta: StatusContas;
    @Prop({ type: Boolean, default: false }) HideExtras: boolean;

    gridFilterStatusConta = StatusContas.Aberto;
    gridFilterKeyId = "";
    gridSelectedValues: Array<ContaReceberModel> = [];

    get gridColumns(): Array<GridColumn> {
        return [
            new GridColumn("dataVencimento", "Vencimento", GridColumnType.Date, false, false, "", "", true),
            new GridColumn("descricao", "Descri\u00e7\u00e3o", GridColumnType.String, false, false, "", "", true),
            new GridColumn("operadoraDescricao", "Operadora", GridColumnType.String, false, false, "", "", true),
            new GridColumn("clienteDescricao", "Cliente", GridColumnType.String, false, false, "", "", true),
            new GridColumn("autorizacao", "Autoriza\u00e7\u00e3o", GridColumnType.String, false, false, "", "", true),
            new GridColumn("valorTotal", "Valor Original", GridColumnType.Money, false, true, "", "", true),
        ];
    }

    subDataName = "lancamentosVinculados";

    get gridColumnsVinculos(): Array<GridColumn> {
        return [
            new GridColumn("dataVencimento", "Vencimento", GridColumnType.Date),
            new GridColumn("descricao", "Descri\u00e7\u00e3o", GridColumnType.String),
            new GridColumn("valorTotal", "Valor", GridColumnType.Money),
            new GridColumn("saldo", "Saldo", GridColumnType.Money),
        ];
    }

    isHidden = false;

    contaGerencialOptions: Array<Object> = [];
    filtro: Array<Object> = [];
    contasFiltro?: number = null;

    dataFilter1: Date = null;
    dataFilter2: Date = null;

    conditionShowSubData(it) {
        return it["lancamentosVinculados"]["length"] > 0;
    }

    private beforeMount() {
        this.setProps(new ContaReceberService(), "contareceber", "dataVencimento");

        this.loadContasGerencial();
    }

    public async loadMethod() {
        this.isHidden = false;
        this.gridFilterKeyId = this.FilterKeyId;
        this.gridFilterStatusConta = this.FilterStatusConta;

        const customParameters: ContaReceberListParameters = {
            statusContas: this.gridFilterStatusConta,
            filterKeyId: this.gridFilterKeyId,
            dataVencimentoIniStr: this.filtro[0] ? this.filtro[0].toString() : "",
            dataVencimentoFinStr: this.filtro[1] ? this.filtro[1].toString() : "",
            contaCorrenteId: null,
            contaGerencialId: this.filtro[2] ? <number>this.filtro[2] : null,
            onlyClientes: false,
            onlyOperadoras: true,
        };

        return await this.service
            .list(
                this.gridFilterKey,
                this.gridSortKey,
                this.gridSortOrder,
                this.pags ? 1 : this.pageIndex,
                this.pags ? 999999 : this.pageSize,
                customParameters,
            )
            .withLoading()
            .resolveWithJSON<PaginationModel<ContaReceberModel>>();
    }

    public async afterLoad() {
        this.gridData["dataDe"] = this.filtro[0] ? this.filtro[0].toString() : " ";
        this.gridData["dataAte"] = this.filtro[1] ? this.filtro[1].toString() : " ";

        if (this.filtro[2]) {
            this.gridData["caixa"] = this.gridData[0]["tipoConta"];
        }
    }

    private receberSelecionados() {
        if (this.gridSelectedValues.length == 1) {
            this.$router.push("/contareceber-edicao/" + this.gridSelectedValues[0].id);
        } else {
            this.$router.push("/contareceber-multiplas-edicao/" + this.gridSelectedValues.map(p => p.id));
        }
    }

    private onSelectedValuesChanged(values: Array<ContaReceberModel>) {
        this.gridSelectedValues = values;
    }

    private getFormValues(listaFiltro) {
        this.filtro = [];

        this.filtro.push(listaFiltro.target.elements.dataFilter1.value);

        this.filtro.push(listaFiltro.target.elements.dataFilter2.value);

        this.filtro.push(this.contasFiltro);

        this.load();
    }

    private async loadContasGerencial() {
        try {
            const data = await new ContaGerencialService()
                .combo()
                .resolveWithJSON<PaginationModel<ContaGerencialModel>>();
            this.contaGerencialOptions = data.list.map(item => ({ value: item.id, text: item.descricao }));
        } catch {}
    }

    @Watch("FilterKeyId")
    private onFilterChange() {
        this.load();
    }

    public async afterLoadPagination() {
        if (this.paginationParams.filtros != null) {
            this.filtro = this.paginationParams.filtros;

            if (this.filtro.length >= 0) {
                if (this.filtro[0] != null && this.filtro[0] != "") {
                    this.dataFilter1 = convertToDateInput(this.filtro[0].toString());
                }
                if (this.filtro[1] != null && this.filtro[1] != "") {
                    this.dataFilter2 = convertToDateInput(this.filtro[1].toString());
                }
                if (this.filtro[2] != null && <number>this.filtro[2] > 0) {
                    this.contasFiltro = <number>this.filtro[2];
                }
            }
        }
    }
}
