var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "shortcutComponent",
    {
      ref: "shortcutComponent",
      attrs: { btOkName: "Ok", modalClass: "bg-modal", showAddNew: false },
      on: { "selection-item": _vm.onClose },
    },
    [
      _c(
        "div",
        { attrs: { slot: "component" }, slot: "component" },
        [
          _c(
            "div",
            { staticClass: "crud crud-list" },
            [
              _c("grid-component", {
                ref: "gridcomponent",
                attrs: {
                  actionName: "vendas",
                  columns: _vm.gridColumns,
                  data: _vm.gridData,
                  extraActions: _vm.extraActions,
                  "page-index": _vm.pageIndex,
                  "page-size": _vm.pageSize,
                  "show-select-checkbox": false,
                  showEditItem: false,
                  showRemoveItem: false,
                  showAddNewButton: false,
                  showSearchField: false,
                  total: _vm.total,
                },
                on: {
                  "change-filter-key": _vm.onChangeFilterKey,
                  "change-page": _vm.onChangePage,
                  "change-sort": _vm.onChangeSort,
                  "create-item": _vm.onCreateItem,
                  "extra-action": _vm.onExtraAction,
                  "gerar-impressao": function ($event) {
                    _vm.pags = true
                    _vm.tipoDocGerar = 2
                  },
                  "gerar-pdf": function ($event) {
                    _vm.pags = true
                    _vm.tipoDocGerar = 1
                  },
                  "gerar-xls": function ($event) {
                    _vm.pags = true
                    _vm.tipoDocGerar = 0
                  },
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("tentativasEntregaComponent", {
            ref: "tentativasEntregaComponent",
            attrs: { somenteConsulta: true },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }