var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "col-sm-12" },
    [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-sm-8" }, [
          _c(
            "div",
            {
              directives: [
                {
                  name: "tooltip",
                  rawName: "v-tooltip",
                  value: _vm.errors.first("produtoId"),
                  expression: "errors.first('produtoId')",
                },
              ],
              staticClass: "form-group",
            },
            [
              _c(
                "label",
                {
                  staticClass: "control-label",
                  attrs: { for: "produtoId", title: _vm.$t("__.obrigatorio") },
                },
                [
                  _c("span", { staticStyle: { color: "red" } }, [_vm._v("*")]),
                  _vm._v(
                    "\n                    " +
                      _vm._s(
                        _vm.$t(
                          "__.Components.parent.venda.item_vue_html.produto"
                        )
                      ) +
                      "\n                "
                  ),
                ]
              ),
              _vm._v(" "),
              _c("combo-component", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required",
                    expression: "'required'",
                  },
                ],
                ref: "produtoId",
                attrs: {
                  id: "produtoId",
                  name: "produtoId",
                  data: _vm.produtosOptions,
                  refresh: _vm.loadProdutos,
                  customView: true,
                },
                on: { select: _vm.onProdutoIdChanged },
                model: {
                  value: _vm.model.produtoId,
                  callback: function ($$v) {
                    _vm.$set(_vm.model, "produtoId", $$v)
                  },
                  expression: "model.produtoId",
                },
              }),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-sm-4" }, [
          _c(
            "div",
            {
              directives: [
                {
                  name: "tooltip",
                  rawName: "v-tooltip",
                  value: _vm.errors.first("produtoLoteId"),
                  expression: "errors.first('produtoLoteId')",
                },
              ],
              staticClass: "form-group",
            },
            [
              _c(
                "label",
                {
                  staticClass: "control-label",
                  attrs: {
                    for: "produtoLoteId",
                    title: _vm.$t("__.obrigatorio"),
                  },
                },
                [
                  _vm.isObrigatorioProdutoLote
                    ? _c("span", { staticStyle: { color: "red" } }, [
                        _vm._v("*"),
                      ])
                    : _vm._e(),
                  _vm._v(
                    "\n                    " +
                      _vm._s(
                        _vm.$t(
                          "__.Components.parent.venda.item_vue_html.produtoLote"
                        )
                      ) +
                      "\n                "
                  ),
                ]
              ),
              _vm._v(" "),
              _c("combo-component", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: {
                      required: _vm.isObrigatorioProdutoLote,
                    },
                    expression:
                      "{\n                        required: isObrigatorioProdutoLote,\n                    }",
                  },
                ],
                attrs: {
                  id: "produtoLoteId",
                  tabindex: _vm.tabIndex(_vm.model.produtoLoteId),
                  name: "produtoLoteId",
                  data: _vm.produtosLoteOptions,
                },
                model: {
                  value: _vm.model.produtoLoteId,
                  callback: function ($$v) {
                    _vm.$set(_vm.model, "produtoLoteId", $$v)
                  },
                  expression: "model.produtoLoteId",
                },
              }),
            ],
            1
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-sm-2" }, [
          _c(
            "div",
            {
              directives: [
                {
                  name: "tooltip",
                  rawName: "v-tooltip",
                  value: _vm.errors.first("quantidade"),
                  expression: "errors.first('quantidade')",
                },
              ],
              staticClass: "form-group",
            },
            [
              _c(
                "label",
                { staticClass: "control-label", attrs: { for: "quantidade" } },
                [
                  _vm._v(
                    "\n                    " +
                      _vm._s(
                        _vm.$t("__.Components.parent.venda.item_vue_html.qtde")
                      ) +
                      "\n                "
                  ),
                ]
              ),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.model.quantidade,
                    expression: "model.quantidade",
                  },
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "maior_que_zero",
                    expression: "'maior_que_zero'",
                  },
                ],
                ref: "quantidade",
                staticClass: "form-control",
                attrs: { type: "text", id: "quantidade", name: "quantidade" },
                domProps: { value: _vm.model.quantidade },
                on: {
                  change: _vm.calcularValorTotal,
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.model, "quantidade", $event.target.value)
                  },
                },
              }),
            ]
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-sm-2" }, [
          _c(
            "div",
            {
              directives: [
                {
                  name: "tooltip",
                  rawName: "v-tooltip",
                  value: _vm.errors.first("valorUnitario"),
                  expression: "errors.first('valorUnitario')",
                },
              ],
              staticClass: "form-group",
            },
            [
              _c(
                "label",
                {
                  staticClass: "control-label",
                  attrs: {
                    for: "valorUnitario",
                    title: _vm.$t("__.obrigatorio"),
                  },
                },
                [
                  _c("span", { staticStyle: { color: "red" } }, [_vm._v("*")]),
                  _vm._v(
                    "\n                    " +
                      _vm._s(
                        _vm.$t(
                          "__.Components.parent.venda.item_vue_html.vlrUnit"
                        )
                      ) +
                      "\n                "
                  ),
                ]
              ),
              _vm._v(" "),
              _c("moeda-component", {
                directives: [
                  {
                    name: "mask-moeda-decimal",
                    rawName: "v-mask-moeda-decimal",
                  },
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required",
                    expression: "'required'",
                  },
                ],
                staticClass: "form-control",
                attrs: { id: "valorUnitario", name: "valorUnitario" },
                on: { change: _vm.calcularValorTotal },
                model: {
                  value: _vm.model.valorUnitario,
                  callback: function ($$v) {
                    _vm.$set(_vm.model, "valorUnitario", $$v)
                  },
                  expression: "model.valorUnitario",
                },
              }),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-sm-2" }, [
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c(
                "label",
                { staticClass: "control-label", attrs: { for: "markupVenda" } },
                [
                  _vm._v(
                    "\n                    " +
                      _vm._s(
                        _vm.$t(
                          "__.Components.parent.venda.item_vue_html.markupProduto"
                        )
                      ) +
                      "\n                "
                  ),
                ]
              ),
              _vm._v(" "),
              _c("decimal-com-sinal-custom-component", {
                staticClass: "form-control",
                attrs: {
                  id: "markupVenda",
                  name: "markupVenda",
                  signal: "%",
                  disabled: _vm.vendaFaturada,
                },
                on: { change: _vm.calcularValorTotal },
                model: {
                  value: _vm.markup,
                  callback: function ($$v) {
                    _vm.markup = $$v
                  },
                  expression: "markup",
                },
              }),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-sm-2" }, [
          _c(
            "div",
            {
              directives: [
                {
                  name: "tooltip",
                  rawName: "v-tooltip",
                  value: _vm.errors.first("acrescimo"),
                  expression: "errors.first('acrescimo')",
                },
              ],
              staticClass: "form-group",
            },
            [
              _c(
                "label",
                {
                  staticClass: "control-label",
                  attrs: { for: "acrescimo", title: _vm.$t("__.obrigatorio") },
                },
                [
                  _c("span", { staticStyle: { color: "red" } }, [_vm._v("*")]),
                  _vm._v(
                    "\n                    " +
                      _vm._s(
                        _vm.$t(
                          "__.Components.parent.venda.item_vue_html.acrescimo"
                        )
                      ) +
                      "\n                "
                  ),
                ]
              ),
              _vm._v(" "),
              _c("moeda-component", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required",
                    expression: "'required'",
                  },
                ],
                staticClass: "form-control",
                attrs: {
                  id: "acrescimo",
                  name: "acrescimo",
                  disabled: _vm.vendaFaturada,
                },
                on: { change: _vm.calcularValorTotal },
                model: {
                  value: _vm.model.acrescimo,
                  callback: function ($$v) {
                    _vm.$set(_vm.model, "acrescimo", $$v)
                  },
                  expression: "model.acrescimo",
                },
              }),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-sm-2" }, [
          _c(
            "div",
            {
              directives: [
                {
                  name: "tooltip",
                  rawName: "v-tooltip",
                  value: _vm.errors.first("desconto"),
                  expression: "errors.first('desconto')",
                },
              ],
              staticClass: "form-group",
            },
            [
              _c(
                "label",
                {
                  staticClass: "control-label",
                  attrs: { for: "desconto", title: _vm.$t("__.obrigatorio") },
                },
                [
                  _c("span", { staticStyle: { color: "red" } }, [_vm._v("*")]),
                  _vm._v(
                    "\n                    " +
                      _vm._s(
                        _vm.$t(
                          "__.Components.parent.venda.item_vue_html.desconto"
                        )
                      ) +
                      "\n                "
                  ),
                ]
              ),
              _vm._v(" "),
              _c("moeda-component", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required",
                    expression: "'required'",
                  },
                ],
                staticClass: "form-control",
                attrs: {
                  id: "desconto",
                  name: "desconto",
                  disabled: _vm.vendaFaturada,
                },
                on: { change: _vm.calcularValorTotal },
                model: {
                  value: _vm.model.desconto,
                  callback: function ($$v) {
                    _vm.$set(_vm.model, "desconto", $$v)
                  },
                  expression: "model.desconto",
                },
              }),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-sm-2" }, [
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c(
                "label",
                { staticClass: "control-label", attrs: { for: "valorTotal" } },
                [
                  _vm._v(
                    "\n                    " +
                      _vm._s(
                        _vm.$t(
                          "__.Components.parent.venda.item_vue_html.vlrTotal"
                        )
                      ) +
                      "\n                "
                  ),
                ]
              ),
              _vm._v(" "),
              _c("moeda-component", {
                directives: [
                  {
                    name: "mask-moeda-decimal",
                    rawName: "v-mask-moeda-decimal",
                  },
                ],
                staticClass: "form-control",
                attrs: { disabled: "", id: "valorTotal", name: "valorTotal" },
                model: {
                  value: _vm.model.valorTotal,
                  callback: function ($$v) {
                    _vm.$set(_vm.model, "valorTotal", $$v)
                  },
                  expression: "model.valorTotal",
                },
              }),
            ],
            1
          ),
        ]),
      ]),
      _vm._v(" "),
      !_vm.isFracionamento
        ? _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-sm-4" }, [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "tooltip",
                      rawName: "v-tooltip",
                      value: _vm.errors.first("posologiaId"),
                      expression: "errors.first('posologiaId')",
                    },
                  ],
                  staticClass: "form-group",
                },
                [
                  _c(
                    "label",
                    {
                      staticClass: "control-label",
                      attrs: { for: "posologiaId" },
                    },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(
                            _vm.$t(
                              "__.Components.parent.venda.item_vue_html.posologia"
                            )
                          ) +
                          "\n                "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  !_vm.isPosologiaTextoLivre || _vm.model.posologiaId
                    ? _c(
                        "combo-component",
                        {
                          attrs: {
                            id: "posologiaId",
                            name: "posologiaId",
                            actionName: "posologias",
                            addEditId: _vm.model.posologiaId,
                            addNew: !_vm.model.posologiaId,
                            customView: true,
                            data: _vm.posologiaOptions,
                            formAddNewName:
                              _vm.model.posologiaId > 0
                                ? _vm.$t(
                                    "__.Components.parent.venda.item_vue_html.editPosologia"
                                  )
                                : _vm.$t(
                                    "__.Components.parent.venda.item_vue_html.novaPosologia"
                                  ),
                            modalClass: "lm-modal",
                            refresh: _vm.loadPosologia,
                            tabindex: _vm.tabIndex(_vm.model.posologiaId),
                            useCloseModalCallback: true,
                          },
                          on: {
                            addNewItemCombo: _vm.onAddNewPosologia,
                            openComboEditItem: _vm.openComboEditPosologia,
                            openComboNewItem: _vm.openComboNewPosologia,
                          },
                          model: {
                            value: _vm.model.posologiaId,
                            callback: function ($$v) {
                              _vm.$set(_vm.model, "posologiaId", $$v)
                            },
                            expression: "model.posologiaId",
                          },
                        },
                        [
                          _c(
                            "div",
                            { attrs: { slot: "component" }, slot: "component" },
                            [
                              _c("posologia-component", {
                                ref: "posologiaComponent",
                                attrs: {
                                  CalledByShortCut: true,
                                  isAcabado: true,
                                },
                                on: { "save-ok": _vm.onPosologiaSaveOk },
                              }),
                            ],
                            1
                          ),
                        ]
                      )
                    : _c("textarea", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.model.posologiaTexto,
                            expression: "model.posologiaTexto",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          id: "posologiaTexto",
                          name: "posologiaTexto",
                          maxlength: "256",
                          rows: "3",
                        },
                        domProps: { value: _vm.model.posologiaTexto },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.model,
                              "posologiaTexto",
                              $event.target.value
                            )
                          },
                        },
                      }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value:
                      _vm.pacienteIdApp == null ||
                      _vm.pacienteIdApp == 0 ||
                      _vm.pacienteDescricao == null ||
                      _vm.pacienteDescricao == "",
                    expression:
                      "\n                pacienteIdApp == null || pacienteIdApp == 0 || pacienteDescricao == null || pacienteDescricao == ''\n            ",
                  },
                ],
                staticClass: "col-sm-4",
              },
              [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip",
                        value: _vm.errors.first("pacienteId"),
                        expression: "errors.first('pacienteId')",
                      },
                    ],
                    staticClass: "form-group",
                  },
                  [
                    _c(
                      "label",
                      {
                        staticClass: "control-label",
                        attrs: { for: "pacienteId" },
                      },
                      [
                        _vm._v(
                          "\n                    " +
                            _vm._s(
                              _vm.$t(
                                "__.Components.parent.venda.item_vue_html.paciente"
                              )
                            ) +
                            "\n                "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "search-combo-component",
                      {
                        attrs: {
                          id: "pacienteId",
                          name: "pacienteId",
                          actionName: "pacientes",
                          addEditId: _vm.model.pacienteId,
                          addNew: !_vm.model.pacienteId,
                          advancedSearch: true,
                          advancedSearchCampos: [
                            { campo: "text", descricao: "Nome do paciente" },
                            {
                              campo: "clienteDescricao",
                              descricao: "Nome do cliente",
                            },
                            {
                              campo: "especieAnimalDescricao",
                              descricao: "Espécie",
                            },
                          ],
                          customComboText: _vm.customComboTextPaciente,
                          customView: true,
                          formAddNewName:
                            _vm.model.pacienteId > 0
                              ? _vm.$t(
                                  "__.Components.parent.venda.item_vue_html.editPaciente"
                                )
                              : _vm.$t(
                                  "__.Components.parent.venda.item_vue_html.novoPaciente"
                                ),
                          modalClass: "lm-modal",
                          propDescription: "nome",
                          service: "Paciente",
                          tabindex: _vm.tabIndex(_vm.model.pacienteId),
                          useCloseModalCallback: true,
                        },
                        on: {
                          addNewItemCombo: _vm.onAddNewPaciente,
                          openComboEditItem: _vm.openComboEditPaciente,
                          openComboNewItem: _vm.openComboNewPaciente,
                        },
                        model: {
                          value: _vm.model.pacienteId,
                          callback: function ($$v) {
                            _vm.$set(_vm.model, "pacienteId", $$v)
                          },
                          expression: "model.pacienteId",
                        },
                      },
                      [
                        _vm.showPaciente
                          ? _c(
                              "div",
                              {
                                attrs: { slot: "component" },
                                slot: "component",
                              },
                              [
                                _c("paciente-component", {
                                  ref: "pacienteComponent",
                                  attrs: { CalledByShortCut: true },
                                  on: { "save-ok": _vm.onPacienteSaveOk },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    ),
                  ],
                  1
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value:
                      _vm.pacienteIdApp > 0 &&
                      _vm.pacienteDescricao != null &&
                      _vm.pacienteDescricao != "",
                    expression:
                      "pacienteIdApp > 0 && pacienteDescricao != null && pacienteDescricao != ''",
                  },
                ],
                staticClass: "col-sm-4",
              },
              [
                _c("div", { staticClass: "form-group" }, [
                  _c(
                    "label",
                    {
                      staticClass: "control-label",
                      attrs: { for: "pacienteDescricao" },
                    },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(
                            _vm.$t(
                              "__.Components.parent.venda.item_vue_html.paciente"
                            )
                          ) +
                          "\n                "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.pacienteDescricao,
                        expression: "pacienteDescricao",
                      },
                    ],
                    ref: "pacienteDescricao",
                    staticClass: "form-control",
                    attrs: {
                      type: "text",
                      readonly: "",
                      id: "pacienteDescricao",
                      name: "pacienteDescricao",
                    },
                    domProps: { value: _vm.pacienteDescricao },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.pacienteDescricao = $event.target.value
                      },
                    },
                  }),
                ]),
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "col-sm-4" }, [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "tooltip",
                      rawName: "v-tooltip",
                      value: _vm.errors.first("prescritorId"),
                      expression: "errors.first('prescritorId')",
                    },
                  ],
                  staticClass: "form-group",
                },
                [
                  _c(
                    "label",
                    {
                      staticClass: "control-label",
                      attrs: {
                        for: "prescritorId",
                        title: _vm.$t("__.obrigatorio"),
                      },
                    },
                    [
                      _c("span", { staticStyle: { color: "red" } }, [
                        _vm._v("*"),
                      ]),
                      _vm._v(
                        "\n                    " +
                          _vm._s(
                            _vm.$t(
                              "__.Components.parent.venda.item_vue_html.prescritor"
                            )
                          ) +
                          "\n                "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "search-combo-component",
                    {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required",
                          expression: "'required'",
                        },
                      ],
                      attrs: {
                        id: "prescritorId",
                        name: "prescritorId",
                        actionName: "prescritores",
                        addEditId: _vm.model.prescritorId,
                        addNew: !_vm.model.prescritorId,
                        advancedSearch: true,
                        advancedSearchCampos: [
                          { campo: "text", descricao: "Nome" },
                          {
                            campo: "numeroRegistro",
                            descricao: "Número de registro",
                          },
                          {
                            campo: "tipoConselhoRegionalDescricao",
                            descricao: "Conselho Regional",
                          },
                          { campo: "cpf", descricao: "CNPJ/CPF" },
                        ],
                        customComboText: _vm.customComboTextPrescritor,
                        customView: true,
                        formAddNewName:
                          _vm.model.prescritorId > 0
                            ? _vm.$t(
                                "__.Components.parent.venda.item_vue_html.editPrescritor"
                              )
                            : _vm.$t(
                                "__.Components.parent.venda.item_vue_html.novoPrescritor"
                              ),
                        propDescription: "nome",
                        service: "Prescritor",
                        useCloseModalCallback: true,
                      },
                      on: {
                        addNewItemCombo: _vm.onAddNewPrescritor,
                        openComboEditItem: _vm.openComboEditPrescritor,
                        openComboNewItem: _vm.openComboNewPrescritor,
                      },
                      model: {
                        value: _vm.model.prescritorId,
                        callback: function ($$v) {
                          _vm.$set(_vm.model, "prescritorId", $$v)
                        },
                        expression: "model.prescritorId",
                      },
                    },
                    [
                      _vm.showPrescritor
                        ? _c(
                            "div",
                            { attrs: { slot: "component" }, slot: "component" },
                            [
                              _c("prescritor-component", {
                                ref: "prescritorComponent",
                                attrs: { CalledByShortCut: true },
                                on: { "save-ok": _vm.onPrescritorSaveOk },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  ),
                ],
                1
              ),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.model.produtoLoteId
        ? _c(
            "fieldset-component",
            {
              attrs: {
                title: _vm.$t(
                  "__.Components.parent.venda.vendaEstoque_vue_html.infoLote"
                ),
                collapsed: false,
              },
            },
            [
              _c("div", { attrs: { slot: "rows" }, slot: "rows" }, [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-sm-3" }, [
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c("label", { staticClass: "control-label" }, [
                          _vm._v(
                            "\n                            " +
                              _vm._s(
                                _vm.$t(
                                  "__.Components.parent.venda.vendaEstoque_vue_html.dataVal"
                                )
                              ) +
                              "\n                        "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("date-time-picker-component", {
                          attrs: { name: "dataValidade", disabled: "" },
                          model: {
                            value: _vm.produtoLoteModel.dataValidade,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.produtoLoteModel,
                                "dataValidade",
                                $$v
                              )
                            },
                            expression: "produtoLoteModel.dataValidade",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-2" }, [
                    _c("div", { staticClass: "form-group" }, [
                      _c(
                        "label",
                        {
                          staticClass: "control-label",
                          attrs: { for: "quantidadeAtual" },
                        },
                        [
                          _vm._v(
                            "\n                            " +
                              _vm._s(
                                _vm.$t(
                                  "__.Components.parent.venda.vendaEstoque_vue_html.qtdEstoque"
                                )
                              ) +
                              "\n                        "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.quantidadeEstoqueLoteString,
                            expression: "quantidadeEstoqueLoteString",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          id: "quantidadeAtual",
                          name: "quantidadeAtual",
                          autocomplete: "off",
                          type: "text",
                          disabled: "",
                        },
                        domProps: { value: _vm.quantidadeEstoqueLoteString },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.quantidadeEstoqueLoteString =
                              $event.target.value
                          },
                        },
                      }),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-2" }, [
                    _c("div", { staticClass: "form-group" }, [
                      _c(
                        "label",
                        {
                          staticClass: "control-label",
                          attrs: { for: "quantidadeComprometidaLote" },
                        },
                        [
                          _vm._v(
                            "\n                            " +
                              _vm._s(
                                _vm.$t(
                                  "__.Components.parent.venda.vendaEstoque_vue_html.quantidadeComprometidaLote"
                                )
                              ) +
                              "\n                        "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.quantidadeComprometidaLoteString,
                            expression: "quantidadeComprometidaLoteString",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          id: "quantidadeComprometidaLote",
                          name: "quantidadeComprometidaLote",
                          autocomplete: "off",
                          type: "text",
                          disabled: "",
                        },
                        domProps: {
                          value: _vm.quantidadeComprometidaLoteString,
                        },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.quantidadeComprometidaLoteString =
                              $event.target.value
                          },
                        },
                      }),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-2" }, [
                    _c("div", { staticClass: "form-group" }, [
                      _c(
                        "label",
                        {
                          staticClass: "control-label",
                          attrs: { for: "quantidadeDisponivel" },
                        },
                        [
                          _vm._v(
                            "\n                            " +
                              _vm._s(
                                _vm.$t(
                                  "__.Components.parent.venda.vendaEstoque_vue_html.quantidadeDisponivel"
                                )
                              ) +
                              "\n                        "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.quantidadeDisponivelString,
                            expression: "quantidadeDisponivelString",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          id: "quantidadeDisponivel",
                          name: "quantidadeDisponivel",
                          autocomplete: "off",
                          type: "text",
                          disabled: "",
                        },
                        domProps: { value: _vm.quantidadeDisponivelString },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.quantidadeDisponivelString = $event.target.value
                          },
                        },
                      }),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-3" }, [
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c("label", { staticClass: "control-label" }, [
                          _vm._v(
                            "\n                            " +
                              _vm._s(
                                _vm.$t(
                                  "__.Components.parent.venda.vendaEstoque_vue_html.valorCusto"
                                )
                              ) +
                              "\n                        "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("moeda-component", {
                          staticClass: "form-control",
                          attrs: { name: "valorUnitario", disabled: "" },
                          model: {
                            value: _vm.produtoLoteModel.valorCusto,
                            callback: function ($$v) {
                              _vm.$set(_vm.produtoLoteModel, "valorCusto", $$v)
                            },
                            expression: "produtoLoteModel.valorCusto",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.isFracionamento
        ? _c(
            "fieldset-component",
            {
              attrs: {
                title: _vm.$t(
                  "__.Components.parent.venda.itemwrapper_vue_html.continuos"
                ),
                collapsed: true,
              },
            },
            [
              _c("div", { attrs: { slot: "rows" }, slot: "rows" }, [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-sm-2" }, [
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "continuo" },
                          },
                          [
                            _vm._v(
                              "\n                            " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Components.parent.venda.editSingle_vue_html.continuo"
                                  )
                                ) +
                                "\n                        "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("checkbox-component", {
                          attrs: { id: "continuo", name: "continuo" },
                          model: {
                            value: _vm.continuo,
                            callback: function ($$v) {
                              _vm.continuo = $$v
                            },
                            expression: "continuo",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-2" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("dataVencimentoContinuo"),
                            expression:
                              "errors.first('dataVencimentoContinuo')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "dataVencimentoContinuo" },
                          },
                          [
                            _vm.continuo
                              ? _c("span", { staticStyle: { color: "red" } }, [
                                  _vm._v("*"),
                                ])
                              : _vm._e(),
                            _vm._v(
                              "\n                            " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Components.parent.venda.editSingle_vue_html.dtVencimentoContinuo"
                                  )
                                ) +
                                "\n                        "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("date-time-picker-component", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: {
                                rules: { required: _vm.continuo },
                              },
                              expression:
                                "{\n                                rules: { required: continuo },\n                            }",
                            },
                          ],
                          attrs: {
                            id: "dataVencimentoContinuo",
                            name: "dataVencimentoContinuo",
                          },
                          model: {
                            value: _vm.dataVencimentoContinuo,
                            callback: function ($$v) {
                              _vm.dataVencimentoContinuo = $$v
                            },
                            expression: "dataVencimentoContinuo",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }