import { formatNumber } from "../common/number";

const FilterNumber = {
    install(Vue) {
        Vue.filter("filterNumber", function (value) {
            return formatNumber(+value);
        });
    },
};

export default FilterNumber;
