var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "col-sm-12" }, [
      _c("div", { staticClass: "form-group buttonIncluir-container" }, [
        _vm.cancelar
          ? _c(
              "button",
              {
                staticClass: "btn btn-secondary buttonIncluir-button",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    _vm.onCancelar()
                  },
                },
              },
              [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.$t("__.Crud.cancelar")) +
                    "\n            "
                ),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.withAll
          ? _c(
              "button",
              {
                staticClass: "btn btn-secondary buttonIncluir-button",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    _vm.onClickAll()
                  },
                },
              },
              [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.$t("__.Crud.adicionarTodos")) +
                    "\n            "
                ),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn btn-secondary buttonIncluir-button",
            attrs: { type: "button" },
            on: {
              click: function ($event) {
                _vm.onClick()
              },
            },
          },
          [
            _vm._v(
              "\n                " +
                _vm._s(
                  _vm.alterar
                    ? _vm.$t("__.Crud.alterar")
                    : _vm.$t("__.Crud.incluir")
                ) +
                "\n            "
            ),
          ]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }