var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "crud crud-edit" },
    [
      _c("actionBarComponent", {
        attrs: {
          title: _vm.$t(
            "__.Components.parent.manipulacao.cancelamento_vue_html.manipuOrdemCancelamento"
          ),
          showSave: !_vm.isCancelado && _vm.manipulacaoModel.destino != 0,
        },
      }),
      _vm._v(" "),
      _c(
        "form",
        {
          attrs: { novalidate: "novalidate" },
          on: {
            submit: function ($event) {
              $event.preventDefault()
              _vm.save()
            },
          },
        },
        [
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isCancelado,
                    expression: "isCancelado",
                  },
                ],
                staticClass: "col-sm-12",
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "alert alert-danger",
                    attrs: { role: "alert" },
                  },
                  [
                    _vm._v(
                      "\n                    " +
                        _vm._s(
                          _vm.$t(
                            "__.Components.parent.manipulacao.cancelamento_vue_html.manipuCanceladaNaoPossiv"
                          )
                        ) +
                        "\n                "
                    ),
                  ]
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.manipulacaoModel.destino == 0,
                    expression: "manipulacaoModel.destino == 0",
                  },
                ],
                staticClass: "col-sm-12",
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "alert alert-danger",
                    attrs: { role: "alert" },
                  },
                  [
                    _vm._v(
                      "\n                    " +
                        _vm._s(
                          _vm.$t(
                            "__.Components.parent.manipulacao.cancelamento_vue_html.deveCancelarPelaTelaCancelamento"
                          )
                        ) +
                        "\n                "
                    ),
                  ]
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-sm-12" },
              [
                _c(
                  "fieldset-component",
                  {
                    attrs: {
                      title: _vm.$t(
                        "__.Components.parent.manipulacao.cancelamento_vue_html.dadosManipu"
                      ),
                      collapsed: false,
                    },
                  },
                  [
                    _c("div", { attrs: { slot: "rows" }, slot: "rows" }, [
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-sm-3" }, [
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "tooltip",
                                  rawName: "v-tooltip",
                                  value: _vm.errors.first("dataCancelamento"),
                                  expression:
                                    "errors.first('dataCancelamento')",
                                },
                              ],
                              staticClass: "form-group",
                            },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "control-label",
                                  attrs: { for: "dataCancelamento" },
                                },
                                [
                                  _vm._v(
                                    "\n                                        " +
                                      _vm._s(
                                        _vm.$t(
                                          "__.Components.parent.manipulacao.cancelamento_vue_html.dtCancelamento"
                                        )
                                      ) +
                                      "\n                                    "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("date-time-picker-component", {
                                attrs: {
                                  id: "dataCancelamento",
                                  name: "dataCancelamento",
                                  disabled: "",
                                },
                                model: {
                                  value: _vm.cancelamentoModel.dataCancelamento,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.cancelamentoModel,
                                      "dataCancelamento",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "cancelamentoModel.dataCancelamento",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-sm-4" }, [
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "tooltip",
                                  rawName: "v-tooltip",
                                  value: _vm.errors.first(
                                    "usuarioCancelamento"
                                  ),
                                  expression:
                                    "errors.first('usuarioCancelamento')",
                                },
                              ],
                              staticClass: "form-group",
                            },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "control-label",
                                  attrs: { for: "usuarioCancelamento" },
                                },
                                [
                                  _vm._v(
                                    "\n                                        " +
                                      _vm._s(
                                        _vm.$t(
                                          "__.Components.parent.manipulacao.cancelamento_vue_html.userCancelamento"
                                        )
                                      ) +
                                      "\n                                    "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value:
                                      _vm.cancelamentoModel
                                        .usuarioCancelamentoNome,
                                    expression:
                                      "cancelamentoModel.usuarioCancelamentoNome",
                                  },
                                ],
                                staticClass: "form-control",
                                attrs: {
                                  autocomplete: "off",
                                  type: "text",
                                  id: "usuarioCancelamento",
                                  name: "usuarioCancelamento",
                                  disabled: "",
                                },
                                domProps: {
                                  value:
                                    _vm.cancelamentoModel
                                      .usuarioCancelamentoNome,
                                },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.cancelamentoModel,
                                      "usuarioCancelamentoNome",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                            ]
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-sm-5" }, [
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "tooltip",
                                  rawName: "v-tooltip",
                                  value: _vm.errors.first(
                                    "motivoCancelamentoId"
                                  ),
                                  expression:
                                    "errors.first('motivoCancelamentoId')",
                                },
                              ],
                              staticClass: "form-group",
                            },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "control-label",
                                  attrs: {
                                    for: "motivoCancelamentoId",
                                    title: _vm.$t("__.obrigatorio"),
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    { staticStyle: { color: "red" } },
                                    [_vm._v("*")]
                                  ),
                                  _vm._v(
                                    "\n                                        " +
                                      _vm._s(
                                        _vm.$t(
                                          "__.Components.parent.manipulacao.cancelamento_vue_html.motivoCancelamento"
                                        )
                                      ) +
                                      "\n                                    "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("combo-component", {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: "required",
                                    expression: "'required'",
                                  },
                                ],
                                attrs: {
                                  id: "motivoCancelamentoId",
                                  name: "motivoCancelamentoId",
                                  data: _vm.motivoCancelamentoOptions,
                                  refresh: _vm.loadMotivoCancelamento,
                                },
                                model: {
                                  value:
                                    _vm.cancelamentoModel.motivoCancelamentoId,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.cancelamentoModel,
                                      "motivoCancelamentoId",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "cancelamentoModel.motivoCancelamentoId",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-sm-12" }, [
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "tooltip",
                                  rawName: "v-tooltip",
                                  value: _vm.errors.first(
                                    "observacaoCancelamento"
                                  ),
                                  expression:
                                    "errors.first('observacaoCancelamento')",
                                },
                              ],
                              staticClass: "form-group",
                            },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "control-label",
                                  attrs: {
                                    for: "observacaoCancelamento",
                                    title: _vm.$t("__.obrigatorio"),
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    { staticStyle: { color: "red" } },
                                    [_vm._v("*")]
                                  ),
                                  _vm._v(
                                    "\n                                        " +
                                      _vm._s(
                                        _vm.$t(
                                          "__.Components.parent.manipulacao.cancelamento_vue_html.obsCancelamento"
                                        )
                                      ) +
                                      "\n                                    "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value:
                                      _vm.cancelamentoModel
                                        .observacaoCancelamento,
                                    expression:
                                      "cancelamentoModel.observacaoCancelamento",
                                  },
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: "required|max:1024",
                                    expression: "'required|max:1024'",
                                  },
                                ],
                                staticClass: "form-control",
                                attrs: {
                                  autocomplete: "off",
                                  type: "text",
                                  id: "observacaoCancelamento",
                                  name: "observacaoCancelamento",
                                  disabled: _vm.isCancelado,
                                },
                                domProps: {
                                  value:
                                    _vm.cancelamentoModel
                                      .observacaoCancelamento,
                                },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.cancelamentoModel,
                                      "observacaoCancelamento",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                            ]
                          ),
                        ]),
                      ]),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "fieldset-component",
                  {
                    attrs: { title: "Itens da Manipulação", collapsed: false },
                  },
                  [
                    _c("div", { attrs: { slot: "rows" }, slot: "rows" }, [
                      _vm.manipulacaoModel.destino == 1
                        ? _c(
                            "div",
                            {
                              staticClass: "alert alert-info",
                              attrs: { role: "alert" },
                            },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(
                                    _vm.$t(
                                      "__.Components.parent.manipulacao.cancelamento_vue_html.reverteLoteCriado"
                                    )
                                  ) +
                                  "\n                        "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c("div", { staticClass: "row" }, [
                        _c("br"),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-sm-6" }, [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "__.Components.parent.manipulacao.cancelamento_vue_html.item"
                                )
                              )
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-sm-2" }, [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "__.Components.parent.manipulacao.cancelamento_vue_html.qtde"
                                )
                              )
                            ),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: this.temMovimentoEstoque,
                              expression: "this.temMovimentoEstoque",
                            },
                          ],
                        },
                        _vm._l(_vm.cancelamentoModel.itens, function (item) {
                          return _c("cancelamento-item-component", {
                            key: item.manipulacaoItemId,
                            attrs: { item: item },
                          })
                        })
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: !this.temMovimentoEstoque,
                              expression: "!this.temMovimentoEstoque",
                            },
                          ],
                        },
                        [
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col-sm-12" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "alert alert-info",
                                  attrs: { role: "alert" },
                                },
                                [
                                  _vm._v(
                                    "\n                                        " +
                                      _vm._s(
                                        _vm.$t(
                                          "__.Components.parent.manipulacao.cancelamento_vue_html.semMovimentoManipulacao"
                                        )
                                      ) +
                                      "\n                                    "
                                  ),
                                ]
                              ),
                            ]),
                          ]),
                        ]
                      ),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c("fieldset", [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-sm-12" }, [
                      _c("div", { staticClass: "form-group newbtn" }, [
                        !_vm.isCancelado && _vm.manipulacaoModel.destino != 0
                          ? _c(
                              "button",
                              {
                                staticClass: "btn btn-success",
                                attrs: { type: "submit" },
                              },
                              [
                                _vm._v(
                                  "\n                                    " +
                                    _vm._s(_vm.$t("__.Crud.salvar")) +
                                    "\n                                "
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-secondary",
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                _vm.load()
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                                    " +
                                _vm._s(_vm.$t("__.Crud.limpar")) +
                                "\n                                "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-secondary",
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                _vm.cancel()
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                                    " +
                                _vm._s(_vm.$t("__.Crud.voltar")) +
                                "\n                                "
                            ),
                          ]
                        ),
                      ]),
                    ]),
                  ]),
                ]),
              ],
              1
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }