import Vue from "vue";
import { mapState, mapMutations, mapGetters } from "vuex";

import ImpressaoComponent from "@/components/child/impressao/impressaoComponent";
import impressaoComponent from "@/components/child/impressao/impressaoComponent.vue";
import EtiquetasListComponent from "@/components/parent/crud/etiquetas/list";
import etiquetasListComponent from "@/components/parent/crud/etiquetas/list.vue";
import ListEntregaComponent from "@/components/parent/expedicao/listEntrega";
import listEntregaComponent from "@/components/parent/expedicao/listEntrega.vue";
import TentativasEntregaComponent from "@/components/parent/expedicao/tentativasEntrega";
import tentativasEntregaComponent from "@/components/parent/expedicao/tentativasEntrega.vue";
import ShortcutComponent from "@/components/parent/shortcut/shortcut";
import shortcutComponent from "@/components/parent/shortcut/shortcut.vue";
import { Component } from "@/decorators";
import ModeloEspecifico from "@/models/enum/modeloEspecifico";
import PaginationModel from "@/models/paginationModel";
import VendaModel, { VendaListParameters } from "@/models/vendaModel";
import CorreiosService from "@/services/correiosService";
import VendaService from "@/services/vendaService";
import { FastActionLastPack, FastActionList, FastActionPack } from "@/store/fastActionStore";
import { AppState, Getters, Mutations } from "@/store/store";
import { convertToDateInputString, convertToDateWithTimeInputString } from "@/utils/common/date";

import { getDateHourString } from "../fastactionUtils";

import "./../fastactionsgrid.scss";

@Component({
    components: {
        tentativasEntregaComponent,
        listEntregaComponent,
        etiquetasListComponent,
        impressaoComponent,
        shortcutComponent,
    },
    computed: {
        ...mapState({
            fastActionLists: (state: AppState) => state.fastAction.fastActionLists,
            fastActionPack: (state: AppState) => state.fastAction.fastActionPack,
        }),
        ...mapGetters(["VALIDAR_PERMISSAO_USUARIO"] as Getters),
    },
    methods: {
        ...mapMutations(["SET_LISTSPACK", "SET_LASTCOUNTPACK"] as Mutations),
        getDateHourString: getDateHourString,
    },
})
export default class FastActionEntregasComponent extends Vue {
    // State computed props
    fastActionLists: FastActionList;
    fastActionPack: FastActionPack;
    SET_LISTSPACK: (data: FastActionList) => void;
    SET_LASTCOUNTPACK: (data: FastActionLastPack) => void;
    VALIDAR_PERMISSAO_USUARIO: (telaDescricao: string, acaoController: string) => boolean;

    private listEntregaComponent: ListEntregaComponent = null;
    private shortcutComponent: ShortcutComponent = null;
    private tentativasEntregaComponent: TentativasEntregaComponent = null;
    private impressaoComponent: ImpressaoComponent = null;
    private vendaEtiquetasShortcutComponent: ShortcutComponent = null;
    private vendaEtiquetasComponent: EtiquetasListComponent = null;

    private vendasEntregas: Array<VendaModel> = [];
    private correiosService = new CorreiosService();

    gridFilterKey = "";
    gridSortKey = "dataVencimento";
    gridSortOrder = "desc";
    startFilterKey = "";
    filterKey = "";
    pageIndex = 1;
    pageSize = 10;
    total = 0;
    filtro: Array<Object> = [];
    tipoBuscaVenda = 0;
    vendedor = null;
    pags = false;
    onPostagem = false;
    vendaId = 0;

    private async loadVendaEntregas(forcarLoad = true) {
        const entregasPreLoads = this.fastActionLists.entregas;
        if (entregasPreLoads.length > 0 && !forcarLoad) {
            this.vendasEntregas = entregasPreLoads as Array<VendaModel>;
        } else {
            try {
                const customParameters: VendaListParameters = {
                    dataInicial: convertToDateInputString(this.filtro[0]),
                    dataFinal: convertToDateInputString(this.filtro[1], true),
                    vendedor: this.vendedor,
                };

                this.pageIndex = this.gridFilterKey !== this.filterKey ? 1 : this.pageIndex;
                this.filterKey = this.gridFilterKey;

                const data = await new VendaService()
                    .listVendasEntregas(
                        this.gridFilterKey,
                        this.gridSortKey,
                        this.gridSortOrder,
                        this.pags ? 1 : this.pageIndex,
                        this.pags ? 999999 : this.pageSize,
                        customParameters,
                    )
                    .withLoading()
                    .resolveWithJSON<PaginationModel<VendaModel>>();

                this.vendasEntregas = data.list;
                this.SET_LISTSPACK({ entregas: data.list });
            } catch {}
        }
    }

    private async loadVendasEtiquetas() {
        try {
            const customParameters: VendaListParameters = {
                dataInicial: convertToDateWithTimeInputString(new Date(new Date().setHours(0, 0, 0))),
                dataFinal: convertToDateWithTimeInputString(new Date(new Date().setHours(23, 59, 59))),
                vendasComNotas: true,
            };

            const data = await new VendaService()
                .listVendasEtiquetas(
                    this.gridFilterKey,
                    this.gridSortKey,
                    this.gridSortOrder,
                    this.pags ? 1 : this.pageIndex,
                    this.pags ? 999999 : this.pageSize,
                    customParameters,
                )
                .withLoading()
                .resolveWithJSON<PaginationModel<VendaModel>>();

            this.vendasEntregas = data.list;
        } catch {}
    }

    private onSearch(venda: VendaModel) {
        this.$router.push("/venda-edicao/" + venda.id);
    }

    private onTentativaEntrega(venda: VendaModel) {
        this.tentativasEntregaComponent.show(venda.id);
    }

    private onMarcarEntregue(venda: VendaModel) {
        this.listEntregaComponent.onMarcarComoEntregue(venda.id);
    }

    private async onImprimirRomaneio(venda: VendaModel) {
        this.impressaoComponent = this.$refs.impressaoComponent as ImpressaoComponent;

        await this.impressaoComponent.show({
            modelId: venda.id,
            tipoImpressao: "Venda",
            impressaoRotulo: false,
            impressaoSelecionarModelo: true,
            modeloEspecifico: ModeloEspecifico.Romaneio,
        });
    }

    private async onVerMais() {
        if (this.onPostagem) {
            this.vendaEtiquetasComponent.load();
            this.vendaEtiquetasShortcutComponent.title = this.$t("__.Crud.etiquetas.list_vue_html.etiquetas") as string;
            this.vendaEtiquetasShortcutComponent.show();
        } else {
            this.listEntregaComponent.load();
            this.shortcutComponent.title = this.$t("__.ts.entregas") as string;
            this.shortcutComponent.show();
        }
    }

    private onEntregas() {
        this.onPostagem = false;
        this.loadVendaEntregas(true);
    }

    private onPostagens() {
        this.onPostagem = true;
        this.loadVendasEtiquetas();
    }

    private async onCorreios(venda: VendaModel) {
        try {
            this.vendaEtiquetasComponent.onGerarCodigo(venda);
        } catch {}
    }

    private onImprimirEtiquetas(venda: VendaModel) {
        try {
            this.vendaEtiquetasComponent.onImprimirEtiquetas([venda]);
        } catch {}
    }

    private mounted() {
        this.shortcutComponent = this.$refs.shortcutComponent as ShortcutComponent;
        this.listEntregaComponent = this.$refs.listEntregaComponent as ListEntregaComponent;
        this.tentativasEntregaComponent = this.$refs.tentativasEntregaComponent as TentativasEntregaComponent;
        this.vendaEtiquetasShortcutComponent = this.$refs.vendaEtiquetasShortcutComponent as ShortcutComponent;
        this.vendaEtiquetasComponent = this.$refs.vendaEtiquetasComponent as EtiquetasListComponent;

        Promise.all([this.loadVendaEntregas(false)])
            .then(() => {
                this.SET_LASTCOUNTPACK({ entregas: Number(this.fastActionPack.countPack.entregas) });
            })
            .catch(() => {});
    }
}
