import nfetch from "../utils/events/nfetch";

export default class VolumeService {
    private controllerName = "Volume";

    public get(id: number): Promise<Response> {
        //@ts-ignore
        return nfetch(`/${this.controllerName}/Get?id=${id}`, {
            credentials: "same-origin"
        });
    }
}