import Vue from "vue";

import checkboxComponent from "@/components/child/form/checkbox.vue";
import comboComponent from "@/components/child/form/combo.vue";
import dataTooltipComponent from "@/components/child/form/datatooltip.vue";
import fieldsetComponent from "@/components/child/form/fieldset.vue";
import moedaComponent from "@/components/child/form/moeda.vue";
import { Component, Watch } from "@/decorators";
import Especie from "@/models/enum/especiePagamento";
import ContaCorrenteModel from "@/models/financeiro/contaCorrenteModel";
import FormaRecebimentoModel from "@/models/financeiro/formaRecebimentoModel";
import PaginationModel from "@/models/paginationModel";
import ContaCorrenteService from "@/services/financeiro/contaCorrenteService";
import FormaRecebimentoService from "@/services/financeiro/formaRecebimentoService";
import MovimentacaoCaixaService from "@/services/financeiro/movimentacaoCaixaService";

import "../crud/crud.scss";

@Component({
    components: {
        checkboxComponent,
        dataTooltipComponent,
        fieldsetComponent,
        moedaComponent,
        comboComponent,
    },
})
export default class SangriaComponent extends Vue {
    private movimentacaoCaixaService = new MovimentacaoCaixaService();

    public sangria = 0;
    carregou = false;
    descricao: string = null;
    saldoAtual = 0;
    contaCorrenteId = 0;
    contaCorrenteIdDestino = 0;

    formaRecebimentoId = 0;
    saldoCalculado = 0;

    bloquearAlteracaoContaCorrente = false;

    contaCorrenteOptions: Array<Object> = [];
    formaRecebimentoOptions: Array<Object> = [];

    public clear(
        caixaId: number,
        caixaDesc: string,
        saldoAtual: number,
        valorSangria = 0,
        complementoJustificativa = "",
        contaCorrenteDestFechamentoId = 0,
    ) {
        this.descricao = "Sangria de Pdv " + caixaDesc + complementoJustificativa;
        this.saldoAtual = saldoAtual;
        this.contaCorrenteId = caixaId;
        this.saldoCalculado = saldoAtual;
        this.sangria = valorSangria;
        this.formaRecebimentoId = 0;

        this.contaCorrenteOptions = this.contaCorrenteOptions.filter(p => p["value"] != this.contaCorrenteId);

        if (
            contaCorrenteDestFechamentoId > 0 &&
            this.contaCorrenteOptions.some(p => p["value"] == contaCorrenteDestFechamentoId)
        ) {
            this.bloquearAlteracaoContaCorrente = contaCorrenteDestFechamentoId > 0;
            this.contaCorrenteIdDestino = contaCorrenteDestFechamentoId;
        }

        this.formaRecebimentoOptions = this.formaRecebimentoOptions.filter(
            p => p["contaCorrenteOrigemId"] == this.contaCorrenteId,
        );
    }

    private async loadContasCorrentes() {
        try {
            const data = await new ContaCorrenteService()
                .combo()
                .resolveWithJSON<PaginationModel<ContaCorrenteModel>>();

            this.contaCorrenteOptions = data.list.map(item => ({ value: item.id, text: item.descricao }));
            //mostra apenas diferente da conta do caixa da sessao aberta
            this.contaCorrenteOptions = this.contaCorrenteOptions.filter(p => p["value"] != this.contaCorrenteId);
        } catch {}
    }

    private async loadFormasRecebimento() {
        try {
            const data = await new FormaRecebimentoService()
                .comboByEspecie(Especie.Transferencia)
                .resolveWithJSON<PaginationModel<FormaRecebimentoModel>>();

            this.formaRecebimentoOptions = data.list.map(item => ({
                value: item.id,
                text: item.descricao,
                especie: item.especie,
                juros: item.juros,
                desconto: item.desconto,
                bandeira: item.bandeiraId,
                metodosXForma: item.metodoPagamentoXForma,
                contaCorrenteOrigemId: item.contaCorrenteOrigemId,
            }));

            if (this.contaCorrenteId > 0) {
                this.formaRecebimentoOptions = this.formaRecebimentoOptions.filter(
                    f => f["contaCorrenteOrigemId"] == this.contaCorrenteId,
                );
            }
        } catch {}
    }

    private cancel() {
        this.$router.back();
    }

    @Watch("sangria")
    private onChangedSangria() {
        if (this.sangria <= this.saldoAtual) {
            this.saldoCalculado = this.saldoAtual - this.sangria;
        } else {
            this.sangria = 0;
            this.$showWarning(
                this.$t("__.ts.atencao"),
                "Não é possível informar sangria maior que o saldo atual do caixa.",
            );
        }
    }

    public getCarregou() {
        return this.carregou;
    }

    public async onGerarSangria() {
        try {
            const isValid = await this.$validator.validateAll();
            if (isValid) {
                const sucesso = await this.movimentacaoCaixaService
                    .insertSangria(this.sangria, this.formaRecebimentoId, this.contaCorrenteIdDestino, this.descricao)
                    .withLoading()
                    .resolveWithoutJSON();

                if (sucesso) {
                    await this.$showSuccess(this.$t("__.ts.inclusao"), this.$t("__.ts.sangriaGeradSucess"));
                    return true;
                }
            }
        } catch {}
        return false;
    }

    private mounted() {
        this.carregou = false;

        Promise.all([this.loadFormasRecebimento(), this.loadContasCorrentes()])
            .then(() => {
                this.carregou = true;
            })
            .catch(() => {});
    }
}
