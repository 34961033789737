import BaseModel from "../../../../../base/baseModel";

export default class TotalRetTribNfeModel extends BaseModel {
    valorRetPIS: number = null;
    valorRetCOFINS: number = null;
    valorRetCSLL: number = null;
    valorBaseCalculoIRRF: number = null;
    valorIRRF: number = null;
    valorBaseCalculoRetPrev: number = null;
    valorRetPrev: number = null;
}
