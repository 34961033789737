import TelaModel from "@/models/telaModel";
import TelaService from "@/services/telaService";

export type MenuItem = {
    id: string;
    name: string;
    title: string;
    description: string;
    classIcon: string;
    itens: Array<SubMenuItem>;
};

export type SubMenuItem = {
    id: string;
    name: string;
    title: string;
    listRoute: string;
    editRoute: string;
    itens: Array<SubMenuItem>;
    link: string;
};

type LoadMenusProps = {
    VALIDAR_PERMISSAO_USUARIO: (telaDescricao: string, acaoController: string) => boolean;
};

export default async function loadMenus({ VALIDAR_PERMISSAO_USUARIO }: LoadMenusProps) {
    const menus = new TelaModel();
    menus.filhos = new Array<TelaModel>();

    try {
        const telasMenu = await new TelaService().getMenu().withLoading().resolveWithJSON<TelaModel[]>();

        let count = 0;
        let countAux = 0;
        const navMenuItens: MenuItem[] = [];

        for (const menu of telasMenu) {
            navMenuItens.push({
                id: menu.id.toString(),
                name: menu.id.toString(),
                title: menu.descricao,
                description: menu.subtitle,
                classIcon: menu.iconeMenu,
                itens: [],
            });

            if (menu.filhos != null) {
                for (const subMenu of menu.filhos) {
                    navMenuItens[count].itens.push({
                        id: subMenu.descricao + menu.id.toString(),
                        name: subMenu.id.toString(),
                        title: subMenu.descricao,
                        listRoute: subMenu.rota,
                        editRoute: VALIDAR_PERMISSAO_USUARIO(subMenu.descricao, "insert")
                            ? subMenu.rotaAcaoRapida
                            : null,
                        itens: [],
                        link: subMenu.link,
                    });

                    if (subMenu.filhos != null) {
                        for (const subSubMenu of subMenu.filhos) {
                            navMenuItens[count].itens[countAux].itens.push({
                                id: menu.id.toString() + subMenu.id.toString() + subSubMenu.id.toString(),
                                name: subSubMenu.id.toString(),
                                title: subSubMenu.descricao,
                                listRoute: subSubMenu.rota,
                                editRoute: VALIDAR_PERMISSAO_USUARIO(subSubMenu.descricao, "insert")
                                    ? subSubMenu.rotaAcaoRapida
                                    : null,
                                itens: [],
                                link: subMenu.link,
                            });
                        }
                        countAux++;
                    }
                }
                countAux = 0;
            }
            count++;
        }

        return navMenuItens;
    } catch {}

    return [];
}
