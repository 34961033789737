var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "combo", staticClass: "input-group combo-component" },
    [
      !_vm.exibeDataCombo
        ? _c(
            "v-select",
            {
              attrs: {
                label: "text",
                disabled: _vm.disabled,
                id: _vm.id,
                name: _vm.name,
                options: _vm.data,
                placeholder: _vm.placeholder,
                searchable: _vm.searchable,
                showClearButton: false,
                tabindex: _vm.tabindex,
                value: _vm.selected,
              },
              on: {
                "update:value": function ($event) {
                  _vm.selected = $event
                },
                "search:blur": _vm.onSearchBlur,
                search: _vm.onSearch,
                input: _vm.onChangeInput,
              },
              scopedSlots: _vm._u([
                {
                  key: "option",
                  fn: function (option) {
                    return option.icon != null
                      ? [
                          _c("span", {
                            staticClass: "fa",
                            class: [
                              option.icon,
                              { "color-black": !option.icon },
                              option.iconColor,
                            ],
                          }),
                          _vm._v(" "),
                          !_vm.isCustomOption(option)
                            ? [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(_vm.getOptionConfigs(option)[0][0])
                                  ),
                                ]),
                              ]
                            : _vm._l(
                                _vm.getOptionConfigs(option)[0],
                                function (config) {
                                  return [
                                    config[1]
                                      ? [
                                          _c("sup", { key: config[0] }, [
                                            _c("span", [
                                              _vm._v(_vm._s(config[0])),
                                            ]),
                                          ]),
                                        ]
                                      : [
                                          _c("span", { key: config[0] }, [
                                            _vm._v(_vm._s(config[0])),
                                          ]),
                                        ],
                                  ]
                                }
                              ),
                        ]
                      : undefined
                  },
                },
                {
                  key: "selected-option",
                  fn: function (option) {
                    return _vm.customView
                      ? [
                          !_vm.isCustomOption(option)
                            ? [
                                _c("span", { staticClass: "text-value" }, [
                                  _vm._v(
                                    _vm._s(_vm.getOptionConfigs(option)[0][0])
                                  ),
                                ]),
                              ]
                            : _vm._l(
                                _vm.getOptionConfigs(option)[0],
                                function (config) {
                                  return [
                                    config[1]
                                      ? [
                                          _c("sup", { key: config[0] }, [
                                            _c("span", [
                                              _vm._v(_vm._s(config[0])),
                                            ]),
                                          ]),
                                        ]
                                      : [
                                          _c("span", { key: config[0] }, [
                                            _vm._v(_vm._s(config[0])),
                                          ]),
                                        ],
                                  ]
                                }
                              ),
                        ]
                      : undefined
                  },
                },
              ]),
              model: {
                value: _vm.selected,
                callback: function ($$v) {
                  _vm.selected = $$v
                },
                expression: "selected",
              },
            },
            [
              _c(
                "span",
                { attrs: { slot: "no-options" }, slot: "no-options" },
                [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        "__.Components.child.form.combo_vue_html.nenhumaOpcEncont"
                      )
                    )
                  ),
                ]
              ),
            ]
          )
        : _c("input", {
            staticClass: "form-control",
            attrs: {
              autocomplete: "off",
              type: "text",
              disabled: _vm.disabled,
              id: _vm.id,
              name: _vm.name,
            },
            domProps: {
              value: _vm.selected == null ? null : _vm.selected.text,
            },
          }),
      _vm._v(" "),
      !_vm.disabled && !_vm.loading && !_vm.exibeDataCombo
        ? _c(
            "span",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.refresh != null,
                  expression: "refresh != null",
                },
              ],
              staticClass: "input-group-addon",
              on: { click: _vm.onRefresh },
            },
            [_c("span", { staticClass: "fa fa-sync" })]
          )
        : _c(
            "span",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.disabled && !_vm.exibeDataCombo,
                  expression: "!disabled && !exibeDataCombo",
                },
              ],
              staticClass: "input-group-addon",
            },
            [_c("span", { staticClass: "fa fa-spinner ignorePrint" })]
          ),
      _vm._v(" "),
      !_vm.disabled && _vm.showAddButton
        ? _c(
            "span",
            { staticClass: "input-group-addon", on: { click: _vm.onAdd } },
            [_c("span", { staticClass: "fa fa-plus" })]
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.disabled && _vm.showEditButton
        ? _c(
            "span",
            { staticClass: "input-group-addon", on: { click: _vm.onAddEdit } },
            [_c("span", { staticClass: "fa fa-edit" })]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.showViewButton
        ? _c(
            "span",
            {
              staticClass: "input-group-addon",
              on: { click: _vm.onAddViewId },
            },
            [_c("span", { staticClass: "fa fa-eye" })]
          )
        : _vm._e(),
      _vm._v(" "),
      [
        _c(
          "shortcutComponent",
          {
            ref: "shortcutComponent",
            staticClass: "shortcutCombo",
            attrs: { modalClass: _vm.modalClass },
            on: { "selection-item": _vm.onConfirmAddNew },
          },
          [
            _c(
              "div",
              {
                staticClass: "shortcutCombo",
                attrs: { slot: "component" },
                slot: "component",
              },
              [_vm._t("component")],
              2
            ),
          ]
        ),
      ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }