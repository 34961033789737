import Vue from "vue";

import actionBarComponent from "@/components/child/actionBar/actionBar.vue";
import checkboxComponent from "@/components/child/form/checkbox.vue";
import comboComponent from "@/components/child/form/combo.vue";
import dataTooltipComponent from "@/components/child/form/datatooltip.vue";
import dateTimePickerComponent from "@/components/child/form/datetimepicker.vue";
import fieldsetComponent from "@/components/child/form/fieldset.vue";
import moedaComponent from "@/components/child/form/moeda.vue";
import textareaComponent from "@/components/child/form/textarea.vue";
import gridComponent from "@/components/child/grid/grid.vue";
import { GridColumn, GridColumnType } from "@/components/child/grid/gridColumn";
import LoadingModalComponent from "@/components/child/loadingmodal/loadingmodal";
import loadingModalComponent from "@/components/child/loadingmodal/loadingmodal.vue";
import { Component, Watch } from "@/decorators";
import EnumExtensions from "@/models/enum/extensions/enumExtensions";
import Frequencias from "@/models/enum/frequencias";
import TipoMovimentacao from "@/models/enum/tiposMovimentacao";
import ContaGerencialModel from "@/models/financeiro/contaGerencialModel";
import ContaPagarModel from "@/models/financeiro/contaPagarModel";
import ContaPagarPagamentoModel from "@/models/financeiro/contaPagarPagamentoModel";
import MetodoPagemento from "@/models/financeiro/metodoPagamentoModel";
import LiquidarModel from "@/models/financeiro/pagamentoContasModel";
import FornecedorModel from "@/models/fornecedorModel";
import PaginationModel from "@/models/paginationModel";
import ValidationErrorModel from "@/models/validationErrorModel";
import ContaGerencialService from "@/services/financeiro/contaGerencialService";
import ContaPagarService from "@/services/financeiro/contaPagarService";
import MetodoPagementoService from "@/services/financeiro/metodoPagamentoService";
import FornecedorService from "@/services/fornecedorService";
import { getFornecedorCombo } from "@/utils/common/combo/combotext";
import { addDays } from "@/utils/common/date";
import ValidationErrorWrapper from "@/wrappers/validationErrorWrapper";

import FornecedorComponent from "../cliente/edit";
import fornecedorComponent from "../fornecedor/edit.vue";

import PagamentoContaComponent from "./pagamentoconta";
import pagamentoContaComponent from "./pagamentoconta.vue";

import "../crud.scss";
import "./edit.scss";

@Component({
    components: {
        textareaComponent,
        comboComponent,
        pagamentoContaComponent,
        fieldsetComponent,
        moedaComponent,
        dateTimePickerComponent,
        dataTooltipComponent,
        gridComponent,
        loadingModalComponent,
        checkboxComponent,
        fornecedorComponent,
        actionBarComponent,
    },
})
export default class ContaPagarEditComponent extends Vue {
    private service = new ContaPagarService();
    private validationErrorWrapper = new ValidationErrorWrapper(this.$validator);
    private pagamentoContaComponent: PagamentoContaComponent = null;
    private loadingModalComponent: LoadingModalComponent = null;
    private fornecedorComponent: FornecedorComponent = null;

    private parcelar = false;
    private numeroParcelas = 1;
    private metodoParcelamentoId = -1;

    pagamentos: Array<ContaPagarPagamentoModel> = [];
    liquidarModel: LiquidarModel = new LiquidarModel();
    model: ContaPagarModel = new ContaPagarModel();
    modelId = 0;
    saldoPagar = 0;

    contasGerenciaisOptions: Array<Object> = [];
    fornecedoresOptions: Array<Object> = [];
    metodosPagamentosOptions: Array<Object> = [];

    recorrenciasOptions: Array<Object> = [
        { text: "Sem Recorr\u00eancias", value: 0 },
        { text: "1 Recorr\u00eancia", value: 1 },
        { text: "2 Recorr\u00eancias", value: 2 },
        { text: "3 Recorr\u00eancias", value: 3 },
        { text: "4 Recorr\u00eancias", value: 4 },
        { text: "5 Recorr\u00eancias", value: 5 },
        { text: "6 Recorr\u00eancias", value: 6 },
        { text: "7 Recorr\u00eancias", value: 7 },
        { text: "8 Recorr\u00eancias", value: 8 },
        { text: "9 Recorr\u00eancias", value: 9 },
        { text: "10 Recorr\u00eancias", value: 10 },
        { text: "11 Recorr\u00eancias", value: 11 },
        { text: "12 Recorr\u00eancias", value: 12 },
    ];
    frequenciasOptions: Array<Object> = EnumExtensions.getNamesAndValuesOrderedByValues(Frequencias);

    get gridColumns(): Array<GridColumn> {
        return [
            new GridColumn("dataMovimento", "Data", GridColumnType.Date),
            new GridColumn("valor", "Pago", GridColumnType.Money),
            new GridColumn("desconto", "Desconto", GridColumnType.Money),
            new GridColumn("juros", "Juros", GridColumnType.Money),
            new GridColumn("formaPagamentoDescricao", "Forma", GridColumnType.String),
            new GridColumn("contaCorrenteDescricao", "Conta", GridColumnType.String),
        ];
    }

    private load() {
        this.model = new ContaPagarModel();
        this.model.recorrencias = 0;
        this.model.dataVencimento = new Date();

        this.parcelar = false;
        this.numeroParcelas = 1;

        this.validationErrorWrapper.clearErrors();

        if (this.modelId > 0) {
            this.loadingModalComponent.showLoading();

            this.service
                .get(this.modelId)
                .then(response => {
                    return response.json() as Promise<ContaPagarModel>;
                })
                .then(data => {
                    this.model.updateFrom(data);
                    this.loadPagamentos();
                    //busca saldo do modelo
                    this.atualizarSaldoPagar();
                    this.pagamentoContaComponent.clear(this.saldoPagar);
                    this.pagamentoContaComponent.enable(this.saldoPagar > 0);
                    this.loadingModalComponent.hide();
                })
                .catch(error => {
                    this.$router.back();
                    this.loadingModalComponent.hide();
                });
        } else {
            this.pagamentoContaComponent.clear(0);
            this.pagamentoContaComponent.enable(false);
            this.loadingModalComponent.hide();
        }
    }
    private async loadMetodoPagamentos(idFiltro = 0) {
        try {
            const data = await new MetodoPagementoService()
                .combo()
                .then(r => r.json() as Promise<PaginationModel<MetodoPagemento>>);
            this.metodosPagamentosOptions = data.list.map(item => {
                return {
                    value: item.id,
                    text: item.descricao,
                    numParcelas: item.numeroParcelas,
                    primeiroIntervalo: item.primeiroIntervalo,
                    intervalo: item.intervalo,
                };
            });
            this.metodosPagamentosOptions = this.metodosPagamentosOptions.filter(p => p["numParcelas"] > 1);
        } catch {}
    }

    private loadPagamentos() {
        this.pagamentos = null;
        this.pagamentos = new Array<ContaPagarPagamentoModel>();

        if (this.model.movimentacoes.length > 0) {
            this.pagamentos = this.model.movimentacoes;
        } else if (this.model.lancamentosVinculados.length > 0) {
            for (let i = 0; i < this.model.lancamentosVinculados.length; i++) {
                for (let j = 0; j < this.model.lancamentosVinculados[i].movimentacoes.length; j++) {
                    this.pagamentos.push(this.model.lancamentosVinculados[i].movimentacoes[j]);
                }
            }
        }
    }

    private async loadContasGerenciais() {
        try {
            const data = await new ContaGerencialService()
                .listByTipoMovimentacao("", 1, 999999, TipoMovimentacao.Saida)
                .resolveWithJSON<PaginationModel<ContaGerencialModel>>();

            this.contasGerenciaisOptions = data.list.map(item => {
                return {
                    value: item.id,
                    text: item.descricao,
                };
            });
        } catch {}
    }

    private async loadFornecedores() {
        try {
            const data = await new FornecedorService()
                .combo()
                .then(r => r.json() as Promise<PaginationModel<FornecedorModel>>);
            this.fornecedoresOptions = data.list.map(getFornecedorCombo);
        } catch {}
    }

    private atualizarSaldoPagar() {
        if (this.model.movimentacoes.length == 0 && this.model.lancamentosVinculados.length == 0)
            this.model.saldo = this.model.valorTotal;

        this.saldoPagar = this.model.saldo;

        if (this.saldoPagar < 0) this.saldoPagar = 0;

        if (this.model.movimentacoes.length == 0 && this.model.lancamentosVinculados.length == 0) {
            this.$el.querySelector("#valorTotal").removeAttribute("disabled");
            this.$el.querySelector("#dataVencimento").removeAttribute("disabled");
        } else {
            this.$el.querySelector("#valorTotal").setAttribute("disabled", "disabled");
            this.$el.querySelector("#dataVencimento").setAttribute("disabled", "disabled");
        }
    }

    private save() {
        this.$validator
            .validateAll()
            .then(isValid => {
                //nao validar o isvalid aqui, pois ja faz no filho
                this.model.dataLancamento = new Date();
                this.loadingModalComponent.showSaving();
                if (!this.model.id && this.model.movimentacoes.length == 0) this.model.saldo = this.model.valorTotal;

                this.service[!this.model.id ? "insert" : "update"](this.model)
                    .then(async response => {
                        this.loadingModalComponent.hide();
                        if (response.ok) {
                            await this.$showInclusaoUpdate(this.model.id);
                            this.$router.back();
                        } else {
                            return response.json() as Promise<ValidationErrorModel[]>;
                        }
                    })
                    .then(data => {
                        this.validationErrorWrapper.showErrors(data);
                    })
                    .catch(error => {
                        this.loadingModalComponent.hide();
                    });
            })
            .catch(error => {});
    }

    private cancel() {
        this.$router.back();
    }

    private onInclusaoPagamento(model: ContaPagarPagamentoModel) {
        this.$validator
            .validateAll()
            .then(isValid => {
                if (isValid) {
                    //this.model.pagamentos.push(model);
                    this.atualizarSaldoPagar();
                    if (this.model.id > 0) {
                        //coloca modelo de pagamento e conta no model de liquidacao para enviar para o service
                        this.liquidarModel = new LiquidarModel();
                        this.liquidarModel.contaPagarPagamentoViewModel = model;
                        this.liquidarModel.contaPagarViewModel = this.model;
                        this.loadingModalComponent.showSaving();
                        this.service
                            .LiquidarWithModel(this.liquidarModel)
                            .then(response => {
                                this.load();
                                this.atualizarSaldoPagar();

                                this.loadingModalComponent.hide();
                                if (response.ok) {
                                    this.$showSuccess(this.$t("__.ts.inclusao"), this.$t("__.ts.registroSalvSucess"));
                                } else {
                                    return response.json() as Promise<ValidationErrorModel[]>;
                                }
                            })
                            .then(data => {
                                this.validationErrorWrapper.showErrors(data);
                            })
                            .catch(error => {
                                this.loadingModalComponent.hide();
                            });
                    } else {
                        this.model.movimentacoes.push(model);
                        this.model.saldo = this.model.saldo - (model.valor + model.desconto - model.juros);
                        this.atualizarSaldoPagar();
                        this.loadPagamentos();
                    }
                    this.pagamentoContaComponent.clear(this.saldoPagar);
                } else {
                    this.$focusErrorField();
                }
            })
            .catch(error => {
                this.loadingModalComponent.hide();
            });
    }

    private onRemoveItem(model: ContaPagarPagamentoModel) {
        if (this.model.id > 0) {
            this.service
                .ExcluirPagamentoWithModel(model)
                .then(response => {
                    this.load();
                    this.atualizarSaldoPagar();
                    this.loadingModalComponent.hide();
                    if (response.ok) {
                        this.$showSuccess(this.$t("__.ts.exclusao"), this.$t("__.ts.regisRemovSucess"));
                    } else {
                        return response.json() as Promise<ValidationErrorModel[]>;
                    }
                })
                .then(data => {
                    this.validationErrorWrapper.showErrors(data);
                })
                .catch(error => {
                    this.loadingModalComponent.hide();
                });
        } else {
            this.model.movimentacoes = this.model.movimentacoes.filter(p => p != model);
            this.model.saldo = this.model.saldo + model.valor + model.desconto - model.juros;
            this.loadPagamentos();
            this.atualizarSaldoPagar();
        }
    }

    private Parcelar() {
        //parcelar aqui, após validações
        this.loadingModalComponent.showCustomText("Parcelando...");
        const metodo = this.metodosPagamentosOptions.filter(p => p["value"] == this.metodoParcelamentoId)[0];
        //value: item.id, text: item.descricao, numParcelas: item.numeroParcelas, primeiroIntervalo: item.primeiroIntervalo , intervalo: item.intervalo
        const numPar: number = metodo["numParcelas"];
        const primeiraParc: number = metodo["primeiroIntervalo"];
        const intervalo: number = metodo["intervalo"];
        let dataAtual = new Date();
        if (numPar > 1 && primeiraParc >= 0 && intervalo >= 0) {
            // .toFixed(2); // Returns 1.55 instead of 1.56.
            const valorPParcela = parseFloat((this.model.valorTotal / numPar).toFixed(2));
            let saldototalDividir: number = this.model.valorTotal;
            for (let i = 0; i < numPar; i++) {
                //se saldo maior q valor calculado por parcela usa o valor e vai deixando o saldo para o final...
                //se for a ultima parcela a criar ..usa o restante do saldo para nao ultrapassar o valor total do lancamento principal
                const valor = saldototalDividir > valorPParcela && numPar - 1 > i ? valorPParcela : saldototalDividir;
                saldototalDividir = saldototalDividir - valor;
                const pagar: ContaPagarModel = new ContaPagarModel();
                pagar.fornecedorId = this.model.fornecedorId;
                pagar.contaGerencialDescricao = this.model.contaGerencialDescricao;
                pagar.contaGerencialId = this.model.contaGerencialId;
                pagar.dataLancamento = this.model.dataLancamento ? this.model.dataLancamento : new Date();
                pagar.descricao = `${this.model.descricao} (${(i + 1).toString()}/${numPar.toString()})`;
                pagar.status = 0;
                pagar.valorTotal = valor;
                pagar.saldo = valor;
                const dias: number = i == 0 ? primeiraParc : intervalo;
                dataAtual = addDays(dias, dataAtual);
                pagar.dataVencimento = dataAtual;
                pagar.lancamentoVinculadoId = this.model.id;

                this.model.lancamentosVinculados.push(pagar);
            }
        }
        this.$el.querySelector("#valorTotal").setAttribute("disabled", "disabled");
        this.loadingModalComponent.hide();
    }

    private onAddNewFornecedor() {
        this.fornecedorComponent.save();
    }

    private async openComboNewFornecedor() {
        this.fornecedorComponent.modelId = 0;
        this.fornecedorComponent.load();
        this.validationErrorWrapper.clearErrors();
    }

    private async openComboEditFornecedor(id) {
        this.fornecedorComponent.modelId = id;
        this.fornecedorComponent.load();
        this.validationErrorWrapper.clearErrors();
    }

    private async onFornecedorSaveOk(modelNovoFornecedor: FornecedorModel) {
        await this.loadFornecedores();
        this.model.fornecedorId = modelNovoFornecedor.id;
        this.validationErrorWrapper.clearErrors();
    }
    //@ts-ignore
    @Watch("model.recorrencias")
    private onRecorrenciasChanged(newValue) {
        if (+newValue == 0) this.model.frequencia = null;
    }

    //@ts-ignore
    @Watch("model.valorTotal")
    private onValorTotalChanged(newValue) {
        if (this.model.movimentacoes.length == 0 && this.model.lancamentosVinculados.length == 0)
            this.model.saldo = this.model.valorTotal;
        this.atualizarSaldoPagar();
    }
    //@ts-ignore
    @Watch("saldoPagar")
    private onSaldoPagarChanged(newValue) {
        this.pagamentoContaComponent.enable(+newValue > 0);
        this.pagamentoContaComponent.clear(+newValue);
    }

    //@ts-ignore
    @Watch("model.fornecedorId")
    private onFornecedorIdChange(newValue) {
        if (newValue) {
            if (this.model.id <= 0) {
                const contaGerencialId = this.fornecedoresOptions.filter(p => p["value"] == newValue)[0];
                if (contaGerencialId) this.model.contaGerencialId = contaGerencialId["contaGerencialId"];
            }
        }
    }

    private mounted() {
        this.pagamentoContaComponent = this.$refs.pagamentoContaComponent as PagamentoContaComponent;
        this.loadingModalComponent = this.$refs.loadingModalComponent as LoadingModalComponent;
        this.fornecedorComponent = this.$refs.fornecedorComponent as FornecedorComponent;

        this.loadingModalComponent.showLoading();

        Promise.all([this.loadContasGerenciais(), this.loadFornecedores(), this.loadMetodoPagamentos()])
            .then(result => {
                if (this.$route.params.id) this.modelId = +this.$route.params.id;

                this.load();
            })
            .catch(error => {
                this.loadingModalComponent.hide();
            });
    }
}
