import SessaoCaixaModel from "@/models/pdv/sessaoCaixaModel";
import nfetch from "@/utils/events/nfetch";

import { IService } from "./../base/iService";

export default class SessaoCaixaService extends IService<SessaoCaixaModel> {
    constructor() {
        super("SessaoCaixa");
    }

    public reabrirCaixa(id: number): Promise<Response> {
        return nfetch(`/${this.controllerName}/ReabrirCaixa?id=${id}`, {
            method: "post",
            credentials: "same-origin",
        });
    }

    public async getRelatorioXLS(id: number) {
        return nfetch(`/${this.controllerName}/GetRelatorioXLS?id=${id}`, {
            credentials: "same-origin",
        });
    }
}
