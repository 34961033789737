import FastActionModel from "../models/auxiliar/fastActionModel";
import nfetch from "../utils/events/nfetch";

import { IService } from "./base/iService";

export default class FastActionService extends IService<FastActionModel> {
    constructor() {
        super("FastAction");
    }

    public loadCountPack(): Promise<Response> {
        //@ts-ignore
        return nfetch(`/${this.controllerName}/LoadCountPack`, {
            credentials: "same-origin",
        });
    }
    public getInfosFinanceiro(): Promise<Response> {
        //@ts-ignore
        return nfetch(`/${this.controllerName}/GetInfosFinanceiro`, {
            credentials: "same-origin",
        });
    }
    public getInfosEstoqueHome(): Promise<Response> {
        //@ts-ignore
        return nfetch(`/${this.controllerName}/GetInfosEstoqueHome`, {
            credentials: "same-origin",
        });
    }
}
