import Vue from "vue";

import { Component, Prop } from "@/decorators";
import CepModel from "@/models/cepModel";
import Ufs from "@/models/enum/ufs";
import CepService from "@/services/external/cepService";

@Component
export default class CepFieldComponent extends Vue {
    private cepService = new CepService();

    @Prop(String) id: string;
    @Prop(String) name: string;
    @Prop(String) value: string;
    @Prop(Boolean) disabled: boolean;

    private async onInput(value) {
        const unformattedValue = this.formatValueToModel(value);

        this.$emit("input", unformattedValue);

        if (unformattedValue.length == 8) {
            const data = await this.cepService.get(value).withLoading().resolveWithJSON<any>();

            if (data.erro) {
                this.$emit("cep-invalid");
            } else {
                const dadosCep = new CepModel();
                dadosCep.cidadeId = data.ibge;
                dadosCep.cidadeNome = data.localidade;
                dadosCep.endereco = data.logradouro;
                dadosCep.bairro = data.bairro;
                dadosCep.complemento = data.complemento;
                dadosCep.uf = Ufs[<string>data.uf];

                this.$emit("cep-changed", dadosCep);
            }
        }
    }

    private onBlur(value) {
        this.$emit("input", this.formatValueToModel(value));
    }

    private formatValueToModel(value) {
        return value.replace(/[^\d]/g, "");
    }
}
