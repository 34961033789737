import BaseModel from "./../base/baseModel";
import EspeciePagamento from "./../enum/especiePagamento";
import BandeiraXFormaPagamento from "./bandeiraXFormaPagamentoModel";
import MetodoPagamentoXForma from "./metodoPagamentoXFormaModel";

export default class FormaRecebimentoModel extends BaseModel {
    id?: number = null;
    descricao: string = null;
    ativo = false;
    especie: EspeciePagamento = null;
    bandeiraId?: number = null;
    operadoraCartoesId?: number = null;
    metodoPagamentoXForma: Array<MetodoPagamentoXForma> = null;
    juros?: number = null;
    desconto?: number = null;
    contaCorrenteOrigemId?: number = null;
    formaPagamentoOrigemId?: number = null;
    bandeiraXFormaPagamento: Array<BandeiraXFormaPagamento> = null;
}
