enum SubGrupoProduto {
    //materia prima
    Ativo = 0,
    Flavorizante = 1,
    VeiculoExcipiente = 2,
    //embalagens
    Recipiente = 3,
    Tampa = 4,
    Aplicador = 5,
    Outros = 6,
    Cor = 7,
}

export default SubGrupoProduto;
