var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "fieldset-component",
        {
          attrs: {
            title: _vm.$t(
              "__.Components.parent.importacaonfe.transporte_vue_html.transportador"
            ),
          },
        },
        [
          _c("div", { attrs: { slot: "rows" }, slot: "rows" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-sm-12" }, [
                _c("div", { staticClass: "form-group" }, [
                  _c(
                    "label",
                    {
                      staticClass: "control-label",
                      attrs: { for: "transportadorNome" },
                    },
                    [
                      _vm._v(
                        "\n                            " +
                          _vm._s(
                            _vm.model.transportador.tipoPessoa ==
                              _vm.tiposPessoa.Fisica
                              ? "Nome"
                              : "Razão Social"
                          ) +
                          "\n                        "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.model.transportador.nome,
                        expression: "model.transportador.nome",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: {
                      disabled: "disabled",
                      autocomplete: "off",
                      type: "text",
                      id: "transportadorNome",
                      name: "transportadorNome",
                    },
                    domProps: { value: _vm.model.transportador.nome },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.model.transportador,
                          "nome",
                          $event.target.value
                        )
                      },
                    },
                  }),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value:
                      _vm.model.transportador.tipoPessoa !=
                      _vm.tiposPessoa.Fisica,
                    expression:
                      "model.transportador.tipoPessoa != tiposPessoa.Fisica",
                  },
                ],
                staticClass: "row",
              },
              [
                _c("div", { staticClass: "col-sm-4" }, [
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c(
                        "label",
                        {
                          staticClass: "control-label",
                          attrs: { for: "transportadorCnpj" },
                        },
                        [
                          _vm._v(
                            "\n                            " +
                              _vm._s(
                                _vm.$t(
                                  "__.Components.parent.importacaonfe.transporte_vue_html.cnpj"
                                )
                              ) +
                              "\n                        "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("cnpj-component", {
                        staticClass: "form-control",
                        attrs: {
                          disabled: "disabled",
                          id: "transportadorCnpj",
                          name: "transportadorCnpj",
                        },
                        model: {
                          value: _vm.model.transportador.cnpj,
                          callback: function ($$v) {
                            _vm.$set(_vm.model.transportador, "cnpj", $$v)
                          },
                          expression: "model.transportador.cnpj",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-sm-4" }, [
                  _c("div", { staticClass: "form-group" }, [
                    _c(
                      "label",
                      {
                        staticClass: "control-label",
                        attrs: { for: "transportadorInscricaoEstadual" },
                      },
                      [
                        _vm._v(
                          "\n                            " +
                            _vm._s(
                              _vm.$t(
                                "__.Components.parent.importacaonfe.transporte_vue_html.ie"
                              )
                            ) +
                            "\n                        "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.model.transportador.inscricaoEstadual,
                          expression: "model.transportador.inscricaoEstadual",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: {
                        disabled: "disabled",
                        autocomplete: "off",
                        type: "text",
                        id: "transportadorInscricaoEstadual",
                        name: "transportadorInscricaoEstadual",
                        placeholder: "___-_______",
                      },
                      domProps: {
                        value: _vm.model.transportador.inscricaoEstadual,
                      },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.model.transportador,
                            "inscricaoEstadual",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-sm-4" }, [
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c(
                        "label",
                        {
                          staticClass: "control-label",
                          attrs: { for: "valorFrete" },
                        },
                        [
                          _vm._v(
                            "\n                            " +
                              _vm._s(
                                _vm.$t(
                                  "__.Components.parent.importacaonfe.transporte_vue_html.valorFrete"
                                )
                              ) +
                              "\n                        "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("moeda-component", {
                        directives: [
                          {
                            name: "mask-moeda-decimal",
                            rawName: "v-mask-moeda-decimal",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: { id: "valorFrete", name: "valorFrete" },
                        model: {
                          value: _vm.valorFreteInterno,
                          callback: function ($$v) {
                            _vm.valorFreteInterno = $$v
                          },
                          expression: "valorFreteInterno",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value:
                      _vm.model.transportador.tipoPessoa ==
                      _vm.tiposPessoa.Fisica,
                    expression:
                      "model.transportador.tipoPessoa == tiposPessoa.Fisica",
                  },
                ],
                staticClass: "row",
              },
              [
                _c("div", { staticClass: "col-sm-6" }, [
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c(
                        "label",
                        {
                          staticClass: "control-label",
                          attrs: { for: "transportadorCpf" },
                        },
                        [
                          _vm._v(
                            "\n                            " +
                              _vm._s(
                                _vm.$t(
                                  "__.Components.parent.importacaonfe.transporte_vue_html.cpf"
                                )
                              ) +
                              "\n                        "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("cpf-component", {
                        staticClass: "form-control",
                        attrs: {
                          disabled: "disabled",
                          id: "transportadorCpf",
                          name: "transportadorCpf",
                        },
                        model: {
                          value: _vm.model.transportador.cpf,
                          callback: function ($$v) {
                            _vm.$set(_vm.model.transportador, "cpf", $$v)
                          },
                          expression: "model.transportador.cpf",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-sm-4" }, [
                _c("div", { staticClass: "form-group" }, [
                  _c(
                    "label",
                    {
                      staticClass: "control-label",
                      attrs: { for: "transportadorUf" },
                    },
                    [
                      _vm._v(
                        "\n                            " +
                          _vm._s(
                            _vm.$t(
                              "__.Components.parent.importacaonfe.transporte_vue_html.uf"
                            )
                          ) +
                          "\n                        "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.model.transportador.uf,
                        expression: "model.transportador.uf",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: {
                      disabled: "disabled",
                      autocomplete: "off",
                      type: "text",
                      id: "transportadorUf",
                      name: "transportadorUf",
                    },
                    domProps: { value: _vm.model.transportador.uf },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.model.transportador,
                          "uf",
                          $event.target.value
                        )
                      },
                    },
                  }),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-sm-8" }, [
                _c("div", { staticClass: "form-group" }, [
                  _c(
                    "label",
                    {
                      staticClass: "control-label",
                      attrs: { for: "transportadorCidadeNome" },
                    },
                    [
                      _vm._v(
                        "\n                            " +
                          _vm._s(
                            _vm.$t(
                              "__.Components.parent.importacaonfe.transporte_vue_html.cidade"
                            )
                          ) +
                          "\n                        "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.model.transportador.cidadeNome,
                        expression: "model.transportador.cidadeNome",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: {
                      disabled: "disabled",
                      autocomplete: "off",
                      type: "text",
                      id: "transportadorCidadeNome",
                      name: "transportadorCidadeNome",
                    },
                    domProps: { value: _vm.model.transportador.cidadeNome },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.model.transportador,
                          "cidadeNome",
                          $event.target.value
                        )
                      },
                    },
                  }),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-sm-12" }, [
                _c("div", { staticClass: "form-group" }, [
                  _c(
                    "label",
                    {
                      staticClass: "control-label",
                      attrs: { for: "transportadorEndereco" },
                    },
                    [
                      _vm._v(
                        "\n                            " +
                          _vm._s(
                            _vm.$t(
                              "__.Components.parent.importacaonfe.transporte_vue_html.endereco"
                            )
                          ) +
                          "\n                        "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.model.transportador.endereco,
                        expression: "model.transportador.endereco",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: {
                      disabled: "disabled",
                      autocomplete: "off",
                      type: "text",
                      id: "transportadorEndereco",
                      name: "transportadorEndereco",
                    },
                    domProps: { value: _vm.model.transportador.endereco },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.model.transportador,
                          "endereco",
                          $event.target.value
                        )
                      },
                    },
                  }),
                ]),
              ]),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _vm._l(_vm.model.volumes, function (volume, volumeIndex) {
        return _c(
          "fieldset-component",
          {
            key: volumeIndex + 1,
            attrs: {
              disabled: "disabled",
              title: "Volume " + (volumeIndex + 1),
            },
          },
          [
            _c("div", { attrs: { slot: "rows" }, slot: "rows" }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-sm-4" }, [
                  _c("div", { staticClass: "form-group" }, [
                    _c(
                      "label",
                      {
                        staticClass: "control-label",
                        attrs: { for: "volume" + volumeIndex + "Quantidade" },
                      },
                      [
                        _vm._v(
                          "\n                            " +
                            _vm._s(
                              _vm.$t(
                                "__.Components.parent.importacaonfe.transporte_vue_html.qtd"
                              )
                            ) +
                            "\n                        "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: volume.quantidade,
                          expression: "volume.quantidade",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: {
                        autocomplete: "off",
                        type: "text",
                        id: "volume" + volumeIndex + "Quantidade",
                        name: "volume" + volumeIndex + "Quantidade",
                      },
                      domProps: { value: volume.quantidade },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(volume, "quantidade", $event.target.value)
                        },
                      },
                    }),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-sm-4" }, [
                  _c("div", { staticClass: "form-group" }, [
                    _c(
                      "label",
                      {
                        staticClass: "control-label",
                        attrs: { for: "volume" + volumeIndex + "Especie" },
                      },
                      [
                        _vm._v(
                          "\n                            " +
                            _vm._s(
                              _vm.$t(
                                "__.Components.parent.importacaonfe.transporte_vue_html.especie"
                              )
                            ) +
                            "\n                        "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: volume.especie,
                          expression: "volume.especie",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: {
                        autocomplete: "off",
                        type: "text",
                        id: "volume" + volumeIndex + "Especie",
                        name: "volume" + volumeIndex + "Especie",
                      },
                      domProps: { value: volume.especie },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(volume, "especie", $event.target.value)
                        },
                      },
                    }),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-sm-4" }, [
                  _c("div", { staticClass: "form-group" }, [
                    _c(
                      "label",
                      {
                        staticClass: "control-label",
                        attrs: { for: "volume" + volumeIndex + "Marca" },
                      },
                      [
                        _vm._v(
                          "\n                            " +
                            _vm._s(
                              _vm.$t(
                                "__.Components.parent.importacaonfe.transporte_vue_html.marca"
                              )
                            ) +
                            "\n                        "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: volume.marca,
                          expression: "volume.marca",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: {
                        autocomplete: "off",
                        type: "text",
                        id: "volume" + volumeIndex + "Marca",
                        name: "volume" + volumeIndex + "Marca",
                      },
                      domProps: { value: volume.marca },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(volume, "marca", $event.target.value)
                        },
                      },
                    }),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-sm-4" }, [
                  _c("div", { staticClass: "form-group" }, [
                    _c(
                      "label",
                      {
                        staticClass: "control-label",
                        attrs: { for: "volume" + volumeIndex + "Numeracao" },
                      },
                      [
                        _vm._v(
                          "\n                            " +
                            _vm._s(
                              _vm.$t(
                                "__.Components.parent.importacaonfe.transporte_vue_html.numeracao"
                              )
                            ) +
                            "\n                        "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: volume.numeracao,
                          expression: "volume.numeracao",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: {
                        autocomplete: "off",
                        type: "text",
                        id: "volume" + volumeIndex + "Numeracao",
                        name: "volume" + volumeIndex + "Numeracao",
                      },
                      domProps: { value: volume.numeracao },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(volume, "numeracao", $event.target.value)
                        },
                      },
                    }),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-sm-4" }, [
                  _c("div", { staticClass: "form-group" }, [
                    _c(
                      "label",
                      {
                        staticClass: "control-label",
                        attrs: { for: "volume" + volumeIndex + "PesoLiquido" },
                      },
                      [
                        _vm._v(
                          "\n                            " +
                            _vm._s(
                              _vm.$t(
                                "__.Components.parent.importacaonfe.transporte_vue_html.pesoLiq"
                              )
                            ) +
                            "\n                        "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        { name: "mask-decimal", rawName: "v-mask-decimal" },
                        {
                          name: "model",
                          rawName: "v-model",
                          value: volume.pesoLiquido,
                          expression: "volume.pesoLiquido",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: {
                        autocomplete: "off",
                        type: "text",
                        id: "volume" + volumeIndex + "PesoLiquido",
                        name: "volume" + volumeIndex + "PesoLiquido",
                        suffix: "KG",
                      },
                      domProps: { value: volume.pesoLiquido },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(volume, "pesoLiquido", $event.target.value)
                        },
                      },
                    }),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-sm-4" }, [
                  _c("div", { staticClass: "form-group" }, [
                    _c(
                      "label",
                      {
                        staticClass: "control-label",
                        attrs: { for: "volume" + volumeIndex + "PesoBruto" },
                      },
                      [
                        _vm._v(
                          "\n                            " +
                            _vm._s(
                              _vm.$t(
                                "__.Components.parent.importacaonfe.transporte_vue_html.pesoBruto"
                              )
                            ) +
                            "\n                        "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        { name: "mask-decimal", rawName: "v-mask-decimal" },
                        {
                          name: "model",
                          rawName: "v-model",
                          value: volume.pesoBruto,
                          expression: "volume.pesoBruto",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: {
                        autocomplete: "off",
                        type: "text",
                        id: "volume" + volumeIndex + "PesoBruto",
                        name: "volume" + volumeIndex + "PesoBruto",
                        suffix: "KG",
                      },
                      domProps: { value: volume.pesoBruto },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(volume, "pesoBruto", $event.target.value)
                        },
                      },
                    }),
                  ]),
                ]),
              ]),
            ]),
          ]
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }