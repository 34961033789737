import Vue from "vue";

import { Component } from "@/decorators";
import RelatorioService from "@/services/relatorioService";
import Delay from "@/utils/common/delay";

import "../crud/crud.scss";
import "./list.scss";

@Component
export default class RelatorioListComponent extends Vue {
    private service: RelatorioService = new RelatorioService();
    model: string[] = [];
    constructor() {
        super();
    }

    private async load() {
        const data = await this.service
            .getRelatoriosPermitidos()
            .then(r => r.json() as Promise<string[]>)
            .withLoading();
        this.model = data;

        this.ajustarCategoriasRelatorios();
    }

    private async ajustarCategoriasRelatorios() {
        await Delay(500);

        this.ajustarCategoriaRelatorio("#catComissao");
        this.ajustarCategoriaRelatorio("#catOrcamento");
        this.ajustarCategoriaRelatorio("#catFaturamento");
        this.ajustarCategoriaRelatorio("#catVendas");
        this.ajustarCategoriaRelatorio("#catNotas");
        this.ajustarCategoriaRelatorio("#catProdutos");
        this.ajustarCategoriaRelatorio("#catFinanceiro");
        this.ajustarCategoriaRelatorio("#catEstoque");
        this.ajustarCategoriaRelatorio("#catOutros");
    }

    private async ajustarCategoriaRelatorio(id: string) {
        const categoria = document.querySelector(id);
        if (categoria) {
            const relatorios = categoria.querySelectorAll(".rel");
            let temElementoVisivel = false;

            relatorios.forEach(rel => {
                if ((<any>rel).style.display !== "none") {
                    temElementoVisivel = true;
                }
            });

            if (temElementoVisivel) {
                categoria.classList.remove("hide");
            } else {
                categoria.classList.add("hide");
            }
        }
    }

    private permiteExibir(nome: string) {
        if (!this.model || this.model.length == 0) {
            return false;
        }

        return this.model.indexOf(nome) > -1;
    }

    private async mounted() {
        await this.load();
    }
}
