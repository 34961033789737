import FormulaPadraoModel from "@/models/formulaPadraoModel";
import FormulaPadraoValoresProdutoModel from "@/models/formulaPadraoValoresProdutoModel";
import { getCustomParams, getPaginationParams } from "@/utils/common/http";
import nfetch from "@/utils/events/nfetch";

import { IService } from "./base/iService";

export default class FormulaPadraoService extends IService<FormulaPadraoModel> {
    constructor() {
        super("FormulaPadrao");
    }

    public listWithFilter(
        filterKey: string,
        sortKey: string,
        sortOrder: string,
        pageIndex: number,
        pageSize: number,
        customProperties: { [key: string]: any } = {},
    ): Promise<Response> {
        const custom = getCustomParams(customProperties);
        const pagination = getPaginationParams(filterKey, sortKey, sortOrder, pageIndex, pageSize);

        return nfetch(`/${this.controllerName}/ListWithFilter?${pagination}` + custom, {
            credentials: "same-origin",
        });
    }

    public listByIds(ids: string): Promise<Response> {
        return nfetch(`/${this.controllerName}/ListByIds?ids=${ids}`, {
            credentials: "same-origin",
        });
    }

    public listDesmembrado(): Promise<Response> {
        return nfetch(`/${this.controllerName}/ListDesmembrado`, {
            credentials: "same-origin",
        });
    }

    public listPreVendaFormulaPadrao(): Promise<Response> {
        return nfetch(`/${this.controllerName}/ListFormulaPadraoPreVenda`, {
            credentials: "same-origin",
        });
    }

    public replicar(id: number, idFranquias: string): Promise<Response> {
        return nfetch(`/${this.controllerName}/Replicar?id=${id}&idFranquias=${idFranquias}`, {
            method: "post",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json; charset=UTF-8",
                "XSRF-TOKEN": this.getCookie("XSRF-TOKEN"),
            },
        });
    }

    public replicarTodos(franquiaOrigemId: number, idFranquias: string): Promise<Response> {
        return nfetch(
            `/${this.controllerName}/replicarTodos?franquiaOrigemId=${franquiaOrigemId}&idFranquias=${idFranquias}`,
            {
                method: "post",
                credentials: "same-origin",
                headers: {
                    "Content-Type": "application/json; charset=UTF-8",
                    "XSRF-TOKEN": this.getCookie("XSRF-TOKEN"),
                },
            },
        );
    }

    public getByParent(parentId: number, franquiaId: number): Promise<Response> {
        return nfetch(`/${this.controllerName}/GetByParentId?parentId=${parentId}&franquiaId=${franquiaId}`, {
            credentials: "same-origin",
        });
    }

    public recalcularValoresProdutosFormulas(ids: string): Promise<Response> {
        return nfetch(`/${this.controllerName}/RecalcularValoresProdutosFormulas?ids=${ids}`, {
            credentials: "same-origin",
        });
    }

    public atualizarValoresProduto(model: Array<FormulaPadraoValoresProdutoModel>): Promise<Response> {
        return nfetch(`/${this.controllerName}/AtualizarValoresProduto`, {
            method: "post",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json; charset=UTF-8",
                "XSRF-TOKEN": this.getCookie("XSRF-TOKEN"),
            },
            body: JSON.stringify(model),
        });
    }

    public getObservacaoFormulaPadrao(formulaPadraoId: number): Promise<Response> {
        return nfetch(`/${this.controllerName}/GetObservacaoFormulaPadrao?formulaPadraoId=${formulaPadraoId}`, {
            credentials: "same-origin",
        });
    }
}
