import Vue from "vue";

import searchComboComponent from "@/components/child/searchCombo/search.vue";
import ShortcutComponent from "@/components/parent/shortcut/shortcut";
import shortcutComponent from "@/components/parent/shortcut/shortcut.vue";
import { Component, Watch } from "@/decorators";
import ClienteModel from "@/models/clienteModel";
import ClienteService from "@/services/clienteService";
import { AtendimentoContextActions } from "@/store/atendimentoContext/types";
import Delay from "@/utils/common/delay";

@Component({
    components: {
        searchComboComponent,
        shortcutComponent,
    },
})
export default class AdicionarAtendimentoComponent extends Vue {
    private shortCutComponent: ShortcutComponent = null;

    private clienteService = new ClienteService();

    clienteId = 0;
    telefone = "";

    private customComboTextCliente = (p: ClienteModel) => ({
        value: p.id,
        text: `${p.id} - ` + (p.tipoPessoa != 1 ? p.nome : p.razaoSocial),
    });

    public open() {
        this.shortCutComponent = this.$refs.shortCutComponent as ShortcutComponent;
        this.shortCutComponent.title = "Criar novo atendimento";
        this.shortCutComponent.show();
    }

    @Watch("clienteId")
    private async onSelectTelefone(clienteId: number) {
        try {
            if (clienteId) {
                const cliente = await this.clienteService.get(clienteId).resolveWithJSON<ClienteModel>();
                this.telefone = cliente.celular ?? cliente.telefone;
            }
        } catch {}
    }

    private async onCreateAtendimento() {
        const isValid = await this.$validator.validateAll();
        if (isValid) {
            this.shortCutComponent.hide();

            await this.$store.dispatch(AtendimentoContextActions.CREATE_ATENDIMENTO_BY_NUMERO, this.telefone);

            this.clienteId = null;
            this.telefone = null;

            await Delay(100);
            this.$emit("@onCreated");
        }
    }
}
