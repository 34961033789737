var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "crud crud-edit" },
    [
      _c(
        "actionBarComponent",
        {
          attrs: {
            showSave: !_vm.somenteConsulta,
            slots: 3,
            title: _vm.$t(
              "__.Crud.formafarmaceutica.edit_vue_html.formFarmaceutEdit"
            ),
          },
          on: { onSave: _vm.save },
          scopedSlots: _vm._u([
            {
              key: "buttonSlot2",
              fn: function (ref) {
                var open = ref.open
                return _vm.modelId
                  ? _c(
                      "div",
                      {
                        attrs: { title: _vm.$t("__.ts.historicoAlteracoes") },
                        on: {
                          click: function ($event) {
                            open(
                              1,
                              _vm.$t("__.ts.historicoAlteracoes"),
                              "lg-modal"
                            )
                          },
                        },
                      },
                      [
                        _c("i", {
                          staticClass: "fa fa-history",
                          attrs: { slot: "button", "aria-hidden": "true" },
                          slot: "button",
                        }),
                      ]
                    )
                  : _vm._e()
              },
            },
          ]),
        },
        [
          _vm.validarVisibilidadeBotoes("replicar")
            ? _c(
                "div",
                {
                  attrs: {
                    slot: "buttonSlot0",
                    title: _vm.$t("__.Crud.replicar"),
                  },
                  on: {
                    click: function ($event) {
                      _vm.onReplicar()
                    },
                  },
                  slot: "buttonSlot0",
                },
                [
                  _c("i", {
                    staticClass: "fa fa-sync",
                    attrs: { "aria-hidden": "true" },
                  }),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.validarVisibilidadeBotoes("remover")
            ? _c(
                "div",
                {
                  attrs: {
                    slot: "buttonSlot1",
                    title: _vm.$t("__.Crud.excluir"),
                  },
                  on: {
                    click: function ($event) {
                      _vm.onRemoveFilho()
                    },
                  },
                  slot: "buttonSlot1",
                },
                [
                  _c("i", {
                    staticClass: "fa fa-times",
                    attrs: { "aria-hidden": "true" },
                  }),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { attrs: { slot: "modalSlot1" }, slot: "modalSlot1" },
            [
              _c("logEntidadeComponent", {
                attrs: { registroId: _vm.modelId, entidade: 1 },
              }),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "form",
        {
          attrs: { novalidate: "novalidate" },
          on: {
            submit: function ($event) {
              $event.preventDefault()
              _vm.save()
            },
          },
        },
        [
          _c("fieldset", [
            _c("div", { attrs: { slot: "rows", id: "forma" }, slot: "rows" }, [
              _c("div", { staticClass: "row" }, [
                _vm.isAdmin && _vm.modelId > 0
                  ? _c("div", { staticClass: "col-sm-12" }, [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: _vm.errors.first("franquiaId"),
                              expression: "errors.first('franquiaId')",
                            },
                          ],
                          staticClass: "form-group",
                        },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "control-label",
                              attrs: { for: "franquiaId" },
                            },
                            [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(
                                    _vm.$t(
                                      "__.Crud.formafarmaceutica.edit_vue_html.franquia"
                                    )
                                  ) +
                                  "\n                            "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("combo-component", {
                            attrs: {
                              id: "franquiaId",
                              name: "franquiaId",
                              data: _vm.franquiaOptions,
                              searchable: false,
                              disabled: _vm.somenteConsulta,
                            },
                            model: {
                              value: _vm.franquiaId,
                              callback: function ($$v) {
                                _vm.franquiaId = $$v
                              },
                              expression: "franquiaId",
                            },
                          }),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  {
                    class:
                      "col-sm-" +
                      [_vm.model.tipoFormaFarmaceutica == 6 ? "7" : "8"],
                  },
                  [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("descricao"),
                            expression: "errors.first('descricao')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: {
                              for: "descricao",
                              title: _vm.$t("__.obrigatorio"),
                            },
                          },
                          [
                            _c("span", { staticStyle: { color: "red" } }, [
                              _vm._v("*"),
                            ]),
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Crud.formafarmaceutica.edit_vue_html.descricao"
                                  )
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            { name: "focus", rawName: "v-focus" },
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.model.descricao,
                              expression: "model.descricao",
                            },
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required|max:256",
                              expression: "'required|max:256'",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            autocomplete: "off",
                            type: "text",
                            id: "descricao",
                            name: "descricao",
                            disabled: _vm.somenteConsulta,
                          },
                          domProps: { value: _vm.model.descricao },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.model,
                                "descricao",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.model.tipoFormaFarmaceutica == 6,
                        expression: "model.tipoFormaFarmaceutica == 6",
                      },
                    ],
                    staticClass: "col-sm-1",
                  },
                  [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("utilizaMolde"),
                            expression: "errors.first('utilizaMolde')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: {
                              for: "utilizaMolde",
                              title: _vm.$t(
                                "__.Crud.formafarmaceutica.edit_vue_html.calcMoldeAdequado"
                              ),
                            },
                          },
                          [
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Crud.formafarmaceutica.edit_vue_html.utilzMolde"
                                  )
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("checkbox-component", {
                          attrs: {
                            id: "utilizaMolde",
                            name: "utilizaMolde",
                            disabled: _vm.somenteConsulta,
                          },
                          model: {
                            value: _vm.model.utilizaMolde,
                            callback: function ($$v) {
                              _vm.$set(_vm.model, "utilizaMolde", $$v)
                            },
                            expression: "model.utilizaMolde",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "col-sm-2" }, [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "tooltip",
                          rawName: "v-tooltip",
                          value: _vm.errors.first("desconsiderarDensidade"),
                          expression: "errors.first('desconsiderarDensidade')",
                        },
                      ],
                      staticClass: "form-group",
                    },
                    [
                      _c(
                        "label",
                        {
                          staticClass: "control-label",
                          attrs: { for: "desconsiderarDensidade" },
                        },
                        [
                          _vm._v(
                            "\n                                " +
                              _vm._s(
                                _vm.$t(
                                  "__.Crud.formafarmaceutica.edit_vue_html.desconsiDensid"
                                )
                              ) +
                              "\n                            "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("data-tooltip-component", {
                        attrs: {
                          text: _vm.$t(
                            "__.Crud.formafarmaceutica.edit_vue_html.desconsiDensidNoCalc"
                          ),
                        },
                      }),
                      _vm._v(" "),
                      _c("checkbox-component", {
                        attrs: {
                          id: "desconsiderarDensidade",
                          name: "desconsiderarDensidade",
                          disabled: _vm.somenteConsulta,
                        },
                        model: {
                          value: _vm.model.desconsiderarDensidade,
                          callback: function ($$v) {
                            _vm.$set(_vm.model, "desconsiderarDensidade", $$v)
                          },
                          expression: "model.desconsiderarDensidade",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-sm-2" }, [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "tooltip",
                          rawName: "v-tooltip",
                          value: _vm.errors.first("ativo"),
                          expression: "errors.first('ativo')",
                        },
                      ],
                      staticClass: "form-group",
                    },
                    [
                      _c(
                        "label",
                        {
                          staticClass: "control-label",
                          attrs: {
                            for: "ativo",
                            title: _vm.$t(
                              "__.Crud.formafarmaceutica.edit_vue_html.ativo"
                            ),
                          },
                        },
                        [
                          _vm._v(
                            "\n                                " +
                              _vm._s(
                                _vm.$t(
                                  "__.Crud.formafarmaceutica.edit_vue_html.ativo"
                                )
                              ) +
                              "\n                            "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("checkbox-component", {
                        attrs: {
                          id: "ativo",
                          name: "ativo",
                          disabled: _vm.somenteConsulta,
                        },
                        model: {
                          value: _vm.model.ativo,
                          callback: function ($$v) {
                            _vm.$set(_vm.model, "ativo", $$v)
                          },
                          expression: "model.ativo",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-sm-4" }, [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "tooltip",
                          rawName: "v-tooltip",
                          value: _vm.errors.first("descricaoRotulo"),
                          expression: "errors.first('descricaoRotulo')",
                        },
                      ],
                      staticClass: "form-group",
                    },
                    [
                      _c(
                        "label",
                        {
                          staticClass: "control-label",
                          attrs: {
                            for: "descricaoRotulo",
                            title: _vm.$t("__.obrigatorio"),
                          },
                        },
                        [
                          _c("span", { staticStyle: { color: "red" } }, [
                            _vm._v("*"),
                          ]),
                          _vm._v(
                            "\n                                " +
                              _vm._s(
                                _vm.$t(
                                  "__.Crud.formafarmaceutica.edit_vue_html.descrRotulo"
                                )
                              ) +
                              "\n                            "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.model.descricaoRotulo,
                            expression: "model.descricaoRotulo",
                          },
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required|max:256",
                            expression: "'required|max:256'",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          autocomplete: "off",
                          type: "text",
                          id: "descricaoRotulo",
                          name: "descricaoRotulo",
                          disabled: _vm.somenteConsulta,
                        },
                        domProps: { value: _vm.model.descricaoRotulo },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.model,
                              "descricaoRotulo",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-sm-2" }, [
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c(
                        "label",
                        {
                          staticClass: "control-label",
                          attrs: {
                            for: "exibirDescricaoRotulo",
                            title: _vm.$t(
                              "__.Crud.formafarmaceutica.edit_vue_html.exibirDescRotulo"
                            ),
                          },
                        },
                        [
                          _vm._v(
                            "\n                                " +
                              _vm._s(
                                _vm.$t(
                                  "__.Crud.formafarmaceutica.edit_vue_html.exibirDescRotulo"
                                )
                              ) +
                              "\n                            "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("checkbox-component", {
                        attrs: {
                          id: "exibirDescricaoRotulo",
                          name: "exibirDescricaoRotulo",
                          disabled: _vm.somenteConsulta,
                        },
                        model: {
                          value: _vm.model.exibirDescricaoRotulo,
                          callback: function ($$v) {
                            _vm.$set(_vm.model, "exibirDescricaoRotulo", $$v)
                          },
                          expression: "model.exibirDescricaoRotulo",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    class: "col-sm-" + [_vm.model.tipoCalculo == 4 ? "2" : "5"],
                  },
                  [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("tipoCalculo"),
                            expression: "errors.first('tipoCalculo')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "tipoCalculo" },
                          },
                          [
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Crud.formafarmaceutica.edit_vue_html.tipCalcAtivo"
                                  )
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("data-tooltip-component", {
                          attrs: {
                            text: _vm.$t(
                              "__.Crud.formafarmaceutica.edit_vue_html.tipCalcPadrao"
                            ),
                          },
                        }),
                        _vm._v(" "),
                        _c("combo-component", {
                          attrs: {
                            id: "tipoCalculo",
                            name: "tipoCalculo",
                            data: _vm.tiposCalculoOptions,
                            searchable: false,
                            disabled: _vm.somenteConsulta,
                          },
                          model: {
                            value: _vm.model.tipoCalculo,
                            callback: function ($$v) {
                              _vm.$set(_vm.model, "tipoCalculo", $$v)
                            },
                            expression: "model.tipoCalculo",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
                _vm._v(" "),
                _vm.model.tipoCalculo == 4
                  ? _c("div", { staticClass: "col-sm-2" }, [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: _vm.errors.first(
                                "concentracaoMaximaFitoterapico"
                              ),
                              expression:
                                "errors.first('concentracaoMaximaFitoterapico')",
                            },
                          ],
                          staticClass: "form-group",
                        },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "control-label",
                              attrs: { for: "concentracaoMaximaFitoterapico" },
                            },
                            [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(
                                    _vm.$t(
                                      "__.Crud.formafarmaceutica.edit_vue_html.conceMaxFito"
                                    )
                                  ) +
                                  "\n                            "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("decimal-com-sinal-custom-component", {
                            staticClass: "form-control",
                            attrs: {
                              signal: _vm.getSignal(
                                _vm.model.unidadeMedidaVolumeId
                              ),
                              id: "concentracaoMaximaFitoterapico",
                              name: "concentracaoMaximaFitoterapico",
                              disabled: _vm.somenteConsulta,
                            },
                            model: {
                              value: _vm.model.concentracaoMaximaFitoterapico,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.model,
                                  "concentracaoMaximaFitoterapico",
                                  $$v
                                )
                              },
                              expression:
                                "model.concentracaoMaximaFitoterapico",
                            },
                          }),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.model.tipoCalculo == 4
                  ? _c("div", { staticClass: "col-sm-2" }, [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: _vm.errors.first("concentracaoMaxima"),
                              expression: "errors.first('concentracaoMaxima')",
                            },
                          ],
                          staticClass: "form-group",
                        },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "control-label",
                              attrs: { for: "doseMaxima" },
                            },
                            [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(
                                    _vm.$t(
                                      "__.Crud.formafarmaceutica.edit_vue_html.conceMax"
                                    )
                                  ) +
                                  "\n                            "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("data-tooltip-component", {
                            attrs: {
                              text: _vm.$t(
                                "__.Crud.formafarmaceutica.edit_vue_html.conceMaxDose"
                              ),
                            },
                          }),
                          _vm._v(" "),
                          _c("decimal-com-sinal-custom-component", {
                            staticClass: "form-control",
                            attrs: {
                              signal: _vm.getSignal(
                                _vm.model.unidadeMedidaVolumeId
                              ),
                              id: "concentracaoMaxima",
                              name: "concentracaoMaxima",
                              disabled: _vm.somenteConsulta,
                            },
                            model: {
                              value: _vm.model.concentracaoMaxima,
                              callback: function ($$v) {
                                _vm.$set(_vm.model, "concentracaoMaxima", $$v)
                              },
                              expression: "model.concentracaoMaxima",
                            },
                          }),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row", attrs: { id: "formaComp" } }, [
              _c("div", [
                _c("div", { staticClass: "col-sm-4" }, [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "tooltip",
                          rawName: "v-tooltip",
                          value: _vm.errors.first("ncmSh"),
                          expression: "errors.first('ncmSh')",
                        },
                      ],
                      staticClass: "form-group",
                    },
                    [
                      _c(
                        "label",
                        {
                          staticClass: "control-label",
                          attrs: { for: "ncmSh" },
                        },
                        [
                          _vm._v(
                            "\n                                " +
                              _vm._s(
                                _vm.$t(
                                  "__.Crud.formafarmaceutica.edit_vue_html.ncmSH"
                                )
                              ) +
                              "\n                            "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("data-tooltip-component", {
                        attrs: {
                          text: _vm.$t(
                            "__.Crud.formafarmaceutica.edit_vue_html.codOitoDig"
                          ),
                        },
                      }),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.model.ncmSh,
                            expression: "model.ncmSh",
                          },
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "numeric|max:8",
                            expression: "'numeric|max:8'",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          autocomplete: "off",
                          type: "text",
                          id: "ncmSh",
                          name: "ncmSh",
                          disabled: _vm.somenteConsulta,
                        },
                        domProps: { value: _vm.model.ncmSh },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(_vm.model, "ncmSh", $event.target.value)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-sm-4" }, [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "tooltip",
                          rawName: "v-tooltip",
                          value: _vm.errors.first("validadeDias"),
                          expression: "errors.first('validadeDias')",
                        },
                      ],
                      staticClass: "form-group",
                    },
                    [
                      _c(
                        "label",
                        {
                          staticClass: "control-label",
                          attrs: { for: "validadeDias" },
                        },
                        [
                          _c("span", { staticStyle: { color: "red" } }, [
                            _vm._v("*"),
                          ]),
                          _vm._v(
                            "\n                                " +
                              _vm._s(
                                _vm.$t(
                                  "__.Crud.formafarmaceutica.edit_vue_html.valDias"
                                )
                              ) +
                              "\n                            "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.model.validadeDias,
                            expression: "model.validadeDias",
                          },
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "numeric|maior_que_zero",
                            expression: "'numeric|maior_que_zero'",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          autocomplete: "off",
                          type: "text",
                          id: "validadeDias",
                          name: "validadeDias",
                          disabled: _vm.somenteConsulta,
                        },
                        domProps: { value: _vm.model.validadeDias },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.model,
                              "validadeDias",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-sm-4" }, [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "tooltip",
                          rawName: "v-tooltip",
                          value: _vm.errors.first("tipoFormaFarmaceutica"),
                          expression: "errors.first('tipoFormaFarmaceutica')",
                        },
                      ],
                      staticClass: "form-group",
                    },
                    [
                      _c(
                        "label",
                        {
                          staticClass: "control-label",
                          attrs: {
                            for: "tipoFormaFarmaceutica",
                            title: _vm.$t("__.obrigatorio"),
                          },
                        },
                        [
                          _c("span", { staticStyle: { color: "red" } }, [
                            _vm._v("*"),
                          ]),
                          _vm._v(
                            "\n                                " +
                              _vm._s(
                                _vm.$t(
                                  "__.Crud.formafarmaceutica.edit_vue_html.tipo"
                                )
                              ) +
                              "\n                            "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("combo-component", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required",
                            expression: "'required'",
                          },
                        ],
                        attrs: {
                          id: "tipoFormaFarmaceutica",
                          name: "tipoFormaFarmaceutica",
                          data: _vm.formaFarmaceuticaTipoOptions,
                          searchable: false,
                          disabled: _vm.somenteConsulta,
                        },
                        model: {
                          value: _vm.model.tipoFormaFarmaceutica,
                          callback: function ($$v) {
                            _vm.$set(_vm.model, "tipoFormaFarmaceutica", $$v)
                          },
                          expression: "model.tipoFormaFarmaceutica",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row", attrs: { id: "formaComp2" } }, [
              _c("div", [
                _c("div", { staticClass: "col-sm-4" }, [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "tooltip",
                          rawName: "v-tooltip",
                          value: _vm.errors.first("tipoCalculoEmbalagem"),
                          expression: "errors.first('tipoCalculoEmbalagem')",
                        },
                      ],
                      staticClass: "form-group",
                    },
                    [
                      _c(
                        "label",
                        {
                          staticClass: "control-label",
                          attrs: {
                            for: "tipoCalculoEmbalagem",
                            title: _vm.$t("__.obrigatorio"),
                          },
                        },
                        [
                          _c("span", { staticStyle: { color: "red" } }, [
                            _vm._v("*"),
                          ]),
                          _vm._v(
                            "\n                                " +
                              _vm._s(
                                _vm.$t(
                                  "__.Crud.formafarmaceutica.edit_vue_html.calcEmbal"
                                )
                              ) +
                              "\n                            "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("combo-component", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required",
                            expression: "'required'",
                          },
                        ],
                        attrs: {
                          id: "tipoCalculoEmbalagem",
                          name: "tipoCalculoEmbalagem",
                          data: _vm.formaFarmaceuticaCalculoOptions,
                          searchable: false,
                          disabled: _vm.somenteConsulta,
                        },
                        model: {
                          value: _vm.model.tipoCalculoEmbalagem,
                          callback: function ($$v) {
                            _vm.$set(_vm.model, "tipoCalculoEmbalagem", $$v)
                          },
                          expression: "model.tipoCalculoEmbalagem",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-sm-4" }, [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "tooltip",
                          rawName: "v-tooltip",
                          value: _vm.errors.first("uso"),
                          expression: "errors.first('uso')",
                        },
                      ],
                      staticClass: "form-group",
                    },
                    [
                      _c(
                        "label",
                        {
                          staticClass: "control-label",
                          attrs: {
                            for: "uso",
                            title: _vm.$t("__.obrigatorio"),
                          },
                        },
                        [
                          _c("span", { staticStyle: { color: "red" } }, [
                            _vm._v("*"),
                          ]),
                          _vm._v(
                            "\n                                " +
                              _vm._s(
                                _vm.$t(
                                  "__.Crud.formafarmaceutica.edit_vue_html.uso"
                                )
                              ) +
                              "\n                            "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("combo-component", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required",
                            expression: "'required'",
                          },
                        ],
                        attrs: {
                          id: "uso",
                          name: "uso",
                          data: _vm.usoOptions,
                          searchable: false,
                          disabled: _vm.somenteConsulta,
                        },
                        model: {
                          value: _vm.model.uso,
                          callback: function ($$v) {
                            _vm.$set(_vm.model, "uso", $$v)
                          },
                          expression: "model.uso",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-sm-4" }, [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "tooltip",
                          rawName: "v-tooltip",
                          value: _vm.errors.first("tipoUso"),
                          expression: "errors.first('tipoUso')",
                        },
                      ],
                      staticClass: "form-group",
                    },
                    [
                      _c(
                        "label",
                        {
                          staticClass: "control-label",
                          attrs: {
                            for: "tipoUso",
                            title: _vm.$t("__.obrigatorio"),
                          },
                        },
                        [
                          _c("span", { staticStyle: { color: "red" } }, [
                            _vm._v("*"),
                          ]),
                          _vm._v(
                            "\n                                " +
                              _vm._s(
                                _vm.$t(
                                  "__.Crud.formafarmaceutica.edit_vue_html.tipoUso"
                                )
                              ) +
                              "\n                            "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("combo-component", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required",
                            expression: "'required'",
                          },
                        ],
                        attrs: {
                          id: "tipoUso",
                          name: "tipoUso",
                          data: _vm.tiposUsoOptions,
                          searchable: false,
                          disabled: _vm.somenteConsulta,
                        },
                        model: {
                          value: _vm.model.tipoUso,
                          callback: function ($$v) {
                            _vm.$set(_vm.model, "tipoUso", $$v)
                          },
                          expression: "model.tipoUso",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-sm-4" }, [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip",
                        value: _vm.errors.first("quantidadeMinima"),
                        expression: "errors.first('quantidadeMinima')",
                      },
                    ],
                    staticClass: "form-group",
                  },
                  [
                    _c(
                      "label",
                      {
                        staticClass: "control-label",
                        attrs: { for: "quantidadeMinima" },
                      },
                      [
                        _vm._v(
                          "\n                            " +
                            _vm._s(
                              _vm.$t(
                                "__.Crud.formafarmaceutica.edit_vue_html.qntMin"
                              )
                            ) +
                            "\n                        "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.model.quantidadeMinima,
                          expression: "model.quantidadeMinima",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: {
                        autocomplete: "off",
                        type: "number",
                        id: "quantidadeMinima",
                        name: "quantidadeMinima",
                        disabled: _vm.somenteConsulta,
                      },
                      domProps: { value: _vm.model.quantidadeMinima },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.model,
                            "quantidadeMinima",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  class:
                    _vm.model.tipoFormaFarmaceutica == 0
                      ? "col-sm-2"
                      : "col-sm-4",
                },
                [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "tooltip",
                          rawName: "v-tooltip",
                          value: _vm.errors.first("quantidadeMaxima"),
                          expression: "errors.first('quantidadeMaxima')",
                        },
                      ],
                      staticClass: "form-group",
                    },
                    [
                      _c(
                        "label",
                        {
                          staticClass: "control-label",
                          attrs: { for: "quantidadeMaxima" },
                        },
                        [
                          _vm._v(
                            "\n                            " +
                              _vm._s(
                                _vm.$t(
                                  "__.Crud.formafarmaceutica.edit_vue_html.qntMax"
                                )
                              ) +
                              "\n                        "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.model.quantidadeMaxima,
                            expression: "model.quantidadeMaxima",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          autocomplete: "off",
                          type: "number",
                          id: "quantidadeMaxima",
                          name: "quantidadeMaxima",
                          disabled: _vm.somenteConsulta,
                        },
                        domProps: { value: _vm.model.quantidadeMaxima },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.model,
                              "quantidadeMaxima",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]
                  ),
                ]
              ),
              _vm._v(" "),
              _vm.model.tipoFormaFarmaceutica == 0
                ? _c("div", { staticClass: "col-sm-2" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("tipoCapsula"),
                            expression: "errors.first('tipoCapsula')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "tipoCapsula" },
                          },
                          [
                            _vm._v(
                              "\n                            " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Crud.fornecedor.edit_vue_html.tipo"
                                  )
                                ) +
                                "\n                        "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("combo-component", {
                          attrs: {
                            id: "tipoCapsula",
                            name: "tipoCapsula",
                            data: _vm.tipoCapsulaOptions,
                            searchable: false,
                            disabled: _vm.somenteConsulta,
                          },
                          model: {
                            value: _vm.model.tipoCapsula,
                            callback: function ($$v) {
                              _vm.$set(_vm.model, "tipoCapsula", $$v)
                            },
                            expression: "model.tipoCapsula",
                          },
                        }),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "col-sm-4" }, [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip",
                        value: _vm.errors.first("unidadeMedidaVolume"),
                        expression: "errors.first('unidadeMedidaVolume')",
                      },
                    ],
                    staticClass: "form-group",
                  },
                  [
                    _c(
                      "label",
                      {
                        staticClass: "control-label",
                        attrs: { for: "unidadeMedidaVolume" },
                      },
                      [
                        _vm._v(
                          "\n                            " +
                            _vm._s(
                              _vm.$t(
                                "__.Crud.formafarmaceutica.edit_vue_html.unMedVol"
                              )
                            ) +
                            "\n                        "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("combo-component", {
                      attrs: {
                        id: "unidadeMedidaVolume",
                        name: "unidadeMedidaVolume",
                        data: _vm.unidadeMedidaVolumeOptions.filter(function (
                          p
                        ) {
                          return p["type"] < 3
                        }),
                        refresh: _vm.loadUnidadeMedidaVolume,
                        searchable: false,
                        disabled: _vm.somenteConsulta,
                      },
                      model: {
                        value: _vm.model.unidadeMedidaVolumeId,
                        callback: function ($$v) {
                          _vm.$set(_vm.model, "unidadeMedidaVolumeId", $$v)
                        },
                        expression: "model.unidadeMedidaVolumeId",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-sm-6" }, [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip",
                        value: _vm.errors.first(
                          "procedimentoOperacionalPadrao"
                        ),
                        expression:
                          "errors.first('procedimentoOperacionalPadrao')",
                      },
                    ],
                    staticClass: "form-group",
                  },
                  [
                    _c(
                      "label",
                      {
                        staticClass: "control-label",
                        attrs: { for: "procedimentoOperacionalPadrao" },
                      },
                      [
                        _vm._v(
                          "\n                            " +
                            _vm._s(
                              _vm.$t(
                                "__.Crud.formafarmaceutica.edit_vue_html.pop"
                              )
                            ) +
                            "\n                        "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("data-tooltip-component", {
                      attrs: {
                        text: _vm.$t(
                          "__.Crud.formafarmaceutica.edit_vue_html.descriDetalhProc"
                        ),
                      },
                    }),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.model.procedimentoOperacionalPadrao,
                          expression: "model.procedimentoOperacionalPadrao",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: {
                        autocomplete: "off",
                        type: "text",
                        id: "procedimentoOperacionalPadrao",
                        name: "procedimentoOperacionalPadrao",
                        disabled: _vm.somenteConsulta,
                      },
                      domProps: {
                        value: _vm.model.procedimentoOperacionalPadrao,
                      },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.model,
                            "procedimentoOperacionalPadrao",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-sm-2" }, [
                _c(
                  "div",
                  {
                    directives: [{ name: "tooltip", rawName: "v-tooltip" }],
                    staticClass: "form-group",
                  },
                  [
                    _c(
                      "label",
                      {
                        staticClass: "control-label",
                        attrs: { for: "ocultarExcipienteRotulo" },
                      },
                      [
                        _vm._v(
                          "\n                            " +
                            _vm._s(
                              _vm.$t(
                                "__.Crud.formafarmaceutica.edit_vue_html.ocultarExcipienteRotulo"
                              )
                            ) +
                            "\n                        "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("data-tooltip-component", {
                      attrs: {
                        text: _vm.$t(
                          "__.Crud.formafarmaceutica.edit_vue_html.ocultarExcipienteRotulo"
                        ),
                      },
                    }),
                    _vm._v(" "),
                    _c("checkbox-component", {
                      attrs: {
                        id: "ocultarExcipienteRotulo",
                        name: "ocultarExcipienteRotulo",
                        disabled: _vm.somenteConsulta,
                      },
                      model: {
                        value: _vm.model.ocultarExcipienteRotulo,
                        callback: function ($$v) {
                          _vm.$set(_vm.model, "ocultarExcipienteRotulo", $$v)
                        },
                        expression: "model.ocultarExcipienteRotulo",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-sm-2" }, [
                _c(
                  "div",
                  {
                    directives: [{ name: "tooltip", rawName: "v-tooltip" }],
                    staticClass: "form-group",
                  },
                  [
                    _c(
                      "label",
                      {
                        staticClass: "control-label",
                        attrs: { for: "ocultarFlavorizanteRotuno" },
                      },
                      [
                        _vm._v(
                          "\n                            " +
                            _vm._s(
                              _vm.$t(
                                "__.Crud.formafarmaceutica.edit_vue_html.ocultarFlavorizanteRotuno"
                              )
                            ) +
                            "\n                        "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("checkbox-component", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required",
                          expression: "'required'",
                        },
                      ],
                      attrs: {
                        id: "ocultarFlavorizanteRotuno",
                        name: "ocultarFlavorizanteRotuno",
                        disabled: _vm.somenteConsulta,
                      },
                      model: {
                        value: _vm.model.ocultarFlavorizanteRotulo,
                        callback: function ($$v) {
                          _vm.$set(_vm.model, "ocultarFlavorizanteRotulo", $$v)
                        },
                        expression: "model.ocultarFlavorizanteRotulo",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-sm-2" }, [
                _c(
                  "div",
                  {
                    directives: [{ name: "tooltip", rawName: "v-tooltip" }],
                    staticClass: "form-group",
                  },
                  [
                    _c(
                      "label",
                      {
                        staticClass: "control-label",
                        attrs: { for: "exibirPrefixoDoseRotulo" },
                      },
                      [
                        _vm._v(
                          "\n                            " +
                            _vm._s(
                              _vm.$t(
                                "__.Crud.formafarmaceutica.edit_vue_html.exibirPrefixoDoseRotulo"
                              )
                            ) +
                            "\n                        "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("data-tooltip-component", {
                      attrs: {
                        text: _vm.$t(
                          "__.Crud.formafarmaceutica.edit_vue_html.exibirPrefixoDoseRotulo"
                        ),
                      },
                    }),
                    _vm._v(" "),
                    _c("checkbox-component", {
                      attrs: {
                        id: "exibirPrefixoDoseRotulo",
                        name: "exibirPrefixoDoseRotulo",
                        disabled: _vm.somenteConsulta,
                      },
                      model: {
                        value: _vm.model.exibirPrefixoDoseRotulo,
                        callback: function ($$v) {
                          _vm.$set(_vm.model, "exibirPrefixoDoseRotulo", $$v)
                        },
                        expression: "model.exibirPrefixoDoseRotulo",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-sm-3" }, [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip",
                        value: _vm.errors.first("valorMinimo"),
                        expression: "errors.first('valorMinimo')",
                      },
                    ],
                    staticClass: "form-group",
                  },
                  [
                    _c(
                      "label",
                      {
                        staticClass: "control-label",
                        attrs: { for: "valorMinimo" },
                      },
                      [
                        _vm._v(
                          "\n                            " +
                            _vm._s(
                              _vm.$t(
                                "__.Crud.formafarmaceutica.edit_vue_html.vlrMin"
                              )
                            ) +
                            "\n                        "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("moeda-component", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required",
                          expression: "'required'",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: {
                        id: "valorMinimo",
                        name: "valorMinimo",
                        disabled: _vm.somenteConsulta,
                      },
                      model: {
                        value: _vm.model.valorMinimo,
                        callback: function ($$v) {
                          _vm.$set(_vm.model, "valorMinimo", $$v)
                        },
                        expression: "model.valorMinimo",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-sm-3" }, [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip",
                        value: _vm.errors.first("valorAdicional"),
                        expression: "errors.first('valorAdicional')",
                      },
                    ],
                    staticClass: "form-group",
                  },
                  [
                    _c(
                      "label",
                      {
                        staticClass: "control-label",
                        attrs: { for: "valorAdicional" },
                      },
                      [
                        _vm._v(
                          "\n                            " +
                            _vm._s(
                              _vm.$t(
                                "__.Crud.formafarmaceutica.edit_vue_html.vlrAdd"
                              )
                            ) +
                            "\n                        "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("moeda-component", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required",
                          expression: "'required'",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: {
                        id: "valorAdicional",
                        name: "valorAdicional",
                        disabled: _vm.somenteConsulta,
                      },
                      model: {
                        value: _vm.model.valorAdicional,
                        callback: function ($$v) {
                          _vm.$set(_vm.model, "valorAdicional", $$v)
                        },
                        expression: "model.valorAdicional",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-sm-2" }, [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip",
                        value: _vm.errors.first("fatorMultiplicadorAdicional"),
                        expression:
                          "errors.first('fatorMultiplicadorAdicional')",
                      },
                    ],
                    staticClass: "form-group",
                  },
                  [
                    _c(
                      "label",
                      {
                        staticClass: "control-label",
                        attrs: { for: "fatorMultiplicadorAdicional" },
                      },
                      [
                        _vm._v(
                          "\n                            " +
                            _vm._s(
                              _vm.$t(
                                "__.Crud.formafarmaceutica.edit_vue_html.ftrMultQtd"
                              )
                            ) +
                            "\n                        "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("decimal-component", {
                      staticClass: "form-control",
                      attrs: {
                        title: _vm.$t(
                          "__.Crud.formafarmaceutica.edit_vue_html.ftrAddVlrForm"
                        ),
                        id: "fatorMultiplicadorAdicional",
                        name: "fatorMultiplicadorAdicional",
                        disabled: _vm.somenteConsulta,
                      },
                      model: {
                        value: _vm.model.fatorMultiplicadorAdicional,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.model,
                            "fatorMultiplicadorAdicional",
                            $$v
                          )
                        },
                        expression: "model.fatorMultiplicadorAdicional",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-sm-2" }, [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip",
                        value: _vm.errors.first("fatorCompensacao"),
                        expression: "errors.first('fatorCompensacao')",
                      },
                    ],
                    staticClass: "form-group",
                  },
                  [
                    _c(
                      "label",
                      {
                        staticClass: "control-label",
                        attrs: { for: "fatorCompensacao" },
                      },
                      [
                        _vm._v(
                          "\n                            " +
                            _vm._s(
                              _vm.$t(
                                "__.Crud.formafarmaceutica.edit_vue_html.ftrCompensacao"
                              )
                            ) +
                            "\n                        "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("decimal-component", {
                      staticClass: "form-control",
                      attrs: {
                        title: _vm.$t(
                          "__.Crud.formafarmaceutica.edit_vue_html.ftrSomaMult"
                        ),
                        id: "fatorCompensacao",
                        name: "fatorCompensacao",
                        disabled: _vm.somenteConsulta,
                      },
                      model: {
                        value: _vm.model.fatorCompensacao,
                        callback: function ($$v) {
                          _vm.$set(_vm.model, "fatorCompensacao", $$v)
                        },
                        expression: "model.fatorCompensacao",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-sm-2" }, [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip",
                        value: _vm.errors.first("fatorDecaimento"),
                        expression: "errors.first('fatorDecaimento')",
                      },
                    ],
                    staticClass: "form-group",
                  },
                  [
                    _c(
                      "label",
                      {
                        staticClass: "control-label",
                        attrs: { for: "fatorDecaimento" },
                      },
                      [
                        _vm._v(
                          "\n                            " +
                            _vm._s(
                              _vm.$t(
                                "__.Crud.formafarmaceutica.edit_vue_html.ftrMultDecaimento"
                              )
                            ) +
                            "\n                        "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("decimal-component", {
                      staticClass: "form-control",
                      attrs: {
                        title: _vm.$t(
                          "__.Crud.formafarmaceutica.edit_vue_html.ftrCriaTaxDecaimento"
                        ),
                        id: "fatorDecaimento",
                        name: "fatorDecaimento",
                        disabled: _vm.somenteConsulta,
                      },
                      model: {
                        value: _vm.model.fatorDecaimento,
                        callback: function ($$v) {
                          _vm.$set(_vm.model, "fatorDecaimento", $$v)
                        },
                        expression: "model.fatorDecaimento",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _vm.model.franquiaId
                ? _c("div", { staticClass: "col-sm-6" }, [
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "configuracaoRotuloId" },
                          },
                          [
                            _vm._v(
                              "\n                            " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Crud.grupoproduto.edit_vue_html.rotuloPadrao"
                                  )
                                ) +
                                "\n                        "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("combo-component", {
                          attrs: {
                            id: "configuracaoRotuloId",
                            name: "configuracaoRotuloId",
                            data: _vm.rotulosManipulacaoOptions,
                            searchable: true,
                            disabled: _vm.somenteConsulta,
                          },
                          model: {
                            value: _vm.model.configuracaoRotuloId,
                            callback: function ($$v) {
                              _vm.$set(_vm.model, "configuracaoRotuloId", $$v)
                            },
                            expression: "model.configuracaoRotuloId",
                          },
                        }),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-sm-12" }, [
                _c("fieldset", [
                  _c("div", { attrs: { slot: "rows" }, slot: "rows" }, [
                    _c(
                      "div",
                      { staticClass: "col-sm-12" },
                      [
                        _c("grafico-component", {
                          ref: "graficoComponent",
                          attrs: {
                            valorInicial: _vm.model.valorAdicional,
                            fatorDecaimento: _vm.model.fatorDecaimento,
                            minimoMultiplicador:
                              _vm.model.fatorMultiplicadorAdicional,
                            fatorCompensacao: _vm.model.fatorCompensacao,
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
            ]),
          ]),
          _vm._v(" "),
          !_vm.somenteConsulta
            ? _c("fieldset", [
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-sm-12" },
                    [
                      _c("forma-item-component", {
                        ref: "formaItemComponent",
                        on: { "inclusao-item": _vm.onInclusaoItem },
                      }),
                    ],
                    1
                  ),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "fieldset-component",
            {
              attrs: {
                title: _vm.$t(
                  "__.Crud.formafarmaceutica.edit_vue_html.itensAdd"
                ),
                collapsed: false,
                validate: false,
              },
            },
            [
              _c(
                "div",
                { attrs: { slot: "rows" }, slot: "rows" },
                [
                  _c("grid-component", {
                    attrs: {
                      data: _vm.model.itensPadroes,
                      columns: _vm.gridColumns,
                      "show-table-head": false,
                      "show-table-foot": false,
                      "show-edit-item": false,
                      "show-sort-icons": false,
                      showActionColumn: !_vm.somenteConsulta,
                    },
                    on: { "remove-item": _vm.onRemoveItem },
                  }),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c("fieldset", [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-sm-12 newbtn" }, [
                _c("div", { staticClass: "form-group" }, [
                  !_vm.somenteConsulta
                    ? _c(
                        "button",
                        {
                          staticClass: "btn btn-success",
                          attrs: { type: "submit" },
                        },
                        [
                          _c("i", {
                            staticClass: "fa fa-save",
                            attrs: { "aria-hidden": "true" },
                          }),
                          _vm._v(
                            "\n                            " +
                              _vm._s(_vm.$t("__.Crud.salvar")) +
                              "\n                        "
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.somenteConsulta
                    ? _c(
                        "button",
                        {
                          staticClass: "btn btn-secondary",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              _vm.load()
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n                            " +
                              _vm._s(_vm.$t("__.Crud.limpar")) +
                              "\n                        "
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-secondary",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          _vm.cancel()
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n                            " +
                          _vm._s(_vm.$t("__.Crud.voltar")) +
                          "\n                        "
                      ),
                    ]
                  ),
                ]),
              ]),
            ]),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _vm.validarVisibilidadeBotoes("replicar")
        ? [
            _c("replicar-selecao-franquia-component", {
              ref: "replicarSelecaoFranquiaComponent",
              on: {
                replicar: function (p) {
                  return _vm.onConfirmReplicar(p).withLoading()
                },
              },
            }),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }