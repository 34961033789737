import enUs from "./en-us";
import es from "./es";
import ptBr from "./pt-br";

const messages = {
    "en-us": enUs,
    "pt-br": ptBr,
    es: es,
};

export default messages;
