var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "crud crud-list" },
    [
      _c("div", { staticClass: "info-div" }, [
        _c("h1", { staticClass: "title" }, [
          _vm._v(_vm._s(_vm.$t("__.Crud.coleta.list_vue_html.coletas"))),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "content" }, [
          _c("div", { staticClass: "info-panel" }, [
            _c("span", [_vm._v(_vm._s(_vm.total))]),
            _vm._v(" "),
            _c("span", [
              _vm._v(_vm._s(_vm.$t("__.Crud.coleta.list_vue_html.coletas"))),
            ]),
            _vm._v(" "),
            _c("span", [
              _vm._v(
                _vm._s(_vm.$t("__.Crud.coleta.list_vue_html.cadastradas"))
              ),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "grid-component",
        {
          ref: "gridcomponent",
          attrs: {
            actionName: "coleta",
            columns: _vm.gridColumns,
            data: _vm.gridData,
            extraActions: _vm.extraActions,
            "page-index": _vm.pageIndex,
            "page-size": _vm.pageSize,
            startFilterKey: _vm.startFilterKey,
            total: _vm.total,
          },
          on: {
            "change-filter-key": _vm.onChangeFilterKey,
            "change-page": _vm.onChangePage,
            "change-sort": _vm.onChangeSort,
            "create-item": _vm.onCreateItem,
            "edit-item": _vm.onEditItem,
            "extra-action": _vm.onExtraAction,
            "gerar-impressao": function ($event) {
              _vm.pags = true
              _vm.tipoDocGerar = 2
            },
            "gerar-pdf": function ($event) {
              _vm.pags = true
              _vm.tipoDocGerar = 1
            },
            "gerar-xls": function ($event) {
              _vm.pags = true
              _vm.tipoDocGerar = 0
            },
            "remove-item": _vm.onRemoveItem,
          },
        },
        [
          _c("div", { attrs: { slot: "header" }, slot: "header" }, [
            _c("div", { staticClass: "flex-row" }, [
              _c("div", { staticClass: "filtro-lista" }, [
                _c(
                  "button",
                  {
                    staticClass: "filtro-avancado-button",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        _vm.isHidden = !_vm.isHidden
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n                        " +
                        _vm._s(_vm.$t("__.Crud.filtroAvancado")) +
                        "\n                        "
                    ),
                    _c("i", { staticClass: "fa fa-caret-down" }),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.isHidden,
                        expression: "isHidden",
                      },
                    ],
                    staticClass: "drop-lista",
                  },
                  [
                    _c(
                      "form",
                      {
                        attrs: { novalidate: "novalidate" },
                        on: {
                          submit: function ($event) {
                            $event.preventDefault()
                            return _vm.getFormValues($event)
                          },
                        },
                      },
                      [
                        _c(
                          "div",
                          { staticStyle: { "margin-bottom": "15px" } },
                          [
                            _c(
                              "label",
                              {
                                staticClass: "control-label",
                                attrs: { for: "de" },
                              },
                              [
                                _vm._v(
                                  "\n                                    " +
                                    _vm._s(
                                      _vm.$t(
                                        "__.Components.parent.venda.listOrcamento_vue_html.de"
                                      )
                                    ) +
                                    "\n                                "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("date-time-picker-component", {
                              staticStyle: { width: "100%" },
                              attrs: {
                                pickTime: true,
                                id: "dataFilter1",
                                name: "dataFilter1",
                              },
                              model: {
                                value: _vm.dataFilter1,
                                callback: function ($$v) {
                                  _vm.dataFilter1 = $$v
                                },
                                expression: "dataFilter1",
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "label",
                              {
                                staticClass: "control-label",
                                attrs: { for: "ate" },
                              },
                              [
                                _vm._v(
                                  "\n                                    " +
                                    _vm._s(
                                      _vm.$t(
                                        "__.Components.parent.venda.listOrcamento_vue_html.ate"
                                      )
                                    ) +
                                    "\n                                "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("date-time-picker-component", {
                              attrs: {
                                pickTime: true,
                                id: "dataFilter2",
                                name: "dataFilter2",
                              },
                              model: {
                                value: _vm.dataFilter2,
                                callback: function ($$v) {
                                  _vm.dataFilter2 = $$v
                                },
                                expression: "dataFilter2",
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "label",
                              {
                                staticClass: "control-label",
                                staticStyle: { "margin-top": "5px" },
                                attrs: { for: "status" },
                              },
                              [
                                _vm._v(
                                  "\n                                    " +
                                    _vm._s(
                                      _vm.$t(
                                        "__.Crud.controleReclamacao.edit_vue_html.status"
                                      )
                                    ) +
                                    "\n                                "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("combo-component", {
                              staticStyle: { background: "white" },
                              attrs: {
                                id: "status",
                                name: "status",
                                data: _vm.statusFiltroOptions,
                              },
                              model: {
                                value: _vm.status,
                                callback: function ($$v) {
                                  _vm.status = $$v
                                },
                                expression: "status",
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "label",
                              {
                                staticClass: "control-label",
                                staticStyle: { "margin-top": "5px" },
                                attrs: { for: "localEntregaId" },
                              },
                              [
                                _vm._v(
                                  "\n                                    " +
                                    _vm._s(
                                      _vm.$t(
                                        "__.Components.parent.venda.editSingle_vue_html.localEntrega"
                                      )
                                    ) +
                                    "\n                                "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("combo-component", {
                              attrs: {
                                id: "localEntregaId",
                                name: "localEntregaId",
                                data: _vm.locaisEntregaOptions,
                                searchable: true,
                                customView: true,
                              },
                              model: {
                                value: _vm.localEntrega,
                                callback: function ($$v) {
                                  _vm.localEntrega = $$v
                                },
                                expression: "localEntrega",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "form-group" }, [
                          _c(
                            "button",
                            {
                              staticClass: "botao-filtrar",
                              attrs: { type: "submit" },
                            },
                            [
                              _vm._v(
                                "\n                                    " +
                                  _vm._s(_vm.$t("__.Crud.filtrar")) +
                                  "\n                                "
                              ),
                            ]
                          ),
                        ]),
                      ]
                    ),
                  ]
                ),
              ]),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _c("impressaoComponent", { ref: "impressaoComponent" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }