import Vue from "vue";
import { mapGetters } from "vuex";

import { Component, Watch } from "@/decorators";
import { Getters, SessionActions } from "@/store/store";
import Bus from "@/utils/events/bus";

import loadMenus, { MenuItem } from "./navmenuitens";

import "./navmenu.scss";

@Component({
    computed: mapGetters(["VALIDAR_PERMISSAO_USUARIO"] as Getters),
})
export default class NavMenuComponent extends Vue {
    // State computed props
    VALIDAR_PERMISSAO_USUARIO: (telaDescricao: string, acaoController: string) => boolean;

    isVisible = false;
    selectedMainMenuItem = "";
    selectedSubMenuItem = "";
    mainMenuItens: Array<MenuItem> = [];
    groupedSubMenuItens: any = {};

    private showMenuLevelOneItens(menu: string) {
        if (this.selectedMainMenuItem == menu) {
            this.selectedMainMenuItem = "";
        } else {
            this.selectedMainMenuItem = menu;
        }
    }

    private showGroupedMenuItens(item) {
        this.groupedSubMenuItens[item] = !this.groupedSubMenuItens[item];
        this.$forceUpdate();
    }

    private selectMenu(item: string) {
        this.selectedSubMenuItem = item;
    }

    private handleResize() {
        this.isVisible = window.innerWidth >= 768;
    }

    @Watch("$route")
    private function() {
        if (this.$route.path == "/") {
            this.selectedMainMenuItem = "";
        }

        if (window.innerWidth < 768) {
            this.isVisible = false;
        }
    }

    private created() {
        this.handleResize();
        window.addEventListener("resize", this.handleResize);
    }

    @Watch("isVisible")
    private onVisibleChanged() {
        Bus.$emit("nav-menu-change", this.isVisible);
    }

    private async beforeMount() {
        await this.$store.dispatch(SessionActions.LOAD_USUARIO_LOGADO, true);

        this.mainMenuItens = await loadMenus({ VALIDAR_PERMISSAO_USUARIO: this.VALIDAR_PERMISSAO_USUARIO });

        for (const menuItem of this.mainMenuItens) {
            for (const subMenuItem of menuItem.itens) {
                this.groupedSubMenuItens[subMenuItem.name] = false;
            }
        }
    }

    private mounted() {
        Bus.$on("hide-nav-menu", (force = false) => {
            if (force || window.innerWidth < 768) {
                this.isVisible = false;
            }
        });

        Bus.$on("show-nav-menu", (force = false) => {
            if (force || window.innerWidth > 768) {
                this.isVisible = true;
            }
        });

        Bus.$on("toggle-nav-menu", () => {
            this.isVisible = !this.isVisible;
        });
    }

    private beforeDestroy() {
        window.removeEventListener("resize", () => {});
    }
}
