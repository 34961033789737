import { mapGetters, mapMutations } from "vuex";

import actionBarComponent from "@/components/child/actionBar/actionBar.vue";
import buttonIncluirComponent from "@/components/child/form/button/buttonIncluir.vue";
import cepFieldComponent from "@/components/child/form/cepfield.vue";
import checkboxComponent from "@/components/child/form/checkbox.vue";
import comboComponent from "@/components/child/form/combo.vue";
import dateTimePickerComponent from "@/components/child/form/datetimepicker.vue";
import decimalComponent from "@/components/child/form/decimal.vue";
import decimalComSinalCustomComponent from "@/components/child/form/decimalComSinalCustom.vue";
import fieldsetComponent from "@/components/child/form/fieldset.vue";
import moedaComponent from "@/components/child/form/moeda.vue";
import textareaComponent from "@/components/child/form/textarea.vue";
import gridComponent from "@/components/child/grid/grid.vue";
import { GridColumn, GridColumnType } from "@/components/child/grid/gridColumn";
import infoBagdeComponent from "@/components/child/infoBagde/infoBagde.vue";
import logEntidadeComponent from "@/components/child/logEntidade/logEntidade.vue";
import { Component, Prop } from "@/decorators";
import BeneficioModel from "@/models/beneficio/beneficioModel";
import CepModel from "@/models/cepModel";
import ComissaoPrescritorFaixaModel from "@/models/comissaoPrescritorFaixaModel";
import EnumExtensions from "@/models/enum/extensions/enumExtensions";
import GenerosPessoa from "@/models/enum/generosPessoa";
import CurvaABC from "@/models/enum/produto/curvaABC";
import TipoConselhoRegional from "@/models/enum/tipoConselhoRegional";
import Ufs from "@/models/enum/ufs";
import EspecialidadeModel from "@/models/especialidadeModel";
import PaginationModel from "@/models/paginationModel";
import PrescritorEspecialidadeModel from "@/models/prescritorEspecialidadeModel";
import PrescritorModel from "@/models/prescritorModel";
import BeneficioService from "@/services/beneficioService";
import EspecialidadeService from "@/services/especialidadeService";
import CidadeService from "@/services/external/cidadeService";
import PrescritorService from "@/services/prescritorService";
import { Getters } from "@/store/store";
import { editDateWithTime } from "@/utils/common/date";

import EditComponentBase from "../editComponentBase";
import ReplicarSelecaoFranquiaFiliaisComponent from "../replicarSelecaoFranquiaFiliais/edit";
import replicarSelecaoFranquiaFiliaisComponent from "../replicarSelecaoFranquiaFiliais/edit.vue";

import "../crud.scss";
import "./edit.scss";

@Component({
    components: {
        textareaComponent,
        checkboxComponent,
        comboComponent,
        fieldsetComponent,
        decimalComponent,
        cepFieldComponent,
        dateTimePickerComponent,
        gridComponent,
        replicarSelecaoFranquiaFiliaisComponent,
        decimalComSinalCustomComponent,
        moedaComponent,
        buttonIncluirComponent,
        logEntidadeComponent,
        actionBarComponent,
        infoBagdeComponent,
    },
    computed: {
        infoBagdeText() {
            return "Data criação: " + editDateWithTime(this.model.dataCriacao);
        },
        infoBagdeTextUsuario() {
            return this.model.usuarioCriacaoNome ? "Usuário criação: " + this.model.usuarioCriacaoNome : "";
        },
        ...mapGetters(["VALIDAR_PERMISSAO_USUARIO"] as Getters),
    },
    methods: mapMutations(["LOAD_LIST"]),
})
export default class PrescritorEditComponent extends EditComponentBase<PrescritorModel> {
    // State computed props
    VALIDAR_PERMISSAO_USUARIO: (telaDescricao: string, acaoController: string) => boolean;
    infoBagdeText: string;

    private cidadeService = new CidadeService();
    private prescritorService = new PrescritorService();
    private replicarSelecaoFranquiaFiliaisComponent: ReplicarSelecaoFranquiaFiliaisComponent = null;

    private async beforeMount() {
        await this.setProps(new PrescritorService(), "prescritores", new PrescritorModel(), this.CalledByShortCut);

        await this.loadEspecialidades().withLoading();
        await this.loadBeneficios().withLoading();

        if (this.CalledByShortCut) {
            this.model = new PrescritorModel();
        }
    }

    visitadoresNomes: string = null;

    @Prop({ type: Boolean, default: false }) CalledByShortCut: boolean;

    generosPessoaOptions: Array<Object> = [
        { text: "Masculino", value: GenerosPessoa.Masculino },
        { text: "Feminino", value: GenerosPessoa.Feminino },
    ];

    curvaABCOptions: Array<Object> = [
        { text: "A", value: CurvaABC.A },
        { text: "B", value: CurvaABC.B },
        { text: "C", value: CurvaABC.C },
    ];

    get gridColumns(): Array<GridColumn> {
        return [new GridColumn("especialidadeDescricao", "Descri\u00e7\u00e3o", GridColumnType.String)];
    }

    gridFaixaColums: Array<GridColumn> = [
        new GridColumn("inicioFaixa", "Faixa Inicio", GridColumnType.Money),
        new GridColumn("fimFaixa", "Faixa Fim", GridColumnType.Money),
        new GridColumn("porcentagemComissao", "Porcentagem Comissão", GridColumnType.Percent),
    ];

    faixaComissao = new ComissaoPrescritorFaixaModel();

    dadosCep: CepModel = new CepModel();
    ufOptions: Array<Object> = EnumExtensions.getNamesAndValuesOrderedByNames(Ufs);
    cidadesOptions: Array<Object> = [];
    conselhosRegionaisOptions: Array<Object> = EnumExtensions.getNamesAndValuesOrderedByNames(TipoConselhoRegional);
    especialidadesOptions: Array<Object> = [];
    beneficiosOptions: Array<Object> = [];

    public async onPreLoadModel() {
        this.visitadoresNomes = null;
        return true;
    }

    public async onLoadModel(model: PrescritorModel) {
        await this.onLoadCidades(model.estadoId);

        if (this.model.visitadores.length > 0) {
            this.visitadoresNomes = this.model.visitadores.map(p => p.visitador.nome).join(", ");
        }
    }

    private async loadEspecialidades() {
        try {
            const data = await new EspecialidadeService()
                .combo()
                .resolveWithJSON<PaginationModel<EspecialidadeModel>>();
            this.especialidadesOptions = data.list.map(item => ({ value: item.id, text: item.descricao }));
        } catch {}
    }

    private onInclusaoEspecialidade() {
        const especialidadeComponent = this.$el.querySelector("#especialidadeId") as HTMLSelectElement;

        if (this.model.especialidadeId > 0) {
            const alreadyExists = this.model.especialidades.filter(p => p.id == this.model.especialidadeId).length > 0;

            if (!alreadyExists) {
                const prescritorEspecialidadeModel = new PrescritorEspecialidadeModel();
                prescritorEspecialidadeModel.especialidadeId = this.model.especialidadeId;
                prescritorEspecialidadeModel.especialidadeDescricao = especialidadeComponent.innerText;

                this.model.especialidades.push(prescritorEspecialidadeModel);

                this.model.especialidadeId = -1;
            }
        }
    }

    private validarVisibilidadeBotoes(botao: string): boolean {
        if (botao == "replicar-filiais") {
            return (
                this.model.id > 0 &&
                this.usuarioLogado.franquia.isFranquiaMatrizOrFilial &&
                this.VALIDAR_PERMISSAO_USUARIO("franquias", "ReplicarItensFiliais")
            );
        }
    }

    private onReplicarFiliais() {
        this.replicarSelecaoFranquiaFiliaisComponent = this.$refs
            .replicarSelecaoFranquiaFiliaisComponent as ReplicarSelecaoFranquiaFiliaisComponent;
        this.replicarSelecaoFranquiaFiliaisComponent.openFranquias();
    }

    private async onConfirmReplicar(franquiasIds: Array<number>) {
        try {
            const sucesso = await this.prescritorService
                .replicarPrescritor(this.model.id, franquiasIds)
                .withLoading()
                .resolveWithoutJSON();
            if (sucesso) {
                this["LOAD_LIST"]({ loadProdutos: true });
                this.$showSuccess("Replicou", this.$t("__.ts.regisSalvosSucess"));
            }
        } catch {}
    }

    private onRemoveItem(model: PrescritorEspecialidadeModel) {
        this.model.especialidades = this.model.especialidades.filter(p => p != model);
    }

    private async onCepChanged(data: CepModel) {
        this.dadosCep = data;
        this.model.bairro = data.bairro;
        this.model.complemento = data.complemento;
        this.model.endereco = data.endereco;
        this.model.estadoId = data.uf;
        await this.onLoadCidades(this.model.estadoId);
        this.model.cidadeId = data.cidadeId;
    }

    private onRemoveFaixa(item) {
        const index = this.model.comissoesFaixa.indexOf(item);
        this.model.comissoesFaixa.splice(index, 1);
    }

    private async loadBeneficios() {
        try {
            const data = await new BeneficioService().combo().resolveWithJSON<PaginationModel<BeneficioModel>>();
            this.beneficiosOptions = data.list.map(item => ({
                value: item.id,
                text: item.descricao,
            }));
        } catch {}
    }

    public atualizarBeneficios() {
        this.loadBeneficios();
    }

    private async onAddFaixa() {
        if (this.faixaComissao.inicioFaixa < this.faixaComissao.fimFaixa) {
            const model = new ComissaoPrescritorFaixaModel();
            model.updateFrom(this.faixaComissao);
            this.faixaComissao = new ComissaoPrescritorFaixaModel();
            this.model.comissoesFaixa.push(model);
        } else {
            this.$showWarning(this.$t("__.ts.atencao"), this.$t("__.ts.faixaFinalMaior"));
        }
    }

    private onCepInvalid() {
        this.$showInfo(this.$t("__.ts.pesqCEP"), this.$t("__.ts.pesqNaoEncResul"));
    }

    private async onLoadCidades(estadoId: number) {
        try {
            this.cidadesOptions = [];
            if (estadoId) {
                this.cidadesOptions = await this.cidadeService.get(estadoId).withLoading();
            }
        } catch {}
    }
}
