import BaseModel from "./base/baseModel";
import ClienteEmailNotaFiscalModel from "./clienteEmailNotaFiscalModel";
import EnderecoEntregaModel from "./clienteEnderecoEntregaModel";
import GenerosPessoa from "./enum/generosPessoa";
import TiposPessoa from "./enum/tiposPessoa";
import Ufs from "./enum/ufs";
import PacienteModel from "./paciente/pacienteModel";

export default class ClienteModel extends BaseModel {
    constructor() {
        super();

        this.enderecosEntrega = new Array<EnderecoEntregaModel>();
    }

    id?: number = null;
    tipoPessoa: TiposPessoa = TiposPessoa.Fisica;
    tipoPessoaDescricao: string = null;
    nome: string = null;
    nomeFantasia: string = null;
    observacao: string = null;
    cpf: string = null;
    rg: string = null;
    orgaoExpedidor: string = null;
    ufExpedidor?: Ufs = null;
    ufExpedidorDescricao: string = null;
    nomeRotulo: string = null;
    dataNascimento?: Date = null;
    genero?: GenerosPessoa = null;
    generoDescricao: string = null;
    razaoSocial: string = null;
    cnpj: string = null;
    inscricaoEstadual: string = null;
    inscricaoMunicipal: string = null;
    cep: string = null;
    estadoDescricao: string = null;
    estadoId?: Ufs = null;
    cidadeId?: number = null;
    cidade: string = null;
    endereco: string = null;
    bairro: string = null;
    numero: string = null;
    complemento: string = null;
    telefone: string = null;
    celular: string = null;
    email: string = null;
    emailsNotaFiscal?: Array<ClienteEmailNotaFiscalModel> = [];
    enderecosEntrega: Array<EnderecoEntregaModel> = [];
    pacientes: Array<PacienteModel> = [];
    pacienteId?: number = null;
    exterior = false;
    idEstrangeiro: string = null;
    pais?: string = "Brasil";
    codigoPais?: number = 1058;
    contribuinteEstadual = false;
    ativo = true;
    dataCriacao: Date = null;
    beneficioId? = null;
    pacienteCliente = false;
}
