import Vue from "vue";
import { mapMutations, mapState } from "vuex";

import Grid from "@/components/child/grid/grid";
import gridComponent from "@/components/child/grid/grid.vue";
import { GridAction } from "@/components/child/grid/gridAction";
import { GridColumn, GridColumnType } from "@/components/child/grid/gridColumn";
import LoadingModalComponent from "@/components/child/loadingmodal/loadingmodal";
import loadingModalComponent from "@/components/child/loadingmodal/loadingmodal.vue";
import { ModalButtonAction } from "@/components/child/modal/modalButtonAction";
import shortcutComponent from "@/components/parent/shortcut/shortcut.vue";
import { Component, Prop, Watch } from "@/decorators";
import PaginationPackModel from "@/models/auxiliar/paginationPackModel";
import PaginationModel from "@/models/paginationModel";
import TransportadorModel from "@/models/transporte/transportadorModel";
import TransportadorService from "@/services/transporte/transportadorService";
import Delay from "@/utils/common/delay";

import ShortcutComponent from "../../shortcut/shortcut";

@Component({
    components: {
        gridComponent,
        loadingModalComponent,
        shortcutComponent,
    },
    computed: mapState({
        loadingPack: state => state["paginationStatus"].loading,
        paginationParams: state => state["paginationStatus"].paginationParams,
    }),
    methods: mapMutations(["LOAD_LIST", "SET_PARAMS", "GET_PARAMS"]),
})
export default class TransportadorListComponent extends Vue {
    private service = new TransportadorService();
    private loadingModalComponent: LoadingModalComponent = null;
    private shortCutList: ShortcutComponent = null;
    private shortcutComponent: ShortcutComponent = null;
    private showList = false;
    private gridcomponent: Grid = null;

    startFilterKey = "";
    gridFilterKey = "";
    gridSortKey = "nome";
    gridSortOrder = "asc";
    gridData: Array<TransportadorModel> = [];
    get gridColumns(): Array<GridColumn> {
        return [
            new GridColumn("nome", "Nome", GridColumnType.String, false, false, "", "", true),
            new GridColumn("cpf", "CPF", GridColumnType.String, false, false, "", "", true),
            new GridColumn("celular", "Celular", GridColumnType.String, false, false, "", "", true, false),
            new GridColumn(
                "transportadora",
                "Transportadora",
                GridColumnType.String,
                false,
                false,
                "",
                "",
                true,
                false,
            ),
        ];
    }
    extraActions: Array<object> = [];
    gridExtraActionsVendas: Array<GridAction> = [];
    gridExtraActionsVer: Array<GridAction> = [];

    pageIndex = 1;
    pageSize = 20;
    total = 0;
    id = 0;
    showVer = false;

    pags = false;
    tipoDocGerar: number = null;

    extraButtonAction: Array<ModalButtonAction> = [
        new ModalButtonAction("Editar", "Editar Campos", true, "l", " btnCopiar"),
        new ModalButtonAction("Fechar", "Fechar", true, "l", " btn-danger"),
    ];

    @Prop({ type: Number, default: 20 }) pgSize: number;
    @Prop({ type: Boolean, default: false }) CalledByShortCut: boolean;

    private hideExtras = false;

    private load() {
        this.loadingModalComponent.showLoading();

        this.service
            .list(
                this.gridFilterKey,
                this.gridSortKey,
                this.gridSortOrder,
                this.pags ? 1 : this.pageIndex,
                this.pags ? 999999 : this.pageSize,
            )
            .then(response => {
                return response.json() as Promise<PaginationModel<TransportadorModel>>;
            })
            .then(data => {
                //atualiza params no store
                if (data.total > 0)
                    this["SET_PARAMS"]({
                        routePath: this.$route.path,
                        filterKey: this.gridFilterKey,
                        sortKey: this.gridSortKey,
                        sortOrder: this.gridSortOrder,
                        pageIndex: this.pageIndex,
                        pageSize: this.pageSize,
                    });

                this.gridData = data.list;
                this.total = data.total;
                this.gridExtraActionsVendas.splice(0);
                this.gridExtraActionsVer.splice(0);
                this.extraActions.splice(0);

                this.extraActions.push(this.gridExtraActionsVer);
                this.extraActions.push(this.gridExtraActionsVendas);

                if (this.pags) {
                    if (this.tipoDocGerar == 0) {
                        setTimeout(() => {
                            this.gridcomponent.gerarXls();
                        }, 2000);
                    }

                    if (this.tipoDocGerar == 1) {
                        setTimeout(() => {
                            this.gridcomponent.gerarPdf();
                        }, 2000);
                    }

                    if (this.tipoDocGerar == 2) {
                        setTimeout(() => {
                            this.gridcomponent.gerarImpressao();
                        }, 2000);
                    }

                    setTimeout(() => {
                        this.pags = false;
                    }, 2000);
                }

                this.loadingModalComponent.hide();
            })
            .catch(error => {
                this.loadingModalComponent.hide();
            });
    }

    private onEditItem(model: TransportadorModel) {
        this.$router.push("/Transportador-edicao/" + model.id);
    }

    private async onRemoveItem(model: TransportadorModel) {
        const response = await this.$showExclusaoQuestion(`${this.$t("__.ts.desejaExcl")}${model.nome}?`);

        if (response) {
            try {
                const sucesso = await this.service.delete(model.id).withLoading().resolveWithoutJSON();
                if (sucesso) {
                    this.load();
                    this.$showExclusao();
                }
            } catch {}
        }
    }

    private onCreateItem() {
        this.$router.push("/Transportador-edicao");
    }

    private onChangeFilterKey(filterKey: string) {
        this.gridFilterKey = filterKey;
        this.load();
    }

    private onChangeSort(sortKey: string, sortOrder: string) {
        this.gridSortKey = sortKey;
        this.gridSortOrder = sortOrder;
        this.load();
    }

    private onChangePage(pageIndex: number) {
        this.pageIndex = pageIndex;
        this.load();
    }

    private async onExtraButtonActionClick(name, entry, codigo) {
        if (name == "Editar") {
            this.onBtnEditItem();
        } else if (name == "Fechar") {
            this.onFechar();
        }
    }

    private onFechar() {
        this.showVer = false;
        this.shortcutComponent.hide();
    }

    private onBtnEditItem() {
        this.$router.push("/Transportador-edicao/" + this.id);
    }

    private async onVendasCliente(clienteId: number, nomeCliente: string) {
        this.loadingModalComponent.showLoading();
        this.showList = true;

        while (!this.$refs.shortCutList) {
            await Delay(5);
        }

        if (!this.shortCutList) this.shortCutList = this.$refs.shortCutList as ShortcutComponent;

        while (!this.$refs.vendasClienteListComponent) {
            await Delay(5);
        }

        this.shortCutList.title = "Vendas do Cliente " + nomeCliente;
        this.shortCutList.show();

        this.loadingModalComponent.hide();
    }
    private onConfirmList() {
        this.shortCutList.hide();
    }

    @Watch("pags")
    private onPags() {
        this.load();
    }

    private async loadPagination() {
        await this["GET_PARAMS"](this.$route.path);
        while (this["loadingPack"]) {
            await Delay(50);
        }
        const paginationPack = this["paginationParams"] as PaginationPackModel;

        if (paginationPack != null) {
            this.gridFilterKey = paginationPack.filterKey;
            this.startFilterKey = this.gridFilterKey;
            this.gridSortKey = paginationPack.sortKey;
            this.gridSortOrder = paginationPack.sortOrder;
            this.pageIndex = paginationPack.pageIndex;
            this.pageSize = paginationPack.pageSize;
        }
    }

    private mounted() {
        this.shortcutComponent = this.$refs.shortcutComponent as ShortcutComponent;
        this.loadingModalComponent = this.$refs.loadingModalComponent as LoadingModalComponent;
        this.shortCutList = this.$refs.shortCutList as ShortcutComponent;
        this.gridcomponent = this.$refs.gridcomponent as Grid;
        this.hideExtras = this.CalledByShortCut ? true : false;
        if (this.hideExtras) {
            this.pageSize = this.pgSize;
        }
        this.loadingModalComponent.showLoading();
        Promise.all([this.loadPagination()])
            .then(result => {
                this.load();
            })
            .catch(error => {
                this.loadingModalComponent.hide();
            });
    }
}
