import SubGrupoProduto from "@/models/enum/subGrupoProduto";
import TiposCalculoManipulacao from "@/models/enum/tiposCalculoManipulacao";
import TiposItemManipulacao from "@/models/enum/tiposItemManipulacao";
import ManipulacaoOrdemItemModel from "@/models/manipulacaoOrdemItemModel";
import ManipulacaoOrdemModel from "@/models/manipulacaoOrdemModel";
import ProdutoModel from "@/models/produto/produtoModel";
import {
    ActionItemType,
    ItemTableType,
    ItemTypeOrigem,
    ManipulacaoContextState,
} from "@/store/manipulacaoContext/types";
import arithmeticHelper from "@/utils/common/arithmeticHelper";

export function updateManipulacao(context: ManipulacaoContextState) {
    context.manipulacao.itens = [];
    context.manipulacao.itens.push(...context.ativos);
    context.manipulacao.itens.push(...context.embalagens);
    context.manipulacao.itens.push(...context.capsulas);
    context.manipulacao.itens.push(...context.desmembrados);

    context.manipulacao.valorProdutos = getValorTotalProdutos(context);
    context.manipulacao.volumeTotal = getVolumeTotal(context.manipulacao);
    context.manipulacao = getValorTotal(
        context.manipulacao,
        context.formaFarmaceutica.fatorMultiplicadorAdicional,
        0,
        context.formaFarmaceutica.valorMinimo,
    );

    return context;
}

export function addItemManipulacaoContext(
    itens: ManipulacaoOrdemItemModel[],
    itemAdd: ManipulacaoOrdemItemModel,
    tipoItem: TiposItemManipulacao,
    { index = null, origem = ItemTypeOrigem.OUTRO, noAddIfExists = false }: ActionItemType,
) {
    // Se está adicionando item vinculado e já tem item com produto ou for QSP e já tem QSP
    if (
        noAddIfExists &&
        (itens.some(p => p.produtoId == itemAdd.produtoId) ||
            (itemAdd.tipoCalculo == TiposCalculoManipulacao.Qsp &&
                itens.some(p => p.tipoCalculo == TiposCalculoManipulacao.Qsp)))
    ) {
        if (itens[index] && itens[index].produtoId == itemAdd.produtoId) {
            itens[index] = itemAdd;
        }
        return itens;
    }

    if (origem == ItemTypeOrigem.ITEM_PADRAO_FORMAFARMACEUTICA) {
        itemAdd.tipoItem = itemAdd.tipoItem ? itemAdd.tipoItem : tipoItem;
        itens.push(itemAdd);
        return itens;
    }

    if (index != null) {
        itens[index] = itemAdd;
        return itens;
    }

    itemAdd.tipoItem = itemAdd.tipoItem ? itemAdd.tipoItem : tipoItem;
    itens.push(itemAdd);

    return itens;
}

export async function getVolumeTotalAtivos(context: ManipulacaoContextState) {
    const ativos = context.ativos.filter(p => p.tipoCalculo != TiposCalculoManipulacao.Qsp);

    if (!context.formaFarmaceutica.desconsiderarDensidade) {
        let total = 0;
        for (const ativo of ativos) {
            const indice = await context.indicesConversoesUnidadeMedidasService.getIndiceConversao(
                ativo.unidadeMedidaManipulacaoId,
                context.manipulacao.unidadeMedidaId,
                ativo.densidade,
                ativo.produtoId,
                ativo.produtoLoteId,
            );
            total += indice * ativo.quantidade;
        }

        return total;
    } else {
        return ativos.reduce((acc, p) => acc + p.quantidadePesagem, 0);
    }
}

export function getQSPPai(ativos: ManipulacaoOrdemItemModel[]) {
    const QSPs = ativos.filter(p => p.tipoCalculo == TiposCalculoManipulacao.Qsp && p.produtoPai == null);
    if (QSPs.length > 0) {
        return QSPs[0];
    }
    return null;
}

export function getValorTotalProdutos(context: ManipulacaoContextState) {
    return (
        context.ativos.reduce((acc, p) => acc + p.valorTotal, 0) +
        context.embalagens.reduce((acc, p) => acc + p.valorTotal, 0) +
        context.capsulas.reduce((acc, p) => acc + p.valorTotal, 0)
    );
}

export function getItemManipulacaoByProduto(
    produto: ProdutoModel,
    model: ManipulacaoOrdemItemModel,
    context: ManipulacaoContextState,
    itemType: ItemTableType,
) {
    const item = { ...model } as ManipulacaoOrdemItemModel;

    item.quantidade = 0;
    item.quantidadePesagem = 0;

    try {
        item.produtoDescricao = produto.descricao;

        item.fatorEquivalencia = null;
        if (produto.fatorEquivalencia > 0) {
            item.fatorEquivalencia = produto.fatorEquivalencia;
        }
        if (item.sinonimoId) {
            const sinonimo = produto.produtoSinonimos.find(p => p.id == item.sinonimoId);
            if (sinonimo) {
                if (sinonimo.fatorEquivalencia) {
                    item.fatorEquivalencia = sinonimo.fatorEquivalencia;
                }
                item.produtoDescricao = sinonimo.sinonimo;
            }
        }

        item.valor = produto.valorVenda ? produto.valorVenda : 0;
        item.fatorCorrecao = produto.fatorCorrecao ? produto.fatorCorrecao : null;
        item.fatorUTR = produto.fatorUTR ? produto.fatorUTR : null;
        item.fatorUI = produto.fatorUI ? produto.fatorUI : null;

        item.densidade = produto.densidade ? produto.densidade : null;
        item.fatorUTR = produto.fatorUTR ? produto.fatorUTR : null;
        item.fatorUFC = produto.fatorUFC ? produto.fatorUFC : null;

        if (context.formaFarmaceutica.desconsiderarDensidade) {
            item.densidade = 1;
        }

        if (itemType != ItemTableType.ATIVO) {
            item.tipoCalculo = TiposCalculoManipulacao.Nenhum;
        }

        if (!item.tipoCalculo && itemType == ItemTableType.ATIVO) {
            if (produto.tipoCalculo) {
                item.tipoCalculo = produto.tipoCalculo;
            }

            if (
                context.formaFarmaceutica.tipoCalculo != null &&
                produto.tipoCalculo != TiposCalculoManipulacao.Qsp &&
                produto.subGrupo == SubGrupoProduto.Ativo
            ) {
                item.tipoCalculo = context.formaFarmaceutica.tipoCalculo;
            }
        }

        item.subGrupoItem = produto.subGrupo;

        item.produtoDiluidoId = produto.produtoDiluidoId;
        item.doseMaximaDiluido = produto.doseMaximaDiluido;
        item.produtoListaControladoId = produto.listaControladoId;

        if (context.isCapsula && produto.volumeCapsula > 0) {
            if (item.quantidadeDose == 0) {
                item.quantidadeDose = context.manipulacao.quantidade;
            }
        }

        if (item.isItemFromPreVenda) {
            if (!item.unidadeMedidaId) {
                item.unidadeMedidaId = produto.unidadeMedidaEstoqueId;
            }
            if (!item.unidadeMedidaManipulacaoId) {
                item.unidadeMedidaManipulacaoId = produto.unidadeMedidaManipulacaoId;
            }
        } else {
            item.unidadeMedidaId = produto.unidadeMedidaEstoqueId;
            item.unidadeMedidaManipulacaoId = produto.unidadeMedidaManipulacaoId;
        }

        if (item.tipoCalculo == TiposCalculoManipulacao.Dose && (item.volumeDose == null || item.volumeDose == 0)) {
            item.volumeDose = 1;
        }
    } catch {}

    return { item, produto };
}

export function getVolumeTotal(manipulacao: ManipulacaoOrdemModel) {
    const fatorMultQuantidade = manipulacao.fatorMultQuantidade ? manipulacao.fatorMultQuantidade : 1;
    return (manipulacao.numeroFormulas ?? 1) * manipulacao.volume * manipulacao.quantidade * fatorMultQuantidade;
}

export function getValorTotal(
    manipulacao: ManipulacaoOrdemModel,
    fatorMultiplicadorAdicional: number,
    valorMinimoMult: number,
    valorMinimo: number,
) {
    manipulacao.valorTotal = arithmeticHelper.round(
        manipulacao.valorProdutos +
            manipulacao.valorFormaFarmaceutica +
            manipulacao.acrescimo -
            manipulacao.desconto -
            manipulacao.descontoConvenio,
    );

    if (fatorMultiplicadorAdicional > 0) {
        if (manipulacao.valorFormaFarmaceutica != valorMinimoMult) {
            manipulacao.valorFormaFarmaceutica = valorMinimoMult;
        }

        if (manipulacao.valorTotal < valorMinimoMult) {
            if (manipulacao.descontoConvenio > 0) {
                manipulacao.descontoConvenio =
                    manipulacao.valorProdutos +
                    manipulacao.valorFormaFarmaceutica +
                    manipulacao.acrescimo -
                    manipulacao.desconto -
                    valorMinimoMult;
            }

            manipulacao.valorTotal = valorMinimoMult;
        }
    } else if (manipulacao.valorTotal < valorMinimo) {
        manipulacao.valorTotal = valorMinimo;
    }

    return manipulacao;
}

export const calculos = {
    async valorTotal(item: ManipulacaoOrdemItemModel, type: ItemTableType, context: ManipulacaoContextState) {
        item.valorTotal = 0;
        if (item.valor && item.quantidade && !context.uplodingPrevenda) {
            const indice = await context.indicesConversoesUnidadeMedidasService.getIndiceConversao(
                item.unidadeMedidaManipulacaoId,
                item.unidadeMedidaId,
                item.densidade,
                item.produtoId,
                item.produtoLoteId,
            );

            if (type == ItemTableType.ATIVO) {
                if (item.tipoCalculo == TiposCalculoManipulacao.UTRPercentual) {
                    item.valorTotal = Math.round(item.valor * item.quantidadePesagem * 100) / 100;
                } else {
                    item.valorTotal = Math.round(item.valor * (item.quantidade * indice) * 100) / 100;
                }
            } else {
                item.valorTotal =
                    Math.round(
                        item.valor * (item.quantidadeDose * (context.manipulacao.numeroFormulas ?? 1) * indice) * 100,
                    ) / 100;
            }
        }

        if (item.valorTotal < 0.01) {
            item.valorTotal = 0.01;
        }

        return item;
    },

    tipoCalculo: {
        nenhum(item: ManipulacaoOrdemItemModel) {
            return item.quantidadeDose;
        },

        dose(item: ManipulacaoOrdemItemModel, context: ManipulacaoContextState) {
            let quantidade = item.quantidadeDose;

            if (item.volumeDose) {
                const multiplicador =
                    context.manipulacao.volumeTotal / (context.manipulacao.numeroFormulas ?? 1) / item.volumeDose;
                if (item.tipoItem == TiposItemManipulacao.Embalagem) {
                    quantidade = Math.ceil(multiplicador);
                } else {
                    quantidade *= multiplicador;
                }
            }

            if (item.fatorCorrecao) {
                quantidade *= item.fatorCorrecao;
            }

            if (item.fatorEquivalencia) {
                quantidade *= item.fatorEquivalencia;
            }

            return quantidade;
        },

        unitario(item: ManipulacaoOrdemItemModel, context: ManipulacaoContextState) {
            let quantidade = item.quantidadeDose * context.manipulacao.quantidade;

            if (item.fatorCorrecao) {
                quantidade *= item.fatorCorrecao;
            }

            if (item.fatorEquivalencia) {
                quantidade *= item.fatorEquivalencia;
            }

            return quantidade;
        },

        percentualUTR(item: ManipulacaoOrdemItemModel) {
            let quantidade = item.quantidadeDose;

            if (item.fatorUTR) {
                quantidade /= item.fatorUTR;
            }

            return quantidade;
        },

        percentual(item: ManipulacaoOrdemItemModel, volumeTotal: number, context: ManipulacaoContextState) {
            let quantidade = (item.quantidadeDose / 100) * (volumeTotal / (context.manipulacao.numeroFormulas ?? 1));

            if (item.fatorCorrecao) {
                quantidade *= item.fatorCorrecao;
            }

            if (item.fatorEquivalencia) {
                quantidade *= item.fatorEquivalencia;
            }

            return quantidade;
        },
    },
};
