import Vue from "vue";

import gridComponent from "@/components/child/grid/grid.vue";
import { GridColumn, GridColumnType } from "@/components/child/grid/gridColumn";
import ShortcutComponent from "@/components/parent/shortcut/shortcut";
import shortcutComponent from "@/components/parent/shortcut/shortcut.vue";
import { Component } from "@/decorators";
import EnsaioFichaTecnicaModel from "@/models/ensaioFichaTecnicaModel";

@Component({
    components: {
        shortcutComponent,
        gridComponent,
    },
})
export default class ResultadosComponent extends Vue {
    private shortcutComponent: ShortcutComponent = null;
    private gridData: Array<EnsaioFichaTecnicaModel> = [];
    private loteSelecionado = 0;

    private get gridColumn(): Array<GridColumn> {
        return [
            new GridColumn("descricaoEnsaio", "Descrição", GridColumnType.String),
            new GridColumn("especificacao", "Especificação", GridColumnType.String),
            new GridColumn("resultado", "Resultado", GridColumnType.String, true),
        ];
    }

    public async load(model: EnsaioFichaTecnicaModel[], loteSelecionado: number) {
        for (const item of model) {
            item.descricaoEnsaio = item.ensaio.descricao;
        }

        this.shortcutComponent = this.$refs.shortcutComponent as ShortcutComponent;
        this.shortcutComponent.title = this.$t("__.ts.resultados").toString();
        this.shortcutComponent.show();
        this.gridData = model;

        this.loteSelecionado = loteSelecionado;
    }

    public onConfirm() {
        this.$emit("resultados", this.gridData, this.loteSelecionado);
        this.shortcutComponent.hide();
    }
}
