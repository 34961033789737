enum SituacoesVenda {
    Orcamento = 0,
    Rejeitado = 1,
    Aprovado = 2,
    Faturado = 4,
    Cancelado = 5,
    Concluido = 6,
    Fracionando = 7,
    ExpedicaoNota = 8,
    Entrega = 9,
    Expedicao = 10,
    EmFaturamento = 11,
    Entregue = 12,
}

export default SituacoesVenda;
