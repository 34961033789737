import Vue from "vue";

import dateTimePickerComponent from "@/components/child/form/datetimepicker.vue";
import fieldsetComponent from "@/components/child/form/fieldset.vue";
import { Component, Prop } from "@/decorators";
import InformacoesNfeModel from "@/models/notafiscaleletronica/dados/informacoes/informacoesNfeModel";

@Component({
    components: {
        fieldsetComponent,
        dateTimePickerComponent,
    },
})
export default class ImportacaoNfeDadosGeraisComponent extends Vue {
    @Prop({ type: InformacoesNfeModel, required: true }) model: InformacoesNfeModel;
}
