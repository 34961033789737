var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "crud crud-list" },
    [
      _c(
        "grid-component",
        {
          attrs: {
            data: _vm.produtosEstoque,
            columns: _vm.gridColumns,
            subColumns: _vm.gridColumnsVinculos,
            subDataName: _vm.subDataName,
            showExpand: true,
            conditionShowSubData: _vm.conditionShowSubData,
            "page-index": _vm.pageIndex,
            "page-size": _vm.pageSize,
            total: _vm.total,
            "show-select-checkbox": false,
            showEditItem: false,
            showRemoveItem: false,
            showAddNewButton: false,
          },
          on: {
            "change-filter-key": _vm.onChangeFilterKey,
            "change-sort": _vm.onChangeSort,
            "change-page": _vm.onChangePage,
          },
        },
        [
          _c("div", { attrs: { slot: "header" }, slot: "header" }, [
            _c("div", { staticClass: "filtro-lista" }, [
              _c(
                "button",
                {
                  staticClass: "filtro-avancado-button",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      _vm.isHidden = !_vm.isHidden
                    },
                  },
                },
                [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.$t("__.Crud.filtroAvancado")) +
                      "\n                    "
                  ),
                  _c("i", { staticClass: "fa fa-caret-down" }),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isHidden,
                      expression: "isHidden",
                    },
                  ],
                  staticClass: "drop-lista",
                },
                [
                  _c(
                    "form",
                    {
                      attrs: { novalidate: "novalidate" },
                      on: {
                        submit: function ($event) {
                          $event.preventDefault()
                          return _vm.getFormValues($event)
                        },
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "radio-group",
                          staticStyle: { "margin-bottom": "15px" },
                        },
                        [
                          _c("div", { staticStyle: { display: "table" } }, [
                            _c(
                              "div",
                              [
                                _c(
                                  "label",
                                  {
                                    staticClass: "control-label",
                                    attrs: { for: "grupoProduto" },
                                  },
                                  [
                                    _vm._v(
                                      "\n                                        " +
                                        _vm._s(
                                          _vm.$t(
                                            "__.Crud.produto.list_vue_html.grupProduto"
                                          )
                                        ) +
                                        "\n                                    "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c("combo-component", {
                                  staticStyle: { background: "white" },
                                  attrs: {
                                    id: "grupoProdutoId",
                                    name: "grupoProdutoId",
                                    data: _vm.grupoProdutoOptions,
                                    refresh: _vm.loadGruposProduto,
                                  },
                                  model: {
                                    value: _vm.grupoProdutoFiltro,
                                    callback: function ($$v) {
                                      _vm.grupoProdutoFiltro = $$v
                                    },
                                    expression: "grupoProdutoFiltro",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _vm.apareceSubGrupo == 2
                              ? _c(
                                  "div",
                                  [
                                    _c(
                                      "label",
                                      {
                                        staticClass: "control-label",
                                        staticStyle: {
                                          "margin-top": "10px",
                                          width: "100%",
                                        },
                                        attrs: { for: "subGrupoProduto" },
                                      },
                                      [
                                        _vm._v(
                                          "\n                                        " +
                                            _vm._s(
                                              _vm.$t(
                                                "__.Crud.produto.list_vue_html.subgrupProd"
                                              )
                                            ) +
                                            "\n                                    "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("combo-component", {
                                      staticStyle: { background: "white" },
                                      attrs: {
                                        id: "subGrupoProduto",
                                        name: "subGrupoProduto",
                                        data: _vm.subGrupoOptions,
                                      },
                                      model: {
                                        value: _vm.subGrupo,
                                        callback: function ($$v) {
                                          _vm.subGrupo = $$v
                                        },
                                        expression: "subGrupo",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "form-group" }, [
                        _c(
                          "button",
                          {
                            staticClass: "botao-filtrar",
                            attrs: { type: "submit" },
                          },
                          [
                            _vm._v(
                              "\n                                " +
                                _vm._s(_vm.$t("__.Crud.filtrar")) +
                                "\n                            "
                            ),
                          ]
                        ),
                      ]),
                    ]
                  ),
                ]
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("template", { slot: "more-tools" }, [
            _c(
              "a",
              {
                staticClass: "bg-blue",
                attrs: {
                  href: "/relatorio-view/9",
                  target: "_blank",
                  title: _vm.$t(
                    "__.Components.parent.home.fastaction.produtos.list_vue_html.relatorioSituaEstq"
                  ),
                },
              },
              [
                _c("i", {
                  staticClass: "fa fa-chart-line",
                  attrs: { "aria-hidden": "true" },
                }),
              ]
            ),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "bg-light-blue",
                attrs: {
                  href: "/relatorio-view/43",
                  target: "_blank",
                  title: _vm.$t(
                    "__.Components.parent.home.fastaction.produtos.list_vue_html.relatorioEstqMin"
                  ),
                },
              },
              [
                _c("i", {
                  staticClass: "fa fa-chart-line",
                  attrs: { "aria-hidden": "true" },
                }),
              ]
            ),
          ]),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }