import Vue from "vue";
import { mapGetters } from "vuex";

import comboComponent from "@/components/child/form/combo.vue";
import { Component, Prop } from "@/decorators";
import CupomModel from "@/models/cupomModel";
import TipoDesconto from "@/models/enum/TipoDesconto";
import PaginationModel from "@/models/paginationModel";
import CupomService from "@/services/CupomService";
import { Getters } from "@/store/store";

import "./../crud/contareceber/recebimentoconta.scss";

@Component({
    components: {
        comboComponent,
    },
    computed: {
        ...mapGetters(["VALIDAR_PERMISSAO_SOMENTE_CONSULTA"] as Getters),
    },
})
export default class AdicionarCupom extends Vue {
    // State computed props
    VALIDAR_PERMISSAO_SOMENTE_CONSULTA: (telaDescricao: string) => boolean;

    private cupomOptions: object[] = [];
    private cupons: CupomModel[] = [];

    @Prop({ type: Number, default: 0 }) cupomId: number;
    @Prop({ type: Boolean, default: false }) consulta: Boolean;

    public validarCupom() {
        const cupom = this.cupons.find(p => p.id == this.cupomId);

        this.$emit("adicionar-cupom", cupom);
    }

    private async loadCupons() {
        try {
            const data = await new CupomService().combo().resolveWithJSON<PaginationModel<CupomModel>>();
            this.cupons = data.list;

            const hoje = new Date();

            if (!this.consulta) {
                this.cupons = this.cupons.filter(
                    item => new Date(item.validadeInicio) <= hoje && new Date(item.validadeFim) >= hoje,
                );
            }

            this.cupomOptions = this.cupons.map(item => ({
                value: item.id,
                text:
                    item.tipoDesconto === TipoDesconto.Dinheiro
                        ? `${item.descricao} (R$ ${item.valorDesconto})`
                        : `${item.descricao} (${item.valorDesconto}%)`,
            }));
        } catch {}
    }

    private async mounted() {
        await this.loadCupons();
    }
}
