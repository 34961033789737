import BaseModel from "../base/baseModel";
import TiposProduto from "../enum/tiposProduto";

export default class GrupoProdutoModel extends BaseModel {
    id?: number = null;
    franquiaId?: number = null;
    descricao: string = null;
    tipoProduto: TiposProduto = null;
    configuracaoRotuloId?: number = null;
}
