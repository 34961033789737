import Vue from "vue";

import actionBarComponent from "@/components/child/actionBar/actionBar.vue";
import comboComponent from "@/components/child/form/combo.vue";
import dateTimePickerComponent from "@/components/child/form/datetimepicker.vue";
import { Component } from "@/decorators";
import CurvaABCConsiderar from "@/models/enum/produto/curvaABCConsiderar";
import CurvaABCModel from "@/models/produto/curvaABCModel";
import PDFService from "@/services/pdf/PDFService";
import CurvaABCService from "@/services/produto/curvaABCService";
import FileHelper from "@/utils/common/fileHelper";

@Component({
    components: {
        actionBarComponent,
        dateTimePickerComponent,
        comboComponent,
    },
})
export default class CurvaABCComponent extends Vue {
    private model = new CurvaABCModel();
    private curvaABCService = new CurvaABCService();
    private pdfService = new PDFService("CurvaABCPDF");

    private isSuccess = false;

    considerarOptions: Array<Object> = [
        { text: "Consumo", value: CurvaABCConsiderar.Consumo },
        { text: "Valor de Venda", value: CurvaABCConsiderar.ValorDeVenda },
    ];

    private async save() {
        try {
            const isValid = await this.$validator.validateAll();
            if (isValid) {
                const response = await this.curvaABCService.definirCurvaABCProdutos(this.model).withLoading();

                if (response) {
                    this.$notifySuccess("A curva ABC dos produtos foi atualizada com sucesso.");

                    this.isSuccess = true;
                }
            }
        } catch {}
    }

    private openPDF() {
        this.pdfService.geraPDFWithFilters(this.model);
    }

    private async openXLS() {
        try {
            const response = await this.curvaABCService.getRelatorioXLS(this.model).withLoading().resolveWithResponse();
            const data = await response.blob();
            const blob = new Blob([data], { type: "text/xls;charset=utf-8;" });

            const fileName = response.headers.get("Content-Disposition").split("filename=")[1].split(";")[0];
            FileHelper.download(blob, fileName);
        } catch {}
    }

    private mounted() {
        this.isSuccess = false;
    }
}
