import Vue from "vue";
import { mapMutations, mapState } from "vuex";

import comboComponent from "@/components/child/form/combo.vue";
import dateTimePickerComponent from "@/components/child/form/datetimepicker.vue";
import Grid from "@/components/child/grid/grid";
import gridComponent from "@/components/child/grid/grid.vue";
import { GridColumn, GridColumnType } from "@/components/child/grid/gridColumn";
import LoadingModalComponent from "@/components/child/loadingmodal/loadingmodal";
import loadingModalComponent from "@/components/child/loadingmodal/loadingmodal.vue";
import { Component, Watch } from "@/decorators";
import PaginationPackModel from "@/models/auxiliar/paginationPackModel";
import StatusContas from "@/models/enum/statusContas";
import TipoMovimentacao from "@/models/enum/tiposMovimentacao";
import ContaGerencialModel from "@/models/financeiro/contaGerencialModel";
import ContaPagarModel, { ContaPagarListParameters } from "@/models/financeiro/contaPagarModel";
import FornecedorModel from "@/models/fornecedorModel";
import PaginationModel from "@/models/paginationModel";
import ContaGerencialService from "@/services/financeiro/contaGerencialService";
import ContaPagarService from "@/services/financeiro/contaPagarService";
import FornecedorService from "@/services/fornecedorService";
import { AppState, Mutations } from "@/store/store";
import { getFornecedorCombo } from "@/utils/common/combo/combotext";
import { convertToDateInput, convertToDateInputString } from "@/utils/common/date";
import Delay from "@/utils/common/delay";

import "../crud.scss";

@Component({
    components: {
        gridComponent,
        comboComponent,
        dateTimePickerComponent,
        loadingModalComponent,
    },
    computed: mapState({
        ...mapState<AppState>({
            loadingPack: state => state.paginationStatus.loading,
            paginationParams: state => state.paginationStatus.paginationParams,
        }),
    }),
    methods: mapMutations(["SET_PARAMS", "GET_PARAMS"] as Mutations),
})
export default class ContaPagaListComponent extends Vue {
    private service = new ContaPagarService();
    private loadingModalComponent: LoadingModalComponent = null;
    private gridcomponent: Grid = null;

    startFilterKey = "";
    gridFilterKey = "";
    gridSortKey = "dataVencimento";
    gridSortOrder = "asc";
    gridData: Array<ContaPagarModel> = [];
    get gridColumns(): Array<GridColumn> {
        return [
            new GridColumn("dataVencimento", "Venc.", GridColumnType.Date),
            new GridColumn("descricao", "Descri\u00e7\u00e3o", GridColumnType.String),
            new GridColumn("fornecedor", "Fornecedor", GridColumnType.String, false, false, "", "", false, false),
            new GridColumn("contaGerencialDescricao", "Categoria", GridColumnType.String),
            new GridColumn("valorTotal", "Valor Orig.", GridColumnType.Money),
            new GridColumn("totalValor", "Valor Pago", GridColumnType.Money, false, false, "", "", false, false),
            new GridColumn("dataLancamento", "Pagamento", GridColumnType.Date, false, false, "", "", false, false),
            new GridColumn("statusDescricao", "Status", GridColumnType.String, false, false, "", "", false, false),
        ];
    }

    subDataName = "lancamentosVinculados";

    gridColumnsVinculos: Array<GridColumn> = [
        new GridColumn("dataVencimento", "Vencimento", GridColumnType.Date),
        new GridColumn("descricao", "Descri\u00e7\u00e3o", GridColumnType.String),
        new GridColumn("valorTotal", "Valor", GridColumnType.Money),
        new GridColumn("saldo", "Saldo", GridColumnType.Money),
    ];

    pageIndex = 1;
    pageSize = 20;
    total = 0;
    isHidden = false;
    filtro: Array<Object> = [];
    contasGerenciaisOptions: Array<Object> = [];
    fornecedoresOptions: Array<Object> = [];

    fornecedorFiltro?: number = null;
    categoriaFiltro?: number = null;
    dataFilter1: Date = null;
    dataFilter2: Date = null;
    dataFilter3: Date = null;
    dataFilter4: Date = null;

    pags = false;
    tipoDocGerar: number = null;

    conditionShowSubData(it) {
        return it["lancamentosVinculados"]["length"] > 0;
    }

    private load() {
        this.isHidden = false;
        this.loadingModalComponent.showLoading();

        const customParameters: ContaPagarListParameters = {
            statusContas: StatusContas.Liquidado,
            filterKeyId: "",
            dataVencimentoIniStr: convertToDateInputString(this.filtro[0]),
            dataVencimentoFinStr: convertToDateInputString(this.filtro[1], true),
            fornecedorId: this.filtro[2] ? <number>this.filtro[2] : null,
            apenasVencidos: false,
            contaGerencialId: this.filtro[3] ? <number>this.filtro[3] : null,
            dataLancamentoIniStr: convertToDateInputString(this.filtro[4]),
            dataLancamentoFinStr: convertToDateInputString(this.filtro[5], true),
        };
        this.service
            .list(
                this.gridFilterKey,
                this.gridSortKey,
                this.gridSortOrder,
                this.pags ? 1 : this.pageIndex,
                this.pags ? 999999 : this.pageSize,
                customParameters,
            )
            .then(response => {
                return response.json() as Promise<PaginationModel<ContaPagarModel>>;
            })
            .then(data => {
                //atualiza params no store
                if (data.total > 0)
                    this["SET_PARAMS"]({
                        routePath: this.$route.path,
                        filterKey: this.gridFilterKey,
                        sortKey: this.gridSortKey,
                        sortOrder: this.gridSortOrder,
                        pageIndex: this.pageIndex,
                        pageSize: this.pageSize,
                        filtros: this.filtro,
                    });

                this.gridData = data.list;

                this.gridData["dataDe"] = this.filtro[0] ? this.filtro[0].toString() : " ";
                this.gridData["dataAte"] = this.filtro[1] ? this.filtro[1].toString() : " ";

                this.gridData["dataDe2"] = this.filtro[4] ? this.filtro[4].toString() : " ";
                this.gridData["dataAte2"] = this.filtro[5] ? this.filtro[5].toString() : " ";

                if (this.filtro[3]) this.gridData["categoria"] = data.list[0]["contaGerencialDescricao"];

                if (this.filtro[2]) this.gridData["fornecedor"] = data.list[0]["fornecedor"];

                this.total = data.total;

                if (this.pags) {
                    if (this.tipoDocGerar == 0) {
                        setTimeout(() => {
                            this.gridcomponent.gerarXls();
                        }, 2000);
                    }

                    if (this.tipoDocGerar == 1) {
                        setTimeout(() => {
                            this.gridcomponent.gerarPdf();
                        }, 2000);
                    }

                    if (this.tipoDocGerar == 2) {
                        setTimeout(() => {
                            this.gridcomponent.gerarImpressao();
                        }, 2000);
                    }

                    setTimeout(() => {
                        this.pags = false;
                    }, 2000);
                }

                this.loadingModalComponent.hide();
            })
            .catch(error => {
                this.loadingModalComponent.hide();
            });
    }

    private onEditItem(model: ContaPagarModel) {
        this.$router.push("/contapagar-edicao/" + model.id);
    }

    private async onRemoveItem(model: ContaPagarModel) {
        const response = await this.$showExclusaoQuestion(`${this.$t("__.ts.desejaExcl")}${model.descricao}?`);

        if (response) {
            try {
                const sucesso = await this.service.delete(model.id).withLoading().resolveWithoutJSON();
                if (sucesso) {
                    this.load();
                    this.$showExclusao();
                }
            } catch {}
        }
    }

    private onCreateItem() {
        this.$router.push("/contapagar-edicao");
    }

    private onChangeFilterKey(filterKey: string) {
        this.gridFilterKey = filterKey;
        this.load();
    }

    private onChangeSort(sortKey: string, sortOrder: string) {
        this.gridSortKey = sortKey;
        this.gridSortOrder = sortOrder;
        this.load();
    }

    private onChangePage(pageIndex: number) {
        this.pageIndex = pageIndex;
        this.load();
    }

    private getFormValues(listaFiltro) {
        this.filtro = [];

        this.filtro.push(listaFiltro.target.elements.dataFilter1.value);

        this.filtro.push(listaFiltro.target.elements.dataFilter2.value);

        this.filtro.push(this.fornecedorFiltro);

        this.filtro.push(this.categoriaFiltro);

        this.filtro.push(listaFiltro.target.elements.dataFilter3.value);

        this.filtro.push(listaFiltro.target.elements.dataFilter4.value);

        this.load();
    }

    private async loadFornecedores() {
        try {
            const data = await new FornecedorService()
                .combo()
                .then(r => r.json() as Promise<PaginationModel<FornecedorModel>>);
            this.fornecedoresOptions = data.list.map(getFornecedorCombo);
        } catch {}
    }

    private async loadContasGerenciais() {
        try {
            const data = await new ContaGerencialService()
                .listByTipoMovimentacao("", 1, 999999, TipoMovimentacao.Saida)
                .then(r => r.json() as Promise<PaginationModel<ContaGerencialModel>>);
            this.contasGerenciaisOptions = data.list.map(item => {
                return { value: item.id, text: item.descricao };
            });
        } catch {}
    }

    @Watch("pags")
    private onPags() {
        this.load();
    }
    private async loadPagination() {
        await this["GET_PARAMS"](this.$route.path);
        while (this["loadingPack"]) {
            await Delay(50);
        }
        const paginationPack = this["paginationParams"] as PaginationPackModel;

        if (paginationPack != null) {
            this.gridFilterKey = paginationPack.filterKey;
            this.startFilterKey = this.gridFilterKey;

            this.gridSortKey = paginationPack.sortKey;
            this.gridSortOrder = paginationPack.sortOrder;
            this.pageIndex = paginationPack.pageIndex;
            this.pageSize = paginationPack.pageSize;
            if (paginationPack.filtros != null) {
                this.filtro = paginationPack.filtros;
                if (this.filtro.length >= 0) {
                    if (this.filtro[0]) {
                        this.dataFilter1 = convertToDateInput(this.filtro[0].toString());
                    }
                    if (this.filtro[1]) {
                        this.dataFilter2 = convertToDateInput(this.filtro[1].toString());
                    }
                    if (this.filtro[2] != null && <number>this.filtro[2] > 0) {
                        this.fornecedorFiltro = <number>this.filtro[2];
                    }
                    if (this.filtro[3] != null && <number>this.filtro[3] > 0) {
                        this.categoriaFiltro = <number>this.filtro[3];
                    }
                    if (this.filtro[4]) {
                        this.dataFilter3 = convertToDateInput(this.filtro[4].toString());
                    }
                    if (this.filtro[5]) {
                        this.dataFilter4 = convertToDateInput(this.filtro[5].toString());
                    }
                }
            }
        }
    }

    private mounted() {
        this.loadingModalComponent = this.$refs.loadingModalComponent as LoadingModalComponent;
        this.gridcomponent = this.$refs.gridcomponent as Grid;
        this.loadContasGerenciais();
        this.loadFornecedores();
        this.loadingModalComponent.showLoading();
        Promise.all([this.loadPagination()])
            .then(result => {
                this.load();
                this.loadingModalComponent.hide();
            })
            .catch(error => {
                this.loadingModalComponent.hide();
            });
    }
}
