import EstoqueModel from "../models/estoqueModel";
import nfetch from "../utils/events/nfetch";

import { IService } from "./base/iService";

export default class EstoqueService extends IService<EstoqueModel> {
    constructor() {
        super("Estoque");
    }

    public listByFranquiaId(franquiaId?: number): Promise<Response> {
        return nfetch(`/${this.controllerName}/ListByFranquiaId?franquiaId=${franquiaId}`, {
            credentials: "same-origin",
        });
    }

    public listEstoqueDestino(): Promise<Response> {
        return nfetch(`/${this.controllerName}/ListEstoqueSaida`, {
            credentials: "same-origin",
        });
    }
}
