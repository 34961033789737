var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "crud crud-edit" },
    [
      _c(
        "actionBarComponent",
        {
          attrs: {
            title: _vm.$t(
              "__.Crud.movimentacaoestoque.edit_vue_html.movimentaEstqEdit"
            ),
            showSave:
              _vm.model.id <= 0 ||
              _vm.model.tipoMovimentacao == _vm.tiposMovimentacao.Saida,
          },
          on: { onSave: _vm.save },
        },
        [
          _vm.model.id && _vm.isAdmin
            ? _c(
                "div",
                {
                  attrs: {
                    slot: "buttonSlot0",
                    title: _vm.$t(
                      "__.Crud.movimentacaoestoque.edit_vue_html.reverterMovimentacao"
                    ),
                  },
                  on: { click: _vm.reverterMovimentacao },
                  slot: "buttonSlot0",
                },
                [
                  _c("i", {
                    staticClass: "fa fa-undo",
                    attrs: { "aria-hidden": "true" },
                  }),
                ]
              )
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _c(
        "form",
        {
          attrs: { novalidate: "novalidate" },
          on: {
            submit: function ($event) {
              $event.preventDefault()
              _vm.save()
            },
          },
        },
        [
          _c(
            "fieldset-component",
            {
              attrs: {
                title: _vm.$t(
                  "__.Crud.movimentacaoestoque.edit_vue_html.dadosGera"
                ),
                collapsed: false,
              },
            },
            [
              _c("div", { attrs: { slot: "rows" }, slot: "rows" }, [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-sm-6" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("tipoMovimentacao"),
                            expression: "errors.first('tipoMovimentacao')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: {
                              for: "tipoMovimentacao",
                              title: _vm.$t("__.obrigatorio"),
                            },
                          },
                          [
                            _c("span", { staticStyle: { color: "red" } }, [
                              _vm._v("*"),
                            ]),
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Crud.movimentacaoestoque.edit_vue_html.tipoMovimenta"
                                  )
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("combo-component", {
                          directives: [
                            { name: "focus", rawName: "v-focus" },
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required",
                              expression: "'required'",
                            },
                          ],
                          attrs: {
                            id: "tipoMovimentacao",
                            name: "tipoMovimentacao",
                            data: _vm.tiposMovimentacaoOptions,
                            searchable: false,
                            disabled:
                              _vm.model.id > 0 ||
                              _vm.model.movimentacaoEstoqueItens.length > 0,
                          },
                          on: {
                            change: function ($event) {
                              _vm.onTipoMovimentacaoChanged(
                                +$event.target.value
                              )
                            },
                          },
                          model: {
                            value: _vm.model.tipoMovimentacao,
                            callback: function ($$v) {
                              _vm.$set(_vm.model, "tipoMovimentacao", $$v)
                            },
                            expression: "model.tipoMovimentacao",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-6" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("dataMovimentacao"),
                            expression: "errors.first('dataMovimentacao')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: {
                              for: "dataMovimentacao",
                              title: _vm.$t("__.obrigatorio"),
                            },
                          },
                          [
                            _c("span", { staticStyle: { color: "red" } }, [
                              _vm._v("*"),
                            ]),
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Crud.movimentacaoestoque.edit_vue_html.dataMovimenta"
                                  )
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("date-time-picker-component", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required",
                              expression: "'required'",
                            },
                          ],
                          attrs: {
                            id: "dataMovimentacao",
                            name: "dataMovimentacao",
                            "pick-time": true,
                            disabled: true,
                          },
                          model: {
                            value: _vm.model.dataMovimentacao,
                            callback: function ($$v) {
                              _vm.$set(_vm.model, "dataMovimentacao", $$v)
                            },
                            expression: "model.dataMovimentacao",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            _vm.model.tipoMovimentacao !=
                            _vm.tiposMovimentacao.Entrada,
                          expression:
                            "model.tipoMovimentacao != tiposMovimentacao.Entrada",
                        },
                      ],
                      staticClass: "col-sm-6",
                    },
                    [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: _vm.errors.first("estoqueOrigemId"),
                              expression: "errors.first('estoqueOrigemId')",
                            },
                          ],
                          staticClass: "form-group",
                        },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "control-label",
                              attrs: {
                                for: "estoqueOrigemId",
                                title: _vm.$t("__.obrigatorio"),
                              },
                            },
                            [
                              _c("span", { staticStyle: { color: "red" } }, [
                                _vm._v("*"),
                              ]),
                              _vm._v(
                                "\n                                " +
                                  _vm._s(
                                    _vm.$t(
                                      "__.Crud.movimentacaoestoque.edit_vue_html.estqOrigem"
                                    )
                                  ) +
                                  "\n                            "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("combo-component", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: {
                                  rules: {
                                    required:
                                      _vm.model.tipoMovimentacao !=
                                      _vm.tiposMovimentacao.Entrada,
                                  },
                                },
                                expression:
                                  "{\n                                    rules: { required: model.tipoMovimentacao != tiposMovimentacao.Entrada },\n                                }",
                              },
                            ],
                            attrs: {
                              id: "estoqueOrigemId",
                              name: "estoqueOrigemId",
                              data:
                                _vm.model.id > 0
                                  ? _vm.estoquesDestinoOptions
                                  : _vm.estoquesOptions,
                              searchable: false,
                              refresh:
                                _vm.model.id > 0 ? null : _vm.loadEstoques,
                              disabled: _vm.model.id > 0,
                            },
                            model: {
                              value: _vm.model.estoqueOrigemId,
                              callback: function ($$v) {
                                _vm.$set(_vm.model, "estoqueOrigemId", $$v)
                              },
                              expression: "model.estoqueOrigemId",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            _vm.model.tipoMovimentacao !=
                            _vm.tiposMovimentacao.Saida,
                          expression:
                            "model.tipoMovimentacao != tiposMovimentacao.Saida",
                        },
                      ],
                      staticClass: "col-sm-6",
                    },
                    [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: _vm.errors.first("estoqueDestinoId"),
                              expression: "errors.first('estoqueDestinoId')",
                            },
                          ],
                          staticClass: "form-group",
                        },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "control-label",
                              attrs: {
                                for: "estoqueDestinoId",
                                title: _vm.$t("__.obrigatorio"),
                              },
                            },
                            [
                              _c("span", { staticStyle: { color: "red" } }, [
                                _vm._v("*"),
                              ]),
                              _vm._v(
                                "\n                                " +
                                  _vm._s(
                                    _vm.$t(
                                      "__.Crud.movimentacaoestoque.edit_vue_html.estqDestino"
                                    )
                                  ) +
                                  "\n                            "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("combo-component", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: {
                                  rules: {
                                    required:
                                      _vm.model.tipoMovimentacao !=
                                      _vm.tiposMovimentacao.Saida,
                                  },
                                },
                                expression:
                                  "{\n                                    rules: { required: model.tipoMovimentacao != tiposMovimentacao.Saida },\n                                }",
                              },
                            ],
                            attrs: {
                              id: "estoqueDestinoId",
                              name: "estoqueDestinoId",
                              data: _vm.estoquesDestinoOptions,
                              searchable: false,
                              refresh:
                                _vm.model.id > 0 ? null : _vm.loadEstoques,
                              disabled: _vm.model.id > 0,
                            },
                            model: {
                              value: _vm.model.estoqueDestinoId,
                              callback: function ($$v) {
                                _vm.$set(_vm.model, "estoqueDestinoId", $$v)
                              },
                              expression: "model.estoqueDestinoId",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _vm.model.tipoMovimentacao == _vm.tiposMovimentacao.Saida
                    ? _c("div", { staticClass: "col-sm-6" }, [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: _vm.errors.first(
                                  "motivoMovimentacaoEstoqueId"
                                ),
                                expression:
                                  "errors.first('motivoMovimentacaoEstoqueId')",
                              },
                            ],
                            staticClass: "form-group",
                          },
                          [
                            _c(
                              "label",
                              {
                                staticClass: "control-label",
                                attrs: {
                                  for: "motivoMovimentacaoEstoqueId",
                                  title: _vm.$t("__.obrigatorio"),
                                },
                              },
                              [
                                _c("span", { staticStyle: { color: "red" } }, [
                                  _vm._v("*"),
                                ]),
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(
                                      _vm.$t(
                                        "__.Crud.movimentacaoestoque.edit_vue_html.motivoMovimentacaoEstoque"
                                      )
                                    ) +
                                    "\n                            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("combo-component", {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "required",
                                  expression: "'required'",
                                },
                              ],
                              attrs: {
                                id: "motivoMovimentacaoEstoqueId",
                                name: "motivoMovimentacaoEstoqueId",
                                data: _vm.motivoMovimentacaoEstoqueOptions,
                                searchable: false,
                                refresh: _vm.loadMotivoMovimentacaoEstoque,
                              },
                              model: {
                                value: _vm.model.motivoMovimentacaoEstoqueId,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.model,
                                    "motivoMovimentacaoEstoqueId",
                                    $$v
                                  )
                                },
                                expression: "model.motivoMovimentacaoEstoqueId",
                              },
                            }),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                ]),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "fieldset-component",
            {
              attrs: {
                title: _vm.$t(
                  "__.Crud.movimentacaoestoque.edit_vue_html.produtos"
                ),
                disabled: _vm.model.id > 0,
                collapsed: false,
                validate: false,
              },
            },
            [
              _c(
                "div",
                { attrs: { slot: "rows" }, slot: "rows" },
                [
                  _c("grid-component", {
                    attrs: {
                      data: _vm.model.movimentacaoEstoqueItens,
                      columns: _vm.gridColumns,
                      total: _vm.model.movimentacaoEstoqueItens.length,
                      "show-table-head": false,
                      "show-edit-item": false,
                      "show-action-column": _vm.model.id <= 0,
                      "show-sort-icons": false,
                    },
                    on: {
                      "remove-item": _vm.onRemoveItem,
                      "create-item": _vm.onCreateItem,
                    },
                  }),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c("fieldset", [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-sm-12 newbtn" }, [
                _c("div", { staticClass: "form-group" }, [
                  _c(
                    "button",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            _vm.model.id <= 0 ||
                            _vm.model.tipoMovimentacao ==
                              _vm.tiposMovimentacao.Saida,
                          expression:
                            "model.id <= 0 || model.tipoMovimentacao == tiposMovimentacao.Saida",
                        },
                      ],
                      staticClass: "btn btn-success",
                      attrs: { type: "submit" },
                    },
                    [
                      _c("i", {
                        staticClass: "fa fa-save",
                        attrs: { "aria-hidden": "true" },
                      }),
                      _vm._v(
                        "\n                            " +
                          _vm._s(_vm.$t("__.Crud.salvar")) +
                          "\n                        "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.model.id <= 0,
                          expression: "model.id <= 0",
                        },
                      ],
                      staticClass: "btn btn-secondary",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          _vm.load()
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n                            " +
                          _vm._s(_vm.$t("__.Crud.limpar")) +
                          "\n                        "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-secondary",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          _vm.cancel()
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n                            " +
                          _vm._s(_vm.$t("__.Crud.voltar")) +
                          "\n                        "
                      ),
                    ]
                  ),
                ]),
              ]),
            ]),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c("movimentacao-estoque-item-component", {
        ref: "movimentacaoEstoqueItemComponent",
        on: { "inclusao-item": _vm.onInclusaoItem },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }