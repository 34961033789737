enum StatusEnvioNF {
    Pendente = 0,
    Enviado = 1,
    Confirmado = 2,
    Cancelado = 3,
    Reprovado = 4,
    EnviadoCancelamento = 5,
    Erro = 6,
}

export default StatusEnvioNF;
