import Vue from "vue";

import modalComponent from "@/components/child/modal/modal.vue";
import { Component } from "@/decorators";

import "./loadingmodal.scss";

@Component({
    components: {
        modalComponent,
    },
})
export default class LoadingModalComponent extends Vue {
    loading = false;
    text = "";
    controlIds: Array<number> = [];

    public show(): void {
        this.showLoading();
    }
    public showLoading(): void {
        this.text = this.$t("__.ts.carregandooo").toString();
        this.loading = true;
    }
    public showLoadingControl(id: number): void {
        this.text = "";
        if (this.controlIds.filter(n => n == id).length == 0) this.controlIds.push(id);

        this.loading = true;
    }
    public showSaving(): void {
        this.text = this.$t("__.ts.salvando").toString();
        this.loading = true;
    }

    public showDeleting(): void {
        this.text = this.$t("__.ts.excluindo").toString();
        this.loading = true;
    }
    public showCustomText(text: string): void {
        this.text = text;
        this.loading = true;
    }
    public hide(id: number = null): void {
        if (id == null && this.controlIds.length == 0) this.loading = false;
        else {
            this.controlIds = this.controlIds.filter(n => n != id);
            if (this.controlIds.length == 0) this.loading = false;
        }
    }
}
