import Vue from "vue";

import comboComponent from "@/components/child/form/combo.vue";
import decimalComponent from "@/components/child/form/decimal.vue";
import decimalComSinalCustomComponent from "@/components/child/form/decimalComSinalCustom.vue";
import moedaComponent from "@/components/child/form/moeda.vue";
import { Component, Prop } from "@/decorators";
import TiposProduto from "@/models/enum/tiposProduto";
import FracionamentoModel from "@/models/fracionamentoModel";
import ItemEmbalagemFracionamentoModel from "@/models/itemEmbalagemFracionamentoModel";
import PaginationModel from "@/models/paginationModel";
import ProdutoModel from "@/models/produto/produtoModel";
import ProdutoService from "@/services/produto/produtoService";

import itemEmbalagemComponent from "./itemEmbalagem.vue";

@Component({
    components: {
        comboComponent,
        moedaComponent,
        decimalComponent,
        decimalComSinalCustomComponent,
        itemEmbalagemComponent,
    },
})
export default class KitEmbalagensComponent extends Vue {
    private produtoService = new ProdutoService();

    private model = new FracionamentoModel();

    private produtoOptions = [];

    @Prop(Array) itensVenda;
    @Prop({ type: Boolean, default: false }) alteracao: boolean;
    @Prop({ type: Boolean, default: false }) desativaCampos: boolean;

    public setFracionamento(fracionamento: FracionamentoModel) {
        this.model = new FracionamentoModel();
        this.model = { ...fracionamento, itensEmbalagem: [...fracionamento.itensEmbalagem] } as FracionamentoModel;
    }

    private onChangeItem(item: ItemEmbalagemFracionamentoModel, index: number) {
        item.produtoLotePadraoId = item.produtoLoteId;
        this.model.itensEmbalagem[index] = item;
        this.$emit("change-embalagens", this.model.itensEmbalagem);
    }

    private async onRemoveItem(item: ItemEmbalagemFracionamentoModel, index: number) {
        try {
            const response = await this.$showQuestion(
                this.$t("__.ts.atencao"),
                this.$t("__.ts.desejaRemov") + item.produto.descricao + "?",
            );
            if (response) {
                this.model.itensEmbalagem.splice(index, 1);
                await this.$showSuccess(this.$t("__.ts.exclusao"), this.$t("__.ts.regisExcluSucess"));
            }
        } catch {}
    }

    private async loadComboProdutos() {
        try {
            const data = await this.produtoService
                .listFilterProdutoTipo(TiposProduto.Embalagem, 1, 999999)
                .resolveWithJSON<PaginationModel<ProdutoModel>>();
            this.produtoOptions = data.list.map(e => ({ value: e.id, text: e.descricao }));
        } catch {}
    }

    private async mounted() {
        this.loadComboProdutos();
    }
}
