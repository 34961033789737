import BaseModel from "../../../../base/baseModel";

import TotalIcmsNfeModel from "./icms/totalIcmsNfeModel";
import TotalIssqnNfeModel from "./issqn/totalIssqnNfeModel";
import TotalRetTribNfeModel from "./retTrib/totalRetTribNfeModel";

export default class TotaisNfeModel extends BaseModel {
    totalIcms: TotalIcmsNfeModel = new TotalIcmsNfeModel();
    totalIssqn: TotalIssqnNfeModel = new TotalIssqnNfeModel();
    totalRetTrib: TotalRetTribNfeModel = new TotalRetTribNfeModel();
}
