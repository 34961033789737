var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "crud crud-list" },
    [
      _c("div", { staticClass: "info-div" }, [
        _c("h1", { staticClass: "title" }, [
          _vm._v(
            _vm._s(
              _vm.$t(
                "__.Components.parent.manipulacao.list_vue_html.manipuVenda"
              )
            )
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "content" }, [
          _c("div", { staticClass: "info-panel" }, [
            _c("span", [_vm._v(_vm._s(_vm.total))]),
            _vm._v(" "),
            _c("span", [
              _vm._v(
                _vm._s(
                  _vm.$t(
                    "__.Components.parent.manipulacao.list_vue_html.manipu"
                  )
                )
              ),
            ]),
            _vm._v(" "),
            _c("span", [
              _vm._v(
                _vm._s(
                  _vm.$t(
                    "__.Components.parent.manipulacao.list_vue_html.cadastradas"
                  )
                )
              ),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "grid-component",
        {
          ref: "gridcomponent",
          attrs: {
            actionName: "manipulacoes_venda",
            columns: _vm.gridColumns,
            controlaPageSize: true,
            data: _vm.gridData,
            extraActions: _vm.extraActions,
            "page-index": _vm.pageIndex,
            "page-size": _vm.pageSize,
            removeItemIcon: "fa-exclamation-circle",
            removeItemTitle: "Cancelar",
            "show-select-checkbox": true,
            startFilterKey: _vm.startFilterKey,
            tituloPagina: "Manipulação De Venda",
            total: _vm.total,
          },
          on: {
            "gerar-xls": function ($event) {
              _vm.pags = true
              _vm.tipoDocGerar = 0
            },
            "gerar-pdf": function ($event) {
              _vm.pags = true
              _vm.tipoDocGerar = 1
            },
            "gerar-impressao": function ($event) {
              _vm.pags = true
              _vm.tipoDocGerar = 2
            },
            "change-filter-key": _vm.onChangeFilterKey,
            "change-sort": _vm.onChangeSort,
            "edit-item": _vm.onEditItem,
            "remove-item": _vm.onRemoveItem,
            "create-item": _vm.onCreateItem,
            "change-page": _vm.onChangePage,
            "change-page-size": _vm.onChangePageSize,
            "extra-action": _vm.onExtraAction,
            "selected-values-changed": _vm.onSelectedValuesChanged,
          },
        },
        [
          _c("div", { attrs: { slot: "header" }, slot: "header" }, [
            _c("div", { staticClass: "filtro-lista" }, [
              _c(
                "button",
                {
                  staticClass: "filtro-avancado-button",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      _vm.isHidden = !_vm.isHidden
                    },
                  },
                },
                [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.$t("__.Crud.filtroAvancado")) +
                      "\n                    "
                  ),
                  _c("i", { staticClass: "fa fa-caret-down" }),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isHidden,
                      expression: "isHidden",
                    },
                  ],
                  staticClass: "drop-lista",
                },
                [
                  _c(
                    "form",
                    {
                      attrs: { novalidate: "novalidate" },
                      on: {
                        submit: function ($event) {
                          $event.preventDefault()
                          return _vm.getFormValues($event)
                        },
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "radio-group",
                          staticStyle: { "margin-bottom": "15px" },
                        },
                        [
                          _c("div", { staticStyle: { display: "table" } }, [
                            _c(
                              "div",
                              [
                                _c(
                                  "label",
                                  {
                                    staticClass: "control-label",
                                    staticStyle: { width: "auto" },
                                    attrs: { for: "estoqueId" },
                                  },
                                  [
                                    _vm._v(
                                      "\n                                        " +
                                        _vm._s(
                                          _vm.$t(
                                            "__.Components.parent.manipulacao.listFormulaPadrao_vue_html.estoque"
                                          )
                                        ) +
                                        "\n                                    "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c("combo-component", {
                                  attrs: {
                                    id: "estoqueId",
                                    name: "estoqueId",
                                    data: _vm.estoqueOptions,
                                    searchable: true,
                                  },
                                  model: {
                                    value: _vm.estoqueId,
                                    callback: function ($$v) {
                                      _vm.estoqueId = $$v
                                    },
                                    expression: "estoqueId",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              [
                                _c(
                                  "label",
                                  {
                                    staticClass: "control-label",
                                    staticStyle: { width: "auto" },
                                    attrs: { for: "manipulacaoOrdem" },
                                  },
                                  [
                                    _vm._v(
                                      "\n                                        " +
                                        _vm._s(
                                          _vm.$t(
                                            "__.Components.parent.manipulacao.listFormulaPadrao_vue_html.statusSituacao"
                                          )
                                        ) +
                                        "\n                                    "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c("combo-component", {
                                  staticStyle: { background: "white" },
                                  attrs: {
                                    id: "manipulacaoOrdem",
                                    name: "manipulacaoOrdem",
                                    data: _vm.situacaoManipulacaoOptions,
                                    defaultValue: -1,
                                  },
                                  model: {
                                    value: _vm.manipulacaoOrdemFiltro,
                                    callback: function ($$v) {
                                      _vm.manipulacaoOrdemFiltro = $$v
                                    },
                                    expression: "manipulacaoOrdemFiltro",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _vm._v(" "),
                          _c("br"),
                          _vm._v(" "),
                          _c("b", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "__.Components.parent.manipulacao.list_vue_html.data"
                                )
                              )
                            ),
                          ]),
                          _vm._v(" "),
                          _c("date-time-picker-component", {
                            staticStyle: { width: "100%" },
                            attrs: { id: "dataFilter1", name: "dataFilter1" },
                            model: {
                              value: _vm.dataFilter1,
                              callback: function ($$v) {
                                _vm.dataFilter1 = $$v
                              },
                              expression: "dataFilter1",
                            },
                          }),
                          _vm._v(" "),
                          _c("b", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "__.Components.parent.venda.list_vue_html.ate"
                                )
                              )
                            ),
                          ]),
                          _vm._v(" "),
                          _c("date-time-picker-component", {
                            attrs: { id: "dataFilter2", name: "dataFilter2" },
                            model: {
                              value: _vm.dataFilter2,
                              callback: function ($$v) {
                                _vm.dataFilter2 = $$v
                              },
                              expression: "dataFilter2",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "form-group" }, [
                        _c(
                          "button",
                          {
                            staticClass: "botao-filtrar",
                            attrs: { type: "submit" },
                          },
                          [
                            _vm._v(
                              "\n                                " +
                                _vm._s(_vm.$t("__.Crud.filtrar")) +
                                "\n                            "
                            ),
                          ]
                        ),
                      ]),
                    ]
                  ),
                ]
              ),
            ]),
          ]),
          _vm._v(" "),
          _vm.VALIDAR_PERMISSAO_USUARIO(
            "manipulacoes_venda",
            "ConcluirManipulacao"
          )
            ? _c(
                "div",
                {
                  attrs: { slot: "footer-extra-options" },
                  slot: "footer-extra-options",
                },
                [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          _vm.concluirManipulacoes()
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(
                            _vm.$t(
                              "__.Crud.formulapadrao.list_vue_html.concluirManipulacoes"
                            )
                          ) +
                          "\n            "
                      ),
                    ]
                  ),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.gridSelectedValues.length > 0
            ? _c(
                "div",
                {
                  attrs: { slot: "footer-extra-options" },
                  slot: "footer-extra-options",
                },
                [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          _vm.imprimirSelecionados()
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(
                            _vm.$t(
                              "__.Crud.formulapadrao.list_vue_html.imprimirManipulacoes"
                            )
                          ) +
                          "\n            "
                      ),
                    ]
                  ),
                ]
              )
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _c("impressaoComponent", { ref: "impressaoComponent" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }