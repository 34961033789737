var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("vue-multiselect", {
        attrs: {
          clearOnSelect: false,
          closeOnSelect: false,
          deselectLabel: "",
          label: "text",
          "limit-text": function (count) {
            return "e " + count + " outras opções"
          },
          limit: 8,
          multiple: true,
          options: _vm.options,
          placeholder: "",
          "preselect-first": false,
          preserveSearch: true,
          selectedLabel: "",
          selectLabel: "",
          taggable: _vm.taggable,
          tagPlaceholder: "",
          trackBy: "value",
        },
        on: { tag: _vm.addTag },
        model: {
          value: _vm.selected,
          callback: function ($$v) {
            _vm.selected = $$v
          },
          expression: "selected",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }