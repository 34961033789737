import BaseModel from "./base/baseModel";
import periodicidadeBalanco from "./enum/periodicidadeBalanco";
import tipoBalanco from "./enum/tipoBalanco";
import tipoCr from "./enum/tipoCr";

export default class SngpcManualModel extends BaseModel {
    public razaoSocial : string
    public rua : string
    public numero :number
    public bairro : string
    public cidade : string
    public telefone : string
    public cnpj : string
    public numeroAutorizacaoAnvisa : string
    public numeroAutorizacaoMapa : string
    public balanco : tipoBalanco
    public periodo : periodicidadeBalanco
    public ano : number
    public responsavel : string
    public crf : string
    public regiao : string
    public dataRelatorio : Date
    public estadoId : number
    public cidadeId: number
    public tipoCr: tipoCr
}