enum TypeTableLines {
    Nenhuma = 0,
    Ambas = 1,
    Horizontal = 2,
    Vertical = 3,
    SomenteCima = 4,
    SomenteBaixo = 5,
}

export default TypeTableLines;
