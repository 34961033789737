enum TipoDado {
    String = 0,
    Boolean = 1,
    Integer = 2,
    Decimal = 3,
    Money = 4,
    Date = 5,
    DateTime = 6,
    Percent = 8,
    Combo = 10,
    DecimalPonto = 11,
    Enum = 12,
    IntegerPonto = 13,
    Custom = 14,
    MultiSelect = 15,
    DateMonth = 16,
}

export default TipoDado;
