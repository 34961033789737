import BaseModel from "./base/baseModel";

export default class CapacidadeProducaoModel extends BaseModel {
    id?: number = null;
    franquiaid?: number = null;
    descricao: string = null;
    horario: Date = new Date();
    horarioString: string = null;
    horarioFinalDeSemana: Date = new Date();
    horarioFinalDeSemanaString: string = null;
    capacidadeSegunda: number = null;
    capacidadeTerca: number = null;
    capacidadeQuarta: number = null;
    capacidadeQuinta: number = null;
    capacidadeSexta: number = null;
    capacidadeSabado: number = null;
    capacidadeDomingo: number = null;
}
