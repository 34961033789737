import BaseModel from "../../../../../../base/baseModel";

export default class ProdutoLoteNfeModel extends BaseModel {
    descricao: string = null;
    produtoId: number = null;
    quantidade: number = null;
    dataFabricacao: Date = null;
    dataValidade: Date = null;
    precoMaximoConsumidor: number = null;
    //incluido a unidade de medida aqui para facilitar a visualização
    unidadeMedidaSigla = "";
    automaticoNfe: boolean = null;
    densidade: number = null;
    fracionarLote = false;
}
