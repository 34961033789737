import BaseModel from "../../../../base/baseModel";
import TiposPessoa from "../../../../enum/tiposPessoa";
import EnderecoNfeModel from "../endereco/enderecoNfeModel";

export default class EmitenteNfeModel extends BaseModel {
    tipoPessoa: TiposPessoa = null;
    nome: string = null;
    nomeFantasia: string = null;
    cnpj: string = null;
    inscricaoEstadual: string = null;
    cpf: string = null;
    endereco: EnderecoNfeModel = new EnderecoNfeModel();
}
