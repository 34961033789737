var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "fast-action-grid-panel" },
    [
      _c("div", { staticClass: "fast-action-grid-panel-left marcaDagua" }, [
        _c(
          "div",
          { staticClass: "component-body" },
          [
            _c("div", { staticClass: "component-body-info top-infos" }, [
              _c("span", { staticClass: "spanFinanceiro spanFinanceiro1" }, [
                _c("i", { staticClass: "fa fa-money-bill" }),
                _vm._v(
                  "\n                    " +
                    _vm._s(
                      _vm.$t(
                        "__.Components.parent.home.fastaction.vendas.vendas_vue_html.vendidosHj"
                      )
                    ) +
                    "\n                    "
                ),
                _c("b", [
                  _vm._v(_vm._s(_vm._f("filterMoeda")(_vm.totalVendidoHoje))),
                ]),
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "spanFinanceiro spanFinanceiro2" }, [
                _c("i", { staticClass: "fa fa-money-bill" }),
                _vm._v(
                  "\n                    " +
                    _vm._s(
                      _vm.$t(
                        "__.Components.parent.home.fastaction.vendas.vendas_vue_html.vendasAprovHj"
                      )
                    ) +
                    "\n                    "
                ),
                _c("b", [_vm._v(_vm._s(_vm.totalAprovadosHoje))]),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "row",
                staticStyle: { "margin-right": "0px", "margin-left": "0px" },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "col-sm-3",
                    class:
                      _vm.categoriaLista == 0
                        ? "btnHomeFinanceiro active"
                        : "btnHomeFinanceiro",
                    on: {
                      click: function ($event) {
                        _vm.trocarCategoria(0)
                      },
                    },
                  },
                  [
                    _c("i", {
                      staticClass: "fa fa-credit-card",
                      staticStyle: { position: "relative" },
                    }),
                    _vm._v(
                      "\n                    " +
                        _vm._s(
                          _vm.$t(
                            "__.Components.parent.home.fastaction.vendas.vendas_vue_html.vendasAprovadas"
                          )
                        ) +
                        "\n                "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "col-sm-3",
                    class:
                      _vm.categoriaLista == 1
                        ? "btnHomeFinanceiro active"
                        : "btnHomeFinanceiro",
                    on: {
                      click: function ($event) {
                        _vm.trocarCategoria(1)
                      },
                    },
                  },
                  [
                    _vm._m(0),
                    _vm._v(
                      "\n                    " +
                        _vm._s(
                          _vm.$t(
                            "__.Components.parent.home.fastaction.vendas.vendas_vue_html.semFaturamento"
                          )
                        ) +
                        "\n                "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "col-sm-3",
                    class:
                      _vm.categoriaLista == 2
                        ? "btnHomeFinanceiro active"
                        : "btnHomeFinanceiro",
                    on: {
                      click: function ($event) {
                        _vm.trocarCategoria(2)
                      },
                    },
                  },
                  [
                    _vm._m(1),
                    _vm._v(
                      "\n                    " +
                        _vm._s(
                          _vm.$t(
                            "__.Components.parent.home.fastaction.vendas.vendas_vue_html.envioNFPend"
                          )
                        ) +
                        "\n                "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "col-sm-3",
                    class: [
                      "component-body-row-foot",
                      _vm.categoriaLista == 3
                        ? "btnHomeFinanceiro active"
                        : "btnHomeFinanceiro",
                    ],
                    on: {
                      click: function ($event) {
                        _vm.trocarCategoria(3)
                      },
                    },
                  },
                  [
                    _vm._m(2),
                    _vm._v(
                      "\n                    " +
                        _vm._s(
                          _vm.$t(
                            "__.Components.parent.home.fastaction.vendas.vendas_vue_html.agRetornoPrefe"
                          )
                        ) +
                        "\n                "
                    ),
                  ]
                ),
              ]
            ),
            _vm._v(" "),
            _vm._l(_vm.listaVendas, function (venda) {
              return _c(
                "a",
                {
                  key: venda.id,
                  staticClass: "list-group-item",
                  staticStyle: { "padding-left": "46px", position: "relative" },
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: { cursor: "pointer" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          _vm.onSearch(venda)
                        },
                      },
                    },
                    [
                      _c("i", {
                        staticClass: "fa fa-exclamation-circle",
                        class: _vm.getClass(venda),
                        attrs: { title: _vm.getTitle(venda) },
                      }),
                      _vm._v(
                        "\n                    " +
                          _vm._s(
                            _vm.$t(
                              "__.Components.parent.home.fastaction.vendas.vendas_vue_html.cod"
                            )
                          ) +
                          "\n                    "
                      ),
                      _c("b", { staticClass: "color-yellow" }, [
                        _vm._v(_vm._s(venda.codigo)),
                      ]),
                      _vm._v("\n                    /\n                    "),
                      _c("b", { staticClass: "color-yellow" }, [
                        _vm._v(
                          _vm._s(_vm.getFirstName(venda.clienteNome, false))
                        ),
                      ]),
                      _vm._v(" "),
                      _vm.isFracionamento || _vm.isEstereis
                        ? _c("span", [
                            _vm._v(
                              "\n                        / " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Components.parent.home.fastaction.vendas.vendas_vue_html.dtHrEnvio"
                                  )
                                ) +
                                "\n                        "
                            ),
                            _c("b", { staticStyle: { color: "yellow" } }, [
                              _vm._v(
                                _vm._s(_vm.editDateWithTime(venda.dataEntrega))
                              ),
                            ]),
                          ])
                        : _c("span", [
                            _vm._v(
                              "\n                        / " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Components.parent.home.fastaction.vendas.vendas_vue_html.dtAprov"
                                  )
                                ) +
                                "\n                        "
                            ),
                            _c("b", { staticClass: "color-yellow" }, [
                              _vm._v(_vm._s(_vm.editDate(venda.dataAprovacao))),
                            ]),
                          ]),
                      _vm._v(
                        "\n                    / " +
                          _vm._s(
                            _vm.$t(
                              "__.Components.parent.venda.financeiro.parcela_vue_html.valor"
                            )
                          ) +
                          ":\n                    "
                      ),
                      _c("b", { staticClass: "valores color-yellow" }, [
                        _vm._v(_vm._s(_vm._f("filterMoeda")(venda.valorFinal))),
                      ]),
                      _vm._v(" "),
                      _vm.isFracionamento || _vm.isEstereis
                        ? _c("span", [
                            _vm._v(
                              "\n                        / " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Components.parent.home.fastaction.vendas.list_vue_html.setor"
                                  )
                                ) +
                                ":\n                        "
                            ),
                            _c("b", { staticStyle: { color: "yellow" } }, [
                              _vm._v(_vm._s(venda.setorDescricao)),
                            ]),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "balaoAtivos" },
                        [
                          _c("i", { staticClass: "fa fa-caret-up" }),
                          _vm._v(" "),
                          _c("b", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "__.Components.parent.home.fastaction.vendas.vendas_vue_html.ativos"
                                )
                              )
                            ),
                          ]),
                          _vm._v(" "),
                          _vm._l(venda.itens, function (item) {
                            return _c("div", { key: item.id }, [
                              _vm._v("- " + _vm._s(item.produtoDescricao)),
                            ])
                          }),
                        ],
                        2
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { attrs: { id: "container-icon" } }, [
                    _c("div", { staticClass: "container-home-icon" }, [
                      _vm.validarVisibilidadeBotao("CopiarVenda") &&
                      venda.situacao != 5
                        ? _c(
                            "div",
                            {
                              staticClass: "home-icon bg-dark-blue",
                              attrs: {
                                "data-toggle": "tooltip",
                                "data-placement": "left",
                                title: _vm.$t("__.Crud.copiar"),
                              },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  _vm.replicar(venda)
                                },
                              },
                            },
                            [
                              _c("i", {
                                staticClass: "fa fa-copy",
                                attrs: { "aria-hidden": "true" },
                              }),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.validarVisibilidadeBotao("CancelarVenda")
                        ? _c(
                            "div",
                            {
                              staticClass: "home-icon bg-red",
                              attrs: {
                                "data-toggle": "tooltip",
                                "data-placement": "left",
                                title: _vm.$t(
                                  "__.Components.parent.home.fastaction.vendas.vendas_vue_html.cancelarVenda"
                                ),
                              },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  _vm.onCancelar(venda)
                                },
                              },
                            },
                            [
                              _c("i", {
                                staticClass: "fa fa-exclamation-circle",
                                attrs: { "aria-hidden": "true" },
                              }),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.validarVisibilidadeBotao("ImprimirVenda")
                        ? _c(
                            "div",
                            {
                              staticClass: "home-icon bg-secondary",
                              attrs: {
                                "data-toggle": "tooltip",
                                "data-placement": "left",
                                title: _vm.$t(
                                  "__.Components.parent.home.fastaction.vendas.vendas_vue_html.imprimirVenda"
                                ),
                              },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  _vm.onImprimir(venda)
                                },
                              },
                            },
                            [
                              _c("i", {
                                staticClass: "fa fa-print",
                                attrs: { "aria-hidden": "true" },
                              }),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "home-icon bg-blue",
                          attrs: {
                            "data-toggle": "tooltip",
                            "data-placement": "left",
                            title: _vm.$t(
                              "__.Components.parent.home.fastaction.vendas.vendas_vue_html.visuVenda"
                            ),
                          },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              _vm.onSearch(venda)
                            },
                          },
                        },
                        [
                          _c("i", {
                            staticClass: "fa fa-eye",
                            attrs: { "aria-hidden": "true" },
                          }),
                        ]
                      ),
                      _vm._v(" "),
                      venda.receitas.length > 0
                        ? _c(
                            "div",
                            {
                              staticClass: "home-icon bg-red",
                              attrs: {
                                "data-toggle": "tooltip",
                                title: _vm.$t(
                                  "__.Components.parent.home.fastaction.manipulacao.manipulacao_vue_html.receita"
                                ),
                                "data-placement": "left",
                              },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  _vm.onExibirReceita(venda)
                                },
                              },
                            },
                            [
                              _c("i", {
                                staticClass: "fa fa-paperclip",
                                attrs: { "aria-hidden": "true" },
                              }),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.validarVisibilidadeBotao("FaturarVenda")
                        ? _c(
                            "div",
                            {
                              staticClass: "home-icon bg-green",
                              attrs: {
                                "data-toggle": "tooltip",
                                "data-placement": "left",
                                title: _vm.$t(
                                  "__.Components.parent.home.fastaction.vendas.vendas_vue_html.faturarVenda"
                                ),
                              },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  _vm.onFaturar(venda).withLoading()
                                },
                              },
                            },
                            [
                              _c("i", {
                                staticClass: "fa fa-dollar-sign",
                                attrs: { "aria-hidden": "true" },
                              }),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.validarVisibilidadeBotao("EmitirNotaVenda") &&
                      _vm.isFaturado(venda)
                        ? _c(
                            "div",
                            {
                              staticClass: "home-icon bg-orange",
                              attrs: {
                                "data-toggle": "tooltip",
                                "data-placement": "left",
                                title: _vm.$t(
                                  "__.Components.parent.home.fastaction.vendas.vendas_vue_html.emitirNF"
                                ),
                              },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  _vm.emitirNF(venda)
                                },
                              },
                            },
                            [
                              _c("i", {
                                staticClass: "fa fa-file-invoice",
                                attrs: { "aria-hidden": "true" },
                              }),
                            ]
                          )
                        : _vm._e(),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        attrs: {
                          id: "icon-button",
                          "data-toggle": "tooltip",
                          "data-placement": "left",
                          "data-original-title": "Create",
                        },
                      },
                      [
                        _c("p", { staticClass: "plus" }, [_vm._v("+")]),
                        _vm._v(" "),
                        _c("div", { staticClass: "edit" }, [
                          _c(
                            "p",
                            {
                              staticClass: "icon",
                              on: {
                                click: function ($event) {
                                  _vm.dragAndDrop = true
                                },
                              },
                            },
                            [
                              _c("i", {
                                staticClass: "fa fa-question",
                                attrs: { "aria-hidden": "true" },
                              }),
                            ]
                          ),
                        ]),
                      ]
                    ),
                  ]),
                ]
              )
            }),
          ],
          2
        ),
        _vm._v(" "),
        _c(
          "a",
          {
            staticClass: "ver-mais list-group-item",
            attrs: { type: "button" },
            on: {
              click: function ($event) {
                _vm.onVerMais().withLoading()
              },
            },
          },
          [
            _vm._v(
              "\n            " +
                _vm._s(
                  _vm.$t(
                    "__.Components.parent.home.fastaction.vendas.vendas_vue_html.verMais"
                  )
                ) +
                "\n        "
            ),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "fast-action-grid-panel-right" }, [
        _c("ul", [
          _vm.VALIDAR_PERMISSAO_USUARIO("clientes", "insert")
            ? _c(
                "li",
                [
                  _c("router-link", { attrs: { to: "/cliente-edicao" } }, [
                    _c("i", {
                      staticClass: "fa fa-user-plus fa-5x",
                      attrs: { "aria-hidden": "true" },
                    }),
                    _vm._v(" "),
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "__.Components.parent.home.fastaction.vendas.vendas_vue_html.novoCliente"
                          )
                        )
                      ),
                    ]),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "li",
            [
              _c("router-link", { attrs: { to: "/venda-lista" } }, [
                _c("i", {
                  staticClass: "fa fa-list fa-5x",
                  attrs: { "aria-hidden": "true" },
                }),
                _vm._v(" "),
                _c("span", [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        "__.Components.parent.home.fastaction.vendas.vendas_vue_html.vendas"
                      )
                    )
                  ),
                ]),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _vm.VALIDAR_PERMISSAO_USUARIO("vendas", "insert")
            ? _c(
                "li",
                [
                  _c("router-link", { attrs: { to: "/venda-edicao" } }, [
                    _c("i", {
                      staticClass: "fa orcamento-plus",
                      attrs: { "aria-hidden": "true" },
                    }),
                    _vm._v(" "),
                    _c("span", [
                      _vm._v(
                        "\n                        " +
                          _vm._s(
                            _vm.$t(
                              "__.Components.parent.home.fastaction.vendas.vendas_vue_html.novoOrcamento"
                            )
                          ) +
                          "\n                    "
                      ),
                    ]),
                  ]),
                ],
                1
              )
            : _vm._e(),
        ]),
      ]),
      _vm._v(" "),
      [
        _c(
          "shortcutComponent",
          {
            ref: "shortCutList",
            attrs: {
              modalClass: "bg-modal",
              showAddNew: false,
              showBtnOk: false,
            },
            on: { "selection-item": _vm.onConfirmList },
          },
          [
            _vm.loadListComponent
              ? _c(
                  "div",
                  { attrs: { slot: "component" }, slot: "component" },
                  [
                    _c("venda-list-component", {
                      key: _vm.flagReload,
                      ref: "vendaListComponent",
                      attrs: { categoria: _vm.categoriaLista },
                      on: {
                        "copiar-venda": _vm.replicar,
                        "cancelar-venda": _vm.onCancelar,
                        "imprimir-venda": _vm.onImprimir,
                        "faturar-venda": _vm.onFaturar,
                        "emitir-nota": _vm.emitirNF,
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ]
        ),
        _vm._v(" "),
        _c("impressao-component", { ref: "impressaoComponent" }),
        _vm._v(" "),
        _c(
          "shortcutComponent",
          {
            ref: "shortcutComponentFinanceiro",
            attrs: { modalClass: "lg-modal" },
            on: { "selection-item": _vm.onConfirmFinanceiro },
          },
          [
            _vm.loadPagamentoComponent && _vm.permiteParcelamentoVenda
              ? _c(
                  "div",
                  { attrs: { slot: "component" }, slot: "component" },
                  [
                    _vm.model.contaReceber != null
                      ? _c("financeiro-component", {
                          ref: "financeiroComponent",
                          attrs: {
                            situacaoVenda: _vm.model.situacao,
                            codigoVenda: _vm.model.codigo,
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
          ]
        ),
        _vm._v(" "),
        _c(
          "shortcutComponent",
          {
            ref: "shortcutComponent",
            attrs: { modalClass: "lm-modal" },
            on: { "selection-item": _vm.onConfirmFinanceiro },
          },
          [
            _vm.loadPagamentoComponent && !_vm.permiteParcelamentoVenda
              ? _c(
                  "div",
                  { attrs: { slot: "component" }, slot: "component" },
                  [
                    _c(
                      "vue-tabs",
                      {
                        ref: "tabFinanceiro",
                        model: {
                          value: _vm.tabFinanceiro,
                          callback: function ($$v) {
                            _vm.tabFinanceiro = $$v
                          },
                          expression: "tabFinanceiro",
                        },
                      },
                      [
                        _c(
                          "v-tab",
                          {
                            attrs: {
                              title: _vm.$t(
                                "__.Components.parent.home.fastaction.vendas.vendas_vue_html.novoPgto"
                              ),
                            },
                          },
                          [
                            _c("div", { staticClass: "row" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "col-sm-12",
                                  attrs: { id: "inclusaoPagamento" },
                                },
                                [
                                  _c("pagamento-component", {
                                    ref: "pagamentoComponent",
                                    on: {
                                      "inclusao-recebimento":
                                        _vm.onAdicionarPagamento,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "v-tab",
                          {
                            attrs: {
                              title: _vm.$t(
                                "__.Components.parent.home.fastaction.vendas.vendas_vue_html.pagamentos"
                              ),
                            },
                          },
                          [
                            _c("div", { staticClass: "row" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "col-sm-12",
                                  attrs: { id: "gridPagamento" },
                                },
                                [
                                  _vm.model.contaReceber != null
                                    ? _c("grid-component", {
                                        staticClass: "ignorePrint",
                                        attrs: {
                                          data: _vm.model.contaReceber
                                            .movimentacoes,
                                          columns: _vm.gridColumnsPagamento,
                                          "show-remove-item": true,
                                          showActionColumn: true,
                                          "show-table-head": false,
                                          "show-table-foot": false,
                                          "show-edit-item": false,
                                        },
                                        on: {
                                          "remove-item": _vm.onRemovePagamento,
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ]
        ),
        _vm._v(" "),
        _c(
          "shortcutComponent",
          {
            ref: "shortcutComponentEmissao",
            attrs: {
              btOkName: "Emitir Nota",
              extraButtons: _vm.extraButtonActionNfe,
              showAddNew: false,
            },
            on: {
              "selection-item": _vm.onEmitirNotaFracionamento,
              "extra-button-click": _vm.onExtraButtonActionClickNfe,
            },
          },
          [
            _vm.loadNfeComponent
              ? _c(
                  "div",
                  { attrs: { slot: "component" }, slot: "component" },
                  [
                    _c("nfe-component", {
                      ref: "nfeComponent",
                      attrs: { isEmissaoVenda: true },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ]
        ),
      ],
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "i",
      {
        staticClass: "fa fa-credit-card",
        staticStyle: { position: "relative" },
      },
      [
        _c("i", {
          staticClass:
            "fa fa-exclamation-circle component-body-row-icon color-light-red",
        }),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "i",
      {
        staticClass: "fa fa-credit-card",
        staticStyle: { position: "relative" },
      },
      [
        _c("i", {
          staticClass:
            "fa fa-exclamation-circle component-body-row-icon color-yellow",
        }),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "i",
      {
        staticClass: "fa fa-credit-card",
        staticStyle: { position: "relative" },
      },
      [
        _c("i", {
          staticClass:
            "fa fa-exclamation-circle component-body-row-icon color-light-green",
        }),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }