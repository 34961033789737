import ValidationErrorModel from "@/models/validationErrorModel";

import Bus from "./events/bus";

const errorMsgForDebug = (type: string) => `Promise que resolve para ${type} retornou (response.ok == false)`;

declare global {
    interface Promise<T> {
        resolveWithoutJSON(showErrorMessage?: boolean): Promise<boolean>;

        resolveWithJSON<T2>(showErrorMessage?: boolean, throwErro?: boolean): Promise<T2>;
        resolveWithResponse(showErrorMessage?: boolean, throwErro?: boolean): Promise<Response>;
        resolveWithText(): Promise<string>;
    }
}

Promise.prototype.resolveWithJSON = async function <T>(
    this: Promise<Response>,
    showErrorMessage = true,
    throwErro = true,
): Promise<T> {
    const response = await this;
    if (response.ok) {
        return response.json() as Promise<T>;
    }

    if (showErrorMessage) {
        const data = (await response.json()) as ValidationErrorModel[];

        Bus.$emit("global-validation-wrapper", data);
    }

    if (throwErro) {
        throw errorMsgForDebug("objeto json");
    }
};

Promise.prototype.resolveWithoutJSON = async function (
    this: Promise<Response>,
    showErrorMessage = true,
): Promise<boolean> {
    const response = await this;
    if (response.ok) {
        return true;
    }

    if (showErrorMessage) {
        const data = (await response.json()) as ValidationErrorModel[];

        Bus.$emit("global-validation-wrapper", data);
    }

    return false;
};

Promise.prototype.resolveWithText = async function (this: Promise<Response>): Promise<string> {
    const response = await this;
    if (!response.ok) {
        throw errorMsgForDebug("texto");
    }

    return response.text();
};

Promise.prototype.resolveWithResponse = async function (
    this: Promise<Response>,
    showErrorMessage = true,
    throwErro = true,
): Promise<Response> {
    const response = await this;
    if (response.ok) {
        return response;
    }

    if (showErrorMessage) {
        const data = (await response.json()) as ValidationErrorModel[];

        Bus.$emit("global-validation-wrapper", data);
    }

    if (throwErro) {
        throw errorMsgForDebug("objeto json");
    }

    return response;
};
