var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "fast-action-grid-panel" },
    [
      _c("div", { staticClass: "fast-action-grid-panel-left marcaDagua" }, [
        _c("div", { staticClass: "component-body" }, [
          _c("div", { staticClass: "component-body-info top-infos" }, [
            _c("span", { staticClass: "spanFinanceiro spanFinanceiro1" }, [
              _c("i", { staticClass: "fa fa-money-bill" }),
              _vm._v(
                "\n                    " +
                  _vm._s(
                    _vm.$t(
                      "__.Components.parent.home.fastaction.financeiro.financeiro_vue_html.sldInicialCaixa"
                    )
                  ) +
                  "\n                    "
              ),
              _c("b", [
                _vm._v(_vm._s(_vm._f("filterMoeda")(_vm.saldoCaixaInicial))),
              ]),
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "spanFinanceiro spanFinanceiro2" }, [
              _c("i", { staticClass: "fa fa-money-bill" }),
              _vm._v(
                "\n                    " +
                  _vm._s(
                    _vm.$t(
                      "__.Components.parent.home.fastaction.financeiro.financeiro_vue_html.sldAtualCaixa"
                    )
                  ) +
                  "\n                    "
              ),
              _c("b", [
                _vm._v(_vm._s(_vm._f("filterMoeda")(_vm.saldoCaixaAtual))),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              {
                staticClass: "col-sm-2",
                staticStyle: { "padding-left": "15px", "padding-right": "0" },
              },
              [
                _c(
                  "a",
                  {
                    class:
                      _vm.movimentoEspecie == 0
                        ? "btnHomeFinanceiro active"
                        : "btnHomeFinanceiro",
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        _vm.onTipoMovimento(0)
                      },
                    },
                  },
                  [
                    _c("i", { staticClass: "fa fa-money-bill" }),
                    _vm._v(
                      "\n                        " +
                        _vm._s(
                          _vm.$t(
                            "__.Components.parent.home.fastaction.financeiro.financeiro_vue_html.dinheiro"
                          )
                        ) +
                        "\n                    "
                    ),
                  ]
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-sm-2", staticStyle: { padding: "0" } },
              [
                _c(
                  "a",
                  {
                    class:
                      _vm.movimentoEspecie == 2
                        ? "btnHomeFinanceiro active"
                        : "btnHomeFinanceiro",
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        _vm.onTipoMovimento(2)
                      },
                    },
                  },
                  [
                    _c("i", { staticClass: "fa fa-barcode" }),
                    _vm._v(
                      "\n                        " +
                        _vm._s(
                          _vm.$t(
                            "__.Components.parent.home.fastaction.financeiro.financeiro_vue_html.boleto"
                          )
                        ) +
                        "\n                    "
                    ),
                  ]
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-sm-2", staticStyle: { padding: "0" } },
              [
                _c(
                  "a",
                  {
                    class:
                      _vm.movimentoEspecie == 5
                        ? "btnHomeFinanceiro active"
                        : "btnHomeFinanceiro",
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        _vm.onTipoMovimento(5)
                      },
                    },
                  },
                  [
                    _c("i", { staticClass: "fa fa-dollar-sign" }),
                    _vm._v(
                      "\n                        " +
                        _vm._s(
                          _vm.$t(
                            "__.Components.parent.home.fastaction.financeiro.financeiro_vue_html.transferecia"
                          )
                        ) +
                        "\n                    "
                    ),
                  ]
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-sm-2", staticStyle: { padding: "0" } },
              [
                _c(
                  "a",
                  {
                    class:
                      _vm.movimentoEspecie == 3
                        ? "btnHomeFinanceiro active"
                        : "btnHomeFinanceiro",
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        _vm.onTipoMovimento(3)
                      },
                    },
                  },
                  [
                    _c("i", { staticClass: "far fa-credit-card" }),
                    _vm._v(
                      "\n                        " +
                        _vm._s(
                          _vm.$t(
                            "__.Components.parent.home.fastaction.financeiro.financeiro_vue_html.debito"
                          )
                        ) +
                        "\n                    "
                    ),
                  ]
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-sm-2", staticStyle: { padding: "0" } },
              [
                _c(
                  "a",
                  {
                    class:
                      _vm.movimentoEspecie == 4
                        ? "btnHomeFinanceiro active"
                        : "btnHomeFinanceiro",
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        _vm.onTipoMovimento(4)
                      },
                    },
                  },
                  [
                    _c("i", { staticClass: "fa fa-credit-card" }),
                    _vm._v(
                      "\n                        " +
                        _vm._s(
                          _vm.$t(
                            "__.Components.parent.home.fastaction.financeiro.financeiro_vue_html.credito"
                          )
                        ) +
                        "\n                    "
                    ),
                  ]
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "col-sm-2",
                staticStyle: { "padding-left": "0", "padding-right": "15px" },
              },
              [
                _c(
                  "a",
                  {
                    class: [
                      "component-body-row-foot",
                      _vm.movimentoEspecie == 1
                        ? "btnHomeFinanceiro active"
                        : "btnHomeFinanceiro",
                    ],
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        _vm.onTipoMovimento(1)
                      },
                    },
                  },
                  [
                    _c("i", { staticClass: "far fa-file-alt" }),
                    _vm._v(
                      "\n                        " +
                        _vm._s(
                          _vm.$t(
                            "__.Components.parent.home.fastaction.financeiro.financeiro_vue_html.cheque"
                          )
                        ) +
                        "\n                    "
                    ),
                  ]
                ),
              ]
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-md-12" },
              _vm._l(_vm.movimentacoes.slice(0, 11), function (movi2) {
                return _c(
                  "a",
                  {
                    key: movi2.id,
                    class:
                      movi2.tiposMovimentacao == 1
                        ? "dollarGreen list-group-item"
                        : "dollarRed list-group-item",
                    staticStyle: {
                      "padding-left": "46px",
                      position: "relative",
                    },
                  },
                  [
                    _c("i", {
                      class:
                        movi2.especie == 0
                          ? "fa fa-money"
                          : movi2.especie == 2
                          ? "fa fa-barcode"
                          : movi2.especie == 1
                          ? "far fa-file-alt"
                          : movi2.especie == 3
                          ? "fa fa-credit-card-alt"
                          : movi2.especie == 4
                          ? "fa fa-credit-card"
                          : "fa fa-dollar-sign",
                    }),
                    _vm._v(" "),
                    _c("span", [_vm._v(_vm._s(movi2.descricao) + "/")]),
                    _vm._v(" "),
                    _c("span", [
                      _c("b", { staticStyle: { color: "yellow" } }, [
                        _vm._v(
                          _vm._s(
                            _vm.getFirstName(movi2.descClienteFornecedor, false)
                          )
                        ),
                      ]),
                      _vm._v(
                        "\n                            /\n                        "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("span", [_vm._v(_vm._s(movi2.especieDescricao) + "/")]),
                    _vm._v(" "),
                    _c("span", [
                      _vm._v(
                        "\n                            " +
                          _vm._s(
                            _vm.$t(
                              "__.Components.parent.home.fastaction.financeiro.financeiro_vue_html.vlr"
                            )
                          ) +
                          "\n                            "
                      ),
                      _c("b", { staticStyle: { color: "yellow" } }, [
                        _vm._v(_vm._s(_vm._f("filterMoeda")(movi2.valor))),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("i", { staticStyle: { float: "right" } }, [
                      _vm._v(_vm._s(_vm.getDateString(movi2.dataLancamento))),
                    ]),
                  ]
                )
              })
            ),
            _vm._v(" "),
            _c("div", { staticClass: "col-md-12" }, [
              _c(
                "a",
                {
                  staticClass: "ver-mais list-group-item",
                  attrs: { type: "button" },
                  on: { click: _vm.onVerMais },
                },
                [
                  _vm._v(
                    "\n                        " +
                      _vm._s(
                        _vm.$t(
                          "__.Components.parent.home.fastaction.financeiro.financeiro_vue_html.verMais"
                        )
                      ) +
                      "\n                    "
                  ),
                ]
              ),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "fast-action-grid-panel-right" }, [
        _c("ul", [
          _vm.VALIDAR_PERMISSAO_USUARIO("fechamento_de_caixa", "update") ||
          _vm.VALIDAR_PERMISSAO_USUARIO("abertura_de_caixa", "update")
            ? _c(
                "li",
                [
                  _vm.idSessaoCaixa > 0
                    ? _c(
                        "router-link",
                        {
                          staticClass: "bg-orange-gradiente",
                          attrs: { to: "/sessaoCaixa-fechar-caixa" },
                        },
                        [
                          _c("i", { staticClass: "fa fa-cash-register" }),
                          _vm._v(" "),
                          _c("span", [
                            _vm._v(
                              "\n                        " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Components.parent.home.fastaction.financeiro.financeiro_vue_html.fechamCaixa"
                                  )
                                ) +
                                "\n                    "
                            ),
                          ]),
                        ]
                      )
                    : _c(
                        "router-link",
                        { attrs: { to: "/sessaoCaixa-abertura" } },
                        [
                          _c("i", { staticClass: "fa fa-cash-register" }),
                          _vm._v(" "),
                          _c("span", [
                            _vm._v(
                              "\n                        " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Components.parent.home.fastaction.financeiro.financeiro_vue_html.abertCaixa"
                                  )
                                ) +
                                "\n                    "
                            ),
                          ]),
                        ]
                      ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.VALIDAR_PERMISSAO_USUARIO(
            "movimentacoes_de_caixa",
            "GerarReforço"
          )
            ? _c("li", [
                _c(
                  "a",
                  { attrs: { href: "#" }, on: { click: _vm.gerarReforco } },
                  [
                    _c("i", {
                      staticClass: "fa contas1",
                      attrs: { "aria-hidden": "true" },
                    }),
                    _vm._v(" "),
                    _c("span", [
                      _vm._v(
                        "\n                        " +
                          _vm._s(
                            _vm.$t(
                              "__.Components.parent.home.fastaction.financeiro.financeiro_vue_html.gerarReforco"
                            )
                          ) +
                          "\n                    "
                      ),
                    ]),
                  ]
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.VALIDAR_PERMISSAO_USUARIO(
            "movimentacoes_de_caixa",
            "GerarSangria"
          )
            ? _c("li", [
                _c(
                  "a",
                  { attrs: { href: "#" }, on: { click: _vm.gerarSangria } },
                  [
                    _c("i", {
                      staticClass: "fa contas2",
                      attrs: { "aria-hidden": "true" },
                    }),
                    _vm._v(" "),
                    _c("span", [
                      _vm._v(
                        "\n                        " +
                          _vm._s(
                            _vm.$t(
                              "__.Components.parent.home.fastaction.financeiro.financeiro_vue_html.gerarSangria"
                            )
                          ) +
                          "\n                    "
                      ),
                    ]),
                  ]
                ),
              ])
            : _vm._e(),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "shortcutComponent",
        {
          ref: "shortcutReforcoComponent",
          attrs: { btOkName: _vm.$t("__.Crud.salvar") },
          on: { "selection-item": _vm.onConfirmReforco },
        },
        [
          _c("div", { attrs: { slot: "component" }, slot: "component" }, [
            _vm.showReforco
              ? _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-sm-12" },
                    [_c("reforco-component", { ref: "reforcoComponent" })],
                    1
                  ),
                ])
              : _vm._e(),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "shortcutComponent",
        {
          ref: "shortcutSangriaComponent",
          attrs: { btOkName: _vm.$t("__.Crud.salvar") },
          on: { "selection-item": _vm.onConfirmSangria },
        },
        [
          _c("div", { attrs: { slot: "component" }, slot: "component" }, [
            _vm.showSangria
              ? _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-sm-12" },
                    [_c("sangria-component", { ref: "sangriaComponent" })],
                    1
                  ),
                ])
              : _vm._e(),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }