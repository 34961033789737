import Vue from "vue";
import { mapGetters, mapState } from "vuex";

import actionBarComponent from "@/components/child/actionBar/actionBar.vue";
import comboComponent from "@/components/child/form/combo.vue";
import decimalComponent from "@/components/child/form/decimal.vue";
import fieldsetComponent from "@/components/child/form/fieldset.vue";
import { Component } from "@/decorators";
import PreLoadPackModel from "@/models/auxiliar/preLoadPackModel";
import ConfiguracaoUsuarioModel from "@/models/configuracaoUsuarioModel";
import Modulos from "@/models/enum/configuracao/modulos";
import TipoCampoConfiguracao from "@/models/enum/configuracao/tipoCampoConfiguracao";
import EnumExtensions from "@/models/enum/extensions/enumExtensions";
import OrigemRotulo from "@/models/enum/rotulo/origemRotulo";
import FranquiaModel from "@/models/franquiaModel";
import PaginationModel from "@/models/paginationModel";
import UsuarioModel from "@/models/usuarioModel";
import ConfiguracaoUsuarioService from "@/services/configuracaoUsuarioService";
import UsuarioService from "@/services/usuarioService";
import { AppState, Getters } from "@/store/store";

import "../crud.scss";

@Component({
    components: {
        comboComponent,
        decimalComponent,
        fieldsetComponent,
        actionBarComponent,
    },
    computed: {
        ...mapState<AppState>({
            preLoadPack: state => state.preLoad.preLoadList,
        }),
        ...mapGetters(["GET_FRANQUIA_ATUAL", "HAS_PERMISSAO_PERFIL"] as Getters),
    },
})
export default class ConfiguracaoUsuarioListComponent extends Vue {
    // State computed props
    preLoadPack: PreLoadPackModel;
    GET_FRANQUIA_ATUAL: () => FranquiaModel;
    HAS_PERMISSAO_PERFIL: (isRede: boolean, isFranqueador: boolean, isSuporte: boolean) => Promise<boolean>;

    private service = new ConfiguracaoUsuarioService();
    verdadeiroOptions: Array<Object> = [
        { text: "Sim", value: true },
        { text: "N\u00e3o", value: false },
    ];

    private usuarioId: number = null;
    usuarioOptions: Array<Object> = [];
    rotulosLoteOptions: Array<Object> = [];
    rotulosManipulacaoOptions: Array<Object> = [];
    private isAdmin = false;

    itens: Array<ConfiguracaoUsuarioModel> = [];

    //apenas para melhorar visualizacao na vue
    private tipoLong = 0; //long?
    private tipoString = 1; //string
    private tipoBoolean = 2; //boolean
    private tipoEstoque = 3; //id tabela estoque
    private tipoContaCorrente = 4; //id tabela conta corrente
    private tipoContaGerencialEntrada = 5; //id Tabela conta gerencial
    private tipoContaGerencialSaida = 6; //id Tabela conta gerencial
    private tipoServico = 7; //id tabela servico
    private tipoFornecedor = 8; //id tabela fornecedor
    private naturezaOperacaoDentroEstado = 9; //id naturezaOperacao
    private naturezaOperacaoForaEstado = 10; //id naturezaOperacao
    private tipoUsuario = 11; //id do usuario
    private tipoRotulo = 12;
    private tipoDecimal = 13; //decimal

    private async load() {
        try {
            await this.loadConfigs();
            this.usuarioId = this.itens[0].usuarioId;

            if (this.isAdmin) {
                await this.loadUsuario();
            }
            await this.loadRotulosLote();
            await this.loadRotulosManipulacao();
        } catch {}
    }

    private async onChangeUsuario(value: number) {
        this.usuarioId = value;

        await this.loadConfigs();
    }

    private async loadConfigs() {
        this.itens = await this.service
            .list(this.usuarioId)
            .withLoading()
            .resolveWithJSON<ConfiguracaoUsuarioModel[]>();

        if (this.itens.length) {
            this.itens
                .filter(p => p.tipoCampo == TipoCampoConfiguracao.Boolean)
                .forEach(item => {
                    item.verdadeiroNum = item.verdadeiro ? 1 : 0;
                });
        }
    }

    private async loadUsuario() {
        try {
            const data = await new UsuarioService()
                .listByFranquiaId(this.GET_FRANQUIA_ATUAL().id)
                .resolveWithJSON<PaginationModel<UsuarioModel>>();

            this.usuarioOptions = data.list.map(item => ({ value: item.id, text: item.nome }));
        } catch {}
    }

    private async loadRotulosLote() {
        try {
            this.rotulosLoteOptions = this.preLoadPack.rotulos
                .filter(item => item.origemDados == OrigemRotulo.Lote)
                .map(item => ({ value: item.id, text: item.nome }));
        } catch {}
    }

    private async loadRotulosManipulacao() {
        try {
            this.rotulosManipulacaoOptions = this.preLoadPack.rotulos
                .filter(
                    item =>
                        item.origemDados == OrigemRotulo.Manipulacao ||
                        item.origemDados == OrigemRotulo.Acabado ||
                        item.origemDados == OrigemRotulo.Strips,
                )
                .map(item => ({ value: item.id, text: item.nome }));
        } catch {}
    }

    private async save() {
        try {
            const isValid = await this.$validator.validateAll();
            if (isValid) {
                this.itens
                    .filter(p => p.tipoCampo == TipoCampoConfiguracao.Boolean)
                    .forEach(item => {
                        item.verdadeiro = item.verdadeiroNum == 1;
                    });

                const sucesso = await this.service
                    .updateAll(this.itens, this.usuarioId)
                    .withLoading()
                    .resolveWithoutJSON();

                if (sucesso) {
                    await this.$showSuccess(this.$t("__.ts.alt"), this.$t("__.ts.regisSalvosSucess"));
                    this.load();
                }
            } else {
                this.$focusErrorField();
            }
        } catch {}
    }

    private getModuloName(modulo) {
        return "M\u00f3dulo " + EnumExtensions.getNameByValue(Modulos, modulo);
    }

    private async mounted() {
        this.isAdmin = await this.HAS_PERMISSAO_PERFIL(false, true, true);

        await this.load();
    }
}
