import Vue from "vue";

import actionBarComponent from "@/components/child/actionBar/actionBar.vue";
import { Component } from "@/decorators";
import ConfiguracaoIntegracaoModel from "@/models/configuracaoIntegracaoModel";
import FranquiaService from "@/services/franquiaService";

import "../../crud.scss";

@Component({
    components: {
        actionBarComponent,
    },
})
export default class FranquiaIntegracaoEditComponent extends Vue {
    private service = new FranquiaService();

    model = new ConfiguracaoIntegracaoModel();
    modelId = 0;

    private async load() {
        this.model = new ConfiguracaoIntegracaoModel();

        if (this.modelId > 0) {
            try {
                const data = await this.service
                    .getIntegracoesFranquia(this.modelId)
                    .withLoading()
                    .resolveWithJSON<ConfiguracaoIntegracaoModel>();

                this.model.updateFrom(data);
            } catch {
                this.$router.back();
            }
        }
    }

    private async save() {
        try {
            const isValid = await this.$validator.validateAll();
            if (isValid) {
                const sucesso = await this.service.updateIntegracao(this.model).withLoading().resolveWithoutJSON();
                if (sucesso) {
                    await this.$showInclusaoUpdate(this.model.id);
                    this.$router.back();
                }
            } else {
                this.$focusErrorField();
            }
        } catch {}
    }

    private cancel() {
        this.$router.back();
    }

    private mounted() {
        if (this.$route.query != null) {
            //@ts-ignore
            this.modelId = parseInt(this.$route.query.id);
            this.load();
        }
    }
}
