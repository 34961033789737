var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-sm-12" }, [
        _c("table", { staticClass: "table table-striped ajusteVer" }, [
          _c("tbody", [
            _c("br"),
            _vm._v(" "),
            _c("tr", [
              _c("th", { attrs: { scope: "row" } }, [
                _c("h4", [
                  _vm._v(_vm._s(_vm.$t("__.Crud.paciente.ver_vue_html.nome"))),
                ]),
              ]),
              _vm._v(" "),
              _c("td", [_c("h4", [_vm._v(_vm._s(_vm.data.nome))])]),
            ]),
            _vm._v(" "),
            _c("tr", [
              _c("th", { attrs: { scope: "row" } }, [
                _c("h4", [
                  _vm._v(
                    _vm._s(_vm.$t("__.Crud.paciente.ver_vue_html.nomeRotulo"))
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("td", [_c("h4", [_vm._v(_vm._s(_vm.data.nomeRotulo))])]),
            ]),
            _vm._v(" "),
            _c("tr", [
              _c("th", { attrs: { scope: "row" } }, [
                _c("h4", [
                  _vm._v(
                    _vm._s(_vm.$t("__.Crud.paciente.ver_vue_html.dtNasc"))
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("td", [
                _c("h4", [
                  _vm._v(_vm._s(_vm.getDateString(_vm.data.dataNascimento))),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("tr", [
              _c("th", { attrs: { scope: "row" } }, [
                _c("h4", [
                  _vm._v(
                    _vm._s(_vm.$t("__.Crud.paciente.ver_vue_html.genero"))
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("td", [_c("h4", [_vm._v(_vm._s(_vm.data.generoDescricao))])]),
            ]),
            _vm._v(" "),
            _c("tr", [
              _c("th", { attrs: { scope: "row" } }, [
                _c("h4", [
                  _vm._v(
                    _vm._s(_vm.$t("__.Crud.paciente.ver_vue_html.especie"))
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("td", [
                _c("h4", [_vm._v(_vm._s(_vm.data.especieAnimalDescricao))]),
              ]),
            ]),
            _vm._v(" "),
            _c("tr", [
              _c("th", { attrs: { scope: "row" } }, [
                _c("h4", [
                  _vm._v(_vm._s(_vm.$t("__.Crud.paciente.ver_vue_html.peso"))),
                ]),
              ]),
              _vm._v(" "),
              _c("td", [_c("h4", [_vm._v(_vm._s(_vm.data.peso))])]),
            ]),
            _vm._v(" "),
            _c("tr", [
              _c("th", { attrs: { scope: "row" } }, [
                _c("h4", [
                  _vm._v(
                    _vm._s(_vm.$t("__.Crud.paciente.ver_vue_html.cliente"))
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("td", [_c("h4", [_vm._v(_vm._s(_vm.data.clienteDescricao))])]),
            ]),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }