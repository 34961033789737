var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("fieldset", { staticClass: "fieldset-component" }, [
    _c(
      "legend",
      {
        style: "color:" + _vm.headerColor,
        attrs: { tabindex: "0" },
        on: {
          click: function ($event) {
            _vm.isCollapsed = !_vm.isCollapsed
          },
        },
      },
      [
        _c("div", [
          _c("i", {
            class: {
              "fa fa-plus-circle": _vm.isCollapsed,
              "fa fa-minus-circle": !_vm.isCollapsed,
            },
          }),
          _vm._v("\n            " + _vm._s(_vm.title) + "\n        "),
        ]),
        _vm._v(" "),
        _c("div", [
          _c("i", {
            class: {
              "fa fa-check fa-lg": _vm.isValid == true,
              "fa fa-times fa-lg": _vm.isValid == false,
            },
          }),
          _vm._v(" "),
          _vm.withCheckBox
            ? _c("div", { staticClass: "checkbox" }, [
                _c("label", { attrs: { for: "checkbox" } }, [
                  _vm._v(_vm._s(_vm.checkBoxLiteral)),
                ]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.isCheckedInternal,
                      expression: "isCheckedInternal",
                    },
                  ],
                  attrs: { type: "checkbox", name: "checkbox", id: "checkbox" },
                  domProps: {
                    checked: Array.isArray(_vm.isCheckedInternal)
                      ? _vm._i(_vm.isCheckedInternal, null) > -1
                      : _vm.isCheckedInternal,
                  },
                  on: {
                    change: function ($event) {
                      var $$a = _vm.isCheckedInternal,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 && (_vm.isCheckedInternal = $$a.concat([$$v]))
                        } else {
                          $$i > -1 &&
                            (_vm.isCheckedInternal = $$a
                              .slice(0, $$i)
                              .concat($$a.slice($$i + 1)))
                        }
                      } else {
                        _vm.isCheckedInternal = $$c
                      }
                    },
                  },
                }),
              ])
            : _vm._e(),
        ]),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.isCollapsed,
            expression: "!isCollapsed",
          },
        ],
      },
      [_vm._t("rows")],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }