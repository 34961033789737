import Vue from "vue";

import actionBarComponent from "@/components/child/actionBar/actionBar.vue";
import comboComponent from "@/components/child/form/combo.vue";
import dateTimePickerComponent from "@/components/child/form/datetimepicker.vue";
import moedaComponent from "@/components/child/form/moeda.vue";
import textareaComponent from "@/components/child/form/textarea.vue";
import LoadingModalComponent from "@/components/child/loadingmodal/loadingmodal";
import loadingModalComponent from "@/components/child/loadingmodal/loadingmodal.vue";
import { Component, Watch } from "@/decorators";
import TiposMovimentacao from "@/models/enum/tiposMovimentacao";
import ContaCorrenteModel from "@/models/financeiro/contaCorrenteModel";
import ContaGerencialModel from "@/models/financeiro/contaGerencialModel";
import FormaPagamentoModel from "@/models/financeiro/formaPagamentoModel";
import FormaRecebimentoModel from "@/models/financeiro/formaRecebimentoModel";
import MovimentacaoContaModel from "@/models/movimentacaoContaModel";
import PaginationModel from "@/models/paginationModel";
import ValidationErrorModel from "@/models/validationErrorModel";
import ContaCorrenteService from "@/services/financeiro/contaCorrenteService";
import ContaGerencialService from "@/services/financeiro/contaGerencialService";
import FormaPagamentoService from "@/services/financeiro/formaPagamentoService";
import FormaRecebimentoService from "@/services/financeiro/formaRecebimentoService";
import MovimentacaoContaService from "@/services/movimentacaoContaService";
import ValidationErrorWrapper from "@/wrappers/validationErrorWrapper";

import "../crud.scss";

@Component({
    components: {
        textareaComponent,
        comboComponent,
        dateTimePickerComponent,
        moedaComponent,
        loadingModalComponent,
        actionBarComponent,
    },
})
export default class MovimentacaoContaEditComponent extends Vue {
    private service = new MovimentacaoContaService();
    private validationErrorWrapper = new ValidationErrorWrapper(this.$validator);
    private loadingModalComponent: LoadingModalComponent = null;

    model: MovimentacaoContaModel = new MovimentacaoContaModel();
    modelId = 0;

    tiposMovimentacao = TiposMovimentacao;
    tiposMovimentacaoOptions: Array<Object> = [
        { text: "Entrada", value: TiposMovimentacao.Entrada },
        { text: "Saida", value: TiposMovimentacao.Saida },
    ];

    contasCorrentesOptions: Array<Object> = [];
    contasGerenciaisOptions: Array<Object> = [];
    formasRecebimentoOptions: Array<Object> = [];
    formasPagamentoOptions: Array<Object> = [];

    private async load() {
        this.model = new MovimentacaoContaModel();
        this.validationErrorWrapper.clearErrors();

        if (this.modelId > 0) {
            this.loadingModalComponent.showLoading();

            this.service
                .get(this.modelId)
                .then(response => {
                    return response.json() as Promise<MovimentacaoContaModel>;
                })
                .then(data => {
                    this.tipoMovimentacaoChanged(data.tipoMovimentacao).then(result => {
                        this.model.updateFrom(data);
                    });
                })
                .catch(error => {
                    this.$router.back();
                    this.loadingModalComponent.hide();
                });
        } else {
            this.model.dataPagamento = new Date();
            this.model.tipoMovimentacao = TiposMovimentacao.Entrada;
            this.tipoMovimentacaoChanged(this.model.tipoMovimentacao);
        }
    }

    private async loadContasCorrentes() {
        try {
            const data = await new ContaCorrenteService()
                .combo()
                .resolveWithJSON<PaginationModel<ContaCorrenteModel>>();
            this.contasCorrentesOptions = data.list.map(item => ({ value: item.id, text: item.descricao }));
        } catch {}
    }

    private async loadContasGerenciais(tipoMovimentacao: TiposMovimentacao) {
        try {
            const data = await new ContaGerencialService()
                .listByTipoMovimentacao("", 1, 999999, tipoMovimentacao)
                .resolveWithJSON<PaginationModel<ContaGerencialModel>>();
            this.contasGerenciaisOptions = data.list.map(item => ({ value: item.id, text: item.descricao }));
        } catch {}
    }

    private async loadFormasRecebimento() {
        try {
            const data = await new FormaRecebimentoService()
                .comboByEspecie()
                .resolveWithJSON<PaginationModel<FormaRecebimentoModel>>();
            this.formasRecebimentoOptions = data.list.map(item => ({ value: item.id, text: item.descricao }));
        } catch {}
    }

    private async loadFormasPagamento() {
        try {
            const data = await new FormaPagamentoService()
                .combo()
                .resolveWithJSON<PaginationModel<FormaPagamentoModel>>();
            this.formasPagamentoOptions = data.list.map(item => ({ value: item.id, text: item.descricao }));
        } catch {}
    }

    private save() {
        this.$validator
            .validateAll()
            .then(isValid => {
                if (isValid) {
                    this.model.dataLancamento = new Date();
                    this.loadingModalComponent.showSaving();

                    this.service[!this.model.id ? "insert" : "update"](this.model)
                        .then(async response => {
                            this.loadingModalComponent.hide();

                            if (response.ok) {
                                await this.$showInclusaoUpdate(this.model.id);
                                this.$router.back();
                            } else {
                                return response.json() as Promise<ValidationErrorModel[]>;
                            }
                        })
                        .then(data => {
                            this.validationErrorWrapper.showErrors(data);
                        })
                        .catch(error => {
                            this.loadingModalComponent.hide();
                        });
                }
            })
            .catch(error => {});
    }

    private cancel() {
        this.$router.back();
    }

    //@ts-ignore
    @Watch("model.tipoMovimentacao")
    private onTipoMovimentacaoChanged(tipoMovimentacao: TiposMovimentacao) {
        this.tipoMovimentacaoChanged(tipoMovimentacao);
    }

    private tipoMovimentacaoChanged(tipoMovimentacao: TiposMovimentacao) {
        let loadFormasFunction = null;
        switch (tipoMovimentacao) {
            case TiposMovimentacao.Entrada:
                this.model.formaPagamentoId = null;
                loadFormasFunction = this.loadFormasRecebimento;
                break;
            case TiposMovimentacao.Saida:
                this.model.formaRecebimentoId = null;
                loadFormasFunction = this.loadFormasPagamento;
                break;
        }

        this.loadingModalComponent.showLoading();

        return Promise.all([this.loadContasGerenciais(tipoMovimentacao), loadFormasFunction()])
            .catch(() => {})
            .then(result => {
                this.loadingModalComponent.hide();
            });
    }

    private mounted() {
        this.loadingModalComponent = this.$refs.loadingModalComponent as LoadingModalComponent;
        this.loadingModalComponent.showLoading();

        if (this.$route.params.id) this.modelId = +this.$route.params.id;

        return Promise.all([this.loadContasCorrentes()])
            .then(result => {
                this.load();
            })
            .catch(() => {
                this.loadingModalComponent.hide();
            });
    }
}
