declare global {
    interface File {
        /** get file extension according to file.type */
        getExtension: () => FileType;
        /** true if file type is in DocumentFileType */
        isDocument: () => boolean;
        /** true if file type is in ImageFileType */
        isImage: () => boolean;
        /** true if file type is in VideoFileType */
        isVideo: () => boolean;
    }
}

File.prototype.getExtension = function (this: File) {
    const extensions = Object.keys(FileType) as FileType[];
    return extensions.find(ext => FileType[ext] === this.type);
};

File.prototype.isDocument = function (this: File) {
    return DocumentFileType[this.getExtension()] != null;
};

File.prototype.isImage = function (this: File) {
    return ImageFileType[this.getExtension()] != null;
};

File.prototype.isVideo = function (this: File) {
    return VideoFileType[this.getExtension()] != null;
};

/** DocumentFileType: arquivos que contém texto, imagens, etc. */
export type DocumentFileType = keyof typeof DocumentFileType;
export const DocumentFileType = {
    doc: "application/msword",
    docx: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    odt: "application/vnd.oasis.opendocument.text",
    pdf: "application/pdf",
    txt: "text/plain",
    rft: "application/rtf",
};

/** ImageFileType: arquivos que podem ser exibidos como uma imagem estática. */
export type ImageFileType = keyof typeof ImageFileType;
export const ImageFileType = {
    bmp: "image/bmp",
    gif: "image/gif",
    jpg: "image/jpeg",
    png: "image/png",
    svg: "image/svg+xml",
    tif: "image/tiff",
    webp: "image/webp",
};

/** VideoFileType: arquivos que podem ser exibidos como um vídeo estático. */
export type VideoFileType = keyof typeof VideoFileType;
export const VideoFileType = {
    mp4: "video/mp4",
    avi: "video/avi",
    mov: "video/mov",
    wmv: "video/wmv",
    mpeg: "video/mpeg",
    mpg: "video/mpg",
    m4v: "video/m4v",
    gp: "video/3gp",
    f4v: "video/f4v ",
};

/** TabularFileType: arquivos onde os dados são organizados no formato de uma tabela. */
export type TabularFileType = keyof typeof TabularFileType;
export const TabularFileType = {
    csv: "text/csv",
    xls: "application/vnd.ms-excel",
    xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
};

export type FileType = keyof typeof FileType;
export const FileType = {
    ...DocumentFileType,
    ...ImageFileType,
    ...VideoFileType,
    ...TabularFileType,
};
