import { GridColumn, GridColumnType } from "@/components/child/grid/gridColumn";
import { Component } from "@/decorators";
import FormaRecebimentoModel from "@/models/financeiro/formaRecebimentoModel";
import FormaRecebimentoService from "@/services/financeiro/formaRecebimentoService";

import ListComponentBase from "../listComponentBase";

@Component
export default class FormaRecebimentoListComponent extends ListComponentBase<FormaRecebimentoModel> {
    get gridColumns(): Array<GridColumn> {
        return [
            new GridColumn("descricao", this.$t("__.ts.descricao"), GridColumnType.String),
            new GridColumn("ativo", this.$t("__.ts.ativo"), GridColumnType.Boolean),
        ];
    }

    private beforeMount() {
        this.setProps(new FormaRecebimentoService(), "formarecebimento", "descricao");
    }
}
