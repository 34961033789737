import ContaPagarPagamentoModel from "./contaPagarPagamentoModel";
import LancamentoBaseModel from "./lancamentoBaseModel";

export default class ContaPagarModel extends LancamentoBaseModel {
    fornecedorId = 0;
    movimentacoes: Array<ContaPagarPagamentoModel> = [];
    lancamentosVinculados: Array<ContaPagarModel> = [];

    totalValor = 0;
    totalDesconto = 0;
    totalJuros = 0;
    statusDescricao: string;
}

export interface ContaPagarListParameters {
    statusContas: number;
    filterKeyId: string;
    dataVencimentoIniStr?: string;
    dataVencimentoFinStr?: string;
    fornecedorId?: number;
    apenasVencidos?: boolean;
    contaGerencialId?: number;
    dataLancamentoIniStr?: string;
    dataLancamentoFinStr?: string;
}
