import Vue from "vue";

import Moment from "moment";

import { Component, Prop, Watch } from "@/decorators";
import TiposMovimentacaoEstoque from "@/models/enum/tiposMovimentacaoEstoque";
import MovimentacaoEstoqueItemModel from "@/models/movimentacaoEstoqueItemModel";
import ProdutoLoteModel from "@/models/produtoLoteModel";
import MovimentacaoEstoqueService from "@/services/movimentacaoEstoqueService";
import ProdutoLoteService from "@/services/produtoLoteService";

@Component
export default class VerLoteComponent extends Vue {
    private model = new ProdutoLoteModel();

    @Prop(Number) loteId: number;

    @Watch("loteId")
    private onChangeLoteId() {
        this.load(this.loteId).withLoading();
    }

    private async load(id: number) {
        try {
            this.model = await new ProdutoLoteService().get(id).then(r => r.json() as Promise<ProdutoLoteModel>);

            const data = await new MovimentacaoEstoqueService()
                .getMovimentacaoLote(this.loteId)
                .then(r => r.json() as Promise<MovimentacaoEstoqueItemModel[]>);
            this.model.movimentacoesEntradaEstoque = new Array<MovimentacaoEstoqueItemModel>();
            this.model.movimentacoesSaidaEstoque = new Array<MovimentacaoEstoqueItemModel>();
            for (const item of data) {
                if (item.tiposMovimentacao == TiposMovimentacaoEstoque.Entrada) {
                    this.model.movimentacoesEntradaEstoque.push(item);
                } else if (item.tiposMovimentacao == TiposMovimentacaoEstoque.Saida) {
                    this.model.movimentacoesSaidaEstoque.push(item);
                }
            }
        } catch {}
    }

    private getDateString(dt: Date) {
        return Moment(dt).format("DD/MM/YYYY");
    }

    private getDateTimeString(dt: Date) {
        return Moment(dt).format("DD/MM/YYYY HH:mm");
    }

    private getMoneyString(money: number) {
        if (money != null) {
            return "R$ " + money.toString().replace(".", ",");
        }
    }

    private mounted() {
        this.load(this.loteId).withLoading();
    }
}
