import { mapState } from "vuex";

import comboComponent from "@/components/child/form/combo.vue";
import dateTimePickerComponent from "@/components/child/form/datetimepicker.vue";
import infoBagdeComponent from "@/components/child/infoBagde/infoBagde.vue";
import searchComboComponent from "@/components/child/searchCombo/search.vue";
import { Component } from "@/decorators";
import PreLoadPackModel from "@/models/auxiliar/preLoadPackModel";
import ClienteModel from "@/models/clienteModel";
import ColetaModel from "@/models/expedicao/coletaModel";
import ColetaService from "@/services/expedicao/coletaService";
import { AppState } from "@/store/store";
import { editDateWithTime } from "@/utils/common/date";

import EditComponentBase from "../editComponentBase";

@Component({
    components: {
        dateTimePickerComponent,
        comboComponent,
        searchComboComponent,
        infoBagdeComponent,
    },
    computed: {
        ...mapState({
            preLoadList: (state: AppState) => state.preLoad.preLoadList,
        }),
        infoBagdeText() {
            return this.model.vendedorNome
                ? "Data criação: " +
                      editDateWithTime(this.model.dataCriacao) +
                      " - Vendedor: " +
                      this.model?.vendedorNome
                : "Data criação: " + editDateWithTime(this.model.dataCriacao);
        },
    },
})
export default class ColetaEditComponent extends EditComponentBase<ColetaModel> {
    // State computed props
    preLoadList: PreLoadPackModel;
    infoBagdeText: string;

    locaisEntregaOptions: Array<Object> = [];

    private customComboTextCliente = (p: ClienteModel) => ({
        value: p.id,
        text: `${p.id} - ` + (p.tipoPessoa != 1 ? p.nome : p.razaoSocial),
    });

    private async beforeMount() {
        await this.setProps(new ColetaService(), "coleta", new ColetaModel());
        this.locaisEntregaOptions = this.preLoadList.locaisEntregaCombo();
    }
}
