import GradePCPRequestModel from "@/models/gradePCPRequestModel";

import nfetch from "../utils/events/nfetch";

export default class GradePCPService {
    private controllerName = "GradePCP";

    public getGradePCP(request: GradePCPRequestModel): Promise<Response> {
        return nfetch(`/${this.controllerName}/GetGradePCP`, {
            method: "post",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json; charset=UTF-8",
            },
            body: JSON.stringify(request),
        });
    }
}
