import BaseModel from "./base/baseModel";
import FracionamentoCancelamentoItemEmbalagemModel from "./fracionamentoCancelamentoItemEmbalagemModel";
import FracionamentoCancelamentoItemModel from "./fracionamentoCancelamentoItemModel";

export default class FracionamentoCancelamentoModel extends BaseModel {
    id?: number = null;
    fracionamentoId: number = null;
    motivoCancelamentoId: number = null;
    usuarioCancelamentoId?: number = null;
    usuarioCancelamentoNome: string = null;
    observacaoCancelamento: string = null;
    dataCancelamento: Date = null;
    movimentacaoEstoqueId?: number = null;
    itens: Array<FracionamentoCancelamentoItemModel> = [];
    itensEmbalagem: Array<FracionamentoCancelamentoItemEmbalagemModel> = [];
}
