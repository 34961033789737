import MovimentacaoEstoqueModel from "../models/movimentacaoEstoqueModel";
import nfetch from "../utils/events/nfetch";

import { getCookie } from "./utils/serviceUtils";

export default class MovimentacaoEstoqueService {
    private controllerName = "MovimentacaoEstoque";

    public list(
        filterKey: string,
        sortKey: string,
        sortOrder: string,
        pageIndex: number,
        pageSize: number,
        customProperties: { [key: string]: any } = {},
    ): Promise<Response> {
        const encodedFilter = encodeURIComponent(filterKey);
        let custom = "";

        for (const key in customProperties) {
            if (customProperties[key] != undefined) {
                custom += `&${key}=${customProperties[key]}`;
            }
        }

        return nfetch(
            `/${this.controllerName}/List?filterKey=${encodedFilter}&sortKey=${sortKey}&sortOrder=${sortOrder}&pageIndex=${pageIndex}&pageSize=${pageSize}` +
                custom,
            {
                credentials: "same-origin",
            },
        );
    }

    public get(id: number): Promise<Response> {
        return nfetch(`/${this.controllerName}/Get?id=${id}`, {
            credentials: "same-origin",
        });
    }

    public getMovimentacaoLote(id: number): Promise<Response> {
        return nfetch(`/${this.controllerName}/GetMovimentacaoLote?id=${id}`, {
            credentials: "same-origin",
        });
    }

    public insert(model: MovimentacaoEstoqueModel): Promise<Response> {
        return nfetch(`/${this.controllerName}/Insert`, {
            method: "post",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json; charset=UTF-8",
                "XSRF-TOKEN": getCookie("XSRF-TOKEN"),
            },
            body: JSON.stringify(model),
        });
    }

    public inserirTransferenciaFiliais(model: MovimentacaoEstoqueModel): Promise<Response> {
        return nfetch(`/${this.controllerName}/InserirTransferenciaFiliais`, {
            method: "post",
            headers: {
                "Content-Type": "application/json; charset=UTF-8",
            },
            body: JSON.stringify(model),
        });
    }

    public update(model: MovimentacaoEstoqueModel): Promise<Response> {
        return nfetch(`/${this.controllerName}/Update`, {
            method: "post",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json; charset=UTF-8",
                "XSRF-TOKEN": getCookie("XSRF-TOKEN"),
            },
            body: JSON.stringify(model),
        });
    }

    public reverterMovimentacao(id: number): Promise<Response> {
        return nfetch(`/${this.controllerName}/ReverterMovimentacao?id=${id}`, {
            credentials: "same-origin",
        });
    }
}
