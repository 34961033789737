enum EspeciePagamento {
        Dinheiro = 0,
        Cheque = 1,
        Boleto = 2,
        CartaoDebito = 3,
        CartaoCredito = 4, 
        Transferencia = 5,
        Pix = 6,
        Convenio = 7,
        TransferenciaBancaria = 8, 
        Outros = 9
}

export default EspeciePagamento;