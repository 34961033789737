var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "crud crud-edit" },
    [
      _c("actionBarComponent", {
        attrs: {
          title: _vm.$t(
            "__.Crud.declaracaoConteudo.view_vue_html.docDeclaraConteudo"
          ),
          showSave: false,
        },
      }),
      _vm._v(" "),
      _c(
        "form",
        {
          attrs: { novalidate: "novalidate" },
          on: {
            submit: function ($event) {
              $event.preventDefault()
              _vm.save()
            },
          },
        },
        [
          _c("fieldset", [
            _c("div", { attrs: { slot: "rows" }, slot: "rows" }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-sm-4" }, [
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c(
                        "label",
                        { staticClass: "control-label", attrs: { for: "ano" } },
                        [
                          _vm._v(
                            "\n                                " +
                              _vm._s(
                                _vm.$t(
                                  "__.Components.parent.venda.editSingle_vue_html.cliente"
                                )
                              ) +
                              "\n                            "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("search-combo-component", {
                        attrs: {
                          id: "clienteId",
                          name: "clienteId",
                          actionName: "clientes",
                          advancedSearch: true,
                          filterable: false,
                          advancedSearchCampos: [
                            { campo: "text", descricao: "Nome" },
                            { campo: "cpf", descricao: "CNPJ/CPF" },
                            { campo: "celular", descricao: "Celular" },
                          ],
                          customComboText: _vm.customComboTextCliente,
                          customView: true,
                          propDescription: "nome",
                          service: "Cliente",
                        },
                        model: {
                          value: _vm.clienteId,
                          callback: function ($$v) {
                            _vm.clienteId = $$v
                          },
                          expression: "clienteId",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-sm-4" }, [
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c(
                        "label",
                        {
                          staticClass: "control-label",
                          attrs: { for: "tipoPeriodoId" },
                        },
                        [
                          _vm._v(
                            "\n                                " +
                              _vm._s(
                                _vm.$t(
                                  "__.Components.parent.venda.cancelamento_vue_html.vlrTotal"
                                )
                              ) +
                              "\n                            "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("moeda-component", {
                        staticClass: "form-control",
                        attrs: { id: "valorTotal", name: "valorTotal" },
                        model: {
                          value: _vm.valorTotal,
                          callback: function ($$v) {
                            _vm.valorTotal = $$v
                          },
                          expression: "valorTotal",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "fieldset-component",
            {
              attrs: {
                title: _vm.$t(
                  "__.Components.parent.notafiscalentrada.edit_vue_html.itens"
                ),
                collapsed: false,
              },
            },
            [
              _c(
                "div",
                { attrs: { slot: "rows" }, slot: "rows" },
                [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-sm-4" }, [
                      _c("div", { staticClass: "form-group" }, [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "fornecedor" },
                          },
                          [
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Components.parent.venda.financeiro.parcela_vue_html.descricao"
                                  )
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.modelItens.descricao,
                              expression: "modelItens.descricao",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            id: "descricao",
                            type: "text",
                            autocomplete: "off",
                            name: "descricao",
                          },
                          domProps: { value: _vm.modelItens.descricao },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.modelItens,
                                "descricao",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-sm-4" }, [
                      _c("div", { staticClass: "form-group" }, [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "fornecedor" },
                          },
                          [
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Crud.devolucaoCompra.edit_vue_html.cabecalhoQuantidade"
                                  )
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.modelItens.quantidade,
                              expression: "modelItens.quantidade",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            id: "quantidade",
                            type: "number",
                            autocomplete: "off",
                            name: "quantidade",
                          },
                          domProps: { value: _vm.modelItens.quantidade },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.modelItens,
                                "quantidade",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-sm-4" }, [
                      _c("div", { staticClass: "form-group" }, [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "fornecedor" },
                          },
                          [
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Crud.paciente.edit_vue_html.pesoKg"
                                  )
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.modelItens.peso,
                              expression: "modelItens.peso",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            id: "peso",
                            type: "number",
                            autocomplete: "off",
                            name: "peso",
                          },
                          domProps: { value: _vm.modelItens.peso },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.modelItens,
                                "peso",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("buttonIncluirComponent", {
                    on: { click: _vm.onInclusaoItem },
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      { staticClass: "col-sm-12" },
                      [
                        _c("grid-component", {
                          attrs: {
                            data: _vm.gridData,
                            columns: _vm.gridColumns,
                            showActionColumn: true,
                            showTableHead: false,
                            showTableFoot: false,
                            showRemoveItem: true,
                            showEditItem: false,
                          },
                          on: { "remove-item": _vm.onRemove },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c("fieldset", [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-sm-12 newbtn" }, [
                _c("div", { staticClass: "form-group" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-success",
                      attrs: { type: "submit" },
                    },
                    [
                      _vm._v(
                        "\n                            " +
                          _vm._s(_vm.$t("__.Crud.download")) +
                          "\n                        "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-secondary",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          _vm.cancel()
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n                            " +
                          _vm._s(_vm.$t("__.Crud.voltar")) +
                          "\n                        "
                      ),
                    ]
                  ),
                ]),
              ]),
            ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }