var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("span", { staticClass: "time" }, [
    _vm._v("\n    " + _vm._s(_vm.formatTimestamp(_vm.message.data)) + "\n    "),
    _vm.showStatus &&
    _vm.message.mensagemPropria &&
    _vm.message.statusMensagem != 0
      ? _c("i", {
          staticClass: "fa fa-check",
          attrs: { "aria-hidden": "true" },
        })
      : _vm.showStatus &&
        _vm.message.mensagemPropria &&
        _vm.message.statusMensagem == 0
      ? _c("i", {
          staticClass: "far fa-clock",
          attrs: { "aria-hidden": "true" },
        })
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }