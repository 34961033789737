import BaseModel from "./base/baseModel";

export default class VendaPreFaturamentoModel extends BaseModel {
    id?: number = null;
    vendaId = 0;
    formaRecebimentoId = 0;
    valor = 0;
    valorTroco?: number = null;
    parcelas?: number = null;
    formaRecebimentoDescricao?: string = null;
}
