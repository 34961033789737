var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "crud crud-edit" },
    [
      _c("actionBarComponent", {
        attrs: {
          title: _vm.$t("__.Crud.pcp.venda.pcpEdit"),
          showSave: _vm.model.vendaId && _vm.model.dataFim == null,
        },
        on: { onSave: _vm.save },
      }),
      _vm._v(" "),
      _c(
        "form",
        {
          attrs: { novalidate: "novalidate" },
          on: {
            submit: function ($event) {
              $event.preventDefault()
              _vm.save().withLoading()
            },
          },
        },
        [
          _c(
            "fieldset-component",
            {
              attrs: {
                title: _vm.$t("__.Crud.pcp.venda.vendaPCP"),
                collapsed: false,
              },
            },
            [
              _c("div", { attrs: { slot: "rows" }, slot: "rows" }, [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-sm-10" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("vendaCodigo"),
                            expression: "errors.first('vendaCodigo')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: {
                              for: "vendaCodigo",
                              title: _vm.$t("__.obrigatorio"),
                            },
                          },
                          [
                            _c("span", { staticStyle: { color: "red" } }, [
                              _vm._v("*"),
                            ]),
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t("__.Crud.pcp.venda.vendaCodigo")
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            { name: "focus", rawName: "v-focus" },
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.vendaCodigo,
                              expression: "vendaCodigo",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            autocomplete: "off",
                            type: "text",
                            id: "vendaCodigo",
                            name: "vendaCodigo",
                          },
                          domProps: { value: _vm.vendaCodigo },
                          on: {
                            keydown: function ($event) {
                              if (
                                !("button" in $event) &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              ) {
                                return null
                              }
                              $event.preventDefault()
                              _vm.onChangeVendaCodigo().withLoading()
                            },
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.vendaCodigo = $event.target.value
                            },
                          },
                        }),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-2" }, [
                    _c(
                      "button",
                      {
                        staticClass: "ajuste-button-form btn-secondary",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            _vm.onChangeVendaCodigo().withLoading()
                          },
                        },
                      },
                      [
                        _c("i", {
                          staticClass: "fa fa-search",
                          attrs: { "aria-hidden": "true" },
                        }),
                        _vm._v(
                          "\n                            " +
                            _vm._s(_vm.$t("__.Crud.pcp.pesquisar")) +
                            "\n                        "
                        ),
                      ]
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-sm-6" }, [
                    _c("div", { staticClass: "form-group" }, [
                      _c(
                        "label",
                        {
                          staticClass: "control-label",
                          attrs: { for: "ativo" },
                        },
                        [
                          _vm._v(
                            "\n                                " +
                              _vm._s(_vm.$t("__.Crud.pcp.venda.cliente")) +
                              "\n                            "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.vendaModel.clienteNome,
                            expression: "vendaModel.clienteNome",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          autocomplete: "off",
                          type: "text",
                          id: "cliente",
                          name: "cliente",
                          disabled: "",
                        },
                        domProps: { value: _vm.vendaModel.clienteNome },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.vendaModel,
                              "clienteNome",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-4" }, [
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "valorTotal" },
                          },
                          [
                            _vm._v(
                              "\n                                " +
                                _vm._s(_vm.$t("__.Crud.pcp.venda.valorTotal")) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("moeda-component", {
                          staticClass: "form-control",
                          attrs: {
                            id: "valorTotal",
                            name: "valorTotal",
                            disabled: "",
                          },
                          model: {
                            value: _vm.vendaModel.valorFinal,
                            callback: function ($$v) {
                              _vm.$set(_vm.vendaModel, "valorFinal", $$v)
                            },
                            expression: "vendaModel.valorFinal",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-2" }, [
                    _c("div", { staticClass: "form-group" }, [
                      _c(
                        "label",
                        {
                          staticClass: "control-label",
                          attrs: { for: "quantidadeItens" },
                        },
                        [
                          _vm._v(
                            "\n                                " +
                              _vm._s(
                                _vm.$t("__.Crud.pcp.venda.quantidadeItens")
                              ) +
                              "\n                            "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.vendaModel.itens.length,
                            expression: "vendaModel.itens.length",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          autocomplete: "off",
                          type: "text",
                          id: "quantidadeItens",
                          name: "quantidadeItens",
                          disabled: "",
                        },
                        domProps: { value: _vm.vendaModel.itens.length },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.vendaModel.itens,
                              "length",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                  ]),
                ]),
              ]),
            ]
          ),
          _vm._v(" "),
          _c("etapasComponent", {
            ref: "etapasComponent",
            attrs: {
              dataInicioProp: _vm.model.dataInicio,
              dataFimProp: _vm.model.dataFim,
            },
          }),
          _vm._v(" "),
          _c("fieldset", [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-sm-12 newbtn" }, [
                _c("div", { staticClass: "form-group" }, [
                  _vm.model.vendaId && _vm.model.dataFim == null
                    ? _c(
                        "button",
                        {
                          staticClass: "btn btn-success",
                          attrs: { type: "submit" },
                        },
                        [
                          _c("i", {
                            staticClass: "fa fa-save",
                            attrs: { "aria-hidden": "true" },
                          }),
                          _vm._v(
                            "\n                            " +
                              _vm._s(_vm.$t("__.Crud.salvar")) +
                              "\n                        "
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-secondary",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          _vm.cancel()
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n                            " +
                          _vm._s(_vm.$t("__.Crud.voltar")) +
                          "\n                        "
                      ),
                    ]
                  ),
                ]),
              ]),
            ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }