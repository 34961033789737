import Vue from "vue";

import { Component, Prop } from "@/decorators";
import LinkPagamentoModel from "@/models/financeiro/gatewayPagamento/linkPagamentoModel";
import LinkPagamentoService from "@/services/linkPagamentoService";

import "../crud/crud.scss";

@Component
export default class LinkPagamentoComponent extends Vue {
    service = new LinkPagamentoService();
    linkPagamento = "";

    @Prop(Number) vendaId: number;

    private async copiarTexto() {
        await navigator.clipboard.writeText(this.linkPagamento);

        this.$notifySuccess(this.$t("__.ts.linkCopiado") as string);
    }

    private async load() {
        try {
            if (this.vendaId) {
                const retorno = await this.service
                    .getLinkPagamentoAtivoByVendaId(this.vendaId)
                    .withLoading()
                    .resolveWithJSON<LinkPagamentoModel>();

                if (retorno) {
                    this.linkPagamento = retorno.linkPagamento;
                }
            }
        } catch {}
    }

    private async criarLinkPagamento() {
        if (this.linkPagamento) {
            const response = await this.$showQuestion(this.$t("__.ts.atencao"), this.$t("__.ts.desejaGerarNovoLink"));

            if (response) {
                const retorno = await this.service
                    .criarLinkPagamento(this.vendaId)
                    .withLoading()
                    .resolveWithJSON<LinkPagamentoModel>();

                if (retorno) {
                    this.linkPagamento = retorno.linkPagamento;

                    this.$notifySuccess(this.$t("__.ts.linkPagamentoAtualizado") as string);
                }
            }
        } else {
            const retorno = await this.service
                .criarLinkPagamento(this.vendaId)
                .withLoading()
                .resolveWithJSON<LinkPagamentoModel>();

            if (retorno) {
                this.linkPagamento = retorno.linkPagamento;
            }
        }
    }

    private mounted() {
        this.load();
    }
}
