var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "crud crud-edit" },
    [
      !_vm.isModal
        ? _c(
            "actionBarComponent",
            {
              attrs: {
                showSave: !_vm.somenteConsulta,
                slots: 1,
                title: _vm.$t(
                  "__.Crud.fornecedor.edit_vue_html.fornecedorEdit"
                ),
              },
              on: { onSave: _vm.save },
              scopedSlots: _vm._u([
                {
                  key: "buttonSlot0",
                  fn: function (ref) {
                    var open = ref.open
                    return _vm.model.id
                      ? _c(
                          "div",
                          {
                            attrs: {
                              title: _vm.$t("__.ts.historicoAlteracoes"),
                            },
                            on: {
                              click: function ($event) {
                                _vm.onShowLogsFornecedor(open)
                              },
                            },
                          },
                          [
                            _c("i", {
                              staticClass: "fa fa-history",
                              attrs: { slot: "button", "aria-hidden": "true" },
                              slot: "button",
                            }),
                          ]
                        )
                      : _vm._e()
                  },
                },
              ]),
            },
            [
              _c(
                "div",
                { attrs: { slot: "modalSlot0" }, slot: "modalSlot0" },
                [
                  _c("logEntidadeComponent", {
                    attrs: { registroId: _vm.model.id, entidade: 9 },
                  }),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "form",
        {
          attrs: { novalidate: "novalidate" },
          on: {
            submit: function ($event) {
              $event.preventDefault()
              _vm.save()
            },
          },
        },
        [
          _c(
            "fieldset-component",
            {
              attrs: {
                title: _vm.$t("__.Crud.fornecedor.edit_vue_html.dadosGerais"),
                collapsed: false,
              },
            },
            [
              _c("div", { attrs: { slot: "rows" }, slot: "rows" }, [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-sm-3" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("tipoPessoa"),
                            expression: "errors.first('tipoPessoa')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: {
                              for: "tipoPessoa",
                              title: _vm.$t("__.obrigatorio"),
                            },
                          },
                          [
                            _c("span", { staticStyle: { color: "red" } }, [
                              _vm._v("*"),
                            ]),
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Crud.fornecedor.edit_vue_html.tipo"
                                  )
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("combo-component", {
                          directives: [
                            { name: "focus", rawName: "v-focus" },
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required",
                              expression: "'required'",
                            },
                          ],
                          attrs: {
                            id: "tipoPessoa",
                            name: "tipoPessoa",
                            data: _vm.tiposPessoaOptions,
                            searchable: false,
                            disabled: _vm.somenteConsulta,
                          },
                          model: {
                            value: _vm.model.tipoPessoa,
                            callback: function ($$v) {
                              _vm.$set(_vm.model, "tipoPessoa", $$v)
                            },
                            expression: "model.tipoPessoa",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            !_vm.model.exterior &&
                            _vm.model.tipoPessoa == _vm.tiposPessoa.Fisica,
                          expression:
                            "!model.exterior && model.tipoPessoa == tiposPessoa.Fisica",
                        },
                      ],
                      staticClass: "col-sm-4",
                    },
                    [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: _vm.errors.first("cpf"),
                              expression: "errors.first('cpf')",
                            },
                          ],
                          staticClass: "form-group",
                        },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "control-label",
                              attrs: {
                                for: "cpf",
                                title: _vm.$t("__.obrigatorio"),
                              },
                            },
                            [
                              _c("span", { staticStyle: { color: "red" } }, [
                                _vm._v("*"),
                              ]),
                              _vm._v(
                                "\n                                " +
                                  _vm._s(
                                    _vm.$t(
                                      "__.Crud.fornecedor.edit_vue_html.cpf"
                                    )
                                  ) +
                                  "\n                            "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("cpf-component", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: {
                                  rules: {
                                    cpf: true,
                                    required:
                                      !_vm.model.exterior &&
                                      _vm.model.tipoPessoa ==
                                        _vm.tiposPessoa.Fisica,
                                  },
                                },
                                expression:
                                  "{\n                                    rules: {\n                                        cpf: true,\n                                        required: !model.exterior && model.tipoPessoa == tiposPessoa.Fisica,\n                                    },\n                                }",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: {
                              id: "cpf",
                              name: "cpf",
                              disabled: _vm.somenteConsulta,
                            },
                            model: {
                              value: _vm.model.cpf,
                              callback: function ($$v) {
                                _vm.$set(_vm.model, "cpf", $$v)
                              },
                              expression: "model.cpf",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            !_vm.model.exterior &&
                            _vm.model.tipoPessoa == _vm.tiposPessoa.Fisica,
                          expression:
                            "!model.exterior && model.tipoPessoa == tiposPessoa.Fisica",
                        },
                      ],
                      staticClass: "col-sm-4",
                    },
                    [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: _vm.errors.first("rg"),
                              expression: "errors.first('rg')",
                            },
                          ],
                          staticClass: "form-group",
                        },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "control-label",
                              attrs: { for: "rg" },
                            },
                            [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(
                                    _vm.$t(
                                      "__.Crud.fornecedor.edit_vue_html.rg"
                                    )
                                  ) +
                                  "\n                            "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.model.rg,
                                expression: "model.rg",
                              },
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "alpha_num",
                                expression: "'alpha_num'",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: {
                              autocomplete: "off",
                              type: "text",
                              id: "rg",
                              name: "rg",
                              disabled: _vm.somenteConsulta,
                            },
                            domProps: { value: _vm.model.rg },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(_vm.model, "rg", $event.target.value)
                              },
                            },
                          }),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            !_vm.model.exterior &&
                            _vm.model.tipoPessoa == _vm.tiposPessoa.Juridica,
                          expression:
                            "!model.exterior && model.tipoPessoa == tiposPessoa.Juridica",
                        },
                      ],
                      staticClass: "col-sm-4",
                    },
                    [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: _vm.errors.first("cnpj"),
                              expression: "errors.first('cnpj')",
                            },
                          ],
                          staticClass: "form-group",
                        },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "control-label",
                              attrs: {
                                for: "cnpj",
                                title: _vm.$t("__.obrigatorio"),
                              },
                            },
                            [
                              _c("span", { staticStyle: { color: "red" } }, [
                                _vm._v("*"),
                              ]),
                              _vm._v(
                                "\n                                " +
                                  _vm._s(
                                    _vm.$t(
                                      "__.Crud.fornecedor.edit_vue_html.cnpj"
                                    )
                                  ) +
                                  "\n                            "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("cnpj-component", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: {
                                  rules: {
                                    cnpj: true,
                                    required:
                                      !_vm.model.exterior &&
                                      _vm.model.tipoPessoa ==
                                        _vm.tiposPessoa.Juridica,
                                  },
                                },
                                expression:
                                  "{\n                                    rules: {\n                                        cnpj: true,\n                                        required: !model.exterior && model.tipoPessoa == tiposPessoa.Juridica,\n                                    },\n                                }",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: {
                              type: "text",
                              id: "cnpj",
                              name: "cnpj",
                              disabled: _vm.somenteConsulta,
                            },
                            model: {
                              value: _vm.model.cnpj,
                              callback: function ($$v) {
                                _vm.$set(_vm.model, "cnpj", $$v)
                              },
                              expression: "model.cnpj",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            !_vm.model.exterior &&
                            _vm.model.tipoPessoa == _vm.tiposPessoa.Juridica,
                          expression:
                            "!model.exterior && model.tipoPessoa == tiposPessoa.Juridica",
                        },
                      ],
                      staticClass: "col-sm-2",
                    },
                    [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: _vm.errors.first("inscricaoEstadual"),
                              expression: "errors.first('inscricaoEstadual')",
                            },
                          ],
                          staticClass: "form-group",
                        },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "control-label",
                              attrs: { for: "inscricaoEstadual" },
                            },
                            [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(
                                    _vm.$t(
                                      "__.Crud.fornecedor.edit_vue_html.ie"
                                    )
                                  ) +
                                  "\n                            "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.model.inscricaoEstadual,
                                expression: "model.inscricaoEstadual",
                              },
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "inscricaoEstadual|max:20",
                                expression: "'inscricaoEstadual|max:20'",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: {
                              autocomplete: "off",
                              type: "text",
                              id: "inscricaoEstadual",
                              name: "inscricaoEstadual",
                              placeholder: "___-_______",
                              disabled: _vm.somenteConsulta,
                            },
                            domProps: { value: _vm.model.inscricaoEstadual },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.model,
                                  "inscricaoEstadual",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            !_vm.model.exterior &&
                            _vm.model.tipoPessoa == _vm.tiposPessoa.Juridica,
                          expression:
                            "!model.exterior && model.tipoPessoa == tiposPessoa.Juridica",
                        },
                      ],
                      staticClass: "col-sm-2",
                    },
                    [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: _vm.errors.first("contribuinteEstadual"),
                              expression:
                                "errors.first('contribuinteEstadual')",
                            },
                          ],
                          staticClass: "form-group",
                        },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "control-label",
                              attrs: { for: "contribuinteEstadual" },
                            },
                            [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(
                                    _vm.$t(
                                      "__.Crud.fornecedor.edit_vue_html.contribuinteEstadual"
                                    )
                                  ) +
                                  "\n                            "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("checkbox-component", {
                            attrs: {
                              id: "contribuinteEstadual",
                              name: "contribuinteEstadual",
                              disabled: _vm.somenteConsulta,
                            },
                            model: {
                              value: _vm.model.contribuinteEstadual,
                              callback: function ($$v) {
                                _vm.$set(_vm.model, "contribuinteEstadual", $$v)
                              },
                              expression: "model.contribuinteEstadual",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _vm.model.exterior
                    ? _c("div", { staticClass: "col-sm-8" }, [
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c(
                              "label",
                              {
                                staticClass: "control-label",
                                attrs: {
                                  for: "idEstrangeiro",
                                  title: _vm.$t("__.obrigatorio"),
                                },
                              },
                              [
                                _c("span", { staticStyle: { color: "red" } }, [
                                  _vm._v("*"),
                                ]),
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(
                                      _vm.$t(
                                        "__.Crud.fornecedor.edit_vue_html.documentoEstrangeiro"
                                      )
                                    ) +
                                    "\n                            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("data-tooltip-component", {
                              attrs: {
                                text: "Número do passaporte, ou outro documento legal do estrangeiro",
                              },
                            }),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.model.idEstrangeiro,
                                  expression: "model.idEstrangeiro",
                                },
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "required",
                                  expression: "'required'",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: {
                                autocomplete: "off",
                                id: "idEstrangeiro",
                                name: "idEstrangeiro",
                                disabled: _vm.somenteConsulta,
                              },
                              domProps: { value: _vm.model.idEstrangeiro },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.model,
                                    "idEstrangeiro",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-1" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("exterior"),
                            expression: "errors.first('exterior')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "exterior" },
                          },
                          [
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Crud.fornecedor.edit_vue_html.exterior"
                                  )
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("checkbox-component", {
                          attrs: {
                            id: "exterior",
                            name: "exterior",
                            disabled: _vm.somenteConsulta,
                          },
                          model: {
                            value: _vm.model.exterior,
                            callback: function ($$v) {
                              _vm.$set(_vm.model, "exterior", $$v)
                            },
                            expression: "model.exterior",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-sm-8" }, [
                    _vm.model.tipoPessoa == _vm.tiposPessoa.Juridica
                      ? _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: _vm.errors.first("nomeFantasia"),
                                expression: "errors.first('nomeFantasia')",
                              },
                            ],
                            staticClass: "form-group",
                          },
                          [
                            _c(
                              "label",
                              {
                                staticClass: "control-label",
                                attrs: {
                                  for: "nomeFantasia",
                                  title: _vm.$t("__.obrigatorio"),
                                },
                              },
                              [
                                _c("span", { staticStyle: { color: "red" } }, [
                                  _vm._v("*"),
                                ]),
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(
                                      _vm.$t(
                                        "__.Crud.fornecedor.edit_vue_html.nomeFants"
                                      )
                                    ) +
                                    "\n                            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.model.nomeFantasia,
                                  expression: "model.nomeFantasia",
                                },
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: {
                                    rules: {
                                      max: 256,
                                      required:
                                        _vm.model.tipoPessoa ==
                                        _vm.tiposPessoa.Juridica,
                                    },
                                  },
                                  expression:
                                    "{\n                                    rules: { max: 256, required: model.tipoPessoa == tiposPessoa.Juridica },\n                                }",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: {
                                autocomplete: "off",
                                type: "text",
                                id: "nomeFantasia",
                                name: "nomeFantasia",
                                disabled: _vm.somenteConsulta,
                              },
                              domProps: { value: _vm.model.nomeFantasia },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.model,
                                    "nomeFantasia",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.model.tipoPessoa == _vm.tiposPessoa.Fisica
                      ? _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: _vm.errors.first("nome"),
                                expression: "errors.first('nome')",
                              },
                            ],
                            staticClass: "form-group",
                          },
                          [
                            _c(
                              "label",
                              {
                                staticClass: "control-label",
                                attrs: {
                                  for: "nome",
                                  title: _vm.$t("__.obrigatorio"),
                                },
                              },
                              [
                                _c("span", { staticStyle: { color: "red" } }, [
                                  _vm._v("*"),
                                ]),
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(
                                      _vm.$t(
                                        "__.Crud.fornecedor.edit_vue_html.nome"
                                      )
                                    ) +
                                    "\n                            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.model.nome,
                                  expression: "model.nome",
                                },
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: {
                                    rules: {
                                      alpha_spaces: true,
                                      max: 256,
                                      required:
                                        _vm.model.tipoPessoa ==
                                        _vm.tiposPessoa.Fisica,
                                    },
                                  },
                                  expression:
                                    "{\n                                    rules: {\n                                        alpha_spaces: true,\n                                        max: 256,\n                                        required: model.tipoPessoa == tiposPessoa.Fisica,\n                                    },\n                                }",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: {
                                autocomplete: "off",
                                type: "text",
                                id: "nome",
                                name: "nome",
                                disabled: _vm.somenteConsulta,
                              },
                              domProps: { value: _vm.model.nome },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.model,
                                    "nome",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                          ]
                        )
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-3" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("contaGerencialId"),
                            expression: "errors.first('contaGerencialId')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "contaGerencialId" },
                          },
                          [
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Crud.fornecedor.edit_vue_html.cntGerencialPdr"
                                  )
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("combo-component", {
                          attrs: {
                            id: "contaGerencialId",
                            name: "contaGerencialId",
                            data: _vm.contasGerenciaisOptions,
                            searchable: true,
                            disabled: _vm.somenteConsulta,
                          },
                          model: {
                            value: _vm.model.contaGerencialId,
                            callback: function ($$v) {
                              _vm.$set(_vm.model, "contaGerencialId", $$v)
                            },
                            expression: "model.contaGerencialId",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-1" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("ativo"),
                            expression: "errors.first('ativo')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "ativo" },
                          },
                          [
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Crud.formulapadrao.edit_vue_html.ativo"
                                  )
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("checkbox-component", {
                          attrs: {
                            id: "ativo",
                            name: "ativo",
                            disabled: _vm.somenteConsulta,
                          },
                          model: {
                            value: _vm.model.ativo,
                            callback: function ($$v) {
                              _vm.$set(_vm.model, "ativo", $$v)
                            },
                            expression: "model.ativo",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.model.tipoPessoa == _vm.tiposPessoa.Juridica,
                        expression: "model.tipoPessoa == tiposPessoa.Juridica",
                      },
                    ],
                    staticClass: "row",
                  },
                  [
                    _c("div", { staticClass: "col-sm-8" }, [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: _vm.errors.first("razaoSocial"),
                              expression: "errors.first('razaoSocial')",
                            },
                          ],
                          staticClass: "form-group",
                        },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "control-label",
                              attrs: {
                                for: "razaoSocial",
                                title: _vm.$t("__.obrigatorio"),
                              },
                            },
                            [
                              _c("span", { staticStyle: { color: "red" } }, [
                                _vm._v("*"),
                              ]),
                              _vm._v(
                                "\n                                " +
                                  _vm._s(
                                    _vm.$t(
                                      "__.Crud.fornecedor.edit_vue_html.razaoSocial"
                                    )
                                  ) +
                                  "\n                            "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.model.razaoSocial,
                                expression: "model.razaoSocial",
                              },
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: {
                                  rules: {
                                    max: 256,
                                    required:
                                      _vm.model.tipoPessoa ==
                                      _vm.tiposPessoa.Juridica,
                                  },
                                },
                                expression:
                                  "{\n                                    rules: { max: 256, required: model.tipoPessoa == tiposPessoa.Juridica },\n                                }",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: {
                              autocomplete: "off",
                              id: "razaoSocial",
                              name: "razaoSocial",
                              disabled: _vm.somenteConsulta,
                            },
                            domProps: { value: _vm.model.razaoSocial },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.model,
                                  "razaoSocial",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-sm-4" }, [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: _vm.errors.first(
                                "dataValidadeLicencaSanitaria"
                              ),
                              expression:
                                "errors.first('dataValidadeLicencaSanitaria')",
                            },
                          ],
                          staticClass: "form-group",
                        },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "control-label",
                              attrs: { for: "dataValidadeLicencaSanitaria" },
                            },
                            [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(
                                    _vm.$t(
                                      "__.Crud.franquia.edit_vue_html.validadeLicensaSanitaria"
                                    )
                                  ) +
                                  "\n                            "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("date-time-picker-component", {
                            attrs: {
                              id: "dataValidadeLicencaSanitaria",
                              name: "dataValidadeLicencaSanitaria",
                            },
                            model: {
                              value: _vm.model.dataValidadeLicencaSanitaria,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.model,
                                  "dataValidadeLicencaSanitaria",
                                  $$v
                                )
                              },
                              expression: "model.dataValidadeLicencaSanitaria",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]
                ),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "fieldset-component",
            {
              attrs: {
                title: _vm.$t("__.Crud.fornecedor.edit_vue_html.localiz"),
              },
            },
            [
              _c("div", { attrs: { slot: "rows" }, slot: "rows" }, [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-sm-3" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("cep"),
                            expression: "errors.first('cep')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "cep" },
                          },
                          [
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t("__.Crud.fornecedor.edit_vue_html.cep")
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("cep-field-component", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "cep",
                              expression: "'cep'",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            id: "cep",
                            name: "cep",
                            disabled: _vm.somenteConsulta,
                          },
                          on: {
                            "cep-changed": _vm.onCepChanged,
                            "cep-invalid": _vm.onCepInvalid,
                          },
                          model: {
                            value: _vm.model.cep,
                            callback: function ($$v) {
                              _vm.$set(_vm.model, "cep", $$v)
                            },
                            expression: "model.cep",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-3" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("estadoId"),
                            expression: "errors.first('estadoId')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "estadoId" },
                          },
                          [
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Crud.fornecedor.edit_vue_html.estado"
                                  )
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("combo-component", {
                          attrs: {
                            id: "estadoId",
                            name: "estadoId",
                            data: _vm.ufOptions,
                            searchable: true,
                            disabled: _vm.somenteConsulta,
                          },
                          on: { input: _vm.onLoadCidades },
                          model: {
                            value: _vm.model.estadoId,
                            callback: function ($$v) {
                              _vm.$set(_vm.model, "estadoId", $$v)
                            },
                            expression: "model.estadoId",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { class: _vm.model.exterior ? "col-sm-3" : "col-sm-6" },
                    [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: _vm.errors.first("cidadeId"),
                              expression: "errors.first('cidadeId')",
                            },
                          ],
                          staticClass: "form-group",
                        },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "control-label",
                              attrs: { for: "cidadeId" },
                            },
                            [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(
                                    _vm.$t(
                                      "__.Crud.fornecedor.edit_vue_html.cidade"
                                    )
                                  ) +
                                  "\n                            "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("combo-component", {
                            attrs: {
                              id: "cidadeId",
                              name: "cidadeId",
                              data: _vm.cidadesOptions,
                              searchable: true,
                              disabled: _vm.somenteConsulta,
                            },
                            model: {
                              value: _vm.model.cidadeId,
                              callback: function ($$v) {
                                _vm.$set(_vm.model, "cidadeId", $$v)
                              },
                              expression: "model.cidadeId",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _vm.model.exterior
                    ? _c("div", { staticClass: "col-sm-3" }, [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: _vm.errors.first("codigoPais"),
                                expression: "errors.first('codigoPais')",
                              },
                            ],
                            staticClass: "form-group",
                          },
                          [
                            _c(
                              "label",
                              {
                                staticClass: "control-label",
                                attrs: {
                                  for: "codigoPais",
                                  title: "* este campo é obrigatório",
                                },
                              },
                              [
                                _c("span", { staticStyle: { color: "red" } }, [
                                  _vm._v("*"),
                                ]),
                                _vm._v(
                                  "\n                                Pais\n                            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("combo-component", {
                              attrs: {
                                id: "codigoPais",
                                name: "codigoPais",
                                data: _vm.paisesOptions,
                                searchable: true,
                                disabled: _vm.somenteConsulta,
                              },
                              model: {
                                value: _vm.model.codigoPais,
                                callback: function ($$v) {
                                  _vm.$set(_vm.model, "codigoPais", $$v)
                                },
                                expression: "model.codigoPais",
                              },
                            }),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-sm-4" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("endereco"),
                            expression: "errors.first('endereco')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "endereco" },
                          },
                          [
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Crud.fornecedor.edit_vue_html.endereco"
                                  )
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.model.endereco,
                              expression: "model.endereco",
                            },
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "max:256",
                              expression: "'max:256'",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            autocomplete: "off",
                            type: "text",
                            id: "endereco",
                            name: "endereco",
                            disabled: _vm.somenteConsulta,
                          },
                          domProps: { value: _vm.model.endereco },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.model,
                                "endereco",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-4" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("numero"),
                            expression: "errors.first('numero')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "numero" },
                          },
                          [
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t("__.Crud.fornecedor.edit_vue_html.nro")
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.model.numero,
                              expression: "model.numero",
                            },
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "max:256",
                              expression: "'max:256'",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            autocomplete: "off",
                            type: "text",
                            id: "numero",
                            name: "numero",
                            disabled: _vm.somenteConsulta,
                          },
                          domProps: { value: _vm.model.numero },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(_vm.model, "numero", $event.target.value)
                            },
                          },
                        }),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-4" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("bairro"),
                            expression: "errors.first('bairro')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "bairro" },
                          },
                          [
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Crud.fornecedor.edit_vue_html.bairro"
                                  )
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.model.bairro,
                              expression: "model.bairro",
                            },
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "max:256",
                              expression: "'max:256'",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            autocomplete: "off",
                            type: "text",
                            id: "bairro",
                            name: "bairro",
                            disabled: _vm.somenteConsulta,
                          },
                          domProps: { value: _vm.model.bairro },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(_vm.model, "bairro", $event.target.value)
                            },
                          },
                        }),
                      ]
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-sm-12" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("complemento"),
                            expression: "errors.first('complemento')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "complemento" },
                          },
                          [
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Crud.fornecedor.edit_vue_html.compl"
                                  )
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.model.complemento,
                              expression: "model.complemento",
                            },
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "max:256",
                              expression: "'max:256'",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            autocomplete: "off",
                            type: "text",
                            id: "complemento",
                            name: "complemento",
                            disabled: _vm.somenteConsulta,
                          },
                          domProps: { value: _vm.model.complemento },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.model,
                                "complemento",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]
                    ),
                  ]),
                ]),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "fieldset-component",
            {
              attrs: {
                title: _vm.$t("__.Crud.fornecedor.edit_vue_html.contato"),
              },
            },
            [
              _c("div", { attrs: { slot: "rows" }, slot: "rows" }, [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-sm-4" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("telefone"),
                            expression: "errors.first('telefone')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "telefone" },
                          },
                          [
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t("__.Crud.fornecedor.edit_vue_html.tel")
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "mask-telefone",
                              rawName: "v-mask-telefone",
                            },
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.model.telefone,
                              expression: "model.telefone",
                            },
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "telefone",
                              expression: "'telefone'",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            autocomplete: "off",
                            type: "text",
                            id: "telefone",
                            name: "telefone",
                            disabled: _vm.somenteConsulta,
                          },
                          domProps: { value: _vm.model.telefone },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.model,
                                "telefone",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-4" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("celular"),
                            expression: "errors.first('celular')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "celular" },
                          },
                          [
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t("__.Crud.fornecedor.edit_vue_html.cel")
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "mask-telefone",
                              rawName: "v-mask-telefone",
                            },
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.model.celular,
                              expression: "model.celular",
                            },
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "telefone",
                              expression: "'telefone'",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            autocomplete: "off",
                            type: "text",
                            id: "celular",
                            name: "celular",
                            disabled: _vm.somenteConsulta,
                          },
                          domProps: { value: _vm.model.celular },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.model,
                                "celular",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-4" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("fax"),
                            expression: "errors.first('fax')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "fax" },
                          },
                          [
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t("__.Crud.fornecedor.edit_vue_html.fax")
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "mask-telefone",
                              rawName: "v-mask-telefone",
                            },
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.model.fax,
                              expression: "model.fax",
                            },
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "telefone",
                              expression: "'telefone'",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            autocomplete: "off",
                            type: "text",
                            id: "fax",
                            name: "fax",
                            disabled: _vm.somenteConsulta,
                          },
                          domProps: { value: _vm.model.fax },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(_vm.model, "fax", $event.target.value)
                            },
                          },
                        }),
                      ]
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-sm-7" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("email"),
                            expression: "errors.first('email')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "email" },
                          },
                          [_vm._v("E-mail")]
                        ),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.model.email,
                              expression: "model.email",
                            },
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "email",
                              expression: "'email'",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            autocomplete: "off",
                            type: "email",
                            id: "email",
                            name: "email",
                            placeholder: "exemplo@exemplo.com.br",
                            disabled: _vm.somenteConsulta,
                          },
                          domProps: { value: _vm.model.email },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(_vm.model, "email", $event.target.value)
                            },
                          },
                        }),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-5" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("homepage"),
                            expression: "errors.first('homepage')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "homepage" },
                          },
                          [
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Crud.fornecedor.edit_vue_html.homepg"
                                  )
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.model.homepage,
                              expression: "model.homepage",
                            },
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "url",
                              expression: "'url'",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            autocomplete: "off",
                            type: "text",
                            id: "homepage",
                            name: "homepage",
                            placeholder: "www.exemplo.com.br",
                            disabled: _vm.somenteConsulta,
                          },
                          domProps: { value: _vm.model.homepage },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.model,
                                "homepage",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-sm-7" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("contatoNome"),
                            expression: "errors.first('contatoNome')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "contatoNome" },
                          },
                          [
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Crud.fornecedor.edit_vue_html.contato"
                                  )
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.model.contatoNome,
                              expression: "model.contatoNome",
                            },
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "alpha_spaces|max:256",
                              expression: "'alpha_spaces|max:256'",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            autocomplete: "off",
                            type: "text",
                            id: "contatoNome",
                            name: "contatoNome",
                            disabled: _vm.somenteConsulta,
                          },
                          domProps: { value: _vm.model.contatoNome },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.model,
                                "contatoNome",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-5" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("contatoTelefone"),
                            expression: "errors.first('contatoTelefone')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "contatoTelefone" },
                          },
                          [
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Crud.fornecedor.edit_vue_html.telContato"
                                  )
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "mask-telefone",
                              rawName: "v-mask-telefone",
                            },
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.model.contatoTelefone,
                              expression: "model.contatoTelefone",
                            },
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "telefone",
                              expression: "'telefone'",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            autocomplete: "off",
                            type: "text",
                            id: "contatoTelefone",
                            name: "contatoTelefone",
                            disabled: _vm.somenteConsulta,
                          },
                          domProps: { value: _vm.model.contatoTelefone },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.model,
                                "contatoTelefone",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]
                    ),
                  ]),
                ]),
              ]),
            ]
          ),
          _vm._v(" "),
          _vm.model.id
            ? _c("infoBagdeComponent", { attrs: { text: _vm.infoBagdeText } })
            : _vm._e(),
          _vm._v(" "),
          _c("fieldset", [
            !_vm.isModal
              ? _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-sm-12 newbtn" }, [
                    _c("div", { staticClass: "form-group" }, [
                      !_vm.somenteConsulta
                        ? _c(
                            "button",
                            {
                              staticClass: "btn btn-success",
                              attrs: { type: "submit" },
                            },
                            [
                              _c("i", {
                                staticClass: "fa fa-save",
                                attrs: { "aria-hidden": "true" },
                              }),
                              _vm._v(
                                "\n                            " +
                                  _vm._s(_vm.$t("__.Crud.salvar")) +
                                  "\n                        "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.somenteConsulta
                        ? _c(
                            "button",
                            {
                              staticClass: "btn btn-secondary",
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  _vm.load()
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(_vm.$t("__.Crud.limpar")) +
                                  "\n                        "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-secondary",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              _vm.cancel()
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n                            " +
                              _vm._s(_vm.$t("__.Crud.voltar")) +
                              "\n                        "
                          ),
                        ]
                      ),
                    ]),
                  ]),
                ])
              : _vm._e(),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }