import BaseModel from "./base/baseModel";

export default class DinamizacaoModel extends BaseModel {
    id?: number = null;
    quantidade = 0;
    unidadeMedidaId = 0;
    kitEmbalagemId = 0;
    validade = 0;
    estoqueId = 0;

    produtoId = 0;
    produtoLoteId = 0;
    dinamizacaoReferencia = 0;
    metodo = 0;
    quantidadeReferencia = 0;
    unidadeMedidaIdReferencia = 0;

    dinamizacao = 0;

    produtoVeiculoId = 0;
    produtoLoteVeiculoId = 0;
    quantidadeNecessaria = 0;
}
