import Vue from "vue";

import actionBarComponent from "@/components/child/actionBar/actionBar.vue";
import { Component } from "@/decorators";
import EmailModel from "@/models/emailModel";
import EmailService from "@/services/emailService";

import "../crud.scss";

@Component({
    components: {
        actionBarComponent,
    },
})
export default class EmailEditComponent extends Vue {
    private service = new EmailService();

    model: EmailModel = new EmailModel();
    modelId = 0;

    private async load() {
        this.model = new EmailModel();

        if (this.modelId > 0) {
            try {
                const data = await this.service.get(this.modelId).withLoading().resolveWithJSON<EmailModel>();

                this.model.updateFrom(data);
            } catch {
                this.$router.back();
            }
        }
    }

    private async save() {
        try {
            const isValid = await this.$validator.validateAll();
            if (isValid) {
                this.model.emailRemetente = this.model.email;

                const sucesso = await this.service[!this.model.id ? "insert" : "update"](this.model)
                    .withLoading()
                    .resolveWithoutJSON();

                if (sucesso) {
                    await this.$showInclusaoUpdate(this.model.id);
                    this.$router.back();
                }
            } else {
                this.$focusErrorField();
            }
        } catch {}
    }

    private cancel() {
        this.$router.back();
    }

    private mounted() {
        if (this.$route.params.id) {
            this.modelId = +this.$route.params.id;
            this.load();
        }
    }
}
