import Vue from "vue";
import { mapMutations } from "vuex";

import { GridColumn, GridColumnType } from "@/components/child/grid/gridColumn";
import shortcutComponent from "@/components/parent/shortcut/shortcut.vue";
import { Component } from "@/decorators";
import NotificationModel from "@/models/notificationModel";
import PaginationModel from "@/models/paginationModel";
import NotificationService from "@/services/notificationService";
import { Mutations } from "@/store/store";
import { editFromNow } from "@/utils/common/date";

import ShortcutComponent from "../shortcut/shortcut";

import "./list.scss";

@Component({
    components: {
        shortcutComponent,
    },
    methods: mapMutations(["READ_ONE_NOTIFICATION", "SET_UNREADED_NOTIFICATIONS"] as Mutations),
})
export default class ListNotificationComponent extends Vue {
    // State computed props
    READ_ONE_NOTIFICATION: () => void;
    SET_UNREADED_NOTIFICATIONS: (value: number) => void;

    private shortcutComponent: ShortcutComponent = null;
    private notificationService = new NotificationService();

    notificacoes: NotificationModel[] = [];
    pageIndex = 1;
    pageSize = 30;
    total = 0;

    get gridColumns(): Array<GridColumn> {
        return [new GridColumn("dataCriacao", "Data", GridColumnType.DateTime)];
    }

    private reset() {
        this.pageIndex = 1;
        this.notificacoes = [];
    }

    private async load() {
        const response = await this.notificationService
            .getListByUsuario("", "", "", this.pageIndex, this.pageSize)
            .withLoading()
            .resolveWithJSON<PaginationModel<NotificationModel>>();

        this.pageIndex = response.pageIndex;
        this.pageSize = response.pageSize;
        this.total = response.total;

        this.notificacoes = [...this.notificacoes, ...response.list];
    }

    private async onMarkAsReaded(id: number) {
        await this.notificationService.markAsReaded(id);

        this.notificacoes.find(p => p.id === id).readed = true;
        this.READ_ONE_NOTIFICATION();
    }

    private async onMarkAllAsReaded() {
        await this.notificationService.markAllAsReaded();

        this.reset();
        await this.load();
        this.SET_UNREADED_NOTIFICATIONS(0);
    }

    private formatDate(data: Date) {
        return editFromNow(data);
    }

    private async onShowMore() {
        this.pageIndex++;

        await this.load();
    }

    public async show() {
        this.reset();
        await this.load();

        this.shortcutComponent = this.$refs.shortcutComponent as ShortcutComponent;
        this.shortcutComponent.title = "Notificações";
        this.shortcutComponent.show();
    }
}
