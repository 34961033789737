import Vue from "vue";

import buttonIncluirComponent from "@/components/child/form/button/buttonIncluir.vue";
import cepFieldComponent from "@/components/child/form/cepfield.vue";
import checkboxComponent from "@/components/child/form/checkbox.vue";
import comboComponent from "@/components/child/form/combo.vue";
import { Component, Prop, Watch } from "@/decorators";
import CepModel from "@/models/cepModel";
import ClienteEnderecoEntregaModel from "@/models/clienteEnderecoEntregaModel";
import ClienteModel from "@/models/clienteModel";
import EnumExtensions from "@/models/enum/extensions/enumExtensions";
import Ufs from "@/models/enum/ufs";
import CidadeService from "@/services/external/cidadeService";

@Component({
    components: {
        comboComponent,
        cepFieldComponent,
        checkboxComponent,
        buttonIncluirComponent,
    },
})
export default class enderecoEntregaEditComponent extends Vue {
    private cidadeService = new CidadeService();

    model = new ClienteEnderecoEntregaModel();
    dadosCep = new CepModel();

    private editingModel = false;

    cidadesEntregaOptions: Array<Object> = [];
    ufOptions: Array<Object> = EnumExtensions.getNamesAndValuesOrderedByNames(Ufs);

    @Prop({ type: Number, default: null }) clienteId: number;

    public async load(model: ClienteEnderecoEntregaModel) {
        this.clear();
        this.model = model;
        this.cidadesEntregaOptions.push({ value: model.cidadeId, text: model.cidade });
        this.model.cidade = this.cidadesEntregaOptions.find(item => item["value"] == this.model.cidadeId)["text"];
        this.editingModel = true;
    }

    public clear() {
        this.dadosCep = new CepModel();
        this.model = new ClienteEnderecoEntregaModel();
        this.model.clienteId = this.clienteId;

        this.editingModel = false;
    }

    public replicar(model: ClienteModel) {
        this.model.cep = model.cep;
        this.model.estadoId = model.estadoId;
        this.model.cidadeId = model.cidadeId;
        this.model.cidade = model.cidade;
        this.model.bairro = model.bairro;
        this.model.endereco = model.endereco;
        this.model.complemento = model.complemento;
        this.model.numero = model.numero;
    }

    private async onAdicionarItem() {
        await this.$validator.validateAll();

        if (this.editingModel) {
            this.$emit("edicao-endereco", this.model);
        } else {
            this.$emit("inclusao-endereco", this.model);
        }
    }

    private onCancelar() {
        this.clear();
    }

    private onCepInvalid() {
        this.$showInfo(this.$t("__.ts.pesqCEP"), this.$t("__.ts.pesqNaoEncResul"));
    }

    private onCepChanged(data: CepModel) {
        this.dadosCep = data;
        this.model.bairro = data.bairro;
        this.model.estadoId = data.uf;
        this.model.cidadeId = data.cidadeId;
        this.model.cidade = data.cidadeNome;
        this.model.complemento = data.complemento;
        this.model.endereco = data.endereco;
    }

    //@ts-ignore
    @Watch("model.estadoId")
    private async onEstadoChange() {
        try {
            if (this.model.estadoId) {
                const data = await this.cidadeService.get(this.model.estadoId).withLoading();
                this.cidadesEntregaOptions = data;

                if (this.dadosCep.cidadeId) {
                    this.model.cidadeId = this.dadosCep.cidadeId;
                }

                this.model.cidade = this.cidadesEntregaOptions.find(item => item["value"] == this.model.cidadeId)[
                    "text"
                ];
            }
        } catch {}
    }
}
