import { mapMutations } from "vuex";

import { GridColumn, GridColumnType } from "@/components/child/grid/gridColumn";
import { Component } from "@/decorators";
import EstoqueModel from "@/models/estoqueModel";
import EstoqueService from "@/services/estoqueService";
import { LoadListProps, Mutations } from "@/store/store";

import ListComponentBase from "../listComponentBase";

import "../crud.scss";

@Component({
    methods: mapMutations(["LOAD_LIST"] as Mutations),
})
export default class EstoqueListComponent extends ListComponentBase<EstoqueModel> {
    // State computed props
    LOAD_LIST: (props: LoadListProps) => void;

    get gridColumns(): Array<GridColumn> {
        return [new GridColumn("descricao", this.$t("__.ts.descricao"), GridColumnType.String)];
    }

    private beforeMount() {
        this.setProps(new EstoqueService(), "estoque", "descricao");
    }

    public async onSucessoExclusao() {
        this.LOAD_LIST({ loadEstoque: true });
    }
}
