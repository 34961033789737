import Vue from "vue";
import { mapState, mapMutations, mapGetters } from "vuex";

import actionBarComponent from "@/components/child/actionBar/actionBar.vue";
import buttonIncluirComponent from "@/components/child/form/button/buttonIncluir.vue";
import checkboxComponent from "@/components/child/form/checkbox.vue";
import comboComponent from "@/components/child/form/combo.vue";
import dataTooltipComponent from "@/components/child/form/datatooltip.vue";
import decimalComponent from "@/components/child/form/decimal.vue";
import decimalComSinalCustomComponent from "@/components/child/form/decimalComSinalCustom.vue";
import decimalComSufixoNullableComponent from "@/components/child/form/decimalComSufixoNullable.vue";
import fieldsetComponent from "@/components/child/form/fieldset.vue";
import moedaComponent from "@/components/child/form/moeda.vue";
import textareaComponent from "@/components/child/form/textarea.vue";
import gridComponent from "@/components/child/grid/grid.vue";
import { GridAction } from "@/components/child/grid/gridAction";
import { GridColors, GridColumn, GridColumnType } from "@/components/child/grid/gridColumn";
import infoBagdeComponent from "@/components/child/infoBagde/infoBagde.vue";
import logEntidadeComponent from "@/components/child/logEntidade/logEntidade.vue";
import { ModalButtonAction } from "@/components/child/modal/modalButtonAction";
import searchComboComponent from "@/components/child/searchCombo/search.vue";
import { Component, Watch, Prop } from "@/decorators";
import AliquotaInterestadualModel from "@/models/aliquotaInterestadualModel";
import PreLoadPackModel from "@/models/auxiliar/preLoadPackModel";
import CodigoSiproquimModel from "@/models/codigoSiproquimModel";
import CorModel from "@/models/corModel";
import CorManipulacao from "@/models/enum/corManipulacao";
import EnumExtensions from "@/models/enum/extensions/enumExtensions";
import OrigemProdutoLote from "@/models/enum/origemProdutoLote";
import CurvaABC from "@/models/enum/produto/curvaABC";
import SubGrupoProduto from "@/models/enum/subGrupoProduto";
import TipoCalculoAssociacao from "@/models/enum/tipoCalculoAssociacao";
import TipoObservacao from "@/models/enum/tipoObservacao";
import TiposCalculoManipulacao from "@/models/enum/tiposCalculoManipulacao";
import TiposCapsula from "@/models/enum/tiposCapsula";
import TiposProduto from "@/models/enum/tiposProduto";
import Ufs from "@/models/enum/ufs";
import FarmacopeiaModel from "@/models/farmacopeiaModel";
import FormaFarmaceuticaModel from "@/models/formaFarmaceuticaModel";
import FornecedorModel from "@/models/fornecedorModel";
import FranquiaModel from "@/models/franquiaModel";
import IncompatibilidadeKitEmbalagemModel from "@/models/incompatibilidadeKitEmbalagemModel";
import IncompatibilidadeProdutoModel from "@/models/incompatibilidadeProdutoModel";
import PaginationModel from "@/models/paginationModel";
import PosologiaModel from "@/models/posologiaModel";
import CestModel from "@/models/produto/cestModel";
import GrupoProdutoModel from "@/models/produto/grupoProdutoModel";
import ListaControladoModel from "@/models/produto/listaControladoModel";
import NbmModel from "@/models/produto/nbmModel";
import NcmModel from "@/models/produto/ncmModel";
import ProdutoAssociacaoModel from "@/models/produto/produtoAssociacaoModel";
import DcbModel from "@/models/produto/produtoDcbModel";
import DciModel from "@/models/produto/produtoDciModel";
import ProdutoFornecedorModel from "@/models/produto/produtoFornecedorModel";
import ProdutoModel from "@/models/produto/produtoModel";
import ProdutoObservacoesModel from "@/models/produto/produtoObservacoesModel";
import ProdutoSinonimoModel from "@/models/produto/produtoSinonimoModel";
import ProdutoAliquotaInterestadualModel from "@/models/produtoAliquotaInterestadualModel";
import ProdutoLogFinanceiroModel from "@/models/produtoLogFinanceiroModel";
import ProdutoLoteModel from "@/models/produtoLoteModel";
import ProdutoMarkupDinamicoModel from "@/models/produtoMarkupDinamicoModel";
import ProdutoRefrigeradoFormaFarmaceuticaModel from "@/models/produtoRefrigeradoFormaFarmaceuticaModel";
import UnidadeMedidaModel from "@/models/unidadeMedidaModel";
import UsuarioModel from "@/models/usuarioModel";
import ValidadeFormaFarmaceuticaProdutoModel from "@/models/validadadeFormaFarmaceuticaProdutoModel";
import CapsulaService from "@/services/capsulaService";
import CodigoSiproquimService from "@/services/codigoSiproquimService";
import CorService from "@/services/corService";
import EstoqueComprometidoService from "@/services/estoqueComprometidoService";
import FarmacopeiaService from "@/services/farmacopeiaService";
import FormaFarmaceuticaService from "@/services/formaFarmaceuticaService";
import FornecedorService from "@/services/fornecedorService";
import FranquiaService from "@/services/franquiaService";
import OrigemMercadoriaService from "@/services/origemMercadoriaService";
import PosologiaService from "@/services/posologiaService";
import CestService from "@/services/produto/cestService";
import GrupoProdutoService from "@/services/produto/grupoProdutoService";
import ListaControladoService from "@/services/produto/listaControladoService";
import NbmService from "@/services/produto/nbmService";
import DcbService from "@/services/produto/produtoDcbService";
import DciService from "@/services/produto/produtoDciService";
import ProdutoService from "@/services/produto/produtoService";
import ProdutoLoteService from "@/services/produtoLoteService";
import SituacaoTributariaService from "@/services/situacaoTributariaService";
import UnidadeMedidaService from "@/services/unidadeMedidaService";
import { AppState, Getters, Mutations, SessionActions } from "@/store/store";
import arithmeticHelper from "@/utils/common/arithmeticHelper";
import { changePosition } from "@/utils/common/array";
import {
    getFormaFarmaceuticaCombo,
    getPosologiaCombo,
    getProdutoCombo,
    getUnidadeMedidaCombo,
    getKitEmbalagemCombo,
    getFornecedorCombo,
} from "@/utils/common/combo/combotext";
import { editDateWithTime } from "@/utils/common/date";
import Delay from "@/utils/common/delay";

import ImprimirRotuloPersonalizadoComponent from "../../crud/rotulopersonalizado/imprimirRotuloComModal";
import imprimirRotuloPersonalizadoComponent from "../../crud/rotulopersonalizado/imprimirRotuloComModal.vue";
import FichaTecnicaEditComponent from "../../fichaTecnica/edit";
import fichaTecnicaComponent from "../../fichaTecnica/edit.vue";
import shortcutComponent from "../../shortcut/shortcut.vue";
import PosologiaComponent from "../posologia/edit";
import posologiaComponent from "../posologia/edit.vue";
import ReplicarSelecaoFranquiaComponent from "../replicarSelecaoFranquia/edit";
import replicarSelecaoFranquiaComponent from "../replicarSelecaoFranquia/edit.vue";

import ShortcutComponent from "./../../shortcut/shortcut";
import ProdutoLogFinanceiroComponent from "./../produtologfinanceiro/list";
import produtoLogFinanceiroComponent from "./../produtologfinanceiro/list.vue";
import ReplicarProdutoComponent from "./replicarProduto";
import replicarProdutoComponent from "./replicarProduto.vue";
import verLoteComponent from "./verLote.vue";

import "../crud.scss";
import "./edit.scss";

@Component({
    components: {
        textareaComponent,
        comboComponent,
        fieldsetComponent,
        checkboxComponent,
        dataTooltipComponent,
        searchComboComponent,
        gridComponent,
        moedaComponent,
        decimalComponent,
        posologiaComponent,
        decimalComSinalCustomComponent,
        decimalComSufixoNullableComponent,
        replicarSelecaoFranquiaComponent,
        replicarProdutoComponent,
        shortcutComponent,
        produtoLogFinanceiroComponent,
        verLoteComponent,
        imprimirRotuloPersonalizadoComponent,
        fichaTecnicaComponent,
        buttonIncluirComponent,
        actionBarComponent,
        logEntidadeComponent,
        infoBagdeComponent,
    },
    computed: {
        ...mapState({
            preLoadPack: (state: AppState) => state.preLoad.preLoadList,
            loadedList: (state: AppState) => state.preLoad.loadedList,
            usuarioLogado: (state: AppState) => state.session.usuarioLogado,
        }),
        ...mapGetters([
            "HAS_PERMISSAO_PERFIL",
            "VALIDAR_PERMISSAO_USUARIO",
            "VALIDAR_PERMISSAO_SOMENTE_CONSULTA",
        ] as Getters),
        infoBagdeText() {
            return "Data criação: " + editDateWithTime(this.model.dataCriacao);
        },
    },
    methods: mapMutations(["LOAD_LIST"] as Mutations),
})
export default class ProdutoEditComponent extends Vue {
    // State computed props
    preLoadPack: PreLoadPackModel;
    loadedList: boolean;
    usuarioLogado: UsuarioModel;
    HAS_PERMISSAO_PERFIL: (isRede: boolean, isFranqueador: boolean, isSuporte: boolean) => Promise<boolean>;
    VALIDAR_PERMISSAO_USUARIO: (telaDescricao: string, acaoController: string) => boolean;
    VALIDAR_PERMISSAO_SOMENTE_CONSULTA: (telaDescricao: string) => boolean;
    infoBagdeText: string;

    private service = new ProdutoService();
    private serviceLote = new ProdutoLoteService();
    private serviceCapsula = new CapsulaService();
    private franquiaService = new FranquiaService();
    private shortcutComponent: ShortcutComponent = null;
    private shortcutAliquotaInterestadualComponent: ShortcutComponent = null;
    private shortcutFichaTecnicaComponent: ShortcutComponent = null;
    private shortcutTrocarUnidadeMedidaComponent: ShortcutComponent = null;
    private fichaTecnicaComponent: FichaTecnicaEditComponent = null;
    private posologiaComponent: PosologiaComponent = null;
    private shortcutLogComponent: ShortcutComponent = null;
    private replicarSelecaoFranquiaComponent: ReplicarSelecaoFranquiaComponent = null;
    private replicarProdutoComponent: ReplicarProdutoComponent = null;
    private imprimirRotuloPersonalizadoComponent: ImprimirRotuloPersonalizadoComponent = null;
    model = new ProdutoModel();
    modelId = 0;
    id = 0;
    loteId = 0;
    loadLote = false;

    private simplesNacional = false;

    somenteConsulta = false;
    showFinanceiro = false;
    loadCompleted = false;

    private franquiaUf = "";
    private ufs = EnumExtensions.getNamesAndValuesOrderedByNames(Ufs);
    private aliquotasICMSInterestadualDefault: Array<AliquotaInterestadualModel> = [];

    private isAdmin = false;
    private isAdminSuporteFranqueado = false;
    private isAdminFranqueador = false;
    private franquiaId: number = null;
    extraActions: Array<object> = [];
    gridExtraActionsVer: Array<GridAction> = [];
    gridExtraActionsImprimirRotulo: Array<GridAction> = [];
    gridExtraActionsDefinirLotePadrao: Array<GridAction> = [];
    extraActionsProdutoFornecedor: Array<object> = [];
    extraActionsUp: Array<GridAction> = [];
    extraActionsDown: Array<GridAction> = [];

    franquiaOptions: Array<Object> = [];
    grupoProdutoOptions: Array<Object> = [];
    unidadesMedidaOptions = [];
    listasControladoOptions: Array<Object> = [];
    nbmsOptions: Array<Object> = [];
    fornecedoresOptions: Array<Object> = [];

    cestsOptions: Array<Object> = [];
    dcbsOptions: Array<Object> = [];
    dcisOptions: Array<Object> = [];
    capsulasOptions: Array<Object> = [];
    produtoOptions: Array<Object> = [];
    produtoAssOptions: Array<Object> = [];
    produtoPuroOptions: Array<Object> = [];
    produtoDiluidoOptions: Array<Object> = [];
    tiposCalculoOptions: Array<Object> = EnumExtensions.getNamesAndValuesOrderedByNames(TiposCalculoManipulacao);
    tipoCalculoAssociacaoOptions: Array<Object> = EnumExtensions.getNamesAndValuesOrderedByNames(TipoCalculoAssociacao);
    formasFarmaceuticaOptions: Array<Object> = [];
    kitEmbalagemOptions: Array<Object> = [];
    posologiaOptions: Array<Object> = [];
    subGrupoOptions: Array<Object> = [
        { text: "Ativo", value: 0 },
        { text: "Flavorizante", value: 1 },
        { text: "Veículos/Excipientes", value: 2 },
    ];
    tipoCapsulaOptions: Array<Object> = [
        { text: "Normal", value: TiposCapsula.Normal },
        { text: "Revestida", value: TiposCapsula.Revestida },
        { text: "Vegana", value: TiposCapsula.Vegana },
        { text: "Vegana Revestida", value: TiposCapsula.VeganaRevestida },
    ];
    farmacopeiaOptions: Array<Object> = [];
    observacaoTipoOptions: Array<Object> = [
        { text: "Venda de Manipulação", value: TipoObservacao.VendaManipulacao },
        { text: "Ordem de Manipulação", value: TipoObservacao.OrdemManipulacao },
        { text: "Conservação e Transporte", value: TipoObservacao.ConservacaoTransporte },
        { text: "Exibir aviso no orçamento", value: TipoObservacao.ExibirAvisoOrcamento },
    ];

    corManipulacaoOptions: Array<Object> = [
        { text: "Vermelho", value: CorManipulacao.Vermelho },
        { text: "Roxo", value: CorManipulacao.Roxo },
        { text: "Verde", value: CorManipulacao.Verde },
        { text: "Azul", value: CorManipulacao.Azul },
        { text: "Laranja", value: CorManipulacao.Laranja },
        { text: "Amarelo", value: CorManipulacao.Amarelo },
    ];

    curvaABCOptions: Array<Object> = [
        { text: "A", value: CurvaABC.A },
        { text: "B", value: CurvaABC.B },
        { text: "C", value: CurvaABC.C },
    ];

    filtroVinculos: number;
    origemMercadoriaOptions: Array<Object> = [];
    cstICMSOptions: Array<Object> = [];
    cstIPIEntradaOptions: Array<Object> = [];
    cstIPISaidaOptions: Array<Object> = [];
    cstPISCOFINSEntradaOptions: Array<Object> = [];
    cstPISCOFINSSaidaOptions: Array<Object> = [];
    coresOptions: Array<Object> = [];
    produtosIncompatibilidadeOptions: Array<Object> = [];
    produtosRefrigeradosOptions: Array<Object> = [];
    kitEmbalagensIncompatibilidadeOptions: Array<Object> = [];
    formaFarmaceuticaIncompatibilidadeOptions: Array<Object> = [];
    ufOptions: Array<Object> = this.ufs;

    codigoSiproquimOptions: Array<Object> = [];

    percentualAssociacao?: number = null;
    excipienteAssociacao?: boolean = false;
    desmembradoAssociacao?: boolean = false;
    produtoAssociacaoId?: number = null;
    formaFarmaceuticaAssociacaoId?: number = null;
    tipoCalculoAssociacao?: TipoCalculoAssociacao = TipoCalculoAssociacao.Ativo;
    totalLote = 0;
    private siglaEstoqueDescricao = "";
    addNewId = 0;

    observacaoTipo = 0;
    observacaoTexto = "";
    produtoIncompativelId = 0;
    kitEmbalagemIncompativelId = 0;
    produtoRefrigeradoFormaceuticaId = 0;
    formaFarmaceuticaIncompativelId = 0;
    produtoFornecedorId = 0;
    refreshDataProdutosAliquotasInterestaduais = false;
    formaFarmaceuticaValidadeId: number = null;
    diasValidadeForma = 0;
    ufAliquotaInterestadual = 0;
    aliquotaInterestadual = 0;
    unidadeMedidaEstoqueIdDestino: number = null;

    private customComboTextNCM = (p: NcmModel) => ({
        value: p.id,
        text: `${p.codigo} ${!p.ativo ? "[INATIVO] " : ""}- (${p.descricao})`,
    });

    produtoSinonimo = "";
    descricaoRotulo = "";
    fatorEquivalencia = 0;
    indexEdditingProdutoSinonimo = null;

    private usaMarkupDinamico = false;

    private showLog = false;
    private loadFichaTecnicaComponent = false;

    private produtoLogFinanceiroComponent: ProdutoLogFinanceiroComponent = null;

    gridColumnsFormaFarmaceutica: Array<GridColumn> = [
        new GridColumn("descricao", "Descri\u00e7\u00e3o", GridColumnType.String),
    ];

    gridColumnsFormaFarmaceuticaEmb: Array<GridColumn> = [
        new GridColumn("descricao", "Descri\u00e7\u00e3o", GridColumnType.String),
    ];

    gridColumnsProdutoAssociacoes: Array<GridColumn> = [
        new GridColumn("formaFarmaceuticaDescricao", "Forma Farm.", GridColumnType.String),
        new GridColumn("produtoAssociadoDescricao", "Produto", GridColumnType.String),
        new GridColumn("percentual", "Percentual", GridColumnType.Decimal),
        new GridColumn("excipiente", "Excipiente", GridColumnType.Boolean),
        new GridColumn("desmembrado", "Desmembrado", GridColumnType.Boolean),
        new GridColumn("tipoCalculoAssociacaoDescricao", "Cálculo", GridColumnType.String),
    ];

    gridDataLote: Array<ProdutoLoteModel> = [];
    get gridColumnLote(): Array<GridColumn> {
        return [
            new GridColumn("descricao", "Descri\u00e7\u00e3o", GridColumnType.String),
            new GridColumn("fornecedorNome", "Fornecedor", GridColumnType.String),
            new GridColumn("fatorCorrecao", "F.C", GridColumnType.Decimal),
            new GridColumn("valorCusto", "Custo Unitário", GridColumnType.Money4Digits, false, !this.showFinanceiro),
            new GridColumn("dataValidade", "Validade", GridColumnType.Date),
            new GridColumn("dataFabricacao", "Fabrica\u00e7\u00e3o", GridColumnType.Date),
            new GridColumn("quantidadeAtual", "Quantidade", GridColumnType.Decimal),
            new GridColumn("quantidadeComprometida", "Qtd. Comprometida", GridColumnType.Decimal),
        ];
    }

    gridColumnsProdutoSinonimos: Array<GridColumn> = [
        new GridColumn("sinonimo", "Sinônimo", GridColumnType.String),
        new GridColumn("descricaoRotulo", "Descrição Rótulo", GridColumnType.String),
        new GridColumn("fatorEquivalencia", "Fator de Equivalência", GridColumnType.Decimal),
    ];

    gridColumnsObservacoes: Array<GridColumn> = [
        new GridColumn("tipoDescricao", "Tipo de Observação", GridColumnType.String),
        new GridColumn("texto", "Observação", GridColumnType.String),
    ];

    gridColumnsProdutosIncompativeis: Array<GridColumn> = [
        new GridColumn("produtoIncompativelDescricao", "Descri\u00e7\u00e3o", GridColumnType.String),
        new GridColumn("formaFarmaceuticaIncompativelDescricao", "Descrição Forma Farmacêutica", GridColumnType.String),
    ];

    gridColumnsProdutoRefrigeradoFormaFarmaceutica: Array<GridColumn> = [
        new GridColumn("formaFarmaceuticaDescricao", "Forma Farmacêutica Refrigerada", GridColumnType.String),
    ];

    gridColumnsProdutoFornecedor: Array<GridColumn> = [
        new GridColumn("fornecedorDescricao", "Nome Fornecedor", GridColumnType.String),
        new GridColumn("ordem", "Ordem", GridColumnType.Integer),
    ];

    gridColumnsKitEmbalagemIncompativeis: Array<GridColumn> = [
        new GridColumn(
            "kitEmbalagemIncompativelDescricao",
            "Descrição Kit Embalagem Incompatível",
            GridColumnType.String,
        ),
    ];

    gridColumnsFormaFarmaceuticaValidades: Array<GridColumn> = [
        new GridColumn("formaFarmaceuticaDescricao", "Descri\u00e7\u00e3o", GridColumnType.String),
        new GridColumn("diasValidade", "Validade em Dias", GridColumnType.Integer),
    ];

    gridColumnsAliquotasInterestaduais: Array<GridColumn> = [
        new GridColumn("ufDestino", "UF", GridColumnType.String),
        new GridColumn("aliquota", "Alíquota", GridColumnType.Decimal),
    ];

    get extraButtonActionFichaTecnica(): Array<ModalButtonAction> {
        return [
            new ModalButtonAction("resultados", this.$t("__.ts.Resultado").toString(), true, "i", " btn-warning"),
            new ModalButtonAction("imprimir", this.$t("__.ts.imprimir").toString(), true, "i", " btn-info"),
        ];
    }

    @Prop({ type: Boolean, default: false }) isModal: boolean;
    @Prop(String) descricaoSugestao: string;

    public async load() {
        this.model = new ProdutoModel();

        if (this.modelId > 0) {
            try {
                const data = await this.service
                    .get(this.modelId, 0.1, false)
                    .withLoading()
                    .resolveWithJSON<ProdutoModel>();
                this.model.updateFrom(data);

                this.usaMarkupDinamico = this.model.produtoMarkupDinamico.length > 0;
                this.model.produtoMarkupDinamico = this.model.produtoMarkupDinamico.sort((a, b) =>
                    Number(a.demaisValores) < Number(b.demaisValores) ? 1 : -1,
                );

                if (this.model.unidadeMedidaEstoqueId > 0) {
                    const unidadeMedidaEstoque = this.preLoadPack.unidadesMedida.find(
                        p => p.id == this.model.unidadeMedidaEstoqueId,
                    );
                    if (unidadeMedidaEstoque) {
                        this.siglaEstoqueDescricao = unidadeMedidaEstoque.sigla;
                    }
                }

                this.model.produtoObservacoes = this.model.produtoObservacoes.map(obs => {
                    const observacao = new ProdutoObservacoesModel();
                    observacao.texto = obs.texto;
                    observacao.tipo = obs.tipo;
                    observacao.tipoDescricao = this.observacaoTipoOptions[obs.tipo]["text"];
                    return observacao;
                });

                this.franquiaId = data.franquiaId;

                this.model.produtoAssociacoes.forEach(p => {
                    p.tipoCalculoAssociacaoDescricao = !p.excipiente
                        ? EnumExtensions.getNameByValue(TipoCalculoAssociacao, p.tipoCalculoAssociacao)
                        : null;
                });

                this.loadProdutosIncompatibilidadeOptions();
                this.loadKitEmbalagemIncompatibilidadeOptions();
                this.loadFormaFarmaceuticaIncompatibilidadeOptions();
                this.loadProdutosRefrigeradosOptions();

                for (let i = 0; i < this.model.validadesFormasFarmaceuticasProdutos.length; i++) {
                    const forma = this.formasFarmaceuticaOptions.filter(
                        f => f["value"] == this.model.validadesFormasFarmaceuticasProdutos[i].formaFarmaceuticaId,
                    )[0];
                    this.model.validadesFormasFarmaceuticasProdutos[i].formaFarmaceuticaDescricao = forma["text"];
                }

                this.refreshDataProdutosAliquotasInterestaduais = true;

                this.loadUfsAliquotasInterestaduais();

                const lotes = await this.serviceLote
                    .listFilterProduto(data.id, null, null, 1, 999999)
                    .withLoading()
                    .resolveWithJSON<PaginationModel<ProdutoLoteModel>>();

                const lotesVadidos = lotes.list.filter(p => !p.loteFoiFracionado);
                lotesVadidos.forEach(item => {
                    if (item.origemProdutoLote == OrigemProdutoLote.Fracionamento) {
                        item.quantidadeAtual = item.quantidadeAtual * item.volumeFracionado;
                    }
                });

                this.totalLote = lotesVadidos.reduce((acc, p) => arithmeticHelper.round(acc + p.quantidadeAtual, 4), 0);
                this.gridDataLote = lotesVadidos;

                const quantidadesComprometidas = await new EstoqueComprometidoService()
                    .getQuantidadeComprometidaLotesByIds(lotesVadidos.map(p => p.id))
                    .withLoading()
                    .resolveWithJSON<{ id: number; quantidadeComprometida: number }[]>();

                this.gridDataLote.forEach(p => {
                    p.quantidadeComprometida = quantidadesComprometidas.filter(
                        q => q.id == p.id,
                    )[0].quantidadeComprometida;
                });

                this.loadGridProdutoLote();
                this.loadGridProdutoFornecedores(true);
            } catch {
                this.$router.back();
            }

            this.loadCompleted = true;
        } else if (this.descricaoSugestao != null && this.isModal) {
            this.model.descricao = this.descricaoSugestao;
        }
    }

    public constructor() {
        super();
    }

    public async save() {
        try {
            const isValid = await this.$validator.validateAll();
            if (isValid) {
                const response = await this.service[!this.model.id ? "insert" : "update"](this.model)
                    .withLoading()
                    .resolveWithResponse();

                if (response.ok) {
                    const nId = Number(response.headers.get("Id"));

                    this["LOAD_LIST"]({ loadProdutos: true });

                    if (
                        this.model.tipoProduto == TiposProduto.Capsula &&
                        this.model.capsulaId != null &&
                        this.model.ordemCapsula == null
                    ) {
                        await this.defineOrdemCoresCapsulas();
                    }

                    await this.$showInclusaoUpdate(this.model.id);
                    if (!this.model.id) {
                        this.model.id = nId;
                    }

                    if (this.isModal) {
                        this.$emit("save-ok", this.model);
                        return true;
                    } else if (
                        this.preLoadPack.currentFranquiaId != this.model.franquiaId &&
                        this.model.franquiaId != null
                    ) {
                        this.load();
                    } else {
                        this.$router.back();
                    }
                }
            } else {
                this.$focusErrorField();
            }
        } catch {}
        return false;
    }

    private async defineOrdemCoresCapsulas() {
        try {
            let cores = await this.serviceCapsula
                .produtosCores(this.model.capsulaId)
                .then(r => r.json() as Promise<ProdutoModel[]>);
            cores = cores.sort((a, b) =>
                a.ordemCapsula > b.ordemCapsula && a.ordemCapsula != null && b.ordemCapsula != null ? 1 : -1,
            );

            let ordermNormal = 1;
            let ordemGastrorresistente = 1;
            let ordemVegana = 1;
            let ordemVeganaRevestida = 1;

            const produtosOrdem = cores.map(p => {
                let produto = new ProdutoModel();
                produto = p;

                if (p.tipoCapsula == TiposCapsula.Normal) {
                    produto.ordemCapsula = ordermNormal;
                    ordermNormal++;
                }
                if (p.tipoCapsula == TiposCapsula.Revestida) {
                    produto.ordemCapsula = ordemGastrorresistente;
                    ordemGastrorresistente++;
                }
                if (p.tipoCapsula == TiposCapsula.Vegana) {
                    produto.ordemCapsula = ordemVegana;
                    ordemVegana++;
                }
                if (p.tipoCapsula == TiposCapsula.VeganaRevestida) {
                    produto.ordemCapsula = ordemVeganaRevestida;
                    ordemVeganaRevestida++;
                }
                return produto;
            });

            await this.serviceCapsula.salvarOrdem(produtosOrdem);
        } catch {}
    }

    private cancel() {
        this.$router.back();
    }

    private async loadPosologia() {
        try {
            const data = await new PosologiaService()
                .combo()
                .then(r => r.json() as Promise<PaginationModel<PosologiaModel>>);
            this.posologiaOptions = data.list.map(getPosologiaCombo);
        } catch {}
    }

    private async loadFormasFarmaceutica() {
        try {
            const data = await new FormaFarmaceuticaService()
                .combo()
                .then(r => r.json() as Promise<PaginationModel<FormaFarmaceuticaModel>>);
            this.formasFarmaceuticaOptions = data.list.map(getFormaFarmaceuticaCombo);
        } catch {}
    }

    private async loadGruposProduto() {
        try {
            const data = await new GrupoProdutoService()
                .combo()
                .then(r => r.json() as Promise<PaginationModel<GrupoProdutoModel>>);
            this.grupoProdutoOptions = data.list.map(item => ({
                value: item.id,
                text: item.descricao,
                tipo: item.tipoProduto,
            }));
        } catch {}
    }

    private async loadUnidadesMedida() {
        try {
            const data = await new UnidadeMedidaService()
                .combo()
                .then(r => r.json() as Promise<PaginationModel<UnidadeMedidaModel>>);
            this.unidadesMedidaOptions = data.list.map(item => getUnidadeMedidaCombo(item));
        } catch {}
    }

    private async loadListasControlado() {
        try {
            const data = await new ListaControladoService()
                .combo()
                .then(r => r.json() as Promise<PaginationModel<ListaControladoModel>>);
            this.listasControladoOptions = data.list.map(item => {
                return { value: item.id, text: item.codigo + " - " + item.descricao };
            });
        } catch {}
    }

    private async loadNbms() {
        try {
            const data = await new NbmService().combo().then(r => r.json() as Promise<PaginationModel<NbmModel>>);
            this.nbmsOptions = data.list.map(item => {
                return { value: item.id, text: item.codigo + " - " + item.descricao };
            });
        } catch {}
    }

    private async loadCests() {
        try {
            const data = await new CestService().combo().then(r => r.json() as Promise<PaginationModel<CestModel>>);
            this.cestsOptions = data.list.map(item => {
                return { value: item.id, text: item.codigo + " - " + item.descricao };
            });
        } catch {}
    }

    private async loadDcbs() {
        try {
            const data = await new DcbService().combo().then(r => r.json() as Promise<PaginationModel<DcbModel>>);
            this.dcbsOptions = data.list.map(item => {
                return { value: item.id, text: item.codigo + " - " + item.descricao };
            });
        } catch {}
    }

    private async loadDcis() {
        try {
            const data = await new DciService().combo().then(r => r.json() as Promise<PaginationModel<DciModel>>);
            this.dcisOptions = data.list.map(item => {
                return { value: item.id, text: item.codigo + " - " + item.descricao };
            });
        } catch {}
    }

    private async loadFarmacopeias() {
        try {
            const data = await new FarmacopeiaService()
                .combo()
                .then(r => r.json() as Promise<PaginationModel<FarmacopeiaModel>>);
            this.farmacopeiaOptions = data.list.map(f => ({ value: f.id, text: f.descricao }));
        } catch {}
    }

    private async loadCSTs() {
        const service = new SituacaoTributariaService();

        this.simplesNacional = this.usuarioLogado.franquia?.opcaoSimplesNacional;

        if (this.simplesNacional) {
            this.cstICMSOptions = service
                .getCSOSN()
                .map(item => ({ value: Number(item.codigo), text: `${item.codigo}-${item.descricao}` }));
        } else {
            this.cstICMSOptions = service
                .getICMS()
                .map(item => ({ value: Number(item.codigo), text: `${item.codigo}-${item.descricao}` }));
        }

        this.cstIPIEntradaOptions = service
            .getIPI()
            .filter(item => Number(item.codigo) < 50)
            .map(item => ({ value: Number(item.codigo), text: `${item.codigo}-${item.descricao}` }));
        this.cstIPISaidaOptions = service
            .getIPI()
            .filter(item => Number(item.codigo) >= 50)
            .map(item => ({ value: Number(item.codigo), text: `${item.codigo}-${item.descricao}` }));
        this.cstPISCOFINSEntradaOptions = service
            .getPISCOFINS()
            .filter(item => Number(item.codigo) >= 50)
            .map(item => ({ value: Number(item.codigo), text: `${item.codigo}-${item.descricao}` }));
        this.cstPISCOFINSSaidaOptions = service
            .getPISCOFINS()
            .filter(item => Number(item.codigo) < 50)
            .map(item => ({ value: Number(item.codigo), text: `${item.codigo}-${item.descricao}` }));

        this.origemMercadoriaOptions = new OrigemMercadoriaService()
            .get()
            .map(item => ({ value: item.codigo, text: `${item.codigo}-${item.descricao}` }));
    }

    private onInclusaoFormaFarmaceuticaValidade() {
        const formaFarmaceuticaValidade = this.$el.querySelector("#formaFarmaceuticaValidadeId") as HTMLSelectElement;

        if (this.formaFarmaceuticaValidadeId > 0 && this.diasValidadeForma > 0) {
            const temForma =
                this.model.validadesFormasFarmaceuticasProdutos.filter(
                    f => f.formaFarmaceuticaId == this.formaFarmaceuticaValidadeId,
                ).length > 0;

            if (!temForma) {
                const validade = new ValidadeFormaFarmaceuticaProdutoModel();

                validade.formaFarmaceuticaId = this.formaFarmaceuticaValidadeId;
                validade.formaFarmaceuticaDescricao = formaFarmaceuticaValidade.innerText;
                validade.produtoId = this.model.id;
                validade.diasValidade = this.diasValidadeForma;
                this.model.validadesFormasFarmaceuticasProdutos.push(validade);

                this.formaFarmaceuticaValidadeId = null;
                this.diasValidadeForma = 0;
            }
        }
    }

    private onInclusaoFormaFarmaceutica() {
        const formaFarmaceuticaComponent = this.$el.querySelector("#formaFarmaceuticaId") as HTMLSelectElement;

        if (this.model.formaFarmaceuticaId > 0) {
            const alreadyExists =
                this.model.formasFarmaceuticas.filter(p => p.id == this.model.formaFarmaceuticaId).length > 0;

            if (!alreadyExists) {
                const formaFarmaceuticaModel = new FormaFarmaceuticaModel();

                formaFarmaceuticaModel.id = this.model.formaFarmaceuticaId;
                formaFarmaceuticaModel.descricao = formaFarmaceuticaComponent.innerText;
                this.model.formasFarmaceuticas.push(formaFarmaceuticaModel);

                this.model.formaFarmaceuticaId = -1;
            }
        }
    }

    private loadProdutosIncompatibilidadeOptions() {
        this.produtosIncompatibilidadeOptions = this.preLoadPack.produtos
            .filter(
                p =>
                    p.id != this.model.id &&
                    this.model.produtosIncompativeis.filter(i => i.produtoIncompativelId == p.id).length == 0,
            )
            .map(getProdutoCombo);
    }

    private loadProdutosRefrigeradosOptions() {
        this.produtosRefrigeradosOptions = this.preLoadPack.formasFarmaceuticas
            .filter(
                p =>
                    p.id != this.model.id &&
                    this.model.produtoRefrigeradoFormaFarmaceutica.filter(i => i.produtoId == p.id).length == 0,
            )
            .map(getFormaFarmaceuticaCombo);
    }

    private async loadFornecedoresOptions() {
        try {
            const data = await new FornecedorService()
                .combo()
                .withLoading()
                .resolveWithJSON<PaginationModel<FornecedorModel>>();

            this.fornecedoresOptions = data.list
                .filter(p => !this.model.produtoFornecedores.some(item => item.fornecedorId === p.id))
                .map(getFornecedorCombo);
        } catch {}
    }

    private loadKitEmbalagemIncompatibilidadeOptions() {
        this.kitEmbalagensIncompatibilidadeOptions = this.preLoadPack.kitsEmbalagem
            .filter(p => !this.model.kitEmbalagemIncompatibilidade.some(i => i.kitEmbalagemIncompativelId == p.id))
            .map(getKitEmbalagemCombo);
    }

    private async loadFormaFarmaceuticaIncompatibilidadeOptions() {
        this.formaFarmaceuticaIncompatibilidadeOptions = this.preLoadPack.formasFarmaceuticas
            .filter(
                p =>
                    p.id != this.model.id &&
                    this.model.produtosIncompativeis.filter(i => i.produtoIncompativelId == p.id).length == 0,
            )
            .map(getFormaFarmaceuticaCombo);
    }

    private onInclusaoProdutoAssociacao() {
        const formaFarmaceuticaComponent = this.$el.querySelector(
            "#formaFarmaceuticaAssociacaoId",
        ) as HTMLSelectElement;
        const produtoAssociacaoComponent = this.$el.querySelector("#produtoAssociacaoId") as HTMLSelectElement;

        if (
            this.formaFarmaceuticaAssociacaoId &&
            this.produtoAssociacaoId &&
            ((this.percentualAssociacao && this.percentualAssociacao > 0) || this.excipienteAssociacao)
        ) {
            const alreadyExists =
                this.model.produtoAssociacoes.filter(
                    p =>
                        p.formaFarmaceuticaId == this.formaFarmaceuticaAssociacaoId &&
                        p.produtoAssociadoId == this.produtoAssociacaoId,
                ).length > 0;

            const possuiQsp =
                this.model.produtoAssociacoes.filter(
                    p =>
                        p.formaFarmaceuticaId == this.formaFarmaceuticaAssociacaoId &&
                        this.excipienteAssociacao &&
                        p.excipiente,
                ).length > 0;

            if (possuiQsp) {
                return this.$showError(this.$t("__.ts.erro"), this.$t("__.ts.jaExisteAssocExcipiente"));
            }

            if (!alreadyExists && !possuiQsp) {
                const associacao = new ProdutoAssociacaoModel();

                associacao.formaFarmaceuticaId = this.formaFarmaceuticaAssociacaoId;
                associacao.formaFarmaceuticaDescricao = formaFarmaceuticaComponent.innerText;

                associacao.produtoAssociadoId = this.produtoAssociacaoId;

                let descricaoProdutoAssociacao = produtoAssociacaoComponent.innerText;
                const associado = this.preLoadPack.produtos.filter(p => p.id == this.produtoAssociacaoId);
                if (associado.length > 0) {
                    descricaoProdutoAssociacao = associado[0].descricao;
                }

                associacao.produtoAssociadoDescricao = descricaoProdutoAssociacao;

                //quando eh excipiente, salva com 100 no percentual ?
                associacao.percentual = !this.excipienteAssociacao ? this.percentualAssociacao : 100;

                associacao.excipiente = this.excipienteAssociacao;

                associacao.desmembrado = this.desmembradoAssociacao && this.excipienteAssociacao;

                if (!this.excipienteAssociacao) {
                    associacao.tipoCalculoAssociacao = this.tipoCalculoAssociacao;
                    associacao.tipoCalculoAssociacaoDescricao = EnumExtensions.getNameByValue(
                        TipoCalculoAssociacao,
                        this.tipoCalculoAssociacao,
                    );
                }

                this.model.produtoAssociacoes.push(associacao);
            }
        }
    }

    private onInclusaoProdutoSinonimo() {
        if (this.indexEdditingProdutoSinonimo != null) {
            this.model.produtoSinonimos[this.indexEdditingProdutoSinonimo].sinonimo = this.produtoSinonimo;
            this.model.produtoSinonimos[this.indexEdditingProdutoSinonimo].fatorEquivalencia = this.fatorEquivalencia;
            this.model.produtoSinonimos[this.indexEdditingProdutoSinonimo].descricaoRotulo = this.descricaoRotulo;
            this.produtoSinonimo = "";
            this.descricaoRotulo = "";
            this.fatorEquivalencia = 0;
        } else {
            if (this.produtoSinonimo.length > 0) {
                const alreadyExists = this.model.produtoSinonimos.some(
                    sinonimo => sinonimo.sinonimo == this.produtoSinonimo,
                );
                if (!alreadyExists) {
                    const sinonimo = new ProdutoSinonimoModel();
                    sinonimo.sinonimo = this.produtoSinonimo;
                    sinonimo.descricaoRotulo = this.descricaoRotulo;
                    sinonimo.fatorEquivalencia = this.fatorEquivalencia;

                    this.model.produtoSinonimos.push(sinonimo);
                    this.produtoSinonimo = "";
                    this.descricaoRotulo = "";
                    this.fatorEquivalencia = 0;
                }
            }
        }
        this.indexEdditingProdutoSinonimo = null;
    }

    private async onEditProdutoSinonimos(produtoSinonimo: ProdutoSinonimoModel) {
        this.indexEdditingProdutoSinonimo = this.model.produtoSinonimos.indexOf(produtoSinonimo);

        if (this.indexEdditingProdutoSinonimo >= 0) {
            this.produtoSinonimo = this.model.produtoSinonimos[this.indexEdditingProdutoSinonimo].sinonimo;
            this.descricaoRotulo = this.model.produtoSinonimos[this.indexEdditingProdutoSinonimo].descricaoRotulo;
            this.fatorEquivalencia = this.model.produtoSinonimos[this.indexEdditingProdutoSinonimo].fatorEquivalencia;
        }
    }

    private onInclusaoProdutoObservacao() {
        if (this.observacaoTexto != "" && this.observacaoTipo != null) {
            const observacao = new ProdutoObservacoesModel();
            observacao.tipo = this.observacaoTipo;
            observacao.tipoDescricao = this.observacaoTipoOptions[this.observacaoTipo]["text"];
            observacao.texto = this.observacaoTexto;
            this.model.produtoObservacoes.push(observacao);
            this.observacaoTipo = 0;
            this.observacaoTexto = "";
        }
    }

    private onInclusaoProdutoIncompativel() {
        if (this.produtoIncompativelId > 0 && this.model.id !== this.produtoIncompativelId) {
            const temProduto =
                this.model.produtosIncompativeis.filter(p => p.id == this.produtoIncompativelId).length > 0;

            if (!temProduto) {
                const incompatibilidade = new IncompatibilidadeProdutoModel();
                incompatibilidade.produtoId = this.model.id;
                incompatibilidade.produtoIncompativelId = this.produtoIncompativelId;
                incompatibilidade.produtoIncompativelDescricao = (
                    this.$el.querySelector("#produtoIncompativelId") as HTMLSelectElement
                ).innerText;

                if (this.formaFarmaceuticaIncompativelId > 0) {
                    incompatibilidade.formaFarmaceuticaIncompativelId = this.formaFarmaceuticaIncompativelId;
                    incompatibilidade.formaFarmaceuticaIncompativelDescricao = (
                        this.$el.querySelector("#formaFarmaceuticaIncompativelId") as HTMLSelectElement
                    ).innerText;
                }
                this.model.produtosIncompativeis.push(incompatibilidade);
                this.produtoIncompativelId = null;
                this.formaFarmaceuticaIncompativelId = null;

                this.loadProdutosIncompatibilidadeOptions();
                this.loadFormaFarmaceuticaIncompatibilidadeOptions();
            }
        }
    }

    private onInclusaoProdutoRefrigerado() {
        if (this.produtoRefrigeradoFormaceuticaId > 0) {
            const alreadyExists =
                this.model.produtoRefrigeradoFormaFarmaceutica.filter(
                    p => p.formaFarmaceuticaId == this.produtoRefrigeradoFormaceuticaId,
                ).length > 0;

            if (!alreadyExists) {
                const produtoRefrigeradoFormaFarmaceutica = new ProdutoRefrigeradoFormaFarmaceuticaModel();
                produtoRefrigeradoFormaFarmaceutica.formaFarmaceuticaId = this.produtoRefrigeradoFormaceuticaId;
                produtoRefrigeradoFormaFarmaceutica.produtoId = this.model.id;
                produtoRefrigeradoFormaFarmaceutica.formaFarmaceuticaDescricao = (
                    this.$el.querySelector("#produtoRefrigeradoFormaceuticaId") as HTMLSelectElement
                ).innerText;

                this.model.produtoRefrigeradoFormaFarmaceutica.push(produtoRefrigeradoFormaFarmaceutica);
                this.produtoRefrigeradoFormaceuticaId = null;
                this.loadProdutosRefrigeradosOptions();
            }
        }
    }

    private onInclusaoProdutoFornecedor() {
        if (this.produtoFornecedorId > 0) {
            const alreadyExists =
                this.model.produtoFornecedores.filter(p => p.fornecedorId == this.produtoFornecedorId).length > 0;

            if (!alreadyExists) {
                const produtoFornecedor = new ProdutoFornecedorModel();
                produtoFornecedor.fornecedorId = this.produtoFornecedorId;
                produtoFornecedor.produtoId = this.model.id;
                produtoFornecedor.ordem = this.model.produtoFornecedores.length + 1;
                produtoFornecedor.fornecedorDescricao = (
                    this.$el.querySelector("#produtoFornecedorId") as HTMLSelectElement
                ).innerText;

                this.model.produtoFornecedores.push(produtoFornecedor);
                this.produtoFornecedorId = null;
                this.loadFornecedoresOptions();
                this.loadGridProdutoFornecedores();
            }
        }
    }

    private onInclusaoKitEmbalagemIncompativel() {
        if (
            this.kitEmbalagemIncompativelId > 0 &&
            !this.model.kitEmbalagemIncompatibilidade.some(
                x => x.kitEmbalagemIncompativelId == this.kitEmbalagemIncompativelId,
            )
        ) {
            const temProduto = this.model.kitEmbalagemIncompatibilidade.some(
                p => p.kitEmbalagemIncompativelId == this.kitEmbalagemIncompativelId,
            );

            if (!temProduto) {
                const incompatibilidade = new IncompatibilidadeKitEmbalagemModel();
                incompatibilidade.produtoId = this.model.id;
                incompatibilidade.kitEmbalagemIncompativelId = this.kitEmbalagemIncompativelId;
                incompatibilidade.kitEmbalagemIncompativelDescricao = (
                    this.$el.querySelector("#kitEmbalagemIncompativelId") as HTMLSelectElement
                ).innerText;

                this.model.kitEmbalagemIncompatibilidade.push(incompatibilidade);
                this.kitEmbalagemIncompativelId = null;

                this.loadKitEmbalagemIncompatibilidadeOptions();
            }
        }
    }

    private async loadUfsAliquotasInterestaduais() {
        const data = this.ufs;
        this.ufOptions = data.filter(
            p => this.model.produtoAliquotasInterestaduais.filter(i => i.ufDestino == p.text).length == 0,
        );
    }

    private async onInclusaoUfAliquotaInterestadual() {
        if (this.ufAliquotaInterestadual > 0 && this.aliquotaInterestadual > 0) {
            const ufDescricao = EnumExtensions.getNameByValue(Ufs, this.ufAliquotaInterestadual);
            const temUf = this.model.produtoAliquotasInterestaduais.filter(p => p.ufDestino == ufDescricao).length > 0;

            if (!temUf) {
                const aliquotaInterestadual = new ProdutoAliquotaInterestadualModel();
                aliquotaInterestadual.produtoId = this.model.id;
                aliquotaInterestadual.ufDestino = ufDescricao;
                aliquotaInterestadual.aliquota = this.aliquotaInterestadual;

                this.model.produtoAliquotasInterestaduais.push(aliquotaInterestadual);
                this.ufAliquotaInterestadual = null;
                this.aliquotaInterestadual = null;

                this.loadUfsAliquotasInterestaduais();
            }
        }
    }

    private async onShowAliquotasInterestaduais() {
        this.franquiaUf = EnumExtensions.getNameByValue(Ufs, this.usuarioLogado.franquia.estadoId);
        this.aliquotasICMSInterestadualDefault = await this.service
            .getAliquotasICMSInterestadualDefault()
            .resolveWithJSON<AliquotaInterestadualModel[]>();

        this.model.produtoAliquotasInterestaduais.forEach(p => {
            const aliquota = this.aliquotasICMSInterestadualDefault.filter(a => a.ufDestino == p.ufDestino);
            aliquota[0].aliquota = p.aliquota;
        });
        this.shortcutAliquotaInterestadualComponent = this.$refs
            .shortcutAliquotaInterestadualComponent as ShortcutComponent;

        this.shortcutAliquotaInterestadualComponent.show();
    }

    private onInclusaoMarkupDinamico() {
        const produtoMarkupDinamico = new ProdutoMarkupDinamicoModel();
        produtoMarkupDinamico.produtoId = this.model.id;
        produtoMarkupDinamico.demaisValores = this.model.produtoMarkupDinamico.length == 0;

        this.model.produtoMarkupDinamico.push(produtoMarkupDinamico);
    }

    private onRemoveFormaFarmaceutica(model: FormaFarmaceuticaModel) {
        this.model.formasFarmaceuticas = this.model.formasFarmaceuticas.filter(p => p != model);
    }

    private onRemoveProdutoAssociacoes(model: ProdutoAssociacaoModel) {
        this.model.produtoAssociacoes = this.model.produtoAssociacoes.filter(p => p != model);
    }

    private onRemoveProdutoSinonimos(model: ProdutoSinonimoModel) {
        this.model.produtoSinonimos = this.model.produtoSinonimos.filter(p => p != model);
    }

    private onRemoveProdutoObservacoes(model: ProdutoObservacoesModel) {
        this.model.produtoObservacoes = this.model.produtoObservacoes.filter(p => p != model);
    }

    private onRemoveProdutoIncompativel(model: IncompatibilidadeProdutoModel) {
        this.model.produtosIncompativeis = this.model.produtosIncompativeis.filter(p => p != model);
        this.loadProdutosIncompatibilidadeOptions();
    }

    private onRemoveKitEmbalagemIncompativel(model: IncompatibilidadeKitEmbalagemModel) {
        this.model.kitEmbalagemIncompatibilidade = this.model.kitEmbalagemIncompatibilidade.filter(p => p != model);
        this.loadKitEmbalagemIncompatibilidadeOptions();
    }

    private onRemoverProdutoRefrigeradoFormaFarmaceutica(model: ProdutoRefrigeradoFormaFarmaceuticaModel) {
        this.model.produtoRefrigeradoFormaFarmaceutica = this.model.produtoRefrigeradoFormaFarmaceutica.filter(
            p => p != model,
        );
        this.loadProdutosRefrigeradosOptions();
    }

    private onRemoverProdutoFornecedor(model: ProdutoFornecedorModel) {
        this.model.produtoFornecedores = this.model.produtoFornecedores
            .filter(p => p != model)
            .map((item, index) => {
                item.ordem = index + 1;
                return item;
            });

        this.loadFornecedoresOptions();
    }

    private onRemoveFormaFormaceuticaValidade(model: ValidadeFormaFarmaceuticaProdutoModel) {
        this.model.validadesFormasFarmaceuticasProdutos = this.model.validadesFormasFarmaceuticasProdutos.filter(
            p => p != model,
        );
    }

    private onRemoveAliquotaInterestadual(model: ProdutoAliquotaInterestadualModel) {
        this.model.produtoAliquotasInterestaduais = this.model.produtoAliquotasInterestaduais.filter(p => p != model);
        this.loadUfsAliquotasInterestaduais();
    }

    private onRemoveMarkupDinamico(model: ProdutoMarkupDinamicoModel) {
        this.model.produtoMarkupDinamico = this.model.produtoMarkupDinamico.filter(p => p != model);
    }

    private async onExtraAction(name: string, model: ProdutoLoteModel) {
        if (name.trim() == "ver") {
            this.onVer(model);
        } else if (name.trim() == "imprimir-rotulo") {
            this.onImprimirRotulo(model);
        } else if (name.trim() == "definir-lote") {
            if (model.id === this.model.lotePadraoId) {
                this.model.lotePadraoId = null;
                this.loadGridProdutoLote();
            } else {
                if (model.quantidadeAtual > 0) {
                    this.model.lotePadraoId = model.id;
                    this.loadGridProdutoLote();
                } else {
                    this.$showWarning("Atenção", "Este lote não possui quantidade.");
                }
            }
        }
    }

    private onExtraActionsProdutoFornecedor(name: string, campo: ProdutoFornecedorModel) {
        if (name.trim() == "up") {
            this.model.produtoFornecedores = changePosition(this.model.produtoFornecedores, campo, "UP");
        }

        if (name.trim() == "down") {
            this.model.produtoFornecedores = changePosition(this.model.produtoFornecedores, campo, "DOWN");
        }
        this.loadGridProdutoFornecedores();
    }

    private loadGridProdutoFornecedores(ordenar = false) {
        if (ordenar) {
            this.model.produtoFornecedores = this.model.produtoFornecedores
                .sort((a, b) => a.ordem - b.ordem)
                .map((item, index) => {
                    item.ordem = index + 1;
                    return item;
                });
            return this.addGridExtraActions();
        }

        this.model.produtoFornecedores = this.model.produtoFornecedores.map((item, index) => {
            item.ordem = index + 1;
            return item;
        });
        this.addGridExtraActions();
    }

    private addGridExtraActions() {
        this.extraActionsUp.splice(0);
        this.extraActionsDown.splice(0);
        this.extraActionsProdutoFornecedor.splice(0);

        for (let i = 0; i < this.model.produtoFornecedores.length; i++) {
            this.extraActionsUp.push(
                new GridAction("up", "Colocar para cima", "fa fa-arrow-up", GridColors.DARKGRAY, true),
            );
            this.extraActionsDown.push(
                new GridAction("down", "Colocar para baixo", "fa fa-arrow-down", GridColors.DARKGRAY, true),
            );
        }

        this.extraActionsProdutoFornecedor.push(this.extraActionsUp);
        this.extraActionsProdutoFornecedor.push(this.extraActionsDown);
    }

    loadGridProdutoLote() {
        this.gridExtraActionsVer.splice(0);
        this.gridExtraActionsImprimirRotulo.splice(0);
        this.gridExtraActionsDefinirLotePadrao.splice(0);
        this.extraActions.splice(0);

        for (let i = 0; i < this.gridDataLote.length; i++) {
            const icon =
                this.gridDataLote[i].id == this.model.lotePadraoId ? "far fa-dot-circle fa-2x" : "far fa-circle fa-2x";

            this.gridExtraActionsVer.push(new GridAction("ver", "Ver Lote", "fa fa-eye", GridColors.BLUE, true));

            this.gridExtraActionsImprimirRotulo.push(
                new GridAction("imprimir-rotulo", "Imprimir R\u00F3tulo", "fa fa-tag", GridColors.GREEN, true),
            );

            this.gridExtraActionsDefinirLotePadrao.push(
                new GridAction("definir-lote", "Definir como lote padrão", icon, GridColors.BLUE),
            );
        }

        this.extraActions.push(this.gridExtraActionsVer);
        this.extraActions.push(this.gridExtraActionsImprimirRotulo);
        this.extraActions.push(this.gridExtraActionsDefinirLotePadrao);
    }

    private async onImprimirRotulo(lote: ProdutoLoteModel) {
        await this.imprimirRotuloPersonalizadoComponent.showRotuloLote(lote.id, {});
    }

    private async onVer(lote: ProdutoLoteModel) {
        this.loadLote = true;
        this.loteId = lote.id;
        this.shortcutComponent = this.$refs.shortcutComponent as ShortcutComponent;
        this.shortcutComponent.title = "Lote";
        this.shortcutComponent.show();
    }

    private async onAddNewPosologia(closeModal) {
        if (await this.posologiaComponent.save()) {
            closeModal();
        }
    }

    private async openComboNewPosologia() {
        this.posologiaComponent.modelId = 0;
        this.posologiaComponent.load();
    }

    private async openComboEditPosologia(id: number) {
        this.posologiaComponent.modelId = id;
        this.posologiaComponent.load();
    }

    private async onPosologiaSaveOk(modelNovaPosologia: PosologiaModel) {
        await this.loadPosologia();
        this.model.posologiaId = modelNovaPosologia.id;
    }

    get isMarkupNull() {
        return this.model.markup == null;
    }

    private onMarkupPadraoChange() {
        if (this.model.markup != null) {
            this.model.markup = null;
        } else {
            this.model.markup = 0;
        }

        this.calcularValorVenda();
    }

    //@ts-ignore
    @Watch("franquiaId")
    private onFranquiaChanged() {
        if (this.franquiaId > 0 && this.franquiaId != this.model.franquiaId) {
            this.loadProdutoByParent();
        }
    }

    //@ts-ignore
    @Watch("model.posologiaId")
    private onPosologiaID() {
        this.addNewId = this.model.posologiaId;
    }

    private onMarkupChanged(newValue: number, oldValue: number) {
        if (oldValue || newValue) {
            this.calcularValorVenda();
        }
    }

    private async calculaMarkupByValorVenda(newValue: number, oldValue: number) {
        if (oldValue || newValue) {
            const response = await this.$showQuestion(this.$t("__.ts.atencao"), this.$t("__.ts.desejRecalcMarkup"));
            if (response) {
                this.model.markup = await this.service
                    .calcularMarkupByValorVenda(this.model)
                    .withLoading()
                    .resolveWithJSON<number>();
            }
        }
    }

    //@ts-ignore
    @Watch("model.custoReferencia")
    private onCustoReferenciaChanged() {
        this.calcularValorVenda();
    }
    //@ts-ignore
    @Watch("model.ignoraControleEstoque")
    private async onIgnoraControleEstoqueChanged(newValue: number) {
        if (!this.loadCompleted) return;

        if (newValue) {
            try {
                const response = await this.$showQuestion(
                    this.$t("__.ts.atencao"),
                    this.$t("__.ts.estNaoSeraControl").toString() + this.$t("__.ts.desejaRealmRemoverContr"),
                );
                if (!response) {
                    this.model.ignoraControleEstoque = false;
                }
            } catch {
                this.model.ignoraControleEstoque = false;
            }
        }
    }
    //@ts-ignore
    @Watch("model.grupoProdutoId")
    private onGrupoProdutoIdChanged() {
        const grupo = this.grupoProdutoOptions.filter(p => p["value"] == this.model.grupoProdutoId);
        if (grupo.length > 0) this.model.tipoProduto = grupo[0]["tipo"];
        switch (this.model.tipoProduto) {
            case TiposProduto.MateriaPrima:
                this.subGrupoOptions = [
                    { text: "Ativo", value: 0 },
                    { text: "Flavorizante", value: 1 },
                    { text: " Veículos/Excipientes", value: 2 },
                ];
                break;
            case TiposProduto.Embalagem:
                this.subGrupoOptions = [
                    { text: "Recipiente", value: 3 },
                    { text: "Tampa", value: 4 },
                    { text: "Aplicador", value: 5 },
                    { text: "Outros", value: 6 },
                ];
                break;
            case TiposProduto.Capsula:
                this.subGrupoOptions = [{ text: "Cor", value: 7 }];
                break;
        }
    }
    // @ts-ignore
    @Watch("model.cstPISEntrada")
    private onCSTPISEntradaChange() {
        if (!this.model.cstCOFINSEntrada) {
            this.model.cstCOFINSEntrada = this.model.cstPISEntrada;
        }
    }
    // @ts-ignore
    @Watch("model.cstPISSaida")
    private onCSTPISChange() {
        if (!this.model.cstCOFINSSaida) {
            this.model.cstCOFINSSaida = this.model.cstPISSaida;
        }
    }

    // @ts-ignore
    @Watch("usaMarkupDinamico")
    private onChangeUsaMarkupDinamico() {
        if (this.usaMarkupDinamico && this.model.produtoMarkupDinamico.length == 0) {
            this.onInclusaoMarkupDinamico();
        }
    }

    private async calcularValorVenda() {
        if (this.loadCompleted) {
            try {
                const response = await this.$showQuestion(
                    this.$t("__.ts.atencao"),
                    this.$t("__.ts.desejRecalcVlrVenda"),
                );
                if (response) {
                    this.model.valorVenda = await this.service
                        .calcularValorVenda({
                            ...this.model,
                            markup: this.model.markup ? this.model.markup / 100 : this.model.markup,
                        } as ProdutoModel)
                        .withLoading()
                        .resolveWithJSON<number>();
                }
            } catch {}
        }
    }

    private async getPreLoadPack() {
        while (!this.loadedList) await Delay(100);

        const currentFranquia = this.preLoadPack.currentFranquiaId;

        this.unidadesMedidaOptions = this.preLoadPack.unidadesMedida
            .filter(p => p.franquiaId == currentFranquia || p.franquiaId == null)
            .map(item => getUnidadeMedidaCombo(item));
        this.formasFarmaceuticaOptions = this.preLoadPack.formasFarmaceuticas
            .filter(p => p.franquiaId == currentFranquia || p.franquiaId == null)
            .map(getFormaFarmaceuticaCombo);
        this.posologiaOptions = this.preLoadPack.posologias
            .filter(p => p.franquiaId == currentFranquia || p.franquiaId == null)
            .map(getPosologiaCombo);
        this.produtoOptions = this.preLoadPack.produtos
            .filter(
                p =>
                    (p.franquiaId == currentFranquia || p.franquiaId == null) &&
                    p.subGrupo == SubGrupoProduto.VeiculoExcipiente,
            )
            .map(getProdutoCombo);
        this.produtoAssOptions = this.preLoadPack.produtos
            .filter(p => p.franquiaId == currentFranquia || p.franquiaId == null)
            .map(getProdutoCombo);
        this.produtoDiluidoOptions = this.preLoadPack.produtos
            .filter(
                p =>
                    (p.franquiaId == currentFranquia || p.franquiaId == null) &&
                    p.tipoProduto == TiposProduto.MateriaPrima &&
                    p.subGrupo == SubGrupoProduto.Ativo &&
                    p.id != +this.$route.params.id,
            )
            .map(getProdutoCombo);
        this.produtoPuroOptions = this.preLoadPack.produtos
            .filter(
                p =>
                    (p.franquiaId == currentFranquia || p.franquiaId == null) &&
                    p.tipoProduto == TiposProduto.MateriaPrima &&
                    p.subGrupo == SubGrupoProduto.Ativo &&
                    p.id != +this.$route.params.id,
            )
            .map(getProdutoCombo);
        this.kitEmbalagemOptions = this.preLoadPack.kitsEmbalagem
            .filter(p => p.franquiaId == currentFranquia || p.franquiaId == null)
            .map(getKitEmbalagemCombo);

        this.loadProdutosIncompatibilidadeOptions();
        this.loadKitEmbalagemIncompatibilidadeOptions();
        this.loadFormaFarmaceuticaIncompatibilidadeOptions();
        this.loadProdutosRefrigeradosOptions();
        this.loadFornecedoresOptions();
    }

    private loadProdutosExcipientes() {
        this.produtoOptions = this.preLoadPack.produtos
            .filter(p => p.franquiaId == this.preLoadPack.currentFranquiaId || p.franquiaId == null)
            .map(getProdutoCombo);
    }

    private getSignal(unidadeMedidaId: number) {
        if (unidadeMedidaId > 0) {
            const unMed = this.preLoadPack.unidadesMedida.filter(p => p.id == unidadeMedidaId);
            if (unMed.length > 0) {
                if (unMed[0].sigla != null) {
                    return unMed[0].sigla;
                }
            }
        }

        return "";
    }

    private validarVisibilidadeBotoes(botao: string): boolean {
        if (botao == "replicar") {
            return (
                this.model.id > 0 &&
                this.VALIDAR_PERMISSAO_USUARIO("produtos", "insert") &&
                this.model.produtoPaiId == null &&
                this.isAdminFranqueador
            );
        }

        if (botao == "remover") {
            return (
                this.VALIDAR_PERMISSAO_USUARIO("produtos", "delete") &&
                this.model.id > 0 &&
                this.model.produtoPaiId != null
            );
        }
    }

    private async onReplicar(ids: Array<number>) {
        this.replicarProdutoComponent = this.$refs.replicarProdutoComponent as ReplicarProdutoComponent;
        this.replicarProdutoComponent.open();
    }

    private async onConfirmReplicarProdutos(ids: Array<number>) {
        this.filtroVinculos = ids.reduce((a, b) => a + b, 0);
        this.replicarSelecaoFranquiaComponent = this.$refs
            .replicarSelecaoFranquiaComponent as ReplicarSelecaoFranquiaComponent;
        this.replicarSelecaoFranquiaComponent.openFranquias();
    }

    private async onConfirmReplicar(franquiasIds: Array<number>) {
        const idsFranquias = franquiasIds.toString();
        try {
            const sucesso = await this.service
                .replicar(this.model.id, idsFranquias, this.filtroVinculos)
                .withLoading()
                .resolveWithoutJSON();
            if (sucesso) {
                this["LOAD_LIST"]({ loadProdutos: true });
                this.$showSuccess("Replicou", this.$t("__.ts.regisSalvosSucess"));
            }
        } catch {}
    }

    private async loadFranquias() {
        if (this.isAdmin) {
            try {
                const data = await this.franquiaService.combo().resolveWithJSON<PaginationModel<FranquiaModel>>();
                this.franquiaOptions = data.list.map(item => ({ value: item.id, text: item.nomeFantasia }));
            } catch {}
        }
    }

    private async loadCores() {
        try {
            const data = await new CorService().combo().then(r => r.json() as Promise<PaginationModel<CorModel>>);
            this.coresOptions = data.list.map(item => ({ value: item.id, text: item.descricao }));
        } catch {}
    }

    private async loadCodigoSiproquim() {
        try {
            const data = await new CodigoSiproquimService()
                .combo()
                .resolveWithJSON<PaginationModel<CodigoSiproquimModel>>();

            this.codigoSiproquimOptions = data.list.map(p => ({
                text: `${p.codigo} - ${p.nomeProduto} ((NCM: ${p.ncm}))`,
                value: p.id,
            }));
        } catch {}
    }

    private async loadProdutoByParent() {
        if (this.modelId > 0 && this.franquiaId > 0) {
            this.loadCompleted = false;

            try {
                const data = await this.service
                    .getByParent(this.modelId, this.franquiaId)
                    .withLoading()
                    .resolveWithJSON<ProdutoModel>();

                this.model = new ProdutoModel();
                this.model.updateFrom(data);
                this.franquiaId = data.franquiaId;

                setTimeout(() => {
                    this.loadCompleted = true;
                }, 250);
            } catch {
                this.loadCompleted = true;
            }
        }
    }

    private onFechar() {
        this.loadLote = false;
        this.shortcutComponent.hide();
    }

    private async onDelete() {
        try {
            const response = await this.$showQuestion(
                this.$t("__.ts.desejaExcl") + this.model.descricao + "?",
                this.$t("__.ts.acaoNaoRevert"),
            );
            if (response) {
                const sucesso = await this.service.delete(this.model.id).withLoading().resolveWithoutJSON();

                if (sucesso) {
                    this.$showExclusao();
                    this.$router.back();
                }
            }
        } catch {}
    }

    private getSiglaEstoque() {
        if (this.model.unidadeMedidaEstoqueId > 0) {
            const unMed = this.preLoadPack.unidadesMedida.filter(p => p.id == this.model.unidadeMedidaEstoqueId);
            if (unMed.length > 0) return unMed[0].sigla;
        } else return "";
    }
    //ver log de alteracao e se nao possuir registro apresentar mensagem
    private async verLogAlteracao() {
        this.showLog = true;

        while (!this.$refs.shortcutLogComponent) await Delay(5);
        this.shortcutLogComponent = this.$refs.shortcutLogComponent as ShortcutComponent;

        while (!this.$refs.produtoLogFinanceiroComponent) await Delay(5);
        this.produtoLogFinanceiroComponent = this.$refs.produtoLogFinanceiroComponent as ProdutoLogFinanceiroComponent;

        this.shortcutLogComponent.title = this.$t("__.ts.logAltValores").toString();

        const modelAtualLog = new ProdutoLogFinanceiroModel();
        modelAtualLog.produtoId = this.model.id;
        modelAtualLog.custoMedio = this.model.custoMedio;
        modelAtualLog.custoReferencia = this.model.custoReferencia;
        modelAtualLog.markup = this.model.markup;
        modelAtualLog.valorCusto = this.model.valorCusto;
        modelAtualLog.valorVenda = this.model.valorVenda;

        this.produtoLogFinanceiroComponent.loadByProduto(modelAtualLog, this.model);

        this.shortcutLogComponent.show();
    }

    private onCloseLog() {
        this.shortcutLogComponent.hide();
    }

    private async onFichaTecnica() {
        this.loadFichaTecnicaComponent = true;

        this.shortcutFichaTecnicaComponent = this.$refs.shortcutFichaTecnicaComponent as ShortcutComponent;
        this.shortcutFichaTecnicaComponent.title = "FICHA TÉCNICA";
        this.shortcutFichaTecnicaComponent.show();

        while (!this.$refs.fichaTecnicaComponent) await Delay(5);
        this.fichaTecnicaComponent = this.$refs.fichaTecnicaComponent as FichaTecnicaEditComponent;

        this.fichaTecnicaComponent.loadFicha(this.model.id);
    }

    private async onSaveFichaTecnica() {
        if (await this.fichaTecnicaComponent.save()) {
            this.shortcutFichaTecnicaComponent.hide();
        }
    }

    private onExtraButtonActionFichaTecnica(name: string) {
        if (name == "imprimir") {
            this.fichaTecnicaComponent.imprimir();
        }
        if (name == "resultados") {
            this.fichaTecnicaComponent.resultados();
        }
    }

    private async onTrocarUnidadeMedidaEstoque() {
        this.unidadeMedidaEstoqueIdDestino = null;

        this.shortcutTrocarUnidadeMedidaComponent.title = this.$t(
            "__.Crud.produto.edit_vue_html.trocarUnidadeMedidaEstoque",
        ) as string;
        this.shortcutTrocarUnidadeMedidaComponent.show();
    }

    private async onSaveTrocarUnidadeMedidaEstoque() {
        if (!this.unidadeMedidaEstoqueIdDestino) {
            return this.$showWarning(this.$t("__.ts.atencao"), "Selecione a unidade de medida de destino");
        }

        try {
            const sucesso = await this.service
                .trocarUnidadeMedidaEstoque(this.model.id, this.unidadeMedidaEstoqueIdDestino)
                .withLoading();

            if (sucesso) {
                await this.$showSuccess(this.$t("__.ts.sucesso"), "Unidade de Medida de Estoque trocada com sucesso");

                this.loadCompleted = false;
                await this.load();
                this.loadCompleted = true;
            }
        } catch {}

        this.shortcutTrocarUnidadeMedidaComponent.hide();
    }

    private async mounted() {
        this.posologiaComponent = this.$refs.posologiaComponent as PosologiaComponent;
        this.shortcutTrocarUnidadeMedidaComponent = this.$refs
            .shortcutTrocarUnidadeMedidaComponent as ShortcutComponent;
        this.imprimirRotuloPersonalizadoComponent = this.$refs
            .imprimirRotuloPersonalizadoComponent as ImprimirRotuloPersonalizadoComponent;

        this.isAdmin = await this.HAS_PERMISSAO_PERFIL(false, false, false);
        this.isAdminFranqueador = await this.HAS_PERMISSAO_PERFIL(false, true, false);
        this.isAdminSuporteFranqueado = await this.HAS_PERMISSAO_PERFIL(false, true, true);

        this.somenteConsulta = this.VALIDAR_PERMISSAO_SOMENTE_CONSULTA("produtos");
        this.showFinanceiro = this.VALIDAR_PERMISSAO_USUARIO("manipulacoes_venda", "ConferirCustosManipulacao");

        await this.$store.dispatch(SessionActions.LOAD_USUARIO_LOGADO);

        Promise.all([
            this.loadFarmacopeias(),
            this.loadGruposProduto(),
            this.loadListasControlado(),
            this.loadNbms(),
            this.loadCests(),
            this.loadDcbs(),
            this.loadDcis(),
            this.getPreLoadPack(),
            this.loadFranquias(),
            this.loadCores(),
            this.loadCodigoSiproquim(),
        ])
            .withLoading()
            .then(() => {
                this.loadCSTs();

                this.capsulasOptions = this.preLoadPack.capsulasCombo();

                if (this.$route.params.id) {
                    this.modelId = +this.$route.params.id;
                    this.load();
                } else {
                    if (this.isModal && this.descricaoSugestao != null) {
                        this.model.descricao = this.descricaoSugestao;
                    }
                    this.loadCompleted = true;
                }
            })
            .catch(() => {
                this.loadCompleted = true;
            });
    }
}
