import Vue from "vue";
import { mapMutations } from "vuex";

import actionBarComponent from "@/components/child/actionBar/actionBar.vue";
import comboComponent from "@/components/child/form/combo.vue";
import decimalComponent from "@/components/child/form/decimal.vue";
import decimalComSufixoNullableComponent from "@/components/child/form/decimalComSufixoNullable.vue";
import fieldsetComponent from "@/components/child/form/fieldset.vue";
import { Component, Watch } from "@/decorators";
import ConfiguracaoFranquiaModel from "@/models/configuracaoFranquia/configuracaoFranquiaModel";
import ConfiguracaoRotuloModel from "@/models/configuracaoRotuloModel";
import AmbienteEnvioNFe from "@/models/enum/configuracao/ambienteEnvioNFe";
import Configuracoes from "@/models/enum/configuracao/configuracoes";
import ControleSessaoCaixa from "@/models/enum/configuracao/controleSessaoCaixa";
import Modulos from "@/models/enum/configuracao/modulos";
import TipoCampoConfiguracao from "@/models/enum/configuracao/tipoCampoConfiguracao";
import EnumExtensions from "@/models/enum/extensions/enumExtensions";
import OrigemRotulo from "@/models/enum/rotulo/origemRotulo";
import TipoMovimentacao from "@/models/enum/tiposMovimentacao";
import EstoqueModel from "@/models/estoqueModel";
import ContaCorrenteModel from "@/models/financeiro/contaCorrenteModel";
import ContaGerencialModel from "@/models/financeiro/contaGerencialModel";
import FranquiaModel from "@/models/franquiaModel";
import NaturezaOperacaoModel from "@/models/naturezaOperacaoModel";
import PaginationModel from "@/models/paginationModel";
import ProdutoModel from "@/models/produto/produtoModel";
import ServicoModel from "@/models/servicoModel";
import UsuarioModel from "@/models/usuarioModel";
import ConfiguracaoFranquiaService from "@/services/configuracaoFranquia/configuracaoFranquiaService";
import ConfiguracaoRotuloService from "@/services/configuracaoRotuloService";
import EstoqueService from "@/services/estoqueService";
import ContaCorrenteService from "@/services/financeiro/contaCorrenteService";
import ContaGerencialService from "@/services/financeiro/contaGerencialService";
import FranquiaService from "@/services/franquiaService";
import NaturezaOperacaoService from "@/services/naturezaOperacaoService";
import ProdutoService from "@/services/produto/produtoService";
import ServicoService from "@/services/servicoService";
import UsuarioService from "@/services/usuarioService";
import { Mutations } from "@/store/store";

import "../crud.scss";

@Component({
    components: {
        comboComponent,
        fieldsetComponent,
        decimalComponent,
        actionBarComponent,
        decimalComSufixoNullableComponent,
    },
    methods: mapMutations(["LOAD_CONFIGURACOES_FRANQUIA"] as Mutations),
})
export default class ConfiguracaoFranquiaListComponent extends Vue {
    // State computed props
    LOAD_CONFIGURACOES_FRANQUIA: () => void;

    private service = new ConfiguracaoFranquiaService();
    private produtoService = new ProdutoService();

    tiposCalculoMarkupOptions: Array<Object> = [
        { text: "Soma", value: 0 },
        { text: "Multiplica\u00e7\u00e3o", value: 1 },
    ];
    considerarValidadeDaManipulacaoOptions: Array<Object> = [
        { text: "Forma Farmacêutica", value: 1 },
        { text: "Validade do lote", value: 2 },
    ];
    produtoOptions: Array<Object> = [];
    contasCorrenteOptions: Array<Object> = [];
    contasGerenciaisSaidaOptions: Array<Object> = [];
    contasGerenciaisEntradaOptions: Array<Object> = [];
    sessoesCaixaOptions: Array<Object> = [
        { text: "Nenhum", value: ControleSessaoCaixa.Nenhum },
        { text: "Franquia", value: ControleSessaoCaixa.Franquia },
        { text: "Usuario", value: ControleSessaoCaixa.Usuario },
    ];
    ambientesEnvioNFeOptions: Array<Object> = [
        { text: "Homologa\u00e7\u00e3o", value: AmbienteEnvioNFe.Homologacao },
        { text: "Produ\u00e7\u00e3o", value: AmbienteEnvioNFe.Producao },
    ];
    servicosFiscaisOptions: Array<Object> = [];
    estoquesOptions: Array<Object> = [];
    verdadeiroOptions: Array<Object> = [
        { text: "Sim", value: true },
        { text: "N\u00e3o", value: false },
    ];
    naturezaOperacaoDentroOptions: Array<Object> = [];
    naturezaOperacaoForaOptions: Array<Object> = [];
    usuarioOptions: Array<Object> = [];
    rotulosLoteOptions: Array<Object> = [];
    rotulosManipulacaoOptions: Array<Object> = [];

    configuracoes: Array<Object> = [
        { text: "EstoquePadrao", value: 1 },
        { text: "ContaGerencialPadraoVenda", value: 2 },
        { text: "ContaGerencialTransferenciaInternaSaida", value: 3 },
        { text: "ContaGerencialTransferenciaInternaEntrada", value: 4 },
        { text: "ControlarSessaoCaixaPor", value: 5 },
        { text: "HorasValidadeOrcamento", value: 6 },
        { text: "AmbienteEnvioNFe", value: 7 },
        { text: "ServicoFiscalManipulacao", value: 8 },
        { text: "AtualizarValoresOrcamentosContinuos", value: 9 },
        { text: "ContaCorrenteSangriaFinalizacaoCaixa", value: 11 },
        { text: "TempoMinimoPrevisaoEntregaVenda", value: 12 },
        { text: "ValorMarkupPadrao", value: 13 },
        { text: "DiasAvisoEstoqueVencendo", value: 14 },
        { text: "DiasValidadePadraoLoteNaoInformadoXml", value: 15 },
        { text: "TipoCalculoMarkup", value: 16 },
        { text: "RotuloLote", value: 17 },
        { text: "RotuloManipulacao", value: 18 },
        { text: "FranquiaAbrangencia", value: 19 },
    ];

    private configs: Array<ConfiguracaoFranquiaModel> = [];
    franquiasOptions: Array<Object> = [];
    private franquiaId: number = null;
    //apenas para melhorar visualizacao na vue
    private tipoLong = 0; //long?
    private tipoString = 1; //string
    private tipoBoolean = 2; //boolean
    private tipoEstoque = 3; //id tabela estoque
    private tipoContaCorrente = 4; //id tabela conta corrente
    private tipoContaGerencialEntrada = 5; //id Tabela conta gerencial
    private tipoContaGerencialSaida = 6; //id Tabela conta gerencial
    private tipoServico = 7; //id tabela servico
    private tipoFornecedor = 8; //id tabela fornecedor
    private naturezaOperacaoDentroEstado = 9; //id naturezaOperacao
    private naturezaOperacaoForaEstado = 10; //id naturezaOperacao
    private tipoUsuario = 11; //id do usuario
    private configuracaoRotulo = 12; //id configuracaoRotulo
    private decimal = 13; //id configuracaoRotulo
    private tipoProduto = 14; //id tabela produtos
    private tipoPercentual = 15; //Percentual

    private async load() {
        try {
            this.configs = await this.service
                .list(this.franquiaId)
                .withLoading()
                .resolveWithJSON<ConfiguracaoFranquiaModel[]>();

            if (this.configs.length) {
                this.franquiaId = this.configs[0].franquiaId;

                this.configs
                    .filter(p => p.tipoCampo == TipoCampoConfiguracao.Boolean)
                    .forEach(item => {
                        item.verdadeiroNum = item.verdadeiro ? 1 : 0;
                    });

                this.configs
                    .filter(p => p.tipoCampo == TipoCampoConfiguracao.Percentual)
                    .forEach(item => {
                        item.valorDecimal *= 100;
                    });
            }
        } catch {}
    }

    private async loadContasCorrente() {
        try {
            const data = await new ContaCorrenteService()
                .listByFranquiaId(this.franquiaId)
                .resolveWithJSON<ContaCorrenteModel[]>();

            this.contasCorrenteOptions = data.map(item => ({
                value: item.id,
                text: item.descricao,
            }));
        } catch {}
    }

    private async loadProdutos() {
        try {
            const data = await new ProdutoService()
                .combo()
                .then(r => r.json() as Promise<PaginationModel<ProdutoModel>>);

            this.produtoOptions = data.list.map((item, index) => ({
                value: item.id,
                text: item.descricao,
            }));
        } catch {}
    }

    private async loadContasGerenciaisSaida() {
        try {
            const data = await new ContaGerencialService()
                .listByFranquiaId(this.franquiaId, TipoMovimentacao.Saida)
                .then(r => r.json() as Promise<PaginationModel<ContaGerencialModel>>);

            this.contasGerenciaisSaidaOptions = data.list.map(item => ({
                value: item.id,
                text: item.descricao,
            }));
        } catch {}
    }

    private async loadContasGerenciaisEntrada() {
        try {
            const data = await new ContaGerencialService()
                .listByFranquiaId(this.franquiaId, TipoMovimentacao.Entrada)
                .then(r => r.json() as Promise<PaginationModel<ContaGerencialModel>>);

            this.contasGerenciaisEntradaOptions = data.list.map(item => ({
                value: item.id,
                text: item.descricao,
            }));
        } catch {}
    }

    private async loadEstoques() {
        try {
            const data = await new EstoqueService()
                .listByFranquiaId(this.franquiaId)
                .then(r => r.json() as Promise<PaginationModel<EstoqueModel>>);

            this.estoquesOptions = data.list.map(item => ({
                value: item.id,
                text: item.descricao,
            }));
        } catch {}
    }

    private async loadServicosFiscais() {
        try {
            const data = await new ServicoService()
                .listByFranquiaId(this.franquiaId)
                .resolveWithJSON<PaginationModel<ServicoModel>>();

            this.servicosFiscaisOptions = data.list.map(item => ({
                value: item.id,
                text: item.descricao,
            }));
        } catch {}
    }

    private async loadNaturezaOperacao() {
        try {
            const data = await new NaturezaOperacaoService()
                .listByFranquiaId(this.franquiaId, TipoMovimentacao.Saida)
                .then(r => r.json() as Promise<PaginationModel<NaturezaOperacaoModel>>);

            this.naturezaOperacaoDentroOptions = data.list
                .filter(p => p.dentroEstado)
                .map(item => ({
                    value: item.id,
                    text: item.codigo + " - " + item.descricao,
                    codigo: item.codigo,
                    dentroEstado: item.dentroEstado,
                }));

            this.naturezaOperacaoForaOptions = data.list
                .filter(p => !p.dentroEstado)
                .map(item => ({
                    value: item.id,
                    text: item.codigo + " - " + item.descricao,
                    codigo: item.codigo,
                    dentroEstado: item.dentroEstado,
                }));
        } catch {}
    }

    private async loadUsuario() {
        try {
            const data = await new UsuarioService()
                .listByFranquiaId(this.franquiaId)
                .then(r => r.json() as Promise<PaginationModel<UsuarioModel>>);

            this.usuarioOptions = data.list.map(item => ({
                value: item.id,
                text: item.nome,
            }));
        } catch {}
    }

    private async loadFranquias() {
        try {
            const data = await new FranquiaService()
                .combo()
                .withLoading()
                .then(r => r.json() as Promise<PaginationModel<FranquiaModel>>);

            this.franquiasOptions = data.list.map(item => ({
                value: item.id,
                text: item.nomeFantasia,
            }));
        } catch {}
    }

    private async loadRotulosLote() {
        try {
            const data = await new ConfiguracaoRotuloService()
                .comboByFranquiaId(this.franquiaId)
                .then(r => r.json() as Promise<PaginationModel<ConfiguracaoRotuloModel>>);

            this.rotulosLoteOptions = data.list
                .filter(item => item.origemDados == OrigemRotulo.Lote)
                .map(item => ({
                    value: item.id,
                    text: item.nome,
                }));
        } catch {}
    }

    private async loadRotulosManipulacao() {
        try {
            const data = await new ConfiguracaoRotuloService()
                .comboByFranquiaId(this.franquiaId)
                .resolveWithJSON<PaginationModel<ConfiguracaoRotuloModel>>();

            this.rotulosManipulacaoOptions = data.list
                .filter(
                    item =>
                        item.origemDados == OrigemRotulo.Manipulacao ||
                        item.origemDados == OrigemRotulo.Acabado ||
                        item.origemDados == OrigemRotulo.Strips,
                )
                .map(item => ({
                    value: item.id,
                    text: item.nome,
                }));
        } catch {}
    }

    private async save(showSuccess = true) {
        try {
            const isValid = await this.$validator.validateAll();
            if (isValid) {
                this.configs
                    .filter(p => p.tipoCampo == TipoCampoConfiguracao.Boolean)
                    .forEach(item => {
                        item.verdadeiro = item.verdadeiroNum == 1;
                    });

                this.configs
                    .filter(p => p.tipoCampo == TipoCampoConfiguracao.Percentual)
                    .forEach(item => {
                        item.valorDecimal /= 100;
                    });

                const sucesso = await this.service
                    .updateAll(this.configs, this.franquiaId)
                    .withLoading()
                    .resolveWithoutJSON();

                if (sucesso && showSuccess) {
                    this.LOAD_CONFIGURACOES_FRANQUIA();
                    await this.$showSuccess(this.$t("__.ts.alt"), this.$t("__.ts.regisSalvosSucess"));
                    this.load();
                }
            }
        } catch {}
    }

    //@ts-ignore
    @Watch("franquiaId")
    private onFranquiaChanged(newValue, oldValue) {
        if (newValue > 0 && oldValue > 0) {
            this.loadAll();
        }
    }

    private async onBooleanChange(value: boolean, codigo: number) {
        if (!value && codigo === Configuracoes.ObrigatorioRGVendaControlados) {
            await this.$showWarning(this.$t("__.ts.atencao"), this.$t("__.ts.avisoSNGPCControlado"));
        }
    }

    private async onPercentualChange(newValue: number, codigo: number) {
        if (newValue && codigo === Configuracoes.ValorMarkupPadrao) {
            const response = await this.$showQuestion(this.$t("__.ts.atencao"), this.$t("__.ts.updateMarkupPadrao"));
            if (response) {
                await this.save(false);

                await this.produtoService.updateValorVendaProdutosMarkupPadrao().withLoading();

                await this.$showSuccess(this.$t("__.ts.sucesso"), this.$t("__.ts.updateMarkupPadraoSucesso"));

                this.LOAD_CONFIGURACOES_FRANQUIA();
                this.load();
            }
        }
    }

    private getModuloName(modulo) {
        return "M\u00f3dulo " + EnumExtensions.getNameByValue(Modulos, modulo);
    }

    private loadAll(montando = false) {
        this.contasCorrenteOptions = [];
        this.contasGerenciaisEntradaOptions = [];
        this.contasGerenciaisSaidaOptions = [];
        this.naturezaOperacaoDentroOptions = [];
        this.naturezaOperacaoForaOptions = [];
        this.estoquesOptions = [];
        this.servicosFiscaisOptions = [];
        this.rotulosLoteOptions = [];
        this.rotulosManipulacaoOptions = [];
        this.produtoOptions = [];

        Promise.all([this.loading(montando)])
            .withLoading()
            .then(() => this.load())
            .catch(() => {});
    }

    private loading(montando = false) {
        this.loadNaturezaOperacao();
        this.loadContasGerenciaisEntrada();
        this.loadContasGerenciaisSaida();
        this.loadContasCorrente();
        this.loadEstoques();
        this.loadServicosFiscais();
        this.loadUsuario();
        this.loadRotulosLote();
        this.loadRotulosManipulacao();
        this.loadProdutos();
        if (montando) {
            this.loadFranquias();
        }
    }

    private mounted() {
        this.loadAll(true);
    }
}
