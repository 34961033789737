import ProdutoEstoqueConferenciaModel from "../models/produtoEstoqueConferenciaModel";
import nfetch from "../utils/events/nfetch";

import { IService } from "./base/iService";

export default class ProdutoEstoqueConferenciaService extends IService<ProdutoEstoqueConferenciaModel> {
    constructor() {
        super("ProdutoEstoqueConferencia");
    }

    public listProdutosVerificados(model: Array<ProdutoEstoqueConferenciaModel>): Promise<Response> {
        //@ts-ignore
        return nfetch(`/${this.controllerName}/ListProdutosVerificados`, {
            method: "post",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json; charset=UTF-8",
                "XSRF-TOKEN": this.getCookie("XSRF-TOKEN"),
            },
            body: JSON.stringify(model),
        })
            .then((response: Response) => {
                if (response.ok) {
                    return response;
                } else {
                    return this.mensagemErro(response, "error");
                }
            })
            .catch(() => {});
    }

    public listProdutosMovimentosVerificados(
        movimentacaoEstoqueId: number,
        manipulacaoOrdemId: number,
    ): Promise<Response> {
        //@ts-ignore
        return nfetch(
            `/${this.controllerName}/ListProdutosMovimentosVerificados?movimentacaoEstoqueId=${movimentacaoEstoqueId}&manipulacaoOrdemId=${manipulacaoOrdemId}`,
            {
                method: "post",
                credentials: "same-origin",
                headers: {
                    "Content-Type": "application/json; charset=UTF-8",
                    "XSRF-TOKEN": this.getCookie("XSRF-TOKEN"),
                },
            },
        )
            .then((response: Response) => {
                if (response.ok) {
                    return response;
                } else {
                    return this.mensagemErro(response, "error");
                }
            })
            .catch(() => {});
    }

    public AtualizarDadosLote(
        produtos: Array<ProdutoEstoqueConferenciaModel>,
        movimentacaoEstoqueId: number,
        manipulacaoOrdemItemIds?: number[],
    ): Promise<Response> {
        //@ts-ignore
        return nfetch(`/${this.controllerName}/AtualizarDadosLote?movimentacaoEstoqueId=${movimentacaoEstoqueId}`, {
            method: "post",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json; charset=UTF-8",
                "XSRF-TOKEN": this.getCookie("XSRF-TOKEN"),
            },
            body: JSON.stringify({ produtos, manipulacaoOrdemItemIds }),
        })
            .then((response: Response) => {
                if (response.ok) {
                    return response;
                } else {
                    return this.mensagemErro(response, "error");
                }
            })
            .catch(() => {});
    }
}
