var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "crud crud-edit" },
    [
      !_vm.isModal
        ? _c("actionBarComponent", {
            attrs: {
              showSave: !_vm.somenteConsulta,
              title: _vm.$t("__.Crud.ensaio.edit_vue_html.ensaioEdit"),
            },
            on: { onSave: _vm.save },
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "form",
        {
          attrs: { novalidate: "novalidate" },
          on: {
            submit: function ($event) {
              $event.preventDefault()
              _vm.save()
            },
          },
        },
        [
          _c("fieldset", [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-sm-6" }, [
                _c("div", { staticClass: "form-group" }, [
                  _c(
                    "label",
                    {
                      staticClass: "control-label",
                      attrs: {
                        for: "descricao",
                        title: _vm.$t("__.obrigatorio"),
                      },
                    },
                    [
                      _c("span", { staticStyle: { color: "red" } }, [
                        _vm._v("*"),
                      ]),
                      _vm._v(
                        "\n                            " +
                          _vm._s(
                            _vm.$t("__.Crud.ensaio.edit_vue_html.descricao")
                          ) +
                          "\n                        "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      { name: "focus", rawName: "v-focus" },
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.model.descricao,
                        expression: "model.descricao",
                      },
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required|max:256",
                        expression: "'required|max:256'",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: {
                      type: "text",
                      id: "descricao",
                      name: "descricao",
                      autocomplete: "off",
                      disabled: _vm.somenteConsulta,
                    },
                    domProps: { value: _vm.model.descricao },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.model, "descricao", $event.target.value)
                      },
                    },
                  }),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-sm-6" }, [
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c(
                      "label",
                      {
                        staticClass: "control-label",
                        attrs: { for: "farmacopeiaId" },
                      },
                      [
                        _vm._v(
                          "\n                            " +
                            _vm._s(
                              _vm.$t("__.Crud.ensaio.edit_vue_html.farmacopeia")
                            ) +
                            "\n                        "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("combo-component", {
                      attrs: {
                        id: "farmacopeiaId",
                        name: "farmacopeiaId",
                        data: _vm.farmacopeiaOptions,
                        searchable: true,
                        refresh: _vm.loadFarmacopeias,
                        disabled: _vm.somenteConsulta,
                      },
                      model: {
                        value: _vm.model.farmacopeiaPadraoId,
                        callback: function ($$v) {
                          _vm.$set(_vm.model, "farmacopeiaPadraoId", $$v)
                        },
                        expression: "model.farmacopeiaPadraoId",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-sm-6" }, [
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c(
                      "label",
                      {
                        staticClass: "control-label",
                        attrs: { for: "soludibilidade" },
                      },
                      [
                        _vm._v(
                          "\n                            " +
                            _vm._s(
                              _vm.$t(
                                "__.Crud.ensaio.edit_vue_html.soludibilidade"
                              )
                            ) +
                            "\n                        "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("checkbox-component", {
                      attrs: {
                        id: "soludibilidade",
                        name: "soludibilidade",
                        disabled: _vm.somenteConsulta,
                      },
                      model: {
                        value: _vm.model.soludibilidade,
                        callback: function ($$v) {
                          _vm.$set(_vm.model, "soludibilidade", $$v)
                        },
                        expression: "model.soludibilidade",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("fieldset", [
            !_vm.isModal
              ? _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-sm-12 newbtn" }, [
                    _c("div", { staticClass: "form-group" }, [
                      !_vm.somenteConsulta
                        ? _c(
                            "button",
                            {
                              staticClass: "btn btn-success",
                              attrs: { type: "submit" },
                            },
                            [
                              _c("i", {
                                staticClass: "fa fa-save",
                                attrs: { "aria-hidden": "true" },
                              }),
                              _vm._v(
                                "\n                            " +
                                  _vm._s(_vm.$t("__.Crud.salvar")) +
                                  "\n                        "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.somenteConsulta
                        ? _c(
                            "button",
                            {
                              staticClass: "btn btn-secondary",
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  _vm.load()
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(_vm.$t("__.Crud.limpar")) +
                                  "\n                        "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.somenteConsulta
                        ? _c(
                            "button",
                            {
                              staticClass: "btn btn-secondary",
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  _vm.cancel()
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(_vm.$t("__.Crud.voltar")) +
                                  "\n                        "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ]),
                  ]),
                ])
              : _vm._e(),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }