var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "crud crud-list" },
    [
      _c("div", { staticClass: "info-div" }, [
        _c("h1", { staticClass: "title" }, [
          _vm._v(
            _vm._s(
              _vm.$t(
                "__.Components.parent.printModel.list_vue_html.modeloImpress"
              )
            )
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "content" }, [
          _c("div", { staticClass: "info-panel" }, [
            _c("span", [_vm._v(_vm._s(_vm.total))]),
            _vm._v(" "),
            _c("span", [
              _vm._v(
                _vm._s(
                  _vm.$t(
                    "__.Components.parent.printModel.list_vue_html.modeloImpress"
                  )
                )
              ),
            ]),
            _vm._v(" "),
            _c("span", [
              _vm._v(
                _vm._s(
                  _vm.$t(
                    "__.Components.parent.printModel.list_vue_html.cadastrados"
                  )
                )
              ),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("grid-component", {
        attrs: {
          columns: _vm.gridColumns,
          data: _vm.gridData,
          "page-index": _vm.pageIndex,
          "page-size": _vm.pageSize,
          showSortIcons: false,
          total: _vm.total,
        },
        on: {
          "change-filter-key": _vm.onChangeFilterKey,
          "change-page": _vm.onChangePage,
          "change-sort": _vm.onChangeSort,
          "create-item": _vm.onCreateItem,
          "edit-item": _vm.onEditItem,
          "remove-item": _vm.onRemoveItem,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }