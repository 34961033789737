var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row ajusteCancelarVenda" }, [
    _c("div", { staticClass: "col-sm-2" }, [
      _c("span", [_vm._v(_vm._s(_vm.model.produtoDescricao))]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "col-sm-2" },
      [
        _c("combo-component", {
          attrs: {
            id: _vm.model.id + "lote",
            name: _vm.model.id + "lote",
            data: _vm.produtoLoteOptions,
            searchable: false,
            tabindex: -1,
          },
          model: {
            value: _vm.model.produtoLoteId,
            callback: function ($$v) {
              _vm.$set(_vm.model, "produtoLoteId", $$v)
            },
            expression: "model.produtoLoteId",
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "col-sm-1" }, [
      _c("span", [_vm._v(_vm._s(_vm.model.quantidade))]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "col-sm-1" },
      [
        _c("decimal-component", {
          staticClass: "form-control",
          attrs: {
            id: _vm.model.id + "quantidadeADevolver",
            name: _vm.model.id + "quantidadeADevolver",
          },
          model: {
            value: _vm.model.quantidadeADevolver,
            callback: function ($$v) {
              _vm.$set(_vm.model, "quantidadeADevolver", $$v)
            },
            expression: "model.quantidadeADevolver",
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "col-sm-1" },
      [
        _c("combo-component", {
          attrs: {
            id: _vm.model.id + "unidadeMedida",
            name: _vm.model.id + "unidadeMedida",
            data: _vm.unidadeMedidaOptions,
            tabindex: -1,
          },
          model: {
            value: _vm.model.unidadeMedidaId,
            callback: function ($$v) {
              _vm.$set(_vm.model, "unidadeMedidaId", $$v)
            },
            expression: "model.unidadeMedidaId",
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "col-sm-1" },
      [
        _c("decimal-component", {
          staticClass: "form-control",
          attrs: {
            id: _vm.model.id + "quantidadeADevolverEstoque",
            name: _vm.model.id + "quantidadeADevolverEstoque",
            disabled: "",
          },
          model: {
            value: _vm.model.quantidadeADevolverEstoque,
            callback: function ($$v) {
              _vm.$set(_vm.model, "quantidadeADevolverEstoque", $$v)
            },
            expression: "model.quantidadeADevolverEstoque",
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "col-sm-1" },
      [
        _c("combo-component", {
          attrs: {
            id: _vm.model.id + "unidadeMedidaEstoque",
            name: _vm.model.id + "unidadeMedidaEstoque",
            data: _vm.unidadeMedidaOptions,
            tabindex: -1,
            disabled: "",
          },
          model: {
            value: _vm.model.produto.unidadeMedidaEstoqueId,
            callback: function ($$v) {
              _vm.$set(_vm.model.produto, "unidadeMedidaEstoqueId", $$v)
            },
            expression: "model.produto.unidadeMedidaEstoqueId",
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "col-sm-1" }, [
      _c("span", [
        _vm._v(
          _vm._s(
            _vm._f("filterMoeda")(
              _vm.model.valorUnitario * _vm.model.quantidadeADevolver
            )
          )
        ),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-sm-1" }, [
      _c("span", [
        _vm._v(_vm._s(_vm._f("filterMoeda")(_vm.model.valorUnitario))),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-sm-1" }, [
      _c("span", [_vm._v(_vm._s(_vm._f("filterMoeda")(_vm.model.valorTotal)))]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }