import Vue from "vue";
import { mapGetters, mapMutations, mapState } from "vuex";

import actionBarComponent from "@/components/child/actionBar/actionBar.vue";
import cepFieldComponent from "@/components/child/form/cepfield.vue";
import checkboxComponent from "@/components/child/form/checkbox.vue";
import cnpjComponent from "@/components/child/form/cnpj.vue";
import comboComponent from "@/components/child/form/combo.vue";
import dateTimePickerComponent from "@/components/child/form/datetimepicker.vue";
import fieldsetComponent from "@/components/child/form/fieldset.vue";
import textareaComponent from "@/components/child/form/textarea.vue";
import { Component, Watch } from "@/decorators";
import CepModel from "@/models/cepModel";
import AreaAtuacao from "@/models/enum/areaAtuacao";
import EnumExtensions from "@/models/enum/extensions/enumExtensions";
import TipoCr from "@/models/enum/tipoCr";
import TiposFranquia from "@/models/enum/tiposFranquia";
import Ufs from "@/models/enum/ufs";
import FranquiaModel from "@/models/franquiaModel";
import PaginationModel from "@/models/paginationModel";
import UsuarioModel from "@/models/usuarioModel";
import CidadeService from "@/services/external/cidadeService";
import FranquiaService from "@/services/franquiaService";
import RedeService from "@/services/rede/redeService";
import { AppState, Getters, LoadListProps, Mutations, SessionActions } from "@/store/store";

import "../crud.scss";

@Component({
    components: {
        comboComponent,
        fieldsetComponent,
        cnpjComponent,
        cepFieldComponent,
        checkboxComponent,
        dateTimePickerComponent,
        actionBarComponent,
        textareaComponent,
    },
    computed: mapState({
        ...mapState<AppState>({
            usuarioLogado: state => state.session.usuarioLogado,
        }),
        ...mapGetters(["HAS_PERMISSAO_PERFIL"] as Getters),
    }),
    methods: mapMutations(["LOAD_LIST"] as Mutations),
})
export default class FranquiaEditComponent extends Vue {
    // State computed props
    HAS_PERMISSAO_PERFIL: (isRede: boolean, isFranqueador: boolean, isSuporte: boolean) => Promise<boolean>;
    LOAD_LIST: (props: LoadListProps) => void;

    private service = new FranquiaService();
    private cidadeService = new CidadeService();
    private redeService = new RedeService();

    model: FranquiaModel = new FranquiaModel();
    modelId = 0;
    isAdmin = false;
    permiteExcluirDados = false;
    redePossuiDotz = false;

    dadosCep: CepModel = new CepModel();
    ufOptions: Array<Object> = EnumExtensions.getNamesAndValuesOrderedByNames(Ufs);
    areaAtuacaoOptions: Array<Object> = EnumExtensions.getNamesAndValuesOrderedByNames(AreaAtuacao);
    tipoCrOptions: Array<Object> = EnumExtensions.getNamesAndValuesOrderedByNames(TipoCr);
    tipoFranquiaOptions: Array<Object> = EnumExtensions.getNamesAndValuesOrderedByNames(TiposFranquia);
    cidadesOptions: Array<Object> = [];
    cidadesEntregaOptions: Array<Object> = [];
    redeOptions: Array<Object> = [];
    matrizOptions: Array<Object> = [];

    naturezaOperacaoOptions: Array<Object> = [
        { text: "Não Informado", value: 0 },
        { text: "1 Tributação No Municipio", value: 1 },
        { text: "2 Tributação Fora Do Municipio", value: 2 },
        { text: "3 Isenção", value: 3 },
        { text: "4 Imune", value: 4 },
        { text: "5 Exigibilidade Suspensa Decisao Judicial", value: 5 },
        { text: "6 Exigibilidade Suspensa Procedimento Administrativo", value: 6 },
    ];

    private async load() {
        this.model = new FranquiaModel();
        this.model.areaAtuacao = 0;

        if (this.modelId > 0) {
            try {
                const data = await this.service.get(this.modelId).withLoading().resolveWithJSON<FranquiaModel>();

                this.model.updateFrom(data);
                await this.onLoadCidades(this.model.estadoId);

                const usuario = this["usuarioLogado"] as UsuarioModel;
                this.permiteExcluirDados =
                    (await this.HAS_PERMISSAO_PERFIL(false, false, false)) &&
                    this.model.id > 0 &&
                    this.model.id != usuario.franquiaId;
            } catch {
                this.$router.back();
            }
        }
    }

    private async save() {
        try {
            const isValid = await this.$validator.validateAll();
            if (isValid) {
                if (!this.redePossuiDotz && this.model.utilizaDotz) {
                    this.model.utilizaDotz = false;
                }

                const sucesso = await this.service[!this.model.id ? "insert" : "update"](this.model)
                    .withLoading()
                    .resolveWithoutJSON();

                if (sucesso) {
                    await this.$showInclusaoUpdate(this.model.id);
                    this.LOAD_LIST({ loadRotulos: true });
                    await this.$store.dispatch(SessionActions.RELOAD_USUARIO_LOGADO);
                    this.$router.back();
                }
            } else {
                this.$focusErrorField();
            }
        } catch {}
    }

    private cancel() {
        this.$router.back();
    }

    private async onReplicar() {
        try {
            const sucesso = await this.service.replicar(this.model.id, 1).withLoading().resolveWithoutJSON();
            if (sucesso) {
                await this.$showSuccess(this.$t("__.ts.replicaFranquia"), this.$t("__.ts.replicasBaseSalvas"));
                this.$router.back();
            }
        } catch {}
    }

    private async excluirDados() {
        try {
            const confirmar = await this.$showQuestion(this.$t("__.ts.atencao"), this.$t("__.ts.msgExcluirDados"));
            if (confirmar) {
                const sucesso = await this.service
                    .excluirDadosFranquia(this.model.id)
                    .withLoading()
                    .resolveWithoutJSON();
                if (sucesso) {
                    await this.$showExclusao();
                    this.$router.back();
                } else {
                    await this.$showError(this.$t("__.ts.erro"), this.$t("__.ts.msgFalhaExclusao"));
                }
            }
        } catch {}
    }

    private async onCepChanged(data: CepModel) {
        this.dadosCep = data;
        this.model.bairro = data.bairro;
        this.model.complemento = data.complemento;
        this.model.logradouro = data.endereco;
        this.model.estadoId = data.uf;
        await this.onLoadCidades(this.model.estadoId);
        this.model.cidadeId = data.cidadeId;
    }

    private async loadRede() {
        try {
            if (this.isAdmin) {
                const data = await this.redeService.getComboRede().then(r => r.json());
                for (let i = 0; data.length > i; i++) {
                    this.redeOptions.push({ value: data[i].id, text: data[i].rede });
                }
            }
        } catch {}
    }

    private async loadMatriz() {
        try {
            const data = await new FranquiaService().combo().resolveWithJSON<PaginationModel<FranquiaModel>>();

            this.matrizOptions = data.list.map(item => ({
                value: item.id,
                text: `${item.nomeFantasia} - [${item.cnpj}]`,
            }));
        } catch {}
    }

    private onCepInvalid() {
        this.$showInfo(this.$t("__.ts.pesqCEP"), this.$t("__.ts.pesqNaoEncResul"));
    }

    private async onLoadCidades(estadoId: number) {
        try {
            this.cidadesOptions = [];
            if (estadoId) {
                this.cidadesOptions = await this.cidadeService.get(estadoId).withLoading();
            }
        } catch {}
    }

    //@ts-ignore
    @Watch("model.redeId")
    private async onRedeIdChanged(redeId: number) {
        if (redeId > 0) {
            try {
                this.redePossuiDotz = await this.redeService
                    .getRedeUtilizaDots(redeId)
                    .then(response => response.json() as Promise<boolean>);
            } catch {}
        }
    }

    private async mounted() {
        this.isAdmin = await this.HAS_PERMISSAO_PERFIL(true, false, false);

        Promise.all([this.loadRede(), this.loadMatriz()])
            .withLoading()
            .then(() => {
                if (this.$route.params.id) {
                    this.modelId = +this.$route.params.id;

                    this.load();
                }
            });
    }
}
