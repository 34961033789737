import Vue from "vue";
import { mapMutations, mapState, mapGetters } from "vuex";

import comboComponent from "@/components/child/form/combo.vue";
import dateTimePickerComponent from "@/components/child/form/datetimepicker.vue";
import Grid from "@/components/child/grid/grid";
import gridComponent from "@/components/child/grid/grid.vue";
import { GridAction } from "@/components/child/grid/gridAction";
import { GridColors, GridColumn, GridColumnType } from "@/components/child/grid/gridColumn";
import { Component, Watch } from "@/decorators";
import PaginationPackModel from "@/models/auxiliar/paginationPackModel";
import StatusEnvioNF from "@/models/enum/statusEnvioNF";
import TipoNotaFiscal from "@/models/enum/tipoNotaFiscal";
import TipoOperacao from "@/models/enum/tipoOperacao";
import NotaFiscalSaidaModel, { NotaFiscalSaidaListParameters } from "@/models/notaFiscalSaidaModel";
import PaginationModel from "@/models/paginationModel";
import UsuarioModel from "@/models/usuarioModel";
import NotaFiscalEletronicaEmissaoService from "@/services/emissaoNota/notaFiscalEletronicaEmissaoService";
import NotaFiscalServicoService, {
    VerificaCancelamentoRetorno,
} from "@/services/emissaoNota/notaFiscalServicoEmissaoService";
import NotaFiscalSaidaService from "@/services/notaFiscalSaidaService";
import { AppState, Mutations } from "@/store/store";
import { Getters } from "@/store/store";
import { convertToDateInput, convertToDateInputString } from "@/utils/common/date";
import Delay from "@/utils/common/delay";

import shortcutComponent from "../shortcut/shortcut.vue";

import "../crud/crud.scss";

@Component({
    components: {
        gridComponent,
        dateTimePickerComponent,
        comboComponent,
        shortcutComponent,
    },
    computed: {
        ...mapState({
            loadingPack: (state: AppState) => state.paginationStatus.loading,
            paginationParams: (state: AppState) => state.paginationStatus.paginationParams,
            usuarioLogado: (state: AppState) => state.session.usuarioLogado,
        }),
        ...mapGetters(["GET_IS_FRACIONAMENTO", "GET_IS_ESTEREIS"] as Getters),
    },
    methods: mapMutations(["SET_PARAMS", "GET_PARAMS"] as Mutations),
})
export default class NotaFiscalSaidaListComponent extends Vue {
    // State computed props
    loadingPack: boolean;
    paginationParams: PaginationPackModel;
    usuarioLogado: UsuarioModel;
    SET_PARAMS: (obj: object) => void;
    GET_PARAMS: (path: string) => Promise<void>;
    GET_IS_FRACIONAMENTO: () => Promise<boolean>;
    GET_IS_ESTEREIS: () => Promise<boolean>;

    private isFracionamento = false;
    private isEstereis = false;

    private service = new NotaFiscalSaidaService();
    private notaFiscalEletronicaEmissaoService = new NotaFiscalEletronicaEmissaoService();
    private notaFiscalServicoService = new NotaFiscalServicoService();

    startFilterKey = "";
    gridFilterKey = "";
    gridSortKey = "numero";
    gridSortOrder = "desc";
    gridData: Array<NotaFiscalSaidaModel> = [];
    get gridColumns(): Array<GridColumn> {
        return [
            new GridColumn("clienteNome", "Cliente", GridColumnType.String, false, false, "", "", true),
            new GridColumn("codigoVenda", "Cód. Venda", GridColumnType.Integer),
            new GridColumn("numero", "Numero", GridColumnType.Integer, false, false, "", "", true),
            new GridColumn("serie", "Série", GridColumnType.Integer, false, false, "", "", true),
            new GridColumn("dataEmissao", "Data", GridColumnType.DateTime, false, false, "", "", true),
            new GridColumn(
                "setorDescricao",
                "Setor",
                GridColumnType.String,
                false,
                !this.isFracionamento && !this.isEstereis,
                "",
                "",
                true,
            ),
            new GridColumn("statusEnvioDescricao", "Status", GridColumnType.String, false, false, "", "", true),
            new GridColumn("tipoDescricao", "Tipo", GridColumnType.String, false, false, "", "", true),
            new GridColumn("valorTotal", "Valor", GridColumnType.Money, false, false, "", "", true),
        ];
    }

    extraActions: Array<object> = [];
    extraActionsPdf: Array<GridAction> = [];
    extraActionsPdfSimplificado: Array<GridAction> = [];
    extraActionsXml: Array<GridAction> = [];
    extraActionsConsultar: Array<GridAction> = [];
    extraActionsCancelar: Array<GridAction> = [];
    extraActionsGerarCartaCorrecao: Array<GridAction> = [];
    extraActionsCartaCorrecao: Array<GridAction> = [];

    pageIndex = 1;
    pageSize = 20;
    total = 0;

    private isHidden = false;
    private tipoFiltro = -1;
    private statusFiltro = -1;

    statusOptions: Array<Object> = [
        { text: "Pendente", value: StatusEnvioNF.Pendente },
        { text: "Confirmado", value: StatusEnvioNF.Confirmado },
        { text: "Cancelado", value: StatusEnvioNF.Cancelado },
        { text: "Reprovado", value: StatusEnvioNF.Reprovado },
        { text: "Erro", value: StatusEnvioNF.Erro },
    ];
    tipoNotaOptions: Array<Object> = [
        { text: "Ambas", value: -1 },
        { text: "Serviço", value: 0 },
        { text: "Cupom Fiscal", value: 1 },
        { text: "Nota Fiscal", value: 2 },
    ];

    lastFilter = 0;
    filtro: Array<Object> = [];

    emissaoDe: Date = null;
    emissaoAte: Date = null;

    pags = false;
    tipoDocGerar: number = null;

    private gridcomponent: Grid = null;

    private async load() {
        this.isFracionamento = await this.GET_IS_FRACIONAMENTO();
        this.isEstereis = await this.GET_IS_ESTEREIS();

        try {
            const customParameters: NotaFiscalSaidaListParameters = {
                statusEnvio: <number>this.filtro[0],
                tipoNota: this.filtro[1] ? <number>this.filtro[1] : -1,
                emissaoDe: convertToDateInputString(this.filtro[2]),
                emissaoAte: convertToDateInputString(this.filtro[3], true),
            };
            const data = await this.service
                .list(
                    this.gridFilterKey,
                    this.gridSortKey,
                    this.gridSortOrder,
                    this.pags ? 1 : this.pageIndex,
                    this.pags ? 999999 : this.pageSize,
                    customParameters,
                )
                .withLoading()
                .resolveWithJSON<PaginationModel<NotaFiscalSaidaModel>>();

            this.gridData = data.list;
            this.total = data.total;
            this.pageIndex = data.pageIndex;
            this.pageSize = data.pageSize;

            if (data.total > 0) {
                this.SET_PARAMS({
                    routePath: this.$route.path,
                    filterKey: this.gridFilterKey,
                    sortKey: this.gridSortKey,
                    sortOrder: this.gridSortOrder,
                    pageIndex: this.pageIndex,
                    pageSize: this.pageSize,
                    filtros: this.filtro,
                });
            }

            this.isHidden = false;

            this.extraActions.splice(0);
            this.extraActionsConsultar.splice(0);
            this.extraActionsCancelar.splice(0);
            this.extraActionsPdf.splice(0);
            this.extraActionsPdfSimplificado.splice(0);
            this.extraActionsXml.splice(0);
            this.extraActionsGerarCartaCorrecao.splice(0);
            this.extraActionsCartaCorrecao.splice(0);

            for (let i = 0; i < this.gridData.length; i++) {
                this.extraActionsConsultar.push(
                    new GridAction("consultar", "Consultar Nota", "fa fa-search", GridColors.BLUE),
                );

                this.extraActionsCancelar.push(
                    new GridAction(
                        "cancelar",
                        "Cancelar Nota",
                        "fa fa-exclamation-circle",
                        GridColors.LIGHTRED,
                        this.gridData[i].statusEnvioDescricao == "Confirmado",
                    ),
                );

                this.extraActionsPdf.push(
                    new GridAction(
                        "abrir-pdf",
                        "Abrir PDF",
                        "fa fa-file-pdf",
                        GridColors.RED,
                        this.gridData[i].pdfLink != null && this.gridData[i].statusEnvioDescricao == "Confirmado",
                    ),
                );

                this.extraActionsPdfSimplificado.push(
                    new GridAction(
                        "abrir-pdf-simplificado",
                        "Abrir PDF Simplificado",
                        "fa fa-file-pdf",
                        GridColors.LIGHTRED,
                        this.gridData[i].pdfSimplificadoLink && this.gridData[i].statusEnvioDescricao == "Confirmado",
                    ),
                );

                this.extraActionsXml.push(
                    new GridAction(
                        "abrir-xml",
                        "Download do XML",
                        "fa fa-file-code",
                        GridColors.LIGHTGREEN,
                        this.gridData[i].xmlLink != null && this.gridData[i].statusEnvioDescricao == "Confirmado",
                    ),
                );

                this.extraActionsGerarCartaCorrecao.push(
                    new GridAction(
                        "carta-correcao",
                        "Gerar Carta de correção",
                        "fa fa-envelope",
                        GridColors.LIGHTGREEN,
                        this.gridData[i].statusEnvioDescricao == "Confirmado" &&
                            this.gridData[i].tipo == TipoNotaFiscal.DANFe,
                    ),
                );

                this.extraActionsCartaCorrecao.push(
                    new GridAction(
                        "consulta-carta-correcao",
                        "Consultar última Carta de correção",
                        "fa fa-envelope-open",
                        GridColors.ORANGE,
                        this.gridData[i].temCartaCorrecao,
                    ),
                );
            }

            this.extraActions.push(this.extraActionsConsultar);
            this.extraActions.push(this.extraActionsPdf);
            this.extraActions.push(this.extraActionsPdfSimplificado);
            this.extraActions.push(this.extraActionsXml);
            this.extraActions.push(this.extraActionsCancelar);
            this.extraActions.push(this.extraActionsGerarCartaCorrecao);
            this.extraActions.push(this.extraActionsCartaCorrecao);

            if (this.pags) {
                if (this.tipoDocGerar == 0) {
                    setTimeout(() => {
                        this.gridcomponent.gerarXls();
                    }, 2000);
                }

                if (this.tipoDocGerar == 1) {
                    setTimeout(() => {
                        this.gridcomponent.gerarPdf();
                    }, 2000);
                }

                if (this.tipoDocGerar == 2) {
                    setTimeout(() => {
                        this.gridcomponent.gerarImpressao();
                    }, 2000);
                }

                setTimeout(() => {
                    this.pags = false;
                }, 2000);
            }
        } catch {}
    }

    private onChangeFilterKey(filterKey: string) {
        this.gridFilterKey = filterKey;
        this.load();
    }

    private onChangeSort(sortKey: string, sortOrder: string) {
        this.gridSortKey = sortKey;
        this.gridSortOrder = sortOrder;
        this.load();
    }

    private onChangePage(pageIndex: number) {
        this.pageIndex = pageIndex;
        this.load();
    }

    private onExtraAction(name: string, model: NotaFiscalSaidaModel) {
        if (name.trim() == "abrir-pdf") {
            this.notaFiscalEletronicaEmissaoService.openPdf(model.pdfLink);
        } else if (name.trim() == "abrir-pdf-simplificado") {
            this.notaFiscalEletronicaEmissaoService.openPdf(model.pdfSimplificadoLink);
        } else if (name.trim() == "abrir-xml") {
            this.notaFiscalEletronicaEmissaoService.downloadXML(model.xmlLink);
        } else if (name.trim() == "cancelar") {
            this.cancelarNota(model);
        } else if (name.trim() == "consultar") {
            this.$router.push("/notafiscalsaida-edicao/" + model.id);
        } else if (name.trim() == "carta-correcao") {
            this.cartaCorrecao(model);
        } else if (name.trim() == "consulta-carta-correcao") {
            this.notaFiscalEletronicaEmissaoService.showCartaCorrecao(model.id, TipoOperacao.Saida);
        }
    }

    private async cancelarNota(model: NotaFiscalSaidaModel) {
        if (model.tipo == TipoNotaFiscal.Servico) {
            const cancelamento = await this.notaFiscalServicoService
                .verificaCancelamento(model.id, this.usuarioLogado.franquia.cidadeId)
                .withLoading();
            if (cancelamento != VerificaCancelamentoRetorno.CANCELANOTA) {
                if (cancelamento == VerificaCancelamentoRetorno.CANCELANOTASISTEMA) {
                    this.load();
                }
                return;
            }

            const response = await this.notaFiscalServicoService.cancelamentoSelecao();
            if (response.isConfirmed) {
                try {
                    const sucesso = await this.notaFiscalServicoService
                        .cancelamento(model.numero, response.value)
                        .withLoading()
                        .resolveWithoutJSON();

                    if (sucesso) {
                        this.load();
                    }
                } catch {}
            }
        } else {
            const response = await this.notaFiscalEletronicaEmissaoService.cancelamentoSelecao();

            if (response.isConfirmed) {
                try {
                    const sucesso = await this.notaFiscalEletronicaEmissaoService
                        .cancelamento(model.chave, response.value)
                        .withLoading()
                        .resolveWithoutJSON();

                    if (sucesso) {
                        this.load();
                    }
                } catch {}
            }
        }
    }

    private async cartaCorrecao(model: NotaFiscalSaidaModel) {
        const response = await this.notaFiscalEletronicaEmissaoService.cartaCorrecaoSelecao();

        if (response.isConfirmed) {
            try {
                const sucesso = await this.notaFiscalEletronicaEmissaoService
                    .cartaCorrecao(model.chave, model.id, response.value, TipoOperacao.Saida)
                    .withLoading()
                    .resolveWithoutJSON();

                if (sucesso) {
                    this.load();
                }
            } catch {}
        }
    }

    private async loadPagination() {
        await this.GET_PARAMS(this.$route.path);
        while (this.loadingPack) await Delay(50);

        if (this.paginationParams != null) {
            this.gridFilterKey = this.paginationParams.filterKey;
            this.startFilterKey = this.gridFilterKey;
            this.gridSortKey = this.paginationParams.sortKey;
            this.gridSortOrder = this.paginationParams.sortOrder;
            this.pageIndex = this.paginationParams.pageIndex;
            this.pageSize = this.paginationParams.pageSize;
            if (this.paginationParams.filtros != null) {
                this.filtro = this.paginationParams.filtros;
                if (this.filtro.length >= 0) {
                    if (this.filtro[0] != null && <number>this.filtro[0] > -1) {
                        this.statusFiltro = <number>this.filtro[0];
                    }
                    if (this.filtro[1] != null && <number>this.filtro[1] > -1) {
                        this.tipoFiltro = <number>this.filtro[1];
                    }

                    if (this.filtro[2]) {
                        this.emissaoDe = convertToDateInput(this.filtro[2].toString());
                    }

                    if (this.filtro[3]) {
                        this.emissaoAte = convertToDateInput(this.filtro[3].toString());
                    }
                }
            }
        }
    }

    @Watch("pags")
    private onPags() {
        this.load();
    }

    private getFormValues(listaFiltro) {
        this.filtro = [];
        //status envio
        this.filtro.push(this.statusFiltro);
        ///tipo da nota
        this.filtro.push(this.tipoFiltro);
        //emissao inicial
        this.filtro.push(listaFiltro.target.elements.emissaoDe.value);
        //emissao final
        this.filtro.push(listaFiltro.target.elements.emissaoAte.value);

        this.load();
    }

    private mounted() {
        this.gridcomponent = this.$refs.gridcomponent as Grid;

        Promise.all([this.loadPagination()])
            .withLoading()
            .then(() => {
                this.load();
            })
            .catch(() => {});
    }
}
