import Vue from "vue";
import { mapGetters, mapState } from "vuex";

import jsPDF from "jspdf";
import Moment from "moment";
import XLSX from "xlsx";

import comboComponent from "@/components/child/form/combo.vue";
import { Component, Prop, Watch } from "@/decorators";
import UsuarioModel from "@/models/usuarioModel";
import { AppState, Getters, SessionActions } from "@/store/store";
import { editDate } from "@/utils/common/date";
import { formatMoney, formatNumber } from "@/utils/common/number";

import checkboxComponent from "../form/checkbox.vue";
import dateTimePickerComponent from "../form/datetimepicker.vue";
import decimalComponent from "../form/decimal.vue";
import moedaComponent from "../form/moeda.vue";
import paginationComponent from "../pagination/pagination.vue";

import { GridColumnType } from "./gridColumn";

import "jspdf-autotable";
import "./grid.scss";

type SelectedRow = {
    data: object;
    selected: boolean;
};

let filterDebounceTimeout;

@Component({
    filters: {
        capitalize: function (str) {
            return str.charAt(0).toUpperCase() + str.slice(1);
        },
    },
    components: {
        moedaComponent,
        dateTimePickerComponent,
        decimalComponent,
        checkboxComponent,
        comboComponent,
        paginationComponent,
    },
    computed: {
        ...mapState({
            usuarioLogado: (state: AppState) => state.session.usuarioLogado,
        }),
        ...mapGetters(["VALIDAR_PERMISSAO_USUARIO", "VALIDAR_PERMISSAO_SOMENTE_CONSULTA"] as Getters),
    },
})
export default class GridComponent extends Vue {
    // State computed props
    VALIDAR_PERMISSAO_USUARIO: (telaDescricao: string, acaoController: string) => boolean;
    VALIDAR_PERMISSAO_SOMENTE_CONSULTA: (telaDescricao: string) => boolean;
    usuarioLogado: UsuarioModel;

    sortKey = "";
    sortOrders: Object = {};
    gridColumnTypes = GridColumnType;
    extendedColumns: Array<boolean> = [];
    readyToShowSubs = false;
    numberOfColumns = 0;
    filterKey = "";
    dataDe = " ";
    dataAte = " ";
    dataDe2 = " ";
    dataAte2 = " ";
    status = " ";
    statusNome = " ";
    subgrupo?: number = null;
    grupo = " ";
    fornecedor = " ";
    categoria = " ";
    caixa = " ";
    refreshGrid = false;

    checkboxAll = false;
    filteredRows: SelectedRow[] = [];

    @Prop({ type: String, default: "" }) tituloPagina: string;

    @Prop({ type: Boolean, default: null }) gerarXLSNaApi: boolean;
    @Prop({ type: Boolean, default: null }) gerarPDFNaApi: boolean;

    @Prop({ type: Array, required: true, default: () => [] }) data;
    @Prop({ type: Array, required: true, default: () => [] }) columns;

    @Prop({ type: Array, default: () => [] }) footer;

    @Prop({ type: Array, default: () => [] }) subColumns;
    @Prop({ type: String, default: null }) subDataName: string;

    @Prop(Function) conditionShowSubData;
    @Prop({ type: Boolean, default: false }) extendedColStartOpen: boolean;

    @Prop(Function) highlightRow;

    @Prop({ type: Boolean, default: false }) isRelatorio: boolean;
    @Prop({ type: Boolean, default: false }) controlaPageSize: boolean;

    @Prop({ type: Array, default: () => [] }) extraActions;
    @Prop({ type: Array, default: () => [] }) extraSubActions;
    @Prop({ type: Array, default: () => [] }) classes;

    // UI
    @Prop({ type: Boolean, default: true }) showTableHead: boolean;
    @Prop({ type: Boolean, default: true }) showSearchField: boolean;
    @Prop({ type: Boolean, default: true }) showTools: boolean;
    @Prop({ type: Boolean, default: true }) showTableFoot: boolean;

    @Prop({ type: Boolean, default: true }) showAddNewButton: boolean;
    private auxShowAddNewButton = true;

    @Prop({ type: Boolean, default: true }) showActionColumn: boolean;

    @Prop({ type: Boolean, default: true }) showEditItem: boolean;
    @Prop({ type: String, default: "fa-edit" }) editItemIcon: string;
    @Prop({ type: String, default: "Alterar" }) editItemTitle: string;
    private auxShowEditItem = true;
    private auxSomenteListagem = false;
    private auxSomenteConsulta = false;

    @Prop({ type: Boolean, default: true }) showRemoveItem: boolean;
    @Prop({ type: String, default: "fa-times" }) removeItemIcon: string;

    @Prop({ type: String, default: "Excluir" }) removeItemTitle: string;
    private auxShowRemoveItem = true;

    @Prop({ type: Boolean, default: false }) showSelectCheckbox: boolean;
    @Prop({ type: Boolean, default: false }) showSubColumnsSelectCheckbox: boolean;

    @Prop({ type: Boolean, default: true }) multipleSelect: boolean;
    @Prop({ type: Boolean, default: true }) showSortIcons: boolean;

    @Prop({ type: Boolean, default: false }) showExpand: boolean;
    @Prop({ type: Boolean, default: false }) refreshData: boolean;
    @Prop({ type: Boolean, default: false }) selectAllReg: boolean;

    @Prop({ type: String, default: null }) startFilterKey: string;

    //quando nao eh multiplas seleções e for selecionado like radio, ele desmarca o marcado para marcar o novo
    @Prop({ type: Boolean, default: false }) selectLikeRadio: boolean;

    // pagination
    @Prop(Number) pageIndex: number;
    @Prop(Number) pageSize: number;
    @Prop(Number) total: number;

    //nome para identificar o grid
    @Prop({ type: String, default: "" }) gridNameId: string;

    @Prop({ type: Boolean, default: false }) selectableLine: boolean;

    @Prop({ type: Array, default: () => [] }) initialRowsChecked;

    @Prop({ type: Boolean, default: true }) showExportPrinter: boolean;
    @Prop({ type: Boolean, default: true }) showExportXls: boolean;
    @Prop({ type: Boolean, default: true }) showExportPdf: boolean;

    @Prop({ type: String, default: null }) actionName: string;
    @Prop({ type: String, default: null }) searchValue: string;

    private sortBy(key) {
        this.sortKey = key;
        this.sortOrders[key] = this.sortOrders[key] * -1;

        // Reinicia as demais colunas
        this.columns
            .filter(p => p.value != key || !this.sortOrders[key])
            .forEach(column => {
                this.sortOrders[column.value] = 1;
            });

        this.$emit("change-sort", this.sortKey, this.sortOrders[key] == -1 ? "asc" : "desc");
    }

    private edit(entry) {
        this.$emit("edit-item", entry);
    }

    private remove(entry) {
        this.$emit("remove-item", entry);
    }

    private create() {
        this.$emit("create-item");
    }

    private getConditionShowSubData(it) {
        if (this.conditionShowSubData == null) {
            return true;
        } else {
            return this.conditionShowSubData(it);
        }
    }

    private getHighlightRow(_, index, subItemIndex) {
        if (this.highlightRow) {
            return this.highlightRow(_, index, subItemIndex);
        }
        return "";
    }

    private onGerarPdfClick() {
        if (this.$listeners && this.$listeners["gerar-pdf"]) {
            this.$emit("gerar-pdf");
        } else {
            this.gerarPdf();
        }
    }

    private onGerarXlsClick() {
        if (this.$listeners && this.$listeners["gerar-xls"]) {
            this.$emit("gerar-xls");
        } else {
            this.gerarXls();
        }
    }

    private onGerarImpressaoClick() {
        if (this.$listeners && this.$listeners["gerar-impressao"]) {
            this.$emit("gerar-impressao");
        } else {
            this.gerarImpressao();
        }
    }

    //Click do botão de gerar PDF
    public async gerarPdf() {
        if (this.gerarPDFNaApi) {
            return this.$emit("gerar-PDF-Api");
        }

        //declaração do JSPDF, Aqui, l significa Paisagem formato. Para retrato , p pode ser utilizado.
        let doc = new jsPDF("l", "pt");
        if (this.isRelatorio) {
            doc = new jsPDF("l", "pt");
        }
        const totalPagesExp = "{total_pages_count_string}";

        doc.setTextColor(59, 160, 177);
        doc.setFontSize(10);

        if (this.isRelatorio) {
            doc.text("FRANQUIA: " + this.usuarioLogado.franquia.razaoSocial, 40, 55);
            doc.text("CNPJ: " + this.usuarioLogado.franquia.cnpj, 800, 55, "right");
            doc.setTextColor(59, 160, 177);
            doc.setFontSize(10);
            doc.text("USUÁRIO: " + this.usuarioLogado.nome, 40, 68);
            const dataAtual = new Date();
            doc.text("DATA: " + dataAtual.toLocaleString(), 800, 68, "right");
        }

        if (this.subgrupo == 0) doc.text("SubGrupo: Ativo", 555, 55, "right");
        else if (this.subgrupo == 1) doc.text("SubGrupo: Flavorizante", 555, 55, "right");
        else if (this.subgrupo == 2) doc.text("SubGrupo: Veículos/Excipientes", 555, 55, "right");

        if (this.grupo != " " && this.grupo != "" && this.grupo != null) {
            if (this.subgrupo != null) doc.text("Grupo: " + this.grupo, 555, 70, "right");
            else doc.text("Grupo: " + this.grupo, 555, 55, "right");
        }

        if (this.status != " " && this.status != "" && this.status != null) {
            doc.setTextColor(59, 160, 177);
            doc.setFontSize(10);

            if (this.status == "0") this.statusNome = "Status: A Faturar";
            else if (this.status == "1") this.statusNome = "Status: Faturado";
            else if (this.status == "2") this.statusNome = "Status: Concluído";
            else if (this.status == "3") this.statusNome = "Status: Cancelado";

            if (this.dataAte != " " && this.dataAte != "" && this.dataAte != null && this.status != "4")
                doc.text(this.statusNome, 480, 55, "right");
            else doc.text(this.statusNome, 555, 55, "right");
        }

        if (this.status == "4") {
            this.statusNome = "Status: Vencidos";
            doc.text(this.statusNome, 480, 70, "right");
        }

        if (this.caixa != " " && this.caixa != "" && this.caixa != null)
            doc.text("Conta: " + this.caixa, 480, 55, "right");

        if (this.categoria != " " && this.categoria != "" && this.categoria != null) {
            doc.text("Categoria: " + this.categoria, 350, 55, "right");
        }

        if (this.dataDe != " " && this.dataDe != "" && this.dataDe != null) {
            doc.setTextColor(59, 160, 177);
            doc.setFontSize(10);
            doc.text("De: " + this.dataDe, 555, 55, "right");
        }

        if (this.dataAte != " " && this.dataAte != "" && this.dataAte != null) {
            doc.setTextColor(59, 160, 177);
            doc.setFontSize(10);
            doc.text("Até: " + this.dataAte, 555, 70, "right");
        }

        if (this.dataDe2 != " " && this.dataDe2 != "" && this.dataDe2 != null) {
            doc.setTextColor(59, 160, 177);
            doc.setFontSize(10);
            doc.text("Pagamento De: " + this.dataDe2, 480, 55, "right");
        }

        if (this.dataAte2 != " " && this.dataAte2 != "" && this.dataAte2 != null) {
            doc.setTextColor(59, 160, 177);
            doc.setFontSize(10);
            doc.text("Até: " + this.dataAte2, 480, 70, "right");
        }

        if (this.fornecedor != " " && this.fornecedor != "" && this.fornecedor != null)
            doc.text("Fornecedor: " + this.fornecedor, 350, 70, "right");

        const pageContent = data => {
            if (this.isRelatorio) {
                doc.setLineWidth(1);
                doc.setDrawColor(59, 160, 177);
                doc.line(40, 40, 800, 40);
            } else {
                doc.setLineWidth(1);
                doc.setDrawColor(59, 160, 177);
                doc.line(40, 40, 555, 40);
            }

            //header
            doc.setFontSize(16);
            doc.setTextColor(59, 160, 177);
            doc.setFontStyle("normal");

            doc.text(this.getTituloPaginaImpressao(), data.settings.margin.left, 30);

            //footer
            let str = data.pageCount;
            //total de paginas
            if (typeof doc.putTotalPages === "function") {
                str = str + "/" + totalPagesExp;
            }

            doc.setTextColor(40);
            doc.setFontSize(10);
            doc.text(str, data.settings.margin.right, doc.internal.pageSize.height - 10);
            if (!this.isRelatorio) {
                doc.text(
                    Moment(Date.parse(new Date().toString())).format("DD/MM/YYYY"),
                    500,
                    doc.internal.pageSize.height - 10,
                );
            }
        };
        //Nome do arquivo para salvar
        const fileName = location.pathname + ".pdf";
        //Criar copia do tabela (formato html)
        const elementoTableHtml = this.tableSemOptionsColsHtml("table" + this.gridNameId);
        //Função para separar os elementos de tabela em formato html para colunas e registros
        const datas = doc.autoTableHtmlToJson(elementoTableHtml);

        doc.autoTable(datas.columns, datas.rows, {
            margin: { top: 78 },
            pageBreak: "always",
            createdHeaderCell: function (cell) {
                cell.styles.lineWidth = 0.5;
                cell.styles.overflow = "linebreak";
                cell.styles.lineColor = [59, 160, 177];
                cell.styles.textColor = [255, 255, 255];
                cell.styles.fillColor = [59, 160, 177];
                cell.styles.cellPadding = [8, 0, 8, 2];
                cell.styles.halign = "center";
                cell.styles.valign = "middle";
            },
            createdCell: function (cell) {
                cell.styles.lineWidth = 0.5;
                cell.styles.overflow = "linebreak";
                cell.styles.lineColor = [59, 160, 177];
                cell.styles.fillColor = [255, 255, 255];
                cell.styles.cellPadding = [8, 0, 8, 6];
                cell.styles.halign = "center";
                cell.styles.valign = "middle";
            },
            addPageContent: pageContent,
        });

        if (typeof doc.putTotalPages === "function") {
            doc.putTotalPages(totalPagesExp);
        }

        if (this.isRelatorio && this.footer.length > 0) {
            let size = this.footer.length + 3;
            for (const foot of this.footer) {
                size -= 1;
                doc.setTextColor(40);
                doc.setFontSize(12);
                doc.setFontStyle("bold");
                doc.text(
                    `Total ${foot.descricao}: ${foot.valor}`,
                    doc.autoTable.previous.pageStartX,
                    doc.internal.pageSize.height - 12 * size,
                );
            }
        }

        //Salva o arquivo com nome previamente declarado
        doc.save(fileName);
    }

    public gerarXls() {
        if (this.gerarXLSNaApi) {
            this.$emit("gerar-XLS-Api");
        } else {
            const table = this.tableSemOptionsColsHtml("table" + this.gridNameId);
            const workbook = XLSX.utils.table_to_book(table, { raw: true });

            const sheet = workbook.Sheets[workbook.SheetNames[0]];

            for (const cell in sheet) {
                if (cell[0] === "!") {
                    continue;
                }

                const cellValue = sheet[cell].v;
                if (typeof cellValue === "string" && cellValue.trim().startsWith("R$")) {
                    const numericValue = parseFloat(
                        cellValue.replace("R$", "").replace(/\./g, "").replace(",", ".").trim(),
                    );
                    if (!isNaN(numericValue)) {
                        sheet[cell].v = numericValue;
                        sheet[cell].t = "n";
                    }
                } else if (
                    !isNaN(parseFloat(cellValue)) &&
                    /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/.test(cellValue)
                ) {
                    sheet[cell].v = parseFloat(cellValue);
                    sheet[cell].t = "n";
                }
            }

            // Triggers download
            XLSX.writeFile(workbook, this.getTituloPaginaImpressao() + ".xlsx");
        }
    }

    public gerarImpressao() {
        //busca nome da pasta onde esta posicionado
        const titleName = location.pathname;
        //instancia nova pagina
        const win = window.open("", "Print", "width=1200,height=600");
        //copia a tabela mostrada na pagina
        const novaHtml = this.tableSemOptionsColsHtml("table" + this.gridNameId);

        let docHtml = "";
        if (novaHtml.hasChildNodes()) {
            docHtml = novaHtml.childNodes[0].parentElement.outerHTML;
        }

        if (this.isRelatorio) {
            const dataAtual = new Date();
            const header = `<h2>${this.getTituloPaginaImpressao()}</h2>
                <div class="row">
                    <div class="col">
                        <div class="cabecalho left">FRANQUIA: ${this.usuarioLogado.franquia.razaoSocial}</div>
                    </div>
                    <div class="col">
                        <div class="cabecalho right">CNPJ: ${this.usuarioLogado.franquia.cnpj} </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <div class="cabecalho left">USUÁRIO: ${this.usuarioLogado.nome}</div>
                    </div>
                    <div class="col">
                        <div class="cabecalho right">DATA: ${dataAtual.toLocaleString()} </div>
                    </div>
                </div>`;

            const titulo = "<h2>" + this.getTituloPaginaImpressao() + "</h2>";
            docHtml = docHtml.replace(titulo, header);
        }
        //busca style padrao
        const style = this.styleToPrint();

        let footer = "";
        if (this.isRelatorio && this.footer.length > 0) {
            for (const foot of this.footer) {
                footer += `<div style="font-weight: bold;">Total ${foot.descricao}: ${foot.valor}</div>`;
            }
        }

        //cria base para nova pagina
        win.document.write(`<html><head> 
        ${style}
        <title>${titleName}</title></head>
        ${docHtml}
        ${
            footer.length > 0
                ? `<div style="margin-top: 8px; font-family: 'Roboto'; line-height: 20px;">${footer}</div>`
                : ""
        }
        </body></html>`);
        //inicia impressora
        win.print();
        //fecha pagina aberta para impressao
        win.document.close();
    }

    //função que retorna string para style padrão de impressão
    //Mais tarde pode ser evoluida para aceitar parametros de cores e etc..
    private styleToPrint() {
        const style = `<style>
            table {
                font-family: 'Roboto';
                border-collapse: collapse;
                width: 100%;
            }

            tr:nth-child(even){
                background-color: #d5f0f4;
            }

            .ajusteTituloPrint {
                color: #3ba0b1;
                text-align: right;
            }
            .ajusteTituloPrint p {
                margin: 2px 0 4px;
            }
            .row {
                display: flex;
            }
            .col {
                width: 50%;
            }
            .left{
                text-align: left;
            }
            .right{
                text-align: right;
            }
            .cabecalho{
                padding: 0px 0px 15px 0px;
                font-family: Roboto;
                color: #3ba0b1;
                font-weight: 400;
            }
            .tableH2 H2{
                display: block!important;
                font-weight: 400;
                text-align: left;
                font-family: Roboto;
                color: #3ba0b1;
                margin: 0;
                padding: 0 0 7px 0;
                border-bottom: 1px solid #3ba0b1;
                margin-bottom: 20px;
            }
            td{
                border: 1px solid #3ba0b1;
                text-align: center;
                padding: 8px;
                background: white;
            }

            th{
                border: 1px solid #3ba0b1;
                color: #3ba0b1;
                font-weight: 500;
                text-align: center;
                padding: 8px;
            }

            tbody:nth-of-type(odd){
                background-color: #f9f9f9;
            }

        </style>`;

        return style;
    }

    //função que busca elemento por id e remove a coluna de opções
    private tableSemOptionsColsHtml(tableElementId) {
        const elementoTableHtml = document.getElementById(tableElementId).cloneNode(true);

        //Variavel declarada abaixo para buscar os ChildNodes do elemento
        //isso foi necessario porque por o elemento ser uma copia e nao afetar na visualizaçao do usuario final
        //a função getElementsByClassName deixa de estar disponivel diretamente
        const elemChilds = elementoTableHtml.childNodes;
        //o Loop abaixo lê os arrays dos elementos remove os elementos por busca do nome de classe 'option-column'
        for (let i = 0; i < elemChilds.length; i++) {
            const removeElemento = elemChilds[i].parentElement.getElementsByClassName("option-column");
            for (let j = 0; j < removeElemento.length; j++) {
                removeElemento[j].remove();
            }
        }

        return elementoTableHtml;
    }

    private selectRow(entry: object) {
        if (this.selectableLine) {
            this.$emit("selected-line", entry);
            return;
        }

        if (!this.showSelectCheckbox && !this.showSubColumnsSelectCheckbox) {
            return;
        }

        if (!this.selectLikeRadio) {
            const row = this.filteredRows.filter(p => p.data == entry);
            if (row.length > 0) {
                row[0].selected = !row[0].selected;
            }
        } else {
            this.filteredRows.forEach(p => {
                p.selected = false;
            });

            const row = this.filteredRows.filter(p => p.data == entry);
            row[0].selected = true;
        }
    }

    @Watch("searchValue")
    private onChangeSearchValue() {
        if (this.searchValue != null) {
            this.filterKey = this.searchValue;
        }
    }

    @Watch("checkboxAll")
    private onChangeCheckboxAll() {
        if (!this.multipleSelect) {
            return;
        }

        this.filteredRows.forEach(p => {
            p.selected = this.checkboxAll;
        });
    }

    @Watch("filteredRows", { deep: true })
    private onChangeSelectedRows() {
        this.$emit(
            "selected-values-changed",
            this.filteredRows.filter(p => p.selected).map(p => p.data),
        );

        if (this.filteredRows.length > 0 && this.filteredRows.filter(p => p.selected).length == this.data.length) {
            this.checkboxAll = true;
        }
    }

    private extraAction(name, entry, index) {
        this.$emit("extra-action", name, entry, index);
    }

    private extraSubAction(name, entry, index) {
        this.$emit("extra-sub-action", name, entry, index);
    }

    private onChangePage(page) {
        this.$emit("change-page", page);
    }

    private onChangePageSize(pageSize) {
        if (pageSize != this.pageSize) {
            this.$emit("change-page-size", pageSize);
        }
    }

    private actionValidations() {
        if (this.actionName) {
            if (this.showEditItem && !this.VALIDAR_PERMISSAO_USUARIO(this.actionName, "update")) {
                this.auxShowEditItem = false;
                this.auxSomenteConsulta = false;

                if (this.VALIDAR_PERMISSAO_SOMENTE_CONSULTA(this.actionName)) {
                    this.auxSomenteConsulta = true;
                    this.auxSomenteListagem = !this.VALIDAR_PERMISSAO_USUARIO(this.actionName, "get");
                }
            }
            if (this.showRemoveItem && !this.VALIDAR_PERMISSAO_USUARIO(this.actionName, "delete")) {
                this.auxShowRemoveItem = false;
            }
            if (this.showAddNewButton && !this.VALIDAR_PERMISSAO_USUARIO(this.actionName, "insert")) {
                this.auxShowAddNewButton = false;
            }
        }
    }

    private onFilterKeyUp(filterKey: string): void {
        clearTimeout(filterDebounceTimeout);

        filterDebounceTimeout = setTimeout(() => {
            this.$emit("change-filter-key", filterKey);
        }, 800);
    }

    private expandSubColumns(rowIndex) {
        Vue.set(this.extendedColumns, rowIndex, !this.extendedColumns[rowIndex]);
    }

    onchangemoney(newValue, index, collumName) {
        this.$emit("change-row-value", newValue, index, collumName);
    }

    private refresh() {
        if (
            this.showExpand &&
            this.subColumns &&
            this.subColumns.length > 0 &&
            this.subDataName != "" &&
            this.extendedColumns.length != this.data.length
        ) {
            for (let i = 0; i < this.data.length; i++) {
                this.extendedColumns.push(this.extendedColStartOpen ? true : false);
                this.readyToShowSubs = true;
            }
        }
        this.numberOfColumns = this.columns.length;
        if (this.showActionColumn) {
            this.numberOfColumns += 1;
        }
        if (this.showSelectCheckbox || this.showSubColumnsSelectCheckbox) {
            this.numberOfColumns += 1;
        }

        if (this.selectAllReg) {
            this.checkboxAll = true;
        }
    }

    private containValue(value, type) {
        if (value == undefined || value == null) return false;

        const valorStr = (<string>value).toString().toLowerCase();

        if (this.filterKey == "" || valorStr == "" || valorStr == null) return false;

        if (GridColumnType.DateTime == type || GridColumnType.Date == type) {
            const dt = editDate(value);
            const dataStr = <string>dt;
            return dataStr.search(this.filterKey) >= 0;
        }

        return valorStr.search(this.filterKey.toLowerCase()) >= 0;
    }

    @Watch("refreshData")
    private onRefreshDataChanged() {
        this.refreshGrid = this.refreshData;
    }

    @Watch("refreshGrid")
    private onRefreshGridChanged() {
        if (this.refreshGrid) {
            this.$forceUpdate();
            this.refresh();
        }

        this.refreshGrid = false;

        if (this.selectAllReg) {
            this.checkboxAll = true;
        }
    }

    @Watch("startFilterKey")
    private onStartFilterKeyChanged() {
        if (this.startFilterKey != null && this.startFilterKey != "" && this.startFilterKey != this.filterKey) {
            this.filterKey = this.startFilterKey;
            const input = this.$el.querySelector("input[type='text']") as HTMLInputElement;
            input.value = this.startFilterKey;
            input.focus();
        }
    }

    @Watch("data", { deep: true })
    private onDataChanged() {
        if (!this.columns.some(p => p.editable) || this.data.length === 0) {
            this.filteredRows = [];
        }
        this.loadSelectedRows();
        this.refresh();
    }

    @Watch("initialRowsChecked")
    private onChangeInitialRowsChecked() {
        if (this.initialRowsChecked.length > 0) {
            this.initialRowsChecked.forEach(p => this.selectRow(p));
        }
    }

    private loadSelectedRows() {
        if (this.showSelectCheckbox) {
            this.data.forEach(p => {
                if (!this.filteredRows.some(p => p.data == p)) {
                    this.filteredRows.push({
                        data: p,
                        selected: this.selectAllReg,
                    });
                }
            });
        }

        if (this.showSubColumnsSelectCheckbox) {
            this.data.forEach(p => {
                p[this.subDataName].forEach(i => {
                    if (!this.filteredRows.some(f => f.data == i)) {
                        this.filteredRows.push({
                            data: i,
                            selected: this.selectAllReg,
                        });
                    }
                });
            });
        }
    }

    private getLineActive(entry: object) {
        const row = this.filteredRows.filter(p => p.data == entry);
        if (row.length > 0) {
            return row[0].selected;
        }
        return false;
    }

    private getCheckboxClass(entry: object) {
        const row = this.filteredRows.filter(p => p.data == entry);
        if (row.length > 0) {
            return row[0].selected ? "custom-checkbox-checked" : "custom-checkbox";
        }
        return "custom-checkbox";
    }

    @Watch("showEditItem")
    private onChangeShowEditItem() {
        this.auxShowEditItem = this.showEditItem;

        this.actionValidations();
    }

    @Watch("showRemoveItem")
    private onChangeShowRemoveItem() {
        this.auxShowRemoveItem = this.showRemoveItem;

        this.actionValidations();
    }

    @Watch("showAddNewButton")
    private onChangeShowAddNewButton() {
        this.auxShowAddNewButton = this.showAddNewButton;

        this.actionValidations();
    }

    private beforeMount() {
        const sortOrders = {};
        const columns = this.columns;
        columns.forEach(function (column) {
            if (columns[0].value == column.value) {
                sortOrders[column.value] = -1;
            } else {
                sortOrders[column.value] = 1;
            }
        });

        this.sortKey = columns[0].value;
        this.sortOrders = sortOrders;

        if (this.showExpand && this.subColumns && this.subColumns.length > 0 && this.subDataName != "") {
            for (let i = 0; i < this.data.length; i++) {
                this.extendedColumns.push(this.extendedColStartOpen ? true : false);
                this.readyToShowSubs = true;
            }
        }

        this.numberOfColumns = this.columns.length;
        if (this.showActionColumn) this.numberOfColumns += 1;

        if (this.data != null && (this.showSelectCheckbox || this.showSubColumnsSelectCheckbox)) {
            this.loadSelectedRows();

            if (this.initialRowsChecked.length > 0) {
                this.initialRowsChecked.forEach(p => this.selectRow(p));
            }
        }
    }

    private getComboDefaultValue(column, data, rowIndex) {
        if (column.defaultValueProp) {
            return column.comboOptions(data[rowIndex])?.find(p => p.value == data[rowIndex][column.defaultValueProp])
                ?.value;
        }

        return column.comboOptions(data[rowIndex])[0].value;
    }

    private getTituloPaginaImpressao() {
        if (this.tituloPagina) {
            return this.tituloPagina;
        }
        return location.pathname;
    }

    private formatData(value: Object, gridColumnType: GridColumnType, minimumFractionDigits: number) {
        if (value == undefined || value == null) {
            return "";
        }

        const fractionDigits = minimumFractionDigits ? minimumFractionDigits : 2;

        switch (gridColumnType) {
            case GridColumnType.String:
            case GridColumnType.Raw:
            default:
                value = value.toString();
                break;
            case GridColumnType.Boolean:
                value = value == true ? "Sim" : "N\u00e3o";
                break;
            case GridColumnType.Integer:
                value = +value;
                break;
            case GridColumnType.Decimal:
                value = formatNumber(Number(value.toString().replace("/[d,]/", "")), fractionDigits);
                break;
            case GridColumnType.DecimalPonto:
                value = value.toString().replace("/[d,]/", "");
                break;
            case GridColumnType.Money:
                value = value != null ? formatMoney(+value, fractionDigits) : "";
                break;
            case GridColumnType.MoneyWithSignal:
                if (Number(value) > 0) {
                    value = "+ " + formatMoney(+value, fractionDigits);
                } else if (Number(value) < 0) {
                    value = "- " + formatMoney(+value * -1, fractionDigits);
                } else {
                    value = formatMoney(+value, fractionDigits);
                }
                break;
            case GridColumnType.Period:
                value = Moment(Date.parse(value.toString())).format("MM/YYYY");
                break;
            case GridColumnType.Date:
                value = Moment(Date.parse(value.toString())).format("DD/MM/YYYY");
                break;
            case GridColumnType.DateTime:
                value = Moment(Date.parse(value.toString())).format("DD/MM/YYYY HH:mm:ss");
                break;
            case GridColumnType.Percent:
                value = formatNumber(+value, fractionDigits) + "%";
                break;
            case GridColumnType.Money4Digits:
                value = formatMoney(+value, 4);
                break;
            case GridColumnType.Combo:
                break;
            case GridColumnType.IntegerPonto:
                value = formatNumber(+value, 0);
                break;
        }

        return value;
    }

    private async mounted() {
        this.auxShowEditItem = this.showEditItem;
        this.auxShowRemoveItem = this.showRemoveItem;
        this.auxShowAddNewButton = this.showAddNewButton;

        await this.$store.dispatch(SessionActions.LOAD_USUARIO_LOGADO);

        if (this.actionName) {
            this.actionValidations();
        }
    }
}
