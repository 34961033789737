import Vue from "vue";

import Grid from "@/components/child/grid/grid";
import gridComponent from "@/components/child/grid/grid.vue";
import { GridColumn, GridColumnType } from "@/components/child/grid/gridColumn";
import { Component, Watch } from "@/decorators";
import EmailModel from "@/models/emailModel";
import PaginationModel from "@/models/paginationModel";
import EmailService from "@/services/emailService";

import "../crud.scss";

@Component({
    components: {
        gridComponent,
    },
})
export default class EmailListComponent extends Vue {
    private service = new EmailService();
    private gridcomponent: Grid = null;

    gridFilterKey = "";
    gridSortKey = "emailRemetente";
    gridSortOrder = "asc";
    gridData: Array<EmailModel> = [];

    get gridColumns(): Array<GridColumn> {
        return [new GridColumn("emailRemetente", "E-mail", GridColumnType.String)];
    }

    pageIndex = 1;
    pageSize = 20;
    total = 0;

    pags = false;
    tipoDocGerar: number = null;

    private async load() {
        try {
            const data = await this.service
                .list(
                    this.gridFilterKey,
                    this.gridSortKey,
                    this.gridSortOrder,
                    this.pags ? 1 : this.pageIndex,
                    this.pags ? 999999 : this.pageSize,
                )
                .withLoading()
                .resolveWithJSON<PaginationModel<EmailModel>>();

            this.gridData = data.list;
            this.total = data.total;

            if (this.pags) {
                if (this.tipoDocGerar == 0) {
                    setTimeout(() => {
                        this.gridcomponent.gerarXls();
                    }, 2000);
                }

                if (this.tipoDocGerar == 1) {
                    setTimeout(() => {
                        this.gridcomponent.gerarPdf();
                    }, 2000);
                }

                if (this.tipoDocGerar == 2) {
                    setTimeout(() => {
                        this.gridcomponent.gerarImpressao();
                    }, 2000);
                }

                setTimeout(() => {
                    this.pags = false;
                }, 2000);
            }
        } catch {}
    }

    private onEditItem(model: EmailModel) {
        this.$router.push("/email-edicao/" + model.id);
    }

    private async onRemoveItem(model: EmailModel) {
        const response = await this.$showExclusaoQuestion(`${this.$t("__.ts.desejaExcl")}?`);

        if (response) {
            try {
                const sucesso = await this.service.delete(model.id).withLoading().resolveWithoutJSON();
                if (sucesso) {
                    this.load();
                    this.$showExclusao();
                }
            } catch {}
        }
    }

    private onCreateItem() {
        this.$router.push("/email-edicao");
    }

    private onChangeFilterKey(filterKey: string) {
        this.gridFilterKey = filterKey;
        this.load();
    }

    private onChangeSort(sortKey: string, sortOrder: string) {
        this.gridSortKey = sortKey;
        this.gridSortOrder = sortOrder;
        this.load();
    }

    private onChangePage(pageIndex: number) {
        this.pageIndex = pageIndex;
        this.load();
    }

    @Watch("pags")
    private onPags() {
        this.load();
    }

    private mounted() {
        this.gridcomponent = this.$refs.gridcomponent as Grid;
        this.load();
    }
}
