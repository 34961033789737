import BaseModel from "../base/baseModel";

import CampanhaFaixaModel from "./campanhaFaixaModel";
import CampanhaGrupoProduto from "./campanhaGrupoProdutoModel";
import CampanhaRecompensaModel from "./campanhaRecompensaModel";

export default class CampanhaModel extends BaseModel {
    id?: number = null;
    descricao: string = null;
    dataInicio: Date = null;
    dataFim: Date = null;
    franquiaId?: number = null;
    redeId = 0;
    brinde = false;
    multiplicador = 0;
    padrao = false;
    faixas: Array<CampanhaFaixaModel> = [];
    recompensas: Array<CampanhaRecompensaModel> = [];
    grupos: Array<CampanhaGrupoProduto> = [];

    private organizarFaixas(faixas: CampanhaFaixaModel[]): number {
        if (faixas.length > 0) {
            const inicioProxFaixa = this.organizarFaixas(faixas.slice(1));
            faixas[0].valorFinal = inicioProxFaixa ? inicioProxFaixa - 0.01 : inicioProxFaixa;
            return faixas[0].valorInicial;
        } else {
            return null;
        }
    }

    private ordemCrescente = (a, b) => a.inicioFaixa - b.inicioFaixa;

    adicionarFaixa(faixa: CampanhaFaixaModel) {
        const novaFaixa = new CampanhaFaixaModel();
        novaFaixa.updateFrom(faixa);
        this.faixas.push(novaFaixa);
        this.faixas.sort(this.ordemCrescente);
        this.organizarFaixas(this.faixas);
    }

    removerFaixa(faixa: any) {
        const index = this.faixas.indexOf(faixa);
        this.faixas.splice(index, 1);
        if (this.faixas.length > 0) {
            this.faixas.sort(this.ordemCrescente);
            this.organizarFaixas(this.faixas);
        }
    }
}
