import TipoEtapa from "@/models/enum/tipoEtapa";
import EtapaPCPModel from "@/models/pcp/etapaPCPModel";
import EtapaPCPService from "@/services/etapaPCPService";

const etapaPCPService = new EtapaPCPService();

export async function gerarModeloEtapaPCPDestino(
    model: any,
    modelEtapaPCP: any,
    etapaDestinoId: number,
    observacao: string,
    motivoEtapaPCPId: string,
) {
    const etapa = await etapaPCPService.get(etapaDestinoId).resolveWithJSON<EtapaPCPModel>();

    if (etapa.tipo === TipoEtapa.Final) {
        model.dataFim = new Date();
        modelEtapaPCP.finalizado = true;
    }

    modelEtapaPCP.observacao = observacao;
    modelEtapaPCP.etapaPCP = null;
    modelEtapaPCP.etapaPCPId = etapaDestinoId;
    modelEtapaPCP.motivoEtapaPCPId = motivoEtapaPCPId;

    return modelEtapaPCP;
}

export function finalizarEtapa(etapas: any) {
    const etapa = etapas.find(p => !p.finalizado);
    if (etapa) {
        etapa.finalizado = true;
        etapa.dataFim = new Date();
    }
}

export function getIsMotivoObrigatorio(
    etapas: EtapaPCPModel[],
    proximaEtapaDestinoId: number,
    proximaEtapaDestinoNotObrigatorioId: number,
    etapaDestinoId: number,
) {
    const hasObrigatorioEtapas = etapas.some(etapa => etapa.obrigatorio);
    if (!hasObrigatorioEtapas) {
        return false;
    }

    if (!proximaEtapaDestinoId) {
        return false;
    }

    if (etapaDestinoId != null && etapaDestinoId == proximaEtapaDestinoId) {
        return false;
    }

    if (
        etapaDestinoId != null &&
        proximaEtapaDestinoNotObrigatorioId != null &&
        etapaDestinoId == proximaEtapaDestinoNotObrigatorioId
    ) {
        return false;
    }

    return true;
}
