import Vue from "vue";
import { mapGetters } from "vuex";

import { Component } from "@/decorators";
import { Getters } from "@/store/store";

import manipulacaoOrdemPCPComponent from "./manipulacaoOrdem/edit.vue";
import vendaPCPComponent from "./venda/edit.vue";

@Component({
    components: {
        manipulacaoOrdemPCPComponent,
        vendaPCPComponent,
    },
    computed: {
        ...mapGetters(["GET_IS_FRACIONAMENTO"] as Getters),
    },
})
export default class EtapasPCPComponent extends Vue {
    // State computed props
    GET_IS_FRACIONAMENTO: () => Promise<boolean>;

    isFracionamento = false;

    private async mounted() {
        this.isFracionamento = await this.GET_IS_FRACIONAMENTO().withLoading();
    }
}
