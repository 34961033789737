import { mapMutations } from "vuex";

import { GridColumn, GridColumnType } from "@/components/child/grid/gridColumn";
import { Component } from "@/decorators";
import LocalEntregaModel from "@/models/transporte/localEntregaModel";
import LocalEntregaService from "@/services/transporte/localEntregaService";
import { LoadListProps } from "@/store/loadListStore";
import { Mutations } from "@/store/store";

import ListComponentBase from "../listComponentBase";

@Component({
    methods: mapMutations(["LOAD_LIST"] as Mutations),
})
export default class LocalEntregaListComponent extends ListComponentBase<LocalEntregaModel> {
    // State computed props
    LOAD_LIST: (props: LoadListProps) => void;

    get gridColumns(): Array<GridColumn> {
        return [
            new GridColumn("codigo", this.$t("__.ts.codigo"), GridColumnType.String),
            new GridColumn("descricao", this.$t("__.ts.descricao"), GridColumnType.String),
            new GridColumn("taxa", this.$t("__.Crud.localentrega.list_vue_html.taxa"), GridColumnType.Money),
        ];
    }

    private beforeMount() {
        this.setProps(new LocalEntregaService(), "localentrega", "codigo");
    }

    public async onSucessoExclusao() {
        this.LOAD_LIST({ loadLocaisEntrega: true });
    }
}
