import Vue from "vue";

import { Component, Prop } from "@/decorators";
import Bus from "@/utils/events/bus";

import "./buttonSc.scss";

@Component
export default class ButtonScComponent extends Vue {
    @Prop({ type: String, required: true }) buttonName: string;
    @Prop({ type: String, default: "btn btn-primary" }) classButton: string;
    @Prop({ type: String, default: null }) classIcon: string;
    @Prop({ type: String, default: null }) shortKeyBt: string;

    private showToolTip = false;
    private activeButton = false;

    private onClick() {
        this.$emit("click");
    }

    private onScClick() {
        if (!this.activeButton) {
            this.activeButton = true;
            setTimeout(() => {
                this.activeButton = false;
            }, 1500);
        }
        this.onClick();
    }

    private mounted() {
        Bus.$on("showShortKeyToolTip", show => {
            this.showToolTip = show;
        });
    }
}
