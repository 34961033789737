import BaseModel from "./base/baseModel";
import GenerosPessoa from "./enum/generosPessoa";
import Ufs from "./enum/ufs";

export default class ColaboradorModel extends BaseModel {
    constructor() {
        super();
    }

    id?: number = null;
    nome: string = null;
    observacao: string = null;
    cpf: string = null;
    rg: string = null;
    orgaoExpedidor: string = null;
    ufExpedidor?: Ufs = null;
    dataNascimento?: Date = null;
    genero?: GenerosPessoa = null;
    cep: string = null;
    estadoId?: Ufs = null;
    cidadeId?: number = null;
    cidade: string = null;
    endereco: string = null;
    bairro: string = null;
    numero: string = null;
    complemento: string = null;
    telefone: string = null;
    celular: string = null;
    email: string = null;
    beneficioId?: number = null;
    franquiaId?: number = null;
    vendedorDotz?: boolean = false;
}
