import ManipulacaoOrdemItemModel from "../models/manipulacaoOrdemItemModel";
import ManipulacaoOrdemModel from "../models/manipulacaoOrdemModel";
import nfetch from "../utils/events/nfetch";

import { IService } from "./base/iService";

export default class ManipulacaoOrdemItemService extends IService<ManipulacaoOrdemItemModel> {
    constructor() {
        super("ManipulacaoOrdemItem");
    }

    public loadDadosProdutoLote(
        item: ManipulacaoOrdemItemModel,
        manipulacao: ManipulacaoOrdemModel,
        usaMarkupEquinos = false,
        isTransferencia = false,
        atualizaDadosLote = false,
    ): Promise<Response> {
        //@ts-ignore
        return nfetch(
            `/${this.controllerName}/LoadDadosProdutoLote?usaMarkupEquinos=${usaMarkupEquinos}&isTransferencia=${isTransferencia}&atualizaDadosLote=${atualizaDadosLote}`,
            {
                method: "post",
                credentials: "same-origin",
                headers: {
                    "Content-Type": "application/json; charset=UTF-8",
                    "XSRF-TOKEN": this.getCookie("XSRF-TOKEN"),
                },
                body: JSON.stringify({
                    item,
                    manipulacao,
                }),
            },
        );
    }
}
