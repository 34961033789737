var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", {
    ref: "gchart",
    staticStyle: {
      margin: "0 auto",
      width: "100%",
      "min-width": "600px",
      "max-width": "100%",
      "min-height": "400px",
      height: "100%",
      "background-color": "white!important",
    },
    attrs: { slot: "content", id: "chartFormaFarm" },
    slot: "content",
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }