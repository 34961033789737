import Vue from "vue";
import { mapMutations, mapState } from "vuex";

import comboComponent from "@/components/child/form/combo.vue";
import fieldsetComponent from "@/components/child/form/fieldset.vue";
import { Component, Watch } from "@/decorators";
import EnumExtensions from "@/models/enum/extensions/enumExtensions";
import Ufs from "@/models/enum/ufs";
import NotaFiscalEletronicaEmissaoModel, { Exportacao } from "@/models/notaFiscalEletronicaEmissaoModel";
import { AppState, Mutations } from "@/store/store";

import "../crud/crud.scss";

@Component({
    components: {
        fieldsetComponent,
        comboComponent,
    },
    computed: mapState<AppState>({
        nota: state => state.nfeContext.nota,
        consulta: state => state.nfeContext.consulta,
    }),
    methods: mapMutations(["SET_NOTA"] as Mutations),
})
export default class ExportacaoComponent extends Vue {
    // State computed props
    nota: NotaFiscalEletronicaEmissaoModel;
    consulta: boolean;
    SET_NOTA: (nota: NotaFiscalEletronicaEmissaoModel) => void;

    private model = new Exportacao();
    private ufOptions: Array<Object> = EnumExtensions.getNamesAndValuesOrderedByNames(Ufs);

    // @ts-ignore
    @Watch("nota", { deep: true })
    private onChangeData() {
        this.model = this.nota.exportacao;
        if (this.model.ufSaidaPaisId) {
            this.model.ufSaidaPais = Ufs[this.model.ufSaidaPaisId];
        }
    }

    // @ts-ignore
    @Watch("model", { deep: true })
    private onChangeModel() {
        this.nota.exportacao = this.model;
        this.SET_NOTA(this.nota);
    }

    public async validate() {
        return await this.$validator.validateAll();
    }
}
