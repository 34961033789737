import Vue from "vue";

import actionBarComponent from "@/components/child/actionBar/actionBar.vue";
import comboComponent from "@/components/child/form/combo.vue";
import dateTimePickerComponent from "@/components/child/form/datetimepicker.vue";
import gridComponent from "@/components/child/grid/grid.vue";
import { GridColumn, GridColumnType } from "@/components/child/grid/gridColumn";
import etapasComponent from "@/components/parent/crud/pcp/etapas.vue";
import ShortcutComponent from "@/components/parent/shortcut/shortcut";
import shortcutComponent from "@/components/parent/shortcut/shortcut.vue";
import { Component, Watch } from "@/decorators";
import PaginationModel from "@/models/paginationModel";
import EtapaPCPModel from "@/models/pcp/etapaPCPModel";
import ManipulacaoOrdemPCPModel from "@/models/pcp/manipulacaoOrdemPCPModel";
import EtapaPCPService from "@/services/etapaPCPService";
import ManipulacaoOrdemPCPService from "@/services/pcp/manipulacaoOrdemPCPService";
import { addHours } from "@/utils/common/date";

import pcpRapidoComponent from "../../crud/pcp/pcpRapido/pcpRapido.vue";

import "../../crud/crud.scss";
import "../view.scss";

@Component({
    components: {
        actionBarComponent,
        comboComponent,
        etapasComponent,
        dateTimePickerComponent,
        gridComponent,
        pcpRapidoComponent,
        shortcutComponent,
    },
})
export default class StatusPCPComponent extends Vue {
    private etapaPCPService = new EtapaPCPService();
    private manipulacaoOrdemPCPService = new ManipulacaoOrdemPCPService();

    private shortcutComponent: ShortcutComponent = null;

    gridData: ManipulacaoOrdemPCPModel[] = [];
    gridSelectedValues: ManipulacaoOrdemPCPModel[] = [];
    etapaId = null;
    etapaDestinoId = null;
    venda: number = null;
    etapasOptions: Object[] = [];
    dataInicial = new Date();
    dataFinal = addHours(3, new Date());

    get gridColumns(): Array<GridColumn> {
        return [
            new GridColumn("descricaoEtapa", "Descrição Etapa", GridColumnType.String),
            new GridColumn("localEntrega", "Local de Entrega", GridColumnType.String),
            new GridColumn("vendaCodigo", "Venda", GridColumnType.Integer),
            new GridColumn("manipulacaoOrdemCodigo", "Manipulação", GridColumnType.Integer),
            new GridColumn("dataEntrega", "Data de Retirada", GridColumnType.DateTime),
            new GridColumn("paciente", "Paciente", GridColumnType.String),
            new GridColumn("formaFarmaceutica", "Forma", GridColumnType.String),
            new GridColumn("vendedor", "Vendedor", GridColumnType.String),
        ];
    }

    private async onAtualizar() {
        if (this.dataInicial && this.dataFinal) {
            this.gridData = await this.manipulacaoOrdemPCPService
                .getByEtapa(this.etapaId, this.venda, this.dataInicial, this.dataFinal)
                .withLoading()
                .resolveWithJSON<ManipulacaoOrdemPCPModel[]>();
        }
    }

    private async onShowEtapasPcp(open: (slot: number, title: string, size: string, fn: () => void) => {}) {
        open(0, "PCP Rápido", "ld-modal", () => {
            this.onAtualizar();
        });
    }

    public async onConfirmAddNew() {
        const ids = this.gridSelectedValues.map(p => p.id);

        if (this.etapaDestinoId) {
            const sucesso = await this.manipulacaoOrdemPCPService
                .updateMany(ids, this.etapaDestinoId)
                .withLoading()
                .resolveWithoutJSON();

            if (sucesso) {
                await this.$showSuccess(this.$t("__.ts.sucesso"), this.$t("__.ts.sucessoApontarEmLote"));
                this.onAtualizar();
                this.etapaDestinoId = 0;
                this.shortcutComponent.hide();
            }
        }
    }

    public async onSelectedValuesChanged(selected: ManipulacaoOrdemPCPModel[]) {
        this.gridSelectedValues = selected;
    }

    public async onApontarEmLote() {
        this.shortcutComponent.title = this.$t("__.ts.apontarEmLote").toString();
        this.shortcutComponent.show();
    }

    @Watch("etapaId")
    @Watch("dataInicial")
    @Watch("dataFinal")
    private async onChangeFilters() {
        await this.onAtualizar();
    }

    private onConsulta(model: ManipulacaoOrdemPCPModel) {
        const routeData = this.$router.resolve({ path: `/manipulacaoordem-edicao/${model.manipulacaoOrdemId}` });
        window.open(routeData.href, "_blank");
    }

    private async mounted() {
        this.shortcutComponent = this.$refs.shortcutComponent as ShortcutComponent;

        const data = await this.etapaPCPService.combo(true).resolveWithJSON<PaginationModel<EtapaPCPModel>>();

        this.etapasOptions = data.list.filter(e => e.ativo).map(item => ({ value: item.id, text: item.descricao }));
    }
}
