import { ErrorField, Validator } from "vee-validate";

import Boot from "@/main";
import ValidationErrorModel, { ValidationErrors } from "@/models/validationErrorModel";

export async function showErrorsAsync(errorList: ValidationErrorModel[], validator: Validator) {
    const fieldErrors = errorList.filter(
        p =>
            p.errorType != ValidationErrors.Error &&
            p.errorType != ValidationErrors.Warning &&
            p.errorType != ValidationErrors.WarningBreaking &&
            p.errorType != ValidationErrors.Unexpected,
    );

    //para usar fora do dor abaixo
    let htmlErrors = "";
    for (const error of fieldErrors) {
        const firstCharLowerCase = error.field.charAt(0).toLowerCase();
        const leftOver = error.field.slice(1);
        const fieldName = firstCharLowerCase + leftOver; // camelCase
        const errorMessage = getErrorMessage(error, validator);
        let msgComplementar = "";
        if (error.references.length > 0) {
            msgComplementar = ", " + error.references.join(",");
        }

        if (validator.fields.items[fieldName]) {
            const errorFields = {} as ErrorField;
            errorFields.id = fieldName;
            errorFields.field = fieldName;
            errorFields.msg = errorMessage;

            validator.errors.add(errorFields);
        } else {
            const htmlStr =
                "<ul><li>" +
                error.field +
                ": " +
                getErrorMessage(error, validator) +
                msgComplementar +
                ";</li></ul></br>";
            htmlErrors = htmlErrors + htmlStr;
        }
    }
    if (htmlErrors != "") {
        await Boot.$showAlert({
            title: "Ocorreram erros nos seguintes campos:",
            html: htmlErrors,
            allowOutsideClick: false,
            icon: "error",
        });
    }

    const errors = errorList.filter(p => p.errorType == ValidationErrors.Error);

    if (errors.length > 0) {
        if (errors.length == 1) {
            await Boot.$showAlert({
                title: errors[0].field,
                html: errors[0].references[0],
                icon: "error",
                allowOutsideClick: false,
            });
        } else {
            await Boot.$showAlert({
                title: "Ocorreram os seguintes erros",
                html: "<ul>" + errors.map(p => "<li>" + p.references[0] + ";</li>").join("") + "</ul>",
                allowOutsideClick: false,
                icon: "error",
            });
        }
    }

    const warnings = errorList.filter(
        p => p.errorType == ValidationErrors.Warning || p.errorType == ValidationErrors.WarningBreaking,
    );

    if (warnings.length > 0) {
        if (warnings.length == 1) {
            await Boot.$showAlert({
                title: warnings[0].field,
                html: warnings[0].references[0],
                icon: "warning",
                allowOutsideClick: false,
            });
        } else {
            await Boot.$showAlert({
                title: "Ocorreram os seguintes avisos",
                html: "<ul>" + warnings.map(p => "<li>" + p.references[0] + "</li>").join("") + "</ul>",
                allowOutsideClick: false,
                icon: "warning",
            });
        }
    }

    const unexpectedErrors = errorList.filter(p => p.errorType == ValidationErrors.Unexpected);

    if (unexpectedErrors.length > 0) {
        if (unexpectedErrors.length == 1) {
            await Boot.$showAlert({
                title: unexpectedErrors[0].field,
                html: unexpectedErrors[0].references[0],
                icon: "error",
                allowOutsideClick: false,
            });
        } else {
            await Boot.$showAlert({
                title: "Ocorreram os seguintes erros:",
                html: "<ul>" + errors.map(p => "<li>" + p.references[0] + ";</li>").join("") + "</ul>",
                allowOutsideClick: false,
                icon: "error",
            });
        }
    }
}

export function showErrors(errorList: ValidationErrorModel[], validator: Validator) {
    const fieldErrors = errorList.filter(
        p =>
            p.errorType != ValidationErrors.Error &&
            p.errorType != ValidationErrors.Warning &&
            p.errorType != ValidationErrors.WarningBreaking &&
            p.errorType != ValidationErrors.Unexpected,
    );

    //para usar fora do dor abaixo
    let htmlErrors = "";
    for (const error of fieldErrors) {
        const firstCharLowerCase = error.field.charAt(0).toLowerCase();
        const leftOver = error.field.slice(1);
        const fieldName = firstCharLowerCase + leftOver; // camelCase
        const errorMessage = getErrorMessage(error, validator);
        let msgComplementar = "";
        if (error.references.length > 0) {
            msgComplementar = ", " + error.references.join(",");
        }

        if (validator.fields.items[fieldName]) {
            const errorFields = {} as ErrorField;
            errorFields.id = fieldName;
            errorFields.field = fieldName;
            errorFields.msg = errorMessage;

            validator.errors.add(errorFields);
        } else {
            const htmlStr =
                "<ul><li>" +
                error.field +
                ": " +
                getErrorMessage(error, validator) +
                msgComplementar +
                ";</li></ul></br>";
            htmlErrors = htmlErrors + htmlStr;
        }
    }
    if (htmlErrors != "") {
        Boot.$showAlert({ title: "Ocorreram erros nos seguintes campos:", html: htmlErrors, icon: "error" });
    }

    const errors = errorList.filter(p => p.errorType == ValidationErrors.Error);

    if (errors.length > 0) {
        if (errors.length == 1) {
            Boot.$showAlert({ title: errors[0].field, html: errors[0].references[0], icon: "error" });
        } else {
            Boot.$showAlert({
                title: "Ocorreram os seguintes erros",
                html: "<ul>" + errors.map(p => "<li>" + p.references[0] + ";</li>").join("") + "</ul>",
                icon: "error",
            });
        }
    }

    const warnings = errorList.filter(
        p => p.errorType == ValidationErrors.Warning || p.errorType == ValidationErrors.WarningBreaking,
    );

    if (warnings.length > 0) {
        if (warnings.length == 1) {
            Boot.$showAlert({ title: warnings[0].field, html: warnings[0].references[0], icon: "warning" });
        } else {
            Boot.$showAlert({
                title: "Ocorreram os seguintes avisos",
                html: "<ul>" + warnings.map(p => "<li>" + p.references[0] + "</li>").join("") + "</ul>",
                icon: "warning",
            });
        }
    }

    const unexpectedErrors = errorList.filter(p => p.errorType == ValidationErrors.Unexpected);

    if (unexpectedErrors.length > 0) {
        if (unexpectedErrors.length == 1) {
            Boot.$showError(unexpectedErrors[0].field, unexpectedErrors[0].references[0]);
        } else {
            Boot.$showAlert({
                title: "Ocorreram os seguintes erros:",
                html: "<ul>" + errors.map(p => "<li>" + p.references[0] + ";</li>").join("") + "</ul>",
                icon: "error",
            });
        }
    }

    const insuficients = errorList.filter(p => p.errorType == ValidationErrors.Insuficient);

    if (insuficients.length > 0) {
        if (insuficients.length == 1) {
            Boot.$showAlert({ title: insuficients[0].field, html: insuficients[0].references[0], icon: "warning" });
        } else {
            Boot.$showAlert({
                title: "Ocorreram os seguintes alertas",
                html: "<ul>" + insuficients.map(p => "<li>" + p.references[0] + ";</li>").join("") + "</ul>",
                icon: "warning",
            });
        }
    }
}

export function getErrorMessage(error: ValidationErrorModel, validator: Validator) {
    const messages = validator.dictionary.container.pt_BR.messages;
    let errorText = "";

    switch (error.errorType) {
        case ValidationErrors.Unexpected:
            errorText = messages.unexpected() + " " + error.references[0];
            break;
        case ValidationErrors.NotExists:
            errorText = messages.not_exists(error.field.toLowerCase());
            break;
        case ValidationErrors.AlreadyExists:
            errorText = messages.already_exists(error.field.toLowerCase());
            break;
        case ValidationErrors.Required:
            errorText = messages.required(error.field.toLowerCase());
            break;
        case ValidationErrors.StringLength:
            errorText = messages.max(error.field.toLowerCase(), error.references[0]);
            break;
        case ValidationErrors.Invalid:
            errorText = messages.default(error.field.toLowerCase());
            break;
        default:
            errorText = messages.default(error.field.toLowerCase());
    }

    return errorText;
}
