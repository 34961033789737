import ContaReceberRecebimentoModel from "./contaReceberRecebimentoModel";
import LancamentoBaseModel from "./lancamentoBaseModel";

export default class ContaReceberModel extends LancamentoBaseModel {
    clienteId?: number = null;
    vendaId?: number = null;
    clienteDescricao = "";
    operadoraDescricao = "";
    tipoConta = "";
    totalValor = 0;
    totalDesconto = 0;
    totalJuros = 0;
    totalData = "";
    operadoraCartoesId?: number = null;
    autorizacao: string = null;
    tarifa?: number = 0;
    movimentacoes: Array<ContaReceberRecebimentoModel> = [];
    lancamentosVinculados: Array<ContaReceberModel> = [];
    contabilizar: boolean = null;
    //para auxilio na selecao de liquidar no contas de operadora parcelados
    liquidarSaldo?: boolean = false;
    //utilizado para exibição no grid sem alterar o saldo pendente da conta
    saldoGrid?: number = 0;
}

export interface ContaReceberListParameters {
    statusContas: number;
    filterKeyId: string;
    dataVencimentoIniStr?: string;
    dataVencimentoFinStr?: string;
    contaCorrenteId?: number;
    contaGerencialId?: number;
    onlyClientes?: boolean;
    onlyOperadoras?: boolean;
    onlyParceladas?: boolean;
    formaPagamentoId?: number;
}
