import TipoLancamento from "../enum/tipoLancamento";

import BaseModel from "./../base/baseModel";
import EspeciePagamento from "./../enum/especiePagamento";
import TiposMovimentacao from "./../enum/tiposMovimentacao";

export default class MovimentacaoCaixaDetalhesModel extends BaseModel {
    //ambos
    id?: number = 0;
    dataLancamento: Date = null;
    dataMovimento: Date = null;
    contaCorrenteId = 0;
    contaCorrenteDescricao: string = null;
    juros = 0;
    desconto = 0;
    valor = 0;
    lancamentoId?: number = null;
    formaPagamentoId = 0;
    formaPagamentoDescricao: string = null;
    observacao: string = null;
    sessaoCaixaId?: number = null;
    descricao: string = null;
    //recebimento
    autorizacao?: number = null;
    contabilizar: boolean = null;
    metodoPagamentoXFormaId?: number = null;
    lancamentoOperadoraId?: number = null;
    //distincao
    tiposMovimentacao?: TiposMovimentacao = null;
    tiposMovimentacaoDescr: string = null;
    //busca do lancamento origem
    descClienteFornecedor: string = null;
    contaGerencial: string = null;
    //busca da forma pagamento ou recebimento
    especie: EspeciePagamento = null;
    especieDescricao: string = null;

    usuario: string = null;
    tipoLancamento?: TipoLancamento = null;
}
