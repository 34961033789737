import Vue from "vue";
import { mapState } from "vuex";

import actionBarComponent from "@/components/child/actionBar/actionBar.vue";
import buttonIncluirComponent from "@/components/child/form/button/buttonIncluir.vue";
import comboComponent from "@/components/child/form/combo.vue";
import fieldsetComponent from "@/components/child/form/fieldset.vue";
import gridComponent from "@/components/child/grid/grid.vue";
import { GridAction } from "@/components/child/grid/gridAction";
import { GridColors, GridColumn, GridColumnExt, GridColumnType } from "@/components/child/grid/gridColumn";
import ShortcutComponent from "@/components/parent/shortcut/shortcut";
import shortcutComponent from "@/components/parent/shortcut/shortcut.vue";
import { Component } from "@/decorators";
import PreLoadPackModel from "@/models/auxiliar/preLoadPackModel";
import CompraItemFornecedorModel from "@/models/compraItemFornecedorModel";
import CompraItemModel from "@/models/compraItemModel";
import CompraModel from "@/models/compraModel";
import NotaFiscalEntradaPorProdutoLoteIdModel from "@/models/notaFiscalEntradaPorProdutoLoteIdModel";
import CompraService from "@/services/compraService";
import IndicesConversoesUnidadeMedidasService from "@/services/indicesConversoesUnidadeMedidaService";
import NotaFiscalEntradaService from "@/services/notaFiscalEntradaService";
import { AppState } from "@/store/store";
import { sortArray } from "@/utils/common/array";
import Delay from "@/utils/common/delay";

import "../crud.scss";

@Component({
    components: {
        fieldsetComponent,
        gridComponent,
        shortcutComponent,
        buttonIncluirComponent,
        comboComponent,
        actionBarComponent,
    },
    computed: {
        ...mapState({
            preLoadList: (state: AppState) => state.preLoad.preLoadList,
        }),
    },
})
export default class CompraCotacaoEditComponent extends Vue {
    private shortcutComponent: ShortcutComponent = null;
    private service = new CompraService();
    private notaFiscalEntradaService = new NotaFiscalEntradaService();
    private indiceConversaoService = new IndicesConversoesUnidadeMedidasService();
    preLoadList: PreLoadPackModel;

    model = new CompraItemModel();
    fornecedorModel = new CompraItemFornecedorModel();
    modelId = 0;
    subDataName = "fornecedores";
    gridData: Array<CompraItemModel> = [];
    gridNotasProduto: Array<NotaFiscalEntradaPorProdutoLoteIdModel> = [];
    unidadeMedidaOptions: Array<Object> = [];
    private extraActions: Array<GridAction[]> = [];
    private extraActionsView: Array<GridAction> = [];

    get gridColumns(): Array<GridColumn> {
        return [
            new GridColumn("produtoDescricao", "Produto", GridColumnType.String, false, false, "", "", true),
            new GridColumn("quantidadeCompra", "Qtd. a Comprar", GridColumnType.Decimal, false, false, "", "", true),
            new GridColumn("unidadeMedidaDescricao", "Unid. Medida", GridColumnType.String, false, false, "", "", true),
            new GridColumn("valorCusto", "Valor Custo", GridColumnType.Money, false, false, "", "", true),
        ];
    }

    get gridColumnsNotas(): Array<GridColumn> {
        return [
            new GridColumn("dataInclusao", "Data", GridColumnType.Date, false, false, "", "", true),
            new GridColumn("numero", "Nº Nota", GridColumnType.Integer, false, false, "", "", true),
            new GridColumn("fornecedorNome", "Fornecedor", GridColumnType.String, false, false, "", "", true),
            new GridColumn("quantidadeComprada", "Quantidade", GridColumnType.Decimal, false, false, "", "", true),
            new GridColumn("valorUnitario", "Valor Unitário", GridColumnType.Money, false, false, "", "", true),
            new GridColumn("valorTotal", "Valor Total", GridColumnType.Money, false, false, "", "", true),
        ];
    }

    get gridColumnsCamposEditable(): Array<GridColumn> {
        return [
            new GridColumn("fornecedorDescricao", "Fornecedor", GridColumnType.String, false, false, "", "", true),
            new GridColumnExt({
                value: "valorTotal",
                text: "Valor Total",
                type: GridColumnType.Money,
                editable: true,
                onChange: (valor, index, subColumnIndex) => this.onCalculaValorUnitario(valor, index, subColumnIndex),
            }),
            new GridColumnExt({
                value: "quantidade",
                text: "Quantidade",
                type: GridColumnType.Decimal,
                onChange: (valor, index, subColumnIndex) => this.onCalculaValorUnitario(valor, index, subColumnIndex),
                editable: true,
            }),
            new GridColumnExt({
                value: "unidadeMedidaId",
                text: "Unidade Medida",
                type: GridColumnType.Combo,
                editable: true,
                comboOptions: () => this.unidadeMedidaOptions,
                onChange: (valor, index, subColumnIndex) => this.onCalculaValorUnitario(valor, index, subColumnIndex),
                defaultValueProp: "unidadeMedidaId",
            }),
            new GridColumnExt({
                value: "dataValidade",
                text: "Data de Validade",
                type: GridColumnType.Date,
                editable: true,
                searchbleColorControl: true,
            }),
            new GridColumn(
                "valorUnitario",
                "Valor Unitário",
                GridColumnType.Money,
                false,
                false,
                "",
                "",
                false,
                false,
                4,
            ),
        ];
    }

    conditionShowSubData(it) {
        return it["fornecedores"]["length"] > 0;
    }

    private highlightRow(_, index: number, subIndex: number): string {
        const hasAnyValue = this.gridData[index].fornecedores.some(item => item.valorUnitario > 0);
        if (!hasAnyValue) {
            return "";
        }

        const menorIndice = this.gridData[index].fornecedores.reduce((minIndex: number, item, i, arr) => {
            if (item.valorUnitario > 0 && minIndex === null) {
                return i;
            } else if (
                item.valorUnitario > 0 &&
                minIndex !== null &&
                item.valorUnitario < arr[minIndex].valorUnitario
            ) {
                return i;
            } else if (minIndex !== null) {
                return minIndex;
            }
            return null;
        }, null);

        return menorIndice !== null && subIndex === menorIndice ? "#FF0" : "";
    }

    private async onCalculaValorUnitario(valor, index, subColumnIndex) {
        const item = this.gridData[index].fornecedores[subColumnIndex];
        if (item && item.quantidade > 0 && item.valorTotal > 0) {
            const produto = this.preLoadList.produtos.find(x => x.id == this.gridData[index].produtoId);

            const indiceConversao = await this.indiceConversaoService.getIndiceConversao(
                produto.unidadeMedidaEstoqueId,
                item.unidadeMedidaId,
            );

            const quantidade = item.quantidade / indiceConversao;
            item.valorUnitario = item.valorTotal / quantidade;
        }
    }

    private onChangeSort(sortKey: keyof CompraItemModel, sortOrder: "asc" | "desc") {
        sortArray(this.gridData, sortKey, sortOrder);
    }

    private async load() {
        this.extraActions.splice(0);
        this.extraActionsView.splice(0);

        this.model = new CompraItemModel();
        const data = await this.service.get(this.modelId).withLoading().resolveWithJSON<CompraModel>();

        this.gridData = data.itens;
        if (data.itens.length > 0) {
            for (const compraItem of this.gridData) {
                if (data.fornecedores.length > 0) {
                    for (const item of data.fornecedores) {
                        if (!compraItem.fornecedores.some(s => s.fornecedorId === item.fornecedorId)) {
                            const compraItemFornecedor = new CompraItemFornecedorModel();
                            compraItemFornecedor.fornecedorId = item.fornecedorId;
                            compraItemFornecedor.fornecedorDescricao = item.fornecedorDescricao;
                            compraItemFornecedor.unidadeMedidaId = compraItem.unidadeMedidaId;
                            compraItemFornecedor.compraItemId = compraItem.id;
                            compraItem.fornecedores.push(compraItemFornecedor);
                        }
                    }
                }
            }
        }

        data.itens.forEach(p => {
            this.extraActionsView.push(
                new GridAction("visualizar", "Visualizar histórico de compras", "fa fa-eye", GridColors.BLUE),
            );
        });

        this.extraActions.push(this.extraActionsView);
    }

    private onExtraActions(name: string, model: CompraItemModel) {
        if (name.trim() == "visualizar") {
            this.showNotas(model);
        }
    }

    private onChangeSortNota(sortKey: keyof NotaFiscalEntradaPorProdutoLoteIdModel, sortOrder: "asc" | "desc") {
        sortArray(this.gridNotasProduto, sortKey, sortOrder);
    }

    public async showNotas(model: CompraItemModel) {
        this.gridNotasProduto = await this.notaFiscalEntradaService
            .getByProduto(model.produtoId)
            .withLoading()
            .resolveWithJSON<NotaFiscalEntradaPorProdutoLoteIdModel[]>();

        while (!this.$refs.shortcutComponent) await Delay(5);
        this.shortcutComponent = this.$refs.shortcutComponent as ShortcutComponent;

        this.shortcutComponent.title = this.$t("__.ts.historicoComprasProduto") as string;
        this.shortcutComponent.show();
    }

    private async save() {
        try {
            try {
                if (this.gridData.some(s => s.fornecedores.some(x => x.unidadeMedidaId == null))) {
                    const produto = this.gridData.find(x => x.fornecedores.some(s => s.unidadeMedidaId == null));
                    return await this.$showError(
                        this.$t("__.ts.erro"),
                        `${this.$t("__.ts.necessarioSelec")} ${produto.produtoDescricao}.`,
                    );
                }

                const response = await this.service
                    .insertUpdateCompraItem(this.gridData, this.gridData[0].compraId)
                    .withLoading()
                    .resolveWithoutJSON();
                if (response) {
                    await this.$showInclusaoUpdate(this.model.id);
                    this.$router.back();
                } else {
                    this.$focusErrorField();
                }
            } catch {}
        } catch {}
    }

    private cancel() {
        this.$router.back();
    }

    private async mounted() {
        while (!this.preLoadList) await Delay(10);
        this.unidadeMedidaOptions = this.preLoadList.unidadesMedida.map(p => ({ value: p.id, text: p.sigla }));

        if (this.$route.params.id) {
            this.modelId = +this.$route.params.id;
            await this.load();
        }
    }
}
