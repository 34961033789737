import Vue from "vue";

import comboComponent from "@/components/child/form/combo.vue";
import decimalComSinalCustomComponent from "@/components/child/form/decimalComSinalCustom.vue";
import { Component, Prop, Watch } from "@/decorators";
import KitEmbalagemItemModel from "@/models/kitEmbalagemItemModel";
import PaginationModel from "@/models/paginationModel";
import ProdutoLoteFracionamentoItemEmbalagemModel from "@/models/produtoLoteFracionamentoItemEmbalagemModel";
import ProdutoLoteModel from "@/models/produtoLoteModel";
import ProdutoLoteService from "@/services/produtoLoteService";

import "../crud.scss";

@Component({
    components: {
        comboComponent,
        decimalComSinalCustomComponent,
    },
})
export default class FracionamentoKitEmbalagemItemEditComponent extends Vue {
    private produtoLoteService = new ProdutoLoteService();

    model = new KitEmbalagemItemModel();
    produtoLotes: ProdutoLoteModel[] = [];
    produtoLoteOptions = [];

    @Prop(Number) index: number;
    @Prop(Array) produtoOptions;
    @Prop(Boolean) somenteConsulta: boolean;

    //@ts-ignore
    @Watch("model.produtoId")
    private onChangeProdutoId() {
        if (this.model.produtoId) {
            this.loadProdutoLoteOptions();
        }
    }

    private async loadProdutoLoteOptions() {
        this.produtoLoteOptions = [];
        this.produtoLotes = [];

        try {
            const quantidade = this.somenteConsulta ? 0 : this.model.quantidade;
            const data = await this.produtoLoteService
                .listFilterProduto(this.model.produtoId, quantidade, 0, 1, 100000)
                .resolveWithJSON<PaginationModel<ProdutoLoteModel>>();

            this.produtoLotes = data.list;

            this.setProdutoLoteId();
        } catch {}
    }

    //@ts-ignore
    @Watch("produtoLotes")
    private async onChangeProdutoLotes() {
        this.produtoLoteOptions = this.produtoLotes.map(p => ({ value: p.id, text: p.descricao }));
    }

    //@ts-ignore
    @Watch("model.produtoLoteId")
    private onChangeProdutoLoteId() {
        this.$emit("onChangeItemEmbalagem", this.model);
    }

    //@ts-ignore
    @Watch("model.quantidade")
    private onChangeQuantidade() {
        if (this.model.produtoId) {
            this.loadProdutoLoteOptions();
        }
    }

    private setProdutoLoteId() {
        const produto = this.produtoOptions.find(p => p["value"] == this.model.produtoId);

        if (produto["ignoraControleEstoque"]) {
            if (this.produtoLotes.length == 0) {
                this.produtoLotes.push(this.geraLoteOutro());
            }
        } else if (this.model.produtoLoteId == null && this.produtoLotes.length > 0) {
            this.model.produtoLoteId = this.produtoLotes.sort((a, b) =>
                a.dataValidade > b.dataValidade ? 1 : 0,
            )[0].id;
        }
    }

    private geraLoteOutro() {
        const lote = new ProdutoLoteModel();
        lote.descricao = "Outro";
        lote.id = 0;
        return lote;
    }

    public async validateAll() {
        return await this.$validator.validateAll();
    }

    public setModel(item: ProdutoLoteFracionamentoItemEmbalagemModel) {
        this.model.updateFrom(item);
    }
}
