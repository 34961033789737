import RascunhoModel from "@/models/rascunhoModel";
import nfetch from "@/utils/events/nfetch";

export default class RascunhoService {
    private controllerName = "Rascunho";

    public list(entidade: string): Promise<Response> {
        return nfetch(`/${this.controllerName}/GetList?entidade=${entidade}`, {
            credentials: "same-origin",
        });
    }

    public get(id: string): Promise<Response> {
        return nfetch(`/${this.controllerName}/Get?id=${id}`, {
            credentials: "same-origin",
        });
    }

    public delete(id: string): Promise<Response> {
        return nfetch(`/${this.controllerName}/Delete?id=${id}`, {
            method: "post",
            credentials: "same-origin",
        });
    }

    public insert(model: RascunhoModel): Promise<Response> {
        return nfetch(`/${this.controllerName}/Insert`, {
            method: "post",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json; charset=UTF-8",
            },
            body: JSON.stringify(model),
        });
    }
}
