import BaseModel from "../base/baseModel";
import Alinhamento from "../enum/alinhamento";
import BasicColor from "../enum/basicColor";
import CodigoBarras from "../enum/modeloImpressao/codigoBarras";
import PosicaoComplemento from "../enum/posicaoComplemento";

export default class ModeloImpressaoCamposModel extends BaseModel {
    id?: number = null;
    modeloImpressaoSessaoId?: number = null;
    coluna: number = null;
    linha: number = null;
    labelName: string = null;
    labelId: string = null;
    labelValue: string = null;
    personalizado: boolean = null;
    labelShowValue: boolean = null;
    header: boolean = null;
    index: number = null;
    parentIndex: number = null;
    grid: boolean = null;
    codGroup: string = null;
    posComplemento: PosicaoComplemento = 0;
    tipoCampo: TipoCampo = 0;
    camposEnumValues: Array<string> = [];
    modeloImpressaoCampoPaiId?: number = null;

    //campos para auxiliar nos detalhes do texto e valores de complementos baseados em outros campos
    bold: boolean = null;
    colorText: BasicColor = null;
    colorBGText: BasicColor = null;
    substituto: boolean = null;
    adicional: boolean = null;
    modeloImpressaoCampoComplementoId?: number = null;
    modeloImpressaoCampoComplemento: ModeloImpressaoCamposModel = null;
    modeloImpressaoCampoSubstitutoId?: number = null;
    modeloImpressaoCampoSubstituto: ModeloImpressaoCamposModel = null;
    limiteCaracteres?: number = null;
    alinhamento: Alinhamento = null;
    //mascaraData: MacaraData="dd/mm/yyyy"?

    ///parte para auxilio de impressao de rótulo
    //utiliza apena primeira parte do texto
    primeiroNome = false;
    primeiroNomeCampoSubstituto = false;
    quebrarLinha?: boolean = null;
    codigoDeBarras?: CodigoBarras = CodigoBarras.Nenhum;
    //maximoLinhas?: number = null;
    fontSize?: number = null;
    brancoQuandoZero = false;
}

export enum TipoCampo {
    Outros = 0,
    Int = 1,
    Decimal = 2,
    DateTime = 3,
    String = 4,
    Float = 5,
    Long = 6,
    Enum = 7,
}
