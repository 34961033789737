import BaseModel from "./base/baseModel";

export default class FracionamentoCancelamentoItemModel extends BaseModel {
    id?: number = null;
    fracionamentoCancelamentoId = 0;
    fracionamentoItemId?: number = null;
    quantidade?: number = null;
    unidadeMedidaId?: number = null;

    descricao: string = null;
    unidadeMedidaDescricao: string = null;
}
