var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !_vm.somenteConsulta
        ? _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-sm-12" }, [
              _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c(
                    "combo-component",
                    {
                      attrs: {
                        id: "ensaioId",
                        name: "ensaioId",
                        actionName: "ensaios",
                        addNew: true,
                        customView: true,
                        data: _vm.options,
                        formAddNewName: "Novo ensaio",
                        modalClass: "lm-modal",
                        refresh: _vm.loadEnsaios,
                      },
                      on: {
                        addNewItemCombo: _vm.onAddNewEnsaio,
                        openComboNewItem: _vm.openNewEnsaio,
                      },
                      model: {
                        value: _vm.selected,
                        callback: function ($$v) {
                          _vm.selected = $$v
                        },
                        expression: "selected",
                      },
                    },
                    [
                      _c(
                        "div",
                        { attrs: { slot: "component" }, slot: "component" },
                        [
                          _c("ensaioComponent", {
                            ref: "ensaioComponent",
                            attrs: { CalledByShortCut: true },
                            on: { "save-ok": _vm.onEnsaioSaveOk },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      !_vm.somenteConsulta
        ? _c("buttonIncluirComponent", { on: { click: _vm.onInclusaoEnsaio } })
        : _vm._e(),
      _vm._v(" "),
      _c("grid-component", {
        ref: "gridcomponent",
        attrs: {
          data: _vm.gridOptions,
          columns: _vm.gridColumns,
          showRemoveItem: true,
          showEditItem: false,
          showActionColumn: !_vm.somenteConsulta,
          showSortIcons: true,
          showTableHead: false,
          showAddNewButton: false,
          showTableFoot: false,
          showTools: false,
        },
        on: { "remove-item": _vm.onRemoveEnsaio },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }