import Vue from "vue";

import actionBarComponent from "@/components/child/actionBar/actionBar.vue";
import comboComponent from "@/components/child/form/combo.vue";
import fieldsetComponent from "@/components/child/form/fieldset.vue";
import CapaSeiComponent from "@/components/parent/crud/veterinario/capaSei/capaSei";
import capaSeiComponent from "@/components/parent/crud/veterinario/capaSei/capaSei.vue";
import { Component, Watch } from "@/decorators";
import EnumExtensions from "@/models/enum/extensions/enumExtensions";
import MesesDoAno from "@/models/enum/mesesDoAno";
import PDFService from "@/services/pdf/PDFService";

import "../crud.scss";

@Component({
    components: {
        fieldsetComponent,
        actionBarComponent,
        comboComponent,
        capaSeiComponent,
    },
})
export default class ManipuladoresComponent extends Vue {
    private service = new PDFService("ManipuladoresPDF");
    private capaSeiComponent: CapaSeiComponent;

    tipoPeriodoId: number = null;
    responsavelId: number = null;
    periodoId: number = null;
    ano: number = null;
    mes: number = null;

    tipoPeriodoOptions: Array<Object> = [
        { text: "Semestral", value: 1 },
        { text: "Trimestral", value: 2 },
        { text: "Mensal", value: 3 },
    ];

    responsavelOptions: Array<Object> = [
        { text: "Farmacêutico", value: 1 },
        { text: "Veterinário MAPA", value: 2 },
    ];

    semestralOptins: Array<object> = [
        { text: "1º Semestre", value: 6 },
        { text: "2º Semestre", value: 7 },
    ];

    trimestralOptins: Array<object> = [
        { text: "1º Trimestre", value: 1 },
        { text: "2º Trimestre", value: 2 },
        { text: "3º Trimestre", value: 3 },
        { text: "4º Trimestre", value: 4 },
    ];

    mesesOptions = EnumExtensions.getNamesAndValuesOrderedByValues(MesesDoAno);

    private load() {
        this.ano = new Date().getFullYear();
    }

    private async save() {
        try {
            const isValid = await this.$validator.validateAll();

            if (isValid) {
                const filters = {
                    periodo: this.periodoId,
                    responsavel: this.responsavelId,
                    ano: this.ano,
                    mes: this.mes,
                    capaSei: this.capaSeiComponent.get(),
                };

                await this.service.geraPDFWithFilters(filters);

                this.$showSuccess("Sucesso", "Arquivo foi gerado corretamente.");
            }
        } catch (e) {
            this.$showError("Erro", `Erro ao gerar o arquivo.\n Erro: ${e.message}`);
        }
    }

    private cancel() {
        this.$router.back();
    }

    @Watch("tipoPeriodoId")
    private onChangeTipoPeriodoId() {
        this.mes = 0;
    }

    private async mounted() {
        this.capaSeiComponent = this.$refs.capaSeiComponent as CapaSeiComponent;
        this.load();
    }
}
