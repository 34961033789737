var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "crud crud-list" },
    [
      _c("div", { staticClass: "info-div" }, [
        _c("h1", { staticClass: "title" }, [
          _vm._v(
            "\n            " +
              _vm._s(
                _vm.$t(
                  "__.Components.parent.sessaoCaixa.listMovimentacoesPendente_vue_html.movimentacoesPendente"
                )
              ) +
              "\n        "
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "content" }, [
          _c("div", { staticClass: "info-panel" }, [
            _c("span", [_vm._v(_vm._s(_vm.total))]),
            _vm._v(" "),
            _c("span", [
              _vm._v(
                "\n                    " +
                  _vm._s(
                    _vm.$t(
                      "__.Components.parent.sessaoCaixa.listMovimentacoesPendente_vue_html.movimentacoesPendente"
                    )
                  ) +
                  "\n                "
              ),
            ]),
            _vm._v(" "),
            _c("span", [
              _vm._v(
                "\n                    " +
                  _vm._s(
                    _vm.$t(
                      "__.Components.parent.sessaoCaixa.listMovimentacoesPendente_vue_html.cadastradas"
                    )
                  ) +
                  "\n                "
              ),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "grid-component",
        {
          ref: "gridcomponent",
          attrs: {
            actionName: "movimentacoesPendentes",
            columns: _vm.gridColumns,
            data: _vm.gridData,
            "page-index": _vm.pageIndex,
            "show-select-checkbox": true,
            "page-size": _vm.pageSize,
            startFilterKey: _vm.startFilterKey,
            tituloPagina: "MovimentacoesPendentes",
            total: _vm.total,
            extraActions: _vm.extraActions,
          },
          on: {
            "extra-action": _vm.onExtraActions,
            "change-filter-key": _vm.onChangeFilterKey,
            "change-page": _vm.onChangePage,
            "change-sort": _vm.onChangeSort,
            "selected-values-changed": _vm.onSelectedValuesChanged,
            "gerar-impressao": function ($event) {
              _vm.pags = true
              _vm.tipoDocGerar = 2
            },
            "gerar-pdf": function ($event) {
              _vm.pags = true
              _vm.tipoDocGerar = 1
            },
            "gerar-xls": function ($event) {
              _vm.pags = true
              _vm.tipoDocGerar = 0
            },
            "remove-item": _vm.onRemoveItem,
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "div-pagar-selecionados",
              attrs: { slot: "footer-extra-options" },
              slot: "footer-extra-options",
            },
            [
              _vm.gridSelectedValues.length > 0
                ? _c(
                    "button",
                    {
                      staticClass: "btn btn-primary",
                      on: { click: _vm.onReprocessar },
                    },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(
                            _vm.$t(
                              "__.Components.parent.sessaoCaixa.listMovimentacoesPendente_vue_html.reprocessar"
                            )
                          ) +
                          "\n            "
                      ),
                    ]
                  )
                : _vm._e(),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }