var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "crud crud-list ajusteAcoesPesquisar" },
    [
      !_vm.CalledByShortCut
        ? _c("div", { staticClass: "info-div" }, [
            _c("h1", { staticClass: "title" }, [
              _vm._v(_vm._s(_vm.$t("__.ts.vendasEntrega"))),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "content" }, [
              _c("div", { staticClass: "info-panel" }, [
                _c("span", [_vm._v(_vm._s(_vm.total))]),
                _vm._v(" "),
                _c("span", [_vm._v(_vm._s(_vm.$t("__.ts.vendasEntrega")))]),
                _vm._v(" "),
                _c("span", [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        "__.Components.parent.venda.listOrcamento_vue_html.cadastrados"
                      )
                    )
                  ),
                ]),
              ]),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "grid-component",
        {
          ref: "gridcomponent",
          attrs: {
            data: _vm.gridData,
            tituloPagina: "Orçamento",
            "edit-item-icon": _vm.somenteConsultaVenda
              ? "fa-search"
              : "fa-edit",
            "edit-item-title": _vm.somenteConsultaVenda
              ? "Consultar"
              : "Alterar",
            columns: _vm.gridColumns,
            subColumns: _vm.gridColumnsVinculos,
            subDataName: _vm.subDataName,
            showExpand: true,
            conditionShowSubData: _vm.conditionShowSubData,
            "show-select-checkbox": true,
            "page-index": _vm.pageIndex,
            "page-size": _vm.pageSize,
            controlaPageSize: true,
            total: _vm.total,
            showRemoveItem: false,
            showAddNewButton: false,
            startFilterKey: _vm.startFilterKey,
            actionName: "vendas",
            extraActions: _vm.extraActions,
          },
          on: {
            "gerar-xls": function ($event) {
              _vm.pags = true
              _vm.tipoDocGerar = 0
            },
            "gerar-pdf": function ($event) {
              _vm.pags = true
              _vm.tipoDocGerar = 1
            },
            "gerar-impressao": function ($event) {
              _vm.pags = true
              _vm.tipoDocGerar = 2
            },
            "extra-action": _vm.onExtraActions,
            "change-filter-key": _vm.onChangeFilterKey,
            "change-sort": _vm.onChangeSort,
            "edit-item": _vm.onEditItem,
            "change-page": _vm.onChangePage,
            "change-page-size": _vm.onChangePageSize,
            "selected-values-changed": _vm.onSelectedValuesChanged,
          },
        },
        [
          _c("div", { attrs: { slot: "header" }, slot: "header" }, [
            _c("div", { staticClass: "flex-row" }, [
              _c(
                "div",
                { staticClass: "tipoBuscaVenda" },
                [
                  _c(
                    "label",
                    {
                      staticClass: "control-label",
                      attrs: { for: "tipoBuscaVenda" },
                    },
                    [
                      _vm._v(
                        "\n                        " +
                          _vm._s(_vm.$t("__.ts.buscarEm")) +
                          "\n                    "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("combo-component", {
                    attrs: {
                      data: _vm.tipoBuscaVendaOptions,
                      searchable: true,
                      id: "tipoBuscaVenda",
                      name: "tipoBuscaVenda",
                    },
                    model: {
                      value: _vm.tipoBuscaVenda,
                      callback: function ($$v) {
                        _vm.tipoBuscaVenda = $$v
                      },
                      expression: "tipoBuscaVenda",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "filtro-lista" }, [
                _c(
                  "button",
                  {
                    staticClass: "filtro-avancado-button",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        _vm.isHidden = !_vm.isHidden
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n                        " +
                        _vm._s(_vm.$t("__.Crud.filtroAvancado")) +
                        "\n                        "
                    ),
                    _c("i", { staticClass: "fa fa-caret-down" }),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.isHidden,
                        expression: "isHidden",
                      },
                    ],
                    staticClass: "drop-lista",
                  },
                  [
                    _c(
                      "form",
                      {
                        attrs: { novalidate: "novalidate" },
                        on: {
                          submit: function ($event) {
                            $event.preventDefault()
                            return _vm.getFormValues($event)
                          },
                        },
                      },
                      [
                        _c(
                          "div",
                          { staticStyle: { "margin-bottom": "15px" } },
                          [
                            _c("b", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "__.Components.parent.venda.listOrcamento_vue_html.de"
                                  )
                                )
                              ),
                            ]),
                            _vm._v(" "),
                            _c("date-time-picker-component", {
                              staticStyle: { width: "100%" },
                              attrs: {
                                pickTime: true,
                                id: "dataFilter1",
                                name: "dataFilter1",
                              },
                              model: {
                                value: _vm.dataFilter1,
                                callback: function ($$v) {
                                  _vm.dataFilter1 = $$v
                                },
                                expression: "dataFilter1",
                              },
                            }),
                            _vm._v(" "),
                            _c("b", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "__.Components.parent.venda.listOrcamento_vue_html.ate"
                                  )
                                )
                              ),
                            ]),
                            _vm._v(" "),
                            _c("date-time-picker-component", {
                              attrs: {
                                pickTime: true,
                                id: "dataFilter2",
                                name: "dataFilter2",
                              },
                              model: {
                                value: _vm.dataFilter2,
                                callback: function ($$v) {
                                  _vm.dataFilter2 = $$v
                                },
                                expression: "dataFilter2",
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "label",
                              {
                                staticClass: "control-label",
                                attrs: { for: "vendedor" },
                              },
                              [
                                _vm._v(
                                  "\n                                    " +
                                    _vm._s(
                                      _vm.$t(
                                        "__.Crud.controleReclamacao.edit_vue_html.vendedor"
                                      )
                                    ) +
                                    "\n                                "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("combo-component", {
                              staticStyle: { background: "white" },
                              attrs: {
                                id: "vendedor",
                                name: "vendedor",
                                data: _vm.usuariosOptions,
                                defaultValue: -1,
                              },
                              model: {
                                value: _vm.vendedor,
                                callback: function ($$v) {
                                  _vm.vendedor = $$v
                                },
                                expression: "vendedor",
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "label",
                              {
                                staticClass: "control-label",
                                attrs: { for: "localEntregaId" },
                              },
                              [
                                _vm._v(
                                  "\n                                    " +
                                    _vm._s(
                                      _vm.$t(
                                        "__.Components.parent.venda.editSingle_vue_html.localEntrega"
                                      )
                                    ) +
                                    "\n                                "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("combo-component", {
                              attrs: {
                                id: "localEntregaId",
                                name: "localEntregaId",
                                data: _vm.locaisEntregaOptions,
                                searchable: true,
                                customView: true,
                              },
                              model: {
                                value: _vm.localEntregaId,
                                callback: function ($$v) {
                                  _vm.localEntregaId = $$v
                                },
                                expression: "localEntregaId",
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "label",
                              {
                                staticClass: "control-label",
                                attrs: { for: "statusEntrega" },
                              },
                              [
                                _vm._v(
                                  "\n                                    " +
                                    _vm._s(
                                      _vm.$t(
                                        "__.Crud.expedicao.listEntrega_vue_html.statusEntrega"
                                      )
                                    ) +
                                    "\n                                "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("combo-component", {
                              attrs: {
                                id: "statusEntrega",
                                name: "statusEntrega",
                                data: _vm.statusEntregaOptions,
                                customView: true,
                              },
                              model: {
                                value: _vm.statusEntrega,
                                callback: function ($$v) {
                                  _vm.statusEntrega = $$v
                                },
                                expression: "statusEntrega",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "form-group" }, [
                          _c(
                            "button",
                            {
                              staticClass: "botao-filtrar",
                              attrs: { type: "submit" },
                            },
                            [
                              _vm._v(
                                "\n                                    " +
                                  _vm._s(_vm.$t("__.Crud.filtrar")) +
                                  "\n                                "
                              ),
                            ]
                          ),
                        ]),
                      ]
                    ),
                  ]
                ),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              attrs: { slot: "footer-extra-options" },
              slot: "footer-extra-options",
            },
            [
              _vm.gridSelectedValues.length > 0
                ? _c(
                    "button",
                    {
                      staticClass: "btn btn-primary",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          _vm.imprimirRomaneio()
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(
                            _vm.$t(
                              "__.Components.parent.home.fastaction.entregas.entregas_vue_html.imprimirRomaneio"
                            )
                          ) +
                          "\n            "
                      ),
                    ]
                  )
                : _vm._e(),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _c("tentativasEntregaComponent", {
        ref: "tentativasEntregaComponent",
        on: { onStatusSucesso: _vm.load },
      }),
      _vm._v(" "),
      _c("impressaoComponent", { ref: "impressaoComponent" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }