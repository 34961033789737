var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "fast-action-grid-panel" }, [
    _c(
      "div",
      { staticClass: "fast-action-grid-panel-left marcaDagua" },
      [
        _c(
          "div",
          {
            staticClass: "component-body",
            staticStyle: { "padding-right": "10px" },
          },
          [
            _c("div", { staticClass: "top-infos" }, [
              _vm._v(
                "\n                " +
                  _vm._s(
                    _vm.$t(
                      "__.Components.parent.home.fastaction.continuos.continuos_vue_html.continuos"
                    )
                  ) +
                  "\n            "
              ),
            ]),
            _vm._v(" "),
            _vm._l(_vm.vendasContinuos, function (venda) {
              return _c(
                "a",
                {
                  key: venda.id,
                  staticClass: "list-group-item",
                  staticStyle: { "padding-left": "46px" },
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: { cursor: "pointer" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          _vm.onSearch(venda)
                        },
                      },
                    },
                    [
                      _c("i", {
                        staticClass: "fa fa-exclamation-circle",
                        class: _vm.getClass(venda),
                      }),
                      _vm._v(" "),
                      _c("b", { staticStyle: { color: "yellow" } }, [
                        _vm._v(
                          "\n                        " +
                            _vm._s(_vm.getFirstName(venda.clienteNome, false)) +
                            "\n                        "
                        ),
                        _c("sup", [
                          _vm._v(
                            _vm._s(_vm.getFirstName(venda.pacienteNome, true))
                          ),
                        ]),
                      ]),
                      _vm._v(
                        "\n                    " +
                          _vm._s(
                            _vm.$t(
                              "__.Components.parent.home.fastaction.continuos.continuos_vue_html.venc"
                            )
                          ) +
                          "\n                    "
                      ),
                      _c("b", { staticStyle: { color: "yellow" } }, [
                        _vm._v(_vm._s(_vm.getDateString(venda.dataVencimento))),
                      ]),
                      _vm._v("\n                    /\n                    "),
                      _c(
                        "b",
                        {
                          staticClass: "valores",
                          class: {
                            valorDif: venda.valor != venda.valorAtualizado,
                          },
                          staticStyle: { color: "yellow" },
                        },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(_vm._f("filterMoeda")(venda.valor)) +
                              "\n                    "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      venda.dataValidade != null
                        ? [
                            _vm._v(
                              "\n                        / Validade:\n                        "
                            ),
                            _c(
                              "b",
                              {
                                class: {
                                  valorDif:
                                    venda.valor != venda.valorAtualizado,
                                },
                                staticStyle: { color: "yellow" },
                              },
                              [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(
                                      _vm.getDateString(venda.dataValidade)
                                    ) +
                                    "\n                        "
                                ),
                              ]
                            ),
                          ]
                        : _vm._e(),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c("div", { attrs: { id: "container-icon" } }, [
                    _c("div", { staticClass: "container-home-icon" }, [
                      _vm.validarVisibilidadeBotao("ArquivarAvisoContinuo")
                        ? _c(
                            "div",
                            {
                              staticClass: "home-icon bg-orange",
                              attrs: {
                                "data-toggle": "tooltip",
                                title: _vm.$t(
                                  "__.Components.parent.home.fastaction.continuos.continuos_vue_html.arqAvisoContinuo"
                                ),
                                "data-placement": "left",
                              },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  _vm.onArquivar(venda)
                                },
                              },
                            },
                            [
                              _c("i", {
                                staticClass: "fa fa-archive",
                                attrs: { "aria-hidden": "true" },
                              }),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.validarVisibilidadeBotao("AdiarVencimentoContinuo")
                        ? _c(
                            "div",
                            {
                              staticClass: "home-icon bg-dark-red",
                              attrs: {
                                "data-toggle": "tooltip",
                                title: _vm.$t(
                                  "__.Components.parent.home.fastaction.continuos.continuos_vue_html.adiarVencContinuo"
                                ),
                                "data-placement": "left",
                              },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  _vm.onAdiar(venda)
                                },
                              },
                            },
                            [
                              _c("i", {
                                staticClass: "fa fa-clock",
                                attrs: { "aria-hidden": "true" },
                              }),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "home-icon bg-blue",
                          attrs: {
                            "data-toggle": "tooltip",
                            title: _vm.$t(
                              "__.Components.parent.home.fastaction.continuos.continuos_vue_html.visuUltimaVendaOrcContinuo"
                            ),
                            "data-placement": "left",
                          },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              _vm.onSearch(venda)
                            },
                          },
                        },
                        [
                          _c("i", {
                            staticClass: "fa fa-eye",
                            attrs: { "aria-hidden": "true" },
                          }),
                        ]
                      ),
                      _vm._v(" "),
                      _vm.validarVisibilidadeBotao("GerarOrcamentoWhatsapp")
                        ? _c(
                            "div",
                            {
                              staticClass: "home-icon bg-green",
                              attrs: {
                                "data-toggle": "tooltip",
                                title: _vm.$t(
                                  "__.Components.parent.home.fastaction.continuos.continuos_vue_html.gerarOrcamentWpp"
                                ),
                                "data-placement": "left",
                              },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  _vm.onWhats(venda)
                                },
                              },
                            },
                            [
                              _c("i", {
                                staticClass: "fab fa-whatsapp",
                                attrs: { "aria-hidden": "true" },
                              }),
                            ]
                          )
                        : _vm._e(),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        attrs: {
                          id: "icon-button",
                          "data-toggle": "tooltip",
                          "data-placement": "left",
                          "data-original-title": "Create",
                        },
                      },
                      [
                        _c("p", { staticClass: "plus" }, [_vm._v("+")]),
                        _vm._v(" "),
                        _c("div", { staticClass: "edit" }, [
                          _c(
                            "p",
                            {
                              staticClass: "icon",
                              on: {
                                click: function ($event) {
                                  _vm.dragAndDrop = true
                                },
                              },
                            },
                            [
                              _c("i", {
                                staticClass: "fa fa-question",
                                attrs: { "aria-hidden": "true" },
                              }),
                            ]
                          ),
                        ]),
                      ]
                    ),
                  ]),
                ]
              )
            }),
          ],
          2
        ),
        _vm._v(" "),
        _c(
          "shortcutComponent",
          {
            ref: "shortcutComponent",
            on: { "selection-item": _vm.onConfirmShortContinuos },
          },
          [
            _c(
              "div",
              {
                attrs: { slot: "component", id: "verMaisContinuos" },
                slot: "component",
              },
              [
                _c("venda-continuo-list-component", {
                  ref: "vendaContinuoListComponent",
                  attrs: { loadManual: true },
                }),
              ],
              1
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "a",
          {
            staticClass: "ver-mais list-group-item",
            attrs: { type: "button" },
            on: { click: _vm.onVerMais },
          },
          [
            _vm._v(
              "\n            " +
                _vm._s(
                  _vm.$t(
                    "__.Components.parent.home.fastaction.continuos.continuos_vue_html.verMais"
                  )
                ) +
                "\n        "
            ),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }