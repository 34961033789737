import PacienteModel from "../../models/paciente/pacienteModel";
import nfetch from "../../utils/events/nfetch";
import { IService } from "../base/iService";

export default class PacienteService extends IService<PacienteModel> {
    constructor() {
        super("Paciente");
    }

    public list(
        filterKey: string,
        sortKey: string,
        sortOrder: string,
        pageIndex: number,
        pageSize: number,
        customProperties: { [key: string]: any } = {},
    ): Promise<Response> {
        const encodedFilter = encodeURIComponent(filterKey);
        let custom = "";
        for (const key in customProperties) {
            if (customProperties[key] != undefined) {
                custom += `&${key}=${customProperties[key]}`;
            }
        }
        return nfetch(
            `/${this.controllerName}/GetList?filterKey=${encodedFilter}&sortKey=${sortKey}&sortOrder=${sortOrder}&pageIndex=${pageIndex}&pageSize=${pageSize}` +
                custom,
            {
                credentials: "same-origin",
            },
        );
    }

    public listAllStatus(
        filterKey: string,
        sortKey: string,
        sortOrder: string,
        pageIndex: number,
        pageSize: number,
        apenasAtivos = false,
    ): Promise<Response> {
        return nfetch(
            `/${this.controllerName}/GetList?filterKey=${filterKey}&sortKey=${sortKey}&sortOrder=${sortOrder}&pageIndex=${pageIndex}&pageSize=${pageSize}&apenasAtivos=${apenasAtivos}`,
            {
                credentials: "same-origin",
            },
        );
    }

    public getAlergias(pacienteId: number): Promise<Response> {
        return nfetch(`/${this.controllerName}/GetAlergias?pacienteId=${pacienteId}`, {
            credentials: "same-origin",
        });
    }

    public getCondicoes(pacienteId: number): Promise<Response> {
        return nfetch(`/${this.controllerName}/GetCondicoes?pacienteId=${pacienteId}`, {
            credentials: "same-origin",
        });
    }
}
