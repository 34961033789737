import Vue from "vue";

import Moment from "moment";

import { Component, Prop } from "@/decorators";
import { formatMoney } from "@/utils/common/number";

import "./ver.scss";

@Component
export default class VerComponent extends Vue {
    @Prop({ type: Object, default: () => {} }) data;

    private getMoneyString(value: number) {
        return formatMoney(+value);
    }

    private getDateString(dt: Date) {
        if (dt == null) return "";

        return Moment(dt).format("DD/MM/YYYY");
    }
}
