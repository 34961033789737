var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "shortcutComponent",
    {
      ref: "shortCutComponent",
      attrs: { btOkName: "Avançar", modalClass: "ss-modal", showAddNew: false },
      on: { "selection-item": _vm.onCreateAtendimento },
    },
    [
      _c("div", { attrs: { slot: "component" }, slot: "component" }, [
        _c("div", { attrs: { slot: "rows" }, slot: "rows" }, [
          _c("form", [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-sm-12" }, [
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c(
                      "label",
                      {
                        staticClass: "control-label",
                        attrs: { for: "clienteId" },
                      },
                      [
                        _vm._v(
                          "\n                                " +
                            _vm._s(
                              _vm.$t(
                                "__.Components.parent.venda.cancelamento_vue_html.nomeCliente"
                              )
                            ) +
                            "\n                            "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("search-combo-component", {
                      attrs: {
                        id: "clienteId",
                        name: "clienteId",
                        actionName: "clientes",
                        addNew: false,
                        advancedSearch: true,
                        filterable: false,
                        advancedSearchCampos: [
                          { campo: "text", descricao: "Nome" },
                          { campo: "cpf", descricao: "CNPJ/CPF" },
                          { campo: "celular", descricao: "Celular" },
                        ],
                        customComboText: _vm.customComboTextCliente,
                        customView: true,
                        propDescription: "nome",
                        service: "Cliente",
                      },
                      model: {
                        value: _vm.clienteId,
                        callback: function ($$v) {
                          _vm.clienteId = $$v
                        },
                        expression: "clienteId",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-sm-12" }, [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip",
                        value: _vm.errors.first("telefone"),
                        expression: "errors.first('telefone')",
                      },
                    ],
                    staticClass: "form-group",
                  },
                  [
                    _c(
                      "label",
                      {
                        staticClass: "control-label",
                        attrs: {
                          for: "telefone",
                          title: _vm.$t("__.obrigatorio"),
                        },
                      },
                      [
                        _c("span", { staticStyle: { color: "red" } }, [
                          _vm._v("*"),
                        ]),
                        _vm._v(
                          "\n                                " +
                            _vm._s(
                              _vm.$t("__.Crud.cliente.edit_vue_html.telefone")
                            ) +
                            "\n                            "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.telefone,
                          expression: "telefone",
                        },
                        { name: "mask-telefone", rawName: "v-mask-telefone" },
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "telefone",
                          expression: "'telefone'",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: {
                        id: "telefone",
                        type: "text",
                        name: "telefone",
                        autocomplete: "off",
                      },
                      domProps: { value: _vm.telefone },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.telefone = $event.target.value
                        },
                      },
                    }),
                  ]
                ),
              ]),
            ]),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }