import TiposPessoa from "../../models/enum/tiposPessoa";
import OperadoraCartoesModel from "../../models/financeiro/operadoraCartoesModel";
import nfetch from "../../utils/events/nfetch";

import { IService } from "./../base/iService";


export default class OperadoraCartoesService extends IService<OperadoraCartoesModel> {
    constructor() {
        super("OperadoraCartoes");
    }

    public getByDocumento(tipoPessoa: TiposPessoa, documento: string): Promise<Response> {
        return nfetch(`/${this.controllerName}/GetByDocumento?tipoPessoa=${tipoPessoa}&documento=${documento}`, {
            credentials: "same-origin"
        });
    }
}