import Vue from "vue";
import { mapState, mapMutations } from "vuex";

import buttonIncluirComponent from "@/components/child/form/button/buttonIncluir.vue";
import comboComponent from "@/components/child/form/combo.vue";
import dateTimePickerComponent from "@/components/child/form/datetimepicker.vue";
import decimalComponent from "@/components/child/form/decimal.vue";
import decimalComSinalCustomComponent from "@/components/child/form/decimalComSinalCustom.vue";
import fieldsetComponent from "@/components/child/form/fieldset.vue";
import { Component, Prop, Watch } from "@/decorators";
import PreLoadPackModel from "@/models/auxiliar/preLoadPackModel";
import EnumExtensions from "@/models/enum/extensions/enumExtensions";
import SubGrupoProduto from "@/models/enum/subGrupoProduto";
import TiposCalculoManipulacao from "@/models/enum/tiposCalculoManipulacao";
import TiposManipulacao from "@/models/enum/tiposItemManipulacao";
import TiposItemManipulacao from "@/models/enum/tiposItemManipulacao";
import TiposProduto from "@/models/enum/tiposProduto";
import FormulaPadraoItemModel from "@/models/formulaPadraoItemModel";
import KitEmbalagemModel from "@/models/kitEmbalagemModel";
import PaginationModel from "@/models/paginationModel";
import ProdutoModel from "@/models/produto/produtoModel";
import UnidadeMedidaModel from "@/models/unidadeMedidaModel";
import IndicesConversoesUnidadeMedidasService from "@/services/indicesConversoesUnidadeMedidaService";
import KitEmbalagemService from "@/services/kitEmbalagemService";
import ProdutoService from "@/services/produto/produtoService";
import UnidadeMedidaService from "@/services/unidadeMedidaService";
import { AppState, Mutations } from "@/store/store";
import arithmeticHelper from "@/utils/common/arithmeticHelper";
import { getProdutoComboText } from "@/utils/common/combo/combotext";
import Delay from "@/utils/common/delay";

import "./item.scss";

@Component({
    components: {
        comboComponent,
        decimalComponent,
        fieldsetComponent,
        dateTimePickerComponent,
        decimalComSinalCustomComponent,
        buttonIncluirComponent,
    },
    computed: mapState<AppState>({
        preLoadPack: state => state.preLoad.preLoadList,
        loadedList: state => state.preLoad.loadedList,
    }),
    methods: mapMutations(["LOAD_LIST"] as Mutations),
})
export default class FormulaPadraoItemComponent extends Vue {
    // State computed props
    preLoadPack: PreLoadPackModel;
    loadedList: boolean;

    private produtoService = new ProdutoService();
    private kitEmbalagemService = new KitEmbalagemService();
    private possuiKit = false;
    private adicionarKit = false;

    private kitEmbalagemModel = new KitEmbalagemModel();
    private kitsModels: Array<KitEmbalagemModel> = [];
    private indicesConversoesUnidadeMedidasService = new IndicesConversoesUnidadeMedidasService();

    model = new FormulaPadraoItemModel();
    private tipoItem: TiposProduto = null;

    private updateMode = false;

    private changeProdutoId = false;
    private produtoId = null;

    produtosOptions: Array<Object> = [];
    unidadesMedidaManipulacaoOptions: Array<Object> = [];
    unidadeMedidaOptions: Array<Object> = [];
    tiposManipulacaoOptions: Array<Object> = [
        { text: "Todos", value: 99 },
        { text: "Capsula", value: TiposProduto.Capsula },
        { text: "Embalagem", value: TiposProduto.Embalagem },
        { text: "Materia Prima", value: TiposProduto.MateriaPrima },
        { text: "Semi-Acabados", value: TiposProduto.SemiAcabado },
    ];
    tiposCalculoOptions: Array<Object> = [
        { text: "Nenhum", value: TiposCalculoManipulacao.Nenhum },
        { text: "Unitario", value: TiposCalculoManipulacao.Unitario },
        { text: "Dose", value: TiposCalculoManipulacao.Dose },
        { text: "Percentual", value: TiposCalculoManipulacao.Percentual },
        { text: "Qsp", value: TiposCalculoManipulacao.Qsp },
        { text: "DH", value: TiposCalculoManipulacao.DH },
        { text: "CH", value: TiposCalculoManipulacao.CH },
    ];

    @Prop(Number) quantidadeManipulacao: number;
    @Prop({ type: Number, required: true }) volumeFormulaPadrao: number;
    @Prop({ type: Number, default: 0 }) unidadeMedidaFormulaPadraoId: number;
    @Prop({ type: Number, default: null }) formaFarmaceuticaId: number;

    private getTipoItemManipulacaoPeloTipoProduto() {
        const tipoProduto = this.tipoItem != null ? this.tipoItem : TiposProduto.MateriaPrima;
        let tipoItemManipulacao = TiposItemManipulacao.MateriaPrima;

        switch (tipoProduto) {
            case TiposProduto.MateriaPrima:
                tipoItemManipulacao = TiposItemManipulacao.MateriaPrima;
                break;
            case TiposProduto.Embalagem:
                tipoItemManipulacao = TiposItemManipulacao.Embalagem;
                break;
            case TiposProduto.Capsula:
                tipoItemManipulacao = TiposItemManipulacao.Capsula;
                break;
            case TiposProduto.SemiAcabado:
                tipoItemManipulacao = TiposItemManipulacao.MateriaPrima;
                break;
            default:
                tipoItemManipulacao = TiposItemManipulacao.MateriaPrima;
        }

        return tipoItemManipulacao;
    }

    get showDose() {
        return this.model.tipoCalculo == TiposCalculoManipulacao.Dose;
    }

    private getTooltipUnidadeMedidaDose(unDose: number) {
        if (unDose != null && unDose > 0) {
            const unMed = this.unidadeMedidaOptions.filter(p => p["value"] == this.unidadeMedidaFormulaPadraoId);
            if (unMed.length > 0) {
                return this.unidadeMedidaOptions.filter(p => p["value"] == this.unidadeMedidaFormulaPadraoId)[0][
                    "text"
                ];
            }
        }

        return "";
    }

    loadProdutos() {
        const tipoProduto = this.tipoItem != null ? this.tipoItem : TiposProduto.MateriaPrima;

        switch (tipoProduto) {
            case TiposProduto.MateriaPrima:
                this.model.tipoItem = TiposItemManipulacao.MateriaPrima;
                break;
            case TiposProduto.Embalagem:
                this.model.tipoItem = TiposItemManipulacao.Embalagem;
                break;
            case TiposProduto.Capsula:
                this.model.tipoItem = TiposItemManipulacao.Capsula;
                break;
            case TiposProduto.SemiAcabado:
                this.model.tipoItem = TiposItemManipulacao.MateriaPrima;
                break;
            default:
                this.model.tipoItem = TiposItemManipulacao.MateriaPrima;
        }

        let produtosModel;

        if (Number(this.tipoItem) == 99) {
            produtosModel = this.preLoadPack.produtos;
        } else {
            produtosModel = this.preLoadPack.produtos.filter(p => p.tipoProduto == this.tipoItem);
        }

        const produtosCompletos = [];

        produtosModel.forEach(p => {
            produtosCompletos.push({
                value: p.id,
                text: getProdutoComboText(p, false),
            });

            p.produtoSinonimos?.forEach(s => {
                const produtoFake = { ...p } as ProdutoModel;
                produtoFake.descricao = s.sinonimo;
                produtosCompletos.push({
                    value: p.id * s.id,
                    produtoId: p.id,
                    sinonimoId: s.id,
                    text: getProdutoComboText(produtoFake, false),
                });
            });
        });

        this.produtosOptions = produtosCompletos;
    }

    private async incluirItem() {
        try {
            const isValid = await this.$validator.validateAll();
            if (isValid) {
                if (this.possuiKit) {
                    await this.addKitQuestion();

                    if (this.kitEmbalagemModel.id > 0) {
                        this.$emit("inclusao-kitEmbalagem", this.kitEmbalagemModel.id);
                    }
                }
                this.$emit("inclusao-item", this.getModel());

                if (this.adicionarKit) {
                    await this.addKitItens();
                }

                this.clear();
            }
        } catch {}
    }

    private async addKitItens() {
        const quantidade = arithmeticHelper.round(this.model.quantidadeDose, 8);
        const fase = this.model.faseUso;

        if (this.kitEmbalagemModel != null) {
            const itensPadrao = this.kitEmbalagemModel.kitEmbalagemItens.filter(p => p.produtoPadrao);
            for (let i = 0; i < itensPadrao.length; i++) {
                const modelKitItem = new FormulaPadraoItemModel();
                // Mantem os mesmos dados para todos os itens de embalagem
                modelKitItem.tipoCalculo = this.model.tipoCalculo;
                modelKitItem.volumeDose = this.model.volumeDose;

                this.clear();

                this.model.produtoId = itensPadrao[i].produtoId;
                await this.carregarProduto(true);
                this.model.tipoItem = TiposItemManipulacao.Embalagem;
                this.model.tipoCalculo = modelKitItem.tipoCalculo;
                this.model.volumeDose = modelKitItem.volumeDose;
                modelKitItem.updateFrom(this.model);
                modelKitItem.formulaPadraoId = this.model.id;
                modelKitItem.produtoDescricao = itensPadrao[i].produtoDescricao;
                modelKitItem.unidadeMedidaDescricao = this.preLoadPack.unidadesMedida.filter(
                    p => p.id == this.model.unidadeMedidaId,
                )[0].descricao;
                modelKitItem.unidadeMedidaManipulacaoDescricao = this.preLoadPack.unidadesMedida.filter(
                    p => p.id == this.model.unidadeMedidaManipulacaoId,
                )[0].descricao;
                modelKitItem.tipoItem = TiposItemManipulacao.Embalagem;
                modelKitItem.tipoItemDescricao = "Embalagem";
                modelKitItem.faseUso = fase;
                modelKitItem.quantidadeDose = itensPadrao[i].quantidade * quantidade;
                modelKitItem.quantidade = modelKitItem.quantidadeDose;

                const tipoCalc = this.tiposCalculoOptions.filter(p => p["value"] == this.model.tipoCalculo);
                modelKitItem.tipoCalculoDescricao = tipoCalc.length > 0 ? tipoCalc[0]["text"] : "";
                this.$emit("inclusao-item", modelKitItem);
            }
        }
    }

    private async addKitQuestion() {
        this.adicionarKit = false;
        const inputOptions = this.kitsModels.reduce(
            (result, p, index) => ({
                ...result,
                [index]: p.descricao,
            }),
            {},
        );
        this.possuiKit = false;

        if (this.kitsModels.length > 0) {
            const response = await this.$showAlert({
                title: this.$t("__.ts.escolhaKitParaVincu") as string,
                text: this.$t("__.ts.itensKitAddAuto") as string,
                icon: "question",
                html: this.$t("__.ts.vincularKitEmb") as string,
                input: "select",
                inputOptions: inputOptions,
                inputValidator: result => {
                    if (result != null && result != "") {
                        return "";
                    }
                    return this.$t("__.ts.casoNaoQueiraKit") as string;
                },
                showCancelButton: true,
                confirmButtonText: this.$t("__.ts.sim") as string,
                cancelButtonText: this.$t("__.ts.nao") as string,
            });

            if (response.isConfirmed) {
                this.kitEmbalagemModel = new KitEmbalagemModel();
                this.kitEmbalagemModel = this.kitsModels[response.value];
                this.kitEmbalagemModel.kitEmbalagemItens = this.kitEmbalagemModel.kitEmbalagemItens.filter(
                    p => p.produtoId != this.model.produtoId,
                );
                this.adicionarKit = true;
            }
        }
    }

    private getModel() {
        const produtoElement = this.$el.querySelector("#produtoItemId") as HTMLSelectElement;
        const unidadeMedidaManipulacaoElement = this.$el.querySelector(
            "#unidadeMedidaManipulacaoItemId",
        ) as HTMLSelectElement;
        const tipoItemElement = this.$el.querySelector("#tipoItem") as HTMLSelectElement;

        const model = new FormulaPadraoItemModel();
        model.updateFrom(this.model);
        model.formulaPadraoId = this.model.id;

        const produto = this.preLoadPack.produtos.filter(p => p.id == this.model.produtoId);

        let produtoDescricao = produtoElement.innerText;

        if (produto.length > 0) {
            produtoDescricao = produto[0].descricao;

            if (this.model.sinonimoId && produto[0].produtoSinonimos.some(p => p.id == this.model.sinonimoId)) {
                produtoDescricao = produto[0].produtoSinonimos.find(p => p.id == this.model.sinonimoId).sinonimo;
            }
        }

        model.produtoDescricao = produtoDescricao; //produtoElement.innerText;
        model.unidadeMedidaDescricao = this.preLoadPack.unidadesMedida.filter(
            p => p.id == this.model.unidadeMedidaId,
        )[0].descricao;
        model.unidadeMedidaManipulacaoDescricao = unidadeMedidaManipulacaoElement.innerText;
        model.tipoItemDescricao = tipoItemElement.innerText;
        model.tipoItem = this.getTipoItemManipulacaoPeloTipoProduto();

        const tipoCalc = this.tiposCalculoOptions.filter(p => p["value"] == this.model.tipoCalculo);
        model.tipoCalculoDescricao = tipoCalc.length > 0 ? tipoCalc[0]["text"] : "";
        model.sinonimoId = this.model.sinonimoId;
        model.desmembrado = false;

        return model;
    }

    public clear() {
        this.initializeModel();
    }

    private initializeModel() {
        this.model = new FormulaPadraoItemModel();

        this.model.tipoItem = TiposManipulacao.MateriaPrima;
        this.model.tipoCalculo = TiposCalculoManipulacao.Nenhum;
        this.model.quantidadeDose = 0;
        this.model.quantidade = 0;
        this.produtoId = null;
    }

    private calculoUnitario(item: FormulaPadraoItemModel, quantidade: number) {
        let qtde = item.quantidadeDose * (quantidade ?? 1);

        if (item.fatorCorrecao) {
            qtde *= item.fatorCorrecao;
        }

        return arithmeticHelper.round(qtde, 4);
    }

    private calculoPercentual(item: FormulaPadraoItemModel, unidadeMedida: number, volume: number) {
        item.unidadeMedidaManipulacaoId = unidadeMedida;

        let qtde = (item.quantidadeDose / 100) * volume;

        if (item.fatorCorrecao) {
            qtde *= item.fatorCorrecao;
        }

        return arithmeticHelper.round(qtde, 4);
    }

    public async calcularQuantidadeItem(item: FormulaPadraoItemModel, quantidadeManipulacao: number) {
        let quantidade = 0;
        let converter = true;

        switch (item.tipoCalculo) {
            case TiposCalculoManipulacao.Unitario:
                quantidade = this.calculoUnitario(item, quantidadeManipulacao);
                break;
            case TiposCalculoManipulacao.Percentual:
                quantidade = this.calculoPercentual(item, this.unidadeMedidaFormulaPadraoId, this.volumeFormulaPadrao);
                break;
            case TiposCalculoManipulacao.Qsp:
                item.quantidadeDose = 0;
                break;
            default:
                converter = false;
                const unidade = this.preLoadPack.unidadesMedida.filter(
                    p => p.descricao == "Unidade" || p.sigla == "un",
                );
                let isUnidade = false;
                if (unidade.length > 0) {
                    isUnidade = unidade[0].id == item.unidadeMedidaManipulacaoId;
                }

                const indiceConversao = isUnidade
                    ? 1
                    : await this.getIndiceConversao(
                          item.unidadeMedidaManipulacaoId,
                          item.produtoUnidadeMedidaEstoqueId,
                          item.produtoDensidade,
                          item.produtoId,
                      );

                item.quantidade = arithmeticHelper.round(item.quantidadeDose * indiceConversao, 8);
        }

        if (converter) {
            const indice = await this.getIndiceConversao(
                item.unidadeMedidaManipulacaoId,
                item.produtoUnidadeMedidaEstoqueId,
                item.produtoDensidade,
                item.produtoId,
            );

            if (quantidade > 0) {
                item.quantidade = quantidade * indice;
            }
        }

        return item;
    }

    private async getIndiceConversao(
        unidadeMedidaOrigem: number,
        unidadeMedidaDestino: number,
        densidade: number,
        produtoId: number,
    ) {
        const indice = await this.indicesConversoesUnidadeMedidasService.getIndiceConversao(
            unidadeMedidaOrigem,
            unidadeMedidaDestino,
            densidade,
            produtoId,
        );
        return indice > 0 ? indice : 1;
    }

    //@ts-ignore
    @Watch("model.tipoCalculo")
    private async onTipoCalculoChanged() {
        if (this.model.tipoCalculo == TiposCalculoManipulacao.Qsp) {
            const buscarUnidadesPadroes = u =>
                u.descricao.toUpperCase() == "GRAMA" ||
                u.sigla.toUpperCase() == "G" ||
                u.descricao.toUpperCase() == "MILILITRO" ||
                u.sigla.toUpperCase() == "ML";
            this.loadUnidadesMedidaManipulacao(buscarUnidadesPadroes);
        } else {
            this.loadUnidadesMedidaManipulacao();
        }

        this.model = await this.calcularQuantidadeItem(this.model, this.quantidadeManipulacao);
    }

    private async loadUnidadesMedidaManipulacao(filtro = null) {
        try {
            const data = await new UnidadeMedidaService()
                .combo()
                .resolveWithJSON<PaginationModel<UnidadeMedidaModel>>();

            if (filtro != null) {
                data.list = data.list.filter(filtro);
            }

            this.unidadesMedidaManipulacaoOptions = data.list.map(item => ({
                value: item.id,
                text: item.descricao,
                sigla: item.sigla,
                type: item.unidadeMedida,
            }));
        } catch {}
    }

    //@ts-ignore
    @Watch("model.quantidadeDose")
    private async onQuantidadeDoseChanged() {
        this.model = await this.calcularQuantidadeItem(this.model, this.quantidadeManipulacao);
    }

    @Watch("volumeFormulaPadrao")
    private async onVolumeTotalChanged() {
        this.model = await this.calcularQuantidadeItem(this.model, this.quantidadeManipulacao);
    }

    @Watch("quantidadeManipulacao")
    private async onChangeQuantidadeManipulacao() {
        this.model = await this.calcularQuantidadeItem(this.model, this.quantidadeManipulacao);
    }

    //@ts-ignore
    @Watch("model.fatorCorrecao")
    private async onFatorCorrecaoChanged() {
        this.model = await this.calcularQuantidadeItem(this.model, this.quantidadeManipulacao);
    }

    //@ts-ignore
    @Watch("tipoItem")
    private onTipoItemChanged() {
        this.loadProdutos();
    }

    //@ts-ignore
    @Watch("produtoId")
    private onChangeProdutoId(newValue: number, oldValue: number) {
        if (!newValue || newValue == oldValue) {
            return;
        }

        const oldProdutoId = this.model.produtoId;
        const oldSinonimoId = this.model.sinonimoId;

        const produtos = this.produtosOptions.filter(p => p["value"] == this.produtoId);
        this.changeProdutoId = true;

        if (produtos.length > 0 && produtos[0]["sinonimoId"]) {
            this.model.produtoId = produtos[0]["produtoId"];
            this.model.sinonimoId = produtos[0]["sinonimoId"];
            this.model.produtoDescricao = produtos[0]["text"];
        } else {
            this.model.produtoId = this.produtoId;
            this.model.sinonimoId = null;
        }

        // Se trocou de sinonimo para outro sinonimo do mesmo produto ou para o proprio produto deve recarregar os dados
        if (
            (this.model.sinonimoId || oldSinonimoId) &&
            this.model.produtoId == oldProdutoId &&
            this.model.sinonimoId != oldSinonimoId
        ) {
            this.carregarProduto();
        }
    }

    //@ts-ignore
    @Watch("model.produtoId")
    private onProdutoIdChanged(newValue: number, oldValue: number) {
        if (!newValue || newValue == oldValue) {
            return;
        }

        this.loadProdutoId();

        this.carregarProduto();
    }

    //@ts-ignore
    @Watch("model.unidadeMedidaManipulacaoId")
    private async onUnidadeMedidaManipulacaoIdChanged(newValue: number) {
        if (!newValue || newValue == 0) return;

        this.model = await this.calcularQuantidadeItem(this.model, this.quantidadeManipulacao);
    }

    private loadProdutoId() {
        if (!this.changeProdutoId) {
            if (this.model.sinonimoId && this.model.produtoId) {
                this.produtoId = this.model.sinonimoId * this.model.produtoId;
            } else {
                this.produtoId = this.model.produtoId;
            }
        }
        this.changeProdutoId = false;
    }

    private async carregarProduto(produtoKit = false) {
        let verificarKitEmbalagem = false;

        try {
            const data = await this.produtoService
                .get(this.model.produtoId)
                .withLoading()
                .resolveWithJSON<ProdutoModel>();

            this.model.fatorCorrecao = null;
            if (data.fatorCorrecao) {
                this.model.fatorCorrecao = data.fatorCorrecao;
            }

            if (data.tipoCalculo && !this.updateMode) {
                this.model.tipoCalculo = data.tipoCalculo;
            }

            if (data.subGrupo == SubGrupoProduto.Recipiente && !produtoKit && !this.updateMode) {
                //verificar se possui kit embalagem
                verificarKitEmbalagem = true;
            }
            if (data.densidade) {
                this.model.produtoDensidade = data.densidade;
            }

            this.model.produtoUnidadeMedidaEstoqueId = data.unidadeMedidaEstoqueId;
            this.model.unidadeMedidaId = data.unidadeMedidaEstoqueId;

            if (!this.updateMode) {
                this.model.unidadeMedidaManipulacaoId = data.unidadeMedidaManipulacaoId;
            }

            let tipoItemManipulacao = TiposItemManipulacao.MateriaPrima;
            switch (data.tipoProduto) {
                case TiposProduto.MateriaPrima:
                    tipoItemManipulacao = TiposItemManipulacao.MateriaPrima;
                    break;
                case TiposProduto.Embalagem:
                    tipoItemManipulacao = TiposItemManipulacao.Embalagem;
                    break;
                case TiposProduto.Capsula:
                    tipoItemManipulacao = TiposItemManipulacao.Capsula;
                    break;
                case TiposProduto.SemiAcabado:
                    tipoItemManipulacao = TiposItemManipulacao.MateriaPrima;
                    break;
                default:
                    tipoItemManipulacao = TiposItemManipulacao.MateriaPrima;
            }
            this.model.tipoItem = tipoItemManipulacao;

            if (!produtoKit) {
                this.kitsModels = null;
                this.kitEmbalagemModel = null;
                this.possuiKit = false;
                if (verificarKitEmbalagem) {
                    //buscar kit embalagem para este recipiente
                    await this.carregarKitEmbalagem();
                }
            }
        } catch {}
    }

    private async carregarKitEmbalagem() {
        this.possuiKit = false;

        try {
            const data = await this.kitEmbalagemService
                .getKitByRecipiente(this.model.produtoId, this.formaFarmaceuticaId)
                .withLoading()
                .resolveWithJSON<KitEmbalagemModel[]>();

            this.possuiKit = data.length > 0;
            this.kitsModels = data;
        } catch {}
    }

    private async getPreLoadPack() {
        while (!this.loadedList) await Delay(100);

        this.unidadesMedidaManipulacaoOptions = this.preLoadPack.unidadesMedida.map(item => ({
            value: item.id,
            text: item.descricao,
            sigla: item.sigla,
            type: item.unidadeMedida,
        }));
    }

    public async refazModel(model: FormulaPadraoItemModel) {
        this.updateMode = true;
        this.model.updateFrom(model);

        await this.carregarProduto();
        this.model = await this.calcularQuantidadeItem(this.model, this.quantidadeManipulacao);

        const modelRetorno = new FormulaPadraoItemModel();
        modelRetorno.updateFrom(this.model);

        modelRetorno.formulaPadraoId = model.formulaPadraoId;

        const produto = this.preLoadPack.produtos.filter(p => p.id == this.model.produtoId);
        let produtoDescricao = "";

        if (produto.length > 0) {
            produtoDescricao = produto[0].descricao;

            if (produto[0].sinonimos != null && produto[0].sinonimos != "") {
                produtoDescricao = produto[0].sinonimos;
            }
        }

        modelRetorno.produtoDescricao = produtoDescricao;

        modelRetorno.unidadeMedidaDescricao = this.preLoadPack.unidadesMedida.filter(
            p => p.id == this.model.unidadeMedidaId,
        )[0].descricao;
        modelRetorno.unidadeMedidaManipulacaoDescricao = this.preLoadPack.unidadesMedida.filter(
            p => p.id == this.model.unidadeMedidaManipulacaoId,
        )[0].descricao;

        modelRetorno.tipoItemDescricao = EnumExtensions.getNameByValue(TiposManipulacao, modelRetorno.tipoItem);

        const tipoCalc = this.tiposCalculoOptions.filter(p => p["value"] == this.model.tipoCalculo);
        modelRetorno.tipoCalculoDescricao = tipoCalc.length > 0 ? tipoCalc[0]["text"] : "";

        this.updateMode = false;
        return modelRetorno;
    }

    private mounted() {
        this.initializeModel();

        this.getPreLoadPack();
    }
}
