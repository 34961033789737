import { Component } from "@/decorators";
import ProdutoDciModel from "@/models/produto/produtoDciModel";
import ProdutoDciService from "@/services/produto/produtoDciService";

import EditComponentBase from "../editComponentBase";

@Component
export default class ProdutoDciEditComponent extends EditComponentBase<ProdutoDciModel> {
    private async beforeMount() {
        await this.setProps(new ProdutoDciService(), "dcis", new ProdutoDciModel());
    }
}
