import BaseModel from "./base/baseModel";
import TypeNotification from "./enum/typeNotification";

export default class NotificationModel extends BaseModel {
    id?: number = null;
    title: string = null;
    text: string = null;
    type: TypeNotification = TypeNotification.Info;
    readed = false;
    pathLink: string = null;
    usuarioDestinoId?: number = null;
    franquiaId?: number = null;
    franquiaNome = "";
}
