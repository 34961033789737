import ProdutoModel, { ProdutoListParameters } from "../../models/produto/produtoModel";
import nfetch from "../../utils/events/nfetch";
import { IService } from "../base/iService";

export default class ProdutoService extends IService<ProdutoModel> {
    constructor() {
        super("Produto");
    }

    public listFilterProdutoTipo(tipoProduto: number, pageIndex: number, pageSize: number): Promise<Response> {
        return nfetch(
            `/${this.controllerName}/ListFilterTipoProduto?TipoProduto=${tipoProduto}&pageIndex=${pageIndex}&pageSize=${pageSize}`,
            {
                credentials: "same-origin",
            },
        );
    }

    public listProdutoByDescricao(descricao: string): Promise<Response> {
        return nfetch(`/${this.controllerName}/ListByDescricao?descricao=${descricao}`, {
            credentials: "same-origin",
        });
    }

    public listIgnoreFilterProdutoTipo(pageIndex: number, pageSize: number, tipoProduto?: number): Promise<Response> {
        return nfetch(
            `/${this.controllerName}/ListIgnoreFilterTipoProduto?pageIndex=${pageIndex}&pageSize=${pageSize}&TipoProduto=${tipoProduto}`,
            {
                credentials: "same-origin",
            },
        );
    }

    public replicar(idProduto: number, idFranquias: string, filtroVinculos: number): Promise<Response> {
        return nfetch(
            `/${this.controllerName}/Replicar?idProduto=${idProduto}&idFranquias=${idFranquias}&filtroVinculos=${filtroVinculos}`,
            {
                method: "post",
                credentials: "same-origin",
                headers: {
                    "Content-Type": "application/json; charset=UTF-8",
                    "XSRF-TOKEN": this.getCookie("XSRF-TOKEN"),
                },
            },
        );
    }

    public getByParent(parentId: number, franquiaId: number): Promise<Response> {
        //@ts-ignore
        return nfetch(`/${this.controllerName}/GetByParentId?parentId=${parentId}&franquiaId=${franquiaId}`, {
            credentials: "same-origin",
        })
            .then((response: Response) => {
                if (response.ok) {
                    return response;
                } else {
                    return this.mensagemErro(response, "Get");
                }
            })
            .catch(() => {});
    }

    public atualizarValorVenda(): Promise<Response> {
        return nfetch(`/${this.controllerName}/AtualizarValorVenda`, {
            method: "post",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json; charset=UTF-8",
                "XSRF-TOKEN": this.getCookie("XSRF-TOKEN"),
            },
        });
    }

    public validateIncompatibilidades(produtosIds: number[], formaFarmaceuticaId: number): Promise<Response> {
        return nfetch(`/${this.controllerName}/ValidateIncompatibilidades?formaFarmaceuticaId=${formaFarmaceuticaId}`, {
            method: "post",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json; charset=UTF-8",
                "XSRF-TOKEN": this.getCookie("XSRF-TOKEN"),
            },
            body: JSON.stringify(produtosIds),
        });
    }

    public validateIncompatibilidadesKitEmbalagem(produtosIds: number[], kitEmbalagemId: number): Promise<Response> {
        return nfetch(
            `/${this.controllerName}/ValidateIncompatibilidadesKitEmbalagem?kitEmbalagemId=${kitEmbalagemId}`,
            {
                method: "post",
                credentials: "same-origin",
                headers: {
                    "Content-Type": "application/json; charset=UTF-8",
                    "XSRF-TOKEN": this.getCookie("XSRF-TOKEN"),
                },
                body: JSON.stringify(produtosIds),
            },
        );
    }

    public getAliquotaICMSInterestadual(produtoId: number, ufDestino: string): Promise<Response> {
        return nfetch(
            `/${this.controllerName}/GetAliquotaICMSInterestadual?produtoId=${produtoId}&ufDestino=${ufDestino}`,
            {
                credentials: "same-origin",
            },
        );
    }

    public getAliquotasICMSInterestadualDefault(): Promise<Response> {
        return nfetch(`/${this.controllerName}/GetAliquotasICMSInterestadualDefault`, {
            credentials: "same-origin",
        });
    }

    public getAliquotaInterestadualByNCM(ncm: string, produtoId: number, ufDestino: string): Promise<Response> {
        return nfetch(
            `/${this.controllerName}/GetAliquotaInterestadualByNCM?ncm=${ncm}&produtoId=${produtoId}&ufDestino=${ufDestino}`,
            {
                credentials: "same-origin",
            },
        );
    }

    public getProdutosByIds(produtosIds: number[]): Promise<Response> {
        return nfetch(`/${this.controllerName}/GetProdutosByIds`, {
            method: "post",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json; charset=UTF-8",
                "XSRF-TOKEN": this.getCookie("XSRF-TOKEN"),
            },
            body: JSON.stringify(produtosIds),
        });
    }

    public getCamposDescricao(): Promise<Response> {
        return nfetch(`/${this.controllerName}/GetCampos`, {
            credentials: "same-origin",
        });
    }

    public get(id: number, quantidadeMinima = 0.1, tratarValores = true): Promise<Response> {
        //@ts-ignore
        return nfetch(
            `/${this.controllerName}/GetById?id=${id}&quantidadeMinima=${quantidadeMinima}&tratarValores=${tratarValores}`,
            {
                credentials: "same-origin",
            },
        )
            .then((response: Response) => {
                if (response.ok) {
                    return response;
                } else {
                    return this.mensagemErro(response, "Get");
                }
            })
            .catch(() => {});
    }

    public comboOnlyAtivos(): Promise<Response> {
        const params: ProdutoListParameters = {
            somenteAtivos: true,
        };
        return this.list("", "descricao", "asc", 1, 999999, params);
    }

    public trocarUnidadeMedidaEstoque(produtoId: number, unidadeMedidaIdDestino: number): Promise<Response> {
        return nfetch(
            `/${this.controllerName}/TrocarUnidadeMedidaEstoque?produtoId=${produtoId}&unidadeMedidaIdDestino=${unidadeMedidaIdDestino}`,
            {
                method: "post",
                credentials: "same-origin",
            },
        );
    }

    public calcularValorVenda(produto: ProdutoModel): Promise<Response> {
        return nfetch(`/${this.controllerName}/CalcularValorVenda`, {
            method: "post",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json; charset=UTF-8",
            },
            body: JSON.stringify(produto),
        });
    }

    public calcularMarkupByValorVenda(produto: ProdutoModel): Promise<Response> {
        return nfetch(`/${this.controllerName}/CalcularMarkupByValorVenda`, {
            method: "post",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json; charset=UTF-8",
            },
            body: JSON.stringify(produto),
        });
    }

    public updateValorVendaProdutosMarkupPadrao(): Promise<Response> {
        return nfetch(`/${this.controllerName}/UpdateValorVendaProdutosMarkupPadrao`, {
            method: "post",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json; charset=UTF-8",
            },
        });
    }
}
