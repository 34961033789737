var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "crud crud-edit" },
    [
      _vm.loaded
        ? _c(
            "form",
            { attrs: { novalidate: "novalidate" } },
            [
              _c(
                "fieldset-component",
                {
                  staticStyle: { "margin-top": "15px" },
                  attrs: { title: "Emitente", collapsed: true },
                },
                [
                  _c(
                    "div",
                    { attrs: { slot: "rows" }, slot: "rows" },
                    [
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-sm-4" }, [
                          _c("div", { staticClass: "form-group" }, [
                            _c(
                              "label",
                              {
                                staticClass: "control-label",
                                attrs: { for: "nomeEmitente" },
                              },
                              [_vm._v("Nome")]
                            ),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.model.emit.xNome,
                                  expression: "model.emit.xNome",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: {
                                type: "text",
                                id: "nomeEmitente",
                                name: "nomeEmitente",
                                disabled: "",
                              },
                              domProps: { value: _vm.model.emit.xNome },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.model.emit,
                                    "xNome",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-sm-4" }, [
                          _c("div", { staticClass: "form-group" }, [
                            _c(
                              "label",
                              {
                                staticClass: "control-label",
                                attrs: { for: "fantasiaEmitente" },
                              },
                              [_vm._v("Fantasia")]
                            ),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.model.emit.xFant,
                                  expression: "model.emit.xFant",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: {
                                type: "text",
                                id: "fantasiaEmitente",
                                name: "fantasiaEmitente",
                                disabled: "",
                              },
                              domProps: { value: _vm.model.emit.xFant },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.model.emit,
                                    "xFant",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-sm-2" }, [
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "control-label",
                                  attrs: { for: "cnpjEmitente" },
                                },
                                [_vm._v("CPF/CNPJ")]
                              ),
                              _vm._v(" "),
                              _c("cnpj-component", {
                                staticClass: "form-control",
                                attrs: {
                                  id: "cnpjEmitente",
                                  name: "cnpjEmitente",
                                  disabled: "",
                                },
                                model: {
                                  value: _vm.model.emit.CNPJ,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.model.emit, "CNPJ", $$v)
                                  },
                                  expression: "model.emit.CNPJ",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-sm-2" }, [
                          _c("div", { staticClass: "form-group" }, [
                            _c(
                              "label",
                              {
                                staticClass: "control-label",
                                attrs: { for: "IEEmitente" },
                              },
                              [_vm._v("Inscrição Estadual")]
                            ),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.model.emit.IE,
                                  expression: "model.emit.IE",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: {
                                type: "text",
                                id: "IEEmitente",
                                name: "IEEmitente",
                                disabled: "",
                              },
                              domProps: { value: _vm.model.emit.IE },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.model.emit,
                                    "IE",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("endereco-component", {
                        attrs: {
                          data: _vm.model.emit.enderEmit,
                          nome: "Emitente",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "fieldset-component",
                { attrs: { title: "Destinatário", collapsed: true } },
                [
                  _c(
                    "div",
                    { attrs: { slot: "rows" }, slot: "rows" },
                    [
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-sm-4" }, [
                          _c("div", { staticClass: "form-group" }, [
                            _c(
                              "label",
                              {
                                staticClass: "control-label",
                                attrs: { for: "nomeDestinatario" },
                              },
                              [_vm._v("Nome")]
                            ),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.model.dest.xNome,
                                  expression: "model.dest.xNome",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: {
                                type: "text",
                                id: "nomeDestinatario",
                                name: "nomeDestinatario",
                                disabled: "",
                              },
                              domProps: { value: _vm.model.dest.xNome },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.model.dest,
                                    "xNome",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-sm-4" }, [
                          _c("div", { staticClass: "form-group" }, [
                            _c(
                              "label",
                              {
                                staticClass: "control-label",
                                attrs: { for: "fantasiaDestinatario" },
                              },
                              [_vm._v("Fantasia")]
                            ),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.model.dest.xFant,
                                  expression: "model.dest.xFant",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: {
                                type: "text",
                                id: "fantasiaDestinatario",
                                name: "fantasiaDestinatario",
                                disabled: "",
                              },
                              domProps: { value: _vm.model.dest.xFant },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.model.dest,
                                    "xFant",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-sm-2" }, [
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "control-label",
                                  attrs: { for: "cnpjDestinatario" },
                                },
                                [_vm._v("CPF/CNPJ")]
                              ),
                              _vm._v(" "),
                              _vm.model.dest.CPF
                                ? _c("cpf-component", {
                                    staticClass: "form-control",
                                    attrs: {
                                      id: "cpfDestinatario",
                                      name: "cpfDestinatario",
                                      disabled: "",
                                    },
                                    model: {
                                      value: _vm.model.dest.CPF,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.model.dest, "CPF", $$v)
                                      },
                                      expression: "model.dest.CPF",
                                    },
                                  })
                                : _c("cnpj-component", {
                                    staticClass: "form-control",
                                    attrs: {
                                      id: "cnpjDestinatario",
                                      name: "cnpjDestinatario",
                                      disabled: "",
                                    },
                                    model: {
                                      value: _vm.model.dest.CNPJ,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.model.dest, "CNPJ", $$v)
                                      },
                                      expression: "model.dest.CNPJ",
                                    },
                                  }),
                            ],
                            1
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-sm-2" }, [
                          _c("div", { staticClass: "form-group" }, [
                            _c(
                              "label",
                              {
                                staticClass: "control-label",
                                attrs: { for: "IEDestinatario" },
                              },
                              [_vm._v("Inscrição Estadual")]
                            ),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.model.dest.IE,
                                  expression: "model.dest.IE",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: {
                                type: "text",
                                id: "IEDestinatario",
                                name: "IEDestinatario",
                                disabled: "",
                              },
                              domProps: { value: _vm.model.dest.IE },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.model.dest,
                                    "IE",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("endereco-component", {
                        attrs: {
                          data: _vm.model.dest.enderDest,
                          nome: "Destinatario",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "fieldset-component",
                { attrs: { title: "Itens", collapsed: false } },
                [
                  _c(
                    "div",
                    { attrs: { slot: "rows" }, slot: "rows" },
                    [
                      _c("grid-component", {
                        attrs: {
                          data: _vm.gridData,
                          columns: _vm.gridColumns,
                          "show-action-column": true,
                          showAddNewButton: false,
                          "show-table-head": false,
                          "show-table-foot": false,
                          showSortIcons: false,
                          subColumns: _vm.gridColumnsVinculos,
                          subDataName: "impostos",
                          showExpand: true,
                          conditionShowSubData: _vm.conditionShowSubData,
                          "show-remove-item": false,
                          "show-edit-item": true,
                        },
                        on: { "edit-item": _vm.onEditItem },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "fieldset-component",
                { attrs: { title: "Totais", collapsed: false } },
                [
                  _c("div", { attrs: { slot: "rows" }, slot: "rows" }, [
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-sm-2" }, [
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c(
                              "label",
                              {
                                staticClass: "control-label",
                                attrs: { for: "totalNota" },
                              },
                              [_vm._v("Total da nota")]
                            ),
                            _vm._v(" "),
                            _c("moeda-component", {
                              staticClass: "form-control",
                              attrs: {
                                readonly: "",
                                id: "totalNota",
                                name: "totalNota",
                                disabled: "",
                              },
                              model: {
                                value: _vm.model.total.ICMSTot.vNF,
                                callback: function ($$v) {
                                  _vm.$set(_vm.model.total.ICMSTot, "vNF", $$v)
                                },
                                expression: "model.total.ICMSTot.vNF",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-sm-2" }, [
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c(
                              "label",
                              {
                                staticClass: "control-label",
                                attrs: { for: "valorProdutos" },
                              },
                              [_vm._v("Valor Produtos")]
                            ),
                            _vm._v(" "),
                            _c("moeda-component", {
                              staticClass: "form-control",
                              attrs: {
                                readonly: "",
                                id: "valorProdutos",
                                name: "valorProdutos",
                                disabled: "",
                              },
                              model: {
                                value: _vm.model.total.ICMSTot.vProd,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.model.total.ICMSTot,
                                    "vProd",
                                    $$v
                                  )
                                },
                                expression: "model.total.ICMSTot.vProd",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-sm-2" }, [
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c(
                              "label",
                              {
                                staticClass: "control-label",
                                attrs: { for: "valorDesconto" },
                              },
                              [_vm._v("Valor Desconto")]
                            ),
                            _vm._v(" "),
                            _c("moeda-component", {
                              staticClass: "form-control",
                              attrs: {
                                readonly: "",
                                id: "valorDesconto",
                                name: "valorDesconto",
                                disabled: "",
                              },
                              model: {
                                value: _vm.model.total.ICMSTot.vDesc,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.model.total.ICMSTot,
                                    "vDesc",
                                    $$v
                                  )
                                },
                                expression: "model.total.ICMSTot.vDesc",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-sm-2" }, [
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c(
                              "label",
                              {
                                staticClass: "control-label",
                                attrs: { for: "valorFrete" },
                              },
                              [_vm._v("Valor Frete")]
                            ),
                            _vm._v(" "),
                            _c("moeda-component", {
                              staticClass: "form-control",
                              attrs: {
                                readonly: "",
                                id: "valorFrete",
                                name: "valorFrete",
                                disabled: "",
                              },
                              model: {
                                value: _vm.model.total.ICMSTot.vFrete,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.model.total.ICMSTot,
                                    "vFrete",
                                    $$v
                                  )
                                },
                                expression: "model.total.ICMSTot.vFrete",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-sm-2" }, [
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c(
                              "label",
                              {
                                staticClass: "control-label",
                                attrs: { for: "valorSeguro" },
                              },
                              [_vm._v("Valor Seguro")]
                            ),
                            _vm._v(" "),
                            _c("moeda-component", {
                              staticClass: "form-control",
                              attrs: {
                                readonly: "",
                                id: "valorSeguro",
                                name: "valorSeguro",
                                disabled: "",
                              },
                              model: {
                                value: _vm.model.total.ICMSTot.vSeg,
                                callback: function ($$v) {
                                  _vm.$set(_vm.model.total.ICMSTot, "vSeg", $$v)
                                },
                                expression: "model.total.ICMSTot.vSeg",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-sm-2" }, [
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c(
                              "label",
                              {
                                staticClass: "control-label",
                                attrs: { for: "totalValorOutro" },
                              },
                              [_vm._v("Valor Outros")]
                            ),
                            _vm._v(" "),
                            _c("moeda-component", {
                              staticClass: "form-control",
                              attrs: {
                                readonly: "",
                                id: "totalValorOutro",
                                name: "totalValorOutro",
                                disabled: "",
                              },
                              model: {
                                value: _vm.model.total.ICMSTot.vOutro,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.model.total.ICMSTot,
                                    "vOutro",
                                    $$v
                                  )
                                },
                                expression: "model.total.ICMSTot.vOutro",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-sm-2" }, [
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c(
                              "label",
                              {
                                staticClass: "control-label",
                                attrs: { for: "totalBaseICMS" },
                              },
                              [_vm._v("Base ICMS")]
                            ),
                            _vm._v(" "),
                            _c("moeda-component", {
                              staticClass: "form-control",
                              attrs: {
                                readonly: "",
                                id: "totalBaseICMS",
                                name: "totalBaseICMS",
                                disabled: "",
                              },
                              model: {
                                value: _vm.model.total.ICMSTot.vBC,
                                callback: function ($$v) {
                                  _vm.$set(_vm.model.total.ICMSTot, "vBC", $$v)
                                },
                                expression: "model.total.ICMSTot.vBC",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-sm-2" }, [
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c(
                              "label",
                              {
                                staticClass: "control-label",
                                attrs: { for: "totalValorICMS" },
                              },
                              [_vm._v("Valor ICMS")]
                            ),
                            _vm._v(" "),
                            _c("moeda-component", {
                              staticClass: "form-control",
                              attrs: {
                                readonly: "",
                                id: "totalValorICMS",
                                name: "totalValorICMS",
                                disabled: "",
                              },
                              model: {
                                value: _vm.model.total.ICMSTot.vICMS,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.model.total.ICMSTot,
                                    "vICMS",
                                    $$v
                                  )
                                },
                                expression: "model.total.ICMSTot.vICMS",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-sm-2" }, [
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c(
                              "label",
                              {
                                staticClass: "control-label",
                                attrs: { for: "totalValorIPI" },
                              },
                              [_vm._v("Valor IPI")]
                            ),
                            _vm._v(" "),
                            _c("moeda-component", {
                              staticClass: "form-control",
                              attrs: {
                                readonly: "",
                                id: "totalValorIPI",
                                name: "totalValorIPI",
                                disabled: "",
                              },
                              model: {
                                value: _vm.model.total.ICMSTot.vIPI,
                                callback: function ($$v) {
                                  _vm.$set(_vm.model.total.ICMSTot, "vIPI", $$v)
                                },
                                expression: "model.total.ICMSTot.vIPI",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-sm-2" }, [
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c(
                              "label",
                              {
                                staticClass: "control-label",
                                attrs: { for: "totalValorPIS" },
                              },
                              [_vm._v("Valor PIS")]
                            ),
                            _vm._v(" "),
                            _c("moeda-component", {
                              staticClass: "form-control",
                              attrs: {
                                readonly: "",
                                id: "totalValorPIS",
                                name: "totalValorPIS",
                                disabled: "",
                              },
                              model: {
                                value: _vm.model.total.ICMSTot.vPIS,
                                callback: function ($$v) {
                                  _vm.$set(_vm.model.total.ICMSTot, "vPIS", $$v)
                                },
                                expression: "model.total.ICMSTot.vPIS",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-sm-2" }, [
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c(
                              "label",
                              {
                                staticClass: "control-label",
                                attrs: { for: "totalValorCOFINS" },
                              },
                              [_vm._v("Valor COFINS")]
                            ),
                            _vm._v(" "),
                            _c("moeda-component", {
                              staticClass: "form-control",
                              attrs: {
                                readonly: "",
                                id: "totalValorCOFINS",
                                name: "totalValorCOFINS",
                                disabled: "",
                              },
                              model: {
                                value: _vm.model.total.ICMSTot.vCOFINS,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.model.total.ICMSTot,
                                    "vCOFINS",
                                    $$v
                                  )
                                },
                                expression: "model.total.ICMSTot.vCOFINS",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-sm-2" }, [
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c(
                              "label",
                              {
                                staticClass: "control-label",
                                attrs: { for: "totalValorII" },
                              },
                              [_vm._v("Valor II")]
                            ),
                            _vm._v(" "),
                            _c("moeda-component", {
                              staticClass: "form-control",
                              attrs: {
                                readonly: "",
                                id: "totalValorII",
                                name: "totalValorII",
                                disabled: "",
                              },
                              model: {
                                value: _vm.model.total.ICMSTot.vII,
                                callback: function ($$v) {
                                  _vm.$set(_vm.model.total.ICMSTot, "vII", $$v)
                                },
                                expression: "model.total.ICMSTot.vII",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]),
                  ]),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "shortcutComponent",
        { ref: "shortcutComponent", attrs: { showBtnOk: false } },
        [
          _c(
            "div",
            { attrs: { slot: "component" }, slot: "component" },
            [_c("item-component", { ref: "itemComponent" })],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }