import BaseModel from "./base/baseModel";
import OperacaoMatematica from "./enum/operacaoMatematica";
import UnidadesMedida from "./enum/unidadesMedida";

export default class UnidadeMedidaModel extends BaseModel {
    id?: number = null;
    franquiaId?: number = null;
    descricao: string = null;
    sigla: string = null;
    unidadeMedida?: UnidadesMedida = null;
    conversaoOperacao: OperacaoMatematica = null;
    conversaoValor = 0;
    variacoesSiglas: string = null;
}