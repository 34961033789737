import Vue from "vue";

import checkboxComponent from "@/components/child/form/checkbox.vue";
import { Component, Prop } from "@/decorators";

@Component({
    components: {
        checkboxComponent,
    },
})
export default class CancelamentoItemComponent extends Vue {
    @Prop({ type: Object, required: true }) item;
}
