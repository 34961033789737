var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("nav", { staticClass: "pagination-component" }, [
    _c("ul", { staticClass: "pagination" }, [
      _c("li", [
        _c(
          "a",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showPreviousButton,
                expression: "showPreviousButton",
              },
            ],
            on: {
              click: function ($event) {
                _vm.goFirstPage()
              },
            },
          },
          [_vm._v("1")]
        ),
      ]),
      _vm._v(" "),
      _c("li", [
        _c(
          "span",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showPreviousButton,
                expression: "showPreviousButton",
              },
            ],
          },
          [_vm._v("...")]
        ),
      ]),
      _vm._v(" "),
      _c("li", [
        _c(
          "a",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showPreviousButton,
                expression: "showPreviousButton",
              },
            ],
            on: {
              click: function ($event) {
                _vm.goPage(_vm.pageIndex - 1)
              },
            },
          },
          [_vm._v(_vm._s(_vm.pageIndex - 1))]
        ),
      ]),
      _vm._v(" "),
      _c("li", [
        _c("span", { staticClass: "is-primary" }, [
          _vm._v(_vm._s(_vm.pageIndex)),
        ]),
      ]),
      _vm._v(" "),
      _c("li", [
        _c(
          "a",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showNextButton,
                expression: "showNextButton",
              },
            ],
            on: {
              click: function ($event) {
                _vm.goPage(_vm.pageIndex + 1)
              },
            },
          },
          [_vm._v(_vm._s(_vm.pageIndex + 1))]
        ),
      ]),
      _vm._v(" "),
      _c("li", [
        _c(
          "span",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showNextButton,
                expression: "showNextButton",
              },
            ],
          },
          [_vm._v("...")]
        ),
      ]),
      _vm._v(" "),
      _c("li", [
        _c(
          "a",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showNextButton,
                expression: "showNextButton",
              },
            ],
            on: {
              click: function ($event) {
                _vm.goLastPage()
              },
            },
          },
          [_vm._v(_vm._s(_vm.totalPages))]
        ),
      ]),
    ]),
    _vm._v(" "),
    _vm.controlaPageSize
      ? _c("div", { staticClass: "pageLimiter" }, [
          _c("div", { staticClass: "col-sm-8" }, [
            _c(
              "div",
              {
                staticClass: "form-group",
                staticStyle: { "margin-bottom": "auto !important" },
              },
              [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm._pageSize,
                      expression: "_pageSize",
                    },
                  ],
                  staticClass: "form-control",
                  attrs: { type: "number" },
                  domProps: { value: _vm._pageSize },
                  on: {
                    blur: _vm.onChangePageSize,
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm._pageSize = $event.target.value
                    },
                  },
                }),
              ]
            ),
          ]),
          _vm._v(" "),
          _vm._m(0),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "form-group",
        staticStyle: { "margin-top": "7px !important" },
      },
      [_c("b", [_vm._v("Reg./Pag.")])]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }