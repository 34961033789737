var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "shortcutComponent",
    {
      ref: "shortcutComponent",
      attrs: { modalClass: "ld-modal", showBtnOk: false },
    },
    [
      _c("div", { attrs: { slot: "component" }, slot: "component" }, [
        _c(
          "form",
          { attrs: { novalidate: "novalidate" } },
          _vm._l(_vm.model, function (item) {
            return _c(
              "fieldset-component",
              {
                key: item.id,
                attrs: {
                  collapsed: false,
                  title: "Campanha: " + item.campanha.descricao,
                },
              },
              [
                _c("div", { attrs: { slot: "rows" }, slot: "rows" }, [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-sm-12" }, [
                      _c("div", { staticClass: "form-group" }, [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "pontos" },
                          },
                          [
                            _vm._v(
                              "\n                                    " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Crud.campanha.edit_vue_html.pontos"
                                  )
                                ) +
                                "\n                                "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: item.pontos,
                              expression: "item.pontos",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            autocomplete: "off",
                            type: "text",
                            id: "pontos",
                            name: "pontos",
                            disabled: "",
                          },
                          domProps: { value: item.pontos },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(item, "pontos", $event.target.value)
                            },
                          },
                        }),
                      ]),
                    ]),
                  ]),
                ]),
              ]
            )
          })
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }