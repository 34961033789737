import Vue from "vue";

import cepFieldComponent from "@/components/child/form/cepfield.vue";
import { Component, Prop } from "@/decorators";

import "../../crud/crud.scss";

@Component({
    components: {
        cepFieldComponent,
    },
})
export default class EnderecoComponent extends Vue {
    @Prop(Object) data;
    @Prop(String) nome: string;
}
