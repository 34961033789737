var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "crud crud-list ajusteAcoesPesquisar" },
    [
      _c("div", { staticClass: "info-div" }, [
        _c("h1", { staticClass: "title" }, [
          _vm._v(
            "\n            " +
              _vm._s(
                _vm.$t(
                  "__.Components.parent.sessaoCaixa.listMovimentosCaixa_vue_html.movimentacoesCaixa"
                )
              ) +
              "\n        "
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "content" }, [
          _c("div", { staticClass: "info-panel" }, [
            _c("span", [_vm._v(_vm._s(_vm.totalMov))]),
            _vm._v(" "),
            _c("span", [
              _vm._v(
                "\n                    " +
                  _vm._s(
                    _vm.$t(
                      "__.Components.parent.sessaoCaixa.listMovimentosCaixa_vue_html.movimentacoesCaixa"
                    )
                  ) +
                  "\n                "
              ),
            ]),
            _vm._v(" "),
            _c("span", [
              _vm._v(
                _vm._s(
                  _vm.$t(
                    "__.Components.parent.sessaoCaixa.listMovimentosCaixa_vue_html.realizadas"
                  )
                )
              ),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "grid-component",
        {
          attrs: {
            data: _vm.gridData,
            columns: _vm.gridColumns,
            "show-select-checkbox": false,
            subColumns: _vm.gridColumnsVinculos,
            subDataName: _vm.subDataName,
            showExpand: true,
            conditionShowSubData: _vm.conditionShowSubData,
            showSearchField: false,
            "page-index": _vm.pageIndex,
            "page-size": _vm.pageSize,
            total: _vm.total,
            showEditItem: false,
            showActionColumn: false,
            showAddNewButton: false,
            showTableFoot: true,
            showTools: true,
            showTableHead: true,
          },
          on: {
            "change-filter-key": _vm.onChangeFilterKey,
            "change-sort": _vm.onChangeSort,
            "change-page": _vm.onChangePage,
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "grid-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("span", { staticClass: "saldo-inicial" }, [
                _c("i", { staticClass: "fa fa-money-bill" }),
                _vm._v(
                  "\n                " +
                    _vm._s(
                      _vm.$t(
                        "__.Components.parent.sessaoCaixa.listMovimentosCaixa_vue_html.saldoInicial"
                      )
                    ) +
                    "\n                " +
                    _vm._s(_vm.caixaSessao) +
                    ":\n                "
                ),
                _c("b", [
                  _vm._v(_vm._s(_vm._f("filterMoeda")(_vm.saldoInicial))),
                ]),
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "saldo-atual" }, [
                _c("i", { staticClass: "fa fa-money-bill" }),
                _vm._v(
                  "\n                " +
                    _vm._s(
                      _vm.$t(
                        "__.Components.parent.sessaoCaixa.listMovimentosCaixa_vue_html.saldoAtual"
                      )
                    ) +
                    "\n                " +
                    _vm._s(_vm.caixaSessao) +
                    ":\n                "
                ),
                _c("b", [
                  _vm._v(_vm._s(_vm._f("filterMoeda")(_vm.saldoAtual))),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "info-panel info-panel-tamanho" }, [
                _vm.VALIDAR_PERMISSAO_USUARIO(
                  "movimentacoes_de_caixa",
                  "GerarReforço"
                )
                  ? _c("span", [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-primary",
                          staticStyle: { "max-width": "100%", width: "240px" },
                          attrs: { type: "button" },
                          on: { click: _vm.gerarReforco },
                        },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(
                                _vm.$t(
                                  "__.Components.parent.sessaoCaixa.listMovimentosCaixa_vue_html.gerarReforco"
                                )
                              ) +
                              "\n                    "
                          ),
                        ]
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.VALIDAR_PERMISSAO_USUARIO(
                  "movimentacoes_de_caixa",
                  "GerarSangria"
                )
                  ? _c("span", [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-primary btn-new",
                          staticStyle: { "max-width": "100%", width: "240px" },
                          attrs: { type: "button" },
                          on: { click: _vm.gerarSangria },
                        },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(
                                _vm.$t(
                                  "__.Components.parent.sessaoCaixa.listMovimentosCaixa_vue_html.gerarSangria"
                                )
                              ) +
                              "\n                    "
                          ),
                        ]
                      ),
                    ])
                  : _vm._e(),
              ]),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "shortcutComponent",
        {
          ref: "shortcutReforcoComponent",
          attrs: { btOkName: _vm.$t("__.Crud.salvar") },
          on: { "selection-item": _vm.onConfirmReforco },
        },
        [
          _c("div", { attrs: { slot: "component" }, slot: "component" }, [
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col-sm-12" },
                [_c("reforco-component", { ref: "reforcoComponent" })],
                1
              ),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "shortcutComponent",
        {
          ref: "shortcutSangriaComponent",
          attrs: { btOkName: _vm.$t("__.Crud.salvar") },
          on: { "selection-item": _vm.onConfirmSangria },
        },
        [
          _c("div", { attrs: { slot: "component" }, slot: "component" }, [
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col-sm-12" },
                [_c("sangria-component", { ref: "sangriaComponent" })],
                1
              ),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }