import AtendimentoWhatsAppModel from "@/models/whatsApp/atendimento/atendimentoWhatsAppModel";
import MensagemAtendimentoWhatsAppModel from "@/models/whatsApp/atendimento/mensagemAtendimentoWhatsAppModel";

export enum AtendimentoContextActions {
    CREATE_ATENDIMENTO_BY_NUMERO = "CREATE_ATENDIMENTO_BY_NUMERO",
    RECEIVE_MESSAGE = "RECEIVE_MESSAGE",
    SEND_MESSAGE = "SEND_MESSAGE",
    SEND_MESSAGE_OBS = "SEND_MESSAGE_OBS",
    SET_ATENDIMENTOS = "SET_ATENDIMENTOS",
    SET_ATENDIMENTOS_ENCERRADOS = "SET_ATENDIMENTOS_ENCERRADOS",
    SET_CURRENT_ATENDIMENTO = "SET_CURRENT_ATENDIMENTO",
    UPDATE_ATENDIMENTO = "UPDATE_ATENDIMENTO",
}

export enum AtendimentoContextMutations {
    CREATE_ATENDIMENTO_BY_ID = "CREATE_ATENDIMENTO_BY_ID",
    CREATE_ATENDIMENTO_BY_NUMERO = "CREATE_ATENDIMENTO_BY_NUMERO",
    NOTIFICATION_ADD = "NOTIFICATION_ADD",
    NOTIFICATION_CLEAN = "NOTIFICATION_CLEAN",
    NOTIFICATION_SET_ALL = "NOTIFICATION_SET_ALL",
    ORDER_ATENDIMENTOS = "ORDER_ATENDIMENTOS",
    RECEIVE_MESSAGE = "RECEIVE_MESSAGE",
    SEND_MESSAGE = "SEND_MESSAGE",
    SET_ATENDIMENTOS = "SET_ATENDIMENTOS",
    SET_ATENDIMENTOS_ENCERRADOS = "SET_ATENDIMENTOS_ENCERRADOS",
    SET_CURRENT_ATENDIMENTO = "SET_CURRENT_ATENDIMENTO",
    SET_CURRENT_MESSAGES = "SET_CURRENT_MESSAGES",
    UPDATE_ATENDIMENTO = "UPDATE_ATENDIMENTO",
    UPDATE_ATENDIMENTO_LAST_MESSAGE = "UPDATE_ATENDIMENTO_LAST_MESSAGE",
}

export class AtendimentoContextState {
    atendimentos: AtendimentoWhatsAppModel[];
    atendimentosEncerrados: AtendimentoWhatsAppModel[];
    currentAtendimento: AtendimentoWhatsAppModel;
    currentMessages: MensagemAtendimentoWhatsAppModel[];
    messagesAtendimentos: AtendimentoMessages[];
    totalUnreadCount: number;
}

export class AtendimentoMessages {
    id?: string;
    messages?: MensagemAtendimentoWhatsAppModel[];
}
