import BaseModel from "./base/baseModel";
import OrigemProdutoLote from "./enum/origemProdutoLote";
import StatusProdutoLote from "./enum/statusProdutoLote";
import EstoqueProdutoLoteModel from "./estoqueProdutoLoteModel";
import MovimentacaoEstoqueItemModel from "./movimentacaoEstoqueItemModel";

export default class ProdutoLoteModel extends BaseModel {
    id?: number = null;
    dataCriacao: Date = null;
    descricao: string = null;
    produtoId: number = null;
    produtoDescricao: string = null;
    produtoValorCusto = 0;
    produtoValorVenda = 0;
    quantidadeAtual = 0;
    fornecedorId?: number = null;
    fornecedorNome: string = null;
    dataFabricacao: Date = null;
    dataValidade: Date = null;
    observacao: string = null;
    valorCusto?: number = null;
    fatorCorrecao?: number = null;
    densidade?: number = null;
    fatorUI?: number = null;
    fatorUFC?: number = null;
    fatorUTR?: number = null;
    sequencialCompra?: number = null;
    conservacao?: string = null;
    descricaoFornecedor: string = null;
    movimentacoesSaidaEstoque: Array<MovimentacaoEstoqueItemModel> = [];
    movimentacoesEntradaEstoque: Array<MovimentacaoEstoqueItemModel> = [];

    // Fracionamento
    statusProdutoLote = StatusProdutoLote.Liberado;
    statusProdutoLoteDescricao: string = null;
    volumeFracionado: number = null;
    unidadeMedidaId: number = null;
    unidadeMedidaSigla: string = null;
    unidadeMedidaFracionadoId: number = null;
    unidadeMedidaFracionadoSigla: string = null;
    quatidadeFracionadaTotal: string = null;
    origemProdutoLote: OrigemProdutoLote = null;
    produtoLoteOrigemId: number = null;
    produtoLotesFracionados: ProdutoLoteModel[] = [];
    estoqueProdutoLote: EstoqueProdutoLoteModel[] = [];
    loteFoiFracionado = false;

    getNaoPermiteAlterarStatus() {
        return (
            this.statusProdutoLote == StatusProdutoLote.Liberado ||
            this.origemProdutoLote == OrigemProdutoLote.Fracionamento
        );
    }

    getPermiteImprimirOrderFracionamento() {
        return (
            this.statusProdutoLote > StatusProdutoLote.Aprovado && this.origemProdutoLote == OrigemProdutoLote.Interno
        );
    }

    getPermiteFracionar() {
        return (
            this.origemProdutoLote == OrigemProdutoLote.Interno && this.statusProdutoLote > StatusProdutoLote.Aprovado
        );
    }

    getStatusProdutoLoteOptions() {
        return [
            { value: StatusProdutoLote.Quarentena, text: "Quarentena" },
            { value: StatusProdutoLote.AnaliseCQ, text: "Análise CQ" },
            { value: StatusProdutoLote.Reprovado, text: "Reprovado" },
            { value: StatusProdutoLote.Aprovado, text: "Aprovado" },
            { value: StatusProdutoLote.Fracionamento, text: "Fracionamento" },
            { value: StatusProdutoLote.AguardandoLiberacao, text: "Aguardando Liberação" },
            { value: StatusProdutoLote.Liberado, text: "Liberado" },
        ];
    }

    // Campo utilizado somente no front
    quantidadeComprometida = 0;
}

export interface ProdutoLoteListParameters {
    statusProdutoLote?: number;
    origemProdutoLote?: number;
    estoqueId?: number;
    ignorarZerados?: boolean;
}
