enum TiposFormaFarmaceutica {
    Capsula = 0,
    Volume = 1,
    Homeopatia = 2,
    Floral = 3,
    Unitario = 4,
    VolumeQtdePerc = 5,
    VolumeQtdeMG = 6,
    Papel = 7
}

export default TiposFormaFarmaceutica;