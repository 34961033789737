var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "crud crud-edit" }, [
    _c("form", { attrs: { novalidate: "novalidate" } }, [
      _c("fieldset", [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-sm-12" }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "tooltip",
                    rawName: "v-tooltip",
                    value: _vm.errors.first("descricao"),
                    expression: "errors.first('descricao')",
                  },
                ],
                staticClass: "form-group",
              },
              [
                _c(
                  "label",
                  {
                    staticClass: "control-label",
                    attrs: {
                      for: "descricao",
                      title: _vm.$t("__.obrigatorio"),
                    },
                  },
                  [
                    _c("span", { staticStyle: { color: "red" } }, [
                      _vm._v("*"),
                    ]),
                    _vm._v(
                      "\n                            " +
                        _vm._s(
                          _vm.$t(
                            "__.Components.parent.sessaoCaixa.sangria_vue_html.descricao"
                          )
                        ) +
                        "\n                        "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.descricao,
                      expression: "descricao",
                    },
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required|max:256",
                      expression: "'required|max:256'",
                    },
                  ],
                  staticClass: "form-control",
                  attrs: { type: "text", id: "descricao", name: "descricao" },
                  domProps: { value: _vm.descricao },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.descricao = $event.target.value
                    },
                  },
                }),
              ]
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-sm-4" }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "tooltip",
                    rawName: "v-tooltip",
                    value: _vm.errors.first("saldoAtual"),
                    expression: "errors.first('saldoAtual')",
                  },
                ],
                staticClass: "form-group",
                attrs: { disabled: "disabled" },
              },
              [
                _c(
                  "label",
                  {
                    staticClass: "control-label",
                    attrs: { for: "saldoAtual" },
                  },
                  [
                    _vm._v(
                      "\n                            " +
                        _vm._s(
                          _vm.$t(
                            "__.Components.parent.sessaoCaixa.sangria_vue_html.saldoAtual"
                          )
                        ) +
                        "\n                        "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("moeda-component", {
                  staticClass: "form-control",
                  attrs: {
                    id: "saldoAtual",
                    name: "saldoAtual",
                    disabled: "disabled",
                  },
                  model: {
                    value: _vm.saldoAtual,
                    callback: function ($$v) {
                      _vm.saldoAtual = $$v
                    },
                    expression: "saldoAtual",
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-sm-4" }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "tooltip",
                    rawName: "v-tooltip",
                    value: _vm.errors.first("sangria"),
                    expression: "errors.first('sangria')",
                  },
                ],
                staticClass: "form-group",
              },
              [
                _c(
                  "label",
                  { staticClass: "control-label", attrs: { for: "sangria" } },
                  [
                    _vm._v(
                      "\n                            " +
                        _vm._s(
                          _vm.$t(
                            "__.Components.parent.sessaoCaixa.sangria_vue_html.sangria"
                          )
                        ) +
                        "\n                        "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("moeda-component", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required|maior_que_zero",
                      expression: "'required|maior_que_zero'",
                    },
                  ],
                  staticClass: "form-control",
                  attrs: { id: "sangria", name: "sangria" },
                  model: {
                    value: _vm.sangria,
                    callback: function ($$v) {
                      _vm.sangria = $$v
                    },
                    expression: "sangria",
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-sm-4" }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "tooltip",
                    rawName: "v-tooltip",
                    value: _vm.errors.first("saldoCalculado"),
                    expression: "errors.first('saldoCalculado')",
                  },
                ],
                staticClass: "form-group",
              },
              [
                _c(
                  "label",
                  {
                    staticClass: "control-label",
                    attrs: { for: "saldoCalculado" },
                  },
                  [
                    _vm._v(
                      "\n                            " +
                        _vm._s(
                          _vm.$t(
                            "__.Components.parent.sessaoCaixa.sangria_vue_html.saldoCalculado"
                          )
                        ) +
                        "\n                        "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("data-tooltip-component", {
                  attrs: {
                    text: _vm.$t(
                      "__.Components.parent.sessaoCaixa.sangria_vue_html.saldoPermanPDV"
                    ),
                  },
                }),
                _vm._v(" "),
                _c("moeda-component", {
                  staticClass: "form-control",
                  attrs: {
                    id: "saldoCalculado",
                    name: "saldoCalculado",
                    disabled: "",
                  },
                  model: {
                    value: _vm.saldoCalculado,
                    callback: function ($$v) {
                      _vm.saldoCalculado = $$v
                    },
                    expression: "saldoCalculado",
                  },
                }),
              ],
              1
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-sm-4" }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "tooltip",
                    rawName: "v-tooltip",
                    value: _vm.errors.first("contaCorrenteDestino"),
                    expression: "errors.first('contaCorrenteDestino')",
                  },
                ],
                staticClass: "form-group",
                attrs: { disabled: "disabled" },
              },
              [
                _c(
                  "label",
                  {
                    staticClass: "control-label",
                    attrs: {
                      for: "contaCorrenteDestino",
                      title: _vm.$t("__.obrigatorio"),
                    },
                  },
                  [
                    _c("span", { staticStyle: { color: "red" } }, [
                      _vm._v("*"),
                    ]),
                    _vm._v(
                      "\n                            " +
                        _vm._s(
                          _vm.$t(
                            "__.Components.parent.sessaoCaixa.sangria_vue_html.contaCorrenteDestino"
                          )
                        ) +
                        "\n                        "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("data-tooltip-component", {
                  attrs: {
                    text: _vm.$t(
                      "__.Components.parent.sessaoCaixa.sangria_vue_html.tooltipContaCorrente"
                    ),
                  },
                }),
                _vm._v(" "),
                _c("combo-component", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required",
                      expression: "'required'",
                    },
                  ],
                  attrs: {
                    id: "contaCorrenteDestino",
                    name: "contaCorrenteDestino",
                    disabled: _vm.bloquearAlteracaoContaCorrente,
                    data: _vm.contaCorrenteOptions,
                    searchable: false,
                    refresh: _vm.loadContasCorrentes,
                  },
                  model: {
                    value: _vm.contaCorrenteIdDestino,
                    callback: function ($$v) {
                      _vm.contaCorrenteIdDestino = $$v
                    },
                    expression: "contaCorrenteIdDestino",
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-sm-4" }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "tooltip",
                    rawName: "v-tooltip",
                    value: _vm.errors.first("formaRecebimentoId"),
                    expression: "errors.first('formaRecebimentoId')",
                  },
                ],
                staticClass: "form-group",
              },
              [
                _c(
                  "label",
                  {
                    staticClass: "control-label",
                    attrs: {
                      for: "formaRecebimentoId",
                      title: _vm.$t("__.obrigatorio"),
                    },
                  },
                  [
                    _c("span", { staticStyle: { color: "red" } }, [
                      _vm._v("*"),
                    ]),
                    _vm._v(
                      "\n                            " +
                        _vm._s(
                          _vm.$t(
                            "__.Components.parent.sessaoCaixa.sangria_vue_html.frmRecebimentoTransf"
                          )
                        ) +
                        "\n                        "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("data-tooltip-component", {
                  attrs: {
                    text: _vm.$t(
                      "__.Components.parent.sessaoCaixa.sangria_vue_html.tooltipFormaRecebimento"
                    ),
                  },
                }),
                _vm._v(" "),
                _c("combo-component", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required",
                      expression: "'required'",
                    },
                  ],
                  attrs: {
                    id: "formaRecebimentoId",
                    name: "formaRecebimentoId",
                    data: _vm.formaRecebimentoOptions,
                    searchable: true,
                    refresh: _vm.loadFormasRecebimento,
                  },
                  model: {
                    value: _vm.formaRecebimentoId,
                    callback: function ($$v) {
                      _vm.formaRecebimentoId = $$v
                    },
                    expression: "formaRecebimentoId",
                  },
                }),
              ],
              1
            ),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }