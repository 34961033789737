import { addDays } from "@/utils/common/date";

import Setor from "./enum/setor";

export default class GradePCPRequestModel {
    dataInicial = new Date();
    dataFinal: Date = addDays(7, new Date());
    formasFarmaceuticasIds: Array<number> = [];
    tipoVendas: Array<number> = [];
    setor: Setor = null;
}
