import Vue from "vue";

import { Component } from "@/decorators";

import "./view.scss";

@Component
export default class ChatBotReceitaComponent extends Vue {
    private showIa = false;
    private showTransition = false;

    public toggleOverlay() {
        this.showIa = !this.showIa;
        this.showTransition = true;
    }

    private closeOverlay() {
        this.showIa = false;
        this.showTransition = false;
    }
}
