import Vue from "vue";
import { mapState } from "vuex";

import ShortcutComponent from "@/components/parent/shortcut/shortcut";
import shortcutComponent from "@/components/parent/shortcut/shortcut.vue";
import { Component } from "@/decorators";
import StatusMensagem from "@/models/enum/statusMensagem";
import UsuarioModel from "@/models/usuarioModel";
import MensagemAtendimentoWhatsAppModel from "@/models/whatsApp/atendimento/mensagemAtendimentoWhatsAppModel";
import MensagemService from "@/services/atendimentos/mensagemService";
import { AppState } from "@/store/store";

import mensagemComponent from "../mensagem/mensagem.vue";

import "./historicoAtendimentoMensagens.scss";

@Component({
    components: {
        mensagemComponent,
        shortcutComponent,
    },
    computed: {
        ...mapState({
            usuarioLogado: (state: AppState) => state.session.usuarioLogado,
        }),
    },
})
export default class HistoricoAtendimentoMensagensComponent extends Vue {
    // State computed props
    usuarioLogado: UsuarioModel;

    private shortcutComponent: ShortcutComponent = null;

    private mensagemService = new MensagemService();
    private messages: MensagemAtendimentoWhatsAppModel[] = [];
    private nomeContato = "";

    public async show(atendimentoId: string, nomeContato: string) {
        this.shortcutComponent = this.$refs.shortcutComponent as ShortcutComponent;
        this.shortcutComponent.title = this.$t("__.ts.historicoContato") as string;

        this.messages = await this.mensagemService.getMensagensByAtendimento(atendimentoId);
        for (const mensagem of this.messages) {
            mensagem.statusMensagem = StatusMensagem.Enviada;
        }
        this.nomeContato = nomeContato;

        this.shortcutComponent.show();
    }

    private sentBy(mensagemRespondidaId: string) {
        const mensagemRespondida = this.getMensagemRespondida(mensagemRespondidaId);
        if (!mensagemRespondida) {
            return "Mensagem inexistente no histórico";
        }
        if (mensagemRespondida.mensagemPropria) {
            return "Você";
        }
        return this.nomeContato;
    }

    private isMensagemContato(mensagemRespondidaId: string) {
        const mensagemRespondida = this.getMensagemRespondida(mensagemRespondidaId);
        if (!mensagemRespondida || mensagemRespondida?.mensagemPropria) {
            return false;
        }
        return true;
    }

    private getMensagemRespondida(mensagemRespondidaId: string) {
        return this.messages.find(item => item.id == mensagemRespondidaId);
    }
}
