var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "crud crud-edit" },
    [
      _c("actionBarComponent", {
        attrs: {
          title: _vm.$t(
            "__.Components.parent.statusPCP.view_vue_html.statusPCP"
          ),
          showSave: false,
        },
      }),
      _vm._v(" "),
      _c("form", [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-sm-10" }, [
            _c("div", { staticClass: "formulasDia" }, [
              _vm._v(
                "\n                    Vendas do dia:\n                    "
              ),
              _c("span", { staticClass: "formulasDiaNumero" }, [
                _vm._v(_vm._s(_vm.gridData.length)),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-sm-2" }, [
            _c(
              "button",
              {
                staticClass: "buttonRefresh btn-secondary",
                attrs: { type: "button" },
                on: { click: _vm.onAtualizar },
              },
              [
                _c("i", {
                  staticClass: "fa fa-sync",
                  attrs: { "aria-hidden": "true" },
                }),
                _vm._v("\n                    Atualizar\n                "),
              ]
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-sm-6" }, [
            _c(
              "div",
              { staticClass: "form-group" },
              [
                _c(
                  "label",
                  { staticClass: "control-label", attrs: { for: "etapa" } },
                  [
                    _vm._v(
                      "\n                        " +
                        _vm._s(
                          _vm.$t(
                            "__.Components.parent.statusPCP.view_vue_html.etapa"
                          )
                        ) +
                        "\n                    "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("combo-component", {
                  attrs: {
                    id: "etapa",
                    name: "etapa",
                    data: _vm.etapasOptions,
                    searchable: true,
                  },
                  model: {
                    value: _vm.etapaId,
                    callback: function ($$v) {
                      _vm.etapaId = $$v
                    },
                    expression: "etapaId",
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-sm-3" }, [
            _c(
              "div",
              { staticClass: "form-group" },
              [
                _c(
                  "label",
                  { staticClass: "control-label", attrs: { for: "etapa" } },
                  [
                    _vm._v(
                      "\n                        " +
                        _vm._s(
                          _vm.$t(
                            "__.Components.parent.statusPCP.view_vue_html.dataInicial"
                          )
                        ) +
                        "\n                    "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("date-time-picker-component", {
                  attrs: { id: "data", name: "data", pickTime: true },
                  model: {
                    value: _vm.dataInicial,
                    callback: function ($$v) {
                      _vm.dataInicial = $$v
                    },
                    expression: "dataInicial",
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-sm-3" }, [
            _c(
              "div",
              { staticClass: "form-group" },
              [
                _c(
                  "label",
                  { staticClass: "control-label", attrs: { for: "etapa" } },
                  [
                    _vm._v(
                      "\n                        " +
                        _vm._s(
                          _vm.$t(
                            "__.Components.parent.statusPCP.view_vue_html.dataFinal"
                          )
                        ) +
                        "\n                    "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("date-time-picker-component", {
                  attrs: { id: "data", name: "data", pickTime: true },
                  model: {
                    value: _vm.dataFinal,
                    callback: function ($$v) {
                      _vm.dataFinal = $$v
                    },
                    expression: "dataFinal",
                  },
                }),
              ],
              1
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-sm-12" },
            [
              _c("grid-component", {
                attrs: {
                  data: _vm.gridData,
                  columns: _vm.gridColumns,
                  editItemIcon: "fa-search",
                  editItemTitle: "Consulta Manipulação",
                  showRemoveItem: false,
                  showSortIcons: false,
                  showTableFoot: false,
                  showTableHead: false,
                },
                on: { "edit-item": _vm.onConsulta },
              }),
            ],
            1
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }