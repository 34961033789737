import Vue from "vue";

import actionBarComponent from "@/components/child/actionBar/actionBar.vue";
import comboComponent from "@/components/child/form/combo.vue";
import dateTimePickerComponent from "@/components/child/form/datetimepicker.vue";
import { Component } from "@/decorators";
import NotaFiscalEletronicaEmissaoService from "@/services/emissaoNota/notaFiscalEletronicaEmissaoService";
import FileHelper from "@/utils/common/fileHelper";

@Component({
    components: {
        dateTimePickerComponent,
        comboComponent,
        actionBarComponent,
    },
})
export default class DownloadXmlsEditComponent extends Vue {
    private service = new NotaFiscalEletronicaEmissaoService();

    dataInicial = new Date();
    dataFinal = new Date();
    tiposDocumento = 3;
    tiposNotas = 1;

    tipoDocumentoOptions = [
        { text: "SERVICO", value: 0 },
        { text: "NFC-e", value: 1 },
        { text: "NF-e", value: 2 },
        { text: "TODOS", value: 3 },
    ];

    tiposNotasOptions = [
        { text: "TODAS", value: 1 },
        { text: "EMITIDAS", value: 2 },
        { text: "CANCELADAS", value: 3 },
    ];

    private async save() {
        try {
            const response = await this.service
                .downloadXmls({
                    dataInicial: this.dataInicial,
                    dataFinal: this.dataFinal,
                    tiposDocumento: this.tiposDocumento,
                    tiposNotas: this.tiposNotas,
                })
                .withLoading()
                .resolveWithResponse();

            if (response.ok) {
                const blob = await response.blob();
                const fileName = response.headers.get("Content-Disposition").split("filename=")[1].split(";")[0];

                FileHelper.download(blob, fileName, "application/zip");
            }
        } catch {}
    }

    private cancel() {
        this.$router.back();
    }
}
