import BaseModel from "../../../../../base/baseModel";

import ProdutoLoteNfeModel from "./produtolote/produtoLoteNfeModel";

export default class ProdutoNfeModel extends BaseModel {
    codigo: string = null;
    produtoId: number = null;
    descricao: string = null;
    EAN: string = null;
    NCM: string = null;
    codigoBeneficio: string = null;
    quantidade: number = null;
    unidadeMedida: string = null;
    unidadeMedidaId: number = null;
    valorUnitario: number = null;
    valorTotal: number = null;
    valorFrete: number = null;
    valorSeguro: number = null;
    valorDesconto: number = null;
    valorOutros: number = null;
    lote: ProdutoLoteNfeModel = new ProdutoLoteNfeModel();
    quantidadeConvertida: number = null;
    descricaoProduto: string = null;
    informacaoAdicional: string = null;
    //criado para controle em telas grids com filhos
    lotes: Array<ProdutoLoteNfeModel> = new Array<ProdutoLoteNfeModel>();
    fatorCorrecao?: number = 0;
    unidadeMedidaEstoqueProduto: string = null;
    //quando true nao exige o lote para prosseguir
    importarSemLote = false;

    //teste para performance
    buscouInfos = false;
    conferido = false;
    //descricao auxiliar apenas front
    descricaoQtdOriginal: string = null;
    unidadeMedidaNfId: number = null;
    densidadeProduto: number = null;
    custoReferenciaProduto: number = null;
}
