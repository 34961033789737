import BaseModel from "./base/baseModel";
import TiposMovimentacao from "./enum/tiposMovimentacao";

export default class NaturezaOperacaoModel extends BaseModel {
    id?: number = null;
    codigo = 0;
    descricao: string = null;
    tipoMovimentacao: TiposMovimentacao = null;
    dentroEstado = false;
    movimentaEstoque = false;
    usaConfiguracaoFiscal = false;
    transferencia = false;
    cstICMS: number = null;
    aliquotaICMS: number = null;
    cstIPI: number = null;
    aliquotaIPI: number = null;
    cstPIS: number = null;
    aliquotaPIS: number = null;
    aliquotaCOFINS: number = null;
    cstCOFINS: number = null;
    codigoBeneficio: string = null;
    observacao: string = null;
}
