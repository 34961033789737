import Vue from "vue";
import { mapState, mapMutations, mapGetters } from "vuex";

import Moment from "moment";

import ImpressaoComponent from "@/components/child/impressao/impressaoComponent";
import impressaoComponent from "@/components/child/impressao/impressaoComponent.vue";
import ClientesListComponent from "@/components/parent/crud/cliente/list";
import clientesListComponent from "@/components/parent/crud/cliente/list.vue";
import imprimirRotuloPersonalizadoComponent from "@/components/parent/crud/rotulopersonalizado/imprimirRotulo.vue";
import ShortcutComponent from "@/components/parent/shortcut/shortcut";
import shortcutComponent from "@/components/parent/shortcut/shortcut.vue";
import ArquivarOrcamentoComponent from "@/components/parent/venda/arquivarOrcamento";
import arquivarOrcamentoComponent from "@/components/parent/venda/arquivarOrcamento.vue";
import OrcamentoListComponent from "@/components/parent/venda/listOrcamento";
import orcamentoListComponent from "@/components/parent/venda/listOrcamento.vue";
import RevisaoCamposComponent from "@/components/parent/venda/revisaoCampos";
import revisaoCamposComponent from "@/components/parent/venda/revisaoCampos.vue";
import { Component } from "@/decorators";
import PreLoadPackModel from "@/models/auxiliar/preLoadPackModel";
import SituacoesVenda from "@/models/enum/situacoesVenda";
import ManipulacaoOrdemModel from "@/models/manipulacaoOrdemModel";
import PaginationModel from "@/models/paginationModel";
import UsuarioModel from "@/models/usuarioModel";
import VendaModel, { VendaListParameters } from "@/models/vendaModel";
import OrcamentoService from "@/services/utils/orcamentoService";
import WhatsAppUtilsService from "@/services/utils/whatsAppUtilsService";
import VendaService from "@/services/vendaService";
import { FastActionLastPack, FastActionList, FastActionPack } from "@/store/fastActionStore";
import { AppState, Getters, Mutations } from "@/store/store";
import { editDateWithTime } from "@/utils/common/date";
import Delay from "@/utils/common/delay";

import { getFirstName } from "../fastactionUtils";

import "./../fastactionsgrid.scss";
import "./orcamentos.scss";

@Component({
    components: {
        imprimirRotuloPersonalizadoComponent,
        shortcutComponent,
        revisaoCamposComponent,
        orcamentoListComponent,
        clientesListComponent,
        impressaoComponent,
        arquivarOrcamentoComponent,
    },
    computed: {
        ...mapState({
            fastActionLists: (state: AppState) => state.fastAction.fastActionLists,
            fastActionPack: (state: AppState) => state.fastAction.fastActionPack,
            preLoadList: (state: AppState) => state.preLoad.preLoadList,
            usuarioLogado: (state: AppState) => state.session.usuarioLogado,
        }),
        ...mapGetters(["VALIDAR_PERMISSAO_USUARIO"] as Getters),
    },
    methods: {
        ...mapMutations(["SET_LISTSPACK", "SET_COUNTPACK", "SET_LASTCOUNTPACK", "CLEAR_LISTSPACK"] as Mutations),
        getFirstName: getFirstName,
    },
})
export default class FastActionOrcamentoPanelComponent extends Vue {
    // State computed props
    fastActionLists: FastActionList;
    fastActionPack: FastActionPack;
    preLoadList: PreLoadPackModel;
    usuarioLogado: UsuarioModel;
    SET_LISTSPACK: (data: FastActionList) => void;
    SET_COUNTPACK: (data: FastActionLastPack) => void;
    SET_LASTCOUNTPACK: (data: FastActionLastPack) => void;
    CLEAR_LISTSPACK: () => void;
    VALIDAR_PERMISSAO_USUARIO: (telaDescricao: string, acaoController: string) => boolean;

    totalOrcadosHoje = 0.0;
    totalOrcamentosCriadosHoje = 0;

    private orcamentos: Array<VendaModel> = [];
    private shortCutRevisaoCampos: ShortcutComponent = null;
    private revisaoCamposComponent: RevisaoCamposComponent = null;
    private impressaoComponent: ImpressaoComponent = null;
    private arquivarOrcamentoComponent: ArquivarOrcamentoComponent = null;

    private shortCutOrcamentoList: ShortcutComponent = null;
    private showOrcamentoList = false;
    private orcamentoListComponent: OrcamentoListComponent = null;

    private shortCutClientesList: ShortcutComponent = null;
    private showClientesList = false;
    private clientesListComponent: ClientesListComponent = null;

    private showRevisao = false;

    private service = new VendaService();
    private orcamentoService = new OrcamentoService();
    private vendaService = new VendaService();
    private whatsAppUtilsService = new WhatsAppUtilsService();

    private model: VendaModel = null;

    constructor() {
        super();
    }

    private getDaysDiff(dataValidade: Date) {
        const timeDiff = Moment(dataValidade).diff(new Date());
        return Math.ceil(timeDiff / (1000 * 3600 * 24));
    }

    private getClass(venda: VendaModel) {
        const diffDays = this.getDaysDiff(venda.dataValidadeOrcamento);
        if (diffDays <= 0) {
            return "ExcRed";
        } else if (diffDays >= 10) {
            return "ExcGreen";
        }
        return "";
    }

    private getTitle(venda: VendaModel) {
        const diffDays = this.getDaysDiff(venda.dataValidadeOrcamento);
        if (diffDays <= 0) {
            return this.$t("__.ts.dtValOrcaVenc");
        } else if (diffDays >= 10) {
            return this.$t("__.ts.valOrcaEmDia");
        }
        return this.$t("__.ts.valOrcaMenos10DiasVenc");
    }

    private async onVerMais() {
        this.shortCutOrcamentoList.title = this.$t("__.ts.orcamentos") as string;
        this.showOrcamentoList = true;

        while (!this.$refs.orcamentoListComponent) await Delay(50);
        this.orcamentoListComponent = this.$refs.orcamentoListComponent as OrcamentoListComponent;

        this.shortCutOrcamentoList.show();
    }

    private onConfirmList() {
        this.shortCutOrcamentoList.hide();
    }

    private async onClientesList() {
        this.shortCutClientesList.title = this.$t("__.Crud.cliente.list_vue_html.clientes") as string;
        this.showClientesList = true;

        while (!this.$refs.clientesListComponent) await Delay(50);
        this.clientesListComponent = this.$refs.clientesListComponent as ClientesListComponent;

        this.shortCutClientesList.show();
    }

    private onConfirmClientesList() {
        this.shortCutClientesList.hide();
    }

    private onSearch(orcamento: VendaModel) {
        this.$router.push("/venda-edicao/" + orcamento.id);
    }

    public async onImprimirOrcamento(orcamento: VendaModel) {
        await this.orcamentoService.onImprimirOrcamento(orcamento);
    }

    private async onArquivar(orcamento: VendaModel) {
        while (!this.$refs.arquivarOrcamentoComponent) await Delay(50);
        this.arquivarOrcamentoComponent = this.$refs.arquivarOrcamentoComponent as ArquivarOrcamentoComponent;
        this.arquivarOrcamentoComponent.show(orcamento.id);
    }

    private onOrcamentoArquivado() {
        this.loadVendasOrcamentos(true);
    }

    private async onCompartilharOrcamento(orcamento: VendaModel) {
        const complemento = this.usuarioLogado.franquia.complementoWhatsAppOrcamento;
        const model = await this.vendaService.get(orcamento.id).resolveWithJSON<VendaModel>().withLoading();
        const msgWhats = this.whatsAppUtilsService.getMensagemWhats(model, complemento);
        this.whatsAppUtilsService.showMensagemWhats(model.clienteTelefone, msgWhats);
    }

    private async onAprovarOrcamento(orcamento: VendaModel) {
        this.model = null;
        await this.load(orcamento.id);

        if (this.model != null) {
            const itensManipulacao = this.model.itens.filter(p => p.manipulacaoOrdem != null);

            const manipulacoesRevisar = itensManipulacao
                .map(p => p.manipulacaoOrdem)
                .filter(
                    p =>
                        (p.posologiaId == null && !p.posologiaTexto) ||
                        p.pacienteId == null ||
                        p.prescritorId == null ||
                        (p.possuiAtivoControlado && p.numeroReceita == null),
                );

            if (itensManipulacao.length > 0) {
                const openModal = await this.revisao(manipulacoesRevisar).withLoading();
                if (!openModal) {
                    if (await this.orcamentoService.aprovarSalvarVenda(this.model)) {
                        await this.save();
                    }
                }
            } else {
                const itensFracionamento = this.model.itens.filter(f => f.fracionamento != null);

                if (await this.orcamentoService.aprovarSalvarVenda(this.model, itensFracionamento.length > 0)) {
                    await this.save();
                }
                this.loadVendasOrcamentos(true);
            }
        }
    }

    private async revisao(manipulacoesRevisar: Array<ManipulacaoOrdemModel>) {
        this.shortCutRevisaoCampos.title = this.$t("__.ts.revPreenchCampos") as string;

        this.showRevisao = true;
        while (!this.$refs.revisaoCamposComponent) await Delay(50);
        this.revisaoCamposComponent = this.$refs.revisaoCamposComponent as RevisaoCamposComponent;

        let openModal = manipulacoesRevisar.length > 0;
        if (!openModal) {
            const dataEntregaObrigatoria = this.revisaoCamposComponent.getDataEntregaObrigatoria();
            if (dataEntregaObrigatoria && !this.model.dataEntrega) {
                openModal = true;
            }
        }

        if (openModal) {
            this.revisaoCamposComponent.setPreLoadPack(this.preLoadList);
            this.revisaoCamposComponent.loadModels(manipulacoesRevisar, this.model);

            this.shortCutRevisaoCampos.show();
        }

        return openModal;
    }

    private async onConfirmRevisao() {
        try {
            const validou = await this.revisaoCamposComponent.validaRevisaoCampos();
            if (validou) {
                this.shortCutRevisaoCampos.hide();

                if (await this.orcamentoService.aprovarSalvarVenda(this.model)) {
                    await this.save();
                }
            }
        } catch {}
    }

    private async save() {
        try {
            const response = await this.service.update(this.model).withLoading().resolveWithResponse();

            let codigo = Number(response.headers.get("Codigo"));
            if (this.model.codigo > 0 && !codigo) {
                codigo = this.model.codigo;
            }

            const orcamentos = Number(this.fastActionPack.countPack.orcamentos) - 1;
            this.SET_COUNTPACK({ orcamentos });
            this.SET_LASTCOUNTPACK({ orcamentos });
            this.CLEAR_LISTSPACK();

            const quantidadeManipulacoes = this.model.itens.filter(p => p.manipulacaoOrdem != null).length;
            if (quantidadeManipulacoes > 0) {
                const manipulacoesAConcluir =
                    Number(this.fastActionPack.countPack.manipulacoesAConcluir) + quantidadeManipulacoes;
                this.SET_COUNTPACK({ manipulacoesAConcluir });
            }

            const tituloMensagem =
                this.model.situacao == SituacoesVenda.Orcamento ? this.$t("__.ts.orcamento") : this.$t("__.ts.venda");

            await this.$showSuccess(
                tituloMensagem + (this.$t("__.ts.codzin") as string) + codigo,
                this.$t("__.ts.salvoSucess"),
            );

            this.orcamentos = this.orcamentos.filter(p => p.id != this.model.id);

            if (this.model.situacao != SituacoesVenda.Orcamento) {
                this.onImprimir(this.model);
            }
        } catch {
            await this.orcamentoService.updateDadosAprovarOrcamento(this.model);
        }
    }

    private async load(id = 0) {
        this.model = new VendaModel();

        if (id > 0) {
            try {
                const data = await this.service.get(id).withLoading().resolveWithJSON<VendaModel>();

                this.model.updateFrom(data);
            } catch {}
        }
    }

    private async onAdiar(orcamento: VendaModel) {
        const sucesso = await this.orcamentoService.adiar(orcamento);
        if (sucesso) {
            this.loadVendasOrcamentos();
        }
    }

    private async loadVendasOrcamentos(forceReload = false) {
        const orcamentosPreLoads = this.fastActionLists.orcamentos;

        if (orcamentosPreLoads.length > 0 && !forceReload) {
            this.orcamentos = orcamentosPreLoads as Array<VendaModel>;
        } else {
            try {
                const customParameters: VendaListParameters = {
                    radio: "0",
                };
                const data = await this.service
                    .listOrcamento("", "datavalidadeorcamento", "desc", 1, 10, customParameters)
                    .withLoading()
                    .resolveWithJSON<PaginationModel<VendaModel>>();
                this.SET_LISTSPACK({ orcamentos: data.list });
                this.orcamentos = data.list;
            } catch {}
        }
    }

    private async getInfos() {
        try {
            const data = await this.service
                .getInfosHome(SituacoesVenda.Orcamento)
                .resolveWithJSON<{ valorHoje: number; quantidadeCriadoHoje: number }>();
            this.totalOrcadosHoje = data.valorHoje;
            this.totalOrcamentosCriadosHoje = data.quantidadeCriadoHoje;
        } catch {}
    }

    private async onImprimir(venda: VendaModel) {
        if (this.VALIDAR_PERMISSAO_USUARIO("vendas", "ImprimirVenda")) {
            await this.impressaoComponent
                .show({
                    modelId: venda.id,
                    tipoImpressao: "Venda",
                    impressaoTodosModelo: true,
                })
                .withLoading();
        }
    }

    private validarVisibilidadeBotao(acao: string) {
        return this.VALIDAR_PERMISSAO_USUARIO("vendas", acao);
    }

    private formatDate(date: Date) {
        return editDateWithTime(date);
    }

    private mounted() {
        this.impressaoComponent = this.$refs.impressaoComponent as ImpressaoComponent;
        this.shortCutRevisaoCampos = this.$refs.shortCutRevisaoCampos as ShortcutComponent;
        this.shortCutOrcamentoList = this.$refs.shortCutOrcamentoList as ShortcutComponent;
        this.shortCutClientesList = this.$refs.shortCutClientesList as ShortcutComponent;

        Promise.all([this.getInfos(), this.loadVendasOrcamentos()])
            .withLoading()
            .then(() => {
                this.SET_LASTCOUNTPACK({ orcamentos: Number(this.fastActionPack.countPack.orcamentos) });
            })
            .catch(() => {});
    }
}
