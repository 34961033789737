import PagSeguroModel from "@/models/pagSeguroModel";
import { getPaginationParams } from "@/utils/common/http";
import nfetch from "@/utils/events/nfetch";

export default class PagSeguroService {
    private controllerName = "PagSeguroWebhook";

    public getList(
        filterKey: string,
        sortKey: string,
        sortOrder: string,
        pageIndex: number,
        pageSize: number,
    ): Promise<Response> {
        const pagination = getPaginationParams(filterKey, sortKey, sortOrder, pageIndex, pageSize);

        return nfetch(`/${this.controllerName}/List?${pagination}`, {
            credentials: "same-origin",
        });
    }

    public reprocessar(payloads: PagSeguroModel[]): Promise<Response> {
        return nfetch(`/${this.controllerName}/Reprocessar`, {
            method: "post",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json; charset=UTF-8",
            },
            body: JSON.stringify(payloads),
        });
    }
}
