var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "form",
      {
        staticClass: "form-incluir-item",
        attrs: { novalidate: "" },
        on: {
          submit: function ($event) {
            $event.preventDefault()
            _vm.incluirItem()
          },
        },
      },
      [
        _c(
          "fieldset-component",
          {
            ref: "fieldsetComponent",
            attrs: {
              title: _vm.$t("__.Crud.formafarmaceutica.item_vue_html.item"),
              collapsed: false,
            },
          },
          [
            _c(
              "div",
              { attrs: { slot: "rows" }, slot: "rows" },
              [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-sm-2" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("subGrupo"),
                            expression: "errors.first('subGrupo')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "subGrupo" },
                          },
                          [
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Crud.formafarmaceutica.item_vue_html.subGrup"
                                  )
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("combo-component", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required",
                              expression: "'required'",
                            },
                          ],
                          attrs: {
                            id: "subGrupo",
                            name: "subGrupo",
                            data: _vm.subGrupoOptions,
                          },
                          model: {
                            value: _vm.model.subGrupo,
                            callback: function ($$v) {
                              _vm.$set(_vm.model, "subGrupo", $$v)
                            },
                            expression: "model.subGrupo",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      class: "col-sm-" + [_vm.model.subGrupo == 2 ? "4" : "5"],
                    },
                    [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: _vm.errors.first("produtoItemId"),
                              expression: "errors.first('produtoItemId')",
                            },
                          ],
                          staticClass: "form-group",
                        },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "control-label",
                              attrs: { for: "produtoItemId" },
                            },
                            [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(
                                    _vm.$t(
                                      "__.Crud.formafarmaceutica.item_vue_html.prod"
                                    )
                                  ) +
                                  "\n                            "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("combo-component", {
                            attrs: {
                              id: "produtoItemId",
                              name: "produtoItemId",
                              data: _vm.produtosOptions,
                              customView: true,
                              searchable: true,
                            },
                            model: {
                              value: _vm.model.produtoId,
                              callback: function ($$v) {
                                _vm.$set(_vm.model, "produtoId", $$v)
                              },
                              expression: "model.produtoId",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-3" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("tipoCalculo"),
                            expression: "errors.first('tipoCalculo')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "tipoCalculo" },
                          },
                          [
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Crud.formafarmaceutica.item_vue_html.tipoCalc"
                                  )
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("combo-component", {
                          attrs: {
                            id: "tipoCalculo",
                            name: "tipoCalculo",
                            data: _vm.tiposCalculoOptions,
                            searchable: true,
                          },
                          model: {
                            value: _vm.model.tipoCalculo,
                            callback: function ($$v) {
                              _vm.$set(_vm.model, "tipoCalculo", $$v)
                            },
                            expression: "model.tipoCalculo",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-2" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("quantidade"),
                            expression: "errors.first('quantidade')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "quantidade" },
                          },
                          [
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Crud.formafarmaceutica.item_vue_html.qnt"
                                  )
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("decimal-component", {
                          staticClass: "form-control",
                          attrs: { id: "quantidade", name: "quantidade" },
                          model: {
                            value: _vm.model.quantidade,
                            callback: function ($$v) {
                              _vm.$set(_vm.model, "quantidade", $$v)
                            },
                            expression: "model.quantidade",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.model.subGrupo == 2,
                          expression: "model.subGrupo == 2",
                        },
                      ],
                      staticClass: "col-sm-1",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c(
                            "label",
                            { attrs: { for: "chkAtualizarParcelar" } },
                            [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(
                                    _vm.$t(
                                      "__.Crud.formafarmaceutica.item_vue_html.desmemb"
                                    )
                                  ) +
                                  "\n                            "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("checkbox-component", {
                            attrs: {
                              id: "checkDesmebrado",
                              name: "checkDesmebrado",
                            },
                            model: {
                              value: _vm.model.desmembrado,
                              callback: function ($$v) {
                                _vm.$set(_vm.model, "desmembrado", $$v)
                              },
                              expression: "model.desmembrado",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("buttonIncluirComponent", {
                  on: { click: _vm.incluirItem },
                }),
              ],
              1
            ),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }