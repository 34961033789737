import Vue from "vue";
import { mapState, mapMutations, mapGetters } from "vuex";

import fieldsetComponent from "@/components/child/form/fieldset.vue";
import gridComponent from "@/components/child/grid/grid.vue";
import { GridAction } from "@/components/child/grid/gridAction";
import { GridColors, GridColumn, GridColumnType } from "@/components/child/grid/gridColumn";
import { Component, Prop } from "@/decorators";
import PreLoadPackModel from "@/models/auxiliar/preLoadPackModel";
import PaginationModel from "@/models/paginationModel";
import ProdutoModel from "@/models/produto/produtoModel";
import ProdutoLoteModel from "@/models/produtoLoteModel";
import ProdutoService from "@/services/produto/produtoService";
import ProdutoLoteService from "@/services/produtoLoteService";
import { AppState, Getters, Mutations } from "@/store/store";
import Delay from "@/utils/common/delay";

import shortcutComponent from "../../shortcut/shortcut.vue";

import ShortcutComponent from "./../../shortcut/shortcut";
import verLoteComponent from "./verLote.vue";

import "../crud.scss";
import "./edit.scss";

@Component({
    components: {
        fieldsetComponent,
        gridComponent,
        shortcutComponent,
        verLoteComponent,
    },
    computed: {
        ...mapState({
            preLoadPack: (state: AppState) => state.preLoad.preLoadList,
            loadedList: (state: AppState) => state.preLoad.loadedList,
        }),
        ...mapGetters(["VALIDAR_PERMISSAO_USUARIO"] as Getters),
    },
    methods: mapMutations(["LOAD_LIST"] as Mutations),
})
export default class ProdutoVerComponent extends Vue {
    // State computed props
    preLoadPack: PreLoadPackModel;
    loadedList: boolean;
    VALIDAR_PERMISSAO_USUARIO: (telaDescricao: string, acaoController: string) => boolean;

    private service = new ProdutoService();
    private serviceLote = new ProdutoLoteService();
    private shortcutComponent: ShortcutComponent = null;

    private model: ProdutoModel = new ProdutoModel();
    private modelId = 0;
    private loadLote = false;
    private loteId = 0;

    private franquiaId: number = null;

    private totalLote = 0;
    private unidadeEstoqueDescricao = "";
    private unidadeManipulacaoDescricao = "";
    private showFinanceiro = false;

    extraActions: Array<object> = [];
    gridExtraActionsVer: Array<GridAction> = [];

    gridColumnsFormaFarmaceutica: Array<GridColumn> = [
        new GridColumn("descricao", "Descri\u00e7\u00e3o", GridColumnType.String),
    ];

    gridColumnsFormaFarmaceuticaEmb: Array<GridColumn> = [
        new GridColumn("descricao", "Descri\u00e7\u00e3o", GridColumnType.String),
    ];

    gridColumnsProdutoAssociacoes: Array<GridColumn> = [
        new GridColumn("formaFarmaceuticaDescricao", "Forma Farm.", GridColumnType.String),
        new GridColumn("produtoAssociadoDescricao", "Produto", GridColumnType.String),
        new GridColumn("percentual", "Percentual", GridColumnType.Decimal),
        new GridColumn("excipiente", "Excipiente", GridColumnType.Boolean),
        new GridColumn("desmembrado", "Desmembrado", GridColumnType.Boolean),
    ];

    gridDataLote: Array<ProdutoLoteModel> = [];
    get gridColumnLote(): Array<GridColumn> {
        return [
            new GridColumn("descricao", "Descri\u00e7\u00e3o", GridColumnType.String),
            new GridColumn("fornecedorNome", "Fornecedor", GridColumnType.String),
            new GridColumn("fatorCorrecao", "F.C", GridColumnType.Decimal),
            new GridColumn("valorCusto", "Custo Unitário", GridColumnType.Money, false, !this.showFinanceiro),
            new GridColumn("dataValidade", "Validade", GridColumnType.Date),
            new GridColumn("dataFabricacao", "Fabrica\u00e7\u00e3o", GridColumnType.Date),
            new GridColumn("quantidadeAtual", "Quantidade", GridColumnType.Decimal),
        ];
    }

    gridColumnsProdutoSinonimos: Array<GridColumn> = [
        new GridColumn("sinonimo", "Sinônimo", GridColumnType.String),
        new GridColumn("fatorEquivalencia", "Fator Equivalência", GridColumnType.Decimal),
    ];
    gridColumnsProdutosIncompativeis: Array<GridColumn> = [
        new GridColumn("produtoIncompativelDescricao", "Descri\u00e7\u00e3o", GridColumnType.String),
    ];

    @Prop({ type: Boolean, default: false }) isModal: boolean;
    @Prop(String) descricaoSugestao: string;

    public constructor() {
        super();
    }

    public async load(id: number = null, quantidadeMinima = 0) {
        this.model = new ProdutoModel();
        this.unidadeManipulacaoDescricao = "";
        this.unidadeEstoqueDescricao = "";
        this.totalLote = 0;

        if (id > 0) {
            this.modelId = id;

            try {
                const data = await this.service.get(id, quantidadeMinima).then(r => r.json() as Promise<ProdutoModel>);
                this.model.updateFrom(data);

                if (this.model.unidadeMedidaEstoqueId > 0) {
                    const unMed = this.preLoadPack.unidadesMedida.filter(
                        p => p.id == this.model.unidadeMedidaEstoqueId,
                    );
                    if (unMed.length > 0) {
                        this.unidadeEstoqueDescricao = unMed[0].sigla + " - " + unMed[0].descricao;
                    }
                }

                if (this.model.unidadeMedidaManipulacaoId > 0) {
                    const unMed = this.preLoadPack.unidadesMedida.filter(
                        p => p.id == this.model.unidadeMedidaManipulacaoId,
                    );
                    if (unMed.length > 0) {
                        this.unidadeManipulacaoDescricao = unMed[0].sigla + " - " + unMed[0].descricao;
                    }
                }

                this.franquiaId = data.franquiaId;

                const lotes = await this.serviceLote
                    .listFilterProduto(data.id, null, null, 1, 999999)
                    .then(r => r.json() as Promise<PaginationModel<ProdutoLoteModel>>);

                this.gridDataLote = lotes.list;
                this.gridExtraActionsVer.splice(0);
                this.extraActions.splice(0);

                this.totalLote = 0;
                for (let i = 0; i < this.gridDataLote.length; i++) {
                    this.totalLote += this.gridDataLote[i].quantidadeAtual;

                    this.gridExtraActionsVer.push(
                        new GridAction("ver", "Ver Lote", "fa fa-eye", GridColors.BLUE, true),
                    );
                }

                this.extraActions.push(this.gridExtraActionsVer);
            } catch {
                this.$router.back();
            }
        } else if (this.descricaoSugestao != null && this.isModal) {
            this.model.descricao = this.descricaoSugestao;
        }
    }

    private async getPreLoadPack() {
        while (!this.loadedList) await Delay(100);
    }

    private getSiglaEstoque() {
        if (this.model.unidadeMedidaEstoqueId > 0) {
            const unMed = this.preLoadPack.unidadesMedida.filter(p => p.id == this.model.unidadeMedidaEstoqueId);
            if (unMed.length > 0) {
                return unMed[0].sigla;
            }
        } else {
            return "";
        }
    }

    private async onExtraAction(name: string, model: ProdutoLoteModel) {
        if (name.trim() == "ver") {
            this.onVer(model).withLoading();
        }
    }

    private async onVer(lote: ProdutoLoteModel) {
        this.loadLote = true;
        this.loteId = lote.id;
        this.shortcutComponent = this.$refs.shortcutComponent as ShortcutComponent;
        this.shortcutComponent.title = "Lote";
        this.shortcutComponent.show();
    }

    private mounted() {
        Promise.all([this.getPreLoadPack()])
            .withLoading()
            .then(async () => {
                if (this.$route.params.id && this.isModal == false) {
                    this.modelId = +this.$route.params.id;
                    await this.load().withLoading();
                } else if (this.isModal && this.descricaoSugestao != null) {
                    this.model.descricao = this.descricaoSugestao;
                }

                this.showFinanceiro = this.VALIDAR_PERMISSAO_USUARIO("manipulacoes_venda", "ConferirCustosManipulacao");
            })
            .catch(() => {});
    }
}
