import { Module } from "vuex";

import CountPackModel from "@/models/auxiliar/countPackModel";
import ManipulacaoOrdemModel from "@/models/manipulacaoOrdemModel";
import ProdutoEstoqueConferenciaModel from "@/models/produtoEstoqueConferenciaModel";
import ProdutoLoteModel from "@/models/produtoLoteModel";
import VendaContinuoModel from "@/models/vendaContinuoModel";
import VendaModel from "@/models/vendaModel";
import FastActionService from "@/services/fastActionService";

import { AppState } from "./store";

export type FastActionPack = {
    countPack: CountPackModel;
    lastCountPack: FastActionLastPack;
};

export type FastActionLastPack = {
    continuos?: number;
    orcamentos?: number;
    vendasAFaturar?: number;
    manipulacoesAConcluir?: number;
    estoqueDivergencias?: number;
    estoqueFracionamento?: number;
    expedicao?: number;
    fracionamento?: number;
    entregas?: number;
};

export type FastActionList = {
    continuos?: VendaContinuoModel[];
    orcamentos?: VendaModel[];
    vendasAFaturar?: VendaModel[];
    manipulacoes?: ManipulacaoOrdemModel[];
    manipulacoesAConcluir?: ManipulacaoOrdemModel[];
    estoqueDivergencias?: ProdutoEstoqueConferenciaModel[];
    estoqueFracionamento?: VendaModel[];
    expedicao?: VendaModel[];
    fracionamento?: ProdutoLoteModel[];
    entregas?: VendaModel[];
};

export type FastActionState = {
    fastActionMenuPos: string;
    fastActionPack: FastActionPack;
    fastActionLists: FastActionList;
};

export type FastActionMutations =
    | "SET_FASTACTIONMENUPOS"
    | "SET_COUNTPACK"
    | "SET_LASTCOUNTPACK"
    | "SET_LISTSPACK"
    | "CLEAR_LISTSPACK"
    | "CLEAR_LISTVENDA"
    | "LOAD_COUNTPACK";

const fastAction: Module<FastActionState, AppState> = {
    state: {
        ///apenas para conrole de pocisionamento no fastAction
        fastActionMenuPos: "financeiro",
        //serve para controlar as quantidades de informacoes de cada action
        fastActionPack: {
            countPack: {
                continuos: 0,
                orcamentos: 0,
                vendasAFaturar: 0,
                manipulacoesAConcluir: 0,
                estoqueDivergencias: 0,
                estoqueDados: {
                    estoqueBaixo: 0,
                    vencendo: 0,
                    semEstoque: 0,
                },
                estoqueFracionamento: 0,
                expedicao: 0,
                fracionamento: 0,
                entregas: 0,
            } as CountPackModel,
            //ultimos qt visualizados
            lastCountPack: {
                continuos: 0,
                orcamentos: 0,
                vendasAFaturar: 0,
                manipulacoesAConcluir: 0,
                estoqueDivergencias: 0,
                estoqueFracionamento: 0,
                expedicao: 0,
                fracionamento: 0,
                entregas: 0,
            },
        },

        fastActionLists: {
            continuos: [],
            orcamentos: [],
            manipulacoes: [],
            manipulacoesAConcluir: [],
            vendasAFaturar: [],
            estoqueDivergencias: [],
            estoqueFracionamento: [],
            expedicao: [],
            fracionamento: [],
            entregas: [],
        },
    },
    mutations: {
        //seta a pocisao menu
        SET_FASTACTIONMENUPOS(store, obj) {
            store.fastActionMenuPos = obj;
        },
        SET_COUNTPACK(store, data: FastActionLastPack) {
            if (data.continuos != null) {
                store.fastActionPack.countPack.continuos = data.continuos;
            }
            if (data.orcamentos != null) {
                store.fastActionPack.countPack.orcamentos = data.orcamentos;
            }
            if (data.vendasAFaturar != null) {
                store.fastActionPack.countPack.vendasAFaturar = data.vendasAFaturar;
            }
            if (data.manipulacoesAConcluir != null) {
                store.fastActionPack.countPack.manipulacoesAConcluir = data.manipulacoesAConcluir;
            }
            if (data.estoqueDivergencias != null) {
                store.fastActionPack.countPack.estoqueDivergencias = data.estoqueDivergencias;
            }
            if (data.estoqueFracionamento != null) {
                store.fastActionPack.countPack.estoqueFracionamento = data.estoqueFracionamento;
            }
            if (data.expedicao != null) {
                store.fastActionPack.countPack.expedicao = data.expedicao;
            }
            if (data.fracionamento != null) {
                store.fastActionPack.countPack.fracionamento = data.fracionamento;
            }
            if (data.entregas != null) {
                store.fastActionPack.countPack.entregas = data.entregas;
            }
        },
        SET_LASTCOUNTPACK(store, data: FastActionLastPack) {
            if (data.continuos != null) {
                store.fastActionPack.lastCountPack.continuos = data.continuos;
            }
            if (data.orcamentos != null) {
                store.fastActionPack.lastCountPack.orcamentos = data.orcamentos;
            }
            if (data.vendasAFaturar != null) {
                store.fastActionPack.lastCountPack.vendasAFaturar = data.vendasAFaturar;
            }
            if (data.manipulacoesAConcluir != null) {
                store.fastActionPack.lastCountPack.manipulacoesAConcluir = data.manipulacoesAConcluir;
            }
            if (data.estoqueDivergencias != null) {
                store.fastActionPack.lastCountPack.estoqueDivergencias = data.estoqueDivergencias;
            }
            if (data.estoqueFracionamento != null) {
                store.fastActionPack.lastCountPack.estoqueFracionamento = data.estoqueFracionamento;
            }
            if (data.expedicao != null) {
                store.fastActionPack.lastCountPack.expedicao = data.expedicao;
            }
            if (data.fracionamento != null) {
                store.fastActionPack.lastCountPack.fracionamento = data.fracionamento;
            }
            if (data.entregas != null) {
                store.fastActionPack.lastCountPack.entregas = data.entregas;
            }
        },
        SET_LISTSPACK(store, data: FastActionList) {
            if (data.continuos != null) {
                store.fastActionLists.continuos = new Array<VendaContinuoModel>();
                store.fastActionLists.continuos = data.continuos;
            }
            if (data.orcamentos != null) {
                store.fastActionLists.orcamentos = new Array<VendaModel>();
                store.fastActionLists.orcamentos = data.orcamentos;
            }
            if (data.vendasAFaturar != null) {
                store.fastActionLists.vendasAFaturar = new Array<VendaModel>();
                store.fastActionLists.vendasAFaturar = data.vendasAFaturar;
            }
            if (data.manipulacoesAConcluir != null) {
                store.fastActionLists.manipulacoes = new Array<ManipulacaoOrdemModel>();
                store.fastActionLists.manipulacoes = data.manipulacoesAConcluir;
            }
            if (data.estoqueDivergencias != null) {
                store.fastActionLists.estoqueDivergencias = new Array<ProdutoEstoqueConferenciaModel>();
                store.fastActionLists.estoqueDivergencias = data.estoqueDivergencias;
            }
            if (data.estoqueFracionamento != null) {
                store.fastActionLists.estoqueFracionamento = new Array<VendaModel>();
                store.fastActionLists.estoqueFracionamento = data.estoqueFracionamento;
            }
            if (data.expedicao != null) {
                store.fastActionLists.expedicao = new Array<VendaModel>();
                store.fastActionLists.expedicao = data.expedicao;
            }
            if (data.fracionamento != null) {
                store.fastActionLists.fracionamento = new Array<ProdutoLoteModel>();
                store.fastActionLists.fracionamento = data.fracionamento;
            }
            if (data.entregas != null) {
                store.fastActionLists.entregas = new Array<VendaModel>();
                store.fastActionLists.entregas = data.entregas;
            }
        },
        async CLEAR_LISTSPACK(store) {
            store.fastActionLists.continuos = null;
            store.fastActionLists.continuos = new Array<VendaContinuoModel>();

            store.fastActionLists.orcamentos = null;
            store.fastActionLists.orcamentos = new Array<VendaModel>();

            store.fastActionLists.vendasAFaturar = null;
            store.fastActionLists.vendasAFaturar = new Array<VendaModel>();

            store.fastActionLists.manipulacoes = null;
            store.fastActionLists.manipulacoes = new Array<ManipulacaoOrdemModel>();

            store.fastActionLists.estoqueDivergencias = null;
            store.fastActionLists.estoqueDivergencias = new Array<ProdutoEstoqueConferenciaModel>();

            store.fastActionLists.estoqueFracionamento = null;
            store.fastActionLists.estoqueFracionamento = new Array<VendaModel>();

            store.fastActionLists.expedicao = null;
            store.fastActionLists.expedicao = new Array<VendaModel>();

            store.fastActionLists.fracionamento = null;
            store.fastActionLists.fracionamento = new Array<ProdutoLoteModel>();

            store.fastActionLists.entregas = null;
            store.fastActionLists.entregas = new Array<VendaModel>();
        },
        async CLEAR_LISTVENDA(store) {
            store.fastActionLists.vendasAFaturar = null;
            store.fastActionLists.vendasAFaturar = new Array<VendaModel>();
        },
        async LOAD_COUNTPACK(store) {
            //chama controller que busca as quantidades de todos e retorna no model
            await new FastActionService()
                .loadCountPack()
                .then(result => {
                    return result.json() as Promise<CountPackModel>;
                })
                .then(data => {
                    store.fastActionPack.countPack = new CountPackModel();
                    store.fastActionPack.countPack = data;
                })
                .catch(() => {});
        },
    },
};

export default fastAction;
