var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "crud crud-edit" },
    [
      _c("actionBarComponent", {
        attrs: {
          title: _vm.$t(
            "__.Components.parent.siproquim.edit_vue_html.siproquimGeracao"
          ),
          saveText: _vm.$t(
            "__.Components.parent.siproquim.edit_vue_html.gerarArquivo"
          ),
        },
        on: { onSave: _vm.save },
      }),
      _vm._v(" "),
      _c(
        "form",
        {
          attrs: { novalidate: "novalidate" },
          on: {
            submit: function ($event) {
              $event.preventDefault()
              _vm.save()
            },
          },
        },
        [
          _c("fieldset", [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-sm-4" }, [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip",
                        value: _vm.errors.first("dataInicial"),
                        expression: "errors.first('dataInicial')",
                      },
                    ],
                    staticClass: "form-group",
                  },
                  [
                    _c(
                      "label",
                      {
                        staticClass: "control-label",
                        attrs: { for: "dataInicial" },
                      },
                      [
                        _vm._v(
                          "\n                            " +
                            _vm._s(
                              _vm.$t(
                                "__.Components.parent.siproquim.edit_vue_html.dataInicial"
                              )
                            ) +
                            "\n                        "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("date-time-picker-component", {
                      attrs: { id: "dataInicial", name: "dataInicial" },
                      model: {
                        value: _vm.dataInicial,
                        callback: function ($$v) {
                          _vm.dataInicial = $$v
                        },
                        expression: "dataInicial",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-sm-4" }, [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip",
                        value: _vm.errors.first("dataFinal"),
                        expression: "errors.first('dataFinal')",
                      },
                    ],
                    staticClass: "form-group",
                  },
                  [
                    _c(
                      "label",
                      {
                        staticClass: "control-label",
                        attrs: { for: "dataFinal" },
                      },
                      [
                        _vm._v(
                          "\n                            " +
                            _vm._s(
                              _vm.$t(
                                "__.Components.parent.siproquim.edit_vue_html.dataFinal"
                              )
                            ) +
                            "\n                        "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("date-time-picker-component", {
                      attrs: { id: "dataFinal", name: "dataFinal" },
                      model: {
                        value: _vm.dataFinal,
                        callback: function ($$v) {
                          _vm.dataFinal = $$v
                        },
                        expression: "dataFinal",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-sm-4" }, [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip",
                        value: _vm.errors.first("geraCadastroProdutos"),
                        expression: "errors.first('geraCadastroProdutos')",
                      },
                    ],
                    staticClass: "form-group",
                  },
                  [
                    _c(
                      "label",
                      {
                        staticClass: "control-label",
                        attrs: { for: "geraCadastroProdutos" },
                      },
                      [
                        _vm._v(
                          "\n                            " +
                            _vm._s(
                              _vm.$t(
                                "__.Components.parent.siproquim.edit_vue_html.geraCadastroProdutos"
                              )
                            ) +
                            "\n                        "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("checkbox-component", {
                      attrs: {
                        id: "geraCadastroProdutos",
                        name: "geraCadastroProdutos",
                      },
                      model: {
                        value: _vm.geraCadastroProdutos,
                        callback: function ($$v) {
                          _vm.geraCadastroProdutos = $$v
                        },
                        expression: "geraCadastroProdutos",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("fieldset", [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-sm-12 newbtn" }, [
                _c("div", { staticClass: "form-group" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-success",
                      attrs: { type: "submit" },
                    },
                    [
                      _vm._v(
                        "\n                            " +
                          _vm._s(
                            _vm.$t(
                              "__.Components.parent.siproquim.edit_vue_html.gerarArquivo"
                            )
                          ) +
                          "\n                        "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-secondary",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          _vm.cancel()
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n                            " +
                          _vm._s(_vm.$t("__.Crud.voltar")) +
                          "\n                        "
                      ),
                    ]
                  ),
                ]),
              ]),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }