import ProdutoEstoqueConferenciaService from "@/services/produtoEstoqueConferenciaService";

import BaseModel from "./base/baseModel";
import OperacaoMatematica from "./enum/operacaoMatematica";
import TiposCalculoManipulacao from "./enum/tiposCalculoManipulacao";
import ProdutoEstoqueConferenciaLoteModel, { StatusEstoque } from "./produtoEstoqueConferenciaLoteModel";

export default class ProdutoEstoqueConferenciaModel extends BaseModel {
    id?: number = null;
    descricao: string = null;
    grupoProdutoId?: number = null;
    tipoCalculo?: TiposCalculoManipulacao = null;
    unidadeMedidaManipulacaoSigla: string = null;
    unidadeMedidaManipulacaoId?: number = null;
    unidadeMedidaEstoqueId?: number = null;
    movimentacaoEstoqueId?: number = null;
    quantidade = 0;
    quantidadeTotal = 0;
    conversaoOperacao?: OperacaoMatematica = null;
    conversaoValor = 0;
    estoqueMinimo?: number = null;
    estoqueMaximo?: number = null;
    indiceConversao?: number = null;
    ignorarControleEstoque = false;
    sequencial?: number = null;
    statusEstoque?: StatusEstoque = null;
    produtoLoteId?: number = null;

    produtoLotes?: Array<ProdutoEstoqueConferenciaLoteModel> = [];

    //funcoes do service
    async GetProdutosEstoquesMovimentosVerificados(movimentacaoEstoqueId: number, manipulacaoOrdemId: number) {
        ///passar parametro de class name
        return await new ProdutoEstoqueConferenciaService()
            .listProdutosMovimentosVerificados(movimentacaoEstoqueId, manipulacaoOrdemId)
            .then(r => r.json() as Promise<Array<ProdutoEstoqueConferenciaModel>>);
    }

    async AtualizaDadosLotes(
        model: ProdutoEstoqueConferenciaModel[],
        movimentacaoEstoqueId?: number,
        manipulacaoOrdemItemIds?: number[],
    ) {
        return await new ProdutoEstoqueConferenciaService()
            .AtualizarDadosLote(model, movimentacaoEstoqueId, manipulacaoOrdemItemIds)
            .resolveWithJSON<boolean>();
    }
}

export interface ProdutoEstoqueConferenciaListParameters {
    semEstoque: boolean;
    vencendo: boolean;
    estoqueBaixo: boolean;
    combo?: string;
    sub?: string;
}
