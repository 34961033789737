import Vue from "vue";

import shortcutComponent from "@/components/parent/shortcut/shortcut.vue";
import { Component, Prop } from "@/decorators";
import UsuarioService from "@/services/usuarioService";

import ShortcutComponent from "../shortcut/shortcut";
import "../crud/crud.scss";

@Component({
    components: {
        shortcutComponent,
    },
})
export default class SolicitarSenhaComponent extends Vue {
    @Prop(String) title: string;

    private usuarioService = new UsuarioService();

    private shortcutComponent: ShortcutComponent = null;

    private senhaUsuarioAdmin = "";

    public show() {
        this.senhaUsuarioAdmin = "";
        this.shortcutComponent.title = "Solicitação de senha do Administrador Franqueado";
        this.shortcutComponent.show();
    }

    private async save() {
        const isValid = await this.$validator.validateAll();
        if (isValid) {
            const sucesso = await this.usuarioService
                .validarSenhaUsuarioAdmin(this.senhaUsuarioAdmin)
                .withLoading()
                .resolveWithJSON<boolean>();

            this.shortcutComponent.hidewithoudOutputEvent();

            this.$emit("onResult", sucesso);
        }
    }

    private async hide() {
        this.$emit("onCancel");
    }

    private async mounted() {
        this.shortcutComponent = this.$refs.shortcutComponent as ShortcutComponent;
    }
}
