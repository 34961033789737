import TipoOperacao from "@/models/enum/tipoOperacao";

import CartaCorrecaoModel from "../models/cartaCorrecaoModel";
import nfetch from "../utils/events/nfetch";

export default class CartaCorrecaoService {
    private controllerName = "CartaCorrecao";

    public get(id: number): Promise<Response> {
        return nfetch(`/${this.controllerName}/Get?id=${id}`, {
            credentials: "same-origin",
        });
    }

    public insert(model: CartaCorrecaoModel): Promise<Response> {
        return nfetch(`/${this.controllerName}/Insert`, {
            method: "post",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json; charset=UTF-8",
            },
            body: JSON.stringify(model),
        });
    }

    public getByNotaFiscal(id: number, tipoOperacao: TipoOperacao): Promise<Response> {
        return nfetch(`/${this.controllerName}/getByNotaFiscal?notaFiscalId=${id}&tipoOperacao=${tipoOperacao}`, {
            credentials: "same-origin",
        });
    }
}
