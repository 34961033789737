import Vue from "vue";

import portletComponent from "@/components/child/containers/portlet.vue";
import buttonScComponent from "@/components/child/form/buttonSc.vue";
import checkboxComponent from "@/components/child/form/checkbox.vue";
import comboComponent from "@/components/child/form/combo.vue";
import decimalComponent from "@/components/child/form/decimal.vue";
import decimalComSinalCustomComponent from "@/components/child/form/decimalComSinalCustom.vue";
import ImpressaoComponent from "@/components/child/impressao/impressaoComponent";
import impressaoComponent from "@/components/child/impressao/impressaoComponent.vue";
import { Component, Prop } from "@/decorators";
import EstoqueModel from "@/models/estoqueModel";
import FormulaPadraoManipulacaoModel from "@/models/formulaPadraoManipulacaoModel";
import PaginationModel from "@/models/paginationModel";
import ValidationErrorModel from "@/models/validationErrorModel";
import EstoqueService from "@/services/estoqueService";
import FormulaPadraoManipulacaoService from "@/services/formulaPadraoManipulacaoService";
import arithmeticHelper from "@/utils/common/arithmeticHelper";
import Delay from "@/utils/common/delay";

import ManipulacaoEditComponent from "../../manipulacao/edit";
import manipulacaoEditComponent from "../../manipulacao/edit.vue";
import ShortcutComponent from "../../shortcut/shortcut";
import shortcutComponent from "../../shortcut/shortcut.vue";

@Component({
    components: {
        checkboxComponent,
        portletComponent,
        decimalComponent,
        decimalComSinalCustomComponent,
        buttonScComponent,
        comboComponent,
        shortcutComponent,
        manipulacaoEditComponent,
        impressaoComponent,
    },
    computed: {
        box() {
            return {
                "border-radius": "4px",
                "margin-bottom": "15px",
                padding: "8px",
                border: `4px solid ${
                    this.status != "PENDING" ? (this.status == "SUCCESS" ? "#389e5e" : "#ff6e6e") : "#fff"
                }`,
            };
        },
        mensagemStyle() {
            return {
                "margin-bottom": "4px",
                color: this.status == "SUCCESS" ? "#389e5e" : "#ff6e6e",
            };
        },
    },
})
export default class FormulaPadraoManipulacaoItemComponent extends Vue {
    private estoquesOptions: Array<Object> = [];
    private shortcutComponent: ShortcutComponent = null;
    private manipulacaoEditComponent: ManipulacaoEditComponent = null;
    private impressaoComponent: ImpressaoComponent = null;

    private showManipulacao = false;
    private manipulacaoLoaded = false;
    private status: "SUCCESS" | "ERROR" | "PENDING" = "PENDING";
    private mensagens: string[] = [];
    private gerado = false;

    @Prop({ type: FormulaPadraoManipulacaoModel, required: true }) value: FormulaPadraoManipulacaoModel;
    @Prop({ type: Number, required: true }) index: number;

    private async loadEstoques() {
        try {
            const data = await new EstoqueService()
                .combo()
                .then(r => r.json() as Promise<PaginationModel<EstoqueModel>>);
            this.estoquesOptions = data.list.map(item => ({ value: item.id, text: item.descricao }));
            this.value.estoqueId = this.estoquesOptions[0]["value"];
        } catch {}
    }

    private getSignal(volume: string, sigla: string) {
        return `x ${volume.toString()}${sigla.toString()}`;
    }

    private remove() {
        this.$emit("remove-item", this.value.formulaPadraoId);
    }

    private async openManipulacao(id: number) {
        this.$router.push("/manipulacaoordem-edicao/" + id);
    }

    private onManipulacaoMounted() {
        this.manipulacaoLoaded = true;
    }

    private onConfirm() {
        this.shortcutComponent.hide();
    }

    private async inicializaManipulacao() {
        this.showManipulacao = true;
        while (!this.$refs.manipulacaoEditComponent) await Delay(5);
        if (!this.manipulacaoEditComponent)
            this.manipulacaoEditComponent = this.$refs.manipulacaoEditComponent as ManipulacaoEditComponent;
    }

    private async onLotes(cod: number) {
        if (!this.showManipulacao) await this.inicializaManipulacao();
        while (!this.manipulacaoLoaded) await Delay(5);

        if (this.manipulacaoLoaded && this.manipulacaoEditComponent) {
            this.manipulacaoEditComponent.onLotes().withLoading();

            this.shortcutComponent.title = "Manipulação " + cod;
            this.shortcutComponent.show();
        }
    }

    private async onImprimirManipulacaoModelo() {
        if (!this.showManipulacao) await this.inicializaManipulacao();
        while (!this.manipulacaoLoaded) await Delay(5);

        this.impressaoComponent = this.$refs.impressaoComponent as ImpressaoComponent;

        await this.impressaoComponent
            .show({
                modelId: this.manipulacaoEditComponent.model.id,
                tipoImpressao: "Manipulacao",
                impressaoRotulo: false,
                impressaoSelecionarModelo: true,
            })
            .withLoading();
    }

    private async onConfirmImpressao() {
        if (this.shortcutComponent.isShowing()) this.shortcutComponent.hide();
        else
            setTimeout(() => {
                this.shortcutComponent.hide();
            }, 500);
    }

    private onConfirmLote() {
        this.shortcutComponent.hide();
    }

    public async gerarManipulacao() {
        this.mensagens = [];
        this.gerado = true;
        try {
            if (this.value.formulaPadrao.volume > 0) {
                this.value.quantidade = arithmeticHelper.round(
                    this.value.volumeTotal / this.value.formulaPadrao.volume,
                    8,
                );
            }

            const data = await new FormulaPadraoManipulacaoService()
                .insert(this.value)
                .withLoading()
                .resolveWithJSON<FormulaPadraoManipulacaoModel>();

            data.volumeTotal = data.manipulacaoOrdem.volumeTotal;
            this.value.updateFrom(data);
            this.status = "SUCCESS";
            this.mensagens = ["Gerado com sucesso"];

            await this.$showSuccess(
                this.$t("__.ts.inclusao"),
                `${this.value.formulaPadrao.descricao} gerado com sucesso`,
            );
        } catch (err) {
            this.status = "ERROR";
            const errors = (err.length ? err : [err]) as ValidationErrorModel[];
            this.mensagens = errors.map(e => e.references[0]);
            await this.$showAlert({
                title: this.$t("__.ts.inclusao") as string,
                html: `<b>Erro na fórmula ${this.value.formulaPadrao.descricao}</b></br></br> - ${this.mensagens.join(
                    "</br></br> - ",
                )}`,
                icon: "error",
            });
        }
    }

    public async validateAsync() {
        const isValid = await this.$validator.validateAll();
        if (!isValid) this.$focusErrorField();
        return isValid;
    }

    private mounted() {
        this.shortcutComponent = this.$refs.shortcutComponent as ShortcutComponent;

        this.value.formulaPadrao.formulaPadraoItens
            .filter(p => p.sinonimoId > 0)
            .forEach(p => {
                p.produtoDescricao = p.sinonimo.sinonimo;
            });

        Promise.all([this.loadEstoques()]).withLoading();
    }
}
