import Vue from "vue";

import { Component, Prop, Watch } from "@/decorators";

import "./multiSelect.scss";

@Component
export default class MultiselectComponent extends Vue {
    @Prop(Array) options;
    @Prop({ type: Array, default: () => [] }) defaultValue;
    @Prop(Array) value;
    @Prop(Boolean) taggable: boolean;

    selected = [];

    @Watch("selected")
    private onSelectedChanged() {
        this.$emit("input", this.selected);
    }

    @Watch("defaultValue")
    private onDefaultValueChanged() {
        this.selected = this.defaultValue;
    }

    private addTag(text: string) {
        const tag = { value: Math.floor(Math.random() * 10000000), text };
        this.options.push(tag);
        this.selected.push(tag);
    }

    private mounted() {
        this.selected = this.defaultValue;
    }
}
