import { GridAction } from "@/components/child/grid/gridAction";
import { GridColors, GridColumn, GridColumnType } from "@/components/child/grid/gridColumn";
import ListComponentBase from "@/components/parent//crud/listComponentBase";
import { Component } from "@/decorators";
import ClienteModel from "@/models/clienteModel";
import PaginationModel from "@/models/paginationModel";
import ClienteService from "@/services/clienteService";

import ClientesDuplicadosUnificarComponent from "./unificar";
import clientesDuplicadosUnificarComponent from "./unificar.vue";
import ClientesDuplicadosViewComponent from "./view";
import clientesDuplicadosViewComponent from "./view.vue";
@Component({
    components: {
        clientesDuplicadosUnificarComponent,
        clientesDuplicadosViewComponent,
    },
})
export default class ClientesDuplicadosListComponent extends ListComponentBase<ClienteModel> {
    private clienteService = new ClienteService();
    private clientesDuplicadosViewComponent: ClientesDuplicadosViewComponent = null;
    private clientesDuplicadosUnificarComponent: ClientesDuplicadosUnificarComponent = null;

    extraActions: Array<object> = [];
    gridExtraActionsAjustar: Array<GridAction> = [];

    get gridColumns(): Array<GridColumn> {
        return [
            new GridColumn("id", this.$t("__.ts.codigo"), GridColumnType.String),
            new GridColumn("nome", this.$t("__.ts.nome"), GridColumnType.String),
            new GridColumn("razaoSocial", this.$t("__.ts.razaoSocial"), GridColumnType.String),
            new GridColumn("cpf", this.$t("__.ts.cpf"), GridColumnType.String),
            new GridColumn("cnpj", this.$t("__.ts.cnpj"), GridColumnType.String),
            new GridColumn("email", this.$t("__.ts.email"), GridColumnType.String),
            new GridColumn("rg", this.$t("__.ts.rg"), GridColumnType.String),
            new GridColumn("telefone", this.$t("__.ts.telefone"), GridColumnType.String),
            new GridColumn("celular", this.$t("__.ts.celular"), GridColumnType.String),
        ];
    }

    private beforeMount() {
        this.setProps(this.clienteService, "cliente", "nome");
    }

    public async load() {
        try {
            const data = await this.clienteService
                .getClientesDuplicados(
                    this.gridFilterKey,
                    this.pags ? 1 : this.pageIndex,
                    this.pags ? 999999 : this.pageSize,
                )
                .withLoading()
                .resolveWithJSON<PaginationModel<ClienteModel>>();

            this.gridData = data.list;
            this.total = data.total;
            this.pageIndex = data.pageIndex;
            this.pageSize = data.pageSize;

            this.extraActions.splice(0);
            this.gridExtraActionsAjustar.splice(0);

            this.gridData.forEach(() => {
                this.gridExtraActionsAjustar.push(
                    new GridAction("visualizar", "Visualizar duplicações", "fa fa-eye", GridColors.BLUE),
                );
            });

            this.extraActions.push(this.gridExtraActionsAjustar);

            if (this.pags) {
                if (this.tipoDocGerar == 0) {
                    setTimeout(() => {
                        this.gridcomponent.gerarXls();
                    }, 2000);
                }

                if (this.tipoDocGerar == 1) {
                    setTimeout(() => {
                        this.gridcomponent.gerarPdf();
                    }, 2000);
                }

                if (this.tipoDocGerar == 2) {
                    setTimeout(() => {
                        this.gridcomponent.gerarImpressao();
                    }, 2000);
                }
            }
        } catch {}
    }

    private onExtraAction(name: string, cliente: ClienteModel) {
        if (name.trim() == "visualizar") {
            this.clientesDuplicadosViewComponent = this.$refs
                .clientesDuplicadosViewComponent as ClientesDuplicadosViewComponent;
            this.clientesDuplicadosViewComponent.show(cliente);
        }
    }

    private onUnificar() {
        this.clientesDuplicadosUnificarComponent = this.$refs
            .clientesDuplicadosUnificarComponent as ClientesDuplicadosUnificarComponent;
        this.clientesDuplicadosUnificarComponent.show();
    }
}
