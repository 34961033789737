import BaseModel from "./base/baseModel";

export default class KitEmbalagemCapsulaModel extends BaseModel {
    constructor() {
        super();
    }
    id?: number = null;
    kitEmbalagemId?: number = null;
    capsulaId?: number = null;
    capsulaDescricao: string = null;
    quantidade = 0;
}