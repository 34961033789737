import BaseModel from "./base/baseModel";
import EstoqueModel from "./estoqueModel";
import FormulaPadraoModel from "./formulaPadraoModel";
import ManipulacaoOrdemModel from "./manipulacaoOrdemModel";
import MovimentacaoEstoqueModel from "./movimentacaoEstoqueModel";

export default class FormulaPadraoManipulacaoModel extends BaseModel {
    id?: number = null;
    formulaPadraoId = 0;
    formulaPadrao: FormulaPadraoModel = null;
    formulaPadraoDescricao: string = null;
    manipulacaoOrdemId?: number = null;
    manipulacaoOrdem: ManipulacaoOrdemModel = null;
    estoqueId = 0;
    estoque: EstoqueModel = null;
    estoqueDescricao: string = null;
    quantidade = 1;
    movimentacaoEstoqueId?: number = null;
    movimentacaoEstoque: MovimentacaoEstoqueModel = null;
    hasWarnings?: boolean = null;
    volumeTotal = 0;
    produtoLoteDestinoId?: number = null;
    atualizarValorCusto?: boolean = true;
}
