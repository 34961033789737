import Vue from "vue";

import comboComponent from "@/components/child/form/combo.vue";
import { Component } from "@/decorators";
import LogService from "@/services/logService";

@Component({
    components: {
        comboComponent,
    },
})
export default class LogLevelComponent extends Vue {
    private service = new LogService();

    logLevel = "";
    newLogLevel = 0;

    newLogLevelOptions = [
        { text: "Verbose", value: 1 },
        { text: "Debug", value: 2 },
        { text: "Information", value: 3 },
        { text: "Warning", value: 4 },
        { text: "Error", value: 5 },
        { text: "Fatal", value: 6 },
    ];

    private async mounted() {
        const response = await this.service.getLogLevel().withLoading().resolveWithJSON<{ level: string }>();
        this.logLevel = response.level;
    }

    private async updateLogLevel() {
        try {
            const isValid = await this.$validator.validateAll();
            if (isValid) {
                await this.service.setLogLevel(this.newLogLevel).withLoading();

                this.$router.back();
            }
        } catch {}
    }
}
