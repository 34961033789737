var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "crud crud-list" },
    [
      _c(
        "grid-component",
        {
          attrs: {
            columns: _vm.gridColumns,
            data: _vm.gridData,
            extraActions: _vm.extraActions,
            "page-index": _vm.pageIndex,
            "page-size": _vm.pageSize,
            showAddNewButton: false,
            showEditItem: false,
            showRemoveItem: false,
            showTools: false,
            total: _vm.total,
          },
          on: {
            "change-filter-key": _vm.onChangeFilterKey,
            "change-page": _vm.onChangePage,
            "change-sort": _vm.onChangeSort,
            "extra-action": _vm.onExtraAction,
          },
        },
        [
          _c("div", { attrs: { slot: "header" }, slot: "header" }, [
            _c("div", { staticClass: "filtro-lista" }, [
              _c(
                "button",
                {
                  staticClass: "filtro-avancado-button",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      _vm.isHidden = !_vm.isHidden
                    },
                  },
                },
                [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.$t("__.Crud.filtroAvancado")) +
                      "\n                    "
                  ),
                  _c("i", { staticClass: "fa fa-caret-down" }),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isHidden,
                      expression: "isHidden",
                    },
                  ],
                  staticClass: "drop-lista",
                },
                [
                  _c(
                    "form",
                    {
                      attrs: { novalidate: "novalidate" },
                      on: {
                        submit: function ($event) {
                          $event.preventDefault()
                          return _vm.load($event)
                        },
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "radio-group",
                          staticStyle: { "margin-bottom": "15px" },
                        },
                        [
                          _c("div", { staticStyle: { display: "table" } }, [
                            _c(
                              "div",
                              [
                                _c(
                                  "label",
                                  {
                                    staticClass: "control-label",
                                    attrs: { for: "setor" },
                                  },
                                  [
                                    _vm._v(
                                      "\n                                        " +
                                        _vm._s(
                                          _vm.$t(
                                            "__.Components.parent.venda.listExpedicao_vue_html.setor"
                                          )
                                        ) +
                                        "\n                                    "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c("combo-component", {
                                  staticStyle: { background: "white" },
                                  attrs: {
                                    id: "setor",
                                    name: "setor",
                                    data: _vm.setorOptions,
                                  },
                                  model: {
                                    value: _vm.setor,
                                    callback: function ($$v) {
                                      _vm.setor = $$v
                                    },
                                    expression: "setor",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "form-group" }, [
                        _c(
                          "button",
                          {
                            staticClass: "botao-filtrar",
                            attrs: { type: "submit" },
                          },
                          [
                            _vm._v(
                              "\n                                " +
                                _vm._s(_vm.$t("__.Crud.filtrar")) +
                                "\n                            "
                            ),
                          ]
                        ),
                      ]),
                    ]
                  ),
                ]
              ),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "shortcutComponent",
        {
          ref: "shortcutComponentEntrega",
          attrs: {
            btOkName: _vm.$t(
              "__.Components.parent.venda.listExpedicao_vue_html.finalizar"
            ),
            showAddNew: false,
          },
          on: {
            "selection-item": function ($event) {
              _vm.onSalvarExpedicao().withLoading()
            },
          },
        },
        [
          _c(
            "div",
            { attrs: { slot: "component" }, slot: "component" },
            [
              _c("endereco-entrega-component", {
                ref: "enderecoEntregaComponent",
              }),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }