import BaseModel from "./base/baseModel";
import TiposMovimentacaoEstoque from "./enum/tiposMovimentacaoEstoque";
import ProdutoLoteModel from "./produtoLoteModel";

export default class MovimentacaoEstoqueItemModel extends BaseModel {
    id?: number = null;
    movimentacaoEstoqueId = 0;
    dataCriacao?: Date = null;
    quantidade = 0;
    valorUnitario = 0;
    tiposMovimentacao?: TiposMovimentacaoEstoque = null;
    produtoDescricao: string = null;
    produtoId?: number = null;
    loteDescricao: string = null;
    produtoLoteId?: number = null;
    produtoLote = new ProdutoLoteModel();
    unidadeMedidaEstoqueId?: number = null;
    unidadeMedidaEstoqueDescricao: string = null;
}
