import { mapState } from "vuex";

import { GridAction } from "@/components/child/grid/gridAction";
import { GridColors, GridColumn, GridColumnType } from "@/components/child/grid/gridColumn";
import { Component } from "@/decorators";
import PontuacaoDotzModel from "@/models/dotz/pontuacaoDotzModel";
import UsuarioModel from "@/models/usuarioModel";
import PontuacaoDotzService from "@/services/dotz/pontuacaoDotzService";
import { AppState } from "@/store/store";
import Delay from "@/utils/common/delay";

import ShortcutComponent from "../../shortcut/shortcut";
import shortcutComponent from "../../shortcut/shortcut.vue";
import ListComponentBase from "../listComponentBase";

import "./list.scss";

@Component({
    components: {
        shortcutComponent,
    },
    computed: {
        ...mapState({
            usuarioLogado: (state: AppState) => state.session.usuarioLogado,
        }),
    },
})
export default class VendasPontuadasListComponent extends ListComponentBase<PontuacaoDotzModel> {
    // State computed props
    usuarioLogado: UsuarioModel;

    private shortcutComponent: ShortcutComponent = null;
    private extraActionsDetalhesVendas: Array<GridAction> = [];
    private extraActionsVendas: Array<object> = [];
    vendas = [];
    pontuacaoPorFranquia = [];

    get gridColumnsVendas(): Array<GridColumn> {
        return [
            new GridColumn("prescritor", "Pontuado", GridColumnType.String),
            new GridColumn("franquia", "Franquia", GridColumnType.String),
            new GridColumn("codigoVenda", "Código Venda", GridColumnType.Integer),
            new GridColumn("dataVenda", "Data", GridColumnType.Date),
            new GridColumn("valor", "Valor", GridColumnType.Money),
            new GridColumn("desconto", "Desconto", GridColumnType.Money),
            new GridColumn("descontoPercentual", "Desconto (%)", GridColumnType.Percent),
            new GridColumn("pontuacao", "Pontuação", GridColumnType.Integer),
        ];
    }

    get gridColumnsPontuacaoPorFranquias(): Array<GridColumn> {
        return [
            new GridColumn("franquia", "Franquia", GridColumnType.String),
            new GridColumn("pontuacao", "Pontuação", GridColumnType.Integer),
        ];
    }

    public async afterLoad() {
        this.extraActionsVendas.splice(0);
        this.extraActionsDetalhesVendas.splice(0);

        for (let i = 0; i < this.vendas.length; i++) {
            this.extraActionsDetalhesVendas.push(
                new GridAction(
                    "venda",
                    "Visualizar venda",
                    "fa fa-search",
                    GridColors.BLUE,
                    this.vendas[i].franquia === this.usuarioLogado.franquiaNome,
                ),
            );
        }

        this.extraActionsVendas.push(this.extraActionsDetalhesVendas);
    }

    public async show(pontuacaoId: number) {
        this.vendas = await new PontuacaoDotzService()
            .getVendasByPontuacao(pontuacaoId)
            .withLoading()
            .resolveWithJSON();

        this.pontuacaoPorFranquia = this.vendas
            .groupBy<any>(p => p.franquia)
            .map(p => ({
                franquia: p.key,
                pontuacao: p.data.sum("pontuacao"),
            }));

        while (!this.$refs.shortcutComponent) await Delay(100);
        this.shortcutComponent = this.$refs.shortcutComponent as ShortcutComponent;
        this.shortcutComponent.title = "Vendas pontuadas";
        this.shortcutComponent.show();
        this.afterLoad();
    }

    private async onExtraActionVendas(name: string, model: any) {
        if (name.trim() == "venda") {
            const routeData = this.$router.resolve({ path: `/venda-edicao/${model.vendaId}` });
            window.open(routeData.href, "_blank");
        }
    }
}
