var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "col-sm-12" },
    [
      _c("grid-component", {
        attrs: {
          data: _vm.data,
          columns: _vm.gridColumns,
          showRemoveItem: false,
          showEditItem: false,
          showTableHead: false,
          showTableFoot: false,
          showSortIcons: false,
          showActionColumn: false,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }