var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "crud crud-edit" },
    [
      _c(
        "actionBarComponent",
        {
          attrs: {
            slots: 2,
            title: _vm.$t("__.Crud.kitembalagem.edit_vue_html.kitEmbalEdit"),
          },
          on: { onSave: _vm.save },
        },
        [
          _vm.validarVisibilidadeBotoes("replicar")
            ? _c(
                "div",
                {
                  attrs: {
                    slot: "buttonSlot0",
                    title: _vm.$t("__.Crud.replicar"),
                  },
                  on: { click: _vm.onReplicar },
                  slot: "buttonSlot0",
                },
                [
                  _c("i", {
                    staticClass: "fa fa-sync",
                    attrs: { "aria-hidden": "true" },
                  }),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.model.id > 0 && _vm.model.kitEmbalagemPaiId != null
            ? _c(
                "div",
                {
                  attrs: {
                    slot: "buttonSlot1",
                    title: _vm.$t("__.Crud.excluir"),
                  },
                  on: { click: _vm.onRemoveFilho },
                  slot: "buttonSlot1",
                },
                [
                  _c("i", {
                    staticClass: "fa fa-times",
                    attrs: { "aria-hidden": "true" },
                  }),
                ]
              )
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _c(
        "form",
        {
          attrs: { novalidate: "novalidate" },
          on: {
            submit: function ($event) {
              $event.preventDefault()
              _vm.save()
            },
          },
        },
        [
          _c(
            "fieldset-component",
            {
              attrs: {
                title: _vm.$t("__.Crud.kitembalagem.edit_vue_html.dadosGerais"),
                collapsed: false,
              },
            },
            [
              _c("div", { attrs: { slot: "rows" }, slot: "rows" }, [
                _vm.isAdmin && _vm.modelId > 0
                  ? _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-sm-12" }, [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: _vm.errors.first("franquiaId"),
                                expression: "errors.first('franquiaId')",
                              },
                            ],
                            staticClass: "form-group",
                          },
                          [
                            _c(
                              "label",
                              {
                                staticClass: "control-label",
                                attrs: { for: "franquiaId" },
                              },
                              [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(
                                      _vm.$t(
                                        "__.Crud.kitembalagem.edit_vue_html.franquia"
                                      )
                                    ) +
                                    "\n                            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("combo-component", {
                              attrs: {
                                id: "franquiaId",
                                name: "franquiaId",
                                data: _vm.franquiaOptions,
                                searchable: false,
                              },
                              model: {
                                value: _vm.franquiaId,
                                callback: function ($$v) {
                                  _vm.franquiaId = $$v
                                },
                                expression: "franquiaId",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-sm-6" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("descricao"),
                            expression: "errors.first('descricao')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: {
                              for: "descricao",
                              title: _vm.$t("__.obrigatorio"),
                            },
                          },
                          [
                            _c("span", { staticStyle: { color: "red" } }, [
                              _vm._v("*"),
                            ]),
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Crud.kitembalagem.edit_vue_html.descr"
                                  )
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.model.descricao,
                              expression: "model.descricao",
                            },
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required|max:256",
                              expression: "'required|max:256'",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            autocomplete: "off",
                            type: "text",
                            id: "descricao",
                            name: "descricao",
                          },
                          domProps: { value: _vm.model.descricao },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.model,
                                "descricao",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-6" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("descricaoRotulo"),
                            expression: "errors.first('descricaoRotulo')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "descricaoRotulo" },
                          },
                          [
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Crud.kitembalagem.edit_vue_html.descrRotu"
                                  )
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.model.descricaoRotulo,
                              expression: "model.descricaoRotulo",
                            },
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "max:256",
                              expression: "'max:256'",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            autocomplete: "off",
                            type: "text",
                            id: "descricaoRotulo",
                            name: "descricaoRotulo",
                          },
                          domProps: { value: _vm.model.descricaoRotulo },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.model,
                                "descricaoRotulo",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-sm-4" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("unidadeMedidaId"),
                            expression: "errors.first('unidadeMedidaId')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "unidadeMedidaId" },
                          },
                          [
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Crud.kitembalagem.edit_vue_html.unMed"
                                  )
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("combo-component", {
                          attrs: {
                            id: "unidadeMedidaId",
                            name: "unidadeMedidaId",
                            data: _vm.unidadesMedidaOptions.filter(function (
                              p
                            ) {
                              return p["type"] < 3
                            }),
                            searchable: true,
                          },
                          model: {
                            value: _vm.model.unidadeMedidaId,
                            callback: function ($$v) {
                              _vm.$set(_vm.model, "unidadeMedidaId", $$v)
                            },
                            expression: "model.unidadeMedidaId",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-2" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("volumeMaximo"),
                            expression: "errors.first('volumeMaximo')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: {
                              for: "volumeMaximo",
                              title: _vm.$t("__.obrigatorio"),
                            },
                          },
                          [
                            _c("span", { staticStyle: { color: "red" } }, [
                              _vm._v("*"),
                            ]),
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Crud.kitembalagem.edit_vue_html.volMax"
                                  )
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("decimal-component", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required|maior_que_zero",
                              expression: "'required|maior_que_zero'",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: { id: "volumeMaximo", name: "volumeMaximo" },
                          model: {
                            value: _vm.model.volumeMaximo,
                            callback: function ($$v) {
                              _vm.$set(_vm.model, "volumeMaximo", $$v)
                            },
                            expression: "model.volumeMaximo",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-2" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("ativo"),
                            expression: "errors.first('ativo')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "ativo" },
                          },
                          [
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Crud.kitembalagem.edit_vue_html.ativo"
                                  )
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("checkbox-component", {
                          attrs: { id: "ativo", name: "ativo" },
                          model: {
                            value: _vm.model.ativo,
                            callback: function ($$v) {
                              _vm.$set(_vm.model, "ativo", $$v)
                            },
                            expression: "model.ativo",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-4" }, [
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "configuracaoRotuloId" },
                          },
                          [
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Crud.kitembalagem.edit_vue_html.rotuloPadrao"
                                  )
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("combo-component", {
                          attrs: {
                            id: "configuracaoRotuloId",
                            name: "configuracaoRotuloId",
                            data: _vm.rotulosManipulacaoOptions,
                            searchable: true,
                          },
                          model: {
                            value: _vm.model.configuracaoRotuloId,
                            callback: function ($$v) {
                              _vm.$set(_vm.model, "configuracaoRotuloId", $$v)
                            },
                            expression: "model.configuracaoRotuloId",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-sm-9" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("observacao"),
                            expression: "errors.first('observacao')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "observacao" },
                          },
                          [
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Crud.kitembalagem.edit_vue_html.obs"
                                  )
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("textarea-component", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "max:256",
                              expression: "'max:256'",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: { id: "observacao", name: "observacao" },
                          model: {
                            value: _vm.model.observacao,
                            callback: function ($$v) {
                              _vm.$set(_vm.model, "observacao", $$v)
                            },
                            expression: "model.observacao",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-3" }, [
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "quantidadeMaximaBiscoitos" },
                          },
                          [
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Crud.kitembalagem.edit_vue_html.qtdMaximaBiscoitos"
                                  )
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("decimal-component", {
                          staticClass: "form-control",
                          attrs: {
                            id: "quantidadeMaximaBiscoitos",
                            name: "quantidadeMaximaBiscoitos",
                          },
                          model: {
                            value: _vm.model.quantidadeMaximaBiscoitos,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.model,
                                "quantidadeMaximaBiscoitos",
                                $$v
                              )
                            },
                            expression: "model.quantidadeMaximaBiscoitos",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "fieldset-component",
            {
              attrs: {
                title: _vm.$t("__.Crud.kitembalagem.edit_vue_html.frmFarmace"),
              },
            },
            [
              _c(
                "div",
                { attrs: { slot: "rows" }, slot: "rows" },
                [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-sm-11" }, [
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "control-label",
                              attrs: { for: "formaFarmaceuticaId" },
                            },
                            [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(
                                    _vm.$t(
                                      "__.Crud.kitembalagem.edit_vue_html.frmFarmace"
                                    )
                                  ) +
                                  "\n                            "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("combo-component", {
                            attrs: {
                              id: "formaFarmaceuticaId",
                              name: "formaFarmaceuticaId",
                              data: _vm.formasFarmaceuticaOptions,
                              searchable: true,
                            },
                            model: {
                              value: _vm.model.formaFarmaceuticaId,
                              callback: function ($$v) {
                                _vm.$set(_vm.model, "formaFarmaceuticaId", $$v)
                              },
                              expression: "model.formaFarmaceuticaId",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-sm-1" }, [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: _vm.errors.first("formaPriorizar"),
                              expression: "errors.first('formaPriorizar')",
                            },
                          ],
                          staticClass: "form-group",
                        },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "control-label",
                              attrs: { for: "formaPriorizar" },
                            },
                            [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(
                                    _vm.$t(
                                      "__.Crud.kitembalagem.edit_vue_html.prioriz"
                                    )
                                  ) +
                                  "\n                            "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("data-tooltip-component", {
                            attrs: {
                              text: _vm.$t(
                                "__.Crud.kitembalagem.edit_vue_html.priorizEssaOpc"
                              ),
                            },
                          }),
                          _vm._v(" "),
                          _c("checkbox-component", {
                            attrs: {
                              id: "formaPriorizar",
                              name: "formaPriorizar",
                            },
                            model: {
                              value: _vm.formaPriorizar,
                              callback: function ($$v) {
                                _vm.formaPriorizar = $$v
                              },
                              expression: "formaPriorizar",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("buttonIncluirComponent", {
                    on: { click: _vm.onInclusaoFormaFarmaceutica },
                  }),
                  _vm._v(" "),
                  _c("grid-component", {
                    attrs: {
                      data: _vm.model.formasFarmaceuticas,
                      columns: _vm.gridColumnsFormaFarmaceutica,
                      "show-table-head": false,
                      "show-table-foot": false,
                      "show-edit-item": false,
                    },
                    on: { "remove-item": _vm.onRemoveFormaFarmaceutica },
                  }),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "fieldset-component",
            {
              attrs: {
                title: _vm.$t(
                  "__.Crud.kitembalagem.edit_vue_html.frmCapacidadeEmbalagemCapsulas"
                ),
              },
            },
            [
              _c(
                "div",
                { attrs: { slot: "rows" }, slot: "rows" },
                [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-sm-6" }, [
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "control-label",
                              attrs: { for: "capsulaComboId" },
                            },
                            [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(
                                    _vm.$t(
                                      "__.Crud.kitembalagem.edit_vue_html.capsula"
                                    )
                                  ) +
                                  "\n                            "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("combo-component", {
                            attrs: {
                              id: "capsulaComboId",
                              name: "capsulaComboId",
                              data: _vm.capsulasOptions,
                              searchable: true,
                            },
                            model: {
                              value: _vm.capsulaComboId,
                              callback: function ($$v) {
                                _vm.capsulaComboId = $$v
                              },
                              expression: "capsulaComboId",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-sm-1" }, [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: _vm.errors.first("quantidadeCapsula"),
                              expression: "errors.first('quantidadeCapsula')",
                            },
                          ],
                          staticClass: "form-group",
                        },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "control-label",
                              attrs: { for: "quantidadeCapsula" },
                            },
                            [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(
                                    _vm.$t(
                                      "__.Crud.kitembalagem.edit_vue_html.qtd"
                                    )
                                  ) +
                                  "\n                            "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("decimal-component", {
                            staticClass: "form-control",
                            attrs: {
                              id: "quantidadeCapsula",
                              name: "quantidadeCapsula",
                            },
                            model: {
                              value: _vm.quantidadeCapsula,
                              callback: function ($$v) {
                                _vm.quantidadeCapsula = $$v
                              },
                              expression: "quantidadeCapsula",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("buttonIncluirComponent", {
                    on: { click: _vm.onInclusaoCapacidadeEmbalagemCapsula },
                  }),
                  _vm._v(" "),
                  _c("grid-component", {
                    attrs: {
                      data: _vm.model.kitEmbalagemCapsulas,
                      columns: _vm.gridColumnsCapacidadeCapsula,
                      "show-table-head": false,
                      "show-table-foot": false,
                      "show-edit-item": false,
                    },
                    on: {
                      "remove-item": _vm.onRemoveCapacidadeEmbalagemCapsula,
                    },
                  }),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "fieldset-component",
            {
              attrs: {
                title: _vm.$t("__.Crud.kitembalagem.edit_vue_html.prod"),
              },
            },
            [
              _c(
                "div",
                { attrs: { slot: "rows" }, slot: "rows" },
                [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { class: "col-sm-3" }, [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: _vm.errors.first("tipoProdutoId"),
                              expression: "errors.first('tipoProdutoId')",
                            },
                          ],
                          staticClass: "form-group",
                        },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "control-label",
                              attrs: { for: "tipoProdutoId" },
                            },
                            [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(
                                    _vm.$t(
                                      "__.Crud.kitembalagem.edit_vue_html.tipoProd"
                                    )
                                  ) +
                                  "\n                            "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("combo-component", {
                            attrs: {
                              id: "tipoProdutoId",
                              name: "tipoProdutoId",
                              data: _vm.tipoProdutoOptions,
                              searchable: false,
                            },
                            model: {
                              value: _vm.tipoProdutoId,
                              callback: function ($$v) {
                                _vm.tipoProdutoId = $$v
                              },
                              expression: "tipoProdutoId",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              _vm.tipoProdutoId == 0 ||
                              _vm.tipoProdutoId == 3 ||
                              _vm.tipoProdutoId == null,
                            expression:
                              "tipoProdutoId == 0 || tipoProdutoId == 3 || tipoProdutoId == null",
                          },
                        ],
                        staticClass: "col-sm-3",
                      },
                      [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: _vm.errors.first("subGrupoProduto"),
                                expression: "errors.first('subGrupoProduto')",
                              },
                            ],
                            staticClass: "form-group",
                          },
                          [
                            _c(
                              "label",
                              {
                                staticClass: "control-label",
                                attrs: {
                                  for: "subGrupoProduto",
                                  title: _vm.$t("__.obrigatorio"),
                                },
                              },
                              [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(
                                      _vm.$t(
                                        "__.Crud.kitembalagem.edit_vue_html.subGrupProd"
                                      )
                                    ) +
                                    "\n                            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("combo-component", {
                              attrs: {
                                id: "subGrupoProduto",
                                name: "subGrupoProduto",
                                data: _vm.subGrupoOptions,
                                searchable: false,
                              },
                              model: {
                                value: _vm.subGrupo,
                                callback: function ($$v) {
                                  _vm.subGrupo = $$v
                                },
                                expression: "subGrupo",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        class:
                          "col-sm-" +
                          [
                            _vm.tipoProdutoId == 0 ||
                            _vm.tipoProdutoId == 3 ||
                            _vm.tipoProdutoId == null
                              ? 4
                              : 7,
                          ],
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c(
                              "label",
                              {
                                staticClass: "control-label",
                                attrs: { for: "produtoId" },
                              },
                              [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(
                                      _vm.$t(
                                        "__.Crud.kitembalagem.edit_vue_html.prods"
                                      )
                                    ) +
                                    "\n                            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("combo-component", {
                              attrs: {
                                id: "produtoComboId",
                                name: "produtoComboId",
                                data: _vm.produtosOptions,
                                customView: true,
                                searchable: true,
                              },
                              model: {
                                value: _vm.produtoComboId,
                                callback: function ($$v) {
                                  _vm.produtoComboId = $$v
                                },
                                expression: "produtoComboId",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-sm-1" }, [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: _vm.errors.first("quantidade"),
                              expression: "errors.first('quantidade')",
                            },
                          ],
                          staticClass: "form-group",
                        },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "control-label",
                              attrs: { for: "quantidade" },
                            },
                            [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(
                                    _vm.$t(
                                      "__.Crud.kitembalagem.edit_vue_html.qtd"
                                    )
                                  ) +
                                  "\n                            "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("decimal-component", {
                            staticClass: "form-control",
                            attrs: { id: "quantidade", name: "quantidade" },
                            model: {
                              value: _vm.quantidade,
                              callback: function ($$v) {
                                _vm.quantidade = $$v
                              },
                              expression: "quantidade",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-sm-1" }, [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: _vm.errors.first("produtoPadrao"),
                              expression: "errors.first('produtoPadrao')",
                            },
                          ],
                          staticClass: "form-group",
                        },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "control-label",
                              attrs: { for: "produtoPadrao" },
                            },
                            [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(
                                    _vm.$t(
                                      "__.Crud.kitembalagem.edit_vue_html.padrao"
                                    )
                                  ) +
                                  "\n                            "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("data-tooltip-component", {
                            attrs: {
                              text: _vm.$t(
                                "__.Crud.kitembalagem.edit_vue_html.prodPadrSeraoASelec"
                              ),
                            },
                          }),
                          _vm._v(" "),
                          _c("checkbox-component", {
                            attrs: {
                              id: "produtoPadrao",
                              name: "produtoPadrao",
                            },
                            model: {
                              value: _vm.produtoPadrao,
                              callback: function ($$v) {
                                _vm.produtoPadrao = $$v
                              },
                              expression: "produtoPadrao",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("buttonIncluirComponent", {
                    on: { click: _vm.onInclusaoProduto },
                  }),
                  _vm._v(" "),
                  _c("grid-component", {
                    attrs: {
                      data: _vm.model.kitEmbalagemItens,
                      columns: _vm.gridColumnsProduto,
                      "show-table-head": false,
                      "show-table-foot": false,
                      "show-edit-item": false,
                    },
                    on: { "remove-item": _vm.onRemoveProduto },
                  }),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c("fieldset", [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-sm-12 newbtn" }, [
                _c("div", { staticClass: "form-group" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-success",
                      attrs: { type: "submit" },
                    },
                    [
                      _vm._v(
                        "\n                            " +
                          _vm._s(_vm.$t("__.Crud.salvar")) +
                          "\n                        "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-secondary",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          _vm.load()
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n                            " +
                          _vm._s(_vm.$t("__.Crud.limpar")) +
                          "\n                        "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-secondary",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          _vm.cancel()
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n                            " +
                          _vm._s(_vm.$t("__.Crud.voltar")) +
                          "\n                        "
                      ),
                    ]
                  ),
                ]),
              ]),
            ]),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _vm.validarVisibilidadeBotoes("replicar")
        ? [
            _c("replicar-selecao-franquia-component", {
              ref: "replicarSelecaoFranquiaComponent",
              on: { replicar: _vm.onConfirmReplicar },
            }),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }