import BaseModel from "../base/baseModel";
import GenerosPessoa from "../enum/generosPessoa";
import TiposPessoa from "../enum/tiposPessoa";
import Ufs from "../enum/ufs";

export default class TransportadoraModel extends BaseModel {
    id?: number = null;
    tipoPessoa: TiposPessoa = TiposPessoa.Fisica;
    nome: string = null;
    nomeFantasia: string = null;
    observacao: string = null;
    cpf: string = null;
    rg: string = null;
    razaoSocial: string = null;
    cnpj: string = null;
    inscricaoEstadual: string = null;
    cep: string = null;
    estadoId?: Ufs = null;
    cidade: string = null;
    cidadeId?: number = null;
    endereco: string = null;
    bairro: string = null;
    numero: string = null;
    complemento: string = null;
    telefone: string = null;
    celular: string = null;
    fax: string = null;
    email: string = null;
    homepage: string = null;
    contatoNome: string = null;
    contatoTelefone: string = null;
    inscricaoMunicipal?: string = '';
    dataNascimento?: Date = null;
    genero?: GenerosPessoa = null;
    orgaoExpedidor?: string = null;
    ufExpedidor?: string = null;
}