import FormulaPadraoManipulacaoModel from "../models/formulaPadraoManipulacaoModel";
import FormulaPadraoModel from "../models/formulaPadraoModel";
import nfetch from "../utils/events/nfetch";

import { IService } from "./base/iService";


export default class FormulaPadraoManipulacaoService extends IService<FormulaPadraoManipulacaoModel> {
    constructor() {
        super("FormulaPadraoManipulacao");
    }

    public insertArray(model: FormulaPadraoManipulacaoModel): Promise<Response> {
        //@ts-ignore
        return nfetch(`/${this.controllerName}/Insert`, {
            method: "post",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json; charset=UTF-8",
                "XSRF-TOKEN": this.getCookie("XSRF-TOKEN"),
            },
            body: JSON.stringify(model),
        }).catch(() => {});
    }

    public convertFormulaPadraoEmManipulacao(model: FormulaPadraoModel, estoqueId: number): Promise<Response> {
        //@ts-ignore
        return nfetch(`/${this.controllerName}/ConvertFPadraoManipulacao?estoqueId=${estoqueId}`, {
            method: "post",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json; charset=UTF-8",
                "XSRF-TOKEN": this.getCookie("XSRF-TOKEN"),
            },
            body: JSON.stringify(model),
        }).then((response: Response) => {
            if (response.ok) {
                return response;
            } else {
                return this.mensagemErro(response, "Save");
            }
        });
    }
}
