import Vue from "vue";
import { mapState } from "vuex";

import comboComponent from "@/components/child/form/combo.vue";
import decimalComponent from "@/components/child/form/decimal.vue";
import { Component, Prop, Watch } from "@/decorators";
import PreLoadPackModel from "@/models/auxiliar/preLoadPackModel";
import NotaFiscalEntradaItemModel from "@/models/notaFiscalEntradaItemModel";
import PaginationModel from "@/models/paginationModel";
import ProdutoLoteModel from "@/models/produtoLoteModel";
import IndicesConversoesUnidadeMedidasService from "@/services/indicesConversoesUnidadeMedidaService";
import ProdutoLoteService from "@/services/produtoLoteService";
import { AppState } from "@/store/store";
import { getProdutoLoteCombo } from "@/utils/common/combo/combotext";
import Delay from "@/utils/common/delay";

@Component({
    components: {
        decimalComponent,
        comboComponent,
    },
    computed: mapState<AppState>({
        loadedList: state => state.preLoad.loadedList,
        preLoadList: state => state.preLoad.preLoadList,
    }),
})
export default class ItemComponent extends Vue {
    // State computed props
    loadedList: boolean;
    preLoadList: PreLoadPackModel;

    private produtoLoteOptions = [];
    private unidadeMedidaOptions = [];

    model = new NotaFiscalEntradaItemModel();

    @Prop(Object) item;
    @Prop(Object) indicesConversoesUnidadeMedidasService;

    private async load() {
        this.model.updateFrom(this.item);

        this.unidadeMedidaOptions = this.preLoadList.unidadesMedida.map(p => ({ value: p.id, text: p.sigla }));

        this.model.unidadeMedidaId = this.unidadeMedidaOptions.find(p => p["text"] == this.model.unidadeMedida)[
            "value"
        ];

        try {
            const data = await new ProdutoLoteService()
                .listFilterProduto(this.model.produtoId, -10000.0, 0, 1, 999999)
                .resolveWithJSON<PaginationModel<ProdutoLoteModel>>();

            const itemNf = data.list.filter(item => item.id == this.model.produtoLoteId);
            this.produtoLoteOptions = itemNf.map(getProdutoLoteCombo);
        } catch {}
    }

    @Watch("model", { deep: true })
    private async onChangeModel() {
        this.$emit("onChangeItem", this.model);

        const indiceConversaoService = this
            .indicesConversoesUnidadeMedidasService as IndicesConversoesUnidadeMedidasService;

        const indiceConversao = await indiceConversaoService.getIndiceConversao(
            this.model.produto.unidadeMedidaEstoqueId,
            this.model.unidadeMedidaId,
        );

        this.model.quantidadeADevolverEstoque = this.model.quantidadeADevolver / indiceConversao;
    }

    private async mounted() {
        while (!this.loadedList) await Delay(10);

        this.load();
    }
}
