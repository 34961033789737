import { mapMutations, mapState } from "vuex";

import actionBarComponent from "@/components/child/actionBar/actionBar.vue";
import buttonIncluirComponent from "@/components/child/form/button/buttonIncluir.vue";
import checkboxComponent from "@/components/child/form/checkbox.vue";
import comboComponent from "@/components/child/form/combo.vue";
import dataTooltipComponent from "@/components/child/form/datatooltip.vue";
import dateTimePickerComponent from "@/components/child/form/datetimepicker.vue";
import decimalComponent from "@/components/child/form/decimal.vue";
import fieldsetComponent from "@/components/child/form/fieldset.vue";
import textareaComponent from "@/components/child/form/textarea.vue";
import gridComponent from "@/components/child/grid/grid.vue";
import { GridColumn, GridColumnType } from "@/components/child/grid/gridColumn";
import infoBagdeComponent from "@/components/child/infoBagde/infoBagde.vue";
import searchComboComponent from "@/components/child/searchCombo/search.vue";
import { Component, Watch, Prop } from "@/decorators";
import PreLoadPackModel from "@/models/auxiliar/preLoadPackModel";
import ClienteModel from "@/models/clienteModel";
import AreaAtuacao from "@/models/enum/areaAtuacao";
import GenerosPessoa from "@/models/enum/generosPessoa";
import CondicaoModel from "@/models/paciente/condicaoModel";
import EspecieAnimalModel from "@/models/paciente/especieAnimalModel";
import PacienteAlergiaModel from "@/models/paciente/pacienteAlergiaModel";
import PacienteCondicaoModel from "@/models/paciente/pacienteCondicaoModel";
import PacienteModel from "@/models/paciente/pacienteModel";
import RacaAnimalModel from "@/models/paciente/racaAnimalModel";
import PaginationModel from "@/models/paginationModel";
import ClienteService from "@/services/clienteService";
import CondicaoService from "@/services/paciente/condicaoService";
import EspecieAnimalService from "@/services/paciente/especieAnimalService";
import PacienteService from "@/services/paciente/pacienteService";
import RacaAnimalService from "@/services/paciente/racaAnimalService";
import { AppState } from "@/store/store";
import { editDateWithTime } from "@/utils/common/date";
import Delay from "@/utils/common/delay";

import ClienteComponent from "../cliente/edit";
import clienteComponent from "../cliente/edit.vue";
import EditComponentBase from "../editComponentBase";

import "../crud.scss";

@Component({
    components: {
        textareaComponent,
        gridComponent,
        comboComponent,
        fieldsetComponent,
        checkboxComponent,
        dataTooltipComponent,
        dateTimePickerComponent,
        buttonIncluirComponent,
        decimalComponent,
        searchComboComponent,
        actionBarComponent,
        infoBagdeComponent,
    },
    computed: {
        ...mapState({
            preLoadPack: (state: AppState) => state.preLoad.preLoadList,
            loadedList: (state: AppState) => state.preLoad.loadedList,
        }),
        infoBagdeText() {
            return "Data criação: " + editDateWithTime(this.model.dataCriacao);
        },
    },
    beforeCreate: function () {
        this.$options.components["clienteComponent"] = clienteComponent;
    },
    methods: mapMutations(["LOAD_LIST"]),
})
export default class PacienteEditComponent extends EditComponentBase<PacienteModel> {
    // State computed props
    infoBagdeText: string;
    loadedList: boolean;
    preLoadPack: PreLoadPackModel;

    private clienteService = new ClienteService();
    private racaAnimalService = new RacaAnimalService();

    private clienteComponent: ClienteComponent = null;
    private nomeCliente = null;

    novoClienteId = 0;
    pacienteCondicaoId = 0;
    isFranquiaHumana = false;
    alergiaId = 0;

    condicoes: CondicaoModel[] = [];
    racasAnimalOptions: Array<Object> = [];
    especiesAnimalOptions: Array<Object> = [];
    produtosOptions: Array<Object> = [];
    condicoesOptions: Array<Object> = [];
    generosPessoaOptions: Array<Object> = [
        { text: "Macho", value: GenerosPessoa.Masculino },
        { text: "Fêmea", value: GenerosPessoa.Feminino },
    ];

    gridColumnsAlergias: Array<GridColumn> = [new GridColumn("produtoDescricao", "Descrição", GridColumnType.String)];

    gridColumnsCondicoes: Array<GridColumn> = [new GridColumn("condicaoDescricao", "Descrição", GridColumnType.String)];

    @Prop({ type: Boolean, default: false }) CalledByShortCut: boolean;
    @Prop({ type: Boolean, default: true }) showCliente: boolean;

    private customComboTextCliente = (p: ClienteModel) => ({
        value: p.id,
        text: `${p.id} - ` + (p.tipoPessoa != 1 ? p.nome : p.razaoSocial),
    });

    public async onPreLoadModel() {
        this.nomeCliente = null;
        return true;
    }

    public async onLoadModel(model: PacienteModel) {
        await this.carregarCliente();
    }

    private onInclusaoCondicoes() {
        if (this.pacienteCondicaoId > 0) {
            const alreadyExists =
                this.model.pacienteCondicoes.filter(p => p.condicaoId == this.pacienteCondicaoId).length > 0;

            if (!alreadyExists) {
                const pacienteCondicao = new PacienteCondicaoModel();
                pacienteCondicao.condicaoId = this.pacienteCondicaoId;
                pacienteCondicao.pacienteId = this.model.id;
                pacienteCondicao.condicaoDescricao = this.condicoesOptions.find(
                    item => item["value"] == this.pacienteCondicaoId,
                )["text"];

                this.model.pacienteCondicoes.push(pacienteCondicao);
                this.pacienteCondicaoId = null;
                this.loadCondicoesOptions();
            }
        }
    }

    private async loadCondicoesOptions() {
        this.condicoesOptions = this.condicoes
            .filter(item => !this.model.pacienteCondicoes.some(p => p.condicaoId == item.id))
            .map(item => ({ value: item.id, text: item.descricao }));
    }

    private async loadDadosCondicoesOptions() {
        const data = await new CondicaoService()
            .combo()
            .withLoading()
            .resolveWithJSON<PaginationModel<CondicaoModel>>();

        this.condicoesOptions = data.list
            .filter(item => !this.model.pacienteCondicoes.some(p => p.condicaoId == item.id))
            .map(item => ({ value: item.id, text: item.descricao }));

        this.condicoes = data.list;
    }

    private onRemoverCondicoes(model: PacienteCondicaoModel) {
        this.model.pacienteCondicoes = this.model.pacienteCondicoes.filter(p => p != model);
        this.loadCondicoesOptions();
    }

    private async beforeMount() {
        await this.setProps(new PacienteService(), "pacientes", new PacienteModel(), this.CalledByShortCut);
        this.isFranquiaHumana = this.usuarioLogado.franquia.areaAtuacao === AreaAtuacao.Humana;
        await this.loadEspeciesAnimal().withLoading();
        await this.loadDadosCondicoesOptions();

        await this.loadProdutos();
    }

    public async onPreSave() {
        if (this.usuarioLogado.franquia.areaAtuacao === AreaAtuacao.Humana) {
            if (this.especiesAnimalOptions.some(p => p["text"].toLowerCase().includes("humano"))) {
                this.model.especieAnimalId = this.especiesAnimalOptions.find(p =>
                    p["text"].toLowerCase().includes("humano"),
                )["value"];
            } else {
                this.$showError(
                    "Erro ao cadastrar",
                    "Não foi encontrada nenhuma especie do tipo 'Humano' cadastrada, por favor cadastrar",
                );
                return;
            }
        }
        return true;
    }

    public async carregarCliente(idCliente = 0) {
        try {
            const cliente = await this.clienteService
                .get(idCliente ? idCliente : this.model.clienteId)
                .resolveWithJSON<ClienteModel>();

            this.nomeCliente = cliente.nome;
        } catch {}
    }

    public loadWithModel(paciente: PacienteModel) {
        this.model = new PacienteModel();
        this.model.updateFrom(paciente);
        this.loadCondicoesOptions();
    }

    private async loadEspeciesAnimal() {
        try {
            const especies = await new EspecieAnimalService()
                .combo()
                .resolveWithJSON<PaginationModel<EspecieAnimalModel>>();

            this.especiesAnimalOptions = especies.list.map(item => ({
                value: item.id,
                text: item.descricao,
            }));
        } catch {}
    }

    private async loadRacasAnimal(especieId = 0) {
        try {
            if (!especieId) {
                this.racasAnimalOptions = [];
            } else {
                const racas = await this.racaAnimalService
                    .listByEspecie(especieId)
                    .resolveWithJSON<RacaAnimalModel[]>();

                this.racasAnimalOptions = racas.map(item => ({
                    value: item.id,
                    text: item.descricao,
                }));
            }
        } catch {}
    }

    private async loadProdutos() {
        while (!this.loadedList) await Delay(50);

        const data = this.preLoadPack.produtosCombo();
        this.produtosOptions = data.filter(item => !this.model.pacienteAlergias.some(p => p.produtoId == item.value));
    }

    private async onAddAlergia() {
        if (this.alergiaId > 0) {
            const alreadyExists = this.model.pacienteAlergias.filter(p => p.id == this.alergiaId).length > 0;

            if (!alreadyExists) {
                const alergia = new PacienteAlergiaModel();
                alergia.produtoId = this.alergiaId;
                alergia.pacienteId = this.model.id;
                alergia.produtoDescricao = this.produtosOptions.find(item => item["value"] == this.alergiaId)["text"];

                this.model.pacienteAlergias.push(alergia);
                this.alergiaId = null;
                this.loadProdutos();
            }
        }
    }

    private onRemoverAlergia(model: PacienteAlergiaModel) {
        this.model.pacienteAlergias = this.model.pacienteAlergias.filter(p => p != model);
        this.loadProdutos();
    }

    private onNomeRotuloFocus() {
        if (!this.model.nomeRotulo) {
            this.model.nomeRotulo = this.model.nome;
        }
    }

    public async retornaPaciente() {
        try {
            if ((await this.$validator.validateAll()) && (await this.onPreSave())) {
                this.$emit("paciente-model", this.model);
            }
        } catch {}
    }

    public clear() {
        this.model = new PacienteModel();
    }

    private onChangeNome() {
        if (this.model.nome) {
            this.montarRotuloPeloPaciente();
        }
    }

    private onChangeClienteId(value: number) {
        this.novoClienteId = value;

        if (value) {
            const cliente = this.$el.querySelector("#clienteId") as HTMLSelectElement;
            this.nomeCliente = cliente.innerText;

            this.montarRotuloPeloPaciente();
        }
    }

    //@ts-ignore
    @Watch("model.especieAnimalId")
    private onRacaAnimalId(newValue, oldValue) {
        if (newValue || (!newValue && oldValue)) {
            this.loadRacasAnimal(newValue);
        }
    }

    private openNewCliente() {
        this.clienteComponent = this.$refs.clienteComponent as ClienteComponent;
        this.clienteComponent.modelId = null;
        this.clienteComponent.load();
    }

    private openComboEditCliente(id: number) {
        this.clienteComponent = this.$refs.clienteComponent as ClienteComponent;
        this.clienteComponent.modelId = id;
        this.clienteComponent.load();
    }

    private async onAddCliente(closeModal) {
        if (await this.clienteComponent.save()) {
            closeModal();
        }
    }

    private async onClienteSaveOk(modelNovoCliente: ClienteModel) {
        this.model.clienteId = modelNovoCliente.id;
    }

    public async montarRotuloPeloPaciente() {
        if (this.novoClienteId > 0) {
            await this.carregarCliente(this.novoClienteId);
        }

        const primeiroNome = this.nomeCliente ? this.nomeCliente.split(" ")[0] : "";
        this.model.nomeRotulo = `${primeiroNome} (Para: ${this.model.nome ?? ""})`;
    }

    public async montarRotuloPeloCliente(nomeCliente = null) {
        if (this.novoClienteId > 0) {
            await this.carregarCliente(this.novoClienteId);
        }

        this.nomeCliente = nomeCliente ? nomeCliente : "";
        const primeiroNome = this.nomeCliente.split(" ")[0];
        this.model.nomeRotulo = `${primeiroNome} (Para: ${this.model.nome ?? ""})`;
    }
}
