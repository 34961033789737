import BaseModel from "../../../../base/baseModel";

export default class IdentificacaoNfeModel extends BaseModel {
    modelo: number = null;
    serie: number = null;
    numero: number = null;
    dataEmissao: Date = null;
    dataEntradaSaidaMercadoria: Date = null;
    finalidadeEmissao: number = null;
    indicadorOperacao: number = null;
    indicadorPresenca: number = null;
}
