import BaseModel from "./../base/baseModel";

//pacote de arrays usados normalmente em combos e listas simples
export default class CountPackModel extends BaseModel {
    continuos: number = null;
    orcamentos: number = null;
    vendasAFaturar: number = null;
    manipulacoesAConcluir: number = null;
    estoqueDivergencias: number = null;
    estoqueDados: {
        estoqueBaixo: number;
        vencendo: number;
        semEstoque: number;
    };
    estoqueFracionamento: number = null;
    expedicao: number = null;
    fracionamento: number = null;
    entregas: number = null;
}
