import ConfiguracaoUsuarioModel from "../models/configuracaoUsuarioModel";
import nfetch from "../utils/events/nfetch";

export default class ConfiguracaoUsuarioService {
    private controllerName = "ConfiguracaoUsuario";

    public listAll(): Promise<Response> {
        return nfetch(`/${this.controllerName}/ListAll`, {
            credentials: "same-origin",
        });
    }

    public list(usuarioId?: number): Promise<Response> {
        return nfetch(`/${this.controllerName}/List?usuarioId=${usuarioId}`, {
            credentials: "same-origin",
        });
    }

    public updateAll(configuracoes: ConfiguracaoUsuarioModel[], usuarioId: number): Promise<Response> {
        return nfetch(`/${this.controllerName}/UpdateAll?usuarioId=${usuarioId}`, {
            method: "post",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json; charset=UTF-8",
            },
            body: JSON.stringify(configuracoes),
        });
    }
}
