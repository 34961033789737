var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "crud crud-edit", staticStyle: { height: "100%" } },
    [
      _c("actionBarComponent", {
        attrs: { title: "Relatório", showSave: false },
      }),
      _vm._v(" "),
      _c("iframe", {
        staticStyle: { width: "100%", height: "calc(100% - 87px)" },
        attrs: { id: "iframe" },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }