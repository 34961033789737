var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "crud crud-edit" },
    [
      _c(
        "actionBarComponent",
        {
          attrs: {
            slots: 7,
            title: _vm.$t(
              "__.Components.parent.notafiscalsaida.edit_vue_html.nfSaidaEdit"
            ),
            showSave: false,
          },
          scopedSlots: _vm._u([
            {
              key: "buttonSlot6",
              fn: function (ref) {
                var open = ref.open
                return _vm.modelId
                  ? _c(
                      "div",
                      {
                        attrs: { title: _vm.$t("__.ts.historicoAlteracoes") },
                        on: {
                          click: function ($event) {
                            open(
                              1,
                              _vm.$t("__.ts.historicoAlteracoes"),
                              "lg-modal"
                            )
                          },
                        },
                      },
                      [
                        _c("i", {
                          staticClass: "fa fa-history",
                          attrs: { slot: "button", "aria-hidden": "true" },
                          slot: "button",
                        }),
                      ]
                    )
                  : _vm._e()
              },
            },
          ]),
        },
        [
          _vm.model.tipo == 2 && _vm.model.statusEnvio == 2
            ? _c(
                "div",
                {
                  attrs: {
                    slot: "buttonSlot0",
                    title: _vm.$t(
                      "__.Components.parent.notafiscalsaida.edit_vue_html.cartaCorrecao"
                    ),
                  },
                  on: { click: _vm.onGerarCartaCorrecao },
                  slot: "buttonSlot0",
                },
                [
                  _c("i", {
                    staticClass: "fa fa-envelope",
                    attrs: { "aria-hidden": "true" },
                  }),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.model.temCartaCorrecao
            ? _c(
                "div",
                {
                  attrs: {
                    slot: "buttonSlot1",
                    title: _vm.$t(
                      "__.Components.parent.notafiscalsaida.edit_vue_html.consultarCartaCorrecao"
                    ),
                  },
                  on: { click: _vm.openCartaCorrecao },
                  slot: "buttonSlot1",
                },
                [
                  _c("i", {
                    staticClass: "fa fa-envelope-open",
                    attrs: { "aria-hidden": "true" },
                  }),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.model.pdfLink != null && _vm.model.statusEnvio == 2
            ? _c(
                "div",
                {
                  attrs: {
                    slot: "buttonSlot2",
                    title: _vm.$t(
                      "__.Components.parent.notafiscalsaida.edit_vue_html.abrirPDF"
                    ),
                  },
                  on: { click: _vm.openPDF },
                  slot: "buttonSlot2",
                },
                [
                  _c("i", {
                    staticClass: "fa fa-file-pdf",
                    attrs: { "aria-hidden": "true" },
                  }),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.model.pdfSimplificadoLink && _vm.model.statusEnvio == 2
            ? _c(
                "div",
                {
                  attrs: {
                    slot: "buttonSlot3",
                    title: _vm.$t(
                      "__.Components.parent.notafiscalsaida.edit_vue_html.abrirPDFSimplificado"
                    ),
                  },
                  on: { click: _vm.openPDFSimplificado },
                  slot: "buttonSlot3",
                },
                [
                  _c("i", {
                    staticClass: "fa fa-file-pdf",
                    attrs: { "aria-hidden": "true" },
                  }),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.model.xmlLink != null && _vm.model.statusEnvio == 2
            ? _c(
                "div",
                {
                  attrs: {
                    slot: "buttonSlot4",
                    title: _vm.$t(
                      "__.Components.parent.notafiscalsaida.edit_vue_html.abrirXML"
                    ),
                  },
                  on: { click: _vm.openXML },
                  slot: "buttonSlot4",
                },
                [
                  _c("i", {
                    staticClass: "fa fa-file-code",
                    attrs: { "aria-hidden": "true" },
                  }),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.model.statusEnvio == 2
            ? _c(
                "div",
                {
                  attrs: {
                    slot: "buttonSlot5",
                    title: _vm.$t(
                      "__.Components.parent.notafiscalsaida.edit_vue_html.cancelarEmissao"
                    ),
                  },
                  on: { click: _vm.onCancelar },
                  slot: "buttonSlot5",
                },
                [
                  _c("i", {
                    staticClass: "fa fa-exclamation-circle",
                    attrs: { "aria-hidden": "true" },
                  }),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { attrs: { slot: "modalSlot1" }, slot: "modalSlot1" },
            [
              _c("logEntidadeComponent", {
                attrs: { registroId: _vm.modelId, entidade: 8 },
              }),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "form",
        { attrs: { novalidate: "novalidate" } },
        [
          _c(
            "fieldset-component",
            {
              attrs: {
                title: _vm.$t(
                  "__.Components.parent.notafiscalsaida.edit_vue_html.dadosNfSaida"
                ),
                collapsed: false,
                disabled: "disabled",
              },
            },
            [
              _c(
                "div",
                { attrs: { slot: "rows" }, slot: "rows" },
                [
                  _c("fieldset", [
                    _vm.model.statusEnvio != 5 && _vm.model.statusEnvio != 3
                      ? _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-sm-6" }, [
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "tooltip",
                                    rawName: "v-tooltip",
                                    value: _vm.errors.first("statusEnvio"),
                                    expression: "errors.first('statusEnvio')",
                                  },
                                ],
                                staticClass: "form-group disabled",
                              },
                              [
                                _c(
                                  "label",
                                  {
                                    staticClass: "control-label",
                                    attrs: {
                                      for: "statusEnvio",
                                      title: _vm.$t("__.obrigatorio"),
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticStyle: { color: "red" } },
                                      [_vm._v("*")]
                                    ),
                                    _vm._v(
                                      "\n                                    " +
                                        _vm._s(
                                          _vm.$t(
                                            "__.Components.parent.notafiscalsaida.edit_vue_html.statusEmissao"
                                          )
                                        ) +
                                        "\n                                "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.model.statusEnvioDescricao,
                                      expression: "model.statusEnvioDescricao",
                                    },
                                    {
                                      name: "validate",
                                      rawName: "v-validate",
                                      value: "required",
                                      expression: "'required'",
                                    },
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    type: "text",
                                    id: "statusEnvio",
                                    name: "statusEnvio",
                                  },
                                  domProps: {
                                    value: _vm.model.statusEnvioDescricao,
                                  },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.model,
                                        "statusEnvioDescricao",
                                        $event.target.value
                                      )
                                    },
                                  },
                                }),
                              ]
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-sm-6" }, [
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "tooltip",
                                    rawName: "v-tooltip",
                                    value: _vm.errors.first("tipo"),
                                    expression: "errors.first('tipo')",
                                  },
                                ],
                                staticClass: "form-group disabled",
                              },
                              [
                                _c(
                                  "label",
                                  {
                                    staticClass: "control-label",
                                    attrs: {
                                      for: "tipo",
                                      title: _vm.$t("__.obrigatorio"),
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticStyle: { color: "red" } },
                                      [_vm._v("*")]
                                    ),
                                    _vm._v(
                                      "\n                                    " +
                                        _vm._s(
                                          _vm.$t(
                                            "__.Components.parent.notafiscalsaida.edit_vue_html.tipoNF"
                                          )
                                        ) +
                                        "\n                                "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.model.tipoDescricao,
                                      expression: "model.tipoDescricao",
                                    },
                                    {
                                      name: "validate",
                                      rawName: "v-validate",
                                      value: "required",
                                      expression: "'required'",
                                    },
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    type: "text",
                                    id: "tipo",
                                    name: "tipo",
                                  },
                                  domProps: { value: _vm.model.tipoDescricao },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.model,
                                        "tipoDescricao",
                                        $event.target.value
                                      )
                                    },
                                  },
                                }),
                              ]
                            ),
                          ]),
                        ])
                      : _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-sm-4" }, [
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "tooltip",
                                    rawName: "v-tooltip",
                                    value: _vm.errors.first("statusEnvio"),
                                    expression: "errors.first('statusEnvio')",
                                  },
                                ],
                                staticClass: "form-group disabled",
                              },
                              [
                                _c(
                                  "label",
                                  {
                                    staticClass: "control-label",
                                    attrs: {
                                      for: "statusEnvio",
                                      title: _vm.$t("__.obrigatorio"),
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticStyle: { color: "red" } },
                                      [_vm._v("*")]
                                    ),
                                    _vm._v(
                                      "\n                                    " +
                                        _vm._s(
                                          _vm.$t(
                                            "__.Components.parent.notafiscalsaida.edit_vue_html.statusEmissao"
                                          )
                                        ) +
                                        "\n                                "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.model.statusEnvioDescricao,
                                      expression: "model.statusEnvioDescricao",
                                    },
                                    {
                                      name: "validate",
                                      rawName: "v-validate",
                                      value: "required",
                                      expression: "'required'",
                                    },
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    type: "text",
                                    id: "statusEnvio",
                                    name: "statusEnvio",
                                  },
                                  domProps: {
                                    value: _vm.model.statusEnvioDescricao,
                                  },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.model,
                                        "statusEnvioDescricao",
                                        $event.target.value
                                      )
                                    },
                                  },
                                }),
                              ]
                            ),
                          ]),
                          _vm._v(" "),
                          _vm.model.tipo == 0
                            ? _c("div", { staticClass: "col-sm-4" }, [
                                _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "tooltip",
                                        rawName: "v-tooltip",
                                        value:
                                          _vm.errors.first(
                                            "motivoCancelamento"
                                          ),
                                        expression:
                                          "errors.first('motivoCancelamento')",
                                      },
                                    ],
                                    staticClass: "form-group disabled",
                                  },
                                  [
                                    _c(
                                      "label",
                                      {
                                        staticClass: "control-label",
                                        attrs: { for: "motivoCancelamento" },
                                      },
                                      [
                                        _vm._v(
                                          "\n                                    " +
                                            _vm._s(
                                              _vm.$t(
                                                "__.Components.parent.notafiscalsaida.edit_vue_html.motivoCancel"
                                              )
                                            ) +
                                            "\n                                "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.motivoDescricao,
                                          expression: "motivoDescricao",
                                        },
                                      ],
                                      staticClass: "form-control",
                                      attrs: {
                                        type: "text",
                                        id: "motivoCancelamento",
                                        name: "motivoCancelamento",
                                      },
                                      domProps: { value: _vm.motivoDescricao },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.motivoDescricao =
                                            $event.target.value
                                        },
                                      },
                                    }),
                                  ]
                                ),
                              ])
                            : _c("div", { staticClass: "col-sm-4" }, [
                                _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "tooltip",
                                        rawName: "v-tooltip",
                                        value:
                                          _vm.errors.first("motivoDescricao"),
                                        expression:
                                          "errors.first('motivoDescricao')",
                                      },
                                    ],
                                    staticClass: "form-group disabled",
                                  },
                                  [
                                    _c(
                                      "label",
                                      {
                                        staticClass: "control-label",
                                        attrs: { for: "motivoDescricao" },
                                      },
                                      [_vm._v("Motivo do Cancelamento")]
                                    ),
                                    _vm._v(" "),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.model.motivoDescricao,
                                          expression: "model.motivoDescricao",
                                        },
                                      ],
                                      staticClass: "form-control",
                                      attrs: {
                                        type: "text",
                                        id: "motivoDescricao",
                                        name: "motivoDescricao",
                                      },
                                      domProps: {
                                        value: _vm.model.motivoDescricao,
                                      },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            _vm.model,
                                            "motivoDescricao",
                                            $event.target.value
                                          )
                                        },
                                      },
                                    }),
                                  ]
                                ),
                              ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-sm-4" }, [
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "tooltip",
                                    rawName: "v-tooltip",
                                    value: _vm.errors.first("tipo"),
                                    expression: "errors.first('tipo')",
                                  },
                                ],
                                staticClass: "form-group disabled",
                              },
                              [
                                _c(
                                  "label",
                                  {
                                    staticClass: "control-label",
                                    attrs: {
                                      for: "tipo",
                                      title: _vm.$t("__.obrigatorio"),
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticStyle: { color: "red" } },
                                      [_vm._v("*")]
                                    ),
                                    _vm._v(
                                      "\n                                    " +
                                        _vm._s(
                                          _vm.$t(
                                            "__.Components.parent.notafiscalsaida.edit_vue_html.tipoNF"
                                          )
                                        ) +
                                        "\n                                "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.model.tipoDescricao,
                                      expression: "model.tipoDescricao",
                                    },
                                    {
                                      name: "validate",
                                      rawName: "v-validate",
                                      value: "required",
                                      expression: "'required'",
                                    },
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    type: "text",
                                    id: "tipo",
                                    name: "tipo",
                                  },
                                  domProps: { value: _vm.model.tipoDescricao },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.model,
                                        "tipoDescricao",
                                        $event.target.value
                                      )
                                    },
                                  },
                                }),
                              ]
                            ),
                          ]),
                        ]),
                    _vm._v(" "),
                    _vm.model.tipo == 0
                      ? _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-sm-4" }, [
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "tooltip",
                                    rawName: "v-tooltip",
                                    value: _vm.errors.first("numero"),
                                    expression: "errors.first('numero')",
                                  },
                                ],
                                staticClass: "form-group disabled",
                              },
                              [
                                _c(
                                  "label",
                                  {
                                    staticClass: "control-label",
                                    attrs: { for: "numero" },
                                  },
                                  [
                                    _vm._v(
                                      "\n                                    " +
                                        _vm._s(
                                          _vm.$t(
                                            "__.Components.parent.notafiscalsaida.edit_vue_html.nro"
                                          )
                                        ) +
                                        "\n                                "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.model.numero,
                                      expression: "model.numero",
                                    },
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    type: "text",
                                    id: "numero",
                                    name: "numero",
                                  },
                                  domProps: { value: _vm.model.numero },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.model,
                                        "numero",
                                        $event.target.value
                                      )
                                    },
                                  },
                                }),
                              ]
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-sm-4" }, [
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "tooltip",
                                    rawName: "v-tooltip",
                                    value: _vm.errors.first("dataEmissao"),
                                    expression: "errors.first('dataEmissao')",
                                  },
                                ],
                                staticClass: "form-group disabled",
                              },
                              [
                                _c(
                                  "label",
                                  {
                                    staticClass: "control-label",
                                    attrs: { for: "dataEmissao" },
                                  },
                                  [
                                    _vm._v(
                                      "\n                                    " +
                                        _vm._s(
                                          _vm.$t(
                                            "__.Components.parent.notafiscalsaida.edit_vue_html.dtEmissao"
                                          )
                                        ) +
                                        "\n                                "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c("date-time-picker-component", {
                                  attrs: {
                                    id: "dataEmissao",
                                    name: "dataEmissao",
                                    disabled: "disabled",
                                  },
                                  model: {
                                    value: _vm.model.dataEmissao,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.model, "dataEmissao", $$v)
                                    },
                                    expression: "model.dataEmissao",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-sm-4" }, [
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "tooltip",
                                    rawName: "v-tooltip",
                                    value: _vm.errors.first("dataInclusao"),
                                    expression: "errors.first('dataInclusao')",
                                  },
                                ],
                                staticClass: "form-group disabled",
                              },
                              [
                                _c(
                                  "label",
                                  {
                                    staticClass: "control-label",
                                    attrs: { for: "dataInclusao" },
                                  },
                                  [
                                    _vm._v(
                                      "\n                                    " +
                                        _vm._s(
                                          _vm.$t(
                                            "__.Components.parent.notafiscalsaida.edit_vue_html.dtInclusao"
                                          )
                                        ) +
                                        "\n                                "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c("date-time-picker-component", {
                                  attrs: {
                                    id: "dataInclusao",
                                    name: "dataInclusao",
                                    disabled: "disabled",
                                  },
                                  model: {
                                    value: _vm.model.dataInclusao,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.model, "dataInclusao", $$v)
                                    },
                                    expression: "model.dataInclusao",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ])
                      : _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-sm-3" }, [
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "tooltip",
                                    rawName: "v-tooltip",
                                    value: _vm.errors.first("numero"),
                                    expression: "errors.first('numero')",
                                  },
                                ],
                                staticClass: "form-group disabled",
                              },
                              [
                                _c(
                                  "label",
                                  {
                                    staticClass: "control-label",
                                    attrs: { for: "numero" },
                                  },
                                  [
                                    _vm._v(
                                      "\n                                    " +
                                        _vm._s(
                                          _vm.$t(
                                            "__.Components.parent.notafiscalsaida.edit_vue_html.nro"
                                          )
                                        ) +
                                        "\n                                "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.model.numero,
                                      expression: "model.numero",
                                    },
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    type: "text",
                                    id: "numero",
                                    name: "numero",
                                  },
                                  domProps: { value: _vm.model.numero },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.model,
                                        "numero",
                                        $event.target.value
                                      )
                                    },
                                  },
                                }),
                              ]
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-sm-3" }, [
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "tooltip",
                                    rawName: "v-tooltip",
                                    value: _vm.errors.first("serie"),
                                    expression: "errors.first('serie')",
                                  },
                                ],
                                staticClass: "form-group disabled",
                              },
                              [
                                _c(
                                  "label",
                                  {
                                    staticClass: "control-label",
                                    attrs: { for: "serie" },
                                  },
                                  [
                                    _vm._v(
                                      "\n                                    " +
                                        _vm._s(
                                          _vm.$t(
                                            "__.Components.parent.notafiscalsaida.edit_vue_html.serie"
                                          )
                                        ) +
                                        "\n                                "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.model.serie,
                                      expression: "model.serie",
                                    },
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    type: "text",
                                    id: "serie",
                                    name: "serie",
                                  },
                                  domProps: { value: _vm.model.serie },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.model,
                                        "serie",
                                        $event.target.value
                                      )
                                    },
                                  },
                                }),
                              ]
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-sm-3" }, [
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "tooltip",
                                    rawName: "v-tooltip",
                                    value: _vm.errors.first("dataEmissao"),
                                    expression: "errors.first('dataEmissao')",
                                  },
                                ],
                                staticClass: "form-group disabled",
                              },
                              [
                                _c(
                                  "label",
                                  {
                                    staticClass: "control-label",
                                    attrs: { for: "dataEmissao" },
                                  },
                                  [
                                    _vm._v(
                                      "\n                                    " +
                                        _vm._s(
                                          _vm.$t(
                                            "__.Components.parent.notafiscalsaida.edit_vue_html.dtEmissao"
                                          )
                                        ) +
                                        "\n                                "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c("date-time-picker-component", {
                                  attrs: {
                                    id: "dataEmissao",
                                    name: "dataEmissao",
                                    disabled: "disabled",
                                  },
                                  model: {
                                    value: _vm.model.dataEmissao,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.model, "dataEmissao", $$v)
                                    },
                                    expression: "model.dataEmissao",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-sm-3" }, [
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "tooltip",
                                    rawName: "v-tooltip",
                                    value: _vm.errors.first("dataInclusao"),
                                    expression: "errors.first('dataInclusao')",
                                  },
                                ],
                                staticClass: "form-group disabled",
                              },
                              [
                                _c(
                                  "label",
                                  {
                                    staticClass: "control-label",
                                    attrs: { for: "dataInclusao" },
                                  },
                                  [
                                    _vm._v(
                                      "\n                                    " +
                                        _vm._s(
                                          _vm.$t(
                                            "__.Components.parent.notafiscalsaida.edit_vue_html.dtInclusao"
                                          )
                                        ) +
                                        "\n                                "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c("date-time-picker-component", {
                                  attrs: {
                                    id: "dataInclusao",
                                    name: "dataInclusao",
                                    disabled: "disabled",
                                  },
                                  model: {
                                    value: _vm.model.dataInclusao,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.model, "dataInclusao", $$v)
                                    },
                                    expression: "model.dataInclusao",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-sm-6" }, [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: _vm.errors.first("searchKey"),
                                expression: "errors.first('searchKey')",
                              },
                            ],
                            staticClass: "form-group disabled",
                          },
                          [
                            _c(
                              "label",
                              {
                                staticClass: "control-label",
                                attrs: {
                                  for: "searchKey",
                                  title: _vm.$t("__.obrigatorio"),
                                },
                              },
                              [
                                _c("span", { staticStyle: { color: "red" } }, [
                                  _vm._v("*"),
                                ]),
                                _vm._v(
                                  "\n                                    " +
                                    _vm._s(
                                      _vm.$t(
                                        "__.Components.parent.notafiscalsaida.edit_vue_html.chaveBusca"
                                      )
                                    ) +
                                    "\n                                "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.model.searchKey,
                                  expression: "model.searchKey",
                                },
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "required",
                                  expression: "'required'",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: {
                                type: "text",
                                id: "searchKey",
                                name: "searchKey",
                              },
                              domProps: { value: _vm.model.searchKey },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.model,
                                    "searchKey",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _vm.model.tipo == 0
                        ? _c("div", { staticClass: "col-sm-6" }, [
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "tooltip",
                                    rawName: "v-tooltip",
                                    value:
                                      _vm.errors.first("codigoVerificacao"),
                                    expression:
                                      "errors.first('codigoVerificacao')",
                                  },
                                ],
                                staticClass: "form-group disabled",
                              },
                              [
                                _c(
                                  "label",
                                  {
                                    staticClass: "control-label",
                                    attrs: { for: "codigoVerificacao" },
                                  },
                                  [
                                    _vm._v(
                                      "\n                                    " +
                                        _vm._s(
                                          _vm.$t(
                                            "__.Components.parent.notafiscalsaida.edit_vue_html.codVerif"
                                          )
                                        ) +
                                        "\n                                "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.model.codigoVerificacao,
                                      expression: "model.codigoVerificacao",
                                    },
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    type: "text",
                                    id: "codigoVerificacao",
                                    name: "codigoVerificaco",
                                  },
                                  domProps: {
                                    value: _vm.model.codigoVerificacao,
                                  },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.model,
                                        "codigoVerificacao",
                                        $event.target.value
                                      )
                                    },
                                  },
                                }),
                              ]
                            ),
                          ])
                        : _c("div", { staticClass: "col-sm-6" }, [
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "tooltip",
                                    rawName: "v-tooltip",
                                    value: _vm.errors.first("chave"),
                                    expression: "errors.first('chave')",
                                  },
                                ],
                                staticClass: "form-group disabled",
                              },
                              [
                                _c(
                                  "label",
                                  {
                                    staticClass: "control-label",
                                    attrs: { for: "chave" },
                                  },
                                  [
                                    _vm._v(
                                      "\n                                    " +
                                        _vm._s(
                                          _vm.$t(
                                            "__.Components.parent.notafiscalsaida.edit_vue_html.chave"
                                          )
                                        ) +
                                        "\n                                "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.model.chave,
                                      expression: "model.chave",
                                    },
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    type: "text",
                                    id: "chave",
                                    name: "chave",
                                  },
                                  domProps: { value: _vm.model.chave },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.model,
                                        "chave",
                                        $event.target.value
                                      )
                                    },
                                  },
                                }),
                              ]
                            ),
                          ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _vm.model.tipo == 0 &&
                  _vm.model.notaFiscalSaidaItens.length > 0
                    ? _c(
                        "fieldset-component",
                        {
                          attrs: {
                            title: _vm.$t(
                              "__.Components.parent.notafiscalsaida.edit_vue_html.servico"
                            ),
                            collapsed: false,
                            disabled: "disabled",
                          },
                        },
                        [
                          _c(
                            "div",
                            { attrs: { slot: "rows" }, slot: "rows" },
                            [
                              _c("grid-component", {
                                attrs: {
                                  data: _vm.model.notaFiscalSaidaItens,
                                  columns: _vm.gridColumnsItens,
                                  "show-table-head": false,
                                  "show-table-foot": false,
                                  "show-edit-item": false,
                                  "show-remove-item": false,
                                  showActionColumn: false,
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.model.tipo == 1 &&
                  _vm.model.notaFiscalSaidaItens.length > 0
                    ? _c(
                        "fieldset-component",
                        {
                          attrs: {
                            title: _vm.$t(
                              "__.Components.parent.notafiscalsaida.edit_vue_html.produtos"
                            ),
                            collapsed: false,
                            disabled: "disabled",
                          },
                        },
                        [
                          _c(
                            "div",
                            { attrs: { slot: "rows" }, slot: "rows" },
                            [
                              _c("grid-component", {
                                attrs: {
                                  data: _vm.model.notaFiscalSaidaItens,
                                  columns: _vm.gridColumnsProdutos,
                                  "show-table-head": false,
                                  "show-table-foot": false,
                                  "show-edit-item": false,
                                  "show-remove-item": false,
                                  showActionColumn: false,
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.model.notaFiscalSaidaTotalISSQN != null
                    ? _c(
                        "fieldset-component",
                        {
                          attrs: {
                            title: _vm.$t(
                              "__.Components.parent.notafiscalsaida.edit_vue_html.totais"
                            ),
                            collapsed: false,
                            disabled: "disabled",
                          },
                        },
                        [
                          _c("div", { attrs: { slot: "rows" }, slot: "rows" }, [
                            _c("div", { staticClass: "row" }, [
                              _c("div", { staticClass: "col-sm-3" }, [
                                _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "tooltip",
                                        rawName: "v-tooltip",
                                        value:
                                          _vm.errors.first("valorBaseCalculo"),
                                        expression:
                                          "errors.first('valorBaseCalculo')",
                                      },
                                    ],
                                    staticClass: "form-group disabled",
                                  },
                                  [
                                    _c(
                                      "label",
                                      {
                                        staticClass: "control-label",
                                        attrs: {
                                          for: "valorBaseCalculo",
                                          title: _vm.$t("__.obrigatorio"),
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticStyle: { color: "red" } },
                                          [_vm._v("*")]
                                        ),
                                        _vm._v(
                                          "\n                                        " +
                                            _vm._s(
                                              _vm.$t(
                                                "__.Components.parent.notafiscalsaida.edit_vue_html.baseCalculo"
                                              )
                                            ) +
                                            "\n                                    "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("moeda-component", {
                                      directives: [
                                        {
                                          name: "validate",
                                          rawName: "v-validate",
                                          value: "required",
                                          expression: "'required'",
                                        },
                                      ],
                                      staticClass: "form-control",
                                      attrs: {
                                        id: "valorBaseCalculo",
                                        name: "valorBaseCalculo",
                                      },
                                      model: {
                                        value:
                                          _vm.model.notaFiscalSaidaTotalISSQN
                                            .valorBaseCalculo,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.model.notaFiscalSaidaTotalISSQN,
                                            "valorBaseCalculo",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "model.notaFiscalSaidaTotalISSQN.valorBaseCalculo",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "col-sm-3" }, [
                                _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "tooltip",
                                        rawName: "v-tooltip",
                                        value: _vm.errors.first("valorServico"),
                                        expression:
                                          "errors.first('valorServico')",
                                      },
                                    ],
                                    staticClass: "form-group disabled",
                                  },
                                  [
                                    _c(
                                      "label",
                                      {
                                        staticClass: "control-label",
                                        attrs: {
                                          for: "valorServico",
                                          title: _vm.$t("__.obrigatorio"),
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticStyle: { color: "red" } },
                                          [_vm._v("*")]
                                        ),
                                        _vm._v(
                                          "\n                                        " +
                                            _vm._s(
                                              _vm.$t(
                                                "__.Components.parent.notafiscalsaida.edit_vue_html.vlrServico"
                                              )
                                            ) +
                                            "\n                                    "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("moeda-component", {
                                      directives: [
                                        {
                                          name: "validate",
                                          rawName: "v-validate",
                                          value: "required",
                                          expression: "'required'",
                                        },
                                      ],
                                      staticClass: "form-control",
                                      attrs: {
                                        id: "valorServico",
                                        name: "valorServico",
                                      },
                                      model: {
                                        value:
                                          _vm.model.notaFiscalSaidaTotalISSQN
                                            .valorServico,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.model.notaFiscalSaidaTotalISSQN,
                                            "valorServico",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "model.notaFiscalSaidaTotalISSQN.valorServico",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "col-sm-3" }, [
                                _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "tooltip",
                                        rawName: "v-tooltip",
                                        value: _vm.errors.first("valorISS"),
                                        expression: "errors.first('valorISS')",
                                      },
                                    ],
                                    staticClass: "form-group disabled",
                                  },
                                  [
                                    _c(
                                      "label",
                                      {
                                        staticClass: "control-label",
                                        attrs: {
                                          for: "valorISS",
                                          title: _vm.$t("__.obrigatorio"),
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticStyle: { color: "red" } },
                                          [_vm._v("*")]
                                        ),
                                        _vm._v(
                                          "\n                                        " +
                                            _vm._s(
                                              _vm.$t(
                                                "__.Components.parent.notafiscalsaida.edit_vue_html.vlrISS"
                                              )
                                            ) +
                                            "\n                                    "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("moeda-component", {
                                      directives: [
                                        {
                                          name: "validate",
                                          rawName: "v-validate",
                                          value: "required",
                                          expression: "'required'",
                                        },
                                      ],
                                      staticClass: "form-control",
                                      attrs: {
                                        id: "valorISS",
                                        name: "valorISS",
                                      },
                                      model: {
                                        value:
                                          _vm.model.notaFiscalSaidaTotalISSQN
                                            .valorISS,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.model.notaFiscalSaidaTotalISSQN,
                                            "valorISS",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "model.notaFiscalSaidaTotalISSQN.valorISS",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "col-sm-3" }, [
                                _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "tooltip",
                                        rawName: "v-tooltip",
                                        value:
                                          _vm.errors.first("valorTotalNfse"),
                                        expression:
                                          "errors.first('valorTotalNfse')",
                                      },
                                    ],
                                    staticClass: "form-group disabled",
                                  },
                                  [
                                    _c(
                                      "label",
                                      {
                                        staticClass: "control-label",
                                        attrs: {
                                          for: "valorTotalNfse",
                                          title: _vm.$t("__.obrigatorio"),
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticStyle: { color: "red" } },
                                          [_vm._v("*")]
                                        ),
                                        _vm._v(
                                          "\n                                        " +
                                            _vm._s(
                                              _vm.$t(
                                                "__.Components.parent.notafiscalsaida.edit_vue_html.vlrTotalServicos"
                                              )
                                            ) +
                                            "\n                                    "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("moeda-component", {
                                      directives: [
                                        {
                                          name: "validate",
                                          rawName: "v-validate",
                                          value: "required",
                                          expression: "'required'",
                                        },
                                      ],
                                      staticClass: "form-control",
                                      attrs: {
                                        id: "valorTotalNfse",
                                        name: "valorTotalNfse",
                                      },
                                      model: {
                                        value:
                                          _vm.model.notaFiscalSaidaTotalISSQN
                                            .valorTotalNfse,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.model.notaFiscalSaidaTotalISSQN,
                                            "valorTotalNfse",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "model.notaFiscalSaidaTotalISSQN.valorTotalNfse",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                            ]),
                          ]),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.model.notaFiscalSaidaTotalICMS != null
                    ? _c(
                        "fieldset-component",
                        {
                          attrs: {
                            title: _vm.$t(
                              "__.Components.parent.notafiscalsaida.edit_vue_html.totais"
                            ),
                            collapsed: false,
                            disabled: "disabled",
                          },
                        },
                        [
                          _c("div", { attrs: { slot: "rows" }, slot: "rows" }, [
                            _c("div", { staticClass: "row" }, [
                              _c("div", { staticClass: "col-sm-3" }, [
                                _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "tooltip",
                                        rawName: "v-tooltip",
                                        value:
                                          _vm.errors.first("valorBaseCalculo"),
                                        expression:
                                          "errors.first('valorBaseCalculo')",
                                      },
                                    ],
                                    staticClass: "form-group disabled",
                                  },
                                  [
                                    _c(
                                      "label",
                                      {
                                        staticClass: "control-label",
                                        attrs: { for: "valorBaseCalculo" },
                                      },
                                      [
                                        _vm._v(
                                          "\n                                        " +
                                            _vm._s(
                                              _vm.$t(
                                                "__.Components.parent.notafiscalsaida.edit_vue_html.baseCalculos"
                                              )
                                            ) +
                                            "\n                                    "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("moeda-component", {
                                      staticClass: "form-control",
                                      attrs: {
                                        id: "valorBaseCalculo",
                                        name: "valorBaseCalculo",
                                      },
                                      model: {
                                        value:
                                          _vm.model.notaFiscalSaidaTotalICMS
                                            .valorBC,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.model.notaFiscalSaidaTotalICMS,
                                            "valorBC",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "model.notaFiscalSaidaTotalICMS.valorBC",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "col-sm-3" }, [
                                _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "tooltip",
                                        rawName: "v-tooltip",
                                        value: _vm.errors.first("valorProd"),
                                        expression: "errors.first('valorProd')",
                                      },
                                    ],
                                    staticClass: "form-group disabled",
                                  },
                                  [
                                    _c(
                                      "label",
                                      {
                                        staticClass: "control-label",
                                        attrs: { for: "valorProd" },
                                      },
                                      [
                                        _vm._v(
                                          "\n                                        " +
                                            _vm._s(
                                              _vm.$t(
                                                "__.Components.parent.notafiscalsaida.edit_vue_html.vlrProds"
                                              )
                                            ) +
                                            "\n                                    "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("moeda-component", {
                                      staticClass: "form-control",
                                      attrs: {
                                        id: "valorProd",
                                        name: "valorProd",
                                      },
                                      model: {
                                        value:
                                          _vm.model.notaFiscalSaidaTotalICMS
                                            .valorProd,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.model.notaFiscalSaidaTotalICMS,
                                            "valorProd",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "model.notaFiscalSaidaTotalICMS.valorProd",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "col-sm-3" }, [
                                _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "tooltip",
                                        rawName: "v-tooltip",
                                        value: _vm.errors.first("valorICMS"),
                                        expression: "errors.first('valorICMS')",
                                      },
                                    ],
                                    staticClass: "form-group disabled",
                                  },
                                  [
                                    _c(
                                      "label",
                                      {
                                        staticClass: "control-label",
                                        attrs: { for: "valorICMS" },
                                      },
                                      [
                                        _vm._v(
                                          "\n                                        " +
                                            _vm._s(
                                              _vm.$t(
                                                "__.Components.parent.notafiscalsaida.edit_vue_html.valorICMS"
                                              )
                                            ) +
                                            "\n                                    "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("moeda-component", {
                                      staticClass: "form-control",
                                      attrs: {
                                        id: "valorICMS",
                                        name: "valorICMS",
                                      },
                                      model: {
                                        value:
                                          _vm.model.notaFiscalSaidaTotalICMS
                                            .valorICMS,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.model.notaFiscalSaidaTotalICMS,
                                            "valorICMS",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "model.notaFiscalSaidaTotalICMS.valorICMS",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "col-sm-3" }, [
                                _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "tooltip",
                                        rawName: "v-tooltip",
                                        value: _vm.errors.first(
                                          "valorTotalNotaFiscal"
                                        ),
                                        expression:
                                          "errors.first('valorTotalNotaFiscal')",
                                      },
                                    ],
                                    staticClass: "form-group disabled",
                                  },
                                  [
                                    _c(
                                      "label",
                                      {
                                        staticClass: "control-label",
                                        attrs: { for: "valorTotalNotaFiscal" },
                                      },
                                      [
                                        _vm._v(
                                          "\n                                        " +
                                            _vm._s(
                                              _vm.$t(
                                                "__.Components.parent.notafiscalsaida.edit_vue_html.vlrTotal"
                                              )
                                            ) +
                                            "\n                                    "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("moeda-component", {
                                      staticClass: "form-control",
                                      attrs: {
                                        id: "valorTotalNotaFiscal",
                                        name: "valorTotalNotaFiscal",
                                      },
                                      model: {
                                        value:
                                          _vm.model.notaFiscalSaidaTotalICMS
                                            .valorTotalNotaFiscal,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.model.notaFiscalSaidaTotalICMS,
                                            "valorTotalNotaFiscal",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "model.notaFiscalSaidaTotalICMS.valorTotalNotaFiscal",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                            ]),
                          ]),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _vm.model.id && _vm.model.usuarioCriacaoNome
            ? _c("infoBagdeComponent", { attrs: { text: _vm.infoBagdeText } })
            : _vm._e(),
          _vm._v(" "),
          _c("fieldset", [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-sm-12 newbtn" }, [
                _c("div", { staticClass: "form-group" }, [
                  _vm.permiteConsultar()
                    ? _c(
                        "button",
                        {
                          staticClass: "btn btn-success",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              _vm.consultaEmissao()
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n                            " +
                              _vm._s(
                                _vm.$t(
                                  "__.Components.parent.notafiscalsaida.edit_vue_html.consultEmissao"
                                )
                              ) +
                              "\n                        "
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-secondary",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          _vm.cancel()
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n                            " +
                          _vm._s(_vm.$t("__.Crud.voltar")) +
                          "\n                        "
                      ),
                    ]
                  ),
                ]),
              ]),
            ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }