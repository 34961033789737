import ClienteEnderecoEntregaModel from "../models/clienteEnderecoEntregaModel";
import ClienteModel from "../models/clienteModel";
import nfetch from "../utils/events/nfetch";

import { IService } from "./base/iService";

export default class ClienteService extends IService<ClienteModel> {
    constructor() {
        super("Cliente");
    }

    public insertUpdateEnderecoEntrega(model: ClienteEnderecoEntregaModel): Promise<Response> {
        return nfetch(`/${this.controllerName}/InsertUpdateEnderecoEntrega`, {
            method: "post",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json; charset=UTF-8",
                "XSRF-TOKEN": this.getCookie("XSRF-TOKEN"),
            },
            body: JSON.stringify(model),
        });
    }

    public listCliente(
        filterKey: string,
        sortKey: string,
        sortOrder: string,
        pageIndex: number,
        pageSize: number,
        emissaoDe = "",
        emissaoAte = "",
    ): Promise<Response> {
        const encodedFilter = encodeURIComponent(filterKey);
        return nfetch(
            `/${this.controllerName}/ListCliente?filterKey=${encodedFilter}&sortKey=${sortKey}&sortOrder=${sortOrder}&pageIndex=${pageIndex}&pageSize=${pageSize}&emissaoDe=${emissaoDe}&emissaoAte=${emissaoAte}`,
            {
                credentials: "same-origin",
                method: "get",
            },
        );
    }

    public getClientesDuplicados(filterKey: string, pageIndex: number, pageSize: number): Promise<Response> {
        const encodedFilter = encodeURIComponent(filterKey);

        return nfetch(
            `/${this.controllerName}/GetClientesDuplicados?filterKey=${encodedFilter}&pageIndex=${pageIndex}&pageSize=${pageSize}`,
            {
                credentials: "same-origin",
            },
        );
    }

    public getClientesDuplicadosByCliente(model: ClienteModel): Promise<Response> {
        return nfetch(`/${this.controllerName}/GetClientesDuplicadosByCliente`, {
            method: "post",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json; charset=UTF-8",
                "XSRF-TOKEN": this.getCookie("XSRF-TOKEN"),
            },
            body: JSON.stringify(model),
        });
    }

    public unificarClientes(clienteIdOrigem: number, clienteIdDestino: number): Promise<Response> {
        return nfetch(
            `/${this.controllerName}/UnificarClientes?clienteIdOrigem=${clienteIdOrigem}&clienteIdDestino=${clienteIdDestino}`,
            {
                method: "post",
                credentials: "same-origin",
                headers: {
                    "Content-Type": "application/json; charset=UTF-8",
                    "XSRF-TOKEN": this.getCookie("XSRF-TOKEN"),
                },
            },
        );
    }

    public existsByCPF(cpf: string): Promise<Response> {
        return nfetch(`/${this.controllerName}/ExistsByCPF?cpf=${cpf}`, {
            credentials: "same-origin",
            method: "get",
        });
    }

    public getNotificacoesByClienteId(clienteId: number): Promise<Response> {
        return nfetch(`/${this.controllerName}/GetNotificacoesByClienteId?clienteId=${clienteId}`, {
            credentials: "same-origin",
            method: "get",
        });
    }
}
