var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.nota.notaExportacao
    ? _c(
        "fieldset-component",
        { attrs: { title: "Exportação", collapsed: false } },
        [
          _c("div", { attrs: { slot: "rows" }, slot: "rows" }, [
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "tooltip",
                      rawName: "v-tooltip",
                      value: _vm.errors.first("ufSaidaPaisId"),
                      expression: "errors.first('ufSaidaPaisId')",
                    },
                  ],
                  staticClass: "col-sm-4",
                },
                [
                  !_vm.consulta
                    ? _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "control-label",
                              attrs: {
                                for: "ufSaidaPaisId",
                                title: _vm.$t("__.obrigatorio"),
                              },
                            },
                            [
                              _c("span", { staticStyle: { color: "red" } }, [
                                _vm._v("* "),
                              ]),
                              _vm._v("UF de saída do país"),
                            ]
                          ),
                          _vm._v(" "),
                          _c("combo-component", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required",
                                expression: "'required'",
                              },
                            ],
                            attrs: {
                              id: "ufSaidaPaisId",
                              name: "ufSaidaPaisId",
                              data: _vm.ufOptions,
                              searchable: true,
                            },
                            model: {
                              value: _vm.model.ufSaidaPaisId,
                              callback: function ($$v) {
                                _vm.$set(_vm.model, "ufSaidaPaisId", $$v)
                              },
                              expression: "model.ufSaidaPaisId",
                            },
                          }),
                        ],
                        1
                      )
                    : _c("div", { staticClass: "form-group" }, [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "ufSaidaPais" },
                          },
                          [_vm._v("UF de saída do país")]
                        ),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.model.ufSaidaPais,
                              expression: "model.ufSaidaPais",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            type: "text",
                            id: "ufSaidaPais",
                            name: "ufSaidaPais",
                            disabled: "",
                          },
                          domProps: { value: _vm.model.ufSaidaPais },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.model,
                                "ufSaidaPais",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "tooltip",
                      rawName: "v-tooltip",
                      value: _vm.errors.first("localExportacao"),
                      expression: "errors.first('localExportacao')",
                    },
                  ],
                  staticClass: "col-sm-4",
                },
                [
                  _c("div", { staticClass: "form-group" }, [
                    _c(
                      "label",
                      {
                        staticClass: "control-label",
                        attrs: {
                          for: "localExportacao",
                          title: _vm.$t("__.obrigatorio"),
                        },
                      },
                      [
                        _c("span", { staticStyle: { color: "red" } }, [
                          _vm._v("* "),
                        ]),
                        _vm._v("Local de exportação"),
                      ]
                    ),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.model.localExportacao,
                          expression: "model.localExportacao",
                        },
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required",
                          expression: "'required'",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: {
                        type: "text",
                        id: "localExportacao",
                        name: "localExportacao",
                        disabled: _vm.consulta,
                      },
                      domProps: { value: _vm.model.localExportacao },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.model,
                            "localExportacao",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "tooltip",
                      rawName: "v-tooltip",
                      value: _vm.errors.first("localDesembaraco"),
                      expression: "errors.first('localDesembaraco')",
                    },
                  ],
                  staticClass: "col-sm-4",
                },
                [
                  _c("div", { staticClass: "form-group" }, [
                    _c(
                      "label",
                      {
                        staticClass: "control-label",
                        attrs: {
                          for: "localDesembaraco",
                          title: _vm.$t("__.obrigatorio"),
                        },
                      },
                      [
                        _c("span", { staticStyle: { color: "red" } }, [
                          _vm._v("* "),
                        ]),
                        _vm._v("Local de desembaraço"),
                      ]
                    ),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.model.localDesembaraco,
                          expression: "model.localDesembaraco",
                        },
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required",
                          expression: "'required'",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: {
                        type: "text",
                        id: "localDesembaraco",
                        name: "localDesembaraco",
                        disabled: _vm.consulta,
                      },
                      domProps: { value: _vm.model.localDesembaraco },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.model,
                            "localDesembaraco",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                ]
              ),
            ]),
          ]),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }