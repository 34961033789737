import comboComponent from "@/components/child/form/combo.vue";
import dateTimePickerComponent from "@/components/child/form/datetimepicker.vue";
import { GridColumn, GridColumnType } from "@/components/child/grid/gridColumn";
import { Component } from "@/decorators";
import StatusContas from "@/models/enum/statusContas";
import ContaReceberModel, { ContaReceberListParameters } from "@/models/financeiro/contaReceberModel";
import FormaRecebimentoModel from "@/models/financeiro/formaRecebimentoModel";
import PaginationModel from "@/models/paginationModel";
import ContaReceberService from "@/services/financeiro/contaReceberService";
import FormaRecebimentoService from "@/services/financeiro/formaRecebimentoService";
import { convertToDateInput, convertToDateInputString } from "@/utils/common/date";

import ListComponentBase from "../listComponentBase";
import "../crud.scss";

@Component({
    components: {
        comboComponent,
        dateTimePickerComponent,
    },
})
export default class ContaParceladasListComponent extends ListComponentBase<ContaReceberModel> {
    get gridColumns(): Array<GridColumn> {
        return [
            new GridColumn("dataVencimento", "Vencimento", GridColumnType.Date, false, false, "", "", true),
            new GridColumn("descricao", "Descri\u00e7\u00e3o", GridColumnType.String, false, false, "", "", true),
            new GridColumn("clienteDescricao", "Cliente", GridColumnType.String, false, false, "", "", true),
            new GridColumn("valorTotal", "Valor Original", GridColumnType.Money, false, false, "", "", true),
            new GridColumn("saldo", "Saldo Pendente", GridColumnType.Money, false, false, "", "", true),
            new GridColumn(
                "contaGerencialDescricao",
                "Conta Gerencial",
                GridColumnType.String,
                false,
                false,
                "",
                "",
                true,
            ),
        ];
    }

    subDataName = "movimentacoes";

    get gridColumnsVinculos(): Array<GridColumn> {
        return [
            new GridColumn("dataMovimento", "Recebimento", GridColumnType.Date),
            new GridColumn("valor", "Valor", GridColumnType.Money),
            new GridColumn("observacao", "Observação", GridColumnType.String),
            new GridColumn("contaCorrenteDescricao", "Conta Corrente", GridColumnType.String),
            new GridColumn("formaPagamentoDescricao", "Forma Pagamento", GridColumnType.String),
        ];
    }

    private contaReceberService = new ContaReceberService();
    isHidden = false;
    formaPagamentoOptions: Array<Object> = [];
    filtro: Array<Object> = [];
    formaPagamento?: number = null;

    dataFilter1: Date = null;
    dataFilter2: Date = null;

    conditionShowSubData(it) {
        return it["movimentacoes"]["length"] > 0;
    }

    private beforeMount() {
        this.setProps(new ContaReceberService(), "contareceber", "dataVencimento");

        this.loadFormaPagamentos();
    }

    public async loadMethod() {
        try {
            this.isHidden = false;

            const customParameters: ContaReceberListParameters = {
                statusContas: StatusContas.ParcialmenteLiquidado,
                filterKeyId: "",
                dataVencimentoIniStr: convertToDateInputString(this.filtro[0]),
                dataVencimentoFinStr: convertToDateInputString(this.filtro[1], true),
                contaCorrenteId: null,
                contaGerencialId: null,
                onlyClientes: false,
                onlyOperadoras: false,
                onlyParceladas: true,
                formaPagamentoId: this.filtro[2] ? <number>this.filtro[2] : null,
            };

            return await this.contaReceberService
                .listParceladas(
                    this.gridFilterKey,
                    this.gridSortKey,
                    this.gridSortOrder,
                    this.pags ? 1 : this.pageIndex,
                    this.pags ? 999999 : this.pageSize,
                    customParameters,
                )
                .withLoading()
                .resolveWithJSON<PaginationModel<ContaReceberModel>>();
        } catch {}
    }

    private getFormValues(listaFiltro) {
        this.filtro = [];

        this.filtro.push(listaFiltro.target.elements.dataFilter1.value);

        this.filtro.push(listaFiltro.target.elements.dataFilter2.value);

        this.filtro.push(this.formaPagamento);

        this.load();
    }

    private async loadFormaPagamentos() {
        try {
            const data = await new FormaRecebimentoService()
                .comboByEspecie()
                .resolveWithJSON<PaginationModel<FormaRecebimentoModel>>();
            this.formaPagamentoOptions = data.list.map(item => ({ value: item.id, text: item.descricao }));
        } catch {}
    }

    public async afterLoadPagination() {
        if (this.paginationParams.filtros != null) {
            this.filtro = this.paginationParams.filtros;

            if (this.filtro.length >= 0) {
                if (this.filtro[0] != null && this.filtro[0] != "") {
                    this.dataFilter1 = convertToDateInput(this.filtro[0].toString());
                }
                if (this.filtro[1] != null && this.filtro[1] != "") {
                    this.dataFilter2 = convertToDateInput(this.filtro[1].toString());
                }
                if (this.filtro[2] != null && <number>this.filtro[2] > 0) {
                    this.formaPagamento = <number>this.filtro[2];
                }
            }
        }
    }
}
