import Vue from "vue";
import { mapMutations, mapState } from "vuex";

import comboComponent from "@/components/child/form/combo.vue";
import dateTimePickerComponent from "@/components/child/form/datetimepicker.vue";
import Grid from "@/components/child/grid/grid";
import gridComponent from "@/components/child/grid/grid.vue";
import { GridColumn, GridColumnType } from "@/components/child/grid/gridColumn";
import LoadingModalComponent from "@/components/child/loadingmodal/loadingmodal";
import loadingModalComponent from "@/components/child/loadingmodal/loadingmodal.vue";
import { Component, Watch } from "@/decorators";
import PaginationPackModel from "@/models/auxiliar/paginationPackModel";
import StatusContas from "@/models/enum/statusContas";
import ContaCorrenteModel from "@/models/financeiro/contaCorrenteModel";
import ContaReceberModel, { ContaReceberListParameters } from "@/models/financeiro/contaReceberModel";
import PaginationModel from "@/models/paginationModel";
import ContaCorrenteService from "@/services/financeiro/contaCorrenteService";
import ContaReceberService from "@/services/financeiro/contaReceberService";
import { convertToDateInput, convertToDateInputString } from "@/utils/common/date";
import Delay from "@/utils/common/delay";

import "../crud.scss";

@Component({
    components: {
        gridComponent,
        comboComponent,
        dateTimePickerComponent,
        loadingModalComponent,
    },
    computed: mapState({
        loadingPack: state => state["paginationStatus"].loading,
        paginationParams: state => state["paginationStatus"].paginationParams,
    }),
    methods: mapMutations(["SET_PARAMS", "GET_PARAMS"]),
})
export default class ContaRecebidaListComponent extends Vue {
    private service = new ContaReceberService();
    private loadingModalComponent: LoadingModalComponent = null;
    private gridcomponent: Grid = null;

    startFilterKey = "";
    gridFilterKey = "";
    gridSortKey = "dataVencimento";
    gridSortOrder = "asc";
    gridData: Array<ContaReceberModel> = [];

    //Venc./Descrição/Cliente/ Valor Receb./Conta / Valor Original/ Juros / Descontos / Data do Recebimento / Saldo
    get gridColumns(): Array<GridColumn> {
        return [
            new GridColumn("dataVencimento", "Vencimento", GridColumnType.Date, false, false, "", "", true),
            new GridColumn("descricao", "Descri\u00e7\u00e3o", GridColumnType.String, false, false, "", "", true),
            new GridColumn("operadoraDescricao", "Operadora", GridColumnType.String, false, false, "", "", true),
            new GridColumn("clienteDescricao", "Cliente", GridColumnType.String, false, false, "", "", true),
            new GridColumn("autorizacao", "Autoriza\u00e7\u00e3o", GridColumnType.String, false, false, "", "", true),
            new GridColumn("totalValor", "Valor Receb.", GridColumnType.Money, false, false, "", "", false, false),
            new GridColumn("tipoConta", "Conta", GridColumnType.String, false, false, "", "", false, false),
            new GridColumn("valorTotal", "Valor Original", GridColumnType.Money),
            new GridColumn("totalData", "Recebimento", GridColumnType.Date, false, false, "", "", false, false),
        ];
    }

    subDataName = "lancamentosVinculados";

    gridColumnsVinculos: Array<GridColumn> = [
        new GridColumn("dataVencimento", "Vencimento", GridColumnType.Date),
        new GridColumn("descricao", "Descri\u00e7\u00e3o", GridColumnType.String),
        new GridColumn("valorTotal", "Valor", GridColumnType.Money),
    ];

    pageIndex = 1;
    pageSize = 20;
    total = 0;

    isHidden = false;
    contaCorrenteOptions: Array<Object> = [];
    filtro: Array<Object> = [];
    contasFiltro?: number = null;

    dataFilter1: Date = null;
    dataFilter2: Date = null;

    pags = false;
    tipoDocGerar: number = null;

    conditionShowSubData(it) {
        return it["lancamentosVinculados"]["length"] > 0;
    }

    private load() {
        this.loadingModalComponent.showLoading();
        this.isHidden = false;

        const customParameters: ContaReceberListParameters = {
            statusContas: StatusContas.Liquidado,
            filterKeyId: "",
            dataVencimentoIniStr: convertToDateInputString(this.filtro[0]),
            dataVencimentoFinStr: convertToDateInputString(this.filtro[1], true),
            contaCorrenteId: this.filtro[2] ? <number>this.filtro[2] : null,
            contaGerencialId: null,
            onlyClientes: false,
            onlyOperadoras: true,
        };
        this.service
            .list(
                this.gridFilterKey,
                this.gridSortKey,
                this.gridSortOrder,
                this.pags ? 1 : this.pageIndex,
                this.pags ? 999999 : this.pageSize,
                customParameters,
            )
            .then(response => {
                return response.json() as Promise<PaginationModel<ContaReceberModel>>;
            })
            .then(data => {
                //atualiza params no store
                if (data.total > 0)
                    this["SET_PARAMS"]({
                        routePath: this.$route.path,
                        filterKey: this.gridFilterKey,
                        sortKey: this.gridSortKey,
                        sortOrder: this.gridSortOrder,
                        pageIndex: this.pageIndex,
                        pageSize: this.pageSize,
                        filtros: this.filtro,
                    });

                this.gridData = data.list;
                this.gridData["dataDe"] = this.filtro[0] ? this.filtro[0].toString() : " ";
                this.gridData["dataAte"] = this.filtro[1] ? this.filtro[1].toString() : " ";
                if (this.filtro[2]) this.gridData["caixa"] = data.list[0]["tipoConta"];

                this.total = data.total;

                if (this.pags) {
                    if (this.tipoDocGerar == 0) {
                        setTimeout(() => {
                            this.gridcomponent.gerarXls();
                        }, 2000);
                    }

                    if (this.tipoDocGerar == 1) {
                        setTimeout(() => {
                            this.gridcomponent.gerarPdf();
                        }, 2000);
                    }

                    if (this.tipoDocGerar == 2) {
                        setTimeout(() => {
                            this.gridcomponent.gerarImpressao();
                        }, 2000);
                    }

                    setTimeout(() => {
                        this.pags = false;
                    }, 2000);
                }

                this.loadingModalComponent.hide();
            })
            .catch(error => {
                this.loadingModalComponent.hide();
            });
    }

    private onEditItem(model: ContaReceberModel) {
        this.$router.push("/contareceber-edicao/" + model.id);
    }

    private async onRemoveItem(model: ContaReceberModel) {
        const response = await this.$showExclusaoQuestion(`${this.$t("__.ts.desejaExcl")}${model.descricao}?`);

        if (response) {
            try {
                const sucesso = await this.service.delete(model.id).withLoading().resolveWithoutJSON();
                if (sucesso) {
                    this.load();
                    this.$showExclusao();
                }
            } catch {}
        }
    }

    private onCreateItem() {
        this.$router.push("/contareceber-edicao");
    }

    private onChangeFilterKey(filterKey: string) {
        this.gridFilterKey = filterKey;
        this.load();
    }

    private onChangeSort(sortKey: string, sortOrder: string) {
        this.gridSortKey = sortKey;
        this.gridSortOrder = sortOrder;
        this.load();
    }

    private onChangePage(pageIndex: number) {
        this.pageIndex = pageIndex;
        this.load();
    }

    private getFormValues(listaFiltro) {
        this.filtro = [];

        this.filtro.push(listaFiltro.target.elements.dataFilter1.value);

        this.filtro.push(listaFiltro.target.elements.dataFilter2.value);

        this.filtro.push(this.contasFiltro);

        this.load();
    }

    private async loadContasCorrentes() {
        try {
            const data = await new ContaCorrenteService()
                .combo()
                .then(r => r.json() as Promise<PaginationModel<ContaCorrenteModel>>);
            this.contaCorrenteOptions = data.list.map(item => {
                return { value: item.id, text: item.descricao };
            });
        } catch {}
    }

    @Watch("pags")
    private onPags() {
        this.load();
    }

    private async loadPagination() {
        await this["GET_PARAMS"](this.$route.path);
        while (this["loadingPack"]) {
            await Delay(50);
        }
        const paginationPack = this["paginationParams"] as PaginationPackModel;

        if (paginationPack != null) {
            this.gridFilterKey = paginationPack.filterKey;
            this.startFilterKey = this.gridFilterKey;

            this.gridSortKey = paginationPack.sortKey;
            this.gridSortOrder = paginationPack.sortOrder;
            this.pageIndex = paginationPack.pageIndex;
            this.pageSize = paginationPack.pageSize;
            if (paginationPack.filtros != null) {
                this.filtro = paginationPack.filtros;
                if (this.filtro.length >= 0) {
                    if (this.filtro[0]) {
                        this.dataFilter1 = convertToDateInput(this.filtro[0].toString());
                    }
                    if (this.filtro[1]) {
                        this.dataFilter2 = convertToDateInput(this.filtro[1].toString());
                    }
                    if (this.filtro[2] != null && <number>this.filtro[2] > 0) {
                        this.contasFiltro = <number>this.filtro[2];
                    }
                }
            }
        }
    }

    private mounted() {
        this.loadingModalComponent = this.$refs.loadingModalComponent as LoadingModalComponent;
        this.gridcomponent = this.$refs.gridcomponent as Grid;
        this.loadContasCorrentes();
        this.loadingModalComponent.showLoading();

        Promise.all([this.loadPagination()])
            .then(result => {
                this.load();
                this.loadingModalComponent.hide();
            })
            .catch(error => {
                this.loadingModalComponent.hide();
            });
    }
}
