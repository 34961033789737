var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "crud crud-list" },
    [
      _c("div", { staticClass: "info-div" }, [
        _c("h1", { staticClass: "title" }, [
          _vm._v(
            _vm._s(_vm.$t("__.Crud.historicoenviosdotz.list_vue_html.title"))
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "content" }, [
          _c("div", { staticClass: "info-panel" }, [
            _c("span", [_vm._v(_vm._s(_vm.total))]),
            _vm._v(" "),
            _c("span", [
              _vm._v(
                _vm._s(
                  _vm.$t("__.Crud.historicoenviosdotz.list_vue_html.enviados")
                )
              ),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "grid-component",
        {
          ref: "gridcomponent",
          attrs: {
            data: _vm.gridData,
            columns: _vm.gridColumns,
            controlaPageSize: true,
            extraActions: _vm.extraActions,
            showAddNewButton: false,
            showEditItem: false,
            showRemoveItem: false,
            "page-index": _vm.pageIndex,
            "page-size": _vm.pageSize,
            total: _vm.total,
          },
          on: {
            "change-filter-key": _vm.onChangeFilterKey,
            "change-sort": _vm.onChangeSort,
            "change-page": _vm.onChangePage,
            "change-page-size": _vm.onChangePageSize,
            "extra-action": _vm.onExtraAction,
            "gerar-xls": function ($event) {
              _vm.pags = true
              _vm.tipoDocGerar = 0
            },
            "gerar-pdf": function ($event) {
              _vm.pags = true
              _vm.tipoDocGerar = 1
            },
            "gerar-impressao": function ($event) {
              _vm.pags = true
              _vm.tipoDocGerar = 2
            },
          },
        },
        [
          _c("div", { attrs: { slot: "header" }, slot: "header" }, [
            _c("div", { staticClass: "filtro-lista" }, [
              _c(
                "button",
                {
                  staticClass: "filtro-avancado-button",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      _vm.showFilter = !_vm.showFilter
                    },
                  },
                },
                [
                  _vm._v(
                    "\n                    " +
                      _vm._s(
                        _vm.$t(
                          "__.Crud.historicoenviosdotz.list_vue_html.titulofiltroAvancado"
                        )
                      ) +
                      "\n                    "
                  ),
                  _c("i", { staticClass: "fa fa-caret-down" }),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.showFilter,
                      expression: "showFilter",
                    },
                  ],
                  staticClass: "drop-lista",
                },
                [
                  _c(
                    "form",
                    {
                      attrs: { novalidate: "novalidate" },
                      on: {
                        submit: function ($event) {
                          $event.preventDefault()
                          return _vm.reloadList($event)
                        },
                      },
                    },
                    [
                      _c("div", { staticStyle: { "margin-bottom": "15px" } }, [
                        _c(
                          "div",
                          [
                            _c(
                              "label",
                              {
                                staticClass: "control-label",
                                attrs: { for: "periodo" },
                              },
                              [_vm._v("Período de Envio")]
                            ),
                            _vm._v(" "),
                            _c("date-time-picker-component", {
                              staticStyle: { background: "white" },
                              attrs: {
                                id: "periodo",
                                name: "periodo",
                                onlyPickMonth: true,
                              },
                              model: {
                                value: _vm.periodo,
                                callback: function ($$v) {
                                  _vm.periodo = $$v
                                },
                                expression: "periodo",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          [
                            _c(
                              "label",
                              {
                                staticClass: "control-label",
                                attrs: { for: "prescritorId" },
                              },
                              [
                                _vm._v(
                                  "\n                                    " +
                                    _vm._s(
                                      _vm.$t(
                                        "__.Crud.historicoenviosdotz.list_vue_html.filtroPrescritor"
                                      )
                                    ) +
                                    "\n                                "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("combo-component", {
                              staticStyle: { background: "white" },
                              attrs: {
                                id: "prescritorId",
                                name: "prescritorId",
                                data: _vm.prescritoresOptions,
                                refresh: _vm.loadPrescritores,
                              },
                              model: {
                                value: _vm.prescritorId,
                                callback: function ($$v) {
                                  _vm.prescritorId = $$v
                                },
                                expression: "prescritorId",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          [
                            _c(
                              "label",
                              {
                                staticClass: "control-label",
                                attrs: { for: "statusEnvioDotz" },
                              },
                              [
                                _vm._v(
                                  "\n                                    " +
                                    _vm._s(
                                      _vm.$t(
                                        "__.Crud.historicoenviosdotz.list_vue_html.filtroStatusEnvio"
                                      )
                                    ) +
                                    "\n                                "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("combo-component", {
                              staticStyle: { background: "white" },
                              attrs: {
                                id: "statusEnvioDotz",
                                name: "statusEnvioDotz",
                                data: _vm.statusEnvioDotzOptions,
                              },
                              model: {
                                value: _vm.statusEnvioDotz,
                                callback: function ($$v) {
                                  _vm.statusEnvioDotz = $$v
                                },
                                expression: "statusEnvioDotz",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "form-group" }, [
                        _c(
                          "button",
                          {
                            staticClass: "botao-filtrar",
                            attrs: { type: "submit" },
                          },
                          [
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Crud.historicoenviosdotz.list_vue_html.btnFiltrar"
                                  )
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                      ]),
                    ]
                  ),
                ]
              ),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "grid-footer",
              staticStyle: { display: "flex", "justify-content": "start" },
              attrs: { slot: "footer-extra-options" },
              slot: "footer-extra-options",
            },
            [
              _c("div", [
                _vm._v(
                  "Total de pontos da página: " +
                    _vm._s(this.pontuacaoDotzTotal)
                ),
              ]),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _c("vendasPontuadasListComponent", {
        ref: "vendasPontuadasListComponent",
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }