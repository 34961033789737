import BaseModel from "./base/baseModel";
import TiposCalculoEmbalagem from "./enum/tiposCalculoEmbalagem";
import TiposCalculoManipulacao from "./enum/tiposCalculoManipulacao";
import TiposCapsula from "./enum/tiposCapsula";
import TiposFormaFarmaceutica from "./enum/tiposFormaFarmaceutica";
import TiposUso from "./enum/tiposUso";
import Usos from "./enum/usos";
import FormaFarmaceuticaItemPadraoModel from "./formaFarmaceuticaItemPadraoModel";

export default class FormaFarmaceuticaModel extends BaseModel {
    id?: number = null;
    franquiaId?: number = null;
    descricao: string = null;
    validadeDias = 0;
    descricaoRotulo: string = null;
    procedimentoOperacionalPadrao: string = null;
    ncmSh: string = null;
    valorMinimo = 0;
    valorAdicional = 0;
    uso: Usos = Usos.Interno;
    tipoUso: TiposUso = TiposUso.Sistemico;
    tipoFormaFarmaceutica: TiposFormaFarmaceutica = TiposFormaFarmaceutica.Capsula;
    tipoCalculoEmbalagem: TiposCalculoEmbalagem = TiposCalculoEmbalagem.Total;
    tipoCalculo?: TiposCalculoManipulacao = null;
    quantidadeMinima?: number = null;
    quantidadeMaxima?: number = null;
    //FatorMultiplicadorAdicional eh o fator minimo
    fatorMultiplicadorAdicional?: number = null;
    //auxilio na curva do preco
    fatorDecaimento?: number = null;
    //o fator de curva x quantidade sera responsavel por diminuir o fator de compensacao que se maior q zero eh somado ao fator multiplicador
    fatorCompensacao?: number = null;
    unidadeMedidaVolumeId?: number = null;
    qtdItensPadroes?: number = null;
    itensPadroes: Array<FormaFarmaceuticaItemPadraoModel> = [];
    utilizaMolde = false;
    desconsiderarDensidade = false;
    concentracaoMaxima?: number = null;
    formaFarmaceuticaPaiId?: number = null;
    //usado no kitembalagem apenas
    priorizar = false;
    ocultarExcipienteRotulo = false;
    ocultarFlavorizanteRotulo = false;
    exibirPrefixoDoseRotulo = false;
    ativo = true;
    tipoCapsula: TiposCapsula = null;
    configuracaoRotuloId?: number = null;
    exibirDescricaoRotulo = false;
    concentracaoMaximaFitoterapico?: number = null;
}

export interface FormaFarmaceuticaListParameters {
    somenteAtivos?: boolean;
}
