import Vue from "vue";

import moment from "moment";

import actionBarComponent from "@/components/child/actionBar/actionBar.vue";
import checkboxComponent from "@/components/child/form/checkbox.vue";
import dateTimePickerComponent from "@/components/child/form/datetimepicker.vue";
import fieldsetComponent from "@/components/child/form/fieldset.vue";
import { Component, Watch } from "@/decorators";
import SiproquimService from "@/services/siproquimService";
import { convertToDateInputString } from "@/utils/common/date";

import "../crud/crud.scss";

@Component({
    components: {
        checkboxComponent,
        fieldsetComponent,
        dateTimePickerComponent,
        actionBarComponent,
    },
})
export default class SiproquimEditComponent extends Vue {
    private service = new SiproquimService();

    dataInicial = new Date();
    dataFinal = new Date();
    geraCadastroProdutos = false;

    private load() {
        this.dataInicial = moment(new Date()).subtract(1, "months").toDate();
        this.dataInicial.setDate(1);
    }

    @Watch("dataInicial")
    private onChangeDataInicial() {
        this.dataFinal = moment(this.dataInicial).add(1, "months").toDate();
        this.dataFinal.setDate(0);
    }

    private async save() {
        try {
            await this.service.geraTXT({
                dataInicial: convertToDateInputString(this.dataInicial),
                dataFinal: convertToDateInputString(this.dataFinal),
                geraCadastroProdutos: this.geraCadastroProdutos,
            });
            await this.$showSuccess("Sucesso", "Arquivo foi gerado corretamente.");
        } catch (e) {
            await this.$showError(
                "Erro",
                `Erro ao gerar o arquivo. Verique se os produtos estão cadastrados corretamente.\n Erro: ${e.message}`,
            );
        }
    }

    private cancel() {
        this.$router.back();
    }

    private async mounted() {
        this.load();
    }
}
