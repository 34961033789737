import nfetch from "../../utils/events/nfetch";

export default class MovimentacaoCaixaService {
    controllerName = "MovimentacoesCaixaAberto";
    //por enquanto nao tem muitos filtros, mas a ideia eh deixar usuario filtrar e separar por todos os tipos
    public list(
        filterKey: string,
        sortKey: string,
        sortOrder: string,
        pageIndex: number,
        pageSize: number,
        contaCorrenteIdFiltro?: number,
        filtroBtn?: number,
    ): Promise<Response> {
        return nfetch(
            `/MovimentacoesCaixaAberto/List?filterKey=${filterKey}&sortKey=${sortKey}&sortOrder=${sortOrder}&pageIndex=${pageIndex}&pageSize=${pageSize}&contaCorrenteIdFiltro=${contaCorrenteIdFiltro}&filtroBtn=${filtroBtn}`,
            {
                credentials: "same-origin",
            },
        );
    }

    //busca Saldo Inicial da abertura de caixa
    public getSaldoInicial(): Promise<Response> {
        return nfetch(`/${this.controllerName}/GetSaldoInicial`, {
            credentials: "same-origin",
        });
    }

    //busca id do caixa da sessao aberta atual
    public getIdCaixaSessaoAberta(): Promise<Response> {
        return nfetch(`/${this.controllerName}/GetIdCaixaSessaoAberta`, {
            credentials: "same-origin",
        });
    }

    //busca descricao do caixa da sessao aberta atual
    public getDescricaoCaixaSessaoAberta(): Promise<Response> {
        return nfetch(`/${this.controllerName}/GetDescricaoCaixaSessaoAberta`, {
            credentials: "same-origin",
        });
    }
    //InsertReforco(decimal valor, long formaRecebimentoId, long contaCorrenteId,string descricao = null)
    public insertReforco(
        valor: number,
        formaRecebimentoId: number,
        contaCorrenteId,
        descricao: string,
    ): Promise<Response> {
        return nfetch(
            `/${this.controllerName}/InsertReforco?valor=${valor}&formaRecebimentoId=${formaRecebimentoId}&contaCorrenteId=${contaCorrenteId}&descricao=${descricao}`,
            {
                credentials: "same-origin",
            },
        );
    }

    public insertSangria(
        valor: number,
        formaRecebimentoId: number,
        contaCorrenteId,
        descricao: string,
    ): Promise<Response> {
        return nfetch(
            `/${this.controllerName}/InsertSangria?valor=${valor}&formaRecebimentoId=${formaRecebimentoId}&contaCorrenteId=${contaCorrenteId}&descricao=${descricao}`,
            {
                credentials: "same-origin",
            },
        );
    }

    //retorna o Id da sessao de caixa aberta
    public getIdSessaoAberta(): Promise<Response> {
        return nfetch(`/${this.controllerName}/GetIdSessaoAberta`, {
            credentials: "same-origin",
        });
    }
}
