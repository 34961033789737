var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "crud crud-list" },
    [
      _c("div", { staticClass: "info-div" }, [
        _c("h1", { staticClass: "title" }, [
          _vm._v(
            "\n            " +
              _vm._s(
                _vm.$t(
                  "__.Components.parent.atualizacaoValores.formaFarmaceutica.atualizacaoValoresProdutos"
                )
              ) +
              "\n        "
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "content" }, [
          _c("div", { staticClass: "info-panel" }, [
            _c("span", [_vm._v(_vm._s(_vm.total))]),
            _vm._v(" "),
            _c("span", [
              _vm._v(
                _vm._s(
                  _vm.$t(
                    "__.Components.parent.atualizacaoValores.formaFarmaceutica.encontrados"
                  )
                )
              ),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "grid-component",
        {
          attrs: {
            data: _vm.gridData,
            columns: _vm.gridColumns,
            showSelectCheckbox: true,
            showActionColumn: false,
            showAddNewButton: false,
            showSearchField: false,
            showSortIcons: false,
            showTools: false,
          },
          on: { "selected-values-changed": _vm.onSelectedValuesChanged },
        },
        [
          _c("div", { attrs: { slot: "header" }, slot: "header" }, [
            _c("div", { staticClass: "atualizacao-container" }, [
              _c("div", { staticClass: "atualizacao-card" }, [
                _c(
                  "label",
                  { staticClass: "control-label", attrs: { for: "valor" } },
                  [
                    _vm._v(
                      "\n                        " +
                        _vm._s(
                          _vm.$t(
                            "__.Components.parent.atualizacaoValores.formaFarmaceutica.percentualValorMinimo"
                          )
                        ) +
                        "\n                    "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.percentualValorMinimo,
                      expression: "percentualValorMinimo",
                    },
                  ],
                  ref: "percentualValorMinimo",
                  staticClass: "form-control",
                  attrs: {
                    id: "percentualValorMinimo",
                    name: "percentualValorMinimo",
                    type: "number",
                  },
                  domProps: { value: _vm.percentualValorMinimo },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.percentualValorMinimo = $event.target.value
                    },
                  },
                }),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "atualizacao-card" }, [
                _c(
                  "label",
                  { staticClass: "control-label", attrs: { for: "valor" } },
                  [
                    _vm._v(
                      "\n                        " +
                        _vm._s(
                          _vm.$t(
                            "__.Components.parent.atualizacaoValores.formaFarmaceutica.percentualValorAdicional"
                          )
                        ) +
                        "\n                    "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.percentualValorAdicional,
                      expression: "percentualValorAdicional",
                    },
                  ],
                  ref: "percentualValorAdicional",
                  staticClass: "form-control",
                  attrs: {
                    id: "percentualValorAdicional",
                    name: "percentualValorAdicional",
                    type: "number",
                  },
                  domProps: { value: _vm.percentualValorAdicional },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.percentualValorAdicional = $event.target.value
                    },
                  },
                }),
              ]),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-primary atualizacao-card",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      _vm.onAtualizarValores()
                    },
                  },
                },
                [
                  _vm._v(
                    "\n                    " +
                      _vm._s(
                        _vm.$t(
                          "__.Components.parent.atualizacaoValores.atualizar"
                        )
                      ) +
                      "\n                "
                  ),
                ]
              ),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }