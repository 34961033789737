var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "label",
    {
      class: [
        "file-picker-component",
        _vm.disabled ? "file-picker-disabled" : "",
      ],
    },
    [
      _c("div", { staticClass: "select-button" }, [
        _vm.value
          ? _c("span", [
              _vm._v(
                "\n            " +
                  _vm._s(
                    _vm.$t(
                      "__.Components.child.form.filepicker_vue_html.arqSelecionado"
                    )
                  ) +
                  " " +
                  _vm._s(_vm.value.name) +
                  "\n        "
              ),
            ])
          : _c("span", [
              _vm._v(
                _vm._s(
                  _vm.$t(
                    "__.Components.child.form.filepicker_vue_html.cliqueParaSelecArq"
                  )
                )
              ),
            ]),
      ]),
      _vm._v(" "),
      _c("input", {
        attrs: {
          type: "file",
          id: _vm.id,
          name: _vm.name,
          disabled: _vm.disabled,
        },
        on: { change: _vm.onFileChanged },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }