import Vue from "vue";
import { mapState } from "vuex";

import actionBarComponent from "@/components/child/actionBar/actionBar.vue";
import buttonIncluirComponent from "@/components/child/form/button/buttonIncluir.vue";
import cepFieldComponent from "@/components/child/form/cepfield.vue";
import checkboxComponent from "@/components/child/form/checkbox.vue";
import cnpjComponent from "@/components/child/form/cnpj.vue";
import comboComponent from "@/components/child/form/combo.vue";
import cpfComponent from "@/components/child/form/cpf.vue";
import dataTooltipComponent from "@/components/child/form/datatooltip.vue";
import dateTimePickerComponent from "@/components/child/form/datetimepicker.vue";
import decimalComponent from "@/components/child/form/decimal.vue";
import fieldsetComponent from "@/components/child/form/fieldset.vue";
import textareaComponent from "@/components/child/form/textarea.vue";
import gridComponent from "@/components/child/grid/grid.vue";
import { GridColumn, GridColumnType } from "@/components/child/grid/gridColumn";
import searchComboComponent from "@/components/child/searchCombo/search.vue";
import { Component, Prop } from "@/decorators";
import PreLoadPackModel from "@/models/auxiliar/preLoadPackModel";
import CepModel from "@/models/cepModel";
import ClinicaModel from "@/models/clinica/clinicaModel";
import ClinicaPrescritorModel from "@/models/clinica/clinicaPrescritorModel";
import EnumExtensions from "@/models/enum/extensions/enumExtensions";
import GenerosPessoa from "@/models/enum/generosPessoa";
import TiposPessoa from "@/models/enum/tiposPessoa";
import Ufs from "@/models/enum/ufs";
import { TypeElement } from "@/models/modeloImpressao/modeloImpressaoSessaoModel";
import PrescritorModel from "@/models/prescritorModel";
import ValidationErrorModel from "@/models/validationErrorModel";
import ClinicaService from "@/services/clinicaService";
import CidadeService from "@/services/external/cidadeService";
import PrescritorService from "@/services/prescritorService";
import { AppState } from "@/store/store";
import { getPrescritorCombo } from "@/utils/common/combo/combotext";
import Print from "@/utils/common/printForm";
import ValidationErrorWrapper from "@/wrappers/validationErrorWrapper";

import PrescritorEditComponent from "../prescritor/edit";
import prescritorComponent from "../prescritor/edit.vue";

import "../crud.scss";

@Component({
    components: {
        textareaComponent,
        fieldsetComponent,
        comboComponent,
        searchComboComponent,
        checkboxComponent,
        cpfComponent,
        cnpjComponent,
        decimalComponent,
        cepFieldComponent,
        dateTimePickerComponent,
        dataTooltipComponent,
        gridComponent,
        prescritorComponent,
        buttonIncluirComponent,
        actionBarComponent,
    },
    computed: mapState({
        ...mapState<AppState>({
            preLoadPack: state => state.preLoad.preLoadList,
        }),
    }),
})
export default class ClinicaEditComponent extends Vue {
    private clinicaService = new ClinicaService();
    private cidadeService = new CidadeService();
    private validationErrorWrapper = new ValidationErrorWrapper(this.$validator);
    private prescritorComponent: PrescritorEditComponent = null;

    private model: ClinicaModel = new ClinicaModel();
    private modelId = 0;

    private preLoadPack: PreLoadPackModel;

    @Prop({ type: Boolean, default: false }) CalledByShortCut: boolean;
    @Prop({ type: Boolean, default: false }) isModal: boolean;

    private hideActionBar = false;

    tiposPessoa = TiposPessoa;
    tiposPessoaOptions: Array<Object> = [
        { text: "Física", value: TiposPessoa.Fisica },
        { text: "Jurídica", value: TiposPessoa.Juridica },
    ];

    generosPessoaOptions: Array<Object> = [
        { text: "Masculino", value: GenerosPessoa.Masculino },
        { text: "Feminino", value: GenerosPessoa.Feminino },
    ];

    get gridColumns(): Array<GridColumn> {
        return [new GridColumn("prescritorNome", "Nome", GridColumnType.String)];
    }

    dadosCep: CepModel = new CepModel();
    ufOptions: Array<Object> = EnumExtensions.getNamesAndValuesOrderedByNames(Ufs);
    cidadesOptions: Array<Object> = [];

    private customComboTextPrescritor = getPrescritorCombo;

    constructor() {
        super();
    }

    public async load() {
        this.model = new ClinicaModel();
        this.validationErrorWrapper.clearErrors();

        if (this.modelId) {
            try {
                const clinica = await this.clinicaService
                    .get(this.modelId)
                    .withLoading()
                    .resolveWithJSON<ClinicaModel>();
                this.model.updateFrom(clinica);
                await this.onLoadCidades(this.model.estadoId);
            } catch {}
        }
    }

    public async save() {
        try {
            if (await this.$validator.validateAll()) {
                if (this.model.cidadeId) {
                    this.model.cidade = this.cidadesOptions.filter(item => item["value"] == this.model.cidadeId)[0][
                        "text"
                    ];
                }

                const response = await this.clinicaService[!this.model.id ? "insert" : "update"](this.model)
                    .withLoading()
                    .resolveWithResponse();

                if (response.ok) {
                    await this.$showInclusaoUpdate(this.model.id);
                    if (this.CalledByShortCut) {
                        this.$emit("save-ok", this.model);
                    } else {
                        this.$router.back();
                    }
                } else {
                    return response.json() as Promise<ValidationErrorModel[]>;
                }
            } else {
                this.$focusErrorField();
            }
        } catch {}
    }

    private cancel() {
        if (this.CalledByShortCut) {
            this.model = new ClinicaModel();

            this.validationErrorWrapper.clearErrors();
        }
        this.$router.back();
    }

    private async onCepChanged(data: CepModel) {
        this.dadosCep = data;
        this.model.bairro = data.bairro;
        this.model.complemento = data.complemento;
        this.model.endereco = data.endereco;
        this.model.estadoId = data.uf;
        await this.onLoadCidades(this.model.estadoId);
        this.model.cidadeId = data.cidadeId;
    }

    private onCepInvalid() {
        this.$showInfo("Pesquisa por CEP", "A pesquisa não encontrou resultados");
    }

    private cadastrarNovoPrescritor() {
        this.prescritorComponent = this.$refs.prescritorComponent as PrescritorEditComponent;
        this.prescritorComponent.save();
    }

    private async incluirClinicaPrescritor() {
        const component = this.$el.querySelector("#prescritorId") as HTMLSelectElement;

        if (this.model.prescritorId > 0 && !this.model.prescritores.some(item => item.id == this.model.prescritorId)) {
            const prescritor = await new PrescritorService()
                .get(this.model.prescritorId)
                .resolveWithJSON<PrescritorModel>();

            if (prescritor.clinicas.some(c => c.clinicaId)) {
                this.$showError(
                    this.$t("__.ts.erro"),
                    this.$t("__.Crud.clinicas.edit_vue_html.prescritorOutraClinica"),
                );
            } else if (this.model.prescritores.some(p => p.prescritorId == prescritor.id)) {
                this.$showError(this.$t("__.ts.erro"), this.$t("__.Crud.clinicas.edit_vue_html.prescritorJaInserido"));
            } else {
                const prescritorClinica = new ClinicaPrescritorModel();
                prescritorClinica.prescritorId = this.model.prescritorId;
                prescritorClinica.clinicaId = this.model.id ? this.model.id : null;
                prescritorClinica.prescritorNome = component.innerText;
                this.model.prescritores.push(prescritorClinica);

                component.value = null;
                this.model.prescritorId = null;
            }
        }
    }

    private async onAddPrescritorOk(modelPrescritor: PrescritorModel) {
        this.model.prescritorId = modelPrescritor.id;
    }

    private removerPrescritor(model: ClinicaPrescritorModel) {
        this.model.prescritores.splice(this.model.prescritores.indexOf(model), 1);
    }

    private async onLoadCidades(estadoId: number) {
        try {
            this.cidadesOptions = [];
            if (estadoId) {
                this.cidadesOptions = await this.cidadeService.get(estadoId).withLoading();
            }
        } catch {}
    }

    private mounted() {
        this.hideActionBar = this.CalledByShortCut ? true : false;

        if (this.$route.params.id && !this.hideActionBar) {
            this.modelId = +this.$route.params.id;

            this.load();
        }
        if (this.CalledByShortCut) {
            this.model = new ClinicaModel();
            this.validationErrorWrapper.clearErrors();
        }
    }

    print() {
        new Print().print(
            [
                {
                    elementId: "clinica",
                    title: "clinica",
                    type: TypeElement.Form,
                },
                {
                    elementId: "localizacao",
                    title: "Localização",
                    type: TypeElement.Form,
                },
                {
                    elementId: "entrega",
                    title: "Endereço de Entrega",
                    type: TypeElement.Form,
                },
                {
                    elementId: "contato",
                    title: "Contato",
                    type: TypeElement.Form,
                },
            ],
            3,
        );
    }
}
