var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "crud crud-list" },
    [
      _c("div", { staticClass: "info-div" }, [
        _c("h1", { staticClass: "title" }, [
          _vm._v(
            _vm._s(_vm.$t("__.Crud.produtolote.list_vue_html.lotesProduto"))
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "content" }, [
          _c("div", { staticClass: "info-panel" }, [
            _c("span", [_vm._v(_vm._s(_vm.total))]),
            _vm._v(" "),
            _c("span", [
              _vm._v(
                _vm._s(_vm.$t("__.Crud.produtolote.list_vue_html.lotesProduto"))
              ),
            ]),
            _vm._v(" "),
            _c("span", [
              _vm._v(
                _vm._s(_vm.$t("__.Crud.produtolote.list_vue_html.cadastrados"))
              ),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "grid-component",
        {
          ref: "gridcomponent",
          attrs: {
            data: _vm.gridData,
            tituloPagina: "Lotes de Produto",
            columns: _vm.gridColumns,
            "page-index": _vm.pageIndex,
            "page-size": _vm.pageSize,
            total: _vm.total,
            startFilterKey: _vm.startFilterKey,
            extraActions: _vm.extraActions,
            controlaPageSize: true,
            actionName: "lotes",
          },
          on: {
            "change-filter-key": _vm.onChangeFilterKey,
            "change-page-size": _vm.onChangePageSize,
            "change-page": _vm.onChangePage,
            "change-sort": _vm.onChangeSort,
            "create-item": _vm.onCreateItem,
            "edit-item": _vm.onEditItem,
            "extra-action": _vm.onExtraAction,
            "gerar-impressao": function ($event) {
              _vm.pags = true
              _vm.tipoDocGerar = 2
            },
            "gerar-pdf": function ($event) {
              _vm.pags = true
              _vm.tipoDocGerar = 1
            },
            "gerar-xls": function ($event) {
              _vm.pags = true
              _vm.tipoDocGerar = 0
            },
            "remove-item": _vm.onRemoveItem,
          },
        },
        [
          _c("div", { attrs: { slot: "header" }, slot: "header" }, [
            _c("div", { staticClass: "filtro-lista" }, [
              _c(
                "button",
                {
                  staticClass: "filtro-avancado-button",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      _vm.isHidden = !_vm.isHidden
                    },
                  },
                },
                [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.$t("__.Crud.filtroAvancado")) +
                      "\n                    "
                  ),
                  _c("i", { staticClass: "fa fa-caret-down" }),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isHidden,
                      expression: "isHidden",
                    },
                  ],
                  staticClass: "drop-lista",
                },
                [
                  _c(
                    "form",
                    {
                      attrs: { novalidate: "novalidate" },
                      on: {
                        submit: function ($event) {
                          $event.preventDefault()
                          return _vm.getFormValues($event)
                        },
                      },
                    },
                    [
                      _vm.isFracionamento
                        ? _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col-sm-12" }, [
                              _c(
                                "div",
                                { staticClass: "form-group" },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "control-label",
                                      attrs: { for: "statusProdutoLote" },
                                    },
                                    [
                                      _vm._v(
                                        "\n                                        " +
                                          _vm._s(
                                            _vm.$t(
                                              "__.Crud.produtolote.list_vue_html.statusProdutoLote"
                                            )
                                          ) +
                                          "\n                                    "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("combo-component", {
                                    staticStyle: { background: "white" },
                                    attrs: {
                                      id: "statusProdutoLote",
                                      name: "statusProdutoLote",
                                      data: _vm.statusProdutoLoteOptions,
                                    },
                                    model: {
                                      value: _vm.statusProdutoLote,
                                      callback: function ($$v) {
                                        _vm.statusProdutoLote = $$v
                                      },
                                      expression: "statusProdutoLote",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.isFracionamento
                        ? _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col-sm-12" }, [
                              _c(
                                "div",
                                { staticClass: "form-group" },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "control-label",
                                      attrs: { for: "origemProdutoLote" },
                                    },
                                    [
                                      _vm._v(
                                        "\n                                        " +
                                          _vm._s(
                                            _vm.$t(
                                              "__.Crud.produtolote.list_vue_html.origemProdutoLote"
                                            )
                                          ) +
                                          "\n                                    "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("combo-component", {
                                    staticStyle: { background: "white" },
                                    attrs: {
                                      id: "origemProdutoLote",
                                      name: "origemProdutoLote",
                                      data: _vm.origemProdutoLoteOptions,
                                    },
                                    model: {
                                      value: _vm.origemProdutoLote,
                                      callback: function ($$v) {
                                        _vm.origemProdutoLote = $$v
                                      },
                                      expression: "origemProdutoLote",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.isFracionamento || _vm.isEstereis
                        ? _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col-sm-12" }, [
                              _c(
                                "div",
                                { staticClass: "form-group" },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "control-label",
                                      attrs: { for: "tipoEstoque" },
                                    },
                                    [
                                      _vm._v(
                                        "\n                                        " +
                                          _vm._s(
                                            _vm.$t(
                                              "__.Crud.produtolote.list_vue_html.tipoEstoque"
                                            )
                                          ) +
                                          "\n                                    "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("combo-component", {
                                    staticStyle: { background: "white" },
                                    attrs: {
                                      id: "tipoEstoque",
                                      name: "tipoEstoque",
                                      data: _vm.estoqueOptions,
                                    },
                                    model: {
                                      value: _vm.estoqueId,
                                      callback: function ($$v) {
                                        _vm.estoqueId = $$v
                                      },
                                      expression: "estoqueId",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-sm-12" }, [
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "control-label",
                                  attrs: { for: "ignorarZerados" },
                                },
                                [
                                  _vm._v(
                                    "\n                                        " +
                                      _vm._s(
                                        _vm.$t(
                                          "__.Crud.produtolote.list_vue_html.ignorarZerados"
                                        )
                                      ) +
                                      "\n                                    "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("checkbox-component", {
                                staticClass: "checkbox",
                                attrs: {
                                  id: "ignorarZerados",
                                  name: "ignorarZerados",
                                },
                                model: {
                                  value: _vm.ignorarZerados,
                                  callback: function ($$v) {
                                    _vm.ignorarZerados = $$v
                                  },
                                  expression: "ignorarZerados",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "form-group" }, [
                        _c(
                          "button",
                          {
                            staticClass: "botao-filtrar",
                            attrs: { type: "submit" },
                          },
                          [
                            _vm._v(
                              "\n                                " +
                                _vm._s(_vm.$t("__.Crud.filtrar")) +
                                "\n                            "
                            ),
                          ]
                        ),
                      ]),
                    ]
                  ),
                ]
              ),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _c("impressaoComponent", { ref: "impressaoComponent" }),
      _vm._v(" "),
      _c("atualizarStatusComponent", {
        ref: "atualizarStatusComponent",
        on: { onStatusSucesso: _vm.load },
      }),
      _vm._v(" "),
      _c("fracionamentoComponent", {
        ref: "fracionamentoComponent",
        on: { onSucessoFracionamento: _vm.load },
      }),
      _vm._v(" "),
      _c("dadosNotaEntradaLoteComponent", {
        ref: "dadosNotaEntradaLoteComponent",
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }