import Vue from "vue";

import fieldsetComponent from "@/components/child/form/fieldset.vue";
import { Component } from "@/decorators";
import CapaSeiModel from "@/models/capaSeiModel";
import LocalStorageService from "@/services/localStorage/localStorageService";

@Component({
    components: {
        fieldsetComponent,
    },
})
export default class CapaSeiComponent extends Vue {
    private service = new LocalStorageService();
    private model = new CapaSeiModel();
    private storageKey = "capa-sei";

    public get() {
        this.service.saveByKey(this.model, this.storageKey);

        return this.model;
    }

    private async mounted() {
        const storage = this.service.getByKey<CapaSeiModel>(this.storageKey);
        if (storage) {
            this.model = storage;
        }
    }
}
