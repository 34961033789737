import Vue from "vue";

import actionBarComponent from "@/components/child/actionBar/actionBar.vue";
import buttonIncluirComponent from "@/components/child/form/button/buttonIncluir.vue";
import checkboxComponent from "@/components/child/form/checkbox.vue";
import dataTooltipComponent from "@/components/child/form/datatooltip.vue";
import decimalComSinalCustomComponent from "@/components/child/form/decimalComSinalCustom.vue";
import fieldsetComponent from "@/components/child/form/fieldset.vue";
import moedaComponent from "@/components/child/form/moeda.vue";
import gridComponent from "@/components/child/grid/grid.vue";
import { GridColumn, GridColumnType } from "@/components/child/grid/gridColumn";
import LoadingModalComponent from "@/components/child/loadingmodal/loadingmodal";
import loadingModalComponent from "@/components/child/loadingmodal/loadingmodal.vue";
import { Component } from "@/decorators";
import BeneficioFaixaModel from "@/models/beneficio/beneficioFaixaModel";
import BeneficioModel from "@/models/beneficio/beneficioModel";
import TipoBeneficio from "@/models/enum/tipoBeneficio";
import ValidationErrorModel from "@/models/validationErrorModel";
import BeneficioService from "@/services/beneficioService";
import ValidationErrorWrapper from "@/wrappers/validationErrorWrapper";

import "../crud.scss";

@Component({
    components: {
        checkboxComponent,
        dataTooltipComponent,
        loadingModalComponent,
        gridComponent,
        decimalComSinalCustomComponent,
        moedaComponent,
        fieldsetComponent,
        buttonIncluirComponent,
        actionBarComponent,
    },
})
export default class BeneficiosEditComponent extends Vue {
    private service = new BeneficioService();
    private validationErrorWrapper = new ValidationErrorWrapper(this.$validator);
    private loadingModalComponent: LoadingModalComponent = null;

    gridFaixaColums: Array<GridColumn> = [
        new GridColumn("inicioFaixa", "Faixa Inicio", GridColumnType.Money),
        new GridColumn("fimFaixa", "Faixa Fim", GridColumnType.Money),
        new GridColumn("valor", "Valor", GridColumnType.Integer),
    ];

    model: BeneficioModel = new BeneficioModel();
    faixaDesconto = new BeneficioFaixaModel(TipoBeneficio.Desconto);
    faixaComissao = new BeneficioFaixaModel();
    modelId = 0;

    constructor() {
        super();
    }

    private load() {
        this.model = new BeneficioModel();
        this.validationErrorWrapper.clearErrors();

        if (this.modelId > 0) {
            this.loadingModalComponent.showLoading();

            this.service
                .get(this.modelId)
                .then(response => {
                    if (response.ok) return response.json() as Promise<BeneficioModel>;
                })
                .then(data => {
                    this.model.updateFrom(data);
                    this.loadingModalComponent.hide();
                })
                .catch(error => {
                    this.$router.back();
                    this.loadingModalComponent.hide();
                });
        }
    }

    private save() {
        this.$validator
            .validateAll()
            .then(isValid => {
                if (isValid) {
                    this.loadingModalComponent.showSaving();

                    this.service[!this.model.id ? "insert" : "update"](this.model)
                        .then(async response => {
                            this.loadingModalComponent.hide();

                            if (response.ok) {
                                await this.$showInclusaoUpdate(this.model.id);
                                this.$router.back();
                            } else {
                                return response.json() as Promise<ValidationErrorModel[]>;
                            }
                        })
                        .then(data => {
                            this.validationErrorWrapper.showErrors(data);
                        })
                        .catch(error => {
                            this.loadingModalComponent.hide();
                        });
                }
            })
            .catch(error => {});
    }

    private async onAddFaixa(tipoFaixa: TipoBeneficio) {
        if (
            this.faixaComissao.inicioFaixa < this.faixaComissao.fimFaixa ||
            this.faixaDesconto.inicioFaixa < this.faixaDesconto.fimFaixa
        ) {
            const model = new BeneficioFaixaModel();
            if (tipoFaixa == TipoBeneficio.Comissao) {
                const results = Promise.all([]);
                const areValid = (await results).every(isValid => isValid);
                if (areValid) {
                    model.updateFrom(this.faixaComissao);
                    this.faixaComissao = new BeneficioFaixaModel();
                    this.model.beneficioFaixas.push(model);
                }
            } else {
                const results = Promise.all([]);
                const areValid = (await results).every(isValid => isValid);
                if (areValid) {
                    model.updateFrom(this.faixaDesconto);
                    this.faixaDesconto = new BeneficioFaixaModel(TipoBeneficio.Desconto);
                    this.model.beneficioFaixas.push(model);
                }
            }
        } else {
            this.$showWarning("Atenção", "Não e permitido faixa final menor ou igual a faixa inicial.");
        }
    }

    private onRemoveFaixa(item) {
        const index = this.model.beneficioFaixas.indexOf(item);
        this.model.beneficioFaixas.splice(index, 1);
    }

    private cancel() {
        this.$router.back();
    }

    private mounted() {
        this.loadingModalComponent = this.$refs.loadingModalComponent as LoadingModalComponent;

        if (this.$route.params.id) {
            this.modelId = +this.$route.params.id;
            this.load();
        }
    }
}
