import Vue from "vue";

import Grid from "@/components/child/grid/grid";
import gridComponent from "@/components/child/grid/grid.vue";
import { GridColumn, GridColumnType } from "@/components/child/grid/gridColumn";
import LoadingModalComponent from "@/components/child/loadingmodal/loadingmodal";
import loadingModalComponent from "@/components/child/loadingmodal/loadingmodal.vue";
import { Component, Watch } from "@/decorators";
import MotivoMovimentacaoEstoqueModel from "@/models/motivoMovimentacaoEstoque";
import PaginationModel from "@/models/paginationModel";
import MotivoMovimentacaoEstoqueService from "@/services/motivoMovimentacaoEstoque";

import "../crud.scss";

@Component({
    components: {
        gridComponent,
        loadingModalComponent,
    },
})
export default class MotivoMovimentacaoEstoqueListComponent extends Vue {
    private service = new MotivoMovimentacaoEstoqueService();
    private loadingModalComponent: LoadingModalComponent = null;
    private gridcomponent: Grid = null;

    gridFilterKey = "";
    gridSortKey = "descricao";
    gridSortOrder = "asc";
    gridData: Array<MotivoMovimentacaoEstoqueModel> = [];
    gridColumns: Array<GridColumn> = [
        new GridColumn("descricao", "Descrição", GridColumnType.String),
        new GridColumn("tipoMotivoDescricao", "Tipo motivo movimentação", GridColumnType.String),
    ];

    pageIndex = 1;
    pageSize = 20;
    total = 0;

    pags = false;
    tipoDocGerar: number = null;

    private async load() {
        this.loadingModalComponent.showLoading();

        try {
            const data = await this.service
                .list(
                    this.gridFilterKey,
                    this.gridSortKey,
                    this.gridSortOrder,
                    this.pags ? 1 : this.pageIndex,
                    this.pags ? 999999 : this.pageSize,
                )
                .then(r => r.json() as Promise<PaginationModel<MotivoMovimentacaoEstoqueModel>>);
            this.gridData = data.list;
            this.total = data.total;

            if (this.pags) {
                if (this.tipoDocGerar == 0) {
                    setTimeout(() => {
                        this.gridcomponent.gerarXls();
                    }, 2000);
                }

                if (this.tipoDocGerar == 1) {
                    setTimeout(() => {
                        this.gridcomponent.gerarPdf();
                    }, 2000);
                }

                if (this.tipoDocGerar == 2) {
                    setTimeout(() => {
                        this.gridcomponent.gerarImpressao();
                    }, 2000);
                }

                setTimeout(() => {
                    this.pags = false;
                }, 2000);
            }
        } catch {}

        this.loadingModalComponent.hide();
    }

    private onEditItem(model: MotivoMovimentacaoEstoqueModel) {
        this.$router.push("/motivomovimentacaoestoque-edicao/" + model.id);
    }

    private async onRemoveItem(model: MotivoMovimentacaoEstoqueModel) {
        const response = await this.$showExclusaoQuestion(`${this.$t("__.ts.desejaExcl")}${model.descricao}?`);

        if (response) {
            try {
                const sucesso = await this.service.delete(model.id).withLoading().resolveWithoutJSON();
                if (sucesso) {
                    this.load();
                    this.$showExclusao();
                }
            } catch {}
        }
    }

    private onCreateItem() {
        this.$router.push("/motivomovimentacaoestoque-edicao");
    }

    private onChangeFilterKey(filterKey: string) {
        this.gridFilterKey = filterKey;
        this.load();
    }

    private onChangeSort(sortKey: string, sortOrder: string) {
        this.gridSortKey = sortKey;
        this.gridSortOrder = sortOrder;
        this.load();
    }

    private onChangePage(pageIndex: number) {
        this.pageIndex = pageIndex;
        this.load();
    }

    @Watch("pags")
    private onPags() {
        this.load();
    }

    private mounted() {
        this.loadingModalComponent = this.$refs.loadingModalComponent as LoadingModalComponent;
        this.gridcomponent = this.$refs.gridcomponent as Grid;
        this.load();
    }
}
