import Vue from "vue";

import comboComponent from "@/components/child/form/combo.vue";
import dateTimePickerComponent from "@/components/child/form/datetimepicker.vue";
import moedaComponent from "@/components/child/form/moeda.vue";
import textareaComponent from "@/components/child/form/textarea.vue";
import gridComponent from "@/components/child/grid/grid.vue";
import { GridAction } from "@/components/child/grid/gridAction";
import { GridColors, GridColumn, GridColumnType } from "@/components/child/grid/gridColumn";
import { Component, Prop } from "@/decorators";
import Especie from "@/models/enum/especiePagamento";
import Bandeira from "@/models/financeiro/bandeiraModel";
import ContaCorrenteModel from "@/models/financeiro/contaCorrenteModel";
import ContaReceberRecebimentoModel from "@/models/financeiro/contaReceberRecebimentoModel";
import FormaRecebimentoModel from "@/models/financeiro/formaRecebimentoModel";
import MetodoPagemento from "@/models/financeiro/metodoPagamentoModel";
import PaginationModel from "@/models/paginationModel";
import BandeiraService from "@/services/financeiro/bandeiraService";
import ContaCorrenteService from "@/services/financeiro/contaCorrenteService";
import FormaRecebimentoService from "@/services/financeiro/formaRecebimentoService";
import MetodoPagementoService from "@/services/financeiro/metodoPagamentoService";
import Delay from "@/utils/common/delay";

import ShortcutComponent from "../shortcut/shortcut";
import shortcutComponent from "../shortcut/shortcut.vue";

@Component({
    components: {
        gridComponent,
        textareaComponent,
        shortcutComponent,
        comboComponent,
        moedaComponent,
        dateTimePickerComponent,
    },
})
export default class RecebimentoComponent extends Vue {
    private shortcutPagamento: ShortcutComponent = null;

    private model = new ContaReceberRecebimentoModel();

    @Prop({ type: Boolean, default: true }) permiteRemover: boolean;

    private gridData = [];

    private contaCorrenteOptions: Array<Object> = [];
    private formaRecebimentoOptions: Array<Object> = [];

    private extraActions: Array<GridAction[]> = [];
    private extraActionsVisualizar: Array<GridAction> = [];
    private extraActionsExcluir: Array<GridAction> = [];

    bandeira = -1;
    bandeiraOptions: Array<Object> = [];

    metodoParcelamentoId = -1;
    metodosPagamentosOptions: Array<Object> = [];

    especie = -1;
    especiesOptions: Array<Object> = [
        { text: "Dinheiro", value: Especie.Dinheiro },
        { text: "Cheque", value: Especie.Cheque },
        { text: "Boleto", value: Especie.Boleto },
        { text: "Cartão de Débito", value: Especie.CartaoDebito },
        { text: "Cartão de Crédito", value: Especie.CartaoCredito },
        { text: "Transferência", value: Especie.Transferencia },
        { text: "Pix", value: Especie.Pix },
        { text: "Convênio", value: Especie.Convenio },
        { text: "Transferência Bancária", value: Especie.TransferenciaBancaria },
        { text: "Outros", value: Especie.Outros },
    ];

    get gridColumnsRecebimentos(): Array<GridColumn> {
        return [
            new GridColumn("dataMovimento", this.$t("__.ts.recebimento") as string, GridColumnType.Date),
            new GridColumn("formaPagamentoDescricao", this.$t("__.ts.formPgto") as string, GridColumnType.String),
            new GridColumn("desconto", this.$t("__.ts.descont") as string, GridColumnType.Money),
            new GridColumn("juros", this.$t("__.ts.juros") as string, GridColumnType.Money),
            new GridColumn("valor", this.$t("__.ts.vlr") as string, GridColumnType.Money),
            new GridColumn("observacao", this.$t("__.ts.observacao") as string, GridColumnType.String),
        ];
    }

    private loadGridData() {
        this.extraActions.splice(0);
        this.extraActionsVisualizar.splice(0);
        this.extraActionsExcluir.splice(0);

        for (let i = 0; i < this.gridData.length; i++) {
            this.extraActionsVisualizar.push(new GridAction("consultar", "Consultar", "fa fa-search", GridColors.BLUE));

            if (this.permiteRemover) {
                this.extraActionsExcluir.push(
                    new GridAction("excluir-recebimento", "Excluir", "fa fa-times", GridColors.RED),
                );
            }
        }
        this.extraActions.push(this.extraActionsVisualizar);
        this.extraActions.push(this.extraActionsExcluir);
    }

    private onExtraActions(name: string, data: ContaReceberRecebimentoModel) {
        if (name.trim() == "excluir-recebimento") {
            this.onRemovePagamento(data);
        }
        if (name.trim() == "consultar") {
            this.showRecebimentos(data);
        }
    }

    private onRemovePagamento(model: object) {
        if (this.model) {
            this.gridData = this.gridData.filter(p => p != model);
            this.loadGridData();
        }
        this.$emit("remove-pagamento", model);
    }

    private async showRecebimentos(model: ContaReceberRecebimentoModel) {
        if (model) {
            this.model = model;
            while (!this.$refs.shortcutPagamento) await Delay(5);
            this.shortcutPagamento = this.$refs.shortcutPagamento as ShortcutComponent;

            this.especie = model.especie;
            this.metodoParcelamentoId = model.metodoPagamentoXForma?.metodoPagamentoId;

            this.shortcutPagamento.title = this.$t("__.ts.recebimento") as string;
            this.shortcutPagamento.show();
        }
    }

    public async show(model: object[]) {
        if (model.length > 0) {
            this.gridData = model;
            this.loadGridData();
        }
    }

    private async loadMetodoPagamentos() {
        try {
            const data = await new MetodoPagementoService().combo().resolveWithJSON<PaginationModel<MetodoPagemento>>();
            this.metodosPagamentosOptions = data.list.map(item => ({ value: item.id, text: item.descricao }));
        } catch {}
    }

    private async loadBandeiras() {
        try {
            const data = await new BandeiraService().combo().resolveWithJSON<PaginationModel<Bandeira>>();
            this.bandeiraOptions = data.list.map(item => ({ value: item.id, text: item.descricao }));
        } catch {}
    }

    private async loadContasCorrentes() {
        try {
            const data = await new ContaCorrenteService()
                .combo()
                .resolveWithJSON<PaginationModel<ContaCorrenteModel>>();

            this.contaCorrenteOptions = data.list.map(item => ({ value: item.id, text: item.descricao }));
        } catch {}
    }

    private async loadFormasRecebimento() {
        try {
            const data = await new FormaRecebimentoService()
                .comboByEspecie()
                .resolveWithJSON<PaginationModel<FormaRecebimentoModel>>();

            this.formaRecebimentoOptions = data.list.map(item => ({ value: item.id, text: item.descricao }));
        } catch {}
    }

    private mounted() {
        Promise.all([
            this.loadContasCorrentes(),
            this.loadFormasRecebimento(),
            this.loadBandeiras(),
            this.loadMetodoPagamentos(),
        ])
            .withLoading()
            .then(() => {});
    }
}
