import Vue from "vue";
import VueI18n from "vue-i18n";

type Text = string | VueI18n.TranslateResult;

declare module "vue/types/vue" {
    interface Vue {
        $focusField: (refName: string) => void;
        $focusErrorField: () => void;
        $setErrorField: (field: string, message: Text) => void;
    }
}

Vue.prototype.$focusField = function (refName: string) {
    const doc = this.$refs[refName]["$el"] as HTMLElement;

    if (doc) {
        if (doc.tagName.toLowerCase() == "input") {
            this.$nextTick(() => doc.focus());
        } else {
            const input = doc.getElementsByTagName("input");

            this.$nextTick(() => input[0].focus());
        }
        window.scrollTo(window.scrollX, window.scrollY - 150);
    }
};

Vue.prototype.$focusErrorField = function () {
    if (this.$validator.errors.items.length > 0) {
        let field = document.querySelector(`fieldset.fieldset-component #${this.$validator.errors.items[0].field}`);
        if (field) {
            let fieldSetChild = null;
            while (1) {
                if (field.parentElement.nodeName == "FIELDSET") {
                    fieldSetChild = field;
                    break;
                }
                if (!field.parentElement) break;
                field = field.parentElement;
            }
            if (fieldSetChild) {
                fieldSetChild.style.display = "block";
            }
        }

        document.getElementById(this.$validator.errors.items[0].field).scrollIntoView({
            behavior: "auto",
            block: "center",
            inline: "center",
        });
    }
};

Vue.prototype.$setErrorField = function (field: string, message: Text) {
    const element = document.getElementById(field);
    if (element) {
        const className = element.parentElement.getAttribute("class");

        const hasErros = className.indexOf("error") >= 0;

        if (hasErros && message == "") {
            this.$validator.errors.remove(field);
        }

        if (!hasErros && message != "") {
            this.$validator.errors.add({ field, msg: message });
        }
    }
};
