import BaseModel from "../base/baseModel";
import GenerosPessoa from "../enum/generosPessoa";
import TiposPessoa from "../enum/tiposPessoa";
import Ufs from "../enum/ufs";

export default class TransportadorModel extends BaseModel {
    constructor() {
        super();

        this.tipoPessoa = TiposPessoa.Fisica;
    }

    id?: number = null;
    tipoPessoa: TiposPessoa = null;
    tipoPessoaDescricao: string = null;
    nome: string = null;
    nomeFantasia: string = null;
    observacao: string = null;
    cpf: string = null;
    rg: string = null;
    orgaoExpedidor: string = null;
    ufExpedidor?: Ufs = null;
    ufExpedidorDescricao: string = null;
    nomeRotulo: string = null;
    dataNascimento?: Date = null;
    genero?: GenerosPessoa = null;
    generoDescricao: string = null;
    razaoSocial: string = null;
    cnpj: string = null;
    inscricaoEstadual: string = null;
    inscricaoMunicipal: string = null;
    cep: string = null;
    estadoDescricao: string = null;
    estadoId?: Ufs = null;
    cidadeId?: number = null;
    cidade: string = null;
    endereco: string = null;
    bairro: string = null;
    numero: string = null;
    complemento: string = null;
    telefone: string = null;
    celular: string = null;
    email: string = null;
    transportadoraId: number = null;
    transportadora: string = null;
}
