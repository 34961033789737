import Vue from "vue";
import { mapState, mapMutations, mapGetters } from "vuex";

import actionBarComponent from "@/components/child/actionBar/actionBar.vue";
import checkboxComponent from "@/components/child/form/checkbox.vue";
import comboComponent from "@/components/child/form/combo.vue";
import decimalComponent from "@/components/child/form/decimal.vue";
import fieldsetComponent from "@/components/child/form/fieldset.vue";
import moedaComponent from "@/components/child/form/moeda.vue";
import textareaComponent from "@/components/child/form/textarea.vue";
import gridComponent from "@/components/child/grid/grid.vue";
import { GridColumn, GridColumnType } from "@/components/child/grid/gridColumn";
import { Component, Watch } from "@/decorators";
import PreLoadPackModel from "@/models/auxiliar/preLoadPackModel";
import OperacaoMatematica from "@/models/enum/operacaoMatematica";
import TiposCalculoManipulacao from "@/models/enum/tiposCalculoManipulacao";
import TiposFormaFarmaceutica from "@/models/enum/tiposFormaFarmaceutica";
import TiposFormulaPadrao from "@/models/enum/tiposFormulaPadrao";
import TiposItemManipulacao from "@/models/enum/tiposItemManipulacao";
import TiposProduto from "@/models/enum/tiposProduto";
import FormulaPadraoItemModel from "@/models/formulaPadraoItemModel";
import FormulaPadraoModel from "@/models/formulaPadraoModel";
import FranquiaModel from "@/models/franquiaModel";
import PaginationModel from "@/models/paginationModel";
import PosologiaModel from "@/models/posologiaModel";
import ProdutoModel, { ProdutoListParameters } from "@/models/produto/produtoModel";
import UnidadeMedidaModel from "@/models/unidadeMedidaModel";
import FormulaPadraoService from "@/services/formulaPadraoService";
import FranquiaService from "@/services/franquiaService";
import PDFService from "@/services/pdf/PDFService";
import PosologiaService from "@/services/posologiaService";
import ProdutoService from "@/services/produto/produtoService";
import UnidadeMedidaService from "@/services/unidadeMedidaService";
import { AppState, Getters, Mutations } from "@/store/store";
import arithmeticHelper from "@/utils/common/arithmeticHelper";
import {
    getFormaFarmaceuticaCombo,
    getPosologiaCombo,
    getProdutoCombo,
    getUnidadeMedidaCombo,
    getKitEmbalagemCombo,
} from "@/utils/common/combo/combotext";
import Delay from "@/utils/common/delay";

import SolicitarSenhaComponent from "../../solicitarSenha/view";
import solicitarSenhaComponent from "../../solicitarSenha/view.vue";
import PosologiaComponent from "../posologia/edit";
import posologiaComponent from "../posologia/edit.vue";
import ReplicarSelecaoFranquiaComponent from "../replicarSelecaoFranquia/edit";
import replicarSelecaoFranquiaComponent from "../replicarSelecaoFranquia/edit.vue";

import FormulaPadraoItemComponent from "./item";
import formulaPadraoItemComponent from "./item.vue";

import "../crud.scss";

@Component({
    components: {
        textareaComponent,
        checkboxComponent,
        comboComponent,
        decimalComponent,
        formulaPadraoItemComponent,
        moedaComponent,
        fieldsetComponent,
        gridComponent,
        replicarSelecaoFranquiaComponent,
        posologiaComponent,
        actionBarComponent,
        solicitarSenhaComponent,
    },
    computed: {
        ...mapState<AppState>({
            preLoadPack: state => state.preLoad.preLoadList,
            loadedList: state => state.preLoad.loadedList,
        }),
        ...mapGetters([
            "HAS_PERMISSAO_PERFIL",
            "VALIDAR_PERMISSAO_USUARIO",
            "VALIDAR_PERMISSAO_SOMENTE_CONSULTA",
        ] as Getters),
    },
    methods: mapMutations(["LOAD_LIST"] as Mutations),
})
export default class FormulaPadraoEditComponent extends Vue {
    // State computed props
    preLoadPack: PreLoadPackModel;
    loadedList: boolean;
    HAS_PERMISSAO_PERFIL: (isRede: boolean, isFranqueador: boolean, isSuporte: boolean) => Promise<boolean>;
    VALIDAR_PERMISSAO_USUARIO: (telaDescricao: string, acaoController: string) => boolean;
    VALIDAR_PERMISSAO_SOMENTE_CONSULTA: (telaDescricao: string) => boolean;

    private service = new FormulaPadraoService();
    private produtoService = new ProdutoService();
    private pdfService = new PDFService("FormulaPadraoPDF");
    private formulaPadraoItemComponent: FormulaPadraoItemComponent = null;
    private solicitarSenhaComponent: SolicitarSenhaComponent = null;
    private showPosologia = false;
    private posologiaComponent: PosologiaComponent = null;
    model: FormulaPadraoModel = new FormulaPadraoModel();
    modelId = 0;

    somenteConsulta = false;
    produtosOptions: Array<Object> = [];
    produtosCapsulasOptions: Array<Object> = [];
    formasFarmaceuticaOptions: Array<Object> = [];
    unidadesMedidaOptions: Array<Object> = [];
    posologiaOptions: Array<Object> = [];
    kitEmbalagemOptions: Array<Object> = [];

    private replicarSelecaoFranquiaComponent: ReplicarSelecaoFranquiaComponent = null;
    private isAdmin = false;
    private isAdminFranqueador = false;
    private isAdminRede = false;
    private franquiaId: number = null;
    franquiaOptions: Array<Object> = [];

    private isFormulaCapsula = false;
    private isformulaVolume = true;

    private conversaoTotalQsp = 0;

    tiposFormulaPadraoOptions: Array<Object> = [
        { text: "Acabado", value: TiposFormulaPadrao.Acabado },
        { text: "Base", value: TiposFormulaPadrao.Base },
        { text: "Diluição", value: TiposFormulaPadrao.Diluicao },
        { text: "Excipiente", value: TiposFormulaPadrao.Excipiente },
        { text: "Pré-Venda", value: TiposFormulaPadrao.PreVenda },
        { text: "Pré-Venda Tabelada", value: TiposFormulaPadrao.PreVendaTabelada },
    ];

    private loading = false;
    private unidadeMedidaDesc = "";

    get gridColumns(): Array<GridColumn> {
        return [
            new GridColumn("produtoDescricao", "Produto", GridColumnType.String),
            new GridColumn("tipoItemDescricao", "Tipo Produto", GridColumnType.String),
            new GridColumn("tipoCalculoDescricao", "tipo Cálculo", GridColumnType.String),
            new GridColumn("volumeDose", "Vol. Dose", GridColumnType.Decimal),
            new GridColumn("quantidadeDose", "Qtde", GridColumnType.Decimal, false, false, "", "", false, false, 4),
            new GridColumn("unidadeMedidaDescricao", "Un. Medida", GridColumnType.String),
            new GridColumn("unidadeMedidaManipulacaoDescricao", "Un. Manipulação", GridColumnType.String),
            new GridColumn("faseUso", "Fase", GridColumnType.String),
            new GridColumn(
                "desmembrado",
                "Desmembrar",
                GridColumnType.Boolean,
                true,
                false,
                "",
                "",
                true,
                false,
                2,
                false,
            ),
        ];
    }

    private async load() {
        this.model = new FormulaPadraoModel();

        if (this.modelId > 0) {
            this.loading = true;

            try {
                const data = await this.service.get(this.modelId).withLoading().resolveWithJSON<FormulaPadraoModel>();
                this.model.updateFrom(data);
                this.franquiaId = data.franquiaId;
                this.formulaPadraoItemComponent.clear();
                this.unidadeMedidaDesc = this.model.unidadeMedida.sigla + " - " + this.model.unidadeMedida.descricao;

                this.model.formulaPadraoItens
                    .filter(p => p.sinonimoId > 0)
                    .forEach(p => {
                        p.produtoDescricao = p.sinonimo.sinonimo;
                    });

                setTimeout(() => {
                    this.loading = false;
                }, 150);
            } catch {
                this.loading = false;
                this.$router.back();
            }
        } else {
            this.formulaPadraoItemComponent.clear();
        }
    }

    private async onImprimirFormula() {
        this.solicitarSenhaComponent = this.$refs.solicitarSenhaComponent as SolicitarSenhaComponent;
        this.solicitarSenhaComponent.show();
    }

    private async onResultSolicitarSenha(sucesso: boolean) {
        if (sucesso) {
            if (this.modelId > 0) {
                await this.pdfService.geraPDF(this.modelId);
            }
        } else {
            await this.$showError(this.$t("__.ts.erro"), "Senha invalida!");
        }
    }

    private async loadPosologia() {
        try {
            const data = await new PosologiaService()
                .combo()
                .then(r => r.json() as Promise<PaginationModel<PosologiaModel>>);
            this.posologiaOptions = data.list.map(getPosologiaCombo);
        } catch {}
    }

    private async loadProdutos(franquiaId: number) {
        try {
            const customParameters: ProdutoListParameters = { franquiaId: franquiaId };
            const data = await this.produtoService
                .list("", "descricao", "asc", 1, 999999, customParameters)
                .then(r => r.json() as Promise<PaginationModel<ProdutoModel>>);
            this.produtosOptions = data.list.map(getProdutoCombo);
        } catch {}
    }

    private async save(showSaveConfirmacao = false) {
        let salvou = false;

        try {
            const isValid = await this.$validator.validateAll();
            if (isValid) {
                const sucesso = await this.service[!this.model.id ? "insert" : "update"](this.model)
                    .withLoading()
                    .resolveWithoutJSON();
                if (sucesso) {
                    this["LOAD_LIST"]({ loadFormulaPadraoDesmembrados: true });

                    if (!showSaveConfirmacao) {
                        await this.$showInclusaoUpdate(this.modelId);
                        if (
                            this.preLoadPack.currentFranquiaId != this.model.franquiaId &&
                            this.model.franquiaId != null
                        ) {
                            this.load();
                        } else {
                            this.$router.back();
                        }
                    }
                    salvou = true;
                }
            } else {
                this.$focusErrorField();
            }
        } catch {}
        return salvou;
    }

    private cancel() {
        this.$router.back();
    }

    private onInclusaoItem(model: FormulaPadraoItemModel) {
        if (model.produtoId > 0) {
            //todo: tratar mensagens de erro
            const temQsp = this.model.formulaPadraoItens.some(
                item =>
                    item.produtoId == model.produtoId ||
                    (item.tipoCalculo == TiposCalculoManipulacao.Qsp && model.tipoCalculo == item.tipoCalculo),
            );

            if (!temQsp) {
                this.conversaoTotalQsp = 0;
                this.model.formulaPadraoItens.push(model);
                //verifica se possui qsp para altera-lo
                if (model.tipoItem == TiposItemManipulacao.MateriaPrima) {
                    this.calcularQSP();
                }
            }
        }
    }

    private setKitEmbalagem(kitId: number) {
        this.model.kitEmbalagemId = kitId;
    }

    private onRemoveItem(model: FormulaPadraoItemModel) {
        this.model.formulaPadraoItens = this.model.formulaPadraoItens.filter(p => p != model);
        this.calcularQSP();
    }

    private async calcularQSP() {
        const ativos = this.model.formulaPadraoItens.filter(
            p => p.tipoItem == TiposItemManipulacao.MateriaPrima && p.tipoCalculo != TiposCalculoManipulacao.Qsp,
        );

        const itemQsp = this.model.formulaPadraoItens.filter(p => p.tipoCalculo == TiposCalculoManipulacao.Qsp)[0];
        if (itemQsp != null) {
            const volumeFormulaConvertido =
                this.model.unidadeMedida.conversaoOperacao === OperacaoMatematica.Dividir
                    ? this.model.volume * this.model.unidadeMedida.conversaoValor
                    : this.model.volume / this.model.unidadeMedida.conversaoValor;

            const totalAtivos = ativos.reduce(
                (acumulador, ativo) =>
                    acumulador +
                    (ativo.tipoCalculo == TiposCalculoManipulacao.Percentual
                        ? (volumeFormulaConvertido * ativo.quantidadeDose) / 100
                        : ativo.quantidade),
                0,
            );

            itemQsp.quantidade = arithmeticHelper.round(volumeFormulaConvertido - totalAtivos, 4);
            itemQsp.quantidadeDose = itemQsp.quantidade;
        } else {
            this.conversaoTotalQsp = 0;
        }
    }

    //@ts-ignore
    @Watch("model.ativo")
    private async onAtivoChanged(newValue: boolean) {
        if (!newValue) {
            const response = await this.$showQuestion(
                this.$t("__.ts.atencao"),
                this.$t("__.ts.desejaRealmDesab") + this.model.descricao + "?",
            );
            if (!response) {
                this.model.ativo = true;
            }
        }
    }

    //@ts-ignore
    @Watch("model.formaFarmaceuticaId")
    private onFormaFarmaceuticaIdChanged() {
        const formaFarm = this.preLoadPack.formasFarmaceuticas.filter(p => p.id == this.model.formaFarmaceuticaId);
        this.isFormulaCapsula = false;
        this.isformulaVolume = false;

        if (formaFarm.length > 0) {
            if (this.model.validadeDias == null) {
                this.model.validadeDias = formaFarm[0].validadeDias;
            }
            if (this.model.unidadeMedidaId == null) {
                this.model.unidadeMedidaId = formaFarm[0].unidadeMedidaVolumeId;
            }

            this.isFormulaCapsula = formaFarm[0].tipoFormaFarmaceutica === TiposFormaFarmaceutica.Capsula;
            this.isformulaVolume = formaFarm[0].tipoFormaFarmaceutica === TiposFormaFarmaceutica.Volume;
        }

        if (!this.isFormulaCapsula) {
            this.model.produtoCapsulaId = null;
        }
    }

    //@ts-ignore
    @Watch("model.produtoCapsulaId")
    private onProdutoCapsulaIdChanged() {
        if (this.isFormulaCapsula && this.model.produtoCapsulaId > 0) {
            this.carregarProduto(this.model.produtoCapsulaId).withLoading();
        }
    }

    private async carregarProduto(produtoId: number) {
        const data = await this.produtoService.get(produtoId, 0.1, false).resolveWithJSON<ProdutoModel>();
        if (data.volumeCapsula && this.isFormulaCapsula) {
            this.model.volume = data.volumeCapsula;
        }
    }

    //@ts-ignore
    @Watch("model.unidadeMedidaId")
    private onUnidadeMedidaIdChanged(newValue: number) {
        if (this.model.unidadeMedidaId > 0) {
            const unidade = this.unidadesMedidaOptions.filter(un => un["value"] == this.model.unidadeMedidaId);
            if (unidade.length > 0) {
                this.unidadeMedidaDesc = unidade[0]["text"];

                new UnidadeMedidaService()
                    .get(newValue)
                    .then(response => response.json() as Promise<UnidadeMedidaModel>)
                    .then(data => (this.model.unidadeMedida = data));
            }
        }
    }

    //@ts-ignore
    @Watch("model.volume")
    private onVolumeChanged(newValue: number, oldValue: number) {
        if (this.loading) {
            return;
        }

        //recalcula pesagem filhos
        if (this.model.formulaPadraoItens.length > 0 && newValue > 0) {
            //ajuste de tipo de calculo percentual e qsp
            for (let i = 0; i < this.model.formulaPadraoItens.length; i++) {
                this.model.formulaPadraoItens[i].unidadeMedidaManipulacaoId = this.model.unidadeMedidaId;
                if (this.model.formulaPadraoItens[i].tipoItem == TiposItemManipulacao.MateriaPrima) {
                    if (this.model.formulaPadraoItens[i].tipoCalculo == TiposCalculoManipulacao.Percentual) {
                        this.model.formulaPadraoItens[i].quantidade =
                            (this.model.formulaPadraoItens[i].quantidadeDose / 100) * this.model.volume;
                        if (this.model.formulaPadraoItens[i].quantidade > 0) {
                            this.model.formulaPadraoItens[i].quantidade = parseFloat(
                                this.model.formulaPadraoItens[i].quantidade.toFixed(4),
                            );
                        }
                    } else if (
                        this.model.formulaPadraoItens[i].tipoCalculo == TiposCalculoManipulacao.Nenhum &&
                        oldValue > 0
                    ) {
                        const fatorDiferenca =
                            this.model.formulaPadraoItens[i].quantidade /
                            this.model.formulaPadraoItens[i].quantidadeDose;
                        const percentualDose = this.model.formulaPadraoItens[i].quantidadeDose / oldValue;
                        this.model.formulaPadraoItens[i].quantidadeDose = percentualDose * this.model.volume;
                        this.model.formulaPadraoItens[i].quantidade =
                            this.model.formulaPadraoItens[i].quantidadeDose * fatorDiferenca;

                        if (this.model.formulaPadraoItens[i].quantidadeDose > 0) {
                            this.model.formulaPadraoItens[i].quantidadeDose = arithmeticHelper.round(
                                this.model.formulaPadraoItens[i].quantidadeDose,
                                4,
                            );
                            this.model.formulaPadraoItens[i].quantidade = arithmeticHelper.round(
                                this.model.formulaPadraoItens[i].quantidade,
                                4,
                            );
                        } else {
                            this.model.formulaPadraoItens[i].quantidadeDose = 0;
                            this.model.formulaPadraoItens[i].quantidade = 0;
                        }
                    }
                }
            }
            this.calcularQSP();
        }
    }
    //inicioAdmin
    //@ts-ignore
    @Watch("franquiaId")
    private async onFranquiaChanged() {
        if (this.franquiaId > 0 && this.franquiaId != this.model.franquiaId) {
            this.loading = true;

            await this.loadProdutos(this.franquiaId).withLoading();
            this.loadFormulaPadraoByParent();
        }
    }

    //@ts-ignore
    @Watch("model.quantidade")
    private async onChangeQuantidade() {
        if (this.model.quantidade > 0) {
            for (let index = 0; index < this.model.formulaPadraoItens.length; index++) {
                if (this.model.formulaPadraoItens[index].tipoCalculo == TiposCalculoManipulacao.Unitario) {
                    this.model.formulaPadraoItens[index] = await this.formulaPadraoItemComponent.calcularQuantidadeItem(
                        this.model.formulaPadraoItens[index],
                        this.model.quantidade,
                    );
                }
            }
        }
    }

    private async loadFormulaPadraoByParent() {
        if (this.modelId > 0 && this.franquiaId > 0) {
            while (!this.loadedList) await Delay(100);

            try {
                const data = await this.service
                    .getByParent(this.modelId, this.franquiaId)
                    .withLoading()
                    .resolveWithJSON<FormulaPadraoModel>();

                this.model = new FormulaPadraoModel();
                this.model.updateFrom(data);
                this.franquiaId = data.franquiaId;
                this.formasFarmaceuticaOptions = this.preLoadPack.formasFarmaceuticas
                    .filter(p => p.franquiaId == this.franquiaId)
                    .map(getFormaFarmaceuticaCombo);
            } catch {
                this.franquiaId = this.model.franquiaId;
            }

            setTimeout(() => {
                this.loading = false;
            }, 200);
        }
    }

    private validarVisibilidadeBotoes(botao: string): boolean {
        if (botao == "produzir") {
            return this.model.id && this.VALIDAR_PERMISSAO_USUARIO("formulas_padrao", "insert");
        }

        if (botao == "replicar") {
            return this.model.id > 0 && this.model.formulaPadraoPaiId == null && this.isAdminFranqueador;
        }

        if (botao == "deletar") {
            return (
                this.model.id > 0 &&
                this.model.formulaPadraoPaiId != null &&
                this.VALIDAR_PERMISSAO_USUARIO("formulas_padrao", "delete")
            );
        }

        if (botao == "revisar") {
            return this.model.id > 0 && this.isAdmin;
        }
    }

    private async onReplicar() {
        this.replicarSelecaoFranquiaComponent = this.$refs
            .replicarSelecaoFranquiaComponent as ReplicarSelecaoFranquiaComponent;
        this.replicarSelecaoFranquiaComponent.openFranquias();
    }

    private async onConfirmReplicar(franquiasIds: Array<number>) {
        const idsFranquias = franquiasIds.toString();

        const replicar = await this.save(true);
        if (replicar) {
            try {
                const sucesso = await this.service.replicar(this.model.id, idsFranquias).withLoading();
                if (sucesso) {
                    await this.$showSuccess("Replicados/Atualizados", this.$t("__.ts.regisSalvosSucess"));
                    this["LOAD_LIST"]({ loadFormulaPadraoDesmembrados: true });
                    this["LOAD_LIST"]({ loadFormaFarmaceutica: true });
                }
            } catch {}
        }
    }

    private async loadFranquais() {
        if (this.isAdminRede) {
            try {
                const data = await new FranquiaService().combo().resolveWithJSON<PaginationModel<FranquiaModel>>();
                this.franquiaOptions = data.list.map(item => ({ value: item.id, text: item.nomeFantasia }));
            } catch {}
        }
    }

    private async onDelete() {
        try {
            const response = await this.$showExclusaoQuestion(this.$t("__.ts.desejaExcl") + this.model.descricao + "?");

            if (response) {
                const sucesso = await this.service.delete(this.model.id).withLoading().resolveWithoutJSON();
                if (sucesso) {
                    this["LOAD_LIST"]({ loadFormulaPadraoDesmembrados: true });
                    this.load();
                    await this.$showExclusao();
                    this.$router.back();
                }
            }
        } catch {}
    }

    //fim admin
    //posologia
    private async onAddNewPosologia(closeModal) {
        if (await this.posologiaComponent.save()) {
            closeModal();
        }
    }

    private async openComboNewPosologia() {
        this.showPosologia = true;
        while (!this.$refs.posologiaComponent) await Delay(5);
        this.posologiaComponent = this.$refs.posologiaComponent as PosologiaComponent;

        this.posologiaComponent.load();
    }

    private async onPosologiaSaveOk(modelNovaPosologia: PosologiaModel) {
        await this.loadPosologia();
        this.model.posologiaId = modelNovaPosologia.id;
    }
    //fim posologia

    private async getPreLoadPack() {
        while (!this.loadedList) await Delay(100);

        const currentFranquia = this.preLoadPack.currentFranquiaId;
        this.unidadesMedidaOptions = this.preLoadPack.unidadesMedida
            .filter(p => p.franquiaId == currentFranquia || p.franquiaId == null)
            .map(item => getUnidadeMedidaCombo(item));

        this.formasFarmaceuticaOptions = this.preLoadPack.formasFarmaceuticas
            .filter(p => p.franquiaId == currentFranquia || p.franquiaId == null)
            .map(getFormaFarmaceuticaCombo);

        this.posologiaOptions = this.preLoadPack.posologias
            .filter(p => p.franquiaId == currentFranquia || p.franquiaId == null)
            .map(getPosologiaCombo);

        this.produtosOptions = this.preLoadPack.produtos
            .filter(p => p.franquiaId == currentFranquia || p.franquiaId == null)
            .map(getProdutoCombo);

        this.produtosCapsulasOptions = this.preLoadPack.produtos
            .filter(
                p => p.tipoProduto == TiposProduto.Capsula && (p.franquiaId == currentFranquia || p.franquiaId == null),
            )
            .map(getProdutoCombo);

        this.kitEmbalagemOptions = this.preLoadPack.kitsEmbalagem
            .filter(p => p.franquiaId == currentFranquia || p.franquiaId == null)
            .map(getKitEmbalagemCombo);
    }

    public async revisarTudo() {
        const itens = [];
        this.model.formulaPadraoItens.forEach(item => {
            const itemFp = new FormulaPadraoItemModel();
            itemFp.updateFrom(item);
            itens.push(itemFp);
        });
        this.model.formulaPadraoItens.splice(0);

        for (let i = 0; i < itens.length; i++) {
            //AJUSTE CONFORME NOVAS INCLUSOES
            const modelItem = await this.formulaPadraoItemComponent.refazModel(itens[i]);
            if (modelItem != null) {
                this.model.formulaPadraoItens.push(modelItem);
            }
        }
        this.calcularQSP();
        this.$forceUpdate();
    }

    private onProduzir() {
        this.$router.push("/formulapadrao-manipulacao/" + this.model.id);
    }

    private async mounted() {
        this.formulaPadraoItemComponent = this.$refs.formulaPadraoItemComponent as FormulaPadraoItemComponent;

        this.isAdminRede = await this.HAS_PERMISSAO_PERFIL(true, false, false);
        this.isAdminFranqueador = await this.HAS_PERMISSAO_PERFIL(false, true, false);
        this.isAdmin = await this.HAS_PERMISSAO_PERFIL(false, false, false);

        Promise.all([this.getPreLoadPack(), this.loadFranquais()])
            .then(() => {
                if (this.$route.params.id) {
                    this.modelId = +this.$route.params.id;
                }

                this.somenteConsulta = this.VALIDAR_PERMISSAO_SOMENTE_CONSULTA("formulas_padrao");

                this.load();
            })
            .catch(() => {});
    }
}
