import Vue from "vue";
import { mapState, mapMutations, mapGetters } from "vuex";

import ShortcutComponent from "@/components/parent/shortcut/shortcut";
import shortcutComponent from "@/components/parent/shortcut/shortcut.vue";
import { Component } from "@/decorators";
import PaginationModel from "@/models/paginationModel";
import { StatusEstoque } from "@/models/produtoEstoqueConferenciaLoteModel";
import ProdutoEstoqueConferenciaModel, {
    ProdutoEstoqueConferenciaListParameters,
} from "@/models/produtoEstoqueConferenciaModel";
import ProdutoEstoqueConferenciaService from "@/services/produtoEstoqueConferenciaService";
import { FastActionLastPack, FastActionList, FastActionPack } from "@/store/fastActionStore";
import { AppState, Getters, Mutations } from "@/store/store";
import Delay from "@/utils/common/delay";

import ProdutoEstoqueListComponent from "./list";
import produtoEstoqueListComponent from "./list.vue";

import "./../fastactionsgrid.scss";
import "./produtos.scss";

@Component({
    components: {
        produtoEstoqueListComponent,
        shortcutComponent,
    },
    computed: {
        ...mapState<AppState>({
            fastActionLists: state => state.fastAction.fastActionLists,
            fastActionPack: state => state.fastAction.fastActionPack,
        }),
        ...mapGetters(["VALIDAR_PERMISSAO_USUARIO"] as Getters),
    },
    methods: mapMutations(["SET_LISTSPACK", "SET_LASTCOUNTPACK"] as Mutations),
})
export default class FastActionProdutosPanelComponent extends Vue {
    // State computed props
    fastActionLists: FastActionList;
    fastActionPack: FastActionPack;
    SET_LISTSPACK: (data: FastActionList) => void;
    SET_LASTCOUNTPACK: (data: FastActionLastPack) => void;
    VALIDAR_PERMISSAO_USUARIO: (telaDescricao: string, acaoController: string) => boolean;

    private service: ProdutoEstoqueConferenciaService = new ProdutoEstoqueConferenciaService();
    private produtosEstoque: Array<ProdutoEstoqueConferenciaModel> = [];
    private produtosEstoqueOriginal: Array<ProdutoEstoqueConferenciaModel> = [];

    private shortCutProdutoEstoque: ShortcutComponent = null;
    private produtoEstoqueListComponent: ProdutoEstoqueListComponent = null;

    private showList = false;

    onsemestoque = true;
    onestoquebaixo = true;
    onestoquevencendo = true;

    estoqueBaixo = 0;
    vencendo = 0;
    semEstoque = 0;

    private get produtosEstoqueBaixo() {
        return this.estoqueBaixo;
    }

    private get produtosSemBaixo() {
        return this.semEstoque;
    }

    private get produtosVencendo() {
        return this.vencendo;
    }

    private getInfos() {
        this.semEstoque = Number(this.fastActionPack.countPack.estoqueDados.semEstoque);
        this.vencendo = Number(this.fastActionPack.countPack.estoqueDados.vencendo);
        this.estoqueBaixo = Number(this.fastActionPack.countPack.estoqueDados.estoqueBaixo);
    }

    private getClass(produto: ProdutoEstoqueConferenciaModel) {
        if (produto.produtoLotes.length == 0) {
            return "ExcRed";
        }
        if (produto.produtoLotes[0].statusEstoque == StatusEstoque.Baixo) {
            return "";
        }
        return "ExcOrange";
    }

    private async onVerMais() {
        this.showList = true;
        while (!this.$refs.shortCutProdutoEstoque) {
            await Delay(5);
        }

        if (!this.shortCutProdutoEstoque)
            this.shortCutProdutoEstoque = this.$refs.shortCutProdutoEstoque as ShortcutComponent;

        while (!this.$refs.produtoEstoqueListComponent) {
            await Delay(5);
        }
        if (!this.produtoEstoqueListComponent)
            this.produtoEstoqueListComponent = this.$refs.produtoEstoqueListComponent as ProdutoEstoqueListComponent;
        else {
            this.produtoEstoqueListComponent.load(true);
        }

        let title = "com alertas de estoque";
        if (!this.onestoquebaixo || !this.onsemestoque || !this.onestoquevencendo) {
            if (this.onestoquebaixo) title = "com estoque baixo";
            else if (this.onsemestoque) title = "sem estoque";
            else if (this.onestoquevencendo) title = "proximos da data de vencimento";
        }
        this.shortCutProdutoEstoque.title = "Produtos " + title;
        this.shortCutProdutoEstoque.show();
    }

    private onBaixoEstoque() {
        if (this.onestoquebaixo && !this.onsemestoque && !this.onestoquevencendo) {
            this.onsemestoque = true;
            this.onestoquevencendo = true;
        } else {
            this.onestoquebaixo = true;
            this.onsemestoque = false;
            this.onestoquevencendo = false;
        }

        this.getProdutosEstoqueDivergentes(true);
    }

    private onVencendoEstoque() {
        if (!this.onestoquebaixo && !this.onsemestoque && this.onestoquevencendo) {
            this.onestoquebaixo = true;
            this.onsemestoque = true;
        } else {
            this.onsemestoque = false;
            this.onestoquebaixo = false;
            this.onestoquevencendo = true;
        }

        this.getProdutosEstoqueDivergentes(true);
    }

    private onSemEstoque() {
        if (!this.onestoquebaixo && this.onsemestoque && !this.onestoquevencendo) {
            this.onestoquebaixo = true;
            this.onestoquevencendo = true;
        } else {
            this.onsemestoque = true;
            this.onestoquevencendo = false;
            this.onestoquebaixo = false;
        }

        this.getProdutosEstoqueDivergentes(true);
    }

    private async getProdutosEstoqueDivergentes(skipPreList = false) {
        if (this.fastActionLists.estoqueDivergencias.length > 0 && !skipPreList) {
            const aux = this.fastActionLists.estoqueDivergencias;

            this.produtosEstoqueOriginal.splice(0);
            this.produtosEstoqueOriginal = null;
            this.produtosEstoqueOriginal = new Array<ProdutoEstoqueConferenciaModel>();

            this.produtosEstoque.splice(0);
            this.produtosEstoque = null;
            this.produtosEstoque = new Array<ProdutoEstoqueConferenciaModel>();

            for (let i = 0; i < aux.length; i++) {
                const produto = new ProdutoEstoqueConferenciaModel();
                produto.updateFrom(aux[i]);

                this.produtosEstoqueOriginal.push(produto);

                if (i < 11) {
                    this.produtosEstoque.push(produto);
                }
            }
        } else {
            const customParameters: ProdutoEstoqueConferenciaListParameters = {
                semEstoque: this.onsemestoque,
                vencendo: this.onestoquevencendo,
                estoqueBaixo: this.onestoquebaixo,
            };

            const data = await this.service
                .list("", "descricao", "asc", 1, 33, customParameters)
                .withLoading()
                .resolveWithJSON<PaginationModel<ProdutoEstoqueConferenciaModel>>();

            if (!skipPreList) {
                this.SET_LISTSPACK({ estoqueDivergencias: data.list });
            }

            this.produtosEstoqueOriginal.splice(0);
            this.produtosEstoqueOriginal = null;
            this.produtosEstoqueOriginal = new Array<ProdutoEstoqueConferenciaModel>();

            this.produtosEstoque.splice(0);
            this.produtosEstoque = null;
            this.produtosEstoque = new Array<ProdutoEstoqueConferenciaModel>();

            for (let i = 0; i < data.list.length; i++) {
                const produto = new ProdutoEstoqueConferenciaModel();
                produto.updateFrom(data.list[i]);

                this.produtosEstoqueOriginal.push(produto);

                if (i < 11) {
                    this.produtosEstoque.push(produto);
                }
            }
        }
    }
    private onConfirmList() {
        this.shortCutProdutoEstoque.hide();
    }

    private onSearch(produto: ProdutoEstoqueConferenciaModel) {
        this.$router.push("/produto-edicao/" + produto.id);
    }

    private mounted() {
        this.shortCutProdutoEstoque = this.$refs.shortCutProdutoEstoque as ShortcutComponent;

        Promise.all([this.getInfos(), this.getProdutosEstoqueDivergentes()])
            .then(() => {
                this.SET_LASTCOUNTPACK({
                    estoqueDivergencias: Number(this.fastActionPack.countPack.estoqueDivergencias),
                });
            })
            .catch(() => {});
    }
}
