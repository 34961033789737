import Vue from "vue";

import actionBarComponent from "@/components/child/actionBar/actionBar.vue";
import comboComponent from "@/components/child/form/combo.vue";
import { Component } from "@/decorators";
import PaginationModel from "@/models/paginationModel";
import GrupoProdutoModel from "@/models/produto/grupoProdutoModel";
import EstoqueMinimoService from "@/services/estoqueMinimoService";
import GrupoProdutoService from "@/services/produto/grupoProdutoService";

@Component({
    components: {
        actionBarComponent,
        comboComponent,
    },
})
export default class EstoqueMinimoComponent extends Vue {
    private service = new EstoqueMinimoService();
    coberturaEmDias = 30;
    mesesConsiderar = 6;
    grupoProdutoId?: number = null;
    grupoProdutoOptions: Array<Object> = [];

    private async onAtualizar() {
        try {
            const isValid = await this.$validator.validateAll();

            if (isValid) {
                const sucesso = await this.service
                    .calcularEstoque(this.mesesConsiderar, this.coberturaEmDias, this.grupoProdutoId)
                    .withLoading()
                    .resolveWithoutJSON();

                if (sucesso) {
                    this.$showSuccess(this.$t("__.ts.sucesso"), this.$t("__.ts.sucessoEstMin"));
                }
            }
        } catch {}
    }

    private async loadGruposProduto() {
        try {
            const data = await new GrupoProdutoService().combo().resolveWithJSON<PaginationModel<GrupoProdutoModel>>();

            this.grupoProdutoOptions = data.list.map(item => ({
                value: item.id,
                text: item.descricao,
                tipo: item.tipoProduto,
            }));
        } catch {}
    }

    private mounted() {
        Promise.all([this.loadGruposProduto()])
            .withLoading()
            .catch(() => {});
    }
}
