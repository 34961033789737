import RelatorioBaseModel from "../models/relatorios/base/relatorioBaseModel";
import nfetch from "../utils/events/nfetch";

import { getCookie, mensagemErro } from "./utils/serviceUtils";

export default class RelatorioService {
    protected controllerName: string;
    public mensagem: string;

    constructor() {
        this.controllerName = "Relatorio";
    }

    public get(id: number): Promise<Response> {
        //@ts-ignore
        return nfetch(`/${this.controllerName}/Get?id=${id}`, {
            credentials: "same-origin",
        })
            .then((response: Response) => {
                if (response.ok) {
                    return response;
                } else {
                    return mensagemErro(response, "Get");
                }
            })
            .catch(() => {});
    }

    public getRelatoriosPermitidos(): Promise<Response> {
        return nfetch(`/${this.controllerName}/GetListaRelatoriosUsuario`, {
            credentials: "same-origin",
        });
    }

    public async getRelatorioXLS(model: RelatorioBaseModel): Promise<Response> {
        //@ts-ignore
        return nfetch(`/${this.controllerName}/GetRelatorioXLS`, {
            method: "post",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json; charset=UTF-8",
                "XSRF-TOKEN": getCookie("XSRF-TOKEN"),
            },
            body: JSON.stringify(model),
        });
    }

    public getRelatorio(model: RelatorioBaseModel): Promise<Response> {
        //@ts-ignore
        return nfetch(`/${this.controllerName}/GetRelatorio`, {
            method: "post",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json; charset=UTF-8",
                "XSRF-TOKEN": getCookie("XSRF-TOKEN"),
            },
            body: JSON.stringify(model),
        });
    }
}
