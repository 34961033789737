var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "crud crud-list" },
    [
      _c("div", { staticClass: "info-div" }, [
        _c("h1", { staticClass: "title" }, [
          _vm._v(_vm._s(_vm.$t("__.Crud.rede.list_vue_html.redes"))),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "content" }, [
          _c("div", { staticClass: "info-panel" }, [
            _c("span", [_vm._v(_vm._s(_vm.total))]),
            _vm._v(" "),
            _c("span", [
              _vm._v(_vm._s(_vm.$t("__.Crud.rede.list_vue_html.redes"))),
            ]),
            _vm._v(" "),
            _c("span", [
              _vm._v(_vm._s(_vm.$t("__.Crud.rede.list_vue_html.cadastradas"))),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "grid-component",
        {
          ref: "gridcomponent",
          attrs: {
            data: _vm.gridData,
            tituloPagina: "Redes",
            columns: _vm.gridColumns,
            "page-index": _vm.pageIndex,
            "page-size": _vm.pageSize,
            total: _vm.total,
            startFilterKey: _vm.startFilterKey,
          },
          on: {
            "gerar-xls": function ($event) {
              _vm.pags = true
              _vm.tipoDocGerar = 0
            },
            "gerar-pdf": function ($event) {
              _vm.pags = true
              _vm.tipoDocGerar = 1
            },
            "gerar-impressao": function ($event) {
              _vm.pags = true
              _vm.tipoDocGerar = 2
            },
            "change-filter-key": _vm.onChangeFilterKey,
            "change-sort": _vm.onChangeSort,
            "edit-item": _vm.onEditItem,
            "remove-item": _vm.onRemoveItem,
            "create-item": _vm.onCreateItem,
            "change-page": _vm.onChangePage,
          },
        },
        [
          !_vm.isAdmin
            ? _c(
                "div",
                {
                  staticClass: "grid-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c("div", [
                    _vm._v(_vm._s(_vm.$t("__.Crud.rede.list_vue_html.redes"))),
                  ]),
                ]
              )
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }