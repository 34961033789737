var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("input", {
    directives: [{ name: "mask-cep", rawName: "v-mask-cep" }],
    attrs: {
      type: "text",
      autocomplete: "off",
      id: _vm.id,
      name: _vm.name,
      disabled: _vm.disabled,
    },
    domProps: { value: _vm.value },
    on: {
      input: function ($event) {
        _vm.onInput($event.target.value)
      },
      blur: function ($event) {
        _vm.onBlur($event.target.value)
      },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }