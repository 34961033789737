var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("input", {
    directives: [
      { name: "mask-moeda-com-sinal", rawName: "v-mask-moeda-com-sinal" },
    ],
    class: [
      { "color-primary": _vm.showDifColor && _vm.value > 0 },
      { "color-black": _vm.showDifColor && _vm.value == 0 },
      { "color-red": _vm.showDifColor && _vm.value < 0 },
    ],
    attrs: { autocomplete: "off", type: "text", id: _vm.id, name: _vm.name },
    domProps: { value: _vm.value },
    on: {
      blur: function ($event) {
        _vm.onBlur($event.target.value)
      },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }