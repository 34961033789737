import { GridColumn, GridColumnType } from "@/components/child/grid/gridColumn";
import { Component } from "@/decorators";
import NcmModel from "@/models/produto/ncmModel";
import NcmService from "@/services/produto/ncmService";

import ListComponentBase from "../listComponentBase";

@Component
export default class NcmListComponent extends ListComponentBase<NcmModel> {
    get gridColumns(): Array<GridColumn> {
        return [
            new GridColumn("codigo", this.$t("__.ts.codigo"), GridColumnType.String),
            new GridColumn("descricao", this.$t("__.ts.descricao"), GridColumnType.String),
        ];
    }

    private beforeMount() {
        this.setProps(new NcmService(), "ncm", "descricao", true);
    }
}
