import Vue from "vue";
import { mapMutations, mapState } from "vuex";

import Grid from "@/components/child/grid/grid";
import gridComponent from "@/components/child/grid/grid.vue";
import { GridColumn, GridColumnType } from "@/components/child/grid/gridColumn";
import LoadingModalComponent from "@/components/child/loadingmodal/loadingmodal";
import loadingModalComponent from "@/components/child/loadingmodal/loadingmodal.vue";
import { Component, Watch } from "@/decorators";
import PaginationPackModel from "@/models/auxiliar/paginationPackModel";
import ContaGerencialClassificacaoModel from "@/models/financeiro/contaGerencialClassificacaoModel";
import PaginationModel from "@/models/paginationModel";
import ContaGerencialClassificacaoService from "@/services/financeiro/contaGerencialClassificacaoService";
import { AppState, Mutations } from "@/store/store";
import Delay from "@/utils/common/delay";

import "../crud.scss";

@Component({
    components: {
        gridComponent,
        loadingModalComponent,
    },
    computed: mapState({
        ...mapState<AppState>({
            loadingPack: state => state.paginationStatus.loading,
            paginationParams: state => state.paginationStatus.paginationParams,
        }),
    }),
    methods: mapMutations(["SET_PARAMS", "GET_PARAMS"] as Mutations),
})
export default class ContaGerencialClassificacaoListComponent extends Vue {
    private service = new ContaGerencialClassificacaoService();
    private loadingModalComponent: LoadingModalComponent = null;
    private gridcomponent: Grid = null;

    startFilterKey = "";
    gridFilterKey = "";
    gridSortKey = "descricao";
    gridSortOrder = "asc";
    gridData: Array<ContaGerencialClassificacaoModel> = [];
    get gridColumns(): Array<GridColumn> {
        return [new GridColumn("descricao", "Descri\u00e7\u00e3o", GridColumnType.String)];
    }

    pageIndex = 1;
    pageSize = 20;
    total = 0;

    pags = false;
    tipoDocGerar: number = null;

    private load() {
        this.loadingModalComponent.showLoading();

        this.service
            .list(
                this.gridFilterKey,
                this.gridSortKey,
                this.gridSortOrder,
                this.pags ? 1 : this.pageIndex,
                this.pags ? 999999 : this.pageSize,
            )
            .then(response => {
                return response.json() as Promise<PaginationModel<ContaGerencialClassificacaoModel>>;
            })
            .then(data => {
                //atualiza params no store
                if (data.total > 0)
                    this["SET_PARAMS"]({
                        routePath: this.$route.path,
                        filterKey: this.gridFilterKey,
                        sortKey: this.gridSortKey,
                        sortOrder: this.gridSortOrder,
                        pageIndex: this.pageIndex,
                        pageSize: this.pageSize,
                    });

                this.gridData = data.list;
                this.total = data.total;
                if (this.pags) {
                    if (this.tipoDocGerar == 0) {
                        setTimeout(() => {
                            this.gridcomponent.gerarXls();
                        }, 2000);
                    }

                    if (this.tipoDocGerar == 1) {
                        setTimeout(() => {
                            this.gridcomponent.gerarPdf();
                        }, 2000);
                    }

                    if (this.tipoDocGerar == 2) {
                        setTimeout(() => {
                            this.gridcomponent.gerarImpressao();
                        }, 2000);
                    }

                    setTimeout(() => {
                        this.pags = false;
                    }, 2000);
                }
                this.loadingModalComponent.hide();
            })
            .catch(error => {
                this.loadingModalComponent.hide();
            });
    }

    private onEditItem(model: ContaGerencialClassificacaoModel) {
        this.$router.push("/contagerencialclassificacao-edicao/" + model.id);
    }

    private async onRemoveItem(model: ContaGerencialClassificacaoModel) {
        const response = await this.$showExclusaoQuestion(`${this.$t("__.ts.desejaExcl")}${model.descricao}?`);

        if (response) {
            try {
                const sucesso = await this.service.delete(model.id).withLoading().resolveWithoutJSON();
                if (sucesso) {
                    this.load();
                    this.$showExclusao();
                }
            } catch {}
        }
    }

    private onCreateItem() {
        this.$router.push("/contagerencialclassificacao-edicao");
    }

    private onChangeFilterKey(filterKey: string) {
        this.gridFilterKey = filterKey;
        this.load();
    }

    private onChangeSort(sortKey: string, sortOrder: string) {
        this.gridSortKey = sortKey;
        this.gridSortOrder = sortOrder;
        this.load();
    }

    private onChangePage(pageIndex: number) {
        this.pageIndex = pageIndex;
        this.load();
    }

    @Watch("pags")
    private onPags() {
        this.load();
    }

    private async loadPagination() {
        await this["GET_PARAMS"](this.$route.path);
        while (this["loadingPack"]) {
            await Delay(50);
        }
        const paginationPack = this["paginationParams"] as PaginationPackModel;

        if (paginationPack != null) {
            this.gridFilterKey = paginationPack.filterKey;
            this.startFilterKey = this.gridFilterKey;
            this.gridSortKey = paginationPack.sortKey;
            this.gridSortOrder = paginationPack.sortOrder;
            this.pageIndex = paginationPack.pageIndex;
            this.pageSize = paginationPack.pageSize;
        }
    }

    private mounted() {
        this.loadingModalComponent = this.$refs.loadingModalComponent as LoadingModalComponent;
        this.gridcomponent = this.$refs.gridcomponent as Grid;
        this.loadingModalComponent.showLoading();
        Promise.all([this.loadPagination()])
            .then(result => {
                this.load();
            })
            .catch(error => {
                this.loadingModalComponent.hide();
            });
    }
}
