import Vue from "vue";

import comboComponent from "@/components/child/form/combo.vue";
import fieldsetComponent from "@/components/child/form/fieldset.vue";
import { Component, Watch } from "@/decorators";
import ModeloImpressaoCampos from "@/models/modeloImpressao/modeloImpressaoCamposModel";
import ModeloImpressaoCondicao from "@/models/modeloImpressao/modeloImpressaoCondicaoModel";

import "../crud/crud.scss";

@Component({
    components: {
        comboComponent,
        fieldsetComponent,
    },
})
export default class CondicaoditComponent extends Vue {
    dadosOptions: Array<Object> = [];
    dados = 0;
    camposOptions: Array<Object> = [];
    campoId = 0;
    campos = new Array<ModeloImpressaoCampos>();
    valoresOptions: Array<Object> = [];
    valorId = null;
    model = new ModeloImpressaoCondicao();
    isTextValue = false;

    public loadNewCondicao(campos: Array<ModeloImpressaoCampos>) {
        this.campos = campos;
        this.dados = 0;
        const mainDado = { value: 0, text: "Dados Principais" };

        this.dadosOptions = this.dadosOptions.concat(
            mainDado,
            this.campos.filter(p => p.header).map(item => ({ value: item.index, text: item.labelName })),
        );

        this.camposOptions = this.campos
            .filter(p => !p.header && p.parentIndex == this.dados)
            .map(item => ({ value: item.index, text: item.labelName }));

        this.valoresOptions = [];
    }

    public loadCondicao(condicao: ModeloImpressaoCondicao) {
        this.clear();
        this.model.updateFrom(condicao);

        if (
            this.model.condicaoCampoName != null &&
            this.model.condicaoCampoPath != null &&
            this.model.condicaoValue != null
        ) {
            const campo = this.campos.filter(p => !p.header && p.labelName == this.model.condicaoCampoName)[0];
            this.dados = campo.parentIndex;

            setTimeout(() => {
                this.campoId = campo.index;
                this.isTextValue = campo.labelId == "FormaFarmaceutica.Descricao";
                setTimeout(() => {
                    this.setValoresOptions();
                    if (this.isTextValue) {
                        this.valorId = condicao.condicaoValue;
                    } else {
                        this.valorId = this.valoresOptions.filter(p => p["text"] == condicao.condicaoValue)[0]["value"];
                    }
                }, 100);
            }, 100);
        }
    }

    public loaded() {
        return this.campos.length > 0;
    }

    public clear() {
        this.dados = 0;
        this.campoId = null;
        this.valorId = null;

        if (this.valoresOptions != null && this.valoresOptions.length > 0) {
            this.valoresOptions.splice(0);
        }
    }

    public getCondicaoModel() {
        if (this.campoId != null && this.valorId != null) {
            const campo = this.campos.find(p => p.index == this.campoId);
            this.model.condicaoCampoName = campo.labelName;
            this.model.condicaoCampoPath = campo.labelId;
            if (this.isTextValue) {
                this.model.condicaoValue = this.valorId;
            } else {
                this.model.condicaoValue = this.valoresOptions.find(p => p["value"] == this.valorId)["text"];
            }
            return this.model;
        }
        return null;
    }

    @Watch("dados")
    private onDadosChanged() {
        this.campoId = null;
        this.camposOptions = this.campos
            .filter(p => !p.header && p.parentIndex == this.dados)
            .map(item => ({ value: item.index, text: item.labelName }));
    }

    @Watch("campoId")
    private onCampoIdChanged() {
        this.valorId = null;
        if (this.campoId > 0) {
            this.setValoresOptions();
        } else {
            this.valorId = null;
            if (this.valoresOptions != null && this.valoresOptions.length > 0) {
                this.valoresOptions.splice(0);
            }
        }

        this.isTextValue = false;
        const dadosFormaFarmaceutica = this.dadosOptions.find(p => p["text"] == "Dados Forma Farmacêutica");
        if (dadosFormaFarmaceutica && dadosFormaFarmaceutica["value"] == this.dados) {
            const campoFormaFarmaceuticaDescricao = this.camposOptions.find(p => p["text"] == "Descrição");
            this.isTextValue = campoFormaFarmaceuticaDescricao["value"] == this.campoId;
        }
    }

    private setValoresOptions() {
        if (this.campoId > 0) {
            let it = 0;
            const valuesEnums: Array<Object> = [];

            this.campos
                .filter(p => p.index == this.campoId && !p.header)[0]
                .camposEnumValues.forEach(obj => {
                    valuesEnums.push({ value: it, text: obj });
                    it++;
                });
            this.valoresOptions = valuesEnums;
        }
    }
}
