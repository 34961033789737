import NotificationModel from "@/models/notificationModel";
import { getCustomParams, getPaginationParams } from "@/utils/common/http";
import nfetch from "@/utils/events/nfetch";

import { IService } from "./base/iService";

export default class NotificationService extends IService<NotificationModel> {
    constructor() {
        super("Notification");
    }

    public getListByUsuario(
        filterKey: string,
        sortKey: string,
        sortOrder: string,
        pageIndex: number,
        pageSize: number,
        customProperties: { [key: string]: any } = {},
    ): Promise<Response> {
        const custom = getCustomParams(customProperties);
        const pagination = getPaginationParams(filterKey, sortKey, sortOrder, pageIndex, pageSize);

        return nfetch(`/${this.controllerName}/GetListByUsuario?${pagination}` + custom, {
            credentials: "same-origin",
        });
    }

    public markAllAsReaded(): Promise<Response> {
        return nfetch(`/${this.controllerName}/MarkAllAsReaded`, {
            method: "post",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json; charset=UTF-8",
            },
        });
    }

    public markAsReaded(id: number): Promise<Response> {
        return nfetch(`/${this.controllerName}/MarkAsReaded?id=${id}`, {
            method: "post",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json; charset=UTF-8",
            },
        });
    }

    public countUnreaded(): Promise<Response> {
        return nfetch(`/${this.controllerName}/CountUnreaded`, {
            credentials: "same-origin",
        });
    }
}
