var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "fieldset-component",
    {
      attrs: {
        disabled: "disabled",
        title: _vm.$t(
          "__.Components.parent.importacaonfe.destinatario_vue_html.destinatario"
        ),
      },
    },
    [
      _c("div", { attrs: { slot: "rows" }, slot: "rows" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-sm-12" }, [
            _c("div", { staticClass: "form-group" }, [
              _c(
                "label",
                { staticClass: "control-label", attrs: { for: "nome" } },
                [
                  _vm._v(
                    _vm._s(
                      _vm.model.tipoPessoa == _vm.tiposPessoa.Fisica
                        ? _vm.$t("__.Crud.cliente.edit_vue_html.nome")
                        : _vm.$t("__.Crud.cliente.edit_vue_html.razaoSocial")
                    )
                  ),
                ]
              ),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.model.nome,
                    expression: "model.nome",
                  },
                ],
                staticClass: "form-control",
                attrs: { type: "text", id: "nome", name: "nome" },
                domProps: { value: _vm.model.nome },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.model, "nome", $event.target.value)
                  },
                },
              }),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.model.tipoPessoa != _vm.tiposPessoa.Fisica,
                expression: "model.tipoPessoa != tiposPessoa.Fisica",
              },
            ],
            staticClass: "row",
          },
          [
            _c("div", { staticClass: "col-sm-4" }, [
              _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c(
                    "label",
                    { staticClass: "control-label", attrs: { for: "cnpj" } },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "__.Components.parent.importacaonfe.destinatario_vue_html.cnpj"
                          )
                        )
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("cnpj-component", {
                    staticClass: "form-control",
                    attrs: { id: "cnpj", name: "cnpj" },
                    model: {
                      value: _vm.model.cnpj,
                      callback: function ($$v) {
                        _vm.$set(_vm.model, "cnpj", $$v)
                      },
                      expression: "model.cnpj",
                    },
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-sm-4" }, [
              _c("div", { staticClass: "form-group" }, [
                _c(
                  "label",
                  {
                    staticClass: "control-label",
                    attrs: { for: "inscricaoEstadual" },
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "__.Components.parent.importacaonfe.destinatario_vue_html.ie"
                        )
                      )
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.model.inscricaoEstadual,
                      expression: "model.inscricaoEstadual",
                    },
                  ],
                  staticClass: "form-control",
                  attrs: {
                    type: "text",
                    id: "inscricaoEstadual",
                    name: "inscricaoEstadual",
                    placeholder: "___-_______",
                  },
                  domProps: { value: _vm.model.inscricaoEstadual },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.model,
                        "inscricaoEstadual",
                        $event.target.value
                      )
                    },
                  },
                }),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-sm-4" }, [
              _c("div", { staticClass: "form-group" }, [
                _c(
                  "label",
                  { staticClass: "control-label", attrs: { for: "telefone" } },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "__.Components.parent.importacaonfe.destinatario_vue_html.tel"
                        )
                      )
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    { name: "mask-telefone", rawName: "v-mask-telefone" },
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.model.endereco.telefone,
                      expression: "model.endereco.telefone",
                    },
                  ],
                  staticClass: "form-control",
                  attrs: { type: "text", id: "telefone", name: "telefone" },
                  domProps: { value: _vm.model.endereco.telefone },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.model.endereco,
                        "telefone",
                        $event.target.value
                      )
                    },
                  },
                }),
              ]),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.model.tipoPessoa == _vm.tiposPessoa.Fisica,
                expression: "model.tipoPessoa == tiposPessoa.Fisica",
              },
            ],
            staticClass: "row",
          },
          [
            _c("div", { staticClass: "col-sm-6" }, [
              _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c(
                    "label",
                    { staticClass: "control-label", attrs: { for: "cpf" } },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "__.Components.parent.importacaonfe.destinatario_vue_html.cpf"
                          )
                        )
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("cpf-component", {
                    staticClass: "form-control",
                    attrs: { id: "cpf", name: "cpf" },
                    model: {
                      value: _vm.model.cpf,
                      callback: function ($$v) {
                        _vm.$set(_vm.model, "cpf", $$v)
                      },
                      expression: "model.cpf",
                    },
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-sm-6" }, [
              _c("div", { staticClass: "form-group" }, [
                _c(
                  "label",
                  { staticClass: "control-label", attrs: { for: "telefone" } },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "__.Components.parent.importacaonfe.destinatario_vue_html.tel"
                        )
                      )
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    { name: "mask-telefone", rawName: "v-mask-telefone" },
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.model.endereco.telefone,
                      expression: "model.endereco.telefone",
                    },
                  ],
                  staticClass: "form-control",
                  attrs: { type: "text", id: "telefone", name: "telefone" },
                  domProps: { value: _vm.model.endereco.telefone },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.model.endereco,
                        "telefone",
                        $event.target.value
                      )
                    },
                  },
                }),
              ]),
            ]),
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-sm-4" }, [
            _c("div", { staticClass: "form-group" }, [
              _c(
                "label",
                { staticClass: "control-label", attrs: { for: "cep" } },
                [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        "__.Components.parent.importacaonfe.destinatario_vue_html.cpf"
                      )
                    )
                  ),
                ]
              ),
              _vm._v(" "),
              _c("input", {
                directives: [
                  { name: "mask-cep", rawName: "v-mask-cep" },
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.model.endereco.cep,
                    expression: "model.endereco.cep",
                  },
                ],
                staticClass: "form-control",
                attrs: { type: "text", id: "cep", name: "cep" },
                domProps: { value: _vm.model.endereco.cep },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.model.endereco, "cep", $event.target.value)
                  },
                },
              }),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-sm-8" }, [
            _c("div", { staticClass: "form-group" }, [
              _c(
                "label",
                { staticClass: "control-label", attrs: { for: "paisNome" } },
                [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        "__.Components.parent.importacaonfe.destinatario_vue_html.pais"
                      )
                    )
                  ),
                ]
              ),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.model.endereco.paisNome,
                    expression: "model.endereco.paisNome",
                  },
                ],
                staticClass: "form-control",
                attrs: { type: "text", id: "paisNome", name: "paisNome" },
                domProps: { value: _vm.model.endereco.paisNome },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.model.endereco,
                      "paisNome",
                      $event.target.value
                    )
                  },
                },
              }),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-sm-4" }, [
            _c("div", { staticClass: "form-group" }, [
              _c(
                "label",
                { staticClass: "control-label", attrs: { for: "uf" } },
                [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        "__.Components.parent.importacaonfe.destinatario_vue_html.uf"
                      )
                    )
                  ),
                ]
              ),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.model.endereco.uf,
                    expression: "model.endereco.uf",
                  },
                ],
                staticClass: "form-control",
                attrs: { type: "text", id: "uf", name: "uf" },
                domProps: { value: _vm.model.endereco.uf },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.model.endereco, "uf", $event.target.value)
                  },
                },
              }),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-sm-8" }, [
            _c("div", { staticClass: "form-group" }, [
              _c(
                "label",
                { staticClass: "control-label", attrs: { for: "cidadeNome" } },
                [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        "__.Components.parent.importacaonfe.destinatario_vue_html.cidade"
                      )
                    )
                  ),
                ]
              ),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.model.endereco.cidadeNome,
                    expression: "model.endereco.cidadeNome",
                  },
                ],
                staticClass: "form-control",
                attrs: { type: "text", id: "cidadeNome", name: "cidadeNome" },
                domProps: { value: _vm.model.endereco.cidadeNome },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.model.endereco,
                      "cidadeNome",
                      $event.target.value
                    )
                  },
                },
              }),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-sm-12" }, [
            _c("div", { staticClass: "form-group" }, [
              _c(
                "label",
                { staticClass: "control-label", attrs: { for: "endereco" } },
                [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        "__.Components.parent.importacaonfe.destinatario_vue_html.endereco"
                      )
                    )
                  ),
                ]
              ),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.model.endereco.endereco,
                    expression: "model.endereco.endereco",
                  },
                ],
                staticClass: "form-control",
                attrs: { type: "text", id: "endereco", name: "endereco" },
                domProps: { value: _vm.model.endereco.endereco },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.model.endereco,
                      "endereco",
                      $event.target.value
                    )
                  },
                },
              }),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-sm-4" }, [
            _c("div", { staticClass: "form-group" }, [
              _c(
                "label",
                { staticClass: "control-label", attrs: { for: "numero" } },
                [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        "__.Components.parent.importacaonfe.destinatario_vue_html.nro"
                      )
                    )
                  ),
                ]
              ),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.model.endereco.numero,
                    expression: "model.endereco.numero",
                  },
                ],
                staticClass: "form-control",
                attrs: { type: "text", id: "numero", name: "numero" },
                domProps: { value: _vm.model.endereco.numero },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.model.endereco, "numero", $event.target.value)
                  },
                },
              }),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-sm-8" }, [
            _c("div", { staticClass: "form-group" }, [
              _c(
                "label",
                { staticClass: "control-label", attrs: { for: "bairro" } },
                [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        "__.Components.parent.importacaonfe.destinatario_vue_html.bairro"
                      )
                    )
                  ),
                ]
              ),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.model.endereco.bairro,
                    expression: "model.endereco.bairro",
                  },
                ],
                staticClass: "form-control",
                attrs: { type: "text", id: "bairro", name: "bairro" },
                domProps: { value: _vm.model.endereco.bairro },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.model.endereco, "bairro", $event.target.value)
                  },
                },
              }),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-sm-12" }, [
            _c("div", { staticClass: "form-group" }, [
              _c(
                "label",
                { staticClass: "control-label", attrs: { for: "complemento" } },
                [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        "__.Components.parent.importacaonfe.destinatario_vue_html.complemen"
                      )
                    )
                  ),
                ]
              ),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.model.endereco.complemento,
                    expression: "model.endereco.complemento",
                  },
                ],
                staticClass: "form-control",
                attrs: { type: "text", id: "complemento", name: "complemento" },
                domProps: { value: _vm.model.endereco.complemento },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.model.endereco,
                      "complemento",
                      $event.target.value
                    )
                  },
                },
              }),
            ]),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }