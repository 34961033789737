import BaseModel from "./base/baseModel";
import GatewayPagamentoProvider from "./enum/financeiro/gatewayPagamentoProvider";

export default class ConfiguracaoGatewayPagamentoModel extends BaseModel {
    id = 0;
    gatewayPagamento = GatewayPagamentoProvider.PagSeguro;
    token = "";
    descricaoFaturaCartao = "";
    pixAtivo = true;
    boletoAtivo = true;
    cartaoCreditoAtivo = true;
    cartaoDebitoAtivo = true;
    cartaoCreditoMaximoParcelasSemJuros = 1;
    cartaoCreditoMaximoParcelasComJuros = 1;
    valorMinimoParcela = 0;
    expiracaoLinkPagamentoEmHoras = 1;
    ativo = true;
}
