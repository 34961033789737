import { GridColumn, GridColumnType } from "@/components/child/grid/gridColumn";
import { Component } from "@/decorators";
import MotivoCancelamentoModel from "@/models/motivoCancelamentoModel";
import MotivoCancelamentoService from "@/services/motivoCancelamentoService";

import ListComponentBase from "../listComponentBase";

@Component
export default class MotivoCancelamentoListComponent extends ListComponentBase<MotivoCancelamentoModel> {
    get gridColumns(): Array<GridColumn> {
        return [
            new GridColumn("descricao", this.$t("__.ts.descricao"), GridColumnType.String),
            new GridColumn("ativo", this.$t("__.ts.ativo"), GridColumnType.Boolean),
        ];
    }

    private beforeMount() {
        this.setProps(new MotivoCancelamentoService(), "motivocancelamento", "descricao");
    }
}
