import Vue from "vue";
import { mapState } from "vuex";

import comboComponent from "@/components/child/form/combo.vue";
import { Component, Prop, Watch } from "@/decorators";
import PreLoadPackModel from "@/models/auxiliar/preLoadPackModel";
import TiposProduto from "@/models/enum/tiposProduto";
import KitEmbalagemModel from "@/models/kitEmbalagemModel";
import PaginationModel from "@/models/paginationModel";
import ProdutoModel from "@/models/produto/produtoModel";
import ProdutoLoteFracionamentoItemEmbalagemModel from "@/models/produtoLoteFracionamentoItemEmbalagemModel";
import ProdutoLoteFracionamentoItemModel from "@/models/produtoLoteFracionamentoItemModel";
import KitEmbalagemService from "@/services/kitEmbalagemService";
import ProdutoService from "@/services/produto/produtoService";
import { AppState } from "@/store/store";
import Delay from "@/utils/common/delay";

import FracionamentoKitEmbalagemItemEditComponent from "./fracionamentoKitEmbalagemItem";
import fracionamentoKitEmbalagemItemComponent from "./fracionamentoKitEmbalagemItem.vue";

import "../crud.scss";

@Component({
    components: {
        comboComponent,
        fracionamentoKitEmbalagemItemComponent,
    },
    computed: mapState<AppState>({
        preLoadList: state => state.preLoad.preLoadList,
    }),
})
export default class FracionamentoKitEmbalagemEditComponent extends Vue {
    // State computed props
    preLoadList: PreLoadPackModel;

    private service = new KitEmbalagemService();
    private produtoService = new ProdutoService();

    model = new ProdutoLoteFracionamentoItemModel();

    produtoOptions = [];
    kitEmbalagensOptions = [];

    @Prop(Number) index: number;
    @Prop(Boolean) somenteConsulta: boolean;
    @Prop(Array) itensEmbalagem;

    //@ts-ignore
    @Watch("model.kitEmbalagemId")
    private async onChangeKitEmbalagemId() {
        this.model.itensEmbalagens = [];

        const itensEmbalagemModel = this.itensEmbalagem as ProdutoLoteFracionamentoItemEmbalagemModel[];
        if (this.model.kitEmbalagemId) {
            try {
                const data = await this.service.get(this.model.kitEmbalagemId).resolveWithJSON<KitEmbalagemModel>();
                data.kitEmbalagemItens.forEach(p => {
                    const item = new ProdutoLoteFracionamentoItemEmbalagemModel();
                    const itemEmbalagem = itensEmbalagemModel.find(i => i.kitEmbalagemItemId == p.id);
                    if (itemEmbalagem) {
                        item.updateFrom(itemEmbalagem);
                        item.produtoId = p.produtoId;
                        item.quantidadeOriginal = p.quantidade;
                    } else {
                        item.kitEmbalagemItemId = p.id;
                        item.produtoId = p.produtoId;
                        item.produtoLoteId = p.produtoLoteId;
                        item.quantidadeOriginal = p.quantidade;
                        item.quantidade = p.quantidade * this.model.quantidadeEmbalagem;
                    }
                    this.model.itensEmbalagens.push(item);
                });
            } catch {}
        }

        this.$emit("onChangeEmbalagem", this.model);
    }

    //@ts-ignore
    @Watch("model.itensEmbalagens")
    private onChangeItensEmbalagens() {
        this.$emit("onChangeEmbalagem", this.model);
    }

    public setModel(item: ProdutoLoteFracionamentoItemModel) {
        this.model.updateFrom(item);

        this.model.itensEmbalagens.forEach(p => {
            p.quantidade = p.quantidadeOriginal * this.model.quantidadeEmbalagem;
        });

        this.updateKitEmbalagemItem();
    }

    public async validateAll() {
        let isValid = await this.$validator.validateAll();

        await this.loadItensComponent();

        for (let index = 0; index < this.model.itensEmbalagens.length; index++) {
            const element = this.$refs.indexEmbalagemItem[index] as FracionamentoKitEmbalagemItemEditComponent;

            const itensIsValid = await element.validateAll();
            isValid = isValid && itensIsValid;
        }

        return isValid;
    }

    private onChangeItemEmbalagem(
        itemEmbalagem: ProdutoLoteFracionamentoItemEmbalagemModel,
        item: ProdutoLoteFracionamentoItemEmbalagemModel,
    ) {
        item.id = itemEmbalagem.id;
        item.produtoLoteId = itemEmbalagem.produtoLoteId;
    }

    private async loadProdutos() {
        try {
            const data = await this.produtoService
                .listFilterProdutoTipo(TiposProduto.Embalagem, 1, 99999)
                .resolveWithJSON<PaginationModel<ProdutoModel>>();

            this.produtoOptions = data.list.map(item => ({
                value: item.id,
                text: item.descricao,
                ignoraControleEstoque: item.ignoraControleEstoque,
            }));
        } catch {}
    }

    private async updateKitEmbalagemItem() {
        await this.loadItensComponent();

        for (let index = 0; index < this.model.itensEmbalagens.length; index++) {
            const element = this.$refs.indexEmbalagemItem[index] as FracionamentoKitEmbalagemItemEditComponent;
            element.setModel(this.model.itensEmbalagens[index]);
        }
    }

    private async loadItensComponent() {
        while (!this.$refs.indexEmbalagemItem) await Delay(50);

        for (let index = 0; index < this.model.itensEmbalagens.length; index++) {
            while (!this.$refs.indexEmbalagemItem[index]) await Delay(50);
        }
    }

    private mounted() {
        Promise.all([this.loadProdutos()])
            .withLoading()
            .then(() => {
                this.kitEmbalagensOptions = this.preLoadList.kitEmbalagemCombo();
            })
            .catch(() => {});
    }
}
