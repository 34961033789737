import Vue from "vue";
import VueI18n from "vue-i18n";
import { NotificationOptions } from "vue-notification";

import Boot from "@/main";
import TypeNotification from "@/models/enum/typeNotification";
import NotificationModel from "@/models/notificationModel";
import NotificationService from "@/services/notificationService";

const notificationService = new NotificationService();

type Text = string | VueI18n.TranslateResult;

declare module "vue/types/vue" {
    interface Vue {
        $notifySuccess: (text: Text, title?: Text) => void;
        $notifyWarn: (text: Text, title?: Text) => void;
        $notifyError: (text: Text, title?: Text) => void;
        $notifyInfo: (text: Text, title?: Text) => void;
        $notifyByType: (text: Text, title?: Text, type?: TypeNotification) => void;
        $notifyOtherUser: (userId: number, text: Text, title?: Text, type?: TypeNotification) => Promise<void>;
    }
}

function BaseNotification(settings: NotificationOptions) {
    Boot.$notify({
        speed: 400,
        type: "success",
        duration: 8000,
        ...settings,
    });
}

function getNotificationType(type: TypeNotification) {
    switch (type) {
        case TypeNotification.Info:
            return "info";
        case TypeNotification.Success:
            return "success";
        case TypeNotification.Error:
            return "error";
        case TypeNotification.Warn:
            return "warn";
    }
}

Vue.prototype.$notifySuccess = (text: Text, title: Text = Boot.$t("__.ts.sucesso")) => {
    BaseNotification({ title: title as string, text: text as string });
};

Vue.prototype.$notifyWarn = (text: Text, title: Text = Boot.$t("__.ts.atencao")) => {
    BaseNotification({ title: title as string, text: text as string, type: "warn" });
};

Vue.prototype.$notifyError = (text: Text, title: Text = Boot.$t("__.ts.erro")) => {
    BaseNotification({ title: title as string, text: text as string, type: "error" });
};

Vue.prototype.$notifyInfo = (text: Text, title: Text = Boot.$t("__.ts.atencao")) => {
    BaseNotification({ title: title as string, text: text as string, type: "info" });
};

Vue.prototype.$notifyByType = (text: Text, title: Text, type: TypeNotification) => {
    BaseNotification({ title: title as string, text: text as string, type: getNotificationType(type) });
};

Vue.prototype.$notifyOtherUser = async (
    userId: number,
    text: Text,
    title: Text = Boot.$t("__.ts.sucesso"),
    type: TypeNotification = TypeNotification.Info,
) => {
    const model = new NotificationModel();
    model.text = text.toString();
    model.title = title.toString();
    model.usuarioDestinoId = userId;
    model.type = type;
    await notificationService.insert(model);
};
