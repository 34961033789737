var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "crud crud-edit" },
    [
      _c("actionBarComponent", {
        attrs: { title: _vm.$t("__.Crud.grupo.edit_vue_html.grupoEdit") },
        on: { onSave: _vm.save },
      }),
      _vm._v(" "),
      _c(
        "form",
        {
          attrs: { novalidate: "novalidate" },
          on: {
            submit: function ($event) {
              $event.preventDefault()
              _vm.save()
            },
          },
        },
        [
          _c("fieldset", [
            _c("div", { attrs: { slot: "rows" }, slot: "rows" }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-sm-6" }, [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "tooltip",
                          rawName: "v-tooltip",
                          value: _vm.errors.first("descricao"),
                          expression: "errors.first('descricao')",
                        },
                      ],
                      staticClass: "form-group",
                    },
                    [
                      _c(
                        "label",
                        {
                          staticClass: "control-label",
                          attrs: {
                            for: "descricao",
                            title: _vm.$t("__.obrigatorio"),
                          },
                        },
                        [
                          _c("span", { staticStyle: { color: "red" } }, [
                            _vm._v("*"),
                          ]),
                          _vm._v(
                            "\n                                " +
                              _vm._s(
                                _vm.$t("__.Crud.grupo.edit_vue_html.descr")
                              ) +
                              "\n                            "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          { name: "focus", rawName: "v-focus" },
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.model.descricao,
                            expression: "model.descricao",
                          },
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required",
                            expression: "'required'",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          autocomplete: "off",
                          type: "text",
                          id: "descricao",
                          name: "descricao",
                          disabled: !_vm.permitiralteracao,
                        },
                        domProps: { value: _vm.model.descricao },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.model,
                              "descricao",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-sm-6" }, [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "tooltip",
                          rawName: "v-tooltip",
                          value: _vm.errors.first("ativo"),
                          expression: "errors.first('ativo')",
                        },
                      ],
                      staticClass: "form-group",
                    },
                    [
                      _c(
                        "label",
                        {
                          staticClass: "control-label",
                          attrs: { for: "ativo" },
                        },
                        [
                          _vm._v(
                            "\n                                " +
                              _vm._s(
                                _vm.$t("__.Crud.grupo.edit_vue_html.ativo")
                              ) +
                              "\n                            "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("checkbox-component", {
                        attrs: {
                          id: "ativo",
                          name: "ativo",
                          disabled: !_vm.permitiralteracao,
                        },
                        model: {
                          value: _vm.model.ativo,
                          callback: function ($$v) {
                            _vm.$set(_vm.model, "ativo", $$v)
                          },
                          expression: "model.ativo",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("fieldset", [
            _c(
              "div",
              { attrs: { slot: "rows" }, slot: "rows" },
              [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-sm-12" }, [
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "acaoId" },
                          },
                          [
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t("__.Crud.grupo.edit_vue_html.acoes")
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("combo-component", {
                          attrs: {
                            id: "acaoId",
                            name: "acaoId",
                            data: _vm.acoesOptions,
                            searchable: true,
                            refresh: _vm.loadAcoes,
                            disabled: !_vm.permitiralteracao,
                          },
                          model: {
                            value: _vm.acaoId,
                            callback: function ($$v) {
                              _vm.acaoId = $$v
                            },
                            expression: "acaoId",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _vm.permitiralteracao
                  ? _c("buttonIncluirComponent", {
                      on: { click: _vm.incluirTelaAcao },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _c("grid-component", {
                  attrs: {
                    columns: _vm.gridColumnsTelaAcao,
                    data: _vm.model.grupoTelaAcoes,
                    "show-edit-item": false,
                    "show-table-foot": false,
                    "show-table-head": false,
                    showActionColumn: _vm.permitiralteracao,
                  },
                  on: {
                    "change-sort": _vm.onChangeSortAcoes,
                    "remove-item": _vm.onRemoveTelaAcao,
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("fieldset", [
            _c(
              "div",
              { attrs: { slot: "rows" }, slot: "rows" },
              [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-sm-12" }, [
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "usuarioId" },
                          },
                          [
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t("__.Crud.grupo.edit_vue_html.users")
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("combo-component", {
                          attrs: {
                            id: "usuarioId",
                            name: "usuarioId",
                            data: _vm.usuariosOptions,
                            searchable: true,
                            refresh: _vm.loadUsuarios,
                          },
                          model: {
                            value: _vm.usuarioId,
                            callback: function ($$v) {
                              _vm.usuarioId = $$v
                            },
                            expression: "usuarioId",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("buttonIncluirComponent", {
                  on: {
                    click: function ($event) {
                      _vm.incluirUsuario()
                    },
                  },
                }),
                _vm._v(" "),
                _c("grid-component", {
                  attrs: {
                    data: _vm.model.grupoUsuarios,
                    columns: _vm.gridColumnsUsuario,
                    "show-table-head": false,
                    "show-table-foot": false,
                    "show-edit-item": false,
                  },
                  on: { "remove-item": _vm.onRemoveUsuario },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("fieldset", [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-sm-12 newbtn" }, [
                _c("div", { staticClass: "form-group" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-success",
                      attrs: { type: "submit" },
                    },
                    [
                      _c("i", {
                        staticClass: "fa fa-save",
                        attrs: { "aria-hidden": "true" },
                      }),
                      _vm._v(
                        "\n                            " +
                          _vm._s(_vm.$t("__.Crud.salvar")) +
                          "\n                        "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-secondary",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          _vm.load()
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n                            " +
                          _vm._s(_vm.$t("__.Crud.limpar")) +
                          "\n                        "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-secondary",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          _vm.cancel()
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n                            " +
                          _vm._s(_vm.$t("__.Crud.voltar")) +
                          "\n                        "
                      ),
                    ]
                  ),
                ]),
              ]),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }