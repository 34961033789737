import nfetch from "@/utils/events/nfetch";

export default class LoginService {
    public async login(email: string, senha: string): Promise<Response> {
        return nfetch(`/Login?login=${encodeURIComponent(email)}&senha=${encodeURIComponent(senha)}`, {
            method: "post",
        });
    }

    public async esqueceuSenha(email: string): Promise<Response> {
        return nfetch(`/EsqueceuSenha?email=${email}`, { method: "post" });
    }
}
