import Vue from "vue";

import checkboxComponent from "@/components/child/form/checkbox.vue";
import comboComponent from "@/components/child/form/combo.vue";
import dataTooltipComponent from "@/components/child/form/datatooltip.vue";
import dateTimePickerComponent from "@/components/child/form/datetimepicker.vue";
import decimalComponent from "@/components/child/form/decimal.vue";
import decimalComSinalCustomComponent from "@/components/child/form/decimalComSinalCustom.vue";
import fieldsetComponent from "@/components/child/form/fieldset.vue";
import { Component, Prop } from "@/decorators";
import OperacaoMatematica from "@/models/enum/operacaoMatematica";
import TiposItemManipulacao from "@/models/enum/tiposItemManipulacao";
import ManipulacaoOrdemItemModel from "@/models/manipulacaoOrdemItemModel";
import ProdutoEstoqueConferenciaLoteModel from "@/models/produtoEstoqueConferenciaLoteModel";
import ProdutoEstoqueConferenciaModel from "@/models/produtoEstoqueConferenciaModel";
import arithmeticHelper from "@/utils/common/arithmeticHelper";

import "./loteItem.scss";

@Component({
    components: {
        comboComponent,
        dataTooltipComponent,
        checkboxComponent,
        decimalComponent,
        decimalComSinalCustomComponent,
        fieldsetComponent,
        dateTimePickerComponent,
    },
})
export default class LoteItemComponent extends Vue {
    private model: ManipulacaoOrdemItemModel[] = null;
    private produtosParaVerificacao: ProdutoEstoqueConferenciaModel[] = null;
    private produtosVerificacaoOriginal: ProdutoEstoqueConferenciaModel[] = null;
    private produtoLoteOptions: Object[][] = [];
    private movimentacaoEstoqueId = 0;
    private carregando = false;

    private produtoEstoqueConferenciaModel = new ProdutoEstoqueConferenciaModel();

    private statusEstoqueOptions: Array<Object> = [
        { text: "Insuficiente", value: 0 },
        { text: "Baixo", value: 1 },
        { text: "Suficiente", value: 2 },
    ];

    @Prop({ type: Array, required: true }) unidadeMedidaOp;
    @Prop({ type: Number, required: true }) volumeTotal: number;

    public clear() {
        this.model = new Array<ManipulacaoOrdemItemModel>();
        this.produtosParaVerificacao = new Array<ProdutoEstoqueConferenciaModel>();
        this.produtoLoteOptions.splice(0);
    }

    //array pq pode ter filhos ou associados
    public async loadModel(
        itens: ManipulacaoOrdemItemModel[],
        movimentoEstoqueId: number,
        manipulacaoOrdemId: number,
        skipComparacao = false,
    ) {
        await this.loadModelInternal(itens, movimentoEstoqueId, manipulacaoOrdemId, skipComparacao).withLoading();
    }

    private async loadModelInternal(
        itens: ManipulacaoOrdemItemModel[],
        movimentoEstoqueId: number,
        manipulacaoOrdemId: number,
        skipComparacao = false,
    ) {
        this.carregando = true;
        this.clear();

        if (!skipComparacao) {
            itens.forEach(item => {
                const mItem = new ManipulacaoOrdemItemModel();
                mItem.updateFrom(item);
                this.model.push(item);
            });
        }

        this.movimentacaoEstoqueId = movimentoEstoqueId;

        if (this.movimentacaoEstoqueId > 0) {
            await this.verificaProdutosLotes(this.movimentacaoEstoqueId, manipulacaoOrdemId, skipComparacao);
        }

        setTimeout(() => {
            this.carregando = false;
        }, 100);
    }

    private async verificaProdutosLotes(
        movimentoEstoqueId: number,
        manipulacaoOrdemId: number,
        skipComparacao = false,
    ) {
        if (!skipComparacao) {
            this.model
                .filter(p => p.tipoItem != TiposItemManipulacao.FormulaPadrao)
                .forEach(item => {
                    const produtoEstoqueConferencia = new ProdutoEstoqueConferenciaModel();
                    produtoEstoqueConferencia.id = item.produtoId;
                    produtoEstoqueConferencia.quantidade = item.quantidadePesagem;
                    produtoEstoqueConferencia.unidadeMedidaManipulacaoId = item.unidadeMedidaManipulacaoId;
                    produtoEstoqueConferencia.unidadeMedidaManipulacaoSigla = item.unidadeMedidaManipulacaoDecricao;

                    this.produtosParaVerificacao.push(produtoEstoqueConferencia);
                });
        }

        if (this.produtosParaVerificacao.length > 0 || skipComparacao) {
            const produtosParaConferencia =
                await this.produtoEstoqueConferenciaModel.GetProdutosEstoquesMovimentosVerificados(
                    movimentoEstoqueId,
                    manipulacaoOrdemId,
                );

            if (!skipComparacao) {
                if (produtosParaConferencia == null || produtosParaConferencia.length == 0) {
                    return this.close();
                } else if (this.produtosParaVerificacao.length != produtosParaConferencia.length) {
                    await this.msgErroDivergencias();
                    return this.close();
                } else {
                    for (const produtoConferencia of produtosParaConferencia) {
                        const produtos = this.produtosParaVerificacao.filter(p => p.id == produtoConferencia.id);

                        if (produtos.length == 0) {
                            await this.msgErroDivergencias();
                            return this.close();
                        } else {
                            if (
                                !produtos.some(
                                    p =>
                                        p.quantidade == produtoConferencia.quantidade ||
                                        p.unidadeMedidaManipulacaoSigla ==
                                            produtoConferencia.unidadeMedidaManipulacaoSigla,
                                )
                            ) {
                                await this.msgErroDivergencias();
                                return this.close();
                            }
                        }
                    }
                }
            }

            this.produtosParaVerificacao = produtosParaConferencia;

            this.produtosVerificacaoOriginal = new Array<ProdutoEstoqueConferenciaModel>();

            produtosParaConferencia.forEach(item => {
                const produtoVerificado = new ProdutoEstoqueConferenciaModel();
                produtoVerificado.updateFrom(item, ["produtoLotes"]);
                //copia lotes
                item.produtoLotes.forEach(lote => {
                    const loteVerificado = new ProdutoEstoqueConferenciaLoteModel();
                    loteVerificado.updateFrom(lote);
                    produtoVerificado.produtoLotes.push(loteVerificado);
                });

                this.produtosVerificacaoOriginal.push(produtoVerificado);
            });

            const lotesComMovimentacao = l => l.movimentacaoEstoqueItemId;

            for (const produto of this.produtosParaVerificacao) {
                const lote = produto.produtoLotes.map(item => ({
                    value: item.id,
                    text: item.descricao,
                    quantidadeAtual: item.quantidadeAtual,
                    quantidadeBaixa: item.quantidadeBaixa,
                    validade: item.dataValidade,
                    status: item.statusEstoque,
                }));

                this.produtoLoteOptions.push(lote);

                produto.produtoLotes = produto.produtoLotes.filter(lotesComMovimentacao);
            }

            //inicia lotes dos que nao tem nenhum
            const produtosSemLote = this.produtosParaVerificacao.filter(
                p => !p.produtoLotes.some(lotesComMovimentacao),
            );

            for (const produto of produtosSemLote) {
                this.criaBaseProdutoLote(produto.id, produto.sequencial);
            }
        }
    }

    private async msgErroDivergencias() {
        await this.$showError(this.$t("__.ts.atencao"), this.$t("__.ts.houveAltProdsManiSalv"));
    }

    private close() {
        this.$emit("close");
    }

    private onRemover(indexProd: number, indexLote: number) {
        this.produtosParaVerificacao[indexProd].produtoLotes.splice(indexLote, 1);

        if (this.produtosParaVerificacao[indexProd].produtoLotes.length == 0) {
            this.criaBaseProdutoLote(
                this.produtosParaVerificacao[indexProd].id,
                this.produtosParaVerificacao[indexProd].sequencial,
            );
        }
    }

    private criaBaseProdutoLote(id: number, produtoSequencial: number) {
        const lote = new ProdutoEstoqueConferenciaLoteModel();
        lote.id = null;
        lote.quantidadeAtual = 0;
        lote.quantidadeBaixa = 0;
        lote.statusEstoque = 0;
        lote.produtoSequencial = produtoSequencial;
        this.produtosParaVerificacao
            .filter(p => p.id == id && p.sequencial == produtoSequencial)[0]
            .produtoLotes.push(lote);
    }

    private getTitle(produto: ProdutoEstoqueConferenciaModel) {
        const quantidadeConvertida =
            produto.conversaoOperacao == OperacaoMatematica.Dividir
                ? produto.quantidade / produto.conversaoValor
                : produto.quantidade * produto.conversaoValor;
        const signal = this.getSignalQuantidade(produto.unidadeMedidaManipulacaoId);
        const controlaEstoque = produto.ignorarControleEstoque ? " (Produto Não Controla Estoque)" : "";

        return `Produto ${produto.descricao} - ${quantidadeConvertida} ${signal}${controlaEstoque}`;
    }

    private getSignalQuantidade(unidadeMedidaId: number) {
        if (this.unidadeMedidaOp.length > 0) {
            const unMed = this.unidadeMedidaOp.filter(p => p["value"] == unidadeMedidaId);
            if (unMed.length > 0) {
                if (unMed[0]["sigla"] != null) {
                    return unMed[0]["sigla"];
                } else {
                    return unMed[0]["text"];
                }
            }
        }
        return "";
    }

    private getRestante(index: number) {
        const quantidadeProduto =
            this.produtosParaVerificacao[index].quantidade * this.produtosParaVerificacao[index].indiceConversao;
        const baixas = this.getNumSomaBaixas(index);
        let resto = (quantidadeProduto - baixas).toFixed(4);
        if (quantidadeProduto - baixas < 0) {
            resto = "0.0000";
        }
        return resto + " " + this.getSignalQuantidade(this.produtosParaVerificacao[index].unidadeMedidaEstoqueId);
    }

    private getClassRestante(index: number) {
        const quantidadeProduto =
            this.produtosParaVerificacao[index].quantidade * this.produtosParaVerificacao[index].indiceConversao;
        const baixas = this.getNumSomaBaixas(index);
        const dotPos = baixas.toString().indexOf(".");
        let decimais = 6;
        if (baixas.toString().length > dotPos + 1 && dotPos >= 0)
            decimais = baixas.toString().substr(dotPos + 1, baixas.toString().length - dotPos + 1).length;

        if (parseFloat(quantidadeProduto.toFixed(decimais)) <= baixas) {
            return "";
        }
        return "Red";
    }

    private getExtras(index: number) {
        const quantidadeProduto =
            this.produtosParaVerificacao[index].quantidade * this.produtosParaVerificacao[index].indiceConversao;
        const baixas = arithmeticHelper.round(
            this.getNumSomaBaixas(index) * this.produtosParaVerificacao[index].indiceConversao,
            4,
        );
        let extra = ((quantidadeProduto - baixas) * -1).toFixed(4);
        if (baixas < quantidadeProduto) {
            extra = "0.0000";
        }

        return extra + " " + this.getSignalQuantidade(this.produtosParaVerificacao[index].unidadeMedidaEstoqueId);
    }

    private getColorHeaderRestante(index: number, ignorarControleEstoque: boolean) {
        if (ignorarControleEstoque) {
            return "#eaff00";
        }

        const quantidadeProduto =
            this.produtosParaVerificacao[index].quantidade * this.produtosParaVerificacao[index].indiceConversao;
        const baixas = arithmeticHelper.round(
            this.getNumSomaBaixas(index) * this.produtosParaVerificacao[index].indiceConversao,
            4,
        );

        const dotPos = baixas.toString().indexOf(".");
        let decimais = 6;
        if (baixas.toString().length > dotPos + 1 && dotPos >= 0)
            decimais = baixas.toString().substr(dotPos + 1, baixas.toString().length - dotPos + 1).length;

        if (parseFloat(quantidadeProduto.toFixed(decimais)) == baixas) {
            return "#eaff00";
        } else if (quantidadeProduto < baixas) {
            return "#ffb895";
        } else {
            return "#f98e8e";
        }
    }

    private showExtras(index: number) {
        const quantidadeProduto = arithmeticHelper.round(
            this.produtosParaVerificacao[index].quantidade * this.produtosParaVerificacao[index].indiceConversao,
            4,
        );

        const baixas = arithmeticHelper.round(
            this.getNumSomaBaixas(index) * this.produtosParaVerificacao[index].indiceConversao,
            4,
        );

        return baixas > quantidadeProduto;
    }

    private getSomaBaixas(index: number) {
        return (
            this.getNumSomaBaixas(index).toFixed(4) +
            " " +
            this.getSignalQuantidade(this.produtosParaVerificacao[index].unidadeMedidaEstoqueId)
        );
    }

    private getNumSomaBaixas(index: number) {
        return this.produtosParaVerificacao[index].produtoLotes.reduce((acc, p) => acc + p.quantidadeBaixa, 0);
    }

    private onChangedLoteId(index: number, indexLote: number) {
        if (!this.carregando) {
            const prodLote = this.produtosParaVerificacao[index].produtoLotes[indexLote];
            if (prodLote.id > 0) {
                if (this.produtosParaVerificacao[index].produtoLotes.filter(l => l.id == prodLote.id).length > 1) {
                    setTimeout(() => {
                        this.produtosParaVerificacao[index].produtoLotes[indexLote].id = null;
                    }, 50);

                    this.$showError(this.$t("__.ts.atencao"), this.$t("__.ts.loteJaInfo"));
                } else {
                    const loteOriginal = new ProdutoEstoqueConferenciaLoteModel();
                    loteOriginal.updateFrom(
                        this.produtosVerificacaoOriginal[index].produtoLotes.filter(p => p.id == prodLote.id)[0],
                    );
                    let qtAtual = loteOriginal.quantidadeAtual;
                    const qtConsideraBaixa = loteOriginal.movimentacaoEstoqueItemId > 0;
                    if (qtConsideraBaixa) {
                        qtAtual += loteOriginal.quantidadeBaixa;
                    }
                    prodLote.quantidadeAtual = qtAtual;

                    prodLote.dataValidade = loteOriginal.dataValidade;
                    prodLote.statusEstoque = loteOriginal.statusEstoque;

                    const qtB =
                        this.produtosParaVerificacao[index].quantidade *
                            this.produtosParaVerificacao[index].indiceConversao -
                        arithmeticHelper.round(this.getNumSomaBaixas(index), 4);

                    prodLote.quantidadeBaixa =
                        qtB > 0 && qtB <= prodLote.quantidadeAtual
                            ? qtB
                            : qtB >= prodLote.quantidadeAtual
                            ? prodLote.quantidadeAtual
                            : 0;

                    prodLote.quantidadeAtual -= prodLote.quantidadeBaixa;
                }
            } else {
                prodLote.dataValidade = "";
                prodLote.quantidadeAtual = 0;
                prodLote.statusEstoque = 0;
                prodLote.quantidadeBaixa = 0;
            }
        }
    }

    private onChangedQuantidade(index: number, indexLote: number) {
        const lote = this.produtosParaVerificacao[index].produtoLotes[indexLote];
        let quantidadeBaixa = this.produtosParaVerificacao[index].produtoLotes[indexLote].quantidadeBaixa;

        const loteOriginal = new ProdutoEstoqueConferenciaLoteModel();
        loteOriginal.updateFrom(this.produtosVerificacaoOriginal[index].produtoLotes.filter(p => p.id == lote.id)[0]);

        let quantidadeAtual = loteOriginal.quantidadeAtual;
        const qtConsideraBaixa = loteOriginal.movimentacaoEstoqueItemId > 0;

        if (qtConsideraBaixa) {
            quantidadeAtual += loteOriginal.quantidadeBaixa;
        }

        if (quantidadeBaixa > quantidadeAtual) {
            quantidadeBaixa = quantidadeAtual;
            quantidadeAtual = 0;

            this.$showError(this.$t("__.ts.atencao"), this.$t("__.ts.qtdBaixaDeveMenorAtual"));
        } else {
            quantidadeAtual -= quantidadeBaixa;
        }

        lote.quantidadeAtual = quantidadeAtual;
        lote.quantidadeBaixa = quantidadeBaixa;
    }

    public async atualizarDadosLotes() {
        return await this.atualizarDadosLotesInternal().withLoading();
    }

    private async atualizarDadosLotesInternal() {
        try {
            const isValid = await this.$validator.validateAll();
            if (isValid) {
                const response = await this.$showQuestion(
                    this.$t("__.ts.atencao"),
                    this.$t("__.ts.aoConfAltAlteradasNoEstq"),
                );
                if (response) {
                    const response = await this.produtoEstoqueConferenciaModel.AtualizaDadosLotes(
                        this.produtosParaVerificacao.filter(p => !p.ignorarControleEstoque),
                        this.movimentacaoEstoqueId,
                        this.model.map(p => p.id),
                    );

                    this.$emit("updateProdutoLoteItens", response);

                    return response;
                }
            }
        } catch {}

        return false;
    }
}
