import { mapState } from "vuex";

import comboComponent from "@/components/child/form/combo.vue";
import dateTimePickerComponent from "@/components/child/form/datetimepicker.vue";
import decimalComponent from "@/components/child/form/decimal.vue";
import textareaComponent from "@/components/child/form/textarea.vue";
import searchComboComponent from "@/components/child/searchCombo/search.vue";
import UploadAnexoComponent from "@/components/child/uploadAnexo/uploadAnexo";
import uploadAnexoComponent from "@/components/child/uploadAnexo/uploadAnexo.vue";
import { Component, Watch } from "@/decorators";
import PreLoadPackModel from "@/models/auxiliar/preLoadPackModel";
import CategoriaControleReclamacaoModel from "@/models/categoriaControleReclamacaoModel";
import ControleReclamacaoModel from "@/models/controleReclamacaoModel";
import ControleReclamacaoStatus from "@/models/enum/controleReclamacaoStatus";
import ControleReclamacaoTipo from "@/models/enum/controleReclamacaoTipo";
import EnumExtensions from "@/models/enum/extensions/enumExtensions";
import JustificativaControleReclamacaoModel from "@/models/justificativaControleReclamacaoModel";
import PaginationModel from "@/models/paginationModel";
import ProdutoModel from "@/models/produto/produtoModel";
import ProdutoLoteModel from "@/models/produtoLoteModel";
import VendaModel from "@/models/vendaModel";
import CategoriaControleReclamacaoService from "@/services/categoriaControleReclamacaoService";
import ControleReclamacaoService from "@/services/controleReclamacaoService";
import JustificativaControleReclamacaoService from "@/services/justificativaControleReclamacaoService";
import ProdutoService from "@/services/produto/produtoService";
import ProdutoLoteService from "@/services/produtoLoteService";
import VendaService from "@/services/vendaService";
import { AppState } from "@/store/store";
import { getProdutoCombo, getProdutoLoteCombo } from "@/utils/common/combo/combotext";
import Delay from "@/utils/common/delay";

import EditComponentBase from "../editComponentBase";

@Component({
    components: {
        textareaComponent,
        dateTimePickerComponent,
        uploadAnexoComponent,
        decimalComponent,
        searchComboComponent,
        comboComponent,
    },
    computed: {
        ...mapState({
            preLoadPack: (state: AppState) => state.preLoad.preLoadList,
            loadedList: (state: AppState) => state.preLoad.loadedList,
        }),
    },
})
export default class ControleReclamacaoEditComponent extends EditComponentBase<ControleReclamacaoModel> {
    // State computed props
    preLoadPack: PreLoadPackModel;
    loadedList: boolean;

    private vendaService = new VendaService();
    private uploadAnexoComponent: UploadAnexoComponent = null;

    produtoLoteOptions: Array<Object> = [];
    produtosOptions: Array<Object> = [];

    controleReclamacaoTipoOptions: Array<Object> =
        EnumExtensions.getNamesAndValuesOrderedByValues(ControleReclamacaoTipo);

    statusOtions: Array<Object> = [
        { text: "Aberto", value: ControleReclamacaoStatus.Aberto },
        { text: "Processo", value: ControleReclamacaoStatus.Processo },
        { text: "Finalizado", value: ControleReclamacaoStatus.Finalizado },
    ];

    categoriaReclamacaoOptions: Array<Object> = [];
    justificativaReclamacaoOptions: Array<Object> = [];

    private clear() {
        this.model = new ControleReclamacaoModel();
    }

    private async onChangeVendaCodigo() {
        if (this.model.vendaCodigo) {
            try {
                const venda = await this.vendaService
                    .getByCodigo(this.model.vendaCodigo)
                    .withLoading()
                    .resolveWithJSON<VendaModel>();

                this.model.clienteNome = venda.clienteNome;
                this.model.atendente = venda.nomeVendedor;
                this.model.clienteId = venda.clienteId;
                this.model.vendaId = venda.id;
            } catch {}
        }
    }

    private async onShowUploadAnexo() {
        while (!this.$refs.uploadAnexoComponent) await Delay(50);
        this.uploadAnexoComponent = this.$refs.uploadAnexoComponent as UploadAnexoComponent;
        this.uploadAnexoComponent.show();
    }

    public async onSucessoSave(id?: number) {
        while (!this.$refs.uploadAnexoComponent) await Delay(50);
        this.uploadAnexoComponent = this.$refs.uploadAnexoComponent as UploadAnexoComponent;
        this.uploadAnexoComponent.onSucessoSave(id);
    }

    private async loadProdutos() {
        try {
            const data = await new ProdutoService()
                .combo()
                .withLoading()
                .resolveWithJSON<PaginationModel<ProdutoModel>>();

            this.produtosOptions = data.list.map(item => ({
                ...getProdutoCombo(item),
            }));
        } catch {}
    }

    private async getPreLoadPack() {
        while (!this.loadedList) await Delay(100);

        this.produtosOptions = this.preLoadPack.produtos.map(item => ({
            ...getProdutoCombo(item),
            unidadeMdedidaEstoque: item.unidadeMedidaEstoqueId,
        }));
    }

    @Watch("model.produtoId")
    private async onIdProdutoChanged() {
        try {
            const data = await new ProdutoLoteService()
                .getByProdutoId(this.model.produtoId)
                .withLoading()
                .resolveWithJSON<PaginationModel<ProdutoLoteModel>>();

            this.produtoLoteOptions = data.list.map(item => ({
                ...getProdutoLoteCombo(item),
                text: `${item.descricao} - ${item.fornecedorNome}`,
            }));
        } catch {}
    }

    private async onChangeTipo(tipo: number) {
        this.model = new ControleReclamacaoModel();
        this.model.tipo = tipo;
    }

    private async loadCategorias() {
        try {
            const data = await new CategoriaControleReclamacaoService()
                .combo()
                .withLoading()
                .resolveWithJSON<PaginationModel<CategoriaControleReclamacaoModel>>();
            this.categoriaReclamacaoOptions = data.list.map(item => ({
                value: item.id,
                text: item.descricao,
            }));
        } catch {}
    }

    private async loadJustificativas() {
        try {
            const data = await new JustificativaControleReclamacaoService()
                .combo()
                .withLoading()
                .resolveWithJSON<PaginationModel<JustificativaControleReclamacaoModel>>();
            this.justificativaReclamacaoOptions = data.list.map(item => ({
                value: item.id,
                text: item.descricao,
            }));
        } catch {}
    }

    private async beforeMount() {
        await this.getPreLoadPack();
        await this.setProps(new ControleReclamacaoService(), "controle-reclamacao", new ControleReclamacaoModel());
        await this.loadCategorias();
        await this.loadJustificativas();
    }
}
