import Vue from "vue";
import { mapGetters } from "vuex";

import comboComponent from "@/components/child/form/combo.vue";
import dateTimePickerComponent from "@/components/child/form/datetimepicker.vue";
import fieldsetComponent from "@/components/child/form/fieldset.vue";
import moedaComponent from "@/components/child/form/moeda.vue";
import textareaComponent from "@/components/child/form/textarea.vue";
import { Component, Prop, Watch } from "@/decorators";
import ConfiguracaoFranquiaModel from "@/models/configuracaoFranquia/configuracaoFranquiaModel";
import Configuracoes from "@/models/enum/configuracao/configuracoes";
import Especie from "@/models/enum/especiePagamento";
import Bandeira from "@/models/financeiro/bandeiraModel";
import ContaCorrenteModel from "@/models/financeiro/contaCorrenteModel";
import ContaReceberRecebimentoModel from "@/models/financeiro/contaReceberRecebimentoModel";
import FormaRecebimentoModel from "@/models/financeiro/formaRecebimentoModel";
import MetodoPagemento from "@/models/financeiro/metodoPagamentoModel";
import PaginationModel from "@/models/paginationModel";
import BandeiraService from "@/services/financeiro/bandeiraService";
import ContaCorrenteService from "@/services/financeiro/contaCorrenteService";
import FormaRecebimentoService from "@/services/financeiro/formaRecebimentoService";
import MetodoPagementoService from "@/services/financeiro/metodoPagamentoService";
import MovimentacaoCaixaService from "@/services/financeiro/movimentacaoCaixaService";
import { Getters } from "@/store/store";
import "./recebimentoconta.scss";

@Component({
    components: {
        textareaComponent,
        comboComponent,
        fieldsetComponent,
        moedaComponent,
        dateTimePickerComponent,
    },
    computed: {
        ...mapGetters(["GET_CONFIG_FRANQUIA"] as Getters),
    },
})
export default class RecebimentoContaComponent extends Vue {
    // State computed props
    GET_CONFIG_FRANQUIA: (configuracao: Configuracoes) => ConfiguracaoFranquiaModel;

    model = new ContaReceberRecebimentoModel();
    jurosValorAnterior = 0;
    descontoValorAnterior = 0;
    valorAbater = 0;
    saldoabater = 0;
    idCaixaSugestao = 0;
    updateMode = false;
    nsuObrigatorio = false;

    contaCorrenteOptions: Array<Object> = [];
    formaRecebimentoOptions: Array<Object> = [];
    formaRecebimentoOptionsFiltrado: Array<Object> = [];

    @Prop({ type: Number, default: 0 }) operadoraCartoesId: number;
    @Prop({ type: Number, default: 0 }) percentualTarifa: number;

    constructor() {
        super();

        this.model.dataMovimento = new Date();
    }

    public clear(_saldoReceber: number) {
        const saldoReceber = parseFloat(_saldoReceber.toFixed(2));
        this.model = new ContaReceberRecebimentoModel();
        this.updateMode = true;
        this.$set(this.model, "dataMovimento", new Date());
        this.$set(this.model, "juros", 0);
        this.$set(this.model, "desconto", 0);

        if (this.percentualTarifa > 0) {
            const tarifa = parseFloat(((this.percentualTarifa / 100) * this.saldoabater).toFixed(2));
            this.$set(this.model, "tarifa", tarifa);
        } else {
            this.$set(this.model, "tarifa", 0);
        }

        this.$set(this.model, "valor", saldoReceber - this.model.tarifa);

        if (this.idCaixaSugestao > 0 && this.operadoraCartoesId == null) {
            this.model.contaCorrenteId = this.idCaixaSugestao;
        }

        this.model.contabilizar = true;
        this.saldoabater = saldoReceber;
        this.valorAbater = saldoReceber;
        this.jurosValorAnterior = 0;
        this.descontoValorAnterior = 0;

        this.updateMode = false;
    }

    bandeira = -1;
    bandeiraOptions: Array<Object> = [];

    especie = -1;
    especiesOptions: Array<Object> = [
        { text: "Dinheiro", value: Especie.Dinheiro },
        { text: "Cheque", value: Especie.Cheque },
        { text: "Boleto", value: Especie.Boleto },
        { text: "Cartão de Débito", value: Especie.CartaoDebito },
        { text: "Cartão de Crédito", value: Especie.CartaoCredito },
        { text: "Transferência", value: Especie.Transferencia },
        { text: "Pix", value: Especie.Pix },
        { text: "Convênio", value: Especie.Convenio },
        { text: "Transferência Bancária", value: Especie.TransferenciaBancaria },
        { text: "Outros", value: Especie.Outros },
    ];

    metodoParcelamentoId = -1;

    metodosPagamentosOptions: Array<Object> = [];
    metodosPagamentosOptionsFiltrado: Array<Object> = [];

    percentualJurosMetodo = 0;
    percentualDescontoMetodo = 0;

    disableRecebimento = false;

    public enable(enable: boolean) {
        this.disableRecebimento = !enable;
    }

    private async loadMetodoPagamentos() {
        try {
            const data = await new MetodoPagementoService().combo().resolveWithJSON<PaginationModel<MetodoPagemento>>();

            this.metodosPagamentosOptions = data.list.map(item => ({
                value: item.id,
                text: item.descricao,
                numParcelas: item.numeroParcelas,
                primeiroIntervalo: item.primeiroIntervalo,
                intervalo: item.intervalo,
            }));

            this.metodosPagamentosOptionsFiltrado = this.metodosPagamentosOptions;
        } catch {}
    }

    private async loadBandeiras() {
        try {
            const data = await new BandeiraService().combo().resolveWithJSON<PaginationModel<Bandeira>>();

            this.bandeiraOptions = data.list.map(item => ({ value: item.id, text: item.descricao }));
        } catch {}
    }

    private async loadContasCorrentes() {
        try {
            const data = await new ContaCorrenteService()
                .combo()
                .resolveWithJSON<PaginationModel<ContaCorrenteModel>>();
            this.contaCorrenteOptions = data.list.map(item => ({ value: item.id, text: item.descricao }));
        } catch {}
    }

    private async loadFormasRecebimento() {
        try {
            const data = await new FormaRecebimentoService()
                .comboByEspecie()
                .resolveWithJSON<PaginationModel<FormaRecebimentoModel>>();

            this.formaRecebimentoOptions = data.list.map(item => ({
                value: item.id,
                text: item.descricao,
                especie: item.especie,
                juros: item.juros,
                desconto: item.desconto,
                bandeira: item.bandeiraId,
                metodosXForma: item.metodoPagamentoXForma,
                bandeiraXFormaPagamento: item.bandeiraXFormaPagamento,
            }));
            this.formaRecebimentoOptionsFiltrado = this.formaRecebimentoOptions;
            this.filtrarFormas();
        } catch {}
    }

    private filtrarFormas() {
        if (this.especie >= 0) {
            this.formaRecebimentoOptionsFiltrado = this.formaRecebimentoOptions.filter(
                p => p["especie"] == this.especie,
            );
            if (this.formaRecebimentoOptionsFiltrado.length == 1) {
                this.model.formaPagamentoId = this.formaRecebimentoOptionsFiltrado[0]["value"];
            }
        }
        if ((this.especie == Especie.CartaoDebito || this.especie == Especie.CartaoCredito) && this.bandeira > 0) {
            this.formaRecebimentoOptionsFiltrado = this.formaRecebimentoOptions.filter(
                p =>
                    p["bandeiraXFormaPagamento"].filter(b => b.bandeiraId == this.bandeira).length > 0 &&
                    p["especie"] == this.especie,
            );
        }
    }

    private limparFiltroFormas() {
        this.formaRecebimentoOptionsFiltrado = this.formaRecebimentoOptions;
    }

    private async incluirRecebimento() {
        try {
            const isValid = await this.$validator.validateAll();
            if (isValid) {
                this.$emit("inclusao-recebimento", this.getModel());
            }
        } catch {}
    }

    private salvarValorAnteriorJuros() {
        this.jurosValorAnterior = +this.model.juros;
    }

    private salvarValorAnteriorDesconto() {
        this.descontoValorAnterior = +this.model.desconto;
    }

    private getModel() {
        const contaCorrenteElement = this.$el.querySelector("#contaCorrenteId") as HTMLSelectElement;
        const formaRecebimentoElement = this.$el.querySelector("#formaPagamentoId") as HTMLSelectElement;

        if (
            this.model.autorizacao != null &&
            this.model.autorizacao != "" &&
            (this.especie == Especie.CartaoCredito || this.especie == Especie.CartaoDebito)
        )
            this.model.contabilizar = false;
        else this.model.contabilizar = true;

        const model = new ContaReceberRecebimentoModel();
        model.updateFrom(this.model);
        model.valor = parseFloat(model.valor.toFixed(2));
        model.juros = parseFloat(model.juros.toFixed(2));
        model.desconto = parseFloat(model.desconto.toFixed(2));
        model.tarifa = parseFloat(model.tarifa.toFixed(2));
        model.lancamentoId = this.model.lancamentoId;
        model.dataLancamento = new Date();
        model.contaCorrenteDescricao = contaCorrenteElement.innerText;
        model.formaPagamentoDescricao = formaRecebimentoElement.innerText;
        if (this.bandeira > 0) {
            model.bandeiraId = this.bandeira;
        }

        if (this.model.formaPagamentoId > 0 && this.metodoParcelamentoId > 0) {
            const formaPagamento = this.formaRecebimentoOptionsFiltrado.find(
                p => p["value"] == this.model.formaPagamentoId,
            );

            model.metodoPagamentoXFormaId = formaPagamento["metodosXForma"].find(
                p => p.metodoPagamentoId == this.metodoParcelamentoId,
            ).id;
        }
        return model;
    }

    //@ts-ignore
    @Watch("model.valor")
    private onChangedValor(newValue, oldValue) {
        if (this.updateMode) {
            return;
        }

        if (
            newValue !=
            parseFloat((this.valorAbater + this.model.juros - this.model.desconto - this.model.tarifa).toFixed(2))
        ) {
            if (newValue < oldValue) {
                this.model.desconto += oldValue - newValue;
            } else if (newValue > oldValue) {
                this.model.juros += newValue - oldValue;
            }
        }
    }

    @Watch("valorAbater")
    private onValorAbater() {
        if (this.valorAbater > this.saldoabater && this.model.id == 0) {
            this.$setErrorField(
                "valorAbater",
                this.$t("__.ts.vlrNaoPodeSerMaior") + this.saldoabater.toString() + ").",
            );
        } else {
            this.$setErrorField("valorAbater", "");
        }

        const desconto = this.model.desconto;
        let juros = this.model.juros;
        let tarifa = this.model.tarifa;

        if (this.percentualDescontoMetodo > 0) {
            const desconto = parseFloat(((this.valorAbater * this.percentualDescontoMetodo) / 100).toFixed(2));
            this.model.desconto = desconto;
        }
        if (this.percentualJurosMetodo > 0) {
            juros = parseFloat(((this.valorAbater * this.percentualJurosMetodo) / 100).toFixed(2));
            this.model.juros = juros;
        }
        if (this.percentualTarifa > 0) {
            tarifa = parseFloat(((this.valorAbater * this.percentualTarifa) / 100).toFixed(2));
            this.model.tarifa = tarifa;
        }

        this.model.valor = parseFloat((this.valorAbater - desconto + juros - tarifa).toFixed(2));
    }

    //@ts-ignore
    @Watch("model.juros")
    private onJurosChanged(newValue) {
        if (this.updateMode) {
            return;
        }

        if (newValue != parseFloat(((this.valorAbater * this.percentualJurosMetodo) / 100).toFixed(2))) {
            this.percentualJurosMetodo = (newValue * 100) / this.valorAbater;
            this.model.valor = parseFloat(
                (
                    this.valorAbater +
                    parseFloat(((this.valorAbater * this.percentualJurosMetodo) / 100).toFixed(2)) -
                    this.model.desconto -
                    this.model.tarifa
                ).toFixed(2),
            );
        }
    }

    //@ts-ignore
    @Watch("model.desconto")
    private onDescontoChanged(newValue) {
        if (this.updateMode) return;

        if (newValue >= parseFloat((this.model.juros + this.valorAbater - this.model.tarifa).toFixed(2))) {
            this.$setErrorField("desconto", this.$t("__.ts.vlrDescMuitoAlto"));
        } else {
            this.$setErrorField("desconto", "");
        }

        if (newValue != parseFloat(((this.valorAbater * this.percentualDescontoMetodo) / 100).toFixed(2))) {
            this.percentualDescontoMetodo = (newValue * 100) / this.valorAbater;
            this.model.valor = parseFloat(
                (
                    this.valorAbater -
                    parseFloat(((this.valorAbater * this.percentualDescontoMetodo) / 100).toFixed(2)) +
                    this.model.juros -
                    this.model.tarifa
                ).toFixed(2),
            );
        }
    }

    //@ts-ignore
    @Watch("especie")
    private onChangeEspecie() {
        if (this.especie != Especie.CartaoCredito && this.especie != Especie.CartaoDebito) {
            this.bandeira = -1;
        }

        this.filtrarFormas();
    }

    //@ts-ignore
    @Watch("bandeira")
    private onChangeBandeira() {
        this.filtrarFormas();
    }

    //@ts-ignore
    @Watch("model.formaPagamentoId")
    private onChangeFormaPagamentoId() {
        if (this.model.formaPagamentoId != null && this.model.formaPagamentoId > 0) {
            if (this.especie != Especie.CartaoCredito && this.especie != Especie.CartaoDebito) {
                this.buscaPercentuaisJurosMulta();
            } else {
                const parcelamentoAnterior = this.metodoParcelamentoId;

                if (!(this.bandeira > 0)) {
                    this.bandeira = this.formaRecebimentoOptionsFiltrado.filter(
                        p => p["value"] == this.model.formaPagamentoId,
                    )[0]["bandeiraXFormaPagamento"][0]["bandeiraId"];
                }

                this.metodosPagamentosOptionsFiltrado = this.metodosPagamentosOptions.filter(p =>
                    this.filterMetodoFunc(p["value"]),
                );

                if (this.metodosPagamentosOptionsFiltrado.length == 1) {
                    this.metodoParcelamentoId = this.metodosPagamentosOptionsFiltrado[0]["value"];
                }

                if (parcelamentoAnterior == this.metodoParcelamentoId) {
                    this.buscaPercentuaisJurosMulta();
                }
            }
        } else {
            if (this.percentualJurosMetodo > 0) {
                this.percentualJurosMetodo = 0;
                this.model.juros = 0;
            }
            if (this.percentualDescontoMetodo > 0) {
                this.percentualDescontoMetodo = 0;
                this.model.desconto = 0;
            }
            this.model.valor = parseFloat((this.valorAbater - this.model.tarifa).toFixed(2));
            this.metodoParcelamentoId = -1;
        }
    }

    @Watch("metodoParcelamentoId")
    private onChangeMetodoPagamentoId() {
        this.buscaPercentuaisJurosMulta();
    }

    private buscaPercentuaisJurosMulta() {
        if (this.metodoParcelamentoId > 0) {
            const formaPagamento = this.formaRecebimentoOptionsFiltrado.find(
                p => p["value"] == this.model.formaPagamentoId,
            );
            const metodos = formaPagamento["metodosXForma"].find(p => p.metodoPagamentoId == this.metodoParcelamentoId);

            this.percentualDescontoMetodo = metodos["desconto"];
            this.percentualJurosMetodo = metodos["juros"];

            if (this.percentualDescontoMetodo > 0) {
                this.model.desconto = parseFloat(((this.valorAbater * this.percentualDescontoMetodo) / 100).toFixed(2));
            }
            if (this.percentualJurosMetodo > 0) {
                this.model.juros = parseFloat(((this.valorAbater * this.percentualJurosMetodo) / 100).toFixed(2));
            }

            this.model.valor = parseFloat(
                (this.valorAbater - this.model.desconto + this.model.juros - this.model.tarifa).toFixed(2),
            );
        } else {
            if (
                this.especie != Especie.CartaoCredito &&
                this.especie != Especie.CartaoDebito &&
                this.model.formaPagamentoId > 0
            ) {
                let juros = this.formaRecebimentoOptionsFiltrado.filter(
                    p => p["value"] == this.model.formaPagamentoId,
                )[0]["juros"];
                let desconto = this.formaRecebimentoOptionsFiltrado.filter(
                    p => p["value"] == this.model.formaPagamentoId,
                )[0]["desconto"];

                if (!juros) juros = 0;
                if (!desconto) desconto = 0;

                this.percentualJurosMetodo = juros;
                this.model.juros = parseFloat(((this.valorAbater * this.percentualJurosMetodo) / 100).toFixed(2));

                this.percentualDescontoMetodo = desconto;
                this.model.desconto = parseFloat(((this.valorAbater * this.percentualDescontoMetodo) / 100).toFixed(2));
            } else {
                if (this.percentualJurosMetodo > 0) {
                    this.percentualJurosMetodo = 0;
                    this.model.juros = 0;
                }
                if (this.percentualDescontoMetodo > 0) {
                    this.percentualDescontoMetodo = 0;
                    this.model.desconto = 0;
                }
            }
            this.model.valor = parseFloat(
                (this.valorAbater - this.model.desconto + this.model.juros - this.model.tarifa).toFixed(2),
            );
        }
    }

    private filterMetodoFunc(id) {
        const metodos = this.formaRecebimentoOptionsFiltrado.filter(p => p["value"] == this.model.formaPagamentoId)[0][
            "metodosXForma"
        ];
        return metodos.filter(p => p.metodoPagamentoId == id).length > 0;
    }

    private alertDesfazer(text: string) {
        this.$showWarning(this.$t("__.ts.opNaoPermitida"), text);
    }

    private async loadIdCaixaSegestao() {
        try {
            const data = await new MovimentacaoCaixaService().getIdCaixaSessaoAberta().resolveWithJSON<number>();
            this.idCaixaSugestao = data;
        } catch {
            this.idCaixaSugestao = 0;
        }
    }

    private mounted() {
        this.percentualDescontoMetodo = 0;
        this.percentualJurosMetodo = 0;
        this.valorAbater = 0;
        this.nsuObrigatorio = this.GET_CONFIG_FRANQUIA(Configuracoes.NsuObrigatorioFaturamentoCartao).verdadeiro;

        Promise.all([
            this.loadIdCaixaSegestao(),
            this.loadContasCorrentes(),
            this.loadFormasRecebimento(),
            this.loadBandeiras(),
            this.loadMetodoPagamentos(),
        ]).withLoading();
    }
}
