import BeneficioModel from "../models/beneficio/beneficioModel";
import nfetch from "../utils/events/nfetch";

import { IService } from "./base/iService";

export default class BeneficioService extends IService<BeneficioModel> {
    constructor() {
        super("Beneficio");
    }
    public GetFromUsuarioLogado(): Promise<Response> {
        return nfetch(`/${this.controllerName}/GetFromUsuarioLogado?`, {
            credentials: "same-origin"
        });
    }
    public GetFromCliente(clienteId): Promise<Response> {
        return nfetch(`/${this.controllerName}/GetFromCliente?clienteId=${clienteId}`, {
            credentials: "same-origin"
        });
    }
}