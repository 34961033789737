import BaseModel from "./../base/baseModel";
import TiposMovimentacao from "./../enum/tiposMovimentacao";

export default class ContaGerencialModel extends BaseModel {
    id?: number = null;
    descricao: string = null;
    classificacaoId = 0;
    classificacaoDescricao: string = null;
    tipoMovimentacao: TiposMovimentacao = null;
    ativo = false;
}
