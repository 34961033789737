import BaseModel from "../../../../base/baseModel";

export default class EnderecoNfeModel extends BaseModel {
    cep: string = null;
    endereco: string = null;
    bairro: string = null;
    numero: number = null;
    complemento: string = null;
    cidadeId: number = null;
    cidadeNome: string = null;
    uf: string = null;
    paisId: number = null;
    paisNome: string = null;
    telefone: string = null;
}
