export default class WhatsAppService {
    private controllerName = "Sender";

    public async enviarMensagemTexto(
        numeroDestino: string,
        mensagem: string,
        mensagemRespondidaId: string = null,
    ): Promise<Response> {
        const tokenAtendimento = localStorage.getItem("@PharmUP:AtendimentoToken");
        return fetch(`${process.env.VUE_APP_ROOT_ATENDIMENTO_API}/${this.controllerName}/EnviarMensagem`, {
            method: "post",
            credentials: "same-origin",
            headers: {
                Authorization: `Bearer ${tokenAtendimento}`,
                "Content-Type": "application/json; charset=UTF-8",
            },
            body: JSON.stringify({ numeroDestino, mensagem, mensagemRespondidaId }),
        });
    }

    public enviarImagem(
        numeroDestino: string,
        legenda: string,
        file: File,
        mensagemRespondidaId: string = null,
    ): Promise<Response> {
        const data = new FormData();
        data.append(file.name, file, file.name);
        const tokenAtendimento = localStorage.getItem("@PharmUP:AtendimentoToken");
        return fetch(
            `${process.env.VUE_APP_ROOT_ATENDIMENTO_API}/${this.controllerName}/EnviarImagem?numeroDestino=${numeroDestino}&legenda=${legenda}&mensagemRespondidaId=${mensagemRespondidaId}`,
            {
                method: "post",
                credentials: "same-origin",
                body: data,
                headers: {
                    Authorization: `Bearer ${tokenAtendimento}`,
                },
            },
        );
    }

    public enviarVideo(
        numeroDestino: string,
        legenda: string,
        file: File,
        mensagemRespondidaId: string = null,
    ): Promise<Response> {
        const data = new FormData();
        data.append(file.name, file, file.name);
        const tokenAtendimento = localStorage.getItem("@PharmUP:AtendimentoToken");
        return fetch(
            `${process.env.VUE_APP_ROOT_ATENDIMENTO_API}/${this.controllerName}/EnviarVideo?numeroDestino=${numeroDestino}&legenda=${legenda}&mensagemRespondidaId=${mensagemRespondidaId}`,
            {
                method: "post",
                credentials: "same-origin",
                body: data,
                headers: {
                    Authorization: `Bearer ${tokenAtendimento}`,
                },
            },
        );
    }

    public enviarDocumento(
        numeroDestino: string,
        legenda: string,
        file: File,
        mensagemRespondidaId: string = null,
    ): Promise<Response> {
        const data = new FormData();
        data.append(file.name, file, file.name);
        const tokenAtendimento = localStorage.getItem("@PharmUP:AtendimentoToken");
        return fetch(
            `${process.env.VUE_APP_ROOT_ATENDIMENTO_API}/${this.controllerName}/EnviarDocumento?numeroDestino=${numeroDestino}&legenda=${legenda}&mensagemRespondidaId=${mensagemRespondidaId}`,
            {
                method: "post",
                credentials: "same-origin",
                body: data,
                headers: {
                    Authorization: `Bearer ${tokenAtendimento}`,
                },
            },
        );
    }
}
