import BaseModel from "./base/baseModel";
import TipoConvenioFaixa from "./enum/tipoConvenioFaixa";

export default class ConvenioFaixaModel extends BaseModel {
    constructor(tipoFaixa: TipoConvenioFaixa = TipoConvenioFaixa.Cliente) {
        super()
        this.tipoFaixa = tipoFaixa;
    }

    id?: number = null
    usuarioCriacaoId?: number = null
    dataCriacao?: Date = new Date()
    usuarioModificacaoId?: number = null
    dataModificacao?: Date = new Date()
    convenioId?: number = null
    faixaInicio = 0.00
    faixaFim = 0.00
    valorDesconto = 0.00
    tipoFaixa: TipoConvenioFaixa = TipoConvenioFaixa.Cliente
}