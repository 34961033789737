import Vue from "vue";
import { mapGetters, mapState } from "vuex";

import actionBarComponent from "@/components/child/actionBar/actionBar.vue";
import buttonIncluirComponent from "@/components/child/form/button/buttonIncluir.vue";
import checkboxComponent from "@/components/child/form/checkbox.vue";
import comboComponent from "@/components/child/form/combo.vue";
import gridComponent from "@/components/child/grid/grid.vue";
import { GridColumn, GridColumnType } from "@/components/child/grid/gridColumn";
import { Component } from "@/decorators";
import GrupoModel from "@/models/grupoModel";
import GrupoTelaAcaoModel from "@/models/grupoTelaAcaoModel";
import GrupoUsuarioModel from "@/models/grupoUsuarioModel";
import PaginationModel from "@/models/paginationModel";
import TelaAcaoModel from "@/models/telaAcaoModel";
import UsuarioModel from "@/models/usuarioModel";
import GrupoService from "@/services/grupoService";
import TelaAcaoService from "@/services/telaAcaoService";
import UsuarioService from "@/services/usuarioService";
import { AppState, Getters, SessionActions } from "@/store/store";
import { sortArray } from "@/utils/common/array";

import "../crud.scss";
import "./edit.scss";

@Component({
    components: {
        comboComponent,
        checkboxComponent,
        gridComponent,
        buttonIncluirComponent,
        actionBarComponent,
    },
    computed: {
        ...mapState({
            usuarioLogado: (state: AppState) => state.session.usuarioLogado,
        }),
        ...mapGetters(["HAS_PERMISSAO_PERFIL"] as Getters),
    },
})
export default class GrupoEditComponent extends Vue {
    // State computed props
    usuarioLogado: UsuarioModel;
    HAS_PERMISSAO_PERFIL: (isRede: boolean, isFranqueador: boolean, isSuporte: boolean) => Promise<boolean>;

    private service = new GrupoService();

    model = new GrupoModel();
    modelId = 0;
    acaoId = 0;
    usuarioId = 0;

    gridColumnsTelaAcao = [new GridColumn("telaAcaoDescricao", "Descrição", GridColumnType.String)];

    gridColumnsUsuario = [new GridColumn("usuarioNome", "Nome", GridColumnType.String)];

    acoesOptions: Array<Object> = [];
    usuariosOptions: Array<Object> = [];
    permitiralteracao = true;
    isAdmin = false;
    franquiaId = 0;
    grupoUsuariosAll: GrupoUsuarioModel[];

    private async load() {
        this.model = new GrupoModel();

        this.isAdmin = await this.HAS_PERMISSAO_PERFIL(false, false, false);
        this.franquiaId = this.usuarioLogado.franquiaId;

        if (this.modelId > 0) {
            try {
                const data = await this.service.get(this.modelId).withLoading().resolveWithJSON<GrupoModel>();
                this.model.updateFrom(data);

                if (this.model.grupoUsuarios) {
                    this.grupoUsuariosAll = [...this.model.grupoUsuarios];
                }

                this.model.grupoTelaAcoes = this.model.grupoTelaAcoes.sort((a, b) =>
                    a.telaAcaoDescricao > b.telaAcaoDescricao ? 1 : -1,
                );
                this.reloadAcoesOptions();

                if (!this.isAdmin) {
                    this.permitiralteracao = this.model.franquiaId != null;
                    this.filtrarUsuariosFranquia();
                }
            } catch {
                this.$router.back();
            }
        }
    }

    private async filtrarUsuariosFranquia() {
        //filtra apenas usuários da franquia logada
        this.model.grupoUsuarios = this.model.grupoUsuarios.filter(
            p => p.usuarioFranquiaId == this.franquiaId && p.id > 1,
        );
    }

    private async loadUsuarios() {
        try {
            const data = await new UsuarioService().combo().resolveWithJSON<PaginationModel<UsuarioModel>>();

            this.usuariosOptions = data.list.map(item => ({ value: item.id, text: item.nome }));
        } catch {}
    }

    private async loadAcoes() {
        try {
            const data = await new TelaAcaoService().combo().resolveWithJSON<PaginationModel<TelaAcaoModel>>();

            this.acoesOptions = data.list.map(item => ({
                value: item.id,
                text: `${item.tela.descricao} - ${item.tela.controller} - ${item.descricao}`,
            }));
        } catch {}
    }

    private async save() {
        try {
            const isValid = await this.$validator.validateAll();

            const data = new GrupoModel();
            data.updateFrom(this.model);

            if (data.id > 0) {
                data.grupoUsuarios = this.grupoUsuariosAll;
            }

            if (isValid) {
                const sucesso = await this.service[!this.model.id ? "insert" : "update"](data)
                    .withLoading()
                    .resolveWithoutJSON();

                if (sucesso) {
                    await this.$showInclusaoUpdate(this.model.id);
                    this.$router.back();
                }
            }
        } catch {}
    }

    private cancel() {
        this.$router.back();
    }

    private incluirTelaAcao() {
        const telaAcaoComponent = this.$el.querySelector("#acaoId") as HTMLSelectElement;

        if (this.acaoId > 0) {
            const existe = this.model.grupoTelaAcoes.some(telaAcao => telaAcao.telaAcaoId == this.acaoId);

            if (!existe) {
                const telaAcaoModel = new GrupoTelaAcaoModel();
                telaAcaoModel.telaAcaoId = this.acaoId;
                telaAcaoModel.telaAcaoDescricao = telaAcaoComponent.innerText;

                this.model.grupoTelaAcoes.push(telaAcaoModel);
                this.acaoId = -1;
            }
        }

        this.reloadAcoesOptions();
    }

    private reloadAcoesOptions() {
        this.acoesOptions = this.acoesOptions.filter(p => !this.model.grupoTelaAcoes.some(a => a.id == p["value"]));
    }

    private incluirUsuario() {
        const usuarioComponent = this.$el.querySelector("#usuarioId") as HTMLSelectElement;

        if (this.usuarioId > 0) {
            const existe = this.model.grupoUsuarios.some(p => p.usuarioId == this.usuarioId);

            if (!existe) {
                const grupoUsuarioModel = new GrupoUsuarioModel();
                grupoUsuarioModel.usuarioId = this.usuarioId;
                grupoUsuarioModel.usuarioNome = usuarioComponent.innerText;

                this.model.grupoUsuarios.push(grupoUsuarioModel);
                this.usuarioId = -1;

                this.grupoUsuariosAll.push(grupoUsuarioModel);
            }
        }
    }

    private onRemoveTelaAcao(model: GrupoTelaAcaoModel) {
        this.model.grupoTelaAcoes = this.model.grupoTelaAcoes.filter(p => p != model);

        this.reloadAcoesOptions();
    }

    private onRemoveUsuario(model: UsuarioModel) {
        this.model.grupoUsuarios = this.model.grupoUsuarios.filter(p => p != model);

        const index = this.grupoUsuariosAll.findIndex(s => s.id == model.id);
        if (index > -1) {
            this.grupoUsuariosAll.splice(index, 1);
        }
    }

    private onChangeSortAcoes(sortKey: keyof GrupoTelaAcaoModel, sortOrder: "asc" | "desc") {
        sortArray(this.model.grupoTelaAcoes, sortKey, sortOrder);
    }

    private async mounted() {
        await this.$store.dispatch(SessionActions.LOAD_USUARIO_LOGADO);

        Promise.all([this.loadAcoes(), this.loadUsuarios()])
            .withLoading()
            .then(() => {
                if (this.$route.params.id) {
                    this.modelId = +this.$route.params.id;
                }

                this.load();
            })
            .catch(() => {});
    }
}
