import FichasTecnicasModel from "../models/fichaTecnicaModel";
import nfetch from "../utils/events/nfetch";

import { IService } from "./base/iService";
import PDFService from "./pdf/PDFService";

export default class FichaTecnicaService extends IService<FichasTecnicasModel> {
    public pdfService = new PDFService("FichaTecnica");

    constructor() {
        super("FichaTecnica");
    }

    public getFichaTecnica(produtoId: number): Promise<Response> {
        return nfetch(`/${this.controllerName}/GetDadosFichaTecnica?produtoId=${produtoId}`, {
            credentials: "same-origin",
        });
    }

    public async geraRelatorio(loteId: number) {
        await this.pdfService.imprimirPDF(`/${this.controllerName}/GeraRelatorios?loteId=${loteId}`);
    }

    public async getPDFByNotaFiscalEntrada(id: number) {
        await this.pdfService.imprimirPDF(`/${this.controllerName}/GetPDFByNotaFiscalEntrada?id=${id}`);
    }

    public salvaFichaTecnica(model: FichasTecnicasModel): Promise<Response> {
        return nfetch(`/${this.controllerName}/SalvarFichaTecnica`, {
            method: "post",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json; charset=UTF-8",
                "XSRF-TOKEN": this.getCookie("XSRF-TOKEN"),
            },
            body: JSON.stringify(model),
        });
    }
}
