import TelaAcaoModel from "../models/telaAcaoModel";
import nfetch from "../utils/events/nfetch";

import { IService } from "./base/iService";

export default class TelaAcaoService extends IService<TelaAcaoModel> {
    constructor() {
        super("TelaAcao");
    }

    public getPermissoesUsuarioLogado(): Promise<Response> {
        return nfetch(`/${this.controllerName}/GetPermissoesUsuarioLogado`, {
            method: "get",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json; charset=UTF-8",
                "XSRF-TOKEN": this.getCookie("XSRF-TOKEN"),
            },
        });
    }
}