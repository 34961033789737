import Vue from "vue";

import { Component, Prop } from "@/decorators";

import "./buttonIncluir.scss";

@Component
export default class ButtonIncluirComponent extends Vue {
    @Prop({ type: Boolean, default: false }) alterar: boolean;
    @Prop({ type: Boolean, default: false }) cancelar: boolean;
    @Prop({ type: Boolean, default: false }) withAll: boolean;

    private onClick() {
        this.$emit("click");
    }

    private onClickAll() {
        this.$emit("clickAll");
    }

    private onCancelar() {
        this.$emit("cancelar");
    }
}
