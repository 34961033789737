import Vue from "vue";

import actionBarComponent from "@/components/child/actionBar/actionBar.vue";
import checkboxComponent from "@/components/child/form/checkbox.vue";
import comboComponent from "@/components/child/form/combo.vue";
import { Component } from "@/decorators";
import Especie from "@/models/enum/especiePagamento";
import FormaPagamentoModel from "@/models/financeiro/formaPagamentoModel";
import FormaPagamentoService from "@/services/financeiro/formaPagamentoService";

import "../crud.scss";

@Component({
    components: {
        comboComponent,
        checkboxComponent,
        actionBarComponent,
    },
})
export default class FormaPagamentoEditComponent extends Vue {
    private service = new FormaPagamentoService();

    model = new FormaPagamentoModel();
    modelId = 0;

    especiesOptions: Array<Object> = [
        { text: "Dinheiro", value: Especie.Dinheiro },
        { text: "Cheque", value: Especie.Cheque },
        { text: "Boleto", value: Especie.Boleto },
        { text: "Cartão de Débito", value: Especie.CartaoDebito },
        { text: "Cartão de Crédito", value: Especie.CartaoCredito },
        { text: "Transferência", value: Especie.Transferencia },
        { text: "Pix", value: Especie.Pix },
        { text: "Convênio", value: Especie.Convenio },
        { text: "Transferência Bancária", value: Especie.TransferenciaBancaria },
        { text: "Outros", value: Especie.Outros },
    ];

    private async load() {
        this.model = new FormaPagamentoModel();

        if (this.modelId > 0) {
            try {
                const data = await this.service.get(this.modelId).withLoading().resolveWithJSON<FormaPagamentoModel>();

                this.model.updateFrom(data);
            } catch {
                this.$router.back();
            }
        } else {
            this.model.ativo = true;
        }
    }

    private async save() {
        try {
            const isValid = await this.$validator.validateAll();
            if (isValid) {
                const sucesso = await this.service[!this.model.id ? "insert" : "update"](this.model)
                    .withLoading()
                    .resolveWithoutJSON();

                if (sucesso) {
                    await this.$showInclusaoUpdate(this.model.id);
                    this.$router.back();
                }
            } else {
                this.$focusErrorField();
            }
        } catch {}
    }

    private cancel() {
        this.$router.back();
    }

    private mounted() {
        if (this.$route.params.id) {
            this.modelId = +this.$route.params.id;
        }
        this.load();
    }
}
