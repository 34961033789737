import BaseModel from "../base/baseModel";

export default class ConfiguracaoDotzFaixaModel extends BaseModel {
    constructor() {
        super();
    }

    inicioFaixa = 0;
    fimFaixa?: number = null;
    multiplicador = 0;
    configuracaoDotzId: number = null;
    id: number = null;
    usuarioCriacaoId?: number = null;
    dataCriacao?: Date = null;
    usuarioModificacaoId?: number = null;
    dataModificacao?: Date = null;
    codigoMecanica: number = null;
}
