var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.nota.cobranca
    ? _c(
        "fieldset-component",
        { attrs: { title: "Fatura", collapsed: true } },
        [
          _c(
            "div",
            { attrs: { slot: "rows" }, slot: "rows" },
            [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-sm-3" }, [
                  _c("div", { staticClass: "form-group" }, [
                    _c(
                      "label",
                      {
                        staticClass: "control-label",
                        attrs: { for: "numero" },
                      },
                      [_vm._v("Número")]
                    ),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.nota.cobranca.numero,
                          expression: "nota.cobranca.numero",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: {
                        type: "text",
                        id: "numero",
                        name: "numero",
                        disabled: _vm.consulta,
                      },
                      domProps: { value: _vm.nota.cobranca.numero },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.nota.cobranca,
                            "numero",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-sm-3" }, [
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c(
                        "label",
                        {
                          staticClass: "control-label",
                          attrs: { for: "valorOriginal" },
                        },
                        [_vm._v("Valor Original")]
                      ),
                      _vm._v(" "),
                      _c("moeda-component", {
                        staticClass: "form-control",
                        attrs: {
                          id: "valorOriginal",
                          name: "valorOriginal",
                          disabled: _vm.consulta,
                        },
                        model: {
                          value: _vm.nota.cobranca.valorOriginal,
                          callback: function ($$v) {
                            _vm.$set(_vm.nota.cobranca, "valorOriginal", $$v)
                          },
                          expression: "nota.cobranca.valorOriginal",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-sm-3" }, [
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c(
                        "label",
                        {
                          staticClass: "control-label",
                          attrs: { for: "valorDesconto" },
                        },
                        [_vm._v("Valor Desconto")]
                      ),
                      _vm._v(" "),
                      _c("moeda-component", {
                        staticClass: "form-control",
                        attrs: {
                          id: "valorDesconto",
                          name: "valorDesconto",
                          disabled: _vm.consulta,
                        },
                        model: {
                          value: _vm.nota.cobranca.valorDesconto,
                          callback: function ($$v) {
                            _vm.$set(_vm.nota.cobranca, "valorDesconto", $$v)
                          },
                          expression: "nota.cobranca.valorDesconto",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-sm-3" }, [
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c(
                        "label",
                        {
                          staticClass: "control-label",
                          attrs: { for: "valorLiquido" },
                        },
                        [_vm._v("Valor Líquido")]
                      ),
                      _vm._v(" "),
                      _c("moeda-component", {
                        staticClass: "form-control",
                        attrs: {
                          id: "valorLiquido",
                          name: "valorLiquido",
                          disabled: "",
                        },
                        model: {
                          value: _vm.nota.cobranca.valorLiquido,
                          callback: function ($$v) {
                            _vm.$set(_vm.nota.cobranca, "valorLiquido", $$v)
                          },
                          expression: "nota.cobranca.valorLiquido",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "fieldset-component",
                {
                  staticStyle: { margin: "15px" },
                  attrs: { title: "Duplicatas", collapsed: false },
                },
                [
                  _c(
                    "div",
                    { attrs: { slot: "rows" }, slot: "rows" },
                    [
                      !_vm.consulta
                        ? _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col-sm-6" }, [
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "tooltip",
                                      rawName: "v-tooltip",
                                      value: _vm.errors.first("valor"),
                                      expression: "errors.first('valor')",
                                    },
                                  ],
                                  staticClass: "form-group",
                                },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "control-label",
                                      attrs: {
                                        for: "valor",
                                        title: "* este campo é obrigatório",
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticStyle: { color: "red" } },
                                        [_vm._v("*")]
                                      ),
                                      _vm._v(
                                        "\n                                Valor\n                            "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("moeda-component", {
                                    directives: [
                                      {
                                        name: "validate",
                                        rawName: "v-validate",
                                        value: "required",
                                        expression: "'required'",
                                      },
                                    ],
                                    staticClass: "form-control",
                                    attrs: { id: "valor", name: "valor" },
                                    model: {
                                      value: _vm.model.valor,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.model, "valor", $$v)
                                      },
                                      expression: "model.valor",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "col-sm-6" }, [
                              _c(
                                "div",
                                { staticClass: "form-group" },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "control-label",
                                      attrs: { for: "dataVencimento" },
                                    },
                                    [_vm._v("Data de vencimento")]
                                  ),
                                  _vm._v(" "),
                                  _c("date-time-picker-component", {
                                    attrs: {
                                      id: "dataVencimento",
                                      name: "dataVencimento",
                                    },
                                    model: {
                                      value: _vm.model.dataVencimento,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.model,
                                          "dataVencimento",
                                          $$v
                                        )
                                      },
                                      expression: "model.dataVencimento",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c("buttonIncluirComponent", {
                        attrs: {
                          alterar: _vm.editingModel,
                          cancelar: _vm.editingModel,
                        },
                        on: { click: _vm.onAdicionarItem, cancelar: _vm.clear },
                      }),
                      _vm._v(" "),
                      _c("grid-component", {
                        attrs: {
                          data: _vm.nota.cobranca.duplicatas,
                          columns: _vm.gridColumns,
                          showRemoveItem: !_vm.consulta,
                          showEditItem: !_vm.consulta,
                          showAddNewButton: false,
                          "show-table-head": false,
                          "show-table-foot": false,
                        },
                        on: {
                          "edit-item": _vm.onEditItem,
                          "remove-item": _vm.onRemoveItem,
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }