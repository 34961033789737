import { mapMutations } from "vuex";

import { GridColumn, GridColumnType } from "@/components/child/grid/gridColumn";
import { Component } from "@/decorators";
import CapsulaModel from "@/models/capsulaModel";
import CapsulaService from "@/services/capsulaService";
import { LoadListProps, Mutations } from "@/store/store";

import ListComponentBase from "../listComponentBase";

@Component({
    methods: mapMutations(["LOAD_LIST"] as Mutations),
})
export default class CapsulaListComponent extends ListComponentBase<CapsulaModel> {
    // State computed props
    LOAD_LIST: (props: LoadListProps) => void;

    get gridColumns(): Array<GridColumn> {
        return [
            new GridColumn("numero", "Número", GridColumnType.String),
            new GridColumn("descricao", this.$t("__.ts.descricao"), GridColumnType.String),
        ];
    }

    private beforeMount() {
        this.setProps(new CapsulaService(), "capsula", "descricao", true);
    }

    public async onSucessoExclusao() {
        this.LOAD_LIST({ loadCapsulas: true });
    }
}
