import BaseModel from "./../base/baseModel";

export default class ConversaoUnidadeMedidasModel extends BaseModel {
    unidadeMedidaOrigemId: number = null;
    unidadeMedidaDestinoId: number = null;
    densidade = 1;
    indice: number = null;
    produtoId = 0;
    produtoLoteId = 0;
}
