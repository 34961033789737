import MetaModel from "../models/metaModel";
import nfetch from "../utils/events/nfetch";

import { IService } from "./base/iService";

export default class MetaService extends IService<MetaModel> {
    constructor() {
        super("Meta");
    }

    public getMetas(year: number): Promise<Response> {
        return nfetch(`/${this.controllerName}/GetMetas?year=${year}`, {
            credentials: "same-origin"
        })
    }
}