import Boot from "@/main";
import TipoOperacao from "@/models/enum/tipoOperacao";

import CartaCorrecaoModel from "../../models/cartaCorrecaoModel";
import TipoNotaFiscal from "../../models/enum/tipoNotaFiscal";
import VendaModel from "../../models/vendaModel";
import { AlertColor } from "../../utils/common/alert";
import nfetch from "../../utils/events/nfetch";
import { getCookie } from "../utils/serviceUtils";

import CartaCorrecaoService from "./../cartaCorrecaoService";
import XmlService from "./../xml/xmlService";

export default class NotaFiscalEletronicaEmissaoService {
    private controllerName = "NotaFiscalEletronicaEmissao";

    private serviceXml = new XmlService();
    private serviceCartaCorrecao = new CartaCorrecaoService();

    public list(
        filterKey: string,
        sortKey: string,
        sortOrder: string,
        pageIndex: number,
        pageSize: number,
    ): Promise<Response> {
        const encodedFIlter = encodeURIComponent(filterKey);
        return nfetch(
            `/${this.controllerName}/List?filterKey=${encodedFIlter}&sortKey=${sortKey}&sortOrder=${sortOrder}&pageIndex=${pageIndex}&pageSize=${pageSize}`,
            {
                credentials: "same-origin",
            },
        );
    }

    public get(id: number): Promise<Response> {
        return nfetch(`/${this.controllerName}/Get?id=${id}`, {
            credentials: "same-origin",
        });
    }

    public emitir(data: object): Promise<Response> {
        return nfetch(`/${this.controllerName}/Emitir`, {
            method: "post",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json; charset=UTF-8",
                "XSRF-TOKEN": getCookie("XSRF-TOKEN"),
            },
            body: JSON.stringify(data),
        });
    }

    public emitirByVenda(data: object): Promise<Response> {
        return nfetch(`/${this.controllerName}/EmitirByVenda`, {
            method: "post",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json; charset=UTF-8",
                "XSRF-TOKEN": getCookie("XSRF-TOKEN"),
            },
            body: JSON.stringify(data),
        });
    }

    public emitirAutomaticoByVenda(tipoNota: TipoNotaFiscal, venda: VendaModel): Promise<Response> {
        return nfetch(`/${this.controllerName}/EmitirAutomaticoByVenda?tipoNota=${tipoNota}`, {
            method: "post",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json; charset=UTF-8",
                "XSRF-TOKEN": getCookie("XSRF-TOKEN"),
            },
            body: JSON.stringify(venda),
        });
    }

    public emitirSAT(venda: VendaModel): Promise<Response> {
        return nfetch(`/NotaFiscalSATEmissao/GetXMLSat`, {
            method: "post",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json; charset=UTF-8",
                "XSRF-TOKEN": getCookie("XSRF-TOKEN"),
            },
            body: JSON.stringify(venda),
        });
    }

    public salvarSAT(venda: VendaModel): Promise<Response> {
        return nfetch(`/NotaFiscalSATEmissao/SalvarNotaFiscalSAT`, {
            method: "post",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json; charset=UTF-8",
                "XSRF-TOKEN": getCookie("XSRF-TOKEN"),
            },
            body: JSON.stringify(venda),
        });
    }

    public cancelarSAT(chave: string): Promise<Response> {
        return nfetch(`/NotaFiscalSATEmissao/GetXMLSatCancelamento`, {
            method: "post",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json; charset=UTF-8",
                "XSRF-TOKEN": getCookie("XSRF-TOKEN"),
            },
            body: JSON.stringify({ chave }),
        });
    }

    public consultar(recibo: string, modelo: number): Promise<Response> {
        return nfetch(`/${this.controllerName}/Consultar`, {
            method: "post",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json; charset=UTF-8",
                "XSRF-TOKEN": getCookie("XSRF-TOKEN"),
            },
            body: JSON.stringify({
                recibo,
                modelo,
            }),
        });
    }

    public cancelamento(chave: string, justificativa: string): Promise<Response> {
        return nfetch(`/${this.controllerName}/Cancelar`, {
            method: "post",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json; charset=UTF-8",
                "XSRF-TOKEN": getCookie("XSRF-TOKEN"),
            },
            body: JSON.stringify({
                chave,
                justificativa,
            }),
        }).then(response => {
            if (response.ok) {
                Boot.$showSuccess("Cancelamento da Nota", "Cancelamento da Nota feito com sucesso.");
            }
            return response;
        });
    }

    public cartaCorrecao(
        chave: string,
        notaFiscalId: number,
        correcao: string,
        tipoOperacao: TipoOperacao,
    ): Promise<Response> {
        return nfetch(`/${this.controllerName}/CartaCorrecao`, {
            method: "post",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json; charset=UTF-8",
                "XSRF-TOKEN": getCookie("XSRF-TOKEN"),
            },
            body: JSON.stringify({
                chave,
                correcao,
            }),
        }).then(async response => {
            if (response.ok) {
                const carta = new CartaCorrecaoModel();

                if (tipoOperacao == TipoOperacao.Saida) {
                    carta.notaFiscalSaidaId = notaFiscalId;
                } else {
                    carta.notaFiscalEntradaId = notaFiscalId;
                }

                carta.descricao = correcao;
                carta.data = new Date();
                const response = await this.serviceCartaCorrecao.getByNotaFiscal(notaFiscalId, tipoOperacao);
                if (response.status == 204) {
                    carta.sequencial = 1;
                } else {
                    const ultimaCarta = (await response.json()) as CartaCorrecaoModel;
                    carta.sequencial = ultimaCarta.sequencial + 1;
                }

                await this.serviceCartaCorrecao.insert(carta);

                Boot.$showSuccess("Carta de Correção", "Carta de Correção enviada com sucesso.");
            }
            return response;
        });
    }

    public downloadXmls(data: object): Promise<Response> {
        return nfetch(`/${this.controllerName}/DownloadXMLs`, {
            method: "post",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json; charset=UTF-8",
                "XSRF-TOKEN": getCookie("XSRF-TOKEN"),
            },
            body: JSON.stringify({ ...data }),
        });
    }

    public resumoNotaFiscal(data: object): Promise<Response> {
        return nfetch(`/${this.controllerName}/ResumoNotaFiscal`, {
            method: "post",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json; charset=UTF-8",
                "XSRF-TOKEN": getCookie("XSRF-TOKEN"),
            },
            body: JSON.stringify(data),
        });
    }

    public async cancelamentoSelecao() {
        return await Boot.$showAlert({
            title: "Ao prosseguir, a rotina enviará uma requisição de cancelamento de nota fiscal. Para prosseguir insira um motivo de cancelamento.",
            text: "Esta a\u00e7\u00e3o n\u00e3o poder\u00e1 ser revertida!",
            icon: "warning",
            html: `<label>Motivo do Cancelamento:</label>`,
            showCancelButton: true,
            confirmButtonText: "Cancelar",
            cancelButtonText: "Fechar",
            confirmButtonColor: AlertColor.RED,
            input: "textarea",
            inputValidator: result => {
                if (result.length > 1000) {
                    return "Motivo muito longo, use no maximo 1000 caracteres.";
                }
                if (result.length < 15) {
                    return "Motivo muito curto, use no minimo 15 caracteres para descrição.";
                }
                return "";
            },
        });
    }

    public async cartaCorrecaoSelecao() {
        return await Boot.$showAlert({
            title: "Ao prosseguir, a rotina enviará uma requisição de envio da carta de correção da nota fiscal. Para prosseguir insira a correção.",
            text: "",
            icon: "warning",
            html: `<label>Carta de correção:</label>`,
            showCancelButton: true,
            confirmButtonText: "Enviar",
            cancelButtonText: "Fechar",
            confirmButtonColor: AlertColor.GREEN,
            input: "textarea",
            inputValidator: result => {
                if (result.length > 1000) {
                    return "Descrição muito longa, use no maximo 1000 caracteres.";
                }
                if (result.length < 15) {
                    return "Descrição muito curta, use no minimo 15 caracteres para descrição.";
                }
                return "";
            },
        });
    }

    public async showCartaCorrecao(notaFiscalId: number, tipoOperacao: TipoOperacao) {
        try {
            const carta = await this.serviceCartaCorrecao
                .getByNotaFiscal(notaFiscalId, tipoOperacao)
                .resolveWithJSON<CartaCorrecaoModel>();

            await Boot.$showAlert({
                title: "Carta de correção da Nota Fiscal",
                text: "",
                icon: "success",
                html: `<label>Carta de correção sequência ${carta.sequencial}</label>`,
                showCancelButton: true,
                showConfirmButton: false,
                cancelButtonText: "Fechar",
                input: "textarea",
                inputValue: carta.descricao,
                inputAttributes: {
                    disabled: "true",
                },
            });
        } catch {}
    }

    public downloadXML(link: string) {
        this.serviceXml.downloadXML(link);
    }

    public openPdf(link: string) {
        window.open(link, "_blank");
    }
}
