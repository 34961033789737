import { GridColumn, GridColumnType } from "@/components/child/grid/gridColumn";
import { Component } from "@/decorators";
import MotivoRejeicaoModel from "@/models/motivoRejeicaoModel";
import MotivoRejeicaoService from "@/services/motivoRejeicaoService";

import ListComponentBase from "../listComponentBase";

@Component
export default class MotivoRejeicaoListComponent extends ListComponentBase<MotivoRejeicaoModel> {
    get gridColumns(): Array<GridColumn> {
        return [new GridColumn("descricao", this.$t("__.ts.descricao"), GridColumnType.String)];
    }

    private beforeMount() {
        this.setProps(new MotivoRejeicaoService(), "motivorejeicao", "descricao", true);
    }
}
