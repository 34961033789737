import Vue from "vue";
import { mapMutations, mapState } from "vuex";

import dateTimePickerComponent from "@/components/child/form/datetimepicker.vue";
import Grid from "@/components/child/grid/grid";
import gridComponent from "@/components/child/grid/grid.vue";
import { GridAction } from "@/components/child/grid/gridAction";
import { GridColors, GridColumn, GridColumnType } from "@/components/child/grid/gridColumn";
import ShortcutComponent from "@/components/parent/shortcut/shortcut";
import shortcutComponent from "@/components/parent/shortcut/shortcut.vue";
import { Component, Prop } from "@/decorators";
import VendaHistoricoFilterModel from "@/models/historico/vendaHistorico/vendaHistoricoFilterModel";
import VendaHistoricoModel from "@/models/historico/vendaHistorico/vendaHistoricoModel";
import VendaHistoricoService from "@/services/historico/vendaHistoricoService";
import { AppState, Mutations } from "@/store/store";

import verComponent from "./ver.vue";

import "../../crud/crud.scss";

@Component({
    components: {
        gridComponent,
        dateTimePickerComponent,
        verComponent,
        shortcutComponent,
    },
    computed: mapState<AppState>({
        loadingPack: state => state.paginationStatus.loading,
        paginationParams: state => state.paginationStatus.paginationParams,
    }),
    methods: mapMutations(["SET_PARAMS", "GET_PARAMS"] as Mutations),
})
export default class VendaHistoricoListComponent extends Vue {
    private service = new VendaHistoricoService();
    private shortcutComponent: ShortcutComponent = null;
    private gridcomponent: Grid = null;

    startFilterKey = "";
    gridFilterKey = "";
    gridSortKey = "nome";
    gridSortOrder = "asc";
    gridData: Array<VendaHistoricoModel> = [];

    @Prop({ type: Boolean, default: false }) isModal;

    get gridColumns(): Array<GridColumn> {
        return [
            new GridColumn("cliente", "Cliente", GridColumnType.String),
            new GridColumn("clienteTelefone", "Telefone", GridColumnType.String),
            new GridColumn("vendaId", "Cod. Venda", GridColumnType.String),
            new GridColumn("dataVenda", "Dt Venda", GridColumnType.Date),
            new GridColumn("valorFinal", "Valor", GridColumnType.Money),
            new GridColumn("possuiContinuo", "Contínuo", GridColumnType.Boolean),
            new GridColumn("medicamentos", "Medicamento", GridColumnType.String),
        ];
    }
    filtro = new VendaHistoricoFilterModel();
    modelVer: any = null;
    showVer = false;
    extraActions: Array<object> = [];
    gridExtraActionsVer: Array<GridAction> = [];

    pageIndex = 1;
    pageSize = 20;
    total = 0;

    public async load() {
        try {
            const data = await this.service
                .list(
                    this.gridFilterKey,
                    this.gridSortKey,
                    this.gridSortOrder,
                    this.pageIndex,
                    this.pageSize,
                    this.filtro.Continuo,
                    this.filtro.DataVenda,
                )
                .withLoading();

            this.gridData = data.list;
            this.total = data.total;
            this.pageIndex = data.pageIndex;
            this.pageSize = data.pageSize;

            this.gridExtraActionsVer.splice(0);
            this.extraActions.splice(0);

            for (let i = 0; i < this.gridData.length; i++) {
                this.gridExtraActionsVer.push(new GridAction("ver", "Ver Venda", "fa fa-eye", GridColors.BLUE, true));
            }

            this.extraActions.push(this.gridExtraActionsVer);
        } catch {}
    }

    private onChangeFilterKey(filterKey: string) {
        this.gridFilterKey = filterKey;
        this.pageIndex = 1;
        this.load();
    }

    private onChangeSort(sortKey: string, sortOrder: string) {
        this.gridSortKey = sortKey;
        this.gridSortOrder = sortOrder;
        this.load();
    }

    private onChangePage(pageIndex: number) {
        this.pageIndex = pageIndex;
        this.load();
    }

    private onExtraAction(name: string, model: VendaHistoricoModel) {
        if (name.trim() == "ver") {
            this.onVer(model);
        }
    }

    private async onVer(model: VendaHistoricoModel) {
        this.shortcutComponent.title = this.$t("__.ts.vendaHist") as string;

        try {
            const data = await this.service.get(model.id);
            this.modelVer = data;
            this.showVer = true;
        } catch {}

        this.shortcutComponent.show();
    }

    private onFiltrarAvancado() {
        this.load();
    }

    private onChangePageSize(newPageSize) {
        this.pageIndex = 1;
        this.pageSize = newPageSize;
        this.load();
    }

    private mounted() {
        this.shortcutComponent = this.$refs.shortcutComponent as ShortcutComponent;
        this.gridcomponent = this.$refs.gridcomponent as Grid;

        if (!this.isModal) {
            this.load();
        }
    }
}
