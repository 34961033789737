import Vue from "vue";
import { VueTabs, VTab } from "vue-nav-tabs";
import { mapGetters, mapState } from "vuex";
Vue.component("vue-tabs", VueTabs);
Vue.component("v-tab", VTab);

import actionBarComponent from "@/components/child/actionBar/actionBar.vue";
import buttonScComponent from "@/components/child/form/buttonSc.vue";
import checkboxComponent from "@/components/child/form/checkbox.vue";
import comboComponent from "@/components/child/form/combo.vue";
import dataTooltipComponent from "@/components/child/form/datatooltip.vue";
import dateTimePickerComponent from "@/components/child/form/datetimepicker.vue";
import decimalComponent from "@/components/child/form/decimal.vue";
import decimalComSinalCustomComponent from "@/components/child/form/decimalComSinalCustom.vue";
import fieldsetComponent from "@/components/child/form/fieldset.vue";
import moedaComponent from "@/components/child/form/moeda.vue";
import textareaComponent from "@/components/child/form/textarea.vue";
import gridComponent from "@/components/child/grid/grid.vue";
import { GridColumn, GridColumnType } from "@/components/child/grid/gridColumn";
import ImpressaoComponent from "@/components/child/impressao/impressaoComponent";
import impressaoComponent from "@/components/child/impressao/impressaoComponent.vue";
import logEntidadeComponent from "@/components/child/logEntidade/logEntidade.vue";
import searchComboComponent from "@/components/child/searchCombo/search.vue";
import { Component, Prop, Watch } from "@/decorators";
import PreLoadPackModel from "@/models/auxiliar/preLoadPackModel";
import ColaboradorModel from "@/models/colaboradorModel";
import ConfiguracaoFranquiaModel from "@/models/configuracaoFranquia/configuracaoFranquiaModel";
import ConvenioModel from "@/models/convenioModel";
import Configuracoes from "@/models/enum/configuracao/configuracoes";
import DestinosManipulacao from "@/models/enum/destinosManipulacao";
import EnumExtensions from "@/models/enum/extensions/enumExtensions";
import SituacoesManipulacaoOrdem from "@/models/enum/situacoesManipulacaoOrdem";
import SituacoesVenda from "@/models/enum/situacoesVenda";
import SubGrupoProduto from "@/models/enum/subGrupoProduto";
import TipoConvenioFaixa from "@/models/enum/tipoConvenioFaixa";
import TipoDesconto from "@/models/enum/TipoDesconto";
import TiposCalculoManipulacao from "@/models/enum/tiposCalculoManipulacao";
import TiposCapsula from "@/models/enum/tiposCapsula";
import TiposFormaFarmaceutica from "@/models/enum/tiposFormaFarmaceutica";
import TiposFormulaPadrao from "@/models/enum/tiposFormulaPadrao";
import TiposItemManipulacao from "@/models/enum/tiposItemManipulacao";
import TiposProduto from "@/models/enum/tiposProduto";
import TipoVenda from "@/models/enum/tipoVenda";
import FormaFarmaceuticaItemPadraoModel from "@/models/formaFarmaceuticaItemPadraoModel";
import FormaFarmaceuticaModel from "@/models/formaFarmaceuticaModel";
import FormulaPadraoModel from "@/models/formulaPadraoModel";
import ManipulacaoOrdemItemModel from "@/models/manipulacaoOrdemItemModel";
import ManipulacaoOrdemModel, { StatusEstoqueManipulacao } from "@/models/manipulacaoOrdemModel";
import moldeModel, { MoldeListParameters } from "@/models/moldeModel";
import PacienteModel from "@/models/paciente/pacienteModel";
import PaginationModel from "@/models/paginationModel";
import PosologiaModel from "@/models/posologiaModel";
import PrescritorModel from "@/models/prescritorModel";
import ProdutoModel from "@/models/produto/produtoModel";
import { StatusEstoque } from "@/models/produtoEstoqueConferenciaLoteModel";
import ProdutoEstoqueConferenciaModel from "@/models/produtoEstoqueConferenciaModel";
import UnidadeMedidaModel from "@/models/unidadeMedidaModel";
import UsuarioModel from "@/models/usuarioModel";
import ValidationErrorModel from "@/models/validationErrorModel";
import VendaItemModel from "@/models/vendaItemModel";
import ColaboradorService from "@/services/colaboradorService";
import ConvenioService from "@/services/convenioService";
import FormaFarmaceuticaService from "@/services/formaFarmaceuticaService";
import FormulaPadraoService from "@/services/formulaPadraoService";
import LogEntidadeService from "@/services/logEntidadeService";
import ManipulacaoOrdemService from "@/services/manipulacaoOrdemService";
import MoldeService from "@/services/moldeService";
import PacienteService from "@/services/paciente/pacienteService";
import PosologiaService from "@/services/posologiaService";
import PrescritorService from "@/services/prescritorService";
import ProdutoService from "@/services/produto/produtoService";
import ProdutoEstoqueConferenciaService from "@/services/produtoEstoqueConferenciaService";
import UnidadeMedidaService from "@/services/unidadeMedidaService";
import {
    ItemTableType,
    ItemTypeOrigem,
    ManipulacaoContextMutations,
    ManipulacaoContextState,
} from "@/store/manipulacaoContext/types";
import { ManipulacaoContextActions } from "@/store/manipulacaoContext/types";
import { AppState, Getters, SessionActions } from "@/store/store";
import { AlertColor } from "@/utils/common/alert";
import arithmeticHelper from "@/utils/common/arithmeticHelper";
import { getFormaFarmaceuticaCombo, getPrescritorCombo, getUnidadeMedidaCombo } from "@/utils/common/combo/combotext";
import { getPosologiaCombo } from "@/utils/common/combo/combotext";
import { addDays, editDateWithTime } from "@/utils/common/date";
import Delay from "@/utils/common/delay";
import { generateUUID } from "@/utils/common/uuid";
import { showErrorsAsync } from "@/wrappers/validationError";

import PacienteComponent from "../crud/paciente/edit";
import pacienteComponent from "../crud/paciente/edit.vue";
import PosologiaComponent from "../crud/posologia/edit";
import posologiaComponent from "../crud/posologia/edit.vue";
import PrescritorComponent from "../crud/prescritor/edit";
import prescritorComponent from "../crud/prescritor/edit.vue";
import ShortcutComponent from "../shortcut/shortcut";
import shortcutComponent from "../shortcut/shortcut.vue";

import DetalhesItemComponent from "./detalhesItem";
import detalhesItemComponent from "./detalhesItem.vue";
import ManipulacaoItemTableComponent from "./itemTable";
import manipulacaoItemTableComponent from "./itemTable.vue";
import ManipulacaoLoteItemComponent from "./loteItem";
import manipulacaoLoteItemComponent from "./loteItem.vue";
import { getItemManipulacaoByProduto, getValorTotal, getVolumeTotal, getVolumeTotalAtivos } from "./manipulacaoUtils";
import PreVendaComponent from "./preVenda";
import preVendaComponent from "./preVenda.vue";

import "../crud/crud.scss";
import "./edit.scss";

@Component({
    components: {
        textareaComponent,
        comboComponent,
        searchComboComponent,
        dataTooltipComponent,
        fieldsetComponent,
        dateTimePickerComponent,
        moedaComponent,
        decimalComponent,
        decimalComSinalCustomComponent,
        manipulacaoItemTableComponent,
        gridComponent,
        shortcutComponent,
        buttonScComponent,
        pacienteComponent,
        prescritorComponent,
        posologiaComponent,
        manipulacaoLoteItemComponent,
        detalhesItemComponent,
        preVendaComponent,
        checkboxComponent,
        impressaoComponent,
        actionBarComponent,
        logEntidadeComponent,
    },
    computed: {
        ...mapState<AppState>({
            preLoadList: state => state.preLoad.preLoadList,
            loadedList: state => state.preLoad.loadedList,
            usuarioLogado: state => state.session.usuarioLogado,
        }),
        ...mapGetters([
            "GET_CONFIG_FRANQUIA",
            "GET_MANIPULACAO_CONTEXT",
            "VALIDAR_PERMISSAO_USUARIO",
            "VALIDAR_PERMISSAO_SOMENTE_CONSULTA",
            "GET_IS_ESTEREIS",
        ] as Getters),
        manipulacaoTitle() {
            const path = "__.Components.parent.manipulacao.edit_vue_html.";
            if (this.manipulacaoContext.isItemFromVenda) {
                return this.$t(path + "manipuEdit");
            }
            return `${this.$t(path + "manipuCod")}<span style="color: mediumblue">${this.model.codigo}</span>${this.$t(
                path + "edit",
            )}`;
        },
        isPosologiaTextoLivre() {
            return this.GET_CONFIG_FRANQUIA(Configuracoes.PosologiasTextoLivre).verdadeiro;
        },
        isDisabledEdicaoPreVenda() {
            return this.model.isPreVenda && !this.model.isPreVendaEditavel;
        },
    },
})
export default class ManipulacaoEditComponent extends Vue {
    // State computed props
    loadedList: boolean;
    preLoadList: PreLoadPackModel;
    usuarioLogado: UsuarioModel;
    GET_CONFIG_FRANQUIA: (configuracao: Configuracoes) => ConfiguracaoFranquiaModel;
    GET_MANIPULACAO_CONTEXT: (uuid: string) => ManipulacaoContextState;
    VALIDAR_PERMISSAO_USUARIO: (telaDescricao: string, acaoController: string) => boolean;
    VALIDAR_PERMISSAO_SOMENTE_CONSULTA: (telaDescricao: string) => boolean;
    GET_IS_ESTEREIS: () => Promise<boolean>;

    private service = new ManipulacaoOrdemService();
    private produtoService = new ProdutoService();
    private formaFarmaceuticaService = new FormaFarmaceuticaService();
    private convenioService = new ConvenioService();
    private logEntidadeService = new LogEntidadeService();

    private manipulacaoItemTableComponent: ManipulacaoItemTableComponent[] = [];

    //guias de itens
    private tabs = new Array<[number, string]>();
    private activeTab = "";

    private tabKitEmbalagemName: string = null;
    private tabCapsulaName: string = null;

    private shortcutLoteComponent: ShortcutComponent = null;
    private shortcutDetalhesComponent: ShortcutComponent = null;
    private shortcutPreVendaComponent: ShortcutComponent = null;
    private shortcutMultiplicarDoseComponent: ShortcutComponent = null;
    private pacienteComponent: PacienteComponent = null;
    private posologiaComponent: PosologiaComponent = null;
    private prescritorComponent: PrescritorComponent = null;
    private manipulacaoLoteItemComponent: ManipulacaoLoteItemComponent = null;
    private detalhesItemComponent: DetalhesItemComponent = null;
    private preVendaComponent: PreVendaComponent = null;
    private impressaoComponent: ImpressaoComponent = null;

    private showLote = false;
    private showDetalhes = false;
    //para quando vem da venda
    private continuo = false;
    private dataVencimentoContinuo: Date = null;

    //se alterou volume após consolidar, vai for;ar a mensagem de consolidar novamente
    private alterouVolume = false;

    //controle de capsula
    private capsulaDefinidaDescricao: string = null;
    private volumeCapsula: number = null;
    private quantidadeItemCapsulas: number = null;
    private showWarningCapsula = false;
    private showWarningEmbalagem = false;

    private adicionouItensPadroesIni = false;

    model: ManipulacaoOrdemModel = new ManipulacaoOrdemModel();
    private modelId = 0;
    private uuid: string = null;

    private prescritorModel = new PrescritorModel();
    private utilizandoValorMinimo = false;
    private carregouLinhasIniciais = false;

    private reConsolidar = false;
    private reConsolidarDiferencaVolume = false;
    private carregouGuias = false;
    private collapsedFieldsets = false;

    public aprovouDesconto = true;

    private quantidadeStrId = "quantidade";

    private showPrescritor = false;
    private showPaciente = false;
    private showPreVenda = false;

    private utilizaMolde = false;
    private moldeId: number = null;
    private moldeProdutoId: number = null;

    private isEstereis = false;
    private isFormaVolume = false;
    private alertarEstoqueMinimo = false;

    private historicoStatus = [];

    private customComboTextPaciente = (p: PacienteModel) => ({
        value: p.id,
        text: `${p.nome} - (${p.clienteDescricao})`,
    });

    private customComboTextPrescritor = getPrescritorCombo;

    private quantidadeCalculada = 0;
    private nomeVendedorText = "";
    private valorTabelado = 0;

    situacoesManipulacaoOrdemOptions: Array<Object> =
        EnumExtensions.getNamesAndValuesOrderedByNames(SituacoesManipulacaoOrdem);

    private formaFarmaceuticasOptions: Array<Object> = [];
    private unidadeMedidasOptions: Array<Object> = [];
    private posologiaOptions: Array<Object> = [];
    private moldeOptions: Array<Object> = [];
    private colaboradoresOptions: Array<Object> = [];

    private itensVendaApp: Array<VendaItemModel> = [];

    private unsubscribe = null;
    private unsubscribeActions = null;
    private timer: number = null;

    public manipulacao = new ManipulacaoOrdemModel();
    public isHomeopatia = false;
    private manipulacaoContext = new ManipulacaoContextState();
    private formaFarmaceutica = new FormaFarmaceuticaModel();

    private oldValorTotal = 0;

    get gridColumns(): Array<GridColumn> {
        return [
            new GridColumn("produtoDescricao", this.$t("__.ts.descr"), GridColumnType.String, true),
            new GridColumn("quantidadeDose", this.$t("__.ts.qtde"), GridColumnType.Decimal, true),
            new GridColumn("quantidade", this.$t("__.ts.qtdTotal"), GridColumnType.Decimal),
            new GridColumn("unidadeMedidaManipulacaoDecricao", this.$t("__.ts.unMedida"), GridColumnType.String, true),
            new GridColumn("produtoLoteDescricao", this.$t("__.ts.lote"), GridColumnType.String, true),
            new GridColumn("valorTotal", this.$t("__.ts.vlr"), GridColumnType.Money, true),
        ];
    }

    get subgridColumns(): Array<GridColumn> {
        return [
            new GridColumn("produtoDescricao", this.$t("__.ts.descr"), GridColumnType.String),
            new GridColumn("quantidadeDose", this.$t("__.ts.qtde"), GridColumnType.Decimal),
            new GridColumn("quantidade", this.$t("__.ts.qtdTotal"), GridColumnType.Decimal),
            new GridColumn("unidadeMedidaManipulacaoDecricao", this.$t("__.ts.unMedida"), GridColumnType.String),
            new GridColumn("produtoLoteDescricao", this.$t("__.ts.lote"), GridColumnType.String),
        ];
    }

    get gridColumnsHistorico(): Array<GridColumn> {
        return [new GridColumn("descricao", this.$t("__.ts.descr"), GridColumnType.String)];
    }

    @Prop({ type: Array, default: () => [] }) itensVenda: VendaItemModel[];
    @Prop({ type: Boolean, default: false }) itemVenda: boolean;
    @Prop({ type: Number, default: 0 }) situacaoVenda: number;
    @Prop({ type: Number, default: 0 }) tipoVenda: number;
    @Prop({ type: Number, default: 0 }) indexVenda: number;
    @Prop({ type: Number, default: 0 }) manipulacaoId: number;
    @Prop({ type: Number, default: 0 }) estoqueId: number;
    @Prop({ type: Boolean, default: false }) CalledByShortCut: boolean;
    @Prop({ type: Boolean, default: false }) vendaFaturada: boolean;
    @Prop({ type: Boolean, default: false }) vendaReplicada: boolean;

    @Prop(String) nomeVendedor: string;

    @Watch("model", { deep: true })
    private onChangeModel() {
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
            this.dispatch(ManipulacaoContextActions.UPDATE_MANIPULACAO, { ...this.model });
        }, 500);
    }

    private async loadMoldes() {
        try {
            const customParameters: MoldeListParameters = { formaFarmaceuticaId: this.model.formaFarmaceuticaId };
            const data = await new MoldeService()
                .list("", "descricao", "asc", 1, 999, customParameters)
                .resolveWithJSON<PaginationModel<moldeModel>>();

            this.moldeOptions = data.list.map(item => ({
                value: item.id,
                text: item.descricao,
                volumeInterno: item.volumeInterno,
                produtoQSPId: item.produtoQSPId,
            }));
        } catch {}
    }

    private async loadPosologia() {
        try {
            const data = await new PosologiaService().combo().resolveWithJSON<PaginationModel<PosologiaModel>>();

            this.posologiaOptions = data.list.map(getPosologiaCombo);
        } catch {}
    }

    private async loadUnidadeMedida() {
        try {
            const data = await new UnidadeMedidaService()
                .combo()
                .resolveWithJSON<PaginationModel<UnidadeMedidaModel>>();

            this.unidadeMedidasOptions = data.list.map(item => getUnidadeMedidaCombo(item));
        } catch {}
    }

    public setPacientePrescritor() {
        if (this.isEstereis) {
            const indexFirstManipulacao = this.itensVenda.findIndex(p => p.manipulacaoOrdem != null);

            if (
                indexFirstManipulacao > -1 &&
                this.itensVenda[indexFirstManipulacao].manipulacaoOrdem.pacienteId &&
                this.itensVenda[indexFirstManipulacao].manipulacaoOrdem.prescritorId
            ) {
                this.model.pacienteId = this.itensVenda[indexFirstManipulacao].manipulacaoOrdem.pacienteId;
                this.model.prescritorId = this.itensVenda[indexFirstManipulacao].manipulacaoOrdem.prescritorId;
            }
        }
    }

    public clear(newManipulacao = false) {
        this.dispatch(ManipulacaoContextActions.RESET_MANIPULACAO, null);
        this.dispatch(ManipulacaoContextActions.SET_IS_ITEM_FROM_VENDA, this.itemVenda);
        this.dispatch(ManipulacaoContextActions.SET_IS_TRANSFERENCIA, this.tipoVenda === TipoVenda.Transferencia);
        this.dispatch(ManipulacaoContextActions.SET_UPLOADING_EDITING_MODEL, false);
        this.dispatch(ManipulacaoContextActions.SET_UPLOADING_PREVENDA, false);
        this.dispatch(ManipulacaoContextActions.SET_IS_VENDA_REPLICADA, this.vendaReplicada);

        if (this.VALIDAR_PERMISSAO_SOMENTE_CONSULTA("manipulacoes_venda") && !this.itemVenda) {
            this.dispatch(ManipulacaoContextActions.SET_IS_DESABILITADO, true);
        }

        this.model = new ManipulacaoOrdemModel();
        this.model.homeopatia = this.isHomeopatia;
        this.model.estoqueId = this.estoqueId;
        this.model.posologiaId = null;
        this.model.posologiaTexto = null;
        this.model.pacienteId = null;

        if (this.manipulacaoId && !newManipulacao) {
            this.model.id = this.manipulacaoId;
            this.model.codigo = this.manipulacaoContext.manipulacao.codigo;
            if (!this.model.dataValidadeAlterada) {
                this.model.dataValidade = this.manipulacaoContext.manipulacao.dataValidade;
            }
        }

        this.model.prescritorId = null;
        this.model.volume = null;
        this.model.formaFarmaceuticaId = null;
        this.model.unidadeMedidaId = null;
        this.model.kitEmbalagemId = null;
        this.volumeCapsula = null;
        this.alterouVolume = false;
        this.utilizaMolde = false;
        this.model.moldeId = null;
        this.moldeId = null;
        this.moldeProdutoId = null;
        if (this.manipulacaoContext.isItemFromVenda) {
            this.model.destino = DestinosManipulacao.Venda;
        }
        this.model.situacao = SituacoesManipulacaoOrdem.Orcamento;
        this.utilizandoValorMinimo = false;
        this.quantidadeCalculada = 0;
        this.model.fatorMultQuantidade = 0;

        this.tabs = [
            [1, this.$t("__.ts.carregandoAtivos") as string],
            [2, this.$t("__.ts.carregandoEmbal") as string],
            [3, this.$t("__.ts.carregandoCaps") as string],
        ];
        this.activeTab = this.$t("__.Crud.formulapadrao.list_vue_html.ativos") as string;

        this.collapsedFieldsets = this.manipulacaoContext.isItemFromVenda;

        this.showWarningCapsula = false;
        this.showWarningEmbalagem = false;
        this.isFormaVolume = false;
        this.carregouLinhasIniciais = false;
        this.valorTabelado = 0;

        this.setKitEmbalagemTabName(null);
        this.setCapsulaTabName(null);

        this.clearItemTable();

        if (this.itemVenda) {
            this.$focusField("formaFarmaceuticaId");
        }

        if (!this.model.posologiaId && !this["isPosologiaTextoLivre"]) {
            if (this.preLoadList.posologias && this.preLoadList.posologias.length > 0) {
                this.model.posologiaId = this.preLoadList.posologias.find(posologia => posologia.padrao)?.id ?? null;
            }
        }

        this.unsubscribe = this.$store.subscribe(mutation => {
            switch (mutation.type) {
                case ManipulacaoContextMutations.UPDATE_MANIPULACAO:
                    this.loadManipulacaoContext();
                    break;
            }
        });

        // @ts-ignore
        this.unsubscribeActions = this.$store.subscribeAction({
            after: action => {
                this.loadManipulacaoContext();
                switch (action.type) {
                    case ManipulacaoContextActions.UPDATE_KIT_EMBALAGEM_ID:
                        if (this.manipulacaoContext.embalagens.length > 0) {
                            this.setKitEmbalagemTabName(
                                this.getObservacaoEmbalagem(this.manipulacaoContext.embalagens[0].observacao),
                            );
                        }
                        this.model.kitEmbalagemId = this.manipulacao.kitEmbalagemId;
                        break;
                    case ManipulacaoContextActions.UPDATE_MANIPULACAO:
                        this.model.kitEmbalagemId = this.manipulacao.kitEmbalagemId;
                        break;
                    case ManipulacaoContextActions.ADD_ITEM_MANIPULACAO:
                    case ManipulacaoContextActions.CALCULAR_QSP:
                    case ManipulacaoContextActions.REMOVE_ATIVOS_ASSOCIADOS:
                    case ManipulacaoContextActions.REMOVE_ITEM_MANIPULACAO:
                    case ManipulacaoContextActions.UPDATE_FORMULA_BASE:
                        this.model.itens = this.manipulacao.itens;
                        break;
                }
            },
        });
    }

    private async clearItemTable() {
        await this.loadAbasManipulacao();
        if (this.manipulacaoItemTableComponent) {
            this.manipulacaoItemTableComponent.forEach(p => p.clear());
        }
    }

    @Watch("itensVenda", { deep: true })
    private onChangeItensVenda() {
        this.itensVendaApp = this.itensVenda as Array<VendaItemModel>;
    }

    private collapsedStatus(collapsed) {
        this.collapsedFieldsets = collapsed;
    }

    private async validaItensConsolida() {
        this.loadManipulacaoContext();

        for (let index = 0; index < this.manipulacaoItemTableComponent.length; index++) {
            if (this.manipulacaoItemTableComponent[index].itens.length == 0) {
                if (index > 0) {
                    let mensagem = this.$t("__.ts.naoForamEncontr") as string;
                    switch (index) {
                        case 1:
                            mensagem += this.$t("__.ts.embalagens") as string;
                            break;
                        case 2:
                            mensagem += this.$t("__.ts.capsulas") as string;
                            break;
                    }
                    mensagem += this.$t("__.ts.paraEstaManip") as string;
                    mensagem += this.$t("__.ts.desejaConsolid") as string;

                    const response = await this.$showQuestion(this.$t("__.ts.atencao"), mensagem);
                    if (response) {
                        await this.consolidar().withLoading();
                    } else {
                        return false;
                    }
                } else {
                    this.$showError(this.$t("__.ts.atencao"), this.$t("__.ts.naoEncontrAtivosManipul"));
                    return false;
                }
            } else if (index > 0 && this.alterouVolume) {
                this.$showWarning(this.$t("__.ts.atencao"), this.$t("__.ts.identAltVolumManipu"));

                await this.consolidar().withLoading();

                if (!this.manipulacao.consolidado) {
                    return false;
                }

                return true;
            }
        }

        if (!this.manipulacao.consolidado) {
            this.$showWarning(this.$t("__.ts.atencao"), this.$t("__.ts.necessarioConsolidar"));

            return false;
        }

        return true;
    }

    private possuiItensValorZerado() {
        this.loadManipulacaoContext();

        return this.manipulacao.itens.some(p => p.produtoPai == null && (p.valorTotal == 0 || p.valorTotal == null));
    }

    private async prosseguirComValoresZerados() {
        this.loadManipulacaoContext();

        const produtoComValorZero = this.manipulacao.itens
            .filter(p => p.valorTotal == 0 || p.valorTotal == null)
            .map(p => p.produtoDescricao);
        let mensagem = "";

        if (produtoComValorZero.length == 1) {
            mensagem = this.$t("__.ts.osProds") + produtoComValorZero[0] + this.$t("__.ts.naoPossuiVlr");
        } else {
            let descricoes = "";
            produtoComValorZero.forEach((p, index) => {
                if (index < produtoComValorZero.length - 1) {
                    if (index == 0) {
                        descricoes = p;
                    } else {
                        descricoes += `, ${p}`;
                    }
                } else {
                    descricoes += this.$t("__.ts.e") + p;
                }
            });
            mensagem = this.$t("__.ts.osProds") + descricoes + this.$t("__.ts.naoPossVlr");
        }

        const question = this.$t("__.ts.desejProssegAssim");
        const html = `${mensagem}<br>${question}`;

        return this.$showQuestionWithHTML(this.$t("__.ts.atencao"), html);
    }

    public async onAdicionarManipulacao() {
        await this.loadAbasManipulacao();
        this.forceUpdateCapsulaItemTable();

        for (let index = 0; index < this.manipulacaoItemTableComponent.length; index++) {
            const isValid = await this.manipulacaoItemTableComponent[index].validateAll();
            if (!isValid) {
                return false;
            }
        }

        this.loadManipulacaoContext();

        if (await this.validar(true)) {
            return false;
        }

        try {
            const isValid = await this.$validator.validateAll();
            if (isValid) {
                let isValidItens = true;
                if (this.manipulacaoContext.isItemFromVenda) {
                    isValidItens = await this.validaItensConsolida();
                }

                if (isValidItens) {
                    const itensSemValor = this.possuiItensValorZerado();
                    if (itensSemValor) {
                        if (!(await this.prosseguirComValoresZerados())) {
                            return false;
                        }
                    }

                    if (!this.manipulacao.itens.some(x => x.tipoCalculo == TiposCalculoManipulacao.Qsp)) {
                        await this.$showWarning(
                            this.$t("__.ts.atencao"),
                            this.$t("__.Components.parent.manipulacao.edit_vue_html.naoFoiInformadoRecipienteQSP"),
                        );
                    }

                    if (this.manipulacaoContext.isItemFromVenda) {
                        this.manipulacao.formaFarmaceuticaDescricao = this.formaFarmaceutica.descricao;
                        this.$emit("inclusao-item", this.manipulacao, this.continuo, this.dataVencimentoContinuo);

                        this.clear();

                        this.unsubscribeAll();

                        return true;
                    } else {
                        await this.save();
                    }
                } else {
                    return false;
                }
            } else {
                this.$focusErrorField();
            }
        } catch {}

        return false;
    }

    public async save() {
        this.loadManipulacaoContext();
        //Verifica se ha alteração no valor total para alocar a diferença em desconto ou acrescimo e impedir alterações no valor final
        if (
            !this.manipulacaoContext.isItemFromVenda &&
            this.manipulacao.situacao === SituacoesManipulacaoOrdem.Aprovado
        ) {
            if (this.model.valorTotal != this.oldValorTotal) {
                const diff = this.model.valorTotal - this.oldValorTotal;

                const response = await this.$showQuestion(
                    "Valor alterado!",
                    "Valor total da manipulação foi alterado, deseja que o sistema prossiga e ajustes os valores necessários?",
                );

                if (response) {
                    if (diff > 0) {
                        this.manipulacao.desconto += diff;
                        this.manipulacao.valorTotal -= diff;
                    } else if (diff < 0) {
                        this.manipulacao.acrescimo += diff * -1;
                        this.manipulacao.valorTotal += diff * -1;
                    }
                    const mensagem = `O usuário ${
                        this.usuarioLogado.nome
                    } alterou os valores da manipulação e concordou com os ajustes no desconto/acréscimo em ${editDateWithTime(
                        new Date(),
                    )}`;

                    await this.logEntidadeService.insert(this.model.id, 2, mensagem).withLoading().resolveWithoutJSON();
                } else {
                    return;
                }
            }
        }

        try {
            const sucesso = await this.service[!this.model.id ? "insert" : "update"](
                this.manipulacao,
            ).resolveWithoutJSON();

            if (sucesso) {
                await this.$showInclusaoUpdate(this.model.id);
                this.$router.back();
            }
        } catch {}
    }

    private back() {
        this.loadManipulacaoContext();

        if (this.manipulacaoContext.isItemFromVenda) {
            this.clear();
            this.$emit("cancelar-inclusao-manipulacao");
        } else {
            this.$router.back();
        }
    }

    private onCancelar() {
        this.$router.push("/manipulacaoordem-cancelamento/" + this.manipulacao.id);
    }

    private removeFormulaPadrao() {
        this.model.formulaPadraoId = null;
        this.model.formulaPadraoDescricao = null;
    }

    //@ts-ignore
    @Watch("model.prescritorId")
    private async onPrescritorId() {
        await this.carregarConvenio().withLoading();

        if (this.model.prescritorId && this.prescritorModel.id != this.model.prescritorId) {
            this.prescritorModel = await new PrescritorService()
                .get(this.model.prescritorId)
                .resolveWithJSON<PrescritorModel>();

            if (this.prescritorModel.observacao) {
                await this.$showWarning(
                    this.$t("__.Components.parent.manipulacao.edit_vue_html.msgPrescritorObs"),
                    this.prescritorModel.observacao,
                );
            }
        }
    }

    @Watch("model.pacienteId")
    private async onPacienteId() {
        if (this.model.pacienteId) {
            const alergias = await new PacienteService().getAlergias(this.model.pacienteId).resolveWithText();
            if (alergias) {
                await this.$showWarningHtml(this.$t("__.ts.atencaoPacientePossuiAlergias"), alergias);
            }

            const condicoes = await new PacienteService().getCondicoes(this.model.pacienteId).resolveWithText();
            if (condicoes) {
                await this.$showWarningHtml(this.$t("__.ts.atencaoPacientePossui"), condicoes);
            }
        }
    }

    //@ts-ignore
    @Watch("model.formulaPadraoId")
    private async onFormulaPadraoChange() {
        if (this.model.formulaPadraoId != 0 && this.model.formulaPadraoId != null) {
            try {
                const data = await new FormulaPadraoService()
                    .get(this.model.formulaPadraoId)
                    .withLoading()
                    .resolveWithJSON<FormulaPadraoModel>();

                this.model.formulaPadraoDescricao = data.descricao;

                this.valorTabelado = 0;
                if (data.tipoFormulaPadrao === TiposFormulaPadrao.PreVendaTabelada) {
                    this.valorTabelado = data.valorTabelado;
                }

                this.model.isPreVenda =
                    data.tipoFormulaPadrao == TiposFormulaPadrao.PreVenda ||
                    data.tipoFormulaPadrao == TiposFormulaPadrao.PreVendaTabelada;

                this.dispatch(ManipulacaoContextActions.UPDATE_MANIPULACAO, { ...this.model });
            } catch {}
        }
    }

    //@ts-ignore
    @Watch("model.volume")
    private onVolumeChanged() {
        if (this.manipulacaoContext.uploadingEditingModel) {
            return;
        }

        this.calcularVolumeTotal();

        if (this.isFormaVolume) {
            this.loadManipulacaoContext();

            this.setValorFormaFarmaceutica(this.formaFarmaceutica);
        }
        this.calcularValorTotal();
    }

    //@ts-ignore
    @Watch("model.valorTotal")
    private async onTotalChanged() {
        if (this.model.descontoConvenio > 0) {
            await this.carregarConvenio().withLoading();
        }

        this.loadManipulacaoContext();

        if (this.manipulacaoContext.isItemFromVenda) {
            this.$emit("atualiza-total-item", arithmeticHelper.round(this.model.valorTotal));
        }
    }

    //@ts-ignore
    @Watch("model.quantidade")
    private async onQuantidadeChanged() {
        if (this.manipulacaoContext.uploadingEditingModel) {
            return;
        }

        this.verificaQuantidade();

        this.calcularVolumeTotal();

        this.loadManipulacaoContext();

        this.setValorFormaFarmaceutica(this.formaFarmaceutica);

        if (!this.model.id && this.manipulacaoContext.ativos.length >= 1) {
            await this.addItensIniciaisByFormaFarmaceutica();
        }

        if (this.model.quantidade > 0 && this.model.fatorMultQuantidade > 1) {
            this.quantidadeCalculada = this.model.quantidade * this.model.fatorMultQuantidade;
        }
    }

    private onChangeQuantidadeInput(value: number) {
        if (this.carregouLinhasIniciais) {
            this.model.quantidade = value;
        }
    }

    //@ts-ignore
    @Watch("manipulacao.fatorMultQuantidade")
    private async onFatorMultQuantidadehanged() {
        this.model.fatorMultQuantidade = this.manipulacao.fatorMultQuantidade;
        if (this.model.quantidade > 0 && this.model.fatorMultQuantidade > 1) {
            this.quantidadeCalculada = this.model.quantidade * this.model.fatorMultQuantidade;
        }
    }

    //@ts-ignore
    @Watch("model.situacao")
    private async onSituacaoChange() {
        this.posologiaOptions = await this.preLoadList.posologiaCombo(
            this.model.situacao != SituacoesManipulacaoOrdem.Concluido,
        );
    }

    private getValorFormaFatorMult(valorSoma: number) {
        this.loadManipulacaoContext();

        const quantidade = this.isFormaVolume
            ? this.model.volumeTotal
            : this.model.quantidade * (this.model.fatorMultQuantidade > 0 ? this.model.fatorMultQuantidade : 1);

        return this.formaFarmaceuticaService.getValorFormaFarmaceutica(
            valorSoma,
            quantidade,
            this.formaFarmaceutica.fatorMultiplicadorAdicional,
            this.formaFarmaceutica.fatorDecaimento,
            this.formaFarmaceutica.fatorCompensacao,
        );
    }

    private async verificaQuantidade() {
        this.loadManipulacaoContext();

        if (
            this.carregouGuias &&
            (this.formaFarmaceutica.quantidadeMaxima != null ||
                this.formaFarmaceutica.quantidadeMinima != null ||
                this.model.quantidade == 0 ||
                this.model.quantidade == null)
        ) {
            if (
                this.formaFarmaceutica.quantidadeMinima != null &&
                this.model.quantidade < this.formaFarmaceutica.quantidadeMinima
            ) {
                //quantidade minima nao atingida
                this.setError(
                    this.quantidadeStrId,
                    this.$t("__.ts.qtdMinPermitida") + this.formaFarmaceutica.quantidadeMinima.toString() + ".",
                );
            } else if (
                this.formaFarmaceutica.quantidadeMaxima != null &&
                this.model.quantidade > this.formaFarmaceutica.quantidadeMaxima
            ) {
                //quantidade maxima ultrapassada
                this.setError(
                    this.quantidadeStrId,
                    this.$t("__.ts.qtdMaxPermitida") + this.formaFarmaceutica.quantidadeMaxima.toString() + ".",
                );
            } else if (this.model.quantidade == 0 || this.model.quantidade == null) {
                this.setError(this.quantidadeStrId, this.$t("__.ts.qtdDeveMaiorZero") as string);
            } else {
                if (this.collapsedFieldsets) {
                    this.collapsedFieldsets = false;
                    await this.carregaLinhasIniciais();
                }
                this.setError(this.quantidadeStrId, "", true);
            }
        } else {
            if (this.collapsedFieldsets) {
                this.collapsedFieldsets = false;
                await this.carregaLinhasIniciais();
            }
            this.setError(this.quantidadeStrId, "", true);
        }
    }

    private setError(idCampo: string, msgTooltip = "", removeErro = false) {
        const possuiErros = this.hasError(idCampo);

        if (removeErro && possuiErros) {
            this.$validator.errors.remove(idCampo);
        } else if (!possuiErros && !removeErro) {
            const errorFields = { field: idCampo, msg: msgTooltip, id: idCampo };
            this.$validator.errors.add(errorFields);
        }
    }

    private hasError(idCampo: string) {
        const form = document.getElementById(idCampo).parentElement;
        const className = form.getAttribute("class");
        return className.indexOf("error") >= 0;
    }

    //@ts-ignore
    @Watch("manipulacao.valorProdutos")
    private onValorProdutosChanged() {
        if (this.manipulacaoContext.uploadingEditingModel) {
            return;
        }

        this.calcularValorTotal();
    }

    //@ts-ignore
    @Watch("model.valorFormaFarmaceutica")
    private onValorFormaFarmaceuticaChanged() {
        if (this.manipulacaoContext.uploadingEditingModel) {
            return;
        }

        this.calcularValorTotal();
    }

    //@ts-ignore
    @Watch("model.acrescimo")
    private onAcrescimoChanged() {
        this.calcularValorTotal(true);
    }

    private onChangeAcrescimo() {
        this.model.acrescimoPercentual = 0;
    }

    //@ts-ignore
    @Watch("model.acrescimoPercentual")
    private onAcrescimoPercentualChanged() {
        if (this.model.acrescimoPercentual) {
            this.loadManipulacaoContext();

            const total =
                this.manipulacao.valorFormaFarmaceutica + this.manipulacao.valorProdutos + this.manipulacao.desconto;
            this.model.acrescimo = arithmeticHelper.round((total * this.model.acrescimoPercentual) / 100, 2);
            this.calcularValorTotal(true);
        }
    }

    //@ts-ignore
    @Watch("model.desconto")
    private onDescontoChanged() {
        this.calcularValorTotal(true);
    }

    private onChangeDesconto() {
        this.model.descontoPercentual = 0;
    }

    //@ts-ignore
    @Watch("model.descontoPercentual")
    private onDescontoPercentualChanged() {
        if (this.model.descontoPercentual) {
            this.loadManipulacaoContext();

            const total =
                this.manipulacao.valorFormaFarmaceutica + this.manipulacao.valorProdutos + this.manipulacao.acrescimo;
            this.model.desconto = arithmeticHelper.round((total * this.model.descontoPercentual) / 100, 2);
            this.calcularValorTotal(true);
        }
    }

    public calcularValorTotal(isChangeFromUser = false) {
        this.dispatch(ManipulacaoContextActions.UPDATE_MANIPULACAO, { ...this.model });

        const valorMinimoMult = this.getValorFormaFatorMult(this.formaFarmaceutica.valorAdicional);
        this.model = getValorTotal(
            this.manipulacao,
            this.formaFarmaceutica.fatorMultiplicadorAdicional,
            valorMinimoMult,
            this.formaFarmaceutica.valorMinimo,
        );

        this.utilizandoValorMinimo = false;
        if (this.formaFarmaceutica.fatorMultiplicadorAdicional > 0) {
            if (this.model.valorTotal == valorMinimoMult && this.model.valorTotal != 0) {
                this.utilizandoValorMinimo = true;
            }
        } else if (this.model.valorTotal == this.formaFarmaceutica.valorMinimo && this.model.valorTotal != 0) {
            this.utilizandoValorMinimo = true;
        }

        if (isChangeFromUser && this.utilizandoValorMinimo && !this.manipulacaoContext.uploadingEditingModel) {
            this.$showWarning(this.$t("__.ts.aviso"), this.$t("__.ts.valorMinimoFormaFarmAtingido"));
        }

        if (this.valorTabelado > 0 && this.model.valorTotal > 0 && this.valorTabelado !== this.model.valorTotal) {
            const totalValorTabelado = this.valorTabelado * this.model.numeroFormulas;

            this.model.acrescimo = 0;
            this.model.desconto = 0;

            this.model = getValorTotal(
                this.manipulacao,
                this.formaFarmaceutica.fatorMultiplicadorAdicional,
                valorMinimoMult,
                this.formaFarmaceutica.valorMinimo,
            );

            if (totalValorTabelado > this.model.valorTotal) {
                this.model.acrescimo = totalValorTabelado - this.model.valorTotal;
            }
            if (totalValorTabelado < this.model.valorTotal) {
                this.model.desconto = this.model.valorTotal - totalValorTabelado;
            }

            this.model = getValorTotal(
                this.manipulacao,
                this.formaFarmaceutica.fatorMultiplicadorAdicional,
                valorMinimoMult,
                this.formaFarmaceutica.valorMinimo,
            );
        }
    }

    private calcularVolumeTotal() {
        if (this.model.volume && this.model.quantidade) {
            const newVolume = getVolumeTotal(this.model);

            this.alterouVolume = false;
            if (this.model.volumeTotal != newVolume) {
                this.alterouVolume = true;
            }

            this.model.volumeTotal = newVolume;
        }
    }

    private async onChangeFormaFarmaceuticaId(formaFarmaceuticaId: number) {
        if (formaFarmaceuticaId > 0 && formaFarmaceuticaId !== this.model.formaFarmaceuticaId) {
            const ativosAssociados = this.manipulacaoContext.ativos.filter(p => p.formaFarmaceuticaId > 0);

            if (ativosAssociados.length > 0) {
                const response = await this.$showQuestion(
                    this.$t("__.ts.atencao"),
                    this.$t("__.ts.desejRemoverAssocAnterior"),
                );

                if (response) {
                    await this.dispatch(ManipulacaoContextActions.REMOVE_ATIVOS_ASSOCIADOS_FORMA_FARMACEUTICA, null);
                }
            }

            await this.dispatch(ManipulacaoContextActions.REMOVE_ATIVOS_ASSOCIADOS, null);
            await this.loadAbasManipulacao();
            await this.manipulacaoItemTableComponent[0].clearItensAssociados();
        }
    }

    //@ts-ignore
    @Watch("model.formaFarmaceuticaId")
    private async onFormaFarmaceuticaIdChanged(newValue: number, oldvalue: number) {
        if (oldvalue > 0) {
            this.quantidadeCalculada = 0;
            this.model.fatorMultQuantidade = 0;
        }

        this.adicionouItensPadroesIni = false;

        if (!newValue || newValue == 0 || this.manipulacaoContext.uploadingEditingModel) {
            return;
        }

        this.utilizaMolde = false;

        let formaFarmaceutica = null;

        try {
            formaFarmaceutica = await this.formaFarmaceuticaService
                .get(newValue)
                .withLoading()
                .resolveWithJSON<FormaFarmaceuticaModel>();

            await this.getDadosFormaFarmaceutica(formaFarmaceutica, true);
        } catch {}
    }

    private async getDadosFormaFarmaceutica(data: FormaFarmaceuticaModel, changeFormaFarmaceuticaId = false) {
        if (!this.model.id) {
            this.setValorFormaFarmaceutica(data);

            if (this.model.destino == DestinosManipulacao.Venda && !this.model.dataValidadeAlterada) {
                // Verifica se há itens e se todos eles possuem quantidadePesagem
                if (this.model.itens.length > 0 && this.model.itens.every(item => item.quantidadePesagem > 0)) {
                    const dataValidade = await this.service.getDataValidade(this.model).resolveWithJSON<Date>();
                    this.model.dataValidade = new Date(dataValidade);
                } else {
                    this.model.dataValidade = addDays(data.validadeDias, new Date());
                }
            }
        }

        this.dispatch(ManipulacaoContextActions.SET_CONCENTRACAO_MAXIMA, data.concentracaoMaxima);

        this.utilizaMolde = false;

        switch (data.tipoFormaFarmaceutica) {
            case TiposFormaFarmaceutica.Capsula:
                this.dispatch(ManipulacaoContextActions.SET_IS_CAPSULA, true);
                this.isFormaVolume = false;
                if (data.unidadeMedidaVolumeId != null) {
                    this.model.unidadeMedidaId = data.unidadeMedidaVolumeId;
                } else {
                    this.model.unidadeMedidaId = this.unidadeMedidasOptions.filter(it => it["sigla"] == "ml")[0][
                        "value"
                    ];
                }
                if (!this.manipulacaoContext.uploadingEditingModel) {
                    this.model.volume = 1;
                }
                break;
            default:
                this.utilizaMolde = data.utilizaMolde;
                if (this.utilizaMolde) {
                    this.loadMoldes().withLoading();
                    this.model.unidadeMedidaId = this.unidadeMedidasOptions.filter(it => it["sigla"] == "ml")[0][
                        "value"
                    ];
                }

                this.dispatch(ManipulacaoContextActions.SET_IS_CAPSULA, false);
                this.dispatch(ManipulacaoContextActions.REMOVE_ALL_ITENS_BY_TYPE, ItemTableType.CAPSULA);
                this.model.itens = this.model.itens.filter(p => p.tipoItem != TiposItemManipulacao.Capsula);
                this.isFormaVolume = false;
                if (data.tipoFormaFarmaceutica == TiposFormaFarmaceutica.Volume) {
                    this.model.quantidade = 1;
                    this.isFormaVolume = true;
                }

                if (changeFormaFarmaceuticaId && data.unidadeMedidaVolumeId != null && !this.utilizaMolde) {
                    this.model.unidadeMedidaId = data.unidadeMedidaVolumeId;
                }
        }

        if (!this.utilizaMolde) {
            this.model.moldeId = null;
        }

        this.atualizarTabs();

        this.dispatch(ManipulacaoContextActions.SET_FORMA_FARMACEUTICA, data);
    }

    //@ts-ignore
    @Watch("model.moldeId")
    private async onMoldeIddChanged(newValue: number) {
        if (!newValue || newValue == 0 || this.manipulacaoContext.uploadingEditingModel) {
            return;
        }

        const molde = this.moldeOptions.filter(p => p["value"] == this.model.moldeId);
        if (molde.length > 0) {
            this.moldeId = molde[0]["value"];
            this.moldeProdutoId = molde[0]["produtoQSPId"];
            this.model.volume = molde[0]["volumeInterno"];
        }
    }

    //@ts-ignore
    @Watch("model.numeroFormulas")
    private async onNumeroFormulasChange() {
        if (this.manipulacaoContext.uploadingEditingModel) {
            return;
        }

        this.calcularVolumeTotal();
        this.setValorFormaFarmaceutica(this.formaFarmaceutica);
    }

    private async onChangeUsaMarkupEquinos() {
        this.model.consolidado = false;
    }

    private setValorFormaFarmaceutica(formaFarmaceutica: FormaFarmaceuticaModel) {
        if (formaFarmaceutica.fatorMultiplicadorAdicional > 0) {
            this.model.valorFormaFarmaceutica = this.getValorFormaFatorMult(formaFarmaceutica.valorAdicional);
        } else {
            this.model.valorFormaFarmaceutica = formaFarmaceutica.valorAdicional;
        }

        this.model.valorFormaFarmaceutica *= this.model.numeroFormulas ?? 1;
    }

    private async atualizarTabs() {
        this.loadManipulacaoContext();

        const embalagem =
            this.tabKitEmbalagemName != null ? this.tabKitEmbalagemName : (this.$t("__.ts.embalagens") as string);
        const tabKitEmbalagem = [2, embalagem] as [number, string];

        const capsula = this.tabCapsulaName != null ? this.tabCapsulaName : (this.$t("__.ts.capsulas") as string);
        const tabKitCapsula = [3, capsula] as [number, string];

        if (this.manipulacaoContext.isCapsula) {
            this.tabs = [[1, "Ativos"], tabKitEmbalagem, tabKitCapsula];
        } else {
            this.tabs = [[1, "Ativos"], tabKitEmbalagem];
        }

        this.$nextTick(() => {
            this.setTabWithoutFocus();
        });
    }

    private setTabWithoutFocus() {
        const docTabs = document.getElementsByClassName("tabs__link");
        for (let i = 0; i < docTabs.length; i++) {
            docTabs[i].setAttribute("tabindex", "-1");
        }
    }

    public async loadModel(
        manipulacao: ManipulacaoOrdemModel,
        dataVencimentoContinuo: Date,
        continuo = false,
        isPreVenda = false,
    ) {
        // Espera a montagem do componente
        await Delay(500);

        this.$focusField(this.quantidadeStrId);
        this.continuo = continuo;
        this.dataVencimentoContinuo = dataVencimentoContinuo;

        this.clear();

        if (!isPreVenda) {
            await this.dispatch(ManipulacaoContextActions.SET_UPLOADING_EDITING_MODEL, true);
        } else {
            await this.dispatch(ManipulacaoContextActions.SET_UPLOADING_PREVENDA, true);
        }

        this.model = new ManipulacaoOrdemModel();
        this.model.updateFrom(manipulacao);
        if (!this.model.estoqueId) {
            this.model.estoqueId = this.estoqueId;
        }
        this.isHomeopatia = this.model.homeopatia;
        this.model.isPreVenda = isPreVenda;
        this.dispatch(ManipulacaoContextActions.UPDATE_MANIPULACAO, { ...this.model });

        await this.setItensLoadModel();

        if (isPreVenda) {
            await this.validaItensInativosPreVenda();
        }

        this.verificaQuantidade();

        this.collapsedFieldsets = false;

        this.forceUpdateCapsulaItemTable();

        if (isPreVenda) {
            await this.dispatch(ManipulacaoContextActions.SET_UPLOADING_PREVENDA, false);
        } else {
            const item = await this.service
                .loadDadosProdutoLoteItens(this.model, this.manipulacaoContext.isTransferencia)
                .resolveWithJSON<ManipulacaoOrdemModel>();

            this.model = new ManipulacaoOrdemModel();
            this.model.updateFrom(item);
            await this.dispatch(ManipulacaoContextActions.UPDATE_MANIPULACAO, { ...item });

            await this.dispatch(ManipulacaoContextActions.SET_UPLOADING_EDITING_MODEL, false);
        }
    }

    private async setItensLoadModel() {
        this.loadManipulacaoContext();

        this.moldeId = this.model.moldeId;

        this.utilizaMolde = this.moldeId > 0;
        if (this.utilizaMolde) {
            await this.loadMoldes();
        }

        if (this.manipulacaoContext.capsulas.length > 0) {
            this.dispatch(ManipulacaoContextActions.SET_IS_CAPSULA, true);
            this.capsulaDefinidaDescricao = this.manipulacaoContext.capsulas[0].produtoDescricao;
            this.volumeCapsula = this.model.volume;
            this.quantidadeItemCapsulas = this.manipulacaoContext.capsulas[0].quantidade;
        }

        this.setTabsName();

        await this.loadAbasManipulacao();
        this.manipulacaoItemTableComponent.forEach(p => p.clear());

        let concentracaoMaxima = 0;
        try {
            const formaFarmaceutica = await this.formaFarmaceuticaService
                .get(this.model.formaFarmaceuticaId)
                .resolveWithJSON<FormaFarmaceuticaModel>();

            this.formaFarmaceutica.updateFrom(formaFarmaceutica);

            await this.getDadosFormaFarmaceutica(formaFarmaceutica);

            if (formaFarmaceutica.tipoFormaFarmaceutica == TiposFormaFarmaceutica.Volume) {
                this.isFormaVolume = true;
                this.model.quantidade = 1;
                concentracaoMaxima = formaFarmaceutica.concentracaoMaxima;
            }

            this.calcularValorTotal();
        } catch {}

        if (concentracaoMaxima > 0) {
            const ativosPCDose = this.manipulacao.itens.filter(
                p => p.tipoCalculo == TiposCalculoManipulacao.Dose && p.produtoPai == null,
            );
            if (ativosPCDose.length > 0 && this.model.quantidade > 0) {
                const concentracaoMaximaAtivo = Math.max(...ativosPCDose.map(p => p.volumeDose));
                if (concentracaoMaximaAtivo > 0 && concentracaoMaximaAtivo != this.model.quantidade) {
                    concentracaoMaxima = 0;
                }
            }
        }

        this.dispatch(ManipulacaoContextActions.SET_CONCENTRACAO_MAXIMA, concentracaoMaxima);
    }

    private async validaItensInativosPreVenda() {
        const ativosInativos = this.manipulacaoContext.ativos.filter(p => !p.produto.ativo);

        for await (const ativo of ativosInativos) {
            await this.$showWarning(
                this.$t("__.ts.atencao"),
                this.$t("__.ts.produtoInativoPreVenda").toString().replace("{produto}", ativo.produto.descricao),
            );

            await this.dispatch(ManipulacaoContextActions.REMOVE_ITEM_MANIPULACAO, {
                type: ItemTableType.ATIVO,
                index: this.manipulacaoContext.ativos.findIndex(p => p.produtoId == ativo.produtoId),
            });
        }
    }

    private async load() {
        this.model = new ManipulacaoOrdemModel();

        if (this.modelId > 0) {
            this.dispatch(ManipulacaoContextActions.SET_UPLOADING_EDITING_MODEL, true);

            try {
                const data = await this.service
                    .get(this.modelId)
                    .withLoading()
                    .resolveWithJSON<ManipulacaoOrdemModel>();
                this.model.updateFrom(data);
                this.nomeVendedorText = data.nomeVendedor;

                await this.loadAbasManipulacao();

                this.loadManipulacaoContext();

                if (!this.manipulacaoContext.isItemFromVenda && this.model.itens.length > 0) {
                    for (let i = 0; i < this.model.itens.length; i++) {
                        if (this.model.itens[i].produtoId > 0) {
                            const prod = this.preLoadList.produtos.filter(p => p.id == this.model.itens[i].produtoId);
                            if (prod.length > 0) {
                                const tipoProduto = this.converteTipoProdutoManipulacao(prod[0].tipoProduto);
                                if (
                                    tipoProduto != this.model.itens[i].tipoItem &&
                                    this.model.itens[i].tipoItem != TiposItemManipulacao.FormulaPadrao
                                )
                                    this.model.itens[i].tipoItem = tipoProduto;
                            }
                        }
                    }
                }

                this.dispatch(ManipulacaoContextActions.UPDATE_MANIPULACAO, { ...this.model });

                await this.setItensLoadModel();

                this.carregouGuias = true;
                this.collapsedFieldsets = false;

                this.forceUpdateCapsulaItemTable();

                if (this.CalledByShortCut) {
                    this.$emit("manipulacao-mounted");
                }
            } catch {}

            this.dispatch(ManipulacaoContextActions.SET_UPLOADING_EDITING_MODEL, false);
        }
        this.oldValorTotal = this.model.valorTotal;
    }

    // @ts-ignore
    @Watch("activeTab")
    private onChangeTab() {
        if (this.activeTab == this.tabCapsulaName) {
            this.forceUpdateCapsulaItemTable();
        }
    }

    private converteTipoProdutoManipulacao(tipo: TiposProduto) {
        let tipoProduto = TiposItemManipulacao.MateriaPrima;
        switch (tipo) {
            case TiposProduto.MateriaPrima:
                tipoProduto = TiposItemManipulacao.MateriaPrima;
                break;
            case TiposProduto.Embalagem:
                tipoProduto = TiposItemManipulacao.Embalagem;
                break;
            case TiposProduto.Capsula:
                tipoProduto = TiposItemManipulacao.Capsula;
                break;
            default:
        }
        return tipoProduto;
    }

    private setTabsName() {
        //busca nome do kit pela observacao dos itens de embalagens e capsulas inseridos pelos kits
        const embalagens = this.model.itens.filter(
            p => p.tipoItem == TiposItemManipulacao.Embalagem && p.observacao != null,
        );
        if (embalagens.length > 0) {
            this.setKitEmbalagemTabName(this.getObservacaoEmbalagem(embalagens[0].observacao));
        }

        //se ainda nao achou, busca pelo subgrupo recipiente
        if (!this.tabKitEmbalagemName) {
            const recipientes = this.model.itens.filter(
                p => p.tipoItem == TiposItemManipulacao.Embalagem && p.subGrupoItem == SubGrupoProduto.Recipiente,
            );
            if (recipientes.length > 0) {
                this.setKitEmbalagemTabName(this.getObservacaoEmbalagem(recipientes[0].produtoDescricao));
            }
        }

        //capsula com observacao sao as inseridas automaticamente
        const capsulas = this.model.itens.filter(
            p => p.tipoItem == TiposItemManipulacao.Capsula && p.observacao != null,
        );
        if (capsulas.length > 0) {
            this.setCapsulaTabName(capsulas[0].produtoDescricao);
        }

        if (!this.tabCapsulaName) {
            if (this.model.itens.some(p => p.tipoItem == TiposItemManipulacao.Capsula)) {
                this.setCapsulaTabName(
                    this.model.itens.filter(p => p.tipoItem == TiposItemManipulacao.Capsula)[0].produtoDescricao,
                );
            }
        }
    }

    private async onCorCapsulaChange({ produtoId, capsulaId, tipoCapsula }: ManipulacaoOrdemItemModel) {
        this.loadManipulacaoContext();

        if (this.model.id || this.manipulacaoContext.capsulas.length == 0) {
            return;
        }

        const capsula =
            this.manipulacaoContext.capsulas.length == 1
                ? this.manipulacaoContext.capsulas[0]
                : this.manipulacaoContext.capsulas.find(item => item.produtoId == produtoId);

        const redefinirCapsula =
            !capsula.buscouCorCapsula || capsula.capsulaId != capsulaId || capsula.tipoCapsula != tipoCapsula;

        if (redefinirCapsula) {
            const corProduto = await this.getCorCapsula(capsulaId, tipoCapsula);

            if (corProduto) {
                capsula.buscouCorCapsula = true;
                capsula.produtoId = corProduto.id;
                capsula.capsulaId = capsulaId;
                capsula.tipoCapsula = tipoCapsula;
                capsula.valor = corProduto.valorVenda;
                this.setCapsulaTabName(corProduto.descricao);
            } else {
                capsula.buscouCorCapsula = false;
            }
        } else {
            const produto = await this.produtoService.get(produtoId).resolveWithJSON<ProdutoModel>();
            this.setCapsulaTabName(produto.descricao);
        }
    }

    private async getCorCapsula(capsulaId: number, tipoCapsula: TiposCapsula): Promise<ProdutoModel> {
        let capsulas: ManipulacaoOrdemItemModel[] = [];
        this.itensVendaApp.forEach(item => {
            item.manipulacaoOrdem.itens
                .filter(p => p.tipoItem == TiposItemManipulacao.Capsula && p.tipoCapsula == tipoCapsula)
                .forEach(item => capsulas.push(item));
        });

        this.loadManipulacaoContext();

        this.manipulacaoContext.capsulas
            .filter(p => p.tipoCapsula == tipoCapsula && p.observacao != "Cápsula localizada automaticamente")
            .forEach(item => capsulas.push(item));

        if (this.manipulacaoContext.capsulas.length > 0) {
            capsulas = capsulas.filter(p => p.produtoId != this.manipulacaoContext.capsulas[0].produtoId);
        }

        return await this.service
            .getCorCapsula(capsulas, this.manipulacao.itens, capsulaId, tipoCapsula, this.manipulacao.quantidade)
            .resolveWithJSON<ProdutoModel>();
    }

    private async addItemEmbalagem() {
        if (!this.model.kitEmbalagemId) {
            await this.dispatch(ManipulacaoContextActions.UPDATE_KIT_EMBALAGEM_ID, 0);

            if (this.manipulacaoContext.embalagens.length > 0) {
                this.setKitEmbalagemTabName(
                    this.getObservacaoEmbalagem(this.manipulacaoContext.embalagens[0].observacao),
                );
            }
        } else {
            this.loadManipulacaoContext();

            try {
                const data = await this.service
                    .definirEmbalagem(
                        this.manipulacao,
                        this.alterouVolume ? null : this.model.kitEmbalagemId,
                        this.manipulacao.usaMarkupEquinos,
                        this.manipulacaoContext.isTransferencia,
                    )
                    .resolveWithJSON<ManipulacaoOrdemModel>();

                if (data.kitEmbalagemId && data.kitEmbalagemId != this.model.kitEmbalagemId) {
                    this.showWarningEmbalagem = true;

                    await this.trocarEmbalagemQuestion(data.kitEmbalagemId);
                } else {
                    await this.dispatch(ManipulacaoContextActions.UPDATE_ITENS_KIT_EMBALAGEM, data.itens);
                }
            } catch {}
        }
    }

    private getObservacaoEmbalagem(observacao: string) {
        if (observacao) {
            //extrair kit da observacao
            const index = observacao.indexOf(":");
            if (index >= 0) {
                observacao = observacao.substring(index + 1, observacao.length);
            }
        }
        return observacao;
    }

    private onMovimentoEstoque() {
        this.$router.push("/movimentacaoestoque-edicao/" + this.model.movimentacaoEstoqueId);
    }

    public async carregaLinhasIniciais() {
        await this.loadAbasManipulacao();

        this.loadManipulacaoContext();

        if (this.tabs.length > 0) {
            if (this.manipulacaoContext.isItemFromVenda && this.manipulacaoContext.ativos.length == 0) {
                this.manipulacaoItemTableComponent[0].addEmptyRow();
                this.carregouLinhasIniciais = true;
            }
        }

        if (this.formaFarmaceutica.itensPadroes.length > 0) {
            await this.addItensIniciaisByFormaFarmaceutica();
        }
    }

    private async onAddNewPaciente(closeModal) {
        if (await this.pacienteComponent.save()) {
            closeModal();
        }
    }

    private async onAddNewPosologia(closeModal) {
        while (!this.$refs.posologiaComponent) await Delay(5);
        this.posologiaComponent = this.$refs.posologiaComponent as PosologiaComponent;

        if (await this.posologiaComponent.save()) {
            closeModal();
        }
    }

    private async onAddNewPrescritor(closeModal) {
        if (await this.prescritorComponent.save()) {
            closeModal();
        }
    }

    private async openComboNewPaciente() {
        this.showPaciente = true;

        while (!this.$refs.pacienteComponent) await Delay(5);
        this.pacienteComponent = this.$refs.pacienteComponent as PacienteComponent;

        this.pacienteComponent.modelId = 0;
        this.pacienteComponent.load();
    }

    private async openComboNewPosologia() {
        this.posologiaComponent.modelId = 0;
        this.posologiaComponent.load();
    }

    private async openComboNewPrescritor() {
        this.showPrescritor = true;

        while (!this.$refs.prescritorComponent) await Delay(5);
        this.prescritorComponent = this.$refs.prescritorComponent as PrescritorComponent;

        this.prescritorComponent.modelId = 0;
        this.prescritorComponent.load().withLoading();
    }

    private async openComboEditPrescritor(id: number) {
        this.showPrescritor = true;

        while (!this.$refs.prescritorComponent) await Delay(5);
        this.prescritorComponent = this.$refs.prescritorComponent as PrescritorComponent;

        this.prescritorComponent.modelId = id;
        this.prescritorComponent.load().withLoading();
    }

    private async openComboEditPosologia(id: number) {
        while (!this.$refs.posologiaComponent) await Delay(5);
        this.posologiaComponent = this.$refs.posologiaComponent as PosologiaComponent;

        this.posologiaComponent.modelId = id;
        this.posologiaComponent.load();
    }

    private async openComboEditPaciente(id: number) {
        this.showPaciente = true;

        while (!this.$refs.pacienteComponent) await Delay(5);
        this.pacienteComponent = this.$refs.pacienteComponent as PacienteComponent;

        this.pacienteComponent.modelId = id;
        this.pacienteComponent.load();
    }

    private async onPacienteSaveOk(modelNovoPaciente: PacienteModel) {
        this.model.pacienteId = modelNovoPaciente.id;
        this.model.pacienteNome = modelNovoPaciente.nome;
    }

    private async onPosologiaSaveOk(modelNovaPosologia: PosologiaModel) {
        await this.loadPosologia();
        this.model.posologiaId = modelNovaPosologia.id;
    }

    private async onPrescritorSaveOk(modelNovoPrescritor: PrescritorModel) {
        this.model.prescritorId = modelNovoPrescritor.id;
    }

    private async onDetalhesCusto() {
        this.showDetalhes = true;

        while (!this.$refs.shortcutDetalhesComponent) await Delay(5);
        this.shortcutDetalhesComponent = this.$refs.shortcutDetalhesComponent as ShortcutComponent;

        while (!this.$refs.detalhesItemComponent) await Delay(5);
        this.detalhesItemComponent = this.$refs.detalhesItemComponent as DetalhesItemComponent;

        this.loadManipulacaoContext();

        await this.detalhesItemComponent.setManipulacaoOrdem(this.manipulacao);

        this.shortcutDetalhesComponent.title = this.$t("__.ts.detalhCustosManipu") as string;

        this.shortcutDetalhesComponent.show();
    }

    public async onLotes() {
        this.loadManipulacaoContext();

        if (this.manipulacao.movimentacaoEstoqueId > 0) {
            this.showLote = true;

            while (!this.$refs.shortcutLoteComponent) await Delay(5);
            this.shortcutLoteComponent = this.$refs.shortcutLoteComponent as ShortcutComponent;

            while (!this.$refs.manipulacaoLoteItemComponent) await Delay(5);
            this.manipulacaoLoteItemComponent = this.$refs.manipulacaoLoteItemComponent as ManipulacaoLoteItemComponent;

            this.shortcutLoteComponent.title = this.$t("__.ts.lotesEstqManipul") as string;
            this.manipulacaoLoteItemComponent.loadModel(
                this.manipulacao.itens,
                this.manipulacao.movimentacaoEstoqueId,
                this.manipulacao.id,
            );

            this.shortcutLoteComponent.show();
        }
    }

    private async onConfirmLote() {
        if (await this.manipulacaoLoteItemComponent.atualizarDadosLotes()) {
            this.shortcutLoteComponent.hide();

            await this.$showWarning(this.$t("__.ts.atencao"), this.$t("__.ts.necessarioConsolidar"));
        }

        if (this.CalledByShortCut) {
            this.$emit("manipulacao-confirm-lote");
        }
    }

    private async updateProdutoLoteItens(dados: { id: number; produtoLoteId: number }[]) {
        this.model.itens.forEach(item => {
            const dado = dados.find(p => p.id == item.id);
            if (dado) {
                item.produtoLoteId = dado.produtoLoteId;
            }
        });

        await this.dispatch(ManipulacaoContextActions.UPDATE_MANIPULACAO_ITENS, {
            itens: this.model.itens,
            atualizaDadosLote: true,
        });
    }

    private onConfirmDetalhes() {
        this.shortcutDetalhesComponent.hide();
    }

    private hideLote() {
        if (this.CalledByShortCut) {
            this.$emit("manipulacao-confirm-lote");
        }
    }

    private hideImpressao() {
        if (this.CalledByShortCut) {
            this.$emit("manipulacao-confirm-impressao");
        }
    }

    private async onCloseLote() {
        this.shortcutLoteComponent.hide();
    }

    private getMsgKits(kitId: number) {
        const kitDiff = this.preLoadList.kitsEmbalagem.filter(p => p.id == kitId);

        let kitNameDiff = "";
        if (kitDiff.length > 0) {
            kitNameDiff = kitDiff[0].descricao;
        }

        const kitAtual = this.preLoadList.kitsEmbalagem.filter(p => p.id == this.model.kitEmbalagemId);
        let kitNameAtual = "";
        if (kitAtual.length > 0) {
            kitNameAtual = kitAtual[0].descricao;
        }

        let msg = "";
        if (kitNameDiff != "" && kitNameAtual != "") {
            msg =
                this.$t("__.ts.sisteVerifKit") +
                kitNameDiff +
                this.$t("__.ts.maiorCompatibEmbalagem") +
                kitNameAtual +
                "</b>).";
        } else if (kitNameDiff != "") {
            msg = this.$t("__.ts.sisteVerifKit") + kitNameDiff + this.$t("__.ts.maiorCompatibForma");
        }

        return msg;
    }

    private async trocarEmbalagemQuestion(kitId: number) {
        const msg = this.getMsgKits(kitId);

        const question = this.$t("__.ts.desejSubstKit");
        const html = `${msg}<br>${question}`;

        try {
            const response = await this.$showQuestionWithHTML(this.$t("__.ts.atencao"), html);

            if (response) {
                this.showWarningEmbalagem = false;

                await this.dispatch(ManipulacaoContextActions.UPDATE_KIT_EMBALAGEM_ID, kitId);
            } else if (this.model.kitEmbalagemId) {
                await this.dispatch(ManipulacaoContextActions.UPDATE_KIT_EMBALAGEM_ID, this.model.kitEmbalagemId);
            }

            this.setKitEmbalagemTabName(this.getObservacaoEmbalagem(this.manipulacaoContext.embalagens[0].observacao));
        } catch {}
    }

    private getMsgCapsula() {
        this.loadManipulacaoContext();

        let capsulaNameAtual = "";
        if (this.manipulacaoContext.capsulas.length > 0) {
            capsulaNameAtual = this.manipulacaoContext.capsulas[0].produtoDescricao;
        }

        let msg = "";
        if (this.capsulaDefinidaDescricao != "" && capsulaNameAtual != "") {
            msg =
                this.$t("__.ts.sisteVerifCapsula") +
                this.capsulaDefinidaDescricao +
                this.$t("__.ts.volMaisAdeqCaps") +
                capsulaNameAtual +
                "</b>).";
        } else if (this.capsulaDefinidaDescricao != "") {
            msg =
                this.$t("__.ts.sisteVerifCapsula") + this.capsulaDefinidaDescricao + this.$t("__.ts.volMaisAdeqForma");
        }

        return msg;
    }

    private async trocarCapsulaQuestion() {
        const msg = this.getMsgCapsula();
        const question = this.$t("__.ts.desejSubstCaps");

        const html = `${msg}<br>${question}`;

        try {
            const response = await this.$showQuestionWithHTML(this.$t("__.ts.atencao"), html);

            if (response) {
                this.showWarningCapsula = false;

                this.dispatch(ManipulacaoContextActions.REMOVE_ALL_ITENS_BY_TYPE, ItemTableType.CAPSULA);

                await this.addItemCapsula();
            }

            this.forceUpdateCapsulaItemTable();
        } catch {}
    }

    private getMsgMolde(destacadoHtml = false) {
        const moldeDiff = this.moldeOptions.filter(p => p["value"] == this.moldeId)[0]["text"];
        const moldeAtual = this.moldeOptions.filter(p => p["value"] == this.model.moldeId)[0]["text"];

        if (destacadoHtml) {
            return (
                this.$t("__.ts.sisteVerifMolde") + moldeDiff + this.$t("__.ts.volMaisAdeqMolde") + moldeAtual + "</b>)."
            );
        }

        return this.$t("__.ts.sisteVerifMoldee") + moldeDiff + this.$t("__.ts.volMaisAdeqMoldee") + moldeAtual + ").";
    }

    private async trocarMoldeQuestion() {
        const mensagem = this.getMsgMolde(true);

        const question = this.$t("__.ts.desejSubstMolde");
        const html = `${mensagem}<br>${question}`;

        try {
            const response = await this.$showQuestionWithHTML(this.$t("__.ts.atencao"), html);

            if (response) {
                this.model.moldeId = this.moldeId;

                await this.addMolde();
            }
        } catch {}
    }

    private async verificaProdutosLotes() {
        this.reConsolidar = false;

        this.loadManipulacaoContext();

        const produtosVerificacao = new Array<ProdutoEstoqueConferenciaModel>();
        this.manipulacao.itens
            .filter(p => p.movimentaEstoque)
            .forEach(p => {
                const produtoConferencia = new ProdutoEstoqueConferenciaModel();
                produtoConferencia.id = p.produtoId;
                produtoConferencia.tipoCalculo = p.tipoCalculo;
                produtoConferencia.quantidade =
                    p.tipoCalculo == TiposCalculoManipulacao.Percentual ? p.quantidadePesagem : p.quantidade;
                produtoConferencia.unidadeMedidaManipulacaoId = p.unidadeMedidaManipulacaoId;
                produtoConferencia.produtoLoteId = p.produtoLoteId;
                produtosVerificacao.push(produtoConferencia);
            });

        if (produtosVerificacao.length > 0) {
            let dataVerificados = await new ProdutoEstoqueConferenciaService()
                .listProdutosVerificados(produtosVerificacao)
                .resolveWithJSON<ProdutoEstoqueConferenciaModel[]>();

            dataVerificados = dataVerificados.filter(p => !p.ignorarControleEstoque);
            const produtosEstoqueBaixo = dataVerificados.filter(
                p => p.statusEstoque == StatusEstoque.Baixo && p.quantidade > 0,
            );
            let produtosSemEstoque = dataVerificados.filter(
                p => p.statusEstoque == StatusEstoque.SemEstoque && p.quantidade > 0,
            );

            const hasErrors = produtosEstoqueBaixo.length > 0 || produtosSemEstoque.length > 0;
            if (hasErrors) {
                if (this.alertarEstoqueMinimo && produtosEstoqueBaixo.length > 0) {
                    let html = "";

                    if (produtosEstoqueBaixo.length == 1) {
                        html = `${this.$t("__.ts.prod")} <b>${produtosEstoqueBaixo[0].descricao}</b> ${this.$t(
                            "__.ts.estaComQtdAbaixoMinCad",
                        )}`;
                    } else {
                        const descricoes = this.getProdutosSemEstoqueDescricao(produtosEstoqueBaixo);

                        html = `${this.$t("__.ts.segProdsQtdsEstqAbaixMin")} <b>${descricoes}</b>.`;
                    }

                    this.$notifyWarn(html, this.$t("__.ts.avisEstqBaixo") as string);
                }

                if (produtosSemEstoque.length > 0) {
                    let html = "";

                    const itens = await this.service
                        .getFormulaPadraoByProdutos(
                            produtosSemEstoque
                                .filter(p => p.tipoCalculo === TiposCalculoManipulacao.Qsp)
                                .map(p => p.id),
                        )
                        .resolveWithJSON<ManipulacaoOrdemItemModel[]>();

                    for (const item of itens.filter(p => p.formulaPadraoId)) {
                        const produtoSemEstoque = produtosSemEstoque.find(p => p.id == item.produtoId);
                        html = `${this.$t("__.ts.prod")} <b>${produtoSemEstoque.descricao}</b> ${this.$t(
                            "__.ts.naoPossQtdEstqSufic",
                        )}\n${this.$t("__.ts.substituirPorFormulaBase")} <b>${item.formulaPadrao.descricao}</b>?`;

                        const response = await this.$showQuestionWithHTML(this.$t("__.ts.atencao"), html);
                        if (response) {
                            const manipulacaoItem = {
                                ...this.manipulacao.itens.find(p => p.produtoId == produtoSemEstoque.id),
                            };

                            let index = this.manipulacaoContext.ativos.filter(
                                p => p.produtoAssociadoPaiId == null && p.produtoId != manipulacaoItem.produtoId,
                            ).length;

                            const desmembrado = this.manipulacaoContext.ativos.find(
                                p => p.produtoId == produtoSemEstoque.id && p.formulaPadraoId,
                            );

                            if (desmembrado) {
                                index =
                                    this.manipulacaoContext.ativos.findIndex(
                                        p => p.formulaPadraoId == desmembrado.formulaPadraoId,
                                    ) + 1;
                            } else {
                                await this.dispatch(ManipulacaoContextActions.REMOVE_ITEM_MANIPULACAO, {
                                    type: ItemTableType.ATIVO,
                                    index: this.manipulacaoContext.ativos.findIndex(
                                        p => p.produtoId == produtoSemEstoque.id,
                                    ),
                                });
                            }

                            manipulacaoItem.formulaPadraoId = item.formulaPadraoId;
                            manipulacaoItem.tipoItem = TiposItemManipulacao.FormulaPadrao;
                            manipulacaoItem.movimentaEstoque = false;
                            manipulacaoItem.produtoAssociadoPaiId = null;
                            manipulacaoItem.formaFarmaceuticaId = null;

                            await this.dispatch(ManipulacaoContextActions.REMOVE_ATIVOS_ASSOCIADOS, null);

                            const ativos = [...this.manipulacaoContext.ativos];

                            await this.dispatch(
                                ManipulacaoContextActions.REMOVE_ALL_ITENS_BY_TYPE,
                                ItemTableType.ATIVO,
                            );

                            let itemIndex = 0;
                            for await (const item of ativos) {
                                await this.dispatch(ManipulacaoContextActions.ADD_ITEM_MANIPULACAO, {
                                    item,
                                    type: ItemTableType.ATIVO,
                                    index: itemIndex,
                                });
                                itemIndex++;
                            }
                            await this.dispatch(ManipulacaoContextActions.ADD_ITEM_MANIPULACAO, {
                                item: manipulacaoItem,
                                type: ItemTableType.ATIVO,
                                index,
                            });

                            this.reConsolidar = true;
                        }

                        produtosSemEstoque = produtosSemEstoque.filter(p => p.id != item.produtoId);
                    }

                    if (produtosSemEstoque.length > 0) {
                        if (produtosSemEstoque.length == 1) {
                            html = `${this.$t("__.ts.prod")} <b>${produtosSemEstoque[0].descricao}</b> ${this.$t(
                                "__.ts.naoPossQtdEstqSufic",
                            )}`;
                        } else {
                            const descricoes = this.getProdutosSemEstoqueDescricao(produtosSemEstoque);

                            html = `${this.$t("__.ts.segProdsNaoPossQtdEstqSufic")} <b>${descricoes}</b>.`;
                        }

                        await this.showMensagemEstoque(this.$t("__.ts.semEstoque") as string, html);
                    }
                }
            }
        }
    }

    private getProdutosSemEstoqueDescricao(produtos: ProdutoEstoqueConferenciaModel[]) {
        let descricoes = "";
        produtos.forEach(p => {
            if (descricoes == "") {
                descricoes = p.descricao;
            } else {
                descricoes += `, ${p.descricao}`;
            }
        });

        const lastVirgula = descricoes.lastIndexOf(",");

        //remove a ultima virgula e coloca o e no com os espaços no lugar
        let descProds = descricoes;
        if (lastVirgula >= 0) {
            descProds =
                descricoes.substring(0, lastVirgula) +
                this.$t("__.ts.e") +
                descricoes.substring(lastVirgula + 1, descricoes.length);
        }

        return descProds;
    }

    private async showMensagemEstoque(title: string, html = "") {
        await this.$showAlert({
            title,
            html,
            icon: "warning",
            confirmButtonColor: AlertColor.GRAY,
            allowOutsideClick: false,
        });
    }

    //por enquanto so valida numeros, mais tarde se houver necessidade, evoluir para strings e etc
    private tabIndex(valor) {
        if (valor != null && valor > 0) return -1;

        return null;
    }

    private async alterarVolumeCapsula(volume: number, descricao: string) {
        if (descricao != "" && this.tabCapsulaName != descricao) {
            this.setCapsulaTabName(descricao);
        }

        this.model.volume = volume;
        this.volumeCapsula = volume;
        this.calcularVolumeTotal();
    }

    private async getItemByFormaFarmaceuticaItem(itemPadrao: FormaFarmaceuticaItemPadraoModel) {
        const newItem = new ManipulacaoOrdemItemModel();
        newItem.produtoId = itemPadrao.produtoId;
        newItem.tipoCalculo = itemPadrao.tipoCalculo;
        newItem.quantidadeDose = itemPadrao.quantidade;
        newItem.subGrupoItem = itemPadrao.subGrupo;
        newItem.formaFarmaceuticaId = itemPadrao.formaFarmaceuticaId;
        newItem.tipoItem = TiposItemManipulacao.MateriaPrima;

        if (newItem.produtoId) {
            this.loadManipulacaoContext();

            const produto = await this.produtoService
                .get(newItem.produtoId)
                .withLoading()
                .resolveWithJSON<ProdutoModel>();

            const { item } = getItemManipulacaoByProduto(
                produto,
                newItem,
                this.manipulacaoContext,
                ItemTableType.ATIVO,
            );

            if (!produto.ativo) {
                await this.$showWarning(
                    this.$t("__.ts.atencao"),
                    this.$t("__.ts.produtoInativoFormaFarmaceutica").toString().replace("{produto}", produto.descricao),
                );
                return null;
            }

            newItem.updateFrom(item);

            if (
                newItem.produtoId > 0 &&
                newItem.subGrupoItem == SubGrupoProduto.VeiculoExcipiente &&
                itemPadrao.desmembrado
            ) {
                const formaPadraoItem = this.preLoadList.formulasPadrao.filter(p => p.produtoId == newItem.produtoId);

                if (formaPadraoItem.length > 0) {
                    newItem.formulaPadraoId = formaPadraoItem[0].id;
                    if (newItem.formulaPadraoId > 0) {
                        newItem.tipoItem = TiposItemManipulacao.FormulaPadrao;
                    }
                }
            }
        }

        return newItem;
    }

    private async getItemByModelQSP(produtoId: number) {
        const newItem = new ManipulacaoOrdemItemModel();
        newItem.produtoId = produtoId;
        newItem.tipoCalculo = TiposCalculoManipulacao.Qsp;
        newItem.tipoItem = TiposItemManipulacao.MateriaPrima;

        if (newItem.produtoId) {
            this.loadManipulacaoContext();

            const produto = await this.produtoService
                .get(newItem.produtoId)
                .withLoading()
                .resolveWithJSON<ProdutoModel>();

            const { item } = getItemManipulacaoByProduto(
                produto,
                newItem,
                this.manipulacaoContext,
                ItemTableType.ATIVO,
            );

            if (!produto.ativo) {
                await this.$showWarning(
                    this.$t("__.ts.atencao"),
                    this.$t("__.ts.produtoInativoMolde").toString().replace("{produto}", produto.descricao),
                );
                return null;
            }

            newItem.updateFrom(item);
        }

        return newItem;
    }

    public async consolidar() {
        this.loadManipulacaoContext();

        this.model.consolidado = false;

        if (await this.validar()) {
            return false;
        }

        await this.dispatch(ManipulacaoContextActions.SET_IS_CONSOLIDANDO, true);

        await this.dispatch(ManipulacaoContextActions.REMOVE_ATIVOS_ASSOCIADOS, null);

        // Força a desmonstagem dos componentes de itens associados
        await this.loadAbasManipulacao();
        await this.manipulacaoItemTableComponent[0].clearItensAssociados();

        if (this.model.isPreVenda) {
            const manipulacao = await this.service
                .loadDadosProdutoLoteItens(this.model, this.manipulacaoContext.isTransferencia)
                .resolveWithJSON<ManipulacaoOrdemModel>();

            for (const aba of this.manipulacaoItemTableComponent) {
                await aba.consolidaItens(manipulacao);
            }

            await this.addItensByFormaFarmaceutica(manipulacao);
        } else {
            for (const aba of this.manipulacaoItemTableComponent) {
                await aba.consolidaItens();
            }

            await this.addItensByFormaFarmaceutica();
        }

        if (this.manipulacaoContext.isCapsula) {
            if (this.manipulacaoContext.capsulas.length == 0) {
                await this.addItemCapsula();
            } else {
                await this.addItemCapsula(false);

                if (
                    this.volumeCapsula == this.model.volume &&
                    this.quantidadeItemCapsulas != this.manipulacaoContext.capsulas[0].quantidade
                ) {
                    this.manipulacaoContext.capsulas[0].quantidade = this.quantidadeItemCapsulas;
                    this.manipulacaoContext.capsulas[0].quantidadeDose = this.quantidadeItemCapsulas;
                    this.manipulacaoContext.capsulas[0].quantidadePesagem = this.quantidadeItemCapsulas;
                    this.manipulacaoContext.capsulas[0].valorTotal =
                        this.quantidadeItemCapsulas * this.manipulacaoContext.capsulas[0].valor;
                } else if (this.volumeCapsula != this.model.volume) {
                    this.showWarningCapsula = true;
                    await this.trocarCapsulaQuestion();
                }
            }
        } else if (this.utilizaMolde) {
            await this.addMolde(this.model.moldeId > 0);

            if (this.moldeId != this.model.moldeId && this.moldeId && this.model.moldeId) {
                await this.trocarMoldeQuestion().withLoading();
            }
        }

        await this.addItemEmbalagem();

        await this.dispatch(ManipulacaoContextActions.CALCULAR_QSP, { loadDadosLote: true, ignoreDebounce: true });

        const { observacao, observacaoAvisoOrcamento } = await this.service
            .getObservacao(this.model.itens, this.model.formulaPadraoId)
            .resolveWithJSON<{ observacao: string; observacaoAvisoOrcamento: string }>();

        this.model.observacao = observacao;
        if (this.model.situacao == SituacoesManipulacaoOrdem.Orcamento && !this.model.dataValidadeAlterada) {
            this.model.dataValidade = addDays(this.formaFarmaceutica.validadeDias, new Date());

            const dataValidade = await this.service.getDataValidade(this.model).resolveWithJSON<Date>();
            this.model.dataValidade = new Date(dataValidade);
        }

        if (observacaoAvisoOrcamento) {
            await this.$showAlert({
                title: this.$t("__.Components.parent.manipulacao.edit_vue_html.observacoes").toString(),
                html: observacaoAvisoOrcamento,
                icon: "warning",
                confirmButtonColor: AlertColor.GRAY,
            });
        }

        const volumeTotalAtivos = await getVolumeTotalAtivos(this.manipulacaoContext);

        if (volumeTotalAtivos > this.model.volumeTotal) {
            if (!this.reConsolidarDiferencaVolume) {
                this.reConsolidarDiferencaVolume = true;
                return await this.consolidar().withLoading();
            }

            await this.$showWarning(this.$t("__.ts.atencao"), this.$t("__.ts.validacaoVolume"));
        }
        this.reConsolidarDiferencaVolume = false;

        if (this.manipulacao.situacao == SituacoesManipulacaoOrdem.Orcamento) {
            // Aguarda tempo maior para garantir que os calculos foram executados
            if (this.manipulacaoContext.desmembrados.length > 0) {
                await Delay(1000);
            }

            await this.verificaProdutosLotes();

            if (this.reConsolidar) {
                return await this.consolidar().withLoading();
            }
        }

        const data = await this.produtoService
            .validateIncompatibilidades(
                this.manipulacao.itens.map(p => p.produtoId),
                this.model.formaFarmaceuticaId,
            )
            .resolveWithJSON<{ errors: ValidationErrorModel[]; incompatibilidades: number[] }>();

        if (data.incompatibilidades.length > 0) {
            await showErrorsAsync(data.errors, null);

            const bloquearIncompatibilidades = this.GET_CONFIG_FRANQUIA(Configuracoes.BloquearIncompatibilidades);
            if (bloquearIncompatibilidades && bloquearIncompatibilidades.verdadeiro) {
                return await this.dispatch(ManipulacaoContextActions.SET_IS_CONSOLIDANDO, false);
            }
        }

        const formaIncompativel = await this.service
            .validarFormaFarmaceuticaIncompativel(
                this.manipulacao.itens.map(p => p.produtoId),
                this.model.formaFarmaceuticaId,
            )
            .resolveWithoutJSON();

        if (!formaIncompativel) {
            return await this.dispatch(ManipulacaoContextActions.SET_IS_CONSOLIDANDO, false);
        }

        const kits = await this.produtoService
            .validateIncompatibilidadesKitEmbalagem(
                this.manipulacao.itens.map(p => p.produtoId),
                this.model.kitEmbalagemId,
            )
            .resolveWithJSON<{ errors: ValidationErrorModel[]; incompatibilidades: number[] }>();

        if (kits.incompatibilidades.length > 0) {
            await showErrorsAsync(kits.errors, null);

            const bloquearIncompatibilidades = this.GET_CONFIG_FRANQUIA(Configuracoes.BloquearIncompatibilidades);
            if (bloquearIncompatibilidades && bloquearIncompatibilidades.verdadeiro) {
                return await this.dispatch(ManipulacaoContextActions.SET_IS_CONSOLIDANDO, false);
            }
        }

        this.model.consolidado = true;

        const consolidouComSucesso =
            this.manipulacaoContext.embalagens.length > 0 &&
            (!this.manipulacaoContext.isCapsula ||
                (this.manipulacaoContext.isCapsula && this.manipulacaoContext.capsulas.length > 0));

        if (consolidouComSucesso) {
            await this.$showSuccess(this.$t("__.ts.consolidado"), this.$t("__.ts.consolidouSucess"));
        } else if (this.manipulacaoContext.embalagens.length === 0) {
            await this.$showSuccess(this.$t("__.ts.consolidado"), this.$t("__.ts.consolidouSucessEmbalagem"));
        } else {
            await this.$showError(this.$t("__.ts.atencao"), this.$t("__.ts.consolidNaoSucess"));
        }

        for (let index = 0; index < this.manipulacaoItemTableComponent.length; index++) {
            this.manipulacaoItemTableComponent[index].refreshManipulacaoContext();
        }

        this.alterouVolume = false;

        await this.dispatch(ManipulacaoContextActions.SET_IS_CONSOLIDANDO, false);
    }

    private async addItemCapsula(addItem = true) {
        this.loadManipulacaoContext();

        try {
            const data = await this.service
                .definirCapsula(
                    this.manipulacao,
                    null,
                    this.manipulacao.usaMarkupEquinos,
                    this.manipulacao.forcarMultQuantidade ? this.manipulacao.fatorMultQuantidade : null,
                    this.manipulacaoContext.isTransferencia,
                )
                .resolveWithJSON<ManipulacaoOrdemModel>();

            if (addItem) {
                const item = data.itens[0];

                const produtoCor = await this.getCorCapsula(item.capsulaId, item.tipoCapsula);
                item.buscouCorCapsula = true;
                item.produto = produtoCor;
                item.produtoId = produtoCor.id;
                item.produtoDescricao = produtoCor.descricao;
                item.densidade = produtoCor.densidade;
                item.produtoDiluidoId = produtoCor.produtoDiluidoId;
                item.produtoListaControladoId = produtoCor.listaControladoId;
                item.valor = this.manipulacaoContext.isTransferencia
                    ? produtoCor.valorCusto ?? 0
                    : produtoCor.valorVenda ?? 0;

                await this.dispatch(ManipulacaoContextActions.ADD_ITEM_MANIPULACAO, {
                    item: data.itens[0],
                    type: ItemTableType.CAPSULA,
                    noAddIfExists: true,
                });

                this.showWarningCapsula = false;

                this.model.volume = data.volume;
                this.model.fatorMultQuantidade = data.fatorMultQuantidade;

                this.setCapsulaTabName(data.itens[0].produtoDescricao);
            } else {
                const item = data.itens[0];

                const produtoCor = await this.getCorCapsula(item.capsulaId, item.tipoCapsula);
                item.produtoDescricao = produtoCor.descricao;
                item.valor = produtoCor.valorVenda ?? 0;
            }

            this.capsulaDefinidaDescricao = data.itens[0].produtoDescricao;

            if (this.volumeCapsula != data.volume) {
                this.alterouVolume = true;
            }
            this.volumeCapsula = data.volume;
            this.quantidadeItemCapsulas = data.itens[0].quantidade;
        } catch {}
    }

    private async addMolde(addItem = true) {
        this.loadManipulacaoContext();

        const data = await this.service
            .definirMolde(
                this.manipulacao,
                null,
                this.manipulacao.forcarMultQuantidade ? this.manipulacao.fatorMultQuantidade : null,
            )
            .withLoading()
            .resolveWithJSON<ManipulacaoOrdemModel>();

        this.moldeId = data.moldeId;

        if (!addItem) {
            this.model.volume = data.volume;
            this.model.moldeId = data.moldeId;
            this.model.volumeTotal = data.volumeTotal;
            this.model.fatorMultQuantidade = data.fatorMultQuantidade;
        } else {
            if (this.moldeId == this.model.moldeId) {
                this.model.fatorMultQuantidade = data.fatorMultQuantidade;
            }
        }
    }

    private async addItensByFormaFarmaceutica(manipulacao: ManipulacaoOrdemModel = null) {
        this.loadManipulacaoContext();

        if (this.utilizaMolde && this.moldeProdutoId) {
            const item = await this.getItemByModelQSP(this.moldeProdutoId);

            if (item) {
                await this.dispatch(ManipulacaoContextActions.ADD_ITEM_MANIPULACAO, {
                    item,
                    type: ItemTableType.ATIVO,
                    noAddIfExists: true,
                });
                this.loadManipulacaoContext();
            }
        }

        let itens = this.formaFarmaceutica.itensPadroes.filter(p => p.subGrupo == SubGrupoProduto.VeiculoExcipiente);

        const naoUtilizarExcipiente = await this.getNaoUtilizarExcipiente();
        if (
            naoUtilizarExcipiente ||
            this.manipulacaoContext.ativos.some(p => p.tipoCalculo == TiposCalculoManipulacao.Qsp)
        ) {
            itens = itens.filter(p => p.tipoCalculo != TiposCalculoManipulacao.Qsp);
        }

        for (let index = 0; index < itens.length; index++) {
            const item = await this.getItemByFormaFarmaceuticaItem(itens[index]);

            if (item) {
                await this.dispatch(ManipulacaoContextActions.ADD_ITEM_MANIPULACAO, {
                    item,
                    type: ItemTableType.ATIVO,
                    noAddIfExists: true,
                });
            }
        }

        await this.dispatch(ManipulacaoContextActions.CALCULAR_QSP, { loadDadosLote: true, ignoreDebounce: true });

        this.loadManipulacaoContext();

        for (const aba of this.manipulacaoItemTableComponent) {
            await aba.consolidaItens(manipulacao);
        }
    }

    private async getNaoUtilizarExcipiente() {
        for (const aba of this.manipulacaoItemTableComponent) {
            if (await aba.getNaoUtilizarExcipiente()) {
                return true;
            }
        }
        return false;
    }

    private async addItensIniciaisByFormaFarmaceutica() {
        if (!this.adicionouItensPadroesIni) {
            this.adicionouItensPadroesIni = true;

            this.loadManipulacaoContext();

            const itens = this.formaFarmaceutica.itensPadroes.filter(
                p => p.subGrupo == SubGrupoProduto.Ativo || p.subGrupo == SubGrupoProduto.Flavorizante,
            );

            for (let index = 0; index < itens.length; index++) {
                const item = await this.getItemByFormaFarmaceuticaItem(itens[index]);
                await this.dispatch(ManipulacaoContextActions.ADD_ITEM_MANIPULACAO, {
                    item,
                    type: ItemTableType.ATIVO,
                    origem: ItemTypeOrigem.ITEM_PADRAO_FORMAFARMACEUTICA,
                });
            }
        }
    }

    public async onConfirmPreVenda() {
        while (!this.$refs.preVendaComponent) await Delay(5);
        this.preVendaComponent = this.$refs.preVendaComponent as PreVendaComponent;

        if (await this.preVendaComponent.onAdicionarFormulaPadrao(this.model.estoqueId)) {
            this.shortcutPreVendaComponent.hide();
        }
    }

    private onLoadPreVenda(model: ManipulacaoOrdemModel) {
        this.model = new ManipulacaoOrdemModel();
        this.model.updateFrom(model);

        this.model.itens.forEach(p => (p.isItemFromPreVenda = true));

        this.dispatch(ManipulacaoContextActions.UPDATE_MANIPULACAO, { ...this.model });

        this.$emit("onLoadPreVenda", this.model);
    }

    private async onImprimirManipulacaoModelo(somenteRotulo = false) {
        this.impressaoComponent = this.$refs.impressaoComponent as ImpressaoComponent;

        if (somenteRotulo) {
            await this.impressaoComponent
                .show({
                    modelId: this.model.id,
                    tipoImpressao: "Manipulacao",
                })
                .withLoading();
        } else {
            await this.impressaoComponent
                .show({
                    modelId: this.model.id,
                    tipoImpressao: "Manipulacao",
                    impressaoRotulo: false,
                    impressaoSelecionarModelo: true,
                })
                .withLoading();
        }
    }

    private getRestante(item: ProdutoEstoqueConferenciaModel) {
        if (item.ignorarControleEstoque) return 0;

        const quantidadeProduto = item.quantidade * item.indiceConversao;
        const baixas = item.produtoLotes.length > 0 ? this.getNumSomaBaixas(item) : 0;
        const resto = (quantidadeProduto - baixas).toFixed(4);
        return parseFloat(resto);
    }

    private getNumSomaBaixas(item: ProdutoEstoqueConferenciaModel) {
        return item.produtoLotes.map(p => p.quantidadeBaixa).reduce((a, b) => a + b);
    }

    private async conferirEstoque() {
        let problemasEstoque = false;

        const dataVerificados = await new ProdutoEstoqueConferenciaModel().GetProdutosEstoquesMovimentosVerificados(
            this.model.movimentacaoEstoqueId,
            this.model.id,
        );

        //se quanlquer item possuir restante maior que zero, deve atribuir que o estoque não esta conferido
        dataVerificados.forEach(data => {
            const resto = this.getRestante(data);
            if (resto > 0) {
                this.model.statusEstoque = StatusEstoqueManipulacao.Divergente;
                problemasEstoque = true;
            }
        });

        if (!problemasEstoque) {
            this.model.statusEstoque = StatusEstoqueManipulacao.Conferido;
        }

        return !problemasEstoque;
    }

    private removeItemByIncompatibilidade(incompatibilidade: number) {
        if (this.manipulacaoContext.ativos.some(p => p.produtoId === incompatibilidade)) {
            this.dispatch(ManipulacaoContextActions.REMOVE_ITEM_MANIPULACAO, {
                type: 0,
                index: this.manipulacaoContext.ativos.findIndex(p => p.produtoId === incompatibilidade),
            });
        }
        if (this.manipulacaoContext.embalagens.some(p => p.produtoId === incompatibilidade)) {
            this.dispatch(ManipulacaoContextActions.REMOVE_ITEM_MANIPULACAO, {
                type: 1,
                index: this.manipulacaoContext.embalagens.findIndex(p => p.produtoId === incompatibilidade),
            });
        }
        if (this.manipulacaoContext.capsulas.some(p => p.produtoId === incompatibilidade)) {
            this.dispatch(ManipulacaoContextActions.REMOVE_ITEM_MANIPULACAO, {
                type: 2,
                index: this.manipulacaoContext.capsulas.findIndex(p => p.produtoId === incompatibilidade),
            });
        }
        if (this.manipulacaoContext.desmembrados.some(p => p.produtoId === incompatibilidade)) {
            const item = this.manipulacaoContext.desmembrados.find(p => p.produtoId === incompatibilidade);
            this.dispatch(ManipulacaoContextActions.REMOVE_ITEM_MANIPULACAO, {
                type: 0,
                index: this.manipulacaoContext.ativos.findIndex(p => p.produtoId === item.produtoPai),
            });
        }
    }

    private async onConcluirManipulacao() {
        const conferidoOk = await this.conferirEstoque();

        if (conferidoOk) {
            const sucesso = await this.service.Concluir(this.model).resolveWithoutJSON();
            if (sucesso) {
                await this.$showSuccess(this.$t("__.ts.concl"), this.$t("__.ts.manipuConclSucesso"));
                this.$router.back();
            }
        } else {
            const response = await this.$showQuestion(
                this.$t("__.ts.atencao"),
                `${this.$t("__.ts.manipPossuiDiverg")}${this.$t("__.ts.desejaConfLotes")}`,
            );
            if (response) {
                await this.onLotes().withLoading();
            }
        }
    }

    public async onPreVenda() {
        this.showPreVenda = true;

        while (!this.$refs.shortcutPreVendaComponent) await Delay(5);
        this.shortcutPreVendaComponent = this.$refs.shortcutPreVendaComponent as ShortcutComponent;

        while (!this.$refs.preVendaComponent) await Delay(5);
        this.preVendaComponent = this.$refs.preVendaComponent as PreVendaComponent;

        this.shortcutPreVendaComponent.title = this.$t(
            "__.Components.parent.manipulacao.edit_vue_html.preVenda",
        ) as string;

        this.shortcutPreVendaComponent.show();
    }

    public async carregarConvenio(chamadaExterno = false) {
        this.model.descontoConvenio = 0;

        try {
            const data = await this.convenioService
                .getFromPrescritor(this.model.prescritorId)
                .resolveWithJSON<ConvenioModel>();

            if ((this.situacaoVenda === SituacoesVenda.Orcamento || chamadaExterno) && this.aprovouDesconto) {
                switch (data.tipoDescontoCliente) {
                    case TipoDesconto.Faixa:
                        const faixa = data.faixas.filter(
                            p => this.model.valorTotal >= p.faixaInicio && this.model.valorTotal <= p.faixaFim,
                        );

                        if (faixa) {
                            this.model.descontoConvenio = this.model.valorTotal * (faixa[0].valorDesconto / 100);
                        }
                        break;
                    case TipoDesconto.Dinheiro:
                        this.model.descontoConvenio = data.faixas.filter(
                            p => p.tipoFaixa == TipoConvenioFaixa.Cliente,
                        )[0].valorDesconto;
                        break;
                    default:
                        this.model.descontoConvenio =
                            (data.faixas.filter(d => d.tipoFaixa == TipoConvenioFaixa.Cliente)[0].valorDesconto / 100) *
                            this.model.valorTotal;
                        break;
                }
            }
        } catch {}

        this.calcularValorTotal();
    }

    get temMarkupEquinos() {
        const markupEquinos = this.GET_CONFIG_FRANQUIA(Configuracoes.MarkupEquinos);
        if (markupEquinos) {
            return markupEquinos.valorDecimal > 0;
        }
        return false;
    }

    private setKitEmbalagemTabName(name: string) {
        this.tabKitEmbalagemName = name;
        this.atualizarTabs();
    }

    private setCapsulaTabName(name: string) {
        this.tabCapsulaName = name;
        this.atualizarTabs();
    }

    private async loadFormaFarmaceutica() {
        try {
            const data = await this.formaFarmaceuticaService
                .combo()
                .resolveWithJSON<PaginationModel<FormaFarmaceuticaModel>>();

            this.formaFarmaceuticasOptions = data.list
                .filter(p => (!this.model.id ? p.ativo : true))
                .map(item => ({
                    ...item,
                    ...getFormaFarmaceuticaCombo(item),
                }));
        } catch {}
    }

    private async getPreLoadPack() {
        while (!this.loadedList) await Delay(100);

        this.unidadeMedidasOptions = this.preLoadList.unidadeMedidaCombo();
        this.posologiaOptions = await this.preLoadList.posologiaCombo(
            this.model.situacao != SituacoesManipulacaoOrdem.Concluido,
        );

        this.loadFormasFarmaceuticasOptions();
    }

    @Watch("manipulacaoId")
    private onChangeManipulacaoId() {
        if (this.loadedList) {
            this.loadFormasFarmaceuticasOptions();
        }
    }

    private loadFormasFarmaceuticasOptions() {
        this.formaFarmaceuticasOptions = this.preLoadList.formasFarmaceuticas
            .filter(p => (!this.manipulacaoId ? p.ativo : true))
            .filter(p => p.franquiaId == null || p.franquiaId == this.preLoadList.currentFranquiaId)
            .map(item => ({
                ...item,
                ...getFormaFarmaceuticaCombo(item),
            }));
    }

    private async loadAbasManipulacao() {
        this.manipulacaoItemTableComponent = [];
        while (!this.$refs.manipulacaoItemTableComponent) await Delay(10);
        while (this.$refs.manipulacaoItemTableComponent["length"] != this.tabs.length) await Delay(10);
        for (let index = 0; index < this.$refs.manipulacaoItemTableComponent["length"]; index++) {
            this.manipulacaoItemTableComponent[index] = this.$refs.manipulacaoItemTableComponent[
                index
            ] as ManipulacaoItemTableComponent;
        }
    }

    private forceUpdateCapsulaItemTable() {
        this.loadManipulacaoContext();

        if (this.manipulacaoContext.isCapsula) {
            this.manipulacaoContext.capsulas
                .filter(p => !p.capsulaId)
                .forEach(p => (p.capsulaId = p.produto.capsulaId));
            this.manipulacaoItemTableComponent[2].itens = this.manipulacaoContext.capsulas;
            this.manipulacaoItemTableComponent[2].forceUpdate();
        }
    }

    private async onDesfazerConclusao() {
        try {
            const response = await this.$showQuestion(
                "Tem certeza?",
                "Deseja realmente desfazer a conclusão da manipulação?",
            );
            if (response) {
                const sucesso = await this.service.desfazerConclusao(this.model.id).withLoading().resolveWithoutJSON();
                if (sucesso) {
                    await this.$showSuccess("Sucesso", "Manipulação agora está com o Status Aprovado novamente!");
                    this.$router.back();
                }
            }
        } catch {}
    }

    private onMultiplicarDose() {
        this.shortcutMultiplicarDoseComponent.title = this.$t(
            "__.Components.parent.manipulacao.edit_vue_html.multiplicarNumeroDose",
        ) as string;

        this.shortcutMultiplicarDoseComponent.show();
    }

    private onCloseMultiplicarDose() {
        this.shortcutMultiplicarDoseComponent.hide();
    }

    private onChangeDataValidadeAlterada(value: Date) {
        this.model.dataValidadeAlterada = value !== null;
        this.dispatch(ManipulacaoContextActions.UPDATE_MANIPULACAO, { ...this.model });
    }

    private async validar(isAddManipulacao = false) {
        this.loadManipulacaoContext();

        if (this.hasItensDuplicados()) {
            return true;
        }

        const hasItemVazio = this.manipulacao.itens.some(p =>
            p.tipoItem == TiposItemManipulacao.FormulaPadrao ? !p.formulaPadraoId : !p.produtoId,
        );
        if (hasItemVazio) {
            await this.$showError(
                this.$t("__.ts.erro"),
                this.$t("__.Components.parent.manipulacao.edit_vue_html.todosItensDevemTerProdutoInformado"),
            );
            return true;
        }

        if (!this.manipulacaoContext.isCapsula && !this.utilizaMolde && !this.model.volume) {
            await this.$showError(
                this.$t("__.ts.erro"),
                this.$t("__.Components.parent.manipulacao.edit_vue_html.volumeObrigatorio"),
            );
            return true;
        } else if (!this.model.quantidade) {
            await this.$showError(
                this.$t("__.ts.erro"),
                this.$t("__.Components.parent.manipulacao.edit_vue_html.quantidadeObrigatoria"),
            );
            return true;
        } else if (!this.model.numeroFormulas || this.model.numeroFormulas <= 0) {
            await this.$showError(
                this.$t("__.ts.erro"),
                this.$t("__.Components.parent.manipulacao.edit_vue_html.numeroFormulasMaiorQueZero"),
            );
            return true;
        } else if (isAddManipulacao) {
            const quantidadeZerada = this.manipulacao.itens.find(x => x.quantidade == 0 && x.formulaPadraoId == null);
            if (quantidadeZerada) {
                await this.$showError(
                    this.$t("__.ts.erro"),
                    `${quantidadeZerada.produtoDescricao} ${this.$t(
                        "__.Components.parent.manipulacao.edit_vue_html.quantidadeIgualAZero",
                    )}`,
                );
                return true;
            }

            if (this.continuo && !this.model.dataValidadeReceita) {
                await this.$showError(
                    this.$t("__.ts.erro"),
                    this.$t("__.Components.parent.manipulacao.edit_vue_html.numeroReceitaDeveSerInformadoContinuo"),
                );
                return true;
            }

            const produtosIds = this.model.itens.map(p => p.produtoId);
            const produtos = this.preLoadList.produtos.filter(p => produtosIds.includes(p.id));

            if (produtos.some(p => p.listaControladoId) && !this.model.dataValidadeReceita) {
                await this.$showError(
                    this.$t("__.ts.erro"),
                    this.$t("__.Components.parent.manipulacao.edit_vue_html.numeroReceitaDeveSerInformadoControlado"),
                );
                return true;
            }
        }
        return false;
    }

    private hasItensDuplicados() {
        const produtosAgrupados = new Map<number, ManipulacaoOrdemItemModel[]>();
        this.model.itens.forEach(item => {
            if (item.produtoPai) {
                return;
            }

            if (!produtosAgrupados.has(item.produtoId)) {
                produtosAgrupados.set(item.produtoId, []);
            }
            produtosAgrupados.get(item.produtoId).push(item);
        });

        let hasError = false;
        produtosAgrupados.forEach(itens => {
            if (itens.length > 1) {
                this.$showError(
                    this.$t("__.ts.erro"),
                    this.$t("__.Components.parent.manipulacao.edit_vue_html.ativosDuplicado")
                        .toString()
                        .replace("{produto1}", itens[0].produtoDescricao)
                        .replace("{produto2}", itens[1].produtoDescricao),
                );
                hasError = true;
            }
        });

        return hasError;
    }

    private async dispatch(action: ManipulacaoContextActions, data: unknown) {
        await this.$store.dispatch(action, { uuid: this.uuid, data });

        this.loadManipulacaoContext();
    }

    public loadManipulacaoContext() {
        this.manipulacaoContext = this.GET_MANIPULACAO_CONTEXT(this.uuid);
        this.manipulacao = this.manipulacaoContext.manipulacao;
        this.formaFarmaceutica = this.manipulacaoContext.formaFarmaceutica;
    }

    private async loadColaboradores() {
        try {
            const data = await new ColaboradorService().combo().resolveWithJSON<PaginationModel<ColaboradorModel>>();
            this.colaboradoresOptions = data.list.map(item => ({
                value: item.id,
                text: item.nome,
            }));
        } catch {}
    }

    public unsubscribeAll() {
        this.unsubscribe();
        this.unsubscribeActions();

        for (let index = 0; index < this.manipulacaoItemTableComponent.length; index++) {
            this.manipulacaoItemTableComponent[index].unsubscribeAll();
        }
    }

    private validarVisibilidadeBotao(botao: string) {
        if (botao == "consolidar") {
            return (
                this.model.situacao < 2 &&
                this.model.destino == 0 &&
                this.model.isPreVendaEditavel &&
                !this.manipulacaoContext.isDesabilitado
            );
        }

        if (botao == "conferirLotes") {
            return (
                this.VALIDAR_PERMISSAO_USUARIO("manipulacoes_venda", "ConferirLotesManipulacao") &&
                this.model.movimentacaoEstoqueId > 0 &&
                this.model.itens.length > 0 &&
                !this.manipulacaoContext.isDesabilitado
            );
        }

        if (botao == "detalhesCusto") {
            return (
                this.VALIDAR_PERMISSAO_USUARIO("manipulacoes_venda", "ConferirCustosManipulacao") &&
                this.model.itens.length > 0 &&
                this.model.isPreVendaEditavel &&
                !this.manipulacaoContext.isDesabilitado
            );
        }

        if (botao == "concluir") {
            return (
                this.VALIDAR_PERMISSAO_USUARIO("manipulacoes_venda", "ConcluirManipulacao") &&
                this.model.movimentacaoEstoqueId != null &&
                this.model.situacao != 5 &&
                this.model.situacao != 2
            );
        }

        if (botao == "desfazerConclusao") {
            return this.VALIDAR_PERMISSAO_USUARIO("manipulacoes_venda", "ConcluirManipulacao");
        }

        if (botao == "imprimir") {
            return (
                this.VALIDAR_PERMISSAO_USUARIO("manipulacoes_venda", "ImprimirManipulacao") &&
                !this.manipulacaoContext.isItemFromVenda &&
                this.modelId > 0
            );
        }

        if (botao == "imprimirRotulo") {
            return this.VALIDAR_PERMISSAO_USUARIO("manipulacoes_venda", "ImprimirRotuloManipulacao");
        }

        if (botao == "cancelar") {
            return this.VALIDAR_PERMISSAO_USUARIO("vendas", "CancelarVenda");
        }
    }

    private async mounted() {
        this.uuid = generateUUID();
        this.$store.dispatch(ManipulacaoContextActions.ADD_NEW_CONTEXT, this.uuid);

        await this.$store.dispatch(SessionActions.LOAD_USUARIO_LOGADO);
        this.isEstereis = await this.GET_IS_ESTEREIS();

        this.posologiaComponent = this.$refs.posologiaComponent as PosologiaComponent;
        this.shortcutMultiplicarDoseComponent = this.$refs.shortcutMultiplicarDoseComponent as ShortcutComponent;

        const alertarEstoqueMinimo = this.GET_CONFIG_FRANQUIA(Configuracoes.AlertarEstoqueMinimo);
        if (alertarEstoqueMinimo) {
            this.alertarEstoqueMinimo = alertarEstoqueMinimo.verdadeiro;
        }
        this.tabs = [
            [1, this.$t("__.ts.carregandoAtivos") as string],
            [2, this.$t("__.ts.carregandoEmbal") as string],
            [3, this.$t("__.ts.carregandoCaps") as string],
        ];

        this.loadManipulacaoContext();

        if (this.manipulacaoContext.isItemFromVenda && this.indexVenda != null) {
            this.quantidadeStrId = "quantidade" + this.indexVenda.toString();
        }

        await this.loadAbasManipulacao();

        this.clear();

        Promise.all([this.getPreLoadPack()])
            .withLoading()
            .then(async () => {
                this.nomeVendedorText = this.nomeVendedor;

                this.loadManipulacaoContext();
                this.loadColaboradores();
                if (!this.manipulacaoContext.isItemFromVenda) {
                    if (this.$route.params.id || this.manipulacaoId > 0) {
                        this.modelId = this.$route.params.id ? +this.$route.params.id : +this.manipulacaoId;

                        this.load().withLoading();
                    } else {
                        this.$router.back();
                    }
                } else {
                    this.carregouGuias = true;

                    this.atualizarTabs();
                }

                if (this.manipulacaoContext.isItemFromVenda) {
                    this.$emit("manipulacao-mounted");
                }
            })
            .catch(() => {});
    }
}
