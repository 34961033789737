import checkboxComponent from "@/components/child/form/checkbox.vue";
import dataTooltipComponent from "@/components/child/form/datatooltip.vue";
import { Component } from "@/decorators";
import BancoModel from "@/models/bancoModel";
import BancoService from "@/services/bancoService";

import EditComponentBase from "../editComponentBase";

@Component({
    components: {
        checkboxComponent,
        dataTooltipComponent,
    },
})
export default class BancoEditComponent extends EditComponentBase<BancoModel> {
    private async beforeMount() {
        await this.setProps(new BancoService(), "bancos", new BancoModel());
    }
}
