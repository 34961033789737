var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "crud crud-edit" },
    [
      !_vm.CalledByShortCut
        ? _c(
            "actionBarComponent",
            {
              attrs: {
                title: _vm.$t("__.Crud.prescritor.edit_vue_html.precritorEdit"),
                slots: 2,
                showSave: !_vm.somenteConsulta,
              },
              on: { onSave: _vm.save },
              scopedSlots: _vm._u([
                {
                  key: "buttonSlot1",
                  fn: function (ref) {
                    var open = ref.open
                    return _vm.model.id
                      ? _c(
                          "div",
                          {
                            attrs: {
                              title: _vm.$t("__.ts.historicoAlteracoes"),
                            },
                            on: {
                              click: function ($event) {
                                open(
                                  1,
                                  _vm.$t("__.ts.historicoAlteracoes"),
                                  "lg-modal"
                                )
                              },
                            },
                          },
                          [
                            _c("i", {
                              staticClass: "fa fa-history",
                              attrs: { slot: "button", "aria-hidden": "true" },
                              slot: "button",
                            }),
                          ]
                        )
                      : _vm._e()
                  },
                },
              ]),
            },
            [
              _vm.validarVisibilidadeBotoes("replicar-filiais")
                ? _c(
                    "div",
                    {
                      attrs: {
                        slot: "buttonSlot0",
                        title: _vm.$t("__.ts.replicarFiliais"),
                      },
                      on: {
                        click: function ($event) {
                          _vm.onReplicarFiliais()
                        },
                      },
                      slot: "buttonSlot0",
                    },
                    [
                      _c("i", {
                        staticClass: "fa fa-clone",
                        attrs: { slot: "button", "aria-hidden": "true" },
                        slot: "button",
                      }),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                { attrs: { slot: "modalSlot1" }, slot: "modalSlot1" },
                [
                  _c("logEntidadeComponent", {
                    attrs: { registroId: _vm.modelId, entidade: 6 },
                  }),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "form",
        {
          attrs: { novalidate: "novalidate" },
          on: {
            submit: function ($event) {
              $event.preventDefault()
              _vm.save()
            },
          },
        },
        [
          _c(
            "fieldset-component",
            {
              attrs: {
                title: _vm.$t("__.Crud.prescritor.edit_vue_html.dadosGerais"),
                collapsed: false,
              },
            },
            [
              _c("div", { attrs: { slot: "rows" }, slot: "rows" }, [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-sm-3" }, [
                    _c("div", { staticClass: "form-group" }, [
                      _c(
                        "label",
                        { staticClass: "control-label", attrs: { for: "id" } },
                        [
                          _vm._v(
                            "\n                                " +
                              _vm._s(
                                _vm.$t("__.Crud.prescritor.edit_vue_html.cod")
                              ) +
                              "\n                            "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.model.id,
                            expression: "model.id",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          autocomplete: "off",
                          readonly: "",
                          type: "number",
                          id: "id",
                          name: "id",
                          disabled: _vm.somenteConsulta,
                        },
                        domProps: { value: _vm.model.id },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(_vm.model, "id", $event.target.value)
                          },
                        },
                      }),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-4" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("cpf"),
                            expression: "errors.first('cpf')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "cpf" },
                          },
                          [
                            _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.model.pontuarDotz,
                                    expression: "model.pontuarDotz",
                                  },
                                ],
                                staticStyle: { color: "red" },
                              },
                              [_vm._v("*")]
                            ),
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t("__.Crud.prescritor.edit_vue_html.cpf")
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            { name: "mask-cpf", rawName: "v-mask-cpf" },
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.model.cpf,
                              expression: "model.cpf",
                            },
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: {
                                required: _vm.model.pontuarDotz,
                                cpf: true,
                              },
                              expression:
                                "{\n                                    required: model.pontuarDotz,\n                                    cpf: true,\n                                }",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            autocomplete: "off",
                            type: "text",
                            id: "cpf",
                            name: "cpf",
                            disabled: _vm.somenteConsulta,
                          },
                          domProps: { value: _vm.model.cpf },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(_vm.model, "cpf", $event.target.value)
                            },
                          },
                        }),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-4" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("rg"),
                            expression: "errors.first('rg')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "rg" },
                          },
                          [
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t("__.Crud.prescritor.edit_vue_html.rg")
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.model.rg,
                              expression: "model.rg",
                            },
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "alpha_num|max:20",
                              expression: "'alpha_num|max:20'",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            autocomplete: "off",
                            type: "text",
                            id: "rg",
                            name: "rg",
                            disabled: _vm.somenteConsulta,
                          },
                          domProps: { value: _vm.model.rg },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(_vm.model, "rg", $event.target.value)
                            },
                          },
                        }),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-1" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("ativo"),
                            expression: "errors.first('ativo')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: {
                              for: "ativo",
                              title: _vm.$t(
                                "__.Crud.prescritor.edit_vue_html.ativo"
                              ),
                            },
                          },
                          [
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Crud.prescritor.edit_vue_html.ativo"
                                  )
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("checkbox-component", {
                          attrs: {
                            id: "ativo",
                            name: "ativo",
                            disabled: _vm.somenteConsulta,
                          },
                          model: {
                            value: _vm.model.ativo,
                            callback: function ($$v) {
                              _vm.$set(_vm.model, "ativo", $$v)
                            },
                            expression: "model.ativo",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-sm-6" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("nome"),
                            expression: "errors.first('nome')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: {
                              for: "nome",
                              title: _vm.$t("__.obrigatorio"),
                            },
                          },
                          [
                            _c("span", { staticStyle: { color: "red" } }, [
                              _vm._v("*"),
                            ]),
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Crud.prescritor.edit_vue_html.nome"
                                  )
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.model.nome,
                              expression: "model.nome",
                            },
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "alpha_spaces|max:256|required",
                              expression: "'alpha_spaces|max:256|required'",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            autocomplete: "off",
                            type: "text",
                            id: "nome",
                            name: "nome",
                            disabled: _vm.somenteConsulta,
                          },
                          domProps: { value: _vm.model.nome },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(_vm.model, "nome", $event.target.value)
                            },
                          },
                        }),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-2" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("dataNascimento"),
                            expression: "errors.first('dataNascimento')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "dataNascimento" },
                          },
                          [
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Crud.prescritor.edit_vue_html.dtNasc"
                                  )
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("date-time-picker-component", {
                          attrs: {
                            id: "dataNascimento",
                            name: "dataNascimento",
                            disabled: _vm.somenteConsulta,
                          },
                          model: {
                            value: _vm.model.dataNascimento,
                            callback: function ($$v) {
                              _vm.$set(_vm.model, "dataNascimento", $$v)
                            },
                            expression: "model.dataNascimento",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-2" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("genero"),
                            expression: "errors.first('genero')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "genero" },
                          },
                          [
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Crud.prescritor.edit_vue_html.genero"
                                  )
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("combo-component", {
                          attrs: {
                            id: "genero",
                            name: "genero",
                            data: _vm.generosPessoaOptions,
                            searchable: false,
                            disabled: _vm.somenteConsulta,
                          },
                          model: {
                            value: _vm.model.genero,
                            callback: function ($$v) {
                              _vm.$set(_vm.model, "genero", $$v)
                            },
                            expression: "model.genero",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-2" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("comissao"),
                            expression: "errors.first('comissao')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "comissao" },
                          },
                          [
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Crud.prescritor.edit_vue_html.comissao"
                                  )
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("decimal-component", {
                          staticClass: "form-control",
                          attrs: {
                            id: "comissao",
                            name: "comissao",
                            disabled: _vm.somenteConsulta,
                          },
                          model: {
                            value: _vm.model.comissao,
                            callback: function ($$v) {
                              _vm.$set(_vm.model, "comissao", $$v)
                            },
                            expression: "model.comissao",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-sm-3" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("numeroRegistro"),
                            expression: "errors.first('numeroRegistro')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: {
                              for: "numeroRegistro",
                              title: _vm.$t("__.obrigatorio"),
                            },
                          },
                          [
                            _c("span", { staticStyle: { color: "red" } }, [
                              _vm._v("*"),
                            ]),
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Crud.prescritor.edit_vue_html.numRegProfis"
                                  )
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.model.numeroRegistro,
                              expression: "model.numeroRegistro",
                            },
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required",
                              expression: "'required'",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            autocomplete: "off",
                            type: "number",
                            id: "numeroRegistro",
                            name: "numeroRegistro",
                            disabled: _vm.somenteConsulta,
                          },
                          domProps: { value: _vm.model.numeroRegistro },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.model,
                                "numeroRegistro",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-2" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("conselhoRegional"),
                            expression: "errors.first('conselhoRegional')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "conselhoRegional" },
                          },
                          [
                            _c("span", { staticStyle: { color: "red" } }, [
                              _vm._v("*"),
                            ]),
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Crud.prescritor.edit_vue_html.conselhoRegional"
                                  )
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("combo-component", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required",
                              expression: "'required'",
                            },
                          ],
                          attrs: {
                            id: "conselhoRegional",
                            name: "conselhoRegional",
                            data: _vm.conselhosRegionaisOptions,
                            searchable: true,
                            disabled: _vm.somenteConsulta,
                          },
                          model: {
                            value: _vm.model.tipoConselhoRegional,
                            callback: function ($$v) {
                              _vm.$set(_vm.model, "tipoConselhoRegional", $$v)
                            },
                            expression: "model.tipoConselhoRegional",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-2" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("ufConselhoRegional"),
                            expression: "errors.first('ufConselhoRegional')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "ufConselhoRegional" },
                          },
                          [
                            _c("span", { staticStyle: { color: "red" } }, [
                              _vm._v("*"),
                            ]),
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Crud.prescritor.edit_vue_html.ufConselhoRegional"
                                  )
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("combo-component", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required",
                              expression: "'required'",
                            },
                          ],
                          attrs: {
                            id: "ufConselhoRegional",
                            name: "ufConselhoRegional",
                            data: _vm.ufOptions,
                            searchable: true,
                            disabled: _vm.somenteConsulta,
                          },
                          model: {
                            value: _vm.model.ufConselhoRegional,
                            callback: function ($$v) {
                              _vm.$set(_vm.model, "ufConselhoRegional", $$v)
                            },
                            expression: "model.ufConselhoRegional",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-3" }, [
                    _c("div", { staticClass: "form-group" }, [
                      _c(
                        "label",
                        {
                          staticClass: "control-label",
                          attrs: { for: "sipeAgro" },
                        },
                        [
                          _vm._v(
                            "\n                                " +
                              _vm._s(
                                _vm.$t(
                                  "__.Crud.prescritor.edit_vue_html.sipeAgro"
                                )
                              ) +
                              "\n                            "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.model.sipeAgro,
                            expression: "model.sipeAgro",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          autocomplete: "off",
                          type: "text",
                          id: "sipeAgro",
                          name: "sipeAgro",
                          disabled: _vm.somenteConsulta,
                        },
                        domProps: { value: _vm.model.sipeAgro },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(_vm.model, "sipeAgro", $event.target.value)
                          },
                        },
                      }),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-2" }, [
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "curvaABC" },
                          },
                          [
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Crud.prescritor.edit_vue_html.classPrescritor"
                                  )
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("combo-component", {
                          attrs: {
                            id: "curvaABC",
                            name: "curvaABC",
                            data: _vm.curvaABCOptions,
                            searchable: true,
                            disabled: "",
                          },
                          model: {
                            value: _vm.model.curvaABC,
                            callback: function ($$v) {
                              _vm.$set(_vm.model, "curvaABC", $$v)
                            },
                            expression: "model.curvaABC",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    {
                      class: _vm.usuarioLogado.franquia.utilizaDotz
                        ? "col-sm-11"
                        : "col-sm-12",
                    },
                    [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: _vm.errors.first("observacao"),
                              expression: "errors.first('observacao')",
                            },
                          ],
                          staticClass: "form-group",
                        },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "control-label",
                              attrs: { for: "observacao" },
                            },
                            [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(
                                    _vm.$t(
                                      "__.Crud.prescritor.edit_vue_html.obs"
                                    )
                                  ) +
                                  "\n                            "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("textarea-component", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "max:256",
                                expression: "'max:256'",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: {
                              id: "observacao",
                              name: "observacao",
                              disabled: _vm.somenteConsulta,
                            },
                            model: {
                              value: _vm.model.observacao,
                              callback: function ($$v) {
                                _vm.$set(_vm.model, "observacao", $$v)
                              },
                              expression: "model.observacao",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _vm.usuarioLogado.franquia.utilizaDotz
                    ? _c("div", { staticClass: "col-sm-1" }, [
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c("label", { attrs: { for: "pontuarDotz" } }, [
                              _vm._v("Pontuar Dotz"),
                            ]),
                            _vm._v(" "),
                            _c("checkbox-component", {
                              attrs: {
                                id: "pontuarDotz",
                                name: "pontuarDotz",
                                disabled: _vm.somenteConsulta,
                              },
                              model: {
                                value: _vm.model.pontuarDotz,
                                callback: function ($$v) {
                                  _vm.$set(_vm.model, "pontuarDotz", $$v)
                                },
                                expression: "model.pontuarDotz",
                              },
                            }),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-sm-8" }, [
                    _c("div", { staticClass: "form-group" }, [
                      _c(
                        "label",
                        {
                          staticClass: "control-label",
                          attrs: { for: "visitadoresNomes" },
                        },
                        [
                          _vm._v(
                            "\n                                " +
                              _vm._s(
                                _vm.$t(
                                  "__.Crud.prescritor.edit_vue_html.visitadoresNomes"
                                )
                              ) +
                              "\n                            "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.visitadoresNomes,
                            expression: "visitadoresNomes",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          id: "visitadoresNomes",
                          name: "visitadoresNomes",
                          disabled: "",
                        },
                        domProps: { value: _vm.visitadoresNomes },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.visitadoresNomes = $event.target.value
                          },
                        },
                      }),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-4" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("beneficio"),
                            expression: "errors.first('beneficio')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "beneficio" },
                          },
                          [
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Crud.colaborador.edit_vue_html.beneficio"
                                  )
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("combo-component", {
                          attrs: {
                            id: "beneficio",
                            name: "beneficio",
                            data: _vm.beneficiosOptions,
                            searchable: true,
                            refresh: _vm.atualizarBeneficios,
                          },
                          model: {
                            value: _vm.model.beneficioId,
                            callback: function ($$v) {
                              _vm.$set(_vm.model, "beneficioId", $$v)
                            },
                            expression: "model.beneficioId",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "fieldset-component",
            {
              attrs: {
                title: _vm.$t(
                  "__.Crud.prescritor.edit_vue_html.especialidSelec"
                ),
                collapsed: false,
              },
            },
            [
              _c(
                "div",
                { attrs: { slot: "rows" }, slot: "rows" },
                [
                  !_vm.somenteConsulta
                    ? _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-sm-12" }, [
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "control-label",
                                  attrs: { for: "especialidadeId" },
                                },
                                [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(
                                        _vm.$t(
                                          "__.Crud.prescritor.edit_vue_html.especialid"
                                        )
                                      ) +
                                      "\n                            "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("combo-component", {
                                attrs: {
                                  id: "especialidadeId",
                                  name: "especialidadeId",
                                  data: _vm.especialidadesOptions,
                                  searchable: true,
                                  refresh: _vm.loadEspecialidades,
                                },
                                model: {
                                  value: _vm.model.especialidadeId,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.model, "especialidadeId", $$v)
                                  },
                                  expression: "model.especialidadeId",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.somenteConsulta
                    ? _c("buttonIncluirComponent", {
                        on: { click: _vm.onInclusaoEspecialidade },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("grid-component", {
                    attrs: {
                      data: _vm.model.especialidades,
                      columns: _vm.gridColumns,
                      "show-action-column": !_vm.somenteConsulta,
                      "show-edit-item": false,
                      "show-table-foot": false,
                      "show-table-head": false,
                    },
                    on: { "remove-item": _vm.onRemoveItem },
                  }),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "fieldset-component",
            {
              attrs: {
                title: _vm.$t("__.Crud.prescritor.edit_vue_html.localiz"),
              },
            },
            [
              _c("div", { attrs: { slot: "rows" }, slot: "rows" }, [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-sm-3" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("cep"),
                            expression: "errors.first('cep')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "cep" },
                          },
                          [
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t("__.Crud.prescritor.edit_vue_html.cep")
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("cep-field-component", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "cep",
                              expression: "'cep'",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            id: "cep",
                            name: "cep",
                            disabled: _vm.somenteConsulta,
                          },
                          on: {
                            "cep-changed": _vm.onCepChanged,
                            "cep-invalid": _vm.onCepInvalid,
                          },
                          model: {
                            value: _vm.model.cep,
                            callback: function ($$v) {
                              _vm.$set(_vm.model, "cep", $$v)
                            },
                            expression: "model.cep",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-3" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("estadoId"),
                            expression: "errors.first('estadoId')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "estadoId" },
                          },
                          [
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Crud.prescritor.edit_vue_html.estado"
                                  )
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("combo-component", {
                          attrs: {
                            id: "estadoId",
                            name: "estadoId",
                            data: _vm.ufOptions,
                            searchable: true,
                            disabled: _vm.somenteConsulta,
                          },
                          on: { input: _vm.onLoadCidades },
                          model: {
                            value: _vm.model.estadoId,
                            callback: function ($$v) {
                              _vm.$set(_vm.model, "estadoId", $$v)
                            },
                            expression: "model.estadoId",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-6" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("cidadeId"),
                            expression: "errors.first('cidadeId')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "cidadeId" },
                          },
                          [
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Crud.prescritor.edit_vue_html.cidade"
                                  )
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("combo-component", {
                          attrs: {
                            id: "cidadeId",
                            name: "cidadeId",
                            data: _vm.cidadesOptions,
                            searchable: true,
                            disabled: _vm.somenteConsulta,
                          },
                          model: {
                            value: _vm.model.cidadeId,
                            callback: function ($$v) {
                              _vm.$set(_vm.model, "cidadeId", $$v)
                            },
                            expression: "model.cidadeId",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-sm-4" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("endereco"),
                            expression: "errors.first('endereco')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "endereco" },
                          },
                          [
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Crud.prescritor.edit_vue_html.endereco"
                                  )
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.model.endereco,
                              expression: "model.endereco",
                            },
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "max:256",
                              expression: "'max:256'",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            autocomplete: "off",
                            type: "text",
                            id: "endereco",
                            name: "endereco",
                            disabled: _vm.somenteConsulta,
                          },
                          domProps: { value: _vm.model.endereco },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.model,
                                "endereco",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-4" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("numero"),
                            expression: "errors.first('numero')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "numero" },
                          },
                          [
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t("__.Crud.prescritor.edit_vue_html.nro")
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.model.numero,
                              expression: "model.numero",
                            },
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "numeric|max:256",
                              expression: "'numeric|max:256'",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            autocomplete: "off",
                            type: "text",
                            id: "numero",
                            name: "numero",
                            disabled: _vm.somenteConsulta,
                          },
                          domProps: { value: _vm.model.numero },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(_vm.model, "numero", $event.target.value)
                            },
                          },
                        }),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-4" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("bairro"),
                            expression: "errors.first('bairro')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "bairro" },
                          },
                          [
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Crud.prescritor.edit_vue_html.bairro"
                                  )
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.model.bairro,
                              expression: "model.bairro",
                            },
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "max:256",
                              expression: "'max:256'",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            autocomplete: "off",
                            type: "text",
                            id: "bairro",
                            name: "bairro",
                            disabled: _vm.somenteConsulta,
                          },
                          domProps: { value: _vm.model.bairro },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(_vm.model, "bairro", $event.target.value)
                            },
                          },
                        }),
                      ]
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-sm-12" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("complemento"),
                            expression: "errors.first('complemento')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "complemento" },
                          },
                          [
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Crud.prescritor.edit_vue_html.complement"
                                  )
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.model.complemento,
                              expression: "model.complemento",
                            },
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "max:256",
                              expression: "'max:256'",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            autocomplete: "off",
                            type: "text",
                            id: "complemento",
                            name: "complemento",
                            disabled: _vm.somenteConsulta,
                          },
                          domProps: { value: _vm.model.complemento },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.model,
                                "complemento",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]
                    ),
                  ]),
                ]),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "fieldset-component",
            {
              attrs: {
                title: _vm.$t("__.Crud.prescritor.edit_vue_html.contato"),
              },
            },
            [
              _c("div", { attrs: { slot: "rows" }, slot: "rows" }, [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-sm-3" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("telefone"),
                            expression: "errors.first('telefone')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "telefone" },
                          },
                          [
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t("__.Crud.prescritor.edit_vue_html.tel")
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "mask-telefone",
                              rawName: "v-mask-telefone",
                            },
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.model.telefone,
                              expression: "model.telefone",
                            },
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "telefone",
                              expression: "'telefone'",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            autocomplete: "off",
                            type: "text",
                            id: "telefone",
                            name: "telefone",
                            disabled: _vm.somenteConsulta,
                          },
                          domProps: { value: _vm.model.telefone },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.model,
                                "telefone",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-3" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("celular"),
                            expression: "errors.first('celular')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "celular" },
                          },
                          [
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t("__.Crud.prescritor.edit_vue_html.cel")
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "mask-telefone",
                              rawName: "v-mask-telefone",
                            },
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.model.celular,
                              expression: "model.celular",
                            },
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "telefone",
                              expression: "'telefone'",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            autocomplete: "off",
                            type: "text",
                            id: "celular",
                            name: "celular",
                            disabled: _vm.somenteConsulta,
                          },
                          domProps: { value: _vm.model.celular },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.model,
                                "celular",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-6" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("email"),
                            expression: "errors.first('email')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "email" },
                          },
                          [_vm._v("E-mail")]
                        ),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.model.email,
                              expression: "model.email",
                            },
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "email",
                              expression: "'email'",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            autocomplete: "off",
                            type: "email",
                            id: "email",
                            name: "email",
                            placeholder: "exemplo@exemplo.com.br",
                            disabled: _vm.somenteConsulta,
                          },
                          domProps: { value: _vm.model.email },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(_vm.model, "email", $event.target.value)
                            },
                          },
                        }),
                      ]
                    ),
                  ]),
                ]),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "fieldset-component",
            {
              attrs: {
                title: _vm.$t(
                  "__.Crud.prescritor.edit_vue_html.comissionamento.titulo"
                ),
              },
            },
            [
              _c(
                "div",
                { attrs: { slot: "rows" }, slot: "rows" },
                [
                  !_vm.somenteConsulta
                    ? _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-sm-4" }, [
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "tooltip",
                                  rawName: "v-tooltip",
                                  value: _vm.errors.first("inicioFaixa"),
                                  expression: "errors.first('inicioFaixa')",
                                },
                              ],
                              staticClass: "form-group",
                            },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "control-label",
                                  attrs: {
                                    for: "inicioFaixa",
                                    title: _vm.$t("__.obrigatorio"),
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    { staticStyle: { color: "red" } },
                                    [_vm._v("*")]
                                  ),
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(
                                        _vm.$t(
                                          "__.Crud.prescritor.edit_vue_html.comissionamento.inicioFaixa"
                                        )
                                      ) +
                                      "\n                            "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("moedaComponent", {
                                staticClass: "form-control",
                                attrs: {
                                  type: "text",
                                  id: "inicioFaixa",
                                  name: "inicioFaixa",
                                  autocomplete: "off",
                                },
                                model: {
                                  value: _vm.faixaComissao.inicioFaixa,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.faixaComissao,
                                      "inicioFaixa",
                                      $$v
                                    )
                                  },
                                  expression: "faixaComissao.inicioFaixa",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-sm-4" }, [
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "tooltip",
                                  rawName: "v-tooltip",
                                  value: _vm.errors.first("fimFaixa"),
                                  expression: "errors.first('fimFaixa')",
                                },
                              ],
                              staticClass: "form-group",
                            },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "control-label",
                                  attrs: {
                                    for: "fimFaixa",
                                    title: _vm.$t("__.obrigatorio"),
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    { staticStyle: { color: "red" } },
                                    [_vm._v("*")]
                                  ),
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(
                                        _vm.$t(
                                          "__.Crud.prescritor.edit_vue_html.comissionamento.fimFaixa"
                                        )
                                      ) +
                                      "\n                            "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("moedaComponent", {
                                staticClass: "form-control",
                                attrs: {
                                  autocomplete: "off",
                                  type: "text",
                                  id: "fimFaixa",
                                  name: "fimFaixa",
                                },
                                model: {
                                  value: _vm.faixaComissao.fimFaixa,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.faixaComissao, "fimFaixa", $$v)
                                  },
                                  expression: "faixaComissao.fimFaixa",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-sm-4" }, [
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "tooltip",
                                  rawName: "v-tooltip",
                                  value: _vm.errors.first("valor"),
                                  expression: "errors.first('valor')",
                                },
                              ],
                              staticClass: "form-group",
                            },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "control-label",
                                  attrs: {
                                    for: "valor",
                                    title: _vm.$t("__.obrigatorio"),
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    { staticStyle: { color: "red" } },
                                    [_vm._v("*")]
                                  ),
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(
                                        _vm.$t(
                                          "__.Crud.prescritor.edit_vue_html.comissionamento.porcentagemComissao"
                                        )
                                      ) +
                                      "\n                            "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("decimal-com-sinal-custom-component", {
                                staticClass: "form-control",
                                attrs: {
                                  signal: "%",
                                  autocomplete: "off",
                                  type: "text",
                                  id: "valor",
                                  name: "valor",
                                },
                                model: {
                                  value: _vm.faixaComissao.porcentagemComissao,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.faixaComissao,
                                      "porcentagemComissao",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "faixaComissao.porcentagemComissao",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.somenteConsulta
                    ? _c("buttonIncluirComponent", {
                        on: { click: _vm.onAddFaixa },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("grid-component", {
                    attrs: {
                      data: _vm.model.comissoesFaixa,
                      columns: _vm.gridFaixaColums,
                      "show-action-column": !_vm.somenteConsulta,
                      "show-edit-item": false,
                      "show-remove-item": true,
                      "show-table-foot": false,
                      "show-table-head": false,
                    },
                    on: { "remove-item": _vm.onRemoveFaixa },
                  }),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "info-badges-container" },
            [
              _vm.model.id
                ? _c("infoBagdeComponent", {
                    attrs: { text: _vm.infoBagdeText },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.model.id && _vm.infoBagdeTextUsuario
                ? _c("infoBagdeComponent", {
                    attrs: { text: _vm.infoBagdeTextUsuario },
                  })
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _vm.validarVisibilidadeBotoes("replicar-filiais")
            ? [
                _c("replicar-selecao-franquia-filiais-component", {
                  ref: "replicarSelecaoFranquiaFiliaisComponent",
                  attrs: { showFiliais: true },
                  on: { "replicar-filiais": _vm.onConfirmReplicar },
                }),
              ]
            : _vm._e(),
          _vm._v(" "),
          _c("fieldset", [
            !_vm.CalledByShortCut
              ? _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-sm-12 newbtn" }, [
                    _c("div", { staticClass: "form-group" }, [
                      !_vm.somenteConsulta
                        ? _c(
                            "button",
                            {
                              staticClass: "btn btn-success",
                              attrs: { type: "submit" },
                            },
                            [
                              _c("i", {
                                staticClass: "fa fa-save",
                                attrs: { "aria-hidden": "true" },
                              }),
                              _vm._v(
                                "\n                            " +
                                  _vm._s(_vm.$t("__.Crud.salvar")) +
                                  "\n                        "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.somenteConsulta
                        ? _c(
                            "button",
                            {
                              staticClass: "btn btn-secondary",
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  _vm.load()
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(_vm.$t("__.Crud.limpar")) +
                                  "\n                        "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-secondary",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              _vm.cancel()
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n                            " +
                              _vm._s(_vm.$t("__.Crud.voltar")) +
                              "\n                        "
                          ),
                        ]
                      ),
                    ]),
                  ]),
                ])
              : _vm._e(),
          ]),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }