import Vue from "vue";

import { Component, Prop } from "@/decorators";

import "./dropdown.scss";

@Component
export default class DropdownComponent extends Vue {
    @Prop({ type: String, default: "LEFT" }) menuSide: string;

    open = false;

    dropMode = "DOWN";

    private onOpen(event: MouseEvent) {
        this.open = true;

        const clickY = event.clientY;
        const windowHeight = window.innerHeight;

        this.dropMode = clickY > windowHeight / 2 ? "UP" : "DOWN";
    }

    private onClose() {
        this.open = false;
    }
}
