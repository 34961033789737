import BaseModel from "./../base/baseModel";

export default class PdvModel extends BaseModel {
    id?: number = null;
    descricao: string = null;
    saldoInicial: number = null;
    saldoAtual: number = null;
    gerarFinanceiro: boolean = null;
    lancamentoSaldoId?: number = null;
    contaCorrenteId: number = null;
    formaRecebimentoId?: number = null;
}
