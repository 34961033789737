import BaseModel from "../base/baseModel";
import CorModel from "../corModel";
import CorManipulacao from "../enum/corManipulacao";
import CurvaABC from "../enum/produto/curvaABC";
import SubGrupoProduto from "../enum/subGrupoProduto";
import TiposCalculoManipulacao from "../enum/tiposCalculoManipulacao";
import TiposCapsula from "../enum/tiposCapsula";
import TiposProduto from "../enum/tiposProduto";
import FarmacopeiaModel from "../farmacopeiaModel";
import FormaFarmaceuticaModel from "../formaFarmaceuticaModel";
import IncompatibilidadeKitEmbalagemModel from "../incompatibilidadeKitEmbalagemModel";
import IncompatibilidadeProdutoModel from "../incompatibilidadeProdutoModel";
import ProdutoAliquotaInterestadualModel from "../produtoAliquotaInterestadualModel";
import ProdutoLoteModel from "../produtoLoteModel";
import ProdutoMarkupDinamicoModel from "../produtoMarkupDinamicoModel";
import ProdutoRefrigeradoFormaFarmaceuticaModel from "../produtoRefrigeradoFormaFarmaceuticaModel";
import ValidadeFormaFarmaceuticaProdutoModel from "../validadadeFormaFarmaceuticaProdutoModel";

import ProdutoAssociacaoModel from "./produtoAssociacaoModel";
import ProdutoFornecedorModel from "./produtoFornecedorModel";
import ProdutoObservacoesModel from "./produtoObservacoesModel";
import ProdutoSinonimoModel from "./produtoSinonimoModel";

export default class ProdutoModel extends BaseModel {
    id?: number = null;
    franquiaId?: number = null;
    descricao: string = null;
    codigoBarra: string = null;
    posologiaId: number = null;
    ativo = true;
    grupoProdutoId = null;
    kitEmbalagemId = 0;
    observacao: string = null;
    unidadeMedidaManipulacaoId = null;
    dcbId?: number = null;
    dciId?: number = null;
    listaControladoId?: number = null;
    cas: string = null;
    nbmId?: number = null;
    ncmId?: number = null;
    cestId?: number = null;
    descricaoRotulo: string = null;
    fatorCorrecao?: number = null;
    densidade?: number = null;
    fatorUI?: number = null;
    fatorUFC?: number = null;
    fatorUTR?: number = null;
    fatorEquivalencia?: number = null;
    diluicao?: number = null;
    doseMaxima?: number = null;
    doseMaximaDiaria?: number = null;
    doseMaximaPorcentagem?: number = null;
    margemSeguranca?: number = null;
    valorCusto?: number = null;
    custoReferencia?: number = null;
    custoMedio?: number = null;
    valorVenda?: number = null;
    markup?: number = null;
    unidadeMedidaEstoqueId = null;
    estoqueMinimo = 0;
    estoqueMaximo?: number = null;

    tipoCalculo?: TiposCalculoManipulacao = null;
    subGrupo?: SubGrupoProduto = null;
    volumeMaximo?: number = null;
    //unidadeMedidaVolumeId?: number = null;
    capsulaId?: number = null;
    volumeCapsula?: number = null;
    formasFarmaceuticas?: Array<FormaFarmaceuticaModel> = [];
    formaFarmaceuticaId?: number = null;

    produtoExcipienteId?: number = null;
    produtoExcipienteDescricao: string = null;

    produtoAssociacoes?: Array<ProdutoAssociacaoModel> = [];

    produtoSinonimos?: Array<ProdutoSinonimoModel> = [];
    sinonimos: string = null;
    sinonimoIds: string = null;
    tipoProduto: TiposProduto = null;
    fatorCorrecaoMolde?: number = null;
    restricoes?: boolean = null;

    produtoDiluidoId?: number = null;
    doseMaximaDiluido?: number = null;

    produtoPuros?: Array<ProdutoModel> = [];
    produtoPaiId?: number = null;

    tipoCapsula: TiposCapsula = null;
    ignoraControleEstoque = false;

    listaProdutoLote?: Array<ProdutoLoteModel> = [];

    lotePadraoId: number = null;
    lotePadrao?: ProdutoLoteModel = null;
    farmacopeiaPadraoId?: number = null;
    farmacopeia?: FarmacopeiaModel = new FarmacopeiaModel();

    origemMercadoria = 0;
    aliquotaII = 0;
    cstICMS = 0;
    aliquotaICMSEntrada = 0;
    aliquotaICMSSaida = 0;
    percentualReducaoBase = 0;
    percentualDiferimento = 0;
    sujeitoIPI = false;
    cstIPIEntrada = 0;
    cstIPISaida = 0;
    aliquotaIPI = 0;
    cstPISEntrada = 0;
    cstPISSaida = 0;
    aliquotaPISEntrada = 0;
    aliquotaPISSaida = 0;
    cstCOFINSEntrada = 0;
    cstCOFINSSaida = 0;
    aliquotaCOFINSEntrada = 0;
    aliquotaCOFINSSaida = 0;
    percentualCreditoSimplesNacional = 0;

    produtoObservacoes?: Array<ProdutoObservacoesModel> = [];
    produtosIncompativeis?: Array<IncompatibilidadeProdutoModel> = [];
    kitEmbalagemIncompatibilidade?: Array<IncompatibilidadeKitEmbalagemModel> = [];
    produtoRefrigeradoFormaFarmaceutica?: Array<ProdutoRefrigeradoFormaFarmaceuticaModel> = [];
    validadesFormasFarmaceuticasProdutos?: Array<ValidadeFormaFarmaceuticaProdutoModel> = [];
    produtoFornecedores?: Array<ProdutoFornecedorModel> = [];

    corId: number = null;
    cor: CorModel = new CorModel();
    ordemCapsula: number = null;

    produtoAliquotasInterestaduais?: Array<ProdutoAliquotaInterestadualModel> = [];
    codigoBeneficio: string = null;

    siproquim = false;
    codigoSiproquimId = 0;
    produtoPuroId: number = null;

    produtoMarkupDinamico?: Array<ProdutoMarkupDinamicoModel> = [];
    formulaMolecular?: number = null;
    pesoMolecular: string = null;

    codigoAnvisa: string = null;
    precoMaximoConsumidor: number = null;
    corPadraoCapsulaId?: number = null;

    naoUtilizarExcipiente?: boolean = false;
    dataCriacao: Date = null;
    corManipulacao?: CorManipulacao = null;
    curvaABC?: CurvaABC = null;
    fitoterapico = false;
    usuarioCriacaoDescricao: string = null;
    descricaoFiscal: string = null;
    controlePoliciaCivil = false;
}

export interface ProdutoListParameters {
    combo?: string;
    sub?: string;
    loadSinonimos?: boolean;
    loadFormaFarmaceuticas?: boolean;
    franquiaId?: number;
    somenteAtivos?: boolean;
    ignorarLotesZerados?: boolean;
}
