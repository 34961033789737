import BaseModel from "@/models/base/baseModel";
import StatusAtendimento from "@/models/enum/statusAtendimento";

export default class AtendimentoWhatsAppModel extends BaseModel {
    id?: string = "";
    nomeContato = "";
    fotoContato = null;
    contatoId: string = null;
    numero = "";
    ultimaMensagem = "";
    dataUltimaMensagem?: Date = null;
    dataInicio: Date = null;
    statusAtendimento: StatusAtendimento;
    unreadCount?: number = 0;
    usuarioId?: number = null;
}
