import { mapMutations } from "vuex";

import buttonIncluirComponent from "@/components/child/form/button/buttonIncluir.vue";
import checkboxComponent from "@/components/child/form/checkbox.vue";
import comboComponent from "@/components/child/form/combo.vue";
import dataTooltipComponent from "@/components/child/form/datatooltip.vue";
import dateTimePickerComponent from "@/components/child/form/datetimepicker.vue";
import decimalComponent from "@/components/child/form/decimal.vue";
import decimalComSinalCustomComponent from "@/components/child/form/decimalComSinalCustom.vue";
import fieldsetComponent from "@/components/child/form/fieldset.vue";
import moedaComponent from "@/components/child/form/moeda.vue";
import gridComponent from "@/components/child/grid/grid.vue";
import { GridColumn, GridColumnType } from "@/components/child/grid/gridColumn";
import { Component, Watch } from "@/decorators";
import TiposProduto from "@/models/enum/tiposProduto";
import PaginationModel from "@/models/paginationModel";
import GrupoProdutoModel from "@/models/produto/grupoProdutoModel";
import CampanhaFaixaModel from "@/models/programafidelidade/campanhaFaixaModel";
import CampanhaGrupoProdutoModel from "@/models/programafidelidade/campanhaGrupoProdutoModel";
import CampanhaModel from "@/models/programafidelidade/campanhaModel";
import CampanhaRecompensaModel from "@/models/programafidelidade/campanhaRecompensaModel";
import RecompensaModel from "@/models/programafidelidade/recompensaModel";
import CampanhaService from "@/services/campanhaService";
import GrupoProdutoService from "@/services/produto/grupoProdutoService";
import RecompensaService from "@/services/recompensaService";
import { LoadListProps, Mutations } from "@/store/store";

import EditComponentBase from "../editComponentBase";

@Component({
    components: {
        comboComponent,
        checkboxComponent,
        dateTimePickerComponent,
        dataTooltipComponent,
        decimalComponent,
        gridComponent,
        buttonIncluirComponent,
        fieldsetComponent,
        moedaComponent,
        decimalComSinalCustomComponent,
    },
    methods: mapMutations(["LOAD_LIST"] as Mutations),
})
export default class CampanhaEditComponent extends EditComponentBase<CampanhaModel> {
    // State computed props
    LOAD_LIST: (props: LoadListProps) => void;

    faixa = new CampanhaFaixaModel();

    get gridColumnsFaixa(): Array<GridColumn> {
        return [
            new GridColumn(
                "valorInicial",
                this.$t("__.Crud.campanha.edit_vue_html.valorInicialFaixaGrid") as string,
                GridColumnType.Money,
            ),
            new GridColumn(
                "valorFinal",
                this.$t("__.Crud.campanha.edit_vue_html.valorFinalFaixaGrid") as string,
                GridColumnType.Money,
            ),
            new GridColumn(
                "valorPorPonto",
                this.$t("__.Crud.campanha.edit_vue_html.valorPorPonto") as string,
                GridColumnType.Money,
            ),
            new GridColumn(
                "pontos",
                this.$t("__.Crud.campanha.edit_vue_html.pontos") as string,
                GridColumnType.Integer,
            ),
        ];
    }

    recompensa = new RecompensaModel();
    recompensasOptions: Array<Object> = [];
    get gridColumnsRecompensa(): Array<GridColumn> {
        return [new GridColumn("premio", "Prêmio", GridColumnType.String)];
    }

    produtoGrupo = new CampanhaGrupoProdutoModel();
    grupoProdutoOptions: Array<Object> = [];
    get gridColumnsGrupoProduto(): Array<GridColumn> {
        return [
            new GridColumn("grupoProdutoDescricao", "Grupo de Produto", GridColumnType.String),
            new GridColumn("subGrupoDescricao", "Sub Grupo Produto", GridColumnType.String),
        ];
    }

    recompensaId: number = null;
    grupoProdutoId: number = null;
    subGrupo: number = null;
    tipoProduto: TiposProduto = null;

    subGrupoOptions: Array<Object> = [
        { text: "Ativo", value: 0 },
        { text: "Flavorizante", value: 1 },
        { text: "Veículos/Excipientes", value: 2 },
    ];

    private async beforeMount() {
        await this.setProps(new CampanhaService(), "campanhas", new CampanhaModel());

        Promise.all([this.loadRecompensas(), this.loadGruposProduto()]).withLoading();
    }

    public async onPreSave() {
        if (this.model.dataInicio > this.model.dataFim) {
            await this.$showWarning(this.$t("__.ts.aviso"), this.$t("__.ts.dtFimMaiorIncio"));
            return false;
        }

        if (this.model.faixas.length == 0) {
            await this.$showWarning(
                this.$t("__.ts.aviso"),
                this.$t("__.Crud.campanha.edit_vue_html.faixasCampanhaValida"),
            );
            return false;
        }

        return true;
    }

    private async loadRecompensas() {
        try {
            const data = await new RecompensaService().combo().resolveWithJSON<PaginationModel<RecompensaModel>>();
            this.recompensasOptions = data.list.map(item => ({ value: item.id, text: item.premio }));
        } catch {}
    }

    private async loadGruposProduto() {
        try {
            const data = await new GrupoProdutoService().combo().resolveWithJSON<PaginationModel<GrupoProdutoModel>>();
            this.grupoProdutoOptions = data.list.map(item => ({
                value: item.id,
                text: item.descricao,
                tipoProduto: item.tipoProduto,
            }));
        } catch {}
    }

    private onInclusaoFaixa() {
        if (this.faixa.valorInicial && this.faixa.valorPorPonto && this.faixa.pontos) {
            if (this.model.faixas.some(f => f.valorInicial == this.faixa.valorInicial)) {
                this.$showWarning(this.$t("__.ts.atencao"), this.$t("__.Crud.campanha.edit_vue_html.faixaSobreposta"));
            } else {
                this.model.adicionarFaixa(this.faixa);
                this.faixa = new CampanhaFaixaModel();
            }
        }
    }

    private onRemoveFaixa(model: CampanhaFaixaModel) {
        this.model.faixas = this.model.faixas.filter(p => p != model);
    }

    private onInclusaoRecompensa() {
        const recompensaComponent = this.$el.querySelector("#recompensaId") as HTMLSelectElement;

        if (this.recompensaId > 0) {
            const alreadyExists = this.model.recompensas.some(p => p.id == this.recompensaId);

            if (!alreadyExists) {
                const recompensaModel = new CampanhaRecompensaModel();

                recompensaModel.recompensaId = this.recompensaId;
                recompensaModel.premio = recompensaComponent.innerText;
                recompensaModel.campanha = null;
                recompensaModel.recompensa = null;

                this.model.recompensas.push(recompensaModel);

                this.recompensaId = -1;
            }
        }
    }

    private onInclusaoGrupoProduto() {
        const grupoProdutoIdComponent = this.$el.querySelector("#grupoProdutoId") as HTMLSelectElement;
        const subGrupoProdutoComponent = this.$el.querySelector("#subGrupoProduto") as HTMLSelectElement;

        if (this.grupoProdutoId || this.subGrupo > 0) {
            const alreadyExists = this.model.grupos.some(
                p => p.grupoProdutoId == this.grupoProdutoId && p.subGrupo == this.subGrupo,
            );

            if (!alreadyExists) {
                const grupoProdutoModel = new CampanhaGrupoProdutoModel();

                grupoProdutoModel.grupoProdutoDescricao = null;
                grupoProdutoModel.subGrupo = null;
                if (this.subGrupo != null) {
                    grupoProdutoModel.subGrupoDescricao = subGrupoProdutoComponent.innerText;
                    grupoProdutoModel.subGrupo = this.subGrupo;
                }

                grupoProdutoModel.grupoProdutoId = this.grupoProdutoId;
                grupoProdutoModel.grupoProdutoDescricao = grupoProdutoIdComponent.innerText;

                this.model.grupos.push(grupoProdutoModel);

                this.grupoProdutoId = null;
                this.subGrupo = null;
            }
        }
    }

    private onInclusaoGrupoProdutoAll() {
        this.model.grupos = [];

        this.grupoProdutoOptions.forEach(item => {
            const alreadyExists = this.model.grupos.some(p => p.grupoProdutoId == item["value"]);
            if (!alreadyExists) {
                const grupoProdutoModel = new CampanhaGrupoProdutoModel();
                grupoProdutoModel.grupoProdutoDescricao = null;
                grupoProdutoModel.subGrupo = null;
                grupoProdutoModel.grupoProdutoId = item["value"];
                grupoProdutoModel.grupoProdutoDescricao = item["text"];

                if (item["tipoProduto"] == 0 || item["tipoProduto"] == 3 || item["tipoProduto"] == 4) {
                    this.loadSubGrupoOptions(item["tipoProduto"]);

                    this.subGrupoOptions.forEach(sub => {
                        grupoProdutoModel.subGrupoDescricao = sub["text"];
                        grupoProdutoModel.subGrupo = sub["value"];
                        this.model.grupos.push({ ...grupoProdutoModel } as CampanhaGrupoProdutoModel);
                    });
                } else {
                    this.model.grupos.push(grupoProdutoModel);
                }
            }
        });
    }

    private onRemoveRecompensa(model: CampanhaRecompensaModel) {
        this.model.recompensas = this.model.recompensas.filter(p => p != model);
    }

    private onRemoveGrupoProduto(model: CampanhaGrupoProdutoModel) {
        this.model.grupos = this.model.grupos.filter(p => p != model);
    }

    public async onLoadModel(model: CampanhaModel) {
        this.model.faixas = [];
        model.faixas.forEach(p => this.model.adicionarFaixa(p));

        this.model.grupos = [];
        model.grupos.forEach(p => {
            this.model.grupos.push({
                ...p,
                grupoProdutoDescricao: p.grupoProduto.descricao,
                subGrupoDescricao: this.getDescricaoSubGrupo(p.subGrupo),
            } as CampanhaGrupoProdutoModel);
        });
    }

    //@ts-ignore
    @Watch("grupoProdutoId")
    private onGrupoProdutoIdChanged() {
        const grupo = this.grupoProdutoOptions.find(p => p["value"] == this.grupoProdutoId);
        if (grupo) {
            this.tipoProduto = grupo["tipoProduto"];
        }
        this.loadSubGrupoOptions(this.tipoProduto);
    }

    private loadSubGrupoOptions(tipoProduto: TiposProduto) {
        switch (tipoProduto) {
            case TiposProduto.MateriaPrima:
                this.subGrupoOptions = [
                    { text: "Ativo", value: 0 },
                    { text: "Flavorizante", value: 1 },
                    { text: "Veículos/Excipientes", value: 2 },
                ];
                break;
            case TiposProduto.Embalagem:
                this.subGrupoOptions = [
                    { text: "Recipiente", value: 3 },
                    { text: "Tampa", value: 4 },
                    { text: "Aplicador", value: 5 },
                    { text: "Outros", value: 6 },
                ];
                break;
            case TiposProduto.Capsula:
                this.subGrupoOptions = [{ text: "Cor", value: 7 }];
                break;
        }
    }

    private getDescricaoSubGrupo(subGrupo: number) {
        switch (subGrupo) {
            case 0:
                return "Ativo";
            case 1:
                return "Flavorizante";
            case 2:
                return "Veículos/Excipientes";
            case 3:
                return "Recipiente";
            case 4:
                return "Tampa";
            case 5:
                return "Aplicador";
            case 6:
                return "Outros";
            case 7:
                return "Cor";
        }
        return "";
    }

    public async onSucessoSave() {
        this.LOAD_LIST({ loadCampanhas: true });
    }
}
