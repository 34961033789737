import { mapMutations } from "vuex";

import actionBarComponent from "@/components/child/actionBar/actionBar.vue";
import checkboxComponent from "@/components/child/form/checkbox.vue";
import comboComponent from "@/components/child/form/combo.vue";
import dataTooltipComponent from "@/components/child/form/datatooltip.vue";
import dateTimePickerComponent from "@/components/child/form/datetimepicker.vue";
import decimalComponent from "@/components/child/form/decimal.vue";
import textareaComponent from "@/components/child/form/textarea.vue";
import searchComboComponent from "@/components/child/searchCombo/search.vue";
import { Component } from "@/decorators";
import EspecieAnimalModel from "@/models/paciente/especieAnimalModel";
import RacaAnimalModel from "@/models/paciente/racaAnimalModel";
import PaginationModel from "@/models/paginationModel";
import EspecieAnimalService from "@/services/paciente/especieAnimalService";
import RacaAnimalService from "@/services/paciente/racaAnimalService";

import clienteComponent from "../cliente/edit.vue";
import EditComponentBase from "../editComponentBase";

import "../crud.scss";

@Component({
    components: {
        textareaComponent,
        comboComponent,
        checkboxComponent,
        dataTooltipComponent,
        dateTimePickerComponent,
        decimalComponent,
        searchComboComponent,
        actionBarComponent,
    },
    beforeCreate: function () {
        this.$options.components["clienteComponent"] = clienteComponent;
    },
    methods: mapMutations(["LOAD_LIST"]),
})
export default class RacaAnimalEditComponent extends EditComponentBase<RacaAnimalModel> {
    private especiesAnimalOptions: Array<Object> = [];

    private async beforeMount() {
        await this.setProps(new RacaAnimalService(), "racaAnimal", new RacaAnimalModel(), false, true);
        Promise.all([this.loadEspeciesAnimal()]);
    }

    private async loadEspeciesAnimal() {
        try {
            const especies = await new EspecieAnimalService()
                .combo()
                .resolveWithJSON<PaginationModel<EspecieAnimalModel>>();

            this.especiesAnimalOptions = especies.list.map(item => ({
                value: item.id,
                text: item.descricao,
            }));
        } catch {}
    }

    private clear() {
        this.model = new RacaAnimalModel();
    }
}
