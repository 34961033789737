import Vue from "vue";
import { mapState } from "vuex";

import { Component, Watch } from "@/decorators";
import StatusAtendimento from "@/models/enum/statusAtendimento";
import UsuarioModel from "@/models/usuarioModel";
import AtendimentoWhatsAppModel from "@/models/whatsApp/atendimento/atendimentoWhatsAppModel";
import AtendimentoService from "@/services/atendimentos/atendimentoService";
import { AtendimentoContextActions, AtendimentoContextMutations } from "@/store/atendimentoContext/types";
import { AppState } from "@/store/store";
import Delay from "@/utils/common/delay";

import AdicionarAtendimentoComponent from "./adicionarAtendimento/adicionarAtendimento";
import adicionarAtendimentoComponent from "./adicionarAtendimento/adicionarAtendimento.vue";

import "./atendimentos.scss";

let filterDebounceTimeout;

@Component({
    components: {
        adicionarAtendimentoComponent,
    },
    computed: {
        ...mapState({
            usuarioLogado: (state: AppState) => state.session.usuarioLogado,
            atendimentos: (state: AppState) => state.atendimentoContext.atendimentos,
            atendimentosEncerrados: (state: AppState) => state.atendimentoContext.atendimentosEncerrados,
            currentAtendimento: (state: AppState) => state.atendimentoContext.currentAtendimento,
        }),
    },
})
export default class AtendimentoComponent extends Vue {
    // State computed props
    usuarioLogado: UsuarioModel;
    atendimentos: AtendimentoWhatsAppModel[];
    atendimentosEncerrados: AtendimentoWhatsAppModel[];
    currentAtendimento: AtendimentoWhatsAppModel;

    private adicionarAtendimentoComponent: AdicionarAtendimentoComponent = null;

    private atendimentoService = new AtendimentoService();

    private unsubscribe = null;

    filterKey = "";
    category = 1;

    pageSize = 20;
    pageIndex = 1;
    total = 0;

    list: AtendimentoWhatsAppModel[] = [];

    private async selectContact(contact: AtendimentoWhatsAppModel) {
        await this.$store.dispatch(AtendimentoContextActions.SET_CURRENT_ATENDIMENTO, contact.id);

        await Delay(100);
        this.$emit("onSelectContact");
    }

    private async onLoadMore() {
        clearTimeout(filterDebounceTimeout);

        if (this.category == 4) {
            this.list = this.atendimentosEncerrados;

            filterDebounceTimeout = setTimeout(() => {
                this.loadAtendimentosEncerrados();
            }, 800);
        } else {
            await this.loadAtendimentos();

            switch (this.category) {
                case 1:
                    this.list = this.atendimentos.filter(
                        p => p.usuarioId == this.usuarioLogado.id && p.statusAtendimento != StatusAtendimento.Fechado,
                    );
                    break;
                case 2:
                    this.list = this.atendimentos.filter(
                        p => !p.usuarioId && p.statusAtendimento != StatusAtendimento.Fechado,
                    );
                    break;
                case 3:
                    this.list = this.atendimentos.filter(
                        p =>
                            p.usuarioId &&
                            p.usuarioId != this.usuarioLogado.id &&
                            p.statusAtendimento != StatusAtendimento.Fechado,
                    );
                    break;
            }

            if (this.filterKey.length > 0) {
                this.list = this.list.filter(p => p.nomeContato.toLowerCase().includes(this.filterKey.toLowerCase()));
            }
        }
    }

    private selectCategory(category: number) {
        this.filterKey = "";
        this.category = category;

        this.onLoadMore();
    }

    private formatTimestamp(timestamp: string) {
        if (!timestamp) {
            return "";
        }

        const date = new Date(timestamp);
        const today = new Date();

        const yesterday = new Date(today);
        yesterday.setDate(today.getDate() - 1);

        const twoDaysAgo = new Date(today);
        twoDaysAgo.setDate(today.getDate() - 2);

        const threeDaysAgo = new Date(today);
        threeDaysAgo.setDate(new Date().getDate() - 3);

        const fourDaysAgo = new Date(today);
        fourDaysAgo.setDate(today.getDate() - 4);

        const daysOfWeek = ["Domingo", "Segunda", "Terça", "Quarta", "Quinta", "Sexta", "Sábado"];

        switch (date.toDateString()) {
            case today.toDateString():
                return date.toLocaleString("pt-BR", {
                    hour: "2-digit",
                    minute: "2-digit",
                });
            case yesterday.toDateString():
                return "Ontem";
            case twoDaysAgo.toDateString():
                return daysOfWeek[date.getDay()];
            case threeDaysAgo.toDateString():
                return daysOfWeek[date.getDay()];
            case fourDaysAgo.toDateString():
                return daysOfWeek[date.getDay()];
            default:
                return date.toLocaleString("pt-BR", {
                    day: "2-digit",
                    month: "2-digit",
                    year: "numeric",
                });
        }
    }

    private async loadAtendimentos() {
        if (this.atendimentos.length == 0) {
            this.list = await this.atendimentoService.getListAtendimentos();
            this.$store.dispatch(AtendimentoContextActions.SET_ATENDIMENTOS, this.list);
        }
    }

    private async loadAtendimentosEncerrados() {
        const hasMorePages = Math.ceil(this.total / this.pageSize) >= this.pageIndex;
        if (this.total == 0 || hasMorePages) {
            const response = await this.atendimentoService.getListAtendimentosEncerrados(
                this.pageIndex,
                this.pageSize,
                this.filterKey,
            );

            this.total = response.total;

            if (this.pageIndex == 1) {
                this.list = [...response.list];
            } else {
                this.list = [...this.list, ...response.list];
            }

            this.$store.dispatch(AtendimentoContextActions.SET_ATENDIMENTOS_ENCERRADOS, this.list);
            this.pageIndex++;
        }
    }

    private onCreate() {
        this.adicionarAtendimentoComponent = this.$refs.adicionarAtendimentoComponent as AdicionarAtendimentoComponent;
        this.adicionarAtendimentoComponent.open();
    }

    private onCreated() {
        this.$emit("onSelectContact");
    }

    @Watch("filterKey")
    private onChangeFilterKey() {
        if (this.category == 4) {
            this.pageIndex = 1;
        }

        this.onLoadMore();
    }

    private mounted() {
        this.unsubscribe = this.$store.subscribe(async mutation => {
            switch (mutation.type) {
                case AtendimentoContextMutations.UPDATE_ATENDIMENTO:
                    this.onLoadMore();
                    break;
                case AtendimentoContextMutations.ORDER_ATENDIMENTOS:
                    if (this.category != 4) {
                        this.onLoadMore();
                    }
                    break;
            }
        });
    }

    private destroy() {
        this.unsubscribe();
    }
}
