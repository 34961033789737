import VendaEntregaStatus from "./enum/VendaEntregaStatus";

export default class VendaEntregaModel {
    id: number = null;
    vendaId: number = null;
    clienteId: number = null;
    data: Date = null;
    status: VendaEntregaStatus = null;
    statusDescricao: string = null;
    motivo: string = null;
}
