import { mapGetters } from "vuex";

import { GridAction } from "@/components/child/grid/gridAction";
import { GridColors, GridColumn, GridColumnType } from "@/components/child/grid/gridColumn";
import LogEntidadeComponent from "@/components/child/logEntidade/logEntidade";
import logEntidadeComponent from "@/components/child/logEntidade/logEntidade.vue";
import shortcutComponent from "@/components/parent/shortcut/shortcut.vue";
import { Component } from "@/decorators";
import ConfiguracaoFranquiaModel from "@/models/configuracaoFranquia/configuracaoFranquiaModel";
import Configuracoes from "@/models/enum/configuracao/configuracoes";
import TipoLogEntidade from "@/models/enum/tipoLogEntidade";
import SessaoCaixaModel from "@/models/pdv/sessaoCaixaModel";
import PDFService from "@/services/pdf/PDFService";
import SessaoCaixaService from "@/services/pdv/sessaoCaixaService";
import { Getters } from "@/store/store";
import FileHelper from "@/utils/common/fileHelper";

import ListComponentBase from "../crud/listComponentBase";
import ShortcutComponent from "../shortcut/shortcut";

@Component({
    components: {
        shortcutComponent,
        logEntidadeComponent,
    },
    computed: {
        ...mapGetters(["GET_CONFIG_FRANQUIA"] as Getters),
    },
})
export default class SessaoCaixaListComponent extends ListComponentBase<SessaoCaixaModel> {
    // State computed props
    GET_CONFIG_FRANQUIA: (configuracao: Configuracoes) => ConfiguracaoFranquiaModel;

    private shortcutComponent: ShortcutComponent = null;
    private logEntidadeComponent: LogEntidadeComponent = null;

    private PDFService = new PDFService("SessaoCaixaPDF");
    private sessaoCaixaService = new SessaoCaixaService();

    get gridColumns(): Array<GridColumn> {
        return [
            new GridColumn("dataHoraAbertura", "Data/Hora Abertura", GridColumnType.DateTime),
            new GridColumn("dataHoraFechamento", "Data/Hora Fechamento", GridColumnType.DateTime),
            new GridColumn("saldoInicial", "Saldo Inicial", GridColumnType.Money),
            new GridColumn("saldoFechamento", "Saldo Fechamento", GridColumnType.Money),
            new GridColumn("totalEntradas", "Total Entrada", GridColumnType.Money),
            new GridColumn("totalSaidas", "Total Saída", GridColumnType.Money),
            new GridColumn("nomeUsuario", "Usuario", GridColumnType.String),
            new GridColumn("dataHoraReabertura", "Data/Hora Reabertura", GridColumnType.DateTime),
        ];
    }

    extraActions: Array<object> = [];
    extraActionsPdf: Array<GridAction> = [];
    extraActionsReabrirCaixa: Array<GridAction> = [];
    extraActionsLogs: Array<GridAction> = [];
    extraActionsXlx: Array<GridAction> = [];

    public override async afterLoad() {
        this.extraActions.splice(0);
        this.extraActionsPdf.splice(0);
        const permiteReabertura = this.GET_CONFIG_FRANQUIA(Configuracoes.PermiteReaberturaCaixa).verdadeiro;

        for (let i = 0; i < this.gridData.length; i++) {
            this.extraActionsPdf.push(
                new GridAction(
                    "abrir-pdf",
                    "Abrir PDF",
                    "fa fa-file-pdf",
                    GridColors.RED,
                    !!this.gridData[i].dataHoraFechamento,
                ),
            );
            this.extraActionsXlx.push(
                new GridAction(
                    "abrir-xls",
                    "Abrir XLS",
                    "fa fa-file-excel",
                    GridColors.GREEN,
                    !!this.gridData[i].dataHoraFechamento,
                ),
            );
            if (permiteReabertura) {
                this.extraActionsReabrirCaixa.push(
                    new GridAction("reabrir-caixa", "Reabrir Caixa", "fa fa-cash-register", GridColors.GREEN),
                );
            }
            this.extraActionsLogs.push(
                new GridAction(
                    "logs",
                    this.$t("__.ts.historicoAlteracoes") as string,
                    "fa fa-history",
                    GridColors.BLUE,
                ),
            );
        }
        this.extraActions.push(this.extraActionsLogs);
        this.extraActions.push(this.extraActionsReabrirCaixa);
        this.extraActions.push(this.extraActionsPdf);
        this.extraActions.push(this.extraActionsXlx);
    }

    private beforeMount() {
        this.setProps(new SessaoCaixaService(), "sessaocaixa", "dataHoraAbertura", false, "desc");
    }

    private async openXLS(id: number) {
        try {
            const response = await this.sessaoCaixaService.getRelatorioXLS(id).withLoading().resolveWithResponse();
            const data = await response.blob();
            const blob = new Blob([data], { type: "text/xls;charset=utf-8;" });

            const fileName = response.headers.get("Content-Disposition").split("filename=")[1].split(";")[0];
            FileHelper.download(blob, fileName);
        } catch {}
    }

    public override onCreateItem() {
        this.$router.push("/sessaoCaixa-fechar-caixa");
    }

    private async onExtraAction(name: string, model: SessaoCaixaModel) {
        if (name.trim() == "abrir-pdf") {
            await this.PDFService.geraPDF(model.id);
        }
        if (name.trim() == "abrir-xls") {
            await this.openXLS(model.id);
        }
        if (name.trim() == "reabrir-caixa") {
            try {
                const data = await this.sessaoCaixaService.reabrirCaixa(model.id).withLoading().resolveWithoutJSON();
                if (data) {
                    this.$showSuccess(this.$t("__.ts.sucesso"), this.$t("__.ts.sucessoReabertura"));
                    this.load();
                }
            } catch {}
        }
        if (name.trim() == "logs") {
            this.shortcutComponent = this.$refs.shortcutComponent as ShortcutComponent;
            this.shortcutComponent.title = this.$t("__.ts.historicoAlteracoes") as string;

            this.logEntidadeComponent = this.$refs.logEntidadeComponent as LogEntidadeComponent;
            await this.logEntidadeComponent.show(model.id, TipoLogEntidade.SessaoCaixa);

            this.shortcutComponent.show();
        }
    }
}
