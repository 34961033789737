import Vue from "vue";
import { mapState } from "vuex";

import dropdownComponent from "@/components/child/dropdown/dropdown.vue";
import { Component } from "@/decorators";
import PreLoadPackModel from "@/models/auxiliar/preLoadPackModel";
import StatusAtendimento from "@/models/enum/statusAtendimento";
import StatusMensagem from "@/models/enum/statusMensagem";
import UsuarioModel from "@/models/usuarioModel";
import AtendimentoWhatsAppModel from "@/models/whatsApp/atendimento/atendimentoWhatsAppModel";
import MensagemAtendimentoWhatsAppModel from "@/models/whatsApp/atendimento/mensagemAtendimentoWhatsAppModel";
import AtendimentoService from "@/services/atendimentos/atendimentoService";
import MensagemService from "@/services/atendimentos/mensagemService";
import { AtendimentoContextActions, AtendimentoContextMutations } from "@/store/atendimentoContext/types";
import { AppState, SessionActions } from "@/store/store";
import Delay from "@/utils/common/delay";
import { formatWhatsAppString } from "@/utils/common/number";
import Bus from "@/utils/events/bus";

import atendimentosComponent from "./atendimentos/atendimentos.vue";
import { getUltimaMensagem } from "./atendimentoUtils";
import DadosContatoComponent from "./dadosContato/dadosContato";
import dadosContatoComponent from "./dadosContato/dadosContato.vue";
import HistoricoAtendimentoComponent from "./historicoAtendimento/historicoAtendimento";
import historicoAtendimentoComponent from "./historicoAtendimento/historicoAtendimento.vue";
import mensagemComponent from "./mensagem/mensagem.vue";

import "./view.scss";

@Component({
    components: {
        atendimentosComponent,
        dadosContatoComponent,
        dropdownComponent,
        historicoAtendimentoComponent,
        mensagemComponent,
    },
    computed: {
        ...mapState({
            preLoadList: (state: AppState) => state.preLoad.preLoadList,
            usuarioLogado: (state: AppState) => state.session.usuarioLogado,
            currentAtendimento: (state: AppState) => state.atendimentoContext.currentAtendimento,
            messages: (state: AppState) => state.atendimentoContext.currentMessages,
        }),
        atendente() {
            if (!this.currentAtendimento || !this.currentAtendimento.usuarioId) {
                return "";
            }
            const usuario = this.preLoadList.vendedores.find(p => p.id == this.currentAtendimento.usuarioId);
            if (!usuario) {
                return "";
            }
            return `(Atendente: ${usuario?.nome ?? ""})`;
        },
    },
})
export default class AtendimentoComponent extends Vue {
    // State computed props
    preLoadList: PreLoadPackModel;
    usuarioLogado: UsuarioModel;
    currentAtendimento: AtendimentoWhatsAppModel;
    messages: MensagemAtendimentoWhatsAppModel[];

    private dadosContatoComponent: DadosContatoComponent = null;
    private historicoAtendimentoComponent: HistoricoAtendimentoComponent = null;

    private mensagemService = new MensagemService();
    private atendimentoService = new AtendimentoService();

    mensagemAResponser: MensagemAtendimentoWhatsAppModel = null;
    selectedFile: File = null;
    newMessage = "";
    private unsubscribe = null;

    private async vincularUsuarioByAtendimento() {
        await this.atendimentoService.vincularUsuarioByAtendimentoId(this.currentAtendimento.id);

        this.currentAtendimento.usuarioId = this.usuarioLogado.id;
        await this.$store.dispatch(AtendimentoContextActions.UPDATE_ATENDIMENTO, this.currentAtendimento);
        await this.$store.dispatch(AtendimentoContextActions.SET_CURRENT_ATENDIMENTO, this.currentAtendimento.id);
    }

    private async fecharAtendimentoByAtendimento() {
        await this.atendimentoService.fecharAtendimentoByAtendimentoId(this.currentAtendimento.id);

        this.currentAtendimento.statusAtendimento = StatusAtendimento.Fechado;
        await this.$store.dispatch(AtendimentoContextActions.UPDATE_ATENDIMENTO, this.currentAtendimento);
        await this.$store.dispatch(AtendimentoContextActions.SET_CURRENT_ATENDIMENTO, this.currentAtendimento.id);
    }

    private async dadosContatoAtendimento() {
        this.dadosContatoComponent = this.$refs.dadosContatoComponent as DadosContatoComponent;
        return this.dadosContatoComponent.show();
    }

    private historicoAtendimento() {
        this.historicoAtendimentoComponent = this.$refs.historicoAtendimentoComponent as HistoricoAtendimentoComponent;

        this.historicoAtendimentoComponent.show(this.currentAtendimento.contatoId);
    }

    private async sendMessage() {
        if (this.newMessage.trim() === "" && !this.selectedFile) {
            return;
        }

        await this.$store.dispatch(AtendimentoContextActions.SEND_MESSAGE, {
            file: this.selectedFile,
            message: {
                id: Date.now().toString(),
                data: new Date().toISOString(),
                texto: this.newMessage,
                atendimentoId: this.currentAtendimento.id.toString(),
                mensagemPropria: true,
                statusMensagem: StatusMensagem.Enviando,
                mensagemRespondidaId: this.mensagemAResponser != null ? this.mensagemAResponser.id : "",
            },
            telefone: formatWhatsAppString(this.currentAtendimento.numero),
        });

        this.deleteAttachment();
        this.deleteMensagemAResponder();
        this.newMessage = "";

        this.scrollToBottom();
    }

    private onFileChanged(event) {
        this.selectedFile = event.target.files[0];
    }

    private async handleKeyUp(event: KeyboardEvent) {
        if (event.key === "Enter" && !event.shiftKey) {
            event.preventDefault();
            await this.sendMessage();

            this.adjustTextareaStyle(event);
        }
    }

    private adjustTextareaStyle(event: Event) {
        const textarea = event.target as HTMLTextAreaElement;

        const maxLines = 8;
        const size = 15;

        const lineHeight = parseFloat(getComputedStyle(textarea).lineHeight);
        if (textarea.scrollHeight > 2 * lineHeight) {
            const currentLines = Math.floor(Math.min(textarea.scrollHeight / lineHeight - 1, maxLines) - 1);
            textarea.style.height = `${44 + currentLines * size}px`;

            const messagesContent = document.querySelector(".messages-content") as HTMLElement;
            messagesContent.style.height = `calc(${this.mensagemAResponser ? 85 : 90}vh - ${currentLines * size}px)`;

            this.scrollToBottom();
        }

        if (!this.newMessage) {
            textarea.style.height = "44px";

            const messagesContent = document.querySelector(".messages-content") as HTMLElement;
            messagesContent.style.height = `${this.mensagemAResponser ? 85 : 90}vh`;
        }
    }

    private async onObservacao() {
        const data = await this.$showAlert({
            title: "Observação",
            text: "",
            icon: "info",
            html: `<label>Informe uma observação para salvar no atendimento</label>`,
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: "Confirmar",
            cancelButtonText: "Fechar",
            input: "textarea",
            inputValue: "",
            inputValidator: result => {
                if (!result) {
                    return "Necessário informar uma observação.";
                }
                return "";
            },
        });

        if (data.value) {
            await this.mensagemService.inserirObservacaoByAtendimentoId(data.value, this.currentAtendimento.id);

            await this.$store.dispatch(AtendimentoContextActions.SEND_MESSAGE_OBS, {
                file: null,
                message: {
                    id: Date.now().toString(),
                    data: new Date().toISOString(),
                    texto: data.value,
                    atendimentoId: this.currentAtendimento.id.toString(),
                    mensagemPropria: true,
                    statusMensagem: StatusMensagem.Enviada,
                    observacao: true,
                },
                telefone: formatWhatsAppString(this.currentAtendimento.numero),
            });

            this.scrollToBottom();
        }
    }

    private sentBy(mensagemRespondidaId: string) {
        const mensagemRespondida = this.getMensagemRespondida(mensagemRespondidaId);
        if (!mensagemRespondida) {
            return "Mensagem inexistente no histórico";
        }
        if (mensagemRespondida.mensagemPropria) {
            return "Você";
        }

        return this.currentAtendimento.nomeContato;
    }

    private isMensagemContato(mensagemRespondidaId: string) {
        const mensagemRespondida = this.getMensagemRespondida(mensagemRespondidaId);
        if (!mensagemRespondida || mensagemRespondida?.mensagemPropria) {
            return false;
        }
        return true;
    }

    private getMensagemRespondida(mensagemRespondidaId: string) {
        return this.messages.find(item => item.id == mensagemRespondidaId);
    }

    private deleteAttachment() {
        this.selectedFile = null;
    }

    private scrollToBottom() {
        const container = document.getElementsByClassName("messages-content")[0];
        container.scrollTop = container.scrollHeight;
    }

    private focusInput() {
        const input = document.getElementById("text-input");
        if (input) {
            input.focus();
        }
    }

    private onResponder(mensagem: MensagemAtendimentoWhatsAppModel) {
        this.mensagemAResponser = mensagem;

        const messagesContent = document.querySelector(".messages-content") as HTMLElement;
        messagesContent.style.height = "85vh";

        this.scrollToBottom();
        this.focusInput();
    }

    private onSelectContact() {
        this.scrollToBottom();
        this.focusInput();
    }

    private getTextMensagemAResponser(mensagem: MensagemAtendimentoWhatsAppModel) {
        return getUltimaMensagem(mensagem);
    }

    private deleteMensagemAResponder() {
        this.mensagemAResponser = null;
    }

    private async mounted() {
        await this.$store.dispatch(SessionActions.LOAD_USUARIO_LOGADO);
        if (!this.usuarioLogado.franquia.moduloWhatsApp) {
            this.$showWarning(this.$t("__.ts.atencao"), this.$t("__.ts.franquiaNaoModuloContratado"));

            return this.$router.back();
        }

        Bus.$emit("hide-nav-menu", true);

        this.unsubscribe = this.$store.subscribe(async mutation => {
            switch (mutation.type) {
                case AtendimentoContextMutations.SET_CURRENT_ATENDIMENTO:
                    this.deleteMensagemAResponder();
                    break;
                case AtendimentoContextMutations.RECEIVE_MESSAGE:
                    if (
                        mutation.payload.atendimentoId &&
                        this.currentAtendimento &&
                        mutation.payload.atendimentoId === this.currentAtendimento.id
                    ) {
                        await Delay(100);

                        this.scrollToBottom();
                    }
                    break;
            }
        });
    }

    private destroy() {
        this.unsubscribe();
    }
}
