import BaseModel from "./base/baseModel";
import ProdutoModel from "./produto/produtoModel";
import ServicoModel from "./servicoModel";

export default class NotaFiscalSaidaItemModel extends BaseModel {
    id?: number = null;
    codigo: string = null;
    descricao: string = null;
    valorTotal: number = null;
    servicoId: number = null;
    servico: ServicoModel = null;
    quantidade: number = null;
    precoUnitario: number = null;
    valorFrete: number = null;
    valorAcrescimo: number = null;
    valorDesconto: number = null;
    valorTotalCalculado: number = null;
    codigoNCM: string = null;
    codigoCest: string = null;
    valorProdutos: number = null;
    produtoId: number = null;
    produto: ProdutoModel = new ProdutoModel();
    informacaoAdicional: string = null;
    codigoBeneficio: string = null;
    unidadeMedidaId: number = null;
    unidadeMedidaSigla: string = null;
    EAN: number = null;
    NcmId: number = null;
    valorUnitario: number = null;
    valorSeguro: number = null;
    valorOutraDespesa: number = null;

    baseICMS: number = null;
    aliquotaICMS: number = null;
    valorICMS: number = null;
    percentualDiferimento: number = null;
    valorICMSDiferido: number = null;
    valorICMSPagar: number = null;
    percentualReducaoBase: number = null;
    baseReduzida: number = null;
    OrigemMercadoria: number = null;
    CstICMS: number = null;

    comICMSPartilha = false;
    baseUFDestino: number = null;
    baseFCPUFDestino: number = null;
    aliquotaFCPUFDestino: number = null;
    aliquotaUFDestino: number = null;
    aliquotaInterestadual: number = null;
    aliquotaInterestadualPartilha: number = null;
    iCMSFCPUFDestino: number = null;
    iCMSUFDestino: number = null;
    iCMSUFRemetente: number = null;

    baseII: number = null;
    valorDespesasAduaneiras: number = null;
    valorII: number = null;
    valorIOF: number = null;
    taxaSISCOMEX: number = null;

    sujeitoIPI = false;
    baseIPI: number = null;
    aliquotaIPI: number = null;
    valorIPI: number = null;
    CstIPI: number = null;

    basePIS: number = null;
    aliquotaPIS: number = null;
    valorPIS: number = null;
    CstPIS: number = null;

    baseCOFINS: number = null;
    aliquotaCOFINS: number = null;
    valorCOFINS: number = null;
    CstCOFINS: number = null;
}
