import Vue from "vue";

import actionBarComponent from "@/components/child/actionBar/actionBar.vue";
import checkboxComponent from "@/components/child/form/checkbox.vue";
import gridComponent from "@/components/child/grid/grid.vue";
import { GridColumn, GridColumnType } from "@/components/child/grid/gridColumn";
import { Component, Watch } from "@/decorators";
import RecebimentoParceladoItemModel from "@/models/financeiro/recebimentoParceladoItemModel";
import RecebimentoParceladoModel from "@/models/financeiro/recebimentoParceladoModel";
import RecebimentoParceladoService from "@/services/financeiro/recebimentoParceladoService";
import arithmeticHelper from "@/utils/common/arithmeticHelper";

import "../crud.scss";

@Component({
    components: {
        checkboxComponent,
        gridComponent,
        actionBarComponent,
    },
})
export default class RecebimentoParceladoEditComponent extends Vue {
    private service = new RecebimentoParceladoService();

    model = new RecebimentoParceladoModel();
    modelId = 0;

    private numeroParcelas: number = null;

    private gridData: RecebimentoParceladoItemModel[] = [];

    get gridColumns(): Array<GridColumn> {
        return [
            new GridColumn("diasVencimento", this.$t("__.ts.diasVencimento") as string, GridColumnType.Integer, true),
            new GridColumn(
                "percentual",
                this.$t("__.ts.percentualValorAPagar") as string,
                GridColumnType.Percent,
                true,
            ),
        ];
    }

    private async load() {
        this.model = new RecebimentoParceladoModel();

        if (this.modelId > 0) {
            try {
                const data = await this.service
                    .get(this.modelId)
                    .withLoading()
                    .resolveWithJSON<RecebimentoParceladoModel>();

                this.model.updateFrom(data);

                this.gridData = this.model.itens;
            } catch {
                this.$router.back();
            }
        }
    }

    private async save() {
        try {
            const isValid = await this.$validator.validateAll();
            if (isValid) {
                const percentualTotal = this.getPercentualTotal();
                if (percentualTotal != 100) {
                    this.$showWarning(
                        this.$t("__.ts.atencao") as string,
                        this.$t("__.ts.somaValoresPercentualTotal")
                            .toString()
                            .replace("{replace}", arithmeticHelper.round(percentualTotal).toString()),
                    );
                    return;
                }

                this.model.itens = this.gridData;

                const sucesso = await this.service[!this.model.id ? "insert" : "update"](this.model)
                    .withLoading()
                    .resolveWithoutJSON();

                if (sucesso) {
                    await this.$showInclusaoUpdate(this.model.id);
                    this.$router.back();
                }
            }
        } catch {}
    }

    // @ts-ignore
    @Watch("gridData", { deep: true })
    private onChangeGridData() {
        if (!this.model.id && this.gridData.length > 0) {
            if (this.gridData.length == 1) {
                this.model.descricao = this.gridData[0].diasVencimento.toString();
            } else {
                this.model.descricao = this.gridData.reduce(
                    (acc, p) => (acc ? `${acc}/${p.diasVencimento}` : acc + p.diasVencimento.toString()),
                    "",
                );
            }
            this.model.descricao += " " + this.$t("__.ts.dias");
        }
    }

    private onChangeNumeroParcelas() {
        this.gridData = [];
        let diasVencimento = 0;

        if (this.numeroParcelas) {
            for (let index = 0; index < this.numeroParcelas; index++) {
                diasVencimento += 15;

                const item = new RecebimentoParceladoItemModel();
                item.diasVencimento = diasVencimento;
                item.percentual = arithmeticHelper.round(100 / this.numeroParcelas, 8);

                this.gridData.push(item);
            }

            const percentualTotal = this.getPercentualTotal();
            if (percentualTotal != 100) {
                const diferenca = arithmeticHelper.round(100 - percentualTotal);
                this.gridData[this.gridData.length - 1].percentual = arithmeticHelper.round(
                    this.gridData[this.gridData.length - 1].percentual + diferenca, 8
                );
            }
        }
    }

    private getPercentualTotal() {
        return arithmeticHelper.round(this.gridData.reduce((acc, p) => acc + Number(p.percentual), 0));
    }

    private cancel() {
        this.$router.back();
    }

    private mounted() {
        if (this.$route.params.id) {
            this.modelId = +this.$route.params.id;
            this.load();
        }
    }
}
