import Vue from "vue";

import actionBarComponent from "@/components/child/actionBar/actionBar.vue";
import checkboxComponent from "@/components/child/form/checkbox.vue";
import comboComponent from "@/components/child/form/combo.vue";
import dateTimePickerComponent from "@/components/child/form/datetimepicker.vue";
import fieldsetComponent from "@/components/child/form/fieldset.vue";
import gridComponent from "@/components/child/grid/grid.vue";
import LoadingModalComponent from "@/components/child/loadingmodal/loadingmodal";
import loadingModalComponent from "@/components/child/loadingmodal/loadingmodal.vue";
import { Component } from "@/decorators";
import InutilizacaoNotaFiscalModel from "@/models/inutilizacaoNotaFiscalModel";
import ValidationErrorModel from "@/models/validationErrorModel";
import InutilizacaoNotaFiscalService from "@/services/inutilizacaoNotaFiscalService";
import ValidationErrorWrapper from "@/wrappers/validationErrorWrapper";

import "../../crud/crud.scss";

@Component({
    components: {
        checkboxComponent,
        fieldsetComponent,
        comboComponent,
        dateTimePickerComponent,
        gridComponent,
        loadingModalComponent,
        actionBarComponent,
    },
})
export default class InutilizacaoNotaFiscalEditComponent extends Vue {
    private validationErrorWrapper = new ValidationErrorWrapper(this.$validator);
    private loadingModalComponent: LoadingModalComponent = null;
    private service = new InutilizacaoNotaFiscalService();

    private model = new InutilizacaoNotaFiscalModel();
    private modelId = null;

    private modeloOptions: Array<object> = [
        { text: "55-NF-e", value: 55 },
        { text: "65-NFC-e", value: 65 },
    ];

    constructor() {
        super();
    }

    private async load() {
        this.model = new InutilizacaoNotaFiscalModel();
        this.validationErrorWrapper.clearErrors();

        if (this.modelId > 0) {
            this.loadingModalComponent.showLoading();

            const data = await this.service
                .get(this.modelId)
                .then(r => r.json() as Promise<InutilizacaoNotaFiscalModel>);
            this.model.updateFrom(data);
            this.loadingModalComponent.hide();
        } else {
            this.loadingModalComponent.hide();
        }
    }

    private cancel() {
        this.$router.back();
    }

    private async save() {
        try {
            const isValid = await this.$validator.validateAll();
            if (isValid) {
                this.loadingModalComponent.showSaving();

                const response = await this.service[!this.model.id ? "insert" : "update"](this.model);
                if (!(await this.hasErrors(response))) {
                    await this.$showSuccess(this.$t("__.ts.inutilizacao"), this.$t("__.ts.inutilizacaoSucesso"));
                }
                this.$router.back();
            }
        } catch {}

        this.loadingModalComponent.hide();
    }

    private async hasErrors(response: Response): Promise<boolean> {
        if (!response.ok) {
            const data = (await response.json()) as ValidationErrorModel[];
            this.validationErrorWrapper.showErrors(data);
            return true;
        }
        return false;
    }

    private mounted() {
        this.loadingModalComponent = this.$refs.loadingModalComponent as LoadingModalComponent;
        this.loadingModalComponent.showLoading();

        if (this.$route.params.id) {
            this.modelId = this.$route.params.id;
        }

        this.load();

        this.loadingModalComponent.hide();
    }
}
