import BaseModel from "./../base/baseModel";
import RecebimentoParceladoItemModel from "./recebimentoParceladoItemModel";

export default class RecebimentoParceladoModel extends BaseModel {
    id?: number = null;
    franquiaId?: number = null;
    descricao: string = null;
    ativo = true;
    itens: RecebimentoParceladoItemModel[] = [];
}
