import BaseModel from "../base/baseModel";
import TipoBeneficio from "../enum/tipoBeneficio";

export default class BeneficioFaixaModel extends BaseModel {
    constructor(tipoFaixa: TipoBeneficio = TipoBeneficio.Comissao) {
        super();
        this.tipoBeneficio = tipoFaixa;
    }
    id?: number = null;
    beneficioId: number = null;
    inicioFaixa = 0;
    fimFaixa = 0;
    valor = 0;
    tipoBeneficio: TipoBeneficio = null;
}
