export class ModalButtonAction {
    constructor(name: string, tooltipText: string, enabled = true, shortKey: string = null, classe: string = null) {
        this.name = name;
        this.tooltipText = tooltipText;
        this.enabled = enabled;
        this.shortKey = shortKey;
        this.classe = classe;
    }

    name: string;
    tooltipText: string;
    enabled: boolean;
    shortKey: string;
    classe: string;
}
