import Vue from "vue";
import { mapGetters } from "vuex";

import dateTimePickerComponent from "@/components/child/form/datetimepicker.vue";
import Grid from "@/components/child/grid/grid";
import gridComponent from "@/components/child/grid/grid.vue";
import { GridAction } from "@/components/child/grid/gridAction";
import { GridColors, GridColumn, GridColumnType } from "@/components/child/grid/gridColumn";
import { Component, Prop, Watch } from "@/decorators";
import PaginationModel from "@/models/paginationModel";
import VendaContinuoModel, { VendaContinuoParameters } from "@/models/vendaContinuoModel";
import VendaContinuoUtilsService from "@/services/utils/vendaContinuoUtilsService";
import VendaContinuoService from "@/services/vendaContinuoService";
import { Getters } from "@/store/store";
import { convertToDateInputString } from "@/utils/common/date";

import "../crud.scss";

@Component({
    components: {
        gridComponent,
        dateTimePickerComponent,
    },
    computed: {
        ...mapGetters(["VALIDAR_PERMISSAO_USUARIO"] as Getters),
    },
})
export default class VendaContinuoListComponent extends Vue {
    // State computed props
    VALIDAR_PERMISSAO_USUARIO: (telaDescricao: string, acaoController: string) => boolean;

    private service = new VendaContinuoService();
    private gridcomponent: Grid = null;

    private title = "Venda Continuos";

    private vendaContinuoUtilsService = new VendaContinuoUtilsService();

    @Prop({ type: Boolean, default: false }) loadManual: boolean;

    gridSelectedValues: Array<VendaContinuoModel> = [];
    gridFilterKey = "";
    gridSortKey = "data_vencimento";
    gridSortOrder = "asc";
    gridData: Array<VendaContinuoModel> = [];
    isHidden = false;
    get gridColumns(): Array<GridColumn> {
        return [
            new GridColumn("clienteNome", "Cliente", GridColumnType.String, false, false, "", "", true),
            new GridColumn("pacienteNome", "Paciente", GridColumnType.String, false, false, "", "", true),
            new GridColumn("dataVencimento", "Vencimento", GridColumnType.Date, false, false, "", "", true),
            new GridColumn("valor", "Valor Vendido", GridColumnType.Money, false, false, "", "", true),
            new GridColumn("valorAtualizado", "Valor Atualizado", GridColumnType.Money, false, false, "", "", false),
            new GridColumn("dataValidade", "Validade", GridColumnType.Date, false, false, "", "", true),
        ];
    }

    extraActions: Array<object> = [];
    gridExtraActionsVisualizar: Array<GridAction> = [];
    gridExtraActionsAdiar: Array<GridAction> = [];
    gridExtraActionsArquivar: Array<GridAction> = [];
    gridExtraActionsWhats: Array<GridAction> = [];

    pageIndex = 1;
    pageSize = 10;
    total = 0;

    pags = false;
    tipoDocGerar = null;
    filtro: Array<Object> = [];
    dataFilter1: Date = null;
    dataFilter2: Date = null;

    private aprovarSelecionados() {}

    private onSelectedValuesChanged(values: Array<VendaContinuoModel>) {
        this.gridSelectedValues = values;
    }

    public async load() {
        try {
            const customParameters: VendaContinuoParameters = {
                dataInicial: convertToDateInputString(this.filtro[0]),
                dataFinal: convertToDateInputString(this.filtro[1], true),
            };

            const data = await this.service
                .list(
                    this.gridFilterKey,
                    this.gridSortKey,
                    this.gridSortOrder,
                    this.pags ? 1 : this.pageIndex,
                    this.pags ? 999999 : this.pageSize,
                    customParameters,
                )
                .withLoading()
                .resolveWithJSON<PaginationModel<VendaContinuoModel>>();

            this.gridData = data.list;
            this.total = data.total;

            this.gridExtraActionsVisualizar.splice(0);
            this.gridExtraActionsAdiar.splice(0);
            this.gridExtraActionsArquivar.splice(0);
            this.gridExtraActionsWhats.splice(0);
            this.extraActions.splice(0);
            this.isHidden = false;

            for (let i = 0; i < this.gridData.length; i++) {
                this.gridExtraActionsVisualizar.push(
                    new GridAction("visualizar-venda", "Detalhes da Venda/Orçamento", "fa fa-search", GridColors.BLUE),
                );

                if (this.VALIDAR_PERMISSAO_USUARIO("vendas", "AdiarVencimentoContinuo")) {
                    this.gridExtraActionsAdiar.push(
                        new GridAction("adiar-continuo", "Adiar Aviso", "fa fa-clock", GridColors.RED),
                    );
                }

                if (this.VALIDAR_PERMISSAO_USUARIO("vendas", "ArquivarAvisoContinuo")) {
                    this.gridExtraActionsArquivar.push(
                        new GridAction("arquivar-continuo", "Arquivar Aviso", "fa fa-archive", GridColors.ORANGE),
                    );
                }

                if (this.VALIDAR_PERMISSAO_USUARIO("vendas", "GerarOrcamentoWhatsapp")) {
                    this.gridExtraActionsWhats.push(
                        new GridAction(
                            "orcar-continuo",
                            "Orçar e enviar mensagem pelo Whatsapp.",
                            "fab fa-whatsapp fa-2x",
                            GridColors.GREEN,
                        ),
                    );
                }
            }

            this.extraActions.push(this.gridExtraActionsArquivar);
            this.extraActions.push(this.gridExtraActionsAdiar);
            this.extraActions.push(this.gridExtraActionsVisualizar);
            this.extraActions.push(this.gridExtraActionsWhats);

            if (this.pags) {
                if (this.tipoDocGerar == 0) {
                    setTimeout(() => {
                        this.gridcomponent.gerarXls();
                    }, 2000);
                }

                if (this.tipoDocGerar == 1) {
                    setTimeout(() => {
                        this.gridcomponent.gerarPdf();
                    }, 2000);
                }

                if (this.tipoDocGerar == 2) {
                    setTimeout(() => {
                        this.gridcomponent.gerarImpressao();
                    }, 2000);
                }

                setTimeout(() => {
                    this.pags = false;
                }, 2000);
            }
        } catch {}
    }

    private onChangeFilterKey(filterKey: string) {
        this.gridFilterKey = filterKey;
        this.load();
    }

    private onChangeSort(sortKey: string, sortOrder: string) {
        this.gridSortKey = sortKey;
        this.gridSortOrder = sortOrder;
        this.load();
    }

    private onChangePage(pageIndex: number) {
        this.pageIndex = pageIndex;
        this.load();
    }

    private onExtraAction(name: string, model: VendaContinuoModel) {
        if (name.trim() == "visualizar-venda") {
            this.$router.push("/venda-edicao/" + model.vendaId);
        } else if (name.trim() == "arquivar-continuo") {
            this.onArquivar(model);
        } else if (name.trim() == "adiar-continuo") {
            this.onAdiar(model);
        } else if (name.trim() == "orcar-continuo") {
            this.onWhats(model);
        }
    }

    private getFormValues(listaFiltro) {
        this.filtro = [];
        this.filtro.push(listaFiltro.target.elements.dataFilter1.value);
        this.filtro.push(listaFiltro.target.elements.dataFilter2.value);

        this.load();
    }

    private async onArquivar(venda: VendaContinuoModel) {
        const sucesso = await this.vendaContinuoUtilsService.arquivar(venda);
        if (sucesso) {
            this.load();
        }
    }

    private async onAdiar(venda: VendaContinuoModel) {
        const sucesso = await this.vendaContinuoUtilsService.adiar(venda);
        if (sucesso) {
            this.load();
        }
    }

    private async onWhats(venda: VendaContinuoModel) {
        const sucesso = await this.vendaContinuoUtilsService.getMessageWhats(venda);
        if (sucesso) {
            this.load();
        }
    }

    @Watch("pags")
    private onPags() {
        this.load();
    }

    private mounted() {
        this.gridcomponent = this.$refs.gridcomponent as Grid;

        if (!this.loadManual) {
            this.load();
        }
    }
}
