var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "shortcutComponent",
    {
      ref: "shortcutComponent",
      attrs: { showAddNew: false },
      on: { "hide-click": _vm.hide, "selection-item": _vm.save },
    },
    [
      _c("div", { attrs: { slot: "component" }, slot: "component" }, [
        _c("div", { attrs: { slot: "rows" }, slot: "rows" }, [
          _c(
            "form",
            {
              attrs: { novalidate: "" },
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.save($event)
                },
              },
            },
            [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-sm-3" }, [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "tooltip",
                          rawName: "v-tooltip",
                          value: _vm.errors.first("formaRecebimentoId"),
                          expression: "errors.first('formaRecebimentoId')",
                        },
                      ],
                      staticClass: "form-group",
                    },
                    [
                      _c(
                        "label",
                        {
                          staticClass: "control-label",
                          attrs: {
                            for: "formaRecebimentoId",
                            title: _vm.$t("__.obrigatorio"),
                          },
                        },
                        [
                          _c("span", { staticClass: "color-red" }, [
                            _vm._v("*"),
                          ]),
                          _vm._v(
                            "\n                                " +
                              _vm._s(
                                _vm.$t(
                                  "__.Components.parent.venda.pagamento_vue_html.frmaRecebimento"
                                )
                              ) +
                              "\n                            "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("combo-component", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required",
                            expression: "'required'",
                          },
                        ],
                        attrs: {
                          id: "formaRecebimentoId",
                          name: "formaRecebimentoId",
                          data: _vm.formaRecebimentoOptionsFiltrado,
                          searchable: true,
                          refresh: _vm.loadFormasRecebimento,
                        },
                        model: {
                          value: _vm.model.formaRecebimentoId,
                          callback: function ($$v) {
                            _vm.$set(_vm.model, "formaRecebimentoId", $$v)
                          },
                          expression: "model.formaRecebimentoId",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-sm-3" }, [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "tooltip",
                          rawName: "v-tooltip",
                          value: _vm.errors.first("valor"),
                          expression: "errors.first('valor')",
                        },
                      ],
                      staticClass: "form-group",
                    },
                    [
                      _c(
                        "label",
                        {
                          staticClass: "control-label",
                          attrs: { for: "valor" },
                        },
                        [
                          _vm._v(
                            "\n                                " +
                              _vm._s(
                                _vm.$t(
                                  "__.Components.parent.venda.financeiro.parcela_vue_html.valor"
                                )
                              ) +
                              "\n                            "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("moeda-component", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required|maior_que_zero",
                            expression: "'required|maior_que_zero'",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: { id: "valor", name: "valor" },
                        model: {
                          value: _vm.model.valor,
                          callback: function ($$v) {
                            _vm.$set(_vm.model, "valor", $$v)
                          },
                          expression: "model.valor",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _vm.modelFormaRecebimento.especie == 0
                  ? _c("div", { staticClass: "col-sm-3" }, [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: _vm.errors.first("valorAbater"),
                              expression: "errors.first('valorAbater')",
                            },
                          ],
                          staticClass: "form-group",
                        },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "control-label",
                              attrs: { for: "valorAbater" },
                            },
                            [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(
                                    _vm.$t(
                                      "__.Components.parent.venda.financeiro.preFaturamento_vue_html.valorSerPago"
                                    )
                                  ) +
                                  "\n                            "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("moeda-component", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required|maior_que_zero",
                                expression: "'required|maior_que_zero'",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: { id: "valorAbater", name: "valorAbater" },
                            model: {
                              value: _vm.valorAbater,
                              callback: function ($$v) {
                                _vm.valorAbater = $$v
                              },
                              expression: "valorAbater",
                            },
                          }),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.modelFormaRecebimento.especie == 0
                  ? _c("div", { staticClass: "col-sm-3" }, [
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "control-label",
                              attrs: { for: "valorTroco" },
                            },
                            [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(
                                    _vm.$t(
                                      "__.Components.parent.venda.financeiro.preFaturamento_vue_html.troco"
                                    )
                                  ) +
                                  "\n                            "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("moeda-component", {
                            staticClass: "form-control",
                            attrs: {
                              id: "valorTroco",
                              name: "valorTroco",
                              disabled: "",
                            },
                            model: {
                              value: _vm.model.valorTroco,
                              callback: function ($$v) {
                                _vm.$set(_vm.model, "valorTroco", $$v)
                              },
                              expression: "model.valorTroco",
                            },
                          }),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.modelFormaRecebimento.especie == 2 ||
                _vm.modelFormaRecebimento.especie == 3 ||
                _vm.modelFormaRecebimento.especie == 4
                  ? _c("div", { staticClass: "col-sm-3" }, [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: _vm.errors.first("parcelas"),
                              expression: "errors.first('parcelas')",
                            },
                          ],
                          staticClass: "form-group",
                        },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "control-label",
                              attrs: { for: "parcelas" },
                            },
                            [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(
                                    _vm.$t(
                                      "__.Components.parent.venda.financeiro.preFaturamento_vue_html.parcelas"
                                    )
                                  ) +
                                  "\n                            "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.model.parcelas,
                                expression: "model.parcelas",
                              },
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: {
                                  rules: {
                                    maior_que_zero: _vm.maior_que_zero,
                                    required:
                                      _vm.modelFormaRecebimento.especie == 3 ||
                                      _vm.modelFormaRecebimento.especie == 4,
                                  },
                                },
                                expression:
                                  "{\n                                    rules: {\n                                        maior_que_zero,\n                                        required:\n                                            modelFormaRecebimento.especie == 3 ||\n                                            modelFormaRecebimento.especie == 4,\n                                    },\n                                }",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: {
                              autocomplete: "off",
                              type: "number",
                              id: "parcelas",
                              name: "parcelas",
                            },
                            domProps: { value: _vm.model.parcelas },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.model,
                                  "parcelas",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]
                      ),
                    ])
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _c("buttonIncluirComponent", {
                on: { click: _vm.incluirRecebimento },
              }),
              _vm._v(" "),
              _c(
                "div",
                { attrs: { slot: "rows" }, slot: "rows" },
                [
                  _c("grid-component", {
                    attrs: {
                      gridNameId: "VendaPreFaturamentoGrid",
                      data: _vm.vendasPreFaturadas,
                      columns: _vm.gridColumnsPagamento,
                      "show-table-head": false,
                      "show-table-foot": false,
                      "show-edit-item": false,
                      "show-remove-item": true,
                    },
                    on: { "remove-item": _vm.onRemovePagamento },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }