var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "shortcutComponent",
    {
      ref: "shortcutComponent",
      attrs: {
        btOkName: _vm.$t(
          "__.Components.parent.venda.listExpedicao_vue_html.finalizar"
        ),
        showAddNew: false,
        modalClass: "ss-modal",
      },
      on: {
        "selection-item": function ($event) {
          _vm.onSendMotivoRejeicao()
        },
      },
    },
    [
      _c("div", { attrs: { slot: "component" }, slot: "component" }, [
        _c(
          "form",
          [
            _c(
              "label",
              {
                staticClass: "control-label",
                attrs: { for: "motivoRejeicao" },
              },
              [_vm._v("Motivo Rejeição")]
            ),
            _vm._v(" "),
            _c("combo-component", {
              attrs: {
                id: "motivoRejeicao",
                name: "motivoRejeicao",
                data: _vm.motivosOptions,
              },
              model: {
                value: _vm.motivoRejeicaoId,
                callback: function ($$v) {
                  _vm.motivoRejeicaoId = $$v
                },
                expression: "motivoRejeicaoId",
              },
            }),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }