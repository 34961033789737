import checkboxComponent from "@/components/child/form/checkbox.vue";
import { Component, Prop } from "@/decorators";
import DadosAdicionaisFichaTecnicaModel from "@/models/dadosAdicionaisFichaTecnicaModel";
import DadosAdicionaisFichaTecnicaService from "@/services/dadosAdicionaisFichaTecnicaService";

import EditComponentBase from "../editComponentBase";

@Component({
    components: {
        checkboxComponent,
    },
})
export default class DadosAdicionaisFichaTecnicaEditComponent extends EditComponentBase<DadosAdicionaisFichaTecnicaModel> {
    @Prop({ type: Boolean, default: false }) CalledByShortCut: boolean;

    private async beforeMount() {
        await this.setProps(
            new DadosAdicionaisFichaTecnicaService(),
            "dados_adicionais_ficha_tecnica",
            new DadosAdicionaisFichaTecnicaModel(),
            this.CalledByShortCut,
        );
    }
}
