import Vue from "vue";

import Moment from "moment";

import { Component, Prop } from "@/decorators";

@Component
export default class VerComponent extends Vue {
    @Prop({ type: Object, default: () => {} }) data;

    private getDateString(data: Date) {
        return data != null ? Moment(data).format("DD/MM/YYYY") : "";
    }
}
