import ConfiguracaoFranquiaModel from "@/models/configuracaoFranquia/configuracaoFranquiaModel";
import nfetch from "@/utils/events/nfetch";

export default class ConfiguracaoFranquiaService {
    private controllerName = "ConfiguracaoFranquia";

    public listAll(): Promise<Response> {
        return nfetch(`/${this.controllerName}/ListAll`, {
            credentials: "same-origin",
        });
    }

    public list(franquiaId?: number): Promise<Response> {
        return nfetch(`/${this.controllerName}/List?franquiaId=${franquiaId}`, {
            credentials: "same-origin",
        });
    }

    public updateAll(configuracoes: ConfiguracaoFranquiaModel[], franquiaId: number): Promise<Response> {
        return nfetch(`/${this.controllerName}/UpdateAll?franquiaId=${franquiaId}`, {
            method: "post",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json; charset=UTF-8",
            },
            body: JSON.stringify(configuracoes),
        });
    }
}
