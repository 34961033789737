import SNGPCModel from "../../models/sngpc/sngpcModel";
import nfetch from "../../utils/events/nfetch";

import { IService } from "./../base/iService";

export default class SNGPCService extends IService<SNGPCModel> {
    constructor() {
        super("Sngpc");
    }

    public getSNGPCAtual(dataInicio: Date, dataFinal: Date, gerarInventario: boolean): Promise<Response> {
        const dataInicioString = new Date(dataInicio).toISOString();
        const dataFinalString = new Date(dataFinal).toISOString();
        //@ts-ignore
        return nfetch(
            `/${this.controllerName}/GetSNGPCAtual?dataInicio=${dataInicioString}&dataFinal=${dataFinalString}&gerarInventario=${gerarInventario}`,
            {
                credentials: "same-origin",
            },
        )
            .then((response: Response) => {
                if (response.ok) {
                    return response;
                } else {
                    return this.mensagemErro(response, "Get");
                }
            })
            .catch(() => {});
    }

    public getDataXml(id: number): Promise<Response> {
        //@ts-ignore
        return nfetch(`/${this.controllerName}/Download?sngpcId=${id}`, {
            credentials: "same-origin",
        })
            .then((response: Response) => {
                if (response.ok) {
                    return response;
                } else {
                    return this.mensagemErro(response, "Get");
                }
            })
            .catch(() => {});
    }

    public sendDataXml(id: number): Promise<Response> {
        //@ts-ignore
        return nfetch(`/${this.controllerName}/SendDataXml?id=${id}`, {
            credentials: "same-origin",
        })
            .then((response: Response) => {
                if (response.ok) {
                    return response;
                } else {
                    return this.mensagemErro(response, "Get");
                }
            })
            .catch(() => {});
    }

    public getProximoPeriodo(gerarInventario: boolean): Promise<Response> {
        return nfetch(`/${this.controllerName}/GetProximoPeriodo?&gerarInventario=${gerarInventario}`, {
            credentials: "same-origin",
        });
    }
}
