var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "qrcode-container" },
    [
      _c("div", { staticClass: "col-sm-12" }, [
        _c(
          "button",
          {
            staticClass: "qrcode-button",
            attrs: { type: "button" },
            on: { click: _vm.onOpen },
          },
          [
            _c("i", {
              staticClass: "fa fa-qrcode",
              attrs: { "aria-hidden": "true" },
            }),
          ]
        ),
      ]),
      _vm._v(" "),
      _c(
        "shortcutComponent",
        {
          ref: "shortcutComponent",
          attrs: {
            modalClass: "ss-modal",
            showAddNew: false,
            showBtnOk: false,
          },
          on: { "hide-click": _vm.onClose },
        },
        [
          _c("div", { attrs: { slot: "component" }, slot: "component" }, [
            _c("div", { staticClass: "col-sm-12 video-container" }, [
              _c("video", {
                ref: "video",
                attrs: { width: "70%", height: "100%", autoplay: "" },
              }),
              _vm._v(" "),
              _c("canvas", { attrs: { id: "canvas" } }),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }