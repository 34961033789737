export default class ValidationErrorModel {
    field: string = null;
    errorType: ValidationErrors = null;
    references: Array<string> = [];
}

export enum ValidationErrors {
    Unexpected = 0,
    NotExists = 1,
    AlreadyExists = 2,
    Required = 3,
    StringLength = 4,
    Invalid = 5,
    Insuficient = 6,
    WarningBreaking = 97,
    Warning = 98,
    Error = 99,
}
