import Vue from "vue";
import VueResource from "vue-resource";
import { mapState, mapMutations, mapGetters } from "vuex";

import Moment from "moment";

import dropdownComponent from "@/components/child/dropdown/dropdown.vue";
import notificationComponent from "@/components/parent/notification/list.vue";
import { Component, Watch } from "@/decorators";
import { i18n } from "@/i18n";
import UsuarioModel from "@/models/usuarioModel";
import FastActionService from "@/services/fastActionService";
import UsuarioService from "@/services/usuarioService";
import { AppState, Mutations, SessionActions, Getters } from "@/store/store";
import { AlertColor } from "@/utils/common/alert";
import Bus from "@/utils/events/bus";

import ListNotificationComponent from "../notification/list";

Vue.use(VueResource);

import "./topmenu.scss";

@Component({
    components: {
        dropdownComponent,
        notificationComponent,
    },
    computed: {
        ...mapState({
            usuarioLogado: (state: AppState) => state.session.usuarioLogado,
            countUnreadedNotification: (state: AppState) => state.notification.countUnreadedNotification,
            totalUnreadCount: (state: AppState) => state.atendimentoContext.totalUnreadCount,
        }),
        ...mapGetters(["HAS_PERMISSAO_PERFIL"] as Getters),
    },
    methods: mapMutations(["LOAD_CONFIGURACOES_FRANQUIA"] as Mutations),
})
export default class TopMenuComponent extends Vue {
    // State computed props
    usuarioLogado: UsuarioModel;
    usuarioService = new UsuarioService();
    countUnreadedNotification: number;
    totalUnreadCount: number;
    LOAD_CONFIGURACOES_FRANQUIA: () => void;
    HAS_PERMISSAO_PERFIL: (isRede: boolean, isFranqueador: boolean, isSuporte: boolean) => Promise<boolean>;

    private notificationComponent: ListNotificationComponent = null;

    menuExibicao = false;

    dia: string = Moment(Date()).format("DD/MM");
    semana: string = Moment(Date()).format("dddd");
    sessaoCaixaId = 0;

    temperatura = "";
    temp = 0;
    weatherID = 0;
    icon = "";
    cidade = "";
    temTempo = false;
    notificacoes = 0;
    notificactionChanged = false;
    isAdmin = false;

    nomeUsuario = "";
    nomeFranquia = "";
    franquiaCidade = "";
    online = true;

    toggleNavMenu() {
        Bus.$emit("toggle-nav-menu");
    }

    private aumentar() {
        //@ts-ignore
        if (document.body.style.zoom != "100%") {
            //@ts-ignore
            document.body.style.zoom = "100%";
        }
    }

    private diminuir() {
        //@ts-ignore
        if (document.body.style.zoom == "100%") {
            //@ts-ignore
            document.body.style.zoom = "90%";
            //@ts-ignore
        } else if (document.body.style.zoom == "90%") {
            //@ts-ignore
            document.body.style.zoom = "80%";
        } else {
            //@ts-ignore
            document.body.style.zoom = "75%";
        }
    }

    private onAlterarSenha() {
        this.$router.push("/alterar-senha/");
    }

    private onVerNotasDaVersao() {
        window.open("https://vettech.notion.site/vettech/PharmUP-Notas-da-Vers-o-8fbf742da28b4fde990282ef0ac69cf4");
    }

    private onVerStatusDoSistema() {
        this.$router.push("/status-sistema/");
    }

    private onUpdateLogLevel() {
        this.$router.push("/log-level/");
    }

    private async onEmailNotasDaVersao() {
        const response = await this.$showAlert({
            title: "E-mail da Versão",
            icon: "success",
            html: `<label>Informe aqui notas da versão publicada.</label>`,
            allowOutsideClick: false,
            showCancelButton: true,
            showConfirmButton: true,
            cancelButtonText: "Fechar",
            confirmButtonText: "Enviar",
            confirmButtonColor: AlertColor.GREEN,
            input: "textarea",
        });

        if (response.isConfirmed) {
            const sucesso = await this.usuarioService
                .enviarEmailNotasDaVersao(response.value)
                .withLoading()
                .resolveWithoutJSON();
            if (sucesso) {
                await this.$showSuccess(
                    "E-mail de versão enviado!",
                    "Um e-mail com notas da versão publicada foi enviado aos usuários do Phamup.",
                );
            }
        }
    }

    private async loadInfosFinanc() {
        try {
            const data = await new FastActionService()
                .getInfosFinanceiro()
                .resolveWithJSON<{ valorPagar?: number; valorReceber?: number; sessaoCaixaId?: number }>();
            this.sessaoCaixaId = data.sessaoCaixaId;
        } catch {}
    }

    //busca clima por lat e long
    private async loadtempo() {
        const reqURL = `https://api.openweathermap.org/data/2.5/weather?q=${this.franquiaCidade},br&APPID=69d3cf86b46f19cf3e049339355533aa`;

        this.$http.get(reqURL).then(
            function (response) {
                this.weatherID = response.data.weather[0].id;
                this.temp = (response.data.main.temp - 273.15).toFixed(0);
                this.cidade = response.data.name;

                if (this.weatherID >= 200 && this.weatherID <= 232) {
                    this.icon = "thunderstorm";
                } else if (this.weatherID >= 300 && this.weatherID <= 321) {
                    this.icon = "shower-rain";
                } else if (this.weatherID >= 500 && this.weatherID <= 531) {
                    this.icon = "rain";
                } else if (this.weatherID >= 600 && this.weatherID <= 622) {
                    this.icon = "snow";
                } else if (this.weatherID >= 701 && this.weatherID <= 781) {
                    this.icon = "mist";
                } else if (this.weatherID == 800) {
                    this.icon = "clear-sky";
                } else if (this.weatherID == 801) {
                    this.icon = "few-clouds";
                } else if (this.weatherID == 802) {
                    this.icon = "scattered-clouds";
                } else if (this.weatherID == 803) {
                    this.icon = "broken-clouds";
                } else if (this.weatherID == 804) {
                    this.icon = "broken-clouds";
                }

                this.temTempo = true;
            },
            function () {},
        );
    }

    @Watch("usuarioLogado")
    private onChangeUsuarioNome() {
        this.nomeUsuario = this.usuarioLogado.nome;

        this.nomeUsuario = this.usuarioLogado.nome.split(" ")[0];
        if (this.nomeUsuario.includes("[SUPORTE]")) {
            this.nomeUsuario += " " + this.usuarioLogado.nome.split(" ")[1];
        }

        this.nomeFranquia = this.usuarioLogado.franquiaNome;
        this.franquiaCidade = this.usuarioLogado.franquiaCidade;
        if (this.franquiaCidade) {
            this.loadtempo();
        }
    }

    private netChanged(on: boolean) {
        if (on) {
            this.$notifySuccess("A sua Conexão foi restabelecida com sucesso!", "Conexão da Internet");
        } else {
            this.$notifySuccess("Aparentemente sua Conexão com internet foi interrompida!", "Conexão da Internet");
        }
    }

    private onChangeIdioma(idioma: string) {
        i18n.locale = idioma;
    }

    private async onSair() {
        await this.$store.dispatch(SessionActions.LOGOUT);

        this.$router.push("/login");
    }

    private onShowNotification() {
        this.notificationComponent.show();
    }

    private async mounted() {
        //@ts-ignore
        document.body.style.zoom = "90%";

        Bus.$on("nav-menu-change", menuExibicao => {
            this.menuExibicao = menuExibicao;
        });

        Bus.$on("sessao-caixa-status", status => {
            status ? (this.sessaoCaixaId = 1) : (this.sessaoCaixaId = 0);
        });

        await this.$store.dispatch(SessionActions.LOAD_USUARIO_LOGADO);

        this.isAdmin = await this.HAS_PERMISSAO_PERFIL(false, false, false);

        this.notificationComponent = this.$refs.notificationComponent as ListNotificationComponent;

        Promise.all([this.LOAD_CONFIGURACOES_FRANQUIA(), this.loadInfosFinanc()])
            .then(() => {})
            .catch(() => {});
    }
}
