import Vue from "vue";
import { VueTabs, VTab } from "vue-nav-tabs";
import { mapState, mapMutations, mapGetters } from "vuex";

Vue.component("vue-tabs", VueTabs);
Vue.component("v-tab", VTab);
import Moment from "moment";

import gridComponent from "@/components/child/grid/grid.vue";
import { GridColumn, GridColumnType } from "@/components/child/grid/gridColumn";
import ImpressaoComponent from "@/components/child/impressao/impressaoComponent";
import impressaoComponent from "@/components/child/impressao/impressaoComponent.vue";
import { ModalButtonAction } from "@/components/child/modal/modalButtonAction";
import NfeEditComponent from "@/components/parent/nfe/edit";
import nfeComponent from "@/components/parent/nfe/edit.vue";
import ShortcutComponent from "@/components/parent/shortcut/shortcut";
import shortcutComponent from "@/components/parent/shortcut/shortcut.vue";
import FinanceiroComponent from "@/components/parent/venda/financeiro/financeiro";
import financeiroComponent from "@/components/parent/venda/financeiro/financeiro.vue";
import PagamentoComponent from "@/components/parent/venda/pagamento";
import pagamentoComponent from "@/components/parent/venda/pagamento.vue";
import { Component } from "@/decorators";
import ConfiguracaoFranquiaModel from "@/models/configuracaoFranquia/configuracaoFranquiaModel";
import Configuracoes from "@/models/enum/configuracao/configuracoes";
import SituacoesVenda from "@/models/enum/situacoesVenda";
import StatusEnvioNF from "@/models/enum/statusEnvioNF";
import TipoNotaFiscal from "@/models/enum/tipoNotaFiscal";
import ContaReceberModel from "@/models/financeiro/contaReceberModel";
import ContaReceberRecebimentoModel from "@/models/financeiro/contaReceberRecebimentoModel";
import UsuarioModel from "@/models/usuarioModel";
import ValidationErrorModel from "@/models/validationErrorModel";
import VendaModel from "@/models/vendaModel";
import NotaFiscalEletronicaEmissaoService from "@/services/emissaoNota/notaFiscalEletronicaEmissaoService";
import NotaFiscalServicoService from "@/services/emissaoNota/notaFiscalServicoEmissaoService";
import MovimentacaoCaixaService from "@/services/financeiro/movimentacaoCaixaService";
import NotaFiscalSaidaService from "@/services/notaFiscalSaidaService";
import ReceitaArquivoService from "@/services/receitaArquivoService";
import VendaService from "@/services/vendaService";
import { FastActionLastPack, FastActionList, FastActionPack } from "@/store/fastActionStore";
import { AppState, Getters, Mutations } from "@/store/store";
import arithmeticHelper from "@/utils/common/arithmeticHelper";
import { editDateWithTime, editDate } from "@/utils/common/date";
import Delay from "@/utils/common/delay";
import Bus from "@/utils/events/bus";

import { getFirstName } from "../fastactionUtils";

import VendaListComponent from "./list";
import vendaListComponent from "./list.vue";

import "./../fastactionsgrid.scss";

@Component({
    components: {
        shortcutComponent,
        gridComponent,
        pagamentoComponent,
        financeiroComponent,
        vendaListComponent,
        nfeComponent,
        impressaoComponent,
    },
    computed: {
        ...mapState({
            usuarioLogado: (state: AppState) => state.session.usuarioLogado,
            fastActionLists: (state: AppState) => state.fastAction.fastActionLists,
            fastActionPack: (state: AppState) => state.fastAction.fastActionPack,
        }),
        ...mapGetters([
            "GET_CONFIG_FRANQUIA",
            "VALIDAR_PERMISSAO_USUARIO",
            "GET_IS_FRACIONAMENTO",
            "GET_IS_ESTEREIS",
        ] as Getters),
    },
    methods: {
        ...mapMutations(["SET_LISTSPACK", "SET_LASTCOUNTPACK"] as Mutations),
        getFirstName: getFirstName,
        editDate: editDate,
        editDateWithTime: editDateWithTime,
    },
})
export default class FastActionVendasPanelComponent extends Vue {
    // State computed props
    usuarioLogado: UsuarioModel;
    fastActionLists: FastActionList;
    fastActionPack: FastActionPack;
    SET_LISTSPACK: (data: FastActionList) => void;
    SET_LASTCOUNTPACK: (data: FastActionLastPack) => void;
    GET_CONFIG_FRANQUIA: (configuracao: Configuracoes) => ConfiguracaoFranquiaModel;
    VALIDAR_PERMISSAO_USUARIO: (telaDescricao: string, acaoController: string) => boolean;
    GET_IS_FRACIONAMENTO: () => Promise<boolean>;
    GET_IS_ESTEREIS: () => Promise<boolean>;

    totalVendidoHoje = 0.0;
    totalAprovadosHoje = 0;

    private permiteParcelamentoVenda = false;
    private emiteNotaServico = false;
    private isFracionamento = false;
    private isEstereis = false;

    private vendas: Array<VendaModel> = [];
    private vendasTotal: Array<VendaModel> = [];

    private impressaoComponent: ImpressaoComponent = null;
    private financeiroComponent: FinanceiroComponent = null;
    private nfeComponent: NfeEditComponent = null;
    private pagamentoComponent: PagamentoComponent = null;
    private shortcutComponent: ShortcutComponent = null;
    private shortcutComponentEmissao: ShortcutComponent = null;
    private shortcutComponentFinanceiro: ShortcutComponent = null;
    private shortCutList: ShortcutComponent = null;
    private vendaListComponent: VendaListComponent = null;

    private showRevisao = false;

    private service = new VendaService();
    private movimentacaoCaixaService = new MovimentacaoCaixaService();
    private notaFiscalServicoService = new NotaFiscalServicoService();
    private notaFiscalEletronicaEmissaoService = new NotaFiscalEletronicaEmissaoService();
    private receitaArquivoService = new ReceitaArquivoService();

    private model: VendaModel = null;

    private saldoPagar = 0;

    private alterouFinanceiro = false;
    private loadListComponent = false;
    private loadPagamentoComponent = false;
    private loadNfeComponent = false;

    private categoriaLista = 0;

    get gridColumnsPagamento(): Array<GridColumn> {
        return [
            new GridColumn("dataMovimento", this.$t("__.ts.dtReceb"), GridColumnType.Date),
            new GridColumn("formaPagamentoDescricao", this.$t("__.ts.formPgto"), GridColumnType.String),
            new GridColumn("desconto", this.$t("__.ts.descont"), GridColumnType.Money),
            new GridColumn("juros", this.$t("__.ts.juros"), GridColumnType.Money),
            new GridColumn("valor", this.$t("__.ts.vlr"), GridColumnType.Money),
        ];
    }

    caixaSessao: string = null;
    idSessaoCaixa: number = null;
    tabFinanceiro = "";
    itens = "";

    saldoCaixaInicial = 0;
    saldoCaixaAtual = 0;

    flagReload = 0;

    extraButtonActionNfe: Array<ModalButtonAction> = [
        new ModalButtonAction("resumo", "Resumo Pré-Emissão", true, "l", " btn-info"),
    ];

    private trocarCategoria(tipoMovimento: number) {
        this.categoriaLista = tipoMovimento;
        this.filtrarVendas();
        this.flagReload++;
    }

    private filtrarVendas() {
        this.loadVendas(true);
    }

    get listaVendas() {
        return this.vendas.slice(0, 10);
    }

    private getDaysDiff(dataValidade: Date) {
        const timeDiff = Moment(dataValidade).diff(new Date());
        return Math.ceil(timeDiff / (1000 * 3600 * 24));
    }

    private isFaturado(venda: VendaModel): boolean {
        return (
            venda.situacao == SituacoesVenda.Faturado ||
            venda.situacao == SituacoesVenda.ExpedicaoNota ||
            venda.situacao == SituacoesVenda.Entregue ||
            venda.situacao == SituacoesVenda.EmFaturamento
        );
    }

    private getClass(venda: VendaModel) {
        if (venda.situacao == SituacoesVenda.Aprovado) {
            return "ExcRed";
        } else if (this.isFaturado(venda)) {
            const possuiServicos =
                venda.itens.filter(
                    p =>
                        (p.manipulacaoOrdemId != null && p.manipulacaoOrdemId > 0) ||
                        (p.manipulacaoOrdem != null && p.produtoId == null),
                ).length > 0;
            const possuiProdutos = venda.itens.filter(p => p.produtoId != null && p.produtoId > 0).length > 0;
            let possuiNfs = false;
            let possuiNFC = false;
            if (possuiServicos)
                possuiNfs =
                    venda.notasFiscaisSaida.filter(
                        p => p.tipo == TipoNotaFiscal.Servico && p.statusEnvio == StatusEnvioNF.Enviado,
                    ).length > 0;

            if (possuiProdutos)
                possuiNFC =
                    venda.notasFiscaisSaida.filter(
                        p => p.tipo == TipoNotaFiscal.CupomFiscal && p.statusEnvio == StatusEnvioNF.Enviado,
                    ).length > 0;

            if (possuiServicos && !possuiNfs) {
                return "";
            }
            if (possuiProdutos && !possuiNFC) {
                return "";
            }

            return "ExcGreen";
        }
    }

    private getTitle(venda: VendaModel) {
        if (venda.situacao == SituacoesVenda.Aprovado) {
            return this.$t("__.ts.vendaSemPgtosRealiz") as string;
        } else if (this.isFaturado(venda)) {
            const possuiServicos =
                venda.itens.filter(
                    p =>
                        (p.manipulacaoOrdemId != null && p.manipulacaoOrdemId > 0) ||
                        (p.manipulacaoOrdem != null && p.produtoId == null),
                ).length > 0;
            const possuiProdutos = venda.itens.filter(p => p.produtoId != null && p.produtoId > 0).length > 0;
            let possuiNfs = false;
            let possuiNFC = false;
            if (possuiServicos)
                possuiNfs =
                    venda.notasFiscaisSaida.filter(
                        p => p.tipo == TipoNotaFiscal.Servico && p.statusEnvio == StatusEnvioNF.Enviado,
                    ).length > 0;

            if (possuiProdutos)
                possuiNFC =
                    venda.notasFiscaisSaida.filter(
                        p => p.tipo == TipoNotaFiscal.CupomFiscal && p.statusEnvio == StatusEnvioNF.Enviado,
                    ).length > 0;

            let msg = "";

            if (possuiServicos && !possuiNfs) {
                msg = this.$t("__.ts.envioNFS") as string;
            }
            if (possuiProdutos && !possuiNFC)
                msg =
                    msg == ""
                        ? (this.$t("__.ts.envioCFPend") as string)
                        : ((msg + this.$t("__.ts.eEnvioCFPends")) as string);
            else if (msg != "") {
                msg = (msg + this.$t("__.ts.pend")) as string;
            } else if (msg == "") {
                return this.$t("__.ts.nfEnvAgRetornoPref") as string;
            } else return msg;
        }

        return "";
    }

    private async onVerMais() {
        this.model = null;

        this.loadPagamentoComponent = false;
        this.loadListComponent = true;

        while (!this.$refs.shortCutList) await Delay(5);
        this.shortCutList = this.$refs.shortCutList as ShortcutComponent;

        while (!this.$refs.vendaListComponent) await Delay(5);
        this.vendaListComponent = this.$refs.vendaListComponent as VendaListComponent;

        this.shortCutList.title = "vendas";
        this.shortCutList.show();
    }

    private onConfirmList() {
        this.shortCutList.hide();
    }

    private onSearch(venda: VendaModel) {
        this.$router.push("/venda-edicao/" + venda.id);
    }

    public async onExibirReceita(venda: VendaModel) {
        await this.receitaArquivoService.showFiles(venda.receitas);
    }

    private onCancelar(venda: VendaModel) {
        this.$router.push("/venda-cancelamento/" + venda.id);
    }

    private iniciarContaReceber(model: VendaModel) {
        if (!model.contaReceber) {
            model.contaReceber = new ContaReceberModel();
            model.contaReceber.recorrencias = 0;
            model.contaReceber.frequencia = null;
            model.contaReceber.descricao = "Venda";
            model.contaReceber.contaGerencialId = 6;
            model.contaReceber.saldo = this.model.valorFinal;
            model.contaReceber.valorTotal = this.model.valorFinal;
            model.contaReceber.dataVencimento = new Date();
            model.contaReceber.dataLancamento = new Date();
            model.contaReceber.contabilizar = true;
            model.contaReceber.clienteId = model.clienteId;
        }
    }

    private async save() {
        try {
            const response = await this.service.update(this.model).withLoading();
            if (response) {
                const erros = await (response.json() as Promise<ValidationErrorModel[]>);
                let nCod = Number(response.headers.get("Codigo"));
                if (this.model.codigo > 0 && (nCod == null || nCod == 0)) {
                    nCod = this.model.codigo;
                }
                if (response.ok) {
                    const strName =
                        this.model.situacao == SituacoesVenda.Orcamento
                            ? this.$t("__.ts.orcamento")
                            : this.$t("__.ts.venda");
                    const codStr = (this.$t("__.ts.codzin") as string) + nCod;
                    const ocError = erros.length > 0 ? this.$t("__.ts.masOcorrAlert") : ".";
                    const text = (this.$t("__.ts.salvoSucess") as string) + ocError;
                    if (erros.length > 0) {
                        await this.$showWarning(strName + codStr, text);

                        return erros;
                    }

                    await this.$showSuccess(strName + codStr, text);
                } else {
                    return erros;
                }
            }
        } catch {}
    }

    private async loadVendas(forceReload = false) {
        if (this.fastActionLists.vendasAFaturar.length > 0 && !forceReload) {
            this.vendasTotal = this.vendas = this.fastActionLists.vendasAFaturar;
        } else {
            try {
                const vendas = await this.service
                    .listVendaHome(this.categoriaLista)
                    .withLoading()
                    .resolveWithJSON<VendaModel[]>();
                this.vendasTotal = this.vendas = vendas;
                this.SET_LISTSPACK({ vendasAFaturar: vendas });
            } catch {}
        }
    }

    private async load(vendaId = 0) {
        this.model = new VendaModel();

        if (vendaId > 0) {
            try {
                const data = await this.service.get(vendaId).withLoading().resolveWithJSON<VendaModel>();
                this.model.updateFrom(data);
            } catch {}
        }
    }

    private async onFaturar(venda: VendaModel) {
        this.tabFinanceiro = "";
        this.loadPagamentoComponent = true;
        this.alterouFinanceiro = false;

        await this.load(venda.id);

        if (this.model.contaReceber == null) {
            this.iniciarContaReceber(this.model);
        }

        await this.calcularSaldoPagar();

        if (this.permiteParcelamentoVenda) {
            while (!this.$refs.shortcutComponentFinanceiro) await Delay(5);
            this.shortcutComponentFinanceiro = this.$refs.shortcutComponentFinanceiro as ShortcutComponent;

            while (!this.$refs.financeiroComponent) await Delay(5);
            this.financeiroComponent = this.$refs.financeiroComponent as FinanceiroComponent;

            this.financeiroComponent.setModel(this.model.contaReceber);
            this.financeiroComponent.load(this.saldoPagar, 0, 0);

            this.shortcutComponentFinanceiro.title = "Financeiro";
            this.shortcutComponentFinanceiro.show();
            return;
        }

        this.loadTabFinanceiro(true);

        while (!this.$refs.pagamentoComponent) await Delay(5);
        this.pagamentoComponent = this.$refs.pagamentoComponent as PagamentoComponent;

        // sempre vamos user o valor final da venda e não será possível aplicar descontos ou acrescimos pela tela de pagamento
        this.pagamentoComponent.load(this.saldoPagar);

        this.shortcutComponent = this.$refs.shortcutComponent as ShortcutComponent;
        this.shortcutComponent.title = this.$t("__.ts.financCntsReceber") as string;
        this.shortcutComponent.show();
    }

    private async calcularSaldoPagar(atualizarTab = false) {
        this.saldoPagar = 0;
        if (this.model != null) {
            if (this.model.contaReceber) {
                const total = this.model.contaReceber.movimentacoes.reduce(
                    (acc, p) => acc + Number(p.valor + p.desconto - p.juros),
                    0,
                );

                this.saldoPagar = arithmeticHelper.round(this.model.valorFinal) - arithmeticHelper.round(total);

                if (total == 0) {
                    this.saldoPagar = arithmeticHelper.round(this.model.valorFinal);
                }

                if (this.saldoPagar < 0) {
                    this.saldoPagar = 0;
                }

                if (this.pagamentoComponent) {
                    // sempre vamos user o valor final da venda e não será possível aplicar descontos ou acrescimos pela tela de pagamento
                    this.pagamentoComponent.load(this.saldoPagar);
                }

                this.loadTabFinanceiro(atualizarTab);
            }
        }
    }

    private loadTabFinanceiro(atualizarTab = false) {
        if (this.saldoPagar > 0 && this.tabFinanceiro != this.$t("__.ts.novoPgto")) {
            this.tabFinanceiro = this.$t("__.ts.novoPgto") as string;
        } else if (this.tabFinanceiro == this.$t("__.ts.novoPgto") && atualizarTab) {
            this.tabFinanceiro = this.$t("__.ts.pgtos") as string;
        }
    }

    private async onConfirmFinanceiro() {
        if (this.alterouFinanceiro || this.permiteParcelamentoVenda) {
            this.calcularSaldoPagar();

            //refaz o valor total da venda
            this.model.valorFinal = arithmeticHelper.round(
                this.model.valorProdutos + this.model.taxaEntrega + this.model.acrescimo - this.model.desconto,
            );

            //atualiza o valor final da tela
            const vendaPosicionada = this.vendas.filter(p => p.id == this.model.id);
            if (vendaPosicionada.length > 0) {
                vendaPosicionada[0].valorFinal = this.model.valorFinal;
                vendaPosicionada[0].acrescimo = this.model.acrescimo;
                vendaPosicionada[0].desconto = this.model.desconto;
            }

            if (this.model.itens[0]?.fracionamentoId > 0) {
                this.model.situacao = SituacoesVenda.ExpedicaoNota;
            }

            try {
                const sucesso = await this.service.updateFaturamento(this.model).withLoading().resolveWithoutJSON();
                if (sucesso) {
                    await this.$showSuccess(this.$t("__.ts.alt"), this.$t("__.ts.salvoSucess"));

                    if (this.permiteParcelamentoVenda) {
                        this.model.contaReceber = this.financeiroComponent.model;
                        this.calcularSaldoPagar();
                    }

                    this.shortcutComponentFinanceiro.hide();
                    this.shortcutComponent.hide();

                    await this.load(this.model.id);
                    if (this.shortCutList != null) {
                        if (this.shortCutList.isShowing() && this.vendaListComponent != null) {
                            this.vendaListComponent.load();
                        }
                    }
                    this.updateGrid();

                    if (this.VALIDAR_PERMISSAO_USUARIO("vendas", "FaturarVenda")) {
                        if (this.model.notasFiscaisSaida.length < 1) {
                            const response = await this.$showQuestion(
                                "Emitir NF",
                                "Deseja emitir a nota fiscal automaticamente?",
                            );
                            if (response) {
                                this.emitirNF(this.model);
                            }
                        }
                    }
                }
            } catch {}
        }

        this.tabFinanceiro = "";
    }

    private onAdicionarPagamento(model: ContaReceberRecebimentoModel) {
        if (this.model.contaReceber) {
            this.model.contaReceber.movimentacoes.push(model);
            this.calcularSaldoPagar(true);
            this.alterouFinanceiro = true;
        }
    }

    private onRemovePagamento(model: ContaReceberRecebimentoModel) {
        if (this.model.contaReceber) {
            this.model.contaReceber.movimentacoes = this.model.contaReceber.movimentacoes.filter(p => p != model);
            this.calcularSaldoPagar();
            this.alterouFinanceiro = true;
        }
    }

    private async getInfos() {
        try {
            const data = await this.service
                .getInfosHome(SituacoesVenda.Aprovado)
                .resolveWithJSON<{ valorHoje: number; quantidadeCriadoHoje: number }>();
            this.totalVendidoHoje = data.valorHoje;
            this.totalAprovadosHoje = data.quantidadeCriadoHoje;
        } catch {}
    }

    private async onImprimir(venda: VendaModel) {
        this.model = null;
        await this.load(venda.id);

        await this.impressaoComponent
            .show({
                modelId: venda.id,
                tipoImpressao: "Venda",
                impressaoTodosModelo: true,
            })
            .withLoading();
    }

    private async emitirNF(venda: VendaModel) {
        await this.load(venda.id);

        if (this.isFracionamento || !this.emiteNotaServico) {
            this.emitirNotaNFe();
        } else {
            for (let index = 0; index < 2; index++) {
                const temNotaParaEmitir =
                    this.model.itens.filter(
                        i =>
                            i.tipoNF == index &&
                            NotaFiscalSaidaService.NFinfo[index].isValidItem(i, this.emiteNotaServico),
                    ).length > 0;
                if (temNotaParaEmitir) {
                    if (index == 0) {
                        this.emitirNotaServico();
                    } else {
                        this.emitirNotaFiscal(index);
                    }
                }
            }
        }
    }

    private async emitirNotaNFe() {
        this.shortcutComponentEmissao.title = "Emissão de Nota Fiscal";
        this.shortcutComponentEmissao.show();

        this.loadNfeComponent = true;

        while (!this.$refs.nfeComponent) await Delay(5);
        this.nfeComponent = this.$refs.nfeComponent as NfeEditComponent;
        await this.nfeComponent.loadModelByVenda(this.model).withLoading();
    }

    private async emitirNotaServico() {
        try {
            const statusRetorno = await this.notaFiscalServicoService
                .emitirByVenda(this.model)
                .withLoading()
                .resolveWithJSON<StatusEnvioNF>();

            await this.notaFiscalServicoService.showMessageByStatus(statusRetorno);
        } catch {}
    }

    private async emitirNotaFiscal(tipoNota: TipoNotaFiscal) {
        try {
            const sucesso = await this.notaFiscalEletronicaEmissaoService
                .emitirAutomaticoByVenda(tipoNota, this.model)
                .withLoading()
                .resolveWithoutJSON();

            if (sucesso) {
                const notaString = tipoNota == TipoNotaFiscal.CupomFiscal ? "Cupom" : "Nota";
                await this.$showSuccess("Enviado!", `${notaString} fiscal foi enviada com sucesso.`);
            }
        } catch {}
    }

    private async onEmitirNotaFracionamento() {
        if (await this.nfeComponent.emitir().withLoading()) {
            this.shortcutComponentEmissao.hide();
            this.updateGrid();
        }
    }

    private async loadGetIdSessaoCaixa() {
        this.idSessaoCaixa = 0;
        try {
            this.idSessaoCaixa = await this.movimentacaoCaixaService.getIdCaixaSessaoAberta().resolveWithJSON<number>();
        } catch {
            this.idSessaoCaixa = 0;
        }
    }

    private async loadDescricaoCaixa() {
        if (this.caixaSessao != null && this.caixaSessao != "") return;

        try {
            this.caixaSessao = await this.movimentacaoCaixaService.getDescricaoCaixaSessaoAberta().resolveWithText();
        } catch {
            this.caixaSessao = null;
        }
    }

    private updateGrid() {
        const vendas = this.vendasTotal.filter(p => p.id == this.model.id);
        if (vendas.length > 0) {
            vendas[0].situacao = this.model.situacao;
            this.$forceUpdate();
        }
        this.filtrarVendas();
    }

    private async onExtraButtonActionClickNfe(name: string) {
        if (name == "resumo") {
            this.nfeComponent.resumo();
        }
    }

    private async replicar(venda: VendaModel) {
        try {
            const data = await this.service.replicarOrcamento(venda.id).withLoading().resolveWithJSON<VendaModel>();
            if (data.id) {
                this.$router.push("/venda-edicao/" + data.id + "/true");
            }
        } catch {}
    }

    private validarVisibilidadeBotao(acao: string) {
        return this.VALIDAR_PERMISSAO_USUARIO("vendas", acao);
    }

    private mounted() {
        this.loadGetIdSessaoCaixa();
        Bus.$on("reload-vendas-home", () => {
            this.loadVendas(true);
        });

        this.impressaoComponent = this.$refs.impressaoComponent as ImpressaoComponent;
        this.nfeComponent = this.$refs.nfeComponent as NfeEditComponent;
        this.shortcutComponent = this.$refs.shortcutComponent as ShortcutComponent;
        this.shortcutComponentEmissao = this.$refs.shortcutComponentEmissao as ShortcutComponent;
        this.shortcutComponentFinanceiro = this.$refs.shortcutComponentFinanceiro as ShortcutComponent;

        const emiteNotaServicoConfig = this.GET_CONFIG_FRANQUIA(Configuracoes.EmiteNotaDeServico);
        if (emiteNotaServicoConfig) {
            this.emiteNotaServico = emiteNotaServicoConfig.verdadeiro;
        }

        const permiteParcelamentoVenda = this.GET_CONFIG_FRANQUIA(Configuracoes.PermiteParcalamentoVenda);
        if (permiteParcelamentoVenda) {
            this.permiteParcelamentoVenda = permiteParcelamentoVenda.verdadeiro;
        }

        Promise.all([this.getInfos(), this.loadVendas()])
            .then(async () => {
                this.SET_LASTCOUNTPACK({
                    vendasAFaturar: Number(this.fastActionPack.countPack.vendasAFaturar),
                });
                this.isFracionamento = await this.GET_IS_FRACIONAMENTO();
                this.isEstereis = await this.GET_IS_ESTEREIS();
            })
            .catch(() => {});
    }
}
