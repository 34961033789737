import BaseModel from "./base/baseModel";
import FormaFarmaceuticaModel from "./formaFarmaceuticaModel";

export default class moldeModel extends BaseModel {
    id?: number = null;
    descricao: string = null;
    ativo = true;
    observacao: string = null;
    produtoQSPId?: number = null;
    unidadeMedidaId?: number = null;
    volumeInterno: number = null;
    volumeTotal: number = null;
    volumeMaximoPrincipioAtivo: number = null;
    formasFarmaceuticas: Array<FormaFarmaceuticaModel> = [];
    formaFarmaceuticaId?: number = null;
    volumeMaximoPrincipioAtivoFitoterapico?: number = null;
}

export interface MoldeListParameters {
    formaFarmaceuticaId?: number;
}
