import BaseModel from "../../../../../base/baseModel";

export default class TotalIcmsNfeModel extends BaseModel {
    valorBaseCalculoST?: number = 0;
    valorOutro?: number = 0;
    valorCOFINS?: number = 0;
    valorPIS?: number = 0;
    valorIPI?: number = 0;
    valorII?: number = 0;
    valorDesc?: number = 0;
    valorSeg?: number = 0;
    valorFrete?: number = 0;
    valorProd?: number = 0;
    valorST?: number = 0;
    valorTotalTributado?: number = 0;
    valorICMSUFRemet?: number = 0;
    valorICMSUFDest?: number = 0;
    valorFCPUFDest?: number = 0;
    valorICMSDeson?: number = 0;
    valorICMS?: number = 0;
    valorBC?: number = 0;
    valorTotalNotaFiscal = 0;
}
