import Vue from "vue";

import checkboxComponent from "@/components/child/form/checkbox.vue";
import comboComponent from "@/components/child/form/combo.vue";
import dataTooltipComponent from "@/components/child/form/datatooltip.vue";
import dateTimePickerComponent from "@/components/child/form/datetimepicker.vue";
import fieldsetComponent from "@/components/child/form/fieldset.vue";
import moedaComponent from "@/components/child/form/moeda.vue";
import gridComponent from "@/components/child/grid/grid.vue";
import { Component, Prop, Watch } from "@/decorators";
import TypeTableLines from "@/models/enum/typeTableLines";
import ModeloImpressaoCampos from "@/models/modeloImpressao/modeloImpressaoCamposModel";
import ModeloImpressaoSessao, { TypeElement } from "@/models/modeloImpressao/modeloImpressaoSessaoModel";
import ModeloimpressaoSessaoService from "@/services/modeloImpressaoSessaoService";
import { copyObject } from "@/utils/common/copyObject";

import ShortcutComponent from "../shortcut/shortcut";
import shortcutComponent from "../shortcut/shortcut.vue";

import ModeloCampoEditComponent from "./campo";
import modeloCampoEditComponent from "./campo.vue";
import {
    clearContainers,
    changeContainerHeigth,
    addListItemContainer,
    addItemContainer,
    atualizaItensContainer,
} from "./containerControlEdit";

import "../crud/crud.scss";
import "./edit.scss";
import "./editOptionst.scss";

@Component({
    components: {
        comboComponent,
        fieldsetComponent,
        dateTimePickerComponent,
        dataTooltipComponent,
        moedaComponent,
        gridComponent,
        shortcutComponent,
        checkboxComponent,
        modeloCampoEditComponent,
    },
})
export default class PrintModelditComponent extends Vue {
    constructor() {
        super();
    }

    service = new ModeloimpressaoSessaoService();
    modeloCampoEditComponent: ModeloCampoEditComponent = null;

    rowSize = 100; // => container height / number of items
    containers: Array<object> = [];

    @Prop(Boolean) tipoRotulo: boolean;

    typeTablelineOptions: Array<Object> = [
        { text: "Nenhuma", value: TypeTableLines.Nenhuma },
        { text: "Ambas", value: TypeTableLines.Ambas },
        { text: "Horizontal", value: TypeTableLines.Horizontal },
        { text: "Vertical", value: TypeTableLines.Vertical },
        { text: "Somente em cima", value: TypeTableLines.SomenteCima },
        { text: "Somente embaixo", value: TypeTableLines.SomenteBaixo },
    ];
    alinhamentoOptions: Array<Object> = [
        { text: "Esquerda", value: 0 },
        { text: "Direita", value: 2 },
    ];
    //recolocar em tela...
    tipoOptions: Array<Object> = [
        { text: "Normal", value: TypeElement.Form },
        { text: "Repetição", value: TypeElement.Grid },
        { text: "Repetição Horizontal", value: TypeElement.GridHorizontal },
    ];

    dadosOptions: Array<Object> = [];
    private dados = 0;

    modelId: number = null;

    private shortcutComponent: ShortcutComponent = null;
    sessao = new ModeloImpressaoSessao();

    camposToShow: Array<ModeloImpressaoCampos> = new Array<ModeloImpressaoCampos>();

    private numColunaAtual = 0;

    public async loadSessao(sessao: ModeloImpressaoSessao) {
        this.sessao = new ModeloImpressaoSessao();
        this.sessao.campos.splice(0);
        this.clear();
        this.sessao.updateFrom(sessao);
        this.dadosOptions.splice(0);

        const dadosDesordenados = this.sessao.campos
            .filter(p => !(p.labelName?.includes("Dados da Franquia") && p.codGroup?.includes(".")))
            .filter(p => p.header)
            .filter(p => this.sessao.campos.some(c => !c.header && c.parentIndex == p.index))
            .map(item => ({
                value: item.index,
                text: item.codGroup + " - " + item.labelName,
            }));

        this.dadosOptions = dadosDesordenados.sort((n1, n2) => n1["value"] - n2["value"]);

        this.dados = 0;
        const mainDado = { value: 0, text: "Dados Principais" };
        this.dadosOptions.push(mainDado);
        this.camposToShow = this.sessao.campos.filter(p => !p.header && p.parentIndex == this.dados);
        clearContainers();
        this.setCamposTela();

        await this.load().withLoading();
    }

    private setCamposTela() {
        if (this.camposToShow.length > 0) {
            const colunas = document.getElementById("containers");
            addItemContainer(".menu-lateral");

            if (this.sessao.maxCols > 0) {
                for (let i = 0; i < this.sessao.maxCols; i++) {
                    this.onAdicionarColuna();

                    const camposAlocados = this.sessao.campos
                        .filter(p => p.coluna == this.numColunaAtual)
                        .sort((a, b) => a.linha - b.linha);

                    if (camposAlocados.length > 0) {
                        const coluna = colunas.getElementsByTagName("section");
                        for (let j = 0; j < camposAlocados.length; j++) {
                            if (camposAlocados[j].labelName == null) {
                                camposAlocados[j].labelName = "";
                            }

                            const elemento = this.criarCampoColuna(
                                camposAlocados[j].labelName,
                                camposAlocados[j].labelId,
                                camposAlocados[j].index * -1,
                            );

                            coluna[i + 1].appendChild(elemento);
                        }

                        atualizaItensContainer("." + coluna[i + 1].classList[1].trim());
                    }
                }
            }
            //monta as labels no menu

            const camposNaoAlocados = this.camposToShow.filter(p => p.coluna <= 0 && p.linha <= 0);
            this.setCamposMenu(camposNaoAlocados);
        }
    }

    private clear() {
        this.sessao = null;
        this.sessao = new ModeloImpressaoSessao();

        this.numColunaAtual = 0;
        this.camposToShow.splice(0);

        this.clearAllItens();
        clearContainers();
        changeContainerHeigth();
    }

    private clearAllItens() {
        //remove containers
        const doc = document.getElementById("containers");
        while (!(doc.lastChild["attributes"].getNamedItem("class").value.toString().indexOf("menu-lateral") > 0)) {
            doc.removeChild(doc.lastChild);
        }
        //remove itens do menu //ajusta o tamanho
        this.clearMenuItens();
    }

    private setCamposMenu(camposMenu: Array<ModeloImpressaoCampos>) {
        if (camposMenu.length > 0) {
            for (let i = 0; i < camposMenu.length; i++) {
                if (camposMenu[i].labelName == null) {
                    camposMenu[i].labelName = "";
                }

                this.adicionarCampo(camposMenu[i], false);
            }
            atualizaItensContainer(".menu-lateral");
        }
    }

    private async load() {
        try {
            if (this.modelId > 0) {
                const data = await this.service
                    .get(this.modelId)
                    .withLoading()
                    .resolveWithJSON<ModeloImpressaoSessao>();

                this.sessao = new ModeloImpressaoSessao();
                this.sessao.updateFrom(data);

                if (this.sessao.campos.length > 0) {
                    const colunas = document.getElementById("containers");
                    addItemContainer(".menu-lateral");

                    if (this.sessao.maxCols > 0) {
                        for (let i = 0; i < this.sessao.maxCols; i++) {
                            this.onAdicionarColuna();

                            const camposAlocados = this.sessao.campos
                                .filter(p => p.coluna == this.numColunaAtual)
                                .sort((obj1, obj2) => obj1.linha - obj2.linha);

                            const coluna = colunas.getElementsByTagName("section");
                            if (camposAlocados.length > 0) {
                                for (let j = 0; j < camposAlocados.length; j++) {
                                    if (camposAlocados[j].labelName == null) {
                                        camposAlocados[j].labelName = "";
                                    }

                                    const elemento = this.criarCampoColuna(
                                        camposAlocados[j].labelName,
                                        camposAlocados[j].labelId,
                                        camposAlocados[j].id,
                                    );

                                    coluna[i + 1].appendChild(elemento);
                                }
                            }
                            atualizaItensContainer("." + coluna[i + 1].classList[1].trim());
                        }
                    }

                    const camposNaoAlocados = this.sessao.campos.filter(p => p.coluna <= 0 && p.linha <= 0);
                    if (camposNaoAlocados.length > 0) {
                        for (let i = 0; i < camposNaoAlocados.length; i++) {
                            if (camposNaoAlocados[i].labelName == null) {
                                camposNaoAlocados[i].labelName = "";
                            }

                            this.adicionarCampo(camposNaoAlocados[i], false);
                        }
                        atualizaItensContainer(".menu-lateral");
                    }
                }
            } else if (this.sessao) {
                addItemContainer(".menu-lateral");
            }
        } catch {}
    }

    private criarCampoColuna(nameItem: string, atributeFor: string, atributeId: number) {
        const item = document.createElement("div");
        item.setAttribute("class", "list-item");
        item.setAttribute("id", "personalizado");

        const conteudo = document.createElement("div");
        conteudo.setAttribute("class", "item-content");
        conteudo.setAttribute("for", atributeFor);
        conteudo.setAttribute("id", atributeId.toString());

        const spanOrder = document.createElement("span");
        spanOrder.setAttribute("class", "order");
        spanOrder.setAttribute("hidden", "hidden");
        conteudo.appendChild(spanOrder);

        const spanLabel = document.createElement("span");
        spanLabel.setAttribute("class", "labelName");
        spanLabel.style.fontSize = "17px";

        const textNode = document.createTextNode(nameItem);
        spanLabel.appendChild(textNode);

        conteudo.appendChild(spanLabel);

        const iconEditar = document.createElement("i");
        iconEditar.setAttribute("class", "fa fa-edit");
        iconEditar.setAttribute("indexCampo", atributeId.toString());
        iconEditar.style.margin = "0px 20px 0px 0px";
        iconEditar.addEventListener("click", this.onEditarCampo);

        const iconExcluir = document.createElement("i");
        iconExcluir.setAttribute("class", "fa fa-trash");
        iconExcluir.setAttribute("indexCampo", atributeId.toString());
        iconExcluir.style.margin = "0px -6px 1px 0px";
        iconExcluir.addEventListener("click", this.onExcluirCampo);

        conteudo.appendChild(iconEditar);
        conteudo.appendChild(iconExcluir);

        item.appendChild(conteudo);
        return item;
    }

    private onEditarCampo(entry) {
        const icon: HTMLElement = entry.target;
        const indexCampo = Number(icon.getAttribute("indexCampo"));
        if (indexCampo) {
            const campo = this.sessao.campos.filter(p => p.index == Math.abs(indexCampo));
            if (campo.length) {
                this.shortcutComponent.title = campo[0].labelName;
                this.modeloCampoEditComponent.loadModal(campo[0]);
                this.shortcutComponent.show();
            }
        }
    }

    private onExcluirCampo(entry) {
        const icon: HTMLElement = entry.target;
        const campo = icon.parentElement;
        if (campo) {
            const colunas = document.getElementById("containers");
            const primeiraColuna = colunas.getElementsByClassName("menu-lateral")[0];

            const idCampo = Number(campo.getAttribute("id"));
            const campoSessao = this.sessao.campos.filter(p => p.index == Math.abs(idCampo))[0];
            if (campoSessao) {
                campoSessao.coluna = 0;
            }

            campo.remove();
            addListItemContainer(".menu-lateral", campo);
            primeiraColuna.appendChild(campo);
            changeContainerHeigth();
        }
    }

    private onAdicionarColuna() {
        this.numColunaAtual++;
        const section = document.createElement("section");
        section.setAttribute("ordem", this.numColunaAtual.toString());
        const className = "container" + this.numColunaAtual.toString();
        section.setAttribute("class", "container " + className);
        section.setAttribute("style", "visibility: inherit; opacity: 1;");

        const iconDelete = document.createElement("i");
        iconDelete.setAttribute("class", "fa fa-minus-square");
        iconDelete.setAttribute("aria-hidden", "true");
        iconDelete.setAttribute("idCol", this.numColunaAtual.toString());
        iconDelete.addEventListener("click", this.onRemoveCol);
        section.appendChild(iconDelete);

        const container = document.getElementById("containers");
        container.appendChild(section);
        addItemContainer("." + className);
    }

    private onRemoveCol(entry) {
        const icon: HTMLElement = entry.target;
        const colId = Number(icon.getAttribute("idCol"));
        if (colId) {
            //remove coluna
            //verifica se possui filhos antes ...
            //se possuir, passe eles para o menu e depois remove a coluna
            const col = icon.parentElement;
            const listItem = col.getElementsByClassName("list-item");
            if (listItem.length > 0) {
                const container = document.getElementById("containers");
                const sectionMenu = container.getElementsByClassName("menu-lateral")[0];

                while (listItem.length > 0) {
                    addListItemContainer(".menu-lateral", listItem[0]);
                    sectionMenu.appendChild(listItem[0]);
                }
            }
            col.remove();
        }
        changeContainerHeigth();
    }

    private onAddLabel() {
        this.shortcutComponent.title = "Novo";
        const campo = new ModeloImpressaoCampos();
        campo.coluna = 0;
        campo.linha = 0;
        campo.personalizado = true;
        campo.labelShowValue = true;
        campo.labelName = "";
        campo.labelShowValue = true;
        campo.header = false;
        campo.bold = false;
        campo.adicional = false;
        campo.substituto = false;
        campo.parentIndex = 0;
        campo.modeloImpressaoSessaoId = this.sessao.id;
        if (this.sessao.campos.length > 0) campo.index = Math.max(...this.sessao.campos.map(p => p.index)) + 1;
        else campo.index = 1;

        this.modeloCampoEditComponent.loadModal(campo);

        this.shortcutComponent.show();
    }

    private adicionarCampo(campo: ModeloImpressaoCampos, atualizarMenuJs = true) {
        //get containers
        const container = document.getElementById("containers");
        const sectionMenu = container.getElementsByClassName("menu-lateral");

        const elementoSessao = this.criarCampoColuna(campo.labelName, campo.labelId, campo.index * -1);
        sectionMenu[0].appendChild(elementoSessao);

        if (atualizarMenuJs) {
            addListItemContainer(".menu-lateral", elementoSessao);
        }

        changeContainerHeigth();
    }

    // @ts-ignore
    @Watch("dados")
    private onDadosChanged() {
        //remove campos do menu-lateral
        this.clearMenuItens();
        //insere campos do novo dado no menu lateral
        const menuFields = this.sessao.campos.filter(
            p => !p.header && p.parentIndex == this.dados && document.getElementById((p.index * -1).toString()) == null,
        );
        this.setCamposMenu(menuFields);
    }

    private clearMenuItens() {
        const doc = document.querySelectorAll(".menu-lateral")[0];
        let possuiItens = false;
        while (doc.hasChildNodes()) {
            doc.removeChild(doc.lastChild);
            possuiItens = true;
        }

        if (possuiItens) {
            clearContainers(".menu-lateral");
        }

        changeContainerHeigth();
    }

    public GetSessaoModel() {
        const montarModel = sessao => {
            const colunas = document.getElementById("containers").getElementsByClassName("container");

            for (let col = 0; col < colunas.length; col++) {
                if (colunas[col].hasChildNodes) {
                    const camposColuna = colunas[col].getElementsByClassName("item-content");

                    for (let row = 0; row < camposColuna.length; row++) {
                        const elemento = camposColuna[row];
                        const idCampo = Number(elemento.getAttribute("id"));

                        let campoSessao = sessao.campos.filter(p => p.index == Math.abs(idCampo))[0];
                        if (!campoSessao) {
                            campoSessao = new ModeloImpressaoCampos();
                            campoSessao.id = null;
                            campoSessao.header = false;
                            campoSessao.index = 0;
                            campoSessao.parentIndex = 0;
                            campoSessao.personalizado = true;
                        }

                        // Se campo tem Id e ta na coluna zero não deve atualizar pois o campo vai ser removido
                        if (!(campoSessao.Id && campoSessao.coluna == 0)) {
                            campoSessao.coluna = col;
                        }
                        campoSessao.linha = col ? Number(elemento.getElementsByTagName("span")[0].textContent) : col;

                        if (campoSessao.id <= 0) {
                            campoSessao.id = null;
                        }
                    }
                }
            }

            sessao.maxCols = colunas.length - 1;

            if (sessao.campos.filter(p => p.coluna > 0).length > 0) {
                sessao.maxRows = Math.max(...sessao.campos.filter(p => p.coluna > 0).map(p => p.linha));
            } else {
                sessao.maxRows = 0;
            }

            return sessao;
        };

        const removerCamposColunaZero = sessao => {
            sessao.campos = sessao.campos.filter(p => p.coluna > 0);
            return sessao;
        };

        return removerCamposColunaZero(montarModel(this.sessao));
    }

    private onConfirmEdicaoCampo() {
        const campoAfter = new ModeloImpressaoCampos();
        campoAfter.updateFrom(this.modeloCampoEditComponent.getModel());
        const editing =
            !campoAfter.personalizado || this.sessao.campos.filter(p => p.index == campoAfter.index).length > 0;

        if (editing) {
            this.sessao.campos
                .filter(p => p.index == campoAfter.index)
                .forEach(obj => {
                    copyObject(campoAfter, obj);
                });
            this.atualizaCampo(campoAfter);
        } else {
            this.sessao.campos.push(campoAfter);
            this.adicionarCampo(campoAfter);
        }
        this.shortcutComponent.hide();
    }

    private atualizaCampo(campo: ModeloImpressaoCampos) {
        const item = document.getElementById("-" + campo.index.toString());
        const labelName = item.getElementsByClassName("labelName");
        labelName[0].textContent = campo.labelName;
    }

    private mounted() {
        this.shortcutComponent = this.$refs.shortcutComponent as ShortcutComponent;
        this.modeloCampoEditComponent = this.$refs.modeloCampoEditComponent as ModeloCampoEditComponent;
    }
}
