import { GridColumn, GridColumnType } from "@/components/child/grid/gridColumn";
import { Component } from "@/decorators";
import FarmacopeiaModel from "@/models/farmacopeiaModel";
import FarmacopeiaService from "@/services/farmacopeiaService";

import ListComponentBase from "../listComponentBase";

@Component
export default class FarmacopeiaListComponent extends ListComponentBase<FarmacopeiaModel> {
    get gridColumns(): Array<GridColumn> {
        return [new GridColumn("descricao", this.$t("__.ts.descricao"), GridColumnType.String)];
    }

    private beforeMount() {
        this.setProps(new FarmacopeiaService(), "farmacopeia", "descricao");
    }
}
