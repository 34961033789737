import Vue from "vue";
import { mapGetters, mapMutations, mapState } from "vuex";

import Grid from "@/components/child/grid/grid";
import gridComponent from "@/components/child/grid/grid.vue";
import { Component, Watch } from "@/decorators";
import PaginationPackModel from "@/models/auxiliar/paginationPackModel";
import PaginationModel from "@/models/paginationModel";
import { IService } from "@/services/base/iService";
import { AppState, Getters, Mutations } from "@/store/store";
import Delay from "@/utils/common/delay";

import "../crud/crud.scss";

@Component({
    components: {
        gridComponent,
    },
    computed: {
        ...mapState({
            loadedList: (state: AppState) => state.preLoad.loadedList,
            loadingPack: (state: AppState) => state.paginationStatus.loading,
            paginationParams: (state: AppState) => state.paginationStatus.paginationParams,
        }),
        ...mapGetters(["HAS_PERMISSAO_PERFIL"] as Getters),
    },
    methods: mapMutations(["SET_PARAMS", "GET_PARAMS"] as Mutations),
})
export default class ListComponentBase<T> extends Vue {
    // State computed props
    loadedList: boolean;
    loadingPack: boolean;
    paginationParams: PaginationPackModel;
    SET_PARAMS: (obj: object) => void;
    GET_PARAMS: (path: string) => Promise<void>;
    HAS_PERMISSAO_PERFIL: (isRede: boolean, isFranqueador: boolean, isSuporte: boolean) => Promise<boolean>;

    protected service: IService<T> = null;
    protected routePath = null;
    protected nomeProp = null;
    protected validaFranquiaIdExclusao = false;

    gridcomponent: Grid = null;
    startFilterKey = "";
    gridFilterKey = "";
    gridSortKey = "";
    gridSortOrder = "asc";
    gridData = [];
    filterKey = "";

    pageIndex = 1;
    pageSize = 20;
    total = 0;
    tipoDocGerar: number = null;
    pags = false;
    filtros = [];

    public setProps(
        service: IService<T>,
        routePath: string,
        nomeProp: string,
        validaFranquiaIdExclusao = false,
        sortOrder = "asc",
    ) {
        this.service = service;
        this.routePath = routePath;
        this.nomeProp = nomeProp;
        this.gridSortKey = nomeProp;
        this.gridSortOrder = sortOrder;
        this.validaFranquiaIdExclusao = validaFranquiaIdExclusao;
    }

    public async load() {
        try {
            this.pageIndex = this.gridFilterKey !== this.filterKey ? 1 : this.pageIndex;
            this.filterKey = this.gridFilterKey;

            const data = await this.loadMethod();

            if (data) {
                if (data.total > 0) {
                    this.SET_PARAMS({
                        routePath: this.$route.path,
                        filterKey: this.gridFilterKey,
                        sortKey: this.gridSortKey,
                        sortOrder: this.gridSortOrder,
                        pageIndex: this.pageIndex,
                        pageSize: this.pageSize,
                        filtros: this.filtros,
                    });
                }

                this.gridData = data.list;
                this.total = data.total;
                this.pageIndex = data.pageIndex;
                this.pageSize = data.pageSize;

                await this.afterLoad();

                if (this.pags) {
                    if (this.tipoDocGerar == 0) {
                        setTimeout(() => {
                            this.gridcomponent.gerarXls();
                        }, 2000);
                    }

                    if (this.tipoDocGerar == 1) {
                        setTimeout(() => {
                            this.gridcomponent.gerarPdf();
                        }, 2000);
                    }

                    if (this.tipoDocGerar == 2) {
                        setTimeout(() => {
                            this.gridcomponent.gerarImpressao();
                        }, 2000);
                    }

                    setTimeout(() => {
                        this.pags = false;
                    }, 2000);
                }
            }
        } catch {}
    }

    private onChangeFilterKey(filterKey: string) {
        this.gridFilterKey = filterKey;
        this.load();
    }

    private onChangeSort(sortKey: string, sortOrder: string) {
        this.gridSortKey = sortKey;
        this.gridSortOrder = sortOrder;
        this.load();
    }

    private onChangePage(pageIndex: number) {
        this.pageIndex = pageIndex;
        this.load();
    }

    private onChangePageSize(newPageSize: number) {
        this.pageIndex = 1;
        this.pageSize = newPageSize;
        this.load();
    }

    @Watch("pags")
    private onPags() {
        this.load();
    }

    public onCreateItem() {
        this.$router.push(`/${this.routePath}-edicao`);
    }

    private onEditItem(model: T) {
        this.$router.push(`/${this.routePath}-edicao/${model["id"]}`);
    }

    public async onRemoveItem(model: T) {
        if (this.validaFranquiaIdExclusao && !(await this.validarPermissaoExclusao(model))) {
            return await this.$showError(
                this.$t("__.ts.atencao"),
                "Este registro não pode ser excluído pois faz parte da franquia modelo.",
            );
        }

        const response = await this.$showQuestion(
            `${this.$t("__.ts.desejaExcl")}${model[this.nomeProp] ?? ""}?`,
            this.$t("__.ts.acaoNaoRevert"),
        );

        if (response) {
            try {
                const sucesso = await this.service.delete(model["id"]).withLoading().resolveWithoutJSON();
                if (sucesso) {
                    await this.onSucessoExclusao();
                    this.load();
                    await this.$showExclusao();
                }
            } catch {}
        }
    }

    private async validarPermissaoExclusao(model: T) {
        return (
            (await this.HAS_PERMISSAO_PERFIL(true, false, false)) ||
            !model.hasOwnProperty("franquiaId") ||
            model["franquiaId"] != null
        );
    }

    // Callbacks
    public async onSucessoExclusao() {}

    public async loadMethod() {
        return await this.service
            .list(
                this.gridFilterKey,
                this.gridSortKey,
                this.gridSortOrder,
                this.pags ? 1 : this.pageIndex,
                this.pags ? 999999 : this.pageSize,
            )
            .withLoading()
            .resolveWithJSON<PaginationModel<T>>();
    }

    public async afterLoad() {}

    public async afterLoadPagination() {}

    private async loadPagination() {
        await this.GET_PARAMS(this.$route.path);
        while (this.loadingPack) await Delay(100);

        if (this.paginationParams != null) {
            this.gridFilterKey = this.paginationParams.filterKey;
            this.startFilterKey = this.gridFilterKey;
            this.gridSortKey = this.paginationParams.sortKey;
            this.gridSortOrder = this.paginationParams.sortOrder;
            this.pageIndex = this.paginationParams.pageIndex;
            this.pageSize = this.paginationParams.pageSize;

            await this.afterLoadPagination();
        }
    }

    public async afterMounted() {}

    private async loadPreLoadPack() {
        while (!this.loadedList) await Delay(100);
    }

    private async mounted() {
        this.gridcomponent = this.$refs.gridcomponent as Grid;

        try {
            await Promise.all([this.loadPreLoadPack(), this.loadPagination()]);

            this.load();
            this.afterMounted();
        } catch {}
    }
}
