import BaseModel from "./../base/baseModel";
import SessaoCaixaDetalhesFechamento from "./sessaoCaixaDetalhesFechamentoModel";

export default class SessaoCaixaModel extends BaseModel {
    id?: number = null;
    usuarioId?: number = null;
    pdvId: number = null;
    dataHoraAbertura?: Date = null;
    dataHoraFechamento?: Date = null;
    saldoInicial: number = null;
    saldoFechamento?: number = null;
    totalEntradas?: number = null;
    totalSaidas?: number = null;
    detalhesFechamento: Array<SessaoCaixaDetalhesFechamento> = [];
    //dados de inicio
    gerarDiferencaSaldo?: boolean = null;
    valorDiferenca?: number = null;
    formaRecebimentoId?: number = null;
    contaCorrenteDestinoId?: number = null;
    nomeUsuario = "";
}
