import { Component } from "@/decorators";
import MotivoRejeicaoModel from "@/models/motivoRejeicaoModel";
import MotivoRejeicaoService from "@/services/motivoRejeicaoService";

import EditComponentBase from "../editComponentBase";

@Component
export default class MotivoRejeicaoEditComponent extends EditComponentBase<MotivoRejeicaoModel> {
    private async beforeMount() {
        await this.setProps(new MotivoRejeicaoService(), "motivo_rejeicao", new MotivoRejeicaoModel(), false, true);
    }
}
