import Vue from "vue";
import { mapGetters, mapState } from "vuex";

import actionBarComponent from "@/components/child/actionBar/actionBar.vue";
import checkboxComponent from "@/components/child/form/checkbox.vue";
import comboComponent from "@/components/child/form/combo.vue";
import dataTooltipComponent from "@/components/child/form/datatooltip.vue";
import dateTimePickerComponent from "@/components/child/form/datetimepicker.vue";
import fieldsetComponent from "@/components/child/form/fieldset.vue";
import moedaComponent from "@/components/child/form/moeda.vue";
import gridComponent from "@/components/child/grid/grid.vue";
import { GridColumn, GridColumnType } from "@/components/child/grid/gridColumn";
import infoBagdeComponent from "@/components/child/infoBagde/infoBagde.vue";
import logEntidadeComponent from "@/components/child/logEntidade/logEntidade.vue";
import { Component } from "@/decorators";
import EnumExtensions from "@/models/enum/extensions/enumExtensions";
import StatusEnvio from "@/models/enum/statusEnvioNF";
import StatusEnvioNF from "@/models/enum/statusEnvioNF";
import TipoNotaFiscal from "@/models/enum/tipoNotaFiscal";
import TipoOperacao from "@/models/enum/tipoOperacao";
import NotaFiscalSaidaItemModel from "@/models/notaFiscalSaidaItemModel";
import NotaFiscalSaidaModel from "@/models/notaFiscalSaidaModel";
import UsuarioModel from "@/models/usuarioModel";
import NotaFiscalEletronicaEmissaoService from "@/services/emissaoNota/notaFiscalEletronicaEmissaoService";
import NotaFiscalServicoService, {
    VerificaCancelamentoRetorno,
} from "@/services/emissaoNota/notaFiscalServicoEmissaoService";
import NotaFiscalSaidaService from "@/services/notaFiscalSaidaService";
import { AppState, Getters, SessionActions } from "@/store/store";

import "./../crud/crud.scss";

@Component({
    components: {
        checkboxComponent,
        dataTooltipComponent,
        dateTimePickerComponent,
        moedaComponent,
        fieldsetComponent,
        gridComponent,
        comboComponent,
        actionBarComponent,
        infoBagdeComponent,
        logEntidadeComponent,
    },
    computed: {
        ...mapState({
            usuarioLogado: (state: AppState) => state.session.usuarioLogado,
        }),
        ...mapGetters(["HAS_PERMISSAO_PERFIL"] as Getters),
        infoBagdeText() {
            return `Emitido por: ${this.model.usuarioCriacaoNome}`;
        },
    },
})
export default class NotaFiscalSaidaEditComponent extends Vue {
    // State computed props
    usuarioLogado: UsuarioModel;
    infoBagdeText: string;
    HAS_PERMISSAO_PERFIL: (isRede: boolean, isFranqueador: boolean, isSuporte: boolean) => Promise<boolean>;

    private service = new NotaFiscalSaidaService();
    private notaFiscalEletronicaEmissaoService = new NotaFiscalEletronicaEmissaoService();
    private notaFiscalServicoService = new NotaFiscalServicoService();

    model = new NotaFiscalSaidaModel();
    modelId = 0;
    isAdminOrSuporte = false;

    constructor() {
        super();
    }

    statusEnvioOptions: Array<Object> = EnumExtensions.getNamesAndValuesOrderedByNames(StatusEnvio);

    motivoCancelamentoOptions: Array<Object> = [
        { text: "1 - Erro de Emissão", value: 1 },
        { text: "2 - Serviço Não Prestado", value: 2 },
        { text: "3 - Erro de Assinatura", value: 3 },
        { text: "4 - Duplicidade de Nota", value: 4 },
    ];

    motivoDescricao: string = null;

    TipoNotaFiscalOptions: Array<Object> = [
        { text: "Serviço", value: 0 },
        { text: "Cupom Fiscal", value: 1 },
        { text: "Nota Fiscal", value: 2 },
    ];

    gridColumnsItens: Array<GridColumn> = [
        new GridColumn("codigo", "Código", GridColumnType.String),
        new GridColumn("atividade", "Atividade", GridColumnType.String, false, false, "", "servico"),
        new GridColumn("descricao", "Serviço", GridColumnType.String, false, false, "", "servico"),
        new GridColumn("aliquota", "Aliquota", GridColumnType.Percent, false, false, "", "servico", false, true, 4),
        new GridColumn("valorTotal", "Valor", GridColumnType.Money),
    ];

    gridColumnsProdutos: Array<GridColumn> = [
        new GridColumn("codigo", "Código", GridColumnType.String),
        new GridColumn("descricao", "Produto", GridColumnType.String),
        new GridColumn("unidadeMedidaSigla", "Un. Medida", GridColumnType.String),
        new GridColumn("valorFrete", "Frete", GridColumnType.Money),
        new GridColumn("valorAcrescimo", "Acrescimo", GridColumnType.Money),
        new GridColumn("valorDesconto", "Desconto", GridColumnType.Money),
        new GridColumn("precoUnitario", "Preço unitário", GridColumnType.Money),
        new GridColumn("quantidade", "quantidade", GridColumnType.Decimal),
        new GridColumn("valorTotalCalculado", "Total", GridColumnType.Money),
    ];

    private async load() {
        this.model = new NotaFiscalSaidaModel();

        if (this.modelId > 0) {
            try {
                const data = await this.service.get(this.modelId).withLoading().resolveWithJSON<NotaFiscalSaidaModel>();
                this.model.updateFrom(data);

                if (this.model.motivoCancelamento != null)
                    this.motivoDescricao = this.motivoCancelamentoOptions.filter(
                        p => p["value"] == this.model.motivoCancelamento,
                    )[0]["text"];

                this.model.notaFiscalSaidaItens = this.model.notaFiscalSaidaItens.map(item => {
                    let newItem = new NotaFiscalSaidaItemModel();
                    newItem = item;
                    newItem.valorTotalCalculado =
                        item.quantidade * item.precoUnitario +
                        item.valorAcrescimo +
                        item.valorFrete -
                        item.valorDesconto;
                    return newItem;
                });
            } catch {
                this.$router.back();
            }
        }
    }

    private cancel() {
        this.$router.back();
    }

    private async consultaEmissao() {
        try {
            let statusRetorno = StatusEnvioNF.Pendente;

            if (this.model.tipo == TipoNotaFiscal.Servico) {
                statusRetorno = await this.notaFiscalServicoService
                    .consultarBySearchKey(this.model.searchKey)
                    .withLoading()
                    .resolveWithJSON<StatusEnvioNF>();
            } else {
                statusRetorno = await this.notaFiscalEletronicaEmissaoService
                    .consultar(this.model.recibo, this.model.modelo)
                    .withLoading()
                    .resolveWithJSON<StatusEnvioNF>();
            }

            await this.notaFiscalServicoService.showMessageByStatus(statusRetorno);
            this.load();
        } catch {}
    }

    private openPDF() {
        this.notaFiscalEletronicaEmissaoService.openPdf(this.model.pdfLink);
    }

    private openPDFSimplificado() {
        this.notaFiscalEletronicaEmissaoService.openPdf(this.model.pdfSimplificadoLink);
    }

    private openXML() {
        this.notaFiscalEletronicaEmissaoService.downloadXML(this.model.xmlLink);
    }

    private openCartaCorrecao() {
        this.notaFiscalEletronicaEmissaoService.showCartaCorrecao(this.model.id, TipoOperacao.Saida);
    }

    private async onGerarCartaCorrecao() {
        const response = await this.notaFiscalEletronicaEmissaoService.cartaCorrecaoSelecao();

        if (response.isConfirmed) {
            try {
                const sucesso = await this.notaFiscalEletronicaEmissaoService
                    .cartaCorrecao(this.model.chave, this.model.id, response.value, TipoOperacao.Saida)
                    .withLoading()
                    .resolveWithoutJSON();

                if (sucesso) {
                    this.load();
                }
            } catch {}
        }
    }

    private async onCancelar() {
        if (this.model.tipo == TipoNotaFiscal.Servico) {
            const cancelamento = await this.notaFiscalServicoService
                .verificaCancelamento(this.model.id, this.usuarioLogado.franquia.cidadeId)
                .withLoading();

            if (cancelamento != VerificaCancelamentoRetorno.CANCELANOTA) {
                if (cancelamento == VerificaCancelamentoRetorno.CANCELANOTASISTEMA) {
                    this.load();
                }
                return;
            }

            const response = await this.notaFiscalServicoService.cancelamentoSelecao();
            if (response.isConfirmed) {
                try {
                    const sucesso = await this.notaFiscalServicoService
                        .cancelamento(this.model.numero, response.value)
                        .withLoading()
                        .resolveWithoutJSON();

                    if (sucesso) {
                        this.load();
                    }
                } catch {}
            }
        } else {
            const response = await this.notaFiscalEletronicaEmissaoService.cancelamentoSelecao();

            if (response.isConfirmed) {
                try {
                    const sucesso = await this.notaFiscalEletronicaEmissaoService
                        .cancelamento(this.model.chave, response.value)
                        .withLoading()
                        .resolveWithoutJSON();

                    if (sucesso) {
                        this.load();
                    }
                } catch {}
            }
        }
    }

    private permiteConsultar() {
        return (
            (this.isAdminOrSuporte && this.model.statusEnvio <= StatusEnvioNF.Confirmado) ||
            (this.model.id && this.model.statusEnvio < StatusEnvioNF.Confirmado) ||
            this.model.statusEnvio == StatusEnvioNF.Reprovado ||
            this.model.numero == 0
        );
    }

    private async mounted() {
        await this.$store.dispatch(SessionActions.LOAD_USUARIO_LOGADO);

        this.isAdminOrSuporte = await this.HAS_PERMISSAO_PERFIL(false, false, true);

        if (this.$route.params.id) {
            this.modelId = +this.$route.params.id;
            this.load();
        }
    }
}
