import Vue from "vue";
import { mapGetters } from "vuex";

import fieldsetComponent from "@/components/child/form/fieldset.vue";
import moedaComponent from "@/components/child/form/moeda.vue";
import gridComponent from "@/components/child/grid/grid.vue";
import { GridColumn, GridColumnType } from "@/components/child/grid/gridColumn";
import { Component } from "@/decorators";
import Especie from "@/models/enum/especiePagamento";
import MovimentacaoCaixaModel from "@/models/financeiro/movimentacaoCaixaModel";
import PaginationModel from "@/models/paginationModel";
import MovimentacaoCaixaService from "@/services/financeiro/movimentacaoCaixaService";
import { Getters } from "@/store/store";

import ShortcutComponent from "../shortcut/shortcut";
import shortcutComponent from "../shortcut/shortcut.vue";

import ReforcoComponent from "./reforco";
import reforcoComponent from "./reforco.vue";
import SangriaComponent from "./sangria";
import sangriaComponent from "./sangria.vue";

import "../crud/crud.scss";
import "./fechamentoCaixa.scss";

@Component({
    components: {
        gridComponent,
        fieldsetComponent,
        shortcutComponent,
        reforcoComponent,
        sangriaComponent,
        moedaComponent,
    },
    computed: {
        ...mapGetters(["VALIDAR_PERMISSAO_USUARIO"] as Getters),
    },
})
export default class MovimentacaoCaixaListComponent extends Vue {
    // State computed props
    VALIDAR_PERMISSAO_USUARIO: (telaDescricao: string, acaoController: string) => boolean;

    private service = new MovimentacaoCaixaService();
    private shortcutReforcoComponent: ShortcutComponent = null;
    private shortcutSangriaComponent: ShortcutComponent = null;
    private reforcoComponent: ReforcoComponent = null;
    private sangriaComponent: SangriaComponent = null;

    saldoInicial = 0;
    saldoAtual = 0;
    caixaSessao: string = null;
    totalDinheiro = 0;
    //conta corrente id do caixa da sessao aberta
    idSessaoCaixa: number = null;

    gridFilterKey = "";
    gridSortKey = "especieDescricao";
    gridSortOrder = "asc";
    gridData: Array<MovimentacaoCaixaModel> = [];
    get gridColumns(): Array<GridColumn> {
        return [
            new GridColumn("especieDescricao", "Especie", GridColumnType.String, false, false, "", "", false, false),
            new GridColumn("entradas", "Entrada", GridColumnType.MoneyWithSignal, false, false, "", "", false, false),
            new GridColumn("saidas", "Saida", GridColumnType.MoneyWithSignal, false, false, "", "", false, false),
            new GridColumn("total", "Total", GridColumnType.MoneyWithSignal, false, false, "", "", false, false),
        ];
    }

    subDataName = "movimentacoes";

    gridColumnsVinculos: Array<GridColumn> = [
        new GridColumn("descricao", "Descrição", GridColumnType.String),
        new GridColumn("descClienteFornecedor", "Cliente/Fornecedor", GridColumnType.String),
        new GridColumn("contaCorrenteDescricao", "Conta Corrente", GridColumnType.String),
        new GridColumn("tiposMovimentacaoDescr", "Tipo", GridColumnType.String),
        new GridColumn("dataLancamento", "Data/Hora", GridColumnType.DateTime),
        new GridColumn("usuario", "Usuario", GridColumnType.String),
        new GridColumn("valor", "valor", GridColumnType.MoneyWithSignal),
    ];

    conditionShowSubData(it) {
        return it["movimentacoes"]["length"] > 0;
    }
    pageIndex = 1;
    pageSize = 20;
    total = 0;
    totalMov = 0;

    private async load() {
        try {
            const data = await this.service
                .list(
                    this.gridFilterKey,
                    this.gridSortKey,
                    this.gridSortOrder,
                    this.pageIndex,
                    this.pageSize,
                    this.idSessaoCaixa,
                )
                .withLoading()
                .then(r => r.json() as Promise<PaginationModel<MovimentacaoCaixaModel>>);

            this.gridData = data.list;
            this.total = data.total;
            this.totalMov = data.list.map(p => p.movimentacoes.length).reduce((acc, p) => acc + p, 0);

            this.totalDinheiro = 0;
            const movDinheiro = data.list.find(p => p.especie == Especie.Dinheiro);
            if (movDinheiro) {
                this.totalDinheiro = movDinheiro.total;
            }

            //se possui transferencias, sao consederadas como entradas de dinheiro p caixa
            const movTransferencia = data.list.filter(p => p.especie == Especie.Transferencia);
            if (movTransferencia.length > 0) {
                this.totalDinheiro += movTransferencia.map(p => p.totalDifCaixa).reduce((acc, p) => acc + p, 0);
            }

            this.saldoAtual = this.totalDinheiro + this.saldoInicial;
        } catch {}
    }

    private onChangeFilterKey(filterKey: string) {
        this.gridFilterKey = filterKey;
        this.load();
    }

    private onChangeSort(sortKey: string, sortOrder: string) {
        this.gridSortKey = sortKey;
        this.gridSortOrder = sortOrder;
        this.load();
    }

    private onChangePage(pageIndex: number) {
        this.pageIndex = pageIndex;
        this.load();
    }

    private async loadSaldoInicial() {
        try {
            const data = await this.service.getSaldoInicial().then(r => r.json() as Promise<number>);
            this.saldoInicial = data;
        } catch {
            this.saldoInicial = 0;
        }
    }

    private async loadGetIdSessaoCaixa() {
        try {
            const data = await this.service.getIdCaixaSessaoAberta().then(r => r.json() as Promise<number>);
            this.idSessaoCaixa = data;
        } catch {
            this.idSessaoCaixa = 0;
        }
    }

    private async loadDescricaoCaixa() {
        try {
            const data = await this.service.getDescricaoCaixaSessaoAberta().then(r => r.text() as Promise<string>);
            this.caixaSessao = data;
        } catch {
            this.caixaSessao = null;
        }
    }

    private cancel() {
        this.$router.back();
    }

    private gerarReforco() {
        if (this.idSessaoCaixa == 0) {
            return this.$showWarning(this.$t("__.ts.atencao"), this.$t("__.ts.caixaFechado"));
        }

        this.shortcutReforcoComponent.title = this.$t("__.ts.gerarReforco") as string;
        this.reforcoComponent.clear(this.caixaSessao, this.saldoAtual, this.idSessaoCaixa);
        this.shortcutReforcoComponent.show();
    }

    private gerarSangria() {
        if (this.idSessaoCaixa == 0) {
            return this.$showWarning(this.$t("__.ts.atencao"), this.$t("__.ts.caixaFechado"));
        }

        this.shortcutSangriaComponent.title = this.$t("__.ts.gerarSangria") as string;
        this.sangriaComponent.clear(this.idSessaoCaixa, this.caixaSessao, this.saldoAtual);
        this.shortcutSangriaComponent.show();
    }

    private async onConfirmReforco() {
        const sucesso = await this.reforcoComponent.onGerarReforco(this.idSessaoCaixa);
        if (sucesso) {
            this.reload();
            this.shortcutReforcoComponent.hide();
        }
    }

    private async onConfirmSangria() {
        const sucesso = await this.sangriaComponent.onGerarSangria();
        if (sucesso) {
            this.reload();
            this.shortcutSangriaComponent.hide();
        }
    }

    private reload() {
        Promise.all([this.loadSaldoInicial(), this.loadDescricaoCaixa(), this.loadGetIdSessaoCaixa()])
            .withLoading()
            .then(() => {
                this.load();
            })
            .catch(() => {});
    }

    private mounted() {
        this.reforcoComponent = this.$refs.reforcoComponent as ReforcoComponent;
        this.sangriaComponent = this.$refs.sangriaComponent as SangriaComponent;
        this.shortcutReforcoComponent = this.$refs.shortcutReforcoComponent as ShortcutComponent;
        this.shortcutSangriaComponent = this.$refs.shortcutSangriaComponent as ShortcutComponent;

        Promise.all([this.loadSaldoInicial(), this.loadDescricaoCaixa(), this.loadGetIdSessaoCaixa()])
            .withLoading()
            .then(() => {
                this.load();
            })
            .catch(() => {});
    }
}
