import Vue from "vue";

import { Component, Prop } from "@/decorators";
import "./infoBagde.scss";

@Component
export default class InfoBagdeComponent extends Vue {
    @Prop({ type: String, default: "" }) text: string;
    @Prop({ type: String, default: "blue" }) badgeColor: string;
}
