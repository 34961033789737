var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "crud crud-list" },
    [
      _c("div", { staticClass: "info-div" }, [
        _c("h1", { staticClass: "title" }, [
          _vm._v(
            "\n            " +
              _vm._s(
                _vm.$t(
                  "__.Components.parent.atualizacaoValores.produto.atualizacaoValoresProdutos"
                )
              ) +
              "\n        "
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "content" }, [
          _c("div", { staticClass: "info-panel" }, [
            _c("span", [_vm._v(_vm._s(_vm.total))]),
            _vm._v(" "),
            _c("span", [
              _vm._v(
                _vm._s(
                  _vm.$t(
                    "__.Components.parent.atualizacaoValores.produto.encontrados"
                  )
                )
              ),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "grid-component",
        {
          attrs: {
            data: _vm.gridData,
            columns: _vm.gridColumns,
            showSelectCheckbox: true,
            showActionColumn: false,
            showAddNewButton: false,
            showSearchField: false,
            showSortIcons: false,
            showTools: false,
          },
          on: { "selected-values-changed": _vm.onSelectedValuesChanged },
        },
        [
          _c("div", { attrs: { slot: "header" }, slot: "header" }, [
            _c("div", { staticStyle: { display: "flex" } }, [
              _c("div", { staticClass: "filtro-lista" }, [
                _c(
                  "button",
                  {
                    staticClass: "filtro-avancado-button",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        _vm.isHidden = !_vm.isHidden
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n                        " +
                        _vm._s(_vm.$t("__.Crud.filtroAvancado")) +
                        "\n                        "
                    ),
                    _c("i", { staticClass: "fa fa-caret-down" }),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.isHidden,
                        expression: "isHidden",
                      },
                    ],
                    staticClass: "drop-lista",
                  },
                  [
                    _c(
                      "form",
                      {
                        attrs: { novalidate: "novalidate" },
                        on: {
                          submit: function ($event) {
                            $event.preventDefault()
                            return _vm.onFiltrar($event)
                          },
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "radio-group",
                            staticStyle: { "margin-bottom": "15px" },
                          },
                          [
                            _c("div", { staticStyle: { display: "table" } }, [
                              _c(
                                "div",
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "control-label",
                                      attrs: { for: "grupoProduto" },
                                    },
                                    [
                                      _vm._v(
                                        "\n                                            " +
                                          _vm._s(
                                            _vm.$t(
                                              "__.Crud.produto.list_vue_html.grupProduto"
                                            )
                                          ) +
                                          "\n                                        "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("combo-component", {
                                    staticStyle: { background: "white" },
                                    attrs: {
                                      id: "grupoProdutoId",
                                      name: "grupoProdutoId",
                                      data: _vm.grupoProdutoOptions,
                                      refresh: _vm.loadGruposProduto,
                                    },
                                    model: {
                                      value: _vm.grupoProdutoFiltro,
                                      callback: function ($$v) {
                                        _vm.grupoProdutoFiltro = $$v
                                      },
                                      expression: "grupoProdutoFiltro",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _vm.apareceSubGrupo == 2
                                ? _c(
                                    "div",
                                    [
                                      _c(
                                        "label",
                                        {
                                          staticClass: "control-label",
                                          staticStyle: {
                                            "margin-top": "10px",
                                            width: "100%",
                                          },
                                          attrs: { for: "subGrupoProduto" },
                                        },
                                        [
                                          _vm._v(
                                            "\n                                            " +
                                              _vm._s(
                                                _vm.$t(
                                                  "__.Crud.produto.list_vue_html.subgrupProd"
                                                )
                                              ) +
                                              "\n                                        "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("combo-component", {
                                        staticStyle: { background: "white" },
                                        attrs: {
                                          id: "subGrupoProduto",
                                          name: "subGrupoProduto",
                                          data: _vm.subGrupoOptions,
                                        },
                                        model: {
                                          value: _vm.subGrupo,
                                          callback: function ($$v) {
                                            _vm.subGrupo = $$v
                                          },
                                          expression: "subGrupo",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "form-group" }, [
                          _c(
                            "button",
                            {
                              staticClass: "botao-filtrar",
                              attrs: { type: "submit" },
                            },
                            [
                              _vm._v(
                                "\n                                    " +
                                  _vm._s(_vm.$t("__.Crud.filtrar")) +
                                  "\n                                "
                              ),
                            ]
                          ),
                        ]),
                      ]
                    ),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "atualizacao-container" }, [
                _c("div", { staticClass: "atualizacao-card" }, [
                  _c(
                    "label",
                    { staticClass: "control-label", attrs: { for: "valor" } },
                    [
                      _vm._v(
                        "\n                            " +
                          _vm._s(
                            _vm.$t(
                              "__.Components.parent.atualizacaoValores.percentualAtualizacao"
                            )
                          ) +
                          "\n                        "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.percentualAtualizacao,
                        expression: "percentualAtualizacao",
                      },
                    ],
                    ref: "percentualAtualizacao",
                    staticClass: "form-control",
                    attrs: {
                      id: "percentualAtualizacao",
                      name: "percentualAtualizacao",
                      type: "number",
                    },
                    domProps: { value: _vm.percentualAtualizacao },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.percentualAtualizacao = $event.target.value
                      },
                    },
                  }),
                ]),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary atualizacao-card",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        _vm.onAtualizarValores()
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n                        " +
                        _vm._s(
                          _vm.$t(
                            "__.Components.parent.atualizacaoValores.atualizar"
                          )
                        ) +
                        "\n                    "
                    ),
                  ]
                ),
              ]),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }