var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "col-sm-12" }, [
      _c(
        "div",
        {
          directives: [
            {
              name: "tooltip",
              rawName: "v-tooltip",
              value: _vm.errors.first("kitEmbalagem" + _vm.index),
              expression: "errors.first('kitEmbalagem' + index)",
            },
          ],
          staticClass: "form-group",
        },
        [
          _c(
            "label",
            {
              staticClass: "control-label",
              attrs: {
                for: "kitEmbalagem" + _vm.index,
                title: _vm.$t("__.obrigatorio"),
              },
            },
            [
              _c("span", { staticStyle: { color: "red" } }, [_vm._v("*")]),
              _vm._v(
                "\n                " +
                  _vm._s(
                    _vm.$t(
                      "__.Crud.produtolote.fracionamentoKitEmbalagem_vue_html.kitEmbalagem"
                    )
                  ) +
                  "\n            "
              ),
            ]
          ),
          _vm._v(" "),
          _c("combo-component", {
            directives: [
              {
                name: "validate",
                rawName: "v-validate",
                value: "required",
                expression: "'required'",
              },
            ],
            attrs: {
              id: "kitEmbalagem" + _vm.index,
              name: "kitEmbalagem" + _vm.index,
              data: _vm.kitEmbalagensOptions,
              disabled: _vm.somenteConsulta,
            },
            model: {
              value: _vm.model.kitEmbalagemId,
              callback: function ($$v) {
                _vm.$set(_vm.model, "kitEmbalagemId", $$v)
              },
              expression: "model.kitEmbalagemId",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm.model.kitEmbalagemId
        ? _c("div", { staticClass: "table-body crud-item-grid" }, [
            _c(
              "table",
              { staticClass: "table table-condensed table-striped" },
              [
                _c("thead", [
                  _c("tr", [
                    _c("th", { staticClass: "col-sm-5" }, [
                      _vm._v(
                        "\n                            " +
                          _vm._s(
                            _vm.$t(
                              "__.Crud.produtolote.fracionamentoKitEmbalagem_vue_html.embalagem"
                            )
                          ) +
                          "\n                        "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("th", { staticClass: "col-sm-3" }, [
                      _vm._v(
                        "\n                            " +
                          _vm._s(
                            _vm.$t(
                              "__.Crud.produtolote.fracionamentoKitEmbalagem_vue_html.embalagemLote"
                            )
                          ) +
                          "\n                        "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("th", { staticClass: "col-sm-2" }, [
                      _vm._v(
                        "\n                            " +
                          _vm._s(
                            _vm.$t(
                              "__.Crud.produtolote.fracionamentoKitEmbalagem_vue_html.quantidade"
                            )
                          ) +
                          "\n                        "
                      ),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "tbody",
                  [
                    _vm._l(
                      _vm.model.itensEmbalagens,
                      function (item, indexEmbalagem) {
                        return [
                          _c("fracionamentoKitEmbalagemItemComponent", {
                            key: indexEmbalagem,
                            ref: "indexEmbalagemItem",
                            refInFor: true,
                            attrs: {
                              item: item,
                              index: _vm.index * indexEmbalagem,
                              produtoOptions: _vm.produtoOptions,
                              somenteConsulta: _vm.somenteConsulta,
                            },
                            on: {
                              onChangeItemEmbalagem: function (itemEmbalagem) {
                                return _vm.onChangeItemEmbalagem(
                                  itemEmbalagem,
                                  item
                                )
                              },
                            },
                          }),
                        ]
                      }
                    ),
                  ],
                  2
                ),
              ]
            ),
          ])
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }