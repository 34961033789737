var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.nota.modelo == 55 && !_vm.emissaoVenda
    ? _c(
        "fieldset-component",
        { attrs: { title: "Retenções", collapsed: true } },
        [
          _c("div", { attrs: { slot: "rows" }, slot: "rows" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-sm-4" }, [
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c(
                      "label",
                      {
                        staticClass: "control-label",
                        attrs: { for: "valorPIS" },
                      },
                      [_vm._v("Valor retenção de PIS")]
                    ),
                    _vm._v(" "),
                    _c("moeda-component", {
                      staticClass: "form-control",
                      attrs: {
                        id: "valorPIS",
                        name: "valorPIS",
                        disabled: _vm.consulta,
                      },
                      model: {
                        value: _vm.model.valorPIS,
                        callback: function ($$v) {
                          _vm.$set(_vm.model, "valorPIS", $$v)
                        },
                        expression: "model.valorPIS",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-sm-4" }, [
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c(
                      "label",
                      {
                        staticClass: "control-label",
                        attrs: { for: "valorCOFINS" },
                      },
                      [_vm._v("Valor retenção de COFINS")]
                    ),
                    _vm._v(" "),
                    _c("moeda-component", {
                      staticClass: "form-control",
                      attrs: {
                        id: "valorCOFINS",
                        name: "valorCOFINS",
                        disabled: _vm.consulta,
                      },
                      model: {
                        value: _vm.model.valorCOFINS,
                        callback: function ($$v) {
                          _vm.$set(_vm.model, "valorCOFINS", $$v)
                        },
                        expression: "model.valorCOFINS",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-sm-4" }, [
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c(
                      "label",
                      {
                        staticClass: "control-label",
                        attrs: { for: "valorCSLL" },
                      },
                      [_vm._v("Valor retenção de CSLL")]
                    ),
                    _vm._v(" "),
                    _c("moeda-component", {
                      staticClass: "form-control",
                      attrs: {
                        id: "valorCSLL",
                        name: "valorCSLL",
                        disabled: _vm.consulta,
                      },
                      model: {
                        value: _vm.model.valorCSLL,
                        callback: function ($$v) {
                          _vm.$set(_vm.model, "valorCSLL", $$v)
                        },
                        expression: "model.valorCSLL",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-sm-3" }, [
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c(
                      "label",
                      {
                        staticClass: "control-label",
                        attrs: { for: "valorBaseIRRF" },
                      },
                      [_vm._v("Valor base IRRF")]
                    ),
                    _vm._v(" "),
                    _c("moeda-component", {
                      staticClass: "form-control",
                      attrs: {
                        id: "valorBaseIRRF",
                        name: "valorBaseIRRF",
                        disabled: _vm.consulta,
                      },
                      model: {
                        value: _vm.model.valorBaseIRRF,
                        callback: function ($$v) {
                          _vm.$set(_vm.model, "valorBaseIRRF", $$v)
                        },
                        expression: "model.valorBaseIRRF",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-sm-3" }, [
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c(
                      "label",
                      {
                        staticClass: "control-label",
                        attrs: { for: "valorIRRF" },
                      },
                      [_vm._v("Valor retenção de IRRF")]
                    ),
                    _vm._v(" "),
                    _c("moeda-component", {
                      staticClass: "form-control",
                      attrs: {
                        id: "valorIRRF",
                        name: "valorIRRF",
                        disabled: _vm.consulta,
                      },
                      model: {
                        value: _vm.model.valorIRRF,
                        callback: function ($$v) {
                          _vm.$set(_vm.model, "valorIRRF", $$v)
                        },
                        expression: "model.valorIRRF",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-sm-3" }, [
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c(
                      "label",
                      {
                        staticClass: "control-label",
                        attrs: { for: "valorBasePrev" },
                      },
                      [_vm._v("Valor base de Previdencia Social")]
                    ),
                    _vm._v(" "),
                    _c("moeda-component", {
                      staticClass: "form-control",
                      attrs: {
                        id: "valorBasePrev",
                        name: "valorBasePrev",
                        disabled: _vm.consulta,
                      },
                      model: {
                        value: _vm.model.valorBasePrev,
                        callback: function ($$v) {
                          _vm.$set(_vm.model, "valorBasePrev", $$v)
                        },
                        expression: "model.valorBasePrev",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-sm-3" }, [
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c(
                      "label",
                      {
                        staticClass: "control-label",
                        attrs: { for: "valorPrev" },
                      },
                      [_vm._v("Valor retenção de Previdencia Social")]
                    ),
                    _vm._v(" "),
                    _c("moeda-component", {
                      staticClass: "form-control",
                      attrs: {
                        id: "valorPrev",
                        name: "valorPrev",
                        disabled: _vm.consulta,
                      },
                      model: {
                        value: _vm.model.valorPrev,
                        callback: function ($$v) {
                          _vm.$set(_vm.model, "valorPrev", $$v)
                        },
                        expression: "model.valorPrev",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }