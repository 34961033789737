var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "fast-action-grid-panel" },
    [
      _c("div", { staticClass: "fast-action-grid-panel-left marcaDagua" }, [
        _c("div", { staticClass: "component-body" }, [
          _c(
            "div",
            {
              staticClass: "row",
              staticStyle: { "margin-left": "0px", "margin-right": "15px" },
            },
            [
              _c("div", { staticClass: "top-infos" }, [
                _c("span", { staticClass: "small" }, [
                  _vm._v(_vm._s(_vm.totalEmFracionamento)),
                ]),
                _vm._v(
                  "\n                    Em Fracionamento\n                    "
                ),
                _c(
                  "span",
                  {
                    staticClass: "small",
                    staticStyle: { "margin-left": "45px" },
                  },
                  [_vm._v(_vm._s(_vm.totalAguardandoLiberacao))]
                ),
                _vm._v(
                  "\n                    Aguardando Liberação\n                "
                ),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "row", staticStyle: { "margin-right": "15px" } },
            [
              _c(
                "div",
                {
                  staticClass: "col-sm-6",
                  staticStyle: { "padding-left": "15px", "padding-right": "0" },
                },
                [
                  _c(
                    "a",
                    {
                      class: _vm.lotesEmFracionamento
                        ? "btnHomeFinanceiro active"
                        : "btnHomeFinanceiro",
                      attrs: { href: "#" },
                      on: { click: _vm.onClickEmFracionamento },
                    },
                    [
                      _c("i", { staticClass: "fa fa-exchange-alt" }),
                      _vm._v(
                        "\n                        Em Fracionamento\n                    "
                      ),
                    ]
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-sm-6", staticStyle: { padding: "0" } },
                [
                  _c(
                    "a",
                    {
                      class: _vm.lotesAguardandoLiberacao
                        ? "btnHomeFinanceiro active"
                        : "btnHomeFinanceiro",
                      attrs: { href: "#" },
                      on: { click: _vm.onClickAguardandoLiberacao },
                    },
                    [
                      _c("i", { staticClass: "fa fa-cube" }),
                      _vm._v(
                        "\n                        Aguardando Liberação\n                    "
                      ),
                    ]
                  ),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "row", staticStyle: { "margin-right": "0px" } },
            [
              _c(
                "div",
                { staticClass: "col-md-12" },
                _vm._l(_vm.lotes.slice(0, 10), function (item) {
                  return _c(
                    "a",
                    {
                      key: item.id,
                      staticClass: "list-group-item",
                      staticStyle: { position: "relative" },
                    },
                    [
                      _c("div", { staticStyle: { cursor: "pointer" } }, [
                        _vm._v(
                          "\n                            Lote:\n                            "
                        ),
                        _c("b", { staticStyle: { color: "yellow" } }, [
                          _vm._v(_vm._s(item.descricao)),
                        ]),
                        _vm._v(
                          "\n                            / Produto:\n                            "
                        ),
                        _c("b", { staticStyle: { color: "yellow" } }, [
                          _vm._v(_vm._s(item.produtoDescricao)),
                        ]),
                        _vm._v(
                          "\n                            / Fornecedor:\n                            "
                        ),
                        _c("b", { staticStyle: { color: "yellow" } }, [
                          _vm._v(_vm._s(item.fornecedorNome)),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { attrs: { id: "container-icon" } }, [
                        _c("div", { staticClass: "container-home-icon" }, [
                          _c(
                            "div",
                            {
                              staticClass: "home-icon bg-blue",
                              attrs: {
                                "data-toggle": "tooltip",
                                "data-placement": "left",
                                title: "Visualizar",
                              },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  _vm.onVisualizar(item)
                                },
                              },
                            },
                            [
                              _c("i", {
                                staticClass: "fa fa-eye",
                                attrs: { "aria-hidden": "true" },
                              }),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "home-icon bg-light-blue",
                              attrs: {
                                "data-toggle": "tooltip",
                                "data-placement": "left",
                                title: "Atualizar status",
                              },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  _vm.onAtualizarStatus(item)
                                },
                              },
                            },
                            [
                              _c("i", {
                                staticClass: "fa fa-sync",
                                attrs: { "aria-hidden": "true" },
                              }),
                            ]
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            attrs: {
                              id: "icon-button",
                              "data-toggle": "tooltip",
                              "data-placement": "left",
                              "data-original-title": "Create",
                            },
                          },
                          [
                            _c("p", { staticClass: "plus" }, [_vm._v("+")]),
                            _vm._v(" "),
                            _c("div", { staticClass: "edit" }, [
                              _c(
                                "p",
                                {
                                  staticClass: "icon",
                                  on: {
                                    click: function ($event) {
                                      _vm.dragAndDrop = true
                                    },
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass: "fa fa-question",
                                    attrs: { "aria-hidden": "true" },
                                  }),
                                ]
                              ),
                            ]),
                          ]
                        ),
                      ]),
                    ]
                  )
                })
              ),
              _vm._v(" "),
              _c("div", { staticClass: "col-md-12" }, [
                _c(
                  "a",
                  {
                    staticClass: "ver-mais list-group-item",
                    attrs: { type: "button" },
                    on: { click: _vm.onVerMais },
                  },
                  [_vm._v("Ver mais")]
                ),
              ]),
            ]
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("atualizarStatusComponent", {
        ref: "atualizarStatusComponent",
        on: { onStatusSucesso: _vm.loadProdutoLote },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }