import { GridAction } from "@/components/child/grid/gridAction";
import { GridColors, GridColumn, GridColumnType } from "@/components/child/grid/gridColumn";
import { Component } from "@/decorators";
import ContaReceberModel from "@/models/financeiro/contaReceberModel";
import PaginationModel from "@/models/paginationModel";
import VendaModel from "@/models/vendaModel";
import ContaReceberService from "@/services/financeiro/contaReceberService";

import ListComponentBase from "../listComponentBase";

import "../crud.scss";

@Component({})
export default class ContaVendasListComponent extends ListComponentBase<ContaReceberModel> {
    private contaReceberService = new ContaReceberService();
    isHidden = false;

    extraActions: Array<object> = [];
    private extraActionsView: Array<GridAction> = [];
    private extraActionsEdit: Array<GridAction> = [];

    get gridColumns(): Array<GridColumn> {
        return [
            new GridColumn("codigoVenda", "Cód. Venda", GridColumnType.Integer, false, false, "", "", true),
            new GridColumn("cliente", "Cliente", GridColumnType.String, false, false, "", "", true, false),
            new GridColumn("situacaoVenda", "Situação", GridColumnType.String, false, false, "", "", true),
            new GridColumn("valor", "Valor", GridColumnType.Money, false, false, "", "", true),
            new GridColumn("valorPago", "Valor Pago", GridColumnType.Money, false, false, "", "", false),
            new GridColumn("dataVencimento", "Data Vencimento", GridColumnType.Date, false, false, "", "", true),
            new GridColumn(
                "contaGerencialDescricao",
                "Conta Gerencial",
                GridColumnType.String,
                false,
                false,
                "",
                "",
                true,
                false,
            ),
        ];
    }

    private beforeMount() {
        this.setProps(new ContaReceberService(), "contareceber", "dataVencimento");
    }

    public async loadMethod() {
        try {
            this.isHidden = false;

            return await this.contaReceberService
                .listVendas(
                    this.gridFilterKey,
                    this.gridSortKey,
                    this.gridSortOrder,
                    this.pags ? 1 : this.pageIndex,
                    this.pags ? 999999 : this.pageSize,
                )
                .withLoading()
                .resolveWithJSON<PaginationModel<ContaReceberModel>>();
        } catch {}
    }

    public async afterLoad() {
        this.extraActions.splice(0);
        this.extraActionsView.splice(0);
        this.extraActionsEdit.splice(0);

        for (let i = 0; i < this.gridData.length; i++) {
            this.extraActionsView.push(
                new GridAction("visualizar-venda", "Visualizar Venda", "fa fa-eye", GridColors.BLUE, true),
            );

            if (this.gridData[i].contaReceberId) {
                this.extraActionsEdit.push(new GridAction("editar", "Editar", "fa fa-edit", GridColors.BLUE, true));
            }
        }

        this.extraActions.push(this.extraActionsView);
        this.extraActions.push(this.extraActionsEdit);
    }

    private onExtraActions(name: string, model: VendaModel) {
        if (name.trim() == "editar") {
            this.$router.push("/contareceber-edicao/" + model.contaReceberId);
        }

        if (name.trim() == "visualizar-venda") {
            window.open("/venda-edicao/" + model.id, "_blank");
        }
    }
}
