import BaseModel from "./base/baseModel";
import GrupoTelaAcaoModel from "./grupoTelaAcaoModel";
import GrupoUsuarioModel from "./grupoUsuarioModel";
import TelaAcaoModel from "./telaAcaoModel";

export default class GrupoModel extends BaseModel {
    id?: number = null;
    descricao: string = null;
    ativo = true;
    grupoTelaAcoes: Array<GrupoTelaAcaoModel> = [];
    grupoUsuarios: Array<GrupoUsuarioModel> = [];

    // Campos usados para validação de acesso do usuário no frontend
    telaAcoes: Array<TelaAcaoModel> = [];
}
