var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._l(_vm.indexs, function (index, indexArray) {
        return [
          _c("rotuloPersonalizadoComponent", {
            key: indexArray + index,
            ref: "index",
            refInFor: true,
            attrs: { CalledByShortCut: true, collapsedCamposImpressoes: true },
          }),
        ]
      }),
      _vm._v(" "),
      _c(
        "div",
        { ref: "renderRotulosRef", staticStyle: { display: "none" } },
        _vm._l(_vm.rotulos, function (rotulo, index) {
          return _c("rotuloComponent", {
            key: index,
            attrs: { model: rotulo, CalledByShortCut: true },
          })
        })
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }