import Vue from "vue";

import { Component, Prop } from "@/decorators";

import "./datatooltip.scss";

@Component
export default class DataTooltipComponent extends Vue {
    @Prop(String) text: string;
    @Prop({ type: String, default: "fa-info-circle" }) icon: string;
    @Prop({ type: String, default: "#2196f3" }) iconColor: string;
}
