import FormaRecebimentoModel from "@/models/financeiro/formaRecebimentoModel";
import nfetch from "@/utils/events/nfetch";

import { IService } from "./../base/iService";

export default class FormaRecebimentoService extends IService<FormaRecebimentoModel> {
    constructor() {
        super("FormaRecebimento");
    }

    public comboByEspecie(especie = -1): Promise<Response> {
        return nfetch(`/${this.controllerName}/List?especie=${especie}&listarInativos=false`, {
            credentials: "same-origin",
        });
    }

    public async buscarRecebimentosByMetodoPagamento(
        formaRecebimentoId: number,
        metodoPagamentoXFormaId: number,
    ): Promise<Response> {
        return nfetch(
            `/${this.controllerName}/BuscaRecebimentoByMetodoPagamento?formaRecebimentoId=${formaRecebimentoId}&metodoPagamentoXFormaId=${metodoPagamentoXFormaId}`,
            {
                credentials: "same-origin",
            },
        );
    }
}
