import nfetch from "../utils/events/nfetch";

import { getCookie } from "./utils/serviceUtils";

export default class AtualizacaoValoresService {
    private controllerName = "AtualizacaoValores";

    public atualizacaoValoresProduto(produtosIds: number[], percentualAtualizacao: number): Promise<Response> {
        return nfetch(
            `/${this.controllerName}/AtualizacaoValoresProduto?percentualAtualizacao=${percentualAtualizacao}`,
            {
                method: "post",
                credentials: "same-origin",
                headers: {
                    "Content-Type": "application/json; charset=UTF-8",
                    "XSRF-TOKEN": getCookie("XSRF-TOKEN"),
                },
                body: JSON.stringify(produtosIds),
            },
        );
    }

    public atualizacaoValoresFormaFarmaceutica(
        formasFarmaceuticasIds: number[],
        percentualValorMinimo: number,
        percentualValorAdicional: number,
    ): Promise<Response> {
        return nfetch(
            `/${this.controllerName}/AtualizacaoValoresFormaFarmaceutica?percentualValorMinimo=${percentualValorMinimo}&percentualValorAdicional=${percentualValorAdicional}`,
            {
                method: "post",
                credentials: "same-origin",
                headers: {
                    "Content-Type": "application/json; charset=UTF-8",
                    "XSRF-TOKEN": getCookie("XSRF-TOKEN"),
                },
                body: JSON.stringify(formasFarmaceuticasIds),
            },
        );
    }
}
