import Vue from "vue";

import dropdownComponent from "@/components/child/dropdown/dropdown.vue";
import { Component, Prop } from "@/decorators";
import RascunhoModel from "@/models/rascunhoModel";
import RascunhoService from "@/services/rascunhoService";

@Component({
    components: {
        dropdownComponent,
    },
})
export default class RascunhoComponent extends Vue {
    @Prop(String) entity: string;

    rascunhos: RascunhoModel[] = [];
    service = new RascunhoService();

    private async mounted() {
        this.rascunhos = await this.service.list(this.entity).resolveWithJSON<RascunhoModel[]>();
    }

    public async saveRascunho(data: any) {
        const response = await this.$showAlert({
            title: "Insira um nome para o rascunho",
            icon: "info",
            showCancelButton: true,
            confirmButtonText: "Salvar",
            cancelButtonText: "Fechar",
            input: "textarea",
            inputValidator: result => {
                if (!result) {
                    return "Necessário informar um nome.";
                }
                return "";
            },
        });

        if (response.isConfirmed) {
            this.rascunhos.push();

            const rascunhoModel = new RascunhoModel();
            rascunhoModel.entidade = this.entity;
            rascunhoModel.nome = response.value;
            rascunhoModel.data = JSON.stringify(data);

            const sucesso = await this.service.insert(rascunhoModel).resolveWithoutJSON();

            if (sucesso) {
                this.rascunhos = await this.service.list(this.entity).resolveWithJSON<RascunhoModel[]>();
            }
        }
    }

    public async save() {
        this.$emit("onSaveRascunho");
    }

    private onLoad(nome: string) {
        const rascunho = this.rascunhos.find(p => p.nome === nome);

        this.$emit("onLoadRascunho", JSON.parse(rascunho.data));
    }

    private async onDelete(nome: string) {
        const rascunho = this.rascunhos.find(p => p.nome === nome);

        if (await this.service.delete(rascunho.id).resolveWithoutJSON()) {
            this.rascunhos = this.rascunhos.filter(p => p.nome !== nome);
        }
    }
}
