import Vue from "vue";
import { mapState } from "vuex";

import buttonIncluirComponent from "@/components/child/form/button/buttonIncluir.vue";
import checkboxComponent from "@/components/child/form/checkbox.vue";
import comboComponent from "@/components/child/form/combo.vue";
import dataTooltipComponent from "@/components/child/form/datatooltip.vue";
import dateTimePickerComponent from "@/components/child/form/datetimepicker.vue";
import decimalComponent from "@/components/child/form/decimal.vue";
import FieldsetComponent from "@/components/child/form/fieldset";
import fieldsetComponent from "@/components/child/form/fieldset.vue";
import { Component, Watch } from "@/decorators";
import PreLoadPackModel from "@/models/auxiliar/preLoadPackModel";
import TiposCalculoManipulacao from "@/models/enum/tiposCalculoManipulacao";
import FormaFarmaceuticaItemPadraoModel from "@/models/formaFarmaceuticaItemPadraoModel";
import ProdutoModel from "@/models/produto/produtoModel";
import ProdutoService from "@/services/produto/produtoService";
import { AppState } from "@/store/store";
import { getProdutoComboText } from "@/utils/common/combo/combotext";

import "./item.scss";

@Component({
    components: {
        comboComponent,
        decimalComponent,
        fieldsetComponent,
        dateTimePickerComponent,
        checkboxComponent,
        dataTooltipComponent,
        buttonIncluirComponent,
    },
    computed: mapState<AppState>({
        preLoadList: state => state.preLoad.preLoadList,
    }),
})
export default class FormaFarmaceuticaItemComponent extends Vue {
    // State computed props
    preLoadList: PreLoadPackModel;

    private fieldsetComponent: FieldsetComponent = null;

    private produtoService = new ProdutoService();
    private preloadPack: PreLoadPackModel = null;
    model = new FormaFarmaceuticaItemPadraoModel();

    produtosOptions: Array<Object> = [];

    tiposCalculoOptions: Array<Object> = [
        { text: "Nenhum", value: TiposCalculoManipulacao.Nenhum },
        { text: "Unitário", value: TiposCalculoManipulacao.Unitario },
        { text: "Dose", value: TiposCalculoManipulacao.Dose },
        { text: "Percentual", value: TiposCalculoManipulacao.Percentual },
        { text: "Qsp", value: TiposCalculoManipulacao.Qsp },
    ];

    subGrupoOptions: Array<Object> = [
        { text: "Ativo", value: 0 },
        { text: "Flavorizante", value: 1 },
        { text: " Veículos/Excipientes", value: 2 },
    ];

    private loadProdutos() {
        this.getPreLoadPack();
    }

    private incluirItem() {
        this.$validator
            .validateAll()
            .then(isValid => {
                if (isValid) {
                    this.$emit("inclusao-item", this.getModel());
                }
            })
            .catch(() => {});
    }

    private getModel() {
        const model = new FormaFarmaceuticaItemPadraoModel();
        model.updateFrom(this.model);
        //add descricoes
        if (this.model.subGrupo >= 0) {
            const sGrupo = this.subGrupoOptions.filter(p => p["value"] == this.model.subGrupo);
            if (sGrupo.length > 0) {
                model.subGrupoDescricao = sGrupo[0]["text"];
            }
        }
        if (this.model.tipoCalculo >= 0) {
            const tipoCalc = this.tiposCalculoOptions.filter(p => p["value"] == this.model.tipoCalculo);
            if (tipoCalc.length > 0) {
                model.tipoCalculoDescricao = tipoCalc[0]["text"];
            }
        }

        return model;
    }

    public clear() {
        this.initializeModel();
    }

    private initializeModel() {
        this.model = null;
        this.model = new FormaFarmaceuticaItemPadraoModel();
        this.model.tipoCalculo = TiposCalculoManipulacao.Nenhum;
        this.model.quantidade = 0;
        this.model.produtoDescricao = "";
        this.model.subGrupo = null;
        this.model.produtoId = null;
        this.model.desmembrado = false;
    }

    //@ts-ignore
    @Watch("model.tipoItem")
    private onTipoItemChanged() {
        this.loadProdutos();
    }

    //@ts-ignore
    @Watch("model.produtoId")
    private onProdutoIdChanged(newValue: number) {
        if (!newValue || newValue <= 0) {
            return;
        }

        this.carregarProduto();
    }

    //@ts-ignore
    @Watch("model.tipoCalculo")
    private onTipoCalculoChanged() {
        if (this.model.tipoCalculo == TiposCalculoManipulacao.Qsp) {
            this.model.quantidade = 1;
        }
    }

    ///talvez nao seja necessario, pode trazer estas infos do list ?
    private async carregarProduto() {
        try {
            const data = await this.produtoService
                .get(this.model.produtoId)
                .withLoading()
                .resolveWithJSON<ProdutoModel>();

            this.model.produtoDescricao = data.descricao;
            if (data.tipoCalculo) {
                this.model.tipoCalculo = data.tipoCalculo;

                if (this.model.tipoCalculo == TiposCalculoManipulacao.Qsp) {
                    this.model.quantidade = 1;
                }
            }

            this.model.subGrupo = data.subGrupo;
        } catch {}
    }

    //@ts-ignore
    @Watch("model.subGrupo")
    private onSubGrupoChanged(newValue) {
        const valueValid = newValue != null;
        if (
            !this.produtosOptions.some(
                p => p["value"] == this.model.produtoId && (p["subGrupo"] == newValue || !valueValid),
            )
        ) {
            this.model.produtoId = 0;
        }

        this.produtosOptions = this.preloadPack.produtos
            .filter(p => p.subGrupo != null && p.subGrupo >= 0 && (p.subGrupo == newValue || !valueValid))
            .map(item => ({
                value: item.id,
                text: getProdutoComboText(item),
                subGrupo: item.subGrupo,
            }));
    }

    private async getPreLoadPack() {
        this.preloadPack = new PreLoadPackModel();
        this.preloadPack.produtos = this.preLoadList.produtos;
        this.produtosOptions = this.preloadPack.produtos
            .filter(p => p.subGrupo != null && p.subGrupo >= 0)
            .map(item => ({
                value: item.id,
                text: getProdutoComboText(item),
                subGrupo: item.subGrupo,
            }));
    }

    private mounted() {
        this.fieldsetComponent = this.$refs.fieldsetComponent as FieldsetComponent;
        this.preloadPack = null;
        this.initializeModel();

        Promise.all([this.getPreLoadPack()])
            .withLoading()
            .then(() => {})
            .catch(() => {});
    }
}
