import BaseModel from "./base/baseModel";
import TipoDesconto from "./enum/TipoDesconto";

export default class CupomModel extends BaseModel {
    constructor() {
        super();
    }

    id?: number = null;
    dataCriacao: Date = null;
    dataModificacao: Date = null;
    usuarioCriacaoId: number = null;
    usuarioModificacaoId: number = null;
    descricao: string = null;
    cupom: string = null
    tipoDesconto: TipoDesconto = TipoDesconto.Dinheiro;
    valorDesconto: number = null
    validadeInicio: Date = new Date()
    validadeFim: Date = new Date()
}