import { mapGetters, mapState } from "vuex";

import { GridColumn, GridColumnType } from "@/components/child/grid/gridColumn";
import { Component } from "@/decorators";
import ControleReclamacaoModel from "@/models/controleReclamacaoModel";
import ControleReclamacaoService from "@/services/controleReclamacaoService";
import { AppState, Getters } from "@/store/store";

import ListComponentBase from "../listComponentBase";

@Component({
    computed: {
        ...mapState({
            preLoadList: (state: AppState) => state.preLoad.preLoadList,
        }),
        ...mapGetters(["GET_IS_FRACIONAMENTO", "GET_IS_ESTEREIS"] as Getters),
    },
})
export default class ControleReclamacaoListComponent extends ListComponentBase<ControleReclamacaoModel> {
    // State computed props
    GET_IS_FRACIONAMENTO: () => Promise<boolean>;
    GET_IS_ESTEREIS: () => Promise<boolean>;

    private isFracionamento = false;
    private isEstereis = false;

    get gridColumns(): Array<GridColumn> {
        const columns = [];
        columns.push(new GridColumn("dataReclamacao", "Data Reclamação", GridColumnType.Date));
        columns.push(new GridColumn("clienteNome", this.$t("__.ts.cliente"), GridColumnType.String));
        columns.push(new GridColumn("tipoDescricao", this.$t("__.ts.tipo"), GridColumnType.String));
        columns.push(new GridColumn("vendaCodigo", this.$t("__.ts.codVenda"), GridColumnType.String));
        columns.push(new GridColumn("reclamacao", this.$t("__.ts.reclamacao"), GridColumnType.String));
        columns.push(new GridColumn("acao", this.$t("__.ts.acao"), GridColumnType.String));
        columns.push(
            new GridColumn("categoriaControleReclamacaoDescricao", this.$t("__.ts.categoria"), GridColumnType.String),
        );
        columns.push(
            new GridColumn(
                "justificativaControleReclamacaoDescricao",
                this.$t("__.ts.justificativa"),
                GridColumnType.String,
            ),
        );
        columns.push(new GridColumn("statusDescricao", this.$t("__.ts.status"), GridColumnType.String));
        if (this.isEstereis || this.isFracionamento) {
            columns.push(
                new GridColumn(
                    "setor",
                    this.$t("__.ts.setor"),
                    GridColumnType.String,
                    false,
                    false,
                    "",
                    "",
                    true,
                    false,
                ),
            );
        }
        columns.push(new GridColumn("produtoLoteDescricao", this.$t("__.ts.lote"), GridColumnType.String));

        return columns;
    }

    private beforeMount() {
        this.setProps(new ControleReclamacaoService(), "controlereclamacao", "datareclamacao", false, "desc");
    }

    public async afterMounted() {
        this.isFracionamento = await this.GET_IS_FRACIONAMENTO();
        this.isEstereis = await this.GET_IS_ESTEREIS();
    }
}
