import nfetch from "@/utils/events/nfetch";

import EtapaPCPModel from "../models/pcp/etapaPCPModel";

import { IService } from "./base/iService";

export default class EtapaPCPService extends IService<EtapaPCPModel> {
    constructor() {
        super("EtapaPCP");
    }

    public override combo(somenteAtivos = false): Promise<Response> {
        return nfetch(`/${this.controllerName}/List?somenteAtivos=${somenteAtivos}`, {
            credentials: "same-origin",
        });
    }
}
