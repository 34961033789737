import Vue from "vue";
import { mapGetters, mapState } from "vuex";

import buttonIncluirComponent from "@/components/child/form/button/buttonIncluir.vue";
import checkboxComponent from "@/components/child/form/checkbox.vue";
import comboComponent from "@/components/child/form/combo.vue";
import dataTooltipComponent from "@/components/child/form/datatooltip.vue";
import decimalComponent from "@/components/child/form/decimal.vue";
import decimalComSinalCustomComponent from "@/components/child/form/decimalComSinalCustom.vue";
import fieldsetComponent from "@/components/child/form/fieldset.vue";
import moedaComponent from "@/components/child/form/moeda.vue";
import gridComponent from "@/components/child/grid/grid.vue";
import { GridColumn, GridColumnType } from "@/components/child/grid/gridColumn";
import { Component, Prop, Watch } from "@/decorators";
import PreLoadPackModel from "@/models/auxiliar/preLoadPackModel";
import StatusProdutoLote from "@/models/enum/statusProdutoLote";
import NotaFiscalEntradaItemImposto from "@/models/notaFiscalEntradaItemImpostoModel";
import NotaFiscalEntradaItem from "@/models/notaFiscalEntradaItemModel";
import PaginationModel from "@/models/paginationModel";
import ProdutoModel from "@/models/produto/produtoModel";
import ProdutoLoteModel from "@/models/produtoLoteModel";
import IndicesConversoesUnidadeMedidasService from "@/services/indicesConversoesUnidadeMedidaService";
import ProdutoService from "@/services/produto/produtoService";
import ProdutoLoteService from "@/services/produtoLoteService";
import { AppState, Getters } from "@/store/store";
import arithmeticHelper from "@/utils/common/arithmeticHelper";
import { getProdutoCombo, getProdutoLoteCombo } from "@/utils/common/combo/combotext";
import { copyObject } from "@/utils/common/copyObject";
import Delay from "@/utils/common/delay";

import ProdutoLoteComponent from "../crud/produtolote/edit";
import produtoLoteComponent from "../crud/produtolote/edit.vue";

import "../crud/crud.scss";

@Component({
    components: {
        gridComponent,
        comboComponent,
        checkboxComponent,
        moedaComponent,
        decimalComponent,
        fieldsetComponent,
        produtoLoteComponent,
        decimalComSinalCustomComponent,
        buttonIncluirComponent,
        dataTooltipComponent,
    },
    computed: {
        ...mapState<AppState>({
            preLoadPack: state => state.preLoad.preLoadList,
            loadedList: state => state.preLoad.loadedList,
        }),
        ...mapGetters(["GET_IS_FRACIONAMENTO"] as Getters),
    },
})
export default class ItemListComponent extends Vue {
    // State computed props
    preLoadPack: PreLoadPackModel;
    loadedList: boolean;
    GET_IS_FRACIONAMENTO: () => Promise<boolean>;

    public showInfos = false;
    private modelAdd: NotaFiscalEntradaItem = new NotaFiscalEntradaItem();
    private produtoLoteComponent: ProdutoLoteComponent = null;

    private indicesConversoesUnidadeMedidasService = new IndicesConversoesUnidadeMedidasService();
    private produtoService = new ProdutoService();
    private produtoLoteService = new ProdutoLoteService();
    private produtoLoteId = 0;

    private nfeItemImposto = new NotaFiscalEntradaItemImposto();

    private editingModel = false;
    private atualizarQuantidadeConvertida = true;
    private indexEdditingModel = -1;

    private produto: ProdutoModel = null;

    private refreshGrid = false;

    private produtosOptions = [];
    private unidadeMedidaOptions = [];
    private produtoLotesOptions = [];

    isFracionamento = false;

    @Prop({ type: Array, required: true }) model;
    @Prop({ type: Boolean, default: false }) ShowAddOptions: boolean;
    @Prop({ type: Number, default: null }) Fornec: number;

    gridFilterKey = "";
    gridSortKey = "numero";
    gridSortOrder = "asc";

    get gridColumns(): Array<GridColumn> {
        return [
            new GridColumn("codigo", "Código", GridColumnType.String),
            new GridColumn("descricao", "Descrição", GridColumnType.String),
            new GridColumn("produtoDescricao", "Produto", GridColumnType.String),
            new GridColumn("dataFabricacao", "Fabricação", GridColumnType.Date),
            new GridColumn("dataValidade", "Validade", GridColumnType.Date),
            new GridColumn("unidadeMedida", "Un. Med.", GridColumnType.String),
            new GridColumn("quantidade", "Quantidade Convertida", GridColumnType.Decimal),
            new GridColumn("valorFrete", "Valor Frete", GridColumnType.Money),
            new GridColumn("valorOutraDespesa", "Valor Outros", GridColumnType.Money),
            new GridColumn("valorSeguro", "Valor Seguro", GridColumnType.Money),
            new GridColumn("valorDesconto", "Valor Desconto", GridColumnType.Money),
            new GridColumn("valorUnitario", "Valor Unitário Convertido", GridColumnType.Money),
            new GridColumn("valorTotal", "Valor Total", GridColumnType.Money),
        ];
    }

    subDataName = "notaFiscalEntradaItemImpostos";

    gridColumnsImpostos: Array<GridColumn> = [
        new GridColumn("imposto", "Imposto", GridColumnType.String),
        new GridColumn("percentual", "Percentual", GridColumnType.Percent),
        new GridColumn("valorBaseCalculo", "Base de cálculo", GridColumnType.Money),
        new GridColumn("valorImposto", "Valor", GridColumnType.Money),
    ];

    pageIndex = 1;
    pageSize = 500;
    total = 0;

    private async loadProdutos() {
        try {
            const data = await this.produtoService.comboOnlyAtivos().resolveWithJSON<PaginationModel<ProdutoModel>>();
            this.produtosOptions = data.list.map(getProdutoCombo);
        } catch {}
    }

    private async loadProdutoLotes() {
        try {
            if (this.modelAdd.produtoId) {
                const data = await this.produtoLoteService
                    .listFilterProduto(this.modelAdd.produtoId, 0, this.modelAdd.unidadeMedidaId, 1, 999999)
                    .resolveWithJSON<PaginationModel<ProdutoLoteModel>>();

                this.produtoLotesOptions = data.list.map(item => ({
                    ...getProdutoLoteCombo(item),
                    dataFabricacao: item.dataFabricacao,
                    dataValidade: item.dataValidade,
                }));
            }
        } catch {}
    }

    public clear() {
        this.editingModel = false;
        this.indexEdditingModel = -1;

        this.modelAdd = new NotaFiscalEntradaItem();
    }

    public clearImposto() {
        this.nfeItemImposto = new NotaFiscalEntradaItemImposto();
    }

    private onChangeFilterKey(filterKey: string) {
        this.gridFilterKey = filterKey;
    }

    private onChangeSort(sortKey: string, sortOrder: string) {
        this.gridSortKey = sortKey;
        this.gridSortOrder = sortOrder;
    }

    private onChangePage(pageIndex: number) {
        this.pageIndex = pageIndex;
    }

    private async onAdicionarItem() {
        try {
            const isValid = await this.$validator.validateAll();

            if (isValid) {
                const descricao = this.produtosOptions.find(p => p["value"] == this.modelAdd.produtoId)["text"];
                this.modelAdd.descricao = descricao.replace("((", "(").replace("))", ")");

                const produto = await this.produtoService.get(this.modelAdd.produtoId).resolveWithJSON<ProdutoModel>();
                if (!this.modelAdd.produtoLoteId) {
                    return await this.$showWarning(this.$t("__.ts.atencao"), "Lote não informado");
                }
                this.modelAdd.produtoDescricao = produto.descricao;

                this.modelAdd.unidadeMedida = this.getUnidadeMedida();

                if (this.modelAdd.produtoLoteId) {
                    this.modelAdd.dataFabricacao = this.produtoLotesOptions.find(
                        p => p["value"] == this.modelAdd.produtoLoteId,
                    )["dataFabricacao"];

                    this.modelAdd.dataValidade = this.produtoLotesOptions.find(
                        p => p["value"] == this.modelAdd.produtoLoteId,
                    )["dataValidade"];
                } else {
                    this.modelAdd.produtoLote = null;
                }

                this.modelAdd.produto = null;

                if (this.modelAdd.codigo == null) {
                    this.modelAdd.codigo = "";
                }

                if (!this.editingModel) {
                    this.model.push(this.modelAdd);
                } else {
                    if (this.indexEdditingModel >= 0) {
                        copyObject(this.modelAdd, this.model[this.indexEdditingModel]);
                        this.model[this.indexEdditingModel].unidadeMedida = this.getUnidadeMedida();

                        this.model[this.indexEdditingModel].notaFiscalEntradaItemImpostos.splice(0);

                        for (let i = 0; i < this.modelAdd.notaFiscalEntradaItemImpostos.length; i++) {
                            const imposto = this.modelAdd.notaFiscalEntradaItemImpostos[i];
                            this.model[this.indexEdditingModel].notaFiscalEntradaItemImpostos.push(imposto);
                        }
                        this.refreshGrid = true;
                    }
                }
                //para atualizar valor total itens
                this.$emit("item-changed");

                this.editingModel = false;
                this.indexEdditingModel = -1;
                this.clear();
                this.clearImposto();
            }
        } catch {}
    }

    private onAdicionarImposto() {
        this.modelAdd.notaFiscalEntradaItemImpostos.push(this.nfeItemImposto);
        this.clearImposto();
    }

    private onRemoverImposto(index: number) {
        this.modelAdd.notaFiscalEntradaItemImpostos.splice(index, 1);
    }

    private onCancelar() {
        this.editingModel = false;
        this.indexEdditingModel = -1;
        this.clear();
        this.clearImposto();
    }

    private async onEditItem(entry) {
        this.refreshGrid = false;

        this.editingModel = true;
        this.atualizarQuantidadeConvertida = false;
        this.indexEdditingModel = this.model.indexOf(entry);
        if (this.indexEdditingModel >= 0) {
            this.modelAdd = new NotaFiscalEntradaItem();
            copyObject(this.model[this.indexEdditingModel], this.modelAdd);

            await Delay(500);

            this.modelAdd.produtoLoteId = this.model[this.indexEdditingModel].produtoLoteId;

            for (let i = 0; i < this.model[this.indexEdditingModel].notaFiscalEntradaItemImpostos.length; i++) {
                const imposto = this.model[this.indexEdditingModel].notaFiscalEntradaItemImpostos[i];
                this.modelAdd.notaFiscalEntradaItemImpostos.push(imposto);
            }
        }
    }

    private async onCarregaLote() {
        this.produtoLoteComponent.modelBasePreencherLote = new ProdutoLoteModel();
        this.produtoLoteComponent.modelBasePreencherLote.produtoId = this.modelAdd.produtoId;
        this.produtoLoteComponent.modelBasePreencherLote.fornecedorId = this.Fornec;
        if (this.isFracionamento) {
            this.produtoLoteComponent.modelBasePreencherLote.descricao = await this.produtoLoteService
                .geraDescricaoLote()
                .withLoading()
                .resolveWithText();
            this.produtoLoteComponent.modelBasePreencherLote.statusProdutoLote = StatusProdutoLote.Quarentena;
        } else {
            this.produtoLoteComponent.modelBasePreencherLote.descricao = "Lote";
        }
        this.produtoLoteComponent.load();
    }

    private onRemoveItem(entry) {
        const index = this.model.indexOf(entry);
        this.model.splice(index, 1);
        this.$emit("item-changed");
    }

    private async onAddNewProdutoLote(closeModal) {
        if (await this.produtoLoteComponent.save()) {
            closeModal();
        }
    }

    private async openComboNewProdutoLote() {
        while (!this.$refs.produtoLoteComponent) await Delay(5);
        this.produtoLoteComponent = this.$refs.produtoLoteComponent as ProdutoLoteComponent;

        this.produtoLoteComponent.modelId = 0;
        this.produtoLoteComponent.modelBasePreencherLote = new ProdutoLoteModel();
        this.produtoLoteComponent.modelBasePreencherLote.produtoId = this.modelAdd.produtoId;
        this.produtoLoteComponent.modelBasePreencherLote.fornecedorId = this.Fornec;
        this.produtoLoteComponent.load();
    }

    private async openComboEditProdutoLote(id) {
        while (!this.$refs.produtoLoteComponent) await Delay(5);
        this.produtoLoteComponent = this.$refs.produtoLoteComponent as ProdutoLoteComponent;

        this.produtoLoteComponent.modelId = id;
        this.produtoLoteComponent.load();
    }

    private async onProdutoLoteSaveOk(modelNovoProdutoLote: ProdutoLoteModel) {
        await this.loadProdutoLotes();
        this.modelAdd.produtoId = modelNovoProdutoLote.produtoId;
    }

    conditionShowSubData(it) {
        return it["notaFiscalEntradaItemImpostos"]["length"] > 0;
    }

    // @ts-ignore
    @Watch("modelAdd.quantidadeOrigem")
    private async onQuantidadeOrigemChanged() {
        if (this.atualizarQuantidadeConvertida) {
            await this.calcularQuantidadeConvertida();
            await this.calcularValorUnitarioConvertido();
        }
        this.atualizarQuantidadeConvertida = true;
    }

    // @ts-ignore
    @Watch("modelAdd.valorUnitarioOrigem")
    private async onValorUnitarioOrigemChanged() {
        await this.calcularValorUnitarioConvertido();
        this.calcularValorTotal();
    }

    // @ts-ignore
    @Watch("modelAdd.valorFrete")
    private async onValorFreteChanged() {
        this.calcularValorTotal();
        await this.calcularValorUnitarioConvertido();
    }

    // @ts-ignore
    @Watch("modelAdd.valorSeguro")
    private async onValorSeguroChanged() {
        this.calcularValorTotal();
        await this.calcularValorUnitarioConvertido();
    }

    // @ts-ignore
    @Watch("modelAdd.valorOutraDespesa")
    private async onValorOutrosChanged() {
        this.calcularValorTotal();
        await this.calcularValorUnitarioConvertido();
    }

    // @ts-ignore
    @Watch("modelAdd.valorDesconto")
    private async onDescontoChanged() {
        this.calcularValorTotal();
        await this.calcularValorUnitarioConvertido();
    }

    // @ts-ignore
    @Watch("modelAdd.produtoId")
    private async onProdutoIdChanged() {
        this.produto = new ProdutoModel();
        if (this.modelAdd.produtoId) {
            this.produto = await this.produtoService.get(this.modelAdd.produtoId).resolveWithJSON<ProdutoModel>();

            if (this.produto) {
                this.modelAdd.unidadeMedidaId = this.produto.unidadeMedidaEstoqueId;
                this.modelAdd.unidadeMedidaSigla = this.getUnidadeMedida();
            }
        }
        this.loadProdutoLotes();
    }

    // @ts-ignore
    @Watch("modelAdd.produtoLoteId")
    private async onProdutoLoteId() {
        this.produtoLoteId = this.modelAdd.produtoLoteId;

        if (this.modelAdd.produtoLoteId) {
            this.modelAdd.produtoLote = await this.produtoService
                .get(this.modelAdd.produtoId)
                .resolveWithJSON<ProdutoLoteModel>();
        }
    }

    // @ts-ignore
    @Watch("modelAdd.unidadeMedidaOrigemId")
    private async onUnidadeMedidaOrigemIdChanged() {
        await this.loadProdutoLotes();
        await this.calcularValorUnitarioConvertido();
    }

    // @ts-ignore
    @Watch("modelAdd.quantidade")
    private async onQuantidadeChanged() {
        this.calcularValorTotal();
        await this.calcularValorUnitarioConvertido();
    }

    // @ts-ignore
    @Watch("modelAdd.valorUnitario")
    private onValorUnitarioChanged() {
        this.calcularValorTotal();
    }

    private async calcularQuantidadeConvertida() {
        if (this.produto) {
            const indice = await this.getIndice();
            this.modelAdd.quantidade = this.modelAdd.quantidadeOrigem * indice;
        }
    }

    private async calcularValorUnitarioConvertido() {
        if (this.produto) {
            this.modelAdd.valorUnitario = arithmeticHelper.round(
                (this.modelAdd.valorUnitarioOrigem * this.modelAdd.quantidadeOrigem) / this.modelAdd.quantidade +
                    (this.modelAdd.valorFrete +
                        this.modelAdd.valorOutraDespesa +
                        this.modelAdd.valorSeguro -
                        this.modelAdd.valorDesconto) /
                        this.modelAdd.quantidade,
                4,
            );
        }
    }

    private calcularValorTotal() {
        this.modelAdd.valorTotal = arithmeticHelper.round(
            this.modelAdd.valorUnitarioOrigem * this.modelAdd.quantidadeOrigem +
                this.modelAdd.valorFrete +
                this.modelAdd.valorOutraDespesa +
                this.modelAdd.valorSeguro -
                this.modelAdd.valorDesconto,
            4,
        );
    }

    private async getPreLoadPack() {
        while (!this.loadedList) await Delay(100);

        this.produtosOptions = this.preLoadPack.produtosComboOnlyAtivos();
        this.unidadeMedidaOptions = this.preLoadPack.unidadeMedidaCombo();
    }

    private getUnidadeMedida() {
        return this.unidadeMedidaOptions.find(p => p["value"] == this.modelAdd.unidadeMedidaId)?.["sigla"] ?? "";
    }

    private async getIndice() {
        return await this.indicesConversoesUnidadeMedidasService.getIndiceConversao(
            this.modelAdd.unidadeMedidaOrigemId,
            this.modelAdd.unidadeMedidaId,
            this.produto.densidade,
            this.modelAdd.produtoId,
        );
    }

    private async mounted() {
        this.produtoLoteComponent = this.$refs.produtoLoteComponent as ProdutoLoteComponent;

        this.isFracionamento = await this.GET_IS_FRACIONAMENTO();

        this.getPreLoadPack();
        this.loadProdutoLotes();
    }
}
