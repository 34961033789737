import Vue from "vue";

import comboComponent from "@/components/child/form/combo.vue";
import gridComponent from "@/components/child/grid/grid.vue";
import { GridColumn, GridColumnType } from "@/components/child/grid/gridColumn";
import { Component, Watch } from "@/decorators";
import PaginationModel from "@/models/paginationModel";
import GrupoProdutoModel from "@/models/produto/grupoProdutoModel";
import ProdutoModel, { ProdutoListParameters } from "@/models/produto/produtoModel";
import AtualizacaoValoresService from "@/services/atualizacaoValoresService";
import GrupoProdutoService from "@/services/produto/grupoProdutoService";
import ProdutoService from "@/services/produto/produtoService";

import "../crud/crud.scss";
import "./atualizacaoValores.scss";

@Component({
    components: {
        gridComponent,
        comboComponent,
    },
})
export default class AtualizacaoValoresProdutoComponent extends Vue {
    private service = new AtualizacaoValoresService();
    private produtoService = new ProdutoService();

    percentualAtualizacao = 0;

    total = 0;
    isHidden = false;
    filtro: Array<Object> = [];
    apareceSubGrupo?: number = null;
    grupoProdutoFiltro?: number = null;
    subGrupo?: number = null;

    grupoProdutoOptions: Array<Object> = [];
    subGrupoOptions: Array<Object> = [
        { text: "Ativo", value: 0 },
        { text: "Flavorizante", value: 1 },
        { text: " Veículos/Excipientes", value: 2 },
    ];

    gridData: ProdutoModel[] = [];
    gridSelectedValues: ProdutoModel[] = [];

    get gridColumns(): Array<GridColumn> {
        return [
            new GridColumn("grupo", this.$t("__.ts.grupoProduto"), GridColumnType.String),
            new GridColumn("descricao", this.$t("__.ts.descricao"), GridColumnType.String),
            new GridColumn("markup", this.$t("__.ts.markupAtual"), GridColumnType.Decimal),
            new GridColumn("valorVenda", this.$t("__.ts.valorVendaAtual"), GridColumnType.Money),
        ];
    }

    private async load() {
        try {
            const customParameters: ProdutoListParameters = {
                combo: this.filtro[0] != null ? this.filtro[0].toString() : "",
                sub: this.filtro[1] != null ? this.filtro[1].toString() : "",
                somenteAtivos: true,
            };

            const data = await this.produtoService
                .list("", "descricao", "asc", 1, 999999, customParameters)
                .withLoading()
                .resolveWithJSON<PaginationModel<ProdutoModel>>();

            this.gridData = data.list;
            this.total = data.total;

            this.gridData.forEach(p => {
                p.markup = p.markup ?? 0;
                p.valorVenda = p.valorVenda ?? 0;
            });
        } catch {}
    }

    private async onAtualizarValores() {
        if (!this.percentualAtualizacao) {
            await this.$showError(this.$t("__.ts.erro"), this.$t("__.ts.informePercentual"));
            return this.$focusField("percentualAtualizacao");
        }

        if (this.gridSelectedValues.length == 0) {
            await this.$showWarning(
                this.$t("__.ts.aviso"),
                this.$t("__.Components.parent.atualizacaoValores.produto.nenhumSelecionado"),
            );
            return;
        }

        try {
            await this.service
                .atualizacaoValoresProduto(
                    this.gridSelectedValues.map(p => p.id),
                    this.percentualAtualizacao,
                )
                .withLoading();

            this.$showSuccess(this.$t("__.ts.sucesso"), this.$t("__.ts.sucessoAtualizacao"));

            this.percentualAtualizacao = 0;

            await this.load().withLoading();
        } catch {}
    }

    private getFormValues() {
        this.filtro = [];
        if (this.grupoProdutoFiltro != 2) {
            this.subGrupo = null;
        }
        this.filtro.push(this.grupoProdutoFiltro);
        this.filtro.push(this.subGrupo);
    }

    private onFiltrar() {
        this.getFormValues();
        this.load();
    }

    private onSelectedValuesChanged(values: ProdutoModel[]) {
        this.gridSelectedValues = values;
    }

    @Watch("grupoProdutoFiltro")
    private onChangeGrupoProdutoFiltro() {
        this.apareceSubGrupo = this.grupoProdutoFiltro;
    }

    private async loadGruposProduto() {
        try {
            const data = await new GrupoProdutoService().combo().resolveWithJSON<PaginationModel<GrupoProdutoModel>>();

            this.grupoProdutoOptions = data.list.map(item => ({
                value: item.id,
                text: item.descricao,
                tipo: item.tipoProduto,
            }));

            if (data.list.length > 0) {
                this.grupoProdutoFiltro = data.list[0].id;
                this.getFormValues();
            }
        } catch {}
    }

    private mounted() {
        Promise.all([this.loadGruposProduto()])
            .withLoading()
            .then(() => {
                this.load();
            })
            .catch(() => {});
    }
}
