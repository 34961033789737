enum Configuracoes {
    // [Description("Estoque Padrão")]
    EstoquePadrao = 1,
    // [Description("Conta Gerencial Padrão Venda")]
    ContaGenrencialPadraoVenda = 2,
    // [Description("Conta Gerencial de Saída Padrão para Transferência Interna")]
    ContaGerencialTransferenciaInternaSaida = 3,
    // [Description("Conta Gerencial de Entrada Padrão para Transferência Interna")]
    ContaGerencialTransferenciaInternaEntrada = 4,
    // [Description("Controlar Sessão de caixa por")]
    ControlarSessaoCaixaPor = 5,
    // [Description("Horas de Validade do Orçamento")]
    HorasValidadeOrcamento = 6,
    // [Description("Ambiente de Envio de Nota Fiscal Eletrônica")]
    AmbienteEnvioNFe = 7,
    // [Description("Serviço Fiscal para Nota de Manipulação")]
    ServicoFiscalManipulacao = 8,
    // [Description("Atualizar valores de orçamentos para venda de contínuos")]
    AtualizarValoresOrcamentosContinuos = 9,
    // [Description("Conta corrente para sangria de fechamento de caixa")]
    ContaCorrenteSangriaFinalizacaoCaixa = 11,
    // [Description("Tempo(horas) minimo de entrega para vendas aprovadas")]
    TempoMinimoPrevisaoEntregaVenda = 12,
    // [Description("Valor de Markup padrão para produtos sem Markup informado")]
    ValorMarkupPadrao = 13,
    // [Description("Dias faltantes para considerar o Lote como vencendo")]
    DiasAvisoEstoqueVencendo = 14,
    // [Description("Dias de validade para lotes não informados do xml da nota de entrada")]
    DiasValidadePadraoLoteNaoInformadoXml = 15,
    // [Description("Tipo de cálculo feito a partir do Markup")]
    TipoCalculoMarkup = 16,
    // [Description("CFOP para emissão de Cupom Fiscal Dentro do Estado")]
    CFOPDentroEstado = 20,
    // [Description("CFOP para emissão de Cupom Fiscal Fora do Estado")]
    CFOPForaEstado = 21,
    // [Description("Usuario Emissor SNGPC")]
    UsuarioEmissorSNGPC = 22,
    // [Description("Periodiciadade Envio SNGPC")]
    PeriodicidadeEnvioSNGPC = 23,
    // [Description("Exigibilidade ISS")]
    ExigibilidadeIss = 26,
    // [Description("Desconsiderar fator de correção na definiçao do molde")]
    DesconsiderarFatorDeCorrecaoNoMolde = 28,
    // [Description("Valor IR")]
    ValorIR = 29,
    // [Description("Valor INSS")]
    ValorINSS = 30,
    // [Description("Valor CSLL (Contribuição Social sobre o Lucro Líquido)")]
    ValorCSLL = 31,
    // [Description("Rótulo para lote")]
    RotuloLoteId = 32,
    // [Description("Rótulo para manipulação")]
    RotuloManipulacao = 33,
    // [Description("Usa controle PCP")]
    UsaPCP = 34,
    // [Description("Vai considerar outras franquias na venda")]
    FranquiaAbrangencia = 35,
    // [Description("Empresa comercializa nacionalmente produtos controlados")]
    SiproquimComercializacaooNacional = 36,
    // [Description("Empresa comercializa internacionalmente produtos controlados")]
    SiproquimComercializacaoInternacional = 37,
    // [Description("Empresa produz produtos controlados")]
    SiproquimProducao = 38,
    // [Description("Empresa faz transformação de produtos controlados")]
    SiproquimTransformacao = 39,
    // [Description("Empresa consome produtos controlados")]
    SiproquimConsumo = 40,
    // [Description("Empresa fabrica produtos controlados")]
    SiproquimFabricacao = 41,
    // [Description("Empresa transporta produtos controlados")]
    SiproquimTransporte = 42,
    // [Description("Empresa armazena produtos controlados")]
    SiproquimArmazenamento = 43,
    // [Description("Data de entrega é obrigatório")]
    DataEntregaObrigatoria = 44,
    // [Description("Markup para equinos")]
    MarkupEquinos = 45,
    // [Description("Permite parcelamento na tela de venda")]
    PermiteParcalamentoVenda = 46,
    // [Description("Permite enviar NFS-e sem dados do tomador")]
    PermiteEnviarNotaSemTomador = 47,
    // [Description("Bloquear cadastro duplicado de cliente na venda")]
    BloquearClienteDuplicado = 48,
    // [Description("Percentual de Desconto Máximo")]
    PercentualDescontoMaximo = 49,
    // [Description("Utiliza SAT SP")]
    UsaSAT = 50,
    // [Description("Alertar estoque mínimo")]
    AlertarEstoqueMinimo = 51,
    // [Description("Exibir o sinônimo do produto no Rótulo")]
    ShowRotuloSinonimo = 52,
    // [Description("Obrigatório informação de lote na venda de produto acabado")]
    LoteProdutoAcabado = 53,
    // Emissão de nota automática na venda após faturamento
    EmissaoNotaAutomatica = 54,
    // [Description("Usa capsula tipo 5")]
    UsaCapsulaTipo5 = 55,
    // [Description("Emite Nota Fiscal de Serviço")]
    EmiteNotaDeServico = 56,
    // [Description("Produto Padrão de Manipulação")]
    ProdutoPadraoDeManipulacao = 57,
    // [Description("Bloquear Incompatibilidades")]
    BloquearIncompatibilidades = 59,
    // [Description("Obrigatório RG do cliente em vendas de controlados")]
    ObrigatorioRGVendaControlados = 60,
    // [Description("Posologias com texto livre")]
    PosologiasTextoLivre = 61,
    // [Description("Permite reabertura de caixa")]
    PermiteReaberturaCaixa = 62,
    // [Description("NSU obrigatório em faturamento com cartão")]
    NsuObrigatorioFaturamentoCartao = 63,
    // [Description("Obrigatório Faturamento para Emissão de Nota fiscal")]
    ObrigatorioFaturamentoParaEmitirNota = 64,
    // [Description("Email do MeusCorreios")]
    EmailMeusCorreios = 65,
    // [Description("Senha do MeusCorreios")]
    SenhaMeusCorreios = 66,
    // [Description("Considerar validade da manipulação por")]
    ConsiderarValidadeManipulacaoPor = 67,
    // [Description("Cartão de Postagem do Correios")]
    CartaoPostagem = 68,
    // [Description("Obrigatório Observação do Desconto")]
    ObsDesconto = 69,
    // [Description("Descriminar acréscimo/desconto na nota de transporte")]
    DescriminarAcrescimoNotaTransporte = 70,
    // [Description("Obrigar informação de CPF para cadastro de cliente")]
    CpfClienteObrigatorio = 71,
}

export default Configuracoes;
