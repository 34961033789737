import Vue from "vue";

import dataTooltipComponent from "@/components/child/form/datatooltip.vue";
import gridComponent from "@/components/child/grid/grid.vue";
import { GridColumn, GridColumnType } from "@/components/child/grid/gridColumn";
import loadingModalComponent from "@/components/child/loadingmodal/loadingmodal.vue";
import ShortcutComponent from "@/components/parent/shortcut/shortcut";
import shortcutComponent from "@/components/parent/shortcut/shortcut.vue";
import { Component } from "@/decorators";
import { sortArray } from "@/utils/common/array";
import "../crud.scss";

interface GridItem {
    id: number;
    descricao: string;
}

export enum ReplicarProduto {
    KitEmbalagemIncompatibilidades = "Kit Embalagem Incompatibilidades",
    ObservacoesProduto = "Observações Produto",
    ProdutoAssociacoes = "Produto Associações",
    ProdutoFichaTecnica = "Produto Ficha Técnica",
    ProdutoFormasFarmaceutica = "Produto Formas Farmacêuticas",
    ProdutosIncompativeis = "Produtos Incompatíveis",
    ProdutoMarkupDinamico = "Produto Markup Dinamico",
    ProdutoRefrigeradoFormaFarmaceutica = "Produto Refrigerado Forma Farmacêutica",
    ProdutoSinonimos = "Produto Sinônimos",
    ValidadeFormaFarmaceuticaProduto = "Validades Forma Farmacêuticas Produto",
}

@Component({
    components: {
        loadingModalComponent,
        shortcutComponent,
        dataTooltipComponent,
        gridComponent,
    },
})
export default class ReplicarProdutoComponent extends Vue {
    private shortcutComponent: ShortcutComponent = null;

    gridData = this.gerarGridData(ReplicarProduto);

    gerarGridData(enumObj: any): GridItem[] {
        return Object.keys(enumObj).map((key, index) => ({
            id: Math.pow(2, index),
            descricao: enumObj[key].toString(),
        }));
    }

    gridSelectedValues: Array<GridItem> = [];
    get gridColumns(): Array<GridColumn> {
        return [new GridColumn("descricao", "Nome", GridColumnType.String)];
    }

    private onConfirm() {
        this.$emit(
            "replicar-produtos",
            this.gridSelectedValues.map(p => p.id),
        );
        this.shortcutComponent.hide();
    }

    public open() {
        this.shortcutComponent.show();
    }

    private onChangeSort(sortKey: keyof GridItem, sortOrder: "asc" | "desc") {
        sortArray(this.gridData, sortKey, sortOrder);
    }

    private onSelectedValuesChanged(values: Array<GridItem>) {
        this.gridSelectedValues = values;
    }

    private mounted() {
        this.shortcutComponent = this.$refs.shortcutComponent as ShortcutComponent;
        this.shortcutComponent.title = this.$t("__.ts.selecItensProd").toString();
    }
}
