import PaginationModel from "@/models/paginationModel";
import AtendimentoWhatsAppModel from "@/models/whatsApp/atendimento/atendimentoWhatsAppModel";
import PerfilWhatsAppModel from "@/models/whatsApp/atendimento/perfilWhatsAppModel";

import { iServiceAtendimento } from "../base/iServiceAtendimento";

export default class AtendimentoService extends iServiceAtendimento {
    constructor() {
        super("Atendimento");
    }

    public async getAtendimentoById(atendimentoId: string): Promise<AtendimentoWhatsAppModel> {
        try {
            return await this.get(`GetById?atendimentoId=${atendimentoId}`).resolveWithJSON<AtendimentoWhatsAppModel>();
        } catch {}
        return null;
    }

    public async getListAtendimentos(): Promise<AtendimentoWhatsAppModel[]> {
        try {
            return await this.get("List").withLoading().resolveWithJSON<AtendimentoWhatsAppModel[]>();
        } catch {}
    }

    public async getListAtendimentosEncerrados(
        pageIndex: number,
        pageSize: number,
        filterKey: string,
    ): Promise<PaginationModel<AtendimentoWhatsAppModel>> {
        try {
            return await this.get(`ListEncerrados?pageIndex=${pageIndex}&pageSize=${pageSize}&filterKey=${filterKey}`)
                .withLoading()
                .resolveWithJSON<PaginationModel<AtendimentoWhatsAppModel>>();
        } catch {}
    }

    public async getListAtendimentosByContato(contatoId: string): Promise<AtendimentoWhatsAppModel[]> {
        try {
            return await this.get(`GetListByContato?contatoId=${contatoId}`)
                .withLoading()
                .resolveWithJSON<AtendimentoWhatsAppModel[]>();
        } catch {}
        return [];
    }

    public async criarAtendimento(numeroDestino: string): Promise<AtendimentoWhatsAppModel> {
        try {
            return await this.post(`CriarAtendimento?numero=${numeroDestino}`, {})
                .withLoading()
                .resolveWithJSON<AtendimentoWhatsAppModel>();
        } catch {}
        return null;
    }

    public async vincularUsuarioByAtendimentoId(atendimentoId: string): Promise<PerfilWhatsAppModel> {
        try {
            return await this.post(`VincularAtendimentoUsuario?atendimentoId=${atendimentoId}`, {})
                .withLoading()
                .resolveWithJSON<PerfilWhatsAppModel>();
        } catch {}
        return null;
    }

    public async fecharAtendimentoByAtendimentoId(atendimentoId: string): Promise<PerfilWhatsAppModel> {
        try {
            return await this.post(`FecharAtendimento?atendimentoId=${atendimentoId}`, {})
                .withLoading()
                .resolveWithJSON<PerfilWhatsAppModel>();
        } catch {}
        return null;
    }
}
