import Vue from "vue";
import { mapMutations, mapState } from "vuex";

import { GridColumn, GridColumnType } from "@/components/child/grid/gridColumn";
import ImpressaoComponent from "@/components/child/impressao/impressaoComponent";
import impressaoComponent from "@/components/child/impressao/impressaoComponent.vue";
import SeparacaoComponent from "@/components/parent/crud/separacao/edit";
import separacaoComponent from "@/components/parent/crud/separacao/edit.vue";
import ShortcutComponent from "@/components/parent/shortcut/shortcut";
import shortcutComponent from "@/components/parent/shortcut/shortcut.vue";
import SolicitarSenhaComponent from "@/components/parent/solicitarSenha/view";
import solicitarSenhaComponent from "@/components/parent/solicitarSenha/view.vue";
import { Component } from "@/decorators";
import OrigemRotulo from "@/models/enum/rotulo/origemRotulo";
import SituacoesVenda from "@/models/enum/situacoesVenda";
import StatusFracionamento from "@/models/enum/statusFracionamento";
import VendaModel from "@/models/vendaModel";
import VendaService from "@/services/vendaService";
import { FastActionLastPack, FastActionList, FastActionPack } from "@/store/fastActionStore";
import { AppState, Mutations } from "@/store/store";
import { editDateWithTime } from "@/utils/common/date";
import Delay from "@/utils/common/delay";

import { getFirstName } from "../fastactionUtils";

import "./../fastactionsgrid.scss";

@Component({
    components: {
        separacaoComponent,
        shortcutComponent,
        solicitarSenhaComponent,
        impressaoComponent,
    },
    computed: mapState({
        fastActionLists: (state: AppState) => state.fastAction.fastActionLists,
        fastActionPack: (state: AppState) => state.fastAction.fastActionPack,
    }),
    methods: {
        ...mapMutations(["SET_LISTSPACK", "SET_COUNTPACK", "SET_LASTCOUNTPACK"] as Mutations),
        getFirstName: getFirstName,
        editDateWithTime: editDateWithTime,
    },
})
export default class FastActionEstoqueFracionamentoComponent extends Vue {
    // State computed props
    fastActionLists: FastActionList;
    fastActionPack: FastActionPack;
    SET_LISTSPACK: (data: FastActionList) => void;
    SET_COUNTPACK: (data: FastActionLastPack) => void;
    SET_LASTCOUNTPACK: (data: FastActionLastPack) => void;

    private service = new VendaService();
    private shortCutComponent: ShortcutComponent = null;
    private separacaoComponent: SeparacaoComponent = null;
    private impressaoComponent: ImpressaoComponent = null;
    private solicitarSenhaComponent: SolicitarSenhaComponent = null;

    private model: VendaModel = null;

    private gridSelectedImpValues: Array<Object> = [];
    private gridImpressoes: Array<Object> = [];

    private totalHaSeparar = 0;
    private totalSeparado = 0;
    modelId = 0;

    private vendas: Array<VendaModel> = [];
    private vendasOriginal: Array<VendaModel> = [];

    private vendasHaSeparar = true;
    private vendasSeparando = false;
    salvarEConcluir = false;
    todosSeparados = false;

    get gridColumnsImpressoes(): Array<GridColumn> {
        return [
            new GridColumn("descricao", this.$t("__.ts.descr") as string, GridColumnType.String),
            new GridColumn("modelo", this.$t("__.ts.model") as string, GridColumnType.String),
        ];
    }

    private onVerMais() {
        this.$router.push("/separacao-lista/");
    }

    private onSearch(venda: VendaModel) {
        this.$router.push("/separacao-edicao/" + venda.id);
    }

    private cancel() {
        this.$router.back();
    }

    private onHaSeparar() {
        this.vendasHaSeparar = true;
        this.vendasSeparando = false;
        this.loadVendas();
    }

    private onSeparados() {
        this.vendasSeparando = true;
        this.vendasHaSeparar = false;
        this.loadVendas();
    }

    private async onSepararItens(venda: VendaModel) {
        this.todosSeparados = venda.itens.filter(i => i.separado).length == venda.itens.length;
        this.shortCutComponent.title = `Separação de itens da venda ${venda.codigo}`;
        this.shortCutComponent.show();
        this.separacaoComponent.loadVendaById(venda.id);
    }

    private onBtnName(concluirESalvar: boolean) {
        this.salvarEConcluir = concluirESalvar;
    }

    private async onConcluirSeparacaoModal() {
        try {
            const retorno = await this.separacaoComponent.save().withLoading();
            if (retorno) {
                this.shortCutComponent.hide();
                await Delay(700);
                await this.loadVendas(true);

                this.calculaTotais();
            }
        } catch {}
    }

    private async onConcluirSeparacao(id: number) {
        try {
            this.modelId = id;
            this.solicitarSenhaComponent.show();
        } catch {}
    }

    private async onResult(sucesso: boolean) {
        if (sucesso) {
            try {
                const response = await this.$showQuestion(
                    this.$t("__.ts.atencao"),
                    this.$t("__.ts.conclSeparacItensApagados"),
                );
                if (response) {
                    await this.concluirSeparacao(this.modelId).withLoading();
                }
            } catch {}
        } else {
            await this.$showError(this.$t("__.ts.erro"), "Senha invalida, separação não será salva!");
        }

        this.modelId = 0;
    }

    private async concluirSeparacao(id: number) {
        try {
            const venda = await this.service.get(id).then(r => r.json() as Promise<VendaModel>);

            venda.itens
                .filter(p => p.fracionamentoId)
                .forEach(i => {
                    i.separado = true;
                    i.fracionamento.itens.forEach(f => {
                        f.statusFracionamento = StatusFracionamento.Separado;
                    });
                });

            venda.situacao = SituacoesVenda.Expedicao;

            const sucesso = await this.service.update(venda).resolveWithoutJSON();
            if (sucesso) {
                this.$showSuccess("Separação!", "Venda separada com sucesso.");
            }
        } catch {}

        await this.loadVendas(true);

        this.calculaTotais();
    }

    private calculaTotais() {
        this.totalHaSeparar = this.vendasOriginal.filter(p => !p.usuarioSeparacaoId).length;
        this.totalSeparado = this.vendasOriginal.filter(p => p.usuarioSeparacaoId).length;
        this.SET_COUNTPACK({ estoqueFracionamento: this.totalHaSeparar });
    }

    private async loadVendas(forceReload = false) {
        if (this.fastActionLists.estoqueFracionamento.length["length"] > 0 && !forceReload) {
            this.loadVendasModel(this.fastActionLists.estoqueFracionamento);
            this.calculaTotais();
        } else {
            const data = await this.service.listVendaSeparacaoHome().resolveWithJSON<VendaModel[]>();

            this.SET_LISTSPACK({ estoqueFracionamento: data });
            this.loadVendasModel(data);
            this.calculaTotais();
        }
    }

    private loadVendasModel(data: VendaModel[]) {
        this.vendasOriginal = data.filter(p => p.situacao == SituacoesVenda.Fracionando);
        this.vendas = this.vendasOriginal;

        if (this.vendasHaSeparar) {
            this.vendas = this.vendasOriginal.filter(venda => !venda.usuarioSeparacaoId);
        }
        if (this.vendasSeparando) {
            this.vendas = this.vendasOriginal.filter(venda => venda.usuarioSeparacaoId);
        }
    }

    private async onImprimir(venda: VendaModel, rotuloSecundário = false) {
        if (rotuloSecundário) {
            await this.impressaoComponent
                .show({
                    modelId: venda.id,
                    tipoImpressao: "Venda",
                    origemRotulo: OrigemRotulo.Secundario,
                })
                .withLoading();
        } else {
            await this.impressaoComponent
                .show({
                    modelId: venda.id,
                    tipoImpressao: "Venda",
                    impressaoTodosModelo: true,
                })
                .withLoading();
        }
    }

    private onVisualizar(venda: VendaModel) {
        this.$router.push("/venda-edicao/" + venda.id);
    }

    private mounted() {
        this.shortCutComponent = this.$refs.shortCutComponent as ShortcutComponent;
        this.separacaoComponent = this.$refs.separacaoComponent as SeparacaoComponent;
        this.impressaoComponent = this.$refs.impressaoComponent as ImpressaoComponent;
        this.solicitarSenhaComponent = this.$refs.solicitarSenhaComponent as SolicitarSenhaComponent;

        Promise.all([this.loadVendas(true)]).then(() => {
            this.SET_LASTCOUNTPACK({
                estoqueFracionamento: Number(this.fastActionPack.countPack.estoqueFracionamento),
            });
        });
    }
}
