import TiposPessoa from "../../models/enum/tiposPessoa";
import NotaFiscalEletronicaImportacaoModel from "../../models/notafiscaleletronica/notaFiscalEletronicaImportacaoModel";
import NotaFiscalEletronicaImportacaoQuestionMessageViewModel from "../../models/questionMessages/notaFiscalEletronicaImportacaoQuestionMessageViewModel";
import nfetch from "../../utils/events/nfetch";
import { getCookie } from "../utils/serviceUtils";

export default class NotaFiscalEletronicaImportacaoService {
    private controllerName = "NotaFiscalEletronicaImportacao";

    public getAssociacoesProdutos(
        tipoPessoa: TiposPessoa,
        documento: string,
        codigos: Array<string>,
    ): Promise<Response> {
        let codigosParemeter = "";

        for (const codigo of codigos) codigosParemeter += "&codigos=" + codigo;

        return nfetch(
            `/${this.controllerName}/GetAssociacoesProdutos?tipoPessoa=${tipoPessoa}&documento=${documento}${codigosParemeter}`,
            {
                credentials: "same-origin",
            },
        );
    }

    public validate(model: NotaFiscalEletronicaImportacaoModel): Promise<Response> {
        return nfetch(`/${this.controllerName}/Validate`, {
            method: "post",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json; charset=UTF-8",
                "XSRF-TOKEN": getCookie("XSRF-TOKEN"),
            },
            body: JSON.stringify(model),
        });
    }

    public insert(data: NotaFiscalEletronicaImportacaoModel, questionMessages: NotaFiscalEletronicaImportacaoQuestionMessageViewModel[]): Promise<Response> {
        return nfetch(`/${this.controllerName}/Insert`, {
            method: "post",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json; charset=UTF-8",
                "XSRF-TOKEN": getCookie("XSRF-TOKEN"),
            },
            body: JSON.stringify({ data, questionMessages }),
        });
    }
}
