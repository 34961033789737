import Vue from "vue";

import comboComponent from "@/components/child/form/combo.vue";
import decimalComponent from "@/components/child/form/decimal.vue";
import decimalComSinalCustomComponent from "@/components/child/form/decimalComSinalCustom.vue";
import moedaComponent from "@/components/child/form/moeda.vue";
import { Component, Prop, Watch } from "@/decorators";
import ItemEmbalagemFracionamentoModel from "@/models/itemEmbalagemFracionamentoModel";
import PaginationModel from "@/models/paginationModel";
import ProdutoModel from "@/models/produto/produtoModel";
import ProdutoLoteModel from "@/models/produtoLoteModel";
import VendaItemModel from "@/models/vendaItemModel";
import EstoqueComprometidoService from "@/services/estoqueComprometidoService";
import ProdutoService from "@/services/produto/produtoService";
import ProdutoLoteService from "@/services/produtoLoteService";
import arithmeticHelper from "@/utils/common/arithmeticHelper";

@Component({
    components: {
        comboComponent,
        moedaComponent,
        decimalComponent,
        decimalComSinalCustomComponent,
    },
})
export default class ItemEmbalagemComponent extends Vue {
    private produtoService = new ProdutoService();
    private produtoLoteService = new ProdutoLoteService();
    private estoqueComprometidoService = new EstoqueComprometidoService();
    private model = new ItemEmbalagemFracionamentoModel();

    private lotes: ProdutoLoteModel[] = [];
    private produtoLoteOptions = [];

    private isSemEstoque = false;
    private loadingModel = false;

    @Prop(Object) item;
    @Prop(Number) itemFracionamentoId: number;
    @Prop(Number) index: number;
    @Prop(Array) itensVenda;
    @Prop(Array) produtoOptions;
    @Prop({ type: Boolean, default: false }) alteracao: boolean;
    @Prop({ type: Boolean, default: false }) desativaCampos: boolean;

    // @ts-ignore
    @Watch("model.quantidade")
    private async onQuantidadeChanged() {
        await this.onChangeDataItem();
    }

    // @ts-ignore
    @Watch("model.produtoLoteId")
    private async onLoteChanged() {
        await this.onChangeDataItem();
    }

    private async onChangeDataItem() {
        if (!this.loadingModel) {
            await this.setValorUnidade();
        }

        if (this.model.produtoLoteId && this.lotes.length > 0) {
            if (this.model.quantidade > this.lotes.filter(p => p.id == this.model.produtoLoteId)[0].quantidadeAtual) {
                this.onAlertQuantidadeInsuficiente(this.model.produto.descricao);
            }
        }

        this.$emit("change-item", this.model, this.index);
    }

    // @ts-ignore
    @Watch("model.produtoId")
    private async onProdutoChanged() {
        if (this.model.produtoId && !this.loadingModel) {
            this.model.produto = await this.loadProduto(this.model.produtoId);
            await this.buscaLotes();
        }
        this.$emit("change-item", this.model, this.index);
    }

    private setIsSemEstoque() {
        this.isSemEstoque = false;

        if (!this.model.produto.ignoraControleEstoque && this.lotes.length == 0) {
            this.isSemEstoque = true;
            this.model.valorTotal = 0;
            this.model.quantidade = 0;
        }
    }

    private onRemoveItem() {
        this.$emit("remove-item", this.model, this.index);
    }

    private async buscaLotes() {
        this.lotes = await this.loadLotesByProduto(this.model.produtoId);

        // Se não tiver quantidade em estoque no lote padrão deve buscar outro
        if (this.model.produtoLotePadraoId && !this.alteracao) {
            const lote = this.lotes.filter(p => p.id == this.model.produtoLotePadraoId)[0];
            if (lote.quantidadeAtual <= 0 || this.model.quantidade > lote.quantidadeAtual) {
                this.model.produtoLoteId = null;
            }
        }

        if (this.model.produto.ignoraControleEstoque) {
            if (this.lotes.length == 0) {
                const loteOutro = await this.geraLoteOutro(this.model.produto);
                this.lotes.push(loteOutro);
            }
        }

        if (this.model.produtoLoteId == null) {
            this.model.produtoLoteId = await this.getLoteValido();
        }

        if (this.model.produtoLoteId) {
            if (this.model.quantidade == null) {
                this.model.quantidade = 1;
            }
        } else {
            if (this.model.quantidade == null) {
                this.model.quantidade = 0;
            }
        }

        this.produtoLoteOptions = this.lotes.map(p => ({ value: p.id, text: p.descricao }));
        this.model.produtoLotePadraoId = this.model.produtoLoteId;
        await this.setValorUnidade();

        this.setIsSemEstoque();
    }

    private async loadLotesByProduto(produtoId: number) {
        const response = await this.produtoLoteService.getByProdutoId(produtoId);
        if (response.ok) {
            const data = (await response.json()) as PaginationModel<ProdutoLoteModel>;
            return data.list;
        } else {
            return [] as ProdutoLoteModel[];
        }
    }

    private async geraLoteOutro(produto: ProdutoModel) {
        const lote = new ProdutoLoteModel();
        lote.descricao = "Outro";
        lote.valorCusto = produto.custoReferencia;
        lote.id = 0;
        return lote;
    }

    private async getLoteValido() {
        const itens = this.itensVenda as VendaItemModel[];
        const quantidadeUtilizadaLote = itens
            .filter(p => p.fracionamento)
            .reduce((acc, p) => acc + p.fracionamento.itensEmbalagem.reduce((acci, i) => acci + i.quantidade, 0), 0);

        let loteSelecionadoId = null;

        const lotesValidos = [];
        for await (const lote of this.lotes) {
            const quantidadeComprometida = await this.estoqueComprometidoService
                .getQuantidadeComprometidaLote(lote.id, this.itemFracionamentoId)
                .resolveWithJSON<number>();

            lote.quantidadeAtual -= quantidadeComprometida;

            if (
                new Date(lote.dataValidade.toString()).toISOString() > new Date().toISOString() &&
                lote.quantidadeAtual > quantidadeUtilizadaLote + this.model.quantidade
            ) {
                lotesValidos.push(lote);
            }
        }

        if (lotesValidos.length > 0) {
            loteSelecionadoId = lotesValidos.sort((a, b) => (a.dataValidade > b.dataValidade ? 1 : 0))[0].id;
        }

        if (loteSelecionadoId == null) {
            return 0;
        }

        return loteSelecionadoId;
    }

    private async setValorUnidade() {
        if (this.lotes.length > 0 && this.model.produtoLoteId) {
            const lote = this.lotes.filter(p => p.id == this.model.produtoLoteId)[0];
            if (this.model.quantidade > lote.quantidadeAtual && this.model.produtoLoteId && lote.quantidadeAtual > 0) {
                this.onAlertQuantidadeInsuficiente(this.model.produto.descricao);
            }
            this.model.valorUnidade = lote.valorCusto;
            this.model.valorTotal = arithmeticHelper.round(this.model.valorUnidade * this.model.quantidade);
        } else if (this.model.produtoLotePadraoId == 0) {
            this.model.valorUnidade = this.model.produto.custoReferencia;
            this.model.valorTotal = arithmeticHelper.round(this.model.valorUnidade * this.model.quantidade);
        } else {
            this.onAlertSemEstoque(this.model.produto.descricao);
        }
    }

    private async loadProduto(produtoId: number) {
        try {
            return await this.produtoService.get(produtoId).then(r => r.json() as Promise<ProdutoModel>);
        } catch {}
    }

    private async onAlertSemEstoque(produto: string, msgFinal: string = this.$t("__.ts.naoSeraInclVenda").toString()) {
        await this.$showError(this.$t("__.ts.atencao"), this.$t("__.ts.naoHaLotes") + produto + msgFinal);
    }

    private async onAlertQuantidadeInsuficiente(
        produto: string,
        msgFinal: string = this.$t("__.ts.foiAltQtdeParaMaxDisp").toString(),
    ) {
        await this.$showError(this.$t("__.ts.atencao"), this.$t("__.ts.naoHaQtd") + produto + msgFinal);
    }

    private async loadItem() {
        this.loadingModel = true;

        this.model.updateFrom(this.item);

        this.model.produtoLoteId = this.model.produtoLotePadraoId;
        this.model.produto = await this.loadProduto(this.model.produtoId);
        await this.buscaLotes();
        this.loadingModel = false;
    }

    // @ts-ignore
    @Watch("item")
    private async onChangeItem() {
        await this.loadItem().withLoading();
    }

    async mounted() {
        await this.loadItem().withLoading();
    }
}
