var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "crud-grid", attrs: { id: "tableCrud" } },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showTableHead,
              expression: "showTableHead",
            },
          ],
          staticClass: "table-head",
        },
        [
          _c(
            "div",
            { staticClass: "table-head-left" },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.showSearchField,
                      expression: "showSearchField",
                    },
                  ],
                  staticClass: "btn-search",
                },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.filterKey,
                        expression: "filterKey",
                      },
                      { name: "focus", rawName: "v-focus" },
                    ],
                    attrs: {
                      type: "text",
                      placeholder: _vm.$t(
                        "__.Components.child.grid.grid_vue_html.procurarPor"
                      ),
                      maxlength: "20",
                    },
                    domProps: { value: _vm.filterKey },
                    on: {
                      keyup: function ($event) {
                        _vm.onFilterKeyUp($event.target.value)
                      },
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.filterKey = $event.target.value
                      },
                    },
                  }),
                ]
              ),
              _vm._v(" "),
              _vm._t("header"),
              _vm._v(" "),
              _vm.auxShowAddNewButton
                ? _c(
                    "button",
                    {
                      staticClass: "btn btn-primary btn-add",
                      attrs: {
                        type: "button",
                        title: _vm.$t(
                          "__.Components.child.grid.grid_vue_html.addNovo"
                        ),
                      },
                      on: { click: _vm.create },
                    },
                    [
                      _c("i", {
                        staticClass: "fa fa-right fa-plus-circle",
                        attrs: { "aria-hidden": "true" },
                      }),
                    ]
                  )
                : _vm._e(),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showTools,
                  expression: "showTools",
                },
              ],
              staticClass: "tools",
            },
            [
              _vm._t("tools", [
                _vm._t("more-tools"),
                _vm._v(" "),
                _vm.showExportPrinter
                  ? _c(
                      "a",
                      {
                        class: [
                          "bg-secondary",
                          { disabled: _vm.data.length == 0 },
                        ],
                        attrs: {
                          href: "#",
                          title: _vm.$t(
                            "__.Components.child.grid.grid_vue_html.imprimirRegistrosAbaixo"
                          ),
                        },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            _vm.onGerarImpressaoClick()
                          },
                        },
                      },
                      [
                        _c("i", {
                          staticClass: "fa fa-print",
                          attrs: { "aria-hidden": "true" },
                        }),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.showExportXls
                  ? _c(
                      "a",
                      {
                        class: [
                          "bg-light-green",
                          { disabled: _vm.data.length == 0 },
                        ],
                        attrs: {
                          href: "#",
                          title: _vm.$t(
                            "__.Components.child.grid.grid_vue_html.gerarXLSdosArq"
                          ),
                        },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            _vm.onGerarXlsClick()
                          },
                        },
                      },
                      [
                        _c("i", {
                          staticClass: "fa fa-file-excel",
                          attrs: { "aria-hidden": "true" },
                        }),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.showExportPdf
                  ? _c(
                      "a",
                      {
                        class: [
                          "bg-light-red",
                          { disabled: _vm.data.length == 0 },
                        ],
                        attrs: {
                          href: "#",
                          title: _vm.$t(
                            "__.Components.child.grid.grid_vue_html.gerarPDFdosArq"
                          ),
                        },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            _vm.onGerarPdfClick()
                          },
                        },
                      },
                      [
                        _c("i", {
                          staticClass: "fa fa-file-pdf",
                          attrs: { "aria-hidden": "true" },
                        }),
                      ]
                    )
                  : _vm._e(),
              ]),
            ],
            2
          ),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "table-body" }, [
        _c(
          "table",
          {
            staticClass: "table table-condensed table-striped",
            attrs: { id: "table" + _vm.gridNameId, name: _vm.gridNameId },
          },
          [
            _c("caption", { staticClass: "tableH2" }, [
              _c("h2", [_vm._v(_vm._s(_vm.tituloPagina))]),
            ]),
            _vm._v(" "),
            (_vm.status != " " && _vm.status != "" && _vm.status != null) ||
            (_vm.caixa != " " && _vm.caixa != "" && _vm.caixa != null)
              ? _c("caption", { staticClass: "ajusteTituloPrint" }, [
                  _vm.status != " " && _vm.status != "" && _vm.status != null
                    ? _c("p", [
                        _vm._v(
                          "\n                    Status:\n                    " +
                            _vm._s(
                              _vm.status == "0"
                                ? "A Faturar"
                                : _vm.status == "1"
                                ? "Faturado"
                                : _vm.status == "2"
                                ? "Concluído"
                                : _vm.status == "3"
                                ? "Cancelado"
                                : "Vencidos"
                            ) +
                            "\n                "
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.caixa != " " && _vm.caixa != "" && _vm.caixa != null
                    ? _c("p", [
                        _vm._v(
                          "\n                    " +
                            _vm._s(
                              _vm.$t(
                                "__.Components.child.grid.grid_vue_html.cash"
                              )
                            ) +
                            " " +
                            _vm._s(_vm.caixa) +
                            "\n                "
                        ),
                      ])
                    : _vm._e(),
                ])
              : _vm._e(),
            _vm._v(" "),
            (_vm.dataDe != " " && _vm.dataDe != "" && _vm.dataDe != null) ||
            (_vm.dataAte != " " && _vm.dataAte != "" && _vm.dataAte != null)
              ? _c("caption", { staticClass: "ajusteTituloPrint" }, [
                  _vm.dataDe != " " && _vm.dataDe != "" && _vm.dataDe != null
                    ? _c("p", [
                        _vm._v(
                          "\n                    " +
                            _vm._s(
                              _vm.$t(
                                "__.Components.child.grid.grid_vue_html.de"
                              )
                            ) +
                            " " +
                            _vm._s(_vm.dataDe) +
                            "\n                "
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.dataAte != " " && _vm.dataAte != "" && _vm.dataAte != null
                    ? _c("p", [
                        _vm._v(
                          "\n                    " +
                            _vm._s(
                              _vm.$t(
                                "__.Components.child.grid.grid_vue_html.ate"
                              )
                            ) +
                            " " +
                            _vm._s(_vm.dataAte) +
                            "\n                "
                        ),
                      ])
                    : _vm._e(),
                ])
              : _vm._e(),
            _vm._v(" "),
            (_vm.dataDe2 != " " && _vm.dataDe2 != "" && _vm.dataDe2 != null) ||
            (_vm.dataAte2 != " " && _vm.dataAte2 != "" && _vm.dataAte2 != null)
              ? _c("caption", { staticClass: "ajusteTituloPrint" }, [
                  _vm.dataDe2 != " " && _vm.dataDe2 != "" && _vm.dataDe2 != null
                    ? _c("p", [
                        _vm._v(
                          "\n                    " +
                            _vm._s(
                              _vm.$t(
                                "__.Components.child.grid.grid_vue_html.pgtoDe"
                              )
                            ) +
                            " " +
                            _vm._s(_vm.dataDe2) +
                            "\n                "
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.dataAte2 != " " &&
                  _vm.dataAte2 != "" &&
                  _vm.dataAte2 != null
                    ? _c("p", [
                        _vm._v(
                          "\n                    " +
                            _vm._s(
                              _vm.$t(
                                "__.Components.child.grid.grid_vue_html.ate"
                              )
                            ) +
                            " " +
                            _vm._s(_vm.dataAte2) +
                            "\n                "
                        ),
                      ])
                    : _vm._e(),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.grupo != " " && _vm.grupo != "" && _vm.grupo != null
              ? _c("caption", { staticClass: "ajusteTituloPrint" }, [
                  _c("p", [
                    _vm._v(
                      _vm._s(
                        _vm.$t("__.Components.child.grid.grid_vue_html.grupo")
                      ) +
                        " " +
                        _vm._s(_vm.grupo)
                    ),
                  ]),
                  _vm._v(" "),
                  _vm.subgrupo != null
                    ? _c("p", [
                        _vm._v(
                          "\n                    " +
                            _vm._s(
                              _vm.$t(
                                "__.Components.child.grid.grid_vue_html.subgrupo"
                              )
                            ) +
                            "\n                    " +
                            _vm._s(
                              _vm.subgrupo == 0
                                ? "Ativo"
                                : _vm.subgrupo == 1
                                ? "Flavorizante"
                                : "Veículos/Excipientes"
                            ) +
                            "\n                "
                        ),
                      ])
                    : _vm._e(),
                ])
              : _vm._e(),
            _vm._v(" "),
            (_vm.fornecedor != " " &&
              _vm.fornecedor != "" &&
              _vm.fornecedor != null) ||
            (_vm.categoria != " " &&
              _vm.categoria != "" &&
              _vm.categoria != null)
              ? _c("caption", { staticClass: "ajusteTituloPrint" }, [
                  _vm.fornecedor != " " &&
                  _vm.fornecedor != "" &&
                  _vm.fornecedor != null
                    ? _c("p", [
                        _vm._v(
                          "\n                    " +
                            _vm._s(
                              _vm.$t(
                                "__.Components.child.grid.grid_vue_html.fornecedor"
                              )
                            ) +
                            " " +
                            _vm._s(_vm.fornecedor) +
                            "\n                "
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.categoria != " " &&
                  _vm.categoria != "" &&
                  _vm.categoria != null
                    ? _c("p", [
                        _vm._v(
                          "\n                    " +
                            _vm._s(
                              _vm.$t(
                                "__.Components.child.grid.grid_vue_html.categoria"
                              )
                            ) +
                            " " +
                            _vm._s(_vm.categoria) +
                            "\n                "
                        ),
                      ])
                    : _vm._e(),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("thead", [
              _c(
                "tr",
                [
                  _vm.showSelectCheckbox
                    ? _c("th", { staticClass: "option-column" }, [
                        _vm.multipleSelect
                          ? _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.checkboxAll,
                                  expression: "checkboxAll",
                                },
                              ],
                              attrs: { type: "checkbox" },
                              domProps: {
                                checked: Array.isArray(_vm.checkboxAll)
                                  ? _vm._i(_vm.checkboxAll, null) > -1
                                  : _vm.checkboxAll,
                              },
                              on: {
                                change: function ($event) {
                                  var $$a = _vm.checkboxAll,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        (_vm.checkboxAll = $$a.concat([$$v]))
                                    } else {
                                      $$i > -1 &&
                                        (_vm.checkboxAll = $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1)))
                                    }
                                  } else {
                                    _vm.checkboxAll = $$c
                                  }
                                },
                              },
                            })
                          : _vm._e(),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.showExpand
                    ? _c("th", { staticClass: "option-column" })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm._l(_vm.columns, function (column, rowIndex) {
                    return _c(
                      "th",
                      {
                        key: rowIndex,
                        class: {
                          active: _vm.sortKey == column.value,
                          "column-hide": column.hidden,
                        },
                        style: {
                          cursor: _vm.showSortIcons ? "pointer" : "default",
                        },
                        attrs: { id: _vm.gridNameId + rowIndex },
                        on: {
                          click: function ($event) {
                            column.showSortIcon
                              ? _vm.sortBy(column.value)
                              : null
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n                        " +
                            _vm._s(_vm._f("capitalize")(column.text)) +
                            "\n                        "
                        ),
                        _c("i", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.showSortIcons && column.showSortIcon,
                              expression:
                                "showSortIcons && column.showSortIcon",
                            },
                          ],
                          class:
                            _vm.sortKey != column.value
                              ? "fa fa-sort"
                              : _vm.sortOrders[column.value] == -1
                              ? "fa fa-sort-amount-down-alt"
                              : "fa fa-sort-amount-up",
                          attrs: { "aria-hidden": "true" },
                        }),
                      ]
                    )
                  }),
                  _vm._v(" "),
                  _c(
                    "th",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.showActionColumn,
                          expression: "showActionColumn",
                        },
                      ],
                      staticClass: "option-column",
                    },
                    [
                      _vm._v(
                        "\n                        " +
                          _vm._s(
                            _vm.$t(
                              "__.Components.child.grid.grid_vue_html.acoes"
                            )
                          ) +
                          "\n                    "
                      ),
                    ]
                  ),
                ],
                2
              ),
            ]),
            _vm._v(" "),
            _vm._l(_vm.data, function (entry, rowIndex) {
              return _c(
                "tbody",
                {
                  key: rowIndex,
                  class: [
                    _vm.classes.length > rowIndex ? _vm.classes[rowIndex] : "",
                    { active: _vm.getLineActive(entry) },
                  ],
                },
                [
                  _c(
                    "tr",
                    { class: [{ "selectable-line": _vm.selectableLine }] },
                    [
                      _vm.showSelectCheckbox
                        ? _c(
                            "td",
                            {
                              staticClass: "option-column",
                              staticStyle: { position: "relative" },
                            },
                            [
                              _c("div", {
                                class: _vm.getCheckboxClass(entry),
                                on: {
                                  click: function ($event) {
                                    _vm.selectRow(entry)
                                  },
                                },
                              }),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.showExpand && _vm.readyToShowSubs
                        ? _c(
                            "td",
                            { staticClass: "option-column" },
                            [
                              _vm.getConditionShowSubData(entry)
                                ? [
                                    !_vm.extendedColumns[rowIndex]
                                      ? _c("i", {
                                          staticClass: "fa fa-plus-square",
                                          attrs: { "aria-hidden": "true" },
                                          on: {
                                            click: function ($event) {
                                              $event.preventDefault()
                                              _vm.expandSubColumns(rowIndex)
                                            },
                                          },
                                        })
                                      : _c("i", {
                                          staticClass: "fa fa-minus-square",
                                          attrs: { "aria-hidden": "true" },
                                          on: {
                                            click: function ($event) {
                                              $event.preventDefault()
                                              _vm.expandSubColumns(rowIndex)
                                            },
                                          },
                                        }),
                                  ]
                                : _vm._e(),
                            ],
                            2
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm._l(_vm.columns, function (column, columnIndex) {
                        return _c(
                          "td",
                          {
                            key: columnIndex,
                            class: [
                              { "column-hide": column.hidden },
                              _vm.gridNameId + columnIndex,
                            ],
                            style: {
                              cursor:
                                _vm.showSelectCheckbox || _vm.selectableLine
                                  ? "pointer"
                                  : "default",
                            },
                            attrs: { id: _vm.gridNameId + columnIndex },
                            on: {
                              click: function ($event) {
                                !column.editable ? _vm.selectRow(entry) : null
                              },
                            },
                          },
                          [
                            column.editable
                              ? [
                                  _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "tooltip",
                                          rawName: "v-tooltip",
                                          value: _vm.errors.first(
                                            "row" +
                                              rowIndex +
                                              "col" +
                                              columnIndex
                                          ),
                                          expression:
                                            "errors.first('row' + rowIndex + 'col' + columnIndex)",
                                        },
                                      ],
                                      staticClass: "form-group",
                                    },
                                    [
                                      column.type == _vm.gridColumnTypes.Money
                                        ? [
                                            _c("moeda-component", {
                                              directives: [
                                                {
                                                  name: "validate",
                                                  rawName: "v-validate",
                                                  value: column.validation,
                                                  expression:
                                                    "column.validation",
                                                },
                                              ],
                                              staticClass: "form-control",
                                              attrs: {
                                                id:
                                                  "row" +
                                                  rowIndex +
                                                  "col" +
                                                  columnIndex,
                                                name:
                                                  "row" +
                                                  rowIndex +
                                                  "col" +
                                                  columnIndex,
                                                disabled: column.disabled,
                                              },
                                              on: {
                                                input: function () {
                                                  return column.onChange
                                                    ? column.onChange(
                                                        _vm.data[rowIndex],
                                                        rowIndex
                                                      )
                                                    : null
                                                },
                                                change: function ($event) {
                                                  _vm.onchangemoney(
                                                    _vm.data[rowIndex][
                                                      column.value
                                                    ],
                                                    rowIndex,
                                                    column.value
                                                  )
                                                },
                                              },
                                              model: {
                                                value:
                                                  _vm.data[rowIndex][
                                                    column.value
                                                  ],
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.data[rowIndex],
                                                    column.value,
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "data[rowIndex][column.value]",
                                              },
                                            }),
                                          ]
                                        : column.type ==
                                          _vm.gridColumnTypes.Date
                                        ? [
                                            _c("date-time-picker-component", {
                                              directives: [
                                                {
                                                  name: "validate",
                                                  rawName: "v-validate",
                                                  value: "data",
                                                  expression: "'data'",
                                                },
                                              ],
                                              attrs: {
                                                id:
                                                  "row" +
                                                  rowIndex +
                                                  "col" +
                                                  columnIndex,
                                                name:
                                                  "row" +
                                                  rowIndex +
                                                  "col" +
                                                  columnIndex,
                                                disabled: column.disabled,
                                                gridEditable: true,
                                              },
                                              on: {
                                                input: function () {
                                                  return column.onChange
                                                    ? column.onChange(
                                                        _vm.data[rowIndex],
                                                        rowIndex
                                                      )
                                                    : null
                                                },
                                              },
                                              model: {
                                                value:
                                                  _vm.data[rowIndex][
                                                    column.value
                                                  ],
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.data[rowIndex],
                                                    column.value,
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "data[rowIndex][column.value]",
                                              },
                                            }),
                                          ]
                                        : column.type ==
                                          _vm.gridColumnTypes.Decimal
                                        ? [
                                            _c("decimal-component", {
                                              directives: [
                                                {
                                                  name: "validate",
                                                  rawName: "v-validate",
                                                  value: column.validation,
                                                  expression:
                                                    "column.validation",
                                                },
                                              ],
                                              staticClass: "form-control",
                                              attrs: {
                                                id:
                                                  "row" +
                                                  rowIndex +
                                                  "col" +
                                                  columnIndex,
                                                name:
                                                  "row" +
                                                  rowIndex +
                                                  "col" +
                                                  columnIndex,
                                                disabled: column.disabled,
                                              },
                                              on: {
                                                input: function () {
                                                  return column.onChange
                                                    ? column.onChange(
                                                        _vm.data[rowIndex],
                                                        rowIndex
                                                      )
                                                    : null
                                                },
                                              },
                                              model: {
                                                value:
                                                  _vm.data[rowIndex][
                                                    column.value
                                                  ],
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.data[rowIndex],
                                                    column.value,
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "data[rowIndex][column.value]",
                                              },
                                            }),
                                          ]
                                        : column.type ==
                                          _vm.gridColumnTypes.Boolean
                                        ? [
                                            _c("checkbox-component", {
                                              directives: [
                                                {
                                                  name: "validate",
                                                  rawName: "v-validate",
                                                  value: column.validation,
                                                  expression:
                                                    "column.validation",
                                                },
                                              ],
                                              attrs: {
                                                id:
                                                  "row" +
                                                  rowIndex +
                                                  "col" +
                                                  columnIndex,
                                                name:
                                                  "row" +
                                                  rowIndex +
                                                  "col" +
                                                  columnIndex,
                                                disabled: column.disabled,
                                              },
                                              on: {
                                                input: function () {
                                                  return column.onChange
                                                    ? column.onChange(
                                                        _vm.data[rowIndex],
                                                        rowIndex
                                                      )
                                                    : null
                                                },
                                              },
                                              model: {
                                                value:
                                                  _vm.data[rowIndex][
                                                    column.value
                                                  ],
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.data[rowIndex],
                                                    column.value,
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "data[rowIndex][column.value]",
                                              },
                                            }),
                                          ]
                                        : column.type ==
                                          _vm.gridColumnTypes.Combo
                                        ? [
                                            _c("combo-component", {
                                              attrs: {
                                                id:
                                                  "row" +
                                                  rowIndex +
                                                  "col" +
                                                  columnIndex,
                                                name:
                                                  "row" +
                                                  rowIndex +
                                                  "col" +
                                                  columnIndex,
                                                data: column.comboOptions(
                                                  _vm.data[rowIndex]
                                                ),
                                                defaultValue:
                                                  _vm.getComboDefaultValue(
                                                    column,
                                                    _vm.data,
                                                    rowIndex
                                                  ),
                                                searchable: true,
                                                disabled: column.disabled,
                                              },
                                              on: {
                                                input: function () {
                                                  return column.onChange
                                                    ? column.onChange(
                                                        _vm.data[rowIndex],
                                                        rowIndex
                                                      )
                                                    : null
                                                },
                                              },
                                              model: {
                                                value:
                                                  _vm.data[rowIndex][
                                                    column.value
                                                  ],
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.data[rowIndex],
                                                    column.value,
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "data[rowIndex][column.value]",
                                              },
                                            }),
                                          ]
                                        : column.type ==
                                          _vm.gridColumnTypes.Integer
                                        ? [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.data[rowIndex][
                                                      column.value
                                                    ],
                                                  expression:
                                                    "data[rowIndex][column.value]",
                                                },
                                                {
                                                  name: "validate",
                                                  rawName: "v-validate",
                                                  value: column.validation,
                                                  expression:
                                                    "column.validation",
                                                },
                                              ],
                                              staticClass: "form-control",
                                              attrs: {
                                                type: "number",
                                                id:
                                                  "row" +
                                                  rowIndex +
                                                  "col" +
                                                  columnIndex,
                                                name:
                                                  "row" +
                                                  rowIndex +
                                                  "col" +
                                                  columnIndex,
                                                disabled: column.disabled,
                                                autocomplete: "off",
                                              },
                                              domProps: {
                                                value:
                                                  _vm.data[rowIndex][
                                                    column.value
                                                  ],
                                              },
                                              on: {
                                                input: [
                                                  function ($event) {
                                                    if (
                                                      $event.target.composing
                                                    ) {
                                                      return
                                                    }
                                                    _vm.$set(
                                                      _vm.data[rowIndex],
                                                      column.value,
                                                      $event.target.value
                                                    )
                                                  },
                                                  function () {
                                                    return column.onChange
                                                      ? column.onChange(
                                                          _vm.data[rowIndex],
                                                          rowIndex
                                                        )
                                                      : null
                                                  },
                                                ],
                                              },
                                            }),
                                          ]
                                        : [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.data[rowIndex][
                                                      column.value
                                                    ],
                                                  expression:
                                                    "data[rowIndex][column.value]",
                                                },
                                                {
                                                  name: "validate",
                                                  rawName: "v-validate",
                                                  value: column.validation,
                                                  expression:
                                                    "column.validation",
                                                },
                                              ],
                                              staticClass: "form-control",
                                              attrs: {
                                                type: "text",
                                                id:
                                                  "row" +
                                                  rowIndex +
                                                  "col" +
                                                  columnIndex,
                                                name:
                                                  "row" +
                                                  rowIndex +
                                                  "col" +
                                                  columnIndex,
                                                disabled: column.disabled,
                                                autocomplete: "off",
                                              },
                                              domProps: {
                                                value:
                                                  _vm.data[rowIndex][
                                                    column.value
                                                  ],
                                              },
                                              on: {
                                                input: [
                                                  function ($event) {
                                                    if (
                                                      $event.target.composing
                                                    ) {
                                                      return
                                                    }
                                                    _vm.$set(
                                                      _vm.data[rowIndex],
                                                      column.value,
                                                      $event.target.value
                                                    )
                                                  },
                                                  function () {
                                                    return column.onChange
                                                      ? column.onChange(
                                                          _vm.data[rowIndex],
                                                          rowIndex
                                                        )
                                                      : null
                                                  },
                                                ],
                                              },
                                            }),
                                          ],
                                    ],
                                    2
                                  ),
                                ]
                              : [
                                  column.type ==
                                  _vm.gridColumnTypes.MoneyWithSignal
                                    ? [
                                        _c(
                                          "span",
                                          {
                                            style: {
                                              color:
                                                entry[column.value] == 0
                                                  ? "black"
                                                  : entry[column.value] > 0
                                                  ? "limegreen"
                                                  : "red",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                                    " +
                                                _vm._s(
                                                  _vm.formatData(
                                                    entry[column.value],
                                                    column.type,
                                                    column.minimumFractionDigits
                                                  )
                                                ) +
                                                "\n                                "
                                            ),
                                          ]
                                        ),
                                      ]
                                    : column.type == _vm.gridColumnTypes.Raw
                                    ? [
                                        _c("pre", {
                                          staticClass: "grid-raw",
                                          domProps: {
                                            textContent: _vm._s(
                                              entry[column.value]
                                            ),
                                          },
                                        }),
                                      ]
                                    : column.parent == "" &&
                                      (!column.searchbleColorControl ||
                                        !_vm.containValue(
                                          entry[column.value],
                                          column.type
                                        ))
                                    ? [
                                        _vm._v(
                                          "\n                                " +
                                            _vm._s(
                                              _vm.formatData(
                                                entry[column.value],
                                                column.type,
                                                column.minimumFractionDigits
                                              )
                                            ) +
                                            "\n                            "
                                        ),
                                      ]
                                    : column.parent == "" &&
                                      column.searchbleColorControl &&
                                      _vm.containValue(
                                        entry[column.value],
                                        column.type
                                      )
                                    ? [
                                        _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              "background-color": "yellow",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                                    " +
                                                _vm._s(
                                                  _vm.formatData(
                                                    entry[column.value],
                                                    column.type,
                                                    column.minimumFractionDigits
                                                  )
                                                ) +
                                                "\n                                "
                                            ),
                                          ]
                                        ),
                                      ]
                                    : [
                                        _vm._v(
                                          "\n                                " +
                                            _vm._s(
                                              _vm.formatData(
                                                entry[column.parent][
                                                  column.value
                                                ],
                                                column.type,
                                                column.minimumFractionDigits
                                              )
                                            ) +
                                            "\n                            "
                                        ),
                                      ],
                                ],
                          ],
                          2
                        )
                      }),
                      _vm._v(" "),
                      _c(
                        "td",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.showActionColumn,
                              expression: "showActionColumn",
                            },
                          ],
                          staticClass: "option-column",
                        },
                        [
                          _c(
                            "div",
                            [
                              _vm._l(
                                _vm.extraActions,
                                function (actionEntry, actionRowIndex) {
                                  return [
                                    actionEntry.length > rowIndex
                                      ? _c(
                                          "a",
                                          {
                                            key: actionRowIndex,
                                            style: {
                                              cursor: actionEntry[rowIndex]
                                                .enabled
                                                ? "pointer"
                                                : "not-allowed",
                                            },
                                            attrs: {
                                              href: "#",
                                              title:
                                                actionEntry[rowIndex]
                                                  .tooltipText,
                                            },
                                          },
                                          [
                                            actionEntry[rowIndex].enabled
                                              ? _c("i", {
                                                  class:
                                                    actionEntry[rowIndex]
                                                      .iconClass,
                                                  style: {
                                                    color:
                                                      actionEntry[rowIndex]
                                                        .color,
                                                  },
                                                  attrs: {
                                                    "aria-hidden": "true",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      $event.preventDefault()
                                                      _vm.extraAction(
                                                        actionEntry[rowIndex]
                                                          .name,
                                                        entry,
                                                        rowIndex
                                                      )
                                                    },
                                                  },
                                                })
                                              : _c("i", {
                                                  class:
                                                    "disabled " +
                                                    actionEntry[rowIndex]
                                                      .iconClass,
                                                  staticStyle: {
                                                    "pointer-events": "none",
                                                    cursor: "default",
                                                    color: "darkgray",
                                                  },
                                                  attrs: {
                                                    "aria-hidden": "true",
                                                  },
                                                }),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]
                                }
                              ),
                              _vm._v(" "),
                              _c(
                                "a",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        _vm.auxShowEditItem ||
                                        (_vm.auxSomenteConsulta &&
                                          !_vm.auxSomenteListagem),
                                      expression:
                                        "auxShowEditItem || (auxSomenteConsulta && !auxSomenteListagem)",
                                    },
                                  ],
                                  attrs: {
                                    href: "#",
                                    title: _vm.auxSomenteConsulta
                                      ? "Consultar"
                                      : _vm.editItemTitle,
                                  },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      _vm.edit(entry)
                                    },
                                  },
                                },
                                [
                                  _c("i", {
                                    class:
                                      "color-blue fa " +
                                      (_vm.auxSomenteConsulta
                                        ? "fa-search"
                                        : _vm.editItemIcon),
                                    attrs: { "aria-hidden": "true" },
                                  }),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "a",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.auxShowRemoveItem,
                                      expression: "auxShowRemoveItem",
                                    },
                                  ],
                                  attrs: {
                                    href: "#",
                                    title: _vm.removeItemTitle,
                                  },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      _vm.remove(entry)
                                    },
                                  },
                                },
                                [
                                  _c("i", {
                                    class: "color-red fa " + _vm.removeItemIcon,
                                    attrs: { "aria-hidden": "true" },
                                  }),
                                ]
                              ),
                            ],
                            2
                          ),
                        ]
                      ),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _vm.readyToShowSubs && _vm.getConditionShowSubData(entry)
                    ? _c("tr", [
                        _vm.extendedColumns[rowIndex]
                          ? _c(
                              "td",
                              { attrs: { colspan: _vm.numberOfColumns + 1 } },
                              [
                                _c(
                                  "table",
                                  {
                                    staticClass:
                                      "table table-condensed subTable-striped",
                                  },
                                  [
                                    _c(
                                      "thead",
                                      [
                                        _vm.showSubColumnsSelectCheckbox
                                          ? _c("th")
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm._l(
                                          _vm.subColumns,
                                          function (column) {
                                            return _c(
                                              "th",
                                              {
                                                key: column.value,
                                                class: {
                                                  active:
                                                    _vm.sortKey == column.value,
                                                  "column-hide": column.hidden,
                                                },
                                                style: {
                                                  cursor: _vm.showSortIcons
                                                    ? "pointer"
                                                    : "default",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                    " +
                                                    _vm._s(
                                                      _vm._f("capitalize")(
                                                        column.text
                                                      )
                                                    ) +
                                                    "\n                                "
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                      ],
                                      2
                                    ),
                                    _vm._v(" "),
                                    _vm._l(
                                      _vm.data[rowIndex][_vm.subDataName],
                                      function (entry, rowSubIndex) {
                                        return _c(
                                          "tbody",
                                          { key: rowSubIndex },
                                          [
                                            _vm.showSubColumnsSelectCheckbox
                                              ? _c(
                                                  "td",
                                                  {
                                                    staticClass:
                                                      "option-column",
                                                    staticStyle: {
                                                      position: "relative",
                                                    },
                                                  },
                                                  [
                                                    _c("div", {
                                                      class:
                                                        _vm.getCheckboxClass(
                                                          entry
                                                        ),
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          _vm.selectRow(entry)
                                                        },
                                                      },
                                                    }),
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm._v(" "),
                                            _vm._l(
                                              _vm.subColumns,
                                              function (
                                                subColumn,
                                                subColumnIndex
                                              ) {
                                                return _c(
                                                  "td",
                                                  {
                                                    key: subColumnIndex,
                                                    class: {
                                                      "column-hide":
                                                        subColumn.hidden,
                                                    },
                                                    style: {
                                                      cursor:
                                                        _vm.showSubColumnsSelectCheckbox
                                                          ? "pointer"
                                                          : "default",
                                                      backgroundColor:
                                                        _vm.getHighlightRow(
                                                          entry,
                                                          rowIndex,
                                                          rowSubIndex
                                                        ),
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        _vm.selectRow(entry)
                                                      },
                                                    },
                                                  },
                                                  [
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "form-group",
                                                        },
                                                        [
                                                          subColumn.type ==
                                                          _vm.gridColumnTypes
                                                            .MoneyWithSignal
                                                            ? [
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    style: {
                                                                      color:
                                                                        entry[
                                                                          subColumn
                                                                            .value
                                                                        ] >= 0
                                                                          ? "limegreen"
                                                                          : "red",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n                                                    " +
                                                                        _vm._s(
                                                                          _vm.formatData(
                                                                            entry[
                                                                              subColumn
                                                                                .value
                                                                            ],
                                                                            subColumn.type,
                                                                            subColumn.minimumFractionDigits
                                                                          )
                                                                        ) +
                                                                        "\n                                                "
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            : subColumn.type ==
                                                                _vm
                                                                  .gridColumnTypes
                                                                  .Decimal &&
                                                              subColumn.editable
                                                            ? [
                                                                _c(
                                                                  "decimal-component",
                                                                  {
                                                                    directives:
                                                                      [
                                                                        {
                                                                          name: "validate",
                                                                          rawName:
                                                                            "v-validate",
                                                                          value:
                                                                            subColumn.validation,
                                                                          expression:
                                                                            "subColumn.validation",
                                                                        },
                                                                      ],
                                                                    staticClass:
                                                                      "form-control",
                                                                    attrs: {
                                                                      id:
                                                                        "row" +
                                                                        rowIndex +
                                                                        "col" +
                                                                        entry[
                                                                          subColumn
                                                                            .value
                                                                        ],
                                                                      name:
                                                                        "row" +
                                                                        rowIndex +
                                                                        "col" +
                                                                        subColumnIndex,
                                                                    },
                                                                    on: {
                                                                      input:
                                                                        function () {
                                                                          return subColumn.onChange
                                                                            ? subColumn.onChange(
                                                                                entry[
                                                                                  subColumn
                                                                                    .value
                                                                                ],
                                                                                rowIndex,
                                                                                rowSubIndex
                                                                              )
                                                                            : null
                                                                        },
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        entry[
                                                                          subColumn
                                                                            .value
                                                                        ],
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            entry,
                                                                            subColumn.value,
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "entry[subColumn.value]",
                                                                    },
                                                                  }
                                                                ),
                                                              ]
                                                            : subColumn.type ==
                                                                _vm
                                                                  .gridColumnTypes
                                                                  .Combo &&
                                                              subColumn.editable
                                                            ? [
                                                                _c(
                                                                  "combo-component",
                                                                  {
                                                                    attrs: {
                                                                      id:
                                                                        "row" +
                                                                        rowIndex +
                                                                        "col" +
                                                                        subColumnIndex,
                                                                      name:
                                                                        "row" +
                                                                        rowIndex +
                                                                        "col" +
                                                                        subColumnIndex,
                                                                      data: subColumn.comboOptions(
                                                                        _vm
                                                                          .data[
                                                                          rowIndex
                                                                        ]
                                                                      ),
                                                                      defaultValue:
                                                                        _vm.getComboDefaultValue(
                                                                          subColumn,
                                                                          _vm.data,
                                                                          rowIndex
                                                                        ),
                                                                      searchable: true,
                                                                      disabled:
                                                                        subColumn.disabled,
                                                                    },
                                                                    on: {
                                                                      input:
                                                                        function () {
                                                                          return subColumn.onChange
                                                                            ? subColumn.onChange(
                                                                                _vm
                                                                                  .data[
                                                                                  rowIndex
                                                                                ],
                                                                                rowIndex,
                                                                                rowSubIndex
                                                                              )
                                                                            : null
                                                                        },
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        entry[
                                                                          subColumn
                                                                            .value
                                                                        ],
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            entry,
                                                                            subColumn.value,
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "entry[subColumn.value]",
                                                                    },
                                                                  }
                                                                ),
                                                              ]
                                                            : subColumn.type ==
                                                                _vm
                                                                  .gridColumnTypes
                                                                  .Money &&
                                                              subColumn.editable
                                                            ? [
                                                                _c(
                                                                  "moeda-component",
                                                                  {
                                                                    directives:
                                                                      [
                                                                        {
                                                                          name: "validate",
                                                                          rawName:
                                                                            "v-validate",
                                                                          value:
                                                                            subColumn.validation,
                                                                          expression:
                                                                            "subColumn.validation",
                                                                        },
                                                                      ],
                                                                    staticClass:
                                                                      "form-control",
                                                                    attrs: {
                                                                      id:
                                                                        "row" +
                                                                        rowIndex +
                                                                        "col" +
                                                                        entry[
                                                                          subColumn
                                                                            .value
                                                                        ],
                                                                      name:
                                                                        "row" +
                                                                        rowIndex +
                                                                        "col" +
                                                                        subColumnIndex,
                                                                    },
                                                                    on: {
                                                                      input:
                                                                        function () {
                                                                          return subColumn.onChange
                                                                            ? subColumn.onChange(
                                                                                entry[
                                                                                  subColumn
                                                                                    .value
                                                                                ],
                                                                                rowIndex,
                                                                                rowSubIndex
                                                                              )
                                                                            : null
                                                                        },
                                                                      change:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          _vm.onchangemoney(
                                                                            entry[
                                                                              subColumn
                                                                                .value
                                                                            ][
                                                                              subColumn
                                                                                .value
                                                                            ],
                                                                            rowIndex,
                                                                            subColumn.value
                                                                          )
                                                                        },
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        entry[
                                                                          subColumn
                                                                            .value
                                                                        ],
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            entry,
                                                                            subColumn.value,
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "entry[subColumn.value]",
                                                                    },
                                                                  }
                                                                ),
                                                              ]
                                                            : subColumn.type ==
                                                                _vm
                                                                  .gridColumnTypes
                                                                  .Date &&
                                                              subColumn.editable
                                                            ? [
                                                                _c(
                                                                  "date-time-picker-component",
                                                                  {
                                                                    directives:
                                                                      [
                                                                        {
                                                                          name: "validate",
                                                                          rawName:
                                                                            "v-validate",
                                                                          value:
                                                                            subColumn.validation,
                                                                          expression:
                                                                            "subColumn.validation",
                                                                        },
                                                                      ],
                                                                    staticStyle:
                                                                      {
                                                                        "background-color":
                                                                          "#fff",
                                                                      },
                                                                    attrs: {
                                                                      id:
                                                                        "row" +
                                                                        rowIndex +
                                                                        "col" +
                                                                        entry[
                                                                          subColumn
                                                                            .value
                                                                        ],
                                                                      name:
                                                                        "row" +
                                                                        rowIndex +
                                                                        "col" +
                                                                        subColumnIndex,
                                                                      disabled:
                                                                        subColumn.disabled,
                                                                    },
                                                                    on: {
                                                                      input:
                                                                        function () {
                                                                          return subColumn.onChange
                                                                            ? subColumn.onChange(
                                                                                entry[
                                                                                  subColumn
                                                                                    .value
                                                                                ],
                                                                                rowIndex,
                                                                                rowSubIndex
                                                                              )
                                                                            : null
                                                                        },
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        entry[
                                                                          subColumn
                                                                            .value
                                                                        ],
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            entry,
                                                                            subColumn.value,
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "entry[subColumn.value]",
                                                                    },
                                                                  }
                                                                ),
                                                              ]
                                                            : [
                                                                _vm._v(
                                                                  "\n                                                " +
                                                                    _vm._s(
                                                                      _vm.formatData(
                                                                        entry[
                                                                          subColumn
                                                                            .value
                                                                        ],
                                                                        subColumn.type,
                                                                        subColumn.minimumFractionDigits
                                                                      )
                                                                    ) +
                                                                    "\n                                            "
                                                                ),
                                                              ],
                                                        ],
                                                        2
                                                      ),
                                                    ],
                                                  ],
                                                  2
                                                )
                                              }
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "td",
                                              {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value: _vm.showActionColumn,
                                                    expression:
                                                      "showActionColumn",
                                                  },
                                                ],
                                                staticClass: "option-column",
                                                style: {
                                                  backgroundColor:
                                                    _vm.getHighlightRow(
                                                      entry,
                                                      rowIndex,
                                                      rowSubIndex
                                                    ),
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  [
                                                    _vm._l(
                                                      _vm.extraSubActions,
                                                      function (
                                                        actionEntry,
                                                        actionRowIndex
                                                      ) {
                                                        return [
                                                          actionEntry.length >
                                                          rowIndex
                                                            ? _c(
                                                                "a",
                                                                {
                                                                  key: actionRowIndex,
                                                                  attrs: {
                                                                    href: "#",
                                                                    title:
                                                                      actionEntry[
                                                                        rowIndex
                                                                      ]
                                                                        .tooltipText,
                                                                  },
                                                                },
                                                                [
                                                                  actionEntry[
                                                                    rowIndex
                                                                  ].enabled
                                                                    ? _c("i", {
                                                                        class:
                                                                          actionEntry[
                                                                            rowIndex
                                                                          ]
                                                                            .iconClass,
                                                                        style: {
                                                                          color:
                                                                            actionEntry[
                                                                              rowIndex
                                                                            ]
                                                                              .color,
                                                                        },
                                                                        attrs: {
                                                                          "aria-hidden":
                                                                            "true",
                                                                        },
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              $event.preventDefault()
                                                                              _vm.extraSubAction(
                                                                                actionEntry[
                                                                                  rowIndex
                                                                                ]
                                                                                  .name,
                                                                                entry,
                                                                                rowIndex
                                                                              )
                                                                            },
                                                                        },
                                                                      })
                                                                    : _c("i", {
                                                                        class:
                                                                          "disabled " +
                                                                          actionEntry[
                                                                            rowIndex
                                                                          ]
                                                                            .iconClass,
                                                                        staticStyle:
                                                                          {
                                                                            "pointer-events":
                                                                              "none",
                                                                            cursor:
                                                                              "default",
                                                                            color:
                                                                              "darkgray",
                                                                          },
                                                                        attrs: {
                                                                          "aria-hidden":
                                                                            "true",
                                                                        },
                                                                      }),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                        ]
                                                      }
                                                    ),
                                                  ],
                                                  2
                                                ),
                                              ]
                                            ),
                                          ],
                                          2
                                        )
                                      }
                                    ),
                                  ],
                                  2
                                ),
                              ]
                            )
                          : _vm._e(),
                      ])
                    : _vm._e(),
                ]
              )
            }),
          ],
          2
        ),
      ]),
      _vm._v(" "),
      _c("pagination-component", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > _vm.pageSize || _vm.controlaPageSize,
            expression: "total > pageSize || controlaPageSize",
          },
        ],
        staticClass: "table-pagination",
        attrs: {
          "page-index": _vm.pageIndex,
          "page-size": _vm.pageSize,
          total: _vm.total,
          controlaPageSize: _vm.controlaPageSize,
        },
        on: {
          "change-page": _vm.onChangePage,
          "change-page-size": _vm.onChangePageSize,
        },
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showTableFoot,
              expression: "showTableFoot",
            },
          ],
          staticClass: "table-foot",
        },
        [
          _vm._t("footer", [
            _c("div", { staticClass: "pagination-info" }, [
              _vm._v(
                "\n                " +
                  _vm._s(
                    _vm.$t("__.Components.child.grid.grid_vue_html.exibindo")
                  ) +
                  " " +
                  _vm._s(_vm.data.length) +
                  "\n                " +
                  _vm._s(_vm.$t("__.Components.child.grid.grid_vue_html.dee")) +
                  " " +
                  _vm._s(_vm.total) +
                  "\n                " +
                  _vm._s(
                    _vm.$t("__.Components.child.grid.grid_vue_html.registros")
                  ) +
                  "\n            "
              ),
            ]),
            _vm._v(" "),
            _vm.auxShowAddNewButton && !_vm.showTableHead
              ? _c(
                  "button",
                  {
                    staticClass: "btn btn-primary",
                    attrs: { type: "button" },
                    on: { click: _vm.create },
                  },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(
                          _vm.$t(
                            "__.Components.child.grid.grid_vue_html.addNovo"
                          )
                        ) +
                        "\n                "
                    ),
                    _c("i", {
                      staticClass: "fa fa-right fa-plus-circle",
                      attrs: { "aria-hidden": "true" },
                    }),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm._t("footer-extra-options"),
          ]),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }