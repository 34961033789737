var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "shortcutComponent",
    {
      ref: "shortcutComponent",
      attrs: { showAddNew: false, showBtnOk: false, modalClass: "lg-modal" },
    },
    [
      _c(
        "div",
        { attrs: { slot: "component" }, slot: "component" },
        [
          _c("fieldset", { staticClass: "header" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-sm-12 newbtn" }, [
                _c("div", { staticClass: "form-group" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-info",
                      attrs: { type: "button" },
                      on: { click: _vm.onMarkAllAsReaded },
                    },
                    [
                      _vm._v(
                        "\n                            Marcar todas como lida\n                        "
                      ),
                    ]
                  ),
                ]),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _vm._l(_vm.notificacoes, function (item) {
            return _c("div", { key: item.id, staticClass: "item-container" }, [
              _c("div", { staticStyle: { width: "90%" } }, [
                _c("strong", [_vm._v(_vm._s(item.title))]),
                _vm._v(" "),
                _c("div", [_vm._v(_vm._s(item.text))]),
              ]),
              _vm._v(" "),
              _c("div", { staticStyle: { width: "10%" } }, [
                _c("div", { staticClass: "item-read" }, [
                  !item.readed
                    ? _c("div", {
                        staticClass: "item-unreaded",
                        on: {
                          click: function ($event) {
                            _vm.onMarkAsReaded(item.id)
                          },
                        },
                      })
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _c("strong", [
                  _vm._v(_vm._s(_vm.formatDate(item.dataCriacao))),
                ]),
                _vm._v(" "),
                _c("div", [_vm._v(_vm._s(item.franquiaNome))]),
              ]),
            ])
          }),
          _vm._v(" "),
          _vm.total > _vm.pageSize * _vm.pageIndex
            ? _c(
                "button",
                {
                  staticClass: "show-more",
                  attrs: { type: "button" },
                  on: { click: _vm.onShowMore },
                },
                [_vm._v("\n            Ver mais notificações\n        ")]
              )
            : _vm._e(),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }