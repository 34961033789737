import Vue from "vue";
import { mapMutations, mapState } from "vuex";

import fieldsetComponent from "@/components/child/form/fieldset.vue";
import moedaComponent from "@/components/child/form/moeda.vue";
import { Component, Watch } from "@/decorators";
import NotaFiscalEletronicaEmissaoModel, { Retencoes } from "@/models/notaFiscalEletronicaEmissaoModel";
import { AppState, Mutations } from "@/store/store";

import "../crud/crud.scss";

@Component({
    components: {
        moedaComponent,
        fieldsetComponent,
    },
    computed: mapState<AppState>({
        nota: state => state.nfeContext.nota,
        consulta: state => state.nfeContext.consulta,
        emissaoVenda: state => state.nfeContext.emissaoVenda,
    }),
    methods: mapMutations(["SET_NOTA"] as Mutations),
})
export default class RetencoesComponent extends Vue {
    // State computed props
    nota: NotaFiscalEletronicaEmissaoModel;
    consulta: boolean;
    emissaoVenda: boolean;
    SET_NOTA: (nota: NotaFiscalEletronicaEmissaoModel) => void;

    private model = new Retencoes();

    // @ts-ignore
    @Watch("nota.retencoes", { deep: true })
    private onChangeData() {
        this.model = this.nota.retencoes;
    }

    // @ts-ignore
    @Watch("model", { deep: true })
    private onChangeModel() {
        this.nota.retencoes = this.model;
        this.SET_NOTA(this.nota);
    }
}
