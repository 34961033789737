var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "crud crud-edit" }, [
    _c(
      "form",
      {
        attrs: { novalidate: "novalidate" },
        on: {
          submit: function ($event) {
            $event.preventDefault()
            _vm.save()
          },
        },
      },
      [
        _c("fieldset", [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-sm-4" }, [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "tooltip",
                      rawName: "v-tooltip",
                      value: _vm.errors.first("pdvId"),
                      expression: "errors.first('pdvId')",
                    },
                  ],
                  staticClass: "form-group",
                },
                [
                  _c(
                    "label",
                    {
                      staticClass: "control-label",
                      attrs: { for: "pdvId", title: _vm.$t("__.obrigatorio") },
                    },
                    [
                      _c("span", { staticStyle: { color: "red" } }, [
                        _vm._v("* "),
                      ]),
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "__.Components.parent.sessaoCaixa.aberturaCaixa_vue_html.pontoVenda"
                          )
                        )
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("combo-component", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'",
                      },
                    ],
                    attrs: {
                      id: "pdvId",
                      name: "pdvId",
                      data: _vm.pdvOptions,
                      searchable: true,
                    },
                    model: {
                      value: _vm.model.pdvId,
                      callback: function ($$v) {
                        _vm.$set(_vm.model, "pdvId", $$v)
                      },
                      expression: "model.pdvId",
                    },
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-sm-4" }, [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "tooltip",
                      rawName: "v-tooltip",
                      value: _vm.errors.first("saldoInicial"),
                      expression: "errors.first('saldoInicial')",
                    },
                  ],
                  staticClass: "form-group",
                },
                [
                  _c(
                    "label",
                    {
                      staticClass: "control-label",
                      attrs: {
                        for: "saldoInicial",
                        title: _vm.$t("__.obrigatorio"),
                      },
                    },
                    [
                      _c("span", { staticStyle: { color: "red" } }, [
                        _vm._v("* "),
                      ]),
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "__.Components.parent.sessaoCaixa.aberturaCaixa_vue_html.saldoInicial"
                          )
                        )
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("moeda-component", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required|maior_igual_a_zero",
                        expression: "'required|maior_igual_a_zero'",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: { id: "saldoInicial", name: "saldoInicial" },
                    model: {
                      value: _vm.model.saldoInicial,
                      callback: function ($$v) {
                        _vm.$set(_vm.model, "saldoInicial", $$v)
                      },
                      expression: "model.saldoInicial",
                    },
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _vm.model.saldoInicial != _vm.saldoSugestao
              ? _c("div", { staticClass: "col-sm-4" }, [
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c("label", { attrs: { for: "chkGerarSaldo" } }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "__.Components.parent.sessaoCaixa.aberturaCaixa_vue_html.gerarDifSaldoInicial"
                            )
                          )
                        ),
                      ]),
                      _vm._v(" "),
                      _c("checkbox-component", {
                        attrs: { id: "chkGerarSaldo", name: "chkGerarSaldo" },
                        model: {
                          value: _vm.model.gerarDiferencaSaldo,
                          callback: function ($$v) {
                            _vm.$set(_vm.model, "gerarDiferencaSaldo", $$v)
                          },
                          expression: "model.gerarDiferencaSaldo",
                        },
                      }),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
          ]),
          _vm._v(" "),
          _vm.model.gerarDiferencaSaldo &&
          _vm.model.saldoInicial != _vm.saldoSugestao
            ? _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-sm-4" }, [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "tooltip",
                          rawName: "v-tooltip",
                          value: _vm.errors.first("formaRecebimentoId"),
                          expression: "errors.first('formaRecebimentoId')",
                        },
                      ],
                      staticClass: "form-group",
                    },
                    [
                      _c(
                        "label",
                        {
                          staticClass: "control-label",
                          attrs: {
                            for: "formaRecebimentoId",
                            title: _vm.$t("__.obrigatorio"),
                          },
                        },
                        [
                          _c("span", { staticStyle: { color: "red" } }, [
                            _vm._v("* "),
                          ]),
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "__.Components.parent.sessaoCaixa.aberturaCaixa_vue_html.formaRecebiTransferencia"
                              )
                            )
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _vm.model.saldoInicial < _vm.saldoSugestao
                        ? _c("data-tooltip-component", {
                            attrs: {
                              text: _vm.$t(
                                "__.Components.parent.sessaoCaixa.aberturaCaixa_vue_html.formaRecebiOrigemDifCaixa"
                              ),
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _c("combo-component", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required",
                            expression: "'required'",
                          },
                        ],
                        attrs: {
                          id: "formaRecebimentoId",
                          name: "formaRecebimentoId",
                          data: _vm.formaRecebimentoOptions,
                          searchable: true,
                          refresh: _vm.loadFormasRecebimento,
                        },
                        model: {
                          value: _vm.model.formaRecebimentoId,
                          callback: function ($$v) {
                            _vm.$set(_vm.model, "formaRecebimentoId", $$v)
                          },
                          expression: "model.formaRecebimentoId",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _vm.model.saldoInicial < _vm.saldoSugestao
                  ? _c("div", { staticClass: "col-sm-4" }, [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: _vm.errors.first("contaCorrenteId"),
                              expression: "errors.first('contaCorrenteId')",
                            },
                          ],
                          staticClass: "form-group",
                        },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "control-label",
                              attrs: {
                                for: "contaCorrenteId",
                                title: _vm.$t("__.obrigatorio"),
                              },
                            },
                            [
                              _c("span", { staticStyle: { color: "red" } }, [
                                _vm._v("* "),
                              ]),
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "__.Components.parent.sessaoCaixa.aberturaCaixa_vue_html.contaCorrenteDestino"
                                  )
                                )
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("combo-component", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required",
                                expression: "'required'",
                              },
                            ],
                            attrs: {
                              id: "contaCorrenteId",
                              name: "contaCorrenteId",
                              data: _vm.contaCorrenteOptions,
                              searchable: true,
                            },
                            model: {
                              value: _vm.model.contaCorrenteDestinoId,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.model,
                                  "contaCorrenteDestinoId",
                                  $$v
                                )
                              },
                              expression: "model.contaCorrenteDestinoId",
                            },
                          }),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
              ])
            : _vm._e(),
        ]),
        _vm._v(" "),
        _c("fieldset", [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-sm-12" }, [
              _c("div", { staticClass: "form-group" }, [
                _c(
                  "button",
                  { staticClass: "btn btn-primary", attrs: { type: "submit" } },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "__.Components.parent.sessaoCaixa.aberturaCaixa_vue_html.confirmarAbertura"
                        )
                      )
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-secondary",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        _vm.cancel()
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("__.Crud.voltar")))]
                ),
              ]),
            ]),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }