import { render, staticRenderFns } from "./list.vue?vue&type=template&id=187fd5be&"
import script from "./list.ts?vue&type=script&lang=js&"
export * from "./list.ts?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\a\\1\\s\\TafStore.PharmUP.Client\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('187fd5be')) {
      api.createRecord('187fd5be', component.options)
    } else {
      api.reload('187fd5be', component.options)
    }
    module.hot.accept("./list.vue?vue&type=template&id=187fd5be&", function () {
      api.rerender('187fd5be', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/parent/crud/formulapadraovalorproduto/list.vue"
export default component.exports