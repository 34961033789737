import { GridColumn, GridColumnType } from "@/components/child/grid/gridColumn";
import { Component } from "@/decorators";
import FornecedorModel from "@/models/fornecedorModel";
import FornecedorService from "@/services/fornecedorService";

import ListComponentBase from "../listComponentBase";

@Component
export default class FornecedorListComponent extends ListComponentBase<FornecedorModel> {
    get gridColumns(): Array<GridColumn> {
        return [
            new GridColumn("nome", "Nome", GridColumnType.String),
            new GridColumn("cpf", "CPF", GridColumnType.String, false, false, "", "", false, false),
            new GridColumn("cnpj", "CNPJ", GridColumnType.String, false, false, "", "", false, false),
            new GridColumn("ativo", "Ativo", GridColumnType.Boolean, false, false, "", "", false, false),
        ];
    }

    private beforeMount() {
        this.setProps(new FornecedorService(), "fornecedor", "nome");
    }
}
