import Vue from "vue";

import actionBarComponent from "@/components/child/actionBar/actionBar.vue";
import { Component } from "@/decorators";
import CapacidadeProducaoModel from "@/models/capacidadeProducaoModel";
import CapacidadeProducaoService from "@/services/capacidadeProducaoService";

import "../crud.scss";

@Component({
    components: {
        actionBarComponent,
    },
})
export default class CapacidadeProducaoEditComponent extends Vue {
    private service = new CapacidadeProducaoService();

    models: CapacidadeProducaoModel[] = [];

    private async load() {
        this.models = [new CapacidadeProducaoModel()];
        try {
            const data = await this.service.getByFranquia().withLoading().resolveWithJSON<CapacidadeProducaoModel[]>();
            if (data.length > 0) {
                this.models = data.map(p => {
                    const model = new CapacidadeProducaoModel();
                    model.updateFrom(p);

                    const date = new Date(p.horario);
                    model.horarioString = `${this.padLeft(date.getHours())}:${this.padLeft(date.getMinutes())}`;

                    const dateFinalDeSemana = new Date(p.horarioFinalDeSemana);
                    model.horarioFinalDeSemanaString = `${this.padLeft(dateFinalDeSemana.getHours())}:${this.padLeft(
                        dateFinalDeSemana.getMinutes(),
                    )}`;

                    return model;
                });
            }
        } catch {}
    }

    private padLeft(value: number) {
        const valueString = value.toString();
        return valueString.length == 1 ? "0" + valueString : valueString;
    }

    private async save() {
        try {
            const isValid = await this.$validator.validateAll();
            if (isValid) {
                this.models = this.models.map(p => {
                    const model = new CapacidadeProducaoModel();
                    model.updateFrom(p);

                    model.horario = new Date();
                    model.horario.setHours(Number(model.horarioString.split(":")[0]));
                    model.horario.setMinutes(Number(model.horarioString.split(":")[1]));

                    model.horarioFinalDeSemana = new Date();
                    model.horarioFinalDeSemana.setHours(Number(model.horarioFinalDeSemanaString.split(":")[0]));
                    model.horarioFinalDeSemana.setMinutes(Number(model.horarioFinalDeSemanaString.split(":")[1]));

                    return model;
                });

                const sucesso = await this.service.updateModels(this.models).withLoading().resolveWithoutJSON();
                if (sucesso) {
                    await this.$showSuccess(this.$t("__.ts.sucesso"), this.$t("__.ts.capacidadeProducaoAtualizada"));
                    this.$router.back();
                }
            } else {
                this.$focusErrorField();
            }
        } catch {}
    }

    private cancel() {
        this.$router.back();
    }

    private onAdd() {
        this.models.push(new CapacidadeProducaoModel());
    }

    private onRemove(model: CapacidadeProducaoModel) {
        this.models = this.models.filter(p => p != model);
    }

    private mounted() {
        this.load();
    }
}
