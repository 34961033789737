import Vue from "vue";

import actionBarComponent from "@/components/child/actionBar/actionBar.vue";
import comboComponent from "@/components/child/form/combo.vue";
import searchComboComponent from "@/components/child/searchCombo/search.vue";
import { Component } from "@/decorators";
import PaginationModel from "@/models/paginationModel";
import CampanhaModel from "@/models/programafidelidade/campanhaModel";
import CampanhaPontuacaoModel from "@/models/programafidelidade/campanhaPontuacaoModel";
import CampanhaService from "@/services/campanhaService";

@Component({
    components: {
        actionBarComponent,
        comboComponent,
        searchComboComponent,
    },
})
export default class PontuarComponent extends Vue {
    model = new CampanhaPontuacaoModel();

    private service = new CampanhaService();

    campanhaOptions: Array<Object> = [];
    clientesOptions: Array<Object> = [];

    clienteId: number;

    private async loadCampanhas() {
        try {
            const data = await new CampanhaService()
                .combo()
                .withLoading()
                .resolveWithJSON<PaginationModel<CampanhaModel>>();

            this.campanhaOptions = data.list.map(item => ({ value: item.id, text: item.descricao }));
        } catch {}
    }

    private async save() {
        try {
            const isValid = await this.$validator.validateAll();
            if (isValid) {
                const sucesso = await this.service
                    .pontuarByCliente(this.clienteId, this.model.campanhaId, this.model.pontos)
                    .withLoading()
                    .resolveWithoutJSON();

                if (sucesso) {
                    await this.$showInclusaoUpdate(this.model.id);

                    this.clienteId = null;
                    this.model.pontos = 0;
                }
            } else {
                this.$focusErrorField();
            }
        } catch {}
    }

    private async mounted() {
        return Promise.all([this.loadCampanhas()])
            .withLoading()
            .then(() => {})
            .catch(() => {});
    }
}
