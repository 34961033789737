/**
 * Representa as possíveis orientações da página, de acordo com as convenções
 * definidas em <https://developer.mozilla.org/en-US/docs/Web/API/Screen/orientation>
 */

import FormatoImpressaoRotulo from "../../models/enum/rotulo/formatoImpressaoRotulo";

function styleFromOrientation(orientation: FormatoImpressaoRotulo) {
    switch (orientation) {
        case FormatoImpressaoRotulo.Paisagem:
            return "transform: rotate(0deg); transform-origin: center center";
        case FormatoImpressaoRotulo.Retrato:
            return "transform: rotate(90deg) translateY(-100%); transform-origin: left top";
        case FormatoImpressaoRotulo.PaisagemInvertido:
            return "transform: rotate(180deg); transform-origin: center center";
        case FormatoImpressaoRotulo.RetratoInvertido:
            return "transform: rotate(270deg) translateX(-100%); transform-origin: left top";
    }
}

/**
 * Alterar a orientação da página.
 * @param html Conteúdo HTML.
 * @param width Largura da página (em milímetros).
 * @param height Altura da página (em milímetros).
 * @param orientation Nova orientação da página.
 */
export function htmlChangeOrientation(
    html: string,
    width: number,
    height: number,
    orientation: FormatoImpressaoRotulo,
) {
    const isLandscape =
        orientation == FormatoImpressaoRotulo.Retrato || orientation == FormatoImpressaoRotulo.RetratoInvertido;
    const newWidth = isLandscape ? height : width;
    const newHeight = isLandscape ? width : height;
    return `
        <div style="width: ${newWidth}mm; height: ${newHeight}mm;">
            <div style="width: ${width}mm; height: ${height}mm; ${styleFromOrientation(orientation)}">
                ${html}
            </div>
        </div>`;
}
