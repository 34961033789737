var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "crud crud-list" },
    [
      _c("grid-component", {
        attrs: {
          columns: _vm.gridColumns,
          conditionShowSubData: _vm.conditionShowSubData,
          data: _vm.gridData,
          showActionColumn: false,
          showExpand: true,
          showSortIcons: false,
          showTableFoot: false,
          showTableHead: false,
          subColumns: _vm.gridColumnsVinculos,
          subDataName: _vm.subDataName,
          tituloPagina: "SNGPC Produtos",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }