import Vue from "vue";

import gridComponent from "@/components/child/grid/grid.vue";
import { GridColumn, GridColumnType } from "@/components/child/grid/gridColumn";
import { Component } from "@/decorators";
import SNGPCProdutoModel from "@/models/sngpc/sNGPCProdutoModel";

import "../crud/crud.scss";

@Component({
    components: {
        gridComponent,
    },
})
export default class SNGPCProdutoListComponent extends Vue {
    gridData: Array<SNGPCProdutoModel> = [];

    get gridColumns(): Array<GridColumn> {
        return [
            new GridColumn("dataInicial", "Inicio", GridColumnType.Date),
            new GridColumn("dataFinal", "Fim", GridColumnType.Date),
            new GridColumn("produtoDescricao", "Produto", GridColumnType.String),
            new GridColumn("estoqueSaldoInicial", "Estoque Inicial", GridColumnType.Decimal),
            new GridColumn("estoqueSaldoFinal", "Estoque Final", GridColumnType.Decimal),
        ];
    }

    subDataName = "sngpcDetalhes";

    get gridColumnsVinculos(): Array<GridColumn> {
        return [
            new GridColumn("dataMovimento", "Data", GridColumnType.Date),
            new GridColumn("tipoMovimentoDescricao", "Movimento", GridColumnType.String),
            new GridColumn("quantidadeMovimentada", "Quantidade", GridColumnType.Decimal),
            new GridColumn("unidadeFarmacotecnicaDescricao", "Unidade", GridColumnType.String),
            new GridColumn("quantidadeDeInsumoPorUnidadeFarmacotecnica", "Quantidade Insumo", GridColumnType.Decimal),
            new GridColumn("unidadeDeMedidaDoInsumoDescricao", "Unidade Insumo", GridColumnType.String),
            new GridColumn("loteDescricao", "Lote", GridColumnType.String),
        ];
    }

    conditionShowSubData(it) {
        return it["sngpcDetalhes"]["length"] > 0;
    }

    public loadModel(model: Array<SNGPCProdutoModel>) {
        this.gridData = null;
        this.gridData = new Array<SNGPCProdutoModel>();
        this.gridData = model;
    }
}
