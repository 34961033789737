import CapsulaModel from "../models/capsulaModel";
import ProdutoModel from "../models/produto/produtoModel";
import nfetch from "../utils/events/nfetch";

import { IService } from "./base/iService";

export default class CapsulaService extends IService<CapsulaModel> {
    constructor() {
        super("Capsula");
    }

    public produtosCores(id: number): Promise<Response> {
        return nfetch(`/${this.controllerName}/GetProdutosCores?id=${id}`, {
            credentials: "same-origin",
        });
    }

    public salvarOrdem(produtos: ProdutoModel[]): Promise<Response> {
        return nfetch(`/${this.controllerName}/SalvarOrdem`, {
            method: "post",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json; charset=UTF-8",
                "XSRF-TOKEN": this.getCookie("XSRF-TOKEN"),
            },
            body: JSON.stringify(produtos),
        });
    }
}
