import BaseModel from "./base/baseModel";
import TelaAcaoModel from "./telaAcaoModel";

export default class TelaAcaoRemoverModel extends BaseModel {
    id?: number = null;
    franquiaId?: number = null;
    telaAcao: TelaAcaoModel;
    telaAcaoId?: number = null;
    usuarioId?: number = null;
    franquiaDescricao = "";
    telaAcaoDescricao = "";
}
