import ModeloEspecifico from "@/models/enum/modeloEspecifico";

import ModeloImpressaoModel from "../models/modeloImpressao/modeloImpressaoModel";
import nfetch from "../utils/events/nfetch";

import { IService } from "./base/iService";

export default class ModeloImpressaolService extends IService<ModeloImpressaoModel> {
    constructor() {
        super("ModeloImpressao");
    }

    public GetByTelaName(telaName: string): Promise<Response> {
        return nfetch(`/${this.controllerName}/GetBytelaName?telaName=${telaName}`, {
            credentials: "same-origin",
        });
    }

    public GetPadraoByTelaName(telaName: string): Promise<Response> {
        return nfetch(`/${this.controllerName}/GetPadraoBytelaName?telaName=${telaName}`, {
            credentials: "same-origin",
        });
    }

    public GetLabelsByClassName(classFullName: string): Promise<Response> {
        return nfetch(`/${this.controllerName}/GetLabelsByClassName?classFullName=${classFullName}`, {
            credentials: "same-origin",
        });
    }

    public replicar(idModelo: number, idsFranquias: string): Promise<Response> {
        return nfetch(`/${this.controllerName}/Replicar?idModelo=${idModelo}&idsFranquias=${idsFranquias}`, {
            method: "post",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json; charset=UTF-8",
                "XSRF-TOKEN": this.getCookie("XSRF-TOKEN"),
            },
        });
    }

    public getByModeloEspecifico(telaName: string, modeloEspecifico: ModeloEspecifico): Promise<Response> {
        return nfetch(
            `/${this.controllerName}/GetByModeloEspecifico?telaName=${telaName}&modeloEspecifico=${modeloEspecifico}`,
            {
                credentials: "same-origin",
            },
        );
    }
}
