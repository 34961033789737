import FileHelper from "@/utils/common/fileHelper";

import UsuarioService from "../usuarioService";

type ServicesTypes =
    | "ClientePDF"
    | "FracionamentoPDF"
    | "OrdemFracionamentoPDF"
    | "OrcamentoPDF"
    | "SessaoCaixaPDF"
    | "LivroRegistroEspecificoPDF"
    | "RelatorioPDF"
    | "BalancoCompletoPDF"
    | "VeterinarioPDF"
    | "FichaTecnica"
    | "SngpcManual"
    | "Siproquim"
    | "CurvaABCPDF"
    | "DeclaracaoConteudoPDF"
    | "MapaSiproquimPDF"
    | "MelhorCompraPDF"
    | "ManipuladoresPDF"
    | "FormulaPadraoPDF";

export default class PDFService {
    protected controllerName: ServicesTypes;
    protected usuarioService: UsuarioService;

    constructor(controllerName: ServicesTypes) {
        this.controllerName = controllerName;
        this.usuarioService = new UsuarioService();
    }

    public async geraPDF(id: number) {
        const url = `/${this.controllerName}/GeraPDF?id=${id}`;
        await this.imprimirPDF(url);
    }

    public async geraPDFWithFilters(data: object) {
        const url = `/${this.controllerName}/GeraPDFWithFilters?filters=${JSON.stringify(data)}`;
        await this.imprimirPDF(url);
    }

    public async imprimirPDF(path: string) {
        const shortToken = await this.usuarioService.getShortToken().resolveWithText();
        window.open(`${process.env.VUE_APP_ROOT_API}${path}&short_token=${shortToken}`);
    }

    public downloadPDF(path: string): void {
        FileHelper.downloadPDF(`${process.env.VUE_APP_ROOT_API}${path}`);
    }
}
