var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "modal-component",
    {
      ref: "modalComponent",
      attrs: {
        "modal-class": "lg-modal",
        title: _vm.$t(
          "__.Crud.rotulopersonalizado.imprimirRotuloComModal_vue_html.rotuloPersonalizado"
        ),
        btOkName: _vm.$t("__.Crud.imprimir"),
      },
      on: {
        "btn-ok-click": function ($event) {
          _vm.onClickImprimirRotuloPersonalizado()
        },
      },
    },
    [
      _c(
        "div",
        { attrs: { slot: "body" }, slot: "body" },
        [
          _c("imprimirRotuloPersonalizadoComponent", {
            ref: "imprimirRotuloPersonalizadoComponent",
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }