enum ConfiguracoesUsuario {
    // [Description("Email acesso RT de SNGPC")]
    EmailRTAcessoSNGPC = 1,
    // [Description("Senha acesso RT do SNGPC")]
    SenhaRTAcessoSNGPC = 2,
    // [Description("Percentual máximo de desconto")]
    PercentualMaximoDesconto = 3,
    // [Description("Marcar por padrão impressão de modelo de venda")]
    MarcarModeloVenda = 4,
    // [Description("Marcar por padrão impressão de modelo de manipulção")]
    MarcarModeloManipulacao = 5,
    // [Description("Marcar por padrão impressão de rótulo")]
    MarcarModeloRotulo = 6,
    // [Description("Rótulo para lote")]
    RotuloLoteId = 7,
    // [Description("Rótulo para manipulação")]
    RotuloManipulacao = 8,
    // [Description("Permitir Entrada Manual de Produto Controlado")]
    PermitirEntradaManualControlado = 9,
    // [Description("Permitir Alterar Venda Faturada")]
    PermitirAlterarVendaFaturada = 10,
}

export default ConfiguracoesUsuario;
