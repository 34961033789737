import Vue from "vue";
import { mapMutations, mapState } from "vuex";

import comboComponent from "@/components/child/form/combo.vue";
import dateTimePickerComponent from "@/components/child/form/datetimepicker.vue";
import Grid from "@/components/child/grid/grid";
import gridComponent from "@/components/child/grid/grid.vue";
import { GridColumn, GridColumnType } from "@/components/child/grid/gridColumn";
import LoadingModalComponent from "@/components/child/loadingmodal/loadingmodal";
import loadingModalComponent from "@/components/child/loadingmodal/loadingmodal.vue";
import { Component, Prop, Watch } from "@/decorators";
import PaginationPackModel from "@/models/auxiliar/paginationPackModel";
import StatusContas from "@/models/enum/statusContas";
import ContaPagarModel, { ContaPagarListParameters } from "@/models/financeiro/contaPagarModel";
import FornecedorModel from "@/models/fornecedorModel";
import PaginationModel from "@/models/paginationModel";
import ContaPagarService from "@/services/financeiro/contaPagarService";
import FornecedorService from "@/services/fornecedorService";
import { AppState, Mutations } from "@/store/store";
import { getFornecedorCombo } from "@/utils/common/combo/combotext";
import { convertToDateInput, convertToDateInputString } from "@/utils/common/date";
import Delay from "@/utils/common/delay";

import "../crud.scss";
import "./list.scss";

@Component({
    components: {
        gridComponent,
        dateTimePickerComponent,
        comboComponent,
        loadingModalComponent,
    },
    computed: mapState<AppState>({
        loadingPack: state => state.paginationStatus.loading,
        paginationParams: state => state.paginationStatus.paginationParams,
    }),
    methods: mapMutations(["SET_PARAMS", "GET_PARAMS"] as Mutations),
})
export default class ContaPagarListComponent extends Vue {
    private service = new ContaPagarService();
    private loadingModalComponent: LoadingModalComponent = null;
    private gridcomponent: Grid = null;

    @Prop({ type: String, default: "" }) FilterKeyId: string;
    @Prop({ type: Number, default: StatusContas.Aberto }) FilterStatusConta: StatusContas;
    @Prop({ type: Boolean, default: false }) HideExtras: boolean;

    private hideExtras = false;
    isHidden = false;

    gridFilterStatusConta = StatusContas.Aberto;
    startFilterKey = "";
    gridFilterKeyId = "";
    gridFilterKey = "";
    gridSortKey = "dataVencimento";
    gridSortOrder = "asc";
    gridSelectedValues: Array<ContaPagarModel> = [];
    gridData: Array<ContaPagarModel> = [];
    get gridColumns(): Array<GridColumn> {
        return [
            new GridColumn("dataVencimento", "Vencimento", GridColumnType.Date, false, false, "", "", true),
            new GridColumn("descricao", "Descri\u00e7\u00e3o", GridColumnType.String, false, false, "", "", true),
            new GridColumn("fornecedor", "Fornecedor", GridColumnType.String, false, false, "", "", true, false),
            new GridColumn(
                "contaGerencialDescricao",
                "Conta Gerencial",
                GridColumnType.String,
                false,
                false,
                "",
                "",
                true,
            ),
            new GridColumn("valorTotal", "Valor", GridColumnType.Money, false, true, "", "", true),
            new GridColumn("saldo", "Saldo", GridColumnType.Money, false, false, "", "", true),
        ];
    }

    subDataName = "lancamentosVinculados";

    gridColumnsVinculos: Array<GridColumn> = [
        new GridColumn("dataVencimento", "Vencimento", GridColumnType.Date),
        new GridColumn("descricao", "Descri\u00e7\u00e3o", GridColumnType.String),
        new GridColumn("valorTotal", "Valor", GridColumnType.Money),
        new GridColumn("saldo", "Saldo", GridColumnType.Money),
    ];

    pageIndex = 1;
    pageSize = 20;
    total = 0;
    fornecedoresOptions: Array<Object> = [];
    filtro: Array<Object> = [];
    fornecedorFiltro?: number = null;
    radioFilter: number = null;
    dataFilter1: Date = null;
    dataFilter2: Date = null;

    pags = false;
    tipoDocGerar: number = null;

    conditionShowSubData(it) {
        return it["lancamentosVinculados"]["length"] > 0;
    }
    private load() {
        this.isHidden = false;
        this.loadingModalComponent.showLoading();

        this.gridFilterKeyId = this.FilterKeyId;
        this.gridFilterStatusConta = this.FilterStatusConta;

        const customParameters: ContaPagarListParameters = {
            statusContas: this.gridFilterStatusConta,
            filterKeyId: this.gridFilterKeyId,
            dataVencimentoIniStr: convertToDateInputString(this.filtro[0]),
            dataVencimentoFinStr: convertToDateInputString(this.filtro[1], true),
            fornecedorId: this.filtro[2] ? <number>this.filtro[2] : null,
            apenasVencidos: this.filtro[3] ? this.filtro[3] == 0 : false,
        };
        this.service
            .list(
                this.gridFilterKey,
                this.gridSortKey,
                this.gridSortOrder,
                this.pags ? 1 : this.pageIndex,
                this.pags ? 999999 : this.pageSize,
                customParameters,
            )
            .then(response => {
                return response.json() as Promise<PaginationModel<ContaPagarModel>>;
            })
            .then(data => {
                //atualiza params no store
                if (data.total > 0)
                    this["SET_PARAMS"]({
                        routePath: this.$route.path,
                        filterKey: this.gridFilterKey,
                        sortKey: this.gridSortKey,
                        sortOrder: this.gridSortOrder,
                        pageIndex: this.pageIndex,
                        pageSize: this.pageSize,
                        filtros: this.filtro,
                    });

                this.gridData = data.list;

                this.gridData["dataDe"] = this.filtro[0] ? this.filtro[0].toString() : " ";
                this.gridData["dataAte"] = this.filtro[1] ? this.filtro[1].toString() : " ";

                if (this.filtro[2]) this.gridData["fornecedor"] = data.list[0]["fornecedor"];

                if (this.filtro[3] == 0) this.gridData["status"] = "4";

                this.total = data.total;

                if (this.pags) {
                    if (this.tipoDocGerar == 0) {
                        setTimeout(() => {
                            this.gridcomponent.gerarXls();
                        }, 2000);
                    }

                    if (this.tipoDocGerar == 1) {
                        setTimeout(() => {
                            this.gridcomponent.gerarPdf();
                        }, 2000);
                    }

                    if (this.tipoDocGerar == 2) {
                        setTimeout(() => {
                            this.gridcomponent.gerarImpressao();
                        }, 2000);
                    }

                    setTimeout(() => {
                        this.pags = false;
                    }, 2000);
                }

                this.loadingModalComponent.hide();
            })
            .catch(error => {
                this.loadingModalComponent.hide();
            });
    }

    private pagarSelecionados() {
        if (this.gridSelectedValues.length == 1)
            this.$router.push("/contapagar-edicao/" + this.gridSelectedValues[0].id);
        else
            this.$router.push(
                "/contapagar-multiplas-edicao/" +
                    this.gridSelectedValues.map(function (model) {
                        return model.id;
                    }),
            );
    }

    private onEditItem(model: ContaPagarModel) {
        this.$router.push("/contapagar-edicao/" + model.id);
    }

    private async onRemoveItem(model: ContaPagarModel) {
        const response = await this.$showExclusaoQuestion(`${this.$t("__.ts.desejaExcl")}${model.descricao}?`);

        if (response) {
            try {
                const sucesso = await this.service.delete(model.id).withLoading().resolveWithoutJSON();
                if (sucesso) {
                    this.load();
                    this.$showExclusao();
                }
            } catch {}
        }
    }

    private onCreateItem() {
        this.$router.push("/contapagar-edicao");
    }

    private onChangeFilterKey(filterKey: string) {
        this.gridFilterKey = filterKey;
        this.load();
    }

    private onChangeSort(sortKey: string, sortOrder: string) {
        this.gridSortKey = sortKey;
        this.gridSortOrder = sortOrder;
        this.load();
    }

    private onChangePage(pageIndex: number) {
        this.pageIndex = pageIndex;
        this.load();
    }

    private onSelectedValuesChanged(values: Array<ContaPagarModel>) {
        this.gridSelectedValues = values;
    }

    @Watch("FilterKeyId")
    private onFilterChange() {
        this.load();
    }

    private getFormValues(listaFiltro) {
        this.filtro = [];

        this.filtro.push(listaFiltro.target.elements.dataFilter1.value);

        this.filtro.push(listaFiltro.target.elements.dataFilter2.value);

        this.filtro.push(this.fornecedorFiltro);

        this.filtro.push(listaFiltro.target.elements.optradio.value);

        this.load();
    }

    private async loadFornecedores() {
        try {
            const data = await new FornecedorService()
                .combo()
                .then(r => r.json() as Promise<PaginationModel<FornecedorModel>>);
            this.fornecedoresOptions = data.list.map(getFornecedorCombo);
        } catch {}
    }

    @Watch("pags")
    private onPags() {
        this.load();
    }
    private async loadPagination() {
        await this["GET_PARAMS"](this.$route.path);
        while (this["loadingPack"]) {
            await Delay(50);
        }
        const paginationPack = this["paginationParams"] as PaginationPackModel;

        if (paginationPack != null) {
            this.gridFilterKey = paginationPack.filterKey;
            this.startFilterKey = this.gridFilterKey;

            this.gridSortKey = paginationPack.sortKey;
            this.gridSortOrder = paginationPack.sortOrder;
            this.pageIndex = paginationPack.pageIndex;
            this.pageSize = paginationPack.pageSize;
            if (paginationPack.filtros != null) {
                this.filtro = paginationPack.filtros;
                if (this.filtro.length >= 0) {
                    if (this.filtro[0]) {
                        this.dataFilter1 = convertToDateInput(this.filtro[0].toString());
                    }
                    if (this.filtro[1]) {
                        this.dataFilter2 = convertToDateInput(this.filtro[1].toString());
                    }
                    if (this.filtro[2] != null && <number>this.filtro[2] > 0) {
                        this.fornecedorFiltro = <number>this.filtro[2];
                    }
                    if (this.filtro[3] != null && <number>this.filtro[3] >= 0) {
                        this.radioFilter = <number>this.filtro[3];
                    }
                }
            }
        }
    }

    private mounted() {
        this.loadingModalComponent = this.$refs.loadingModalComponent as LoadingModalComponent;
        this.hideExtras = this.HideExtras ? true : false;
        this.gridcomponent = this.$refs.gridcomponent as Grid;

        this.loadFornecedores();
        this.loadingModalComponent.showLoading();
        Promise.all([this.loadPagination()])
            .then(result => {
                this.load();
                this.loadingModalComponent.hide();
            })
            .catch(error => {
                this.loadingModalComponent.hide();
            });
    }
}
