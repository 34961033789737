import BaseModel from "./base/baseModel";

export default class GrupoUsuarioModel extends BaseModel {
    id?: number = null;
    grupoId?: number = null;
    grupoDescricao?: string = null;
    usuarioId?: number = null;
    usuarioNome?: string = null;
    usuarioFranquiaId?: number = null;
}
