import Vue from "vue";

import gridComponent from "@/components/child/grid/grid.vue";
import { GridColumn, GridColumnType } from "@/components/child/grid/gridColumn";
import ClienteEditComponent from "@/components/parent/crud/cliente/edit";
import clienteComponent from "@/components/parent/crud/cliente/edit.vue";
import ShortcutComponent from "@/components/parent/shortcut/shortcut";
import shortcutComponent from "@/components/parent/shortcut/shortcut.vue";
import { Component } from "@/decorators";
import ClienteModel from "@/models/clienteModel";
import ClienteService from "@/services/clienteService";
import Delay from "@/utils/common/delay";

@Component({
    components: {
        clienteComponent,
        gridComponent,
        shortcutComponent,
    },
})
export default class ClientesDuplicadosViewComponent extends Vue {
    private clienteService = new ClienteService();

    private clienteComponent: ClienteEditComponent = null;
    private shortcutComponent: ShortcutComponent = null;
    private shortcutComponentCliente: ShortcutComponent = null;

    private gridData: ClienteModel[] = [];
    private searchValue: string = null;

    get gridColumns(): Array<GridColumn> {
        return [
            new GridColumn("nome", this.$t("__.ts.nome"), GridColumnType.String, false, false, "", "", true),
            new GridColumn(
                "razaoSocial",
                this.$t("__.ts.razaoSocial"),
                GridColumnType.String,
                false,
                false,
                "",
                "",
                true,
            ),
            new GridColumn("cpf", this.$t("__.ts.cpf"), GridColumnType.String, false, false, "", "", true),
            new GridColumn("cnpj", this.$t("__.ts.cnpj"), GridColumnType.String, false, false, "", "", true),
            new GridColumn("email", this.$t("__.ts.email"), GridColumnType.String, false, false, "", "", true),
            new GridColumn("rg", this.$t("__.ts.rg"), GridColumnType.String, false, false, "", "", true),
            new GridColumn("telefone", this.$t("__.ts.telefone"), GridColumnType.String, false, false, "", "", true),
            new GridColumn("celular", this.$t("__.ts.celular"), GridColumnType.String, false, false, "", "", true),
        ];
    }

    public async show(cliente: ClienteModel) {
        while (!this.$refs.shortcutComponent) await Delay(100);
        this.shortcutComponent = this.$refs.shortcutComponent as ShortcutComponent;
        this.shortcutComponent.title = this.$t("__.Components.parent.duplicacoes.list_vue_html.unificar") as string;
        this.shortcutComponent.show();

        await this.loadClientesDuplicados(cliente);
    }

    public async loadClientesDuplicados(cliente: ClienteModel) {
        this.gridData = await this.clienteService
            .getClientesDuplicadosByCliente(cliente)
            .withLoading()
            .resolveWithJSON<ClienteModel[]>();

        const campoDuplicado = this.getCampoDuplicado();
        this.searchValue = campoDuplicado ? cliente[campoDuplicado] : "";
    }

    private getCampoDuplicado() {
        const campos = ["nome", "cpf", "cnpj", "email", "rg", "telefone", "celular"];

        return campos.reduce((acc, p) => {
            const values = this.gridData.map(g => g[p]);
            return values.every(v => v && v == values[0]) ? p : acc;
        }, null);
    }

    private async onEditItem(cliente: ClienteModel) {
        this.clienteComponent = this.$refs.clienteComponent as ClienteEditComponent;

        this.clienteComponent.modelId = cliente.id;
        this.clienteComponent.load();

        this.shortcutComponentCliente = this.$refs.shortcutComponentCliente as ShortcutComponent;
        this.shortcutComponentCliente.title = this.$t(
            "__.Components.parent.duplicacoes.edit_vue_html.edicaoCliente",
        ) as string;
        this.shortcutComponentCliente.show();
    }

    private async onClienteSave() {
        if (await this.clienteComponent.save()) {
            this.shortcutComponentCliente.hide();
        }
    }

    public hide() {
        this.shortcutComponent.hide();
    }

    private onEvent() {
        this.$emit("hide-click");
    }

    private async onClienteSaveOk(cliente: ClienteModel) {
        await this.loadClientesDuplicados(this.gridData.find(p => p.id != cliente.id));

        if (this.gridData.length < 2) {
            await this.$showSuccess(this.$t("__.ts.sucesso"), this.$t("__.ts.cadastroResolvidos"));

            this.$emit("onRefresh", true);
        } else {
            this.$emit("onRefresh", false);
        }
    }
}
