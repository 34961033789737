import BaseModel from "./base/baseModel";
import SituacoesVendaItem from "./enum/situacoesVendaItem";
import StatusFracionamento from "./enum/statusFracionamento";
import TipoNotaFiscal from "./enum/tipoNotaFiscal";
import FracionamentoModel from "./fracionamentoModel";
import ManipulacaoOrdemModel from "./manipulacaoOrdemModel";
import ProdutoModel from "./produto/produtoModel";

export default class VendaItemModel extends BaseModel {
    id?: number = null;
    vendaId?: number = null;
    produto?: ProdutoModel = null;
    produtoId?: number = null;
    produtoDescricao: string = null;
    produtoLoteId?: number = null;
    produtoLoteDescricao: string = null;
    manipulacaoOrdemId?: number = null;
    manipulacaoOrdem?: ManipulacaoOrdemModel = null;
    fracionamentoId?: number = null;
    fracionamento?: FracionamentoModel = null;
    observacao: string = null;
    pacienteId?: number = null;
    prescritorId?: number = null;
    posologiaId?: number = null;
    posologiaTexto: string = null;
    situacao: SituacoesVendaItem = SituacoesVendaItem.Orcamento;

    valorUnitario = 0;
    quantidade = 0;
    unidadeMedidaId?: number = 0;
    unidadeMedidaDescricao: string = null;
    desconto = 0;
    acrescimo = 0;
    valorTotal = 0;
    continuo = false;
    vendaEstoque?: boolean = false;
    separado?: boolean = false;

    // Tipo da Nota Fiscal que deve ser emitida para este item
    tipoNF?: TipoNotaFiscal = null;
    movimentaEstoque?: boolean = true;

    dataAprovacao: Date = null;
    dataValidade: Date = null;
    dataVencimentoContinuo?: Date = null;

    // Utilizado na separação de item acabado
    statusFracionamento: StatusFracionamento = null;

    //utilizado na listagem/edição da venda para exibir o código da manipulação/produto
    codigo?: number = 0;
}
