import Vue from "vue";
import { mapState, mapMutations } from "vuex";

import Alert from "sweetalert2";

import { Component } from "@/decorators";
import PreLoadPackModel from "@/models/auxiliar/preLoadPackModel";
import UsuarioModel from "@/models/usuarioModel";
import ConfiguracaoWhatsAppService from "@/services/atendimentos/configuracaoWhatsAppService";
import NotificationService from "@/services/notificationService";
import MensagemAtendimentoHub from "@/services/websocket/mensagemAtendimentoHub";
import PharmUPHub from "@/services/websocket/pharmUPHub";
import { AppState, LoadListProps, Mutations, SessionActions } from "@/store/store";
import Bus from "@/utils/events/bus";

import navMenuComponent from "../navmenu/navmenu.vue";
import notificationComponent from "../notification/view.vue";
import topMenuComponent from "../topmenu/topmenu.vue";

import "./app.scss";

@Component({
    components: {
        topMenuComponent,
        navMenuComponent,
        notificationComponent,
    },
    computed: mapState({
        preLoadList: (state: AppState) => state.preLoad.preLoadList,
        franquiaCidade: (state: AppState) => state.session.usuarioLogado.franquiaCidade,
        usuarioLogado: (state: AppState) => state.session.usuarioLogado,
    }),
    methods: mapMutations([
        "LOAD_LIST",
        "CLEAR_LISTSPACK",
        "SET_UNREADED_NOTIFICATIONS",
        "ADD_ONE_NOTIFICATION",
    ] as Mutations),
})
export default class AppComponent extends Vue {
    // State computed props
    usuarioLogado: UsuarioModel;
    preLoadList: PreLoadPackModel;
    franquiaCidade: string;
    LOAD_LIST: (props: LoadListProps) => void;
    CLEAR_LISTSPACK: () => void;
    SET_UNREADED_NOTIFICATIONS: (value: number) => void;
    ADD_ONE_NOTIFICATION: () => void;

    private notificationService = new NotificationService();

    //controle de sessao
    private sessao_IntervaloTempo = 500; //consulta diferenca de tempo em x milissegundos
    private sessao_diminuiIntervaloMinutos = 5; ///emite o aviso de x minutos a sessao expira e ja comeca controlar mais rapido o tempo
    private sessao_startIntervalo = 60000; ///inicia buscando a cada um minuto e depois este tempo  vai diminuir quando ficar proximo
    private sessao_expiracaoMinutos = 120; ///expira em x minutos
    private sessao_avisoMinutos = 2; // aviso x minutos - expirarminutos antes de expirar
    private sessao_intervaloID = 0;
    private sessao_ultimaAtividade; ///tempo salvo na ultima atividade
    private emitiuAvisoSessao = false;
    //sessao

    private get isNotProduction() {
        return this.usuarioLogado.ambiente && this.usuarioLogado.ambiente !== "Production";
    }

    private initSession() {
        this.sessao_ultimaAtividade = new Date().getTime();
        this.sessao_SetIntervalo();
    }

    private sessao_LimpaIntervalo() {
        clearInterval(this.sessao_intervaloID);
    }

    private sessao_SetIntervalo(tempoMinimo = false) {
        this.emitiuAvisoSessao = tempoMinimo;
        this.sessao_LimpaIntervalo();
        this.sessao_intervaloID = setInterval(
            () => this.sessaoIntervalo(),
            tempoMinimo ? this.sessao_IntervaloTempo : this.sessao_startIntervalo,
        );
    }
    private sessao_refresh() {
        this.sessao_ultimaAtividade = new Date().getTime();
        this.sessao_SetIntervalo();
    }
    private sessao_LogOut() {
        window.location.href = "Login";
    }

    private async sessaoIntervalo() {
        let now = new Date().getTime();
        //obtem a diferença de tempo em milisegundos
        let diferencaMilisegundos = now - this.sessao_ultimaAtividade;
        //obtem o tempo em minutos
        let diferencaMinutos = diferencaMilisegundos / 1000 / 60;
        if (diferencaMinutos >= this.sessao_expiracaoMinutos - this.sessao_avisoMinutos) {
            //emite o aviso de expiração
            //para o timer
            this.sessao_LimpaIntervalo();
            //mensagem de alerta
            const _html =
                this.$t("__.ts.sessaoExp").toString() +
                (this.sessao_avisoMinutos * 60).toString() +
                this.$t("__.ts.seg").toString() +
                '<div class="loader" > </div>.<br/><br/>' +
                this.$t("__.ts.pressSimParaLogado").toString() +
                this.$t("__.ts.pressNaoParaDesconect").toString() +
                this.$t("__.ts.obs").toString();

            let timerInterval;
            const timeInit = new Date().getTime();
            const timer = this.sessao_avisoMinutos * 60000;

            this.$showAlert({
                title: this.$t("__.ts.tempoParaExp") as string,
                html: _html,
                icon: "question",
                showCancelButton: true,
                confirmButtonText: this.$t("__.ts.sim") as string,
                cancelButtonText: this.$t("__.ts.nao") as string,
                allowOutsideClick: false,
                allowEscapeKey: false,
                timer: timer,
                didOpen: () => {
                    timerInterval = setInterval(() => {
                        Alert.getHtmlContainer().querySelector("strong").textContent = (
                            timer / 1000 -
                            (new Date().getTime() - timeInit) / 1000
                        ).toFixed(0);
                    }, 1000);
                },
                didClose: () => {
                    clearInterval(timerInterval);
                },
            })
                .then(async result => {
                    if (result) {
                        now = new Date().getTime();
                        diferencaMilisegundos = now - this.sessao_ultimaAtividade;
                        diferencaMinutos = diferencaMilisegundos / 1000 / 60;

                        if (diferencaMinutos > this.sessao_expiracaoMinutos) {
                            this.sessao_LogOut();
                        } else {
                            this["LOAD_LIST"]({ loadAll: true });
                            this["CLEAR_LISTSPACK"]();
                            this.initSession();
                            this.sessao_SetIntervalo();
                            this.sessao_ultimaAtividade = new Date().getTime();
                        }
                    } else {
                        this.sessao_LogOut();
                    }
                })
                .catch(() => {
                    //quando cai aqui é pq nao respondeu ?
                    this.sessao_LogOut();
                });
        } else if (
            diferencaMinutos >= this.sessao_expiracaoMinutos - this.sessao_diminuiIntervaloMinutos &&
            !this.emitiuAvisoSessao
        ) {
            // emitir aviso e mudar o tempo de intervalo para requisicoes
            this.$notifyInfo(
                this.$t("__.ts.sessaoExp").toString() +
                    this.sessao_diminuiIntervaloMinutos.toString() +
                    this.$t("__.ts.min").toString(),
                this.$t("__.ts.tempoParaExp").toString(),
            );
            this.sessao_SetIntervalo(true);
        }
    }
    //fim metodos sessao
    mostrar = true;

    private click() {
        Bus.$emit("hide-nav-menu");
    }

    private showKeyToolTip = false;

    private onKeyAlt() {
        this.showKeyToolTip = !this.showKeyToolTip;
        Bus.$emit("showShortKeyToolTip", this.showKeyToolTip);
    }

    private getPreloadPack() {
        Bus.$emit("setPreLoadPack", this.preLoadList);
    }

    private async loadPack() {
        await this.preLoadList.LoadAllPackLists();
    }

    private async mounted() {
        //inicia controle de tempo de ssesao
        this.initSession();

        await this.$store.dispatch(SessionActions.LOAD_USUARIO_LOGADO);

        // gerar o token para envio de msg pelo whats - precisa gerar mesmo que nao tenha modulo contratado
        await new ConfiguracaoWhatsAppService().getTokenAtendimento();
        // iniciar conexão websocket
        await new PharmUPHub(this.$router).criarConexaoWebSocketPharmUP();
        // iniciar conexão websocket de atendimento apenas quando a franquia tem o módulo contratado
        if (this.usuarioLogado.franquia.moduloWhatsApp) {
            await new MensagemAtendimentoHub().criarConexaoWebSocketMensagemAtendimento();
        }

        Bus.$on("add-one-notification", () => {
            this.ADD_ONE_NOTIFICATION();
        });

        Bus.$on("nfetch-service-event", () => {
            this.sessao_refresh();
        });

        Promise.all([this.LOAD_LIST({ loadAll: true }), this.CLEAR_LISTSPACK()])
            .then(() => {})
            .catch(() => {});

        this.SET_UNREADED_NOTIFICATIONS(await this.notificationService.countUnreaded().resolveWithJSON<number>());
    }

    private beforeDestroy() {
        this.sessao_LimpaIntervalo();
    }
}
