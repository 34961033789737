var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "tooltip-container" }, [
    _c("i", { class: "fa " + _vm.icon, style: "color: " + _vm.iconColor }),
    _vm._v(" "),
    _c("div", { staticClass: "tooltip-content" }, [
      _vm._v(_vm._s(_vm.text.replaceAll(" ", " "))),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }