import Vue from "vue";

import Grid from "@/components/child/grid/grid";
import gridComponent from "@/components/child/grid/grid.vue";
import { GridAction } from "@/components/child/grid/gridAction";
import { GridColumn, GridColumnType } from "@/components/child/grid/gridColumn";
import LoadingModalComponent from "@/components/child/loadingmodal/loadingmodal";
import loadingModalComponent from "@/components/child/loadingmodal/loadingmodal.vue";
import { ModalButtonAction } from "@/components/child/modal/modalButtonAction";
import { Component, Watch, Prop } from "@/decorators";
import ConvenioModel from "@/models/convenioModel";
import PaginationModel from "@/models/paginationModel";
import ConvenioService from "@/services/convenioService";

import ShortcutComponent from "../../shortcut/shortcut";
import shortcutComponent from "../../shortcut/shortcut.vue";

@Component({
    components: {
        gridComponent,
        loadingModalComponent,
        shortcutComponent,
    },
})
export default class ConvenioListComponent extends Vue {
    private service = new ConvenioService();
    private loadingModalComponent: LoadingModalComponent = null;
    private shortCutList: ShortcutComponent = null;
    private shortcutComponent: ShortcutComponent = null;
    private gridcomponent: Grid = null;

    startFilterKey = "";
    gridFilterKey = "";
    gridSortKey = "descricao";
    gridSortOrder = "asc";
    gridData: Array<ConvenioModel> = [];
    get gridColumns(): Array<GridColumn> {
        return [
            new GridColumn("descricao", "Descrição", GridColumnType.String, false, false, "", "", true),
            new GridColumn("validadeInicio", "Validade Inicio", GridColumnType.Date, false, false, "", "", true),
            new GridColumn("validadeFim", "Validade Fim", GridColumnType.Date, false, false, "", "", true, false),
        ];
    }
    extraActions: Array<object> = [];
    gridExtraActionsVendas: Array<GridAction> = [];
    gridExtraActionsVer: Array<GridAction> = [];

    pageIndex = 1;
    pageSize = 20;
    total = 0;
    id = 0;
    showVer = false;

    pags = false;
    tipoDocGerar: number = null;

    extraButtonAction: Array<ModalButtonAction> = [
        new ModalButtonAction("Editar", "Editar Campos", true, "l", " btnCopiar"),
        new ModalButtonAction("Fechar", "Fechar", true, "l", " btn-danger"),
    ];

    @Prop({ type: Number, default: 20 }) pgSize: number;
    @Prop({ type: Boolean, default: false }) CalledByShortCut: boolean;

    private hideExtras = false;

    private load() {
        this.loadingModalComponent.showLoading();

        this.service
            .list(
                this.gridFilterKey,
                this.gridSortKey,
                this.gridSortOrder,
                this.pags ? 1 : this.pageIndex,
                this.pags ? 999999 : this.pageSize,
            )
            .then(response => {
                return response.json() as Promise<PaginationModel<ConvenioModel>>;
            })
            .then(data => {
                //atualiza params no store

                this.gridData = data.list;
                this.total = data.total;
                this.gridExtraActionsVendas.splice(0);
                this.gridExtraActionsVer.splice(0);
                this.extraActions.splice(0);

                this.extraActions.push(this.gridExtraActionsVer);
                this.extraActions.push(this.gridExtraActionsVendas);

                if (this.pags) {
                    if (this.tipoDocGerar == 0) {
                        setTimeout(() => {
                            this.gridcomponent.gerarXls();
                        }, 2000);
                    }

                    if (this.tipoDocGerar == 1) {
                        setTimeout(() => {
                            this.gridcomponent.gerarPdf();
                        }, 2000);
                    }

                    if (this.tipoDocGerar == 2) {
                        setTimeout(() => {
                            this.gridcomponent.gerarImpressao();
                        }, 2000);
                    }

                    setTimeout(() => {
                        this.pags = false;
                    }, 2000);
                }

                this.loadingModalComponent.hide();
            })
            .catch(error => {
                this.loadingModalComponent.hide();
            });
    }

    private onEditItem(model: ConvenioModel) {
        this.$router.push("/convenio-edicao/" + model.id);
    }

    private async onRemoveItem(model: ConvenioModel) {
        const response = await this.$showExclusaoQuestion(`${this.$t("__.ts.desejaExcl")}${model.descricao}?`);

        if (response) {
            try {
                const sucesso = await this.service.delete(model.id).withLoading().resolveWithoutJSON();
                if (sucesso) {
                    this.load();
                    this.$showExclusao();
                }
            } catch {}
        }
    }

    private onCreateItem() {
        this.$router.push("/convenio-edicao");
    }

    private onChangeFilterKey(filterKey: string) {
        this.gridFilterKey = filterKey;
        this.load();
    }

    private onChangeSort(sortKey: string, sortOrder: string) {
        this.gridSortKey = sortKey;
        this.gridSortOrder = sortOrder;
        this.load();
    }

    private onChangePage(pageIndex: number) {
        this.pageIndex = pageIndex;
        this.load();
    }
    private async onExtraButtonActionClick(name, entry, codigo) {
        if (name == "Editar") {
            this.onBtnEditItem();
        } else if (name == "Fechar") {
            this.onFechar();
        }
    }

    private onFechar() {
        this.showVer = false;
        this.shortcutComponent.hide();
    }

    private onBtnEditItem() {
        this.$router.push("/convenio-edicao/" + this.id);
    }
    private onConfirmList() {
        this.shortCutList.hide();
    }

    @Watch("pags")
    private onPags() {
        this.load();
    }

    private mounted() {
        this.shortcutComponent = this.$refs.shortcutComponent as ShortcutComponent;
        this.loadingModalComponent = this.$refs.loadingModalComponent as LoadingModalComponent;
        this.shortCutList = this.$refs.shortCutList as ShortcutComponent;
        this.gridcomponent = this.$refs.gridcomponent as Grid;
        this.hideExtras = this.CalledByShortCut ? true : false;
        if (this.hideExtras) {
            this.pageSize = this.pgSize;
        }
        this.loadingModalComponent.showLoading();
        Promise.all([this.load()]).catch(error => {
            this.loadingModalComponent.hide();
        });
    }
}
