import BaseModel from "../base/baseModel";

import CampanhaModel from "./campanhaModel";
import RecompensaModel from "./recompensaModel";

export default class CampanhaRecompensaModel extends BaseModel {
    id?: number = null;
    recompensaId = 0;
    recompensa = new RecompensaModel();
    campanhaId = 0;
    campanha = new CampanhaModel();
    premio = "";
}
