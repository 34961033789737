import { render, staticRenderFns } from "./edit.vue?vue&type=template&id=d5744568&"
import script from "./edit.ts?vue&type=script&lang=js&"
export * from "./edit.ts?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\a\\1\\s\\TafStore.PharmUP.Client\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('d5744568')) {
      api.createRecord('d5744568', component.options)
    } else {
      api.reload('d5744568', component.options)
    }
    module.hot.accept("./edit.vue?vue&type=template&id=d5744568&", function () {
      api.rerender('d5744568', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/parent/crud/capsula/edit.vue"
export default component.exports