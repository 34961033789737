import PDFService from "./pdf/PDFService";

export default class SiproquimService {
    public pdfService = new PDFService("Siproquim");

    private controllerName = "Siproquim";

    public async geraTXT(data: object) {
        const url = `/${this.controllerName}/Get?filters=${JSON.stringify(data)}`;
        await this.pdfService.imprimirPDF(url);
    }
}
