import { mapGetters } from "vuex";

import checkboxComponent from "@/components/child/form/checkbox.vue";
import comboComponent from "@/components/child/form/combo.vue";
import { Component } from "@/decorators";
import EnumExtensions from "@/models/enum/extensions/enumExtensions";
import TipoEtapa from "@/models/enum/tipoEtapa";
import EtapaPCPModel from "@/models/pcp/etapaPCPModel";
import EtapaPCPService from "@/services/etapaPCPService";
import { Getters } from "@/store/store";
import { getSetorCombo } from "@/utils/common/combo/combotext";

import EditComponentBase from "../editComponentBase";

@Component({
    components: {
        checkboxComponent,
        comboComponent,
    },
    computed: {
        ...mapGetters(["GET_IS_FRACIONAMENTO", "GET_IS_ESTEREIS"] as Getters),
    },
})
export default class EtapaEditComponent extends EditComponentBase<EtapaPCPModel> {
    tipoOptions: Array<Object> = EnumExtensions.getNamesAndValuesOrderedByNames(TipoEtapa);
    GET_IS_FRACIONAMENTO: () => Promise<boolean>;
    GET_IS_ESTEREIS: () => Promise<boolean>;

    setorOptions = [];
    private isFracionamento = false;
    private isEstereis = false;

    private async beforeMount() {
        this.isFracionamento = await this.GET_IS_FRACIONAMENTO();
        this.isEstereis = await this.GET_IS_ESTEREIS();

        this.setorOptions = getSetorCombo(this.isFracionamento);

        await this.setProps(new EtapaPCPService(), "especie_de_animal", new EtapaPCPModel());
    }
}
