import { GridColumn, GridColumnType } from "@/components/child/grid/gridColumn";
import { Component } from "@/decorators";
import JustificativaControleReclamacaoModel from "@/models/justificativaControleReclamacaoModel";
import JustificativaControleReclamacaoService from "@/services/justificativaControleReclamacaoService";

import ListComponentBase from "../listComponentBase";

@Component
export default class JustificativaControleReclamacaoListComponent extends ListComponentBase<JustificativaControleReclamacaoModel> {
    get gridColumns(): Array<GridColumn> {
        return [new GridColumn("descricao", this.$t("__.ts.descricao"), GridColumnType.String)];
    }

    private beforeMount() {
        this.setProps(new JustificativaControleReclamacaoService(), "justificativaControleReclamacao", "descricao");
    }
}
