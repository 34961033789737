import Vue from "vue";

import actionBarComponent from "@/components/child/actionBar/actionBar.vue";
import comboComponent from "@/components/child/form/combo.vue";
import dateTimePickerComponent from "@/components/child/form/datetimepicker.vue";
import gridComponent from "@/components/child/grid/grid.vue";
import { GridColumn, GridColumnType } from "@/components/child/grid/gridColumn";
import { Component, Watch } from "@/decorators";
import PaginationModel from "@/models/paginationModel";
import EtapaPCPModel from "@/models/pcp/etapaPCPModel";
import VendaPCPModel from "@/models/pcp/vendaPCPModel";
import EtapaPCPService from "@/services/etapaPCPService";
import VendaPcpService from "@/services/pcp/vendaPCPService";
import { addHours } from "@/utils/common/date";

import "../../crud/crud.scss";
import "../view.scss";

@Component({
    components: {
        actionBarComponent,
        comboComponent,
        dateTimePickerComponent,
        gridComponent,
    },
})
export default class StatusPCPComponent extends Vue {
    private etapaPCPService = new EtapaPCPService();
    private vendaPCPService = new VendaPcpService();

    gridData: VendaPCPModel[] = [];
    etapaId = null;
    etapasOptions: Object[] = [];
    dataInicial = new Date();
    dataFinal = addHours(3, new Date());

    get gridColumns(): Array<GridColumn> {
        return [
            new GridColumn("descricaoEtapa", "Descrição Etapa", GridColumnType.String),
            new GridColumn("localEntrega", "Local de Entrega", GridColumnType.String),
            new GridColumn("vendaCodigo", "Venda", GridColumnType.Integer),
            new GridColumn("dataEntrega", "Data de Retirada", GridColumnType.DateTime),
            new GridColumn("vendedor", "Vendedor", GridColumnType.String),
        ];
    }

    private async onAtualizar() {
        if (this.dataInicial && this.dataFinal) {
            this.gridData = await this.vendaPCPService
                .getByEtapa(this.etapaId, this.dataInicial, this.dataFinal)
                .withLoading()
                .resolveWithJSON<VendaPCPModel[]>();
        }
    }

    @Watch("etapaId")
    @Watch("dataInicial")
    @Watch("dataFinal")
    private async onChangeFilters() {
        await this.onAtualizar();
    }

    private onConsulta(model: VendaPCPModel) {
        const routeData = this.$router.resolve({ path: `/venda-edicao/${model.vendaId}` });
        window.open(routeData.href, "_blank");
    }

    private async mounted() {
        const data = await this.etapaPCPService.combo(true).resolveWithJSON<PaginationModel<EtapaPCPModel>>();

        this.etapasOptions = data.list.filter(e => e.ativo).map(item => ({ value: item.id, text: item.descricao }));
    }
}
