import Vue from "vue";

import { Component, Prop } from "@/decorators";

import "./pagination.scss";

@Component
export default class PaginationComponent extends Vue {
    _pageSize: number = null;

    @Prop({ type: Boolean, default: false }) controlaPageSize: boolean;
    @Prop(Number) total: number;
    @Prop(Number) pageIndex: number;
    @Prop(Number) pageSize: number;

    private get totalPages() {
        return Math.ceil(this.total / this.pageSize) || 1;
    }

    private get showNextButton() {
        return this.pageIndex != this.totalPages;
    }

    private get showPreviousButton() {
        return this.pageIndex != 1;
    }

    private goNextPage() {
        this.$emit("change-page", this.pageIndex + 1);
    }

    private goPreviousPage() {
        this.$emit("change-page", this.pageIndex - 1);
    }

    private goFirstPage() {
        this.$emit("change-page", 1);
    }

    private goLastPage() {
        this.$emit("change-page", this.totalPages);
    }

    private goPage(pageIndex) {
        this.$emit("change-page", pageIndex);
    }

    private onChangePageSize() {
        if (+this._pageSize != +this.pageSize) this.$emit("change-page-size", +this._pageSize);
    }

    private beforeMount() {
        this._pageSize = +this.pageSize;
    }
}
