import nfetch from "../utils/events/nfetch";

export default class NotaFiscalEmissaoLoteService {
    protected controllerName = "NotaFiscalEmissaoLote";

    public listVendaEmissaoLote(
        pageIndex: number,
        pageSize: number,
        emissaoDe: string,
        emissaoAte: string,
        situacao: number,
        filterKey: string,
    ): Promise<Response> {
        const encodedFilter = encodeURIComponent(filterKey);
        return nfetch(
            `/${this.controllerName}/List?filterKey=${encodedFilter}&pageIndex=${pageIndex}&pageSize=${pageSize}&emissaoDe=${emissaoDe}&emissaoAte=${emissaoAte}&situacao=${situacao}`,
            {
                credentials: "same-origin",
            },
        );
    }

    public emitirEmLoteByVendaIds(vendaIds: Array<number>): Promise<Response> {
        return nfetch(`/${this.controllerName}/EmissaoLoteByVendaIds`, {
            method: "post",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json; charset=UTF-8",
            },
            body: JSON.stringify(vendaIds),
        });
    }
}
