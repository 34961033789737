enum periodicidadeBalanco {
    Ano,
    PrimeiroSemestre,
    SegundoSemestre,
    PrimeiroTrimestre,
    SegundoTrimestre,
    TerceiroTrimestre,
    QuartoTrimestre,
    Mensal,
}

export default periodicidadeBalanco;
