import BaseModel from "./base/baseModel";
import DadosAdicionaisFichaTecnicaModel from "./dadosAdicionaisFichaTecnicaModel";
import DadosAdicionaisFichaTecnicaResultadoModel from "./dadosAdicionaisFichaTecnicaResultadoModel";
import EnsaioFichaTecnicaModel from "./ensaioFichaTecnicaModel";
import NotaFiscalEntradaModel from "./notaFiscalEntradaModel";
import ProdutoModel from "./produto/produtoModel";

export default class FichaTecnicaModel extends BaseModel {
    id?: number = 0;
    produtoId?: number = null;
    produto: ProdutoModel = new ProdutoModel();
    observacao?: string = null;
    categoriaTerapeutica?: string = null;
    conservacao?: string = null;
    notaFiscal: NotaFiscalEntradaModel = new NotaFiscalEntradaModel();
    ensaioFichaTecnica?: Array<EnsaioFichaTecnicaModel> = [];
    dadosAdicionais: Array<DadosAdicionaisFichaTecnicaModel> = [];
    dadosAdicionaisResultados: Array<DadosAdicionaisFichaTecnicaResultadoModel> = [];
}
