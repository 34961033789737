import { Component } from "@/decorators";
import CategoriaControleReclamacaoModel from "@/models/categoriaControleReclamacaoModel";
import CategoriaControleReclamacaoService from "@/services/categoriaControleReclamacaoService";

import EditComponentBase from "../editComponentBase";

@Component
export default class CategoriaControleReclamacaoComponent extends EditComponentBase<CategoriaControleReclamacaoModel> {
    private async beforeMount() {
        await this.setProps(
            new CategoriaControleReclamacaoService(),
            "categoria_controle_reclamacao",
            new CategoriaControleReclamacaoModel(),
        );
    }
}
