import Vue from "vue";

import comboComponent from "@/components/child/form/combo.vue";
import moedaComponent from "@/components/child/form/moeda.vue";
import { Component, Prop } from "@/decorators";
import TiposMovimentacao from "@/models/enum/tiposMovimentacao";
import ContaCorrenteModel from "@/models/financeiro/contaCorrenteModel";
import ContaGerencialModel from "@/models/financeiro/contaGerencialModel";
import FormaPagamentoModel from "@/models/financeiro/formaPagamentoModel";
import MovimentacaoContaModel from "@/models/movimentacaoContaModel";
import PaginationModel from "@/models/paginationModel";
import ContaCorrenteService from "@/services/financeiro/contaCorrenteService";
import ContaGerencialService from "@/services/financeiro/contaGerencialService";
import FormaPagamentoService from "@/services/financeiro/formaPagamentoService";

@Component({
    components: {
        comboComponent,
        moedaComponent,
    },
})
export default class EstornoComponent extends Vue {
    contasCorrentesOptions: Array<Object> = [];
    contasGerenciaisOptions: Array<Object> = [];
    formasPagamentoOptions: Array<Object> = [];

    @Prop({ type: Object, required: true }) model;
    @Prop(Boolean) isCancelado: boolean;

    private async loadContasCorrentes() {
        try {
            const data = await new ContaCorrenteService()
                .combo()
                .resolveWithJSON<PaginationModel<ContaCorrenteModel>>();

            this.contasCorrentesOptions = data.list.map(item => ({ value: item.id, text: item.descricao }));
        } catch {}
    }

    private async loadContasGerenciais(tipoMovimentacao: TiposMovimentacao) {
        try {
            const data = await new ContaGerencialService()
                .listByTipoMovimentacao("", 1, 999999, tipoMovimentacao)
                .resolveWithJSON<PaginationModel<ContaGerencialModel>>();

            this.contasGerenciaisOptions = data.list.map(item => ({ value: item.id, text: item.descricao }));
        } catch {}
    }

    private async loadFormasPagamento() {
        try {
            const data = await new FormaPagamentoService()
                .combo()
                .resolveWithJSON<PaginationModel<FormaPagamentoModel>>();

            this.formasPagamentoOptions = data.list.map(item => ({ value: item.id, text: item.descricao }));
        } catch {}
    }

    private mounted() {
        Promise.all([
            this.loadContasCorrentes(),
            this.loadContasGerenciais(TiposMovimentacao.Saida),
            this.loadFormasPagamento(),
        ])
            .withLoading()
            .then(() => {
                if ((this.model as MovimentacaoContaModel).id == null) {
                    const contaGerencial = this.contasGerenciaisOptions.filter(
                        conta => conta["text"].indexOf("Estorno") >= 0,
                    );

                    if (contaGerencial.length > 0) {
                        (this.model as MovimentacaoContaModel).contaGerencialId = contaGerencial[0]["value"];
                    }

                    const formaPagamento = this.formasPagamentoOptions.filter(
                        forma => forma["text"].indexOf("Dinheiro") >= 0,
                    );
                    if (formaPagamento.length > 0) {
                        (this.model as MovimentacaoContaModel).formaPagamentoId = formaPagamento[0]["value"];
                    }

                    (this.model as MovimentacaoContaModel).contaCorrenteId = this.contasCorrentesOptions[0]["value"];
                }
            })
            .catch(() => {});
    }
}
