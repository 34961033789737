import BaseModel from "./base/baseModel";
import TiposMovimentacaoEstoque from "./enum/tiposMovimentacaoEstoque";
import MovimentacaoEstoqueItemModel from "./movimentacaoEstoqueItemModel";

export default class MovimentacaoEstoqueModel extends BaseModel {
    id?: number = null;
    dataMovimentacao: Date = null;
    tipoMovimentacao: TiposMovimentacaoEstoque = null;
    tipoMovimentacaoDescricao?: string = null;
    estoqueOrigemId?: number = null;
    estoqueDestinoId?: number = null;
    produtoDescricao: string = null;
    loteDescricao: string = null;
    quantidade = 0;
    unidadeMedida: string = null;
    motivoMovimentacaoEstoqueId?: number = null;
    movimentacaoEstoqueItens: Array<MovimentacaoEstoqueItemModel> = [];
}

export interface MovimentacaoEstoqueListParameters {
    filterKeyId: string;
    dataMovimentacaoIniStr?: string;
    dataMovimentacaoFinStr?: string;
    tipoMovimentacaoId?: number;
    estoqueId?: number;
}
