import Vue from "vue";

import actionBarComponent from "@/components/child/actionBar/actionBar.vue";
import checkboxComponent from "@/components/child/form/checkbox.vue";
import comboComponent from "@/components/child/form/combo.vue";
import dataTooltipComponent from "@/components/child/form/datatooltip.vue";
import moedaComponent from "@/components/child/form/moeda.vue";
import { Component } from "@/decorators";
import Especie from "@/models/enum/especiePagamento";
import ContaCorrenteModel from "@/models/financeiro/contaCorrenteModel";
import FormaRecebimentoModel from "@/models/financeiro/formaRecebimentoModel";
import PaginationModel from "@/models/paginationModel";
import PdvModel from "@/models/pdv/pdvModel";
import ContaCorrenteService from "@/services/financeiro/contaCorrenteService";
import FormaRecebimentoService from "@/services/financeiro/formaRecebimentoService";
import PdvService from "@/services/pdv/pdvService";

import "../crud.scss";

@Component({
    components: {
        checkboxComponent,
        dataTooltipComponent,
        moedaComponent,
        comboComponent,
        actionBarComponent,
    },
})
export default class PdvEditComponent extends Vue {
    private service = new PdvService();

    model = new PdvModel();
    modelId = 0;
    contaCorrenteOptions: Array<Object> = [];
    formaRecebimentoOptions: Array<Object> = [];

    private async loadFormasRecebimento() {
        try {
            const data = await new FormaRecebimentoService()
                .comboByEspecie(Especie.Transferencia)
                .resolveWithJSON<PaginationModel<FormaRecebimentoModel>>();

            this.formaRecebimentoOptions = data.list.map(item => ({
                value: item.id,
                text: item.descricao,
            }));
        } catch {}
    }

    private async loadContasCorrentes() {
        try {
            const data = await new ContaCorrenteService()
                .combo()
                .resolveWithJSON<PaginationModel<ContaCorrenteModel>>();
            this.contaCorrenteOptions = data.list
                .filter(p => p.interna)
                .map(item => ({
                    value: item.id,
                    text: item.descricao,
                }));
        } catch {}
    }

    private async load() {
        this.model = new PdvModel();

        if (this.modelId > 0) {
            try {
                const data = await this.service.get(this.modelId).withLoading().resolveWithJSON<PdvModel>();
                this.model.updateFrom(data);
                this.model.gerarFinanceiro = false;
            } catch {
                this.$router.back();
            }
        } else {
            this.model.gerarFinanceiro = false;
            this.model.saldoInicial = 0;
            this.model.saldoAtual = 0;
        }
    }

    private async save() {
        try {
            const isValid = await this.$validator.validateAll();
            if (isValid) {
                const sucesso = await this.service[!this.modelId ? "insert" : "update"](this.model)
                    .withLoading()
                    .resolveWithoutJSON();

                if (sucesso) {
                    await this.$showInclusaoUpdate(this.model.id);
                    this.$router.back();
                }
            }
        } catch {}
    }

    private cancel() {
        this.$router.back();
    }

    private mounted() {
        Promise.all([this.loadContasCorrentes(), this.loadFormasRecebimento()])
            .withLoading()
            .then(() => {
                if (this.$route.params.id) {
                    this.modelId = +this.$route.params.id;
                }

                this.load();
            })
            .catch(() => {});
    }
}
