var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "crud crud-edit" },
    [
      _c("actionBarComponent", {
        attrs: {
          title: _vm.model.nomeExibicao,
          subTitle: _vm.model.descricao,
          showSave: false,
        },
      }),
      _vm._v(" "),
      _c(
        "form",
        {
          attrs: { novalidate: "novalidate" },
          on: {
            submit: function ($event) {
              $event.preventDefault()
              _vm.search()
            },
          },
        },
        [
          _vm.isCustomFields
            ? _c("fieldset", [
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-sm-4" },
                    [
                      _c(
                        "label",
                        {
                          staticClass: "control-label",
                          attrs: { for: "templateId" },
                        },
                        [_vm._v("Templates")]
                      ),
                      _vm._v(" "),
                      _c("combo-component", {
                        attrs: {
                          id: "templateId",
                          name: _vm.templateId,
                          data: _vm.templateOptions,
                          searchable: true,
                        },
                        model: {
                          value: _vm.templateId,
                          callback: function ($$v) {
                            _vm.templateId = $$v
                          },
                          expression: "templateId",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col-sm-6" },
                    [
                      _c(
                        "label",
                        {
                          staticClass: "control-label",
                          attrs: { for: "campoId" },
                        },
                        [_vm._v("Campos do Produto")]
                      ),
                      _vm._v(" "),
                      _c("combo-component", {
                        attrs: {
                          id: "campoId",
                          name: _vm.campoId,
                          data: _vm.camposOptions,
                          searchable: true,
                        },
                        model: {
                          value: _vm.campoId,
                          callback: function ($$v) {
                            _vm.campoId = $$v
                          },
                          expression: "campoId",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-2" }, [
                    _c("div", { staticClass: "form-group" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-secondary",
                          attrs: { type: "button" },
                          on: { click: _vm.onInclusaoCampo },
                        },
                        [
                          _vm._v(
                            "\n                            " +
                              _vm._s(_vm.$t("__.Crud.incluir")) +
                              "\n                        "
                          ),
                        ]
                      ),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-sm-12" },
                    [
                      !_vm.pags
                        ? _c("grid-component", {
                            attrs: {
                              data: _vm.camposSelecionados,
                              columns: _vm.camposGridColumns,
                              showTableHead: false,
                              showAddNewButton: false,
                              showTableFoot: false,
                              showTools: false,
                              showSortIcons: false,
                              showActionColumn: true,
                              showEditItem: false,
                              showRemoveItem: true,
                              extraActions: _vm.extraActions,
                            },
                            on: {
                              "remove-item": _vm.onRemoveCampo,
                              "extra-action": _vm.onExtraAction,
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("fieldset", [
            _c(
              "div",
              { staticClass: "row" },
              [
                _vm._l(_vm.model.filters, function (filter, index) {
                  return [
                    _vm.checkShowFilter(filter, _vm.model.filters)
                      ? _c(
                          "div",
                          { key: index, class: _vm.getColumnClass(filter) },
                          [
                            _c(
                              "div",
                              { staticClass: "form-group" },
                              [
                                _c(
                                  "label",
                                  {
                                    staticClass: "control-label",
                                    attrs: {
                                      for: filter.nomeCampo,
                                      title: filter.obrigatorio
                                        ? _vm.$t("__.obrigatorio")
                                        : "",
                                    },
                                  },
                                  [
                                    filter.obrigatorio
                                      ? _c(
                                          "span",
                                          { staticStyle: { color: "red" } },
                                          [_vm._v("*")]
                                        )
                                      : _vm._e(),
                                    _vm._v(
                                      "\n                                " +
                                        _vm._s(filter.descricao) +
                                        "\n                            "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                filter.title
                                  ? _c("data-tooltip-component", {
                                      attrs: { text: filter.title },
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                filter.tipoDado == 5
                                  ? _c("date-time-picker-component", {
                                      directives: [
                                        {
                                          name: "validate",
                                          rawName: "v-validate",
                                          value: {
                                            rules: {
                                              required: filter.obrigatorio,
                                            },
                                          },
                                          expression:
                                            "{ rules: { required: filter.obrigatorio } }",
                                        },
                                      ],
                                      attrs: {
                                        name: filter.nomeCampo,
                                        id: filter.nomeCampo,
                                        title: filter.title,
                                      },
                                      model: {
                                        value: filter.valor,
                                        callback: function ($$v) {
                                          _vm.$set(filter, "valor", $$v)
                                        },
                                        expression: "filter.valor",
                                      },
                                    })
                                  : filter.tipoDado == 6
                                  ? _c("date-time-picker-component", {
                                      directives: [
                                        {
                                          name: "validate",
                                          rawName: "v-validate",
                                          value: {
                                            rules: {
                                              required: filter.obrigatorio,
                                            },
                                          },
                                          expression:
                                            "{ rules: { required: filter.obrigatorio } }",
                                        },
                                      ],
                                      attrs: {
                                        name: filter.nomeCampo,
                                        id: filter.nomeCampo,
                                        title: filter.title,
                                        pickTime: true,
                                      },
                                      model: {
                                        value: filter.valor,
                                        callback: function ($$v) {
                                          _vm.$set(filter, "valor", $$v)
                                        },
                                        expression: "filter.valor",
                                      },
                                    })
                                  : filter.tipoDado == 16
                                  ? _c("date-time-picker-component", {
                                      directives: [
                                        {
                                          name: "validate",
                                          rawName: "v-validate",
                                          value: {
                                            rules: {
                                              required: filter.obrigatorio,
                                            },
                                          },
                                          expression:
                                            "{ rules: { required: filter.obrigatorio } }",
                                        },
                                      ],
                                      attrs: {
                                        name: filter.nomeCampo,
                                        id: filter.nomeCampo,
                                        title: filter.title,
                                        onlyPickMonth: true,
                                      },
                                      model: {
                                        value: filter.valor,
                                        callback: function ($$v) {
                                          _vm.$set(filter, "valor", $$v)
                                        },
                                        expression: "filter.valor",
                                      },
                                    })
                                  : filter.tipoDado == 1
                                  ? _c("checkbox-component", {
                                      directives: [
                                        {
                                          name: "validate",
                                          rawName: "v-validate",
                                          value: {
                                            rules: {
                                              required: filter.obrigatorio,
                                            },
                                          },
                                          expression:
                                            "{ rules: { required: filter.obrigatorio } }",
                                        },
                                      ],
                                      attrs: {
                                        name: filter.nomeCampo,
                                        id: filter.nomeCampo,
                                        title: filter.title,
                                      },
                                      model: {
                                        value: filter.valor,
                                        callback: function ($$v) {
                                          _vm.$set(filter, "valor", $$v)
                                        },
                                        expression: "filter.valor",
                                      },
                                    })
                                  : filter.tipoDado == 10 &&
                                    (filter.comboLoad == "ClienteService" ||
                                      filter.comboLoad == "PrescritorService")
                                  ? _c("search-combo-component", {
                                      directives: [
                                        {
                                          name: "validate",
                                          rawName: "v-validate",
                                          value: {
                                            rules: {
                                              required: filter.obrigatorio,
                                            },
                                          },
                                          expression:
                                            "{ rules: { required: filter.obrigatorio } }",
                                        },
                                      ],
                                      attrs: {
                                        id: filter.nomeCampo,
                                        name: filter.nomeCampo,
                                        title: filter.title,
                                        advancedSearch: true,
                                        advancedSearchCampos: [
                                          { campo: "text", descricao: "Nome" },
                                        ],
                                        customView: true,
                                        propDescription: "nome",
                                        service: filter.comboLoad.replace(
                                          "Service",
                                          ""
                                        ),
                                      },
                                      model: {
                                        value: filter.valor,
                                        callback: function ($$v) {
                                          _vm.$set(filter, "valor", $$v)
                                        },
                                        expression: "filter.valor",
                                      },
                                    })
                                  : filter.nomeCampo == "uf"
                                  ? _c("combo-component", {
                                      directives: [
                                        {
                                          name: "validate",
                                          rawName: "v-validate",
                                          value: {
                                            rules: {
                                              required: filter.obrigatorio,
                                            },
                                          },
                                          expression:
                                            "{ rules: { required: filter.obrigatorio } }",
                                        },
                                      ],
                                      attrs: {
                                        id: filter.nomeCampo,
                                        name: filter.nomeCampo,
                                        title: filter.title,
                                        data: filter.comboOptions,
                                        searchable: true,
                                        defaultValue:
                                          filter.tipoDado == 14
                                            ? filter.defaultValue
                                            : null,
                                      },
                                      model: {
                                        value: _vm.uf,
                                        callback: function ($$v) {
                                          _vm.uf = $$v
                                        },
                                        expression: "uf",
                                      },
                                    })
                                  : filter.nomeCampo == "produtoWithLote"
                                  ? _c("combo-component", {
                                      directives: [
                                        {
                                          name: "validate",
                                          rawName: "v-validate",
                                          value: {
                                            rules: {
                                              required: filter.obrigatorio,
                                            },
                                          },
                                          expression:
                                            "{ rules: { required: filter.obrigatorio } }",
                                        },
                                      ],
                                      attrs: {
                                        id: filter.nomeCampo,
                                        name: filter.nomeCampo,
                                        title: filter.title,
                                        data: filter.comboOptions,
                                        searchable: true,
                                      },
                                      model: {
                                        value: _vm.produtoId,
                                        callback: function ($$v) {
                                          _vm.produtoId = $$v
                                        },
                                        expression: "produtoId",
                                      },
                                    })
                                  : (filter.tipoDado == 10 ||
                                      filter.tipoDado == 12 ||
                                      filter.tipoDado == 14) &&
                                    filter.comboOptions != null
                                  ? _c("combo-component", {
                                      directives: [
                                        {
                                          name: "validate",
                                          rawName: "v-validate",
                                          value: {
                                            rules: {
                                              required: filter.obrigatorio,
                                            },
                                          },
                                          expression:
                                            "{ rules: { required: filter.obrigatorio } }",
                                        },
                                      ],
                                      attrs: {
                                        id: filter.nomeCampo,
                                        name: filter.nomeCampo,
                                        title: filter.title,
                                        data: filter.comboOptions,
                                        searchable: true,
                                        defaultValue:
                                          filter.tipoDado == 14
                                            ? filter.defaultValue
                                            : null,
                                        disabled:
                                          filter.nomeCampo == "cidade" &&
                                          !_vm.uf,
                                      },
                                      model: {
                                        value: filter.valor,
                                        callback: function ($$v) {
                                          _vm.$set(filter, "valor", $$v)
                                        },
                                        expression: "filter.valor",
                                      },
                                    })
                                  : filter.comboOptions != null &&
                                    filter.tipoDado == 15
                                  ? _c("multiselect-component", {
                                      directives: [
                                        {
                                          name: "validate",
                                          rawName: "v-validate",
                                          value: {
                                            required: filter.obrigatorio,
                                          },
                                          expression:
                                            "{ required: filter.obrigatorio }",
                                        },
                                      ],
                                      attrs: {
                                        options: filter.comboOptions,
                                        taggable:
                                          filter.comboMultselectTaggable,
                                        defaultValue: filter.valorMultiSelect,
                                      },
                                      model: {
                                        value: filter.valorMultiSelect,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            filter,
                                            "valorMultiSelect",
                                            $$v
                                          )
                                        },
                                        expression: "filter.valorMultiSelect",
                                      },
                                    })
                                  : _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: filter.valor,
                                          expression: "filter.valor",
                                        },
                                        {
                                          name: "validate",
                                          rawName: "v-validate",
                                          value: {
                                            rules: {
                                              required: filter.obrigatorio,
                                              max: 256,
                                            },
                                          },
                                          expression:
                                            "{ rules: { required: filter.obrigatorio, max: 256 } }",
                                        },
                                      ],
                                      staticClass: "form-control",
                                      attrs: {
                                        type: "text",
                                        id: filter.nomeCampo,
                                        name: filter.nomeCampo,
                                        autocomplete: "off",
                                        title: filter.title,
                                        disabled:
                                          filter.nomeCampo == "cidade" &&
                                          !_vm.uf,
                                      },
                                      domProps: { value: filter.valor },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            filter,
                                            "valor",
                                            $event.target.value
                                          )
                                        },
                                      },
                                    }),
                              ],
                              1
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]
                }),
              ],
              2
            ),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-sm-12 text-right" }, [
                _c(
                  "div",
                  { staticClass: "form-group", staticStyle: { margin: "0px" } },
                  [
                    _vm.model.filters.length > 0
                      ? _c(
                          "button",
                          {
                            staticClass: "btn btn-secondary",
                            staticStyle: {
                              "padding-top": "3px",
                              "padding-bottom": "3px",
                              "margin-right": "10px",
                            },
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                _vm.reset()
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                            " +
                                _vm._s(_vm.$t("__.Crud.limpar")) +
                                "\n                        "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.model.filters != null && _vm.model.filters.length > 0
                      ? _c(
                          "button",
                          {
                            staticClass: "btn btn-success",
                            staticStyle: {
                              "padding-top": "3px",
                              "padding-bottom": "3px",
                            },
                            attrs: {
                              type: "submit",
                              disabled: !_vm.temModelo(),
                            },
                          },
                          [
                            _c("i", {
                              staticClass: "fa fa-save",
                              attrs: { "aria-hidden": "true" },
                            }),
                            _vm._v(
                              "\n                            Buscar\n                        "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]
                ),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("grid-component", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.gridColumns != [],
                expression: "gridColumns != []",
              },
            ],
            ref: "gridcomponent",
            attrs: {
              gridNameId: "relatorio",
              columns: _vm.gridColumns,
              conditionShowSubData: function () {
                return true
              },
              data: _vm.gridData,
              showSearchField: false,
              showAddNewButton: false,
              showExpand: _vm.showExpand,
              showActionColumn: false,
              showEditItem: false,
              showRemoveItem: false,
              "page-index": _vm.pageIndex,
              "page-size": _vm.pageSize,
              total: _vm.total,
              isRelatorio: true,
              gerarXLSNaApi: _vm.model.gerarXLSNaApi,
              gerarPDFNaApi: true,
              footer: _vm.totais,
              subColumns: _vm.gridColumnsVinculos,
              subDataName: "subItens",
              tituloPagina: _vm.model.nomeExibicao,
            },
            on: {
              "gerar-xls": function ($event) {
                _vm.pags = true
                _vm.tipoDocGerar = 0
              },
              "gerar-impressao": function ($event) {
                _vm.pags = true
                _vm.tipoDocGerar = 2
              },
              "change-filter-key": _vm.onChangeFilterKey,
              "change-sort": _vm.onChangeSort,
              "change-page": _vm.onChangePage,
              "gerar-XLS-Api": _vm.downloadXLS,
              "gerar-PDF-Api": _vm.downloadPdf,
            },
          }),
          _vm._v(" "),
          _vm.totais.length > 0
            ? _c("fieldset", [
                _c(
                  "div",
                  { staticClass: "row" },
                  _vm._l(_vm.totais, function (total) {
                    return _c(
                      "div",
                      { key: total.descricao, staticClass: "col-sm-12" },
                      [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "font-weight": "bold",
                              "font-size": "18px",
                            },
                          },
                          [
                            _vm._v(
                              "\n                        Total " +
                                _vm._s(total.descricao) +
                                ": " +
                                _vm._s(total.valor) +
                                "\n                    "
                            ),
                          ]
                        ),
                      ]
                    )
                  })
                ),
              ])
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }