import Vue from "vue";
import { mapGetters, mapMutations, mapState } from "vuex";

import buttonIncluirComponent from "@/components/child/form/button/buttonIncluir.vue";
import cnpjComponent from "@/components/child/form/cnpj.vue";
import comboComponent from "@/components/child/form/combo.vue";
import dataTooltipComponent from "@/components/child/form/datatooltip.vue";
import fieldsetComponent from "@/components/child/form/fieldset.vue";
import moedaComponent from "@/components/child/form/moeda.vue";
import gridComponent from "@/components/child/grid/grid.vue";
import { GridColumn, GridColumnType } from "@/components/child/grid/gridColumn";
import { Component, Watch } from "@/decorators";
import ConfiguracaoFranquiaModel from "@/models/configuracaoFranquia/configuracaoFranquiaModel";
import Configuracoes from "@/models/enum/configuracao/configuracoes";
import BandeiraModel from "@/models/financeiro/bandeiraModel";
import NotaFiscalEletronicaEmissaoModel, { Cartao, Pagamento } from "@/models/notaFiscalEletronicaEmissaoModel";
import PaginationModel from "@/models/paginationModel";
import BandeiraService from "@/services/financeiro/bandeiraService";
import { AppState, Getters, Mutations } from "@/store/store";

import "../crud/crud.scss";

@Component({
    components: {
        gridComponent,
        dataTooltipComponent,
        cnpjComponent,
        comboComponent,
        moedaComponent,
        fieldsetComponent,
        buttonIncluirComponent,
    },
    computed: mapState<AppState>({
        ...mapGetters(["GET_CONFIG_FRANQUIA"] as Getters),
        nota: state => state.nfeContext.nota,
        consulta: state => state.nfeContext.consulta,
    }),
    methods: mapMutations(["SET_NOTA"] as Mutations),
})
export default class PagamentoComponent extends Vue {
    // State computed props
    nota: NotaFiscalEletronicaEmissaoModel;
    consulta: boolean;
    SET_NOTA: (nota: NotaFiscalEletronicaEmissaoModel) => void;
    GET_CONFIG_FRANQUIA: (configuracao: Configuracoes) => ConfiguracaoFranquiaModel;

    private model = new Pagamento();
    private cartaoModel = new Cartao();
    private gridData = [];
    nsuObrigatorio = false;
    private editingModel = false;
    private indexEdditingModel = -1;
    bandeiraOptions: Array<Object> = [];

    private indicadorPagamentoOptions: Array<Object> = [
        { text: "0-Pagamento à Vista", value: 0 },
        { text: "1-Pagamento à Prazo", value: 1 },
    ];

    private meioPagamentoOptions: Array<Object> = [
        { text: "01-Dinheiro", value: 1 },
        { text: "02-Cheque", value: 2 },
        { text: "03-Cartão de Crédito", value: 3 },
        { text: "04-Cartão de Débito", value: 4 },
        { text: "05-Crédito Loja", value: 5 },
        { text: "10-Vale Alimentação", value: 10 },
        { text: "11-Vale Refeição", value: 11 },
        { text: "12-Vale Presente", value: 12 },
        { text: "13-Vale Combustível", value: 13 },
        { text: "14-Duplicata Mercantil", value: 14 },
        { text: "15-Boleto Bancário", value: 15 },
        { text: "90-Sem pagamento", value: 90 },
        { text: "99-Outros", value: 99 },
    ];

    private gridColumns: Array<GridColumn> = [
        new GridColumn("indicadorPagamentoDescricao", "Tipo de pagamento", GridColumnType.String),
        new GridColumn("meioPagamentoDescricao", "Meio de pagamento", GridColumnType.String),
        new GridColumn("valorPagamento", "Valor", GridColumnType.Money),
        new GridColumn("descricao", "Descrição", GridColumnType.String),
    ];

    // @ts-ignore
    @Watch("nota.pagamentos", { deep: true })
    private onChangeData() {
        this.gridData = this.nota.pagamentos.map((pagamento, index) => ({
            ...pagamento,
            indicadorPagamentoDescricao:
                pagamento.indicadorPagamento == null
                    ? ""
                    : this.indicadorPagamentoOptions.filter(i => i["value"] == pagamento.indicadorPagamento)[0]["text"],
            meioPagamentoDescricao: this.meioPagamentoOptions.filter(i => i["value"] == pagamento.meioPagamento)[0][
                "text"
            ],
            index,
        }));
    }

    private async onAdicionarItem() {
        const isValid = await this.$validator.validateAll();

        if (isValid) {
            if (!this.editingModel) {
                this.nota.pagamentos.push(this.model);
            } else {
                if (this.indexEdditingModel >= 0) {
                    this.nota.pagamentos[this.indexEdditingModel].indicadorPagamento = this.model.indicadorPagamento;
                    this.nota.pagamentos[this.indexEdditingModel].meioPagamento = this.model.meioPagamento;
                    this.nota.pagamentos[this.indexEdditingModel].valorPagamento = this.model.valorPagamento;
                    this.nota.pagamentos[this.indexEdditingModel].descricao = this.model.descricao;
                    this.nota.pagamentos[this.indexEdditingModel].automatico = false;
                }
            }
            this.model.cartao = this.cartaoModel;
            this.SET_NOTA(this.nota);
            this.clear();
        }
    }

    private async onEditItem(pagamento: Pagamento) {
        this.editingModel = true;
        this.indexEdditingModel = pagamento.index;

        if (this.indexEdditingModel >= 0) {
            this.model.indicadorPagamento = this.nota.pagamentos[this.indexEdditingModel].indicadorPagamento;
            this.model.meioPagamento = this.nota.pagamentos[this.indexEdditingModel].meioPagamento;
            this.model.valorPagamento = this.nota.pagamentos[this.indexEdditingModel].valorPagamento;
            this.model.descricao = this.nota.pagamentos[this.indexEdditingModel].descricao;
        }
    }

    private async loadBandeiras() {
        try {
            const data = await new BandeiraService().combo().resolveWithJSON<PaginationModel<BandeiraModel>>();
            this.bandeiraOptions = data.list.map(item => ({ value: item.id, text: item.descricao }));
        } catch {}
    }

    private onRemoveItem(pagamento: Pagamento) {
        this.nota.pagamentos.splice(pagamento.index, 1);
        this.SET_NOTA(this.nota);
    }

    private clear() {
        this.editingModel = false;
        this.indexEdditingModel = -1;

        this.model = new Pagamento();
        this.gridData = [];
        this.$validator.reset();
    }

    private mounted() {
        this.nsuObrigatorio = this.GET_CONFIG_FRANQUIA(Configuracoes.NsuObrigatorioFaturamentoCartao).verdadeiro;
        Promise.all([this.loadBandeiras()]).withLoading();
    }
}
