import NotaFiscalSaidaModel from "../models/notaFiscalSaidaModel";
import VendaItemModel from "../models/vendaItemModel";
import nfetch from "../utils/events/nfetch";

import { IService } from "./base/iService";

export class NotaFiscalInfo {
    desc: string; // e.g. NFS-e
    name: string; // Sem espaços e caracteres especiais. e.g. NFSe
    isValidItem: (item: VendaItemModel, emiteNotaServico: boolean) => boolean | number;
}

export default class NotaFiscalSaidaService extends IService<NotaFiscalSaidaModel> {
    // A ordem do array corresponde a ordem do enum.
    public static NFinfo: NotaFiscalInfo[] = [
        {
            desc: "NFS-e",
            name: "Nfse",
            isValidItem: (item: VendaItemModel, emiteNotaServico: boolean) =>
                (item.manipulacaoOrdemId || item.manipulacaoOrdem) && !item.vendaEstoque && emiteNotaServico,
        },
        {
            desc: "NFC-e",
            name: "Nfce",
            isValidItem: (item: VendaItemModel, emiteNotaServico: boolean) =>
                (item.produtoId || (!item.manipulacaoOrdem && !item.manipulacaoOrdemId)) &&
                !item.vendaEstoque &&
                emiteNotaServico,
        },
        {
            desc: "NF-e (DANFe)",
            name: "Nfe",
            isValidItem: (item: VendaItemModel, emiteNotaServico: boolean) =>
                !emiteNotaServico || item.produtoId || (!item.manipulacaoOrdem && !item.manipulacaoOrdemId),
        },
    ];

    constructor() {
        super("NotaFiscalSaida");
    }

    public cancelaNota(notaId: number): Promise<Response> {
        //@ts-ignore
        return nfetch(`/${this.controllerName}/CancelaNota?notaId=${notaId}`, {
            method: "post",
            credentials: "same-origin",
            headers: {
                "XSRF-TOKEN": this.getCookie("XSRF-TOKEN"),
            },
        });
    }
}
