var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "crud crud-list" }, [
    _c("div", { staticClass: "info-div" }, [
      _c("h1", { staticClass: "title" }, [
        _vm._v(
          _vm._s(
            _vm.$t("__.Components.parent.topmenu.topmenu_vue_html.logLevel")
          )
        ),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "form",
      {
        attrs: { novalidate: "novalidate" },
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.updateLogLevel($event)
          },
        },
      },
      [
        _c(
          "div",
          { staticClass: "row", staticStyle: { "margin-bottom": "40px" } },
          [
            _c("div", { staticClass: "col-sm-10" }, [
              _c("span", { staticStyle: { "font-size": "18px" } }, [
                _vm._v("Log Level Atual: " + _vm._s(_vm.logLevel)),
              ]),
            ]),
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-sm-4" }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "tooltip",
                    rawName: "v-tooltip",
                    value: _vm.errors.first("newLogLevel"),
                    expression: "errors.first('newLogLevel')",
                  },
                ],
                staticClass: "form-group",
              },
              [
                _vm._m(0),
                _vm._v(" "),
                _c("combo-component", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required",
                      expression: "'required'",
                    },
                  ],
                  attrs: {
                    id: "newLogLevel",
                    name: "newLogLevel",
                    data: _vm.newLogLevelOptions,
                  },
                  model: {
                    value: _vm.newLogLevel,
                    callback: function ($$v) {
                      _vm.newLogLevel = $$v
                    },
                    expression: "newLogLevel",
                  },
                }),
              ],
              1
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-sm-12 newbtn" }, [
            _c("div", { staticClass: "form-group" }, [
              _c(
                "button",
                { staticClass: "btn btn-success", attrs: { type: "submit" } },
                [_vm._v(_vm._s(_vm.$t("__.Crud.salvar")))]
              ),
            ]),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "label",
      { staticClass: "control-label", attrs: { for: "newLogLevel" } },
      [
        _c("span", { staticStyle: { color: "red" } }, [_vm._v("*")]),
        _vm._v("\n                        New Log Level\n                    "),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }