import { GridColumn, GridColumnType } from "@/components/child/grid/gridColumn";
import { Component } from "@/decorators";
import RecompensaModel from "@/models/programafidelidade/recompensaModel";
import RecompensaService from "@/services/recompensaService";

import ListComponentBase from "../listComponentBase";

@Component
export default class RecompensaListComponent extends ListComponentBase<RecompensaModel> {
    get gridColumns(): Array<GridColumn> {
        return [
            new GridColumn("premio", "Prêmio", GridColumnType.String),
            new GridColumn("pontosNecessarios", "Pontos Necessários", GridColumnType.Decimal),
            new GridColumn("ativo", this.$t("__.ts.ativo"), GridColumnType.Boolean),
        ];
    }

    private beforeMount() {
        this.setProps(new RecompensaService(), "recompensa", "premio");
    }
}
