import { mapState } from "vuex";

import dateTimePickerComponent from "@/components/child/form/datetimepicker.vue";
import { GridAction } from "@/components/child/grid/gridAction";
import { GridColors, GridColumn, GridColumnExt, GridColumnType } from "@/components/child/grid/gridColumn";
import { Component } from "@/decorators";
import ConfiguracaoDotzModel from "@/models/dotz/configuracaoDotzModel";
import PontuacaoDotzModel from "@/models/dotz/pontuacaoDotzModel";
import StatusEnvioDotz from "@/models/enum/dotz/statusEnvioDotz";
import TipoEnvio from "@/models/enum/tipoEnvio";
import PaginationModel from "@/models/paginationModel";
import UsuarioModel from "@/models/usuarioModel";
import ConfiguracaoDotzService from "@/services/dotz/configuracaoDotzService";
import PontuacaoDotzService from "@/services/dotz/pontuacaoDotzService";
import { AppState } from "@/store/store";
import Delay from "@/utils/common/delay";

import ShortcutComponent from "../../shortcut/shortcut";
import shortcutComponent from "../../shortcut/shortcut.vue";
import VendasPontuadasListComponent from "../historicoEnviosDotz/vendasPontuadas";
import vendasPontuadasListComponent from "../historicoEnviosDotz/vendasPontuadas.vue";
import ListComponentBase from "../listComponentBase";

@Component({
    components: {
        shortcutComponent,
        vendasPontuadasListComponent,
        dateTimePickerComponent,
    },
    computed: {
        ...mapState({
            usuarioLogado: (state: AppState) => state.session.usuarioLogado,
        }),
    },
})
export default class PontuacaoManualDotzComponent extends ListComponentBase<PontuacaoDotzModel> {
    // State computed props
    usuarioLogado: UsuarioModel;

    private pontuacaoDotzService = new PontuacaoDotzService();
    private configuracaoDotzService = new ConfiguracaoDotzService();

    private shortcutComponent: ShortcutComponent = null;
    private vendasPontuadasListComponent: VendasPontuadasListComponent = null;
    private periodo = new Date();
    private extraActions: Array<object> = [];
    private extraActionsDetalhes: Array<GridAction> = [];
    private pontuacaoDotzTotal = 0;
    pageSize = 50;

    get gridColumns(): Array<GridColumn> {
        return [
            new GridColumn("nomePontuado", "Prescritor", GridColumnType.String),
            new GridColumnExt({
                value: "pontuacao",
                text: "Pontuação",
                type: GridColumnType.Integer,
                editable: true,
                onChange: (_, index) => this.onChangePontuacao(index),
            }),
            new GridColumn("periodoEnvio", "Período de Envio", GridColumnType.Period),
            new GridColumn("statusDescricao", "Situação do Envio", GridColumnType.String),
        ];
    }

    public async load() {
        try {
            this.pageIndex = this.gridFilterKey !== this.filterKey ? 1 : this.pageIndex;
            this.filterKey = this.gridFilterKey;

            const data = await this.pontuacaoDotzService
                .list(
                    this.gridFilterKey,
                    this.gridSortKey,
                    this.gridSortOrder,
                    this.pags ? 1 : this.pageIndex,
                    this.pags ? 999999 : this.pageSize,
                    { statusEnvioDotz: StatusEnvioDotz.Pendente },
                )
                .withLoading()
                .resolveWithJSON<PaginationModel<PontuacaoDotzModel>>();

            this.gridData = data.list;
            this.total = data.total;
            this.pageIndex = data.pageIndex;
            this.pageSize = data.pageSize;

            this.extraActions.splice(0);
            this.extraActionsDetalhes.splice(0);

            for (let i = 0; i < this.gridData.length; i++) {
                this.extraActionsDetalhes.push(
                    new GridAction("detalhes", "Visualizar vendas pontuadas", "fa fa-search", GridColors.BLUE),
                );
            }

            this.extraActions.push(this.extraActionsDetalhes);

            this.pontuacaoDotzTotal = data.list.reduce((total, item) => total + item.pontuacao, 0);
        } catch {}
    }

    private async onChangePontuacao(index: number) {
        const item: PontuacaoDotzModel = this.gridData[index];
        item.pontuacao = this.gridData[index].pontuacao;
        await this.pontuacaoDotzService.updatePontuacao(item.pontuacao, item.id).withLoading();
    }

    private async onGerarPontuacao() {
        while (!this.$refs.shortcutComponent) await Delay(5);
        this.shortcutComponent = this.$refs.shortcutComponent as ShortcutComponent;
        this.shortcutComponent.title = "Gerar pontuação";

        this.shortcutComponent.show();
    }

    private async onEnviarPontuacao() {
        const response = await this.$showQuestion(this.$t("__.ts.atencao"), this.$t("__.ts.alertaTansmIrrever"));

        if (response) {
            await this.pontuacaoDotzService.enviarPontuacaoPendente().withLoading();

            await this.$showSuccess(this.$t("__.ts.sucesso"), this.$t("__.ts.pontuacaoEnviada"));
            this.load();
        }
    }

    private async onConfirm() {
        if (await this.$validator.validateAll()) {
            await this.pontuacaoDotzService.gerarPontuacaoPendente(this.periodo).withLoading();
            this.shortcutComponent.hide();
            this.load();
        }
    }

    private async onExtraAction(name: string, pontuacao: PontuacaoDotzModel) {
        if (name.trim() == "detalhes") {
            this.vendasPontuadasListComponent = this.$refs.vendasPontuadasListComponent as VendasPontuadasListComponent;
            await this.vendasPontuadasListComponent.show(pontuacao.id);
        }
    }

    private async onRemove(teste: PontuacaoDotzModel) {
        await this.pontuacaoDotzService.deletePontuacao(teste.id).withLoading();
        this.load();
    }

    public async afterMounted() {
        if (!this.usuarioLogado.franquiaUtilizaDotz) {
            this.$showWarning(this.$t("__.ts.atencao"), this.$t("__.ts.franquiaNaoUsaDotz"));
            this.$router.back();
        }

        const configuracaoDotz = await this.configuracaoDotzService
            .GetCurrent()
            .withLoading()
            .resolveWithJSON<ConfiguracaoDotzModel>();

        if (configuracaoDotz.franquiaEnvioId) {
            this.$showWarning(this.$t("__.ts.atencao"), this.$t("__.ts.franquiaConfigOutraFranquia"));
            this.$router.back();
        }

        if (configuracaoDotz.tipoEnvio !== TipoEnvio.Manual) {
            this.$showWarning(this.$t("__.ts.atencao"), this.$t("__.ts.franquiaNaoEnviaManual"));
            this.$router.back();
        }
    }
}
