import BaseModel from "./base/baseModel";
import StatusFracionamento from "./enum/statusFracionamento";
import ItemEmbalagemFracionamentoModel from "./itemEmbalagemFracionamentoModel";
import Produto from "./produto/produtoModel";
import ProdutoLoteModel from "./produtoLoteModel";
import UnidadeMedidaModel from "./unidadeMedidaModel";

export default class FracionamentoItemModel extends BaseModel {
    constructor() {
        super();
        this.unidadeMedida = new UnidadeMedidaModel();
        this.produtoLote = new ProdutoLoteModel();
    }

    id?: number = null;
    fracionamentoId?: number = null;
    descricao: string = null;
    observacao: string = null;
    produtoId = 0;
    produto: Produto = null;
    produtoLoteId?: number = null;
    produtoLote: ProdutoLoteModel = null;
    quantidade = 0;
    quantidadePesagem = 0;
    unidadeMedidaId = 0;
    unidadeMedida: UnidadeMedidaModel = null;
    valor = 0;
    valorTotal = 0;
    statusFracionamento: StatusFracionamento = null;
    quantidadeSeparada = 0;

    // Dados Separação
    produtoDescricao: string = null;
    produtoLoteDescricao: string = null;
    unidadeMedidaDescricao: string = null;
    itensEmbalagemFracionamento: ItemEmbalagemFracionamentoModel[] = [];

    getMarkupByQuantidade(quantidade: number) {
        // Markups que estejam dentro do limite da quantidade ordenados do menor ao maior
        const markups = this.produto.produtoMarkupDinamico
            .filter(p => !p.demaisValores && quantidade <= p.quantidadeLimite)
            .sort((a, b) => (a.quantidadeLimite > b.quantidadeLimite ? 1 : -1));

        // Se não tem markup dentro do limite
        if (markups.length == 0) {
            const demaisValoresMarkup = this.produto.produtoMarkupDinamico.find(p => p.demaisValores);
            return demaisValoresMarkup ? demaisValoresMarkup.markup : this.produto.markup;
        }

        // Pega o primeiro markup pois é o que está dentro da faixa
        return markups[0].markup;
    }
}
