var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "crud crud-edit" },
    [
      _c("actionBarComponent", {
        attrs: {
          title: _vm.$t("__.Crud.alterarsenha.alterarsenha_vue_html.altSenha"),
        },
        on: { onSave: _vm.save },
      }),
      _vm._v(" "),
      _c(
        "form",
        {
          attrs: { novalidate: "novalidate" },
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.save($event)
            },
          },
        },
        [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-sm-4" }, [
              _c("div", { staticClass: "form-group" }, [
                _c(
                  "label",
                  {
                    staticClass: "control-label",
                    attrs: {
                      for: "novasenha",
                      title: _vm.$t("__.obrigatorio"),
                    },
                  },
                  [
                    _c("span", { staticStyle: { color: "red" } }, [
                      _vm._v("*"),
                    ]),
                    _vm._v(
                      "\n                        " +
                        _vm._s(
                          _vm.$t(
                            "__.Crud.alterarsenha.alterarsenha_vue_html.novaSenha"
                          )
                        ) +
                        "\n                    "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.novasenha,
                      expression: "novasenha",
                    },
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "alpha_spaces|max:256",
                      expression: "'alpha_spaces|max:256'",
                    },
                  ],
                  staticClass: "form-control",
                  attrs: {
                    autocomplete: "off",
                    type: "password",
                    id: "novasenha",
                    name: "novasenha",
                  },
                  domProps: { value: _vm.novasenha },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.novasenha = $event.target.value
                    },
                  },
                }),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-sm-4" }, [
              _c("div", { staticClass: "form-group" }, [
                _c(
                  "label",
                  {
                    staticClass: "control-label",
                    attrs: {
                      for: "confirmarsenha",
                      title: _vm.$t("__.obrigatorio"),
                    },
                  },
                  [
                    _c("span", { staticStyle: { color: "red" } }, [
                      _vm._v("*"),
                    ]),
                    _vm._v(
                      "\n                        " +
                        _vm._s(
                          _vm.$t(
                            "__.Crud.alterarsenha.alterarsenha_vue_html.confSenha"
                          )
                        ) +
                        "\n                    "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.confirmarsenha,
                      expression: "confirmarsenha",
                    },
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "alpha_spaces|max:256",
                      expression: "'alpha_spaces|max:256'",
                    },
                  ],
                  staticClass: "form-control",
                  attrs: {
                    autocomplete: "off",
                    type: "password",
                    id: "confirmarsenha",
                    name: "confirmarsenha",
                  },
                  domProps: { value: _vm.confirmarsenha },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.confirmarsenha = $event.target.value
                    },
                  },
                }),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-sm-12 newbtn" }, [
              _c("div", { staticClass: "form-group" }, [
                _c(
                  "button",
                  { staticClass: "btn btn-success", attrs: { type: "submit" } },
                  [
                    _c("i", {
                      staticClass: "fa fa-save",
                      attrs: { "aria-hidden": "true" },
                    }),
                    _vm._v(
                      "\n                        " +
                        _vm._s(_vm.$t("__.Crud.salvar")) +
                        "\n                    "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-secondary",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        _vm.cancel()
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n                        " +
                        _vm._s(_vm.$t("__.Crud.voltar")) +
                        "\n                    "
                    ),
                  ]
                ),
              ]),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }