var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "crud crud-edit" },
    [
      _c(
        "actionBarComponent",
        {
          attrs: {
            showSave: !_vm.somenteConsulta,
            slots: 5,
            title: _vm.$t(
              "__.Crud.formulapadrao.edit_vue_html.frmlaPadraoEdit"
            ),
          },
          on: { onSave: _vm.save },
        },
        [
          _vm.validarVisibilidadeBotoes("produzir")
            ? _c(
                "div",
                {
                  attrs: {
                    slot: "buttonSlot0",
                    title: _vm.$t(
                      "__.Crud.formulapadrao.edit_vue_html.produzir"
                    ),
                  },
                  on: { click: _vm.onProduzir },
                  slot: "buttonSlot0",
                },
                [
                  _c("i", {
                    staticClass: "fa fa-plus-circle",
                    attrs: { "aria-hidden": "true" },
                  }),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.validarVisibilidadeBotoes("deletar")
            ? _c(
                "div",
                {
                  attrs: {
                    slot: "buttonSlot1",
                    title: _vm.$t("__.Crud.excluir"),
                  },
                  on: { click: _vm.onDelete },
                  slot: "buttonSlot1",
                },
                [
                  _c("i", {
                    staticClass: "fa fa-times",
                    attrs: { "aria-hidden": "true" },
                  }),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.validarVisibilidadeBotoes("replicar")
            ? _c(
                "div",
                {
                  attrs: {
                    slot: "buttonSlot2",
                    title: _vm.$t("__.Crud.replicar"),
                  },
                  on: {
                    click: function ($event) {
                      _vm.onReplicar()
                    },
                  },
                  slot: "buttonSlot2",
                },
                [
                  _c("i", {
                    staticClass: "fa fa-sync",
                    attrs: { "aria-hidden": "true" },
                  }),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.validarVisibilidadeBotoes("revisar")
            ? _c(
                "div",
                {
                  attrs: {
                    slot: "buttonSlot3",
                    title: _vm.$t(
                      "__.Crud.formulapadrao.edit_vue_html.revisAjustar"
                    ),
                  },
                  on: {
                    click: function ($event) {
                      _vm.revisarTudo().withLoading()
                    },
                  },
                  slot: "buttonSlot3",
                },
                [
                  _c("i", {
                    staticClass: "fa fa-clipboard-check",
                    attrs: { "aria-hidden": "true" },
                  }),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            {
              attrs: { slot: "buttonSlot4", title: _vm.$t("__.Crud.imprimir") },
              on: {
                click: function ($event) {
                  _vm.onImprimirFormula()
                },
              },
              slot: "buttonSlot4",
            },
            [
              _c("i", {
                staticClass: "fa fa-print",
                attrs: { "aria-hidden": "true" },
              }),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "form",
        {
          attrs: { novalidate: "novalidate" },
          on: {
            submit: function ($event) {
              $event.preventDefault()
              _vm.save()
            },
          },
        },
        [
          _c(
            "fieldset-component",
            {
              attrs: {
                title: _vm.$t(
                  "__.Crud.formulapadrao.edit_vue_html.dadosGerais"
                ),
                collapsed: false,
              },
            },
            [
              _c("div", { attrs: { slot: "rows" }, slot: "rows" }, [
                _vm.isAdmin && _vm.modelId > 0
                  ? _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-sm-10" }, [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: _vm.errors.first("franquiaId"),
                                expression: "errors.first('franquiaId')",
                              },
                            ],
                            staticClass: "form-group",
                          },
                          [
                            _c(
                              "label",
                              {
                                staticClass: "control-label",
                                attrs: { for: "franquiaId" },
                              },
                              [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(
                                      _vm.$t(
                                        "__.Crud.formulapadrao.edit_vue_html.franquia"
                                      )
                                    ) +
                                    "\n                            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("combo-component", {
                              attrs: {
                                id: "franquiaId",
                                name: "franquiaId",
                                data: _vm.franquiaOptions,
                                searchable: false,
                                disabled: _vm.somenteConsulta,
                              },
                              model: {
                                value: _vm.franquiaId,
                                callback: function ($$v) {
                                  _vm.franquiaId = $$v
                                },
                                expression: "franquiaId",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-sm-2" }, [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: _vm.errors.first("ativo"),
                                expression: "errors.first('ativo')",
                              },
                            ],
                            staticClass: "form-group",
                          },
                          [
                            _c(
                              "label",
                              {
                                staticClass: "control-label",
                                attrs: { for: "ativo" },
                              },
                              [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(
                                      _vm.$t(
                                        "__.Crud.formulapadrao.edit_vue_html.ativo"
                                      )
                                    ) +
                                    "\n                            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("checkbox-component", {
                              attrs: {
                                id: "ativo",
                                name: "ativo",
                                disabled: _vm.somenteConsulta,
                              },
                              model: {
                                value: _vm.model.ativo,
                                callback: function ($$v) {
                                  _vm.$set(_vm.model, "ativo", $$v)
                                },
                                expression: "model.ativo",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c("div", { class: "col-sm-" + [_vm.isAdmin ? "5" : "4"] }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("descricao"),
                            expression: "errors.first('descricao')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: {
                              for: "descricao",
                              title: _vm.$t("__.obrigatorio"),
                            },
                          },
                          [
                            _c("span", { staticStyle: { color: "red" } }, [
                              _vm._v("*"),
                            ]),
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Crud.formulapadrao.edit_vue_html.descricao"
                                  )
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.model.descricao,
                              expression: "model.descricao",
                            },
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required|max:256",
                              expression: "'required|max:256'",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            autocomplete: "off",
                            type: "text",
                            id: "descricao",
                            name: "descricao",
                            disabled: _vm.somenteConsulta,
                          },
                          domProps: { value: _vm.model.descricao },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.model,
                                "descricao",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { class: "col-sm-" + [_vm.isAdmin ? "5" : "4"] }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("produtoId"),
                            expression: "errors.first('produtoId')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: {
                              for: "produtoId",
                              title: _vm.$t("__.obrigatorio"),
                            },
                          },
                          [
                            _c("span", { staticStyle: { color: "red" } }, [
                              _vm._v("*"),
                            ]),
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Crud.formulapadrao.edit_vue_html.prodFinal"
                                  )
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("combo-component", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required",
                              expression: "'required'",
                            },
                          ],
                          attrs: {
                            id: "produtoId",
                            name: "produtoId",
                            data: _vm.produtosOptions,
                            searchable: true,
                            customView: true,
                            disabled: _vm.somenteConsulta,
                          },
                          model: {
                            value: _vm.model.produtoId,
                            callback: function ($$v) {
                              _vm.$set(_vm.model, "produtoId", $$v)
                            },
                            expression: "model.produtoId",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-2" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("tipoFormulaPadrao"),
                            expression: "errors.first('tipoFormulaPadrao')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: {
                              for: "tipoFormulaPadrao",
                              title: _vm.$t("__.obrigatorio"),
                            },
                          },
                          [
                            _c("span", { staticStyle: { color: "red" } }, [
                              _vm._v("*"),
                            ]),
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Crud.formulapadrao.edit_vue_html.tipoFrmlPadrao"
                                  )
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("combo-component", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required",
                              expression: "'required'",
                            },
                          ],
                          attrs: {
                            id: "tipoFormulaPadrao",
                            name: "tipoFormulaPadrao",
                            data: _vm.tiposFormulaPadraoOptions,
                            searchable: true,
                            disabled: _vm.somenteConsulta,
                          },
                          model: {
                            value: _vm.model.tipoFormulaPadrao,
                            callback: function ($$v) {
                              _vm.$set(_vm.model, "tipoFormulaPadrao", $$v)
                            },
                            expression: "model.tipoFormulaPadrao",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  !_vm.isAdmin
                    ? _c("div", { staticClass: "col-sm-2" }, [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: _vm.errors.first("ativo"),
                                expression: "errors.first('ativo')",
                              },
                            ],
                            staticClass: "form-group",
                          },
                          [
                            _c(
                              "label",
                              {
                                staticClass: "control-label",
                                attrs: { for: "ativo" },
                              },
                              [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(
                                      _vm.$t(
                                        "__.Crud.formulapadrao.edit_vue_html.ativo"
                                      )
                                    ) +
                                    "\n                            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("checkbox-component", {
                              attrs: {
                                id: "ativo",
                                name: "ativo",
                                disabled: _vm.somenteConsulta,
                              },
                              model: {
                                value: _vm.model.ativo,
                                callback: function ($$v) {
                                  _vm.$set(_vm.model, "ativo", $$v)
                                },
                                expression: "model.ativo",
                              },
                            }),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-sm-4" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("formaFarmaceuticaId"),
                            expression: "errors.first('formaFarmaceuticaId')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: {
                              for: "formaFarmaceuticaId",
                              title: _vm.$t("__.obrigatorio"),
                            },
                          },
                          [
                            _c("span", { staticStyle: { color: "red" } }, [
                              _vm._v("*"),
                            ]),
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Crud.formulapadrao.edit_vue_html.frmFarmaceu"
                                  )
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("combo-component", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required",
                              expression: "'required'",
                            },
                          ],
                          attrs: {
                            id: "formaFarmaceuticaId",
                            name: "formaFarmaceuticaId",
                            data: _vm.formasFarmaceuticaOptions,
                            searchable: true,
                            disabled: _vm.somenteConsulta,
                          },
                          model: {
                            value: _vm.model.formaFarmaceuticaId,
                            callback: function ($$v) {
                              _vm.$set(_vm.model, "formaFarmaceuticaId", $$v)
                            },
                            expression: "model.formaFarmaceuticaId",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-4" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("validadeDias"),
                            expression: "errors.first('validadeDias')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: {
                              for: "validadeDias",
                              title: _vm.$t("__.obrigatorio"),
                            },
                          },
                          [
                            _c("span", { staticStyle: { color: "red" } }, [
                              _vm._v("*"),
                            ]),
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Crud.formulapadrao.edit_vue_html.valDias"
                                  )
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("decimal-component", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required|maior_que_zero",
                              expression: "'required|maior_que_zero'",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            id: "validadeDias",
                            name: "validadeDias",
                            disabled: _vm.somenteConsulta,
                          },
                          model: {
                            value: _vm.model.validadeDias,
                            callback: function ($$v) {
                              _vm.$set(_vm.model, "validadeDias", $$v)
                            },
                            expression: "model.validadeDias",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _vm.model.tipoFormulaPadrao == 4 ||
                  _vm.model.tipoFormulaPadrao == 5
                    ? _c("div", { staticClass: "col-sm-2" }, [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: _vm.errors.first("edicaoPreVenda"),
                                expression: "errors.first('edicaoPreVenda')",
                              },
                            ],
                            staticClass: "form-group",
                          },
                          [
                            _c(
                              "label",
                              {
                                staticClass: "control-label",
                                attrs: { for: "edicaoPreVenda" },
                              },
                              [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(
                                      _vm.$t(
                                        "__.Crud.formulapadrao.edit_vue_html.edicaoPreVenda"
                                      )
                                    ) +
                                    "\n                            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("checkbox-component", {
                              attrs: {
                                title: "Permitir edição dos dados na pré-venda",
                                id: "edicaoPreVenda",
                                name: "edicaoPreVenda",
                                disabled: _vm.somenteConsulta,
                              },
                              model: {
                                value: _vm.model.edicaoPreVenda,
                                callback: function ($$v) {
                                  _vm.$set(_vm.model, "edicaoPreVenda", $$v)
                                },
                                expression: "model.edicaoPreVenda",
                              },
                            }),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.model.tipoFormulaPadrao == 5
                    ? _c("div", { staticClass: "col-sm-2" }, [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: _vm.errors.first("valorTabelado"),
                                expression: "errors.first('valorTabelado')",
                              },
                            ],
                            staticClass: "form-group",
                          },
                          [
                            _c(
                              "label",
                              {
                                staticClass: "control-label",
                                attrs: {
                                  for: "valorTabelado",
                                  title: _vm.$t("__.obrigatorio"),
                                },
                              },
                              [
                                _c("span", { staticStyle: { color: "red" } }, [
                                  _vm._v("*"),
                                ]),
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(
                                      _vm.$t(
                                        "__.Crud.formulapadrao.edit_vue_html.valorTabelado"
                                      )
                                    ) +
                                    "\n                            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("moeda-component", {
                              staticClass: "form-control",
                              attrs: {
                                id: "valorTabelado",
                                name: "valorTabelado",
                                disabled:
                                  _vm.isFormulaCapsula || _vm.somenteConsulta,
                              },
                              model: {
                                value: _vm.model.valorTabelado,
                                callback: function ($$v) {
                                  _vm.$set(_vm.model, "valorTabelado", $$v)
                                },
                                expression: "model.valorTabelado",
                              },
                            }),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _vm.isFormulaCapsula
                  ? _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-sm-6" }, [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: _vm.errors.first("produtoCapsulaId"),
                                expression: "errors.first('produtoCapsulaId')",
                              },
                            ],
                            staticClass: "form-group",
                          },
                          [
                            _c(
                              "label",
                              {
                                staticClass: "control-label",
                                attrs: {
                                  for: "produtoCapsulaId",
                                  title: _vm.$t("__.obrigatorio"),
                                },
                              },
                              [
                                _c("span", { staticStyle: { color: "red" } }, [
                                  _vm._v("*"),
                                ]),
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(
                                      _vm.$t(
                                        "__.Crud.formulapadrao.edit_vue_html.capsula"
                                      )
                                    ) +
                                    "\n                            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("combo-component", {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "required",
                                  expression: "'required'",
                                },
                              ],
                              attrs: {
                                id: "produtoCapsulaId",
                                name: "produtoCapsulaId",
                                data: _vm.produtosCapsulasOptions,
                                searchable: true,
                                disabled: _vm.somenteConsulta,
                              },
                              model: {
                                value: _vm.model.produtoCapsulaId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.model, "produtoCapsulaId", $$v)
                                },
                                expression: "model.produtoCapsulaId",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-sm-6" }, [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: _vm.errors.first("quantidadeCapsulas"),
                                expression:
                                  "errors.first('quantidadeCapsulas')",
                              },
                            ],
                            staticClass: "form-group",
                          },
                          [
                            _c(
                              "label",
                              {
                                staticClass: "control-label",
                                attrs: {
                                  for: "quantidadeCapsulas",
                                  title: _vm.$t("__.obrigatorio"),
                                },
                              },
                              [
                                _c("span", { staticStyle: { color: "red" } }, [
                                  _vm._v("*"),
                                ]),
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(
                                      _vm.$t(
                                        "__.Crud.formulapadrao.edit_vue_html.qtdCapsulas"
                                      )
                                    ) +
                                    "\n                            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("decimal-component", {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "required|maior_igual_a_zero",
                                  expression: "'required|maior_igual_a_zero'",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: {
                                id: "quantidadeCapsulas",
                                name: "quantidadeCapsulas",
                                disabled: _vm.somenteConsulta,
                              },
                              model: {
                                value: _vm.model.quantidade,
                                callback: function ($$v) {
                                  _vm.$set(_vm.model, "quantidade", $$v)
                                },
                                expression: "model.quantidade",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    {
                      class:
                        !_vm.isFormulaCapsula && !_vm.isformulaVolume
                          ? "col-sm-4"
                          : "col-sm-6",
                    },
                    [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: _vm.errors.first("volume"),
                              expression: "errors.first('volume')",
                            },
                          ],
                          staticClass: "form-group",
                        },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "control-label",
                              attrs: {
                                for: "volume",
                                title: _vm.$t("__.obrigatorio"),
                              },
                            },
                            [
                              _c("span", { staticStyle: { color: "red" } }, [
                                _vm._v("*"),
                              ]),
                              _vm._v(
                                "\n                                " +
                                  _vm._s(
                                    _vm.$t(
                                      "__.Crud.formulapadrao.edit_vue_html.volume"
                                    )
                                  ) +
                                  "\n                            "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("decimal-component", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required|maior_igual_a_zero",
                                expression: "'required|maior_igual_a_zero'",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: {
                              id: "volume",
                              name: "volume",
                              disabled:
                                _vm.isFormulaCapsula || _vm.somenteConsulta,
                            },
                            model: {
                              value: _vm.model.volume,
                              callback: function ($$v) {
                                _vm.$set(_vm.model, "volume", $$v)
                              },
                              expression: "model.volume",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _vm.model.formulaPadraoItens.length > 0
                    ? _c(
                        "div",
                        {
                          class:
                            !_vm.isFormulaCapsula && !_vm.isformulaVolume
                              ? "col-sm-4"
                              : "col-sm-6",
                        },
                        [
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "tooltip",
                                  rawName: "v-tooltip",
                                  value: _vm.errors.first("unidadeMedidaDesc"),
                                  expression:
                                    "errors.first('unidadeMedidaDesc')",
                                },
                              ],
                              staticClass: "form-group",
                            },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "control-label",
                                  attrs: {
                                    for: "unidadeMedidaDesc",
                                    title: _vm.$t("__.obrigatorio"),
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    { staticStyle: { color: "red" } },
                                    [_vm._v("*")]
                                  ),
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(
                                        _vm.$t(
                                          "__.Crud.formulapadrao.edit_vue_html.unMedida"
                                        )
                                      ) +
                                      "\n                            "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.unidadeMedidaDesc,
                                    expression: "unidadeMedidaDesc",
                                  },
                                ],
                                staticClass: "form-control",
                                attrs: {
                                  title:
                                    "Alteração permitida apenas quando não possui itens",
                                  autocomplete: "off",
                                  readonly: "",
                                  type: "text",
                                  id: "unidadeMedidaDesc",
                                  name: "unidadeMedidaDesc",
                                  disabled: _vm.somenteConsulta,
                                },
                                domProps: { value: _vm.unidadeMedidaDesc },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.unidadeMedidaDesc = $event.target.value
                                  },
                                },
                              }),
                            ]
                          ),
                        ]
                      )
                    : _c(
                        "div",
                        {
                          class:
                            !_vm.isFormulaCapsula && !_vm.isformulaVolume
                              ? "col-sm-4"
                              : "col-sm-6",
                        },
                        [
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "tooltip",
                                  rawName: "v-tooltip",
                                  value: _vm.errors.first("unidadeMedidaId"),
                                  expression: "errors.first('unidadeMedidaId')",
                                },
                              ],
                              staticClass: "form-group",
                            },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "control-label",
                                  attrs: {
                                    for: "unidadeMedidaId",
                                    title: _vm.$t("__.obrigatorio"),
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    { staticStyle: { color: "red" } },
                                    [_vm._v("*")]
                                  ),
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(
                                        _vm.$t(
                                          "__.Crud.formulapadrao.edit_vue_html.unMedida"
                                        )
                                      ) +
                                      "\n                            "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("combo-component", {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: "required",
                                    expression: "'required'",
                                  },
                                ],
                                attrs: {
                                  id: "unidadeMedidaId",
                                  name: "unidadeMedidaId",
                                  data: _vm.unidadesMedidaOptions,
                                  searchable: true,
                                  disabled: _vm.somenteConsulta,
                                },
                                model: {
                                  value: _vm.model.unidadeMedidaId,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.model, "unidadeMedidaId", $$v)
                                  },
                                  expression: "model.unidadeMedidaId",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                  _vm._v(" "),
                  !_vm.isFormulaCapsula && !_vm.isformulaVolume
                    ? _c("div", { staticClass: "col-sm-4" }, [
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c(
                              "label",
                              {
                                staticClass: "control-label",
                                attrs: { for: "quantidade" },
                              },
                              [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(
                                      _vm.$t(
                                        "__.Crud.formulapadrao.edit_vue_html.qtdPadraoPreVenda"
                                      )
                                    ) +
                                    "\n                            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("decimal-component", {
                              staticClass: "form-control",
                              attrs: {
                                id: "quantidade",
                                name: "quantidade",
                                disabled: _vm.somenteConsulta,
                              },
                              model: {
                                value: _vm.model.quantidade,
                                callback: function ($$v) {
                                  _vm.$set(_vm.model, "quantidade", $$v)
                                },
                                expression: "model.quantidade",
                              },
                            }),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-sm-12" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("observacao"),
                            expression: "errors.first('observacao')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "observacao" },
                          },
                          [
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Crud.formulapadrao.edit_vue_html.obs"
                                  )
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("textarea-component", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "max:1024",
                              expression: "'max:1024'",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            id: "observacao",
                            name: "observacao",
                            disabled: _vm.somenteConsulta,
                          },
                          model: {
                            value: _vm.model.observacao,
                            callback: function ($$v) {
                              _vm.$set(_vm.model, "observacao", $$v)
                            },
                            expression: "model.observacao",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-sm-12" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("procedimentoProducao"),
                            expression: "errors.first('procedimentoProducao')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "procedimentoProducao" },
                          },
                          [
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Crud.formulapadrao.edit_vue_html.proceProd"
                                  )
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("textarea-component", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "max:5000",
                              expression: "'max:5000'",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            id: "procedimentoProducao",
                            name: "procedimentoProducao",
                            disabled: _vm.somenteConsulta,
                          },
                          model: {
                            value: _vm.model.procedimentoProducao,
                            callback: function ($$v) {
                              _vm.$set(_vm.model, "procedimentoProducao", $$v)
                            },
                            expression: "model.procedimentoProducao",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _vm.model.tipoFormulaPadrao == 0
                  ? _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-sm-6" }, [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: _vm.errors.first("posologiaId"),
                                expression: "errors.first('posologiaId')",
                              },
                            ],
                            staticClass: "form-group",
                          },
                          [
                            _c(
                              "label",
                              {
                                staticClass: "control-label",
                                attrs: { for: "posologiaId" },
                              },
                              [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(
                                      _vm.$t(
                                        "__.Crud.formulapadrao.edit_vue_html.posologia"
                                      )
                                    ) +
                                    "\n                            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "combo-component",
                              {
                                attrs: {
                                  id: "posologiaId",
                                  name: "posologiaId",
                                  actionName: "posologias",
                                  addNew: true,
                                  customView: true,
                                  data: _vm.posologiaOptions,
                                  disabled: _vm.somenteConsulta,
                                  formAddNewName: "Nova Posologia",
                                  modalClass: "lm-modal",
                                  refresh: _vm.loadPosologia,
                                  useCloseModalCallback: true,
                                },
                                on: {
                                  addNewItemCombo: _vm.onAddNewPosologia,
                                  openComboNewItem: _vm.openComboNewPosologia,
                                },
                                model: {
                                  value: _vm.model.posologiaId,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.model, "posologiaId", $$v)
                                  },
                                  expression: "model.posologiaId",
                                },
                              },
                              [
                                _vm.showPosologia
                                  ? _c(
                                      "div",
                                      {
                                        attrs: { slot: "component" },
                                        slot: "component",
                                      },
                                      [
                                        _c("posologia-component", {
                                          ref: "posologiaComponent",
                                          attrs: { CalledByShortCut: true },
                                          on: {
                                            "save-ok": _vm.onPosologiaSaveOk,
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-sm-2" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("desmembrarFormula"),
                            expression: "errors.first('desmembrarFormula')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "desmembrarFormula" },
                          },
                          [
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Crud.formulapadrao.edit_vue_html.desmemFrml"
                                  )
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("checkbox-component", {
                          attrs: {
                            id: "desmembrarFormula",
                            name: "desmembrarFormula",
                            disabled: _vm.somenteConsulta,
                          },
                          model: {
                            value: _vm.model.desmembrarFormula,
                            callback: function ($$v) {
                              _vm.$set(_vm.model, "desmembrarFormula", $$v)
                            },
                            expression: "model.desmembrarFormula",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-4" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("kitEmbalagemId"),
                            expression: "errors.first('kitEmbalagemId')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "kitEmbalagemId" },
                          },
                          [
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Crud.formulapadrao.edit_vue_html.kitEmbalagem"
                                  )
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("combo-component", {
                          attrs: {
                            id: "kitEmbalagemId",
                            name: "kitEmbalagemId",
                            data: _vm.kitEmbalagemOptions,
                          },
                          model: {
                            value: _vm.model.kitEmbalagemId,
                            callback: function ($$v) {
                              _vm.$set(_vm.model, "kitEmbalagemId", $$v)
                            },
                            expression: "model.kitEmbalagemId",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-6" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("descricaoRotulo"),
                            expression: "errors.first('descricaoRotulo')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "descricaoRotulo" },
                          },
                          [
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Crud.formulapadrao.edit_vue_html.descrRotulo"
                                  )
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("textarea-component", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "max:1024",
                              expression: "'max:1024'",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            id: "descricaoRotulo",
                            name: "descricaoRotulo",
                            disabled: _vm.somenteConsulta,
                          },
                          model: {
                            value: _vm.model.descricaoRotulo,
                            callback: function ($$v) {
                              _vm.$set(_vm.model, "descricaoRotulo", $$v)
                            },
                            expression: "model.descricaoRotulo",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
            ]
          ),
          _vm._v(" "),
          !_vm.somenteConsulta
            ? _c("fieldset", [
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-sm-12" },
                    [
                      _c("formula-padrao-item-component", {
                        ref: "formulaPadraoItemComponent",
                        attrs: {
                          quantidadeManipulacao: _vm.model.quantidade,
                          volumeFormulaPadrao: _vm.model.volume,
                          unidadeMedidaFormulaPadraoId:
                            _vm.model.unidadeMedidaId,
                          formaFarmaceuticaId: +_vm.model.formaFarmaceuticaId,
                        },
                        on: {
                          "inclusao-item": _vm.onInclusaoItem,
                          "inclusao-kitEmbalagem": _vm.setKitEmbalagem,
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "fieldset-component",
            {
              attrs: {
                title: "Itens adicionados",
                collapsed: false,
                validate: false,
              },
            },
            [
              _c(
                "div",
                { attrs: { slot: "rows" }, slot: "rows" },
                [
                  _c("grid-component", {
                    attrs: {
                      data: _vm.model.formulaPadraoItens,
                      columns: _vm.gridColumns,
                      "show-table-head": false,
                      "show-table-foot": false,
                      "show-edit-item": false,
                      "show-sort-icons": false,
                      showActionColumn: !_vm.somenteConsulta,
                    },
                    on: { "remove-item": _vm.onRemoveItem },
                  }),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c("fieldset", [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-sm-12 newbtn" }, [
                _c("div", { staticClass: "form-group" }, [
                  !_vm.somenteConsulta
                    ? _c(
                        "button",
                        {
                          staticClass: "btn btn-success",
                          attrs: { type: "submit" },
                        },
                        [
                          _c("i", {
                            staticClass: "fa fa-save",
                            attrs: { "aria-hidden": "true" },
                          }),
                          _vm._v(
                            "\n                            " +
                              _vm._s(_vm.$t("__.Crud.salvar")) +
                              "\n                        "
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.somenteConsulta
                    ? _c(
                        "button",
                        {
                          staticClass: "btn btn-secondary",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              _vm.load()
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n                            " +
                              _vm._s(_vm.$t("__.Crud.limpar")) +
                              "\n                        "
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-secondary",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          _vm.cancel()
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n                            " +
                          _vm._s(_vm.$t("__.Crud.voltar")) +
                          "\n                        "
                      ),
                    ]
                  ),
                ]),
              ]),
            ]),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c("solicitarSenhaComponent", {
        ref: "solicitarSenhaComponent",
        attrs: { title: "Digite a senha" },
        on: { onResult: _vm.onResultSolicitarSenha },
      }),
      _vm._v(" "),
      _vm.validarVisibilidadeBotoes("replicar")
        ? [
            _c("replicar-selecao-franquia-component", {
              ref: "replicarSelecaoFranquiaComponent",
              on: { replicar: _vm.onConfirmReplicar },
            }),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }