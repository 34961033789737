import Vue from "vue";

import gridComponent from "@/components/child/grid/grid.vue";
import { GridColumn, GridColumnType } from "@/components/child/grid/gridColumn";
import PrescritorEditComponent from "@/components/parent/crud/prescritor/edit";
import prescritorComponent from "@/components/parent/crud/prescritor/edit.vue";
import ShortcutComponent from "@/components/parent/shortcut/shortcut";
import shortcutComponent from "@/components/parent/shortcut/shortcut.vue";
import { Component } from "@/decorators";
import PrescritorModel from "@/models/prescritorModel";
import PrescritorService from "@/services/prescritorService";
import Delay from "@/utils/common/delay";

@Component({
    components: {
        prescritorComponent,
        gridComponent,
        shortcutComponent,
    },
})
export default class PrescritoresDuplicadosViewComponent extends Vue {
    private prescritorService = new PrescritorService();

    private prescritorComponent: PrescritorEditComponent = null;
    private shortcutComponent: ShortcutComponent = null;
    private shortcutComponentPrescritor: ShortcutComponent = null;

    private gridData: PrescritorModel[] = [];
    private searchValue: string = null;

    get gridColumns(): Array<GridColumn> {
        return [
            new GridColumn("id", this.$t("__.ts.codigo"), GridColumnType.String),
            new GridColumn("nome", this.$t("__.ts.nome"), GridColumnType.String, false, false, "", "", true),
            new GridColumn(
                "numeroRegistro",
                this.$t("__.Crud.franquia.edit_vue_html.crmv"),
                GridColumnType.String,
                false,
                false,
                "",
                "",
                true,
            ),
        ];
    }

    public async show(prescritor: PrescritorModel) {
        while (!this.$refs.shortcutComponent) await Delay(100);
        this.shortcutComponent = this.$refs.shortcutComponent as ShortcutComponent;
        this.shortcutComponent.title = this.$t("__.Components.parent.duplicacoes.list_vue_html.unificar") as string;
        this.shortcutComponent.show();

        await this.loadPrescritoresDuplicados(prescritor);
    }

    public async loadPrescritoresDuplicados(prescritor: PrescritorModel) {
        this.gridData = await this.prescritorService
            .getPrescritoresDuplicadosByPrescritor(prescritor)
            .withLoading()
            .resolveWithJSON<PrescritorModel[]>();

        const campoDuplicado = this.getCampoDuplicado();
        this.searchValue = campoDuplicado ? prescritor[campoDuplicado] : "";
    }

    private getCampoDuplicado() {
        const campos = ["nome"];

        return campos.reduce((acc, p) => {
            const values = this.gridData.map(g => g[p]);
            return values.every(v => v && v == values[0]) ? p : acc;
        }, null);
    }

    private async onEditItem(prescritor: PrescritorModel) {
        this.prescritorComponent = this.$refs.prescritorComponent as PrescritorEditComponent;

        this.prescritorComponent.modelId = prescritor.id;
        this.prescritorComponent.load();

        this.shortcutComponentPrescritor = this.$refs.shortcutComponentPrescritor as ShortcutComponent;
        this.shortcutComponentPrescritor.title = this.$t(
            "__.Components.parent.duplicacoes.edit_vue_html.edicaoPrescritor",
        ) as string;
        this.shortcutComponentPrescritor.show();
    }

    private async onPrescritorSave() {
        if (await this.prescritorComponent.save()) {
            this.shortcutComponentPrescritor.hide();
        }
    }

    private async onPrescritorSaveOk(prescritor: PrescritorModel) {
        await this.loadPrescritoresDuplicados(this.gridData.find(p => p.id != prescritor.id));

        if (this.gridData.length < 2) {
            await this.$showSuccess(this.$t("__.ts.sucesso"), this.$t("__.ts.cadastroResolvidos"));

            this.shortcutComponent.hide();
        }
        this.$emit("onRefresh");
    }
}
