import Vue from "vue";
import { mapMutations, mapState } from "vuex";

import cnpjComponent from "@/components/child/form/cnpj.vue";
import comboComponent from "@/components/child/form/combo.vue";
import fieldsetComponent from "@/components/child/form/fieldset.vue";
import moedaComponent from "@/components/child/form/moeda.vue";
import { Component, Watch } from "@/decorators";
import EnumExtensions from "@/models/enum/extensions/enumExtensions";
import Ufs from "@/models/enum/ufs";
import NotaFiscalEletronicaEmissaoModel from "@/models/notaFiscalEletronicaEmissaoModel";
import PaginationModel from "@/models/paginationModel";
import TransportadoraModel from "@/models/transporte/transportadoraModel";
import CidadeService from "@/services/external/cidadeService";
import TransportadoraService from "@/services/transporte/transportadoraService";
import { AppState, Mutations } from "@/store/store";
import { editaCnpj } from "@/utils/common/formatText";

import transportadoraComponent from "../../parent/crud/transportadora/edit.vue";
import TransportadoraEditComponent from "../crud/transportadora/edit";

import "../crud/crud.scss";

@Component({
    components: {
        moedaComponent,
        cnpjComponent,
        fieldsetComponent,
        comboComponent,
        transportadoraComponent,
    },
    computed: mapState<AppState>({
        nota: state => state.nfeContext.nota,
        consulta: state => state.nfeContext.consulta,
    }),
    methods: mapMutations(["SET_NOTA"] as Mutations),
})
export default class RetencoesComponent extends Vue {
    // State computed props
    nota: NotaFiscalEletronicaEmissaoModel;
    consulta: boolean;
    SET_NOTA: (nota: NotaFiscalEletronicaEmissaoModel) => void;

    private transportadoraComponent: TransportadoraEditComponent = null;

    private modalidadeFreteOptions: Array<object> = [
        { text: "0-Por conta do emitente", value: 0 },
        { text: "1-Por conta do destinatário/remetente", value: 1 },
        { text: "2-Por conta de terceiros", value: 2 },
        { text: "3-Transporte Próprio por conta do Remetente", value: 3 },
        { text: "4-Transporte Próprio por conta do Destinatário", value: 4 },
        { text: "9-Sem frete", value: 9 },
    ];
    private transportadoraOptions: Array<object> = [];

    // @ts-ignore
    @Watch("nota.transportadoraId")
    private async onTransportadoraIdChanged(newId: number) {
        if (!newId || this.consulta) return;

        const data = await new TransportadoraService().get(newId).then(r => r.json() as Promise<TransportadoraModel>);
        this.nota.transportadoraId = data.id;
        this.nota.transportadoraNome = data.razaoSocial;
        this.nota.transportadoraCnpj = data.cnpj;
        this.nota.transportadoraEndereco = data.endereco;
        this.nota.transportadoraUF = EnumExtensions.getNameByValue(Ufs, data.estadoId);
        this.nota.transportadoraCidade = null;

        try {
            const cidades = await new CidadeService().get(data.estadoId);
            this.nota.transportadoraCidade = cidades.filter(cidade => cidade.value == data.cidadeId)[0].text;
        } catch {}

        if (data.inscricaoEstadual != "ISENTO") {
            this.nota.transportadoraIE = data.inscricaoEstadual;
        }
    }

    @Watch("nota")
    private onChangeNota() {
        if (!this.consulta) this.SET_NOTA(this.nota);
    }

    private async carregarTransportadoras() {
        const response = await new TransportadoraService().combo();
        const data = (await response.json()) as PaginationModel<TransportadoraModel>;
        this.transportadoraOptions = data.list.map(item => ({
            value: item.id,
            text: (item.cnpj != null && item.cnpj != "" ? editaCnpj(item.cnpj) + " - " : "") + item.nome,
        }));
    }

    private async openNewTransportadora() {
        this.transportadoraComponent = this.$refs.transportadoraComponent as TransportadoraEditComponent;
        this.transportadoraComponent.modelId = null;
        this.transportadoraComponent.load();
    }

    private async onAddTransportadora(closeModal) {
        if (await this.transportadoraComponent.save()) {
            closeModal();
        }
    }

    private async openComboEditTransportadora(id: number) {
        this.transportadoraComponent = this.$refs.transportadoraComponent as TransportadoraEditComponent;
        this.transportadoraComponent.modelId = id;
        this.transportadoraComponent.load();
    }

    private async onTransportadoraSaveOk() {
        await this.carregarTransportadoras();
    }

    private mounted() {
        this.transportadoraComponent = this.$refs.transportadoraComponent as TransportadoraEditComponent;
        this.carregarTransportadoras();
    }
}
