import nfetch from "../utils/events/nfetch";

import { getCookie } from "./utils/serviceUtils";

export default class EstoqueMinimoService {
    private controllerName = "EstoqueMinimo";

    public calcularEstoque(
        mesesConsiderar: number,
        coberturaEmDias: number,
        grupoProdutoId: number,
    ): Promise<Response> {
        return nfetch(
            `/${this.controllerName}/CalcularEstoque?mesesConsiderar=${mesesConsiderar}&coberturaEmDias=${coberturaEmDias}&grupoProdutoId=${grupoProdutoId}`,
            {
                method: "post",
                credentials: "same-origin",
                headers: {
                    "Content-Type": "application/json; charset=UTF-8",
                    "XSRF-TOKEN": getCookie("XSRF-TOKEN"),
                },
            },
        );
    }
}
