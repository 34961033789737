import { GridColumn, GridColumnType } from "@/components/child/grid/gridColumn";
import { Component } from "@/decorators";
import CestModel from "@/models/produto/cestModel";
import CestService from "@/services/produto/cestService";

import ListComponentBase from "../listComponentBase";

@Component
export default class CestListComponent extends ListComponentBase<CestModel> {
    get gridColumns(): Array<GridColumn> {
        return [
            new GridColumn("codigo", this.$t("__.ts.codigo"), GridColumnType.String),
            new GridColumn("descricao", this.$t("__.ts.descricao"), GridColumnType.String),
        ];
    }

    private beforeMount() {
        this.setProps(new CestService(), "cest", "descricao");
    }
}
