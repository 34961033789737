import BaseModel from "../base/baseModel";
import TipoEnvio from "../enum/tipoEnvio";
import TipoFiltroDataVenda from "../enum/TipoFiltroDataVenda";

import ConfiguracaoDotzFaixaModel from "./configuracaoDotzFaixaModel";

export default class ConfiguracaoDotzModel extends BaseModel {
    constructor() {
        super();
    }

    porcentagemMaximaDesconto = 0;
    franquiaId?: number = null;
    id: number = null;
    usuarioCriacaoId?: number = null;
    dataCriacao?: Date = null;
    usuarioModificacaoId?: number = null;
    dataModificacao?: Date = null;
    storeCode = "";
    utilizaFaixasPadroes = false;
    envioInstantaneo?: boolean = false;
    quantidadeMinimaVendas?: number = null;
    diaEnvioPontuacao = 0;
    documentoFuncionario?: string = null;
    faixas?: Array<ConfiguracaoDotzFaixaModel> = [];
    franquiaEnvioId? = null;
    tipoEnvio: TipoEnvio = TipoEnvio.Automatico;
    tipoFiltroDataVenda: TipoFiltroDataVenda = TipoFiltroDataVenda.DataAprovacao;

    organizarFaixas(faixas: ConfiguracaoDotzFaixaModel[]): number {
        if (faixas.length > 0) {
            const inicioProxFaixa = this.organizarFaixas(faixas.slice(1));
            faixas[0].fimFaixa = inicioProxFaixa ? inicioProxFaixa - 0.01 : inicioProxFaixa;
            return faixas[0].inicioFaixa;
        } else {
            return null;
        }
    }

    private ordemCrescente = (a, b) => a.inicioFaixa - b.inicioFaixa;

    adicionarFaixa(faixa: ConfiguracaoDotzFaixaModel) {
        const novaFaixa = new ConfiguracaoDotzFaixaModel();
        novaFaixa.updateFrom(faixa);
        this.faixas.push(novaFaixa);
        this.faixas.sort(this.ordemCrescente);
        this.organizarFaixas(this.faixas);
    }

    removerFaixa(faixa: ConfiguracaoDotzFaixaModel) {
        const index = this.faixas.indexOf(faixa);
        this.faixas.splice(index, 1);
        if (this.faixas.length > 0) {
            this.faixas.sort(this.ordemCrescente);
            this.organizarFaixas(this.faixas);
        }
    }
}
