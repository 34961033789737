import FormaFarmaceuticaModel from "@/models/formaFarmaceuticaModel";
import ManipulacaoOrdemItemModel from "@/models/manipulacaoOrdemItemModel";
import ManipulacaoOrdemModel from "@/models/manipulacaoOrdemModel";
import IndicesConversoesUnidadeMedidasService from "@/services/indicesConversoesUnidadeMedidaService";
import ManipulacaoOrdemService from "@/services/manipulacaoOrdemService";

export enum ManipulacaoContextActions {
    ADD_ITEM_MANIPULACAO = "ADD_ITEM_MANIPULACAO",
    ADD_NEW_CONTEXT = "ADD_NEW_CONTEXT",
    CALCULA_DOSE = "CALCULA_DOSE",
    CALCULAR_QSP = "CALCULAR_QSP",
    CALCULAR_QUANTIDADES_ITEM = "CALCULAR_QUANTIDADES_ITEM",
    GET_MANIPULACAO_CONTEXT = "GET_MANIPULACAO_CONTEXT",
    REMOVE_ALL_ITENS_BY_TYPE = "REMOVE_ALL_ITENS_BY_TYPE",
    REMOVE_ATIVOS_ASSOCIADOS = "REMOVE_ATIVOS_ASSOCIADOS",
    REMOVE_ATIVOS_ASSOCIADOS_FORMA_FARMACEUTICA = "REMOVE_ATIVOS_ASSOCIADOS_FORMA_FARMACEUTICA",
    REMOVE_ITEM_MANIPULACAO = "REMOVE_ITEM_MANIPULACAO",
    REPLICA_DOSE_ATIVOS = "REPLICA_DOSE_ATIVOS",
    RESET_MANIPULACAO = "RESET_MANIPULACAO",
    SET_CONCENTRACAO_MAXIMA = "SET_CONCENTRACAO_MAXIMA",
    SET_FORMA_FARMACEUTICA = "SET_FORMA_FARMACEUTICA",
    SET_IS_CAPSULA = "SET_IS_CAPSULA",
    SET_IS_CONSOLIDANDO = "SET_IS_CONSOLIDANDO",
    SET_IS_DESABILITADO = "SET_IS_DESABILITADO",
    SET_IS_ITEM_FROM_VENDA = "SET_IS_ITEM_FROM_VENDA",
    SET_IS_TRANSFERENCIA = "SET_IS_TRANSFERENCIA",
    SET_IS_VENDA_REPLICADA = "SET_IS_VENDA_REPLICADA",
    SET_MANIPULACAO = "SET_MANIPULACAO",
    SET_UPLOADING_EDITING_MODEL = "SET_UPLOADING_EDITING_MODEL",
    SET_UPLOADING_PREVENDA = "SET_UPLOADING_PREVENDA",
    UPDATE_FORMULA_BASE = "UPDATE_FORMULA_BASE",
    UPDATE_ITENS_KIT_EMBALAGEM = "UPDATE_ITENS_KIT_EMBALAGEM",
    UPDATE_KIT_EMBALAGEM_ID = "UPDATE_KIT_EMBALAGEM_ID",
    UPDATE_MANIPULACAO = "UPDATE_MANIPULACAO",
    UPDATE_MANIPULACAO_ITENS = "UPDATE_MANIPULACAO_ITENS",
}

export enum ManipulacaoContextMutations {
    ADD_ITEM_MANIPULACAO = "ADD_ITEM_MANIPULACAO",
    ADD_ITENS_DESMEMBRADOS = "ADD_ITENS_DESMEMBRADOS",
    ADD_NEW_CONTEXT = "ADD_NEW_CONTEXT",
    CALCULAR_QSP = "CALCULAR_QSP",
    REMOVE_ALL_ITENS_BY_TYPE = "REMOVE_ALL_ITENS_BY_TYPE",
    REMOVE_ATIVOS_ASSOCIADOS = "REMOVE_ATIVOS_ASSOCIADOS",
    REMOVE_ATIVOS_ASSOCIADOS_FORMA_FARMACEUTICA = "REMOVE_ATIVOS_ASSOCIADOS_FORMA_FARMACEUTICA",
    REMOVE_EMBALAGENS_BY_KIT_ID = "REMOVE_EMBALAGENS_BY_KIT_ID",
    REMOVE_ITEM_MANIPULACAO = "REMOVE_ITEM_MANIPULACAO",
    REPLICA_DOSE_ATIVOS = "REPLICA_DOSE_ATIVOS",
    RESET_MANIPULACAO = "RESET_MANIPULACAO",
    SET_CONCENTRACAO_MAXIMA = "SET_CONCENTRACAO_MAXIMA",
    SET_FORMA_FARMACEUTICA = "SET_FORMA_FARMACEUTICA",
    SET_IS_CAPSULA = "SET_IS_CAPSULA",
    SET_IS_CONSOLIDANDO = "SET_IS_CONSOLIDANDO",
    SET_IS_DESABILITADO = "SET_IS_DESABILITADO",
    SET_IS_ITEM_FROM_VENDA = "SET_IS_ITEM_FROM_VENDA",
    SET_IS_TRANSFERENCIA = "SET_IS_TRANSFERENCIA",
    SET_IS_VENDA_REPLICADA = "SET_IS_VENDA_REPLICADA",
    SET_ITEM_DATA = "SET_ITEM_DATA",
    SET_UPLOADING_EDITING_MODEL = "SET_UPLOADING_EDITING_MODEL",
    SET_UPLOADING_PREVENDA = "SET_UPLOADING_PREVENDA",
    UPDATE_ITENS_KIT_EMBALAGEM = "UPDATE_ITENS_KIT_EMBALAGEM",
    UPDATE_MANIPULACAO = "UPDATE_MANIPULACAO",
    UPDATE_MANIPULACAO_KIT_EMBALAGEM_ID = "UPDATE_MANIPULACAO_KIT_EMBALAGEM_ID",
}

export enum ItemTableType {
    ATIVO = 0,
    EMBALAGEM = 1,
    CAPSULA = 2,
}

export enum ItemTypeOrigem {
    OUTRO = 0,
    ITEM_PADRAO_FORMAFARMACEUTICA = 1,
}

export type ActionItemType = {
    type: ItemTableType;
    item?: ManipulacaoOrdemItemModel;
    index?: number;
    origem?: ItemTypeOrigem;
    noAddIfExists?: boolean;
};

export type UpdateItemType = {
    index: number;
    type: ItemTableType;
    item: ManipulacaoOrdemItemModel;
    force: boolean;
};

export type UpdateItemDesmembradoType = {
    desmembrados: ManipulacaoOrdemItemModel[];
    manipulacaoOrdemItemProdutoPaiId: number;
};

export class ManipulacaoContextState {
    ativos: ManipulacaoOrdemItemModel[] = [];
    capsulas: ManipulacaoOrdemItemModel[] = [];
    embalagens: ManipulacaoOrdemItemModel[] = [];
    desmembrados: ManipulacaoOrdemItemModel[] = [];
    uploadingEditingModel = false;
    uplodingPrevenda = false;
    formaFarmaceutica = new FormaFarmaceuticaModel();
    indicesConversoesUnidadeMedidasService = new IndicesConversoesUnidadeMedidasService();
    manipulacaoOrdemService = new ManipulacaoOrdemService();
    isCapsula = false;
    isConsolidando = false;
    isDesabilitado = false;
    isItemFromVenda = false;
    isTransferencia = false;
    isVendaReplicada = false;
    manipulacao = new ManipulacaoOrdemModel();
    temProdutoListacontrolado = false;
    concentracaoMaxima = 0;
    atualizaDadosLote = false;
}

export type ManipulacaoContextUUID<T> = {
    uuid: string;
    data: T;
};
