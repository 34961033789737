import Vue from "vue";
import { mapGetters, mapMutations, mapState } from "vuex";

import comboComponent from "@/components/child/form/combo.vue";
import gridComponent from "@/components/child/grid/grid.vue";
import { GridAction } from "@/components/child/grid/gridAction";
import { GridColors, GridColumn, GridColumnType } from "@/components/child/grid/gridColumn";
import { Component } from "@/decorators";
import PaginationPackModel from "@/models/auxiliar/paginationPackModel";
import PreLoadPackModel from "@/models/auxiliar/preLoadPackModel";
import EnumExtensions from "@/models/enum/extensions/enumExtensions";
import tipoFormula from "@/models/enum/tiposFormulaPadrao";
import TiposFormulaPadrao from "@/models/enum/tiposFormulaPadrao";
import FormulaPadraoModel, { FormulaPadraoListParameters } from "@/models/formulaPadraoModel";
import PaginationModel from "@/models/paginationModel";
import UsuarioModel from "@/models/usuarioModel";
import FormulaPadraoService from "@/services/formulaPadraoService";
import { AppState, Getters } from "@/store/store";
import Delay from "@/utils/common/delay";

import ShortcutComponent from "../../shortcut/shortcut";
import shortcutComponent from "../../shortcut/shortcut.vue";
import ReplicarSelecaoFranquiaComponent from "../replicarSelecaoFranquia/edit";
import replicarSelecaoFranquiaComponent from "../replicarSelecaoFranquia/edit.vue";

import verComponent from "./ver.vue";

import "../crud.scss";

@Component({
    components: {
        gridComponent,
        comboComponent,
        shortcutComponent,
        verComponent,
        replicarSelecaoFranquiaComponent,
    },
    computed: {
        ...mapState<AppState>({
            loadingPack: state => state.paginationStatus.loading,
            paginationParams: state => state.paginationStatus.paginationParams,
            usuarioLogado: state => state.session.usuarioLogado,
            preLoadPack: state => state.preLoad.preLoadList,
        }),
        ...mapGetters(["HAS_PERMISSAO_PERFIL", "VALIDAR_PERMISSAO_USUARIO"] as Getters),
    },
    methods: mapMutations(["LOAD_LIST", "SET_PARAMS", "GET_PARAMS"]),
})
export default class FormulaPadraoListComponent extends Vue {
    // State computed props
    loadingPack: boolean;
    paginationParams: PaginationPackModel;
    usuarioLogado: UsuarioModel;
    SET_PARAMS: (obj: object) => void;
    GET_PARAMS: (path: string) => Promise<void>;
    HAS_PERMISSAO_PERFIL: (isRede: boolean, isFranqueador: boolean, isSuporte: boolean) => Promise<boolean>;
    VALIDAR_PERMISSAO_USUARIO: (telaDescricao: string, acaoController: string) => boolean;

    private service = new FormulaPadraoService();
    private replicarSelecaoFranquiaComponent: ReplicarSelecaoFranquiaComponent = null;
    private shortcutComponent: ShortcutComponent = null;
    private isAdmin = false;
    private loadedPagination = false;

    startFilterKey = "";
    gridFilterKey = "";
    gridSortKey = "descricao";
    gridSortOrder = "asc";
    gridData: Array<FormulaPadraoModel> = [];
    gridSelectedValues: Array<FormulaPadraoModel> = [];

    preLoadPack: PreLoadPackModel;

    get gridColumns(): Array<GridColumn> {
        return [
            new GridColumn("descricao", "Descri\u00e7\u00e3o", GridColumnType.String),
            new GridColumn("produtoDescricao", "Produto Final", GridColumnType.String),
            new GridColumn("tipoFormulaPadraoDescricao", "Tipo Fórmula", GridColumnType.String),
        ];
    }

    tipoFormulaPadrao: number = null;
    tiposFormulaPadraoOptions: Array<Object> = [
        { text: "Acabado", value: TiposFormulaPadrao.Acabado },
        { text: "Base", value: TiposFormulaPadrao.Base },
        { text: "Diluição", value: TiposFormulaPadrao.Diluicao },
        { text: "Excipiente", value: TiposFormulaPadrao.Excipiente },
        { text: "Pré-Venda", value: TiposFormulaPadrao.PreVenda },
        { text: "Pré-Venda Tabelada", value: TiposFormulaPadrao.PreVendaTabelada },
    ];

    extraActions: Array<object> = [];
    gridExtraActionsVer: Array<GridAction> = [];
    modelVer: FormulaPadraoModel = null;

    isHidden = false;
    radioFilter = 1;
    filtrarPorNomeAtivo = false;
    filtro: Array<Object> = [];

    pageIndex = 1;
    pageSize = 20;
    total = 0;
    id = 0;

    showVer = false;

    private async load() {
        const customParameters: FormulaPadraoListParameters = {
            filtroAtivo: this.filtro[0] ? <number>this.filtro[0] : 1,
            tipoFormula: this.filtro[1] || <number>this.filtro[1] == 0 ? <number>this.filtro[1] : null,
            filtroNomeAtivo: this.filtrarPorNomeAtivo,
        };

        try {
            const data = await this.service
                .listWithFilter(
                    this.gridFilterKey,
                    this.gridSortKey,
                    this.gridSortOrder,
                    this.pageIndex,
                    this.pageSize,
                    customParameters,
                )
                .withLoading()
                .resolveWithJSON<PaginationModel<FormulaPadraoModel>>();

            if (data.total > 0) {
                this.SET_PARAMS({
                    routePath: this.$route.path,
                    filterKey: this.gridFilterKey,
                    sortKey: this.gridSortKey,
                    sortOrder: this.gridSortOrder,
                    pageIndex: this.pageIndex,
                    pageSize: this.pageSize,
                    filtros: this.filtro,
                });
            }

            this.gridData = data.list;
            this.total = data.total;
            this.isHidden = false;
            this.radioFilter = this.filtro[0] ? <number>this.filtro[0] : 1;
            this.tipoFormulaPadrao = this.filtro[1] || <number>this.filtro[1] == 0 ? <number>this.filtro[1] : null;
            this.gridExtraActionsVer.splice(0);
            this.extraActions.splice(0);

            for (let i = 0; i < this.gridData.length; i++) {
                this.gridExtraActionsVer.push(
                    new GridAction(
                        "ver",
                        this.$t("__.ts.verFrmlPadrao").toString(),
                        "fa fa-eye",
                        GridColors.BLUE,
                        true,
                    ),
                );
            }

            this.extraActions.push(this.gridExtraActionsVer);
        } catch {}
    }

    private onExtraAction(name: string, model: FormulaPadraoModel) {
        if (name.trim() == "ver") {
            this.onVer(model);
        }
    }

    private onVer(formula: FormulaPadraoModel) {
        this.shortcutComponent.title = "Fórmula Padrão";
        this.service
            .get(formula.id)
            .then(response => {
                return response.json() as Promise<FormulaPadraoModel>;
            })
            .then(data => {
                this.modelVer = data;
                this.id = this.modelVer.id;
                this.modelVer.formaFarmaceutica = this.preLoadPack.formasFarmaceuticas.find(
                    p => p.id == this.modelVer.formaFarmaceuticaId,
                );
                this.modelVer.tipoFormulaPadraoDescricao = EnumExtensions.getNameByValue(
                    tipoFormula,
                    data.tipoFormulaPadrao,
                );
                this.showVer = true;
            })
            .catch(() => {});
        this.shortcutComponent.show();
    }

    private onBtnEditItem() {
        this.$router.push("/formulapadrao-edicao/" + this.id);
    }

    private onEditItem(model: FormulaPadraoModel) {
        this.$router.push("/formulapadrao-edicao/" + model.id);
    }

    private async onRemoveItem(model: FormulaPadraoModel) {
        const response = await this.$showExclusaoQuestion(`${this.$t("__.ts.desejaExcl")}${model.descricao}?`);

        if (response) {
            try {
                const sucesso = await this.service.delete(model.id).withLoading().resolveWithoutJSON();
                if (sucesso) {
                    this["LOAD_LIST"]({ loadFormulaPadraoDesmembrados: true });
                    this.load();
                    this.$showExclusao();
                }
            } catch {}
        }
    }

    private onCreateItem() {
        this.$router.push("/formulapadrao-edicao");
    }

    private onChangeFilterKey(filterKey: string) {
        this.gridFilterKey = filterKey;
        this.load();
    }

    private onChangeSort(sortKey: string, sortOrder: string) {
        this.gridSortKey = sortKey;
        this.gridSortOrder = sortOrder;
        this.load();
    }

    private onChangePage(pageIndex: number) {
        this.pageIndex = pageIndex;
        this.load();
    }

    private onSelectedValuesChanged(values: Array<FormulaPadraoModel>) {
        this.gridSelectedValues = values;
    }

    gerarManipulacaoOrdem() {
        this.$router.push(
            "/formulapadrao-manipulacao/" +
                this.gridSelectedValues.map(function (model) {
                    return model.id;
                }),
        );
    }

    private async loadPagination() {
        await this.GET_PARAMS(this.$route.path);
        while (this.loadingPack) await Delay(50);

        if (this.paginationParams != null) {
            this.gridFilterKey = this.paginationParams.filterKey;
            this.startFilterKey = this.gridFilterKey;
            this.gridSortKey = this.paginationParams.sortKey;
            this.gridSortOrder = this.paginationParams.sortOrder;
            this.pageIndex = this.paginationParams.pageIndex;
            this.pageSize = this.paginationParams.pageSize;

            if (this.paginationParams.filtros != null) {
                this.filtro = this.paginationParams.filtros;
                if (this.filtro.length >= 0) {
                    if (this.filtro[0] != "") {
                        this.radioFilter = <number>this.filtro[0];
                    }
                    if (this.filtro.length >= 1) {
                        if (this.filtro[1] != null || <number>this.filtro[1] == 0) {
                            this.tipoFormulaPadrao = <number>this.filtro[1];
                        }
                    }
                }
            }
        }
        this.loadedPagination = true;
    }

    private replicarTodos() {
        this.replicarSelecaoFranquiaComponent = this.$refs
            .replicarSelecaoFranquiaComponent as ReplicarSelecaoFranquiaComponent;
        this.replicarSelecaoFranquiaComponent.openFranquias();
    }

    private async onConfirmReplicar(franquiasIds: Array<number>) {
        const idsFranquias = franquiasIds.toString();

        try {
            const response = await this.service.replicarTodos(1, idsFranquias).withLoading().resolveWithResponse();
            if (response.ok) {
                await this.$showSuccess(this.$t("__.ts.replicAtualiz"), this.$t("__.ts.regisSalvosSucess"));
                this["LOAD_LIST"]({ loadFormulaPadraoDesmembrados: true });
                this["LOAD_LIST"]({ loadFormaFarmaceutica: true });
            }
        } catch {}
    }

    private onChangePageSize(newPageSize) {
        this.pageIndex = 1;
        this.pageSize = newPageSize;
        this.load();
    }

    private redefinirValorProduto() {
        if (this.gridSelectedValues.length > 0) {
            this.$router.push(
                "/formulapadrao-recalculo-valor-produto/" +
                    this.gridSelectedValues.map(function (model) {
                        return model.id;
                    }),
            );
        } else {
            this.$showWarning(this.$t("__.ts.recalcValores"), this.$t("__.ts.nenhumRegisSelec"));
        }
    }

    private onBuscarAtivoChange() {
        this.filtrarPorNomeAtivo = !this.filtrarPorNomeAtivo;
    }

    private getFormValues(listaFiltro) {
        this.filtro = [];

        this.filtro.push(listaFiltro.target.elements.optradio.value);

        if (this.tipoFormulaPadrao >= 0) {
            this.filtro.push(this.tipoFormulaPadrao);
        }

        this.load();
    }

    private beforeMount() {
        this.loadPagination();
    }

    private async mounted() {
        this.shortcutComponent = this.$refs.shortcutComponent as ShortcutComponent;

        this.isAdmin = await this.HAS_PERMISSAO_PERFIL(true, false, false);

        this.load();
    }
}
