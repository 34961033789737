import { Component } from "@/decorators";
import EspecieAnimalModel from "@/models/paciente/especieAnimalModel";
import EspecieAnimalService from "@/services/paciente/especieAnimalService";

import EditComponentBase from "../editComponentBase";

import "../crud.scss";

@Component
export default class EspecieAnimalEditComponent extends EditComponentBase<EspecieAnimalModel> {
    private async beforeMount() {
        await this.setProps(new EspecieAnimalService(), "especie_de_animal", new EspecieAnimalModel(), false, true);
    }
}
