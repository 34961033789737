var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("input", {
    directives: [{ name: "mask-decimal", rawName: "v-mask-decimal" }],
    attrs: { autocomplete: "off", type: "text", id: _vm.id, name: _vm.name },
    domProps: { value: _vm.value },
    on: {
      blur: function ($event) {
        _vm.onBlur($event.target.value)
      },
      focus: function ($event) {
        _vm.onFocus($event.target.value)
      },
      keyup: function ($event) {
        _vm.onKeyUp($event)
      },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }