import Vue from "vue";
import { mapState } from "vuex";

import actionBarComponent from "@/components/child/actionBar/actionBar.vue";
import { Component } from "@/decorators";
import UsuarioModel from "@/models/usuarioModel";
import { AppState } from "@/store/store";

@Component({
    components: {
        actionBarComponent,
    },
    computed: mapState({
        usuarioLogado: (state: AppState) => state.session.usuarioLogado,
    }),
})
export default class RelatorioStimulsoftComponent extends Vue {
    // State computed props
    usuarioLogado: UsuarioModel;

    private mounted() {
        document
            .querySelector("iframe")
            .setAttribute(
                "src",
                `${process.env.VUE_APP_ROOT_API}/${this.$route.params.id}?franquiaId=${this.usuarioLogado.franquiaId}&usuarioId=${this.usuarioLogado.id}`,
            );
    }
}
