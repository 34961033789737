import nfetch from "@/utils/events/nfetch";

import ProdutoLoteFracionamentoModel from "../models/produtoLoteFracionamentoModel";

import { IService } from "./base/iService";

export default class ProdutoLoteFracionamentoService extends IService<ProdutoLoteFracionamentoModel> {
    constructor() {
        super("ProdutoLoteFracionamento");
    }

    public getFracionamentoByLote(id: number): Promise<Response> {
        return nfetch(`/${this.controllerName}/GetFracionamentoByLote?id=${id}`, {
            credentials: "same-origin",
        });
    }
}
