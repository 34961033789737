interface OrigemMercadoria {
    codigo: number;
    descricao: string;
}

export default class OrigemMercadoriaService {
    public get(): OrigemMercadoria[] {
        return [
            { "codigo": 0, "descricao": "Nacional - Exceto as indicadas nos código 3, 4, 5 e 8" },
            { "codigo": 1, "descricao": "Estrangeira - Importação direta, exceto a indicada no código 6" },
            { "codigo": 2, "descricao": "Estrangeira - Adquirida no mercado interno, exceto a indicada no código 7" },
            { "codigo": 3, "descricao": "Nacional - Mercadoria ou bem com Conteúdo de Importação superior a 40% e inferior ou igual a 70%" },
            { "codigo": 4, "descricao": "Nacional - Cuja produção tenha sido feita em conformidade com os processos produtivos básicos de que tratam as legislações citadas nos Ajustes" },
            { "codigo": 5, "descricao": "Nacional, mercadoria ou bem com Conteúdo de Importação inferior ou igual a 40%" },
            { "codigo": 6, "descricao": "Estrangeira - Importação direta, sem similar nacional, constante em lista da CAMEX e gás natural" },
            { "codigo": 7, "descricao": "Estrangeira - Adquirida no mercado interno, sem similar nacional, constante em lista da CAMEX e gás natural" },
            { "codigo": 8, "descricao": "Nacional - Mercadoria ou bem com Conteúdo de Importação superior a 70%" },
        ];
    }
}