import BaseModel from "./base/baseModel";

export default class CapsulaModel extends BaseModel {
    id?: number = null;
    franquiaId?: number = null;
    descricao: string = null;
    numero: string = null;
    volumeInterno = 0;
    volumeTotal = 0;
    peso = 0;
    ativo = true;
}
