import BaseModel from "./../base/baseModel";

export default class ContaCorrenteModel extends BaseModel {
    id?: number = null;
    descricao: string = null;
    ativo = false;
    interna = false;
    bancoId?: number = null;
    agencia: string = null;
    conta: string = null;
}
