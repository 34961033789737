var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "slide" } }, [
    _vm.isVisible
      ? _c("nav", { staticClass: "nav-menu" }, [
          _c("div", [
            _c("div", { staticClass: "nav-menu-level-1" }, [
              _c(
                "ul",
                { staticClass: "nav" },
                _vm._l(_vm.mainMenuItens, function (menuItemLevel1) {
                  return _c(
                    "li",
                    {
                      key: menuItemLevel1.id,
                      class: {
                        active: _vm.selectedMainMenuItem == menuItemLevel1.name,
                      },
                      on: {
                        click: function ($event) {
                          _vm.showMenuLevelOneItens(menuItemLevel1.name)
                        },
                      },
                    },
                    [
                      _c("i", {
                        staticClass: "fa fa-fw",
                        class: menuItemLevel1.classIcon,
                        attrs: { "aria-hidden": "true" },
                      }),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.selectedMainMenuItem == "",
                              expression: "selectedMainMenuItem == ''",
                            },
                          ],
                          staticClass: "nav-menu-level-1-title",
                        },
                        [
                          _vm._v(
                            "\n                            " +
                              _vm._s(
                                _vm.$t("__.Components.parent.navmenu")[
                                  menuItemLevel1.title
                                ]
                              ) +
                              "\n                        "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.selectedMainMenuItem == "",
                              expression: "selectedMainMenuItem == ''",
                            },
                          ],
                          staticClass: "nav-menu-level-1-desc",
                        },
                        [
                          _vm._v(
                            "\n                            " +
                              _vm._s(menuItemLevel1.description) +
                              "\n                        "
                          ),
                        ]
                      ),
                    ]
                  )
                })
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.selectedMainMenuItem != "",
                    expression: "selectedMainMenuItem != ''",
                  },
                ],
                staticClass: "nav-menu-level-2",
              },
              _vm._l(_vm.mainMenuItens, function (menuItemLevel1) {
                return _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.selectedMainMenuItem == menuItemLevel1.name,
                        expression:
                          "selectedMainMenuItem == menuItemLevel1.name",
                      },
                    ],
                    key: menuItemLevel1.id,
                  },
                  [
                    _c(
                      "ul",
                      { staticClass: "nav" },
                      [
                        _c("li", [
                          _c("p", { staticClass: "nav-menu-level-2-title" }, [
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t("__.Components.parent.navmenu")[
                                    menuItemLevel1.title
                                  ]
                                ) +
                                "\n                            "
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _vm._l(menuItemLevel1.itens, function (menuItemLevel2) {
                          return [
                            menuItemLevel2.itens.length == 0
                              ? [
                                  _c(
                                    "li",
                                    {
                                      key: menuItemLevel2.id,
                                      class: {
                                        active:
                                          _vm.selectedSubMenuItem ==
                                          menuItemLevel2.name,
                                      },
                                      on: {
                                        click: function ($event) {
                                          _vm.selectMenu(menuItemLevel2.name)
                                        },
                                      },
                                    },
                                    [
                                      menuItemLevel2.listRoute
                                        ? _c(
                                            "router-link",
                                            {
                                              attrs: {
                                                to: menuItemLevel2.listRoute,
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "nav-menu-level-2-desc",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                            " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "__.Components.parent.navmenu"
                                                        )[menuItemLevel2.title]
                                                      ) +
                                                      "\n                                        "
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              menuItemLevel2.editRoute != null
                                                ? [
                                                    _c(
                                                      "router-link",
                                                      {
                                                        attrs: {
                                                          to: menuItemLevel2.editRoute,
                                                        },
                                                      },
                                                      [
                                                        _c("i", {
                                                          staticClass:
                                                            "fa fa-plus-circle",
                                                          attrs: {
                                                            "aria-hidden":
                                                              "true",
                                                          },
                                                        }),
                                                      ]
                                                    ),
                                                  ]
                                                : _vm._e(),
                                            ],
                                            2
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      menuItemLevel2.link
                                        ? _c(
                                            "a",
                                            {
                                              attrs: {
                                                target: "_blank",
                                                href: menuItemLevel2.link,
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "nav-menu-level-2-desc",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                            " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "__.Components.parent.navmenu"
                                                        )[menuItemLevel2.title]
                                                      ) +
                                                      "\n                                        "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ]
                              : _vm._e(),
                            _vm._v(" "),
                            menuItemLevel2.itens.length > 0
                              ? [
                                  _c(
                                    "li",
                                    {
                                      key: menuItemLevel2.id,
                                      staticClass: "nav-menu-level-3-title",
                                      on: {
                                        click: function ($event) {
                                          _vm.showGroupedMenuItens(
                                            menuItemLevel2.name
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "__.Components.parent.navmenu"
                                            )[menuItemLevel2.title]
                                          )
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("i", {
                                        staticClass: "fa fa-ellipsis-h",
                                        attrs: { "aria-hidden": "true" },
                                      }),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "ul",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value:
                                            _vm.groupedSubMenuItens[
                                              menuItemLevel2.name
                                            ],
                                          expression:
                                            "groupedSubMenuItens[menuItemLevel2.name]",
                                        },
                                      ],
                                      key: menuItemLevel2.id + "submenu",
                                      staticClass: "nav nav-menu-level-3",
                                    },
                                    _vm._l(
                                      menuItemLevel2.itens,
                                      function (menuItemLevel3) {
                                        return _c(
                                          "li",
                                          {
                                            key: menuItemLevel3.id,
                                            class: {
                                              active:
                                                _vm.selectedSubMenuItem ==
                                                menuItemLevel3.name,
                                            },
                                            on: {
                                              click: function ($event) {
                                                _vm.selectMenu(
                                                  menuItemLevel3.name
                                                )
                                              },
                                            },
                                          },
                                          [
                                            menuItemLevel3.listRoute
                                              ? _c(
                                                  "router-link",
                                                  {
                                                    attrs: {
                                                      to: menuItemLevel3.listRoute,
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "nav-menu-level-3-desc",
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                                                " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "__.Components.parent.navmenu"
                                                              )[
                                                                menuItemLevel3
                                                                  .title
                                                              ]
                                                            ) +
                                                            "\n                                            "
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    menuItemLevel3.editRoute !=
                                                    null
                                                      ? [
                                                          _c(
                                                            "router-link",
                                                            {
                                                              attrs: {
                                                                to: menuItemLevel3.editRoute,
                                                              },
                                                            },
                                                            [
                                                              _c("i", {
                                                                staticClass:
                                                                  "fa fa-plus-circle",
                                                                attrs: {
                                                                  "aria-hidden":
                                                                    "true",
                                                                },
                                                              }),
                                                            ]
                                                          ),
                                                        ]
                                                      : _vm._e(),
                                                  ],
                                                  2
                                                )
                                              : _vm._e(),
                                            _vm._v(" "),
                                            menuItemLevel3.link
                                              ? _c(
                                                  "a",
                                                  {
                                                    attrs: {
                                                      target: "_blank",
                                                      href: menuItemLevel3.link,
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "nav-menu-level-2-desc",
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                                                " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "__.Components.parent.navmenu"
                                                              )[
                                                                menuItemLevel3
                                                                  .title
                                                              ]
                                                            ) +
                                                            "\n                                            "
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        )
                                      }
                                    )
                                  ),
                                ]
                              : _vm._e(),
                          ]
                        }),
                      ],
                      2
                    ),
                  ]
                )
              })
            ),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }