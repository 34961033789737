import FormaFarmaceuticaModel from "../models/formaFarmaceuticaModel";
import arithmeticHelper from "../utils/common/arithmeticHelper";
import nfetch from "../utils/events/nfetch";

import { IService } from "./base/iService";

export default class FormaFarmaceuticaService extends IService<FormaFarmaceuticaModel> {
    constructor() {
        super("FormaFarmaceutica");
    }

    public replicar(id: number, idFranquias: string, atualizarValores: boolean): Promise<Response> {
        return nfetch(
            `/${this.controllerName}/Replicar?id=${id}&idFranquias=${idFranquias}&atualizarValores=${atualizarValores}`,
            {
                method: "post",
                credentials: "same-origin",
                headers: {
                    "Content-Type": "application/json; charset=UTF-8",
                    "XSRF-TOKEN": this.getCookie("XSRF-TOKEN"),
                },
            },
        );
    }

    public getByParent(parentId: number, franquiaId: number): Promise<Response> {
        //@ts-ignore
        return nfetch(`/${this.controllerName}/GetByParentId?parentId=${parentId}&franquiaId=${franquiaId}`, {
            credentials: "same-origin",
        })
            .then((response: Response) => {
                if (response.ok) {
                    return response;
                } else {
                    return this.mensagemErro(response, "Get");
                }
            })
            .catch(() => {});
    }

    public getValorFormaFarmaceutica(
        valorSoma: number,
        quantidade: number,
        fatorMultiplicadorAdicional: number,
        fatorDecaimento: number,
        fatorCompensacao: number,
    ) {
        let fatorCurva = 0;
        fatorMultiplicadorAdicional = fatorMultiplicadorAdicional ? fatorMultiplicadorAdicional : 0;
        fatorDecaimento = fatorDecaimento ? fatorDecaimento : 0;
        fatorCompensacao = fatorCompensacao ? fatorCompensacao : 0;
        const fatorMultMinimo = fatorMultiplicadorAdicional * quantidade;
        if (fatorDecaimento > 0 && fatorCompensacao > 0) {
            //verifica o ponto limite do decaimento
            let limitQuantidadeAd = arithmeticHelper.round(fatorCompensacao / fatorDecaimento, 0);
            if (limitQuantidadeAd > quantidade) {
                limitQuantidadeAd = quantidade;
            }

            //soma das compensacoes - soma dos decaimentos
            const fatorAdicional = fatorCompensacao * limitQuantidadeAd;
            //formula de PA
            const fatorDecaimentoAdicional = (fatorDecaimento * (1 + limitQuantidadeAd) * limitQuantidadeAd) / 2;
            fatorCurva = fatorAdicional - fatorDecaimentoAdicional;
        }
        return arithmeticHelper.round(valorSoma + (fatorMultMinimo + fatorCurva));
    }
}
