import Vue from "vue";

import gridComponent from "@/components/child/grid/grid.vue";
import { GridColumn, GridColumnType } from "@/components/child/grid/gridColumn";
import ModalComponent from "@/components/child/modal/modal";
import modalComponent from "@/components/child/modal/modal.vue";
import { Component } from "@/decorators";
import PaginationModel from "@/models/paginationModel";
import ProdutoModel from "@/models/produto/produtoModel";
import ProdutoService from "@/services/produto/produtoService";
import ValidationErrorWrapper from "@/wrappers/validationErrorWrapper";

import ProdutoEditComponent from "../crud/produto/edit";
import produtoEditComponent from "../crud/produto/edit.vue";

import "./produtoassociacao.scss";

@Component({
    components: {
        gridComponent,
        produtoEditComponent,
        modalComponent,
    },
})
export default class ImportacaoNfeProdutoAssociacaoComponent extends Vue {
    private validationErrorWrapper = new ValidationErrorWrapper(this.$validator);
    private modalComponent: ModalComponent = null;
    private cadastroProdutoModalComponent: ModalComponent = null;
    private produtoEditComponent: ProdutoEditComponent = null;

    gridFilterKey = "";
    gridSortKey = "descricao";
    gridSortOrder = "asc";
    gridData: Array<ProdutoModel> = [];
    get gridColumns(): Array<GridColumn> {
        return [new GridColumn("descricao", "Descri\u00e7\u00e3o", GridColumnType.String)];
    }

    pageIndex = 1;
    pageSize = 20;
    total = 0;

    codigo: string = null;
    produtoId: number = null;
    produtoNome: string = null;
    produtoSelecionadoId: number = null;

    public async show(codigo: string, produtoId: number, produtoNome: string) {
        this.modalComponent.show();
        this.validationErrorWrapper.clearErrors();

        this.codigo = codigo;
        this.produtoId = produtoId;
        this.produtoNome = produtoNome;
        this.produtoSelecionadoId = null;
        this.gridFilterKey = produtoNome.split(" ")[0];

        // Desmarca os checkboxes que ficaram marcados na última vez que a tela foi aberta
        let checkboxes = this.$el.querySelectorAll("tr > td > input[type='checkbox']") as NodeListOf<HTMLInputElement>;

        for (let i = 0; i < checkboxes.length; i++) {
            checkboxes[i].checked = false;
        }

        await this.load().withLoading();

        if (produtoId) {
            const produtoGrid = this.gridData.filter(p => p.id != produtoId);

            if (produtoGrid.length > 0) {
                checkboxes = this.$el.querySelectorAll(
                    "tr > td > input[type='checkbox']",
                ) as NodeListOf<HTMLInputElement>;
                checkboxes[this.gridData.indexOf(produtoGrid[0])].checked = true;

                this.onSelectedValuesChanged([produtoGrid[0]]);
            }
        }

        const input = this.$el.querySelector("input[type='text']") as HTMLInputElement;
        input.value = this.gridFilterKey;
        input.focus();
    }

    public hide() {
        this.modalComponent.hide();
    }

    private async load() {
        try {
            const data = await new ProdutoService()
                .list(this.gridFilterKey, this.gridSortKey, this.gridSortOrder, this.pageIndex, this.pageSize)
                .then(r => r.json() as Promise<PaginationModel<ProdutoModel>>);

            this.gridData = data.list;
            this.total = data.total;
        } catch {}
    }

    private onChangeFilterKey(filterKey: string) {
        this.gridFilterKey = filterKey;
        this.load().withLoading();
    }

    private onChangeSort(sortKey: string, sortOrder: string) {
        this.gridSortKey = sortKey;
        this.gridSortOrder = sortOrder;
        this.load().withLoading();
    }

    private onChangePage(pageIndex: number) {
        this.pageIndex = pageIndex;
        this.load().withLoading();
    }

    private onSelectedValuesChanged(values: Array<ProdutoModel>) {
        if (values.length == 0) {
            this.produtoSelecionadoId = null;
        } else {
            this.produtoSelecionadoId = values[0].id;
        }
    }

    private onBtnOkClick() {
        this.$validator
            .validateAll()
            .then(() => {
                this.$emit(
                    "associacao-item",
                    this.codigo,
                    this.produtoSelecionadoId ? this.produtoSelecionadoId : this.produtoId,
                );
            })
            .catch(() => {});
    }

    private onBtnCriarProdutoClick() {
        this.produtoEditComponent.load();
        this.cadastroProdutoModalComponent.show();
    }

    private onProdutoBtnOkClick() {
        this.produtoEditComponent.save();
    }

    private async onProdutoSaveOk(produto: ProdutoModel) {
        this.cadastroProdutoModalComponent.hide();
        this.gridFilterKey = produto.descricao;

        await this.load().withLoading();

        const produtoGrid = this.gridData.filter(p => p.id != produto.id)[0];
        const checkboxes = this.$el.querySelectorAll(
            "tr > td > input[type='checkbox']",
        ) as NodeListOf<HTMLInputElement>;

        checkboxes[this.gridData.indexOf(produtoGrid)].checked = true;

        this.onSelectedValuesChanged([produtoGrid]);

        const input = this.$el.querySelector("input[type='text']") as HTMLInputElement;
        input.value = this.gridFilterKey;
        input.focus();
    }

    private mounted() {
        this.modalComponent = this.$refs.modalComponent as ModalComponent;
        this.cadastroProdutoModalComponent = this.$refs.cadastroProdutoModalComponent as ModalComponent;
        this.produtoEditComponent = this.$refs.produtoEditComponent as ProdutoEditComponent;
    }
}
