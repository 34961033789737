import ConversaoUnidadeMedidasModel from "@/models/auxiliar/conversaoUnidadeMedidasModel";
import Delay from "@/utils/common/delay";

import UnidadeMedidaService from "./unidadeMedidaService";

export default class IndicesConversoesUnidadeMedidasService {
    conversoes: Array<ConversaoUnidadeMedidasModel> = [];

    conversoesRequested: Array<ConversaoUnidadeMedidasModel> = [];

    unidadeMedidaService = new UnidadeMedidaService();

    //passar produtoid apenas quando for unidade medida tipo UI
    async getIndiceConversao(
        unidadeMedidaOrigemId = 0,
        unidadeMedidaDestinoId = 0,
        densidade = 1,
        produtoId = 0,
        produtoLoteId: number = null,
    ): Promise<number> {
        const filter = (p: ConversaoUnidadeMedidasModel) =>
            p.unidadeMedidaOrigemId == unidadeMedidaOrigemId &&
            p.unidadeMedidaDestinoId == unidadeMedidaDestinoId &&
            p.densidade == densidade &&
            p.produtoLoteId == produtoLoteId &&
            p.produtoId == produtoId;

        if (
            unidadeMedidaOrigemId != unidadeMedidaDestinoId &&
            unidadeMedidaDestinoId > 0 &&
            unidadeMedidaOrigemId > 0
        ) {
            let conversao = this.conversoes.filter(filter);

            if (conversao.length > 0) {
                return conversao[0].indice;
            }

            let requested = this.conversoesRequested.some(filter);

            while (requested) {
                await Delay(100);

                requested = this.conversoesRequested.some(filter);

                conversao = this.conversoes.filter(filter);

                if (conversao.length > 0) {
                    return conversao[0].indice;
                }
            }

            try {
                const conversaoRequest = new ConversaoUnidadeMedidasModel();
                conversaoRequest.densidade = densidade;
                conversaoRequest.unidadeMedidaDestinoId = unidadeMedidaDestinoId;
                conversaoRequest.unidadeMedidaOrigemId = unidadeMedidaOrigemId;
                conversaoRequest.produtoId = produtoId;
                conversaoRequest.produtoLoteId = produtoLoteId;
                const index = this.conversoesRequested.push(conversaoRequest);

                const data = await this.unidadeMedidaService
                    .converterUnidadeMedida(
                        unidadeMedidaOrigemId,
                        unidadeMedidaDestinoId,
                        densidade,
                        produtoId,
                        produtoLoteId,
                    )
                    .resolveWithJSON<number>();

                if (data != 0) {
                    const conversaoUnidadeMedida = new ConversaoUnidadeMedidasModel();
                    conversaoUnidadeMedida.densidade = densidade;
                    conversaoUnidadeMedida.unidadeMedidaDestinoId = unidadeMedidaDestinoId;
                    conversaoUnidadeMedida.unidadeMedidaOrigemId = unidadeMedidaOrigemId;
                    conversaoUnidadeMedida.indice = data;
                    conversaoUnidadeMedida.produtoId = produtoId;
                    conversaoUnidadeMedida.produtoLoteId = produtoLoteId;
                    this.conversoes.push(conversaoUnidadeMedida);
                }

                this.conversoesRequested.splice(index, 1);

                return data;
            } catch {}

            return 0;
        }
        return 1;
    }
}
