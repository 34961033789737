var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "crud crud-edit" },
    [
      _c("actionBarComponent", {
        attrs: {
          showSave: !_vm.somenteConsulta,
          title: _vm.$t("__.Crud.coleta.edit_vue_html.coletaEdit"),
        },
        on: { onSave: _vm.save },
      }),
      _vm._v(" "),
      _c(
        "form",
        {
          attrs: { novalidate: "novalidate" },
          on: {
            submit: function ($event) {
              $event.preventDefault()
              _vm.save()
            },
          },
        },
        [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-sm-3" }, [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "tooltip",
                      rawName: "v-tooltip",
                      value: _vm.errors.first("data"),
                      expression: "errors.first('data')",
                    },
                  ],
                  staticClass: "form-group",
                },
                [
                  _c(
                    "label",
                    {
                      staticClass: "control-label",
                      attrs: { for: "data", title: _vm.$t("__.obrigatorio") },
                    },
                    [
                      _c("span", { staticStyle: { color: "red" } }, [
                        _vm._v("*"),
                      ]),
                      _vm._v(
                        "\n                        " +
                          _vm._s(_vm.$t("__.Crud.coleta.edit_vue_html.data")) +
                          "\n                    "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("date-time-picker-component", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'",
                      },
                    ],
                    attrs: {
                      id: "data",
                      name: "data",
                      pickTime: true,
                      disabled: _vm.somenteConsulta,
                    },
                    model: {
                      value: _vm.model.data,
                      callback: function ($$v) {
                        _vm.$set(_vm.model, "data", $$v)
                      },
                      expression: "model.data",
                    },
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-sm-4" }, [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "tooltip",
                      rawName: "v-tooltip",
                      value: _vm.errors.first("descricao"),
                      expression: "errors.first('descricao')",
                    },
                  ],
                  staticClass: "form-group",
                },
                [
                  _c(
                    "label",
                    {
                      staticClass: "control-label",
                      attrs: {
                        for: "descricao",
                        title: _vm.$t("__.obrigatorio"),
                      },
                    },
                    [
                      _c("span", { staticStyle: { color: "red" } }, [
                        _vm._v("*"),
                      ]),
                      _vm._v(
                        "\n                        " +
                          _vm._s(
                            _vm.$t("__.Crud.coleta.edit_vue_html.descricao")
                          ) +
                          "\n                    "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      { name: "focus", rawName: "v-focus" },
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.model.descricao,
                        expression: "model.descricao",
                      },
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: {
                      type: "text",
                      id: "descricao",
                      name: "descricao",
                      autocomplete: "off",
                      disabled: _vm.somenteConsulta,
                    },
                    domProps: { value: _vm.model.descricao },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.model, "descricao", $event.target.value)
                      },
                    },
                  }),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-sm-3" }, [
              _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c(
                    "label",
                    {
                      staticClass: "control-label",
                      attrs: { for: "clienteId" },
                    },
                    [
                      _vm._v(
                        "\n                        " +
                          _vm._s(
                            _vm.$t(
                              "__.Components.parent.venda.editSingle_vue_html.cliente"
                            )
                          ) +
                          "\n                    "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("search-combo-component", {
                    attrs: {
                      id: "clienteId",
                      name: "clienteId",
                      actionName: "clientes",
                      advancedSearch: true,
                      filterable: false,
                      advancedSearchCampos: [
                        { campo: "text", descricao: "Nome" },
                        { campo: "cpf", descricao: "CNPJ/CPF" },
                        { campo: "celular", descricao: "Celular" },
                      ],
                      customComboText: _vm.customComboTextCliente,
                      customView: true,
                      disabled: _vm.somenteConsulta,
                      propDescription: "nome",
                      service: "Cliente",
                    },
                    model: {
                      value: _vm.model.clienteId,
                      callback: function ($$v) {
                        _vm.$set(_vm.model, "clienteId", $$v)
                      },
                      expression: "model.clienteId",
                    },
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-sm-2" }, [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "tooltip",
                      rawName: "v-tooltip",
                      value: _vm.errors.first("localEntregaId"),
                      expression: "errors.first('localEntregaId')",
                    },
                  ],
                  staticClass: "form-group",
                },
                [
                  _c(
                    "label",
                    {
                      staticClass: "control-label",
                      attrs: { for: "localEntregaId" },
                    },
                    [
                      _c("span", { staticStyle: { color: "red" } }, [
                        _vm._v("*"),
                      ]),
                      _vm._v(
                        "\n                        " +
                          _vm._s(
                            _vm.$t(
                              "__.Components.parent.venda.editSingle_vue_html.localEntrega"
                            )
                          ) +
                          "\n                    "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("combo-component", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required|maior_que_zero",
                        expression: "'required|maior_que_zero'",
                      },
                    ],
                    attrs: {
                      id: "localEntregaId",
                      name: "localEntregaId",
                      data: _vm.locaisEntregaOptions,
                      searchable: true,
                      customView: true,
                    },
                    model: {
                      value: _vm.model.localEntregaId,
                      callback: function ($$v) {
                        _vm.$set(_vm.model, "localEntregaId", $$v)
                      },
                      expression: "model.localEntregaId",
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
          _vm._v(" "),
          _vm.model.id
            ? _c("infoBagdeComponent", { attrs: { text: _vm.infoBagdeText } })
            : _vm._e(),
          _vm._v(" "),
          _c("fieldset", [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-sm-12 newbtn" }, [
                _c("div", { staticClass: "form-group" }, [
                  !_vm.somenteConsulta
                    ? _c(
                        "button",
                        {
                          staticClass: "btn btn-success",
                          attrs: { type: "submit" },
                        },
                        [
                          _c("i", {
                            staticClass: "fa fa-save",
                            attrs: { "aria-hidden": "true" },
                          }),
                          _vm._v(
                            "\n                            " +
                              _vm._s(_vm.$t("__.Crud.salvar")) +
                              "\n                        "
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.somenteConsulta
                    ? _c(
                        "button",
                        {
                          staticClass: "btn btn-secondary",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              _vm.load()
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n                            " +
                              _vm._s(_vm.$t("__.Crud.limpar")) +
                              "\n                        "
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-secondary",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          _vm.cancel()
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n                            " +
                          _vm._s(_vm.$t("__.Crud.voltar")) +
                          "\n                        "
                      ),
                    ]
                  ),
                ]),
              ]),
            ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }