import BaseModel from "./base/baseModel";

export default class CartaCorrecaoModel extends BaseModel {
    id?: number = null;
    notaFiscalSaidaId?: number = null;
    notaFiscalEntradaId?: number = null;
    descricao: string = null;
    sequencial: number = null;
    data: Date = null;
}
