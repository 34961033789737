import BaseModel from "../../../../../base/baseModel";

export default class TotalIssqnNfeModel extends BaseModel {
    valorDescCond: number = null;
    valorDescIncond: number = null;
    valorOutro: number = null;
    valorDeducao: number = null;
    dCompet: string = null;
    valorCOFINS: number = null;
    valorPIS: number = null;
    valorISS: number = null;
    valorBaseCalculo: number = null;
    valorServico: number = null;
    cRegTrib: number = null;
    valorISSRet: number = null;
}
