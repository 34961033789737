var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "crud crud-edit" },
    [
      _c(
        "div",
        { staticClass: "col-sm-12" },
        [
          _c("buttonScComponent", {
            attrs: {
              classButton: "btn btn-primary btn-barcode",
              classIcon: "fa fa-plus-circle",
              buttonName: _vm.$t("__.Crud.separacao.edit_vue_html.botaoCodigo"),
            },
            on: { click: _vm.showAddCodigo },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm.isFracionamento
        ? _c(
            "div",
            { staticClass: "col-sm-12" },
            [
              !_vm.CalledByShortCut
                ? _c("actionBarComponent", {
                    attrs: {
                      title:
                        _vm.$t(
                          "__.Crud.separacao.edit_vue_html.separacaoVenda"
                        ) +
                        " " +
                        _vm.model.codigo,
                      showSave: !_vm.todosSeparados,
                    },
                    on: { onSave: _vm.save },
                  })
                : _vm._e(),
              _vm._v(" "),
              _c(
                "form",
                {
                  attrs: { novalidate: "novalidate" },
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      _vm.save().withLoading()
                    },
                  },
                },
                [
                  _c("fieldset", [
                    _c(
                      "div",
                      { staticClass: "row" },
                      [
                        _c("grid-component", {
                          ref: "gridcomponent",
                          attrs: {
                            data: _vm.modelGrid,
                            columns: _vm.gridColumns,
                            showRemoveItem: false,
                            showEditItem: false,
                            showActionColumn: true,
                            showSortIcons: false,
                            showTableHead: false,
                            showAddNewButton: false,
                            showTableFoot: false,
                            showTools: false,
                            extraActions: _vm.extraActions,
                            subColumns: _vm.gridSubColumn,
                            subDataName: _vm.subDataName,
                            showExpand: true,
                            conditionShowSubData: _vm.conditionShowSubData,
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("fieldset", [
                    !_vm.CalledByShortCut
                      ? _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-sm-12 newbtn" }, [
                            _c("div", { staticClass: "form-group" }, [
                              !_vm.todosSeparados
                                ? _c(
                                    "button",
                                    {
                                      staticClass: "btn btn-success",
                                      attrs: { type: "submit" },
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "fa fa-save",
                                        attrs: { "aria-hidden": "true" },
                                      }),
                                      _vm._v(
                                        "\n                                " +
                                          _vm._s(
                                            _vm.salvarEConcluir
                                              ? _vm.$t(
                                                  "__.Crud.separacao.edit_vue_html.salvarEConcluir"
                                                )
                                              : _vm.$t("__.Crud.salvar")
                                          ) +
                                          "\n                            "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-secondary",
                                  attrs: { type: "button" },
                                  on: {
                                    click: function ($event) {
                                      _vm.cancel()
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(_vm.$t("__.Crud.voltar")) +
                                      "\n                            "
                                  ),
                                ]
                              ),
                            ]),
                          ]),
                        ])
                      : _vm._e(),
                  ]),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "shortcutComponent",
        {
          ref: "shortcutComponentCodigo",
          attrs: { modalClass: "ld-modal" },
          on: {
            "selection-item": function ($event) {
              _vm.onSearchCodigo().withLoading()
            },
          },
        },
        [
          _c("div", { attrs: { slot: "component" }, slot: "component" }, [
            _c("form", [
              _c("div", { staticClass: "row" }, [
                _c("div", { class: "col-sm-12 paddingTop" }, [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "tooltip",
                          rawName: "v-tooltip",
                          value: _vm.errors.first("nome"),
                          expression: "errors.first('nome')",
                        },
                      ],
                      staticClass: "form-group",
                    },
                    [
                      _c(
                        "label",
                        {
                          staticClass: "control-label",
                          attrs: { for: "nome" },
                        },
                        [
                          _vm._v(
                            "\n                                " +
                              _vm._s(
                                _vm.$t(
                                  "__.Crud.separacao.edit_vue_html.labelCodigo"
                                )
                              ) +
                              "\n                            "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.codigo,
                            expression: "codigo",
                          },
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required|alpha_spaces|max:256",
                            expression: "'required|alpha_spaces|max:256'",
                          },
                          { name: "focus", rawName: "v-focus" },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          autocomplete: "off",
                          type: "text",
                          id: "codigo",
                          name: "codigo",
                        },
                        domProps: { value: _vm.codigo },
                        on: {
                          keydown: function ($event) {
                            if (
                              !("button" in $event) &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            $event.preventDefault()
                            _vm.onSearchCodigo().withLoading()
                          },
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.codigo = $event.target.value
                          },
                        },
                      }),
                    ]
                  ),
                ]),
              ]),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }