var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "form",
      {
        staticClass: "form-incluir-item",
        attrs: { novalidate: "" },
        on: {
          submit: function ($event) {
            $event.preventDefault()
            _vm.incluirItem()
          },
        },
      },
      [
        _c(
          "fieldset-component",
          { attrs: { title: "Item", collapsed: false } },
          [
            _c(
              "div",
              { attrs: { slot: "rows" }, slot: "rows" },
              [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-sm-4" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("tipoItem"),
                            expression: "errors.first('tipoItem')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: {
                              for: "tipoItem",
                              title: _vm.$t("__.obrigatorio"),
                            },
                          },
                          [
                            _c("span", { staticStyle: { color: "red" } }, [
                              _vm._v("*"),
                            ]),
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Crud.formulapadrao.item_vue_html.tipoProd"
                                  )
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("combo-component", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required",
                              expression: "'required'",
                            },
                          ],
                          attrs: {
                            id: "tipoItem",
                            name: "tipoItem",
                            data: _vm.tiposManipulacaoOptions,
                            searchable: true,
                          },
                          model: {
                            value: _vm.tipoItem,
                            callback: function ($$v) {
                              _vm.tipoItem = $$v
                            },
                            expression: "tipoItem",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-6" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("produtoItemId"),
                            expression: "errors.first('produtoItemId')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: {
                              for: "produtoItemId",
                              title: _vm.$t("__.obrigatorio"),
                            },
                          },
                          [
                            _c("span", { staticStyle: { color: "red" } }, [
                              _vm._v("*"),
                            ]),
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Crud.formulapadrao.item_vue_html.prod"
                                  )
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("combo-component", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required",
                              expression: "'required'",
                            },
                          ],
                          attrs: {
                            id: "produtoItemId",
                            name: "produtoItemId",
                            data: _vm.produtosOptions,
                            searchable: true,
                            customView: true,
                          },
                          model: {
                            value: _vm.produtoId,
                            callback: function ($$v) {
                              _vm.produtoId = $$v
                            },
                            expression: "produtoId",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-2" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("faseUso"),
                            expression: "errors.first('faseUso')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "faseUso" },
                          },
                          [
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Crud.formulapadrao.item_vue_html.faseUso"
                                  )
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.model.faseUso,
                              expression: "model.faseUso",
                            },
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "max:256",
                              expression: "'max:256'",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            autocomplete: "off",
                            type: "text",
                            id: "faseUso",
                            name: "faseUso",
                          },
                          domProps: { value: _vm.model.faseUso },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.model,
                                "faseUso",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-sm-3" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("fatorCorrecao"),
                            expression: "errors.first('fatorCorrecao')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "fatorCorrecao" },
                          },
                          [
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Crud.formulapadrao.item_vue_html.ftrCorre"
                                  )
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("decimal-component", {
                          staticClass: "form-control",
                          attrs: {
                            id: "fatorCorrecao",
                            name: "fatorCorrecao",
                            disabled: true,
                          },
                          model: {
                            value: _vm.model.fatorCorrecao,
                            callback: function ($$v) {
                              _vm.$set(_vm.model, "fatorCorrecao", $$v)
                            },
                            expression: "model.fatorCorrecao",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { class: "col-sm-" + [_vm.showDose ? "2" : "3"] }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("tipoCalculo"),
                            expression: "errors.first('tipoCalculo')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "tipoCalculo" },
                          },
                          [
                            _c("span", { staticStyle: { color: "red" } }, [
                              _vm._v("*"),
                            ]),
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Crud.formulapadrao.item_vue_html.tipoCalc"
                                  )
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("combo-component", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required|maior_que_zero",
                              expression: "'required|maior_que_zero'",
                            },
                          ],
                          attrs: {
                            id: "tipoCalculo",
                            name: "tipoCalculo",
                            data: _vm.tiposCalculoOptions,
                            searchable: true,
                          },
                          model: {
                            value: _vm.model.tipoCalculo,
                            callback: function ($$v) {
                              _vm.$set(_vm.model, "tipoCalculo", $$v)
                            },
                            expression: "model.tipoCalculo",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _vm.showDose
                    ? _c("div", { staticClass: "col-sm-1" }, [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: _vm.errors.first("volumeDose"),
                                expression: "errors.first('volumeDose')",
                              },
                            ],
                            staticClass: "form-group",
                          },
                          [
                            _c(
                              "label",
                              {
                                staticClass: "control-label",
                                attrs: {
                                  for: "volumeDose",
                                  title: _vm.$t("__.obrigatorio"),
                                },
                              },
                              [
                                _c("span", { staticStyle: { color: "red" } }, [
                                  _vm._v("*"),
                                ]),
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(
                                      _vm.$t(
                                        "__.Crud.formulapadrao.item_vue_html.volumeDose"
                                      )
                                    ) +
                                    "\n                            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("decimal-com-sinal-custom-component", {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "required|maior_que_zero",
                                  expression: "'required|maior_que_zero'",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: {
                                title:
                                  "Volume Dose é o valor de cada dose em unidades de medida igual unidade do produto (" +
                                  _vm.getTooltipUnidadeMedidaDose(
                                    _vm.model.unidadeMedidaId
                                  ) +
                                  ")",
                                signal: _vm.getTooltipUnidadeMedidaDose(
                                  _vm.model.unidadeMedidaId
                                ),
                                id: "volumeDose",
                                name: "volumeDose",
                              },
                              model: {
                                value: _vm.model.volumeDose,
                                callback: function ($$v) {
                                  _vm.$set(_vm.model, "volumeDose", $$v)
                                },
                                expression: "model.volumeDose",
                              },
                            }),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-3" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("quantidadeDose"),
                            expression: "errors.first('quantidadeDose')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: {
                              for: "quantidadeDose",
                              title: _vm.$t("__.obrigatorio"),
                            },
                          },
                          [
                            _c("span", { staticStyle: { color: "red" } }, [
                              _vm._v("*"),
                            ]),
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Crud.formulapadrao.item_vue_html.qtd"
                                  )
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("decimal-component", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required|maior_que_zero",
                              expression: "'required|maior_que_zero'",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            id: "quantidadeDose",
                            name: "quantidadeDose",
                            disabled: _vm.model.tipoCalculo == 3,
                          },
                          model: {
                            value: _vm.model.quantidadeDose,
                            callback: function ($$v) {
                              _vm.$set(_vm.model, "quantidadeDose", $$v)
                            },
                            expression: "model.quantidadeDose",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-3" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first(
                              "unidadeMedidaManipulacaoItemId"
                            ),
                            expression:
                              "errors.first('unidadeMedidaManipulacaoItemId')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: {
                              for: "unidadeMedidaManipulacaoItemId",
                              title: _vm.$t("__.obrigatorio"),
                            },
                          },
                          [
                            _c("span", { staticStyle: { color: "red" } }, [
                              _vm._v("*"),
                            ]),
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Crud.formulapadrao.item_vue_html.unMedidaManipu"
                                  )
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("combo-component", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required",
                              expression: "'required'",
                            },
                          ],
                          attrs: {
                            id: "unidadeMedidaManipulacaoItemId",
                            name: "unidadeMedidaManipulacaoItemId",
                            data: _vm.unidadesMedidaManipulacaoOptions,
                            searchable: true,
                          },
                          model: {
                            value: _vm.model.unidadeMedidaManipulacaoId,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.model,
                                "unidadeMedidaManipulacaoId",
                                $$v
                              )
                            },
                            expression: "model.unidadeMedidaManipulacaoId",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("buttonIncluirComponent", {
                  on: { click: _vm.incluirItem },
                }),
              ],
              1
            ),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }