import TiposMovimentacao from "../../models/enum/tiposMovimentacao";
import ContaGerencialModel from "../../models/financeiro/contaGerencialModel";
import nfetch from "../../utils/events/nfetch";

import { IService } from "./../base/iService";

export default class ContaGerencialService extends IService<ContaGerencialModel> {
    constructor() {
        super("ContaGerencial");
    }

    public listByTipoMovimentacao(filterKey: string, pageIndex: number, pageSize: number, tipo: number): Promise<Response> {
        return nfetch(`/${this.controllerName}/ListByTipoMovimentacao?filterKey=${filterKey}&pageIndex=${pageIndex}&pageSize=${pageSize}&tipo=${tipo}`, {
            credentials: "same-origin"
        });
    }

    public listByFranquiaId(franquiaId: number = null, tipo: TiposMovimentacao = TiposMovimentacao.Entrada): Promise<Response> {
        return nfetch(`/${this.controllerName}/ListByFranquiaId?franquiaId=${franquiaId}&tipo=${tipo}`, {
            credentials: "same-origin"
        });
    }
}