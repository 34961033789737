import Vue from "vue";

import gridComponent from "@/components/child/grid/grid.vue";
import { GridColumn, GridColumnType } from "@/components/child/grid/gridColumn";
import { Component } from "@/decorators";
import FormaFarmaceuticaModel, { FormaFarmaceuticaListParameters } from "@/models/formaFarmaceuticaModel";
import PaginationModel from "@/models/paginationModel";
import AtualizacaoValoresService from "@/services/atualizacaoValoresService";
import FormaFarmaceuticaService from "@/services/formaFarmaceuticaService";

import "../crud/crud.scss";
import "./atualizacaoValores.scss";

@Component({
    components: {
        gridComponent,
    },
})
export default class AtualizacaoValoresFormaFarmaceuticaComponent extends Vue {
    private service = new AtualizacaoValoresService();
    private formaFarmaceuticaService = new FormaFarmaceuticaService();

    percentualValorMinimo = 0;
    percentualValorAdicional = 0;

    total = 0;

    gridData: FormaFarmaceuticaModel[] = [];
    gridSelectedValues: FormaFarmaceuticaModel[] = [];

    get gridColumns(): Array<GridColumn> {
        return [
            new GridColumn("descricao", this.$t("__.ts.descricao"), GridColumnType.String),
            new GridColumn("valorMinimo", this.$t("__.ts.valorMinimoAtual"), GridColumnType.Money),
            new GridColumn("valorAdicional", this.$t("__.ts.valorAdicionalAtual"), GridColumnType.Money),
        ];
    }

    private async load() {
        try {
            const customParameters: FormaFarmaceuticaListParameters = {
                somenteAtivos: true,
            };

            const data = await this.formaFarmaceuticaService
                .list("", "descricao", "asc", 1, 999999, customParameters)
                .withLoading()
                .resolveWithJSON<PaginationModel<FormaFarmaceuticaModel>>();

            this.gridData = data.list;
            this.total = data.total;
        } catch {}
    }

    private async onAtualizarValores() {
        if (!this.percentualValorMinimo && !this.percentualValorAdicional) {
            await this.$showError(this.$t("__.ts.erro"), this.$t("__.ts.informePercentual"));
            return this.$focusField("percentualValorMinimo");
        }

        if (this.gridSelectedValues.length == 0) {
            await this.$showWarning(
                this.$t("__.ts.aviso"),
                this.$t("__.Components.parent.atualizacaoValores.produto.nenhumSelecionado"),
            );
            return;
        }

        try {
            await this.service
                .atualizacaoValoresFormaFarmaceutica(
                    this.gridSelectedValues.map(p => p.id),
                    this.percentualValorMinimo,
                    this.percentualValorAdicional,
                )
                .withLoading();

            this.$showSuccess(this.$t("__.ts.sucesso"), this.$t("__.ts.sucessoAtualizacao"));

            this.percentualValorMinimo = 0;
            this.percentualValorAdicional = 0;

            await this.load().withLoading();
        } catch {}
    }

    private onSelectedValuesChanged(values: FormaFarmaceuticaModel[]) {
        this.gridSelectedValues = values;
    }

    private mounted() {
        this.load();
    }
}
