var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "crud crud-edit" },
    [
      !_vm.isModal
        ? _c(
            "actionBarComponent",
            {
              attrs: {
                slots: 1,
                title: _vm.$t(
                  "__.Components.parent.notafiscalentrada.edit_vue_html.nfEntradaEdit"
                ),
              },
              on: { onSave: _vm.save },
            },
            [
              !_vm.modelId
                ? _c(
                    "div",
                    { attrs: { slot: "buttonSlot0" }, slot: "buttonSlot0" },
                    [
                      _c("rascunhoComponent", {
                        ref: "rascunhoComponent",
                        attrs: { entity: "notaentrada" },
                        on: {
                          onSaveRascunho: _vm.onSaveRascunho,
                          onLoadRascunho: _vm.onLoadRascunho,
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "form",
        {
          attrs: { novalidate: "novalidate" },
          on: {
            submit: function ($event) {
              $event.preventDefault()
              _vm.save()
            },
          },
        },
        [
          _c(
            "fieldset-component",
            {
              attrs: {
                title: _vm.$t(
                  "__.Components.parent.notafiscalentrada.edit_vue_html.dadosNfEnrtada"
                ),
                collapsed: false,
              },
            },
            [
              _c("div", { attrs: { slot: "rows" }, slot: "rows" }, [
                _c("div", { staticClass: "row" }, [
                  _vm.model.movimentacaoEstoque != null && _vm.modelId == 0
                    ? _c("div", { staticClass: "col-sm-6" }, [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: _vm.errors.first("estoqueDestinoId"),
                                expression: "errors.first('estoqueDestinoId')",
                              },
                            ],
                            staticClass: "form-group",
                          },
                          [
                            _c(
                              "label",
                              {
                                staticClass: "control-label",
                                attrs: {
                                  for: "estoqueDestinoId",
                                  title: _vm.$t("__.obrigatorio"),
                                },
                              },
                              [
                                _c("span", { staticStyle: { color: "red" } }, [
                                  _vm._v("*"),
                                ]),
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(
                                      _vm.$t(
                                        "__.Components.parent.notafiscalentrada.edit_vue_html.estqDestino"
                                      )
                                    ) +
                                    "\n                            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("combo-component", {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "required",
                                  expression: "'required'",
                                },
                              ],
                              attrs: {
                                id: "estoqueDestinoId",
                                name: "estoqueDestinoId",
                                data: _vm.estoquesOptions,
                                searchable: false,
                                refresh: _vm.loadEstoques,
                                disabled: _vm.disabled,
                              },
                              model: {
                                value:
                                  _vm.model.movimentacaoEstoque
                                    .estoqueDestinoId,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.model.movimentacaoEstoque,
                                    "estoqueDestinoId",
                                    $$v
                                  )
                                },
                                expression:
                                  "model.movimentacaoEstoque.estoqueDestinoId",
                              },
                            }),
                          ],
                          1
                        ),
                      ])
                    : _vm.estoqueDestino != null
                    ? _c("div", { staticClass: "col-sm-6" }, [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: _vm.errors.first("estoqueDestino"),
                                expression: "errors.first('estoqueDestino')",
                              },
                            ],
                            staticClass: "form-group disabled",
                          },
                          [
                            _c(
                              "label",
                              {
                                staticClass: "control-label",
                                attrs: { for: "estoqueDestino" },
                              },
                              [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(
                                      _vm.$t(
                                        "__.Components.parent.notafiscalentrada.edit_vue_html.estqDestino"
                                      )
                                    ) +
                                    "\n                            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.estoqueDestino,
                                  expression: "estoqueDestino",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: {
                                type: "text",
                                id: "estoqueDestino",
                                name: "estoqueDestino",
                                disabled: _vm.disabled,
                              },
                              domProps: { value: _vm.estoqueDestino },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.estoqueDestino = $event.target.value
                                },
                              },
                            }),
                          ]
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-2" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("dataEmissao"),
                            expression: "errors.first('dataEmissao')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: {
                              for: "dataEmissao",
                              title: _vm.$t("__.obrigatorio"),
                            },
                          },
                          [
                            _c("span", { staticStyle: { color: "red" } }, [
                              _vm._v("*"),
                            ]),
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Components.parent.notafiscalentrada.edit_vue_html.dtEmissao"
                                  )
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("date-time-picker-component", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required",
                              expression: "'required'",
                            },
                          ],
                          attrs: {
                            id: "dataInclusao",
                            name: "dataEmissao",
                            disabled: _vm.disabled,
                          },
                          model: {
                            value: _vm.model.dataEmissao,
                            callback: function ($$v) {
                              _vm.$set(_vm.model, "dataEmissao", $$v)
                            },
                            expression: "model.dataEmissao",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-2" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("dataInclusao"),
                            expression: "errors.first('dataInclusao')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: {
                              for: "dataInclusao",
                              title: _vm.$t("__.obrigatorio"),
                            },
                          },
                          [
                            _c("span", { staticStyle: { color: "red" } }, [
                              _vm._v("*"),
                            ]),
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Components.parent.notafiscalentrada.edit_vue_html.dtInclusao"
                                  )
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("date-time-picker-component", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required",
                              expression: "'required'",
                            },
                          ],
                          attrs: {
                            id: "dataInclusao",
                            name: "dataInclusao",
                            disabled: _vm.disabled,
                          },
                          model: {
                            value: _vm.model.dataInclusao,
                            callback: function ($$v) {
                              _vm.$set(_vm.model, "dataInclusao", $$v)
                            },
                            expression: "model.dataInclusao",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-1" }, [
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c(
                          "label",
                          {
                            attrs: {
                              for: "chkAtualizaCustoProdutos",
                              title: _vm.$t(
                                "__.Components.parent.notafiscalentrada.edit_vue_html.vlrSeraAtualizVlrNota"
                              ),
                            },
                          },
                          [
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Components.parent.notafiscalentrada.edit_vue_html.atualizarCustos"
                                  )
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("checkbox-component", {
                          attrs: {
                            id: "chkAtualizaCustoProdutos",
                            name: "chkAtualizaCustoProdutos",
                            title: _vm.$t(
                              "__.Components.parent.notafiscalentrada.edit_vue_html.vlrSeraAtualizVlrNota"
                            ),
                            disabled: _vm.disabled,
                          },
                          model: {
                            value: _vm.model.atualizarCustoProdutos,
                            callback: function ($$v) {
                              _vm.$set(_vm.model, "atualizarCustoProdutos", $$v)
                            },
                            expression: "model.atualizarCustoProdutos",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-1" }, [
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c("label", { attrs: { for: "chkGerarContaPagar" } }, [
                          _vm._v(
                            "\n                                " +
                              _vm._s(
                                _vm.$t(
                                  "__.Components.parent.notafiscalentrada.edit_vue_html.gerarCntPagar"
                                )
                              ) +
                              "\n                            "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("checkbox-component", {
                          attrs: {
                            id: "chkGerarContaPagar",
                            name: "chkGerarContaPagar",
                            disabled: !!_vm.model.id || _vm.disabled,
                          },
                          model: {
                            value: _vm.model.gerarContasPagar,
                            callback: function ($$v) {
                              _vm.$set(_vm.model, "gerarContasPagar", $$v)
                            },
                            expression: "model.gerarContasPagar",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-sm-3" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("numero"),
                            expression: "errors.first('numero')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: {
                              for: "numero",
                              title: _vm.$t("__.obrigatorio"),
                            },
                          },
                          [
                            _c("span", { staticStyle: { color: "red" } }, [
                              _vm._v("*"),
                            ]),
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Components.parent.notafiscalentrada.edit_vue_html.nroNF"
                                  )
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.model.numero,
                              expression: "model.numero",
                            },
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required|numeric|max:12",
                              expression: "'required|numeric|max:12'",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            type: "number",
                            id: "numero",
                            name: "numero",
                            disabled: _vm.disabled,
                          },
                          domProps: { value: _vm.model.numero },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(_vm.model, "numero", $event.target.value)
                            },
                          },
                        }),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-3" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("serie"),
                            expression: "errors.first('serie')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: {
                              for: "serie",
                              title: _vm.$t("__.obrigatorio"),
                            },
                          },
                          [
                            _c("span", { staticStyle: { color: "red" } }, [
                              _vm._v("*"),
                            ]),
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Components.parent.notafiscalentrada.edit_vue_html.serie"
                                  )
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.model.serie,
                              expression: "model.serie",
                            },
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required|max:5",
                              expression: "'required|max:5'",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            type: "number",
                            id: "serie",
                            name: "serie",
                            disabled: _vm.disabled,
                          },
                          domProps: { value: _vm.model.serie },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(_vm.model, "serie", $event.target.value)
                            },
                          },
                        }),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-6" }, [
                    _c("div", { staticClass: "form-group" }, [
                      _c(
                        "label",
                        {
                          staticClass: "control-label",
                          attrs: { for: "chave" },
                        },
                        [
                          _vm._v(
                            "\n                                " +
                              _vm._s(
                                _vm.$t(
                                  "__.Components.parent.notafiscalentrada.edit_vue_html.chave"
                                )
                              ) +
                              "\n                            "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.model.chave,
                            expression: "model.chave",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          id: "chave",
                          name: "chave",
                          disabled: _vm.disabled,
                        },
                        domProps: { value: _vm.model.chave },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(_vm.model, "chave", $event.target.value)
                          },
                        },
                      }),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _vm.modelId == 0
                    ? _c("div", { staticClass: "col-sm-6" }, [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: _vm.errors.first("fornecedorId"),
                                expression: "errors.first('fornecedorId')",
                              },
                            ],
                            staticClass: "form-group",
                          },
                          [
                            _c(
                              "label",
                              {
                                staticClass: "control-label",
                                attrs: {
                                  for: "fornecedorId",
                                  title: _vm.$t("__.obrigatorio"),
                                },
                              },
                              [
                                _c("span", { staticStyle: { color: "red" } }, [
                                  _vm._v("*"),
                                ]),
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(
                                      _vm.$t(
                                        "__.Components.parent.notafiscalentrada.edit_vue_html.fornecedor"
                                      )
                                    ) +
                                    "\n                            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "combo-component",
                              {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: "required",
                                    expression: "'required'",
                                  },
                                ],
                                attrs: {
                                  id: "fornecedorId",
                                  name: "fornecedorId",
                                  actionName: "fornecedores",
                                  addEditId: _vm.addNewId,
                                  addNew: !_vm.addNewId,
                                  data: _vm.fornecedoresOptions,
                                  disabled: _vm.disabled,
                                  formAddNewName:
                                    _vm.addNewId > 0
                                      ? _vm.$t(
                                          "__.Components.parent.notafiscalentrada.edit_vue_html.editFornecedor"
                                        )
                                      : _vm.$t(
                                          "__.Components.parent.notafiscalentrada.edit_vue_html.novoFornecedor"
                                        ),
                                  refresh: _vm.loadFornecedores,
                                  searchable: true,
                                },
                                on: {
                                  addNewItemCombo: function ($event) {
                                    _vm.onAddNewFornecedor()
                                  },
                                  openComboEditItem:
                                    _vm.openComboEditFornecedor,
                                  openComboNewItem: _vm.openComboNewFornecedor,
                                },
                                model: {
                                  value: _vm.model.fornecedorId,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.model, "fornecedorId", $$v)
                                  },
                                  expression: "model.fornecedorId",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    attrs: { slot: "component" },
                                    slot: "component",
                                  },
                                  [
                                    _c("fornecedorComponent", {
                                      ref: "fornecedorComponent",
                                      attrs: { CalledByShortCut: true },
                                      on: { "save-ok": _vm.onFornecedorSaveOk },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ])
                    : _vm.fornecedorNome != null
                    ? _c("div", { staticClass: "col-sm-6" }, [
                        _c("div", { staticClass: "form-group disabled" }, [
                          _c("label", { staticClass: "control-label" }, [
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Components.parent.notafiscalentrada.edit_vue_html.fornecedor"
                                  )
                                ) +
                                "\n                            "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.fornecedorNome,
                                expression: "fornecedorNome",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: { type: "text", disabled: _vm.disabled },
                            domProps: { value: _vm.fornecedorNome },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.fornecedorNome = $event.target.value
                              },
                            },
                          }),
                        ]),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-6" }, [
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "transportadoraId" },
                          },
                          [
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Components.parent.notafiscalentrada.edit_vue_html.transportadora"
                                  )
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("combo-component", {
                          attrs: {
                            id: "transportadoraId",
                            name: "transportadoraId",
                            data: _vm.transportadoraOptions,
                            searchable: true,
                            refresh: _vm.loadTransportadoras,
                            disabled: _vm.disabled,
                          },
                          model: {
                            value: _vm.model.transportadoraId,
                            callback: function ($$v) {
                              _vm.$set(_vm.model, "transportadoraId", $$v)
                            },
                            expression: "model.transportadoraId",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "fieldset-component",
            {
              attrs: {
                title: _vm.$t(
                  "__.Components.parent.notafiscalentrada.edit_vue_html.itens"
                ),
                collapsed: false,
              },
            },
            [
              _c(
                "div",
                { attrs: { slot: "rows" }, slot: "rows" },
                [
                  _c("item-list-component", {
                    ref: "itemListComponent",
                    attrs: {
                      model: _vm.model.notaFiscalEntradaItens,
                      Fornec: _vm.model.fornecedorId,
                      ShowAddOptions:
                        _vm.modelId == 0 || _vm.model.importacaoxml == false,
                    },
                    on: { "item-changed": _vm.onItemChaged },
                  }),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _vm.model.gerarContasPagar
            ? _c(
                "fieldset-component",
                {
                  attrs: {
                    title: _vm.$t(
                      "__.Components.parent.notafiscalentrada.edit_vue_html.financeiro"
                    ),
                    collapsed: false,
                  },
                },
                [
                  _vm.model.contaPagar != null
                    ? _c(
                        "div",
                        { attrs: { slot: "rows" }, slot: "rows" },
                        [
                          _vm.modelId == 0 || _vm.model.importacaoXml == false
                            ? [
                                _c("financeiro-list-component", {
                                  ref: "financeiroListComponent",
                                  attrs: {
                                    model: _vm.model.contaPagar,
                                    ShowAddOptions:
                                      _vm.modelId == 0 ||
                                      _vm.model.importacaoXml == false,
                                  },
                                }),
                              ]
                            : [
                                _c("contas-pagar-list-component", {
                                  attrs: {
                                    HideExtras: true,
                                    FilterKeyId: _vm.model.contaPagarId,
                                    FilterStatusConta: -1,
                                  },
                                }),
                              ],
                        ],
                        2
                      )
                    : _vm._e(),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "fieldset-component",
            {
              attrs: {
                title: _vm.$t(
                  "__.Components.parent.notafiscalentrada.edit_vue_html.totais"
                ),
                collapsed: false,
              },
            },
            [
              _c(
                "div",
                { attrs: { slot: "rows" }, slot: "rows" },
                [
                  _vm.model.notaFiscalEntradaTotalICMS != null
                    ? [
                        _vm.model.importacaoXml || _vm.modelId > 0
                          ? [
                              _c("div", { staticClass: "row" }, [
                                _c("div", { staticClass: "col-sm-2" }, [
                                  _c(
                                    "div",
                                    { staticClass: "form-group disabled" },
                                    [
                                      _c(
                                        "label",
                                        {
                                          staticClass: "control-label",
                                          attrs: { for: "valorTotalFrete" },
                                        },
                                        [
                                          _vm._v(
                                            "\n                                        " +
                                              _vm._s(
                                                _vm.$t(
                                                  "__.Components.parent.notafiscalentrada.edit_vue_html.vlrFrete"
                                                )
                                              ) +
                                              "\n                                    "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("moeda-component", {
                                        staticClass: "form-control",
                                        attrs: {
                                          id: "valorTotalFrete",
                                          name: "valorTotalFrete",
                                          disabled: _vm.disabled,
                                        },
                                        model: {
                                          value:
                                            _vm.model.notaFiscalEntradaTotalICMS
                                              .valorFrete,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.model
                                                .notaFiscalEntradaTotalICMS,
                                              "valorFrete",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "model.notaFiscalEntradaTotalICMS.valorFrete",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "col-sm-2" }, [
                                  _c(
                                    "div",
                                    { staticClass: "form-group disabled" },
                                    [
                                      _c(
                                        "label",
                                        {
                                          staticClass: "control-label",
                                          attrs: { for: "valorIPI" },
                                        },
                                        [
                                          _vm._v(
                                            "\n                                        " +
                                              _vm._s(
                                                _vm.$t(
                                                  "__.Components.parent.notafiscalentrada.edit_vue_html.vlrIPI"
                                                )
                                              ) +
                                              "\n                                    "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("moeda-component", {
                                        staticClass: "form-control",
                                        attrs: {
                                          id: "valorIPI",
                                          name: "valorIPI",
                                          disabled: _vm.disabled,
                                        },
                                        model: {
                                          value:
                                            _vm.model.notaFiscalEntradaTotalICMS
                                              .valorIPI,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.model
                                                .notaFiscalEntradaTotalICMS,
                                              "valorIPI",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "model.notaFiscalEntradaTotalICMS.valorIPI",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "col-sm-3" }, [
                                  _c(
                                    "div",
                                    { staticClass: "form-group disabled" },
                                    [
                                      _c(
                                        "label",
                                        {
                                          staticClass: "control-label",
                                          attrs: { for: "valorTotalProdutos" },
                                        },
                                        [
                                          _vm._v(
                                            "\n                                        " +
                                              _vm._s(
                                                _vm.$t(
                                                  "__.Components.parent.notafiscalentrada.edit_vue_html.vlrTotalProd"
                                                )
                                              ) +
                                              "\n                                    "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("moeda-component", {
                                        directives: [
                                          {
                                            name: "mask-moeda-decimal",
                                            rawName: "v-mask-moeda-decimal",
                                          },
                                        ],
                                        staticClass: "form-control",
                                        attrs: {
                                          id: "valorTotalProdutos",
                                          name: "valorTotalProdutos",
                                          disabled: _vm.disabled,
                                        },
                                        model: {
                                          value:
                                            _vm.model.notaFiscalEntradaTotalICMS
                                              .valorProd,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.model
                                                .notaFiscalEntradaTotalICMS,
                                              "valorProd",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "model.notaFiscalEntradaTotalICMS.valorProd",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "col-sm-2" }, [
                                  _c(
                                    "div",
                                    { staticClass: "form-group disabled" },
                                    [
                                      _c(
                                        "label",
                                        {
                                          staticClass: "control-label",
                                          attrs: { for: "valorTotalDescontos" },
                                        },
                                        [
                                          _vm._v(
                                            "\n                                        " +
                                              _vm._s(
                                                _vm.$t(
                                                  "__.Components.parent.notafiscalentrada.edit_vue_html.vlrDescontos"
                                                )
                                              ) +
                                              "\n                                    "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("moeda-component", {
                                        staticClass: "form-control",
                                        attrs: {
                                          id: "valorTotalDescontos",
                                          name: "valorTotalDescontos",
                                          disabled: _vm.disabled,
                                        },
                                        model: {
                                          value:
                                            _vm.model.notaFiscalEntradaTotalICMS
                                              .valorDesc,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.model
                                                .notaFiscalEntradaTotalICMS,
                                              "valorDesc",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "model.notaFiscalEntradaTotalICMS.valorDesc",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "col-sm-3" }, [
                                  _c(
                                    "div",
                                    { staticClass: "form-group disabled" },
                                    [
                                      _c(
                                        "label",
                                        {
                                          staticClass: "control-label",
                                          attrs: { for: "valorTotal" },
                                        },
                                        [
                                          _vm._v(
                                            "\n                                        " +
                                              _vm._s(
                                                _vm.$t(
                                                  "__.Components.parent.notafiscalentrada.edit_vue_html.vlrTotal"
                                                )
                                              ) +
                                              "\n                                    "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("moeda-component", {
                                        directives: [
                                          {
                                            name: "mask-moeda-decimal",
                                            rawName: "v-mask-moeda-decimal",
                                          },
                                        ],
                                        staticClass: "form-control",
                                        attrs: {
                                          id: "valorTotal",
                                          name: "valorTotal",
                                          disabled: _vm.disabled,
                                        },
                                        model: {
                                          value: _vm.model.valorTotal,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.model,
                                              "valorTotal",
                                              $$v
                                            )
                                          },
                                          expression: "model.valorTotal",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                              ]),
                            ]
                          : [
                              _c("div", { staticClass: "row" }, [
                                _c("div", { staticClass: "col-sm-3" }, [
                                  _c(
                                    "div",
                                    { staticClass: "form-group" },
                                    [
                                      _c(
                                        "label",
                                        {
                                          staticClass: "control-label",
                                          attrs: { for: "valorFrete" },
                                        },
                                        [
                                          _vm._v(
                                            "\n                                        " +
                                              _vm._s(
                                                _vm.$t(
                                                  "__.Components.parent.notafiscalentrada.edit_vue_html.vlrFrete"
                                                )
                                              ) +
                                              "\n                                    "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("moeda-component", {
                                        staticClass: "form-control",
                                        attrs: {
                                          id: "valorFrete",
                                          name: "valorFrete",
                                          disabled: _vm.disabled,
                                        },
                                        model: {
                                          value:
                                            _vm.model.notaFiscalEntradaTotalICMS
                                              .valorFrete,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.model
                                                .notaFiscalEntradaTotalICMS,
                                              "valorFrete",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "model.notaFiscalEntradaTotalICMS.valorFrete",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "col-sm-3" }, [
                                  _c(
                                    "div",
                                    { staticClass: "form-group" },
                                    [
                                      _c(
                                        "label",
                                        {
                                          staticClass: "control-label",
                                          attrs: { for: "valorIPI" },
                                        },
                                        [
                                          _vm._v(
                                            "\n                                        " +
                                              _vm._s(
                                                _vm.$t(
                                                  "__.Components.parent.notafiscalentrada.edit_vue_html.vlrIPI"
                                                )
                                              ) +
                                              "\n                                    "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("moeda-component", {
                                        staticClass: "form-control",
                                        attrs: {
                                          id: "valorIPI",
                                          name: "valorIPI",
                                          disabled: _vm.disabled,
                                        },
                                        model: {
                                          value:
                                            _vm.model.notaFiscalEntradaTotalICMS
                                              .valorIPI,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.model
                                                .notaFiscalEntradaTotalICMS,
                                              "valorIPI",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "model.notaFiscalEntradaTotalICMS.valorIPI",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "col-sm-3" }, [
                                  _c(
                                    "div",
                                    { staticClass: "form-group" },
                                    [
                                      _c(
                                        "label",
                                        {
                                          staticClass: "control-label",
                                          attrs: { for: "valorTotalDescontos" },
                                        },
                                        [
                                          _vm._v(
                                            "\n                                        " +
                                              _vm._s(
                                                _vm.$t(
                                                  "__.Components.parent.notafiscalentrada.edit_vue_html.vlrDescontos"
                                                )
                                              ) +
                                              "\n                                    "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("moeda-component", {
                                        staticClass: "form-control",
                                        attrs: {
                                          id: "valorTotalDescontos",
                                          name: "valorTotalDescontos",
                                          disabled: _vm.disabled,
                                        },
                                        model: {
                                          value:
                                            _vm.model.notaFiscalEntradaTotalICMS
                                              .valorDesc,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.model
                                                .notaFiscalEntradaTotalICMS,
                                              "valorDesc",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "model.notaFiscalEntradaTotalICMS.valorDesc",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "col-sm-3" }, [
                                  _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "tooltip",
                                          rawName: "v-tooltip",
                                          value: _vm.errors.first("valorTotal"),
                                          expression:
                                            "errors.first('valorTotal')",
                                        },
                                      ],
                                      staticClass: "form-group",
                                    },
                                    [
                                      _c(
                                        "label",
                                        {
                                          staticClass: "control-label",
                                          attrs: { for: "valorTotal" },
                                        },
                                        [
                                          _vm._v(
                                            "\n                                        " +
                                              _vm._s(
                                                _vm.$t(
                                                  "__.Components.parent.notafiscalentrada.edit_vue_html.vlrTotalFaturado"
                                                )
                                              ) +
                                              "\n                                    "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("moeda-component", {
                                        directives: [
                                          {
                                            name: "mask-moeda-decimal",
                                            rawName: "v-mask-moeda-decimal",
                                          },
                                          {
                                            name: "validate",
                                            rawName: "v-validate",
                                            value: "required|maior_que_zero",
                                            expression:
                                              "'required|maior_que_zero'",
                                          },
                                        ],
                                        staticClass: "form-control",
                                        attrs: {
                                          id: "valorTotal",
                                          name: "valorTotal",
                                          disabled: _vm.disabled,
                                        },
                                        model: {
                                          value: _vm.model.valorTotal,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.model,
                                              "valorTotal",
                                              $$v
                                            )
                                          },
                                          expression: "model.valorTotal",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                              ]),
                            ],
                      ]
                    : _vm._e(),
                ],
                2
              ),
            ]
          ),
          _vm._v(" "),
          !_vm.isModal
            ? _c("fieldset", [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-sm-12 newbtn" }, [
                    _c("div", { staticClass: "form-group" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-success",
                          attrs: { type: "submit" },
                        },
                        [
                          _c("i", {
                            staticClass: "fa fa-save",
                            attrs: { "aria-hidden": "true" },
                          }),
                          _vm._v(
                            "\n                            " +
                              _vm._s(_vm.$t("__.Crud.salvar")) +
                              "\n                        "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-secondary",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              _vm.load()
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n                            " +
                              _vm._s(_vm.$t("__.Crud.limpar")) +
                              "\n                        "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-secondary",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              _vm.cancel()
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n                            " +
                              _vm._s(_vm.$t("__.Crud.voltar")) +
                              "\n                        "
                          ),
                        ]
                      ),
                    ]),
                  ]),
                ]),
              ])
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c("imprimirRotuloPersonalizadoComponent", {
        ref: "imprimirRotuloPersonalizadoComponent",
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }