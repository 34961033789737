export default class XmlService {
    public downloadXML(link: string) {
        const http = new XMLHttpRequest();
        http.open("GET", link, false);
        http.send();

        const xmlFileName = http.getResponseHeader("Filename");
        const element = document.createElement('a');
        element.setAttribute('href', 'data:Application/octet-stream,' + encodeURIComponent(http.responseText));
        element.setAttribute('download', xmlFileName);

        element.style.display = 'none';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
    }
}