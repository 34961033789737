import Vue from "vue";
import { mapState, mapMutations, mapGetters } from "vuex";

import ImpressaoComponent from "@/components/child/impressao/impressaoComponent";
import impressaoComponent from "@/components/child/impressao/impressaoComponent.vue";
import loadingModalComponent from "@/components/child/loadingmodal/loadingmodal.vue";
import { Component } from "@/decorators";
import PreLoadPackModel from "@/models/auxiliar/preLoadPackModel";
import DestinoManipulacao from "@/models/enum/destinosManipulacao";
import ManipulacaoModel, { StatusEstoqueManipulacao } from "@/models/manipulacaoOrdemModel";
import ManipulacaoOrdemModel from "@/models/manipulacaoOrdemModel";
import ProdutoEstoqueConferenciaModel from "@/models/produtoEstoqueConferenciaModel";
import ManipulacaoService from "@/services/manipulacaoOrdemService";
import ReceitaArquivoService from "@/services/receitaArquivoService";
import { FastActionLastPack, FastActionList, FastActionPack } from "@/store/fastActionStore";
import { AppState, Getters, Mutations } from "@/store/store";
import Delay from "@/utils/common/delay";

import LoteItemComponent from "./../../../manipulacao/loteItem";
import loteItemComponent from "./../../../manipulacao/loteItem.vue";
import ShortcutComponent from "./../../../shortcut/shortcut";
import shortcutComponent from "./../../../shortcut/shortcut.vue";
import ManipulacaoListComponent from "./list";
import manipulacaoListComponent from "./list.vue";

import "./../fastactionsgrid.scss";

@Component({
    components: {
        loadingModalComponent,
        shortcutComponent,
        loteItemComponent,
        manipulacaoListComponent,
        impressaoComponent,
    },
    computed: {
        ...mapState({
            fastActionLists: (state: AppState) => state.fastAction.fastActionLists,
            fastActionPack: (state: AppState) => state.fastAction.fastActionPack,
            preLoadPack: (state: AppState) => state.preLoad.preLoadList,
            loadedList: (state: AppState) => state.preLoad.loadedList,
        }),
        ...mapGetters(["VALIDAR_PERMISSAO_USUARIO"] as Getters),
    },
    methods: {
        ...mapMutations(["SET_LISTSPACK", "SET_LASTCOUNTPACK", "LOAD_COUNTPACK", "CLEAR_LISTVENDA"] as Mutations),
    },
})
export default class FastActionManipulacaoPanelComponent extends Vue {
    // State computed props
    preLoadPack: PreLoadPackModel;
    loadedList: boolean;
    fastActionLists: FastActionList;
    fastActionPack: FastActionPack;
    SET_LISTSPACK: (data: FastActionList) => void;
    SET_LASTCOUNTPACK: (data: FastActionLastPack) => void;
    LOAD_COUNTPACK: () => void;
    CLEAR_LISTVENDA: () => void;
    VALIDAR_PERMISSAO_USUARIO: (telaDescricao: string, acaoController: string) => boolean;

    private manipulacoes: Array<ManipulacaoModel> = [];
    private shortCutLote: ShortcutComponent = null;

    private shortCutList: ShortcutComponent = null;
    private loteItemComponent: LoteItemComponent = null;
    private manipulacaoListComponent: ManipulacaoListComponent = null;
    private impressaoComponent: ImpressaoComponent = null;

    private service = new ManipulacaoService();
    private receitaArquivoService = new ReceitaArquivoService();

    private preloadPack: PreLoadPackModel = null;

    private loadLoteItemComponent = false;
    private loadManipulacaoListComponent = false;

    private model: ManipulacaoModel = null;

    unidadeMedidasOptions: Array<Object> = [];

    private getClass(manipulacao: ManipulacaoModel) {
        manipulacao.destino == DestinoManipulacao.Venda;
        switch (manipulacao.statusEstoque) {
            case StatusEstoqueManipulacao.NaoVerificado:
                return "";
            case StatusEstoqueManipulacao.Divergente:
                return "ExcRed";
            case StatusEstoqueManipulacao.Conferido:
                return "ExcGreen";
            default:
                return "";
        }
    }

    private getTitle(manipulacao: ManipulacaoModel) {
        switch (manipulacao.statusEstoque) {
            case StatusEstoqueManipulacao.NaoVerificado:
                return this.$t("__.ts.estqNaoVerif") as string;
            case StatusEstoqueManipulacao.Divergente:
                return this.$t("__.ts.estqQtdBaixaDiverg") as string;
            case StatusEstoqueManipulacao.Conferido:
                return this.$t("__.ts.estqVerif") as string;
            default:
                return this.$t("__.ts.estqAindaNaoVerif") as string;
        }
    }

    private async onVerMais() {
        this.model = null;
        this.loadManipulacaoListComponent = true;

        while (!this.$refs.shortCutList) await Delay(5);
        this.shortCutList = this.$refs.shortCutList as ShortcutComponent;

        while (!this.$refs.manipulacaoListComponent) await Delay(5);
        this.manipulacaoListComponent = this.$refs.manipulacaoListComponent as ManipulacaoListComponent;

        this.shortCutList.title = this.$t("__.ts.manipulacoes") as string;
        this.shortCutList.show();
    }

    private onSearch(manipulacao: ManipulacaoModel) {
        this.$router.push("/manipulacaoordem-edicao/" + manipulacao.id);
    }

    private async onLote(manipulacao: ManipulacaoModel) {
        this.loadLoteItemComponent = true;
        this.model = null;
        await this.load(manipulacao.id);

        if (manipulacao.statusEstoque == StatusEstoqueManipulacao.NaoVerificado || manipulacao.statusEstoque == null) {
            this.conferirEstoque(this.model);
        }

        //a priveira vez instancia o shortcut e loteItem component
        while (!this.$refs.shortCutLote) await Delay(5);
        this.shortCutLote = this.$refs.shortCutLote as ShortcutComponent;

        while (!this.$refs.loteItemComponent) await Delay(5);
        this.loteItemComponent = this.$refs.loteItemComponent as LoteItemComponent;

        this.shortCutLote.title = this.$t("__.ts.lotesEstqManipul") as string;
        this.loteItemComponent.loadModel(null, this.model.movimentacaoEstoqueId, this.model.id, true);

        this.shortCutLote.show();
    }

    private async onConfirmLote() {
        if (await this.loteItemComponent.atualizarDadosLotes()) {
            this.shortCutLote.hide();
        }

        if (this.model != null) {
            await this.conferirEstoque(this.model).withLoading();
        }
    }

    private async loadManipulacoes(forceLoad = false) {
        if (this.fastActionLists.manipulacoes.length > 0 && !forceLoad) {
            this.manipulacoes = this.fastActionLists.manipulacoes;
        } else {
            //salva as conferidas para nao atualizar a tela sem refazer conferencia de estoque
            let manipulacoesConferidas = new Array<ManipulacaoModel>();
            if (this.manipulacoes.length > 0) {
                manipulacoesConferidas = this.manipulacoes.filter(
                    p => p.statusEstoque != StatusEstoqueManipulacao.NaoVerificado,
                );
            }
            try {
                this.manipulacoes = await this.service.listManipulacaoHome().resolveWithJSON<ManipulacaoModel[]>();

                //atualiza as manipulacoes com os status das conferidas
                if (manipulacoesConferidas.length > 0) {
                    for (let i = 0; i < manipulacoesConferidas.length; i++) {
                        if (this.manipulacoes.some(p => p.id == manipulacoesConferidas[i].id)) {
                            this.manipulacoes.find(p => p.id == manipulacoesConferidas[i].id).statusEstoque =
                                manipulacoesConferidas[i].statusEstoque;
                        }
                    }
                }
                this.SET_LISTSPACK({ manipulacoesAConcluir: this.manipulacoes });
            } catch {}
        }
    }

    private async load(id = 0) {
        this.model = new ManipulacaoModel();

        if (id > 0) {
            try {
                const data = await this.service.get(id).withLoading().resolveWithJSON<ManipulacaoModel>();
                this.model.updateFrom(data);
            } catch {}
        }
    }

    public async onImprimirRotulo(manipulacao: ManipulacaoOrdemModel) {
        await this.impressaoComponent
            .show({
                modelId: manipulacao.id,
                tipoImpressao: "Manipulacao",
            })
            .withLoading();
    }

    public async onExibirReceita(manipulacao: ManipulacaoOrdemModel) {
        await this.receitaArquivoService.showFiles(manipulacao.receitas);
    }

    public async onImprimirManipulacaoModelo(manipulacao: ManipulacaoOrdemModel) {
        await this.impressaoComponent
            .show({
                modelId: manipulacao.id,
                tipoImpressao: "Manipulacao",
                impressaoRotulo: false,
                impressaoSelecionarModelo: true,
            })
            .withLoading();
    }

    private async onConcluirManipulacao(manipulacao: ManipulacaoModel) {
        if (this.model == null || this.model.id != manipulacao.id) {
            await this.load(manipulacao.id);
        }

        const conferidoOk = await this.conferirEstoque(this.model);

        if (conferidoOk) {
            try {
                const sucesso = await this.service.Concluir(this.model).withLoading().resolveWithoutJSON();
                if (sucesso) {
                    await this.$showSuccess(this.$t("__.ts.concl"), this.$t("__.ts.manipuConclSucesso"));

                    if (this.loadManipulacaoListComponent && this.shortCutList.isShowing()) {
                        if (this.manipulacaoListComponent != null) {
                            this.manipulacaoListComponent.load();
                        }
                    }
                    this.loadManipulacoes(true);
                    //diminui 1 manipucao
                    const manipulacoesAConcluir = Number(this.fastActionPack.countPack.manipulacoesAConcluir) - 1;
                    //limpa vendas para refazer ao entrar nelas caso tenha alterado algo
                    this.CLEAR_LISTVENDA();
                    this.SET_LASTCOUNTPACK({ manipulacoesAConcluir });
                    this.LOAD_COUNTPACK();
                    //pode ser que aqui aumente a venda, se tds manipulacoes da venda estiverem concluidas
                    //setar a venda para false para forcar o reload e refazer o count principal
                    //todo: verificacao da venda desta manipulacao apenas
                }
            } catch {}
        } else {
            const msg = this.$t("__.ts.manipPossuiDiverg") as string;
            const question = this.$t("__.ts.desejaConfLotes") as string;

            const response = await this.$showQuestion(this.$t("__.ts.atencao"), msg + question);
            if (response) {
                await this.onLote(manipulacao);
            }
        }
    }

    private async conferirEstoque(manipulacao: ManipulacaoModel) {
        let problemasEstoque = false;
        const dataVerificados = await new ProdutoEstoqueConferenciaModel().GetProdutosEstoquesMovimentosVerificados(
            manipulacao.movimentacaoEstoqueId,
            manipulacao.id,
        );
        //se quanlquer item possuir restante maior que zero, deve atribuir que o estoque não esta conferido
        for (let i = 0; i < dataVerificados.length; i++) {
            if (this.getRestante(dataVerificados[i]) > 0) {
                manipulacao.statusEstoque = StatusEstoqueManipulacao.Divergente;

                if (this.manipulacoes.some(p => p.id == manipulacao.id)) {
                    this.manipulacoes.find(p => p.id == manipulacao.id).statusEstoque = manipulacao.statusEstoque;
                }

                this.$forceUpdate();
                problemasEstoque = true;
                break;
            }
        }
        if (!problemasEstoque) {
            manipulacao.statusEstoque = StatusEstoqueManipulacao.Conferido;
            if (this.manipulacoes.some(p => p.id == manipulacao.id)) {
                this.manipulacoes.find(p => p.id == manipulacao.id).statusEstoque = manipulacao.statusEstoque;
            }

            this.$forceUpdate();
        }
        return !problemasEstoque;
    }

    private getRestante(item: ProdutoEstoqueConferenciaModel) {
        if (item.ignorarControleEstoque) {
            return 0;
        }

        const quantidadeProduto = item.quantidade * item.indiceConversao;
        const baixas = item.produtoLotes.length > 0 ? this.getNumSomaBaixas(item) : 0;
        return parseFloat((quantidadeProduto - baixas).toFixed(4));
    }

    private getNumSomaBaixas(item: ProdutoEstoqueConferenciaModel) {
        return item.produtoLotes.map(p => p.quantidadeBaixa).reduce((p, acc) => acc + p, 0);
    }

    private getVerificados() {
        return this.manipulacoes.filter(
            p => p.statusEstoque != null && p.statusEstoque != StatusEstoqueManipulacao.NaoVerificado,
        ).length;
    }

    private onConfirmList() {
        this.shortCutList.hide();
    }

    private async getPreLoadPack() {
        while (!this.loadedList) await Delay(100);

        this.unidadeMedidasOptions = this.preloadPack.unidadeMedidaCombo();
    }

    private validarVisibilidadeBotao(acao: string) {
        return this.VALIDAR_PERMISSAO_USUARIO("manipulacoes_venda", acao);
    }

    private mounted() {
        this.shortCutLote = this.$refs.shortCutLote as ShortcutComponent;
        this.impressaoComponent = this.$refs.impressaoComponent as ImpressaoComponent;

        Promise.all([this.getPreLoadPack(), this.loadManipulacoes(false)])
            .then(() => {
                this.SET_LASTCOUNTPACK({
                    manipulacoesAConcluir: Number(this.fastActionPack.countPack.manipulacoesAConcluir),
                });
            })
            .catch(() => {});
    }
}
