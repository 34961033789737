import { getPaginationParams } from "@/utils/common/http";
import nfetch from "@/utils/events/nfetch";

export default class VendaExpedicaoService {
    public listExpedicaoPagination(
        filterKey: string,
        sortKey: string,
        sortOrder: string,
        pageIndex: number,
        pageSize: number,
        setor: number,
    ): Promise<Response> {
        const pagination = getPaginationParams(filterKey, sortKey, sortOrder, pageIndex, pageSize);

        return nfetch(`/VendaExpedicao/ListExpedicaoPagination?${pagination}&setor=${setor}`, {
            credentials: "same-origin",
        });
    }

    public listExpedicaoHome(): Promise<Response> {
        return nfetch("/VendaExpedicao/ListExpedicaoHome", { credentials: "same-origin" });
    }
}
