import BaseModel from "../base/baseModel";
import Ufs from "../enum/ufs";

import ClinicaPrescritorModel from "./clinicaPrescritorModel";

export default class ClinicaModel extends BaseModel {
    constructor() {
        super();
    }

    id?: number = 0;
    nome?: string = "";
    nomeFantasia?: string = "";
    dataCriacao?: Date = new Date();
    razaoSocial?: string = "";
    cnpj?: string = "";
    inscricaoEstadual?: string = "";
    inscricaoMunicipal?: string = "";
    observacao?: string = "";
    cep?: string = "";
    estadoId?: Ufs;
    cidadeId?: number = 0;
    cidade?: string = "";
    endereco?: string = "";
    bairro?: string = "";
    numero?: string = "";
    complemento?: string = "";
    telefone?: string = "";
    celular?: string = "";
    email?: string = "";
    pontuarDotz?: boolean = false;
    fanquiaId?: number = 0;
    prescritorId?: number = 0;
    prescritores?: Array<ClinicaPrescritorModel> = [];
}
