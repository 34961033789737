var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "produto-associacao-component" },
        [
          _c(
            "modal-component",
            {
              ref: "modalComponent",
              attrs: {
                "modal-class": "lg-modal",
                title: _vm.codigo + " - " + _vm.produtoNome,
              },
              on: { "btn-ok-click": _vm.onBtnOkClick },
            },
            [
              _c(
                "div",
                { attrs: { slot: "body" }, slot: "body" },
                [
                  _c(
                    "grid-component",
                    {
                      attrs: {
                        data: _vm.gridData,
                        columns: _vm.gridColumns,
                        "show-select-checkbox": true,
                        "multiple-select": false,
                        "show-action-column": false,
                        showAddNewButton: false,
                        "page-index": _vm.pageIndex,
                        "page-size": _vm.pageSize,
                        total: _vm.total,
                      },
                      on: {
                        "change-filter-key": _vm.onChangeFilterKey,
                        "change-sort": _vm.onChangeSort,
                        "change-page": _vm.onChangePage,
                        "selected-values-changed": _vm.onSelectedValuesChanged,
                      },
                    },
                    [
                      _c("div", { attrs: { slot: "tools" }, slot: "tools" }, [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-primary",
                            attrs: { type: "button" },
                            on: { click: _vm.onBtnCriarProdutoClick },
                          },
                          [
                            _vm._v(
                              "\n                            " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Components.parent.importacaonfe.produtoassociacao_vue_html.addNovo"
                                  )
                                ) +
                                "\n                            "
                            ),
                            _c("i", {
                              staticClass: "fa fa-right fa-plus-circle",
                              attrs: { "aria-hidden": "true" },
                            }),
                          ]
                        ),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "modal-component",
        {
          ref: "cadastroProdutoModalComponent",
          attrs: { "modal-class": "lg-modal", title: "Cadastrar Produto" },
          on: { "btn-ok-click": _vm.onProdutoBtnOkClick },
        },
        [
          _c(
            "div",
            { attrs: { slot: "body" }, slot: "body" },
            [
              _c("produtoEditComponent", {
                ref: "produtoEditComponent",
                attrs: { "is-modal": true },
                on: { "save-ok": _vm.onProdutoSaveOk },
              }),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }