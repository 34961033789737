import { mapGetters, mapMutations, mapState } from "vuex";

import comboComponent from "@/components/child/form/combo.vue";
import dateTimePickerComponent from "@/components/child/form/datetimepicker.vue";
import gridComponent from "@/components/child/grid/grid.vue";
import { GridAction } from "@/components/child/grid/gridAction";
import { GridColors, GridColumn, GridColumnSearchColor, GridColumnType } from "@/components/child/grid/gridColumn";
import ImpressaoComponent from "@/components/child/impressao/impressaoComponent";
import impressaoComponent from "@/components/child/impressao/impressaoComponent.vue";
import { Component, Prop, Watch } from "@/decorators";
import PreLoadPackModel from "@/models/auxiliar/preLoadPackModel";
import SituacoesVenda from "@/models/enum/situacoesVenda";
import TipoBuscaVenda from "@/models/enum/tipoBuscaVenda";
import ManipulacaoOrdemModel from "@/models/manipulacaoOrdemModel";
import PaginationModel from "@/models/paginationModel";
import UsuarioModel from "@/models/usuarioModel";
import VendaModel, { VendaListParameters } from "@/models/vendaModel";
import OrcamentoService from "@/services/utils/orcamentoService";
import WhatsAppUtilsService from "@/services/utils/whatsAppUtilsService";
import VendaService from "@/services/vendaService";
import { FastActionLastPack, FastActionPack } from "@/store/fastActionStore";
import { AppState, Getters, Mutations } from "@/store/store";
import { convertToDateInput, convertToDateInputString } from "@/utils/common/date";
import Delay from "@/utils/common/delay";

import ListComponentBase from "../crud/listComponentBase";

import ShortcutComponent from "./../shortcut/shortcut";
import shortcutComponent from "./../shortcut/shortcut.vue";
import ArquivarOrcamentoComponent from "./arquivarOrcamento";
import arquivarOrcamentoComponent from "./arquivarOrcamento.vue";
import RevisaoCamposComponent from "./revisaoCampos";
import revisaoCamposComponent from "./revisaoCampos.vue";

import "./list.scss";

@Component({
    components: {
        gridComponent,
        shortcutComponent,
        dateTimePickerComponent,
        revisaoCamposComponent,
        comboComponent,
        impressaoComponent,
        arquivarOrcamentoComponent,
    },
    computed: {
        ...mapState({
            fastActionPack: (state: AppState) => state.fastAction.fastActionPack,
            preLoadList: (state: AppState) => state.preLoad.preLoadList,
            loadingPack: (state: AppState) => state.paginationStatus.loading,
            paginationParams: (state: AppState) => state.paginationStatus.paginationParams,
            usuarioLogado: (state: AppState) => state.session.usuarioLogado,
        }),
        ...mapGetters(["VALIDAR_PERMISSAO_USUARIO", "GET_IS_FRACIONAMENTO", "GET_IS_ESTEREIS"] as Getters),
    },
    methods: mapMutations([
        "SET_COUNTPACK",
        "SET_LASTCOUNTPACK",
        "CLEAR_LISTSPACK",
        "SET_PARAMS",
        "GET_PARAMS",
    ] as Mutations),
})
export default class OrcamentoListComponent extends ListComponentBase<VendaModel> {
    // State computed props
    usuarioLogado: UsuarioModel;
    preLoadList: PreLoadPackModel;
    fastActionPack: FastActionPack;
    SET_COUNTPACK: (data: FastActionLastPack) => void;
    SET_LASTCOUNTPACK: (data: FastActionLastPack) => void;
    CLEAR_LISTSPACK: () => void;
    GET_IS_FRACIONAMENTO: () => Promise<boolean>;
    GET_IS_ESTEREIS: () => Promise<boolean>;
    VALIDAR_PERMISSAO_USUARIO: (telaDescricao: string, acaoController: string) => boolean;

    @Prop({ type: Boolean, default: false }) CalledByShortCut: boolean;
    @Prop({ type: Number, default: 20 }) pgSize: number;
    @Prop({ type: String, default: "codigo" }) sortKey: string;
    @Prop({ type: String, default: "desc" }) sortOrder: string;

    private orcamentoService = new OrcamentoService();
    private whatsAppUtilsService = new WhatsAppUtilsService();
    private vendaService = new VendaService();

    private impressaoComponent: ImpressaoComponent = null;
    private shortCutRevisaoCampos: ShortcutComponent = null;
    private arquivarOrcamentoComponent: ArquivarOrcamentoComponent = null;
    private revisaoCamposComponent: RevisaoCamposComponent = null;

    extraActions: Array<object> = [];
    gridExtraActionsVisualizar: Array<GridAction> = [];
    gridExtraActionsAdiar: Array<GridAction> = [];
    gridExtraActionsArquivar: Array<GridAction> = [];
    gridExtraActionsConfirmar: Array<GridAction> = [];
    gridExtraActionsImprimir: Array<GridAction> = [];
    gridExtraActionsWhatsapp: Array<GridAction> = [];
    produtosOptions: Array<Object> = [];

    private model: VendaModel = null;

    private loadRevisaoComponent = false;

    isFracionamento = false;
    isEstereis = false;

    usuariosOptions: Array<Object> = [];
    tipoBuscaVendaOptions: Array<Object> = [
        { text: "Codigo da Venda", value: TipoBuscaVenda.Venda },
        { text: "Cliente", value: TipoBuscaVenda.Cliente },
        { text: "Manipulacao", value: TipoBuscaVenda.Manipulacao },
        { text: "Paciente", value: TipoBuscaVenda.Paciente },
    ];

    isHidden = false;
    filtro: Array<Object> = [];
    radioFilter = 0;
    dataFilter1: Date = null;
    dataFilter2: Date = null;
    tipoBuscaVenda = 0;
    vendedor = null;
    produto = null;

    get gridColumns(): Array<GridColumnSearchColor> {
        const columns = [];
        columns.push(new GridColumnSearchColor("codigo", "Código", GridColumnType.String));
        columns.push(new GridColumnSearchColor("situacaoDescricao", "Situação", GridColumnType.String, false));
        columns.push(new GridColumnSearchColor("clienteNome", "Cliente", GridColumnType.String));
        if (!this.isFracionamento) {
            columns.push(new GridColumnSearchColor("vendedor", "Vendedor", GridColumnType.String));
        }
        columns.push(new GridColumnSearchColor("dataInclusao", "Data", GridColumnType.Date));
        columns.push(new GridColumnSearchColor("dataValidadeOrcamento", "Validade", GridColumnType.DateTime));
        if (this.isFracionamento || this.isEstereis) {
            columns.push(new GridColumnSearchColor("setorDescricao", "Setor", GridColumnType.String));
        }
        columns.push(new GridColumnSearchColor("valorFinal", "Valor", GridColumnType.Money));
        return columns;
    }

    get gridColumnsVinculos(): Array<GridColumn> {
        return [
            new GridColumn("produtoDescricao", "Produto", GridColumnType.String),
            new GridColumn("continuo", "Continuo", GridColumnType.Boolean),
            new GridColumn("quantidade", "quantidade", GridColumnType.DecimalPonto),
            new GridColumn("valorUnitario", "Valor Unitário", GridColumnType.Money),
            new GridColumn("valorTotal", "Valor Total", GridColumnType.Money),
            new GridColumn("dataValidade", "Data Validade", GridColumnType.Date, false, this.isFracionamento),
        ];
    }

    private beforeMount() {
        this.setProps(new VendaService(), "venda", "codigo", false, "desc");
    }

    public async afterMounted() {
        this.shortCutRevisaoCampos = this.$refs.shortCutRevisaoCampos as ShortcutComponent;
        this.impressaoComponent = this.$refs.impressaoComponent as ImpressaoComponent;
        this.usuariosOptions = this.preLoadList.vendedoresCombo();
        this.produtosOptions = this.preLoadList.produtosCombo();

        if (this.CalledByShortCut) {
            this.pageSize = this.pgSize;
            this.gridSortKey = this.sortKey;
            this.gridSortOrder = this.sortOrder;
        }

        this.isFracionamento = await this.GET_IS_FRACIONAMENTO();
        this.isEstereis = await this.GET_IS_ESTEREIS();
    }

    public async loadMethod() {
        const customParameters: VendaListParameters = {
            dataInicial: convertToDateInputString(this.filtro[0]),
            dataFinal: convertToDateInputString(this.filtro[1], true),
            radio: this.radioFilter > -1 ? this.radioFilter.toString() : "",
            tipoBuscaVenda: this.tipoBuscaVenda,
            vendedor: this.vendedor,
            produto: this.produto,
        };

        this.pageIndex = this.gridFilterKey !== this.filterKey ? 1 : this.pageIndex;
        this.filterKey = this.gridFilterKey;

        this.isHidden = false;

        this.gridData["dataDe"] = this.filtro[0] ? this.filtro[0].toString() : " ";
        this.gridData["dataAte"] = this.filtro[1] ? this.filtro[1].toString() : " ";
        this.gridData["status"] = this.filtro[2] ? this.filtro[2].toString() : " ";

        return await this.vendaService
            .listOrcamento(
                this.gridFilterKey,
                this.gridSortKey,
                this.gridSortOrder,
                this.pags ? 1 : this.pageIndex,
                this.pags ? 999999 : this.pageSize,
                customParameters,
            )
            .withLoading()
            .resolveWithJSON<PaginationModel<VendaModel>>();
    }

    public async afterLoad() {
        this.gridExtraActionsVisualizar.splice(0);
        this.gridExtraActionsAdiar.splice(0);
        this.gridExtraActionsArquivar.splice(0);
        this.gridExtraActionsConfirmar.splice(0);
        this.extraActions.splice(0);
        this.gridExtraActionsImprimir.splice(0);
        this.gridExtraActionsWhatsapp.splice(0);

        for (let i = 0; i < this.gridData.length; i++) {
            if (this.VALIDAR_PERMISSAO_USUARIO("vendas", "ImprimirOrcamento")) {
                this.gridExtraActionsImprimir.push(
                    new GridAction("imprimir-orcamento", "Imprimir Orçamento.", "fa fa-print", GridColors.DARKGRAY),
                );
            }

            this.gridExtraActionsWhatsapp.push(
                new GridAction(
                    "compartilhar-orçamento",
                    "Compartilhar Orçamento",
                    "fab fa-whatsapp fa-2x",
                    GridColors.GREEN,
                ),
            );

            this.gridExtraActionsVisualizar.push(
                new GridAction(
                    "visualizar-orcamento",
                    "Visualizar/Alterar Orçamento",
                    this.gridData[i].situacao == SituacoesVenda.Orcamento ? "fa fa-edit" : "fa fa-search",
                    GridColors.BLUE,
                ),
            );

            if (this.VALIDAR_PERMISSAO_USUARIO("vendas", "AdiarVenda")) {
                this.gridExtraActionsAdiar.push(
                    new GridAction(
                        "adiar-orcamento",
                        "Adiar validade deste Orçamento",
                        "fa fa-clock",
                        GridColors.RED,
                        this.gridData[i].situacao == SituacoesVenda.Orcamento,
                    ),
                );
            }

            if (this.VALIDAR_PERMISSAO_USUARIO("vendas", "ArquivarVenda")) {
                this.gridExtraActionsArquivar.push(
                    new GridAction(
                        "arquivar-orcamento",
                        "Arquivar/rejeitar Orçamento",
                        "fa fa-archive",
                        GridColors.ORANGE,
                        this.gridData[i].situacao == SituacoesVenda.Orcamento,
                    ),
                );
            }

            if (this.VALIDAR_PERMISSAO_USUARIO("vendas", "AprovarOrcamento")) {
                this.gridExtraActionsConfirmar.push(
                    new GridAction("aprovar-orcamento", "Aprovar Orçamento.", "fa fa-check", GridColors.GREEN),
                );
            }
        }

        this.extraActions.push(this.gridExtraActionsWhatsapp);
        this.extraActions.push(this.gridExtraActionsImprimir);
        this.extraActions.push(this.gridExtraActionsArquivar);
        this.extraActions.push(this.gridExtraActionsAdiar);
        this.extraActions.push(this.gridExtraActionsVisualizar);
        this.extraActions.push(this.gridExtraActionsConfirmar);
    }

    private onExtraAction(name: string, model: VendaModel) {
        if (name.trim() == "visualizar-orcamento") {
            this.$router.push("/venda-edicao/" + model.id);
        } else if (name.trim() == "arquivar-orcamento") {
            this.onArquivar(model);
        } else if (name.trim() == "adiar-orcamento") {
            this.onAdiarVencimento(model);
        } else if (name.trim() == "aprovar-orcamento") {
            this.onAprovarOrcamento(model);
        } else if (name.trim() == "imprimir-orcamento") {
            this.onImprimirOrcamento(model);
        } else if (name.trim() == "compartilhar-orçamento") {
            this.onCompartilharOrcamento(model);
        }
    }

    private getFormValues(listaFiltro) {
        this.filtro = [];

        this.filtro.push(listaFiltro.target.elements.dataFilter1.value);
        this.filtro.push(listaFiltro.target.elements.dataFilter2.value);
        this.filtro.push(listaFiltro.target.elements.optradio.value);

        this.load();
    }

    private async onCompartilharOrcamento(orcamento: VendaModel) {
        const complemento = this.usuarioLogado.franquia.complementoWhatsAppOrcamento;
        const model = await this.vendaService.get(orcamento.id).resolveWithJSON<VendaModel>().withLoading();
        const msgWhats = this.whatsAppUtilsService.getMensagemWhats(model, complemento);
        this.whatsAppUtilsService.showMensagemWhats(model.clienteTelefone, msgWhats);
    }

    private async onAdiarVencimento(orcamento: VendaModel) {
        const sucesso = await this.orcamentoService.adiar(orcamento);
        if (sucesso) {
            this.load();
        }
    }

    private async onArquivar(orcamento: VendaModel) {
        while (!this.$refs.arquivarOrcamentoComponent) await Delay(50);
        this.arquivarOrcamentoComponent = this.$refs.arquivarOrcamentoComponent as ArquivarOrcamentoComponent;
        this.arquivarOrcamentoComponent.show(orcamento.id);
    }

    private onOrcamentoArquivado() {
        this.load();
    }

    private async onAprovarOrcamento(orcamento: VendaModel) {
        this.model = null;
        await this.loadModel(orcamento.id);

        if (this.model != null) {
            const itensManipulacao = this.model.itens.filter(p => p.manipulacaoOrdem != null);

            const manipulacoesRevisar = itensManipulacao
                .map(p => p.manipulacaoOrdem)
                .filter(
                    p =>
                        (p.posologiaId == null && !p.posologiaTexto) ||
                        p.pacienteId == null ||
                        p.prescritorId == null ||
                        (p.possuiAtivoControlado && p.numeroReceita == null),
                );

            if (itensManipulacao.length > 0) {
                const openModal = await this.revisao(manipulacoesRevisar).withLoading();
                if (!openModal) {
                    if (await this.orcamentoService.aprovarSalvarVenda(this.model, this.isFracionamento)) {
                        await this.save().withLoading();
                    }
                }
            } else {
                if (await this.orcamentoService.aprovarSalvarVenda(this.model, this.isFracionamento)) {
                    await this.save().withLoading();
                }
                this.load();
            }
        }
    }

    public async onImprimirOrcamento(orcamento: VendaModel) {
        await this.orcamentoService.onImprimirOrcamento(orcamento);
    }

    private async revisao(manipulacoesRevisar: Array<ManipulacaoOrdemModel>) {
        this.shortCutRevisaoCampos.title = this.$t("__.ts.revPreenchCampos") as string;

        this.loadRevisaoComponent = true;
        while (!this.$refs.revisaoCamposComponent) await Delay(50);
        this.revisaoCamposComponent = this.$refs.revisaoCamposComponent as RevisaoCamposComponent;

        let openModal = manipulacoesRevisar.length > 0;
        if (!openModal) {
            const dataEntregaObrigatoria = this.revisaoCamposComponent.getDataEntregaObrigatoria();
            if (dataEntregaObrigatoria && !this.model.dataEntrega) {
                openModal = true;
            }
        }

        if (openModal) {
            this.revisaoCamposComponent.setPreLoadPack(this.preLoadList);
            this.revisaoCamposComponent.loadModels(manipulacoesRevisar, this.model);

            this.shortCutRevisaoCampos.show();
        }

        return openModal;
    }

    private async onConfirmRevisao() {
        try {
            const validou = await this.revisaoCamposComponent.validaRevisaoCampos();
            if (validou) {
                this.shortCutRevisaoCampos.hide();

                if (await this.orcamentoService.aprovarSalvarVenda(this.model, this.isFracionamento)) {
                    await this.save().withLoading();
                }
            }
        } catch {}
    }

    private async save() {
        try {
            const response = await this.vendaService.update(this.model).withLoading().resolveWithResponse();

            let codigo = Number(response.headers.get("Codigo"));
            if (this.model.codigo > 0 && !codigo) {
                codigo = this.model.codigo;
            }

            const orcamentos = Number(this.fastActionPack.countPack.orcamentos) - 1;
            this.SET_COUNTPACK({ orcamentos });
            this.SET_LASTCOUNTPACK({ orcamentos });
            this.CLEAR_LISTSPACK();

            const quantidadeManipulacoes = this.model.itens.filter(p => p.manipulacaoOrdem != null).length;
            if (quantidadeManipulacoes > 0) {
                const manipulacoesAConcluir =
                    Number(this.fastActionPack.countPack.manipulacoesAConcluir) + quantidadeManipulacoes;
                this.SET_COUNTPACK({ manipulacoesAConcluir });
            }

            const tituloMensagem =
                this.model.situacao == SituacoesVenda.Orcamento ? this.$t("__.ts.orcamento") : this.$t("__.ts.venda");

            await this.$showSuccess(
                tituloMensagem + (this.$t("__.ts.codzin") as string) + codigo,
                this.$t("__.ts.salvoSucess"),
            );

            if (this.model.situacao != SituacoesVenda.Orcamento) {
                this.onImprimir(this.model);
                this.load();
            }
        } catch {
            await this.orcamentoService.updateDadosAprovarOrcamento(this.model);
        }
    }

    private async loadModel(id = 0) {
        this.model = new VendaModel();

        if (id > 0) {
            try {
                const data = await this.vendaService.get(id).withLoading().resolveWithJSON<VendaModel>();
                this.model.updateFrom(data);
            } catch {}
        }
    }

    @Watch("tipoBuscaVenda")
    private onTipoBuscaVenda() {
        this.load();
    }

    public async afterLoadPagination() {
        if (this.paginationParams.filtros != null) {
            this.filtro = this.paginationParams.filtros;
            if (this.filtro.length >= 0) {
                if (this.filtro[0]) {
                    this.dataFilter1 = convertToDateInput(this.filtro[0].toString());
                }
                if (this.filtro.length >= 1) {
                    if (this.filtro[1]) {
                        this.dataFilter2 = convertToDateInput(this.filtro[1].toString());
                    }
                }

                if (this.filtro.length >= 2) {
                    this.radioFilter = <number>this.filtro[2];
                }
            }
        }
    }

    private async onImprimir(venda: VendaModel) {
        if (this.VALIDAR_PERMISSAO_USUARIO("vendas", "ImprimirVenda")) {
            await this.impressaoComponent
                .show({
                    modelId: venda.id,
                    tipoImpressao: "Venda",
                    impressaoTodosModelo: true,
                })
                .withLoading();
        }
    }
}
