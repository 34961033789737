var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "crud crud-list ajusteAcoesPesquisar" },
    [
      !_vm.hideExtras
        ? _c("div", { staticClass: "info-div" }, [
            _c("h1", { staticClass: "title" }, [
              _vm._v(_vm._s(_vm.$t("__.Crud.produto.list_vue_html.produtos"))),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "content" }, [
              _c("div", { staticClass: "info-panel" }, [
                _c("span", [_vm._v(_vm._s(_vm.total))]),
                _vm._v(" "),
                _c("span", [
                  _vm._v(
                    _vm._s(_vm.$t("__.Crud.produto.list_vue_html.produtos"))
                  ),
                ]),
                _vm._v(" "),
                _c("span", [
                  _vm._v(
                    _vm._s(_vm.$t("__.Crud.produto.list_vue_html.cadastrados"))
                  ),
                ]),
              ]),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.loadedPagination
        ? _c(
            "grid-component",
            {
              ref: "gridcomponent",
              attrs: {
                data: _vm.gridData,
                tituloPagina: "Produtos",
                columns: _vm.gridColumns,
                "page-index": _vm.pageIndex,
                "page-size": _vm.pageSize,
                total: _vm.total,
                showEditItem: !_vm.hideExtras,
                showActionColumn: !_vm.hideExtras,
                showAddNewButton: !_vm.hideExtras,
                showTableFoot: !_vm.hideExtras,
                showTools: !_vm.hideExtras,
                showTableHead: !_vm.hideExtras,
                controlaPageSize: true,
                startFilterKey: _vm.startFilterKey,
                actionName: "produtos",
              },
              on: {
                "gerar-xls": function ($event) {
                  _vm.pags = true
                  _vm.tipoDocGerar = 0
                },
                "gerar-pdf": function ($event) {
                  _vm.pags = true
                  _vm.tipoDocGerar = 1
                },
                "gerar-impressao": function ($event) {
                  _vm.pags = true
                  _vm.tipoDocGerar = 2
                },
                "change-filter-key": _vm.onChangeFilterKey,
                "change-sort": _vm.onChangeSort,
                "edit-item": _vm.onEditItem,
                "remove-item": _vm.onRemoveItem,
                "create-item": _vm.onCreateItem,
                "change-page": _vm.onChangePage,
                "change-page-size": _vm.onChangePageSize,
              },
            },
            [
              _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                _c("div", { staticClass: "filtro-lista" }, [
                  _c(
                    "button",
                    {
                      staticClass: "filtro-avancado-button",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          _vm.isHidden = !_vm.isHidden
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.$t("__.Crud.filtroAvancado")) +
                          "\n                    "
                      ),
                      _c("i", { staticClass: "fa fa-caret-down" }),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.isHidden,
                          expression: "isHidden",
                        },
                      ],
                      staticClass: "drop-lista",
                    },
                    [
                      _c(
                        "form",
                        {
                          attrs: { novalidate: "novalidate" },
                          on: {
                            submit: function ($event) {
                              $event.preventDefault()
                              return _vm.getFormValues($event)
                            },
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "radio-group",
                              staticStyle: { "margin-bottom": "15px" },
                            },
                            [
                              _c("div", { staticStyle: { display: "table" } }, [
                                _c(
                                  "div",
                                  [
                                    _c(
                                      "label",
                                      {
                                        staticClass: "control-label",
                                        attrs: { for: "situacaoProduto" },
                                      },
                                      [
                                        _vm._v(
                                          "\n                                        " +
                                            _vm._s(
                                              _vm.$t(
                                                "__.Crud.produto.list_vue_html.situacaoProduto"
                                              )
                                            ) +
                                            "\n                                    "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("combo-component", {
                                      staticStyle: { background: "white" },
                                      attrs: {
                                        id: "situacaoProduto",
                                        name: "situacaoProduto",
                                        data: _vm.situacaoProdutoOptions,
                                        defaultValue: -1,
                                      },
                                      model: {
                                        value: _vm.situacaoProdutoFiltro,
                                        callback: function ($$v) {
                                          _vm.situacaoProdutoFiltro = $$v
                                        },
                                        expression: "situacaoProdutoFiltro",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  [
                                    _c(
                                      "label",
                                      {
                                        staticClass: "control-label",
                                        attrs: { for: "grupoProduto" },
                                      },
                                      [
                                        _vm._v(
                                          "\n                                        " +
                                            _vm._s(
                                              _vm.$t(
                                                "__.Crud.produto.list_vue_html.grupProduto"
                                              )
                                            ) +
                                            "\n                                    "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("combo-component", {
                                      staticStyle: { background: "white" },
                                      attrs: {
                                        id: "grupoProdutoId",
                                        name: "grupoProdutoId",
                                        data: _vm.grupoProdutoOptions,
                                        refresh: _vm.loadGruposProduto,
                                      },
                                      model: {
                                        value: _vm.grupoProdutoFiltro,
                                        callback: function ($$v) {
                                          _vm.grupoProdutoFiltro = $$v
                                        },
                                        expression: "grupoProdutoFiltro",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _vm.apareceSubGrupo == 2
                                  ? _c(
                                      "div",
                                      [
                                        _c(
                                          "label",
                                          {
                                            staticClass: "control-label",
                                            staticStyle: {
                                              "margin-top": "10px",
                                              width: "100%",
                                            },
                                            attrs: { for: "subGrupoProduto" },
                                          },
                                          [
                                            _vm._v(
                                              "\n                                        " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "__.Crud.produto.list_vue_html.subgrupProd"
                                                  )
                                                ) +
                                                "\n                                    "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("combo-component", {
                                          staticStyle: { background: "white" },
                                          attrs: {
                                            id: "subGrupoProduto",
                                            name: "subGrupoProduto",
                                            data: _vm.subGrupoOptions,
                                          },
                                          model: {
                                            value: _vm.subGrupo,
                                            callback: function ($$v) {
                                              _vm.subGrupo = $$v
                                            },
                                            expression: "subGrupo",
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "form-group" }, [
                            _c(
                              "button",
                              {
                                staticClass: "botao-filtrar",
                                attrs: { type: "submit" },
                              },
                              [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(_vm.$t("__.Crud.filtrar")) +
                                    "\n                            "
                                ),
                              ]
                            ),
                          ]),
                        ]
                      ),
                    ]
                  ),
                ]),
              ]),
              _vm._v(" "),
              _vm.isAdmin
                ? _c(
                    "div",
                    {
                      attrs: { slot: "footer-extra-options" },
                      slot: "footer-extra-options",
                    },
                    [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-primary",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              _vm.atualizaMarkup()
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n                " +
                              _vm._s(
                                _vm.$t(
                                  "__.Crud.produto.list_vue_html.corrigirVlrVenda"
                                )
                              ) +
                              "\n            "
                          ),
                        ]
                      ),
                    ]
                  )
                : _vm._e(),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }