import SngpcManualModel from "@/models/sngpcManualModel";
import nfetch from "@/utils/events/nfetch";

import { IService } from "./base/iService";
import PDFService from "./pdf/PDFService";

export default class SngpcManualService extends IService<SngpcManualModel> {
    public pdfService = new PDFService("SngpcManual");

    constructor() {
        super("SngpcManual");
    }

    public getDados(): Promise<Response> {
        return nfetch(`/${this.controllerName}/GetDados`, {
            credentials: "same-origin",
        });
    }
}
