import BaseModel from "./base/baseModel";
import ManipulacaoOrdemCancelamentoItemModel from "./manipulacaoOrdemCancelamentoItemModel";

export default class ManipulacaoOrdemCancelamentoModel extends BaseModel {
    id?: number = null;

    manipulacaoOrdemId: number = null;

    motivoCancelamentoId: number = null;
    usuarioCancelamentoId?: number = null;
    usuarioCancelamentoNome: string = null;
    observacaoCancelamento: string = null;
    dataCancelamento: Date = null;
    movimentacaoEstoqueId?: number = null;

    itens: Array<ManipulacaoOrdemCancelamentoItemModel> = [];
}
