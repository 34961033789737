import { GridColumn, GridColumnType } from "@/components/child/grid/gridColumn";
import { Component } from "@/decorators";
import EspecieAnimalModel from "@/models/paciente/especieAnimalModel";
import EspecieAnimalService from "@/services/paciente/especieAnimalService";

import ListComponentBase from "../listComponentBase";

import "../crud.scss";

@Component
export default class EspecieAnimalListComponent extends ListComponentBase<EspecieAnimalModel> {
    get gridColumns(): Array<GridColumn> {
        return [new GridColumn("descricao", this.$t("__.ts.descricao"), GridColumnType.String)];
    }

    private beforeMount() {
        this.setProps(new EspecieAnimalService(), "especieanimal", "descricao", true);
    }
}
