import Vue from "vue";

import actionBarComponent from "@/components/child/actionBar/actionBar.vue";
import checkboxComponent from "@/components/child/form/checkbox.vue";
import dataTooltipComponent from "@/components/child/form/datatooltip.vue";
import LoadingModalComponent from "@/components/child/loadingmodal/loadingmodal";
import loadingModalComponent from "@/components/child/loadingmodal/loadingmodal.vue";
import { Component } from "@/decorators";
import MetodoPagamentoModel from "@/models/financeiro/metodoPagamentoModel";
import ValidationErrorModel from "@/models/validationErrorModel";
import MetodoPagamentoService from "@/services/financeiro/metodoPagamentoService";
import ValidationErrorWrapper from "@/wrappers/validationErrorWrapper";

import "../crud.scss";

@Component({
    components: {
        checkboxComponent,
        dataTooltipComponent,
        loadingModalComponent,
        actionBarComponent,
    },
})
export default class MetodoPagamentoEditComponent extends Vue {
    private service = new MetodoPagamentoService();
    private validationErrorWrapper = new ValidationErrorWrapper(this.$validator);
    private loadingModalComponent: LoadingModalComponent = null;

    model: MetodoPagamentoModel = new MetodoPagamentoModel();
    modelId = 0;

    constructor() {
        super();

        this.model.ativo = true;
    }

    private load() {
        this.model = new MetodoPagamentoModel();
        this.validationErrorWrapper.clearErrors();

        if (this.modelId > 0) {
            this.loadingModalComponent.showLoading();

            this.service
                .get(this.modelId)
                .then(response => {
                    if (response.ok) return response.json() as Promise<MetodoPagamentoModel>;
                })
                .then(data => {
                    this.model.updateFrom(data);
                    this.loadingModalComponent.hide();
                })
                .catch(error => {
                    this.$router.back();
                    this.loadingModalComponent.hide();
                });
        }
    }

    private save() {
        this.$validator
            .validateAll()
            .then(isValid => {
                if (isValid) {
                    this.loadingModalComponent.showSaving();
                    if (this.model.intervalo == null) this.model.intervalo = 0;

                    if (this.model.primeiroIntervalo == null) this.model.primeiroIntervalo = 0;
                    this.service[!this.model.id ? "insert" : "update"](this.model)
                        .then(async response => {
                            this.loadingModalComponent.hide();

                            if (response.ok) {
                                await this.$showInclusaoUpdate(this.model.id);
                                this.$router.back();
                            } else {
                                return response.json() as Promise<ValidationErrorModel[]>;
                            }
                        })
                        .then(data => {
                            this.validationErrorWrapper.showErrors(data);
                        })
                        .catch(error => {
                            this.loadingModalComponent.hide();
                        });
                }
            })
            .catch(error => {});
    }

    private cancel() {
        this.$router.back();
    }

    private mounted() {
        this.loadingModalComponent = this.$refs.loadingModalComponent as LoadingModalComponent;

        if (this.$route.params.id) {
            this.modelId = +this.$route.params.id;

            this.load();
        } else {
            this.model.ativo = true;
        }
    }
}
