var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "fieldset-component",
    {
      attrs: { title: "Manipulação " + _vm.model.descricao, collapsed: false },
    },
    [
      _c("div", { attrs: { slot: "rows" }, slot: "rows" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-sm-4" }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "tooltip",
                    rawName: "v-tooltip",
                    value: _vm.errors.first("posologiaId" + _vm.index),
                    expression: "errors.first('posologiaId' + index)",
                  },
                ],
                staticClass: "form-group",
              },
              [
                _c(
                  "label",
                  {
                    staticClass: "control-label",
                    attrs: {
                      for: "posologiaId" + _vm.index,
                      title: "* este campo é obrigatório",
                    },
                  },
                  [
                    !_vm.isEstereis
                      ? _c("span", { staticStyle: { color: "red" } }, [
                          _vm._v("*"),
                        ])
                      : _vm._e(),
                    _vm._v(
                      "\n                        Posologia\n                    "
                    ),
                  ]
                ),
                _vm._v(" "),
                !_vm.isPosologiaTextoLivre
                  ? _c(
                      "combo-component",
                      {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: {
                              rules: {
                                required: !_vm.isEstereis,
                              },
                            },
                            expression:
                              "{\n                            rules: {\n                                required: !isEstereis,\n                            },\n                        }",
                          },
                        ],
                        attrs: {
                          actionName: "posologias",
                          addEditId: _vm.model.posologiaId,
                          addNew: !_vm.model.posologiaId,
                          customView: true,
                          data: _vm.posologiaOptions,
                          formAddNewName:
                            _vm.model.posologiaId > 0
                              ? _vm.$t(
                                  "__.Components.parent.venda.revisaoCampos_vue_html.editPosologia"
                                )
                              : _vm.$t(
                                  "__.Components.parent.venda.revisaoCampos_vue_html.novaPosologia"
                                ),
                          id: "posologiaId" + _vm.index,
                          modalClass: "lm-modal",
                          name: "posologiaId" + _vm.index,
                          refresh: _vm.loadPosologia,
                          useCloseModalCallback: true,
                        },
                        on: {
                          addNewItemCombo: _vm.onAddNewPosologia,
                          openComboEditItem: _vm.openComboEditPosologia,
                          openComboNewItem: _vm.openComboNewPosologia,
                        },
                        model: {
                          value: _vm.model.posologiaId,
                          callback: function ($$v) {
                            _vm.$set(_vm.model, "posologiaId", $$v)
                          },
                          expression: "model.posologiaId",
                        },
                      },
                      [
                        _vm.loadPosologiaComponent
                          ? _c(
                              "div",
                              {
                                attrs: { slot: "component" },
                                slot: "component",
                              },
                              [
                                _c("posologia-component", {
                                  ref: "posologiaComponent",
                                  attrs: {
                                    CalledByShortCut: true,
                                    indexComp: _vm.index,
                                  },
                                  on: {
                                    "save-ok-index": _vm.onPosologiaSaveOk,
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    )
                  : _c("textarea", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.model.posologiaTexto,
                          expression: "model.posologiaTexto",
                        },
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: {
                            rules: {
                              required: !_vm.isEstereis,
                            },
                          },
                          expression:
                            "{\n                            rules: {\n                                required: !isEstereis,\n                            },\n                        }",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: {
                        id: "posologiaId" + _vm.index,
                        name: "posologiaId" + _vm.index,
                        maxlength: "256",
                        rows: "3",
                      },
                      domProps: { value: _vm.model.posologiaTexto },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.model,
                            "posologiaTexto",
                            $event.target.value
                          )
                        },
                      },
                    }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-sm-4" }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "tooltip",
                    rawName: "v-tooltip",
                    value: _vm.errors.first("pacienteId" + _vm.index),
                    expression: "errors.first('pacienteId' + index)",
                  },
                ],
                staticClass: "form-group",
              },
              [
                _c(
                  "label",
                  {
                    staticClass: "control-label",
                    attrs: {
                      for: "pacienteId" + _vm.index,
                      title: "* este campo é obrigatório",
                    },
                  },
                  [
                    !_vm.isEstereis
                      ? _c("span", { staticStyle: { color: "red" } }, [
                          _vm._v("*"),
                        ])
                      : _vm._e(),
                    _vm._v(
                      "\n                        Paciente\n                    "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "search-combo-component",
                  {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: {
                          rules: {
                            required: !_vm.isEstereis,
                          },
                        },
                        expression:
                          "{\n                            rules: {\n                                required: !isEstereis,\n                            },\n                        }",
                      },
                    ],
                    attrs: {
                      actionName: "pacientes",
                      addEditId: _vm.model.pacienteId,
                      addNew: !_vm.model.pacienteId,
                      advancedSearch: true,
                      advancedSearchCampos: [
                        { campo: "text", descricao: "Nome do paciente" },
                        {
                          campo: "clienteDescricao",
                          descricao: "Nome do cliente",
                        },
                        {
                          campo: "especieAnimalDescricao",
                          descricao: "Espécie",
                        },
                      ],
                      customComboText: _vm.customComboTextPaciente,
                      customView: true,
                      formAddNewName:
                        _vm.model.pacienteId > 0
                          ? _vm.$t(
                              "__.Components.parent.venda.revisaoCampos_vue_html.editPaciente"
                            )
                          : _vm.$t(
                              "__.Components.parent.venda.revisaoCampos_vue_html.novoPaciente"
                            ),
                      id: "pacienteId" + _vm.index,
                      modalClass: "lm-modal",
                      name: "pacienteId" + _vm.index,
                      propDescription: "nome",
                      service: "Paciente",
                      useCloseModalCallback: true,
                    },
                    on: {
                      addNewItemCombo: _vm.onAddNewPaciente,
                      openComboEditItem: _vm.openComboEditPaciente,
                      openComboNewItem: _vm.openComboNewPaciente,
                    },
                    model: {
                      value: _vm.model.pacienteId,
                      callback: function ($$v) {
                        _vm.$set(_vm.model, "pacienteId", $$v)
                      },
                      expression: "model.pacienteId",
                    },
                  },
                  [
                    _vm.loadPacienteComponent
                      ? _c(
                          "div",
                          { attrs: { slot: "component" }, slot: "component" },
                          [
                            _c("paciente-component", {
                              ref: "pacienteComponent",
                              attrs: { CalledByShortCut: true },
                              on: { "save-ok": _vm.onPacienteSaveOk },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-sm-4" }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "tooltip",
                    rawName: "v-tooltip",
                    value: _vm.errors.first("prescritorId" + _vm.index),
                    expression: "errors.first('prescritorId' + index)",
                  },
                ],
                staticClass: "form-group",
              },
              [
                _c(
                  "label",
                  {
                    staticClass: "control-label",
                    attrs: {
                      for: "prescritorId" + _vm.index,
                      title: "* este campo é obrigatório",
                    },
                  },
                  [
                    _c("span", { staticStyle: { color: "red" } }, [
                      _vm._v("*"),
                    ]),
                    _vm._v(
                      "\n                        Prescritor\n                    "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "search-combo-component",
                  {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'",
                      },
                    ],
                    attrs: {
                      actionName: "prescritores",
                      addEditId: _vm.model.prescritorId,
                      addNew: !_vm.model.prescritorId,
                      advancedSearch: true,
                      advancedSearchCampos: [
                        { campo: "text", descricao: "Nome" },
                        {
                          campo: "numeroRegistro",
                          descricao: "Número de registro",
                        },
                        {
                          campo: "tipoConselhoRegionalDescricao",
                          descricao: "Conselho Regional",
                        },
                        { campo: "cpf", descricao: "CNPJ/CPF" },
                      ],
                      customComboText: _vm.customComboTextPrescritor,
                      customView: true,
                      formAddNewName:
                        _vm.model.prescritorId > 0
                          ? _vm.$t(
                              "__.Components.parent.venda.revisaoCampos_vue_html.editPrescritor"
                            )
                          : _vm.$t(
                              "__.Components.parent.venda.revisaoCampos_vue_html.novoPrescritor"
                            ),
                      id: "prescritorId" + _vm.index,
                      name: "prescritorId" + _vm.index,
                      propDescription: "nome",
                      service: "Prescritor",
                      useCloseModalCallback: true,
                    },
                    on: {
                      addNewItemCombo: _vm.onAddNewPrescritor,
                      openComboEditItem: _vm.openComboEditPrescritor,
                      openComboNewItem: _vm.openComboNewPrescritor,
                    },
                    model: {
                      value: _vm.model.prescritorId,
                      callback: function ($$v) {
                        _vm.$set(_vm.model, "prescritorId", $$v)
                      },
                      expression: "model.prescritorId",
                    },
                  },
                  [
                    _vm._v(
                      "\n                        >\n                        "
                    ),
                    _vm.loadPrescritorComponent
                      ? _c(
                          "div",
                          { attrs: { slot: "component" }, slot: "component" },
                          [
                            _c("prescritor-component", {
                              ref: "prescritorComponent",
                              attrs: {
                                CalledByShortCut: true,
                                indexComp: _vm.index,
                              },
                              on: { "save-ok-index": _vm.onPrescritorSaveOk },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                ),
              ],
              1
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.model.possuiAtivoControlado,
                  expression: "model.possuiAtivoControlado",
                },
              ],
              staticClass: "col-sm-12",
            },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "tooltip",
                      rawName: "v-tooltip",
                      value: _vm.errors.first("numeroReceita"),
                      expression: "errors.first('numeroReceita')",
                    },
                  ],
                  staticClass: "form-group",
                },
                [
                  _c(
                    "label",
                    {
                      staticClass: "control-label",
                      attrs: {
                        for: "numeroReceita",
                        title: "* este campo é obrigatório",
                      },
                    },
                    [
                      _c("span", { staticStyle: { color: "red" } }, [
                        _vm._v("*"),
                      ]),
                      _vm._v(
                        "\n                        Número Receita\n                    "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.model.numeroReceita,
                        expression: "model.numeroReceita",
                      },
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: _vm.model.possuiAtivoControlado
                          ? "required"
                          : "",
                        expression:
                          "model.possuiAtivoControlado ? 'required' : ''",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: {
                      autocomplete: "off",
                      type: "text",
                      id: "numeroReceita",
                      name: "numeroReceita",
                      maxlength: "100",
                    },
                    domProps: { value: _vm.model.numeroReceita },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.model,
                          "numeroReceita",
                          $event.target.value
                        )
                      },
                    },
                  }),
                ]
              ),
            ]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }