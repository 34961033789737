var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-sm-4" }, [
          _c(
            "div",
            {
              directives: [
                {
                  name: "tooltip",
                  rawName: "v-tooltip",
                  value: _vm.errors.first("dataEntrega"),
                  expression: "errors.first('dataEntrega')",
                },
              ],
              staticClass: "form-group",
            },
            [
              _c(
                "label",
                { staticClass: "control-label", attrs: { for: "dataEntrega" } },
                [
                  _vm._v(
                    "\n                    " +
                      _vm._s(
                        _vm.$t(
                          "__.Components.parent.venda.editSingle_vue_html.dtHraEntrega"
                        )
                      ) +
                      "\n                "
                  ),
                ]
              ),
              _vm._v(" "),
              _c("date-time-picker-component", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: _vm.dataEntregaObrigatoria ? "required" : "",
                    expression: "dataEntregaObrigatoria ? 'required' : ''",
                  },
                ],
                attrs: {
                  id: "dataEntrega",
                  name: "dataEntrega",
                  pickTime: true,
                },
                model: {
                  value: _vm.vendaModel.dataEntrega,
                  callback: function ($$v) {
                    _vm.$set(_vm.vendaModel, "dataEntrega", $$v)
                  },
                  expression: "vendaModel.dataEntrega",
                },
              }),
            ],
            1
          ),
        ]),
      ]),
      _vm._v(" "),
      _vm._l(_vm.models, function (model, indexModel) {
        return _c(
          "div",
          { key: model.id },
          [
            _c("revisao-campos-item-component", {
              ref: "indexModel",
              refInFor: true,
              attrs: {
                model: model,
                posologiaOptions: _vm.posologiaOptions,
                index: indexModel,
              },
              on: { "load-posologia": _vm.loadPosologia },
            }),
          ],
          1
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }