import ControleReclamacaoModel from "@/models/controleReclamacaoModel";
import nfetch from "@/utils/events/nfetch";

import { IService } from "./base/iService";

export default class ControleReclamacaoService extends IService<ControleReclamacaoModel> {
    constructor() {
        super("ControleReclamacao");
    }

    public countByCliente(clienteId: number): Promise<Response> {
        return nfetch(`/${this.controllerName}/CountByCliente?clienteId=${clienteId}`, {
            credentials: "same-origin",
        });
    }
}
