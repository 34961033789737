import { mapGetters, mapState } from "vuex";

import checkboxComponent from "@/components/child/form/checkbox.vue";
import comboComponent from "@/components/child/form/combo.vue";
import dateTimePickerComponent from "@/components/child/form/datetimepicker.vue";
import { GridAction } from "@/components/child/grid/gridAction";
import { GridColors, GridColumn, GridColumnType } from "@/components/child/grid/gridColumn";
import impressaoComponent from "@/components/child/impressao/impressaoComponent.vue";
import { Component, Prop } from "@/decorators";
import PreLoadPackModel from "@/models/auxiliar/preLoadPackModel";
import PaginationModel from "@/models/paginationModel";
import VendaModel, { VendaListParameters } from "@/models/vendaModel";
import CorreiosService from "@/services/correiosService";
import EtiquetaService from "@/services/etiquetaService";
import VendaService from "@/services/vendaService";
import { AppState, Getters } from "@/store/store";
import { getSetorCombo } from "@/utils/common/combo/combotext";
import { convertToDateInput, convertToDateWithTimeInputString } from "@/utils/common/date";

import ListComponentBase from "../listComponentBase";

@Component({
    components: {
        dateTimePickerComponent,
        checkboxComponent,
        comboComponent,
        impressaoComponent,
    },
    computed: {
        ...mapState({
            preLoadList: (state: AppState) => state.preLoad.preLoadList,
        }),
        ...mapGetters(["GET_IS_FRACIONAMENTO", "GET_IS_ESTEREIS"] as Getters),
    },
})
export default class EtiquetasListComponent extends ListComponentBase<VendaModel> {
    // State computed props
    preLoadList: PreLoadPackModel;
    GET_IS_FRACIONAMENTO: () => Promise<boolean>;
    GET_IS_ESTEREIS: () => Promise<boolean>;

    extraActions: Array<object> = [];
    extraActionGerarCodigo: Array<GridAction> = [];
    extraActionEtiquetas: Array<GridAction> = [];

    @Prop({ type: Boolean, default: false }) CalledByShortCut: boolean;

    private vendaService = new VendaService();
    private correiosService = new CorreiosService();
    private etiquetaService = new EtiquetaService();

    locaisEntregaOptions: Array<Object> = [];
    setorOptions = [];

    isHidden = false;
    filtro: Array<Object> = [];
    dataFilter1: Date = new Date(new Date().setHours(0, 0, 0));
    dataFilter2: Date = new Date(new Date().setHours(23, 59, 59));
    localEntregaId = null;
    vendaComNota = true;
    setor = 0;

    private isFracionamento = false;
    private isEstereis = false;

    gridSelectedValues: Array<VendaModel> = [];

    get gridColumns(): Array<GridColumn> {
        const columns = [];
        columns.push(new GridColumn("codigo", "Código", GridColumnType.String, false, false, "", "", true));
        columns.push(
            new GridColumn("situacaoDescricao", "Situação", GridColumnType.String, false, false, "", "", true, false),
        );
        columns.push(new GridColumn("clienteNome", "Cliente", GridColumnType.String, false, false, "", "", true));
        columns.push(new GridColumn("enderecoEntrega", "Endereço", GridColumnType.String, false, false, "", "", true));
        columns.push(
            new GridColumn(
                "localEntregaDescricao",
                "Local de entrega",
                GridColumnType.String,
                false,
                false,
                "",
                "",
                true,
            ),
        );
        columns.push(
            new GridColumn("dataEntrega", "Data Entrega", GridColumnType.DateTime, false, false, "", "", true),
        );
        columns.push(
            new GridColumn("codigoRastreio", "Cód. Rastreio", GridColumnType.String, false, false, "", "", true),
        );
        columns.push(new GridColumn("numeroNota", "Nº Nota", GridColumnType.String, false, false, "", "", true, false));
        if (this.isEstereis || this.isFracionamento) {
            columns.push(
                new GridColumn("setorDescricao", "Setor", GridColumnType.String, false, false, "", "", true, false),
            );
        }
        return columns;
    }

    public async afterMounted() {
        this.isFracionamento = await this.GET_IS_FRACIONAMENTO();
        this.isEstereis = await this.GET_IS_ESTEREIS();

        this.setorOptions = getSetorCombo(this.isFracionamento);
    }

    public async loadMethod() {
        const customParameters: VendaListParameters = {
            dataInicial: convertToDateWithTimeInputString(this.dataFilter1 ?? this.filtro[0]),
            dataFinal: convertToDateWithTimeInputString(this.filtro[1]),
            localEntrega: this.localEntregaId,
            vendasComNotas: this.vendaComNota,
            setor: this.setor,
        };

        return await this.vendaService
            .listVendasEtiquetas(
                this.gridFilterKey,
                this.gridSortKey,
                this.gridSortOrder,
                this.pags ? 1 : this.pageIndex,
                this.pags ? 999999 : this.pageSize,
                customParameters,
            )
            .withLoading()
            .resolveWithJSON<PaginationModel<VendaModel>>();
    }

    public async afterLoad() {
        try {
            this.gridData["dataDe"] = this.filtro[0] ? this.filtro[0].toString() : " ";
            this.gridData["dataAte"] = this.filtro[1] ? this.filtro[1].toString() : " ";

            this.isHidden = false;

            this.locaisEntregaOptions = this.preLoadList.locaisEntregaCombo();
        } catch {}

        this.extraActions.splice(0);
        this.extraActionGerarCodigo.splice(0);
        this.extraActionEtiquetas.splice(0);

        for (let i = 0; i < this.gridData.length; i++) {
            this.extraActionGerarCodigo.push(
                new GridAction(
                    "gerar-codigo",
                    this.$t("__.Components.parent.venda.editSingle_vue_html.gerarCodigoRastreio") as string,
                    "fa fa-truck-loading",
                    GridColors.GREEN,
                ),
            );
            this.extraActionEtiquetas.push(
                new GridAction(
                    "imprimir-etiquetas",
                    this.$t("__.Crud.etiquetas.list_vue_html.imprimirEtiquetas") as string,
                    "fa fa-print",
                    GridColors.GRAY,
                    this.gridData[i].codigoRastreio != null,
                ),
            );
        }

        this.extraActions.push(this.extraActionGerarCodigo);
        this.extraActions.push(this.extraActionEtiquetas);
    }

    private onSelectedValuesChanged(values: Array<VendaModel>) {
        this.gridSelectedValues = values;
    }

    public async onImprimirEtiquetas(vendas: VendaModel[]) {
        try {
            const base64Data = await this.etiquetaService.getListEtiquetas(vendas.map(p => p.id)).resolveWithText();
            if (!base64Data) {
                await this.$showError(this.$t("__.ts.erro"), this.$t("__.ts.nenhumaEtiqueta"));
            }

            const byteCharacters = atob(base64Data);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], { type: "application/pdf" });

            const blobUrl = URL.createObjectURL(blob);
            const iframe = document.createElement("iframe");
            iframe.style.display = "none";
            iframe.src = blobUrl;
            document.body.appendChild(iframe);

            iframe.onload = () => {
                iframe.contentWindow?.print();
                URL.revokeObjectURL(blobUrl); // Limpa a URL criada
            };
        } catch (error) {
            await this.$showError(this.$t("__.ts.erro"), this.$t("__.ts.erroGerarEtiq"));
        }
    }

    public async onGerarEtiquetas(vendas: VendaModel[]) {
        try {
            const question = await this.$showQuestion(this.$t("__.ts.codigoRastreio"), this.$t("__.ts.desejaGerarCod"));

            if (question) {
                await this.correiosService
                    .gerarCodigoRastreio(vendas.map(p => p.id))
                    .withLoading()
                    .resolveWithoutJSON();
                await this.load();
            }
        } catch (error) {
            await this.$showError(this.$t("__.ts.erro"), this.$t("__.ts.erroGerarEtiq"));
        }
    }

    public async afterLoadPagination() {
        if (this.paginationParams.filtros != null) {
            this.filtro = this.paginationParams.filtros;
            if (this.filtro.length >= 0) {
                if (this.filtro[0]) {
                    this.dataFilter1 = convertToDateInput(this.filtro[0].toString());
                }
                if (this.filtro.length >= 1) {
                    if (this.filtro[1]) {
                        this.dataFilter2 = convertToDateInput(this.filtro[1].toString());
                    }
                }
            }
        }
    }

    public async onGerarCodigo(venda: VendaModel) {
        try {
            const question = await this.$showQuestion(
                this.$t("__.ts.codigoRastreio"),
                venda.codigoRastreio ? this.$t("__.ts.temCertezaDesejaGerarCod") : this.$t("__.ts.desejaGerarCod"),
            );

            if (question) {
                await this.correiosService.gerarCodigoRastreio([venda.id]).withLoading().resolveWithoutJSON();
                await this.load();
            }
        } catch {}
    }

    private async onExtraActions(name: string, venda: VendaModel) {
        if (name.trim() == "gerar-codigo") {
            this.onGerarCodigo(venda);
        }
        if (name.trim() == "imprimir-etiquetas") {
            this.onImprimirEtiquetas([venda]);
        }
    }
}
