import BaseModel from "../base/baseModel";
import StatusColeta from "../enum/statusColeta";

export default class ColetaModel extends BaseModel {
    id?: number = null;
    descricao = "";
    data = new Date();
    dataCriacao = null;
    status = 0;
    statusDescricao = "";
    clienteId?: number = null;
    clienteNome = "";
    vendedorId?: number = null;
    vendedorNome = "";
    localEntregaDescricao = "";
    localEntregaId?: number = null;
}

export interface ColetaListParameters {
    dataInicial?: string;
    dataFinal?: string;
    status?: StatusColeta;
    localEntregaId?: number;
}
