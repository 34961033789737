import ProdutoLogFinanceiroModel from "../../models/produtoLogFinanceiroModel";
import nfetch from "../../utils/events/nfetch";
import { IService } from "../base/iService";

export default class produtoLogFinanceiroService extends IService<ProdutoLogFinanceiroModel> {
    constructor() {
        super("ProdutoLogFinanceiro");
    }

    public listByProduto(filterKey: string, sortKey: string, sortOrder: string, pageIndex: number, pageSize: number, produtoId:number): Promise<Response> {
        const encodedFIlter = encodeURIComponent(filterKey);
        return nfetch(`/${this.controllerName}/List?filterKey=${encodedFIlter}&sortKey=${sortKey}&sortOrder=${sortOrder}&pageIndex=${pageIndex}&pageSize=${pageSize}&produtoId=${produtoId}`, {
            credentials: "same-origin"
        });
    }
 
}