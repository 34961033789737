var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "shortcutComponent",
    {
      ref: "shortcutComponent",
      attrs: { showAddNew: false, showBtnOk: false, modalClass: "ss-modal" },
    },
    [
      _c(
        "div",
        { attrs: { slot: "component" }, slot: "component" },
        [
          _vm.atendimentos.length == 0
            ? _c("div", { staticClass: "sem-historico" }, [
                _vm._v(
                  "\n            " +
                    _vm._s(
                      _vm.$t(
                        "__.Components.parent.atendimento.historicoAtendimento.semAtendimento"
                      )
                    ) +
                    "\n        "
                ),
              ])
            : _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-sm-12" }, [
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    _vm._l(_vm.atendimentos, function (data, index) {
                      return _c("div", { key: index }, [
                        _c(
                          "button",
                          {
                            staticClass: "historico-button",
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                _vm.onMensagemAtendimento(data.id)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                            " +
                                _vm._s(_vm.getDate(data.dataInicio)) +
                                "\n                        "
                            ),
                          ]
                        ),
                      ])
                    })
                  ),
                ]),
              ]),
          _vm._v(" "),
          _c("historicoAtendimentoMensagensComponent", {
            ref: "historicoAtendimentoMensagensComponent",
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }