import Vue from "vue";
import { mapState } from "vuex";

import LoadingComponent from "./components/child/loadingComponent/loadingComponent";
import loadingComponent from "./components/child/loadingComponent/loadingComponent.vue";
import appComponent from "./components/parent/app/app.vue";
import { Component } from "./decorators";
import ValidationErrorModel from "./models/validationErrorModel";
import { AppState, SessionActions } from "./store/store";
import Bus from "./utils/events/bus";
import ValidationErrorWrapper from "./wrappers/validationErrorWrapper";

@Component({
    components: {
        appComponent,
        loadingComponent,
    },
    computed: mapState({
        logado: (state: AppState) => state.session.logado,
    }),
})
export default class AppComponent extends Vue {
    // State computed props
    logado: boolean;

    // ===========================================================================
    // Instância do componente de loading global
    // ===========================================================================
    private globalLoadingComponent: LoadingComponent;
    private validationErrorWrapper = new ValidationErrorWrapper(this.$validator);

    private registerGlobalLoadingComponentEvents() {
        Bus.$on("global-loading-component-show", () => {
            if (!this.globalLoadingComponent) {
                this.globalLoadingComponent = this.$refs.loadingComponent as LoadingComponent;
            }
            this.globalLoadingComponent.show();
        });
        Bus.$on("global-loading-component-hide", () => {
            if (!this.globalLoadingComponent) {
                this.globalLoadingComponent = this.$refs.loadingComponent as LoadingComponent;
            }
            this.globalLoadingComponent.hide();
        });
    }

    private registerGlobalValidationWrapper() {
        Bus.$on("global-validation-wrapper", (data: ValidationErrorModel[]) => {
            this.validationErrorWrapper.clearErrors();
            if (this.validationErrorWrapper.hasValidErrors(data)) {
                this.validationErrorWrapper.showErrors(data);
            }
        });
    }

    private beforeMount() {
        this.registerGlobalLoadingComponentEvents();
        this.registerGlobalValidationWrapper();
    }

    private mounted() {
        this.$store.dispatch(SessionActions.HYDRATE);
    }
}
