enum Alinhamento {
    Esquerda = 0,
    Centro = 1,
    Direita = 2,
}

export default Alinhamento;

export enum AlinhamentoCss {
    left = 0,
    center = 1,
    right = 2,
}
