import Vue from "vue";

import { Component } from "@/decorators";
import loadingImageUrl from "@/image/giff.gif";

import "./loadingComponent.scss";

@Component
export default class LoadingComponent extends Vue {
    private loadingImageUrl: string = loadingImageUrl;

    loading = false;
    text = "";
    instancias = 0;

    public show(): void {
        this.instancias++;
        this.loading = true;
    }

    public showSaving(): void {
        this.instancias++;
        this.text = this.$t("__.ts.salvando").toString();
        this.loading = true;
    }

    public showDeleting(): void {
        this.instancias++;
        this.text = this.$t("__.ts.excluindo").toString();
        this.loading = true;
    }

    public showCustomText(text: string): void {
        this.instancias++;
        this.text = text;
        this.loading = true;
    }

    public hide(): void {
        this.instancias--;

        if (this.instancias <= 0) this.loading = false;
    }
}
