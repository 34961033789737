var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "crud crud-list ajusteAcoesPesquisar" },
    [
      _c("div", { staticClass: "info-div" }, [
        _c("h1", { staticClass: "title" }, [_vm._v(_vm._s(_vm.$t(_vm.title)))]),
        _vm._v(" "),
        _c("div", { staticClass: "content" }, [
          _c("div", { staticClass: "info-panel" }, [
            _c("span", [_vm._v(_vm._s(_vm.total))]),
            _vm._v(" "),
            _c("span", [_vm._v(_vm._s(_vm.$t(_vm.title)))]),
            _vm._v(" "),
            _vm.situacao == 0
              ? _c("span", [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        "__.Components.parent.venda.list_vue_html.cadastrados"
                      )
                    )
                  ),
                ])
              : _c("span", [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        "__.Components.parent.venda.list_vue_html.cadastradas"
                      )
                    )
                  ),
                ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "grid-component",
        {
          ref: "gridcomponent",
          attrs: {
            actionName: "vendas",
            columns: _vm.gridColumns,
            conditionShowSubData: _vm.conditionShowSubData,
            data: _vm.gridData,
            "edit-item-icon": _vm.somenteConsultaVenda
              ? "fa-search"
              : "fa-edit",
            "edit-item-title": _vm.somenteConsultaVenda
              ? "Consultar"
              : "Alterar",
            extraActions: _vm.extraActions,
            extraSubActions: _vm.extraSubActions,
            "page-index": _vm.pageIndex,
            "page-size": _vm.pageSize,
            controlaPageSize: true,
            "remove-item-icon": "fa-exclamation-circle",
            "remove-item-title": "Cancelar",
            showExpand: true,
            showRemoveItem: _vm.VALIDAR_PERMISSAO_USUARIO(
              "vendas",
              "CancelarVenda"
            ),
            startFilterKey: _vm.startFilterKey,
            subColumns: _vm.gridColumnsVinculos,
            subDataName: _vm.subDataName,
            tituloPagina: _vm.$t(
              "__.Components.parent.venda.list_vue_html.vendas"
            ),
            total: _vm.total,
            showSubColumnsSelectCheckbox: true,
          },
          on: {
            "change-filter-key": _vm.onChangeFilterKey,
            "change-page": _vm.onChangePage,
            "change-page-size": _vm.onChangePageSize,
            "change-sort": _vm.onChangeSort,
            "create-item": _vm.onCreateItem,
            "edit-item": _vm.onEditItem,
            "extra-action": _vm.onExtraAction,
            "extra-sub-action": _vm.onExtraSubAction,
            "gerar-impressao": function ($event) {
              _vm.pags = true
              _vm.tipoDocGerar = 2
            },
            "gerar-pdf": function ($event) {
              _vm.pags = true
              _vm.tipoDocGerar = 1
            },
            "gerar-xls": function ($event) {
              _vm.pags = true
              _vm.tipoDocGerar = 0
            },
            "remove-item": _vm.onRemoveItem,
            "selected-values-changed": _vm.onSelectedLine,
          },
        },
        [
          _c("div", { attrs: { slot: "header" }, slot: "header" }, [
            _c("div", { staticClass: "flex-row" }, [
              _c(
                "div",
                { staticClass: "tipoBuscaVenda" },
                [
                  _c(
                    "label",
                    {
                      staticClass: "control-label",
                      attrs: { for: "tipoBuscaVenda" },
                    },
                    [
                      _vm._v(
                        "\n                        " +
                          _vm._s(_vm.$t("__.ts.buscarEm")) +
                          "\n                    "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("combo-component", {
                    attrs: {
                      data: _vm.tipoBuscaVendaOptions,
                      searchable: true,
                      id: "tipoBuscaVenda",
                      name: "tipoBuscaVenda",
                    },
                    model: {
                      value: _vm.tipoBuscaVenda,
                      callback: function ($$v) {
                        _vm.tipoBuscaVenda = $$v
                      },
                      expression: "tipoBuscaVenda",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "filtro-lista" }, [
                _c(
                  "button",
                  {
                    staticClass: "filtro-avancado-button",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        _vm.isHidden = !_vm.isHidden
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n                        " +
                        _vm._s(_vm.$t("__.Crud.filtroAvancado")) +
                        "\n                        "
                    ),
                    _c("i", { staticClass: "fa fa-caret-down" }),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.isHidden,
                        expression: "isHidden",
                      },
                    ],
                    staticClass: "drop-lista",
                  },
                  [
                    _c(
                      "form",
                      {
                        attrs: { novalidate: "novalidate" },
                        on: {
                          submit: function ($event) {
                            $event.preventDefault()
                            return _vm.getFormValues($event)
                          },
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "radio-group",
                            staticStyle: { "margin-bottom": "15px" },
                          },
                          [
                            _c("div", { staticStyle: { display: "table" } }, [
                              _c("label", [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.radioFilter,
                                      expression: "radioFilter",
                                    },
                                  ],
                                  attrs: {
                                    name: "optradio",
                                    type: "radio",
                                    value: "",
                                  },
                                  domProps: {
                                    checked: _vm._q(_vm.radioFilter, ""),
                                  },
                                  on: {
                                    change: function ($event) {
                                      _vm.radioFilter = ""
                                    },
                                  },
                                }),
                                _vm._v(" "),
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "__.Components.parent.venda.list_vue_html.tudo"
                                      )
                                    )
                                  ),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c("label", [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.radioFilter,
                                      expression: "radioFilter",
                                    },
                                  ],
                                  attrs: {
                                    name: "optradio",
                                    type: "radio",
                                    value: "0",
                                  },
                                  domProps: {
                                    checked: _vm._q(_vm.radioFilter, "0"),
                                  },
                                  on: {
                                    change: function ($event) {
                                      _vm.radioFilter = "0"
                                    },
                                  },
                                }),
                                _vm._v(" "),
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "__.Components.parent.venda.list_vue_html.aFaturar"
                                      )
                                    )
                                  ),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c("label", [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.radioFilter,
                                      expression: "radioFilter",
                                    },
                                  ],
                                  attrs: {
                                    name: "optradio",
                                    type: "radio",
                                    value: "1",
                                  },
                                  domProps: {
                                    checked: _vm._q(_vm.radioFilter, "1"),
                                  },
                                  on: {
                                    change: function ($event) {
                                      _vm.radioFilter = "1"
                                    },
                                  },
                                }),
                                _vm._v(" "),
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "__.Components.parent.venda.list_vue_html.faturado"
                                      )
                                    )
                                  ),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c("label", [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.radioFilter,
                                      expression: "radioFilter",
                                    },
                                  ],
                                  attrs: {
                                    name: "optradio",
                                    type: "radio",
                                    value: "2",
                                  },
                                  domProps: {
                                    checked: _vm._q(_vm.radioFilter, "2"),
                                  },
                                  on: {
                                    change: function ($event) {
                                      _vm.radioFilter = "2"
                                    },
                                  },
                                }),
                                _vm._v(" "),
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "__.Components.parent.venda.list_vue_html.concluido"
                                      )
                                    )
                                  ),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c("label", [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.radioFilter,
                                      expression: "radioFilter",
                                    },
                                  ],
                                  attrs: {
                                    name: "optradio",
                                    type: "radio",
                                    value: "3",
                                  },
                                  domProps: {
                                    checked: _vm._q(_vm.radioFilter, "3"),
                                  },
                                  on: {
                                    change: function ($event) {
                                      _vm.radioFilter = "3"
                                    },
                                  },
                                }),
                                _vm._v(" "),
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "__.Components.parent.venda.list_vue_html.cancelado"
                                      )
                                    )
                                  ),
                                ]),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("br"),
                            _vm._v(" "),
                            _c("b", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "__.Components.parent.venda.list_vue_html.de"
                                  )
                                )
                              ),
                            ]),
                            _vm._v(" "),
                            _c("date-time-picker-component", {
                              staticStyle: { width: "100%" },
                              attrs: { id: "dataFilter1", name: "dataFilter1" },
                              model: {
                                value: _vm.dataFilter1,
                                callback: function ($$v) {
                                  _vm.dataFilter1 = $$v
                                },
                                expression: "dataFilter1",
                              },
                            }),
                            _vm._v(" "),
                            _c("b", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "__.Components.parent.venda.list_vue_html.ate"
                                  )
                                )
                              ),
                            ]),
                            _vm._v(" "),
                            _c("date-time-picker-component", {
                              attrs: { id: "dataFilter2", name: "dataFilter2" },
                              model: {
                                value: _vm.dataFilter2,
                                callback: function ($$v) {
                                  _vm.dataFilter2 = $$v
                                },
                                expression: "dataFilter2",
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "label",
                              {
                                staticClass: "control-label",
                                attrs: { for: "vendedor" },
                              },
                              [
                                _vm._v(
                                  "\n                                    " +
                                    _vm._s(
                                      _vm.$t(
                                        "__.Crud.controleReclamacao.edit_vue_html.vendedor"
                                      )
                                    ) +
                                    "\n                                "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("combo-component", {
                              staticStyle: { background: "white" },
                              attrs: {
                                id: "vendedor",
                                name: "vendedor",
                                data: _vm.usuariosOptions,
                                defaultValue: -1,
                              },
                              model: {
                                value: _vm.vendedor,
                                callback: function ($$v) {
                                  _vm.vendedor = $$v
                                },
                                expression: "vendedor",
                              },
                            }),
                            _vm._v(" "),
                            _vm.isFracionamento || _vm.isEstereis
                              ? _c(
                                  "label",
                                  {
                                    staticClass: "control-label",
                                    attrs: { for: "setor" },
                                  },
                                  [
                                    _vm._v(
                                      "\n                                    " +
                                        _vm._s(
                                          _vm.$t(
                                            "__.Components.parent.venda.editSingle_vue_html.setor"
                                          )
                                        ) +
                                        "\n                                "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.isFracionamento || _vm.isEstereis
                              ? _c("combo-component", {
                                  attrs: {
                                    id: "setor",
                                    name: "setor",
                                    data: _vm.setorOptions,
                                  },
                                  model: {
                                    value: _vm.setor,
                                    callback: function ($$v) {
                                      _vm.setor = $$v
                                    },
                                    expression: "setor",
                                  },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "label",
                              {
                                staticClass: "control-label",
                                attrs: { for: "produto" },
                              },
                              [
                                _vm._v(
                                  "\n                                    " +
                                    _vm._s(
                                      _vm.$t(
                                        "__.Components.parent.venda.item_vue_html.produto"
                                      )
                                    ) +
                                    ":\n                                "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("combo-component", {
                              staticStyle: { background: "white" },
                              attrs: {
                                id: "produto",
                                name: "produto",
                                data: _vm.produtosOptions,
                                defaultValue: -1,
                              },
                              model: {
                                value: _vm.produto,
                                callback: function ($$v) {
                                  _vm.produto = $$v
                                },
                                expression: "produto",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "form-group" }, [
                          _c(
                            "button",
                            {
                              staticClass: "botao-filtrar",
                              attrs: { type: "submit" },
                            },
                            [
                              _vm._v(
                                "\n                                    " +
                                  _vm._s(_vm.$t("__.Crud.filtrar")) +
                                  "\n                                "
                              ),
                            ]
                          ),
                        ]),
                      ]
                    ),
                  ]
                ),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              attrs: { slot: "footer-extra-options" },
              slot: "footer-extra-options",
            },
            [
              _c(
                "button",
                {
                  staticClass: "btn btn-primary",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      _vm.replicarVendasSelecionadas()
                    },
                  },
                },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(
                        _vm.$t(
                          "__.Components.parent.venda.list_vue_html.repetirVendas"
                        )
                      ) +
                      "\n            "
                  ),
                ]
              ),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _vm.loadPagamentoComponent
        ? _c(
            "shortcutComponent",
            {
              ref: "shortcutComponent",
              attrs: {
                showBtnOk: false,
                showAddNew: false,
                modalClass: "lm-h-flex-modal",
              },
            },
            [
              _c(
                "div",
                { attrs: { slot: "component" }, slot: "component" },
                [
                  _vm.permiteParcelamentoVenda
                    ? _c("financeiro-component", {
                        ref: "financeiroComponent",
                        attrs: { isConsulta: true, permiteRemover: false },
                      })
                    : _c("recebimento-component", {
                        ref: "recebimentoComponent",
                        attrs: { permiteRemover: false },
                      }),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("impressaoComponent", { ref: "impressaoComponent" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }