import BaseModel from "./../base/baseModel";

export default class MetodoPagamentoModel extends BaseModel {
    id?: number = null;
    franquiaId?: number = null;
    descricao: string = null;
    numeroParcelas: number = null;
    ativo: boolean = null;
    primeiroIntervalo: number = null;
    intervalo: number = null;
}
