import Vue from "vue";

import { Component, Prop } from "@/decorators";

import "./portlet.scss";

@Component
export default class PortletComponent extends Vue {
    isFullScreen = false;

    @Prop({ type: String, required: true }) title: string;

    @Prop({ type: Boolean, default: true }) showFullScreenButton: boolean;
    @Prop({ type: Boolean, default: true }) showRemoveButton: boolean;
    @Prop({ type: Boolean, default: false }) showWarningButton: boolean;
    @Prop({ type: Boolean, default: false }) showErrorButton: boolean;
    @Prop({ type: Boolean, default: false }) showCheckedButton: boolean;

    @Prop({ type: String, default: "" }) titleWarning: string;
    @Prop({ type: String, default: "" }) titleError: string;

    public toggleFullScreen() {
        this.isFullScreen = !this.isFullScreen;
    }

    private resize() {
        this.$emit("resize");
    }

    private remove() {
        this.$emit("remove");
    }
}
