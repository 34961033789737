import Vue from "vue";

import buttonIncluirComponent from "@/components/child/form/button/buttonIncluir.vue";
import comboComponent from "@/components/child/form/combo.vue";
import dateTimePickerComponent from "@/components/child/form/datetimepicker.vue";
import gridComponent from "@/components/child/grid/grid.vue";
import { GridColumn, GridColumnType } from "@/components/child/grid/gridColumn";
import ShortcutComponent from "@/components/parent/shortcut/shortcut";
import shortcutComponent from "@/components/parent/shortcut/shortcut.vue";
import { Component, Prop } from "@/decorators";
import VendaEntregaStatus from "@/models/enum/VendaEntregaStatus";
import VendaEntregaModel from "@/models/vendaEntregaModel";
import VendaEntregaService from "@/services/venda/vendaEntregaService";

import "@/components/parent/crud/crud.scss";

@Component({
    components: {
        gridComponent,
        comboComponent,
        shortcutComponent,
        dateTimePickerComponent,
        buttonIncluirComponent,
    },
})
export default class TentativaEntregaComponent extends Vue {
    private shortcutComponent: ShortcutComponent = null;
    private vendaEntregaService = new VendaEntregaService();

    @Prop({ type: Boolean, default: false }) somenteConsulta: boolean;

    private vendaId: number = null;

    statusOptions: Array<Object> = [
        { text: "Entregue", value: VendaEntregaStatus.Entregue },
        { text: "Não Entregue", value: VendaEntregaStatus.NaoEntregue },
    ];

    gridData: Array<VendaEntregaModel> = [];

    private status: VendaEntregaStatus = null;
    private data = new Date();
    private motivo = "";

    get gridColumns(): Array<GridColumn> {
        return [
            new GridColumn("data", "Data", GridColumnType.DateTime, false, false, "", "", true),
            new GridColumn("statusDescricao", "Status", GridColumnType.String, false, false, "", "", true),
            new GridColumn("motivo", "Motivo", GridColumnType.String, false, false, "", "", true),
        ];
    }

    public async show(vendaId: number) {
        this.shortcutComponent = this.$refs.shortcutComponent as ShortcutComponent;

        this.vendaId = vendaId;

        this.gridData = await this.vendaEntregaService
            .listByVenda(this.vendaId)
            .resolveWithJSON<VendaEntregaModel[]>()
            .withLoading();

        this.shortcutComponent.title = this.$t("__.ts.tentativasEntrega").toString();

        this.shortcutComponent.show();
    }

    public async onAddTentativaEntrega() {
        if (this.status && this.data) {
            const tentativaEntrega = new VendaEntregaModel();
            tentativaEntrega.status = this.status;
            tentativaEntrega.statusDescricao = this.statusOptions.find(p => p["value"] === this.status)["text"];
            tentativaEntrega.data = this.data;
            tentativaEntrega.vendaId = this.vendaId;
            tentativaEntrega.motivo = this.motivo;

            this.gridData.push(tentativaEntrega);
            this.status = null;
            this.data = null;
            this.motivo = null;
        }
    }

    public async onRemoveTentativaEntrega(model: VendaEntregaModel) {
        this.gridData = this.gridData.filter(p => p != model);
    }

    public async onSave() {
        try {
            const sucesso = await this.vendaEntregaService
                .upsertByVenda(this.vendaId, this.gridData)
                .withLoading()
                .resolveWithoutJSON();

            if (sucesso) {
                await this.$showInclusaoUpdate(this.vendaId);
                this.$emit("onStatusSucesso");
                this.shortcutComponent.hide();

                const has3tentativas = await this.vendaEntregaService
                    .has3TentativasEntregaByClienteId(this.gridData[0].clienteId)
                    .withLoading()
                    .resolveWithJSON<boolean>();

                if (has3tentativas) {
                    this.$notifyWarn(this.$t("__.ts.tresTentativasEntrega").toString());
                }
            }
        } catch {}
    }
}
