import nfetch from "@/utils/events/nfetch";

import ConfiguracaoDotzModel from "../../models/dotz/configuracaoDotzModel";
import { IService } from "../base/iService";

export default class ConfiguracaoDotzService extends IService<ConfiguracaoDotzModel> {
    constructor() {
        super("ConfiguracaoDotz");
    }

    public GetCurrent(): Promise<Response> {
        //@ts-ignore
        return nfetch(`/${this.controllerName}/GetCurrent`, {
            credentials: "same-origin",
        })
            .then((response: Response) => {
                if (response.ok) {
                    return response;
                } else {
                    return this.mensagemErro(response, "Get");
                }
            })
            .catch(error => {});
    }

    public GetFranquias(): Promise<Response> {
        //@ts-ignore
        return nfetch(`/${this.controllerName}/GetFranquias`, {
            credentials: "same-origin",
        })
            .then((response: Response) => {
                if (response.ok) {
                    return response;
                } else {
                    return this.mensagemErro(response, "GetFranquias");
                }
            })
            .catch(error => {});
    }

    public GetFaixasPadroes(): Promise<Response> {
        //@ts-ignore
        return nfetch(`/${this.controllerName}/GetFaixasPadroes`, {
            credentials: "same-origin",
        })
            .then((response: Response) => {
                return response;
            })
            .catch(error => {});
    }
}
