import { render, staticRenderFns } from "./cancelamentoitem.vue?vue&type=template&id=8c1c73ee&"
import script from "./cancelamentoitem.ts?vue&type=script&lang=js&"
export * from "./cancelamentoitem.ts?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\a\\1\\s\\TafStore.PharmUP.Client\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('8c1c73ee')) {
      api.createRecord('8c1c73ee', component.options)
    } else {
      api.reload('8c1c73ee', component.options)
    }
    module.hot.accept("./cancelamentoitem.vue?vue&type=template&id=8c1c73ee&", function () {
      api.rerender('8c1c73ee', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/parent/manipulacao/cancelamentoitem.vue"
export default component.exports