var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      class: [_vm.classButton, { "activeScClick ": _vm.activeButton }],
      attrs: { type: "button" },
      on: { click: _vm.onClick },
    },
    [
      _vm.classIcon != null
        ? _c("i", {
            class: _vm.classIcon + " btScIcon",
            attrs: { "aria-hidden": "true" },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.shortKeyBt != null
        ? _c(
            "span",
            {
              directives: [
                {
                  name: "shortkey",
                  rawName: "v-shortkey.once",
                  value: ["alt", _vm.shortKeyBt],
                  expression: "['alt', shortKeyBt]",
                  modifiers: { once: true },
                },
                {
                  name: "shortkey",
                  rawName: "v-shortkey.focus",
                  value: true,
                  expression: "true",
                  modifiers: { focus: true },
                },
              ],
              staticClass: "keySpan",
              on: { shortkey: _vm.onScClick },
            },
            [
              _c(
                "span",
                {
                  class: [
                    "toolTipKey ",
                    { showToolTipKey: _vm.showToolTip },
                    { "toolTipKeyWithIcon ": _vm.classIcon != null },
                  ],
                },
                [
                  _vm._v(
                    "\n            " + _vm._s(_vm.shortKeyBt) + "\n        "
                  ),
                ]
              ),
            ]
          )
        : _vm._e(),
      _vm._v("\n    " + _vm._s(_vm.buttonName) + "\n"),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }