import Vue from "vue";

import moment from "moment";

import actionBarComponent from "@/components/child/actionBar/actionBar.vue";
import fieldsetComponent from "@/components/child/form/fieldset.vue";
import moedaComponent from "@/components/child/form/moeda.vue";
import { Component, Watch } from "@/decorators";
import TipoEtapa from "@/models/enum/tipoEtapa";
import VendaEtapaPCPModel from "@/models/pcp/vendaEtapaPCPModel";
import VendaPCPModel from "@/models/pcp/vendaPCPModel";
import VendaModel from "@/models/vendaModel";
import VendaPCPService from "@/services/pcp/vendaPCPService";
import VendaService from "@/services/vendaService";

import EtapasPCPComponent from "../etapas";
import etapasComponent from "../etapas.vue";

import "@/components/parent/crud/crud.scss";

@Component({
    components: {
        fieldsetComponent,
        moedaComponent,
        etapasComponent,
        actionBarComponent,
    },
})
export default class VendaPCPEditComponent extends Vue {
    private service = new VendaPCPService();

    private etapasComponent: EtapasPCPComponent = null;

    private model = new VendaPCPModel();
    private vendaModel = new VendaModel();
    private vendaCodigo = null;

    private clear() {
        this.model = new VendaPCPModel();
        this.vendaModel = new VendaModel();
        this.etapasComponent.clear();
    }

    //@ts-ignore
    @Watch("model.vendaEtapasPCP", { deep: true })
    private onChangeEtapas() {
        const etapas = [...this.model.vendaEtapasPCP];
        etapas.sort((a, b) => (moment(a.dataInicio).isAfter(b.dataInicio) ? 1 : -1));
        this.etapasComponent.updateGridData(etapas);
    }

    private async onChangeVendaCodigo() {
        this.clear();

        // Remove caracter enviado pelo código de barras
        this.vendaCodigo = this.vendaCodigo.toString().toLocaleUpperCase().replace("$M", "");

        if (this.vendaCodigo) {
            try {
                this.vendaModel = await new VendaService().getByCodigo(this.vendaCodigo).resolveWithJSON<VendaModel>();

                if (this.vendaModel) {
                    this.model = await this.service.getByVenda(this.vendaModel.id).resolveWithJSON<VendaPCPModel>();

                    if (this.model) {
                        this.model.vendaId = this.vendaModel.id;
                        this.model.venda = this.vendaModel;

                        const etapasOrdenadas = this.model.vendaEtapasPCP.sort((a, b) =>
                            a.etapaPCP.ordem > b.etapaPCP.ordem ? -1 : 1,
                        );

                        const etapasEmAberto = etapasOrdenadas.find(
                            p => p.dataFim == null || p.etapaPCP.tipo == TipoEtapa.Final,
                        );

                        if (etapasEmAberto) {
                            this.etapasComponent.etapaAtualId = etapasEmAberto.etapaPCPId;
                            this.etapasComponent.setEtapaDestinoId(etapasEmAberto.etapaPCP.ordem);
                        } else {
                            this.etapasComponent.setEtapaDestinoId(0);
                        }
                    }
                } else {
                    this.vendaCodigo = null;
                }
            } catch {}
        }
    }

    private cancel() {
        this.$router.back();
    }

    private async save() {
        try {
            const isValid = await this.etapasComponent.validate();
            if (isValid) {
                if (this.model.id == null) {
                    this.model.dataInicio = new Date();
                }

                if (this.etapasComponent.etapaAtualId) {
                    this.etapasComponent.finalizaEtapaAtual(this.model.vendaEtapasPCP);
                }

                const newEtapa = await this.etapasComponent.criaEtapaDestino(this.model, new VendaEtapaPCPModel());

                this.model.vendaEtapasPCP.push(newEtapa);

                const sucesso = await this.service[!this.model.id ? "insert" : "update"](
                    this.model,
                ).resolveWithoutJSON();

                if (sucesso) {
                    await this.$showInclusaoUpdate(this.model.id);
                    this.onChangeVendaCodigo();
                }
            }
        } catch {}
    }

    private mounted() {
        this.etapasComponent = this.$refs.etapasComponent as EtapasPCPComponent;

        if (this.$route.params.id) {
            this.vendaCodigo = this.$route.params.id;
            this.onChangeVendaCodigo().withLoading();
        }
    }
}
