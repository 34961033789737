import BaseModel from "./base/baseModel";
import ProdutoLoteFracionamentoItemModel from "./produtoLoteFracionamentoItemModel";

export default class ProdutoLoteFracionamentoModel extends BaseModel {
    id: number = null;
    produtoLoteId: number = null;
    codigo: number = null;
    itens: ProdutoLoteFracionamentoItemModel[] = [];
    quantidadePerda = 0;
    temperatura: string = null;
    umidade: string = null;
    salaFracionamento: string = null;
    quantidadeFracionada = 0;
    quantidadeEfetivamenteFracionada = 0;
    quantidadeRetiradaAmostragem = 0;
    horaInicio: Date = null;
    horaFim: Date = null;
    embalagensNaoUtilizadas = 0;
    observacao: string = null;
}
