import Vue from "vue";

import { Component, Prop } from "@/decorators";

import "./box.scss";

@Component
export default class BoxComponent extends Vue {
    @Prop(String) icon: string;
    @Prop({ type: String, required: true }) value: string;
    @Prop({ type: String, required: true }) title: string;
    @Prop({ type: Array, default: () => ["col-lg-3", "col-md-3", "col-sm-6", "col-xs-12"] }) dimensoes;
    @Prop({ type: Array, default: () => ["dashboard-stat", "dashboard-stat-v2", "blue"] }) classes;
}
