import VendaContinuoModel from "@/models/vendaContinuoModel";
import VendaItemModel from "@/models/vendaItemModel";

import nfetch from "../utils/events/nfetch";

import { IService } from "./base/iService";

export default class VendaContinuoService extends IService<VendaContinuoModel> {
    constructor() {
        super("VendaContinuo");
    }

    public GetCelularClienteByVendaId(vendaId: number): Promise<Response> {
        return nfetch(`/${this.controllerName}/GetCelularClienteByVendaId?id=${vendaId}`, {
            credentials: "same-origin",
        });
    }

    public Adiar(model: VendaContinuoModel, diasAdiar: number): Promise<Response> {
        //@ts-ignore
        return nfetch(`/${this.controllerName}/Adiar?diasAdiar=${diasAdiar}`, {
            method: "post",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json; charset=UTF-8",
                "XSRF-TOKEN": this.getCookie("XSRF-TOKEN"),
            },
            body: JSON.stringify(model),
        })
            .then((response: Response) => {
                if (response.ok) {
                    return response;
                } else {
                    return this.mensagemErro(response, "Save");
                }
            })
            .catch(() => {});
    }

    public Arquivar(model: VendaContinuoModel): Promise<Response> {
        //@ts-ignore
        return nfetch(`/${this.controllerName}/Arquivar`, {
            method: "post",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json; charset=UTF-8",
                "XSRF-TOKEN": this.getCookie("XSRF-TOKEN"),
            },
            body: JSON.stringify(model),
        })
            .then((response: Response) => {
                if (response.ok) {
                    return response;
                } else {
                    return this.mensagemErro(response, "Save");
                }
            })
            .catch(() => {});
    }

    public GerarOrcamento(model: VendaContinuoModel): Promise<Response> {
        //@ts-ignore
        return nfetch(`/${this.controllerName}/GerarOrcamento`, {
            method: "post",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json; charset=UTF-8",
                "XSRF-TOKEN": this.getCookie("XSRF-TOKEN"),
            },
            body: JSON.stringify(model),
        })
            .then((response: Response) => {
                if (response.ok) {
                    return response;
                } else {
                    return this.mensagemErro(response, "Save");
                }
            })
            .catch(() => {});
    }

    public getDataContinuo(item: VendaItemModel, dataConclusao: Date): Promise<Response> {
        return nfetch(`/${this.controllerName}/GetDataVencimentoContinuo?dataConclusao=${dataConclusao}`, {
            method: "post",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json; charset=UTF-8",
                "XSRF-TOKEN": this.getCookie("XSRF-TOKEN"),
            },
            body: JSON.stringify(item),
        });
    }
}
