import Vue from "vue";

import buttonIncluirComponent from "@/components/child/form/button/buttonIncluir.vue";
import comboComponent from "@/components/child/form/combo.vue";
import gridComponent from "@/components/child/grid/grid.vue";
import { GridColumn, GridColumnType } from "@/components/child/grid/gridColumn";
import { Component, Prop, Watch } from "@/decorators";
import EnsaioFichaTecnicaModel from "@/models/ensaioFichaTecnicaModel";
import EnsaiosModel from "@/models/ensaiosModel";
import PaginationModel from "@/models/paginationModel";
import EnsaioService from "@/services/ensaioService";

import EnsaioComponent from "../crud/ensaio/edit";
import ensaioComponent from "../crud/ensaio/edit.vue";

import { Options } from "./edit";

import "../crud/crud.scss";

@Component({
    components: {
        comboComponent,
        ensaioComponent,
        gridComponent,
        buttonIncluirComponent,
    },
})
export default class GridEnsaioComponent extends Vue {
    private ensaioComponent: EnsaioComponent = null;
    private ensaioService = new EnsaioService();

    private selected: number = null;
    private options: Array<Options> = [];
    gridOptions: Array<EnsaioFichaTecnicaModel> = [];

    @Prop({ type: Boolean, default: false }) soludibilidade: boolean;
    @Prop(Array) initialData;
    @Prop(Array) ensaiosOptions;
    @Prop(Boolean) somenteConsulta: boolean;

    get gridColumns(): Array<GridColumn> {
        return [
            new GridColumn("descricaoEnsaio", "Ensaios", GridColumnType.String),
            new GridColumn("especificacao", "Especifica\u00e7\u00e3o", GridColumnType.String, !this.somenteConsulta),
        ];
    }

    private async openNewEnsaio() {
        this.ensaioComponent = this.$refs.ensaioComponent as EnsaioComponent;
        this.ensaioComponent.load();
    }

    private onAddNewEnsaio() {
        this.onEmitChange(this.gridOptions);
        this.ensaioComponent.save();
    }

    private onEnsaioSaveOk() {
        this.loadEnsaios(true);
    }

    @Watch("initialData")
    private onChangeInitialData() {
        this.gridOptions = this.initialData as EnsaioFichaTecnicaModel[];
        this.gridOptions = this.gridOptions.map(p => ({
            ...p,
            descricaoEnsaio: p.ensaio.descricao,
        })) as EnsaioFichaTecnicaModel[];

        this.options = this.removeOptionsUtilizadas(this.options, this.gridOptions);
    }

    private async loadEnsaios(force = false) {
        let ensaios = this.ensaiosOptions as EnsaiosModel[];

        if (force) {
            const data = await this.ensaioService.combo().resolveWithJSON<PaginationModel<EnsaiosModel>>();
            ensaios = data.list;
        }

        this.options = ensaios
            .filter(ensaio => ensaio.soludibilidade === this.soludibilidade)
            .map(ensaio => ({ value: ensaio.id, text: ensaio.descricao }))
            .filter(ensaio => this.gridOptions.filter(e => e.ensaio.descricao === ensaio.text).length == 0);
    }

    private async onInclusaoEnsaio() {
        const ensaioComponent = this.$el.querySelector("#ensaioId") as HTMLSelectElement;
        if (this.selected > 0) {
            const alreadyExists = this.gridOptions.filter(ensaio => ensaio.id == this.selected).length > 0;

            if (!alreadyExists) {
                const ensaiosModel = new EnsaioFichaTecnicaModel();

                const ensaio = await this.ensaioService.get(this.selected).then(r => r.json() as Promise<EnsaiosModel>);
                ensaiosModel.ensaioId = ensaio.id;
                ensaiosModel.ensaio = ensaio;
                ensaiosModel.descricaoEnsaio = ensaioComponent.innerText;
                this.gridOptions.push(ensaiosModel);

                this.onEmitChange(this.gridOptions);

                this.options = this.removeOptionsUtilizadas(this.options, this.gridOptions);
                this.selected = -1;
            }
        }
    }

    private onRemoveEnsaio(model: EnsaioFichaTecnicaModel) {
        this.gridOptions = this.gridOptions.filter(ensaio => ensaio != model);

        this.onEmitChange(this.gridOptions);

        this.loadEnsaios();
    }

    private removeOptionsUtilizadas(options: Options[], utilizados: Array<EnsaioFichaTecnicaModel>): Array<Options> {
        return options.filter(o => utilizados.filter(u => o.text == u.ensaio.descricao).length == 0);
    }

    @Watch("gridOptions")
    private onGridOptionsChange() {
        this.onEmitChange(this.gridOptions);
    }

    @Watch("ensaiosOptions")
    private onEnsaiosOptionsChange() {
        this.loadEnsaios();
    }

    private onEmitChange(model: Array<EnsaioFichaTecnicaModel>) {
        this.$emit("onChange", model);
    }
}
