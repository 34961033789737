enum Modulos {
    Infraestrutura = 0,
    Financeiro = 1,
    Fiscal = 2,
    Estoque = 3,
    Venda = 4,
    Contabil = 5,
    Farmaceutico = 6,
    Manipulacao = 7,
    PCP = 8,
    Siproquim = 9,
    Rotulo = 10,
    Impressao = 11,
    Correios = 12,
}

export default Modulos;
