import Vue from "vue";

import DashboardService from "@/services/dashboardService";

type TiposGraficos = "bar" | "line";

type Config = {
    titulo: string;
    labels: string[];
    dados: any[];
    tipoGrafico: TiposGraficos;
    format: string;
    cor?: string;
    nomeSerie?: string;
    multiSerie?: boolean;
    mostrarLegenda?: boolean;
};

export default abstract class DashboardComponent extends Vue {
    protected service = new DashboardService();

    private graficos: any[];

    protected setGraficos(graficos: any[]) {
        this.graficos = graficos;
    }

    protected compensarZoomGrafico(zoom) {
        if (!zoom) return "100%";
        const numero = parseFloat(zoom.substr(0, zoom.length - 1));
        if (numero != null && numero != 100) {
            const zoomEq = (100 / numero) * 100;
            return zoomEq.toString() + "%";
        }
        return "100%";
    }

    protected abstract updateDashboard();

    protected getOpcoesGrafico({
        titulo,
        labels,
        dados,
        tipoGrafico,
        format,
        cor = "auto",
        nomeSerie = null,
        multiSerie = false,
        mostrarLegenda = false,
    }: Config): Object {
        let series = [];

        if (multiSerie) {
            const seriesNames = dados[0].series.map(d => d.label);
            seriesNames.forEach(serie => {
                series.push({
                    name: serie,
                    type: tipoGrafico,
                    data: dados.map(d => d.series.filter(s => s.label == serie).map(s => s.valor)[0]),
                });
            });
        } else {
            series = [
                {
                    name: nomeSerie,
                    type: tipoGrafico,
                    data: dados,
                    itemStyle: { color: cor },
                },
            ];
        }

        return {
            title: {
                text: titulo,
                left: "center",
                textStyle: { fontSize: 16 },
            },
            legend: {
                show: mostrarLegenda,
                data: series.map(s => s.name),
                top: 25,
                left: 10,
            },
            tooltip: {
                formatter: format,
                position: point => [point[0] + 20, point[1]],
            },
            xAxis: {
                type: "category",
                data: labels,
            },
            yAxis: {
                type: "value",
            },
            grid: {
                bottom: 20,
            },
            series: series,
        };
    }

    protected getOpcoesGraficoPie(titulo, dados) {
        return {
            title: {
                text: titulo,
                left: "center",
                textStyle: { fontSize: 16 },
            },
            tooltip: {
                formatter: "<b>{b}</b><br/>{c} %",
                position: point => [point[0] + 20, point[1]],
                trigger: "item",
            },
            series: [
                {
                    name: "Prescritores",
                    type: "pie",
                    radius: ["40%", "80%"],
                    avoidLabelOverlap: false,
                    itemStyle: {
                        borderRadius: 10,
                        borderColor: "#fff",
                        borderWidth: 2,
                    },
                    label: {
                        show: false,
                        position: "center",
                    },
                    data: dados.map(d => {
                        return { name: d.label, value: d.valor.toFixed(2) };
                    }),
                    emphasis: {
                        itemStyle: {
                            shadowBlur: 10,
                            shadowOffsetX: 0,
                            shadowColor: "rgba(0, 0, 0, 0.5)",
                        },
                    },
                },
            ],
        };
    }

    protected getMonthName(monthOrDate: string): string {
        const [month, year] = monthOrDate.split("/");

        let monthName: string;
        switch (month) {
            case "1":
                monthName = "Janeiro";
                break;
            case "2":
                monthName = "Fevereiro";
                break;
            case "3":
                monthName = "Março";
                break;
            case "4":
                monthName = "Abril";
                break;
            case "5":
                monthName = "Maio";
                break;
            case "6":
                monthName = "Junho";
                break;
            case "7":
                monthName = "Julho";
                break;
            case "8":
                monthName = "Agosto";
                break;
            case "9":
                monthName = "Setembro";
                break;
            case "10":
                monthName = "Outubro";
                break;
            case "11":
                monthName = "Novembro";
                break;
            case "12":
                monthName = "Dezembro";
                break;
        }
        if (year) {
            return `${monthName}/${year}`;
        }
        return monthName;
    }

    protected showLoadingGraficos() {
        for (const grafico of this.graficos) {
            grafico.showLoading("default", {
                text: this.$t("__.ts.carregando"),
                color: "#000",
            });
        }
    }

    protected hideLoadingGraficos() {
        for (const grafico of this.graficos) {
            grafico.hideLoading();
        }
    }

    protected abstract convertToString(numero: number);
}
