import Vue from "vue";

import actionBarComponent from "@/components/child/actionBar/actionBar.vue";
import checkboxComponent from "@/components/child/form/checkbox.vue";
import comboComponent from "@/components/child/form/combo.vue";
import { Component } from "@/decorators";
import TiposMovimentacao from "@/models/enum/tiposMovimentacao";
import ContaGerencialClassificacaoModel from "@/models/financeiro/contaGerencialClassificacaoModel";
import ContaGerencialModel from "@/models/financeiro/contaGerencialModel";
import PaginationModel from "@/models/paginationModel";
import ContaGerencialClassificacaoService from "@/services/financeiro/contaGerencialClassificacaoService";
import ContaGerencialService from "@/services/financeiro/contaGerencialService";

import "../crud.scss";

@Component({
    components: {
        checkboxComponent,
        comboComponent,
        actionBarComponent,
    },
})
export default class ContaGerencialEditComponent extends Vue {
    private service = new ContaGerencialService();

    model = new ContaGerencialModel();
    modelId = 0;

    classificacoesOptions: Array<Object> = [];
    tiposMovimentoOptions: Array<Object> = [
        { text: "Entrada", value: TiposMovimentacao.Entrada },
        { text: "Saida", value: TiposMovimentacao.Saida },
    ];

    private async load() {
        this.model = new ContaGerencialModel();

        if (this.modelId > 0) {
            try {
                const data = await this.service.get(this.modelId).withLoading().resolveWithJSON<ContaGerencialModel>();

                this.model.updateFrom(data);
            } catch {
                this.$router.back();
            }
        } else {
            this.model.ativo = true;
        }
    }

    private async loadClassificacoes() {
        try {
            const data = await new ContaGerencialClassificacaoService()
                .combo()
                .resolveWithJSON<PaginationModel<ContaGerencialClassificacaoModel>>();
            this.classificacoesOptions = data.list.map(item => ({ value: item.id, text: item.descricao }));
        } catch {}
    }

    private async save() {
        try {
            const isValid = await this.$validator.validateAll();
            if (isValid) {
                const sucesso = await this.service[!this.model.id ? "insert" : "update"](this.model)
                    .withLoading()
                    .resolveWithoutJSON();

                if (sucesso) {
                    await this.$showInclusaoUpdate(this.model.id);
                    this.$router.back();
                }
            } else {
                this.$focusErrorField();
            }
        } catch {}
    }

    private cancel() {
        this.$router.back();
    }

    private mounted() {
        Promise.all([this.loadClassificacoes()])
            .withLoading()
            .then(() => {
                if (this.$route.params.id) {
                    this.modelId = +this.$route.params.id;
                }
                this.load();
            })
            .catch(() => {});
    }
}
