import PreLoadPackModel from "../models/auxiliar/preLoadPackModel";
import nfetch from "../utils/events/nfetch";

import { IService } from "./base/iService";

export default class PreLoadPackService extends IService<PreLoadPackModel> {
    constructor() {
        super("PreLoadPack");
    }

    public loadPackLists(
        loadUnidadeMedida = false,
        loadFormaFarmaceutica = false,
        loadPosologia = false,
        loadKitEmbalagem = false,
        loadFormulaPadraoDesmembrados = false,
        loadProdutos = false,
        loadEstoque = false,
        loadLocaisEntrega = false,
        loadCampanhas = false,
        loadCapsulas = false,
        loadVendedores = false,
        loadRotulos = false,
    ): Promise<Response> {
        //@ts-ignore
        return nfetch(
            `/${this.controllerName}/LoadPackLists?loadUnidadeMedida=${loadUnidadeMedida}&loadFormaFarmaceutica=${loadFormaFarmaceutica}&loadPosologia=${loadPosologia}&loadKitEmbalagem=${loadKitEmbalagem}&loadFormulaPadraoDesmembrados=${loadFormulaPadraoDesmembrados}&loadProdutos=${loadProdutos}&loadEstoque=${loadEstoque}&loadLocaisEntrega=${loadLocaisEntrega}&loadCampanhas=${loadCampanhas}&loadCapsulas=${loadCapsulas}&LoadVendedores=${loadVendedores}&loadRotulos=${loadRotulos}`,
            {
                credentials: "same-origin",
            },
        );
    }
}
