import Vue from "vue";

import Moment from "moment";

import { Component, Prop } from "@/decorators";

@Component
export default class VerComponent extends Vue {
    @Prop({ type: Object, default: {} }) data;

    private getDateString(dt: Date) {
        return Moment(dt).format("DD/MM/YYYY");
    }
}
