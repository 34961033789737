import Vue from "vue";
import { mapMutations, mapState } from "vuex";

import buttonIncluirComponent from "@/components/child/form/button/buttonIncluir.vue";
import decimalComponent from "@/components/child/form/decimal.vue";
import fieldsetComponent from "@/components/child/form/fieldset.vue";
import gridComponent from "@/components/child/grid/grid.vue";
import { GridColumn, GridColumnType } from "@/components/child/grid/gridColumn";
import { Component } from "@/decorators";
import NotaFiscalEletronicaEmissaoModel from "@/models/notaFiscalEletronicaEmissaoModel";
import VolumeModel from "@/models/volumeModel";
import { AppState, Mutations } from "@/store/store";

import "../crud/crud.scss";

@Component({
    components: {
        gridComponent,
        decimalComponent,
        fieldsetComponent,
        buttonIncluirComponent,
    },
    computed: mapState<AppState>({
        nota: state => state.nfeContext.nota,
        consulta: state => state.nfeContext.consulta,
    }),
    methods: mapMutations(["SET_NOTA"] as Mutations),
})
export default class VolumesComponent extends Vue {
    // State computed props
    nota: NotaFiscalEletronicaEmissaoModel;
    consulta: boolean;
    SET_NOTA: (nota: NotaFiscalEletronicaEmissaoModel) => void;

    private model = new VolumeModel();
    private editingModel = false;
    private indexEdditingModel = -1;

    private gridColumns: Array<GridColumn> = [
        new GridColumn("quantidade", "Quantidade", GridColumnType.Integer),
        new GridColumn("especie", "Especie do volume", GridColumnType.String),
        new GridColumn("marca", "Marca do volume", GridColumnType.String),
        new GridColumn("numeracao", "Número do volume", GridColumnType.String),
        new GridColumn("pesoLiquido", "Peso Líquido", GridColumnType.Decimal),
        new GridColumn("pesoBruto", "Peso Bruto", GridColumnType.Decimal),
    ];

    private async onAdicionarItem() {
        const isValid = await this.$validator.validateAll();

        if (isValid) {
            if (!this.editingModel) {
                this.nota.volumes.push(this.model);
            } else {
                if (this.indexEdditingModel >= 0) {
                    this.nota.volumes[this.indexEdditingModel].quantidade = this.model.quantidade;
                    this.nota.volumes[this.indexEdditingModel].especie = this.model.especie;
                    this.nota.volumes[this.indexEdditingModel].marca = this.model.marca;
                    this.nota.volumes[this.indexEdditingModel].numeracao = this.model.numeracao;
                    this.nota.volumes[this.indexEdditingModel].pesoLiquido = this.model.pesoLiquido;
                    this.nota.volumes[this.indexEdditingModel].pesoBruto = this.model.pesoBruto;
                }
            }
            this.SET_NOTA(this.nota);

            this.clear();
        }
    }

    private async onEditItem(volume: VolumeModel) {
        this.editingModel = true;
        this.indexEdditingModel = this.nota.volumes.indexOf(volume);

        if (this.indexEdditingModel >= 0) {
            this.model.quantidade = this.nota.volumes[this.indexEdditingModel].quantidade;
            this.model.especie = this.nota.volumes[this.indexEdditingModel].especie;
            this.model.marca = this.nota.volumes[this.indexEdditingModel].marca;
            this.model.numeracao = this.nota.volumes[this.indexEdditingModel].numeracao;
            this.model.pesoLiquido = this.nota.volumes[this.indexEdditingModel].pesoLiquido;
            this.model.pesoBruto = this.nota.volumes[this.indexEdditingModel].pesoBruto;
        }
        this.SET_NOTA(this.nota);
    }

    private onRemoveItem(volume: VolumeModel) {
        const index = this.nota.volumes.indexOf(volume);
        this.nota.volumes.splice(index, 1);
        this.SET_NOTA(this.nota);
    }

    private clear() {
        this.editingModel = false;
        this.indexEdditingModel = -1;

        this.model = new VolumeModel();
        this.$validator.reset();
    }
}
