import BaseModel from "./base/baseModel";
import ServicoFiscalItemModel from "./servicoFiscalItemModel";

export default class ServicoModel extends BaseModel {
    id?: number = null;
    franquiaId?: number = null;
    atividade: string = null;
    codigoLCMunicipio: string = null;
    descricao: string = null;
    servicoFiscalItemId: number = null;
    servicoFiscalItem: ServicoFiscalItemModel = null;
    aliquota: number = null;
    issRetido = false;
}
