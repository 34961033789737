import BaseModel from "./base/baseModel";
import Setor from "./enum/setor";
import FranquiaModel from "./franquiaModel";
import UsuarioModel from "./usuarioModel";

export default class UsuarioFranquiaModel extends BaseModel {
    id?: number = null;

    usuarioId: number = null;
    usuario: UsuarioModel = null;
    franquiaId: number = null;
    franquia: FranquiaModel = null;
    franquiaDescricao: string = null;

    setor: Setor = null;
}
