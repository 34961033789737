import Vue from "vue";

import actionBarComponent from "@/components/child/actionBar/actionBar.vue";
import dateTimePickerComponent from "@/components/child/form/datetimepicker.vue";
import fieldsetComponent from "@/components/child/form/fieldset.vue";
import moedaComponent from "@/components/child/form/moeda.vue";
import { ModalButtonAction } from "@/components/child/modal/modalButtonAction";
import { Component } from "@/decorators";
import DevolucaoItemModel from "@/models/devolucaoItemModel";
import DevolucaoModel from "@/models/devolucaoModel";
import NotaFiscalEntradaItemModel from "@/models/notaFiscalEntradaItemModel";
import NotaFiscalEntradaModel from "@/models/notaFiscalEntradaModel";
import DevolucaoService from "@/services/devolucaoService";
import IndicesConversoesUnidadeMedidasService from "@/services/indicesConversoesUnidadeMedidaService";
import NotaFiscalEntradaService from "@/services/notaFiscalEntradaService";

import NfeEditComponent from "../../nfe/edit";
import nfeComponent from "../../nfe/edit.vue";
import ShortcutComponent from "../../shortcut/shortcut";
import shortcutComponent from "../../shortcut/shortcut.vue";

import itemComponent from "./item.vue";

import "../crud.scss";

@Component({
    components: {
        fieldsetComponent,
        dateTimePickerComponent,
        moedaComponent,
        nfeComponent,
        shortcutComponent,
        itemComponent,
        actionBarComponent,
    },
})
export default class DevolucaoCompraEditComponent extends Vue {
    private service = new DevolucaoService();
    private notaFiscalEntradaService = new NotaFiscalEntradaService();
    private nfeComponent: NfeEditComponent = null;
    private shortcutComponentEmissao: ShortcutComponent = null;

    model = new NotaFiscalEntradaModel();
    indicesConversoesUnidadeMedidasService = new IndicesConversoesUnidadeMedidasService();
    modelId = 0;
    valorTotalADevolver = 0;

    extraButtonActionNfe: Array<ModalButtonAction> = [
        new ModalButtonAction("resumo", "Resumo Pré-Emissão", true, "l", " btn-info"),
    ];

    private async load() {
        this.model = new NotaFiscalEntradaModel();

        if (this.modelId > 0) {
            try {
                const data = await this.notaFiscalEntradaService
                    .get(this.modelId)
                    .resolveWithJSON<NotaFiscalEntradaModel>();

                this.model.updateFrom(data);
            } catch {}
        }
    }

    private onChangeItem(item: NotaFiscalEntradaItemModel, index: number) {
        this.model.notaFiscalEntradaItens[index] = item;

        this.valorTotalADevolver = this.model.notaFiscalEntradaItens.reduce(
            (acc, i) => acc + parseFloat((i.valorUnitario * i.quantidadeADevolver).toFixed(2)),
            0,
        );
    }

    private async save() {
        const itensComQuantidadeADevolver = this.model.notaFiscalEntradaItens.filter(i => i.quantidadeADevolver > 0);
        if (itensComQuantidadeADevolver.length == 0) {
            return this.$showError("Devolução de compra", "Indique ao menos um item com quantidade a devolver.");
        }

        const itensComQuantidadeADevolverMaior = this.model.notaFiscalEntradaItens.filter(
            i => i.quantidadeADevolver > i.quantidade,
        );
        if (itensComQuantidadeADevolverMaior.length > 0) {
            return this.$showError(
                "Devolução de compra",
                `Item ${itensComQuantidadeADevolverMaior[0].produtoDescricao} com quantidade a devolver maior que quantidade comprada.`,
            );
        }

        const itensSemLote = itensComQuantidadeADevolver.filter(i => i.produtoLoteId == null);
        if (itensSemLote.length > 0) {
            return this.$showError(
                "Devolução de compra",
                `Item ${itensSemLote[0].produtoDescricao} com quantidade a devolver sem lote informado.`,
            );
        }

        const itensSemQuantidadeDisponivelNoLote = itensComQuantidadeADevolver.find(
            i => i.quantidadeADevolver > i.produtoLote.quantidadeAtual,
        );
        if (itensSemQuantidadeDisponivelNoLote) {
            const lote = itensSemQuantidadeDisponivelNoLote.produtoLote;

            return this.$showError(
                "Devolução de compra",
                `Quantidade a devolver ${itensSemQuantidadeDisponivelNoLote.quantidadeADevolver} do produto ${itensSemQuantidadeDisponivelNoLote.produtoDescricao}  é maior que a quantidade ${lote.quantidadeAtual} disponível no lote ${lote.descricao}`,
            );
        }

        this.shortcutComponentEmissao.title = "Emissão de Nota Fiscal de devolução";
        this.shortcutComponentEmissao.show();

        await this.nfeComponent.loadNotaDevolucaoCompra(this.model, itensComQuantidadeADevolver).withLoading();
    }

    private cancel() {
        this.$router.back();
    }

    private async onEmitirNota() {
        try {
            const nota = await this.nfeComponent.emitir(false);
            if (nota) {
                const devolucao = new DevolucaoModel();
                devolucao.notaFiscalEntradaOrigemId = this.model.id;
                devolucao.notaFiscalSaidaDevolucaoId = nota.id;
                devolucao.dataDevolucao = new Date();

                devolucao.itens = nota.itens.map(i => {
                    const devolucaoItem = new DevolucaoItemModel();
                    const notaFiscalEntradaItem = this.model.notaFiscalEntradaItens.find(
                        p => p.produtoId == i.produtoId && p.quantidadeADevolver > 0,
                    );
                    devolucaoItem.produtoLoteId = i.produtoLoteId || notaFiscalEntradaItem?.produtoLoteId;
                    devolucaoItem.quantidadeDevolvida = notaFiscalEntradaItem?.quantidadeADevolverEstoque;
                    devolucaoItem.unidadeMedidaId = notaFiscalEntradaItem.produto.unidadeMedidaEstoqueId;
                    devolucaoItem.valorUnitario = i.valorUnitario;
                    return devolucaoItem;
                });

                devolucao.notaFiscalEntradaOrigem = null;
                devolucao.notaFiscalSaidaDevolucao = null;
                devolucao.movimentacaoEstoque = null;

                const sucesso = await this.service.insertDevolucaoCompra(devolucao).resolveWithoutJSON();
                if (sucesso) {
                    this.$showSuccess("Devolução!", "Devolução feita com sucesso.");
                }

                this.$router.back();
            }
        } catch {}
    }

    private async onExtraButtonActionClickNfe(name: string) {
        if (name == "resumo") {
            this.nfeComponent.resumo();
        }
    }

    private mounted() {
        this.nfeComponent = this.$refs.nfeComponent as NfeEditComponent;
        this.shortcutComponentEmissao = this.$refs.shortcutComponentEmissao as ShortcutComponent;

        if (this.$route.params.id) {
            this.modelId = +this.$route.params.id;
            this.load().withLoading();
        }
    }
}
