import Vue from "vue";
import { mapGetters } from "vuex";

import comboComponent from "@/components/child/form/combo.vue";
import dateTimePickerComponent from "@/components/child/form/datetimepicker.vue";
import decimalComponent from "@/components/child/form/decimal.vue";
import decimalComSinalCustomComponent from "@/components/child/form/decimalComSinalCustom.vue";
import fieldsetComponent from "@/components/child/form/fieldset.vue";
import textareaComponent from "@/components/child/form/textarea.vue";
import { Component, Watch } from "@/decorators";
import ConfiguracaoFranquiaModel from "@/models/configuracaoFranquia/configuracaoFranquiaModel";
import Configuracoes from "@/models/enum/configuracao/configuracoes";
import StatusProdutoLote from "@/models/enum/statusProdutoLote";
import PaginationModel from "@/models/paginationModel";
import ProdutoLoteFracionamentoItemModel from "@/models/produtoLoteFracionamentoItemModel";
import ProdutoLoteFracionamentoModel from "@/models/produtoLoteFracionamentoModel";
import ProdutoLoteModel from "@/models/produtoLoteModel";
import UnidadeMedidaModel from "@/models/unidadeMedidaModel";
import IndicesConversoesUnidadeMedidasService from "@/services/indicesConversoesUnidadeMedidaService";
import ProdutoLoteFracionamentoService from "@/services/produtoLoteFracionamentoService";
import ProdutoLoteService from "@/services/produtoLoteService";
import UnidadeMedidaService from "@/services/unidadeMedidaService";
import { Getters } from "@/store/store";
import arithmeticHelper from "@/utils/common/arithmeticHelper";
import { getUnidadeMedidaCombo } from "@/utils/common/combo/combotext";
import Delay from "@/utils/common/delay";

import ShortcutComponent from "../../shortcut/shortcut";
import shortcutComponent from "../../shortcut/shortcut.vue";

import FracionamentoKitEmbalagemEditComponent from "./fracionamentoKitEmbalagem";
import fracionamentoKitEmbalagemComponent from "./fracionamentoKitEmbalagem.vue";

import "../crud.scss";
import "./fracionamento.scss";

@Component({
    components: {
        comboComponent,
        dateTimePickerComponent,
        decimalComponent,
        decimalComSinalCustomComponent,
        fieldsetComponent,
        fracionamentoKitEmbalagemComponent,
        shortcutComponent,
        textareaComponent,
    },
    computed: {
        ...mapGetters(["GET_CONFIG_FRANQUIA"] as Getters),
    },
})
export default class FracionamentoEditComponent extends Vue {
    // State computed props
    GET_CONFIG_FRANQUIA: (configuracao: Configuracoes) => ConfiguracaoFranquiaModel;

    private service = new ProdutoLoteFracionamentoService();
    private produtoLoteService = new ProdutoLoteService();

    private timer: number = null;

    produtoLoteModel = new ProdutoLoteModel();
    model = new ProdutoLoteFracionamentoModel();
    shortcutComponent: ShortcutComponent = null;
    indicesConversoesUnidadeMedidasService = new IndicesConversoesUnidadeMedidasService();

    quantidadeTotal = 0;
    unidadeMedidaId: number = null;
    unidadeMedidaDescricao = "";
    unidadeMedidaOptions = [];
    somenteConsulta = false;

    private async onRemoveItem(item: ProdutoLoteFracionamentoItemModel) {
        this.model.itens = this.model.itens.filter(p => p != item);

        await this.calcularQuantidadeTotal();
    }

    private onInclusaoItem() {
        const item = new ProdutoLoteFracionamentoItemModel();
        item.unidadeMedidaId = this.unidadeMedidaId;
        item.quantidadeEmbalagem = 1;
        this.model.itens.push(item);
    }

    private async onChangeQuantidade(item: ProdutoLoteFracionamentoItemModel) {
        item.quantidadeEmbalagem = item.quantidade;
        await this.onCalcularTotal(item);

        await this.onChangeQuantidadeEmbalagem();
    }

    private async onCalcularTotal(item: ProdutoLoteFracionamentoItemModel) {
        item.total = item.quantidade * item.volume;

        await this.calcularQuantidadeTotal();
    }

    private async onChangeQuantidadeEmbalagem() {
        await this.updateKitEmbalagem();
    }

    //@ts-ignore
    @Watch("model.quantidadeFracionada")
    //@ts-ignore
    @Watch("model.quantidadePerda")
    //@ts-ignore
    @Watch("model.quantidadeRetiradaAmostragem")
    private onChangeItensEmbalagens() {
        if (this.model.quantidadePerda > 0 || this.model.quantidadeRetiradaAmostragem > 0) {
            this.model.quantidadeEfetivamenteFracionada = arithmeticHelper.round(
                this.model.quantidadeFracionada - this.model.quantidadePerda - this.model.quantidadeRetiradaAmostragem,
                4,
            );
        } else {
            this.model.quantidadeEfetivamenteFracionada = 0;
        }
    }

    private async calcularQuantidadeTotal() {
        clearTimeout(this.timer);

        this.timer = setTimeout(async () => {
            this.quantidadeTotal = 0;
            for await (const item of this.model.itens) {
                const indice = await this.indicesConversoesUnidadeMedidasService.getIndiceConversao(
                    item.unidadeMedidaId,
                    this.unidadeMedidaId,
                );

                this.quantidadeTotal += arithmeticHelper.round(item.total * indice, 4);
            }
        }, 500);
    }

    public async show(model: ProdutoLoteModel) {
        const produtoLote = await this.produtoLoteService
            .get(model.id)
            .withLoading()
            .resolveWithJSON<ProdutoLoteModel>();

        this.somenteConsulta = produtoLote.statusProdutoLote == StatusProdutoLote.Liberado;

        this.produtoLoteModel.updateFrom(produtoLote);

        this.model = new ProdutoLoteFracionamentoModel();

        this.unidadeMedidaId = produtoLote.unidadeMedidaId;
        this.unidadeMedidaDescricao = this.unidadeMedidaOptions.find(p => p["value"] == this.unidadeMedidaId)["sigla"];

        while (!this.$refs.shortcutComponent) await Delay(100);
        this.shortcutComponent = this.$refs.shortcutComponent as ShortcutComponent;

        try {
            const produtoLoteFracionamento = await this.service
                .getFracionamentoByLote(model.id)
                .withLoading()
                .resolveWithJSON<ProdutoLoteModel>();

            if (produtoLoteFracionamento) {
                this.model.updateFrom(produtoLoteFracionamento);
            }
        } catch {
            if (this.somenteConsulta) {
                this.shortcutComponent.hide();
                return await this.$showError(this.$t("__.ts.erro"), "Este lote não foi fracionado pelo sistema");
            }

            this.model.itens = [];
            this.model.produtoLoteId = this.produtoLoteModel.id;
            this.onInclusaoItem();
        }

        if (this.model.quantidadeFracionada == 0) {
            const estoqueId = this.GET_CONFIG_FRANQUIA(Configuracoes.EstoquePadrao).estoqueId;
            this.model.quantidadeFracionada = this.produtoLoteModel.estoqueProdutoLote.find(
                p => p.estoqueId == estoqueId,
            )?.estoqueAtual;

            if (!this.model.quantidadeFracionada) {
                this.model.quantidadeFracionada = this.produtoLoteModel.quantidadeAtual;
            }
        }

        await this.updateKitEmbalagem();

        this.shortcutComponent.title = `Fracionamento de ${this.model.quantidadeFracionada} ${this.unidadeMedidaDescricao} do Lote ${this.produtoLoteModel.descricao}`;

        this.shortcutComponent.show();
    }

    private async onFracionar() {
        try {
            const isValid = await this.validateAll();

            if (isValid) {
                await this.calcularQuantidadeTotal();

                const quantidadeTotalUtilizada = arithmeticHelper.round(this.quantidadeTotal, 4);
                const quantidadeComparada =
                    this.model.quantidadeEfetivamenteFracionada > 0
                        ? this.model.quantidadeEfetivamenteFracionada
                        : this.model.quantidadeFracionada;

                if (quantidadeTotalUtilizada != quantidadeComparada) {
                    return this.$showAlert({
                        icon: "warning",
                        title: this.$t("__.ts.atencao") as string,
                        html:
                            this.model.quantidadeEfetivamenteFracionada > 0
                                ? `Quantidade total <b>${quantidadeTotalUtilizada} ${this.unidadeMedidaDescricao}</b> é diferente da quantidade efetivamente fracionada <b>${this.model.quantidadeEfetivamenteFracionada} ${this.unidadeMedidaDescricao}</b>, por favor atualize todas as quantidades corretamente`
                                : `Quantidade total <b>${quantidadeTotalUtilizada} ${this.unidadeMedidaDescricao}</b> é diferente da quantidade fracionada <b>${this.model.quantidadeFracionada} ${this.unidadeMedidaDescricao}</b>, por favor atualize todas as quantidades corretamente`,
                    });
                }

                const sucesso = await this.service[!this.model.id ? "insert" : "update"](this.model)
                    .withLoading()
                    .resolveWithoutJSON();
                if (sucesso) {
                    await this.$showSuccess(
                        this.$t("__.ts.sucesso"),
                        this.$t("__.Crud.produtolote.fracionamento_vue_html.sucessoFracionamento"),
                    );

                    this.$emit("onSucessoFracionamento");
                    this.shortcutComponent.hide();
                }
            }
        } catch {}
    }

    private async validateAll() {
        let isValid = await this.$validator.validateAll();

        await this.loadEmabalagemComponent();

        if (isValid) {
            for (let index = 0; index < this.model.itens.length; index++) {
                const element = this.$refs.indexEmbalagem[index] as FracionamentoKitEmbalagemEditComponent;

                isValid = isValid && (await element.validateAll());
            }
        }

        return isValid;
    }

    private onChangeEmbalagem(
        itemFracionamento: ProdutoLoteFracionamentoItemModel,
        item: ProdutoLoteFracionamentoItemModel,
    ) {
        item.id = itemFracionamento.id;
        item.itensEmbalagens = itemFracionamento.itensEmbalagens;
        item.kitEmbalagemId = itemFracionamento.kitEmbalagemId;

        this.updateKitEmbalagem();
    }

    private async updateKitEmbalagem() {
        await this.loadEmabalagemComponent();

        for (let index = 0; index < this.model.itens.length; index++) {
            const element = this.$refs.indexEmbalagem[index] as FracionamentoKitEmbalagemEditComponent;

            element.setModel(this.model.itens[index]);
        }
    }

    private async loadEmabalagemComponent() {
        while (!this.$refs.indexEmbalagem) await Delay(50);

        for (let index = 0; index < this.model.itens.length; index++) {
            while (!this.$refs.indexEmbalagem[index]) await Delay(50);
        }
    }

    private getUnidadeMedidaItem(item: ProdutoLoteFracionamentoItemModel) {
        if (this.unidadeMedidaOptions.some(p => p["value"] == item.unidadeMedidaId)) {
            return this.unidadeMedidaOptions.find(p => p["value"] == item.unidadeMedidaId)["sigla"];
        }
        return "";
    }

    private async loadUnidadeMedida() {
        try {
            const data = await new UnidadeMedidaService()
                .combo()
                .resolveWithJSON<PaginationModel<UnidadeMedidaModel>>();
            this.unidadeMedidaOptions = data.list.map(item => getUnidadeMedidaCombo(item));
        } catch {}
    }

    private mounted() {
        Promise.all([this.loadUnidadeMedida()])
            .withLoading()
            .then(() => {})
            .catch(() => {});
    }
}
