import Vue from "vue";

import { Component, Prop, Watch } from "@/decorators";
import ConfiguracaoRotuloCamposModel from "@/models/configuracaoRotuloCamposModel";
import ConfiguracaoRotuloModel from "@/models/configuracaoRotuloModel";
import Alinhamento from "@/models/enum/alinhamento";
import { Fonte } from "@/models/enum/fonte";
import PosicaoDadosFranquia from "@/models/enum/rotulo/posicaoDadosFranquia";
import PosicaoRotulo from "@/models/enum/rotulo/posicaoRotulo";
import CodigosIdentificacaoService from "@/services/codigosIdentificacaoService";

// #################################################################################################################
// IMPORTANTE: O estilo do rótulo deve ser inline usando o atributo style. Caso contrário, o rótulo será renderizado
//             sem estilo na janela de impressão ou no QZ Tray.
// #################################################################################################################

@Component
export default class RotuloComponent extends Vue {
    @Prop(ConfiguracaoRotuloModel) model: ConfiguracaoRotuloModel;
    @Prop(String) manipulacaoKey: string;
    @Prop(Boolean) CalledByShortCut: boolean;
    @Prop({ type: Boolean, default: false }) selected: boolean;
    @Prop({ type: Number, default: 1 }) scaleFactor: number;

    private codigosIdentificacaoService = new CodigosIdentificacaoService();

    private qrCodeBase64 = "";
    private barCodeBase64 = "";

    private getStringExibicao(model: ConfiguracaoRotuloCamposModel) {
        const label = model.label ?? "";

        if (this.CalledByShortCut) {
            if (model.value && label) {
                return `${label}: ${model.value ?? ""}`;
            }
            if (model.value) {
                return model.value;
            }
        } else if (label == "") {
            return model.campo;
        }
        return label;
    }

    // Para uso no template
    private PosicaoRotulo = PosicaoRotulo;

    private get linhas() {
        const array = this.model.campos;
        const tamanhoLinha = this.model.quantidadeColunas;
        const linhas: Array<Array<ConfiguracaoRotuloCamposModel>> = [];
        let totalColunas = 0;
        for (const item of array) {
            item.quantidadeColunas = Math.min(item.quantidadeColunas, tamanhoLinha);
            const linhaAtual = Math.floor(totalColunas / tamanhoLinha);
            linhas[linhaAtual] = [...(linhas[linhaAtual] || []), item];
            totalColunas += item.quantidadeColunas;
        }
        return linhas;
    }

    // Style getters usados nos atributos style do template
    // ==============================================================================================

    private adjustFontSize(size: number) {
        return size / 4;
    }

    private adjustLineHeight(lineHeight: number) {
        return lineHeight || 1.4;
    }

    private getFontFamily(fonteId: Fonte) {
        switch (fonteId) {
            case 1:
                return "Calibri";
            case 2:
                return "Space Mono";
            case 3:
                return "Courier Prime";
            case 4:
                return "Helvetica";
            case 5:
                return "Times";
            default:
                return "Arial";
        }
    }

    private flexBaseStyle(override: any = {}) {
        return [{ display: "flex", alignItems: "center", justifyContent: "center", overflow: "hidden" }, override];
    }

    private get frameExternoStyle() {
        return this.flexBaseStyle({
            // O tamanho da fonte (que é herdado pelos elementos filhos) é a unidade base para o tamanho de todos outros elementos
            fontSize: `${this.scaleFactor * 1}mm`,
            width: `${this.model.largura}em`,
            height: `${this.model.altura}em`,
            alignItems: "flex-start",
            breakInside: "avoid",
        });
    }

    private get frameInternoStyle() {
        return this.flexBaseStyle({
            flex: "1",
            margin: `${this.model.paddingVertical || 0}em  ${this.model.paddingHorizontal || 0}em`,
            flexWrap: "wrap",
            flexDirection:
                this.model.posicaoQrCode == PosicaoRotulo.Cima || this.model.posicaoQrCode == PosicaoRotulo.Baixo
                    ? "column"
                    : "row",
        });
    }

    private get rowContainerStyle() {
        return {
            width: "100%",
            display: "flex",
            flexDirection: "column",
            flex: "1",
        };
    }

    private get logoStyle() {
        return this.flexBaseStyle({
            width: `${this.model.larguraLogo}em`,
            height: `${this.model.alturaLogo}em`,
            order: this.model.posicaoLogo == PosicaoRotulo.Esquerda ? 0 : 1,
            backgroundColor: !this.CalledByShortCut ? "lightgoldenrodyellow" : "transparent",
        });
    }

    private get qrCodeStyle() {
        return this.flexBaseStyle({
            marginLeft: "auto",
            width: `${this.model.larguraCode}em`,
            height: `${this.model.alturaCode}em`,
            order:
                this.model.posicaoQrCode == PosicaoRotulo.Esquerda || this.model.posicaoQrCode == PosicaoRotulo.Cima
                    ? 0
                    : 1,
            backgroundColor: !this.CalledByShortCut ? "lightgoldenrodyellow" : "transparent",
        });
    }

    private get observacaoManipulacaoStyle() {
        return this.flexBaseStyle({
            fontFamily: this.getFontFamily(this.model.fonteRotulo),
            fontSize: `${this.adjustFontSize(this.model.observacaoFonte)}em`,
            fontWeight: this.model.observacaoNegrito ? "bold" : "normal",
        });
    }

    private get infoFranquiaStyle() {
        return this.flexBaseStyle({
            justifyContent: "flex-start",
            width: "100%",
        });
    }

    private get spanStyle() {
        return {
            fontFamily: this.getFontFamily(this.model.fonteRotulo),
            fontSize: `${this.adjustFontSize(this.model.fonteDadosFranquia)}em`,
            lineHeight: `${this.adjustLineHeight(this.model.paddingLine)}em`,
            width: "100%",
            textAlign:
                this.model.posicaoDadosFranquia === PosicaoDadosFranquia.Direita
                    ? "right"
                    : this.model.posicaoDadosFranquia === PosicaoDadosFranquia.Centro
                    ? "center"
                    : "left",
        };
    }

    private getLinhaStyle() {
        return this.flexBaseStyle();
    }

    private getCampoStyle(campo: ConfiguracaoRotuloCamposModel) {
        return this.flexBaseStyle({
            flex: campo.quantidadeColunas, // quantas colunas o campo ocupa
            justifyContent: "flex-start",
            fontFamily: this.getFontFamily(this.model.fonteRotulo),
            fontSize: `${this.adjustFontSize(campo.fonte)}em`,
            fontWeight: campo.negrito ? "bold" : "normal",
            lineHeight: `${this.adjustLineHeight(this.model.paddingLine)}em`,
            display: "block",
            // overflowWrap: "normal",
            // overflowX: "hidden",
            // overflowY: "hidden",
            // textWrap: "wrap",
            // wordBreak: "normal",
            textAlign:
                campo.alinhamento == Alinhamento.Esquerda
                    ? "left"
                    : campo.alinhamento == Alinhamento.Direita
                    ? "right"
                    : "center",
        });
    }

    @Watch("model.dadosId", { immediate: true })
    private async onChangeRotuloId() {
        if (this.model.dadosId && this.model.posicaoQrCode != PosicaoRotulo.Nenhuma) {
            if (this.model.posicaoQrCode == PosicaoRotulo.Baixo || this.model.posicaoQrCode == PosicaoRotulo.Cima) {
                this.barCodeBase64 = await this.codigosIdentificacaoService
                    .getBarCode(this.model.dadosId.toString())
                    .resolveWithText();
            } else {
                const dadoEncode = `${process.env.VUE_APP_ROOT_API}/InformacaoProduto/Manipulacao?key=${this.manipulacaoKey}`;
                this.qrCodeBase64 = await this.codigosIdentificacaoService.getQrCode(dadoEncode).resolveWithText();
            }
        }
    }
}
