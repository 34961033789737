import BaseModel from "./base/baseModel";
import ProdutoLoteFracionamentoItemEmbalagemModel from "./produtoLoteFracionamentoItemEmbalagemModel";

export default class ProdutoLoteFracionamentoItemModel extends BaseModel {
    id: number = null;
    quantidade = 0;
    quantidadeEmbalagem = 1;
    volume = 0;
    total = 0;
    unidadeMedidaId: number = null;
    kitEmbalagemId: number = null;

    itensEmbalagens: ProdutoLoteFracionamentoItemEmbalagemModel[] = [];
}
