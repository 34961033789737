import Vue from "vue";
import { mapState, mapMutations, mapGetters } from "vuex";

import actionBarComponent from "@/components/child/actionBar/actionBar.vue";
import checkboxComponent from "@/components/child/form/checkbox.vue";
import comboComponent from "@/components/child/form/combo.vue";
import dataTooltipComponent from "@/components/child/form/datatooltip.vue";
import decimalComponent from "@/components/child/form/decimal.vue";
import decimalComSinalCustomComponent from "@/components/child/form/decimalComSinalCustom.vue";
import fieldsetComponent from "@/components/child/form/fieldset.vue";
import moedaComponent from "@/components/child/form/moeda.vue";
import gridComponent from "@/components/child/grid/grid.vue";
import { GridColumn, GridColumnType } from "@/components/child/grid/gridColumn";
import logEntidadeComponent from "@/components/child/logEntidade/logEntidade.vue";
import { Component, Watch } from "@/decorators";
import PreLoadPackModel from "@/models/auxiliar/preLoadPackModel";
import ConfiguracaoRotuloModel from "@/models/configuracaoRotuloModel";
import EnumExtensions from "@/models/enum/extensions/enumExtensions";
import OrigemRotulo from "@/models/enum/rotulo/origemRotulo";
import TiposCalculoEmbalagem from "@/models/enum/tiposCalculoEmbalagem";
import TiposCalculoManipulacao from "@/models/enum/tiposCalculoManipulacao";
import TiposCapsula from "@/models/enum/tiposCapsula";
import TiposFormaFarmaceutica from "@/models/enum/tiposFormaFarmaceutica";
import TiposUso from "@/models/enum/tiposUso";
import Usos from "@/models/enum/usos";
import FormaFarmaceuticaItemPadraoModel from "@/models/formaFarmaceuticaItemPadraoModel";
import FormaFarmaceuticaModel from "@/models/formaFarmaceuticaModel";
import FranquiaModel from "@/models/franquiaModel";
import { TypeElement } from "@/models/modeloImpressao/modeloImpressaoSessaoModel";
import PaginationModel from "@/models/paginationModel";
import UnidadeMedidaModel from "@/models/unidadeMedidaModel";
import ConfiguracaoRotuloService from "@/services/configuracaoRotuloService";
import FormaFarmaceuticaService from "@/services/formaFarmaceuticaService";
import FranquiaService from "@/services/franquiaService";
import UnidadeMedidaService from "@/services/unidadeMedidaService";
import { AppState, Getters, Mutations } from "@/store/store";
import Print from "@/utils/common/printForm";

import ReplicarSelecaoFranquiaComponent from "../replicarSelecaoFranquia/edit";
import replicarSelecaoFranquiaComponent from "../replicarSelecaoFranquia/edit.vue";

import GraficoComponent from "./grafico";
import graficoComponent from "./grafico.vue";
import FormaItemComponent from "./item";
import formaItemComponent from "./item.vue";

import "../crud.scss";

@Component({
    components: {
        comboComponent,
        dataTooltipComponent,
        moedaComponent,
        decimalComponent,
        formaItemComponent,
        fieldsetComponent,
        checkboxComponent,
        gridComponent,
        graficoComponent,
        decimalComSinalCustomComponent,
        replicarSelecaoFranquiaComponent,
        actionBarComponent,
        logEntidadeComponent,
    },
    computed: {
        ...mapState<AppState>({
            preLoadList: state => state.preLoad.preLoadList,
        }),
        ...mapGetters([
            "HAS_PERMISSAO_PERFIL",
            "VALIDAR_PERMISSAO_USUARIO",
            "VALIDAR_PERMISSAO_SOMENTE_CONSULTA",
        ] as Getters),
    },
    methods: mapMutations(["LOAD_LIST"] as Mutations),
})
export default class FormaFarmaceuticaEditComponent extends Vue {
    // State computed props
    preLoadList: PreLoadPackModel;
    HAS_PERMISSAO_PERFIL: (isRede: boolean, isFranqueador: boolean, isSuporte: boolean) => Promise<boolean>;
    VALIDAR_PERMISSAO_USUARIO: (telaDescricao: string, acaoController: string) => boolean;
    VALIDAR_PERMISSAO_SOMENTE_CONSULTA: (telaDescricao: string) => boolean;

    rotulosManipulacaoOptions: Array<Object> = [];

    private service = new FormaFarmaceuticaService();
    private formaItemComponent: FormaItemComponent = null;

    private graficoComponent: GraficoComponent = null;

    model = new FormaFarmaceuticaModel();

    modelId = 0;

    private currentFranquiaId = null;

    private replicarSelecaoFranquiaComponent: ReplicarSelecaoFranquiaComponent = null;
    private franquiaId: number = null;
    private isAdmin = false;
    private isAdminFranqueador = false;
    somenteConsulta = false;

    franquiaOptions: Array<Object> = [];

    tiposCalculoOptions: Array<Object> = [
        { text: "Dose", value: TiposCalculoManipulacao.Dose },
        { text: "Percentual", value: TiposCalculoManipulacao.Percentual },
        { text: "Unitário", value: TiposCalculoManipulacao.Unitario },
        { text: "Nenhum", value: TiposCalculoManipulacao.Nenhum },
    ];

    tipoCapsulaOptions: Array<Object> = [
        { text: "Normal", value: TiposCapsula.Normal },
        { text: "Revestida", value: TiposCapsula.Revestida },
        { text: "Vegana", value: TiposCapsula.Vegana },
        { text: "Vegana Revestida", value: TiposCapsula.VeganaRevestida },
    ];

    get gridColumns(): Array<GridColumn> {
        return [
            new GridColumn("subGrupoDescricao", "Sub Grupo", GridColumnType.String),
            new GridColumn("produtoDescricao", "Produto", GridColumnType.String),
            new GridColumn("tipoCalculoDescricao", "Tipo Cálculo", GridColumnType.String),
            new GridColumn("quantidade", "Quantidade", GridColumnType.Decimal),
            new GridColumn("desmembrado", "Desmembrado", GridColumnType.Boolean),
        ];
    }

    private onInclusaoItem(model: FormaFarmaceuticaItemPadraoModel) {
        if (model.subGrupo != null) {
            model.formaFarmaceuticaId = this.model.id;
            this.model.itensPadroes.push(model);
            this.formaItemComponent.clear();
        }
    }

    private onRemoveItem(model: FormaFarmaceuticaItemPadraoModel) {
        this.model.itensPadroes = this.model.itensPadroes.filter(p => p != model);
    }

    usoOptions: Array<Object> = EnumExtensions.getNamesAndValuesOrderedByNames(Usos);

    tiposUsoOptions: Array<Object> = [
        { text: "Sistêmico", value: TiposUso.Sistemico },
        { text: "Tópico", value: TiposUso.Topico },
        { text: "Oftálmico", value: TiposUso.Oftalmico },
        { text: "Injetável", value: TiposUso.Injetavel },
    ];

    formaFarmaceuticaCalculoOptions: Array<Object> = [
        { text: "Unitário", value: TiposCalculoEmbalagem.Unitario },
        { text: "Total", value: TiposCalculoEmbalagem.Total },
    ];

    formaFarmaceuticaTipoOptions: Array<Object> = [
        { text: "Capsula", value: TiposFormaFarmaceutica.Capsula },
        { text: "Floral", value: TiposFormaFarmaceutica.Floral },
        { text: "Homeopatia", value: TiposFormaFarmaceutica.Homeopatia },
        { text: "Papel", value: TiposFormaFarmaceutica.Papel },
        { text: "Unitário", value: TiposFormaFarmaceutica.Unitario },
        { text: "Volume x Qtde (mg)", value: TiposFormaFarmaceutica.VolumeQtdeMG },
        { text: "Volume x Qtde (%)", value: TiposFormaFarmaceutica.VolumeQtdePerc },
        { text: "Volume", value: TiposFormaFarmaceutica.Volume },
    ];
    unidadeMedidaVolumeOptions: Array<Object> = [];

    private async loadUnidadeMedidaVolume() {
        try {
            const data = await new UnidadeMedidaService()
                .combo()
                .then(r => r.json() as Promise<PaginationModel<UnidadeMedidaModel>>);
            this.unidadeMedidaVolumeOptions = data.list.map(item => ({
                value: item.id,
                text: `${item.descricao} - ${item.sigla}`,
                sigla: item.sigla,
                type: item.unidadeMedida,
            }));
        } catch {}
    }

    private async loadRotulos() {
        try {
            const data = await new ConfiguracaoRotuloService()
                .comboByFranquiaId(this.model.franquiaId)
                .resolveWithJSON<PaginationModel<ConfiguracaoRotuloModel>>();

            this.rotulosManipulacaoOptions = data.list
                .filter(
                    item =>
                        item.origemDados == OrigemRotulo.Manipulacao ||
                        item.origemDados == OrigemRotulo.Acabado ||
                        item.origemDados == OrigemRotulo.Strips,
                )
                .map(item => ({ value: item.id, text: item.nome }));
        } catch {}
    }

    private async load() {
        this.model = new FormaFarmaceuticaModel();
        this.model.itensPadroes = new Array<FormaFarmaceuticaItemPadraoModel>();

        if (this.modelId > 0) {
            try {
                const data = await this.service
                    .get(this.modelId)
                    .withLoading()
                    .resolveWithJSON<FormaFarmaceuticaModel>();
                if (data.fatorCompensacao == null) {
                    data.fatorCompensacao = 0;
                }
                if (data.fatorDecaimento == null) {
                    data.fatorDecaimento = 0;
                }
                if (data.fatorMultiplicadorAdicional == null) {
                    data.fatorMultiplicadorAdicional = 0;
                }

                this.model.updateFrom(data);

                this.franquiaId = data.franquiaId;
            } catch {
                this.$router.back();
            }
        }
        if (this.model.franquiaId) {
            await this.loadRotulos();
        }
    }

    private async save(showSaveConfirmacao = false) {
        let salvou = false;

        try {
            const isValid = await this.$validator.validateAll();
            if (isValid) {
                if (this.model.tipoCalculo != TiposCalculoManipulacao.Dose && this.model.concentracaoMaxima != null) {
                    this.model.concentracaoMaxima = null;
                }
                if (this.model.desconsiderarDensidade == null) {
                    this.model.desconsiderarDensidade = false;
                }

                const sucesso = await this.service[!this.model.id ? "insert" : "update"](this.model)
                    .withLoading()
                    .resolveWithoutJSON();
                if (sucesso) {
                    this["LOAD_LIST"]({ loadFormaFarmaceutica: true });

                    if (!showSaveConfirmacao) {
                        await this.$showInclusaoUpdate(this.model.id);
                        if (this.currentFranquiaId != this.model.franquiaId && this.model.franquiaId != null) {
                            this.load();
                        } else {
                            this.$router.back();
                        }
                    }
                    salvou = true;
                } else {
                    salvou = false;
                }
            } else {
                salvou = false;
                this.$focusErrorField();
            }
        } catch {
            salvou = false;
        }
        return salvou;
    }

    private cancel() {
        this.$router.back();
    }

    private getSignal(unidadeMedidaId: number) {
        if (unidadeMedidaId > 0) {
            const unMed = this.unidadeMedidaVolumeOptions.filter(p => p["value"] == unidadeMedidaId);
            if (unMed.length > 0) {
                if (unMed[0]["sigla"] != null) {
                    return unMed[0]["sigla"];
                }
            }
        }
        return "";
    }
    //inicioAdmin
    //@ts-ignore
    @Watch("franquiaId")
    private onFranquiaChanged() {
        if (this.franquiaId > 0 && this.franquiaId != this.model.franquiaId) {
            this.loadFormaFarmaceuticaByParent();
        }
    }

    private async loadFormaFarmaceuticaByParent() {
        if (this.modelId > 0 && this.franquiaId > 0) {
            this.franquiaId = this.model.franquiaId;
            try {
                const data = await this.service
                    .getByParent(this.modelId, this.franquiaId)
                    .withLoading()
                    .resolveWithJSON<FormaFarmaceuticaModel>();
                this.model = new FormaFarmaceuticaModel();
                this.model.updateFrom(data);
                this.franquiaId = data.franquiaId;
            } catch {}
        }
    }

    private validarVisibilidadeBotoes(botao: string): boolean {
        if (botao == "replicar") {
            return this.model.id > 0 && this.model.formaFarmaceuticaPaiId == null && this.isAdminFranqueador;
        }

        if (botao == "remover") {
            return (
                this.VALIDAR_PERMISSAO_USUARIO("formas_farmaceuticas", "delete") &&
                this.model.id > 0 &&
                this.model.formaFarmaceuticaPaiId != null
            );
        }
    }

    private onReplicar() {
        this.replicarSelecaoFranquiaComponent = this.$refs
            .replicarSelecaoFranquiaComponent as ReplicarSelecaoFranquiaComponent;
        this.replicarSelecaoFranquiaComponent.openFranquias();
    }

    private async onConfirmReplicar(franquiasIds: Array<number>) {
        const idsFranquias = franquiasIds.toString();

        const atualizarValores = await this.$showQuestionWithHTML(
            this.$t("__.ts.atencao"),
            this.$t("__.Crud.formafarmaceutica.edit_vue_html.atualizarValores"),
        );

        const replicar = await this.save(true);

        if (replicar) {
            try {
                const sucesso = await this.service
                    .replicar(this.model.id, idsFranquias, atualizarValores)
                    .resolveWithoutJSON();

                if (sucesso) {
                    this.$showSuccess(this.$t("__.ts.replicou"), this.$t("__.ts.regisSalvosSucess"));
                }
            } catch {}
        }
    }

    private async loadFranquais() {
        if (this.validarVisibilidadeBotoes("replicar")) {
            const data = await new FranquiaService()
                .combo()
                .withLoading()
                .resolveWithJSON<PaginationModel<FranquiaModel>>();
            this.franquiaOptions = data.list.map(item => ({ value: item.id, text: item.nomeFantasia }));
        }
    }

    private async onRemoveFilho() {
        const response = await this.$showExclusaoQuestion(`${this.$t("__.ts.desejaExcl")}${this.model.descricao}?`);

        if (response) {
            try {
                const sucesso = await this.service.delete(this.model.id).withLoading().resolveWithoutJSON();
                if (sucesso) {
                    this["LOAD_LIST"]({ loadFormaFarmaceutica: true });
                    this.load();
                    this.$showExclusao();
                }
            } catch {}
        }
    }
    //fim admin

    private async mounted() {
        this.graficoComponent = this.$refs.graficoComponent as GraficoComponent;
        this.formaItemComponent = this.$refs.formaItemComponent as FormaItemComponent;

        this.isAdmin = await this.HAS_PERMISSAO_PERFIL(false, false, false);
        this.isAdminFranqueador = await this.HAS_PERMISSAO_PERFIL(false, true, false);

        this.somenteConsulta = this.VALIDAR_PERMISSAO_SOMENTE_CONSULTA("formas_farmaceuticas");

        this.loadFranquais();
        this.currentFranquiaId = this.preLoadList.currentFranquiaId;
        this.loadUnidadeMedidaVolume();
        this.model = new FormaFarmaceuticaModel();
        this.model.fatorCompensacao = 0;
        this.model.fatorDecaimento = 0;
        this.model.fatorMultiplicadorAdicional = 0;
        this.model.itensPadroes = new Array<FormaFarmaceuticaItemPadraoModel>();

        if (this.$route.params.id) {
            this.modelId = +this.$route.params.id;
            this.load();
        }
    }

    print() {
        new Print().print(
            [
                {
                    elementId: "forma",
                    title: "FORMA FARMACÊUTICA",
                    type: TypeElement.Form,
                },
                {
                    elementId: "formaComp",
                    title: "",
                    type: TypeElement.Form,
                },
                {
                    elementId: "formaComp2",
                    title: "",
                    type: TypeElement.Form,
                },
            ],
            1,
        );
    }
}
