import Vue from "vue";

import moment from "moment";

import actionBarComponent from "@/components/child/actionBar/actionBar.vue";
import fieldsetComponent from "@/components/child/form/fieldset.vue";
import { Component, Watch } from "@/decorators";
import TipoEtapa from "@/models/enum/tipoEtapa";
import ManipulacaoOrdemModel from "@/models/manipulacaoOrdemModel";
import ManipulacaoOrdemEtapaPCPModel from "@/models/pcp/manipulacaoOrdemEtapaPCPModel";
import ManipulacaoOrdemPCPModel from "@/models/pcp/manipulacaoOrdemPCPModel";
import ManipulacaoOrdemService from "@/services/manipulacaoOrdemService";
import ManipulacaoOrdemPCPService from "@/services/pcp/manipulacaoOrdemPCPService";

import EtapasPCPComponent from "../etapas";
import etapasComponent from "../etapas.vue";
import pcpRapidoComponent from "../pcpRapido/pcpRapido.vue";

import PesquisaManipulacaoComponent from "./pesquisaAvancada/edit";
import pesquisaManipulacaoComponent from "./pesquisaAvancada/edit.vue";

import "@/components/parent/crud/crud.scss";

@Component({
    components: {
        fieldsetComponent,
        etapasComponent,
        actionBarComponent,
        pesquisaManipulacaoComponent,
        pcpRapidoComponent,
    },
})
export default class ManipulacaoOrdemPCPEditComponent extends Vue {
    private service = new ManipulacaoOrdemPCPService();

    private etapasComponent: EtapasPCPComponent = null;
    private pesquisaManipulacaoComponent: PesquisaManipulacaoComponent = null;

    private model = new ManipulacaoOrdemPCPModel();
    private manipulacaoOrdemCodigo = null;
    private manipulacaoOrdemCodigoOld = null;
    private ativoDescricao = null;

    private clear() {
        this.model = new ManipulacaoOrdemPCPModel();
        this.ativoDescricao = null;
        this.etapasComponent.clear();
    }

    //@ts-ignore
    @Watch("model.manipulacaoOrdemEtapasPCP")
    private onChangeEtapas() {
        const etapas = [...this.model.manipulacaoOrdemEtapasPCP];
        etapas.sort((a, b) => (moment(a.dataInicio).isAfter(b.dataInicio) ? 1 : -1));
        this.etapasComponent.updateGridData(etapas);
    }

    private async onChangeManipulacaoOrdemCodigo() {
        this.clear();

        // Remove caracter enviado pelo código de barras
        this.manipulacaoOrdemCodigo = this.manipulacaoOrdemCodigo.toString().toLocaleUpperCase().replace("$M", "");

        if (this.manipulacaoOrdemCodigo) {
            try {
                const manipulacaoOrdem = await new ManipulacaoOrdemService()
                    .getByCodigo(this.manipulacaoOrdemCodigo)
                    .resolveWithJSON<ManipulacaoOrdemModel>();

                if (manipulacaoOrdem) {
                    this.ativoDescricao = manipulacaoOrdem.descricao;
                    this.model = await this.service
                        .getByManipulacaoOrdem(manipulacaoOrdem.id)
                        .resolveWithJSON<ManipulacaoOrdemPCPModel>();

                    if (this.model) {
                        this.model.manipulacaoOrdemId = manipulacaoOrdem.id;
                        this.model.manipulacaoOrdem = manipulacaoOrdem;

                        const etapasOrdenadas = this.model.manipulacaoOrdemEtapasPCP.sort((a, b) =>
                            a.etapaPCP.ordem > b.etapaPCP.ordem ? -1 : 1,
                        );

                        const etapasEmAberto = etapasOrdenadas.find(
                            p => p.dataFim == null || p.etapaPCP.tipo == TipoEtapa.Final,
                        );

                        if (etapasEmAberto) {
                            this.etapasComponent.etapaAtualId = etapasEmAberto.etapaPCPId;
                            this.etapasComponent.setEtapaDestinoId(
                                etapasEmAberto.etapaPCP.ordem,
                                manipulacaoOrdem.setor,
                            );
                        } else {
                            this.etapasComponent.setEtapaDestinoId(0);
                        }
                    }
                } else {
                    this.manipulacaoOrdemCodigo = null;
                }
            } catch {}

            if (this.manipulacaoOrdemCodigoOld == this.manipulacaoOrdemCodigo && !this.model.dataFim) {
                this.manipulacaoOrdemCodigoOld = null;
                return this.save().withLoading();
            }

            this.manipulacaoOrdemCodigoOld = this.manipulacaoOrdemCodigo;
        }
    }

    private cancel() {
        this.$router.back();
    }

    private async save() {
        try {
            const isValid = await this.etapasComponent.validate();
            if (isValid) {
                if (this.model.id == null) {
                    this.model.dataInicio = new Date();
                }

                if (this.etapasComponent.etapaAtualId) {
                    this.etapasComponent.finalizaEtapaAtual(this.model.manipulacaoOrdemEtapasPCP);
                }

                const newEtapa = await this.etapasComponent.criaEtapaDestino(
                    this.model,
                    new ManipulacaoOrdemEtapaPCPModel(),
                );

                this.model.manipulacaoOrdemEtapasPCP.push(newEtapa);

                const sucesso = await this.service[!this.model.id ? "insert" : "update"](
                    this.model,
                ).resolveWithoutJSON();

                if (sucesso) {
                    await this.$showInclusaoUpdate(this.model.id);

                    this.clear();
                    this.manipulacaoOrdemCodigo = null;
                }
            }
        } catch {}
    }

    private async onShowEtapasPcp(open: (slot: number, title: string, size: string) => {}) {
        open(0, "PCP Rápido", "ld-modal");
    }

    private async onShowDadosNotaEntradaLote() {
        await this.pesquisaManipulacaoComponent.show();
    }

    private async onReturnPesquisa(value: any) {
        this.manipulacaoOrdemCodigo = value;
        this.onChangeManipulacaoOrdemCodigo();
    }

    private mounted() {
        this.etapasComponent = this.$refs.etapasComponent as EtapasPCPComponent;
        this.pesquisaManipulacaoComponent = this.$refs.pesquisaManipulacaoComponent as PesquisaManipulacaoComponent;

        if (this.$route.params.id) {
            this.manipulacaoOrdemCodigo = this.$route.params.id;
            this.onChangeManipulacaoOrdemCodigo().withLoading();
        }
    }
}
