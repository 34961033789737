import actionBarComponent from "@/components/child/actionBar/actionBar.vue";
import cepFieldComponent from "@/components/child/form/cepfield.vue";
import checkboxComponent from "@/components/child/form/checkbox.vue";
import cnpjComponent from "@/components/child/form/cnpj.vue";
import comboComponent from "@/components/child/form/combo.vue";
import cpfComponent from "@/components/child/form/cpf.vue";
import dataTooltipComponent from "@/components/child/form/datatooltip.vue";
import dateTimePickerComponent from "@/components/child/form/datetimepicker.vue";
import fieldsetComponent from "@/components/child/form/fieldset.vue";
import infoBagdeComponent from "@/components/child/infoBagde/infoBagde.vue";
import logEntidadeComponent from "@/components/child/logEntidade/logEntidade.vue";
import { Component, Prop, Watch } from "@/decorators";
import CepModel from "@/models/cepModel";
import EnumExtensions from "@/models/enum/extensions/enumExtensions";
import TipoMovimentacao from "@/models/enum/tiposMovimentacao";
import TiposPessoa from "@/models/enum/tiposPessoa";
import Ufs from "@/models/enum/ufs";
import ContaGerencialModel from "@/models/financeiro/contaGerencialModel";
import FornecedorModel from "@/models/fornecedorModel";
import PaginationModel from "@/models/paginationModel";
import CidadeService from "@/services/external/cidadeService";
import ContaGerencialService from "@/services/financeiro/contaGerencialService";
import FornecedorService from "@/services/fornecedorService";
import PaisService from "@/services/paisService";
import { editDateWithTime } from "@/utils/common/date";

import EditComponentBase from "../editComponentBase";

import "../crud.scss";

@Component({
    components: {
        comboComponent,
        checkboxComponent,
        fieldsetComponent,
        cpfComponent,
        cnpjComponent,
        logEntidadeComponent,
        cepFieldComponent,
        dataTooltipComponent,
        actionBarComponent,
        infoBagdeComponent,
        dateTimePickerComponent,
    },
    computed: {
        infoBagdeText() {
            return "Data criação: " + editDateWithTime(this.model.dataCriacao);
        },
    },
})
export default class FornecedorEditComponent extends EditComponentBase<FornecedorModel> {
    // State computed props
    infoBagdeText: string;

    private cidadeService = new CidadeService();

    tiposPessoa = TiposPessoa;
    tiposPessoaOptions: Array<Object> = [
        { text: "F\u00edsica", value: TiposPessoa.Fisica },
        { text: "Jur\u00eddica", value: TiposPessoa.Juridica },
    ];

    dadosCep = new CepModel();
    ufOptions: Array<Object> = EnumExtensions.getNamesAndValuesOrderedByNames(Ufs);
    cidadesOptions: Array<Object> = [];
    paisesOptions: Array<Object> = [];
    contasGerenciaisOptions: Array<Object> = [];

    @Prop({ type: Boolean, default: false }) CalledByShortCut: boolean;

    private async beforeMount() {
        await this.setProps(new FornecedorService(), "fornecedores", new FornecedorModel(), this.CalledByShortCut);

        this.loadPaises();

        Promise.all([this.loadContasGerenciais()]).withLoading();
    }

    public async onLoadModel(model: FornecedorModel) {
        await this.onLoadCidades(model.estadoId);
    }

    public async onPreSave() {
        if (this.model.cidadeId) {
            const cidade = this.cidadesOptions.find(item => item["value"] == this.model.cidadeId);
            if (cidade) {
                this.model.cidade = cidade["text"];
            }
        }

        if (this.model.codigoPais != null) {
            this.model.pais = this.paisesOptions.find(item => item["value"] == this.model.codigoPais)["text"];
        }

        return true;
    }

    private async onCepChanged(data: CepModel) {
        this.dadosCep = data;
        this.model.bairro = data.bairro;
        this.model.complemento = data.complemento;
        this.model.endereco = data.endereco;
        this.model.estadoId = data.uf;

        await this.onLoadCidades(this.model.estadoId);
        this.model.cidadeId = data.cidadeId;
    }

    private onCepInvalid() {
        this.$showInfo(this.$t("__.ts.pesqCEP"), this.$t("__.ts.pesqNaoEncResul"));
    }

    private async onShowLogsFornecedor(open: (slot: number, title: string, size: string) => {}) {
        open(0, this.$t("__.ts.historicoAlteracoes") as string, "lg-modal");
    }

    //@ts-ignore
    @Watch("model.tipoPessoa")
    private onTipoPessoaChanged(tipoPessoa: TiposPessoa) {
        switch (tipoPessoa) {
            case TiposPessoa.Fisica:
                this.model.nomeFantasia = null;
                this.model.cnpj = null;
                this.model.inscricaoEstadual = null;
                this.model.razaoSocial = null;
                break;
            case TiposPessoa.Juridica:
                this.model.nome = null;
                this.model.cpf = null;
                this.model.rg = null;
                break;
        }
    }

    private async onLoadCidades(estadoId: number) {
        try {
            this.cidadesOptions = [];
            if (estadoId) {
                this.cidadesOptions = await this.cidadeService.get(estadoId).withLoading();
            }
        } catch {}
    }

    private async loadContasGerenciais() {
        try {
            const data = await new ContaGerencialService()
                .listByTipoMovimentacao("", 1, 999999, TipoMovimentacao.Saida)
                .resolveWithJSON<PaginationModel<ContaGerencialModel>>();
            this.contasGerenciaisOptions = data.list.map(p => ({ value: p.id, text: p.descricao }));
        } catch {}
    }

    private loadPaises() {
        const paises = new PaisService().get();
        this.paisesOptions = paises.map(pais => ({ value: pais.codigoSPED, text: pais.descricao }));
    }
}
