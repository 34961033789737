var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "loading-component",
    },
    [
      _c("div", { staticClass: "overlay" }, [
        _c("div", { staticClass: "overlay__inner" }, [
          _c("div", { staticClass: "overlay__content" }, [
            _c("span", { staticClass: "spinner" }, [
              _c("img", { attrs: { width: "250", src: _vm.loadingImageUrl } }),
            ]),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }