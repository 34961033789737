import BaseModel from "../base/baseModel";

import EtapaPCPModel from "./etapaPCPModel";
import MotivoEtapaPCPModel from "./motivoEtapaPCPModel";
import VendaPCPModel from "./vendaPCPModel";

export default class VendaEtapaPCPModel extends BaseModel {
    id?: number = null;
    vendaPCPId?: number = null;
    vendaPCP = new VendaPCPModel();
    etapaPCP = new EtapaPCPModel();
    etapaPCPId?: number = null;
    etapaPCPDescricao: string = null;
    usuarioInicioId?: number = null;
    usuarioInicioNome: string = null;
    dataInicio: Date = null;
    observacao: string = null;
    motivoEtapaPCP = new MotivoEtapaPCPModel();
    motivoEtapaPCPId?: number = null;
    motivoEtapaPCPDescricao: string = null;
    finalizado = false;
    usuarioFinalizacaoId?: number = null;
    usuarioFinalizacaoNome: string = null;
    dataFim: Date = null;
}
