var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _c("form", [
      _c("div", [
        _vm._m(0),
        _vm._v(" "),
        _c("div", { staticClass: "form-group" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.email,
                expression: "email",
              },
              { name: "focus", rawName: "v-focus" },
              {
                name: "validate",
                rawName: "v-validate",
                value: "required",
                expression: "'required'",
              },
            ],
            staticClass: "form-control",
            attrs: {
              type: "text",
              placeholder: "E-mail",
              name: "email",
              id: "email",
            },
            domProps: { value: _vm.email },
            on: {
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.email = $event.target.value
              },
            },
          }),
        ]),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn button",
            attrs: { type: "button" },
            on: { click: _vm.onEsqueceuSenha },
          },
          [_vm._v("Enviar")]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "form-group" }, [
      _c("div", { staticClass: "logoLogin" }, [
        _c("img", {
          attrs: { src: require("../../../../image/logo-login.svg") },
        }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }