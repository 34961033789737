var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "crud crud-edit",
        staticStyle: { width: "100%", height: "100%" },
      },
      [
        _c("iframe", {
          attrs: {
            src: "https://dante-ai.com/embed/?kb_id=a98d004f-76cc-4ca6-a8f5-1bd069715908&token=c991779f-ce91-45f5-8441-4f406018d106&modeltype=gpt-4-omnimodel&mode=undefined&logo=dHJ1ZQ%3D%3D",
            allow: "clipboard-write; clipboard-read; *;microphone *",
            width: "100%",
            height: "100%",
            frameborder: "0",
          },
        }),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }