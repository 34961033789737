enum TiposMotivoMovimentacao {
    PerdaporFurto = 1,
    PerdaporAvaria = 2,
    PerdaporVencimento = 3,
    RecolhimentopelaAnvisa = 4,
    PerdanoProcesso = 5,
    PerdaporUsoControledeQualidade = 6,
    PerdaPorDesviodeQualidade = 8,
    RecolhimentodoFabricante = 9,
    DevolucaoAoFornecedor = 10,
    AlteracaoInterna = 11,
    UsoConsumoDuranteProcesso = 12,
    TransferenciaParaFilial = 13,
}

export default TiposMotivoMovimentacao;
