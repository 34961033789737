var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "loading-modal-component",
    },
    [
      _c(
        "modal-component",
        {
          attrs: {
            "show-modal": _vm.loading,
            "show-header": false,
            "show-footer": false,
          },
        },
        [
          _c("div", { attrs: { slot: "body" }, slot: "body" }, [
            _c("h3", { staticStyle: { "text-align": "center" } }, [
              _c("img", { attrs: { width: "250" } }),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }