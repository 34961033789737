import Vue from "vue";
import { mapGetters } from "vuex";

import actionBarComponent from "@/components/child/actionBar/actionBar.vue";
import buttonIncluirComponent from "@/components/child/form/button/buttonIncluir.vue";
import checkboxComponent from "@/components/child/form/checkbox.vue";
import comboComponent from "@/components/child/form/combo.vue";
import dataTooltipComponent from "@/components/child/form/datatooltip.vue";
import decimalComponent from "@/components/child/form/decimal.vue";
import fieldsetComponent from "@/components/child/form/fieldset.vue";
import gridComponent from "@/components/child/grid/grid.vue";
import { GridColumn, GridColumnType } from "@/components/child/grid/gridColumn";
import { Component } from "@/decorators";
import Especie from "@/models/enum/especiePagamento";
import BandeiraModel from "@/models/financeiro/bandeiraModel";
import BandeiraXFormaPagamentoModel from "@/models/financeiro/bandeiraXFormaPagamentoModel";
import ContaCorrenteModel from "@/models/financeiro/contaCorrenteModel";
import ContaReceberRecebimentoModel from "@/models/financeiro/contaReceberRecebimentoModel";
import FormaPagamentoModel from "@/models/financeiro/formaPagamentoModel";
import FormaRecebimentoModel from "@/models/financeiro/formaRecebimentoModel";
import MetodoXFormaPagamento from "@/models/financeiro/metodoPagamentoXFormaModel";
import metodoPagamentoXFormaModel from "@/models/financeiro/metodoPagamentoXFormaModel";
import OperadoraCartoes from "@/models/financeiro/operadoraCartoesModel";
import PaginationModel from "@/models/paginationModel";
import BandeiraService from "@/services/financeiro/bandeiraService";
import ContaCorrenteService from "@/services/financeiro/contaCorrenteService";
import FormaPagamentoService from "@/services/financeiro/formaPagamentoService";
import FormaRecebimentoService from "@/services/financeiro/formaRecebimentoService";
import OperadoraCartoesService from "@/services/financeiro/operadoraCartoesService";
import { Getters } from "@/store/store";
import { copyObject } from "@/utils/common/copyObject";

import MetodoPagamentoXFormaComponent from "./metodosXFormaEdit";
import metodoPagamentoXFormaComponent from "./metodosXFormaEdit.vue";

import "../crud.scss";

@Component({
    components: {
        comboComponent,
        fieldsetComponent,
        decimalComponent,
        checkboxComponent,
        dataTooltipComponent,
        gridComponent,
        metodoPagamentoXFormaComponent,
        buttonIncluirComponent,
        actionBarComponent,
    },
    computed: {
        ...mapGetters(["VALIDAR_PERMISSAO_USUARIO", "VALIDAR_PERMISSAO_SOMENTE_CONSULTA"] as Getters),
    },
})
export default class FormaRecebimentoEditComponent extends Vue {
    // State computed props
    VALIDAR_PERMISSAO_USUARIO: (telaDescricao: string, acaoController: string) => boolean;
    VALIDAR_PERMISSAO_SOMENTE_CONSULTA: (telaDescricao: string) => boolean;

    private service = new FormaRecebimentoService();

    model = new FormaRecebimentoModel();
    modelId = 0;
    somenteConsulta = false;
    isCollapsedAdd = true;
    modelMetodo = new MetodoXFormaPagamento();

    private metodoPagamentoXFormaComponent: MetodoPagamentoXFormaComponent = null;
    private metodoXFormaPagamento: MetodoXFormaPagamento[] = null;

    constructor() {
        super();

        this.model.ativo = true;
    }

    formaPagamentoOptions: Array<Object> = [];
    contaCorrenteOptions: Array<Object> = [];
    especiesOptions: Array<Object> = [
        { text: "Dinheiro", value: Especie.Dinheiro },
        { text: "Cheque", value: Especie.Cheque },
        { text: "Boleto", value: Especie.Boleto },
        { text: "Cartão de Débito", value: Especie.CartaoDebito },
        { text: "Cartão de Crédito", value: Especie.CartaoCredito },
        { text: "Transferência", value: Especie.Transferencia },
        { text: "Pix", value: Especie.Pix },
        { text: "Convênio", value: Especie.Convenio },
        { text: "Transferência Bancária", value: Especie.TransferenciaBancaria },
        { text: "Outros", value: Especie.Outros },
    ];

    bandeirasOptions: Array<Object> = [];
    operadorasCartoesOptions: Array<Object> = [];

    gridColumns: Array<GridColumn> = [
        new GridColumn("metodoPagamentoDescricao", "Método", GridColumnType.String),
        new GridColumn("desconto", "Desconto", GridColumnType.Percent),
        new GridColumn("juros", "Juros", GridColumnType.Percent),
        new GridColumn("tarifa", "Tarifa", GridColumnType.Percent),
    ];

    gridColumnsBandeiras: Array<GridColumn> = [
        new GridColumn("bandeiraDescricao", "Descri\u00e7\u00e3o", GridColumnType.String),
    ];

    private async loadBandeiras() {
        try {
            const data = await new BandeiraService().combo().resolveWithJSON<PaginationModel<BandeiraModel>>();
            this.bandeirasOptions = data.list.map(item => ({
                value: item.id,
                text: item.descricao,
            }));
        } catch {}
    }

    private async loadContasCorrentes() {
        try {
            const data = await new ContaCorrenteService()
                .combo()
                .resolveWithJSON<PaginationModel<ContaCorrenteModel>>();
            this.contaCorrenteOptions = data.list.map(item => ({
                value: item.id,
                text: item.descricao,
            }));
        } catch {}
    }

    private async loadFormaPagamento() {
        try {
            const data = await new FormaPagamentoService()
                .combo()
                .resolveWithJSON<PaginationModel<FormaPagamentoModel>>();
            this.formaPagamentoOptions = data.list.map(item => ({
                value: item.id,
                text: item.descricao,
            }));
        } catch {}
    }

    private async loadOperadorasCartoes() {
        try {
            const data = await new OperadoraCartoesService()
                .combo()
                .resolveWithJSON<PaginationModel<OperadoraCartoes>>();
            this.operadorasCartoesOptions = data.list.map(item => ({
                value: item.id,
                text: item.nomeFantasia ? item.nomeFantasia : item.nome,
            }));
        } catch {}
    }

    private async load() {
        this.model = new FormaRecebimentoModel();

        if (this.modelId > 0) {
            try {
                const data = await this.service
                    .get(this.modelId)
                    .withLoading()
                    .resolveWithJSON<FormaRecebimentoModel>();

                this.model.updateFrom(data);
            } catch {
                this.$router.back();
            }
        } else {
            this.model.metodoPagamentoXForma = new Array<MetodoXFormaPagamento>();
            this.model.bandeiraXFormaPagamento = new Array<BandeiraXFormaPagamentoModel>();
            this.model.ativo = true;
        }
    }

    private async save() {
        try {
            const isValid = await this.$validator.validateAll();
            if (isValid) {
                if (
                    (this.model.especie == Especie.CartaoCredito || this.model.especie == Especie.CartaoDebito) &&
                    this.model.bandeiraXFormaPagamento.length == 0
                ) {
                    return this.$showError(this.$t("__.ts.bandeiras"), this.$t("__.ts.nenhumaBandeiraAdd"));
                }

                if (this.model.especie != Especie.CartaoCredito && this.model.especie != Especie.CartaoDebito) {
                    this.model.bandeiraId = null;
                    this.model.operadoraCartoesId = null;
                    this.model.metodoPagamentoXForma = null;
                    this.model.bandeiraXFormaPagamento = null;
                } else {
                    this.model.juros = null;
                    this.model.desconto = null;
                }

                const sucesso = await this.service[!this.model.id ? "insert" : "update"](this.model)
                    .withLoading()
                    .resolveWithoutJSON();

                this.isCollapsedAdd = true;

                if (sucesso) {
                    await this.$showInclusaoUpdate(this.model.id);
                    this.$router.back();
                }
            } else {
                this.$focusErrorField();
            }
        } catch {}
    }

    private cancel() {
        this.$router.back();
    }

    private async onAdicionarMetodo(metodo: MetodoXFormaPagamento) {
        this.model.metodoPagamentoXForma.push(metodo);
    }

    private async onRemoveMetodo(metodo: metodoPagamentoXFormaModel) {
        try {
            const contas = await this.service
                .buscarRecebimentosByMetodoPagamento(metodo.formaRecebimentoId, metodo.id)
                .resolveWithJSON<ContaReceberRecebimentoModel[]>();

            if (contas.length == 0) {
                this.model.metodoPagamentoXForma = this.model.metodoPagamentoXForma.filter(p => p != metodo);
            } else {
                this.$showWarning(this.$t("__.ts.avis"), this.$t("__.ts.mtdNaoPodeSerRemov"));
            }
        } catch {}
    }

    private onEditMetodo(metodo: MetodoXFormaPagamento) {
        this.modelMetodo = this.model.metodoPagamentoXForma.filter(
            p => p.metodoPagamentoId == metodo.metodoPagamentoId,
        )[0];
        const metd = new MetodoXFormaPagamento();
        metd.updateFrom(metodo);
        this.isCollapsedAdd = false;
        (this.$refs.metodoPagamentoXFormaComponent as MetodoPagamentoXFormaComponent).load(metd);
    }

    private onEditMetodoSubmit(metodo: MetodoXFormaPagamento) {
        this.isCollapsedAdd = true;
        const index = this.model.metodoPagamentoXForma.indexOf(this.modelMetodo);
        copyObject(metodo, this.model.metodoPagamentoXForma[index]);
    }

    private onChangeCollapsedStatusAdd(collapsed: boolean) {
        this.isCollapsedAdd = collapsed;
    }

    private onInclusaoBandeira() {
        const bandeiraComponent = this.$el.querySelector("#bandeiraId") as HTMLSelectElement;

        if (this.model.bandeiraId > 0) {
            const alreadyExists = this.model.bandeiraXFormaPagamento.some(p => p.bandeiraId == this.model.bandeiraId);

            if (!alreadyExists) {
                const bandeiraModel = new BandeiraXFormaPagamentoModel();

                bandeiraModel.bandeiraId = this.model.bandeiraId;
                bandeiraModel.bandeiraDescricao = bandeiraComponent.innerText;

                this.model.bandeiraXFormaPagamento.push(bandeiraModel);

                this.model.bandeiraId = -1;
            }
        }
    }

    private onRemoveItem(model: BandeiraXFormaPagamentoModel) {
        this.model.bandeiraXFormaPagamento = this.model.bandeiraXFormaPagamento.filter(p => p != model);
    }

    private async onCopiar() {
        try {
            const isValid = await this.$validator.validateAll();
            if (isValid) {
                const response = await this.$showQuestion(
                    this.$t("__.ts.desejaReplFrma") + this.model.descricao + "?",
                    this.$t("__.ts.casoAltRecomendSalvar"),
                );
                if (response) {
                    const newModel = new FormaRecebimentoModel();
                    newModel.updateFrom(this.model);
                    if (newModel.especie != Especie.CartaoCredito && newModel.especie != Especie.CartaoDebito) {
                        newModel.bandeiraId = null;
                        newModel.operadoraCartoesId = null;
                        newModel.metodoPagamentoXForma = null;
                        newModel.bandeiraXFormaPagamento = null;
                    } else {
                        this.model.juros = null;
                        this.model.desconto = null;
                    }

                    if (newModel.metodoPagamentoXForma != null) {
                        for (let i = 0; i < newModel.metodoPagamentoXForma.length; i++) {
                            newModel.metodoPagamentoXForma[i].id = null;
                            newModel.metodoPagamentoXForma[i].formaRecebimentoId = null;
                        }
                    }
                    if (newModel.bandeiraXFormaPagamento != null) {
                        for (let i = 0; i < newModel.bandeiraXFormaPagamento.length; i++) {
                            newModel.bandeiraXFormaPagamento[i].id = null;
                            newModel.bandeiraXFormaPagamento[i].formaRecebimentoId = null;
                        }
                    }
                    newModel.id = null;
                    newModel.descricao += "(Replica)";

                    const responseInsert = await this.service.insert(newModel).withLoading().resolveWithResponse();
                    const nId = Number(responseInsert.headers.get("Id"));
                    this.isCollapsedAdd = true;
                    if (responseInsert.ok) {
                        await this.$showSuccess(this.$t("__.ts.replicado"), this.$t("__.ts.registroSalvSucess"));
                        this.modelId = nId;
                        this.load();
                        this.$router.push("/formarecebimento-edicao/" + nId);
                    }
                }
            }
        } catch {}
    }

    private mounted() {
        this.metodoPagamentoXFormaComponent = this.$refs
            .metodoPagamentoXFormaComponent as MetodoPagamentoXFormaComponent;
        this.metodoXFormaPagamento = new Array<MetodoXFormaPagamento>();
        this.isCollapsedAdd = true;

        Promise.all([
            this.loadBandeiras(),
            this.loadOperadorasCartoes(),
            this.loadContasCorrentes(),
            this.loadFormaPagamento(),
        ])
            .withLoading()
            .then(() => {
                if (this.$route.params.id) {
                    this.modelId = +this.$route.params.id;
                }

                this.somenteConsulta = this.VALIDAR_PERMISSAO_SOMENTE_CONSULTA("formas_de_recebimento");

                this.load();
            })
            .catch(() => {});
    }
}
