var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "crud crud-list ajusteAcoesPesquisar" },
    [
      !_vm.CalledByShortCut
        ? _c("div", { staticClass: "info-div" }, [
            _c("h1", { staticClass: "title" }, [
              _vm._v(
                _vm._s(_vm.$t("__.Crud.etiquetas.list_vue_html.etiquetas"))
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "content" }, [
              _c("div", { staticClass: "info-panel" }, [
                _c("span", [_vm._v(_vm._s(_vm.total))]),
                _vm._v(" "),
                _c("span", [
                  _vm._v(
                    _vm._s(_vm.$t("__.Crud.etiquetas.list_vue_html.etiquetas"))
                  ),
                ]),
                _vm._v(" "),
                _c("span", [
                  _vm._v(
                    _vm._s(
                      _vm.$t("__.Crud.etiquetas.list_vue_html.cadastradas")
                    )
                  ),
                ]),
              ]),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "grid-component",
        {
          ref: "gridcomponent",
          attrs: {
            actionName: "vendas",
            columns: _vm.gridColumns,
            controlaPageSize: true,
            data: _vm.gridData,
            extraActions: _vm.extraActions,
            "page-index": _vm.pageIndex,
            "page-size": _vm.pageSize,
            "show-select-checkbox": true,
            showAddNewButton: false,
            showEditItem: false,
            showRemoveItem: false,
            startFilterKey: _vm.startFilterKey,
            tituloPagina: "Etiquetas",
            total: _vm.total,
          },
          on: {
            "change-filter-key": _vm.onChangeFilterKey,
            "change-page-size": _vm.onChangePageSize,
            "change-page": _vm.onChangePage,
            "change-sort": _vm.onChangeSort,
            "extra-action": _vm.onExtraActions,
            "gerar-impressao": function ($event) {
              _vm.pags = true
              _vm.tipoDocGerar = 2
            },
            "gerar-pdf": function ($event) {
              _vm.pags = true
              _vm.tipoDocGerar = 1
            },
            "gerar-xls": function ($event) {
              _vm.pags = true
              _vm.tipoDocGerar = 0
            },
            "selected-values-changed": _vm.onSelectedValuesChanged,
          },
        },
        [
          _c("div", { attrs: { slot: "header" }, slot: "header" }, [
            _c("div", { staticClass: "flex-row" }, [
              _c("div", { staticClass: "filtro-lista" }, [
                _c(
                  "button",
                  {
                    staticClass: "filtro-avancado-button",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        _vm.isHidden = !_vm.isHidden
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n                        " +
                        _vm._s(_vm.$t("__.Crud.filtroAvancado")) +
                        "\n                        "
                    ),
                    _c("i", { staticClass: "fa fa-caret-down" }),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.isHidden,
                        expression: "isHidden",
                      },
                    ],
                    staticClass: "drop-lista",
                  },
                  [
                    _c(
                      "form",
                      {
                        attrs: { novalidate: "novalidate" },
                        on: {
                          submit: function ($event) {
                            $event.preventDefault()
                            return _vm.load($event)
                          },
                        },
                      },
                      [
                        _c(
                          "div",
                          { staticStyle: { "margin-bottom": "15px" } },
                          [
                            _c("b", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "__.Components.parent.venda.listOrcamento_vue_html.de"
                                  )
                                )
                              ),
                            ]),
                            _vm._v(" "),
                            _c("date-time-picker-component", {
                              staticStyle: { width: "100%" },
                              attrs: {
                                pickTime: true,
                                id: "dataFilter1",
                                name: "dataFilter1",
                              },
                              model: {
                                value: _vm.dataFilter1,
                                callback: function ($$v) {
                                  _vm.dataFilter1 = $$v
                                },
                                expression: "dataFilter1",
                              },
                            }),
                            _vm._v(" "),
                            _c("b", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "__.Components.parent.venda.listOrcamento_vue_html.ate"
                                  )
                                )
                              ),
                            ]),
                            _vm._v(" "),
                            _c("date-time-picker-component", {
                              attrs: {
                                pickTime: true,
                                id: "dataFilter2",
                                name: "dataFilter2",
                              },
                              model: {
                                value: _vm.dataFilter2,
                                callback: function ($$v) {
                                  _vm.dataFilter2 = $$v
                                },
                                expression: "dataFilter2",
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "label",
                              {
                                staticClass: "control-label",
                                attrs: { for: "localEntregaId" },
                              },
                              [
                                _vm._v(
                                  "\n                                    " +
                                    _vm._s(
                                      _vm.$t(
                                        "__.Components.parent.venda.editSingle_vue_html.localEntrega"
                                      )
                                    ) +
                                    "\n                                "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("combo-component", {
                              attrs: {
                                id: "localEntregaId",
                                name: "localEntregaId",
                                data: _vm.locaisEntregaOptions,
                                searchable: true,
                                customView: true,
                              },
                              model: {
                                value: _vm.localEntregaId,
                                callback: function ($$v) {
                                  _vm.localEntregaId = $$v
                                },
                                expression: "localEntregaId",
                              },
                            }),
                            _vm._v(" "),
                            _vm.isFracionamento || _vm.isEstereis
                              ? _c(
                                  "div",
                                  [
                                    _c(
                                      "label",
                                      {
                                        staticClass: "control-label",
                                        attrs: { for: "setor" },
                                      },
                                      [
                                        _vm._v(
                                          "\n                                        " +
                                            _vm._s(
                                              _vm.$t(
                                                "__.Components.parent.home.fastaction.vendas.list_vue_html.setor"
                                              )
                                            ) +
                                            "\n                                    "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("combo-component", {
                                      staticStyle: { background: "white" },
                                      attrs: {
                                        id: "setor",
                                        name: "setor",
                                        data: _vm.setorOptions,
                                      },
                                      model: {
                                        value: _vm.setor,
                                        callback: function ($$v) {
                                          _vm.setor = $$v
                                        },
                                        expression: "setor",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "label",
                              {
                                staticClass: "control-label",
                                staticStyle: { "margin-top": "5px" },
                                attrs: { for: "statusEntrega" },
                              },
                              [
                                _vm._v(
                                  "\n                                    " +
                                    _vm._s(
                                      _vm.$t(
                                        "__.Crud.etiquetas.list_vue_html.exibirSomenteVendas"
                                      )
                                    ) +
                                    "\n                                "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("checkbox-component", {
                              attrs: {
                                id: "vendaComNota",
                                name: "vendaComNota",
                              },
                              model: {
                                value: _vm.vendaComNota,
                                callback: function ($$v) {
                                  _vm.vendaComNota = $$v
                                },
                                expression: "vendaComNota",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "form-group" }, [
                          _c(
                            "button",
                            {
                              staticClass: "botao-filtrar",
                              attrs: { type: "submit" },
                            },
                            [
                              _vm._v(
                                "\n                                    " +
                                  _vm._s(_vm.$t("__.Crud.filtrar")) +
                                  "\n                                "
                              ),
                            ]
                          ),
                        ]),
                      ]
                    ),
                  ]
                ),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              attrs: { slot: "footer-extra-options" },
              slot: "footer-extra-options",
            },
            [
              _vm.gridSelectedValues.length > 0
                ? _c(
                    "button",
                    {
                      staticClass: "btn btn-primary",
                      staticStyle: { "margin-right": "5px" },
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          _vm.onImprimirEtiquetas(_vm.gridSelectedValues)
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(
                            _vm.$t(
                              "__.Crud.etiquetas.list_vue_html.imprimirEtiquetas"
                            )
                          ) +
                          "\n            "
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.gridSelectedValues.length > 0
                ? _c(
                    "button",
                    {
                      staticClass: "btn btn-primary",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          _vm.onGerarEtiquetas(_vm.gridSelectedValues)
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(
                            _vm.$t(
                              "__.Crud.etiquetas.list_vue_html.gerarEtiquetaLote"
                            )
                          ) +
                          "\n            "
                      ),
                    ]
                  )
                : _vm._e(),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _c("impressaoComponent", { ref: "impressaoComponent" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }