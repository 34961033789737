var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("tr", [
    _c("td", { staticClass: "col-sm-5" }, [
      _c(
        "div",
        { staticClass: "form-group" },
        [
          _c("combo-component", {
            attrs: {
              id: "produtoItem",
              name: "produtoItem",
              data: _vm.produtoOptions,
              disabled: _vm.desativaCampos,
            },
            model: {
              value: _vm.model.produtoId,
              callback: function ($$v) {
                _vm.$set(_vm.model, "produtoId", $$v)
              },
              expression: "model.produtoId",
            },
          }),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _c("td", { staticClass: "col-sm-3" }, [
      _c(
        "div",
        { staticClass: "form-group" },
        [
          _c("combo-component", {
            attrs: {
              id: "loteItem",
              name: "loteItem",
              data: _vm.produtoLoteOptions,
              disabled: _vm.desativaCampos || _vm.isSemEstoque,
            },
            model: {
              value: _vm.model.produtoLoteId,
              callback: function ($$v) {
                _vm.$set(_vm.model, "produtoLoteId", $$v)
              },
              expression: "model.produtoLoteId",
            },
          }),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _c("td", { staticClass: "col-sm-2" }, [
      _c(
        "div",
        { staticClass: "form-group" },
        [
          _c("decimal-com-sinal-custom-component", {
            staticClass: "form-control",
            attrs: {
              id: "quantidadeItem",
              name: "quantidadeItem",
              signal: "un",
              disabled: _vm.desativaCampos || _vm.isSemEstoque,
            },
            model: {
              value: _vm.model.quantidade,
              callback: function ($$v) {
                _vm.$set(_vm.model, "quantidade", $$v)
              },
              expression: "model.quantidade",
            },
          }),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _c("td", { staticClass: "col-sm-2" }, [
      _c(
        "div",
        { staticClass: "form-group" },
        [
          _c("moeda-component", {
            staticClass: "form-control",
            attrs: { id: "totalItem", name: "totalItem", disabled: "" },
            model: {
              value: _vm.model.valorTotal,
              callback: function ($$v) {
                _vm.$set(_vm.model, "valorTotal", $$v)
              },
              expression: "model.valorTotal",
            },
          }),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _c("td", { staticClass: "text-center option-column" }, [
      _c(
        "a",
        {
          attrs: { href: "#", title: _vm.$t("__.Crud.excluir") },
          on: {
            click: function ($event) {
              $event.preventDefault()
              return _vm.onRemoveItem($event)
            },
          },
        },
        [
          _c("i", {
            staticClass: "fa fa-times",
            attrs: { "aria-hidden": "true" },
          }),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }