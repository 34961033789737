import Vue from "vue";
import { mapGetters, mapState } from "vuex";
import { mapMutations } from "vuex";

import actionBarComponent from "@/components/child/actionBar/actionBar.vue";
import buttonIncluirComponent from "@/components/child/form/button/buttonIncluir.vue";
import checkboxComponent from "@/components/child/form/checkbox.vue";
import comboComponent from "@/components/child/form/combo.vue";
import cpfComponent from "@/components/child/form/cpf.vue";
import fieldsetComponent from "@/components/child/form/fieldset.vue";
import gridComponent from "@/components/child/grid/grid.vue";
import { GridColumn, GridColumnExt, GridColumnType } from "@/components/child/grid/gridColumn";
import infoBagdeComponent from "@/components/child/infoBagde/infoBagde.vue";
import logEntidadeComponent from "@/components/child/logEntidade/logEntidade.vue";
import { Component, Watch } from "@/decorators";
import ColaboradorModel from "@/models/colaboradorModel";
import EnumExtensions from "@/models/enum/extensions/enumExtensions";
import PerfisUsuario from "@/models/enum/perfisUsuario";
import Setor from "@/models/enum/setor";
import FranquiaModel from "@/models/franquiaModel";
import GrupoModel from "@/models/grupoModel";
import GrupoUsuarioModel from "@/models/grupoUsuarioModel";
import PaginationModel from "@/models/paginationModel";
import TelaAcaoModel from "@/models/telaAcaoModel";
import TelaAcaoRemoverModel from "@/models/telaAcaoRemoverModel";
import TelaAcaoUsuarioModel from "@/models/telaAcaoUsuarioModel";
import UsuarioFranquiaModel from "@/models/usuarioFranquiaModel";
import UsuarioModel from "@/models/usuarioModel";
import ColaboradorService from "@/services/colaboradorService";
import FranquiaService from "@/services/franquiaService";
import GrupoService from "@/services/grupoService";
import TelaAcaoService from "@/services/telaAcaoService";
import UsuarioService from "@/services/usuarioService";
import { LoadListProps, Mutations } from "@/store/store";
import { AppState, Getters, SessionActions } from "@/store/store";
import { editDateWithTime } from "@/utils/common/date";

import "../crud.scss";
import "./edit.scss";

@Component({
    components: {
        comboComponent,
        fieldsetComponent,
        gridComponent,
        checkboxComponent,
        cpfComponent,
        buttonIncluirComponent,
        actionBarComponent,
        infoBagdeComponent,
        logEntidadeComponent,
    },
    computed: {
        ...mapState({
            usuarioLogado: (state: AppState) => state.session.usuarioLogado,
        }),
        ...mapGetters(["HAS_PERMISSAO_PERFIL"] as Getters),
        infoBagdeText() {
            return "Data criação: " + editDateWithTime(this.model.dataCriacao);
        },
    },
    methods: mapMutations(["LOAD_LIST"] as Mutations),
})
export default class UsuarioEditComponent extends Vue {
    // State computed props
    LOAD_LIST: (props: LoadListProps) => void;
    HAS_PERMISSAO_PERFIL: (isRede: boolean, isFranqueador: boolean, isSuporte: boolean) => Promise<boolean>;
    usuarioLogado: UsuarioModel;
    infoBagdeText: string;

    private service = new UsuarioService();

    model: UsuarioModel = new UsuarioModel();
    modelId = 0;
    franquiaId = 0;
    isFranquiaPadrao = false;
    isAdmin = false;

    acoesOptions: Array<Object> = [];
    gruposOptions: Array<Object> = [];
    franquiasOptions: Array<Object> = [];
    colaboradoresOptions: Array<Object> = [];

    franquiaIdRemovida = 0;
    acaoIdRemovida = 0;
    acaoId = 0;
    grupoId = 0;

    gridColumnsTelaAcao: Array<GridColumn> = [new GridColumn("telaAcaoDescricao", "Descrição", GridColumnType.String)];

    gridColumnsGrupo: Array<GridColumn> = [new GridColumn("grupoDescricao", "Descrição", GridColumnType.String)];

    gridColumnsTelaAcaoRemovida: Array<GridColumn> = [
        new GridColumn("franquiaDescricao", "Franquia", GridColumnType.String),
        new GridColumn("telaAcaoDescricao", "Descrição", GridColumnType.String),
    ];

    gridColumnsFranquia: Array<GridColumn> = [
        new GridColumn("franquiaDescricao", "Descrição", GridColumnType.String),
        new GridColumnExt({
            value: "setor",
            text: "Setor",
            type: GridColumnType.Combo,
            editable: true,
            comboOptions: () => EnumExtensions.getNamesAndValuesOrderedByValues(Setor),
            onChange: () => {},
            defaultValueProp: "setor",
        }),
    ];

    usuarioPerfilOptions: Array<Object> = [];

    constructor() {
        super();

        this.model.perfil = PerfisUsuario.Padrao;
    }

    private async load() {
        this.model = new UsuarioModel();

        switch (this.usuarioLogado.perfil) {
            case PerfisUsuario.Admin:
                this.usuarioPerfilOptions.push({ text: "Admin", value: PerfisUsuario.Admin });
                this.usuarioPerfilOptions.push({ text: "Padrão", value: PerfisUsuario.Padrao });
                this.usuarioPerfilOptions.push({ text: "Franqueado", value: PerfisUsuario.Franqueado });
                this.usuarioPerfilOptions.push({ text: "Rede", value: PerfisUsuario.Rede });
                this.usuarioPerfilOptions.push({ text: "Suporte", value: PerfisUsuario.Suporte });
                break;
            case PerfisUsuario.Suporte:
                this.usuarioPerfilOptions.push({ text: "Padrão", value: PerfisUsuario.Padrao });
                this.usuarioPerfilOptions.push({ text: "Franqueado", value: PerfisUsuario.Franqueado });
                this.usuarioPerfilOptions.push({ text: "Suporte", value: PerfisUsuario.Suporte });
                break;
            case PerfisUsuario.Franqueado:
                this.usuarioPerfilOptions.push({ text: "Padrão", value: PerfisUsuario.Padrao });
                this.usuarioPerfilOptions.push({ text: "Franqueado", value: PerfisUsuario.Franqueado });
                break;
            default:
                this.usuarioPerfilOptions.push({ text: "Padrão", value: PerfisUsuario.Padrao });
                break;
        }

        if (this.modelId > 0) {
            try {
                const data = await this.service.get(this.modelId).withLoading().resolveWithJSON<UsuarioModel>();
                this.model.updateFrom(data);

                this.reloadTelasAcaoUsuarioOptions();
                this.reloadGruposUsuarioOptions();
            } catch {
                this.$router.back();
            }
        } else {
            this.model.perfil = PerfisUsuario.Padrao;
        }
    }

    private async onShowLogsUsuario(open: (slot: number, title: string, size: string) => {}) {
        open(1, this.$t("__.ts.histAlterUsuario") as string, "lg-modal");
    }

    private async loadAcoes() {
        try {
            const data = await new TelaAcaoService().getPermissoesUsuarioLogado().resolveWithJSON<TelaAcaoModel[]>();

            this.acoesOptions = data.map(item => ({
                value: item.id,
                text: `${item.tela.descricao} - ${item.tela.controller} - ${item.descricao}`,
            }));
        } catch {}
    }

    private async loadGrupos() {
        try {
            const data = await new GrupoService().combo().resolveWithJSON<PaginationModel<GrupoModel>>();

            this.gruposOptions = data.list.map(item => ({
                value: item.id,
                text: item.descricao,
            }));
        } catch {}
    }

    private async loadFranquias() {
        try {
            const data = await new FranquiaService().combo().resolveWithJSON<PaginationModel<FranquiaModel>>();

            this.franquiasOptions = data.list.map(item => ({
                value: item.id,
                text: item.nomeFantasia,
            }));
        } catch {}
    }

    private async loadColaboradores() {
        try {
            const data = await new ColaboradorService().combo().resolveWithJSON<PaginationModel<ColaboradorModel>>();
            this.colaboradoresOptions = data.list.map(item => ({
                value: item.id,
                text: item.nome,
                email: item.email,
            }));
        } catch {}
    }

    private async setDadosColaborador() {
        try {
            if (this.model.colaboradorId > 0 && !this.model.id) {
                const data = this.colaboradoresOptions.find(p => p["value"] == this.model.colaboradorId);
                if (data) {
                    this.model.nome = data["text"];
                    this.model.email = data["email"];
                }
            }
        } catch {}
    }

    private async save() {
        try {
            const isValid = await this.$validator.validateAll();

            if (isValid) {
                // Incluir franquia padrao na lista de franquias do usuário se ela não estiver lá
                if (this.model.usuarioFranquias.every(p => p.franquiaId != this.model.franquiaId)) {
                    const usuarioFranquiaModel = new UsuarioFranquiaModel();
                    usuarioFranquiaModel.franquiaId = this.model.franquiaId;
                    usuarioFranquiaModel.franquiaDescricao = this.franquiasOptions.find(
                        p => p["value"] == this.model.franquiaId,
                    )["text"];
                    this.model.usuarioFranquias.push(usuarioFranquiaModel);
                }

                const sucesso = await this.service[!this.model.id ? "insert" : "update"](this.model)
                    .withLoading()
                    .resolveWithoutJSON();

                if (sucesso) {
                    await this.$store.dispatch(SessionActions.RELOAD_USUARIO_LOGADO);
                    await this.$showInclusaoUpdate(this.model.id);
                    this.LOAD_LIST({ loadVendedores: true });
                    this.$router.back();
                }
            } else {
                this.$focusErrorField();
            }
        } catch {}
    }

    private onInclusaoTelaAcao() {
        const telaAcaoComponent = this.$el.querySelector("#acaoId") as HTMLSelectElement;

        if (this.acaoId > 0) {
            const alreadyExists = this.model.telasAcaoUsuario.some(p => p.id == this.acaoId);

            if (!alreadyExists) {
                const telaAcaoUsuario = new TelaAcaoUsuarioModel();
                telaAcaoUsuario.telaAcaoId = this.acaoId;
                telaAcaoUsuario.telaAcaoDescricao = telaAcaoComponent.innerText;

                this.model.telasAcaoUsuario.push(telaAcaoUsuario);

                this.acaoId = -1;
            }
        }

        this.reloadTelasAcaoUsuarioOptions();
    }

    private onInclusaoGrupo() {
        const grupoComponent = this.$el.querySelector("#grupoId") as HTMLSelectElement;

        if (this.grupoId > 0) {
            const alreadyExists = this.model.gruposUsuario.some(p => p.grupoId == this.grupoId);

            if (!alreadyExists) {
                const grupoUsuario = new GrupoUsuarioModel();
                grupoUsuario.grupoId = this.grupoId;
                grupoUsuario.grupoDescricao = grupoComponent.innerText;

                this.model.gruposUsuario.push(grupoUsuario);

                this.grupoId = -1;
            }
        }

        this.reloadGruposUsuarioOptions();
    }

    private async onInclusaoTelaAcaoRemovida() {
        if (this.franquiaIdRemovida <= 0) {
            await this.$showWarning(this.$t("__.ts.aviso"), this.$t("Selecione a Franquia que deseja restringir"));
            return;
        } else if (this.acaoIdRemovida <= 0) {
            await this.$showWarning(this.$t("__.ts.aviso"), this.$t("Selecione a Ação que deseja restringir"));
            return;
        }

        const franquiaComponent = this.$el.querySelector("#franquiaIdRemovida") as HTMLSelectElement;
        const telaAcaoComponent = this.$el.querySelector("#acaoIdRemovida") as HTMLSelectElement;
        const alreadyExists = this.model.telasAcaoRemover.some(
            p => p.franquiaId == this.franquiaIdRemovida && p.telaAcaoId == this.acaoIdRemovida,
        );

        if (alreadyExists) {
            await this.$showWarning(
                this.$t("__.ts.aviso"),
                this.$t("A Franquia e Ação selecionada ja consta como restringida"),
            );
            return;
        } else {
            const telaAcaoRemoverModel = new TelaAcaoRemoverModel();

            telaAcaoRemoverModel.franquiaId = this.franquiaIdRemovida;
            telaAcaoRemoverModel.franquiaDescricao = franquiaComponent.innerText;
            telaAcaoRemoverModel.usuarioId = this.model.id;
            telaAcaoRemoverModel.telaAcaoId = this.acaoIdRemovida;
            telaAcaoRemoverModel.telaAcaoDescricao = telaAcaoComponent.innerText;

            this.model.telasAcaoRemover.push(telaAcaoRemoverModel);

            this.franquiaIdRemovida = -1;
            this.acaoIdRemovida = -1;

            return;
        }
    }

    private onInclusaoFranquia() {
        const franquiaComponent = this.$el.querySelector("#franquiaId") as HTMLSelectElement;

        if (this.franquiaId > 0) {
            const alreadyExists = this.model.usuarioFranquias.some(p => p.franquiaId == this.franquiaId);

            if (!alreadyExists) {
                if (this.isFranquiaPadrao) {
                    this.model.franquiaId = this.franquiaId;
                }
                this.isFranquiaPadrao = false;

                const usuarioFranquiaModel = new UsuarioFranquiaModel();
                usuarioFranquiaModel.franquiaId = this.franquiaId;
                usuarioFranquiaModel.franquiaDescricao = franquiaComponent.innerText;

                this.model.usuarioFranquias.push(usuarioFranquiaModel);

                this.franquiaId = -1;
            }
        }
    }

    private onRemoveTelaAcao(model: TelaAcaoUsuarioModel) {
        this.model.telasAcaoUsuario = this.model.telasAcaoUsuario.filter(p => p != model);

        this.reloadTelasAcaoUsuarioOptions();
    }

    private onRemoveGrupo(model: GrupoUsuarioModel) {
        this.model.gruposUsuario = this.model.gruposUsuario.filter(p => p != model);

        this.reloadGruposUsuarioOptions();
    }

    private onRemoveTelaAcaoRemovida(model: TelaAcaoRemoverModel) {
        this.model.telasAcaoRemover = this.model.telasAcaoRemover.filter(p => p != model);
    }

    private onRemoveFranquia(model: UsuarioFranquiaModel) {
        this.model.usuarioFranquias = this.model.usuarioFranquias.filter(p => p != model);
        if (this.model.usuarioFranquias.length == 0 && !(this.model.id > 0)) {
            this.model.franquiaId = null;
        }
    }

    private reloadTelasAcaoUsuarioOptions() {
        this.acoesOptions = this.acoesOptions.filter(
            p => !this.model.telasAcaoUsuario.some(a => a.telaAcaoId == p["value"]),
        );
    }

    private reloadGruposUsuarioOptions() {
        this.gruposOptions = this.gruposOptions.filter(
            p => !this.model.gruposUsuario.some(a => a.grupoId == p["value"]),
        );
    }

    private cancel() {
        this.$router.back();
    }

    private async onClickFranquiaDrogavet() {
        try {
            const data = await new FranquiaService().combo().resolveWithJSON<PaginationModel<FranquiaModel>>();
            data.list
                .filter(x => x.redeId == 6)
                .forEach(item => {
                    const alreadyExists = this.model.usuarioFranquias.some(p => p.franquiaId == item.id);
                    if (!alreadyExists) {
                        const usuarioFranquiaModel = new UsuarioFranquiaModel();
                        usuarioFranquiaModel.franquiaId = item.id;
                        usuarioFranquiaModel.franquiaDescricao = item.nomeFantasia;

                        this.model.usuarioFranquias.push(usuarioFranquiaModel);
                    }
                });
        } catch {}
    }

    //@ts-ignore
    @Watch("franquiaId")
    private onFranquiaChanged() {
        const alreadyHasdefault = this.model.franquiaId > 0;
        if (this.franquiaId && alreadyHasdefault) {
            this.isFranquiaPadrao = false;
        }

        if (!alreadyHasdefault && !alreadyHasdefault) {
            this.isFranquiaPadrao = true;
        }
    }

    //@ts-ignore
    @Watch("model.colaboradorId")
    private async onColaboradorChanged() {
        if (this.model.colaboradorId > 0) {
            await this.setDadosColaborador();
        }
    }

    private async resetarSenha() {
        try {
            const sucesso = await this.service.resetarSenha(this.model.id).withLoading().resolveWithoutJSON();
            if (sucesso) {
                await this.$showSuccess(
                    "Senha alterada",
                    "A senha do usuário foi alterada, foi enviado email para o usuário com as credenciais de acesso",
                );
            }
        } catch {}
    }

    private async mounted() {
        await this.$store.dispatch(SessionActions.LOAD_USUARIO_LOGADO);
        Promise.all([this.loadAcoes(), this.loadGrupos(), this.loadFranquias(), this.loadColaboradores()])
            .withLoading()
            .then(async () => {
                if (this.$route.params.id) {
                    this.modelId = +this.$route.params.id;
                }

                this.isAdmin = await this.HAS_PERMISSAO_PERFIL(false, false, true);

                this.load();
            })
            .catch(() => {});
    }
}
