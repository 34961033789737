import { GridColumn, GridColumnType } from "@/components/child/grid/gridColumn";
import { Component } from "@/decorators";
import MetaModel from "@/models/metaModel";
import MetaService from "@/services/metaService";

import ListComponentBase from "../listComponentBase";

@Component
export default class MetaListComponent extends ListComponentBase<MetaModel> {
    get gridColumns(): Array<GridColumn> {
        return [
            new GridColumn("mesAno", "Período", GridColumnType.String),
            new GridColumn("valor", "Valor", GridColumnType.Money),
            new GridColumn("diasUteis", "Dias Úteis", GridColumnType.Integer),
        ];
    }

    private beforeMount() {
        this.setProps(new MetaService(), "meta", "mesAno");
    }
}
