import Vue from "vue";
import Vuei18n from "vue-i18n";
import InfiniteScroll from "vue-infinite-scroll";
import VueNetwork from "vue-network";
import Notifications from "vue-notification";
import VueResize from "vue-resize";
import VueRouter from "vue-router";
import VueSelect from "vue-select";
import VueShortKey from "vue-shortkey";

import Moment from "moment";
import VeeValidate from "vee-validate";

import PrinterService from "@/services/print/printerService";

import App from "./app.vue";
import { i18n } from "./i18n";
import routes from "./routes";
import store from "./store/store";
import Filter from "./utils/plugin/filter";
import FilterNumber from "./utils/plugin/filter-number";
import Mask from "./utils/plugin/mask";
import Tooltip from "./utils/plugin/tooltip";

//@ts-ignore
Vue.component("vue-multiselect", window.VueMultiselect.default);
//@ts-ignore
Vue.component("v-select", VueSelect);
Vue.component("v-network", VueNetwork);

Vue.use(Filter);
Vue.use(FilterNumber);
Vue.use(InfiniteScroll);
Vue.use(Mask);
Vue.use(Notifications);
Vue.use(Tooltip);
Vue.use(VeeValidate, { locale: "pt_BR" });
Vue.use(Vuei18n);
Vue.use(VueResize);
Vue.use(VueRouter);
Vue.use(VueShortKey);

Object.defineProperty(Vue.prototype, "$Moment", { value: Moment });
Date.prototype.toJSON = function () {
    return Moment(this).format();
};

import "./utils/promiseWithLoading";
import "./utils/promiseWithJSON";
import "./utils/promiseWithTimeout";
import "./utils/arrayExtensions";

import "./utils/plugin/validator";

import "./utils/common/alert";
import "./utils/common/focus";
import "./utils/common/formatters";
import "./utils/common/notification";

import "./styles/global.scss";

Vue.config.productionTip = false;

const router = new VueRouter({ mode: "history", routes });

router.beforeEach((to, from, next) => {
    const token = localStorage.getItem("@PharmUP:Token");
    const isRequiredAuthRoute =
        to.path.toLocaleLowerCase() !== "/login" &&
        to.path.toLocaleLowerCase() !== "/esqueceu-senha" &&
        !/^\/cotacao-fornecedor\/[0-9a-fA-F\-]{36}$/.test(to.path.toLocaleLowerCase());

    if (isRequiredAuthRoute && !token) {
        next({ path: "/login" });
    }
    if (!isRequiredAuthRoute && token) {
        next({ path: "/" });
    }
    next();
});

router.afterEach(to => {
    Vue.nextTick(() => {
        document.title = to.meta.title ? `${to.meta.title} - PharmUP` : "PharmUP";
    });
});

PrinterService.init();

const vue = new Vue({
    router,
    store,
    i18n,
    render: h => h(App),
}).$mount("#app");

export default vue;
