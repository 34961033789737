import Vue from "vue";

import { Component } from "@/decorators";
import LoginService from "@/services/loginService";

import "../login.scss";

@Component
export default class EsqueceuSenhaComponent extends Vue {
    private service = new LoginService();
    private email = "";

    private async onEsqueceuSenha() {
        try {
            const isValid = await this.$validator.validateAll();
            if (isValid) {
                await this.service.esqueceuSenha(this.email).withLoading().resolveWithResponse();

                await this.$showSuccess(this.$t("__.ts.sucesso"), this.$t("__.ts.emailEnviSucess"));

                this.$router.push("/login");
            }
        } catch {}
    }
}
