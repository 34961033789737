import Vue from "vue";

import Inputmask from "inputmask";

import { Component, Prop, Watch } from "@/decorators";

@Component
export default class DecimalComSufixoNullableComponent extends Vue {
    @Prop(String) id: string;
    @Prop(String) name: string;
    @Prop(Number) value: number;
    @Prop(String) suffix: string;
    @Prop(String) prefix: string;

    private onBlur(value) {
        this.$emit("input", this.formatValueToModel(value));
        this.$emit("change", this.formatValueToModel(value));
    }

    private formatValueToModel(value) {
        if (value == "") {
            return null;
        }

        return +value
            .replace(<string>this.suffix, "")
            .replace(<string>this.prefix, "")
            .replace(/\./g, "")
            .replace(",", ".");
    }

    private getFocus(event) {
        const inputElement = event.target as HTMLInputElement;
        const value = inputElement.value;
        const suffix = this.suffix || "%";
        const suffixPosition = value.indexOf(suffix);

        if (suffixPosition !== -1) {
            inputElement.focus();
            inputElement.setSelectionRange(suffixPosition - 1, suffixPosition);
        } else {
            inputElement.focus();
            inputElement.setSelectionRange(value.length, value.length);
        }
    }

    private onInput(event) {
        const numericValue = event.target.value.replace(/[^0-9]/g, "");
        if (numericValue > 0) {
            this.applyMask(Number(numericValue));
        }
    }

    @Watch("value")
    private onValueChanged(newValue, oldValue) {
        if (oldValue == null && newValue != null) {
            this.applyMask(Number(newValue));
        }
    }

    private applyMask(value) {
        const element = document.getElementById(this.id);

        if (value >= 0 && !element.inputmask) {
            Inputmask({
                alias: "numeric",
                allowMinus: false,
                clearMaskOnLostFocus: false,
                digits: 4,
                placeholder: "",
                inputmode: "decimal",
                inputType: "number",
                suffix: this.suffix ? " " + this.suffix : "",
                prefix: this.prefix ? this.prefix + " " : "",
                radixPoint: ",",
                rightAlign: false,
            }).mask(element);
        } else if (element.inputmask && value.toString().replace(" %", "") == "0") {
            element.inputmask.remove();
            this.value = null;
        }
    }

    private mounted() {
        if (this.value != null) {
            this.applyMask(Number(this.value));
        }
    }
}
