var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "crud-lote-item-grid" },
    _vm._l(_vm.produtosParaVerificacao, function (produto, index) {
      return _c(
        "fieldset-component",
        {
          key: produto.id,
          attrs: {
            title: _vm.getTitle(produto),
            collapsed: produto.ignorarControleEstoque,
            headerColor: _vm.getColorHeaderRestante(
              index,
              produto.ignorarControleEstoque
            ),
          },
        },
        [
          produto.ignorarControleEstoque
            ? _c("div", { attrs: { slot: "rows" }, slot: "rows" }, [
                produto.ignorarControleEstoque
                  ? _c(
                      "span",
                      {
                        staticStyle: {
                          color: "orange",
                          "font-size": "18px",
                          "margin-left": "25px",
                        },
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(
                              _vm.$t(
                                "__.Components.parent.manipulacao.loteitem_vue_html.produtoParametNaoControlEstq"
                              )
                            ) +
                            "\n            "
                        ),
                      ]
                    )
                  : _vm._e(),
              ])
            : _c(
                "div",
                { attrs: { slot: "rows" }, slot: "rows" },
                _vm._l(produto.produtoLotes, function (lote, indexLote) {
                  return _c(
                    "div",
                    { key: indexLote, staticClass: "row-main" },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "row",
                          attrs: { id: "ajustaPaddingLoteId" },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "col-sm-3 ajustaPaddingLote" },
                            [
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "tooltip",
                                      rawName: "v-tooltip",
                                      value: _vm.errors.first(
                                        "produtoLoteId" + index + indexLote
                                      ),
                                      expression:
                                        "errors.first('produtoLoteId' + index + indexLote)",
                                    },
                                  ],
                                  staticClass: "form-group",
                                },
                                [
                                  indexLote == 0
                                    ? _c(
                                        "label",
                                        {
                                          staticClass: "control-label",
                                          attrs: {
                                            for:
                                              "produtoLoteId" +
                                              index +
                                              indexLote,
                                            title: _vm.$t("__.obrigatorio"),
                                          },
                                        },
                                        [
                                          _c(
                                            "span",
                                            { staticStyle: { color: "red" } },
                                            [_vm._v("*")]
                                          ),
                                          _vm._v(
                                            "\n                                " +
                                              _vm._s(
                                                _vm.$t(
                                                  "__.Components.parent.manipulacao.loteitem_vue_html.lote"
                                                )
                                              ) +
                                              "\n                            "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  indexLote == 0
                                    ? _c("data-tooltip-component", {
                                        attrs: {
                                          text: _vm.$t(
                                            "__.Components.parent.manipulacao.loteitem_vue_html.loteManipulacaoItemTooltip"
                                          ),
                                        },
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c("combo-component", {
                                    directives: [
                                      {
                                        name: "validate",
                                        rawName: "v-validate",
                                        value: "required",
                                        expression: "'required'",
                                      },
                                    ],
                                    staticClass: "form-grid",
                                    attrs: {
                                      id: "produtoLoteId" + index + indexLote,
                                      name: "produtoLoteId" + index + indexLote,
                                      data: _vm.produtoLoteOptions[index],
                                      searchable: false,
                                    },
                                    on: {
                                      input: function ($event) {
                                        _vm.onChangedLoteId(index, indexLote)
                                      },
                                    },
                                    model: {
                                      value: lote.id,
                                      callback: function ($$v) {
                                        _vm.$set(lote, "id", $$v)
                                      },
                                      expression: "lote.id",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "col-sm-2 ajustaPaddingLote" },
                            [
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "tooltip",
                                      rawName: "v-tooltip",
                                      value: _vm.errors.first(
                                        "quantidadeAtual" + index + indexLote
                                      ),
                                      expression:
                                        "errors.first('quantidadeAtual' + index + indexLote)",
                                    },
                                  ],
                                  staticClass: "form-group",
                                },
                                [
                                  indexLote == 0
                                    ? _c(
                                        "label",
                                        {
                                          staticClass: "control-label",
                                          attrs: {
                                            for:
                                              "quantidadeAtual" +
                                              index +
                                              indexLote,
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                                " +
                                              _vm._s(
                                                _vm.$t(
                                                  "__.Components.parent.manipulacao.loteitem_vue_html.qntdEstoque"
                                                )
                                              ) +
                                              "\n                            "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c("decimal-com-sinal-custom-component", {
                                    staticClass: "form-control",
                                    attrs: {
                                      signal: _vm.getSignalQuantidade(
                                        produto.unidadeMedidaEstoqueId
                                      ),
                                      id: "quantidadeAtual" + index + indexLote,
                                      name:
                                        "quantidadeAtual" + index + indexLote,
                                      readonly: "",
                                    },
                                    model: {
                                      value: lote.quantidadeAtual,
                                      callback: function ($$v) {
                                        _vm.$set(lote, "quantidadeAtual", $$v)
                                      },
                                      expression: "lote.quantidadeAtual",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "col-sm-2",
                              attrs: { ajustaPaddingLote: "" },
                            },
                            [
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "tooltip",
                                      rawName: "v-tooltip",
                                      value: _vm.errors.first(
                                        "quantidadeBaixa" + index + indexLote
                                      ),
                                      expression:
                                        "errors.first('quantidadeBaixa' + index + indexLote)",
                                    },
                                  ],
                                  staticClass: "form-group",
                                },
                                [
                                  indexLote == 0
                                    ? _c(
                                        "label",
                                        {
                                          staticClass: "control-label",
                                          attrs: {
                                            for:
                                              "quantidadeBaixa" +
                                              index +
                                              indexLote,
                                            title: _vm.$t("__.obrigatorio"),
                                          },
                                        },
                                        [
                                          _c(
                                            "span",
                                            { staticStyle: { color: "red" } },
                                            [_vm._v("*")]
                                          ),
                                          _vm._v(
                                            "\n                                " +
                                              _vm._s(
                                                _vm.$t(
                                                  "__.Components.parent.manipulacao.loteitem_vue_html.qntdBaixa"
                                                )
                                              ) +
                                              "\n                            "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c("decimal-com-sinal-custom-component", {
                                    directives: [
                                      {
                                        name: "validate",
                                        rawName: "v-validate",
                                        value: "required|maior_que_zero",
                                        expression: "'required|maior_que_zero'",
                                      },
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      signal: _vm.getSignalQuantidade(
                                        produto.unidadeMedidaEstoqueId
                                      ),
                                      id: "quantidadeBaixa" + index + indexLote,
                                      name:
                                        "quantidadeBaixa" + index + indexLote,
                                    },
                                    on: {
                                      input: function ($event) {
                                        _vm.onChangedQuantidade(
                                          index,
                                          indexLote
                                        )
                                      },
                                    },
                                    model: {
                                      value: lote.quantidadeBaixa,
                                      callback: function ($$v) {
                                        _vm.$set(lote, "quantidadeBaixa", $$v)
                                      },
                                      expression: "lote.quantidadeBaixa",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "col-sm-2 ajustaPaddingLote" },
                            [
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "tooltip",
                                      rawName: "v-tooltip",
                                      value: _vm.errors.first(
                                        "dataValidade" + index + indexLote
                                      ),
                                      expression:
                                        "errors.first('dataValidade' + index + indexLote)",
                                    },
                                  ],
                                  staticClass: "form-group",
                                },
                                [
                                  indexLote == 0
                                    ? _c(
                                        "label",
                                        {
                                          staticClass: "control-label",
                                          attrs: {
                                            for:
                                              "dataValidade" +
                                              index +
                                              indexLote,
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                                " +
                                              _vm._s(
                                                _vm.$t(
                                                  "__.Components.parent.manipulacao.loteitem_vue_html.val"
                                                )
                                              ) +
                                              "\n                            "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: lote.dataValidade,
                                        expression: "lote.dataValidade",
                                      },
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      autocomplete: "off",
                                      type: "text",
                                      readonly: "",
                                      id: "dataValidade" + index + indexLote,
                                      name: "dataValidade" + index + indexLote,
                                      maxlength: "10",
                                    },
                                    domProps: { value: lote.dataValidade },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          lote,
                                          "dataValidade",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                ]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "col-sm-2 ajustaPaddingLote" },
                            [
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "tooltip",
                                      rawName: "v-tooltip",
                                      value: _vm.errors.first(
                                        "statusEstoque" + index + indexLote
                                      ),
                                      expression:
                                        "errors.first('statusEstoque' + index + indexLote)",
                                    },
                                  ],
                                  staticClass: "form-group",
                                },
                                [
                                  indexLote == 0
                                    ? _c(
                                        "label",
                                        {
                                          staticClass: "control-label",
                                          attrs: {
                                            for:
                                              "statusEstoque" +
                                              index +
                                              indexLote,
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                                " +
                                              _vm._s(
                                                _vm.$t(
                                                  "__.Components.parent.manipulacao.loteitem_vue_html.status"
                                                )
                                              ) +
                                              "\n                            "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c("combo-component", {
                                    attrs: {
                                      id: "statusEstoque" + index + indexLote,
                                      disabled: true,
                                      name: "statusEstoque" + index + indexLote,
                                      data: _vm.statusEstoqueOptions,
                                      searchable: false,
                                    },
                                    model: {
                                      value: lote.statusEstoque,
                                      callback: function ($$v) {
                                        _vm.$set(lote, "statusEstoque", $$v)
                                      },
                                      expression: "lote.statusEstoque",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "col-sm-1 ajustaPaddingLote" },
                            [
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "tooltip",
                                      rawName: "v-tooltip",
                                      value: _vm.errors.first(
                                        "acao" + index + indexLote
                                      ),
                                      expression:
                                        "errors.first('acao' + index + indexLote)",
                                    },
                                  ],
                                  staticClass: "form-group",
                                },
                                [
                                  indexLote == 0
                                    ? _c(
                                        "label",
                                        {
                                          staticClass: "control-label",
                                          attrs: {
                                            for: "acao" + index + indexLote,
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                                " +
                                              _vm._s(
                                                _vm.$t(
                                                  "__.Crud.listInputs.acao"
                                                )
                                              ) +
                                              "\n                            "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c("span", { staticClass: "option-column" }, [
                                    _c(
                                      "a",
                                      {
                                        attrs: {
                                          title: _vm.$t(
                                            "__.Crud.listInputs.remover"
                                          ),
                                          href: "#",
                                        },
                                        on: {
                                          click: function ($event) {
                                            $event.preventDefault()
                                            _vm.onRemover(index, indexLote)
                                          },
                                        },
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "fa fa-times excluir",
                                          attrs: { "aria-hidden": "true" },
                                        }),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    indexLote == produto.produtoLotes.length - 1
                                      ? _c(
                                          "a",
                                          {
                                            attrs: {
                                              title: _vm.$t(
                                                "__.Crud.listInputs.adicionar"
                                              ),
                                              href: "#",
                                            },
                                            on: {
                                              click: function ($event) {
                                                $event.preventDefault()
                                                _vm.criaBaseProdutoLote(
                                                  produto.id,
                                                  produto.sequencial
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "fa fa-plus addLote",
                                              attrs: { "aria-hidden": "true" },
                                            }),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]),
                                ]
                              ),
                            ]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      indexLote == produto.produtoLotes.length - 1
                        ? _c("div", { staticClass: "footer" }, [
                            _c("div", { staticClass: "row-footer-line" }, [
                              _c("div", { staticClass: "col-sm-12" }, [
                                _c(
                                  "div",
                                  { staticClass: "form-group-footer" },
                                  [
                                    _c("label", {
                                      staticClass: "control-label-line-footer",
                                      attrs: {
                                        for: "lineFooter" + index + indexLote,
                                      },
                                    }),
                                  ]
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "row-footer" }, [
                              _c("div", { staticClass: "col-sm-3" }, [
                                _c("div", { staticClass: "form-group-footer" }),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "col-sm-2" }, [
                                _c(
                                  "div",
                                  { staticClass: "form-group-footer" },
                                  [
                                    _c(
                                      "label",
                                      {
                                        staticClass: "control-label-footer",
                                        attrs: {
                                          for: "restante" + index + indexLote,
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                                    " +
                                            _vm._s(
                                              _vm.$t(
                                                "__.Components.parent.manipulacao.loteitem_vue_html.saldo"
                                              )
                                            ) +
                                            "\n                                    "
                                        ),
                                        _c(
                                          "b",
                                          {
                                            class:
                                              "ajustaEstoq" +
                                              _vm.getClassRestante(index),
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.getRestante(index))
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "col-sm-2" }, [
                                _c(
                                  "div",
                                  { staticClass: "form-group-footer" },
                                  [
                                    _c(
                                      "label",
                                      {
                                        staticClass: "control-label-footer",
                                        attrs: {
                                          for: "totalBaixa" + index + indexLote,
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                                    " +
                                            _vm._s(
                                              _vm.$t(
                                                "__.Components.parent.manipulacao.loteitem_vue_html.total"
                                              )
                                            ) +
                                            "\n                                    "
                                        ),
                                        _c(
                                          "b",
                                          { staticClass: "ajustaEstoq" },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.getSomaBaixas(index))
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]),
                              _vm._v(" "),
                              _vm.showExtras(index)
                                ? _c("div", { staticClass: "col-sm-2" }, [
                                    _c(
                                      "div",
                                      { staticClass: "form-group-footer" },
                                      [
                                        _c(
                                          "label",
                                          {
                                            staticClass: "control-label-footer",
                                            attrs: {
                                              for: "extra" + index + indexLote,
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                                    " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "__.Components.parent.manipulacao.loteitem_vue_html.extra"
                                                  )
                                                ) +
                                                "\n                                    "
                                            ),
                                            _c(
                                              "b",
                                              {
                                                staticClass: "ajustaEstoqExtra",
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(_vm.getExtras(index))
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ])
                                : _vm._e(),
                            ]),
                          ])
                        : _vm._e(),
                    ]
                  )
                })
              ),
        ]
      )
    })
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }