var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "crud crud-edit" },
    [
      _c("actionBarComponent", {
        attrs: {
          title: _vm.$t("__.Crud.downloadXmls.edit_vue_html.downloadXmls"),
          saveText: _vm.$t("__.Crud.downloadXmls.edit_vue_html.download"),
        },
        on: { onSave: _vm.save },
      }),
      _vm._v(" "),
      _c(
        "form",
        {
          attrs: { novalidate: "novalidate" },
          on: {
            submit: function ($event) {
              $event.preventDefault()
              _vm.save()
            },
          },
        },
        [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-sm-6" }, [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "tooltip",
                      rawName: "v-tooltip",
                      value: _vm.errors.first("dataInicial"),
                      expression: "errors.first('dataInicial')",
                    },
                  ],
                  staticClass: "form-group",
                },
                [
                  _c(
                    "label",
                    {
                      staticClass: "control-label",
                      attrs: {
                        for: "dataInicial",
                        title: "* este campo é obrigatório",
                      },
                    },
                    [
                      _c("span", { staticStyle: { color: "red" } }, [
                        _vm._v("* "),
                      ]),
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "__.Crud.downloadXmls.edit_vue_html.dataInicial"
                          )
                        )
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("date-time-picker-component", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'",
                      },
                    ],
                    attrs: { id: "dataInicial", name: "dataInicial" },
                    model: {
                      value: _vm.dataInicial,
                      callback: function ($$v) {
                        _vm.dataInicial = $$v
                      },
                      expression: "dataInicial",
                    },
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-sm-6" }, [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "tooltip",
                      rawName: "v-tooltip",
                      value: _vm.errors.first("dataFinal"),
                      expression: "errors.first('dataFinal')",
                    },
                  ],
                  staticClass: "form-group",
                },
                [
                  _c(
                    "label",
                    {
                      staticClass: "control-label",
                      attrs: {
                        for: "dataFinal",
                        title: "* este campo é obrigatório",
                      },
                    },
                    [
                      _c("span", { staticStyle: { color: "red" } }, [
                        _vm._v("* "),
                      ]),
                      _vm._v(
                        _vm._s(
                          _vm.$t("__.Crud.downloadXmls.edit_vue_html.dataFinal")
                        )
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("date-time-picker-component", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'",
                      },
                    ],
                    attrs: { id: "dataFinal", name: "dataFinal" },
                    model: {
                      value: _vm.dataFinal,
                      callback: function ($$v) {
                        _vm.dataFinal = $$v
                      },
                      expression: "dataFinal",
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-sm-6" }, [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "tooltip",
                      rawName: "v-tooltip",
                      value: _vm.errors.first("tiposDocumento"),
                      expression: "errors.first('tiposDocumento')",
                    },
                  ],
                  staticClass: "form-group",
                },
                [
                  _c(
                    "label",
                    {
                      staticClass: "control-label",
                      attrs: {
                        for: "tiposDocumento",
                        title: "* este campo é obrigatório",
                      },
                    },
                    [
                      _c("span", { staticStyle: { color: "red" } }, [
                        _vm._v("* "),
                      ]),
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "__.Crud.downloadXmls.edit_vue_html.tiposDocumento"
                          )
                        )
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("combo-component", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'",
                      },
                    ],
                    attrs: {
                      id: "tiposDocumento",
                      name: "tiposDocumento",
                      data: _vm.tipoDocumentoOptions,
                      searchable: false,
                    },
                    model: {
                      value: _vm.tiposDocumento,
                      callback: function ($$v) {
                        _vm.tiposDocumento = $$v
                      },
                      expression: "tiposDocumento",
                    },
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-sm-6" }, [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "tooltip",
                      rawName: "v-tooltip",
                      value: _vm.errors.first("tiposNotas"),
                      expression: "errors.first('tiposNotas')",
                    },
                  ],
                  staticClass: "form-group",
                },
                [
                  _c(
                    "label",
                    {
                      staticClass: "control-label",
                      attrs: {
                        for: "tiposNotas",
                        title: "* este campo é obrigatório",
                      },
                    },
                    [
                      _c("span", { staticStyle: { color: "red" } }, [
                        _vm._v("* "),
                      ]),
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "__.Crud.downloadXmls.edit_vue_html.tiposNotas"
                          )
                        )
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("combo-component", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'",
                      },
                    ],
                    attrs: {
                      id: "tiposNotas",
                      name: "tiposNotas",
                      data: _vm.tiposNotasOptions,
                      searchable: false,
                    },
                    model: {
                      value: _vm.tiposNotas,
                      callback: function ($$v) {
                        _vm.tiposNotas = $$v
                      },
                      expression: "tiposNotas",
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("fieldset", [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-sm-12 newbtn" }, [
                _c("div", { staticClass: "form-group" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-success",
                      attrs: { type: "submit" },
                    },
                    [
                      _c("i", {
                        staticClass: "fa fa-save",
                        attrs: { "aria-hidden": "true" },
                      }),
                      _vm._v(
                        _vm._s(
                          _vm.$t("__.Crud.downloadXmls.edit_vue_html.download")
                        )
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-secondary",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          _vm.cancel()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("__.Crud.voltar")))]
                  ),
                ]),
              ]),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }