//import Moment from "moment";
import { copyObject, isEqual } from "../../utils/common/copyObject";

export default class BaseModel {
    public franquiaId?: number = null;

    public updateFrom(
        objectValue: any,
        ignoredProperties = new Array<string>(),
        ignoreEmptyProperties = false,
        objectReference?: any,
    ) {
        copyObject(objectValue, this, ignoredProperties, ignoreEmptyProperties, objectReference);
    }

    public isEmpty(this) {
        let props = Object.getOwnPropertyNames(this);
        const thisCompare = Object.create(Object.getPrototypeOf(this));
        thisCompare.constructor.apply(thisCompare);
        props = props.filter(d => d !== "__ob__");
        for (const item of props) {
            if (this[item] != thisCompare[item]) {
                if (this[item] instanceof Array) {
                    if (this[item].length !== 0) {
                        return false;
                    }
                } else if (!(this[item] instanceof Array) && this[item] instanceof Object) {
                    if (!this.isEmpty(this[item])) {
                        return false;
                    }
                } else {
                    return false;
                }
            }
        }
        return true;
    }

    public isEqual(this, modelCompare) {
        return isEqual(this, modelCompare);
    }
}
