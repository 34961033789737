var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "crud crud-edit" },
    [
      !_vm.isModal
        ? _c(
            "actionBarComponent",
            {
              attrs: {
                slots: 5,
                title: _vm.$t("__.Crud.produto.edit_vue_html.prodEdit"),
                showSave: !_vm.somenteConsulta,
              },
              on: { onSave: _vm.save },
              scopedSlots: _vm._u([
                {
                  key: "buttonSlot4",
                  fn: function (ref) {
                    var open = ref.open
                    return _vm.model.id
                      ? _c(
                          "div",
                          {
                            attrs: {
                              title: _vm.$t("__.ts.historicoAlteracoes"),
                            },
                            on: {
                              click: function ($event) {
                                open(
                                  1,
                                  _vm.$t("__.ts.historicoAlteracoes"),
                                  "lg-modal"
                                )
                              },
                            },
                          },
                          [
                            _c("i", {
                              staticClass: "fa fa-history",
                              attrs: { slot: "button", "aria-hidden": "true" },
                              slot: "button",
                            }),
                          ]
                        )
                      : _vm._e()
                  },
                },
              ]),
            },
            [
              _vm.validarVisibilidadeBotoes("replicar")
                ? _c(
                    "div",
                    {
                      attrs: {
                        slot: "buttonSlot0",
                        title: _vm.$t("__.Crud.replicar"),
                      },
                      on: {
                        click: function ($event) {
                          _vm.onReplicar()
                        },
                      },
                      slot: "buttonSlot0",
                    },
                    [
                      _c("i", {
                        staticClass: "fa fa-sync",
                        attrs: { "aria-hidden": "true" },
                      }),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.validarVisibilidadeBotoes("remover")
                ? _c(
                    "div",
                    {
                      attrs: {
                        slot: "buttonSlot1",
                        title: _vm.$t("__.Crud.excluir"),
                      },
                      on: {
                        click: function ($event) {
                          _vm.onDelete()
                        },
                      },
                      slot: "buttonSlot1",
                    },
                    [
                      _c("i", {
                        staticClass: "fa fa-times",
                        attrs: { "aria-hidden": "true" },
                      }),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.model.id
                ? _c(
                    "div",
                    {
                      attrs: {
                        slot: "buttonSlot2",
                        title: _vm.$t(
                          "__.Crud.produto.edit_vue_html.fichaTecnica"
                        ),
                      },
                      on: {
                        click: function ($event) {
                          _vm.onFichaTecnica()
                        },
                      },
                      slot: "buttonSlot2",
                    },
                    [
                      _c("i", {
                        staticClass: "fa fa-file",
                        attrs: { "aria-hidden": "true" },
                      }),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              (_vm.usuarioLogado.franquia.tipoFranquia == 3 ||
                _vm.isAdminSuporteFranqueado) &&
              _vm.model.id &&
              !_vm.somenteConsulta
                ? _c(
                    "div",
                    {
                      attrs: {
                        slot: "buttonSlot3",
                        title: _vm.$t(
                          "__.Crud.produto.edit_vue_html.trocarUnidadeMedidaEstoque"
                        ),
                      },
                      on: {
                        click: function ($event) {
                          _vm.onTrocarUnidadeMedidaEstoque()
                        },
                      },
                      slot: "buttonSlot3",
                    },
                    [
                      _c("i", {
                        staticClass: "fa fa-ruler",
                        attrs: { "aria-hidden": "true" },
                      }),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                { attrs: { slot: "modalSlot1" }, slot: "modalSlot1" },
                [
                  _c("logEntidadeComponent", {
                    attrs: { registroId: _vm.modelId, entidade: 4 },
                  }),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "form",
        {
          attrs: { novalidate: "novalidate" },
          on: {
            submit: function ($event) {
              $event.preventDefault()
              _vm.save()
            },
          },
        },
        [
          _c(
            "fieldset-component",
            {
              attrs: {
                title: _vm.$t("__.Crud.produto.edit_vue_html.dadosGerais"),
                collapsed: false,
              },
            },
            [
              _c("div", { attrs: { slot: "rows" }, slot: "rows" }, [
                _vm.isAdmin && _vm.modelId > 0
                  ? _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-sm-12" }, [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: _vm.errors.first("franquiaId"),
                                expression: "errors.first('franquiaId')",
                              },
                            ],
                            staticClass: "form-group",
                          },
                          [
                            _c(
                              "label",
                              {
                                staticClass: "control-label",
                                attrs: { for: "franquiaId" },
                              },
                              [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(
                                      _vm.$t(
                                        "__.Crud.produto.edit_vue_html.franquia"
                                      )
                                    ) +
                                    "\n                            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("combo-component", {
                              attrs: {
                                id: "franquiaId",
                                name: "franquiaId",
                                data: _vm.franquiaOptions,
                                searchable: false,
                                disabled: _vm.somenteConsulta,
                              },
                              model: {
                                value: _vm.franquiaId,
                                callback: function ($$v) {
                                  _vm.franquiaId = $$v
                                },
                                expression: "franquiaId",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _vm.model.id
                    ? _c("div", { staticClass: "col-sm-2" }, [
                        _c("div", { staticClass: "form-group" }, [
                          _c(
                            "label",
                            {
                              staticClass: "control-label",
                              attrs: { for: "codigoFranquia" },
                            },
                            [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(
                                    _vm.$t(
                                      "__.Crud.produto.edit_vue_html.codigoFranquia"
                                    )
                                  ) +
                                  "\n                            "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.model.id,
                                expression: "model.id",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: {
                              type: "text",
                              id: "codigoFranquia",
                              name: "codigoFranquia",
                              disabled: "",
                            },
                            domProps: { value: _vm.model.id },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(_vm.model, "id", $event.target.value)
                              },
                            },
                          }),
                        ]),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.model.produtoPaiId
                    ? _c("div", { staticClass: "col-sm-2" }, [
                        _c("div", { staticClass: "form-group" }, [
                          _c(
                            "label",
                            {
                              staticClass: "control-label",
                              attrs: { for: "codigoRede" },
                            },
                            [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(
                                    _vm.$t(
                                      "__.Crud.produto.edit_vue_html.codigoRede"
                                    )
                                  ) +
                                  "\n                            "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.model.produtoPaiId,
                                expression: "model.produtoPaiId",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: {
                              type: "text",
                              id: "codigoRede",
                              name: "codigoRede",
                              disabled: "",
                            },
                            domProps: { value: _vm.model.produtoPaiId },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.model,
                                  "produtoPaiId",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]),
                      ])
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-sm-11" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("descricao"),
                            expression: "errors.first('descricao')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: {
                              for: "descricao",
                              title: _vm.$t("__.obrigatorio"),
                            },
                          },
                          [
                            _c("span", { staticStyle: { color: "red" } }, [
                              _vm._v("*"),
                            ]),
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Crud.produto.edit_vue_html.descricao"
                                  )
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            { name: "focus", rawName: "v-focus" },
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.model.descricao,
                              expression: "model.descricao",
                            },
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required|max:256",
                              expression: "'required|max:256'",
                            },
                          ],
                          ref: "descricao",
                          staticClass: "form-control",
                          attrs: {
                            autocomplete: "off",
                            type: "text",
                            id: "descricao",
                            name: "descricao",
                            disabled: _vm.somenteConsulta,
                          },
                          domProps: { value: _vm.model.descricao },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.model,
                                "descricao",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-1" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("ativo"),
                            expression: "errors.first('ativo')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "ativo" },
                          },
                          [
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t("__.Crud.produto.edit_vue_html.ativo")
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("checkbox-component", {
                          attrs: {
                            id: "ativo",
                            name: "ativo",
                            disabled: _vm.somenteConsulta,
                          },
                          model: {
                            value: _vm.model.ativo,
                            callback: function ($$v) {
                              _vm.$set(_vm.model, "ativo", $$v)
                            },
                            expression: "model.ativo",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    {
                      class:
                        "col-sm-" +
                        [
                          _vm.model.tipoProduto == 0 ||
                          _vm.model.tipoProduto == 3 ||
                          _vm.model.tipoProduto == 4
                            ? 3
                            : 6,
                        ],
                    },
                    [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: _vm.errors.first("grupoProdutoId"),
                              expression: "errors.first('grupoProdutoId')",
                            },
                          ],
                          staticClass: "form-group",
                        },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "control-label",
                              attrs: {
                                for: "grupoProduto",
                                title: _vm.$t("__.obrigatorio"),
                              },
                            },
                            [
                              _c("span", { staticStyle: { color: "red" } }, [
                                _vm._v("*"),
                              ]),
                              _vm._v(
                                "\n                                " +
                                  _vm._s(
                                    _vm.$t(
                                      "__.Crud.produto.edit_vue_html.grupAtivo"
                                    )
                                  ) +
                                  "\n                            "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("combo-component", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required",
                                expression: "'required'",
                              },
                            ],
                            ref: "grupoProdutoId",
                            attrs: {
                              id: "grupoProdutoId",
                              name: "grupoProdutoId",
                              data: _vm.grupoProdutoOptions,
                              refresh: _vm.loadGruposProduto,
                              disabled: _vm.somenteConsulta,
                            },
                            model: {
                              value: _vm.model.grupoProdutoId,
                              callback: function ($$v) {
                                _vm.$set(_vm.model, "grupoProdutoId", $$v)
                              },
                              expression: "model.grupoProdutoId",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            _vm.model.tipoProduto == 0 ||
                            _vm.model.tipoProduto == 3,
                          expression:
                            "model.tipoProduto == 0 || model.tipoProduto == 3",
                        },
                      ],
                      staticClass: "col-sm-3",
                    },
                    [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: _vm.errors.first("subGrupoProduto"),
                              expression: "errors.first('subGrupoProduto')",
                            },
                          ],
                          staticClass: "form-group",
                        },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "control-label",
                              attrs: {
                                for: "subGrupoProduto",
                                title: _vm.$t("__.obrigatorio"),
                              },
                            },
                            [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(
                                    _vm.$t(
                                      "__.Crud.produto.edit_vue_html.subgrupoProd"
                                    )
                                  ) +
                                  "\n                            "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("combo-component", {
                            attrs: {
                              id: "subGrupoProduto",
                              name: "subGrupoProduto",
                              data: _vm.subGrupoOptions,
                              disabled: _vm.somenteConsulta,
                            },
                            model: {
                              value: _vm.model.subGrupo,
                              callback: function ($$v) {
                                _vm.$set(_vm.model, "subGrupo", $$v)
                              },
                              expression: "model.subGrupo",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.model.tipoProduto == 4,
                          expression: "model.tipoProduto == 4",
                        },
                      ],
                      class: "col-sm-" + [_vm.model.subGrupo == 7 ? 2 : 3],
                    },
                    [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: _vm.errors.first("subGrupoProduto"),
                              expression: "errors.first('subGrupoProduto')",
                            },
                          ],
                          staticClass: "form-group",
                        },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "control-label",
                              attrs: {
                                for: "subGrupoProduto",
                                title: _vm.$t("__.obrigatorio"),
                              },
                            },
                            [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(
                                    _vm.$t(
                                      "__.Crud.produto.edit_vue_html.subgrupoProd"
                                    )
                                  ) +
                                  "\n                            "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("combo-component", {
                            attrs: {
                              id: "subGrupoProduto",
                              name: "subGrupoProduto",
                              data: _vm.subGrupoOptions,
                              disabled: _vm.somenteConsulta,
                            },
                            model: {
                              value: _vm.model.subGrupo,
                              callback: function ($$v) {
                                _vm.$set(_vm.model, "subGrupo", $$v)
                              },
                              expression: "model.subGrupo",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _vm.model.subGrupo == 7
                    ? _c("div", { staticClass: "col-sm-2" }, [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: _vm.errors.first("cor"),
                                expression: "errors.first('cor')",
                              },
                            ],
                            staticClass: "form-group",
                          },
                          [
                            _c(
                              "label",
                              {
                                staticClass: "control-label",
                                attrs: {
                                  for: "cor",
                                  title: _vm.$t("__.obrigatorio"),
                                },
                              },
                              [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(
                                      _vm.$t(
                                        "__.Crud.produto.edit_vue_html.cores"
                                      )
                                    ) +
                                    "\n                            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("combo-component", {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "required",
                                  expression: "'required'",
                                },
                              ],
                              attrs: {
                                id: "cor",
                                name: "cor",
                                data: _vm.coresOptions,
                                searchable: true,
                                disabled: _vm.somenteConsulta,
                              },
                              model: {
                                value: _vm.model.corId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.model, "corId", $$v)
                                },
                                expression: "model.corId",
                              },
                            }),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    { class: "col-sm-" + [_vm.model.subGrupo == 7 ? 3 : 4] },
                    [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: _vm.errors.first("codigoBarra"),
                              expression: "errors.first('codigoBarra')",
                            },
                          ],
                          staticClass: "form-group",
                        },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "control-label",
                              attrs: { for: "codigoBarra" },
                            },
                            [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(
                                    _vm.$t(
                                      "__.Crud.produto.edit_vue_html.codBarras"
                                    )
                                  ) +
                                  "\n                            "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.model.codigoBarra,
                                expression: "model.codigoBarra",
                              },
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "max:256",
                                expression: "'max:256'",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: {
                              autocomplete: "off",
                              type: "text",
                              id: "codigoBarra",
                              name: "codigoBarra",
                              disabled: _vm.somenteConsulta,
                            },
                            domProps: { value: _vm.model.codigoBarra },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.model,
                                  "codigoBarra",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-2" }, [
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "curvaABC" },
                          },
                          [
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Crud.produto.edit_vue_html.classProduto"
                                  )
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("combo-component", {
                          attrs: {
                            id: "curvaABC",
                            name: "curvaABC",
                            data: _vm.curvaABCOptions,
                            searchable: true,
                            disabled: "",
                          },
                          model: {
                            value: _vm.model.curvaABC,
                            callback: function ($$v) {
                              _vm.$set(_vm.model, "curvaABC", $$v)
                            },
                            expression: "model.curvaABC",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-sm-3" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("tipoCalculo"),
                            expression: "errors.first('tipoCalculo')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "tipoCalculo" },
                          },
                          [
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Crud.produto.edit_vue_html.tipoCalc"
                                  )
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("combo-component", {
                          ref: "tipoCalculo",
                          attrs: {
                            id: "tipoCalculo",
                            name: "tipoCalculo",
                            data: _vm.tiposCalculoOptions,
                            disabled: _vm.somenteConsulta,
                          },
                          model: {
                            value: _vm.model.tipoCalculo,
                            callback: function ($$v) {
                              _vm.$set(_vm.model, "tipoCalculo", $$v)
                            },
                            expression: "model.tipoCalculo",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-3" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first(
                              "unidadeMedidaManipulacaoId"
                            ),
                            expression:
                              "errors.first('unidadeMedidaManipulacaoId')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: {
                              for: "unidadeMedidaManipulacaoId",
                              title: _vm.$t("__.obrigatorio"),
                            },
                          },
                          [
                            _c("span", { staticStyle: { color: "red" } }, [
                              _vm._v("*"),
                            ]),
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Crud.produto.edit_vue_html.unMedManipu"
                                  )
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("combo-component", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required",
                              expression: "'required'",
                            },
                          ],
                          ref: "unidadeMedidaManipulacaoId",
                          attrs: {
                            id: "unidadeMedidaManipulacaoId",
                            name: "unidadeMedidaManipulacaoId",
                            data: _vm.unidadesMedidaOptions,
                            refresh: _vm.loadUnidadesMedida,
                            disabled: _vm.somenteConsulta,
                          },
                          model: {
                            value: _vm.model.unidadeMedidaManipulacaoId,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.model,
                                "unidadeMedidaManipulacaoId",
                                $$v
                              )
                            },
                            expression: "model.unidadeMedidaManipulacaoId",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-3" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("unidadeMedidaEstoqueId"),
                            expression:
                              "errors.first('unidadeMedidaEstoqueId')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: {
                              for: "unidadeMedidaEstoqueId",
                              title: _vm.$t("__.obrigatorio"),
                            },
                          },
                          [
                            _c("span", { staticStyle: { color: "red" } }, [
                              _vm._v("*"),
                            ]),
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Crud.produto.edit_vue_html.unMedEstq"
                                  )
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("combo-component", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required",
                              expression: "'required'",
                            },
                          ],
                          ref: "unidadeMedidaEstoqueId",
                          attrs: {
                            id: "unidadeMedidaEstoqueId",
                            name: "unidadeMedidaEstoqueId",
                            data: _vm.unidadesMedidaOptions.filter(function (
                              p
                            ) {
                              return p["type"] < 3
                            }),
                            refresh: _vm.loadUnidadesMedida,
                            disabled:
                              _vm.somenteConsulta ||
                              (_vm.modelId > 0 && _vm.gridDataLote.length > 0),
                          },
                          model: {
                            value: _vm.model.unidadeMedidaEstoqueId,
                            callback: function ($$v) {
                              _vm.$set(_vm.model, "unidadeMedidaEstoqueId", $$v)
                            },
                            expression: "model.unidadeMedidaEstoqueId",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-3" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("ignoraControleEstoque"),
                            expression: "errors.first('ignoraControleEstoque')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "ignoraControleEstoque" },
                          },
                          [
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Crud.produto.edit_vue_html.naoControlEstq"
                                  )
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("checkbox-component", {
                          attrs: {
                            id: "ignoraControleEstoque",
                            name: "ignoraControleEstoque",
                            disabled: _vm.somenteConsulta,
                          },
                          model: {
                            value: _vm.model.ignoraControleEstoque,
                            callback: function ($$v) {
                              _vm.$set(_vm.model, "ignoraControleEstoque", $$v)
                            },
                            expression: "model.ignoraControleEstoque",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-sm-3" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("estoqueMinimo"),
                            expression: "errors.first('estoqueMinimo')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: {
                              for: "estoqueMinimo",
                              title: _vm.$t("__.obrigatorio"),
                            },
                          },
                          [
                            _c("span", { staticStyle: { color: "red" } }, [
                              _vm._v("*"),
                            ]),
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Crud.produto.edit_vue_html.estqMin"
                                  )
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.model.estoqueMinimo,
                              expression: "model.estoqueMinimo",
                            },
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required|maior_igual_a_zero",
                              expression: "'required|maior_igual_a_zero'",
                            },
                          ],
                          ref: "estoqueMinimo",
                          staticClass: "form-control",
                          attrs: {
                            autocomplete: "off",
                            type: "text",
                            id: "estoqueMinimo",
                            name: "estoqueMinimo",
                            disabled: _vm.somenteConsulta,
                          },
                          domProps: { value: _vm.model.estoqueMinimo },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.model,
                                "estoqueMinimo",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-3" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("estoqueMaximo"),
                            expression: "errors.first('estoqueMaximo')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "estoqueMaximo" },
                          },
                          [
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Crud.produto.edit_vue_html.estqMax"
                                  )
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.model.estoqueMaximo,
                              expression: "model.estoqueMaximo",
                            },
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "maior_que_zero",
                              expression: "'maior_que_zero'",
                            },
                          ],
                          ref: "estoqueMaximo",
                          staticClass: "form-control",
                          attrs: {
                            autocomplete: "off",
                            type: "text",
                            id: "estoqueMaximo",
                            name: "estoqueMaximo",
                            disabled: _vm.somenteConsulta,
                          },
                          domProps: { value: _vm.model.estoqueMaximo },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.model,
                                "estoqueMaximo",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-2" }, [
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "farmacopeiaId" },
                          },
                          [
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Components.parent.navmenu.farmacopeia"
                                  )
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("combo-component", {
                          attrs: {
                            id: "farmacopeiaId",
                            name: "farmacopeiaId",
                            data: _vm.farmacopeiaOptions,
                            searchable: true,
                            refresh: _vm.loadFarmacopeias,
                            disabled: _vm.somenteConsulta,
                          },
                          model: {
                            value: _vm.model.farmacopeiaPadraoId,
                            callback: function ($$v) {
                              _vm.$set(_vm.model, "farmacopeiaPadraoId", $$v)
                            },
                            expression: "model.farmacopeiaPadraoId",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-4" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("posologiaId"),
                            expression: "errors.first('posologiaId')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "posologiaId" },
                          },
                          [
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Crud.produto.edit_vue_html.posologia"
                                  )
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "combo-component",
                          {
                            attrs: {
                              id: "posologiaId",
                              name: "posologiaId",
                              actionName: "posologias",
                              addEditId: _vm.addNewId,
                              addNew: !_vm.addNewId,
                              customView: true,
                              disabled: _vm.somenteConsulta,
                              data: _vm.posologiaOptions,
                              formAddNewName:
                                _vm.addNewId > 0
                                  ? _vm.$t(
                                      "__.Crud.produto.edit_vue_html.editPosologia"
                                    )
                                  : _vm.$t(
                                      "__.Crud.produto.edit_vue_html.novaPosologia"
                                    ),
                              modalClass: "lm-modal",
                              refresh: _vm.loadPosologia,
                              useCloseModalCallback: true,
                            },
                            on: {
                              addNewItemCombo: _vm.onAddNewPosologia,
                              openComboEditItem: _vm.openComboEditPosologia,
                              openComboNewItem: _vm.openComboNewPosologia,
                            },
                            model: {
                              value: _vm.model.posologiaId,
                              callback: function ($$v) {
                                _vm.$set(_vm.model, "posologiaId", $$v)
                              },
                              expression: "model.posologiaId",
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                attrs: { slot: "component" },
                                slot: "component",
                              },
                              [
                                _c("posologia-component", {
                                  ref: "posologiaComponent",
                                  attrs: { CalledByShortCut: true },
                                  on: { "save-ok": _vm.onPosologiaSaveOk },
                                }),
                              ],
                              1
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "fieldset-component",
            {
              attrs: {
                title: _vm.$t("__.Crud.produto.edit_vue_html.observacoes"),
              },
            },
            [
              _c(
                "div",
                { attrs: { slot: "rows" }, slot: "rows" },
                [
                  !_vm.somenteConsulta
                    ? _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-sm-3" }, [
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "tooltip",
                                  rawName: "v-tooltip",
                                  value: _vm.errors.first("observacaoTipo"),
                                  expression: "errors.first('observacaoTipo')",
                                },
                              ],
                              staticClass: "form-group",
                            },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "control-label",
                                  attrs: { for: "observacaoTipo" },
                                },
                                [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(
                                        _vm.$t(
                                          "__.Crud.produto.edit_vue_html.tipoObs"
                                        )
                                      ) +
                                      "\n                            "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("combo-component", {
                                attrs: {
                                  id: "observacaoTipo",
                                  name: "observacaoTipo",
                                  data: _vm.observacaoTipoOptions,
                                },
                                model: {
                                  value: _vm.observacaoTipo,
                                  callback: function ($$v) {
                                    _vm.observacaoTipo = $$v
                                  },
                                  expression: "observacaoTipo",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-sm-9" }, [
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "tooltip",
                                  rawName: "v-tooltip",
                                  value: _vm.errors.first("observacaoTexto"),
                                  expression: "errors.first('observacaoTexto')",
                                },
                              ],
                              staticClass: "form-group",
                            },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "control-label",
                                  attrs: { for: "observacaoTexto" },
                                },
                                [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(
                                        _vm.$t(
                                          "__.Crud.produto.edit_vue_html.obs"
                                        )
                                      ) +
                                      "\n                            "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("textarea-component", {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: "max:256",
                                    expression: "'max:256'",
                                  },
                                ],
                                staticClass: "form-control",
                                attrs: {
                                  id: "observacaoTexto",
                                  name: "observacaoTexto",
                                },
                                model: {
                                  value: _vm.observacaoTexto,
                                  callback: function ($$v) {
                                    _vm.observacaoTexto = $$v
                                  },
                                  expression: "observacaoTexto",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.somenteConsulta
                    ? _c("buttonIncluirComponent", {
                        on: { click: _vm.onInclusaoProdutoObservacao },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("grid-component", {
                    attrs: {
                      data: _vm.model.produtoObservacoes,
                      columns: _vm.gridColumnsObservacoes,
                      "show-table-head": false,
                      "show-table-foot": false,
                      "show-edit-item": false,
                      showActionColumn: !_vm.somenteConsulta,
                    },
                    on: { "remove-item": _vm.onRemoveProdutoObservacoes },
                  }),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _vm.showFinanceiro
            ? _c(
                "fieldset-component",
                {
                  attrs: {
                    title: _vm.$t("__.Crud.produto.edit_vue_html.financeiro"),
                  },
                },
                [
                  _c(
                    "div",
                    { attrs: { slot: "rows" }, slot: "rows" },
                    [
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-sm-2" }, [
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "tooltip",
                                  rawName: "v-tooltip",
                                  value: _vm.errors.first("valorCusto"),
                                  expression: "errors.first('valorCusto')",
                                },
                              ],
                              staticClass: "form-group",
                            },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "control-label",
                                  attrs: { for: "valorCusto" },
                                },
                                [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(
                                        _vm.$t(
                                          "__.Crud.produto.edit_vue_html.vlrCusto"
                                        )
                                      ) +
                                      "\n                            "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("moeda-component", {
                                directives: [
                                  {
                                    name: "mask-moeda-decimal",
                                    rawName: "v-mask-moeda-decimal",
                                  },
                                ],
                                staticClass: "form-control",
                                attrs: {
                                  id: "valorCusto",
                                  name: "valorCusto",
                                  disabled: _vm.somenteConsulta,
                                },
                                model: {
                                  value: _vm.model.valorCusto,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.model, "valorCusto", $$v)
                                  },
                                  expression: "model.valorCusto",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-sm-2" }, [
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "tooltip",
                                  rawName: "v-tooltip",
                                  value: _vm.errors.first("custoReferencia"),
                                  expression: "errors.first('custoReferencia')",
                                },
                              ],
                              staticClass: "form-group",
                            },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "control-label",
                                  attrs: { for: "custoReferencia" },
                                },
                                [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(
                                        _vm.$t(
                                          "__.Crud.produto.edit_vue_html.custoRefe"
                                        )
                                      ) +
                                      "\n                            "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("moeda-component", {
                                directives: [
                                  {
                                    name: "mask-moeda-decimal",
                                    rawName: "v-mask-moeda-decimal",
                                  },
                                ],
                                staticClass: "form-control",
                                attrs: {
                                  id: "custoReferencia",
                                  name: "custoReferencia",
                                  disabled: _vm.somenteConsulta,
                                },
                                on: { change: _vm.calcularValorVenda },
                                model: {
                                  value: _vm.model.custoReferencia,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.model, "custoReferencia", $$v)
                                  },
                                  expression: "model.custoReferencia",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-sm-2" }, [
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "tooltip",
                                  rawName: "v-tooltip",
                                  value: _vm.errors.first("custoMedio"),
                                  expression: "errors.first('custoMedio')",
                                },
                              ],
                              staticClass: "form-group",
                            },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "control-label",
                                  attrs: { for: "custoMedio" },
                                },
                                [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(
                                        _vm.$t(
                                          "__.Crud.produto.edit_vue_html.custoMed"
                                        )
                                      ) +
                                      "\n                            "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("moeda-component", {
                                directives: [
                                  {
                                    name: "mask-moeda-decimal",
                                    rawName: "v-mask-moeda-decimal",
                                  },
                                ],
                                staticClass: "form-control",
                                attrs: {
                                  id: "custoMedio",
                                  name: "custoMedio",
                                  disabled: _vm.somenteConsulta,
                                },
                                model: {
                                  value: _vm.model.custoMedio,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.model, "custoMedio", $$v)
                                  },
                                  expression: "model.custoMedio",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-sm-1" }, [
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "control-label",
                                  attrs: { for: "markupPadrao" },
                                },
                                [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(
                                        _vm.$t(
                                          "__.Crud.produto.edit_vue_html.markupPadrao"
                                        )
                                      ) +
                                      "\n                            "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("data-tooltip-component", {
                                attrs: {
                                  text: _vm.$t(
                                    "__.Crud.produto.edit_vue_html.markupPadraoToolTip"
                                  ),
                                },
                              }),
                              _vm._v(" "),
                              _c("checkbox-component", {
                                attrs: {
                                  id: "markupPadrao",
                                  name: "markupPadrao",
                                },
                                on: { change: _vm.onMarkupPadraoChange },
                                model: {
                                  value: _vm.isMarkupNull,
                                  callback: function ($$v) {
                                    _vm.isMarkupNull = $$v
                                  },
                                  expression: "isMarkupNull",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-sm-3" }, [
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "tooltip",
                                  rawName: "v-tooltip",
                                  value: _vm.errors.first("markup"),
                                  expression: "errors.first('markup')",
                                },
                              ],
                              staticClass: "form-group",
                            },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "control-label",
                                  attrs: { for: "markup" },
                                },
                                [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(
                                        _vm.$t(
                                          "__.Crud.produto.edit_vue_html.markupPorcent"
                                        )
                                      ) +
                                      "\n                            "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("decimal-com-sufixo-nullable-component", {
                                ref: "markup",
                                staticClass: "form-control",
                                attrs: {
                                  id: "markup",
                                  name: "markup",
                                  suffix: "%",
                                  title: _vm.$t(
                                    "__.Crud.produto.edit_vue_html.markup"
                                  ),
                                  disabled:
                                    _vm.somenteConsulta ||
                                    _vm.model.markup === null,
                                },
                                on: { change: _vm.onMarkupChanged },
                                model: {
                                  value: _vm.model.markup,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.model, "markup", $$v)
                                  },
                                  expression: "model.markup",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-sm-2" }, [
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "tooltip",
                                  rawName: "v-tooltip",
                                  value: _vm.errors.first("valorVenda"),
                                  expression: "errors.first('valorVenda')",
                                },
                              ],
                              staticClass: "form-group",
                            },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "control-label",
                                  attrs: { for: "valorVenda" },
                                },
                                [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(
                                        _vm.$t(
                                          "__.Crud.produto.edit_vue_html.vlrVenda"
                                        )
                                      ) +
                                      "\n                            "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("moeda-component", {
                                directives: [
                                  {
                                    name: "mask-moeda-decimal",
                                    rawName: "v-mask-moeda-decimal",
                                  },
                                ],
                                staticClass: "form-control",
                                attrs: {
                                  id: "valorVenda",
                                  name: "valorVenda",
                                  disabled: _vm.somenteConsulta,
                                },
                                on: { change: _vm.calculaMarkupByValorVenda },
                                model: {
                                  value: _vm.model.valorVenda,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.model, "valorVenda", $$v)
                                  },
                                  expression: "model.valorVenda",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-sm-6" }, [
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "tooltip",
                                  rawName: "v-tooltip",
                                  value: _vm.errors.first("nbmId"),
                                  expression: "errors.first('nbmId')",
                                },
                              ],
                              staticClass: "form-group",
                            },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "control-label",
                                  attrs: { for: "nbmId" },
                                },
                                [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(
                                        _vm.$t(
                                          "__.Crud.produto.edit_vue_html.nbmSH"
                                        )
                                      ) +
                                      "\n                            "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("combo-component", {
                                attrs: {
                                  id: "nbmId",
                                  name: "nbmId",
                                  data: _vm.nbmsOptions,
                                  searchable: true,
                                  refresh: _vm.loadNbms,
                                  disabled: _vm.somenteConsulta,
                                },
                                model: {
                                  value: _vm.model.nbmId,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.model, "nbmId", $$v)
                                  },
                                  expression: "model.nbmId",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-sm-6" }, [
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "tooltip",
                                  rawName: "v-tooltip",
                                  value: _vm.errors.first("ncmId"),
                                  expression: "errors.first('ncmId')",
                                },
                              ],
                              staticClass: "form-group",
                            },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "control-label",
                                  attrs: { for: "ncmId" },
                                },
                                [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(
                                        _vm.$t(
                                          "__.Crud.produto.edit_vue_html.ncmSH"
                                        )
                                      ) +
                                      "\n                            "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("search-combo-component", {
                                attrs: {
                                  id: "ncmId",
                                  name: "ncmId",
                                  customView: false,
                                  disabled: _vm.somenteConsulta,
                                  advancedSearch: true,
                                  service: "Ncm",
                                  propDescription: "descricao",
                                  customComboText: _vm.customComboTextNCM,
                                  advancedSearchCampos: [
                                    { campo: "codigo", descricao: "codigo" },
                                    { campo: "text", descricao: "descricao" },
                                  ],
                                },
                                model: {
                                  value: _vm.model.ncmId,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.model, "ncmId", $$v)
                                  },
                                  expression: "model.ncmId",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-sm-6" }, [
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "tooltip",
                                  rawName: "v-tooltip",
                                  value: _vm.errors.first("cestId"),
                                  expression: "errors.first('cestId')",
                                },
                              ],
                              staticClass: "form-group",
                            },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "control-label",
                                  attrs: { for: "cestId" },
                                },
                                [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(
                                        _vm.$t(
                                          "__.Crud.produto.edit_vue_html.cest"
                                        )
                                      ) +
                                      "\n                            "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("combo-component", {
                                attrs: {
                                  id: "cestId",
                                  name: "cestId",
                                  data: _vm.cestsOptions,
                                  searchable: true,
                                  refresh: _vm.loadCests,
                                  disabled: _vm.somenteConsulta,
                                },
                                model: {
                                  value: _vm.model.cestId,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.model, "cestId", $$v)
                                  },
                                  expression: "model.cestId",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _vm._v(" "),
                        _vm.usuarioLogado.franquia.tipoFranquia == 3
                          ? _c("div", { staticClass: "col-sm-6" }, [
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "tooltip",
                                      rawName: "v-tooltip",
                                      value:
                                        _vm.errors.first("usaMarkupDinamico"),
                                      expression:
                                        "errors.first('usaMarkupDinamico')",
                                    },
                                  ],
                                  staticClass: "form-group",
                                },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "control-label",
                                      attrs: { for: "usaMarkupDinamico" },
                                    },
                                    [
                                      _vm._v(
                                        "\n                                " +
                                          _vm._s(
                                            _vm.$t(
                                              "__.Crud.produto.edit_vue_html.usaMarkupDinamico"
                                            )
                                          ) +
                                          "\n                            "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("checkbox-component", {
                                    attrs: {
                                      id: "usaMarkupDinamico",
                                      name: "usaMarkupDinamico",
                                      disabled: _vm.somenteConsulta,
                                    },
                                    model: {
                                      value: _vm.usaMarkupDinamico,
                                      callback: function ($$v) {
                                        _vm.usaMarkupDinamico = $$v
                                      },
                                      expression: "usaMarkupDinamico",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ])
                          : _vm._e(),
                      ]),
                      _vm._v(" "),
                      _vm.usaMarkupDinamico
                        ? _c(
                            "fieldset-component",
                            {
                              attrs: {
                                title: _vm.$t(
                                  "__.Crud.produto.edit_vue_html.markupDinamico"
                                ),
                                collapsed: false,
                              },
                            },
                            [
                              _c(
                                "div",
                                { attrs: { slot: "rows" }, slot: "rows" },
                                _vm._l(
                                  _vm.model.produtoMarkupDinamico,
                                  function (markupDinamico, indexMarkup) {
                                    return _c(
                                      "div",
                                      {
                                        key: indexMarkup,
                                        staticClass:
                                          "row list-inputs-container",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "col-sm-4 list-inputs-column",
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "form-group" },
                                              [
                                                indexMarkup == 0
                                                  ? _c(
                                                      "label",
                                                      {
                                                        staticClass:
                                                          "control-label",
                                                        attrs: {
                                                          for:
                                                            "quantidadeLimite" +
                                                            indexMarkup,
                                                          title:
                                                            _vm.$t(
                                                              "__.obrigatorio"
                                                            ),
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticStyle: {
                                                              color: "red",
                                                            },
                                                          },
                                                          [_vm._v("*")]
                                                        ),
                                                        _vm._v(
                                                          "\n                                        " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "__.Crud.produto.edit_vue_html.quantidadeLimite"
                                                              )
                                                            ) +
                                                            "\n                                    "
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                indexMarkup == 0
                                                  ? _c("input", {
                                                      staticClass:
                                                        "form-control",
                                                      attrs: {
                                                        id:
                                                          "quantidadeLimite" +
                                                          indexMarkup,
                                                        name:
                                                          "quantidadeLimite" +
                                                          indexMarkup,
                                                        value: "Demais valores",
                                                        disabled: "",
                                                      },
                                                    })
                                                  : _c(
                                                      "decimal-com-sufixo-nullable-component",
                                                      {
                                                        ref:
                                                          "quantidadeLimite" +
                                                          indexMarkup,
                                                        refInFor: true,
                                                        staticClass:
                                                          "form-control",
                                                        attrs: {
                                                          id:
                                                            "quantidadeLimite" +
                                                            indexMarkup,
                                                          name:
                                                            "quantidadeLimite" +
                                                            indexMarkup,
                                                          prefix: "até",
                                                          suffix:
                                                            _vm.siglaEstoqueDescricao,
                                                          disabled:
                                                            _vm.somenteConsulta,
                                                        },
                                                        model: {
                                                          value:
                                                            markupDinamico.quantidadeLimite,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              markupDinamico,
                                                              "quantidadeLimite",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "markupDinamico.quantidadeLimite",
                                                        },
                                                      }
                                                    ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "col-sm-4 list-inputs-column",
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "form-group" },
                                              [
                                                indexMarkup == 0
                                                  ? _c(
                                                      "label",
                                                      {
                                                        staticClass:
                                                          "control-label",
                                                        attrs: {
                                                          for:
                                                            "markupDinamico" +
                                                            indexMarkup,
                                                          title:
                                                            _vm.$t(
                                                              "__.obrigatorio"
                                                            ),
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticStyle: {
                                                              color: "red",
                                                            },
                                                          },
                                                          [_vm._v("*")]
                                                        ),
                                                        _vm._v(
                                                          "\n                                        " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "__.Crud.produto.edit_vue_html.markupDinamico"
                                                              )
                                                            ) +
                                                            "\n                                    "
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                _c(
                                                  "decimal-com-sufixo-nullable-component",
                                                  {
                                                    ref:
                                                      "markupDinamico" +
                                                      indexMarkup,
                                                    refInFor: true,
                                                    staticClass: "form-control",
                                                    attrs: {
                                                      id:
                                                        "markupDinamico" +
                                                        indexMarkup,
                                                      name:
                                                        "markupDinamico" +
                                                        indexMarkup,
                                                      suffix: "%",
                                                      disabled:
                                                        _vm.somenteConsulta,
                                                    },
                                                    model: {
                                                      value:
                                                        markupDinamico.markup,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          markupDinamico,
                                                          "markup",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "markupDinamico.markup",
                                                    },
                                                  }
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        !_vm.somenteConsulta
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "col-sm-1 list-inputs-column",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "form-group" },
                                                  [
                                                    indexMarkup == 0
                                                      ? _c(
                                                          "label",
                                                          {
                                                            staticClass:
                                                              "control-label",
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                                        " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "__.Crud.listInputs.acao"
                                                                  )
                                                                ) +
                                                                "\n                                    "
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                    _vm._v(" "),
                                                    _c("span", [
                                                      indexMarkup > 0
                                                        ? _c(
                                                            "a",
                                                            {
                                                              attrs: {
                                                                title: _vm.$t(
                                                                  "__.Crud.listInputs.remover"
                                                                ),
                                                                href: "#",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    $event.preventDefault()
                                                                    _vm.onRemoveMarkupDinamico(
                                                                      markupDinamico
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _c("i", {
                                                                staticClass:
                                                                  "fa fa-times button-remove",
                                                                attrs: {
                                                                  "aria-hidden":
                                                                    "true",
                                                                },
                                                              }),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      _vm._v(" "),
                                                      indexMarkup ==
                                                      _vm.model
                                                        .produtoMarkupDinamico
                                                        .length -
                                                        1
                                                        ? _c(
                                                            "a",
                                                            {
                                                              attrs: {
                                                                title: _vm.$t(
                                                                  "__.Crud.listInputs.adicionar"
                                                                ),
                                                                href: "#",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    $event.preventDefault()
                                                                    return _vm.onInclusaoMarkupDinamico(
                                                                      $event
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _c("i", {
                                                                staticClass:
                                                                  "fa fa-plus button-plus",
                                                                attrs: {
                                                                  "aria-hidden":
                                                                    "true",
                                                                },
                                                              }),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                    ]),
                                                  ]
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    )
                                  }
                                )
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.isModal
                        ? _c("fieldset", [
                            _c("div", { staticClass: "row" }, [
                              _c("div", { staticClass: "col-sm-12 newbtn" }, [
                                _c("div", { staticClass: "form-group" }, [
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn btn-primary",
                                      attrs: { type: "button" },
                                      on: {
                                        click: function ($event) {
                                          _vm.verLogAlteracao().withLoading()
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                                    " +
                                          _vm._s(
                                            _vm.$t(
                                              "__.Crud.produto.edit_vue_html.visuLogVlrs"
                                            )
                                          ) +
                                          "\n                                "
                                      ),
                                    ]
                                  ),
                                ]),
                              ]),
                            ]),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "fieldset-component",
            {
              attrs: { title: _vm.$t("__.Crud.produto.edit_vue_html.manipu") },
            },
            [
              _c("div", { attrs: { slot: "rows" }, slot: "rows" }, [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-sm-9" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("descricaoRotulo"),
                            expression: "errors.first('descricaoRotulo')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "descricaoRotulo" },
                          },
                          [
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Crud.produto.edit_vue_html.descrRotulo"
                                  )
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.model.descricaoRotulo,
                              expression: "model.descricaoRotulo",
                            },
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "max:256",
                              expression: "'max:256'",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            autocomplete: "off",
                            type: "text",
                            id: "descricaoRotulo",
                            name: "descricaoRotulo",
                            disabled: _vm.somenteConsulta,
                          },
                          domProps: { value: _vm.model.descricaoRotulo },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.model,
                                "descricaoRotulo",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-3" }, [
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "corManipulacao" },
                          },
                          [
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Crud.produto.edit_vue_html.corManipulacao"
                                  )
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("combo-component", {
                          attrs: {
                            id: "corManipulacao",
                            name: "corManipulacao",
                            data: _vm.corManipulacaoOptions,
                            customView: true,
                            searchable: true,
                            disabled: _vm.somenteConsulta,
                          },
                          model: {
                            value: _vm.model.corManipulacao,
                            callback: function ($$v) {
                              _vm.$set(_vm.model, "corManipulacao", $$v)
                            },
                            expression: "model.corManipulacao",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-sm-4" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("densidade"),
                            expression: "errors.first('densidade')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "densidade" },
                          },
                          [
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Crud.produto.edit_vue_html.densidadeGml"
                                  )
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("decimal-component", {
                          staticClass: "form-control",
                          attrs: {
                            id: "densidade",
                            name: "densidade",
                            disabled: _vm.somenteConsulta,
                          },
                          model: {
                            value: _vm.model.densidade,
                            callback: function ($$v) {
                              _vm.$set(_vm.model, "densidade", $$v)
                            },
                            expression: "model.densidade",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-4" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("diluicao"),
                            expression: "errors.first('diluicao')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "diluicao" },
                          },
                          [
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Crud.produto.edit_vue_html.diluicao"
                                  )
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("decimal-component", {
                          staticClass: "form-control",
                          attrs: {
                            id: "diluicao",
                            name: "diluicao",
                            disabled: _vm.somenteConsulta,
                          },
                          model: {
                            value: _vm.model.diluicao,
                            callback: function ($$v) {
                              _vm.$set(_vm.model, "diluicao", $$v)
                            },
                            expression: "model.diluicao",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-2" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("margemSeguranca"),
                            expression: "errors.first('margemSeguranca')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "margemSeguranca" },
                          },
                          [
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Crud.produto.edit_vue_html.margemSegur"
                                  )
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("decimal-component", {
                          staticClass: "form-control",
                          attrs: {
                            id: "margemSeguranca",
                            name: "margemSeguranca",
                            disabled: _vm.somenteConsulta,
                          },
                          model: {
                            value: _vm.model.margemSeguranca,
                            callback: function ($$v) {
                              _vm.$set(_vm.model, "margemSeguranca", $$v)
                            },
                            expression: "model.margemSeguranca",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-2" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("fitoterapico"),
                            expression: "errors.first('fitoterapico')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "fitoterapico" },
                          },
                          [
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Crud.produto.edit_vue_html.fitoterapico"
                                  )
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("checkbox-component", {
                          attrs: {
                            id: "fitoterapico",
                            name: "fitoterapico",
                            disabled: _vm.somenteConsulta,
                          },
                          model: {
                            value: _vm.model.fitoterapico,
                            callback: function ($$v) {
                              _vm.$set(_vm.model, "fitoterapico", $$v)
                            },
                            expression: "model.fitoterapico",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-sm-3" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("fatorCorrecao"),
                            expression: "errors.first('fatorCorrecao')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "fatorCorrecao" },
                          },
                          [
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Crud.produto.edit_vue_html.ftrCorrecao"
                                  )
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("decimal-component", {
                          staticClass: "form-control",
                          attrs: {
                            id: "fatorCorrecao",
                            name: "fatorCorrecao",
                            disabled: _vm.somenteConsulta,
                          },
                          model: {
                            value: _vm.model.fatorCorrecao,
                            callback: function ($$v) {
                              _vm.$set(_vm.model, "fatorCorrecao", $$v)
                            },
                            expression: "model.fatorCorrecao",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-3" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("fatorEquivalencia"),
                            expression: "errors.first('fatorEquivalencia')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "fatorEquivalencia" },
                          },
                          [
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Crud.produto.edit_vue_html.ftrEquivalencia"
                                  )
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("decimal-component", {
                          staticClass: "form-control",
                          attrs: {
                            id: "fatorEquivalencia",
                            name: "fatorEquivalencia",
                          },
                          model: {
                            value: _vm.model.fatorEquivalencia,
                            callback: function ($$v) {
                              _vm.$set(_vm.model, "fatorEquivalencia", $$v)
                            },
                            expression: "model.fatorEquivalencia",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-2" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("fatorUI"),
                            expression: "errors.first('fatorUI')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "fatorUI" },
                          },
                          [
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t("__.Crud.produto.edit_vue_html.ftrUI")
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("decimal-component", {
                          staticClass: "form-control",
                          attrs: {
                            id: "fatorUI",
                            name: "fatorUI",
                            disabled: _vm.somenteConsulta,
                          },
                          model: {
                            value: _vm.model.fatorUI,
                            callback: function ($$v) {
                              _vm.$set(_vm.model, "fatorUI", $$v)
                            },
                            expression: "model.fatorUI",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-2" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("fatorUFC"),
                            expression: "errors.first('fatorUFC')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "fatorUFC" },
                          },
                          [
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t("__.Crud.produto.edit_vue_html.ftrUFC")
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("decimal-component", {
                          staticClass: "form-control",
                          attrs: {
                            id: "fatorUFC",
                            name: "fatorUFC",
                            disabled: _vm.somenteConsulta,
                          },
                          model: {
                            value: _vm.model.fatorUFC,
                            callback: function ($$v) {
                              _vm.$set(_vm.model, "fatorUFC", $$v)
                            },
                            expression: "model.fatorUFC",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-2" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("fatorUTR"),
                            expression: "errors.first('fatorUTR')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "fatorUTR" },
                          },
                          [
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t("__.Crud.produto.edit_vue_html.ftrUTR")
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("decimal-component", {
                          staticClass: "form-control",
                          attrs: {
                            id: "fatorUTR",
                            name: "fatorUTR",
                            disabled: _vm.somenteConsulta,
                          },
                          model: {
                            value: _vm.model.fatorUTR,
                            callback: function ($$v) {
                              _vm.$set(_vm.model, "fatorUTR", $$v)
                            },
                            expression: "model.fatorUTR",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _vm.model.tipoProduto == 0 && _vm.model.subGrupo == 0
                    ? _c("div", { staticClass: "col-sm-3" }, [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: _vm.errors.first("fatorCorrecaoMolde"),
                                expression:
                                  "errors.first('fatorCorrecaoMolde')",
                              },
                            ],
                            staticClass: "form-group",
                          },
                          [
                            _c(
                              "label",
                              {
                                staticClass: "control-label",
                                attrs: { for: "fatorCorrecaoMolde" },
                              },
                              [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(
                                      _vm.$t(
                                        "__.Crud.produto.edit_vue_html.ftrCorrecMolde"
                                      )
                                    ) +
                                    "\n                            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("decimal-component", {
                              staticClass: "form-control",
                              attrs: {
                                id: "fatorCorrecaoMolde",
                                name: "fatorCorrecaoMolde",
                                disabled: _vm.somenteConsulta,
                              },
                              model: {
                                value: _vm.model.fatorCorrecaoMolde,
                                callback: function ($$v) {
                                  _vm.$set(_vm.model, "fatorCorrecaoMolde", $$v)
                                },
                                expression: "model.fatorCorrecaoMolde",
                              },
                            }),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      class:
                        "col-sm-" +
                        [
                          _vm.model.tipoProduto == 0 && _vm.model.subGrupo == 0
                            ? "3"
                            : "4",
                        ],
                    },
                    [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: _vm.errors.first("doseMaxima"),
                              expression: "errors.first('doseMaxima')",
                            },
                          ],
                          staticClass: "form-group",
                        },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "control-label",
                              attrs: { for: "doseMaxima" },
                            },
                            [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(
                                    _vm.$t(
                                      "__.Crud.produto.edit_vue_html.doseMax"
                                    )
                                  ) +
                                  "\n                            "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("decimal-component", {
                            staticClass: "form-control",
                            attrs: {
                              id: "doseMaxima",
                              name: "doseMaxima",
                              disabled: _vm.somenteConsulta,
                            },
                            model: {
                              value: _vm.model.doseMaxima,
                              callback: function ($$v) {
                                _vm.$set(_vm.model, "doseMaxima", $$v)
                              },
                              expression: "model.doseMaxima",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      class:
                        "col-sm-" +
                        [
                          _vm.model.tipoProduto == 0 && _vm.model.subGrupo == 0
                            ? "3"
                            : "4",
                        ],
                    },
                    [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: _vm.errors.first("doseMaximaDiaria"),
                              expression: "errors.first('doseMaximaDiaria')",
                            },
                          ],
                          staticClass: "form-group",
                        },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "control-label",
                              attrs: { for: "doseMaximaDiaria" },
                            },
                            [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(
                                    _vm.$t(
                                      "__.Crud.produto.edit_vue_html.doseMaxDiaria"
                                    )
                                  ) +
                                  "\n                            "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("decimal-component", {
                            staticClass: "form-control",
                            attrs: {
                              id: "doseMaximaDiaria",
                              name: "doseMaximaDiaria",
                              disabled: _vm.somenteConsulta,
                            },
                            model: {
                              value: _vm.model.doseMaximaDiaria,
                              callback: function ($$v) {
                                _vm.$set(_vm.model, "doseMaximaDiaria", $$v)
                              },
                              expression: "model.doseMaximaDiaria",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      class:
                        "col-sm-" +
                        [
                          _vm.model.tipoProduto == 0 && _vm.model.subGrupo == 0
                            ? "3"
                            : "4",
                        ],
                    },
                    [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: _vm.errors.first("doseMaximaPorcentagem"),
                              expression:
                                "errors.first('doseMaximaPorcentagem')",
                            },
                          ],
                          staticClass: "form-group",
                        },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "control-label",
                              attrs: { for: "doseMaximaPorcentagem" },
                            },
                            [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(
                                    _vm.$t(
                                      "__.Crud.produto.edit_vue_html.doseMaxPorcent"
                                    )
                                  ) +
                                  "\n                            "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("decimal-component", {
                            staticClass: "form-control",
                            attrs: {
                              id: "doseMaximaPorcentagem",
                              name: "doseMaximaPorcentagem",
                              disabled: _vm.somenteConsulta,
                            },
                            model: {
                              value: _vm.model.doseMaximaPorcentagem,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.model,
                                  "doseMaximaPorcentagem",
                                  $$v
                                )
                              },
                              expression: "model.doseMaximaPorcentagem",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-sm-6" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("dcbId"),
                            expression: "errors.first('dcbId')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "dcbId" },
                          },
                          [
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t("__.Crud.produto.edit_vue_html.dcb")
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("combo-component", {
                          attrs: {
                            id: "dcbId",
                            name: "dcbId",
                            data: _vm.dcbsOptions,
                            refresh: _vm.loadDcbs,
                            disabled: _vm.somenteConsulta,
                          },
                          model: {
                            value: _vm.model.dcbId,
                            callback: function ($$v) {
                              _vm.$set(_vm.model, "dcbId", $$v)
                            },
                            expression: "model.dcbId",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-6" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("dciId"),
                            expression: "errors.first('dciId')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "dciId" },
                          },
                          [
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t("__.Crud.produto.edit_vue_html.dci")
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("combo-component", {
                          attrs: {
                            id: "dciId",
                            name: "dciId",
                            data: _vm.dcisOptions,
                            refresh: _vm.loadDcis,
                            disabled: _vm.somenteConsulta,
                          },
                          model: {
                            value: _vm.model.dciId,
                            callback: function ($$v) {
                              _vm.$set(_vm.model, "dciId", $$v)
                            },
                            expression: "model.dciId",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-sm-6" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("listaControladoId"),
                            expression: "errors.first('listaControladoId')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "listaControladoId" },
                          },
                          [
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Crud.produto.edit_vue_html.listControlados"
                                  )
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("combo-component", {
                          attrs: {
                            id: "listaControladoId",
                            name: "listaControladoId",
                            data: _vm.listasControladoOptions,
                            refresh: _vm.loadListasControlado,
                            disabled: _vm.somenteConsulta,
                          },
                          model: {
                            value: _vm.model.listaControladoId,
                            callback: function ($$v) {
                              _vm.$set(_vm.model, "listaControladoId", $$v)
                            },
                            expression: "model.listaControladoId",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-6" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("cas"),
                            expression: "errors.first('cas')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "cas" },
                          },
                          [
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t("__.Crud.produto.edit_vue_html.cas")
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.model.cas,
                              expression: "model.cas",
                            },
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "max:30",
                              expression: "'max:30'",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            autocomplete: "off",
                            type: "text",
                            id: "cas",
                            name: "cas",
                            disabled: _vm.somenteConsulta,
                          },
                          domProps: { value: _vm.model.cas },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(_vm.model, "cas", $event.target.value)
                            },
                          },
                        }),
                      ]
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-sm-6" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("pesoMolecular"),
                            expression: "errors.first('pesoMolecular')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "PesoMolecular" },
                          },
                          [
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Crud.produto.edit_vue_html.pesoMolecular"
                                  )
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("decimal-component", {
                          staticClass: "form-control",
                          attrs: {
                            id: "pesoMolecular",
                            name: "pesoMolecular",
                            disabled: _vm.somenteConsulta,
                          },
                          model: {
                            value: _vm.model.pesoMolecular,
                            callback: function ($$v) {
                              _vm.$set(_vm.model, "pesoMolecular", $$v)
                            },
                            expression: "model.pesoMolecular",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-4" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("formulaMolecular"),
                            expression: "errors.first('formulaMolecular')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "formulaMolecular" },
                          },
                          [
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Crud.produto.edit_vue_html.formulaMolecular"
                                  )
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.model.formulaMolecular,
                              expression: "model.formulaMolecular",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            autocomplete: "off",
                            type: "text",
                            id: "formulaMolecular",
                            name: "formulaMolecular",
                            disabled: _vm.somenteConsulta,
                          },
                          domProps: { value: _vm.model.formulaMolecular },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.model,
                                "formulaMolecular",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-2" }, [
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "naoUtilizarExcipiente" },
                          },
                          [
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Crud.produto.edit_vue_html.naoUtilizarExcipiente"
                                  )
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("checkbox-component", {
                          attrs: {
                            id: "naoUtilizarExcipiente",
                            name: "naoUtilizarExcipiente",
                          },
                          model: {
                            value: _vm.model.naoUtilizarExcipiente,
                            callback: function ($$v) {
                              _vm.$set(_vm.model, "naoUtilizarExcipiente", $$v)
                            },
                            expression: "model.naoUtilizarExcipiente",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _vm.model.tipoProduto == 0 && _vm.model.subGrupo == 0
                  ? _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-sm-6" }, [
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c(
                              "label",
                              {
                                staticClass: "control-label",
                                attrs: { for: "produtoDiluidoId" },
                              },
                              [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(
                                      _vm.$t(
                                        "__.Crud.produto.edit_vue_html.prodPuro"
                                      )
                                    ) +
                                    "\n                            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("combo-component", {
                              attrs: {
                                id: "produtoPuroId",
                                name: "produtoPuroId",
                                data: _vm.produtoPuroOptions,
                                customView: true,
                                searchable: true,
                                disabled: _vm.somenteConsulta,
                              },
                              model: {
                                value: _vm.model.produtoPuroId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.model, "produtoPuroId", $$v)
                                },
                                expression: "model.produtoPuroId",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-sm-6" }, [
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c(
                              "label",
                              {
                                staticClass: "control-label",
                                attrs: { for: "produtoDiluidoId" },
                              },
                              [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(
                                      _vm.$t(
                                        "__.Crud.produto.edit_vue_html.prodDiluido"
                                      )
                                    ) +
                                    "\n                            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("combo-component", {
                              attrs: {
                                id: "produtoDiluidoId",
                                name: "produtoDiluidoId",
                                data: _vm.produtoDiluidoOptions,
                                customView: true,
                                searchable: true,
                                disabled: _vm.somenteConsulta,
                              },
                              model: {
                                value: _vm.model.produtoDiluidoId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.model, "produtoDiluidoId", $$v)
                                },
                                expression: "model.produtoDiluidoId",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-sm-6" }, [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: _vm.errors.first("doseMaximaDiluido"),
                                expression: "errors.first('doseMaximaDiluido')",
                              },
                            ],
                            staticClass: "form-group",
                          },
                          [
                            _c(
                              "label",
                              {
                                staticClass: "control-label",
                                attrs: { for: "doseMaximaDiluido" },
                              },
                              [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(
                                      _vm.$t(
                                        "__.Crud.produto.edit_vue_html.doseMaxProdDiluido"
                                      )
                                    ) +
                                    "\n                            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("decimal-com-sinal-custom-component", {
                              staticClass: "form-control",
                              attrs: {
                                id: "doseMaximaDiluido",
                                title: _vm.$t(
                                  "__.Crud.produto.edit_vue_html.indicaLimitDose"
                                ),
                                signal: _vm.getSignal(
                                  _vm.model.unidadeMedidaManipulacaoId
                                ),
                                name: "doseMaximaDiluido",
                                disabled: _vm.somenteConsulta,
                              },
                              model: {
                                value: _vm.model.doseMaximaDiluido,
                                callback: function ($$v) {
                                  _vm.$set(_vm.model, "doseMaximaDiluido", $$v)
                                },
                                expression: "model.doseMaximaDiluido",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-sm-1" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("siproquim"),
                            expression: "errors.first('siproquim')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "siproquim" },
                          },
                          [
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Crud.produto.edit_vue_html.siproquim"
                                  )
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("checkbox-component", {
                          attrs: {
                            id: "siproquim",
                            name: "siproquim",
                            disabled: _vm.somenteConsulta,
                          },
                          model: {
                            value: _vm.model.siproquim,
                            callback: function ($$v) {
                              _vm.$set(_vm.model, "siproquim", $$v)
                            },
                            expression: "model.siproquim",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _vm.model.siproquim
                    ? _c("div", { staticClass: "col-sm-5" }, [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: _vm.errors.first("codigoSiproquimId"),
                                expression: "errors.first('codigoSiproquimId')",
                              },
                            ],
                            staticClass: "form-group",
                          },
                          [
                            _c(
                              "label",
                              {
                                staticClass: "control-label",
                                attrs: { for: "codigoSiproquimId" },
                              },
                              [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(
                                      _vm.$t(
                                        "__.Crud.produto.edit_vue_html.codigoSiproquim"
                                      )
                                    ) +
                                    "\n                            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("combo-component", {
                              attrs: {
                                id: "codigoSiproquimId",
                                name: "codigoSiproquimId",
                                data: _vm.codigoSiproquimOptions,
                                refresh: _vm.loadCodigoSiproquim,
                                customView: true,
                                searchable: true,
                                disabled: _vm.somenteConsulta,
                              },
                              model: {
                                value: _vm.model.codigoSiproquimId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.model, "codigoSiproquimId", $$v)
                                },
                                expression: "model.codigoSiproquimId",
                              },
                            }),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-1" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("controlePoliciaCivil"),
                            expression: "errors.first('controlePoliciaCivil')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "controlePoliciaCivil" },
                          },
                          [
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Crud.produto.edit_vue_html.policiaCivil"
                                  )
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("checkbox-component", {
                          attrs: {
                            id: "controlePoliciaCivil",
                            name: "controlePoliciaCivil",
                            disabled: _vm.somenteConsulta,
                          },
                          model: {
                            value: _vm.model.controlePoliciaCivil,
                            callback: function ($$v) {
                              _vm.$set(_vm.model, "controlePoliciaCivil", $$v)
                            },
                            expression: "model.controlePoliciaCivil",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "fieldset-component",
            {
              attrs: {
                title: _vm.$t("__.Crud.produto.edit_vue_html.embalagem"),
              },
            },
            [
              _c("div", { attrs: { slot: "rows" }, slot: "rows" }, [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-sm-6" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("volumeMaximo"),
                            expression: "errors.first('volumeMaximo')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "volumeMaximo" },
                          },
                          [
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Crud.produto.edit_vue_html.volMaxMl"
                                  )
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("decimal-component", {
                          staticClass: "form-control",
                          attrs: {
                            id: "volumeMaximo",
                            name: "volumeMaximo",
                            disabled: _vm.somenteConsulta,
                          },
                          model: {
                            value: _vm.model.volumeMaximo,
                            callback: function ($$v) {
                              _vm.$set(_vm.model, "volumeMaximo", $$v)
                            },
                            expression: "model.volumeMaximo",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-6" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("kitEmbalagemId"),
                            expression: "errors.first('kitEmbalagemId')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "kitEmbalagemId" },
                          },
                          [
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Crud.produto.edit_vue_html.kitEmbalagem"
                                  )
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("combo-component", {
                          attrs: {
                            id: "kitEmbalagemId",
                            name: "kitEmbalagemId",
                            data: _vm.kitEmbalagemOptions,
                          },
                          model: {
                            value: _vm.model.kitEmbalagemId,
                            callback: function ($$v) {
                              _vm.$set(_vm.model, "kitEmbalagemId", $$v)
                            },
                            expression: "model.kitEmbalagemId",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "fieldset-component",
            {
              attrs: {
                title: _vm.$t("__.Crud.produto.edit_vue_html.validades"),
              },
            },
            [
              _c(
                "div",
                { attrs: { slot: "rows" }, slot: "rows" },
                [
                  !_vm.somenteConsulta
                    ? _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-sm-9" }, [
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "control-label",
                                  attrs: { for: "formaFarmaceuticaValidadeId" },
                                },
                                [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(
                                        _vm.$t(
                                          "__.Crud.produto.edit_vue_html.frmFarmaceu"
                                        )
                                      ) +
                                      "\n                            "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("combo-component", {
                                attrs: {
                                  id: "formaFarmaceuticaValidadeId",
                                  name: "formaFarmaceuticaValidadeId",
                                  data: _vm.formasFarmaceuticaOptions,
                                },
                                model: {
                                  value: _vm.formaFarmaceuticaValidadeId,
                                  callback: function ($$v) {
                                    _vm.formaFarmaceuticaValidadeId = $$v
                                  },
                                  expression: "formaFarmaceuticaValidadeId",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-sm-3" }, [
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "control-label",
                                  attrs: { for: "diasValidadeForma" },
                                },
                                [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(
                                        _vm.$t(
                                          "__.Crud.produto.edit_vue_html.validadeDias"
                                        )
                                      ) +
                                      "\n                            "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("decimal-component", {
                                staticClass: "form-control",
                                attrs: {
                                  id: "diasValidadeForma",
                                  name: "diasValidadeForma",
                                },
                                model: {
                                  value: _vm.diasValidadeForma,
                                  callback: function ($$v) {
                                    _vm.diasValidadeForma = $$v
                                  },
                                  expression: "diasValidadeForma",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.somenteConsulta
                    ? _c("buttonIncluirComponent", {
                        on: { click: _vm.onInclusaoFormaFarmaceuticaValidade },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("grid-component", {
                    attrs: {
                      data: _vm.model.validadesFormasFarmaceuticasProdutos,
                      columns: _vm.gridColumnsFormaFarmaceuticaValidades,
                      "show-table-head": false,
                      "show-table-foot": false,
                      "show-edit-item": false,
                      showActionColumn: !_vm.somenteConsulta,
                    },
                    on: {
                      "remove-item": _vm.onRemoveFormaFormaceuticaValidade,
                    },
                  }),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "fieldset-component",
            {
              attrs: { title: _vm.$t("__.Crud.produto.edit_vue_html.capsula") },
            },
            [
              _c("div", { attrs: { slot: "rows" }, slot: "rows" }, [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-sm-7" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("capsulaId"),
                            expression: "errors.first('capsulaId')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "capsulaId" },
                          },
                          [
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Crud.produto.edit_vue_html.tipoCapsula"
                                  )
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("combo-component", {
                          attrs: {
                            id: "capsulaId",
                            name: "capsulaId",
                            data: _vm.capsulasOptions,
                            searchable: true,
                            disabled: _vm.somenteConsulta,
                          },
                          model: {
                            value: _vm.model.capsulaId,
                            callback: function ($$v) {
                              _vm.$set(_vm.model, "capsulaId", $$v)
                            },
                            expression: "model.capsulaId",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-2" }, [
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "capsulaGastrorresistente" },
                          },
                          [
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Crud.fornecedor.edit_vue_html.tipo"
                                  )
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("combo-component", {
                          attrs: {
                            id: "tipoCapsula",
                            name: "tipoCapsula",
                            data: _vm.tipoCapsulaOptions,
                            disabled: _vm.somenteConsulta,
                          },
                          model: {
                            value: _vm.model.tipoCapsula,
                            callback: function ($$v) {
                              _vm.$set(_vm.model, "tipoCapsula", $$v)
                            },
                            expression: "model.tipoCapsula",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-3" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("corPadraoCapsulaId"),
                            expression: "errors.first('corPadraoCapsulaId')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: {
                              for: "cor",
                              title: _vm.$t("__.obrigatorio"),
                            },
                          },
                          [
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Crud.produto.edit_vue_html.corPadraoCapsula"
                                  )
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("combo-component", {
                          attrs: {
                            id: "corPadraoCapsulaId",
                            name: "corPadraoCapsulaId",
                            data: _vm.coresOptions,
                            searchable: true,
                            disabled: _vm.somenteConsulta,
                          },
                          model: {
                            value: _vm.model.corPadraoCapsulaId,
                            callback: function ($$v) {
                              _vm.$set(_vm.model, "corPadraoCapsulaId", $$v)
                            },
                            expression: "model.corPadraoCapsulaId",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "fieldset-component",
            {
              attrs: {
                title: _vm.$t(
                  "__.Crud.produto.edit_vue_html.frmFarmaceuBloqueada"
                ),
              },
            },
            [
              _c(
                "div",
                { attrs: { slot: "rows" }, slot: "rows" },
                [
                  !_vm.somenteConsulta
                    ? _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-sm-10" }, [
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "control-label",
                                  attrs: { for: "formaFarmaceuticaId" },
                                },
                                [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(
                                        _vm.$t(
                                          "__.Crud.produto.edit_vue_html.frmFarmaceu"
                                        )
                                      ) +
                                      "\n                            "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("combo-component", {
                                attrs: {
                                  id: "formaFarmaceuticaId",
                                  name: "formaFarmaceuticaId",
                                  data: _vm.formasFarmaceuticaOptions,
                                },
                                model: {
                                  value: _vm.model.formaFarmaceuticaId,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.model,
                                      "formaFarmaceuticaId",
                                      $$v
                                    )
                                  },
                                  expression: "model.formaFarmaceuticaId",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.somenteConsulta
                    ? _c("buttonIncluirComponent", {
                        on: { click: _vm.onInclusaoFormaFarmaceutica },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.model.tipoProduto == 0
                    ? _c("grid-component", {
                        attrs: {
                          data: _vm.model.formasFarmaceuticas,
                          columns: _vm.gridColumnsFormaFarmaceutica,
                          "show-table-head": false,
                          "show-table-foot": false,
                          "show-edit-item": false,
                          showActionColumn: !_vm.somenteConsulta,
                        },
                        on: { "remove-item": _vm.onRemoveFormaFarmaceutica },
                      })
                    : _c("grid-component", {
                        attrs: {
                          data: _vm.model.formasFarmaceuticas,
                          columns: _vm.gridColumnsFormaFarmaceuticaEmb,
                          "show-table-head": false,
                          "show-table-foot": false,
                          "show-edit-item": false,
                          showActionColumn: !_vm.somenteConsulta,
                        },
                        on: { "remove-item": _vm.onRemoveFormaFarmaceutica },
                      }),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "fieldset-component",
            {
              attrs: {
                title: _vm.$t("__.Crud.produto.edit_vue_html.associacoes"),
              },
            },
            [
              _c(
                "div",
                { attrs: { slot: "rows" }, slot: "rows" },
                [
                  !_vm.somenteConsulta
                    ? _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-sm-6" }, [
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "control-label",
                                  attrs: {
                                    for: "formaFarmaceuticaAssociacaoId",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(
                                        _vm.$t(
                                          "__.Crud.produto.edit_vue_html.frmFarmaceu"
                                        )
                                      ) +
                                      "\n                            "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("combo-component", {
                                attrs: {
                                  id: "formaFarmaceuticaAssociacaoId",
                                  name: "formaFarmaceuticaAssociacaoId",
                                  data: _vm.formasFarmaceuticaOptions,
                                  searchable: true,
                                },
                                model: {
                                  value: _vm.formaFarmaceuticaAssociacaoId,
                                  callback: function ($$v) {
                                    _vm.formaFarmaceuticaAssociacaoId = $$v
                                  },
                                  expression: "formaFarmaceuticaAssociacaoId",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-sm-6" }, [
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "control-label",
                                  attrs: { for: "produtoAssociacaoId" },
                                },
                                [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(
                                        _vm.$t(
                                          "__.Crud.produto.edit_vue_html.produto"
                                        )
                                      ) +
                                      "\n                            "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("combo-component", {
                                attrs: {
                                  id: "produtoAssociacaoId",
                                  name: "produtoAssociacaoId",
                                  data: _vm.produtoAssOptions,
                                  customView: true,
                                  searchable: true,
                                },
                                model: {
                                  value: _vm.produtoAssociacaoId,
                                  callback: function ($$v) {
                                    _vm.produtoAssociacaoId = $$v
                                  },
                                  expression: "produtoAssociacaoId",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.somenteConsulta
                    ? _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-sm-2" }, [
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "tooltip",
                                  rawName: "v-tooltip",
                                  value: _vm.errors.first("excipiente"),
                                  expression: "errors.first('excipiente')",
                                },
                              ],
                              staticClass: "form-group",
                            },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "control-label",
                                  attrs: { for: "excipiente" },
                                },
                                [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(
                                        _vm.$t(
                                          "__.Crud.produto.edit_vue_html.excip"
                                        )
                                      ) +
                                      "\n                            "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("checkbox-component", {
                                attrs: { id: "excipiente", name: "excipiente" },
                                model: {
                                  value: _vm.excipienteAssociacao,
                                  callback: function ($$v) {
                                    _vm.excipienteAssociacao = $$v
                                  },
                                  expression: "excipienteAssociacao",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _vm._v(" "),
                        _vm.excipienteAssociacao
                          ? _c("div", { staticClass: "col-sm-2" }, [
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "tooltip",
                                      rawName: "v-tooltip",
                                      value: _vm.errors.first("desmembrado"),
                                      expression: "errors.first('desmembrado')",
                                    },
                                  ],
                                  staticClass: "form-group",
                                },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "control-label",
                                      attrs: { for: "desmembrado" },
                                    },
                                    [
                                      _vm._v(
                                        "\n                                " +
                                          _vm._s(
                                            _vm.$t(
                                              "__.Crud.produto.edit_vue_html.desmembrar"
                                            )
                                          ) +
                                          "\n                            "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("checkbox-component", {
                                    attrs: {
                                      id: "desmembrado",
                                      name: "desmembrado",
                                    },
                                    model: {
                                      value: _vm.desmembradoAssociacao,
                                      callback: function ($$v) {
                                        _vm.desmembradoAssociacao = $$v
                                      },
                                      expression: "desmembradoAssociacao",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        !_vm.excipienteAssociacao
                          ? _c("div", { staticClass: "col-sm-4" }, [
                              _c(
                                "div",
                                { staticClass: "form-group" },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "control-label",
                                      attrs: { for: "tipoCalculoAssociacao" },
                                    },
                                    [
                                      _vm._v(
                                        "\n                                " +
                                          _vm._s(
                                            _vm.$t(
                                              "__.Crud.produto.edit_vue_html.tipoCalculoAssociacao"
                                            )
                                          ) +
                                          "\n                            "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("combo-component", {
                                    attrs: {
                                      id: "tipoCalculoAssociacao",
                                      name: "tipoCalculoAssociacao",
                                      data: _vm.tipoCalculoAssociacaoOptions,
                                      customView: true,
                                      searchable: true,
                                    },
                                    model: {
                                      value: _vm.tipoCalculoAssociacao,
                                      callback: function ($$v) {
                                        _vm.tipoCalculoAssociacao = $$v
                                      },
                                      expression: "tipoCalculoAssociacao",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            class: _vm.excipienteAssociacao
                              ? "col-sm-8"
                              : "col-sm-6",
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "form-group" },
                              [
                                _c(
                                  "label",
                                  {
                                    staticClass: "control-label",
                                    attrs: { for: "percentualAssociacao" },
                                  },
                                  [
                                    _vm._v(
                                      "\n                                " +
                                        _vm._s(
                                          _vm.$t(
                                            "__.Crud.produto.edit_vue_html.percentual"
                                          )
                                        ) +
                                        "\n                            "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _vm.excipienteAssociacao
                                  ? _c("decimal-component", {
                                      staticClass: "form-control",
                                      attrs: {
                                        disabled: "excipienteAssociacao",
                                        id: "percentualAssociacao",
                                        name: "percentualAssociacao",
                                      },
                                      model: {
                                        value: _vm.percentualAssociacao,
                                        callback: function ($$v) {
                                          _vm.percentualAssociacao = $$v
                                        },
                                        expression: "percentualAssociacao",
                                      },
                                    })
                                  : _c("decimal-component", {
                                      staticClass: "form-control",
                                      attrs: {
                                        id: "percentualAssociacao",
                                        name: "percentualAssociacao",
                                      },
                                      model: {
                                        value: _vm.percentualAssociacao,
                                        callback: function ($$v) {
                                          _vm.percentualAssociacao = $$v
                                        },
                                        expression: "percentualAssociacao",
                                      },
                                    }),
                              ],
                              1
                            ),
                          ]
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.somenteConsulta
                    ? _c("buttonIncluirComponent", {
                        on: { click: _vm.onInclusaoProdutoAssociacao },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("grid-component", {
                    attrs: {
                      data: _vm.model.produtoAssociacoes,
                      columns: _vm.gridColumnsProdutoAssociacoes,
                      "show-table-head": false,
                      "show-table-foot": false,
                      "show-edit-item": false,
                      showActionColumn: !_vm.somenteConsulta,
                    },
                    on: { "remove-item": _vm.onRemoveProdutoAssociacoes },
                  }),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "fieldset-component",
            {
              attrs: {
                title: _vm.$t("__.Crud.produto.edit_vue_html.sinonimos"),
              },
            },
            [
              _c(
                "div",
                { attrs: { slot: "rows" }, slot: "rows" },
                [
                  !_vm.somenteConsulta
                    ? _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-sm-5" }, [
                          _c("div", { staticClass: "form-group" }, [
                            _c(
                              "label",
                              {
                                staticClass: "control-label",
                                attrs: { for: "produtoSinonimo" },
                              },
                              [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(
                                      _vm.$t(
                                        "__.Crud.produto.edit_vue_html.sinonimos"
                                      )
                                    ) +
                                    "\n                            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.produtoSinonimo,
                                  expression: "produtoSinonimo",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: {
                                autocomplete: "off",
                                type: "text",
                                id: "produtoSinonimo",
                                name: "produtoSinonimo",
                              },
                              domProps: { value: _vm.produtoSinonimo },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.produtoSinonimo = $event.target.value
                                },
                              },
                            }),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-sm-4" }, [
                          _c("div", { staticClass: "form-group" }, [
                            _c(
                              "label",
                              {
                                staticClass: "control-label",
                                attrs: { for: "descricaoRotulo" },
                              },
                              [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(
                                      _vm.$t(
                                        "__.Crud.produto.edit_vue_html.descricaoRotulo"
                                      )
                                    ) +
                                    "\n                            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.descricaoRotulo,
                                  expression: "descricaoRotulo",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: {
                                autocomplete: "off",
                                type: "text",
                                id: "descricaoRotulo",
                                name: "descricaoRotulo",
                              },
                              domProps: { value: _vm.descricaoRotulo },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.descricaoRotulo = $event.target.value
                                },
                              },
                            }),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-sm-3" }, [
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "control-label",
                                  attrs: { for: "fatorEquivalencia" },
                                },
                                [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(
                                        _vm.$t(
                                          "__.Crud.produto.edit_vue_html.fatorEquivalencia"
                                        )
                                      ) +
                                      "\n                            "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("decimal-component", {
                                staticClass: "form-control",
                                attrs: {
                                  id: "fatorEquivalencia",
                                  name: "fatorEquivalencia",
                                },
                                model: {
                                  value: _vm.fatorEquivalencia,
                                  callback: function ($$v) {
                                    _vm.fatorEquivalencia = $$v
                                  },
                                  expression: "fatorEquivalencia",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.somenteConsulta
                    ? _c("buttonIncluirComponent", {
                        attrs: {
                          alterar: _vm.indexEdditingProdutoSinonimo != null,
                        },
                        on: { click: _vm.onInclusaoProdutoSinonimo },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("grid-component", {
                    attrs: {
                      data: _vm.model.produtoSinonimos,
                      columns: _vm.gridColumnsProdutoSinonimos,
                      "show-table-head": false,
                      "show-table-foot": false,
                      showActionColumn: !_vm.somenteConsulta,
                    },
                    on: {
                      "remove-item": _vm.onRemoveProdutoSinonimos,
                      "edit-item": _vm.onEditProdutoSinonimos,
                    },
                  }),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "fieldset-component",
            {
              attrs: {
                title: _vm.$t(
                  "__.Crud.produto.edit_vue_html.incompatibilidade"
                ),
              },
            },
            [
              _c(
                "div",
                { attrs: { slot: "rows" }, slot: "rows" },
                [
                  !_vm.somenteConsulta
                    ? _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-sm-6" }, [
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "control-label",
                                  attrs: { for: "produtoIncompativelId" },
                                },
                                [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(
                                        _vm.$t(
                                          "__.Crud.produto.edit_vue_html.produtoIncompativel"
                                        )
                                      ) +
                                      "\n                            "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("combo-component", {
                                attrs: {
                                  id: "produtoIncompativelId",
                                  name: "produtoIncompativelId",
                                  data: _vm.produtosIncompatibilidadeOptions,
                                  refresh:
                                    _vm.loadProdutosIncompatibilidadeOptions,
                                },
                                model: {
                                  value: _vm.produtoIncompativelId,
                                  callback: function ($$v) {
                                    _vm.produtoIncompativelId = $$v
                                  },
                                  expression: "produtoIncompativelId",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-sm-6" }, [
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "control-label",
                                  attrs: {
                                    for: "formaFarmaceuticaIncompativelId",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(
                                        _vm.$t(
                                          "__.Crud.produto.edit_vue_html.formaFarmIncompativel"
                                        )
                                      ) +
                                      "\n                            "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("combo-component", {
                                attrs: {
                                  id: "formaFarmaceuticaIncompativelId",
                                  name: "formaFarmaceuticaIncompativelId",
                                  data: _vm.formaFarmaceuticaIncompatibilidadeOptions,
                                  refresh:
                                    _vm.loadFormaFarmaceuticaIncompatibilidadeOptions,
                                },
                                model: {
                                  value: _vm.formaFarmaceuticaIncompativelId,
                                  callback: function ($$v) {
                                    _vm.formaFarmaceuticaIncompativelId = $$v
                                  },
                                  expression: "formaFarmaceuticaIncompativelId",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.somenteConsulta
                    ? _c("buttonIncluirComponent", {
                        on: { click: _vm.onInclusaoProdutoIncompativel },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("grid-component", {
                    attrs: {
                      data: _vm.model.produtosIncompativeis,
                      columns: _vm.gridColumnsProdutosIncompativeis,
                      showRemoveItem: true,
                      showSortIcons: false,
                      showEditItem: false,
                      showTableHead: false,
                      showAddNewButton: false,
                      showTableFoot: false,
                      showTools: false,
                      showActionColumn: !_vm.somenteConsulta,
                    },
                    on: { "remove-item": _vm.onRemoveProdutoIncompativel },
                  }),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "fieldset-component",
            {
              attrs: {
                title: _vm.$t(
                  "__.Crud.produto.edit_vue_html.embalagemIncompativeis"
                ),
              },
            },
            [
              _c(
                "div",
                { attrs: { slot: "rows" }, slot: "rows" },
                [
                  !_vm.somenteConsulta
                    ? _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-sm-6" }, [
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "control-label",
                                  attrs: { for: "kitEmbalagemIncompativelId" },
                                },
                                [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(
                                        _vm.$t(
                                          "__.Crud.produto.edit_vue_html.kitEmbalagemIncompativel"
                                        )
                                      ) +
                                      "\n                            "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("combo-component", {
                                attrs: {
                                  id: "kitEmbalagemIncompativelId",
                                  name: "kitEmbalagemIncompativelId",
                                  data: _vm.kitEmbalagensIncompatibilidadeOptions,
                                  refresh:
                                    _vm.loadKitEmbalagemIncompatibilidadeOptions,
                                },
                                model: {
                                  value: _vm.kitEmbalagemIncompativelId,
                                  callback: function ($$v) {
                                    _vm.kitEmbalagemIncompativelId = $$v
                                  },
                                  expression: "kitEmbalagemIncompativelId",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.somenteConsulta
                    ? _c("buttonIncluirComponent", {
                        on: { click: _vm.onInclusaoKitEmbalagemIncompativel },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("grid-component", {
                    attrs: {
                      data: _vm.model.kitEmbalagemIncompatibilidade,
                      columns: _vm.gridColumnsKitEmbalagemIncompativeis,
                      showRemoveItem: true,
                      showSortIcons: false,
                      showEditItem: false,
                      showTableHead: false,
                      showAddNewButton: false,
                      showTableFoot: false,
                      showTools: false,
                      showActionColumn: !_vm.somenteConsulta,
                    },
                    on: { "remove-item": _vm.onRemoveKitEmbalagemIncompativel },
                  }),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "fieldset-component",
            {
              attrs: {
                title: _vm.$t(
                  "__.Crud.produto.edit_vue_html.produtoRefrigerado"
                ),
              },
            },
            [
              _c(
                "div",
                { attrs: { slot: "rows" }, slot: "rows" },
                [
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      { staticClass: "col-sm-6" },
                      [
                        _c("div", { staticClass: "form-group" }, [
                          _c(
                            "label",
                            {
                              staticClass: "control-label",
                              attrs: {
                                for: "produtoRefrigeradoFormaceuticaId",
                              },
                            },
                            [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(
                                    _vm.$t(
                                      "__.Crud.produto.edit_vue_html.frmFarmaceu"
                                    )
                                  ) +
                                  "\n                            "
                              ),
                            ]
                          ),
                        ]),
                        _vm._v(" "),
                        _c("combo-component", {
                          attrs: {
                            id: "produtoRefrigeradoFormaceuticaId",
                            name: "produtoRefrigeradoFormaceuticaId",
                            data: _vm.produtosRefrigeradosOptions,
                            refresh: _vm.loadProdutosRefrigeradosOptions,
                          },
                          model: {
                            value: _vm.produtoRefrigeradoFormaceuticaId,
                            callback: function ($$v) {
                              _vm.produtoRefrigeradoFormaceuticaId = $$v
                            },
                            expression: "produtoRefrigeradoFormaceuticaId",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  !_vm.somenteConsulta
                    ? _c("buttonIncluirComponent", {
                        on: { click: _vm.onInclusaoProdutoRefrigerado },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("grid-component", {
                    attrs: {
                      data: _vm.model.produtoRefrigeradoFormaFarmaceutica,
                      columns:
                        _vm.gridColumnsProdutoRefrigeradoFormaFarmaceutica,
                      showRemoveItem: true,
                      showSortIcons: false,
                      showEditItem: false,
                      showTableHead: false,
                      showAddNewButton: false,
                      showTableFoot: false,
                      showTools: false,
                      showActionColumn: !_vm.somenteConsulta,
                    },
                    on: {
                      "remove-item":
                        _vm.onRemoverProdutoRefrigeradoFormaFarmaceutica,
                    },
                  }),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _vm.usuarioLogado.franquia.moduloCompras
            ? _c(
                "fieldset-component",
                {
                  attrs: {
                    title: _vm.$t(
                      "__.Crud.produtolote.edit_vue_html.fornecedor"
                    ),
                  },
                },
                [
                  _c(
                    "div",
                    { attrs: { slot: "rows" }, slot: "rows" },
                    [
                      _c("div", { staticClass: "row" }, [
                        _c(
                          "div",
                          { staticClass: "col-sm-6" },
                          [
                            _c("div", { staticClass: "form-group" }, [
                              _c(
                                "label",
                                {
                                  staticClass: "control-label",
                                  attrs: { for: "produtoFornecedorId" },
                                },
                                [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(
                                        _vm.$t(
                                          "__.Crud.produtolote.edit_vue_html.fornecedor"
                                        )
                                      ) +
                                      "\n                            "
                                  ),
                                ]
                              ),
                            ]),
                            _vm._v(" "),
                            _c("combo-component", {
                              attrs: {
                                id: "produtoFornecedorId",
                                name: "produtoFornecedorId",
                                data: _vm.fornecedoresOptions,
                                refresh: _vm.loadFornecedoresOptions,
                              },
                              model: {
                                value: _vm.produtoFornecedorId,
                                callback: function ($$v) {
                                  _vm.produtoFornecedorId = $$v
                                },
                                expression: "produtoFornecedorId",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      !_vm.somenteConsulta
                        ? _c("buttonIncluirComponent", {
                            on: { click: _vm.onInclusaoProdutoFornecedor },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _c("grid-component", {
                        attrs: {
                          data: _vm.model.produtoFornecedores,
                          columns: _vm.gridColumnsProdutoFornecedor,
                          showRemoveItem: true,
                          showSortIcons: false,
                          showEditItem: false,
                          showTableHead: false,
                          showAddNewButton: false,
                          showTableFoot: false,
                          showTools: false,
                          showActionColumn: !_vm.somenteConsulta,
                          extraActions: _vm.extraActionsProdutoFornecedor,
                        },
                        on: {
                          "extra-action": _vm.onExtraActionsProdutoFornecedor,
                          "remove-item": _vm.onRemoverProdutoFornecedor,
                        },
                      }),
                    ],
                    1
                  ),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.modelId > 0
            ? _c(
                "fieldset-component",
                {
                  attrs: {
                    title: _vm.$t("__.Crud.produto.edit_vue_html.lote"),
                  },
                },
                [
                  _c(
                    "div",
                    { attrs: { slot: "rows" }, slot: "rows" },
                    [
                      _c("grid-component", {
                        attrs: {
                          data: _vm.gridDataLote,
                          columns: _vm.gridColumnLote,
                          showEditItem: false,
                          showRemoveItem: false,
                          showActionColumn: !_vm.somenteConsulta,
                          showAddNewButton: false,
                          showTableFoot: false,
                          showTools: false,
                          showTableHead: false,
                          extraActions: _vm.extraActions,
                        },
                        on: { "extra-action": _vm.onExtraAction },
                      }),
                      _vm._v(" "),
                      _c("h4", { staticClass: "total-lote" }, [
                        _vm._v(
                          "\n                    " +
                            _vm._s(
                              _vm.$t("__.Crud.produto.edit_vue_html.qtdTotal")
                            ) +
                            " " +
                            _vm._s(_vm.totalLote) +
                            " " +
                            _vm._s(_vm.getSiglaEstoque()) +
                            "\n                "
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "shortcutComponent",
                        {
                          ref: "shortcutComponent",
                          attrs: {
                            showAddNew: false,
                            showBtnOk: false,
                            modalClass: "md-modal",
                            title: _vm.$t(
                              "__.Crud.produto.edit_vue_html.visuLote"
                            ),
                          },
                        },
                        [
                          _c(
                            "div",
                            { attrs: { slot: "component" }, slot: "component" },
                            [
                              _vm.loadLote
                                ? _c("ver-lote-component", {
                                    ref: "verLoteComponent",
                                    attrs: { loteId: _vm.loteId },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "fieldset-component",
            {
              attrs: { title: _vm.$t("__.Crud.produto.edit_vue_html.fiscal") },
            },
            [
              _c(
                "div",
                { attrs: { slot: "rows" }, slot: "rows" },
                [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-sm-12" }, [
                      _c("div", { staticClass: "form-group" }, [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "descricaoFiscal" },
                          },
                          [
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Crud.produto.edit_vue_html.descricaoFiscal"
                                  )
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            { name: "focus", rawName: "v-focus" },
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.model.descricaoFiscal,
                              expression: "model.descricaoFiscal",
                            },
                          ],
                          ref: "descricaoFiscal",
                          staticClass: "form-control",
                          attrs: {
                            autocomplete: "off",
                            type: "text",
                            id: "descricaoFiscal",
                            name: "descricaoFiscal",
                            disabled: _vm.somenteConsulta,
                          },
                          domProps: { value: _vm.model.descricaoFiscal },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.model,
                                "descricaoFiscal",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-sm-6" }, [
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "control-label",
                              attrs: { for: "origemMercadoria" },
                            },
                            [_vm._v("Origem Mercadoria")]
                          ),
                          _vm._v(" "),
                          _c("combo-component", {
                            attrs: {
                              id: "origemMercadoria",
                              name: "origemMercadoria",
                              data: _vm.origemMercadoriaOptions,
                              disabled: _vm.somenteConsulta,
                            },
                            model: {
                              value: _vm.model.origemMercadoria,
                              callback: function ($$v) {
                                _vm.$set(_vm.model, "origemMercadoria", $$v)
                              },
                              expression: "model.origemMercadoria",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-sm-6" }, [
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "control-label",
                              attrs: { for: "aliquotaII" },
                            },
                            [
                              _vm._v(
                                "\n                                Alíquota Imposto de Importação (II)\n                            "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("decimal-com-sinal-custom-component", {
                            staticClass: "form-control",
                            attrs: {
                              signal: "%",
                              id: "aliquotaII",
                              name: "aliquotaII",
                              disabled: _vm.somenteConsulta,
                            },
                            model: {
                              value: _vm.model.aliquotaII,
                              callback: function ($$v) {
                                _vm.$set(_vm.model, "aliquotaII", $$v)
                              },
                              expression: "model.aliquotaII",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      {
                        class:
                          _vm.model.cstICMS == 20 || _vm.model.cstICMS == 51
                            ? "col-sm-4"
                            : "col-sm-6",
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c(
                              "label",
                              {
                                staticClass: "control-label",
                                attrs: { for: "cstICMS" },
                              },
                              [_vm._v("Situação tributaria ICMS")]
                            ),
                            _vm._v(" "),
                            _c("combo-component", {
                              attrs: {
                                id: "cstICMS",
                                name: "cstICMS",
                                data: _vm.cstICMSOptions,
                                disabled: _vm.somenteConsulta,
                              },
                              model: {
                                value: _vm.model.cstICMS,
                                callback: function ($$v) {
                                  _vm.$set(_vm.model, "cstICMS", $$v)
                                },
                                expression: "model.cstICMS",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _vm.model.cstICMS == 20
                      ? _c("div", { staticClass: "col-sm-2" }, [
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "control-label",
                                  attrs: { for: "percentualReducaoBase" },
                                },
                                [
                                  _vm._v(
                                    "\n                                Percentual Redução de Base\n                            "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("decimal-com-sinal-custom-component", {
                                staticClass: "form-control",
                                attrs: {
                                  signal: "%",
                                  id: "percentualReducaoBase",
                                  name: "percentualReducaoBase",
                                  disabled: _vm.somenteConsulta,
                                },
                                model: {
                                  value: _vm.model.percentualReducaoBase,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.model,
                                      "percentualReducaoBase",
                                      $$v
                                    )
                                  },
                                  expression: "model.percentualReducaoBase",
                                },
                              }),
                            ],
                            1
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.model.cstICMS == 51
                      ? _c("div", { staticClass: "col-sm-2" }, [
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "control-label",
                                  attrs: { for: "percentualDiferimento" },
                                },
                                [_vm._v("Percentual Diferimento")]
                              ),
                              _vm._v(" "),
                              _c("decimal-com-sinal-custom-component", {
                                staticClass: "form-control",
                                attrs: {
                                  signal: "%",
                                  id: "percentualDiferimento",
                                  name: "percentualDiferimento",
                                  disabled: _vm.somenteConsulta,
                                },
                                model: {
                                  value: _vm.model.percentualDiferimento,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.model,
                                      "percentualDiferimento",
                                      $$v
                                    )
                                  },
                                  expression: "model.percentualDiferimento",
                                },
                              }),
                            ],
                            1
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.simplesNacional
                      ? _c("div", { staticClass: "col-sm-2" }, [
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "control-label",
                                  attrs: { for: "aliquotaICMSEntrada" },
                                },
                                [_vm._v("Alíquota ICMS Entradas")]
                              ),
                              _vm._v(" "),
                              _c("decimal-com-sinal-custom-component", {
                                staticClass: "form-control",
                                attrs: {
                                  signal: "%",
                                  id: "aliquotaICMSEntrada",
                                  name: "aliquotaICMSEntrada",
                                  disabled: _vm.somenteConsulta,
                                },
                                model: {
                                  value: _vm.model.aliquotaICMSEntrada,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.model,
                                      "aliquotaICMSEntrada",
                                      $$v
                                    )
                                  },
                                  expression: "model.aliquotaICMSEntrada",
                                },
                              }),
                            ],
                            1
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.simplesNacional
                      ? _c("div", { staticClass: "col-sm-2" }, [
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "control-label",
                                  attrs: { for: "aliquotaICMSSaida" },
                                },
                                [_vm._v("Alíquota ICMS Saídas")]
                              ),
                              _vm._v(" "),
                              _c("decimal-com-sinal-custom-component", {
                                staticClass: "form-control",
                                attrs: {
                                  signal: "%",
                                  id: "aliquotaICMSSaida",
                                  name: "aliquotaICMSSaida",
                                  disabled: _vm.somenteConsulta,
                                },
                                model: {
                                  value: _vm.model.aliquotaICMSSaida,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.model,
                                      "aliquotaICMSSaida",
                                      $$v
                                    )
                                  },
                                  expression: "model.aliquotaICMSSaida",
                                },
                              }),
                            ],
                            1
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.simplesNacional
                      ? _c("div", { staticClass: "col-sm-4" }, [
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "control-label",
                                  attrs: {
                                    for: "percentualCreditoSimplesNacional",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                                Percentual de Crédito do Simples Nacional\n                            "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("decimal-com-sinal-custom-component", {
                                staticClass: "form-control",
                                attrs: {
                                  id: "percentualCreditoSimplesNacional",
                                  name: "percentualCreditoSimplesNacional",
                                  signal: "%",
                                  disabled: _vm.somenteConsulta,
                                },
                                model: {
                                  value:
                                    _vm.model.percentualCreditoSimplesNacional,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.model,
                                      "percentualCreditoSimplesNacional",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "model.percentualCreditoSimplesNacional",
                                },
                              }),
                            ],
                            1
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-sm-2" }, [
                      _c("div", { staticClass: "form-group" }, [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "codigoBeneficio" },
                          },
                          [_vm._v("Código Benefício Fiscal")]
                        ),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.model.codigoBeneficio,
                              expression: "model.codigoBeneficio",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            type: "text",
                            id: "codigoBeneficio",
                            name: "codigoBeneficio",
                            disabled: _vm.somenteConsulta,
                          },
                          domProps: { value: _vm.model.codigoBeneficio },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.model,
                                "codigoBeneficio",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-sm-1" }, [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: _vm.errors.first("sujeitoIPI"),
                              expression: "errors.first('sujeitoIPI')",
                            },
                          ],
                          staticClass: "form-group",
                        },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "control-label",
                              attrs: { for: "sujeitoIPI" },
                            },
                            [_vm._v("Sujeito ao IPI")]
                          ),
                          _vm._v(" "),
                          _c("checkbox-component", {
                            attrs: {
                              id: "sujeitoIPI",
                              name: "sujeitoIPI",
                              disabled: _vm.somenteConsulta,
                            },
                            model: {
                              value: _vm.model.sujeitoIPI,
                              callback: function ($$v) {
                                _vm.$set(_vm.model, "sujeitoIPI", $$v)
                              },
                              expression: "model.sujeitoIPI",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-sm-5" }, [
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "control-label",
                              attrs: { for: "cstIPIEntrada" },
                            },
                            [
                              _vm._v(
                                "\n                                Situação tributaria IPI Entradas\n                            "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("combo-component", {
                            attrs: {
                              id: "cstIPIEntrada",
                              name: "cstIPIEntrada",
                              data: _vm.cstIPIEntradaOptions,
                              disabled:
                                !_vm.model.sujeitoIPI || _vm.somenteConsulta,
                            },
                            model: {
                              value: _vm.model.cstIPIEntrada,
                              callback: function ($$v) {
                                _vm.$set(_vm.model, "cstIPIEntrada", $$v)
                              },
                              expression: "model.cstIPIEntrada",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-sm-4" }, [
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "control-label",
                              attrs: { for: "cstIPISaida" },
                            },
                            [_vm._v("Situação tributaria IPI Saídas")]
                          ),
                          _vm._v(" "),
                          _c("combo-component", {
                            attrs: {
                              id: "cstIPISaida",
                              name: "cstIPISaida",
                              data: _vm.cstIPISaidaOptions,
                              disabled:
                                !_vm.model.sujeitoIPI || _vm.somenteConsulta,
                            },
                            model: {
                              value: _vm.model.cstIPISaida,
                              callback: function ($$v) {
                                _vm.$set(_vm.model, "cstIPISaida", $$v)
                              },
                              expression: "model.cstIPISaida",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-sm-2" }, [
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "control-label",
                              attrs: { for: "aliquotaIPI" },
                            },
                            [_vm._v("Alíquota IPI")]
                          ),
                          _vm._v(" "),
                          _c("decimal-com-sinal-custom-component", {
                            staticClass: "form-control",
                            attrs: {
                              signal: "%",
                              id: "aliquotaIPI",
                              name: "aliquotaIPI",
                              disabled:
                                !_vm.model.sujeitoIPI || _vm.somenteConsulta,
                            },
                            model: {
                              value: _vm.model.aliquotaIPI,
                              callback: function ($$v) {
                                _vm.$set(_vm.model, "aliquotaIPI", $$v)
                              },
                              expression: "model.aliquotaIPI",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-sm-4" }, [
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "control-label",
                              attrs: { for: "cstPISEntrada" },
                            },
                            [
                              _vm._v(
                                "\n                                Situação tributaria PIS Entradas\n                            "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("combo-component", {
                            attrs: {
                              id: "cstPISEntrada",
                              name: "cstPISEntrada",
                              data: _vm.cstPISCOFINSEntradaOptions,
                              disabled: _vm.somenteConsulta,
                            },
                            model: {
                              value: _vm.model.cstPISEntrada,
                              callback: function ($$v) {
                                _vm.$set(_vm.model, "cstPISEntrada", $$v)
                              },
                              expression: "model.cstPISEntrada",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-sm-4" }, [
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "control-label",
                              attrs: { for: "cstPISSaida" },
                            },
                            [_vm._v("Situação tributaria PIS Saídas")]
                          ),
                          _vm._v(" "),
                          _c("combo-component", {
                            attrs: {
                              id: "cstPISSaida",
                              name: "cstPISSaida",
                              data: _vm.cstPISCOFINSSaidaOptions,
                              disabled: _vm.somenteConsulta,
                            },
                            model: {
                              value: _vm.model.cstPISSaida,
                              callback: function ($$v) {
                                _vm.$set(_vm.model, "cstPISSaida", $$v)
                              },
                              expression: "model.cstPISSaida",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-sm-2" }, [
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "control-label",
                              attrs: { for: "aliquotaPISEntrada" },
                            },
                            [_vm._v("Alíquota PIS Entradas")]
                          ),
                          _vm._v(" "),
                          _c("decimal-com-sinal-custom-component", {
                            staticClass: "form-control",
                            attrs: {
                              signal: "%",
                              id: "aliquotaPISEntrada",
                              name: "aliquotaPISEntrada",
                              disabled: _vm.somenteConsulta,
                            },
                            model: {
                              value: _vm.model.aliquotaPISEntrada,
                              callback: function ($$v) {
                                _vm.$set(_vm.model, "aliquotaPISEntrada", $$v)
                              },
                              expression: "model.aliquotaPISEntrada",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-sm-2" }, [
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "control-label",
                              attrs: { for: "aliquotaPISSaida" },
                            },
                            [_vm._v("Alíquota PIS Saídas")]
                          ),
                          _vm._v(" "),
                          _c("decimal-com-sinal-custom-component", {
                            staticClass: "form-control",
                            attrs: {
                              signal: "%",
                              id: "aliquotaPISSaida",
                              name: "aliquotaPISSaida",
                              disabled: _vm.somenteConsulta,
                            },
                            model: {
                              value: _vm.model.aliquotaPISSaida,
                              callback: function ($$v) {
                                _vm.$set(_vm.model, "aliquotaPISSaida", $$v)
                              },
                              expression: "model.aliquotaPISSaida",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-sm-4" }, [
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "control-label",
                              attrs: { for: "cstCOFINSEntrada" },
                            },
                            [
                              _vm._v(
                                "\n                                Situação tributaria COFINS Entradas\n                            "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("combo-component", {
                            attrs: {
                              id: "cstCOFINSEntrada",
                              name: "cstCOFINSEntrada",
                              data: _vm.cstPISCOFINSEntradaOptions,
                              disabled: _vm.somenteConsulta,
                            },
                            model: {
                              value: _vm.model.cstCOFINSEntrada,
                              callback: function ($$v) {
                                _vm.$set(_vm.model, "cstCOFINSEntrada", $$v)
                              },
                              expression: "model.cstCOFINSEntrada",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-sm-4" }, [
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "control-label",
                              attrs: { for: "cstCOFINSSaida" },
                            },
                            [
                              _vm._v(
                                "\n                                Situação tributaria COFINS Saídas\n                            "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("combo-component", {
                            attrs: {
                              id: "cstCOFINSSaida",
                              name: "cstCOFINSSaida",
                              data: _vm.cstPISCOFINSSaidaOptions,
                              disabled: _vm.somenteConsulta,
                            },
                            model: {
                              value: _vm.model.cstCOFINSSaida,
                              callback: function ($$v) {
                                _vm.$set(_vm.model, "cstCOFINSSaida", $$v)
                              },
                              expression: "model.cstCOFINSSaida",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-sm-2" }, [
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "control-label",
                              attrs: { for: "aliquotaCOFINSEntrada" },
                            },
                            [
                              _vm._v(
                                "\n                                Alíquota COFINS Entradas\n                            "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("decimal-com-sinal-custom-component", {
                            staticClass: "form-control",
                            attrs: {
                              id: "aliquotaCOFINSEntrada",
                              name: "aliquotaCOFINSEntrada",
                              signal: "%",
                              disabled: _vm.somenteConsulta,
                            },
                            model: {
                              value: _vm.model.aliquotaCOFINSEntrada,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.model,
                                  "aliquotaCOFINSEntrada",
                                  $$v
                                )
                              },
                              expression: "model.aliquotaCOFINSEntrada",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-sm-2" }, [
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "control-label",
                              attrs: { for: "aliquotaCOFINSSaida" },
                            },
                            [_vm._v("Alíquota COFINS Saídas")]
                          ),
                          _vm._v(" "),
                          _c("decimal-com-sinal-custom-component", {
                            staticClass: "form-control",
                            attrs: {
                              id: "aliquotaCOFINSSaida",
                              name: "aliquotaCOFINSSaida",
                              signal: "%",
                              disabled: _vm.somenteConsulta,
                            },
                            model: {
                              value: _vm.model.aliquotaCOFINSSaida,
                              callback: function ($$v) {
                                _vm.$set(_vm.model, "aliquotaCOFINSSaida", $$v)
                              },
                              expression: "model.aliquotaCOFINSSaida",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-sm-2" }, [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: _vm.errors.first("codigoAnvisa"),
                              expression: "errors.first('codigoAnvisa')",
                            },
                          ],
                          staticClass: "form-group",
                        },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "control-label",
                              attrs: { for: "codigoAnvisa" },
                            },
                            [_vm._v("Código Anvisa")]
                          ),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.model.codigoAnvisa,
                                expression: "model.codigoAnvisa",
                              },
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "min:13",
                                expression: "'min:13'",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: {
                              type: "text",
                              id: "codigoAnvisa",
                              name: "codigoAnvisa",
                              autocomplete: "off",
                              disabled: _vm.somenteConsulta,
                            },
                            domProps: { value: _vm.model.codigoAnvisa },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.model,
                                  "codigoAnvisa",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-sm-2" }, [
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "control-label",
                              attrs: { for: "precoMaximoConsumidor" },
                            },
                            [_vm._v("Preço Máximo Consumidor")]
                          ),
                          _vm._v(" "),
                          _c("moeda-component", {
                            staticClass: "form-control",
                            attrs: {
                              id: "precoMaximoConsumidor",
                              name: "precoMaximoConsumidor",
                              disabled: _vm.somenteConsulta,
                            },
                            model: {
                              value: _vm.model.precoMaximoConsumidor,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.model,
                                  "precoMaximoConsumidor",
                                  $$v
                                )
                              },
                              expression: "model.precoMaximoConsumidor",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "fieldset-component",
                    {
                      attrs: {
                        title: _vm.$t(
                          "__.Crud.produto.edit_vue_html.aliquotasInterestaduais"
                        ),
                      },
                    },
                    [
                      _c(
                        "div",
                        { attrs: { slot: "rows" }, slot: "rows" },
                        [
                          _c("div", { staticClass: "row" }, [
                            !_vm.somenteConsulta
                              ? _c("div", { staticClass: "col-sm-5" }, [
                                  _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "tooltip",
                                          rawName: "v-tooltip",
                                          value: _vm.errors.first(
                                            "ufAliquotaInterestadual"
                                          ),
                                          expression:
                                            "errors.first('ufAliquotaInterestadual')",
                                        },
                                      ],
                                      staticClass: "form-group",
                                    },
                                    [
                                      _c(
                                        "label",
                                        {
                                          staticClass: "control-label",
                                          attrs: {
                                            for: "ufAliquotaInterestadual",
                                            title: _vm.$t("__.obrigatorio"),
                                          },
                                        },
                                        [
                                          _c(
                                            "span",
                                            { staticStyle: { color: "red" } },
                                            [_vm._v("*")]
                                          ),
                                          _vm._v(
                                            "\n                                        " +
                                              _vm._s(
                                                _vm.$t(
                                                  "__.Crud.produto.edit_vue_html.uf"
                                                )
                                              ) +
                                              "\n                                    "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("combo-component", {
                                        attrs: {
                                          id: "ufAliquotaInterestadual",
                                          name: "ufAliquotaInterestadual",
                                          data: _vm.ufOptions,
                                        },
                                        model: {
                                          value: _vm.ufAliquotaInterestadual,
                                          callback: function ($$v) {
                                            _vm.ufAliquotaInterestadual = $$v
                                          },
                                          expression: "ufAliquotaInterestadual",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            !_vm.somenteConsulta
                              ? _c("div", { staticClass: "col-sm-5" }, [
                                  _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "tooltip",
                                          rawName: "v-tooltip",
                                          value: _vm.errors.first(
                                            "aliquotaInterestadual"
                                          ),
                                          expression:
                                            "errors.first('aliquotaInterestadual')",
                                        },
                                      ],
                                      staticClass: "form-group",
                                    },
                                    [
                                      _c(
                                        "label",
                                        {
                                          staticClass: "control-label",
                                          attrs: {
                                            for: "aliquotaInterestadual",
                                            title: _vm.$t("__.obrigatorio"),
                                          },
                                        },
                                        [
                                          _c(
                                            "span",
                                            { staticStyle: { color: "red" } },
                                            [_vm._v("*")]
                                          ),
                                          _vm._v(
                                            "\n                                        " +
                                              _vm._s(
                                                _vm.$t(
                                                  "__.Crud.produto.edit_vue_html.aliquotaInterestadual"
                                                )
                                              ) +
                                              "\n                                    "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("decimal-component", {
                                        staticClass: "form-control",
                                        attrs: {
                                          id: "aliquotaInterestadual",
                                          name: "aliquotaInterestadual",
                                        },
                                        model: {
                                          value: _vm.aliquotaInterestadual,
                                          callback: function ($$v) {
                                            _vm.aliquotaInterestadual = $$v
                                          },
                                          expression: "aliquotaInterestadual",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _c("div", { staticClass: "col-sm-2" }, [
                              _c(
                                "div",
                                { staticClass: "form-group ajusteIncluir" },
                                [
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn btn-info",
                                      attrs: { type: "button" },
                                      on: {
                                        click: function ($event) {
                                          _vm.onShowAliquotasInterestaduais()
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                                        " +
                                          _vm._s(
                                            _vm.$t(
                                              "__.Crud.produto.edit_vue_html.verAliquotasInterestaduais"
                                            )
                                          ) +
                                          "\n                                    "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          !_vm.somenteConsulta
                            ? _c("buttonIncluirComponent", {
                                on: {
                                  click: _vm.onInclusaoUfAliquotaInterestadual,
                                },
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _c("grid-component", {
                            attrs: {
                              data: _vm.model.produtoAliquotasInterestaduais,
                              columns: _vm.gridColumnsAliquotasInterestaduais,
                              showRemoveItem: true,
                              showSortIcons: false,
                              showEditItem: false,
                              showTableHead: false,
                              showAddNewButton: false,
                              showTableFoot: false,
                              showTools: false,
                              refreshData:
                                _vm.refreshDataProdutosAliquotasInterestaduais,
                              showActionColumn: !_vm.somenteConsulta,
                            },
                            on: {
                              "remove-item": _vm.onRemoveAliquotaInterestadual,
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _vm.model.id
            ? _c("infoBagdeComponent", { attrs: { text: _vm.infoBagdeText } })
            : _vm._e(),
          _vm._v(" "),
          !_vm.isModal
            ? _c("fieldset", [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-sm-12 newbtn" }, [
                    _c("div", { staticClass: "form-group" }, [
                      !_vm.somenteConsulta
                        ? _c(
                            "button",
                            {
                              staticClass: "btn btn-success",
                              attrs: { type: "submit" },
                            },
                            [
                              _c("i", {
                                staticClass: "fa fa-save",
                                attrs: { "aria-hidden": "true" },
                              }),
                              _vm._v(
                                "\n                            " +
                                  _vm._s(_vm.$t("__.Crud.salvar")) +
                                  "\n                        "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.somenteConsulta
                        ? _c(
                            "button",
                            {
                              staticClass: "btn btn-secondary",
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  _vm.load()
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(_vm.$t("__.Crud.limpar")) +
                                  "\n                        "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-secondary",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              _vm.cancel()
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n                            " +
                              _vm._s(_vm.$t("__.Crud.voltar")) +
                              "\n                        "
                          ),
                        ]
                      ),
                    ]),
                  ]),
                ]),
              ])
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _vm.validarVisibilidadeBotoes("replicar")
        ? [
            _c("replicar-produto-component", {
              ref: "replicarProdutoComponent",
              on: { "replicar-produtos": _vm.onConfirmReplicarProdutos },
            }),
            _vm._v(" "),
            _c("replicar-selecao-franquia-component", {
              ref: "replicarSelecaoFranquiaComponent",
              on: { replicar: _vm.onConfirmReplicar },
            }),
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.showLog
        ? [
            _c(
              "shortcutComponent",
              {
                ref: "shortcutLogComponent",
                attrs: { showAddNew: false, btOkName: "OK" },
                on: { "selection-item": _vm.onCloseLog },
              },
              [
                _c(
                  "div",
                  { attrs: { slot: "component" }, slot: "component" },
                  [
                    _c("produto-log-financeiro-component", {
                      ref: "produtoLogFinanceiroComponent",
                      on: { close: _vm.onCloseLog },
                    }),
                  ],
                  1
                ),
              ]
            ),
          ]
        : _vm._e(),
      _vm._v(" "),
      _c(
        "shortcutComponent",
        {
          ref: "shortcutAliquotaInterestadualComponent",
          attrs: { showAddNew: false, showBtnOk: false },
        },
        [
          _c("div", { attrs: { slot: "component" }, slot: "component" }, [
            _c("table", { staticClass: "aliquota-interestadual-table" }, [
              _c(
                "thead",
                [
                  _c("th"),
                  _vm._v(" "),
                  _vm._l(_vm.ufs, function (uf) {
                    return [
                      _c("th", { key: uf.value }, [
                        _c("h5", [_vm._v(_vm._s(uf.text))]),
                      ]),
                    ]
                  }),
                ],
                2
              ),
              _vm._v(" "),
              _c("tbody", [
                _c(
                  "tr",
                  [
                    _c("td", {}, [_c("h5", [_vm._v(_vm._s(_vm.franquiaUf))])]),
                    _vm._v(" "),
                    _vm._l(
                      _vm.aliquotasICMSInterestadualDefault,
                      function (uf) {
                        return [
                          _c("td", { key: uf.id }, [
                            _c("h5", [_vm._v(_vm._s(uf.aliquota) + "%")]),
                          ]),
                        ]
                      }
                    ),
                  ],
                  2
                ),
              ]),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "shortcutComponent",
        {
          ref: "shortcutFichaTecnicaComponent",
          attrs: {
            showAddNew: false,
            showBtnOk: _vm.VALIDAR_PERMISSAO_USUARIO("produtos", "update"),
            btOkName: _vm.$t("__.Crud.salvar"),
            extraButtons: _vm.extraButtonActionFichaTecnica,
          },
          on: {
            "selection-item": _vm.onSaveFichaTecnica,
            "extra-button-click": _vm.onExtraButtonActionFichaTecnica,
          },
        },
        [
          _vm.loadFichaTecnicaComponent
            ? _c(
                "div",
                { attrs: { slot: "component" }, slot: "component" },
                [_c("fichaTecnicaComponent", { ref: "fichaTecnicaComponent" })],
                1
              )
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _c(
        "shortcutComponent",
        {
          ref: "shortcutTrocarUnidadeMedidaComponent",
          attrs: {
            modalClass: "ld-modal",
            showAddNew: false,
            btOkName: _vm.$t("__.Crud.produto.edit_vue_html.trocar"),
          },
          on: { "selection-item": _vm.onSaveTrocarUnidadeMedidaEstoque },
        },
        [
          _c("div", { attrs: { slot: "component" }, slot: "component" }, [
            _c(
              "div",
              {
                staticClass: "color-red",
                staticStyle: { "font-weight": "bold" },
              },
              [_vm._v("Serão alterados os seguintes registros:")]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "color-red" }, [
              _vm._v(
                "\n                -Coversão do Valor de Custo, Custo Referência, Valor Venda, Estoque Minimo, Fator UI e Fator UTR\n            "
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "color-red" }, [
              _vm._v("-Conversão dos movimentos de estoque"),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "color-red" }, [
              _vm._v("-Conversão dos lotes"),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "color-red" }, [
              _vm._v(
                "**Não será considerado a densidade na conversão das unidades"
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-sm-6" }, [
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c(
                      "label",
                      {
                        staticClass: "control-label",
                        attrs: { for: "unidadeMedidaEstoqueIdOrigem" },
                      },
                      [
                        _vm._v(
                          "\n                            " +
                            _vm._s(
                              _vm.$t(
                                "__.Crud.produto.edit_vue_html.unidadeMedidaEstoqueIdOrigem"
                              )
                            ) +
                            "\n                        "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("combo-component", {
                      attrs: {
                        id: "unidadeMedidaEstoqueIdOrigem",
                        name: "unidadeMedidaEstoqueIdOrigem",
                        data: _vm.unidadesMedidaOptions,
                        refresh: _vm.loadUnidadesMedida,
                        disabled: "",
                      },
                      model: {
                        value: _vm.model.unidadeMedidaEstoqueId,
                        callback: function ($$v) {
                          _vm.$set(_vm.model, "unidadeMedidaEstoqueId", $$v)
                        },
                        expression: "model.unidadeMedidaEstoqueId",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-sm-6" }, [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip",
                        value: _vm.errors.first(
                          "unidadeMedidaEstoqueIdDestino"
                        ),
                        expression:
                          "errors.first('unidadeMedidaEstoqueIdDestino')",
                      },
                    ],
                    staticClass: "form-group",
                  },
                  [
                    _c(
                      "label",
                      {
                        staticClass: "control-label",
                        attrs: {
                          for: "unidadeMedidaEstoqueIdDestino",
                          title: _vm.$t("__.obrigatorio"),
                        },
                      },
                      [
                        _c("span", { staticStyle: { color: "red" } }, [
                          _vm._v("*"),
                        ]),
                        _vm._v(
                          "\n                            " +
                            _vm._s(
                              _vm.$t(
                                "__.Crud.produto.edit_vue_html.unidadeMedidaEstoqueIdDestino"
                              )
                            ) +
                            "\n                        "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("combo-component", {
                      attrs: {
                        id: "unidadeMedidaEstoqueIdDestino",
                        name: "unidadeMedidaEstoqueIdDestino",
                        data: _vm.unidadesMedidaOptions,
                        refresh: _vm.loadUnidadesMedida,
                      },
                      model: {
                        value: _vm.unidadeMedidaEstoqueIdDestino,
                        callback: function ($$v) {
                          _vm.unidadeMedidaEstoqueIdDestino = $$v
                        },
                        expression: "unidadeMedidaEstoqueIdDestino",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _c("imprimirRotuloPersonalizadoComponent", {
        ref: "imprimirRotuloPersonalizadoComponent",
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }