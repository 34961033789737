import { mapGetters } from "vuex";

import buttonIncluirComponent from "@/components/child/form/button/buttonIncluir.vue";
import dateTimePickerComponent from "@/components/child/form/datetimepicker.vue";
import fieldsetComponent from "@/components/child/form/fieldset.vue";
import filePickerComponent from "@/components/child/form/filepicker.vue";
import { GridAction } from "@/components/child/grid/gridAction";
import { GridColors, GridColumn, GridColumnType } from "@/components/child/grid/gridColumn";
import ListComponentBase from "@/components/parent/crud/listComponentBase";
import ShortcutComponent from "@/components/parent/shortcut/shortcut";
import shortcutComponent from "@/components/parent/shortcut/shortcut.vue";
import { Component, Prop } from "@/decorators";
import PaginationModel from "@/models/paginationModel";
import { IServiceAnexo } from "@/services/base/iServiceAnexo";
import ControleReclamacaoAnexoService from "@/services/controleReclamacaoAnexoService";
import ProdutoLoteAnexoService from "@/services/produtoLoteAnexoService";
import { Getters } from "@/store/store";
import Delay from "@/utils/common/delay";
import FileHelper from "@/utils/common/fileHelper";
import { DocumentFileType, ImageFileType } from "@/utils/fileExtensions";

export class ModelUpload {
    id?: number = null;
    descricao: string = null;
    file: File;
    dataCriacao?: Date = null;
}

@Component({
    components: {
        filePickerComponent,
        fieldsetComponent,
        shortcutComponent,
        buttonIncluirComponent,
        dateTimePickerComponent,
    },
    computed: {
        ...mapGetters(["VALIDAR_PERMISSAO_SOMENTE_CONSULTA"] as Getters),
    },
})
export default class UploadAnexoComponent extends ListComponentBase<any> {
    // State computed props
    VALIDAR_PERMISSAO_SOMENTE_CONSULTA: (telaDescricao: string) => boolean;

    serviceInstance: IServiceAnexo = null;
    serviceList = new Map<string, any>([
        ["ProdutoLoteAnexo", ProdutoLoteAnexoService],
        ["ControleReclamacaoAnexo", ControleReclamacaoAnexoService],
    ]);

    private shortCutComponent: ShortcutComponent = null;
    private loadUploadComponent = false;

    gridSelectedValues: Array<any> = [];
    extraActions: Array<object> = [];
    extraActionsView: GridAction[] = [];
    extraActionsDownload: GridAction[] = [];
    modelsUpload: ModelUpload[] = [];
    deleteIds: ModelUpload[] = [];

    get gridColumns(): Array<GridColumn> {
        return [
            new GridColumn("dataCriacao", "Data Inclusão", GridColumnType.DateTime),
            new GridColumn("descricao", "Descrição", GridColumnType.String),
        ];
    }

    selectedFile: File = null;
    somenteConsultaVenda = false;
    newRequest = true;

    @Prop(Number) id: number;
    @Prop(String) serviceUpload: string;

    model = null;

    private async onView(fileUpload: any) {
        const response = await this.serviceInstance.get(fileUpload.id);
        FileHelper.openBlank(response);
    }

    public async show() {
        while (!this.$refs.shortCutComponent) await Delay(50);
        this.shortCutComponent = this.$refs.shortCutComponent as ShortcutComponent;
        this.shortCutComponent.title = this.$t("__.Crud.produtolote.edit_vue_html.uploadAnexo") as string;
        this.loadUploadComponent = true;

        await this.load();

        this.shortCutComponent.show();
    }

    public async loadMethod() {
        if (this.newRequest) {
            this.somenteConsultaVenda = this.VALIDAR_PERMISSAO_SOMENTE_CONSULTA("vendas");
            this.createServiceInstance();

            try {
                if (this.id) {
                    this.newRequest = false;
                    return await this.serviceInstance
                        .listFiles(this.id)
                        .withLoading()
                        .resolveWithJSON<PaginationModel<any>>();
                }
            } catch {}
        }
    }

    private createServiceInstance() {
        if (this.serviceUpload && this.serviceInstance == null) {
            this.serviceInstance = new (this.serviceList.get(this.serviceUpload))() as IServiceAnexo;
        }
    }

    public async afterLoad() {
        this.extraActions.splice(0);
        this.extraActionsView.splice(0);
        this.extraActionsDownload.splice(0);

        for (let i = 0; i < this.gridData.length; i++) {
            if (this.gridData[i].id) {
                this.extraActionsView.push(new GridAction("visualizar", "Visualizar", "fa fa-eye", GridColors.BLUE));
                this.extraActionsDownload.push(
                    new GridAction("baixar", "Baixar Arquivo", "fa fa-download", GridColors.GREEN),
                );
            }
        }

        this.extraActions.push(this.extraActionsView);
        this.extraActions.push(this.extraActionsDownload);
    }

    private onExtraActions(name: string, model: any) {
        if (name.trim() == "visualizar") {
            this.onView(model);
        }

        if (name.trim() == "baixar") {
            this.onDownload(model);
        }
    }

    public async afterMounted() {
        this.model = new ModelUpload();
    }

    private clear() {
        this.afterMounted();
        this.selectedFile = null;
    }

    private async onInclusaoFile() {
        if (!this.selectedFile) {
            return;
        }

        const isValid = await this.$validator.validateAll();
        if (isValid) {
            if (!this.selectedFile.isImage() && !this.selectedFile.isDocument()) {
                const allowedFileTypes = [...Object.keys(DocumentFileType), ...Object.keys(ImageFileType)];
                this.$showError(
                    this.$t("__.ts.tipoArquivoInvalido"),
                    `${this.$t("__.ts.exemplosTipoArquivoPermitido")}: ${allowedFileTypes.join(", ")}`,
                );
                this.selectedFile = null;
                return;
            }

            this.modelsUpload.push({ descricao: this.model.descricao, file: this.selectedFile });

            this.gridData.push({
                descricao: this.model.descricao,
                selectedFile: this.selectedFile,
                dataCriacao: new Date(),
            });
            this.clear();
        }
    }

    private async insertFile(id: number, descricao: string, file: File) {
        try {
            await this.serviceInstance.insertFile(id, descricao, file).withLoading().resolveWithoutJSON();
            return true;
        } catch {}
        return false;
    }

    private async onDownload(fileUpload: any) {
        const response = await this.serviceInstance.get(fileUpload.id);

        const blob = await response.blob();
        const fileName = response.headers.get("Content-Disposition").split("filename=")[1].split(";")[0];
        FileHelper.download(blob, fileName);
    }

    private async onDelete(fileUpload: any) {
        if (fileUpload.id) {
            this.deleteIds.push(fileUpload);
        }
        this.gridData = this.gridData.filter(p => p != fileUpload);
        this.modelsUpload = this.modelsUpload.filter(
            p => p.file != fileUpload.file && p.descricao != fileUpload.descricao,
        );
        this.afterLoad();
    }

    public async onSucessoSave(id?: number) {
        if (this.deleteIds.length > 0) {
            await this.serviceInstance
                .delete(this.deleteIds.map(p => p.id))
                .withLoading()
                .resolveWithoutJSON();
        }

        for (const item of this.modelsUpload) {
            if (!item.id) {
                this.serviceInstance.insertFile(id, item.descricao, item.file);
            }
        }
    }
}
