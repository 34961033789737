import checkboxComponent from "@/components/child/form/checkbox.vue";
import dataTooltipComponent from "@/components/child/form/datatooltip.vue";
import { Component } from "@/decorators";
import BandeiraModel from "@/models/financeiro/bandeiraModel";
import BandeiraService from "@/services/financeiro/bandeiraService";

import EditComponentBase from "../editComponentBase";

@Component({
    components: {
        checkboxComponent,
        dataTooltipComponent,
    },
})
export default class BandeiraEditComponent extends EditComponentBase<BandeiraModel> {
    private async beforeMount() {
        await this.setProps(new BandeiraService(), "bandeira", new BandeiraModel());
    }
}
