import RedeModel from "../../models/rede/redeModel";
import nfetch from "../../utils/events/nfetch";
import { IService } from "../base/iService";

export default class RedeService extends IService<RedeModel> {
    constructor() {
        super("Rede");
    }

    public getComboRede(): Promise<Response> {
        //@ts-ignore
        return nfetch(`/${this.controllerName}/GetRedeBase`, {
            credentials: "same-origin",
        })
            .then((response: Response) => {
                if (response.ok) {
                    return response;
                } else {
                    return this.mensagemErro(response, "Get");
                }
            })
            .catch(() => {});
    }

    public replicarBaseModelo(model: RedeModel): Promise<Response> {
        return nfetch(`/${this.controllerName}/ReplicarBaseModelo`, {
            method: "post",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json; charset=UTF-8",
                "XSRF-TOKEN": this.getCookie("XSRF-TOKEN"),
            },
            body: JSON.stringify(model),
        });
    }

    public getRedeUtilizaDots(id: number): Promise<Response> {
        return nfetch(`/${this.controllerName}/GetRedeUtilizaDots?id=${id}`, {
            credentials: "same-origin",
        });
    }
}
