var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "crud crud-edit" },
    [
      _c("actionBarComponent", {
        attrs: {
          title: _vm.$t(
            "__.Crud.contagerencial.edit_vue_html.contaGerencialEdit"
          ),
        },
        on: { onSave: _vm.save },
      }),
      _vm._v(" "),
      _c(
        "form",
        {
          attrs: { novalidate: "novalidate" },
          on: {
            submit: function ($event) {
              $event.preventDefault()
              _vm.save()
            },
          },
        },
        [
          _c("fieldset", [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-sm-12" }, [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip",
                        value: _vm.errors.first("descricao"),
                        expression: "errors.first('descricao')",
                      },
                    ],
                    staticClass: "form-group",
                  },
                  [
                    _c(
                      "label",
                      {
                        staticClass: "control-label",
                        attrs: {
                          for: "descricao",
                          title: _vm.$t("__.obrigatorio"),
                        },
                      },
                      [
                        _c("span", { staticStyle: { color: "red" } }, [
                          _vm._v("* "),
                        ]),
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "__.Crud.contagerencial.edit_vue_html.descricao"
                            )
                          )
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        { name: "focus", rawName: "v-focus" },
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.model.descricao,
                          expression: "model.descricao",
                        },
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required|max:256",
                          expression: "'required|max:256'",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: {
                        autocomplete: "off",
                        type: "text",
                        id: "descricao",
                        name: "descricao",
                      },
                      domProps: { value: _vm.model.descricao },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.model, "descricao", $event.target.value)
                        },
                      },
                    }),
                  ]
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-sm-6" }, [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip",
                        value: _vm.errors.first("classificacaoId"),
                        expression: "errors.first('classificacaoId')",
                      },
                    ],
                    staticClass: "form-group",
                  },
                  [
                    _c(
                      "label",
                      {
                        staticClass: "control-label",
                        attrs: {
                          for: "classificacaoId",
                          title: _vm.$t("__.obrigatorio"),
                        },
                      },
                      [
                        _c("span", { staticStyle: { color: "red" } }, [
                          _vm._v("* "),
                        ]),
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "__.Crud.contagerencial.edit_vue_html.classif"
                            )
                          )
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("combo-component", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required",
                          expression: "'required'",
                        },
                      ],
                      attrs: {
                        id: "classificacaoId",
                        name: "classificacaoId",
                        data: _vm.classificacoesOptions,
                        searchable: false,
                        refresh: _vm.loadClassificacoes,
                      },
                      model: {
                        value: _vm.model.classificacaoId,
                        callback: function ($$v) {
                          _vm.$set(_vm.model, "classificacaoId", $$v)
                        },
                        expression: "model.classificacaoId",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-sm-6" }, [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip",
                        value: _vm.errors.first("tipoMovimentacao"),
                        expression: "errors.first('tipoMovimentacao')",
                      },
                    ],
                    staticClass: "form-group",
                  },
                  [
                    _c(
                      "label",
                      {
                        staticClass: "control-label",
                        attrs: {
                          for: "tipoMovimentacao",
                          title: _vm.$t("__.obrigatorio"),
                        },
                      },
                      [
                        _c("span", { staticStyle: { color: "red" } }, [
                          _vm._v("* "),
                        ]),
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "__.Crud.contagerencial.edit_vue_html.tipoMovimen"
                            )
                          )
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("combo-component", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required",
                          expression: "'required'",
                        },
                      ],
                      attrs: {
                        id: "tipoMovimentacao",
                        name: "tipoMovimentacao",
                        data: _vm.tiposMovimentoOptions,
                        searchable: false,
                      },
                      model: {
                        value: _vm.model.tipoMovimentacao,
                        callback: function ($$v) {
                          _vm.$set(_vm.model, "tipoMovimentacao", $$v)
                        },
                        expression: "model.tipoMovimentacao",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-sm-6" }, [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip",
                        value: _vm.errors.first("ativo"),
                        expression: "errors.first('ativo')",
                      },
                    ],
                    staticClass: "form-group",
                  },
                  [
                    _c(
                      "label",
                      { staticClass: "control-label", attrs: { for: "ativo" } },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.$t("__.Crud.contagerencial.edit_vue_html.ativo")
                          )
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("checkbox-component", {
                      attrs: { id: "ativo", name: "ativo" },
                      model: {
                        value: _vm.model.ativo,
                        callback: function ($$v) {
                          _vm.$set(_vm.model, "ativo", $$v)
                        },
                        expression: "model.ativo",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("fieldset", [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-sm-12 newbtn" }, [
                _c("div", { staticClass: "form-group" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-success",
                      attrs: { type: "submit" },
                    },
                    [
                      _c("i", {
                        staticClass: "fa fa-save",
                        attrs: { "aria-hidden": "true" },
                      }),
                      _vm._v(_vm._s(_vm.$t("__.Crud.salvar"))),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-secondary",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          _vm.load()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("__.Crud.limpar")))]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-secondary",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          _vm.cancel()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("__.Crud.voltar")))]
                  ),
                ]),
              ]),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }