var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "crud crud-edit-venda",
      staticStyle: { background: "white" },
    },
    [
      _c(
        "vue-tabs",
        {
          on: { input: _vm.inputTab },
          model: {
            value: _vm.activeTab,
            callback: function ($$v) {
              _vm.activeTab = $$v
            },
            expression: "activeTab",
          },
        },
        _vm._l(_vm.tabs, function (tab, indexTab) {
          return _c(
            "v-tab",
            {
              key: tab,
              attrs: {
                title:
                  _vm.etapasVenda[indexTab][0] + _vm.etapasVenda[indexTab][2],
              },
            },
            [
              _c(
                "div",
                {
                  staticStyle: { "padding-right": "12px" },
                  attrs: { slot: "title" },
                  slot: "title",
                },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(
                        _vm.etapasVenda[indexTab][0] +
                          _vm.etapasVenda[indexTab][2]
                      ) +
                      "\n                "
                  ),
                  _c(
                    "span",
                    {
                      staticClass: "closeTab",
                      on: {
                        click: function ($event) {
                          _vm.onRemoveTab(indexTab)
                        },
                      },
                    },
                    [
                      _c("i", {
                        staticClass: "fa fa-times",
                        attrs: { "aria-hidden": "true" },
                      }),
                    ]
                  ),
                  _vm._v(" "),
                  _c("span", {
                    staticClass: "fa fa-plus",
                    on: { click: _vm.onAddTab },
                  }),
                ]
              ),
              _vm._v(" "),
              _c(
                "keep-alive",
                [
                  _c("venda-component", {
                    ref: "vendaComponent",
                    refInFor: true,
                    attrs: {
                      indexVenda: indexTab,
                      isActiveTab:
                        _vm.activeTab ==
                        _vm.etapasVenda[indexTab][0] +
                          _vm.etapasVenda[indexTab][2],
                      motivoCancelamentoOp: _vm.motivoCancelamentoOptions,
                      modelId: _vm.etapasVenda[indexTab][1],
                      replicaModel: _vm.etapasVenda[indexTab][3],
                      clienteId: _vm.numClienteId,
                      pacienteId: _vm.paramPacienteId,
                      enderecoEntregaId: _vm.paramEnderecoEntregaId,
                      origem: _vm.paramOrigem,
                    },
                    on: {
                      "replicar-venda": function (p) {
                        return _vm.onReplicarVenda(p).withLoading()
                      },
                      "loaded-new-venda": _vm.onLoadedNewVenda,
                      "edit-venda": function (p, index) {
                        return _vm.onEditVenda(p, index).withLoading()
                      },
                      "close-venda": _vm.onRemoveTab,
                      "update-venda": _vm.onChangedVenda,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          )
        })
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }