import BaseModel from "./base/baseModel";
import StatusInventario from "./enum/statusInventario";
import TipoInventario from "./enum/tipoInventario";

export default class InventarioModel extends BaseModel {
    id?: number;
    dataConclusao?: Date = null;
    dataCriacao?: Date;
    dataModificacao?: Date;
    dataValidade?: Date;
    status: StatusInventario;
    statusDescricao: string;
    tipoInventario: TipoInventario;
    tipoInventarioDescricao: string;
    grupoInventario?: string;
    grupoInventarioId?: number;
    usuarioId?: number = null;
    usuarioDescricao: string;
    movimentacaoEntrada?: number;
    movimentacaoSaida?: number;
}
