enum BasicColor {

    Preto = 0,
    Azul = 1,
    Magenta = 2,
    Cinza = 3,
    verde = 4,
    Lima = 5,
    Castanho_Avermelhado = 6,
    Marinha = 7,
    Oliva = 8,
    Laranja = 9,
    Roxo = 10,
    Vermelho = 11,
    Prata = 12,
    Cerceta = 13,
    Branco = 14,
    Amarelo = 15,
    Agua = 16

}
export default BasicColor;

export enum BasicCssColor {

    black = 0,
    blue = 1,
    magenta = 2,
    gray = 3,
    green = 4,
    lime = 5,
    maroon = 6,
    darkblue = 7,
    olive = 8,
    orange = 9,
    purple = 10,
    red = 11,
    silver = 12,
    teal = 13,
    white = 14,
    yellow = 15,
    aqua = 16

}


