import { Module } from "vuex";

import { AppState } from "./store";

export type NotificationStoreState = {
    countUnreadedNotification: number;
};

export type NotificationStoreMutations =
    | "SET_UNREADED_NOTIFICATIONS"
    | "READ_ONE_NOTIFICATION"
    | "ADD_ONE_NOTIFICATION";

const notificationStore: Module<NotificationStoreState, AppState> = {
    state: {
        countUnreadedNotification: 0,
    },
    mutations: {
        SET_UNREADED_NOTIFICATIONS(store, value: number) {
            if (value >= 0) {
                store.countUnreadedNotification = value;
            }
        },
        READ_ONE_NOTIFICATION(store) {
            if (store.countUnreadedNotification >= 0) {
                store.countUnreadedNotification--;
            }
        },
        ADD_ONE_NOTIFICATION(store) {
            store.countUnreadedNotification++;
        },
    },
};

export default notificationStore;
