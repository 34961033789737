var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "shortcutComponent",
    {
      ref: "shortcutComponent",
      attrs: { modalClass: "lm-modal", showAddNew: false, btOkName: "Salvar" },
      on: { "selection-item": _vm.onSave },
    },
    [
      _c("div", { attrs: { slot: "component" }, slot: "component" }, [
        _c(
          "form",
          { attrs: { novalidate: "novalidate" } },
          [
            _c("fieldset", [
              !_vm.somenteConsulta
                ? _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-sm-6" }, [
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "control-label",
                              attrs: { for: "data" },
                            },
                            [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(
                                    _vm.$t(
                                      "__.Components.parent.manipulacao.list_vue_html.data"
                                    )
                                  ) +
                                  "\n                            "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("date-time-picker-component", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required",
                                expression: "'required'",
                              },
                            ],
                            attrs: { id: "data", name: "data", pickTime: true },
                            model: {
                              value: _vm.data,
                              callback: function ($$v) {
                                _vm.data = $$v
                              },
                              expression: "data",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-sm-6" }, [
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "control-label",
                              attrs: { for: "status" },
                            },
                            [_vm._v(_vm._s(_vm.$t("__.ts.status")))]
                          ),
                          _vm._v(" "),
                          _c("combo-component", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required",
                                expression: "'required'",
                              },
                            ],
                            attrs: {
                              id: "status",
                              name: "status",
                              data: _vm.statusOptions,
                            },
                            model: {
                              value: _vm.status,
                              callback: function ($$v) {
                                _vm.status = $$v
                              },
                              expression: "status",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.status == 2
                ? _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-sm-12" }, [
                      _c("div", { staticClass: "form-group" }, [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "motivo" },
                          },
                          [
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t("__.Crud.pcp.etapas.motivoEtapaPCP")
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.motivo,
                              expression: "motivo",
                            },
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required|max:256",
                              expression: "'required|max:256'",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            type: "text",
                            id: "motivo",
                            name: "motivo",
                            autocomplete: "off",
                          },
                          domProps: { value: _vm.motivo },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.motivo = $event.target.value
                            },
                          },
                        }),
                      ]),
                    ]),
                  ])
                : _vm._e(),
            ]),
            _vm._v(" "),
            !_vm.somenteConsulta
              ? _c("buttonIncluirComponent", {
                  on: { click: _vm.onAddTentativaEntrega },
                })
              : _vm._e(),
            _vm._v(" "),
            _c("grid-component", {
              attrs: {
                data: _vm.gridData,
                columns: _vm.gridColumns,
                controlaPageSize: false,
                showAddNewButton: false,
                showTools: false,
                showSearchField: false,
                showTableFoot: false,
                "show-edit-item": false,
              },
              on: { "remove-item": _vm.onRemoveTentativaEntrega },
            }),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }