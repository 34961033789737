var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "table-body crud-item-grid" }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.itemType == 1,
            expression: "itemType == 1",
          },
        ],
        staticClass: "row",
        staticStyle: { "margin-top": "15px" },
      },
      [
        _c("div", { staticClass: "col-sm-12" }, [
          _c(
            "div",
            {
              directives: [
                {
                  name: "tooltip",
                  rawName: "v-tooltip",
                  value: _vm.errors.first("kitEmbalagem"),
                  expression: "errors.first('kitEmbalagem')",
                },
              ],
              staticClass: "form-group",
            },
            [
              _c(
                "label",
                {
                  staticClass: "control-label",
                  attrs: { for: "kitEmbalagem" },
                },
                [
                  _vm._v(
                    "\n                    " +
                      _vm._s(
                        _vm.$t(
                          "__.Components.parent.manipulacao.itemTable_vue_html.kitEmbalagem"
                        )
                      ) +
                      "\n                "
                  ),
                ]
              ),
              _vm._v(" "),
              _c("combo-component", {
                attrs: {
                  id: "kitEmbalagem",
                  name: "kitEmbalagem",
                  value: _vm.manipulacao.kitEmbalagemId,
                  data: _vm.kitEmbalagensOptions,
                  refresh: _vm.loadKitEmbalagem,
                  disabled: _vm.manipulacaoContext.isDesabilitado,
                },
                on: { input: _vm.onChangeKitEmbalagem },
              }),
            ],
            1
          ),
        ]),
      ]
    ),
    _vm._v(" "),
    _c("table", { staticClass: "table table-condensed table-striped" }, [
      _c("thead", [
        _c("tr", [
          _c("th", [
            _c(
              "div",
              { staticClass: "row" },
              [
                _vm._l(_vm.theader, function (nameHeader, nameIndex) {
                  return [
                    _c(
                      "div",
                      {
                        key: nameIndex,
                        class: "col-sm-" + _vm.theaderColSpan[nameIndex],
                        staticStyle: { "padding-left": "10px !important" },
                      },
                      [
                        _vm._v(
                          "\n                                " +
                            _vm._s(nameHeader) +
                            "\n                            "
                        ),
                      ]
                    ),
                  ]
                }),
                _vm._v(" "),
                _vm.theader2.length > 0 && _vm.theaderColSpan2.length > 0
                  ? _c(
                      "div",
                      {
                        class: "col-sm-3",
                        staticStyle: { "padding-left": "5px !important" },
                      },
                      [
                        _vm._l(_vm.theader2, function (nameHeader, nameIndex) {
                          return [
                            _c(
                              "div",
                              {
                                key: nameIndex,
                                class:
                                  "col-sm-" + _vm.theaderColSpan2[nameIndex],
                                staticStyle: {
                                  "padding-left": "0px !important",
                                },
                              },
                              [
                                _vm._v(
                                  "\n                                    " +
                                    _vm._s(nameHeader) +
                                    "\n                                "
                                ),
                              ]
                            ),
                          ]
                        }),
                      ],
                      2
                    )
                  : _vm._e(),
              ],
              2
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "tbody",
        _vm._l(_vm.itens, function (item, index) {
          return _c("tr", { key: index }, [
            _c(
              "td",
              { staticClass: "large-column" },
              [
                [
                  _c(
                    "keep-alive",
                    [
                      _c("manipulacao-item-component", {
                        ref: "manipulacaoItemComponent",
                        refInFor: true,
                        staticClass: "ignorePrint",
                        attrs: {
                          index: index,
                          uuid: _vm.uuid,
                          itemModel: item,
                          itemType: _vm.itemType,
                          produtosList: _vm.produtosOptions,
                          formulaPadraoList: _vm.formulaPadraoOptions,
                          unidadeMedidaList: _vm.unidadeMedidaOptions,
                          capsulasOptionsList: _vm.capsulasOptions,
                        },
                        on: {
                          onRefazerHeader: _vm.onRefazerHeader,
                          alterarVolumeCapsula: _vm.alterarVolumeCapsula,
                          onCorCapsulaChange: _vm.onCorCapsulaChange,
                        },
                      }),
                    ],
                    1
                  ),
                ],
              ],
              2
            ),
            _vm._v(" "),
            !_vm.manipulacaoContext.isDesabilitado
              ? _c("td", { staticClass: "option-column" }, [
                  _c(
                    "a",
                    {
                      attrs: { href: "#", title: _vm.$t("__.Crud.excluir") },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          _vm.removeItem(index)
                        },
                      },
                    },
                    [
                      _c("i", {
                        staticClass: "fa fa-times",
                        attrs: { "aria-hidden": "true" },
                      }),
                    ]
                  ),
                ])
              : _vm._e(),
          ])
        })
      ),
    ]),
    _vm._v(" "),
    _c("fieldset", [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-sm-12" }, [
          _c(
            "div",
            { staticClass: "form-group bg-white" },
            [
              !_vm.manipulacaoContext.isDesabilitado
                ? _c("buttonScComponent", {
                    attrs: {
                      buttonName:
                        _vm.itemType == 0
                          ? _vm.$t(
                              "__.Components.parent.manipulacao.itemTable_vue_html.addAtivo"
                            )
                          : _vm.$t(
                              "__.Components.parent.manipulacao.itemTable_vue_html.add"
                            ),
                      classButton: "btn btn-primary",
                      classIcon: "fa fa-plus-circle",
                      shortKeyBt: _vm.isActiveTab ? "d" : null,
                    },
                    on: { click: _vm.addEmptyRow },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.itemType == 0 && !_vm.manipulacaoContext.isDesabilitado
                ? _c("buttonScComponent", {
                    attrs: {
                      buttonName: _vm.$t(
                        "__.Components.parent.manipulacao.itemTable_vue_html.addFormBase"
                      ),
                      classButton: "btn btn-primary",
                      classIcon: "fa fa-plus-circle",
                      shortKeyBt: "b",
                    },
                    on: { click: _vm.onAdicionarFormulaBase },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.itemType == 1 &&
              _vm.itens.length == 0 &&
              !_vm.manipulacaoContext.isDesabilitado
                ? _c("buttonScComponent", {
                    attrs: {
                      buttonName: _vm.$t(
                        "__.Components.parent.manipulacao.itemTable_vue_html.addKitEmb"
                      ),
                      classButton: "btn btn-primary",
                      classIcon: "fa fa-plus-circle",
                      shortKeyBt: "k",
                    },
                    on: { click: _vm.onAdicionarEmbalagem },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.itemType == 2 &&
              _vm.itens.length == 0 &&
              !_vm.manipulacaoContext.isDesabilitado
                ? _c("buttonScComponent", {
                    attrs: {
                      buttonName: _vm.$t(
                        "__.Components.parent.manipulacao.itemTable_vue_html.addCapsula"
                      ),
                      classButton: "btn btn-primary",
                      classIcon: "fa fa-plus-circle",
                      shortKeyBt: "p",
                    },
                    on: { click: _vm.onAdicionarCapsula },
                  })
                : _vm._e(),
            ],
            1
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }