import BaseModel from "./base/baseModel";

export default class ProdutoLogFinanceiroModel extends BaseModel {
    id?: number = null;

    valorCusto?: number = null;
    custoMedio?: number = null;
    valorVenda?: number = null;
    custoReferencia?: number = null;
    markup?: number = null;

    produtoId: number = null;
    usuarioCriacaoId?: number = null;
    usuarioCriacaoDescricao: string = null;
    dataCriacao: Date = null;
    rotinaAlteracao: string = null;
}
