import Vue from "vue";

import checkboxComponent from "@/components/child/form/checkbox.vue";
import comboComponent from "@/components/child/form/combo.vue";
import dataTooltipComponent from "@/components/child/form/datatooltip.vue";
import dateTimePickerComponent from "@/components/child/form/datetimepicker.vue";
import fieldsetComponent from "@/components/child/form/fieldset.vue";
import moedaComponent from "@/components/child/form/moeda.vue";
import gridComponent from "@/components/child/grid/grid.vue";
import { Component, Prop, Watch } from "@/decorators";
import Alinhamento from "@/models/enum/alinhamento";
import BasicColor from "@/models/enum/basicColor";
import EnumExtensions from "@/models/enum/extensions/enumExtensions";
import CodigoBarras from "@/models/enum/modeloImpressao/codigoBarras";
import Posicao from "@/models/enum/posicaoComplemento";
import ModeloImpressaoCampos from "@/models/modeloImpressao/modeloImpressaoCamposModel";

import "../crud/crud.scss";

@Component({
    components: {
        comboComponent,
        fieldsetComponent,
        dateTimePickerComponent,
        dataTooltipComponent,
        moedaComponent,
        gridComponent,
        checkboxComponent,
    },
})
export default class CampoEditComponent extends Vue {
    constructor() {
        super();
    }

    @Prop({ type: Array, required: true }) dadosOptions;
    @Prop({ type: Array, required: true }) sessaoCampos;

    model: ModeloImpressaoCampos = new ModeloImpressaoCampos();
    basicColorOptions: Array<Object> = EnumExtensions.getNamesAndValuesOrderedByNames(BasicColor);
    posicaoOptions: Array<Object> = EnumExtensions.getNamesAndValuesOrderedByNames(Posicao);
    alinhamentoOptions: Array<Object> = EnumExtensions.getNamesAndValuesOrderedByNames(Alinhamento);
    codigoDeBarrasOptions: Array<Object> = EnumExtensions.getNamesAndValuesOrderedByValues(CodigoBarras);
    camposComplementoOptions: Array<Object> = [];
    camposSubstitutoOptions: Array<Object> = [];
    complementoCampo = false;
    dados = 0;
    dadosSubstituto = 0;
    indexCampoComplemento: number = null;
    indexCampoSubstituto: number = null;
    utilizarLimite = false;

    public loadModal(campo: ModeloImpressaoCampos) {
        this.clear();

        this.model.updateFrom(campo);

        if (this.model.modeloImpressaoCampoComplemento != null) {
            this.dados = this.model.modeloImpressaoCampoComplemento.parentIndex;
            this.camposComplementoOptions = (this.sessaoCampos as Array<ModeloImpressaoCampos>)
                .filter(p => !p.header && p.parentIndex == this.dados)
                .map(item => {
                    return {
                        value: item.index,
                        text: item.labelName,
                    };
                });
            this.indexCampoComplemento = this.model.modeloImpressaoCampoComplemento.index;
            this.complementoCampo = true;
        }

        if (this.model.modeloImpressaoCampoSubstituto != null) {
            this.dadosSubstituto = this.model.modeloImpressaoCampoSubstituto.parentIndex;
            this.camposSubstitutoOptions = (this.sessaoCampos as Array<ModeloImpressaoCampos>)
                .filter(p => !p.header && p.parentIndex == this.dadosSubstituto)
                .map(item => {
                    return {
                        value: item.index,
                        text: item.labelName,
                    };
                });
            this.indexCampoSubstituto = this.model.modeloImpressaoCampoSubstituto.index;
        }

        if (this.model.colorText == null) {
            this.model.colorText = 0;
        }

        if (this.model.colorBGText == null || (this.model.colorBGText == 0 && this.model.colorText == 0)) {
            this.model.colorBGText = 14;
        }
    }

    public clear() {
        this.model = null;
        this.model = new ModeloImpressaoCampos();
        this.indexCampoComplemento = null;
        this.indexCampoSubstituto = null;
        this.complementoCampo = false;
        this.dados = 0;
        this.dadosSubstituto = 0;
        this.utilizarLimite = false;
        this.model.alinhamento = 0;

        this.camposComplementoOptions = (this.sessaoCampos as Array<ModeloImpressaoCampos>)
            .filter(p => !p.header && p.parentIndex == this.dados)
            .map(item => {
                return {
                    value: item.index,
                    text: item.labelName,
                };
            });
        this.camposSubstitutoOptions = (this.sessaoCampos as Array<ModeloImpressaoCampos>)
            .filter(p => !p.header && p.parentIndex == this.dadosSubstituto)
            .map(item => {
                return {
                    value: item.index,
                    text: item.labelName,
                };
            });
    }

    public getModel() {
        //cria o modelo dos campos adicionais e etc
        if (this.complementoCampo && this.indexCampoComplemento != null && this.indexCampoComplemento > 0) {
            const camposAux = (this.sessaoCampos as Array<ModeloImpressaoCampos>).filter(
                p => p.index == this.indexCampoComplemento,
            );

            if (camposAux.length > 0) {
                let alterouComplemento = true;
                if (this.model.modeloImpressaoCampoComplemento != null) {
                    alterouComplemento = this.model.modeloImpressaoCampoComplemento.labelId != camposAux[0].labelId;
                }
                //cria modelo novo ou substitui completamente
                if (this.model.modeloImpressaoCampoComplementoId == null || alterouComplemento) {
                    this.model.modeloImpressaoCampoComplemento = new ModeloImpressaoCampos();
                    this.model.modeloImpressaoCampoComplemento.updateFrom(camposAux[0]);
                    this.model.modeloImpressaoCampoComplemento.colorText = this.model.colorText;
                    this.model.modeloImpressaoCampoComplemento.colorBGText = this.model.colorBGText;
                    this.model.modeloImpressaoCampoComplemento.bold = this.model.bold;
                    this.model.modeloImpressaoCampoComplemento.adicional = true;
                    this.model.modeloImpressaoCampoComplemento.modeloImpressaoSessaoId = null;
                    this.model.modeloImpressaoCampoComplementoId = this.model.modeloImpressaoCampoComplemento.id;
                } else {
                    this.model.modeloImpressaoCampoComplemento.colorText = this.model.colorText;
                    this.model.modeloImpressaoCampoComplemento.colorBGText = this.model.colorBGText;
                    this.model.modeloImpressaoCampoComplemento.bold = this.model.bold;
                }
            }
        }

        if (!this.complementoCampo) {
            this.model.modeloImpressaoCampoComplementoId = null;
            this.model.modeloImpressaoCampoComplemento = null;
        }

        if (this.indexCampoSubstituto != null && this.indexCampoSubstituto > 0) {
            const camposAux = (this.sessaoCampos as Array<ModeloImpressaoCampos>).filter(
                p => p.index == this.indexCampoSubstituto,
            );
            if (camposAux.length > 0) {
                let alterouSubstituto = true;
                if (this.model.modeloImpressaoCampoSubstituto != null) {
                    alterouSubstituto = this.model.modeloImpressaoCampoSubstituto.labelId != camposAux[0].labelId;
                }
                if (this.model.modeloImpressaoCampoSubstitutoId == null || alterouSubstituto) {
                    this.model.modeloImpressaoCampoSubstituto = new ModeloImpressaoCampos();
                    this.model.modeloImpressaoCampoSubstituto.updateFrom(camposAux[0]);
                    this.model.modeloImpressaoCampoSubstituto.colorText = this.model.colorText;
                    this.model.modeloImpressaoCampoSubstituto.colorBGText = this.model.colorBGText;
                    this.model.modeloImpressaoCampoSubstituto.bold = this.model.bold;
                    this.model.modeloImpressaoCampoSubstituto.substituto = true;
                    this.model.modeloImpressaoCampoSubstituto.modeloImpressaoSessaoId = null;
                    this.model.modeloImpressaoCampoSubstitutoId = this.model.modeloImpressaoCampoSubstituto.id;
                } else {
                    this.model.modeloImpressaoCampoSubstituto.colorText = this.model.colorText;
                    this.model.modeloImpressaoCampoSubstituto.colorBGText = this.model.colorBGText;
                    this.model.modeloImpressaoCampoSubstituto.bold = this.model.bold;
                }
            }
        } else {
            this.model.modeloImpressaoCampoSubstituto = null;
            this.model.modeloImpressaoCampoSubstitutoId = null;
        }

        if (!this.utilizarLimite) {
            this.model.limiteCaracteres = null;
        }

        if (this.model.alinhamento == null || this.model.alinhamento < 0) {
            this.model.alinhamento = Alinhamento.Esquerda;
        }

        return this.model;
    }

    @Watch("dados")
    private onChangedDados() {
        if (this.complementoCampo) {
            this.camposComplementoOptions = (this.sessaoCampos as Array<ModeloImpressaoCampos>)
                .filter(p => !p.header && p.parentIndex == this.dados)
                .map(item => {
                    return {
                        value: item.index,
                        text: item.labelName,
                    };
                });
        }
    }

    @Watch("dadosSubstituto")
    private onChangedDadosSubstituto() {
        this.camposSubstitutoOptions = (this.sessaoCampos as Array<ModeloImpressaoCampos>)
            .filter(p => !p.header && p.parentIndex == this.dadosSubstituto)
            .map(item => {
                return {
                    value: item.index,
                    text: item.labelName,
                };
            });
    }
}
