import { GridColumn, GridColumnType } from "@/components/child/grid/gridColumn";
import { Component } from "@/decorators";
import BancoModel from "@/models/bancoModel";
import BancoService from "@/services/bancoService";

import ListComponentBase from "../listComponentBase";

@Component
export default class BancoListComponent extends ListComponentBase<BancoModel> {
    get gridColumns(): Array<GridColumn> {
        return [
            new GridColumn("codigo", this.$t("__.ts.codigo"), GridColumnType.String),
            new GridColumn("nome", this.$t("__.ts.nome"), GridColumnType.String),
            new GridColumn("ativo", this.$t("__.ts.ativo"), GridColumnType.Boolean),
        ];
    }

    private beforeMount() {
        this.setProps(new BancoService(), "banco", "nome");
    }
}
