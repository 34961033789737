import Vue from "vue";

import { Component, Prop } from "@/decorators";

import "../modal/modal.scss";
import "../../parent/crud/crud.scss";
import "./actionBar.scss";

@Component
export default class ActionBarComponent extends Vue {
    @Prop({ type: Number, default: 1 }) slots: number;
    @Prop({ type: Boolean, default: true }) showSave: boolean;
    @Prop({ type: Boolean, default: true }) showBack: boolean;

    @Prop(String) title: string;
    @Prop(String) subTitle: string;
    @Prop(String) titleHtml: string;

    @Prop({ type: String, default: "" }) saveText: string;

    private isOpen = false;
    private slotsKeys = [];
    private modalSlot = null;
    private modalTitle = null;
    private modalClass = null;
    private modalCloseCallback = () => {};

    private saveTextInternal = this.$t("__.Crud.salvar") as string;

    private openModal(modalSlot: number, modalTitle = "", modalClass = "lm-modal", fn = () => {}) {
        this.isOpen = true;
        this.modalSlot = modalSlot;
        this.modalTitle = modalTitle;
        this.modalClass = modalClass;
        this.modalCloseCallback = fn;
    }

    private onClose() {
        this.isOpen = false;
        this.modalSlot = null;
        this.modalCloseCallback();
    }

    private onSave() {
        this.$emit("onSave");
    }

    private mounted() {
        this.slotsKeys = Array.from(Array(this.slots).keys());

        if (this.saveText) {
            this.saveTextInternal = this.saveText;
        }
    }
}
