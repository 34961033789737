import { Component } from "@/decorators";
import NbmModel from "@/models/produto/nbmModel";
import NbmService from "@/services/produto/nbmService";

import EditComponentBase from "../editComponentBase";

@Component
export default class NbmEditComponent extends EditComponentBase<NbmModel> {
    private async beforeMount() {
        await this.setProps(new NbmService(), "nbms", new NbmModel());
    }
}
