import Vue from "vue";

import actionBarComponent from "@/components/child/actionBar/actionBar.vue";
import comboComponent from "@/components/child/form/combo.vue";
import dateTimePickerComponent from "@/components/child/form/datetimepicker.vue";
import fieldsetComponent from "@/components/child/form/fieldset.vue";
import moedaComponent from "@/components/child/form/moeda.vue";
import gridComponent from "@/components/child/grid/grid.vue";
import { GridColumn, GridColumnType } from "@/components/child/grid/gridColumn";
import LoadingModalComponent from "@/components/child/loadingmodal/loadingmodal";
import loadingModalComponent from "@/components/child/loadingmodal/loadingmodal.vue";
import { Component, Watch } from "@/decorators";
import StatusContas from "@/models/enum/statusContas";
import ContaCorrenteModel from "@/models/financeiro/contaCorrenteModel";
import ContaPagarModel, { ContaPagarListParameters } from "@/models/financeiro/contaPagarModel";
import ContaPagarMultiplasModel from "@/models/financeiro/contaPagarMultiplasModel";
import ContaPagarPagamentoModel from "@/models/financeiro/contaPagarPagamentoModel";
import FormaPagamentoModel from "@/models/financeiro/formaPagamentoModel";
import PaginationModel from "@/models/paginationModel";
import ValidationErrorModel from "@/models/validationErrorModel";
import ContaCorrenteService from "@/services/financeiro/contaCorrenteService";
import ContaPagarService from "@/services/financeiro/contaPagarService";
import FormaPagamentoService from "@/services/financeiro/formaPagamentoService";
import MovimentacaoCaixaService from "@/services/financeiro/movimentacaoCaixaService";
import ValidationErrorWrapper from "@/wrappers/validationErrorWrapper";

import "../crud.scss";
import "./edit.scss";

@Component({
    components: {
        comboComponent,
        fieldsetComponent,
        moedaComponent,
        dateTimePickerComponent,
        gridComponent,
        loadingModalComponent,
        actionBarComponent,
    },
})
export default class ContaPagarMultiplasEditComponent extends Vue {
    private service = new ContaPagarService();
    private validationErrorWrapper = new ValidationErrorWrapper(this.$validator);
    private loadingModalComponent: LoadingModalComponent = null;

    model: Array<ContaPagarMultiplasModel> = [];
    modelIds: Array<number> = [];
    saldoPagar = 0;

    idCaixaSugestao = 0;
    dataPagamento: Date = null;
    contaCorrenteId = 0;
    formaPagamentoId = 0;
    juros = 0;
    descontos = 0;
    valorPago = 0;

    contaCorrenteOptions: Array<Object> = [];
    formaPagamentoOptions: Array<Object> = [];

    get gridColumns(): Array<GridColumn> {
        return [
            new GridColumn("contaGerencialDescricao", "Conta Gerencial", GridColumnType.String),
            new GridColumn("dataVencimento", "Vencimento", GridColumnType.Date),
            new GridColumn("saldo", "Saldo", GridColumnType.Money),
            new GridColumn("juros", "Juros", GridColumnType.Money, true),
            new GridColumn("desconto", "Desconto", GridColumnType.Money, true),
            new GridColumn("valorPago", "Pago", GridColumnType.Money, true),
        ];
    }

    constructor() {
        super();

        this.dataPagamento = new Date();
    }

    private load() {
        this.model = [];
        this.validationErrorWrapper.clearErrors();
        if (this.idCaixaSugestao > 0) this.contaCorrenteId = this.idCaixaSugestao;

        if (this.modelIds.length > 0) {
            this.loadingModalComponent.showLoading();

            const customParameters: ContaPagarListParameters = {
                statusContas: StatusContas.Aberto,
                filterKeyId: this.modelIds.toString(),
            };
            this.service
                .list("", "dataVencimento", "asc", 1, 999999, customParameters)
                .then(response => {
                    return response.json() as Promise<PaginationModel<ContaPagarModel>>;
                })
                .then(data => {
                    const model = this.model;
                    data.list.forEach(function (data) {
                        const contaPagarMultiplasModel = new ContaPagarMultiplasModel();
                        contaPagarMultiplasModel.updateFrom(data);
                        contaPagarMultiplasModel.valorPago = data.saldo;

                        model.push(contaPagarMultiplasModel);
                    });

                    this.loadingModalComponent.hide();
                })
                .catch(error => {
                    this.loadingModalComponent.hide();
                });
        } else {
            this.loadingModalComponent.hide();
        }
    }

    private async loadContasCorrentes() {
        try {
            const data = await new ContaCorrenteService()
                .combo()
                .then(r => r.json() as Promise<PaginationModel<ContaCorrenteModel>>);
            this.contaCorrenteOptions = data.list.map(item => {
                return { value: item.id, text: item.descricao };
            });
        } catch {}
    }

    private async loadFormasPagamento() {
        try {
            const data = await new FormaPagamentoService()
                .combo()
                .then(r => r.json() as Promise<PaginationModel<FormaPagamentoModel>>);
            this.formaPagamentoOptions = data.list.map(item => {
                return { value: item.id, text: item.descricao };
            });
        } catch {}
    }

    private async loadIdCaixaSegestao() {
        try {
            const data = await new MovimentacaoCaixaService()
                .getIdCaixaSessaoAberta()
                .then(r => r.json() as Promise<number>);
            this.idCaixaSugestao = data;
        } catch {
            this.idCaixaSugestao = 0;
        }
    }

    private save() {
        this.$validator
            .validateAll()
            .then(isValid => {
                if (isValid) {
                    this.loadingModalComponent.showSaving();

                    const pagamentos = new Array<ContaPagarPagamentoModel>();

                    this.model.forEach(function (model) {
                        const pagamento = new ContaPagarPagamentoModel();
                        pagamento.contaCorrenteId = this.contaCorrenteId;
                        pagamento.lancamentoId = model.id;
                        pagamento.dataLancamento = new Date();
                        pagamento.dataMovimento = this.dataPagamento;
                        pagamento.desconto = model.desconto;
                        pagamento.formaPagamentoId = this.formaPagamentoId;
                        pagamento.juros = model.juros;
                        pagamento.valor = model.valorPago;

                        pagamentos.push(pagamento);
                    });

                    this.service
                        .LiquidarPorPagamentos(pagamentos)
                        .then(async response => {
                            this.loadingModalComponent.hide();
                            if (response.ok) {
                                await this.$showSuccess(
                                    this.$t("__.ts.pagamento"),
                                    this.$t("__.ts.registroSalvSucess"),
                                );
                                this.$router.back();
                            } else {
                                return response.json() as Promise<ValidationErrorModel[]>;
                            }
                        })
                        .then(data => {
                            this.validationErrorWrapper.showErrors(data);
                        })
                        .catch(error => {
                            this.loadingModalComponent.hide();
                        });
                }
            })
            .catch(error => {});
    }

    private cancel() {
        this.$router.back();
    }

    private onRemoveItem(model: ContaPagarMultiplasModel) {
        this.model = this.model.filter(p => p != model);
    }

    @Watch("model", { deep: true })
    private onModelChanged() {
        let totalSaldo = 0;
        let totalJuros = 0;
        let totalDescontos = 0;
        let totalPago = 0;

        this.model.forEach(function (model) {
            totalSaldo += +model.saldo;
            totalJuros += +model.juros;
            totalDescontos += +model.desconto;
            totalPago += +model.valorPago + +model.desconto - +model.juros;
        });

        this.juros = totalJuros;
        this.descontos = totalDescontos;
        this.valorPago = totalPago;
        this.saldoPagar = totalSaldo - totalPago;

        if (this.saldoPagar < 0) this.saldoPagar = 0;
    }

    private mounted() {
        this.loadingModalComponent = this.$refs.loadingModalComponent as LoadingModalComponent;
        this.loadingModalComponent.showLoading();

        Promise.all([this.loadIdCaixaSegestao(), this.loadContasCorrentes(), this.loadFormasPagamento()])
            .then(result => {
                if (this.$route.params.ids) this.modelIds = this.$route.params.ids.split(",").map(Number);

                this.load();
            })
            .catch(error => {
                this.loadingModalComponent.hide();
            });
    }
}
