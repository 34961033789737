import { mountHtml, PrinterBackend, PrinterQueue } from "./printerQueue";

export class BrowserPrinterBackend implements PrinterBackend {

    public name = "browser";

    private static printerQueues: PrinterQueue[] = [];

    public async init(): Promise<boolean> {
        if (BrowserPrinterBackend.printerQueues.length) {
            return true;
        }

        const printerQueue = new PrinterQueue("browser", this);
        printerQueue.printerDesc = `${getBrowserName()}`;
        BrowserPrinterBackend.printerQueues.push(printerQueue);
        return true;
    }

    public async getPrinters(): Promise<PrinterQueue[]> {
        return BrowserPrinterBackend.printerQueues;
    }

    public async flushQueue(printerQueue: PrinterQueue): Promise<boolean> {
        const pages = printerQueue.dequeueAll();
        if (!pages.length) {
            return true;
        }

        const iFrame = document.createElement("iframe");

        const closePrint = () => document.body.removeChild(iFrame);

        iFrame.onload = () => {
            iFrame.contentWindow.onbeforeunload = closePrint;
            iFrame.contentWindow.onafterprint = closePrint;
            iFrame.contentWindow.focus(); // Required for IE
            iFrame.contentWindow.print();
        };

        iFrame.style.position = "fixed";
        iFrame.style.right = "0";
        iFrame.style.bottom = "0";
        iFrame.style.width = "0";
        iFrame.style.height = "0";
        iFrame.style.border = "0";

        iFrame.srcdoc = mountHtml(pages.map(page => page.pageHtml).join(""));
        document.body.appendChild(iFrame);

        return true;
    }
}

// Código obtido através de:
//   https://developer.mozilla.org/en-US/docs/Web/API/Window/navigator#example_1_browser_detect_and_return_a_string
function getBrowserName() {
    let sBrowser: string;
    const sUsrAg = navigator.userAgent;

    // The order matters here, and this may report false positives for unlisted browsers.

    if (sUsrAg.indexOf("Firefox") > -1) {
        sBrowser = "Firefox";
    } else if (sUsrAg.indexOf("Opera") > -1 || sUsrAg.indexOf("OPR") > -1) {
        sBrowser = "Opera";
    } else if (sUsrAg.indexOf("Edge") > -1) {
        sBrowser = "Edge Legacy";
    } else if (sUsrAg.indexOf("Edg") > -1) {
        sBrowser = "Edge";
    } else if (sUsrAg.indexOf("Chrome") > -1) {
        sBrowser = "Chrome";
    } else if (sUsrAg.indexOf("Safari") > -1) {
        sBrowser = "Safari";
    } else {
        sBrowser = "???";
    }

    return sBrowser;
}
