var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "crud crud-edit" },
    [
      !_vm.hideExtras
        ? _c(
            "actionBarComponent",
            {
              attrs: {
                slots: 3,
                showSave: !_vm.somenteConsulta,
                title: _vm.$t("__.Crud.cliente.edit_vue_html.clienteEdit"),
              },
              on: { onSave: _vm.save },
            },
            [
              _vm.model.id
                ? _c(
                    "div",
                    {
                      attrs: {
                        slot: "buttonSlot0",
                        title: _vm.$t("__.ts.visuVendasClient"),
                      },
                      on: { click: _vm.onOpenVendasModal },
                      slot: "buttonSlot0",
                    },
                    [
                      _c("i", {
                        staticClass: "fa fa-clipboard-list",
                        attrs: { "aria-hidden": "true" },
                      }),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.model.id
                ? _c(
                    "div",
                    {
                      attrs: {
                        slot: "buttonSlot1",
                        title: _vm.$t("__.Crud.imprimir"),
                      },
                      on: { click: _vm.print },
                      slot: "buttonSlot1",
                    },
                    [
                      _c("i", {
                        staticClass: "fa fa-print",
                        attrs: { "aria-hidden": "true" },
                      }),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.model.id &&
              _vm.VALIDAR_PERMISSAO_USUARIO("clientes", "delete")
                ? _c(
                    "div",
                    {
                      attrs: {
                        slot: "buttonSlot2",
                        title: _vm.$t("__.Crud.excluir"),
                      },
                      on: { click: _vm.onDelete },
                      slot: "buttonSlot2",
                    },
                    [
                      _c("i", {
                        staticClass: "fa fa-times",
                        attrs: { "aria-hidden": "true" },
                      }),
                    ]
                  )
                : _vm._e(),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "form",
        {
          attrs: { novalidate: "novalidate" },
          on: {
            submit: function ($event) {
              $event.preventDefault()
              _vm.save()
            },
          },
        },
        [
          _c(
            "fieldset-component",
            {
              attrs: {
                title: _vm.$t("__.Crud.cliente.edit_vue_html.dadosGerais"),
                collapsed: false,
              },
            },
            [
              _c(
                "div",
                { attrs: { slot: "rows", id: "cliente" }, slot: "rows" },
                [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-sm-3" }, [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: _vm.errors.first("tipoPessoa"),
                              expression: "errors.first('tipoPessoa')",
                            },
                          ],
                          staticClass: "form-group",
                        },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "control-label",
                              attrs: {
                                for: "tipoPessoa",
                                title: _vm.$t("__.obrigatorio"),
                              },
                            },
                            [
                              _c("span", { staticStyle: { color: "red" } }, [
                                _vm._v("*"),
                              ]),
                              _vm._v(
                                "\n                                " +
                                  _vm._s(
                                    _vm.$t("__.Crud.cliente.edit_vue_html.tipo")
                                  ) +
                                  "\n                            "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("combo-component", {
                            directives: [
                              { name: "focus", rawName: "v-focus" },
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required",
                                expression: "'required'",
                              },
                            ],
                            attrs: {
                              id: "tipoPessoa",
                              name: "tipoPessoa",
                              data: _vm.tiposPessoaOptions,
                              searchable: false,
                              disabled: _vm.somenteConsulta,
                            },
                            model: {
                              value: _vm.model.tipoPessoa,
                              callback: function ($$v) {
                                _vm.$set(_vm.model, "tipoPessoa", $$v)
                              },
                              expression: "model.tipoPessoa",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              (!_vm.model.exterior &&
                                _vm.model.tipoPessoa ==
                                  _vm.tiposPessoa.Fisica) ||
                              _vm.model.tipoPessoa ==
                                _vm.tiposPessoa.ProdutorRural,
                            expression:
                              "\n                            (!model.exterior && model.tipoPessoa == tiposPessoa.Fisica) ||\n                            model.tipoPessoa == tiposPessoa.ProdutorRural\n                        ",
                          },
                        ],
                        staticClass: "col-sm-4",
                      },
                      [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: _vm.errors.first("cpf"),
                                expression: "errors.first('cpf')",
                              },
                            ],
                            staticClass: "form-group",
                          },
                          [
                            _c(
                              "label",
                              {
                                staticClass: "control-label",
                                attrs: { for: "cpf" },
                              },
                              [
                                _vm.cpfObrigatorio
                                  ? _c(
                                      "span",
                                      { staticStyle: { color: "red" } },
                                      [_vm._v("*")]
                                    )
                                  : _vm._e(),
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(
                                      _vm.$t(
                                        "__.Crud.cliente.edit_vue_html.cpf"
                                      )
                                    ) +
                                    "\n                            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("cpf-component", {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: _vm.cpfObrigatorio ? "required" : null,
                                  expression:
                                    "cpfObrigatorio ? 'required' : null",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: {
                                id: "cpf",
                                name: "cpf",
                                disabled: _vm.somenteConsulta,
                              },
                              on: { input: _vm.onBlurCpf },
                              model: {
                                value: _vm.model.cpf,
                                callback: function ($$v) {
                                  _vm.$set(_vm.model, "cpf", $$v)
                                },
                                expression: "model.cpf",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              (!_vm.model.exterior &&
                                _vm.model.tipoPessoa ==
                                  _vm.tiposPessoa.Fisica) ||
                              _vm.model.tipoPessoa ==
                                _vm.tiposPessoa.ProdutorRural,
                            expression:
                              "\n                            (!model.exterior && model.tipoPessoa == tiposPessoa.Fisica) ||\n                            model.tipoPessoa == tiposPessoa.ProdutorRural\n                        ",
                          },
                        ],
                        staticClass: "col-sm-4",
                      },
                      [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: _vm.errors.first("rg"),
                                expression: "errors.first('rg')",
                              },
                            ],
                            staticClass: "form-group",
                          },
                          [
                            _c(
                              "label",
                              {
                                staticClass: "control-label",
                                attrs: { for: "rg" },
                              },
                              [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(
                                      _vm.$t("__.Crud.cliente.edit_vue_html.rg")
                                    ) +
                                    "\n                            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.model.rg,
                                  expression: "model.rg",
                                },
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "alpha_num|max:20",
                                  expression: "'alpha_num|max:20'",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: {
                                autocomplete: "off",
                                type: "text",
                                id: "rg",
                                name: "rg",
                                disabled: _vm.somenteConsulta,
                              },
                              domProps: { value: _vm.model.rg },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(_vm.model, "rg", $event.target.value)
                                },
                              },
                            }),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              !_vm.model.exterior &&
                              _vm.model.tipoPessoa == _vm.tiposPessoa.Juridica,
                            expression:
                              "!model.exterior && model.tipoPessoa == tiposPessoa.Juridica",
                          },
                        ],
                        staticClass: "col-sm-4",
                      },
                      [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: _vm.errors.first("cnpj"),
                                expression: "errors.first('cnpj')",
                              },
                            ],
                            staticClass: "form-group",
                          },
                          [
                            _c(
                              "label",
                              {
                                staticClass: "control-label",
                                attrs: {
                                  for: "cnpj",
                                  title: _vm.$t("__.obrigatorio"),
                                },
                              },
                              [
                                _c("span", { staticStyle: { color: "red" } }, [
                                  _vm._v("*"),
                                ]),
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(
                                      _vm.$t(
                                        "__.Crud.cliente.edit_vue_html.cnpj"
                                      )
                                    ) +
                                    "\n                            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("cnpj-component", {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: {
                                    rules: {
                                      cnpj: true,
                                      required:
                                        !_vm.model.exterior &&
                                        _vm.model.tipoPessoa ==
                                          _vm.tiposPessoa.Juridica,
                                    },
                                  },
                                  expression:
                                    "{\n                                    rules: {\n                                        cnpj: true,\n                                        required: !model.exterior && model.tipoPessoa == tiposPessoa.Juridica,\n                                    },\n                                }",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: {
                                id: "cnpj",
                                name: "cnpj",
                                disabled: _vm.somenteConsulta,
                              },
                              model: {
                                value: _vm.model.cnpj,
                                callback: function ($$v) {
                                  _vm.$set(_vm.model, "cnpj", $$v)
                                },
                                expression: "model.cnpj",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              (!_vm.model.exterior &&
                                _vm.model.tipoPessoa ==
                                  _vm.tiposPessoa.Juridica) ||
                              _vm.model.tipoPessoa ==
                                _vm.tiposPessoa.ProdutorRural,
                            expression:
                              "\n                            (!model.exterior && model.tipoPessoa == tiposPessoa.Juridica) ||\n                            model.tipoPessoa == tiposPessoa.ProdutorRural\n                        ",
                          },
                        ],
                        staticClass: "col-sm-2",
                      },
                      [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: _vm.errors.first("inscricaoEstadual"),
                                expression: "errors.first('inscricaoEstadual')",
                              },
                            ],
                            staticClass: "form-group",
                          },
                          [
                            _c(
                              "label",
                              {
                                staticClass: "control-label",
                                attrs: { for: "inscricaoEstadual" },
                              },
                              [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(
                                      _vm.$t("__.Crud.cliente.edit_vue_html.ie")
                                    ) +
                                    "\n                            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("data-tooltip-component", {
                              attrs: {
                                text: _vm.$t(
                                  "__.Crud.cliente.edit_vue_html.clientIsentoEmBranco"
                                ),
                              },
                            }),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.model.inscricaoEstadual,
                                  expression: "model.inscricaoEstadual",
                                },
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "inscricaoEstadual|max:20",
                                  expression: "'inscricaoEstadual|max:20'",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: {
                                autocomplete: "off",
                                type: "text",
                                id: "inscricaoEstadual",
                                name: "inscricaoEstadual",
                                placeholder: "___-_______",
                                disabled: _vm.somenteConsulta,
                              },
                              domProps: { value: _vm.model.inscricaoEstadual },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.model,
                                    "inscricaoEstadual",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              !_vm.model.exterior &&
                              _vm.model.tipoPessoa == _vm.tiposPessoa.Juridica,
                            expression:
                              "!model.exterior && model.tipoPessoa == tiposPessoa.Juridica",
                          },
                        ],
                        staticClass: "col-sm-2",
                      },
                      [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: _vm.errors.first("contribuinteEstadual"),
                                expression:
                                  "errors.first('contribuinteEstadual')",
                              },
                            ],
                            staticClass: "form-group",
                          },
                          [
                            _c(
                              "label",
                              {
                                staticClass: "control-label",
                                attrs: { for: "contribuinteEstadual" },
                              },
                              [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(
                                      _vm.$t(
                                        "__.Crud.cliente.edit_vue_html.contribuinteEstadual"
                                      )
                                    ) +
                                    "\n                            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("checkbox-component", {
                              attrs: {
                                id: "contribuinteEstadual",
                                name: "contribuinteEstadual",
                                disabled: _vm.somenteConsulta,
                              },
                              model: {
                                value: _vm.model.contribuinteEstadual,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.model,
                                    "contribuinteEstadual",
                                    $$v
                                  )
                                },
                                expression: "model.contribuinteEstadual",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _vm.model.exterior
                      ? _c("div", { staticClass: "col-sm-8" }, [
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "tooltip",
                                  rawName: "v-tooltip",
                                  value: _vm.errors.first("idEstrangeiro"),
                                  expression: "errors.first('idEstrangeiro')",
                                },
                              ],
                              staticClass: "form-group",
                            },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "control-label",
                                  attrs: {
                                    for: "idEstrangeiro",
                                    title: _vm.$t("__.obrigatorio"),
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    { staticStyle: { color: "red" } },
                                    [_vm._v("*")]
                                  ),
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(
                                        _vm.$t(
                                          "__.Crud.cliente.edit_vue_html.documentoEstrangeiro"
                                        )
                                      ) +
                                      "\n                            "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("data-tooltip-component", {
                                attrs: {
                                  text: "Número do passaporte, ou outro documento legal do estrangeiro",
                                },
                              }),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.model.idEstrangeiro,
                                    expression: "model.idEstrangeiro",
                                  },
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: "required",
                                    expression: "'required'",
                                  },
                                ],
                                staticClass: "form-control",
                                attrs: {
                                  autocomplete: "off",
                                  id: "idEstrangeiro",
                                  name: "idEstrangeiro",
                                  disabled: _vm.somenteConsulta,
                                },
                                domProps: { value: _vm.model.idEstrangeiro },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.model,
                                      "idEstrangeiro",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-sm-1" }, [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: _vm.errors.first("exterior"),
                              expression: "errors.first('exterior')",
                            },
                          ],
                          staticClass: "form-group",
                        },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "control-label",
                              attrs: { for: "exterior" },
                            },
                            [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(
                                    _vm.$t(
                                      "__.Crud.cliente.edit_vue_html.exterior"
                                    )
                                  ) +
                                  "\n                            "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("checkbox-component", {
                            attrs: {
                              id: "exterior",
                              name: "exterior",
                              disabled: _vm.somenteConsulta,
                            },
                            model: {
                              value: _vm.model.exterior,
                              callback: function ($$v) {
                                _vm.$set(_vm.model, "exterior", $$v)
                              },
                              expression: "model.exterior",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              _vm.model.tipoPessoa == _vm.tiposPessoa.Fisica ||
                              _vm.model.tipoPessoa ==
                                _vm.tiposPessoa.ProdutorRural,
                            expression:
                              "\n                            model.tipoPessoa == tiposPessoa.Fisica || model.tipoPessoa == tiposPessoa.ProdutorRural\n                        ",
                          },
                        ],
                        staticClass: "col-sm-6",
                      },
                      [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: _vm.errors.first("nome"),
                                expression: "errors.first('nome')",
                              },
                            ],
                            staticClass: "form-group",
                          },
                          [
                            _c(
                              "label",
                              {
                                staticClass: "control-label",
                                attrs: {
                                  for: "nome",
                                  title: _vm.$t("__.obrigatorio"),
                                },
                              },
                              [
                                _c("span", { staticStyle: { color: "red" } }, [
                                  _vm._v("*"),
                                ]),
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(
                                      _vm.$t(
                                        "__.Crud.cliente.edit_vue_html.nome"
                                      )
                                    ) +
                                    "\n                            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.model.nome,
                                  expression: "model.nome",
                                },
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: {
                                    rules: {
                                      max: 256,
                                      required:
                                        _vm.model.tipoPessoa ==
                                          _vm.tiposPessoa.Fisica ||
                                        _vm.model.tipoPessoa ==
                                          _vm.tiposPessoa.ProdutorRural,
                                    },
                                  },
                                  expression:
                                    "{\n                                    rules: {\n                                        max: 256,\n                                        required:\n                                            model.tipoPessoa == tiposPessoa.Fisica ||\n                                            model.tipoPessoa == tiposPessoa.ProdutorRural,\n                                    },\n                                }",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: {
                                autocomplete: "off",
                                type: "text",
                                id: "nome",
                                name: "nome",
                                disabled: _vm.somenteConsulta,
                              },
                              domProps: { value: _vm.model.nome },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.model,
                                    "nome",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              _vm.model.tipoPessoa == _vm.tiposPessoa.Juridica,
                            expression:
                              "model.tipoPessoa == tiposPessoa.Juridica",
                          },
                        ],
                        staticClass: "col-sm-6",
                      },
                      [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: _vm.errors.first("nomeFantasia"),
                                expression: "errors.first('nomeFantasia')",
                              },
                            ],
                            staticClass: "form-group",
                          },
                          [
                            _c(
                              "label",
                              {
                                staticClass: "control-label",
                                attrs: {
                                  for: "nomeFantasia",
                                  title: _vm.$t("__.obrigatorio"),
                                },
                              },
                              [
                                _c("span", { staticStyle: { color: "red" } }, [
                                  _vm._v("*"),
                                ]),
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(
                                      _vm.$t(
                                        "__.Crud.cliente.edit_vue_html.MASCULINO"
                                      )
                                    ) +
                                    "\n                            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.model.nomeFantasia,
                                  expression: "model.nomeFantasia",
                                },
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: {
                                    rules: {
                                      max: 256,
                                      required:
                                        _vm.model.tipoPessoa ==
                                        _vm.tiposPessoa.Juridica,
                                    },
                                  },
                                  expression:
                                    "{\n                                    rules: { max: 256, required: model.tipoPessoa == tiposPessoa.Juridica },\n                                }",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: {
                                autocomplete: "off",
                                type: "text",
                                id: "nomeFantasia",
                                name: "nomeFantasia",
                                disabled: _vm.somenteConsulta,
                              },
                              domProps: { value: _vm.model.nomeFantasia },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.model,
                                    "nomeFantasia",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-sm-5" }, [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: _vm.errors.first("nomeRotulo"),
                              expression: "errors.first('nomeRotulo')",
                            },
                          ],
                          staticClass: "form-group",
                        },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "control-label",
                              attrs: {
                                for: "nomeRotulo",
                                title: _vm.$t("__.obrigatorio"),
                              },
                            },
                            [
                              _c("span", { staticStyle: { color: "red" } }, [
                                _vm._v("*"),
                              ]),
                              _vm._v(
                                "\n                                " +
                                  _vm._s(
                                    _vm.$t(
                                      "__.Crud.cliente.edit_vue_html.nomeRotulo"
                                    )
                                  ) +
                                  "\n                            "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("data-tooltip-component", {
                            attrs: {
                              text: _vm.$t(
                                "__.Crud.cliente.edit_vue_html.nomeQueRotulo"
                              ),
                            },
                          }),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.model.nomeRotulo,
                                expression: "model.nomeRotulo",
                              },
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required|max:256",
                                expression: "'required|max:256'",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: {
                              autocomplete: "off",
                              type: "text",
                              id: "nomeRotulo",
                              name: "nomeRotulo",
                              disabled: _vm.somenteConsulta,
                            },
                            domProps: { value: _vm.model.nomeRotulo },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.model,
                                  "nomeRotulo",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-sm-1" }, [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: _vm.errors.first("ativo"),
                              expression: "errors.first('ativo')",
                            },
                          ],
                          staticClass: "form-group",
                        },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "control-label",
                              attrs: { for: "ativo" },
                            },
                            [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(
                                    _vm.$t(
                                      "__.Crud.cliente.edit_vue_html.ativo"
                                    )
                                  ) +
                                  "\n                            "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("checkbox-component", {
                            attrs: {
                              id: "ativo",
                              name: "ativo",
                              disabled: _vm.somenteConsulta,
                            },
                            model: {
                              value: _vm.model.ativo,
                              callback: function ($$v) {
                                _vm.$set(_vm.model, "ativo", $$v)
                              },
                              expression: "model.ativo",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              _vm.model.tipoPessoa == _vm.tiposPessoa.Juridica,
                            expression:
                              "model.tipoPessoa == tiposPessoa.Juridica",
                          },
                        ],
                        staticClass: "col-sm-8",
                      },
                      [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: _vm.errors.first("razaoSocial"),
                                expression: "errors.first('razaoSocial')",
                              },
                            ],
                            staticClass: "form-group",
                          },
                          [
                            _c(
                              "label",
                              {
                                staticClass: "control-label",
                                attrs: {
                                  for: "razaoSocial",
                                  title: _vm.$t("__.obrigatorio"),
                                },
                              },
                              [
                                _c("span", { staticStyle: { color: "red" } }, [
                                  _vm._v("*"),
                                ]),
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(
                                      _vm.$t(
                                        "__.Crud.cliente.edit_vue_html.razaoSocial"
                                      )
                                    ) +
                                    "\n                            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.model.razaoSocial,
                                  expression: "model.razaoSocial",
                                },
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: {
                                    rules: {
                                      max: 256,
                                      required:
                                        _vm.model.tipoPessoa ==
                                        _vm.tiposPessoa.Juridica,
                                    },
                                  },
                                  expression:
                                    "{\n                                    rules: { max: 256, required: model.tipoPessoa == tiposPessoa.Juridica },\n                                }",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: {
                                autocomplete: "off",
                                type: "text",
                                id: "razaoSocial",
                                name: "razaoSocial",
                                disabled: _vm.somenteConsulta,
                              },
                              domProps: { value: _vm.model.razaoSocial },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.model,
                                    "razaoSocial",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              _vm.model.tipoPessoa ==
                                _vm.tiposPessoa.Juridica ||
                              _vm.model.tipoPessoa ==
                                _vm.tiposPessoa.ProdutorRural,
                            expression:
                              "\n                            model.tipoPessoa == tiposPessoa.Juridica ||\n                            model.tipoPessoa == tiposPessoa.ProdutorRural\n                        ",
                          },
                        ],
                        staticClass: "col-sm-4",
                      },
                      [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: _vm.errors.first("inscricaoMunicipal"),
                                expression:
                                  "errors.first('inscricaoMunicipal')",
                              },
                            ],
                            staticClass: "form-group",
                          },
                          [
                            _c(
                              "label",
                              {
                                staticClass: "control-label",
                                attrs: { for: "inscricaoMunicipal" },
                              },
                              [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(
                                      _vm.$t("__.Crud.cliente.edit_vue_html.im")
                                    ) +
                                    "\n                            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.model.inscricaoMunicipal,
                                  expression: "model.inscricaoMunicipal",
                                },
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "max:20",
                                  expression: "'max:20'",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: {
                                autocomplete: "off",
                                type: "text",
                                id: "inscricaoMunicipal",
                                name: "inscricaoMunicipal",
                                placeholder: "__________",
                                disabled: _vm.somenteConsulta,
                              },
                              domProps: { value: _vm.model.inscricaoMunicipal },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.model,
                                    "inscricaoMunicipal",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                          ]
                        ),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            _vm.model.tipoPessoa == _vm.tiposPessoa.Fisica ||
                            _vm.model.tipoPessoa ==
                              _vm.tiposPessoa.ProdutorRural,
                          expression:
                            "model.tipoPessoa == tiposPessoa.Fisica || model.tipoPessoa == tiposPessoa.ProdutorRural",
                        },
                      ],
                      staticClass: "row",
                    },
                    [
                      _c("div", { staticClass: "col-sm-3" }, [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: _vm.errors.first("dataNascimento"),
                                expression: "errors.first('dataNascimento')",
                              },
                            ],
                            staticClass: "form-group",
                          },
                          [
                            _c(
                              "label",
                              {
                                staticClass: "control-label",
                                attrs: { for: "dataNascimento" },
                              },
                              [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(
                                      _vm.$t(
                                        "__.Crud.cliente.edit_vue_html.dataNasc"
                                      )
                                    ) +
                                    "\n                            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("date-time-picker-component", {
                              attrs: {
                                id: "dataNascimento",
                                name: "dataNascimento",
                                disabled: _vm.somenteConsulta,
                              },
                              model: {
                                value: _vm.model.dataNascimento,
                                callback: function ($$v) {
                                  _vm.$set(_vm.model, "dataNascimento", $$v)
                                },
                                expression: "model.dataNascimento",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-sm-3" }, [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: _vm.errors.first("genero"),
                                expression: "errors.first('genero')",
                              },
                            ],
                            staticClass: "form-group",
                          },
                          [
                            _c(
                              "label",
                              {
                                staticClass: "control-label",
                                attrs: {
                                  for: "genero",
                                  title: _vm.$t("__.obrigatorio"),
                                },
                              },
                              [
                                _c("span", { staticStyle: { color: "red" } }, [
                                  _vm._v("*"),
                                ]),
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(
                                      _vm.$t(
                                        "__.Crud.cliente.edit_vue_html.genero"
                                      )
                                    ) +
                                    "\n                            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("combo-component", {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: {
                                    rules: {
                                      required:
                                        _vm.model.tipoPessoa ==
                                          _vm.tiposPessoa.Fisica ||
                                        _vm.model.tipoPessoa ==
                                          _vm.tiposPessoa.ProdutorRural,
                                    },
                                  },
                                  expression:
                                    "{\n                                    rules: {\n                                        required:\n                                            model.tipoPessoa == tiposPessoa.Fisica ||\n                                            model.tipoPessoa == tiposPessoa.ProdutorRural,\n                                    },\n                                }",
                                },
                              ],
                              attrs: {
                                id: "genero",
                                name: "genero",
                                data: _vm.generosPessoaOptions,
                                searchable: false,
                                disabled: _vm.somenteConsulta,
                              },
                              model: {
                                value: _vm.model.genero,
                                callback: function ($$v) {
                                  _vm.$set(_vm.model, "genero", $$v)
                                },
                                expression: "model.genero",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-sm-3" }, [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: _vm.errors.first("orgaoExpedidor"),
                                expression: "errors.first('orgaoExpedidor')",
                              },
                            ],
                            staticClass: "form-group",
                          },
                          [
                            _c(
                              "label",
                              {
                                staticClass: "control-label",
                                attrs: { for: "orgaoExpedidor" },
                              },
                              [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(
                                      _vm.$t(
                                        "__.Crud.cliente.edit_vue_html.orgaoExp"
                                      )
                                    ) +
                                    "\n                            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.model.orgaoExpedidor,
                                  expression: "model.orgaoExpedidor",
                                },
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "alpha_spaces|max:256",
                                  expression: "'alpha_spaces|max:256'",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: {
                                autocomplete: "off",
                                type: "text",
                                id: "orgaoExpedidor",
                                name: "orgaoExpedidor",
                                disabled: _vm.somenteConsulta,
                              },
                              domProps: { value: _vm.model.orgaoExpedidor },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.model,
                                    "orgaoExpedidor",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-sm-3" }, [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: _vm.errors.first("ufExpedidor"),
                                expression: "errors.first('ufExpedidor')",
                              },
                            ],
                            staticClass: "form-group",
                          },
                          [
                            _c(
                              "label",
                              {
                                staticClass: "control-label",
                                attrs: { for: "ufExpedidor" },
                              },
                              [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(
                                      _vm.$t(
                                        "__.Crud.cliente.edit_vue_html.ufExp"
                                      )
                                    ) +
                                    "\n                            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("combo-component", {
                              attrs: {
                                id: "ufExpedidor",
                                name: "ufExpedidor",
                                data: _vm.ufOptions,
                                searchable: true,
                                disabled: _vm.somenteConsulta,
                              },
                              model: {
                                value: _vm.model.ufExpedidor,
                                callback: function ($$v) {
                                  _vm.$set(_vm.model, "ufExpedidor", $$v)
                                },
                                expression: "model.ufExpedidor",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-sm-8" }, [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: _vm.errors.first("observacao"),
                              expression: "errors.first('observacao')",
                            },
                          ],
                          staticClass: "form-group",
                        },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "control-label",
                              attrs: { for: "observacao" },
                            },
                            [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(
                                    _vm.$t("__.Crud.cliente.edit_vue_html.obs")
                                  ) +
                                  "\n                            "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("textarea-component", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "max:256",
                                expression: "'max:256'",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: {
                              id: "observacao",
                              name: "observacao",
                              disabled: _vm.somenteConsulta,
                            },
                            model: {
                              value: _vm.model.observacao,
                              callback: function ($$v) {
                                _vm.$set(_vm.model, "observacao", $$v)
                              },
                              expression: "model.observacao",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        class:
                          _vm.isFranquiaHumana && !_vm.model.id
                            ? "col-sm-3"
                            : "col-sm-4",
                      },
                      [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: _vm.errors.first("beneficio"),
                                expression: "errors.first('beneficio')",
                              },
                            ],
                            staticClass: "form-group",
                          },
                          [
                            _c(
                              "label",
                              {
                                staticClass: "control-label",
                                attrs: { for: "beneficio" },
                              },
                              [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(
                                      _vm.$t(
                                        "__.Crud.colaborador.edit_vue_html.beneficio"
                                      )
                                    ) +
                                    "\n                            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("combo-component", {
                              attrs: {
                                id: "beneficio",
                                name: "beneficio",
                                data: _vm.beneficiosOptions,
                                searchable: true,
                                refresh: _vm.atualizarBeneficios,
                              },
                              model: {
                                value: _vm.model.beneficioId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.model, "beneficioId", $$v)
                                },
                                expression: "model.beneficioId",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _vm.isFranquiaHumana && !_vm.model.id
                      ? _c("div", { staticClass: "col-sm-1" }, [
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "tooltip",
                                  rawName: "v-tooltip",
                                  value: _vm.errors.first("pacienteCliente"),
                                  expression: "errors.first('pacienteCliente')",
                                },
                              ],
                              staticClass: "form-group",
                            },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "control-label",
                                  attrs: { for: "pacienteCliente" },
                                },
                                [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(
                                        _vm.$t(
                                          "__.Crud.paciente.edit_vue_html.pacienteCliente"
                                        )
                                      ) +
                                      "\n                            "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("checkbox-component", {
                                attrs: {
                                  id: "pacienteCliente",
                                  name: "pacienteCliente",
                                  disabled: _vm.somenteConsulta,
                                },
                                model: {
                                  value: _vm.model.pacienteCliente,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.model, "pacienteCliente", $$v)
                                  },
                                  expression: "model.pacienteCliente",
                                },
                              }),
                            ],
                            1
                          ),
                        ])
                      : _vm._e(),
                  ]),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "fieldset-component",
            {
              attrs: {
                title: _vm.$t("__.Crud.cliente.edit_vue_html.localizacao"),
              },
            },
            [
              _c(
                "div",
                { attrs: { slot: "rows", id: "localizacao" }, slot: "rows" },
                [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-sm-3" }, [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: _vm.errors.first("cep"),
                              expression: "errors.first('cep')",
                            },
                          ],
                          staticClass: "form-group",
                        },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "control-label",
                              attrs: { for: "cep" },
                            },
                            [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(
                                    _vm.$t("__.Crud.cliente.edit_vue_html.cep")
                                  ) +
                                  "\n                            "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("cep-field-component", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "cep",
                                expression: "'cep'",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: {
                              id: "cep",
                              name: "cep",
                              disabled: _vm.somenteConsulta,
                            },
                            on: {
                              "cep-changed": _vm.onCepChanged,
                              "cep-invalid": _vm.onCepInvalid,
                            },
                            model: {
                              value: _vm.model.cep,
                              callback: function ($$v) {
                                _vm.$set(_vm.model, "cep", $$v)
                              },
                              expression: "model.cep",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-sm-3" }, [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: _vm.errors.first("estadoId"),
                              expression: "errors.first('estadoId')",
                            },
                          ],
                          staticClass: "form-group",
                        },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "control-label",
                              attrs: { for: "estadoId" },
                            },
                            [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(
                                    _vm.$t(
                                      "__.Crud.cliente.edit_vue_html.estado"
                                    )
                                  ) +
                                  "\n                            "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("combo-component", {
                            attrs: {
                              id: "estadoId",
                              name: "estadoId",
                              data: _vm.ufOptions,
                              searchable: true,
                              disabled: _vm.somenteConsulta,
                            },
                            on: { input: _vm.onLoadCidades },
                            model: {
                              value: _vm.model.estadoId,
                              callback: function ($$v) {
                                _vm.$set(_vm.model, "estadoId", $$v)
                              },
                              expression: "model.estadoId",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { class: _vm.model.exterior ? "col-sm-3" : "col-sm-6" },
                      [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: _vm.errors.first("cidadeId"),
                                expression: "errors.first('cidadeId')",
                              },
                            ],
                            staticClass: "form-group",
                          },
                          [
                            _c(
                              "label",
                              {
                                staticClass: "control-label",
                                attrs: { for: "cidadeId" },
                              },
                              [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(
                                      _vm.$t(
                                        "__.Crud.cliente.edit_vue_html.cidade"
                                      )
                                    ) +
                                    "\n                            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("combo-component", {
                              attrs: {
                                id: "cidadeId",
                                name: "cidadeId",
                                data: _vm.cidadesOptions,
                                searchable: true,
                                disabled: _vm.somenteConsulta,
                              },
                              model: {
                                value: _vm.model.cidadeId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.model, "cidadeId", $$v)
                                },
                                expression: "model.cidadeId",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _vm.model.exterior
                      ? _c("div", { staticClass: "col-sm-3" }, [
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "tooltip",
                                  rawName: "v-tooltip",
                                  value: _vm.errors.first("codigoPais"),
                                  expression: "errors.first('codigoPais')",
                                },
                              ],
                              staticClass: "form-group",
                            },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "control-label",
                                  attrs: {
                                    for: "codigoPais",
                                    title: "* este campo é obrigatório",
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    { staticStyle: { color: "red" } },
                                    [_vm._v("*")]
                                  ),
                                  _vm._v(
                                    "\n                                Pais\n                            "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("combo-component", {
                                attrs: {
                                  id: "codigoPais",
                                  name: "codigoPais",
                                  data: _vm.paisesOptions,
                                  searchable: true,
                                  disabled: _vm.somenteConsulta,
                                },
                                model: {
                                  value: _vm.model.codigoPais,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.model, "codigoPais", $$v)
                                  },
                                  expression: "model.codigoPais",
                                },
                              }),
                            ],
                            1
                          ),
                        ])
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-sm-4" }, [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: _vm.errors.first("endereco"),
                              expression: "errors.first('endereco')",
                            },
                          ],
                          staticClass: "form-group",
                        },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "control-label",
                              attrs: { for: "endereco" },
                            },
                            [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(
                                    _vm.$t(
                                      "__.Crud.cliente.edit_vue_html.endereco"
                                    )
                                  ) +
                                  "\n                            "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.model.endereco,
                                expression: "model.endereco",
                              },
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "max:256",
                                expression: "'max:256'",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: {
                              autocomplete: "off",
                              type: "text",
                              id: "endereco",
                              name: "endereco",
                              disabled: _vm.somenteConsulta,
                            },
                            domProps: { value: _vm.model.endereco },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.model,
                                  "endereco",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-sm-4" }, [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: _vm.errors.first("numero"),
                              expression: "errors.first('numero')",
                            },
                          ],
                          staticClass: "form-group",
                        },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "control-label",
                              attrs: { for: "numero" },
                            },
                            [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(
                                    _vm.$t("__.Crud.cliente.edit_vue_html.nro")
                                  ) +
                                  "\n                            "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.model.numero,
                                expression: "model.numero",
                              },
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "max:256",
                                expression: "'max:256'",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: {
                              autocomplete: "off",
                              type: "text",
                              id: "numero",
                              name: "numero",
                              disabled: _vm.somenteConsulta,
                            },
                            domProps: { value: _vm.model.numero },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.model,
                                  "numero",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-sm-4" }, [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: _vm.errors.first("bairro"),
                              expression: "errors.first('bairro')",
                            },
                          ],
                          staticClass: "form-group",
                        },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "control-label",
                              attrs: { for: "bairro" },
                            },
                            [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(
                                    _vm.$t(
                                      "__.Crud.cliente.edit_vue_html.bairro"
                                    )
                                  ) +
                                  "\n                            "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.model.bairro,
                                expression: "model.bairro",
                              },
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "max:256",
                                expression: "'max:256'",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: {
                              autocomplete: "off",
                              type: "text",
                              id: "bairro",
                              name: "bairro",
                              maxlength: "50",
                              disabled: _vm.somenteConsulta,
                            },
                            domProps: { value: _vm.model.bairro },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.model,
                                  "bairro",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-sm-12" }, [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: _vm.errors.first("complemento"),
                              expression: "errors.first('complemento')",
                            },
                          ],
                          staticClass: "form-group",
                        },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "control-label",
                              attrs: { for: "complemento" },
                            },
                            [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(
                                    _vm.$t(
                                      "__.Crud.cliente.edit_vue_html.complemento"
                                    )
                                  ) +
                                  "\n                            "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.model.complemento,
                                expression: "model.complemento",
                              },
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "max:256",
                                expression: "'max:256'",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: {
                              autocomplete: "off",
                              type: "text",
                              id: "complemento",
                              name: "complemento",
                              disabled: _vm.somenteConsulta,
                            },
                            domProps: { value: _vm.model.complemento },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.model,
                                  "complemento",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]
                      ),
                    ]),
                  ]),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "fieldset-component",
            {
              attrs: {
                title: _vm.$t("__.Crud.cliente.edit_vue_html.enderecoEntrega"),
              },
            },
            [
              _c(
                "div",
                { attrs: { slot: "rows", id: "entrega" }, slot: "rows" },
                [
                  !_vm.somenteConsulta
                    ? _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-sm-12" }, [
                          _c("div", { staticClass: "form-group" }, [
                            _c(
                              "button",
                              {
                                staticClass: "btn btnCopiar",
                                attrs: { type: "button" },
                                on: {
                                  click: function ($event) {
                                    _vm.replicarLocalizacao()
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(
                                      _vm.$t(
                                        "__.Crud.cliente.edit_vue_html.usarMesmoEnde"
                                      )
                                    ) +
                                    "\n                            "
                                ),
                              ]
                            ),
                          ]),
                        ]),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.somenteConsulta
                    ? _c("endereco-entrega-component", {
                        ref: "enderecoEntregaComponent",
                        staticClass: "ignorePrint",
                        attrs: { clienteId: _vm.model.id },
                        on: {
                          "inclusao-endereco": _vm.onAdicionarEndereco,
                          "edicao-endereco": _vm.onEditEnderecoSubmit,
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("grid-component", {
                    attrs: {
                      data: _vm.model.enderecosEntrega,
                      columns: _vm.gridColumnsEndereco,
                      "show-action-column": !_vm.somenteConsulta,
                      "show-table-foot": false,
                      "show-table-head": false,
                    },
                    on: {
                      "edit-item": _vm.onEditEndereco,
                      "remove-item": _vm.onRemoveEndereco,
                    },
                  }),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "fieldset-component",
            {
              attrs: {
                title: _vm.$t("__.Crud.cliente.edit_vue_html.telefone"),
                collapsed: false,
              },
            },
            [
              _c(
                "div",
                { attrs: { slot: "rows", id: "contato" }, slot: "rows" },
                [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-sm-3" }, [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: _vm.errors.first("telefone"),
                              expression: "errors.first('telefone')",
                            },
                          ],
                          staticClass: "form-group",
                        },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "control-label",
                              attrs: { for: "telefone" },
                            },
                            [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(
                                    _vm.$t(
                                      "__.Crud.cliente.edit_vue_html.telefone"
                                    )
                                  ) +
                                  "\n                            "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "mask-telefone",
                                rawName: "v-mask-telefone",
                              },
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.model.telefone,
                                expression: "model.telefone",
                              },
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "telefone",
                                expression: "'telefone'",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: {
                              autocomplete: "off",
                              type: "text",
                              id: "telefone",
                              name: "telefone",
                              disabled: _vm.somenteConsulta,
                            },
                            domProps: { value: _vm.model.telefone },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.model,
                                  "telefone",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-sm-3" }, [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: _vm.errors.first("celular"),
                              expression: "errors.first('celular')",
                            },
                          ],
                          staticClass: "form-group",
                        },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "control-label",
                              attrs: { for: "celular" },
                            },
                            [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(
                                    _vm.$t(
                                      "__.Crud.cliente.edit_vue_html.celular"
                                    )
                                  ) +
                                  "\n                            "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "mask-telefone",
                                rawName: "v-mask-telefone",
                              },
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.model.celular,
                                expression: "model.celular",
                              },
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "telefone",
                                expression: "'telefone'",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: {
                              autocomplete: "off",
                              type: "text",
                              id: "celular",
                              name: "celular",
                              disabled: _vm.somenteConsulta,
                            },
                            domProps: { value: _vm.model.celular },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.model,
                                  "celular",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-sm-6" }, [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: _vm.errors.first("email"),
                              expression: "errors.first('email')",
                            },
                          ],
                          staticClass: "form-group",
                        },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "control-label",
                              attrs: { for: "email" },
                            },
                            [_vm._v("E-mail")]
                          ),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.model.email,
                                expression: "model.email",
                              },
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "email",
                                expression: "'email'",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: {
                              autocomplete: "off",
                              type: "email",
                              id: "email",
                              name: "email",
                              placeholder: "exemplo@exemplo.com.br",
                              disabled: _vm.somenteConsulta,
                            },
                            domProps: { value: _vm.model.email },
                            on: {
                              blur: _vm.onChangeEmail,
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.model,
                                  "email",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]
                      ),
                    ]),
                  ]),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "fieldset-component",
            {
              attrs: {
                title: _vm.$t(
                  "__.Crud.cliente.edit_vue_html.emailsParaNotaFiscal"
                ),
                collapsed: false,
              },
            },
            [
              _c(
                "div",
                {
                  attrs: { slot: "rows", id: "emailNotaFiscal" },
                  slot: "rows",
                },
                [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-sm-12" }, [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: _vm.errors.first("emailNotaFiscal"),
                              expression: "errors.first('emailNotaFiscal')",
                            },
                          ],
                          staticClass: "form-group",
                        },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "control-label",
                              attrs: { for: "emailNotaFiscal" },
                            },
                            [_vm._v("E-mail")]
                          ),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.emailNotaFiscal,
                                expression: "emailNotaFiscal",
                              },
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "email",
                                expression: "'email'",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: {
                              autocomplete: "off",
                              type: "email",
                              id: "emailNotaFiscal",
                              name: "emailNotaFiscal",
                              placeholder: "exemplo@exemplo.com.br",
                              disabled: _vm.somenteConsulta,
                            },
                            domProps: { value: _vm.emailNotaFiscal },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.emailNotaFiscal = $event.target.value
                              },
                            },
                          }),
                        ]
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  !_vm.somenteConsulta
                    ? _c("buttonIncluirComponent", {
                        on: { click: _vm.onAddEmail },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("grid-component", {
                    attrs: {
                      data: _vm.model.emailsNotaFiscal,
                      columns: _vm.gridColumnsEmails,
                      showRemoveItem: true,
                      showSortIcons: false,
                      showEditItem: false,
                      showTableHead: false,
                      showAddNewButton: false,
                      showTableFoot: false,
                      showTools: false,
                      showActionColumn: !_vm.somenteConsulta,
                    },
                    on: { "remove-item": _vm.onRemoverEmail },
                  }),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          !_vm.isFranquiaHumana
            ? _c(
                "fieldset-component",
                {
                  attrs: {
                    title: _vm.$t("__.Crud.cliente.edit_vue_html.pacientes"),
                    collapsed: true,
                  },
                },
                [
                  _c(
                    "div",
                    { attrs: { slot: "rows" }, slot: "rows" },
                    [
                      _c(
                        "shortcutComponent",
                        {
                          ref: "shortcutComponent",
                          attrs: {
                            showAddNew: false,
                            btOkName: _vm.$t("__.Crud.salvar"),
                          },
                          on: { "selection-item": _vm.onSavePaciente },
                        },
                        [
                          _c(
                            "div",
                            { attrs: { slot: "component" }, slot: "component" },
                            [
                              _c("paciente-component", {
                                ref: "pacienteComponent",
                                attrs: {
                                  CalledByShortCut: true,
                                  showCliente: false,
                                },
                                on: { "paciente-model": _vm.onAddPacienteOk },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("grid-component", {
                        attrs: {
                          data: _vm.model.pacientes,
                          columns: _vm.gridColumns,
                          "show-action-column": !_vm.somenteConsulta,
                          "show-remove-item": false,
                          "show-table-foot": false,
                          "show-table-head": false,
                        },
                        on: { "edit-item": _vm.onVerPaciente },
                      }),
                      _vm._v(" "),
                      !_vm.somenteConsulta
                        ? _c("buttonIncluirComponent", {
                            on: { click: _vm.onVerPaciente },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.model.id
            ? _c("infoBagdeComponent", { attrs: { text: _vm.infoBagdeText } })
            : _vm._e(),
          _vm._v(" "),
          _c("fieldset", [
            !_vm.hideExtras
              ? _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-sm-12 newbtn" }, [
                    _c("div", { staticClass: "form-group" }, [
                      !_vm.somenteConsulta
                        ? _c(
                            "button",
                            {
                              staticClass: "btn btn-success",
                              attrs: { type: "submit" },
                            },
                            [
                              _c("i", {
                                staticClass: "fa fa-save",
                                attrs: { "aria-hidden": "true" },
                              }),
                              _vm._v(
                                "\n                            " +
                                  _vm._s(_vm.$t("__.Crud.salvar")) +
                                  "\n                        "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.somenteConsulta
                        ? _c(
                            "button",
                            {
                              staticClass: "btn btn-secondary",
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  _vm.load()
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(_vm.$t("__.Crud.limpar")) +
                                  "\n                        "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-secondary",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              _vm.cancel()
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n                            " +
                              _vm._s(_vm.$t("__.Crud.voltar")) +
                              "\n                        "
                          ),
                        ]
                      ),
                    ]),
                  ]),
                ])
              : _vm._e(),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c("vendas-cliente-list-component", {
        ref: "vendasClienteListComponent",
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }