import { Component } from "@/decorators";
import JustificativaControleReclamacaoModel from "@/models/justificativaControleReclamacaoModel";
import JustificativaControleReclamacaoService from "@/services/justificativaControleReclamacaoService";

import EditComponentBase from "../editComponentBase";

@Component
export default class JustificativaControleReclamacaoComponent extends EditComponentBase<JustificativaControleReclamacaoModel> {
    private async beforeMount() {
        await this.setProps(
            new JustificativaControleReclamacaoService(),
            "justificativa_controle_reclamacao",
            new JustificativaControleReclamacaoModel(),
        );
    }
}
