import BaseModel from "./base/baseModel";
import StatusInutilizacaoNotaFiscal from "./enum/statusInutilizacaoNotaFiscal";

export default class InutilizacaoNotaFiscalModel extends BaseModel {
    id?: number = null;
    ano: number = new Date().getFullYear();
    modelo = 55;
    serie = "";
    numeroInicial: number = null;
    numeroFinal: number = null;
    justificativa = "";
    status: StatusInutilizacaoNotaFiscal = StatusInutilizacaoNotaFiscal.Pendente;
    rejeicao = "";
    dataSolicitacao: Date = new Date();
}
