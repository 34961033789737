import PontuacaoDotzModel from "@/models/dotz/pontuacaoDotzModel";
import nfetch from "@/utils/events/nfetch";

import { IService } from "../base/iService";

export default class PontuacaoDotzService extends IService<PontuacaoDotzModel> {
    constructor() {
        super("PontuacaoDotz");
    }

    public getVendasByPontuacao(pontuacaoId: number): Promise<Response> {
        return nfetch(`/${this.controllerName}/GetVendasByPontuacao?pontuacaoId=${pontuacaoId}`, {
            credentials: "same-origin",
        });
    }

    public enviarArquivosVendedores(): Promise<Response> {
        return nfetch(`/${this.controllerName}/EnviarArquivosVendedores`, {
            method: "post",
            credentials: "same-origin",
        });
    }

    public updatePontuacao(pontuacao: Number, id: Number): Promise<Response> {
        return nfetch(`/${this.controllerName}/UpdatePontuacao?pontuacao=${pontuacao}&id=${id}`, {
            method: "post",
            credentials: "same-origin",
        });
    }

    public gerarPontuacaoPendente(periodo: Date): Promise<Response> {
        return nfetch(`/${this.controllerName}/GerarPontuacaoPendente?periodo=${periodo.toDateString()}`, {
            method: "post",
            credentials: "same-origin",
        });
    }

    public enviarPontuacaoPendente(): Promise<Response> {
        return nfetch(`/${this.controllerName}/EnviarPontuacaoPendente`, {
            method: "post",
            credentials: "same-origin",
        });
    }

    public deletePontuacao(id: number): Promise<Response> {
        return nfetch(`/${this.controllerName}/DeletePontuacaoManual?id=${id}`, {
            method: "post",
            credentials: "same-origin",
        });
    }
}
