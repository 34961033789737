import FormatoImpressaoRotulo from "../../models/enum/rotulo/formatoImpressaoRotulo";

import { htmlChangeOrientation } from "./pageOrientation";
import { PrinterError } from "./printerService";

/** Representa um serviço de impressão que gerencia uma ou mais impressoras. */
export interface PrinterBackend {
    name: string;

    init(): Promise<boolean>;
    getPrinters(): Promise<PrinterQueue[]>;
    flushQueue(queue: PrinterQueue): Promise<boolean>;
}

/** Representa uma impressora e seus trabalhos pendentes */
export class PrinterQueue {
    /** Fila de páginas */
    private queue: HtmlPrintPageInfo[] = [];

    constructor(printerName: string, backend: PrinterBackend, pageSizes: PageSize[] = null) {
        this.printerBackend = backend;
        this.printerName = printerName;
        this.pageSizes = pageSizes;
    }

    /** Nome da impressora (deve ser único) */
    public printerName: string;
    /** Backend da impressora */
    public printerBackend: PrinterBackend;
    /** Tamanhos de página suportados */
    public pageSizes?: PageSize[];
    /** Descrição da impressora */
    public printerDesc?: string;

    /** Imprimir todos os itens da fila de impressão (chamado pelo PrinterService) */
    public async flush() {
        await this.printerBackend.flushQueue(this);
    }

    /** Adicionar uma página à fila de impressão (chamado pelo PrinterService) */
    public enqueue(page: HtmlPrintPageInfo) {
        // generate page id based on current time
        page.id = new Date().getTime().toString();

        // Ajustar orientação da página
        if (page.orientation != null) {
            page.pageHtml = htmlChangeOrientation(
                page.pageHtml,
                page.pageSize.width,
                page.pageSize.height,
                page.orientation,
            );
        }

        let copies = page.copies ?? 1;
        while (copies--) {
            this.queue.push(page);
        }
    }

    /** Remover um item da fila (chamado pelo PrinterBackend) */
    public dequeue(page: HtmlPrintPageInfo = null): HtmlPrintPageInfo {
        if (page == null) {
            return this.queue.shift();
        } else {
            const index = this.queue.findIndex(p => p.id === page.id);
            if (index >= 0) {
                return this.queue.splice(index, 1)[0];
            }
        }
    }

    /** Remover todos os itens da fila (chamado pelo PrinterBackend) */
    public dequeueAll(): HtmlPrintPageInfo[] {
        const result = this.queue;
        this.queue = [];
        return result;
    }
}

/** Representa um tamanho de página pré configurado. */
export interface PageSize {
    /** Nome do modelo de tamanho */
    name: string;
    /** largura (em milímetros) */
    width: number;
    /** altura (em milímetros) */
    height: number;
}

/** Informações da página. */
export interface HtmlPrintPageInfo {
    /** HTML para imprimir */
    pageHtml: string;
    /** Número de cópias */
    copies?: number;
    /** Número da página */
    pageNumber?: number;
    /** Título da página */
    pageTitle?: string;

    /** Erro retornado pelo serviço de impressão. */
    error?: PrinterError;
    /** Identificador único da página atribuido ao enfileirar */
    id?: string;

    // Informações para o backend de impressão

    /** Nome da impressora */
    printerName?: string;
    /** Tamanho da página */
    pageSize?: PageSize;
    /** Flag para imprimir em modo paisagem (o padrão é retrato) */
    orientation?: FormatoImpressaoRotulo;
}

export const mountHtml = (body: string, globalStyle = "") => `
    <!DOCTYPE html>
    <html>
        <head>
            <meta charset="utf-8">
            <style>
                @media print {
                    @page { margin: 0; }
                }

                pre {
                    background-color: transparent !important;
                    border: 0 !important;
                    border-radius: 0 !important;
                    padding: 0 !important;
                    margin: 0 !important;
                }
            </style>
            <style>
                ${globalStyle}
            </style>
        </head>
        <body style="margin: 0; padding: 0;">
            ${body}
        </body>
    </html>`;
