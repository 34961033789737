var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "crud crud-list ajusteAcoesPesquisar" },
    [
      _c("div", { staticClass: "info-div" }, [
        _c("h1", { staticClass: "title" }, [
          _vm._v(
            _vm._s(
              _vm.$t(
                "__.Components.parent.notafiscalsaida.list_vue_html.nfSaida"
              )
            )
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "content" }, [
          _c("div", { staticClass: "info-panel" }, [
            _c("span", [_vm._v(_vm._s(_vm.total))]),
            _vm._v(" "),
            _c("span", [
              _vm._v(
                _vm._s(
                  _vm.$t(
                    "__.Components.parent.notafiscalsaida.list_vue_html.nf"
                  )
                )
              ),
            ]),
            _vm._v(" "),
            _c("span", [
              _vm._v(
                _vm._s(
                  _vm.$t(
                    "__.Components.parent.notafiscalsaida.list_vue_html.cadastradas"
                  )
                )
              ),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "grid-component",
        {
          ref: "gridcomponent",
          attrs: {
            data: _vm.gridData,
            tituloPagina: "Notas fiscais de Saída",
            columns: _vm.gridColumns,
            "show-action-column": true,
            "show-edit-item": false,
            "show-remove-item": false,
            showAddNewButton: false,
            "show-table-head": true,
            "page-index": _vm.pageIndex,
            "page-size": _vm.pageSize,
            total: _vm.total,
            startFilterKey: _vm.startFilterKey,
            extraActions: _vm.extraActions,
          },
          on: {
            "gerar-xls": function ($event) {
              _vm.pags = true
              _vm.tipoDocGerar = 0
            },
            "gerar-pdf": function ($event) {
              _vm.pags = true
              _vm.tipoDocGerar = 1
            },
            "gerar-impressao": function ($event) {
              _vm.pags = true
              _vm.tipoDocGerar = 2
            },
            "change-filter-key": _vm.onChangeFilterKey,
            "change-sort": _vm.onChangeSort,
            "change-page": _vm.onChangePage,
            "extra-action": _vm.onExtraAction,
          },
        },
        [
          _c("div", { attrs: { slot: "header" }, slot: "header" }, [
            _c("div", { staticClass: "filtro-lista" }, [
              _c(
                "button",
                {
                  staticClass: "filtro-avancado-button",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      _vm.isHidden = !_vm.isHidden
                    },
                  },
                },
                [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.$t("__.Crud.filtroAvancado")) +
                      "\n                    "
                  ),
                  _c("i", { staticClass: "fa fa-caret-down" }),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isHidden,
                      expression: "isHidden",
                    },
                  ],
                  staticClass: "drop-lista",
                },
                [
                  _c(
                    "form",
                    {
                      attrs: { novalidate: "novalidate" },
                      on: {
                        submit: function ($event) {
                          $event.preventDefault()
                          return _vm.getFormValues($event)
                        },
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "radio-group",
                          staticStyle: { "margin-bottom": "15px" },
                        },
                        [
                          _c("b", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "__.Components.parent.notafiscalsaida.list_vue_html.status"
                                )
                              )
                            ),
                          ]),
                          _vm._v(" "),
                          _c("combo-component", {
                            staticStyle: { background: "white" },
                            attrs: {
                              id: "statusFiltro",
                              defaultValue: -1,
                              name: "statusFiltro",
                              data: _vm.statusOptions,
                              searchable: false,
                            },
                            model: {
                              value: _vm.statusFiltro,
                              callback: function ($$v) {
                                _vm.statusFiltro = $$v
                              },
                              expression: "statusFiltro",
                            },
                          }),
                          _vm._v(" "),
                          _c("b", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "__.Components.parent.notafiscalsaida.list_vue_html.tipoNota"
                                )
                              )
                            ),
                          ]),
                          _vm._v(" "),
                          _c("combo-component", {
                            staticStyle: { background: "white" },
                            attrs: {
                              id: "tipoFiltro",
                              defaultValue: -1,
                              name: "tipoFiltro",
                              data: _vm.tipoNotaOptions,
                              searchable: false,
                            },
                            model: {
                              value: _vm.tipoFiltro,
                              callback: function ($$v) {
                                _vm.tipoFiltro = $$v
                              },
                              expression: "tipoFiltro",
                            },
                          }),
                          _vm._v(" "),
                          _c("b", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "__.Components.parent.notafiscalsaida.list_vue_html.de"
                                )
                              )
                            ),
                          ]),
                          _vm._v(" "),
                          _c("date-time-picker-component", {
                            staticStyle: { width: "100%" },
                            attrs: { id: "emissaoDe", name: "emissaoDe" },
                            model: {
                              value: _vm.emissaoDe,
                              callback: function ($$v) {
                                _vm.emissaoDe = $$v
                              },
                              expression: "emissaoDe",
                            },
                          }),
                          _vm._v(" "),
                          _c("b", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "__.Components.parent.notafiscalsaida.list_vue_html.ate"
                                )
                              )
                            ),
                          ]),
                          _vm._v(" "),
                          _c("date-time-picker-component", {
                            attrs: { id: "emissaoAte", name: "emissaoAte" },
                            model: {
                              value: _vm.emissaoAte,
                              callback: function ($$v) {
                                _vm.emissaoAte = $$v
                              },
                              expression: "emissaoAte",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "form-group" }, [
                        _c(
                          "button",
                          {
                            staticClass: "botao-filtrar",
                            attrs: { type: "submit" },
                          },
                          [
                            _vm._v(
                              "\n                                " +
                                _vm._s(_vm.$t("__.Crud.filtrar")) +
                                "\n                            "
                            ),
                          ]
                        ),
                      ]),
                    ]
                  ),
                ]
              ),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }