import Vue from "vue";

import comboComponent from "@/components/child/form/combo.vue";
import dateTimePickerComponent from "@/components/child/form/datetimepicker.vue";
import fieldsetComponent from "@/components/child/form/fieldset.vue";
import gridComponent from "@/components/child/grid/grid.vue";
import { GridColumn, GridColumnType } from "@/components/child/grid/gridColumn";
import { Component, Prop, Watch } from "@/decorators";
import Setor from "@/models/enum/setor";
import PaginationModel from "@/models/paginationModel";
import EtapaPCPModel from "@/models/pcp/etapaPCPModel";
import MotivoEtapaPCPModel from "@/models/pcp/motivoEtapaPCPModel";
import EtapaPCPService from "@/services/etapaPCPService";
import MotivoEtapaPCPService from "@/services/motivoEtapaPCPService";

import { finalizarEtapa, gerarModeloEtapaPCPDestino, getIsMotivoObrigatorio } from "./pcpUtil";

@Component({
    components: {
        fieldsetComponent,
        comboComponent,
        dateTimePickerComponent,
        gridComponent,
    },
})
export default class EtapasPCPComponent extends Vue {
    private etapaPCPService = new EtapaPCPService();
    private motivoEtapaPCPService = new MotivoEtapaPCPService();

    public etapaAtualId = null;

    private etapas: EtapaPCPModel[] = [];
    private etapasOptions: Object[] = [];
    private motivosEtapaOptions: Object[] = [];
    private proximaEtapaDestinoId = null;
    private proximaEtapaDestinoNotObrigatorioId = null;
    private etapaDestinoId = null;
    private motivoEtapaPCPId = null;
    private dataInicio = null;
    private isMotivoObrigatorio = false;
    private dataFim = null;
    private observacao = null;
    private gridData = [];

    @Prop(String) dataInicioProp: string;
    @Prop(String) dataFimProp: string;

    get gridEtapasColumns(): Array<GridColumn> {
        return [
            new GridColumn("etapaPCPDescricao", this.$t("__.ts.descricao") as string, GridColumnType.String),
            new GridColumn("usuarioInicioNome", this.$t("__.ts.usuarioInicio") as string, GridColumnType.String),
            new GridColumn("dataInicio", this.$t("__.ts.dataInicio") as string, GridColumnType.DateTime),
            new GridColumn("usuarioFinalizacaoNome", this.$t("__.ts.usuarioFim") as string, GridColumnType.String),
            new GridColumn("dataFim", this.$t("__.ts.dataFim") as string, GridColumnType.DateTime),
            new GridColumn("motivoEtapaPCPDescricao", this.$t("__.ts.motivoEtapaPCP") as string, GridColumnType.String),
            new GridColumn("observacao", this.$t("__.ts.obss") as string, GridColumnType.String),
        ];
    }

    public clear() {
        this.etapaAtualId = null;
        this.etapaDestinoId = null;
        this.motivoEtapaPCPId = null;
    }

    @Watch("dataInicioProp")
    private onChangeDataInicio() {
        this.dataInicio = null;
        if (this.dataInicioProp) {
            this.dataInicio = new Date(this.dataInicioProp.toString());
        }
    }

    @Watch("dataFimProp")
    private onChangeDataFim() {
        this.dataFim = null;
        if (this.dataFimProp) {
            this.dataFim = new Date(this.dataFimProp.toString());
        }
    }

    public updateGridData(data) {
        this.gridData = data;
    }

    public setEtapaDestinoId(ordem: number, setor?: Setor) {
        if (this.etapas.length > 0) {
            if (setor) {
                this.etapas.forEach(etapa => {
                    if (!etapa.obrigatorio && etapa.setor == setor) {
                        etapa.obrigatorio = true;
                    }
                });
            }

            const filteredEtapas = this.etapas.filter(p => p.obrigatorio && p.ordem > ordem);
            const filteredEtapasNotObrigatorio = this.etapas.filter(p => p.ordem > ordem);

            if (filteredEtapas.length > 0) {
                this.etapaDestinoId = filteredEtapas.sort((a, b) => (a.ordem > b.ordem ? 1 : -1))[0].id;
            }

            if (filteredEtapasNotObrigatorio.length > 0) {
                this.proximaEtapaDestinoNotObrigatorioId = filteredEtapasNotObrigatorio.sort((a, b) =>
                    a.ordem > b.ordem ? 1 : -1,
                )[0].id;
            }
        } else {
            this.etapaDestinoId = null;
        }
        this.proximaEtapaDestinoId = this.etapaDestinoId;

        this.observacao = null;
    }

    @Watch("etapaDestinoId")
    private onChangeObrigatorioMotivo() {
        this.isMotivoObrigatorio = getIsMotivoObrigatorio(
            this.etapas,
            this.proximaEtapaDestinoId,
            this.proximaEtapaDestinoNotObrigatorioId,
            this.etapaDestinoId,
        );
    }

    private async loadEtapas() {
        try {
            const data = await this.etapaPCPService.combo(true).resolveWithJSON<PaginationModel<EtapaPCPModel>>();

            this.etapas = data.list.filter(e => e.ativo);
            this.etapasOptions = data.list.map(item => ({ value: item.id, text: item.descricao }));
        } catch {}
    }

    private async loadMotivosEtapas() {
        try {
            const data = await this.motivoEtapaPCPService
                .combo()
                .resolveWithJSON<PaginationModel<MotivoEtapaPCPModel>>();

            this.motivosEtapaOptions = data.list.map(item => ({ value: item.id, text: item.descricao }));
        } catch {}
    }

    public finalizaEtapaAtual(etapas: any[]) {
        return finalizarEtapa(etapas);
    }

    public async criaEtapaDestino(model: any, modelEtapaPCP: any) {
        return await gerarModeloEtapaPCPDestino(
            model,
            modelEtapaPCP,
            this.etapaDestinoId,
            this.observacao,
            this.motivoEtapaPCPId,
        );
    }

    public async validate() {
        return await this.$validator.validateAll();
    }

    private mounted() {
        this.loadEtapas();
        this.loadMotivosEtapas();
    }
}
