import BaseModel from "./base/baseModel";

export default class ProdutoEstoqueConferenciaLoteModel extends BaseModel {
    id?: number = null;
    descricao: string = null;
    quantidadeAtual = 0;
    fornecedorId?: number = null;
    fornecedorNome: string = null;
    dataFabricacao: string = null;
    dataValidade: string = null;
    observacao: string = null;

    produtoSequencial?: number = null;

    statusEstoque?: StatusEstoque = null;
    movimentacaoEstoqueItemId?: number = null;
    quantidadeBaixa = 0;
}

export enum StatusEstoque {
    SemEstoque = 0,
    Baixo = 1,
    Suficiente = 2,
}
