import Vue from "vue";

import buttonIncluirComponent from "@/components/child/form/button/buttonIncluir.vue";
import comboComponent from "@/components/child/form/combo.vue";
import dateTimePickerComponent from "@/components/child/form/datetimepicker.vue";
import fieldsetComponent from "@/components/child/form/fieldset.vue";
import moedaComponent from "@/components/child/form/moeda.vue";
import textareaComponent from "@/components/child/form/textarea.vue";
import gridComponent from "@/components/child/grid/grid.vue";
import { GridColumn, GridColumnType } from "@/components/child/grid/gridColumn";
import ShortcutComponent from "@/components/parent/shortcut/shortcut";
import shortcutComponent from "@/components/parent/shortcut/shortcut.vue";
import { Component, Watch } from "@/decorators";
import FormaRecebimentoModel from "@/models/financeiro/formaRecebimentoModel";
import PaginationModel from "@/models/paginationModel";
import VendaPreFaturamentoModel from "@/models/vendaPreFaturamentoModel";
import FormaRecebimentoService from "@/services/financeiro/formaRecebimentoService";
import arithmeticHelper from "@/utils/common/arithmeticHelper";
import "../../crud/contareceber/recebimentoconta.scss";

@Component({
    components: {
        textareaComponent,
        fieldsetComponent,
        comboComponent,
        gridComponent,
        moedaComponent,
        buttonIncluirComponent,
        shortcutComponent,
        dateTimePickerComponent,
    },
})
export default class PreFaturamentoComponent extends Vue {
    model = new VendaPreFaturamentoModel();
    modelFormaRecebimento = new FormaRecebimentoModel();
    vendasPreFaturadas: VendaPreFaturamentoModel[] = [];

    private valorAbater = 0;
    private saldoAbater = 0;

    formaRecebimentos: Array<FormaRecebimentoModel> = [];
    formaRecebimentoOptionsFiltrado: Array<Object> = [];

    gridColumnsPagamento: Array<GridColumn> = [
        new GridColumn("formaRecebimentoDescricao", "Forma Recebimento", GridColumnType.String),
        new GridColumn("parcelas", "Parcelas", GridColumnType.Integer),
        new GridColumn("valor", "Valor", GridColumnType.Money),
        new GridColumn("valorTroco", "Valor Troco", GridColumnType.Money),
    ];

    private shortcutComponent: ShortcutComponent = null;

    public show() {
        this.shortcutComponent.title = this.$t("__.ts.preFaturamento") as string;
        this.shortcutComponent.show();
    }

    private async save() {
        this.shortcutComponent.hidewithoudOutputEvent();
        this.$emit("onResult", this.vendasPreFaturadas);
    }

    public load(vendasPreFaturadas: VendaPreFaturamentoModel[], valorVenda: number) {
        const saldoTotal = valorVenda - vendasPreFaturadas.reduce((acc, venda) => acc + venda.valor, 0);

        this.saldoAbater = valorVenda;
        this.vendasPreFaturadas = vendasPreFaturadas;
        this.model.valor = saldoTotal > 0 ? arithmeticHelper.round(saldoTotal) : 0;
    }

    private async loadFormasRecebimento() {
        try {
            const data = await new FormaRecebimentoService()
                .comboByEspecie()
                .resolveWithJSON<PaginationModel<FormaRecebimentoModel>>();

            this.formaRecebimentos = data.list;
            this.formaRecebimentoOptionsFiltrado = data.list.map(item => ({
                value: item.id,
                text: item.descricao,
                ...item,
            }));
        } catch {}
    }

    private async incluirRecebimento() {
        try {
            const isValid = await this.$validator.validateAll();
            if (isValid) {
                this.vendasPreFaturadas.push(this.getModel());
            } else {
                this.$focusErrorField();
            }
        } catch {}
    }

    private getModel() {
        const formaRecebimentoElement = this.$el.querySelector("#formaRecebimentoId") as HTMLSelectElement;

        const model = new VendaPreFaturamentoModel();
        model.updateFrom(this.model);
        model.formaRecebimentoDescricao = formaRecebimentoElement.innerText;

        if (this.modelFormaRecebimento.especie == 0) {
            model.valor = arithmeticHelper.round(this.valorAbater);
            this.model.valor =
                this.model.valor - this.valorAbater > 0
                    ? arithmeticHelper.round(this.model.valor - this.valorAbater)
                    : 0;
        } else {
            model.valor = arithmeticHelper.round(model.valor);
            const valor = this.saldoAbater - this.vendasPreFaturadas.reduce((acc, venda) => acc + venda.valor, 0);

            this.model.valor = valor - model.valor > 0 ? arithmeticHelper.round(valor - model.valor) : 0;
        }
        this.limparCampos();

        return model;
    }

    private onRemovePagamento(model: object) {
        if (this.model) {
            this.vendasPreFaturadas = this.vendasPreFaturadas.filter(p => p != model);
            this.model.valor = this.saldoAbater - this.vendasPreFaturadas.reduce((acc, venda) => acc + venda.valor, 0);
        }
    }

    @Watch("valorAbater")
    private onValorAbater() {
        this.calcularValor();
    }

    private calcularValor() {
        this.model.valorTroco = this.valorAbater - this.model.valor > 0 ? this.valorAbater - this.model.valor : 0;
    }

    @Watch("model.formaRecebimentoId")
    private onChangeFormaPagamentoId() {
        if (this.model.formaRecebimentoId != null && this.model.formaRecebimentoId > 0) {
            this.modelFormaRecebimento = this.formaRecebimentos.find(x => x.id == this.model.formaRecebimentoId);
        }
        this.limparCampos();
    }

    private limparCampos() {
        this.model.parcelas = null;
        this.model.valorTroco = 0;
        this.valorAbater = 0;
    }

    private async hide() {
        this.$emit("onCancel");
    }

    private mounted() {
        this.shortcutComponent = this.$refs.shortcutComponent as ShortcutComponent;
        this.valorAbater = 0;

        Promise.all([this.loadFormasRecebimento()])
            .withLoading()
            .then(() => {});
    }
}
