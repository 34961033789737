import BaseModel from "./base/baseModel";
import Alinhamento from "./enum/alinhamento";

export default class ConfiguracaoRotuloCamposModel extends BaseModel {
    id?: number = 0;
    sequencia: number = null;
    campo: string = null;
    campoOriginal: string = null;
    fonte = 0;
    negrito = false;
    value: string = null;
    label: string = null;
    quantidadeColunas = 1;
    alinhamento: Alinhamento = Alinhamento.Esquerda;
}
