import Moment from "moment";

import Boot from "../../main";
import SituacoesVenda from "../../models/enum/situacoesVenda";
import VendaModel from "../../models/vendaModel";
import ManipulacaoOrdemService from "../manipulacaoOrdemService";
import PDFService from "../pdf/PDFService";
import VendaService from "../vendaService";

export default class OrcamentoService {
    private service = new VendaService();
    private orcamentoPDFService = new PDFService("OrcamentoPDF");
    private manipulacaoOrdemService = new ManipulacaoOrdemService();

    public async adiar(orcamento: VendaModel) {
        const timeDiff = Moment(orcamento.dataValidadeOrcamento).diff(orcamento.dataInclusao);
        const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
        const inputValue = Math.abs(diffDays);

        const response = await Boot.$showAlert({
            title: Boot.$t("__.ts.adiarOrca") as string,
            text: Boot.$t("__.ts.adiarDtValOrca") as string,
            input: "number",
            showCancelButton: true,
            confirmButtonText: Boot.$t("__.ts.adiar") as string,
            inputValue,
            inputValidator: result => {
                if (Number(result) > 0) {
                    return "";
                }
                return Boot.$t("__.ts.qtdDiasAdiarMaiorZero") as string;
            },
        });

        if (response.isConfirmed) {
            try {
                const sucesso = await this.service.adiar(orcamento, response.value).withLoading().resolveWithoutJSON();
                if (sucesso) {
                    await Boot.$showSuccess(Boot.$t("__.ts.alt"), Boot.$t("__.ts.orcaAdiadoSucess"));
                    return true;
                }
            } catch {}
        }
        return false;
    }

    public async onImprimirOrcamento(orcamento: VendaModel) {
        await this.orcamentoPDFService.geraPDF(orcamento.id);
    }

    public async aprovarSalvarVenda(venda: VendaModel, isFracionamento = false) {
        if (venda != null) {
            const response = await Boot.$showQuestion(
                Boot.$t("__.ts.atencao"),
                isFracionamento ? "Deseja enviar a venda para o Fracionamento?" : "Deseja aprovar este orçamento?",
            );

            if (response) {
                venda.situacao = isFracionamento ? SituacoesVenda.Fracionando : SituacoesVenda.Aprovado;
                venda.dataAprovacao = new Date();
                return true;
            }
        }
        return false;
    }

    public async updateDadosAprovarOrcamento(venda: VendaModel) {
        await this.manipulacaoOrdemService.updateDadosAprovarOrcamento(
            venda.itens.filter(p => p.manipulacaoOrdemId).map(p => p.manipulacaoOrdem),
        );
    }
}
