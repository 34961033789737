import Vue from "vue";

import actionBarComponent from "@/components/child/actionBar/actionBar.vue";
import buttonIncluirComponent from "@/components/child/form/button/buttonIncluir.vue";
import comboComponent from "@/components/child/form/combo.vue";
import { Component } from "@/decorators";
import FormulaPadraoManipulacaoModel from "@/models/formulaPadraoManipulacaoModel";
import FormulaPadraoModel from "@/models/formulaPadraoModel";
import ManipulacaoModel from "@/models/manipulacaoOrdemModel";
import PaginationModel from "@/models/paginationModel";
import FormulaPadraoService from "@/services/formulaPadraoService";
import ManipulacaoService from "@/services/manipulacaoOrdemService";

import ShortcutComponent from "./../../shortcut/shortcut";
import shortcutComponent from "./../../shortcut/shortcut.vue";
import FormulaPadraoManipulacaoItemComponent from "./item";
import formulaPadraoManipulacaoItemComponent from "./item.vue";

import "../crud.scss";

@Component({
    components: {
        formulaPadraoManipulacaoItemComponent,
        shortcutComponent,
        comboComponent,
        buttonIncluirComponent,
        actionBarComponent,
    },
})
export default class FormulaPadraoManipulacaoComponent extends Vue {
    private service = new FormulaPadraoService();
    private manipulacaoService = new ManipulacaoService();

    private modelIds: Array<number> = [];
    private model: Array<FormulaPadraoManipulacaoModel> = [];

    private formulaPadraoId?: number = null;
    private formulasPadraoOptions: Array<Object> = [];

    private shortcutPrintComponent: ShortcutComponent = null;
    private model2: ManipulacaoModel = null;
    private itemComponent: FormulaPadraoManipulacaoItemComponent[] = [];

    private manipulacoesGeradas = false;

    private async load() {
        this.model = [];

        const apenasNumeros = this.modelIds.filter(item => typeof item === "number" && !isNaN(item));
        if (apenasNumeros.length > 0) {
            try {
                const data = await this.service
                    .listByIds(apenasNumeros.toString())
                    .withLoading()
                    .then(r => r.json() as Promise<FormulaPadraoModel[]>);

                data.forEach(d => {
                    const item = new FormulaPadraoManipulacaoModel();
                    item.formulaPadraoId = d.id;
                    item.formulaPadrao = d;
                    item.volumeTotal = item.formulaPadrao.volume;
                    this.model.push(item);
                });
            } catch {}
        }
    }

    private async adicionarItem() {
        if (this.formulaPadraoId && this.modelIds.indexOf(this.formulaPadraoId) === -1) {
            try {
                const data = await this.service
                    .get(this.formulaPadraoId)
                    .then(r => r.json() as Promise<FormulaPadraoModel>);

                const item = new FormulaPadraoManipulacaoModel();
                item.formulaPadraoId = data.id;
                item.volumeTotal = data.volume;

                const observacao = await this.service
                    .getObservacaoFormulaPadrao(item.formulaPadraoId)
                    .then(r => r && (r.json() as Promise<{ observacao: string }>));

                data.observacao = observacao.observacao;

                item.formulaPadrao = data;
                this.model.push(item);
                this.modelIds.push(this.formulaPadraoId);
                this.formulaPadraoId = 0;
            } catch {}
        }
    }

    private async onGerarOrdensManipulacao() {
        try {
            for (let index = 0; index < this.model.length; index++) {
                this.itemComponent[index] = this.$refs.itemComponent[index] as FormulaPadraoManipulacaoItemComponent;
                if (!(await this.itemComponent[index].validateAsync())) throw "";
            }

            for (let index = 0; index < this.model.length; index++) {
                await this.itemComponent[index].gerarManipulacao();
            }

            this.manipulacoesGeradas = true;
        } catch {}
    }

    private onRemoveItem(id: number) {
        this.model = this.model.filter(it => it.formulaPadraoId != id);
        this.modelIds = this.model.map(it => it.id);
    }

    private sair() {
        this.$router.back();
    }

    private async loadFormulaPadrao() {
        try {
            const data = await this.service.combo().then(r => r.json() as Promise<PaginationModel<FormulaPadraoModel>>);
            this.formulasPadraoOptions = data.list.map(item => ({ value: item.id, text: item.descricao }));
        } catch {}
    }

    private async mounted() {
        this.shortcutPrintComponent = this.$refs.shortcutPrintComponent as ShortcutComponent;

        this.manipulacoesGeradas = false;

        Promise.all([this.loadFormulaPadrao()])
            .withLoading()
            .then(() => {
                if (this.$route.params.ids) this.modelIds = this.$route.params.ids.split(",").map(Number);

                this.load();
            })
            .catch(() => {});
    }
}
