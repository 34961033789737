var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "crud crud-edit" },
    [
      _c(
        "actionBarComponent",
        {
          attrs: {
            showSave: !_vm.somenteConsulta,
            title: _vm.$t(
              "__.Crud.controleReclamacao.edit_vue_html.controleReclamacaoEdit"
            ),
            slots: 1,
          },
          on: { onSave: _vm.save },
        },
        [
          _c(
            "div",
            {
              attrs: {
                slot: "buttonSlot0",
                title: _vm.$t("__.Crud.produtolote.edit_vue_html.anexarLote"),
              },
              on: { click: _vm.onShowUploadAnexo },
              slot: "buttonSlot0",
            },
            [
              _c("i", {
                staticClass: "fa fa-paperclip",
                attrs: { "aria-hidden": "true" },
              }),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "form",
        {
          attrs: { novalidate: "novalidate" },
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.save($event)
            },
          },
        },
        [
          _c("fieldset", [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-sm-2" }, [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip",
                        value: _vm.errors.first("Tipo"),
                        expression: "errors.first('Tipo')",
                      },
                    ],
                    staticClass: "form-group",
                  },
                  [
                    _c(
                      "label",
                      {
                        staticClass: "control-label",
                        attrs: { for: "Tipo", title: _vm.$t("__.obrigatorio") },
                      },
                      [
                        _c("span", { staticStyle: { color: "red" } }, [
                          _vm._v("*"),
                        ]),
                        _vm._v(
                          "\n                            " +
                            _vm._s(
                              _vm.$t("__.Crud.cliente.edit_vue_html.tipo")
                            ) +
                            "\n                        "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("combo-component", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required",
                          expression: "'required'",
                        },
                      ],
                      attrs: {
                        id: "tipo",
                        name: "tipo",
                        data: _vm.controleReclamacaoTipoOptions,
                        disabled: _vm.model.id,
                      },
                      on: { change: _vm.onChangeTipo },
                      model: {
                        value: _vm.model.tipo,
                        callback: function ($$v) {
                          _vm.$set(_vm.model, "tipo", $$v)
                        },
                        expression: "model.tipo",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _vm.model.tipo == 1
                ? _c("div", { staticClass: "col-sm-2" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("codVenda"),
                            expression: "errors.first('codVenda')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: {
                              for: "codVenda",
                              title: _vm.$t("__.obrigatorio"),
                            },
                          },
                          [
                            _c("span", { staticStyle: { color: "red" } }, [
                              _vm._v("*"),
                            ]),
                            _vm._v(
                              "\n                            " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Crud.controleReclamacao.edit_vue_html.codVenda"
                                  )
                                ) +
                                "\n                        "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            { name: "focus", rawName: "v-focus" },
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.model.vendaCodigo,
                              expression: "model.vendaCodigo",
                            },
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required",
                              expression: "'required'",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            type: "text",
                            id: "codVenda",
                            name: "codVenda",
                            autocomplete: "off",
                          },
                          domProps: { value: _vm.model.vendaCodigo },
                          on: {
                            change: _vm.onChangeVendaCodigo,
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.model,
                                "vendaCodigo",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.model.tipo == 1
                ? _c("div", { staticClass: "col-sm-4" }, [
                    _c("div", { staticClass: "form-group" }, [
                      _c(
                        "label",
                        {
                          staticClass: "control-label",
                          attrs: { for: "cliente" },
                        },
                        [
                          _vm._v(
                            "\n                            " +
                              _vm._s(
                                _vm.$t(
                                  "__.Crud.controleReclamacao.edit_vue_html.cliente"
                                )
                              ) +
                              "\n                        "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.model.clienteNome,
                            expression: "model.clienteNome",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          id: "cliente",
                          name: "cliente",
                          disabled: "",
                        },
                        domProps: { value: _vm.model.clienteNome },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.model,
                              "clienteNome",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.model.tipo == 1
                ? _c("div", { staticClass: "col-sm-4" }, [
                    _c("div", { staticClass: "form-group" }, [
                      _c(
                        "label",
                        {
                          staticClass: "control-label",
                          attrs: { for: "atendente" },
                        },
                        [
                          _vm._v(
                            "\n                            " +
                              _vm._s(
                                _vm.$t(
                                  "__.Crud.controleReclamacao.edit_vue_html.vendedor"
                                )
                              ) +
                              "\n                        "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.model.atendente,
                            expression: "model.atendente",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          id: "atendente",
                          name: "atendente",
                          disabled: "",
                        },
                        domProps: { value: _vm.model.atendente },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.model,
                              "atendente",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.model.tipo == 2
                ? _c("div", { staticClass: "col-sm-4" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("produtoId"),
                            expression: "errors.first('produtoId')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: {
                              for: "produtoId",
                              title: _vm.$t("__.obrigatorio"),
                            },
                          },
                          [
                            _c("span", { staticStyle: { color: "red" } }, [
                              _vm._v("*"),
                            ]),
                            _vm._v(
                              "\n                            " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Crud.movimentacaoestoque.item_vue_html.produto"
                                  )
                                ) +
                                "\n                        "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("combo-component", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required",
                              expression: "'required'",
                            },
                          ],
                          attrs: {
                            id: "'produtoId'",
                            name: "'produtoId'",
                            data: _vm.produtosOptions,
                          },
                          model: {
                            value: _vm.model.produtoId,
                            callback: function ($$v) {
                              _vm.$set(_vm.model, "produtoId", $$v)
                            },
                            expression: "model.produtoId",
                          },
                        }),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.model.tipo == 2
                ? _c("div", { staticClass: "col-sm-4" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("produtoLoteId"),
                            expression: "errors.first('produtoLoteId')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: {
                              for: "produtoLoteId",
                              title: _vm.$t("__.obrigatorio"),
                            },
                          },
                          [
                            _c("span", { staticStyle: { color: "red" } }, [
                              _vm._v("*"),
                            ]),
                            _vm._v(
                              "\n                            " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Components.parent.venda.item_vue_html.produtoLote"
                                  )
                                ) +
                                "\n                        "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("combo-component", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required",
                              expression: "'required'",
                            },
                          ],
                          attrs: {
                            id: "'produtoLoteId'",
                            name: "'produtoLoteId'",
                            data: _vm.produtoLoteOptions,
                          },
                          model: {
                            value: _vm.model.produtoLoteId,
                            callback: function ($$v) {
                              _vm.$set(_vm.model, "produtoLoteId", $$v)
                            },
                            expression: "model.produtoLoteId",
                          },
                        }),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.model.tipo == 2
                ? _c("div", { staticClass: "col-sm-2" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("quantidade"),
                            expression: "errors.first('quantidade')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: {
                              for: "quantidade",
                              title: _vm.$t("__.obrigatorio"),
                            },
                          },
                          [
                            _c("span", { staticStyle: { color: "red" } }, [
                              _vm._v("*"),
                            ]),
                            _vm._v(
                              "\n                            " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Crud.produto.verLote_vue_html.quantidade"
                                  )
                                ) +
                                "\n                        "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("decimal-component", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required|maior_que_zero",
                              expression: "'required|maior_que_zero'",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: { id: "quantidade", name: "quantidade" },
                          model: {
                            value: _vm.model.quantidade,
                            callback: function ($$v) {
                              _vm.$set(_vm.model, "quantidade", $$v)
                            },
                            expression: "model.quantidade",
                          },
                        }),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.model.tipo == 2
                ? _c("div", { staticClass: "col-sm-12" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("clienteId"),
                            expression: "errors.first('clienteId')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: {
                              for: "clienteId",
                              title: _vm.$t("__.obrigatorio"),
                            },
                          },
                          [
                            _c("span", { staticClass: "color-red" }, [
                              _vm._v("*"),
                            ]),
                            _vm._v(
                              "\n                            " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Components.parent.venda.editSingle_vue_html.cliente"
                                  )
                                ) +
                                "\n                        "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "search-combo-component",
                          {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required",
                                expression: "'required'",
                              },
                            ],
                            attrs: {
                              id: "clienteId",
                              name: "clienteId",
                              actionName: "clientes",
                              addEditId: this.model.clienteId,
                              addNew:
                                !this.model.clienteId && !_vm.requisicaoApp,
                              advancedSearch: !_vm.requisicaoApp,
                              advancedSearchCampos: [
                                { campo: "text", descricao: "Nome" },
                                { campo: "cpf", descricao: "CNPJ/CPF" },
                                { campo: "celular", descricao: "Celular" },
                              ],
                              customComboText: _vm.customComboTextCliente,
                              customView: true,
                              disabled:
                                _vm.requisicaoApp || _vm.somenteConsultaVenda,
                              formAddNewName: _vm.$t(
                                "__.Components.parent.venda.editSingle_vue_html." +
                                  (this.model.clienteId
                                    ? "editCliente"
                                    : "novoCliente")
                              ),
                              filterable: false,
                              propDescription: "nome",
                              service: "Cliente",
                              useCloseModalCallback: true,
                            },
                            on: {
                              addNewItemCombo: _vm.onAddCliente1,
                              openComboEditItem: _vm.openComboEditCliente1,
                              openComboNewItem: _vm.openNewCliente1,
                            },
                            model: {
                              value: _vm.model.clienteId,
                              callback: function ($$v) {
                                _vm.$set(_vm.model, "clienteId", $$v)
                              },
                              expression: "model.clienteId",
                            },
                          },
                          [
                            _vm.loadClienteComponent1
                              ? _c(
                                  "div",
                                  {
                                    attrs: { slot: "component" },
                                    slot: "component",
                                  },
                                  [
                                    _c("clienteComponent", {
                                      ref: "clienteComponent1",
                                      attrs: { CalledByShortCut: true },
                                      on: { "save-ok": _vm.onClienteSaveOk },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]
                        ),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-sm-2" }, [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip",
                        value: _vm.errors.first("dataReclamacao"),
                        expression: "errors.first('dataReclamacao')",
                      },
                    ],
                    staticClass: "form-group",
                  },
                  [
                    _c(
                      "label",
                      {
                        staticClass: "control-label",
                        attrs: {
                          for: "dataReclamacao",
                          title: _vm.$t("__.obrigatorio"),
                        },
                      },
                      [
                        _c("span", { staticStyle: { color: "red" } }, [
                          _vm._v("*"),
                        ]),
                        _vm._v(
                          "\n                            " +
                            _vm._s(
                              _vm.$t(
                                "__.Crud.controleReclamacao.edit_vue_html.dataReclamacao"
                              )
                            ) +
                            "\n                        "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("dateTimePickerComponent", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required",
                          expression: "'required'",
                        },
                      ],
                      attrs: { id: "dataReclamacao", name: "dataReclamacao" },
                      model: {
                        value: _vm.model.dataReclamacao,
                        callback: function ($$v) {
                          _vm.$set(_vm.model, "dataReclamacao", $$v)
                        },
                        expression: "model.dataReclamacao",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-sm-1" }, [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip",
                        value: _vm.errors.first("status"),
                        expression: "errors.first('status')",
                      },
                    ],
                    staticClass: "form-group",
                  },
                  [
                    _c(
                      "label",
                      {
                        staticClass: "control-label",
                        attrs: {
                          for: "status",
                          title: _vm.$t("__.obrigatorio"),
                        },
                      },
                      [
                        _c("span", { staticStyle: { color: "red" } }, [
                          _vm._v("*"),
                        ]),
                        _vm._v(
                          "\n                            " +
                            _vm._s(
                              _vm.$t(
                                "__.Crud.controleReclamacao.edit_vue_html.status"
                              )
                            ) +
                            "\n                        "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("combo-component", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required",
                          expression: "'required'",
                        },
                      ],
                      attrs: {
                        id: "status",
                        name: "status",
                        data: _vm.statusOtions,
                      },
                      model: {
                        value: _vm.model.status,
                        callback: function ($$v) {
                          _vm.$set(_vm.model, "status", $$v)
                        },
                        expression: "model.status",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-sm-3" }, [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip",
                        value: _vm.errors.first(
                          "justificativaControleReclamacaoId"
                        ),
                        expression:
                          "errors.first('justificativaControleReclamacaoId')",
                      },
                    ],
                    staticClass: "form-group",
                  },
                  [
                    _c(
                      "label",
                      {
                        staticClass: "control-label",
                        attrs: {
                          for: "justificativaControleReclamacaoId",
                          title: _vm.$t("__.obrigatorio"),
                        },
                      },
                      [
                        _c("span", { staticStyle: { color: "red" } }, [
                          _vm._v("*"),
                        ]),
                        _vm._v(
                          "\n                            " +
                            _vm._s(
                              _vm.$t(
                                "__.Crud.controleReclamacao.edit_vue_html.justificativaReclamacao"
                              )
                            ) +
                            "\n                        "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("combo-component", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required",
                          expression: "'required'",
                        },
                      ],
                      attrs: {
                        id: "justificativaControleReclamacaoId",
                        name: "justificativaControleReclamacaoId",
                        data: _vm.justificativaReclamacaoOptions,
                      },
                      model: {
                        value: _vm.model.justificativaControleReclamacaoId,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.model,
                            "justificativaControleReclamacaoId",
                            $$v
                          )
                        },
                        expression: "model.justificativaControleReclamacaoId",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-sm-3" }, [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip",
                        value: _vm.errors.first("responsavel"),
                        expression: "errors.first('responsavel')",
                      },
                    ],
                    staticClass: "form-group",
                  },
                  [
                    _c(
                      "label",
                      {
                        staticClass: "control-label",
                        attrs: {
                          for: "responsavel",
                          title: _vm.$t("__.obrigatorio"),
                        },
                      },
                      [
                        _c("span", { staticStyle: { color: "red" } }, [
                          _vm._v("*"),
                        ]),
                        _vm._v(
                          "\n                            " +
                            _vm._s(
                              _vm.$t(
                                "__.Crud.controleReclamacao.edit_vue_html.responsavel"
                              )
                            ) +
                            "\n                        "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.model.responsavel,
                          expression: "model.responsavel",
                        },
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required",
                          expression: "'required'",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: {
                        type: "text",
                        id: "responsavel",
                        name: "responsavel",
                        autocomplete: "off",
                      },
                      domProps: { value: _vm.model.responsavel },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.model,
                            "responsavel",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-sm-3" }, [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip",
                        value: _vm.errors.first(
                          "categoriaControleReclamacaoId"
                        ),
                        expression:
                          "errors.first('categoriaControleReclamacaoId')",
                      },
                    ],
                    staticClass: "form-group",
                  },
                  [
                    _c(
                      "label",
                      {
                        staticClass: "control-label",
                        attrs: {
                          for: "categoriaControleReclamacaoId",
                          title: _vm.$t("__.obrigatorio"),
                        },
                      },
                      [
                        _c("span", { staticStyle: { color: "red" } }, [
                          _vm._v("*"),
                        ]),
                        _vm._v(
                          "\n                            " +
                            _vm._s(
                              _vm.$t(
                                "__.Crud.controleReclamacao.edit_vue_html.categoriaReclamacao"
                              )
                            ) +
                            "\n                        "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("combo-component", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required",
                          expression: "'required'",
                        },
                      ],
                      attrs: {
                        id: "categoriaControleReclamacaoId",
                        name: "categoriaControleReclamacaoId",
                        data: _vm.categoriaReclamacaoOptions,
                      },
                      model: {
                        value: _vm.model.categoriaControleReclamacaoId,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.model,
                            "categoriaControleReclamacaoId",
                            $$v
                          )
                        },
                        expression: "model.categoriaControleReclamacaoId",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-sm-12" }, [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip",
                        value: _vm.errors.first("reclamacao"),
                        expression: "errors.first('reclamacao')",
                      },
                    ],
                    staticClass: "form-group",
                  },
                  [
                    _c(
                      "label",
                      {
                        staticClass: "control-label",
                        attrs: {
                          for: "reclamacao",
                          title: _vm.$t("__.obrigatorio"),
                        },
                      },
                      [
                        _c("span", { staticStyle: { color: "red" } }, [
                          _vm._v("*"),
                        ]),
                        _vm._v(
                          "\n                            " +
                            _vm._s(
                              _vm.$t(
                                "__.Crud.controleReclamacao.edit_vue_html.reclamacao"
                              )
                            ) +
                            "\n                        "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("textarea-component", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required",
                          expression: "'required'",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { id: "reclamacao", name: "reclamacao", rows: 4 },
                      model: {
                        value: _vm.model.reclamacao,
                        callback: function ($$v) {
                          _vm.$set(_vm.model, "reclamacao", $$v)
                        },
                        expression: "model.reclamacao",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-sm-12" }, [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip",
                        value: _vm.errors.first("acao"),
                        expression: "errors.first('acao')",
                      },
                    ],
                    staticClass: "form-group",
                  },
                  [
                    _c(
                      "label",
                      {
                        staticClass: "control-label",
                        attrs: { for: "acao", title: _vm.$t("__.obrigatorio") },
                      },
                      [
                        _c("span", { staticStyle: { color: "red" } }, [
                          _vm._v("*"),
                        ]),
                        _vm._v(
                          "\n                            " +
                            _vm._s(
                              _vm.$t(
                                "__.Crud.controleReclamacao.edit_vue_html.acao"
                              )
                            ) +
                            "\n                        "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("textarea-component", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required",
                          expression: "'required'",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { id: "acao", name: "acao", rows: 4 },
                      model: {
                        value: _vm.model.acao,
                        callback: function ($$v) {
                          _vm.$set(_vm.model, "acao", $$v)
                        },
                        expression: "model.acao",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("fieldset", [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-sm-12 newbtn" }, [
                _c("div", { staticClass: "form-group" }, [
                  !_vm.somenteConsulta
                    ? _c(
                        "button",
                        {
                          staticClass: "btn btn-success",
                          attrs: { type: "submit" },
                        },
                        [
                          _c("i", {
                            staticClass: "fa fa-save",
                            attrs: { "aria-hidden": "true" },
                          }),
                          _vm._v(
                            "\n                            " +
                              _vm._s(_vm.$t("__.Crud.salvar")) +
                              "\n                        "
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.somenteConsulta
                    ? _c(
                        "button",
                        {
                          staticClass: "btn btn-secondary",
                          attrs: { type: "button" },
                          on: { click: _vm.clear },
                        },
                        [
                          _vm._v(
                            "\n                            " +
                              _vm._s(_vm.$t("__.Crud.limpar")) +
                              "\n                        "
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-secondary",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          _vm.cancel()
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n                            " +
                          _vm._s(_vm.$t("__.Crud.voltar")) +
                          "\n                        "
                      ),
                    ]
                  ),
                ]),
              ]),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _c("uploadAnexoComponent", {
        ref: "uploadAnexoComponent",
        attrs: { id: _vm.model.id, serviceUpload: "ControleReclamacaoAnexo" },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }