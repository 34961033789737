enum TiposFranquia {
    Padrao = 0,
    BaseRede = 1,
    BaseAdm = 2,
    Fracionadora = 3,
    Estereis = 4,
    App = 5,
}

export default TiposFranquia;
