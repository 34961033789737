import BaseModel from "./base/baseModel";
import MovimentacaoContaModel from "./movimentacaoContaModel";
import VendaCancelamentoItem from "./vendaCancelamentoItemModel";

export default class VendaCancelamentoModel extends BaseModel {
    id?: number = null;
    vendaId: number = null;
    motivoCancelamentoId: number = null;
    usuarioCancelamentoId?: number = null;
    usuarioCancelamentoNome: string = null;
    observacaoCancelamento: string = null;
    dataCancelamento: Date = null;
    movimentacaoEstoqueId?: number = null;

    movimentacaoContaId?: number = null;
    movimentacaoConta?: MovimentacaoContaModel = null;

    itens: Array<VendaCancelamentoItem> = [];
}
