import actionBarComponent from "@/components/child/actionBar/actionBar.vue";
import checkboxComponent from "@/components/child/form/checkbox.vue";
import comboComponent from "@/components/child/form/combo.vue";
import dataTooltipComponent from "@/components/child/form/datatooltip.vue";
import { Component, Watch } from "@/decorators";
import PaginationModel from "@/models/paginationModel";
import ServicoFiscalModel from "@/models/servicoFiscalModel";
import ServicoModel from "@/models/servicoModel";
import ServicoFiscalService from "@/services/servicoFiscalService";
import ServicoService from "@/services/servicoService";

import EditComponentBase from "../editComponentBase";

@Component({
    components: {
        checkboxComponent,
        dataTooltipComponent,
        comboComponent,
        actionBarComponent,
    },
})
export default class ServicoEditComponent extends EditComponentBase<ServicoModel> {
    private async beforeMount() {
        await this.setProps(new ServicoService(), "servicos_fiscais", new ServicoModel());

        await this.loadServicoFiscal();

        this.loadServicoFiscalItem();
    }

    private servicoFiscalService = new ServicoFiscalService();
    private modelServicosFiscais: Array<ServicoFiscalModel> = [];

    servicoFiscalOptions: Array<Object> = [];
    servicoFiscalItemOptions: Array<Object> = [];

    servicoFiscalId: number = null;

    public async onPreLoadModel() {
        this.servicoFiscalId = 0;

        return true;
    }

    public async onLoadModel(model: ServicoModel) {
        this.servicoFiscalId = model.servicoFiscalItem.servicoFiscalId;
        this.loadServicoFiscalItem();
    }

    private async loadServicoFiscal() {
        try {
            const data = await this.servicoFiscalService.combo().resolveWithJSON<PaginationModel<ServicoFiscalModel>>();

            this.servicoFiscalOptions = data.list.map(item => ({
                value: item.id,
                text: item.codigo + " - " + item.descricao,
            }));
            this.modelServicosFiscais = data.list;
        } catch {}
    }

    private loadServicoFiscalItem() {
        if (this.servicoFiscalId != null && this.servicoFiscalId > 0 && this.modelServicosFiscais != null) {
            if (this.model.servicoFiscalItemId == null) this.model.servicoFiscalItemId = -1;

            if (this.modelServicosFiscais.length > 0) {
                this.servicoFiscalItemOptions = this.modelServicosFiscais
                    .find(p => p.id == this.servicoFiscalId)
                    .servicoFiscalItens.map(item => ({
                        value: item.id,
                        text: item.codigo + " - " + item.descricao,
                    }));
            }
        } else {
            this.servicoFiscalItemOptions = [];
        }
    }

    @Watch("servicoFiscalId")
    private onServicoFiscalIdChanged() {
        this.servicoFiscalItemOptions = [];

        this.loadServicoFiscalItem();
    }
}
