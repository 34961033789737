var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    [
      _c(
        "fieldset-component",
        {
          attrs: {
            title: _vm.$t(
              "__.Components.parent.venda.enderecoEntrega_vue_html.enderecoEntrega"
            ),
            collapsed: false,
          },
        },
        [
          _c("div", { attrs: { slot: "rows" }, slot: "rows" }, [
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col-sm-12" },
                [
                  _c("grid-component", {
                    attrs: {
                      data: _vm.gridData,
                      columns: _vm.gridColumnsEndereco,
                      "show-table-head": false,
                      "show-table-foot": true,
                      "show-sort-icons": false,
                      showSelectCheckbox: true,
                      multipleSelect: false,
                      "show-remove-item": false,
                      selectLikeRadio: true,
                      initialRowsChecked: _vm.initialRowsChecked,
                      showActionColumn: !_vm.somenteConsultaVenda,
                      showAddNewButton: !_vm.somenteConsultaVenda,
                    },
                    on: {
                      "edit-item": _vm.onEditEndereco,
                      "selected-values-changed": _vm.onSelectedModel,
                      "create-item": _vm.onCreateEndereco,
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "fieldset-component",
        {
          attrs: {
            title: _vm.$t(
              "__.Components.parent.venda.enderecoEntrega_vue_html.dadosTransporte"
            ),
            collapsed: false,
          },
        },
        [
          _c("div", { attrs: { slot: "rows" }, slot: "rows" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-sm-4" }, [
                _c("div", { staticClass: "form-group" }, [
                  _c(
                    "label",
                    {
                      staticClass: "control-label",
                      attrs: { for: "pesoBrutoTransportado" },
                    },
                    [
                      _vm._v(
                        "\n                            " +
                          _vm._s(
                            _vm.$t(
                              "__.Components.parent.venda.enderecoEntrega_vue_html.pesoBrutoKg"
                            )
                          ) +
                          "\n                        "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.vendaModel.pesoBrutoTransportado,
                        expression: "vendaModel.pesoBrutoTransportado",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: {
                      type: "number",
                      autocomplete: "off",
                      id: "pesoBrutoTransportado",
                      name: "pesoBrutoTransportado",
                      disabled: _vm.somenteConsultaVenda,
                    },
                    domProps: { value: _vm.vendaModel.pesoBrutoTransportado },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.vendaModel,
                          "pesoBrutoTransportado",
                          $event.target.value
                        )
                      },
                    },
                  }),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { class: "col-sm-4" }, [
                _c("div", { staticClass: "form-group" }, [
                  _c(
                    "label",
                    {
                      staticClass: "control-label",
                      attrs: { for: "pesoLiquidoTransportado" },
                    },
                    [
                      _vm._v(
                        "\n                            " +
                          _vm._s(
                            _vm.$t(
                              "__.Components.parent.venda.enderecoEntrega_vue_html.pesoLiqKg"
                            )
                          ) +
                          "\n                        "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.vendaModel.pesoLiquidoTransportado,
                        expression: "vendaModel.pesoLiquidoTransportado",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: {
                      type: "number",
                      autocomplete: "off",
                      id: "pesoLiquidoTransportado",
                      name: "pesoLiquidoTransportado",
                      disabled: _vm.somenteConsultaVenda,
                    },
                    domProps: { value: _vm.vendaModel.pesoLiquidoTransportado },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.vendaModel,
                          "pesoLiquidoTransportado",
                          $event.target.value
                        )
                      },
                    },
                  }),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { class: "col-sm-4" }, [
                _c("div", { staticClass: "form-group" }, [
                  _c(
                    "label",
                    { staticClass: "control-label", attrs: { for: "volumes" } },
                    [
                      _vm._v(
                        "\n                            " +
                          _vm._s(
                            _vm.$t(
                              "__.Components.parent.venda.enderecoEntrega_vue_html.volumesUn"
                            )
                          ) +
                          "\n                        "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.vendaModel.volumes,
                        expression: "vendaModel.volumes",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: {
                      type: "number",
                      autocomplete: "off",
                      id: "volumes",
                      name: "volumes",
                      disabled: _vm.somenteConsultaVenda,
                    },
                    domProps: { value: _vm.vendaModel.volumes },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.vendaModel, "volumes", $event.target.value)
                      },
                    },
                  }),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-sm-4" }, [
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c(
                      "label",
                      {
                        staticClass: "control-label",
                        attrs: { for: "transportadoraId" },
                      },
                      [
                        _vm._v(
                          "\n                            " +
                            _vm._s(
                              _vm.$t(
                                "__.Components.parent.venda.enderecoEntrega_vue_html.transportadora"
                              )
                            ) +
                            "\n                        "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("combo-component", {
                      attrs: {
                        id: "transportadoraId",
                        name: "transportadoraId",
                        data: _vm.transportadoraOptions,
                        searchable: true,
                        refresh: _vm.loadTransportadora,
                        customView: true,
                        disabled: _vm.somenteConsultaVenda,
                      },
                      model: {
                        value: _vm.vendaModel.transportadoraId,
                        callback: function ($$v) {
                          _vm.$set(_vm.vendaModel, "transportadoraId", $$v)
                        },
                        expression: "vendaModel.transportadoraId",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _vm.transportadorOptions.length > 0
                ? _c("div", { staticClass: "col-sm-4" }, [
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "transportadorId" },
                          },
                          [
                            _vm._v(
                              "\n                            " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Components.parent.venda.enderecoEntrega_vue_html.transportador"
                                  )
                                ) +
                                "\n                        "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("combo-component", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required",
                              expression: "'required'",
                            },
                          ],
                          attrs: {
                            id: "transportadorId",
                            name: "transportadorId",
                            data: _vm.transportadorOptions,
                            searchable: true,
                            refresh: _vm.loadTransportador,
                            customView: true,
                            disabled: _vm.somenteConsultaVenda,
                          },
                          model: {
                            value: _vm.vendaModel.transportadorId,
                            callback: function ($$v) {
                              _vm.$set(_vm.vendaModel, "transportadorId", $$v)
                            },
                            expression: "vendaModel.transportadorId",
                          },
                        }),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "col-sm-4" }, [
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c(
                      "label",
                      {
                        staticClass: "control-label",
                        attrs: { for: "taxaEntrega" },
                      },
                      [
                        _vm._v(
                          "\n                            " +
                            _vm._s(
                              _vm.$t(
                                "__.Components.parent.venda.enderecoEntrega_vue_html.taxaEntrega"
                              )
                            ) +
                            "\n                        "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("moeda-component", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required",
                          expression: "'required'",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: {
                        id: "taxaEntrega",
                        name: "taxaEntrega",
                        disabled: _vm.somenteConsultaVenda,
                      },
                      model: {
                        value: _vm.vendaModel.taxaEntrega,
                        callback: function ($$v) {
                          _vm.$set(_vm.vendaModel, "taxaEntrega", $$v)
                        },
                        expression: "vendaModel.taxaEntrega",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-sm-4" }, [
                _c("div", { staticClass: "form-group" }, [
                  _c(
                    "label",
                    {
                      staticClass: "control-label",
                      attrs: { for: "codigoRastreio" },
                    },
                    [
                      _vm._v(
                        "\n                            " +
                          _vm._s(
                            _vm.$t(
                              "__.Components.parent.venda.enderecoEntrega_vue_html.codigoRastreio"
                            )
                          ) +
                          "\n                        "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.vendaModel.codigoRastreio,
                        expression: "vendaModel.codigoRastreio",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: {
                      type: "text",
                      autocomplete: "off",
                      id: "codigoRastreio",
                      name: "codigoRastreio",
                      disabled: _vm.somenteConsultaVenda,
                    },
                    domProps: { value: _vm.vendaModel.codigoRastreio },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.vendaModel,
                          "codigoRastreio",
                          $event.target.value
                        )
                      },
                    },
                  }),
                ]),
              ]),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "shortcutComponent",
        {
          ref: "shortcutComponent",
          attrs: {
            btOkName: _vm.$t("__.Crud.salvar"),
            modalClass: "lm-modal",
            showAddNew: false,
          },
          on: { "selection-item": _vm.onSaveEndereco },
        },
        [
          (_vm.editingModel || _vm.addNewModel) && _vm.model != null
            ? _c("div", { attrs: { slot: "component" }, slot: "component" }, [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-sm-4" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("cep"),
                            expression: "errors.first('cep')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "cep" },
                          },
                          [
                            _vm._v(
                              "\n                            " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Components.parent.venda.enderecoEntrega_vue_html.cep"
                                  )
                                ) +
                                "\n                        "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("cep-field-component", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "cep",
                              expression: "'cep'",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: { id: "cep", name: "cep" },
                          on: {
                            "cep-changed": _vm.onCepChanged,
                            "cep-invalid": _vm.onCepInvalid,
                          },
                          model: {
                            value: _vm.model.cep,
                            callback: function ($$v) {
                              _vm.$set(_vm.model, "cep", $$v)
                            },
                            expression: "model.cep",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-3" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("estadoId"),
                            expression: "errors.first('estadoId')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "estadoId" },
                          },
                          [
                            _vm._v(
                              "\n                            " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Components.parent.venda.enderecoEntrega_vue_html.estado"
                                  )
                                ) +
                                "\n                        "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("combo-component", {
                          attrs: {
                            id: "estadoId",
                            name: "estadoId",
                            data: _vm.ufOptions,
                            searchable: true,
                          },
                          model: {
                            value: _vm.model.estadoId,
                            callback: function ($$v) {
                              _vm.$set(_vm.model, "estadoId", $$v)
                            },
                            expression: "model.estadoId",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-5" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("cidadeId"),
                            expression: "errors.first('cidadeId')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "cidadeId" },
                          },
                          [
                            _vm._v(
                              "\n                            " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Components.parent.venda.enderecoEntrega_vue_html.cidade"
                                  )
                                ) +
                                "\n                        "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("combo-component", {
                          attrs: {
                            id: "cidadeId",
                            name: "cidadeId",
                            data: _vm.cidadesEntregaOptions,
                            searchable: true,
                          },
                          model: {
                            value: _vm.model.cidadeId,
                            callback: function ($$v) {
                              _vm.$set(_vm.model, "cidadeId", $$v)
                            },
                            expression: "model.cidadeId",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-sm-4" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("endereco"),
                            expression: "errors.first('endereco')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "endereco" },
                          },
                          [
                            _vm._v(
                              "\n                            " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Components.parent.venda.enderecoEntrega_vue_html.endereco"
                                  )
                                ) +
                                "\n                        "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.model.endereco,
                              expression: "model.endereco",
                            },
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required|max:256",
                              expression: "'required|max:256'",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            autocomplete: "off",
                            type: "text",
                            id: "endereco",
                            name: "endereco",
                          },
                          domProps: { value: _vm.model.endereco },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.model,
                                "endereco",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-4" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("numero"),
                            expression: "errors.first('numero')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "numero" },
                          },
                          [
                            _vm._v(
                              "\n                            " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Components.parent.venda.enderecoEntrega_vue_html.nro"
                                  )
                                ) +
                                "\n                        "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.model.numero,
                              expression: "model.numero",
                            },
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "max:256",
                              expression: "'max:256'",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            autocomplete: "off",
                            type: "text",
                            id: "numero",
                            name: "numero",
                          },
                          domProps: { value: _vm.model.numero },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(_vm.model, "numero", $event.target.value)
                            },
                          },
                        }),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-4" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("bairro"),
                            expression: "errors.first('bairro')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "bairro" },
                          },
                          [
                            _vm._v(
                              "\n                            " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Components.parent.venda.enderecoEntrega_vue_html.bairro"
                                  )
                                ) +
                                "\n                        "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.model.bairro,
                              expression: "model.bairro",
                            },
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "max:256",
                              expression: "'max:256'",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            autocomplete: "off",
                            type: "text",
                            id: "bairro",
                            name: "bairro",
                          },
                          domProps: { value: _vm.model.bairro },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(_vm.model, "bairro", $event.target.value)
                            },
                          },
                        }),
                      ]
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-sm-8" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("complemento"),
                            expression: "errors.first('complemento')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "complemento" },
                          },
                          [
                            _vm._v(
                              "\n                            " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Components.parent.venda.enderecoEntrega_vue_html.complemento"
                                  )
                                ) +
                                "\n                        "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.model.complemento,
                              expression: "model.complemento",
                            },
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "max:256",
                              expression: "'max:256'",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            autocomplete: "off",
                            type: "text",
                            id: "complemento",
                            name: "complemento",
                          },
                          domProps: { value: _vm.model.complemento },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.model,
                                "complemento",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-4" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("enderecoPadrao"),
                            expression: "errors.first('enderecoPadrao')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "enderecoPadrao" },
                          },
                          [
                            _vm._v(
                              "\n                            " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Components.parent.venda.enderecoEntrega_vue_html.enderecoPadrao"
                                  )
                                ) +
                                "\n                        "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("checkbox-component", {
                          attrs: {
                            id: "enderecoPadrao",
                            name: "enderecoPadrao",
                          },
                          model: {
                            value: _vm.model.enderecoPadrao,
                            callback: function ($$v) {
                              _vm.$set(_vm.model, "enderecoPadrao", $$v)
                            },
                            expression: "model.enderecoPadrao",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
              ])
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }