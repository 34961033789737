import BaseModel from "./base/baseModel";
import FormaFarmaceuticaModel from "./formaFarmaceuticaModel";
import IncompatibilidadeKitEmbalagemModel from "./incompatibilidadeKitEmbalagemModel";
import KitEmbalagemCapsulaModel from "./kitEmbalagemCapsulaModel";
import KitEmbalagemItemModel from "./kitEmbalagemItemModel";
import UnidadeMedidaModel from "./unidadeMedidaModel";

export default class KitEmbalagemModel extends BaseModel {
    id?: number = null;
    franquiaId?: number = null;
    descricao: string = null;
    descricaoRotulo: string = null;
    ativo = true;
    observacao: string = null;
    kitEmbalagemItens: Array<KitEmbalagemItemModel> = [];
    kitItemId?: number = null;
    unidadeMedidaId?: number = null;
    unidadeMedida: UnidadeMedidaModel = null;
    volumeMaximo: number = null;
    formasFarmaceuticas: Array<FormaFarmaceuticaModel> = [];
    formaFarmaceuticaId?: number = null;
    kitEmbalagemPaiId?: number = null;
    configuracaoRotuloId?: number = null;
    quantidadeMaximaBiscoitos?: number = null;
    kitEmbalagemCapsulas: Array<KitEmbalagemCapsulaModel> = [];
    kitEmbalagemIncompatibilidade?: Array<IncompatibilidadeKitEmbalagemModel> = [];
}
