import { Module } from "vuex";

import { AppState } from "../store";

import actions from "./actions";
import mutations from "./mutations";
import { ManipulacaoContextState } from "./types";

export type ManipulacaoContextGetters = "GET_MANIPULACAO_CONTEXT";

const manipulacaoContext: Module<Map<string, ManipulacaoContextState>, AppState> = {
    state: new Map<string, ManipulacaoContextState>(),
    actions,
    mutations,
    getters: {
        GET_MANIPULACAO_CONTEXT: store => (uuid: string) => {
            return store.get(uuid);
        },
    },
};

export default manipulacaoContext;
