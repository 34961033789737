import Vue from "vue";

import { Component } from "@/decorators";
import LoginService from "@/services/loginService";
import { SessionActions } from "@/store/sessionStore";

import "./login.scss";

@Component
export default class LoginComponent extends Vue {
    private service = new LoginService();
    private email = "";
    private senha = "";

    private async onLogin() {
        try {
            const isValid = await this.$validator.validateAll();
            if (isValid) {
                const data = await this.service
                    .login(this.email, this.senha)
                    .withLoading()
                    .resolveWithJSON<{ token: string }>();

                this.$store.dispatch(SessionActions.LOGIN, data.token);

                this.$router.push("/");
            }
        } catch {}
    }
}
