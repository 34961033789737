enum SituacaoCompra {
    Orcamento = 1,
    AguardandoCotacao = 10,
    Analise = 20,
    Aprovado = 30,
    AguardandoRecebimento = 40,
    Concluido = 50,
    Cancelado = 99,
}

export default SituacaoCompra;
