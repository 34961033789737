import { saveAs } from "file-saver";

export default class FileHelper {
    public static download(data: Blob, fileName: string, type = "") {
        if (!type) {
            type = "text/csv;charset=utf-8;";
        }

        const blob = new Blob([data], { type: type });
        saveAs(blob, fileName);
    }

    public static downloadPDF(path: string) {
        const regex = /\/([^\/]+)$/i;
        const match = path.match(regex);

        saveAs(path, match[1]);
    }

    public static async openBlank(response: Response) {
        const a = document.createElement("a");
        document.body.appendChild(a);
        a.style.display = "none";

        const blob = await response.blob();

        const urlFile = window.URL.createObjectURL(blob);
        a.href = urlFile;
        a.target = "_blank";
        a.click();
        window.URL.revokeObjectURL(urlFile);
    }
}
