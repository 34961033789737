import Vue from "vue";

import actionBarComponent from "@/components/child/actionBar/actionBar.vue";
import checkboxComponent from "@/components/child/form/checkbox.vue";
import comboComponent from "@/components/child/form/combo.vue";
import { Component, Prop } from "@/decorators";
import PaginationModel from "@/models/paginationModel";
import TelaModel from "@/models/telaModel";
import TelaService from "@/services/telaService";

import "../crud.scss";

@Component({
    components: {
        comboComponent,
        checkboxComponent,
        actionBarComponent,
    },
})
export default class TelaEditComponent extends Vue {
    private service = new TelaService();

    model = new TelaModel();
    modelId = 0;

    paisOptions: Array<Object> = [];

    @Prop({ type: Boolean, default: false }) isModal: boolean;

    public async load() {
        this.model = new TelaModel();

        if (this.modelId > 0) {
            try {
                const data = await this.service.get(this.modelId).withLoading().resolveWithJSON<TelaModel>();

                this.model.updateFrom(data);
            } catch {
                this.$router.back();
            }
        }
    }

    private async loadTelas() {
        try {
            const data = await this.service.combo().resolveWithJSON<PaginationModel<TelaModel>>();

            this.paisOptions = data.list.map(item => ({
                value: item.id,
                text: item.descricao + (item.pai !== null ? " - " + item.pai.descricao : ""),
            }));
        } catch {}
    }

    public async save() {
        try {
            const isValid = await this.$validator.validateAll();
            if (isValid) {
                const sucesso = await this.service[!this.model.id ? "insert" : "update"](this.model)
                    .withLoading()
                    .resolveWithoutJSON();

                if (sucesso) {
                    await this.$showInclusaoUpdate(this.model.id);
                    if (this.isModal) {
                        this.$emit("save-ok", this.model);
                    } else {
                        this.$router.back();
                    }
                }
            } else {
                this.$focusErrorField();
            }
        } catch {}
    }

    private cancel() {
        this.$router.back();
    }

    private mounted() {
        Promise.all([this.loadTelas()])
            .withLoading()
            .then(() => {
                if (!this.isModal && this.$route.params.id) {
                    this.modelId = +this.$route.params.id;
                    this.load();
                }
            })
            .catch(() => {});
    }
}
