import BaseModel from "./base/baseModel";
import ClienteModel from "./clienteModel";
import MotivoCancelamentoNF from "./enum/motivoCancelamentoNF";
import NaturezaOperacoes from "./enum/naturezaOperacoes";
import StatusEnvioNF from "./enum/statusEnvioNF";
import TipoCadastro from "./enum/tipoCadastro";
import TipoNotaFiscal from "./enum/tipoNotaFiscal";
import TipoOperacao from "./enum/tipoOperacao";
import TipoRPS from "./enum/tipoRPS";
import FornecedorModel from "./fornecedorModel";
import FranquiaModel from "./franquiaModel";
import { Pagamento } from "./notaFiscalEletronicaEmissaoModel";
import NotaFiscalSaidaItemModel from "./notaFiscalSaidaItemModel";
import NotaFiscalSaidaTotalICMS from "./notaFiscalSaidaTotalICMS";
import NotaFiscalSaidaTotalISSQN from "./notaFiscalSaidaTotalISSQN";
import VolumeModel from "./volumeModel";

export default class NotaFiscalSaidaModel extends BaseModel {
    id?: number = null;
    dataInclusao: Date = null;
    dataEmissao: Date = null;
    vendaId?: number = null;
    codigoVenda?: number = null;
    franquiaId: number = null;
    franquia: FranquiaModel = null;
    tipoCadastro: TipoCadastro = 0;
    fornecedorId = 0;
    fornecedor: FornecedorModel = null;
    fornecedorNome: string = null;
    clienteId = 0;
    cliente: ClienteModel = null;
    clienteNome: string = null;
    transportadoraId?: number = null;
    transportadoraNome?: string = null;
    valorTotal: number = null;
    contaReceberId?: number = null;
    notaFiscalSaidaItens: Array<NotaFiscalSaidaItemModel> = [];
    notaFiscalSaidaTotalISSQN: NotaFiscalSaidaTotalISSQN = null;
    notaFiscalSaidaTotalICMS: NotaFiscalSaidaTotalICMS = null;
    movimentacaoEstoqueId?: number = null;
    naturezaOperacoes: NaturezaOperacoes = null;
    tipoRPS: TipoRPS = null;
    statusEnvio: StatusEnvioNF = null;
    statusEnvioDescricao: string = null;
    searchKey: string = null;
    atividade: string = null;
    codigoVerificacao: string = null;
    tipo: TipoNotaFiscal = null;
    tipoDescricao: string = null;
    pdfLink: string = null;
    pdfSimplificadoLink: string = null;
    xmlLink: string = null;
    motivoCancelamento?: MotivoCancelamentoNF = null;
    motivoDescricao: string = null;
    numero?: number = null;
    numeroRps?: number = null;
    serie?: number = null;
    chave: string = null;
    recibo: string = null;
    protocolo: string = null;
    naturezaOperacaoId?: number = null;

    emitidoAPI = false;

    tipoOperacao: TipoOperacao = 0;
    modelo = 55;
    finalidadeEmissao = 0;
    indicadorPresenca = 0;
    indicativoIntermedio = 0;
    indicadorOperacao = 0;
    observacao: string = null;
    modalidadeFrete = 0;
    transportadoraPlacaVeiculo: string = null;

    volumes: VolumeModel[] = [];
    pagamentos: Pagamento[] = [];

    valorTroco = 0;

    // Retensões
    valorPIS = 0;
    valorCOFINS = 0;
    valorCSLL = 0;
    valorBaseIRRF = 0;
    valorIRRF = 0;
    valorBasePrev = 0;
    valorPrev = 0;

    chaveReferenciada: string = null;
    temCartaCorrecao = false;
    usuarioCriacaoNome: number = null;
}

export interface NotaFiscalSaidaListParameters {
    statusEnvio?: number;
    tipoNota?: number;
    emissaoDe?: string;
    emissaoAte?: string;
}
