import Vue from "vue";
import { mapGetters, mapState } from "vuex";

import actionBarComponent from "@/components/child/actionBar/actionBar.vue";
import comboComponent from "@/components/child/form/combo.vue";
import dateTimePickerComponent from "@/components/child/form/datetimepicker.vue";
import fieldsetComponent from "@/components/child/form/fieldset.vue";
import gridComponent from "@/components/child/grid/grid.vue";
import { GridColumn, GridColumnType } from "@/components/child/grid/gridColumn";
import { Component, Watch } from "@/decorators";
import PreLoadPackModel from "@/models/auxiliar/preLoadPackModel";
import ConfiguracaoFranquiaModel from "@/models/configuracaoFranquia/configuracaoFranquiaModel";
import Configuracoes from "@/models/enum/configuracao/configuracoes";
import TiposMovimentacaoEstoque from "@/models/enum/tiposMovimentacaoEstoque";
import EstoqueModel from "@/models/estoqueModel";
import MotivoMovimentacaoEstoqueModel from "@/models/motivoMovimentacaoEstoque";
import MovimentacaoEstoqueItemModel from "@/models/movimentacaoEstoqueItemModel";
import MovimentacaoEstoqueModel from "@/models/movimentacaoEstoqueModel";
import PaginationModel from "@/models/paginationModel";
import EstoqueService from "@/services/estoqueService";
import MotivoMovimentacaoEstoqueService from "@/services/motivoMovimentacaoEstoque";
import MovimentacaoEstoqueService from "@/services/movimentacaoEstoqueService";
import { AppState, Getters } from "@/store/store";

import MovimentacaoEstoqueItemComponent from "./item";
import movimentacaoEstoqueItemComponent from "./item.vue";

import "../crud.scss";

@Component({
    components: {
        comboComponent,
        movimentacaoEstoqueItemComponent,
        fieldsetComponent,
        dateTimePickerComponent,
        gridComponent,
        actionBarComponent,
    },
    computed: {
        ...mapState({
            preLoadList: (state: AppState) => state.preLoad.preLoadList,
        }),
        ...mapGetters(["HAS_PERMISSAO_PERFIL", "GET_CONFIG_FRANQUIA"] as Getters),
    },
})
export default class MovimentacaoEstoqueEditComponent extends Vue {
    // State computed props
    preLoadList: PreLoadPackModel;
    HAS_PERMISSAO_PERFIL: (isRede: boolean, isFranqueador: boolean, isSuporte: boolean) => Promise<boolean>;
    GET_CONFIG_FRANQUIA: (configuracao: Configuracoes) => ConfiguracaoFranquiaModel;

    private service = new MovimentacaoEstoqueService();
    private movimentacaoEstoqueItemComponent: MovimentacaoEstoqueItemComponent = null;

    model = new MovimentacaoEstoqueModel();
    modelId = 0;
    isAdmin = false;

    tiposMovimentacao = TiposMovimentacaoEstoque;
    tiposMovimentacaoOptions: Array<Object> = [
        { text: "Entrada", value: TiposMovimentacaoEstoque.Entrada },
        { text: "Sa\u00edda", value: TiposMovimentacaoEstoque.Saida },
        { text: "Transfer\u00eancia", value: TiposMovimentacaoEstoque.Transferencia },
    ];

    estoquesOptions: Array<Object> = [];
    estoquesDestinoOptions: Array<Object> = [];
    motivoMovimentacaoEstoqueOptions: Array<Object> = [];

    get gridColumns(): Array<GridColumn> {
        return [
            new GridColumn("produtoDescricao", "Descri\u00e7\u00e3o", GridColumnType.String),
            new GridColumn("quantidade", "Quantidade", GridColumnType.DecimalPonto),
            new GridColumn("valorUnitario", "Valor Unit\u00e1rio", GridColumnType.Money),
            new GridColumn("loteDescricao", "Lote", GridColumnType.String),
        ];
    }

    constructor() {
        super();

        this.model.dataMovimentacao = new Date();
        this.model.tipoMovimentacao = TiposMovimentacaoEstoque.Entrada;
    }

    private async load() {
        this.model = new MovimentacaoEstoqueModel();

        if (this.modelId > 0) {
            try {
                const data = await this.service
                    .get(this.modelId)
                    .withLoading()
                    .resolveWithJSON<MovimentacaoEstoqueModel>();

                this.model.updateFrom(data);
                this.model.movimentacaoEstoqueItens.forEach(p => {
                    p.loteDescricao =
                        p.produtoLote.sequencialCompra > 1
                            ? `${p.produtoLote.descricao} (${p.produtoLote.sequencialCompra})`
                            : p.produtoLote.descricao;
                });
            } catch {
                this.$router.back();
            }
        } else {
            this.model.dataMovimentacao = new Date();
            this.model.tipoMovimentacao = TiposMovimentacaoEstoque.Entrada;
        }

        await this.loadEstoques();
    }

    private async loadEstoques() {
        try {
            this.estoquesOptions = this.preLoadList.estoques.map(item => ({
                value: item.id,
                text: item.descricao,
                franquiaId: item.franquiaId,
            }));

            if (this.model.tipoMovimentacao != TiposMovimentacaoEstoque.Transferencia) {
                this.estoquesDestinoOptions = this.estoquesOptions;
            } else {
                const data = await new EstoqueService()
                    .listEstoqueDestino()
                    .withLoading()
                    .resolveWithJSON<PaginationModel<EstoqueModel>>();

                this.estoquesDestinoOptions = data.list.map(item => ({
                    value: item.id,
                    text: item.descricao,
                    franquiaId: item.franquiaId,
                }));
            }
        } catch {}
    }

    private async loadMotivoMovimentacaoEstoque() {
        try {
            const data = await new MotivoMovimentacaoEstoqueService()
                .combo()
                .withLoading()
                .resolveWithJSON<PaginationModel<MotivoMovimentacaoEstoqueModel>>();
            this.motivoMovimentacaoEstoqueOptions = data.list.map(item => ({ value: item.id, text: item.descricao }));
        } catch {}
    }

    private async save() {
        try {
            const isValid = await this.$validator.validateAll();
            if (isValid) {
                if (
                    this.model.tipoMovimentacao == TiposMovimentacaoEstoque.Transferencia &&
                    this.model.estoqueOrigemId == this.model.estoqueDestinoId
                ) {
                    await this.$showError(
                        this.$t("__.Crud.formulapadraomanipulacao.item_vue_html.estq"),
                        this.$t("__.ts.estqDestinoNaoPodeMesmoOrig"),
                    );
                } else if (this.model.movimentacaoEstoqueItens.length == 0) {
                    await this.$showError(this.$t("__.ts.prods"), this.$t("__.ts.incluaUmLote"));
                } else {
                    let sucesso = false;
                    let transferenciaFiliais = false;

                    if (this.model.tipoMovimentacao == TiposMovimentacaoEstoque.Transferencia) {
                        const estoqueDestino = this.estoquesDestinoOptions.find(
                            p => p["value"] == this.model.estoqueDestinoId,
                        );
                        const estoqueOrigem = this.estoquesOptions.find(p => p["value"] == this.model.estoqueOrigemId);

                        transferenciaFiliais = estoqueOrigem["franquiaId"] != estoqueDestino["franquiaId"];
                    }

                    if (transferenciaFiliais) {
                        sucesso = await this.service
                            .inserirTransferenciaFiliais(this.model)
                            .withLoading()
                            .resolveWithoutJSON();
                    } else {
                        sucesso = await this.service[!this.model.id ? "insert" : "update"](this.model)
                            .withLoading()
                            .resolveWithoutJSON();
                    }

                    if (sucesso) {
                        await this.$showInclusaoUpdate(this.model.id);
                        this.$router.back();
                    }
                }
            } else {
                this.$focusErrorField();
            }
        } catch {}
    }

    private cancel() {
        this.$router.back();
    }

    private onRemoveItem(model: MovimentacaoEstoqueItemModel) {
        this.model.movimentacaoEstoqueItens = this.model.movimentacaoEstoqueItens.filter(p => p != model);
    }

    private onCreateItem() {
        this.movimentacaoEstoqueItemComponent.show(
            this.model.tipoMovimentacao,
            this.model.estoqueOrigemId ?? this.model.estoqueDestinoId,
        );
    }

    private async onInclusaoItem(model: MovimentacaoEstoqueItemModel) {
        const loteJaExistente = this.model.movimentacaoEstoqueItens.some(p => p.produtoLote.id == model.produtoLote.id);

        if (loteJaExistente) {
            const response = await this.$showQuestion(
                this.$t("__.ts.loteJaExist"),
                this.$t("__.ts.oLote") + model.produtoLote.descricao + this.$t("__.ts.jaInclNessaMoviment"),
            );
            if (response) {
                this.model.movimentacaoEstoqueItens = this.model.movimentacaoEstoqueItens.filter(
                    p => p.produtoLote.id != model.produtoLote.id,
                );
            }
            this.model.movimentacaoEstoqueItens.push(model);
            this.movimentacaoEstoqueItemComponent.hide();
        } else {
            this.model.movimentacaoEstoqueItens.push(model);
            this.movimentacaoEstoqueItemComponent.hide();
        }
    }

    private onTipoMovimentacaoChanged(tipoMovimentacao: TiposMovimentacaoEstoque) {
        switch (tipoMovimentacao) {
            case TiposMovimentacaoEstoque.Entrada:
                this.model.estoqueOrigemId = null;
                this.model.estoqueDestinoId = this.GET_CONFIG_FRANQUIA(Configuracoes.EstoquePadrao).estoqueId;
                break;
            case TiposMovimentacaoEstoque.Saida:
            case TiposMovimentacaoEstoque.Transferencia:
                this.model.estoqueOrigemId = this.GET_CONFIG_FRANQUIA(Configuracoes.EstoquePadrao).estoqueId;
                this.model.estoqueDestinoId = null;
                break;
        }
    }

    private async reverterMovimentacao() {
        try {
            const sucesso = await this.service.reverterMovimentacao(this.model.id).withLoading().resolveWithoutJSON();
            if (sucesso) {
                await this.$showSuccess(
                    "Movimentação de estoque revertida",
                    "Movimentação de estoque foi revertida com sucesso",
                );
                this.$router.back();
            }
        } catch {}
    }

    @Watch("model.tipoMovimentacao")
    private async tipoMovimentacaoChanged() {
        if (this.model.tipoMovimentacao) {
            await this.loadEstoques();
        }
    }

    private async mounted() {
        this.movimentacaoEstoqueItemComponent = this.$refs
            .movimentacaoEstoqueItemComponent as MovimentacaoEstoqueItemComponent;

        await this.loadMotivoMovimentacaoEstoque();
        if (this.$route.params.id) {
            this.modelId = +this.$route.params.id;
        }

        this.isAdmin = await this.HAS_PERMISSAO_PERFIL(false, false, true);
        await this.load();
    }
}
