var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "dropdownComponent",
    {
      attrs: {
        title: _vm.$t(
          "__.Components.child.rascunho.rascunho_vue_html.rascunho"
        ),
        menuSide: "RIGHT",
      },
    },
    [
      _c("i", {
        staticClass: "fab fa-firstdraft fa-2x",
        staticStyle: { "margin-right": "8px" },
        attrs: { slot: "button", "aria-hidden": "true" },
        slot: "button",
      }),
      _vm._v(" "),
      _c("template", { slot: "itens" }, [
        _c("li", [
          _c("div", { on: { click: _vm.save } }, [
            _vm._v(
              _vm._s(
                _vm.$t(
                  "__.Components.child.rascunho.rascunho_vue_html.salvarRascunho"
                )
              )
            ),
          ]),
        ]),
        _vm._v(" "),
        _vm.rascunhos.length > 0
          ? _c("li", [
              _c("div", [
                _vm._v(
                  "\n                " +
                    _vm._s(
                      _vm.$t(
                        "__.Components.child.rascunho.rascunho_vue_html.selecionar"
                      )
                    ) +
                    "\n                "
                ),
                _c("i", {
                  staticClass: "fa fa-chevron-right",
                  staticStyle: { "margin-left": "auto" },
                }),
                _vm._v(" "),
                _c(
                  "ul",
                  {
                    staticClass:
                      "custom-dropdown-menu custom-dropdown-submenu-right",
                  },
                  _vm._l(_vm.rascunhos, function (item) {
                    return _c(
                      "li",
                      {
                        key: item.id,
                        on: {
                          click: function () {
                            return _vm.onLoad(item.nome)
                          },
                        },
                      },
                      [_c("div", [_vm._v(_vm._s(item.nome))])]
                    )
                  })
                ),
              ]),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.rascunhos.length > 0
          ? _c("li", [
              _c("div", [
                _vm._v(
                  "\n                " +
                    _vm._s(
                      _vm.$t(
                        "__.Components.child.rascunho.rascunho_vue_html.remover"
                      )
                    ) +
                    "\n                "
                ),
                _c("i", {
                  staticClass: "fa fa-chevron-right",
                  staticStyle: { "margin-left": "auto" },
                }),
                _vm._v(" "),
                _c(
                  "ul",
                  {
                    staticClass:
                      "custom-dropdown-menu custom-dropdown-submenu-right",
                  },
                  _vm._l(_vm.rascunhos, function (item) {
                    return _c(
                      "li",
                      {
                        key: item.id,
                        on: {
                          click: function () {
                            return _vm.onDelete(item.nome)
                          },
                        },
                      },
                      [_c("div", [_vm._v(_vm._s(item.nome))])]
                    )
                  })
                ),
              ]),
            ])
          : _vm._e(),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }