import BaseModel from "./base/baseModel";
import ClinicaModel from "./clinica/clinicaModel";
import ConvenioFaixaModel from "./convenioFaixaModel";
import TipoConvenio from "./enum/tipoConvenio";
import TipoDesconto from "./enum/TipoDesconto";
import FranquiaModel from "./franquiaModel";

export default class ConvenioModel extends BaseModel {
    constructor() {
        super()
        this.validadeFim.setDate(this.validadeFim.getDate() + 7)
    }

    id?: number = null
    usuarioCriacaoId?: number = null
    dataCriacao?: Date = new Date()
    usuarioModificacaoId?: number = null
    dataModificacao?: Date = new Date()
    descricao?: string = ''
    validadeInicio?: Date = new Date()
    validadeFim?: Date = new Date()
    tipoConvenio?: TipoConvenio = TipoConvenio.Franquia
    clinicas?: Array<ClinicaModel> = []
    franquias?: Array<FranquiaModel> = []
    franquiaId?: number = null
    clinicaId?: number = null
    tipoDescontoCliente?: TipoDesconto = TipoDesconto.Dinheiro
    tipoDescontoVeterinario?: TipoDesconto = TipoDesconto.Dinheiro
    faixas?: Array<ConvenioFaixaModel> = []
}