import VendaHistoricoModel from "../../models/historico/vendaHistorico/vendaHistoricoModel";
import PaginationModel from "../../models/paginationModel";
import nfetch from "../../utils/events/nfetch";

export default class VendaHistoricoService {
    protected controllerName: string;

    constructor() {
        this.controllerName = "VendaHistorico";
    }

    public list(
        filterKey: string,
        sortKey: string,
        sortOrder: string,
        pageIndex: number,
        pageSize: number,
        continuo: string = null,
        dataVenda: Date = null,
    ): Promise<PaginationModel<VendaHistoricoModel>> {
        const encodedFIlter = encodeURIComponent(filterKey);

        let request = `/${this.controllerName}/List?filterKey=${encodedFIlter}&sortKey=${sortKey}&sortOrder=${sortOrder}&pageIndex=${pageIndex}&pageSize=${pageSize}`;

        if (continuo) request += `&continuo=${continuo}`;

        if (dataVenda) request += `&dataVenda=${encodeURIComponent(dataVenda.toDateString())}`;

        return nfetch(request, {
            credentials: "same-origin",
        }).then(response => {
            return response.json() as Promise<PaginationModel<VendaHistoricoModel>>;
        });
    }

    public get(id: string): Promise<VendaHistoricoModel> {
        //@ts-ignore
        return nfetch(`/${this.controllerName}/Get?id=${id}`, {
            credentials: "same-origin",
        })
            .then((response: Response) => {
                if (response.ok) {
                    return response;
                }
                return null;
            })
            .then(response => {
                return response.json() as Promise<VendaHistoricoModel>;
            })
            .catch(error => {});
    }
}
