import BaseModel from "../base/baseModel";
import Setor from "../enum/setor";
import TipoEtapa from "../enum/tipoEtapa";

export default class EtapaPCPModel extends BaseModel {
    id?: number = null;
    descricao: string = null;
    ordem: number = null;
    ativo = true;
    obrigatorio = true;
    tipo: TipoEtapa = null;
    tipoDescricao: string = null;
    setor: Setor = null;
}
