import BaseModel from "./../base/baseModel";

//pacote de arrays usados normalmente em combos e listas simples
export default class PaginationPackModel extends BaseModel {
    routePath = "";
    //padrao do list
    filterKey = "";
    sortKey = "";
    sortOrder = "";
    pageIndex = 1;
    pageSize = 999999;
    //outros parametros
    filtros: Array<object>;
}
