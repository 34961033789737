var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "datetimepicker",
      class: [
        "input-group",
        "date",
        "datetimepicker-component",
        { "date-grid": _vm.gridEditable },
      ],
    },
    [
      _c("input", {
        ref: "input",
        staticClass: "form-control",
        attrs: {
          autocomplete: "off",
          type: "text",
          id: _vm.id,
          name: _vm.name,
          disabled: _vm.disabled,
          maxlength: this.pickTime ? 18 : 10,
        },
      }),
      _vm._v(" "),
      _c(
        "span",
        {
          class: ["input-group-addon", { "span-grid": _vm.gridEditable }],
          on: {
            click: function ($event) {
              _vm.onClearButtonClick($event)
            },
          },
        },
        [_c("span", { staticClass: "glyphicon glyphicon-remove" })]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }