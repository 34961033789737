var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "crud crud-list" },
    [
      _vm.showInfos
        ? _c("div", { staticClass: "info-div" }, [
            _c("h1", { staticClass: "title" }, [
              _vm._v(
                _vm._s(
                  _vm.$t(
                    "__.Components.parent.notafiscalentrada.list_vue_html.nfEntrada"
                  )
                )
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "content" }, [
              _c("div", { staticClass: "info-panel" }, [
                _c("span", [_vm._v(_vm._s(_vm.total))]),
                _vm._v(" "),
                _c("span", [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        "__.Components.parent.notafiscalentrada.list_vue_html.nf"
                      )
                    )
                  ),
                ]),
                _vm._v(" "),
                _c("span", [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        "__.Components.parent.notafiscalentrada.list_vue_html.cadastradas"
                      )
                    )
                  ),
                ]),
              ]),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("grid-component", {
        attrs: {
          data: _vm.gridData,
          tituloPagina: "Notas fiscais",
          columns: _vm.gridColumns,
          "show-action-column": _vm.showInfos,
          "show-edit-item": _vm.showInfos,
          "show-remove-item": _vm.showInfos,
          showAddNewButton: _vm.showInfos,
          "show-table-head": _vm.showInfos,
          "show-table-foot": _vm.showInfos,
          "page-index": _vm.pageIndex,
          "page-size": _vm.pageSize,
          total: _vm.total,
          startFilterKey: _vm.startFilterKey,
          extraActions: _vm.extraActions,
        },
        on: {
          "change-filter-key": _vm.onChangeFilterKey,
          "change-sort": _vm.onChangeSort,
          "edit-item": _vm.onEditItem,
          "remove-item": _vm.onRemoveItem,
          "create-item": _vm.onCreateItem,
          "change-page": _vm.onChangePage,
          "extra-action": _vm.onExtraAction,
        },
      }),
      _vm._v(" "),
      _c("imprimirRotuloPersonalizadoComponent", {
        ref: "imprimirRotuloPersonalizadoComponent",
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }