import { mapMutations } from "vuex";

import comboComponent from "@/components/child/form/combo.vue";
import moedaComponent from "@/components/child/form/moeda.vue";
import { Component } from "@/decorators";
import PaginationModel from "@/models/paginationModel";
import LocalEntregaModel from "@/models/transporte/localEntregaModel";
import TransportadoraModel from "@/models/transporte/transportadoraModel";
import LocalEntregaService from "@/services/transporte/localEntregaService";
import TransportadoraService from "@/services/transporte/transportadoraService";
import { LoadListProps, Mutations } from "@/store/store";

import EditComponentBase from "../editComponentBase";

@Component({
    components: {
        comboComponent,
        moedaComponent,
    },
    methods: mapMutations(["LOAD_LIST"] as Mutations),
})
export default class LocalEntregaEditComponent extends EditComponentBase<LocalEntregaModel> {
    // State computed props
    LOAD_LIST: (props: LoadListProps) => void;

    private localEntregaService = new LocalEntregaService();
    private transportadoraService = new TransportadoraService();

    transportadoraOptions: Array<Object> = [];

    private async loadTransportadora() {
        try {
            const data = await this.transportadoraService
                .combo()
                .resolveWithJSON<PaginationModel<TransportadoraModel>>();
            this.transportadoraOptions = data.list.map(p => ({ value: p.id, text: p.nome }));
        } catch {}
    }

    private async beforeMount() {
        await this.setProps(this.localEntregaService, "local_de_entrega", new LocalEntregaModel());

        Promise.all([this.loadTransportadora()]).withLoading();
    }

    public async afterMounted() {
        if (!this.modelId) {
            this.model["codigo"] = await this.localEntregaService.getProximoCodigo().resolveWithJSON<number>();
        }
    }

    public async onSucessoSave() {
        this.LOAD_LIST({ loadLocaisEntrega: true });
    }
}
