import Vue from "vue";
import { mapGetters } from "vuex";

import actionBarComponent from "@/components/child/actionBar/actionBar.vue";
import checkboxComponent from "@/components/child/form/checkbox.vue";
import comboComponent from "@/components/child/form/combo.vue";
import dataTooltipComponent from "@/components/child/form/datatooltip.vue";
import dateTimePickerComponent from "@/components/child/form/datetimepicker.vue";
import fieldsetComponent from "@/components/child/form/fieldset.vue";
import moedaComponent from "@/components/child/form/moeda.vue";
import textareaComponent from "@/components/child/form/textarea.vue";
import gridComponent from "@/components/child/grid/grid.vue";
import { GridColumn, GridColumnExt, GridColumnType } from "@/components/child/grid/gridColumn";
import searchComboComponent from "@/components/child/searchCombo/search.vue";
import { Component, Watch } from "@/decorators";
import ClienteModel from "@/models/clienteModel";
import ConfiguracaoFranquiaModel from "@/models/configuracaoFranquia/configuracaoFranquiaModel";
import Configuracoes from "@/models/enum/configuracao/configuracoes";
import EnumExtensions from "@/models/enum/extensions/enumExtensions";
import Frequencias from "@/models/enum/frequencias";
import StatusContas from "@/models/enum/statusContas";
import TipoMovimentacao from "@/models/enum/tiposMovimentacao";
import ContaGerencialModel from "@/models/financeiro/contaGerencialModel";
import ContaReceberModel from "@/models/financeiro/contaReceberModel";
import ContaReceberRecebimentoModel from "@/models/financeiro/contaReceberRecebimentoModel";
import MetodoPagemento from "@/models/financeiro/metodoPagamentoModel";
import LiquidarModel from "@/models/financeiro/pagamentoContasModel";
import PaginationModel from "@/models/paginationModel";
import ContaGerencialService from "@/services/financeiro/contaGerencialService";
import ContaReceberService from "@/services/financeiro/contaReceberService";
import MetodoPagementoService from "@/services/financeiro/metodoPagamentoService";
import { Getters } from "@/store/store";
import { addDays } from "@/utils/common/date";

import RecebimentoContaComponent from "./recebimentoconta";
import recebimentoContaComponent from "./recebimentoconta.vue";

import "../crud.scss";
import "./edit.scss";

@Component({
    components: {
        textareaComponent,
        comboComponent,
        recebimentoContaComponent,
        fieldsetComponent,
        moedaComponent,
        dateTimePickerComponent,
        dataTooltipComponent,
        gridComponent,
        checkboxComponent,
        actionBarComponent,
        searchComboComponent,
    },
    computed: {
        ...mapGetters(["GET_CONFIG_FRANQUIA"] as Getters),
    },
})
export default class ContaReceberEditComponent extends Vue {
    // State computed props
    GET_CONFIG_FRANQUIA: (configuracao: Configuracoes) => ConfiguracaoFranquiaModel;

    private service = new ContaReceberService();
    private recebimentoContaComponent: RecebimentoContaComponent = null;

    private parcelar = false;
    private nsuObrigatorio = false;
    private numeroParcelas = 1;

    private metodoParcelamentoId = -1;

    operadoraCartoesDescricao: string = null;
    recebimentos: Array<ContaReceberRecebimentoModel> = [];
    liquidarModel: LiquidarModel = new LiquidarModel();
    model: ContaReceberModel = new ContaReceberModel();
    modelId = 0;
    saldoReceber = 0;

    contasGerenciaisOptions: Array<Object> = [];
    operadoraCartoesOptions: Array<Object> = [];
    metodosPagamentosOptions: Array<Object> = [];
    recorrenciasOptions: Array<Object> = [
        { text: "Sem Recorr\u00eancias", value: 0 },
        { text: "1 Recorr\u00eancia", value: 1 },
        { text: "2 Recorr\u00eancias", value: 2 },
        { text: "3 Recorr\u00eancias", value: 3 },
        { text: "4 Recorr\u00eancias", value: 4 },
        { text: "5 Recorr\u00eancias", value: 5 },
        { text: "6 Recorr\u00eancias", value: 6 },
        { text: "7 Recorr\u00eancias", value: 7 },
        { text: "8 Recorr\u00eancias", value: 8 },
        { text: "9 Recorr\u00eancias", value: 9 },
        { text: "10 Recorr\u00eancias", value: 10 },
        { text: "11 Recorr\u00eancias", value: 11 },
        { text: "12 Recorr\u00eancias", value: 12 },
    ];
    frequenciasOptions: Array<Object> = EnumExtensions.getNamesAndValuesOrderedByValues(Frequencias);

    disabledValorTotal = false;
    disabledDataVencimento = false;

    get gridColumns(): Array<GridColumn> {
        return [
            new GridColumn("dataMovimento", "Data", GridColumnType.Date),
            new GridColumn("valor", "Pago", GridColumnType.Money),
            new GridColumn("desconto", "Desconto", GridColumnType.Money),
            new GridColumn("tarifa", "Tarifa", GridColumnType.Money),
            new GridColumn("juros", "Juros", GridColumnType.Money),
            new GridColumn("formaPagamentoDescricao", "Forma", GridColumnType.String),
            new GridColumn("contaCorrenteDescricao", "Conta", GridColumnType.String),
        ];
    }

    get gridColumnsParcelas(): Array<GridColumn> {
        return [
            new GridColumn("descricao", this.$t("__.Crud.contareceber.edit_vue_html.descricao"), GridColumnType.String),
            new GridColumn(
                "valorTotal",
                this.$t("__.Crud.contareceber.edit_vue_html.vlrParcela"),
                GridColumnType.Money,
            ),
            new GridColumn("saldo", this.$t("__.Crud.contareceber.edit_vue_html.sldParcela"), GridColumnType.Money),
            new GridColumn(
                "dataVencimento",
                this.$t("__.Crud.contareceber.edit_vue_html.dtVenc"),
                GridColumnType.Date,
                true,
                false,
                "",
            ),
            new GridColumnExt({
                value: "liquidarSaldo",
                text: this.$t("__.Crud.contareceber.edit_vue_html.liquidar"),
                type: GridColumnType.Boolean,
                editable: this.model.status !== StatusContas.Liquidado,
                showSortIcon: false,
                onChange: (lancamento: ContaReceberModel) => {
                    this.model.lancamentosVinculados = this.model.lancamentosVinculados.map(p =>
                        p.id == lancamento.id ? lancamento : p,
                    );
                },
            }),
        ];
    }

    private customComboTextCliente = (p: ClienteModel) => ({
        value: p.id,
        text: `${p.id} - ` + (p.tipoPessoa != 1 ? p.nome : p.razaoSocial),
    });

    private async load() {
        this.model = new ContaReceberModel();
        this.model.recorrencias = 0;
        this.model.dataVencimento = new Date();
        this.model.contabilizar = true;
        this.parcelar = false;
        this.numeroParcelas = 1;

        if (this.modelId > 0) {
            try {
                const data = await this.service.get(this.modelId).withLoading().resolveWithJSON<ContaReceberModel>();
                this.model.updateFrom(data);
                this.loadRecebimentos();

                //marcar o primeiro lancamento a ser liquidado ///antes this.model.operadoraCartoesId != null &&
                if (this.model.lancamentosVinculados.length > 0) {
                    const lanctosSaldoaberto = this.model.lancamentosVinculados.filter(
                        p => p.saldo > 0 && p.valorTotal > 0,
                    );
                    if (lanctosSaldoaberto.length > 0) {
                        lanctosSaldoaberto[0].liquidarSaldo = true;
                        this.saldoReceber = lanctosSaldoaberto[0].saldo;
                    }
                }
                //busca saldo do modelo
                this.atualizarSaldoReceber();

                this.recebimentoContaComponent.clear(this.saldoReceber);
                this.recebimentoContaComponent.enable(this.saldoReceber > 0);

                if (this.model.vendaId != null && this.model.vendaId > 0) {
                    this.disabledValorTotal = true;
                }
            } catch {
                this.$router.back();
            }
        } else {
            this.recebimentoContaComponent.clear(0);
            this.recebimentoContaComponent.enable(false);
        }
    }

    private loadRecebimentos() {
        this.recebimentos = null;
        this.recebimentos = new Array<ContaReceberRecebimentoModel>();

        if (this.model.movimentacoes.length > 0) this.recebimentos = this.model.movimentacoes;
        else if (this.model.lancamentosVinculados.length > 0) {
            for (let i = 0; i < this.model.lancamentosVinculados.length; i++) {
                for (let j = 0; j < this.model.lancamentosVinculados[i].movimentacoes.length; j++) {
                    this.recebimentos.push(this.model.lancamentosVinculados[i].movimentacoes[j]);
                }
            }
        }
    }

    private async loadMetodoPagamentos() {
        try {
            const data = await new MetodoPagementoService().combo().resolveWithJSON<PaginationModel<MetodoPagemento>>();

            this.metodosPagamentosOptions = data.list.map(item => ({
                value: item.id,
                text: item.descricao,
                numParcelas: item.numeroParcelas,
                primeiroIntervalo: item.primeiroIntervalo,
                intervalo: item.intervalo,
            }));
            this.metodosPagamentosOptions = this.metodosPagamentosOptions.filter(p => p["numParcelas"] > 1);
        } catch {}
    }

    private async loadContasGerenciais() {
        try {
            const data = await new ContaGerencialService()
                .listByTipoMovimentacao("", 1, 999999, TipoMovimentacao.Entrada)
                .resolveWithJSON<PaginationModel<ContaGerencialModel>>();
            this.contasGerenciaisOptions = data.list.map(item => ({ value: item.id, text: item.descricao }));
        } catch {}
    }

    private atualizarSaldoReceber() {
        if (this.model.movimentacoes.length == 0 && this.model.lancamentosVinculados.length == 0) {
            this.model.saldo = this.model.valorTotal;
        }

        //marcar o primeiro lancamento a ser liquidado
        if (this.model.lancamentosVinculados.length > 0) {
            //soma dos marcados
            this.saldoReceber = this.model.lancamentosVinculados
                .filter(p => p.liquidarSaldo)
                .map(p => p.saldo)
                .reduce((a, b) => a + b, 0);
        } else {
            this.saldoReceber = this.model.saldo;
        }

        if (this.saldoReceber < 0) this.saldoReceber = 0;

        if (this.model.operadoraCartoesId != null && this.model.operadoraCartoesId > 0) {
            this.disabledValorTotal = true;
            this.disabledDataVencimento = true;
        } else {
            if (this.model.movimentacoes.length == 0 && this.model.lancamentosVinculados.length == 0) {
                this.disabledValorTotal = false;
                this.disabledDataVencimento = false;
            } else {
                this.disabledValorTotal = true;
            }
        }
    }

    private async save() {
        try {
            const isValid = await this.$validator.validateAll();
            if (isValid) {
                this.model.dataLancamento = new Date();
                if (!this.model.id && this.model.movimentacoes.length == 0) {
                    this.model.saldo = this.model.valorTotal;
                }

                const sucesso = await this.service[!this.model["id"] ? "insert" : "update"](this.model)
                    .withLoading()
                    .resolveWithoutJSON();

                if (sucesso) {
                    await this.$showInclusaoUpdate(this.model.id);
                    this.$router.back();
                }
            } else {
                this.$focusErrorField();
            }
        } catch {}
    }

    private cancel() {
        this.$router.back();
    }

    private async onInclusaoRecebimento(model: ContaReceberRecebimentoModel) {
        try {
            const isValid = await this.$validator.validateAll();
            if (isValid) {
                if (model.id > 0) {
                    const sucesso = await this.service.updateRecebimento(model).withLoading().resolveWithoutJSON();

                    if (sucesso) {
                        if (this.model.lancamentosVinculados.length > 0) {
                            this.model.lancamentosVinculados
                                .filter(p => p.liquidarSaldo)
                                .forEach(item => {
                                    item.liquidarSaldo = false;
                                });
                        }

                        this.load();
                        this.$showSuccess(this.$t("__.ts.sucesso"), this.$t("__.ts.sucessoAtualizacao"));
                    }
                } else {
                    //nao validar o isvalid aqui, pois ja faz no filho
                    this.atualizarSaldoReceber();
                    if (this.model.id > 0) {
                        //coloca modelo de recebimento e conta no model de liquidacao para enviar para o service
                        this.liquidarModel = new LiquidarModel();
                        this.liquidarModel.contaReceberRecebimentoViewModel = model;
                        this.liquidarModel.contaReceberViewModel = this.model;

                        const sucesso = await this.service
                            .adicionarRecebimento(this.liquidarModel)
                            .withLoading()
                            .resolveWithoutJSON();

                        if (sucesso) {
                            if (this.model.lancamentosVinculados.length > 0) {
                                this.model.lancamentosVinculados
                                    .filter(p => p.liquidarSaldo)
                                    .forEach(item => {
                                        item.liquidarSaldo = false;
                                    });
                            }

                            this.load();

                            await this.$showSuccess(this.$t("__.ts.inclusao"), this.$t("__.ts.registroSalvSucess"));
                        }
                    } else {
                        this.model.movimentacoes.push(model);
                        this.model.saldo =
                            this.model.saldo -
                            (model.valor + model.desconto - model.juros + (model.tarifa != null ? model.tarifa : 0));
                        this.atualizarSaldoReceber();
                        this.loadRecebimentos();
                    }

                    this.recebimentoContaComponent.clear(this.saldoReceber);
                }
            } else {
                this.$focusErrorField();
            }
        } catch {}
    }

    private async onRemoveItem(model: ContaReceberRecebimentoModel) {
        if (this.model.id > 0) {
            try {
                const sucesso = await this.service
                    .excluirRecebimentoWithModel(model)
                    .withLoading()
                    .resolveWithoutJSON();

                if (sucesso) {
                    this.load();
                    this.atualizarSaldoReceber();
                    await this.$showSuccess(this.$t("__.ts.exclusao"), this.$t("__.ts.regisRemovSucess"));
                }
            } catch {}
        } else {
            this.model.movimentacoes = this.model.movimentacoes.filter(p => p != model);
            this.model.saldo =
                this.model.saldo +
                model.valor +
                model.desconto -
                model.juros +
                (model.tarifa != null ? model.tarifa : 0);
            this.loadRecebimentos();
            this.atualizarSaldoReceber();
        }
    }

    private async onEditItem(model: ContaReceberRecebimentoModel) {
        if (this.model.id) {
            this.recebimentoContaComponent.enable(true);
            this.recebimentoContaComponent.model = model;
            this.recebimentoContaComponent.valorAbater = model.valor;
            this.recebimentoContaComponent.especie = this.recebimentoContaComponent.formaRecebimentoOptions.find(
                item => item["value"] == model.formaPagamentoId,
            )["especie"];
        }
    }

    private onParcelar() {
        const metodo = this.metodosPagamentosOptions.filter(p => p["value"] == this.metodoParcelamentoId)[0];

        const numPar: number = metodo["numParcelas"];
        const primeiraParc: number = metodo["primeiroIntervalo"];
        const intervalo: number = metodo["intervalo"];
        let dataAtual = new Date();

        if (numPar > 1 && primeiraParc >= 0 && intervalo >= 0) {
            const valorPParcela = parseFloat((this.model.valorTotal / numPar).toFixed(2));
            let saldototalDividir: number = this.model.valorTotal;

            for (let i = 0; i < numPar; i++) {
                //se saldo maior q valor calculado por parcela usa o valor e vai deixando o saldo para o final...
                //se for a ultima parcela a criar ..usa o restante do saldo para nao ultrapassar o valor total do lancamento principal
                const valor = saldototalDividir > valorPParcela && numPar - 1 > i ? valorPParcela : saldototalDividir;
                saldototalDividir = saldototalDividir - valor;
                const receber = new ContaReceberModel();
                receber.clienteId = this.model.clienteId;
                receber.contaGerencialDescricao = this.model.contaGerencialDescricao;
                receber.contaGerencialId = this.model.contaGerencialId;
                receber.dataLancamento = this.model.dataLancamento ? this.model.dataLancamento : new Date();
                receber.descricao = this.model.descricao + " (" + (i + 1).toString() + "/" + numPar.toString() + ")";
                receber.status = 0;
                receber.valorTotal = valor;
                receber.saldo = valor;
                receber.contabilizar = this.model.contabilizar;
                const dias = i == 0 ? primeiraParc : intervalo;
                dataAtual = addDays(dias, dataAtual);
                receber.dataVencimento = dataAtual;
                receber.lancamentoVinculadoId = this.model.id;

                this.model.lancamentosVinculados.push(receber);
            }
        }

        this.disabledValorTotal = true;
        this.atualizarSaldoReceber();
    }

    //@ts-ignore
    @Watch("model.recorrencias")
    private onRecorrenciasChanged(newValue) {
        if (+newValue == 0) {
            this.model.frequencia = null;
        }
    }

    //@ts-ignore
    @Watch("model.valorTotal")
    private onValorTotalChanged() {
        if (this.model.movimentacoes.length == 0 && this.model.lancamentosVinculados.length == 0) {
            this.model.saldo = this.model.valorTotal;
        }

        this.atualizarSaldoReceber();
    }

    //@ts-ignore
    @Watch("saldoReceber")
    private onSaldoReceberChanged() {
        this.recebimentoContaComponent.enable(this.saldoReceber > 0);
        this.recebimentoContaComponent.clear(this.saldoReceber);
    }

    //@ts-ignore
    @Watch("model.lancamentosVinculados", { deep: true })
    private onChangeParcelas() {
        this.atualizarSaldoReceber();
    }

    private mounted() {
        this.recebimentoContaComponent = this.$refs.recebimentoContaComponent as RecebimentoContaComponent;
        this.nsuObrigatorio = this.GET_CONFIG_FRANQUIA(Configuracoes.NsuObrigatorioFaturamentoCartao).verdadeiro;

        Promise.all([this.loadContasGerenciais(), this.loadMetodoPagamentos()])
            .withLoading()
            .then(() => {
                if (this.$route.params.id) {
                    this.modelId = +this.$route.params.id;
                }

                this.load();
            })
            .catch(() => {});
    }
}
