var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: _vm.dimensoes }, [
    _c("a", { class: _vm.classes }, [
      _c("div", { staticClass: "visual" }, [_c("i", { class: _vm.icon })]),
      _vm._v(" "),
      _c("div", { staticClass: "details" }, [
        _c("div", { staticClass: "number" }, [
          _vm._v("\n                " + _vm._s(_vm.value) + "\n            "),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "desc" }, [_vm._v(_vm._s(_vm.title))]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }