import BaseModel from "./base/baseModel";
import StatusEnvioNF from "./enum/statusEnvioNF";
import TipoCadastro from "./enum/tipoCadastro";
import TipoOperacao from "./enum/tipoOperacao";
import NaturezaOperacaoModel from "./naturezaOperacaoModel";
import NotaFiscalEletronicaEmissaoItemModel from "./notaFiscalEletronicaEmissaoItemModel";
import VolumeModel from "./volumeModel";

export class Cobranca {
    numero = "";
    valorOriginal = 0;
    valorDesconto = 0;
    valorLiquido = 0;
    duplicatas: Duplicata[] = [];
}

export class Duplicata {
    numero: string;
    dataVencimento: Date;
    valor: number;
}

export class Pagamento {
    index?: number;
    indicadorPagamento?: number;
    meioPagamento: number;
    valorPagamento: number;
    automatico?: boolean;
    descricao?: string;
    cartao?: Cartao;
}

export class Cartao {
    bandeira?: number = null;
    tipoIntegracao?: number = 2;
    autorizacao?: string = null;
    cnpjOperadoraCartao?: string = null;
}

export class Retencoes {
    id?: number;
    valorPIS?: number;
    valorCOFINS?: number;
    valorCSLL?: number;
    valorBaseIRRF?: number;
    valorIRRF?: number;
    valorBasePrev?: number;
    valorPrev?: number;
}

export class Exportacao {
    ufSaidaPaisId?: number = null;
    ufSaidaPais?: string;
    localExportacao?: string;
    localDesembaraco?: string;
}

export default class NotaFiscalEletronicaEmissaoModel extends BaseModel {
    id = 0;
    vendaId = 0;
    franquiaId = 0;
    tipoOperacao: TipoOperacao = null;
    tipoOperacaoDescricao: string = null;
    tipoCadastro: TipoCadastro = null;
    modelo = 0;
    chave: string = null;
    numero: number = null;
    serie: string = null;
    recibo: string = null;
    finalidadeEmissao = 0;
    indicadorPresenca = 0;
    indicativoIntermedio = 0;
    indicadorOperacao = 0;
    destinatarioId = 0;
    destinatario: object = null;
    naturezaOperacaoId?: number = 0;
    naturezaOperacao: NaturezaOperacaoModel = null;
    observacao?: string = null;
    transportadoraId?: number = null;
    transportadoraNome?: string = null;
    transportadoraCnpj?: string = null;
    transportadoraEndereco?: string = null;
    transportadoraCidade?: string = null;
    transportadoraUF?: string = null;
    transportadoraIE?: string = null;
    modalidadeFrete = 9;
    transportadoraPlacaVeiculo?: string = null;
    pdfLink: string = null;
    pdfSimplificadoLink: string = null;
    xmlLink: string = null;
    statusEnvio: StatusEnvioNF = null;
    statusEnvioDescricao: string = null;
    itens: Array<NotaFiscalEletronicaEmissaoItemModel> = [];
    notaImportacao = false;
    notaExportacao = false;
    vendaInterestadual = false;
    semDestinatario = false;
    cobranca = new Cobranca();
    pagamentos: Array<Pagamento> = [];
    valorTroco?: number;
    volumes: Array<VolumeModel> = [];
    valorTotal = 0;
    retencoesId = 0;
    retencoes: Retencoes = {};
    exportacaoId = 0;
    exportacao: Exportacao = {};
    chaveReferenciada: string = null;
    temCartaCorrecao = false;
    movimentarEstoque = false;
    estoqueId = null;
    emailTransportadora?: string = "";
}
