import Vue from "vue";

import actionBarComponent from "@/components/child/actionBar/actionBar.vue";
import buttonIncluirComponent from "@/components/child/form/button/buttonIncluir.vue";
import cepFieldComponent from "@/components/child/form/cepfield.vue";
import comboComponent from "@/components/child/form/combo.vue";
import dataTooltipComponent from "@/components/child/form/datatooltip.vue";
import dateTimePickerComponent from "@/components/child/form/datetimepicker.vue";
import decimalComSinalCustomComponent from "@/components/child/form/decimalComSinalCustom.vue";
import fieldsetComponent from "@/components/child/form/fieldset.vue";
import moedaComponent from "@/components/child/form/moeda.vue";
import textareaComponent from "@/components/child/form/textarea.vue";
import gridComponent from "@/components/child/grid/grid.vue";
import { GridColumn, GridColumnType } from "@/components/child/grid/gridColumn";
import { Component, Prop, Watch } from "@/decorators";
import CepModel from "@/models/cepModel";
import ClinicaModel from "@/models/clinica/clinicaModel";
import ClinicaPrescritorModel from "@/models/clinica/clinicaPrescritorModel";
import ConvenioFaixaModel from "@/models/convenioFaixaModel";
import ConvenioModel from "@/models/convenioModel";
import EnumExtensions from "@/models/enum/extensions/enumExtensions";
import GenerosPessoa from "@/models/enum/generosPessoa";
import TipoConvenioFaixa from "@/models/enum/tipoConvenioFaixa";
import TipoDesconto from "@/models/enum/TipoDesconto";
import TiposPessoa from "@/models/enum/tiposPessoa";
import Ufs from "@/models/enum/ufs";
import { TypeElement } from "@/models/modeloImpressao/modeloImpressaoSessaoModel";
import PaginationModel from "@/models/paginationModel";
import PrescritorModel from "@/models/prescritorModel";
import ClinicaService from "@/services/clinicaService";
import ConvenioService from "@/services/convenioService";
import Delay from "@/utils/common/delay";
import Print from "@/utils/common/printForm";

import ClinicaEditComponent from "../clinica/edit";
import clinicaComponent from "../clinica/edit.vue";
import prescritorComponent from "../prescritor/edit.vue";

import "../crud.scss";

@Component({
    components: {
        textareaComponent,
        fieldsetComponent,
        comboComponent,
        cepFieldComponent,
        dateTimePickerComponent,
        dataTooltipComponent,
        gridComponent,
        prescritorComponent,
        decimalComSinalCustomComponent,
        moedaComponent,
        clinicaComponent,
        buttonIncluirComponent,
        actionBarComponent,
    },
})
export default class ConvenioEditComponent extends Vue {
    private service = new ConvenioService();
    private clinicaComponent: ClinicaEditComponent = null;

    model = new ConvenioModel();
    convenioFaixaCliente: ConvenioFaixaModel;
    convenioFaixaVeterinario: ConvenioFaixaModel;
    modelId = 0;

    @Prop({ type: Boolean, default: false }) CalledByShortCut: boolean;
    @Prop({ type: Boolean, default: false }) isModal: boolean;

    private hideExtras = false;

    tiposPessoaOptions: Array<Object> = [
        { text: "F\u00edsica", value: TiposPessoa.Fisica },
        { text: "Jur\u00eddica", value: TiposPessoa.Juridica },
    ];

    generosPessoaOptions: Array<Object> = [
        { text: "Masculino", value: GenerosPessoa.Masculino },
        { text: "Feminino", value: GenerosPessoa.Feminino },
    ];

    gridClinicaColumns: Array<GridColumn> = [new GridColumn("nome", "Nome", GridColumnType.String)];
    gridFaixaColums: Array<GridColumn> = [
        new GridColumn("faixaInicio", "Faixa Inicio", GridColumnType.Money),
        new GridColumn("faixaFim", "Faixa Fim", GridColumnType.Money),
        new GridColumn("valorDesconto", "Valor Desconto", GridColumnType.Percent),
    ];

    dadosCep: CepModel = new CepModel();
    ufOptions: Array<Object> = EnumExtensions.getNamesAndValuesOrderedByNames(Ufs);
    cidadesOptions: Array<Object> = [];
    clinicasOptions: Array<any> = [];
    tipoDescontoOptions: Array<Object> = EnumExtensions.getNamesAndValuesOrderedByValues(TipoDesconto);

    constructor() {
        super();
        this.convenioFaixaCliente = new ConvenioFaixaModel();
        this.convenioFaixaVeterinario = new ConvenioFaixaModel(TipoConvenioFaixa.Veterinario);
    }

    private async onAddFaixa(tipoFaixa: TipoConvenioFaixa) {
        this.$validator.attach("faixaInicio", "required|maior_que_zero");
        this.$validator.attach("faixaFim", "required|maior_que_zero");
        this.$validator.attach("valorDesconto", "required|maior_que_zero");

        const faixa = new ConvenioFaixaModel();
        if (tipoFaixa == TipoConvenioFaixa.Cliente) {
            const results = Promise.all([
                this.$validator.validate("faixaInicio", this.convenioFaixaCliente.faixaInicio),
                this.$validator.validate("faixaFim", this.convenioFaixaCliente.faixaFim),
                this.$validator.validate("valorDesconto", this.convenioFaixaCliente.valorDesconto),
            ]);
            const areValid = (await results).every(isValid => isValid);
            if (areValid) {
                faixa.updateFrom(this.convenioFaixaCliente);
                faixa.tipoFaixa = tipoFaixa;
                this.convenioFaixaCliente = new ConvenioFaixaModel();
                this.model.faixas.push(faixa);
            }
        } else {
            const results = Promise.all([
                this.$validator.validate("faixaInicio", this.convenioFaixaVeterinario.faixaInicio),
                this.$validator.validate("faixaFim", this.convenioFaixaVeterinario.faixaFim),
                this.$validator.validate("valorDesconto", this.convenioFaixaVeterinario.valorDesconto),
            ]);
            const areValid = (await results).every(isValid => isValid);
            if (areValid) {
                faixa.updateFrom(this.convenioFaixaVeterinario);
                faixa.tipoFaixa = tipoFaixa;
                this.convenioFaixaVeterinario = new ConvenioFaixaModel(TipoConvenioFaixa.Veterinario);
                this.model.faixas.push(faixa);
            }
        }

        this.$validator.detach("faixaInicio");
        this.$validator.detach("faixaFim");
        this.$validator.detach("valorDesconto");
    }

    public async load() {
        this.model = new ConvenioModel();

        this.loadClinicas();

        if (this.modelId > 0) {
            try {
                const data = await this.service.get(this.modelId).withLoading().resolveWithJSON<ConvenioModel>();

                this.model.updateFrom(data);
                this.convenioFaixaCliente = new ConvenioFaixaModel();
                this.convenioFaixaVeterinario = new ConvenioFaixaModel();
                if (this.model.tipoDescontoCliente !== TipoDesconto.Faixa) {
                    this.convenioFaixaCliente.updateFrom(
                        data.faixas.filter(x => x.tipoFaixa == TipoConvenioFaixa.Cliente)[0],
                    );
                }
                if (this.model.tipoDescontoVeterinario !== TipoDesconto.Faixa) {
                    this.convenioFaixaVeterinario.updateFrom(
                        data.faixas.filter(x => x.tipoFaixa == TipoConvenioFaixa.Veterinario)[0],
                    );
                }
            } catch {
                this.$router.back();
            }
        }
    }

    public async save() {
        try {
            const isValid = await this.$validator.validateAll();
            if (isValid) {
                if (this.model.clinicas.length == 0) {
                    return this.$showWarning(
                        "Clinica não informada",
                        "É obrigatório a informação de pelo menos uma clinica.",
                    );
                }

                this.AddFaixa();

                const response = await this.service[!this.model.id ? "insert" : "update"](this.model)
                    .withLoading()
                    .resolveWithResponse();

                if (response.ok) {
                    await this.$showInclusaoUpdate(this.model.id);
                    if (this.CalledByShortCut) {
                        if (!this.model.id) {
                            this.model.id = Number(response.headers.get("Id"));
                        }

                        this.$emit("save-ok", this.model);
                    } else {
                        this.$router.back();
                    }
                }
            } else {
                this.$focusErrorField();
            }
        } catch {}
    }

    private AddFaixa() {
        if (this.model.tipoDescontoCliente != TipoDesconto.Faixa) {
            this.model.faixas = this.model.faixas.filter(x => x.tipoFaixa == TipoConvenioFaixa.Veterinario);
            this.convenioFaixaCliente.tipoFaixa = TipoConvenioFaixa.Cliente;
            this.model.faixas.push(this.convenioFaixaCliente);
        }
        if (this.model.tipoDescontoVeterinario != TipoDesconto.Faixa) {
            this.model.faixas = this.model.faixas.filter(x => x.tipoFaixa == TipoConvenioFaixa.Cliente);
            this.convenioFaixaVeterinario.tipoFaixa = TipoConvenioFaixa.Veterinario;
            this.model.faixas.push(this.convenioFaixaVeterinario);
        }
    }

    public atualizarClinicas() {
        this.loadClinicas();
    }

    private cancel() {
        if (this.CalledByShortCut) {
            this.model = new ConvenioModel();
        }
        this.$router.back();
    }

    private async loadClinicas() {
        try {
            const data = await new ClinicaService().combo().resolveWithJSON<PaginationModel<PrescritorModel>>();
            this.clinicasOptions = data.list.map(item => ({ value: item.id, text: item.nome }));
        } catch {}
    }

    private onAddClinica() {
        const component = this.$el.querySelector("#clinicaId") as HTMLSelectElement;

        if (this.model.clinicaId > 0) {
            const alreadyExists = this.model.clinicas.filter(item => item.id == this.model.clinicaId).length > 0;

            if (!alreadyExists) {
                const clinica = new ClinicaModel();
                clinica.id = this.model.clinicaId;
                clinica.nome = component.innerText;
                this.model.clinicas.push(clinica);

                component.value = null;
                this.model.clinicaId = null;
                this.clinicasOptions = this.clinicasOptions.filter(x => x.text != component.innerText);
            }
        }
    }

    private onCepInvalid() {
        this.$showInfo("Pesquisa por CEP", "A pesquisa n\u00e3o encontrou resultados");
    }

    private onRemoverClinica(model: ClinicaPrescritorModel) {
        this.model.clinicas.splice(this.model.clinicas.indexOf(model), 1);
    }

    private onRemoveFaixa(item) {
        this.model.faixas.splice(this.model.faixas.indexOf(item), 1);
    }

    //@ts-ignore
    @Watch("model.tipoDescontoCliente")
    private onTipoDescontoCliente() {
        if (this.model.tipoDescontoCliente != TipoDesconto.Faixa) {
            this.model.faixas = this.model.faixas.filter(x => x.tipoFaixa == TipoConvenioFaixa.Veterinario);
        }
        this.convenioFaixaCliente = new ConvenioFaixaModel();
    }

    //@ts-ignore
    @Watch("model.tipoDescontoVeterinario")
    private onTipoDescontoVeterinario() {
        if (this.model.tipoDescontoVeterinario != TipoDesconto.Faixa) {
            this.model.faixas = this.model.faixas.filter(x => x.tipoFaixa == TipoConvenioFaixa.Cliente);
        }
        this.convenioFaixaVeterinario = new ConvenioFaixaModel();
    }

    private async openNewClinica() {
        while (!this.$refs.clinicaComponent) await Delay(10);
        this.clinicaComponent = this.$refs.clinicaComponent as ClinicaEditComponent;

        this.clinicaComponent.load();
    }

    private onAddNewClinica() {
        this.clinicaComponent.save();
    }

    private async onClinicaSaveOk() {
        await this.loadClinicas();
    }

    private mounted() {
        this.hideExtras = this.CalledByShortCut ? true : false;

        if (this.$route.params.id && !this.hideExtras) {
            this.modelId = +this.$route.params.id;

            this.load().withLoading();
        }

        if (this.CalledByShortCut) {
            this.model = new ConvenioModel();
        }

        if (!this.isModal) {
            this.loadClinicas();
        }
    }

    print() {
        new Print().print(
            [
                {
                    elementId: "clinica",
                    title: "clinica",
                    type: TypeElement.Form,
                },
                {
                    elementId: "localizacao",
                    title: "Localização",
                    type: TypeElement.Form,
                },
                {
                    elementId: "entrega",
                    title: "Endereço de Entrega",
                    type: TypeElement.Form,
                },
                {
                    elementId: "contato",
                    title: "Contato",
                    type: TypeElement.Form,
                },
            ],
            3,
        );
    }
}
