interface SituacaoTributaria {
    codigo: string;
    descricao: string;
}

export default class SituacaoTributariaService {
    public getICMS(): SituacaoTributaria[] {
        return [
            { "codigo": "00", "descricao": "Tributada integralmente" },
            { "codigo": "10", "descricao": "Tributada e com cobrança do ICMS por substituição tributária" },
            { "codigo": "20", "descricao": "Com redução de base de cálculo" },
            { "codigo": "30", "descricao": "Isenta ou não tributada e com cobrança do ICMS por substituição tributária" },
            { "codigo": "40", "descricao": "Isenta" },
            { "codigo": "41", "descricao": "Não Tributada" },
            { "codigo": "50", "descricao": "Suspensão" },
            { "codigo": "51", "descricao": "Diferimento" },
            { "codigo": "60", "descricao": "ICMS cobrado anteriormente por substituição tributária" },
            { "codigo": "70", "descricao": "Com redução de base de cálculo e cobrança do ICMS por substituição tributária" },
            { "codigo": "90", "descricao": "Outros" },
        ];
    }

    public getCSOSN(): SituacaoTributaria[] {
        return [
            { "codigo": "101", "descricao": "Tributada pelo Simples Nacional com permissão de crédito de ICMS" },
            { "codigo": "102", "descricao": "Tributada pelo Simples Nacional sem permissão de crédito" },
            { "codigo": "103", "descricao": "Isenção de ICMS no Simples Nacional na faixa de receita bruta" },
            { "codigo": "201", "descricao": "Tributada pelo Simples Nacional com permissão de crédito e cobrança do ICMS por ST" },
            { "codigo": "202", "descricao": "Tributada pelo Simples Nacional sem permissão de crédito e com cobrança do ICMS por ST" },
            { "codigo": "203", "descricao": "Isenção do ICMS no Simples Nacional para faixa de receita bruta e cobrança de ICMS por ST" },
            { "codigo": "300", "descricao": "Imune de ICMS" },
            { "codigo": "400", "descricao": "Não tributada pelo Simples Nacional" },
            { "codigo": "500", "descricao": "ICMS cobrado anteriormente por ST ou por antecipação" },
            { "codigo": "900", "descricao": "Outros" },
        ];
    }

    public getIPI(): SituacaoTributaria[] {
        return [
            { "codigo": "00", "descricao": "Entrada com Recuperação de Crédito" },
            { "codigo": "01", "descricao": "Entrada Tributável com Alíquota Zero" },
            { "codigo": "02", "descricao": "Entrada Isenta" },
            { "codigo": "03", "descricao": "Entrada Não-Tributada" },
            { "codigo": "04", "descricao": "Entrada Imune" },
            { "codigo": "05", "descricao": "Entrada com Suspensão" },
            { "codigo": "49", "descricao": "Outras Entradas" },
            { "codigo": "50", "descricao": "Saída tributada" },
            { "codigo": "51", "descricao": "Saída tributada com alíquota zero" },
            { "codigo": "52", "descricao": "Saída isenta" },
            { "codigo": "53", "descricao": "Saída não-tributada" },
            { "codigo": "54", "descricao": "Saída imune" },
            { "codigo": "55", "descricao": "Saída com suspensão" },
            { "codigo": "99", "descricao": "Outras saídas" },
        ];
    }

    public getPISCOFINS(): SituacaoTributaria[] {
        return [
            { "codigo": "01", "descricao": "Operação Tributável (base de cálculo = valor da operação alíquota normal (cumulativo/não cumulativo))" },
            { "codigo": "02", "descricao": "Operação Tributável (base de cálculo = valor da operação (alíquota diferenciada))" },
            { "codigo": "03", "descricao": "Operação Tributável (base de cálculo = quantidade vendida x alíquota por unidade de produto)" },
            { "codigo": "04", "descricao": "Operação Tributável (tributação monofásica (alíquota zero))" },
            { "codigo": "05", "descricao": "Operação Tributável por Substituição Tributária" },
            { "codigo": "06", "descricao": "Operação Tributável (alíquota zero)" },
            { "codigo": "07", "descricao": "Operação Isenta da Contribuição" },
            { "codigo": "08", "descricao": "Operação Sem Incidência da Contribuição" },
            { "codigo": "09", "descricao": "Operação com Suspensão da Contribuição" },
            { "codigo": "49", "descricao": "Outras Operações de Saída" },
            { "codigo": "50", "descricao": "Operação com Direito a Crédito Vinculada Exclusivamente a Receita Tributada no Mercado Interno" },
            { "codigo": "51", "descricao": "Operação com Direito a Crédito – Vinculada Exclusivamente a Receita Não Tributada no Mercado Interno" },
            { "codigo": "52", "descricao": "Operação com Direito a Crédito Vinculada Exclusivamente a Receita de Exportação" },
            { "codigo": "53", "descricao": "Operação com Direito a Crédito Vinculada a Receitas Tributadas e Não-Tributadas no Mercado Interno" },
            { "codigo": "54", "descricao": "Operação com Direito a Crédito Vinculada a Receitas Tributadas no Mercado Interno e de Exportação" },
            { "codigo": "55", "descricao": "Operação com Direito a Crédito Vinculada a Receitas Não-Tributadas no Mercado Interno e de Exportação" },
            { "codigo": "56", "descricao": "Operação com Direito a Crédito Vinculada a Receitas Tributadas e Não-Tributadas no Mercado Interno, e de Exportação" },
            { "codigo": "60", "descricao": "Crédito Presumido Operação de Aquisição Vinculada Exclusivamente a Receita Tributada no Mercado Interno" },
            { "codigo": "61", "descricao": "Crédito Presumido Operação de Aquisição Vinculada Exclusivamente a Receita Não-Tributada no Mercado Interno" },
            { "codigo": "62", "descricao": "Crédito Presumido Operação de Aquisição Vinculada Exclusivamente a Receita de Exportação" },
            { "codigo": "63", "descricao": "Crédito Presumido Operação de Aquisição Vinculada a Receitas Tributadas e Não-Tributadas no Mercado Interno" },
            { "codigo": "64", "descricao": "Crédito Presumido Operação de Aquisição Vinculada a Receitas Tributadas no Mercado Interno e de Exportação" },
            { "codigo": "65", "descricao": "Crédito Presumido Operação de Aquisição Vinculada a Receitas Não-Tributadas no Mercado Interno e de Exportação" },
            { "codigo": "66", "descricao": "Crédito Presumido Operação de Aquisição Vinculada a Receitas Tributadas e Não-Tributadas no Mercado Interno, e de Exportação" },
            { "codigo": "67", "descricao": "Crédito Presumido Outras Operações" },
            { "codigo": "70", "descricao": "Operação de Aquisição sem Direito a Crédito" },
            { "codigo": "71", "descricao": "Operação de Aquisição com Isenção" },
            { "codigo": "72", "descricao": "Operação de Aquisição com Suspensão" },
            { "codigo": "73", "descricao": "Operação de Aquisição a Alíquota Zero" },
            { "codigo": "74", "descricao": "Operação de Aquisição sem Incidência da Contribuição" },
            { "codigo": "75", "descricao": "Operação de Aquisição por Substituição Tributária" },
            { "codigo": "98", "descricao": "Outras Operações de Entrada" },
            { "codigo": "99", "descricao": "Outras Operações" },
        ];
    }
}