import actionBarComponent from "@/components/child/actionBar/actionBar.vue";
import checkboxComponent from "@/components/child/form/checkbox.vue";
import comboComponent from "@/components/child/form/combo.vue";
import dataTooltipComponent from "@/components/child/form/datatooltip.vue";
import { Component } from "@/decorators";
import ConfiguracaoRotuloModel from "@/models/configuracaoRotuloModel";
import OrigemRotulo from "@/models/enum/rotulo/origemRotulo";
import TiposProduto from "@/models/enum/tiposProduto";
import PaginationModel from "@/models/paginationModel";
import GrupoProdutoModel from "@/models/produto/grupoProdutoModel";
import ConfiguracaoRotuloService from "@/services/configuracaoRotuloService";
import GrupoProdutoService from "@/services/produto/grupoProdutoService";

import EditComponentBase from "../editComponentBase";

@Component({
    components: {
        checkboxComponent,
        comboComponent,
        actionBarComponent,
        dataTooltipComponent,
    },
})
export default class GrupoProdutoEditComponent extends EditComponentBase<GrupoProdutoModel> {
    rotulosManipulacaoOptions: Array<Object> = [];

    tiposProdutoOptions: Array<Object> = [
        { text: "Acabado", value: TiposProduto.Acabado },
        { text: "Semi-Acabado", value: TiposProduto.SemiAcabado },
        { text: "C\u00e1psula", value: TiposProduto.Capsula },
        { text: "Embalagem", value: TiposProduto.Embalagem },
        { text: "Floral", value: TiposProduto.Floral },
        { text: "Homeopatia", value: TiposProduto.Homeopatia },
        { text: "Mat\u00e9ria-Prima", value: TiposProduto.MateriaPrima },
    ];

    private async beforeMount() {
        await this.setProps(new GrupoProdutoService(), "grupos", new GrupoProdutoModel(), false, true);
    }

    public async onLoadModel(model: GrupoProdutoModel) {
        if (model.franquiaId) {
            await this.loadRotulosManipulacao();
        }
    }

    private async loadRotulosManipulacao() {
        try {
            const data = await new ConfiguracaoRotuloService()
                .comboByFranquiaId(this.model.franquiaId)
                .resolveWithJSON<PaginationModel<ConfiguracaoRotuloModel>>();

            this.rotulosManipulacaoOptions = data.list
                .filter(
                    item =>
                        item.origemDados == OrigemRotulo.Manipulacao ||
                        item.origemDados == OrigemRotulo.Acabado ||
                        item.origemDados == OrigemRotulo.Strips,
                )
                .map(item => ({ value: item.id, text: item.nome }));
        } catch {}
    }
}
