import Vue from "vue";

import searchComboComponent from "@/components/child/searchCombo/search.vue";
import ShortcutComponent from "@/components/parent/shortcut/shortcut";
import shortcutComponent from "@/components/parent/shortcut/shortcut.vue";
import { Component } from "@/decorators";
import ClienteModel from "@/models/clienteModel";
import ClienteService from "@/services/clienteService";

@Component({
    components: {
        searchComboComponent,
        shortcutComponent,
    },
})
export default class ClientesDuplicadosUnificarComponent extends Vue {
    private clienteService = new ClienteService();
    private shortcutComponent: ShortcutComponent = null;

    clienteIdOrigem = null;
    clienteIdDestino = null;

    private customComboTextCliente = (p: ClienteModel) => ({
        value: p.id,
        text: `${p.ativo ? "" : "[INATIVO]"} ${p.id} - ${p.nome}`,
    });

    public show() {
        this.clienteIdOrigem = null;
        this.clienteIdDestino = null;

        this.shortcutComponent = this.$refs.shortcutComponent as ShortcutComponent;
        this.shortcutComponent.title = this.$t("__.Components.parent.duplicacoes.list_vue_html.unificar") as string;
        this.shortcutComponent.show();
    }

    private async onUnificar() {
        const isValid = await this.$validator.validateAll();
        if (isValid) {
            const response = await this.$showQuestion(this.$t("__.ts.atencao"), this.$t("__.ts.unificacaoConfirmacao"));
            if (response) {
                try {
                    const sucesso = await this.clienteService
                        .unificarClientes(this.clienteIdOrigem, this.clienteIdDestino)
                        .withLoading()
                        .resolveWithoutJSON();

                    if (sucesso) {
                        await this.$showSuccess(this.$t("__.ts.sucesso"), this.$t("__.ts.unificacaoSucesso"));

                        this.shortcutComponent.hide();
                        return this.$emit("onRefresh");
                    }
                } catch {}

                await this.$showError(this.$t("__.ts.erro"), this.$t("__.ts.unificacaoErro"));
            }
        }
    }
}
