var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "crud crud-edit" },
    [
      _c("actionBarComponent", {
        attrs: {
          title: _vm.$t(
            "__.Crud.devolucaoCompra.consulta_vue_html.devolucaoCompra"
          ),
          showSave: false,
        },
      }),
      _vm._v(" "),
      _c(
        "form",
        {
          attrs: { novalidate: "novalidate" },
          on: {
            submit: function ($event) {
              $event.preventDefault()
              _vm.save()
            },
          },
        },
        [
          _c(
            "fieldset-component",
            {
              attrs: {
                title: _vm.$t(
                  "__.Crud.devolucaoCompra.consulta_vue_html.notaEntrada"
                ),
                collapsed: false,
              },
            },
            [
              _c("div", { attrs: { slot: "rows" }, slot: "rows" }, [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-sm-2" }, [
                    _c("div", { staticClass: "form-group" }, [
                      _c(
                        "label",
                        {
                          staticClass: "control-label",
                          attrs: { for: "numero-entrada" },
                        },
                        [
                          _vm._v(
                            "\n                                " +
                              _vm._s(
                                _vm.$t(
                                  "__.Crud.devolucaoCompra.consulta_vue_html.numero"
                                )
                              ) +
                              "\n                            "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.model.notaFiscalEntradaOrigem.numero,
                            expression: "model.notaFiscalEntradaOrigem.numero",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "number",
                          id: "numero-entrada",
                          name: "numero-entrada",
                          disabled: "",
                        },
                        domProps: {
                          value: _vm.model.notaFiscalEntradaOrigem.numero,
                        },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.model.notaFiscalEntradaOrigem,
                              "numero",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-2" }, [
                    _c("div", { staticClass: "form-group" }, [
                      _c(
                        "label",
                        {
                          staticClass: "control-label",
                          attrs: { for: "serie-entrada" },
                        },
                        [
                          _vm._v(
                            "\n                                " +
                              _vm._s(
                                _vm.$t(
                                  "__.Crud.devolucaoCompra.consulta_vue_html.serie"
                                )
                              ) +
                              "\n                            "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.model.notaFiscalEntradaOrigem.serie,
                            expression: "model.notaFiscalEntradaOrigem.serie",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "number",
                          id: "serie-entrada",
                          name: "serie-entrada",
                          disabled: "",
                        },
                        domProps: {
                          value: _vm.model.notaFiscalEntradaOrigem.serie,
                        },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.model.notaFiscalEntradaOrigem,
                              "serie",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-2" }, [
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "dataEmissao-entrada" },
                          },
                          [
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Crud.devolucaoCompra.consulta_vue_html.dataEmissao"
                                  )
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("date-time-picker-component", {
                          attrs: {
                            id: "dataEmissao-entrada",
                            name: "dataEmissao-entrada",
                            disabled: "",
                          },
                          model: {
                            value:
                              _vm.model.notaFiscalEntradaOrigem.dataEmissao,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.model.notaFiscalEntradaOrigem,
                                "dataEmissao",
                                $$v
                              )
                            },
                            expression:
                              "model.notaFiscalEntradaOrigem.dataEmissao",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      class:
                        _vm.model.notaFiscalEntradaOrigem.chave != null
                          ? "col-sm-5"
                          : "col-sm-6",
                    },
                    [
                      _c("div", { staticClass: "form-group" }, [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "chave-entrada" },
                          },
                          [
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Crud.devolucaoCompra.consulta_vue_html.chaveAcesso"
                                  )
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.model.notaFiscalEntradaOrigem.chave,
                              expression: "model.notaFiscalEntradaOrigem.chave",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            type: "text",
                            id: "chave-entrada",
                            name: "chave-entrada",
                            disabled: "",
                          },
                          domProps: {
                            value: _vm.model.notaFiscalEntradaOrigem.chave,
                          },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.model.notaFiscalEntradaOrigem,
                                "chave",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _vm.model.notaFiscalEntradaOrigem.chave != null
                    ? _c("div", { staticClass: "col-sm-1" }, [
                        _c("div", { staticClass: "form-group ajusteIncluir" }, [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-success",
                              staticStyle: { padding: "4px 14px" },
                              attrs: { type: "button" },
                              on: { click: _vm.onConsultaNotaOrigem },
                            },
                            [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(
                                    _vm.$t(
                                      "__.Crud.devolucaoCompra.consulta_vue_html.consultar"
                                    )
                                  ) +
                                  "\n                            "
                              ),
                            ]
                          ),
                        ]),
                      ])
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-sm-6" }, [
                    _c("div", { staticClass: "form-group" }, [
                      _c(
                        "label",
                        {
                          staticClass: "control-label",
                          attrs: { for: "fornecedor-entrada" },
                        },
                        [
                          _vm._v(
                            "\n                                " +
                              _vm._s(
                                _vm.$t(
                                  "__.Crud.devolucaoCompra.consulta_vue_html.fornecedor"
                                )
                              ) +
                              "\n                            "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value:
                              _vm.model.notaFiscalEntradaOrigem.fornecedorNome,
                            expression:
                              "model.notaFiscalEntradaOrigem.fornecedorNome",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          id: "fornecedor-entrada",
                          name: "fornecedor-entrada",
                          disabled: "",
                        },
                        domProps: {
                          value:
                            _vm.model.notaFiscalEntradaOrigem.fornecedorNome,
                        },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.model.notaFiscalEntradaOrigem,
                              "fornecedorNome",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-6" }, [
                    _c("div", { staticClass: "form-group" }, [
                      _c(
                        "label",
                        {
                          staticClass: "control-label",
                          attrs: { for: "transportadora-entrada" },
                        },
                        [
                          _vm._v(
                            "\n                                " +
                              _vm._s(
                                _vm.$t(
                                  "__.Crud.devolucaoCompra.consulta_vue_html.transportadora"
                                )
                              ) +
                              "\n                            "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value:
                              _vm.model.notaFiscalEntradaOrigem
                                .transportadoraNome,
                            expression:
                              "model.notaFiscalEntradaOrigem.transportadoraNome",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          id: "transportadora-entrada",
                          name: "transportadora-entrada",
                          disabled: "",
                        },
                        domProps: {
                          value:
                            _vm.model.notaFiscalEntradaOrigem
                              .transportadoraNome,
                        },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.model.notaFiscalEntradaOrigem,
                              "transportadoraNome",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                  ]),
                ]),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "fieldset-component",
            {
              attrs: {
                title: _vm.$t(
                  "__.Crud.devolucaoCompra.consulta_vue_html.notaDevolucao"
                ),
                collapsed: false,
              },
            },
            [
              _c("div", { attrs: { slot: "rows" }, slot: "rows" }, [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-sm-2" }, [
                    _c("div", { staticClass: "form-group" }, [
                      _c(
                        "label",
                        {
                          staticClass: "control-label",
                          attrs: { for: "numero-devolucao" },
                        },
                        [
                          _vm._v(
                            "\n                                " +
                              _vm._s(
                                _vm.$t(
                                  "__.Crud.devolucaoCompra.consulta_vue_html.numero"
                                )
                              ) +
                              "\n                            "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.model.notaFiscalSaidaDevolucao.numero,
                            expression: "model.notaFiscalSaidaDevolucao.numero",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "number",
                          id: "numero-devolucao",
                          name: "numero-devolucao",
                          disabled: "",
                        },
                        domProps: {
                          value: _vm.model.notaFiscalSaidaDevolucao.numero,
                        },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.model.notaFiscalSaidaDevolucao,
                              "numero",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-2" }, [
                    _c("div", { staticClass: "form-group" }, [
                      _c(
                        "label",
                        {
                          staticClass: "control-label",
                          attrs: { for: "serie-devolucao" },
                        },
                        [
                          _vm._v(
                            "\n                                " +
                              _vm._s(
                                _vm.$t(
                                  "__.Crud.devolucaoCompra.consulta_vue_html.serie"
                                )
                              ) +
                              "\n                            "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.model.notaFiscalSaidaDevolucao.serie,
                            expression: "model.notaFiscalSaidaDevolucao.serie",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "number",
                          id: "serie-devolucao",
                          name: "serie-devolucao",
                          disabled: "",
                        },
                        domProps: {
                          value: _vm.model.notaFiscalSaidaDevolucao.serie,
                        },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.model.notaFiscalSaidaDevolucao,
                              "serie",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-2" }, [
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "dataEmissao-devolucao" },
                          },
                          [
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Crud.devolucaoCompra.consulta_vue_html.dataEmissao"
                                  )
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("date-time-picker-component", {
                          attrs: {
                            id: "dataEmissao-devolucao",
                            name: "dataEmissao-devolucao",
                            disabled: "",
                          },
                          model: {
                            value:
                              _vm.model.notaFiscalSaidaDevolucao.dataEmissao,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.model.notaFiscalSaidaDevolucao,
                                "dataEmissao",
                                $$v
                              )
                            },
                            expression:
                              "model.notaFiscalSaidaDevolucao.dataEmissao",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      class:
                        _vm.model.notaFiscalSaidaDevolucao.chave != null
                          ? "col-sm-5"
                          : "col-sm-6",
                    },
                    [
                      _c("div", { staticClass: "form-group" }, [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "chave-devolucao" },
                          },
                          [
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Crud.devolucaoCompra.consulta_vue_html.chaveAcesso"
                                  )
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.model.notaFiscalSaidaDevolucao.chave,
                              expression:
                                "model.notaFiscalSaidaDevolucao.chave",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            type: "text",
                            id: "chave-devolucao",
                            name: "chave-devolucao",
                            disabled: "",
                          },
                          domProps: {
                            value: _vm.model.notaFiscalSaidaDevolucao.chave,
                          },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.model.notaFiscalSaidaDevolucao,
                                "chave",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _vm.model.notaFiscalSaidaDevolucao.chave != null
                    ? _c("div", { staticClass: "col-sm-1" }, [
                        _c("div", { staticClass: "form-group ajusteIncluir" }, [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-success",
                              staticStyle: { padding: "4px 14px" },
                              attrs: { type: "button" },
                              on: { click: _vm.onConsultaNotaDevolucao },
                            },
                            [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(
                                    _vm.$t(
                                      "__.Crud.devolucaoCompra.consulta_vue_html.consultar"
                                    )
                                  ) +
                                  "\n                            "
                              ),
                            ]
                          ),
                        ]),
                      ])
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-sm-6" }, [
                    _c("div", { staticClass: "form-group" }, [
                      _c(
                        "label",
                        {
                          staticClass: "control-label",
                          attrs: { for: "fornecedor-devolucao" },
                        },
                        [
                          _vm._v(
                            "\n                                " +
                              _vm._s(
                                _vm.$t(
                                  "__.Crud.devolucaoCompra.consulta_vue_html.fornecedor"
                                )
                              ) +
                              "\n                            "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value:
                              _vm.model.notaFiscalSaidaDevolucao.fornecedorNome,
                            expression:
                              "model.notaFiscalSaidaDevolucao.fornecedorNome",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          id: "fornecedor-devolucao",
                          name: "fornecedor-devolucao",
                          disabled: "",
                        },
                        domProps: {
                          value:
                            _vm.model.notaFiscalSaidaDevolucao.fornecedorNome,
                        },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.model.notaFiscalSaidaDevolucao,
                              "fornecedorNome",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-6" }, [
                    _c("div", { staticClass: "form-group" }, [
                      _c(
                        "label",
                        {
                          staticClass: "control-label",
                          attrs: { for: "transportadora-devolucao" },
                        },
                        [
                          _vm._v(
                            "\n                                " +
                              _vm._s(
                                _vm.$t(
                                  "__.Crud.devolucaoCompra.consulta_vue_html.transportadora"
                                )
                              ) +
                              "\n                            "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value:
                              _vm.model.notaFiscalSaidaDevolucao
                                .transportadoraNome,
                            expression:
                              "model.notaFiscalSaidaDevolucao.transportadoraNome",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          id: "transportadora-devolucao",
                          name: "transportadora-devolucao",
                          disabled: "",
                        },
                        domProps: {
                          value:
                            _vm.model.notaFiscalSaidaDevolucao
                              .transportadoraNome,
                        },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.model.notaFiscalSaidaDevolucao,
                              "transportadoraNome",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                  ]),
                ]),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "fieldset-component",
            {
              attrs: {
                title: _vm.$t(
                  "__.Crud.devolucaoCompra.consulta_vue_html.movimentacaoEstoque"
                ),
                collapsed: false,
              },
            },
            [
              _c(
                "div",
                { attrs: { slot: "rows" }, slot: "rows" },
                [
                  _c("grid-component", {
                    ref: "gridcomponent",
                    attrs: {
                      data: _vm.gridData,
                      columns: _vm.gridColumns,
                      showActionColumn: false,
                      "show-table-head": false,
                      "show-table-foot": false,
                    },
                  }),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c("fieldset", [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-sm-12 newbtn" }, [
                _c("div", { staticClass: "form-group" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-secondary",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          _vm.cancel()
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n                            " +
                          _vm._s(_vm.$t("__.Crud.voltar")) +
                          "\n                        "
                      ),
                    ]
                  ),
                ]),
              ]),
            ]),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _vm.loadComponentOrigem
        ? _c(
            "shortcutComponent",
            {
              ref: "shortcutComponentOrigem",
              attrs: { showBtnOk: false, showAddNew: false },
            },
            [
              _c(
                "div",
                { attrs: { slot: "component" }, slot: "component" },
                [
                  _c("notaFiscalEntradaComponent", {
                    ref: "notaFiscalEntradaComponent",
                    attrs: { isModal: true },
                  }),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.loadComponentDevolucao
        ? _c(
            "shortcutComponent",
            {
              ref: "shortcutComponentDevolucao",
              attrs: { showBtnOk: false, showAddNew: false },
            },
            [
              _c(
                "div",
                { attrs: { slot: "component" }, slot: "component" },
                [
                  _c("nfeComponent", {
                    ref: "nfeComponent",
                    attrs: { isModal: true },
                  }),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }