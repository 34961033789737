import { mapGetters } from "vuex";

import actionBarComponent from "@/components/child/actionBar/actionBar.vue";
import buttonIncluirComponent from "@/components/child/form/button/buttonIncluir.vue";
import checkboxComponent from "@/components/child/form/checkbox.vue";
import comboComponent from "@/components/child/form/combo.vue";
import dataTooltipComponent from "@/components/child/form/datatooltip.vue";
import decimalComponent from "@/components/child/form/decimal.vue";
import decimalComSinalCustomComponent from "@/components/child/form/decimalComSinalCustom.vue";
import fieldsetComponent from "@/components/child/form/fieldset.vue";
import moedaComponent from "@/components/child/form/moeda.vue";
import gridComponent from "@/components/child/grid/grid.vue";
import { GridColumn, GridColumnType } from "@/components/child/grid/gridColumn";
import { Component, Watch } from "@/decorators";
import ConfiguracaoDotzFaixaModel from "@/models/dotz/configuracaoDotzFaixaModel";
import ConfiguracaoDotzModel from "@/models/dotz/configuracaoDotzModel";
import EnumExtensions from "@/models/enum/extensions/enumExtensions";
import TipoEnvio from "@/models/enum/tipoEnvio";
import TipoFiltroDataVenda from "@/models/enum/TipoFiltroDataVenda";
import TiposFranquia from "@/models/enum/tiposFranquia";
import FranquiaModel from "@/models/franquiaModel";
import PaginationModel from "@/models/paginationModel";
import ConfiguracaoDotzService from "@/services/dotz/configuracaoDotzService";
import PontuacaoDotzService from "@/services/dotz/pontuacaoDotzService";
import FranquiaService from "@/services/franquiaService";
import { Getters } from "@/store/store";

import EditComponentBase from "../editComponentBase";

import "../crud.scss";

@Component({
    components: {
        checkboxComponent,
        dataTooltipComponent,
        moedaComponent,
        gridComponent,
        decimalComponent,
        decimalComSinalCustomComponent,
        comboComponent,
        fieldsetComponent,
        buttonIncluirComponent,
        actionBarComponent,
    },
    computed: mapGetters(["GET_FRANQUIA_ATUAL"] as Getters),
})
export default class ConfiguracaoDotzEditComponent extends EditComponentBase<ConfiguracaoDotzModel> {
    // State computed props
    GET_FRANQUIA_ATUAL: () => FranquiaModel;

    private isFranquiaModelo = false;

    private faixa = new ConfiguracaoDotzFaixaModel();
    private faixasFranquiaTemp: Array<ConfiguracaoDotzFaixaModel> = null;
    tiposEnvioOptions: Array<Object> = EnumExtensions.getNamesAndValuesOrderedByNames(TipoEnvio);
    franquiaOptions: Array<Object> = [];
    tipoFiltroDataVendaOptions: Array<Object> = [
        { text: "Data aprovação", value: TipoFiltroDataVenda.DataAprovacao },
        { text: "Data de faturamento - Com pelo menos um faturamento", value: TipoFiltroDataVenda.FaturamentoParcial },
        {
            text: "Data de faturamento - Somente com faturamento completo",
            value: TipoFiltroDataVenda.FaturamentoCompleto,
        },
    ];
    isAdmin = false;

    get gridFaixaColums(): Array<GridColumn> {
        return [
            new GridColumn(
                "inicioFaixa",
                this.$t("__.Crud.configuracaodotz.edit_vue_html.faixaInicioGrid") as string,
                GridColumnType.Money,
            ),
            new GridColumn(
                "fimFaixa",
                this.$t("__.Crud.configuracaodotz.edit_vue_html.faixaFimGrid") as string,
                GridColumnType.Money,
            ),
            new GridColumn(
                "multiplicador",
                this.$t("__.Crud.configuracaodotz.edit_vue_html.multiplicador") as string,
                GridColumnType.Integer,
            ),
            new GridColumn(
                "codigoMecanica",
                this.$t("__.Crud.configuracaodotz.edit_vue_html.codigoMecanica") as string,
                GridColumnType.Integer,
            ),
        ];
    }

    private beforeMount() {
        this.setProps(new ConfiguracaoDotzService(), "configuracao_dotz", new ConfiguracaoDotzModel());
        this.load(this.usuarioLogado.franquiaId);

        this.isFranquiaModelo = this.GET_FRANQUIA_ATUAL().tipoFranquia == TiposFranquia.BaseRede;

        this.loadFranquias();
    }

    public async onPreLoadModel() {
        if (!this.usuarioLogado.franquiaUtilizaDotz) {
            this.$showWarning(this.$t("__.ts.atencao"), this.$t("__.ts.franquiaNaoUsaDotz"));
            this.$router.back();
        }
        return true;
    }

    public async onLoadModel() {
        this.model.organizarFaixas(this.model.faixas);
    }

    public async onPreSave() {
        if (!this.model.faixas.length) {
            this.$showWarning(
                this.$t("__.ts.atencao"),
                this.$t("__.Crud.configuracaodotz.edit_vue_html.nenhumaFaixaCadastrada"),
            );
            return false;
        }
        return true;
    }

    private onRemoverFaixa(faixa) {
        this.model.removerFaixa(faixa);
    }

    private adicionarFaixa() {
        if (this.faixa.inicioFaixa && this.faixa.multiplicador) {
            if (
                this.model.faixas.some(f => f.inicioFaixa == this.faixa.inicioFaixa) ||
                this.model.faixas.some(f => f.multiplicador == this.faixa.multiplicador)
            ) {
                this.$showWarning(
                    this.$t("__.ts.atencao"),
                    this.$t("__.Crud.configuracaodotz.edit_vue_html.faixaSobreposta"),
                );
            } else {
                this.model.adicionarFaixa(this.faixa);
                this.faixa = new ConfiguracaoDotzFaixaModel();
            }
        }
    }

    //@ts-ignore
    @Watch("model.utilizaFaixasPadroes")
    private async onChangeUtilizaFaxiasPadroes() {
        if (this.model.utilizaFaixasPadroes) {
            await this.loadFaixasPadroes();
        } else if (this.faixasFranquiaTemp != null) {
            this.model.faixas = this.faixasFranquiaTemp;
        }
    }

    private async loadFranquias() {
        const data = await new FranquiaService()
            .combo()
            .withLoading()
            .resolveWithJSON<PaginationModel<FranquiaModel>>();
        this.franquiaOptions = data.list.map(item => ({ value: item.id, text: item.nomeFantasia }));
    }

    private async loadFaixasPadroes() {
        try {
            const faixasPadroes = await (this.service as ConfiguracaoDotzService)
                .GetFaixasPadroes()
                .resolveWithJSON<ConfiguracaoDotzFaixaModel[]>();

            if (faixasPadroes) {
                this.faixasFranquiaTemp = this.model.faixas;
                this.model.faixas = [];
                faixasPadroes.forEach(p => this.model.adicionarFaixa(p));
            } else {
                this.$showWarning(
                    this.$t("__.ts.atencao"),
                    this.$t("__.Crud.configuracaodotz.edit_vue_html.naoExistemFaixasPadroes"),
                );
                this.model.utilizaFaixasPadroes = false;
            }
        } catch {
            this.$showWarning(
                this.$t("__.ts.atencao"),
                this.$t("__.Crud.configuracaodotz.edit_vue_html.naoExistemFaixasPadroes"),
            );
            this.model.utilizaFaixasPadroes = false;
        }
    }

    private async enviarArquivosVendedores() {
        try {
            const sucesso = await new PontuacaoDotzService()
                .enviarArquivosVendedores()
                .withLoading()
                .resolveWithoutJSON();
            if (sucesso) {
                await this.$showSuccess(
                    this.$t("__.Crud.configuracaodotz.edit_vue_html.pontuacaoDotz"),
                    this.$t("__.Crud.configuracaodotz.edit_vue_html.enviadoArquivosVendedores"),
                );
            }
        } catch {}
    }

    public async afterMounted() {
        this.isAdmin = await this.HAS_PERMISSAO_PERFIL(false, false, false);
    }
}
