import checkboxComponent from "@/components/child/form/checkbox.vue";
import { Component } from "@/decorators";
import NcmModel from "@/models/produto/ncmModel";
import NcmService from "@/services/produto/ncmService";

import EditComponentBase from "../editComponentBase";

@Component({
    components: {
        checkboxComponent,
    },
})
export default class NcmEditComponent extends EditComponentBase<NcmModel> {
    private async beforeMount() {
        await this.setProps(new NcmService(), "ncms", new NcmModel(), false, true);
    }
}
