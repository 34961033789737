import Boot from "@/main";
import { SessionActions } from "@/store/sessionStore";
import Bus from "@/utils/events/bus";

const nfetch = function (url, options) {
    const token = localStorage.getItem("@PharmUP:Token");
    const webSocketId = sessionStorage.getItem("@PharmUP:WebSocketId");

    return fetch(`${process.env.VUE_APP_ROOT_API}${url}`, {
        ...options,
        headers: {
            Authorization: `Bearer ${token}`,
            "web-socket-id": webSocketId,
            ...options.headers,
        },
    }).then(response => {
        Bus.$emit("nfetch-service-event", response);

        if (response.status == 401) {
            if (localStorage.getItem("@PharmUP:Token")) {
                document.location.reload();
            }
            Boot.$store.dispatch(SessionActions.LOGOUT);
        }

        return response;
    });
};

export default nfetch;
