import ReceitaArquivoModel from "../models/receitaArquivoModel";
import FileHelper from "../utils/common/fileHelper";
import nfetch from "../utils/events/nfetch";

import { IService } from "./base/iService";

export default class ReceitaArquivoService extends IService<ReceitaArquivoModel> {
    constructor() {
        super("ReceitaArquivo");
    }

    public listFiles(vendaId: number): Promise<Response> {
        return nfetch(`/${this.controllerName}/List?vendaId=${vendaId}`, {
            credentials: "same-origin",
        });
    }

    public insertFile(model: ReceitaArquivoModel, file: File): Promise<Response> {
        const data = new FormData();
        const ext = file.getExtension();
        data.append(file.name, file, file.name);
        return nfetch(
            `/${this.controllerName}/Insert?vendaId=${model.vendaId}&descricao=${model.descricao}&extensao=${ext}`,
            {
                method: "post",
                credentials: "same-origin",
                headers: {
                    "XSRF-TOKEN": this.getCookie("XSRF-TOKEN"),
                },
                body: data,
            },
        );
    }

    public async showFiles(receitas: ReceitaArquivoModel[]) {
        for (const receita of receitas) {
            const response = await this.get(receita.id);
            await FileHelper.openBlank(response);
        }
    }
}
