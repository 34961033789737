import { TweenLite } from "gsap";
import Draggable from "gsap/Draggable";

const rowSize = 62; // => container height / number of items
const containers = [];
let yG = 0;
let lastTipo = 0;

containers.find = i => containers[i];

const startDrag = function initDrag() {
    containers.splice(0);
    Array.from(document.querySelectorAll(".container")).forEach(node => {
        let container;

        containers.push((container = this.createContainer(node)));

        Array.from(node.children).forEach(child => {
            container.insertItem(child);
        });

        container.indexItems();
    });
};

const clearContainers = function clearContainers(className = "") {
    if (className == "") {
        containers.splice(0);
    } else {
        containers.filter(p => p.name == className).forEach(p => p.sortables.splice(0));
    }
};

const addItemContainer = function addItemContainer(classeName, tipo = 0) {
    Array.from(document.querySelectorAll(classeName)).forEach(node => {
        let container;
        containers.push((container = createContainer(node, tipo, classeName)));

        Array.from(node.children).forEach(child => {
            //@ts-ignore
            if (child.tagName !== "I" && tipo !== 1) container.insertItem(child);
        });

        container.indexItems();
    });
};

const atualizaItensContainer = function atualizaItensContainer(classeName) {
    Array.from(document.querySelectorAll(classeName)).forEach(node => {
        const container = containers.filter(p => p.name === classeName)[0];

        Array.from(node.children).forEach(child => {
            //@ts-ignore
            if (child.tagName !== "I") {
                container.insertItem(child);
            }
        });
        container.indexItems();

        changeContainerHeigth();
    });
};

const addListItemContainer = function addListItemContainer(classeNameContainer, childListItem) {
    Array.from(document.querySelectorAll(classeNameContainer)).forEach(node => {
        const container = containers.filter(p => p.name === classeNameContainer)[0];
        if (childListItem.tagName !== "I") container.insertItem(childListItem);

        container.indexItems();
    });
};

const changeContainerHeigth = function changeContainerHeigth() {
    const highestRows = Math.max(...containers.filter(p => p.sortables.length > 2).map(p => p.sortables.length));
    if (highestRows > 2) containers[0].element.style.height = highestRows * rowSize + 38 + "px";
};

function createContainer(element, _tipo = 0, _name) {
    const index = containers.length;
    const sortables = []; // Array of sortables
    const container = {
        index: index,
        element: element,
        sortables: sortables,
        indexItems: indexItems,
        insertItem: insertItem,
        name: _name,
        tipo: _tipo,
    };

    TweenLite.to(element, 0.5, { autoAlpha: 1 });

    function indexItems() {
        sortables.forEach((s, i) => s.setIndex(i));
    }

    function insertItem(node) {
        container.sortables.push(Sortable(node, container.sortables.length, container.index));
    }

    return container;
}

function changeIndex(item, to) {
    const container = containers.find(item.col);

    // Change position in array
    arrayMove(container.sortables, item.index, to);

    // Set index for each sortable
    container.indexItems();
}

function Sortable(element, index, col) {
    const content = element.querySelector(".item-content");
    const order = element.querySelector(".order");

    const animation = TweenLite.to(content, 0.3, {
        boxShadow: "rgba(0,0,0,0.2) 0px 16px 32px 0px",
        force3D: true,
        scale: 1.1,
        paused: true,
    });

    const dragger = new Draggable(element, {
        onDragStart: onDragStart,
        onRelease: onRelease,
        onDrag: onDrag,
        cursor: "inherit",
        type: "x,y",
    });

    // Public properties and methods
    const sortable = {
        dragger: dragger,
        element: element,
        index: index,
        col: col,
        setIndex: setIndex,
        initCol: null,
    };

    TweenLite.set(element, { y: index * rowSize, x: 0 });

    function setIndex(index) {
        sortable.index = index;
        //numera os itens conforme posicao
        order.textContent = index + 1;

        // Don't layout if you're dragging
        if (!dragger.isDragging) {
            layout();
        }
    }

    function onDragStart() {
        sortable.initCol = sortable.col;
        animation.play();
        this.update();
    }

    function onDrag() {
        let container = containers.find(sortable.col);
        const last = container;
        const hitPercent = containers.length > 6 ? "44%" : "51%";

        containers.forEach(c => {
            if (c !== container && this.hitTest(c.element, hitPercent)) {
                container = c;
                sortable.col = c.index;
            }
        });

        if (container !== last) {
            // Remove the sortable from the last container;
            last.sortables.splice(sortable.index, 1);

            // Make sure we do not insert the sortable at an index higher than the next containers length...
            // Otherwise Natively Javascript will insert an 'undefined' value for every value between the containers length and the new item
            if (sortable.index > container.sortables.length - 1) {
                sortable.index = container.sortables.length;
            }

            // After the proper sortable index is set... insert the sortable into the new containers list
            container.sortables.splice(sortable.index, 0, sortable);

            // Re index the last containers items, so that the empty space is removed
            last.indexItems();

            // Next we want to append the dragging target to the new container, However there are special steps we must follow,
            // in order to keep the drag target's position
            if (container.element !== sortable.element.parentNode) {
                if (container.tipo === 1) {
                    container.element.appendChild(sortable.element);
                    this.endDrag();
                    const width = container.element.clientWidth;
                    const newX = width - width / 2;
                    const newY = this.y;
                    yG = newY;
                    lastTipo = 1;

                    TweenLite.set(sortable.element, { x: 0, y: 0 });
                    TweenLite.set(sortable.element, { x: 0, y: 0 });
                    this.startDrag(this.pointerEvent);
                } else {
                    container.element.appendChild(sortable.element);
                    this.endDrag();

                    // compensate for the directional shift in X.

                    const width = container.element.clientWidth;
                    const newX = container.index < last.index ? width - width / 2 : width / 2 - width;
                    const newY = lastTipo === 1 ? this.y + yG : this.y;
                    lastTipo = 0;
                    // Not sure why I have to set values to 0... the to the correct value,
                    // If I do not do this, it does not work for me.
                    TweenLite.set(sortable.element, { x: 0, y: 0 });
                    TweenLite.set(sortable.element, { x: newX, y: newY });

                    this.startDrag(this.pointerEvent);
                }
            }
        }

        // Calculate the current index based on element's position
        const index = clamp(Math.round(this.y / rowSize), 0, container.sortables.length - 1);

        if (index !== sortable.index) {
            changeIndex(sortable, index);
        }
    }

    function onRelease() {
        animation.reverse();

        if (sortable.initCol !== sortable.col) {
            const container = containers.find(sortable.col);
            container.indexItems();
        } else {
            lastTipo = 0;

            let container = containers.find(sortable.col);
            container.indexItems();
            if (container.tipo === 1) {
                const child = container.element.lastChild.cloneNode(true);
                child.firstChild.style.transform = "scale(1, 1)";
                child.firstChild.style.boxShadow = "";
                container.element.lastChild.remove();
                container = containers[0];
                if (child.getAttribute("id") === "personalizado") {
                    const trash = document.getElementById("trashIconContainer");
                    if (trash) {
                        const animationTrash = TweenLite.to(child, 0.5, {
                            boxShadow: "rgba(0,0,0,0.2) 0px 16px 32px 0px",
                            force3D: true,
                            scale: 0,
                            paused: false,
                        });

                        child.style.transform = "translate3d(0px,0px,0px)";
                        trash.appendChild(child);
                    }
                } else {
                    document.querySelectorAll(".menu-lateral")[0].appendChild(child);
                    container.insertItem(child);
                }
                changeContainerHeigth();
            } else {
                changeContainerHeigth();
                layout();
            }
        }
    }

    function layout() {
        TweenLite.to(element, 0.3, { y: sortable.index * rowSize, x: 0 });
    }

    return sortable;
}

// Changes an elements's position in array
function arrayMove(array, from, to) {
    array.splice(to, 0, array.splice(from, 1)[0]);
}

// Clamps a value to a min/max
function clamp(value, a, b) {
    return value < a ? a : value > b ? b : value;
}

export {
    startDrag,
    addItemContainer,
    atualizaItensContainer,
    clearContainers,
    addListItemContainer,
    changeContainerHeigth,
};
