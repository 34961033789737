import nfetch from "../utils/events/nfetch";

import { getCookie } from "./utils/serviceUtils";

export default class EstoqueComprometidoService {
    protected controllerName: string;

    constructor() {
        this.controllerName = "EstoqueComprometido";
    }

    public getQuantidadeComprometida(produtoId: number, itemId: number): Promise<Response> {
        return nfetch(`/${this.controllerName}/GetQuantidadeComprometida?produtoId=${produtoId}&itemId=${itemId}`, {
            credentials: "same-origin",
        });
    }

    public getQuantidadeComprometidaLote(
        loteId: number,
        itemId: number,
        estoqueId = 0,
        isValidacaoLote = false,
    ): Promise<Response> {
        return nfetch(
            `/${this.controllerName}/GetQuantidadeComprometidaLote?loteId=${loteId}&itemId=${itemId}&estoqueId=${estoqueId}&isValidacaoLote=${isValidacaoLote}`,
            {
                credentials: "same-origin",
            },
        );
    }

    public getVendasComprometendoLote(loteId: number, itemId: number): Promise<Response> {
        return nfetch(`/${this.controllerName}/GetVendasComprometendoLote?loteId=${loteId}&itemId=${itemId}`, {
            credentials: "same-origin",
        });
    }

    public getQuantidadeComprometidaLotesByIds(loteIds: number[]): Promise<Response> {
        return nfetch(`/${this.controllerName}/GetQuantidadeComprometidaLotesByIds`, {
            method: "post",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json; charset=UTF-8",
                "XSRF-TOKEN": getCookie("XSRF-TOKEN"),
            },
            body: JSON.stringify(loteIds),
        });
    }
}
