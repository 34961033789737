var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "crud crud-edit" },
    [
      !_vm.emissaoVenda
        ? _c(
            "actionBarComponent",
            {
              attrs: {
                slots: 8,
                title: _vm.$t("__.Components.parent.nfe.edit_vue_html.nfeEdit"),
                showSave: false,
              },
              scopedSlots: _vm._u([
                {
                  key: "buttonSlot7",
                  fn: function (ref) {
                    var open = ref.open
                    return _vm.consulta
                      ? _c(
                          "div",
                          {
                            attrs: {
                              title: _vm.$t("__.ts.historicoAlteracoes"),
                            },
                            on: {
                              click: function ($event) {
                                open(
                                  1,
                                  _vm.$t("__.ts.historicoAlteracoes"),
                                  "lg-modal"
                                )
                              },
                            },
                          },
                          [
                            _c("i", {
                              staticClass: "fa fa-history",
                              attrs: { slot: "button", "aria-hidden": "true" },
                              slot: "button",
                            }),
                          ]
                        )
                      : _vm._e()
                  },
                },
              ]),
            },
            [
              _vm.consulta && _vm.nota.statusEnvio == 2 && _vm.nota.modelo == 55
                ? _c(
                    "div",
                    {
                      attrs: {
                        slot: "buttonSlot0",
                        title: _vm.$t(
                          "__.Components.parent.nfe.edit_vue_html.cartaCorrecao"
                        ),
                      },
                      on: { click: _vm.onGerarCartaCorrecao },
                      slot: "buttonSlot0",
                    },
                    [
                      _c("i", {
                        staticClass: "fa fa-envelope",
                        attrs: { "aria-hidden": "true" },
                      }),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.consulta && _vm.nota.temCartaCorrecao
                ? _c(
                    "div",
                    {
                      attrs: {
                        slot: "buttonSlot1",
                        title: _vm.$t(
                          "__.Components.parent.nfe.edit_vue_html.consultarCartaCorrecao"
                        ),
                      },
                      on: { click: _vm.openCartaCorrecao },
                      slot: "buttonSlot1",
                    },
                    [
                      _c("i", {
                        staticClass: "fa fa-envelope-open",
                        attrs: { "aria-hidden": "true" },
                      }),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.consulta &&
              _vm.nota.pdfLink != null &&
              _vm.nota.statusEnvio == 2
                ? _c(
                    "div",
                    {
                      attrs: {
                        slot: "buttonSlot2",
                        title: _vm.$t(
                          "__.Components.parent.nfe.edit_vue_html.abrirPDF"
                        ),
                      },
                      on: { click: _vm.openPDF },
                      slot: "buttonSlot2",
                    },
                    [
                      _c("i", {
                        staticClass: "fa fa-file-pdf",
                        attrs: { "aria-hidden": "true" },
                      }),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.consulta &&
              _vm.nota.pdfSimplificadoLink &&
              _vm.nota.statusEnvio == 2
                ? _c(
                    "div",
                    {
                      attrs: {
                        slot: "buttonSlot3",
                        title: _vm.$t(
                          "__.Components.parent.nfe.edit_vue_html.abrirPDFSimplificado"
                        ),
                      },
                      on: { click: _vm.openPDFSimplificado },
                      slot: "buttonSlot3",
                    },
                    [
                      _c("i", {
                        staticClass: "fa fa-file-pdf",
                        attrs: { "aria-hidden": "true" },
                      }),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.consulta &&
              _vm.nota.xmlLink != null &&
              _vm.nota.statusEnvio == 2
                ? _c(
                    "div",
                    {
                      attrs: {
                        slot: "buttonSlot4",
                        title: _vm.$t(
                          "__.Components.parent.nfe.edit_vue_html.abrirXML"
                        ),
                      },
                      on: { click: _vm.openXML },
                      slot: "buttonSlot4",
                    },
                    [
                      _c("i", {
                        staticClass: "fa fa-file-code",
                        attrs: { "aria-hidden": "true" },
                      }),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.consulta && _vm.nota.statusEnvio == 2
                ? _c(
                    "div",
                    {
                      attrs: {
                        slot: "buttonSlot5",
                        title: _vm.$t(
                          "__.Components.parent.nfe.edit_vue_html.cancelarEmissao"
                        ),
                      },
                      on: { click: _vm.onCancelar },
                      slot: "buttonSlot5",
                    },
                    [
                      _c("i", {
                        staticClass: "fa fa-exclamation-circle",
                        attrs: { "aria-hidden": "true" },
                      }),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              !_vm.consulta
                ? _c(
                    "div",
                    { attrs: { slot: "buttonSlot6" }, slot: "buttonSlot6" },
                    [
                      _c("rascunhoComponent", {
                        ref: "rascunhoComponent",
                        attrs: { entity: "notasemitidas" },
                        on: {
                          onSaveRascunho: _vm.onSaveRascunho,
                          onLoadRascunho: _vm.onLoadRascunho,
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                { attrs: { slot: "modalSlot1" }, slot: "modalSlot1" },
                [
                  _c("logEntidadeComponent", {
                    attrs: { registroId: _vm.modelId, entidade: 8 },
                  }),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "form",
        {
          attrs: { novalidate: "novalidate" },
          on: {
            submit: function ($event) {
              $event.preventDefault()
              _vm.save()
            },
          },
        },
        [
          _c(
            "fieldset-component",
            {
              attrs: {
                title: _vm.$t(
                  "__.Components.parent.nfe.edit_vue_html.dadosNfe"
                ),
                collapsed: false,
              },
            },
            [
              _c("div", { attrs: { slot: "rows" }, slot: "rows" }, [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-sm-2" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("tipoOperacao"),
                            expression: "errors.first('tipoOperacao')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: {
                              for: "tipoOperacao",
                              title: _vm.$t("__.obrigatorio"),
                            },
                          },
                          [
                            _c("span", { staticStyle: { color: "red" } }, [
                              _vm._v("*"),
                            ]),
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Components.parent.nfe.edit_vue_html.operacao"
                                  )
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("combo-component", {
                          directives: [
                            { name: "focus", rawName: "v-focus" },
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required",
                              expression: "'required'",
                            },
                          ],
                          attrs: {
                            id: "tipoOperacao",
                            name: "tipoOperacao",
                            data: _vm.tipoOperacaoOptions,
                            searchable: false,
                            disabled: _vm.consulta || _vm.emissaoVenda,
                          },
                          model: {
                            value: _vm.nota.tipoOperacao,
                            callback: function ($$v) {
                              _vm.$set(_vm.nota, "tipoOperacao", $$v)
                            },
                            expression: "nota.tipoOperacao",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-2" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("finalidadeEmissao"),
                            expression: "errors.first('finalidadeEmissao')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: {
                              for: "finalidadeEmissao",
                              title: _vm.$t("__.obrigatorio"),
                            },
                          },
                          [
                            _c("span", { staticStyle: { color: "red" } }, [
                              _vm._v("*"),
                            ]),
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Components.parent.nfe.edit_vue_html.finalidadeEmissao"
                                  )
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("combo-component", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required",
                              expression: "'required'",
                            },
                          ],
                          attrs: {
                            id: "finalidadeEmissao",
                            name: "finalidadeEmissao",
                            data: _vm.finalidadeEmissaoOptions,
                            searchable: false,
                            disabled: _vm.consulta,
                          },
                          model: {
                            value: _vm.nota.finalidadeEmissao,
                            callback: function ($$v) {
                              _vm.$set(_vm.nota, "finalidadeEmissao", $$v)
                            },
                            expression: "nota.finalidadeEmissao",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-2" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("indicadorOperacao"),
                            expression: "errors.first('indicadorOperacao')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: {
                              for: "indicadorOperacao",
                              title: _vm.$t("__.obrigatorio"),
                            },
                          },
                          [
                            _c("span", { staticStyle: { color: "red" } }, [
                              _vm._v("*"),
                            ]),
                            _vm._v(
                              "\n                                Indicador de Operação\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("combo-component", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required",
                              expression: "'required'",
                            },
                          ],
                          attrs: {
                            id: "indicadorOperacao",
                            name: "indicadorOperacao",
                            data: _vm.indicadorOperacaoOptions,
                            searchable: false,
                            disabled: _vm.consulta,
                          },
                          model: {
                            value: _vm.nota.indicadorOperacao,
                            callback: function ($$v) {
                              _vm.$set(_vm.nota, "indicadorOperacao", $$v)
                            },
                            expression: "nota.indicadorOperacao",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      class:
                        _vm.nota.indicadorPresenca != 0 &&
                        _vm.nota.indicadorPresenca != 5
                          ? "col-sm-3"
                          : "col-sm-6",
                    },
                    [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: _vm.errors.first("indicadorPresenca"),
                              expression: "errors.first('indicadorPresenca')",
                            },
                          ],
                          staticClass: "form-group",
                        },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "control-label",
                              attrs: {
                                for: "indicadorPresenca",
                                title: _vm.$t("__.obrigatorio"),
                              },
                            },
                            [
                              _c("span", { staticStyle: { color: "red" } }, [
                                _vm._v("*"),
                              ]),
                              _vm._v(
                                "\n                                " +
                                  _vm._s(
                                    _vm.$t(
                                      "__.Components.parent.nfe.edit_vue_html.indicadorPresenca"
                                    )
                                  ) +
                                  "\n                            "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("combo-component", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required",
                                expression: "'required'",
                              },
                            ],
                            attrs: {
                              id: "indicadorPresenca",
                              name: "indicadorPresenca",
                              data: _vm.indicadorPresencaOptions,
                              searchable: false,
                              disabled:
                                _vm.consulta || _vm.nota.finalidadeEmissao == 2,
                            },
                            model: {
                              value: _vm.nota.indicadorPresenca,
                              callback: function ($$v) {
                                _vm.$set(_vm.nota, "indicadorPresenca", $$v)
                              },
                              expression: "nota.indicadorPresenca",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _vm.nota.indicadorPresenca != 0 &&
                  _vm.nota.indicadorPresenca != 5
                    ? _c("div", { staticClass: "col-sm-3" }, [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: _vm.errors.first("indicativoIntermedio"),
                                expression:
                                  "errors.first('indicativoIntermedio')",
                              },
                            ],
                            staticClass: "form-group",
                          },
                          [
                            _c(
                              "label",
                              {
                                staticClass: "control-label",
                                attrs: {
                                  for: "indicativoIntermedio",
                                  title: _vm.$t("__.obrigatorio"),
                                },
                              },
                              [
                                _c("span", { staticStyle: { color: "red" } }, [
                                  _vm._v("*"),
                                ]),
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(
                                      _vm.$t(
                                        "__.Components.parent.nfe.edit_vue_html.indicativoIntermedio"
                                      )
                                    ) +
                                    "\n                            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("combo-component", {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "required",
                                  expression: "'required'",
                                },
                              ],
                              attrs: {
                                id: "indicativoIntermedio",
                                name: "indicativoIntermedio",
                                data: _vm.indicativoIntermedioOptions,
                                searchable: false,
                                disabled: _vm.consulta,
                              },
                              model: {
                                value: _vm.nota.indicativoIntermedio,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.nota,
                                    "indicativoIntermedio",
                                    $$v
                                  )
                                },
                                expression: "nota.indicativoIntermedio",
                              },
                            }),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _vm.consulta
                  ? _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-sm-2" }, [
                        _c("div", { staticClass: "form-group" }, [
                          _c(
                            "label",
                            {
                              staticClass: "control-label",
                              attrs: { for: "numero" },
                            },
                            [_vm._v("Número da nota")]
                          ),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.nota.numero,
                                expression: "nota.numero",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: {
                              type: "text",
                              id: "numero",
                              name: "numero",
                              disabled: true,
                            },
                            domProps: { value: _vm.nota.numero },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.nota,
                                  "numero",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-sm-2" }, [
                        _c("div", { staticClass: "form-group" }, [
                          _c(
                            "label",
                            {
                              staticClass: "control-label",
                              attrs: { for: "serie" },
                            },
                            [_vm._v("Série")]
                          ),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.nota.serie,
                                expression: "nota.serie",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: {
                              type: "text",
                              id: "serie",
                              name: "serie",
                              disabled: true,
                            },
                            domProps: { value: _vm.nota.serie },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(_vm.nota, "serie", $event.target.value)
                              },
                            },
                          }),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-sm-2" }, [
                        _c("div", { staticClass: "form-group" }, [
                          _c(
                            "label",
                            {
                              staticClass: "control-label",
                              attrs: { for: "statusEnvio" },
                            },
                            [_vm._v("Status do envio")]
                          ),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.nota.statusEnvioDescricao,
                                expression: "nota.statusEnvioDescricao",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: {
                              type: "text",
                              id: "statusEnvio",
                              name: "statusEnvio",
                              disabled: "",
                            },
                            domProps: { value: _vm.nota.statusEnvioDescricao },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.nota,
                                  "statusEnvioDescricao",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-sm-6" }, [
                        _c("div", { staticClass: "form-group" }, [
                          _c(
                            "label",
                            {
                              staticClass: "control-label",
                              attrs: { for: "chave" },
                            },
                            [_vm._v("Chave da nota")]
                          ),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.nota.chave,
                                expression: "nota.chave",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: {
                              type: "text",
                              id: "chave",
                              name: "chave",
                              disabled: true,
                            },
                            domProps: { value: _vm.nota.chave },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(_vm.nota, "chave", $event.target.value)
                              },
                            },
                          }),
                        ]),
                      ]),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.nota.finalidadeEmissao == 4 ||
                _vm.nota.finalidadeEmissao == 2
                  ? _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-sm-12" }, [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: _vm.errors.first("chaveReferenciada"),
                                expression: "errors.first('chaveReferenciada')",
                              },
                            ],
                            staticClass: "form-group",
                          },
                          [
                            _c(
                              "label",
                              {
                                staticClass: "control-label",
                                attrs: {
                                  for: "chaveReferenciada",
                                  title: _vm.$t("__.obrigatorio"),
                                },
                              },
                              [
                                _c("span", { staticStyle: { color: "red" } }, [
                                  _vm._v("*"),
                                ]),
                                _vm._v(
                                  "\n                                Chave da nota referenciada\n                            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.nota.chaveReferenciada,
                                  expression: "nota.chaveReferenciada",
                                },
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "required",
                                  expression: "'required'",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: {
                                type: "text",
                                id: "chaveReferenciada",
                                name: "chaveReferenciada",
                                disabled: _vm.consulta,
                              },
                              domProps: { value: _vm.nota.chaveReferenciada },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.nota,
                                    "chaveReferenciada",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                          ]
                        ),
                      ]),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-sm-3" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("modelo"),
                            expression: "errors.first('modelo')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: {
                              for: "modelo",
                              title: _vm.$t("__.obrigatorio"),
                            },
                          },
                          [
                            _c("span", { staticStyle: { color: "red" } }, [
                              _vm._v("*"),
                            ]),
                            _vm._v(
                              "\n                                Modelo do documento\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("combo-component", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required",
                              expression: "'required'",
                            },
                          ],
                          attrs: {
                            id: "modelo",
                            name: "modelo",
                            data: _vm.modeloOptions,
                            searchable: false,
                            disabled:
                              _vm.nota.tipoOperacao == 0 || _vm.consulta,
                          },
                          model: {
                            value: _vm.nota.modelo,
                            callback: function ($$v) {
                              _vm.$set(_vm.nota, "modelo", $$v)
                            },
                            expression: "nota.modelo",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { class: "col-sm-6" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("naturezaOperacaoId"),
                            expression: "errors.first('naturezaOperacaoId')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: {
                              for: "naturezaOperacaoId",
                              title: _vm.$t("__.obrigatorio"),
                            },
                          },
                          [
                            _c("span", { staticStyle: { color: "red" } }, [
                              _vm._v("*"),
                            ]),
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Components.parent.nfe.edit_vue_html.cfop"
                                  )
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "combo-component",
                          {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required|maior_que_zero",
                                expression: "'required|maior_que_zero'",
                              },
                            ],
                            attrs: {
                              id: "naturezaOperacaoId",
                              name: "naturezaOperacaoId",
                              actionName: "naturezas_de_operacao",
                              addEditId:
                                _vm.nota.naturezaOperacaoId > 0 && !_vm.consulta
                                  ? _vm.nota.naturezaOperacaoId
                                  : 0,
                              addNew:
                                _vm.nota.naturezaOperacaoId > 0 ||
                                !_vm.consulta,
                              data: _vm.cfopOptions,
                              disabled: _vm.consulta,
                              formAddNewName:
                                _vm.nota.naturezaOperacaoId > 0
                                  ? "Editar natureza de operação"
                                  : "Nova natureza de operação",
                              refresh: _vm.consulta ? null : _vm.carregarCfops,
                              searchable: !_vm.consulta,
                              useCloseModalCallback: true,
                            },
                            on: {
                              addNewItemCombo: _vm.onAddNaturezaOperacao,
                              openComboEditItem:
                                _vm.openComboEditNaturezaOperacao,
                              openComboNewItem: _vm.openNewNaturezaOperacao,
                            },
                            model: {
                              value: _vm.nota.naturezaOperacaoId,
                              callback: function ($$v) {
                                _vm.$set(_vm.nota, "naturezaOperacaoId", $$v)
                              },
                              expression: "nota.naturezaOperacaoId",
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                attrs: { slot: "component" },
                                slot: "component",
                              },
                              [
                                _c("naturezaOperacaoComponent", {
                                  ref: "naturezaOperacaoComponent",
                                  attrs: { CalledByShortCut: true },
                                  on: {
                                    "save-ok": _vm.onNaturezaOperacaoSaveOk,
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-1" }, [
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "movimentarEstoque" },
                          },
                          [
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Components.parent.nfe.edit_vue_html.movimentaEstoque"
                                  )
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("checkbox-component", {
                          attrs: {
                            id: "movimentarEstoque",
                            name: "movimentarEstoque",
                            disabled: _vm.consulta,
                          },
                          model: {
                            value: _vm.nota.movimentarEstoque,
                            callback: function ($$v) {
                              _vm.$set(_vm.nota, "movimentarEstoque", $$v)
                            },
                            expression: "nota.movimentarEstoque",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _vm.nota.movimentarEstoque
                    ? _c("div", { staticClass: "col-sm-2" }, [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: _vm.errors.first("estoqueId"),
                                expression: "errors.first('estoqueId')",
                              },
                            ],
                            staticClass: "form-group",
                          },
                          [
                            _c(
                              "label",
                              {
                                staticClass: "control-label",
                                attrs: {
                                  for: "estoqueId",
                                  title: _vm.$t("__.obrigatorio"),
                                },
                              },
                              [
                                _c("span", { staticClass: "color-red" }, [
                                  _vm._v("*"),
                                ]),
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(
                                      _vm.$t(
                                        "__.Components.parent.nfe.edit_vue_html.estoque"
                                      )
                                    ) +
                                    "\n                            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("combo-component", {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "required",
                                  expression: "'required'",
                                },
                              ],
                              attrs: {
                                id: "estoqueId",
                                name: "estoqueId",
                                data: _vm.estoqueOptions,
                                searchable: true,
                                disabled: _vm.consulta,
                              },
                              model: {
                                value: _vm.nota.estoqueId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.nota, "estoqueId", $$v)
                                },
                                expression: "nota.estoqueId",
                              },
                            }),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _vm.nota.modelo == 65
                  ? _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-sm-2" }, [
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c(
                              "label",
                              {
                                staticClass: "control-label",
                                attrs: { for: "semDestinatario" },
                              },
                              [_vm._v("Sem destinatário")]
                            ),
                            _vm._v(" "),
                            _c("checkbox-component", {
                              attrs: {
                                id: "semDestinatario",
                                name: "semDestinatario",
                                disabled: _vm.consulta,
                              },
                              model: {
                                value: _vm.nota.semDestinatario,
                                callback: function ($$v) {
                                  _vm.$set(_vm.nota, "semDestinatario", $$v)
                                },
                                expression: "nota.semDestinatario",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-sm-10" }, [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: _vm.errors.first("destinatarioId"),
                                expression: "errors.first('destinatarioId')",
                              },
                            ],
                            staticClass: "form-group",
                          },
                          [
                            _c(
                              "label",
                              {
                                staticClass: "control-label",
                                attrs: {
                                  for: "destinatarioId",
                                  title: _vm.$t("__.obrigatorio"),
                                },
                              },
                              [
                                _c("span", { staticStyle: { color: "red" } }, [
                                  _vm._v("*"),
                                ]),
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(
                                      _vm.$t(
                                        "__.Components.parent.nfe.edit_vue_html.cliente"
                                      )
                                    ) +
                                    "\n                            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "search-combo-component",
                              {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: "required",
                                    expression: "'required'",
                                  },
                                ],
                                attrs: {
                                  id: "destinatarioId",
                                  name: "destinatarioId",
                                  actionName: "clientes",
                                  addEditId:
                                    _vm.nota.destinatarioId > 0 && !_vm.consulta
                                      ? _vm.nota.destinatarioId
                                      : 0,
                                  addNew:
                                    _vm.nota.destinatarioId > 0 ||
                                    !_vm.consulta,
                                  disabled:
                                    (_vm.nota.modelo == 65 &&
                                      _vm.nota.semDestinatario) ||
                                    _vm.consulta,
                                  formAddNewName:
                                    _vm.nota.destinatarioId > 0
                                      ? "Editar cliente"
                                      : "Novo cliente",
                                  useCloseModalCallback: true,
                                  customComboText: _vm.customComboTextCliente,
                                  advancedSearch: true,
                                  advancedSearchCampos: [
                                    { campo: "text", descricao: "Nome" },
                                    { campo: "cpf", descricao: "CNPJ/CPF" },
                                    { campo: "celular", descricao: "Celular" },
                                  ],
                                  customView: true,
                                  propDescription: "nome",
                                  service: "Cliente",
                                },
                                on: {
                                  addNewItemCombo: _vm.onAddCliente,
                                  openComboEditItem: _vm.openComboEditCliente,
                                  openComboNewItem: _vm.openNewCliente,
                                },
                                model: {
                                  value: _vm.clienteId,
                                  callback: function ($$v) {
                                    _vm.clienteId = $$v
                                  },
                                  expression: "clienteId",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    attrs: { slot: "component" },
                                    slot: "component",
                                  },
                                  [
                                    _c("clienteComponent", {
                                      ref: "clienteComponent",
                                      attrs: { CalledByShortCut: true },
                                      on: { "save-ok": _vm.onClienteSaveOk },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]),
                    ])
                  : _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-sm-2" }, [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: _vm.errors.first("tipoCadastro"),
                                expression: "errors.first('tipoCadastro')",
                              },
                            ],
                            staticClass: "form-group",
                          },
                          [
                            _c(
                              "label",
                              {
                                staticClass: "control-label",
                                attrs: {
                                  for: "tipoCadastro",
                                  title: _vm.$t("__.obrigatorio"),
                                },
                              },
                              [
                                _c("span", { staticStyle: { color: "red" } }, [
                                  _vm._v("*"),
                                ]),
                                _vm._v(
                                  "\n                                Tipo de cadastro\n                            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("combo-component", {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "required",
                                  expression: "'required'",
                                },
                              ],
                              attrs: {
                                id: "tipoCadastro",
                                name: "tipoCadastro",
                                data: _vm.tipoCadastroOptions,
                                searchable: _vm.consulta ? false : true,
                                disabled: _vm.consulta,
                              },
                              model: {
                                value: _vm.nota.tipoCadastro,
                                callback: function ($$v) {
                                  _vm.$set(_vm.nota, "tipoCadastro", $$v)
                                },
                                expression: "nota.tipoCadastro",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _vm.nota.tipoCadastro == 0
                        ? _c("div", { staticClass: "col-sm-10" }, [
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "tooltip",
                                    rawName: "v-tooltip",
                                    value: _vm.errors.first("destinatarioId"),
                                    expression:
                                      "errors.first('destinatarioId')",
                                  },
                                ],
                                staticClass: "form-group",
                              },
                              [
                                _c(
                                  "label",
                                  {
                                    staticClass: "control-label",
                                    attrs: {
                                      for: "destinatarioId",
                                      title: _vm.$t("__.obrigatorio"),
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticStyle: { color: "red" } },
                                      [_vm._v("*")]
                                    ),
                                    _vm._v(
                                      "\n                                " +
                                        _vm._s(
                                          _vm.$t(
                                            "__.Components.parent.nfe.edit_vue_html.fornecedor"
                                          )
                                        ) +
                                        "\n                            "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "combo-component",
                                  {
                                    directives: [
                                      {
                                        name: "validate",
                                        rawName: "v-validate",
                                        value: "required",
                                        expression: "'required'",
                                      },
                                    ],
                                    attrs: {
                                      id: "destinatarioId",
                                      name: "destinatarioId",
                                      actionName: "fornecedores",
                                      addEditId:
                                        _vm.nota.destinatarioId > 0 &&
                                        !_vm.consulta
                                          ? _vm.nota.destinatarioId
                                          : 0,
                                      addNew:
                                        _vm.nota.destinatarioId > 0 ||
                                        !_vm.consulta,
                                      data: _vm.fornecedorOptions,
                                      disabled: _vm.consulta,
                                      formAddNewName:
                                        _vm.nota.destinatarioId > 0
                                          ? "Editar fornecedor"
                                          : "Novo fornecedor",
                                      refresh: _vm.consulta
                                        ? null
                                        : _vm.carregarFornecedores,
                                      searchable: !_vm.consulta,
                                      useCloseModalCallback: true,
                                    },
                                    on: {
                                      addNewItemCombo: _vm.onAddFornecedor,
                                      openComboEditItem:
                                        _vm.openComboEditFornecedor,
                                      openComboNewItem: _vm.openNewFornecedor,
                                    },
                                    model: {
                                      value: _vm.nota.destinatarioId,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.nota,
                                          "destinatarioId",
                                          $$v
                                        )
                                      },
                                      expression: "nota.destinatarioId",
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        attrs: { slot: "component" },
                                        slot: "component",
                                      },
                                      [
                                        _c("fornecedorComponent", {
                                          ref: "fornecedorComponent",
                                          attrs: { CalledByShortCut: true },
                                          on: {
                                            "save-ok": _vm.onFornecedorSaveOk,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.nota.tipoCadastro == 1
                        ? _c("div", { staticClass: "col-sm-10" }, [
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "tooltip",
                                    rawName: "v-tooltip",
                                    value: _vm.errors.first("destinatarioId"),
                                    expression:
                                      "errors.first('destinatarioId')",
                                  },
                                ],
                                staticClass: "form-group",
                              },
                              [
                                _c(
                                  "label",
                                  {
                                    staticClass: "control-label",
                                    attrs: {
                                      for: "destinatarioId",
                                      title: _vm.$t("__.obrigatorio"),
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticStyle: { color: "red" } },
                                      [_vm._v("*")]
                                    ),
                                    _vm._v(
                                      "\n                                " +
                                        _vm._s(
                                          _vm.$t(
                                            "__.Components.parent.nfe.edit_vue_html.cliente"
                                          )
                                        ) +
                                        "\n                            "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "search-combo-component",
                                  {
                                    directives: [
                                      {
                                        name: "validate",
                                        rawName: "v-validate",
                                        value: "required",
                                        expression: "'required'",
                                      },
                                    ],
                                    attrs: {
                                      id: "destinatarioId",
                                      name: "destinatarioId",
                                      actionName: "clientes",
                                      addEditId:
                                        _vm.nota.destinatarioId > 0 &&
                                        !_vm.consulta
                                          ? _vm.nota.destinatarioId
                                          : 0,
                                      addNew:
                                        _vm.nota.destinatarioId > 0 ||
                                        !_vm.consulta,
                                      disabled: _vm.consulta,
                                      formAddNewName:
                                        _vm.nota.destinatarioId > 0
                                          ? "Editar cliente"
                                          : "Novo cliente",
                                      useCloseModalCallback: true,
                                      customComboText:
                                        _vm.customComboTextCliente,
                                      advancedSearch: true,
                                      advancedSearchCampos: [
                                        { campo: "text", descricao: "Nome" },
                                        { campo: "cpf", descricao: "CNPJ/CPF" },
                                        {
                                          campo: "celular",
                                          descricao: "Celular",
                                        },
                                      ],
                                      customView: true,
                                      propDescription: "nome",
                                      service: "Cliente",
                                    },
                                    on: {
                                      addNewItemCombo: _vm.onAddCliente,
                                      openComboEditItem:
                                        _vm.openComboEditCliente,
                                      openComboNewItem: _vm.openNewCliente,
                                    },
                                    model: {
                                      value: _vm.clienteId,
                                      callback: function ($$v) {
                                        _vm.clienteId = $$v
                                      },
                                      expression: "clienteId",
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        attrs: { slot: "component" },
                                        slot: "component",
                                      },
                                      [
                                        _c("clienteComponent", {
                                          ref: "clienteComponent",
                                          attrs: { CalledByShortCut: true },
                                          on: {
                                            "save-ok": _vm.onClienteSaveOk,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ])
                        : _vm._e(),
                    ]),
              ]),
            ]
          ),
          _vm._v(" "),
          _c("item-list-component", { ref: "itemListComponent" }),
          _vm._v(" "),
          _c("transportadora-component"),
          _vm._v(" "),
          _c("volumes-component"),
          _vm._v(" "),
          _c("cobranca-component"),
          _vm._v(" "),
          _c("pagamento-component"),
          _vm._v(" "),
          _c("retencoes-component"),
          _vm._v(" "),
          _c("exportacao-component", { ref: "exportacaoComponent" }),
          _vm._v(" "),
          _c(
            "fieldset-component",
            { attrs: { title: "Observações", collapsed: true } },
            [
              _c("div", { attrs: { slot: "rows" }, slot: "rows" }, [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-sm-12" }, [
                    _c("div", { staticClass: "form-group" }, [
                      _c(
                        "label",
                        {
                          staticClass: "control-label",
                          attrs: { for: "observacao" },
                        },
                        [
                          _vm._v(
                            "\n                                " +
                              _vm._s(
                                _vm.$t(
                                  "__.Components.parent.nfe.edit_vue_html.obs"
                                )
                              ) +
                              "\n                            "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.nota.observacao,
                            expression: "nota.observacao",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          id: "observacao",
                          name: "observacao",
                          disabled: _vm.consulta,
                        },
                        domProps: { value: _vm.nota.observacao },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.nota,
                              "observacao",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                  ]),
                ]),
              ]),
            ]
          ),
          _vm._v(" "),
          !_vm.emissaoVenda && !_vm.isModal
            ? _c("fieldset", [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-sm-12 newbtn" }, [
                    _c("div", { staticClass: "form-group" }, [
                      !_vm.consulta
                        ? _c(
                            "button",
                            {
                              staticClass: "btn btn-info",
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  _vm.resumo()
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n                            Resumo\n                        "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.consulta
                        ? _c(
                            "button",
                            {
                              staticClass: "btn btn-success",
                              attrs: { type: "submit" },
                            },
                            [
                              _c("i", {
                                staticClass: "fa fa-save",
                                attrs: { "aria-hidden": "true" },
                              }),
                              _vm._v(
                                "\n                            " +
                                  _vm._s(
                                    _vm.$t(
                                      "__.Components.parent.nfe.edit_vue_html.salvarEEmitir"
                                    )
                                  ) +
                                  "\n                        "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-secondary",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              _vm.cancel()
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n                            " +
                              _vm._s(_vm.$t("__.Crud.voltar")) +
                              "\n                        "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      (_vm.nota.id && _vm.nota.statusEnvio < 2) ||
                      _vm.nota.statusEnvio == 4
                        ? _c(
                            "button",
                            {
                              staticClass: "btn btn-success",
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  _vm.consultaEmissao().withLoading()
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(
                                    _vm.$t(
                                      "__.Components.parent.notafiscalsaida.edit_vue_html.consultEmissao"
                                    )
                                  ) +
                                  "\n                        "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ]),
                  ]),
                ]),
              ])
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "shortcutComponent",
        { ref: "shortcutComponent", attrs: { showBtnOk: false } },
        [
          _c(
            "div",
            { attrs: { slot: "component" }, slot: "component" },
            [_c("resumo-component", { ref: "resumoComponent" })],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }