enum TiposFormulaPadrao {
    Acabado = 0,
    Diluicao = 1,
    Base = 2,
    Excipiente = 3,
    PreVenda = 4,
    PreVendaTabelada = 5,
}

export default TiposFormulaPadrao;
