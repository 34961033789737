import Vue from "vue";

import fieldsetComponent from "@/components/child/form/fieldset.vue";
import ShortcutComponent from "@/components/parent/shortcut/shortcut";
import shortcutComponent from "@/components/parent/shortcut/shortcut.vue";
import { Component } from "@/decorators";
import CampanhaPontuacaoModel from "@/models/programafidelidade/campanhaPontuacaoModel";
import CampanhaService from "@/services/campanhaService";

import "../crud.scss";

@Component({
    components: {
        fieldsetComponent,
        shortcutComponent,
    },
})
export default class PontosComponent extends Vue {
    private campanhaService = new CampanhaService();
    private shortcutComponent: ShortcutComponent = null;

    private model: CampanhaPontuacaoModel[] = [];

    public async load(documento: string) {
        this.model = await this.campanhaService
            .getPontosByDocumento(documento)
            .withLoading()
            .resolveWithJSON<CampanhaPontuacaoModel[]>();

        if (this.model.length === 0) {
            return await this.$showWarning(this.$t("__.ts.atencao"), this.$t("__.ts.nenhumaPontuacao"));
        }

        this.shortcutComponent = this.$refs.shortcutComponent as ShortcutComponent;
        this.shortcutComponent.show();
    }
}
