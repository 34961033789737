import Vue from "vue";
import { mapState } from "vuex";

import Grid from "@/components/child/grid/grid";
import gridComponent from "@/components/child/grid/grid.vue";
import { GridColumn, GridColumnType } from "@/components/child/grid/gridColumn";
import LoadingModalComponent from "@/components/child/loadingmodal/loadingmodal";
import loadingModalComponent from "@/components/child/loadingmodal/loadingmodal.vue";
import { Component, Watch } from "@/decorators";
import InventarioModel from "@/models/inventarioModel";
import PaginationModel from "@/models/paginationModel";
import InventarioService from "@/services/inventarioService";

import "../crud.scss";

@Component({
    components: {
        gridComponent,
        loadingModalComponent,
    },
    computed: mapState({
        loadingPack: state => state["paginationStatus"].loading,
        paginationParams: state => state["paginationStatus"].paginationParams,
    }),
})
export default class InventarioListComponent extends Vue {
    private service = new InventarioService();
    private loadingModalComponent: LoadingModalComponent = null;
    private gridcomponent: Grid = null;
    startFilterKey = "";
    gridFilterKey = "";
    gridSortKey = "dataCriacao";
    gridSortOrder = "desc";
    gridData: Array<InventarioModel> = [];
    get gridColumns(): Array<GridColumn> {
        return [
            new GridColumn("dataCriacao", "Data", GridColumnType.Date),
            new GridColumn("tipoInventarioDescricao", "Tipo de Inventario", GridColumnType.String),
            new GridColumn("usuarioDescricao", "Usuario", GridColumnType.String),
            new GridColumn("statusDescricao", "Status", GridColumnType.String),
        ];
    }

    pageIndex = 1;
    pageSize = 20;
    total = 0;

    pags = false;
    tipoDocGerar: number = null;

    private load() {
        this.loadingModalComponent.showLoading();
        this.service
            .list(
                this.gridFilterKey,
                this.gridSortKey,
                this.gridSortOrder,
                this.pags ? 1 : this.pageIndex,
                this.pags ? 999999 : this.pageSize,
            )
            .then(response => {
                return response.json() as Promise<PaginationModel<InventarioModel>>;
            })
            .then(data => {
                this.gridData = data.list;
                this.total = data.total;
                if (this.pags) {
                    if (this.tipoDocGerar == 0) {
                        setTimeout(() => {
                            this.gridcomponent.gerarXls();
                        }, 2000);
                    }

                    if (this.tipoDocGerar == 1) {
                        setTimeout(() => {
                            this.gridcomponent.gerarPdf();
                        }, 2000);
                    }

                    if (this.tipoDocGerar == 2) {
                        setTimeout(() => {
                            this.gridcomponent.gerarImpressao();
                        }, 2000);
                    }

                    setTimeout(() => {
                        this.pags = false;
                    }, 2000);
                }
                this.loadingModalComponent.hide();
            })
            .catch(error => {
                this.loadingModalComponent.hide();
            });
    }

    private onEditItem(model: InventarioModel) {
        this.$router.push("/inventario-edicao/" + model.id);
    }

    private async onRemoveItem(model: InventarioModel) {
        const response = await this.$showExclusaoQuestion(
            `${this.$t("__.ts.desejaExclInventario")}${model.tipoInventarioDescricao} ${model.statusDescricao}?`,
        );

        if (response) {
            try {
                const sucesso = await this.service.delete(model.id).withLoading().resolveWithoutJSON();
                if (sucesso) {
                    this.load();
                    this.$showExclusao();
                }
            } catch {}
        }
    }

    private onCreateItem() {
        this.$router.push("/inventario-edicao");
    }

    private onChangeFilterKey(filterKey: string) {
        this.gridFilterKey = filterKey;
        this.load();
    }

    private onChangeSort(sortKey: string, sortOrder: string) {
        this.gridSortKey = sortKey;
        this.gridSortOrder = sortOrder;
        this.load();
    }

    private onChangePage(pageIndex: number) {
        this.pageIndex = pageIndex;
        this.load();
    }

    @Watch("pags")
    private onPags() {
        this.load();
    }

    private mounted() {
        this.loadingModalComponent = this.$refs.loadingModalComponent as LoadingModalComponent;
        this.gridcomponent = this.$refs.gridcomponent as Grid;
        this.load();
    }
}
