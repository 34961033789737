import Vue from "vue";

import ShortcutComponent from "@/components/parent/shortcut/shortcut";
import shortcutComponent from "@/components/parent/shortcut/shortcut.vue";
import { Component } from "@/decorators";
import AtendimentoWhatsAppModel from "@/models/whatsApp/atendimento/atendimentoWhatsAppModel";
import AtendimentoService from "@/services/atendimentos/atendimentoService";
import { editDateWithTime } from "@/utils/common/date";

import HistoricoAtendimentoMensagensComponent from "../historicoAtendimentoMensagens/historicoAtendimentoMensagens";
import historicoAtendimentoMensagensComponent from "../historicoAtendimentoMensagens/historicoAtendimentoMensagens.vue";

import "./historicoAtendimento.scss";

@Component({
    components: {
        historicoAtendimentoMensagensComponent,
        shortcutComponent,
    },
})
export default class HistoricoAtendimentoComponent extends Vue {
    private shortcutComponent: ShortcutComponent = null;
    private historicoAtendimentoMensagensComponent: HistoricoAtendimentoMensagensComponent = null;

    private atendimentoService = new AtendimentoService();

    private atendimentos: AtendimentoWhatsAppModel[] = [];
    private nomeContato = "";

    public async show(contatoId: string) {
        this.shortcutComponent = this.$refs.shortcutComponent as ShortcutComponent;
        this.shortcutComponent.title = this.$t("__.ts.historicoContato") as string;

        this.atendimentos = await this.atendimentoService.getListAtendimentosByContato(contatoId);

        this.nomeContato = this.atendimentos.length > 0 ? this.atendimentos[0].nomeContato : "";

        this.shortcutComponent.show();
    }

    private async onMensagemAtendimento(atendimentoId: string) {
        this.historicoAtendimentoMensagensComponent = this.$refs
            .historicoAtendimentoMensagensComponent as HistoricoAtendimentoMensagensComponent;

        return this.historicoAtendimentoMensagensComponent.show(atendimentoId, this.nomeContato);
    }

    private getDate(date: Date) {
        return editDateWithTime(date);
    }
}
