import ColetaModel, { ColetaListParameters } from "@/models/expedicao/coletaModel";
import { getCustomParams, getPaginationParams } from "@/utils/common/http";
import nfetch from "@/utils/events/nfetch";

import { IServicePrint } from "../base/iServicePrint";

export default class ColetaService extends IServicePrint<ColetaModel> {
    constructor() {
        super("Coleta");
    }

    public async listColeta(
        filterKey: string,
        sortKey: string,
        sortOrder: string,
        pageIndex: number,
        pageSize: number,
        customProperties: ColetaListParameters,
    ): Promise<Response> {
        const custom = getCustomParams(customProperties);
        const pagination = getPaginationParams(filterKey, sortKey, sortOrder, pageIndex, pageSize);

        return nfetch(`/${this.controllerName}/ListColetas?${pagination}` + custom, {
            credentials: "same-origin",
        });
    }

    public marcarComoColetado(id: number): Promise<Response> {
        return nfetch(`/${this.controllerName}/MarcarComoColetado?id=${id}`, {
            method: "post",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json; charset=UTF-8",
            },
        });
    }
}
