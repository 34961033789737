import { mapState } from "vuex";

import boxComponent from "@/components/child/containers/box.vue";
import portletComponent from "@/components/child/containers/portlet.vue";
import checkboxComponent from "@/components/child/form/checkbox.vue";
import comboComponent from "@/components/child/form/combo.vue";
import dateTimePickerComponent from "@/components/child/form/datetimepicker.vue";
import multiSelectComponent from "@/components/child/multiSelect/multiSelect.vue";
import { Component, Watch } from "@/decorators";
import DashboardModel from "@/models/dashboard/dashboardModel";
import Graficos from "@/models/enum/dashboard/graficos";
import FranquiaModel from "@/models/franquiaModel";
import PaginationModel from "@/models/paginationModel";
import UsuarioModel from "@/models/usuarioModel";
import FranquiaService from "@/services/franquiaService";
import { AppState, SessionActions } from "@/store/store";
import { getTipoVendaCombo } from "@/utils/common/combo/combotext";
import Bus from "@/utils/events/bus";

import DashboardComponent from "./dashboardComponent";

// Precisa ser assim se não ocorre erro
// eslint-disable-next-line @typescript-eslint/no-var-requires
const echarts = require("echarts");

import "./dashboardComponent.scss";

@Component({
    components: {
        boxComponent,
        checkboxComponent,
        portletComponent,
        dateTimePickerComponent,
        multiSelectComponent,
        comboComponent,
    },
    computed: {
        ...mapState({
            usuarioLogado: (state: AppState) => state.session.usuarioLogado,
        }),
    },
})
export default class EvolucaoVendas extends DashboardComponent {
    // State computed props
    usuarioLogado: UsuarioModel;

    private totalVendas = 0;
    private totalQtdPedidos = 0;
    private totalClientesCadastrados = 0;

    private graficoVendasDiario: any;
    private graficoSomaVendasMensal: any;
    private graficoQtdeVendasMensal: any;
    private graficoSomaVendasAnualAprovadas: any;
    private graficoQtdeVendasAnualAprovadas: any;
    private graficoSomaVendasAnualRejeitadas: any;
    private graficoQtdeVendasAnualRejeitadas: any;
    private graficoQtdeAtendimentos: any;
    private graficoTicketMedio: any;
    private graficoOrcamentosRejeitados: any;

    franquiaIds: Array<Object> = [];
    defaultFranquia: Array<Object> = [];
    franquiasOptions: Array<Object> = [];
    taxaEntrega = true;
    tipoVenda = 0;

    tipoVendaOptions = getTipoVendaCombo();

    private graphStyle = {
        width: "100%",
        height: "300px",
        margin: "25px 0px 0px 0px",
        // @ts-ignore
        zoom: this.compensarZoomGrafico(document.body.style.zoom),
    };

    private dataInicial = new Date(new Date().getFullYear(), 0, 1);
    private dataFinal = new Date(new Date().getFullYear(), 11, 31);

    private watchesHabilitados = false;

    private async mounted() {
        await this.$store.dispatch(SessionActions.LOAD_USUARIO_LOGADO);
        Bus.$emit("hide-nav-menu", false);

        await this.loadFranquias().withLoading();
        this.defaultFranquia.push(this.franquiasOptions.find(p => p["value"] === this.usuarioLogado.franquiaId));

        //@ts-ignore
        this.graficoVendasDiario = echarts.init(this.$refs.vendasDiario);
        //@ts-ignore
        this.graficoSomaVendasMensal = echarts.init(this.$refs.somaVendasMensal);
        //@ts-ignore
        this.graficoQtdeVendasMensal = echarts.init(this.$refs.qtdeVendasMensal);
        //@ts-ignore
        this.graficoSomaVendasAnualAprovadas = echarts.init(this.$refs.somaVendasAnualAprovadas);
        //@ts-ignore
        this.graficoQtdeVendasAnualAprovadas = echarts.init(this.$refs.qtdeVendasAnualAprovadas);
        //@ts-ignore
        this.graficoSomaVendasAnualRejeitadas = echarts.init(this.$refs.somaVendasAnualRejeitadas);
        //@ts-ignore
        this.graficoQtdeVendasAnualRejeitadas = echarts.init(this.$refs.qtdeVendasAnualRejeitadas);
        //@ts-ignore
        this.graficoQtdeAtendimentos = echarts.init(this.$refs.qtdeAtendimentos);
        //@ts-ignore
        this.graficoTicketMedio = echarts.init(this.$refs.ticketMedio);
        //@ts-ignore
        this.graficoOrcamentosRejeitados = echarts.init(this.$refs.orcamentosRejeitados);

        this.setGraficos([
            this.graficoVendasDiario,
            this.graficoSomaVendasMensal,
            this.graficoQtdeVendasMensal,
            this.graficoSomaVendasAnualAprovadas,
            this.graficoQtdeVendasAnualAprovadas,
            this.graficoSomaVendasAnualRejeitadas,
            this.graficoQtdeVendasAnualRejeitadas,
            this.graficoQtdeAtendimentos,
            this.graficoTicketMedio,
            this.graficoOrcamentosRejeitados,
        ]);

        this.updateDashboard();
    }

    protected async updateDashboard() {
        this.showLoadingGraficos();

        const data = await this.service
            .getEvolucaoVendas(
                this.franquiaIds.map(p => p["value"]),
                this.dataInicial,
                this.dataFinal,
                this.taxaEntrega,
                this.tipoVenda,
            )
            .resolveWithJSON<{
                cardsData: {
                    valorVendasMes?: number;
                    totalPedidosMes?: number;
                    numeroClientesCadastrados?: number;
                };
                informacoes: Map<Graficos, DashboardModel[]>;
            }>();

        this.totalVendas = data.cardsData.valorVendasMes != null ? data.cardsData.valorVendasMes : 0;
        this.totalQtdPedidos = data.cardsData.totalPedidosMes != null ? data.cardsData.totalPedidosMes : 0;
        this.totalClientesCadastrados =
            data.cardsData.numeroClientesCadastrados != null ? data.cardsData.numeroClientesCadastrados : 0;

        this.graficoVendasDiario.setOption(
            this.getOpcoesGrafico({
                titulo: this.$t("__.ts.vendasDiario") as string,
                labels: data.informacoes["VendasDiario"].map(d => `${d.label}h`),
                dados: data.informacoes["VendasDiario"].map(d => d.valor.toFixed(2)),
                tipoGrafico: "line",
                format: "<b>{b}</b><br/>R$ {c}",
                nomeSerie: "Valor",
            }),
        );

        this.graficoSomaVendasMensal.setOption(
            this.getOpcoesGrafico({
                titulo: this.$t("__.ts.somaVendasMensal") as string,
                labels: data.informacoes["SomaVendasMensal"].map(d => `Dia ${d.label}`),
                dados: data.informacoes["SomaVendasMensal"].map(d => d.valor.toFixed(2)),
                tipoGrafico: "line",
                format: "<b>{b}</b><br/>R$ {c}",
                nomeSerie: data.informacoes["SomaVendasMensal"].map(d => d.serie)[0],
            }),
        );

        this.graficoQtdeVendasMensal.setOption(
            this.getOpcoesGrafico({
                titulo: this.$t("__.ts.qtdeVendasMensal") as string,
                labels: data.informacoes["QtdeVendasMensal"].map(d => `Dia ${d.label}`),
                dados: data.informacoes["QtdeVendasMensal"].map(d => d.valor),
                tipoGrafico: "line",
                format: "<b>{b}</b><br/>{c}",
                nomeSerie: data.informacoes["QtdeVendasMensal"].map(d => d.serie)[0],
            }),
        );

        this.graficoSomaVendasAnualAprovadas.setOption(
            this.getOpcoesGrafico({
                titulo: this.$t("__.ts.somaVendasAnualAprovadas") as string,
                labels: data.informacoes["SomaVendasAnualAprovadas"].map(d => this.getMonthName(d.label)),
                dados: data.informacoes["SomaVendasAnualAprovadas"].map(d => d.valor.toFixed(2)),
                tipoGrafico: "line",
                format: "<b>{b}</b><br/>R$ {c}",
                nomeSerie: data.informacoes["SomaVendasAnualAprovadas"].map(d => d.serie)[0],
            }),
        );

        this.graficoQtdeVendasAnualAprovadas.setOption(
            this.getOpcoesGrafico({
                titulo: this.$t("__.ts.qtdeVendasAnualAprovadas") as string,
                labels: data.informacoes["QtdeVendasAnualAprovadas"].map(d => this.getMonthName(d.label)),
                dados: data.informacoes["QtdeVendasAnualAprovadas"].map(d => d.valor),
                tipoGrafico: "line",
                format: "<b>{b}</b><br/>{c}",
                nomeSerie: data.informacoes["QtdeVendasAnualAprovadas"].map(d => d.serie)[0],
            }),
        );

        this.graficoSomaVendasAnualRejeitadas.setOption(
            this.getOpcoesGrafico({
                titulo: this.$t("__.ts.somaVendasAnualRejeitadas") as string,
                labels: data.informacoes["SomaVendasAnualRejeitadas"].map(d => this.getMonthName(d.label)),
                dados: data.informacoes["SomaVendasAnualRejeitadas"].map(d => d.valor.toFixed(2)),
                tipoGrafico: "line",
                format: "<b>{b}</b><br/>R$ {c}",
                cor: "rgb(212,7,7)",
                nomeSerie: data.informacoes["SomaVendasAnualRejeitadas"].map(d => d.serie)[0],
            }),
        );

        this.graficoQtdeVendasAnualRejeitadas.setOption(
            this.getOpcoesGrafico({
                titulo: this.$t("__.ts.qtdeVendasAnualRejeitadas") as string,
                labels: data.informacoes["QtdeVendasAnualRejeitadas"].map(d => this.getMonthName(d.label)),
                dados: data.informacoes["QtdeVendasAnualRejeitadas"].map(d => d.valor),
                tipoGrafico: "line",
                format: "<b>{b}</b><br/>{c}",
                cor: "rgb(212,7,7)",
                nomeSerie: data.informacoes["QtdeVendasAnualRejeitadas"].map(d => d.serie)[0],
            }),
        );

        this.graficoOrcamentosRejeitados.setOption(
            this.getOpcoesGrafico({
                titulo: this.$t("__.ts.qtdeOrcamentosRejeitados") as string,
                labels: data.informacoes["OrcamentosRejeitados"].map(d => this.getMonthName(d.label)),
                dados: data.informacoes["OrcamentosRejeitados"].map(d => d.valor),
                tipoGrafico: "line",
                format: "<b>{b}</b><br/>{c}",
                cor: "rgb(212,7,7)",
                nomeSerie: data.informacoes["OrcamentosRejeitados"].map(d => d.serie)[0],
            }),
        );

        this.graficoQtdeAtendimentos.setOption(
            this.getOpcoesGrafico({
                titulo: this.$t("__.ts.qtdeAtendimentos") as string,
                labels: data.informacoes["QtdeAtendimentos"].map(d => this.getMonthName(d.label)),
                dados: data.informacoes["QtdeAtendimentos"].map(d => d.valor),
                tipoGrafico: "line",
                format: "<b>{b}</b><br/>{c}",
                nomeSerie: data.informacoes["QtdeAtendimentos"].map(d => d.serie)[0],
            }),
        );

        this.graficoTicketMedio.setOption(
            this.getOpcoesGrafico({
                titulo: this.$t("__.ts.ticketMedio") as string,
                labels: data.informacoes["TicketMedio"].map(d => this.getMonthName(d.label)),
                dados: data.informacoes["TicketMedio"].map(d => d.valor.toFixed(2)),
                tipoGrafico: "line",
                format: "<b>{b}</b><br/>R$ {c}",
                nomeSerie: data.informacoes["TicketMedio"].map(d => d.serie)[0],
            }),
        );

        this.hideLoadingGraficos();

        this.watchesHabilitados = true;
    }

    private async loadFranquias() {
        const data = await new FranquiaService().combo().resolveWithJSON<PaginationModel<FranquiaModel>>();
        this.franquiasOptions = data.list.map(franquia => ({
            value: franquia.id,
            text: franquia.nomeFantasia,
        }));
    }

    protected convertToString(numero: number) {
        return numero == null ? "0" : numero.toString();
    }

    private onSelecionarTodas() {
        this.franquiaIds = this.franquiasOptions;
        this.defaultFranquia = this.franquiasOptions;
    }

    private onLimpar() {
        this.franquiaIds = [];
        this.defaultFranquia = [];
    }

    @Watch("franquiaIds")
    private onFranquiaId() {
        if (this.franquiaIds.length > 0) {
            this.updateDashboard();
        }
    }

    @Watch("dataInicial")
    private onDataInicial(newValue, oldValue) {
        if (newValue != oldValue && this.watchesHabilitados) {
            this.updateDashboard();
        }
    }

    @Watch("dataFinal")
    private onDataFinal(newValue, oldValue) {
        if (newValue != oldValue && this.watchesHabilitados) {
            this.updateDashboard();
        }
    }

    @Watch("taxaEntrega")
    @Watch("tipoVenda")
    private onChangeFiltros() {
        this.updateDashboard();
    }
}
