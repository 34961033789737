enum TiposProduto {
    MateriaPrima = 0,
    SemiAcabado = 1,
    Acabado = 2,
    Embalagem = 3,
    Capsula = 4,
    Homeopatia = 5,
    Floral = 6,
}

export default TiposProduto;
