import Vue from "vue";
import { mapGetters, mapState } from "vuex";

import checkboxComponent from "@/components/child/form/checkbox.vue";
import comboComponent from "@/components/child/form/combo.vue";
import dateTimePickerComponent from "@/components/child/form/datetimepicker.vue";
import decimalComSinalCustomComponent from "@/components/child/form/decimalComSinalCustom.vue";
import fieldsetComponent from "@/components/child/form/fieldset.vue";
import moedaComponent from "@/components/child/form/moeda.vue";
import modalComponent from "@/components/child/modal/modal.vue";
import searchComboComponent from "@/components/child/searchCombo/search.vue";
import { Component, Prop, Watch } from "@/decorators";
import PreLoadPackModel from "@/models/auxiliar/preLoadPackModel";
import ConfiguracaoFranquiaModel from "@/models/configuracaoFranquia/configuracaoFranquiaModel";
import Configuracoes from "@/models/enum/configuracao/configuracoes";
import SituacoesVendaItem from "@/models/enum/situacoesVendaItem";
import TipoNota from "@/models/enum/tipoNotaFiscal";
import TiposProduto from "@/models/enum/tiposProduto";
import TipoVenda from "@/models/enum/tipoVenda";
import PacienteModel from "@/models/paciente/pacienteModel";
import PaginationModel from "@/models/paginationModel";
import PosologiaModel from "@/models/posologiaModel";
import PrescritorModel from "@/models/prescritorModel";
import ProdutoModel from "@/models/produto/produtoModel";
import ProdutoLoteModel from "@/models/produtoLoteModel";
import UnidadeMedidaModel from "@/models/unidadeMedidaModel";
import VendaItemModel from "@/models/vendaItemModel";
import EstoqueComprometidoService from "@/services/estoqueComprometidoService";
import PosologiaService from "@/services/posologiaService";
import PrescritorService from "@/services/prescritorService";
import ProdutoService from "@/services/produto/produtoService";
import ProdutoLoteService from "@/services/produtoLoteService";
import UnidadeMedidaService from "@/services/unidadeMedidaService";
import { AppState, Getters } from "@/store/store";
import arithmeticHelper from "@/utils/common/arithmeticHelper";
import {
    getPosologiaCombo,
    getPrescritorCombo,
    getProdutoCombo,
    getProdutoLoteCombo,
} from "@/utils/common/combo/combotext";
import Delay from "@/utils/common/delay";

import PacienteComponent from "../crud/paciente/edit";
import pacienteComponent from "../crud/paciente/edit.vue";
import PosologiaComponent from "../crud/posologia/edit";
import posologiaComponent from "../crud/posologia/edit.vue";
import PrescritorComponent from "../crud/prescritor/edit";
import prescritorComponent from "../crud/prescritor/edit.vue";

@Component({
    components: {
        comboComponent,
        searchComboComponent,
        moedaComponent,
        modalComponent,
        pacienteComponent,
        prescritorComponent,
        decimalComSinalCustomComponent,
        posologiaComponent,
        fieldsetComponent,
        dateTimePickerComponent,
        checkboxComponent,
    },
    computed: {
        ...mapState<AppState>({
            preLoadList: state => state.preLoad.preLoadList,
        }),
        ...mapGetters(["GET_CONFIG_FRANQUIA", "GET_IS_FRACIONAMENTO"] as Getters),
        quantidadeEstoqueLoteString() {
            return `${this.produtoLoteModel.quantidadeAtual.toString().replace(".", ",")} ${this.unidadeMedidaSigla}`;
        },
        quantidadeComprometidaLoteString() {
            return `${this.quantidadeComprometidaLote.toString().replace(".", ",")} ${this.unidadeMedidaSigla}`;
        },
        quantidadeDisponivelString() {
            return `${(this.produtoLoteModel.quantidadeAtual - this.quantidadeComprometidaLote)
                .toString()
                .replace(".", ",")} ${this.unidadeMedidaSigla}`;
        },
        isPosologiaTextoLivre() {
            return this.GET_CONFIG_FRANQUIA(Configuracoes.PosologiasTextoLivre).verdadeiro;
        },
    },
})
export default class ItemComponent extends Vue {
    // State computed props
    preLoadList: PreLoadPackModel;
    isPosologiaTextoLivre: boolean;
    GET_IS_FRACIONAMENTO: () => Promise<boolean>;
    GET_CONFIG_FRANQUIA: (configuracao: Configuracoes) => ConfiguracaoFranquiaModel;

    private produtoLoteService = new ProdutoLoteService();
    private estoqueComprometidoService = new EstoqueComprometidoService();
    private pacienteComponent: PacienteComponent = null;
    private posologiaComponent: PosologiaComponent = null;
    private prescritorComponent: PrescritorComponent = null;
    private preloadPack: PreLoadPackModel = null;

    private editingModel = false;
    private uplodingModel = false;

    private showPrescritor = false;
    private showPaciente = false;
    private continuo = false;
    private dataVencimentoContinuo: Date = null;

    isFracionamento = false;
    isObrigatorioProdutoLote = false;
    quantidadeComprometidaLote = 0;
    unidadeMedidaSigla = "";

    model: VendaItemModel = new VendaItemModel();
    markup = 0;
    private prescritorModel = new PrescritorModel();
    private produtoLoteModel = new ProdutoLoteModel();

    suggestions = [
        { descricao: "desc 1", subDescricao: "subdescricao" },
        { descricao: "desc 2", subDescricao: "subdescricao" },
        { descricao: "desc 3", subDescricao: "subdescricao" },
        { descricao: "desc 4", subDescricao: "subdescricao" },
    ];
    selection = "";

    situacoesVendaItemOptions: Array<Object> = [
        { text: "Aprovado", value: SituacoesVendaItem.Aprovado },
        { text: "Cancelado", value: SituacoesVendaItem.Cancelado },
        { text: "Conclu\u00eddo", value: SituacoesVendaItem.Concluido },
        { text: "Or\u00e7amento", value: SituacoesVendaItem.Orcamento },
        { text: "Rejeitado", value: SituacoesVendaItem.Rejeitado },
    ];

    produtosOptions: Array<Object> = [];
    produtosLoteOptions: Array<Object> = [];
    unidadeMedidaOptions: Array<Object> = [];
    posologiaOptions: Array<Object> = [];

    pacienteDescricao: string = null;

    private customComboTextPaciente = (p: PacienteModel) => ({
        value: p.id,
        text: `${p.nome} - (${p.clienteDescricao})`,
    });

    private customComboTextPrescritor = getPrescritorCombo;

    @Prop(Number) pacienteIdApp: number;
    @Prop(Number) clienteId: number;
    @Prop(Number) estoqueId: number;
    @Prop({ type: Boolean, default: false }) vendaFaturada: boolean;
    @Prop({ type: Number, default: 0 }) tipoVenda: number;

    public clear() {
        this.model = new VendaItemModel();
        this.model.tipoNF = TipoNota.CupomFiscal;
        this.$focusField("produtoId");
        this.pacienteDescricao = null;
        this.editingModel = false;
        this.$forceUpdate();

        if (!this.model.posologiaId && !this.isPosologiaTextoLivre) {
            if (this.preloadPack.posologias && this.preloadPack.posologias.length > 0) {
                this.model.posologiaId =
                    this.preloadPack.posologias.find(posologia => posologia.padrao === true)?.id ?? null;
            }
        }
    }

    private async loadProdutos() {
        try {
            const data = await new ProdutoService()
                .listFilterProdutoTipo(TiposProduto.Acabado, 1, 999999)
                .resolveWithJSON<PaginationModel<ProdutoModel>>();
            this.produtosOptions = data.list.map(getProdutoCombo);
        } catch {}
    }

    private async loadPosologia() {
        try {
            const data = await new PosologiaService().combo().resolveWithJSON<PaginationModel<PosologiaModel>>();
            this.posologiaOptions = data.list.map(getPosologiaCombo);
        } catch {}
    }

    public async onAdicionarItem() {
        const isValid = await this.$validator.validateAll();
        if (isValid) {
            if (this.model.produtoLoteId) {
                try {
                    if (
                        this.produtoLoteModel.quantidadeAtual - this.quantidadeComprometidaLote <
                        Number(this.model.quantidade)
                    ) {
                        const response = await this.$showQuestion(
                            "Sem estoque!",
                            "N\u00e3o h\u00e1 estoque suficiente para atender! Deseja continuar?",
                        );

                        if (response) {
                            this.emitirEventoAdicionar();
                            return true;
                        }
                    } else {
                        this.emitirEventoAdicionar();
                        return true;
                    }
                } catch {
                    return false;
                }
            } else {
                try {
                    const response = await this.$showQuestion(
                        "Lote n\u00e3o informado!",
                        "N\u00e3o foi informado um lote do produto! Deseja continuar?",
                    );

                    if (response) {
                        this.emitirEventoAdicionar();
                        return true;
                    }
                } catch {
                    return false;
                }
            }
        } else {
            this.$focusErrorField();
        }

        return false;
    }

    private async emitirEventoAdicionar() {
        if (this.editingModel) {
            this.$emit("edicao-item", this.getModel());
        } else {
            this.$emit("inclusao-item", this.getModel());
        }

        this.clear();
    }

    @Watch("continuo")
    @Watch("dataVencimentoContinuo")
    private onChangeContinuo() {
        this.model.continuo = this.continuo;
        this.model.dataVencimentoContinuo = this.dataVencimentoContinuo;
    }

    private getModel() {
        const model = new VendaItemModel();
        model.updateFrom(this.model);
        model.produtoDescricao = this.produtosOptions.find(it => it["value"] == model.produtoId)["text"];
        model.produtoDescricao = model.produtoDescricao.replace("((", "(");
        model.produtoDescricao = model.produtoDescricao.replace("))", ")");

        return model;
    }

    private async onProdutoIdChanged(newValue) {
        if (!newValue) return;

        let unidadeMedidaId = 0;
        try {
            const data = await new ProdutoService().get(newValue).withLoading().resolveWithJSON<ProdutoModel>();
            this.markup = data.markup;
            unidadeMedidaId = data.unidadeMedidaEstoqueId;
            this.model.unidadeMedidaId = unidadeMedidaId;
            if (data.posologiaId) {
                this.model.posologiaId = data.posologiaId;
            }
            this.$emit("onChangeMovimentaEstoque", !data.ignoraControleEstoque);

            if (data.valorVenda) {
                this.model.valorUnitario =
                    this.tipoVenda === TipoVenda.Transferencia ? data.valorCusto : data.valorVenda;

                const unidadeMedida = await new UnidadeMedidaService()
                    .get(unidadeMedidaId)
                    .withLoading()
                    .resolveWithJSON<UnidadeMedidaModel>();
                this.unidadeMedidaSigla = unidadeMedida.sigla;
                this.unidadeMedidaOptions = [{ value: unidadeMedida.id, text: unidadeMedida.descricao }];
            }

            const produtoLote = await this.produtoLoteService
                .listByProdutoIdAndEstoqueId(this.model.produtoId, this.estoqueId, 0.00001, 0, 1, 999999)
                .withLoading()
                .resolveWithJSON<PaginationModel<ProdutoLoteModel>>();
            this.produtosLoteOptions = produtoLote.list.map(getProdutoLoteCombo);

            if (!this.model.id) {
                this.model.produtoLoteId = produtoLote.list[0].id;
            }

            this.calcularValorTotal();
        } catch {}
    }

    @Watch("model.valorTotal")
    private onValorChanged() {
        this.$emit("atualiza-total-item", this.model.valorTotal);
    }

    @Watch("model.prescritorId")
    private async onPrescritorId() {
        if (this.model.prescritorId && this.prescritorModel.id != this.model.prescritorId) {
            this.prescritorModel = await new PrescritorService()
                .get(this.model.prescritorId)
                .resolveWithJSON<PrescritorModel>();

            if (this.prescritorModel.observacao) {
                await this.$showWarning(
                    this.$t("__.Components.parent.manipulacao.edit_vue_html.msgPrescritorObs"),
                    this.prescritorModel.observacao,
                );
            }
        }
    }

    @Watch("model.produtoLoteId")
    private async onChangeProdutoLoteId() {
        if (this.model.produtoLoteId) {
            this.produtoLoteModel = await this.produtoLoteService
                .get(this.model.produtoLoteId)
                .resolveWithJSON<ProdutoLoteModel>();

            try {
                this.quantidadeComprometidaLote = await this.estoqueComprometidoService
                    .getQuantidadeComprometidaLote(this.model.produtoLoteId, this.model.id, this.estoqueId)
                    .resolveWithJSON<number>();

                this.produtoLoteModel.quantidadeAtual = this.produtoLoteModel.estoqueProdutoLote.find(
                    p => p.estoqueId == this.estoqueId,
                ).estoqueAtual;
            } catch {}
        }
    }

    private calcularValorTotal() {
        if (this.model.quantidade && this.model.valorUnitario) {
            this.model.valorTotal = this.model.quantidade * this.model.valorUnitario;

            if (this.model.desconto) this.model.valorTotal -= this.model.desconto;

            if (this.model.acrescimo) this.model.valorTotal += this.model.acrescimo;

            if (this.markup > 0) {
                this.model.valorTotal = arithmeticHelper.round(
                    (this.model.valorTotal * this.markup) / 100 + this.model.valorTotal,
                    4,
                );
            }
        }
    }

    public async loadModel(item: VendaItemModel) {
        this.editingModel = true;
        this.uplodingModel = true;

        this.continuo = item.continuo;
        this.dataVencimentoContinuo = item.dataVencimentoContinuo;

        const prod = this.preLoadList.produtos.find(p => p.id == item.produtoId);
        this.markup = prod.markup * 100;

        const produtoLote = await this.produtoLoteService
            .listByProdutoIdAndEstoqueId(item.produtoId, this.estoqueId, 0.00001, 0, 1, 999999)
            .withLoading()
            .resolveWithJSON<PaginationModel<ProdutoLoteModel>>();
        this.produtosLoteOptions = produtoLote.list.map(getProdutoLoteCombo);

        this.model.updateFrom(item);
        this.$focusField("quantidade");
        this.uplodingModel = false;
    }

    private async onAddNewPaciente(closeModal) {
        if (await this.pacienteComponent.save()) {
            closeModal();
        }
    }

    private async onAddNewPosologia(closeModal) {
        if (await this.posologiaComponent.save()) {
            closeModal();
        }
    }

    private async onAddNewPrescritor(closeModal) {
        if (await this.prescritorComponent.save()) {
            closeModal();
        }
    }

    private async openComboNewPaciente() {
        this.showPaciente = true;

        while (!this.$refs.pacienteComponent) await Delay(5);
        this.pacienteComponent = this.$refs.pacienteComponent as PacienteComponent;

        this.pacienteComponent.modelId = 0;
        this.pacienteComponent.load();
    }

    private async openComboNewPosologia() {
        this.posologiaComponent.modelId = 0;
        this.posologiaComponent.load();
    }

    private async openComboNewPrescritor() {
        this.showPrescritor = true;

        while (!this.$refs.prescritorComponent) await Delay(5);
        this.prescritorComponent = this.$refs.prescritorComponent as PrescritorComponent;

        this.prescritorComponent.modelId = 0;
        this.prescritorComponent.load().withLoading();
    }

    private async openComboEditPrescritor(id: number) {
        this.showPrescritor = true;

        while (!this.$refs.prescritorComponent) await Delay(5);
        this.prescritorComponent = this.$refs.prescritorComponent as PrescritorComponent;

        this.prescritorComponent.modelId = id;
        this.prescritorComponent.load().withLoading();
    }

    private async openComboEditPosologia(id: number) {
        this.posologiaComponent.modelId = id;
        this.posologiaComponent.load();
    }

    private async openComboEditPaciente(id: number) {
        this.showPaciente = true;

        while (!this.$refs.pacienteComponent) await Delay(5);
        this.pacienteComponent = this.$refs.pacienteComponent as PacienteComponent;

        this.pacienteComponent.modelId = id;
        this.pacienteComponent.load();
    }

    private async onPacienteSaveOk(modelNovoPaciente: PacienteModel) {
        this.model.pacienteId = modelNovoPaciente.id;
    }

    private async onPosologiaSaveOk(modelNovaPosologia: PosologiaModel) {
        await this.loadPosologia();
        this.model.posologiaId = modelNovaPosologia.id;
    }

    private async onPrescritorSaveOk(modelNovoPrescritor: PrescritorModel) {
        this.model.prescritorId = modelNovoPrescritor.id;
    }

    private tabIndex(valor) {
        if (valor != null && valor > 0) {
            return -1;
        }

        return null;
    }

    public async setPreLoadPack(_preloadPack: PreLoadPackModel) {
        this.preloadPack = _preloadPack;

        if (this.pacienteIdApp != null && this.pacienteIdApp > 0) {
            this.model.pacienteId = this.pacienteIdApp;

            const pacienteComponent = this.$el.querySelector("#pacienteId") as HTMLSelectElement;
            if (pacienteComponent.innerText) {
                this.pacienteDescricao = pacienteComponent.innerText;
            }
        }

        this.posologiaOptions = await this.preloadPack.posologiaCombo();
        this.produtosOptions = this.preloadPack.produtos
            .filter(p => p.tipoProduto == TiposProduto.Acabado)
            .map(getProdutoCombo);
        this.model.quantidade = 1;
    }

    public hasPreLoadPack() {
        return this.preloadPack != null;
    }

    private async mounted() {
        this.posologiaComponent = this.$refs.posologiaComponent as PosologiaComponent;

        this.isFracionamento = await this.GET_IS_FRACIONAMENTO();
        this.isObrigatorioProdutoLote = this.GET_CONFIG_FRANQUIA(Configuracoes.LoteProdutoAcabado).verdadeiro;
    }
}
