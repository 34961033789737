import Vue from "vue";

import actionBarComponent from "@/components/child/actionBar/actionBar.vue";
import checkboxComponent from "@/components/child/form/checkbox.vue";
import comboComponent from "@/components/child/form/combo.vue";
import dateTimePickerComponent from "@/components/child/form/datetimepicker.vue";
import fieldsetComponent from "@/components/child/form/fieldset.vue";
import moedaComponent from "@/components/child/form/moeda.vue";
import gridComponent from "@/components/child/grid/grid.vue";
import { GridAction } from "@/components/child/grid/gridAction";
import { GridColors, GridColumn, GridColumnType } from "@/components/child/grid/gridColumn";
import { Component } from "@/decorators";
import SituacoesVenda from "@/models/enum/situacoesVenda";
import SituacoesVendaItem from "@/models/enum/situacoesVendaItem";
import TiposMovimentacao from "@/models/enum/tiposMovimentacao";
import FracionamentoCancelamentoItemEmbalagemModel from "@/models/fracionamentoCancelamentoItemEmbalagemModel";
import FracionamentoCancelamentoItemModel from "@/models/fracionamentoCancelamentoItemModel";
import FracionamentoCancelamentoModel from "@/models/fracionamentoCancelamentoModel";
import ManipulacaoOrdemCancelamentoItemModel from "@/models/manipulacaoOrdemCancelamentoItemModel";
import ManipulacaoOrdemCancelamentoModel from "@/models/manipulacaoOrdemCancelamentoModel";
import MotivoCancelamentoModel from "@/models/motivoCancelamentoModel";
import MovimentacaoContaModel from "@/models/movimentacaoContaModel";
import PaginationModel from "@/models/paginationModel";
import VendaCancelamentoItemModel from "@/models/vendaCancelamentoItemModel";
import VendaCancelamentoModel from "@/models/vendaCancelamentoModel";
import VendaItemModel from "@/models/vendaItemModel";
import VendaModel from "@/models/vendaModel";
import MotivoCancelamentoService from "@/services/motivoCancelamentoService";
import VendaCancelamentoService from "@/services/vendaCancelamentoService";

import estornoComponent from "./estorno.vue";

import "../crud/crud.scss";

@Component({
    components: {
        comboComponent,
        fieldsetComponent,
        checkboxComponent,
        gridComponent,
        estornoComponent,
        moedaComponent,
        dateTimePickerComponent,
        actionBarComponent,
    },
    computed: {
        cancelamentoTitle() {
            return (
                this.$t("__.Components.parent.venda.cancelamento_vue_html.vendaCancelCodigo") +
                ` <span style="color: mediumblue">${this.vendaModel.codigo}</span>`
            );
        },
    },
})
export default class CancelamentoComponent extends Vue {
    private service = new VendaCancelamentoService();
    extraActions: Array<object> = [];
    gridExtraActionsVer: Array<GridAction> = [];
    private vendaId = 0;
    private vendaModel = new VendaModel();
    private cancelamentoModel = new VendaCancelamentoModel();
    private isCancelado = false;
    private realizarEstorno = true;

    private motivoCancelamentoOptions: Array<Object> = [];

    private itensSelecionado = [];
    private initialCheckedItens = [];
    esconderBotaoNovo = false;

    get gridColumns(): Array<GridColumn> {
        return [
            new GridColumn("descricao", this.$t("__.ts.descricao"), GridColumnType.String),
            new GridColumn("valorTotal", this.$t("__.ts.valorTotal"), GridColumnType.Money),
        ];
    }

    get gridCancelamentoColumns(): Array<GridColumn> {
        return [
            new GridColumn(
                "dataCancelamento",
                this.$t("__.Components.parent.venda.cancelamento_vue_html.dtCancelamento"),
                GridColumnType.DateTime,
            ),
            new GridColumn(
                "motivoCancelamentoDescricao",
                this.$t("__.Components.parent.venda.cancelamento_vue_html.motivoCancelamento"),
                GridColumnType.String,
            ),
            new GridColumn("valor", this.$t("__.ts.vlr"), GridColumnType.Money),
            new GridColumn(
                "usuarioCancelamentoNome",
                this.$t("__.Components.parent.venda.cancelamento_vue_html.userCancelamento"),
                GridColumnType.String,
            ),
        ];
    }

    get gridColumnsCancelar(): Array<GridColumn> {
        return [
            new GridColumn("descricao", this.$t("__.ts.descricao"), GridColumnType.String),
            new GridColumn("quantidadeAtual", this.$t("__.ts.quantidade"), GridColumnType.Decimal),
            new GridColumn(
                "quantidade",
                !this.isCancelado ? this.$t("__.ts.quantidadeADevolver") : this.$t("__.ts.quantidadeDevolvida"),
                GridColumnType.Decimal,
                !this.isCancelado,
            ),
            new GridColumn("unidadeMedidaDescricao", this.$t("__.ts.unidade"), GridColumnType.String),
        ];
    }

    private onExtraAction(name: string, model: VendaCancelamentoModel) {
        if (name.trim() == "ver") {
            this.cancelamentoModel = new VendaCancelamentoModel();
            this.onVerCancelamento(model);
        }
    }

    async onVerCancelamento(model: VendaCancelamentoModel) {
        await this.loadCancelamentoExistente(model.id);
    }

    private conditionShowSubData(data: object) {
        return data["itens"]["length"] > 0;
    }

    private async load() {
        if (this.vendaId > 0) {
            const data = await this.service.getVenda(this.vendaId).withLoading().resolveWithJSON<VendaModel>();
            this.vendaModel.updateFrom(data);

            this.cancelamentoModel = new VendaCancelamentoModel();
            this.cancelamentoModel.vendaId = this.vendaModel.id;

            if (this.vendaModel.vendaCancelamentoId != null) {
                await this.loadCancelamentoExistente(this.vendaModel.vendaCancelamentoId);
            } else {
                await this.getCancelamento(false);
            }

            this.extraActions.splice(0);

            for (let i = 0; i < this.vendaModel.cancelamentos.length; i++) {
                this.gridExtraActionsVer.push(
                    new GridAction("ver", "Ver Cancelamento", "fa fa-eye", GridColors.GRAY, true),
                );
            }

            this.extraActions.push(this.gridExtraActionsVer);

            const temItemACancelar = await this.temItemACancelar();
            this.esconderBotaoNovo = !temItemACancelar || this.vendaModel.cancelamentos.length == 0;
        }
    }

    private async newCancelamento() {
        await this.clear();
        await this.addCancelamento();
    }

    private async clear() {
        this.cancelamentoModel = new VendaCancelamentoModel();
    }

    private async addCancelamento() {
        await this.getCancelamento(true);
    }

    private async loadCancelamentoExistente(vendaCancelamentoId: number) {
        this.isCancelado = true;

        const data = await this.service.get(vendaCancelamentoId).resolveWithJSON<VendaCancelamentoModel>();

        this.cancelamentoModel.updateFrom(data);
        this.cancelamentoModel.itens = [];

        const vendaItemIds = data.itens.map(p => p.vendaItemId);

        // filtra apenas os itens deste cancelamento
        this.vendaModel.itens
            .filter(p => p.situacao == SituacoesVendaItem.Cancelado && vendaItemIds.indexOf(p.id) > -1)
            .map(this.loadCancelamentoItens);

        //Caso seja cancelamento antigo que não tenha alterado a situacao do item
        if (this.cancelamentoModel.itens.length == 0) {
            this.cancelamentoModel.itens = data.itens;
        }

        this.loadGridData();

        this.cancelamentoModel.itens.forEach((p, index) => {
            if (p.fracionamentoCancelamento) {
                const itens = [
                    ...data.itens[index].fracionamentoCancelamento.itens,
                    ...data.itens[index].fracionamentoCancelamento.itensEmbalagem,
                ];
                p.itens.forEach((i, itemIndex) => {
                    i.quantidade = itens[itemIndex].quantidade;
                });
            } else if (p.manipulacaoOrdemCancelamento) {
                p.itens.forEach((i, itemIndex) => {
                    if (
                        data.itens[index] != null &&
                        data.itens[index].manipulacaoOrdemCancelamento.itens.length > itemIndex
                    ) {
                        i.quantidade = data.itens[index].manipulacaoOrdemCancelamento.itens[itemIndex].quantidade;
                    }
                });
            } else {
                p.itens[0].quantidade = data.itens[index].quantidade;
            }
        });
    }

    private async temItemACancelar() {
        return this.vendaModel.itens.filter(s => s.situacao != SituacoesVendaItem.Cancelado).length > 0;
    }

    private async getCancelamento(filtrarCancelados = false) {
        this.isCancelado = false;

        if (filtrarCancelados) {
            const itens = this.vendaModel.itens.filter(s => s.situacao != SituacoesVendaItem.Cancelado);
            itens.forEach(this.loadCancelamentoItens);
        } else {
            // No caso de fracionamento ainda não movimentou estoque então não deve reverter a movimentação
            if (this.vendaModel.situacao != SituacoesVenda.Fracionando) {
                this.vendaModel.itens.forEach(this.loadCancelamentoItens);
            }
        }

        this.loadGridData();

        if (this.vendaModel.contaReceberId != null) {
            this.cancelamentoModel.movimentacaoConta = new MovimentacaoContaModel();
            this.cancelamentoModel.movimentacaoConta.tipoMovimentacao = TiposMovimentacao.Saida;
            this.cancelamentoModel.movimentacaoConta.descricao = "Estorno de venda " + this.vendaModel.codigo;
            this.cancelamentoModel.movimentacaoConta.dataPagamento = new Date();
            this.cancelamentoModel.movimentacaoConta.dataLancamento = new Date();

            if (this.vendaModel.contaReceber.movimentacoes.length > 0) {
                this.cancelamentoModel.movimentacaoConta.valor = this.vendaModel.contaReceber.movimentacoes.reduce(
                    (acc, p) => acc + p.valor,
                    0,
                );
            } else {
                this.cancelamentoModel.movimentacaoConta.valor = 0;
            }
        }
    }

    private loadCancelamentoItens(vendaItem: VendaItemModel) {
        const itemCancelamento = new VendaCancelamentoItemModel();

        if (vendaItem.fracionamentoId) {
            const fracionamento = new FracionamentoCancelamentoModel();
            fracionamento.fracionamentoId = vendaItem.fracionamentoId;
            fracionamento.motivoCancelamentoId = this.cancelamentoModel.motivoCancelamentoId;
            fracionamento.observacaoCancelamento = this.cancelamentoModel.observacaoCancelamento;

            vendaItem.fracionamento.itens.forEach(i => {
                const itemFracionamento = new FracionamentoCancelamentoItemModel();
                itemFracionamento.fracionamentoItemId = i.id;
                itemFracionamento.quantidade = i.quantidade;
                itemFracionamento.unidadeMedidaId = i.unidadeMedidaId;
                itemFracionamento.unidadeMedidaDescricao = i.unidadeMedida.descricao;
                itemFracionamento.descricao = i.produto.descricao;
                fracionamento.itens.push(itemFracionamento);
            });

            vendaItem.fracionamento.itensEmbalagem.forEach(i => {
                const itemEmbalagem = new FracionamentoCancelamentoItemEmbalagemModel();
                itemEmbalagem.itemEmbalagemFracionamentoId = i.id;
                itemEmbalagem.quantidade = i.quantidade;
                itemEmbalagem.unidadeMedidaId = i.produto.unidadeMedidaEstoqueId;
                itemEmbalagem.unidadeMedidaDescricao = "Un";
                itemEmbalagem.descricao = i.produto.descricao;
                fracionamento.itensEmbalagem.push(itemEmbalagem);
            });

            itemCancelamento.fracionamentoCancelamento = fracionamento;
        } else if (vendaItem.produtoId) {
            itemCancelamento.quantidade = vendaItem.quantidade;
            itemCancelamento.unidadeMedidaId = vendaItem.unidadeMedidaId;
            itemCancelamento.unidadeMedidaDescricao = vendaItem.unidadeMedidaDescricao;
        } else {
            const manipulacao = new ManipulacaoOrdemCancelamentoModel();
            manipulacao.manipulacaoOrdemId = vendaItem.manipulacaoOrdemId;
            manipulacao.motivoCancelamentoId = this.cancelamentoModel.motivoCancelamentoId;
            manipulacao.observacaoCancelamento = this.cancelamentoModel.observacaoCancelamento;

            vendaItem.manipulacaoOrdem.itens.forEach(p => {
                const item = new ManipulacaoOrdemCancelamentoItemModel();
                item.manipulacaoOrdemItemId = p.id;
                item.quantidade = p.quantidade;
                item.unidadeMedidaId = p.unidadeMedidaManipulacaoId;
                item.unidadeMedidaDescricao = p.unidadeMedidaManipulacaoDecricao;
                item.descricao = p.produtoDescricao;
                manipulacao.itens.push(item);
            });

            itemCancelamento.manipulacaoOrdemCancelamento = manipulacao;
        }

        itemCancelamento.valorTotal = vendaItem.valorTotal;
        itemCancelamento.vendaItemId = vendaItem.id;
        itemCancelamento.descricao = vendaItem.produtoDescricao;
        this.cancelamentoModel.itens.push(itemCancelamento);
    }

    private loadGridData() {
        this.cancelamentoModel.itens.forEach((p, index) => {
            p.id = p.id ? p.id : index;

            if (p.fracionamentoCancelamento) {
                p.itens = p.fracionamentoCancelamento.itens;
                if (p.fracionamentoCancelamento.itensEmbalagem.length > 0) {
                    p.itens = [...p.itens, ...p.fracionamentoCancelamento.itensEmbalagem];
                }
            } else if (p.manipulacaoOrdemCancelamento) {
                p.itens = p.manipulacaoOrdemCancelamento.itens;
            } else {
                p.vendaItem = new VendaItemModel();
                p.vendaItem.quantidade = p.quantidade;
                p.vendaItem.unidadeMedidaDescricao = p.unidadeMedidaDescricao;
                p.vendaItem.produtoDescricao = p.descricao;
                p.vendaItem.tipoNF = 0;
                p.itens.push({
                    quantidade: p.vendaItem.quantidade,
                    unidadeMedidaDescricao: p.vendaItem.unidadeMedidaDescricao,
                    descricao: p.vendaItem.produtoDescricao,
                });
            }

            p.itens.forEach(i => (i.quantidadeAtual = i.quantidade));
        });

        this.initialCheckedItens = this.cancelamentoModel.itens;
    }

    private onSelectedChanged(values: VendaItemModel[]) {
        this.itensSelecionado = values;
    }

    private async loadMotivoCancelamento() {
        try {
            const data = await new MotivoCancelamentoService()
                .combo()
                .resolveWithJSON<PaginationModel<MotivoCancelamentoModel>>();

            this.motivoCancelamentoOptions = data.list.map(item => ({ value: item.id, text: item.descricao }));
        } catch {}
    }

    private async save() {
        this.cancelamentoModel.itens.forEach(p => {
            if (p.manipulacaoOrdemCancelamento != null) {
                p.manipulacaoOrdemCancelamento.observacaoCancelamento = this.cancelamentoModel.observacaoCancelamento;
                p.manipulacaoOrdemCancelamento.motivoCancelamentoId = this.cancelamentoModel.motivoCancelamentoId;
            } else if (p.fracionamentoCancelamento != null) {
                p.fracionamentoCancelamento.observacaoCancelamento = this.cancelamentoModel.observacaoCancelamento;
                p.fracionamentoCancelamento.motivoCancelamentoId = this.cancelamentoModel.motivoCancelamentoId;
            }

            if (!p.fracionamentoCancelamento && !p.manipulacaoOrdemCancelamento) {
                p.vendaItem.quantidade = p.itens[0].quantidade;
                p.vendaItem.unidadeMedidaDescricao = p.itens[0].unidadeMedidaDescricao;
                p.vendaItem.produtoDescricao = p.itens[0].descricao;
            }
        });

        if (!this.realizarEstorno) {
            this.cancelamentoModel.movimentacaoConta = null;
        }

        try {
            const isValid = await this.$validator.validateAll();

            if (isValid) {
                if (this.itensSelecionado.length == 0 && this.cancelamentoModel.itens.length > 0) {
                    this.$showWarning("Atenção", "Selecione ao menos um item");
                    return;
                }

                this.cancelamentoModel.itens = this.cancelamentoModel.itens.filter(
                    p => this.itensSelecionado.filter(i => i.id == p.id).length > 0,
                );

                this.cancelamentoModel.itens.forEach(p => (p.id = null));

                this.cancelamentoModel.vendaId = this.vendaId;

                const sucesso = await this.service.insert(this.cancelamentoModel).withLoading().resolveWithoutJSON();

                if (sucesso) {
                    await this.$showInclusaoUpdate(null);
                    this.$router.back();
                }
            } else {
                this.$focusErrorField();
            }
        } catch {}
    }

    private cancel() {
        this.$router.back();
    }

    private mounted() {
        Promise.all([this.loadMotivoCancelamento()])
            .withLoading()
            .then(() => {
                if (this.$route.params.id) {
                    this.vendaId = +this.$route.params.id;
                    this.load();
                }
            })
            .catch(() => {});
    }
}
