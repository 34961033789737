import dateTimePickerComponent from "@/components/child/form/datetimepicker.vue";
import decimalComponent from "@/components/child/form/decimal.vue";
import decimalComSinalCustomComponent from "@/components/child/form/decimalComSinalCustom.vue";
import decimalComSufixoNullableComponent from "@/components/child/form/decimalComSufixoNullable";
import fieldsetComponent from "@/components/child/form/fieldset.vue";
import moedaComponent from "@/components/child/form/moeda.vue";
import { GridColumn, GridColumnType } from "@/components/child/grid/gridColumn";
import { Component } from "@/decorators";
import PaginationModel from "@/models/paginationModel";
import ProdutoModel from "@/models/produto/produtoModel";
import ProdutoLogFinanceiroModel from "@/models/produtoLogFinanceiroModel";
import ProdutoLogFinanceiroService from "@/services/produto/produtoLogFinanceiroService";

import ListComponentBase from "../listComponentBase";

import "../crud.scss";

@Component({
    components: {
        dateTimePickerComponent,
        fieldsetComponent,
        moedaComponent,
        decimalComponent,
        decimalComSinalCustomComponent,
        decimalComSufixoNullableComponent,
    },
})
export default class ProdutoLogFinanceiroListComponent extends ListComponentBase<ProdutoLogFinanceiroModel> {
    private produtoLogFinanceiroService = new ProdutoLogFinanceiroService();
    private model = new ProdutoLogFinanceiroModel();

    dataUltimaAlteracao = null;
    usuarioUltimaAlteracao = "";

    get gridColumns(): Array<GridColumn> {
        return [
            new GridColumn("dataCriacao", "Data da atualização", GridColumnType.DateTime),
            new GridColumn("rotinaAlteracao", "Rotina", GridColumnType.String),
            new GridColumn("usuarioCriacaoDescricao", "Usuário", GridColumnType.String),
            new GridColumn("valorCusto", "Valor Custo", GridColumnType.Money, false, false, "", "", false, false, 4),
            new GridColumn(
                "custoReferencia",
                "Custo Referência",
                GridColumnType.Money,
                false,
                false,
                "",
                "",
                false,
                false,
                4,
            ),
            new GridColumn("custoMedio", "Custo Médio", GridColumnType.Money, false, false, "", "", false, false, 4),
            new GridColumn("markup", "Markup", GridColumnType.Percent),
            new GridColumn("valorVenda", "Valor Venda", GridColumnType.Money, false, false, "", "", false, false, 4),
        ];
    }

    private beforeMount() {
        this.setProps(new ProdutoLogFinanceiroService(), "", "datacriacao");

        this.pageSize = 5;
        this.gridSortOrder = "desc";
    }

    public async load() {}

    public async loadByProduto(model: ProdutoLogFinanceiroModel, produto: ProdutoModel) {
        this.model.updateFrom(model);

        const data = await this.produtoLogFinanceiroService
            .listByProduto(
                this.gridFilterKey,
                this.gridSortKey,
                this.gridSortOrder,
                this.pags ? 1 : this.pageIndex,
                this.pags ? 999999 : this.pageSize,
                model.produtoId,
            )
            .withLoading()
            .resolveWithJSON<PaginationModel<ProdutoLogFinanceiroModel>>();

        if (data.total == 0) {
            await this.$showError(this.$t("__.ts.logAlt"), this.$t("__.ts.naoHouveAltRegis"));
        } else {
            this.dataUltimaAlteracao = new Date(data.list[0].dataCriacao);
            this.usuarioUltimaAlteracao = data.list[0].usuarioCriacaoDescricao;

            this.gridData = data.list.map((p, index) => ({
                ...p,
                dataCriacao: index + 1 == data.list.length ? produto.dataCriacao : data.list[index + 1].dataCriacao,
                usuarioCriacaoDescricao:
                    index + 1 == data.list.length
                        ? produto.usuarioCriacaoDescricao
                        : data.list[index + 1].usuarioCriacaoDescricao,
            }));
        }
    }

    private close() {
        this.$emit("close");
    }
}
