import { Validator } from "vee-validate";

import ValidationErrorModel, { ValidationErrors } from "@/models/validationErrorModel";

import { showErrors, showErrorsAsync } from "./validationError";

export default class ValidationErrorWrapper {
    private validator: Validator;

    public constructor(validator: Validator) {
        this.validator = validator;
    }

    public clearErrors() {
        setTimeout(() => {
            this.validator.errors.clear();
        }, 50);
    }

    public async hasValidErrors(errorList: Array<ValidationErrorModel>) {
        const fieldErrors = errorList.filter(
            p =>
                p.errorType == ValidationErrors.Error ||
                p.errorType == ValidationErrors.Warning ||
                p.errorType == ValidationErrors.WarningBreaking,
        );
        //retorna se possui erros ou warnings para poder tratar melhor as mensagens de erros Unexpected
        return fieldErrors.length > 0;
    }

    public showErrors(errorList: Array<ValidationErrorModel>) {
        return showErrors(errorList, this.validator);
    }

    public async showErrorsAsync(errorList: Array<ValidationErrorModel>) {
        return showErrorsAsync(errorList, this.validator);
    }
}
