var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "crud crud-list ajusteAcoesPesquisar" },
    [
      _c("div", { staticClass: "info-div" }, [
        _c("h1", { staticClass: "title" }, [
          _vm._v(
            _vm._s(_vm.$t("__.Crud.formulapadrao.list_vue_html.frmlsPadr"))
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "content" }, [
          _c("div", { staticClass: "info-panel" }, [
            _c("span", [_vm._v(_vm._s(_vm.total))]),
            _vm._v(" "),
            _c("span", [
              _vm._v(
                _vm._s(_vm.$t("__.Crud.formulapadrao.list_vue_html.frmls"))
              ),
            ]),
            _vm._v(" "),
            _c("span", [
              _vm._v(
                _vm._s(
                  _vm.$t("__.Crud.formulapadrao.list_vue_html.cadastradas")
                )
              ),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _vm.loadedPagination
        ? _c(
            "grid-component",
            {
              attrs: {
                data: _vm.gridData,
                tituloPagina: "Fórmulas",
                columns: _vm.gridColumns,
                "show-select-checkbox": true,
                "page-index": _vm.pageIndex,
                "page-size": _vm.pageSize,
                total: _vm.total,
                startFilterKey: _vm.startFilterKey,
                controlaPageSize: true,
                extraActions: _vm.extraActions,
                actionName: "formulas_padrao",
              },
              on: {
                "change-filter-key": _vm.onChangeFilterKey,
                "change-sort": _vm.onChangeSort,
                "edit-item": _vm.onEditItem,
                "remove-item": _vm.onRemoveItem,
                "create-item": _vm.onCreateItem,
                "change-page-size": _vm.onChangePageSize,
                "change-page": _vm.onChangePage,
                "selected-values-changed": _vm.onSelectedValuesChanged,
                "extra-action": _vm.onExtraAction,
              },
            },
            [
              _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                _c("div", { staticClass: "filtro-lista" }, [
                  _c(
                    "button",
                    {
                      staticClass: "filtro-avancado-button",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          _vm.isHidden = !_vm.isHidden
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.$t("__.Crud.filtroAvancado")) +
                          "\n                    "
                      ),
                      _c("i", { staticClass: "fa fa-caret-down" }),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.isHidden,
                          expression: "isHidden",
                        },
                      ],
                      staticClass: "drop-lista",
                    },
                    [
                      _c(
                        "form",
                        {
                          attrs: { novalidate: "novalidate" },
                          on: {
                            submit: function ($event) {
                              $event.preventDefault()
                              return _vm.getFormValues($event)
                            },
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "radio-group",
                              staticStyle: { "margin-bottom": "15px" },
                            },
                            [
                              _c(
                                "div",
                                { staticStyle: { display: "contents" } },
                                [
                                  _c("label", [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.radioFilter,
                                          expression: "radioFilter",
                                        },
                                      ],
                                      attrs: {
                                        name: "optradio",
                                        type: "radio",
                                        value: "1",
                                      },
                                      domProps: {
                                        checked: _vm._q(_vm.radioFilter, "1"),
                                      },
                                      on: {
                                        change: function ($event) {
                                          _vm.radioFilter = "1"
                                        },
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "__.Crud.formulapadrao.list_vue_html.ativos"
                                          )
                                        )
                                      ),
                                    ]),
                                  ]),
                                  _vm._v(" "),
                                  _c("label", [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.radioFilter,
                                          expression: "radioFilter",
                                        },
                                      ],
                                      attrs: {
                                        name: "optradio",
                                        type: "radio",
                                        value: "0",
                                      },
                                      domProps: {
                                        checked: _vm._q(_vm.radioFilter, "0"),
                                      },
                                      on: {
                                        change: function ($event) {
                                          _vm.radioFilter = "0"
                                        },
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "__.Crud.formulapadrao.list_vue_html.desativados"
                                          )
                                        )
                                      ),
                                    ]),
                                  ]),
                                  _vm._v(" "),
                                  _c("label", [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.radioFilter,
                                          expression: "radioFilter",
                                        },
                                      ],
                                      attrs: {
                                        name: "optradio",
                                        type: "radio",
                                        value: "2",
                                      },
                                      domProps: {
                                        checked: _vm._q(_vm.radioFilter, "2"),
                                      },
                                      on: {
                                        change: function ($event) {
                                          _vm.radioFilter = "2"
                                        },
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "__.Crud.formulapadrao.list_vue_html.ambos"
                                          )
                                        )
                                      ),
                                    ]),
                                  ]),
                                ]
                              ),
                              _vm._v(" "),
                              _c("br"),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticStyle: { "text-align": "center" },
                              attrs: {
                                title:
                                  "Pesquisa pelo nome do produto entre os itens presentes na formula",
                              },
                            },
                            [
                              _c("input", {
                                staticClass: "col-sm-2",
                                attrs: { id: "BuscarAtivo", type: "checkbox" },
                                on: {
                                  click: function ($event) {
                                    _vm.onBuscarAtivoChange()
                                  },
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "label",
                                {
                                  staticClass: "col-sm-10",
                                  attrs: { for: "BuscarAtivo" },
                                },
                                [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(
                                        _vm.$t(
                                          "__.Crud.formulapadrao.list_vue_html.buscPorInsumo"
                                        )
                                      ) +
                                      "\n                            "
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "control-label",
                                  attrs: { for: "tipoFormulaPadrao" },
                                },
                                [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(
                                        _vm.$t(
                                          "__.Crud.formulapadrao.list_vue_html.tipoFrmlPadr"
                                        )
                                      ) +
                                      "\n                            "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("combo-component", {
                                attrs: {
                                  id: "tipoFormulaPadrao",
                                  name: "tipoFormulaPadrao",
                                  placeholder: "Todos",
                                  data: _vm.tiposFormulaPadraoOptions,
                                  searchable: true,
                                },
                                model: {
                                  value: _vm.tipoFormulaPadrao,
                                  callback: function ($$v) {
                                    _vm.tipoFormulaPadrao = $$v
                                  },
                                  expression: "tipoFormulaPadrao",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "form-group" }, [
                            _c(
                              "button",
                              {
                                staticClass: "botao-filtrar",
                                attrs: { type: "submit" },
                              },
                              [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(_vm.$t("__.Crud.filtrar")) +
                                    "\n                            "
                                ),
                              ]
                            ),
                          ]),
                        ]
                      ),
                    ]
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "div-pagar-selecionados",
                  attrs: { slot: "footer-extra-options" },
                  slot: "footer-extra-options",
                },
                [
                  _vm.VALIDAR_PERMISSAO_USUARIO("formulas_padrao", "insert")
                    ? _c(
                        "button",
                        {
                          staticClass: "btn btn-primary",
                          on: { click: _vm.gerarManipulacaoOrdem },
                        },
                        [
                          _vm._v(
                            "\n                " +
                              _vm._s(
                                _vm.$t(
                                  "__.Crud.formulapadrao.list_vue_html.produzir"
                                )
                              ) +
                              "\n            "
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isAdmin
                    ? _c(
                        "button",
                        {
                          staticClass: "btn btn-primary",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              _vm.replicarTodos()
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n                " +
                              _vm._s(
                                _vm.$t(
                                  "__.Crud.formulapadrao.list_vue_html.replAtualizTds"
                                )
                              ) +
                              "\n            "
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          _vm.redefinirValorProduto()
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(
                            _vm.$t(
                              "__.Crud.formulapadrao.list_vue_html.calcVlres"
                            )
                          ) +
                          "\n            "
                      ),
                    ]
                  ),
                ]
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "shortcutComponent",
        {
          ref: "shortcutComponent",
          attrs: {
            showAddNew: false,
            btOkName: "Editar",
            title: _vm.$t("__.Crud.formulapadrao.list_vue_html.visuFrmPadr"),
          },
          on: { "selection-item": _vm.onBtnEditItem },
        },
        [
          _c(
            "div",
            { attrs: { slot: "component" }, slot: "component" },
            [
              _vm.showVer
                ? _c("ver-component", {
                    ref: "verComponent",
                    attrs: { data: _vm.modelVer },
                  })
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _vm.isAdmin
        ? [
            _c("replicar-selecao-franquia-component", {
              ref: "replicarSelecaoFranquiaComponent",
              on: { replicar: _vm.onConfirmReplicar },
            }),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }