import Vue from "vue";

import gridComponent from "@/components/child/grid/grid.vue";
import { GridColumn, GridColumnType } from "@/components/child/grid/gridColumn";
import { Component } from "@/decorators";
import PaginationModel from "@/models/paginationModel";
import ProdutoLoteModel from "@/models/produtoLoteModel";
import ProdutoLoteService from "@/services/produtoLoteService";

@Component({
    components: {
        gridComponent,
    },
})
export default class GridLoteComponent extends Vue {
    private gridData: Array<ProdutoLoteModel> = [];
    private get gridColumn(): Array<GridColumn> {
        return [
            new GridColumn("descricao", "Descri\u00e7\u00e3o", GridColumnType.String),
            new GridColumn("fornecedorNome", "Fornecedor", GridColumnType.String),
            new GridColumn("fatorCorrecao", "F.C", GridColumnType.Decimal),
            new GridColumn("valorCusto", "Custo Unitário", GridColumnType.Money4Digits),
            new GridColumn("dataValidade", "Validade", GridColumnType.Date),
            new GridColumn("dataFabricacao", "Fabrica\u00e7\u00e3o", GridColumnType.Date),
            new GridColumn("quantidadeAtual", "Quantidade", GridColumnType.Decimal),
        ];
    }

    public async load(produtoId: number) {
        const data = await new ProdutoLoteService()
            .getByProdutoId(produtoId)
            .resolveWithJSON<PaginationModel<ProdutoLoteModel>>();

        this.gridData = data.list;
    }

    private onSelectLote(value: ProdutoLoteModel) {
        this.$emit("onSelectLote", value);
    }
}
