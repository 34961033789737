import Vue from "vue";

import dropdownComponent from "@/components/child/dropdown/dropdown.vue";
import { Component, Prop } from "@/decorators";

@Component({
    components: {
        dropdownComponent,
    },
})
export default class MensagemOptionsComponent extends Vue {
    @Prop({ type: Boolean, default: false }) isMensagemPropria: boolean;

    private onResponder() {
        this.$emit("onResponder");
    }
}
