import BaseModel from "./base/baseModel";
import OrigemAtendimento from "./enum/origemAtendimento";
import Setor from "./enum/setor";
import SituacoesVenda from "./enum/situacoesVenda";
import TipoVenda from "./enum/tipoVenda";
import ContaReceber from "./financeiro/contaReceberModel";
import NotaFiscalSaidaModel from "./notaFiscalSaidaModel";
import ReceitaArquivoModel from "./receitaArquivoModel";
import LocalEntregaModel from "./transporte/localEntregaModel";
import VendaCancelamentoModel from "./vendaCancelamentoModel";
import VendaExtraModel from "./vendaExtraModel";
import VendaItem from "./vendaItemModel";
import VendaPreFaturamentoModel from "./vendaPreFaturamentoModel";

export default class VendaModel extends BaseModel {
    id?: number = null;
    situacao: SituacoesVenda = null;
    situacaoDescricao: string = null;
    origem: OrigemAtendimento = null;
    tipoVenda = TipoVenda.Normal;
    codigo = 0;
    dataInclusao: Date = null;
    dataAprovacao: Date = null;
    dataConclusao: Date = null;
    dataEntrega: Date = null;
    dataValidadeOrcamento: Date = null;
    dataCancelamento: Date = null;
    clienteId = 0;
    clienteNome: string = null;
    clienteTelefone: string = null;
    clienteCpfCnpj: string = null;
    campanhaId?: number = null;
    recompensaId?: number = null;
    usuarioId = 0;
    nomeVendedor: string = null;
    nomeSeparador: string = null;
    observacao: string = null;
    vendaCancelamentoId?: number = null;
    movimentacaoEstoqueId?: number = null;
    contaReceberId?: number = null;
    contaReceber: ContaReceber = null;
    itens: Array<VendaItem> = [];
    valorProdutos = 0;
    localEntrega: LocalEntregaModel = null;
    localEntregaId?: number = null;
    taxaEntrega = 0;
    descontoPercentual = 0;
    cashback = 0;
    usaCashback = false;
    desconto = 0;
    acrescimoPercentual = 0;
    acrescimo = 0;
    valorFinal = 0;
    notasFiscaisSaida: Array<NotaFiscalSaidaModel> = [];
    numeroNota: number = null;
    naturezaOperacaoId?: number = null;
    descontoFuncionario?: number = null;
    descontoPrescritor?: number = null;
    usuarioSeparacaoId?: number = null;
    dataSeparacao?: Date = null;
    numeroRps: number = null;
    listaItensDescricao: string = null;
    estoqueId: number = null;
    receitas: ReceitaArquivoModel[] = [];
    setor: Setor = null;
    setorDescricao: string = null;
    vendaPreFaturamento: VendaPreFaturamentoModel[] = [];
    motivoRejeicaoId?: number = null;
    cupomId?: number = null;

    // Transporte
    enderecoEntregaId?: number = null;
    enderecoEntrega?: string = null;
    transportadorId?: number = null;
    transportadoraId?: number = null;
    pesoBrutoTransportado?: number = null;
    pesoLiquidoTransportado?: number = null;
    volumeId?: number = null;
    volumes?: number = null;
    vendaPaiId?: number = null;
    franquiaAbrangenciaId?: number = null;
    cancelamentos: Array<VendaCancelamentoModel> = [];
    identificarClienteNF = false;
    descontoAprovadoSupervisor = 0;
    codigoRastreio: string = null;
    vendaExtra: VendaExtraModel = null;

    //Local de Entrega
    localEntregaDescricao: string = null;
    statusTentativa: string = null;
    quantidadeTentativas?: number = null;
}

export interface VendaListParameters {
    situacao?: number;
    onlyVendas?: boolean;
    vendasAFaturar?: boolean;
    clienteId?: number;
    data1?: string;
    data2?: string;
    radio?: string;
    categoria?: number;
    setor?: Setor;
    produto?: number;

    dataInicial?: string;
    dataFinal?: string;
    tipoBuscaVenda?: number;
    vendedor?: number;
    apenasEntrega?: boolean;
    localEntrega?: number;
    statusEntrega?: number;
    dataEntregaInicial?: string;
    dataEntregaFinal?: string;
    vendasComNotas?: boolean;
}
