import Vue from "vue";

import Grid from "@/components/child/grid/grid";
import gridComponent from "@/components/child/grid/grid.vue";
import { GridAction } from "@/components/child/grid/gridAction";
import { GridColors, GridColumn, GridColumnType } from "@/components/child/grid/gridColumn";
import { Component, Watch } from "@/decorators";
import DevolucaoModel from "@/models/devolucaoModel";
import PaginationModel from "@/models/paginationModel";
import DevolucaoService from "@/services/devolucaoService";

import "../crud.scss";

@Component({
    components: {
        gridComponent,
    },
})
export default class DevolucaoCompraListComponent extends Vue {
    private service = new DevolucaoService();
    private gridcomponent: Grid = null;

    gridFilterKey = "";
    gridSortKey = "dataDevolucao";
    gridSortOrder = "asc";
    gridData: Array<DevolucaoModel> = [];

    get gridColumns(): Array<GridColumn> {
        return [
            new GridColumn("dataDevolucao", "Data", GridColumnType.Date),
            new GridColumn("destinatario", "Destinatário", GridColumnType.String),
            new GridColumn("notaEntrada", "Nota entrada", GridColumnType.Integer),
            new GridColumn("notaDevolucao", "Nota devolução", GridColumnType.Integer),
        ];
    }

    get gridItemColumns() {
        return [
            new GridColumn("descricao", "Produto", GridColumnType.String),
            new GridColumn("numeroLote", "Lote", GridColumnType.String),
            new GridColumn("quantidade", "Quantidade devolvida", GridColumnType.Integer),
            new GridColumn("valorUnitario", "Valor unitário", GridColumnType.Money),
            new GridColumn("valorTotal", "Valor total", GridColumnType.Money),
        ];
    }

    subDataName = "notaItens";

    conditionShowSubData(it) {
        if (it["notaItens"] != null) return it["notaItens"]["length"] > 0;
        else return false;
    }

    pageIndex = 1;
    pageSize = 20;
    total = 0;

    pags = false;
    tipoDocGerar: number = null;

    extraActions: Array<object> = [];
    extraActionsConsultar: Array<GridAction> = [];

    private async load() {
        try {
            const data = await this.service
                .list(
                    this.gridFilterKey,
                    this.gridSortKey,
                    this.gridSortOrder,
                    this.pags ? 1 : this.pageIndex,
                    this.pags ? 999999 : this.pageSize,
                )
                .withLoading()
                .resolveWithJSON<PaginationModel<DevolucaoModel>>();

            this.gridData = data.list;
            this.total = data.total;

            this.extraActions.splice(0);
            this.extraActionsConsultar.splice(0);

            for (let i = 0; i < this.gridData.length; i++) {
                this.extraActionsConsultar.push(
                    new GridAction("consultar", "Consultar Devolução", "fa fa-search", GridColors.BLUE),
                );
            }

            this.extraActions.push(this.extraActionsConsultar);

            if (this.pags) {
                if (this.tipoDocGerar == 0) {
                    setTimeout(() => {
                        this.gridcomponent.gerarXls();
                    }, 2000);
                }

                if (this.tipoDocGerar == 1) {
                    setTimeout(() => {
                        this.gridcomponent.gerarPdf();
                    }, 2000);
                }

                if (this.tipoDocGerar == 2) {
                    setTimeout(() => {
                        this.gridcomponent.gerarImpressao();
                    }, 2000);
                }

                setTimeout(() => {
                    this.pags = false;
                }, 2000);
            }
        } catch {}
    }

    private async onExtraAction(name: string, model: DevolucaoModel) {
        if (name.trim() == "consultar") {
            this.$router.push("/devolucaocompra-consulta/" + model.id);
        }
    }

    private onChangeFilterKey(filterKey: string) {
        this.gridFilterKey = filterKey;
        this.load();
    }

    private onChangeSort(sortKey: string, sortOrder: string) {
        this.gridSortKey = sortKey;
        this.gridSortOrder = sortOrder;
        this.load();
    }

    private onChangePage(pageIndex: number) {
        this.pageIndex = pageIndex;
        this.load();
    }

    @Watch("pags")
    private onPags() {
        this.load();
    }

    private mounted() {
        this.gridcomponent = this.$refs.gridcomponent as Grid;
        this.load();
    }
}
