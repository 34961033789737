var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "crud crud-list crud-model-sessao" },
    [
      _c(
        "actionBarComponent",
        {
          attrs: {
            slots: 2,
            title:
              _vm.$t(
                "__.Components.parent.printModel.sessao_vue_html.modelImpressEdit"
              ) +
              " - " +
              _vm.telaName,
          },
          on: { onSave: _vm.onSalvar },
        },
        [
          _vm.model.id
            ? _c(
                "div",
                {
                  attrs: {
                    slot: "buttonSlot0",
                    title: _vm.$t("__.Crud.copiar"),
                  },
                  on: { click: _vm.onCopiar },
                  slot: "buttonSlot0",
                },
                [
                  _c("i", {
                    staticClass: "fa fa-copy",
                    attrs: { "aria-hidden": "true" },
                  }),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.permitirReplicarModelo
            ? _c(
                "div",
                {
                  attrs: {
                    slot: "buttonSlot1",
                    title: _vm.$t("__.Crud.replicar"),
                  },
                  on: { click: _vm.onReplicar },
                  slot: "buttonSlot1",
                },
                [
                  _c("i", {
                    staticClass: "fa fa-sync",
                    attrs: { "aria-hidden": "true" },
                  }),
                ]
              )
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _c(
        "form",
        { attrs: { novalidate: "novalidate", id: "form" } },
        [
          _c("div", { attrs: { slot: "rows" }, slot: "rows" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-sm-6" }, [
                _c("div", { staticClass: "form-group" }, [
                  _c(
                    "label",
                    {
                      staticClass: "control-label",
                      attrs: {
                        for: "descricao",
                        title: _vm.$t("__.obrigatorio"),
                      },
                    },
                    [
                      _c("span", { staticStyle: { color: "red" } }, [
                        _vm._v("*"),
                      ]),
                      _vm._v(
                        "\n                            " +
                          _vm._s(
                            _vm.$t(
                              "__.Components.parent.printModel.sessao_vue_html.descricao"
                            )
                          ) +
                          "\n                        "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.model.descricao,
                        expression: "model.descricao",
                      },
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: { type: "text", id: "descricao", name: "descricao" },
                    domProps: { value: _vm.model.descricao },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.model, "descricao", $event.target.value)
                      },
                    },
                  }),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-sm-5" }, [
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c(
                      "label",
                      {
                        staticClass: "control-label",
                        attrs: { for: "nomeImpressora" },
                      },
                      [_vm._v(_vm._s(_vm.$t("__.ts.impressora")))]
                    ),
                    _vm._v(" "),
                    _c("combo-component", {
                      attrs: {
                        id: "nomeImpressora",
                        name: "nomeImpressora",
                        data: _vm.impressoraOptions,
                        refresh: _vm.loadImpressoraOptions,
                        searchable: false,
                      },
                      model: {
                        value: _vm.impressoraSelecionada,
                        callback: function ($$v) {
                          _vm.impressoraSelecionada = $$v
                        },
                        expression: "impressoraSelecionada",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-sm-1" }, [
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c("label", { attrs: { for: "checkPadrao" } }, [
                      _vm._v(
                        "\n                            " +
                          _vm._s(
                            _vm.$t(
                              "__.Components.parent.printModel.sessao_vue_html.padrao"
                            )
                          ) +
                          "\n                        "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("checkbox-component", {
                      attrs: { id: "checkPadrao", name: "checkPadrao" },
                      model: {
                        value: _vm.model.padrao,
                        callback: function ($$v) {
                          _vm.$set(_vm.model, "padrao", $$v)
                        },
                        expression: "model.padrao",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-sm-3" }, [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip",
                        value: _vm.errors.first("fonte"),
                        expression: "errors.first('fonte')",
                      },
                    ],
                    staticClass: "form-group",
                  },
                  [
                    _c(
                      "label",
                      {
                        staticClass: "control-label",
                        attrs: {
                          for: "fonte",
                          title: _vm.$t("__.obrigatorio"),
                        },
                      },
                      [
                        _c("span", { staticStyle: { color: "red" } }, [
                          _vm._v("*"),
                        ]),
                        _vm._v(
                          "\n                            " +
                            _vm._s(
                              _vm.$t(
                                "__.Components.parent.printModel.sessao_vue_html.fonte"
                              )
                            ) +
                            "\n                        "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("combo-component", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required",
                          expression: "'required'",
                        },
                      ],
                      attrs: {
                        name: "fonte",
                        data: _vm.fonteOptions,
                        searchable: false,
                      },
                      model: {
                        value: _vm.model.fonte,
                        callback: function ($$v) {
                          _vm.$set(_vm.model, "fonte", $$v)
                        },
                        expression: "model.fonte",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-sm-3" }, [
                _c("div", { staticClass: "form-group" }, [
                  _c(
                    "label",
                    {
                      staticClass: "control-label",
                      attrs: {
                        for: "tamanhoFonte",
                        title: _vm.$t("__.obrigatorio"),
                      },
                    },
                    [
                      _c("span", { staticStyle: { color: "red" } }, [
                        _vm._v("*"),
                      ]),
                      _vm._v(
                        "\n                            " +
                          _vm._s(
                            _vm.$t(
                              "__.Components.parent.printModel.sessao_vue_html.tamanhoFonte"
                            )
                          ) +
                          "\n                        "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.model.tamanhoFonte,
                        expression: "model.tamanhoFonte",
                      },
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required|maior_que_zero",
                        expression: "'required|maior_que_zero'",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: {
                      type: "number",
                      id: "tamanhoFonte",
                      name: "tamanhoFonte",
                    },
                    domProps: { value: _vm.model.tamanhoFonte },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.model, "tamanhoFonte", $event.target.value)
                      },
                    },
                  }),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-sm-3" }, [
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c("label", { attrs: { for: "modeloEspecifico" } }, [
                      _vm._v(
                        "\n                            " +
                          _vm._s(
                            _vm.$t(
                              "__.Components.parent.printModel.sessao_vue_html.tipoModelo"
                            )
                          ) +
                          "\n                        "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("combo-component", {
                      attrs: {
                        id: "modeloEspecifico",
                        name: "modeloEspecifico",
                        data: _vm.modeloEspecificoOptions,
                        searchable: false,
                      },
                      model: {
                        value: _vm.model.modeloEspecifico,
                        callback: function ($$v) {
                          _vm.$set(_vm.model, "modeloEspecifico", $$v)
                        },
                        expression: "model.modeloEspecifico",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-sm-3" }, [
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c("label", { attrs: { for: "modeloPreDefinido" } }, [
                      _vm._v(
                        "\n                            " +
                          _vm._s(
                            _vm.$t(
                              "__.Components.parent.printModel.sessao_vue_html.modeloPreDefinido"
                            )
                          ) +
                          "\n                        "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("data-tooltip-component", {
                      attrs: {
                        text: _vm.$t(
                          "__.Components.parent.printModel.sessao_vue_html.modeloPreDefinidoTooltip"
                        ),
                      },
                    }),
                    _vm._v(" "),
                    _c("combo-component", {
                      attrs: {
                        id: "modeloPreDefinido",
                        name: "modeloPreDefinido",
                        data: _vm.modeloPreDefinidoOptions,
                        searchable: false,
                      },
                      model: {
                        value: _vm.model.modeloPreDefinido,
                        callback: function ($$v) {
                          _vm.$set(_vm.model, "modeloPreDefinido", $$v)
                        },
                        expression: "model.modeloPreDefinido",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "fieldset-component",
            {
              attrs: {
                title: _vm.$t(
                  "__.Components.parent.printModel.sessao_vue_html.sessoesModelImpress"
                ),
                collapsed: false,
              },
            },
            [
              _vm.model.sessoes != null
                ? _c(
                    "div",
                    { attrs: { slot: "rows" }, slot: "rows" },
                    [
                      _c("grid-component", {
                        attrs: {
                          data: _vm.model.sessoes,
                          columns: _vm.gridColumns,
                          showRemoveItem: true,
                          showTableFoot: true,
                          "page-index": _vm.pageIndex,
                          "page-size": _vm.pageSize,
                          total: _vm.total,
                          showTableHead: false,
                        },
                        on: {
                          "edit-item": _vm.onEditarSessao,
                          "create-item": _vm.onCriarSessao,
                          "remove-item": _vm.onRemoverSessao,
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ]
          ),
          _vm._v(" "),
          _c(
            "fieldset-component",
            {
              attrs: {
                title: _vm.$t(
                  "__.Components.parent.printModel.sessao_vue_html.condicoes"
                ),
                collapsed: false,
              },
            },
            [
              _vm.model.condicoes != null
                ? _c(
                    "div",
                    { attrs: { slot: "rows" }, slot: "rows" },
                    [
                      _c("grid-component", {
                        attrs: {
                          data: _vm.model.condicoes,
                          columns: _vm.gridColumnsCondicoes,
                          showRemoveItem: true,
                          showTableFoot: true,
                          "page-index": 1,
                          "page-size": 999,
                          total: _vm.model.condicoes.length,
                          showTableHead: false,
                        },
                        on: {
                          "edit-item": _vm.onEditarCondicao,
                          "create-item": _vm.onCriarCondicao,
                          "remove-item": _vm.onRemoverCondicao,
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ]
          ),
          _vm._v(" "),
          _vm.isAdmin
            ? _c("modeloImpressaoUsuarioComponent", {
                ref: "modeloImpressaoUsuarioComponent",
                attrs: { impressoraOptions: _vm.impressoraOptions },
              })
            : _vm._e(),
          _vm._v(" "),
          [
            _c("fieldset", [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-sm-12 newbtn" }, [
                  _c("div", { staticClass: "form-group" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-primary",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            _vm.onSalvar()
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n                                " +
                            _vm._s(_vm.$t("__.Crud.salvar")) +
                            "\n                            "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-secondary",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            _vm.voltar()
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n                                " +
                            _vm._s(_vm.$t("__.Crud.voltar")) +
                            "\n                            "
                        ),
                      ]
                    ),
                  ]),
                ]),
              ]),
            ]),
          ],
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "ignorePrint" },
        [
          _c(
            "shortcutComponent",
            {
              ref: "shortcutComponent",
              attrs: { modalClass: "printEdit-modal", showAddNew: false },
              on: { "selection-item": _vm.onConfirmSessao },
            },
            [
              _c(
                "div",
                {
                  staticClass: "ignorePrint",
                  attrs: { slot: "component" },
                  slot: "component",
                },
                [
                  _c("modelo-impressao-edit-component", {
                    ref: "modeloImpressaoEditComponent",
                    attrs: { tipoRotulo: _vm.model.tipoRotulo },
                  }),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "shortcutComponent",
            {
              ref: "shortcutCondicaoComponent",
              attrs: { showAddNew: false },
              on: { "selection-item": _vm.onConfirmCondicao },
            },
            [
              _c(
                "div",
                {
                  staticClass: "ignorePrint",
                  attrs: { slot: "component" },
                  slot: "component",
                },
                [
                  _c("modelo-impressao-condicao-component", {
                    ref: "modeloImpressaoCondicaoComponent",
                  }),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("replicar-selecao-franquia-component", {
        ref: "replicarSelecaoFranquiaComponent",
        on: { replicar: _vm.onConfirmReplicar },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }