import Vue from "vue";
import { mapState } from "vuex";

import ShortcutComponent from "@/components/parent/shortcut/shortcut";
import shortcutComponent from "@/components/parent/shortcut/shortcut.vue";
import { Component } from "@/decorators";
import AtendimentoWhatsAppModel from "@/models/whatsApp/atendimento/atendimentoWhatsAppModel";
import { AppState } from "@/store/store";
import { formatWhatsAppString } from "@/utils/common/number";

import "./dadosContato.scss";

@Component({
    components: {
        shortcutComponent,
    },
    computed: {
        ...mapState({
            currentAtendimento: (state: AppState) => state.atendimentoContext.currentAtendimento,
        }),
    },
})
export default class DadosContatoComponent extends Vue {
    // State computed props
    currentAtendimento: AtendimentoWhatsAppModel;

    private shortcutComponent: ShortcutComponent = null;

    private numeroContato = "";

    public async show() {
        this.shortcutComponent = this.$refs.shortcutComponent as ShortcutComponent;

        this.numeroContato = formatWhatsAppString(this.currentAtendimento.numero);
        this.shortcutComponent.title = this.$t("__.Components.parent.atendimento.view_vue_html.dadosContato") as string;

        this.shortcutComponent.show();
    }
}
