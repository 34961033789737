import BaseModel from "../base/baseModel";
import VendaModel from "../vendaModel";

import VendaEtapaPCPModel from "./vendaEtapaPCPModel";

export default class VendaPCPModel extends BaseModel {
    id?: number = null;
    vendaId?: number = null;
    venda = new VendaModel();
    dataInicio?: Date = null;
    dataFim?: Date = null;
    vendaEtapasPCP: VendaEtapaPCPModel[] = [];
}
