import BaseModel from "./base/baseModel";
import ClinicaPrescritorModel from "./clinica/clinicaPrescritorModel";
import ComissaoPrescritorFaixaModel from "./comissaoPrescritorFaixaModel";
import GenerosPessoa from "./enum/generosPessoa";
import CurvaABC from "./enum/produto/curvaABC";
import TipoConselhoRegional from "./enum/tipoConselhoRegional";
import Ufs from "./enum/ufs";
import PrescritorEspecialidadeModel from "./prescritorEspecialidadeModel";
import VisitadorPrescritorModel from "./visitadorPrescritorModel";

export default class PrescritorModel extends BaseModel {
    id?: number = null;
    dataCriacao: Date = null;
    nome: string = null;
    observacao: string = null;
    cpf: string = null;
    rg: string = null;
    dataNascimento: Date = null;
    genero?: GenerosPessoa = null;
    generoDescricao: string = null;
    cep: string = null;
    estadoId?: Ufs = null;
    estadoDescricao: string = null;
    cidadeId?: number = null;
    cidadeDescricao: string = null;
    endereco: string = null;
    bairro: string = null;
    numero: string = null;
    complemento: string = null;
    telefone: string = null;
    celular: string = null;
    email: string = null;
    numeroRegistro: string = null;
    especialidades: Array<PrescritorEspecialidadeModel> = [];
    especialidadeId?: number = null;
    comissao?: number = 0;
    pontuarDotz = false;
    tipoConselhoRegional: TipoConselhoRegional = 0;
    tipoConselhoRegionalDescricao: string = null;
    ufConselhoRegional?: Ufs = null;
    ufConselhoRegionalDescricao: string = null;
    comissoesFaixa: Array<ComissaoPrescritorFaixaModel> = [];
    ativo = true;
    visitadores: Array<VisitadorPrescritorModel> = [];
    clinicas: Array<ClinicaPrescritorModel> = [];
    beneficioId? = null;
    curvaABC?: CurvaABC = null;
    sipeAgro?: string = null;
    usuarioCriacaoNome: string = null;
}
