import { Component } from "@/decorators";
import CestModel from "@/models/produto/cestModel";
import CestService from "@/services/produto/cestService";

import EditComponentBase from "../editComponentBase";

@Component
export default class CestEditComponent extends EditComponentBase<CestModel> {
    private async beforeMount() {
        await this.setProps(new CestService(), "cests", new CestModel());
    }
}
