enum SituacoesManipulacaoOrdem {
    Orcamento = 0,
    Aprovado = 1,
    Cancelado = 2,
    //Aguardando = 3,
    //EmProcesso = 4,
    Concluido = 5,
}

export default SituacoesManipulacaoOrdem;
