var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "fieldset-component",
        {
          attrs: {
            title: _vm.$t(
              "__.Components.parent.importacaonfe.produtos_vue_html.prodsLotes"
            ),
            collapsed: false,
          },
        },
        [
          _c(
            "div",
            { attrs: { slot: "rows" }, slot: "rows" },
            [
              _c(
                "grid-component",
                {
                  attrs: {
                    data: _vm.gridData,
                    columns: _vm.produtosGridColumns,
                    subColumns: _vm.loteGridColumns,
                    subDataName: _vm.subDataName,
                    showExpand: true,
                    extendedColStartOpen: false,
                    classes: _vm.produtosClasses,
                    extraActions: _vm.multExtraActions,
                    conditionShowSubData: _vm.conditionShowSubData,
                    "show-table-head": false,
                    "show-sort-icons": false,
                    showEditItem: false,
                    showRemoveItem: false,
                  },
                  on: { "extra-action": _vm.onExtraAction },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "grid-footer",
                      attrs: { slot: "footer" },
                      slot: "footer",
                    },
                    [
                      _c("div", [_vm._v("TOTAL")]),
                      _vm._v(" "),
                      _c("div", [
                        _vm._v(
                          "\n                        " +
                            _vm._s(
                              _vm._f("filterMoeda")(
                                this.model.detalhes.reduce(function (acc, p) {
                                  return acc + p.produto.valorTotal
                                }, 0)
                              )
                            ) +
                            "\n                    "
                        ),
                      ]),
                    ]
                  ),
                ]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "shortcutComponent",
        {
          ref: "shortcutProdLotesComponent",
          attrs: { headerColor: "#f9f900" },
          on: {
            "selection-item": _vm.onConfirmProdLotes,
            "add-new-save-ok-button-click": _vm.onProdutoLoteOk,
          },
        },
        [
          _vm.showProdLote
            ? _c(
                "div",
                { attrs: { slot: "component" }, slot: "component" },
                [
                  _c("produto-e-lote-edit-component", {
                    ref: "produtoELoteEditComponent",
                    attrs: { numeroNF: _vm.model.identificacao.numero },
                    on: { "get-model": _vm.onGetModelProdutoLote },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { attrs: { slot: "footerRight" }, slot: "footerRight" },
            [
              _c("buttonScComponent", {
                attrs: {
                  classButton: "btn btn-primary",
                  buttonName: _vm.$t(
                    "__.Components.parent.importacaonfe.produtos_vue_html.proximo"
                  ),
                  title: _vm.$t(
                    "__.Components.parent.importacaonfe.produtos_vue_html.conclEAvancProxProd"
                  ),
                  shortKeyBt: "p",
                },
                on: { click: _vm.onNext },
              }),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }