import DashboardModel from "@/models/dashboard/dashboardModel";
import nfetch from "@/utils/events/nfetch";

import { IService } from "./base/iService";

export default class DashboardService extends IService<DashboardModel> {
    constructor() {
        super("Dashboard");
    }

    public getEvolucaoVendas(
        franquiaIds: number[],
        dataInicial: Date,
        dataFinal: Date,
        taxaEntrega: boolean,
        tipoVenda: number,
    ): Promise<Response> {
        return nfetch(
            `/${this.controllerName}/GetEvolucaoVendas?${this.filtrosData(
                dataInicial,
                dataFinal,
                taxaEntrega,
                tipoVenda,
            )}`,
            {
                method: "post",
                credentials: "same-origin",
                headers: {
                    "Content-Type": "application/json; charset=UTF-8",
                },
                body: JSON.stringify(franquiaIds),
            },
        );
    }

    public getManipulacoes(
        franquiaIds: number[],
        dataInicial: Date,
        dataFinal: Date,
        taxaEntrega: boolean,
        tipoVenda: number,
    ): Promise<Response> {
        return nfetch(
            `/${this.controllerName}/GetManipulacoes?${this.filtrosData(
                dataInicial,
                dataFinal,
                taxaEntrega,
                tipoVenda,
            )}`,
            {
                method: "post",
                credentials: "same-origin",
                headers: {
                    "Content-Type": "application/json; charset=UTF-8",
                },
                body: JSON.stringify(franquiaIds),
            },
        );
    }

    public getTotaisPorCategoria(
        franquiaIds: number[],
        dataInicial: Date,
        dataFinal: Date,
        taxaEntrega: boolean,
        tipoVenda: number,
    ): Promise<Response> {
        return nfetch(
            `/${this.controllerName}/GetTotaisPorCategoria?${this.filtrosData(
                dataInicial,
                dataFinal,
                taxaEntrega,
                tipoVenda,
            )}`,
            {
                method: "post",
                credentials: "same-origin",
                headers: {
                    "Content-Type": "application/json; charset=UTF-8",
                },
                body: JSON.stringify(franquiaIds),
            },
        );
    }

    private filtrosData(dataInicial: Date, dataFinal: Date, taxaEntrega: boolean, tipoVenda: number): string {
        return `dataInicial=${dataInicial ? dataInicial.toJSON() : ""}&dataFinal=${
            dataFinal ? dataFinal.toJSON() : ""
        }&taxaEntrega=${taxaEntrega}&tipoVenda=${tipoVenda}`;
    }
}
