import Vue from "vue";

import dateTimePickerComponent from "@/components/child/form/datetimepicker.vue";
import { Component } from "@/decorators";
import NotaFiscalEntradaPorProdutoLoteModel from "@/models/notaFiscalEntradaPorProdutoLoteIdModel";
import ProdutoLoteModel from "@/models/produtoLoteModel";
import NotaFiscalEntradaService from "@/services/notaFiscalEntradaService";
import Delay from "@/utils/common/delay";

import ShortcutComponent from "../../shortcut/shortcut";
import shortcutComponent from "../../shortcut/shortcut.vue";

import "../crud.scss";

@Component({
    components: {
        shortcutComponent,
        dateTimePickerComponent,
    },
})
export default class DadosNotaEntradaLoteComponent extends Vue {
    private notaFiscalEntradaService = new NotaFiscalEntradaService();
    private shortcutComponent: ShortcutComponent = null;

    notaFiscalEntradaPorProdutoLoteModel = [];
    produtoLoteModel = new ProdutoLoteModel();

    public async show(model: ProdutoLoteModel) {
        this.produtoLoteModel.updateFrom(model);

        const response = await this.notaFiscalEntradaService
            .getByProdutoId(model.id)
            .withLoading()
            .resolveWithJSON<NotaFiscalEntradaPorProdutoLoteModel[]>();

        if (response.length == 0) {
            this.$showWarning(
                this.$t("__.ts.atencao"),
                this.$t("__.Crud.produtolote.dadosNotaEntradaLote_vue_html.notaDeCompraNaoEncontradaNoSitema"),
            );
            return;
        }

        this.notaFiscalEntradaPorProdutoLoteModel = [];

        response.forEach(x => {
            x.dataEmissao = new Date(x.dataEmissao);
            x.dataInclusao = new Date(x.dataInclusao);
            this.notaFiscalEntradaPorProdutoLoteModel.push(x);
        });

        while (!this.$refs.shortcutComponent) await Delay(100);

        this.shortcutComponent = this.$refs.shortcutComponent as ShortcutComponent;

        this.shortcutComponent.title = `Dados da nota de entrada para o lote ${this.produtoLoteModel.descricao}`;
        this.shortcutComponent.show();
    }
}
