import Vue from "vue";

import actionBarComponent from "@/components/child/actionBar/actionBar.vue";
import gridComponent from "@/components/child/grid/grid.vue";
import { GridColumn, GridColumnType } from "@/components/child/grid/gridColumn";
import { Component } from "@/decorators";
import MelhorCompraModel from "@/models/melhorCompraModel";
import CompraService from "@/services/compraService";
import PDFService from "@/services/pdf/PDFService";
import { sortArray } from "@/utils/common/array";
import { editaCnpj, editaTelefone } from "@/utils/common/formatText";

import "../crud.scss";

@Component({
    components: {
        gridComponent,
        actionBarComponent,
    },
})
export default class MelhorCompraEditComponent extends Vue {
    private service = new CompraService();
    private pdfService = new PDFService("MelhorCompraPDF");

    modelId = 0;
    subDataName = "itens";
    gridData: Array<MelhorCompraModel> = [];

    gridColumns: Array<GridColumn> = [
        new GridColumn("fornecedorDescricao", "Fornecedor", GridColumnType.String),
        new GridColumn("cpfCnpj", "Cnpj", GridColumnType.String),
        new GridColumn("email", "E-Mail", GridColumnType.String),
        new GridColumn("telefone", "Telefone", GridColumnType.String),
        new GridColumn("valorSerComprado", "Valor a ser Comprado", GridColumnType.Money),
    ];

    gridSubColumnsitens: Array<GridColumn> = [
        new GridColumn("produtoDescricao", "produto", GridColumnType.String),
        new GridColumn("valorTotal", "Valor Total", GridColumnType.Money),
        new GridColumn("quantidade", "Quantidade", GridColumnType.Decimal),
        new GridColumn("valorUnitario", "Valor Unitário", GridColumnType.Money, false, false, "", "", false, false, 4),
        new GridColumn("unidadeMedidaFornecedor", "Unidade de medida do fornecedor", GridColumnType.String),
    ];

    conditionShowSubData(it) {
        return it["itens"]["length"] > 0;
    }

    private async load() {
        try {
            this.gridData = await this.service
                .getMelhorCompra(this.modelId)
                .withLoading()
                .resolveWithJSON<Array<MelhorCompraModel>>();

            this.gridData = this.gridData.map(p => ({
                ...p,
                cpfCnpj: editaCnpj(p.cpfCnpj),
                telefone: editaTelefone(p.telefone),
            }));

            const question = await this.$showQuestion(
                this.$t("__.ts.melhorCompraSucesso"),
                this.$t("__.ts.desejaImprimir"),
            );

            if (question) {
                await this.pdfService.geraPDF(this.modelId).withLoading();
            }
        } catch {
            this.$router.back();
        }
    }

    private onChangeSort(sortKey: keyof MelhorCompraModel, sortOrder: "asc" | "desc") {
        sortArray(this.gridData, sortKey, sortOrder);
    }

    private async mounted() {
        if (this.$route.params.id) {
            this.modelId = +this.$route.params.id;
            await this.load();
        }
    }
}
