var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "crud crud-edit" },
    [
      _c("actionBarComponent", {
        attrs: {
          title: _vm.$t("__.Crud.contareceber.edit_vue_html.cntReceberEdit"),
        },
        on: { onSave: _vm.save },
      }),
      _vm._v(" "),
      _c(
        "form",
        {
          attrs: { novalidate: "novalidate" },
          on: {
            submit: function ($event) {
              $event.preventDefault()
              _vm.save()
            },
          },
        },
        [
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-sm-12" },
              [
                _c(
                  "fieldset-component",
                  {
                    attrs: {
                      title: _vm.$t(
                        "__.Crud.contareceber.edit_vue_html.dadosConta"
                      ),
                      collapsed: false,
                    },
                  },
                  [
                    _c(
                      "div",
                      { attrs: { slot: "rows" }, slot: "rows" },
                      [
                        _c("div", { staticClass: "row" }, [
                          _c(
                            "div",
                            { class: !_vm.modelId ? "col-sm-8" : "col-sm-12" },
                            [
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "tooltip",
                                      rawName: "v-tooltip",
                                      value: _vm.errors.first("descricao"),
                                      expression: "errors.first('descricao')",
                                    },
                                  ],
                                  staticClass: "form-group",
                                },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "control-label",
                                      attrs: {
                                        for: "descricao",
                                        title: _vm.$t("__.obrigatorio"),
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticStyle: { color: "red" } },
                                        [_vm._v("*")]
                                      ),
                                      _vm._v(
                                        "\n                                        " +
                                          _vm._s(
                                            _vm.$t(
                                              "__.Crud.contareceber.edit_vue_html.descricao"
                                            )
                                          ) +
                                          "\n                                    "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("input", {
                                    directives: [
                                      { name: "focus", rawName: "v-focus" },
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.model.descricao,
                                        expression: "model.descricao",
                                      },
                                      {
                                        name: "validate",
                                        rawName: "v-validate",
                                        value: "required|max:256",
                                        expression: "'required|max:256'",
                                      },
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      autocomplete: "off",
                                      type: "text",
                                      id: "descricao",
                                      name: "descricao",
                                    },
                                    domProps: { value: _vm.model.descricao },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.model,
                                          "descricao",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                ]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          !_vm.modelId
                            ? _c("div", { staticClass: "col-sm-2" }, [
                                _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "tooltip",
                                        rawName: "v-tooltip",
                                        value: _vm.errors.first("recorrencias"),
                                        expression:
                                          "errors.first('recorrencias')",
                                      },
                                    ],
                                    staticClass: "form-group",
                                  },
                                  [
                                    _c(
                                      "label",
                                      {
                                        staticClass: "control-label",
                                        attrs: { for: "recorrencias" },
                                      },
                                      [
                                        _vm._v(
                                          "\n                                        " +
                                            _vm._s(
                                              _vm.$t(
                                                "__.Crud.contareceber.edit_vue_html.recorrencias"
                                              )
                                            ) +
                                            "\n                                    "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("data-tooltip-component", {
                                      attrs: {
                                        text: _vm.$t(
                                          "__.Crud.contareceber.edit_vue_html.utilizCasoRepita"
                                        ),
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("combo-component", {
                                      directives: [
                                        {
                                          name: "validate",
                                          rawName: "v-validate",
                                          value: "required",
                                          expression: "'required'",
                                        },
                                      ],
                                      attrs: {
                                        id: "recorrencias",
                                        name: "recorrencias",
                                        data: _vm.recorrenciasOptions,
                                        searchable: false,
                                      },
                                      model: {
                                        value: _vm.model.recorrencias,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.model,
                                            "recorrencias",
                                            $$v
                                          )
                                        },
                                        expression: "model.recorrencias",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          !_vm.modelId
                            ? _c("div", { staticClass: "col-sm-2" }, [
                                _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "tooltip",
                                        rawName: "v-tooltip",
                                        value: _vm.errors.first("frequencia"),
                                        expression:
                                          "errors.first('frequencia')",
                                      },
                                    ],
                                    staticClass: "form-group",
                                  },
                                  [
                                    _c(
                                      "label",
                                      {
                                        staticClass: "control-label",
                                        attrs: { for: "frequencia" },
                                      },
                                      [
                                        _vm._v(
                                          "\n                                        " +
                                            _vm._s(
                                              _vm.$t(
                                                "__.Crud.contareceber.edit_vue_html.freq"
                                              )
                                            ) +
                                            "\n                                    "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("combo-component", {
                                      directives: [
                                        {
                                          name: "validate",
                                          rawName: "v-validate",
                                          value: {
                                            rules: {
                                              required:
                                                _vm.model.recorrencias > 0,
                                            },
                                          },
                                          expression:
                                            "{ rules: { required: model.recorrencias > 0 } }",
                                        },
                                      ],
                                      attrs: {
                                        id: "frequencia",
                                        name: "frequencia",
                                        data: _vm.frequenciasOptions,
                                        disabled: _vm.model.recorrencias == 0,
                                        searchable: false,
                                      },
                                      model: {
                                        value: _vm.model.frequencia,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.model, "frequencia", $$v)
                                        },
                                        expression: "model.frequencia",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ])
                            : _vm._e(),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-sm-6" }, [
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "tooltip",
                                    rawName: "v-tooltip",
                                    value: _vm.errors.first("contaGerencialId"),
                                    expression:
                                      "errors.first('contaGerencialId')",
                                  },
                                ],
                                staticClass: "form-group",
                              },
                              [
                                _c(
                                  "label",
                                  {
                                    staticClass: "control-label",
                                    attrs: {
                                      for: "contaGerencialId",
                                      title: _vm.$t("__.obrigatorio"),
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticStyle: { color: "red" } },
                                      [_vm._v("*")]
                                    ),
                                    _vm._v(
                                      "\n                                        " +
                                        _vm._s(
                                          _vm.$t(
                                            "__.Crud.contareceber.edit_vue_html.cntGerencial"
                                          )
                                        ) +
                                        "\n                                    "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c("combo-component", {
                                  directives: [
                                    {
                                      name: "validate",
                                      rawName: "v-validate",
                                      value: "required",
                                      expression: "'required'",
                                    },
                                  ],
                                  attrs: {
                                    id: "contaGerencialId",
                                    name: "contaGerencialId",
                                    data: _vm.contasGerenciaisOptions,
                                    searchable: false,
                                    refresh: _vm.loadContasGerenciais,
                                  },
                                  model: {
                                    value: _vm.model.contaGerencialId,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.model,
                                        "contaGerencialId",
                                        $$v
                                      )
                                    },
                                    expression: "model.contaGerencialId",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _vm._v(" "),
                          _vm.model.operadoraCartoesId == null
                            ? _c("div", { staticClass: "col-sm-6" }, [
                                _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "tooltip",
                                        rawName: "v-tooltip",
                                        value: _vm.errors.first("clienteId"),
                                        expression: "errors.first('clienteId')",
                                      },
                                    ],
                                    staticClass: "form-group",
                                  },
                                  [
                                    _c(
                                      "label",
                                      {
                                        staticClass: "control-label",
                                        attrs: {
                                          for: "clienteId",
                                          title: _vm.$t("__.obrigatorio"),
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticStyle: { color: "red" } },
                                          [_vm._v("*")]
                                        ),
                                        _vm._v(
                                          "\n                                        " +
                                            _vm._s(
                                              _vm.$t(
                                                "__.Crud.contareceber.edit_vue_html.cliente"
                                              )
                                            ) +
                                            "\n                                    "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("search-combo-component", {
                                      directives: [
                                        {
                                          name: "validate",
                                          rawName: "v-validate",
                                          value: "required",
                                          expression: "'required'",
                                        },
                                      ],
                                      attrs: {
                                        id: "clienteId",
                                        name: "clienteId",
                                        actionName: "clientes",
                                        advancedSearch: true,
                                        filterable: false,
                                        advancedSearchCampos: [
                                          { campo: "text", descricao: "Nome" },
                                          {
                                            campo: "cpf",
                                            descricao: "CNPJ/CPF",
                                          },
                                          {
                                            campo: "celular",
                                            descricao: "Celular",
                                          },
                                        ],
                                        customComboText:
                                          _vm.customComboTextCliente,
                                        customView: true,
                                        propDescription: "nome",
                                        service: "Cliente",
                                      },
                                      model: {
                                        value: _vm.model.clienteId,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.model, "clienteId", $$v)
                                        },
                                        expression: "model.clienteId",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ])
                            : _c("div", { staticClass: "col-sm-6" }, [
                                _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "tooltip",
                                        rawName: "v-tooltip",
                                        value:
                                          _vm.errors.first("clienteDescricao"),
                                        expression:
                                          "errors.first('clienteDescricao')",
                                      },
                                    ],
                                    staticClass: "form-group",
                                  },
                                  [
                                    _c(
                                      "label",
                                      {
                                        staticClass: "control-label",
                                        attrs: { for: "clienteDescricao" },
                                      },
                                      [
                                        _vm._v(
                                          "\n                                        " +
                                            _vm._s(
                                              _vm.$t(
                                                "__.Crud.contareceber.edit_vue_html.cliente"
                                              )
                                            ) +
                                            "\n                                    "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.model.clienteDescricao,
                                          expression: "model.clienteDescricao",
                                        },
                                      ],
                                      staticClass: "form-control",
                                      attrs: {
                                        autocomplete: "off",
                                        type: "text",
                                        disabled: "disabled",
                                        id: "clienteDescricao",
                                        name: "clienteDescricao",
                                      },
                                      domProps: {
                                        value: _vm.model.clienteDescricao,
                                      },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            _vm.model,
                                            "clienteDescricao",
                                            $event.target.value
                                          )
                                        },
                                      },
                                    }),
                                  ]
                                ),
                              ]),
                        ]),
                        _vm._v(" "),
                        _vm.model.operadoraCartoesId != null
                          ? _c("div", { staticClass: "row" }, [
                              _c("div", { staticClass: "col-sm-6" }, [
                                _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "tooltip",
                                        rawName: "v-tooltip",
                                        value: _vm.errors.first(
                                          "operadoraCartoesDescricao"
                                        ),
                                        expression:
                                          "errors.first('operadoraCartoesDescricao')",
                                      },
                                    ],
                                    staticClass: "form-group",
                                  },
                                  [
                                    _c(
                                      "label",
                                      {
                                        staticClass: "control-label",
                                        attrs: {
                                          for: "operadoraCartoesDescricao",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                                        " +
                                            _vm._s(
                                              _vm.$t(
                                                "__.Crud.contareceber.edit_vue_html.operadoraCartoes"
                                              )
                                            ) +
                                            "\n                                    "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.model.operadoraDescricao,
                                          expression:
                                            "model.operadoraDescricao",
                                        },
                                      ],
                                      staticClass: "form-control",
                                      attrs: {
                                        autocomplete: "off",
                                        type: "text",
                                        disabled: "disabled",
                                        id: "operadoraCartoesDescricao",
                                        name: "operadoraCartoesDescricao",
                                      },
                                      domProps: {
                                        value: _vm.model.operadoraDescricao,
                                      },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            _vm.model,
                                            "operadoraDescricao",
                                            $event.target.value
                                          )
                                        },
                                      },
                                    }),
                                  ]
                                ),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "col-sm-6" }, [
                                _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "tooltip",
                                        rawName: "v-tooltip",
                                        value: _vm.errors.first("autorizacao"),
                                        expression:
                                          "errors.first('autorizacao')",
                                      },
                                    ],
                                    staticClass: "form-group",
                                  },
                                  [
                                    _vm.nsuObrigatorio
                                      ? _c(
                                          "span",
                                          { staticStyle: { color: "red" } },
                                          [_vm._v("*")]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c(
                                      "label",
                                      {
                                        staticClass: "control-label",
                                        attrs: { for: "autorizacao" },
                                      },
                                      [
                                        _vm._v(
                                          "\n                                        " +
                                            _vm._s(
                                              _vm.$t(
                                                "__.Crud.contareceber.edit_vue_html.autoriz"
                                              )
                                            ) +
                                            "\n                                    "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.model.autorizacao,
                                          expression: "model.autorizacao",
                                        },
                                        {
                                          name: "validate",
                                          rawName: "v-validate",
                                          value: {
                                            rules: {
                                              required: _vm.nsuObrigatorio,
                                            },
                                          },
                                          expression:
                                            "{\n                                            rules: {\n                                                required: nsuObrigatorio,\n                                            },\n                                        }",
                                        },
                                      ],
                                      staticClass: "form-control",
                                      attrs: {
                                        autocomplete: "off",
                                        type: "text",
                                        id: "autorizacao",
                                        name: "autorizacao",
                                      },
                                      domProps: {
                                        value: _vm.model.autorizacao,
                                      },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            _vm.model,
                                            "autorizacao",
                                            $event.target.value
                                          )
                                        },
                                      },
                                    }),
                                  ]
                                ),
                              ]),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-sm-6" }, [
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "tooltip",
                                    rawName: "v-tooltip",
                                    value: _vm.errors.first("dataVencimento"),
                                    expression:
                                      "errors.first('dataVencimento')",
                                  },
                                ],
                                staticClass: "form-group",
                              },
                              [
                                _c(
                                  "label",
                                  {
                                    staticClass: "control-label",
                                    attrs: {
                                      for: "dataVencimento",
                                      title: _vm.$t("__.obrigatorio"),
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticStyle: { color: "red" } },
                                      [_vm._v("*")]
                                    ),
                                    _vm._v(
                                      "\n                                        " +
                                        _vm._s(
                                          _vm.$t(
                                            "__.Crud.contareceber.edit_vue_html.dtVenc"
                                          )
                                        ) +
                                        "\n                                    "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c("date-time-picker-component", {
                                  directives: [
                                    {
                                      name: "validate",
                                      rawName: "v-validate",
                                      value: "required",
                                      expression: "'required'",
                                    },
                                  ],
                                  attrs: {
                                    id: "dataVencimento",
                                    name: "dataVencimento",
                                    disabled: _vm.disabledDataVencimento,
                                  },
                                  model: {
                                    value: _vm.model.dataVencimento,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.model, "dataVencimento", $$v)
                                    },
                                    expression: "model.dataVencimento",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-sm-3" }, [
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "tooltip",
                                    rawName: "v-tooltip",
                                    value: _vm.errors.first("valorTotal"),
                                    expression: "errors.first('valorTotal')",
                                  },
                                ],
                                staticClass: "form-group",
                              },
                              [
                                _c(
                                  "label",
                                  {
                                    staticClass: "control-label",
                                    attrs: {
                                      for: "valorTotal",
                                      title: _vm.$t("__.obrigatorio"),
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticStyle: { color: "red" } },
                                      [_vm._v("*")]
                                    ),
                                    _vm._v(
                                      "\n                                        " +
                                        _vm._s(
                                          _vm.$t(
                                            "__.Crud.contareceber.edit_vue_html.vlrTotal"
                                          )
                                        ) +
                                        "\n                                    "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c("moeda-component", {
                                  directives: [
                                    {
                                      name: "validate",
                                      rawName: "v-validate",
                                      value: "required|maior_que_zero",
                                      expression: "'required|maior_que_zero'",
                                    },
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    id: "valorTotal",
                                    name: "valorTotal",
                                    disabled: _vm.disabledValorTotal,
                                  },
                                  model: {
                                    value: _vm.model.valorTotal,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.model, "valorTotal", $$v)
                                    },
                                    expression: "model.valorTotal",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-sm-3" }, [
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "tooltip",
                                    rawName: "v-tooltip",
                                    value: _vm.errors.first("saldoTotal"),
                                    expression: "errors.first('saldoTotal')",
                                  },
                                ],
                                staticClass: "form-group",
                              },
                              [
                                _c(
                                  "label",
                                  {
                                    staticClass: "control-label",
                                    attrs: { for: "saldoTotal" },
                                  },
                                  [
                                    _vm._v(
                                      "\n                                        " +
                                        _vm._s(
                                          _vm.$t(
                                            "__.Crud.contareceber.edit_vue_html.sldTotal"
                                          )
                                        ) +
                                        "\n                                    "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c("moeda-component", {
                                  staticClass: "form-control",
                                  attrs: {
                                    id: "saldoTotal",
                                    name: "saldoTotal",
                                    disabled: "disabled",
                                  },
                                  model: {
                                    value: _vm.model.saldo,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.model, "saldo", $$v)
                                    },
                                    expression: "model.saldo",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "row" }, [
                          _vm.model.lancamentosVinculados.length == 0
                            ? _c("div", { staticClass: "col-sm-6" }, [
                                _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "tooltip",
                                        rawName: "v-tooltip",
                                        value: _vm.errors.first("codigoBarras"),
                                        expression:
                                          "errors.first('codigoBarras')",
                                      },
                                    ],
                                    staticClass: "form-group",
                                  },
                                  [
                                    _c(
                                      "label",
                                      {
                                        staticClass: "control-label",
                                        attrs: { for: "codigoBarras" },
                                      },
                                      [
                                        _vm._v(
                                          "\n                                        " +
                                            _vm._s(
                                              _vm.$t(
                                                "__.Crud.contareceber.edit_vue_html.codBarras"
                                              )
                                            ) +
                                            "\n                                    "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.model.codigoBarras,
                                          expression: "model.codigoBarras",
                                        },
                                        {
                                          name: "validate",
                                          rawName: "v-validate",
                                          value: "max:256",
                                          expression: "'max:256'",
                                        },
                                      ],
                                      staticClass: "form-control",
                                      attrs: {
                                        autocomplete: "off",
                                        type: "text",
                                        id: "codigoBarras",
                                        name: "codigoBarras",
                                      },
                                      domProps: {
                                        value: _vm.model.codigoBarras,
                                      },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            _vm.model,
                                            "codigoBarras",
                                            $event.target.value
                                          )
                                        },
                                      },
                                    }),
                                  ]
                                ),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              class:
                                _vm.model.lancamentosVinculados.length == 0
                                  ? "col-sm-6"
                                  : "col-sm-12",
                            },
                            [
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "tooltip",
                                      rawName: "v-tooltip",
                                      value: _vm.errors.first("observacao"),
                                      expression: "errors.first('observacao')",
                                    },
                                  ],
                                  staticClass: "form-group",
                                },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "control-label",
                                      attrs: { for: "observacao" },
                                    },
                                    [
                                      _vm._v(
                                        "\n                                        " +
                                          _vm._s(
                                            _vm.$t(
                                              "__.Crud.contareceber.edit_vue_html.obs"
                                            )
                                          ) +
                                          "\n                                    "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("textarea-component", {
                                    directives: [
                                      {
                                        name: "validate",
                                        rawName: "v-validate",
                                        value: "max:256",
                                        expression: "'max:256'",
                                      },
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      id: "observacao",
                                      name: "observacao",
                                    },
                                    model: {
                                      value: _vm.model.observacao,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.model, "observacao", $$v)
                                      },
                                      expression: "model.observacao",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                        ]),
                        _vm._v(" "),
                        (_vm.modelId == 0 ||
                          _vm.model.saldo == _vm.model.valorTotal) &&
                        _vm.model.valorTotal > 0 &&
                        _vm.model.lancamentosVinculados.length == 0 &&
                        _vm.model.clienteId != null &&
                        _vm.model.vendaId == null
                          ? [
                              _c("div", { staticClass: "row" }, [
                                _c("div", { staticClass: "col-sm-2" }, [
                                  _c(
                                    "div",
                                    { staticClass: "form-group" },
                                    [
                                      _c(
                                        "label",
                                        {
                                          attrs: {
                                            for: "chkAtualizarParcelar",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                                            " +
                                              _vm._s(
                                                _vm.$t(
                                                  "__.Crud.contareceber.edit_vue_html.parcelar"
                                                )
                                              ) +
                                              "\n                                        "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("checkbox-component", {
                                        attrs: {
                                          id: "chkAtualizarParcelar",
                                          name: "chkAtualizarParcelar",
                                        },
                                        model: {
                                          value: _vm.parcelar,
                                          callback: function ($$v) {
                                            _vm.parcelar = $$v
                                          },
                                          expression: "parcelar",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                                _vm._v(" "),
                                _vm.parcelar
                                  ? _c("div", { staticClass: "col-sm-10" }, [
                                      _c(
                                        "div",
                                        {
                                          directives: [
                                            {
                                              name: "tooltip",
                                              rawName: "v-tooltip",
                                              value: _vm.errors.first(
                                                "metodoParcelamentoId"
                                              ),
                                              expression:
                                                "errors.first('metodoParcelamentoId')",
                                            },
                                          ],
                                          staticClass: "form-group",
                                        },
                                        [
                                          _c(
                                            "label",
                                            {
                                              staticClass: "control-label",
                                              attrs: {
                                                for: "metodoParcelamentoId",
                                                title: _vm.$t("__.obrigatorio"),
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: { color: "red" },
                                                },
                                                [_vm._v("*")]
                                              ),
                                              _vm._v(
                                                "\n                                            " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "__.Crud.contareceber.edit_vue_html.mtdParcelamento"
                                                    )
                                                  ) +
                                                  "\n                                        "
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c("combo-component", {
                                            directives: [
                                              {
                                                name: "validate",
                                                rawName: "v-validate",
                                                value: "required",
                                                expression: "'required'",
                                              },
                                            ],
                                            attrs: {
                                              id: "metodoParcelamentoId",
                                              name: "metodoParcelamentoId",
                                              data: _vm.metodosPagamentosOptions,
                                              refresh: _vm.loadMetodoPagamentos,
                                            },
                                            model: {
                                              value: _vm.metodoParcelamentoId,
                                              callback: function ($$v) {
                                                _vm.metodoParcelamentoId = $$v
                                              },
                                              expression:
                                                "metodoParcelamentoId",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ])
                                  : _vm._e(),
                              ]),
                              _vm._v(" "),
                              _vm.parcelar
                                ? _c("div", { staticClass: "row" }, [
                                    _c("div", { staticClass: "col-sm-12" }, [
                                      _c("div", { staticClass: "form-group" }, [
                                        _c(
                                          "button",
                                          {
                                            staticClass: "btn btn-primary",
                                            attrs: { type: "button" },
                                            on: {
                                              click: function ($event) {
                                                _vm.onParcelar()
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                                            " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "__.Crud.contareceber.edit_vue_html.parcelar"
                                                  )
                                                ) +
                                                "\n                                        "
                                            ),
                                          ]
                                        ),
                                      ]),
                                    ]),
                                  ])
                                : _vm._e(),
                            ]
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.model.lancamentosVinculados.length > 0
                          ? _c(
                              "fieldset-component",
                              {
                                attrs: {
                                  title: _vm.$t(
                                    "__.Crud.contapagar.edit_vue_html.parcela"
                                  ),
                                  collapsed: false,
                                },
                              },
                              [
                                _c(
                                  "div",
                                  { attrs: { slot: "rows" }, slot: "rows" },
                                  [
                                    _c("div", { staticClass: "row" }, [
                                      _c(
                                        "div",
                                        { staticClass: "col-sm-12" },
                                        [
                                          _c("grid-component", {
                                            attrs: {
                                              columns: _vm.gridColumnsParcelas,
                                              data: _vm.model
                                                .lancamentosVinculados,
                                              showActionColumn: false,
                                              "show-edit-item": false,
                                              "show-remove-item": false,
                                              "show-table-foot": false,
                                              "show-table-head": false,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]),
                                  ]
                                ),
                              ]
                            )
                          : _vm._e(),
                      ],
                      2
                    ),
                  ]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-md-12" },
              [
                _c("recebimento-conta-component", {
                  ref: "recebimentoContaComponent",
                  attrs: {
                    operadoraCartoesId: _vm.model.operadoraCartoesId,
                    percentualTarifa: _vm.model.tarifa,
                  },
                  on: { "inclusao-recebimento": _vm.onInclusaoRecebimento },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c(
            "fieldset-component",
            {
              attrs: {
                title: _vm.$t(
                  "__.Crud.contareceber.edit_vue_html.recebRealizados"
                ),
                collapsed: false,
              },
            },
            [
              _c(
                "div",
                { attrs: { slot: "rows" }, slot: "rows" },
                [
                  _c(
                    "grid-component",
                    {
                      attrs: {
                        data: _vm.recebimentos,
                        columns: _vm.gridColumns,
                        "show-table-head": false,
                        "show-sort-icons": false,
                        showActionColumn: true,
                      },
                      on: {
                        "remove-item": _vm.onRemoveItem,
                        "edit-item": _vm.onEditItem,
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "grid-footer",
                          attrs: { slot: "footer" },
                          slot: "footer",
                        },
                        [
                          _c("div", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "__.Crud.contareceber.edit_vue_html.sldReceber"
                                )
                              )
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", [
                            _vm._v(
                              _vm._s(_vm._f("filterMoeda")(_vm.saldoReceber))
                            ),
                          ]),
                        ]
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c("fieldset", [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-sm-12 newbtn" }, [
                _c("div", { staticClass: "form-group" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-success",
                      attrs: { type: "submit" },
                    },
                    [
                      _c("i", {
                        staticClass: "fa fa-save",
                        attrs: { "aria-hidden": "true" },
                      }),
                      _vm._v(
                        "\n                            " +
                          _vm._s(_vm.$t("__.Crud.salvar")) +
                          "\n                        "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-secondary",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          _vm.load()
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n                            " +
                          _vm._s(_vm.$t("__.Crud.limpar")) +
                          "\n                        "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-secondary",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          _vm.cancel()
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n                            " +
                          _vm._s(_vm.$t("__.Crud.voltar")) +
                          "\n                        "
                      ),
                    ]
                  ),
                ]),
              ]),
            ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }