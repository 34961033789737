import Vue from "vue";

import buttonScComponent from "@/components/child/form/buttonSc.vue";
import fieldsetComponent from "@/components/child/form/fieldset.vue";
import gridComponent from "@/components/child/grid/grid.vue";
import { GridAction } from "@/components/child/grid/gridAction";
import { GridColors, GridColumn, GridColumnType } from "@/components/child/grid/gridColumn";
import { Component, Prop, Watch } from "@/decorators";
import TiposPessoa from "@/models/enum/tiposPessoa";
import ProdutoLoteNfeModel from "@/models/notafiscaleletronica/dados/informacoes/detalhe/produto/produtolote/produtoLoteNfeModel";
import ProdutoNfeModel from "@/models/notafiscaleletronica/dados/informacoes/detalhe/produto/produtoNfeModel";
import InformacoesNfeModel from "@/models/notafiscaleletronica/dados/informacoes/informacoesNfeModel";
import ProdutoModel from "@/models/produto/produtoModel";
import UnidadeMedidaModel from "@/models/unidadeMedidaModel";
import IndicesConversoesUnidadeMedidasService from "@/services/indicesConversoesUnidadeMedidaService";
import NotaFiscalEletronicaImportacaoService from "@/services/notaFiscalEletronica/notaFiscalEletronicaImportacaoService";
import ProdutoService from "@/services/produto/produtoService";
import UnidadeMedidaService from "@/services/unidadeMedidaService";
import Delay from "@/utils/common/delay";

import Dictionary from "../../../types/dictionary";
import ProdutoLoteEditComponent from "../crud/produtolote/edit";
import produtoLoteEditComponent from "../crud/produtolote/edit.vue";
import ShortcutComponent from "../shortcut/shortcut";
import shortcutComponent from "../shortcut/shortcut.vue";

import importacaoNfeProdutoAssociacaoComponent from "./produtoassociacao.vue";
import ProdutoELoteEditComponent from "./produtolotes";
import produtoELoteEditComponent from "./produtolotes.vue";

@Component({
    components: {
        fieldsetComponent,
        gridComponent,
        importacaoNfeProdutoAssociacaoComponent,
        buttonScComponent,
        produtoLoteEditComponent,
        shortcutComponent,
        produtoELoteEditComponent,
    },
})
export default class ImportacaoNfeProdutosComponent extends Vue {
    private unidadeMedidaService = new UnidadeMedidaService();
    private indicesConversoesUnidadeMedidasService = new IndicesConversoesUnidadeMedidasService();

    private produtoLoteEdit: ProdutoLoteEditComponent = null;
    private produtoELoteEditComponent: ProdutoELoteEditComponent = null;
    private shortcutProdLotesComponent: ShortcutComponent = null;
    private showProdLote = false;

    private unidadesMedida: UnidadeMedidaModel[] = [];

    @Prop({ type: InformacoesNfeModel, required: true }) model: InformacoesNfeModel;

    codigos: Array<string> = [];

    gridData: Array<ProdutoNfeModel> = [];
    gridExtraActionsAssociaProd: Array<GridAction> = [];
    gridExtraActionsAssociaLote: Array<GridAction> = [];
    gridExtraActionsAssociaProdLote: Array<GridAction> = [];
    multExtraActions: Array<object> = [];

    produtosAssociacoes: Map<string, number> = new Map<string, number>();
    produtosClasses: Array<string> = [];
    get produtosGridColumns(): Array<GridColumn> {
        return [
            new GridColumn("codigo", this.$t("__.ts.cod"), GridColumnType.String),
            new GridColumn("descricao", this.$t("__.ts.descr"), GridColumnType.String),
            new GridColumn("quantidade", this.$t("__.ts.qtde"), GridColumnType.Decimal),
            new GridColumn("unidadeMedida", this.$t("__.ts.unid"), GridColumnType.String),
            new GridColumn("fatorCorrecao", this.$t("__.ts.fc"), GridColumnType.Decimal, true, false, ""),
            new GridColumn("valorTotal", this.$t("__.Crud.contapagar.edit_vue_html.vlrTotal"), GridColumnType.Money),
            new GridColumn("descricaoProduto", this.$t("__.ts.prod"), GridColumnType.String),
        ];
    }

    subDataName = "lotes";

    get loteGridColumns(): Array<GridColumn> {
        return [
            new GridColumn("descricao", this.$t("__.ts.lote") as string, GridColumnType.String),
            new GridColumn("dataFabricacao", this.$t("__.ts.dtFabric") as string, GridColumnType.Date),
            new GridColumn("dataValidade", this.$t("__.ts.dtVal") as string, GridColumnType.Date),
            new GridColumn("quantidade", this.$t("__.ts.quantidade") as string, GridColumnType.Decimal),
            new GridColumn("unidadeMedidaSigla", this.$t("__.ts.unidadeMedida") as string, GridColumnType.String),
            new GridColumn("precoMaximoConsumidor", this.$t("__.ts.precMaxConsumidor") as string, GridColumnType.Money),
        ];
    }

    conditionShowSubData(it) {
        if (it["lotes"] != null) return it["lotes"]["length"] > 0;
        else return false;
    }

    public async getAssociacoes() {
        if (!this.isValid()) {
            throw {
                title: this.$t("__.ts.prods"),
                message: this.$t("__.ts.necessAssociarProds"),
            };
        } else if (!this.hasValidLotes()) {
            throw {
                title: this.$t("__.ts.prods"),
                message: this.$t("__.ts.necessAssociarLotes"),
            };
        }

        const associacoes = new Dictionary<number>();

        this.produtosAssociacoes.forEach((value, key) => {
            associacoes[key] = value;
        });

        return associacoes;
    }

    public allConferido() {
        return this.model.detalhes.every(p => p.produto.conferido);
    }

    private isValid() {
        const codigos = this.model.detalhes.map(p => p.produto.codigo);

        for (const codigo of codigos) {
            if (!this.produtosAssociacoes.get(codigo)) {
                return false;
            }
        }

        return true;
    }
    //se todos produtos possuem lotes
    private hasValidLotes() {
        return !(
            this.model.detalhes
                .filter(p => !p.produto.importarSemLote)
                .map(p => p.produto.lote == null)
                .indexOf(true) >= 0
        );
    }

    private async loadAssociacoesProdutos() {
        try {
            const produtos = this.model.detalhes.map(p => `${p.produto.codigo},${p.produto.descricao}`);

            const data = await new NotaFiscalEletronicaImportacaoService()
                .getAssociacoesProdutos(
                    this.model.emitente.tipoPessoa,
                    this.model.emitente.tipoPessoa == TiposPessoa.Juridica
                        ? this.model.emitente.cnpj
                        : this.model.emitente.cpf,
                    produtos,
                )
                .withLoading()
                .then(r => r.json() as Promise<Dictionary<number>>);

            const codigos = this.model.detalhes.map(p => p.produto.codigo);
            for (const codigo of codigos) {
                this.produtosAssociacoes.set(codigo, data[codigo]);
            }
        } catch {}
    }

    private async atualizarGrid() {
        this.codigos = this.model.detalhes.map(p => p.produto.codigo);
        this.gridData = this.model.detalhes.map(p => p.produto);

        const produtosAssociados: number[] = [];
        this.produtosAssociacoes.forEach(p => produtosAssociados.push(p));
        const produtos = await new ProdutoService()
            .getProdutosByIds(produtosAssociados.filter(p => p))
            .then(r => r.json() as Promise<ProdutoModel[]>);

        for (let i = 0; i < this.codigos.length; i++) {
            //atribui ao campo quantidade convertida o campo quantidade original se ainda nao foi incluido
            if (this.model.detalhes[i].produto.quantidadeConvertida == null) {
                this.model.detalhes[i].produto.quantidadeConvertida = 0;
            }
            //para mostras lotes em subgrids
            if (this.model.detalhes[i].produto.lotes == null) {
                this.model.detalhes[i].produto.lotes = new Array<ProdutoLoteNfeModel>();

                if (this.model.detalhes[i].produto.lote != null) {
                    this.model.detalhes[i].produto.lotes.push(this.model.detalhes[i].produto.lote);
                }
            }

            if (!this.model.detalhes[i].produto.buscouInfos) {
                await this.getAssociacao(i, produtos);
            } else {
                const associacao = this.produtosAssociacoes.get(this.codigos[i]);

                await this.validaIconItem(i, !associacao);
            }
        }

        if (this.multExtraActions.length == 0) {
            if (this.gridExtraActionsAssociaProdLote.length > 0) {
                this.multExtraActions.push(this.gridExtraActionsAssociaProdLote);
            }
        }

        this.gridData = this.model.detalhes.map(p => p.produto);
    }

    private async getAssociacao(index: number, produtos: ProdutoModel[]) {
        this.model.detalhes[index].produto.descricaoQtdOriginal = `Qtd. ${this.model.detalhes[
            index
        ].produto.quantidade.toString()} - ${this.model.detalhes[index].produto.unidadeMedida.toString()}`;
        const unidadeMedidaSiglaNfe = this.getString(this.model.detalhes[index].produto.unidadeMedida);
        const numQtdNf = this.getFloat(this.model.detalhes[index].produto.unidadeMedida);

        this.model.detalhes[index].produto.unidadeMedida = unidadeMedidaSiglaNfe; // mantem apenas o que é letras para melhroar a busca
        //multiplica o numero pela quantidade para ter o valor exato ...exc 2 un de 2kg = 4kg
        this.model.detalhes[index].produto.quantidade = this.model.detalhes[index].produto.quantidade * numQtdNf;

        const associacao = this.produtosAssociacoes.get(this.codigos[index]);
        if (associacao) {
            try {
                const data = produtos.filter(p => p.id == associacao)[0];
                this.model.detalhes[index].produto.descricaoProduto = data.descricao;
                this.model.detalhes[index].produto.produtoId = data.id;
                this.model.detalhes[index].produto.unidadeMedidaId = data.unidadeMedidaEstoqueId;
                this.model.detalhes[index].produto.densidadeProduto = data.densidade;
                this.model.detalhes[index].produto.custoReferenciaProduto = data.custoReferencia;
                await this.getUnidadesMedida(data.unidadeMedidaEstoqueId, index, data.densidade, data.id);
                await this.validaIconItem(index, false);
            } catch {
                this.validaIconItem(index, !associacao);
            }
        } else {
            if (!this.model.detalhes[index].produto.unidadeMedidaNfId) {
                const unidade = await this.getUnidadeMedidaBySigla(unidadeMedidaSiglaNfe);

                if (unidade) {
                    this.model.detalhes[index].produto.unidadeMedidaNfId = unidade.id;
                }
            }

            this.validaIconItem(index, false);
        }
    }

    private async validaIconItem(index: number, naoAssociado: boolean) {
        const warn = this.getProdDivergente(index, !naoAssociado);

        this.gridExtraActionsAssociaProdLote[index] = new GridAction(
            "associar",
            "Associar",
            warn ? "fa fa-exclamation-circle" : "fa fa-check-circle",
            warn ? GridColors.YELLOW : GridColors.GREEN,
        );
        if (this.model.detalhes[index].produto.lote != null) {
            if (
                this.model.detalhes[index].produto.lote.unidadeMedidaSigla == null ||
                this.model.detalhes[index].produto.lote.unidadeMedidaSigla.trim() == ""
            )
                this.model.detalhes[index].produto.lote.unidadeMedidaSigla =
                    this.model.detalhes[index].produto.unidadeMedida;
        }
    }

    private getFloat(str: string) {
        const numStr = str.replace(/[^0-9.,]/g, "");

        return numStr == "" || numStr == null ? 1 : parseFloat(numStr);
    }

    private getString(str: string) {
        const onlyTxt = str.replace(/[0-9]/g, "");

        return onlyTxt.replace(/[^a-z0-9]/gi, "");
    }

    //busca unidades de medidias e converte quando necessario
    private async getUnidadesMedida(unidadeId: number, indexDetalhes: number, densidade: number, produtoId: number) {
        let unidadeMedidaIdNfe;
        const unidadeMedidaSiglaNfe = this.model.detalhes[indexDetalhes].produto.unidadeMedida;

        try {
            this.model.detalhes[indexDetalhes].produto.buscouInfos = true;

            const unidade = await this.getUnidadeMedidaById(unidadeId);

            this.model.detalhes[indexDetalhes].produto.unidadeMedidaEstoqueProduto = unidade.sigla;

            const unidadeSigla = await this.getUnidadeMedidaBySigla(unidadeMedidaSiglaNfe);

            if (unidadeSigla != null) {
                unidadeMedidaIdNfe = unidadeSigla.id;
                this.model.detalhes[indexDetalhes].produto.unidadeMedidaNfId = unidadeSigla.id;
            }

            const fatorConversor = await this.indicesConversoesUnidadeMedidasService.getIndiceConversao(
                unidadeMedidaIdNfe,
                unidadeId,
                densidade,
                produtoId,
            );

            //Validações de unidades de medidas
            if (
                this.model.detalhes[indexDetalhes].produto.quantidadeConvertida == 0 ||
                this.model.detalhes[indexDetalhes].produto.quantidadeConvertida == null
            ) {
                if (unidade.id == unidadeMedidaIdNfe) {
                    this.model.detalhes[indexDetalhes].produto.quantidadeConvertida =
                        this.model.detalhes[indexDetalhes].produto.quantidade;
                } else {
                    if (fatorConversor > 0) {
                        this.model.detalhes[indexDetalhes].produto.quantidadeConvertida =
                            this.model.detalhes[indexDetalhes].produto.quantidade * fatorConversor;
                    } else {
                        //se ainda nao foi atribuido valor ao campo, atribuir igual a quantidade
                        if (
                            this.model.detalhes[indexDetalhes].produto.quantidadeConvertida == null ||
                            this.model.detalhes[indexDetalhes].produto.quantidadeConvertida == 0
                        )
                            this.model.detalhes[indexDetalhes].produto.quantidadeConvertida =
                                this.model.detalhes[indexDetalhes].produto.quantidade;
                    }
                }
            }
        } catch {}
    }

    private async onExtraAction(name: string, model: ProdutoNfeModel, index: number) {
        if (name.trim() == "associar") {
            await this.openProdutoLote(model, index);
        }
    }

    private async openProdutoLote(model: ProdutoNfeModel, index: number) {
        this.showProdLote = true;

        while (this.$refs.produtoELoteEditComponent == null) await Delay(50);
        this.produtoELoteEditComponent = this.$refs.produtoELoteEditComponent as ProdutoELoteEditComponent;

        this.shortcutProdLotesComponent.title = model.descricao;
        this.produtoELoteEditComponent.setModel(model, index);
        this.shortcutProdLotesComponent.show();
    }

    private async onAssociacaoItem(codigo: string, produtoId?: number) {
        if (!produtoId) {
            this.$showError(this.$t("__.ts.prods"), this.$t("__.ts.necessAssociarUmProd"));
        } else {
            this.produtosAssociacoes.set(codigo, produtoId);

            await this.atualizarGrid().withLoading();
        }
    }

    private onProdutoLoteOk() {
        this.produtoLoteEdit.save();
    }

    //@ts-ignore
    @Watch("model.chaveAcesso")
    private async onDataChanged() {
        const codigos = this.model.detalhes.map(p => p.produto.codigo);

        this.produtosAssociacoes.clear();

        if (codigos.length == 0) {
            this.gridData = [];
        } else {
            await this.loadAssociacoesProdutos();

            await this.atualizarGrid().withLoading();
        }
    }

    private async onConfirmProdLotes() {
        this.produtoELoteEditComponent.getValidModel();
    }

    private onGetModelProdutoLote(produto: ProdutoNfeModel, index: number, next: boolean) {
        if (index >= 0) {
            this.model.detalhes[index].produto.conferido = true;
            this.model.detalhes[index].produto.produtoId = produto.produtoId;
            this.model.detalhes[index].produto.descricaoProduto = produto.descricaoProduto;
            this.model.detalhes[index].produto.quantidadeConvertida = produto.quantidadeConvertida;
            this.model.detalhes[index].produto.importarSemLote = produto.importarSemLote;
            this.model.detalhes[index].produto.fatorCorrecao = produto.fatorCorrecao;
            this.model.detalhes[index].produto.unidadeMedida = produto.unidadeMedida;
            if (produto.unidadeMedidaId > 0) {
                this.model.detalhes[index].produto.unidadeMedidaId = produto.unidadeMedidaId;
            }

            this.model.detalhes[index].produto.quantidade = produto.quantidade;
            this.model.detalhes[index].produto.unidadeMedidaNfId = produto.unidadeMedidaNfId;
            this.model.detalhes[index].produto.densidadeProduto = produto.densidadeProduto;

            this.model.detalhes[index].produto.lote = new ProdutoLoteNfeModel();
            this.model.detalhes[index].produto.lote.updateFrom(produto.lote);
            //remove o ultimo e inclui o novo
            this.model.detalhes[index].produto.lotes.pop();
            if (!produto.importarSemLote) {
                if (this.model.detalhes[index].produto.lote.precoMaximoConsumidor == null) {
                    this.model.detalhes[index].produto.lote.precoMaximoConsumidor = 0;
                }

                if (this.model.detalhes[index].produto.lote.densidade == null) {
                    this.model.detalhes[index].produto.lote.densidade = 0;
                }

                this.model.detalhes[index].produto.lote.produtoId = produto.produtoId;

                if (this.model.detalhes[index].produto.lote.automaticoNfe == null)
                    this.model.detalhes[index].produto.lote.automaticoNfe = false;

                if (
                    this.model.detalhes[index].produto.lote.quantidade == null ||
                    this.model.detalhes[index].produto.lote.quantidade == 0
                )
                    this.model.detalhes[index].produto.lote.quantidade = produto.quantidade;

                if (
                    this.model.detalhes[index].produto.lote.unidadeMedidaSigla == null ||
                    this.model.detalhes[index].produto.lote.unidadeMedidaSigla == ""
                )
                    this.model.detalhes[index].produto.lote.unidadeMedidaSigla = produto.unidadeMedida;

                this.model.detalhes[index].produto.lotes.push(this.model.detalhes[index].produto.lote);
            } else {
                //para nao dar erro na transformacao do json
                this.model.detalhes[index].produto.lote = new ProdutoLoteNfeModel();
                this.model.detalhes[index].produto.lote.produtoId = 0;
                this.model.detalhes[index].produto.lote.quantidade = 0;
                this.model.detalhes[index].produto.lote.densidade = 0;
                this.model.detalhes[index].produto.lote.precoMaximoConsumidor = 0;
                this.model.detalhes[index].produto.lote.dataFabricacao = new Date();
                this.model.detalhes[index].produto.lote.dataValidade = new Date();
                this.model.detalhes[index].produto.lote.automaticoNfe = false;
            }
            this.onAssociacaoItem(produto.codigo, produto.produtoId);

            if (!next) {
                this.shortcutProdLotesComponent.hide();
            } else {
                //busca proximo
                const newIndex = this.getNextDivergente(index);

                if (newIndex >= 0) {
                    const model = new ProdutoNfeModel();
                    model.updateFrom(this.model.detalhes[newIndex].produto);
                    this.openProdutoLote(model, newIndex);
                } else {
                    //concluido
                    this.$showSuccess(this.$t("__.ts.verifics"), this.$t("__.ts.todosProdsVerifSucess")).then(() => {
                        this.shortcutProdLotesComponent.hide();
                    });
                }
            }
        }
    }

    private getNextDivergente(index: number) {
        //busca apartir do atual ate o final
        for (let i = index + 1; i < this.model.detalhes.length; i++) {
            const associacao = this.produtosAssociacoes.get(this.codigos[i]);
            if (this.getProdDivergente(i, associacao)) {
                return i;
            }
        }
        //se nao retornou nada no loop acima, busca desde o inicio ate o atual
        for (let i = 0; i < index; i++) {
            const associacao = this.produtosAssociacoes.get(this.codigos[i]);
            if (this.getProdDivergente(i, associacao)) {
                return i;
            }
        }

        return -1;
    }

    private getProdDivergente(index: number, associacao) {
        return (
            !this.model.detalhes[index].produto.conferido ||
            (this.model.detalhes[index].produto.lote == null && !this.model.detalhes[index].produto.importarSemLote) ||
            !associacao ||
            this.model.detalhes[index].produto.quantidadeConvertida == 0
        );
    }

    private isShowingShort() {
        if (this.shortcutProdLotesComponent == null) return false;

        return this.shortcutProdLotesComponent.isShowing();
    }

    private onNext() {
        if (this.isShowingShort()) {
            this.produtoELoteEditComponent.getValidModel(true);
        } else {
            const newIndex = this.getNextDivergente(-1);

            if (newIndex >= 0) {
                const model = new ProdutoNfeModel();
                model.updateFrom(this.model.detalhes[newIndex].produto);
                this.openProdutoLote(model, newIndex);
            } else {
                this.$showSuccess(this.$t("__.ts.verifics"), this.$t("__.ts.naoEncontProdsDiverg")).then(() => {
                    this.shortcutProdLotesComponent.hide();
                });
            }
        }
    }

    private async getUnidadeMedidaById(id: number) {
        let unidade: UnidadeMedidaModel = null;

        const unidades = this.unidadesMedida.filter(p => p.id == id);
        if (unidades.length > 0) {
            unidade = unidades[0];
        }

        if (!unidade) {
            unidade = await this.unidadeMedidaService.get(id).then(r => r.json() as Promise<UnidadeMedidaModel>);

            this.unidadesMedida.push(unidade);
        }

        return unidade;
    }

    private async getUnidadeMedidaBySigla(sigla: string) {
        let unidade: UnidadeMedidaModel = null;

        const unidades = this.unidadesMedida.filter(p => p.sigla == sigla);
        if (unidades.length > 0) {
            unidade = unidades[0];
        }

        if (!unidade) {
            unidade = await this.unidadeMedidaService
                .getUnidadeMedidabySiglas(sigla)
                .then(r => r.json() as Promise<UnidadeMedidaModel>);

            this.unidadesMedida.push(unidade);
        }

        return unidade;
    }

    private mounted() {
        this.shortcutProdLotesComponent = this.$refs.shortcutProdLotesComponent as ShortcutComponent;
    }
}
