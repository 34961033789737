var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "row" }, [
        _c("div", { class: _vm.dataFim == null ? "col-sm-6" : "col-sm-12" }, [
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c(
                "label",
                { staticClass: "control-label", attrs: { for: "etapaAtual" } },
                [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.$t("__.Crud.pcp.etapas.etapaAtual")) +
                      "\n                "
                  ),
                ]
              ),
              _vm._v(" "),
              _c("combo-component", {
                attrs: {
                  id: "etapaAtual",
                  name: "etapaAtual",
                  data: _vm.etapasOptions,
                  searchable: false,
                  disabled: "",
                },
                model: {
                  value: _vm.etapaAtualId,
                  callback: function ($$v) {
                    _vm.etapaAtualId = $$v
                  },
                  expression: "etapaAtualId",
                },
              }),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _vm.dataFim == null
          ? _c("div", { staticClass: "col-sm-6" }, [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "tooltip",
                      rawName: "v-tooltip",
                      value: _vm.errors.first("etapaDestino"),
                      expression: "errors.first('etapaDestino')",
                    },
                  ],
                  staticClass: "form-group",
                },
                [
                  _c(
                    "label",
                    {
                      staticClass: "control-label",
                      attrs: {
                        for: "etapaDestino",
                        title: _vm.$t("__.obrigatorio"),
                      },
                    },
                    [
                      _c("span", { staticStyle: { color: "red" } }, [
                        _vm._v("*"),
                      ]),
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.$t("__.Crud.pcp.etapas.etapaDestino")) +
                          "\n                "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("combo-component", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'",
                      },
                    ],
                    attrs: {
                      id: "etapaDestino",
                      name: "etapaDestino",
                      data: _vm.etapasOptions,
                      searchable: true,
                      refresh: _vm.loadEtapas,
                    },
                    model: {
                      value: _vm.etapaDestinoId,
                      callback: function ($$v) {
                        _vm.etapaDestinoId = $$v
                      },
                      expression: "etapaDestinoId",
                    },
                  }),
                ],
                1
              ),
            ])
          : _vm._e(),
      ]),
      _vm._v(" "),
      _vm.dataFim == null
        ? _c("div", { staticClass: "row" }, [
            _vm.isMotivoObrigatorio
              ? _c("div", { staticClass: "col-sm-6" }, [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "tooltip",
                          rawName: "v-tooltip",
                          value: _vm.errors.first("motivoEtapaPCP"),
                          expression: "errors.first('motivoEtapaPCP')",
                        },
                      ],
                      staticClass: "form-group",
                    },
                    [
                      _c(
                        "label",
                        {
                          staticClass: "control-label",
                          attrs: {
                            for: "motivoEtapaPCP",
                            title: _vm.$t("__.obrigatorio"),
                          },
                        },
                        [
                          _c("span", { staticStyle: { color: "red" } }, [
                            _vm._v("*"),
                          ]),
                          _vm._v(
                            "\n                    " +
                              _vm._s(
                                _vm.$t("__.Crud.pcp.etapas.motivoEtapaPCP")
                              ) +
                              "\n                "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("combo-component", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required",
                            expression: "'required'",
                          },
                        ],
                        attrs: {
                          id: "motivoEtapaPCP",
                          name: "motivoEtapaPCP",
                          data: _vm.motivosEtapaOptions,
                          searchable: true,
                          refresh: _vm.loadMotivosEtapas,
                        },
                        model: {
                          value: _vm.motivoEtapaPCPId,
                          callback: function ($$v) {
                            _vm.motivoEtapaPCPId = $$v
                          },
                          expression: "motivoEtapaPCPId",
                        },
                      }),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              { class: _vm.isMotivoObrigatorio ? "col-sm-6" : "col-sm-12" },
              [
                _c("div", { staticClass: "form-group" }, [
                  _c(
                    "label",
                    {
                      staticClass: "control-label",
                      attrs: { for: "observacao" },
                    },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.$t("__.Crud.pcp.etapas.observacao")) +
                          "\n                "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.observacao,
                        expression: "observacao",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: {
                      autocomplete: "off",
                      type: "text",
                      id: "observacao",
                      name: "observacao",
                    },
                    domProps: { value: _vm.observacao },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.observacao = $event.target.value
                      },
                    },
                  }),
                ]),
              ]
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-sm-6" }, [
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c(
                "label",
                { staticClass: "control-label", attrs: { for: "etapaAtual" } },
                [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.$t("__.Crud.pcp.etapas.dataInicio")) +
                      "\n                "
                  ),
                ]
              ),
              _vm._v(" "),
              _c("date-time-picker-component", {
                attrs: {
                  pickTime: true,
                  id: "dataInicio",
                  name: "dataInicio",
                  disabled: "",
                },
                model: {
                  value: _vm.dataInicio,
                  callback: function ($$v) {
                    _vm.dataInicio = $$v
                  },
                  expression: "dataInicio",
                },
              }),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-sm-6" }, [
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c(
                "label",
                { staticClass: "control-label", attrs: { for: "dataFim" } },
                [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.$t("__.Crud.pcp.etapas.dataFim")) +
                      "\n                "
                  ),
                ]
              ),
              _vm._v(" "),
              _c("date-time-picker-component", {
                attrs: {
                  pickTime: true,
                  id: "dataFim",
                  name: "dataFim",
                  disabled: "",
                },
                model: {
                  value: _vm.dataFim,
                  callback: function ($$v) {
                    _vm.dataFim = $$v
                  },
                  expression: "dataFim",
                },
              }),
            ],
            1
          ),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "fieldset-component",
        {
          attrs: {
            title: _vm.$t("__.Crud.pcp.etapas.etapas"),
            collapsed: false,
          },
        },
        [
          _c(
            "div",
            { attrs: { slot: "rows" }, slot: "rows" },
            [
              _c("grid-component", {
                attrs: {
                  data: _vm.gridData,
                  columns: _vm.gridEtapasColumns,
                  "show-action-column": false,
                  "show-sort-icons": false,
                  "show-table-head": false,
                  "show-table-foot": false,
                  "show-edit-item": false,
                },
              }),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }