import Vue from "vue";
import { mapGetters } from "vuex";

import Grid from "@/components/child/grid/grid";
import gridComponent from "@/components/child/grid/grid.vue";
import { GridColumn, GridColumnType } from "@/components/child/grid/gridColumn";
import { Component, Watch } from "@/decorators";
import GrupoModel from "@/models/grupoModel";
import PaginationModel from "@/models/paginationModel";
import GrupoService from "@/services/grupoService";
import { Getters } from "@/store/store";

import "../crud.scss";

@Component({
    components: {
        gridComponent,
    },
    computed: {
        ...mapGetters(["HAS_PERMISSAO_PERFIL"] as Getters),
    },
})
export default class GrupoListComponent extends Vue {
    // State computed props
    HAS_PERMISSAO_PERFIL: (isRede: boolean, isFranqueador: boolean, isSuporte: boolean) => Promise<boolean>;

    private service = new GrupoService();
    private gridcomponent: Grid = null;

    gridFilterKey = "";
    gridSortKey = "descricao";
    gridSortOrder = "asc";
    gridData: Array<GrupoModel> = [];
    isAdmin: boolean;
    get gridColumns(): Array<GridColumn> {
        return [
            new GridColumn("descricao", "Descri\u00e7\u00e3o", GridColumnType.String),
            new GridColumn("ativo", "Ativo", GridColumnType.Boolean),
        ];
    }

    pageIndex = 1;
    pageSize = 20;
    total = 0;

    pags = false;
    tipoDocGerar: number = null;

    private async load() {
        this.isAdmin = await this.HAS_PERMISSAO_PERFIL(false, false, false);

        try {
            const data = await this.service
                .list(
                    this.gridFilterKey,
                    this.gridSortKey,
                    this.gridSortOrder,
                    this.pags ? 1 : this.pageIndex,
                    this.pags ? 999999 : this.pageSize,
                )
                .withLoading()
                .resolveWithJSON<PaginationModel<GrupoModel>>();

            this.gridData = data.list;
            this.total = data.total;

            if (this.pags) {
                if (this.tipoDocGerar == 0) {
                    setTimeout(() => {
                        this.gridcomponent.gerarXls();
                    }, 2000);
                }

                if (this.tipoDocGerar == 1) {
                    setTimeout(() => {
                        this.gridcomponent.gerarPdf();
                    }, 2000);
                }

                if (this.tipoDocGerar == 2) {
                    setTimeout(() => {
                        this.gridcomponent.gerarImpressao();
                    }, 2000);
                }

                setTimeout(() => {
                    this.pags = false;
                }, 2000);
            }
        } catch {}
    }

    private onEditItem(model: GrupoModel) {
        this.$router.push("/grupo-edicao/" + model.id);
    }

    private async onRemoveItem(model: GrupoModel) {
        if (model.franquiaId == null && !this.isAdmin) {
            return this.$showError(
                this.$t("__.ts.erro"),
                this.$t("__.Crud.grupo.list_vue_html.sem_permissao_excluir_grupo"),
            );
        }

        const response = await this.$showExclusaoQuestion(`${this.$t("__.ts.desejaExcl")}${model.descricao}?`);

        if (response) {
            try {
                const sucesso = await this.service.delete(model.id).withLoading().resolveWithoutJSON();
                if (sucesso) {
                    this.load();
                    this.$showExclusao();
                }
            } catch {}
        }
    }

    private onCreateItem() {
        this.$router.push("/grupo-edicao");
    }

    private onChangeFilterKey(filterKey: string) {
        this.gridFilterKey = filterKey;
        this.load();
    }

    private onChangeSort(sortKey: string, sortOrder: string) {
        this.gridSortKey = sortKey;
        this.gridSortOrder = sortOrder;
        this.load();
    }

    private onChangePage(pageIndex: number) {
        this.pageIndex = pageIndex;
        this.load();
    }

    @Watch("pags")
    private onPags() {
        this.load();
    }

    private mounted() {
        this.gridcomponent = this.$refs.gridcomponent as Grid;
        this.load();
    }
}
