import Vue from "vue";

import actionBarComponent from "@/components/child/actionBar/actionBar.vue";
import cepFieldComponent from "@/components/child/form/cepfield.vue";
import cnpjComponent from "@/components/child/form/cnpj.vue";
import comboComponent from "@/components/child/form/combo.vue";
import cpfComponent from "@/components/child/form/cpf.vue";
import fieldsetComponent from "@/components/child/form/fieldset.vue";
import LoadingModalComponent from "@/components/child/loadingmodal/loadingmodal";
import loadingModalComponent from "@/components/child/loadingmodal/loadingmodal.vue";
import { Component, Watch } from "@/decorators";
import CepModel from "@/models/cepModel";
import EnumExtensions from "@/models/enum/extensions/enumExtensions";
import TiposPessoa from "@/models/enum/tiposPessoa";
import Ufs from "@/models/enum/ufs";
import OperadoraCartoesModel from "@/models/financeiro/operadoraCartoesModel";
import ValidationErrorModel from "@/models/validationErrorModel";
import CidadeService from "@/services/external/cidadeService";
import OperadoraCartoesService from "@/services/financeiro/operadoraCartoesService";
import ValidationErrorWrapper from "@/wrappers/validationErrorWrapper";

import "../crud.scss";

@Component({
    components: {
        comboComponent,
        fieldsetComponent,
        cpfComponent,
        cnpjComponent,
        cepFieldComponent,
        loadingModalComponent,
        actionBarComponent,
    },
})
export default class OperadoraCartoesEditComponent extends Vue {
    private service = new OperadoraCartoesService();
    private cidadeService = new CidadeService();
    private validationErrorWrapper = new ValidationErrorWrapper(this.$validator);
    private loadingModalComponent: LoadingModalComponent = null;
    model = new OperadoraCartoesModel();
    modelId = 0;

    tiposPessoa = TiposPessoa;
    tiposPessoaOptions: Array<Object> = [
        { text: "F\u00edsica", value: TiposPessoa.Fisica },
        { text: "Jur\u00eddica", value: TiposPessoa.Juridica },
    ];

    dadosCep: CepModel = new CepModel();
    ufOptions: Array<Object> = EnumExtensions.getNamesAndValuesOrderedByNames(Ufs);
    cidadesOptions: Array<Object> = [];

    constructor() {
        super();

        this.model.tipoPessoa = TiposPessoa.Juridica;
    }

    private async load() {
        this.model = new OperadoraCartoesModel();
        this.validationErrorWrapper.clearErrors();

        if (this.modelId > 0) {
            this.loadingModalComponent.showLoading();

            this.service
                .get(this.modelId)
                .then(response => {
                    return response.json() as Promise<OperadoraCartoesModel>;
                })
                .then(async data => {
                    this.model.updateFrom(data);
                    await this.onLoadCidades(this.model.estadoId);
                    this.loadingModalComponent.hide();
                })
                .catch(() => {
                    this.$router.back();
                    this.loadingModalComponent.hide();
                });
        } else {
            this.model.tipoPessoa = TiposPessoa.Juridica;
        }
    }

    private save() {
        this.$validator
            .validateAll()
            .then(isValid => {
                if (isValid) {
                    this.loadingModalComponent.showSaving();
                    this.service[!this.model.id ? "insert" : "update"](this.model)
                        .then(async response => {
                            this.loadingModalComponent.hide();

                            if (response.ok) {
                                await this.$showInclusaoUpdate(this.model.id);
                                this.$router.back();
                            } else {
                                return response.json() as Promise<ValidationErrorModel[]>;
                            }
                        })
                        .then(data => {
                            this.validationErrorWrapper.showErrors(data);
                        })
                        .catch(() => {
                            this.loadingModalComponent.hide();
                        });
                } else {
                    this.$focusErrorField();
                }
            })
            .catch(() => {});
    }

    private cancel() {
        this.$router.back();
    }

    private async onCepChanged(data: CepModel) {
        this.dadosCep = data;
        this.model.bairro = data.bairro;
        this.model.complemento = data.complemento;
        this.model.endereco = data.endereco;
        this.model.estadoId = data.uf;
        await this.onLoadCidades(this.model.estadoId);
        this.model.cidadeId = data.cidadeId;
    }

    private onCepInvalid() {
        this.$showInfo(this.$t("__.ts.pesqCEP"), this.$t("__.ts.pesqNaoEncResul"));
    }

    //@ts-ignore
    @Watch("model.tipoPessoa", { deep: true })
    private onTipoPessoaChanged(tipoPessoa: TiposPessoa) {
        switch (tipoPessoa) {
            case TiposPessoa.Fisica:
                this.model.nomeFantasia = null;
                this.model.cnpj = null;
                this.model.inscricaoEstadual = null;
                this.model.razaoSocial = null;
                break;
            case TiposPessoa.Juridica:
                this.model.nome = null;
                this.model.cpf = null;
                this.model.rg = null;
                break;
        }
    }

    private async onLoadCidades(estadoId: number) {
        try {
            this.cidadesOptions = [];
            if (estadoId) {
                this.cidadesOptions = await this.cidadeService.get(estadoId).withLoading();
            }
        } catch {}
    }

    private mounted() {
        this.loadingModalComponent = this.$refs.loadingModalComponent as LoadingModalComponent;

        if (this.$route.params.id) {
            this.modelId = +this.$route.params.id;
            this.load();
        }
    }
}
