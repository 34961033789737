import BaseModel from "./base/baseModel";

export default class ManipulacaoOrdemCancelamentoItemModel extends BaseModel {
    id?: number = null;
    manipulacaoOrdemCancelamentoId = 0;
    manipulacaoOrdemItemId?: number = null;
    quantidade?: number = null;
    unidadeMedidaId?: number = null;

    descricao: string = null;
    unidadeMedidaDescricao: string = null;
}
