import BaseModel from "./base/baseModel";
import GenerosPessoa from "./enum/generosPessoa";
import TiposPessoa from "./enum/tiposPessoa";
import Ufs from "./enum/ufs";
import VisitadorPrescritorModel from "./visitadorPrescritorModel";

export default class VisitadorModel extends BaseModel {
    constructor() {
        super();
    }

    id?: number = null;
    dataCriacao: Date = null;
    tipoPessoa: TiposPessoa = null;
    tipoPessoaDescricao: string = null;
    nome: string = null;
    nomeFantasia: string = null;
    observacao: string = null;
    cpf: string = null;
    rg: string = null;
    orgaoExpedidor: string = null;
    ufExpedidor?: Ufs = null;
    ufExpedidorDescricao: string = null;
    nomeRotulo: string = null;
    dataNascimento?: Date = null;
    genero?: GenerosPessoa = null;
    generoDescricao: string = null;
    razaoSocial: string = null;
    cnpj: string = null;
    inscricaoEstadual: string = null;
    inscricaoMunicipal: string = null;
    cep: string = null;
    estadoDescricao: string = null;
    estadoId?: Ufs = null;
    cidadeId?: number = null;
    cidade: string = null;
    endereco: string = null;
    bairro: string = null;
    numero: string = null;
    complemento: string = null;
    telefone: string = null;
    celular: string = null;
    email: string = null;
    prescritorId: number = null;
    prescritores?: Array<VisitadorPrescritorModel> = [];
    comissao?: number = 0;
    beneficioId?: number = null;
}
