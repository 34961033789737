import VisitadorModel from "../models/visitadorModel";
import nfetch from "../utils/events/nfetch";

import { IService } from "./base/iService";

export default class VisitadorService extends IService<VisitadorModel> {
    constructor() {
        super("Visitador");
    }

    public GetFromPrescritor(prescritorId: number): Promise<Response> {
        //@ts-ignore
        return nfetch(`/${this.controllerName}/GetFromPrescritor?prescritorId=${prescritorId}`, {
            credentials: "same-origin",
        })
            .then((response: Response) => {
                return response;
            })
            .catch(() => {});
    }

    public replicarVisitador(idVisitador: number, idFranquias: number[]): Promise<Response> {
        return nfetch(`/${this.controllerName}/ReplicarVisitador?idVisitador=${idVisitador}`, {
            method: "post",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json; charset=UTF-8",
            },
            body: JSON.stringify(idFranquias),
        });
    }
}
