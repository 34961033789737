import Vue from "vue";
import { mapMutations, mapState } from "vuex";

import gridComponent from "@/components/child/grid/grid.vue";
import { GridAction } from "@/components/child/grid/gridAction";
import { GridColors, GridColumn, GridColumnType } from "@/components/child/grid/gridColumn";
import { Component } from "@/decorators";
import PaginationPackModel from "@/models/auxiliar/paginationPackModel";
import NotaFiscalEntradaModel from "@/models/notaFiscalEntradaModel";
import PaginationModel from "@/models/paginationModel";
import NotaFiscalEntradaService from "@/services/notaFiscalEntradaService";
import Delay from "@/utils/common/delay";

import ImprimirRotuloPersonalizadoComponent from "../crud/rotulopersonalizado/imprimirRotuloComModal";
import imprimirRotuloPersonalizadoComponent from "../crud/rotulopersonalizado/imprimirRotuloComModal.vue";
import shortcutComponent from "../shortcut/shortcut.vue";

import "../crud/crud.scss";

@Component({
    components: {
        gridComponent,
        shortcutComponent,
        imprimirRotuloPersonalizadoComponent,
    },
    computed: mapState({
        loadingPack: state => state["paginationStatus"].loading,
        paginationParams: state => state["paginationStatus"].paginationParams,
    }),
    methods: mapMutations(["SET_PARAMS", "GET_PARAMS"]),
})
export default class NotaFiscalEntradaListComponent extends Vue {
    private service = new NotaFiscalEntradaService();
    private imprimirRotuloPersonalizadoComponent: ImprimirRotuloPersonalizadoComponent = null;

    public showInfos = true;

    startFilterKey = "";
    gridFilterKey = "";
    gridSortKey = "dataInclusao";
    gridSortOrder = "desc";
    gridData: Array<NotaFiscalEntradaModel> = [];
    get gridColumns(): Array<GridColumn> {
        return [
            new GridColumn("fornecedorNome", "Fornecedor", GridColumnType.String),
            new GridColumn("dataInclusao", "Data", GridColumnType.DateTime),
            new GridColumn("numero", "Numero", GridColumnType.String),
            new GridColumn("serie", "Série", GridColumnType.String),
            new GridColumn("valorTotal", "Valor", GridColumnType.Money),
        ];
    }

    extraActions: Array<object> = [];
    gridExtraActionsRotulo: Array<GridAction> = [];
    gridExtraActionsDevolucao: Array<GridAction> = [];

    pageIndex = 1;
    pageSize = 20;
    total = 0;

    private load() {
        this.service
            .list(this.gridFilterKey, this.gridSortKey, this.gridSortOrder, this.pageIndex, this.pageSize)
            .withLoading()
            .then(response => {
                return response.json() as Promise<PaginationModel<NotaFiscalEntradaModel>>;
            })
            .then(data => {
                this.gridData = data.list;
                this.total = data.total;
                this.pageIndex = data.pageIndex;
                this.pageSize = data.pageSize;

                //atualiza params no store
                if (data.total > 0)
                    this["SET_PARAMS"]({
                        routePath: this.$route.path,
                        filterKey: this.gridFilterKey,
                        sortKey: this.gridSortKey,
                        sortOrder: this.gridSortOrder,
                        pageIndex: this.pageIndex,
                        pageSize: this.pageSize,
                    });

                this.extraActions.splice(0);
                this.gridExtraActionsRotulo.splice(0);
                this.gridExtraActionsDevolucao.splice(0);

                for (let i = 0; i < this.gridData.length; i++) {
                    this.gridExtraActionsRotulo.push(
                        new GridAction("imprimir-rotulo", "Imprimir Rótulo", "fa fa-tag", GridColors.GREEN, true),
                    );

                    this.gridExtraActionsDevolucao.push(
                        new GridAction("devolver-itens", "Devolução de itens", "fa fa-undo", GridColors.RED, true),
                    );
                }

                this.extraActions.push(this.gridExtraActionsDevolucao);
                this.extraActions.push(this.gridExtraActionsRotulo);
            })
            .catch(error => {});
    }

    private onEditItem(model: NotaFiscalEntradaModel) {
        this.$router.push("/notafiscalentrada-edicao/" + model.id);
    }

    private onCreateItem() {
        this.$router.push("/notafiscalentrada-edicao");
    }

    private async onRemoveItem(model: NotaFiscalEntradaModel) {
        const response = await this.$showExclusaoQuestion(`${this.$t("__.ts.desejaExcl")}${model.numero}?`);

        if (response) {
            try {
                const sucesso = await this.service.delete(model.id).withLoading().resolveWithoutJSON();
                if (sucesso) {
                    this.load();
                    this.$showExclusao();
                }
            } catch {}
        }
    }

    private onChangeFilterKey(filterKey: string) {
        this.gridFilterKey = filterKey;
        this.load();
    }

    private onChangeSort(sortKey: string, sortOrder: string) {
        this.gridSortKey = sortKey;
        this.gridSortOrder = sortOrder;
        this.load();
    }

    private onChangePage(pageIndex: number) {
        this.pageIndex = pageIndex;
        this.load();
    }

    private onExtraAction(name: string, model: NotaFiscalEntradaModel) {
        if (name.trim() == "imprimir-rotulo") {
            this.onImprimirRotulo(model.id);
        }
        if (name.trim() == "devolver-itens") {
            this.$router.push(`/devolucaocompra-edicao/${model.id}`);
        }
    }

    private async onImprimirRotulo(id: number) {
        const data = await this.service.get(id).withLoading().resolveWithJSON<NotaFiscalEntradaModel>();
        if (!this.imprimirRotuloPersonalizadoComponent)
            this.imprimirRotuloPersonalizadoComponent = this.$refs
                .imprimirRotuloPersonalizadoComponent as ImprimirRotuloPersonalizadoComponent;

        const loteIds = data.notaFiscalEntradaItens.map(p => p.produtoLoteId);
        this.imprimirRotuloPersonalizadoComponent.addRotuloLoteByIds(loteIds);

        await this.imprimirRotuloPersonalizadoComponent.show({});
    }

    private async loadPagination() {
        await this["GET_PARAMS"](this.$route.path);
        while (this["loadingPack"]) {
            await Delay(50);
        }
        const paginationPack = this["paginationParams"] as PaginationPackModel;

        if (paginationPack != null) {
            this.gridFilterKey = paginationPack.filterKey;
            this.startFilterKey = this.gridFilterKey;
            this.gridSortKey = paginationPack.sortKey;
            this.gridSortOrder = paginationPack.sortOrder;
            this.pageIndex = paginationPack.pageIndex;
            this.pageSize = paginationPack.pageSize;
        }
    }

    private mounted() {
        Promise.all([this.loadPagination()])
            .withLoading()
            .then(result => this.load())
            .catch(error => {});
    }
}
