import BaseModel from "../base/baseModel";

import BeneficioFaixaModel from "./beneficioFaixaModel";

export default class BeneficioModel extends BaseModel {
    id?: number = null;
    descricao = "";
    beneficioId?: number = null;
    beneficioFaixas: Array<BeneficioFaixaModel> = [];
}
