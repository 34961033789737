import Vue from "vue";

import jsQR from "jsqr";

import gridComponent from "@/components/child/grid/grid.vue";
import ShortcutComponent from "@/components/parent/shortcut/shortcut";
import shortcutComponent from "@/components/parent/shortcut/shortcut.vue";
import { Component } from "@/decorators";

import "./view.scss";

@Component({
    components: {
        gridComponent,
        shortcutComponent,
    },
})
export default class QrCodeReaderComponent extends Vue {
    private shortcutComponent: ShortcutComponent = null;
    private video: HTMLVideoElement;
    private stream: MediaStream;

    private async onOpen() {
        this.stream = await navigator.mediaDevices.getUserMedia({ video: { facingMode: "environment" } }).withLoading();

        this.shortcutComponent.show();

        this.video.srcObject = this.stream;
        this.video.play();

        requestAnimationFrame(this.onFrame);
    }

    private onFrame() {
        if (this.video.readyState === this.video.HAVE_ENOUGH_DATA) {
            const canvas = document.getElementById("canvas") as HTMLCanvasElement;

            const context = canvas.getContext("2d");
            canvas.width = this.video.videoWidth;
            canvas.height = this.video.videoHeight;
            context.drawImage(this.video, 0, 0, canvas.width, canvas.height);

            const imageData = context.getImageData(0, 0, canvas.width, canvas.height);
            const code = jsQR(imageData.data, imageData.width, imageData.height);
            if (code) {
                this.$emit("onReadQrCode", code.data.replace("$M", ""));

                this.shortcutComponent.hide();
                return;
            }
        }

        requestAnimationFrame(this.onFrame);
    }

    private onClose() {
        const tracks = this.stream.getTracks();
        tracks.forEach(track => track.stop());
        this.video.srcObject = null;
    }

    private mounted() {
        this.video = this.$refs.video as HTMLVideoElement;
        this.shortcutComponent = this.$refs.shortcutComponent as ShortcutComponent;
    }
}
