import TiposMovimentacao from "../models/enum/tiposMovimentacao";
import NaturezaOperacaoModel from "../models/naturezaOperacaoModel";
import nfetch from "../utils/events/nfetch";

import { IService } from "./base/iService";

export default class NaturezaOperacaoService extends IService<NaturezaOperacaoModel> {
    constructor() {
        super("NaturezaOperacao");
    }

    public listByFranquiaId(
        franquiaId: number = null,
        tipo: TiposMovimentacao = TiposMovimentacao.Saida,
    ): Promise<Response> {
        return nfetch(`/${this.controllerName}/ListByFranquiaId?franquiaId=${franquiaId}&tipo=${tipo}`, {
            credentials: "same-origin",
        });
    }

    public getNaturezaDevolucaoByFornecedor(fornecedorId: number): Promise<Response> {
        return nfetch(`/${this.controllerName}/GetNaturezaDevolucaoByFornecedor?fornecedorId=${fornecedorId}`, {
            credentials: "same-origin",
        });
    }

    public getNaturezaByCliente(clienteId: number): Promise<Response> {
        return nfetch(`/${this.controllerName}/GetNaturezaByCliente?clienteId=${clienteId}`, {
            credentials: "same-origin",
        });
    }

    public getNaturezaByCodigo(codigo: number): Promise<Response> {
        return nfetch(`/${this.controllerName}/GetNaturezaByCodigo?codigo=${codigo}`, {
            credentials: "same-origin",
        });
    }
}
