import Vue from "vue";
import { mapGetters } from "vuex";

import fieldsetComponent from "@/components/child/form/fieldset.vue";
import moedaComponent from "@/components/child/form/moeda.vue";
import { Component } from "@/decorators";
import Especie from "@/models/enum/especiePagamento";
import MovimentacaoCaixaDetalhesModel from "@/models/financeiro/movimentacaoCaixaDetalhesModel";
import MovimentacaoCaixaModel from "@/models/financeiro/movimentacaoCaixaModel";
import PaginationModel from "@/models/paginationModel";
import FastActionService from "@/services/fastActionService";
import MovimentacaoCaixaService from "@/services/financeiro/movimentacaoCaixaService";
import { Getters } from "@/store/store";
import Delay from "@/utils/common/delay";

import ReforcoComponent from "../../../sessaoCaixa/reforco";
import reforcoComponent from "../../../sessaoCaixa/reforco.vue";
import SangriaComponent from "../../../sessaoCaixa/sangria";
import sangriaComponent from "../../../sessaoCaixa/sangria.vue";
import ShortcutComponent from "../../../shortcut/shortcut";
import { getFirstName, getDateString } from "../fastactionUtils";

import shortcutComponent from "./../../../shortcut/shortcut.vue";

import "./../fastactionsgrid.scss";

@Component({
    components: {
        fieldsetComponent,
        shortcutComponent,
        reforcoComponent,
        sangriaComponent,
        moedaComponent,
    },
    computed: {
        ...mapGetters(["VALIDAR_PERMISSAO_USUARIO"] as Getters),
    },
    methods: {
        getFirstName: getFirstName,
        getDateString: getDateString,
    },
})
export default class FastActionFinanceiroPanelComponent extends Vue {
    // State computed props
    VALIDAR_PERMISSAO_USUARIO: (telaDescricao: string, acaoController: string) => boolean;

    private service = new MovimentacaoCaixaService();
    private totalAReceber = 0;
    private totalAPagar = 0;
    private shortcutReforcoComponent: ShortcutComponent = null;
    private shortcutSangriaComponent: ShortcutComponent = null;
    private reforcoComponent: ReforcoComponent = null;
    private sangriaComponent: SangriaComponent = null;

    caixaSessao: string = null;
    idSessaoCaixa: number = null;

    metaMensal = 0; //"10.000,00"
    maiorMensal = 0;
    totalAnual = 0;
    sessaoCaixaId = 0;
    movimentoEspecie: number = null;

    totalDinheiro = 0;
    totalMov = 0;

    saldoCaixaInicial = 0;
    saldoCaixaAtual = 0;

    showReforco = false;
    showSangria = false;

    private movimentacoesOriginal: Array<MovimentacaoCaixaDetalhesModel> = [];
    private movimentacoes: Array<MovimentacaoCaixaDetalhesModel> = [];

    private get saldoInicial() {
        return 0;
    }

    private get totalReceber() {
        return this.totalAReceber;
    }

    private get totalPagar() {
        return this.totalAPagar;
    }

    private get saldoFinal() {
        return this.saldoInicial + this.totalAReceber - this.totalAPagar;
    }

    private async loadSaldoInicial() {
        try {
            const data = await this.service.getSaldoInicial().resolveWithJSON<number>();
            this.saldoCaixaInicial = data ? data : 0;
        } catch {
            this.saldoCaixaInicial = 0;
        }
    }

    private async loadInfosFinanc() {
        await new FastActionService()
            .getInfosFinanceiro()
            .then(result => {
                return result.json() as Promise<{
                    valorPagar?: number;
                    valorReceber?: number;
                    sessaoCaixaId?: number;
                }>;
            })
            .then(data => {
                this.totalAPagar = data.valorPagar;
                this.totalAReceber = data.valorReceber;
                this.sessaoCaixaId = data.sessaoCaixaId;
            })
            .catch(() => {});
    }

    private onVerMais() {
        this.$router.push("/sessaoCaixa-lista/");
    }

    gridFilterKey = "";
    gridSortKey = "dataLancamento";
    gridSortOrder = "desc";

    onTipoMovimento(tipoMovimento) {
        if (this.movimentoEspecie != tipoMovimento) this.movimentoEspecie = tipoMovimento;
        else this.movimentoEspecie = null;

        this.filtraTipoMovimento(this.movimentoEspecie);
    }

    private filtraTipoMovimento(tipoMovimento: number) {
        this.movimentacoes.splice(0);
        this.movimentacoes = null;
        this.movimentacoes = new Array<MovimentacaoCaixaDetalhesModel>();

        let movimentosFilter = new Array<MovimentacaoCaixaDetalhesModel>();
        if (tipoMovimento != null) {
            movimentosFilter = this.movimentacoesOriginal.filter(p => p.especie == tipoMovimento);
        } else {
            movimentosFilter = this.movimentacoesOriginal;
        }

        for (let i = 0; i < movimentosFilter.length; i++) {
            const movimento = new MovimentacaoCaixaDetalhesModel();
            movimento.updateFrom(movimentosFilter[i]);
            this.movimentacoes.push(movimento);
        }
    }

    private async loadMovimentacao() {
        try {
            const data = await this.service
                .list(
                    this.gridFilterKey,
                    this.gridSortKey,
                    this.gridSortOrder,
                    1,
                    20,
                    this.idSessaoCaixa,
                    this.movimentoEspecie,
                )
                .withLoading()
                .resolveWithJSON<PaginationModel<MovimentacaoCaixaModel>>();

            const aux = [];
            data.list.map(It => {
                It.movimentacoes.map(tI => {
                    aux.push(tI);
                });
            });

            this.movimentacoes = aux as Array<MovimentacaoCaixaDetalhesModel>;

            if (data.list.length > 0) {
                this.totalMov = data.list.map(p => p.movimentacoes.length).reduce((acc, p) => acc + p, 0);
            }

            const movDinheiro = data.list.filter(p => p.especie == Especie.Dinheiro);
            if (movDinheiro.length > 0) {
                this.totalDinheiro = movDinheiro[0].total;
            } else {
                this.totalDinheiro = 0;
            }

            //se possui transferencias, sao consederadas como entradas de dinheiro p caixa
            const movTransferencia = data.list.filter(p => p.especie == Especie.Transferencia);
            if (movTransferencia.length > 0) {
                this.totalDinheiro += movTransferencia.map(p => p.totalDifCaixa).reduce((acc, p) => acc + p, 0);
            }

            if (this.saldoCaixaAtual == 0) {
                this.saldoCaixaAtual = this.totalDinheiro + this.saldoCaixaInicial;
            }

            this.movimentacoesOriginal = new Array<MovimentacaoCaixaDetalhesModel>();
            for (let i = 0; i < this.movimentacoes.length; i++) {
                const movimento = new MovimentacaoCaixaDetalhesModel();
                movimento.updateFrom(this.movimentacoes[i]);
                this.movimentacoesOriginal.push(movimento);
            }
        } catch {}
    }

    private async gerarReforco() {
        if (this.idSessaoCaixa == 0) {
            return this.$showWarning(this.$t("__.ts.atencao"), this.$t("__.ts.caixaFechado"));
        }

        this.showReforco = true;

        while (!this.$refs.reforcoComponent) await Delay(5);
        this.reforcoComponent = this.$refs.reforcoComponent as ReforcoComponent;

        await this.loadDescricaoCaixa();

        this.shortcutReforcoComponent.title = this.$t("__.ts.gerarReforco") as string;
        this.reforcoComponent.clear(this.caixaSessao, this.saldoCaixaAtual, this.idSessaoCaixa);
        this.shortcutReforcoComponent.show();
    }

    private async gerarSangria() {
        if (this.idSessaoCaixa == 0) {
            return this.$showWarning(this.$t("__.ts.atencao"), this.$t("__.ts.caixaFechado"));
        }

        this.showSangria = true;

        while (!this.$refs.sangriaComponent) await Delay(5);
        this.sangriaComponent = this.$refs.sangriaComponent as SangriaComponent;

        //controle interno
        await this.loadDescricaoCaixa();

        this.shortcutSangriaComponent.title = this.$t("__.ts.gerarSangria") as string;

        while (!this.sangriaComponent.getCarregou()) await Delay(10);

        this.sangriaComponent.clear(this.idSessaoCaixa, this.caixaSessao, this.saldoCaixaAtual);
        this.shortcutSangriaComponent.show();
    }

    private async onConfirmReforco() {
        const sucesso = await this.reforcoComponent.onGerarReforco(this.idSessaoCaixa);
        if (sucesso) {
            this.reload();
            this.shortcutReforcoComponent.hide();
        }
    }

    private async onConfirmSangria() {
        const sucesso = await this.sangriaComponent.onGerarSangria();
        if (sucesso) {
            this.reload();
            this.shortcutSangriaComponent.hide();
        }
    }

    private async loadGetIdSessaoCaixa() {
        this.idSessaoCaixa = 0;
        try {
            const data = await this.service.getIdCaixaSessaoAberta().then(r => r.json() as Promise<number>);
            this.idSessaoCaixa = data;
        } catch {
            this.idSessaoCaixa = 0;
        }
    }

    private async loadDescricaoCaixa() {
        if (this.caixaSessao != null && this.caixaSessao != "") return;

        try {
            const data = await this.service.getDescricaoCaixaSessaoAberta().then(r => r.text() as Promise<string>);
            this.caixaSessao = data;
        } catch {
            this.caixaSessao = null;
        }
    }

    private reload() {
        this.saldoCaixaAtual = 0;

        return Promise.all([this.loadSaldoInicial(), this.loadMovimentacao()])
            .then(() => {
                this.mounted();
            })
            .catch(() => {});
    }

    private cancel() {
        this.$router.back();
    }

    private mounted() {
        this.shortcutReforcoComponent = this.$refs.shortcutReforcoComponent as ShortcutComponent;
        this.shortcutSangriaComponent = this.$refs.shortcutSangriaComponent as ShortcutComponent;

        Promise.all([
            this.loadGetIdSessaoCaixa(), //busca sessao caixa controla icone de fechado aberto e usada no parametro do reforco e sangria
            this.loadSaldoInicial(),
        ])
            .then(() => {
                this.loadMovimentacao();
            })
            .catch(() => {});
    }
}
