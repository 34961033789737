import { GridColumn, GridColumnType } from "@/components/child/grid/gridColumn";
import { Component } from "@/decorators";
import RacaAnimalModel from "@/models/paciente/racaAnimalModel";
import RacaAnimalService from "@/services/paciente/racaAnimalService";

import ListComponentBase from "../listComponentBase";

import "../crud.scss";

@Component
export default class RacaAnimalListComponent extends ListComponentBase<RacaAnimalModel> {
    get gridColumns(): Array<GridColumn> {
        return [
            new GridColumn("descricao", "Descrição", GridColumnType.String),
            new GridColumn("especieAnimalDescricao", "Espécie do Animal", GridColumnType.String),
        ];
    }

    private beforeMount() {
        this.setProps(new RacaAnimalService(), "racaAnimal", "descricao", true);
    }
}
