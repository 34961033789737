import CompraItemModel from "@/models/compraItemModel";
import CompraModel from "@/models/compraModel";
import nfetch from "@/utils/events/nfetch";

import { IService } from "./base/iService";

export default class CompraService extends IService<CompraModel> {
    constructor() {
        super("Compra");
    }

    public consolidar(filters: object): Promise<Response> {
        return nfetch(`/${this.controllerName}/Consolidar`, {
            method: "post",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json; charset=UTF-8",
            },
            body: JSON.stringify(filters),
        });
    }

    public insertUpdateCompraItem(model: CompraItemModel[], compraId: number): Promise<Response> {
        return nfetch(`/${this.controllerName}/AdicionarCotacao?compraId=${compraId}`, {
            method: "post",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json; charset=UTF-8",
            },
            body: JSON.stringify(model),
        });
    }

    public getMelhorCompra(compraId: number): Promise<Response> {
        return nfetch(`/${this.controllerName}/GetMelhorCompra?compraId=${compraId}`, {
            method: "get",
            credentials: "same-origin",
        });
    }

    public sendEmailFornecedor(compraId: number): Promise<Response> {
        return nfetch(`/${this.controllerName}/SendEmailFornecedor?compraId=${compraId}`, {
            method: "post",
            credentials: "same-origin",
        });
    }

    public getFornecedoresUltimaCompra(): Promise<Response> {
        return nfetch(`/${this.controllerName}/GetFornecedoresUltimaCompra`, {
            method: "get",
            credentials: "same-origin",
        });
    }

    public getCotacaoFornecedor(key: string): Promise<Response> {
        return nfetch(`/${this.controllerName}/GetCotacaoFornecedor?key=${key}`, {
            method: "get",
            credentials: "same-origin",
        });
    }

    public adicionarCotacaoFornecedor(model: CompraItemModel[], key: string): Promise<Response> {
        return nfetch(`/${this.controllerName}/AdicionarCotacaoFornecedor?key=${key}`, {
            method: "post",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json; charset=UTF-8",
            },
            body: JSON.stringify(model),
        });
    }

    public cancelarCompra(compraId: number): Promise<Response> {
        return nfetch(`/${this.controllerName}/CancelarCompra?compraId=${compraId}`, {
            method: "post",
            credentials: "same-origin",
        });
    }
}
