var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "shortcutComponent",
    {
      ref: "shortcutComponent",
      attrs: {
        modalClass: "ss-modal",
        btOkName: _vm.$t(
          "__.Components.parent.duplicacoes.list_vue_html.unificar"
        ),
        showAddNew: false,
      },
      on: { "selection-item": _vm.onUnificar },
    },
    [
      _c("div", { attrs: { slot: "component" }, slot: "component" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-sm-12" }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "tooltip",
                    rawName: "v-tooltip",
                    value: _vm.errors.first("prescritorIdOrigem"),
                    expression: "errors.first('prescritorIdOrigem')",
                  },
                ],
                staticClass: "form-group",
              },
              [
                _c(
                  "label",
                  {
                    staticClass: "control-label",
                    attrs: {
                      for: "prescritorIdOrigem",
                      title: _vm.$t("__.obrigatorio"),
                    },
                  },
                  [
                    _c("span", { staticStyle: { color: "red" } }, [
                      _vm._v("*"),
                    ]),
                    _vm._v(
                      "\n                        " +
                        _vm._s(
                          _vm.$t(
                            "__.Components.parent.duplicacoes.list_vue_html.prescritorOrigem"
                          )
                        ) +
                        "\n                    "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("search-combo-component", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required",
                      expression: "'required'",
                    },
                  ],
                  attrs: {
                    id: "prescritorIdOrigem",
                    name: "prescritorIdOrigem",
                    actionName: "prescritores",
                    addEditId: false,
                    addNew: false,
                    advancedSearch: true,
                    advancedSearchCampos: [
                      { campo: "text", descricao: "Nome" },
                      {
                        campo: "numeroRegistro",
                        descricao: "Número de registro",
                      },
                      {
                        campo: "tipoConselhoRegionalDescricao",
                        descricao: "Conselho Regional",
                      },
                      { campo: "cpf", descricao: "CNPJ/CPF" },
                    ],
                    customComboText: _vm.customComboTextPrescritor,
                    customView: true,
                    propDescription: "nome",
                    service: "Prescritor",
                  },
                  model: {
                    value: _vm.prescritorIdOrigem,
                    callback: function ($$v) {
                      _vm.prescritorIdOrigem = $$v
                    },
                    expression: "prescritorIdOrigem",
                  },
                }),
              ],
              1
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-sm-12" }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "tooltip",
                    rawName: "v-tooltip",
                    value: _vm.errors.first("prescritorIdDestino"),
                    expression: "errors.first('prescritorIdDestino')",
                  },
                ],
                staticClass: "form-group",
              },
              [
                _c(
                  "label",
                  {
                    staticClass: "control-label",
                    attrs: {
                      for: "prescritorIdDestino",
                      title: _vm.$t("__.obrigatorio"),
                    },
                  },
                  [
                    _c("span", { staticStyle: { color: "red" } }, [
                      _vm._v("*"),
                    ]),
                    _vm._v(
                      "\n                        " +
                        _vm._s(
                          _vm.$t(
                            "__.Components.parent.duplicacoes.list_vue_html.prescritorDestino"
                          )
                        ) +
                        "\n                    "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("search-combo-component", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required",
                      expression: "'required'",
                    },
                  ],
                  attrs: {
                    id: "prescritorIdDestino",
                    name: "prescritorIdDestino",
                    actionName: "prescritores",
                    addEditId: false,
                    addNew: false,
                    advancedSearch: true,
                    advancedSearchCampos: [
                      { campo: "text", descricao: "Nome" },
                      {
                        campo: "numeroRegistro",
                        descricao: "Número de registro",
                      },
                      {
                        campo: "tipoConselhoRegionalDescricao",
                        descricao: "Conselho Regional",
                      },
                      { campo: "cpf", descricao: "CNPJ/CPF" },
                    ],
                    customComboText: _vm.customComboTextPrescritor,
                    customView: true,
                    propDescription: "nome",
                    service: "Prescritor",
                  },
                  model: {
                    value: _vm.prescritorIdDestino,
                    callback: function ($$v) {
                      _vm.prescritorIdDestino = $$v
                    },
                    expression: "prescritorIdDestino",
                  },
                }),
              ],
              1
            ),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }