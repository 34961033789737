import BaseModel from "./base/baseModel";

export default class KitEmbalagemItemModel extends BaseModel {
    constructor() {
        super();
    }
    id?: number = null;
    kitEmbalagemId?: number = null;
    quantidade = 0;
    produtoId?: number = null;
    produtoDescricao: string = null;
    produtoValor?: number = null;
    produtoPadrao = false;
    produtoLoteId?: number = null;
}
