import { mapMutations } from "vuex";

import { GridColumn, GridColumnType } from "@/components/child/grid/gridColumn";
import { Component } from "@/decorators";
import UsuarioModel from "@/models/usuarioModel";
import UsuarioService from "@/services/usuarioService";
import { LoadListProps } from "@/store/loadListStore";
import { Mutations } from "@/store/store";

import ListComponentBase from "../listComponentBase";

import "../crud.scss";

@Component({
    methods: mapMutations(["LOAD_LIST"] as Mutations),
})
export default class UsuarioListComponent extends ListComponentBase<UsuarioModel> {
    // State computed props
    LOAD_LIST: (props: LoadListProps) => void;

    get gridColumns(): Array<GridColumn> {
        return [
            new GridColumn("nome", "Nome", GridColumnType.String),
            new GridColumn("email", "E-mail", GridColumnType.String),
            new GridColumn("ativo", "Ativo", GridColumnType.Boolean, false, false, "", "", false, false),
        ];
    }

    private beforeMount() {
        this.setProps(new UsuarioService(), "usuario", "nome");
    }

    public async onSucessoExclusao() {
        this.LOAD_LIST({ loadVendedores: true });
    }
}
