import Vue from "vue";
import { mapGetters } from "vuex";

import actionBarComponent from "@/components/child/actionBar/actionBar.vue";
import cepFieldComponent from "@/components/child/form/cepfield.vue";
import checkboxComponent from "@/components/child/form/checkbox.vue";
import cnpjComponent from "@/components/child/form/cnpj.vue";
import comboComponent from "@/components/child/form/combo.vue";
import fieldsetComponent from "@/components/child/form/fieldset.vue";
import { Component } from "@/decorators";
import CepModel from "@/models/cepModel";
import AreaAtuacao from "@/models/enum/areaAtuacao";
import EnumExtensions from "@/models/enum/extensions/enumExtensions";
import TipoCr from "@/models/enum/tipoCr";
import Ufs from "@/models/enum/ufs";
import UsoApp from "@/models/enum/usoApp";
import RedeModel from "@/models/rede/redeModel";
import CidadeService from "@/services/external/cidadeService";
import RedeService from "@/services/rede/redeService";
import { Getters } from "@/store/store";

import "../crud.scss";

@Component({
    components: {
        comboComponent,
        fieldsetComponent,
        cnpjComponent,
        cepFieldComponent,
        checkboxComponent,
        actionBarComponent,
    },
    computed: {
        ...mapGetters(["HAS_PERMISSAO_PERFIL"] as Getters),
    },
})
export default class RedeEditComponent extends Vue {
    // State computed props
    HAS_PERMISSAO_PERFIL: (isRede: boolean, isFranqueador: boolean, isSuporte: boolean) => Promise<boolean>;

    private service = new RedeService();
    private cidadeService = new CidadeService();

    model = new RedeModel();
    modelId = 0;
    isAdmin = false;

    dadosCep = new CepModel();
    ufOptions: Array<Object> = EnumExtensions.getNamesAndValuesOrderedByNames(Ufs);
    areaAtuacaoOptions: Array<Object> = EnumExtensions.getNamesAndValuesOrderedByNames(AreaAtuacao);
    usoAppOptions: Array<Object> = EnumExtensions.getNamesAndValuesOrderedByNames(UsoApp);
    tipoCrOptions: Array<Object> = EnumExtensions.getNamesAndValuesOrderedByNames(TipoCr);
    cidadesOptions: Array<Object> = [];
    cidadesEntregaOptions: Array<Object> = [];

    naturezaOperacaoOptions: Array<Object> = [
        { text: "Não Informado", value: 0 },
        { text: "1 Tributação No Municipio", value: 1 },
        { text: "2 Tributação Fora Do Municipio", value: 2 },
        { text: "3 Isenção", value: 3 },
        { text: "4 Imune", value: 4 },
        { text: "5 Exigibilidade Suspensa Decisao Judicial", value: 5 },
        { text: "6 Exigibilidade Suspensa Procedimento Administrativo", value: 6 },
    ];

    private async load() {
        this.model = new RedeModel();
        this.model.areaAtuacao = 0;

        if (this.modelId > 0) {
            try {
                const data = await this.service.get(this.modelId).withLoading().resolveWithJSON<RedeModel>();

                this.model.updateFrom(data);
                await this.onLoadCidades(this.model.estadoId);
            } catch {
                this.$router.back();
            }
        }
    }

    private async save() {
        try {
            const isValid = await this.$validator.validateAll();
            if (isValid) {
                const sucesso = await this.service[!this.model.id ? "insert" : "update"](this.model)
                    .withLoading()
                    .resolveWithoutJSON();

                if (sucesso) {
                    await this.$showInclusaoUpdate(this.model.id);
                    this.$router.back();
                }
            } else {
                this.$focusErrorField();
            }
        } catch {}
    }

    private cancel() {
        this.$router.back();
    }

    private async onCepChanged(data: CepModel) {
        this.dadosCep = data;
        this.model.bairro = data.bairro;
        this.model.complemento = data.complemento;
        this.model.logradouro = data.endereco;
        this.model.estadoId = data.uf;
        await this.onLoadCidades(this.model.estadoId);
        this.model.cidadeId = data.cidadeId;
    }

    private async geraBaseModelo() {
        try {
            const response = await this.service.replicarBaseModelo(this.model).withLoading().resolveWithResponse();

            if (response.ok) {
                await this.$showSuccess(this.$t("__.ts.baseModelRede"), this.$t("__.ts.replicaBaseSucess"));
                this.$router.back();
            }
        } catch {}
    }

    private onCepInvalid() {
        this.$showInfo(this.$t("__.ts.pesqCEP"), this.$t("__.ts.pesqNaoEncResul"));
    }

    private async onLoadCidades(estadoId: number) {
        try {
            this.cidadesOptions = [];
            if (estadoId) {
                this.cidadesOptions = await this.cidadeService.get(estadoId).withLoading();
            }
        } catch {}
    }

    private async mounted() {
        this.isAdmin = await this.HAS_PERMISSAO_PERFIL(true, false, false);

        if (this.$route.params.id) {
            this.modelId = +this.$route.params.id;
            this.load();
        }
    }
}
