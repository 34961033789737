var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "crud crud-edit" },
    [
      _c("actionBarComponent", {
        attrs: {
          title: _vm.$t(
            "__.Crud.formulapadraomanipulacao.edit_vue_html.frmlPadrGerarManipu"
          ),
          showSave: false,
        },
      }),
      _vm._v(" "),
      _c(
        "form",
        {
          attrs: { novalidate: "novalidate" },
          on: {
            submit: function ($event) {
              $event.preventDefault()
              _vm.save()
            },
          },
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.manipulacoesGeradas,
                  expression: "!manipulacoesGeradas",
                },
              ],
              staticClass: "row",
            },
            [
              _c("div", { staticClass: "col-sm-12" }, [
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c(
                      "label",
                      {
                        staticClass: "control-label",
                        attrs: { for: "formaFarmaceuticaId" },
                      },
                      [
                        _vm._v(
                          "\n                        " +
                            _vm._s(
                              _vm.$t(
                                "__.Crud.formulapadraomanipulacao.edit_vue_html.frmlPadr"
                              )
                            ) +
                            "\n                    "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("combo-component", {
                      attrs: {
                        id: "formulaPadraoId",
                        name: "formulaPadraoId",
                        data: _vm.formulasPadraoOptions,
                      },
                      model: {
                        value: _vm.formulaPadraoId,
                        callback: function ($$v) {
                          _vm.formulaPadraoId = $$v
                        },
                        expression: "formulaPadraoId",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]
          ),
          _vm._v(" "),
          _c("buttonIncluirComponent", {
            on: {
              click: function ($event) {
                _vm.adicionarItem().withLoading()
              },
            },
          }),
          _vm._v(" "),
          _vm._l(_vm.model, function (item, itemComponent) {
            return _c(
              "div",
              { key: itemComponent },
              [
                _c("formulaPadraoManipulacaoItemComponent", {
                  ref: "itemComponent",
                  refInFor: true,
                  attrs: { index: itemComponent },
                  on: { "remove-item": _vm.onRemoveItem },
                  model: {
                    value: _vm.model[itemComponent],
                    callback: function ($$v) {
                      _vm.$set(_vm.model, itemComponent, $$v)
                    },
                    expression: "model[itemComponent]",
                  },
                }),
              ],
              1
            )
          }),
          _vm._v(" "),
          _c(
            "fieldset",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.model.length > 0,
                  expression: "model.length > 0",
                },
              ],
            },
            [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-sm-12 newbtn" }, [
                  _c("div", { staticClass: "form-group" }, [
                    !_vm.manipulacoesGeradas
                      ? _c(
                          "button",
                          {
                            staticClass: "btn btn-primary",
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                _vm.onGerarOrdensManipulacao().withLoading()
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                            " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Crud.formulapadraomanipulacao.edit_vue_html.gerarManipulacao"
                                  )
                                ) +
                                "\n                        "
                            ),
                          ]
                        )
                      : _c(
                          "button",
                          {
                            staticClass: "btn btn-secondary",
                            attrs: { type: "button" },
                            on: { click: _vm.sair },
                          },
                          [
                            _vm._v(
                              "\n                            " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Crud.formulapadraomanipulacao.edit_vue_html.sair"
                                  )
                                ) +
                                "\n                        "
                            ),
                          ]
                        ),
                  ]),
                ]),
              ]),
            ]
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }