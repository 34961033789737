export type WebSocketState = "CLOSED" | "CLOSING" | "CONNECTING" | "OPEN";

export default class WebSocketService {
    // API privada
    // =========================================================================

    private socket: WebSocket = null;
    private state: WebSocketState = null;
    private url: string = null;

    private messageQueue: string[] = [];

    // Promise para esperar o recebimento de uma mensagem
    private get messagePromise(): Promise<string> {
        return new Promise(resolve => this.socket.addEventListener("message", e => resolve(e.data), { once: true }));
    }

    private async open(url: string) {
        this.url = url;
        console.log(`Conectando ao WebSocket ${url}`);
        this.socket = new WebSocket(url);
        this.socket.onopen = () => {
            this.updateState();
            console.log(`Aberta a conexão com o WebSocket ${url}`);
        };
        this.socket.onclose = e => {
            this.updateState();
            console.log(`Fechada a conexão com o WebSocket ${url}. Code: ${e.code}  Reason: ${e.reason}`);
        };
        this.socket.onerror = () => this.updateState();
        this.socket.onmessage = e => this.messageQueue.push(e.data);
    }

    constructor(url: string) {
        this.open(url);
    }

    private logErrorClosedConn() {
        console.log("A conexão com o WebSocket não está aberta.");
    }

    private async updateState() {
        if (!this.socket) {
            console.error("Erro ao atualizar state do WebSocket (socket == null).");
            return;
        }
        const currState = WebSocket[this.socket.readyState];
        currState != this.state && console.log(`State changed to ${this.state}`);
        this.state = currState;
    }

    // API pública
    // =========================================================================

    /** Enviar string `str` atraves do WebSocket. */
    public async send(str: string) {
        if (!this.socket || this.socket.readyState !== WebSocket.OPEN) {
            this.logErrorClosedConn();
            return;
        }
        this.socket.send(str);
    }

    /** Receber string atraves do WebSocket. */
    public async receive(): Promise<string> {
        if (!this.socket || this.socket.readyState !== WebSocket.OPEN) {
            this.logErrorClosedConn();
            return;
        }
        if (this.messageQueue.length == 0) {
            await this.messagePromise;
        }
        return this.messageQueue.shift();
    }

    public async isConnected(): Promise<boolean> {
        if (!this.isOpen()) {
            this.open(this.url);
        }
        if (this.isOpen()) {
            return true;
        }
        return false;
    }

    private isOpen(): boolean {
        return this.socket && this.socket.readyState === WebSocket.OPEN;
    }

    /**
     * Similar a `receive()`, mas converte a string para JSON antes de retornar.
     * É responsabilidade do desenvolvedor especificar corretamente os tipos de
     * dados esperados.
     */
    public async receiveJSON<T>(): Promise<T> {
        const str = await this.receive();
        return JSON.parse(str);
    }

    /** Fechar conexão. TODO: se possível, remover a necessidade de chamar este método. */
    public async close() {
        if (!this.socket || this.socket.readyState !== WebSocket.OPEN) {
            this.logErrorClosedConn();
            return;
        }
        this.socket.close(1000, "Closing from client");
    }
}
