var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "table-body crud-item-grid" }, [
      _c("table", { staticClass: "table table-condensed table-striped" }, [
        _c("thead", [
          _c("tr", [
            _c("th", { staticClass: "col-sm-4" }, [
              _vm._v(
                "\n                        " +
                  _vm._s(
                    _vm.$t(
                      "__.Components.parent.fracionaEstoque.kitEmbalagens_vue_html.embalagem"
                    )
                  ) +
                  "\n                    "
              ),
            ]),
            _vm._v(" "),
            _c("th", { staticClass: "col-sm-3" }, [
              _vm._v(
                "\n                        " +
                  _vm._s(
                    _vm.$t(
                      "__.Components.parent.fracionaEstoque.kitEmbalagens_vue_html.lote"
                    )
                  ) +
                  "\n                    "
              ),
            ]),
            _vm._v(" "),
            _c("th", { staticClass: "col-sm-2" }, [
              _vm._v(
                "\n                        " +
                  _vm._s(
                    _vm.$t(
                      "__.Components.parent.fracionaEstoque.kitEmbalagens_vue_html.qtd"
                    )
                  ) +
                  "\n                    "
              ),
            ]),
            _vm._v(" "),
            _c("th", { staticClass: "col-sm-2" }, [
              _vm._v(
                "\n                        " +
                  _vm._s(
                    _vm.$t(
                      "__.Components.parent.fracionaEstoque.kitEmbalagens_vue_html.total"
                    )
                  ) +
                  "\n                    "
              ),
            ]),
            _vm._v(" "),
            _c("th", { staticClass: "text-center" }, [
              _vm._v(
                "\n                        " +
                  _vm._s(
                    _vm.$t(
                      "__.Components.parent.fracionaEstoque.kitEmbalagens_vue_html.acao"
                    )
                  ) +
                  "\n                    "
              ),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c(
          "tbody",
          [
            _vm._l(_vm.model.itensEmbalagem, function (item, index) {
              return [
                _c("item-embalagem-component", {
                  key: item.id,
                  attrs: {
                    item: item,
                    index: index,
                    produtoOptions: _vm.produtoOptions,
                    alteracao: _vm.alteracao,
                    desativaCampos: _vm.desativaCampos,
                    itensVenda: _vm.itensVenda,
                    itemFracionamentoId: _vm.model.id,
                  },
                  on: {
                    "change-item": _vm.onChangeItem,
                    "remove-item": _vm.onRemoveItem,
                  },
                }),
              ]
            }),
          ],
          2
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }