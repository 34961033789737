import Vue from "vue";
import { mapState, mapMutations } from "vuex";

import Moment from "moment";

import ShortcutComponent from "@/components/parent/shortcut/shortcut";
import shortcutComponent from "@/components/parent/shortcut/shortcut.vue";
import EnderecoEntregaComponent from "@/components/parent/venda/enderecoEntrega";
import enderecoEntregaComponent from "@/components/parent/venda/enderecoEntrega.vue";
import VendaExpedicaoListComponent from "@/components/parent/venda/listExpedicao";
import vendaExpedicaoListComponent from "@/components/parent/venda/listExpedicao.vue";
import { Component } from "@/decorators";
import SituacoesVenda from "@/models/enum/situacoesVenda";
import VendaModel from "@/models/vendaModel";
import VendaExpedicaoService from "@/services/venda/vendaExpedicaoService";
import VendaService from "@/services/vendaService";
import { FastActionLastPack, FastActionList, FastActionPack } from "@/store/fastActionStore";
import { AppState, Mutations } from "@/store/store";
import { editDateWithTime } from "@/utils/common/date";
import Delay from "@/utils/common/delay";

import "./../fastactionsgrid.scss";

@Component({
    components: {
        vendaExpedicaoListComponent,
        shortcutComponent,
        enderecoEntregaComponent,
    },
    computed: mapState({
        fastActionLists: (state: AppState) => state.fastAction.fastActionLists,
        fastActionPack: (state: AppState) => state.fastAction.fastActionPack,
    }),
    methods: {
        ...mapMutations(["SET_LISTSPACK", "SET_LASTCOUNTPACK"] as Mutations),
        editDateWithTime: editDateWithTime,
    },
})
export default class FastActionExpedicaoComponent extends Vue {
    // State computed props
    fastActionLists: FastActionList;
    fastActionPack: FastActionPack;
    SET_LISTSPACK: (data: FastActionList) => void;
    SET_LASTCOUNTPACK: (data: FastActionLastPack) => void;

    private service = new VendaService();
    private vendaExpedicaoService = new VendaExpedicaoService();

    private vendaExpedicaoListComponent: VendaExpedicaoListComponent = null;
    private shortcutComponent: ShortcutComponent = null;
    private shortcutComponentEntrega: ShortcutComponent = null;
    private enderecoEntregaComponent: EnderecoEntregaComponent = null;

    private vendasExpedicao: Array<VendaModel> = [];

    private loadEnderecoEntregaComponent = false;
    private loadVendaExpedicaoListComponent = false;

    private async load(forcarLoad = true) {
        if (this.fastActionLists.expedicao.length > 0 && !forcarLoad) {
            this.vendasExpedicao = this.fastActionLists.expedicao;
        } else {
            this.vendasExpedicao = await this.vendaExpedicaoService.listExpedicaoHome().resolveWithJSON<VendaModel[]>();
            this.SET_LISTSPACK({ expedicao: this.vendasExpedicao });
        }
    }

    private getClass(venda: VendaModel) {
        const hoje = new Date();
        const timeDiff = Moment(hoje).diff(venda.dataAprovacao);
        const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));

        if (diffDays >= 4) {
            return "ExcRed";
        }
        if (diffDays <= 1 && venda.situacao == SituacoesVenda.Expedicao) {
            return "ExcGreen";
        }
        return "";
    }

    private getFirstName(str: string, entreParenteses: boolean) {
        const strFirstName = str.substr(0, str.indexOf(" "));
        if (strFirstName.trim() != "") {
            return (entreParenteses ? "(" : "") + strFirstName.trim() + (entreParenteses ? ")" : "");
        }
        return str;
    }

    private async onVisualizar(venda: VendaModel) {
        this.$router.push("/venda-edicao/" + venda.id);
    }

    private async onDadosExpedicao(venda: VendaModel) {
        this.loadEnderecoEntregaComponent = true;

        while (!this.$refs.enderecoEntregaComponent) await Delay(5);
        this.enderecoEntregaComponent = this.$refs.enderecoEntregaComponent as EnderecoEntregaComponent;

        this.enderecoEntregaComponent.setVenda({ ...venda } as VendaModel);
        this.shortcutComponentEntrega.title = this.$t("__.ts.infoEntrega") as string;
        this.shortcutComponentEntrega.show();
    }

    private async onFinaliza(venda: VendaModel) {
        const response = await this.$showQuestion(this.$t("__.ts.atencao"), this.$t("__.ts.expedFinalizMesmoSemDados"));
        if (response) {
            venda.situacao = SituacoesVenda.EmFaturamento;
            const retorno = await this.service.updateExpedicao(venda, true).withLoading();
            if (retorno.ok) {
                this.$showSuccess(this.$t("__.ts.concluido"), this.$t("__.ts.expedFoiFinaliz"));
                await this.load();
            } else {
                this.$showError(this.$t("__.ts.erro"), this.$t("__.ts.ocorrErroAoInclRegis"));
            }
        }
    }

    private async onVerMais() {
        this.loadVendaExpedicaoListComponent = true;

        while (!this.$refs.vendaExpedicaoListComponent) await Delay(5);
        this.vendaExpedicaoListComponent = this.$refs.vendaExpedicaoListComponent as VendaExpedicaoListComponent;

        this.vendaExpedicaoListComponent.load();
        this.shortcutComponent.title = this.$t("__.ts.vendasEmExped") as string;
        this.shortcutComponent.show();
    }

    private onConfirmShortExpedicao() {
        this.shortcutComponent.hide();
    }

    private async onSalvarExpedicao() {
        const venda = this.enderecoEntregaComponent.getVenda();
        venda.situacao = SituacoesVenda.EmFaturamento;

        const response = await this.service.updateExpedicao(venda).withLoading();
        if (response.ok) {
            this.$showSuccess(this.$t("__.ts.concluido"), this.$t("__.ts.expedFoiFinaliz"));
            this.shortcutComponentEntrega.hide();
            this.enderecoEntregaComponent.clear();
            await this.load();
        } else {
            this.$showError(this.$t("__.ts.erro"), this.$t("__.ts.ocorrErroAoInclRegis"));
        }
    }

    private mounted() {
        this.shortcutComponent = this.$refs.shortcutComponent as ShortcutComponent;
        this.vendaExpedicaoListComponent = this.$refs.vendaExpedicaoListComponent as VendaExpedicaoListComponent;
        this.shortcutComponentEntrega = this.$refs.shortcutComponentEntrega as ShortcutComponent;
        this.enderecoEntregaComponent = this.$refs.enderecoEntregaComponent as EnderecoEntregaComponent;

        Promise.all([this.load()])
            .then(() => {
                this.SET_LASTCOUNTPACK({ expedicao: Number(this.fastActionPack.countPack.expedicao) });
            })
            .catch(() => {});
    }
}
