import TransportadorModel from "../../models/transporte/transportadorModel";
import nfetch from "../../utils/events/nfetch";
import { IService } from "../base/iService";

export default class TransportadorService extends IService<TransportadorModel> {
    constructor() {
        super("Transportador");
    }

    public async getByTransportadora(id: number): Promise<Response> {
        return await nfetch(`/${this.controllerName}/GetBytransportadora?transportadoraId=${id}`, {
            credentials: "same-origin"
        });
    }
}