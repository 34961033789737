import BaseModel from "./../base/baseModel";
import EspeciePagamento from "./../enum/especiePagamento";

export default class SessaoCaixaDetalhesFechamentoModel extends BaseModel {
    sessaoCaixaId: number = null;
    especie: EspeciePagamento = null;
    entradas: number = null;
    saidas: number = null;
    totalUsuario: number = null;
    justificativa: string = null;
    divergente: boolean = null;
}
