var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "crud crud-edit" },
    [
      _c("actionBarComponent", {
        attrs: {
          title: _vm.$t(
            "__.Crud.balancoCompleto.view_vue_html.balancoCompletoGeracao"
          ),
          saveText: _vm.$t(
            "__.Crud.balancoCompleto.view_vue_html.balancoCompletoGeracao"
          ),
        },
        on: { onSave: _vm.save },
      }),
      _vm._v(" "),
      _c(
        "form",
        {
          attrs: { novalidate: "novalidate" },
          on: {
            submit: function ($event) {
              $event.preventDefault()
              _vm.save()
            },
          },
        },
        [
          _c("fieldset", [
            _c("div", { attrs: { slot: "rows" }, slot: "rows" }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-sm-4" }, [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "tooltip",
                          rawName: "v-tooltip",
                          value: _vm.errors.first("ano"),
                          expression: "errors.first('ano')",
                        },
                      ],
                      staticClass: "form-group",
                    },
                    [
                      _c(
                        "label",
                        {
                          staticClass: "control-label",
                          attrs: {
                            for: "ano",
                            title: _vm.$t("__.obrigatorio"),
                          },
                        },
                        [
                          _c("span", { staticStyle: { color: "red" } }, [
                            _vm._v("*"),
                          ]),
                          _vm._v(
                            "\n                                " +
                              _vm._s(
                                _vm.$t(
                                  "__.Crud.balancoCompleto.view_vue_html.ano"
                                )
                              ) +
                              "\n                            "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.ano,
                            expression: "ano",
                          },
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required",
                            expression: "'required'",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          autocomplete: "off",
                          type: "number",
                          id: "ano",
                          name: "ano",
                          maxlength: "4",
                        },
                        domProps: { value: _vm.ano },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.ano = $event.target.value
                          },
                        },
                      }),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-sm-4" }, [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "tooltip",
                          rawName: "v-tooltip",
                          value: _vm.errors.first("tipoPeriodoId"),
                          expression: "errors.first('tipoPeriodoId')",
                        },
                      ],
                      staticClass: "form-group",
                    },
                    [
                      _c(
                        "label",
                        {
                          staticClass: "control-label",
                          attrs: {
                            for: "tipoPeriodoId",
                            title: _vm.$t("__.obrigatorio"),
                          },
                        },
                        [
                          _c("span", { staticStyle: { color: "red" } }, [
                            _vm._v("*"),
                          ]),
                          _vm._v(
                            "\n                                " +
                              _vm._s(
                                _vm.$t(
                                  "__.Crud.balancoCompleto.view_vue_html.tipoPeriodo"
                                )
                              ) +
                              "\n                            "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("combo-component", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required",
                            expression: "'required'",
                          },
                        ],
                        attrs: {
                          id: "tipoPeriodoId",
                          name: "tipoPeriodoId",
                          data: _vm.tipoPeriodoOptions,
                          type: "text",
                        },
                        model: {
                          value: _vm.tipoPeriodoId,
                          callback: function ($$v) {
                            _vm.tipoPeriodoId = $$v
                          },
                          expression: "tipoPeriodoId",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("fieldset", [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-sm-12 newbtn" }, [
                _c("div", { staticClass: "form-group" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-success",
                      attrs: { type: "submit" },
                    },
                    [
                      _vm._v(
                        "\n                            " +
                          _vm._s(_vm.$t("__.Crud.gerarArquivo")) +
                          "\n                        "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-secondary",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          _vm.cancel()
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n                            " +
                          _vm._s(_vm.$t("__.Crud.voltar")) +
                          "\n                        "
                      ),
                    ]
                  ),
                ]),
              ]),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }