import Vue from "vue";
import { mapGetters } from "vuex";

import buttonScComponent from "@/components/child/form/buttonSc.vue";
import dateTimePickerComponent from "@/components/child/form/datetimepicker.vue";
import fieldsetComponent from "@/components/child/form/fieldset.vue";
import filePickerComponent from "@/components/child/form/filepicker.vue";
import { Component, Prop, Watch } from "@/decorators";
import ReceitaArquivoModel from "@/models/receitaArquivoModel";
import ReceitaArquivoService from "@/services/receitaArquivoService";
import { Getters } from "@/store/store";
import { editDate, editDateWithTime } from "@/utils/common/date";
import FileHelper from "@/utils/common/fileHelper";
import { DocumentFileType, ImageFileType } from "@/utils/fileExtensions";

import "../crud/crud.scss";

@Component({
    components: {
        filePickerComponent,
        fieldsetComponent,
        buttonScComponent,
        dateTimePickerComponent,
    },
    computed: {
        ...mapGetters(["VALIDAR_PERMISSAO_SOMENTE_CONSULTA"] as Getters),
    },
})
export default class UploadReceitaComponent extends Vue {
    // State computed props
    VALIDAR_PERMISSAO_SOMENTE_CONSULTA: (telaDescricao: string) => boolean;

    private uploadService = new ReceitaArquivoService();

    model = new ReceitaArquivoModel();
    selectedFile: File = null;
    uploadedFiles: ReceitaArquivoModel[] = [];
    filesToUploadOrcamento: { file: File; model: ReceitaArquivoModel }[] = [];
    somenteConsultaVenda = false;

    @Prop(Number) vendaId: number;

    private async onView(fileUpload: ReceitaArquivoModel) {
        const response = await this.uploadService.get(fileUpload.id);

        FileHelper.openBlank(response);
    }

    private async onDownload(fileUpload: ReceitaArquivoModel) {
        const response = await this.uploadService.get(fileUpload.id);

        const blob = await response.blob();
        const fileName = response.headers.get("Content-Disposition").split("filename=")[1].split(";")[0];
        FileHelper.download(blob, fileName);
    }

    private async onDelete(fileUpload: ReceitaArquivoModel) {
        const response = await this.$showExclusaoQuestion(`${this.$t("__.ts.desejaExcl")}?`);

        if (response) {
            if (fileUpload.id) {
                try {
                    const sucesso = await this.uploadService.delete(fileUpload.id).withLoading().resolveWithoutJSON();
                    if (sucesso) {
                        this.load();
                        this.$showExclusao();
                    }
                } catch {}
            } else {
                this.filesToUploadOrcamento = this.filesToUploadOrcamento.filter(p => p.model != fileUpload);
                this.uploadedFiles = this.uploadedFiles.filter(p => p != fileUpload);
            }
        }
    }

    private async load() {
        try {
            if (this.vendaId) {
                this.uploadedFiles = await this.uploadService
                    .listFiles(this.vendaId)
                    .resolveWithJSON<ReceitaArquivoModel[]>();
            }
        } catch {}
    }

    private clear() {
        this.model = new ReceitaArquivoModel();
        this.model.descricao = "Receita";
        this.model.vendaId = this.vendaId;

        this.selectedFile = null;
    }

    private editaDateInclusao(date: Date) {
        return editDateWithTime(date);
    }

    private editaDate(date: Date) {
        return editDate(date);
    }

    @Watch("selectedFile")
    private async onChangeSelectedFile() {
        if (!this.selectedFile) {
            return;
        }

        if (!this.selectedFile.isImage() && !this.selectedFile.isDocument()) {
            const allowedFileTypes = [...Object.keys(DocumentFileType), ...Object.keys(ImageFileType)];
            this.$showError(
                this.$t("__.ts.tipoArquivoInvalido"),
                `${this.$t("__.ts.exemplosTipoArquivoPermitido")}: ${allowedFileTypes.join(", ")}`,
            );
            this.selectedFile = null;
            return;
        }

        if (this.vendaId) {
            await this.upload(this.vendaId);
        } else {
            const model = new ReceitaArquivoModel();
            model.updateFrom(this.model);
            model.dataInclusao = new Date();

            this.filesToUploadOrcamento.push({ file: this.selectedFile, model });
            this.uploadedFiles.push(model);
            this.selectedFile = null;
        }
    }

    public async upload(vendaId: number) {
        if (!this.selectedFile && this.filesToUploadOrcamento.length == 0) {
            return null;
        }
        if (this.filesToUploadOrcamento.length > 0) {
            for await (const file of this.filesToUploadOrcamento) {
                file.model.vendaId = vendaId;
                await this.insertFile(file.model, file.file);
            }
        } else {
            this.model.vendaId = vendaId;

            const sucesso = await this.insertFile(this.model, this.selectedFile);
            if (sucesso) {
                this.clear();
                this.load();
            }
        }
    }

    private async insertFile(model: ReceitaArquivoModel, file: File) {
        try {
            await this.uploadService.insertFile(model, file).resolveWithoutJSON();

            return true;
        } catch {}
        return false;
    }

    private mounted() {
        this.somenteConsultaVenda = this.VALIDAR_PERMISSAO_SOMENTE_CONSULTA("vendas");

        Promise.all([this.load()])
            .withLoading()
            .then(() => {
                this.clear();
            })
            .catch(() => {});
    }
}
