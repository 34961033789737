var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row ajusteCancelarVenda" }, [
    _c("div", { staticClass: "col-sm-6" }, [
      _c("span", [_vm._v(_vm._s(_vm.item.descricao))]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-sm-2" }, [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.item.quantidade,
            expression: "item.quantidade",
          },
        ],
        staticClass: "form-control",
        staticStyle: { width: "70%", display: "inline-block" },
        attrs: { type: "text", id: "quantidade", name: "quantidade" },
        domProps: { value: _vm.item.quantidade },
        on: {
          input: function ($event) {
            if ($event.target.composing) {
              return
            }
            _vm.$set(_vm.item, "quantidade", $event.target.value)
          },
        },
      }),
      _vm._v(" "),
      _c("span", { staticStyle: { width: "30%" } }, [
        _vm._v(_vm._s(_vm.item.unidadeMedidaDescricao)),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }