var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "crud-item-grid-container",
      staticStyle: { "padding-left": "0px !important" },
    },
    [
      _c(
        "div",
        {
          class:
            "col-sm-" +
            [_vm.itemType == 0 ? "4" : _vm.itemType == 2 ? "2" : "6"],
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "tooltip",
                  rawName: "v-tooltip",
                  value: _vm.errors.first("produtoId"),
                  expression: "errors.first('produtoId')",
                },
              ],
              class: [
                "form-group",
                { colorFormulaBase: _vm.isFormulaPadrao },
                { colorFlavorizante: _vm.isFlavorizante },
                { colorAssociado: _vm.isAssociado },
                { colorFormaFarmaceutica: _vm.isFormaFarmaceutica },
              ],
            },
            [
              _vm.itemType == 2
                ? _c("combo-component", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'",
                      },
                    ],
                    attrs: {
                      name: "capsulaId",
                      disabled:
                        _vm.manipulacaoContext.isDesabilitado ||
                        _vm.isDisabledEdicaoPreVenda,
                      exibeDataCombo:
                        _vm.manipulacaoContext.isDesabilitado ||
                        _vm.isDisabledEdicaoPreVenda,
                      id: "capsulaId" + _vm.index,
                      data: _vm.capsulasOptions,
                    },
                    model: {
                      value: _vm.model.capsulaId,
                      callback: function ($$v) {
                        _vm.$set(_vm.model, "capsulaId", $$v)
                      },
                      expression: "model.capsulaId",
                    },
                  })
                : _vm.isFormulaPadrao
                ? _c("combo-component", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'",
                      },
                    ],
                    attrs: {
                      id: "produtoId",
                      name: "produtoId",
                      disabled:
                        _vm.manipulacaoContext.isDesabilitado ||
                        _vm.isDisabledEdicaoPreVenda,
                      exibeDataCombo:
                        _vm.manipulacaoContext.isDesabilitado ||
                        _vm.isDisabledEdicaoPreVenda,
                      data: _vm.formulaPadraoOptions,
                      refresh: _vm.loadFormulaPadrao,
                      customView: true,
                    },
                    model: {
                      value: _vm.model.formulaPadraoId,
                      callback: function ($$v) {
                        _vm.$set(_vm.model, "formulaPadraoId", $$v)
                      },
                      expression: "model.formulaPadraoId",
                    },
                  })
                : _c(
                    "search-combo-component",
                    {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required",
                          expression: "'required'",
                        },
                      ],
                      attrs: {
                        id: _vm.itemType + "produtoId" + _vm.index,
                        name: "produtoId",
                        addViewId: _vm.model.produtoId,
                        actionName: "produtos",
                        advancedSearch:
                          !_vm.manipulacaoContext.isDesabilitado ||
                          _vm.isDisabledEdicaoPreVenda,
                        advancedSearchCampos: [
                          { campo: "produtoId", descricao: "Código" },
                          { campo: "text", descricao: "Descrição" },
                          {
                            campo: "quantidadeEstoque",
                            descricao: "Quantidade em Estoque",
                          },
                          {
                            campo: "quantidadeDisponivel",
                            descricao: "Quantidade Disponível",
                          },
                        ],
                        customView: true,
                        defaultData: _vm.produtosOptions,
                        disabled:
                          _vm.manipulacaoContext.isDesabilitado ||
                          _vm.isDisabledEdicaoPreVenda,
                        formAddNewName: _vm.$t(
                          "__.Components.parent.manipulacao.item_vue_html.visuProduto"
                        ),
                        propDescription: "descricao",
                        service: "Produto",
                        backgroundColor: _vm.produtoStyle,
                      },
                      on: { openComboViewItem: _vm.openComboVerProduto },
                      model: {
                        value: _vm.produtoId,
                        callback: function ($$v) {
                          _vm.produtoId = $$v
                        },
                        expression: "produtoId",
                      },
                    },
                    [
                      _c(
                        "div",
                        { attrs: { slot: "component" }, slot: "component" },
                        [
                          _c("produto-ver-component", {
                            ref: "produtoVerComponent",
                            attrs: { isModel: true },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _vm.itemType == 2
        ? _c("div", { staticClass: "col-sm-4" }, [
            _c(
              "div",
              { staticClass: "form-group" },
              [
                _c(
                  "combo-component",
                  {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'",
                      },
                    ],
                    attrs: {
                      actionName: "produtos",
                      addNewItemCombo: function () {},
                      addViewId: _vm.model.produtoId,
                      customView: true,
                      data: _vm.coresCapsulasOptions,
                      disabled:
                        _vm.manipulacaoContext.isDesabilitado ||
                        _vm.isDisabledEdicaoPreVenda,
                      exibeDataCombo:
                        _vm.manipulacaoContext.isDesabilitado ||
                        _vm.isDisabledEdicaoPreVenda,
                      formAddNewName: _vm.$t(
                        "__.Components.parent.manipulacao.item_vue_html.visuProduto"
                      ),
                      id: "coresCapsulas" + _vm.index,
                      name: "coresCapsulas" + _vm.index,
                    },
                    on: { openComboViewItem: _vm.openComboVerProduto },
                    model: {
                      value: _vm.produtoId,
                      callback: function ($$v) {
                        _vm.produtoId = $$v
                      },
                      expression: "produtoId",
                    },
                  },
                  [
                    _c(
                      "div",
                      { attrs: { slot: "component" }, slot: "component" },
                      [
                        _c("produto-ver-component", {
                          ref: "produtoVerComponent",
                          attrs: { isModel: true },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.itemType == 0,
              expression: "itemType == 0",
            },
          ],
          class: "col-sm-" + [_vm.showDose ? "1" : "2"],
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "tooltip",
                  rawName: "v-tooltip",
                  value: _vm.errors.first("tipoCalculo"),
                  expression: "errors.first('tipoCalculo')",
                },
              ],
              class: [
                "form-group",
                { colorFormulaBase: _vm.isFormulaPadrao },
                { colorFlavorizante: _vm.isFlavorizante },
                { colorAssociado: _vm.isAssociado },
                { colorFormaFarmaceutica: _vm.isFormaFarmaceutica },
              ],
            },
            [
              _c("combo-component", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required",
                    expression: "'required'",
                  },
                ],
                attrs: {
                  disabled:
                    _vm.manipulacaoContext.isDesabilitado ||
                    _vm.isDisabledEdicaoPreVenda,
                  exibeDataCombo:
                    _vm.manipulacaoContext.isDesabilitado ||
                    _vm.isDisabledEdicaoPreVenda,
                  tabindex: _vm.tabIndex(_vm.model.tipoCalculo),
                  id: _vm.itemType + "tipoCalculo" + _vm.index,
                  name: "tipoCalculo" + _vm.index,
                  data: _vm.tiposCalculoOptions,
                },
                model: {
                  value: _vm.model.tipoCalculo,
                  callback: function ($$v) {
                    _vm.$set(_vm.model, "tipoCalculo", $$v)
                  },
                  expression: "model.tipoCalculo",
                },
              }),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _vm.showDose && _vm.itemType == 0
        ? _c("div", { staticClass: "col-sm-1" }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "tooltip",
                    rawName: "v-tooltip",
                    value: _vm.errors.first("volumeDose"),
                    expression: "errors.first('volumeDose')",
                  },
                ],
                class: [
                  "form-group",
                  { colorFormulaBase: _vm.isFormulaPadrao },
                  { colorFlavorizante: _vm.isFlavorizante },
                  { colorAssociado: _vm.isAssociado },
                  { colorFormaFarmaceutica: _vm.isFormaFarmaceutica },
                ],
              },
              [
                _c("decimal-com-sinal-custom-component", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "maior_que_zero",
                      expression: "'maior_que_zero'",
                    },
                  ],
                  staticClass: "form-control",
                  attrs: {
                    disabled:
                      _vm.manipulacaoContext.isDesabilitado ||
                      _vm.isDisabledEdicaoPreVenda,
                    title:
                      _vm.$t(
                        "__.Components.parent.manipulacao.item_vue_html.vlrCadaDoseUnMedIguais"
                      ) +
                      " (" +
                      _vm.getTooltipUnidadeMedidaDose(
                        _vm.model.unidadeMedidaId
                      ) +
                      ")",
                    signal: _vm.getTooltipUnidadeMedidaDose(
                      _vm.model.unidadeMedidaId
                    ),
                    id: _vm.itemType + "volumeDose" + _vm.index,
                    name: "volumeDose" + _vm.index,
                  },
                  on: { change: _vm.onChangeVolumeDose },
                  model: {
                    value: _vm.model.volumeDose,
                    callback: function ($$v) {
                      _vm.$set(_vm.model, "volumeDose", $$v)
                    },
                    expression: "model.volumeDose",
                  },
                }),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "col-sm-2" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "tooltip",
                rawName: "v-tooltip",
                value: _vm.errors.first("quantidadeDose"),
                expression: "errors.first('quantidadeDose')",
              },
            ],
            class: [
              "form-group",
              { colorFormulaBase: _vm.isFormulaPadrao },
              { colorFlavorizante: _vm.isFlavorizante },
              { colorAssociado: _vm.isAssociado },
              { colorFormaFarmaceutica: _vm.isFormaFarmaceutica },
            ],
          },
          [
            _vm.model.tipoCalculo >= 6
              ? _c("combo-component", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required",
                      expression: "'required'",
                    },
                  ],
                  attrs: {
                    id: _vm.itemType + "lotesOptions" + _vm.index,
                    name: "lotesOptions" + _vm.index,
                    data: _vm.lotesOptions,
                  },
                  model: {
                    value: _vm.model.produtoLoteId,
                    callback: function ($$v) {
                      _vm.$set(_vm.model, "produtoLoteId", $$v)
                    },
                    expression: "model.produtoLoteId",
                  },
                })
              : _c("decimal-com-sinal-custom-component", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required|maior_que_zero",
                      expression: "'required|maior_que_zero'",
                    },
                  ],
                  staticClass: "form-control",
                  attrs: {
                    signal: _vm.getSignalQuantidade(
                      _vm.model.tipoCalculo,
                      false,
                      true
                    ),
                    title: _vm.getTipoCalculoName(_vm.model.tipoCalculo),
                    id: _vm.itemType + "quantidadeDose" + _vm.index,
                    name: "quantidadeDose" + _vm.index,
                    exibeDataCombo:
                      _vm.manipulacaoContext.isDesabilitado ||
                      _vm.isDisabledEdicaoPreVenda,
                    disabled:
                      _vm.model.tipoCalculo == 3 ||
                      _vm.manipulacaoContext.isDesabilitado ||
                      _vm.isDisabledEdicaoPreVenda,
                  },
                  model: {
                    value: _vm.model.quantidadeDose,
                    callback: function ($$v) {
                      _vm.$set(_vm.model, "quantidadeDose", $$v)
                    },
                    expression: "model.quantidadeDose",
                  },
                }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.itemType == 0,
              expression: "itemType == 0",
            },
          ],
          staticClass: "col-sm-1",
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "tooltip",
                  rawName: "v-tooltip",
                  value: _vm.errors.first("unidadeMedidaManipulacaoId"),
                  expression: "errors.first('unidadeMedidaManipulacaoId')",
                },
              ],
              class: [
                "form-group",
                { colorFormulaBase: _vm.isFormulaPadrao },
                { colorFlavorizante: _vm.isFlavorizante },
                { colorAssociado: _vm.isAssociado },
                { colorFormaFarmaceutica: _vm.isFormaFarmaceutica },
              ],
            },
            [
              _c("combo-component", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required",
                    expression: "'required'",
                  },
                ],
                attrs: {
                  tabindex: _vm.tabIndex(_vm.model.unidadeMedidaManipulacaoId),
                  id: _vm.itemType + "unidadeMedidaManipulacaoId" + _vm.index,
                  name: "unidadeMedidaManipulacaoId" + _vm.index,
                  data: _vm.unidadeMedidaOptions,
                  exibeDataCombo:
                    _vm.manipulacaoContext.isDesabilitado ||
                    _vm.isDisabledEdicaoPreVenda,
                  disabled:
                    _vm.model.tipoCalculo == 3 ||
                    _vm.model.tipoCalculo == 5 ||
                    _vm.manipulacaoContext.isDesabilitado ||
                    _vm.isDisabledEdicaoPreVenda,
                },
                model: {
                  value: _vm.model.unidadeMedidaManipulacaoId,
                  callback: function ($$v) {
                    _vm.$set(_vm.model, "unidadeMedidaManipulacaoId", $$v)
                  },
                  expression: "model.unidadeMedidaManipulacaoId",
                },
              }),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c("div", { class: _vm.itemType == 0 ? "col-sm-3" : "col-sm-4" }, [
        _c("div", { staticClass: "col-sm-6" }, [
          _c(
            "div",
            {
              directives: [
                {
                  name: "tooltip",
                  rawName: "v-tooltip",
                  value: _vm.errors.first("quantidadeItemManipulacao"),
                  expression: "errors.first('quantidadeItemManipulacao')",
                },
              ],
              class: [
                "form-group",
                { colorFormulaBase: _vm.isFormulaPadrao },
                { colorFlavorizante: _vm.isFlavorizante },
                { colorAssociado: _vm.isAssociado },
                { colorFormaFarmaceutica: _vm.isFormaFarmaceutica },
              ],
            },
            [
              _c("decimal-com-sinal-custom-component", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required",
                    expression: "'required'",
                  },
                ],
                staticClass: "form-control",
                attrs: {
                  disabled: "",
                  signal: _vm.getSignalQuantidade(_vm.model.tipoCalculo, true),
                  title: _vm.getTootipQtdTotal(_vm.model.tipoCalculo),
                  id: _vm.itemType + "quantidadeItemManipulacao" + _vm.index,
                  name: "quantidadeItemManipulacao" + _vm.index,
                  decimais: _vm.model.quantidadePesagem > 0.0001 ? 4 : 8,
                },
                model: {
                  value: _vm.model.quantidadePesagem,
                  callback: function ($$v) {
                    _vm.$set(_vm.model, "quantidadePesagem", $$v)
                  },
                  expression: "model.quantidadePesagem",
                },
              }),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-sm-6" }, [
          _c(
            "div",
            {
              directives: [
                {
                  name: "tooltip",
                  rawName: "v-tooltip",
                  value: _vm.errors.first("valorTotalItem"),
                  expression: "errors.first('valorTotalItem')",
                },
              ],
              class: [
                "form-group",
                { colorFormulaBase: _vm.isFormulaPadrao },
                { colorFlavorizante: _vm.isFlavorizante },
                { colorAssociado: _vm.isAssociado },
                { colorFormaFarmaceutica: _vm.isFormaFarmaceutica },
              ],
            },
            [
              _c("moeda-component", {
                directives: [
                  {
                    name: "mask-moeda-2-decimal",
                    rawName: "v-mask-moeda-2-decimal",
                  },
                ],
                staticClass: "form-control",
                attrs: {
                  id: _vm.itemType + "valorTotalItem" + _vm.index,
                  name: "valorTotalItem" + _vm.index,
                  tabindex: _vm.tabIndex(_vm.model.valorTotal),
                  title: _vm.getTooltipValorFinal(),
                  disabled: "",
                },
                model: {
                  value: _vm.model.valorTotal,
                  callback: function ($$v) {
                    _vm.$set(_vm.model, "valorTotal", $$v)
                  },
                  expression: "model.valorTotal",
                },
              }),
            ],
            1
          ),
        ]),
      ]),
      _vm._v(" "),
      _vm.isFormulaPadrao && _vm.itensDesemembradosFormulaBase.length > 0
        ? _c(
            "div",
            { staticClass: "col-sm-12" },
            [
              _c("grid-component", {
                attrs: {
                  data: _vm.itensDesemembradosFormulaBase,
                  columns: _vm.gridColumns,
                  "show-edit-item": false,
                  "show-remove-item": false,
                  showActionColumn: false,
                  "show-table-head": false,
                  "show-table-foot": false,
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }