import Vue from "vue";

import actionBarComponent from "@/components/child/actionBar/actionBar.vue";
import dateTimePickerComponent from "@/components/child/form/datetimepicker.vue";
import fieldsetComponent from "@/components/child/form/fieldset.vue";
import gridComponent from "@/components/child/grid/grid.vue";
import { GridColumn, GridColumnType } from "@/components/child/grid/gridColumn";
import { Component } from "@/decorators";
import DevolucaoModel from "@/models/devolucaoModel";
import DevolucaoService from "@/services/devolucaoService";
import Delay from "@/utils/common/delay";

import NfeEditComponent from "../../nfe/edit";
import nfeComponent from "../../nfe/edit.vue";
import NotaFiscalEntradaEditComponent from "../../notafiscalentrada/edit";
import notaFiscalEntradaComponent from "../../notafiscalentrada/edit.vue";
import ShortcutComponent from "../../shortcut/shortcut";
import shortcutComponent from "../../shortcut/shortcut.vue";

import "../crud.scss";

@Component({
    components: {
        fieldsetComponent,
        dateTimePickerComponent,
        nfeComponent,
        notaFiscalEntradaComponent,
        shortcutComponent,
        gridComponent,
        actionBarComponent,
    },
})
export default class DevolucaoCompraConsultaComponent extends Vue {
    private service = new DevolucaoService();
    private shortcutComponentOrigem: ShortcutComponent = null;
    private shortcutComponentDevolucao: ShortcutComponent = null;
    private notaFiscalEntradaComponent: NotaFiscalEntradaEditComponent = null;
    private nfeComponent: NfeEditComponent = null;

    loadComponentOrigem = false;
    loadComponentDevolucao = false;

    model = new DevolucaoModel();
    modelId = 0;
    valorTotalADevolver = 0;
    gridData = [];

    get gridColumns(): Array<GridColumn> {
        return [
            new GridColumn("dataCriacao", "Data", GridColumnType.Date),
            new GridColumn("produtoDescricao", "Produto", GridColumnType.String),
            new GridColumn("loteDescricao", "Lote", GridColumnType.String),
            new GridColumn("quantidade", "Quantidade", GridColumnType.Decimal),
            new GridColumn("valorUnitario", "Valor unitário", GridColumnType.Money),
        ];
    }

    private async load() {
        this.model = new DevolucaoModel();

        if (this.modelId > 0) {
            try {
                const data = await this.service.get(this.modelId).withLoading().resolveWithJSON<DevolucaoModel>();
                this.model.updateFrom(data);
                this.gridData = this.model.movimentacaoEstoque.movimentacaoEstoqueItens;
            } catch {}
        }
    }

    private cancel() {
        this.$router.back();
    }

    private async onConsultaNotaOrigem() {
        this.loadComponentOrigem = true;

        while (!this.$refs.notaFiscalEntradaComponent) await Delay(100);
        this.notaFiscalEntradaComponent = this.$refs.notaFiscalEntradaComponent as NotaFiscalEntradaEditComponent;

        this.notaFiscalEntradaComponent.loadById(this.model.notaFiscalEntradaOrigemId).withLoading();

        this.shortcutComponentOrigem = this.$refs.shortcutComponentOrigem as ShortcutComponent;
        this.shortcutComponentOrigem.title = this.$t("__.ts.notaEntrada") as string;
        this.shortcutComponentOrigem.show();
    }

    private async onConsultaNotaDevolucao() {
        this.loadComponentDevolucao = true;

        while (!this.$refs.nfeComponent) await Delay(100);
        this.nfeComponent = this.$refs.nfeComponent as NfeEditComponent;

        this.nfeComponent.loadById(this.model.notaFiscalSaidaDevolucaoId).withLoading();

        this.shortcutComponentDevolucao = this.$refs.shortcutComponentDevolucao as ShortcutComponent;
        this.shortcutComponentDevolucao.title = this.$t("__.ts.notaDevolucao") as string;
        this.shortcutComponentDevolucao.show();
    }

    private mounted() {
        if (this.$route.params.id) {
            this.modelId = +this.$route.params.id;
            this.load();
        }
    }
}
