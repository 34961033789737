import Vue from "vue";
import VueI18n from "vue-i18n";

import messages from "./traducao/index";

Vue.use(VueI18n);

const i18n = new VueI18n({
    locale: "pt-br",
    fallbackLocale: "pt-br",
    messages,
});

export default ({ app }) => {
    app.i18n = i18n;
};

export { i18n };
