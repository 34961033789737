import BaseModel from "./base/baseModel";
import TelaModel from "./telaModel";

export default class TelaAcaoModel extends BaseModel {
    id?: number = null;
    telaId?: number = null;
    tela: TelaModel = null;
    telaDescricao: string;
    descricao: string = null;
    acaoController: string = null;
    acaoVue: string = null;
    ativo = true;
}
