import Vue from "vue";
import { mapGetters } from "vuex";

import actionBarComponent from "@/components/child/actionBar/actionBar.vue";
import cepFieldComponent from "@/components/child/form/cepfield.vue";
import checkboxComponent from "@/components/child/form/checkbox.vue";
import cnpjComponent from "@/components/child/form/cnpj.vue";
import comboComponent from "@/components/child/form/combo.vue";
import cpfComponent from "@/components/child/form/cpf.vue";
import dataTooltipComponent from "@/components/child/form/datatooltip.vue";
import dateTimePickerComponent from "@/components/child/form/datetimepicker.vue";
import decimalComponent from "@/components/child/form/decimal.vue";
import fieldsetComponent from "@/components/child/form/fieldset.vue";
import textareaComponent from "@/components/child/form/textarea.vue";
import gridComponent from "@/components/child/grid/grid.vue";
import { GridColumn, GridColumnType } from "@/components/child/grid/gridColumn";
import { Component, Prop } from "@/decorators";
import BeneficioModel from "@/models/beneficio/beneficioModel";
import CepModel from "@/models/cepModel";
import ColaboradorModel from "@/models/colaboradorModel";
import EnumExtensions from "@/models/enum/extensions/enumExtensions";
import GenerosPessoa from "@/models/enum/generosPessoa";
import TiposPessoa from "@/models/enum/tiposPessoa";
import Ufs from "@/models/enum/ufs";
import FranquiaModel from "@/models/franquiaModel";
import { TypeElement } from "@/models/modeloImpressao/modeloImpressaoSessaoModel";
import PaginationModel from "@/models/paginationModel";
import BeneficioService from "@/services/beneficioService";
import ColaboradorService from "@/services/colaboradorService";
import CidadeService from "@/services/external/cidadeService";
import FranquiaService from "@/services/franquiaService";
import { Getters } from "@/store/store";
import Print from "@/utils/common/printForm";

import PrescritorComponent from "../prescritor/edit";
import prescritorComponent from "../prescritor/edit.vue";

import "../crud.scss";

@Component({
    components: {
        textareaComponent,
        fieldsetComponent,
        comboComponent,
        checkboxComponent,
        cpfComponent,
        cnpjComponent,
        decimalComponent,
        cepFieldComponent,
        dateTimePickerComponent,
        dataTooltipComponent,
        gridComponent,
        prescritorComponent,
        actionBarComponent,
    },
    computed: {
        ...mapGetters(["HAS_PERMISSAO_PERFIL"] as Getters),
    },
})
export default class ColaboradorEditComponent extends Vue {
    // State computed props
    HAS_PERMISSAO_PERFIL: (isRede: boolean, isFranqueador: boolean, isSuporte: boolean) => Promise<boolean>;

    private service = new ColaboradorService();
    private cidadeService = new CidadeService();
    private prescritorComponent: PrescritorComponent = null;
    private franquiasOptions: Array<Object> = [];
    private isAdmin = false;

    private model: ColaboradorModel = new ColaboradorModel();
    private modelId = 0;

    @Prop({ type: Boolean, default: false }) CalledByShortCut: boolean;
    @Prop({ type: Boolean, default: false }) isModal: boolean;

    private hideExtras = false;

    tiposPessoa = TiposPessoa;
    tiposPessoaOptions: Array<Object> = [
        { text: "Física", value: TiposPessoa.Fisica },
        { text: "Jurídica", value: TiposPessoa.Juridica },
    ];

    generosPessoaOptions: Array<Object> = [
        { text: "Masculino", value: GenerosPessoa.Masculino },
        { text: "Feminino", value: GenerosPessoa.Feminino },
    ];

    gridColumns: Array<GridColumn> = [new GridColumn("nome", "Nome", GridColumnType.String)];

    dadosCep: CepModel = new CepModel();
    ufOptions: Array<Object> = EnumExtensions.getNamesAndValuesOrderedByNames(Ufs);
    cidadesOptions: Array<Object> = [];
    beneficiosOptions: Array<Object> = [];

    constructor() {
        super();
    }

    public async load() {
        this.model = new ColaboradorModel();
        this.loadFranquias();
        this.loadBeneficios();
        this.model.vendedorDotz = false;
        if (this.modelId > 0) {
            try {
                const data = await this.service.get(this.modelId).withLoading().resolveWithJSON<ColaboradorModel>();

                this.model.updateFrom(data);
                await this.onLoadCidades(this.model.estadoId);
            } catch {
                this.$router.back();
            }
        }
    }

    public async save() {
        try {
            const isValid = await this.$validator.validateAll();
            if (isValid) {
                if (this.model.cidadeId) {
                    this.model.cidade = this.cidadesOptions.find(item => item["value"] == this.model.cidadeId)["text"];
                }

                const sucesso = await this.service[!this.model.id ? "insert" : "update"](this.model)
                    .withLoading()
                    .resolveWithoutJSON();

                if (sucesso) {
                    await this.$showInclusaoUpdate(this.model.id);
                    this.$router.back();
                }
            } else {
                this.$focusErrorField();
            }
        } catch {}
    }

    public atualizarBeneficios() {
        this.loadBeneficios();
    }

    private cancel() {
        if (this.CalledByShortCut) {
            this.model = new ColaboradorModel();
        }
        this.$router.back();
    }

    private async loadFranquias() {
        try {
            const data = await new FranquiaService().combo().resolveWithJSON<PaginationModel<FranquiaModel>>();

            this.franquiasOptions = data.list.map(item => ({
                value: item.id,
                text: item.nomeFantasia,
            }));
        } catch {}
    }

    private async loadBeneficios() {
        try {
            const data = await new BeneficioService().combo().resolveWithJSON<PaginationModel<BeneficioModel>>();
            this.beneficiosOptions = data.list.map(item => ({
                value: item.id,
                text: item.descricao,
            }));
        } catch {}
    }

    private onAddNewPrescritor() {
        this.prescritorComponent = this.$refs.PrescritorComponent as PrescritorComponent;
        this.prescritorComponent.save();
    }

    private async onCepChanged(data: CepModel) {
        this.dadosCep = data;
        this.model.bairro = data.bairro;
        this.model.complemento = data.complemento;
        this.model.endereco = data.endereco;
        this.model.estadoId = data.uf;
        await this.onLoadCidades(data.uf);
        this.model.cidadeId = data.cidadeId;
    }

    private onCepInvalid() {
        this.$showInfo("Pesquisa por CEP", "A pesquisa não encontrou resultados");
    }

    private async onLoadCidades(estadoId: number) {
        try {
            this.cidadesOptions = [];
            if (estadoId) {
                this.cidadesOptions = await this.cidadeService.get(estadoId).withLoading();
            }
        } catch {}
    }

    private async mounted() {
        Promise.all([this.loadFranquias()])
            .withLoading()
            .then(async () => {
                this.hideExtras = this.CalledByShortCut ? true : false;
                this.isAdmin = await this.HAS_PERMISSAO_PERFIL(false, false, false);

                if (this.$route.params.id && !this.hideExtras) {
                    this.modelId = +this.$route.params.id;
                    this.load();
                }

                if (this.CalledByShortCut) {
                    this.model = new ColaboradorModel();
                }

                if (!this.isModal) {
                    this.loadBeneficios();
                }
            })
            .catch(() => {});
    }

    private print() {
        new Print().print(
            [
                {
                    elementId: "colaborador",
                    title: "colaborador",
                    type: TypeElement.Form,
                },
                {
                    elementId: "localizacao",
                    title: "Localização",
                    type: TypeElement.Form,
                },
                {
                    elementId: "entrega",
                    title: "Endereço de Entrega",
                    type: TypeElement.Form,
                },
                {
                    elementId: "contato",
                    title: "Contato",
                    type: TypeElement.Form,
                },
            ],
            3,
        );
    }
}
