import Vue from "vue";
import { VueTabs, VTab } from "vue-nav-tabs";
import { mapState } from "vuex";

Vue.component("vue-tabs", VueTabs);
Vue.component("v-tab", VTab);

import buttonScComponent from "@/components/child/form/buttonSc.vue";
import checkboxComponent from "@/components/child/form/checkbox.vue";
import dateTimePickerComponent from "@/components/child/form/datetimepicker.vue";
import fieldsetComponent from "@/components/child/form/fieldset.vue";
import moedaComponent from "@/components/child/form/moeda.vue";
import gridComponent from "@/components/child/grid/grid.vue";
import { Component, Prop } from "@/decorators";
import PreLoadPackModel from "@/models/auxiliar/preLoadPackModel";
import OrigemAtendimento from "@/models/enum/origemAtendimento";
import SituacoesVenda from "@/models/enum/situacoesVenda";
import MotivoCancelamentoModel from "@/models/motivoCancelamentoModel";
import PaginationModel from "@/models/paginationModel";
import VendaModel from "@/models/vendaModel";
import MotivoCancelamentoService from "@/services/motivoCancelamentoService";
import { AppState } from "@/store/store";
import Delay from "@/utils/common/delay";
import Bus from "@/utils/events/bus";

import VendaComponent from "./editSingle";
import vendaComponent from "./editSingle.vue";

import "../crud/crud.scss";
import "./../../../../node_modules/vue-nav-tabs/themes/vue-tabs.css";
import "./edit.scss";

@Component({
    components: {
        fieldsetComponent,
        dateTimePickerComponent,
        moedaComponent,
        gridComponent,
        checkboxComponent,
        vendaComponent,
        buttonScComponent,
    },
    computed: mapState<AppState>({
        preLoadList: state => state.preLoad.preLoadList,
        loadedList: state => state.preLoad.loadedList,
    }),
})
export default class VendaEditComponent extends Vue {
    // State computed props
    loadedList: boolean;
    preLoadList: PreLoadPackModel;

    private vendaComponent: VendaComponent = null;

    //title, Id da tabela e o codigo da ordem
    private etapasVenda = new Array<[string, number, number, object]>();
    private tabs = new Array<number>();
    activeTab = "";

    private paramEnderecoEntregaId: number = null;
    private paramPacienteId: number = null;
    private paramOrigem: number = null;

    it = 1;

    //string pq eh o que o paramentro aceita
    @Prop(String) clienteId: string;
    private numClienteId = 0;

    inputTab() {}

    onAddTab() {
        const etapaVenda: [string, number, number, object] = ["Orçamento Novo **", 0, this.it, null];
        this.etapasVenda.push(etapaVenda);
        this.tabs.push(this.it);

        this.it++;
    }

    //nao pode ser parametro para a funcao acima pq a funcao ganha parametros de clicks da tela
    private async onReplicarVenda(model: VendaModel) {
        const etapaVenda: [string, number, number, object] = ["Orçamento Novo **", 0, this.it, model];
        this.etapasVenda.push(etapaVenda);
        this.tabs.push(this.it);

        this.it++;
    }

    onRemoveTab(num: number, validarStopProp = true) {
        if (this.etapasVenda.length == 1) {
            this.$router.back();
        } else {
            let stopProp = true;
            if (num == 0 && this.etapasVenda.length > 2) {
                this.activeTab = this.etapasVenda[1][0] + this.etapasVenda[1][2];
            } else if (num > 0) {
                this.activeTab = this.etapasVenda[num - 1][0] + this.etapasVenda[num - 1][2];
            } else {
                stopProp = false;
            }
            this.etapasVenda.splice(num, 1);
            this.tabs.splice(num, 1);

            if (stopProp && validarStopProp) {
                window.event.stopPropagation();
            }
        }
    }

    constructor() {
        super();
    }

    private onLoadedNewVenda(index: number) {
        if (index > 0) {
            this.activeTab = "Orçamento Novo **" + this.etapasVenda[index][2];
        }
    }

    private async onEditVenda(model: VendaModel, index) {
        let title = this.getTitleBySituacao(model.situacao);

        title += " " + (model.id == null ? "Novo **" : "Cód:");
        const idVenda = model.id == null ? 0 : model.id;
        const etapaVenda: [string, number, number, object] = [
            title,
            idVenda,
            model.id == null ? this.etapasVenda[index][2] : model.codigo,
            null,
        ];
        this.$set(this.etapasVenda, index, etapaVenda);

        //sempre q chega aqui com id null eh orçamento novo ? por enquanto eh
        if (model.id == null) {
            this.activeTab = title + this.etapasVenda[index][2];
        }
    }

    private onChangedVenda(model: VendaModel, index: number) {
        let title = this.getTitleBySituacao(model.situacao);

        title += " " + (model.id == null ? "Novo **" : "Cód:");

        const idVenda = model.id == null ? 0 : model.id;
        const etapaVenda: [string, number, number, object] = [
            title,
            idVenda,
            model.id == null ? this.etapasVenda[index][2] : model.codigo,
            null,
        ];
        this.$set(this.etapasVenda, index, etapaVenda);
    }

    private getTitleBySituacao(situacao: SituacoesVenda) {
        switch (situacao) {
            case SituacoesVenda.Orcamento:
                return "Orçamento ";
            case SituacoesVenda.Rejeitado:
                return "Rejeitado";
            case SituacoesVenda.Aprovado:
                return "Venda";
            case SituacoesVenda.Concluido:
                return "Venda Concluída";
            case SituacoesVenda.Entregue:
                return "Venda Entregue";
            case SituacoesVenda.Faturado:
                return "Venda Faturada";
            case SituacoesVenda.Cancelado:
                return "Venda Cancelada";
            case SituacoesVenda.Fracionando:
                return "Venda em Fracionamento";
            case SituacoesVenda.ExpedicaoNota:
                return "Venda aguardando Nota";
            case SituacoesVenda.Entrega:
                return "Venda em Entrega";
            case SituacoesVenda.Expedicao:
                return "Venda em Expedição";
            case SituacoesVenda.EmFaturamento:
                return "Venda em Faturamento";
        }
    }

    motivoCancelamentoOptions: Array<Object> = [];

    private async loadMotivoCancelamento() {
        try {
            const data = await new MotivoCancelamentoService()
                .combo()
                .then(r => r.json() as Promise<PaginationModel<MotivoCancelamentoModel>>);
            this.motivoCancelamentoOptions = data.list.map(item => ({ value: item.id, text: item.descricao }));
        } catch {}
    }

    private async getPreLoadPack() {
        while (!this.loadedList) await Delay(100);
    }

    private beforeDestroy() {
        this.clearAll();
    }

    private clearAll() {
        this.vendaComponent = null;
        this.etapasVenda = null;
    }

    private async mounted() {
        this.vendaComponent = this.$refs.vendaComponent as VendaComponent;
        Bus.$emit("hide-nav-menu", true);

        this.paramEnderecoEntregaId = null;
        this.paramPacienteId = null;
        this.paramOrigem = null;

        if (this.$route.query.clienteId != null) {
            //@ts-ignore
            this.numClienteId = parseInt(this.$route.query.clienteId);
            this.paramOrigem = OrigemAtendimento.Aplicativo;
        }

        if (this.$route.query.enderecoEntregaId != null) {
            //@ts-ignore
            this.paramEnderecoEntregaId = parseInt(this.$route.query.enderecoEntregaId);
        }

        if (this.$route.query.pacienteId != null) {
            //@ts-ignore
            this.paramPacienteId = parseInt(this.$route.query.pacienteId);
        }

        if (this.$route.query.origem != null) {
            //@ts-ignore
            this.paramOrigem = parseInt(this.$route.query.origem);
        }

        if (this.clienteId != null && this.clienteId != "") {
            this.numClienteId = parseInt(this.clienteId);
        }

        await this.getPreLoadPack();

        let x: [string, number, number, object];

        if (this.$route.params.id) {
            x = ["Orçamento cód:", +this.$route.params.id, 0, null];
        } else {
            x = ["Orçamento Novo **", 0, 0, null];
        }

        this.etapasVenda.push(x);
        this.tabs.push(0);
    }
}
