var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "shortcutComponent",
    {
      ref: "shortcutComponent",
      attrs: { showAddNew: false, showBtnOk: false, modalClass: "lm-modal" },
    },
    [
      _c(
        "div",
        {
          staticClass: "historico-atendimento-mensagens",
          attrs: { slot: "component" },
          slot: "component",
        },
        [
          _c(
            "div",
            {
              staticClass: "chat-window",
              staticStyle: { width: "102.3%", "margin-left": "-14px" },
            },
            [
              _c("div", { staticClass: "messages-container" }, [
                _c(
                  "div",
                  { staticClass: "messages-content" },
                  _vm._l(_vm.messages, function (message) {
                    return _c(
                      "div",
                      {
                        key: message.id,
                        class: [
                          message.mensagemPropria ? "me" : "them",
                          message.observacao ? "observation" : "",
                        ],
                      },
                      [
                        message.usuarioEnvio != null &&
                        message.usuarioEnvio.id != _vm.usuarioLogado.id
                          ? _c(
                              "div",
                              {
                                class: [
                                  "send-user",
                                  message.observacao ? "observation" : "",
                                ],
                              },
                              [
                                _c("span", [
                                  _vm._v(_vm._s(message.usuarioEnvio.nome)),
                                ]),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        message.mensagemRespondidaId
                          ? _c("div", { staticClass: "reply-container" }, [
                              _c(
                                "div",
                                {
                                  class: [
                                    "reply-content",
                                    _vm.isMensagemContato(
                                      message.mensagemRespondidaId
                                    )
                                      ? "them-reply"
                                      : "me-reply",
                                  ],
                                },
                                [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.sentBy(message.mensagemRespondidaId)
                                      )
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("mensagemComponent", {
                                    attrs: {
                                      message: _vm.getMensagemRespondida(
                                        message.mensagemRespondidaId
                                      ),
                                      reply: true,
                                      isHistorico: true,
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                [
                                  _c("mensagemComponent", {
                                    attrs: {
                                      message: message,
                                      isHistorico: true,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ])
                          : _c("mensagemComponent", {
                              attrs: { message: message, isHistorico: true },
                            }),
                      ],
                      1
                    )
                  })
                ),
              ]),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }