import nfetch from "../utils/events/nfetch";

export default class EtiquetaService {
    private controllerName = "Etiqueta";

    public getListEtiquetas(vendaIds: number[]): Promise<Response> {
        return nfetch(`/${this.controllerName}/GetListEtiquetas`, {
            method: "post",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json; charset=UTF-8",
            },
            body: JSON.stringify(vendaIds),
        });
    }
}
