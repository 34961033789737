import Vue from "vue";

import checkboxComponent from "@/components/child/form/checkbox.vue";
import comboComponent from "@/components/child/form/combo.vue";
import dataTooltipComponent from "@/components/child/form/datatooltip.vue";
import dateTimePickerComponent from "@/components/child/form/datetimepicker.vue";
import fieldsetComponent from "@/components/child/form/fieldset.vue";
import moedaComponent from "@/components/child/form/moeda.vue";
import { Component, Watch } from "@/decorators";
import Especie from "@/models/enum/especiePagamento";
import FormaRecebimentoModel from "@/models/financeiro/formaRecebimentoModel";
import PaginationModel from "@/models/paginationModel";
import FormaRecebimentoService from "@/services/financeiro/formaRecebimentoService";
import MovimentacaoCaixaService from "@/services/financeiro/movimentacaoCaixaService";

import "../crud/crud.scss";

@Component({
    components: {
        checkboxComponent,
        dataTooltipComponent,
        fieldsetComponent,
        moedaComponent,
        comboComponent,
        dateTimePickerComponent,
    },
})
export default class ReforcoComponent extends Vue {
    private movimentacaoCaixaService = new MovimentacaoCaixaService();

    public valorReforco = 0;
    descricao: string = null;
    saldoAtual = 0;
    contaCorrenteId = 0;
    contaCorrenteDesc: string = null;
    formaRecebimentoId = 0;
    totalCalculado = 0;

    contaCorrenteOptions: Array<Object> = [];
    formaRecebimentoOptions: Array<Object> = [];

    constructor() {
        super();
    }

    public clear(caixaDesc: string, saldoAtual: number, contaCorrenteId: number, valorReforco = 0) {
        this.descricao = "Reforço de saldo para Pdv:" + caixaDesc;
        this.saldoAtual = saldoAtual;
        this.contaCorrenteDesc = caixaDesc;
        this.totalCalculado = saldoAtual;
        this.valorReforco = valorReforco;
        this.formaRecebimentoId = 0;
        this.contaCorrenteId = contaCorrenteId;

        this.formaRecebimentoOptions = this.formaRecebimentoOptions.filter(
            p => p["contaCorrenteOrigemId"] != this.contaCorrenteId,
        );
    }

    private async loadFormasRecebimento() {
        try {
            const data = await new FormaRecebimentoService()
                .comboByEspecie(Especie.Transferencia)
                .resolveWithJSON<PaginationModel<FormaRecebimentoModel>>();

            this.formaRecebimentoOptions = data.list.map(item => ({
                value: item.id,
                text: item.descricao,
                especie: item.especie,
                juros: item.juros,
                desconto: item.desconto,
                bandeira: item.bandeiraId,
                metodosXForma: item.metodoPagamentoXForma,
                contaCorrenteOrigemId: item.contaCorrenteOrigemId,
            }));

            this.formaRecebimentoOptions = this.formaRecebimentoOptions.filter(
                p => p["contaCorrenteOrigemId"] != this.contaCorrenteId,
            );
        } catch {}
    }

    private cancel() {
        this.$router.back();
    }

    @Watch("valorReforco")
    private onChangedReforco() {
        this.totalCalculado = this.saldoAtual + this.valorReforco;
    }

    @Watch("totalCalculado")
    private onChangedTotalCalculado() {
        this.valorReforco = this.totalCalculado - this.saldoAtual;
    }

    private validaErroTotalCalculado() {
        if (this.totalCalculado <= this.saldoAtual) {
            this.setError("totalCalculado", "Valor total calculado não pode ser menor ou igual ao saldo atual.");
        } else {
            this.setError("totalCalculado", "", true);
        }
    }

    private setError(idCampo: string, msgTooltip = "", removeErro = false) {
        const form = document.getElementById(idCampo).parentElement;

        const possuiErros = form.getAttribute("class").indexOf("error") >= 0;

        if (removeErro && possuiErros) {
            this.$validator.errors.remove(idCampo);
        } else if (!possuiErros && !removeErro) {
            this.$validator.errors.add({ field: idCampo, msg: msgTooltip, id: idCampo });
        }
    }

    public async onGerarReforco(contaCorrenteId: number) {
        try {
            this.validaErroTotalCalculado();

            const isValid = await this.$validator.validateAll();
            if (isValid) {
                const sucesso = await this.movimentacaoCaixaService
                    .insertReforco(this.valorReforco, this.formaRecebimentoId, contaCorrenteId, this.descricao)
                    .withLoading()
                    .resolveWithoutJSON();

                if (sucesso) {
                    await this.$showSuccess(this.$t("__.ts.inclusao"), this.$t("__.ts.reforcoGeradSucess"));
                    return true;
                }
            }
        } catch {}
        return false;
    }

    private mounted() {
        Promise.all([this.loadFormasRecebimento()])
            .withLoading()
            .then(() => {})
            .catch(() => {});
    }
}
