import Vue from "vue";
import { mapGetters } from "vuex";

import ShortcutComponent from "@/components/parent/shortcut/shortcut";
import shortcutComponent from "@/components/parent/shortcut/shortcut.vue";
import { Component, Prop, Watch } from "@/decorators";
import { Getters } from "@/store/store";

import "./combo.scss";

@Component({
    components: {
        shortcutComponent,
    },
    computed: {
        ...mapGetters(["VALIDAR_PERMISSAO_USUARIO"] as Getters),
    },
})
export default class ComboComponent extends Vue {
    // State computed props
    VALIDAR_PERMISSAO_USUARIO: (telaDescricao: string, acaoController: string) => boolean;

    public selected: any = null;
    private loading = false;
    private shortcutComponent: ShortcutComponent = null;

    private seachingValue = "";

    showAddButton = false;
    showEditButton = false;
    showViewButton = false;

    @Prop(String) id: string;
    @Prop(String) name: string;
    @Prop(String) formAddNewName: string;

    @Prop({ type: Number, default: null }) tabindex: number;
    @Prop({ type: Number, default: null }) value: number;
    @Prop({ type: Number, default: null }) defaultValue: number;

    @Prop(Array) data;
    @Prop(Boolean) disabled: boolean;
    @Prop({ type: Boolean, default: true }) searchable: boolean;
    @Prop({ type: Boolean, default: false }) customView: boolean;

    @Prop(Function) refresh;

    @Prop({ type: Boolean, default: false }) addNew: boolean;
    @Prop({ type: Number, default: null }) addEditId: number;

    @Prop({ type: String, default: "" }) placeholder: string;
    @Prop({ type: Number, default: null }) addViewId: number;
    @Prop({ type: String, default: "lg-modal" }) modalClass: string;

    @Prop({ type: Boolean, default: false }) useCloseModalCallback: boolean;
    @Prop({ type: Boolean, default: false }) exibeDataCombo: boolean;
    @Prop({ type: String, default: "" }) actionName: string;

    private onSearch(value) {
        if (value != null) {
            this.seachingValue = value;
        } else {
            this.seachingValue = "";
        }
    }

    private onSearchBlur() {
        this.seachingValue = "";
    }

    private isCustomOption(option) {
        if (this.seachingValue.length > 0) {
            return true;
        }

        if (typeof option === "object") {
            const textOption = (<string>option["text"]).toString();
            return textOption.indexOf("((") >= 0 || textOption.indexOf("<#") >= 0;
        }

        return false;
    }

    private getOptionConfigs(option) {
        const strAr: Array<[string, boolean, string]> = new Array<[string, boolean, string]>();

        if (typeof option === "object") {
            if (option["text"]) {
                let textOption = (<string>option["text"]).toString();

                if (this.seachingValue != "") {
                    textOption = this.concatenarChavesCor(textOption);
                }

                //definir melhores sinais depois,
                //((Tudo aqui dentro será < sup > e sera removido um dos parenteses))
                //<#Tudo aqui dentro tera o txt com outra cor e sera removido os caracteres '#>' #>
                if (textOption.indexOf("((") >= 0 || textOption.indexOf("<#") >= 0) {
                    strAr.push(this.findFirstSup(textOption));
                } else {
                    strAr.push([textOption, false, null]);
                }
                return strAr;
            }
        }

        strAr.push([option, false, null]);
        return strAr;
    }

    private concatenarChavesCor(textOption: string) {
        const strSearchReplace = this.seachingValue.toLowerCase();

        const posIni = textOption.toLowerCase().indexOf(strSearchReplace);
        if (posIni >= 0) {
            const strDef =
                (posIni > 0 ? textOption.substr(0, posIni) : "") +
                "<#" +
                textOption.substr(posIni, this.seachingValue.length) +
                "#>";
            const strRed = textOption.slice(posIni + this.seachingValue.length);
            return strDef + this.concatenarChavesCor(strRed);
        } else {
            return textOption;
        }
    }

    //exemplo
    // Fenrir((Diego))   // <#Fenrir#>((Diego))   // Fenrir<#((Diego))>#   // F<#e#>nrir((Di<#e#>go))
    private findFirstSup(textOption: string) {
        const strAr: Array<[string, boolean, string]> = new Array<[string, boolean, string]>();

        const posIniSup = textOption.indexOf("((");
        const posFinSup = textOption.indexOf("))");
        const posIniColor = textOption.indexOf("<#");
        const posFinColor = textOption.indexOf("#>");
        let str = textOption;
        let strTemp = str;

        if (posIniSup >= 0 || posIniColor >= 0) {
            //se nenhum comeca no zero, entao a primeira string eh sem cor e sem sup
            if (posIniColor != 0 && posIniSup != 0) {
                let fim = (posIniSup < posIniColor && posIniSup >= 0) || posIniColor < 0 ? posIniSup : posIniColor;
                if (fim < 0) fim = str.length;

                strTemp = str.substr(0, fim);
                strAr.push([strTemp, false, null]);
                //remove a parte da string que foi atribuida ao temp
                str = str.slice(fim);
                if (str.length > 0) {
                    const strArray = strAr.concat(this.findFirstSup(str));
                    return strArray;
                } else return strAr;
            }
            //descobrir se tem um dentro de outro, se houver, deve ler internamente e quebrar mais vezes a string
            else {
                ///verifica cor inicio
                if (posIniColor >= 0 && (posIniColor < posIniSup || posIniSup < 0)) {
                    //guarda a string ate o primeiro que terminar ou o proximo que comecar
                    const posFim = posFinColor < posIniSup || posIniSup < 0 ? posFinColor : posIniSup;
                    //guarda pos fim - 1
                    strTemp = str.substr(2, posFim - 2);
                    strAr.push([strTemp, false, "yellow"]);
                    str = str.slice(posFim + 2);

                    if (str.trim().length > 0) {
                        if (posFinColor < posIniSup || posIniSup < 0) {
                            return strAr.concat(this.findFirstSup(str));
                        } else if (posIniSup > posFinColor) {
                            //deve chamar a funcao de novo e manter a cor ativa apos os parenteses removidos no splice
                            return strAr.concat(this.findFirstSup("((<#" + str));
                        }
                    } else return strAr;
                }
                //terminar de tratar aqui quando apenas parentes ou ainda possui parentes e cores
                else if (posIniSup >= 0 && (posIniSup < posIniColor || posIniColor < 0)) {
                    let posFim =
                        posFinColor < posFinSup && posFinColor > 0
                            ? posIniColor > 2
                                ? posIniColor
                                : posFinColor
                            : posFinSup;

                    if (posFim < 0) {
                        posFim = str.length;
                    }
                    const possuirCor = posFim == posFinColor || posFim == posIniColor;
                    //para nao pegar o primeiros parenteses
                    strTemp = str.substr(2, posFim - 2);
                    //se possue cor antes de fechar o sup, remove o inicio da cor
                    strTemp = strTemp.replace("<#", "");
                    //se a cor acaba antes, salva com possuirCor cor e chama de novo a funcao
                    strAr.push([strTemp, true, possuirCor && posFim == posFinColor ? "yellow" : null]);

                    str = str.slice(posFim + 2);

                    if (str.trim().length > 0) {
                        if (possuirCor && posFim == posFinColor) {
                            const strArrayColorAntes = strAr.concat(this.findFirstSup("((" + str));
                            return strArrayColorAntes;
                        } else if (possuirCor && posFim == posIniColor) {
                            const strArraySupColor = strAr.concat(this.findFirstSup("((<#" + str));
                            return strArraySupColor;
                        } else {
                            const strArraySup = strAr.concat(this.findFirstSup(str));
                            return strArraySup;
                        }
                    } else {
                        return strAr;
                    }
                }
            }
        } else {
            strAr.push([str, false, null]);
            return strAr;
        }
    }

    public async onRefresh() {
        if (this.disabled || this.refresh == null) {
            return;
        }
        this.loading = true;

        await this.refresh();
        this.selected = null;

        this.loading = false;
        this.closeddNew();
    }

    public onAdd() {
        this.onOpenComboExtra("openComboNewItem", null);
    }

    public onAddEdit() {
        this.onOpenComboExtra("openComboEditItem", this.addEditId);
    }

    public onAddViewId() {
        this.onOpenComboExtra("openComboViewItem", this.addViewId);
    }

    private onOpenComboExtra(event: string, id: number) {
        if (this.disabled) {
            return;
        }

        this.$emit(event, id);
        this.shortcutComponent.title = this.formAddNewName?.toString() ?? this.$t("__.ts.addNovo").toString();
        this.shortcutComponent.show();
    }

    private closeModal() {
        this.shortcutComponent.hide();
    }

    private onConfirmAddNew() {
        if (!this.useCloseModalCallback) {
            this.shortcutComponent.hide();
        }

        this.$emit("addNewItemCombo", this.closeModal);
    }

    private closeddNew() {
        this.shortcutComponent.hide();
    }

    @Watch("selected")
    private onSelectedChanged() {
        this.$emit("input", this.selected ? +this.selected.value : this.defaultValue);
    }

    @Watch("value")
    private onValueChanged(newValue, oldValue) {
        if ((newValue || newValue == 0) && newValue !== oldValue) {
            this.updateSelected();
        } else if (newValue == null) {
            this.selected = this.defaultValue;
        }
    }

    @Watch("data")
    private onDataChanged() {
        this.updateSelected();
        this.closeddNew();
    }

    @Watch("addNew")
    @Watch("addEditId")
    @Watch("addViewId")
    private onChangeExtraButton() {
        this.actionValidations();
    }

    @Watch("actionName")
    private onChangeActionName() {
        this.actionValidations();
    }

    private actionValidations() {
        this.showAddButton = !this.exibeDataCombo && this.addNew;
        this.showEditButton = !this.exibeDataCombo && this.addEditId > 0;
        this.showViewButton = !this.exibeDataCombo && this.addViewId > 0;

        if (this.actionName) {
            this.showAddButton = this.showAddButton && this.VALIDAR_PERMISSAO_USUARIO(this.actionName, "insert");
            this.showEditButton = this.showEditButton && this.VALIDAR_PERMISSAO_USUARIO(this.actionName, "update");
            this.showViewButton = this.showViewButton && this.VALIDAR_PERMISSAO_USUARIO(this.actionName, "get");
        }
    }

    private mounted() {
        this.shortcutComponent = this.$refs.shortcutComponent as ShortcutComponent;
        this.updateSelected();

        this.actionValidations();
    }

    private onChangeInput(value) {
        this.$emit("select", value ? value.value : value);
    }

    private updateSelected() {
        if (this.data.length !== 0) {
            const option = this.data.filter(it => it["value"] == this.value);

            if (option.length !== 0) {
                this.selected = option[0];
            } else {
                this.selected = null;
            }
        } else {
            this.selected = null;
        }
    }
}
