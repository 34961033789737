import BaseModel from "../base/baseModel";

export default class NcmModel extends BaseModel {
    id?: number = null;
    franquiaId?: number = null;
    descricao: string = null;
    codigo: string = null;
    similarNacional = true;
    ativo = true;
}
