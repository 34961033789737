import BaseModel from "./base/baseModel";

export default class EmailModel extends BaseModel {
    id?: number = null;
    franquiaId?: number = null;
    host: string = null;
    porta: number = null;
    email: string = null;
    emailRemetente: string = null;
    senha: string = null;
}