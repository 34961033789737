import Vue from "vue";
import { mapGetters } from "vuex";

import comboComponent from "@/components/child/form/combo.vue";
import fieldsetComponent from "@/components/child/form/fieldset.vue";
import loadingModalComponent from "@/components/child/loadingmodal/loadingmodal.vue";
import searchComboComponent from "@/components/child/searchCombo/search.vue";
import { Component, Prop, Watch } from "@/decorators";
import ConfiguracaoFranquiaModel from "@/models/configuracaoFranquia/configuracaoFranquiaModel";
import Configuracoes from "@/models/enum/configuracao/configuracoes";
import ManipulacaoOrdemModel from "@/models/manipulacaoOrdemModel";
import PacienteModel from "@/models/paciente/pacienteModel";
import PosologiaModel from "@/models/posologiaModel";
import PrescritorModel from "@/models/prescritorModel";
import ConvenioService from "@/services/convenioService";
import PrescritorService from "@/services/prescritorService";
import { Getters } from "@/store/store";
import { getPrescritorCombo } from "@/utils/common/combo/combotext";
import Delay from "@/utils/common/delay";
import ValidationErrorWrapper from "@/wrappers/validationErrorWrapper";

import PacienteEditComponent from "../crud/paciente/edit";
import pacienteComponent from "../crud/paciente/edit.vue";
import PosologiaEditComponent from "../crud/posologia/edit";
import posologiaComponent from "../crud/posologia/edit.vue";
import PrescritorEditComponent from "../crud/prescritor/edit";
import prescritorComponent from "../crud/prescritor/edit.vue";

import "./../crud/contareceber/recebimentoconta.scss";

@Component({
    components: {
        comboComponent,
        searchComboComponent,
        fieldsetComponent,
        loadingModalComponent,
        pacienteComponent,
        prescritorComponent,
        posologiaComponent,
    },
    computed: {
        ...mapGetters(["GET_IS_ESTEREIS", "GET_CONFIG_FRANQUIA"] as Getters),
        isPosologiaTextoLivre() {
            return this.GET_CONFIG_FRANQUIA(Configuracoes.PosologiasTextoLivre).verdadeiro;
        },
    },
})
export default class RevisaoCamposItemComponent extends Vue {
    // State computed props
    isPosologiaTextoLivre: boolean;
    GET_CONFIG_FRANQUIA: (configuracao: Configuracoes) => ConfiguracaoFranquiaModel;

    private pacienteComponent: PacienteEditComponent = null;
    private posologiaComponent: PosologiaEditComponent = null;
    private prescritorComponent: PrescritorEditComponent = null;
    private validationErrorWrapper = new ValidationErrorWrapper(this.$validator);

    private loadPacienteComponent = false;
    private loadPosologiaComponent = false;
    private loadPrescritorComponent = false;

    GET_IS_ESTEREIS: () => Promise<boolean>;
    isEstereis = false;

    @Prop({ type: ManipulacaoOrdemModel, required: true }) model: ManipulacaoOrdemModel;
    @Prop({ type: Number, required: true }) index: number;
    @Prop({ type: Array, required: true }) posologiaOptions;

    private customComboTextPaciente = (p: PacienteModel) => ({
        value: p.id,
        text: `${p.nome} - (${p.clienteDescricao})`,
    });

    private customComboTextPrescritor = getPrescritorCombo;

    private loadPosologia() {
        this.$emit("load-posologia");
    }

    private async onAddNewPaciente(closeModal) {
        if (await this.pacienteComponent.save()) {
            this.loadPacienteComponent = false;
            closeModal();
        }
    }

    private async onAddNewPosologia(closeModal) {
        if (await this.posologiaComponent.save()) {
            this.loadPosologiaComponent = false;
            closeModal();
        }
    }

    private async onAddNewPrescritor(closeModal) {
        if (await this.prescritorComponent.save()) {
            this.loadPrescritorComponent = false;
            closeModal();
        }
    }

    private async openComboNewPaciente() {
        this.validationErrorWrapper.clearErrors();
        this.loadPacienteComponent = true;

        while (!this.$refs.pacienteComponent) await Delay(5);
        this.pacienteComponent = this.$refs.pacienteComponent as PacienteEditComponent;

        this.pacienteComponent.modelId = 0;
        this.pacienteComponent.load();
    }

    private async openComboNewPosologia() {
        this.validationErrorWrapper.clearErrors();
        this.loadPosologiaComponent = true;

        while (!this.$refs.posologiaComponent) await Delay(5);
        this.posologiaComponent = this.$refs.posologiaComponent as PosologiaEditComponent;

        this.posologiaComponent.modelId = 0;
        this.posologiaComponent.load();
    }

    private async openComboNewPrescritor() {
        this.validationErrorWrapper.clearErrors();
        this.loadPrescritorComponent = true;

        while (!this.$refs.prescritorComponent) await Delay(5);
        this.prescritorComponent = this.$refs.prescritorComponent as PrescritorEditComponent;

        this.prescritorComponent.modelId = 0;
        this.prescritorComponent.load().withLoading();
    }

    private async openComboEditPrescritor(id: number) {
        this.loadPrescritorComponent = true;

        while (!this.$refs.prescritorComponent) await Delay(5);
        this.prescritorComponent = this.$refs.prescritorComponent as PrescritorEditComponent;

        this.prescritorComponent.modelId = id;
        this.prescritorComponent.load().withLoading();
    }

    private async openComboEditPosologia(id: number) {
        this.loadPosologiaComponent = true;

        while (!this.$refs.posologiaComponent) await Delay(5);
        this.posologiaComponent = this.$refs.posologiaComponent as PosologiaEditComponent;

        this.posologiaComponent.modelId = id;
        this.posologiaComponent.load();
    }

    private async openComboEditPaciente(id: number) {
        this.loadPacienteComponent = true;

        while (!this.$refs.pacienteComponent) await Delay(5);
        this.pacienteComponent = this.$refs.pacienteComponent as PacienteEditComponent;

        this.pacienteComponent.modelId = id;
        this.pacienteComponent.load();
    }

    private async onPacienteSaveOk(modelNovoPaciente: PacienteModel) {
        this.model.pacienteId = modelNovoPaciente.id;
    }

    private async onPosologiaSaveOk(modelNovaPosologia: PosologiaModel) {
        this.loadPosologia();
        this.model.posologiaId = modelNovaPosologia.id;
    }

    private async onPrescritorSaveOk(modelNovoPrescritor: PrescritorModel) {
        this.model.prescritorId = modelNovoPrescritor.id;
    }

    //@ts-ignore
    @Watch("model.prescritorId")
    private async onChangePrescritor(newValue: number, oldValue: number) {
        try {
            await new ConvenioService().getFromPrescritor(this.model.prescritorId).then(res => res.json());

            await this.validarPrescritor();
        } catch {}

        if (this.model.prescritorId && (!this.model.id || (this.model.id && oldValue != 0 && newValue != oldValue))) {
            const prescritor = await new PrescritorService()
                .get(this.model.prescritorId)
                .resolveWithJSON<PrescritorModel>();

            if (prescritor.observacao) {
                await this.$showWarning(
                    this.$t("__.Components.parent.manipulacao.edit_vue_html.msgPrescritorObs"),
                    prescritor.observacao,
                );
            }
        }
    }

    public getModel() {
        const modelAux = new ManipulacaoOrdemModel();
        modelAux.updateFrom(this.model);
        return modelAux;
    }

    private async validarPrescritor() {
        try {
            this.model.aprovouDesconto = false;

            const response = await this.$showQuestion(
                this.$t("__.ts.atencao"),
                "Este prescritor esta vinculado a um convenio, deseja atualizar os valores da venda?",
            );
            if (response) {
                this.model.aprovouDesconto = true;
            }
        } catch {}
    }

    public async validate() {
        try {
            return await this.$validator.validateAll();
        } catch {
            return false;
        }
    }

    private async mounted() {
        this.isEstereis = await this.GET_IS_ESTEREIS();
        setTimeout(() => {
            this.pacienteComponent = this.$refs.pacienteComponent as PacienteEditComponent;
            this.posologiaComponent = this.$refs.posologiaComponent as PosologiaEditComponent;
            this.prescritorComponent = this.$refs.prescritorComponent as PrescritorEditComponent;
            this.validationErrorWrapper.clearErrors();
        }, 250);
    }
}
