import { formatMoney } from "../common/number";

const Filter = {
    install(Vue) {
        Vue.filter("filterMoeda", function (value) {
            return formatMoney(value);
        });
    },
};

export default Filter;
