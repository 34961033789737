import LoadingComponent from "@/components/child/loadingComponent/loadingComponent";
import LoadingModalComponent from "@/components/child/loadingmodal/loadingmodal";

import Bus from "./events/bus";

declare global {
    interface Promise<T> {
        withLoading(loading?: LoadingComponent | LoadingModalComponent): Promise<T>;
    }
}

Promise.prototype.withLoading = function <T>(this: Promise<T>, loading?: LoadingComponent): Promise<T> {
    loading ? loading.show() : Bus.$emit("global-loading-component-show");
    return this.then(() => {
        loading ? loading.hide() : Bus.$emit("global-loading-component-hide");
        return this;
    }).catch(exception => {
        loading ? loading.hide() : Bus.$emit("global-loading-component-hide");
        throw exception;
    });
};

export {};
