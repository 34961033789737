import BaseModel from "./base/baseModel";
import MesesDoAno from "./enum/mesesDoAno";
import PeriodicidadeBalanco from "./enum/periodicidadeBalanco";
import TipoBalanco from "./enum/tipoBalanco";

export default class GerarRelatorioSngpcManual extends BaseModel {
    AutorizacaoAnvisa: string;
    AutorizacaoMapa: string;
    TipoBalanco: TipoBalanco;
    Periodicidade: PeriodicidadeBalanco;
    Ano: number;
    Regiao: string;
    Cidade: string;
    Mes: MesesDoAno;
}
