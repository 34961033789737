import comboComponent from "@/components/child/form/combo.vue";
import dataTooltipComponent from "@/components/child/form/datatooltip.vue";
import dateTimePickerComponent from "@/components/child/form/datetimepicker.vue";
import moedaComponent from "@/components/child/form/moeda.vue";
import { Component } from "@/decorators";
import MetaModel from "@/models/metaModel";
import MetaService from "@/services/metaService";

import EditComponentBase from "../editComponentBase";

@Component({
    components: {
        comboComponent,
        dataTooltipComponent,
        moedaComponent,
        dateTimePickerComponent,
    },
})
export default class MetaEditComponent extends EditComponentBase<MetaModel> {
    mesesOptions: Array<Object> = [
        { text: "Janeiro", value: 1 },
        { text: "Fevereiro", value: 2 },
        { text: "Mar\u00e7o", value: 3 },
        { text: "Abril", value: 4 },
        { text: "Maio", value: 5 },
        { text: "Junho", value: 6 },
        { text: "Julho", value: 7 },
        { text: "Agosto", value: 8 },
        { text: "Setembro", value: 9 },
        { text: "Outubro", value: 10 },
        { text: "Novembro", value: 11 },
        { text: "Dezembro", value: 12 },
    ];

    ultimo = 0;
    mes = 0;
    ano = 0;

    public async onLoadModel(model: MetaModel) {
        this.mes = +model.mesAno.slice(0, 2);
        this.ano = +model.mesAno.slice(3, 7);
    }

    public async onPreSave() {
        if (this.model.dataInicio > this.model.dataFim) {
            await this.$showWarning(this.$t("__.ts.dadosInval"), this.$t("__.ts.dataFimMiorInicio"));
            return false;
        }
        this.model.mesAno = (this.mes < 10 ? "0" + this.mes : this.mes).toString() + "/" + this.ano.toString();
        return true;
    }

    private calculaValor(value: number) {
        if (value == 0) {
            if (this.ultimo == 1) {
                if (this.model.diasUteis > 0 && this.model.valorDiario > 0) {
                    this.model.valor = this.model.diasUteis * this.model.valorDiario;
                }
            } else if (this.ultimo == 2) {
                if (this.model.diasUteis > 0 && this.model.valor > 0) {
                    this.model.valorDiario = this.model.valor / this.model.diasUteis;
                }
            }
        } else if (value == 1) {
            if (this.model.diasUteis > 0 && this.model.valorDiario > 0) {
                this.model.valor = this.model.diasUteis * this.model.valorDiario;
            }
            this.ultimo = value;
        } else if (value == 2) {
            if (this.model.diasUteis > 0 && this.model.valor > 0) {
                this.model.valorDiario = this.model.valor / this.model.diasUteis;
            }
            this.ultimo = value;
        }
    }

    private verificaTamanhoMaximo() {
        if (this.ano.toString().length > 4) {
            this.ano = +this.ano.toString().slice(0, 4);
        }
    }

    private async beforeMount() {
        await this.setProps(new MetaService(), "metas", new MetaModel());

        if (!this.$route.params.id) {
            this.mes = new Date().getMonth() + 1;
            this.ano = new Date().getFullYear();
        }
    }
}
