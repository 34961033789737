import Vue from "vue";

import actionBarComponent from "@/components/child/actionBar/actionBar.vue";
import comboComponent from "@/components/child/form/combo.vue";
import { Component, Watch } from "@/decorators";
import EnumExtensions from "@/models/enum/extensions/enumExtensions";
import MesesDoAno from "@/models/enum/mesesDoAno";
import PDFService from "@/services/pdf/PDFService";

import "../../crud/crud.scss";

@Component({
    components: {
        comboComponent,
        actionBarComponent,
    },
})
export default class LivroRegistroEspecificoEditComponent extends Vue {
    private service = new PDFService("LivroRegistroEspecificoPDF");

    ano: number = null;
    mes = 0;
    tipoPeriodoLivroRegistroEspecificoId: number = null;
    tipoMedicamentoControladoId: number = null;

    tipoPeriodoLivroRegistroEspecificoOptions: Array<Object> = [
        { text: "1º Trimestre", value: 1 },
        { text: "2º Trimestre", value: 2 },
        { text: "3º Trimestre", value: 3 },
        { text: "4º Trimestre", value: 4 },
        { text: "Anual", value: 5 },
        { text: "Mensal", value: 6 },
    ];

    tipoMedicamentoControladoOptions: Array<Object> = [
        { text: "A1 e A2 - Entorpecentes", value: 1 },
        { text: "A3, B1 e B2 - Psicotrópicos", value: 2 },
        { text: "C1, C2, C4 e C5 - Controle Especial", value: 3 },
    ];

    mesesOptions = EnumExtensions.getNamesAndValuesOrderedByValues(MesesDoAno);

    private load() {
        this.ano = new Date().getFullYear();
    }

    private async save() {
        try {
            const isValid = await this.$validator.validateAll();

            if (isValid) {
                const filters = {
                    ano: this.ano,
                    periodo: this.tipoPeriodoLivroRegistroEspecificoId,
                    tipoMedicamento: this.tipoMedicamentoControladoId,
                    mes: this.mes,
                };

                await this.service.geraPDFWithFilters(filters);

                this.$showSuccess("Sucesso", "Arquivo foi gerado corretamente.");
            }
        } catch (e) {
            this.$showError("Erro", `Erro ao gerar o arquivo.\n Erro: ${e.message}`);
        }
    }

    @Watch("tipoPeriodoLivroRegistroEspecificoId")
    private onChangeTipoId() {
        if (this.tipoPeriodoLivroRegistroEspecificoId && this.tipoPeriodoLivroRegistroEspecificoId != 6) {
            this.mes = 0;
        }
    }

    private cancel() {
        this.$router.back();
    }

    private async mounted() {
        this.load();
    }
}
