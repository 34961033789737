import CapacidadeProducaoModel from "../models/capacidadeProducaoModel";
import nfetch from "../utils/events/nfetch";

export default class CapacidadeProducaoService {
    private controllerName = "CapacidadeProducao";

    public getByFranquia(): Promise<Response> {
        return nfetch(`/${this.controllerName}/GetByFranquia`, {
            credentials: "same-origin",
        });
    }

    public updateModels(models: CapacidadeProducaoModel[]): Promise<Response> {
        return nfetch(`/${this.controllerName}/UpdateModels`, {
            method: "post",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json; charset=UTF-8",
            },
            body: JSON.stringify(models),
        });
    }
}
