import BaseModel from "./base/baseModel";

export default class IncompatibilidadeProdutoModel extends BaseModel {
    id?: number = null;
    produtoId: number = null;
    produtoIncompativelId: number = null;
    produtoIncompativelDescricao = "";
    formaFarmaceuticaIncompativelId?: number = null;
    formaFarmaceuticaIncompativelDescricao = "";
}
