import comboComponent from "@/components/child/form/combo.vue";
import dateTimePickerComponent from "@/components/child/form/datetimepicker.vue";
import { GridColumn, GridColumnType } from "@/components/child/grid/gridColumn";
import { Component } from "@/decorators";
import TiposMovimentacaoEstoque from "@/models/enum/tiposMovimentacaoEstoque";
import EstoqueModel from "@/models/estoqueModel";
import MovimentacaoEstoqueModel, { MovimentacaoEstoqueListParameters } from "@/models/movimentacaoEstoqueModel";
import PaginationModel from "@/models/paginationModel";
import EstoqueService from "@/services/estoqueService";
import MovimentacaoEstoqueService from "@/services/movimentacaoEstoqueService";
import { convertToDateInput, convertToDateInputString } from "@/utils/common/date";

import ListComponentBase from "../listComponentBase";

@Component({
    components: {
        comboComponent,
        dateTimePickerComponent,
    },
})
export default class MovimentacaoEstoqueListComponent extends ListComponentBase<MovimentacaoEstoqueModel> {
    get gridColumns(): Array<GridColumn> {
        return [
            new GridColumn("dataMovimentacao", "Data", GridColumnType.DateTime),
            new GridColumn(
                "tipoMovimentacaoDescricao",
                "Tipo",
                GridColumnType.String,
                false,
                false,
                "",
                "",
                false,
                false,
            ),
            new GridColumn("descricaoEstoque", "Estoque", GridColumnType.String),
            new GridColumn("produtoDescricao", "Produto", GridColumnType.String),
            new GridColumn("quantidade", "Quantidade", GridColumnType.DecimalPonto),
            new GridColumn("unidadeMedida", "Un.Medida", GridColumnType.String),
            new GridColumn("loteDescricao", "Lote", GridColumnType.String),
        ];
    }

    private beforeMount() {
        this.setProps(null, "movimentacaoestoque", "dataMovimentacao");
        //@ts-ignore
        this.service = new MovimentacaoEstoqueService();
        this.gridSortOrder = "desc";

        this.loadEstoques();
    }

    isHidden = false;
    filtro: Array<Object> = [];
    tiposMovimentosOptions: Array<Object> = [
        { text: "Entrada", value: TiposMovimentacaoEstoque.Entrada },
        { text: "Sa\u00edda", value: TiposMovimentacaoEstoque.Saida },
        { text: "Transfer\u00eancia", value: TiposMovimentacaoEstoque.Transferencia },
    ];
    estoquesOptions: Array<Object> = [];

    dataFilter1: Date = null;
    dataFilter2: Date = null;
    tipoMovimentoFiltro?: number = null;
    estoqueFiltro?: number = null;

    public async loadMethod() {
        this.isHidden = false;

        const customParameters: MovimentacaoEstoqueListParameters = {
            filterKeyId: "",
            dataMovimentacaoIniStr: convertToDateInputString(this.filtro[0]),
            dataMovimentacaoFinStr: convertToDateInputString(this.filtro[1], true),
            tipoMovimentacaoId: this.filtro[2] ? <number>this.filtro[2] : null,
            estoqueId: this.filtro[3] ? <number>this.filtro[3] : null,
        };

        return await this.service
            .list(
                this.gridFilterKey,
                this.gridSortKey,
                this.gridSortOrder,
                this.pags ? 1 : this.pageIndex,
                this.pags ? 999999 : this.pageSize,
                customParameters,
            )
            .withLoading()
            .resolveWithJSON<PaginationModel<MovimentacaoEstoqueModel>>();
    }

    private getFormValues(listaFiltro) {
        this.filtro = [];

        this.filtro.push(listaFiltro.target.elements.dataFilter1.value);

        this.filtro.push(listaFiltro.target.elements.dataFilter2.value);

        this.filtro.push(this.tipoMovimentoFiltro);

        this.filtro.push(this.estoqueFiltro);

        this.load();
    }

    private async loadEstoques() {
        try {
            const data = await new EstoqueService().combo().resolveWithJSON<PaginationModel<EstoqueModel>>();
            this.estoquesOptions = data.list.map(item => ({ value: item.id, text: item.descricao }));
        } catch {}
    }

    public async afterLoadPagination() {
        if (this.paginationParams.filtros != null) {
            this.filtro = this.paginationParams.filtros;

            if (this.filtro.length >= 0) {
                if (this.filtro[0]) {
                    this.dataFilter1 = convertToDateInput(this.filtro[0].toString());
                }
                if (this.filtro[1]) {
                    this.dataFilter2 = convertToDateInput(this.filtro[1].toString());
                }
                if (this.filtro[2] != null && <number>this.filtro[2] > 0) {
                    this.tipoMovimentoFiltro = <number>this.filtro[2];
                }
                if (this.filtro[3] != null && <number>this.filtro[3] > 0) {
                    this.estoqueFiltro = <number>this.filtro[3];
                }
            }
        }
    }
}
