var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "crud crud-edit" },
    [
      _c(
        "actionBarComponent",
        {
          attrs: {
            title: _vm.$t(
              "__.Components.parent.statusPCP.view_vue_html.statusPCP"
            ),
            slots: 9,
            showSave: false,
          },
          scopedSlots: _vm._u([
            {
              key: "buttonSlot0",
              fn: function (ref) {
                var open = ref.open
                return _c(
                  "div",
                  {
                    attrs: {
                      title: _vm.$t(
                        "__.Components.parent.statusPCP.view_vue_html.pcpRapido"
                      ),
                    },
                    on: {
                      click: function ($event) {
                        _vm.onShowEtapasPcp(open)
                      },
                    },
                  },
                  [
                    _c("i", {
                      staticClass: "fa fa-list",
                      attrs: { "aria-hidden": "true" },
                    }),
                  ]
                )
              },
            },
          ]),
        },
        [
          _c(
            "div",
            { attrs: { slot: "modalSlot0" }, slot: "modalSlot0" },
            [_c("pcpRapidoComponent")],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "shortcutComponent",
        {
          ref: "shortcutComponent",
          attrs: { modalClass: "ss-modal" },
          on: { "selection-item": _vm.onConfirmAddNew },
        },
        [
          _c("div", { attrs: { slot: "component" }, slot: "component" }, [
            _c("div", { staticClass: "col-sm-12" }, [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "tooltip",
                      rawName: "v-tooltip",
                      value: _vm.errors.first("etapa"),
                      expression: "errors.first('etapa')",
                    },
                  ],
                  staticClass: "form-group",
                },
                [
                  _c(
                    "label",
                    {
                      staticClass: "control-label",
                      attrs: { for: "etapa", title: _vm.$t("__.obrigatorio") },
                    },
                    [
                      _c("span", { staticStyle: { color: "red" } }, [
                        _vm._v("*"),
                      ]),
                      _vm._v(
                        "\n                        " +
                          _vm._s(
                            _vm.$t(
                              "__.Components.parent.statusPCP.view_vue_html.etapa"
                            )
                          ) +
                          "\n                    "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("combo-component", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'",
                      },
                    ],
                    attrs: {
                      id: "etapa",
                      name: "etapa",
                      data: _vm.etapasOptions,
                      searchable: true,
                    },
                    model: {
                      value: _vm.etapaDestinoId,
                      callback: function ($$v) {
                        _vm.etapaDestinoId = $$v
                      },
                      expression: "etapaDestinoId",
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _c("form", [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-sm-10" }, [
            _c("div", { staticClass: "formulasDia" }, [
              _vm._v(
                "\n                    Manipulações do dia:\n                    "
              ),
              _c("span", { staticClass: "formulasDiaNumero" }, [
                _vm._v(_vm._s(_vm.gridData.length)),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-sm-2" }, [
            _c(
              "button",
              {
                staticClass: "buttonRefresh btn-secondary",
                attrs: { type: "button" },
                on: { click: _vm.onAtualizar },
              },
              [
                _c("i", {
                  staticClass: "fa fa-sync",
                  attrs: { "aria-hidden": "true" },
                }),
                _vm._v("\n                    Atualizar\n                "),
              ]
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-sm-3" }, [
            _c(
              "div",
              { staticClass: "form-group" },
              [
                _c(
                  "label",
                  { staticClass: "control-label", attrs: { for: "etapa" } },
                  [
                    _vm._v(
                      "\n                        " +
                        _vm._s(
                          _vm.$t(
                            "__.Components.parent.statusPCP.view_vue_html.etapa"
                          )
                        ) +
                        "\n                    "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("combo-component", {
                  attrs: {
                    id: "etapa",
                    name: "etapa",
                    data: _vm.etapasOptions,
                    searchable: true,
                  },
                  model: {
                    value: _vm.etapaId,
                    callback: function ($$v) {
                      _vm.etapaId = $$v
                    },
                    expression: "etapaId",
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-sm-3" }, [
            _c("div", { staticClass: "form-group" }, [
              _c(
                "label",
                { staticClass: "control-label", attrs: { for: "vendaCodigo" } },
                [
                  _vm._v(
                    "\n                        " +
                      _vm._s(
                        _vm.$t(
                          "__.Components.parent.statusPCP.view_vue_html.venda"
                        )
                      ) +
                      "\n                    "
                  ),
                ]
              ),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.venda,
                    expression: "venda",
                  },
                ],
                staticClass: "form-control",
                attrs: {
                  autocomplete: "off",
                  type: "text",
                  id: "vendaCodigo",
                  name: "vendaCodigo",
                },
                domProps: { value: _vm.venda },
                on: {
                  blur: _vm.onAtualizar,
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.venda = $event.target.value
                  },
                },
              }),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-sm-3" }, [
            _c(
              "div",
              { staticClass: "form-group" },
              [
                _c(
                  "label",
                  { staticClass: "control-label", attrs: { for: "etapa" } },
                  [
                    _vm._v(
                      "\n                        " +
                        _vm._s(
                          _vm.$t(
                            "__.Components.parent.statusPCP.view_vue_html.dataInicial"
                          )
                        ) +
                        "\n                    "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("date-time-picker-component", {
                  attrs: { id: "data", name: "data", pickTime: true },
                  model: {
                    value: _vm.dataInicial,
                    callback: function ($$v) {
                      _vm.dataInicial = $$v
                    },
                    expression: "dataInicial",
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-sm-3" }, [
            _c(
              "div",
              { staticClass: "form-group" },
              [
                _c(
                  "label",
                  { staticClass: "control-label", attrs: { for: "etapa" } },
                  [
                    _vm._v(
                      "\n                        " +
                        _vm._s(
                          _vm.$t(
                            "__.Components.parent.statusPCP.view_vue_html.dataFinal"
                          )
                        ) +
                        "\n                    "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("date-time-picker-component", {
                  attrs: { id: "data", name: "data", pickTime: true },
                  model: {
                    value: _vm.dataFinal,
                    callback: function ($$v) {
                      _vm.dataFinal = $$v
                    },
                    expression: "dataFinal",
                  },
                }),
              ],
              1
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-sm-12" },
            [
              _c(
                "grid-component",
                {
                  attrs: {
                    data: _vm.gridData,
                    columns: _vm.gridColumns,
                    editItemIcon: "fa-search",
                    editItemTitle: "Consulta Manipulação",
                    showAddNewButton: false,
                    showRemoveItem: false,
                    showSortIcons: false,
                    showTableFoot: true,
                    showTableHead: false,
                    showSelectCheckbox: true,
                  },
                  on: {
                    "edit-item": _vm.onConsulta,
                    "selected-values-changed": _vm.onSelectedValuesChanged,
                  },
                },
                [
                  _c(
                    "div",
                    {
                      attrs: { slot: "footer-extra-options" },
                      slot: "footer-extra-options",
                    },
                    [
                      _vm.gridSelectedValues.length > 0
                        ? _c(
                            "button",
                            {
                              staticClass: "btn btn-primary",
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  _vm.onApontarEmLote()
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(_vm.$t("__.ts.apontarEmLote")) +
                                  "\n                        "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ]
                  ),
                ]
              ),
            ],
            1
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }