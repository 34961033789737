import Vue from "vue";
import { mapState, mapMutations } from "vuex";

import actionBarComponent from "@/components/child/actionBar/actionBar.vue";
import { Component } from "@/decorators";
import UsuarioModel from "@/models/usuarioModel";
import UsuarioService from "@/services/usuarioService";
import { AppState, Mutations, SessionActions } from "@/store/store";

import "../crud.scss";

@Component({
    components: {
        actionBarComponent,
    },
    computed: mapState<AppState>({
        usuarioLogado: state => state.session.usuarioLogado,
    }),
    methods: mapMutations(["LOAD_CONFIGURACOES_FRANQUIA"] as Mutations),
})
export default class AlterarsenhaComponent extends Vue {
    // State computed props
    usuarioLogado: UsuarioModel;

    private service = new UsuarioService();

    novasenha = "";
    confirmarsenha = "";

    private async save() {
        if (this.novasenha != this.confirmarsenha) {
            return this.$showError(this.$t("__.ts.erro"), this.$t("__.ts.senhasDif"));
        }

        if (this.novasenha != "" && this.confirmarsenha != "") {
            const usuario = this.usuarioLogado;
            usuario.senha = this.novasenha;

            const sucesso = await this.service.alterarSenha(usuario).withLoading().resolveWithoutJSON();
            if (sucesso) {
                await this.$showInclusaoUpdate(usuario.id);
                this.$router.back();
            }
        } else {
            this.$showError(this.$t("__.ts.erro"), this.$t("__.ts.camposNaoVazios"));
        }
    }

    private cancel() {
        this.$router.back();
    }

    private async mounted() {
        await this.$store.dispatch(SessionActions.LOAD_USUARIO_LOGADO);

        Promise.all([this["LOAD_CONFIGURACOES_FRANQUIA"]()])
            .then(() => {})
            .catch(() => {});
    }
}
