import Vue from "vue";

import comboComponent from "@/components/child/form/combo.vue";
import dateTimePickerComponent from "@/components/child/form/datetimepicker.vue";
import fieldsetComponent from "@/components/child/form/fieldset.vue";
import { Component, Prop, Watch } from "@/decorators";
import EnumExtensions from "@/models/enum/extensions/enumExtensions";
import StatusConta from "@/models/enum/statusContas";
import TipoMovimentacao from "@/models/enum/tiposMovimentacao";
import ContaCorrenteModel from "@/models/financeiro/contaCorrenteModel";
import ContaGerencialModel from "@/models/financeiro/contaGerencialModel";
import FormaPagamentoModel from "@/models/financeiro/formaPagamentoModel";
import ContaPagarImportacaoModel from "@/models/notafiscaleletronica/contaPagarImportacaoModel";
import CobrancaNfeModel from "@/models/notafiscaleletronica/dados/informacoes/cobranca/cobrancaNfeModel";
import PaginationModel from "@/models/paginationModel";
import ContaCorrenteService from "@/services/financeiro/contaCorrenteService";
import ContaGerencialService from "@/services/financeiro/contaGerencialService";
import FormaPagamentoService from "@/services/financeiro/formaPagamentoService";

@Component({
    components: {
        fieldsetComponent,
        comboComponent,
        dateTimePickerComponent,
    },
})
export default class ImportacaoNfeContaPagarComponent extends Vue {
    @Prop({ type: ContaPagarImportacaoModel, required: true }) model: ContaPagarImportacaoModel;
    @Prop({ type: CobrancaNfeModel, default: null }) cobranca: CobrancaNfeModel;

    statusContasOptions: Array<Object> = EnumExtensions.getNamesAndValuesOrderedByNames(StatusConta).filter(
        p => p["value"] != StatusConta.ParcialmenteLiquidado,
    );

    contasGerenciaisOptions: Array<Object> = [];

    FormaPagamentoOptions: Array<Object> = [];

    ContaCorrenteOptions: Array<Object> = [];

    private async loadContasGerenciais() {
        try {
            const data = await new ContaGerencialService()
                .listByTipoMovimentacao("", 1, 999999, TipoMovimentacao.Saida)
                .then(r => r.json() as Promise<PaginationModel<ContaGerencialModel>>);
            this.contasGerenciaisOptions = data.list.map(item => ({ value: item.id, text: item.descricao }));
        } catch {}
    }

    private async loadFormasPagamento() {
        try {
            const data = await new FormaPagamentoService()
                .combo()
                .then(r => r.json() as Promise<PaginationModel<FormaPagamentoModel>>);
            this.FormaPagamentoOptions = data.list.map(item => ({ value: item.id, text: item.descricao }));
        } catch {}
    }

    private async loadContaCorrente() {
        try {
            const data = await new ContaCorrenteService()
                .combo()
                .then(r => r.json() as Promise<PaginationModel<ContaCorrenteModel>>);
            this.ContaCorrenteOptions = data.list.map(item => ({ value: item.id, text: item.descricao }));
        } catch {}
    }

    public async validateAll(gerarContaPagar) {
        if (!gerarContaPagar) {
            return true;
        } else {
            return await this.$validator.validateAll();
        }
    }

    public getError() {
        return this.$validator.errors.items;
    }

    //valida se existem vencimentos das duplicas, se nao  existe é a data da emissao da nf
    //@ts-ignore
    @Watch("cobranca.duplicatas")
    private ValidaVencimentoDuplicatas() {
        for (let i = 0; i < this.cobranca.duplicatas.length; i++) {
            if (this.cobranca.duplicatas[i].dataVencimento == null) {
                this.cobranca.duplicatas[i].dataVencimento = new Date();
                this.cobranca.duplicatas[i].statusContas = 2;
            }
        }
    }

    private mounted() {
        this.loadContasGerenciais();
        this.loadFormasPagamento();
        this.loadContaCorrente();
    }
}
