import { GridColumn, GridColumnType } from "@/components/child/grid/gridColumn";
import { Component } from "@/decorators";
import EnsaiosModel from "@/models/ensaiosModel";
import EnsaioService from "@/services/ensaioService";

import ListComponentBase from "../listComponentBase";

@Component
export default class EnsaioListComponent extends ListComponentBase<EnsaiosModel> {
    get gridColumns(): Array<GridColumn> {
        return [new GridColumn("descricao", this.$t("__.ts.descricao"), GridColumnType.String)];
    }

    private beforeMount() {
        this.setProps(new EnsaioService(), "ensaio", "descricao");
    }
}
