import Vue from "vue";

import Grid from "@/components/child/grid/grid";
import gridComponent from "@/components/child/grid/grid.vue";
import { GridColumn, GridColumnType } from "@/components/child/grid/gridColumn";
import { Component, Prop, Watch } from "@/decorators";
import ClinicaModel from "@/models/clinica/clinicaModel";
import PaginationModel from "@/models/paginationModel";
import ClinicaService from "@/services/clinicaService";

@Component({
    components: {
        gridComponent,
    },
})
export default class ClinicaListComponent extends Vue {
    private service = new ClinicaService();
    private gridcomponent: Grid = null;

    startFilterKey = "";
    gridFilterKey = "";
    gridSortKey = "nome";
    gridSortOrder = "asc";
    gridData: Array<ClinicaModel> = [];
    get gridColumns(): Array<GridColumn> {
        return [
            new GridColumn("nome", "Nome", GridColumnType.String, false, false, "", "", true),
            new GridColumn("cnpj", "CNPJ", GridColumnType.String, false, false, "", "", true),
            new GridColumn("celular", "Celular", GridColumnType.String, false, false, "", "", true, false),
        ];
    }

    pageIndex = 1;
    pageSize = 20;
    total = 0;

    pags = false;
    tipoDocGerar: number = null;

    @Prop({ type: Number, default: 20 }) pgSize: number;

    private async load() {
        try {
            const clinicas = await this.service
                .list(
                    this.gridFilterKey,
                    this.gridSortKey,
                    this.gridSortOrder,
                    this.pags ? 1 : this.pageIndex,
                    this.pags ? 999999 : this.pageSize,
                )
                .withLoading()
                .resolveWithJSON<PaginationModel<ClinicaModel>>();

            this.gridData = clinicas.list;
            this.total = clinicas.total;

            if (this.pags) {
                if (this.tipoDocGerar == 0) {
                    setTimeout(() => {
                        this.gridcomponent.gerarXls();
                    }, 2000);
                }

                if (this.tipoDocGerar == 1) {
                    setTimeout(() => {
                        this.gridcomponent.gerarPdf();
                    }, 2000);
                }

                if (this.tipoDocGerar == 2) {
                    setTimeout(() => {
                        this.gridcomponent.gerarImpressao();
                    }, 2000);
                }

                setTimeout(() => {
                    this.pags = false;
                }, 2000);
            }
        } catch {}
    }

    private onCreateItem() {
        this.$router.push("/clinica-edicao");
    }

    private onEditItem(model: ClinicaModel) {
        this.$router.push("/clinica-edicao/" + model.id);
    }

    private async onRemoveItem(model: ClinicaModel) {
        if (await this.$showExclusaoQuestion(`${this.$t("__.ts.desejaExcl")}${model.nome}?`)) {
            try {
                if (await this.service.delete(model.id).withLoading().resolveWithoutJSON()) {
                    this.load();
                    this.$showExclusao();
                }
            } catch {}
        }
    }

    private onChangeFilterKey(filterKey: string) {
        this.gridFilterKey = filterKey;
        this.load();
    }

    private onChangeSort(sortKey: string, sortOrder: string) {
        this.gridSortKey = sortKey;
        this.gridSortOrder = sortOrder;
        this.load();
    }

    private onChangePage(pageIndex: number) {
        this.pageIndex = pageIndex;
        this.load();
    }

    @Watch("pags")
    private onPags() {
        this.load();
    }

    private mounted() {
        this.gridcomponent = this.$refs.gridcomponent as Grid;
        this.load();
    }
}
