import BaseModel from "./base/baseModel";
import AreaAtuacao from "./enum/areaAtuacao";
import NaturezaOperacoes from "./enum/naturezaOperacoes";
import tipoCr from "./enum/tipoCr";
import TiposFranquia from "./enum/tiposFranquia";

export default class FranquiaModel extends BaseModel {
    id?: number = null;
    razaoSocial: string = null;
    nomeFantasia: string = null;
    redeDescricao: string = null;
    redeId?: number = null;
    tipoFranquia?: TiposFranquia = null;
    cnpj: string = null;
    inscricaoEstadual: string = null;
    inscricaoMunicipal: string = null;
    logradouro: string = null;
    complemento: string = null;
    numero?: number = null;
    cep: string = null;
    bairro: string = null;
    cidadeId?: number = null;
    estadoId?: number = null;
    telefone: string = null;
    celular: string = null;
    email: string = null;
    farmaceuticoNome: string = null;
    crf: string = null;
    cpfSngpc: string = null;
    licenca: string = null;
    autoridadeSanitaria: string = null;
    matrizId?: number = null;
    naturezaOperacoes: NaturezaOperacoes = null;
    opcaoSimplesNacional = false;
    incentivadorCultural = false;
    areaAtuacao: AreaAtuacao = 0;
    tipoCr: tipoCr = 0;
    utilizaDotz = false;
    rotuloLoteId: number = null;
    rotuloManipulacaoId: number = null;
    veterinarioMapa: string = null;
    veterinarioMapaCRMV: string = null;
    autorizacaoMapa: string;
    autorizacaoAnvisa: string = null;
    complementoWhatsAppOrcamento: string = null;
    isFranquiaMatriz = false;
    isFranquiaMatrizOrFilial = false;
    dataValidadeLicencaSanitaria?: Date = null;

    // Módulos
    moduloApp = false;
    moduloCompras = false;
    moduloWhatsApp = false;
    moduloAutomatizacaoContinuos = false;
}

export interface FranquiaListParameters {
    ignoreCurrentFranquia?: boolean;
}
