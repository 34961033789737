export class GridAction {
    constructor(name: string, tooltipText: string, iconClass: string, color: string, enabled = true) {
        this.name = name;
        this.tooltipText = tooltipText;
        this.iconClass = iconClass;
        this.color = color;
        this.enabled = enabled;
    }

    name: string;
    tooltipText: string;
    iconClass: string;
    color: string;
    enabled: boolean;
}
