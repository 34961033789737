import Vue from "vue";
import { mapGetters, mapState } from "vuex";

import actionBarComponent from "@/components/child/actionBar/actionBar.vue";
import portletComponent from "@/components/child/containers/portlet.vue";
import buttonScComponent from "@/components/child/form/buttonSc.vue";
import comboComponent from "@/components/child/form/combo.vue";
import dataTooltipComponent from "@/components/child/form/datatooltip.vue";
import decimalComponent from "@/components/child/form/decimal.vue";
import decimalComSinalCustomComponent from "@/components/child/form/decimalComSinalCustom.vue";
import fieldsetComponent from "@/components/child/form/fieldset.vue";
import searchComboComponent from "@/components/child/searchCombo/search.vue";
import { Component, Watch } from "@/decorators";
import PreLoadPackModel from "@/models/auxiliar/preLoadPackModel";
import ConfiguracaoFranquiaModel from "@/models/configuracaoFranquia/configuracaoFranquiaModel";
import DinamizacaoModel from "@/models/dinamizacaoModel";
import Configuracoes from "@/models/enum/configuracao/configuracoes";
import OrigemProdutoLote from "@/models/enum/origemProdutoLote";
import KitEmbalagemItemModel from "@/models/kitEmbalagemItemModel";
import KitEmbalagemModel from "@/models/kitEmbalagemModel";
import MovimentacaoEstoqueItemModel from "@/models/movimentacaoEstoqueItemModel";
import PaginationModel from "@/models/paginationModel";
import ProdutoLoteModel from "@/models/produtoLoteModel";
import IndicesConversoesUnidadeMedidasService from "@/services/indicesConversoesUnidadeMedidaService";
import KitEmbalagemService from "@/services/kitEmbalagemService";
import ProdutoLoteService from "@/services/produtoLoteService";
import { AppState, Getters } from "@/store/store";
import arithmeticHelper from "@/utils/common/arithmeticHelper";
import { getProdutoLoteCombo } from "@/utils/common/combo/combotext";
import { addDays } from "@/utils/common/date";
import Delay from "@/utils/common/delay";

import "../crud/crud.scss";
import "./view.scss";

@Component({
    components: {
        actionBarComponent,
        buttonScComponent,
        comboComponent,
        dataTooltipComponent,
        decimalComponent,
        decimalComSinalCustomComponent,
        fieldsetComponent,
        searchComboComponent,
        portletComponent,
    },
    computed: {
        ...mapState<AppState>({
            preLoadList: state => state.preLoad.preLoadList,
            loadedList: state => state.preLoad.loadedList,
        }),
        ...mapGetters(["GET_CONFIG_FRANQUIA"] as Getters),
        unidadeMedidaDescricao() {
            if (this.model.unidadeMedidaId) {
                return this.unidadeMedidasOptions.find(p => p["value"] == this.model.unidadeMedidaId)["sigla"];
            }
            return "";
        },
    },
})
export default class DinamizacaoComponent extends Vue {
    // State computed props
    loadedList: boolean;
    preLoadList: PreLoadPackModel;
    GET_CONFIG_FRANQUIA: (configuracao: Configuracoes) => ConfiguracaoFranquiaModel;

    model = new DinamizacaoModel();
    indicesConversoesUnidadeMedidasService = new IndicesConversoesUnidadeMedidasService();
    private kitEmbalagemService = new KitEmbalagemService();

    produtoLoteService = new ProdutoLoteService();

    produtosOptions: Array<Object> = [];
    produtoLotesOptions: Array<Object> = [];
    produtoLotesVeiculoOptions: Array<Object> = [];
    estoqueOptions: Array<Object> = [];
    unidadeMedidasOptions: Array<Object> = [];
    kitEmbalagensOptions: Array<Object> = [];
    disabled = false;
    produtoReferencia = "";
    produtoVeiculo = "";
    metodoDescricao = "";
    dinamizacaoCount = [];
    quantidadeDinamizacaoVeiculo = 0;
    quantidadeProdutoReferencia = 0;
    kitEmbalagemItem: KitEmbalagemItemModel[] = [];
    movimentacaoSaida: Array<MovimentacaoEstoqueItemModel> = [];

    metodoOptions: Array<Object> = [
        { value: 1, text: "CH" },
        { value: 2, text: "DH" },
    ];

    public async save() {
        try {
            const isValid = await this.$validator.validateAll();
            if (isValid) {
                if (this.model.produtoLoteId == 0 || this.model.produtoLoteVeiculoId == 0) {
                    return await this.$showWarning(this.$t("__.ts.atencao"), this.$t("__.ts.necessarioSelecionarLote"));
                }
                if (this.model.kitEmbalagemId == 0 || this.model.kitEmbalagemId == null) {
                    return await this.$showWarning(this.$t("__.ts.atencao"), this.$t("__.ts.necessarioSelecionarKit"));
                }
                this.disabled = true;
                this.gerarDinamizacao();
            } else {
                this.$focusErrorField();
            }
        } catch {}
    }

    @Watch("model.produtoId")
    private async onChangeProdutoId() {
        try {
            this.produtoLotesOptions = [];

            if (this.model.produtoId) {
                const produtoLote = await this.produtoLoteService
                    .listByProdutoIdAndEstoqueId(this.model.produtoId, this.model.estoqueId, 0.00001, 0, 1, 999999)
                    .withLoading()
                    .resolveWithJSON<PaginationModel<ProdutoLoteModel>>();

                this.produtoLotesOptions = produtoLote.list.map(getProdutoLoteCombo);

                if (this.produtoLotesOptions.length == 1) {
                    this.model.produtoLoteId = this.produtoLotesOptions[0]["value"];
                }
            }
        } catch {}
    }

    @Watch("model.produtoVeiculoId")
    private async onChangeProdutoVeiculoId() {
        try {
            this.produtoLotesVeiculoOptions = [];

            if (this.model.produtoVeiculoId) {
                const produtoLote = await this.produtoLoteService
                    .listByProdutoIdAndEstoqueId(
                        this.model.produtoVeiculoId,
                        this.model.estoqueId,
                        this.model.quantidadeNecessaria,
                        0,
                        1,
                        20,
                    )
                    .withLoading()
                    .resolveWithJSON<PaginationModel<ProdutoLoteModel>>();

                this.produtoLotesVeiculoOptions = produtoLote.list.map(getProdutoLoteCombo);

                if (this.produtoLotesVeiculoOptions.length == 1) {
                    this.model.produtoLoteVeiculoId = this.produtoLotesVeiculoOptions[0]["value"];
                }
            }
        } catch {}
    }

    @Watch("model.quantidade")
    @Watch("model.dinamizacao")
    @Watch("model.unidadeMedidaId")
    @Watch("model.unidadeMedidaIdReferencia")
    private async onCalcularQuantidadeNecessaria() {
        const indiceConversao = await this.indicesConversoesUnidadeMedidasService.getIndiceConversao(
            this.model.unidadeMedidaIdReferencia,
            this.model.unidadeMedidaId,
        );

        this.model.quantidadeNecessaria = arithmeticHelper.round(
            this.model.quantidade * this.model.dinamizacao -
                indiceConversao * this.model.quantidadeReferencia * this.model.dinamizacao,
            2,
        );
    }

    private async gerarDinamizacao() {
        this.metodoDescricao = this.metodoOptions.find(p => p["value"] === this.model.metodo)["text"];
        this.produtoVeiculo = this.produtosOptions.find(p => p["value"] === this.model.produtoVeiculoId)["text"];
        this.produtoReferencia = this.produtosOptions.find(p => p["value"] === this.model.produtoId)["text"];

        const indiceConversao = await this.indicesConversoesUnidadeMedidasService.getIndiceConversao(
            this.model.unidadeMedidaIdReferencia,
            this.model.unidadeMedidaId,
        );

        this.quantidadeProdutoReferencia = arithmeticHelper.round(indiceConversao * this.model.quantidadeReferencia, 2);

        if (this.model.kitEmbalagemId > 0) {
            const retorno = await this.kitEmbalagemService
                .get(this.model.kitEmbalagemId)
                .withLoading()
                .resolveWithJSON<KitEmbalagemModel>();

            this.kitEmbalagemItem = retorno.kitEmbalagemItens;

            const kit = await this.kitEmbalagemService
                .get(this.model.kitEmbalagemId)
                .withLoading()
                .resolveWithJSON<KitEmbalagemModel>();

            for await (const item of kit.kitEmbalagemItens) {
                const produtoLote = await this.produtoLoteService
                    .listByProdutoIdAndEstoqueId(item.produtoId, this.model.estoqueId, 0.00001, 0, 1, 999999)
                    .withLoading()
                    .resolveWithJSON<PaginationModel<ProdutoLoteModel>>();

                if (produtoLote.list.length == 0) {
                    this.disabled = false;
                    return this.$showError(
                        this.$t("__.ts.erro"),
                        `O produto ${item.produtoDescricao} não possui lote disponível`,
                    );
                }

                const produto = produtoLote.list.find(item => item.estoqueProdutoLote.find(x => x.produtoLoteId > 0))
                    .estoqueProdutoLote[0];

                const saidaKit = new MovimentacaoEstoqueItemModel();
                saidaKit.produtoLote = null;
                saidaKit.produtoLoteId = produto.produtoLoteId;
                saidaKit.quantidade = item.quantidade;
                this.movimentacaoSaida.push(saidaKit);
            }
        }

        this.quantidadeDinamizacaoVeiculo = arithmeticHelper.round(
            this.model.quantidadeNecessaria / this.model.dinamizacao,
            2,
        );

        this.dinamizacaoCount = Array(this.model.dinamizacao * 1).fill(null);
    }

    public async gerarLote() {
        const movimentacaoEntradaItem = new MovimentacaoEstoqueItemModel();
        movimentacaoEntradaItem.produtoLote = null;
        movimentacaoEntradaItem.quantidade = this.model.quantidade;
        movimentacaoEntradaItem.unidadeMedidaEstoqueId = this.model.unidadeMedidaId;

        const movimentacaoEntrada: Array<MovimentacaoEstoqueItemModel> = [];
        movimentacaoEntrada.push(movimentacaoEntradaItem);

        const saidaVeiculo = new MovimentacaoEstoqueItemModel();
        saidaVeiculo.produtoLote = null;
        saidaVeiculo.produtoLoteId = this.model.produtoLoteVeiculoId;
        saidaVeiculo.quantidade = this.model.quantidadeNecessaria;
        this.movimentacaoSaida.push(saidaVeiculo);

        const saidaProdutoReferencia = new MovimentacaoEstoqueItemModel();
        saidaProdutoReferencia.produtoLote = null;
        saidaProdutoReferencia.produtoLoteId = this.model.produtoLoteId;
        saidaProdutoReferencia.quantidade = this.model.quantidadeReferencia;
        saidaProdutoReferencia.unidadeMedidaEstoqueId = this.model.unidadeMedidaIdReferencia;

        this.movimentacaoSaida.push(saidaProdutoReferencia);

        const descricao = this.produtoLotesOptions.find(item => item["value"] == this.model.produtoLoteId)["text"];

        const produtoLote = await this.produtoLoteService
            .get(this.model.produtoLoteId)
            .withLoading()
            .resolveWithJSON<ProdutoLoteModel>();

        produtoLote.id = null;
        produtoLote.descricao = `${descricao} ${this.model.dinamizacao} ${this.metodoDescricao}`;
        produtoLote.origemProdutoLote = OrigemProdutoLote.Dinamizacao;
        produtoLote.quantidadeAtual = 0;
        produtoLote.dataFabricacao = new Date();
        produtoLote.dataValidade = addDays(this.model.validade, new Date());
        produtoLote.movimentacoesEntradaEstoque = movimentacaoEntrada;
        produtoLote.movimentacoesSaidaEstoque = this.movimentacaoSaida;

        const response = await this.produtoLoteService
            .insertProdutoDinamizado(produtoLote)
            .withLoading()
            .resolveWithoutJSON();

        if (response) {
            this.$showSuccess(this.$t("__.ts.sucesso"), this.$t("__.ts.loteSucesso"));
            this.$router.back();
        }
    }

    private async getPreLoadPack() {
        while (!this.loadedList) await Delay(100);

        this.produtosOptions = this.preLoadList.produtosCombo();
        this.kitEmbalagensOptions = this.preLoadList.kitEmbalagemCombo();
        this.unidadeMedidasOptions = this.preLoadList.unidadeMedidaCombo();
        this.estoqueOptions = this.preLoadList.estoquesCombo();

        this.model.estoqueId = this.GET_CONFIG_FRANQUIA(Configuracoes.EstoquePadrao).estoqueId;
    }

    private mounted() {
        this.getPreLoadPack();
    }
}
