var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { ref: "rotulo", style: _vm.frameExternoStyle }, [
    _c("div", { style: _vm.frameInternoStyle }, [
      _vm.model.posicaoQrCode && !_vm.model.dadosId
        ? _c("div", { style: _vm.qrCodeStyle })
        : _vm.model.posicaoQrCode &&
          _vm.model.dadosId > 0 &&
          (_vm.barCodeBase64 || _vm.qrCodeBase64)
        ? _c("img", {
            style: _vm.qrCodeStyle,
            attrs: {
              src:
                _vm.model.posicaoQrCode == _vm.PosicaoRotulo.Baixo ||
                _vm.model.posicaoQrCode == _vm.PosicaoRotulo.Cima
                  ? _vm.barCodeBase64
                  : _vm.qrCodeBase64,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.model.posicaoLogo != _vm.PosicaoRotulo.Nenhuma
        ? _c("div", { style: _vm.logoStyle })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { style: _vm.rowContainerStyle },
        [
          _vm._l(_vm.linhas, function (linha, index) {
            return _c(
              "div",
              { key: index, style: _vm.getLinhaStyle(linha) },
              _vm._l(linha, function (campo) {
                return _c(
                  "div",
                  { key: campo.id, style: _vm.getCampoStyle(campo) },
                  [
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm.getStringExibicao(campo)) +
                        "\n                "
                    ),
                  ]
                )
              })
            )
          }),
          _vm._v(" "),
          _c("div", { style: _vm.observacaoManipulacaoStyle }, [
            _vm._v(_vm._s(_vm.model.observacao)),
          ]),
          _vm._v(" "),
          _vm.model.respTecDadosFranquia === 2 ||
          _vm.model.respTecDadosFranquia === 3 ||
          _vm.model.respTecDadosFranquia === 4
            ? _c("div", { style: _vm.infoFranquiaStyle }, [
                _vm.model.imprimirDadosFranquiaNegrito
                  ? _c("span", { style: _vm.spanStyle }, [
                      _c("b", [
                        _vm._v(
                          "\n                        " +
                            _vm._s(_vm.model.dadosResponsavelTecnicoFranquia) +
                            "\n                        " +
                            _vm._s(
                              _vm.model.respTecDadosFranquia === 4
                                ? " - " + _vm.model.dadosFranquia
                                : ""
                            ) +
                            "\n                    "
                        ),
                      ]),
                    ])
                  : _c("span", { style: _vm.spanStyle }, [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.model.dadosResponsavelTecnicoFranquia) +
                          "\n                    " +
                          _vm._s(
                            _vm.model.respTecDadosFranquia === 4
                              ? " - " + _vm.model.dadosFranquia
                              : ""
                          ) +
                          "\n                "
                      ),
                    ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          (_vm.model.respTecDadosFranquia === 1 ||
            _vm.model.respTecDadosFranquia === 3) &&
          _vm.model.respTecDadosFranquia !== 4
            ? _c("div", { style: _vm.infoFranquiaStyle }, [
                _vm.model.imprimirDadosFranquiaNegrito
                  ? _c("span", { style: _vm.spanStyle }, [
                      _c("b", [_vm._v(_vm._s(_vm.model.dadosFranquia))]),
                    ])
                  : _c("span", { style: _vm.spanStyle }, [
                      _vm._v(_vm._s(_vm.model.dadosFranquia)),
                    ]),
              ])
            : _vm._e(),
        ],
        2
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }