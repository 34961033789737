import ConfiguracaoRotuloModel from "../models/configuracaoRotuloModel";
import OrigemRotulo from "../models/enum/rotulo/origemRotulo";
import nfetch from "../utils/events/nfetch";

import { IService } from "./base/iService";

export default class ConfiguracaoRotuloService extends IService<ConfiguracaoRotuloModel> {
    constructor() {
        super("RotuloPersonalizado");
    }

    public replicar(configuracaoRotuloId: number, idFranquias: string): Promise<Response> {
        return nfetch(
            `/${this.controllerName}/Replicar?configuracaoRotuloId=${configuracaoRotuloId}&idFranquias=${idFranquias}`,
            {
                method: "post",
                credentials: "same-origin",
                headers: {
                    "Content-Type": "application/json; charset=UTF-8",
                    "XSRF-TOKEN": this.getCookie("XSRF-TOKEN"),
                },
            },
        );
    }

    public getCamposTabelaLote(): Promise<Response> {
        return nfetch(`/${this.controllerName}/GetCamposTabelaLote`, {
            method: "get",
            credentials: "same-origin",
        });
    }

    public getCamposTabelaManipulacao(): Promise<Response> {
        return nfetch(`/${this.controllerName}/GetCamposTabelaManipulacao`, {
            method: "get",
            credentials: "same-origin",
        });
    }

    public getRotuloLote(loteId: number, rotuloId = 0): Promise<Response> {
        //@ts-ignore
        return nfetch(`/${this.controllerName}/GetRotuloLote?id=${loteId}&rotuloId=${rotuloId}`, {
            method: "get",
            credentials: "same-origin",
        });
    }

    public getRotuloManipulacao(manipulacaoId: number, rotuloId = 0): Promise<Response> {
        //@ts-ignore
        return nfetch(`/${this.controllerName}/GetRotuloManipulacao?id=${manipulacaoId}&rotuloId=${rotuloId}`, {
            method: "get",
            credentials: "same-origin",
        });
    }

    public salvarRotulo(rotulos: ConfiguracaoRotuloModel[]): Promise<Response> {
        //@ts-ignore
        return nfetch(`/${this.controllerName}/SalvarRotulo`, {
            method: "post",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json; charset=UTF-8",
                "XSRF-TOKEN": this.getCookie("XSRF-TOKEN"),
            },
            body: JSON.stringify(rotulos),
        })
            .then((response: Response) => {
                if (response.ok) {
                    return response;
                } else {
                    return this.mensagemErro(response, "Save");
                }
            })
            .catch(() => {});
    }

    public comboByFranquiaId(franquiaId: number): Promise<Response> {
        return nfetch(`/${this.controllerName}/List?franquiaId=${franquiaId}`, {
            credentials: "same-origin",
        });
    }

    public getDadosFranquia(): Promise<Response> {
        return nfetch(`/${this.controllerName}/GetDadosFranquia`, {
            method: "get",
            credentials: "same-origin",
        });
    }

    public getRotulos(
        lotesIds: number[] = [],
        manipulacoesIds: number[] = [],
        vendaId = 0,
        origemRotulo: OrigemRotulo,
    ): Promise<Response> {
        //@ts-ignore
        return nfetch(
            `/${this.controllerName}/GetRotulos?lotesIds=${lotesIds}&manipulacoesIds=${manipulacoesIds}&vendaId=${vendaId}&origemRotulo=${origemRotulo}`,
            {
                method: "post",
                credentials: "same-origin",
                headers: {
                    "Content-Type": "application/json; charset=UTF-8",
                    "XSRF-TOKEN": this.getCookie("XSRF-TOKEN"),
                },
                body: JSON.stringify({ lotesIds, manipulacoesIds }),
            },
        );
    }
}
