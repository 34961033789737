var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "crud crud-edit" },
    [
      _c("actionBarComponent", {
        attrs: { title: _vm.$t("__.Crud.racaAnimal.edit_vue_html.titulo") },
        on: { onSave: _vm.save },
      }),
      _vm._v(" "),
      _c(
        "form",
        {
          attrs: { novalidate: "novalidate" },
          on: {
            submit: function ($event) {
              $event.preventDefault()
              _vm.save()
            },
          },
        },
        [
          _c("fieldset", [
            _c("div", { staticClass: "row form-group" }, [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "tooltip",
                      rawName: "v-tooltip",
                      value: _vm.errors.first("codigo"),
                      expression: "errors.first('codigo')",
                    },
                  ],
                  staticClass: "col-sm-6",
                },
                [
                  _c(
                    "label",
                    {
                      staticClass: "control-label",
                      attrs: { for: "nome", title: _vm.$t("__.obrigatorio") },
                    },
                    [
                      _c("span", { staticStyle: { color: "red" } }, [
                        _vm._v("*"),
                      ]),
                      _vm._v(
                        "\n                        " +
                          _vm._s(
                            _vm.$t("__.Crud.racaAnimal.edit_vue_html.descricao")
                          ) +
                          "\n                    "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.model.descricao,
                        expression: "model.descricao",
                      },
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required|max:256",
                        expression: "'required|max:256'",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: {
                      type: "text",
                      id: "descricao",
                      name: "descricao",
                      autocomplete: "off",
                      disabled: _vm.somenteConsulta,
                    },
                    domProps: { value: _vm.model.descricao },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.model, "descricao", $event.target.value)
                      },
                    },
                  }),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "tooltip",
                      rawName: "v-tooltip",
                      value: _vm.errors.first("especieAnimalId"),
                      expression: "errors.first('especieAnimalId')",
                    },
                  ],
                  staticClass: "col-sm-6",
                },
                [
                  _c(
                    "label",
                    {
                      staticClass: "control-label",
                      attrs: {
                        for: "especieAnimalId",
                        title: _vm.$t("__.obrigatorio"),
                      },
                    },
                    [
                      _c("span", { staticStyle: { color: "red" } }, [
                        _vm._v("*"),
                      ]),
                      _vm._v(
                        "\n                        " +
                          _vm._s(
                            _vm.$t(
                              "__.Crud.racaAnimal.edit_vue_html.especieAnimal"
                            )
                          ) +
                          "\n                    "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("combo-component", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'",
                      },
                    ],
                    attrs: {
                      id: "especieAnimalId",
                      name: "especieAnimalId",
                      data: _vm.especiesAnimalOptions,
                      searchable: false,
                      refresh: _vm.loadEspeciesAnimal,
                      disabled: _vm.somenteConsulta,
                    },
                    model: {
                      value: _vm.model.especieAnimalId,
                      callback: function ($$v) {
                        _vm.$set(_vm.model, "especieAnimalId", $$v)
                      },
                      expression: "model.especieAnimalId",
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("fieldset", [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-sm-12 newbtn" }, [
                _c("div", { staticClass: "form-group" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-success",
                      attrs: { type: "submit" },
                    },
                    [
                      _c("i", {
                        staticClass: "fa fa-save",
                        attrs: { "aria-hidden": "true" },
                      }),
                      _vm._v(
                        "\n                            " +
                          _vm._s(_vm.$t("__.Crud.salvar")) +
                          "\n                        "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-secondary",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          _vm.load()
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n                            " +
                          _vm._s(_vm.$t("__.Crud.limpar")) +
                          "\n                        "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-secondary",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          _vm.cancel()
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n                            " +
                          _vm._s(_vm.$t("__.Crud.voltar")) +
                          "\n                        "
                      ),
                    ]
                  ),
                ]),
              ]),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }