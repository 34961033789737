enum OrigemAtendimento {
    Balcao = 0,
    Telefone = 1,
    WhatsApp = 2,
    FaceBook = 3,
    Aplicativo = 4,
    ECommerce = 5,
    SAC = 6,
    Erros = 7,
}

export default OrigemAtendimento;
