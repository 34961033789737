import Vue from "vue";

import Ping from "ping.js";

import { Component } from "@/decorators";
import { editDateWithTime } from "@/utils/common/date";

import "./view.scss";

@Component
export default class StatusSistemaComponent extends Vue {
    status = "Testando conexão...";
    connectionStatus = "";
    circleColor = "";

    public async onPing() {
        this.status = "Testando conexão...";
        this.connectionStatus = "";
        this.circleColor = "";
        const pingCount = 20;
        let totalPing = 0;

        const check = new Ping();
        const url = "https://s1.pharmup.com.br";
        const start = new Date();

        for (let i = 0; i < pingCount; i++) {
            try {
                const result = await check.ping(url);
                totalPing += result;
            } catch {
                this.status = "Erro ao efetuar teste de conexão";
                return;
            }
        }

        const averagePing = totalPing / pingCount;
        this.status = `${editDateWithTime(start)} - Teste executado com sucesso.`;

        if (averagePing < 100) {
            this.circleColor = "green";
            this.connectionStatus = "Conexão boa";
        } else if (averagePing >= 100 && averagePing < 300) {
            this.circleColor = "yellow";
            this.connectionStatus = "Conexão razoável";
        } else {
            this.circleColor = "red";
            this.connectionStatus = "Conexão ruim";
        }
    }

    private async mounted() {
        Promise.all([this.onPing()])
            .withLoading()
            .catch(() => {});
    }
}
