import CurvaABCModel from "@/models/produto/curvaABCModel";
import nfetch from "@/utils/events/nfetch";

export default class CurvaABCService {
    public async definirCurvaABCProdutos(data: CurvaABCModel) {
        return nfetch(`/CurvaABC/DefinirCurvaABCProdutos?filters=${JSON.stringify(data)}`, {
            credentials: "same-origin",
        });
    }

    public async definirCurvaABCPrescritores(data: CurvaABCModel) {
        return nfetch(`/CurvaABC/DefinirCurvaABCPrescritores?filters=${JSON.stringify(data)}`, {
            credentials: "same-origin",
        });
    }

    public async getRelatorioXLS(data: CurvaABCModel): Promise<Response> {
        return nfetch(`/CurvaABC/GetRelatorioXLS?filters=${JSON.stringify(data)}`, {
            credentials: "same-origin",
        });
    }
}
