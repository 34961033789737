import Vue from "vue";

import { Component, Prop } from "@/decorators";
import MensagemAtendimentoWhatsAppModel from "@/models/whatsApp/atendimento/mensagemAtendimentoWhatsAppModel";

import "./mensagemTempo.scss";

@Component
export default class MensagemTempoComponent extends Vue {
    @Prop(Object) message: MensagemAtendimentoWhatsAppModel;
    @Prop({ type: Boolean, default: true }) showStatus: boolean;

    private formatTimestamp(timestamp: string) {
        const date = new Date(timestamp);
        return date.toLocaleString("pt-BR", {
            hour: "2-digit",
            minute: "2-digit",
        });
    }
}
