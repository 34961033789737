var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "crud crud-edit" },
    [
      _c("actionBarComponent", {
        attrs: { titleHtml: _vm.cancelamentoTitle, showSave: !_vm.isCancelado },
      }),
      _vm._v(" "),
      _c(
        "form",
        {
          attrs: { novalidate: "novalidate" },
          on: {
            submit: function ($event) {
              $event.preventDefault()
              _vm.save()
            },
          },
        },
        [
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-sm-12" },
              [
                _c(
                  "fieldset-component",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.vendaModel.cancelamentos.length > 0,
                        expression: "vendaModel.cancelamentos.length > 0",
                      },
                    ],
                    attrs: { title: "Cancelamentos", collapsed: false },
                  },
                  [
                    _c("div", { attrs: { slot: "rows" }, slot: "rows" }, [
                      _c(
                        "div",
                        [
                          _c("grid-component", {
                            attrs: {
                              data: _vm.vendaModel.cancelamentos,
                              columns: _vm.gridCancelamentoColumns,
                              showActionColumn: true,
                              showEditItem: false,
                              showExpand: false,
                              showRemoveItem: false,
                              showSortIcons: false,
                              showTableFoot: false,
                              showTableHead: false,
                              extraActions: _vm.extraActions,
                            },
                            on: { "extra-action": _vm.onExtraAction },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.esconderBotaoNovo,
                        expression: "!esconderBotaoNovo",
                      },
                    ],
                    staticClass: "row",
                  },
                  [
                    _c("div", { staticClass: "col-sm-12" }, [
                      _c("div", { staticClass: "form-group newbtn" }, [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-secondary",
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                _vm.newCancelamento()
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                                " +
                                _vm._s(_vm.$t("__.ts.addNovo")) +
                                "\n                            "
                            ),
                          ]
                        ),
                      ]),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "fieldset-component",
                  {
                    attrs: {
                      title: _vm.$t(
                        "__.Components.parent.venda.cancelamento_vue_html.dadosVenda"
                      ),
                      collapsed: false,
                    },
                  },
                  [
                    _c("div", { attrs: { slot: "rows" }, slot: "rows" }, [
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-sm-3" }, [
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "tooltip",
                                  rawName: "v-tooltip",
                                  value: _vm.errors.first("dataInclusao"),
                                  expression: "errors.first('dataInclusao')",
                                },
                              ],
                              staticClass: "form-group",
                            },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "control-label",
                                  attrs: { for: "dataInclusao" },
                                },
                                [
                                  _vm._v(
                                    "\n                                        " +
                                      _vm._s(
                                        _vm.$t(
                                          "__.Components.parent.venda.cancelamento_vue_html.dtVenda"
                                        )
                                      ) +
                                      "\n                                    "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("date-time-picker-component", {
                                attrs: {
                                  id: "dataInclusao",
                                  name: "dataInclusao",
                                  disabled: "disabled",
                                },
                                model: {
                                  value: _vm.vendaModel.dataInclusao,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.vendaModel,
                                      "dataInclusao",
                                      $$v
                                    )
                                  },
                                  expression: "vendaModel.dataInclusao",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-sm-3" }, [
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "tooltip",
                                  rawName: "v-tooltip",
                                  value: _vm.errors.first("clienteNome"),
                                  expression: "errors.first('clienteNome')",
                                },
                              ],
                              staticClass: "form-group",
                            },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "control-label",
                                  attrs: { for: "clienteNome" },
                                },
                                [
                                  _vm._v(
                                    "\n                                        " +
                                      _vm._s(
                                        _vm.$t(
                                          "__.Components.parent.venda.cancelamento_vue_html.nomeCliente"
                                        )
                                      ) +
                                      "\n                                    "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.vendaModel.clienteNome,
                                    expression: "vendaModel.clienteNome",
                                  },
                                ],
                                staticClass: "form-control",
                                attrs: {
                                  type: "text",
                                  id: "clienteNome",
                                  name: "clienteNome",
                                  disabled: "",
                                },
                                domProps: { value: _vm.vendaModel.clienteNome },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.vendaModel,
                                      "clienteNome",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                            ]
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-sm-3" }, [
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "tooltip",
                                  rawName: "v-tooltip",
                                  value: _vm.errors.first("valorFinal"),
                                  expression: "errors.first('valorFinal')",
                                },
                              ],
                              staticClass: "form-group",
                            },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "control-label",
                                  attrs: { for: "valorFinal" },
                                },
                                [
                                  _vm._v(
                                    "\n                                        " +
                                      _vm._s(
                                        _vm.$t(
                                          "__.Components.parent.venda.cancelamento_vue_html.vlrTotal"
                                        )
                                      ) +
                                      "\n                                    "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("moeda-component", {
                                staticClass: "form-control",
                                attrs: {
                                  id: "valorFinal",
                                  name: "valorFinal",
                                  disabled: "",
                                },
                                model: {
                                  value: _vm.vendaModel.valorFinal,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.vendaModel, "valorFinal", $$v)
                                  },
                                  expression: "vendaModel.valorFinal",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-sm-3" }, [
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "tooltip",
                                  rawName: "v-tooltip",
                                  value: _vm.errors.first("dataCancelamento"),
                                  expression:
                                    "errors.first('dataCancelamento')",
                                },
                              ],
                              staticClass: "form-group",
                            },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "control-label",
                                  attrs: { for: "dataCancelamento" },
                                },
                                [
                                  _vm._v(
                                    "\n                                        " +
                                      _vm._s(
                                        _vm.$t(
                                          "__.Components.parent.venda.cancelamento_vue_html.dtCancelamento"
                                        )
                                      ) +
                                      "\n                                    "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("date-time-picker-component", {
                                attrs: {
                                  id: "dataCancelamento",
                                  name: "dataCancelamento",
                                  disabled: "",
                                },
                                model: {
                                  value: _vm.cancelamentoModel.dataCancelamento,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.cancelamentoModel,
                                      "dataCancelamento",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "cancelamentoModel.dataCancelamento",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-sm-6" }, [
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "tooltip",
                                  rawName: "v-tooltip",
                                  value: _vm.errors.first(
                                    "usuarioCancelamento"
                                  ),
                                  expression:
                                    "errors.first('usuarioCancelamento')",
                                },
                              ],
                              staticClass: "form-group",
                            },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "control-label",
                                  attrs: { for: "usuarioCancelamento" },
                                },
                                [
                                  _vm._v(
                                    "\n                                        " +
                                      _vm._s(
                                        _vm.$t(
                                          "__.Components.parent.venda.cancelamento_vue_html.userCancelamento"
                                        )
                                      ) +
                                      "\n                                    "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value:
                                      _vm.cancelamentoModel
                                        .usuarioCancelamentoNome,
                                    expression:
                                      "cancelamentoModel.usuarioCancelamentoNome",
                                  },
                                ],
                                staticClass: "form-control",
                                attrs: {
                                  type: "text",
                                  id: "usuarioCancelamento",
                                  name: "usuarioCancelamento",
                                  disabled: "",
                                },
                                domProps: {
                                  value:
                                    _vm.cancelamentoModel
                                      .usuarioCancelamentoNome,
                                },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.cancelamentoModel,
                                      "usuarioCancelamentoNome",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                            ]
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-sm-6" }, [
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "tooltip",
                                  rawName: "v-tooltip",
                                  value: _vm.errors.first(
                                    "motivoCancelamentoId"
                                  ),
                                  expression:
                                    "errors.first('motivoCancelamentoId')",
                                },
                              ],
                              staticClass: "form-group",
                            },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "control-label",
                                  attrs: {
                                    for: "motivoCancelamentoId",
                                    title: _vm.$t("__.obrigatorio"),
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    { staticStyle: { color: "red" } },
                                    [_vm._v("*")]
                                  ),
                                  _vm._v(
                                    "\n                                        " +
                                      _vm._s(
                                        _vm.$t(
                                          "__.Components.parent.venda.cancelamento_vue_html.motivoCancelamento"
                                        )
                                      ) +
                                      "\n                                    "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("combo-component", {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: "required",
                                    expression: "'required'",
                                  },
                                ],
                                attrs: {
                                  id: "motivoCancelamentoId",
                                  name: "motivoCancelamentoId",
                                  data: _vm.motivoCancelamentoOptions,
                                  refresh: _vm.loadMotivoCancelamento,
                                  disabled: _vm.isCancelado,
                                },
                                model: {
                                  value:
                                    _vm.cancelamentoModel.motivoCancelamentoId,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.cancelamentoModel,
                                      "motivoCancelamentoId",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "cancelamentoModel.motivoCancelamentoId",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-sm-12" }, [
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "tooltip",
                                  rawName: "v-tooltip",
                                  value: _vm.errors.first(
                                    "observacaoCancelamento"
                                  ),
                                  expression:
                                    "errors.first('observacaoCancelamento')",
                                },
                              ],
                              staticClass: "form-group",
                            },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "control-label",
                                  attrs: {
                                    for: "observacaoCancelamento",
                                    title: _vm.$t("__.obrigatorio"),
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    { staticStyle: { color: "red" } },
                                    [_vm._v("*")]
                                  ),
                                  _vm._v(
                                    "\n                                        " +
                                      _vm._s(
                                        _vm.$t(
                                          "__.Components.parent.venda.cancelamento_vue_html.obsCancelamento"
                                        )
                                      ) +
                                      "\n                                    "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value:
                                      _vm.cancelamentoModel
                                        .observacaoCancelamento,
                                    expression:
                                      "cancelamentoModel.observacaoCancelamento",
                                  },
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: "required|max:1024",
                                    expression: "'required|max:1024'",
                                  },
                                ],
                                staticClass: "form-control",
                                attrs: {
                                  type: "text",
                                  id: "observacaoCancelamento",
                                  name: "observacaoCancelamento",
                                  disabled: _vm.isCancelado,
                                },
                                domProps: {
                                  value:
                                    _vm.cancelamentoModel
                                      .observacaoCancelamento,
                                },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.cancelamentoModel,
                                      "observacaoCancelamento",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                            ]
                          ),
                        ]),
                      ]),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "fieldset-component",
                  { attrs: { title: "Itens da Venda", collapsed: false } },
                  [
                    _c("div", { attrs: { slot: "rows" }, slot: "rows" }, [
                      _c(
                        "div",
                        [
                          _c("grid-component", {
                            attrs: {
                              data: _vm.cancelamentoModel.itens,
                              columns: _vm.gridColumns,
                              subColumns: _vm.gridColumnsCancelar,
                              subDataName: "itens",
                              conditionShowSubData: _vm.conditionShowSubData,
                              extendedColStartOpen: true,
                              multipleSelect: true,
                              showActionColumn: false,
                              showEditItem: false,
                              showExpand: true,
                              showRemoveItem: false,
                              showSelectCheckbox: !_vm.isCancelado,
                              showSortIcons: false,
                              showTableFoot: false,
                              showTableHead: false,
                              initialRowsChecked: _vm.initialCheckedItens,
                            },
                            on: {
                              "selected-values-changed": _vm.onSelectedChanged,
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "fieldset-component",
                  { attrs: { title: "Financeiro", collapsed: false } },
                  [
                    _c("div", { attrs: { slot: "rows" }, slot: "rows" }, [
                      _vm.vendaModel.contaReceberId != null
                        ? _c(
                            "div",
                            [
                              _vm.cancelamentoModel.id == null
                                ? _c("checkbox-component", {
                                    model: {
                                      value: _vm.realizarEstorno,
                                      callback: function ($$v) {
                                        _vm.realizarEstorno = $$v
                                      },
                                      expression: "realizarEstorno",
                                    },
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.cancelamentoModel.movimentacaoConta != null &&
                              _vm.realizarEstorno
                                ? _c("estorno-component", {
                                    attrs: {
                                      model:
                                        _vm.cancelamentoModel.movimentacaoConta,
                                      isCancelado: _vm.isCancelado,
                                    },
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.cancelamentoModel.movimentacaoConta == null &&
                              _vm.cancelamentoModel.id != 0
                                ? _c("div", { staticClass: "row" }, [
                                    _c("div", { staticClass: "col-sm-12" }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "alert alert-info",
                                          attrs: { role: "alert" },
                                        },
                                        [
                                          _vm._v(
                                            "\n                                        " +
                                              _vm._s(
                                                _vm.$t(
                                                  "__.Components.parent.venda.cancelamento_vue_html.naoFoiRealizEstornoVenda"
                                                )
                                              ) +
                                              "\n                                    "
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ])
                                : _vm._e(),
                            ],
                            1
                          )
                        : _c("div", [
                            _c("div", { staticClass: "row" }, [
                              _c("div", { staticClass: "col-sm-12" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "alert alert-info",
                                    attrs: { role: "alert" },
                                  },
                                  [
                                    _vm._v(
                                      "\n                                        " +
                                        _vm._s(
                                          _vm.$t(
                                            "__.Components.parent.venda.cancelamento_vue_html.naoHaMovimentoFinanceiro"
                                          )
                                        ) +
                                        "\n                                    "
                                    ),
                                  ]
                                ),
                              ]),
                            ]),
                          ]),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c("fieldset", [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-sm-12" }, [
                      _c("div", { staticClass: "form-group newbtn" }, [
                        !_vm.isCancelado
                          ? _c(
                              "button",
                              {
                                staticClass: "btn btn-success",
                                attrs: { type: "submit" },
                              },
                              [
                                _vm._v(
                                  "\n                                    " +
                                    _vm._s(_vm.$t("__.Crud.salvar")) +
                                    "\n                                "
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        !_vm.isCancelado
                          ? _c(
                              "button",
                              {
                                staticClass: "btn btn-secondary",
                                attrs: { type: "button" },
                                on: {
                                  click: function ($event) {
                                    _vm.load()
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n                                    " +
                                    _vm._s(_vm.$t("__.Crud.limpar")) +
                                    "\n                                "
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-secondary",
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                _vm.cancel()
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                                    " +
                                _vm._s(_vm.$t("__.Crud.voltar")) +
                                "\n                                "
                            ),
                          ]
                        ),
                      ]),
                    ]),
                  ]),
                ]),
              ],
              1
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }