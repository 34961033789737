import { GridColumn, GridColumnType } from "@/components/child/grid/gridColumn";
import { Component } from "@/decorators";
import NaturezaOperacaoModel from "@/models/naturezaOperacaoModel";
import NaturezaOperacaoService from "@/services/naturezaOperacaoService";

import ListComponentBase from "../listComponentBase";

@Component
export default class NaturezaOperacaoListComponent extends ListComponentBase<NaturezaOperacaoModel> {
    get gridColumns(): Array<GridColumn> {
        return [
            new GridColumn("codigo", this.$t("__.ts.codigo") as string, GridColumnType.String),
            new GridColumn("descricao", this.$t("__.ts.descricao") as string, GridColumnType.String),
            new GridColumn("dentroEstado", "Dentro do Estado", GridColumnType.Boolean),
        ];
    }

    private beforeMount() {
        this.setProps(new NaturezaOperacaoService(), "naturezaoperacao", "descricao");
    }
}
