var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "shortkey",
          rawName: "v-shortkey.push",
          value: ["alt"],
          expression: "['alt']",
          modifiers: { push: true },
        },
      ],
      on: { shortkey: _vm.onKeyAlt },
    },
    [
      _c("top-menu-component"),
      _vm._v(" "),
      _c(
        "div",
        {
          class: {
            appContent: true,
            appContentWithEnvironment: _vm.isNotProduction,
          },
        },
        [
          _c("nav-menu-component"),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "router-view",
              on: {
                click: function ($event) {
                  _vm.click()
                },
              },
            },
            [_c("router-view", { on: { getPreLoadPack: _vm.getPreloadPack } })],
            1
          ),
          _vm._v(" "),
          _c("notification-component"),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }