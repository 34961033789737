import ServicoModel from "@/models/servicoModel";
import nfetch from "@/utils/events/nfetch";

import { IService } from "./base/iService";

export default class ServicoService extends IService<ServicoModel> {
    constructor() {
        super("Servico");
    }

    public listByFranquiaId(franquiaId: number = null): Promise<Response> {
        return nfetch(`/${this.controllerName}/ListByFranquiaId?franquiaId=${franquiaId}`, {
            credentials: "same-origin",
        });
    }
}
