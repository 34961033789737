import Vue from "vue";
import { mapGetters } from "vuex";

import actionBarComponent from "@/components/child/actionBar/actionBar.vue";
import checkboxComponent from "@/components/child/form/checkbox.vue";
import comboComponent from "@/components/child/form/combo.vue";
import dataTooltipComponent from "@/components/child/form/datatooltip.vue";
import filePickerComponent from "@/components/child/form/filepicker.vue";
import { Component, Watch } from "@/decorators";
import EstoqueModel from "@/models/estoqueModel";
import FornecedorModel from "@/models/fornecedorModel";
import NotaFiscalEletronicaImportacaoModel from "@/models/notafiscaleletronica/notaFiscalEletronicaImportacaoModel";
import NotaFiscalEletronicaModel from "@/models/notafiscaleletronica/notaFiscalEletronicaModel";
import NotaFiscalEntradaModel from "@/models/notaFiscalEntradaModel";
import PaginationModel from "@/models/paginationModel";
import NotaFiscalEletronicaImportacaoQuestionMessageViewModel from "@/models/questionMessages/notaFiscalEletronicaImportacaoQuestionMessageViewModel";
import ValidationErrorModel from "@/models/validationErrorModel";
import EstoqueService from "@/services/estoqueService";
import FichaTecnicaService from "@/services/fichaTecnicaService";
import FornecedorService from "@/services/fornecedorService";
import NotaFiscalEletronicaImportacaoService from "@/services/notaFiscalEletronica/notaFiscalEletronicaImportacaoService";
import NotaFiscalEletronicaService from "@/services/notaFiscalEletronica/notaFiscalEletronicaService";
import NotaFiscalEntradaService from "@/services/notaFiscalEntradaService";
import { Getters } from "@/store/store";
import ValidationErrorWrapper from "@/wrappers/validationErrorWrapper";

import ImprimirRotuloPersonalizadoComponent from "../crud/rotulopersonalizado/imprimirRotuloComModal";
import imprimirRotuloPersonalizadoComponent from "../crud/rotulopersonalizado/imprimirRotuloComModal.vue";

import ImportacaoNfeContaPagarComponent from "./contapagar";
import importacaoNfeContaPagarComponent from "./contapagar.vue";
import importacaoNfeDadosGeraisComponent from "./dadosgerais.vue";
import importacaoNfeDestinatarioComponent from "./destinatario.vue";
import importacaoNfeEmitenteComponent from "./emitente.vue";
import ImportacaoNfeProdutosComponent from "./produtos";
import importacaoNfeProdutosComponent from "./produtos.vue";
import importacaoNfeTransporteComponent from "./transporte.vue";

import "./edit.scss";

@Component({
    components: {
        imprimirRotuloPersonalizadoComponent,
        filePickerComponent,
        checkboxComponent,
        comboComponent,
        importacaoNfeProdutosComponent,
        importacaoNfeDadosGeraisComponent,
        importacaoNfeEmitenteComponent,
        importacaoNfeDestinatarioComponent,
        importacaoNfeTransporteComponent,
        importacaoNfeContaPagarComponent,
        dataTooltipComponent,
        actionBarComponent,
    },
    computed: {
        ...mapGetters(["GET_IS_FRACIONAMENTO"] as Getters),
    },
})
export default class ImportacaoNfeEditComponent extends Vue {
    // State computed props
    GET_IS_FRACIONAMENTO: () => Promise<boolean>;
    private service = new NotaFiscalEletronicaService();
    private notaFiscalEntradaService = new NotaFiscalEntradaService();
    private notaFiscalEletronicaImportacaoService = new NotaFiscalEletronicaImportacaoService();
    private validationErrorWrapper = new ValidationErrorWrapper(this.$validator);
    private produtosComponent: ImportacaoNfeProdutosComponent = null;
    private contaPagarComponent: ImportacaoNfeContaPagarComponent = null;
    private imprimirRotuloPersonalizadoComponent: ImprimirRotuloPersonalizadoComponent = null;
    private fichaTecnicaService = new FichaTecnicaService();

    isFracionamento = false;
    model: NotaFiscalEletronicaImportacaoModel = new NotaFiscalEletronicaImportacaoModel();
    file: File = null;
    estoquesOptions: Array<Object> = [];

    constructor() {
        super();

        // ver comentário em ~/ClientApp/models/notafiscaleletronica/notaFiscalEletronicaImportacaoModel.ts
        this.model.notaFiscal = new NotaFiscalEletronicaModel();
    }

    private async loadEstoques() {
        const data = await new EstoqueService().combo().then(r => r.json() as Promise<PaginationModel<EstoqueModel>>);
        this.estoquesOptions = data.list.map(item => ({
            value: item.id,
            text: item.descricao,
        }));
    }

    private async save() {
        this.model.notaFiscal.dados.informacoes.detalhes
            .filter(p => p.produto.importarSemLote)
            .forEach(p => {
                p.produto.lote.produtoId = p.produto.produtoId;
            });

        if (!this.produtosComponent.allConferido()) {
            return await this.$showWarning(this.$t("__.ts.atencao"), this.$t("__.ts.itensNaoConferidos"));
        }

        try {
            const isValid = await this.$validator.validateAll();
            if (isValid) {
                const contaIsValid = await this.contaPagarComponent.validateAll(this.model.gerarContaPagar);
                if (contaIsValid) {
                    await this.insertNota().withLoading();
                } else {
                    this.$validator.errors.items = this.contaPagarComponent.getError();
                    this.$focusErrorField();
                }
            } else {
                this.$focusErrorField();
            }
        } catch {
            this.$showWarning(this.$t("__.ts.atencao"), this.$t("__.ts.itensNaoAssociados"));
        }
    }

    private async insertNota() {
        try {
            const associacoes = await this.produtosComponent.getAssociacoes();

            this.model.associacoes = associacoes;
        } catch (error) {
            if (error.message) {
                this.$showError(error.title, error.message);
            }
            return;
        }

        //valida alguns campos qnao podem ser null
        if (this.model.notaFiscal.dados.informacoes.identificacao.dataEntradaSaidaMercadoria == null) {
            this.model.notaFiscal.dados.informacoes.identificacao.dataEntradaSaidaMercadoria =
                this.model.notaFiscal.dados.informacoes.identificacao.dataEmissao;
        }
        if (this.model.notaFiscal.dados.informacoes.cobranca.faturamento.statusContas == null) {
            this.model.notaFiscal.dados.informacoes.cobranca.faturamento.statusContas = 0;
        }

        let questionMessages: NotaFiscalEletronicaImportacaoQuestionMessageViewModel[] = [];
        try {
            questionMessages = await this.notaFiscalEletronicaImportacaoService
                .validate(this.model)
                .resolveWithJSON<NotaFiscalEletronicaImportacaoQuestionMessageViewModel[]>();

            if (questionMessages.length > 0) {
                questionMessages = await this.showQuestionMessages(questionMessages);
            }
        } catch {
            return;
        }

        try {
            const response = await this.notaFiscalEletronicaImportacaoService.insert(this.model, questionMessages);

            if (response.ok) {
                this.file = null;
                this.model.atualizarFornecedor = true;
                this.model.gerarContaPagar = true;

                this.$showSuccess(this.$t("__.ts.inclusao"), this.$t("__.ts.registroSalvSucess"));

                const nfeId = Number(response.headers.get("Id"));

                const nfeResponse = await this.$showQuestion(this.$t("__.ts.atencao"), this.$t("__.ts.imprimirFichas"));

                if (nfeResponse) {
                    try {
                        await this.fichaTecnicaService.getPDFByNotaFiscalEntrada(nfeId);

                        await this.onImprimirRotulo(nfeId).withLoading();
                    } catch {}
                } else {
                    this.onImprimirRotulo(nfeId).withLoading();
                }
            } else {
                const data = (await response.json()) as ValidationErrorModel[];

                this.validationErrorWrapper.showErrors(data);
            }
        } catch (error) {
            this.$showError(this.$t("__.ts.erro"), error);
        }
    }

    private async showQuestionMessages(questionMessages: NotaFiscalEletronicaImportacaoQuestionMessageViewModel[]) {
        for (let index = 0; index < questionMessages.length; index++) {
            const element = questionMessages[index];

            for (let index = 0; index < element.questionMessages.length; index++) {
                const message = element.questionMessages[index];
                message.response = await this.showAlertMessage(message.message);
            }
        }

        return questionMessages;
    }

    private async showAlertMessage(text: string) {
        try {
            return await this.$showQuestionWithHTML(this.$t("__.ts.atencao"), text, {
                allowEscapeKey: false,
                allowOutsideClick: false,
                allowEnterKey: false,
            });
        } catch {}
        return false;
    }

    private async onImprimirRotulo(notaFiscalEntradaId: number) {
        if (!this.imprimirRotuloPersonalizadoComponent)
            this.imprimirRotuloPersonalizadoComponent = this.$refs
                .imprimirRotuloPersonalizadoComponent as ImprimirRotuloPersonalizadoComponent;

        const data = await this.notaFiscalEntradaService
            .get(notaFiscalEntradaId)
            .resolveWithJSON<NotaFiscalEntradaModel>();

        const lotesIds = data.movimentacaoEstoque.movimentacaoEstoqueItens.map(p => p.produtoLoteId);
        this.imprimirRotuloPersonalizadoComponent.addRotuloLoteByIds(lotesIds);
        await this.imprimirRotuloPersonalizadoComponent.show({});
    }

    private cancel() {
        this.$router.push("/");
    }

    //@ts-ignore
    @Watch("model.notaFiscal.dados.informacoes.emitente.cnpj")
    private async onFornecedorIdChange(newValue: string) {
        if (newValue) {
            const data = await new FornecedorService().getByDocumento(newValue).resolveWithJSON<FornecedorModel>();
            this.model.contaPagar.contaGerencialId = data.contaGerencialId;
        }
    }

    @Watch("file")
    private async onFileChanged() {
        this.model.notaFiscal = new NotaFiscalEletronicaModel();
        this.validationErrorWrapper.clearErrors();

        if (!this.file) {
            return;
        }

        try {
            const data = await this.service
                .getXmlData(this.file)
                .withLoading()
                .resolveWithJSON<NotaFiscalEletronicaModel>();

            this.model.notaFiscal.updateFrom(data);

            if (this.isFracionamento) {
                this.model.notaFiscal.dados.informacoes.detalhes.forEach(p =>
                    p.produto.lote != null ? (p.produto.lote.fracionarLote = this.model.fracionarLotes) : false,
                );
            }

            const numero = this.model.notaFiscal.dados.informacoes.identificacao.numero;
            const serie = this.model.notaFiscal.dados.informacoes.identificacao.serie;
            const cnpjFornecedor = this.model.notaFiscal.dados.informacoes.emitente.cnpj;
            const chave = this.model.notaFiscal.protNFE.informacoes.chave;

            await this.verificaExisteNf(numero, serie, cnpjFornecedor, chave);
        } catch {
            this.file = null;
        }
    }

    private async verificaExisteNf(numero: number, serie: number, cnpj: string, chave: string) {
        try {
            let sucesso = true;
            if (numero && serie) {
                sucesso = await this.notaFiscalEntradaService
                    .verificaExisteNf(numero, serie, cnpj)
                    .withLoading()
                    .resolveWithoutJSON();
            }

            if (sucesso && chave) {
                await this.notaFiscalEntradaService.verificaExisteNfByChave(chave).withLoading().resolveWithoutJSON();
            }
        } catch {}
    }

    private onChangeValorFrete(frete: number) {
        this.model.notaFiscal.dados.informacoes.totais.totalIcms.valorFrete = frete;
    }

    private async mounted() {
        this.isFracionamento = await this.GET_IS_FRACIONAMENTO().withLoading();
        this.produtosComponent = this.$refs.produtosComponent as ImportacaoNfeProdutosComponent;
        this.contaPagarComponent = this.$refs.contaPagarComponent as ImportacaoNfeContaPagarComponent;
        this.imprimirRotuloPersonalizadoComponent = this.$refs
            .imprimirRotuloPersonalizadoComponent as ImprimirRotuloPersonalizadoComponent;

        Promise.all([this.loadEstoques()])
            .withLoading()
            .then(() => {
                if (this.estoquesOptions.length == 1 && this.model.estoqueDestinoId == null) {
                    this.model.estoqueDestinoId = this.estoquesOptions[0]["value"];
                }
            })
            .catch(() => {});
    }
}
