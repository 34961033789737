import Vue from "vue";

import actionBarComponent from "@/components/child/actionBar/actionBar.vue";
import checkboxComponent from "@/components/child/form/checkbox.vue";
import comboComponent from "@/components/child/form/combo.vue";
import dataTooltipComponent from "@/components/child/form/datatooltip.vue";
import dateTimePickerComponent from "@/components/child/form/datetimepicker.vue";
import fieldsetComponent from "@/components/child/form/fieldset.vue";
import moedaComponent from "@/components/child/form/moeda.vue";
import RascunhoComponent from "@/components/child/rascunho/rascunho";
import rascunhoComponent from "@/components/child/rascunho/rascunho.vue";
import { Component, Prop, Watch } from "@/decorators";
import TipoMovimento from "@/models/enum/tiposMovimentacaoEstoque";
import EstoqueModel from "@/models/estoqueModel";
import ContaPagarModel from "@/models/financeiro/contaPagarModel";
import ContaPagarPagamentoModel from "@/models/financeiro/contaPagarPagamentoModel";
import FornecedorModel from "@/models/fornecedorModel";
import MovimentoEstoqueModel from "@/models/movimentacaoEstoqueModel";
import NotaFiscalEntradaTotalICMSModel from "@/models/notafiscaleletronica/dados/informacoes/totais/icms/totalIcmsNfeModel";
import NotaFiscalEntradaModel from "@/models/notaFiscalEntradaModel";
import PaginationModel from "@/models/paginationModel";
import NotaFiscalEletronicaImportacaoQuestionMessageViewModel from "@/models/questionMessages/notaFiscalEletronicaImportacaoQuestionMessageViewModel";
import TransportadoraModel from "@/models/transporte/transportadoraModel";
import EstoqueService from "@/services/estoqueService";
import FichaTecnicaService from "@/services/fichaTecnicaService";
import FornecedorService from "@/services/fornecedorService";
import NotaFiscalEntradaService from "@/services/notaFiscalEntradaService";
import TransportadoraService from "@/services/transporte/transportadoraService";
import { getFornecedorCombo } from "@/utils/common/combo/combotext";
import Bus from "@/utils/events/bus";

import FornecedorComponent from "../crud/cliente/edit";
import contasPagarListComponent from "../crud/contapagar/list.vue";
import fornecedorComponent from "../crud/fornecedor/edit.vue";
import ImprimirRotuloPersonalizadoComponent from "../crud/rotulopersonalizado/imprimirRotuloComModal";
import imprimirRotuloPersonalizadoComponent from "../crud/rotulopersonalizado/imprimirRotuloComModal.vue";
import shortcutComponent from "../shortcut/shortcut.vue";

import FinanceiroListComponent from "./financeiro";
import financeiroListComponent from "./financeiro.vue";
import itemListComponent from "./item.vue";

import "./edit.scss";
import "../crud/crud.scss";

@Component({
    components: {
        checkboxComponent,
        dataTooltipComponent,
        dateTimePickerComponent,
        moedaComponent,
        fieldsetComponent,
        comboComponent,
        itemListComponent,
        financeiroListComponent,
        contasPagarListComponent,
        fornecedorComponent,
        shortcutComponent,
        imprimirRotuloPersonalizadoComponent,
        actionBarComponent,
        rascunhoComponent,
    },
})
export default class NotaFiscalEntradaEditComponent extends Vue {
    private service = new NotaFiscalEntradaService();
    private fichaTecnicaService = new FichaTecnicaService();
    private fornecedorService = new FornecedorService();
    private financeiroListComponent: FinanceiroListComponent = null;
    private imprimirRotuloPersonalizadoComponent: ImprimirRotuloPersonalizadoComponent = null;
    private fornecedorComponent: FornecedorComponent = null;

    model: NotaFiscalEntradaModel = new NotaFiscalEntradaModel();
    modelId = 0;

    private estoqueDestino: string = null;
    private fornecedorNome: string = null;

    fornecedoresOptions: Array<Object> = [];
    transportadoraOptions: Array<Object> = [];
    estoquesOptions: Array<Object> = [];

    private totalDescontoItens = 0;

    addNewId = 0;
    disabled = false;

    @Prop({ type: Boolean, default: false }) isModal: boolean;

    public async loadById(id: number) {
        this.modelId = id;
        this.disabled = true;

        await this.load();
    }

    private async onSaveRascunho() {
        const rascunhoComponent = this.$refs.rascunhoComponent as RascunhoComponent;
        rascunhoComponent.saveRascunho(this.model);
    }

    private onLoadRascunho(data: any) {
        this.model.updateFrom(data);
    }

    private async load() {
        this.model = new NotaFiscalEntradaModel();

        this.estoqueDestino = null;
        this.fornecedorNome = null;

        if (this.modelId == 0) {
            this.model.importacaoXml = false;
        }

        if (this.model.movimentacaoEstoque == null) {
            this.model.movimentacaoEstoque = new MovimentoEstoqueModel();
        }

        if (this.model.contaPagar == null) {
            this.model.contaPagar = new ContaPagarModel();
            this.model.contaPagar.status = 0;

            this.model.contaPagar.codigoBarras = "";
            this.model.contaPagar.contaGerencialDescricao = "";
            this.model.contaPagar.dataLancamento = new Date();
            this.model.contaPagar.descricao = "";
            this.model.contaPagar.observacao = "";
            this.model.contaPagar.saldo = 0;
            this.model.contaPagar.valorTotal = 0;
            this.model.contaPagar.status = 0;

            //cria o contas comm um pagamento vinculado para usar de referencia caso já liquide a nota na criacao
            const pagamento = new ContaPagarPagamentoModel();
            pagamento.dataLancamento = new Date();
            this.model.contaPagar.movimentacoes = new Array<ContaPagarPagamentoModel>();
            this.model.contaPagar.movimentacoes.push(pagamento);
        }

        if (this.model.notaFiscalEntradaTotalICMS == null) {
            this.model.notaFiscalEntradaTotalICMS = new NotaFiscalEntradaTotalICMSModel();
        }

        if (this.modelId > 0) {
            try {
                const data = await this.service
                    .get(this.modelId)
                    .withLoading()
                    .resolveWithJSON<NotaFiscalEntradaModel>();
                this.model.updateFrom(data);

                const fornecedor = this.fornecedoresOptions.filter(p => p["value"] == this.model.fornecedorId);
                if (fornecedor.length > 0) {
                    this.fornecedorNome = fornecedor[0]["text"];
                }

                if (this.model.movimentacaoEstoque != null) {
                    const estoque = this.estoquesOptions.filter(
                        p => p["value"] == this.model.movimentacaoEstoque.estoqueDestinoId,
                    );
                    if (estoque.length > 0) {
                        this.estoqueDestino = estoque[0]["text"];
                    }
                } else {
                    this.estoqueDestino = "Sem Estoque";
                }

                if (this.model.id) {
                    this.model.gerarContasPagar = !!this.model.contaPagarId;
                }
            } catch {
                this.$router.back();
            }
        } else {
            if (this.model.dataInclusao == null) {
                this.model.dataInclusao = new Date();
            }

            if (this.model.dataEmissao == null) {
                this.model.dataEmissao = new Date();
            }

            if (this.model.contaPagar == null) {
                this.model.contaPagar = new ContaPagarModel();
            }
        }
    }

    private async loadEstoques() {
        try {
            const data = await new EstoqueService()
                .combo()
                .then(r => r.json() as Promise<PaginationModel<EstoqueModel>>);
            this.estoquesOptions = data.list.map(item => ({ value: item.id, text: item.descricao }));
        } catch {}
    }

    private async loadFornecedores() {
        try {
            const data = await this.fornecedorService
                .combo()
                .then(r => r.json() as Promise<PaginationModel<FornecedorModel>>);
            this.fornecedoresOptions = data.list.map(getFornecedorCombo);
        } catch {}
    }

    private async loadTransportadoras() {
        try {
            const data = await new TransportadoraService()
                .combo()
                .then(r => r.json() as Promise<PaginationModel<TransportadoraModel>>);
            this.transportadoraOptions = data.list.map(item => ({ value: item.id, text: item.nome }));
        } catch {}
    }

    private async save() {
        try {
            let isValid = await this.$validator.validateAll();
            if (!isValid) {
                return this.$focusErrorField();
            }

            this.model.notaFiscalEntradaItens.forEach(item => (item.produtoLote = null));

            if (this.model.gerarContasPagar) {
                this.financeiroListComponent = this.$refs.financeiroListComponent as FinanceiroListComponent;
                if (this.financeiroListComponent) {
                    isValid = await this.financeiroListComponent.validate();
                    if (!isValid) {
                        return this.$focusErrorField();
                    }
                }
            }

            if (this.model.importacaoXml == null) {
                this.model.importacaoXml = false;
            }

            if (this.modelId == 0) {
                //para nao ocorrer problemas ao passar para viewModel
                if (this.model.contaPagar.status == 0) {
                    this.model.contaPagar.movimentacoes[0].contaCorrenteId = 0;
                    this.model.contaPagar.movimentacoes[0].formaPagamentoId = 0;
                    this.model.contaPagar.movimentacoes[0].dataMovimento = new Date();
                    if (!this.model.contaPagar.contaGerencialId) {
                        this.model.contaPagar.contaGerencialId = 0;
                    }
                    if (!this.model.contaPagar.dataVencimento) {
                        this.model.contaPagar.dataVencimento = new Date();
                    }
                }

                if (this.model.fornecedorNome == null && this.model.fornecedorId > 0) {
                    this.model.fornecedorNome = this.fornecedoresOptions.filter(
                        p => p["value"] == this.model.fornecedorId,
                    )[0]["text"];
                }

                //atualiza valor da tabela de ICMS com o total da tela.
                this.model.notaFiscalEntradaTotalICMS.valorTotalNotaFiscal = this.model.valorTotal;
                //ajuste do movimento de estoque
                this.model.movimentacaoEstoque.dataMovimentacao = new Date();
                this.model.movimentacaoEstoque.tipoMovimentacao = TipoMovimento.Entrada;
            } else {
                this.model.contaPagar = null;
            }

            //valida alguns itens para evitar erros
            if (
                this.model.notaFiscalEntradaItens.filter(
                    p => p.valorUnitario == null || p.valorTotal == null || p.quantidade == null,
                ).length > 0
            ) {
                this.model.notaFiscalEntradaItens
                    .filter(p => p.valorUnitario == null || p.valorTotal == null || p.quantidade == null)
                    .forEach(item => {
                        if (item.valorUnitario == null) item.valorUnitario = 0;
                        if (item.valorTotal == null) item.valorTotal = 0;
                        if (item.quantidade == null) item.quantidade = 0;
                    });
            }

            try {
                let response = null;
                if (!this.model.id) {
                    if (!this.model.gerarContasPagar) {
                        this.model.contaPagar = null;
                    }

                    let questionMessages = await this.service
                        .validate(this.model)
                        .withLoading()
                        .resolveWithJSON<NotaFiscalEletronicaImportacaoQuestionMessageViewModel[]>();

                    if (questionMessages.length > 0) {
                        questionMessages = await this.showQuestionMessages(questionMessages);
                    }

                    response = await this.service
                        .insertWithQuestions(this.model, questionMessages)
                        .withLoading()
                        .resolveWithResponse();
                } else {
                    response = await this.service.update(this.model).withLoading().resolveWithResponse();
                }

                if (response.ok) {
                    await this.$showInclusaoUpdate(this.model.id);

                    const nfeId = Number(response.headers.get("Id"));
                    this.modelId = nfeId;
                    await this.load();

                    const nfeResponse = await this.$showQuestion(
                        this.$t("__.ts.atencao"),
                        this.$t("__.ts.imprimirFichas"),
                    );

                    if (nfeResponse) {
                        try {
                            await this.fichaTecnicaService.getPDFByNotaFiscalEntrada(nfeId);

                            await this.onImprimirRotulo();
                        } catch {}
                    } else {
                        await this.onImprimirRotulo();
                    }
                }
            } catch {}
        } catch {}
    }

    private async showQuestionMessages(questionMessages: NotaFiscalEletronicaImportacaoQuestionMessageViewModel[]) {
        for (let index = 0; index < questionMessages.length; index++) {
            const element = questionMessages[index];

            for (let index = 0; index < element.questionMessages.length; index++) {
                const message = element.questionMessages[index];
                message.response = await this.$showQuestionWithHTML(this.$t("__.ts.atencao"), message.message, {
                    allowEscapeKey: false,
                    allowOutsideClick: false,
                    allowEnterKey: false,
                });
            }
        }

        return questionMessages;
    }

    @Watch("model.fornecedorId")
    private async onFornecedorIdChange(newValue: number) {
        if (this.modelId == 0) {
            this.addNewId = this.model.fornecedorId;
            if (newValue) {
                const contaGerencialId = this.fornecedoresOptions.filter(p => p["value"] == newValue)[0];
                if (contaGerencialId) {
                    this.model.contaPagar.contaGerencialId = contaGerencialId["contaGerencialId"];
                }

                await this.fornecedorService.validarValidadeLicensaSanitaria(this.model.fornecedorId);
            }
        }
    }

    private cancel() {
        this.$router.back();
    }

    private async onImprimirRotulo() {
        if (!this.imprimirRotuloPersonalizadoComponent)
            this.imprimirRotuloPersonalizadoComponent = this.$refs
                .imprimirRotuloPersonalizadoComponent as ImprimirRotuloPersonalizadoComponent;

        const loteIds = this.model.notaFiscalEntradaItens.map(p => p.produtoLoteId);
        this.imprimirRotuloPersonalizadoComponent.addRotuloLoteByIds(loteIds);

        await this.imprimirRotuloPersonalizadoComponent.show({});
    }

    private onItemChaged() {
        this.model.notaFiscalEntradaTotalICMS.valorFrete = this.model.notaFiscalEntradaItens.reduce(
            (acc, p) => acc + p.valorFrete,
            0,
        );
        this.model.notaFiscalEntradaTotalICMS.valorDesc = this.totalDescontoItens =
            this.model.notaFiscalEntradaItens.reduce((acc, p) => acc + p.valorDesconto, 0);
        this.calcularTotalNota();
    }

    private onAddNewFornecedor() {
        this.fornecedorComponent.save();
    }

    private async onFornecedorSaveOk(modelNovoFornecedor: FornecedorModel) {
        await this.loadFornecedores();
        this.model.fornecedorId = modelNovoFornecedor.id;
    }

    private async openComboNewFornecedor() {
        this.fornecedorComponent.modelId = 0;
        this.fornecedorComponent.load();
    }

    private async openComboEditFornecedor(id) {
        this.fornecedorComponent.modelId = id;
        this.fornecedorComponent.load();
    }

    //@ts-ignore
    @Watch("model.notaFiscalEntradaTotalICMS.valorFrete")
    private onValorFreteChange() {
        if (this.modelId <= 0) {
            this.calcularTotalNota();
        }
    }

    //@ts-ignore
    @Watch("model.notaFiscalEntradaTotalICMS.valorIPI")
    private onValorIPIChange() {
        if (this.modelId <= 0) {
            this.calcularTotalNota();
        }
    }

    //@ts-ignore
    @Watch("model.notaFiscalEntradaTotalICMS.valorDesc")
    private onValorDescChange(newValue, oldValue) {
        if (this.modelId <= 0) {
            if (newValue < this.model.valorTotal) {
                this.calcularTotalNota();
            } else if (newValue > 0) {
                this.model.notaFiscalEntradaTotalICMS.valorDesc = !oldValue ? this.totalDescontoItens : oldValue;
                this.calcularTotalNota();
                this.$showWarning("Operação não permitida!", "Desconto não pode ser maior que o valor total");
            }
        }
    }

    private calcularTotalNota() {
        const totalItensSemFreteDesconto = this.model.notaFiscalEntradaItens.reduce(
            (acc, item) =>
                acc + item.valorUnitarioOrigem * item.quantidadeOrigem + item.valorSeguro + item.valorOutraDespesa,
            0,
        );

        this.model.valorTotal =
            totalItensSemFreteDesconto +
            this.model.notaFiscalEntradaTotalICMS.valorFrete -
            this.model.notaFiscalEntradaTotalICMS.valorDesc;
    }

    private mounted() {
        Bus.$emit("hide-nav-menu", true);
        if (this.model.contaPagar == null) {
            this.model.contaPagar = new ContaPagarModel();
            //cria o contas comm um pagamento vinculado para usar de referencia caso já liquide a nota na criacao
            const pagamento = new ContaPagarPagamentoModel();
            this.model.contaPagar.movimentacoes = new Array<ContaPagarPagamentoModel>();
            this.model.contaPagar.movimentacoes.push(pagamento);
        }
        if (this.model.movimentacaoEstoque == null) {
            this.model.movimentacaoEstoque = new MovimentoEstoqueModel();
        }
        if (this.model.notaFiscalEntradaTotalICMS == null) {
            this.model.notaFiscalEntradaTotalICMS = new NotaFiscalEntradaTotalICMSModel();
        }

        this.fornecedorComponent = this.$refs.fornecedorComponent as FornecedorComponent;
        this.imprimirRotuloPersonalizadoComponent = this.$refs
            .imprimirRotuloPersonalizadoComponent as ImprimirRotuloPersonalizadoComponent;

        Promise.all([this.loadFornecedores(), this.loadTransportadoras(), this.loadEstoques()])
            .withLoading()
            .then(() => {
                if (!this.isModal) {
                    if (this.$route.params.id) {
                        this.modelId = +this.$route.params.id;
                    }

                    this.load();
                }
            })
            .catch(() => {});
    }
}
