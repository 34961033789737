var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "crud crud-campo-edit" }, [
    _c(
      "form",
      {
        attrs: { novalidate: "novalidate" },
        on: {
          submit: function ($event) {
            $event.preventDefault()
            _vm.save()
          },
        },
      },
      [
        _c("fieldset", [
          _c("div", { attrs: { slot: "rows" }, slot: "rows" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-sm-8" }, [
                _c("div", { staticClass: "form-group" }, [
                  _c(
                    "label",
                    {
                      staticClass: "control-label",
                      attrs: { for: "labelName" },
                    },
                    [
                      _vm._v(
                        "\n                                " +
                          _vm._s(
                            _vm.$t(
                              "__.Components.parent.printModel.campo_vue_html.nomeCampo"
                            )
                          ) +
                          "\n                            "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.model.labelName,
                        expression: "model.labelName",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: { type: "text", id: "labelName", name: "labelName" },
                    domProps: { value: _vm.model.labelName },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.model, "labelName", $event.target.value)
                      },
                    },
                  }),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-sm-2" }, [
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c("label", { attrs: { for: "labelShowValue" } }, [
                      _vm._v(
                        "\n                                " +
                          _vm._s(
                            _vm.$t(
                              "__.Components.parent.printModel.campo_vue_html.mostrarNomeCampo"
                            )
                          ) +
                          "\n                            "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("data-tooltip-component", {
                      attrs: {
                        text: _vm.$t(
                          "__.Components.parent.printModel.campo_vue_html.seMarcNaoMostra"
                        ),
                      },
                    }),
                    _vm._v(" "),
                    _c("checkbox-component", {
                      attrs: { id: "labelShowValue", name: "labelShowValue" },
                      model: {
                        value: _vm.model.labelShowValue,
                        callback: function ($$v) {
                          _vm.$set(_vm.model, "labelShowValue", $$v)
                        },
                        expression: "model.labelShowValue",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-sm-2" }, [
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c("label", { attrs: { for: "personalizado" } }, [
                      _vm._v(
                        "\n                                " +
                          _vm._s(
                            _vm.$t(
                              "__.Components.parent.printModel.campo_vue_html.personalizado"
                            )
                          ) +
                          "\n                            "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("checkbox-component", {
                      attrs: { id: "personalizado", name: "personalizado" },
                      model: {
                        value: _vm.model.personalizado,
                        callback: function ($$v) {
                          _vm.$set(_vm.model, "personalizado", $$v)
                        },
                        expression: "model.personalizado",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
            _vm._v(" "),
            _vm.complementoCampo
              ? _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-sm-12" }, [
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "dadosCamposComp" },
                          },
                          [
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Components.parent.printModel.campo_vue_html.origemCampoComplemen"
                                  )
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("combo-component", {
                          attrs: {
                            id: "dadosCamposComp",
                            name: "dadosCamposComp",
                            title: _vm.$t(
                              "__.Components.parent.printModel.campo_vue_html.separOndeOriginaInfo"
                            ),
                            data: _vm.dadosOptions,
                            searchable: true,
                          },
                          model: {
                            value: _vm.dados,
                            callback: function ($$v) {
                              _vm.dados = $$v
                            },
                            expression: "dados",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              !_vm.model.personalizado
                ? _c("div", { staticClass: "col-sm-2" }, [
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c("label", { attrs: { for: "bold" } }, [
                          _vm._v(
                            "\n                                " +
                              _vm._s(
                                _vm.$t(
                                  "__.Components.parent.printModel.campo_vue_html.campoComplemen"
                                )
                              ) +
                              "\n                            "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("data-tooltip-component", {
                          attrs: {
                            text: _vm.$t(
                              "__.Components.parent.printModel.campo_vue_html.utilizOutroCampoComplemen"
                            ),
                          },
                        }),
                        _vm._v(" "),
                        _c("checkbox-component", {
                          attrs: {
                            id: "complementoCampo",
                            name: "complementoCampo",
                          },
                          model: {
                            value: _vm.complementoCampo,
                            callback: function ($$v) {
                              _vm.complementoCampo = $$v
                            },
                            expression: "complementoCampo",
                          },
                        }),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              !_vm.complementoCampo
                ? _c(
                    "div",
                    { class: "col-sm-" + [_vm.model.personalizado ? 8 : 5] },
                    [
                      _c("div", { staticClass: "form-group" }, [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "labelValue" },
                          },
                          [
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Components.parent.printModel.campo_vue_html.complemenCampo"
                                  )
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.model.labelValue,
                              expression: "model.labelValue",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            type: "text",
                            id: "labelValue",
                            name: "labelValue",
                          },
                          domProps: { value: _vm.model.labelValue },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.model,
                                "labelValue",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]),
                    ]
                  )
                : _c("div", { staticClass: "col-sm-5" }, [
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "indexCampoComplemento" },
                          },
                          [
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Components.parent.printModel.campo_vue_html.complemenCampo"
                                  )
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("combo-component", {
                          attrs: {
                            id: "indexCampoComplemento",
                            name: "indexCampoComplemento",
                            data: _vm.camposComplementoOptions,
                            searchable: true,
                          },
                          model: {
                            value: _vm.indexCampoComplemento,
                            callback: function ($$v) {
                              _vm.indexCampoComplemento = $$v
                            },
                            expression: "indexCampoComplemento",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-sm-3" }, [
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c(
                      "label",
                      {
                        staticClass: "control-label",
                        attrs: { for: "posComplemento" },
                      },
                      [
                        _vm._v(
                          "\n                                " +
                            _vm._s(
                              _vm.$t(
                                "__.Components.parent.printModel.campo_vue_html.posComplemen"
                              )
                            ) +
                            "\n                            "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("data-tooltip-component", {
                      attrs: {
                        text: _vm.$t(
                          "__.Components.parent.printModel.campo_vue_html.posComplemenRelacaoVlr"
                        ),
                      },
                    }),
                    _vm._v(" "),
                    _c("combo-component", {
                      attrs: {
                        id: "posComplemento",
                        name: "posComplemento",
                        data: _vm.posicaoOptions,
                        searchable: false,
                      },
                      model: {
                        value: _vm.model.posComplemento,
                        callback: function ($$v) {
                          _vm.$set(_vm.model, "posComplemento", $$v)
                        },
                        expression: "model.posComplemento",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-sm-2" }, [
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c("label", { attrs: { for: "brancoQuandoZero" } }, [
                      _vm._v(
                        "\n                                " +
                          _vm._s(
                            _vm.$t(
                              "__.Components.parent.printModel.campo_vue_html.exibirCampoBranco"
                            )
                          ) +
                          "\n                            "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("data-tooltip-component", {
                      attrs: {
                        text: _vm.$t(
                          "__.Components.parent.printModel.campo_vue_html.exibirCampoBrancoTooltip"
                        ),
                      },
                    }),
                    _vm._v(" "),
                    _c("checkbox-component", {
                      attrs: {
                        id: "brancoQuandoZero",
                        name: "brancoQuandoZero",
                      },
                      model: {
                        value: _vm.model.brancoQuandoZero,
                        callback: function ($$v) {
                          _vm.$set(_vm.model, "brancoQuandoZero", $$v)
                        },
                        expression: "model.brancoQuandoZero",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-sm-3" }, [
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c("label", { attrs: { for: "bold" } }, [
                      _vm._v(
                        "\n                                " +
                          _vm._s(
                            _vm.$t(
                              "__.Components.parent.printModel.campo_vue_html.txtNegrito"
                            )
                          ) +
                          "\n                            "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("checkbox-component", {
                      attrs: { id: "bold", name: "bold" },
                      model: {
                        value: _vm.model.bold,
                        callback: function ($$v) {
                          _vm.$set(_vm.model, "bold", $$v)
                        },
                        expression: "model.bold",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-sm-5" }, [
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c(
                      "label",
                      {
                        staticClass: "control-label",
                        attrs: { for: "colorText" },
                      },
                      [
                        _vm._v(
                          "\n                                " +
                            _vm._s(
                              _vm.$t(
                                "__.Components.parent.printModel.campo_vue_html.corTxt"
                              )
                            ) +
                            "\n                            "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("combo-component", {
                      attrs: {
                        id: "colorText",
                        name: "colorText",
                        data: _vm.basicColorOptions,
                        searchable: true,
                        defaultValue: 0,
                      },
                      model: {
                        value: _vm.model.colorText,
                        callback: function ($$v) {
                          _vm.$set(_vm.model, "colorText", $$v)
                        },
                        expression: "model.colorText",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-sm-4" }, [
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c(
                      "label",
                      {
                        staticClass: "control-label",
                        attrs: { for: "colorBGText" },
                      },
                      [
                        _vm._v(
                          "\n                                " +
                            _vm._s(
                              _vm.$t(
                                "__.Components.parent.printModel.campo_vue_html.corFundoTxt"
                              )
                            ) +
                            "\n                            "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("combo-component", {
                      attrs: {
                        id: "colorBGText",
                        name: "colorBGText",
                        data: _vm.basicColorOptions,
                        searchable: true,
                        defaultValue: 14,
                      },
                      model: {
                        value: _vm.model.colorBGText,
                        callback: function ($$v) {
                          _vm.$set(_vm.model, "colorBGText", $$v)
                        },
                        expression: "model.colorBGText",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
            _vm._v(" "),
            !_vm.model.personalizado
              ? _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-sm-5" }, [
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "dadosSubstituto" },
                          },
                          [
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Components.parent.printModel.campo_vue_html.origCampoSubstituto"
                                  )
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("combo-component", {
                          attrs: {
                            title: _vm.$t(
                              "__.Components.parent.printModel.campo_vue_html.separOndeOriginaInfo"
                            ),
                            id: "dadosSubstituto",
                            name: "dadosSubstituto",
                            data: _vm.dadosOptions,
                            searchable: true,
                          },
                          model: {
                            value: _vm.dadosSubstituto,
                            callback: function ($$v) {
                              _vm.dadosSubstituto = $$v
                            },
                            expression: "dadosSubstituto",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-4" }, [
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "indexCampoComplemento" },
                          },
                          [
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Components.parent.printModel.campo_vue_html.campoSubstituto"
                                  )
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("data-tooltip-component", {
                          attrs: {
                            text: _vm.$t(
                              "__.Components.parent.printModel.campo_vue_html.campoSubstituiInfos"
                            ),
                          },
                        }),
                        _vm._v(" "),
                        _c("combo-component", {
                          attrs: {
                            id: "indexCampoSubstituto",
                            name: "indexCampoSubstituto",
                            data: _vm.camposSubstitutoOptions,
                            searchable: true,
                          },
                          model: {
                            value: _vm.indexCampoSubstituto,
                            callback: function ($$v) {
                              _vm.indexCampoSubstituto = $$v
                            },
                            expression: "indexCampoSubstituto",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-3" }, [
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c(
                          "label",
                          { attrs: { for: "primeiroNomeCampoSubstituto" } },
                          [
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Components.parent.printModel.campo_vue_html.primeiroNomeCampoSubstituto"
                                  )
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("data-tooltip-component", {
                          attrs: {
                            text: "Apresenta texto até o primeiro espaçamento",
                          },
                        }),
                        _vm._v(" "),
                        _c("checkbox-component", {
                          attrs: {
                            id: "primeiroNomeCampoSubstituto",
                            name: "primeiroNomeCampoSubstituto",
                          },
                          model: {
                            value: _vm.model.primeiroNomeCampoSubstituto,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.model,
                                "primeiroNomeCampoSubstituto",
                                $$v
                              )
                            },
                            expression: "model.primeiroNomeCampoSubstituto",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-sm-2" }, [
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c("label", { attrs: { for: "codigoDeBarras" } }, [
                      _vm._v(
                        "\n                                " +
                          _vm._s(
                            _vm.$t(
                              "__.Components.parent.printModel.campo_vue_html.codigoBarras"
                            )
                          ) +
                          "\n                            "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("combo-component", {
                      attrs: {
                        id: "codigoDeBarras",
                        name: "codigoDeBarras",
                        data: _vm.codigoDeBarrasOptions,
                      },
                      model: {
                        value: _vm.model.codigoDeBarras,
                        callback: function ($$v) {
                          _vm.$set(_vm.model, "codigoDeBarras", $$v)
                        },
                        expression: "model.codigoDeBarras",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-sm-3" }, [
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c("label", { attrs: { for: "bold" } }, [
                      _vm._v(
                        "\n                                " +
                          _vm._s(
                            _vm.$t(
                              "__.Components.parent.printModel.campo_vue_html.utilizLimiteChar"
                            )
                          ) +
                          "\n                            "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("data-tooltip-component", {
                      attrs: {
                        text: _vm.$t(
                          "__.Components.parent.printModel.campo_vue_html.utilizNroLimiteCharVlrCampo"
                        ),
                      },
                    }),
                    _vm._v(" "),
                    _c("checkbox-component", {
                      attrs: { id: "utilizarLimite", name: "utilizarLimite" },
                      model: {
                        value: _vm.utilizarLimite,
                        callback: function ($$v) {
                          _vm.utilizarLimite = $$v
                        },
                        expression: "utilizarLimite",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _vm.utilizarLimite
                ? _c("div", { staticClass: "col-sm-2" }, [
                    _c("div", { staticClass: "form-group" }, [
                      _c(
                        "label",
                        {
                          staticClass: "control-label",
                          attrs: { for: "alinhamento" },
                        },
                        [
                          _vm._v(
                            "\n                                " +
                              _vm._s(
                                _vm.$t(
                                  "__.Components.parent.printModel.campo_vue_html.maxChar"
                                )
                              ) +
                              "\n                            "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.model.limiteCaracteres,
                            expression: "model.limiteCaracteres",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "number",
                          id: "alinhamento",
                          name: "alinhamento",
                          data: _vm.alinhamentoOptions,
                          searchable: true,
                        },
                        domProps: { value: _vm.model.limiteCaracteres },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.model,
                              "limiteCaracteres",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                  ])
                : _c("div", { staticClass: "col-sm-2" }, [
                    _c("div", { staticClass: "form-group" }, [
                      _c(
                        "label",
                        {
                          staticClass: "control-label",
                          attrs: { for: "alinhamento" },
                        },
                        [
                          _vm._v(
                            "\n                                " +
                              _vm._s(
                                _vm.$t(
                                  "__.Components.parent.printModel.campo_vue_html.maxChar"
                                )
                              ) +
                              "\n                            "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.model.limiteCaracteres,
                            expression: "model.limiteCaracteres",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          disabled: "true",
                          type: "number",
                          id: "alinhamento",
                          name: "alinhamento",
                          data: _vm.alinhamentoOptions,
                          searchable: true,
                        },
                        domProps: { value: _vm.model.limiteCaracteres },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.model,
                              "limiteCaracteres",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                  ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-sm-5" }, [
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c(
                      "label",
                      {
                        staticClass: "control-label",
                        attrs: { for: "alinhamento" },
                      },
                      [
                        _vm._v(
                          "\n                                " +
                            _vm._s(
                              _vm.$t(
                                "__.Components.parent.printModel.campo_vue_html.alinTxt"
                              )
                            ) +
                            "\n                            "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("combo-component", {
                      attrs: {
                        id: "alinhamento",
                        name: "alinhamento",
                        data: _vm.alinhamentoOptions,
                        searchable: true,
                      },
                      model: {
                        value: _vm.model.alinhamento,
                        callback: function ($$v) {
                          _vm.$set(_vm.model, "alinhamento", $$v)
                        },
                        expression: "model.alinhamento",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-sm-9" }, [
                _c("div", { staticClass: "form-group" }, [
                  _c(
                    "label",
                    { staticClass: "control-label", attrs: { for: "labelId" } },
                    [
                      _vm._v(
                        "\n                                " +
                          _vm._s(
                            _vm.$t(
                              "__.Components.parent.printModel.campo_vue_html.identificador"
                            )
                          ) +
                          "\n                            "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.model.labelId,
                        expression: "model.labelId",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: {
                      id: "labelId",
                      name: "labelId",
                      type: "text",
                      disabled: "",
                    },
                    domProps: { value: _vm.model.labelId },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.model, "labelId", $event.target.value)
                      },
                    },
                  }),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-sm-3" }, [
                _c("div", { staticClass: "form-group" }, [
                  _c(
                    "label",
                    {
                      staticClass: "control-label",
                      attrs: { for: "fontSize" },
                    },
                    [
                      _vm._v(
                        "\n                                " +
                          _vm._s(
                            _vm.$t(
                              "__.Components.parent.printModel.sessao_vue_html.tamanhoFonte"
                            )
                          ) +
                          "\n                            "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.model.fontSize,
                        expression: "model.fontSize",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: { type: "number", id: "fontSize", name: "fontSize" },
                    domProps: { value: _vm.model.fontSize },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.model, "fontSize", $event.target.value)
                      },
                    },
                  }),
                ]),
              ]),
            ]),
            _vm._v(" "),
            !_vm.model.personalizado && _vm.model.tipoCampo == 4
              ? _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-sm-3" }, [
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c("label", { attrs: { for: "primeiroNome" } }, [
                          _vm._v(
                            "\n                                " +
                              _vm._s(
                                _vm.$t(
                                  "__.Components.parent.printModel.campo_vue_html.primeiroNome"
                                )
                              ) +
                              "\n                            "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("data-tooltip-component", {
                          attrs: {
                            text: "Apresenta texto até o primeiro espaçamento",
                          },
                        }),
                        _vm._v(" "),
                        _c("checkbox-component", {
                          attrs: { id: "primeiroNome", name: "primeiroNome" },
                          model: {
                            value: _vm.model.primeiroNome,
                            callback: function ($$v) {
                              _vm.$set(_vm.model, "primeiroNome", $$v)
                            },
                            expression: "model.primeiroNome",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _vm.utilizarLimite
                    ? _c("div", { staticClass: "col-sm-3" }, [
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c("label", { attrs: { for: "quebrarLinha" } }, [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(
                                    _vm.$t(
                                      "__.Components.parent.printModel.campo_vue_html.quebLinha"
                                    )
                                  ) +
                                  "\n                            "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("data-tooltip-component", {
                              attrs: {
                                text: _vm.$t(
                                  "__.Components.parent.printModel.campo_vue_html.qndoLimitMaxCharLinhaAbaixo"
                                ),
                              },
                            }),
                            _vm._v(" "),
                            _c("checkbox-component", {
                              attrs: {
                                id: "quebrarLinha",
                                name: "quebrarLinha",
                              },
                              model: {
                                value: _vm.model.quebrarLinha,
                                callback: function ($$v) {
                                  _vm.$set(_vm.model, "quebrarLinha", $$v)
                                },
                                expression: "model.quebrarLinha",
                              },
                            }),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                ])
              : _vm._e(),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }