var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-sm-6" }, [
        _c("table", { staticClass: "table table-striped ajusteVer" }, [
          _c("tbody", [
            _c("h3", [
              _vm._v(
                _vm._s(_vm.$t("__.Crud.prescritor.ver_vue_html.dadosGerais"))
              ),
            ]),
            _vm._v(" "),
            _c("tr", [
              _c("th", { attrs: { scope: "row" } }, [
                _c("h4", [
                  _vm._v(
                    _vm._s(_vm.$t("__.Crud.prescritor.ver_vue_html.codigo"))
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("td", [_c("h4", [_vm._v(_vm._s(_vm.data.id))])]),
            ]),
            _vm._v(" "),
            _c("tr", [
              _c("th", { attrs: { scope: "row" } }, [
                _c("h4", [
                  _vm._v(
                    _vm._s(
                      _vm.$t("__.Crud.prescritor.ver_vue_html.numeroRegistro")
                    )
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("td", [_c("h4", [_vm._v(_vm._s(_vm.data.numeroRegistro))])]),
            ]),
            _vm._v(" "),
            _c("tr", [
              _c("th", { attrs: { scope: "row" } }, [
                _c("h4", [
                  _vm._v(_vm._s(_vm.$t("__.Crud.prescritor.ver_vue_html.cpf"))),
                ]),
              ]),
              _vm._v(" "),
              _c("td", [_c("h4", [_vm._v(_vm._s(_vm.data.cpf))])]),
            ]),
            _vm._v(" "),
            _c("tr", [
              _c("th", { attrs: { scope: "row" } }, [
                _c("h4", [
                  _vm._v(_vm._s(_vm.$t("__.Crud.prescritor.ver_vue_html.rg"))),
                ]),
              ]),
              _vm._v(" "),
              _c("td", [_c("h4", [_vm._v(_vm._s(_vm.data.rg))])]),
            ]),
            _vm._v(" "),
            _c("tr", [
              _c("th", { attrs: { scope: "row" } }, [
                _c("h4", [
                  _vm._v(
                    _vm._s(_vm.$t("__.Crud.prescritor.ver_vue_html.nome"))
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("td", [_c("h4", [_vm._v(_vm._s(_vm.data.nome))])]),
            ]),
            _vm._v(" "),
            _c("tr", [
              _c("th", { attrs: { scope: "row" } }, [
                _c("h4", [
                  _vm._v(
                    _vm._s(_vm.$t("__.Crud.prescritor.ver_vue_html.dtNasc"))
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("td", [
                _c("h4", [
                  _vm._v(_vm._s(_vm.getDateString(_vm.data.dataNascimento))),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("tr", [
              _c("th", { attrs: { scope: "row" } }, [
                _c("h4", [
                  _vm._v(
                    _vm._s(_vm.$t("__.Crud.prescritor.ver_vue_html.genero"))
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("td", [_c("h4", [_vm._v(_vm._s(_vm.data.generoDescricao))])]),
            ]),
            _vm._v(" "),
            _c("tr", [
              _c("th", { attrs: { scope: "row" } }, [
                _c("h4", [
                  _vm._v(_vm._s(_vm.$t("__.Crud.prescritor.ver_vue_html.obs"))),
                ]),
              ]),
              _vm._v(" "),
              _c("td", [_c("h4", [_vm._v(_vm._s(_vm.data.observacao))])]),
            ]),
            _vm._v(" "),
            _c("tr", [
              _c("th", { attrs: { scope: "row" } }, [
                _c("h4", [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        "__.Crud.prescritor.ver_vue_html.tipoConselhoRegional"
                      )
                    )
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("td", [
                _c("h4", [
                  _vm._v(_vm._s(_vm.data.tipoConselhoRegionalDescricao)),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("tr", [
              _c("th", { attrs: { scope: "row" } }, [
                _c("h4", [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        "__.Crud.prescritor.ver_vue_html.ufConselhoRegional"
                      )
                    )
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("td", [
                _c("h4", [
                  _vm._v(_vm._s(_vm.data.ufConselhoRegionalDescricao)),
                ]),
              ]),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-sm-6" }, [
        _c("table", { staticClass: "table table-striped ajusteVer" }, [
          _c("tbody", [
            _c("h3", [
              _vm._v(_vm._s(_vm.$t("__.Crud.prescritor.ver_vue_html.localiz"))),
            ]),
            _vm._v(" "),
            _c("tr", [
              _c("th", { attrs: { scope: "row" } }, [
                _c("h4", [
                  _vm._v(_vm._s(_vm.$t("__.Crud.prescritor.ver_vue_html.cep"))),
                ]),
              ]),
              _vm._v(" "),
              _c("td", [_c("h4", [_vm._v(_vm._s(_vm.data.cep))])]),
            ]),
            _vm._v(" "),
            _c("tr", [
              _c("th", { attrs: { scope: "row" } }, [
                _c("h4", [
                  _vm._v(
                    _vm._s(_vm.$t("__.Crud.prescritor.ver_vue_html.estado"))
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("td", [_c("h4", [_vm._v(_vm._s(_vm.data.estadoDescricao))])]),
            ]),
            _vm._v(" "),
            _c("tr", [
              _c("th", { attrs: { scope: "row" } }, [
                _c("h4", [
                  _vm._v(
                    _vm._s(_vm.$t("__.Crud.prescritor.ver_vue_html.cidade"))
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("td", [_c("h4", [_vm._v(_vm._s(_vm.data.cidadeDescricao))])]),
            ]),
            _vm._v(" "),
            _c("tr", [
              _c("th", { attrs: { scope: "row" } }, [
                _c("h4", [
                  _vm._v(
                    _vm._s(_vm.$t("__.Crud.prescritor.ver_vue_html.endereco"))
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("td", [_c("h4", [_vm._v(_vm._s(_vm.data.endereco))])]),
            ]),
            _vm._v(" "),
            _c("tr", [
              _c("th", { attrs: { scope: "row" } }, [
                _c("h4", [
                  _vm._v(_vm._s(_vm.$t("__.Crud.prescritor.ver_vue_html.nro"))),
                ]),
              ]),
              _vm._v(" "),
              _c("td", [_c("h4", [_vm._v(_vm._s(_vm.data.numero))])]),
            ]),
            _vm._v(" "),
            _c("tr", [
              _c("th", { attrs: { scope: "row" } }, [
                _c("h4", [
                  _vm._v(
                    _vm._s(_vm.$t("__.Crud.prescritor.ver_vue_html.bairro"))
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("td", [_c("h4", [_vm._v(_vm._s(_vm.data.bairro))])]),
            ]),
            _vm._v(" "),
            _c("tr", [
              _c("th", { attrs: { scope: "row" } }, [
                _c("h4", [
                  _vm._v(
                    _vm._s(_vm.$t("__.Crud.prescritor.ver_vue_html.complement"))
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("td", [_c("h4", [_vm._v(_vm._s(_vm.data.complemento))])]),
            ]),
          ]),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-sm-6" }, [
        _c("table", { staticClass: "table table-striped ajusteVer" }, [
          _c("tbody", [
            _c("h3", [
              _vm._v(_vm._s(_vm.$t("__.Crud.prescritor.ver_vue_html.contato"))),
            ]),
            _vm._v(" "),
            _c("tr", [
              _c("th", { attrs: { scope: "row" } }, [
                _c("h4", [
                  _vm._v(_vm._s(_vm.$t("__.Crud.prescritor.ver_vue_html.tel"))),
                ]),
              ]),
              _vm._v(" "),
              _c("td", [_c("h4", [_vm._v(_vm._s(_vm.data.telefone))])]),
            ]),
            _vm._v(" "),
            _c("tr", [
              _c("th", { attrs: { scope: "row" } }, [
                _c("h4", [
                  _vm._v(_vm._s(_vm.$t("__.Crud.prescritor.ver_vue_html.cel"))),
                ]),
              ]),
              _vm._v(" "),
              _c("td", [_c("h4", [_vm._v(_vm._s(_vm.data.celular))])]),
            ]),
            _vm._v(" "),
            _c("tr", [
              _vm._m(0),
              _vm._v(" "),
              _c("td", [_c("h4", [_vm._v(_vm._s(_vm.data.email))])]),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-sm-6" }, [
        _c("table", { staticClass: "table table-striped ajusteVer" }, [
          _c(
            "tbody",
            [
              _c("h3", [
                _vm._v(
                  _vm._s(_vm.$t("__.Crud.prescritor.ver_vue_html.especialids"))
                ),
              ]),
              _vm._v(" "),
              _vm._l(_vm.data.especialidades, function (item) {
                return _c("tr", { key: item.descricao }, [
                  _c("th", { attrs: { scope: "row" } }, [
                    _c("h4", [
                      _vm._v(
                        _vm._s(
                          _vm.$t("__.Crud.prescritor.ver_vue_html.especialid")
                        )
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("td", [_c("h4", [_vm._v(_vm._s(item.descricao))])]),
                ])
              }),
            ],
            2
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("th", { attrs: { scope: "row" } }, [
      _c("h4", [_vm._v("E-mail:")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }