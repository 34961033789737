import VueRouter from "vue-router";

import * as signalR from "@microsoft/signalr";

import Boot from "@/main";
import NotificationModel from "@/models/notificationModel";
import Delay from "@/utils/common/delay";
import { generateUUID } from "@/utils/common/uuid";
import Bus from "@/utils/events/bus";

export default class PharmUPHub {
    private connection: signalR.HubConnection = null;
    private router: VueRouter = null;

    constructor(router: VueRouter) {
        this.router = router;
    }

    private getOrCreateSessionId(): string {
        let sessionId = localStorage.getItem("@PharmUP:SessionId");
        if (sessionId === null) {
            sessionId = generateUUID();
            localStorage.setItem("@PharmUP:SessionId", sessionId);
        }

        return sessionId;
    }

    public async criarConexaoWebSocketPharmUP() {
        const token = localStorage.getItem("@PharmUP:AtendimentoToken");
        const sessionId = this.getOrCreateSessionId();

        this.connection = new signalR.HubConnectionBuilder()
            .withUrl(`${process.env.VUE_APP_ROOT_WEBSOCKET}/hub/pharmupHub`, { accessTokenFactory: () => token })
            .build();

        this.connection.on("FranquiaChanged", async () => {
            // tempo para o token ser atualizado no localstorage
            await Delay(1000);
            this.router.push("/");
            location.reload();
        });

        this.connection.onreconnected(connectionId => {
            sessionStorage.setItem("@PharmUP:WebSocketId", connectionId);
        });

        this.connection.on("VendaUpdate", async (vendaId: number) => {
            Bus.$emit("venda-update", vendaId);
        });

        this.connection.on("Notify", (model: NotificationModel) => {
            Boot.$notifyByType(model.text, model.title, model.type);

            Bus.$emit("add-one-notification");
        });

        try {
            await this.connection.start();
            await this.connection.invoke("AddGrupoTrocaFranquia", sessionId);
            await this.connection.invoke("AddGrupoVendaUpdate");
            await this.connection.invoke("AddGrupoNotification");
            sessionStorage.setItem("@PharmUP:WebSocketId", this.connection.connectionId);
        } catch {}
    }
}
