export function changePosition<T>(array: T[], data: T, direcao: "UP" | "DOWN") {
    let index = 0;

    array.forEach((campo, i) => {
        if (campo == data) {
            index = i;
        }
    });

    if ((index == 0 && direcao == "UP") || (index == array.length - 1 && direcao == "DOWN")) {
        return array;
    }

    if (direcao == "UP") {
        array.splice(index - 1, 0, array.splice(index, 1)[0]);
    } else {
        array.splice(index + 1, 0, array.splice(index, 1)[0]);
    }

    return array;
}

export function sortArray<T>(array: T[], column: keyof T, order: "asc" | "desc"): T[] {
    return array.sort((a, b) => {
        if (a[column] < b[column]) {
            return order === "asc" ? -1 : 1;
        }
        if (a[column] > b[column]) {
            return order === "asc" ? 1 : -1;
        }
        return 0;
    });
}
