import Vue from "vue";
import { mapState, mapMutations, mapGetters } from "vuex";

import checkboxComponent from "@/components/child/form/checkbox.vue";
import comboComponent from "@/components/child/form/combo.vue";
import dataTooltipComponent from "@/components/child/form/datatooltip.vue";
import dateTimePickerComponent from "@/components/child/form/datetimepicker.vue";
import decimalComponent from "@/components/child/form/decimal.vue";
import decimalComSinalCustomComponent from "@/components/child/form/decimalComSinalCustom.vue";
import fieldsetComponent from "@/components/child/form/fieldset.vue";
import moedaComponent from "@/components/child/form/moeda.vue";
import { Component, Prop, Watch } from "@/decorators";
import PreLoadPackModel from "@/models/auxiliar/preLoadPackModel";
import ConfiguracaoFranquiaModel from "@/models/configuracaoFranquia/configuracaoFranquiaModel";
import Configuracoes from "@/models/enum/configuracao/configuracoes";
import ProdutoLoteNfeModel from "@/models/notafiscaleletronica/dados/informacoes/detalhe/produto/produtolote/produtoLoteNfeModel";
import ProdutoNfeModel from "@/models/notafiscaleletronica/dados/informacoes/detalhe/produto/produtoNfeModel";
import PaginationModel from "@/models/paginationModel";
import ProdutoModel from "@/models/produto/produtoModel";
import UnidadeMedidaModel from "@/models/unidadeMedidaModel";
import ProdutoService from "@/services/produto/produtoService";
import UnidadeMedidaService from "@/services/unidadeMedidaService";
import { AppState, Getters, Mutations } from "@/store/store";
import { getProdutoCombo } from "@/utils/common/combo/combotext";
import { addDays } from "@/utils/common/date";
import Delay from "@/utils/common/delay";

import ProdutoComponent from "../crud/produto/edit";
import produtoComponent from "../crud/produto/edit.vue";

@Component({
    components: {
        fieldsetComponent,
        dateTimePickerComponent,
        moedaComponent,
        comboComponent,
        produtoComponent,
        decimalComponent,
        decimalComSinalCustomComponent,
        checkboxComponent,
        dataTooltipComponent,
    },
    computed: {
        ...mapState<AppState>({
            preLoadList: state => state.preLoad.preLoadList,
            loadedList: state => state.preLoad.loadedList,
        }),
        ...mapGetters(["GET_IS_FRACIONAMENTO", "GET_CONFIG_FRANQUIA"] as Getters),
    },
    methods: mapMutations(["LOAD_LIST"] as Mutations),
})
export default class ImportacaoNfeProdutoLotesComponent extends Vue {
    // State computed props
    loadedList: boolean;
    preLoadList: PreLoadPackModel;
    GET_IS_FRACIONAMENTO: () => Promise<boolean>;
    GET_CONFIG_FRANQUIA: (configuracao: Configuracoes) => ConfiguracaoFranquiaModel;

    produtosOptions: Array<Object> = [];
    model: ProdutoNfeModel = null;
    private produtoComponent: ProdutoComponent = null;
    private showProduto = false;
    private index: number = null;
    private filterKey: string = null;
    private unOriginal: string = null;
    private diasValidadePadraoConfig = 1825;

    private unidadeMedidaService = new UnidadeMedidaService();
    private produtoService = new ProdutoService();

    isFracionamento = false;

    get tipoProdutoOptions(): Array<Object> {
        return [
            {
                text: this.$t("__.Components.parent.notafiscalsaida.list_vue_html.todos") as string,
                value: -1,
            },
            { text: this.$t("__.ts.matPrima"), value: 0 },
            { text: this.$t("__.ts.semiAcab"), value: 1 },
            { text: this.$t("__.ts.acabado"), value: 2 },
            { text: this.$t("__.ts.embalagem"), value: 3 },
            { text: this.$t("__.ts.capsula"), value: 4 },
            { text: this.$t("__.ts.homeopatia"), value: 5 },
            { text: this.$t("__.ts.floral"), value: 6 },
        ];
    }

    @Prop({ type: Number, required: true }) numeroNF: number;

    private tipoProdutoId = -1;

    //campos para auxilio de preenchimentos
    private indexConversao = 1;
    private precoUnitarioNf = 0;
    unidadeMedidaNfOptions: Array<Object> = [];

    public clear() {
        this.model = null;
        this.model = new ProdutoNfeModel();
        this.index = null;
        this.tipoProdutoId = -1;
        this.unOriginal = null;
        this.precoUnitarioNf = 0;
        this.indexConversao = 1;
        this.$forceUpdate();
    }

    public setModel(model: ProdutoNfeModel, index: number) {
        this.clear();
        this.index = index;
        this.model.updateFrom(model);
        //this.unOriginal = "Qtd. " + this.model.quantidade.toString() + " - " + this.model.unidadeMedida.toString();
        //gerado na leitura de itens
        this.unOriginal = this.model.descricaoQtdOriginal;
        this.setTipoProduto(model.produtoId);

        let custo = 0;
        if (this.model.valorTotal > 0 && this.model.quantidadeConvertida > 0)
            custo = parseFloat((this.model.valorTotal / this.model.quantidadeConvertida).toFixed(4));

        this.precoUnitarioNf = custo;

        if (this.model.lote == null) {
            this.model.lote = new ProdutoLoteNfeModel();
        }
        this.filterKey = model.descricao.split(" ")[0];
    }

    private setTipoProduto(produtoId: number) {
        if (produtoId > 0) {
            const prod = this.preLoadList.produtos.filter(p => p.id == produtoId);
            if (prod.length > 0) {
                this.tipoProdutoId = prod[0].tipoProduto;
            }
        }
    }

    public getModel() {
        const model = new ProdutoNfeModel();
        model.updateFrom(this.model);

        return model;
    }

    private async getPreLoadPack() {
        while (!this.loadedList) await Delay(100);

        this.unidadeMedidaNfOptions = this.preLoadList.unidadeMedidaCombo();
        this.produtosOptions = this.preLoadList.produtosCombo();
    }

    private filtraProdutoOptions() {
        this.produtosOptions = this.preLoadList.produtos
            .filter(p => this.tipoProdutoId == null || this.tipoProdutoId == -1 || p.tipoProduto == this.tipoProdutoId)
            .map(getProdutoCombo);
    }

    private async loadProdutos() {
        try {
            const data = await this.produtoService.combo().resolveWithJSON<PaginationModel<ProdutoModel>>();
            this.produtosOptions = data.list
                .filter(
                    p => this.tipoProdutoId == null || this.tipoProdutoId == -1 || p.tipoProduto == this.tipoProdutoId,
                )
                .map(getProdutoCombo);
        } catch {}
    }

    private async openComboEditProduto(produtoId: number) {
        this.showProduto = true;

        while (!this.$refs.produtoComponent) await Delay(5);
        this.produtoComponent = this.$refs.produtoComponent as ProdutoComponent;

        this.produtoComponent.modelId = produtoId;
        this.produtoComponent.load();
    }

    private async openComboNewProduto() {
        this.showProduto = true;
        while (!this.$refs.produtoComponent) await Delay(5);
        this.produtoComponent = this.$refs.produtoComponent as ProdutoComponent;

        await this.produtoComponent.load();
    }

    private async onProdutoSaveOk(modelNovoProduto: ProdutoModel) {
        //salvar apenas quando esta incluindo novo e nao quando editado..
        const incluindo = modelNovoProduto.id > 0 && (this.model.produtoId == null || this.model.produtoId <= 0);
        if (incluindo) {
            this.produtosOptions.push({
                value: modelNovoProduto.id,
                text: modelNovoProduto.descricao,
            });
            //colocar tamber no pack
            this.preLoadList.produtos.push(modelNovoProduto);
        }

        this.model.produtoId = modelNovoProduto.id;
    }

    private async onAddNewProduto(closeModal) {
        if (await this.produtoComponent.save()) {
            this.showProduto = false;
            closeModal();
        }
    }

    private getSignalQuantidade() {
        return this.model.unidadeMedidaEstoqueProduto != null ? this.model.unidadeMedidaEstoqueProduto : "";
    }
    //@ts-ignore
    @Watch("model.produtoId")
    private async onProdutoChanged() {
        this.indexConversao = 0;

        if (this.model.produtoId) {
            this.getProduto(this.model.produtoId).withLoading();
        }
    }
    //@ts-ignore
    @Watch("model.unidadeMedidaNfId")
    private async onUnidadeMedidaNfIdChanged() {
        //Validações de unidades de medidas
        this.indexConversao = 0;
        this.model.unidadeMedida = "";

        await this.calcularQuantidadeConvertida();

        if (this.model.unidadeMedidaNfId > 0) {
            const unMed = this.preLoadList.unidadesMedida.filter(p => p.id == this.model.unidadeMedidaNfId);
            if (unMed.length > 0) {
                this.model.unidadeMedida = unMed[0].sigla;
            }
        }
    }
    //@ts-ignore
    @Watch("model.quantidade")
    private onQuantidadeChanged() {
        //Validações de unidades de medidas
        if (this.model.unidadeMedidaId > 0 && this.model.produtoId > 0) {
            this.model.quantidadeConvertida = this.model.quantidade * this.indexConversao;
        }
    }
    //@ts-ignore
    @Watch("model.quantidadeConvertida")
    private onQuantidadeConvertidaChanged() {
        let custo = 0;
        if (this.model.valorTotal > 0 && this.model.quantidadeConvertida > 0)
            custo = parseFloat((this.model.valorTotal / this.model.quantidadeConvertida).toFixed(4));

        this.precoUnitarioNf = custo;
    }

    //@ts-ignore
    @Watch("tipoProdutoId")
    private onTipoProdutoChanged() {
        this.filtraProdutoOptions();
    }

    //@ts-ignore
    @Watch("model.importarSemLote")
    private onImportarSemLoteChanged(newValue) {
        if (newValue) {
            this.model.lote.descricao = "";
            this.model.lote.dataFabricacao = null;
            this.model.lote.dataValidade = null;
            this.model.lote.densidade = null;
            this.model.lote.precoMaximoConsumidor = null;
        }
    }

    public getValidModel(next = false) {
        this.$validator.validateAll().then(isValid => {
            if (isValid) {
                this.$emit("get-model", this.model, this.index, next);
            }
        });
    }

    private async getProduto(id: number) {
        if (!id) {
            return;
        }

        try {
            const data = await new ProdutoService().get(id).then(r => r.json() as Promise<ProdutoModel>);
            this.tipoProdutoId = data.tipoProduto;
            this.model.descricaoProduto = data.descricao;
            this.model.produtoId = data.id;
            this.model.unidadeMedidaId = data.unidadeMedidaEstoqueId;
            this.model.densidadeProduto = this.model.lote.densidade;
            this.model.custoReferenciaProduto = data.custoReferencia;

            await this.getUnidadesMedida(
                data.unidadeMedidaEstoqueId,
                this.model.densidadeProduto,
                data.id,
            ).withLoading();
        } catch {}
    }

    private async getUnidadesMedida(unidadeId: number, densidade: number, produtoId: number) {
        try {
            const unidade = await this.unidadeMedidaService.get(unidadeId).resolveWithJSON<UnidadeMedidaModel>();

            this.model.unidadeMedidaEstoqueProduto = unidade.sigla;

            let fatorConversor = await this.unidadeMedidaService
                .converterUnidadeMedida(this.model.unidadeMedidaNfId, unidadeId, densidade, produtoId)
                .resolveWithJSON<number>();

            if (!fatorConversor) {
                fatorConversor = 1;
            }

            this.indexConversao = fatorConversor;

            if (!this.model.quantidadeConvertida) {
                if (unidade.id == this.model.unidadeMedidaNfId) {
                    this.model.quantidadeConvertida = this.model.quantidade * fatorConversor;
                } else {
                    if (fatorConversor > 0) {
                        this.model.quantidadeConvertida = this.model.quantidade * fatorConversor;
                    } else {
                        //se ainda nao foi atribuido valor ao campo, atribuir igual a quantidade
                        if (!this.model.quantidadeConvertida)
                            this.model.quantidadeConvertida = this.model.quantidade * fatorConversor;
                    }
                }
            }
        } catch {}
    }

    private cursorFocus(refName) {
        let doc = this.$refs[refName]["$el"] as HTMLElement;
        if (doc == null) doc = this.$refs[refName] as HTMLElement;
        if (doc != null) {
            if (doc.tagName.toLowerCase() == "input") {
                this.$nextTick(() => doc.focus());
            } else {
                const inpEl = doc.getElementsByTagName("input");
                if (this.model.produtoId == null)
                    setTimeout(() => {
                        this.$nextTick(() => (inpEl[0].value = this.filterKey));
                        this.$nextTick(() => (inpEl[0].innerText = this.filterKey));
                    }, 200);
                this.$nextTick(() => inpEl[0].focus());
            }

            const x = window.scrollX,
                y = window.scrollY;
            window.scrollTo(x, y - 150);
        }
    }

    private autoCompleteLote() {
        if (this.model.lote.dataFabricacao == null) {
            this.model.lote.dataFabricacao = new Date();
        }
        if (this.model.lote.dataValidade == null) {
            this.model.lote.dataValidade = addDays(this.diasValidadePadraoConfig, new Date());
        }

        if (this.model.lote.descricao == null || this.model.lote.descricao == "") {
            let descSemEspacos = this.model.descricao.replace(" ", "");
            while (descSemEspacos.indexOf(" ") != -1) {
                descSemEspacos = descSemEspacos.replace(" ", "");
            }
            this.model.lote.descricao = descSemEspacos + this.model.codigo + "NF" + this.numeroNF.toString();
        }

        if (this.model.lote.quantidade == 0 || this.model.lote.quantidade == null) {
            this.model.lote.quantidade = this.model.quantidade;
        }

        if (this.model.lote.unidadeMedidaSigla == "" || this.model.lote.unidadeMedidaSigla == null) {
            this.model.lote.unidadeMedidaSigla = this.model.unidadeMedida;
        }
    }

    private async getConfigDias() {
        const config = this.GET_CONFIG_FRANQUIA(Configuracoes.DiasValidadePadraoLoteNaoInformadoXml);
        this.diasValidadePadraoConfig = config.valor != null ? config.valor : 1825;
    }

    //@ts-ignore
    @Watch("model.lote.densidade")
    private async onChangeDensidade() {
        this.model.densidadeProduto = this.model.lote.densidade;

        await this.calcularQuantidadeConvertida();
    }

    private async calcularQuantidadeConvertida() {
        if (this.model.unidadeMedidaId > 0 && this.model.produtoId > 0) {
            //buscas novo indexconversao ealterar o valor do convertido
            this.indexConversao = await this.unidadeMedidaService
                .converterUnidadeMedida(
                    this.model.unidadeMedidaNfId,
                    this.model.unidadeMedidaId,
                    this.model.densidadeProduto,
                    this.model.produtoId,
                )
                .resolveWithJSON<number>();

            this.model.quantidadeConvertida = this.model.quantidade * this.indexConversao;
        }
    }

    private async mounted() {
        this.isFracionamento = await this.GET_IS_FRACIONAMENTO();

        Promise.all([this.getConfigDias(), this.getPreLoadPack()])
            .withLoading()
            .then(() => {})
            .catch(() => {});
    }
}
