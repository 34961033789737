// Portuguese Brazil

const __ = {
    obrigatorio: "* este campo é obrigatório",

    ts: {
        macho: "Macho",
        femea: "Fêmea",
        endeInfoJaCadast: "O endereço informado já está cadastrado",
        preenchCorretCamposAddLista: "Preencha corretamente os campos para adicionar à lista",
        endeNaoRemovPoisVendasEntreg: "O endereço não pode ser removido pois já possui vendas entregues!",
        visuVendasClient: "Visualizar Vendas do Cliente",
        verClient: "Ver Cliente",
        verPontos: "Ver pontos acumulados do cliente",
        regisRemovSucess: "Registro foi removido com sucesso",
        parcelando: "Parcelando...",
        vlrFormaFarmaceu: "Valor Forma Farmacêutica",
        replicando: "Replicando...",
        vincularKitEmb: "<label>Vincular Kit de Embalagem:</label>",
        atualizacao: "Atualização!",
        tamanhFontExcedRotulo: "Tamanho da fonte excede o tamanho do rótulo.",
        quantidadeColunasInvalida: "Quantidade de colunas inválidas. Campo: ",
        registNaoInseridExcedRotulo: "Registro não inserido pois excede o tamanho do rótulo.",
        copiaa: "Cópia!",
        regisCopiadoSucess: "Registro foi copiado com sucesso.",
        foiAltQtdeParaMaxDisp: " foi alterado a quantidade para a maxima disponível.",
        codVenda: "Cód. Venda",
        obss: "Observação",
        manipuConclSucesso: "Manipulação concluída com sucesso.",
        orcamento: "Orçamento",
        volPCaps: "Volume for Caps.",
        qtdeCaps: "Qtde Caps.",
        unidMedCaps: "Unit. Med. Caps. ",
        consolidado: "Consolidado",
        estaComQtdAbaixoMinCad: "está com quantidade de estoque abaixo do mínimo cadastrado.",
        avisEstqBaixo: "Aviso de Estoque Baixo",
        semEstoque: "Falta de Estoque",
        estoqueAtual: "Quantidade Atual Não Comprometida",
        estoqueMinimo: "Estoque Mínimo",
        segProdsQtdsEstqAbaixMin:
            "Seguintes produtos estão com suas quantidades de estoque abaixo de seus mínimo cadastrado:",
        naoPossQtdEstqSufic: "não possui quantidade de estoque suficiente para atender esta demanda.",
        segProdsNaoPossQtdEstqSufic:
            "Seguintes produtos não possuem quantidade de estoque suficiente para atender esta demanda:",
        substituirPorFormulaBase: "Deseja que o sistema faça a substituição para formula base",
        printRotuloManipu: "Imprimir Rótulo de Manipulação",
        puro: "puro",
        diluido: "diluído",
        calc: "Cálculo",
        volDose: "Vol. Dose",
        concentr: "Concentração",
        cor: "Cor",
        estqPadrao: "Estoque Padrão",
        fabEstrang: "Fabricante Estrangeiro",
        atencao: "Atenção!",
        aprovado: "Aprovado!",
        buscarEm: "Buscar em...",
        desejaContinuar: "Deseja continuar a impressão pelo navegador?",
        obrigatorioinformarLoteParaProduto: "É obrigatório informar o lote para produto",
        dataInvalida: "Data Inválida",

        // Impressão e impressoras
        // =====================================================================
        imprimir: "Imprimir",
        impressora: "Impressora",
        copias: "Cópias",
        selecioneZeroNaoImprimir: 'Selecione "0" para não imprimir',
        impressoraPadraoFoiUsadaEmUmaOuMaisPaginas:
            "Alguma impressora está offline ou não foi definida impressora no modelo.",
        impressoraNull: "(impressora não definida)",
        erroNenhumaImpressoraDisponivel: "Nenhuma impressora disponível.",
        coleta: "Coleta",

        // Upload de arquivo
        // =====================================================================
        tipoArquivoInvalido: "Tipo de arquivo inválido.",
        exemplosTipoArquivoPermitido: "Tipos de arquivo permitido:",
        arquivosSalvos: "Arquivos salvos",

        //..child/form/combo.ts
        addNovo: "Adicionar Novo",

        //..child/grid/grid.ts
        sttsAFaturar: "Status: A Faturar",
        sttsFaturado: "Status: Faturado",
        sttsConcluido: "Status: Concluído",
        sttsCancelado: "Status: Cancelado",
        sttsVencidos: "Status: Vencidos",
        conta: "Conta",
        categoria: "Categoria",
        de: "De: ",
        ate: "Até: ",
        pgtoDe: "Pagamento De: ",
        fornecedor: "Fornecedor: ",

        //..child/impressaoComponent/impressaoComponent.ts
        muitosRotulos:
            "Sistema identificou uma quantidade muito de grande rótulos, então eles serão impressos de forma paginada após a impressão dos modelos!",
        salvarRotulo: "Salvar Rótulo",
        salvarRotuloSucesso: "Rótulo salvo com sucesso!",

        //..child/loadingmodal/loadingmodal.ts
        carregandooo: "Carregando...",
        salvando: "Salvando...",
        excluindo: "Excluindo...",

        //..child/logEntidade/logEntidade.ts
        historicoAlteracoes: "Histórico de alterações",

        //..parent/app/app.ts
        sessaoExp: "A sua sessão irá expirar em ",
        seg: " <strong> segundos <br/>",
        pressSimParaLogado: "Pressione <b>SIM</b> para permanecer logado ",
        pressNaoParaDesconect: "ou pressione <b>NÃO</b> para desconectar.<br/><br/>",
        obs: "Obs.: Ao se desconectar seis dadps da sessão serão perdidos.",
        tempoParaExp: "Tempo para expirar Sessão!",
        sim: "Sim",
        nao: "Não",
        min: " minutos",
        nfe: "Nota Fiscal de Serviço",
        cf: "Cupom Fiscal",
        vendaCod: " - Venda Cód: ",
        erroReq: "Erro na requisição do registro para consulta de emissão de nota fiscal. ",
        aindaPend: " ainda Pendente, tente requisitar novamente mais tarde pela rotina de venda.",
        pendente: "Pendente",
        reprovada: "Reprovada",
        emissaoConfPrefe: "Emissão de nota fiscal confirmada pela prefeitura.",

        //..parent/atendimento
        franquiaNaoModuloContratado: "Franquia não tem esse módulo contratado",
        historicoContato: "Histórico do Contato",

        //..parent/atualizacaoValores
        grupoProduto: "Grupo de Produto",
        markupAtual: "Markup Atual",
        valorMinimoAtual: "Valor Mínimo Atual",
        valorAdicionalAtual: "Valor Adicional Atual",
        informePercentual: "Informe um percentual de atualização!",
        sucessoAtualizacao: "Atualização do valores feita com sucesso!",

        //..parent/crud/todos
        inclusao: "Inclusão!",
        alt: "Alteração!",
        erro: "Erro!",
        erroCarregarDados: "Erro ao carregar dados!",
        camposNaoVazios: "Os campos não podem estar vazios!",
        senhasDif: "Senhas diferentes!",
        regisSalvoSucess: "Registro foi salvo com sucesso.",

        //..parent/crud/todos
        codigo: "Código",
        nome: "Nome",
        ativo: "Ativo",
        desejaExcl: "Deseja excluir ",
        acaoNaoRevert: "Esta ação não poderá ser revertida!",
        exclusao: "Exclusão!",
        regisExcluSucess: "Registro foi excluído com sucesso.",

        //../parent/crud/beneficio/edit.ts
        faixaInic: "Faixa Início",
        faixaFim: "Faixa Fim",
        vlr: "Valor",

        //../parent/crud/beneficio/list.ts
        descr: "Descrição",

        //../parent/crud/campanha/edit.ts
        premio: "Prêmio",
        aviso: "Aviso!",
        dtFimMaiorIncio: "Data Fim deve ser maior que Data Início!",

        //../parent/crud/campanha/list.ts
        dtInicio: "Data Início",
        dtFim: "Data Fim",

        //../parent/crud/capacidadeProducao/edit.ts
        sucesso: "Sucesso",
        capacidadeProducaoAtualizada: "Capacidade de produção atualizada!",

        //../parent/crud/capsula/list.ts
        nro: "Número",

        //../parent/crud/cliente/edit.ts
        cliente: "Cliente",
        localiz: "Localização",
        endeEntrega: "Endereço de Entrega",
        contato: "Contato",
        alertImpressao: "Para imprimir é preciso salvar o cadastro.",

        //../parent/crud/cliente/pontos.ts
        nenhumaPontuacao: "Nenhuma pontuação encontrada!",

        //../parent/crud/duplicacoes
        cadastrosNaoResolvidos:
            "Os conflitos NÃO foram resolvidos, será necessário alterar os dados cadastrais para que não haja duplicação no sistema",
        cadastroResolvidos: "Os conflitos do cadastros foram resolvidos com sucesso",
        unificacaoConfirmacao:
            "Ao prosseguir o sistema irá remover o cadastro de origem transferindo todos os seus vínculos para o cadastro de destino. Essa ação é irreversível, tem certeza que deseja continuar?",
        unificacaoErro: "Erro ao unificar cadastros! Tente novamente!",
        unificacaoSucesso: "Cadastros unificados com sucesso!",
        nenhumManterCadastro: "Por favor selecione um cadastro para manter",
        maisDeUmManterCadastro: "Por favor selecione somente um cadastro para manter",
        razaoSocial: "Razão Social",
        cpf: "CPF",
        cpfExistente: "O CPF informado já está cadastrado",
        cnpj: "CNPJ",
        email: "E-mail",
        rg: "RG",
        telefone: "Telefone",

        //../parent/crud/cliente/enderecoEntregaEdit.ts
        pesqCEP: "Pesquisa por CEP",
        pesqNaoEncResul: "A pesquisa não encontrou resultados",

        //../parent/crud/cliente/list.ts
        celular: "Celular",
        editCampos: "Editar Campos",
        fechar: "Fechar",
        editar: "Editar",
        vendasCliente: "Vendas do Cliente ",

        //../parent/crud/cliente/listVendas.ts
        situacao: "Situação",
        data: "Data",
        dataConclusao: "Data Conclusão",
        dataAprovacao: "Data Aprovação",
        dataEntrega: "Data Entrega",

        //../parent/crud/compras/edit.ts
        grupo: "Grupo",
        estoqueMin: "Estoque Mín.",
        estoqueMax: "Estoque Máx.",
        qtdVendida: "Qtd. Vendida",
        valorVendido: "Valor Vendido",
        consumoDiario: "Consumo Diário",
        qtdComprar: "Qtd. a comprar",
        necessarioSelec: "É necessário selecionar a unidade de medida do produto:",
        necessarioSelecData: "É necessário selecionar a validade do produto:",
        necessarioAddValorUnitario: "É necessário adicionar o valor unitário do produto:",
        solicitarCotacaoForn: "Solicitar cotação para os fornecedores",
        desejaEnviarEmail: "Deseja enviar e-mail solicitando cotação dos fornecedores?",
        emailEnviadoForn: "E-mail enviado para fornecedores",
        cancelarCompra: "Cancelar Compra",
        desejaCancelar: "Deseja cancelar essa compra ",
        alterar: "Alterar",

        //../parent/crud/compras/cotacao.ts
        historicoComprasProduto: "Histórico de compras do produto",

        //../parent/crud/compras/melhorCompra.ts
        melhorCompraSucesso: "Melhor compra gerada com sucesso",
        desejaImprimir: "Deseja imprimir o PDF?",

        //../parent/crud/configuracaofranquia/edit.ts
        soma: "Soma",
        mult: "Multiplicação",
        nenhum: "Nenhum",
        franq: "Franquia",
        user: "Usuário",
        homolog: "Homologação",
        prod: "Produto",
        avisoSNGPCControlado:
            "Esteja ciente que ao não informar dados do RG do cliente em vendas com controlado podem ocorrer problemas ao fazer o envio automatizado das informações do SNGPC",
        updateMarkupPadrao:
            "Você está atualizando o Markup padrão da franquia, deseja recalcular o valor de venda de todos os produtos que estão utilizando esse Markup?",
        updateMarkupPadraoSucesso: "Valor de venda dos produtos atualizados com sucesso",

        //../parent/crud/configuracaoWhatsApp/edit.ts
        desconectarIntegracao: "Desconectar Integracao",
        confirmarDesconectarIntegracao: "Tem certeza que deseja desconectar a integração com WhatsApp?",

        //../parent/crud/contagerencial/edit.ts
        entrada: "Entrada",
        saida: "Saída",
        transferecia: "Transferência",

        //../parent/crud/contagerencial/list.ts
        classif: "Classificação",

        //../parent/crud/coleta/list.ts
        localEntrega: "Local de Entrega",

        //../parent/crud/contapaga/list.ts
        venc: "Venc.",
        vlrOrig: "Valor Orig.",
        vlrPago: "Valor Pago",
        pagamento: "Pagamento",

        //../parent/crud/contapagar/edit.ts
        pago: "Pago",
        descont: "Desconto",
        juros: "Juros",
        forma: "Forma",

        //../parent/crud/contapagarmultiplas/edit.ts
        vencimento: "Vencimento",
        saldo: "Saldo",

        //../parent/crud/contareceber/edit.ts
        tarifa: "Tarifa",
        vlrOriginal: "Valor Original",

        //../parent/crud/contareceber/listOperadoras.ts
        operadora: "Operadora",
        autoriz: "Autorização",

        //../parent/crud/contareceber/recebimentoconta.ts
        dinheiro: "Dinheiro",
        cheque: "Cheque",
        boleto: "Boleto",
        cardDebito: "Cartão de Débito",
        cardCredito: "Cartão de Crédito",
        vlrNaoPodeSerMaior: "Valor a ser pago não pode ser maior que saldo a receber(R$",
        vlrDescMuitoAlto: "Valor de desconto muito alto, valor de desconto não pode ser maior que saldo + juros.",
        opNaoPermitida: "Operação não permitida!",

        //../parent/crud/contarecebermultiplas/edit.ts
        recebido: "Recebido",
        recebimento: "Recebimento",

        //../parent/crud/contarecebida/list.ts
        vlrReceb: "Valor Receb.",

        //../parent/crud/devolucaCompra/consulta.ts
        notaEntrada: "Nota de Entrada",
        notaDevolucao: "Nota de Devolução",

        //../parent/crud/etapaPCP/edit.ts
        descricao: "Descrição",
        ordem: "Ordem",
        obrigatorio: "Obrigatório",

        //../parent/crud/etiquetas/list.ts
        erroGerarEtiq: "Erro ao gerar etiquetas",
        nenhumaEtiqueta: "Nenhuma etiqueta disponível para impressão",

        //../parent/crud/formafarmaceutica/edit.ts
        dose: "Dose",
        percentual: "Percentual",
        unitario: "Unitário",
        subGrupo: "Sub Grupo",
        tipoCalculo: "Tipo Cálculo",
        quantidade: "Quantidade",
        desmembrado: "Desmembrado",
        sistemico: "Sistêmico",
        topico: "Tópico",
        total: "Total",
        homeopatia: "Homeopatia",
        papel: "Papel",
        volQtdeMg: "Volume x Qtde (mg)",
        volQtdePorcent: "Volume x Qtde (%)",
        volume: "Volume",
        replicou: "Replicou",
        regisSalvosSucess: "Registros foram salvos com sucesso",
        frmFarmaceutica: "FORMA FARMACÊUTICA",

        //../parent/crud/formafarmaceutica/item.ts
        qsp: "Qsp",
        falvoriz: "Flavorizante",
        veicuExcip: "Veículos/Excipientes",

        //../parent/crud/formafarmaceutica/list.ts
        vlrMin: "Valor mínimo",
        vlrAdd: "Valor adicional",

        //../parent/crud/formarecebimento/edit.ts
        metodo: "Método",
        bandeiras: "Bandeiras",
        obgAoMenosUma: "Obrigatório a seleção de pelo menos uma Bandeira.",
        avis: "Aviso",
        mtdNaoPodeSerRemov: "O Método não pode ser removido pois já possui contas recebidas!",
        desejaReplFrma: "Deseja Replicar a forma ",
        casoAltRecomendSalvar: "Caso esse registro tenha sofrido alterações, aconselhamos Salvar antes de prosseguir!",
        replicado: "Replicado",
        registroSalvSucess: "Registro foi salvo com sucesso",
        nenhumaBandeiraAdd: "Nenhuma bandeira foi adicionada.",

        //../parent/crud/formulapadrao/edit.ts
        tipoProd: "Tipo Produto",
        qtde: "Qtde",
        qtdePesagem: "Qtde Pesagem",
        unMedida: "Un.Med.",
        unManipulacao: "Un. Manipulação",
        fase: "Fase",
        desejaRealmDesab: "Deseja realmente desabilitar ",
        replicAtualiz: "Replicados/Atualizados",
        qtdeFormPadrao: "Dose da Fórmula Padrão",

        //../parent/crud/formulapadrao/item.ts
        escolhaKitParaVincu:
            "Este produto possui vinculo com kit de embalagem, escolha o kit que deseja vincular a esta fórmula.",
        itensKitAddAuto:
            "Os itens que compoem este kit serão adicionados automaticamente a esta formula padrão, deseja vincular este kit?",
        casoNaoQueiraKit: "Caso não queira vincular um kit a esta fórmula, clique em NÃO para prosseguir sem vinculos.",
        prodFinal: "Produto Final",
        tipoFormula: "Tipo Fórmula",
        verFrmlPadrao: "Ver Fórmula Padrão",
        frmlPadrao: "Fórmula Padrão",

        recalcValores: "Recalculo de Valores",
        nenhumRegisSelec: "Nenhum registro selecionado.",

        //../parent/crud/formulapadraomanipulacao/edit.ts
        acaoRealizSucess: "Ação realizada com sucesso.",
        manipulacao: "Manipulação",

        //../parent/crud/formulapadraovalorproduto/list.ts
        formula: "Fórmula",
        custoCalculado: "Custo Calculado",
        refeAtual: "Referência Atual",
        valorVendaCalc: "Valor Venda Calculado",
        valorVendaAtual: "Valor Venda Atual",

        //../parent/crud/fornecedor/edit.ts
        fisica: "Física",
        juridica: "Jurídica",

        //../parent/crud/franquia/edit.ts
        replicaFranquia: "Replica de Franquia!",
        replicasBaseSalvas: "Replica base salvas com sucesso.",
        msgExcluirDados: "Tem certeza que deseja excluir os Registros da Franquia?",
        msgFalhaExclusao: "Falha ao tentar excluir dados da franquia.",

        //../parent/crud/inutilizacaoNotaFiscal/edit.ts
        inutilizacao: "Inutilização",
        inutilizacaoSucesso: "Notas Fiscais inutilizadas com sucesso",

        //../parent/crud/inventario/edit.ts
        cod: "Cód.",
        qtdDisponivel: "Qtd. Disponível",
        qtdVencido: "Qtd. Vencido",
        unid: "Unid.",
        lote: "Lote",
        dtFabric: "Data de Fabricação",
        dtVal: "Data de Validade",
        qtdAtual: "Qtd. Atual",
        qtdMovimentada: "Qtd. Movimentada",
        dtCriacao: "Data de Criação",
        valLote: "Validade do Lote",
        unidade: "Unidade",
        tipoMoviment: "Tipo Movimentação",
        campoVazio: "Campo Vazio!",
        campoInventDeve: "O campo tipo inventário deve ser preenchido.",
        campoGrupoProdsDeve: "O campo Grupo de Produtos deve ser preenchido.",
        jaExisteInvent: "Já existe um inventário em andamento!",
        naoPossivIniciarDois: "Não é possivel iniciar dois inventarios ao mesmo tempo.",
        aprovOrcamento: "Aprovar Orçamento.",
        todasAltRevertidas: "Esta opção fará com que todas as alterações efetuadas sejam revertidas.",
        desejaReverter: "Deseja reverter todos os ajustes?",
        cancelar: "Cancelar",
        voltar: "Voltar",
        reverter: "Reverter",
        inventCancelado: "Inventário Cancelado!",
        inventCanceladoSucess: "Inventário cancelado com sucesso. Todas as alterações foram revertidas",
        naoPossivCancelar: "Não foi possivel cancelar o inventário nesse momento, tente novamente.",
        opcIraFinaliz: "Esta opção irá finalziar o Inventário.",
        desejaProsseg: " Deseja prosseguir?",
        finalizar: "Finalizar",
        inventFinalizado: "Inventário Finalizado!",
        inventFinalizadoSucess: "Inventário finalizado com sucesso.",
        naoPossivFinalizar: "Não foi possivel finalizar o inventário nesse momento, tente novamente.",
        alteraQtdLote: "Esta opção irá alterar a quantidade deste lote e não poderá ser revertido.",
        loteAlterado: "Lote Alterado!",
        tenteNovamente: "Tente novamente.",
        inventario: "Inventário ",
        inventFinalizadoSoConsul: "Inventário finalizado, somente consulta disponível",
        venda: "Venda",

        //../parent/crud/inventario/list.ts
        tipoInventario: "Tipo de Inventário",
        status: "Status",
        desejaExclInventario: "Deseja excluir Inventário ",

        //../parent/crud/inventario/produtos.ts
        precMaxConsumidor: "Preço Máximo Consumidor",
        todosProdsVerifSucess: "Todos produtos verificados com sucesso.",
        naoEncontProdsDiverg: "Não encontrado produtos divergentes.",

        //../parent/crud/kitembalagem/edit.ts
        embalagem: "Embalagem",
        matPrima: "Matéria Prima",
        semiAcab: "Semi-Acabados",
        acabado: "Acabado",
        capsula: "Cápsula",
        floral: "Floral",
        recipiente: "Recipiente",
        tampa: "Tampa", //cadê?
        aplicador: "Aplicador",
        outrosEmb: "Outros (Embalagens)",
        priorizar: "Priorizar",

        //../parent/crud/kitembalagem/edit.ts
        antimicrobiano: "Antimicrobiano",
        sujeitoControlEsp: "Sujeito A Controle Especial",
        titulo: "Título",
        msg: "Mensagem",
        erroFormaFarmaceutica: "É necessário ter no mínimo uma Forma Farmaceutica cadastrada.",
        erroProduto: "É necessário ter no mínimo um produto cadastrado.",

        //../parent/crud/meta/edit.ts
        jan: "Janeiro",
        fev: "Fevereiro",
        mar: "Março",
        abr: "Abril",
        mai: "Maio",
        jun: "Junho",
        jul: "Julho",
        ago: "Agosto",
        set: "Setembro",
        out: "Outubro",
        nov: "Novembro",
        dez: "Dezembro",
        dadosInval: "Dados Inválidos",
        dataFimMiorInicio: "A Data Fim deve ser maior que a Data Início!",
        periodo: "Período",
        diasUteis: "Dias Úteis",

        //../parent/crud/metodoPagamento/list.ts
        nroParcelas: "Número de Parcelas",
        intervalEntreParc: "Intervalo (dias) entre Parcelas",
        diasPrimeiraParc: "Dias para Primeira Parcela",

        //../parent/crud/molde/edit.ts
        sistApenasMl: "Sistema está preparado apenas para unidade de medida igual Mililitro!",

        //../parent/crud/movimentacaoconta/list.ts
        cntCorrente: "Conta Corrente",
        cntGerencial: "Conta Gerencial",

        //../parent/crud/movimentacaoestoque/edit.ts
        vlrUnit: "Valor Unitário",
        estqDestinoNaoPodeMesmoOrig: "O estoque de destino não pode ser o mesmo da origem",
        incluaUmLote: "Inclua ao menos um lote de produto",
        loteJaExist: "Lote já existente",
        oLote: "O lote ",
        jaInclNessaMoviment: " já foi incluido nesta movimentação de estoque. Deseja substituí-lo?",

        //../parent/crud/movimentacaoestoque/edit.ts
        qtdDisponivelInsulf: "Quantidade disponível insuficiente",
        naoPossuiQtdSufic: " não possui quantidade disponível suficiente. Deseja continuar?",
        msgStatusQuarentena: "Lote está em quarentena e não pode ser movimentado",
        msgLoteEmFracionamento: "Lote está em fracionamento e não pode ser movimentado.",

        //../parent/crud/anturezaoperacao/list.ts
        dentroEstado: "Detro do Estado",

        //../parent/crud/paciente/list.ts
        especie: "Espécie",

        //../parent/crud/paciente/edit.ts
        atencaoPacientePossuiAlergias: "Atenção, este paciente possui alergia(s):",
        atencaoPacientePossui: "Atenção, este paciente possui condições especiais:",

        //../parent/crud/pcp/edit.ts
        dataInicio: "Data de Início",
        dataFim: "Data do Fim",
        motivoEtapaPCP: "Motivo",
        usuarioInicio: "Usuário Início",
        usuarioFim: "Usuário Fim",
        manipulacaoNaoEncontrada: "Ordem de Manipulação não encontrada!",

        //../parent/crud/PDV/list.ts
        sldCaixa: "Saldo do Caixa",
        sldAtulCaixa: "Saldo Atual do Caixa",

        //../parent/crud/posologia/list.ts
        dosesSemana: "Doses por semana",
        posologiaPadraoAlerta:
            'Ao marcar a posologia como "Padrão", saiba que esta será carregada automaticamente em todos os itens acabados e de manipulação. A troca pode ser feita a qualquer momento, mas lembre-se: Somente uma posologia deve estar marcada como padrão. Caso contrário, o sistema selecionará a primeira que encontrar.',

        //../parent/crud/prescritor/edit.ts
        masc: "Masculino",
        fem: "Feminio",
        faixaFinalMaior: "Não é permitido faixa final menor ou igual a faixa inicial.",

        //../parent/crud/produto/edit.ts
        verPrescr: "Ver Prescritor",
        liberado: "Liberado",
        formfarmceu: "Forma Farm.",
        excipiente: "Excipiente",
        fc: "F.C.",
        cstUnit: "Custo Unitário",
        val: "Validade",
        fab: "Fabricação",
        sinonimo: "Sinônimo",
        ver: "Ver",
        verLote: "Ver Lote",
        printRotulo: "Imprimir Rótulo",
        defLotePadr: "Definir como lote padrão",
        estNaoSeraControl:
            "O Estoque deste produto não será mais controlado pelo sistema e avisos sobre o mesmo não serão mais exibidos!",
        desejaRealmRemoverContr: " Deseja mesmo remover controle de estoque deste produto?",
        atualizVlrVenda: "Atualizar valor de venda?",
        desejRecalcVlrVenda: "Deseja recalcular o valor de venda deste produto?",
        desejRecalcMarkup: "Deseja recalcular o markup deste produto?",
        logAltValores: "Log de Alteração de Valores de Financeiro do Produto",
        jaExisteAssocExcipiente: "Já existe associação de excipiente para essa forma farmaceutica",

        //../parent/crud/produto/list.ts
        vlrsVendaAtualizSucess: "Valores de venda atualizados com sucesso.",

        //../parent/crud/produtologfinanceiro/list.ts
        rotina: "Rotina",
        vlrCusto: "Valor Custo",
        cstRefe: "Custo Referência",
        cstMedio: "Custo Médio",
        vlrVenda: "Valor Venda",
        logAlt: "Log de Alterações!",
        naoHouveAltRegis: "Não houve alterações registradas neste produto!",

        //../parent/crud/produto/replicarProduto.ts
        selecItensProd: "Seleção de Itens do Produto",

        //../parent/crud/recompensa/list.ts
        dias: "dias",
        diasVencimento: "Dias para vencimento",
        percentualValorAPagar: "Percentual do valor a pagar",
        somaValoresPercentualTotal: "Soma do total dos pencentuais {replace}% não fecha em 100%",

        //../parent/crud/recompensa/list.ts
        ptsNecess: "Pontos Necessários",

        //../parent/crud/rede/edit.ts
        naoInfo: "Não Informado",
        tributMunicipio: "1 Tributação No Municipio",
        tributForaMunicipio: "2 Tributação Fora Do Municipio",
        isencao: "3 Isenção",
        imune: "4 Imune",
        exigiSuspensJudic: "5 Exigibilidade Suspensa Decisao Judicial",
        exigiSuspensAdminis: "6 Exigibilidade Suspensa Procedimento Administrativo",
        baseModelRede: "Base Modelo de Rede!",
        replicaBaseSucess: "Replica de base modelo gerada com sucesso, franquias podem ser replicadas.",

        //../parent/crud/replicarSelecaoFranquia/edit.ts
        nomeFantasia: "Nome Fantasia",
        selecFranq: "Seleção de Franquias",

        //../parent/crud/replicarSelecaoFranquiaFiliais/edit.ts
        selecFranqFiliais: "Seleção de Franquias Filiais",
        replicarFiliais: "Replicar entre filiais",

        //../parent/crud/rotulopersonalizado/edit.ts
        semLogo: "Sem logo",
        esquerda: "Esquerda",
        direita: "Direita",
        semQR: "Sem QR Code",
        esqBaixo: "Esquerda embaixo",
        dirBaixo: "Direita embaixo",
        codBarras: "Código de barras",
        loteEstoque: "Lote estoque",
        campo: "Campo",
        tamFonte: "Tamanho da fonte",
        negrito: "Negrito",
        confImpress: "Configurações de impressão",
        visuImpress: "Visualização da impressão",
        tipoUso: "Tipo uso",
        colocarCima: "Colocar para cima",
        colocarBaixo: "Colocar para baixo",
        dimensoes: "Dimensões",
        msgLoteNaoEncontrado: "Lote não encontrado, não será possível carregar o modelo do rótulo",

        //../parent/crud/separacao/edit.ts
        itensEmb: "Itens Embalagem",
        unidadeMedida: "Unidade Medida",
        necSepararTodosItens: "Necessário separar todos os itens para continuar",
        necSenhaParaSepararTodosItens: "Necessário senha de administrador para separar todos os itens.",

        //../parent/statusPcp/view.ts
        apontarEmLote: "Apontar em lote",
        sucessoApontarEmLote: "As alterações de Status foram salvas com sucesso",

        //../parent/crud/separacao/list.ts
        dtInclusao: "Data Inclusão",
        dtAprov: "Data Aprovação",

        //../parent/crud/servico/list.ts
        atividade: "Atividade",
        aliq: "Alíquota",

        //../parent/crud/transportador/edit.ts
        transport: "Transportador",

        //../parent/crud/transportadora/edit.ts
        transporta: "Transportadora",

        //../parent/crud/unidademedida/edit.ts
        massa: "Massa",
        multiplicador: "Multiplicador",
        dividir: "Dividir",
        grama: "Grama (g)",
        exKg: "KG: Dividir por 1000. MG: Multiplicar por 1000.",
        mililitro: "Mililitro (ml)",
        exMl: "L: Dividir por 1000. Microlitro: Multiplicar por 1000",
        un: "Unidade (un)",
        cxUn: "Cx: Multiplicar por 12",
        ui: "Unidade Internacional (UI)",
        exUI: "Milhões UI: Multiplicar por 1000000",
        ufc: "Unidade de Formação de Colônias (UFC)",
        exUFC: "Milhões UFC: Multiplicar por 1000000",
        utr: "Unidade de Turbidez (UTR)",
        exUTR: "Milhões UTR: Multiplicar por 1000000",
        naoNecessiOpConversUI:
            "Não necessita de operador de conversão pré-parametrizado, o mesmo será oriundo do campo Fator UI do cadastro de Lote ou de Produto.",
        naoNecessiOpConversUFC:
            "Não necessita de operador de conversão pré-parametrizado, o mesmo será oriundo do campo Fator UFC do cadastro de Lote ou de Produto.",
        naoNecessiOpConversUTR:
            "Não necessita de operador de conversão pré-parametrizado, o mesmo será oriundo do campo Fator UTR do cadastro de Lote ou de Produto.",
        unMedidaPadrDe: "A unidade de medida padrão de ",
        eh: " é ",
        informeOpEValor: ". Informe o operador e o valor para converter. Ex: ",

        //../parent/crud/usuario/edit.ts
        padr: "Padrão",
        franquador: "Franqueador",
        franqueado: "Franqueado",
        atendente: "Atendente",
        prescritor: "Prescritor",
        adminEstoque: "Administração de Estoque",
        comercial: "Comercial",
        histAlterUsuario: "Histórico de alterações do Usuário",

        //../parent/crud/vendaContinuo/list.ts
        vendaContinuos: "Venda Continuos",
        paciente: "Paciente",
        vlrVendido: "Valor Vendido",
        vlrAtualizado: "Valor Atualizado",
        detVendaOrca: "Detalhes da Venda/Orçamento",
        adiarAviso: "Adiar Aviso",
        arqAviso: "Arquivar Aviso",
        orcarEnviarMsgWpp: "Orçar e enviar mensagem pelo Whatsapp",
        avisoFoiArqSucess: "Aviso foi arquivado com sucesso.",
        adiarAvisoCont: "Adiar Aviso de contínuo",
        adiar: "Adiar",
        qtdDiasAdiarMaiorZero: "Quantidade de dias para adiar deve ser maior que zero.",
        avisoFoiAdiadoSucess: "Aviso foi adiado com sucesso.",
        orcaCriadoSucess: "Orçamento criado com sucesso.",
        enviarOrca: "Enviar Orçamento",
        enviarMsgOrcaCliente: "Enviar mensagem de orçamento para o cliente",

        //../parent/dashboard/charts/pedidos.ts
        carregando: "Carregando",

        //../parent/dashboard/charts/previsaoRoyalties.ts
        previsao: "Previsão",
        taxaPublic: "Taxa Publicidade",

        //../parent/dashboard/evolucaoVendas.ts
        vendasDiario: "Vendas Aprovadas Hoje",
        somaVendasMensal: "Valor das Vendas deste Mês",
        qtdeVendasMensal: "Quantidade de Vendas deste Mês",
        somaVendasAnualAprovadas: "Valor das Vendas Aprovadas (Período)",
        qtdeVendasAnualAprovadas: "Quantidade de Vendas Aprovadas (Período)",
        somaVendasAnualRejeitadas: "Valor das Vendas Rejeitadas (Período)",
        qtdeVendasAnualRejeitadas: "Quantidade de Vendas Rejeitadas (Período)",
        qtdeAtendimentos: "Quantidade de Atendimentos",
        ticketMedio: "Ticket Médio",
        qtdeOrcamentosRejeitados: "Quantidade de Orçamentos Rejeitados em %",

        //../parent/dashboard/manipulacoes.ts
        somaPorFormaFarmaceutica: "Valor de Manipulações por Forma Farmacêutica",
        qtdePorFormaFarmaceutica: "Quantidade de Manipulações por Forma Farmacêutica",
        somaPorEspecie: "Valor de Manipulações por Espécie de Paciente",
        qtdePorEspecie: "Quantidade de Manipulações por Espécie de Paciente",

        //../parent/dashboard/totaisPorCategoria.ts
        vendasVendedorAnual: "Valor de Vendas por Vendedor",
        percVendasPrescritorMensal: "% de Vendas por Prescritor",
        percOrigemVendas: "% da Origem de Atendimento",

        //../parent/fichaTecnica/edit.ts
        ensaios: "Ensaios",
        especific: "Especificações",
        validado: "Validado",
        resultados: "Resultados",
        selecLoteAddResult: "Selecione o lote para adicionar resultado",
        impFichaTecnica: "Impressão de Ficha técnica",
        desejaImp: "Deseja imprimir o resultado dos ensaios?",
        nenhumLoteDisp: "Nenhum lote disponível",

        //../parent/fracionaEstoque/kitEmbalagem.ts
        outro: "Outro",
        naoSeraInclVenda: " não será incluido na venda.",
        naoHaLotes: "Não há lotes disponíveis do produto! ",
        naoHaQtd: "Não há quantidades disponíveis do produto no lote selecionado! ",
        desejaRemov: "Deseja remover ",
        erroAoRemov: "Erro ao remover item, tente novamente.",
        desejaAlter: "Deseja alterar ",
        regisAltSucess: "Registro foi alterado com sucesso.",

        //../parent/historico/vendaHistorico/list.ts
        vendaHist: "Venda Histórico",

        //../parent/home/fastaction/continuos/continuos.ts
        enviar: "Enviar",
        avisosContinuo: "Avisos de Contínuos",

        //../parent/home/fastaction/entregas/entregas.ts
        entregas: "Entregas",
        vendasEntrega: "Vendas com Entregas",
        marcarEntregue: "Marcar como entregue",
        codigoRastreio: "Código de Rastreio",
        desejaGerarCod: "Deseja gerar o código de rastreio?",
        temCertezaDesejaGerarCod: "Tem certeza que deseja gerar de novo o código de rastreio?",

        //../parent/home/fastaction/estoqueFracionamento/estoqueFracionamento.ts
        separacaoItensVenda: "Separação de itens da venda ",
        conclSeparacItensApagados: "Ao concluir separação, todos os itens serão separados. Deseja continuar?",

        //../parent/home/fastaction/expedicao/expedicao.ts
        infoEntrega: "Informações da Entrega",
        expedFinalizMesmoSemDados:
            "A expedição será finalizada mesmo que os dados de transporte não estejam preenchidos. Deseja continuar?",
        expedFoiFinaliz: "A expedição foi finalizada.",
        ocorrErroAoInclRegis: "Ocorreu um erro ao tentar incluir os registros, por favor tente novamente.",
        motivoObrigatorioEtapaPcp: "Motivo obrigatório ao pular etapa",
        vendasEmExped: "Vendas em Expedição",
        tentativasEntrega: "Tentativas de entrega",
        tresTentativasEntrega: "O cliente possui pelo menos uma venda com 3 tentativas de entrega",

        //../parent/home/fastaction/financeiro/financeiro.ts
        gerarReforco: "Gerar Reforço",
        gerarSangria: "Gerar Sangria",
        reforcoGeradSucess: "Reforço gerado com sucesso.",
        sangriaGeradSucess: "Sangria gerada com sucesso.",
        caixaFechado: "O caixa está fechado! Primeiro é necessário abrir o caixa para fazer a movimentação",

        //../parent/home/fastaction/manipulacao/list.ts
        visuManipu: "Visualizar Manipulação",
        confLoteEstqManipu: "Conferir Lote/Estoque Manipulação",
        printManipu: "Imprimir Manipulação",
        conclManipu: "Concluir Manipulação",
        conclManipuMsg: "As manipulações selecionadas foram concluídas com sucesso!",
        conclManipuErr: "Erro de processamento ao concluir manipulações",

        //../parent/home/fastaction/manipulacao/manipulacao.ts
        estqNaoVerif: "Estoque não verificado",
        estqQtdBaixaDiverg: "Estoque com quantidade de baixa divergênte",
        estqVerif: "Estoque verificado",
        estqAindaNaoVerif: "Estoque ainda não verificado",
        manipulacoes: "Manipulações",
        lotesEstqManipul: "Lotes/Estoque da Manipulação",
        modelImpressNaoEncont: "Modelo de Impressão não encontrado!",
        manipPossuiDiverg: "Manipulação possui divergências no seu estoque.",
        desejaConfLotes: " Deseja conferir os lotes?",

        //../parent/home/fastaction/orcamentos/orcamentos.ts
        dtValOrcaVenc: "Data de validade do Orçamento vencida",
        valOrcaEmDia: "Validade do Orçamento em dia",
        valOrcaMenos10DiasVenc: "Validade do orçamento com menos de 10 dias para vencer",
        vendas: "Vendas",
        orcamentos: "Orçamentos",
        opcIraArquivOrca:
            "Esta opção irá arquivar este orçamento e o mesmo só poderá ser acessado pelos filtros avançados de listagem de orçamentos.",
        revPreenchCampos: "Revisão de Preenchimento de Campos",
        informeMotivoRejeicaoCampos: "Informe o motivo da rejeição",
        enviarVendParaFrac: "Deseja enviar a venda para o Fracionamento?",
        vendaEmSeparFrac: "A venda encontra-se em separação pelo fracionamento.",
        codzin: " Código: ",
        masOcorrAlert: ", mas ocorreram alertas!",
        salvoSucess: "Salvo com sucesso",
        adiarOrca: "Adiar Orçamento",
        adiarDtValOrca: "Adiar data de validade do orçamento",
        orcaAdiadoSucess: "Orçamento foi adiado com sucesso.",
        rotuloManipu: "Rótulo de Manipulação",
        printRegistros: "Imprimir Registros",

        //../parent/home/fastaction/produtos/produtos.ts
        comAlertasEstq: "com alertas de estoque",
        comEstqBaixo: "com estoque baixo",
        semEstq: "sem estoque",
        proxDaDataVenc: "proximos da data de vencimento",
        prods: "Produtos ",

        //../parent/home/fastaction/vendas/list.ts
        cancelarVenda: "Cancelar Venda",
        visuVenda: "Visualizar Venda",
        printVenda: "Imprimir Venda",
        faturarVenda: "Faturar Venda",

        //../parent/home/fastaction/vendas/vendas.ts
        vendaSemPgtosRealiz: "Venda sem pagamentos realizados",
        envioNFS: "Envio da Nota Fiscal de Serviço",
        envioCFPend: "Envio do Cupom Fiscal Pendente",
        eEnvioCFPends: " e Envio do Cupom Fiscal Pendentes",
        pend: " Pendente",
        nfEnvAgRetornoPref: "Nota Fiscal enviada, aguardando retorno da prefeitura",
        financCntsReceber: "Financeiro - Contas a Receber",
        novoPgto: "Novo Pagamento",
        pgtos: "Pagamentos",
        rotulo: " Rótulo",

        //../parent/importacaonfe/edit.ts
        erroDadosXml: "Erro de dados do XML",
        regisNaoPodeSalvo: "Registro não pode ser salvo.",
        printRotulos: "Imprimir Rótulos",
        itensNaoAssociados: "Todos os itens da nota devem estar associados a um produto.",
        itensNaoConferidos: "Todos os itens da nota devem estar conferidos.",
        imprimirFichas: "Deseja imprimir as fichas técnica dos lotes importados?",

        //../parent/importacaonfe/produtos.ts
        necessAssociarProds: "É necessário associar todos os produtos para a importação.",
        necessAssociarLotes: "É necessário associar todos lotes aos produtos para a importação.",
        necessAssociarUmProd: "É necessário associar um produto para a importação",
        necessAssociarUmLote: "É necessário associal um lote ao produto para a importação",
        verifics: "Verificações",

        //../parent/manipulacao/detalhesitem.ts
        tipo: "Tipo",
        valorCusto: "Valor Custo",
        valorVenda: "Valor Venda",
        diferencaCustoVenda: "Diferença Custo/Venda",

        //../parent/manipulacao/edit.ts
        qtdDeveMaiorZero: "Quantidade deve ser maior que zero.",
        naoEncontrAtivosManipu: "Não foram encontrados Ativos nesta manipulação,",
        naoForamEncontr: "Não foram encontradas ",
        embalagens: "Embalagens",
        capsulas: "Cápsulas",
        paraEstaManip: " para esta manipulação,",
        desejaConsolid: "Deseja consolidar antes de prosseguir?",
        naoEncontrAtivosManipul: "Não foram encontrados Ativos nesta manipulação!",
        identAltVolumManipu:
            "O sistema identificou uma alteração no volume total desta manipulação e irá consolidar antes de prosseguir!",
        e: " e ",
        osProds: "Os produtos <b> ",
        naoPossVlr: "</b> não possuem valor definido.",
        naoPossuiVlr: "</b> não possui valor definido.",
        desejProssegAssim: "Deseja prosseguir mesmo assim?",
        regSalvoMasAlert: "Registro foi salvo com sucesso, mas ocorreram alertas!",
        manipulacaoGeradaMasAlert: "Manipulação foi gerada com sucesso, mas ocorreram alertas!",
        regSalvoSucess: "Registro foi salvo com sucesso.",
        qtdMinPermitida: "Quantidade Mínima permitida para esta Forma Farmacêutica é ",
        qtdMaxPermitida: "Quantidade Máxima permitida para esta Forma Farmacêutica é ",
        carregandoAtivos: "Carregando Ativos",
        carregandoEmbal: "Carregando Embalagens",
        carregandoCaps: "Carregando Cápsulas",
        selecModelImpress: "Selecione um Modelo de Impressão",
        jaExistCapsula: "Já existe capsula nesta manipulação",
        jaExistEmbal: "Já existe embalagem nesta manipulação",
        detalhCustosManipu: "Detalhes de Custos da Manipulação",
        consolidouSucess: "Consolidou com sucesso!",
        consolidouSucessEmbalagem: "Consolidou com sucesso! Mas é necessário adicionar embalagem manualmente",
        consolidNaoSucess: "Consolidação não finalizada com sucesso!",
        validacaoVolume:
            "A Soma do volume dos ativos é maior que o volume total da manipulação. Tente consolidar novamente ou faça os ajustes necessários para evitar erros!",
        sisteVerifKit: "O sistema verificou que o kit de embalagem <b>",
        sisteVerifKitt: "O sistema verificou que o kit de embalagem ",
        maiorCompatibEmbalagem:
            "</b> tem maior compatibilidade com esta forma do que o seu kit de embalagem atual (<b>",
        maiorCompatibEmbalagemm: " tem maior compatibilidade com esta forma do que o seu kit de embalagem atual (",
        maiorCompatibForma: "</b> tem maior compatibilidade com esta forma farmacêutica.",
        maiorCompatibFormaa: " tem maior compatibilidade com esta forma farmacêutica.",
        desejSubstKit: "Deseja que o sistema faça a substituição do kit de embalagem?",
        sisteVerifCapsula: "O sistema verificou que a cápsula <b>",
        sisteVerifCapsulaa: "O sistema verificou que a cápsula ",
        volMaisAdeqCaps: "</b> tem o volume mais adequado para esta forma do que a cápsula atual (<b>",
        volMaisAdeqCapss: " tem o volume mais adequado para esta forma do que a cápsula atual (",
        volMaisAdeqForma: "</b> tem o volume mais adequado para esta forma farmacêutica.",
        volMaisAdeqFormaa: " tem o volume mais adequado para esta forma farmacêutica.",
        desejSubstCaps: "Deseja que o sistema faça a substituição da cápsula?",
        sisteVerifMolde: "O sistema verificou que o molde <b>",
        sisteVerifMoldee: "O sistema verificou que o molde ",
        volMaisAdeqMolde: "</b> tem o volume mais adequado para esta forma do que o molde atual (<b>",
        volMaisAdeqMoldee: "tem o volume mais adequado para esta forma do que o molde atual (",
        desejSubstMolde: "Deseja que o sistema faça a substituição do molde?",
        altManualVolDose: "Alteração manual do Volume da Dose <br/>",
        aoAltSistDeixaCalc:
            "Ao alterar manualmente o volume da dose o sistema deixa de calcular automaticamente conforme parametrização de concentração máxima do ativo. <br/>",
        desejProssegAltManual: "<br>Deseja prosseguir com a alteração manual?</br>",
        produtoInativoFormaFarmaceutica:
            "Produto {produto} vinculado a forma farmacêutica está inativo e não será adicionado a manipulação!",
        produtoInativoPreVenda:
            "Produto {produto} vinculado a pré venda está inativo e não será adicionado a manipulação!",
        produtoInativoMolde: "Produto {produto} vinculado ao molde está inativo e não será adicionado a manipulação!",
        necessarioConsolidar: "Necessário consolidar para atualizar valores!",
        desejRemoverAssocAnterior: "Deseja remover as associações da Forma Farmacêutica anterior?",
        valorMinimoFormaFarmAtingido:
            "Valor total é menor que valor mínimo da forma farmacêutica, não será permitido valores inferiores ao valor mínimo cadastrado",

        //../parent/manipulacao/list.ts
        qtdTotal: "Qtd.Total",
        qtdTotalPesado: "Qtde de Pesagem",
        naoHaEstqDisp: "Não há estoque suficiente para atender! Deseja continuar?",
        sisteIdentifProd: "O sistema identificou um produto ",
        paraEssaQtdDose: " para essa quantidade de dose! Deseja substituir pelo ",
        fatorUI: "Fator UI!",
        naoHaFtrUI: "Não há fator UI informado neste produto!",
        qtdCapsMultQtdForma:
            "Quantidade de Capsulas multiplicado pelo valor do campo 'Quantidade' da Forma Farmacêutica.",
        qtdPercentVolForma: "Quantidade Percentual sobre o Volume total da Forma Farmacêutica.",
        qtdSuficComplForma: "Quantidade suficiente para completar a Forma Farmacêutica",
        qtdTotalRepresMultQtd:
            "Quantidade total representa a multiplicação da quantidade dose pelo Volume total dividido pela Base Dose.",
        semCalcEspecifQtdIgualQtd: "Sem cálculo específico, quantidade total igual a quantidade",
        //Prestar atenção aqui
        obsPossuiFtrCorrec: " Obs. Possui fator de correção igual a ",
        eP: " e p",
        obsP: " Obs. P",
        ossuiFtrIgual: "ossui fator UI igual a ",
        //deu

        //../parent/manipulacao/item.ts
        ocorreuTrocaManual: "Ocorreu uma troca de dose manual, verifique a necessidade de alteração do volume também",
        trocaDose: "Troca de dose manual",

        //../parent/manipulacao/loteItem.ts
        houveAltProdsManiSalv: "Houve alteração de produtos da manipulação, salve antes de prosseguir!",
        loteJaInfo: "Este Lote já foi informado para este produto!",
        qtdBaixaDeveMenorAtual: "Quantidade para Baixa deve ser menor ou igual a quantidade atual no estoque!",
        aoConfAltAlteradasNoEstq:
            "Ao confirmar, as alterações serão alteradas diretamente no estoque. Deseja prosseguir?",

        //../parent/nfe/edit.ts
        entrd: "-Entrada",
        said: "-Saída",
        nfeNormal: "1-NF-e Normal",
        pgtoAVist: "0-Pagamento à Vista",
        pgtoPrazo: "1-Pagamento à Prazo",
        outros: "2-Outros",
        nfe55: "55-NF-e",
        nfc: "65-NFC-e",
        normal0: "0-Normal",
        consumFinal: "1-Consumidor Final",
        naoSeAplica: "0-Não se aplica",
        opPresenc: "1-Operação presencial",
        opNaoPresencInternet: "2-Operação não presencial, pela Internet",
        opNaoPresencTele: "3-Operação não presencial, Teleatendimento",
        nfceOpEntregaDomic: "4-NFC-e em operação com entrega a domicílio",
        opNaoPresencOutros: "9-Operação não presencial, outros",
        nfee: "NF-e",
        nfeEnvSucess: "NF-e enviada com sucesso.",

        //../parent/notafiscalentrada/edit.ts
        descNaoPodeMaiorVlr: "Desconto não pode ser maior que o valor total",

        //../parent/notafiscalsaida/edit.ts
        erroEmiss: "1 - Erro de Emissão",
        servNaoPrest: "2 - Serviço Não Prestado",
        erroAss: "3 - Erro de Assinatura",
        duplicNota: "4 - Duplicidade de Nota",
        servic: "Serviço",
        cupomFisc: "Cupom Fiscal",
        precUnit: "Preço Unitário",
        nota: "Nota",
        cupom: "Cupom",
        //prestar atenção
        a: "a",
        o: "o",
        atualiz: "Atualizando ",
        fiscal: " Fiscal...",
        enviandReqCancel: "Enviando requisição para Cancelamento de ",
        enviandConsultPara: "Enviando Consulta para ",
        fiscalEletr: " Fiscal eletrônica...",
        confirmad: "Confirmada!",
        consultDe: "Consulta de ",
        fiscalRetornSucess: " Fiscal retornou com sucesso, ",
        fiscalAprov: " Fiscal foi aprovad",
        retorn: "Retorno!",
        requisDeAlter: "Requisição de alteração de ",
        fiscalEnv: " Fiscal enviad",
        comSucess: "com sucesso.",
        requisDeCancel: "Requisição de cancelamento de ",
        prossegRotinEnviaRequisCancelNFEscolh:
            "Ao prosseguir, a rotina enviará uma requisição de cancelamento de nota fiscal. Para prosseguir escolha um motivo de cancelamento.",
        motivCancel: "Motivo do Cancelamento:",
        obgInfoMotivoCancel: "Obrigatório informar o motivo do cancelamento.",
        prossegRotinEnviaRequisCancelNFInsira:
            "Ao prosseguir, a rotina enviara uma requisição de cancelamento de nota fiscal. Para prosseguir insira um motivo de cancelamento.",
        motivMtLongo: "Motivo muito longo, use no máximo 1000 caracteres.",
        motivMtCurto: "Motivo muito curto, use no mínimo 15 caracteres para descrição.",
        prossegAlterSeraoAtualizNF:
            "Ao prosseguir, alterações efetuadas na venda serão atualizadas na Nota Fiscal enviada. Deseja prosseguir?",

        //../parent/notafiscalsaida/list.ts
        ambas: "Ambas",

        //../parent/printModel/condicao.ts
        dadosPrinc: "Dados Principais",

        //../parent/printModel/edit.ts
        normal: "Normal",
        repeticao: "Repetição",
        vlrCampo: "Valor do Campo",
        complementVlr: "Complemento do Valor",
        posicCompl: "Posição do Complemento: ",
        anteriorVlr: "Anterior ao Valor",
        posteriorVlr: "Posterior ao Valor",
        corText: "Cor do Texto: ",
        corFundText: "Cor do fundo do Texto: ",
        altLabelExib: "Alterar Label de exibição",
        altLabelInclInfoPerson: "Alterar Label para incluir informações personalizadas na impressão",
        nomeCampo: "Nome do Campo:",
        criarNovoLabelExib: "Criar novo Label de exibição",
        criarNovoLabelInclInfos: "Criar novo Label para incluir informações personalizadas na impressão",

        //../parent/printModel/sessao.ts
        modelImpressSalvoSucess: "Modelo de Impressão salvo com sucesso.",
        novaSessaoModel: "Nova Sessão de Modelo",
        condicaoModel: "Condição de Modelo",
        modelAlterSucess: "Modelo alterado com sucesso.",
        copiaDe: "Cópia de ",
        modelImpressPara: "Modelo de Impressão para ",

        //../parent/produtoLote/edit.ts
        dataMovimentacao: "Data Movimentação",
        valorUnitario: "Valor Unitário",
        tipoMovimentacao: "Tipo Movimentação",
        quantidadeEfetivaEstoque: "Quantidade efetiva em estoque",
        usuario: "Usuário",
        statusAnterior: "Status Anterior",
        statusAtualizado: "Atualizado Para",
        sucessArquivo: "O upload do arquivo foi feito com sucesso!",

        //../parent/crud/pontuacaoManual/list.ts
        alertaTansmIrrever:
            "O sistema irá fazer a transmissão da pontuação Dotz, esse processo é IRREVERSIVEL. Deseja prosseguir?",
        franquiaNaoUsaDotz: "Esta franquia não está configurada para utilizar Dotz.",
        franquiaConfigOutraFranquia:
            "Esta franquia está configurada para enviar junto com outra franquia e não é possível gerar pontuação manual",
        franquiaNaoEnviaManual: "Esta franquia não está configurada para enviar Dotz Manual.",
        pontuacaoEnviada: "Pontuação Dotz enviada!",

        //../parent/sessaoCaixa/aberturaCaixa.ts
        abertCaixa: "Abertura de Caixa!",
        abertCaixaRegSucess: "Abertura de Caixa registrada com sucesso.",

        //../parent/sessaoCaixa/fechamentoCaixa.ts
        contagem: "Contagem",
        validacao: "Validação",
        conclusa: "Conclusão",
        contagCaixa: "Contagem no Caixa",
        diferencs: "Diferenças",
        clientForneced: "Cliente/Fornecedor",
        dtHra: "Data/Hora",
        gerarReforcDif: "Gerar Reforço da Diferença",
        gerarSangrDif: "Gerar Sangria da Diferença",
        devidDifFechCaixa: " devido a diferença no fechamento de caixa",
        ajustDif: "Ajustar Diferença",
        justif: "Justificar",
        vlrCorretoJustif: "Valor Correto e/ou Justificado",
        justifiq: "Justifique!",
        justifDifVlr: "Justificar diferenças de valor",
        justifDifEntrCalcCaixa: "Justificar diferença entre o cálculo do sistema e a soma do caixa",
        justificativDifVlres: "Justificativa da diferença de valores",
        justifMtLonga: "Justificativa muito longa, use no máximo 1000 caracteres.",
        justifMtCurta: "Justificativa muito curta, use no mínimo 15 caracteres para descrição.",
        fechament: "Fechamento!",
        fechamentSalvoSucess: "Fechamento foi salvo com sucesso.",
        caixJaFechad: "O caixa já está fechado!",

        //../parent/sessaoCaixa/list.ts
        sucessoReabertura: "Caixa Reaberto com sucesso",

        //../parent/sessaoCaixa/reforco.ts
        reforcSaldoPDV: "Reforço de saldo para PDV:",
        vlrTotalCalcNaoMenorSaldo: "Valor total calculado não pode ser menor ou igual ao saldo atual.",

        //../parent/sessaoCaixa/sangria.ts
        sangriaPDV: "Sangria de PDV ",
        sldCalcNaoMenorZero: "Saldo calculado não pode ser menor que zero.",
        sangriaNaoMaiorSaldPDV: "Sangria não pode ser maior que o saldo atual do PDV.",

        //../parent/sessaoCaixa/listMovimentacoesPendente.ts
        pagamentoReprocessado: "Pagamentos solicitados foram reprocessados com sucesso.",

        //../parent/sngpc/list.ts
        visuProds: "Visualizar Produtos",
        enviarXML: "Enviar XML",
        remover: "Remover",
        desejExcluirr: "Deseja excluir?",
        regEnviadoSucess: "Registro foi enviado com sucesso.",

        //../parent/topmenu/topmenu.ts
        conexaoInternet: "Concexão da Internet",
        conexaoRestSucess: "A sua Conexão foi restabelecida com sucesso!",
        aparentInternetInterr: "Aparentemente sua Conexão com internet foi interrompida",
        conexaoInternetPerd: "Conexão da Internet Perdida",

        //../parent/venda/cancelamento.ts
        valorTotal: "Valor total",
        quantidadeADevolver: "Quantidade a devolver",
        quantidadeDevolvida: "Quantidade devolvida",

        //../parent/venda/edit.ts
        criandOrca: "Criando Orçamento...",
        orcaNovo: "Orçamento Novo **",
        copiand: "Copiando...",
        concl: "Concluída",
        faturad: "Faturada",
        cancelad: "Cancelada",
        novo: "Novo **",
        orcaCod: "Orçamento cód:",

        //../parent/venda/editSingle.ts
        revisPreenchCampos: "Revisão de Preenchimento de Campos",
        acresc: "Acréscimo",
        continu: "Contínuo",
        fiscl: "Fiscal",
        dtReceb: "Dt. Recebimento",
        formPgto: "Forma Pgto",
        sttsNota: "Status da Nota",
        tipoNota: "Tipo da Nota",
        model: "Modelo",
        visuPDF: "Visualizar PDF",
        cpfAtingPtsPremio: "O CPF atingiu a pontuação necessária para ganhar um prêmio!",
        naoReceber: "Não Receber",
        abrirPDF: "Abrir PDF",
        abrirPDFSimplificado: "Abrir PDF Simplificado",
        detalhNota: "Detalhes da Nota",
        aoConclNaoPossivEmit: "Ao concluir não será possível emitir Nota Fiscal Eletrônica",
        desejSistemEfetConcl: "Deseja que o sistema efetue a conclusão da venda?",
        enviandNF: "Enviando Notas Fiscais...",
        enviado: "Enviado!",
        foiEnviadSucess: " foi enviado com sucesso.",
        vendCod: " - Venda Cód: ",
        nfReqSttsEnviad: "Nota Fiscal e requisição de status da nota foram enviados.",
        copia: "Cópia ",
        doOrca: "do Orçamento",
        daVend: "da Venda",
        emailEnviSucess: "E-mail enviado com sucesso.",
        obgSelecEndeEntr: "Obrigatório selecionar o endereço de entrega",
        editDetalhNF: "Editar detalhes NF-e",
        desejEnviNotifPronto: "Deseja enviar notificação que o orçamento está pronto?",
        orcaConcl: "Orçamento concluído",
        orcaReqFoiConclAcessLink: "Orçamento requisitado foi concluído. Acesse o link acima para aprovar o mesmo.",
        desejEnviarVendaPFracion: "Deseja enviar a venda para o Fracionamento?",
        acaoIraFinalizVenda: "Esta ação irá finalizar a venda, deseja continuar?",
        concluido: "Concluído!",
        vendaFoiFinaliz: "A venda foi finalizada.",
        msgCpfNaoInformado: "CPF não informado no cadastro do cliente.",
        vendaReplicadaComManipulacao: "Venda Replicada com Manipulações",
        vendaReplicadaComManipulacaoConsolidar:
            "Houve a replicação de manipulações nessa venda, por tanto é necessário entrar em cada manipulação e fazer a consolidação para o sistema atualizar as informações corretamente",
        vendaReplicadaComManipulacaoSemConsolidar: "Consolide todos os itens de manipulação para poder continuar",
        observacao: "Observação",
        altVendaFaturada: "Alteração de venda faturada só pode ser feita com aprovação de um supervisor",
        temCertezaSeDeseja: "Tem certeza que deseja excluir o item da venda?",
        LinkPagamento: "Link de pagamento",

        //../parent/venda/enderecoEntrega.ts
        criandNovoEndeEntrg: "Criando novo endereço de entrega",
        editEndeEntreg: "Editando endereço de entrega",
        jaExistEnderMarcPdr:
            "Já existe um endereço marcado como endereço padrão. Deseja manter este endereço como novo endereço padrão?",
        naoInfoLoteProd: "Não foi informado um lote do produto! Deseja continuar?",
        fracionaEstq: "Francionamento de Estoque",

        //../parent/venda/itemwrapper.ts
        estoqueNaoInformado: "Estoque não informado, selecione o estoque para continuar!",

        //../parent/venda/linkPagamento.ts
        desejaGerarNovoLink:
            "Deseja gerar novo link? Ao fazer isso o link atual será inativado, não sendo possível efetuar o pagamento através dele, somente a partir do novo!",
        linkCopiado: "Link copiado para a área de transferência!",
        linkPagamentoAtualizado: "Link de pagamento atualizado!",

        //../parent/venda/list.ts
        repEstaVenda: "Repetir esta venda",
        selecioneUmaVenda: "Selecione ao menos uma venda",

        //../parent/venda/listExpedicao.ts
        detalhExpedic: "Detalhes da expedição",
        finalizExpedic: "Finalizar expedição",
        gridCodigo: "Código",
        gridCliente: "Cliente",

        //../parent/venda/listOrcamento.ts
        orcaSelecAprovSucess: "Orçamentos selecionados aprovados com sucesso.",
        visuAltOrca: "Visualizar/Alterar Orçamento",
        adiarValOrca: "Adiar validade deste Orçamento",
        arqRejOrca: "Arquivar/Rejeitar Orçamento",
        arqSucesso: "Arquivado com sucesso.",

        //../parent/venda/uploadReceita.ts
        formatInvalid:
            "Seu arquivo está em um formato inválido. As extensões aceitas são: (.jpg), (.png), (.pdf), (.doc), (.txt)",

        //../parent/venda/vendaEstoque.ts
        prodPossuiContrEstqAtiv: "O produto possui controle de estoque ativo e não será incluido por falta de lotes.",
        naoQtdDispNoLote: "Não há quantidade disponível no Lote de ",

        //../parent/venda/financeiro/financeiro.ts
        addParcela: "Adicionar Parcela",
        parcelamento: "Parcelamento",
        preFaturamento: "Pré-Faturamento",

        //../parent/printModel/list.ts
        gridColumnDescricao: "Descrição",
        gridColumnPadrao: "Padrão",

        //../parent/crud/visitador/edit.ts
        prescritorJaAssociado: "Este prescritor já tem um visitador associado.",
        prescritorJaInserido: "Este prescritor já foi inserido.",
        prescritorNaoSelecionadoo: "Nenhum prescritor foi selecionado.",

        //../parent/dinamizacao/view.ts
        necessarioSelecionarLote: "Necessário selecionar um lote do produto",
        necessarioSelecionarKit: "Necessário selecionar um kit de embalagem",
        loteSucesso: "Lote gerado com sucesso",

        //../parent/crud/controlereclamacao/list.ts
        responsavel: "Responsavel",
        reclamacao: "Reclamação",
        acao: "Ação",
        setor: "Setor",
        justificativa: "Justificativa",

        //..parent/estoqueMinimo
        sucessoEstMin: "Estoque mínimo e máximo atualizado com sucesso!",
    },

    Components: {
        parent: {
            atendimento: {
                view_vue_html: {
                    selecContatoComecar:
                        "Selecione um dos contatos para começar ou então<br>inicie uma conversa com um novo contato",
                    nenhumAtendEncontrado: "Nenhum atendimento encontrado...",
                    meus: "Meus",
                    meusTitle: "Atendimentos vinculados ao seu usuário",
                    emAberto: "Em Aberto",
                    emAbertoTitle: "Atendimentos sem nenhum atendente alocado",
                    encerrados: "Encerrados",
                    encerradosTitle: "Atendimentos encerrados",
                    emAtendimento: "Em Atendimento",
                    emAtendimentoTitle: "Em atendimento alocados para os demais atendentes",
                    pegarAtendimento: "Alocar atendimento",
                    encerrarAtendimento: "Encerrar atendimento",
                    anexar: "Anexar",
                    dadosContato: "Dados do Contato",
                    historicoAtend: "Histórico de atendimento",
                },
                historicoAtendimento: {
                    semAtendimento: "Esse contato não possui nenhum atendimento encerrado!",
                },
                mensagemOptions: {
                    responder: "Responder",
                },
            },

            atualizacaoValores: {
                percentualAtualizacao: "Percentual de Atualização (%)",
                atualizar: "Atualizar",
                formaFarmaceutica: {
                    atualizacaoValoresProdutos: "Atualização de valores de Forma Farmacêutica",
                    encontrados: "Formas Farmacêuticas encontrados",
                    percentualValorMinimo: "Percentual Valor Mínimo (%)",
                    percentualValorAdicional: "Percentual Valor Adicional (%)",
                },
                produto: {
                    atualizacaoValoresProdutos: "Atualização de valores do Produto",
                    encontrados: "Produtos encontrados",
                    nenhumSelecionado: "Nenhum registro foi selecionado.",
                },
            },

            duplicacoes: {
                list_vue_html: {
                    clientesDuplicados: "Clientes Duplicados",
                    prescritoresDuplicados: "Prescritores Duplicados",
                    registros: "Registros Duplicados",
                    resolver: "Resolver",
                    unificar: "Unificar",
                    prescritorOrigem: "Prescritor de origem",
                    prescritorDestino: "Prescritor de destino",
                    clienteOrigem: "Cliente de origem",
                    clienteDestino: "Cliente de destino",
                },

                edit_vue_html: {
                    edicaoCliente: "Edição do Cliente",
                    edicaoPrescritor: "Edição do Prescritor",
                    manter: "Manter",
                },
            },

            crud: {
                incluir: "Incluir",
                alterar: "Alterar",
                descricao: "Descrição",
                ativo: "Ativo",
                salvar: "Salvar",
                limpar: "Limpar",
                cancelar: "Cancelar",
                voltar: "Voltar",
                fechar: "Fechar",
                imprimir: "Imprimir",
                replicar: "Replicar",
                copiar: "Copiar",
                excluir: "Excluir",
                download: "Baixar arquivo",
                gerarArquivo: "Gerar Arquivo",
                adicionarTodos: "Adicionar Todos",

                filtrar: "Filtrar",
                filtroAvancado: "Filtro Avançado",

                listInputs: {
                    acao: "Ação",
                    adicionar: "Adicionar",
                    remover: "Remover",
                },

                alterarsenha: {
                    alterarsenha_vue_html: {
                        altSenha: "Alterar Senha",
                        novaSenha: "Nova Senha",
                        confSenha: "Confirmar Senha",
                    },
                },

                balancoCompleto: {
                    view_vue_html: {
                        balancoCompletoGeracao: "Balanço Completo",
                        ano: "Ano",
                        tipoPeriodo: "Tipo de Período",
                    },
                },

                mapaSiproquim: {
                    view_vue_html: {
                        mapaSiproquim: "Mapa SIPROQUIM",
                    },
                },

                banco: {
                    edit_vue_html: {
                        bancoEdit: "Banco - Edição",
                        nome: "Nome",
                        codigo: "Código",
                        utilizCodFEBRABAN: "Utilize o código fornecido pela FEBRABAN",
                        ativo: "Ativo",
                    },

                    list_vue_html: {
                        bancos: "Bancos",
                        cadastrados: "Cadastrados",
                    },
                },

                controleReclamacao: {
                    edit_vue_html: {
                        controleReclamacaoEdit: "Controle Reclamação - Edição",
                        codVenda: "Código da Venda",
                        cliente: "Cliente",
                        vendedor: "Vendedor",
                        responsavel: "Responsável",
                        dataReclamacao: "Data reclamação",
                        reclamacao: "Reclamação",
                        acao: "Ação",
                        status: "Status",
                        categoriaReclamacao: "Categoria da reclamação",
                        justificativaReclamacao: "Justificativa da reclamação",
                    },

                    list_vue_html: {
                        controleReclamacao: "Controle de Reclamações",
                        reclamacoes: "Reclamações",
                        cadastradas: "Cadastradas",
                    },
                },

                categoriaControleReclamacao: {
                    edit_vue_html: {
                        categoriaControleReclamacaoEdit: "Categoria de controle de reclamação - Edição",
                        descricao: "Descrição",
                    },

                    list_vue_html: {
                        categoriaControleReclamacao: "Categorias",
                        categoriasList: "Categorias",
                        categorias: "Categorias",
                        cadastradas: "Cadastradas",
                    },
                },

                justificativaControleReclamacao: {
                    edit_vue_html: {
                        justificativaControleReclamacaoEdit: "Justificativa da reclamação - Edição",
                        descricao: "Descrição",
                    },

                    list_vue_html: {
                        justificativaControleReclamacao: "Justificativas",
                        justificativasList: "Justificativas",
                        justificativas: "Justificativas",
                        cadastradas: "Registradas",
                    },
                },

                bandeira: {
                    edit_vue_html: {
                        bandeiraEdit: "Bandeira - Edição",
                        descricao: "Descrição",
                        ativo: "Ativo",
                    },

                    list_vue_html: {
                        bandeiras: "Bandeiras",
                        cadastradas: "Cadastradas",
                    },
                },

                beneficio: {
                    edit_vue_html: {
                        beneficiosEdit: "Benefícios - Edição",
                        descricao: "Descrição",
                        descontos: "Descontos",
                        inicioFaixa: "Início Faixa",
                        fimFaixa: "Fim Faixa",
                        valor: "Valor",
                        comissao: "Comissão",
                    },

                    list_vue_html: {
                        beneficio: "Benefício",
                        beneficios: "Benefícios",
                        cadastrados: "Cadastrados",
                    },
                },

                campanha: {
                    list_vue_html: {
                        campanhas: "Campanhas",
                        cadastradas: "Cadastradas",
                    },

                    edit_vue_html: {
                        campanhaEdit: "Campanha - Edição",
                        descricao: "Descrição",
                        dataInicio: "Data Inicio",
                        dataFim: "Data Fim",
                        faixas: "Faixas",
                        recompensas: "Recompensas",
                        grupoProduto: "Grupo de Produtos",
                        valorInicial: "Valor Inicial",
                        valorPorPonto: "Valor por Ponto",
                        pontos: "Pontos",
                        pontuar: "Pontuar",
                        valorInicialFaixaGrid: "Valor Inicial",
                        valorFinalFaixaGrid: "Valor Final",
                        faixaSobreposta: "O início dessa faixa não pode sobrepor o valor final de outra faixa.",
                        faixasCampanhaValida: "Para a campanha ser válida, é necessário cadastrar ao menos uma faixa.",
                        brinde: "Usa recompensa",
                        multiplicador: "Multiplicador",
                        valorUtilizadoPntsRecebidos: "Valor em Reais por ponto recebido",
                        padrao: "Padrão",
                    },
                },

                capacidadeProducao: {
                    edit_vue_html: {
                        capacidadeProducaoEdit: "Capacidade de Produção - Edição",
                        descricao: "Descrição",
                        horario: "Horário",
                        horarioFinalDeSemana: "Hor.Fin.Semana",
                        capacidadeSegunda: "Cap. Segunda",
                        capacidadeTerca: "Cap. Terça",
                        capacidadeQuarta: "Cap. Quarta",
                        capacidadeQuinta: "Cap. Quinta",
                        capacidadeSexta: "Cap. Sexta",
                        capacidadeSabado: "Cap. Sabado",
                        capacidadeDomingo: "Cap. Domingo",
                    },
                },

                capsula: {
                    edit_vue_html: {
                        capsulaEdit: "Cápsula - Edição",
                        descricao: "Descrição",
                        nro: "Número",
                        volumeInternoml: "Volume Interno (ml)",
                        volumeTotalml: "Volume Total (ml)",
                        pesog: "Peso (g)",
                        tipoCapsAtivo: "Tipo de cápsula ativo",
                        capsulas: "Cápsulas",
                        capsulasGastrorresistente: "Cápsulas Gastrorresistente",
                    },

                    list_vue_html: {
                        capsulas: "Cápsulas",
                        cadastradas: "Cadastradas",
                    },
                },

                cest: {
                    edit_vue_html: {
                        cestEdit: "CEST - Edição",
                        descricao: "Descrição",
                        codigo: "Código",
                    },

                    list_vue_html: {
                        cests: "CESTs",
                        ncms: "NCMs",
                        cadastrados: "Cadastrados",
                    },
                },

                farmacopeia: {
                    edit_vue_html: {
                        farmacopeiaEdit: "Farmacopéia - Edição",
                        codigo: "Código",
                        descricao: "Descrição",
                    },

                    list_vue_html: {
                        farmacopeia: "Farmacopeia",
                        farmacopeias: "Farmacopeias",
                        cadastradas: "Cadastradas",
                    },
                },

                cliente: {
                    edit_vue_html: {
                        clienteEdit: "Cliente - Edição",
                        dadosGerais: "Dados Gerais",
                        tipo: "Tipo",
                        cpf: "CPF",
                        rg: "RG",
                        cnpj: "CNPJ",
                        ie: "Insc. Estadual",
                        contribuinteEstadual: "Contribuinte Estadual",
                        documentoEstrangeiro: "Documento estrangeiro",
                        exterior: "Exterior",
                        clientIsentoEmBranco: "Para clientes isentos deixe em branco",
                        nome: "Nome",
                        MASCULINO: "Nome Fantasia",
                        nomeRotulo: "Nome Rótulo",
                        nomeQueRotulo: "Nome que será utilizado no rótulo para identificação do cliente",
                        razaoSocial: "Razão Social",
                        im: "Insc. Municipal",
                        dataNasc: "Data Nascimento",
                        genero: "Gênero",
                        orgaoExp: "Órgão Expedidor",
                        ufExp: "UF Expedidor",
                        obs: "Observação",
                        localizacao: "Localização",
                        cep: "CEP",
                        estado: "Estado",
                        cidade: "Cidade",
                        endereco: "Endereço",
                        nro: "Número",
                        bairro: "Bairro",
                        complemento: "Complemento",
                        enderecoEntrega: "Endereço de Entrega",
                        usarMesmoEnde: "Usar o mesmo endereço",
                        contato: "Contato",
                        telefone: "Telefone",
                        celular: "Celular",
                        pacientes: "Pacientes",
                        cpfJACadastradoOutrocliente: "Cpf já cadastrado para outro cliente!",
                        ativo: "Ativo",
                        emailsParaNotaFiscal: "Email(s) para nota fiscal",
                        email: "E-mail",
                    },

                    enderecoEntregaEdit_vue_html: {
                        cep: "CEP",
                        estado: "Estado",
                        cidade: "Cidade",
                        endereco: "Endereço",
                        nro: "Número",
                        bairro: "Bairro",
                        complemento: "Complemento",
                        endecoPdr: "Endereço Padrão",
                    },

                    list_vue_html: {
                        clientes: "Clientes",
                        cadastrados: "Cadastrados",
                        visuCliente: "Visualizar Cliente",
                    },

                    ver_vue_html: {
                        dadosGerais: "Dados Gerais",
                        tipoPessoa: "Tipo Pessoa:",
                        cpf: "CPF:",
                        rg: "RG:",
                        nome: "Nome:",
                        dtNasc: "Data de Nascimento:",
                        genero: "Genero:",
                        orgaoExp: "Orgão Expedidor:",
                        ufExp: "UF Expedidor:",
                        obs: "Observação:",
                        localiz: "Localização",
                        cep: "CEP:",
                        estado: "Estado:",
                        cidade: "Cidade:",
                        endereco: "Endereço:",
                        nro: "Número:",
                        bairro: "Bairro:",
                        complemt: "Complemento:",
                        contato: "Contato",
                        tel: "Telefone:",
                        cel: "Celular:",
                        pacientes: "Pacientes",
                        paciente: "Paciente:",
                        nomeEspec: "Nome/Especie:",
                        documentoEstrangeiro: "Documento de estrangeiro:",
                    },
                },

                clinicas: {
                    list_vue_html: {
                        titulo: "Clínicas",
                        cadastradas: "Cadastradas",
                    },

                    edit_vue_html: {
                        configFranquia: "Cadastro de Clínicas Parceiras",
                        prescritorOutraClinica: "Este prescritor faz parte de outra clínica",
                        prescritorJaInserido: "Este prescritor já foi inserido",
                        pontuarDotz: "Pontuar Dotz",
                    },
                },

                coleta: {
                    list_vue_html: {
                        coletas: "Coletas",
                        cadastradas: "Cadastradas",
                    },

                    edit_vue_html: {
                        coletaEdit: "Coleta - Edição",
                        data: "Data da coleta",
                        descricao: "Descrição",
                    },
                },

                condicao: {
                    edit_vue_html: {
                        descricao: "Descrição",
                    },
                    list_vue_html: {
                        condicoes: "Condições",
                    },
                },

                configuracaofranquia: {
                    edit_vue_html: {
                        configFranquia: "Configurações da Franquia",
                        franquia: "Franquia",
                        integradorFranquia: "Configuração de integração - Editar",
                        api_Key: "API KEY",
                    },
                },

                configuracaousuario: {
                    edit_vue_html: {
                        configUsuario: "Configurações do Usuário",
                        usuario: "Usuário",
                    },
                },

                configuracaowhatsapp: {
                    edit_vue_html: {
                        configWhatsApp: "Configuração WhatsApp",
                        iniciarConfiguracao: "Iniciar Configuração",
                        atualizar: "Atualizar",
                        cliqueAqui: "Clique aqui",
                        verComoFazer: "para ver como fazer",
                        leiaQrCode: "Use o celular para ler o QrCode abaixo e conectar ao WhatsApp.",
                        integracaoPermite:
                            "A integração com o WhatsApp permite o envio de mensagens automáticas para os clientes de forma segura e rápida.",
                        paraFazerAConfiguracao:
                            "Para fazer a configuração e ativar a integração, tenha o celular da loja em mãos e clique no botão abaixo para iniciar a configuração",
                        cliqueBotaoAbaixo: "Após ler o codigo no celular, clique no botão abaixo para prosseguir",
                        integracaoProntaUso: "Integração com WhatsApp está pronta para uso",
                        desconectarIntegracao: "Desconectar integração",
                        definirWebhook: "Configurar Webhook",
                    },
                },

                contacorrente: {
                    edit_vue_html: {
                        contaCorrentEdit: "Conta Corrente - Edição",
                        descricao: "Descrição",
                        interna: "Interna",
                        ativo: "Ativo",
                        banco: "Banco",
                        agencia: "Agência",
                        conta: "Conta",
                    },

                    list_vue_html: {
                        contasCorr: "Contas Correntes",
                        cadastradas: "Cadastradas",
                    },
                },

                contagerencial: {
                    edit_vue_html: {
                        contaGerencialEdit: "Conta Gerencial - Edição",
                        descricao: "Descrição",
                        classif: "Classificação",
                        tipoMovimen: "Tipo Movimento",
                        ativo: "Ativo",
                    },

                    list_vue_html: {
                        contasGerenc: "Contas Gerenciais",
                        cadastradas: "Cadastradas",
                    },
                },

                contagerencialclassificacao: {
                    edit_vue_html: {
                        classifCntGerencialEdit: "Classificação de Conta Gerencial - Edição",
                        obrigatorio: "* este  campo  é  obrigatório",
                        descricao: "Descrição",
                    },

                    list_vue_html: {
                        classificCntGerencial: "Classificações de Conta Gerencial",
                        cadastradas: "Cadastradas",
                    },
                },

                contapaga: {
                    list_vue_html: {
                        contasPagas: "Contas Pagas",
                        cadastradas: "Cadastradas",
                        categ: "Categoria:",
                        fornec: "Fornecedor:",
                        venciDe: "Vencimento De:",
                        ate: "Até:",
                        pagamentDe: "Pagamento De:",
                    },
                },

                contapagar: {
                    edit_vue_html: {
                        contAPagarEdit: "Conta a Pagar - Edição",
                        dadosConta: "Dados da Conta",
                        descricao: "Descrição",
                        recorrenc: "Recorrências",
                        utilizParaLancFuturos:
                            "Utilize esta opção caso esta conta for se repetir nos próximos meses, para efetuarmos os lançamentos futuros",
                        frequenc: "Frequência",
                        fornecedor: "Fornecedor",
                        contGerencial: "Conta Gerencial",
                        dtVenciment: "Data Vencimento",
                        vlrTotal: "Valor Total",
                        saldo: "Saldo",
                        cdBarras: "Código de Barras",
                        obs: "Observação",
                        parcelar: "Parcelar",
                        mtdPagament: "Método de Parcelamento",
                        parcela: "Parcela ",
                        vlrParcela: "Valor da Parcela",
                        saldoParcela: "Saldo da Parcela",
                        pgmtRealizados: "Pagamentos Realizados",
                        sldPagar: "SALDO A PAGAR",
                    },

                    list_vue_html: {
                        contasAPagrAberto: "Contas a Pagar - Em Aberto",
                        contasAPagr: "Contas a Pagar",
                        cadastradas: "Cadastradas",
                        tudo: "Tudo",
                        vencidos: "Vencidos",
                        fornecedor: "Fornecedor:",
                        vencDe: "Vencimento De:",
                        ate: "Até:",
                    },

                    pagamentoconta_vue_html: {
                        pgto: "Pagamento",
                        dtPgto: "Data Pagamento",
                        valor: "Valor",
                        contaCorrente: "Conta Corrente",
                        formaPagamento: "Forma Pagamento",
                        juros: "Juros",
                        desconto: "Desconto",
                        totalPago: "Total Pago",
                        obs: "Observação",
                        incluirPgto: "Incluir Pagamento",
                    },
                },

                contapagarmultiplas: {
                    edit_vue_html: {
                        cntPagarEdit: "Contas a Pagar - Edição",
                        cntPagar: "Contas a Pagar",
                        saldoPagar: "SALDO A PAGAR",
                        cifrao: "R$",
                        efetPgtos: "Efetuar Pagamentos",
                        dtPgto: "Data Pagamento",
                        cntCorrent: "Conta Corrente",
                        formaPgto: "Forma Pagamento",
                        juros: "Juros",
                        descontos: "Descontos",
                        vlrPago: "Valor Pago",
                    },
                },

                contareceber: {
                    edit_vue_html: {
                        cntReceberEdit: "Conta a Receber - Edição",
                        naoPodeAlterarMovimentac:
                            "Conta Receber oriunda de venda! Não é possível alterar movimentações por este cadastro.",
                        dadosConta: "Dados da Conta",
                        descricao: "Descrição",
                        recorrencias: "Recorrências",
                        utilizCasoRepita:
                            "Utilize esta opção caso esta conta for se repetir nos próximos meses, para efetuarmos os lançamentos futuros",
                        freq: "Frequência",
                        cntGerencial: "Conta Gerencial",
                        cliente: "Cliente",
                        operadoraCartoes: "Operadora de Cartões",
                        autoriz: "Autorização",
                        dtVenc: "Data Vencimento",
                        vlrTotal: "Valor Total",
                        sldTotal: "Saldo Total",
                        codBarras: "Código de Barras",
                        obs: "Observação",
                        parcelar: "Parcelar",
                        mtdParcelamento: "Método de Parcelamento",
                        parcela: "Parcela",
                        vlrParcela: "Valor Parcela",
                        sldParcela: "Saldo da Parcela",
                        liquidar: "Liquidar",
                        recebRealizados: "Recebimentos Realizados",
                        sldReceber: "SALDO A RECEBER",
                    },

                    list_vue_html: {
                        cntReceberAberto: "Contas a Receber - Em Aberto",
                        cntReceberParcelado: "Contas a Receber - Parceladas",
                        cntReceber: "Contas a Receber",
                        cadastradas: "Cadastradas",
                        cntGerencial: "Conta Gerencial:",
                        vncDe: "Vencimento De:",
                        ate: "Até:",
                        formaPgto: "Forma de Pagamento: ",
                        cntReceberVenda: "Conta Receber Vendas",
                    },

                    listOperadoras_vue_html: {
                        cntReceberOpCartAberto: "Contas a Receber de Operadoras de Cartão - Em Aberto",
                        cntReceber: "Contas a Receber",
                        cadastradas: "Cadastradas",
                        cntReceberAberto: "Contas a Receber - Em Aberto",
                        cntGerencial: "Conta Gerencial:",
                        vncDe: "Vencimento De:",
                        ate: "Até:",
                    },

                    recebimentoconta_vue_html: {
                        recebimento: "Recebimento",
                        dtRecebimento: "Data Recebimento",
                        cntCorrente: "Conta Corrente",
                        especie: "Espécie",
                        bandeira: "Bandeira",
                        frmRecebimento: "Forma Recebimento",
                        mtdParcelamento: "Metodo de Parcelamento",
                        autorizNSUCartao: "Autorização/NSU do cartão",
                        vlrAbater: "Valor à ser Pago",
                        tarifa: "Tarifa",
                        juros: "Juros",
                        desconto: "Desconto",
                        totalRecebido: "Total Recebido",
                        obs: "Observação",
                        inclRecebimento: "Incluir Recebimento",
                        attRecebimento: "Atualizar Recebimento",
                    },
                },

                contarecebermultiplas: {
                    edit_vue_html: {
                        cntReceberEdit: "Contas a Receber - Edição",
                        cntReceber: "Contas a Receber",
                        sldReceber: "SALDO A RECEBER",
                        cifrao: "R$",
                        efetRecebimentos: "Efetuar Recebimentos",
                        dtRecebimento: "Data Recebimento",
                        cntCorrente: "Conta Corrente",
                        especie: "Espécie",
                        bandeira: "Bandeira",
                        frmRecebimento: "Forma Recebimento",
                        mtdParcelamento: "Metodo de Parcelamento",
                        autorNSUCartao: "Autor/NSU Cartão",
                        juros: "Juros",
                        descontos: "Descontos",
                        vlrRecebido: "Valor Recebido",
                    },
                },

                contarecebida: {
                    list_vue_html: {
                        cntReceberRecebidas: "Contas a Receber - Recebidas",
                        cntRecebidas: "Contas Recebidas",
                        cadastradas: "Cadastradas",
                        conta: "Conta:",
                        vncDe: "Vencimento De:",
                        ate: "Até:",
                    },

                    listOperadoras_vue_html: {
                        cntReceberRecebidas: "Contas a Receber - Recebidas",
                        cntRecebidas: "Contas Recebidas",
                        cadastradas: "Cadastradas",
                        conta: "Conta:",
                        vncDe: "Vencimento De:",
                        ate: "Até:",
                    },
                },

                configuracaoFranquiaGatewayPagamento: {
                    edit_vue_html: {
                        gatewayPagamentoEdit: "Gateway pagamento - Edição",
                        gatewayPagamento: "Gateway pagamento",
                        toke: "Token",
                        descFaturaCartao: "Descrição fatura do cartão",
                        cartaoCreditoMaximoParcelasSemJuros: "Numero máximo de parcelas SEM JUROS no crédito",
                        cartaoCreditoMaximoParcelasComJuros: "Numero máximo de parcelas COM JUROS no crédito",
                        expLinkpagamentoHoras: "Expiração do link de pagamento (horas)",
                        pixAtivo: "Pix ativo",
                        boletoAtivo: "Boleto ativo",
                        cartCredAtivo: "Cartão crédito ativo",
                        cartDebAtivo: "Cartão débito ativo",
                        valorMinimoParcela: "Valor mínimo da parcela",
                    },
                },

                cor: {
                    edit_vue_html: {
                        corEdit: "Cores - Edição",
                        descricao: "Descrição",
                    },

                    list_vue_html: {
                        cores: "Cores",
                        cadastrados: "Cadastrados",
                    },
                },

                dadosAdicionaisFichaTecnica: {
                    edit_vue_html: {
                        dadosAdicFTEdit: "Dados Adicionais da Ficha Técnica - Edição",
                        descricao: "Descrição",
                        padrao: "Padrão",
                    },

                    list_vue_html: {
                        dadosAdicFT: "Dados Adicionais da Ficha Técnica",
                        cadastrados: "Cadastrados",
                    },
                },

                declaracaoConteudo: {
                    view_vue_html: {
                        docDeclaraConteudo: "Documento de declaração de conteúdo",
                    },
                },

                devolucaoCompra: {
                    edit_vue_html: {
                        devolucaoCompraEdit: "Devolução de compra - Edição",
                        nota: "Nota",
                        numero: "Número da Nota Fiscal",
                        serie: "Série",
                        dataEmissao: "Data de Emissão",
                        dataInclusao: "Data de Inclusão",
                        fornecedor: "Fornecedor",
                        transportadora: "Transportadora",
                        itens: "Itens a serem devolvidos",
                        emitir: "Emitir nota devolução",
                        valorTotal: "Valor total a devolver",
                        cabecalhoProduto: "Produto",
                        cabecalhoLote: "Lote",
                        cabecalhoQuantidade: "Quantidade",
                        cabecalhoQuantidadeDevolver: "Quantidade a devolver",
                        cabecalhoUnidadeDevolver: "Un.Medida a devolver",
                        cabecalhoQuantidadeEstoque: "Qtd descontar Estoque",
                        cabecalhoUnidadeEstoque: "Un.Medida Estoque",
                        cabecalhoValorDevolver: "Valor a devolver",
                        cabecalhoValorUnitario: "Valor unitário",
                        cabecalhoValorTotal: "Valor total",
                    },

                    consulta_vue_html: {
                        devolucaoCompra: "Devolução de compra - Consulta",
                        numero: "Número",
                        serie: "Série",
                        dataEmissao: "Data de Emissão",
                        chaveAcesso: "Chave de acesso",
                        consultar: "Consultar",
                        fornecedor: "Fornecedor",
                        transportadora: "Transportadora",
                        notaEntrada: "Nota de Entrada",
                        notaDevolucao: "Nota de Devolução",
                        movimentacaoEstoque: "Movimentação de estoque",
                    },

                    list_vue_html: {
                        devolucaoCompra: "Devoluções de compra",
                        cadastrados: "Cadastrados",
                    },
                },

                downloadXmls: {
                    edit_vue_html: {
                        downloadXmls: "Download de XMLs de Notas Fiscais",
                        dataInicial: "Data Inicial",
                        dataFinal: "Data Final",
                        tiposDocumento: "Tipo de documento",
                        tiposNotas: "Tipo de notas",
                        download: "Download",
                    },
                },

                email: {
                    edit_vue_html: {
                        emailEdit: "Email - Edição",
                        host: "Host",
                        porta: "Porta",
                        email: "E-mail",
                        senha: "Senha",
                    },

                    list_vue_html: {
                        cadastrados: "Cadastrados",
                    },
                },

                ensaio: {
                    edit_vue_html: {
                        ensaioEdit: "Ensaio - Edição",
                        descricao: "Descrição",
                        farmacopeia: "Farmacopeia",
                        soludibilidade: "Solubilidade",
                    },

                    list_vue_html: {
                        ensaio: "Ensaio",
                        ensaios: "Ensaios",
                        cadastrados: "Cadastrados",
                    },
                },

                especialidade: {
                    edit_vue_html: {
                        especialidadeEdit: "Especialidade - Edição",
                        descricao: "Descrição",
                    },

                    list_vue_html: {
                        especialidades: "Especialidades",
                        cadastradas: "Cadastradas",
                    },
                },

                especieanimal: {
                    edit_vue_html: {
                        espAnimalEdit: "Espécie de Animal - Edição",
                        descricao: "Descrição",
                    },

                    list_vue_html: {
                        espAnimal: "Espécies de Animal",
                        cadastradas: "Cadastradas",
                    },
                },

                estoque: {
                    edit_vue_html: {
                        estqEdit: "Estoque - Edição",
                        descricao: "Descrição",
                        permiteVenda: "Permite Venda",
                    },

                    list_vue_html: {
                        estoques: "Estoques",
                        cadastradas: "Cadastradas",
                    },
                },

                estoqueMinimo: {
                    view_vue_html: {
                        mesesConsiderar: "Meses a considerar",
                        coberturaEstoque: "Cobertura de estoque em dias",
                        calcularEstoque: "Calcular estoque",
                    },
                },

                etapaPCP: {
                    edit_vue_html: {
                        etapaPCPEdit: "Etapa PCP - Edição",
                        descricao: "Descrição",
                        ordem: "Ordem",
                        ativo: "Ativo",
                        obrigatorio: "Obrigatório",
                        tipo: "Tipo",
                    },

                    list_vue_html: {
                        etapasPCP: "Etapas PCP",
                        cadastrados: "Cadastrados",
                    },
                },

                etiquetas: {
                    list_vue_html: {
                        etiquetas: "Etiquetas",
                        cadastradas: "Cadastradas",
                        exibirSomenteVendas: "Exibir somente vendas com notas",
                        imprimirEtiquetas: "Imprimir Etiquetas",
                        gerarEtiquetaLote: "Gerar etiquetas em lote",
                    },
                },

                expedicao: {
                    listEntrega_vue_html: {
                        statusEntrega: "Status Entrega",
                    },
                },

                formafarmaceutica: {
                    edit_vue_html: {
                        formFarmaceutEdit: "Forma Farmacêutica - Edição",
                        franquia: "Franquia",
                        ativo: "Ativo",
                        descricao: "Descrição",
                        utilzMolde: "Utiliza Molde",
                        calcMoldeAdequado:
                            "Se utiliza molde, e possui moldes cadastrados no cadastro de model, ao criar uma manipulação pelo orçamento o sistema irá buscar o molde mais adequado para quantidade de ativo informado, caso não utilize molde o volume da manipulação deverá ser informado manualmente em sua criação.",
                        desconsiDensid: "Desconsiderar Densidade",
                        desconsiDensidNoCalc:
                            "Desconsiderar a densidade no cálculo de volume total de ativos que definem o QSP.",
                        descrRotulo: "Descrição Rótulo",
                        tipCalcAtivo: "Tipo de Cálculo do Ativo",
                        tipCalcPadrao:
                            "Tipo de Cálculo padrão para quando informado o ativo na manipulação desta forma farmacêutica, caso não informado, o sistema utilizará o tipo de cálculo do cadastro do produto.",
                        conceMax: "Concentração Máxima",
                        conceMaxFito: "Concentração Máxima Fitoterápico",
                        conceMaxDose: "Concentração máxima por dose do ativo principal.",
                        ncmSH: "NCM/SH",
                        codOitoDig:
                            "Código composto de 8 números, utilizado para classificar os itens de acordo com regulamentos do Mercosul",
                        valDias: "Validade (dias)",
                        tipo: "Tipo",
                        calcEmbal: "Cálculo Embalagem",
                        uso: "Uso",
                        tipoUso: "Tipo Uso",
                        qntMin: "Quantidade Mínima",
                        qntMax: "Quantidade Máxima",
                        unMedVol: "Unidade Medida Volume",
                        pop: "P.O.P (Procedimento Operacional Padrao)",
                        descriDetalhProc:
                            "Descrição detalhada das operações necessárias para a realização do procedimento",
                        exibirPrefixoDoseRotulo: "Exibir prefixo de dose no rótulo",
                        ocultarExcipienteRotulo: "Ocultar veiculo/excipiente no rótulo",
                        ocultarFlavorizanteRotuno: "Ocultar flavorizante no rótulo",
                        vlrMin: "Valor Mínimo",
                        vlrAdd: "Valor Adicional",
                        ftrMultQtd: "Fator Multip. Quantidade",
                        ftrAddVlrForm:
                            "Fator que multiplicado a quantidade da forma farmacêutica será adicionado ao valor adicional da forma",
                        ftrCompensacao: "Fator de Compensação",
                        ftrSomaMult:
                            "Fator que soma ao multiplicador enquanto for maior que zero e diminui com a taxa de decaimento",
                        ftrMultDecaimento: "Fator Mult. Decaimento",
                        ftrCriaTaxDecaimento:
                            "Fator que multiplicado a quantidade cria uma taxa de decaimento e criando uma curva de preço",
                        itensAdd: "Itens Adicionados",
                        atualizarValores:
                            "Você deseja atualizar os campos de valores? <BR/><BR/><BR/> Campos que serão atualizados:<BR/> Valor Mínimo<BR/> Valor Adicional<BR/> Fator de Compensação<BR/> Fator Multiplicador Adicional<BR/> Fator de Decaimento<BR/>",
                        exibirDescRotulo: "Exibir Descrição Rótulo no rótulo",
                        capsVegana: "Cápsula Vegana",
                        capsVeganaRevestida: "Cápsula Vegana Revestida",
                    },

                    item_vue_html: {
                        item: "Item",
                        subGrup: "SubGrupo",
                        prod: "Produto",
                        tipoCalc: "Tipo Cálculo",
                        qnt: "Quantidade",
                        desmemb: "Desmembrado",
                    },

                    list_vue_html: {
                        frmsFarmaceu: "Formas Farmacêuticas",
                        cadastradas: "Cadastradas",
                    },
                },

                formapagamento: {
                    edit_vue_html: {
                        frmPagtoEdit: "Forma de Pagamento - Edição",
                        descricao: "Descrição",
                        especie: "Espécie",
                        ativo: "Ativo",
                    },

                    list_vue_html: {
                        frmsPagto: "Formas de Pagamento",
                        cadastradas: "Cadastradas",
                    },
                },

                formarecebimento: {
                    edit_vue_html: {
                        frmRecebimentoEdit: "Forma de Recebimento - Edição",
                        obrigatorio: "* este campo é obrigatório",
                        descricao: "Descrição",
                        ativo: "Ativo",
                        especie: "Espécie",
                        percenDesc: "Percentual de Desconto",
                        percenJuros: "Percentual de Juros",
                        operadCartoes: "Operadora de Cartões",
                        cntCorrOrigem: "Conta Corrente Origem",
                        cntCorrOrigemVlr: "Conta Corrente da origem do valor transferido",
                        frmPgtoOrigem: "Forma de Pagamento Origem",
                        frmPgtoParaSaida:
                            "Forma de Pagamento utilizada para gerar a saida. Obs: Sua especie define o tipo de especie do recebimento gerado.",
                        bandeirasSelec: "Bandeiras Selecionadas",
                        bandeira: "Bandeira",
                        mtdPgtoAceitos: "Metodos de Pagamento aceitos",
                        addAltMetodos: "Adicionar / Alterar Métodos",
                    },

                    list_vue_html: {
                        frmsRecebimento: "Formas de Recebimento",
                        cadastradas: "Cadastradas",
                    },

                    metodosXFormaEdit_vue_html: {
                        mtdPgto: "Metodo de Pagamento",
                        percenDesc: "Percentual de Desconto",
                        percenJuros: "Percentual de Juros",
                        percenTarifa: "Percentual de Tarifa",
                    },
                },

                formulapadrao: {
                    edit_vue_html: {
                        frmlaPadraoEdit: "Fórmula Padrão - Edição",
                        dadosGerais: "Dados Gerais",
                        franquia: "Franquia",
                        ativo: "Ativo",
                        descricao: "Descrição",
                        prodFinal: "Produto Final",
                        frmFarmaceu: "Forma Farmaceutica",
                        valDias: "Validade (dias)",
                        tipoFrmlPadrao: "Tipo Fórmula Padrão",
                        capsula: "Cápsula",
                        qtdCapsulas: "Quantidade Cápsulas",
                        volume: "Volume",
                        unMedida: "Unidade de Medida",
                        obs: "Observação",
                        proceProd: "Procedimento Produto",
                        posologia: "Posologia",
                        descrRotulo: "Descrição Rótulo",
                        desmemFrml: "Desmembrar Fórmula",
                        produzir: "Produzir",
                        revisAjustar: "Revisar/Ajustar",
                        kitEmbalagem: "Kit de Embalagem",
                        qtdPadraoPreVenda: "Quantidade padrão na pré venda",
                        valorTabelado: "Valor Tabelado",
                        edicaoPreVenda: "Edição na Pré-venda",
                    },

                    item_vue_html: {
                        tipoProd: "Tipo Produto",
                        prod: "Produto",
                        faseUso: "Fase Uso",
                        unMedida: "Unidade Medida",
                        ftrCorre: "Fator Correção",
                        tipoCalc: "Tipo Cálculo",
                        volumeDose: "Vol. Dose",
                        qtd: "Quantidade",
                        unMedidaManipu: "Unidade Medida Manipulação",
                        qtdTotal: "Quantidade Total",
                    },

                    list_vue_html: {
                        frmlsPadr: "Fórmulas Padrão",
                        frmls: "Fórmulas",
                        cadastradas: "Cadastradas",
                        ativos: "Ativos",
                        desativados: "Desativados",
                        ambos: "Ambos",
                        buscPorInsumo: "Buscar por insumo",
                        tipoFrmlPadr: "Tipo Formula Padrão",
                        todos: "Todos",
                        produzir: "Produzir",
                        concluirManipulacoes: "Concluir Selecionados",
                        imprimirManipulacoes: "Imprimir selecionados",
                        replAtualizTds: "Replicar/Atualizar Todos",
                        calcVlres: "Calcular Valores",
                        visuFrmPadr: "Visualizar Formula Padrão",
                    },

                    ver_vue_html: {
                        dadosGera: "Dados Gerais",
                        descricao: "Descrição:",
                        prodFinal: "Produto Final:",
                        frmFarmaceu: "Forma Farmaceutica:",
                        valDias: "Validade (dias):",
                        tipoFrmlPadr: "Tipo Formula Padrão:",
                        vol: "Volume:",
                        unMedida: "Unidade de Medida:",
                        procedimProducao: "Procedimento Produção:",
                        obs: "Observação:",
                        itens: "Itens",
                        prod: "Produto",
                        tipoProd: "Tipo Produto",
                        tipoCalc: "Tipo Cálculo",
                        qtde: "Qtde",
                        qtdePesag: "Qtde Pesagem",
                        umMedidaAbv: "Un. Medida",
                        unManipul: "Un. Manipulação",
                        fase: "Fase",
                    },
                },

                dinamizacao: {
                    edit_vue_html: {
                        fieldsetReferencia: "Produto Referência",
                        fieldsetDinamizado: "Produto Dinamizado",
                        fieldsetVeículo: "Veículo",
                        quantidade: "Quantidade",
                        unidadeMedida: "Unidade de Medida",
                        kitEmbalagem: "Kit de Embalagem",
                        validade: "Validade (dias)",
                        estoque: "Estoque",
                        produto: "Produto",
                        lote: "Lote",
                        dinamizacao: "Dinamização",
                        metodo: "Método",
                        qtdNecessaria: "Quantidade Necessária para Dinamização",
                        gerarDinamizacao: "Gerar Dinamização",
                        gerarLote: "Gerar Lote",
                    },
                },

                pontuacaoManualDotz: {
                    list_vue_html: {
                        pontuacaoManual: "Pontuação Manual",
                        pontuacoesManuais: "Pontuações manuais",
                        gerarPontuacao: "Gerar pontuações",
                        enviarPontuacao: "Enviar pontuações",
                    },
                },

                formulapadraomanipulacao: {
                    edit_vue_html: {
                        frmlPadrGerarManipu: "Fórmula Padrão - Gerar Manipulação",
                        frmlPadr: "Formula Padrão",
                        gerarManipulacao: "Gerar Manipulação",
                        gerarOrdemManipu: "Gerar Ordem de Manipulação",
                        sair: "Sair",
                        manipu: "Manipulacao",
                    },

                    item_vue_html: {
                        ocorrDiverg:
                            "Manipulação gerada com sucesso, porém ocorreram divergências, verifique-as pelo botão visualizar manipulação que se encontra ao lado do código da mesma.",
                        manipuNaoGerada:
                            "Manipulação não pode ser criada, verifique o cadastro da fórmula padrão para identificar o problema.",
                        codManipu: "Código da Manipulação:",
                        visuManipu: "Visualizar Manipulação",
                        volQtd: "Volume/Quantidade:",
                        valDias: "Validade (dias):",
                        itens: "Itens:",
                        estq: "Estoque",
                        qtd: "Quantidade",
                        totalProduzir: "Total Produzir",
                        confEstqEQntd: "Confere estoque e ajusta quantidades e lotes quando necessario",
                        printOrdem: "Imprimir Ordem",
                        printRotulo: "Imprimir Rotulo",
                        naoPermiteQuantidadeDecimal:
                            "Formula com itens do tipo Capsula ou Embalagem não permitem quantidade decimal",
                        vlrSeraAtualizCustoProd: "Se marcado a opção, o valor do custo do produto final será alterado.",
                        atualizarCustosProduto: "Atualizar Custo do Produto",
                    },
                },

                formulapadraovalorproduto: {
                    list_vue_html: {
                        calcVlrsProdsFrmlPdr: "Cálculo de Valores de Produtos de Fórmulas Padrão",
                        frmlsRecalcs: "Fórmulas Recalculadas",
                        recalcDeVlrProd: "Recalculo de Valores de Produtos",
                        tdsRegisRecalcs: "Todos Registros Recalculados",
                        apenasComDifVlr: "Apenas com Diferenças nos valores Calculados",
                        apenasCustMaiorVlr: "Apenas Custo Calculado maiores que o valor atual",
                        atualiz: "Atualizar",
                    },
                },

                fornecedor: {
                    edit_vue_html: {
                        fornecedorEdit: "Fornecedor - Edição",
                        dadosGerais: "Dados Gerais",
                        tipo: "Tipo",
                        cpf: "CPF",
                        rg: "RG",
                        cnpj: "CNPJ",
                        ie: "Insc. Estadual",
                        contribuinteEstadual: "Contribuinte Estadual",
                        documentoEstrangeiro: "Documento estrangeiro",
                        exterior: "Exterior",
                        nomeFants: "Nome Fantasia",
                        nome: "Nome",
                        cntGerencialPdr: "Conta Gerencial Padrão",
                        razaoSocial: "Razão Social",
                        localiz: "Localização",
                        cep: "CEP",
                        estado: "Estado",
                        cidade: "Cidade",
                        endereco: "Endereço",
                        nro: "Número",
                        bairro: "Bairro",
                        compl: "Complemento",
                        contato: "Contato",
                        tel: "Telefone",
                        cel: "Celular",
                        fax: "Fax",
                        homepg: "Homepage",
                        telContato: "Telefone  Contato",
                    },

                    list_vue_html: {
                        fornecedores: "Fornecedores",
                        cadastrados: "Cadastrados",
                    },
                },

                franquia: {
                    edit_vue_html: {
                        franquiaEdit: "Franquia - Edição",
                        dadosGerais: "Dados Gerais",
                        mapa: "MAPA",
                        veterinarioMapa: "Veterinário MAPA",
                        veterinarioMapaCRMV: "CRMV Veterinário MAPA",
                        autorizacaoMapa: "Autorização MAPA",
                        autorizacaoAnvisa: "Autorização ANVISA",
                        nomeFantasia: "Nome Fantasia",
                        razaoSocial: "Razão Social",
                        cnpj: "CNPJ",
                        ie: "Insc. Estadual",
                        im: "Insc. Municipal",
                        nomeFarmaceu: "Nome Farmacêutico",
                        conselhoReg: "Conselho Regional",
                        nroCR: "Numero CR",
                        crf: "CRF",
                        crmv: "CRMV",
                        crm: "CRM",
                        areaAtuac: "Àrea de Atuação",
                        cpfSNGPC: "CPF  SNGPC",
                        autorSanitaria: "Autoridade Sanitária",
                        licenca: "Licença",
                        natOperacao: "Natureza de Operação",
                        optanteSN: "Optante pelo Simples Nacional",
                        incentivCult: "Incentivador Cultural",
                        rede: "Rede",
                        tipoFranquia: "Tipo da Franquia",
                        localiz: "Localização",
                        cep: "CEP",
                        estado: "Estado",
                        cidade: "Cidade",
                        lograd: "Logradouro",
                        complemento: "Complemento",
                        nro: "Número",
                        bairro: "Bairro",
                        contato: "Contato",
                        tel: "Telefone",
                        cel: "Celular",
                        modulos: "Módulos",
                        atendimentoWpp: "Atendimento(WhatsApp)",
                        chaveAPI: "Chave API",
                        diasArmaz: "Dias de Armazenamento",
                        excluirDados: "Excluir Dados",
                        configuracoes: "Configurações",
                        matriz: "Matriz",
                        moduloApp: "Módulo APP",
                        moduloCompras: "Módulo Compras",
                        moduloWhatsApp: "Módulo Atendimentos",
                        moduloAutomatizacaoContinuos: "Módulo Automatização de Contínuos",
                        whatsapp: "Whatsapp",
                        complementoWhatsapp: "Complemento Whatsapp Orcamento",
                        validadeLicensaSanitaria: "Validade da Licensa Sanitária",
                    },

                    list_vue_html: {
                        franquias: "Franquias",
                        cadastradas: "Cadastradas",
                    },
                },

                grupo: {
                    edit_vue_html: {
                        grupoEdit: "Grupo - Edição",
                        descr: "Descrição",
                        ativo: "Ativo",
                        acoes: "Ações",
                        users: "Usuários",
                    },

                    list_vue_html: {
                        grupos: "Grupos",
                        cadastrados: "Cadastrados",
                        sem_permissao_excluir_grupo: "Você não tem permissao para excluir esse grupo!",
                    },
                },

                grupoproduto: {
                    edit_vue_html: {
                        grupoProdEdit: "Grupo de Produto - Edição",
                        descr: "Descrição",
                        tipoProd: "Tipo Produto",
                        rotuloPadrao: "Rótulo Padrão",
                    },

                    list_vue_html: {
                        grupoProd: "Grupos de Produto",
                        cadastrados: "Cadastrados",
                    },
                },

                historicoenviosdotz: {
                    list_vue_html: {
                        title: "Histórico de Envios Dotz",
                        enviados: "Envios",
                        titulofiltroAvancado: "Filtro Avançado",
                        filtroDataInicial: "Data Inicial",
                        filtroDataFinal: "Data Final",
                        filtroPrescritor: "Prescritor",
                        filtroStatusEnvio: "Status do Envio",
                        btnFiltrar: "Filtrar",
                    },
                },

                inutilizacaoNotaFiscal: {
                    edit_vue_html: {
                        inutilizacaoNotaFiscalEdit: "Inutilização de nota fiscal - Edição",
                        ano: "Ano",
                        modelo: "Modelo",
                        numeroInicial: "Número Inicial",
                        numeroFinal: "Número Final",
                        serie: "Série",
                        justificativa: "Justificativa",
                        rejeicao: "Rejeição",
                        inutilizar: "Inutilizar",
                    },

                    list_vue_html: {
                        inutilizacaoNotaFiscal: "Inutilização de notas fiscais",
                        cadastrados: "Cadastrados",
                    },
                },

                inventario: {
                    edit_vue_html: {
                        ajusteEstq: "Ajuste de Estoque",
                        tipoInvent: "Tipo Inventário",
                        grupoProd: "Grupo Produtos",
                        prodLotes: "Produtos/Lotes",
                        produtos: "Produtos",
                        status: "Status",
                        movimentacoes: "Movimentações",
                        iniciar: "Iniciar",
                        finalizar: "Finalizar",
                        ignoraLotesZerados: "Ignorar lotes zerados",
                    },

                    list_vue_html: {
                        inventarios: "inventários",
                        gerados: "Gerados",
                    },

                    produtoassociacao_vue_html: {
                        addNovo: "Adicionar Novo",
                        cadProd: "Cadastrar Produto",
                    },

                    produtolotes_vue_html: {
                        prod: "Produto",
                        tipo: "Tipo",
                        editProd: "Editar Produto",
                        novoProd: "Novo Produto",
                        ftrCorrec: "Fator de Correção",
                        fc: "F.C.",
                        qtdUnMedOrig: "Qtd./ Un.Med. Orig.",
                        qtd: "Qtd.",
                        unMed: "Un.Med",
                        qtdConvert: "Qtd. Convertida",
                        custUnit: "Custo Unitário",
                        custRefAtual: "Custo Referência Atual",
                        dadosLote: "Dados do Lote",
                        descr: "Descrição",
                        dataFab: "Data de Fabricação",
                        dataVal: "Data de Validade",
                        precoMaxConsu: "Preço Máx. Consumidor",
                        importSemLote: "Importar Sem Lote",
                        preenchLote: "Preencher Lote",
                    },

                    produtos_vue_html: {
                        prodLotes: "Produtos/Lotes",
                        proximo: "Próximo",
                        conclAlteAvancProxProd:
                            "Conclui as alterações atuais e avança para o próximo produto divergente",
                    },
                },

                kitembalagem: {
                    edit_vue_html: {
                        kitEmbalEdit: "Kit Embalagem - Edição",
                        dadosGerais: "Dados Gerais",
                        franquia: "Franquia",
                        descr: "Descrição",
                        descrRotu: "Descrição Rótulo",
                        unMed: "Unidade de Medida",
                        volMax: "Volume Maximo",
                        ativo: "Ativo",
                        obs: "Observação",
                        frmFarmace: "Forma Farmaceutica",
                        frmsFarmaces: "Formas Farmacêuticas",
                        prioriz: "Priorizar",
                        priorizEssaOpc:
                            "Quando selecionado para Priorizar e no uso Forma Farmacêutica possuir varios kits com mesmo volume, o sistema irá priorizar o marcado com essa opção",
                        prod: "Produto",
                        tipoProd: "Tipo Produto",
                        subGrupProd: "SubGrupo Produto",
                        prods: "Produtos",
                        qtd: "Quantidade",
                        padrao: "Padrão",
                        prodPadrSeraoASelec:
                            "Produtos marcados como padrão serão automaticamente adicionados na manipulação quando selecionado este kit de embalagens",
                        rotuloPadrao: "Rótulo Padrão",
                        frmCapacidadeEmbalagemCapsulas: "Capacidade Embalagem (Cápsulas)",
                        capsula: "Cápsula",
                        qtdMaximaBiscoitos: "Quantidade Máxima de Biscoitos",
                    },

                    list_vue_html: {
                        kitsEmbal: "Kits Embalagem",
                        cadastrados: "Cadastrados",
                    },
                },

                listacontrolado: {
                    edit_vue_html: {
                        lstControladosEdit: "Lista de Controlados - Edição",
                        descricao: "Descrição",
                        codigo: "Código",
                        clssTerapeu: "Classe Terapêutica",
                    },

                    list_vue_html: {
                        lstControlados: "Listas de Controlado",
                        cadastrados: "Cadastrados",
                    },
                },

                livroRegistroEspecifico: {
                    view_vue_html: {
                        livroRegistroEspecificoGeracao: "Livro de Registro Específico",
                        ano: "Ano",
                        tipoPeriodo: "Tipo de Período",
                        tipoMedicamentoControlado: "Tipo de Medicamento Cotrolado",
                    },
                },

                localentrega: {
                    list_vue_html: {
                        titulo: "Locais de Entrega",
                        cadastrados: "Cadastrados",
                        taxa: "Taxa",
                    },

                    edit_vue_html: {
                        titulo: "Locais de Entrega - Edição",
                        codigo: "Código",
                        descricao: "Descrição",
                        taxa: "Taxa",
                        transportadora: "Transportadora",
                    },
                },

                manipuladores: {
                    view_vue_html: {
                        manipuladores: "Manipuladores",
                    },
                },

                meta: {
                    edit_vue_html: {
                        metaEdit: "Meta - Edição",
                        mes: "Mês",
                        ano: "Ano",
                        vlrTotal: "Valor Total",
                        diasUteis: "Dias Úteis",
                        vlrDiario: "Valor Diário",
                    },

                    list_vue_html: {
                        metas: "Metas",
                        cadastradas: "Cadastradas",
                    },
                },

                metodopagamento: {
                    edit_vue_html: {
                        mtdsPgtoEdit: "Métodos de Pagamento - Edição",
                        descricao: "Descrição",
                        ativo: "Ativo",
                        nroParcels: "Número de Parcelas",
                        intervPrimParce: "Intervalo (Dias) até 1° parcela",
                        intervEntreParce: "Intervalo (Dias) entre Parcelas",
                    },

                    list_vue_html: {
                        mtdsPgto: "Métodos de Pagamento",
                        cadastrados: "cadastrados",
                    },
                },

                molde: {
                    edit_vue_html: {
                        moldeEdit: "Molde - Edição",
                        dadosGerais: "Dados Gerais",
                        descricao: "Descrição",
                        ativo: "Ativo",
                        unMedida: "Unidade de Medida",
                        volInterno: "Volume Interno",
                        volTotal: "Volume Total",
                        volMaxPrinciAtivo: "Volume Máx. Principio Ativo",
                        volMaxPrinciAtivoFito: "Volume Máx. Principio Ativo Fitoterápico",
                        obs: "Observação",
                        frmFarmaceu: "Forma Farmaceutica",
                        frmsFarmaceus: "Formas Farmacêuticas",
                        qspPadrao: "Produto QSP padrao",
                    },

                    list_vue_html: {
                        moldes: "Moldes",
                        cadastrados: "Cadastrados",
                    },
                },

                motivocancelamento: {
                    edit_vue_html: {
                        mtvCancelEdit: "Motivo de Cancelamento - Edição",
                        descricao: "Descrição",
                        exigirObs: "Exigir Observação",
                        ativo: "Ativo",
                    },

                    list_vue_html: {
                        mtvCancelamento: "Motivos de Cancelamento",
                        cadastrados: "Cadastrados",
                    },
                },

                motivoEtapaPCP: {
                    edit_vue_html: {
                        motivoEtapaPCPEdit: "Motivo Etapa PCP - Edição",
                        descricao: "Descrição",
                    },

                    list_vue_html: {
                        motivoEtapaPCP: "Motivo Etapa PCP",
                        cadastrados: "Cadastrados",
                    },
                },

                motivorejeicao: {
                    edit_vue_html: {
                        mtvRejeicaoEdit: "Motivo de Rejeição - Edição",
                    },

                    list_vue_html: {
                        mtvRejeicao: "Motivos de Rejeição",
                    },
                },

                motivomovimentacao: {
                    edit_vue_html: {
                        motivoMovimentacaoEdit: "Motivo Movimentação Estoque - Edição",
                        descricao: "Descrição",
                    },

                    list_vue_html: {
                        motivomovimentacao: "Motivos Movimentação Estoque",
                        cadastrados: "Cadastrados",
                    },
                },

                movimentacaoconta: {
                    edit_vue_html: {
                        movimentacContaEdit: "Movimentação de Conta - Edição",
                        tipoMovimenta: "Tipo Movimentação",
                        contaGerenc: "Conta Gerencial",
                        dataPgto: "Data Pagamento",
                        frmRecebimento: "Forma Recebimento",
                        frmPgto: "Forma Pagamento",
                        contaCorrt: "Conta Corrente",
                        vlrRecebido: "Valor Recebido",
                        vlrPago: "Valor Pago",
                        descricao: "Descrição",
                        obs: "Observação",
                    },

                    list_vue_html: {
                        movimentacConta: "Movimentações da Conta",
                        cadastradas: "Cadastradas",
                    },
                },

                movimentacaoestoque: {
                    edit_vue_html: {
                        movimentaEstqEdit: "Movimentação de Estoque - Edição",
                        dadosGera: "Dados Gerais",
                        tipoMovimenta: "Tipo Movimentação",
                        dataMovimenta: "Data Movimentação",
                        estqOrigem: "Estoque Origem",
                        estqDestino: "Estoque Destino",
                        motivoMovimentacaoEstoque: "Motivo da Movimentação",
                        produtos: "Produtos",
                        reverterMovimentacao: "Reverter movimentação de estoque",
                    },

                    item_vue_html: {
                        dadosLote: "Dados do Lote",
                        lote: "Lote",
                        editProdLote: "Editar Produto Lote",
                        novoProdLote: "Novo Produto Lote",
                        produto: "Produto",
                        qtd: "Quantidade",
                        vlrUnitario: "Valor Unitário",
                        qtdAtual: "Quantidade Atual",
                        dataFab: "Data Fabricação",
                        dataVal: "Data Validade",
                        obs: "Observação",
                    },

                    list_vue_html: {
                        movimentaEstq: "Movimentação de Estoque",
                        cadastradas: "Cadastradas",
                        dataInicial: "Data Inicial",
                        dataFinal: "Data Final",
                        tipoMov: "Tipo de Movimentação",
                        estoque: "Estoque",
                    },
                },

                naturezaoperacao: {
                    edit_vue_html: {
                        naturezaOpEdit: "Natureza de Operação - Edição",
                        descricao: "Descrição",
                        codigo: "Código",
                        tipoMovimento: "Tipo Movimento",
                        dentroEstado: "Dentro Estado",
                        movimentaEstq: "Movimenta Estoque",
                        usaConfiguracaoFiscal: "Usa Configuração Fiscal",
                        observacoes: "Observações",
                        transferencia: "Transferência",
                        movimentaEstqToolTip:
                            "Ao marcar esse campo o sistema irá considerar automaticamente na emissão de nota avulsa a movimentação de estoque após emitir a nota",
                    },

                    list_vue_html: {
                        naturezaOp: "Naturezas de Operação",
                        cadastradas: "Cadastradas",
                    },
                },

                nbm: {
                    edit_vue_html: {
                        nbmEdit: "NBM - Edição",
                        descricao: "Descrição",
                        codigo: "Código",
                    },

                    list_vue_html: {
                        nbms: "NBMs",
                        cadastrados: "Cadastrados",
                    },
                },

                ncm: {
                    edit_vue_html: {
                        ncmEdit: "NCM - Edição",
                        descricao: "Descrição",
                        codigo: "Código",
                        ativo: "Ativo",
                    },

                    list_vue_html: {
                        ncms: "NCMs",
                        cadastrados: "Cadastrados",
                    },
                },

                operadoraCartoes: {
                    edit_vue_html: {
                        operadoraCardsEdit: "Operadora de Cartões - Edição",
                        dadosGerais: "Dados Gerais",
                        tipo: "Tipo",
                        cpf: "CPF",
                        rg: "RG",
                        cnpj: "CNPJ",
                        ie: "Insc. Estadual",
                        nomeFantasia: "Nome Fantasia",
                        nome: "Nome",
                        razaoSocial: "Razão Social",
                        localizacao: "Localização",
                        cep: "CEP",
                        estado: "Estado",
                        cidade: "Cidade",
                        endereco: "Endereço",
                        nro: "Número",
                        bairro: "Bairro",
                        complemento: "Complemento",
                        contato: "Contato",
                        tel: "Telefone",
                        cel: "Celular",
                        telContato: "Telefone Contato",
                    },

                    list_vue_html: {
                        operadoraCards: "Operadora de Cartões",
                        cadastradas: "Cadastradas",
                    },
                },

                paciente: {
                    edit_vue_html: {
                        pacienteEdit: "Paciente - Edição",
                        nome: "Nome",
                        nomeRotulo: "Nome Rótulo",
                        nomeUtilizRotulo: "Nome que será utilizado no rótulo para identificação do paciente",
                        ativo: "Ativo",
                        dataNasc: "Data Nascimento",
                        genero: "Gênero",
                        pesoKg: "Peso (Kg)",
                        especie: "Espécie",
                        raca: "Raça",
                        cliente: "Cliente",
                        editCliente: "Editar Cliente",
                        novoCliente: "Novo Cliente",
                        obs: "Observação",
                        pacienteCliente: "Paciente Cliente",
                        produto: "Produto",
                        alergias: "Alergias",
                        condicao: "Condição",
                    },

                    list_vue_html: {
                        paciente: "Paciente",
                        pacientes: "Pacientes",
                        cadastrados: "Cadastrados",
                        visuPaciente: "Visualizar Paciente",
                    },

                    ver_vue_html: {
                        nome: "Nome:",
                        nomeRotulo: "Nome Rotulo:",
                        dtNasc: "Data de Nascimento:",
                        genero: "Genero:",
                        especie: "Espécie:",
                        peso: "Peso:",
                        cliente: "Cliente:",
                    },
                },

                pcp: {
                    pesquisar: "Pesquisar",
                    manipulacaoOrdem: {
                        pcpEdit: "PCP Manipulação - Edição",
                        manipulacaoOrdemCodigo: "Código Ordem de manipulação",
                        manipulacaoOrdemPCP: "Ordem de manipulação",
                        manipulacao: "Manipulação",
                        paciente: "Paciente",
                        codigoVenda: "Código Venda",
                        pesquisaAvancada: "Pesquisa Avançada",
                    },
                    venda: {
                        pcpEdit: "PCP Venda - Edição",
                        vendaCodigo: "Código da Venda",
                        vendaPCP: "Venda",
                        cliente: "Cliente",
                        valorTotal: "Valor Total da Venda",
                        quantidadeItens: "Quantidade Itens",
                    },
                    etapas: {
                        etapas: "Etapas",
                        etapaAtual: "Etapa Atual",
                        etapaDestino: "Etapa Destino",
                        motivoEtapaPCP: "Motivo",
                        observacao: "Observação",
                        dataInicio: "Data de início",
                        dataFim: "Data do fim",
                    },
                },

                pdv: {
                    edit_vue_html: {
                        pdvEdit: "Pdv - Edição",
                        descricao: "Descrição",
                        saldoInicial: "Saldo Inicial",
                        contaCorrente: "Conta Corrente",
                        gerarSaldoInicial: "Gerar (Financeiro) Saldo Inicial",
                        formaRecebimento: "Forma Recebimento de Transferência",
                        contaCorrenteInterna: "Contas correntes para vínculo com PDV somente podem ser do tipo interna",
                    },

                    list_vue_html: {
                        pdvs: "PDVs",
                        cadastrados: "Cadastrados",
                    },
                },

                posologia: {
                    edit_vue_html: {
                        posologiaEdit: "Posologia - Edição",
                        descricao: "Descrição",
                        dosesDia: "Doses por Dia",
                        dosesSemana: "Doses por Semana",
                        dosesMes: "Doses por Mês",
                        duracaoEstimDias: "Duração estimada (dias)",
                        padrao: "Padrão",
                        ativo: "Ativo",
                    },

                    list_vue_html: {
                        posologias: "Posologias",
                        cadastradas: "Cadastradas",
                    },
                },

                prescritor: {
                    edit_vue_html: {
                        precritorEdit: "Prescritor - Edição",
                        dadosGerais: "Dados Gerais",
                        cod: "Cód.",
                        numRegProfis: "Nº Registro Profissional",
                        cpf: "CPF",
                        rg: "RG",
                        ativo: "Ativo",
                        conselhoRegional: "Conselho Regional",
                        ufConselhoRegional: "UF Conselho Regional",
                        nome: "Nome",
                        dtNasc: "Data Nascimento",
                        genero: "Gênero",
                        comissao: "Comissão",
                        obs: "Observação",
                        especialidSelec: "Especialidades Selecionadas",
                        especialid: "Especialidade",
                        localiz: "Localização",
                        cep: "CEP",
                        estado: "Estado",
                        cidade: "Cidade",
                        endereco: "Endereço",
                        nro: "Número",
                        bairro: "Bairro",
                        complement: "Complemento",
                        contato: "Contato",
                        tel: "Telefone",
                        cel: "Celular",
                        comissionamento: {
                            titulo: "Comissionamento",
                            inicioFaixa: "Início Faixa",
                            fimFaixa: "Fim Faixa",
                            porcentagemComissao: "Porcentagem Comissão",
                        },
                        visitadoresNomes: "Visitadores",
                        classPrescritor: "Classificação do Prescritor",
                        sipeAgro: "SipeAgro",
                    },

                    list_vue_html: {
                        prescritores: "Prescritores",
                        cadastrados: "Cadastrados",
                        visuPrescritor: "Visualizar prescritor",
                    },

                    ver_vue_html: {
                        dadosGerais: "Dados Gerais",
                        codigo: "Código:",
                        numeroRegistro: "Número de Registro:",
                        cpf: "CPF:",
                        rg: "RG:",
                        nome: "Nome:",
                        dtNasc: "Data de Nascimento:",
                        genero: "Genero:",
                        obs: "Observação:",
                        localiz: "Localização",
                        cep: "CEP:",
                        estado: "Estado:",
                        cidade: "Cidade:",
                        endereco: "Endereço:",
                        nro: "Número:",
                        bairro: "Bairro:",
                        complement: "Complemento:",
                        contato: "Contato",
                        tel: "Telefone:",
                        cel: "Celular:",
                        especialids: "Especialidades",
                        especialid: "Especialidade:",
                        tipoConselhoRegional: "Conselho Regional:",
                        ufConselhoRegional: "UF do Conselho Regional:",
                    },
                },

                curvaABC: {
                    edit_vue_html: {
                        dataInicio: "Data inicial",
                        dataFim: "Data final",
                        considerar: "Considerar",
                        curvaABCProdutos: "Curva ABC Produtos",
                        curvaABCPrescritores: "Curva ABC Prescritores",
                        atualizar: "Atualizar",
                        sucesso: "Curva ABC atualizada com sucesso!",
                    },
                },

                produto: {
                    edit_vue_html: {
                        prodEdit: "Produto - Edição",
                        dadosGerais: "Dados Gerais",
                        codigoFranquia: "Código da Franquia",
                        codigoRede: "Código da Rede",
                        franquia: "Franquia",
                        descricao: "Descrição",
                        ativo: "Ativo",
                        grupAtivo: "Grupo Produto",
                        subgrupoProd: "SubGrupo Produto",
                        cores: "Cores",
                        codBarras: "Código de Barra",
                        tipoCalc: "Tipo Cálculo",
                        unMedManipu: "Unidade Medida Manipulação",
                        unMedEstq: "Unidade Medida Estoque",
                        naoControlEstq: "Não Controlar Estoque",
                        estqMin: "Estoque Mínimo",
                        estqMax: "Estoque Máximo",
                        posologia: "Posologia",
                        editPosologia: "Editar Posologia",
                        novaPosologia: "Nova Posologia",
                        tipoObs: "Tipo de Observação",
                        obs: "Observação",
                        observacoes: "Observações",
                        financeiro: "Financeiro",
                        vlrCusto: "Valor de Custo",
                        custoRefe: "Custo de Referência",
                        custoMed: "Custo Médio",
                        markupPorcent: "Markup (%)",
                        markupPadrao: "Markup franquia",
                        markupPadraoToolTip:
                            "Ao selecionar Markup da franquia, o sistema irá considerar o Markup padrão configurado na franquia para calcular o valor de venda",
                        markup: "Markup",
                        vlrVenda: "Valor de Venda",
                        nbmSH: "NBM/SH",
                        ncmSH: "NCM/SH",
                        cest: "CEST",
                        visuLogVlrs: "Visualizar Log Valores",
                        manipu: "Manipulação",
                        descrRotulo: "Descrição Rótulo",
                        excip: "Excipiente",
                        densidadeGml: "Densidade (g/ml)",
                        diluicao: "Diluição",
                        margemSegur: "Margem Segurança",
                        ftrCorrecao: "Fator Correção",
                        ftrUI: "Fator UI",
                        ftrUFC: "Fator UFC",
                        ftrUTR: "Fator UTR",
                        ftrEquivalencia: "Fator Equivalência",
                        ftrCorrecMolde: "Fator Correção Molde",
                        doseMax: "Dose Máxima",
                        doseMaxDiaria: "Dose Máxima Diária",
                        doseMaxPorcent: "Dose Máxima (%)",
                        dcb: "DCB",
                        dci: "DCI",
                        listControlados: "Lista de Controlados",
                        cas: "CAS",
                        pesoMolecular: "Peso molecular",
                        formulaMolecular: "Fórmula molecular",
                        prodDiluido: "Produto Diluído",
                        prodPuro: "Produto puro que originou o produto diluído",
                        doseMaxProdDiluido: "Dose Máxima do Produto Diluído",
                        indicaLimitDose:
                            "Dose máxima do produto diluido indica o limite da dose em que o sistema irá sugerir a troca do ativo puro para o diluido",
                        embalagem: "Embalagem",
                        siproquim: "SIPROQUIM",
                        policiaCivil: "Polícia Civil",
                        codigoSiproquim: "Código SIPROQUIM",
                        volMaxMl: "Volume Máximo(ml)",
                        kitEmbalagem: "Kit de Embalagem",
                        validades: "Validades",
                        validadeDias: "Validades em Dias",
                        capsula: "Cápsula",
                        tipoCapsula: "Tipo Cápsula",
                        gastrorre: "Gastrorresistente",
                        frmFarmaceu: "Forma Farmacêutica",
                        frmFarmaceuBloqueada: "Formas Farmacêuticas Bloqueadas",
                        libBloq: "Liberado",
                        associacoes: "Associações",
                        produto: "Produto",
                        desmembrar: "Desmembrar",
                        tipoCalculoAssociacao: "Tipo de cálculo",
                        percentual: "Percentual",
                        sinonimos: "Sinônimos",
                        descricaoRotulo: "Descrição do rótulo",
                        fatorEquivalencia: "Fator de equivalência",
                        qtdTotal: "Quantidade Total:",
                        visuLote: "Visualizar Lote",
                        incompatibilidade: "Produtos Incompatíveis",
                        embalagemIncompativeis: "Embalagens Incompatíveis",
                        produtoRefrigerado: "Produto Refrigerado",
                        produtoIncompativel: "Produto Incompatível",
                        kitEmbalagemIncompativel: "Kit Embalagem Incompatível",
                        formaFarmIncompativel: "Forma Farmacêutica Incompatível",
                        lote: "Lote",
                        fiscal: "Fiscal",
                        aliquotasInterestaduais: "Alíquotas Interestaduais",
                        uf: "UF",
                        aliquotaInterestadual: "Alíquota",
                        verAliquotasInterestaduais: "Ver alíquotas por UF",
                        fichaTecnica: "Ficha técnica",
                        usaMarkupDinamico: "Usa markup dinâmico",
                        markupDinamico: "Markup dinâmico",
                        quantidadeLimite: "Quantidade limite",
                        acao: "Ação",
                        trocarUnidadeMedidaEstoque: "Trocar unidade medida estoque",
                        trocar: "Trocar",
                        unidadeMedidaEstoqueIdOrigem: "Unidade Medida Estoque Atual",
                        unidadeMedidaEstoqueIdDestino: "Unidade Medida Estoque Destino",
                        corPadraoCapsula: "Cor padrão para cápsula",
                        naoUtilizarExcipiente: "Não utilizar excipiente",
                        corManipulacao: "Cor de identificação na manipulação",
                        classProduto: "Classificação do Produto",
                        fitoterapico: "Fitoterápico",
                        descricaoFiscal: "Descrição Fiscal",
                    },

                    list_vue_html: {
                        produtos: "Produtos",
                        cadastrados: "Cadastrados",
                        grupProduto: "Grupo Produto",
                        subgrupProd: "SubGrupo Produto",
                        corrigirVlrVenda: "Corrigir Valor Venda",
                        situacaoProduto: "Status Produto",
                    },

                    verLote_vue_html: {
                        dadosGerais: "Dados Gerais",
                        descricao: "Descrição",
                        fornecedor: "Fornecedor:",
                        produto: "Produto:",
                        qtdAtual: "Quantidade Atual:",
                        dtFabricacao: "Data Fabricação:",
                        dtValidade: "Data Validade:",
                        dataMovimentacao: "Data Movimentação",
                        quantidade: "Quantidade",
                        valorUnitario: "Valor Unitário",
                        movimentacao: "Movimentação",
                        ftrCorrecao: "Fator Correção:",
                        densidade: "Densidade:",
                        fatorUI: "Fator UI:",
                        fatorUFC: "Fator UFC:",
                        fatorUTR: "Fator UTR:",
                        vlrCusto: "Valor Custo:",
                        entrada: "Entrada",
                        saida: "Saída",
                        obs: "Observação:",
                    },

                    verProduto_vue_html: {
                        dadosGerais: "Dados Gerais",
                        descricao: "Descrição:",
                        descrRotulo: "Descrição Rótulo:",
                        unMedManipu: "Unidade Medida Manipulação:",
                        unMedEstq: "Unidade Medida Estoque:",
                        controlaEstq: "Controla Estoque:",
                        estqMin: "Estoque Mínimo:",
                        obs: "Observação:",
                        financeiro: "Financeiro",
                        vlrCusto: "Valor de Custo:",
                        custoRefe: "Custo de Referência:",
                        custoMedio: "Custo Médio:",
                        markupPorcent: "Markup (%):",
                        vlrVenda: "Valor de Venda:",
                        manipulacao: "Manipulação",
                        densidadeGml: "Densidade (g/ml):",
                        ftrCorrec: "Fator Correção:",
                        ftrUI: "Fator UI:",
                        ftrUFC: "Fator UFC:",
                        ftrUTR: "Fator UTR:",
                        doseMax: "Dose Máxima:",
                        doseMaxDia: "Dose Máxima Diária:",
                        doseMaxPorcent: "Dose Máxima %:",
                        embalagem: "Embalagem",
                        volMaxMl: "Volume Máximo (ml):",
                        associacoes: "Associações",
                        sinonimos: "Sinônimos",
                        qtdTotal: "Quantidade Total:",
                        visuLote: "Visualizar Lote",
                        capsulaCor: "Cápsulas Cores",
                        incompatibilidades: "Incompatibilidades",
                    },
                },

                produtodcb: {
                    edit_vue_html: {
                        dcbEdit: "DCB - Edição",
                        descricao: "Descrição",
                        codigo: "Código",
                        codigoCAS: "Código CAS",
                    },

                    list_vue_html: {
                        denomComunsBra: "Denominações Comuns Brasileiras",
                        dcbs: "DCBs",
                        cadastradas: "Cadastradas",
                    },
                },

                produtodci: {
                    edit_vue_html: {
                        dciEdit: "DCI - Edição",
                        descricao: "Descrição",
                        codigo: "Código",
                    },

                    list_vue_html: {
                        denomComunsInternac: "Denominações Comuns Internacionais",
                        dcis: "DCIs",
                        cadastradas: "Cadastradas",
                    },
                },

                produtologfinanceiro: {
                    list_vue_html: {
                        logFinanceprod: "Log de Financeiro do Produto",
                        logProdutos: "Log de Produtos",
                        alterados: "Alterados",
                        valoresAtuais: "Valores Atuais",
                        valorCusto: "Valor de Custo",
                        custoRefe: "Custo de Referência",
                        custoMedio: "Custo Médio",
                        markupPorce: "Markup(%)",
                        markup: "markup",
                        vlrsVenda: "Valor de Venda",
                        vlrsLogAnterior: "Valores Log Anteriores",
                        logAlteCamposFinanceProd: "Log de Alteração de campos Financeiros Produto",
                        dataUltimaAlteracao: "Data da última alteração",
                        usuarioUltimaAlteracao: "Usuário última alteração",
                    },
                },

                produtolote: {
                    edit_vue_html: {
                        loteProdEdit: "Lote de Produto - Edição",
                        descricao: "Descrição",
                        fornecedor: "Fornecedor",
                        produto: "Produto",
                        qtdAtual: "Quantidade Atual",
                        quantidadeComprometida: "Quantidade Comprometida",
                        dataFab: "Data Fabricação",
                        dataVal: "Data Validade",
                        sequencialCompra: "Sequência da compra",
                        ftrCorrecao: "Fator Correção",
                        densidadeGml: "Densidade (g/ml)",
                        fatorUI: "Fator UI",
                        fatorUFC: "Fator UFC",
                        fatorUTR: "Fator UTR",
                        vlrCusto: "Valor de Custo",
                        obs: "Observação",
                        preenchLote: "Preencher Lote",
                        imprimirRotulo: "Imprimir Rótulo",
                        estoqueComprometido: "Vendas Comprometendo Estoque do Lote",
                        movimentosEstoque: "Movimentação de Estoque do Lote",
                        campoNull:
                            "Caso informado valor neste campo mesmo que 0, será considerado na manipulação. Se deseja utilizar o valor cadastrado no produto, deixar o campo sem nenhum valor",
                        conservacao: "Conservação",
                        statusProdutoLote: "Status do Lote",
                        origemProdutoLote: "Origem do Lote",
                        descricaoFornecedor: "Lote do Fornecedor",
                        fracionamento: "Fracionamento",
                        imprimirOrdemFracionamento: "Imprimir ordem de fracionamento",
                        lotesFracionados: "Lotes Fracionados",
                        atualizarDadosOrdemFracionamento: "Atualização de dados da Ordem de Fracionamento",
                        estoques: "Estoque",
                        dadosNotaEntradaLote: "Dados da nota de entrada do lote",
                        retornarLoteParaFracionamento: "Retornar lote para fracionamento",
                        anexarLote: "Anexar arquivo no Lote",
                        uploadAnexo: "Upload Anexo",
                        nomeArqAnexoLote: "Nome do arquivo do anexo",
                    },

                    list_vue_html: {
                        lotesProduto: "Lotes de Produto",
                        cadastrados: "Cadastrados",
                        statusProdutoLote: "Status do Lote",
                        origemProdutoLote: "Origem do Lote",
                        tipoEstoque: "Estoque",
                        ignorarZerados: "Ignorar Zerados",
                    },

                    atualizarStatus_vue_html: {
                        atualizar: "Atualizar",
                        newStatusProdutoLote: "Novo status do lote",
                        atualizadoSucesso: "Status do lote Atualizado com Sucesso",
                    },

                    fracionamento_vue_html: {
                        quantidade: "Quantidade",
                        quantidadeEmbalagem: "Quantidade Kit Embalagem",
                        volume: "Volume",
                        unidadeMedida: "Unidade Medida",
                        total: "Total",
                        quantidadeFracionada: "Quantidade Fracionada",
                        quantidadePerda: "Quantidade de Perda",
                        quantidadeTotal: "Quantidade Total",
                        fracionar: "Fracionar",
                        sucessoFracionamento: "Fracionamento gerado com sucesso",
                        temperatura: "Temperatura",
                        umidade: "Umidade",
                        salaFracionamento: "Sala de Fracionamento",
                        quantidadeEfetivamenteFracionada: "Quantidade efetivamente fracionada",
                        quantidadeRetiradaAmostragem: "Quantidade Retirada para Amostragem",
                        horaInicio: "Data de Início",
                        horaFim: "Data do Fim",
                        embalagensNaoUtilizadas: "Embalagens não utilizadas",
                        observacao: "Observação",
                        fracionamento: "Fracionamento",
                    },

                    fracionamentoKitEmbalagem_vue_html: {
                        kitEmbalagem: "Kit de embalagem",
                        embalagem: "Embalagem",
                        embalagemLote: "Lote",
                        quantidade: "Quantidade total",
                    },

                    dadosNotaEntradaLote_vue_html: {
                        dataInclusaoNota: "Data de inclusão da nota",
                        dataEmissaoNota: "Data de emissão da nota",
                        numeroNota: "Número",
                        serieNota: "Série",
                        quantidadeItemRespectivoLotea: "Quantidade do item respectivo ao lote",
                        notaDeCompraNaoEncontradaNoSitema: "Nota de compra do lote não encontrada no sistema",
                    },
                },

                racaAnimal: {
                    list_vue_html: {
                        titulo: "Raças dos Animais",
                        cadastradas: "Cadastradas",
                    },

                    edit_vue_html: {
                        titulo: "Cadastro de Raças dos Animais",
                        descricao: "Descrição",
                        especieAnimal: "Espécie Animal",
                    },
                },

                recebimentoParcelado: {
                    edit_vue_html: {
                        recebimentoParceladoEdit: "Métodos de recebimento parcelado - Edição",
                        numeroParcelas: "Número de parcelas",
                        addParcela: "Adicionar parcelas",
                        descricao: "Descrição",
                        ativo: "Ativo",
                    },

                    list_vue_html: {
                        recebimentoParcelado: "Métodos de recebimento parcelado",
                        cadastrados: "Cadastrados",
                    },
                },

                recompensa: {
                    edit_vue_html: {
                        recompensaEdit: "Recompensa - Edição",
                        premio: "Prêmio",
                        pontosNecess: "Pontos Necessários",
                        ativo: "Ativo",
                    },

                    list_vue_html: {
                        campanhaRecompensas: "Campanha: Recompensas",
                        recompensas: "Recompensas",
                        cadastradas: "Cadastradas",
                    },
                },

                rede: {
                    edit_vue_html: {
                        redeEdit: "Rede - Edição",
                        dadosGerais: "Dados Gerais",
                        nomeFantasia: "Nome Fantasia",
                        razaoSocial: "Razão Social",
                        cnpj: "CNPJ",
                        ie: "Insc. Estadual",
                        im: "Insc. Municipal",
                        respTecnico: "Responsável Técnico",
                        conseRegional: "Conselho Regional",
                        nroCR: "Numero CR",
                        crf: "CRF",
                        crmv: "CRMV",
                        crm: "CRM",
                        areaAtuacao: "Área de Atuação",
                        naturOperacao: "Natureza de Operação",
                        optanteSimplNacio: "Optante pelo Simples Nacional",
                        incentivCultuta: "Incentivador Cultural",
                        localizacao: "Localização",
                        cep: "CEP",
                        estado: "Estado",
                        cidade: "Cidade",
                        lograd: "Logradouro",
                        complemen: "Complemento",
                        nro: "Número",
                        bairro: "Bairro",
                        contato: "Contato",
                        tel: "Telefone",
                        cel: "Celular",
                        gerarBaseModel: "Gerar base Modelo",
                        usoDoAppPor: "Uso do App por",
                    },

                    list_vue_html: {
                        redes: "Redes",
                        cadastradas: "Cadastradas",
                    },
                },

                rotulopersonalizado: {
                    edit_vue_html: {
                        rotuloEdit: "Rotulo - Edição",
                        nome: "Nome",
                        tipoRotulo: "Tipo do Rótulo",
                        rotuloUtilizar: "Rotulo a utilizar",
                        largura: "Largura",
                        altura: "Altura",
                        espacamentoSuperior: "Espaçamento Superior",
                        espacHoriz: "Espaçamento Horizontal",
                        posicQRCode: "Posição QR Code/Cód. Barras",
                        larguraCode: "Largura do QR Code/Cód. Barras",
                        alturaCode: "Altura do QR Code/Cód. Barras",
                        posLogo: "Posição Logo",
                        largLogo: "Largura do Logo",
                        altLogo: "Altura do logo",
                        printDadosFranq: "Imprimir dados da Franquia",
                        printDadosFranqNegrito: "Dados da Franquia em Negrito",
                        dadosFranquiaRespTec: "Dados franquia e Responsavel tecnico",
                        fonteDadosFranq: "Fonte dados Franquia",
                        alinhamentoDadosDaFranquia: "Alinhamento dados Franquia",
                        quantidadeColunas: "Quantidade máxima de colunas",
                        fonteRotulo: "Fonte do Rótulo",
                        paddingLine: "Espaçamento entre linhas",
                        camposImpressao: "Campos para Impressão",
                        formatoImpressao: "Formato da Impressão",
                        imprimirResponsavelTecnico: "Imprimir Responsável Técnico",
                    },

                    imprimirRotuloComModal_vue_html: {
                        rotuloPersonalizado: "Rotulo Personalizado",
                        imprimir: "Imprimir",
                    },

                    list_vue_html: {
                        rotulos: "Rótulos",
                        cadastrados: "Cadastrados",
                    },
                },

                separacao: {
                    edit_vue_html: {
                        separacaoVenda: "Separação da Venda ",
                        salvarEConcluir: "Salvar e Concluir",
                        botaoCodigo: "Separar",
                        labelCodigo: "Código do Lote",
                        title: "Informe Código do Lote",
                    },

                    list_vue_html: {
                        fracionamento: "Fracionamento",
                        vendas: "Vendas",
                        cadastrados: "Cadastrados",
                        setor: "Setor",
                    },
                },

                servico: {
                    edit_vue_html: {
                        servicoEdit: "Serviço - Edição",
                        descricao: "Descrição",
                        servico: "Serviço",
                        detalheServico: "Detalhe do Serviço",
                        atividade: "Atividade",
                        utilizCodTributPrefeitura:
                            "Utilize o código de tributação do serviço fornecido pela prefeitura",
                        codServicLC: "Cód. Serviço LC 116/2003",
                        utilizCodLCPrefeitura: "Utilize o código do item LC fornecido pela prefeitura",
                        aliquota: "Alíquota",
                        issRetido: "ISS Retido",
                    },

                    list_vue_html: {
                        servicos: "Serviços",
                        cadastrados: "Cadastrados",
                    },
                },

                sngpcManual: {
                    edit_vue_html: {
                        formularioSngpcRelat: "FORMULÁRIO SNGPC - RELATÓRIO",
                        identificacao: "Identificação",
                        razaoSocial: "Razão Social",
                        endereco: "Endereço",
                        nro: "Número",
                        bairro: "Bairro",
                        cidade: "Cidade",
                        tel: "Telefone",
                        cnpj: "CNPJ",
                        nroAutorizAnvisa: "Nº Autorização ANVISA",
                        nroAutorizMapa: "Nº Autorização MAPA",
                        periodo: "Período",
                        tipoBalanco: "Tipo de Balanço",
                        periodicBalanco: "Periodicidade do Balanço",
                        ano: "Ano",
                        regiao: "Região",
                        respTecnico: "Responsável Técnico",
                        nroCR: "Número CR",
                        crf: "CRF",
                        crmv: "CRMV",
                        crm: "CRM",
                        data: "Data",
                        download: "Baixar Arquivo",
                    },
                },

                tela: {
                    edit_vue_html: {
                        telaEdit: "Tela - Edição",
                        chaveTraducao: "Chave da Tradução",
                        rota: "Rota",
                        iconeMenu: "Icone Menu",
                        rotaAcaoRapida: "Rota Ação Rápida",
                        iconeAcaoRapida: "Icone Ação Rápida",
                        controller: "Controller",
                        ordem: "Ordem",
                        pai: "Pai",
                        ocultarMenu: "Ocultar Menu",
                        ativo: "Ativo",
                    },

                    list_vue_html: {
                        telas: "Telas",
                        cadastradas: "Cadastradas",
                    },
                },

                telaacao: {
                    edit_vue_html: {
                        acaoTelaEdit: "Ação da tela - Edição",
                        descricao: "Descrição",
                        tela: "Tela",
                        acaoController: "Ação Controller",
                        acaoVue: "Ação Vue",
                        ativo: "Ativo",
                    },

                    list_vue_html: {
                        acoesTelas: "Ações das telas",
                        acoes: "Ações",
                        cadastradas: "Cadastradas",
                    },
                },

                transportador: {
                    edit_vue_html: {
                        transportadorEdit: "Transportador - Edição",
                        dadosGerais: "Dados Gerais",
                        cpf: "CPF",
                        rg: "RG",
                        ie: "Insc. Estadual",
                        clienteIsentoEmBranco: "Para clientes isentos deixe em branco",
                        nome: "Nome",
                        nomeFantasia: "Nome fantasia",
                        razaoSocial: "Razão Social",
                        im: "Insc. Municipal",
                        dtNasc: "Data Nascimento",
                        genero: "Gênero",
                        orgaoExpedidor: "Orgão Expedidor",
                        ufExpedidor: "UF Expedidor",
                        obs: "Observação",
                        transportadora: "Transportadora",
                        localizacao: "Localização",
                        cep: "CEP",
                        estado: "Estado",
                        cidade: "Cidade",
                        endereco: "Endereço",
                        nro: "Número",
                        bairro: "Bairro",
                        complemento: "Complemento",
                        contato: "Contato",
                        tel: "Telefone",
                        cel: "Celular",
                    },

                    list_vue_html: {
                        transportadores: "Transportadores",
                        cadastrados: "Cadastrados",
                    },
                },

                transportadora: {
                    edit_vue_html: {
                        transportadoraEdit: "Transportadora - Edição",
                        dadosGerais: "Dados Gerais",
                        tipo: "Tipo",
                        cpf: "CPF",
                        rg: "RG",
                        cnpj: "CNPJ",
                        ie: "Insc. Estadual",
                        clienteIsentoEmBranco: "Para clientes isentos deixe em branco",
                        nome: "Nome",
                        nomeFantasia: "Nome fantasia",
                        razaoSocial: "Razão Social",
                        im: "Insc. Municipal",
                        dtNasc: "Data Nascimento",
                        genero: "Gênero",
                        orgaoExpedidor: "Orgão Expedidor",
                        ufExpedidor: "UF Expedidor",
                        obs: "Observação",
                        localizacao: "Localização",
                        cep: "CEP",
                        estado: "Estado",
                        cidade: "Cidade",
                        endereco: "Endereço",
                        nro: "Número",
                        bairro: "Bairro",
                        complemento: "Complemento",
                        contato: "Contato",
                        tel: "Telefone",
                        cel: "Celular",
                    },

                    list_vue_html: {
                        transportadoras: "Transportadoras",
                        cadastrados: "Cadastrados",
                    },
                },

                compras: {
                    edit_vue_html: {
                        consolidar: "Consolidar",
                        compraCod: "Compra Código ",
                        compraEdit: "Compra - Edição",
                        edit: " - Edição",
                        dataInicial: "Data Inicial das Vendas",
                        dataFinal: "Data Final das Vendas",
                        dias: "Dias de cobertura",
                        margemTolerancia: "Margem de Tolerância",
                        margemToleranciaTooltip:
                            "A margem de tolerância será utilizada para o sistema identificar se a quantidade presente no estoque atual vai cobrir a quantidade calculada para o consumo no período selecionado. Por exemplo se o estoque atual é 13 e a quantidade de compra é 10, com uma tolerância de 20% o sistema irá considerar que a quantidade necessário é 12 ou seja o estoque atual vai cobrir a demanda. Quanto maior a tolerância mais folgado será o estoque.",
                        curvaABC: "Curva ABC",
                        ultFornecedores: "Últimos Fornecedores",
                        carregarFornecedores: "Carregar fornecedores da última compra realizada no sistema",
                    },
                    list_vue_html: {
                        compras: "Compras",
                    },
                    cotacao_vue_html: {
                        cotacaoEdit: "Cotação - Edição",
                        compraItens: "Itens da Compra",
                    },
                    melhorCompra_vue_html: {
                        melhorCompra: "Melhor Compra",
                    },
                    cotacaoFornecedor_vue_html: {
                        cotacaoFornecedor: "Cotação Fornecedor",
                        enviarCotacao: "Enviar Cotação",
                    },
                },

                unidademedida: {
                    edit_vue_html: {
                        unMedidaEdit: "Unidade de Medida - Edição",
                        descricao: "Descrição",
                        sigla: "Sigla",
                        tipo: "Tipo",
                        conversao: "Conversão",
                        operador: "Operador",
                        valor: "Valor",
                    },

                    list_vue_html: {
                        unMedida: "Unidades de Medida",
                        cadastradas: "Cadastradas",
                    },
                },

                usuario: {
                    edit_vue_html: {
                        usuarioEdit: "Usuário - Edição",
                        dadosGerais: "Dados Gerais",
                        nomeCompleto: "Nome Completo",
                        ativo: "Ativo",
                        supervisor: "Supervisor",
                        cpf: "CPF",
                        perfil: "Perfil",
                        beneficio: "Benefício",
                        permissoes: "Permissões",
                        permissoesRemovidas: "Remover Permissões",
                        acoes: "Ações",
                        grupos: "Grupos",
                        franquias: "Franquias",
                        franquia: "Franquia",
                        franquiaPadrao: "Franquia Padrão",
                        padrao: "Padrão",
                        colaborador: "Colaborador",
                        resetarSenha: "Resetar senha do usuário",
                        franquiaDrogavet: "Franquias Drogavet",
                        atendimento: "Atendimento",
                        atendente: "Atendente",
                    },

                    list_vue_html: {
                        usuarios: "Usuários",
                        cadastrados: "Cadastrados",
                    },
                },

                vendaContinuo: {
                    list_vue_html: {
                        orcar: "Orçar",
                        relatContinuos: "Relatório de Continuos",
                        dataInicio: "Data Inicio Validade",
                        dataFinal: "Data Final Validade",
                    },
                },

                veterinario: {
                    view_vue_html: {
                        veterinario: "Veterinário",
                        ano: "Ano",
                        tipoPeriodo: "Tipo de Período",
                        mes: "Mês",
                        autorizacao: "Autorização",
                    },
                },

                visitador: {
                    edit_vue_html: {
                        visitadorEdit: "Visitador - Edição",
                        dadosGerais: "Dados Gerais",
                        tipo: "Tipo",
                        cpf: "CPF",
                        rg: "RG",
                        cnpj: "CNPJ",
                        ie: "Insc. Estadual",
                        clientIsentoBranco: "Para clientes isentos deixe em branco",
                        nome: "Nome",
                        nomeFantasia: "Nome fantasia",
                        razaoSocial: "Razão Social",
                        im: "Insc. Municipal",
                        dtNasc: "Data Nascimento",
                        genero: "Gênero",
                        orgaoExpedidor: "Orgão Expedidor",
                        ufExpedidor: "UF Expedidor",
                        comissao: "Comissão",
                        obs: "Observação",
                        localizacao: "Localização",
                        cep: "CEP",
                        estado: "Estado",
                        cidade: "Cidade",
                        endereco: "Endereço",
                        nro: "Número",
                        bairro: "Bairro",
                        complemen: "Complemento",
                        contato: "Contato",
                        tel: "Telefone",
                        cel: "Celular",
                        prescritores: "Prescritores",
                        prescritor: "Prescritor",
                        editPrescritor: "Editar Prescritor",
                        novoPrescritor: "Novo Prescritor",
                    },

                    list_vue_html: {
                        visitadores: "visitadores",
                        cadastrados: "Cadastrados",
                    },
                },

                colaborador: {
                    edit_vue_html: {
                        colaboradorEdicao: "Colaborador - Edição",
                        dadosGerais: "Dados Gerais",
                        cpf: "CPF",
                        rg: "RG",
                        nome: "Nome",
                        franquia: "Franquia",
                        dataNasc: "Data de nascimento",
                        genero: "Gênero",
                        orgaoExpeditor: "Orgão expeditor",
                        ufExpeditor: "UF Expeditor",
                        obs: "Observação",
                        localizacao: "Localização",
                        cep: "CEP",
                        estado: "Estado",
                        cidade: "Cidade",
                        endereco: "Endereço",
                        nro: "Numero",
                        bairro: "Bairro",
                        complemento: "Complemento",
                        contato: "Contato",
                        telefone: "Telefone",
                        celular: "Celular",
                        beneficio: "Beneficio",
                        vendedorDotz: "Vendedor Dotz",
                    },
                    list_vue_html: {
                        colaboradores: "Colaboradores",
                        cadastrados: "Cadastrados",
                    },
                },

                configuracaodotz: {
                    edit_vue_html: {
                        tituloPagina: "Configuracao Dotz - Edição",
                        qtdMinVendas: "Quantidade mínima de fórmulas",
                        porcMaxDesconto: "Porcentagem máxima de desconto",
                        nomeLoja: "Nome da Loja",
                        tlpStoreCode: "Store Code",
                        codigoMecanica: "Código da Mecânica",
                        tlpOfferCode: "Offer Code",
                        diaEnvioPontuacao: "Dias úteis após o início do mês para Envio da Pontuação",
                        utilizaFaixasRede: "Utiliza Faixas da Rede",
                        envioInstantaneo: "Envio Instantâneo",
                        faixasTitulo: "Faixas",
                        filtros: "Filtros",
                        inicioFaixa: "Inicio da Faixa",
                        fimFaixa: "Fim da Faixa",
                        multiplicador: "Multiplicador",
                        tlpMultiplicador: "Este valor será multiplicado pelo valor total das vendas",
                        faixaInicioGrid: "Faixa Inicio",
                        faixaFimGrid: "Faixa Fim",
                        naoExistemFaixasPadroes: "Não existem faixas padrões cadastradas na rede.",
                        nenhumaFaixaCadastrada: "Nenhuma faixa cadastrada para essa franquia.",
                        valorInicialMaiorFinal: "Valor inicial da faixa é superior ao valor final.",
                        faixaSobreposta: "Faixa cadastrada está sobreposta a outra faixa.",
                        documentoFuncionario: "Documento do funcionário",
                        tlpDiaEnvioPontuacao:
                            "Será considerado a quantidade de dias uteis após o início do mês, se informado 1 será o primeiro dia util do mês para o Envio da Pontuação.",
                        tlpUtilizaFaixasRede:
                            "Ao indicar que usa faixas irá considerar as faixas cadastradas na franquia Modelo.",
                        tlpDocumentoFuncionario:
                            "Documento cadastrado na Dotz para vinculo da pontuação. Caso o documento não esteja cadastrado na Dotz ocorrera erro no crédito da pontuação. (Deve ser o documento do franqueado)",
                        franquiaEnvio: "Franquia de envio",
                        franquiaEnvioTooltip:
                            "Franquia que irá receber a pontuação. Ao informar a franquia o sistema não irá mais enviar as pontuações para a franquia, e sim consolidar na franquia selecionada!",
                        enviarArquivosVendedores: "Enviar arquivos dos vendedores",
                        pontuacaoDotz: "Pontuação Dotz",
                        enviadoArquivosVendedores: "Enviado o arquivo dos vendedores com sucesso!",
                        tipoEnvio: "Tipo Envio",
                        considerarVendasPor: "Considerar vendas por:",
                    },
                },
            },

            dashboard: {
                charts: {
                    pedidos_vue_html: {
                        pedidosHoje: "Pedidos de hoje",
                    },

                    previsaoRoyalties_vue_html: {
                        prevRoyaltiesMes: "Previsão de Royalties do mês",
                    },

                    vendas_vue_html: {
                        vendasHoje: "Vendas de hoje",
                    },

                    vendasAnual_vue_html: {
                        vendasNesteAno: "Vendas neste ano",
                    },

                    vendasMensal_vue_html: {
                        vendasNesteMes: "Vendas neste mês",
                    },
                },

                evolucaoVendas_vue_html: {
                    valorVendasMes: "Valor Total das Vendas no período selecionado",
                    qtdeVendasMes: "Vendas Realizadas no período selecionado",
                    novosClientesMes: "Novos Clientes no período selecionado",
                    franquias: "Franquias",
                    limpar: "Limpar",
                    todas: "Todas",
                    taxaEntrega: "Considerar Taxa de Entrega",
                },

                manipulacoes_vue_html: {
                    valorTotalVendas: "Valor Total de Vendas",
                    qtdFormulas: "Quantidade Total de Fórmulas",
                    qtdVendas: "Quantidade de vendas",
                    ticketMedioFormula: "Ticket Médio por formula",
                    ticketMedioVenda: "Ticket Médio por Venda",
                    dataInicial: "Data Inicial",
                    dataFinal: "Data Final",
                    franquias: "Franquias",
                },

                totaisPorCategoria_vue_html: {
                    dataInicial: "Data Inicial",
                    dataFinal: "Data Final",
                    franquias: "Franquias",
                },
            },

            fichaTecnica: {
                edit_vue_html: {
                    dadosGerais: "Dados Gerais",
                    catTerapeu: "Categoria Terapêutica",
                    conserv: "Conservação",
                    refe: "Referência (Farmacopéia)",
                    ensaios: "Ensaios",
                    soludibilidade: "Solubilidade",
                    dadosAdicionais: "Dados Adicionais",
                    novoDadoAdicional: "Novo Dado Adicional",
                    obss: "Observações",
                },
            },

            fluxo: {
                list_vue_html: {
                    aReceber: "A Receber",
                    aPagar: "A Pagar",
                    saldoFinal: "Saldo Final",
                    fluxoCaixa: "Fluxo de Caixa",
                    metaMensal: "Meta Mensal: ",
                    maiorMensal: "Maior Mensal: ",
                    totalAnual: "Total Anual: ",
                    receita: "Receita",
                    despesa: "Despesa",
                    meta: "Meta",
                },
            },

            fracionaEstoque: {
                kitEmbalagens_vue_html: {
                    embalagem: "Embalagem",
                    lote: "Lote",
                    qtd: "Quantidade",
                    total: "Total",
                    acao: "Ação",
                },
            },

            gradePCP: {
                view_vue_html: {
                    gradePCP: "Grade PCP",
                    producao: "Produção - Seg a Sex / Final de Semana",
                    capacidade: "Capacidade",
                    vendas: "Vendas",
                    pendente: "Pendente",
                    dataInicial: "Data Inicial",
                    dataFinal: "Data Final",
                    formasFarmacIgnorar: "Formas Farmacêuticas a ignorar",
                    oSistemaDesconsiFormasSelec:
                        "O sistema vai desconsiderar da grade as formas farmacêuticas selecionadas",
                    tipoVendaAIgnorar: "Tipo de venda a ignorar",
                    oSistemaDesconsiTiposVendasSelec:
                        "O sistema vai desconsiderar da grade os tipos de vendas selecionadas",
                },
            },

            historico: {
                vendaHistorico: {
                    list_vue_html: {
                        historicoVendas: "Histórico de Vendas",
                        vendaHistorico: "Venda Histórico",
                        tudo: "Tudo",
                        contiuo: "Contínuo",
                        dtVenda: "Dt Venda:",
                        visuVenda: "Visualizar Venda",
                    },

                    ver_vue_html: {
                        dadosGerais: "Dados Gerais",
                        cliente: "Cliente:",
                        cpf: "CPF:",
                        dtNasc: "Data Nasc.:",
                        cel: "Celular:",
                        tel: "Telefone:",
                        vlrTotal: "Valor Total:",
                        dtVenda: "Data Venda:",
                        obs: "Obs:",
                        itensAcabados: "Itens Acabados",
                        produto: "Produto:",
                        qtd: "Qtde:",
                        vlr: "Valor:",
                        prescritor: "Prescritor:",
                        paciente: "Paciente:",
                        itensManipu: "Itens Manipulados",
                        formaFarm: "Forma Farm.:",
                        qtdVol: "Qtde/Vol.:",
                        volBase: "Vol. Base:",
                        unMed: "Unid. Med.:",
                        continuo: "Contínuo:",
                        embalagem: "Embalagem:",
                        capsula: "Cápsula:",
                        posologia: "Posologia:",
                        dose: "Dose",
                        tipoDose: "Tipo Dose",
                        lote: "Lote",
                        nr: "Número registro:",
                    },
                },
            },

            home: {
                dashboard: {
                    dashboard_vue_html: {
                        vendaAno: "Venda ano",
                        vendasMes: "Vendas por mês",
                        vendasDias: "Vendas dia",
                        pedidosDia: "Pedidos dia",
                    },
                },

                fastaction: {
                    continuos: {
                        continuos_vue_html: {
                            continuos: "Continuos",
                            venc: "/ Venc.: ",
                            arqAvisoContinuo: "Arquivar aviso deste contínuo",
                            adiarVencContinuo: "Adiar vencimento deste contínuo",
                            visuUltimaVendaOrcContinuo: "Visualizar a última venda/orçamento deste contínuo",
                            gerarOrcamentWpp: "Gerar Orçamento e montar Whats para envio",
                            verMais: "Ver mais",
                        },
                    },

                    expedicao: {
                        expedicao_vue_html: {
                            expedicao: "Expedicao",
                            cod: "Cód: ",
                            aprovacao: "/ Aprovação.: ",
                            adicionarInfromacao: "Adicionar informação da expedição",
                            finalizarExpedicao: "Finalizar expedição",
                            finalizar: "Finalizar",
                            verMais: "Ver mais",
                            visualizarVenda: "Visualizar Venda",
                        },
                    },

                    financeiro: {
                        financeiro_vue_html: {
                            sldInicialCaixa: "Saldo Inicial do Caixa: ",
                            sldAtualCaixa: "Saldo Atual do Caixa: ",
                            dinheiro: "Dinheiro",
                            boleto: "Boleto",
                            transferecia: "Transferência",
                            debito: "Débito",
                            credito: "Crédito",
                            cheque: "Cheque",
                            vlr: "Valor: ",
                            verMais: "Ver mais",
                            fechamCaixa: "Fechamento de Caixa",
                            abertCaixa: "Abertura de Caixa",
                            gerarReforco: "Gerar Reforço",
                            gerarSangria: "Gerar Sangria",
                        },
                    },

                    manipulacao: {
                        manipulacao_vue_html: {
                            emProcesso: "Em processo",
                            verificados: "Verificados",
                            formaFarm: "/ Forma Farm.: ",
                            paciente: "/ Paciente: ",
                            codVenda: "/ Cód. Venda:",
                            formula: "/ Formula: ",
                            imprimirRotulo: "Imprimir Rótulo",
                            imprimirManip: "Imprimir Manipulação",
                            confLotesEstqManipu: "Conferir Lotes/Estoque Manipulação",
                            visuManipu: "Visualizar Manipulação",
                            conclManipu: "Concluir Manipulação",
                            verMais: "Ver mais",
                            manipuFrmlPadrao: "Manipulação de Formula Padrão",
                            manipulacoesVenda: "Manipulações de Venda",
                            novaManipu: "Nova Manipulação",
                            confirmarAlte: "Confirmar Alterações",
                            manipulacao: "Manipulacao",
                            receita: "Exibir receitas da venda",
                            historicomanipulacao: "Histórico da manipulação",
                        },
                    },

                    orcamentos: {
                        orcamentos_vue_html: {
                            orcadosHoje: "Orçados hoje: ",
                            orcamentosCriadosHj: "Orçamentos criados hoje: ",
                            orcamentoVenc: "Orçamento Vencido",
                            orcamentoAVenc: "Orçamento a Vencer",
                            orcamentoEmDia: "Orçamento em Dia",
                            cod: "Cód: ",
                            vencOrcamento: "Venc. do Orçamento: ",
                            ativos: "Ativos:",
                            arquivarRejeiOrcamento: "Arquivar/rejeitar Orçamento",
                            adiarValDesteOrcamento: "Adiar validade deste Orçamento",
                            compartilharOrcamento: "Compartilhar orçamento",
                            visuOrcamento: "Visualizar Orçamento",
                            aprovOrcamento: "Aprovar Orçamento",
                            verMais: "Ver mais",
                            clientes: "Clientes",
                            orcamentos: "Orçamentos",
                            novoOrcamento: "Novo Orçamento",
                            confirmarAlte: "Confirmar Alterações",
                        },
                    },

                    produtos: {
                        list_vue_html: {
                            relatorioSituaEstq: "Relatório de Situação de Estoque",
                            relatorioEstqMin: "Relatório de Estoque de Mínimo",
                        },

                        produtos_vue_html: {
                            prodSemEstq: "Produto Sem Estoque",
                            prodVencendo: "Produtos Vencendo",
                            estqBaixo: "Estoque Baixo",
                            produto: "Produto: ",
                            lote: "Lote:",
                            val: "Validade:",
                            verMais: "Ver mais",
                            produtos: "Produtos",
                            lotes: "Lotes",
                            novoProd: "Novo Produto",
                        },
                    },

                    vendas: {
                        list_vue_html: {
                            relatorioVendClient: "Relatório de Venda por Cliente",
                            setor: "Setor",
                            situacao: "Situação",
                            dtInicEntrega: "Data Inicial da Entrega",
                            dtFinalEntrega: "Data Final da Entrega",
                        },

                        vendas_vue_html: {
                            vendidosHj: "Vendidos hoje: ",
                            vendasAprovHj: "Vendas Aprovadas hoje: ",
                            vendasAprovadas: "Vendas Aprovadas",
                            semFaturamento: "Vendas Sem Faturamento",
                            envioNFPend: "Vendas sem Nota Fiscal",
                            agRetornoPrefe: "Vendas com Nota Fiscal Pendente na Prefeitura",
                            cod: "Cód: ",
                            dtAprov: "Data de Aprovação: ",
                            dtHrEnvio: "Data Hora de Envio: ",
                            ativos: "Ativos:",
                            cancelarVenda: "Cancelar Venda",
                            imprimirVenda: "Imprimir Venda",
                            visuVenda: "Visualizar Venda",
                            faturarVenda: "Faturar Venda",
                            emitirNF: "Emitir NF",
                            verMais: "Ver mais",
                            novoCliente: "Novo cliente",
                            vendas: "Vendas",
                            novoOrcamento: "Novo Orçamento",
                            novoPgto: "Novo Pagamento",
                            pagamentos: "Pagamentos",
                        },
                    },

                    fastaction_vue_html: {
                        orcamentos: "Orçamentos",
                        manipulacao: "Manipulação",
                        vendas: "Vendas",
                        continuos: "Contínuos",
                        financeiro: "Financeiro",
                        produtos: "Produtos",
                        atendimento: "Atendimento",
                        estoque: "Estoque",
                        expedicao: "Expedição",
                        fracionamento: "Fracionam.",
                    },

                    entregas: {
                        entregas_vue_html: {
                            dataHoraEntrega: "Data/Hora Entrega:",
                            local: "Local:",
                            visualizarVenda: "Visualizar venda",
                            entregas: "Entregas",
                            imprimirRomaneio: "Imprimir romaneios",
                            postagens: "Postagens",
                        },
                    },
                },
            },

            importacaonfe: {
                contapagar_vue_html: {
                    contaGerenc: "Conta Gerencial",
                    dtVenc: "Data vencimento",
                    financeiro: "Financeiro",
                    parcela: "Parcela",
                    numeracaoDuplic: "Numeração Duplicata",
                    dtDeVenc: "Data de Vencimento: ",
                    vlrDuplic: "Valor da Duplicata: ",
                    status: "Status",
                    dadosPgto: "Dados de pagamento",
                    dtPgto: "Data de Pagamento: ",
                    formaPgto: "Forma de Pagamento: ",
                    contaCorrente: "Conta Corrente: ",
                    faturamento: "Faturamento",
                    vlrLiquido: "Valor Liquido: ",
                },

                dadosgerais_vue_html: {
                    dadosGerais: "Dados Gerais",
                    chaveAcess: "Chave Acesso",
                    modelo: "Modelo",
                    serie: "Série",
                    nro: "Número",
                    versaoXML: "Versão XML",
                    vlrTotalNF: "Valor Total Nota Fiscal",
                    dtEmissao: "Data Emissão",
                    dtHrEntradaSaida: "Data e Hora da Entrada/Saída",
                },

                destinatario_vue_html: {
                    destinatario: "Destinatário",
                    cnpj: "CNPJ",
                    ie: "Insc. Estadual",
                    tel: "Telefone",
                    cpf: "CPF",
                    cep: "CEP",
                    pais: "País",
                    uf: "UF",
                    cidade: "Cidade",
                    endereco: "Endereço",
                    nro: "Número",
                    bairro: "Bairro",
                    complemen: "Complemento",
                },

                edit_vue_html: {
                    nfeImport: "NFe - Importação",
                    estqDestino: "Estoque destino",
                    atualizDadosFornecedor: "Atualizar dados do fornecedor",
                    gerarCntPagar: "Gerar conta a pagar",
                    fracionarLotes: "Fracionar lotes",
                    devolucao: "Nota de devolução",
                    devolucaoTooltip:
                        "Caso marcado nenhum lote será incluido no sistema apenas será movimentado os já existentes, as descrições de cada lote devem ser a mesmas que existem no sistema para que a devolução seja feita corretamente",
                    atualizVlrSeMarcado:
                        "Se marcado a opção e o valor unitário do item da nota estiver maior que o valor de custo referência do produto, o mesmo será atualizado para o valor oriundo da nota.",
                    atualizCustos: "Atualizar Custos",
                },

                emitente_vue_html: {
                    emitente: "Emitente",
                    nomeFantasia: "Nome Fantasia",
                    cnpj: "CNPJ",
                    ie: "Insc. Estadual",
                    tel: "Telefone",
                    cpf: "CPF",
                    cep: "CEP",
                    pais: "País",
                    uf: "UF",
                    cidade: "Cidade",
                    endereco: "Endereço",
                    nro: "Número",
                    bairro: "Bairro",
                    complemen: "Complemento",
                },

                produtoassociacao_vue_html: {
                    addNovo: "Adicionar Novo",
                },

                produtolotes_vue_html: {
                    tipo: "Tipo",
                    produto: "Produto",
                    ftrCorrecao: "Fator de Correção",
                    fc: "F.C.",
                    qtdUnMedOrig: "Qtd./ Un.Med. Orig.",
                    qtd: "Qtd.",
                    unMed: "Un.Med.",
                    qtdConvertida: "Qtd. Convertida",
                    custoUnit: "Custo Unitário",
                    custoRefeAtual: "Custo Referência Atual",
                    percentMarkupVenda: "Percentual Markup Venda (%)",
                    dadosLote: "Dados do Lote",
                    descricao: "Descrição",
                    dtFab: "Data de Fabricação",
                    dtVal: "Data de Validade",
                    precoMaxConsumidor: "Preço Máx. Consumidor",
                    densidade: "Densidade (g/ml)",
                    importSemLote: "Importar Sem Lote",
                    preencherLote: "Preencher Lote",
                    fracionarLote: "Fracionar Lote",
                },

                produtos_vue_html: {
                    prodsLotes: "Produtos/Lotes",
                    total: "TOTAL",
                    proximo: "Próximo",
                    conclEAvancProxProd: "Conclui as alterações atuais e avança para o próximo produto divergente",
                },

                transporte_vue_html: {
                    transportador: "Transportador",
                    cnpj: "CNPJ",
                    ie: "Insc. Estadual",
                    cpf: "CPF",
                    uf: "UF",
                    cidade: "Cidade",
                    endereco: "Endereço",
                    qtd: "Quantidade",
                    especie: "Espécie",
                    marca: "Marca",
                    numeracao: "Numeração",
                    pesoLiq: "Peso Líquido",
                    pesoBruto: "Peso Bruto",
                    valorFrete: "Valor Frete",
                },
            },

            manipulacao: {
                cancelamento_vue_html: {
                    manipuOrdemCancelamento: "Manipulacao Ordem - Cancelamento",
                    manipuCanceladaNaoPossiv: "Manipulação já está cancelada! Não é possível alterar os dados.",
                    deveCancelarPelaTelaCancelamento:
                        "Esta manipulação deve ser cancelada pela tela de cancelamento de venda",
                    dadosManipu: "Dados da Manipulação",
                    dtCancelamento: "Data Cancelamento",
                    userCancelamento: "Usuário Cancelamento",
                    motivoCancelamento: "Motivo Cancelamento",
                    obsCancelamento: "Observação Cancelamento",
                    itensManipu: "Itens da Manipulação",
                    item: "Item",
                    qtde: "Quantidade",
                    semMovimentoManipulacao: "Não há movimento de estoque desta manipulação!",
                    reverteLoteCriado:
                        "Atenção! Ao cancelar manipulação será estornado a quantidade gerado do lote produzido",
                },

                detalhesitem_vue_html: {
                    comissaoPrescritor: "Comissão Prescritor: ",
                    comissaoVisitador: "Comissão Visitador: ",
                    comissaoAtendente: "Comissão Atendente: ",
                },

                edit_vue_html: {
                    manipuEdit: "Manipulação - Edição",
                    manipuCod: "Manipulação Código ",
                    edit: " - Edição",
                    desfazer: "Desfazer",
                    manipuJaConcluida: "Manipulação já está concluída! Não é possível alterar os dados.",
                    manipuCancelada: "Manipulação está cancelada! Não é possível alterar os dados.",
                    situacao: "Situação",
                    codigo: "Código",
                    numeroFormulas: "Número Fórmulas",
                    formaFarmaceu: "Forma Farmacêutica",
                    molde: "Molde",
                    tamPlaca: "tamanho da placa",
                    volDose: "Volume",
                    qtdTotal: "Qtd. Total",
                    volTotal: "Volume Total",
                    consolidaInfos:
                        "Consolida as informações após inseridos os ativos para buscas automaticas de Cápsulas, Embalagens e validações de estoque",
                    consolidarItens: "Consolidar Itens",
                    todosItensDevemTerProdutoInformado: "Todos itens da manipulação devem ter seu produto informado!",
                    confEstqAjusta: "Confere estoque e ajusta quantidades e lotes quando necessario",
                    lotesEstq: "Lotes/Estoque",
                    detalsDeCustos: "Detalhes de custos",
                    detalsCusto: "Detalhes Custo",
                    valores: "Valores",
                    valorFormFarma: "Valor Forma Farm.",
                    valorProds: "Valor Produtos",
                    acrescimo: "Acréscimo",
                    acrescimoPercentual: "Acréscimo (%)",
                    desconto: "Desconto",
                    descontoPercentual: "Desconto (%)",
                    valorTotal: "Valor Total",
                    utilizMinForma: "Utilizando valor mínimo da forma farmacêutica",
                    itensManipu: "Itens da Manipulação",
                    complementosManipu: "Complementos da Manipulação",
                    dtVal: "Data Validade",
                    posologia: "Posologia",
                    editPosologia: "Editar Posologia",
                    novaPosologia: "Nova Posologia",
                    paciente: "Paciente",
                    editPaciente: "Editar Paciente",
                    novoPaciente: "Novo Paciente",
                    prescritor: "Prescritor",
                    editPrescritor: "Editar Prescritor",
                    novoPrescritor: "Novo Prescritor",
                    obs: "Observação",
                    continuo: "Contínuo",
                    nroReceita: "Número receita",
                    dataValidadeReceita: "Data de validade da receita",
                    msgInformeNumeroReceita: "Você deve informar o número da receita!",
                    salvarAlt: "Salvar Alteraração",
                    add: "Adicionar",
                    concluir: "Concluir",
                    movimentacaoEstq: "Movimento Estoque",
                    imprimirModel: "Imprimir Modelo",
                    atualizDadosLotes: "Atualizar Dados Lotes",
                    preVenda: "Pré-Venda",
                    mensagemHabilitaAlteracao:
                        "Manipulacação com origem de pré-venda! Não será possível alterar essa manipulação.",
                    nomeVendedor: "Nome do Vendedor",
                    usaMarkupEquinos: "Usa Markup para Equinos",
                    multiplicarNumeroDose: "Multiplicar Número de Doses",
                    mensagemMultiplicarNumeroDose:
                        "Ao informar multiplicador este será utilizado para quebrar o número total de doses, se indicado para forçar a multiplicação o sistema irá buscar o molde mais adequado caso contrário não será considerado a quebra na busca do molde",
                    forcarMultQuantidade: "Forçar Multiplicação",
                    fatorMultQuantidade: "Fator de multiplicação",
                    volumeObrigatorio: "Informe o volume antes de consolidar a manipulação",
                    quantidadeObrigatoria: "Informe a quantidade antes de consolidar a manipulação",
                    quantidadeIgualAZero: "Não pode ter quantidade igual a zero",
                    obsUsuario: "Observação Usuário",
                    msgPrescritorObs: "Atenção, esse prescritor possui uma observação",
                    observacoes: "Observações",
                    numeroFormulasMaiorQueZero: "Informe o número de fórmulas",
                    naoFoiInformadoRecipienteQSP: "Não foi informado recipiente QSP na manipulação",
                    numeroReceitaDeveSerInformadoContinuo:
                        "A data de validade da receita deve ser informado, pois a manipulação contém produtos contínuos",
                    numeroReceitaDeveSerInformadoControlado:
                        "A data de validade da receita deve ser informado, pois a manipulação contém produtos controlados",
                    ativosDuplicado:
                        "Os ativos {produto1} e {produto2} são o mesmo produto. Não será possível continuar, ajuste a manipulação.",
                    manipulador: "Manipulador",
                    oCalculoVolume:
                        "O cálculo do volume dos ativos é feito com base no grupo de matéria prima e nos subgrupos Ativo e Flavorizante",
                    dataValidadeManipulacao: "Data de validade da manipulação",
                },

                item_vue_html: {
                    visuProduto: "Visualizar Produto",
                    vlrCadaDoseUnMedIguais:
                        "Volume Dose é o valor de cada dose em unidades de medida igual unidade do produto",
                },

                itemTable_vue_html: {
                    kitEmbalagem: "Kit de Embalagem",
                    lote: "Lote",
                    addAtivo: "Adicionar Ativo",
                    add: "Adicionar",
                    addFormBase: "Adicionar Formula Base",
                    addKitEmb: "Adicionar Kit Embalagem",
                    addCapsula: "Adicionar Cápsula",
                },

                list_vue_html: {
                    manipuVenda: "Manipulação de Venda",
                    manipu: "Manipulação",
                    cadastradas: "Cadastradas",
                    de: "Data Conclusão de:",
                    data: "Data:",
                },

                listFormulaPadrao_vue_html: {
                    manipuFrmlPadrao: "Manipulação de Formula Padrão",
                    manipu: "Manipulação",
                    cadastradas: "Cadastradas",
                    statusSituacao: "Situação",
                    estoque: "Estoque",
                },

                loteitem_vue_html: {
                    produto: "Produto",
                    produtoParametNaoControlEstq: "Produto parametrizado para não controlar estoque",
                    lote: "Lote",
                    qntdEstoque: "Quantidade em Estoque",
                    qntdBaixa: "Quantidade da Baixa",
                    val: "Validade",
                    status: "Status",
                    saldo: "Saldo:",
                    total: "Total:",
                    extra: "Extra:",
                    loteManipulacaoItemTooltip: "O primeiro lote será salvo no item da ordem de manipulação",
                },
            },

            navmenu: {
                abertura_de_caixa: "Abertura de Caixa",
                atualizacao_valores: "Atualização de Valores",
                atualizacao_valores_produto: "Atualização de produtos",
                atualizacao_valores_forma_farmaceutica: "Atualização de Forma Farmacêutica",
                bancos: "Bancos",
                bandeira: "Bandeira",
                beneficio: "Benefício",
                cadastros: "Cadastros",
                campanhas: "Campanhas",
                capacidade_producao: "Capacidade Produção",
                capsulas: "Cápsulas",
                categoria_controle_reclamacao: "Categoria",
                cests: "CESTs",
                classificacao_de_conta_gerencial: "Classificação de Conta Gerencial",
                cliente: "Cliente",
                clientes: "Clientes",
                clientes_duplicacoes: "Clientes",
                prescritores_duplicacoes: "Prescritores",
                clinicas: "Clínicas",
                colaboradores: "Colaboradores",
                configuracao: "Configuração",
                configuracao_dotz: "Configuração Dotz",
                configuracoes_da_franquia: "Configurações da Franquia",
                configuracoes_de_usuario: "Configurações do Usuário",
                configuracao_whatsapp: "Configuração WhatsApp",
                contas_a_pagar: "Contas a Pagar",
                contas_a_receber: "Contas a Receber",
                contas_correntes: "Contas Correntes",
                contas_gerenciais: "Contas Gerenciais",
                contas_pagas: "Contas Pagas",
                contas_recebidas: "Contas Recebidas",
                controle_reclamacao: "Controle de reclamação",
                convenio: "Convênio",
                cores: "Cores",
                cupom: "Cupom",
                dados_adicionais_ficha_tecnica: "Dados Adicionais Ficha Técnica",
                dashboard: "Dashboard",
                dashboards: "Dashboards",
                dashboard_evolucao_de_vendas: "Evolução de Vendas",
                dashboard_manipulacoes: "Manipulações",
                dashboard_totais_categoria: "Totais por Categoria",
                devolucoes: "Devoluções",
                dcbs: "DCBs",
                dcis: "DCIs",
                dotz: "Dotz",
                duplicacoes: "Duplicações",
                ensaios: "Ensaios",
                especialidades: "Especialidades",
                especie_de_animal: "Espécie de Animal",
                estoque: "Estoque",
                estoques: "Estoques",
                etapa_pcp: "Etapa PCP",
                farmacopeia: "Farmacopéia",
                fechamento_de_caixa: "Fechamento de Caixa",
                ficha_tecnica: "Ficha Técnica",
                financeiro: "Financeiro",
                fiscal: "Fiscal",
                fluxo_de_caixa: "Fluxo de Caixa",
                formas_de_pagamento: "Formas de Pagamento",
                formas_de_recebimento: "Formas de Recebimento",
                formas_farmaceuticas: "Formas Farmacêuticas",
                formula_padrao: "Fórmula Padrão",
                formulas_padrao: "Fórmulas Padrão",
                fornecedores: "Fornecedores",
                franquias: "Franquias",
                grupos: "Grupos",
                grade_pcp: "Grade PCP",
                status_pcp: "Status PCP",
                historico: "Historico",
                historico_envios: "Histórico de Envios",
                importacao_nfe: "Importação NFe",
                inutilizacao_nota_fiscal: "Inutilização Notas Fiscais",
                download_xmls_nota_fiscal: "Download XMLs Notas Fiscais",
                emissao_nota_saida_lote: "Emissão de nota fiscal em lote",
                geracao_siproquim: "SIPROQUIM",
                emissao_nota: "Emissão de notas",
                inventario: "Inventário",
                justificativa_controle_reclamacao: "Justificativas",
                kit_embalagem: "Kit Embalagem",
                curva_abc: "Curva ABC",
                curva_abc_produto: "Produtos",
                curva_abc_prescritores: "Prescritores",
                listas_de_controlado: "Listas de Controlado",
                localentrega: "Locais de Entrega",
                lotes: "Lotes",
                manipulacao: "Manipulação",
                manipulacoes: "Manipulações",
                manipulacoes_venda: "Venda",
                policia_civil: "Polícia Civil",
                metas: "Metas",
                metodos_de_pagamento_recebimento: "Métodos de Pagamento/ Recebimento",
                modelos_de_impressao: "Modelos de Impressão",
                moldes: "Moldes",
                motivos_cancelamento: "Motivos Cancelamento",
                motivo_rejeicao: "Motivos Rejeição",
                motivo_movimentacao_estoque: "Motivo Movimentação",
                motivo_etapa_pcp: "Motivo Etapa PCP",
                movimentacoes_de_caixa: "Movimentações de Caixa",
                movimentacoes_de_conta: "Movimentações de Conta",
                movimentacoes_de_estoque: "Movimentações de Estoque",
                movimentacoes_pendentes: "Movimentações Pendentes",
                naturezas_de_operacao: "Naturezas de Operação",
                nbms: "NBMs",
                ncms: "NCMs",
                nfe_danfe_saida: "Notas Emitidas",
                nota_fiscal_de_entrada: "Nota Fiscal de Entrada",
                nota_fiscal_de_saida: "Nota Fiscal de Saída",
                operadora_de_cartoes: "Operadora de Cartões",
                contas_a_receber_parceladas: "Parceladas",
                operados_de_cartao: "Operadoras de Cartão",
                orcamentos: "Orçamentos",
                pacientes: "Pacientes",
                pcp: "PCP",
                pdv: "PDV",
                posologias: "Posologias",
                prescritores: "Prescritores",
                produto: "Produto",
                produtos: "Produtos",
                pontuar: "Pontuar",
                pontuar_manual: "Pontuação manual",
                compras: "Compras",
                programa_fidelidade: "Programa Fidelidade",
                racas_animais: "Raças dos Animais",
                recebimento_parcelado: "Métodos de recebimento parcelado",
                recompensas: "Recompensas",
                relatorios: "Relatórios",
                rotulo: "Rótulo",
                servicos: "Serviços",
                servicos_fiscais: "Serviços Fiscais",
                sngpc: "SNGPC",
                sngpc_manual: "SNGPC Manual",
                telas: "Telas",
                telas_acoes: "Telas Ações",
                transportador: "Transportador",
                transportadora: "Transportadora",
                unidades_de_medida: "Unidades de Medida",
                usuarios: "Usuários",
                venda: "Venda",
                vendas: "Vendas",
                visitador: "Visitador",
                caixa: "Caixa",
                livro_registro_especifico: "Livro de Registro Específico",
                balanco_completo: "Balanço Completo",
                veterinario: "Veterinário",
                dinamizacao: "Dinamização",
                estoque_minimo: "Estoque Mínimo",
                vendas_entregas: "Entregas",
                expedicao: "Expedição",
                coleta: "Coletas",
                declaracao_conteudo: "Declaração de Conteúdo",
                suporte: "Suporte",
                whatsapp: "WhatsApp",
                tomticket: "TomTicket",
                chatbot: "Chat Bot",
                controle_especial: "Controle Especial",
                manipuladores: "Manipuladores",
                etiqueta: "Etiquetas",
                condicao: "Condições",
            },

            nfe: {
                edit_vue_html: {
                    nfeEdit: "Nota Fiscal Emitida - Edição",
                    dadosNfe: "Dados da NF-e",
                    operacao: "Operação",
                    dtEmissao: "Data de Emissão",
                    dtHraEntradaSaida: "Data e Hora da Entrada/Saída",
                    finalidadeEmissao: "Finalidade da Emissão",
                    indicadorPresenca: "Indicador de Presença",
                    indicativoIntermedio: "Indicativo Intermédio",
                    tipoEmissao: "Tipo de Emissão",
                    fornecedor: "Fornecedor",
                    cliente: "Cliente",
                    cfop: "CFOP",
                    itens: "Itens",
                    obs: "Observações",
                    transportadora: "Transportadora",
                    modalFrete: "Modalidade do Frete",
                    cnpj: "CNPJ",
                    endereco: "Endereço",
                    cidade: "Cidade",
                    uf: "UF",
                    ie: "I.E.",
                    placaVeiculo: "Placa do Veículo",
                    salvarEEmitir: "Salvar e Emitir",
                    consultaEmissao: "Consulta Emissão",
                    cartaCorrecao: "Gerar Carta de correção",
                    consultarCartaCorrecao: "Consultar última Carta de correção",
                    abrirPDF: "Abrir PDF",
                    abrirPDFSimplificado: "Abrir PDF Simplificado",
                    abrirXML: "Abrir XML",
                    cancelarEmissao: "Cancelar Emissão",
                    movimentaEstoque: "Movimenta estoque",
                    estoque: "Estoque",
                },

                item_vue_html: {
                    produto: "Produto",
                    lote: "Lote",
                    infoAdicional: "Informação Adicional",
                    EAN: "EAN (Código de barras)",
                    unMed: "Unidade de Medida",
                    qtde: "Quantidade",
                    ncm: "NCM",
                    vlrUnitario: "Valor Unitário",
                    desconto: "Desconto",
                    frete: "Frete",
                    seguro: "Seguro",
                    outrasDespesas: "Outras Despesas",
                    vlrTotal: "Valor Total",
                },

                list_vue_html: {
                    nfe: "NF-e",
                    nf: "Notas Fiscais",
                    emitidas: "Emitidas",
                    NFE: "NFE",
                },
            },

            notafiscalentrada: {
                edit_vue_html: {
                    nfEntradaEdit: "Nota Fiscal de Entrada - Edição",
                    dadosNfEnrtada: "Dados da Nota fiscal de entrada",
                    estqDestino: "Estoque destino",
                    dtEmissao: "Data de Emissão",
                    dtInclusao: "Data da inclusão",
                    vlrSeraAtualizVlrNota:
                        "Se marcado a opção e o valor unitário do item da nota estiver maior que o valor de custo referência do produto, o mesmo será atualizado para o valor oriundo da nota.",
                    atualizarCustos: "Atualizar Custos",
                    gerarCntPagar: "Gerar conta a pagar",
                    nroNF: "Número da Nota Fiscal",
                    serie: "Série",
                    chave: "Chave de acesso",
                    fornecedor: "Fornecedor",
                    editFornecedor: "Editar Fornecedor",
                    novoFornecedor: "Novo Fornecedor",
                    transportadora: "Transportadora",
                    itens: "Itens",
                    financeiro: "Financeiro",
                    totais: "Totais",
                    vlrFrete: "Valor Frete",
                    vlrIPI: "Valor IPI",
                    vlrTotalProd: "Valor Total Produtos",
                    vlrDescontos: "Valor Descontos",
                    vlrTotal: "Valor Total",
                    vlrTotalFaturado: "Valor Total Faturado",
                },

                financeiro_vue_html: {
                    descrDuplicata: "Descrição Duplicata",
                    dtVenc: "Data vencimento",
                    contaGerencial: "Conta Gerencial",
                    status: "Status",
                    dadosPgto: "Dados de pagamento",
                    dtPgto: "Data de Pagamento",
                    formPgto: "Forma de Pagamento",
                    contaCorrente: "Conta Corrente",
                },

                item_vue_html: {
                    produto: "Produto",
                    codProd: "Código Produto",
                    lote: "Lote",
                    editProdLote: "Editar Produto Lote",
                    novoProdLote: "Novo Produto Lote",
                    unidadeMedidaOrigem: "Unidade Medida Origem",
                    quantidadeOrigem: "Quantidade Origem",
                    valorUnitarioOrigem: "Valor Unitário Origem",
                    quantidadeConvertida: "Quantidade Convertida",
                    valorUnitarioConvertido: "Valor Unitário Convertido",
                    vlrTotal: "Valor Total",
                    valorFrete: "Valor Frete",
                    valorSeguro: "Valor Seguro",
                    valorOutraDespesa: "Valor Outras Despesas",
                    valorDesconto: "Valor Desconto",
                    tlpQuantidadeConvertida: "Quantidade Origem com índice de conversão aplicado",
                    tlpValorUnitarioOrigem: "Valor Bruto do item da nota",
                    tlpValorTotal: "Valor Líquido calculado",
                    tlpValorUnitarioConvertido: "Será considerado no valor unitário do movimento de estoque",
                },

                list_vue_html: {
                    nfEntrada: "Notas fiscais de entrada",
                    cadastradas: "Cadastradas",
                    nf: "Notas fiscais",
                },
            },

            notafiscalsaida: {
                edit_vue_html: {
                    nfSaidaEdit: "Nota Fiscal de Saída - Edição",
                    dadosNfSaida: "Dados da Nota fiscal de saida",
                    statusEmissao: "Status de Emissão",
                    tipoNF: "Tipo da Nota Fiscal",
                    motivoCancel: "Motivo do Cancelamento",
                    nro: "Número",
                    dtEmissao: "Data de Emissão",
                    dtInclusao: "Data Inclusão",
                    serie: "Série",
                    chaveBusca: "Chave de Busca",
                    codVerif: "Código de Verificação",
                    chave: "Chave",
                    servico: "Serviço",
                    produtos: "Produtos",
                    totais: "Totais",
                    baseCalculo: "Base de Cálculo",
                    vlrServico: "Valor do Serviço",
                    vlrISS: "Valor do ISS",
                    vlrTotalServicos: "Valor total de Serviços",
                    baseCalculos: "Base de Cálculos",
                    vlrProds: "Valor dos Produtos",
                    valorICMS: "Valor do ICMS",
                    vlrTotal: "Valor total",
                    consultEmissao: "Consultar Emissão",
                    cancelarEmissao: "Cancelar Emissão",
                    consultEmissaoCancelamento: "Consultar Emissão de Cancelamento",
                    altDadosNF: "Alterar dados da Nota Fiscal",
                    cartaCorrecao: "Gerar Carta de correção",
                    consultarCartaCorrecao: "Consultar última Carta de correção",
                    abrirPDF: "Abrir PDF",
                    abrirPDFSimplificado: "Abrir PDF Simplificado",
                    abrirXML: "Abrir XML",
                },

                list_vue_html: {
                    nfSaida: "Notas fiscais de Saída",
                    nf: "Notas fiscais",
                    cadastradas: "Cadastradas",
                    status: "Status Nota:",
                    tipoNota: "Tipo Nota:",
                    de: "De:",
                    ate: "Até:",
                    todos: "Todos",
                },
            },

            printModel: {
                campo_vue_html: {
                    nomeCampo: "Nome do Campo",
                    mostrarNomeCampo: "Mostrar Nome Campo",
                    seMarcNaoMostra: "Se desmarcado, não apresenta o nome do campo na impressão",
                    origemCampoComplemen: "Origem do Campo de Complemento",
                    separOndeOriginaInfo: "Separação de onde se origina a informação",
                    campoComplemen: "Campo Complemento",
                    utilizOutroCampoComplemen: "Utilizar outro Campo para complemento de valor deste campo",
                    complemenCampo: "Complemento do Campo",
                    posComplemen: "Posição do Complemento",
                    posComplemenRelacaoVlr: "Posição do complemento em relação ao dado do valor Impresso",
                    txtNegrito: "Texto Negrito",
                    corTxt: "Cor do Texto",
                    corFundoTxt: "Cor Fundo do Texto",
                    origCampoSubstituto: "Origem do Campo Substituto",
                    primeiroNomeCampoSubstituto: "Primeiro Nome Campo Substituto",
                    campoSubstituto: "Campo Substituto",
                    campoSubstituiInfos: "Campo que substitui as informações do campo caso o mesmo não possua valores",
                    utilizLimiteChar: "Utilizar Limite de Caracteres",
                    codigoBarras: "Código de barras",
                    utilizNroLimiteCharVlrCampo: "Utilizar numero de caracteres limitado para o valor do campo",
                    avisoCodigoBarras: "Utilizar cób. de barras na impressão.",
                    maxChar: "Máximo Caracteres",
                    alinTxt: "Alinhamento do Texto",
                    primeiroNome: "Primeiro Nome",
                    apresTxtAtePrimEspaco: "Apresenta texto até o primeiro espaçamento",
                    quebLinha: "Quebrar Linha",
                    qndoLimitMaxCharLinhaAbaixo:
                        "Quando limitado o máximo de cáracteres, pode se escolher continuar o texto na linha abaixo",
                    personalizado: "Personalizado",
                    identificador: "Identificador",
                    exibirCampoBranco: "Campo em branco",
                    exibirCampoBrancoTooltip:
                        "Ao marcar essa opção, quando o campo tiver valor zero, ele será exibido em branco",
                },

                condicao_vue_html: {
                    condImpressao: "Condição de Impressão",
                    origemCampo: "Origem do Campo",
                    separOndeOriginInfo: "Separação de onde origina a informação",
                    campoComparar: "Campo a comparar",
                    vlrComparar: "Valor a comparar",
                },

                edit_vue_html: {
                    modelImpressEdit: "Modelo de Impressão - Edição",
                    dadosImpress: "Dados de Impressão",
                    descrSessao: "Descrição da Sessão",
                    imprimirDesc: "Imprimir Desc.",
                    ordem: "Ordem",
                    auxOrdenacaoSessoes: "Auxilia para ordenação das sessões",
                    tipoDado: "Tipo de Dado",
                    tipoDadoCampo: "Tipo de dado do campo, as sessões devem ser separadas por tipo de dados",
                    linhas: "Linhas",
                    tiposLinhasTab: "Tipos de Linhas de tabelas",
                    ativo: "Ativo",
                    maxCharLinha: "Máximo Carácteres por Linha",
                    limitaCharLinha: "Limita o número de carácteres por linha",
                    maxLinhas: "Máximo de Linhas",
                    limitInfosCampos: "Limita as informações dos campos para uma quantidade x de linhas",
                    corpoImpress: "Corpo da Impressão",
                    separOriginaInfo: "Separação de onde origina a informação",
                },

                list_vue_html: {
                    modeloImpress: "Modelo Impressao",
                    cadastrados: "Cadastrados",
                },

                sessao_vue_html: {
                    modelImpressEdit: "Modelo de Impressão - Edição",
                    descricao: "Descrição",
                    padrao: "Padrao",
                    tamanhoFonte: "Tamanho da Fonte",
                    fonte: "Fonte",
                    modeloPreDefinido: "Modelo Pré Definido",
                    modeloPreDefinidoTooltip:
                        "Modelo personalizado irá respeitar as configurações deste modelo de impressão, caso contrário irá utilizar o PDF pré definido sem possibilidade de alteração",
                    sessoesModelImpress: "Sessões do Modelo de Impressão",
                    condicoes: "Condições",
                    usuarios: "Usuários",
                    tipoModelo: "Tipo Modelo",
                },
            },

            relatorio: {
                list_vue_html: {
                    obsoleto: "obsoleto",
                    relatorios: "Relatórios",
                    relatVendas: "RELATÓRIOS DE VENDAS",
                    relaNotas: "RELATÓRIOS DE NOTAS",
                    relaFaturamentos: "RELATÓRIOS DE FATURAMENTO",
                    relaOrcamentos: "RELATÓRIOS DE ORÇAMENTO",
                    relaComissao: "RELATÓRIOS DE COMISSÃO",
                    relatBeneficios: "RELATÓRIO DE BENEFÍCIO",
                    relatCustoVenda: "RELATÓRIO CUSTO DE VENDA",
                    relatCustoVendaFracionamento: "RELATÓRIO CUSTO DE VENDA FRACIONAMENTO",
                    relatProdSemVenda: "RELATÓRIO PRODUTOS SEM VENDA",
                    relatVendaXPrescr: "RELATORIO QUANTIDADE DE VENDAS POR PRESCRITOR",
                    relatVendaComDesc: "RELATÓRIO VENDA COM DESCONTO",
                    relatPrescrDiasSemPrescricoes: "RELATÓRIO PRESCRITORES: DIAS SEM PRESCRIÇÕES",
                    relatVendaPorCliente: "RELATÓRIO VENDAS POR CLIENTE",
                    relatVendasPorEspecie: "RELATÓRIO VENDAS POR ESPÉCIE",
                    relatVendasPorPrescr: "RELATÓRIO VENDAS POR PRESCRITOR",
                    relatVendasPorProds: "RELATÓRIO VENDAS POR PRODUTOS",
                    relatVendasPorProdsClientes: "RELATÓRIO VENDAS POR PRODUTOS E CLIENTES",
                    relatVendasPorTipoPgto: "RELATÓRIO VENDAS POR FORMA DE PAGAMENTO",
                    relatFaturamento: "RELATÓRIO DE FATURAMENTO",
                    relatVendasPorVendedor: "RELATÓRIO VENDAS POR VENDEDOR",
                    relatVendasPorVisitador: "RELATÓRIO VENDAS POR VISITADOR",
                    relatVendasFranquiaAbrangencia: "RELATÓRIO VENDA POR FRANQUIA DE ABRANGÊNCIA",
                    relatPosicaoEstoque: "RELATÓRIO DE POSIÇÃO DE ESTOQUE",
                    relatFatuamentoFranquia: "RELATÓRIO FATURAMENTO FRANQUIA",
                    relatFatuamentoFracionamento: "RELATÓRIO FATURAMENTO FRACIONAMENTO",
                    relatComissaoVisitadorSobreVendas: "RELATÓRIO COMISSÃO VISITADOR SOBRE VENDAS",
                    relatComissaoVendedorSobreVendas: "RELATÓRIO COMISSÃO VENDEDOR SOBRE VENDAS",
                    relatOrcamentosRejeitados: "RELATÓRIO DE ORÇAMENTOS REJEITADOS",
                    relatVendasPorTransportador: "RELATÓRIO VENDAS POR TRANSPORTADOR",
                    relatVendasSemNota: "RELATÓRIO VENDAS SEM NOTA",
                    relatVendasSemFaturamento: "RELATÓRIO VENDAS SEM FATURAMENTO",
                    relatRegistroReceituarioGeral: "REGISTRO DE RECEITUÁRIO GERAL",
                    relatsProdutos: "RELATÓRIOS DE PRODUTOS",
                    relatContinuos: "RELATÓRIO CONTÍNUOS",
                    relatRecompensaUtilizadaCampanha: "RELATÓRIO DE PONTUAÇÃO/RECOMPENSAS",
                    relatDadosGeraisProduto: "RELATÓRIO DADOS GERAIS DOS PRODUTOS",
                    relatCustoMedioProdutoVendido: "RELATÓRIO DE CUSTO MÉDIO DE PRODUTOS VENDIDOS",
                    relatsGerenciais: "RELATÓRIOS GERENCIAIS",
                    relatCustoMarkupProds: "RELATÓRIO DE CUSTO E MARKUP DE PRODUTOS",
                    relatsFinanceiro: "RELATÓRIOS FINANCEIRO",
                    relatContasAPagar: "RELATÓRIO CONTAS A PAGAR",
                    relatContasAReceber: "RELATÓRIO CONTAS A RECEBER",
                    relatContasPagas: "RELATÓRIO CONTAS PAGAS",
                    relatContasRecebidas: "RELATÓRIO CONTAS RECEBIDAS",
                    relatFluxoCaixa: "RELATÓRIO FLUXO DE CAIXA",
                    relatProdutosFinanceiro: "RELATÓRIO PRODUTOS FINANCEIRO",
                    relatEstoque: "RELATÓRIOS DE ESTOQUE",
                    relatCoberturaEstoque: "RELATÓRIO DE COBERTURA DE ESTOQUE",
                    relatorioRomaneio: "RELATÓRIO DE ROMANEIO DE ENTREGA",
                    relatorioMotivoMovimentacao: "RELATÓRIO DE MOTIVO DE MOVIMENTAÇÃO",
                    relatMovimentacaoLote: "RELATÓRIO MOVIMENTAÇÃO LOTE",
                    relatLotes: "RELATÓRIO DE LOTES",
                    relatComprasProduto: "RELATÓRIO COMPRAS DE PRODUTO",
                    relatVendasCompromentendoLote: "RELATÓRIO VENDAS COMPROMENTENDO LOTE",
                    relatSaldoProdutos: "RELATÓRIO DE SALDO DE PRODUTOS",
                    relatPrescritores: "RELATÓRIO DE PRESCRITORES",
                    relatCobranca: "RELATÓRIO DE COBRANÇA",
                    relatProdutoVendidosDrogavet: "RELATÓRIO DE PRODUTOS VENDIDOS DROGAVET",
                    outros: "OUTROS",
                    relatAniversario: "RELATÓRIO DE ANIVERSÁRIO",
                    relatorioPontuacoesPorPrescritor: "RELATÓRIO DE PONTUAÇÕES DOTZ POR PRESCRITOR",
                    relatorioImpostoEmissoes: "RELATÓRIO IMPOSTO EMISSÕES",
                    relatorioAberturasCaixa: "RELATÓRIO DE ABERTURAS DE CAIXA",
                    relatorioPCPEtapas: "RELATÓRIO DE STATUS PCP ETAPAS",
                    relatorioProdutosControlados: "RELATÓRIO DE PRODUTOS CONTROLADOS (SIPROQUIM)",
                    relatorioConsumoProdutoPorFranquia: "RELATÓRIO DE CONSUMO DE PRODUTOS POR FRANQUIA",
                    relatorioVendasPorFormaFarmaceutica: "RELATÓRIO DE VENDAS POR FORMAS FARMACÊUTICAS",
                    relatorioNotasSaida: "RELATÓRIO DE NOTAS DE SAÍDA",
                    relatorioEvolucaoVendas: "RELATÓRIO DE EVOLUÇÃO DE VENDAS",
                    relatorioVendasPorPeriodo: "RELATÓRIO DE VENDAS POR PERÍODO",
                    relatorioProdutosMaisVendidos: "RELATÓRIO DE PRODUTOS MAIS VENDIDOS",
                    relatorioVendaPorCidade: "RELATÓRIO DE VENDAS POR CIDADE",
                    relatorioVendaComTroco: "RELATÓRIO DE VENDAS COM TROCO",
                    relatorioVendaComCupom: "RELATÓRIO DE VENDAS COM CUPOM",
                    relatorioTaxaEntrega: "RELATÓRIO DE TAXA DE ENTREGA",
                    relatorioOrcamento: "RELATÓRIO DE ORÇAMENTOS",
                    relatorioControleReclamacao: "RELATÓRIO DE CONTROLE DE RECLAMAÇÕES",
                    relatorioVendasPorColaborador: "RELATÓRIO DE VENDAS POR COLABORADOR",
                    relatorioVendasPorEstoque: "RELATÓRIO DE VENDAS POR ESTOQUE",
                    relatorioAcompanhamentoFranqueadora: "RELATÓRIO DE ACOMPANHAMENTO DA FRANQUEADORA",
                },
            },

            sessaoCaixa: {
                aberturaCaixa_vue_html: {
                    pontoVenda: "Ponto de Venda",
                    saldoInicial: "Saldo Inicial",
                    gerarDifSaldoInicial: "Gerar diferença Saldo Inicial",
                    formaRecebiTransferencia: "Forma Recebimento de Transferência",
                    formaRecebiOrigemDifCaixa:
                        "Forma de Recebimento cuja origem seja diferente ao caixa da Sessão aberta",
                    contaCorrenteDestino: "Conta Corrente Destino",
                    confirmarAbertura: "Confirmar Abertura",
                },

                fechamentoCaixa_vue_html: {
                    fechamentoCaixa: "Fechamento de Caixa",
                    movimentacoesCaixa: "Movimentações de Caixa",
                    realizadas: "Realizadas",
                    saldoInicial: "Saldo Inicial",
                    saldoAtual: "Saldo Atual",
                    somaEntradas: "Soma de Entradas",
                    somaSaidas: "Soma de Saidas",
                    somaDiferenc: "Soma das Diferenças",
                    totalSaldoGeral: "Total Saldo Geral",
                    somaEntradasSubtraindoSaidas: "Soma de todas Entradas subtraindo todas as Saidas",
                    resumoCaixa: "Resumo do Caixa",
                    dtHraAbertura: "Data/Hora Abertura",
                    dtHoraFechamento: "Data/Hora Fechamento",
                    saldoFechamento: "Saldo do Fechamento",
                    sangria: "Sangrias",
                    reforcos: "Reforços",
                    movimentacoesDinheiro: "Movimentações em Dinheiro",
                    movimentacoesCredito: "Movimentações em Crédito",
                    movimentacoesDebito: "Movimentações em Débito",
                    movimentacoesBoleto: "Movimentações em Boleto",
                    movimentacoesTransferencia: "Movimentações de Transferência",
                    movimentacoesCheque: "Movimentações em Cheque",
                    anterior: "Anterior",
                    proximo: "Proximo",
                    concluir: "Concluir",
                },

                listMovimentosCaixa_vue_html: {
                    movimentacoesCaixa: "Movimentações de Caixa",
                    realizadas: "Realizadas",
                    saldoInicial: "Saldo Inicial",
                    saldoAtual: "Saldo Atual",
                    gerarReforco: "Gerar Reforço",
                    gerarSangria: "Gerar Sangria",
                },

                listMovimentacoesPendente_vue_html: {
                    movimentacoesPendente: "Movimentações Pendentes",
                    cadastradas: "Cadastradas",
                    reprocessar: "Reprocessar",
                },

                listCaixa_vue_html: {
                    sessaoCaixa: "Sessão de Caixa",
                    fechamentoCaixa: "Fechamento de caixa",
                },

                reforco_vue_html: {
                    descricao: "Descrição",
                    saldoAtual: "Saldo Atual",
                    valorReforco: "Valor Reforço",
                    totalCalculado: "Total Calculado",
                    contaCorrente: "Conta Corrente",
                    frmRecebimentoTransf: "Forma Recebimento de Transferência",
                    tooltipFormaRecebimento:
                        "Forma de Recebimento cuja origem seja DIFERENTE ao caixa da Sessão Aberta",
                },

                sangria_vue_html: {
                    descricao: "Descrição",
                    saldoAtual: "Saldo Atual",
                    sangria: "Sangria",
                    saldoCalculado: "Saldo Calculado",
                    saldoPermanPDV: "Saldo que permanecerá no PDV",
                    contaCorrenteDestino: "Conta Corrente Destino",
                    frmRecebimentoTransf: "Forma Recebimento de Transferência",
                    tooltipContaCorrente: "Conta Corrente que não seja igual ao caixa da Sessão Aberta",
                    tooltipFormaRecebimento: "Forma de Recebimento cuja origem seja IGUAL ao caixa da Sessão Aberta",
                },
            },

            shortcut: {
                shortcut_vue_html: {
                    addNovo: "Adicionar Novo",
                },
            },

            siproquim: {
                edit_vue_html: {
                    siproquimGeracao: "Siproquim - Geração",
                    dataInicial: "Data Inicial",
                    dataFinal: "Data Final",
                    geraCadastroProdutos: "Gerar registros de cadastro dos produtos (PR)",
                    gerarArquivo: "Gerar Arquivo",
                },
            },

            sngpc: {
                list_vue_html: {
                    sngpc: "SNGPC",
                    gerados: "Gerados",
                    gerarNovoRelatorio: "Gerar Novo Relatório",
                    gerarInventario: "Gerar Novo Inventário",
                    visuProdutos: "Visualizar Produtos",
                    confirmaGeracaoInventario:
                        "ATENÇÃO. Confira no site da ANVISA as situações para criação de um novo inventário. Confirma a geração de um novo inventário?",
                    dataInicio: "Data Inicial",
                    dataFinal: "Data Final",
                },

                sngpc_vue_html: {
                    funcionalidadeEmDesenvol: "Esta funcionalidade está em desenvolvimento",
                    aguardeProxAtualiz: "Aguarde a próxima atualização para poder acessá-la",
                    duvidasEntrarContato: "Caso tenha dúvidas entre em contato conosco",
                },

                ver_vue_html: {
                    sngpcProds: "SNGPC Produtos",
                },
            },

            statusPCP: {
                view_vue_html: {
                    statusPCP: "Status PCP",
                    etapa: "Etapa",
                    venda: "Venda",
                    dataInicial: "Data inicial",
                    dataFinal: "Data final",
                    codigo: "Código",
                    pcpRapido: "PCP rápido",
                },
            },

            topmenu: {
                topmenu_vue_html: {
                    suporte: "Suporte",
                    cidade: "cidade",
                    caixaAberto: "Caixa Aberto",
                    caixaFechado: "Caixa Fechado",
                    notificacoes: "Notificações",
                    semNotificacoesRecentes: "Sem Notificações Recentes",
                    novas: "Novas",
                    marcarTodasComoLidas: "Marcar todas como lidas",
                    marcarComoLido: "Marcar como Lido",
                    antigos: "Antigos",
                    marcarComoNaoLido: "Marcar como não Lido",
                    verMais: "Ver Mais",
                    alterarSenha: "Alterar Senha",
                    sair: "Sair",
                    alterarIdioma: "Alterar Idioma",
                    notasDaVersao: "Notas da Versão",
                    emailNotasDaVersao: "E-mail da Versão",
                    logLevel: "Log Level",
                    ambienteDesenvolvimento: "Ambiente de Desenvolvimento",
                    ambienteHomologacao: "Ambiente de Homologação",
                    ambienteTrainamento: "Ambiente de Treinamento",
                    statusSistema: "Status do Sistema",
                },
            },

            venda: {
                cancelamento_vue_html: {
                    vendaCancelCodigo: "Venda Cancelamento - Código: ",
                    vendaJaCancelada: "Venda já está cancelada! Não é possível alterar os dados.",
                    selecionaItensCancelar:
                        "Atenção! Selecione todos os itens que deseja cancelar, não será possível cancelar novamente após salvar",
                    dadosVenda: "Dados da Venda",
                    dtVenda: "Data da Venda",
                    nomeCliente: "Nome do Cliente",
                    vlrTotal: "Valor Total",
                    dtCancelamento: "Data Cancelamento",
                    userCancelamento: "Usuário Cancelamento",
                    motivoCancelamento: "Motivo Cancelamento",
                    obsCancelamento: "Observação Cancelamento",
                    itensVenda: "Itens da Venda",
                    item: "Item",
                    qtd: "Quantidade",
                    itensManipulCod: "Itens de manipulação cod: ",
                    naoHaMovimentEstq: "Não há movimento de estoque desta venda!",
                    naoFoiRealizEstornoVenda: "Não foi realizado estorno dessa venda!",
                    naoHaMovimentoFinanceiro: "Não há movimento financeiro desta venda!",
                },

                edit_vue_html: {
                    vendaEdit: "Venda - Edição",
                },

                editSingle_vue_html: {
                    dtVencimentoContinuoVazio: "Informe uma data de vencimento para contínuo",
                    vendaJaConcluida: "Venda já está concluída! Não é possível alterar os dados.",
                    vendaJaCancelada: "Venda está cancelada! Não é possível alterar os dados.",
                    origemAtend: "Origem Atendimento",
                    tipoVenda: "Tipo da Venda",
                    franquiaAbrangencia: "Franquia de Abrangência",
                    situacao: "Situação",
                    codigo: "Código",
                    cliente: "Cliente",
                    editCliente: "Editar Cliente",
                    novoCliente: "Novo Cliente",
                    dtAprov: "Data/Hora Aprovação",
                    dtConclusao: "Data Conclusão",
                    dtHraEntrega: "Data/Hora Entrega",
                    continuo: "Contínuo",
                    dtVencimentoContinuo: "Data vencimento continuo",
                    origem: "Origem",
                    obs: "Observação",
                    vlrProds: "Valor Produtos",
                    taxaEntrega: "Taxa Entrega",
                    acrescimo: "Acrescimo",
                    desconto: "Desconto",
                    descBeneficio: "Desconto do Benefício",
                    vlrFinal: "Valor Final",
                    dadosFiscais: "Dados Fiscais",
                    emitiNFEAposSalvarConcluir: "Emitir NFS-e Automaticamente após Salvar/Concluir a Venda",
                    detalhes: "Detalhes",
                    permitirFinalSemEmissao: "Permitir finalizar venda sem emissão de nota",
                    detalhesNFe: "Detalhes NF-e",
                    campanha: "Campanha",
                    cpf: "CPF",
                    recompensas: "Recompensas",
                    ptsAcumulados: "Pontos Acumulados",
                    ptsCompra: "Pontos Compra",
                    totalPts: "Total Pontos",
                    dadosComplementOrcamento: "Dados Complementares do Orçamento",
                    dtInclusao: "Data Inclusão",
                    dtValOrcamento: "Dt Val. Orçamento",
                    transportadora: "Transportadora",
                    transportador: "Transportador",
                    salvarImprimir: "Salvar e Imprimir",
                    aprovOrcamento: "Aprovar Orçamento",
                    emitirNota: "Emitir Nota",
                    faturar: "Faturar",
                    concluir: "Concluir",
                    financeiro: "Financeiro",
                    enviarOrcamento: "Compartilhar Orçamento",
                    imprimirOrcamento: "Imprimir Orçamento",
                    receita: "Anexar Arquivo",
                    expedicao: "Expedição",
                    confirAlteracoes: "Confirmar Alterações",
                    novoPgto: "Novo Pagamento",
                    selecione: "Selecione",
                    pagamentos: "Pagamentos",
                    nomeVendedor: "Nome do Vendedor",
                    movimentacaoEstoque: "Movimento Estoque",
                    nomeSeparador: "Nome do Separador",
                    identificarClienteNF: "Identificar Cliente na NF",
                    tlpIdentificarClienteNF: "Cliente precisa ter o CPF informado no cadastro",
                    localEntrega: "Local de Entrega",
                    cashback: "CashBack",
                    cashbackAplicar: "Aplicar Desconto",
                    desconcluirVenda: "Defazer conclusão",
                    estoqueOrigem: "Estoque da Franquia",
                    setor: "Setor",
                    historicoVendas: "Histórico de Vendas",
                    notaTransporte: "Nota Transporte",
                    entrega: "Entrega",
                    gerarCodigoRastreio: "Gerar código de rastreio",
                    obsDesconto: "Observação do desconto",
                    acrescimoDesc: "Acréscimo/Desconto",
                },

                enderecoEntrega_vue_html: {
                    enderecoEntrega: "Endereço de entrega",
                    dadosTransporte: "Dados de Transporte",
                    cep: "CEP",
                    estado: "Estado",
                    cidade: "Cidade",
                    endereco: "Endereço",
                    nro: "Número",
                    bairro: "Bairro",
                    complemento: "Complemento",
                    enderecoPadrao: "Endereço Padrão",
                    pesoBrutoKg: "Peso Bruto (kg)",
                    pesoLiqKg: "Peso Líquido (kg)",
                    volumesUn: "Volumes (Un)",
                    transportadora: "Transportadora",
                    transportador: "Transportador",
                    taxaEntrega: "Taxa Entrega",
                    codigoRastreio: "Código de Rastreio",
                },

                estorno_vue_html: {
                    contaGerencial: "Conta Gerencial",
                    frmPgto: "Forma Pagamento",
                    contaCorrente: "Conta Corrente",
                    vlrPago: "Valor Pago",
                    obs: "Observação",
                },

                item_vue_html: {
                    produto: "Produto",
                    produtoLote: "Produto Lote",
                    qtde: "Quantidade",
                    vlrUnit: "Valor Unit.",
                    acrescimo: "Acréscimo",
                    desconto: "Desconto",
                    markupProduto: "Markup do produto",
                    vlrTotal: "Valor Total",
                    posologia: "Posologia",
                    editPosologia: "Editar Posologia",
                    novaPosologia: "Nova Posologia",
                    paciente: "Paciente",
                    editPaciente: "Editar Paciente",
                    novoPaciente: "Novo Paciente",
                    prescritor: "Prescritor",
                    editPrescritor: "Editar Prescritor",
                    novoPrescritor: "Novo Prescritor",
                },

                itemwrapper_vue_html: {
                    manipulacao: "Manipulação",
                    acabado: "Acabado",
                    fracionamento: "Fracionamento",
                    homeopatia: "Homeopatia",
                    alterar: "Alterar",
                    add: "Adicionar",
                    naoImplementado: "Não implementado...",
                    total: "Total : ",
                    infoAdic: "Informações Adicionais",
                    continuos: "Contínuos",
                    continuo: "Continuo",
                    tipoNF: "Tipo da Nota Fiscal",
                    movimentaEstq: "Movimenta Estoque",
                },

                linkPagamento_vue_html: {
                    linkPagamento: "Link para pagamento",
                    gerarLink: "Gerar novo Link",
                    copiarAreaTransf: "Copiar para área de transferência",
                },

                list_vue_html: {
                    cadastrados: "Cadastrados",
                    cadastradas: "Cadastradas",
                    vendas: "Vendas",
                    tudo: "Tudo",
                    aFaturar: "A Faturar",
                    faturado: "Faturado",
                    concluido: "Concluído",
                    cancelado: "Cancelado",
                    de: "De:",
                    ate: "Até:",
                    manipulacao: "Manipulacao",
                    repetirVendas: "Repetir selecionadas",
                },

                listExpedicao_vue_html: {
                    title: "Venda em Expedicao",
                    finalizar: "Finalizar",
                    setor: "Setor",
                },

                listOrcamento_vue_html: {
                    cadastrados: "Cadastrados",
                    orcamento: "Orçamento",
                    orcamentos: "Orçamentos",
                    rejeitados: "Rejeitados",
                    ambos: "Ambos",
                    de: "De:",
                    ate: "Até:",
                    confirmAlteracoes: "Confirmar Alterações",
                },

                pagamento_vue_html: {
                    recebimento: "Recebimento",
                    dtRecebimento: "Data Recebimento",
                    contaCorrente: "Conta Corrente",
                    especie: "Espécie",
                    bandeira: "Bandeira",
                    frmaRecebimento: "Forma Recebimento",
                    mtdParcelamento: "Metodo de Parcelamento",
                    autorizNSUCartao: "Autorização/NSU do cartão",
                    obs: "Observação",
                    vlrAbater: "Valor à subtrair",
                    juros: "Juros",
                    desconto: "Desconto",
                    totalRecebido: "Total Recebido",
                    inclRecebimento: "Incluir Recebimento",
                },

                revisaoCampos_vue_html: {
                    manipulacao: "Manipulação",
                    ativoPrincipal: "Ativo Principal",
                    posologia: "Posologia",
                    editPosologia: "Editar Posologia",
                    novaPosologia: "Nova Posologia",
                    paciente: "Paciente",
                    editPaciente: "Editar Paciente",
                    novoPaciente: "Novo Paciente",
                    prescritor: "Prescritor",
                    editPrescritor: "Editar Prescritor",
                    novoPrescritor: "Novo Prescritor",
                    nroReceita: "Número Receita",
                },

                vendaEstoque_vue_html: {
                    produto: "Produto",
                    quantidadeComprometida: "Qtd. Comprometida Produto",
                    quantidadeComprometidaTitle: "Quantidade Comprometida do produto em fracionando",
                    quantidadeComprometidaLote: "Qtd. Comprometida Lote",
                    quantidadeComprometidaLoteTitle: "Quantidade Comprometida do lote em fracionando",
                    quantidadeDisponivel: "Qtd. Disponível",
                    prodLote: "Produto Lote",
                    qtd: "Quantidade",
                    vlrUnit: "Valor Unit.",
                    acrescimo: "Acréscimo",
                    desconto: "Desconto",
                    vlrTotal: "Total Produto",
                    valorEmbalagem: "Valor Embalagem",
                    embalagemProd: "Embalagem do Produto",
                    kitEmbalagem: "Kit de Embalagem",
                    considerarEmbalagem: "Considerar Emb.",
                    unidadeMed: "Unidade Med.",
                    infoLote: "Informações do Lote",
                    dataVal: "Data de Validade",
                    qtdEstoque: "Quantidade em Estoque",
                    valorCusto: "Valor de Custo",
                    observacaoArmazenamento: "Observação Armazenamento",
                },

                financeiro: {
                    financeiro_vue_html: {
                        addRecebimento: "Adicionar Recebimento",
                        addParcela: "Adicionar Parcela",
                        criarParcelamento: "Criar parcelamento",
                    },

                    parcela_vue_html: {
                        descricao: "Descrição",
                        dataVencimento: "Data Vencimento",
                        valor: "Valor",
                    },

                    parcelamento_vue_html: {
                        metodoParcelamento: "Método de parcelamento",
                    },

                    preFaturamento_vue_html: {
                        valorSerPago: "Valor a ser pago",
                        troco: "Troco",
                        parcelas: "Parcelas",
                    },
                },
            },

            statusSistema: {
                statussistema_vue_html: {
                    title: "Status do Sistema",
                },
            },
        },

        child: {
            form: {
                combo_vue_html: {
                    nenhumaOpcEncont: "Nenhuma opção encontrada",
                },

                filepicker_vue_html: {
                    arqSelecionado: "Arquivo selecionado: ",
                    cliqueParaSelecArq: "Clique aqui para selecionar o arquivo",
                },

                sei_vue_html: {
                    tipoProcesso: "Tipo de Processo",
                    especificacoes: "Especificações",
                    classificacao: "Classificação",
                    interessado: "Interessado",
                    destino: "Destino",
                    obsUnidade: "Observações da Unidade",
                    endereco: "Endereço Eletrônico",
                    numProcesso: "Nº do Processo Anterior",
                    outrasInfo: "Outras Informações",
                },
            },

            grid: {
                grid_vue_html: {
                    procurarPor: "Procurar por...",
                    imprimirRegistrosAbaixo: "Imprimir registros exibidos abaixo",
                    gerarXLSdosArq: "Gerar aquivo do tipo Xls dos registros exibidos abaixo.",
                    gerarPDFdosArq: "Gerar aquivo do tipo Pdf dos registros exibidos abaixo.",
                    cash: "Conta",
                    de: "De: ",
                    ate: "Até: ",
                    pgtoDe: "Pagamento De: ",
                    grupo: "Grupo: ",
                    subgrupo: "Subgrupo: ",
                    fornecedor: "Fornecedor: ",
                    categoria: "Categoria: ",
                    acoes: "Ações",
                    exibindo: "Exibindo ",
                    dee: " de ",
                    registros: " registros",
                    addNovo: "Adicionar novo",
                },
            },

            rascunho: {
                rascunho_vue_html: {
                    rascunho: "Rascunho",
                    salvarRascunho: "Salvar Rascunho",
                    selecionar: "Selecionar",
                    remover: "Remover",
                },
            },

            searchCombo: {
                search_vue_html: {
                    digiteParaProcurar: "Digite para buscar...",
                },
            },
        },
    },

    Crud: null,
};

__.Crud = __.Components.parent.crud;

export default { __ };
