var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "fast-action-grid-panel" },
    [
      _c("div", { staticClass: "fast-action-grid-panel-left marcaDagua" }, [
        _c("div", { staticClass: "component-body" }, [
          _c("div", {
            staticClass: "component-body-info top-infos",
            staticStyle: { height: "35px" },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              {
                staticClass: "col-sm-4",
                staticStyle: { "padding-left": "15px", "padding-right": "0" },
              },
              [
                _c(
                  "a",
                  {
                    class:
                      _vm.onestoquebaixo == false &&
                      _vm.onestoquevencendo == false
                        ? "btnHomeFinanceiro active"
                        : "btnHomeFinanceiro",
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        _vm.onSemEstoque()
                      },
                    },
                  },
                  [
                    _vm._m(0),
                    _vm._v(" "),
                    _c("span", { staticClass: "component-body-row-text" }, [
                      _vm._v(_vm._s(_vm.produtosSemBaixo)),
                    ]),
                    _vm._v(
                      "\n                        " +
                        _vm._s(
                          _vm.$t(
                            "__.Components.parent.home.fastaction.produtos.produtos_vue_html.prodSemEstq"
                          )
                        ) +
                        "\n                    "
                    ),
                  ]
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "col-sm-4",
                staticStyle: { padding: "0 !important" },
              },
              [
                _c(
                  "a",
                  {
                    class:
                      _vm.onestoquebaixo == false && _vm.onsemestoque == false
                        ? "btnHomeFinanceiro active"
                        : "btnHomeFinanceiro",
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        _vm.onVencendoEstoque()
                      },
                    },
                  },
                  [
                    _vm._m(1),
                    _vm._v(" "),
                    _c("span", { staticClass: "component-body-row-text" }, [
                      _vm._v(_vm._s(_vm.produtosVencendo)),
                    ]),
                    _vm._v(
                      "\n                        " +
                        _vm._s(
                          _vm.$t(
                            "__.Components.parent.home.fastaction.produtos.produtos_vue_html.prodVencendo"
                          )
                        ) +
                        "\n                    "
                    ),
                  ]
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "col-sm-4",
                staticStyle: { "padding-left": "0", "padding-right": "15px" },
              },
              [
                _c(
                  "a",
                  {
                    class: [
                      "component-body-row-foot",
                      _vm.onsemestoque == false &&
                      _vm.onestoquevencendo == false
                        ? "btnHomeFinanceiro active"
                        : "btnHomeFinanceiro",
                    ],
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        _vm.onBaixoEstoque()
                      },
                    },
                  },
                  [
                    _vm._m(2),
                    _vm._v(" "),
                    _c("span", { staticClass: "component-body-row-text" }, [
                      _vm._v(_vm._s(_vm.produtosEstoqueBaixo)),
                    ]),
                    _vm._v(
                      "\n                        " +
                        _vm._s(
                          _vm.$t(
                            "__.Components.parent.home.fastaction.produtos.produtos_vue_html.estqBaixo"
                          )
                        ) +
                        "\n                    "
                    ),
                  ]
                ),
              ]
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-md-12" },
              _vm._l(_vm.produtosEstoque, function (produto) {
                return _c(
                  "a",
                  {
                    key: produto.id,
                    staticClass: "list-group-item",
                    staticStyle: { "padding-left": "46px" },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticStyle: { cursor: "pointer" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            _vm.onSearch(produto)
                          },
                        },
                      },
                      [
                        _c("i", {
                          staticClass: "fa fa-exclamation-circle",
                          class: _vm.getClass(produto),
                        }),
                        _vm._v(
                          "\n                            " +
                            _vm._s(
                              _vm.$t(
                                "__.Components.parent.home.fastaction.produtos.produtos_vue_html.produto"
                              )
                            ) +
                            "\n                            "
                        ),
                        _c("b", { staticClass: "color-yellow" }, [
                          _vm._v(
                            "\n                                " +
                              _vm._s(produto.descricao) +
                              "\n                            "
                          ),
                        ]),
                        _vm._v(" "),
                        produto.produtoLotes.length > 0
                          ? _c("span", [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(
                                    _vm.$t(
                                      "__.Components.parent.home.fastaction.produtos.produtos_vue_html.lote"
                                    )
                                  ) +
                                  "\n                                "
                              ),
                              _c("b", { staticClass: "color-yellow" }, [
                                _vm._v(
                                  "\n                                    " +
                                    _vm._s(produto.produtoLotes[0].descricao) +
                                    "\n                                "
                                ),
                              ]),
                              _vm._v(
                                "\n                                " +
                                  _vm._s(
                                    _vm.$t(
                                      "__.Components.parent.home.fastaction.produtos.produtos_vue_html.val"
                                    )
                                  ) +
                                  "\n                                "
                              ),
                              _c("b", { staticClass: "color-yellow" }, [
                                _vm._v(
                                  "\n                                    " +
                                    _vm._s(
                                      produto.produtoLotes[0].dataValidade
                                    ) +
                                    "\n                                "
                                ),
                              ]),
                            ])
                          : _vm._e(),
                      ]
                    ),
                  ]
                )
              })
            ),
            _vm._v(" "),
            _c("div", { staticClass: "col-md-12" }, [
              _c(
                "a",
                {
                  staticClass: "ver-mais list-group-item",
                  attrs: { type: "button" },
                  on: { click: _vm.onVerMais },
                },
                [
                  _vm._v(
                    "\n                        " +
                      _vm._s(
                        _vm.$t(
                          "__.Components.parent.home.fastaction.produtos.produtos_vue_html.verMais"
                        )
                      ) +
                      "\n                    "
                  ),
                ]
              ),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "fast-action-grid-panel-right" }, [
        _c("ul", [
          _c(
            "li",
            [
              _c("router-link", { attrs: { to: "/produto-lista" } }, [
                _c("i", {
                  staticClass: "fa fa-list fa-5x",
                  attrs: { "aria-hidden": "true" },
                }),
                _vm._v(" "),
                _c("span", [
                  _vm._v(
                    "\n                        " +
                      _vm._s(
                        _vm.$t(
                          "__.Components.parent.home.fastaction.produtos.produtos_vue_html.produtos"
                        )
                      ) +
                      "\n                    "
                  ),
                ]),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _vm.VALIDAR_PERMISSAO_USUARIO("lotes", "list")
            ? _c(
                "li",
                [
                  _c("router-link", { attrs: { to: "/produtolote-lista" } }, [
                    _c("i", {
                      staticClass: "fa fa-list fa-5x",
                      attrs: { "aria-hidden": "true" },
                    }),
                    _vm._v(" "),
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "__.Components.parent.home.fastaction.produtos.produtos_vue_html.lotes"
                          )
                        )
                      ),
                    ]),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.VALIDAR_PERMISSAO_USUARIO("produtos", "insert")
            ? _c(
                "li",
                [
                  _c("router-link", { attrs: { to: "/produto-edicao" } }, [
                    _c("i", {
                      staticClass: "fa barcode-plus",
                      attrs: { "aria-hidden": "true" },
                    }),
                    _vm._v(" "),
                    _c("span", [
                      _vm._v(
                        "\n                        " +
                          _vm._s(
                            _vm.$t(
                              "__.Components.parent.home.fastaction.produtos.produtos_vue_html.novoProd"
                            )
                          ) +
                          "\n                    "
                      ),
                    ]),
                  ]),
                ],
                1
              )
            : _vm._e(),
        ]),
      ]),
      _vm._v(" "),
      [
        _c(
          "shortcutComponent",
          {
            ref: "shortCutProdutoEstoque",
            attrs: { modalClass: "bg-modal", showAddNew: false },
            on: { "selection-item": _vm.onConfirmList },
          },
          [
            _vm.showList
              ? _c(
                  "div",
                  { attrs: { slot: "component" }, slot: "component" },
                  [
                    _c("produto-estoque-list-component", {
                      ref: "produtoEstoqueListComponent",
                      attrs: {
                        onsemestoque: _vm.onsemestoque,
                        onestoquebaixo: _vm.onestoquebaixo,
                        onestoquevencendo: _vm.onestoquevencendo,
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ]
        ),
      ],
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "i",
      { staticClass: "fa fa-archive", staticStyle: { position: "relative" } },
      [
        _c("i", {
          staticClass:
            "fa fa-exclamation-circle component-body-row-icon color-light-red",
        }),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "i",
      { staticClass: "fa fa-archive", staticStyle: { position: "relative" } },
      [
        _c("i", {
          staticClass:
            "fa fa-exclamation-circle component-body-row-icon color-orange",
        }),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "i",
      { staticClass: "fa fa-archive", staticStyle: { position: "relative" } },
      [
        _c("i", {
          staticClass:
            "fa fa-exclamation-circle component-body-row-icon color-yellow",
        }),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }