var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "crud crud-edit" },
    [
      _c(
        "fieldset-component",
        {
          attrs: {
            title: _vm.$t(
              "__.Components.parent.printModel.condicao_vue_html.condImpressao"
            ),
            collapsed: false,
          },
        },
        [
          _c("div", { attrs: { slot: "rows" }, slot: "rows" }, [
            _c("form", [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-sm-4" }, [
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c(
                        "label",
                        {
                          staticClass: "control-label",
                          attrs: { for: "dados" },
                        },
                        [
                          _vm._v(
                            "\n                                " +
                              _vm._s(
                                _vm.$t(
                                  "__.Components.parent.printModel.condicao_vue_html.origemCampo"
                                )
                              ) +
                              "\n                            "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("combo-component", {
                        attrs: {
                          title: _vm.$t(
                            "__.Components.parent.printModel.condicao_vue_html.separOndeOriginInfo"
                          ),
                          id: "dados",
                          name: "dados",
                          data: _vm.dadosOptions,
                          searchable: true,
                        },
                        model: {
                          value: _vm.dados,
                          callback: function ($$v) {
                            _vm.dados = $$v
                          },
                          expression: "dados",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-sm-4" }, [
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c(
                        "label",
                        {
                          staticClass: "control-label",
                          attrs: { for: "campoId" },
                        },
                        [
                          _vm._v(
                            "\n                                " +
                              _vm._s(
                                _vm.$t(
                                  "__.Components.parent.printModel.condicao_vue_html.campoComparar"
                                )
                              ) +
                              "\n                            "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("combo-component", {
                        attrs: {
                          id: "campoId",
                          name: "campoId",
                          data: _vm.camposOptions,
                          searchable: true,
                        },
                        model: {
                          value: _vm.campoId,
                          callback: function ($$v) {
                            _vm.campoId = $$v
                          },
                          expression: "campoId",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-sm-4" }, [
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c(
                        "label",
                        {
                          staticClass: "control-label",
                          attrs: { for: "valorId" },
                        },
                        [
                          _vm._v(
                            "\n                                " +
                              _vm._s(
                                _vm.$t(
                                  "__.Components.parent.printModel.condicao_vue_html.vlrComparar"
                                )
                              ) +
                              "\n                            "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _vm.isTextValue
                        ? _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.valorId,
                                expression: "valorId",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: {
                              autocomplete: "off",
                              type: "text",
                              id: "valorId",
                              name: "valorId",
                            },
                            domProps: { value: _vm.valorId },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.valorId = $event.target.value
                              },
                            },
                          })
                        : _c("combo-component", {
                            attrs: {
                              id: "valorId",
                              name: "valorId",
                              data: _vm.valoresOptions,
                              searchable: true,
                            },
                            model: {
                              value: _vm.valorId,
                              callback: function ($$v) {
                                _vm.valorId = $$v
                              },
                              expression: "valorId",
                            },
                          }),
                    ],
                    1
                  ),
                ]),
              ]),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }