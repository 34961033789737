var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("grid-component", {
    attrs: {
      data: _vm.gridData,
      columns: _vm.gridColumns,
      subColumns: _vm.gridColumnsSub,
      subDataName: "itens",
      showExpand: true,
      conditionShowSubData: _vm.conditionShowSubData,
      showRemoveItem: false,
      showEditItem: false,
      showTableHead: false,
      showTableFoot: false,
      showSortIcons: false,
      extraActions: _vm.extraActions,
    },
    on: { "extra-action": _vm.onExtraAction },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }