var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "fast-action-grid-panel" },
    [
      _c("div", { staticClass: "fast-action-grid-panel-left marcaDagua" }, [
        _c("div", { staticClass: "component-body" }, [
          _c("div", { staticClass: "top-infos" }, [
            _vm._v(
              "\n                " +
                _vm._s(
                  _vm.$t(
                    "__.Components.parent.home.fastaction.entregas.entregas_vue_html.entregas"
                  )
                ) +
                "\n            "
            ),
          ]),
          _vm._v(" "),
          _vm.VALIDAR_PERMISSAO_USUARIO("etiqueta", "list")
            ? _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  {
                    staticClass: "col-sm-6",
                    staticStyle: {
                      "padding-left": "15px",
                      "padding-right": "0",
                    },
                  },
                  [
                    _c(
                      "a",
                      {
                        class: !_vm.onPostagem
                          ? "btnHomeFinanceiro active"
                          : "btnHomeFinanceiro",
                        attrs: { href: "#" },
                        on: {
                          click: function ($event) {
                            _vm.onEntregas()
                          },
                        },
                      },
                      [
                        _c("i", {
                          staticClass: "fas fa-truck",
                          staticStyle: { position: "relative" },
                        }),
                        _vm._v(
                          "\n                        " +
                            _vm._s(
                              _vm.$t(
                                "__.Components.parent.home.fastaction.entregas.entregas_vue_html.entregas"
                              )
                            ) +
                            "\n                    "
                        ),
                      ]
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "col-sm-6",
                    staticStyle: {
                      "padding-left": "0",
                      "padding-right": "15px",
                    },
                  },
                  [
                    _c(
                      "a",
                      {
                        class: _vm.onPostagem
                          ? "btnHomeFinanceiro active"
                          : "btnHomeFinanceiro",
                        attrs: { href: "#" },
                        on: {
                          click: function ($event) {
                            _vm.onPostagens()
                          },
                        },
                      },
                      [
                        _c("i", {
                          staticClass: "fas fa-truck-loading",
                          staticStyle: { position: "relative" },
                        }),
                        _vm._v(
                          "\n                        " +
                            _vm._s(
                              _vm.$t(
                                "__.Components.parent.home.fastaction.entregas.entregas_vue_html.postagens"
                              )
                            ) +
                            "\n                    "
                        ),
                      ]
                    ),
                  ]
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-md-12" },
              _vm._l(_vm.vendasEntregas, function (venda) {
                return _c(
                  "a",
                  { key: venda.id, staticClass: "list-group-item" },
                  [
                    _c(
                      "div",
                      {
                        staticStyle: { cursor: "pointer" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            _vm.onSearch(venda)
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n                            " +
                            _vm._s(
                              _vm.$t(
                                "__.Components.parent.home.fastaction.orcamentos.orcamentos_vue_html.cod"
                              )
                            ) +
                            "\n                            "
                        ),
                        _c("b", { staticClass: "color-yellow" }, [
                          _vm._v(_vm._s(venda.codigo)),
                        ]),
                        _vm._v(
                          "\n                            /\n                            "
                        ),
                        _c("b", { staticStyle: { color: "yellow" } }, [
                          _vm._v(_vm._s(venda.clienteNome)),
                        ]),
                        _vm._v(
                          "\n                            " +
                            _vm._s(
                              _vm.$t(
                                "__.Components.parent.home.fastaction.entregas.entregas_vue_html.dataHoraEntrega"
                              )
                            ) +
                            "\n                            "
                        ),
                        _c("b", { staticStyle: { color: "yellow" } }, [
                          _vm._v(
                            _vm._s(_vm.getDateHourString(venda.dataEntrega))
                          ),
                        ]),
                        _vm._v(
                          "\n                            /\n                            " +
                            _vm._s(
                              _vm.$t(
                                "__.Components.parent.home.fastaction.entregas.entregas_vue_html.local"
                              )
                            ) +
                            "\n                            "
                        ),
                        _c(
                          "b",
                          {
                            staticClass: "valores",
                            class: {
                              valorDif:
                                venda.valorFinal != venda.valorAtualizado,
                            },
                            staticStyle: { color: "yellow" },
                          },
                          [
                            _vm._v(
                              "\n                                " +
                                _vm._s(venda.localEntregaDescricao) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _vm.onPostagem && venda.codigoRastreio
                          ? _c("span", [
                              _vm._v(
                                "\n                                / " +
                                  _vm._s(_vm.$t("__.ts.codigoRastreio")) +
                                  ":\n                            "
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.onPostagem && venda.codigoRastreio
                          ? _c(
                              "b",
                              {
                                staticClass: "color-yellow",
                                staticStyle: { color: "yellow" },
                              },
                              [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(venda.codigoRastreio) +
                                    "\n                            "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", { attrs: { id: "container-icon" } }, [
                      _c("div", { staticClass: "container-home-icon" }, [
                        !_vm.onPostagem
                          ? _c(
                              "div",
                              {
                                staticClass: "home-icon bg-green",
                                attrs: {
                                  "data-toggle": "tooltip",
                                  title: _vm.$t("__.ts.marcarEntregue"),
                                  "data-placement": "left",
                                },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    _vm.onMarcarEntregue(venda)
                                  },
                                },
                              },
                              [
                                _c("i", {
                                  staticClass: "fa fa-check",
                                  attrs: { "aria-hidden": "true" },
                                }),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        !_vm.onPostagem
                          ? _c(
                              "div",
                              {
                                staticClass: "home-icon bg-secondary",
                                attrs: {
                                  "data-toggle": "tooltip",
                                  title: _vm.$t(
                                    "__.Components.parent.home.fastaction.entregas.entregas_vue_html.imprimirRomaneio"
                                  ),
                                  "data-placement": "left",
                                },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    _vm.onImprimirRomaneio(venda)
                                  },
                                },
                              },
                              [
                                _c("i", {
                                  staticClass: "fa fa-print",
                                  attrs: { "aria-hidden": "true" },
                                }),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        !_vm.onPostagem
                          ? _c(
                              "div",
                              {
                                staticClass: "home-icon bg-green",
                                attrs: {
                                  "data-toggle": "tooltip",
                                  title: _vm.$t("__.ts.tentativasEntrega"),
                                  "data-placement": "left",
                                },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    _vm.onTentativaEntrega(venda)
                                  },
                                },
                              },
                              [
                                _c("i", {
                                  staticClass: "fa fa-truck",
                                  attrs: { "aria-hidden": "true" },
                                }),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        !_vm.onPostagem
                          ? _c(
                              "div",
                              {
                                staticClass: "home-icon bg-blue",
                                attrs: {
                                  "data-toggle": "tooltip",
                                  title: _vm.$t(
                                    "__.Components.parent.home.fastaction.entregas.entregas_vue_html.visualizarVenda"
                                  ),
                                  "data-placement": "left",
                                },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    _vm.onSearch(venda)
                                  },
                                },
                              },
                              [
                                _c("i", {
                                  staticClass: "fa fa-eye",
                                  attrs: { "aria-hidden": "true" },
                                }),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.onPostagem
                          ? _c(
                              "div",
                              {
                                staticClass: "home-icon bg-green",
                                attrs: {
                                  "data-toggle": "tooltip",
                                  title: _vm.$t(
                                    "__.Components.parent.venda.editSingle_vue_html.gerarCodigoRastreio"
                                  ),
                                  "data-placement": "left",
                                },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    _vm.onCorreios(venda)
                                  },
                                },
                              },
                              [
                                _c("i", {
                                  staticClass: "fas fa-barcode",
                                  attrs: { "aria-hidden": "true" },
                                }),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.onPostagem && venda.codigoRastreio
                          ? _c(
                              "div",
                              {
                                staticClass: "home-icon bg-green",
                                attrs: {
                                  "data-toggle": "tooltip",
                                  title: _vm.$t(
                                    "__.Crud.etiquetas.list_vue_html.imprimirEtiquetas"
                                  ),
                                  "data-placement": "left",
                                },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    _vm.onImprimirEtiquetas(venda)
                                  },
                                },
                              },
                              [
                                _c("i", {
                                  staticClass: "fa fa-print",
                                  attrs: { "aria-hidden": "true" },
                                }),
                              ]
                            )
                          : _vm._e(),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          attrs: {
                            id: "icon-button",
                            "data-toggle": "tooltip",
                            "data-placement": "left",
                            "data-original-title": "Create",
                          },
                        },
                        [
                          _c("p", { staticClass: "plus" }, [_vm._v("+")]),
                          _vm._v(" "),
                          _c("div", { staticClass: "edit" }, [
                            _c(
                              "p",
                              {
                                staticClass: "icon",
                                on: {
                                  click: function ($event) {
                                    _vm.dragAndDrop = true
                                  },
                                },
                              },
                              [
                                _c("i", {
                                  staticClass: "fa fa-question",
                                  attrs: { "aria-hidden": "true" },
                                }),
                              ]
                            ),
                          ]),
                        ]
                      ),
                    ]),
                  ]
                )
              })
            ),
          ]),
        ]),
        _vm._v(" "),
        _c(
          "a",
          {
            staticClass: "ver-mais list-group-item",
            attrs: { type: "button" },
            on: { click: _vm.onVerMais },
          },
          [
            _vm._v(
              "\n            " +
                _vm._s(
                  _vm.$t(
                    "__.Components.parent.home.fastaction.continuos.continuos_vue_html.verMais"
                  )
                ) +
                "\n        "
            ),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("tentativasEntregaComponent", { ref: "tentativasEntregaComponent" }),
      _vm._v(" "),
      _c("impressaoComponent", { ref: "impressaoComponent" }),
      _vm._v(" "),
      _c(
        "shortcutComponent",
        {
          ref: "shortcutComponent",
          attrs: { modalClass: "bg-modal", showBtnOk: false },
        },
        [
          _c(
            "div",
            { attrs: { slot: "component" }, slot: "component" },
            [
              _c("list-entrega-component", {
                ref: "listEntregaComponent",
                attrs: { CalledByShortCut: true },
              }),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "shortcutComponent",
        {
          ref: "vendaEtiquetasShortcutComponent",
          attrs: { modalClass: "bg-modal", showBtnOk: false },
        },
        [
          _c(
            "div",
            { attrs: { slot: "component" }, slot: "component" },
            [
              _c("etiquetas-list-component", {
                ref: "vendaEtiquetasComponent",
                attrs: { CalledByShortCut: true },
              }),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }