import Vue from "vue";

import dateTimePickerComponent from "@/components/child/form/datetimepicker.vue";
import moedaComponent from "@/components/child/form/moeda.vue";
import textareaComponent from "@/components/child/form/textarea.vue";
import { Component } from "@/decorators";
import ContaReceberModel from "@/models/financeiro/contaReceberModel";

import "../../crud/crud.scss";

@Component({
    components: {
        textareaComponent,
        moedaComponent,
        dateTimePickerComponent,
    },
})
export default class ParcelaComponent extends Vue {
    public model = new ContaReceberModel();

    private clear() {
        this.model = new ContaReceberModel();
        this.model.contabilizar = false;
        this.model.dataLancamento = new Date();
        this.$validator.reset();
    }

    public load(saldo: number, desconto: number) {
        this.clear();

        this.model.valorTotal = 0;
        if (saldo > 0) {
            this.model.valorTotal = saldo;
        }
        this.model.totalDesconto = desconto;
    }

    public async validate() {
        return await this.$validator.validateAll();
    }

    private mounted() {
        this.clear();
    }
}
