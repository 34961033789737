import { mapGetters } from "vuex";

import { GridAction } from "@/components/child/grid/gridAction";
import { GridColors, GridColumn, GridColumnSearchColor, GridColumnType } from "@/components/child/grid/gridColumn";
import ListComponentBase from "@/components/parent/crud/listComponentBase";
import { Component } from "@/decorators";
import ManipulacaoOrdemModel from "@/models/manipulacaoOrdemModel";
import PaginationModel from "@/models/paginationModel";
import ManipulacaoOrdemService from "@/services/manipulacaoOrdemService";
import ReceitaArquivoService from "@/services/receitaArquivoService";
import { Getters } from "@/store/store";

@Component({
    computed: {
        ...mapGetters(["VALIDAR_PERMISSAO_USUARIO"] as Getters),
    },
})
export default class ManipulacaoListComponent extends ListComponentBase<ManipulacaoOrdemModel> {
    // State computed props
    VALIDAR_PERMISSAO_USUARIO: (telaDescricao: string, acaoController: string) => boolean;

    private manipulacaoOrdemService = new ManipulacaoOrdemService();
    private receitaArquivoService = new ReceitaArquivoService();

    extraActions: Array<object> = [];
    gridExtraActionsVisualizar: Array<GridAction> = [];
    gridExtraActionsConferirLote: Array<GridAction> = [];
    gridExtraActionsImprimir: Array<GridAction> = [];
    gridExtraActionsImprimirRotulo: Array<GridAction> = [];
    gridExtraActionsConcluir: Array<GridAction> = [];
    gridExtraActionsExibirReceitas: Array<GridAction> = [];

    pageSize = 10;

    get gridColumns(): Array<GridColumn> {
        return [
            new GridColumnSearchColor("codigo", this.$t("__.ts.codigo"), GridColumnType.String),
            new GridColumnSearchColor(
                "formaFarmaceuticaDescricao",
                this.$t("__.ts.formfarmceu"),
                GridColumnType.String,
            ),
            new GridColumnSearchColor("pacienteNome", this.$t("__.ts.paciente"), GridColumnType.String),
            new GridColumnSearchColor("codigoVenda", this.$t("__.ts.codVenda"), GridColumnType.String),
            new GridColumn("observacao", this.$t("__.ts.obss"), GridColumnType.String),
        ];
    }

    private beforeMount() {
        this.setProps(this.manipulacaoOrdemService, "manipulacaorodem", "codigo");
    }

    public async loadMethod() {
        return await this.manipulacaoOrdemService
            .listManipulacaoHomePagination(
                this.gridFilterKey,
                this.gridSortKey,
                this.gridSortOrder,
                this.pageIndex,
                this.pageSize,
            )
            .withLoading()
            .resolveWithJSON<PaginationModel<ManipulacaoOrdemModel>>();
    }

    public async afterLoad() {
        this.gridExtraActionsVisualizar.splice(0);
        this.gridExtraActionsConferirLote.splice(0);
        this.gridExtraActionsImprimir.splice(0);
        this.gridExtraActionsImprimirRotulo.splice(0);
        this.gridExtraActionsConcluir.splice(0);
        this.gridExtraActionsExibirReceitas.splice(0);
        this.extraActions.splice(0);

        for (let i = 0; i < this.gridData.length; i++) {
            if (this.VALIDAR_PERMISSAO_USUARIO("manipulacoes_venda", "get")) {
                this.gridExtraActionsVisualizar.push(
                    new GridAction("visualizar-manipulacao", "Visualizar Manipulação", "fa fa-search", GridColors.BLUE),
                );
            }

            if (this.VALIDAR_PERMISSAO_USUARIO("manipulacoes_venda", "ConferirLotesManipulacao")) {
                this.gridExtraActionsConferirLote.push(
                    new GridAction(
                        "conferir-lote",
                        "Conferir Lote/Estoque Manipulação",
                        "fa fa-balance-scale",
                        GridColors.ORANGE,
                    ),
                );
            }

            if (this.VALIDAR_PERMISSAO_USUARIO("manipulacoes_venda", "ImprimirManipulacao")) {
                this.gridExtraActionsImprimir.push(
                    new GridAction("imprimir-manipulacao", "Imprimir Manipulação", "fa fa-print", GridColors.DARKGRAY),
                );
            }

            if (this.VALIDAR_PERMISSAO_USUARIO("manipulacoes_venda", "ImprimirRotuloManipulacao")) {
                this.gridExtraActionsImprimirRotulo.push(
                    new GridAction(
                        "imprimir-manipulacao-rotulo",
                        this.$t("__.ts.printRotuloManipu") as string,
                        "fa fa-tag",
                        GridColors.GREEN,
                    ),
                );
            }

            if (this.VALIDAR_PERMISSAO_USUARIO("manipulacoes_venda", "ConcluirManipulacao")) {
                this.gridExtraActionsConcluir.push(
                    new GridAction("concluir-manipulacao", "Concluir Manipulação.", "fa fa-check", GridColors.GREEN),
                );
            }

            this.gridExtraActionsExibirReceitas.push(
                new GridAction(
                    "exibir-receitas",
                    this.$t("__.Components.parent.home.fastaction.manipulacao.manipulacao_vue_html.receita") as string,
                    "fa fa-paperclip",
                    GridColors.RED,
                    this.gridData[i].receitas.length > 0,
                ),
            );
        }

        this.extraActions.push(this.gridExtraActionsImprimir);
        this.extraActions.push(this.gridExtraActionsImprimirRotulo);
        this.extraActions.push(this.gridExtraActionsConferirLote);
        this.extraActions.push(this.gridExtraActionsExibirReceitas);
        this.extraActions.push(this.gridExtraActionsVisualizar);
        this.extraActions.push(this.gridExtraActionsConcluir);
    }

    private onExtraAction(name: string, model: ManipulacaoOrdemModel) {
        if (name.trim() == "visualizar-manipulacao") {
            this.$router.push("/manipulacaoordem-edicao/" + model.id);
        } else if (name.trim() == "conferir-lote") {
            this.$emit("conferir-lote", model);
        } else if (name.trim() == "imprimir-manipulacao") {
            this.$emit("imprimir-manipulacao", model);
        } else if (name.trim() == "imprimir-manipulacao-rotulo") {
            this.$emit("imprimir-manipulacao-rotulo", model);
        } else if (name.trim() == "concluir-manipulacao") {
            this.$emit("concluir-manipulacao", model);
        } else if (name.trim() == "exibir-receitas") {
            this.onExibirReceita(model);
        }
    }

    public async onExibirReceita(manipulacao: ManipulacaoOrdemModel) {
        await this.receitaArquivoService.showFiles(manipulacao.receitas);
    }
}
