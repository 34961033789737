import Vue from "vue";
import { mapGetters, mapState } from "vuex";

import actionBarComponent from "@/components/child/actionBar/actionBar.vue";
import { Component } from "@/decorators";
import UsuarioModel from "@/models/usuarioModel";
import { IService } from "@/services/base/iService";
import { AppState, Getters, SessionActions } from "@/store/store";
import { copyObject } from "@/utils/common/copyObject";

import "../crud/crud.scss";

@Component({
    components: {
        actionBarComponent,
    },
    computed: {
        ...mapState({
            usuarioLogado: (state: AppState) => state.session.usuarioLogado,
        }),
        ...mapGetters(["VALIDAR_PERMISSAO_SOMENTE_CONSULTA", "HAS_PERMISSAO_PERFIL"] as Getters),
    },
})
export default class EditComponentBase<T> extends Vue {
    // State computed props
    usuarioLogado: UsuarioModel;
    VALIDAR_PERMISSAO_SOMENTE_CONSULTA: (telaDescricao: string) => boolean;
    HAS_PERMISSAO_PERFIL: (isRede: boolean, isFranqueador: boolean, isSuporte: boolean) => Promise<boolean>;

    protected service: IService<T> = null;
    protected tela = null;
    protected newModel = null;

    isModal = false;
    validaFranquiaId = false;
    somenteConsulta = false;
    model: T = null;
    modelId = 0;

    public async setProps(service: IService<T>, tela: string, newModel: T, isModal = false, validaFranquiaId = false) {
        this.service = service;
        this.tela = tela;
        this.newModel = newModel;
        this.model = newModel;
        this.isModal = isModal;
        this.validaFranquiaId = validaFranquiaId;

        await this.$store.dispatch(SessionActions.LOAD_USUARIO_LOGADO);
    }

    public async load(customId = null): Promise<void> {
        if (!(await this.onPreLoadModel())) {
            return null;
        }

        copyObject(this.newModel, this.model);

        if (this.modelId || customId) {
            try {
                const data = await this.service
                    .get(customId ?? this.modelId)
                    .withLoading()
                    .resolveWithJSON<T>();

                this.model["updateFrom"](data);
                await this.onLoadModel(data);

                const somenteConsultaSemFranquiaId =
                    this.validaFranquiaId &&
                    !(await this.HAS_PERMISSAO_PERFIL(false, false, false)) &&
                    this.model["franquiaId"] == null;

                this.somenteConsulta =
                    this.VALIDAR_PERMISSAO_SOMENTE_CONSULTA(this.tela) || somenteConsultaSemFranquiaId;
            } catch {
                this.$router.back();
            }
        }
    }

    public async save() {
        if (!(await this.onPreSave())) {
            return false;
        }

        try {
            const isValid = await this.$validator.validateAll();
            if (isValid) {
                const response = await this.service[!this.model["id"] ? "insert" : "update"](this.model)
                    .withLoading()
                    .resolveWithResponse();

                const id = Number(response.headers.get("Id"));

                if (response.ok) {
                    await this.onSucessoSave(id);
                    await this.$showInclusaoUpdate(this.model["id"]);
                    if (this.isModal) {
                        this.model["id"] = id;
                        this.$emit("save-ok", this.model);
                    } else {
                        this.$router.back();
                    }
                    return true;
                }
            } else {
                this.$focusErrorField();
            }
        } catch {}
    }

    private cancel() {
        this.$router.back();
    }

    // Callbacks
    public async onPreLoadModel() {
        return true;
    }

    public async onLoadModel(model: T) {}

    public async onPreSave() {
        return true;
    }

    public async onSucessoSave(id?: number) {}

    public async afterMounted() {}

    public async mounted() {
        await this.$store.dispatch(SessionActions.LOAD_USUARIO_LOGADO);

        if (this.$route.params.id && !this.isModal) {
            this.modelId = +this.$route.params.id;
            this.load();
        }
        this.afterMounted();
    }
}
