import SepararValoresPor from "@/models/enum/separarValoresPor";
import ContaReceberModel from "@/models/financeiro/contaReceberModel";
import ContaReceberRecebimentoModel from "@/models/financeiro/contaReceberRecebimentoModel";
import LiquidacaoModel from "@/models/financeiro/pagamentoContasModel";
import nfetch from "@/utils/events/nfetch";

import { IService } from "../base/iService";

export default class ContaReceberService extends IService<ContaReceberModel> {
    constructor() {
        super("ContaReceber");
    }

    public getMovimentacoesByDate(
        separarPor: SepararValoresPor,
        dataInicial: Date,
        dataFinal: Date,
    ): Promise<Response> {
        return nfetch(
            `/${
                this.controllerName
            }/GetMovimentacoesByDate?separarPor=${separarPor}&dataInicial=${dataInicial.toJSON()}&dataFinal=${dataFinal.toJSON()}`,
            {
                credentials: "same-origin",
            },
        );
    }

    public adicionarRecebimento(model: LiquidacaoModel): Promise<Response> {
        //@ts-ignore
        return nfetch(`/${this.controllerName}/AdicionarRecebimento`, {
            method: "post",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json; charset=UTF-8",
                "XSRF-TOKEN": this.getCookie("XSRF-TOKEN"),
            },
            body: JSON.stringify(model),
        });
    }

    public liquidarPorRecebimentos(model: Array<ContaReceberRecebimentoModel>): Promise<Response> {
        //@ts-ignore
        return nfetch(`/${this.controllerName}/LiquidarPorRecebimentos`, {
            method: "post",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json; charset=UTF-8",
                "XSRF-TOKEN": this.getCookie("XSRF-TOKEN"),
            },
            body: JSON.stringify(model),
        })
            .then((response: Response) => {
                if (response.ok) {
                    return response;
                } else {
                    return this.mensagemErro(response, "Liquidado");
                }
            })
            .catch(() => {});
    }

    public excluirRecebimentoWithModel(model: ContaReceberRecebimentoModel): Promise<Response> {
        //@ts-ignore
        return nfetch(`/${this.controllerName}/ExcluirRecebimentoWithModel`, {
            method: "post",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json; charset=UTF-8",
                "XSRF-TOKEN": this.getCookie("XSRF-TOKEN"),
            },
            body: JSON.stringify(model),
        })
            .then((response: Response) => {
                if (response.ok) {
                    return response;
                } else {
                    return this.mensagemErro(response, "Excluindo Recebimento");
                }
            })
            .catch(() => {});
    }

    public updateRecebimento(model: ContaReceberRecebimentoModel): Promise<Response> {
        //@ts-ignore
        return nfetch(`/${this.controllerName}/UpdateRecebimento`, {
            method: "post",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json; charset=UTF-8",
            },
            body: JSON.stringify(model),
        });
    }

    public listParceladas(
        filterKey: string,
        sortKey: string,
        sortOrder: string,
        pageIndex: number,
        pageSize: number,
        customProperties: { [key: string]: any } = {},
    ): Promise<Response> {
        const encodedFilter = encodeURIComponent(filterKey);
        let custom = "";
        for (const key in customProperties) {
            if (customProperties[key] != undefined) {
                custom += `&${key}=${customProperties[key]}`;
            }
        }
        return nfetch(
            `/${this.controllerName}/ListContaReceberParceladas?filterKey=${encodedFilter}&sortKey=${sortKey}&sortOrder=${sortOrder}&pageIndex=${pageIndex}&pageSize=${pageSize}` +
                custom,
            {
                credentials: "same-origin",
            },
        );
    }

    public listVendas(
        filterKey: string,
        sortKey: string,
        sortOrder: string,
        pageIndex: number,
        pageSize: number,
    ): Promise<Response> {
        const encodedFilter = encodeURIComponent(filterKey);

        return nfetch(
            `/${this.controllerName}/ListContaReceberVendas?filterKey=${encodedFilter}&sortKey=${sortKey}&sortOrder=${sortOrder}&pageIndex=${pageIndex}&pageSize=${pageSize}`,
            {
                credentials: "same-origin",
            },
        );
    }
}
