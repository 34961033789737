import FileHelper from "@/utils/common/fileHelper";
import nfetch from "@/utils/events/nfetch";

export abstract class IServiceAnexo {
    protected controllerName: string;

    constructor(controllerName: string) {
        this.controllerName = controllerName;
    }

    public listFiles(id: number): Promise<Response> {
        return nfetch(`/${this.controllerName}/List?id=${id}`, { credentials: "same-origin" });
    }

    public insertFile(id: number, descricao: string, file: File): Promise<Response> {
        const data = new FormData();
        const ext = file.getExtension();
        data.append("file", file, file.name);
        return nfetch(`/${this.controllerName}/Insert?id=${id}&descricao=${descricao}&extensao=${ext}`, {
            method: "post",
            credentials: "same-origin",
            body: data,
        });
    }

    public async showFiles(ids: number[]) {
        for (const id of ids) {
            const response = await this.get(id);
            await FileHelper.openBlank(response);
        }
    }

    public get(id: number): Promise<Response> {
        return nfetch(`/${this.controllerName}/Get?id=${id}`, { credentials: "same-origin" });
    }

    public delete(ids: number[]): Promise<Response> {
        return nfetch(`/${this.controllerName}/Delete`, {
            method: "post",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json; charset=UTF-8",
            },
            body: JSON.stringify(ids),
        });
    }
}
