import qz from "qz-tray";

import nfetch from "../../utils/events/nfetch";

import { HtmlPrintPageInfo, mountHtml, PrinterBackend, PrinterQueue } from "./printerQueue";

export class QzTrayPrinterBackend implements PrinterBackend {

    public name = "qztray";

    public async flushQueue(printerQueue: PrinterQueue) {
        if (!(await this.init())) {
            return false;
        }

        // Obter lista de páginas a serem impressas
        const pages = printerQueue.dequeueAll();
        if (!pages.length) {
            return true;
        }

        // Imprimir páginas
        try {
            await this.qzPrintPages(printerQueue.printerName, pages);
        } catch (e) {
            console.error(e);
            return false;
        }

        return true;
    }

    // Inicialização
    // ==========================================================================================

    /** Retorna true se a conexão foi bem sucedida, e falso se houve algum erro. */
    public async init() {
        if (qz.websocket.isActive()) {
            return true;
        }

        const nfetchOptions = { cache: "no-store", headers: { "Content-Type": "text/plain" } };

        // Definir certificado da chave privada que assinará as requisições
        qz.security.setCertificatePromise(async (resolve, reject) => {
            const res = await nfetch(`/QzTraySigning/GetCertificate`, nfetchOptions);
            res.ok ? resolve(res.text()) : reject(res.text());
        });

        // Configurar o endereço para assinatura do certificado de confiança
        qz.security.setSignatureAlgorithm("SHA512");
        qz.security.setSignaturePromise((msg: string) => async (resolve, reject) => {
            const res = await nfetch(`/QzTraySigning/SignMessage?msg=${msg}`, nfetchOptions);
            res.ok ? resolve(res.text()) : reject(res.text());
        });

        // Conectar ao QZ Tray do computador atual
        try {
            await this.qzConnect().withTimeout(2);
            await this.updatePrinterQueues();
        } catch (e) {
            return false;
        }

        return true;
    }

    // Métodos privados para abstração do QZ Tray
    // ==========================================================================================

    private static printerQueues: PrinterQueue[] = [];

    private async updatePrinterQueues() {
        const printers = await qz.printers.details();

        // Remover filas das impressoras que não estão mais disponíveis
        for (let i = 0; i < QzTrayPrinterBackend.printerQueues.length; i++) {
            if (!printers.some(p => p.name === QzTrayPrinterBackend.printerQueues[i].printerName)) {
                QzTrayPrinterBackend.printerQueues.splice(i, 1);
            }
        }

        // Inserir filas das impressoras que não estão na lista
        for (const printer of printers) {
            if (!QzTrayPrinterBackend.printerQueues.some(p => p.printerName === printer.name)) {
                QzTrayPrinterBackend.printerQueues.push(new PrinterQueue(printer.name, this));
            }
        }
    }

    public async getPrinters(): Promise<PrinterQueue[]> {
        if (!(await this.init())) {
            return [];
        }
        // Obter lista de impressoras com suas configurações
        try {
            await this.updatePrinterQueues();
            return QzTrayPrinterBackend.printerQueues;
        } catch (e) {
            QzTrayPrinterBackend.printerQueues = [];
            return [];
        }
    }

    private async qzConnect() {
        await qz.websocket.connect();
    }

    private async qzPrintPages(printerName: string, pages: HtmlPrintPageInfo[]) {
        const config = qz.configs.create(printerName);
        await qz.print(config, pages.map(page => ({
            type: "pixel",
            format: "html",
            flavor: "plain",
            data: mountHtml(page.pageHtml)
        })));
    }
}
