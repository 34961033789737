import nfetch from "../utils/events/nfetch";

export default class CorreiosService {
    public gerarCodigoRastreio(vendaIds: number[]): Promise<Response> {
        return nfetch(`/Correios/GerarCodigoRastreio`, {
            method: "post",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json; charset=UTF-8",
            },
            body: JSON.stringify(vendaIds),
        });
    }
}
