import Vue from "vue";
import { mapMutations, mapState } from "vuex";

import gridComponent from "@/components/child/grid/grid.vue";
import { Component } from "@/decorators";
import StatusProdutoLote from "@/models/enum/statusProdutoLote";
import ProdutoLoteModel from "@/models/produtoLoteModel";
import ProdutoLoteService from "@/services/produtoLoteService";
import { FastActionLastPack, FastActionPack } from "@/store/fastActionStore";
import { AppState, Mutations } from "@/store/store";

import atualizarStatusComponent from "../../../../parent/crud/produtolote/atualizarStatus.vue";
import AtualizarStatusEditComponent from "../../../crud/produtolote/atualizarStatus";

import "./../fastactionsgrid.scss";

@Component({
    components: {
        gridComponent,
        atualizarStatusComponent,
    },
    computed: mapState<AppState>({
        fastActionPack: state => state.fastAction.fastActionPack,
    }),
    methods: {
        ...mapMutations(["SET_LASTCOUNTPACK"] as Mutations),
    },
})
export default class FastActionFracionamentoComponent extends Vue {
    // State computed props
    fastActionPack: FastActionPack;
    SET_LASTCOUNTPACK: (data: FastActionLastPack) => void;

    private service = new ProdutoLoteService();
    private lotes: Array<ProdutoLoteModel> = [];

    private totalEmFracionamento = 0;
    private totalAguardandoLiberacao = 0;

    private lotesEmFracionamento = true;
    private lotesAguardandoLiberacao = false;

    private atualizarStatusComponent: AtualizarStatusEditComponent = null;

    private onVerMais() {
        this.$router.push("/produtolote-lista/");
    }

    private onVisualizar(model: ProdutoLoteModel) {
        this.$router.push("/produtolote-edicao/" + model.id);
    }

    private async onAtualizarStatus(model: ProdutoLoteModel) {
        await this.atualizarStatusComponent.show(model);
    }

    private onClickEmFracionamento() {
        this.lotesEmFracionamento = true;
        this.lotesAguardandoLiberacao = false;
        this.loadProdutoLote();
    }

    private onClickAguardandoLiberacao() {
        this.lotesAguardandoLiberacao = true;
        this.lotesEmFracionamento = false;
        this.loadProdutoLote();
    }

    private async loadProdutoLote() {
        this.lotes = await this.service
            .listFracionamentoHome(
                this.lotesEmFracionamento ? StatusProdutoLote.Fracionamento : StatusProdutoLote.AguardandoLiberacao,
            )
            .withLoading()
            .resolveWithJSON<ProdutoLoteModel[]>();

        const { totalAguardandoLiberacao, totalEmFracionamento } = await this.service
            .totalFracionamentoHome()
            .withLoading()
            .resolveWithJSON<{ totalEmFracionamento: number; totalAguardandoLiberacao: number }>();

        this.totalAguardandoLiberacao = totalAguardandoLiberacao;
        this.totalEmFracionamento = totalEmFracionamento;
    }

    private mounted() {
        this.atualizarStatusComponent = this.$refs.atualizarStatusComponent as AtualizarStatusEditComponent;

        Promise.all([this.loadProdutoLote()]).then(() => {
            this.SET_LASTCOUNTPACK({ fracionamento: Number(this.fastActionPack.countPack.fracionamento) });
        });
    }
}
