import Vue from "vue";
import { mapGetters, mapState } from "vuex";

import buttonScComponent from "@/components/child/form/buttonSc.vue";
import checkboxComponent from "@/components/child/form/checkbox.vue";
import comboComponent from "@/components/child/form/combo.vue";
import fieldsetComponent from "@/components/child/form/fieldset.vue";
import { ModalButtonAction } from "@/components/child/modal/modalButtonAction";
import { Component, Prop, Watch } from "@/decorators";
import PreLoadPackModel from "@/models/auxiliar/preLoadPackModel";
import OrigemProdutoLote from "@/models/enum/origemProdutoLote";
import SituacoesVenda from "@/models/enum/situacoesVenda";
import TipoNota from "@/models/enum/tipoNotaFiscal";
import ManipulacaoOrdemModel from "@/models/manipulacaoOrdemModel";
import VendaItemModel from "@/models/vendaItemModel";
import { AppState, Getters } from "@/store/store";
import Delay from "@/utils/common/delay";

import ManipulacaoEditComponent from "../manipulacao/edit";
import manipulacaoEditComponent from "../manipulacao/edit.vue";
import ShortcutComponent from "../shortcut/shortcut";
import shortcutComponent from "../shortcut/shortcut.vue";

import VendaItemComponent from "./item";
import ItemComponent from "./item";
import itemComponent from "./item.vue";
import VendaEstoqueComponent from "./vendaEstoque";
import vendaEstoqueComponent from "./vendaEstoque.vue";

import "./itemWrapper.scss";

@Component({
    components: {
        manipulacaoEditComponent,
        itemComponent,
        buttonScComponent,
        comboComponent,
        checkboxComponent,
        fieldsetComponent,
        shortcutComponent,
        vendaEstoqueComponent,
    },
    computed: {
        ...mapState<AppState>({
            preLoadPack: state => state.preLoad.preLoadList,
            loadedList: state => state.preLoad.loadedList,
        }),
        ...mapGetters([
            "HAS_PERMISSAO_PERFIL",
            "GET_IS_FRACIONAMENTO",
            "VALIDAR_PERMISSAO_SOMENTE_CONSULTA",
        ] as Getters),
    },
})
export default class ItemWrapperComponent extends Vue {
    // State computed props
    preLoadPack: PreLoadPackModel;
    loadedList: boolean;
    GET_IS_FRACIONAMENTO: () => Promise<boolean>;
    HAS_PERMISSAO_PERFIL: (isRede: boolean, isFranqueador: boolean, isSuporte: boolean) => Promise<boolean>;
    VALIDAR_PERMISSAO_SOMENTE_CONSULTA: (telaDescricao: string) => boolean;

    private shortcutComponent: ShortcutComponent = null;
    public manipulacaoEditComponent: ManipulacaoEditComponent = null;
    private vendaItemComponent: ItemComponent = null;
    private vendaEstoqueComponent: VendaEstoqueComponent = null;

    somenteConsultaVenda = false;
    isManipulacao = false;
    isVendaItem = false;
    isVendaEstoque = false;

    isFracionamento = false;
    isAdmin = false;
    isAdminRede = false;
    desativaCampos = false;
    classSC = "manipulacaoShortCut-modal";

    @Prop({ type: Array, default: () => [] }) itensVenda;
    @Prop({ type: Number, default: 0 }) situacaoVenda: number;
    @Prop({ type: Number, default: 0 }) tipoVenda: number;
    @Prop({ type: Number, default: 0 }) indexVenda: number;
    @Prop({ type: Boolean, default: false }) isActiveTab: boolean;
    @Prop({ type: Boolean, default: false }) vendaFaturada: boolean;
    @Prop({ type: Boolean, default: false }) vendaReplicada: boolean;
    @Prop(Number) pacienteId: number;
    @Prop(Number) clienteId: number;
    @Prop(Number) estoqueId: number;
    @Prop(String) nomeVendedor: string;

    @Prop(Function) tipoNFOptionsCallback;

    private tipoNFOptions: Array<object> = [];

    total = 0;

    private extraButtonAction: Array<ModalButtonAction> = [];

    model: VendaItemModel = new VendaItemModel();
    private editingModel = false;
    private uplodingModel = false;

    private emitirEventoAdicionar() {
        if (this.editingModel) {
            this.$emit("edicao-item", this.getModel());
        } else {
            this.$emit("inclusao-item", this.getModel(), this.indexVenda);
        }
    }

    private onManipulacaoInsert(model: ManipulacaoOrdemModel, continuo = false, dataVencimentoContinuo = null) {
        this.model.manipulacaoOrdem = model;
        this.model.continuo = continuo;
        this.model.dataVencimentoContinuo = dataVencimentoContinuo;
        this.model.produtoDescricao =
            model.formulaPadraoId != 0 && model.formulaPadraoId != null
                ? model.formulaPadraoDescricao
                : "Manipulação " +
                  model.formaFarmaceuticaDescricao +
                  " Ativo (" +
                  model.itens[0].produtoDescricao +
                  ")";
        this.model.quantidade = model.numeroFormulas;
        this.model.valorTotal = model.valorTotal;
        this.model.valorUnitario = model.valorTotal;
        this.model.acrescimo = model.acrescimo;
        this.model.desconto = model.desconto;
        this.model.dataValidade = model.dataValidade;

        this.shortcutComponent.hide();
        this.emitirEventoAdicionar();
    }

    private async onVendaItemInsert(model: VendaItemModel) {
        this.shortcutComponent.hide();

        if (this.isVendaEstoque) {
            await this.setupItemFracionamento(model);
        }

        this.model.updateFrom(model);
        this.emitirEventoAdicionar();
    }

    private async onVendaItemEdit(model: VendaItemModel) {
        this.shortcutComponent.hide();

        if (this.isVendaEstoque) {
            await this.setupItemFracionamento(model);
        }

        this.model.updateFrom(model, ["tipoNF"]);
        this.emitirEventoAdicionar();
    }

    private async setupItemFracionamento(model: VendaItemModel) {
        if (model.fracionamento?.itens[0]?.produtoLote?.origemProdutoLote == OrigemProdutoLote.Fracionamento) {
            const produtoLote = model.fracionamento?.itens[0]?.produtoLote;
            const volumeFracionado = produtoLote.volumeFracionado.toFixed(2).replace(".", ",");
            model.produtoDescricao = `${model.produtoDescricao} ${volumeFracionado} ${produtoLote.unidadeMedidaFracionadoSigla}`;
        }

        const itensVenda = [...this.itensVenda] as VendaItemModel[];
        itensVenda.push(model);
        const itensMesmoProduto = itensVenda.filter(
            p =>
                p.fracionamento != null &&
                p.fracionamento.itens[0].produtoId == model.produtoId &&
                p.fracionamento.itens[0].produtoLote.origemProdutoLote == OrigemProdutoLote.Fracionamento,
        );

        if (itensMesmoProduto.length > 1) {
            const quantidadeTotal = itensMesmoProduto.reduce(
                (acc, p) =>
                    acc +
                    Number(p.fracionamento.itens[0].quantidade) * p.fracionamento.itens[0].produtoLote.volumeFracionado,
                0,
            );

            const markup = model.fracionamento.itens[0].getMarkupByQuantidade(quantidadeTotal);
            const itensComMarkupDiferente = itensMesmoProduto.filter(
                p => markup && p.fracionamento.markupItemVenda != markup,
            );

            if (itensComMarkupDiferente.length > 0) {
                await this.$showWarning(
                    "Alteração Markup Dinâmico",
                    `Devido a alteração das quantidades os valores dos itens cujo produto seja ${model.fracionamento.itens[0].produto.descricao} serão recalculados aplicando o markup dinâmico`,
                );
            }

            itensComMarkupDiferente.forEach(p => {
                p.fracionamento.markupItemVenda = markup;
                p.fracionamento.itens[0] = p.fracionamento.calcularValorTotal(p.fracionamento.itens[0]);
                p.valorTotal = p.fracionamento.valorTotal;
            });

            this.$emit("onUpdateItensFracionamento", itensComMarkupDiferente);
        }
    }

    //@ts-ignore
    @Watch("editingModel")
    private onChangeEditingModel() {
        this.loadExtraButtons();
    }

    private onDesativaCampos(campos: boolean) {
        this.desativaCampos = campos;
        this.loadExtraButtons();
    }

    private loadExtraButtons() {
        this.extraButtonAction = [];
        if (!this.desativaCampos) {
            this.extraButtonAction.push(
                new ModalButtonAction(
                    this.editingModel ? "Alterar" : "Adicionar",
                    this.editingModel ? "Alterar" : "Adicionar",
                    true,
                    "a",
                    "btn btn-primary",
                ),
            );
        }
    }

    private onAdicionarManipulacao(model: ManipulacaoOrdemModel) {
        this.model.manipulacaoOrdem = model;

        this.model.quantidade = model.numeroFormulas;
        this.model.valorTotal = model.valorTotal;
        this.model.valorUnitario = model.valorTotal;

        this.emitirEventoAdicionar();
    }

    private updateNFOptions() {
        this.tipoNFOptions = this.tipoNFOptionsCallback(this.model);
    }

    public clear() {
        this.model = new VendaItemModel();
    }

    public getModel() {
        const model = new VendaItemModel();
        model.updateFrom(this.model);
        return model;
    }

    private onAtualizaTotalItemManipulacao(total: number, atualizar = false) {
        this.total = total;
        if (atualizar && total > 0) {
            this.manipulacaoEditComponent.loadManipulacaoContext();
            this.model.manipulacaoOrdem.updateFrom(this.manipulacaoEditComponent.manipulacao);
            this.model.valorTotal = +this.model.manipulacaoOrdem.valorTotal.toFixed(2);
            this.model.valorUnitario = +this.model.valorTotal.toFixed(2);
        }
    }
    private onAtualizaTotalItemProduto(total: number) {
        this.total = total;
    }

    @Watch("isManipulacao")
    private onIsManipulacaoChanged(newValue: boolean) {
        if (newValue) {
            this.setIsManipulacao();
            this.classSC = "manipulacaoShortCut-modal";
        }
    }

    @Watch("isVendaItem")
    private onIsVendaItemChanged(newValue) {
        if (newValue) {
            this.model.manipulacaoOrdem = null;
            this.setIsVendaItem();
            this.classSC = "produtoShortCut-modal";
        }
    }

    private async onLoadPreVenda(model: ManipulacaoOrdemModel) {
        this.manipulacaoEditComponent.clear();

        const vendaItem = new VendaItemModel();
        vendaItem.manipulacaoOrdem = model;

        await this.load(vendaItem, true);

        await this.manipulacaoEditComponent.consolidar().withLoading();
    }

    private validarPermissaoBotaoVenda(botao: string): boolean {
        if (this.somenteConsultaVenda) {
            return false;
        }

        const situacaoIncorreta =
            this.situacaoVenda > SituacoesVenda.Faturado && this.situacaoVenda != SituacoesVenda.Fracionando;

        if (botao === "manipulacao" || botao === "homeopatia") {
            return (!situacaoIncorreta && !this.isFracionamento) || this.isAdmin || this.isAdminRede;
        } else if (botao === "acabado") {
            return !situacaoIncorreta || this.isAdmin || this.isAdminRede;
        } else if (botao === "fracionamento") {
            return !situacaoIncorreta && this.isFracionamento;
        }
    }

    @Watch("isVendaEstoque")
    private onIsVendaItemEstoqueChanged(newValue) {
        if (newValue) {
            this.model.manipulacaoOrdem = null;
            this.setIsVendaEstoque();
            this.classSC = "fracionamentoShortCut-modal";
        }
    }

    public async load(item: VendaItemModel, isPreVenda = false) {
        this.total = 0;
        this.uplodingModel = true;

        if (item.manipulacaoOrdem == null) {
            if (item.vendaEstoque) {
                this.setIsVendaEstoque();
                await this.loadVendaEstoqueComponent().withLoading();

                this.vendaEstoqueComponent.clear(false);
                this.model = new VendaItemModel();
                this.model.updateFrom(item);
                this.total = this.model.valorTotal;
                this.editingModel = true;
                this.shortcutComponent.show();
                await this.vendaEstoqueComponent.loadModel(this.model);
            } else {
                this.setIsVendaItem();
                await this.loadVendaItemComponent().withLoading();

                if (!this.vendaItemComponent.hasPreLoadPack()) {
                    this.vendaItemComponent.setPreLoadPack(this.preLoadPack);
                }
                this.vendaItemComponent.clear();
                this.model.updateFrom(item);
                this.total = this.model.valorTotal;
                this.editingModel = true;
                this.shortcutComponent.show();
                this.vendaItemComponent.loadModel(this.model);
            }
        } else {
            this.setIsManipulacao();
            await this.loadManipulacaoEditComponent();

            this.manipulacaoEditComponent.clear();
            this.model.manipulacaoOrdem = new ManipulacaoOrdemModel();
            this.model.updateFrom(item, ["manipulacaoOrdem"]);
            this.model.manipulacaoOrdem.updateFrom(item.manipulacaoOrdem);
            this.total = this.model.manipulacaoOrdem.valorTotal;
            ///volta do item venda para manipulacoa, para manter iguais
            this.model.manipulacaoOrdem.acrescimo = this.model.acrescimo;
            this.model.manipulacaoOrdem.desconto = this.model.desconto;

            await this.manipulacaoEditComponent
                .loadModel(
                    this.model.manipulacaoOrdem,
                    this.model.dataVencimentoContinuo,
                    this.model.continuo,
                    isPreVenda,
                )
                .withLoading();
            this.uplodingModel = false;
            this.editingModel = !isPreVenda;

            this.shortcutComponent.show();
        }

        this.updateNFOptions();
    }

    private onCancelar() {
        if (this.isManipulacao) {
            this.manipulacaoEditComponent.clear();
            this.manipulacaoEditComponent.unsubscribeAll();
        }
    }

    private async onManipulacao(isHomeopatia = false) {
        if (!this.estoqueId) {
            return await this.$showWarning(this.$t("__.ts.atencao"), this.$t("__.ts.estoqueNaoInformado"));
        }

        this.setIsManipulacao();
        await this.loadManipulacaoEditComponent();

        this.total = 0;
        this.shortcutComponent.title = isHomeopatia
            ? (this.$t("__.Components.parent.venda.itemwrapper_vue_html.homeopatia") as string)
            : (this.$t("__.Components.parent.venda.itemwrapper_vue_html.manipulacao") as string);
        this.editingModel = false;
        this.model = new VendaItemModel();
        this.model.tipoNF = TipoNota.Servico;
        this.model.manipulacaoOrdem = new ManipulacaoOrdemModel();
        this.updateNFOptions();
        this.manipulacaoEditComponent.isHomeopatia = isHomeopatia;
        //quando for pelo botao, limpar sempre
        this.manipulacaoEditComponent.clear(true);
        this.manipulacaoEditComponent.setPacientePrescritor();
        this.shortcutComponent.show();
    }

    private async onProduto() {
        this.setIsVendaItem();
        await this.loadVendaItemComponent();

        if (!this.vendaItemComponent.hasPreLoadPack()) {
            this.vendaItemComponent.setPreLoadPack(this.preLoadPack);
        }
        this.total = 0;
        this.shortcutComponent.title = "Produto";
        this.editingModel = false;
        this.model = null;
        this.model = new VendaItemModel();
        this.model.tipoNF = TipoNota.CupomFiscal;
        this.model.manipulacaoOrdem = null;
        this.model.manipulacaoOrdemId = null;
        this.updateNFOptions();
        this.vendaItemComponent.clear();
        this.shortcutComponent.show();
    }

    private async onFracionar() {
        this.setIsVendaEstoque();
        await this.loadVendaEstoqueComponent();

        this.total = 0;
        this.shortcutComponent.title = "Fracionamento de Estoque";
        this.editingModel = false;
        this.model = null;
        this.model = new VendaItemModel();
        this.model.tipoNF = TipoNota.DANFe;
        this.model.vendaEstoque = true;
        this.model.manipulacaoOrdem = null;
        this.model.manipulacaoOrdemId = null;
        this.updateNFOptions();
        this.vendaEstoqueComponent.clear();
        this.shortcutComponent.show();
    }

    private async onConfirm() {
        if (this.isManipulacao) {
            if (await this.manipulacaoEditComponent.onAdicionarManipulacao()) {
                this.shortcutComponent.hide();
            }
        } else if (this.isVendaItem) {
            if (!this.model.id) {
                this.vendaItemComponent.model.tipoNF = this.model.tipoNF;
                this.vendaItemComponent.model.movimentaEstoque = this.model.movimentaEstoque;
            }
            if (await this.vendaItemComponent.onAdicionarItem()) {
                this.shortcutComponent.hide();
            }
        } else if (this.isVendaEstoque) {
            if (!this.model.id) {
                this.vendaEstoqueComponent.modelFracionamento.tipoNF = this.model.tipoNF;
                this.vendaEstoqueComponent.modelFracionamento.movimentaEstoque = this.model.movimentaEstoque;
            }
            if (await this.vendaEstoqueComponent.onAdicionarItemEstoque()) {
                this.shortcutComponent.hide();
            }
        }
    }

    // botão para limpar os campos
    private async onExtraButtonActionClick(name: string) {
        if (name == "Adicionar" || name == "Alterar") {
            this.onConfirm();
        }
    }

    public inFocus() {
        return !this.shortcutComponent.isShowing();
    }

    private async onManipulacaoMounted() {
        await this.loadManipulacaoEditComponent();
    }

    private onChangeMovimentaEstoque(movimentaEstoque: boolean) {
        this.model.movimentaEstoque = movimentaEstoque;
    }

    private setIsManipulacao() {
        this.isManipulacao = true;
        this.isVendaItem = false;
        this.isVendaEstoque = false;
    }

    private setIsVendaItem() {
        this.isManipulacao = false;
        this.isVendaItem = true;
        this.isVendaEstoque = false;
    }

    private setIsVendaEstoque() {
        this.isManipulacao = false;
        this.isVendaItem = false;
        this.isVendaEstoque = true;
    }

    private async loadManipulacaoEditComponent() {
        while (!this.$refs.manipulacaoEditComponent) await Delay(5);
        this.manipulacaoEditComponent = this.$refs.manipulacaoEditComponent as ManipulacaoEditComponent;
    }

    private async loadVendaItemComponent() {
        while (!this.$refs.vendaItemComponent) await Delay(5);
        this.vendaItemComponent = this.$refs.vendaItemComponent as VendaItemComponent;
    }

    private async loadVendaEstoqueComponent() {
        while (!this.$refs.vendaEstoqueComponent) await Delay(5);
        this.vendaEstoqueComponent = this.$refs.vendaEstoqueComponent as VendaEstoqueComponent;
    }

    private async getPreLoadPack() {
        while (!this.loadedList) await Delay(100);
    }

    private async mounted() {
        this.shortcutComponent = this.$refs.shortcutComponent as ShortcutComponent;
        this.manipulacaoEditComponent = this.$refs.manipulacaoEditComponent as ManipulacaoEditComponent;
        this.vendaItemComponent = this.$refs.vendaItemComponent as ItemComponent;
        this.vendaEstoqueComponent = this.$refs.vendaEstoqueComponent as VendaEstoqueComponent;

        await this.getPreLoadPack();

        this.isFracionamento = await this.GET_IS_FRACIONAMENTO();
        this.isAdminRede = await this.HAS_PERMISSAO_PERFIL(true, false, false);
        this.isAdmin = await this.HAS_PERMISSAO_PERFIL(false, false, false);

        this.loadExtraButtons();

        this.somenteConsultaVenda = this.VALIDAR_PERMISSAO_SOMENTE_CONSULTA("vendas");
    }
}
