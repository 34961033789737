import Vue from "vue";

import actionBarComponent from "@/components/child/actionBar/actionBar.vue";
import buttonIncluirComponent from "@/components/child/form/button/buttonIncluir.vue";
import comboComponent from "@/components/child/form/combo.vue";
import fieldsetComponent from "@/components/child/form/fieldset.vue";
import moedaComponent from "@/components/child/form/moeda.vue";
import gridComponent from "@/components/child/grid/grid.vue";
import { GridColumn, GridColumnType } from "@/components/child/grid/gridColumn";
import searchComboComponent from "@/components/child/searchCombo/search.vue";
import { Component } from "@/decorators";
import ClienteModel from "@/models/clienteModel";
import DeclaracaoConteudoItensModel from "@/models/declaracaoConteudoItensModel";
import PDFService from "@/services/pdf/PDFService";
import "../crud.scss";

@Component({
    components: {
        fieldsetComponent,
        actionBarComponent,
        searchComboComponent,
        moedaComponent,
        buttonIncluirComponent,
        comboComponent,
        gridComponent,
    },
})
export default class DeclaracaoConteudoComponent extends Vue {
    private pdfService = new PDFService("DeclaracaoConteudoPDF");
    modelItens = new DeclaracaoConteudoItensModel();

    clienteId: number = null;
    valorTotal = 0;
    gridData: Array<DeclaracaoConteudoItensModel> = [];

    private get gridColumns(): Array<GridColumn> {
        return [
            new GridColumn("descricao", this.$t("__.ts.descricao"), GridColumnType.String),
            new GridColumn("quantidade", "Quantidade", GridColumnType.String),
            new GridColumn("peso", "Peso", GridColumnType.String),
        ];
    }

    private customComboTextCliente = (p: ClienteModel) => ({
        value: p.id,
        text: `${p.id} - ` + (p.tipoPessoa != 1 ? p.nome : p.razaoSocial) + (p.ativo ? "" : " - [INATIVO]"),
    });

    private onInclusaoItem() {
        const declaracao = new DeclaracaoConteudoItensModel();
        declaracao.descricao = this.modelItens.descricao;
        declaracao.quantidade = this.modelItens.quantidade;
        declaracao.peso = this.modelItens.peso;

        this.gridData.push(declaracao);
        this.clear();
    }

    private clear(limparCliente = false) {
        this.modelItens = new DeclaracaoConteudoItensModel();
        if (limparCliente) {
            this.gridData = [];
            this.clienteId = 0;
            this.valorTotal = 0;
        }
    }

    private onRemove(item: DeclaracaoConteudoItensModel) {
        this.gridData = this.gridData.filter(i => i !== item);
    }

    private async save() {
        try {
            await this.pdfService.geraPDFWithFilters({
                itens: this.gridData,
                clienteId: this.clienteId,
                valorTotal: this.valorTotal,
            });

            await this.$showSuccess("Sucesso", "Arquivo foi gerado corretamente.");
            this.clear(true);
        } catch (e) {
            this.$showError("Erro", `Erro ao gerar o arquivo.\n Erro: ${e.message}`);
        }
    }

    private cancel() {
        this.$router.back();
    }
}
