var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", {
    ref: "chart",
    staticStyle: {
      position: "relative",
      left: "-10px",
      right: "-10px",
      "min-width": "600px",
      "min-height": "400px",
    },
    attrs: { id: "chartGraficoFluxo" },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }