var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "atendimento-list" },
    [
      _c("div", { staticClass: "atendimento-header" }, [
        _c("section", [
          _c("img", {
            attrs: { src: require("../../../../image/logo-login.svg") },
          }),
          _vm._v(" "),
          _c(
            "button",
            {
              attrs: {
                type: "button",
                title: _vm.$t("__.Components.child.grid.grid_vue_html.addNovo"),
              },
              on: { click: _vm.onCreate },
            },
            [
              _c("i", {
                staticClass: "fa fa-right fa-plus-circle",
                attrs: { "aria-hidden": "true" },
              }),
            ]
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "atendimento-header-filter" }, [
          _c(
            "button",
            {
              class: [{ active: _vm.category === 1 }],
              attrs: {
                type: "button",
                title: _vm.$t(
                  "__.Components.parent.atendimento.view_vue_html.meusTitle"
                ),
              },
              on: {
                click: function ($event) {
                  _vm.selectCategory(1)
                },
              },
            },
            [
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.$t(
                      "__.Components.parent.atendimento.view_vue_html.meus"
                    )
                  )
                ),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              class: [{ active: _vm.category === 2 }],
              attrs: {
                type: "button",
                title: _vm.$t(
                  "__.Components.parent.atendimento.view_vue_html.emAbertoTitle"
                ),
              },
              on: {
                click: function ($event) {
                  _vm.selectCategory(2)
                },
              },
            },
            [
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.$t(
                      "__.Components.parent.atendimento.view_vue_html.emAberto"
                    )
                  )
                ),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              class: [{ active: _vm.category === 3 }],
              attrs: {
                type: "button",
                title: _vm.$t(
                  "__.Components.parent.atendimento.view_vue_html.emAtendimentoTitle"
                ),
              },
              on: {
                click: function ($event) {
                  _vm.selectCategory(3)
                },
              },
            },
            [
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.$t(
                      "__.Components.parent.atendimento.view_vue_html.emAtendimento"
                    )
                  )
                ),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              class: [{ active: _vm.category === 4 }],
              attrs: {
                type: "button",
                title: _vm.$t(
                  "__.Components.parent.atendimento.view_vue_html.encerradosTitle"
                ),
              },
              on: {
                click: function ($event) {
                  _vm.selectCategory(4)
                },
              },
            },
            [
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.$t(
                      "__.Components.parent.atendimento.view_vue_html.encerrados"
                    )
                  )
                ),
              ]),
            ]
          ),
        ]),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.filterKey,
              expression: "filterKey",
            },
          ],
          attrs: {
            type: "text",
            placeholder: "Pesquise pelo nome...",
            maxlength: "20",
          },
          domProps: { value: _vm.filterKey },
          on: {
            input: function ($event) {
              if ($event.target.composing) {
                return
              }
              _vm.filterKey = $event.target.value
            },
          },
        }),
      ]),
      _vm._v(" "),
      _vm.list.length == 0
        ? _c("div", { staticClass: "atendimento-list-vazio" }, [
            _c("p", [
              _vm._v(
                _vm._s(
                  _vm.$t(
                    "__.Components.parent.atendimento.view_vue_html.nenhumAtendEncontrado"
                  )
                )
              ),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "infinite-scroll",
              rawName: "v-infinite-scroll",
              value: _vm.onLoadMore,
              expression: "onLoadMore",
            },
          ],
          staticClass: "atendimento-list-wrapper",
          attrs: {
            "infinite-scroll-disabled": "busy",
            "infinite-scroll-distance": "10",
          },
        },
        _vm._l(_vm.list, function (item) {
          return _c(
            "div",
            {
              key: item.id,
              class: [
                "contact-item",
                {
                  selected:
                    _vm.currentAtendimento !== null &&
                    item.id === _vm.currentAtendimento.id,
                },
              ],
              on: {
                click: function ($event) {
                  _vm.selectContact(item).withLoading()
                },
              },
            },
            [
              _c("img", {
                staticClass: "avatar",
                attrs: { src: item.fotoContato },
              }),
              _vm._v(" "),
              _c("div", { staticClass: "contact-details" }, [
                _c("strong", [_vm._v(_vm._s(item.nomeContato))]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "contact-last-message",
                    class: {
                      isNegrito:
                        item.unreadCount > 0 &&
                        (_vm.currentAtendimento == null ||
                          item.id !== _vm.currentAtendimento.id),
                    },
                  },
                  [
                    _vm._v(
                      "\n                    " +
                        _vm._s(item.ultimaMensagem) +
                        "\n                "
                    ),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "right-info" }, [
                _c("div", { staticClass: "contact-timestamp" }, [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.formatTimestamp(item.dataUltimaMensagem)) +
                      "\n                "
                  ),
                ]),
                _vm._v(" "),
                item.unreadCount > 0 &&
                (_vm.currentAtendimento == null ||
                  item.id !== _vm.currentAtendimento.id)
                  ? _c("div", { staticClass: "unread-container" }, [
                      _c("span", [
                        _vm._v(
                          "\n                        " +
                            _vm._s(item.unreadCount) +
                            "\n                    "
                        ),
                      ]),
                    ])
                  : _vm._e(),
              ]),
            ]
          )
        })
      ),
      _vm._v(" "),
      _c("adicionarAtendimentoComponent", {
        ref: "adicionarAtendimentoComponent",
        on: { onCreated: _vm.onCreated },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }