// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../image/giff.gif", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".loading-modal-component .modal-body {\n  overflow: hidden !important;\n}\n.loading-modal-component .modal-body h3 {\n  color: #3ba0b1;\n  font-size: 24px;\n  line-height: 50px;\n  margin-top: 0;\n  margin-bottom: 0;\n}\n.loading-modal-component .modal-body img {\n  content: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n}\n.loading-modal-component .modal-body i {\n  float: right;\n}\n\n.loading-modal-component .modal-wrapper {\n  margin-top: 0;\n}\n\n.modal-mask {\n  background-color: rgba(255, 255, 255, 0.53) !important;\n  z-index: 9;\n}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
