import VisitadorModel from "./visitadorModel";

export default class VisitadorPrescritorModel {
    id?: number = null;
    dataCriacao: Date = null;
    dataModificacao: Date = null;
    usuarioCriacaoId: number = null;
    usuarioModificacaoId: number = null;
    prescritorId: number = null;
    visitador: VisitadorModel = null;
    visitadorId: number = null;
    nome: string = null;
}
