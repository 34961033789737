export default class CapaSeiModel {
    tipoProcesso: string = null;
    especificacoes: string = null;
    classificacao: string = null;
    interessado: string = null;
    destino: string = null;
    observacoes: string = null;
    endereco: string = null;
    numeroProcessoAnterior: string = null;
    outrasInformacoes: string = null;
}
