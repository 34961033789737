import BaseModel from "../../../base/baseModel";

import CobrancaNfeModel from "./cobranca/cobrancaNfeModel";
import DestinatarioNfeModel from "./destinatario/destinatarioNfeModel";
import DetalheNfeModel from "./detalhe/detalheNfeModel";
import EmitenteNfeModel from "./emitente/emitenteNfeModel";
import IdentificacaoNfeModel from "./identificacao/identificacaoNfeModel";
import InformacoesAdicionaisNfeModel from "./informacoesAdicionais/informacoesAdicionaisNfeModel";
import TotaisNfeModel from "./totais/totaisNfeModel";
import TransporteNfeModel from "./transporte/transporteNfeModel";

export default class InformacoesNfeModel extends BaseModel {
    chaveAcesso: string = null;
    versaoXml: string = null;
    identificacao: IdentificacaoNfeModel = new IdentificacaoNfeModel();
    emitente: EmitenteNfeModel = new EmitenteNfeModel();
    destinatario: DestinatarioNfeModel = new DestinatarioNfeModel();
    transporte: TransporteNfeModel = new TransporteNfeModel();
    detalhes: DetalheNfeModel[] = [];
    totais: TotaisNfeModel = new TotaisNfeModel();
    cobranca: CobrancaNfeModel = new CobrancaNfeModel();

    informacoesAdicionais: InformacoesAdicionaisNfeModel = new InformacoesAdicionaisNfeModel();
}
