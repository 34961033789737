import FornecedorModel from "@/models/fornecedorModel";
import nfetch from "@/utils/events/nfetch";

import { IService } from "./base/iService";

export default class FornecedorService extends IService<FornecedorModel> {
    constructor() {
        super("Fornecedor");
    }

    public getByDocumento(documento: string): Promise<Response> {
        return nfetch(`/${this.controllerName}/GetByDocumento?documento=${documento}`, {
            credentials: "same-origin",
        });
    }

    public validarValidadeLicensaSanitaria(fornecedorId: number): Promise<Response> {
        return nfetch(`/${this.controllerName}/ValidarValidadeLicencaSanitaria?fornecedorId=${fornecedorId}`, {
            credentials: "same-origin",
        });
    }
}
