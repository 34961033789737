import Vue from "vue";
import { mapState } from "vuex";

import actionBarComponent from "@/components/child/actionBar/actionBar.vue";
import fieldsetComponent from "@/components/child/form/fieldset.vue";
import { Component } from "@/decorators";
import ConfiguracaoWhatsAppSituacao from "@/models/enum/configuracaoWhatsAppSituacao";
import UsuarioModel from "@/models/usuarioModel";
import ConfiguracaoWhatsAppModel from "@/models/whatsApp/configuracaoWhatsAppModel";
import { IniciarConfiguracaoWhatsAppResponse } from "@/models/whatsApp/iniciarConfiguracaoWhatsAppResponse";
import ConfiguracaoWhatsAppService from "@/services/atendimentos/configuracaoWhatsAppService";
import { AppState, SessionActions } from "@/store/store";

import "../crud.scss";

@Component({
    components: {
        fieldsetComponent,
        actionBarComponent,
    },
    computed: {
        ...mapState({
            usuarioLogado: (state: AppState) => state.session.usuarioLogado,
        }),
    },
})
export default class ConfiguracaoWhatsAppEditComponent extends Vue {
    // State computed props
    usuarioLogado: UsuarioModel;

    qrCode: string;
    configuracaoWhatsAppId: number;
    model: ConfiguracaoWhatsAppModel;
    exibirBotaoIniciarConfiguracao = false;
    exibirQrCode = false;
    exibirMensagemSucesso = false;

    private async iniciarConfiguracao() {
        try {
            const data = await new ConfiguracaoWhatsAppService()
                .iniciarConfiguracao()
                .withLoading()
                .resolveWithJSON<IniciarConfiguracaoWhatsAppResponse>();

            this.qrCode = data.qrCode;

            this.atualizarExibicaoBotao();
        } catch {}
    }

    private async atualizarStatus() {
        try {
            const data = await new ConfiguracaoWhatsAppService()
                .atualizarStatusIntegracao()
                .withLoading()
                .resolveWithJSON<ConfiguracaoWhatsAppModel>();

            this.model = data;

            this.atualizarExibicaoBotao();
        } catch {}
    }

    private async desconectarIntegracao() {
        const response = await this.$showAlert({
            title: this.$t("__.ts.desconectarIntegracao") as string,
            text: this.$t("__.ts.confirmarDesconectarIntegracao") as string,
            icon: "question",
            showCancelButton: true,
            confirmButtonText: this.$t("__.ts.sim") as string,
            cancelButtonText: this.$t("__.ts.nao") as string,
        });

        if (response.isConfirmed) {
            try {
                const response = await new ConfiguracaoWhatsAppService()
                    .desconectarIntegracao()
                    .withLoading()
                    .resolveWithResponse(false, false);

                if (!response.ok) {
                    await this.$showError(this.$t("__.ts.erro"), "Ocorreu um erro ao desconectar a integração");
                }

                this.atualizarStatus();
            } catch {}
        }
    }

    private async definirWebhook() {
        try {
            const response = await new ConfiguracaoWhatsAppService()
                .definirWebhook()
                .withLoading()
                .resolveWithResponse(false, false);

            if (!response.ok) {
                await this.$showError(this.$t("__.ts.erro"), "Ocorreu um erro ao configurar webhook");
            } else {
                await this.$showSuccess(this.$t("__.ts.sucesso"), "Webhook configurado com sucesso!");
            }
        } catch {}
    }

    private async getConfiguracao() {
        const data = await new ConfiguracaoWhatsAppService()
            .getByFranquia()
            .withLoading()
            .resolveWithJSON<ConfiguracaoWhatsAppModel>(false, false);

        this.model = data;

        this.atualizarExibicaoBotao();
    }

    private atualizarExibicaoBotao() {
        if (this.model != null && this.model.situacao == ConfiguracaoWhatsAppSituacao.ProntoParaUso) {
            this.exibirBotaoIniciarConfiguracao = false;
            this.exibirQrCode = false;
            this.exibirMensagemSucesso = true;
            return;
        }

        if (this.qrCode != null) {
            this.exibirBotaoIniciarConfiguracao = false;
            this.exibirMensagemSucesso = false;
            this.exibirQrCode = true;
            return;
        }

        this.exibirBotaoIniciarConfiguracao = true;
        this.exibirQrCode = false;
        this.exibirMensagemSucesso = false;
        return;
    }

    private async mounted() {
        await this.$store.dispatch(SessionActions.LOAD_USUARIO_LOGADO);

        if (!this.usuarioLogado.franquia.moduloWhatsApp) {
            this.$showWarning(this.$t("__.ts.atencao"), this.$t("__.ts.franquiaNaoModuloContratado"));

            return this.$router.back();
        }

        await this.atualizarStatus();
        await this.getConfiguracao();
    }
}
