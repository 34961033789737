var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("notifications", {
    staticClass: "notification-container",
    attrs: {
      duration: 450000,
      width: 420,
      "animation-name": "v-fade-right",
      position: "top right",
    },
    scopedSlots: _vm._u([
      {
        key: "body",
        fn: function (props) {
          return [
            _c("div", { class: "notification-item " + props.item.type }, [
              _c("article", [
                _c("div", { staticClass: "notification-icon" }, [
                  props.item.type == "success"
                    ? _c("i", { staticClass: "fa fa-check-circle color-green" })
                    : _vm._e(),
                  _vm._v(" "),
                  props.item.type == "warn"
                    ? _c("i", {
                        staticClass: "fa fa-exclamation-circle color-orange",
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  props.item.type == "error"
                    ? _c("i", { staticClass: "fa fa-times-circle color-red" })
                    : _vm._e(),
                  _vm._v(" "),
                  props.item.type == "info"
                    ? _c("i", {
                        staticClass: "fa fa-exclamation-circle color-blue",
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  props.item.type == "question"
                    ? _c("i", {
                        staticClass: "fa fa-question-circle",
                        staticStyle: { color: "cornflowerblue" },
                      })
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "notification-content" }, [
                  props.item.title
                    ? _c("strong", [
                        _vm._v(
                          "\n                        " +
                            _vm._s(props.item.title) +
                            "\n                    "
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", {
                    domProps: { innerHTML: _vm._s(props.item.text) },
                  }),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "notification-close",
                    on: { click: props.close },
                  },
                  [_c("i", { staticClass: "fa fa-times color-black" })]
                ),
                _vm._v(" "),
                _c("div", {
                  staticClass: "notification-time",
                  style:
                    "animation: growth " +
                    props.item.length +
                    "ms ease-in-out reverse",
                }),
              ]),
            ]),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }