var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "shortcutComponent",
    {
      ref: "shortcutComponent",
      attrs: { showAddNew: false, showBtnOk: false },
    },
    [
      _c(
        "div",
        { attrs: { slot: "component" }, slot: "component" },
        [
          _c("div", { staticClass: "totais" }, [
            _vm._v("\n            Total de vendas:\n            "),
            _c("span", [_vm._v(_vm._s(_vm.vendas.length))]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "totais" }, [
            _vm._v("\n            Valor Total:\n            "),
            _c("span", [
              _vm._v(
                _vm._s(
                  _vm._f("filterMoeda")(
                    _vm.vendas.reduce(function (acc, p) {
                      return acc + p.valor - p.desconto
                    }, 0)
                  )
                )
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("grid-component", {
            attrs: {
              columns: _vm.gridColumnsVendas,
              data: _vm.vendas,
              extraActions: _vm.extraActionsVendas,
              showEditItem: false,
              showRemoveItem: false,
              showTableFoot: false,
              showTableHead: false,
            },
            on: { "extra-action": _vm.onExtraActionVendas },
          }),
          _vm._v(" "),
          _c("grid-component", {
            attrs: {
              columns: _vm.gridColumnsPontuacaoPorFranquias,
              data: _vm.pontuacaoPorFranquia,
              showEditItem: false,
              showRemoveItem: false,
              showTableFoot: false,
              showTableHead: false,
              showActionColumn: false,
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }