import { Module } from "vuex";

import PaginationPackModel from "@/models/auxiliar/paginationPackModel";

import { AppState } from "./store";

export type PaginationStatusState = {
    loading: boolean;
    paginationParams: PaginationPackModel;
    paginationPack: PaginationPackModel[];
};

export type PaginationStatusMutations = "SET_PARAMS" | "GET_PARAMS" | "CLEAR_LISTSPACK";

const paginationStatus: Module<PaginationStatusState, AppState> = {
    state: {
        loading: false,
        paginationParams: new PaginationPackModel(),
        paginationPack: [],
    },
    mutations: {
        //seta a pocisao menu
        SET_PARAMS(store, obj: PaginationPackModel) {
            //quando necessario alterar sem consultar banco
            const { routePath, filterKey, sortKey, sortOrder, pageIndex, pageSize, filtros } = obj;

            if (routePath != null && routePath != "") {
                const pagination = new PaginationPackModel();
                pagination.routePath = routePath;

                pagination.filterKey = filterKey;
                pagination.sortKey = sortKey;
                pagination.sortOrder = sortOrder;
                pagination.pageIndex = pageIndex;
                pagination.pageSize = pageSize;

                if (filtros != null) {
                    pagination.filtros = filtros;
                }

                const index = store.paginationPack.map(p => p.routePath).indexOf(routePath);

                if (index >= 0) {
                    store.paginationPack[index] = pagination;
                } else {
                    store.paginationPack.push(pagination);
                }
            }
        },
        async GET_PARAMS(store, pathName) {
            store.loading = true;
            store.paginationParams = null;
            if (store.paginationPack["length"] > 0) {
                const index = store.paginationPack.map(p => p.routePath).indexOf(pathName);
                if (index >= 0) {
                    store.paginationParams = new PaginationPackModel();
                    store.paginationParams = store.paginationPack[index];
                }
            }
            store.loading = false;
        },
        async CLEAR_LISTSPACK(store) {
            store.paginationPack = null;
            store.paginationPack = new Array<PaginationPackModel>();
            store.paginationParams = null;
        },
    },
};

export default paginationStatus;
