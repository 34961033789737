import Vue from "vue";

import actionBarComponent from "@/components/child/actionBar/actionBar.vue";
import comboComponent from "@/components/child/form/combo.vue";
import LoadingModalComponent from "@/components/child/loadingmodal/loadingmodal";
import loadingModalComponent from "@/components/child/loadingmodal/loadingmodal.vue";
import { Component } from "@/decorators";
import TiposMotivoMovimentacao from "@/models/enum/tiposMotivoMovimentacao";
import MotivoMovimentacaoEstoqueModel from "@/models/motivoMovimentacaoEstoque";
import ValidationErrorModel from "@/models/validationErrorModel";
import MotivoMovimentacaoEstoqueService from "@/services/motivoMovimentacaoEstoque";
import ValidationErrorWrapper from "@/wrappers/validationErrorWrapper";

import "../crud.scss";

@Component({
    components: {
        loadingModalComponent,
        comboComponent,
        actionBarComponent,
    },
})
export default class MotivoMovimentacaoEstoqueEditComponent extends Vue {
    private service = new MotivoMovimentacaoEstoqueService();
    private validationErrorWrapper = new ValidationErrorWrapper(this.$validator);
    private loadingModalComponent: LoadingModalComponent = null;

    model = new MotivoMovimentacaoEstoqueModel();
    modelId = 0;

    tiposMotivoMovimentacaoOptions: Array<Object> = [
        { text: "Devolução ao fornecedor", value: TiposMotivoMovimentacao.DevolucaoAoFornecedor },
        { text: "Perda por desvio de qualidade", value: TiposMotivoMovimentacao.PerdaPorDesviodeQualidade },
        { text: "Perda no processo", value: TiposMotivoMovimentacao.PerdanoProcesso },
        { text: "Perda por avaria", value: TiposMotivoMovimentacao.PerdaporAvaria },
        { text: "Perda por furto", value: TiposMotivoMovimentacao.PerdaporFurto },
        {
            text: "Perda por uso no controlede de qualidade",
            value: TiposMotivoMovimentacao.PerdaporUsoControledeQualidade,
        },
        { text: "Perda por vencimento", value: TiposMotivoMovimentacao.PerdaporVencimento },
        { text: "Recolhimento do fabricante", value: TiposMotivoMovimentacao.RecolhimentodoFabricante },
        { text: "Recolhimento pela anvisa", value: TiposMotivoMovimentacao.RecolhimentopelaAnvisa },
        { text: "Alteração Interna", value: TiposMotivoMovimentacao.AlteracaoInterna },
        { text: "Uso e consumo durante processo", value: TiposMotivoMovimentacao.UsoConsumoDuranteProcesso },
        { text: "Transferência para filial", value: TiposMotivoMovimentacao.TransferenciaParaFilial },
    ];

    private async load() {
        this.model = new MotivoMovimentacaoEstoqueModel();
        this.validationErrorWrapper.clearErrors();

        if (this.modelId > 0) {
            this.loadingModalComponent.showLoading();

            try {
                const data = await this.service
                    .get(this.modelId)
                    .then(r => r.json() as Promise<MotivoMovimentacaoEstoqueModel>);
                this.model.updateFrom(data);
            } catch {
                this.$router.back();
            }

            this.loadingModalComponent.hide();
        }
    }

    private async save() {
        try {
            const isValid = await this.$validator.validateAll();
            if (isValid) {
                this.loadingModalComponent.showSaving();

                try {
                    const response = await this.service[!this.model.id ? "insert" : "update"](this.model);
                    this.loadingModalComponent.hide();

                    if (response.ok) {
                        await this.$showInclusaoUpdate(this.model.id);
                        this.$router.back();
                    } else {
                        const data = (await response.json()) as ValidationErrorModel[];
                        this.validationErrorWrapper.showErrors(data);
                    }
                } catch {}

                this.loadingModalComponent.hide();
            }
        } catch {}
    }

    private cancel() {
        this.$router.back();
    }

    private mounted() {
        this.loadingModalComponent = this.$refs.loadingModalComponent as LoadingModalComponent;

        if (this.$route.params.id) {
            this.modelId = +this.$route.params.id;
            this.load();
        }
    }
}
