var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "crud crud-edit" },
    [
      !_vm.hideExtras
        ? _c(
            "actionBarComponent",
            {
              attrs: {
                slots: 1,
                title: _vm.$t(
                  "__.Crud.transportadora.edit_vue_html.transportadoraEdit"
                ),
              },
              on: { onSave: _vm.save },
            },
            [
              _c(
                "div",
                {
                  attrs: {
                    slot: "buttonSlot0",
                    title: _vm.$t("__.Crud.imprimir"),
                  },
                  on: { click: _vm.print },
                  slot: "buttonSlot0",
                },
                [
                  _c("i", {
                    staticClass: "fa fa-print",
                    attrs: { "aria-hidden": "true" },
                  }),
                ]
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "form",
        {
          attrs: { novalidate: "novalidate" },
          on: {
            submit: function ($event) {
              $event.preventDefault()
              _vm.save()
            },
          },
        },
        [
          _c(
            "fieldset-component",
            {
              attrs: {
                title: _vm.$t(
                  "__.Crud.transportadora.edit_vue_html.dadosGerais"
                ),
                collapsed: false,
              },
            },
            [
              _c(
                "div",
                { attrs: { slot: "rows", id: "transportadora" }, slot: "rows" },
                [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-sm-4" }, [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: _vm.errors.first("tipoPessoa"),
                              expression: "errors.first('tipoPessoa')",
                            },
                          ],
                          staticClass: "form-group",
                        },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "control-label",
                              attrs: {
                                for: "tipoPessoa",
                                title: _vm.$t("__.obrigatorio"),
                              },
                            },
                            [
                              _c("span", { staticStyle: { color: "red" } }, [
                                _vm._v("*"),
                              ]),
                              _vm._v(
                                "\n                                " +
                                  _vm._s(
                                    _vm.$t(
                                      "__.Crud.transportadora.edit_vue_html.tipo"
                                    )
                                  ) +
                                  "\n                            "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("combo-component", {
                            directives: [
                              { name: "focus", rawName: "v-focus" },
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required",
                                expression: "'required'",
                              },
                            ],
                            attrs: {
                              id: "tipoPessoa",
                              name: "tipoPessoa",
                              data: _vm.tiposPessoaOptions,
                              searchable: false,
                            },
                            model: {
                              value: _vm.model.tipoPessoa,
                              callback: function ($$v) {
                                _vm.$set(_vm.model, "tipoPessoa", $$v)
                              },
                              expression: "model.tipoPessoa",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              _vm.model.tipoPessoa == _vm.tiposPessoa.Fisica,
                            expression:
                              "model.tipoPessoa == tiposPessoa.Fisica",
                          },
                        ],
                        staticClass: "col-sm-4",
                      },
                      [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: _vm.errors.first("cpf"),
                                expression: "errors.first('cpf')",
                              },
                            ],
                            staticClass: "form-group",
                          },
                          [
                            _c(
                              "label",
                              {
                                staticClass: "control-label",
                                attrs: {
                                  for: "cpf",
                                  title: _vm.$t("__.obrigatorio"),
                                },
                              },
                              [
                                _c("span", { staticStyle: { color: "red" } }, [
                                  _vm._v("*"),
                                ]),
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(
                                      _vm.$t(
                                        "__.Crud.transportadora.edit_vue_html.cpf"
                                      )
                                    ) +
                                    "\n                            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("cpf-component", {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: {
                                    rules: {
                                      cpf: true,
                                      required:
                                        _vm.model.tipoPessoa ==
                                        _vm.tiposPessoa.Fisica,
                                    },
                                  },
                                  expression:
                                    "{\n                                    rules: { cpf: true, required: model.tipoPessoa == tiposPessoa.Fisica },\n                                }",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: { id: "cpf", name: "cpf" },
                              model: {
                                value: _vm.model.cpf,
                                callback: function ($$v) {
                                  _vm.$set(_vm.model, "cpf", $$v)
                                },
                                expression: "model.cpf",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              _vm.model.tipoPessoa == _vm.tiposPessoa.Fisica,
                            expression:
                              "model.tipoPessoa == tiposPessoa.Fisica",
                          },
                        ],
                        staticClass: "col-sm-4",
                      },
                      [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: _vm.errors.first("rg"),
                                expression: "errors.first('rg')",
                              },
                            ],
                            staticClass: "form-group",
                          },
                          [
                            _c(
                              "label",
                              {
                                staticClass: "control-label",
                                attrs: { for: "rg" },
                              },
                              [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(
                                      _vm.$t(
                                        "__.Crud.transportadora.edit_vue_html.rg"
                                      )
                                    ) +
                                    "\n                            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.model.rg,
                                  expression: "model.rg",
                                },
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "alpha_num",
                                  expression: "'alpha_num'",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: {
                                autocomplete: "off",
                                type: "text",
                                id: "rg",
                                name: "rg",
                              },
                              domProps: { value: _vm.model.rg },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(_vm.model, "rg", $event.target.value)
                                },
                              },
                            }),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              _vm.model.tipoPessoa == _vm.tiposPessoa.Juridica,
                            expression:
                              "model.tipoPessoa == tiposPessoa.Juridica",
                          },
                        ],
                        staticClass: "col-sm-4",
                      },
                      [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: _vm.errors.first("cnpj"),
                                expression: "errors.first('cnpj')",
                              },
                            ],
                            staticClass: "form-group",
                          },
                          [
                            _c(
                              "label",
                              {
                                staticClass: "control-label",
                                attrs: {
                                  for: "cnpj",
                                  title: _vm.$t("__.obrigatorio"),
                                },
                              },
                              [
                                _c("span", { staticStyle: { color: "red" } }, [
                                  _vm._v("*"),
                                ]),
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(
                                      _vm.$t(
                                        "__.Crud.transportadora.edit_vue_html.cnpj"
                                      )
                                    ) +
                                    "\n                            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("cnpj-component", {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: {
                                    rules: {
                                      cnpj: true,
                                      required:
                                        _vm.model.tipoPessoa ==
                                        _vm.tiposPessoa.Juridica,
                                    },
                                  },
                                  expression:
                                    "{\n                                    rules: { cnpj: true, required: model.tipoPessoa == tiposPessoa.Juridica },\n                                }",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: { id: "cnpj", name: "cnpj" },
                              model: {
                                value: _vm.model.cnpj,
                                callback: function ($$v) {
                                  _vm.$set(_vm.model, "cnpj", $$v)
                                },
                                expression: "model.cnpj",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              _vm.model.tipoPessoa == _vm.tiposPessoa.Juridica,
                            expression:
                              "model.tipoPessoa == tiposPessoa.Juridica",
                          },
                        ],
                        staticClass: "col-sm-4",
                      },
                      [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: _vm.errors.first("inscricaoEstadual"),
                                expression: "errors.first('inscricaoEstadual')",
                              },
                            ],
                            staticClass: "form-group",
                          },
                          [
                            _c(
                              "label",
                              {
                                staticClass: "control-label",
                                attrs: { for: "inscricaoEstadual" },
                              },
                              [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(
                                      _vm.$t(
                                        "__.Crud.transportadora.edit_vue_html.ie"
                                      )
                                    ) +
                                    "\n                            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("data-tooltip-component", {
                              attrs: {
                                text: _vm.$t(
                                  "__.Crud.transportadora.edit_vue_html.clienteIsentoEmBranco"
                                ),
                              },
                            }),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.model.inscricaoEstadual,
                                  expression: "model.inscricaoEstadual",
                                },
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "inscricaoEstadual|max:20",
                                  expression: "'inscricaoEstadual|max:20'",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: {
                                autocomplete: "off",
                                type: "text",
                                id: "inscricaoEstadual",
                                name: "inscricaoEstadual",
                                placeholder: "___-_______",
                              },
                              domProps: { value: _vm.model.inscricaoEstadual },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.model,
                                    "inscricaoEstadual",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              _vm.model.tipoPessoa == _vm.tiposPessoa.Fisica,
                            expression:
                              "model.tipoPessoa == tiposPessoa.Fisica",
                          },
                        ],
                        staticClass: "col-sm-12",
                      },
                      [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: _vm.errors.first("nome"),
                                expression: "errors.first('nome')",
                              },
                            ],
                            staticClass: "form-group",
                          },
                          [
                            _c(
                              "label",
                              {
                                staticClass: "control-label",
                                attrs: {
                                  for: "nome",
                                  title: _vm.$t("__.obrigatorio"),
                                },
                              },
                              [
                                _c("span", { staticStyle: { color: "red" } }, [
                                  _vm._v("*"),
                                ]),
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(
                                      _vm.$t(
                                        "__.Crud.transportadora.edit_vue_html.nome"
                                      )
                                    ) +
                                    "\n                            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.model.nome,
                                  expression: "model.nome",
                                },
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: {
                                    rules: {
                                      alpha_spaces: true,
                                      max: 256,
                                      required:
                                        _vm.model.tipoPessoa ==
                                        _vm.tiposPessoa.Fisica,
                                    },
                                  },
                                  expression:
                                    "{\n                                    rules: {\n                                        alpha_spaces: true,\n                                        max: 256,\n                                        required: model.tipoPessoa == tiposPessoa.Fisica,\n                                    },\n                                }",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: {
                                autocomplete: "off",
                                type: "text",
                                id: "nome",
                                name: "nome",
                              },
                              domProps: { value: _vm.model.nome },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.model,
                                    "nome",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              _vm.model.tipoPessoa == _vm.tiposPessoa.Juridica,
                            expression:
                              "model.tipoPessoa == tiposPessoa.Juridica",
                          },
                        ],
                        staticClass: "col-sm-12",
                      },
                      [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: _vm.errors.first("nomeFantasia"),
                                expression: "errors.first('nomeFantasia')",
                              },
                            ],
                            staticClass: "form-group",
                          },
                          [
                            _c(
                              "label",
                              {
                                staticClass: "control-label",
                                attrs: {
                                  for: "nomeFantasia",
                                  title: _vm.$t("__.obrigatorio"),
                                },
                              },
                              [
                                _c("span", { staticStyle: { color: "red" } }, [
                                  _vm._v("*"),
                                ]),
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(
                                      _vm.$t(
                                        "__.Crud.transportadora.edit_vue_html.nomeFantasia"
                                      )
                                    ) +
                                    "\n                            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.model.nomeFantasia,
                                  expression: "model.nomeFantasia",
                                },
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: {
                                    rules: {
                                      max: 256,
                                      required:
                                        _vm.model.tipoPessoa ==
                                        _vm.tiposPessoa.Juridica,
                                    },
                                  },
                                  expression:
                                    "{\n                                    rules: { max: 256, required: model.tipoPessoa == tiposPessoa.Juridica },\n                                }",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: {
                                autocomplete: "off",
                                type: "text",
                                id: "nomeFantasia",
                                name: "nomeFantasia",
                              },
                              domProps: { value: _vm.model.nomeFantasia },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.model,
                                    "nomeFantasia",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                          ]
                        ),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            _vm.model.tipoPessoa == _vm.tiposPessoa.Juridica,
                          expression:
                            "model.tipoPessoa == tiposPessoa.Juridica",
                        },
                      ],
                      staticClass: "row",
                    },
                    [
                      _c("div", { staticClass: "col-sm-8" }, [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: _vm.errors.first("razaoSocial"),
                                expression: "errors.first('razaoSocial')",
                              },
                            ],
                            staticClass: "form-group",
                          },
                          [
                            _c(
                              "label",
                              {
                                staticClass: "control-label",
                                attrs: {
                                  for: "razaoSocial",
                                  title: _vm.$t("__.obrigatorio"),
                                },
                              },
                              [
                                _c("span", { staticStyle: { color: "red" } }, [
                                  _vm._v("*"),
                                ]),
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(
                                      _vm.$t(
                                        "__.Crud.transportadora.edit_vue_html.razaoSocial"
                                      )
                                    ) +
                                    "\n                            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.model.razaoSocial,
                                  expression: "model.razaoSocial",
                                },
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: {
                                    rules: {
                                      max: 256,
                                      required:
                                        _vm.model.tipoPessoa ==
                                        _vm.tiposPessoa.Juridica,
                                    },
                                  },
                                  expression:
                                    "{\n                                    rules: { max: 256, required: model.tipoPessoa == tiposPessoa.Juridica },\n                                }",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: {
                                autocomplete: "off",
                                type: "text",
                                id: "razaoSocial",
                                name: "razaoSocial",
                              },
                              domProps: { value: _vm.model.razaoSocial },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.model,
                                    "razaoSocial",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-sm-4" }, [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: _vm.errors.first("inscricaoMunicipal"),
                                expression:
                                  "errors.first('inscricaoMunicipal')",
                              },
                            ],
                            staticClass: "form-group",
                          },
                          [
                            _c(
                              "label",
                              {
                                staticClass: "control-label",
                                attrs: { for: "inscricaoMunicipal" },
                              },
                              [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(
                                      _vm.$t(
                                        "__.Crud.transportadora.edit_vue_html.im"
                                      )
                                    ) +
                                    "\n                            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.model.inscricaoMunicipal,
                                  expression: "model.inscricaoMunicipal",
                                },
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "max:20",
                                  expression: "'max:20'",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: {
                                autocomplete: "off",
                                type: "text",
                                id: "inscricaoMunicipal",
                                name: "inscricaoMunicipal",
                                placeholder: "__________",
                              },
                              domProps: { value: _vm.model.inscricaoMunicipal },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.model,
                                    "inscricaoMunicipal",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                          ]
                        ),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.model.tipoPessoa == _vm.tiposPessoa.Fisica,
                          expression: "model.tipoPessoa == tiposPessoa.Fisica",
                        },
                      ],
                      staticClass: "row",
                    },
                    [
                      _c("div", { staticClass: "col-sm-3" }, [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: _vm.errors.first("dataNascimento"),
                                expression: "errors.first('dataNascimento')",
                              },
                            ],
                            staticClass: "form-group",
                          },
                          [
                            _c(
                              "label",
                              {
                                staticClass: "control-label",
                                attrs: { for: "dataNascimento" },
                              },
                              [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(
                                      _vm.$t(
                                        "__.Crud.transportadora.edit_vue_html.dtNasc"
                                      )
                                    ) +
                                    "\n                            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("date-time-picker-component", {
                              attrs: {
                                id: "dataNascimento",
                                name: "dataNascimento",
                              },
                              model: {
                                value: _vm.model.dataNascimento,
                                callback: function ($$v) {
                                  _vm.$set(_vm.model, "dataNascimento", $$v)
                                },
                                expression: "model.dataNascimento",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-sm-2" }, [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: _vm.errors.first("genero"),
                                expression: "errors.first('genero')",
                              },
                            ],
                            staticClass: "form-group",
                          },
                          [
                            _c(
                              "label",
                              {
                                staticClass: "control-label",
                                attrs: { for: "genero" },
                              },
                              [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(
                                      _vm.$t(
                                        "__.Crud.transportadora.edit_vue_html.genero"
                                      )
                                    ) +
                                    "\n                            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("combo-component", {
                              attrs: {
                                id: "genero",
                                name: "genero",
                                data: _vm.generosPessoaOptions,
                                searchable: false,
                              },
                              model: {
                                value: _vm.model.genero,
                                callback: function ($$v) {
                                  _vm.$set(_vm.model, "genero", $$v)
                                },
                                expression: "model.genero",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-sm-3" }, [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: _vm.errors.first("orgaoExpedidor"),
                                expression: "errors.first('orgaoExpedidor')",
                              },
                            ],
                            staticClass: "form-group",
                          },
                          [
                            _c(
                              "label",
                              {
                                staticClass: "control-label",
                                attrs: { for: "orgaoExpedidor" },
                              },
                              [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(
                                      _vm.$t(
                                        "__.Crud.transportadora.edit_vue_html.orgaoExpedidor"
                                      )
                                    ) +
                                    "\n                            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.model.orgaoExpedidor,
                                  expression: "model.orgaoExpedidor",
                                },
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "alpha_spaces|max:256",
                                  expression: "'alpha_spaces|max:256'",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: {
                                autocomplete: "off",
                                type: "text",
                                id: "orgaoExpedidor",
                                name: "orgaoExpedidor",
                              },
                              domProps: { value: _vm.model.orgaoExpedidor },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.model,
                                    "orgaoExpedidor",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-sm-2" }, [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: _vm.errors.first("ufExpedidor"),
                                expression: "errors.first('ufExpedidor')",
                              },
                            ],
                            staticClass: "form-group",
                          },
                          [
                            _c(
                              "label",
                              {
                                staticClass: "control-label",
                                attrs: { for: "ufExpedidor" },
                              },
                              [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(
                                      _vm.$t(
                                        "__.Crud.transportadora.edit_vue_html.ufExpedidor"
                                      )
                                    ) +
                                    "\n                            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("combo-component", {
                              attrs: {
                                id: "ufExpedidor",
                                name: "ufExpedidor",
                                data: _vm.ufOptions,
                                searchable: true,
                              },
                              model: {
                                value: _vm.model.ufExpedidor,
                                callback: function ($$v) {
                                  _vm.$set(_vm.model, "ufExpedidor", $$v)
                                },
                                expression: "model.ufExpedidor",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-sm-12" }, [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: _vm.errors.first("observacao"),
                              expression: "errors.first('observacao')",
                            },
                          ],
                          staticClass: "form-group",
                        },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "control-label",
                              attrs: { for: "observacao" },
                            },
                            [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(
                                    _vm.$t(
                                      "__.Crud.transportadora.edit_vue_html.obs"
                                    )
                                  ) +
                                  "\n                            "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("textarea-component", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "max:256",
                                expression: "'max:256'",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: { id: "observacao", name: "observacao" },
                            model: {
                              value: _vm.model.observacao,
                              callback: function ($$v) {
                                _vm.$set(_vm.model, "observacao", $$v)
                              },
                              expression: "model.observacao",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "fieldset-component",
            {
              attrs: {
                title: _vm.$t(
                  "__.Crud.transportadora.edit_vue_html.localizacao"
                ),
              },
            },
            [
              _c(
                "div",
                { attrs: { slot: "rows", id: "localizacao" }, slot: "rows" },
                [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-sm-4" }, [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: _vm.errors.first("cep"),
                              expression: "errors.first('cep')",
                            },
                          ],
                          staticClass: "form-group",
                        },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "control-label",
                              attrs: { for: "cep" },
                            },
                            [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(
                                    _vm.$t(
                                      "__.Crud.transportadora.edit_vue_html.cep"
                                    )
                                  ) +
                                  "\n                            "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("cep-field-component", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "cep",
                                expression: "'cep'",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: { id: "cep", name: "cep" },
                            on: {
                              "cep-changed": _vm.onCepChanged,
                              "cep-invalid": _vm.onCepInvalid,
                            },
                            model: {
                              value: _vm.model.cep,
                              callback: function ($$v) {
                                _vm.$set(_vm.model, "cep", $$v)
                              },
                              expression: "model.cep",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-sm-3" }, [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: _vm.errors.first("estadoId"),
                              expression: "errors.first('estadoId')",
                            },
                          ],
                          staticClass: "form-group",
                        },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "control-label",
                              attrs: { for: "estadoId" },
                            },
                            [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(
                                    _vm.$t(
                                      "__.Crud.transportadora.edit_vue_html.estado"
                                    )
                                  ) +
                                  "\n                            "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("combo-component", {
                            attrs: {
                              id: "estadoId",
                              name: "estadoId",
                              data: _vm.ufOptions,
                              searchable: true,
                            },
                            on: { input: _vm.onLoadCidades },
                            model: {
                              value: _vm.model.estadoId,
                              callback: function ($$v) {
                                _vm.$set(_vm.model, "estadoId", $$v)
                              },
                              expression: "model.estadoId",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-sm-5" }, [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: _vm.errors.first("cidadeId"),
                              expression: "errors.first('cidadeId')",
                            },
                          ],
                          staticClass: "form-group",
                        },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "control-label",
                              attrs: { for: "cidadeId" },
                            },
                            [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(
                                    _vm.$t(
                                      "__.Crud.transportadora.edit_vue_html.cidade"
                                    )
                                  ) +
                                  "\n                            "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("combo-component", {
                            attrs: {
                              id: "cidadeId",
                              name: "cidadeId",
                              data: _vm.cidadesOptions,
                              searchable: true,
                            },
                            model: {
                              value: _vm.model.cidadeId,
                              callback: function ($$v) {
                                _vm.$set(_vm.model, "cidadeId", $$v)
                              },
                              expression: "model.cidadeId",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-sm-4" }, [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: _vm.errors.first("endereco"),
                              expression: "errors.first('endereco')",
                            },
                          ],
                          staticClass: "form-group",
                        },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "control-label",
                              attrs: { for: "endereco" },
                            },
                            [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(
                                    _vm.$t(
                                      "__.Crud.transportadora.edit_vue_html.endereco"
                                    )
                                  ) +
                                  "\n                            "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.model.endereco,
                                expression: "model.endereco",
                              },
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "max:256",
                                expression: "'max:256'",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: {
                              autocomplete: "off",
                              type: "text",
                              id: "endereco",
                              name: "endereco",
                            },
                            domProps: { value: _vm.model.endereco },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.model,
                                  "endereco",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-sm-4" }, [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: _vm.errors.first("numero"),
                              expression: "errors.first('numero')",
                            },
                          ],
                          staticClass: "form-group",
                        },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "control-label",
                              attrs: { for: "numero" },
                            },
                            [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(
                                    _vm.$t(
                                      "__.Crud.transportadora.edit_vue_html.nro"
                                    )
                                  ) +
                                  "\n                            "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.model.numero,
                                expression: "model.numero",
                              },
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "max:256",
                                expression: "'max:256'",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: {
                              autocomplete: "off",
                              type: "text",
                              id: "numero",
                              name: "numero",
                            },
                            domProps: { value: _vm.model.numero },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.model,
                                  "numero",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-sm-4" }, [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: _vm.errors.first("bairro"),
                              expression: "errors.first('bairro')",
                            },
                          ],
                          staticClass: "form-group",
                        },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "control-label",
                              attrs: { for: "bairro" },
                            },
                            [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(
                                    _vm.$t(
                                      "__.Crud.transportadora.edit_vue_html.bairro"
                                    )
                                  ) +
                                  "\n                            "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.model.bairro,
                                expression: "model.bairro",
                              },
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "max:256",
                                expression: "'max:256'",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: {
                              autocomplete: "off",
                              type: "text",
                              id: "bairro",
                              name: "bairro",
                            },
                            domProps: { value: _vm.model.bairro },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.model,
                                  "bairro",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-sm-12" }, [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: _vm.errors.first("complemento"),
                              expression: "errors.first('complemento')",
                            },
                          ],
                          staticClass: "form-group",
                        },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "control-label",
                              attrs: { for: "complemento" },
                            },
                            [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(
                                    _vm.$t(
                                      "__.Crud.transportadora.edit_vue_html.complemento"
                                    )
                                  ) +
                                  "\n                            "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.model.complemento,
                                expression: "model.complemento",
                              },
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "max:256",
                                expression: "'max:256'",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: {
                              autocomplete: "off",
                              type: "text",
                              id: "complemento",
                              name: "complemento",
                            },
                            domProps: { value: _vm.model.complemento },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.model,
                                  "complemento",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]
                      ),
                    ]),
                  ]),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "fieldset-component",
            {
              attrs: {
                title: _vm.$t("__.Crud.transportadora.edit_vue_html.contato"),
              },
            },
            [
              _c(
                "div",
                { attrs: { slot: "rows", id: "contato" }, slot: "rows" },
                [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-sm-3" }, [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: _vm.errors.first("telefone"),
                              expression: "errors.first('telefone')",
                            },
                          ],
                          staticClass: "form-group",
                        },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "control-label",
                              attrs: { for: "telefone" },
                            },
                            [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(
                                    _vm.$t(
                                      "__.Crud.transportadora.edit_vue_html.tel"
                                    )
                                  ) +
                                  "\n                            "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "mask-telefone",
                                rawName: "v-mask-telefone",
                              },
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.model.telefone,
                                expression: "model.telefone",
                              },
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "telefone",
                                expression: "'telefone'",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: {
                              autocomplete: "off",
                              type: "text",
                              id: "telefone",
                              name: "telefone",
                            },
                            domProps: { value: _vm.model.telefone },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.model,
                                  "telefone",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-sm-3" }, [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: _vm.errors.first("celular"),
                              expression: "errors.first('celular')",
                            },
                          ],
                          staticClass: "form-group",
                        },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "control-label",
                              attrs: { for: "celular" },
                            },
                            [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(
                                    _vm.$t(
                                      "__.Crud.transportadora.edit_vue_html.cel"
                                    )
                                  ) +
                                  "\n                            "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "mask-telefone",
                                rawName: "v-mask-telefone",
                              },
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.model.celular,
                                expression: "model.celular",
                              },
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "telefone",
                                expression: "'telefone'",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: {
                              autocomplete: "off",
                              type: "text",
                              id: "celular",
                              name: "celular",
                            },
                            domProps: { value: _vm.model.celular },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.model,
                                  "celular",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-sm-6" }, [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: _vm.errors.first("email"),
                              expression: "errors.first('email')",
                            },
                          ],
                          staticClass: "form-group",
                        },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "control-label",
                              attrs: { for: "email" },
                            },
                            [_vm._v("E-mail")]
                          ),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.model.email,
                                expression: "model.email",
                              },
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "email",
                                expression: "'email'",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: {
                              autocomplete: "off",
                              type: "email",
                              id: "email",
                              name: "email",
                              placeholder: "exemplo@exemplo.com.br",
                            },
                            domProps: { value: _vm.model.email },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.model,
                                  "email",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]
                      ),
                    ]),
                  ]),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _c("fieldset", [
            !_vm.hideExtras
              ? _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-sm-12 newbtn" }, [
                    _c("div", { staticClass: "form-group" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-success",
                          attrs: { type: "submit" },
                        },
                        [
                          _c("i", {
                            staticClass: "fa fa-save",
                            attrs: { "aria-hidden": "true" },
                          }),
                          _vm._v(
                            "\n                            " +
                              _vm._s(_vm.$t("__.Crud.salvar")) +
                              "\n                        "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-secondary",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              _vm.load()
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n                            " +
                              _vm._s(_vm.$t("__.Crud.limpar")) +
                              "\n                        "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-secondary",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              _vm.cancel()
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n                            " +
                              _vm._s(_vm.$t("__.Crud.voltar")) +
                              "\n                        "
                          ),
                        ]
                      ),
                    ]),
                  ]),
                ])
              : _vm._e(),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c("loading-modal-component", { ref: "loadingModalComponent" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }