import Vue from "vue";

import comboComponent from "@/components/child/form/combo.vue";
import qrCodeReaderComponent from "@/components/child/qrCodeReader/view.vue";
import { Component, Watch } from "@/decorators";
import TipoEtapa from "@/models/enum/tipoEtapa";
import ManipulacaoOrdemModel from "@/models/manipulacaoOrdemModel";
import PaginationModel from "@/models/paginationModel";
import EtapaPCPModel from "@/models/pcp/etapaPCPModel";
import ManipulacaoOrdemEtapaPCPModel from "@/models/pcp/manipulacaoOrdemEtapaPCPModel";
import ManipulacaoOrdemPCPModel from "@/models/pcp/manipulacaoOrdemPCPModel";
import MotivoEtapaPCPModel from "@/models/pcp/motivoEtapaPCPModel";
import EtapaPCPService from "@/services/etapaPCPService";
import ManipulacaoOrdemService from "@/services/manipulacaoOrdemService";
import MotivoEtapaPCPService from "@/services/motivoEtapaPCPService";
import ManipulacaoOrdemPCPService from "@/services/pcp/manipulacaoOrdemPCPService";

import { finalizarEtapa, gerarModeloEtapaPCPDestino, getIsMotivoObrigatorio } from "../pcpUtil";

@Component({
    components: {
        comboComponent,
        qrCodeReaderComponent,
    },
})
export default class PcpRapidoComponent extends Vue {
    private etapaPCPService = new EtapaPCPService();
    private manipulacaoOrdemPCPService = new ManipulacaoOrdemPCPService();
    private manipulacaoOrdemService = new ManipulacaoOrdemService();
    private motivoEtapaPCPService = new MotivoEtapaPCPService();

    private codigo = null;
    private etapaDestinoId = null;
    private proximaEtapaDestinoId = null;
    private motivoEtapaPCPId = null;
    private status = 0;
    private proximaEtapaDestinoNotObrigatorioId = null;
    private etapas: EtapaPCPModel[] = [];

    private etapasOptions = [];
    private motivosEtapaOptions: Object[] = [];
    private isMotivoObrigatorio = false;

    private async onSubmitEtapaPcp() {
        try {
            const isValid = await this.$validator.validateAll();
            if (isValid) {
                if (this.codigo) {
                    this.codigo = this.codigo.toLocaleUpperCase().replace("$M", "");

                    const manipulacao = await this.manipulacaoOrdemService
                        .getByCodigo(this.codigo)
                        .withLoading()
                        .resolveWithJSON<ManipulacaoOrdemModel>(false);

                    const pcp = await this.manipulacaoOrdemPCPService
                        .getByManipulacaoOrdem(manipulacao.id)
                        .withLoading()
                        .resolveWithJSON<ManipulacaoOrdemPCPModel>(false);

                    if (pcp.id == null) {
                        pcp.dataInicio = new Date();
                    }

                    const etapasOrdenadas = pcp.manipulacaoOrdemEtapasPCP.sort((a, b) =>
                        a.etapaPCP.ordem > b.etapaPCP.ordem ? -1 : 1,
                    );

                    const etapaEmAberto = etapasOrdenadas.find(
                        p => p.dataFim == null || p.etapaPCP.tipo == TipoEtapa.Final,
                    );

                    this.proximaEtapaDestinoId = null;
                    this.proximaEtapaDestinoNotObrigatorioId = null;

                    if (etapaEmAberto) {
                        if (this.etapas.length > 0) {
                            const filteredEtapas = this.etapas.filter(
                                p => p.obrigatorio && p.ordem > etapaEmAberto.etapaPCP.ordem,
                            );
                            const filteredEtapasNotObrigatorio = this.etapas.filter(
                                p => p.ordem > etapaEmAberto.etapaPCP.ordem,
                            );

                            if (filteredEtapas.length > 0) {
                                this.proximaEtapaDestinoId = filteredEtapas.sort((a, b) =>
                                    a.ordem > b.ordem ? 1 : -1,
                                )[0].id;
                            }

                            if (filteredEtapasNotObrigatorio.length > 0) {
                                this.proximaEtapaDestinoNotObrigatorioId = filteredEtapasNotObrigatorio.sort((a, b) =>
                                    a.ordem > b.ordem ? 1 : -1,
                                )[0].id;
                            }
                        }
                    } else {
                        this.etapaDestinoId = null;
                        return;
                    }

                    this.isMotivoObrigatorio = getIsMotivoObrigatorio(
                        this.etapas,
                        this.proximaEtapaDestinoId,
                        this.proximaEtapaDestinoNotObrigatorioId,
                        this.etapaDestinoId,
                    );

                    if (this.isMotivoObrigatorio) {
                        if (this.motivosEtapaOptions.length === 0) {
                            try {
                                const data = await this.motivoEtapaPCPService
                                    .combo()
                                    .resolveWithJSON<PaginationModel<MotivoEtapaPCPModel>>();

                                this.motivosEtapaOptions = data.list.map(item => ({
                                    value: item.id,
                                    text: item.descricao,
                                }));
                            } catch {}
                        }

                        if (!this.motivoEtapaPCPId) {
                            this.status = 3;
                            return;
                        }
                    }

                    finalizarEtapa(pcp.manipulacaoOrdemEtapasPCP);

                    const model = new ManipulacaoOrdemEtapaPCPModel();
                    const newEtapa = await gerarModeloEtapaPCPDestino(
                        pcp,
                        model,
                        this.etapaDestinoId,
                        "",
                        "",
                    ).withLoading();

                    pcp.manipulacaoOrdemEtapasPCP.push(newEtapa);

                    this.status = 0;

                    const response = await this.manipulacaoOrdemPCPService[!pcp.id ? "insert" : "update"](pcp)
                        .withLoading()
                        .resolveWithResponse(false);

                    this.status = response.ok ? 1 : 2;

                    if (this.status === 1) {
                        this.codigo = "";
                        this.isMotivoObrigatorio = false;
                        this.motivoEtapaPCPId = null;
                    }
                }
            }
        } catch {
            this.status = 2;
        }
    }

    @Watch("etapaDestinoId")
    private onChangeEtapa() {
        this.isMotivoObrigatorio = false;
        this.status = 0;
    }

    private onReadQrCode(code: string) {
        this.codigo = code;
        this.onSubmitEtapaPcp();
    }

    private async mounted() {
        const data = await this.etapaPCPService.combo(true).resolveWithJSON<PaginationModel<EtapaPCPModel>>();

        this.etapas = data.list.filter(e => e.ativo);
        this.etapasOptions = this.etapas.map(item => ({ value: item.id, text: item.descricao }));
    }
}
