import ModeloImpressaoModel from "@/models/modeloImpressao/modeloImpressaoModel";
import { getCustomParams, getPaginationParams } from "@/utils/common/http";

import ClienteEnderecoEntregaModel from "../models/clienteEnderecoEntregaModel";
import SituacoesVenda from "../models/enum/situacoesVenda";
import VendaModel, { VendaListParameters } from "../models/vendaModel";
import nfetch from "../utils/events/nfetch";

import { IServicePrint } from "./base/iServicePrint";
import { HtmlPrintPageInfo } from "./print/printerQueue";

export default class VendaService extends IServicePrint<VendaModel> {
    constructor() {
        super("Venda");
    }

    //adiar data validade do orçamento
    public adiar(model: VendaModel, diasAdiar: number): Promise<Response> {
        //@ts-ignore
        return nfetch(`/${this.controllerName}/Adiar?diasAdiar=${diasAdiar}`, {
            method: "post",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json; charset=UTF-8",
                "XSRF-TOKEN": this.getCookie("XSRF-TOKEN"),
            },
            body: JSON.stringify(model),
        })
            .then((response: Response) => {
                if (response.ok) {
                    return response;
                } else {
                    return this.mensagemErro(response, "Save");
                }
            })
            .catch(() => {});
    }

    public rejeitar(vendaId: number, motivoId: number): Promise<Response> {
        //@ts-ignore
        return nfetch(`/${this.controllerName}/Rejeitar?vendaId=${vendaId}&motivoId=${motivoId}`, {
            method: "post",
            credentials: "same-origin",
        })
            .then((response: Response) => {
                if (response.ok) {
                    return response;
                } else {
                    return this.mensagemErro(response, "Save");
                }
            })
            .catch(() => {});
    }

    public getInfosHome(situacao: SituacoesVenda): Promise<Response> {
        return nfetch(`/${this.controllerName}/GetInfosHome?situacao=${situacao}`, {
            credentials: "same-origin",
        });
    }

    public updateFaturamento(model: VendaModel): Promise<Response> {
        //@ts-ignore
        return nfetch(`/${this.controllerName}/UpdateFaturameto`, {
            method: "post",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json; charset=UTF-8",
                "XSRF-TOKEN": this.getCookie("XSRF-TOKEN"),
            },
            body: JSON.stringify(model),
        })
            .then((response: Response) => {
                if (response.ok) {
                    return response;
                } else {
                    return this.mensagemErro(response, "Save");
                }
            })
            .catch(() => {});
    }

    public updateExpedicao(model: VendaModel, ignoraTransporte = false): Promise<Response> {
        //@ts-ignore
        return nfetch(`/${this.controllerName}/UpdateExpedicao?ignoraTransporte=${ignoraTransporte}`, {
            method: "post",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json; charset=UTF-8",
                "XSRF-TOKEN": this.getCookie("XSRF-TOKEN"),
            },
            body: JSON.stringify(model),
        })
            .then((response: Response) => {
                if (response.ok) {
                    return response;
                } else {
                    return this.mensagemErro(response, "Save");
                }
            })
            .catch(() => {});
    }

    public replicarOrcamento(vendaId: number): Promise<Response> {
        //@ts-ignore
        return nfetch(`/${this.controllerName}/ReplicarOrcamento?vendaId=${vendaId}`, {
            credentials: "same-origin",
        })
            .then((response: Response) => {
                if (response.ok) {
                    return response;
                } else {
                    return this.mensagemErro(response, "Get");
                }
            })
            .catch(() => {});
    }

    public getReplicaOrcamento(vendaId: number): Promise<Response> {
        return nfetch(`/${this.controllerName}/GetReplicaOrcamento?vendaId=${vendaId}`, {
            credentials: "same-origin",
        });
    }

    public replicarMultiplosVendaItem(itemsIds: number[]) {
        return nfetch(`/${this.controllerName}/ReplicarMultiplosVendaItem`, {
            method: "post",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json; charset=UTF-8",
                "XSRF-TOKEN": this.getCookie("XSRF-TOKEN"),
            },
            body: JSON.stringify(itemsIds),
        });
    }

    public buscaVendaByEndereco(endereco: ClienteEnderecoEntregaModel): Promise<Response> {
        //@ts-ignore
        return nfetch(`/${this.controllerName}/BuscaVendaByEndereco?endereco=${endereco}`, {
            method: "post",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json; charset=UTF-8",
                "XSRF-TOKEN": this.getCookie("XSRF-TOKEN"),
            },
            body: JSON.stringify(endereco),
        });
    }

    public getByCodigo(codigo: number): Promise<Response> {
        //@ts-ignore
        return nfetch(`/${this.controllerName}/GetByCodigo?codigo=${codigo}`, {
            credentials: "same-origin",
        })
            .then((response: Response) => {
                if (response.ok) {
                    return response;
                } else {
                    return this.mensagemErro(response, "Get");
                }
            })
            .catch(() => {});
    }

    public desconcluirVenda(model: VendaModel): Promise<Response> {
        //@ts-ignore
        return nfetch(`/${this.controllerName}/DesconcluirVenda`, {
            method: "post",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json; charset=UTF-8",
                "XSRF-TOKEN": this.getCookie("XSRF-TOKEN"),
            },
            body: JSON.stringify(model),
        });
    }

    public listOrcamento(
        filterKey: string,
        sortKey: string,
        sortOrder: string,
        pageIndex: number,
        pageSize: number,
        customProperties: VendaListParameters,
    ): Promise<Response> {
        const custom = getCustomParams(customProperties);
        const pagination = getPaginationParams(filterKey, sortKey, sortOrder, pageIndex, pageSize);

        return nfetch(`/${this.controllerName}/ListOrcamentos?${pagination}` + custom, {
            credentials: "same-origin",
        });
    }

    public listVenda(
        filterKey: string,
        sortKey: string,
        sortOrder: string,
        pageIndex: number,
        pageSize: number,
        customProperties: VendaListParameters,
    ): Promise<Response> {
        const custom = getCustomParams(customProperties);
        const pagination = getPaginationParams(filterKey, sortKey, sortOrder, pageIndex, pageSize);

        return nfetch(`/${this.controllerName}/ListVendas?${pagination}` + custom, {
            credentials: "same-origin",
        });
    }

    public listVendasEntregas(
        filterKey: string,
        sortKey: string,
        sortOrder: string,
        pageIndex: number,
        pageSize: number,
        customProperties: VendaListParameters,
    ): Promise<Response> {
        const custom = getCustomParams(customProperties);
        const pagination = getPaginationParams(filterKey, sortKey, sortOrder, pageIndex, pageSize);

        return nfetch(`/${this.controllerName}/ListVendasEntregas?${pagination}` + custom, {
            credentials: "same-origin",
        });
    }

    public listVendasEtiquetas(
        filterKey: string,
        sortKey: string,
        sortOrder: string,
        pageIndex: number,
        pageSize: number,
        customProperties: VendaListParameters,
    ): Promise<Response> {
        const custom = getCustomParams(customProperties);
        const pagination = getPaginationParams(filterKey, sortKey, sortOrder, pageIndex, pageSize);

        return nfetch(`/${this.controllerName}/ListVendasEtiquetas?${pagination}` + custom, {
            credentials: "same-origin",
        });
    }

    public listVendasByCliente(
        filterKey: string,
        sortKey: string,
        sortOrder: string,
        pageIndex: number,
        pageSize: number,
        customProperties: VendaListParameters,
    ): Promise<Response> {
        const custom = getCustomParams(customProperties);
        const pagination = getPaginationParams(filterKey, sortKey, sortOrder, pageIndex, pageSize);

        return nfetch(`/${this.controllerName}/ListVendasByCliente?${pagination}` + custom, {
            credentials: "same-origin",
        });
    }

    public listFracionamento(
        filterKey: string,
        sortKey: string,
        sortOrder: string,
        pageIndex: number,
        pageSize: number,
        customProperties: { [key: string]: any } = {},
    ): Promise<Response> {
        const custom = getCustomParams(customProperties);
        const pagination = getPaginationParams(filterKey, sortKey, sortOrder, pageIndex, pageSize);

        return nfetch(`/${this.controllerName}/ListFracionamento?${pagination}` + custom, {
            credentials: "same-origin",
        });
    }

    public marcarComoEntregue(id: number): Promise<Response> {
        return nfetch(`/${this.controllerName}/MarcarComoEntregue?id=${id}`, {
            method: "post",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json; charset=UTF-8",
            },
        });
    }

    public async getToPrintPersonalizado(ids: number[], modeloImpressaoId: number): Promise<ModeloImpressaoModel[]> {
        const response = nfetch(
            `/${this.controllerName}/GetToPrintPersonalizado?modeloImpressaoId=${modeloImpressaoId}`,
            {
                method: "post",
                credentials: "same-origin",
                headers: {
                    "Content-Type": "application/json; charset=UTF-8",
                },
                body: JSON.stringify(ids),
            },
        );

        return await response.resolveWithJSON<ModeloImpressaoModel[]>();
    }

    public async getManyHTMLPage(
        vendaIds: number[],
        modelImpressaoId: number,
        copias: number,
        usuarioId: number,
    ): Promise<HtmlPrintPageInfo> {
        try {
            const data = await this.getToPrintPersonalizado(vendaIds, modelImpressaoId);

            if (data.length == 0) {
                throw new Error("Não foi possível obter o modelo de impressão");
            }

            let modelos = "";
            let printerName = "";
            for (let i = 0; i < data.length; i++) {
                const modelImpressao = new ModeloImpressaoModel();
                modelImpressao.updateFrom(data[i]);

                printerName = modelImpressao.nomeImpressora;
                if (modelImpressao.usuarios.some(p => p.usuarioId == usuarioId)) {
                    printerName = modelImpressao.usuarios.find(p => p.usuarioId == usuarioId).nomeImpressora;
                }

                const modelo = await modelImpressao.toHTML();
                modelos += modelo;
            }
            return {
                pageTitle: data[0].descricao,
                pageHtml: modelos,
                copies: copias,
                printerName: printerName,
            };
        } catch {}
    }

    public listVendaHome(categoria?: number): Promise<Response> {
        return nfetch(`/${this.controllerName}/ListVendaHome?categoria=${categoria}`, { credentials: "same-origin" });
    }

    public listVendaHomePagination(
        filterKey: string,
        sortKey: string,
        sortOrder: string,
        pageIndex: number,
        pageSize: number,
        customProperties: VendaListParameters,
    ): Promise<Response> {
        const custom = getCustomParams(customProperties);
        const pagination = getPaginationParams(filterKey, sortKey, sortOrder, pageIndex, pageSize);

        return nfetch(`/${this.controllerName}/ListVendaHomePagination?${pagination}` + custom, {
            credentials: "same-origin",
        });
    }

    public listVendaSeparacaoHome(): Promise<Response> {
        return nfetch(`/${this.controllerName}/ListVendaSeparacaoHome`, { credentials: "same-origin" });
    }
}
