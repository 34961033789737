import { mapGetters, mapState } from "vuex";

import comboComponent from "@/components/child/form/combo.vue";
import dateTimePickerComponent from "@/components/child/form/datetimepicker.vue";
import { GridAction } from "@/components/child/grid/gridAction";
import { GridColors, GridColumn, GridColumnType } from "@/components/child/grid/gridColumn";
import ImpressaoComponent from "@/components/child/impressao/impressaoComponent";
import impressaoComponent from "@/components/child/impressao/impressaoComponent.vue";
import TentativasEntregaComponent from "@/components/parent/expedicao/tentativasEntrega";
import tentativasEntregaComponent from "@/components/parent/expedicao/tentativasEntrega.vue";
import { Component, Prop, Watch } from "@/decorators";
import PreLoadPackModel from "@/models/auxiliar/preLoadPackModel";
import ModeloEspecifico from "@/models/enum/modeloEspecifico";
import TipoBuscaVenda from "@/models/enum/tipoBuscaVenda";
import VendaEntregaStatus from "@/models/enum/VendaEntregaStatus";
import ModeloImpressaoModel from "@/models/modeloImpressao/modeloImpressaoModel";
import PaginationModel from "@/models/paginationModel";
import UsuarioModel from "@/models/usuarioModel";
import VendaModel, { VendaListParameters } from "@/models/vendaModel";
import ModeloImpressaolService from "@/services/modeloImpressaoService";
import VendaService from "@/services/vendaService";
import { AppState, Getters } from "@/store/store";
import { convertToDateInput, convertToDateWithTimeInputString } from "@/utils/common/date";

import ListComponentBase from "../crud/listComponentBase";
import PrintModel from "../printModel/printModel";

@Component({
    components: {
        dateTimePickerComponent,
        comboComponent,
        tentativasEntregaComponent,
        impressaoComponent,
    },
    computed: {
        ...mapState({
            preLoadList: (state: AppState) => state.preLoad.preLoadList,
            usuarioLogado: (state: AppState) => state.session.usuarioLogado,
        }),
        ...mapGetters(["VALIDAR_PERMISSAO_SOMENTE_CONSULTA"] as Getters),
    },
})
export default class ListEntregaComponent extends ListComponentBase<VendaModel> {
    // State computed props
    preLoadList: PreLoadPackModel;
    usuarioLogado: UsuarioModel;
    VALIDAR_PERMISSAO_SOMENTE_CONSULTA: (telaDescricao: string) => boolean;

    private tentativasEntregaComponent: TentativasEntregaComponent = null;
    private impressaoComponent: ImpressaoComponent = null;

    extraActions: Array<object> = [];
    extraActionTentativa: Array<GridAction> = [];
    extraActionEntregue: Array<GridAction> = [];
    extraActionRomaneio: Array<GridAction> = [];

    @Prop({ type: Boolean, default: false }) CalledByShortCut: boolean;

    private vendaService = new VendaService();
    usuariosOptions: Array<Object> = [];
    locaisEntregaOptions: Array<Object> = [];
    statusEntregaOptions: Array<Object> = [
        { text: "Entregue", value: VendaEntregaStatus.Entregue },
        { text: "Não Entregue", value: VendaEntregaStatus.NaoEntregue },
    ];

    isHidden = false;
    filtro: Array<Object> = [];
    dataFilter1: Date = null;
    dataFilter2: Date = null;
    tipoBuscaVenda = 0;
    vendedor = null;
    localEntregaId = null;
    statusEntrega = null;
    somenteConsultaVenda = false;
    situacao = 0;

    subDataName = "itens";

    tipoBuscaVendaOptions: Array<Object> = [
        { text: "Codigo da Venda", value: TipoBuscaVenda.Venda },
        { text: "Cliente", value: TipoBuscaVenda.Cliente },
        { text: "Manipulacao", value: TipoBuscaVenda.Manipulacao },
        { text: "Paciente", value: TipoBuscaVenda.Paciente },
    ];

    gridData: Array<VendaModel> = [];
    gridSelectedValues: Array<VendaModel> = [];

    get gridColumns(): Array<GridColumn> {
        const columns = [];
        columns.push(new GridColumn("codigo", "C\u00f3digo", GridColumnType.String, false, false, "", "", true));
        columns.push(
            new GridColumn("situacaoDescricao", "Situação", GridColumnType.String, false, false, "", "", true, false),
        );
        columns.push(new GridColumn("clienteNome", "Cliente", GridColumnType.String, false, false, "", "", true));
        columns.push(
            new GridColumn(
                "localEntregaDescricao",
                "Local de entrega",
                GridColumnType.String,
                false,
                false,
                "",
                "",
                true,
            ),
        );
        columns.push(
            new GridColumn("dataEntrega", "Data Entrega", GridColumnType.DateTime, false, false, "", "", true),
        );
        columns.push(new GridColumn("valorFinal", "Valor", GridColumnType.Money, false, false, "", "", true));
        columns.push(
            new GridColumn(
                "statusTentativa",
                "Status Entrega",
                GridColumnType.String,
                false,
                false,
                "",
                "",
                true,
                false,
            ),
        );
        columns.push(
            new GridColumn(
                "quantidadeTentativas",
                "Qtd Tentativas",
                GridColumnType.Integer,
                false,
                false,
                "",
                "",
                true,
                false,
            ),
        );
        return columns;
    }

    get gridColumnsVinculos(): Array<GridColumn> {
        return [
            new GridColumn("produtoDescricao", "Produto", GridColumnType.String),
            new GridColumn("continuo", "Continuo", GridColumnType.Boolean),
            new GridColumn("quantidade", "quantidade", GridColumnType.DecimalPonto),
            new GridColumn("valorUnitario", "valorUnitario", GridColumnType.Money),
            new GridColumn("valorTotal", "valorTotal", GridColumnType.Money),
            new GridColumn("dataValidade", "Data Validade", GridColumnType.Date, false),
        ];
    }

    conditionShowSubData(it) {
        return it["itens"]["length"] > 0;
    }

    private beforeMount() {
        this.setProps(new VendaService(), "venda", "dataEntrega", false, "desc");
    }

    public async loadMethod() {
        this.tentativasEntregaComponent = this.$refs.tentativasEntregaComponent as TentativasEntregaComponent;

        const customParameters: VendaListParameters = {
            dataInicial: convertToDateWithTimeInputString(this.filtro[0]),
            dataFinal: convertToDateWithTimeInputString(this.filtro[1]),
            vendedor: this.vendedor,
            localEntrega: this.localEntregaId,
            statusEntrega: this.statusEntrega,
        };

        return await this.vendaService
            .listVendasEntregas(
                this.gridFilterKey,
                this.gridSortKey,
                this.gridSortOrder,
                this.pags ? 1 : this.pageIndex,
                this.pags ? 999999 : this.pageSize,
                customParameters,
            )
            .withLoading()
            .resolveWithJSON<PaginationModel<VendaModel>>();
    }

    public async onMarcarComoEntregue(vendaId: number) {
        try {
            const sucesso = await this.vendaService.marcarComoEntregue(vendaId).withLoading().resolveWithoutJSON();

            if (sucesso) {
                await this.$showInclusaoUpdate(vendaId);
                await this.load();
            }
        } catch {}
    }

    public async afterLoad() {
        try {
            this.gridData["dataDe"] = this.filtro[0] ? this.filtro[0].toString() : " ";
            this.gridData["dataAte"] = this.filtro[1] ? this.filtro[1].toString() : " ";
            this.gridData["status"] = this.filtro[2] ? this.filtro[2].toString() : " ";

            this.isHidden = false;

            this.usuariosOptions = this.preLoadList.vendedoresCombo();
            this.locaisEntregaOptions = this.preLoadList.locaisEntregaCombo();
            this.somenteConsultaVenda = this.VALIDAR_PERMISSAO_SOMENTE_CONSULTA("vendas");
        } catch {}

        this.extraActions.splice(0);
        this.extraActionEntregue.splice(0);
        this.extraActionTentativa.splice(0);
        this.extraActionRomaneio.splice(0);

        for (let i = 0; i < this.gridData.length; i++) {
            this.extraActionEntregue.push(
                new GridAction("entregue", this.$t("__.ts.marcarEntregue") as string, "fa fa-check", GridColors.GREEN),
            );
            this.extraActionTentativa.push(
                new GridAction(
                    "tentativa",
                    this.$t("__.ts.tentativasEntrega") as string,
                    "fa fa-truck",
                    GridColors.GREEN,
                ),
            );
            this.extraActionRomaneio.push(
                new GridAction(
                    "imprimir-romaneio",
                    this.$t(
                        "__.Components.parent.home.fastaction.entregas.entregas_vue_html.imprimirRomaneio",
                    ) as string,
                    "fa fa-print",
                    GridColors.GRAY,
                ),
            );
        }

        this.extraActions.push(this.extraActionEntregue);
        this.extraActions.push(this.extraActionRomaneio);
        this.extraActions.push(this.extraActionTentativa);
    }

    private getFormValues(listaFiltro) {
        this.filtro = [];
        this.filtro.push(listaFiltro.target.elements.dataFilter1.value);
        this.filtro.push(listaFiltro.target.elements.dataFilter2.value);

        this.load();
    }

    private onSelectedValuesChanged(values: Array<VendaModel>) {
        this.gridSelectedValues = values;
    }

    private async imprimirRomaneio() {
        try {
            const modelosVenda = await new ModeloImpressaolService()
                .getByModeloEspecifico("venda", ModeloEspecifico.Romaneio)
                .resolveWithJSON<ModeloImpressaoModel>();

            const pages = await this.vendaService
                .getManyHTMLPage(
                    this.gridSelectedValues.map(x => x.id),
                    modelosVenda.id,
                    1,
                    this.usuarioLogado.id,
                )
                .withLoading();

            const printModel = new PrintModel();
            printModel.showHTMLOnBrowser(pages.pageHtml, true);
        } catch {}
    }

    @Watch("tipoBuscaVenda")
    private onTipoBuscaVenda() {
        this.load();
    }

    public async afterLoadPagination() {
        if (this.paginationParams.filtros != null) {
            this.filtro = this.paginationParams.filtros;
            if (this.filtro.length >= 0) {
                if (this.filtro[0]) {
                    this.dataFilter1 = convertToDateInput(this.filtro[0].toString());
                }
                if (this.filtro.length >= 1) {
                    if (this.filtro[1]) {
                        this.dataFilter2 = convertToDateInput(this.filtro[1].toString());
                    }
                }
            }
        }
    }

    private async onExtraActions(name: string, venda: VendaModel) {
        if (name.trim() == "tentativa") {
            this.tentativasEntregaComponent.show(venda.id);
        }
        if (name.trim() == "entregue") {
            this.onMarcarComoEntregue(venda.id);
        }
        if (name.trim() == "imprimir-romaneio") {
            this.impressaoComponent = this.$refs.impressaoComponent as ImpressaoComponent;

            await this.impressaoComponent.show({
                modelId: venda.id,
                tipoImpressao: "Venda",
                impressaoRotulo: false,
                impressaoSelecionarModelo: true,
                modeloEspecifico: ModeloEspecifico.Romaneio,
            });
        }
    }
}
