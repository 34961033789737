import Vue from "vue";

import actionBarComponent from "@/components/child/actionBar/actionBar.vue";
import comboComponent from "@/components/child/form/combo.vue";
import fieldsetComponent from "@/components/child/form/fieldset.vue";
import { Component } from "@/decorators";
import PDFService from "@/services/pdf/PDFService";

import "../crud.scss";

@Component({
    components: {
        comboComponent,
        fieldsetComponent,
        actionBarComponent,
    },
})
export default class BalancoCompletoEditComponent extends Vue {
    private service = new PDFService("BalancoCompletoPDF");

    ano: number = null;
    tipoPeriodoId: number = null;

    tipoPeriodoOptions: Array<Object> = [
        { text: "1º Trimestre", value: 1 },
        { text: "2º Trimestre", value: 2 },
        { text: "3º Trimestre", value: 3 },
        { text: "4º Trimestre", value: 4 },
        { text: "Anual", value: 5 },
    ];

    private load() {
        this.ano = new Date().getFullYear();
    }

    private async save() {
        try {
            const isValid = await this.$validator.validateAll();

            if (isValid) {
                const filters = {
                    ano: this.ano,
                    periodo: this.tipoPeriodoId,
                };

                await this.service.geraPDFWithFilters(filters);

                this.$showSuccess("Sucesso", "Arquivo foi gerado corretamente.");
            }
        } catch (e) {
            this.$showError("Erro", `Erro ao gerar o arquivo.\n Erro: ${e.message}`);
        }
    }

    private cancel() {
        this.$router.back();
    }

    private async mounted() {
        this.load();
    }
}
