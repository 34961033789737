import Boot from "../../main";

import { BrowserPrinterBackend } from "./browserPrinterBackend";
import { HtmlPrintPageInfo, PrinterBackend, PrinterQueue } from "./printerQueue";
import { QzTrayPrinterBackend } from "./qzTrayPrinterBackend";

export class PrinterError extends Error {
    name = "PrinterError";
}
/** Nenhuma impressora disponível */
export class PrinterNoPrinterAvailable extends PrinterError {}
/** Houve fallback para a impressora padrão */
export class PrinterFallbackDefaultError extends PrinterError {}

export default class PrinterService {
    /**
     * Lista de backends. Para registrar um novo backend, basta adicionar ele aqui.
     * Também é possível adicionar um novo backend em tempo de execução.
     *
     * Por exemplo, é possível criar um backend que se comunica (através de um servidor
     * intermediário) com uma instância do PrintService em outro computador.
     */
    private static backends: PrinterBackend[] = [new BrowserPrinterBackend(), new QzTrayPrinterBackend()];

    /** Filas de impressão. Há uma fila por impressora. */
    private static queues: PrinterQueue[] = [];

    /** Inicializar backends de impressão. Retorna true se todos os backends foram inicializados corretamente. */
    public static async init() {
        const results = await Promise.all(PrinterService.backends.map(backend => backend.init()));
        return results.every(result => result);
    }

    /** Envia para impressão todas as páginas em todas as filas de impressão. */
    public async flushAllQueues() {
        await Promise.all(PrinterService.queues.map(queue => queue.flush()));
    }

    /**
     * Enfileirar páginas nas filas de impressão. A lista de filas de impressão
     * é atualizada antes das páginas serem enfileiradas.
     *
     * Se houver algum erro para enfileirar uma página, a propriedade `error` da
     * página é definida.
     */
    public async enqueuePages(pages: HtmlPrintPageInfo[], defaultPrinterName: string = null) {
        if ((await this.refreshPrinterQueues()) == null) {
            throw new PrinterNoPrinterAvailable();
        }
        for (const page of pages) {
            page.printerName = page.printerName || defaultPrinterName;
            const printerQueue = this.getPrinterQueueOrDefault(page.printerName);
            if (printerQueue.printerName !== page.printerName) {
                if (!(printerQueue.printerName == "browser" && !page.printerName)) {
                    page.error = new PrinterFallbackDefaultError(page.printerName);
                }
                page.printerName = printerQueue.printerName;
            }
            printerQueue.enqueue(page);
        }
    }

    // Verificar se houve erros ao adicionar as páginas nas filas de impressão.
    public async verifyErrors(pages: HtmlPrintPageInfo[]) {
        const pagesWithErrors = pages.filter(p => p.error);
        if (pagesWithErrors.length) {
            const pagesWithPrinterFallbackError = pages.filter(p => p.error instanceof PrinterFallbackDefaultError);
            if (pagesWithPrinterFallbackError.length) {
                if (!(await this.shouldContinueOnPrinterFallback(pagesWithPrinterFallbackError))) {
                    return true;
                }
            }
        }

        return false;
    }

    private async shouldContinueOnPrinterFallback(pages: HtmlPrintPageInfo[]) {
        const errors = pages.map(
            (p, idx) =>
                `${p.pageTitle || p.pageNumber || idx} - ${
                    p.error.message ? `(offline) ${p.error.message}` : Boot.$t("__.ts.impressoraNull")
                }`,
        );

        return await Boot.$showQuestionWithHTML(
            `${Boot.$t("__.ts.impressoraPadraoFoiUsadaEmUmaOuMaisPaginas")} ${Boot.$t("__.ts.desejaContinuar")}`,
            errors.join("<br>"),
        );
    }

    public async dequeuePages(pages: HtmlPrintPageInfo[]) {
        for (const page of pages) {
            const printerQueue = this.getPrinterQueue(page.printerName);
            printerQueue.dequeue(page);
        }
    }

    /**
     * Imprime o conteúdo HTML passado em cada uma das páginas do array `pages`.
     *
     * Este método é a combinação de `enqueuePages()` + `flushAllQueues()`.
     */
    public async printHtml(pages: HtmlPrintPageInfo[], printerName: string = null) {
        await this.enqueuePages(pages, printerName);
        await this.flushAllQueues();
    }

    /** Atualiza a lista global de todas as filas de impressora, e retorna uma referência para essa lista. */
    public async refreshPrinterQueues(): Promise<PrinterQueue[]> {
        const result = await Promise.all(PrinterService.backends.map(backend => backend.getPrinters())).withLoading();
        PrinterService.queues = result.reduce((accumulator, current) => accumulator.concat(current), []);
        return this.getPrinterQueues();
    }

    /** Retorna a lista de impressoras já registradas. */
    public getPrinterQueues(): PrinterQueue[] {
        return PrinterService.queues;
    }

    /** Retorna a fila de impressão da impressora `printerName`. */
    public getPrinterQueue(printerName: string): PrinterQueue {
        return PrinterService.queues.find(queue => queue.printerName === printerName);
    }

    /**
     * Se `printerName` não for especificado ou for null, retorna a primeira fila de impressão.
     * Caso contrário, retorna a fila de impressão da impressora `printerName`.
     */
    public getPrinterQueueOrDefault(printerName: string = null): PrinterQueue {
        if (printerName) {
            const printerByName = this.getPrinterQueue(printerName);
            if (printerByName) {
                return printerByName;
            }
        }
        return PrinterService.queues[0];
    }
}
