import nfetch from "@/utils/events/nfetch";

export default class LogEntidadeService {
    private controllerName = "LogEntidade";

    public getList(registroId: number, tipoEntidade: number): Promise<Response> {
        return nfetch(`/${this.controllerName}/GetList?registroId=${registroId}&entidade=${tipoEntidade}`, {
            credentials: "same-origin",
        });
    }

    public getListWithoutFranquiaId(registroId: number, tipoEntidade: number): Promise<Response> {
        return nfetch(
            `/${this.controllerName}/GetListWithoutFranquiaId?registroId=${registroId}&entidade=${tipoEntidade}`,
            {
                credentials: "same-origin",
            },
        );
    }

    public insert(registroId: number, tipoEntidade: number, descricao: string): Promise<Response> {
        return nfetch(
            `/${this.controllerName}/Insert?registroId=${registroId}&entidade=${tipoEntidade}&descricao=${descricao}`,
            {
                method: "post",
                credentials: "same-origin",
                headers: {
                    "Content-Type": "application/json; charset=UTF-8",
                },
            },
        );
    }
}
