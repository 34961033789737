import Boot from "@/main";

export function getCookie(name: string): string {
    const nameLenPlus = name.length + 1;
    return (
        document.cookie
            .split(";")
            .map(c => c.trim())
            .filter(cookie => {
                return cookie.substring(0, nameLenPlus) === `${name}=`;
            })
            .map(cookie => {
                return decodeURIComponent(cookie.substring(nameLenPlus));
            })[0] || null
    );
}

export function mensagemErro(erro: Response, metodo: string) {
    let mensagem = "";
    switch (erro.status) {
        case 403: {
            mensagem = "Você não tem acesso a este recurso!";
            break;
        }
        case 400: {
            if (metodo == "Delete") {
                mensagem = "O item selecionado não pode ser excluído, pois está sendo utilizado por outros registros!";
            } else if (metodo == "Save") {
                mensagem = "Não foi possível salvar os dados informados, verifique se já não estão cadastrados!";
            }
            break;
        }
        case 404: {
            mensagem = "O Registro requisitado não foi encontrado!";
            break;
        }
        case 408: {
            mensagem = "A operação excedeu o tempo limite de execução, tente novamente!";
            break;
        }
        case 500: {
            if (metodo == "Save") {
                mensagem = "Verifique se todos os campos foram preenchidos corretamente, e tente novamente!";
            } else {
                if (metodo == "Get") {
                    mensagem = "Ocorreu um erro inesperado na requisição do registro!";
                }
            }
            break;
        }
        default: {
            mensagem = "Ocorreu um erro inesperado durante a execução do processo, tente novamente!";
            break;
        }
    }
    if (erro.status != 401) {
        Boot.$showError("Ocorreu um erro!", mensagem);
    }
    return erro;
}
