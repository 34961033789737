var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "crud crud-edit" },
    [
      _c(
        "form",
        {
          attrs: { novalidate: "novalidate" },
          on: {
            submit: function ($event) {
              $event.preventDefault()
              _vm.save()
            },
          },
        },
        [
          _c(
            "fieldset-component",
            {
              attrs: {
                title: _vm.$t(
                  "__.Components.parent.fichaTecnica.edit_vue_html.dadosGerais"
                ),
                collapsed: false,
              },
            },
            [
              _c("div", { attrs: { slot: "rows" }, slot: "rows" }, [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-sm-4" }, [
                    _c("div", { staticClass: "form-group" }, [
                      _c(
                        "label",
                        {
                          staticClass: "control-label",
                          attrs: { for: "categoriaTerapeutica" },
                        },
                        [
                          _vm._v(
                            "\n                                " +
                              _vm._s(
                                _vm.$t(
                                  "__.Components.parent.fichaTecnica.edit_vue_html.catTerapeu"
                                )
                              ) +
                              "\n                            "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.model.categoriaTerapeutica,
                            expression: "model.categoriaTerapeutica",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          autocomplete: "off",
                          type: "text",
                          id: "categoriaTerapeutica",
                          name: "categoriaTerapeutica",
                          disabled: _vm.somenteConsulta,
                        },
                        domProps: { value: _vm.model.categoriaTerapeutica },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.model,
                              "categoriaTerapeutica",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-4" }, [
                    _c("div", { staticClass: "form-group" }, [
                      _c(
                        "label",
                        {
                          staticClass: "control-label",
                          attrs: { for: "conservacao" },
                        },
                        [
                          _vm._v(
                            "\n                                " +
                              _vm._s(
                                _vm.$t(
                                  "__.Components.parent.fichaTecnica.edit_vue_html.conserv"
                                )
                              ) +
                              "\n                            "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.model.conservacao,
                            expression: "model.conservacao",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          autocomplete: "off",
                          type: "text",
                          id: "conservacao",
                          name: "conservacao",
                          disabled: _vm.somenteConsulta,
                        },
                        domProps: { value: _vm.model.conservacao },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.model,
                              "conservacao",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-4" }, [
                    _c("div", { staticClass: "form-group" }, [
                      _c(
                        "label",
                        {
                          staticClass: "control-label",
                          attrs: { for: "codigo" },
                        },
                        [
                          _vm._v(
                            "\n                                " +
                              _vm._s(
                                _vm.$t(
                                  "__.Components.parent.fichaTecnica.edit_vue_html.refe"
                                )
                              ) +
                              "\n                            "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.model.produto.farmacopeia.descricao,
                            expression: "model.produto.farmacopeia.descricao",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          autocomplete: "off",
                          type: "text",
                          id: "referencia",
                          name: "referencia",
                          disabled: "",
                        },
                        domProps: {
                          value: _vm.model.produto.farmacopeia.descricao,
                        },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.model.produto.farmacopeia,
                              "descricao",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                  ]),
                ]),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "fieldset-component",
            {
              attrs: {
                title: _vm.$t(
                  "__.Components.parent.fichaTecnica.edit_vue_html.ensaios"
                ),
                collapsed: false,
              },
            },
            [
              _c(
                "div",
                { attrs: { slot: "rows" }, slot: "rows" },
                [
                  _c("gridEnsaiosComponent", {
                    attrs: {
                      initialData: _vm.ensaiosInitial,
                      ensaiosOptions: _vm.ensaiosOptions,
                      somenteConsulta: _vm.somenteConsulta,
                    },
                    on: { onChange: _vm.onChangeEnsaios },
                  }),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "fieldset-component",
            {
              attrs: {
                title: _vm.$t(
                  "__.Components.parent.fichaTecnica.edit_vue_html.soludibilidade"
                ),
                collapsed: false,
              },
            },
            [
              _c(
                "div",
                { attrs: { slot: "rows" }, slot: "rows" },
                [
                  _c("gridEnsaiosComponent", {
                    attrs: {
                      initialData: _vm.ensaiosSoludibilidadeInitial,
                      ensaiosOptions: _vm.ensaiosOptions,
                      somenteConsulta: _vm.somenteConsulta,
                      soludibilidade: true,
                    },
                    on: { onChange: _vm.onChangeEnsaiosSoludibilidade },
                  }),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "fieldset-component",
            {
              attrs: {
                title: _vm.$t(
                  "__.Components.parent.fichaTecnica.edit_vue_html.dadosAdicionais"
                ),
                collapsed: true,
              },
            },
            [
              _c(
                "div",
                { attrs: { slot: "rows" }, slot: "rows" },
                [
                  !_vm.somenteConsulta
                    ? _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-sm-12" }, [
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c(
                                "combo-component",
                                {
                                  attrs: {
                                    id: "dadosAdicionaisId",
                                    name: "dadosAdicionaisId",
                                    actionName:
                                      "dados_adicionais_ficha_tecnica",
                                    addNew: true,
                                    customView: true,
                                    data: _vm.optionsDadosAdicionais,
                                    formAddNewName: _vm.$t(
                                      "__.Components.parent.fichaTecnica.edit_vue_html.novoDadoAdicional"
                                    ),
                                    modalClass: "lm-modal",
                                    refresh: _vm.loadDadosAdicionais,
                                  },
                                  on: {
                                    addNewItemCombo:
                                      _vm.onAddNewDadosAdicionais,
                                    openComboNewItem:
                                      _vm.openNewDadosAdicionais,
                                  },
                                  model: {
                                    value: _vm.dadosAdicionaisSelected,
                                    callback: function ($$v) {
                                      _vm.dadosAdicionaisSelected = $$v
                                    },
                                    expression: "dadosAdicionaisSelected",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      attrs: { slot: "component" },
                                      slot: "component",
                                    },
                                    [
                                      _c("dadosAdicionaisFichaTecnica", {
                                        ref: "dadosAdicionaisFichaTecnicaComponent",
                                        attrs: { CalledByShortCut: true },
                                        on: {
                                          "save-ok":
                                            _vm.onDadosAdicionaisSaveOk,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.somenteConsulta
                    ? _c("buttonIncluirComponent", {
                        on: { click: _vm.onInclusaoDadosAdicionais },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("grid-component", {
                    ref: "gridcomponent",
                    attrs: {
                      data: _vm.gridDataDadosAdicionais,
                      columns: _vm.gridColumnsDadosAdicionais,
                      showRemoveItem: true,
                      showEditItem: false,
                      showActionColumn: !_vm.somenteConsulta,
                      showSortIcons: true,
                      showTableHead: false,
                      showAddNewButton: false,
                      showTableFoot: false,
                      showTools: false,
                    },
                    on: { "remove-item": _vm.onRemoveDadosAdicionais },
                  }),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "fieldset-component",
            {
              attrs: {
                title: _vm.$t(
                  "__.Components.parent.fichaTecnica.edit_vue_html.obss"
                ),
                collapsed: false,
              },
            },
            [
              _c("div", { attrs: { slot: "rows" }, slot: "rows" }, [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-sm-12" }, [
                    _c("div", { staticClass: "form-group" }, [
                      _c(
                        "label",
                        {
                          staticClass: "control-label",
                          attrs: { for: "observacao" },
                        },
                        [
                          _vm._v(
                            "\n                                " +
                              _vm._s(
                                _vm.$t(
                                  "__.Components.parent.fichaTecnica.edit_vue_html.obss"
                                )
                              ) +
                              "\n                            "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("textarea", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.model.observacao,
                            expression: "model.observacao",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          autocomplete: "off",
                          type: "text",
                          id: "observacao",
                          rows: 5,
                          name: "observacao",
                          disabled: _vm.somenteConsulta,
                        },
                        domProps: { value: _vm.model.observacao },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.model,
                              "observacao",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                  ]),
                ]),
              ]),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("gridResultadosComponent", {
        ref: "gridResultadosComponent",
        on: { "ensaios-resultados": _vm.onResultados },
      }),
      _vm._v(" "),
      _c(
        "shortcutComponent",
        {
          ref: "shortcutComponent",
          attrs: { showAddNew: false, showBtnOk: false },
        },
        [
          _c(
            "div",
            { attrs: { slot: "component" }, slot: "component" },
            [
              _c("gridLoteComponent", {
                ref: "gridLoteComponent",
                on: { onSelectLote: _vm.onImprimirLote },
              }),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }