import TiposPessoa from "../../models/enum/tiposPessoa";
import TransportadoraModel from "../../models/transporte/transportadoraModel";
import nfetch from "../../utils/events/nfetch";
import { IService } from "../base/iService";

export default class TransportadoraService extends IService<TransportadoraModel> {
    constructor() {
        super("Transportadora");
    }

    public getByDocumento(tipoPessoa: TiposPessoa, documento: string): Promise<Response> {
        return nfetch(`/${this.controllerName}/GetByDocumento?tipoPessoa=${tipoPessoa}&documento=${documento}`, {
            credentials: "same-origin"
        });
    }
}