var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "crud crud-edit" },
    [
      _c("actionBarComponent", {
        attrs: {
          title: _vm.$t(
            "__.Crud.configuracaousuario.edit_vue_html.configUsuario"
          ),
        },
        on: { onSave: _vm.save },
      }),
      _vm._v(" "),
      _c(
        "form",
        {
          attrs: { novalidate: "novalidate" },
          on: {
            submit: function ($event) {
              $event.preventDefault()
              _vm.save()
            },
          },
        },
        [
          _vm.isAdmin
            ? _c("div", [
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-sm-6" },
                    [
                      _c("label", { staticClass: "control-label" }, [
                        _vm._v(
                          "\n                        " +
                            _vm._s(
                              _vm.$t(
                                "__.Crud.configuracaousuario.edit_vue_html.usuario"
                              )
                            ) +
                            "\n                    "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("combo-component", {
                        attrs: {
                          id: "usuarioId",
                          name: "usuarioId",
                          data: _vm.usuarioOptions,
                          searchable: true,
                        },
                        on: { select: _vm.onChangeUsuario },
                        model: {
                          value: _vm.usuarioId,
                          callback: function ($$v) {
                            _vm.usuarioId = $$v
                          },
                          expression: "usuarioId",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _vm.itens && _vm.itens.length > 0
            ? _vm._l(
                _vm.itens
                  .map(function (p) {
                    return p.modulo
                  })
                  .filter(function (value, index, self) {
                    return self.indexOf(value) === index
                  }),
                function (modulo) {
                  return _c(
                    "fieldset-component",
                    {
                      key: modulo,
                      attrs: {
                        title: _vm.getModuloName(modulo),
                        collapsed: false,
                      },
                    },
                    [
                      _c("div", { attrs: { slot: "rows" }, slot: "rows" }, [
                        _c(
                          "div",
                          { staticClass: "col-sm-12" },
                          _vm._l(
                            _vm.itens.filter(function (p) {
                              return p.modulo == modulo
                            }),
                            function (item, indexRow) {
                              return _c(
                                "div",
                                {
                                  key: indexRow,
                                  class: [(indexRow + 1) % 2 == 0 ? "row" : ""],
                                  staticStyle: {
                                    "margin-right": "auto !important",
                                    "margin-left": "auto !important",
                                  },
                                },
                                [
                                  _c("div", { staticClass: "col-sm-6" }, [
                                    _c(
                                      "div",
                                      {
                                        directives: [
                                          {
                                            name: "tooltip",
                                            rawName: "v-tooltip",
                                            value: _vm.errors.first(
                                              item.descricao
                                            ),
                                            expression:
                                              "errors.first(item.descricao)",
                                          },
                                        ],
                                        staticClass: "form-group",
                                      },
                                      [
                                        _c(
                                          "label",
                                          {
                                            staticClass: "control-label",
                                            attrs: {
                                              title: _vm.$t("__.obrigatorio"),
                                            },
                                          },
                                          [
                                            _c(
                                              "span",
                                              { staticStyle: { color: "red" } },
                                              [_vm._v("*")]
                                            ),
                                            _vm._v(
                                              "\n                                        " +
                                                _vm._s(item.descricao) +
                                                "\n                                    "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        item.tipoCampo == _vm.tipoLong
                                          ? _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: item.valorLong,
                                                  expression: "item.valorLong",
                                                },
                                                {
                                                  name: "validate",
                                                  rawName: "v-validate",
                                                  value: "required",
                                                  expression: "'required'",
                                                },
                                              ],
                                              staticClass: "form-control",
                                              attrs: {
                                                type: "number",
                                                id: item.codigo.toString(),
                                                name: item.descricao,
                                                autocomplete: "off",
                                              },
                                              domProps: {
                                                value: item.valorLong,
                                              },
                                              on: {
                                                input: function ($event) {
                                                  if ($event.target.composing) {
                                                    return
                                                  }
                                                  _vm.$set(
                                                    item,
                                                    "valorLong",
                                                    $event.target.value
                                                  )
                                                },
                                              },
                                            })
                                          : _vm._e(),
                                        _vm._v(" "),
                                        item.tipoCampo == _vm.tipoString
                                          ? _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: item.valor,
                                                  expression: "item.valor",
                                                },
                                              ],
                                              staticClass: "form-control",
                                              attrs: {
                                                type: "text",
                                                id: item.codigo.toString(),
                                                name: item.descricao,
                                                autocomplete: "off",
                                              },
                                              domProps: { value: item.valor },
                                              on: {
                                                input: function ($event) {
                                                  if ($event.target.composing) {
                                                    return
                                                  }
                                                  _vm.$set(
                                                    item,
                                                    "valor",
                                                    $event.target.value
                                                  )
                                                },
                                              },
                                            })
                                          : _vm._e(),
                                        _vm._v(" "),
                                        item.tipoCampo == _vm.tipoBoolean
                                          ? _c("combo-component", {
                                              attrs: {
                                                id: item.codigo.toString(),
                                                name: item.descricao,
                                                data: _vm.verdadeiroOptions,
                                                searchable: false,
                                              },
                                              model: {
                                                value: item.verdadeiroNum,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    item,
                                                    "verdadeiroNum",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "item.verdadeiroNum",
                                              },
                                            })
                                          : _vm._e(),
                                        _vm._v(" "),
                                        item.tipoCampo == _vm.tipoDecimal
                                          ? _c("decimal-component", {
                                              staticClass: "form-control",
                                              attrs: {
                                                id: item.codigo.toString(),
                                                name: item.descricao,
                                              },
                                              model: {
                                                value: item.valorDecimal,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    item,
                                                    "valorDecimal",
                                                    $$v
                                                  )
                                                },
                                                expression: "item.valorDecimal",
                                              },
                                            })
                                          : _vm._e(),
                                        _vm._v(" "),
                                        item.tipoCampo == _vm.tipoRotulo &&
                                        item.codigo == 7
                                          ? _c("combo-component", {
                                              attrs: {
                                                id: item.codigo.toString(),
                                                name: item.descricao,
                                                data: _vm.rotulosLoteOptions,
                                                searchable: false,
                                              },
                                              model: {
                                                value: item.rotuloId,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    item,
                                                    "rotuloId",
                                                    $$v
                                                  )
                                                },
                                                expression: "item.rotuloId",
                                              },
                                            })
                                          : _vm._e(),
                                        _vm._v(" "),
                                        item.tipoCampo == _vm.tipoRotulo &&
                                        item.codigo == 8
                                          ? _c("combo-component", {
                                              attrs: {
                                                id: item.codigo.toString(),
                                                name: item.descricao,
                                                data: _vm.rotulosManipulacaoOptions,
                                                searchable: false,
                                              },
                                              model: {
                                                value: item.rotuloId,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    item,
                                                    "rotuloId",
                                                    $$v
                                                  )
                                                },
                                                expression: "item.rotuloId",
                                              },
                                            })
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ]),
                                ]
                              )
                            }
                          )
                        ),
                      ]),
                    ]
                  )
                }
              )
            : _vm._e(),
          _vm._v(" "),
          _c("fieldset", [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-sm-12 newbtn" }, [
                _c("div", { staticClass: "form-group" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-success",
                      attrs: { type: "submit" },
                    },
                    [
                      _c("i", {
                        staticClass: "fa fa-save",
                        attrs: { "aria-hidden": "true" },
                      }),
                      _vm._v(
                        "\n                            " +
                          _vm._s(_vm.$t("__.Crud.salvar")) +
                          "\n                        "
                      ),
                    ]
                  ),
                ]),
              ]),
            ]),
          ]),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }