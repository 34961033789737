import Vue from "vue";
import { mapGetters, mapMutations } from "vuex";

import actionBarComponent from "@/components/child/actionBar/actionBar.vue";
import checkboxComponent from "@/components/child/form/checkbox.vue";
import dataTooltipComponent from "@/components/child/form/datatooltip.vue";
import decimalComponent from "@/components/child/form/decimal.vue";
import gridComponent from "@/components/child/grid/grid.vue";
import { GridAction } from "@/components/child/grid/gridAction";
import { GridColors, GridColumn, GridColumnType } from "@/components/child/grid/gridColumn";
import { Component } from "@/decorators";
import CapsulaModel from "@/models/capsulaModel";
import TiposCapsula from "@/models/enum/tiposCapsula";
import ProdutoModel from "@/models/produto/produtoModel";
import CapsulaService from "@/services/capsulaService";
import { Getters, LoadListProps, Mutations } from "@/store/store";
import { changePosition } from "@/utils/common/array";

import "../crud.scss";

type ProdutoCor = {
    id: number;
    descricao: string;
    cor: string;
    ordem: number;
};

@Component({
    components: {
        actionBarComponent,
        checkboxComponent,
        dataTooltipComponent,
        decimalComponent,
        gridComponent,
    },
    computed: {
        ...mapGetters(["HAS_PERMISSAO_PERFIL", "VALIDAR_PERMISSAO_SOMENTE_CONSULTA"] as Getters),
    },
    methods: mapMutations(["LOAD_LIST"] as Mutations),
})
export default class CapsulaEditComponent extends Vue {
    // State computed props
    LOAD_LIST: (props: LoadListProps) => void;
    HAS_PERMISSAO_PERFIL: (isRede: boolean, isFranqueador: boolean, isSuporte: boolean) => Promise<boolean>;
    VALIDAR_PERMISSAO_SOMENTE_CONSULTA: (telaDescricao: string) => boolean;

    private service = new CapsulaService();

    private isAdmin = false;
    private podeEditar = true;
    private itemCompartilhado = false;
    somenteConsulta = false;

    model = new CapsulaModel();
    modelId = 0;

    produtosCores: ProdutoCor[] = [];
    produtosCoresGastrorresistente: ProdutoCor[] = [];
    produtosCoresVeganas: ProdutoCor[] = [];
    produtosCoresVeganasRevestidas: ProdutoCor[] = [];
    produtos: ProdutoModel[] = [];

    extraActions: Array<object> = [];
    extraActionsUp: Array<GridAction> = [];
    extraActionsDown: Array<GridAction> = [];

    gridColumns: Array<GridColumn> = [
        new GridColumn("descricao", "Produto", GridColumnType.String),
        new GridColumn("cor", "Cor", GridColumnType.String),
        new GridColumn("ordem", "Prioridade", GridColumnType.String),
    ];

    private async load() {
        this.model = new CapsulaModel();
        this.model.ativo = true;

        if (this.modelId > 0) {
            try {
                const data = await this.service.get(this.modelId).withLoading().resolveWithJSON<CapsulaModel>();
                this.model.updateFrom(data);

                this.itemCompartilhado = this.model.franquiaId == null && this.model.id !== null;
                this.podeEditar = (this.itemCompartilhado && this.isAdmin) || !this.itemCompartilhado;

                this.produtos = await this.service.produtosCores(this.modelId).resolveWithJSON<ProdutoModel[]>();

                this.produtosCores = this.produtos
                    .filter(p => p.tipoCapsula == TiposCapsula.Normal)
                    .sort((a, b) => (a.ordemCapsula > b.ordemCapsula ? 1 : -1))
                    .map((p, i) => ({
                        id: p.id,
                        descricao: p.descricao,
                        cor: p.cor != null ? p.cor.descricao : "",
                        ordem: i + 1,
                    }));

                this.produtosCoresGastrorresistente = this.produtos
                    .filter(p => p.tipoCapsula == TiposCapsula.Revestida)
                    .sort((a, b) => (a.ordemCapsula > b.ordemCapsula ? 1 : -1))
                    .map((p, i) => ({
                        id: p.id,
                        descricao: p.descricao,
                        cor: p.cor != null ? p.cor.descricao : "",
                        ordem: i + 1,
                    }));

                this.produtosCoresVeganas = this.produtos
                    .filter(p => p.tipoCapsula == TiposCapsula.Vegana)
                    .sort((a, b) => (a.ordemCapsula > b.ordemCapsula ? 1 : -1))
                    .map((p, i) => ({
                        id: p.id,
                        descricao: p.descricao,
                        cor: p.cor != null ? p.cor.descricao : "",
                        ordem: i + 1,
                    }));

                this.produtosCoresVeganasRevestidas = this.produtos
                    .filter(p => p.tipoCapsula == TiposCapsula.VeganaRevestida)
                    .sort((a, b) => (a.ordemCapsula > b.ordemCapsula ? 1 : -1))
                    .map((p, i) => ({
                        id: p.id,
                        descricao: p.descricao,
                        cor: p.cor != null ? p.cor.descricao : "",
                        ordem: i + 1,
                    }));

                this.loadGrid();
            } catch {
                this.$router.back();
            }
        }
    }

    private async save() {
        const produtosOrdem = this.produtos.map(p => {
            let produto = new ProdutoModel();
            produto = p;
            let ordemCapsula = null;

            switch (p.tipoCapsula) {
                case TiposCapsula.Normal:
                    ordemCapsula = this.produtosCores.find(c => c.id === p.id)?.ordem;
                    break;
                case TiposCapsula.Revestida:
                    ordemCapsula = this.produtosCoresGastrorresistente.find(c => c.id === p.id)?.ordem;
                    break;
                case TiposCapsula.Vegana:
                    ordemCapsula = this.produtosCoresVeganas.find(c => c.id === p.id)?.ordem;
                    break;
                case TiposCapsula.VeganaRevestida:
                    ordemCapsula = this.produtosCoresVeganasRevestidas.find(c => c.id === p.id)?.ordem;
                    break;
                default:
                    ordemCapsula = null;
                    break;
            }

            produto.ordemCapsula = ordemCapsula;
            return produto;
        });

        if (this.itemCompartilhado && !this.isAdmin) {
            try {
                const sucesso = await this.service.salvarOrdem(produtosOrdem).withLoading().resolveWithoutJSON();
                if (sucesso) {
                    await this.$showInclusaoUpdate(this.modelId);
                    this.$router.back();
                }
            } catch {}
        } else {
            try {
                const isValid = await this.$validator.validateAll();
                if (isValid && this.podeEditar) {
                    try {
                        const sucesso = await this.service[!this.model.id ? "insert" : "update"](this.model)
                            .withLoading()
                            .resolveWithoutJSON();

                        if (sucesso) {
                            const sucesso = await this.service
                                .salvarOrdem(produtosOrdem)
                                .withLoading()
                                .resolveWithoutJSON();

                            if (sucesso) {
                                this.LOAD_LIST({ loadCapsulas: true });

                                await this.$showInclusaoUpdate(this.modelId);
                                this.$router.back();
                            }
                        }
                    } catch {}
                }
            } catch {}
        }
    }

    private cancel() {
        this.$router.back();
    }

    private loadGrid() {
        this.produtosCores = this.produtosCores.map((p, i) => ({ ...p, ordem: i + 1 }));
        this.produtosCoresGastrorresistente = this.produtosCoresGastrorresistente.map((p, i) => ({
            ...p,
            ordem: i + 1,
        }));
        this.produtosCoresVeganas = this.produtosCoresVeganas.map((p, i) => ({ ...p, ordem: i + 1 }));
        this.produtosCoresVeganasRevestidas = this.produtosCoresVeganasRevestidas.map((p, i) => ({
            ...p,
            ordem: i + 1,
        }));

        this.addGridExtraActions();
    }

    private onExtraActions(name: string, campo: ProdutoCor) {
        if (name.trim() == "up") {
            this.produtosCores = changePosition(this.produtosCores, campo, "UP");
        }

        if (name.trim() == "down") {
            this.produtosCores = changePosition(this.produtosCores, campo, "DOWN");
        }

        this.loadGrid();
    }

    private onExtraActionsGastrorresistente(name: string, campo: ProdutoCor) {
        if (name.trim() == "up") {
            this.produtosCoresGastrorresistente = changePosition(this.produtosCoresGastrorresistente, campo, "UP");
        }

        if (name.trim() == "down") {
            this.produtosCoresGastrorresistente = changePosition(this.produtosCoresGastrorresistente, campo, "DOWN");
        }

        this.loadGrid();
    }

    private onExtraActionsVeganas(name: string, campo: ProdutoCor) {
        if (name.trim() == "up") {
            this.produtosCoresVeganas = changePosition(this.produtosCoresVeganas, campo, "UP");
        }

        if (name.trim() == "down") {
            this.produtosCoresVeganas = changePosition(this.produtosCoresVeganas, campo, "DOWN");
        }

        this.loadGrid();
    }

    private onExtraActionsVeganasRevestidas(name: string, campo: ProdutoCor) {
        if (name.trim() == "up") {
            this.produtosCoresVeganasRevestidas = changePosition(this.produtosCoresVeganasRevestidas, campo, "UP");
        }

        if (name.trim() == "down") {
            this.produtosCoresVeganasRevestidas = changePosition(this.produtosCoresVeganasRevestidas, campo, "DOWN");
        }

        this.loadGrid();
    }

    private addGridExtraActions() {
        this.extraActionsUp.splice(0);
        this.extraActionsDown.splice(0);
        this.extraActions.splice(0);

        for (let i = 0; i < this.produtosCores.length; i++) {
            this.extraActionsUp.push(
                new GridAction("up", "Colocar para cima", "fa fa-arrow-up", GridColors.DARKGRAY, true),
            );
            this.extraActionsDown.push(
                new GridAction("down", "Colocar para baixo", "fa fa-arrow-down", GridColors.DARKGRAY, true),
            );
        }

        this.extraActions.push(this.extraActionsUp);
        this.extraActions.push(this.extraActionsDown);
    }

    private async mounted() {
        if (this.$route.params.id) {
            this.modelId = +this.$route.params.id;
        }

        this.isAdmin = await this.HAS_PERMISSAO_PERFIL(false, false, false);

        this.somenteConsulta = this.VALIDAR_PERMISSAO_SOMENTE_CONSULTA("capsulas");

        this.load();
    }
}
