import fieldsetComponent from "@/components/child/form/fieldset.vue";
import moedaComponent from "@/components/child/form/moeda.vue";
import gridComponent from "@/components/child/grid/grid.vue";
import { GridAction } from "@/components/child/grid/gridAction";
import { GridColors, GridColumn, GridColumnType } from "@/components/child/grid/gridColumn";
import { Component } from "@/decorators";
import PaginationModel from "@/models/paginationModel";
import PagSeguroModel from "@/models/pagSeguroModel";
import PagSeguroService from "@/services/pagSeguroService";

import ListComponentBase from "../crud/listComponentBase";
import shortcutComponent from "../shortcut/shortcut.vue";

import "../crud/crud.scss";

@Component({
    components: {
        gridComponent,
        fieldsetComponent,
        shortcutComponent,
        moedaComponent,
    },
})
export default class MovimentacoesPendentesListComponent extends ListComponentBase<PagSeguroModel> {
    private pagSeguroService = new PagSeguroService();

    extraActions: Array<object> = [];
    private extraActionsReprocessar: Array<GridAction> = [];
    gridSelectedValues: Array<PagSeguroModel> = [];

    get gridColumns(): Array<GridColumn> {
        return [
            new GridColumn("codigoVenda", "Cód. Venda", GridColumnType.Integer, false, false, "", "", false, false),
            new GridColumn("dataCriacao", "Data Criação", GridColumnType.Date, false, false, "", "", false, true),
            new GridColumn("erro", "Erro", GridColumnType.String, false, false, "", "", false, true),
        ];
    }

    public async loadMethod() {
        try {
            return await this.pagSeguroService
                .getList(
                    this.gridFilterKey,
                    this.gridSortKey,
                    this.gridSortOrder,
                    this.pags ? 1 : this.pageIndex,
                    this.pags ? 999999 : this.pageSize,
                )
                .withLoading()
                .resolveWithJSON<PaginationModel<PagSeguroModel>>();
        } catch {}
    }

    public async afterLoad() {
        this.extraActions.splice(0);
        this.extraActionsReprocessar.splice(0);

        for (let i = 0; i < this.gridData.length; i++) {
            this.extraActionsReprocessar.push(
                new GridAction("reprocessar", "Reprocessar", "fa fa-redo", GridColors.BLUE, true),
            );
        }

        this.extraActions.push(this.extraActionsReprocessar);
    }

    private onSelectedValuesChanged(values: Array<PagSeguroModel>) {
        this.gridSelectedValues = values;
    }

    private async onReprocessar() {
        const sucesso = await this.pagSeguroService
            .reprocessar(this.gridSelectedValues)
            .withLoading()
            .resolveWithoutJSON();

        if (sucesso) {
            await this.$showSuccess(this.$t("__.ts.sucesso"), this.$t("__.ts.pagamentoReprocessado"));

            this.load();
        }
    }

    private async onExtraActions(name: string, model: PagSeguroModel) {
        if (name.trim() == "reprocessar") {
            const sucesso = await this.pagSeguroService.reprocessar([model]).withLoading().resolveWithoutJSON();
            if (sucesso) {
                await this.$showSuccess(this.$t("__.ts.sucesso"), this.$t("__.ts.pagamentoReprocessado"));

                this.load();
            }
        }
    }
}
