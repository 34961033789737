import Vue from "vue";

import { Component } from "@/decorators";
import Bus from "@/utils/events/bus";

import fastActionComponent from "./fastaction/fastaction.vue";

import "./home.scss";

@Component({
    components: {
        fastActionComponent,
    },
})
export default class HomeComponent extends Vue {
    private beforeMount() {
        Bus.$emit("hide-nav-menu", false);
    }
}
