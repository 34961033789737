import BaseModel from "./base/baseModel";
import ProdutoModel from "./produto/produtoModel";
import ProdutoLoteModel from "./produtoLoteModel";

export default class ItemEmbalagemFracionamentoModel extends BaseModel {
    constructor() {
        super();
    }

    id?: number = null;
    fracionamentoId?: number = null;
    kitEmbalagemId?: number = null;
    quantidade = 1;
    produtoId?: number = null;
    produto?: ProdutoModel = null;
    valorUnidade?: number = null;
    valorTotal?: number = null;
    produtoLoteId?: number = null;
    produtoLote?: ProdutoLoteModel = null;
    produtoLotePadraoId?: number = null;
    markupItemEmbalagem?: number = null;

    // Dados Separação
    produtoDescricao: string;
    unidadeMedidaDescricao: string;
    produtoLoteDescricao: string;
}
