var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("input", {
    directives: [
      {
        name: "mask-decimal",
        rawName: "v-mask-decimal",
        value: _vm.decimais,
        expression: "decimais",
      },
    ],
    attrs: {
      autocomplete: "off",
      type: "text",
      id: _vm.id,
      name: _vm.name,
      suffix: _vm.signal,
    },
    domProps: { value: _vm.value },
    on: {
      blur: function ($event) {
        _vm.onBlur($event.target.value)
      },
      focus: function ($event) {
        _vm.getFocus($event.target)
      },
      change: function ($event) {
        _vm.onChange($event.target.value)
      },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }