import Vue from "vue";

import comboComponent from "@/components/child/form/combo.vue";
import dateTimePickerComponent from "@/components/child/form/datetimepicker.vue";
import FieldsetComponent from "@/components/child/form/fieldset";
import fieldsetComponent from "@/components/child/form/fieldset.vue";
import moedaComponent from "@/components/child/form/moeda.vue";
import textareaComponent from "@/components/child/form/textarea.vue";
import LoadingModalComponent from "@/components/child/loadingmodal/loadingmodal";
import loadingModalComponent from "@/components/child/loadingmodal/loadingmodal.vue";
import { Component, Watch } from "@/decorators";
import ContaCorrenteModel from "@/models/financeiro/contaCorrenteModel";
import ContaPagarPagamentoModel from "@/models/financeiro/contaPagarPagamentoModel";
import FormaPagamentoModel from "@/models/financeiro/formaPagamentoModel";
import PaginationModel from "@/models/paginationModel";
import ContaCorrenteService from "@/services/financeiro/contaCorrenteService";
import FormaPagamentoService from "@/services/financeiro/formaPagamentoService";
import MovimentacaoCaixaService from "@/services/financeiro/movimentacaoCaixaService";
import ValidationErrorWrapper from "@/wrappers/validationErrorWrapper";

import "./pagamentoconta.scss";

@Component({
    components: {
        textareaComponent,
        comboComponent,
        fieldsetComponent,
        moedaComponent,
        dateTimePickerComponent,
        loadingModalComponent,
    },
})
export default class PagamentoContaComponent extends Vue {
    private fieldsetComponent: FieldsetComponent = null;
    private validationErrorWrapper = new ValidationErrorWrapper(this.$validator);
    private loadingModalComponent: LoadingModalComponent = null;

    model: ContaPagarPagamentoModel = new ContaPagarPagamentoModel();
    jurosValorAnterior = 0;
    descontoValorAnterior = 0;
    idCaixaSugestao = 0;

    contaCorrenteOptions: Array<Object> = [];
    formaPagamentoOptions: Array<Object> = [];

    constructor() {
        super();

        this.model.dataMovimento = new Date();
    }

    public clear(saldoPagar) {
        this.model = new ContaPagarPagamentoModel();
        this.$set(this.model, "dataMovimento", new Date());
        this.$set(this.model, "juros", 0);
        this.$set(this.model, "desconto", 0);
        this.$set(this.model, "valor", saldoPagar);

        if (this.idCaixaSugestao > 0) this.model.contaCorrenteId = this.idCaixaSugestao;

        this.jurosValorAnterior = 0;
        this.descontoValorAnterior = 0;

        this.validationErrorWrapper.clearErrors();
    }

    public enable(enable: boolean) {
        if (enable) this.fieldsetComponent.$el.removeAttribute("disabled");
        else this.fieldsetComponent.$el.setAttribute("disabled", "disabled");
    }

    private async loadContasCorrentes() {
        try {
            const data = await new ContaCorrenteService()
                .combo()
                .then(r => r.json() as Promise<PaginationModel<ContaCorrenteModel>>);
            this.contaCorrenteOptions = data.list.map(item => {
                return { value: item.id, text: item.descricao };
            });
        } catch {}
    }

    private async loadFormasPagamento() {
        try {
            const data = await new FormaPagamentoService()
                .combo()
                .then(r => r.json() as Promise<PaginationModel<FormaPagamentoModel>>);
            this.formaPagamentoOptions = data.list.map(item => {
                return { value: item.id, text: item.descricao };
            });
        } catch {}
    }

    private incluirPagamento() {
        this.$validator
            .validateAll()
            .then(isValid => {
                if (isValid) this.$emit("inclusao-pagamento", this.getModel());
            })
            .catch(error => {});
    }

    private salvarValorAnteriorJuros() {
        this.jurosValorAnterior = +this.model.juros;
    }

    private salvarValorAnteriorDesconto() {
        this.descontoValorAnterior = +this.model.desconto;
    }

    private getModel() {
        const contaCorrenteElement = this.$el.querySelector("#contaCorrenteId") as HTMLSelectElement;
        const formaPagamentoElement = this.$el.querySelector("#formaPagamentoId") as HTMLSelectElement;

        const model = new ContaPagarPagamentoModel();
        model.updateFrom(this.model);
        model.lancamentoId = this.model.id;
        model.dataLancamento = new Date();
        model.contaCorrenteDescricao = contaCorrenteElement.innerText;
        model.formaPagamentoDescricao = formaPagamentoElement.innerText;

        return model;
    }
    //@ts-ignore
    @Watch("model.juros")
    private onJurosChanged(newValue) {
        let valorPago = +this.model.valor;
        valorPago -= this.jurosValorAnterior;
        valorPago += +newValue;

        this.$set(this.model, "valor", valorPago);
    }
    //@ts-ignore
    @Watch("model.desconto")
    private onDescontoChanged(newValue) {
        let valorPago = +this.model.valor;
        valorPago += this.descontoValorAnterior;
        valorPago -= +newValue;

        this.$set(this.model, "valor", valorPago);
    }

    private async loadIdCaixaSegestao() {
        try {
            const data = await new MovimentacaoCaixaService()
                .getIdCaixaSessaoAberta()
                .then(r => r.json() as Promise<number>);
            this.idCaixaSugestao = data;
        } catch {
            this.idCaixaSugestao = 0;
        }
    }

    private mounted() {
        this.fieldsetComponent = this.$refs.fieldsetComponent as FieldsetComponent;
        this.loadingModalComponent = this.$refs.loadingModalComponent as LoadingModalComponent;
        this.loadingModalComponent.showLoading();

        Promise.all([this.loadIdCaixaSegestao(), this.loadContasCorrentes(), this.loadFormasPagamento()])
            .then(result => {
                this.loadingModalComponent.hide();
            })
            .catch(error => {
                this.loadingModalComponent.hide();
            });
    }
}
