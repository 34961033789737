import Vue from "vue";

import gridComponent from "@/components/child/grid/grid.vue";
import { GridColumn, GridColumnType } from "@/components/child/grid/gridColumn";
import ShortcutComponent from "@/components/parent/shortcut/shortcut";
import shortcutComponent from "@/components/parent/shortcut/shortcut.vue";
import { Component } from "@/decorators";
import EnsaioFichaTecnicaModel from "@/models/ensaioFichaTecnicaModel";
import FichaTecnicaModel from "@/models/fichaTecnicaModel";
import PaginationModel from "@/models/paginationModel";
import ProdutoLoteModel from "@/models/produtoLoteModel";
import ProdutoLoteService from "@/services/produtoLoteService";

import ResultadosComponent from "./resultados";
import resultadosComponent from "./resultados.vue";

@Component({
    components: {
        gridComponent,
        shortcutComponent,
        resultadosComponent,
    },
})
export default class GridResultadosComponent extends Vue {
    private shortcutComponent: ShortcutComponent = null;
    private resultadosComponent: ResultadosComponent = null;
    private model: FichaTecnicaModel = new FichaTecnicaModel();
    private gridData: Array<ProdutoLoteModel> = [];

    private get gridColumn(): Array<GridColumn> {
        return [
            new GridColumn("descricao", "Descrição", GridColumnType.String),
            new GridColumn("fornecedorNome", "Fornecedor", GridColumnType.String),
            new GridColumn("fatorCorrecao", "F.C", GridColumnType.Decimal),
            new GridColumn("valorCusto", "Custo Unitário", GridColumnType.Money4Digits),
            new GridColumn("dataValidade", "Validade", GridColumnType.Date),
            new GridColumn("dataFabricacao", "Fabricação", GridColumnType.Date),
            new GridColumn("quantidadeAtual", "Quantidade", GridColumnType.Decimal),
        ];
    }

    public async load(model: FichaTecnicaModel) {
        this.model = model;
        this.shortcutComponent = this.$refs.shortcutComponent as ShortcutComponent;
        this.shortcutComponent.title = this.$t("__.ts.selecLoteAddResult") as string;

        const loteIds = model.ensaioFichaTecnica
            .map(ensaio => ensaio.ensaioFichaTecnicaResultados ?? [])
            .reduce((accumulator, currentValue) => accumulator.concat(currentValue), [])
            .map(resultado => resultado.produtoLoteId);

        const data = await new ProdutoLoteService()
            .getByProdutoId(model.produtoId)
            .resolveWithJSON<PaginationModel<ProdutoLoteModel>>();

        this.gridData = data.list.filter(x => !loteIds.includes(x.id));

        if (this.gridData.length > 0) {
            this.shortcutComponent.show();
        } else {
            await this.$showWarning(this.$t("__.ts.aviso"), this.$t("__.ts.nenhumLoteDisp"));
            this.shortcutComponent.hide();
        }
    }

    private async onSelectLote(value: ProdutoLoteModel) {
        this.resultadosComponent = this.$refs.resultadosComponent as ResultadosComponent;
        await this.resultadosComponent.load(this.model.ensaioFichaTecnica, value.id);
        this.shortcutComponent.hide();
    }

    private async onEmit(ensaios: EnsaioFichaTecnicaModel[], loteSelecionado: number) {
        this.$emit("ensaios-resultados", ensaios, loteSelecionado);
    }
}
