import Vue from "vue";
import { mapGetters, mapMutations, mapState } from "vuex";

import actionBarComponent from "@/components/child/actionBar/actionBar.vue";
import buttonIncluirComponent from "@/components/child/form/button/buttonIncluir.vue";
import checkboxComponent from "@/components/child/form/checkbox.vue";
import comboComponent from "@/components/child/form/combo.vue";
import dataTooltipComponent from "@/components/child/form/datatooltip.vue";
import decimalComponent from "@/components/child/form/decimal.vue";
import fieldsetComponent from "@/components/child/form/fieldset.vue";
import textareaComponent from "@/components/child/form/textarea.vue";
import gridComponent from "@/components/child/grid/grid.vue";
import { GridColumn, GridColumnType } from "@/components/child/grid/gridColumn";
import { Component, Watch } from "@/decorators";
import PreLoadPackModel from "@/models/auxiliar/preLoadPackModel";
import ConfiguracaoRotuloModel from "@/models/configuracaoRotuloModel";
import OrigemRotulo from "@/models/enum/rotulo/origemRotulo";
import TiposProduto from "@/models/enum/tiposProduto";
import FormaFarmaceuticaModel from "@/models/formaFarmaceuticaModel";
import FranquiaModel from "@/models/franquiaModel";
import KitEmbalagemCapsulaModel from "@/models/kitEmbalagemCapsulaModel";
import KitEmbalagemItemModel from "@/models/kitEmbalagemItemModel";
import KitEmbalagemModel from "@/models/kitEmbalagemModel";
import PaginationModel from "@/models/paginationModel";
import ConfiguracaoRotuloService from "@/services/configuracaoRotuloService";
import FranquiaService from "@/services/franquiaService";
import KitEmbalagemService from "@/services/kitEmbalagemService";
import { AppState, Getters, Mutations } from "@/store/store";
import { getFormaFarmaceuticaCombo, getProdutoComboText, getUnidadeMedidaCombo } from "@/utils/common/combo/combotext";
import Delay from "@/utils/common/delay";

import ReplicarSelecaoFranquiaComponent from "../replicarSelecaoFranquia/edit";
import replicarSelecaoFranquiaComponent from "../replicarSelecaoFranquia/edit.vue";

import "../crud.scss";
import "./edit.scss";

@Component({
    components: {
        textareaComponent,
        checkboxComponent,
        comboComponent,
        dataTooltipComponent,
        decimalComponent,
        fieldsetComponent,
        gridComponent,
        replicarSelecaoFranquiaComponent,
        buttonIncluirComponent,
        actionBarComponent,
    },
    computed: {
        ...mapState<AppState>({
            preLoadPack: state => state.preLoad.preLoadList,
            loadedList: state => state.preLoad.loadedList,
        }),
        ...mapGetters(["HAS_PERMISSAO_PERFIL"] as Getters),
    },
    methods: mapMutations(["LOAD_LIST"] as Mutations),
})
export default class KitEmbalagemEditComponent extends Vue {
    // State computed props
    preLoadPack: PreLoadPackModel;
    loadedList: boolean;
    HAS_PERMISSAO_PERFIL: (isRede: boolean, isFranqueador: boolean, isSuporte: boolean) => Promise<boolean>;

    private service = new KitEmbalagemService();

    model = new KitEmbalagemModel();
    modelId = 0;

    produtoComboId = 0;
    subGrupo: number = null;
    quantidade = 0;
    produtoPadrao = true;

    private replicarSelecaoFranquiaComponent: ReplicarSelecaoFranquiaComponent = null;
    private isAdmin = false;
    private isAdminFranqueador = false;
    private franquiaId: number = null;

    private formaPriorizar = false;
    franquiaOptions: Array<Object> = [];

    formasFarmaceuticaOptions: Array<Object> = [];
    produtosOptions: Array<Object> = [];
    unidadesMedidaOptions: Array<Object> = [];
    tipoProdutoId: number = null;
    tipoProdutoOptions: Array<Object> = [
        { text: "Matéria-Prima", value: 0 },
        { text: "Semi-Acabado", value: 1 },
        { text: "Acabado", value: 2 },
        { text: "Embalagem", value: 3 },
        { text: "Cápsula", value: 4 },
        { text: "Floral", value: 6 },
    ];

    subGrupoOptions: Array<Object> = [
        { text: "Ativo", value: 0 },
        { text: "Flavorizante", value: 1 },
        { text: " Veículos/Excipientes", value: 2 },
        { text: "Recipiente", value: 3 },
        { text: "Tampa", value: 4 },
        { text: "Aplicador", value: 5 },
        { text: "Outros(Embalagens)", value: 6 },
    ];

    rotulosManipulacaoOptions: Array<Object> = [];

    capsulaComboId = 0;
    capsulasOptions: Array<Object> = [];
    quantidadeCapsula = 0;

    constructor() {
        super();

        this.model.ativo = true;
    }

    gridColumnsFormaFarmaceutica: Array<GridColumn> = [
        new GridColumn("descricao", "Descri\u00e7\u00e3o", GridColumnType.String),
        new GridColumn("priorizar", "Priorizar", GridColumnType.Boolean),
    ];

    gridColumnsCapacidadeCapsula: Array<GridColumn> = [
        new GridColumn("capsulaDescricao", "Descri\u00e7\u00e3o", GridColumnType.String),
        new GridColumn("quantidade", "Qtde", GridColumnType.Integer),
    ];

    gridColumnsProduto: Array<GridColumn> = [
        new GridColumn("produtoDescricao", "Descri\u00e7\u00e3o", GridColumnType.String),
        new GridColumn("quantidade", "Qtde", GridColumnType.Integer),
        new GridColumn("produtoPadrao", "Padrão", GridColumnType.Boolean),
    ];

    private async load() {
        this.model = new KitEmbalagemModel();

        this.loadRotulosManipulacao();

        if (this.modelId > 0) {
            try {
                const data = await this.service.get(this.modelId).withLoading().resolveWithJSON<KitEmbalagemModel>();

                this.model.updateFrom(data);
                this.franquiaId = data.franquiaId;
            } catch {
                this.$router.back();
            }
        }
    }

    private async loadRotulosManipulacao() {
        try {
            const data = await new ConfiguracaoRotuloService()
                .comboByFranquiaId(this.franquiaId)
                .withLoading()
                .resolveWithJSON<PaginationModel<ConfiguracaoRotuloModel>>();

            this.rotulosManipulacaoOptions = data.list
                .filter(
                    item =>
                        item.origemDados == OrigemRotulo.Manipulacao ||
                        item.origemDados == OrigemRotulo.Acabado ||
                        item.origemDados == OrigemRotulo.Strips,
                )
                .map(item => ({ value: item.id, text: item.nome }));
        } catch {}
    }

    private async save() {
        try {
            const isValid = await this.$validator.validateAll();
            if (isValid) {
                if (this.model.formasFarmaceuticas.length <= 0) {
                    return this.$showError(this.$t("__.ts.erro"), this.$t("__.ts.erroFormaFarmaceutica"));
                }
                if (this.model.kitEmbalagemItens.length <= 0) {
                    return this.$showError(this.$t("__.ts.erro"), this.$t("__.ts.erroProduto"));
                }

                const sucesso = await this.service[!this.model.id ? "insert" : "update"](this.model)
                    .withLoading()
                    .resolveWithoutJSON();

                if (sucesso) {
                    this["LOAD_LIST"]({ loadKitEmbalagem: true });
                    await this.$showInclusaoUpdate(this.model.id);

                    if (this.preLoadPack.currentFranquiaId != this.model.franquiaId && this.model.franquiaId != null) {
                        this.load();
                    } else {
                        this.$router.back();
                    }
                }
            } else {
                this.$focusErrorField();
            }
        } catch {}
    }

    private cancel() {
        this.$router.back();
    }

    private onInclusaoFormaFarmaceutica() {
        const formaFarmaceuticaComponent = this.$el.querySelector("#formaFarmaceuticaId") as HTMLSelectElement;

        if (this.model.formaFarmaceuticaId > 0) {
            const alreadyExists = this.model.formasFarmaceuticas.some(p => p.id == this.model.formaFarmaceuticaId);

            if (!alreadyExists) {
                const formaFarmaceuticaModel = new FormaFarmaceuticaModel();

                formaFarmaceuticaModel.id = this.model.formaFarmaceuticaId;
                formaFarmaceuticaModel.descricao = formaFarmaceuticaComponent.innerText;
                formaFarmaceuticaModel.itensPadroes = null;
                formaFarmaceuticaModel.priorizar = this.formaPriorizar;
                this.model.formasFarmaceuticas.push(formaFarmaceuticaModel);

                this.model.formaFarmaceuticaId = -1;
            }
        }
    }

    private onInclusaoCapacidadeEmbalagemCapsula() {
        const capsulaComponent = this.$el.querySelector("#capsulaComboId") as HTMLSelectElement;

        if (this.capsulaComboId > 0 && this.quantidadeCapsula > 0) {
            const alreadyExists = this.model.kitEmbalagemCapsulas.some(p => p.capsulaId == this.capsulaComboId);

            if (!alreadyExists) {
                const kitEmbalagemCapsula = new KitEmbalagemCapsulaModel();

                kitEmbalagemCapsula.capsulaId = this.capsulaComboId;
                kitEmbalagemCapsula.quantidade = this.quantidadeCapsula;
                kitEmbalagemCapsula.capsulaDescricao = capsulaComponent.innerText;
                this.model.kitEmbalagemCapsulas.push(kitEmbalagemCapsula);

                this.capsulaComboId = -1;
                this.quantidadeCapsula = 0;
            }
        }
    }

    private onInclusaoProduto() {
        if (this.produtoComboId > 0 && this.quantidade > 0) {
            const alreadyExists =
                this.model.kitEmbalagemItens.filter(item => item.produtoId == this.produtoComboId).length > 0;

            if (!alreadyExists) {
                const selected = this.preLoadPack.produtos.filter(p => p.id == this.produtoComboId);

                const item = new KitEmbalagemItemModel();
                item.produtoId = this.produtoComboId;
                item.produtoDescricao =
                    selected[0].descricao +
                    (selected[0].sinonimos != null && selected[0].sinonimos != ""
                        ? " (" + selected[0].sinonimos + ")"
                        : "");
                item.quantidade = this.quantidade;
                item.produtoPadrao = this.produtoPadrao;

                this.model.kitEmbalagemItens.push(item);

                this.produtoComboId = -1;
                this.quantidade = 0;
            }
        }
    }

    private onRemoveFormaFarmaceutica(model: FormaFarmaceuticaModel) {
        this.model.formasFarmaceuticas = this.model.formasFarmaceuticas.filter(p => p != model);
    }

    private onRemoveCapacidadeEmbalagemCapsula(model: KitEmbalagemCapsulaModel) {
        this.model.kitEmbalagemCapsulas = this.model.kitEmbalagemCapsulas.filter(p => p != model);
    }

    private onRemoveProduto(model: KitEmbalagemItemModel) {
        this.model.kitEmbalagemItens = this.model.kitEmbalagemItens.filter(p => p != model);
    }

    private async getPreLoadPack() {
        while (!this.loadedList) await Delay(100);

        const currentFranquia = this.preLoadPack.currentFranquiaId;

        this.unidadesMedidaOptions = this.preLoadPack.unidadesMedida
            .filter(p => p.franquiaId == currentFranquia || p.franquiaId == null)
            .map(item => getUnidadeMedidaCombo(item));
        this.formasFarmaceuticaOptions = this.preLoadPack.formasFarmaceuticas
            .filter(p => p.franquiaId == currentFranquia || p.franquiaId == null)
            .map(getFormaFarmaceuticaCombo);
        this.capsulasOptions = this.preLoadPack.capsulasCombo();
        this.produtosOptions = this.preLoadPack.produtos
            .filter(p => p.franquiaId == currentFranquia || p.franquiaId == null)
            .map(item => ({
                value: item.id,
                text: getProdutoComboText(item),
                tipoProduto: item.tipoProduto,
                subGrupo: item.subGrupo,
            }));
    }

    //@ts-ignore
    @Watch("tipoProdutoId")
    private onGrupoProdutoIdChanged() {
        if (this.tipoProdutoId >= 0) {
            switch (this.tipoProdutoId) {
                case TiposProduto.MateriaPrima:
                    this.subGrupoOptions = [
                        { text: "Ativo", value: 0 },
                        { text: "Flavorizante", value: 1 },
                        { text: " Veículos/Excipientes", value: 2 },
                    ];
                    break;
                case TiposProduto.Embalagem:
                    this.subGrupoOptions = [
                        { text: "Recipiente", value: 3 },
                        { text: "Tampa", value: 4 },
                        { text: "Aplicador", value: 5 },
                        { text: "Outros", value: 6 },
                    ];
                    break;
                default:
                    this.subGrupo = null;
                    this.subGrupoOptions = [
                        { text: "Ativo", value: 0 },
                        { text: "Flavorizante", value: 1 },
                        { text: " Veículos/Excipientes", value: 2 },
                        { text: "Recipiente", value: 3 },
                        { text: "Tampa", value: 4 },
                        { text: "Aplicador", value: 5 },
                        { text: "Outros(Embalagens)", value: 6 },
                    ];
                    break;
            }
        } else {
            this.subGrupo = null;
            this.subGrupoOptions = [
                { text: "Ativo", value: 0 },
                { text: "Flavorizante", value: 1 },
                { text: " Veículos/Excipientes", value: 2 },
                { text: "Recipiente", value: 3 },
                { text: "Tampa", value: 4 },
                { text: "Aplicador", value: 5 },
                { text: "Outros(Embalagens)", value: 6 },
            ];
        }
        this.atualizaProdutoOptions();
    }

    //@ts-ignore
    @Watch("subGrupo")
    private onSubGrupoChanged() {
        if (this.subGrupo >= 0 && this.subGrupo <= 2 && this.tipoProdutoId != TiposProduto.MateriaPrima) {
            this.tipoProdutoId = TiposProduto.MateriaPrima;
        } else if (this.subGrupo > 2 && this.subGrupo <= 6 && this.tipoProdutoId != TiposProduto.Embalagem) {
            this.tipoProdutoId = TiposProduto.Embalagem;
        } else {
            //nao chama pelo TipoProduto chama por aqui
            this.atualizaProdutoOptions();
        }
    }

    //@ts-ignore
    @Watch("produtoComboId")
    private onProdutoIdChanged() {
        const produto = this.preLoadPack.produtos.filter(p => p.id == this.produtoComboId);
        if (produto.length > 0) {
            this.tipoProdutoId = produto[0].tipoProduto;
            this.subGrupo = produto[0].subGrupo;
            this.$forceUpdate();
        }
    }

    private atualizaProdutoOptions() {
        this.produtosOptions = this.preLoadPack.produtos
            .filter(
                p =>
                    (this.subGrupo == null ||
                        (p.subGrupo == this.subGrupo &&
                            (this.tipoProdutoId == null ||
                                this.tipoProdutoId == TiposProduto.MateriaPrima ||
                                this.tipoProdutoId == TiposProduto.Embalagem))) &&
                    (this.tipoProdutoId == null || p.tipoProduto == this.tipoProdutoId),
            )
            .map(item => ({
                value: item.id,
                text: getProdutoComboText(item),
                tipoProduto: item.tipoProduto,
                subGrupo: item.subGrupo,
            }));
    }

    //inicioAdmin
    //@ts-ignore
    @Watch("franquiaId")
    private onFranquiaChanged() {
        if (this.franquiaId > 0 && this.franquiaId != this.model.franquiaId) {
            this.loadKitEmbalagemByParent();
        }
    }

    private async loadKitEmbalagemByParent() {
        if (this.modelId > 0 && this.franquiaId > 0) {
            this.franquiaId = this.model.franquiaId;

            const data = await this.service
                .getByParent(this.modelId, this.franquiaId)
                .withLoading()
                .resolveWithJSON<KitEmbalagemModel>();

            this.model = new KitEmbalagemModel();
            this.model.updateFrom(data);
            this.franquiaId = data.franquiaId;

            this.formasFarmaceuticaOptions = this.preLoadPack.formasFarmaceuticas
                .filter(p => p.franquiaId == this.franquiaId)
                .map(getFormaFarmaceuticaCombo);

            this.capsulasOptions = this.preLoadPack.capsulasCombo();
        }
    }

    private validarVisibilidadeBotoes(botao: string): boolean {
        if (botao == "replicar") {
            return this.model.id > 0 && this.model.kitEmbalagemPaiId == null && this.isAdminFranqueador;
        }

        if (botao == "remover") {
            return this.model.id > 0 && this.model.kitEmbalagemPaiId != null;
        }
    }

    private onReplicar() {
        this.replicarSelecaoFranquiaComponent = this.$refs
            .replicarSelecaoFranquiaComponent as ReplicarSelecaoFranquiaComponent;
        this.replicarSelecaoFranquiaComponent.openFranquias();
    }

    private async onConfirmReplicar(franquiasIds: Array<number>) {
        const idsFranquias = franquiasIds.toString();

        const sucesso = await this.service.replicar(this.model.id, idsFranquias).withLoading().resolveWithoutJSON();
        if (sucesso) {
            this.$showSuccess(this.$t("__.ts.replicou"), this.$t("__.ts.regisSalvosSucess"));
        }
    }

    private async loadFranquais() {
        if (this.isAdmin) {
            const data = await new FranquiaService()
                .combo()
                .withLoading()
                .resolveWithJSON<PaginationModel<FranquiaModel>>();
            this.franquiaOptions = data.list.map(item => ({ value: item.id, text: item.nomeFantasia }));
        }
    }

    private async onRemoveFilho() {
        const response = await this.$showExclusaoQuestion(`${this.$t("__.ts.desejaExcl")}${this.model.descricao}?`);

        if (response) {
            try {
                const sucesso = await this.service.delete(this.model.id).withLoading().resolveWithoutJSON();
                if (sucesso) {
                    this["LOAD_LIST"]({ loadKitEmbalagem: true });
                    this.load();
                    this.$showExclusao();
                }
            } catch {}
        }
    }
    //fim admin

    private async mounted() {
        this.isAdmin = await this.HAS_PERMISSAO_PERFIL(false, false, false);
        this.isAdminFranqueador = await this.HAS_PERMISSAO_PERFIL(false, true, false);

        Promise.all([this.getPreLoadPack(), this.loadFranquais(), this.loadRotulosManipulacao()])
            .then(() => {
                if (this.$route.params.id) {
                    this.modelId = +this.$route.params.id;
                }

                this.load();
            })
            .catch(() => {});
    }
}
