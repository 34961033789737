import Vue from "vue";

import { Component } from "@/decorators";
import FastActionService from "@/services/fastActionService";

import FastActionGraficoComponent from "./grafico/grafico";
import fastActionGraficoComponent from "./grafico/grafico.vue";

import "./list.scss";

@Component({
    components: {
        fastActionGraficoComponent,
    },
})
export default class FastActionFinanceiroPanelComponent extends Vue {
    private fastActionGraficoComponent: FastActionGraficoComponent = null;
    private totalAReceber = 0;
    private totalAPagar = 0;

    metaMensal = 0; //"10.000,00"
    maiorMensal = 0;
    totalAnual = 0;
    sessaoCaixaId = 0;

    private onCarregouGrafico() {
        this.totalAnual = this.fastActionGraficoComponent.getTotalReceitas();
        this.maiorMensal = this.fastActionGraficoComponent.getMaiorMensal();
        this.metaMensal = this.fastActionGraficoComponent.getMetaMensal();
    }

    private get saldoInicial() {
        return 0;
    }

    private get totalReceber() {
        return this.totalAReceber;
    }

    private get totalPagar() {
        return this.totalAPagar;
    }

    private get saldoFinal() {
        return this.saldoInicial + this.totalAReceber - this.totalAPagar;
    }

    private async loadInfosFinanc() {
        await new FastActionService()
            .getInfosFinanceiro()
            .then(result => {
                return result.json() as Promise<{ valorPagar?: number; valorReceber?: number; sessaoCaixaId?: number }>;
            })
            .then(data => {
                this.totalAPagar = data.valorPagar;
                this.totalAReceber = data.valorReceber;
                this.sessaoCaixaId = data.sessaoCaixaId;
                this.$forceUpdate();
            })
            .catch(error => {});
    }

    private mounted() {
        this.fastActionGraficoComponent = this.$refs.fastActionGraficoComponent as FastActionGraficoComponent;
        this.onCarregouGrafico();
        return Promise.all([this.loadInfosFinanc()])
            .then(result => {
                // this.loadingModalComponent.hide();
            })
            .catch(() => {
                // this.loadingModalComponent.hide();
            });
    }
}
