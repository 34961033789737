import ContaCorrenteModel from "../../models/financeiro/contaCorrenteModel";
import nfetch from "../../utils/events/nfetch";

import { IService } from "./../base/iService";

export default class ContaCorrenteService extends IService<ContaCorrenteModel> {
    constructor() {
        super("ContaCorrente");
    }
    public listByFranquiaId(franquiaId: number = null): Promise<Response> {
        return  nfetch(`/${this.controllerName}/ListByFranquiaId?franquiaId=${franquiaId}`, {
            credentials: "same-origin"
        });
    }

}