import Vue from "vue";
import VueI18n from "vue-i18n";

import Alert, { SweetAlertOptions, SweetAlertResult } from "sweetalert2";

import Boot from "@/main";
type ColorType = "BLUE" | "RED" | "GREEN" | "GRAY";

export const AlertColor = {
    BLUE: "#3085d6",
    RED: "#d33",
    GREEN: "#65bb65",
    GRAY: "#b9b4b4",
};

type Text = string | VueI18n.TranslateResult;

declare module "vue/types/vue" {
    interface Vue {
        $showSuccess: (title: Text, text: Text) => Promise<void>;
        $showWarning: (title: Text, text: Text) => Promise<void>;
        $showWarningHtml: (title: Text, html: Text) => Promise<void>;
        $showInfo: (title: Text, text: Text) => Promise<void>;
        $showError: (title: Text, text: Text) => Promise<void>;
        $showAlert: (settings: SweetAlertOptions) => Promise<SweetAlertResult>;
        $showExclusaoQuestion: (title: Text, text?: Text) => Promise<boolean>;
        $showQuestion: (
            title: Text,
            text: Text,
            settings?: SweetAlertOptions,
            confirmColor?: ColorType,
            cancelColor?: ColorType,
        ) => Promise<boolean>;
        $showQuestionWithHTML: (
            title: Text,
            html: Text,
            settings?: SweetAlertOptions,
            confirmColor?: ColorType,
            cancelColor?: ColorType,
        ) => Promise<boolean>;
        $showExclusao: () => Promise<void>;
        $showInclusaoUpdate: (id: number) => Promise<void>;
    }
}

async function DefaultAlert(settings: SweetAlertOptions) {
    if (!settings.title) {
        switch (settings.icon) {
            case "success":
                settings.title = Boot.$t("__.ts.sucesso").toString();
                break;
            case "error":
                settings.title = Boot.$t("__.ts.erro").toString();
                break;
            case "warning":
                settings.title = Boot.$t("__.ts.atencao").toString();
                break;
        }
    }
    return await Alert.fire({
        confirmButtonColor: AlertColor.GRAY,
        ...settings,
    });
}

Vue.prototype.$showSuccess = async (title: Text, text: Text) =>
    await DefaultAlert({ title: title as string, text: text as string, icon: "success" });

Vue.prototype.$showWarning = async function (title: Text, text: Text = this.$t("__.ts.atencao")) {
    return await DefaultAlert({ title: title as string, text: text as string, icon: "warning" });
};

Vue.prototype.$showWarningHtml = async function (title: Text, html: Text = this.$t("__.ts.atencao")) {
    return await DefaultAlert({ title: title as string, html: html as string, icon: "warning" });
};

Vue.prototype.$showError = async function (title: Text, text: Text = this.$t("__.ts.erro")) {
    return await DefaultAlert({ title: title as string, text: text as string, icon: "error" });
};

Vue.prototype.$showInfo = async (title: Text, text: Text) =>
    await DefaultAlert({ title: title as string, text: text as string, icon: "info" });

Vue.prototype.$showAlert = async function (settings: SweetAlertOptions) {
    return await DefaultAlert({
        confirmButtonColor: AlertColor.GREEN,
        cancelButtonColor: AlertColor.GRAY,
        cancelButtonText: this.$t("__.ts.cancelar") as string,
        ...settings,
    });
};

Vue.prototype.$showExclusaoQuestion = async function (title: Text, text: Text = this.$t("__.ts.acaoNaoRevert")) {
    try {
        const reponse = await DefaultAlert({
            title: title as string,
            text: text as string,
            icon: "question",
            showCancelButton: true,
            confirmButtonColor: AlertColor.RED,
            cancelButtonColor: AlertColor.GRAY,
            confirmButtonText: this.$t("__.ts.sim"),
            cancelButtonText: this.$t("__.ts.nao"),
        });

        return reponse.isConfirmed;
    } catch {}
    return false;
};

Vue.prototype.$showQuestion = async function (
    title: Text,
    text: Text,
    settings: {},
    confirmColor: ColorType = "GREEN",
    cancelColor: ColorType = "GRAY",
) {
    try {
        const response = await DefaultAlert({
            title: title as string,
            text: text as string,
            icon: "question",
            showCancelButton: true,
            confirmButtonColor: AlertColor[confirmColor],
            cancelButtonColor: AlertColor[cancelColor],
            confirmButtonText: this.$t("__.ts.sim"),
            cancelButtonText: this.$t("__.ts.nao"),
            ...settings,
        });
        return response.isConfirmed;
    } catch {}
    return false;
};

Vue.prototype.$showQuestionWithHTML = async function (
    title: Text,
    html: Text,
    settings: {},
    confirmColor: ColorType = "GREEN",
    cancelColor: ColorType = "GRAY",
) {
    try {
        const response = await DefaultAlert({
            title: title as string,
            html: html as string,
            icon: "question",
            showCancelButton: true,
            confirmButtonColor: AlertColor[confirmColor],
            cancelButtonColor: AlertColor[cancelColor],
            confirmButtonText: this.$t("__.ts.sim"),
            cancelButtonText: this.$t("__.ts.nao"),
            ...settings,
        });
        return response.isConfirmed;
    } catch {}
    return false;
};

Vue.prototype.$showExclusao = async function () {
    return await DefaultAlert({
        title: this.$t("__.ts.exclusao"),
        text: this.$t("__.ts.regisExcluSucess"),
        icon: "success",
    });
};

Vue.prototype.$showInclusaoUpdate = async function (id: number) {
    return await DefaultAlert({
        title: !id ? this.$t("__.ts.inclusao") : this.$t("__.ts.alt"),
        text: this.$t("__.ts.registroSalvSucess"),
        icon: "success",
        allowOutsideClick: false,
    });
};

export { DefaultAlert as Alert };
