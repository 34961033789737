import BaseModel from "./../base/baseModel";

export default class metodoPagamentoXFormaModel extends BaseModel {
    id?: number = null;
    formaRecebimentoId?: number = null;
    metodoPagamentoId: number = null;
    tarifa?: number = null;
    juros?: number = null;
    desconto?: number = null;
    metodoPagamentoDescricao: string = null;
}
