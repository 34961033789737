import Vue from "vue";
import { mapMutations, mapState } from "vuex";

import Grid from "@/components/child/grid/grid";
import gridComponent from "@/components/child/grid/grid.vue";
import { GridColumn, GridColumnType } from "@/components/child/grid/gridColumn";
import { Component, Watch } from "@/decorators";
import PaginationPackModel from "@/models/auxiliar/paginationPackModel";
import FormaFarmaceuticaModel from "@/models/formaFarmaceuticaModel";
import PaginationModel from "@/models/paginationModel";
import FormaFarmaceuticaService from "@/services/formaFarmaceuticaService";
import { AppState, Mutations } from "@/store/store";
import Delay from "@/utils/common/delay";

import "../crud.scss";

@Component({
    components: {
        gridComponent,
    },
    computed: mapState<AppState>({
        loadingPack: state => state.paginationStatus.loading,
        paginationParams: state => state.paginationStatus.paginationParams,
    }),
    methods: mapMutations(["LOAD_LIST", "SET_PARAMS", "GET_PARAMS"] as Mutations),
})
export default class FormaFarmaceuticaListComponent extends Vue {
    // State computed props
    loadingPack: boolean;
    paginationParams: PaginationPackModel;
    SET_PARAMS: (obj: object) => void;
    GET_PARAMS: (path: string) => Promise<void>;

    private service = new FormaFarmaceuticaService();
    private gridcomponent: Grid = null;

    startFilterKey = "";
    gridFilterKey = "";
    gridSortKey = "descricao";
    gridSortOrder = "asc";
    gridData: Array<FormaFarmaceuticaModel> = [];
    get gridColumns(): Array<GridColumn> {
        return [
            new GridColumn("descricao", "Descri\u00e7\u00e3o", GridColumnType.String),
            new GridColumn("valorMinimo", "Valor m\u00ednimo", GridColumnType.Money),
            new GridColumn("valorAdicional", "Valor adicional", GridColumnType.Money),
        ];
    }

    pageIndex = 1;
    pageSize = 20;
    total = 0;

    pags = false;
    tipoDocGerar: number = null;

    private async load() {
        try {
            const data = await this.service
                .list(
                    this.gridFilterKey,
                    this.gridSortKey,
                    this.gridSortOrder,
                    this.pags ? 1 : this.pageIndex,
                    this.pags ? 999999 : this.pageSize,
                )
                .withLoading()
                .resolveWithJSON<PaginationModel<FormaFarmaceuticaModel>>();

            if (data) {
                //atualiza params no store
                if (data.total > 0) {
                    this.SET_PARAMS({
                        routePath: this.$route.path,
                        filterKey: this.gridFilterKey,
                        sortKey: this.gridSortKey,
                        sortOrder: this.gridSortOrder,
                        pageIndex: this.pageIndex,
                        pageSize: this.pageSize,
                    });
                }

                this.gridData = data.list;
                this.total = data.total;
                if (this.pags) {
                    if (this.tipoDocGerar == 0) {
                        setTimeout(() => {
                            this.gridcomponent.gerarXls();
                        }, 2000);
                    }

                    if (this.tipoDocGerar == 1) {
                        setTimeout(() => {
                            this.gridcomponent.gerarPdf();
                        }, 2000);
                    }

                    if (this.tipoDocGerar == 2) {
                        setTimeout(() => {
                            this.gridcomponent.gerarImpressao();
                        }, 2000);
                    }

                    setTimeout(() => {
                        this.pags = false;
                    }, 2000);
                }
            }
        } catch {}
    }

    private onEditItem(model: FormaFarmaceuticaModel) {
        this.$router.push("/formafarmaceutica-edicao/" + model.id);
    }

    private async onRemoveItem(model: FormaFarmaceuticaModel) {
        const response = await this.$showExclusaoQuestion(`${this.$t("__.ts.desejaExcl")}${model.descricao}?`);

        if (response) {
            try {
                const sucesso = await this.service.delete(model.id).withLoading().resolveWithoutJSON();
                if (sucesso) {
                    this["LOAD_LIST"]({ loadFormaFarmaceutica: true });
                    this.load();
                    this.$showExclusao();
                }
            } catch {}
        }
    }

    private onCreateItem() {
        this.$router.push("/formafarmaceutica-edicao");
    }

    private onChangeFilterKey(filterKey: string) {
        this.gridFilterKey = filterKey;
        this.load();
    }

    private onChangeSort(sortKey: string, sortOrder: string) {
        this.gridSortKey = sortKey;
        this.gridSortOrder = sortOrder;
        this.load();
    }

    private onChangePage(pageIndex: number) {
        this.pageIndex = pageIndex;
        this.load();
    }

    @Watch("pags")
    private onPags() {
        this.load();
    }

    private async loadPagination() {
        await this.GET_PARAMS(this.$route.path);
        while (this.loadingPack) await Delay(50);

        if (this.paginationParams != null) {
            this.gridFilterKey = this.paginationParams.filterKey;
            this.startFilterKey = this.gridFilterKey;
            this.gridSortKey = this.paginationParams.sortKey;
            this.gridSortOrder = this.paginationParams.sortOrder;
            this.pageIndex = this.paginationParams.pageIndex;
            this.pageSize = this.paginationParams.pageSize;
        }
    }

    private mounted() {
        this.gridcomponent = this.$refs.gridcomponent as Grid;

        Promise.all([this.loadPagination()])
            .withLoading()
            .then(() => {
                this.load();
            })
            .catch(() => {});
    }
}
