import { Component } from "@/decorators";
import CorModel from "@/models/corModel";
import CorService from "@/services/corService";

import EditComponentBase from "../editComponentBase";

@Component
export default class CorEditComponent extends EditComponentBase<CorModel> {
    private async beforeMount() {
        await this.setProps(new CorService(), "cores", new CorModel());
    }
}
