import NotaFiscalEntradaModel from "../models/notaFiscalEntradaModel";
import NotaFiscalEletronicaImportacaoQuestionMessageViewModel from "../models/questionMessages/notaFiscalEletronicaImportacaoQuestionMessageViewModel";
import nfetch from "../utils/events/nfetch";

import { IService } from "./base/iService";

export default class NotaFiscalEntradaService extends IService<NotaFiscalEntradaModel> {
    constructor() {
        super("NotaFiscalEntrada");
    }

    public override insert(data: NotaFiscalEntradaModel): Promise<Response> {
        return nfetch(`/${this.controllerName}/Insert`, {
            method: "post",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json; charset=UTF-8",
                "XSRF-TOKEN": this.getCookie("XSRF-TOKEN"),
            },
            body: JSON.stringify({ data }),
        });
    }

    public insertWithQuestions(
        data: NotaFiscalEntradaModel,
        questionMessages: NotaFiscalEletronicaImportacaoQuestionMessageViewModel[],
    ): Promise<Response> {
        return nfetch(`/${this.controllerName}/Insert`, {
            method: "post",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json; charset=UTF-8",
                "XSRF-TOKEN": this.getCookie("XSRF-TOKEN"),
            },
            body: JSON.stringify({ data, questionMessages }),
        });
    }

    public validate(model: NotaFiscalEntradaModel): Promise<Response> {
        return nfetch(`/${this.controllerName}/Validate`, {
            method: "post",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json; charset=UTF-8",
                "XSRF-TOKEN": this.getCookie("XSRF-TOKEN"),
            },
            body: JSON.stringify(model),
        });
    }

    public verificaExisteNf(numero: number, serie: number, cnpj: string): Promise<Response> {
        //@ts-ignore
        return nfetch(`/${this.controllerName}/VerificarNotaExistente?numero=${numero}&serie=${serie}&cnpj=${cnpj}`, {
            method: "post",
            credentials: "same-origin",
            headers: {
                "XSRF-TOKEN": this.getCookie("XSRF-TOKEN"),
            },
        })
            .then((response: Response) => {
                if (response.ok) {
                    return response;
                } else {
                    return this.mensagemErro(response, "");
                }
            })
            .catch(() => {});
    }

    public verificaExisteNfByChave(chave: string): Promise<Response> {
        //@ts-ignore
        return nfetch(`/${this.controllerName}/VerificarNotaExistenteByChave?chave=${chave}`, {
            method: "post",
            credentials: "same-origin",
            headers: {
                "XSRF-TOKEN": this.getCookie("XSRF-TOKEN"),
            },
        });
    }

    public getByProdutoId(produtoLoteId: number): Promise<Response> {
        return nfetch(`/${this.controllerName}/GetByProdutoLote?produtoLoteId=${produtoLoteId}`, {
            method: "get",
            credentials: "same-origin",
            headers: {
                "XSRF-TOKEN": this.getCookie("XSRF-TOKEN"),
            },
        });
    }

    public getByProduto(produtoId: number): Promise<Response> {
        return nfetch(`/${this.controllerName}/GetByProduto?produtoId=${produtoId}`, {
            method: "get",
            credentials: "same-origin",
        });
    }
}
