import Vue from "vue";

import { Component, Prop, Watch } from "@/decorators";

import "./filepicker.scss";

@Component
export default class FilePickerComponent extends Vue {
    @Prop(String) id: string;
    @Prop(String) name: string;
    @Prop(File) value: File;
    @Prop(Boolean) disabled: boolean;

    private onFileChanged(event) {
        this.$emit("input", event.target.files[0]);
    }

    @Watch("value")
    private onValueChanged() {
        if (!this.value) {
            (this.$el.querySelector("input[type='file']") as HTMLInputElement).value = "";
        }
    }
}
