import Vue from "vue";

import { GridColumn, GridColumnType } from "@/components/child/grid/gridColumn";
import ModalComponent from "@/components/child/modal/modal";
import modalComponent from "@/components/child/modal/modal.vue";
import { Component, Prop } from "@/decorators";
import Bus from "@/utils/events/bus";

import "./shortcut.scss";

@Component({
    components: {
        modalComponent,
    },
})
export default class ShortcutComponent extends Vue {
    private modalComponent: ModalComponent = null;
    private modalAddNewComponent: ModalComponent = null;

    public title = "";

    selecionado: object = null;
    //variavel para usar de retorno do id que chamou o componente
    private codigo = null;

    gridFilterKey = "";
    gridSortKey = "descricao";
    gridSortOrder = "asc";

    get gridColumns(): Array<GridColumn> {
        return [new GridColumn("descricao", "Descrição", GridColumnType.String)];
    }

    @Prop({ type: Array, default: () => [] }) extraButtons;
    @Prop({ type: Boolean, default: false }) showAddNew: boolean;
    @Prop({ type: Boolean, default: true }) showBtnOk: boolean;
    @Prop({ type: String, default: "OK" }) btOkName: string;
    @Prop({ type: String, default: null }) btOkShortKey: string;
    @Prop({ type: String, default: "lg-modal" }) modalClass: string;
    @Prop({ type: String, default: "white" }) headerColor: string;

    public show(_codigoRetorno?) {
        //será enviado de volta como parametro para a tarefa q o chamou junto ao objeto selecionado
        this.codigo = _codigoRetorno;
        this.modalComponent.show();

        //Desmarca os checkboxes que ficaram marcados na última vez que a tela foi aberta
        const checkboxes = this.$el.querySelectorAll(
            "tr > td > input[type='checkbox']",
        ) as NodeListOf<HTMLInputElement>;

        for (let i = 0; i < checkboxes.length; i++) {
            checkboxes[i].checked = false;
        }
    }

    public getCodigo() {
        return this.codigo;
    }

    public hide() {
        this.hideAddNew();
        this.$emit("hide-click");
        this.modalComponent.hide();
    }

    public hidewithoudOutputEvent() {
        this.hideAddNew();
        this.modalComponent.hideWithoudOutputEvent();
    }

    private onBtnOkClick() {
        this.$validator
            .validateAll()
            .then(() => {
                this.$emit("selection-item", this.selecionado, this.codigo);
            })
            .catch(() => {});
    }

    private extraButtonClick(name, entry) {
        this.$emit("extra-button-click", name, entry, this.codigo);
    }

    private onBtnAddNewClick() {
        this.modalAddNewComponent.show();
    }

    public hideAddNew() {
        this.modalAddNewComponent.hide();
    }

    private onBtnOkClickAddNew() {
        this.$emit("add-new-save-ok-button-click", this.codigo);
    }

    public isShowing() {
        return this.modalComponent.isShowing();
    }

    private hideBtnClick() {
        this.$emit("hide-click");
    }

    private mounted() {
        //cria o listener para receber o evento 'globla'
        Bus.$on("select-value-changed-emit", param => {
            this.selecionado = param || null;
        });

        this.modalComponent = this.$refs.modalComponent as ModalComponent;
        this.modalAddNewComponent = this.$refs.modalAddNewComponent as ModalComponent;
    }
}
