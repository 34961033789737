var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "fieldset-component",
    {
      attrs: {
        title: _vm.$t(
          "__.Components.parent.printModel.sessao_vue_html.usuarios"
        ),
        collapsed: false,
      },
    },
    [
      _c(
        "div",
        { attrs: { slot: "rows" }, slot: "rows" },
        [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-sm-6" }, [
              _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c(
                    "label",
                    {
                      staticClass: "control-label",
                      attrs: {
                        for: "usuarioId",
                        title: _vm.$t("__.obrigatorio"),
                      },
                    },
                    [
                      _c("span", { staticStyle: { color: "red" } }, [
                        _vm._v("*"),
                      ]),
                      _vm._v(
                        "\n                        " +
                          _vm._s(_vm.$t("__.ts.usuario")) +
                          "\n                    "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("combo-component", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'",
                      },
                    ],
                    attrs: {
                      id: "usuarioId",
                      name: "usuarioId",
                      data: _vm.usuarioOptions,
                      searchable: true,
                    },
                    model: {
                      value: _vm.usuarioId,
                      callback: function ($$v) {
                        _vm.usuarioId = $$v
                      },
                      expression: "usuarioId",
                    },
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-sm-6" }, [
              _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c(
                    "label",
                    {
                      staticClass: "control-label",
                      attrs: {
                        for: "usuarioImpressora",
                        title: _vm.$t("__.obrigatorio"),
                      },
                    },
                    [
                      _c("span", { staticStyle: { color: "red" } }, [
                        _vm._v("*"),
                      ]),
                      _vm._v(
                        "\n                        " +
                          _vm._s(_vm.$t("__.ts.impressora")) +
                          "\n                    "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("combo-component", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'",
                      },
                    ],
                    attrs: {
                      id: "usuarioImpressora",
                      name: "usuarioImpressora",
                      data: _vm.impressoraOptions,
                      searchable: false,
                    },
                    model: {
                      value: _vm.usuarioImpressora,
                      callback: function ($$v) {
                        _vm.usuarioImpressora = $$v
                      },
                      expression: "usuarioImpressora",
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("buttonIncluirComponent", {
            on: { click: _vm.onInclusaoModeloUsuario },
          }),
          _vm._v(" "),
          _c("grid-component", {
            attrs: {
              data: _vm.usuarios,
              columns: _vm.gridColumnsUsuarios,
              "show-table-head": false,
              "show-table-foot": false,
              "show-edit-item": false,
            },
            on: { "remove-item": _vm.onRemoveModeloUsuario },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }