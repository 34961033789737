var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "crud crud-edit" },
    [
      _c("actionBarComponent", {
        attrs: {
          title: _vm.$t("__.Crud.compras.cotacao_vue_html.cotacaoEdit"),
        },
        on: { onSave: _vm.save },
      }),
      _vm._v(" "),
      _c(
        "form",
        {
          attrs: { novalidate: "" },
          on: {
            submit: function ($event) {
              $event.preventDefault()
              _vm.save()
            },
          },
        },
        [
          _c(
            "fieldset-component",
            {
              attrs: {
                title: _vm.$t("__.Crud.compras.cotacao_vue_html.compraItens"),
                collapsed: false,
              },
            },
            [
              _c(
                "div",
                { attrs: { slot: "rows" }, slot: "rows" },
                [
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      { staticClass: "col-sm-12" },
                      [
                        _c("grid-component", {
                          ref: "gridcomponent",
                          attrs: {
                            data: _vm.gridData,
                            columns: _vm.gridColumns,
                            subColumns: _vm.gridColumnsCamposEditable,
                            showActionColumn: true,
                            subDataName: _vm.subDataName,
                            conditionShowSubData: _vm.conditionShowSubData,
                            highlightRow: _vm.highlightRow,
                            showExpand: true,
                            showTableHead: false,
                            showTableFoot: false,
                            showRemoveItem: false,
                            showEditItem: false,
                            extraActions: _vm.extraActions,
                          },
                          on: {
                            "change-sort": _vm.onChangeSort,
                            "extra-action": _vm.onExtraActions,
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "shortcutComponent",
                    {
                      ref: "shortcutComponent",
                      attrs: {
                        showAddNew: false,
                        showBtnOk: false,
                        modalClass: "lg-modal",
                      },
                    },
                    [
                      _c(
                        "div",
                        { attrs: { slot: "component" }, slot: "component" },
                        [
                          _c("grid-component", {
                            ref: "gridNotascomponent",
                            attrs: {
                              data: _vm.gridNotasProduto,
                              columns: _vm.gridColumnsNotas,
                              showActionColumn: false,
                              showTableHead: false,
                              showTableFoot: false,
                              showRemoveItem: false,
                              showEditItem: false,
                            },
                            on: { "change-sort": _vm.onChangeSortNota },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c("fieldset", [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-sm-12 newbtn" }, [
                _c("div", { staticClass: "form-group" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-success",
                      attrs: { type: "submit" },
                    },
                    [
                      _c("i", {
                        staticClass: "fa fa-save",
                        attrs: { "aria-hidden": "true" },
                      }),
                      _vm._v(
                        "\n                            " +
                          _vm._s(_vm.$t("__.Crud.salvar")) +
                          "\n                        "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-secondary",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          _vm.cancel()
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n                            " +
                          _vm._s(_vm.$t("__.Crud.voltar")) +
                          "\n                        "
                      ),
                    ]
                  ),
                ]),
              ]),
            ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }