var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "home-infos" }, [
    _c("div", { staticClass: "fast-action" }, [
      _c("div", { staticClass: "fast-main-block" }, [
        _c("div", { staticClass: "fast-action-menu" }, [
          _c("ul", [
            _vm.VALIDAR_PERMISSAO_USUARIO("orcamentos", "list")
              ? _c(
                  "li",
                  {
                    class: { active: _vm.selectedMenu == "orcamentos" },
                    on: {
                      click: function ($event) {
                        _vm.showMenu("orcamentos")
                      },
                    },
                  },
                  [
                    _c("div", [
                      _vm.countDiff != null && _vm.countDiff.orcamentos > 0
                        ? _c("span", { staticClass: "squareCount" }, [
                            _c(
                              "span",
                              {
                                staticClass: "count",
                                attrs: { "aria-hidden": "true" },
                              },
                              [_vm._v(_vm._s(_vm.countDiff.orcamentos))]
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c("i", {
                        staticClass: "fa credit-card",
                        attrs: { "aria-hidden": "true" },
                      }),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(
                          "\n                                " +
                            _vm._s(
                              _vm.$t(
                                "__.Components.parent.home.fastaction.fastaction_vue_html.orcamentos"
                              )
                            ) +
                            "\n                            "
                        ),
                      ]),
                    ]),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            !_vm.isFracionamento &&
            _vm.VALIDAR_PERMISSAO_USUARIO("manipulacoes_venda", "list")
              ? _c(
                  "li",
                  {
                    class: { active: _vm.selectedMenu == "manipulacao" },
                    on: {
                      click: function ($event) {
                        _vm.showMenu("manipulacao")
                      },
                    },
                  },
                  [
                    _c("div", [
                      _vm.countDiff != null &&
                      _vm.countDiff.manipulacoesAConcluir > 0
                        ? _c("span", { staticClass: "squareCount" }, [
                            _c(
                              "span",
                              {
                                staticClass: "count",
                                attrs: { "aria-hidden": "true" },
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.countDiff.manipulacoesAConcluir)
                                ),
                              ]
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c("i", {
                        staticClass: "fa fa-flask fa-3x",
                        attrs: { "aria-hidden": "true" },
                      }),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(
                          "\n                                " +
                            _vm._s(
                              _vm.$t(
                                "__.Components.parent.home.fastaction.fastaction_vue_html.manipulacao"
                              )
                            ) +
                            "\n                            "
                        ),
                      ]),
                    ]),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.isFracionamento
              ? _c(
                  "li",
                  {
                    class: {
                      active: _vm.selectedMenu == "estoqueFracionamento",
                    },
                    on: {
                      click: function ($event) {
                        _vm.showMenu("estoqueFracionamento")
                      },
                    },
                  },
                  [
                    _c("div", [
                      _vm.countDiff != null &&
                      _vm.countDiff.estoqueFracionamento > 0
                        ? _c("span", { staticClass: "squareCount" }, [
                            _c(
                              "span",
                              {
                                staticClass: "count",
                                attrs: { "aria-hidden": "true" },
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.countDiff.estoqueFracionamento)
                                ),
                              ]
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c("i", {
                        staticClass: "fa fa-cubes fa-3x",
                        attrs: { "aria-hidden": "true" },
                      }),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(
                          "\n                                " +
                            _vm._s(
                              _vm.$t(
                                "__.Components.parent.home.fastaction.fastaction_vue_html.estoque"
                              )
                            ) +
                            "\n                            "
                        ),
                      ]),
                    ]),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.isFracionamento
              ? _c(
                  "li",
                  {
                    class: { active: _vm.selectedMenu == "expedicao" },
                    on: {
                      click: function ($event) {
                        _vm.showMenu("expedicao")
                      },
                    },
                  },
                  [
                    _c("div", [
                      _vm.countDiff != null && _vm.countDiff.expedicao > 0
                        ? _c("span", { staticClass: "squareCount" }, [
                            _c(
                              "span",
                              {
                                staticClass: "count",
                                attrs: { "aria-hidden": "true" },
                              },
                              [_vm._v(_vm._s(_vm.countDiff.expedicao))]
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c("i", {
                        staticClass: "fa credit-card-exp",
                        attrs: { "aria-hidden": "true" },
                      }),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(
                          "\n                                " +
                            _vm._s(
                              _vm.$t(
                                "__.Components.parent.home.fastaction.fastaction_vue_html.expedicao"
                              )
                            ) +
                            "\n                            "
                        ),
                      ]),
                    ]),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.isFracionamento &&
            _vm.VALIDAR_PERMISSAO_USUARIO("lotes", "list")
              ? _c(
                  "li",
                  {
                    class: { active: _vm.selectedMenu == "fracionamento" },
                    on: {
                      click: function ($event) {
                        _vm.showMenu("fracionamento")
                      },
                    },
                  },
                  [
                    _c("div", [
                      _vm.countDiff != null && _vm.countDiff.fracionamento > 0
                        ? _c("span", { staticClass: "squareCount" }, [
                            _c(
                              "span",
                              {
                                staticClass: "count",
                                attrs: { "aria-hidden": "true" },
                              },
                              [_vm._v(_vm._s(_vm.countDiff.fracionamento))]
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c("i", {
                        staticClass: "fa fa-box-open fa-3x",
                        attrs: { "aria-hidden": "true" },
                      }),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(
                          "\n                                " +
                            _vm._s(
                              _vm.$t(
                                "__.Components.parent.home.fastaction.fastaction_vue_html.fracionamento"
                              )
                            ) +
                            "\n                            "
                        ),
                      ]),
                    ]),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "li",
              {
                class: { active: _vm.selectedMenu == "vendas" },
                on: {
                  click: function ($event) {
                    _vm.showMenu("vendas")
                  },
                },
              },
              [
                _c("div", [
                  _vm.countDiff != null && _vm.countDiff.vendasAFaturar > 0
                    ? _c("span", { staticClass: "squareCount" }, [
                        _c(
                          "span",
                          {
                            staticClass: "count",
                            attrs: { "aria-hidden": "true" },
                          },
                          [_vm._v(_vm._s(_vm.countDiff.vendasAFaturar))]
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("i", {
                    staticClass: "fa fa-credit-card fa-3x",
                    attrs: { "aria-hidden": "true" },
                  }),
                  _vm._v(" "),
                  !_vm.isFracionamento
                    ? _c("span", [
                        _vm._v(
                          "\n                                " +
                            _vm._s(
                              _vm.$t(
                                "__.Components.parent.home.fastaction.fastaction_vue_html.vendas"
                              )
                            ) +
                            "\n                            "
                        ),
                      ])
                    : _c("span", [
                        _vm._v(
                          "\n                                " +
                            _vm._s(
                              _vm.$t(
                                "__.Components.parent.home.fastaction.fastaction_vue_html.financeiro"
                              )
                            ) +
                            "\n                            "
                        ),
                      ]),
                ]),
              ]
            ),
            _vm._v(" "),
            !_vm.isFracionamento &&
            _vm.VALIDAR_PERMISSAO_USUARIO("vendas_entregas", "Listar")
              ? _c(
                  "li",
                  {
                    class: { active: _vm.selectedMenu == "entregas" },
                    on: {
                      click: function ($event) {
                        _vm.showMenu("entregas")
                      },
                    },
                  },
                  [
                    _c("div", [
                      _vm.countDiff != null && _vm.countDiff.entregas > 0
                        ? _c("span", { staticClass: "squareCount" }, [
                            _c(
                              "span",
                              {
                                staticClass: "count",
                                attrs: { "aria-hidden": "true" },
                              },
                              [_vm._v(_vm._s(_vm.countDiff.entregas))]
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c("i", {
                        staticClass: "fa fa-truck fa-3x",
                        attrs: { "aria-hidden": "true" },
                      }),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(
                          "\n                                " +
                            _vm._s(
                              _vm.$t(
                                "__.Components.parent.home.fastaction.entregas.entregas_vue_html.entregas"
                              )
                            ) +
                            "\n                            "
                        ),
                      ]),
                    ]),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            !_vm.isFracionamento &&
            _vm.VALIDAR_PERMISSAO_USUARIO("vendas", "ListarContinuos")
              ? _c(
                  "li",
                  {
                    class: { active: _vm.selectedMenu == "continuos" },
                    on: {
                      click: function ($event) {
                        _vm.showMenu("continuos")
                      },
                    },
                  },
                  [
                    _c("div", [
                      _vm.countDiff != null && _vm.countDiff.continuos > 0
                        ? _c("span", { staticClass: "squareCount" }, [
                            _c(
                              "span",
                              {
                                staticClass: "count",
                                attrs: { "aria-hidden": "true" },
                              },
                              [_vm._v(_vm._s(_vm.countDiff.continuos))]
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c("i", {
                        staticClass: "fa fa-sync fa-3x",
                        attrs: { "aria-hidden": "true" },
                      }),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(
                          "\n                                " +
                            _vm._s(
                              _vm.$t(
                                "__.Components.parent.home.fastaction.fastaction_vue_html.continuos"
                              )
                            ) +
                            "\n                            "
                        ),
                      ]),
                    ]),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            !_vm.isFracionamento &&
            _vm.VALIDAR_PERMISSAO_USUARIO("fechamento_de_caixa", "List")
              ? _c(
                  "li",
                  {
                    class: { active: _vm.selectedMenu == "financeiro" },
                    on: {
                      click: function ($event) {
                        _vm.showMenu("financeiro")
                      },
                    },
                  },
                  [
                    _c("div", [
                      _c("i", {
                        staticClass: "fa fa-calculator fa-3x",
                        attrs: { "aria-hidden": "true" },
                      }),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(
                          "\n                                " +
                            _vm._s(
                              _vm.$t(
                                "__.Components.parent.home.fastaction.fastaction_vue_html.financeiro"
                              )
                            ) +
                            "\n                            "
                        ),
                      ]),
                    ]),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.VALIDAR_PERMISSAO_USUARIO("produtos", "list")
              ? _c(
                  "li",
                  {
                    class: { active: _vm.selectedMenu == "produtos" },
                    on: {
                      click: function ($event) {
                        _vm.showMenu("produtos")
                      },
                    },
                  },
                  [
                    _c("div", [
                      _vm.countDiff != null &&
                      _vm.countDiff.estoqueDivergencias > 0
                        ? _c("span", { staticClass: "squareCount" }, [
                            _c(
                              "span",
                              {
                                staticClass: "count",
                                attrs: { "aria-hidden": "true" },
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.countDiff.estoqueDivergencias)
                                ),
                              ]
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c("i", {
                        staticClass: "fa fa-barcode fa-3x",
                        attrs: { "aria-hidden": "true" },
                      }),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(
                          "\n                                " +
                            _vm._s(
                              _vm.$t(
                                "__.Components.parent.home.fastaction.fastaction_vue_html.produtos"
                              )
                            ) +
                            "\n                            "
                        ),
                      ]),
                    ]),
                  ]
                )
              : _vm._e(),
          ]),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "fast-action-panel" },
          [
            _vm.showOrcamentos && _vm.selectedMenu == "orcamentos"
              ? _c("fast-action-orcamentos-panel-component")
              : _vm._e(),
            _vm._v(" "),
            _vm.showVendas && _vm.selectedMenu == "vendas"
              ? _c("fast-action-vendas-panel-component")
              : _vm._e(),
            _vm._v(" "),
            _vm.showFinanceiro && _vm.selectedMenu == "financeiro"
              ? _c("fast-action-financeiro-panel-component")
              : _vm._e(),
            _vm._v(" "),
            _vm.showProdutos && _vm.selectedMenu == "produtos"
              ? _c("fast-action-produtos-panel-component")
              : _vm._e(),
            _vm._v(" "),
            _vm.showManipulacoes && _vm.selectedMenu == "manipulacao"
              ? _c("fast-action-manipulacao-panel-component")
              : _vm._e(),
            _vm._v(" "),
            _vm.showFracionamento && _vm.selectedMenu == "fracionamento"
              ? _c("fast-Action-fracionamento-component")
              : _vm._e(),
            _vm._v(" "),
            _vm.showEstoqueFracionamento &&
            _vm.selectedMenu == "estoqueFracionamento"
              ? _c("fast-Action-Estoque-Fracionamento-Component")
              : _vm._e(),
            _vm._v(" "),
            _vm.showContinuos && _vm.selectedMenu == "continuos"
              ? _c("fast-action-continuos-component")
              : _vm._e(),
            _vm._v(" "),
            _vm.showEntregas && _vm.selectedMenu == "entregas"
              ? _c("fast-action-entregas-component")
              : _vm._e(),
            _vm._v(" "),
            _vm.showExpedicao && _vm.selectedMenu == "expedicao"
              ? _c("fast-action-expedicao-component")
              : _vm._e(),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }