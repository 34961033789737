var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "crud crud-edit" },
    [
      _c("actionBarComponent", {
        attrs: {
          showSave: !_vm.somenteConsulta,
          title: _vm.$t("__.Crud.molde.edit_vue_html.moldeEdit"),
        },
        on: { onSave: _vm.save },
      }),
      _vm._v(" "),
      _c(
        "form",
        {
          attrs: { novalidate: "novalidate" },
          on: {
            submit: function ($event) {
              $event.preventDefault()
              _vm.save()
            },
          },
        },
        [
          _c(
            "fieldset-component",
            {
              attrs: {
                title: _vm.$t("__.Crud.molde.edit_vue_html.dadosGerais"),
                collapsed: false,
              },
            },
            [
              _c("div", { attrs: { slot: "rows" }, slot: "rows" }, [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-sm-10" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("descricao"),
                            expression: "errors.first('descricao')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: {
                              for: "descricao",
                              title: _vm.$t("__.obrigatorio"),
                            },
                          },
                          [
                            _c("span", { staticStyle: { color: "red" } }, [
                              _vm._v("*"),
                            ]),
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Crud.molde.edit_vue_html.descricao"
                                  )
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.model.descricao,
                              expression: "model.descricao",
                            },
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required|max:256",
                              expression: "'required|max:256'",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            autocomplete: "off",
                            type: "text",
                            id: "descricao",
                            name: "descricao",
                            disabled: _vm.somenteConsulta,
                          },
                          domProps: { value: _vm.model.descricao },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.model,
                                "descricao",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-2" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("ativo"),
                            expression: "errors.first('ativo')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "ativo" },
                          },
                          [
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t("__.Crud.molde.edit_vue_html.ativo")
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("checkbox-component", {
                          attrs: {
                            id: "ativo",
                            name: "ativo",
                            disabled: _vm.somenteConsulta,
                          },
                          model: {
                            value: _vm.model.ativo,
                            callback: function ($$v) {
                              _vm.$set(_vm.model, "ativo", $$v)
                            },
                            expression: "model.ativo",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-sm-3" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("unMeddescricao"),
                            expression: "errors.first('unMeddescricao')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "unMeddescricao" },
                          },
                          [
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t("__.Crud.molde.edit_vue_html.unMedida")
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("input", {
                          staticClass: "form-control",
                          attrs: {
                            type: "text",
                            disabled: "",
                            id: "unMeddescricao",
                            name: "unMeddescricao",
                            value: "Mililitro - ml",
                          },
                        }),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-2" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("volumeInterno"),
                            expression: "errors.first('volumeInterno')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: {
                              for: "volumeInterno",
                              title: _vm.$t("__.obrigatorio"),
                            },
                          },
                          [
                            _c("span", { staticStyle: { color: "red" } }, [
                              _vm._v("*"),
                            ]),
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Crud.molde.edit_vue_html.volInterno"
                                  )
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("decimal-component", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required|maior_que_zero",
                              expression: "'required|maior_que_zero'",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            id: "volumeInterno",
                            name: "volumeInterno",
                            disabled: _vm.somenteConsulta,
                          },
                          model: {
                            value: _vm.model.volumeInterno,
                            callback: function ($$v) {
                              _vm.$set(_vm.model, "volumeInterno", $$v)
                            },
                            expression: "model.volumeInterno",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-2" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("volumeTotal"),
                            expression: "errors.first('volumeTotal')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: {
                              for: "volumeTotal",
                              title: _vm.$t("__.obrigatorio"),
                            },
                          },
                          [
                            _c("span", { staticStyle: { color: "red" } }, [
                              _vm._v("*"),
                            ]),
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t("__.Crud.molde.edit_vue_html.volTotal")
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("decimal-component", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required|maior_que_zero",
                              expression: "'required|maior_que_zero'",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            id: "volumeTotal",
                            name: "volumeTotal",
                            disabled: _vm.somenteConsulta,
                          },
                          model: {
                            value: _vm.model.volumeTotal,
                            callback: function ($$v) {
                              _vm.$set(_vm.model, "volumeTotal", $$v)
                            },
                            expression: "model.volumeTotal",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-2" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first(
                              "volumeMaximoPrincipioAtivo"
                            ),
                            expression:
                              "errors.first('volumeMaximoPrincipioAtivo')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: {
                              for: "volumeMaximoPrincipioAtivo",
                              title: _vm.$t("__.obrigatorio"),
                            },
                          },
                          [
                            _c("span", { staticStyle: { color: "red" } }, [
                              _vm._v("*"),
                            ]),
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Crud.molde.edit_vue_html.volMaxPrinciAtivo"
                                  )
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("decimal-component", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required|maior_que_zero",
                              expression: "'required|maior_que_zero'",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            id: "volumeMaximoPrincipioAtivo",
                            name: "volumeMaximoPrincipioAtivo",
                            disabled: _vm.somenteConsulta,
                          },
                          model: {
                            value: _vm.model.volumeMaximoPrincipioAtivo,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.model,
                                "volumeMaximoPrincipioAtivo",
                                $$v
                              )
                            },
                            expression: "model.volumeMaximoPrincipioAtivo",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-3" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first(
                              "volumeMaximoPrincipioAtivoFitoterapico"
                            ),
                            expression:
                              "errors.first('volumeMaximoPrincipioAtivoFitoterapico')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: {
                              for: "volumeMaximoPrincipioAtivoFitoterapico",
                              title: _vm.$t("__.obrigatorio"),
                            },
                          },
                          [
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Crud.molde.edit_vue_html.volMaxPrinciAtivoFito"
                                  )
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("decimal-component", {
                          staticClass: "form-control",
                          attrs: {
                            id: "volumeMaximoPrincipioAtivoFitoterapico",
                            name: "volumeMaximoPrincipioAtivoFitoterapico",
                            disabled: _vm.somenteConsulta,
                          },
                          model: {
                            value:
                              _vm.model.volumeMaximoPrincipioAtivoFitoterapico,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.model,
                                "volumeMaximoPrincipioAtivoFitoterapico",
                                $$v
                              )
                            },
                            expression:
                              "model.volumeMaximoPrincipioAtivoFitoterapico",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-sm-6" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("observacao"),
                            expression: "errors.first('observacao')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "observacao" },
                          },
                          [
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t("__.Crud.molde.edit_vue_html.obs")
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("textarea-component", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "max:256",
                              expression: "'max:256'",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            id: "observacao",
                            name: "observacao",
                            disabled: _vm.somenteConsulta,
                          },
                          model: {
                            value: _vm.model.observacao,
                            callback: function ($$v) {
                              _vm.$set(_vm.model, "observacao", $$v)
                            },
                            expression: "model.observacao",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-6" }, [
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "observacao" },
                          },
                          [
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Crud.molde.edit_vue_html.qspPadrao"
                                  )
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("combo-component", {
                          attrs: {
                            id: "produtoQSPId",
                            name: "produtoQSPId",
                            data: _vm.produtosOptions,
                            searchable: true,
                            disabled: _vm.somenteConsulta,
                          },
                          model: {
                            value: _vm.model.produtoQSPId,
                            callback: function ($$v) {
                              _vm.$set(_vm.model, "produtoQSPId", $$v)
                            },
                            expression: "model.produtoQSPId",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "fieldset-component",
            {
              attrs: {
                title: _vm.$t("__.Crud.molde.edit_vue_html.frmFarmaceu"),
              },
            },
            [
              _c(
                "div",
                { attrs: { slot: "rows" }, slot: "rows" },
                [
                  !_vm.somenteConsulta
                    ? _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-sm-12" }, [
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "control-label",
                                  attrs: { for: "formaFarmaceuticaId" },
                                },
                                [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(
                                        _vm.$t(
                                          "__.Crud.molde.edit_vue_html.frmsFarmaceus"
                                        )
                                      ) +
                                      "\n                            "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("combo-component", {
                                attrs: {
                                  id: "formaFarmaceuticaId",
                                  name: "formaFarmaceuticaId",
                                  data: _vm.formasFarmaceuticaOptions,
                                  searchable: true,
                                  refresh: _vm.loadFormasFarmaceutica,
                                },
                                model: {
                                  value: _vm.model.formaFarmaceuticaId,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.model,
                                      "formaFarmaceuticaId",
                                      $$v
                                    )
                                  },
                                  expression: "model.formaFarmaceuticaId",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.somenteConsulta
                    ? _c("buttonIncluirComponent", {
                        on: { click: _vm.onInclusaoFormaFarmaceutica },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("grid-component", {
                    attrs: {
                      data: _vm.model.formasFarmaceuticas,
                      columns: _vm.gridColumnsFormaFarmaceutica,
                      "show-table-head": false,
                      "show-table-foot": false,
                      "show-edit-item": false,
                      showActionColumn: !_vm.somenteConsulta,
                    },
                    on: { "remove-item": _vm.onRemoveFormaFarmaceutica },
                  }),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c("fieldset", [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-sm-12 newbtn" }, [
                _c("div", { staticClass: "form-group" }, [
                  !_vm.somenteConsulta
                    ? _c(
                        "button",
                        {
                          staticClass: "btn btn-success",
                          attrs: { type: "submit" },
                        },
                        [
                          _c("i", {
                            staticClass: "fa fa-save",
                            attrs: { "aria-hidden": "true" },
                          }),
                          _vm._v(
                            "\n                            " +
                              _vm._s(_vm.$t("__.Crud.salvar")) +
                              "\n                        "
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.somenteConsulta
                    ? _c(
                        "button",
                        {
                          staticClass: "btn btn-secondary",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              _vm.load()
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n                            " +
                              _vm._s(_vm.$t("__.Crud.limpar")) +
                              "\n                        "
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-secondary",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          _vm.cancel()
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n                            " +
                          _vm._s(_vm.$t("__.Crud.voltar")) +
                          "\n                        "
                      ),
                    ]
                  ),
                ]),
              ]),
            ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }