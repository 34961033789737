var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "crud crud-list" },
    [
      _c("div", { staticClass: "info-div" }, [
        _c("h1", { staticClass: "title" }, [
          _vm._v(
            _vm._s(
              _vm.$t(
                "__.Crud.pontuacaoManualDotz.list_vue_html.pontuacaoManual"
              )
            )
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "content" }, [
          _c("div", { staticClass: "info-panel" }, [
            _c("span", [_vm._v(_vm._s(_vm.total))]),
            _vm._v(" "),
            _c("span", [
              _vm._v(
                _vm._s(
                  _vm.$t(
                    "__.Crud.pontuacaoManualDotz.list_vue_html.pontuacoesManuais"
                  )
                )
              ),
            ]),
            _vm._v(" "),
            _c("span", [
              _vm._v(_vm._s(_vm.$t("__.Crud.banco.list_vue_html.cadastrados"))),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "grid-component",
        {
          ref: "gridcomponent",
          attrs: {
            columns: _vm.gridColumns,
            data: _vm.gridData,
            "page-index": _vm.pageIndex,
            "page-size": _vm.pageSize,
            startFilterKey: _vm.startFilterKey,
            tituloPagina: "Pontuação manual",
            total: _vm.total,
            extraActions: _vm.extraActions,
            showTools: false,
            showEditItem: false,
            showAddNewButton: false,
          },
          on: {
            "remove-item": _vm.onRemove,
            "change-filter-key": _vm.onChangeFilterKey,
            "change-page": _vm.onChangePage,
            "change-sort": _vm.onChangeSort,
            "extra-action": _vm.onExtraAction,
          },
        },
        [
          _c(
            "div",
            {
              attrs: { slot: "footer-extra-options" },
              slot: "footer-extra-options",
            },
            [
              _c(
                "button",
                {
                  staticClass: "btn btn-primary",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      _vm.onGerarPontuacao()
                    },
                  },
                },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(
                        _vm.$t(
                          "__.Crud.pontuacaoManualDotz.list_vue_html.gerarPontuacao"
                        )
                      ) +
                      "\n            "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-primary",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      _vm.onEnviarPontuacao()
                    },
                  },
                },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(
                        _vm.$t(
                          "__.Crud.pontuacaoManualDotz.list_vue_html.enviarPontuacao"
                        )
                      ) +
                      "\n            "
                  ),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "grid-footer",
              staticStyle: { display: "flex", "justify-content": "start" },
              attrs: { slot: "footer-extra-options" },
              slot: "footer-extra-options",
            },
            [
              _c("div", [
                _vm._v(
                  "Total de pontos da página: " +
                    _vm._s(this.pontuacaoDotzTotal)
                ),
              ]),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _c("vendasPontuadasListComponent", {
        ref: "vendasPontuadasListComponent",
      }),
      _vm._v(" "),
      _c(
        "shortcutComponent",
        {
          ref: "shortcutComponent",
          attrs: { showAddNew: false, "modal-class": "modal-dialog" },
          on: { "selection-item": _vm.onConfirm },
        },
        [
          _c("div", { attrs: { slot: "component" }, slot: "component" }, [
            _c("div", { staticClass: "rows" }, [
              _c("form", { attrs: { novalidate: "novalidate" } }, [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-sm-12" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("periodo"),
                            expression: "errors.first('periodo')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: {
                              for: "periodo",
                              title: _vm.$t("__.obrigatorio"),
                            },
                          },
                          [
                            _c("span", { staticStyle: { color: "red" } }, [
                              _vm._v("*"),
                            ]),
                            _vm._v(
                              "\n                                    " +
                                _vm._s(_vm.$t("__.ts.periodo")) +
                                "\n                                "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("dateTimePickerComponent", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required",
                              expression: "'required'",
                            },
                          ],
                          attrs: {
                            id: "periodo",
                            name: "periodo",
                            onlyPickMonth: true,
                          },
                          model: {
                            value: _vm.periodo,
                            callback: function ($$v) {
                              _vm.periodo = $$v
                            },
                            expression: "periodo",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }