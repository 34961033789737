import dataTooltipComponent from "@/components/child/form/datatooltip.vue";
import gridComponent from "@/components/child/grid/grid.vue";
import { GridColumn, GridColumnType } from "@/components/child/grid/gridColumn";
import loadingModalComponent from "@/components/child/loadingmodal/loadingmodal.vue";
import ShortcutComponent from "@/components/parent/shortcut/shortcut";
import shortcutComponent from "@/components/parent/shortcut/shortcut.vue";
import { Component, Prop } from "@/decorators";
import FranquiaModel from "@/models/franquiaModel";
import PaginationModel from "@/models/paginationModel";
import FranquiaService from "@/services/franquiaService";

import ListComponentBase from "../listComponentBase";
import "../crud.scss";

@Component({
    components: {
        loadingModalComponent,
        shortcutComponent,
        dataTooltipComponent,
        gridComponent,
    },
})
export default class ReplicarSelecaoFranquiaFiliaisComponent extends ListComponentBase<FranquiaModel> {
    private shortcutComponent: ShortcutComponent = null;
    private showFranquias = false;
    private franquiaService = new FranquiaService();

    @Prop({ type: Boolean, default: false }) showFiliais: boolean;

    gridData: Array<FranquiaModel> = [];
    gridSelectedValues: Array<FranquiaModel> = [];
    get gridColumns(): Array<GridColumn> {
        return [
            new GridColumn("nomeFantasia", "Nome Fantasia", GridColumnType.String),
            new GridColumn("cnpj", "Cnpj", GridColumnType.String),
        ];
    }

    private onConfirm() {
        if (this.gridSelectedValues.length > 0) {
            this.$emit(
                "replicar-filiais",
                this.gridSelectedValues.map(p => p.id),
            );
            this.shortcutComponent.hide();
        } else {
            this.shortcutComponent.hide();
        }
    }

    public openFranquias() {
        if (!this.showFranquias) this.load();

        this.showFranquias = true;
        this.shortcutComponent.show();
    }

    public async loadMethod() {
        this.shortcutComponent = this.$refs.shortcutComponent as ShortcutComponent;
        this.shortcutComponent.title = this.$t("__.ts.selecFranqFiliais").toString();

        return await this.franquiaService
            .listFranquiasFiliais(
                this.gridFilterKey,
                this.gridSortKey,
                this.gridSortOrder,
                this.pags ? 1 : this.pageIndex,
                this.pags ? 999999 : this.pageSize,
                this.showFiliais,
            )
            .withLoading()
            .resolveWithJSON<PaginationModel<FranquiaModel>>();
    }

    private onSelectedValuesChanged(values: Array<FranquiaModel>) {
        this.gridSelectedValues = values;
    }
}
