enum TiposCalculoManipulacao {
    Nenhum = 0,
    Unitario = 1,
    Percentual = 2,
    Qsp = 3,
    Dose = 4,
    UTRPercentual = 5,
    DH = 6,
    CH = 7,
}

export default TiposCalculoManipulacao;
