import BaseModel from "../base/baseModel";

import CampanhaModel from "./campanhaModel";

export default class CampanhaPontuacaoModel extends BaseModel {
    id?: number = null;
    cpfCnpj = "";
    pontos = 0;
    campanhaId = 0;
    campanha = new CampanhaModel();
}
