import VendaEntregaModel from "@/models/vendaEntregaModel";
import nfetch from "@/utils/events/nfetch";

import { IService } from "../base/iService";

export default class VendaEntregaService extends IService<VendaEntregaModel> {
    constructor() {
        super("VendaEntrega");
    }

    public listByVenda(id: number): Promise<Response> {
        return nfetch(`/${this.controllerName}/ListByVenda?id=${id}`, {
            credentials: "same-origin",
        });
    }

    public upsertByVenda(vendaId: number, lista: Array<VendaEntregaModel>): Promise<Response> {
        return nfetch(`/${this.controllerName}/UpsertByVenda?vendaId=${vendaId}`, {
            method: "POST",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(lista),
        });
    }

    public has3TentativasEntregaByClienteId(clienteId: number): Promise<Response> {
        return nfetch(`/${this.controllerName}/Has3TentativasEntregaByClienteId?clienteId=${clienteId}`, {
            credentials: "same-origin",
        });
    }
}
