var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "crud crud-list ajusteAcoesPesquisar" },
    [
      !_vm.CalledByShortCut
        ? _c("div", { staticClass: "info-div" }, [
            _c("h1", { staticClass: "title" }, [
              _vm._v(_vm._s(_vm.$t("__.ts.orcamentos"))),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "content" }, [
              _c("div", { staticClass: "info-panel" }, [
                _c("span", [_vm._v(_vm._s(_vm.total))]),
                _vm._v(" "),
                _c("span", [_vm._v(_vm._s(_vm.$t("__.ts.orcamentos")))]),
                _vm._v(" "),
                _c("span", [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        "__.Components.parent.venda.listOrcamento_vue_html.cadastrados"
                      )
                    )
                  ),
                ]),
              ]),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "grid-component",
        {
          ref: "gridcomponent",
          attrs: {
            data: _vm.gridData,
            tituloPagina: "Orçamento",
            columns: _vm.gridColumns,
            subColumns: _vm.gridColumnsVinculos,
            subDataName: "itens",
            showExpand: true,
            conditionShowSubData: function (p) {
              return p.itens.length > 0
            },
            "page-index": _vm.pageIndex,
            "page-size": _vm.pageSize,
            controlaPageSize: true,
            total: _vm.total,
            showEditItem: false,
            showRemoveItem: false,
            startFilterKey: _vm.startFilterKey,
            showAddNewButton: !_vm.CalledByShortCut,
            extraActions: _vm.extraActions,
            actionName: "vendas",
          },
          on: {
            "gerar-xls": function ($event) {
              _vm.pags = true
              _vm.tipoDocGerar = 0
            },
            "gerar-pdf": function ($event) {
              _vm.pags = true
              _vm.tipoDocGerar = 1
            },
            "gerar-impressao": function ($event) {
              _vm.pags = true
              _vm.tipoDocGerar = 2
            },
            "change-filter-key": _vm.onChangeFilterKey,
            "change-sort": _vm.onChangeSort,
            "create-item": _vm.onCreateItem,
            "change-page": _vm.onChangePage,
            "change-page-size": _vm.onChangePageSize,
            "extra-action": _vm.onExtraAction,
          },
        },
        [
          _c("div", { attrs: { slot: "header" }, slot: "header" }, [
            _c("div", { staticClass: "flex-row" }, [
              _c(
                "div",
                { staticClass: "tipoBuscaVenda" },
                [
                  _c(
                    "label",
                    {
                      staticClass: "control-label",
                      attrs: { for: "tipoBuscaVenda" },
                    },
                    [
                      _vm._v(
                        "\n                        " +
                          _vm._s(_vm.$t("__.ts.buscarEm")) +
                          "\n                    "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("combo-component", {
                    attrs: {
                      data: _vm.tipoBuscaVendaOptions,
                      searchable: true,
                      id: "tipoBuscaVenda",
                      name: "tipoBuscaVenda",
                    },
                    model: {
                      value: _vm.tipoBuscaVenda,
                      callback: function ($$v) {
                        _vm.tipoBuscaVenda = $$v
                      },
                      expression: "tipoBuscaVenda",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "filtro-lista" }, [
                _c(
                  "button",
                  {
                    staticClass: "filtro-avancado-button",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        _vm.isHidden = !_vm.isHidden
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n                        " +
                        _vm._s(_vm.$t("__.Crud.filtroAvancado")) +
                        "\n                        "
                    ),
                    _c("i", { staticClass: "fa fa-caret-down" }),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.isHidden,
                        expression: "isHidden",
                      },
                    ],
                    staticClass: "drop-lista",
                  },
                  [
                    _c(
                      "form",
                      {
                        attrs: { novalidate: "novalidate" },
                        on: {
                          submit: function ($event) {
                            $event.preventDefault()
                            return _vm.getFormValues($event)
                          },
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "radio-group",
                            staticStyle: { "margin-bottom": "15px" },
                          },
                          [
                            _c("div", { staticStyle: { display: "table" } }, [
                              _c("label", [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.radioFilter,
                                      expression: "radioFilter",
                                    },
                                  ],
                                  attrs: {
                                    name: "optradio",
                                    type: "radio",
                                    value: "0",
                                  },
                                  domProps: {
                                    checked: _vm._q(_vm.radioFilter, "0"),
                                  },
                                  on: {
                                    change: function ($event) {
                                      _vm.radioFilter = "0"
                                    },
                                  },
                                }),
                                _vm._v(" "),
                                _c("span", [
                                  _vm._v(
                                    "\n                                            " +
                                      _vm._s(
                                        _vm.$t(
                                          "__.Components.parent.venda.listOrcamento_vue_html.orcamentos"
                                        )
                                      ) +
                                      "\n                                        "
                                  ),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c("label", [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.radioFilter,
                                      expression: "radioFilter",
                                    },
                                  ],
                                  attrs: {
                                    name: "optradio",
                                    type: "radio",
                                    value: "1",
                                  },
                                  domProps: {
                                    checked: _vm._q(_vm.radioFilter, "1"),
                                  },
                                  on: {
                                    change: function ($event) {
                                      _vm.radioFilter = "1"
                                    },
                                  },
                                }),
                                _vm._v(" "),
                                _c("span", [
                                  _vm._v(
                                    "\n                                            " +
                                      _vm._s(
                                        _vm.$t(
                                          "__.Components.parent.venda.listOrcamento_vue_html.rejeitados"
                                        )
                                      ) +
                                      "\n                                        "
                                  ),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c("label", [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.radioFilter,
                                      expression: "radioFilter",
                                    },
                                  ],
                                  attrs: {
                                    name: "optradio",
                                    type: "radio",
                                    value: "2",
                                  },
                                  domProps: {
                                    checked: _vm._q(_vm.radioFilter, "2"),
                                  },
                                  on: {
                                    change: function ($event) {
                                      _vm.radioFilter = "2"
                                    },
                                  },
                                }),
                                _vm._v(" "),
                                _c("br"),
                                _vm._v(" "),
                                _c("span", [
                                  _vm._v(
                                    "\n                                            " +
                                      _vm._s(
                                        _vm.$t(
                                          "__.Components.parent.venda.listOrcamento_vue_html.ambos"
                                        )
                                      ) +
                                      "\n                                        "
                                  ),
                                ]),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("br"),
                            _vm._v(" "),
                            _c("b", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "__.Components.parent.venda.listOrcamento_vue_html.de"
                                  )
                                )
                              ),
                            ]),
                            _vm._v(" "),
                            _c("date-time-picker-component", {
                              staticStyle: { width: "100%" },
                              attrs: { id: "dataFilter1", name: "dataFilter1" },
                              model: {
                                value: _vm.dataFilter1,
                                callback: function ($$v) {
                                  _vm.dataFilter1 = $$v
                                },
                                expression: "dataFilter1",
                              },
                            }),
                            _vm._v(" "),
                            _c("b", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "__.Components.parent.venda.listOrcamento_vue_html.ate"
                                  )
                                )
                              ),
                            ]),
                            _vm._v(" "),
                            _c("date-time-picker-component", {
                              attrs: { id: "dataFilter2", name: "dataFilter2" },
                              model: {
                                value: _vm.dataFilter2,
                                callback: function ($$v) {
                                  _vm.dataFilter2 = $$v
                                },
                                expression: "dataFilter2",
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "label",
                              {
                                staticClass: "control-label",
                                attrs: { for: "vendedor" },
                              },
                              [
                                _vm._v(
                                  "\n                                    " +
                                    _vm._s(
                                      _vm.$t(
                                        "__.Crud.controleReclamacao.edit_vue_html.vendedor"
                                      )
                                    ) +
                                    "\n                                "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("combo-component", {
                              staticStyle: { background: "white" },
                              attrs: {
                                id: "vendedor",
                                name: "vendedor",
                                data: _vm.usuariosOptions,
                                defaultValue: -1,
                              },
                              model: {
                                value: _vm.vendedor,
                                callback: function ($$v) {
                                  _vm.vendedor = $$v
                                },
                                expression: "vendedor",
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "label",
                              {
                                staticClass: "control-label",
                                attrs: { for: "produto" },
                              },
                              [
                                _vm._v(
                                  "\n                                    " +
                                    _vm._s(
                                      _vm.$t(
                                        "__.Components.parent.venda.item_vue_html.produto"
                                      )
                                    ) +
                                    ":\n                                "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("combo-component", {
                              staticStyle: { background: "white" },
                              attrs: {
                                id: "produto",
                                name: "produto",
                                data: _vm.produtosOptions,
                                defaultValue: -1,
                              },
                              model: {
                                value: _vm.produto,
                                callback: function ($$v) {
                                  _vm.produto = $$v
                                },
                                expression: "produto",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "form-group" }, [
                          _c(
                            "button",
                            {
                              staticClass: "botao-filtrar",
                              attrs: { type: "submit" },
                            },
                            [
                              _vm._v(
                                "\n                                    " +
                                  _vm._s(_vm.$t("__.Crud.filtrar")) +
                                  "\n                                "
                              ),
                            ]
                          ),
                        ]),
                      ]
                    ),
                  ]
                ),
              ]),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "shortcutComponent",
        {
          ref: "shortCutRevisaoCampos",
          attrs: {
            btOkName: _vm.$t(
              "__.Components.parent.venda.listOrcamento_vue_html.confirmAlteracoes"
            ),
            showAddNew: false,
          },
          on: { "selection-item": _vm.onConfirmRevisao },
        },
        [
          _vm.loadRevisaoComponent
            ? _c(
                "div",
                { attrs: { slot: "component" }, slot: "component" },
                [
                  _c("revisao-campos-component", {
                    ref: "revisaoCamposComponent",
                    attrs: { clienteId: _vm.model.clienteId },
                  }),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _c("arquivar-orcamento-component", {
        ref: "arquivarOrcamentoComponent",
        on: { "orcamento-arquivado": _vm.onOrcamentoArquivado },
      }),
      _vm._v(" "),
      _c("impressaoComponent", { ref: "impressaoComponent" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }