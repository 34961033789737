var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("label", { staticClass: "checkbox-component" }, [
    _c("input", {
      attrs: {
        autocomplete: "off",
        type: "checkbox",
        id: _vm.id,
        name: _vm.name,
        disabled: _vm.disabled,
      },
      domProps: { checked: _vm.value },
      on: {
        change: function ($event) {
          _vm.onChange($event.target.checked)
        },
      },
    }),
    _vm._v(" "),
    _c("div", { staticClass: "slider", class: { disabled: _vm.disabled } }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }