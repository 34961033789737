import ModeloImpressaoCamposModel from "../models/modeloImpressao/modeloImpressaoCamposModel";
import ModeloImpressaoSessaoModel from "../models/modeloImpressao/modeloImpressaoSessaoModel";
import nfetch from "../utils/events/nfetch";

import { IService } from "./base/iService";



export default class ModeloImpressaoSessaolService extends IService<ModeloImpressaoSessaoModel> {
    constructor() {
        super("ModeloImpressaoSessao");
    }
    public updateWithDeletedCampos(model: ModeloImpressaoSessaoModel, IdsCamposDeletar: Array<string>): Promise<Response> {
        let idsCamposParemeter = "";

        for (const id of IdsCamposDeletar)
        {
            if (idsCamposParemeter.trim() == "")
                idsCamposParemeter += "idsCamposDeletar=" + id;
            else
                idsCamposParemeter += "&idsCamposDeletar=" + id;
        }

       //@ts-ignore
        return nfetch(`/${this.controllerName}/UpdateWithDeletedCampos?${idsCamposParemeter}`, {
            method: "post",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json; charset=UTF-8",
                "XSRF-TOKEN": this.getCookie("XSRF-TOKEN")
            },
            body: JSON.stringify(model)
        }).then((response: Response) => {
            if (response.ok) {
                return response;
            } else {
                return this.mensagemErro(response, "");
            }
        }).catch((error) => {
        });
    }
}