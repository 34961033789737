import Vue from "vue";

import Grid from "@/components/child/grid/grid";
import gridComponent from "@/components/child/grid/grid.vue";
import { GridColumn, GridColumnType } from "@/components/child/grid/gridColumn";
import { Component, Prop } from "@/decorators";
import FormulaPadraoValoresProdutoModel from "@/models/formulaPadraoValoresProdutoModel";
import FormulaPadraoService from "@/services/formulaPadraoService";

import "../crud.scss";

@Component({
    components: {
        gridComponent,
    },
})
export default class ProdutoLogFinanceiroListComponent extends Vue {
    private service = new FormulaPadraoService();
    private gridcomponent: Grid = null;

    @Prop({ type: Boolean, default: false }) CalledByShortCut: boolean;

    private hideExtras = false;
    isHidden = true;
    private pageIndex = 1;
    private pageSize = 999999;

    radioFilter = 0;

    modelIds: Array<number> = [];
    total = 0;
    gridSelectedValues: Array<FormulaPadraoValoresProdutoModel> = [];
    startFilterKey = "";
    gridFilterKey = "";
    gridSortKey = "datacriacao";
    gridSortOrder = "desc";
    formulasCalculadas: Array<FormulaPadraoValoresProdutoModel> = [];
    gridData: Array<FormulaPadraoValoresProdutoModel> = [];
    get gridColumns(): Array<GridColumn> {
        return [
            new GridColumn("descricao", "Formula", GridColumnType.String),
            new GridColumn("produtoDescricao", "Produto", GridColumnType.String),
            new GridColumn("markup", "Markup", GridColumnType.Percent),
            new GridColumn("valorCusto", "Custo Calculado", GridColumnType.Money4Digits, true),
            new GridColumn("custoReferenciaProduto", "Referência Atual", GridColumnType.Money4Digits),
            new GridColumn("valorVenda", "Valor Venda Calculado", GridColumnType.Money4Digits, true),
            new GridColumn("valorVendaProduto", "Valor Venda Atual", GridColumnType.Money4Digits),
        ];
    }

    private onChangeFilterKey(filterKey: string) {
        this.gridFilterKey = filterKey;
    }

    private close() {
        this.$emit("close");
    }

    private async load() {
        const apenasNumeros = this.modelIds.filter(item => typeof item === "number" && !isNaN(item));
        if (apenasNumeros.length > 0) {
            try {
                const data = await this.service
                    .recalcularValoresProdutosFormulas(apenasNumeros.toString())
                    .withLoading()
                    .resolveWithJSON<FormulaPadraoValoresProdutoModel[]>();
                this.total = data.length;
                this.formulasCalculadas = data;
                this.formulasCalculadas.forEach(item => {
                    const formulaCalc = new FormulaPadraoValoresProdutoModel();
                    formulaCalc.updateFrom(item);
                    this.gridData.push(formulaCalc);
                });
            } catch {}
        } else {
            await this.$showWarning(this.$t("__.ts.recalcValores"), this.$t("__.ts.nenhumRegisSelec"));
            this.$router.back();
        }
    }

    private atualizarValorProduto() {
        if (this.gridSelectedValues.length > 0) {
            try {
                const sucesso = this.service
                    .atualizarValoresProduto(this.gridSelectedValues)
                    .withLoading()
                    .resolveWithoutJSON();
                if (sucesso) {
                    this.$showSuccess(this.$t("__.ts.atualizacao"), this.$t("__.ts.acaoRealizSucess"));
                    this.$router.back();
                }
            } catch {}
        } else {
            this.$showWarning(this.$t("__.ts.recalcValores"), this.$t("__.ts.nenhumRegisSelec"));
        }
    }

    private onSelectedValuesChanged(values: Array<FormulaPadraoValoresProdutoModel>) {
        this.gridSelectedValues = values;
    }

    private getFormValues() {
        this.gridData = [];

        if (this.gridSelectedValues.length > 0) {
            const checkBoxs = this.$el.querySelectorAll(
                "tr > td > input[type='checkbox']",
            ) as NodeListOf<HTMLInputElement>;

            for (let i = 0; i < checkBoxs.length; i++) {
                checkBoxs[i].checked = false;
            }
            const checkAll = this.$el.querySelector("tr > th > input[type='checkbox']") as HTMLInputElement;
            if (checkAll != null) {
                checkAll.checked = false;
            }
        }

        this.gridSelectedValues = [];
        switch (+this.radioFilter) {
            case 0:
                this.formulasCalculadas.forEach(item => {
                    const formulaCalc = new FormulaPadraoValoresProdutoModel();
                    formulaCalc.updateFrom(item);
                    this.gridData.push(formulaCalc);
                });

                break;
            case 1:
                this.formulasCalculadas
                    .filter(p => p.hasDifference)
                    .forEach(item => {
                        const formulaCalc = new FormulaPadraoValoresProdutoModel();
                        formulaCalc.updateFrom(item);
                        this.gridData.push(formulaCalc);
                    });
                break;
            case 2:
                this.formulasCalculadas
                    .filter(p => p.hasDifference && p.custoReferenciaProduto < p.custoReferencia)
                    .forEach(item => {
                        const formulaCalc = new FormulaPadraoValoresProdutoModel();
                        formulaCalc.updateFrom(item);
                        this.gridData.push(formulaCalc);
                    });
                break;
        }
        this.isHidden = true;
    }

    private changeValues(newValue, index, collumName) {
        const formula = this.formulasCalculadas.filter(p => p.id == this.gridData[index].id)[0];
        if (collumName == "valorCusto") {
            const oldVal = formula.valorCusto;
            if (oldVal != newValue) {
                if (this.gridData[index].tipoCalcMarkup == 0)
                    this.gridData[index].valorVenda = parseFloat(
                        (
                            this.gridData[index].valorCusto +
                            this.gridData[index].valorCusto * (this.gridData[index].markup / 100)
                        ).toFixed(2),
                    );
                else
                    this.gridData[index].valorVenda = parseFloat(
                        (this.gridData[index].valorCusto * (this.gridData[index].markup / 100)).toFixed(2),
                    );

                this.gridData[index].custoReferencia = parseFloat(this.gridData[index].valorCusto.toFixed(2));

                if (this.gridData[index].custoMedio == 0 || this.gridData[index].custoMedio == null)
                    this.gridData[index].custoMedio = +newValue;
                else {
                    this.gridData[index].custoMedio = parseFloat(
                        (+newValue + this.gridData[index].custoMedio / 2).toFixed(2),
                    );
                }

                //antes de atualizar o formula
                this.gridData[index].hasDifference =
                    formula.valorCusto != <number>newValue ||
                    formula.custoMedio != parseFloat(this.gridData[index].custoMedio.toFixed(2)) ||
                    formula.custoReferencia != <number>newValue ||
                    formula.valorVenda != parseFloat(this.gridData[index].valorVenda.toFixed(2));
                formula.hasDifference = this.gridData[index].hasDifference;
                //atualiza para se fizer filtro, busca o alterado e nao o antigo
                formula.valorCusto = <number>newValue;
                formula.custoReferencia = <number>newValue;
                formula.custoMedio = parseFloat(this.gridData[index].custoMedio.toFixed(2));
                formula.valorVenda = parseFloat(this.gridData[index].valorVenda.toFixed(2));
            }
        } else if (collumName == "valorVenda") {
            this.gridData[index].valorVenda = +newValue;
            this.gridData[index].hasDifference =
                formula.valorCusto != this.gridData[index].valorCusto ||
                formula.custoMedio != parseFloat(this.gridData[index].custoMedio.toFixed(2)) ||
                formula.custoReferencia != this.gridData[index].custoReferencia ||
                formula.valorVenda != parseFloat(this.gridData[index].valorVenda.toFixed(2));

            formula.hasDifference = this.gridData[index].hasDifference;
            formula.valorVenda = +newValue;
        }
    }

    private mounted() {
        this.isHidden = true;
        this.gridcomponent = this.$refs.gridcomponent as Grid;
        this.hideExtras = this.CalledByShortCut ? true : false;
        this.radioFilter = 0;

        if (this.$route.params.ids) {
            this.modelIds = this.$route.params.ids.split(",").map(Number);
        }
        this.load();
    }
}
