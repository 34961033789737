import checkboxComponent from "@/components/child/form/checkbox.vue";
import comboComponent from "@/components/child/form/combo.vue";
import { Component, Prop } from "@/decorators";
import EnsaiosModel from "@/models/ensaiosModel";
import FarmacopeiaModel from "@/models/farmacopeiaModel";
import PaginationModel from "@/models/paginationModel";
import EnsaioService from "@/services/ensaioService";
import FarmacopeiaService from "@/services/farmacopeiaService";

import EditComponentBase from "../editComponentBase";

@Component({
    components: {
        checkboxComponent,
        comboComponent,
    },
})
export default class EnsaioEditComponent extends EditComponentBase<EnsaiosModel> {
    farmacopeiaOptions: Array<Object> = [];

    @Prop({ type: Boolean, default: false }) CalledByShortCut: boolean;

    private async beforeMount() {
        await this.setProps(new EnsaioService(), "ensaios", new EnsaiosModel(), this.CalledByShortCut);

        this.loadFarmacopeias();
    }

    private async loadFarmacopeias() {
        try {
            const data = await new FarmacopeiaService().combo().resolveWithJSON<PaginationModel<FarmacopeiaModel>>();
            this.farmacopeiaOptions = data.list.map(p => ({ value: p.id, text: p.descricao }));
        } catch {}
    }
}
