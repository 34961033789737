import TypeTableLines from "../enum/typeTableLines";

import BaseModel from "./../base/baseModel";
import Alinhamento from "./../enum/alinhamento";
import ModeloImpressaoCampos from "./modeloImpressaoCamposModel";

export default class ModeloImpressaoSessaoModel extends BaseModel {
    id?: number = null;
    modeloImpressaoId?: number = null;
    maxCols: number = null;
    maxRows: number = null;
    sessao: number = null;
    campos: Array<ModeloImpressaoCampos> = [];
    descricao: string = null;
    tipo: TypeElement = null;
    gridName: string = null;
    personalizado: boolean = null;
    showDescricao: boolean = null;
    typeTableLines: TypeTableLines = null;
    ativo: boolean = null;
    grupo = 0;
    alinhamento: Alinhamento = null;
    //rotulos
    maxCaracteresLinha?: number = null;
    maxLinhas?: number = null;
}

export enum TypeElement {
    Form = 1,
    Grid = 2,
    GridHorizontal = 3,
}
