import BaseModel from "./base/baseModel";

export default class MetaModel extends BaseModel {
    id?: number = null;
    valor: number = null;
    valorDiario: number = null;
    dataInicio: Date = null;
    dataFim: Date = null;
    diasUteis?: number = null;
    mesAno: string = null;
}
