import Vue from "vue";

import comboComponent from "@/components/child/form/combo.vue";
import { Component } from "@/decorators";
import RecebimentoParceladoModel from "@/models/financeiro/recebimentoParceladoModel";
import PaginationModel from "@/models/paginationModel";
import RecebimentoParceladoService from "@/services/financeiro/recebimentoParceladoService";

import "../../crud/crud.scss";

@Component({
    components: {
        comboComponent,
    },
})
export default class ParcelamentoComponent extends Vue {
    private recebimentoParceladoId: number = null;
    private recebimentoParceladoOptions = [];

    public getMetodoRecebimento(): RecebimentoParceladoModel {
        return this.recebimentoParceladoOptions.filter(p => p["value"] == this.recebimentoParceladoId)[0];
    }

    private async loadRecebimentoParcelado() {
        try {
            const data = await new RecebimentoParceladoService()
                .combo()
                .resolveWithJSON<PaginationModel<RecebimentoParceladoModel>>();

            this.recebimentoParceladoOptions = data.list
                .filter(p => p.ativo)
                .map(item => ({
                    value: item.id,
                    text: item.descricao,
                    ...item,
                }));
        } catch {}
    }

    public async validate() {
        return await this.$validator.validateAll();
    }

    private mounted() {
        this.loadRecebimentoParcelado();
    }
}
