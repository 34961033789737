var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !_vm.showIa && _vm.showTransition
        ? _c(
            "div",
            {
              staticClass: "reopen-ia",
              on: {
                click: function ($event) {
                  _vm.showIa = true
                },
              },
            },
            [
              _c("i", {
                staticClass: "fa fa-arrow-left",
                attrs: { "aria-hidden": "true" },
              }),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("transition", { attrs: { name: "slide" } }, [
        _vm.showIa
          ? _c("div", { staticClass: "open-ia" }, [
              _c("i", {
                staticClass: "fa fa-times close-iframe",
                attrs: { "aria-hidden": "true" },
                on: { click: _vm.closeOverlay },
              }),
              _vm._v(" "),
              _vm.showIa && _vm.showTransition
                ? _c("div", { staticClass: "close-ia" }, [
                    _c("i", {
                      staticClass: "fa fa-arrow-right",
                      attrs: { "aria-hidden": "true" },
                      on: {
                        click: function ($event) {
                          _vm.showIa = false
                        },
                      },
                    }),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("iframe", {
                staticStyle: { width: "100%", height: "100%" },
                attrs: {
                  src: "https://udify.app/chatbot/XwgVqyY2sSO081kL",
                  frameborder: "0",
                },
              }),
            ])
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }