enum CorManipulacao {
    Vermelho = 1,
    Roxo = 2,
    Verde = 3,
    Azul = 4,
    Laranja = 5,
    Amarelo = 6,
}

export default CorManipulacao;
