import Vue from "vue";

import $ from "jquery";
import moment from "moment";

import { Component, Prop, Watch } from "@/decorators";

import "moment/locale/pt-br";
import "bootstrap-datetime-picker/js/bootstrap-datetimepicker.js";
import "bootstrap-datetime-picker/css/bootstrap-datetimepicker.css";

import "./datetimepicker.scss";

@Component
export default class DateTimePickerComponent extends Vue {
    @Prop(String) id: string;
    @Prop(String) name: string;
    @Prop(Date) value: Date;
    @Prop({ type: Boolean, default: false }) pickTime: boolean;
    @Prop({ type: Boolean, default: false }) onlyPickMonth: boolean;
    @Prop({ type: Boolean, default: false }) disabled: boolean;
    @Prop({ type: Boolean, default: false }) gridEditable: boolean;

    private onClearButtonClick(event: Event) {
        if (!this.disabled && this.$parent.$el.getAttribute("disabled") != "disabled") {
            this.$emit("input", null);
        }

        event.stopImmediatePropagation();
    }

    @Watch("value")
    private onValueChanged() {
        $(this.$refs.datetimepicker).datetimepicker("update", this.value);
        // Quando o valor é nulo o input continua com texto, por isso é forçado um texto vazio
        if (!this.value) {
            (this.$refs.input as HTMLInputElement).value = "";
        } else if (typeof this.value === "string") {
            const valueSemMilisecond = String(this.value).split(".")[0];
            $(this.$refs.datetimepicker).datetimepicker("update", valueSemMilisecond);
        }
    }

    private changeDate() {
        let regex = new RegExp("^([1-9]|([012][0-9])|(3[01]))\\/([0]{0,1}[1-9]|1[012])\\/\\d\\d\\d\\d");
        if (this.pickTime) {
            regex = new RegExp(
                "^([1-9]|([012][0-9])|(3[01]))\\/([0]{0,1}[1-9]|1[012])\\/\\d\\d\\d\\d - [012]{0,1}[0-9]:[0-6][0-9]$",
            );
        } else if (this.onlyPickMonth) {
            regex = new RegExp("^(0?[1-9]|1[0-2])\\/\\d\\d\\d\\d");
        }

        const data = $(this.$refs.input).val();

        if (!data) {
            this.$emit("input", null);
            $(this.$refs.input).val("");
            return;
        }

        if (regex.test(data)) {
            let dateFormat = "DD/MM/YYYY";
            if (this.pickTime) {
                dateFormat = "DD/MM/YYYY - hh:mm";
            } else if (this.onlyPickMonth) {
                dateFormat = "MM/YYYY";
            }

            const date = moment(data, dateFormat);
            if (date.isValid()) {
                const valor = date.toDate();
                this.$emit("input", valor);
            } else {
                this.$notifyError(this.$t("__.ts.dataInvalida"));
                this.$emit("input", null);
                $(this.$refs.input).val("");
                return;
            }
        } else {
            $(this.$refs.datetimepicker).datetimepicker("update", this.value);
        }
    }

    private mounted() {
        $.fn.datetimepicker.dates["pt-BR"] = {
            format: "dd/mm/yyyy",
            days: ["Domingo", "Segunda", "Terça", "Quarta", "Quinta", "Sexta", "Sábado", "Domingo"],
            daysShort: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sáb", "Dom"],
            daysMin: ["Do", "Se", "Te", "Qu", "Qu", "Se", "Sa", "Do"],
            months: [
                "Janeiro",
                "Fevereiro",
                "Março",
                "Abril",
                "Maio",
                "Junho",
                "Julho",
                "Agosto",
                "Setembro",
                "Outubro",
                "Novembro",
                "Dezembro",
            ],
            monthsShort: ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez"],
            today: "Hoje",
            suffix: [],
            meridiem: [],
        };

        let format = "dd/mm/yyyy";
        if (this.pickTime) {
            format = "dd/mm/yyyy - hh:ii";
        } else if (this.onlyPickMonth) {
            format = "mm/yyyy";
        }

        let minView = 2;
        if (this.pickTime) {
            minView = 0;
        } else if (this.onlyPickMonth) {
            minView = 3;
        }

        $(this.$refs.datetimepicker)
            .datetimepicker({
                autoclose: true,
                format,
                pickTime: false,
                language: "pt-BR",
                pickerPosition: "bottom-left",
                todayBtn: true,
                minView,
                startView: this.onlyPickMonth ? 3 : 2,
            })
            .change(this.changeDate);

        $(this.$refs.input).keyup(function (e) {
            if ((e.keyCode < 48 || e.keyCode > 57) && (e.keyCode < 96 || e.keyCode > 105)) {
                $(this).val($(this).val().replace(e.key, ""));
            }

            if (e.keyCode != 8) {
                if ($(this).val().length == 2 || $(this).val().length == 5) {
                    $(this).val($(this).val() + "/");
                }
                if (this.pickTime) {
                    if ($(this).val().length == 10) {
                        $(this).val($(this).val() + " - ");
                    }
                    if ($(this).val().length == 15) {
                        $(this).val($(this).val() + ":");
                    }
                }
            }
        });

        if (this.value) {
            $(this.$refs.datetimepicker).datetimepicker("update", this.value);
            this.changeDate();
        }
    }
}
