var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "modal-component",
        {
          ref: "modalComponent",
          attrs: {
            "modal-class": "lg-modal",
            title: _vm.$t(
              "__.Crud.movimentacaoestoque.item_vue_html.dadosLote"
            ),
          },
          on: {
            "btn-ok-click": function ($event) {
              _vm.onBtnOkClick()
            },
          },
        },
        [
          _c("div", { attrs: { slot: "body" }, slot: "body" }, [
            _c("form", { attrs: { novalidate: "novalidate" } }, [
              _c("fieldset", [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-sm-6" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("id"),
                            expression: "errors.first('id')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: {
                              for: "id",
                              title: _vm.$t("__.obrigatorio"),
                            },
                          },
                          [
                            _c("span", { staticStyle: { color: "red" } }, [
                              _vm._v("*"),
                            ]),
                            _vm._v(
                              "\n                                    " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Crud.movimentacaoestoque.item_vue_html.lote"
                                  )
                                ) +
                                "\n                                "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "combo-component",
                          {
                            directives: [
                              { name: "focus", rawName: "v-focus" },
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required",
                                expression: "'required'",
                              },
                            ],
                            attrs: {
                              id: "id",
                              name: "id",
                              actionName: "lotes",
                              addEditId: _vm.model.produtoLoteId,
                              addNew: !_vm.model.produtoLoteId,
                              customView: true,
                              data: _vm.lotesOptions,
                              formAddNewName:
                                _vm.model.produtoLoteId > 0
                                  ? _vm.$t(
                                      "__.Crud.movimentacaoestoque.item_vue_html.editProdLote"
                                    )
                                  : _vm.$t(
                                      "__.Crud.movimentacaoestoque.item_vue_html.novoProdLote"
                                    ),
                              refresh: _vm.loadLotes,
                              searchable: true,
                            },
                            on: {
                              addNewItemCombo: function ($event) {
                                _vm.onAddNewProdutoLote()
                              },
                              openComboEditItem: _vm.openComboEditProdutoLote,
                              openComboNewItem: function ($event) {
                                _vm.openComboNewProdutoLote()
                              },
                            },
                            model: {
                              value: _vm.model.produtoLoteId,
                              callback: function ($$v) {
                                _vm.$set(_vm.model, "produtoLoteId", $$v)
                              },
                              expression: "model.produtoLoteId",
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                attrs: { slot: "component" },
                                slot: "component",
                              },
                              [
                                _c("produto-lote-component", {
                                  ref: "produtoLoteComponent",
                                  attrs: {
                                    CalledByShortCut: true,
                                    IdProduto: _vm.model.produtoId,
                                  },
                                  on: {
                                    "save-ok": _vm.onProdutoLoteSaveOk,
                                    "on-load-lote": _vm.onCarregaLote,
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-6" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("produtoId"),
                            expression: "errors.first('produtoId')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "produtoId" },
                          },
                          [
                            _vm._v(
                              "\n                                    " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Crud.movimentacaoestoque.item_vue_html.produto"
                                  )
                                ) +
                                "\n                                "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("combo-component", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required",
                              expression: "'required'",
                            },
                          ],
                          attrs: {
                            id: "produtoId",
                            name: "produtoId",
                            data: _vm.produtosOptions,
                            searchable: true,
                            refresh: _vm.loadProdutos,
                          },
                          model: {
                            value: _vm.model.produtoId,
                            callback: function ($$v) {
                              _vm.$set(_vm.model, "produtoId", $$v)
                            },
                            expression: "model.produtoId",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    {
                      class:
                        _vm.tipoMovimentacao ==
                        _vm.tiposMovimentacao.Transferencia
                          ? "col-sm-6"
                          : "col-sm-4",
                    },
                    [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: _vm.errors.first("quantidade"),
                              expression: "errors.first('quantidade')",
                            },
                          ],
                          staticClass: "form-group",
                        },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "control-label",
                              attrs: {
                                for: "quantidade",
                                title: _vm.$t("__.obrigatorio"),
                              },
                            },
                            [
                              _c("span", { staticStyle: { color: "red" } }, [
                                _vm._v("*"),
                              ]),
                              _vm._v(
                                "\n                                    " +
                                  _vm._s(
                                    _vm.$t(
                                      "__.Crud.movimentacaoestoque.item_vue_html.qtd"
                                    )
                                  ) +
                                  "\n                                "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("decimal-com-sinal-custom-component", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required|maior_que_zero",
                                expression: "'required|maior_que_zero'",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: {
                              signal: _vm.signal,
                              decimais: 8,
                              id: "quantidade",
                              disabled: !_vm.model.produtoLoteId,
                              name: "quantidade",
                            },
                            model: {
                              value: _vm.model.quantidade,
                              callback: function ($$v) {
                                _vm.$set(_vm.model, "quantidade", $$v)
                              },
                              expression: "model.quantidade",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            _vm.tipoMovimentacao !=
                            _vm.tiposMovimentacao.Transferencia,
                          expression:
                            "tipoMovimentacao != tiposMovimentacao.Transferencia",
                        },
                      ],
                      staticClass: "col-sm-4",
                    },
                    [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: _vm.errors.first("valorUnitario"),
                              expression: "errors.first('valorUnitario')",
                            },
                          ],
                          staticClass: "form-group",
                        },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "control-label",
                              attrs: {
                                for: "valorUnitario",
                                title: _vm.$t("__.obrigatorio"),
                              },
                            },
                            [
                              _c("span", { staticStyle: { color: "red" } }, [
                                _vm._v("*"),
                              ]),
                              _vm._v(
                                "\n                                    " +
                                  _vm._s(
                                    _vm.$t(
                                      "__.Crud.movimentacaoestoque.item_vue_html.vlrUnitario"
                                    )
                                  ) +
                                  "\n                                "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("moeda-component", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: {
                                  rules: {
                                    required: true,
                                    maior_que_zero:
                                      _vm.tipoMovimentacao !=
                                      _vm.tiposMovimentacao.Transferencia,
                                  },
                                },
                                expression:
                                  "{\n                                        rules: {\n                                            required: true,\n                                            maior_que_zero: tipoMovimentacao != tiposMovimentacao.Transferencia,\n                                        },\n                                    }",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: {
                              id: "valorUnitario",
                              name: "valorUnitario",
                              disabled: !_vm.model.produtoLoteId,
                            },
                            model: {
                              value: _vm.model.valorUnitario,
                              callback: function ($$v) {
                                _vm.$set(_vm.model, "valorUnitario", $$v)
                              },
                              expression: "model.valorUnitario",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      class:
                        _vm.tipoMovimentacao ==
                        _vm.tiposMovimentacao.Transferencia
                          ? "col-sm-6"
                          : "col-sm-4",
                    },
                    [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: _vm.errors.first("quantidadeAtual"),
                              expression: "errors.first('quantidadeAtual')",
                            },
                          ],
                          staticClass: "form-group",
                        },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "control-label",
                              attrs: { for: "quantidadeAtual" },
                            },
                            [
                              _vm._v(
                                "\n                                    " +
                                  _vm._s(
                                    _vm.$t(
                                      "__.Crud.movimentacaoestoque.item_vue_html.qtdAtual"
                                    )
                                  ) +
                                  "\n                                "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.model.produtoLote.quantidadeAtual,
                                expression: "model.produtoLote.quantidadeAtual",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: {
                              type: "text",
                              id: "quantidadeAtual",
                              name: "quantidadeAtual",
                              disabled: "",
                            },
                            domProps: {
                              value: _vm.model.produtoLote.quantidadeAtual,
                            },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.model.produtoLote,
                                  "quantidadeAtual",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]
                      ),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-sm-6" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("dataFabricacao"),
                            expression: "errors.first('dataFabricacao')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: {
                              for: "dataFabricacao",
                              title: _vm.$t("__.obrigatorio"),
                            },
                          },
                          [
                            _c("span", { staticStyle: { color: "red" } }, [
                              _vm._v("*"),
                            ]),
                            _vm._v(
                              "\n                                    " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Crud.movimentacaoestoque.item_vue_html.dataFab"
                                  )
                                ) +
                                "\n                                "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("date-time-picker-component", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required",
                              expression: "'required'",
                            },
                          ],
                          attrs: {
                            id: "dataFabricacao",
                            name: "dataFabricacao",
                            disabled: !_vm.model.produtoLoteId,
                          },
                          model: {
                            value: _vm.model.produtoLote.dataFabricacao,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.model.produtoLote,
                                "dataFabricacao",
                                $$v
                              )
                            },
                            expression: "model.produtoLote.dataFabricacao",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-6" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("dataValidade"),
                            expression: "errors.first('dataValidade')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: {
                              for: "dataValidade",
                              title: _vm.$t("__.obrigatorio"),
                            },
                          },
                          [
                            _c("span", { staticStyle: { color: "red" } }, [
                              _vm._v("*"),
                            ]),
                            _vm._v(
                              "\n                                    " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Crud.movimentacaoestoque.item_vue_html.dataVal"
                                  )
                                ) +
                                "\n                                "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("date-time-picker-component", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required",
                              expression: "'required'",
                            },
                          ],
                          attrs: {
                            id: "dataValidade",
                            name: "dataValidade",
                            disabled: !_vm.model.produtoLoteId,
                          },
                          model: {
                            value: _vm.model.produtoLote.dataValidade,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.model.produtoLote,
                                "dataValidade",
                                $$v
                              )
                            },
                            expression: "model.produtoLote.dataValidade",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-sm-12" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("observacao"),
                            expression: "errors.first('observacao')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "observacao" },
                          },
                          [
                            _vm._v(
                              "\n                                    " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Crud.movimentacaoestoque.item_vue_html.obs"
                                  )
                                ) +
                                "\n                                "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("textarea", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.model.produtoLote.observacao,
                              expression: "model.produtoLote.observacao",
                            },
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "max:1024",
                              expression: "'max:1024'",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            id: "observacao",
                            name: "observacao",
                            disabled: !_vm.model.produtoLoteId,
                          },
                          domProps: { value: _vm.model.produtoLote.observacao },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.model.produtoLote,
                                "observacao",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]
                    ),
                  ]),
                ]),
              ]),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }