var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "fast-action-grid-panel" },
    [
      _c("div", { staticClass: "fast-action-grid-panel-left marcaDagua" }, [
        _c(
          "div",
          { staticClass: "component-body" },
          [
            _c("div", { staticClass: "top-infos" }, [
              _c("span", { staticClass: "small" }, [
                _vm._v(
                  _vm._s(_vm.fastActionPack.countPack.manipulacoesAConcluir)
                ),
              ]),
              _vm._v(
                "\n                " +
                  _vm._s(
                    _vm.$t(
                      "__.Components.parent.home.fastaction.manipulacao.manipulacao_vue_html.emProcesso"
                    )
                  ) +
                  "\n                "
              ),
              _c(
                "span",
                {
                  staticClass: "small",
                  staticStyle: { "margin-left": "45px" },
                },
                [_vm._v(_vm._s(_vm.getVerificados()))]
              ),
              _vm._v(
                "\n                " +
                  _vm._s(
                    _vm.$t(
                      "__.Components.parent.home.fastaction.manipulacao.manipulacao_vue_html.verificados"
                    )
                  ) +
                  "\n            "
              ),
            ]),
            _vm._v(" "),
            _vm._l(_vm.manipulacoes, function (manipulacao) {
              return _c(
                "a",
                {
                  key: manipulacao.id,
                  staticClass: "list-group-item",
                  staticStyle: { "padding-left": "46px" },
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: { cursor: "pointer" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          _vm.onSearch(manipulacao)
                        },
                      },
                    },
                    [
                      _c("i", {
                        staticClass: "fa fa-exclamation-circle",
                        class: _vm.getClass(manipulacao),
                        attrs: { title: _vm.getTitle(manipulacao) },
                      }),
                      _vm._v(
                        "\n                    Cód:\n                    "
                      ),
                      _c("b", { staticStyle: { color: "yellow" } }, [
                        _vm._v(_vm._s(manipulacao.codigo)),
                      ]),
                      _vm._v(
                        "\n                    " +
                          _vm._s(
                            _vm.$t(
                              "__.Components.parent.home.fastaction.manipulacao.manipulacao_vue_html.formaFarm"
                            )
                          ) +
                          "\n                    "
                      ),
                      _c("b", { staticStyle: { color: "yellow" } }, [
                        _vm._v(_vm._s(manipulacao.formaFarmaceuticaDescricao)),
                      ]),
                      _vm._v(
                        "\n                    " +
                          _vm._s(
                            _vm.$t(
                              "__.Components.parent.home.fastaction.manipulacao.manipulacao_vue_html.paciente"
                            )
                          ) +
                          "\n                    "
                      ),
                      _c("b", { staticStyle: { color: "yellow" } }, [
                        _vm._v(_vm._s(manipulacao.pacienteNome)),
                      ]),
                      _vm._v(" "),
                      manipulacao.codigoVenda > 0
                        ? _c("span", [
                            _vm._v(
                              "\n                        " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Components.parent.home.fastaction.manipulacao.manipulacao_vue_html.codVenda"
                                  )
                                ) +
                                "\n                        "
                            ),
                            _c("b", { staticStyle: { color: "yellow" } }, [
                              _vm._v(_vm._s(manipulacao.codigoVenda)),
                            ]),
                          ])
                        : _c("span", [
                            _vm._v(
                              "\n                        " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Components.parent.home.fastaction.manipulacao.manipulacao_vue_html.formula"
                                  )
                                ) +
                                "\n                        "
                            ),
                            _c("b", { staticStyle: { color: "yellow" } }, [
                              _vm._v(
                                _vm._s(manipulacao.formulaPadraoDescricao)
                              ),
                            ]),
                          ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { attrs: { id: "container-icon" } }, [
                    _c("div", { staticClass: "container-home-icon" }, [
                      _vm.validarVisibilidadeBotao("ImprimirManipulacao")
                        ? _c(
                            "div",
                            {
                              staticClass: "home-icon bg-secondary",
                              attrs: {
                                "data-toggle": "tooltip",
                                title: _vm.$t(
                                  "__.Components.parent.home.fastaction.manipulacao.manipulacao_vue_html.imprimirManip"
                                ),
                                "data-placement": "left",
                              },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  _vm.onImprimirManipulacaoModelo(manipulacao)
                                },
                              },
                            },
                            [
                              _c("i", {
                                staticClass: "fa fa-print",
                                attrs: { "aria-hidden": "true" },
                              }),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.validarVisibilidadeBotao("ImprimirRotuloManipulacao")
                        ? _c(
                            "div",
                            {
                              staticClass: "home-icon bg-green",
                              attrs: {
                                "data-toggle": "tooltip",
                                title: _vm.$t(
                                  "__.Components.parent.home.fastaction.manipulacao.manipulacao_vue_html.imprimirRotulo"
                                ),
                                "data-placement": "left",
                              },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  _vm.onImprimirRotulo(manipulacao)
                                },
                              },
                            },
                            [
                              _c("i", {
                                staticClass: "fa fa-tag",
                                attrs: { "aria-hidden": "true" },
                              }),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.validarVisibilidadeBotao("ConferirLotesManipulacao")
                        ? _c(
                            "div",
                            {
                              staticClass: "home-icon bg-orange",
                              attrs: {
                                "data-toggle": "tooltip",
                                title: _vm.$t(
                                  "__.Components.parent.home.fastaction.manipulacao.manipulacao_vue_html.confLotesEstqManipu"
                                ),
                                "data-placement": "left",
                              },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  _vm.onLote(manipulacao)
                                },
                              },
                            },
                            [
                              _c("i", {
                                staticClass: "fa fa-balance-scale",
                                attrs: { "aria-hidden": "true" },
                              }),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      manipulacao.receitas.length > 0
                        ? _c(
                            "div",
                            {
                              staticClass: "home-icon bg-red",
                              attrs: {
                                "data-toggle": "tooltip",
                                title: _vm.$t(
                                  "__.Components.parent.home.fastaction.manipulacao.manipulacao_vue_html.receita"
                                ),
                                "data-placement": "left",
                              },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  _vm.onExibirReceita(manipulacao)
                                },
                              },
                            },
                            [
                              _c("i", {
                                staticClass: "fa fa-paperclip",
                                attrs: { "aria-hidden": "true" },
                              }),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.validarVisibilidadeBotao("get")
                        ? _c(
                            "div",
                            {
                              staticClass: "home-icon bg-blue",
                              attrs: {
                                "data-toggle": "tooltip",
                                title: _vm.$t(
                                  "__.Components.parent.home.fastaction.manipulacao.manipulacao_vue_html.visuManipu"
                                ),
                                "data-placement": "left",
                              },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  _vm.onSearch(manipulacao)
                                },
                              },
                            },
                            [
                              _c("i", {
                                staticClass: "fa fa-eye",
                                attrs: { "aria-hidden": "true" },
                              }),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.validarVisibilidadeBotao("ConcluirManipulacao")
                        ? _c(
                            "div",
                            {
                              staticClass: "home-icon bg-green",
                              attrs: {
                                "data-toggle": "tooltip",
                                title: _vm.$t(
                                  "__.Components.parent.home.fastaction.manipulacao.manipulacao_vue_html.conclManipu"
                                ),
                                "data-placement": "left",
                              },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  _vm.onConcluirManipulacao(manipulacao)
                                },
                              },
                            },
                            [
                              _c("i", {
                                staticClass: "fa fa-check",
                                attrs: { "aria-hidden": "true" },
                              }),
                            ]
                          )
                        : _vm._e(),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        attrs: {
                          id: "icon-button",
                          "data-toggle": "tooltip",
                          "data-placement": "left",
                          "data-original-title": "Create",
                        },
                      },
                      [
                        _c("p", { staticClass: "plus" }, [_vm._v("+")]),
                        _vm._v(" "),
                        _c("div", { staticClass: "edit" }, [
                          _c(
                            "p",
                            {
                              staticClass: "icon",
                              on: {
                                click: function ($event) {
                                  _vm.dragAndDrop = true
                                },
                              },
                            },
                            [
                              _c("i", {
                                staticClass: "fa fa-question",
                                attrs: { "aria-hidden": "true" },
                              }),
                            ]
                          ),
                        ]),
                      ]
                    ),
                  ]),
                ]
              )
            }),
          ],
          2
        ),
        _vm._v(" "),
        _c(
          "a",
          {
            staticClass: "ver-mais list-group-item",
            attrs: { type: "button" },
            on: {
              click: function ($event) {
                _vm.onVerMais().withLoading()
              },
            },
          },
          [
            _vm._v(
              "\n            " +
                _vm._s(
                  _vm.$t(
                    "__.Components.parent.home.fastaction.manipulacao.manipulacao_vue_html.verMais"
                  )
                ) +
                "\n        "
            ),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "fast-action-grid-panel-right" }, [
        _c("ul", [
          _c(
            "li",
            [
              _c(
                "router-link",
                { attrs: { to: "/manipulacaoordem-lista-formula-padrao" } },
                [
                  _c("i", {
                    staticClass: "fa fa-list fa-5x",
                    attrs: { "aria-hidden": "true" },
                  }),
                  _vm._v(" "),
                  _c("span", [
                    _vm._v(
                      "\n                        " +
                        _vm._s(
                          _vm.$t(
                            "__.Components.parent.home.fastaction.manipulacao.manipulacao_vue_html.manipuFrmlPadrao"
                          )
                        ) +
                        "\n                    "
                    ),
                  ]),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            [
              _c("router-link", { attrs: { to: "/manipulacaoordem-lista" } }, [
                _c("i", {
                  staticClass: "fa fa-list fa-5x",
                  attrs: { "aria-hidden": "true" },
                }),
                _vm._v(" "),
                _c("span", [
                  _vm._v(
                    "\n                        " +
                      _vm._s(
                        _vm.$t(
                          "__.Components.parent.home.fastaction.manipulacao.manipulacao_vue_html.manipulacoesVenda"
                        )
                      ) +
                      "\n                    "
                  ),
                ]),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _vm.VALIDAR_PERMISSAO_USUARIO("formulas_padrao", "insert")
            ? _c(
                "li",
                [
                  _c(
                    "router-link",
                    { attrs: { to: "/formulapadrao-manipulacao" } },
                    [
                      _c("i", {
                        staticClass: "fa flask-plus",
                        attrs: { "aria-hidden": "true" },
                      }),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(
                          "\n                        " +
                            _vm._s(
                              _vm.$t(
                                "__.Components.parent.home.fastaction.manipulacao.manipulacao_vue_html.novaManipu"
                              )
                            ) +
                            "\n                    "
                        ),
                      ]),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
        ]),
      ]),
      _vm._v(" "),
      [
        _c(
          "shortcutComponent",
          {
            ref: "shortCutList",
            attrs: { modalClass: "bg-modal", showAddNew: false },
            on: { "selection-item": _vm.onConfirmList },
          },
          [
            _vm.loadManipulacaoListComponent
              ? _c(
                  "div",
                  { attrs: { slot: "component" }, slot: "component" },
                  [
                    _c("manipulacao-list-component", {
                      ref: "manipulacaoListComponent",
                      on: {
                        "conferir-lote": _vm.onLote,
                        "imprimir-manipulacao": _vm.onImprimirManipulacaoModelo,
                        "imprimir-manipulacao-rotulo": _vm.onImprimirRotulo,
                        "concluir-manipulacao": _vm.onConcluirManipulacao,
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ]
        ),
        _vm._v(" "),
        _c(
          "shortcutComponent",
          {
            ref: "shortCutLote",
            attrs: {
              btOkName: _vm.$t(
                "__.Components.parent.home.fastaction.manipulacao.manipulacao_vue_html.confirmarAlte"
              ),
              showAddNew: false,
            },
            on: { "selection-item": _vm.onConfirmLote },
          },
          [
            _vm.loadLoteItemComponent
              ? _c(
                  "div",
                  { attrs: { slot: "component" }, slot: "component" },
                  [
                    _c("lote-item-component", {
                      ref: "loteItemComponent",
                      attrs: {
                        unidadeMedidaOp: _vm.unidadeMedidasOptions,
                        volumeTotal: _vm.model.volumeTotal,
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ]
        ),
        _vm._v(" "),
        _c("impressaoComponent", { ref: "impressaoComponent" }),
      ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }