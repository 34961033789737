import BaseModel from "../base/baseModel";
import ManipulacaoOrdemModel from "../manipulacaoOrdemModel";

import ManipulacaoOrdemEtapaPCPModel from "./manipulacaoOrdemEtapaPCPModel";

export default class ManipulacaoOrdemPCPModel extends BaseModel {
    id?: number = null;
    manipulacaoOrdemId?: number = null;
    manipulacaoOrdem = new ManipulacaoOrdemModel();
    dataInicio?: Date = null;
    dataFim?: Date = null;
    manipulacaoOrdemEtapasPCP: ManipulacaoOrdemEtapaPCPModel[] = [];
    dataEntrega: Date = null;
    manipulacaoOrdemCodigo?: number = null;
    vendaCodigo?: number = null;
    formaFarmaceutica: string;
    localEntrega: string;
    paciente: string;
    vendedor: string;
    descricaoEtapa: string;
}
