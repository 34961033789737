import { mapMutations } from "vuex";

import { GridAction } from "@/components/child/grid/gridAction";
import { GridColors, GridColumn, GridColumnType } from "@/components/child/grid/gridColumn";
import { Component } from "@/decorators";
import EnumExtensions from "@/models/enum/extensions/enumExtensions";
import generoPessoa from "@/models/enum/generosPessoa";
import tipoConselhoRegional from "@/models/enum/tipoConselhoRegional";
import ufs from "@/models/enum/ufs";
import PrescritorModel from "@/models/prescritorModel";
import CidadeService from "@/services/external/cidadeService";
import PrescritorService from "@/services/prescritorService";
import Delay from "@/utils/common/delay";

import ShortcutComponent from "../../shortcut/shortcut";
import shortcutComponent from "../../shortcut/shortcut.vue";
import ListComponentBase from "../listComponentBase";

import verComponent from "./ver.vue";

import "../crud.scss";

@Component({
    components: {
        shortcutComponent,
        verComponent,
    },
    methods: mapMutations(["LOAD_LIST"]),
})
export default class PrescritorListComponent extends ListComponentBase<PrescritorModel> {
    get gridColumns(): Array<GridColumn> {
        return [
            new GridColumn("id", "Código", GridColumnType.Integer),
            new GridColumn("nome", "Nome", GridColumnType.String),
            new GridColumn("numeroRegistro", "Número de Registro", GridColumnType.String),
            new GridColumn("tipoConselhoRegionalDescricao", "Conselho Regional", GridColumnType.String),
            new GridColumn("ufConselhoRegionalDescricao", "UF do Conselho", GridColumnType.String),
            new GridColumn("sipeAgro", "SipeAgro", GridColumnType.String),
        ];
    }

    private beforeMount() {
        this.setProps(new PrescritorService(), "prescritor", "nome");
    }

    private cidadeService = new CidadeService();
    private shortcutComponent: ShortcutComponent = null;

    extraActions: Array<object> = [];
    gridExtraActionsVer: Array<GridAction> = [];
    modelVer: PrescritorModel = null;
    showVer = false;

    public async afterLoad() {
        this.gridExtraActionsVer.splice(0);
        this.extraActions.splice(0);

        for (let i = 0; i < this.gridData.length; i++) {
            this.gridExtraActionsVer.push(new GridAction("ver", "Ver Prescritor", "fa fa-eye", GridColors.BLUE, true));
        }

        this.extraActions.push(this.gridExtraActionsVer);
    }

    private onExtraAction(name: string, model: PrescritorModel) {
        if (name.trim() == "ver") {
            this.onVer(model);
        }
    }

    private async onVer(prescritor: PrescritorModel) {
        while (!this.$refs.shortcutComponent) await Delay(5);
        this.shortcutComponent = this.$refs.shortcutComponent as ShortcutComponent;
        this.shortcutComponent.title = "Prescritor";

        try {
            const data = await this.service.get(prescritor.id).withLoading().resolveWithJSON<PrescritorModel>();
            this.modelVer = data;

            if (data.estadoId) {
                const cidades = await this.cidadeService.get(data.estadoId).withLoading();
                if (cidades.some(p => p.value == this.modelVer.cidadeId)) {
                    this.modelVer.cidadeDescricao = cidades.find(p => p.value == this.modelVer.cidadeId)["text"];
                }
                this.modelVer.tipoConselhoRegionalDescricao = EnumExtensions.getNameByValue(
                    tipoConselhoRegional,
                    data.tipoConselhoRegional,
                );
                this.modelVer.ufConselhoRegionalDescricao = EnumExtensions.getNameByValue(ufs, data.ufConselhoRegional);
            }

            this.modelVer.generoDescricao = EnumExtensions.getNameByValue(generoPessoa, data.genero);
            this.modelVer.estadoDescricao = EnumExtensions.getNameByValue(ufs, data.estadoId);
            this.showVer = true;
        } catch {}

        this.shortcutComponent.show();
    }
}