import Vue from "vue";
import { mapGetters } from "vuex";

import actionBarComponent from "@/components/child/actionBar/actionBar.vue";
import checkboxComponent from "@/components/child/form/checkbox.vue";
import comboComponent from "@/components/child/form/combo.vue";
import dataTooltipComponent from "@/components/child/form/datatooltip.vue";
import fieldsetComponent from "@/components/child/form/fieldset.vue";
import gridComponent from "@/components/child/grid/grid.vue";
import { GridColumn, GridColumnType } from "@/components/child/grid/gridColumn";
import { Component, Watch } from "@/decorators";
import EnumExtensions from "@/models/enum/extensions/enumExtensions";
import { Fonte } from "@/models/enum/fonte";
import ModeloEspecifico from "@/models/enum/modeloEspecifico";
import ModeloImpressaoCampos, { TipoCampo } from "@/models/modeloImpressao/modeloImpressaoCamposModel";
import ModeloImpressaoCondicao from "@/models/modeloImpressao/modeloImpressaoCondicaoModel";
import ModeloImpressao from "@/models/modeloImpressao/modeloImpressaoModel";
import ModeloImpressaoSessao from "@/models/modeloImpressao/modeloImpressaoSessaoModel";
import { TypeElement } from "@/models/modeloImpressao/modeloImpressaoSessaoModel";
import { IServicePrint } from "@/services/base/iServicePrint";
import ColetaService from "@/services/expedicao/coletaService";
import ManipulacaoService from "@/services/manipulacaoOrdemService";
import ModeloImpressaoService from "@/services/modeloImpressaoService";
import PrinterService from "@/services/print/printerService";
import VendaService from "@/services/vendaService";
import { Getters, SessionActions } from "@/store/store";
import { copyObject } from "@/utils/common/copyObject";

import ReplicarSelecaoFranquiaComponent from "../crud/replicarSelecaoFranquia/edit";
import replicarSelecaoFranquiaComponent from "../crud/replicarSelecaoFranquia/edit.vue";
import ShortcutComponent from "../shortcut/shortcut";
import shortcutComponent from "../shortcut/shortcut.vue";

import ModeloImpressaoCondicaoComponent from "./condicao";
import modeloImpressaoCondicaoComponent from "./condicao.vue";
import ModeloImpressaoEditComponent from "./edit";
import modeloImpressaoEditComponent from "./edit.vue";
import ModeloImpressaoUsuarioComponent from "./modeloImpressaoUsuario";
import modeloImpressaoUsuarioComponent from "./modeloImpressaoUsuario.vue";

import "../crud/crud.scss";
import "./sessao.scss";

@Component({
    components: {
        comboComponent,
        dataTooltipComponent,
        gridComponent,
        checkboxComponent,
        fieldsetComponent,
        shortcutComponent,
        modeloImpressaoEditComponent,
        modeloImpressaoCondicaoComponent,
        replicarSelecaoFranquiaComponent,
        actionBarComponent,
        modeloImpressaoUsuarioComponent,
    },
    computed: {
        ...mapGetters(["HAS_PERMISSAO_PERFIL"] as Getters),
    },
})
export default class ModeloImpressaoSessaoListComponent extends Vue {
    // State computed props
    HAS_PERMISSAO_PERFIL: (isRede: boolean, isFranqueador: boolean, isSuporte: boolean) => Promise<boolean>;

    private service = new ModeloImpressaoService();
    private shortcutComponent: ShortcutComponent = null;
    private shortcutCondicaoComponent: ShortcutComponent = null;
    private modeloImpressaoEditComponent: ModeloImpressaoEditComponent = null;
    private modeloImpressaoCondicaoComponent: ModeloImpressaoCondicaoComponent = null;
    private replicarSelecaoFranquiaComponent: ReplicarSelecaoFranquiaComponent = null;
    private modeloImpressaoUsuarioComponent: ModeloImpressaoUsuarioComponent = null;
    private isAdmin = false;
    private permitirReplicarModelo = false;

    private modeloPreDefinidoOptions = [
        { text: "Personalizado", value: 0 },
        { text: "PDF Fracionamento", value: 1 },
    ];

    fonteOptions: Array<Object> = [
        { text: "Padrão", value: Fonte.Default },
        { text: "Calibri", value: Fonte.Calibri },
        { text: "Monospace", value: Fonte.Monospace },
        { text: "Courier", value: Fonte.Courier },
        { text: "Helvetica", value: Fonte.Helvetica },
        { text: "Times Roman", value: Fonte.TimesRoman },
    ];

    modeloEspecificoOptions: Array<Object> = EnumExtensions.getNamesAndValuesOrderedByNames(ModeloEspecifico);

    gridFilterKey = "";
    gridSortKey = "nome";
    gridSortOrder = "asc";
    gridData: Array<ModeloImpressaoSessao> = [];
    get gridColumns(): Array<GridColumn> {
        return [
            new GridColumn("sessao", "Ordem", GridColumnType.String),
            new GridColumn("descricao", "Descricao", GridColumnType.String),
            new GridColumn("grupo", "Grupo", GridColumnType.Integer),
            new GridColumn("ativo", "Ativo", GridColumnType.Boolean),
        ];
    }

    get gridColumnsCondicoes(): Array<GridColumn> {
        return [
            new GridColumn("condicaoCampoName", "Campo", GridColumnType.String),
            new GridColumn("condicaoValue", "Condição", GridColumnType.String),
        ];
    }

    pageIndex = 1;
    pageSize = 20;
    total = 0;

    modelId: number = null;
    private model = new ModeloImpressao();
    private camposPreBusca = new Array<ModeloImpressaoCampos>();
    private itSessaoTemp = 0;

    telaName: string = null;
    private classFullName: string = null;

    private impressoraSelecionada: number = null;
    private impressoraOptions: Array<{ text: string; value: number; printerName: string }> = [];

    private async load() {
        this.model = new ModeloImpressao();

        if (this.modelId > 0) {
            try {
                const data = await this.service.get(this.modelId).withLoading().resolveWithJSON<ModeloImpressao>();

                this.model.sessoes = new Array<ModeloImpressaoSessao>();
                this.model.updateFrom(data);

                this.gridData = this.model.sessoes.sort((obj1, obj2) => obj1.sessao - obj2.sessao);
                this.telaName = data.telaName;
                this.total = this.model.sessoes.length;
                if (this.isAdmin) {
                    this.modeloImpressaoUsuarioComponent = this.$refs
                        .modeloImpressaoUsuarioComponent as ModeloImpressaoUsuarioComponent;
                    this.modeloImpressaoUsuarioComponent.setUsuarios(this.model.usuarios);
                }
            } catch {
                this.$router.back();
            }
        }

        this.loadImpressoraOptions();
    }

    private ajustaIds() {
        //ajusta ids sessoes
        this.model.sessoes.forEach(obj => {
            //seta id campos com id negativo com null
            if (obj.id <= 0) obj.id = null;
            //remove campos com colunas = 0
            obj.campos = obj.campos.filter(p => p.coluna > 0);
            //ajusta sessao id dos campos
            obj.campos.forEach(campo => {
                if (campo.id <= 0) campo.id = null;
                if (campo.modeloImpressaoSessaoId <= 0) campo.modeloImpressaoSessaoId = null;
            });
        });
        //ajusta ids condicoes
        this.model.condicoes.forEach(obj => {
            //seta id negativo com null
            if (obj.id <= 0) obj.id = null;
        });
    }

    private onSalvar() {
        this.save();
    }

    private async save({
        model = null,
        showConfirmacao = true,
    }: { model?: ModeloImpressao; showConfirmacao?: boolean } = {}) {
        let salvou = false;

        //altera ids negativos para null
        const useModel = model != null;
        this.ajustaIds();

        try {
            const isValid = await this.$validator.validateAll();
            if (isValid) {
                if (this.isAdmin) {
                    this.modeloImpressaoUsuarioComponent = this.$refs
                        .modeloImpressaoUsuarioComponent as ModeloImpressaoUsuarioComponent;
                    this.model.usuarios = this.modeloImpressaoUsuarioComponent.getUsuarios();
                }

                const response = await this.service[!this.model.id || useModel ? "insert" : "update"](
                    !useModel ? this.model : model,
                )
                    .withLoading()
                    .resolveWithResponse();

                const nId = Number(response.headers.get("Id"));
                if (response.ok) {
                    if (nId) {
                        this.model.id = nId;
                        this.modelId = nId;
                    }

                    if (showConfirmacao) {
                        await this.$showSuccess(
                            !this.model.id ? this.$t("__.ts.inclusao") : this.$t("__.ts.alt"),
                            "Modelo de Impressão salvo com sucesso.",
                        );
                        if (useModel && nId) {
                            this.load();
                            this.$router.push({
                                name: "printModel-edicao",
                                params: { modelId: nId.toString(), fullClassName: this.classFullName },
                            });
                        } else {
                            this.$router.back();
                        }
                    }

                    salvou = true;
                }
            }
        } catch {}

        return salvou;
    }

    private async onEditarSessao(model: ModeloImpressaoSessao) {
        const modelSessao = new ModeloImpressaoSessao();
        modelSessao.updateFrom(model);

        for (const campoPreBusca of this.camposPreBusca) {
            const campo = new ModeloImpressaoCampos();
            campo.updateFrom(campoPreBusca);

            modelSessao.campos
                .filter(c => c.labelId == campo.labelId)
                .forEach(c => {
                    c.index = campo.index;
                    c.parentIndex = campo.parentIndex;
                    c.header = campo.header;
                    c.codGroup = campo.codGroup;
                    c.grid = campo.grid;
                });

            const setarCamposComplemento = campos =>
                campos
                    .filter(c => c.modeloImpressaoCampoComplemento != null)
                    .filter(c => c.modeloImpressaoCampoComplemento.labelId == campo.labelId)
                    .forEach(c => {
                        c.modeloImpressaoCampoComplemento.index = campo.index;
                        c.modeloImpressaoCampoComplemento.parentIndex = campo.parentIndex;
                        c.modeloImpressaoCampoComplemento.header = campo.header;
                        c.modeloImpressaoCampoComplemento.codGroup = campo.codGroup;
                        c.modeloImpressaoCampoComplemento.grid = campo.grid;
                    });
            setarCamposComplemento(modelSessao.campos);

            const setarCamposSubstitutos = campos =>
                campos
                    .filter(c => c.modeloImpressaoCampoSubstituto != null)
                    .filter(c => c.modeloImpressaoCampoSubstituto.labelId == campo.labelId)
                    .forEach(c => {
                        c.modeloImpressaoCampoSubstituto.index = campo.index;
                        c.modeloImpressaoCampoSubstituto.parentIndex = campo.parentIndex;
                        c.modeloImpressaoCampoSubstituto.header = campo.header;
                        c.modeloImpressaoCampoSubstituto.codGroup = campo.codGroup;
                        c.modeloImpressaoCampoSubstituto.grid = campo.grid;
                    });
            setarCamposSubstitutos(modelSessao.campos);

            //add campos novos no model
            if (modelSessao.campos.filter(p => p.labelId == campo.labelId).length == 0) {
                modelSessao.campos.push(campo);
            }
        }

        //trata index de campos personalizados
        let maxIndex = modelSessao.campos.length ? Math.max(...modelSessao.campos.map(p => p.index)) + 1 : 1;

        const setarCamposPersonalizados = campos =>
            campos
                .filter(c => c.index == null)
                .forEach(obj => {
                    obj.index = maxIndex;
                    maxIndex++;
                });
        setarCamposPersonalizados(modelSessao.campos);

        await this.modeloImpressaoEditComponent.loadSessao(modelSessao).withLoading();

        this.shortcutComponent.show();
    }

    private async onCriarSessao() {
        this.itSessaoTemp--;

        const novaSessao = new ModeloImpressaoSessao();
        novaSessao.modeloImpressaoId = this.model.id;
        novaSessao.id = this.itSessaoTemp;
        novaSessao.descricao = "Nova Sessão de Modelo";
        novaSessao.maxCols = 0;
        novaSessao.maxRows = 0;
        novaSessao.tipo = TypeElement.Form;
        novaSessao.personalizado = true;
        novaSessao.showDescricao = true;
        novaSessao.ativo = true;
        novaSessao.typeTableLines = 0;
        novaSessao.alinhamento = 0;

        if (this.model.sessoes.length > 0) {
            novaSessao.sessao = Math.max(...this.model.sessoes.map(p => p.sessao)) + 1;
            novaSessao.grupo = Math.max(...this.model.sessoes.map(p => p.grupo));
        } else {
            novaSessao.sessao = 1;
            novaSessao.grupo = 1;
        }

        novaSessao.campos = new Array<ModeloImpressaoCampos>();

        for (const campoPreBusca of this.camposPreBusca) {
            const campo = new ModeloImpressaoCampos();
            campo.updateFrom(campoPreBusca);
            campo.modeloImpressaoSessaoId = novaSessao.id;
            novaSessao.campos.push(campo);
        }

        this.model.sessoes.push(novaSessao);

        this.modeloImpressaoEditComponent.loadSessao(novaSessao);
        this.shortcutComponent.show();
    }

    private onRemoverSessao(model: ModeloImpressaoSessao) {
        this.model.sessoes.splice(this.model.sessoes.indexOf(model), 1);
    }

    private onConfirmSessao() {
        // get sessao nova e campos
        const sessao = new ModeloImpressaoSessao();
        sessao.updateFrom(this.modeloImpressaoEditComponent.GetSessaoModel());

        if (sessao.id != null && this.model.sessoes.filter(p => p.id == sessao.id).length > 0) {
            this.model.sessoes
                .filter(p => p.id == sessao.id)
                .forEach(obj => {
                    copyObject(sessao, obj);
                });
        }
        this.gridData = this.model.sessoes.sort(function (obj1, obj2) {
            // Ascending
            return obj1.sessao - obj2.sessao;
        });

        this["$forceUpdate"]();

        this.shortcutComponent.hide();
    }

    private onConfirmCondicao() {
        const cond = this.modeloImpressaoCondicaoComponent.getCondicaoModel();
        if (cond != null) {
            const condicao = new ModeloImpressaoCondicao();
            condicao.updateFrom(cond);
            if (condicao.id != null) {
                const condicaoModel = this.model.condicoes.filter(p => p.id == condicao.id)[0];
                condicaoModel.condicaoValue = condicao.condicaoValue;
                condicaoModel.condicaoCampoName = condicao.condicaoCampoName;
                condicaoModel.condicaoCampoPath = condicao.condicaoCampoPath;
                condicaoModel.modeloImpressaoId = this.model.id;
            } else {
                condicao.id = (this.model.condicoes.length + 1) * -1;
                condicao.modeloImpressaoId = this.model.id;
                this.model.condicoes.push(condicao);
            }
            this.shortcutCondicaoComponent.hide();
        }
    }

    private onEditarCondicao(condicao: ModeloImpressaoCondicao) {
        this.setCamposCondicoes();
        const cond = new ModeloImpressaoCondicao();
        cond.updateFrom(condicao);
        this.modeloImpressaoCondicaoComponent.loadCondicao(cond);
        this.shortcutCondicaoComponent.title = "Condição de Modelo";
        this.shortcutCondicaoComponent.show();
    }

    private setCamposCondicoes() {
        this.modeloImpressaoCondicaoComponent.clear();
        if (!this.modeloImpressaoCondicaoComponent.loaded()) {
            const camposEnum = this.camposPreBusca.filter(
                p =>
                    (p.tipoCampo == TipoCampo.Enum && p.camposEnumValues.length > 0) ||
                    p.labelId == "FormaFarmaceutica.Descricao",
            );
            //busca parents para mostrar no combo
            let parents = new Array<ModeloImpressaoCampos>();
            parents = this.joinParentCampos(camposEnum);
            this.modeloImpressaoCondicaoComponent.loadNewCondicao(camposEnum.concat(parents));
        }
    }

    private onCriarCondicao() {
        //zera combos apenas
        this.setCamposCondicoes();

        this.shortcutCondicaoComponent.title = "Condição de Modelo";
        this.shortcutCondicaoComponent.show();
    }

    private onRemoverCondicao(condicao: ModeloImpressaoCondicao) {
        this.model.condicoes.splice(this.model.condicoes.indexOf(condicao), 1);
    }

    private joinParentCampos(campos: Array<ModeloImpressaoCampos>) {
        let parents = new Array<ModeloImpressaoCampos>();
        campos.forEach(campo => {
            if (
                parents.filter(p => p.index == campo.parentIndex).length == 0 &&
                campos.filter(p => p.index == campo.parentIndex).length == 0
            ) {
                parents = parents.concat(this.camposPreBusca.filter(p => p.index == campo.parentIndex));
                //se o campo adicionado possuir parents, chama a funcao de novo
                if (this.camposPreBusca.filter(p => p.index == campo.parentIndex && p.parentIndex > 0).length > 0) {
                    parents = parents.concat(
                        this.joinParentCampos(
                            this.camposPreBusca.filter(p => p.index == campo.parentIndex && p.parentIndex > 0),
                        ),
                    );
                }
            }
        });

        return parents;
    }

    private voltar() {
        this.$router.back();
    }

    private onCopiar() {
        //cria model igual zerando ids e alterando descricao, salva e chama a tela do id replicado se possivel
        const modelReplica = new ModeloImpressao();
        modelReplica.updateFrom(this.model);

        modelReplica.descricao = "Cópia de " + this.model.descricao;
        modelReplica.id = null;
        modelReplica.padrao = false;
        modelReplica.condicoes.forEach(cond => {
            cond.id = null;
            cond.modeloImpressaoId = null;
        });
        modelReplica.sessoes.forEach(sessao => {
            sessao.id = null;
            sessao.modeloImpressaoId = null;
            sessao.campos.forEach(campo => {
                campo.id = null;
                campo.modeloImpressaoSessaoId = null;

                if (campo.modeloImpressaoCampoSubstituto != null) campo.modeloImpressaoCampoSubstituto.id = null;

                campo.modeloImpressaoCampoSubstitutoId = null;

                if (campo.modeloImpressaoCampoComplemento != null) campo.modeloImpressaoCampoComplemento.id = null;

                campo.modeloImpressaoCampoComplementoId = null;
            });
        });

        this.save({ model: modelReplica });
    }

    private async onReplicar() {
        this.replicarSelecaoFranquiaComponent.openFranquias();
    }

    private async onConfirmReplicar(franquiasIds: Array<number>) {
        if (await this.save({ showConfirmacao: false })) {
            const idsFranquias = franquiasIds.toString();

            try {
                const sucesso = await this.service
                    .replicar(this.model.id, idsFranquias)
                    .withLoading()
                    .resolveWithoutJSON();

                if (sucesso) {
                    this.$showSuccess("Replicação", this.$t("__.ts.regisSalvosSucess"));
                }
            } catch {}
        }
    }

    private async loadClassName() {
        if (this.telaName != null && this.telaName != "") {
            switch (this.telaName) {
                case "Venda":
                    await this.loadClassFullName(new VendaService());
                    break;
                case "Manipulacao":
                    await this.loadClassFullName(new ManipulacaoService());
                    break;
                case "Coleta":
                    await this.loadClassFullName(new ColetaService());
                    break;
                case "Rotulo":
                    await this.loadClassFullName(new ManipulacaoService());
                    break;
            }
        }
    }

    //criar funcoes gerenicas para tratar isso
    private async loadClassFullName(service: IServicePrint<Object>) {
        try {
            this.classFullName = await service.getFullClassName().resolveWithText();
        } catch {}
    }

    //@ts-ignore
    @Watch("$route.params.tela")
    private onChangeParamsName() {
        this.reload().withLoading();
    }

    private async getCamposComDisplayName() {
        let campos = new Array<ModeloImpressaoCampos>();
        if (this.classFullName) {
            campos = await this.model.GetCamposComDisplayName(this.classFullName);
        }
        return campos;
    }

    private async loadImpressoraOptions() {
        const printers = await new PrinterService().refreshPrinterQueues();

        const printerOptions = printers.map(printer => ({
            text: printer.printerName + (printer.printerDesc ? ` (${printer.printerDesc})` : ""),
            printerName: printer.printerName,
        }));

        // Se já houver uma impressora definida no modelo, colocar ela como primeira opção.
        if (this.model.nomeImpressora) {
            const res = printerOptions.findIndex(i => i.printerName == this.model.nomeImpressora);
            const printerName = this.model.nomeImpressora;
            const text = res == -1 ? `(offline) ${this.model.nomeImpressora}` : printerOptions.splice(res, 1)[0].text;
            printerOptions.unshift({ text, printerName });
        }

        this.impressoraOptions = printerOptions.map((option, index) => ({ ...option, value: index }));
        if (this.impressoraOptions.length) {
            this.impressoraSelecionada = 0;
        }
    }

    //@ts-ignore
    @Watch("impressoraSelecionada")
    private onChangeImpressoraSelecionada() {
        if (this.impressoraSelecionada != null) {
            this.model.nomeImpressora = this.impressoraOptions[this.impressoraSelecionada].printerName;
        } else {
            this.model.nomeImpressora = null;
        }
    }

    private mounted() {
        this.reload().withLoading();
    }

    private async reload() {
        this.shortcutComponent = this.$refs.shortcutComponent as ShortcutComponent;
        this.shortcutCondicaoComponent = this.$refs.shortcutCondicaoComponent as ShortcutComponent;
        this.modeloImpressaoEditComponent = this.$refs.modeloImpressaoEditComponent as ModeloImpressaoEditComponent;
        this.modeloImpressaoCondicaoComponent = this.$refs
            .modeloImpressaoCondicaoComponent as ModeloImpressaoCondicaoComponent;

        await this.$store.dispatch(SessionActions.LOAD_USUARIO_LOGADO);

        this.isAdmin = await this.HAS_PERMISSAO_PERFIL(true, true, true);

        if (this.isAdmin) {
            this.permitirReplicarModelo = true;
            this.replicarSelecaoFranquiaComponent = this.$refs
                .replicarSelecaoFranquiaComponent as ReplicarSelecaoFranquiaComponent;
        }

        this.telaName = <string>this.$route.params.tela;

        await this.loadClassName();

        if (this.classFullName == null || this.classFullName.trim() == "") {
            this.$router.back();
        }

        this.camposPreBusca = await this.getCamposComDisplayName();

        if (this.$route.params.id && +this.$route.params.id > 0) {
            this.modelId = +this.$route.params.id;
            this.load();
        } else if (this.telaName) {
            this.model.descricao = "Modelo de Impressão para " + this.telaName;
            this.model.sessoes = new Array<ModeloImpressaoSessao>();
            this.model.telaName = this.telaName;
            this.model.padrao = false;
        } else {
            this.$router.back();
        }
    }
}
