var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-sm-12" }, [
        _c("table", { staticClass: "table table-striped ajusteVer" }, [
          _c("tbody", [
            _c("h3", [
              _vm._v(
                _vm._s(_vm.$t("__.Crud.formulapadrao.ver_vue_html.dadosGera"))
              ),
            ]),
            _vm._v(" "),
            _c("tr", [
              _c("th", { attrs: { scope: "row" } }, [
                _c("h4", [
                  _vm._v(
                    _vm._s(
                      _vm.$t("__.Crud.formulapadrao.ver_vue_html.descricao")
                    )
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("td", [_c("h4", [_vm._v(_vm._s(_vm.data.descricao))])]),
            ]),
            _vm._v(" "),
            _c("tr", [
              _c("th", { attrs: { scope: "row" } }, [
                _c("h4", [
                  _vm._v(
                    _vm._s(
                      _vm.$t("__.Crud.formulapadrao.ver_vue_html.prodFinal")
                    )
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("td", [_c("h4", [_vm._v(_vm._s(_vm.data.produtoDescricao))])]),
            ]),
            _vm._v(" "),
            _c("tr", [
              _c("th", { attrs: { scope: "row" } }, [
                _c("h4", [
                  _vm._v(
                    _vm._s(
                      _vm.$t("__.Crud.formulapadrao.ver_vue_html.frmFarmaceu")
                    )
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("td", [
                _c("h4", [
                  _vm._v(
                    _vm._s(
                      _vm.data.formaFarmaceutica
                        ? _vm.data.formaFarmaceutica.descricao
                        : ""
                    )
                  ),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("tr", [
              _c("th", { attrs: { scope: "row" } }, [
                _c("h4", [
                  _vm._v(
                    _vm._s(_vm.$t("__.Crud.formulapadrao.ver_vue_html.valDias"))
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("td", [_c("h4", [_vm._v(_vm._s(_vm.data.validadeDias))])]),
            ]),
            _vm._v(" "),
            _c("tr", [
              _c("th", { attrs: { scope: "row" } }, [
                _c("h4", [
                  _vm._v(
                    _vm._s(
                      _vm.$t("__.Crud.formulapadrao.ver_vue_html.tipoFrmlPadr")
                    )
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("td", [
                _c("h4", [_vm._v(_vm._s(_vm.data.tipoFormulaPadraoDescricao))]),
              ]),
            ]),
            _vm._v(" "),
            _c("tr", [
              _c("th", { attrs: { scope: "row" } }, [
                _c("h4", [
                  _vm._v(
                    _vm._s(_vm.$t("__.Crud.formulapadrao.ver_vue_html.vol"))
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("td", [_c("h4", [_vm._v(_vm._s(_vm.data.volume))])]),
            ]),
            _vm._v(" "),
            _c("tr", [
              _c("th", { attrs: { scope: "row" } }, [
                _c("h4", [
                  _vm._v(
                    _vm._s(
                      _vm.$t("__.Crud.formulapadrao.ver_vue_html.unMedida")
                    )
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("td", [
                _c("h4", [_vm._v(_vm._s(_vm.data.unidadeMedida.descricao))]),
              ]),
            ]),
            _vm._v(" "),
            _c("tr", [
              _c("th", { attrs: { scope: "row" } }, [
                _c("h4", [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        "__.Crud.formulapadrao.ver_vue_html.procedimProducao"
                      )
                    )
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("td", [
                _c("h4", [_vm._v(_vm._s(_vm.data.procedimentoProducao))]),
              ]),
            ]),
            _vm._v(" "),
            _c("tr", [
              _c("th", { attrs: { scope: "row" } }, [
                _c("h4", [
                  _vm._v(
                    _vm._s(_vm.$t("__.Crud.formulapadrao.ver_vue_html.obs"))
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("td", [_c("h4", [_vm._v(_vm._s(_vm.data.observacao))])]),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-sm-12" }, [
        _c("table", { staticClass: "table table-striped ajusteVer" }, [
          _c("thead", [
            _c("h3", [
              _vm._v(
                _vm._s(_vm.$t("__.Crud.formulapadrao.ver_vue_html.itens"))
              ),
            ]),
            _vm._v(" "),
            _c("tr", [
              _c("th", { attrs: { scope: "col" } }, [
                _c("h4", [
                  _vm._v(
                    _vm._s(_vm.$t("__.Crud.formulapadrao.ver_vue_html.prod"))
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("th", { attrs: { scope: "col" } }, [
                _c("h4", [
                  _vm._v(
                    _vm._s(
                      _vm.$t("__.Crud.formulapadrao.ver_vue_html.tipoProd")
                    )
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("th", { attrs: { scope: "col" } }, [
                _c("h4", [
                  _vm._v(
                    _vm._s(
                      _vm.$t("__.Crud.formulapadrao.ver_vue_html.tipoCalc")
                    )
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("th", { attrs: { scope: "col" } }, [
                _c("h4", [
                  _vm._v(
                    _vm._s(
                      _vm.$t("__.Crud.formulapadrao.item_vue_html.volumeDose")
                    )
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("th", { attrs: { scope: "col" } }, [
                _c("h4", [
                  _vm._v(
                    _vm._s(_vm.$t("__.Crud.formulapadrao.ver_vue_html.qtde"))
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("th", { attrs: { scope: "col" } }, [
                _c("h4", [
                  _vm._v(
                    _vm._s(
                      _vm.$t("__.Crud.formulapadrao.ver_vue_html.unMedida")
                    )
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("th", { attrs: { scope: "col" } }, [
                _c("h4", [
                  _vm._v(
                    _vm._s(
                      _vm.$t("__.Crud.formulapadrao.ver_vue_html.unManipul")
                    )
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("th", { attrs: { scope: "col" } }, [
                _c("h4", [
                  _vm._v(
                    _vm._s(_vm.$t("__.Crud.formulapadrao.ver_vue_html.fase"))
                  ),
                ]),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "tbody",
            _vm._l(_vm.data.formulaPadraoItens, function (item) {
              return _c("tr", { key: item.produtoDescricao }, [
                _c("td", [_c("h4", [_vm._v(_vm._s(item.produtoDescricao))])]),
                _vm._v(" "),
                _c("td", [_c("h4", [_vm._v(_vm._s(item.tipoItemDescricao))])]),
                _vm._v(" "),
                _c("td", [
                  _c("h4", [_vm._v(_vm._s(item.tipoCalculoDescricao))]),
                ]),
                _vm._v(" "),
                _c("td", [_c("h4", [_vm._v(_vm._s(item.volumeDose))])]),
                _vm._v(" "),
                _c("td", [_c("h4", [_vm._v(_vm._s(item.quantidadeDose))])]),
                _vm._v(" "),
                _c("td", [
                  _c("h4", [_vm._v(_vm._s(item.unidadeMedidaDescricao))]),
                ]),
                _vm._v(" "),
                _c("td", [
                  _c("h4", [
                    _vm._v(_vm._s(item.unidadeMedidaManipulacao.descricao)),
                  ]),
                ]),
                _vm._v(" "),
                _c("td", [_c("h4", [_vm._v(_vm._s(item.faseUso))])]),
              ])
            })
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }