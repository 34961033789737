import Vue from "vue";

import { Component, Prop, Watch } from "@/decorators";
import FormaFarmaceuticaService from "@/services/formaFarmaceuticaService";

// Precisa ser assim se não ocorre erro
// eslint-disable-next-line @typescript-eslint/no-var-requires
const echarts = require("echarts");

import "echarts/theme/dark";

@Component
export default class GraficoComponent extends Vue {
    myChart: any;
    legenda = [];
    labels = [];
    private limitQtd = 120;

    private formaFarmaceuticaService = new FormaFarmaceuticaService();

    values: Array<number> = null; //[1000, 2000, 1000, 4000, 5000, 4000, 8000, 4000, 4000, 5000, 4000, 5000];

    @Prop(Number) valorInicial: number;
    @Prop(Number) fatorDecaimento: number;
    @Prop(Number) minimoMultiplicador: number;
    @Prop(Number) fatorCompensacao: number;

    private newChart() {
        const quantidade = new Array<number>();
        for (let i = 0; i < this.limitQtd; i++) {
            quantidade.push(i + 1);
        }

        //@ts-ignore
        this.myChart = echarts.init(this.$refs.gchart);

        this.myChart.setOption({
            backgroundColor: "transparent",
            tooltip: {
                trigger: "axis",
            },
            toolbox: {
                show: true,
                orient: "vertical",
                left: "right",
                top: "center",
                feature: {
                    dataZoom: {
                        yAxisIndex: "none",
                        title: " ",
                    },
                    magicType: { type: ["line", "bar"], title: " " },
                    restore: { title: " " },
                    saveAsImage: { title: " " },
                },
            },
            xAxis: {
                type: "category",
                boundaryGap: false,
                data: quantidade,
                color: "black",
            },
            yAxis: {
                type: "value",
                axisLabel: {
                    formatter: "R${value}",
                },
                color: "red",
            },
            dataZoom: [
                {
                    type: "inside",
                    start: 0,
                    end: 100,
                },
                {
                    start: 0,
                    end: 10,
                    handleIcon:
                        "M10.7,11.9v-1.3H9.3v1.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4v1.3h1.3v-1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7V23h6.6V24.4z M13.3,19.6H6.7v-1.4h6.6V19.6z",
                    handleSize: "80%",
                    handleStyle: {
                        color: "#fff",
                        shadowBlur: 3,
                        shadowColor: "rgba(0, 0, 0, 0.6)",
                        shadowOffsetX: 2,
                        shadowOffsetY: 2,
                    },
                },
            ],
            color: ["#8cc63f", "#ff6e6e", "#6ce4ff"],
            series: [
                {
                    name: this.$t("__.ts.vlrFormaFarmaceu"),
                    type: "line",
                    data: this.values,
                    markPoint: {
                        data: [
                            { type: "max", name: "???" },
                            { type: "min", name: "???" },
                        ],
                    },
                    markLine: {
                        data: [{ type: "average", name: "???" }],
                    },
                },
            ],
        });
    }

    private carregou() {
        this.$emit("carregou-grafico", true);
    }

    private loadValues() {
        this.values = null;
        this.values = new Array<number>();

        for (let i = 1; i <= this.limitQtd; i++) {
            this.values.push(
                this.formaFarmaceuticaService.getValorFormaFarmaceutica(
                    this.valorInicial,
                    i,
                    this.minimoMultiplicador,
                    this.fatorDecaimento,
                    this.fatorCompensacao,
                ),
            );
        }
        this.newChart();
    }

    //@ts-ignore
    @Watch("valorInicial")
    private onValorInicialChanged() {
        if (
            this.valorInicial == null ||
            this.fatorCompensacao == null ||
            this.fatorDecaimento == null ||
            this.minimoMultiplicador == null
        )
            return;

        this.loadValues();
    }

    //@ts-ignore
    @Watch("fatorDecaimento")
    private onFatorDecaimentoChanged() {
        if (
            this.valorInicial == null ||
            this.fatorCompensacao == null ||
            this.fatorDecaimento == null ||
            this.minimoMultiplicador == null
        )
            return;

        this.loadValues();
    }

    //@ts-ignore
    @Watch("minimoMultiplicador")
    private onMinimoMultiplicadorChanged() {
        if (
            this.valorInicial == null ||
            this.fatorCompensacao == null ||
            this.fatorDecaimento == null ||
            this.minimoMultiplicador == null
        )
            return;

        this.loadValues();
    }

    //@ts-ignore
    @Watch("fatorCompensacao")
    private onfatorCompensacaoChanged() {
        if (
            this.valorInicial == null ||
            this.fatorCompensacao == null ||
            this.fatorDecaimento == null ||
            this.minimoMultiplicador == null
        )
            return;

        this.loadValues();
    }

    private mounted() {
        // @ts-ignore
        const zoom = document.body.style.zoom;
        const zoomNumb = parseFloat(zoom.substr(0, zoom.length - 1));
        if (zoomNumb != null && zoomNumb != 100) {
            const zoomEq = (100 / zoomNumb) * 100;
            // @ts-ignore
            document.getElementById("chartFormaFarm").style.zoom = zoomEq.toString() + "%";
        }

        return Promise.all([this.newChart()])
            .then(() => {
                this.carregou();
            })
            .catch(() => {});
    }
}
