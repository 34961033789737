import EspeciePagamento from "../enum/especiePagamento";

import LancamentoMovimentacaoBaseModel from "./lancamentoMovimentacaoBaseModel";
import metodoPagamentoXFormaModel from "./metodoPagamentoXFormaModel";

export default class ContaReceberRecebimentoModel extends LancamentoMovimentacaoBaseModel {
    contabilizar: boolean = null;
    autorizacao: string = null;
    tarifa?: number = 0; //zera para teste de nao precisar zerar antes de virar
    metodoPagamentoXFormaId?: number = null;
    metodoPagamentoXForma?: metodoPagamentoXFormaModel = null;
    lancamentoOperadoraId?: number = null;
    bandeiraId?: number = null;
    especie?: EspeciePagamento = null;
}
