import Vue from "vue";
import { mapState } from "vuex";

import checkboxComponent from "@/components/child/form/checkbox.vue";
import comboComponent from "@/components/child/form/combo.vue";
import dateTimePickerComponent from "@/components/child/form/datetimepicker.vue";
import decimalComponent from "@/components/child/form/decimal.vue";
import decimalComSinalCustomComponent from "@/components/child/form/decimalComSinalCustom.vue";
import fieldsetComponent from "@/components/child/form/fieldset.vue";
import moedaComponent from "@/components/child/form/moeda.vue";
import LoadingModalComponent from "@/components/child/loadingmodal/loadingmodal";
import loadingModalComponent from "@/components/child/loadingmodal/loadingmodal.vue";
import modalComponent from "@/components/child/modal/modal.vue";
import { Component, Prop, Watch } from "@/decorators";
import PreLoadPackModel from "@/models/auxiliar/preLoadPackModel";
import EnumExtensions from "@/models/enum/extensions/enumExtensions";
import OrigemProdutoLote from "@/models/enum/origemProdutoLote";
import SituacoesVenda from "@/models/enum/situacoesVenda";
import StatusProdutoLote from "@/models/enum/statusProdutoLote";
import TipoObservacao from "@/models/enum/tipoObservacao";
import TipoVenda from "@/models/enum/tipoVenda";
import FracionamentoItemModel from "@/models/fracionamentoItemModel";
import FracionamentoModel from "@/models/fracionamentoModel";
import ItemEmbalagemFracionamentoModel from "@/models/itemEmbalagemFracionamentoModel";
import KitEmbalagemModel from "@/models/kitEmbalagemModel";
import PaginationModel from "@/models/paginationModel";
import ProdutoModel from "@/models/produto/produtoModel";
import ProdutoLoteModel from "@/models/produtoLoteModel";
import UnidadeMedidaModel from "@/models/unidadeMedidaModel";
import VendaItemModel from "@/models/vendaItemModel";
import EstoqueComprometidoService from "@/services/estoqueComprometidoService";
import IndicesConversoesUnidadeMedidasService from "@/services/indicesConversoesUnidadeMedidaService";
import KitEmbalagemService from "@/services/kitEmbalagemService";
import ProdutoService from "@/services/produto/produtoService";
import ProdutoLoteService from "@/services/produtoLoteService";
import UnidadeMedidaService from "@/services/unidadeMedidaService";
import { AppState } from "@/store/store";
import arithmeticHelper from "@/utils/common/arithmeticHelper";
import { getProdutoCombo, getProdutoLoteCombo } from "@/utils/common/combo/combotext";
import { copyObject } from "@/utils/common/copyObject";
import Delay from "@/utils/common/delay";
import ValidationErrorWrapper from "@/wrappers/validationErrorWrapper";

import KitEmbalagensComponent from "../fracionaEstoque/kitEmbalagens";
import kitEmbalagensComponent from "../fracionaEstoque/kitEmbalagens.vue";
import ShortcutComponent from "../shortcut/shortcut";
import shortcutComponent from "../shortcut/shortcut.vue";

import EstoqueComprometidoComponent from "./estoqueComprometido";
import estoqueComprometidoComponent from "./estoqueComprometido.vue";

type LoteOptions = {
    text: string;
    value: number;
    bloqueado?: boolean;
};

@Component({
    components: {
        comboComponent,
        checkboxComponent,
        decimalComponent,
        moedaComponent,
        modalComponent,
        fieldsetComponent,
        loadingModalComponent,
        decimalComSinalCustomComponent,
        dateTimePickerComponent,
        kitEmbalagensComponent,
        shortcutComponent,
        estoqueComprometidoComponent,
    },
    computed: {
        ...mapState<AppState>({
            preLoadList: state => state.preLoad.preLoadList,
        }),
        unidadeMedidaDescricao() {
            if (this.isProdutoLoteFracionamento) {
                return "Unidade";
            }
            return this.itemFracionamento.unidadeMedida.descricao ? this.itemFracionamento.unidadeMedida.descricao : "";
        },
        unidadeMedidaProdutoDescricao() {
            return this.itemFracionamento.unidadeMedida.descricao ? this.itemFracionamento.unidadeMedida.descricao : "";
        },
        quantidadeComprometidaProdutoString() {
            return `${this.quantidadeComprometidaProduto.toString().replace(".", ",")} ${
                this.unidadeMedidaProdutoDescricao
            }`;
        },
        quantidadeComprometidaLoteString() {
            return `${this.quantidadeComprometidaLote.toString().replace(".", ",")} ${
                this.unidadeMedidaProdutoDescricao
            }`;
        },
        isProdutoLoteFracionamento() {
            return this.itemFracionamento.produtoLote?.origemProdutoLote == OrigemProdutoLote.Fracionamento;
        },
        quantidadeDisponivelString() {
            if (this.isProdutoLoteFracionamento) {
                return `${(this.quantidadeEstoque - this.quantidadeComprometidaLoteFracionamento)
                    .toString()
                    .replace(".", ",")} ${this.unidadeMedidaDescricao}`;
            }
            return `${(this.quantidadeEstoque - this.quantidadeComprometidaLote).toString().replace(".", ",")} ${
                this.unidadeMedidaDescricao
            }`;
        },
    },
})
export default class VendaEstoqueComponent extends Vue {
    // State computed props
    preLoadList: PreLoadPackModel;
    isProdutoLoteFracionamento: boolean;

    private loadingModalComponent: LoadingModalComponent = null;
    private validationErrorWrapper = new ValidationErrorWrapper(this.$validator);
    private editingModel = false;
    private uplodingModel = false;

    private kitEmbalagemComponent: KitEmbalagensComponent = null;
    private shortcutComponent: ShortcutComponent = null;
    private estoqueComprometidoComponent: EstoqueComprometidoComponent = null;

    indicesConversoesUnidadeMedidasService = new IndicesConversoesUnidadeMedidasService();

    model = new VendaItemModel();
    modelFracionamento = new FracionamentoModel();
    itemFracionamento = new FracionamentoItemModel();
    kitEmbalagemService = new KitEmbalagemService();
    estoqueComprometidoService = new EstoqueComprometidoService();
    produtoLoteService = new ProdutoLoteService();
    produtoService = new ProdutoService();
    markupVenda: number = null;
    totalValorEmbalagens = 0;
    quantidadeComprometidaProduto = 0;
    quantidadeComprometidaLote = 0;
    quantidadeComprometidaLoteFracionamento = 0;
    oldValorTotalItem: number = null;
    desativaCampos = false;
    onBluringValorTotal = false;
    loadingLote = false;
    loteComprometidoId = null;
    quantidadeEstoque = 0;
    produtosOptions: Array<Object> = [];
    produtosLoteOptions: LoteOptions[] = [];
    kitEmbalagensOptions: Array<Object> = [];

    @Prop({ type: Number, default: 0 }) fracionamentoId: number;
    @Prop({ type: Number, default: 0 }) vendaItemId: number;
    @Prop({ type: Number, default: 0 }) vendaId: number;
    @Prop({ type: Number, default: 0 }) estoqueId: number;
    @Prop({ type: Number, default: SituacoesVenda.Orcamento }) situacaoVenda: SituacoesVenda;
    @Prop({ type: Number, default: 0 }) tipoVenda: number;
    @Prop({ type: Array, default: () => [] }) itensVenda;

    observacaoArmazenamento = "";

    constructor() {
        super();
    }

    private cursorFocus(refName) {
        let doc = this.$refs[refName]["$el"] as HTMLElement;
        if (doc == null) doc = this.$refs[refName] as HTMLElement;
        if (doc != null) {
            if (doc.tagName.toLowerCase() == "input") {
                this.$nextTick(() => doc.focus());
            } else {
                const inpEl = doc.getElementsByTagName("input");
                this.$nextTick(() => inpEl[0].focus());
            }
            const x = window.scrollX,
                y = window.scrollY;
            window.scrollTo(x, y - 150);
        }
    }

    public clear(newItem = true) {
        this.modelFracionamento = new FracionamentoModel();
        this.model = new VendaItemModel();
        this.itemFracionamento = new FracionamentoItemModel();
        this.modelFracionamento.itensEmbalagem = [];
        this.modelFracionamento.itens = [];
        this.totalValorEmbalagens = 0;
        if (newItem) this.cursorFocus("produtoId");

        this.editingModel = false;
        this.loadingLote = false;
        this.$forceUpdate();
        this.validationErrorWrapper.clearErrors();
        this.quantidadeEstoque = 0;
    }

    public async limparCampos() {
        this.quantidadeComprometidaProduto = 0;
        this.quantidadeComprometidaLote = 0;
        this.quantidadeComprometidaLoteFracionamento = 0;
        this.observacaoArmazenamento = "";
    }

    private async loadProdutosEstoque() {
        try {
            const data = await this.produtoService
                .listIgnoreFilterProdutoTipo(1, 999999)
                .then(response => response.json() as Promise<PaginationModel<ProdutoModel>>);

            this.produtosOptions = data.list.map(getProdutoCombo);
        } catch {}
    }

    public async onAdicionarItemEstoque() {
        this.loadingModalComponent.showLoading();

        try {
            const isValid = await this.$validator.validateAll();
            if (isValid) {
                if (this.modelFracionamento.kitEmbalagemId) {
                    const itemEmbalagemSemLote = this.modelFracionamento.itensEmbalagem.filter(p => !p.produtoLoteId);

                    if (itemEmbalagemSemLote.length > 0) {
                        this.showErrorAlert(
                            `Item de embalagem ${itemEmbalagemSemLote[0].produto.descricao} sem lote, obrigatório a informação do lote`,
                        );
                        this.loadingModalComponent.hide();
                        return false;
                    }
                }

                if (
                    this.itemFracionamento.produtoLoteId ||
                    (this.itemFracionamento.produtoLote && this.itemFracionamento.produtoLote.id)
                ) {
                    const data = await this.produtoLoteService
                        .get(
                            this.itemFracionamento.produtoLoteId
                                ? this.itemFracionamento.produtoLoteId
                                : this.itemFracionamento.produtoLote.id,
                        )
                        .then(response => response.json() as Promise<ProdutoLoteModel>);

                    this.loadingModalComponent.hide();

                    let semQuantidade = false;
                    if (this.isProdutoLoteFracionamento) {
                        semQuantidade =
                            this.quantidadeEstoque - this.quantidadeComprometidaLoteFracionamento <
                            this.itemFracionamento.quantidade;
                    } else {
                        semQuantidade =
                            this.quantidadeEstoque - this.quantidadeComprometidaLote <
                            this.itemFracionamento.quantidade;
                    }

                    if (semQuantidade) {
                        // não controla estoque
                        if (this.itemFracionamento.produto.ignoraControleEstoque) {
                            await this.$showQuestion(
                                "Sem estoque!",
                                "N\u00e3o h\u00e1 estoque suficiente para atender! Deseja continuar?",
                            );
                            //adiciona item
                            this.emitirEventoAdicionar();
                            return true;
                        } else {
                            //controla estoque fazer
                            this.showErrorAlert(
                                `Produto ${
                                    this.itemFracionamento.produto.descricao
                                } controla estoque, mas não há quantidade disponivel no lote ${this.getDescricaoLote(
                                    data,
                                )}!`,
                            );
                        }
                    } else {
                        // tem estoque adicona item
                        this.emitirEventoAdicionar();
                        return true;
                    }
                } else {
                    this.loadingModalComponent.hide();

                    if (this.itemFracionamento.produto.ignoraControleEstoque) {
                        const result = await this.$showQuestion(
                            "Lote n\u00e3o informado!",
                            "N\u00e3o foi informado um lote do produto! Deseja continuar?",
                        );

                        if (result) {
                            // como não selecionou nenhum lote e escolheu seguir sem lote limpa a propriedade
                            this.itemFracionamento.produtoLote = null;
                            this.emitirEventoAdicionar();
                            return true;
                        }
                    } else {
                        this.showErrorAlert(
                            `Produto ${this.itemFracionamento.produto.descricao} controla estoque, obrigatório a informação do lote`,
                        );
                    }
                }
            }
        } catch {}
        this.loadingModalComponent.hide();
        return false;
    }

    private emitirEventoAdicionar() {
        const vendaItem = this.getModel();
        if (this.editingModel) {
            this.$emit("edicao-item", vendaItem);
        } else {
            this.$emit("inclusao-item", vendaItem);
        }
        this.clear();
    }

    private getModel() {
        this.model = new VendaItemModel();

        this.modelFracionamento.itensEmbalagem.forEach(item => {
            item.id = this.fracionamentoId ? item.id : null;
            item.fracionamentoId = this.fracionamentoId;
        });

        if (this.modelFracionamento.itensEmbalagem.length == 0) {
            this.modelFracionamento.kitEmbalagemId = null;
        }

        this.modelFracionamento.itens[0] = this.itemFracionamento;
        this.modelFracionamento.quantidade = this.itemFracionamento.quantidade;
        this.modelFracionamento.valorProdutos = this.itemFracionamento.valor;

        this.modelFracionamento.dataValidade = null;
        if (this.itemFracionamento.produtoLote != null) {
            this.model.produtoLoteDescricao = this.getDescricaoLote(this.itemFracionamento.produtoLote);
            this.modelFracionamento.dataValidade = this.itemFracionamento.produtoLote.dataValidade;
        }

        this.model.acrescimo = this.modelFracionamento.acrescimo;
        this.model.desconto = this.modelFracionamento.desconto;
        this.model.fracionamento = this.modelFracionamento;
        this.model.fracionamentoId = this.fracionamentoId;
        this.model.vendaId = this.vendaId;
        this.model.id = this.vendaItemId;
        this.model.produtoDescricao = this.itemFracionamento.produto.descricao;
        this.model.quantidade = this.itemFracionamento.quantidade as number;
        this.model.produtoId = this.itemFracionamento.produtoId;
        this.model.produtoLoteId = this.itemFracionamento.produtoLoteId;
        this.model.valorTotal = this.modelFracionamento.valorTotal;
        this.model.valorUnitario = this.modelFracionamento.valorProdutos;
        this.model.vendaEstoque = true;
        this.model.tipoNF = this.modelFracionamento.tipoNF;
        this.model.movimentaEstoque = this.modelFracionamento.movimentaEstoque;
        this.model.separado = this.modelFracionamento.separado;
        return this.model;
    }

    private async getModelFracionamento(model: VendaItemModel) {
        this.modelFracionamento.updateFrom(model.fracionamento);
        this.itemFracionamento.updateFrom(model.fracionamento.itens[0]);
        this.model.produtoId = this.itemFracionamento.produtoId;
        await this.loadLotesProduto();
        this.modelFracionamento.markupItemVenda = model.fracionamento.markupItemVenda;
        this.modelFracionamento.itensEmbalagem = model.fracionamento.itensEmbalagem;
        this.modelFracionamento.valorEmbalagem = model.fracionamento.valorEmbalagem;
    }

    // @ts-ignore
    @Watch("model.produtoId")
    private async onProdutoIdChanged() {
        if (!this.uplodingModel) {
            this.produtosLoteOptions = [];
            this.itemFracionamento.produtoLote = new ProdutoLoteModel();
            this.itemFracionamento.produtoLoteId = null;
            this.limparCampos();

            if (!this.model.produtoId) return;

            this.loadingModalComponent.showLoading();

            await this.loadProduto(this.model.produtoId);
            await this.loadLotesProduto();
            this.itemFracionamento.quantidade = this.modelFracionamento.quantidade;
            this.calcularValorTotal();
            this.loadingModalComponent.hide();
            await this.loadQuantidadeComprometida().withLoading();
        } else if (this.model.produtoId) {
            await this.loadQuantidadeComprometida().withLoading();
        }
    }

    private async loadProduto(id: number) {
        try {
            const data = await this.produtoService.get(id).resolveWithJSON<ProdutoModel>();
            this.modelFracionamento.unidadeMedidaId = data.unidadeMedidaEstoqueId;
            this.$emit("onChangeMovimentaEstoque", !data.ignoraControleEstoque);
            this.itemFracionamento.produto = new ProdutoModel();
            this.itemFracionamento.produto.updateFrom(data);
            this.itemFracionamento.produtoId = data.id;
            this.modelFracionamento.markupItemVenda = await this.getMarkupByQuantidade();

            if (this.itemFracionamento.produto.id != null) {
                const unidade = await new UnidadeMedidaService()
                    .get(this.modelFracionamento.unidadeMedidaId)
                    .resolveWithJSON<UnidadeMedidaModel>();
                this.itemFracionamento.unidadeMedidaId = unidade.id;
                this.itemFracionamento.unidadeMedida = new UnidadeMedidaModel();
                this.itemFracionamento.unidadeMedida.updateFrom(unidade);
            }

            if (data.produtoObservacoes.length > 0) {
                this.observacaoArmazenamento = "";

                const observacoesArmazenamento = data.produtoObservacoes.filter(
                    s => s.tipo == TipoObservacao.ConservacaoTransporte,
                );
                if (observacoesArmazenamento.length > 0) {
                    this.observacaoArmazenamento = observacoesArmazenamento.map(p => p.texto).join("; ");
                }
            }
        } catch {}
    }

    private async loadLotesProduto() {
        try {
            const lotes = await this.produtoLoteService
                .listByProdutoIdAndEstoqueId(this.itemFracionamento.produtoId, this.estoqueId, 0.00001, 0, 1, 999999)
                .resolveWithJSON<PaginationModel<ProdutoLoteModel>>();

            this.desativaCampos = false;

            this.produtosLoteOptions = lotes.list
                .filter(
                    p =>
                        !(
                            p.loteFoiFracionado &&
                            p.origemProdutoLote == OrigemProdutoLote.Interno &&
                            p.statusProdutoLote == StatusProdutoLote.Liberado
                        ),
                )
                .map(p => ({
                    value: p.id,
                    text:
                        p.descricao +
                        (p.statusProdutoLote != StatusProdutoLote.Liberado
                            ? ` [BLOQUEADO - ${EnumExtensions.getNameByValue(StatusProdutoLote, p.statusProdutoLote)}]`
                            : ""),
                    bloqueado: p.statusProdutoLote != StatusProdutoLote.Liberado,
                }));

            if (
                !this.itemFracionamento.produto.ignoraControleEstoque && // verifica se não tem Lote
                this.produtosLoteOptions.length == 0 &&
                this.itemFracionamento.produtoLoteId == null
            ) {
                this.desativaCampos = true;
                this.onAlertSemEstoque(this.itemFracionamento.produto.descricao);
            } else if (this.itemFracionamento.produto.ignoraControleEstoque) {
                this.desativaCampos = false;
                this.produtosLoteOptions.push({
                    value: 0,
                    text: "Ignorar controle de estoque",
                });

                if (!this.editingModel) {
                    this.itemFracionamento.produtoLote = new ProdutoLoteModel();
                }

                this.itemFracionamento.valor = this.valorUnitario();
                if (this.produtosLoteOptions.length > 0 && lotes.total == 0) {
                    this.itemFracionamento.produtoLoteId = 0;
                }

                this.modelFracionamento.markupItemVenda = await this.getMarkupByQuantidade();
            } else if (this.itemFracionamento.produtoLoteId != null) {
                this.desativaCampos =
                    this.situacaoVenda == SituacoesVenda.Expedicao ||
                    this.situacaoVenda == SituacoesVenda.EmFaturamento ||
                    this.situacaoVenda == SituacoesVenda.Faturado ||
                    this.situacaoVenda == SituacoesVenda.Entrega ||
                    this.situacaoVenda == SituacoesVenda.ExpedicaoNota ||
                    this.situacaoVenda == SituacoesVenda.Concluido;

                this.model.produtoLoteId = this.itemFracionamento.produtoLoteId;
            }

            this.$emit("desativa-campos", this.desativaCampos);

            const lotesLiberados = lotes.list.filter(p => p.statusProdutoLote == StatusProdutoLote.Liberado);

            if (this.itemFracionamento.produtoLoteId == null && lotesLiberados.length > 0) {
                if (lotesLiberados.length == 1) {
                    this.itemFracionamento.produtoLote = lotesLiberados[0];
                } else if (this.itemFracionamento.produto.lotePadraoId != null) {
                    this.itemFracionamento.produtoLote = lotesLiberados.find(
                        l => l.id == this.itemFracionamento.produto.lotePadraoId,
                    );
                }

                if (!this.itemFracionamento.produtoLote) {
                    this.itemFracionamento.produtoLote = lotesLiberados.reduce((prox, atual) =>
                        atual == null || atual.dataValidade > prox.dataValidade ? prox : atual,
                    );
                }
                this.modelFracionamento.markupItemVenda = await this.getMarkupByQuantidade();
            }

            // Se está consultando um item com lote zerado, carrega todos os lotes para exibir corretamente na combo
            if (
                this.desativaCampos &&
                this.itemFracionamento.produtoLoteId &&
                !this.produtosLoteOptions.some(p => p["value"] == this.itemFracionamento.produtoLoteId)
            ) {
                const data = await this.produtoLoteService
                    .listByProdutoIdAndEstoqueId(this.itemFracionamento.produtoId, this.estoqueId, 0, 0, 1, 999999)
                    .resolveWithJSON<PaginationModel<ProdutoLoteModel>>();
                this.produtosLoteOptions = data.list.map(getProdutoLoteCombo);
                this.itemFracionamento.produtoLote.id = this.itemFracionamento.produtoLoteId;
            }
        } catch {}
    }

    private async loadQuantidadeComprometida() {
        try {
            this.quantidadeComprometidaProduto = await this.estoqueComprometidoService
                .getQuantidadeComprometida(this.model.produtoId, this.itemFracionamento.id)
                .resolveWithJSON<number>();
        } catch {}
    }

    private async loadQuantidadeComprometidaLote(loteId: number) {
        try {
            this.quantidadeComprometidaLote = await this.estoqueComprometidoService
                .getQuantidadeComprometidaLote(loteId, this.itemFracionamento.id, this.estoqueId)
                .resolveWithJSON<number>();

            if (this.isProdutoLoteFracionamento) {
                const indice = await this.indicesConversoesUnidadeMedidasService.getIndiceConversao(
                    this.itemFracionamento.produtoLote?.unidadeMedidaFracionadoId,
                    this.itemFracionamento.produto?.unidadeMedidaEstoqueId,
                );
                this.quantidadeComprometidaLoteFracionamento =
                    this.quantidadeComprometidaLote / (indice * this.itemFracionamento.produtoLote?.volumeFracionado);
            }
        } catch {}
    }

    // @ts-ignore
    @Watch("itemFracionamento.valor")
    private onValorUnitarioChanged() {
        this.calcularValorTotal();
    }

    // @ts-ignore
    @Watch("modelFracionamento.markupItemVenda")
    private onMarkupVendaChanged() {
        if (!this.onBluringValorTotal) {
            this.itemFracionamento.valor = this.valorUnitario();
        }
    }

    // @ts-ignore
    @Watch("modelFracionamento.considerarEmbalagem")
    private onConsiderarEmbalagemChanged() {
        this.calculaValorEmbalagem();
    }

    // @ts-ignore
    @Watch("modelFracionamento.valorEmbalagem")
    private onValorEmbalagemChanged() {
        this.calcularValorTotal();
    }

    // @ts-ignore
    @Watch("modelFracionamento.acrescimo")
    private onAcrescimoChanged() {
        this.calcularValorTotal();
    }

    // @ts-ignore
    @Watch("modelFracionamento.desconto")
    private onDescontoChanged() {
        this.calcularValorTotal();
    }

    // @ts-ignore
    @Watch("modelFracionamento.descontoPercentual")
    private onDescontoPercentChanged() {
        if (!(this.editingModel && this.uplodingModel)) {
            this.modelFracionamento.desconto =
                this.modelFracionamento.valorTotal * (this.modelFracionamento.descontoPercentual / 100);
        }
    }

    // @ts-ignore
    @Watch("modelFracionamento.acrescimoPercentual")
    private onAcrescimoPercentChanged() {
        if (!(this.editingModel && this.uplodingModel)) {
            this.modelFracionamento.acrescimo =
                this.modelFracionamento.valorTotal * (this.modelFracionamento.acrescimoPercentual / 100);
        }
    }

    // @ts-ignore
    @Watch("itemFracionamento.quantidade")
    private async onQuantidadeChanged() {
        this.modelFracionamento.quantidade = this.itemFracionamento.quantidade;

        if (this.itemFracionamento.produto?.produtoMarkupDinamico.length > 0) {
            this.modelFracionamento.markupItemVenda = await this.getMarkupByQuantidade();
        }

        this.calcularValorTotal();
    }

    // @ts-ignore
    @Watch("modelFracionamento.valorTotal")
    private onValorChanged() {
        this.$emit("atualiza-total-item", this.modelFracionamento.valorTotal);
    }

    // @ts-ignore
    @Watch("itemFracionamento.produtoLote.id")
    private async onChangeLote(): Promise<void> {
        if (this.loadingLote) return;

        if (this.itemFracionamento.produtoLote.id) {
            const loteNotLiberado = this.produtosLoteOptions.find(
                p => p.value == this.itemFracionamento.produtoLote.id,
            )?.bloqueado;

            if (loteNotLiberado) {
                await this.$showError(this.$t("__.ts.erro"), "Lote selecionado está bloqueado");
                this.itemFracionamento.produtoLoteId = null;
                this.itemFracionamento.produtoLote.id = null;
                return;
            }
        }

        this.itemFracionamento.produtoLoteId = this.itemFracionamento.produtoLote.id;

        if (this.itemFracionamento.produtoLoteId) {
            this.loadingLote = true;
            const data = await this.produtoLoteService
                .get(this.itemFracionamento.produtoLoteId)
                .resolveWithJSON<ProdutoLoteModel>();

            const listLotes = data.estoqueProdutoLote.find(p => p.estoqueId == this.estoqueId);
            this.quantidadeEstoque = listLotes.estoqueAtual;

            this.itemFracionamento.produtoLote = data;
            this.itemFracionamento.produtoLote.dataValidade = new Date(
                this.itemFracionamento.produtoLote.dataValidade.toString(),
            );
            this.itemFracionamento.valor = this.valorUnitario();

            await this.loadQuantidadeComprometidaLote(this.itemFracionamento.produtoLoteId).withLoading();
            this.loadingLote = false;
        } else {
            copyObject(new ProdutoLoteModel(), this.itemFracionamento.produtoLote, ["id"]);
            this.quantidadeComprometidaLote = 0;
            this.quantidadeComprometidaLoteFracionamento = 0;
        }
    }

    private valorUnitario(valorEmbalagem = 0) {
        return this.modelFracionamento.calcularValorUnitario(
            this.itemFracionamento,
            valorEmbalagem,
            this.tipoVenda === TipoVenda.Transferencia,
        );
    }

    private async calcularValorTotal() {
        if (!this.onBluringValorTotal && !this.uplodingModel) {
            const itemFracionamento = this.modelFracionamento.calcularValorTotal(this.itemFracionamento);
            this.itemFracionamento.valor = itemFracionamento.valor;
            this.itemFracionamento.valorTotal = itemFracionamento.valorTotal;
            this.oldValorTotalItem = this.modelFracionamento.valorTotal;
        }
    }

    private async onBlurValorTotal(newValue: number) {
        this.onBluringValorTotal = true;
        if (this.itemFracionamento.valorTotal != this.oldValorTotalItem) {
            if (this.modelFracionamento.considerarEmbalagem) {
                this.modelFracionamento.considerarEmbalagem = false;
            }
            if (this.modelFracionamento.desconto) {
                newValue += this.modelFracionamento.desconto;
            }
            if (this.modelFracionamento.acrescimo) {
                newValue -= this.modelFracionamento.acrescimo;
            }
            this.modelFracionamento.valorProdutos = newValue;
            this.oldValorTotalItem = this.itemFracionamento.valorTotal;
            const newvalueUnit = newValue / this.itemFracionamento.quantidade;
            this.itemFracionamento.valor = newvalueUnit;
            this.modelFracionamento.markupItemVenda = 0;
            const markupCalculado = (newvalueUnit / this.valorUnitario() - 1) * 100;
            this.modelFracionamento.markupItemVenda = arithmeticHelper.round(markupCalculado, 4);
            this.modelFracionamento.valorTotal = newValue;
            this.$emit("atualiza-total-item", this.modelFracionamento.valorTotal);
        }
        // Aguarda rodar o watch do markupItemVenda para não recalcular o valor total do item
        await Delay(200);
        this.onBluringValorTotal = false;
    }

    async loadModel(item: VendaItemModel) {
        this.loadingModalComponent.showLoading();
        this.editingModel = true;
        this.uplodingModel = true;
        await this.getModelFracionamento(item);
        this.cursorFocus("quantidade");
        this.uplodingModel = false;

        this.kitEmbalagemComponent.setFracionamento(this.modelFracionamento);
        this.loadingModalComponent.hide();
    }

    private tabIndex(valor) {
        if (valor != null && valor > 0) return -1;

        return null;
    }

    // @ts-ignore
    @Watch("modelFracionamento.kitEmbalagemId")
    private async onKitChanged(newValue: number, oldValue: number) {
        this.loadingModalComponent.showLoading();

        if (
            !this.uplodingModel &&
            this.modelFracionamento.kitEmbalagemId &&
            oldValue != newValue &&
            !(oldValue == null && this.modelFracionamento.itensEmbalagem.length > 0)
        ) {
            const data = await this.kitEmbalagemService
                .get(this.modelFracionamento.kitEmbalagemId)
                .then(r => r.json() as Promise<KitEmbalagemModel>);

            this.modelFracionamento.itensEmbalagem = [];
            data.kitEmbalagemItens.forEach(item => {
                const newItem = new ItemEmbalagemFracionamentoModel();
                newItem.updateFrom(item, ["id"]);
                this.modelFracionamento.itensEmbalagem.push(newItem);
            });
        }

        if (!this.modelFracionamento.kitEmbalagemId) {
            this.modelFracionamento.itensEmbalagem = [];
            this.modelFracionamento.considerarEmbalagem = false;
            this.modelFracionamento.valorEmbalagem = 0;
        }

        this.kitEmbalagemComponent.setFracionamento(this.modelFracionamento);

        this.loadingModalComponent.hide();
    }

    private onChangeEmbalagens(itens: ItemEmbalagemFracionamentoModel[]) {
        this.modelFracionamento.itensEmbalagem = itens;
        this.calculaValorEmbalagem();
    }

    private calculaValorEmbalagem() {
        this.modelFracionamento.valorEmbalagem = 0;

        if (this.modelFracionamento.considerarEmbalagem) {
            this.modelFracionamento.valorEmbalagem = this.modelFracionamento.itensEmbalagem.reduce(
                (acc, kit) => acc + Number(kit.valorTotal),
                0,
            );
            this.calcularValorTotal();
        }
    }

    private async onAlertSemEstoque(produto: string) {
        await this.$showError(
            this.$t("__.ts.erro"),
            `Não há lotes disponiveis de ${produto}! O produto possui controle de estoque ativo e não será incluido por falta de lotes.`,
        );

        this.limparCampos();
    }

    private async showErrorAlert(message: string) {
        await this.$showError(this.$t("__.ts.erro"), message);
    }

    private getDescricaoLote(lote: ProdutoLoteModel) {
        return lote.sequencialCompra > 1 ? `${lote.descricao} (${lote.sequencialCompra})` : lote.descricao;
    }

    private onShowEstoqueComprometido() {
        this.loteComprometidoId = this.itemFracionamento.produtoLoteId;
        this.shortcutComponent.show();
    }

    private async getMarkupByQuantidade() {
        // Se não utiliza markup dinamico
        if (this.itemFracionamento.produto.produtoMarkupDinamico.length == 0) {
            return this.itemFracionamento.produto.markup;
        }

        let quantidade = this.itemFracionamento.quantidade;
        if (this.isProdutoLoteFracionamento) {
            const indice = await this.indicesConversoesUnidadeMedidasService.getIndiceConversao(
                this.itemFracionamento.produtoLote?.unidadeMedidaFracionadoId,
                this.itemFracionamento.produto?.unidadeMedidaEstoqueId,
            );
            quantidade = quantidade * this.itemFracionamento.produtoLote.volumeFracionado * indice;
        }

        return this.itemFracionamento.getMarkupByQuantidade(quantidade);
    }

    private async mounted() {
        this.loadingModalComponent = this.$refs.loadingModalComponent as LoadingModalComponent;
        this.kitEmbalagemComponent = this.$refs.kitEmbalagemComponent as KitEmbalagensComponent;
        this.shortcutComponent = this.$refs.shortcutComponent as ShortcutComponent;
        this.estoqueComprometidoComponent = this.$refs.estoqueComprometidoComponent as EstoqueComprometidoComponent;

        Promise.all([this.loadProdutosEstoque()])
            .withLoading()
            .then(() => {
                this.kitEmbalagensOptions = this.preLoadList.kitEmbalagemCombo();
            })
            .catch(() => {});
    }
}
