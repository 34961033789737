import UsuarioModel from "../models/usuarioModel";
import nfetch from "../utils/events/nfetch";

import { IService } from "./base/iService";

export default class UsuarioService extends IService<UsuarioModel> {
    constructor() {
        super("Usuario");
    }

    public getUsuarioLogado(): Promise<Response> {
        //@ts-ignore
        return nfetch(`/${this.controllerName}/GetUsuarioLogado`, {
            credentials: "same-origin",
            headers: {
                PharmUpSession: this.getCookie("PharmUpSession"),
            },
        })
            .then((response: Response) => {
                if (response.ok) {
                    return response;
                } else {
                    return this.mensagemErro(response, "Get");
                }
            })
            .catch(() => {});
    }

    public trocaFranquiaUsuario(franquiaId: number, sessionId: string): Promise<Response> {
        //@ts-ignore
        return nfetch(`/${this.controllerName}/TrocaFranquia?franquiaId=${franquiaId}&sessionId=${sessionId}`, {
            method: "post",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json; charset=UTF-8",
                "XSRF-TOKEN": this.getCookie("XSRF-TOKEN"),
            },
        });
    }

    public alterarSenha(model: UsuarioModel): Promise<Response> {
        //@ts-ignore
        // ;
        return nfetch(`/${this.controllerName}/AlterarSenha`, {
            method: "post",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json; charset=UTF-8",
                "XSRF-TOKEN": this.getCookie("XSRF-TOKEN"),
            },
            body: JSON.stringify(model),
        })
            .then((response: Response) => {
                if (response.ok) {
                    return response;
                } else {
                    return this.mensagemErro(response, "Save");
                }
            })
            .catch(() => {});
    }

    public listByFranquiaId(franquiaId: number = null): Promise<Response> {
        return nfetch(`/${this.controllerName}/ListByFranquiaId?franquiaId=${franquiaId}`, {
            credentials: "same-origin",
        });
    }

    public resetarSenha(id: number): Promise<Response> {
        //@ts-ignore
        return nfetch(`/${this.controllerName}/ResetarSenha?usuarioId=${id}`, {
            method: "post",
            credentials: "same-origin",
        });
    }

    public validarSenhaUsuarioAdmin(senha: string): Promise<Response> {
        //@ts-ignore
        return nfetch(`/${this.controllerName}/ValidarSenhaUsuarioAdmin?senha=${senha}`, {
            credentials: "same-origin",
        });
    }

    public getUsuariosFranquia(): Promise<Response> {
        //@ts-ignore
        return nfetch(`/${this.controllerName}/GetUsuariosFranquia`, {
            credentials: "same-origin",
        });
    }

    public enviarEmailNotasDaVersao(notasDaVersao: string): Promise<Response> {
        //@ts-ignore
        return nfetch(`/${this.controllerName}/EnviarEmailNotasDaVersao`, {
            method: "post",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json; charset=UTF-8",
                "XSRF-TOKEN": this.getCookie("XSRF-TOKEN"),
            },
            body: JSON.stringify(notasDaVersao),
        });
    }

    public getShortToken(): Promise<Response> {
        return nfetch(`/${this.controllerName}/GetShortToken`, {
            credentials: "same-origin",
        });
    }
}
