import Vue from "vue";

import cnpjComponent from "@/components/child/form/cnpj.vue";
import cpfComponent from "@/components/child/form/cpf.vue";
import fieldsetComponent from "@/components/child/form/fieldset.vue";
import moedaComponent from "@/components/child/form/moeda.vue";
import { Component, Prop, Watch } from "@/decorators";
import TiposPessoa from "@/models/enum/tiposPessoa";
import TransporteNfeModel from "@/models/notafiscaleletronica/dados/informacoes/transporte/transporteNfeModel";

@Component({
    components: {
        fieldsetComponent,
        cpfComponent,
        cnpjComponent,
        moedaComponent,
    },
})
export default class ImportacaoNfeTransporteComponent extends Vue {
    tiposPessoa = TiposPessoa;

    @Prop({ type: TransporteNfeModel, required: true }) model: TransporteNfeModel;
    @Prop(Number) valorFrete: number;

    valorFreteInterno = 0;

    @Watch("valorFrete")
    private onChangeValorFrete() {
        this.valorFreteInterno = this.valorFrete;
    }

    @Watch("valorFreteInterno")
    private onChangeValorFreteInterno() {
        this.$emit("onChangeValorFrete", this.valorFreteInterno);
    }
}
