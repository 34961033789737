import Vue from "vue";
import { mapMutations } from "vuex";

import actionBarComponent from "@/components/child/actionBar/actionBar.vue";
import cepFieldComponent from "@/components/child/form/cepfield.vue";
import checkboxComponent from "@/components/child/form/checkbox.vue";
import cnpjComponent from "@/components/child/form/cnpj.vue";
import comboComponent from "@/components/child/form/combo.vue";
import cpfComponent from "@/components/child/form/cpf.vue";
import dataTooltipComponent from "@/components/child/form/datatooltip.vue";
import dateTimePickerComponent from "@/components/child/form/datetimepicker.vue";
import decimalComponent from "@/components/child/form/decimal.vue";
import fieldsetComponent from "@/components/child/form/fieldset.vue";
import textareaComponent from "@/components/child/form/textarea.vue";
import gridComponent from "@/components/child/grid/grid.vue";
import LoadingModalComponent from "@/components/child/loadingmodal/loadingmodal";
import loadingModalComponent from "@/components/child/loadingmodal/loadingmodal.vue";
import { Component, Prop, Watch } from "@/decorators";
import CepModel from "@/models/cepModel";
import EnumExtensions from "@/models/enum/extensions/enumExtensions";
import GenerosPessoa from "@/models/enum/generosPessoa";
import TiposPessoa from "@/models/enum/tiposPessoa";
import Ufs from "@/models/enum/ufs";
import { TypeElement } from "@/models/modeloImpressao/modeloImpressaoSessaoModel";
import PaginationModel from "@/models/paginationModel";
import TransportadoraModel from "@/models/transporte/transportadoraModel";
import TransportadorModel from "@/models/transporte/transportadorModel";
import ValidationErrorModel from "@/models/validationErrorModel";
import CidadeService from "@/services/external/cidadeService";
import TransportadoraService from "@/services/transporte/transportadoraService";
import TransportadorService from "@/services/transporte/transportadorService";
import Print from "@/utils/common/printForm";
import ValidationErrorWrapper from "@/wrappers/validationErrorWrapper";

import "../crud.scss";

@Component({
    components: {
        textareaComponent,
        fieldsetComponent,
        comboComponent,
        checkboxComponent,
        cpfComponent,
        cnpjComponent,
        decimalComponent,
        cepFieldComponent,
        dateTimePickerComponent,
        dataTooltipComponent,
        loadingModalComponent,
        gridComponent,
        actionBarComponent,
    },
    methods: mapMutations(["LOAD_LIST"]),
})
export default class TransportadorEditComponent extends Vue {
    private service = new TransportadorService();
    private cidadeService = new CidadeService();
    private validationErrorWrapper = new ValidationErrorWrapper(this.$validator);
    private loadingModalComponent: LoadingModalComponent = null;
    //private enderecoEntregaComponent: EnderecoEntregaComponent = null;

    model: TransportadorModel = new TransportadorModel();
    modelId = 0;

    @Prop({ type: Boolean, default: false }) CalledByShortCut: boolean;
    @Prop({ type: Boolean, default: false }) isModal: boolean;

    private hideExtras = false;

    tiposPessoa = TiposPessoa;
    tiposPessoaOptions: Array<Object> = [
        { text: "F\u00edsica", value: TiposPessoa.Fisica },
        { text: "Jur\u00eddica", value: TiposPessoa.Juridica },
    ];

    generosPessoaOptions: Array<Object> = [
        { text: "Masculino", value: GenerosPessoa.Masculino },
        { text: "Feminino", value: GenerosPessoa.Feminino },
    ];

    dadosCep: CepModel = new CepModel();
    ufOptions: Array<Object> = EnumExtensions.getNamesAndValuesOrderedByNames(Ufs);
    transportadorasOptions: Array<Object> = [];
    cidadesOptions: Array<Object> = [];

    constructor() {
        super();
        this.model.tipoPessoa = TiposPessoa.Fisica;
    }

    private loadTransportadoras() {
        new TransportadoraService()
            .combo()
            .then(response => {
                if (response.ok) return response.json() as Promise<PaginationModel<TransportadoraModel>>;
            })
            .then(data => {
                this.transportadorasOptions = data.list.map(item => {
                    return { value: item.id, text: item.nome };
                });
            });
    }
    public load() {
        this.model = new TransportadorModel();
        this.validationErrorWrapper.clearErrors();
        this.loadTransportadoras();

        if (this.modelId > 0) {
            this.loadingModalComponent.showLoading();
            this.service
                .get(this.modelId)
                .then(response => {
                    return response.json() as Promise<TransportadorModel>;
                })
                .then(async data => {
                    this.model.updateFrom(data);
                    await this.onLoadCidades(this.model.estadoId);

                    this.loadingModalComponent.hide();
                })
                .catch(error => {
                    this.$router.back();
                    this.loadingModalComponent.hide();
                });
        } else {
            this.model.tipoPessoa = TiposPessoa.Fisica;
        }
    }

    public save() {
        this.$validator
            .validateAll()
            .then(isValid => {
                if (isValid) {
                    this.loadingModalComponent.showSaving();
                    if (this.model.cidadeId) {
                        this.model.cidade = this.cidadesOptions.filter(item => item["value"] == this.model.cidadeId)[0][
                            "text"
                        ];
                    }
                    this.service[!this.model.id ? "insert" : "update"](this.model)
                        .then(async response => {
                            const nId = Number(response.headers.get("Id"));
                            this.loadingModalComponent.hide();
                            if (response.ok) {
                                await this.$showInclusaoUpdate(this.model.id);
                                if (this.CalledByShortCut) {
                                    if (!this.model.id) {
                                        this.model.id = nId;
                                    }

                                    this.$emit("save-ok", this.model);
                                } else {
                                    this.$router.back();
                                }
                            } else {
                                return response.json() as Promise<ValidationErrorModel[]>;
                            }
                        })
                        .then(data => {
                            this.validationErrorWrapper.showErrors(data);
                        })
                        .catch(() => {
                            this.loadingModalComponent.hide();
                        });
                }
            })
            .catch(() => {});
    }

    private cancel() {
        if (this.CalledByShortCut) {
            this.model = new TransportadorModel();

            this.validationErrorWrapper.clearErrors();
        }
        this.$router.back();
    }

    private async onCepChanged(data: CepModel) {
        this.dadosCep = data;
        this.model.bairro = data.bairro;
        this.model.complemento = data.complemento;
        this.model.endereco = data.endereco;
        this.model.estadoId = data.uf;
        await this.onLoadCidades(this.model.estadoId);
        this.model.cidadeId = data.cidadeId;
    }

    private replicarLocalizacao() {
        //this.enderecoEntregaComponent.replicar(this.model);
    }

    //carrega dados em endereço de entrega automatico
    //@ts-ignore
    //@Watch("model.numero")
    //private teste1() {
    //    this.model.enderecosEntrega.numero = this.model.numero;
    //}

    //carrega dados em endereço de entrega automatico
    //@ts-ignore
    //@Watch("model.complemento")
    //private teste2() {
    //    this.model.enderecosEntrega.complemento = this.model.complemento;
    //}

    private onCepInvalid() {
        this.$showInfo(this.$t("__.ts.pesqCEP"), this.$t("__.ts.pesqNaoEncResul"));
    }

    //@ts-ignore
    @Watch("model.tipoPessoa")
    private onTipoPessoaChanged(tipoPessoa: TiposPessoa) {
        switch (tipoPessoa) {
            case TiposPessoa.Fisica:
                this.model.nomeFantasia = null;
                this.model.cnpj = null;
                this.model.inscricaoEstadual = null;
                this.model.inscricaoMunicipal = null;
                this.model.razaoSocial = null;
                break;
            case TiposPessoa.Juridica:
                this.model.nome = null;
                this.model.cpf = null;
                this.model.rg = null;
                this.model.dataNascimento = null;
                this.model.genero = null;
                this.model.orgaoExpedidor = null;
                this.model.ufExpedidor = null;
                break;
        }
    }

    private async onLoadCidades(estadoId: number) {
        try {
            this.cidadesOptions = [];
            if (estadoId) {
                this.cidadesOptions = await this.cidadeService.get(estadoId).withLoading();
            }
        } catch {}
    }

    //@ts-ignore
    @Watch("model.nome")
    private onNomeChanged(nome) {
        this.loadingModalComponent.showLoading();

        this.loadingModalComponent.hide();
    }

    private mounted() {
        this.loadingModalComponent = this.$refs.loadingModalComponent as LoadingModalComponent;
        this.loadingModalComponent.showLoading();
        // this.enderecoEntregaComponent = this.$refs.enderecoEntregaComponent as EnderecoEntregaComponent;
        this.hideExtras = this.CalledByShortCut ? true : false;

        if (this.$route.params.id && !this.hideExtras) {
            this.modelId = +this.$route.params.id;

            this.load();
        }
        if (this.CalledByShortCut) {
            this.model = new TransportadorModel();
            this.validationErrorWrapper.clearErrors();
        }
        this.loadTransportadoras();
        this.loadingModalComponent.hide();
    }
    print() {
        new Print().print(
            [
                {
                    elementId: "Transportador",
                    title: "Transportador",
                    type: TypeElement.Form,
                },
                {
                    elementId: "localizacao",
                    title: "Localização",
                    type: TypeElement.Form,
                },
                {
                    elementId: "entrega",
                    title: "Endereço de Entrega",
                    type: TypeElement.Form,
                },
                {
                    elementId: "contato",
                    title: "Contato",
                    type: TypeElement.Form,
                },
            ],
            3,
        );
    }
}
