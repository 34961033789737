import BaseModel from "../base/baseModel";
import TipoCalculoAssociacao from "../enum/tipoCalculoAssociacao";

import ProdutoModel from "./produtoModel";

export default class ProdutoAssociacaoModel extends BaseModel {
    id?: number = null;

    produtoId?: number = null;
    produtoDescricao: string = null;

    produtoAssociadoId: number = null;
    produtoAssociadoDescricao: string = null;
    produtoAssociado: ProdutoModel = null;

    formaFarmaceuticaId: number = null;
    formaFarmaceuticaDescricao: string = null;

    percentual = 0;
    excipiente = false;
    desmembrado = false;
    tipoCalculoAssociacao: TipoCalculoAssociacao = TipoCalculoAssociacao.Ativo;
    tipoCalculoAssociacaoDescricao: string = null;
}