// eslint-disable-next-line no-unused-vars
interface Array<T> {
    groupBy<T>(fn: (data: T) => string): {
        key: string;
        data: T[];
    }[];
    sum(prop: keyof T): number;
}

Array.prototype.sum = function <T>(this: T[], prop: keyof T): number {
    if (this.length === 0) {
        return 0;
    }

    return this.reduce((acc, p) => acc + Number(p[prop] ?? 0), 0);
};

Array.prototype.groupBy = function <T>(this: T[], fn: (data: T) => string) {
    const reduced = this.reduce((acc, p) => {
        const groupByField = fn(p);

        if (!acc[groupByField]) {
            acc[groupByField] = [];
        }
        acc[groupByField].push(p);
        return acc;
    }, {} as { [key: string]: T[] });

    return Object.keys(reduced).map(p => ({
        key: p,
        data: reduced[p],
    }));
};
