var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "crud crud-list" },
    [
      _c("div", { staticClass: "info-div" }, [
        _c("h1", { staticClass: "title" }, [
          _vm._v(
            _vm._s(_vm.$t("__.Crud.especieanimal.list_vue_html.espAnimal"))
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "content" }, [
          _c("div", { staticClass: "info-panel" }, [
            _c("span", [_vm._v(_vm._s(_vm.total))]),
            _vm._v(" "),
            _c("span", [
              _vm._v(
                _vm._s(_vm.$t("__.Crud.especieanimal.list_vue_html.espAnimal"))
              ),
            ]),
            _vm._v(" "),
            _c("span", [
              _vm._v(
                _vm._s(
                  _vm.$t("__.Crud.especieanimal.list_vue_html.cadastradas")
                )
              ),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("grid-component", {
        ref: "gridcomponent",
        attrs: {
          actionName: "especie_de_animal",
          columns: _vm.gridColumns,
          data: _vm.gridData,
          "page-index": _vm.pageIndex,
          "page-size": _vm.pageSize,
          tituloPagina: "Espécies de Animal",
          total: _vm.total,
        },
        on: {
          "change-filter-key": _vm.onChangeFilterKey,
          "change-page": _vm.onChangePage,
          "change-sort": _vm.onChangeSort,
          "create-item": _vm.onCreateItem,
          "edit-item": _vm.onEditItem,
          "gerar-impressao": function ($event) {
            _vm.pags = true
            _vm.tipoDocGerar = 2
          },
          "gerar-pdf": function ($event) {
            _vm.pags = true
            _vm.tipoDocGerar = 1
          },
          "gerar-xls": function ($event) {
            _vm.pags = true
            _vm.tipoDocGerar = 0
          },
          "remove-item": _vm.onRemoveItem,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }