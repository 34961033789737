import PrescritorModel from "@/models/prescritorModel";
import nfetch from "@/utils/events/nfetch";

import { IService } from "./base/iService";

export default class PrescritorService extends IService<PrescritorModel> {
    constructor() {
        super("Prescritor");
    }

    public getPrescritoresDuplicados(filterKey: string, pageIndex: number, pageSize: number): Promise<Response> {
        const encodedFilter = encodeURIComponent(filterKey);

        return nfetch(
            `/${this.controllerName}/GetPrescritoresDuplicados?filterKey=${encodedFilter}&pageIndex=${pageIndex}&pageSize=${pageSize}`,
            {
                credentials: "same-origin",
            },
        );
    }

    public getPrescritoresDuplicadosByPrescritor(model: PrescritorModel): Promise<Response> {
        return nfetch(`/${this.controllerName}/GetPrescritoresDuplicadosByPrescritor`, {
            method: "post",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json; charset=UTF-8",
                "XSRF-TOKEN": this.getCookie("XSRF-TOKEN"),
            },
            body: JSON.stringify(model),
        });
    }

    public unificarPrescritores(prescritorIdOrigem: number, prescritorIdDestino: number): Promise<Response> {
        return nfetch(
            `/${this.controllerName}/UnificarPrescritores?prescritorIdOrigem=${prescritorIdOrigem}&prescritorIdDestino=${prescritorIdDestino}`,
            {
                method: "post",
                credentials: "same-origin",
                headers: {
                    "Content-Type": "application/json; charset=UTF-8",
                    "XSRF-TOKEN": this.getCookie("XSRF-TOKEN"),
                },
            },
        );
    }

    public replicarPrescritor(idPrescritor: number, idFranquias: number[]): Promise<Response> {
        return nfetch(`/${this.controllerName}/ReplicarPrescritor?idPrescritor=${idPrescritor}`, {
            method: "post",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json; charset=UTF-8",
            },
            body: JSON.stringify(idFranquias),
        });
    }
}
