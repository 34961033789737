enum Frequencias {
    Diariamente = 0,
    Semanalmente = 1,
    Mensalmente = 2,
    Bimestralmente = 3,
    Trimestralmente = 4,
    Semestralmente = 5,
    Anualmente = 6
}

export default Frequencias;