import Vue from "vue";

import dateTimePickerComponent from "@/components/child/form/datetimepicker.vue";
import gridComponent from "@/components/child/grid/grid.vue";
import { GridColumn, GridColumnType } from "@/components/child/grid/gridColumn";
import { Component } from "@/decorators";
import ManipulacaoOrdemPCPModel from "@/models/pcp/manipulacaoOrdemPCPModel";
import ManipulacaoOrdemPCPService from "@/services/pcp/manipulacaoOrdemPCPService";
import Delay from "@/utils/common/delay";

import ShortcutComponent from "../../../../shortcut/shortcut";
import shortcutComponent from "../../../../shortcut/shortcut.vue";

import "@/components/parent/crud/crud.scss";

let filterDebounceTimeout;

@Component({
    components: {
        gridComponent,
        shortcutComponent,
        dateTimePickerComponent,
    },
})
export default class PesquisaManipulacaoComponent extends Vue {
    private shortcutComponent: ShortcutComponent = null;
    gridColumns: Array<GridColumn> = [
        new GridColumn("vendaCodigo", "Venda", GridColumnType.String),
        new GridColumn("paciente", "Nome", GridColumnType.String),
        new GridColumn("id", "Código", GridColumnType.String),
        new GridColumn("descricaoManipulacao", "Descricao", GridColumnType.String),
    ];

    manipulacaoOrdemPCPModel = new ManipulacaoOrdemPCPModel();
    private manipulacaoOrdemPCPService = new ManipulacaoOrdemPCPService();
    private manipulacaoOrdemCodigo = "";
    gridData: ManipulacaoOrdemPCPModel[] = [];

    public async show() {
        this.gridData = [];
        this.manipulacaoOrdemCodigo = "";
        while (!this.$refs.shortcutComponent) await Delay(100);
        this.shortcutComponent = this.$refs.shortcutComponent as ShortcutComponent;
        this.shortcutComponent.title = `Pesquisa Avançada`;
        this.shortcutComponent.showBtnOk = false;
        this.shortcutComponent.show();
    }

    private onChangeManipulacaoOrdemCodigo() {
        clearTimeout(filterDebounceTimeout);

        filterDebounceTimeout = setTimeout(this.loadGridData, 500);
    }

    private async loadGridData() {
        if (this.manipulacaoOrdemCodigo.length > 1) {
            try {
                const manipulacaoOrdem = await this.manipulacaoOrdemPCPService
                    .getByFilter(this.manipulacaoOrdemCodigo)
                    .withLoading()
                    .resolveWithJSON<ManipulacaoOrdemPCPModel[]>();

                if (manipulacaoOrdem) {
                    this.gridData = manipulacaoOrdem;
                } else {
                    this.gridData = [];
                }
            } catch {}
        }
    }

    private onSelectSearchAdvanced(value: ManipulacaoOrdemPCPModel) {
        this.manipulacaoOrdemPCPModel = value;
        this.$emit("onSelect", value.id);
        this.shortcutComponent.hide();
    }
}
