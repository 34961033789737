import { Component } from "@/decorators";
import FarmacopeiaModel from "@/models/farmacopeiaModel";
import FarmacopeiaService from "@/services/farmacopeiaService";

import EditComponentBase from "../editComponentBase";

@Component
export default class FarmacopeiaEditComponent extends EditComponentBase<FarmacopeiaModel> {
    private async beforeMount() {
        await this.setProps(new FarmacopeiaService(), "farmacopeia", new FarmacopeiaModel());
    }
}
