import CampanhaModel from "../models/programafidelidade/campanhaModel";
import nfetch from "../utils/events/nfetch";

import { IService } from "./base/iService";

export default class CampanhaService extends IService<CampanhaModel> {
    constructor() {
        super("Campanha");
    }

    public buscaRecompensas(campanhaId: number, cpfCnpj: string, pontos: number): Promise<Response> {
        return nfetch(
            `/${this.controllerName}/BuscaRecompensas?campanhaId=${campanhaId}&cpfCnpj=${cpfCnpj}&pontos=${pontos}`,
            {
                credentials: "same-origin",
            },
        );
    }

    public getPontosByDocumento(documento: string): Promise<Response> {
        return nfetch(`/${this.controllerName}/GetPontosByDocumento?documento=${documento}`, {
            credentials: "same-origin",
        });
    }

    public getPontosByCampanha(documento: string, campanhaId: number): Promise<Response> {
        return nfetch(`/${this.controllerName}/GetPontosByCampanha?documento=${documento}&campanhaId=${campanhaId}`, {
            credentials: "same-origin",
        });
    }

    public pontuarByCliente(clienteId: number, campanhaId: number, pontos: number): Promise<Response> {
        return nfetch(
            `/${this.controllerName}/PontuarByCliente?clienteId=${clienteId}&campanhaId=${campanhaId}&pontos=${pontos}`,
            {
                method: "POST",
                credentials: "same-origin",
                headers: {
                    "Content-Type": "application/json",
                },
            },
        );
    }
}
