var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "crud crud-edit" }, [
    _c("form", [
      _c("fieldset", [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-sm-11" }, [
            _c("div", { staticClass: "form-group" }, [
              _c(
                "label",
                {
                  staticClass: "control-label",
                  attrs: { for: "linkPagamento" },
                },
                [
                  _vm._v(
                    "\n                            " +
                      _vm._s(
                        _vm.$t(
                          "__.Components.parent.venda.linkPagamento_vue_html.linkPagamento"
                        )
                      ) +
                      "\n                        "
                  ),
                ]
              ),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.linkPagamento,
                    expression: "linkPagamento",
                  },
                ],
                staticClass: "form-control",
                attrs: {
                  type: "text",
                  id: "linkPagamento",
                  name: "linkPagamento",
                  autocomplete: "off",
                  disabled: "",
                },
                domProps: { value: _vm.linkPagamento },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.linkPagamento = $event.target.value
                  },
                },
              }),
            ]),
          ]),
          _vm._v(" "),
          _vm.linkPagamento.length > 0
            ? _c("div", { staticClass: "col-sm-1 input-with-icon" }, [
                _c("div", { staticClass: "form-group" }, [
                  _c("label", {
                    staticClass: "control-label",
                    attrs: { for: "detalhesTaxaEntrega" },
                  }),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticClass:
                        "input-group-addon form-control input-with-icon-wrapper",
                      attrs: {
                        title: _vm.$t(
                          "__.Components.parent.venda.linkPagamento_vue_html.copiarAreaTransf"
                        ),
                      },
                      on: { click: _vm.copiarTexto },
                    },
                    [_c("span", { staticClass: "fas fa-copy" })]
                  ),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-sm-12 newbtn" }, [
              _c(
                "button",
                {
                  staticClass: "btn btnCopiar",
                  attrs: { type: "button" },
                  on: { click: _vm.criarLinkPagamento },
                },
                [
                  _vm._v(
                    "\n                            " +
                      _vm._s(
                        _vm.$t(
                          "__.Components.parent.venda.linkPagamento_vue_html.gerarLink"
                        )
                      ) +
                      "\n                        "
                  ),
                ]
              ),
            ]),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }