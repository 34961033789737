import Vue from "vue";

import actionBarComponent from "@/components/child/actionBar/actionBar.vue";
import checkboxComponent from "@/components/child/form/checkbox.vue";
import comboComponent from "@/components/child/form/combo.vue";
import moedaComponent from "@/components/child/form/moeda.vue";
import { Component } from "@/decorators";
import ConfiguracaoGatewayPagamentoModel from "@/models/configuracaoGatewayPagamentoModel";
import GatewayPagamentoProvider from "@/models/enum/financeiro/gatewayPagamentoProvider";
import ConfiguracaoGatewayPagamentoService from "@/services/configuracaoGatewayPagamentoService";

@Component({
    components: {
        actionBarComponent,
        checkboxComponent,
        comboComponent,
        moedaComponent,
    },
})
export default class ConfiguracaoGatewayPagamentoEditComponent extends Vue {
    gatewayPagamentoOptions: Array<Object> = [{ text: "PagSeguro", value: GatewayPagamentoProvider.PagSeguro }];

    private configuracaoGatewayPagamentoService = new ConfiguracaoGatewayPagamentoService();

    private model = new ConfiguracaoGatewayPagamentoModel();

    private async save() {
        const isValid = await this.$validator.validateAll();
        if (isValid) {
            try {
                this.model.franquiaId = Number(this.$route.params.id);
                if (this.model.id) {
                    await this.configuracaoGatewayPagamentoService.update(this.model).withLoading();
                } else {
                    await this.configuracaoGatewayPagamentoService.insert(this.model).withLoading();
                }
                await this.$showSuccess(this.$t("__.ts.sucesso"), this.$t("__.ts.regisSalvosSucess"));
            } catch {}

            this.$router.back();
        }
    }

    private cancel() {
        this.$router.back();
    }

    private async mounted() {
        this.model = await this.configuracaoGatewayPagamentoService
            .get(Number(this.$route.params.id))
            .withLoading()
            .resolveWithJSON<ConfiguracaoGatewayPagamentoModel>();
    }
}
