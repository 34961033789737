var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-sm-12 text-center" }, [
        _c("h3", [
          _vm._v(
            _vm._s(
              _vm.$t(
                "__.Components.parent.historico.vendaHistorico.ver_vue_html.dadosGerais"
              )
            )
          ),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-sm-12" }, [
        _c("div", { staticClass: "col-sm-4" }, [
          _c("dl", { staticClass: "dl-horizontal" }, [
            _c("dt", [
              _vm._v(
                _vm._s(
                  _vm.$t(
                    "__.Components.parent.historico.vendaHistorico.ver_vue_html.cliente"
                  )
                )
              ),
            ]),
            _vm._v(" "),
            _c("dd", [_vm._v(_vm._s(_vm.data.cliente.nome))]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-sm-3" }, [
          _c("dl", { staticClass: "dl-horizontal" }, [
            _c("dt", [
              _vm._v(
                _vm._s(
                  _vm.$t(
                    "__.Components.parent.historico.vendaHistorico.ver_vue_html.cpf"
                  )
                )
              ),
            ]),
            _vm._v(" "),
            _c("dd", [_vm._v(_vm._s(_vm.data.cliente.cpf))]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-sm-3" }, [
          _c("dl", { staticClass: "dl-horizontal" }, [
            _c("dt", [
              _vm._v(
                _vm._s(
                  _vm.$t(
                    "__.Components.parent.historico.vendaHistorico.ver_vue_html.dtNasc"
                  )
                )
              ),
            ]),
            _vm._v(" "),
            _c("dd", [
              _vm._v(
                _vm._s(_vm.getDateString(_vm.data.cliente.dataNascimento))
              ),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-sm-3" }, [
          _c("dl", { staticClass: "dl-horizontal" }, [
            _c("dt", [
              _vm._v(
                _vm._s(
                  _vm.$t(
                    "__.Components.parent.historico.vendaHistorico.ver_vue_html.cel"
                  )
                )
              ),
            ]),
            _vm._v(" "),
            _c("dd", [_vm._v(_vm._s(_vm.data.cliente.celular))]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-sm-3" }, [
          _c("dl", { staticClass: "dl-horizontal" }, [
            _c("dt", [
              _vm._v(
                _vm._s(
                  _vm.$t(
                    "__.Components.parent.historico.vendaHistorico.ver_vue_html.tel"
                  )
                )
              ),
            ]),
            _vm._v(" "),
            _c("dd", [_vm._v(_vm._s(_vm.data.cliente.telefone))]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-sm-3" }, [
          _c("dl", { staticClass: "dl-horizontal" }, [
            _c("dt", [
              _vm._v(
                _vm._s(
                  _vm.$t(
                    "__.Components.parent.historico.vendaHistorico.ver_vue_html.vlrTotal"
                  )
                )
              ),
            ]),
            _vm._v(" "),
            _c("dd", [
              _vm._v(
                "\n                        " +
                  _vm._s(_vm.getMoneyString(_vm.data.valorFinal)) +
                  "\n                    "
              ),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-sm-3" }, [
          _c("dl", { staticClass: "dl-horizontal" }, [
            _c("dt", [
              _vm._v(
                _vm._s(
                  _vm.$t(
                    "__.Components.parent.historico.vendaHistorico.ver_vue_html.dtVenda"
                  )
                )
              ),
            ]),
            _vm._v(" "),
            _c("dd", [_vm._v(_vm._s(_vm.getDateString(_vm.data.dataVenda)))]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-sm-6" }, [
          _c("dl", { staticClass: "dl-horizontal" }, [
            _c("dt", [
              _vm._v(
                _vm._s(
                  _vm.$t(
                    "__.Components.parent.historico.vendaHistorico.ver_vue_html.obs"
                  )
                )
              ),
            ]),
            _vm._v(" "),
            _c("dd", [_vm._v(_vm._s(_vm.data.observacao))]),
          ]),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _vm.data.itensAcabado.length > 0
      ? _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-sm-12 text-center" }, [
            _c("h3", [
              _vm._v(
                _vm._s(
                  _vm.$t(
                    "__.Components.parent.historico.vendaHistorico.ver_vue_html.itensAcabados"
                  )
                )
              ),
            ]),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-sm-12" },
        [
          _vm._l(_vm.data.itensAcabado, function (item) {
            return [
              _c("div", { key: item.produto, staticClass: "col-sm-4" }, [
                _c("dl", { staticClass: "dl-horizontal" }, [
                  _c("dt", [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "__.Components.parent.historico.vendaHistorico.ver_vue_html.produto"
                        )
                      )
                    ),
                  ]),
                  _vm._v(" "),
                  _c("dd", [_vm._v(_vm._s(item.produto))]),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { key: item.produto, staticClass: "col-sm-1" }, [
                _c("dl", { staticClass: "dl-horizontal" }, [
                  _c("dt", [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "__.Components.parent.historico.vendaHistorico.ver_vue_html.qtd"
                        )
                      )
                    ),
                  ]),
                  _vm._v(" "),
                  _c("dd", [_vm._v(_vm._s(item.quantidade))]),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { key: item.produto, staticClass: "col-sm-1" }, [
                _c("dl", { staticClass: "dl-horizontal" }, [
                  _c("dt", [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "__.Components.parent.historico.vendaHistorico.ver_vue_html.vlr"
                        )
                      )
                    ),
                  ]),
                  _vm._v(" "),
                  _c("dd", [
                    _vm._v(_vm._s(_vm.getMoneyString(item.valorTotal))),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { key: item.produto, staticClass: "col-sm-3" }, [
                _c("dl", { staticClass: "dl-horizontal" }, [
                  _c("dt", [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "__.Components.parent.historico.vendaHistorico.ver_vue_html.paciente"
                        )
                      )
                    ),
                  ]),
                  _vm._v(" "),
                  _c("dd", [
                    _vm._v(_vm._s(item.paciente ? item.paciente.nome : "")),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { key: item.produto, staticClass: "col-sm-3" }, [
                _c("dl", { staticClass: "dl-horizontal" }, [
                  _c("dt", [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "__.Components.parent.historico.vendaHistorico.ver_vue_html.prescritor"
                        )
                      )
                    ),
                  ]),
                  _vm._v(" "),
                  _c("dd", [
                    _vm._v(_vm._s(item.prescritor ? item.prescritor.nome : "")),
                  ]),
                ]),
              ]),
            ]
          }),
        ],
        2
      ),
    ]),
    _vm._v(" "),
    _vm.data.itensManipulacao.length > 0
      ? _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-sm-12 text-center" }, [
            _c("h3", [
              _vm._v(
                _vm._s(
                  _vm.$t(
                    "__.Components.parent.historico.vendaHistorico.ver_vue_html.itensManipu"
                  )
                )
              ),
            ]),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "col-sm-12" },
      [
        _vm._l(_vm.data.itensManipulacao, function (item) {
          return [
            _c(
              "div",
              {
                key: item.formaFarmaceutica,
                staticClass: "panel panel-default",
              },
              [
                _c("div", { staticClass: "panel-body" }, [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-sm-2" }, [
                      _c("dl", { staticClass: "dl-horizontal" }, [
                        _c("dt", [
                          _vm._v(
                            "\n                                    " +
                              _vm._s(
                                _vm.$t(
                                  "__.Components.parent.historico.vendaHistorico.ver_vue_html.nr"
                                )
                              ) +
                              "\n                                "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("dd", [_vm._v(_vm._s(item.numeroRegistroFormula))]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-sm-4" }, [
                      _c("dl", { staticClass: "dl-horizontal" }, [
                        _c("dt", [
                          _vm._v(
                            "\n                                    " +
                              _vm._s(
                                _vm.$t(
                                  "__.Components.parent.historico.vendaHistorico.ver_vue_html.formaFarm"
                                )
                              ) +
                              "\n                                "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("dd", [_vm._v(_vm._s(item.formaFarmaceutica))]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-sm-2" }, [
                      _c("dl", { staticClass: "dl-horizontal" }, [
                        _c("dt", [
                          _vm._v(
                            "\n                                    " +
                              _vm._s(
                                _vm.$t(
                                  "__.Components.parent.historico.vendaHistorico.ver_vue_html.qtdVol"
                                )
                              ) +
                              "\n                                "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("dd", [_vm._v(_vm._s(item.quantidadeVolume))]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-sm-1" }, [
                      _c("dl", { staticClass: "dl-horizontal" }, [
                        _c("dt", [
                          _vm._v(
                            "\n                                    " +
                              _vm._s(
                                _vm.$t(
                                  "__.Components.parent.historico.vendaHistorico.ver_vue_html.volBase"
                                )
                              ) +
                              "\n                                "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("dd", [_vm._v(_vm._s(item.volumeBase))]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-sm-1" }, [
                      _c("dl", { staticClass: "dl-horizontal" }, [
                        _c("dt", [
                          _vm._v(
                            "\n                                    " +
                              _vm._s(
                                _vm.$t(
                                  "__.Components.parent.historico.vendaHistorico.ver_vue_html.unMed"
                                )
                              ) +
                              "\n                                "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("dd", [_vm._v(_vm._s(item.unidadeMedida))]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-sm-2" }, [
                      _c("dl", { staticClass: "dl-horizontal" }, [
                        _c("dt", [
                          _vm._v(
                            "\n                                    " +
                              _vm._s(
                                _vm.$t(
                                  "__.Components.parent.historico.vendaHistorico.ver_vue_html.continuo"
                                )
                              ) +
                              "\n                                "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("dd", [
                          _vm._v(_vm._s(item.continuo ? "Sim" : "Não")),
                        ]),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-sm-4" }, [
                      _c("dl", { staticClass: "dl-horizontal" }, [
                        _c("dt", [
                          _vm._v(
                            "\n                                    " +
                              _vm._s(
                                _vm.$t(
                                  "__.Components.parent.historico.vendaHistorico.ver_vue_html.embalagem"
                                )
                              ) +
                              "\n                                "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("dd", [_vm._v(_vm._s(item.embalagem))]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-sm-4" }, [
                      _c("dl", { staticClass: "dl-horizontal" }, [
                        _c("dt", [
                          _vm._v(
                            "\n                                    " +
                              _vm._s(
                                _vm.$t(
                                  "__.Components.parent.historico.vendaHistorico.ver_vue_html.capsula"
                                )
                              ) +
                              "\n                                "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("dd", [_vm._v(_vm._s(item.capsula))]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-sm-2" }, [
                      _c("dl", { staticClass: "dl-horizontal" }, [
                        _c("dt", [
                          _vm._v(
                            "\n                                    " +
                              _vm._s(
                                _vm.$t(
                                  "__.Components.parent.historico.vendaHistorico.ver_vue_html.qtd"
                                )
                              ) +
                              "\n                                "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("dd", [_vm._v(_vm._s(item.quantidadeFormula))]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-sm-2" }, [
                      _c("dl", { staticClass: "dl-horizontal" }, [
                        _c("dt", [
                          _vm._v(
                            "\n                                    " +
                              _vm._s(
                                _vm.$t(
                                  "__.Components.parent.historico.vendaHistorico.ver_vue_html.vlr"
                                )
                              ) +
                              "\n                                "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("dd", [
                          _vm._v(_vm._s(_vm.getMoneyString(item.valorTotal))),
                        ]),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-sm-6" }, [
                      _c("dl", { staticClass: "dl-horizontal" }, [
                        _c("dt", [
                          _vm._v(
                            "\n                                    " +
                              _vm._s(
                                _vm.$t(
                                  "__.Components.parent.historico.vendaHistorico.ver_vue_html.prescritor"
                                )
                              ) +
                              "\n                                "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("dd", [
                          _vm._v(
                            _vm._s(item.prescritor ? item.prescritor.nome : "")
                          ),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-sm-6" }, [
                      _c("dl", { staticClass: "dl-horizontal" }, [
                        _c("dt", [
                          _vm._v(
                            "\n                                    " +
                              _vm._s(
                                _vm.$t(
                                  "__.Components.parent.historico.vendaHistorico.ver_vue_html.paciente"
                                )
                              ) +
                              "\n                                "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("dd", [
                          _vm._v(
                            _vm._s(item.paciente ? item.paciente.nome : "")
                          ),
                        ]),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-sm-12" }, [
                      _c("dl", { staticClass: "dl-horizontal" }, [
                        _c("dt", [
                          _vm._v(
                            "\n                                    " +
                              _vm._s(
                                _vm.$t(
                                  "__.Components.parent.historico.vendaHistorico.ver_vue_html.posologia"
                                )
                              ) +
                              "\n                                "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("dd", [_vm._v(_vm._s(item.posologia))]),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-sm-12" }, [
                      _c("dl", { staticClass: "dl-horizontal" }, [
                        _c("dt", [
                          _vm._v(
                            "\n                                    " +
                              _vm._s(
                                _vm.$t(
                                  "__.Components.parent.historico.vendaHistorico.ver_vue_html.obs"
                                )
                              ) +
                              "\n                                "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("dd", [_vm._v(_vm._s(item.observacao))]),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("table", { staticClass: "table table-striped" }, [
                    _c("thead", [
                      _c("tr", [
                        _c("th", { attrs: { scope: "col" } }, [
                          _vm._v(
                            "\n                                    " +
                              _vm._s(
                                _vm.$t(
                                  "__.Components.parent.historico.vendaHistorico.ver_vue_html.produto"
                                )
                              ) +
                              "\n                                "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("th", { attrs: { scope: "col" } }, [
                          _vm._v(
                            "\n                                    " +
                              _vm._s(
                                _vm.$t(
                                  "__.Components.parent.historico.vendaHistorico.ver_vue_html.dose"
                                )
                              ) +
                              "\n                                "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("th", { attrs: { scope: "col" } }, [
                          _vm._v(
                            "\n                                    " +
                              _vm._s(
                                _vm.$t(
                                  "__.Components.parent.historico.vendaHistorico.ver_vue_html.tipoDose"
                                )
                              ) +
                              "\n                                "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("th", { attrs: { scope: "col" } }, [
                          _vm._v(
                            "\n                                    " +
                              _vm._s(
                                _vm.$t(
                                  "__.Components.parent.historico.vendaHistorico.ver_vue_html.lote"
                                )
                              ) +
                              "\n                                "
                          ),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "tbody",
                      _vm._l(item.ativos, function (ativo) {
                        return _c("tr", { key: ativo.produto }, [
                          _c("td", [_vm._v(_vm._s(ativo.produto))]),
                          _vm._v(" "),
                          _c("td", [_vm._v(_vm._s(ativo.dose))]),
                          _vm._v(" "),
                          _c("td", [_vm._v(_vm._s(ativo.tipoDose))]),
                          _vm._v(" "),
                          _c("td", [_vm._v(_vm._s(ativo.produtoLote))]),
                        ])
                      })
                    ),
                  ]),
                ]),
              ]
            ),
          ]
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }