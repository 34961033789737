var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "shortcutComponent",
    {
      ref: "shortcutComponent",
      attrs: { showAddNew: false, showBtnOk: false },
      on: { "hide-click": _vm.onEvent },
    },
    [
      _c(
        "div",
        { attrs: { slot: "component" }, slot: "component" },
        [
          _c("grid-component", {
            attrs: {
              columns: _vm.gridColumns,
              data: _vm.gridData,
              searchValue: _vm.searchValue,
              showRemoveItem: false,
              showEditItem: true,
              showSortIcons: false,
              showTableHead: false,
              showAddNewButton: false,
              showTableFoot: false,
              showTools: false,
            },
            on: { "edit-item": _vm.onEditItem },
          }),
          _vm._v(" "),
          _c(
            "shortcutComponent",
            {
              ref: "shortcutComponentCliente",
              attrs: { btOkName: _vm.$t("__.Crud.salvar"), showAddNew: false },
              on: { "selection-item": _vm.onClienteSave },
            },
            [
              _c(
                "div",
                { attrs: { slot: "component" }, slot: "component" },
                [
                  _c("clienteComponent", {
                    ref: "clienteComponent",
                    attrs: { CalledByShortCut: true },
                    on: { "save-ok": _vm.onClienteSaveOk },
                  }),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }