import Vue from "vue";
import { mapGetters } from "vuex";

import comboComponent from "@/components/child/form/combo.vue";
import dateTimePickerComponent from "@/components/child/form/datetimepicker.vue";
import moedaComponent from "@/components/child/form/moeda.vue";
import textareaComponent from "@/components/child/form/textarea.vue";
import { Component, Prop, Watch } from "@/decorators";
import ConfiguracaoFranquiaModel from "@/models/configuracaoFranquia/configuracaoFranquiaModel";
import Configuracoes from "@/models/enum/configuracao/configuracoes";
import Especie from "@/models/enum/especiePagamento";
import Bandeira from "@/models/financeiro/bandeiraModel";
import ContaCorrenteModel from "@/models/financeiro/contaCorrenteModel";
import ContaReceberRecebimentoModel from "@/models/financeiro/contaReceberRecebimentoModel";
import FormaRecebimentoModel from "@/models/financeiro/formaRecebimentoModel";
import MetodoPagemento from "@/models/financeiro/metodoPagamentoModel";
import PaginationModel from "@/models/paginationModel";
import BandeiraService from "@/services/financeiro/bandeiraService";
import ContaCorrenteService from "@/services/financeiro/contaCorrenteService";
import FormaRecebimentoService from "@/services/financeiro/formaRecebimentoService";
import MetodoPagementoService from "@/services/financeiro/metodoPagamentoService";
import MovimentacaoCaixaService from "@/services/financeiro/movimentacaoCaixaService";
import { Getters } from "@/store/store";
import arithmeticHelper from "@/utils/common/arithmeticHelper";

import "./../crud/contareceber/recebimentoconta.scss";

@Component({
    components: {
        textareaComponent,
        comboComponent,
        moedaComponent,
        dateTimePickerComponent,
    },
    computed: {
        ...mapGetters(["GET_CONFIG_FRANQUIA"] as Getters),
    },
})
export default class RecebimentoContaComponent extends Vue {
    // State computed props
    GET_CONFIG_FRANQUIA: (configuracao: Configuracoes) => ConfiguracaoFranquiaModel;

    model = new ContaReceberRecebimentoModel();

    private valorAbater = 0;
    private saldoabater = 0;
    private idCaixaSugestao = 0;
    private initialLoading = false;
    private nsuObrigatorio = false;

    contaCorrenteOptions: Array<Object> = [];
    formaRecebimentoOptions: Array<Object> = [];
    formaRecebimentoOptionsFiltrado: Array<Object> = [];

    bandeira = -1;
    bandeiraOptions: Array<Object> = [];

    especie = -1;
    especiesOptions: Array<Object> = [
        { text: "Dinheiro", value: Especie.Dinheiro },
        { text: "Cheque", value: Especie.Cheque },
        { text: "Boleto", value: Especie.Boleto },
        { text: "Cartão de Débito", value: Especie.CartaoDebito },
        { text: "Cartão de Crédito", value: Especie.CartaoCredito },
        { text: "Transferência", value: Especie.Transferencia },
        { text: "Pix", value: Especie.Pix },
        { text: "Convênio", value: Especie.Convenio },
        { text: "Transferência Bancária", value: Especie.TransferenciaBancaria },
        { text: "Outros", value: Especie.Outros },
    ];

    metodoParcelamentoId = -1;

    metodosPagamentosOptions: Array<Object> = [];
    metodosPagamentosOptionsFiltrado: Array<Object> = [];

    percentualJurosMetodo = 0;
    percentualDescontoMetodo = 0;

    @Prop({ type: Boolean, default: true }) showIncluirRecebimento: boolean;

    public load(saldoReceber: number) {
        this.initialLoading = true;

        this.model = new ContaReceberRecebimentoModel();
        this.model.dataMovimento = new Date();
        this.model.valor = saldoReceber;

        //se possui sugestao de caixa na conta corretne ...inicia com ele
        if (this.idCaixaSugestao > 0) {
            this.model.contaCorrenteId = this.idCaixaSugestao;
        }
        this.model.contabilizar = true;

        this.saldoabater = saldoReceber;
        this.valorAbater = saldoReceber;
        this.model.valor = arithmeticHelper.round(saldoReceber);

        this.$forceUpdate();

        setTimeout(() => {
            this.initialLoading = false;
        }, 100);
    }

    private async loadMetodoPagamentos() {
        try {
            const data = await new MetodoPagementoService().combo().resolveWithJSON<PaginationModel<MetodoPagemento>>();
            this.metodosPagamentosOptions = data.list.map(item => ({
                value: item.id,
                text: item.descricao,
                numParcelas: item.numeroParcelas,
                primeiroIntervalo: item.primeiroIntervalo,
                intervalo: item.intervalo,
            }));
            //por enquanto faz com segunda variavel, se ficar pesado pela quantidade de informacoes, chamar service na alteracoa do forma e criar service com parametro de filtro
            this.metodosPagamentosOptionsFiltrado = this.metodosPagamentosOptions;
        } catch {}
    }

    private async loadBandeiras() {
        try {
            const data = await new BandeiraService().combo().resolveWithJSON<PaginationModel<Bandeira>>();
            this.bandeiraOptions = data.list.map(item => ({ value: item.id, text: item.descricao }));
        } catch {}
    }

    private async loadContasCorrentes() {
        try {
            const data = await new ContaCorrenteService()
                .combo()
                .resolveWithJSON<PaginationModel<ContaCorrenteModel>>();

            this.contaCorrenteOptions = data.list.map(item => ({ value: item.id, text: item.descricao }));
        } catch {}
    }

    private async loadFormasRecebimento() {
        try {
            const data = await new FormaRecebimentoService()
                .comboByEspecie()
                .resolveWithJSON<PaginationModel<FormaRecebimentoModel>>();

            this.formaRecebimentoOptions = data.list.map(item => ({
                value: item.id,
                text: item.descricao,
                ...item,
            }));
            this.formaRecebimentoOptionsFiltrado = this.formaRecebimentoOptions;
            this.filtrarFormas();
        } catch {}
    }

    private filtrarFormas() {
        if (this.especie >= 0) {
            this.formaRecebimentoOptionsFiltrado = this.formaRecebimentoOptions.filter(
                p => p["especie"] == this.especie,
            );

            if (this.formaRecebimentoOptionsFiltrado.length == 1) {
                this.model.formaPagamentoId = this.formaRecebimentoOptionsFiltrado[0]["value"];
            }
        }

        if ((this.especie == Especie.CartaoDebito || this.especie == Especie.CartaoCredito) && this.bandeira > 0) {
            this.formaRecebimentoOptionsFiltrado = this.formaRecebimentoOptions.filter(
                p =>
                    p["bandeiraXFormaPagamento"].filter(b => b.bandeiraId == this.bandeira).length > 0 &&
                    p["especie"] == this.especie,
            );
        }
    }

    public async validate() {
        return await this.$validator.validateAll();
    }

    private async incluirRecebimento() {
        try {
            const isValid = await this.validate();
            if (isValid) {
                if (!this.hasError("valorAbater")) {
                    this.$emit("inclusao-recebimento", this.getModel());
                }
            } else {
                this.$focusErrorField();
            }
        } catch {}
    }

    public getModel() {
        const contaCorrenteElement = this.$el.querySelector("#contaCorrenteId") as HTMLSelectElement;
        const formaRecebimentoElement = this.$el.querySelector("#formaPagamentoId") as HTMLSelectElement;

        ///se possui operacao de cartao eh pq a conta q sera criada para operadora de cartoes que ira ser contabilizada
        this.model.contabilizar = true;
        if (
            this.model.autorizacao != null &&
            this.model.autorizacao != "" &&
            (this.especie == Especie.CartaoCredito || this.especie == Especie.CartaoDebito)
        ) {
            this.model.contabilizar = false;
        }

        const model = new ContaReceberRecebimentoModel();
        model.updateFrom(this.model);
        model.lancamentoId = this.model.id;
        model.dataLancamento = new Date();
        model.contaCorrenteDescricao = contaCorrenteElement.innerText;
        model.formaPagamentoDescricao = formaRecebimentoElement.innerText;
        model.valor = arithmeticHelper.round(model.valor);
        model.juros = arithmeticHelper.round(model.juros);
        model.desconto = arithmeticHelper.round(model.desconto);
        model.especie = this.especie;

        if (this.bandeira > 0) {
            model.bandeiraId = this.bandeira;
        }

        if (this.model.formaPagamentoId > 0 && this.metodoParcelamentoId > 0) {
            model.metodoPagamentoXForma = this.getMetodoByParcelamento(
                this.formaRecebimentoOptionsFiltrado as FormaRecebimentoModel[],
            );
            model.metodoPagamentoXFormaId = model.metodoPagamentoXForma.id;
        }
        return model;
    }

    // @ts-ignore
    @Watch("valorAbater")
    private onValorAbater() {
        if (this.initialLoading) {
            return;
        }
        //quando alterado deve aplicar percentual de juros e desconto novamente sobre este valor
        //e  recalcular o total recebido
        //valor a abater nao pode ser maior que o saldo a abater
        if (this.valorAbater > this.saldoabater) {
            this.setError(
                "valorAbater",
                "Valor a abater não pode ser maior que saldo a receber(R$" + this.saldoabater.toString() + ").",
            );
        } else {
            this.setError("valorAbater", "", true);
        }

        if (this.percentualDescontoMetodo > 0) {
            this.model.desconto = arithmeticHelper.round((this.valorAbater * this.percentualDescontoMetodo) / 100);
        }

        if (this.percentualJurosMetodo > 0) {
            this.model.juros = arithmeticHelper.round((this.valorAbater * this.percentualJurosMetodo) / 100);
        }

        this.calcularValor();
    }

    private setError(idCampo: string, msgTooltip = "", removeErro = false) {
        const possuiErros = this.hasError(idCampo);

        if (removeErro && possuiErros) {
            this.$validator.errors.remove(idCampo);
        } else if (!possuiErros && !removeErro) {
            const errorFields = { field: idCampo, msg: msgTooltip, id: idCampo };
            this.$validator.errors.add(errorFields);
        }
    }

    private hasError(idCampo: string) {
        const form = document.getElementById(idCampo).parentElement;
        const className = form.getAttribute("class");

        return className.indexOf("error") >= 0;
    }

    // @ts-ignore
    @Watch("model.juros")
    private onJurosChanged() {
        if (this.initialLoading) {
            return;
        }

        this.calcularValor();
    }

    // @ts-ignore
    @Watch("model.desconto")
    private onDescontoChanged(newValue: number) {
        if (this.initialLoading) {
            return;
        }

        const valor = arithmeticHelper.round(this.model.juros + this.valorAbater);
        if (newValue >= valor) {
            this.setError(
                "desconto",
                "Valor de desconto muito alto, valor de desconto não pode ser maior que saldo mais juros.",
            );
        } else {
            this.setError("desconto", "", true);
        }

        this.calcularValor();
    }

    private calcularValor() {
        this.percentualJurosMetodo = arithmeticHelper.round((this.model.juros * 100) / this.valorAbater);
        this.percentualDescontoMetodo = arithmeticHelper.round((this.model.desconto * 100) / this.valorAbater);

        this.model.valor = arithmeticHelper.round(this.valorAbater + this.model.juros - this.model.desconto);
    }

    @Watch("especie")
    private onChangeEspecie() {
        if (this.especie != Especie.CartaoCredito && this.especie != Especie.CartaoDebito) {
            this.bandeira = -1;
        }

        this.filtrarFormas();
    }

    @Watch("bandeira")
    private onChangeBandeira() {
        this.filtrarFormas();
    }

    // @ts-ignore
    @Watch("model.formaPagamentoId")
    private onChangeFormaPagamentoId() {
        if (this.model.formaPagamentoId != null && this.model.formaPagamentoId > 0) {
            if (this.especie != Especie.CartaoCredito && this.especie != Especie.CartaoDebito) {
                this.buscaPercentuaisJurosMulta();
            } else {
                const parcelamentoAnterior = this.metodoParcelamentoId;

                if (!(this.bandeira > 0)) {
                    this.bandeira = this.formaRecebimentoOptionsFiltrado.filter(
                        p => p["value"] == this.model.formaPagamentoId,
                    )[0]["bandeiraXFormaPagamento"][0]["bandeiraId"];
                }

                this.metodosPagamentosOptionsFiltrado = this.metodosPagamentosOptions.filter(metodo => {
                    const metodos = this.formaRecebimentoOptionsFiltrado.filter(
                        p => p["value"] == this.model.formaPagamentoId,
                    )[0]["metodoPagamentoXForma"];

                    return metodos.filter(p => p.metodoPagamentoId == metodo["value"]).length > 0;
                });

                if (this.metodosPagamentosOptionsFiltrado.length == 1) {
                    this.metodoParcelamentoId = this.metodosPagamentosOptionsFiltrado[0]["value"];
                }

                //se for igual, nao passa no change para recalcular o percentual de juros e desconto
                if (parcelamentoAnterior == this.metodoParcelamentoId) {
                    this.buscaPercentuaisJurosMulta();
                }
            }
        } else {
            if (this.percentualJurosMetodo > 0) {
                this.percentualJurosMetodo = 0;
            }
            if (this.percentualDescontoMetodo > 0) {
                this.percentualDescontoMetodo = 0;
            }
            this.model.valor = arithmeticHelper.round(this.valorAbater);
            this.metodoParcelamentoId = -1;
        }
    }

    @Watch("metodoParcelamentoId")
    private onChangeMetodoPagamentoId() {
        this.buscaPercentuaisJurosMulta();
    }

    private buscaPercentuaisJurosMulta() {
        if (this.metodoParcelamentoId > 0) {
            const metodos = this.getMetodoByParcelamento(
                this.formaRecebimentoOptionsFiltrado as FormaRecebimentoModel[],
            );

            if (metodos != null) {
                this.percentualDescontoMetodo = metodos.desconto;
                this.percentualJurosMetodo = metodos.juros;
            }

            if (this.percentualDescontoMetodo > 0) {
                this.model.desconto = arithmeticHelper.round((this.valorAbater * this.percentualDescontoMetodo) / 100);
            }

            if (this.percentualJurosMetodo > 0) {
                this.model.juros = arithmeticHelper.round((this.valorAbater * this.percentualJurosMetodo) / 100);
            }

            this.model.valor = arithmeticHelper.round(this.valorAbater - this.model.desconto + this.model.juros);
        } else {
            if (
                this.especie != Especie.CartaoCredito &&
                this.especie != Especie.CartaoDebito &&
                this.model.formaPagamentoId > 0
            ) {
                let juros = this.formaRecebimentoOptionsFiltrado.filter(
                    p => p["value"] == this.model.formaPagamentoId,
                )[0]["juros"];
                let desconto = this.formaRecebimentoOptionsFiltrado.filter(
                    p => p["value"] == this.model.formaPagamentoId,
                )[0]["desconto"];

                if (!juros) {
                    juros = 0;
                }
                if (!desconto) {
                    desconto = 0;
                }

                this.percentualJurosMetodo = juros;
                this.model.juros = arithmeticHelper.round((this.valorAbater * this.percentualJurosMetodo) / 100);

                this.percentualDescontoMetodo = desconto;
                this.model.desconto = arithmeticHelper.round((this.valorAbater * this.percentualDescontoMetodo) / 100);
            } else {
                if (this.percentualJurosMetodo > 0) {
                    this.percentualJurosMetodo = 0;
                }
                if (this.percentualDescontoMetodo > 0) {
                    this.percentualDescontoMetodo = 0;
                }
            }

            this.model.valor = arithmeticHelper.round(this.valorAbater - this.model.desconto + this.model.juros);
        }
    }

    private getMetodoByParcelamento(formas: FormaRecebimentoModel[]) {
        return formas
            .filter(p => p["value"] == this.model.formaPagamentoId)[0]
            .metodoPagamentoXForma.filter(p => p.metodoPagamentoId == this.metodoParcelamentoId)[0];
    }

    private alertDesfazer(text: string) {
        this.$showWarning("Operação não permitida!", text);
    }

    private async loadIdCaixaSegestao() {
        this.idCaixaSugestao = 0;
        try {
            const data = await new MovimentacaoCaixaService().getIdCaixaSessaoAberta().resolveWithJSON<number>();

            this.idCaixaSugestao = data;
        } catch {}
    }

    private mounted() {
        this.nsuObrigatorio = this.GET_CONFIG_FRANQUIA(Configuracoes.NsuObrigatorioFaturamentoCartao).verdadeiro;
        this.percentualDescontoMetodo = 0;
        this.percentualJurosMetodo = 0;
        this.valorAbater = 0;

        Promise.all([
            this.loadIdCaixaSegestao(),
            this.loadContasCorrentes(),
            this.loadFormasRecebimento(),
            this.loadBandeiras(),
            this.loadMetodoPagamentos(),
        ])
            .withLoading()
            .then(() => {});
    }
}
