import nfetch from "../../utils/events/nfetch";

export default class NotaFiscalEletronicaEmissaoItemService {
    private controllerName = "NotaFiscalEletronicaEmissaoItem";

    public getICMSCompra(produtoId: number, loteId: number): Promise<Response> {
        return nfetch(`/${this.controllerName}/GetICMSCompra?produtoId=${produtoId}&loteId=${loteId}`, {
            credentials: "same-origin",
        });
    }
}
