import Vue from "vue";
import { mapGetters, mapMutations, mapState } from "vuex";

import actionBarComponent from "@/components/child/actionBar/actionBar.vue";
import buttonIncluirComponent from "@/components/child/form/button/buttonIncluir.vue";
import checkboxComponent from "@/components/child/form/checkbox.vue";
import comboComponent from "@/components/child/form/combo.vue";
import dataTooltipComponent from "@/components/child/form/datatooltip.vue";
import decimalComponent from "@/components/child/form/decimal.vue";
import fieldsetComponent from "@/components/child/form/fieldset.vue";
import textareaComponent from "@/components/child/form/textarea.vue";
import gridComponent from "@/components/child/grid/grid.vue";
import { GridColumn, GridColumnType } from "@/components/child/grid/gridColumn";
import { Component, Watch } from "@/decorators";
import PreLoadPackModel from "@/models/auxiliar/preLoadPackModel";
import FormaFarmaceuticaModel from "@/models/formaFarmaceuticaModel";
import MoldeModel from "@/models/moldeModel";
import PaginationModel from "@/models/paginationModel";
import FormaFarmaceuticaService from "@/services/formaFarmaceuticaService";
import MoldeService from "@/services/moldeService";
import { AppState, Getters, Mutations, SessionActions } from "@/store/store";
import { getFormaFarmaceuticaCombo } from "@/utils/common/combo/combotext";
import Delay from "@/utils/common/delay";

import "../crud.scss";
import "./edit.scss";

@Component({
    components: {
        textareaComponent,
        checkboxComponent,
        comboComponent,
        dataTooltipComponent,
        decimalComponent,
        fieldsetComponent,
        gridComponent,
        buttonIncluirComponent,
        actionBarComponent,
    },
    computed: {
        ...mapState<AppState>({
            preLoadPack: state => state.preLoad.preLoadList,
            loadedList: state => state.preLoad.loadedList,
        }),
        ...mapGetters(["VALIDAR_PERMISSAO_SOMENTE_CONSULTA"] as Getters),
    },
    methods: mapMutations(["LOAD_LIST"] as Mutations),
})
export default class MoldeEditComponent extends Vue {
    // State computed props
    preLoadPack: PreLoadPackModel;
    loadedList: boolean;
    VALIDAR_PERMISSAO_SOMENTE_CONSULTA: (telaDescricao: string) => boolean;

    produtosOptions: Array<Object> = [];

    private service = new MoldeService();

    model = new MoldeModel();
    modelId = 0;

    somenteConsulta = false;
    produtoComboId = 0;
    subGrupo: number = null;
    quantidade = 0;
    produtoPadrao = true;

    private unidadeMedId: number = null;

    formasFarmaceuticaOptions: Array<Object> = [];

    gridColumnsFormaFarmaceutica: Array<GridColumn> = [
        new GridColumn("descricao", "Descri\u00e7\u00e3o", GridColumnType.String),
    ];

    private async load() {
        this.produtosOptions = this.preLoadPack.produtosCombo();

        this.model = new MoldeModel();
        this.model.ativo = true;

        if (this.modelId > 0) {
            try {
                const data = await this.service.get(this.modelId).withLoading().resolveWithJSON<MoldeModel>();

                this.model.updateFrom(data);
            } catch {
                this.$router.back();
            }
        }
    }

    private async loadFormasFarmaceutica() {
        try {
            const data = await new FormaFarmaceuticaService()
                .combo()
                .resolveWithJSON<PaginationModel<FormaFarmaceuticaModel>>();
            this.formasFarmaceuticaOptions = data.list.map(getFormaFarmaceuticaCombo);
        } catch {}
    }

    private async save() {
        try {
            const isValid = await this.$validator.validateAll();
            if (isValid) {
                if (this.model.unidadeMedidaId == null) {
                    if (this.unidadeMedId != null) {
                        this.model.unidadeMedidaId = this.unidadeMedId;
                    } else {
                        const unidadeMl = this.preLoadPack.unidadesMedida.filter(p => p.sigla.toLowerCase() == "ml");
                        if (unidadeMl.length > 0) {
                            this.model.unidadeMedidaId = unidadeMl[0].id;
                            this.unidadeMedId = unidadeMl[0].id;
                        }
                    }
                }

                const sucesso = await this.service[!this.model.id ? "insert" : "update"](this.model)
                    .withLoading()
                    .resolveWithoutJSON();

                if (sucesso) {
                    this["LOAD_LIST"]({ loadMolde: true });
                    await this.$showInclusaoUpdate(this.model.id);
                    this.$router.back();
                }
            } else {
                this.$focusErrorField();
            }
        } catch {}
    }

    private cancel() {
        this.$router.back();
    }

    private onInclusaoFormaFarmaceutica() {
        const formaFarmaceuticaComponent = this.$el.querySelector("#formaFarmaceuticaId") as HTMLSelectElement;

        if (this.model.formaFarmaceuticaId > 0) {
            const alreadyExists = this.model.formasFarmaceuticas.some(
                item => item.id == this.model.formaFarmaceuticaId,
            );

            if (!alreadyExists) {
                const formaFarmaceuticaModel = new FormaFarmaceuticaModel();

                formaFarmaceuticaModel.id = this.model.formaFarmaceuticaId;
                formaFarmaceuticaModel.descricao = formaFarmaceuticaComponent.innerText;
                formaFarmaceuticaModel.itensPadroes = null;

                this.model.formasFarmaceuticas.push(formaFarmaceuticaModel);

                this.model.formaFarmaceuticaId = -1;
            }
        }
    }

    private onRemoveFormaFarmaceutica(model: FormaFarmaceuticaModel) {
        this.model.formasFarmaceuticas = this.model.formasFarmaceuticas.filter(p => p != model);
    }

    private async getPreLoadPack() {
        while (!this.loadedList) await Delay(100);

        const unidadeMl = this.preLoadPack.unidadesMedida.filter(p => p.sigla.toLowerCase() == "ml");
        if (unidadeMl.length > 0) {
            this.model.unidadeMedidaId = unidadeMl[0].id;
            this.unidadeMedId = unidadeMl[0].id;
        }

        this.formasFarmaceuticaOptions = this.preLoadPack.formaFarmaceuticaCombo();
    }

    //@ts-ignore
    @Watch("unidadeMedId")
    private onUnidadeMedIdChanged() {
        if (this.unidadeMedId != this.model.unidadeMedidaId) {
            this.unidadeMedId = parseInt(this.model.unidadeMedidaId.toString());
            this.$showError(this.$t("__.ts.atencao"), this.$t("__.ts.sistApenasMl"));
        }
    }

    private async mounted() {
        await this.$store.dispatch(SessionActions.LOAD_USUARIO_LOGADO);

        this.somenteConsulta = this.VALIDAR_PERMISSAO_SOMENTE_CONSULTA("moldes");

        Promise.all([this.getPreLoadPack()])
            .then(() => {
                if (this.$route.params.id) {
                    this.modelId = +this.$route.params.id;
                }

                this.load();
            })
            .catch(() => {});
    }
}
