import Vue from "vue";
import { mapGetters } from "vuex";

import actionBarComponent from "@/components/child/actionBar/actionBar.vue";
import comboComponent from "@/components/child/form/combo.vue";
import dateTimePickerComponent from "@/components/child/form/datetimepicker.vue";
import fieldsetComponent from "@/components/child/form/fieldset.vue";
import moedaComponent from "@/components/child/form/moeda.vue";
import gridComponent from "@/components/child/grid/grid.vue";
import { GridColumn, GridColumnType } from "@/components/child/grid/gridColumn";
import LoadingModalComponent from "@/components/child/loadingmodal/loadingmodal";
import loadingModalComponent from "@/components/child/loadingmodal/loadingmodal.vue";
import { Component, Watch } from "@/decorators";
import ConfiguracaoFranquiaModel from "@/models/configuracaoFranquia/configuracaoFranquiaModel";
import Configuracoes from "@/models/enum/configuracao/configuracoes";
import Especie from "@/models/enum/especiePagamento";
import StatusContas from "@/models/enum/statusContas";
import Bandeira from "@/models/financeiro/bandeiraModel";
import ContaCorrenteModel from "@/models/financeiro/contaCorrenteModel";
import ContaReceberModel, { ContaReceberListParameters } from "@/models/financeiro/contaReceberModel";
import ContaReceberMultiplasModel from "@/models/financeiro/contaReceberMultiplasModel";
import ContaReceberRecebimentoModel from "@/models/financeiro/contaReceberRecebimentoModel";
import FormaRecebimentoModel from "@/models/financeiro/formaRecebimentoModel";
import MetodoPagemento from "@/models/financeiro/metodoPagamentoModel";
import PaginationModel from "@/models/paginationModel";
import ValidationErrorModel from "@/models/validationErrorModel";
import BandeiraService from "@/services/financeiro/bandeiraService";
import ContaCorrenteService from "@/services/financeiro/contaCorrenteService";
import ContaReceberService from "@/services/financeiro/contaReceberService";
import FormaRecebimentoService from "@/services/financeiro/formaRecebimentoService";
import MetodoPagementoService from "@/services/financeiro/metodoPagamentoService";
import MovimentacaoCaixaService from "@/services/financeiro/movimentacaoCaixaService";
import { Getters } from "@/store/store";
import ValidationErrorWrapper from "@/wrappers/validationErrorWrapper";

import "../crud.scss";
import "./edit.scss";

@Component({
    components: {
        comboComponent,
        fieldsetComponent,
        moedaComponent,
        dateTimePickerComponent,
        gridComponent,
        loadingModalComponent,
        actionBarComponent,
    },
    computed: {
        ...mapGetters(["GET_CONFIG_FRANQUIA"] as Getters),
    },
})
export default class ContaReceberMultiplasEditComponent extends Vue {
    // State computed props
    GET_CONFIG_FRANQUIA: (configuracao: Configuracoes) => ConfiguracaoFranquiaModel;

    private service = new ContaReceberService();
    private validationErrorWrapper = new ValidationErrorWrapper(this.$validator);
    private loadingModalComponent: LoadingModalComponent = null;

    model: Array<ContaReceberMultiplasModel> = [];
    modelIds: Array<number> = [];
    saldoReceber = 0;
    idCaixaSugestao = 0;
    dataRecebimento: Date = null;
    contaCorrenteId = 0;
    juros = 0;
    descontos = 0;
    valorRecebido = 0;
    formaPagamentoId = 0;
    autorizacao: string = null;
    nsuObrigatorio = false;

    contaCorrenteOptions: Array<Object> = [];

    formaRecebimentoOptions: Array<Object> = [];
    formaRecebimentoOptionsFiltrado: Array<Object> = [];

    bandeira = -1;
    bandeiraOptions: Array<Object> = [];

    especie = -1;
    especiesOptions: Array<Object> = [
        { text: "Dinheiro", value: Especie.Dinheiro },
        { text: "Cheque", value: Especie.Cheque },
        { text: "Boleto", value: Especie.Boleto },
        { text: "Cartão de Débito", value: Especie.CartaoDebito },
        { text: "Cartão de Crédito", value: Especie.CartaoCredito },
        { text: "Transferência", value: Especie.Transferencia },
        { text: "Pix", value: Especie.Pix },
        { text: "Convênio", value: Especie.Convenio },
        { text: "Transferência Bancária", value: Especie.TransferenciaBancaria },
        { text: "Outros", value: Especie.Outros },
    ]; //= EnumExtensions.getNamesAndValuesOrderedByValues(Especie);

    metodoParcelamentoId = -1;

    metodosPagamentosOptions: Array<Object> = [];
    metodosPagamentosOptionsFiltrado: Array<Object> = [];

    percentualJurosMetodo = 0;
    percentualDescontoMetodo = 0;

    get gridColumns(): Array<GridColumn> {
        return [
            new GridColumn("contaGerencialDescricao", "Conta Gerencial", GridColumnType.String),
            new GridColumn("dataVencimento", "Vencimento", GridColumnType.Date),
            new GridColumn("saldo", "Saldo", GridColumnType.Money),
            new GridColumn("juros", "Juros", GridColumnType.Money, true),
            new GridColumn("desconto", "Desconto", GridColumnType.Money, true),
            new GridColumn("valorRecebido", "Recebido", GridColumnType.Money, true),
        ];
    }

    constructor() {
        super();

        this.dataRecebimento = new Date();
    }

    private load() {
        this.model = [];
        this.validationErrorWrapper.clearErrors();

        if (this.idCaixaSugestao > 0) this.contaCorrenteId = this.idCaixaSugestao;

        if (this.modelIds.length > 0) {
            this.loadingModalComponent.showLoading();

            const customParameters: ContaReceberListParameters = {
                statusContas: StatusContas.Aberto,
                filterKeyId: this.modelIds.toString(),
            };
            this.service
                .list("", "dataVencimento", "asc", 1, 20, customParameters)
                .then(response => {
                    return response.json() as Promise<PaginationModel<ContaReceberModel>>;
                })
                .then(data => {
                    const model = this.model;
                    data.list.forEach(function (data) {
                        const contaReceberMultiplasModel = new ContaReceberMultiplasModel();
                        contaReceberMultiplasModel.updateFrom(data);
                        contaReceberMultiplasModel.valorRecebido = data.saldo;

                        model.push(contaReceberMultiplasModel);
                    });

                    this.loadingModalComponent.hide();
                })
                .catch(error => {
                    this.loadingModalComponent.hide();
                });
        } else {
            this.loadingModalComponent.hide();
        }
    }

    private async loadContasCorrentes() {
        try {
            const data = await new ContaCorrenteService()
                .combo()
                .then(r => r.json() as Promise<PaginationModel<ContaCorrenteModel>>);
            this.contaCorrenteOptions = data.list.map(item => {
                return { value: item.id, text: item.descricao };
            });
        } catch {}
    }

    private async loadMetodoPagamentos() {
        try {
            const data = await new MetodoPagementoService()
                .combo()
                .then(r => r.json() as Promise<PaginationModel<MetodoPagemento>>);
            this.metodosPagamentosOptions = data.list.map(item => {
                return {
                    value: item.id,
                    text: item.descricao,
                    numParcelas: item.numeroParcelas,
                    primeiroIntervalo: item.primeiroIntervalo,
                    intervalo: item.intervalo,
                };
            });
            //por enquanto faz com segunda variavel, se ficar pesado pela quantidade de informacoes, chamar service na alteracoa do forma e criar service com parametro de filtro
            this.metodosPagamentosOptionsFiltrado = this.metodosPagamentosOptions;
        } catch {}
    }

    private async loadBandeiras() {
        try {
            const data = await new BandeiraService().combo().then(r => r.json() as Promise<PaginationModel<Bandeira>>);
            this.bandeiraOptions = data.list.map(item => {
                return { value: item.id, text: item.descricao };
            });
        } catch {}
    }

    private async loadFormasRecebimento() {
        try {
            const data = await new FormaRecebimentoService()
                .comboByEspecie()
                .resolveWithJSON<PaginationModel<FormaRecebimentoModel>>();
            this.formaRecebimentoOptions = data.list.map(item => {
                return {
                    value: item.id,
                    text: item.descricao,
                    especie: item.especie,
                    juros: item.juros,
                    desconto: item.desconto,
                    bandeira: item.bandeiraId,
                    metodosXForma: item.metodoPagamentoXForma,
                };
            });
            this.formaRecebimentoOptionsFiltrado = this.formaRecebimentoOptions;
            this.filtrarFormas();
        } catch {}
    }

    private filtrarFormas() {
        //filtrarr formas conforme especie e bandeira
        if (this.especie >= 0) {
            this.formaRecebimentoOptionsFiltrado = this.formaRecebimentoOptions.filter(
                p => p["especie"] == this.especie,
            );
            if (this.formaRecebimentoOptionsFiltrado.length == 1)
                this.formaPagamentoId = this.formaRecebimentoOptionsFiltrado[0]["value"];
        }
        if ((this.especie == Especie.CartaoDebito || this.especie == Especie.CartaoCredito) && this.bandeira > 0)
            this.formaRecebimentoOptionsFiltrado = this.formaRecebimentoOptions.filter(
                p => p["bandeira"] == this.bandeira && p["especie"] == this.especie,
            );
    }
    private limparFiltroFormas() {
        this.formaRecebimentoOptionsFiltrado = this.formaRecebimentoOptions;
    }
    private filterMetodoFunc(id) {
        const metodos = this.formaRecebimentoOptionsFiltrado.filter(p => p["value"] == this.formaPagamentoId)[0][
            "metodosXForma"
        ];
        return metodos.filter(p => p.metodoPagamentoId == id).length > 0;
    }

    private save() {
        this.$validator
            .validateAll()
            .then(isValid => {
                if (isValid) {
                    this.loadingModalComponent.showSaving();
                    let contabilizar = false;
                    let metodoPagamentoXFormaId = 0;
                    if (this.especie != Especie.CartaoCredito && this.especie != Especie.CartaoDebito) {
                        this.autorizacao = null;
                        this.metodoParcelamentoId = -1;

                        contabilizar = true;
                    } else {
                        if (this.metodoParcelamentoId > 0 && this.formaPagamentoId > 0) {
                            const metodoXForma = this.formaRecebimentoOptionsFiltrado.filter(
                                p => p["value"] == this.formaPagamentoId,
                            )[0];
                            metodoPagamentoXFormaId = metodoXForma["metodosXForma"].filter(
                                p => p.metodoPagamentoId == this.metodoParcelamentoId,
                            )[0].id;
                        }
                    }

                    const recebimentos = new Array<ContaReceberRecebimentoModel>();

                    this.model.forEach(function (model) {
                        const recebimento = new ContaReceberRecebimentoModel();
                        recebimento.contaCorrenteId = this.contaCorrenteId;
                        recebimento.lancamentoId = model.id;
                        recebimento.dataLancamento = new Date();
                        recebimento.dataMovimento = this.dataRecebimento;
                        recebimento.desconto = model.desconto;
                        recebimento.formaPagamentoId = this.formaPagamentoId;
                        recebimento.juros = model.juros;
                        recebimento.valor = model.valorRecebido;
                        recebimento.autorizacao = this.autorizacao;
                        recebimento.contabilizar = contabilizar;
                        recebimento.metodoPagamentoXFormaId = metodoPagamentoXFormaId;

                        recebimentos.push(recebimento);
                    });
                    this.service
                        .liquidarPorRecebimentos(recebimentos)
                        .then(async response => {
                            this.loadingModalComponent.hide();
                            if (response.ok) {
                                await this.$showSuccess("Recebimento", this.$t("__.ts.registroSalvSucess"));
                                this.$router.back();
                            } else {
                                return response.json() as Promise<ValidationErrorModel[]>;
                            }
                        })
                        .then(data => {
                            this.validationErrorWrapper.showErrors(data);
                        })
                        .catch(error => {
                            this.loadingModalComponent.hide();
                        });
                }
            })
            .catch(error => {});
    }

    private cancel() {
        this.$router.back();
    }

    private onRemoveItem(model: ContaReceberMultiplasModel) {
        this.model = this.model.filter(p => p != model);
    }

    @Watch("model", { deep: true })
    private onModelChanged() {
        let totalSaldo = 0;
        let totalJuros = 0;
        let totalDescontos = 0;
        let totalRecebido = 0;

        this.model.forEach(function (model) {
            totalSaldo += +model.saldo;
            totalJuros += +model.juros;
            totalDescontos += +model.desconto;
            totalRecebido += +model.valorRecebido + +model.desconto - +model.juros;
        });

        this.juros = totalJuros;
        this.descontos = totalDescontos;
        this.valorRecebido = totalRecebido;
        this.saldoReceber = totalSaldo - totalRecebido;

        if (this.saldoReceber < 0) this.saldoReceber = 0;
    }
    @Watch("percentualDescontoMetodo")
    private onChangePercentualDesconto() {
        this.calcularJurosMulta();
    }
    @Watch("percentualJurosMetodo")
    private onChangeJurosDesconto() {
        this.calcularJurosMulta();
    }
    @Watch("metodoParcelamentoId")
    private onChangeMetodoPagamentoId() {
        this.buscaPercentuaisJurosMulta();
    }
    @Watch("especie")
    private onChangeEspecie() {
        if (this.especie != Especie.CartaoCredito && this.especie != Especie.CartaoDebito) this.bandeira = -1;

        this.filtrarFormas();
    }
    @Watch("bandeira")
    private onChangeBandeira() {
        this.filtrarFormas();
    }
    @Watch("formaPagamentoId")
    private onChangeFormaPagamentoId() {
        if (this.formaPagamentoId != null && this.formaPagamentoId > 0) {
            if (this.especie != Especie.CartaoCredito && this.especie != Especie.CartaoDebito) {
                this.buscaPercentuaisJurosMulta();
            } else {
                const parcelamentoAnterior = this.metodoParcelamentoId;
                this.bandeira = this.formaRecebimentoOptionsFiltrado.filter(
                    p => p["value"] == this.formaPagamentoId,
                )[0]["bandeira"];
                this.metodosPagamentosOptionsFiltrado = this.metodosPagamentosOptions.filter(function (obj) {
                    return this.filterMetodoFunc(obj["value"]);
                });
                if (this.metodosPagamentosOptionsFiltrado.length == 1)
                    this.metodoParcelamentoId = this.metodosPagamentosOptionsFiltrado[0]["value"];

                //se for igual, nao passa no change para recalcular o percentual de juros e desconto
                if (parcelamentoAnterior == this.metodoParcelamentoId) this.buscaPercentuaisJurosMulta();
            }
        } else {
            if (this.percentualJurosMetodo > 0) {
                this.percentualJurosMetodo = 0;
                //  this.model.juros = 0
            }
            if (this.percentualDescontoMetodo > 0) {
                this.percentualDescontoMetodo = 0;
                // this.model.desconto = 0;
            }
            //   this.model.valor = this.valorAbater;
            this.metodoParcelamentoId = -1;
        }
    }
    private buscaPercentuaisJurosMulta() {
        if (this.metodoParcelamentoId > 0) {
            const metodoXForma = this.formaRecebimentoOptionsFiltrado.filter(
                p => p["value"] == this.formaPagamentoId,
            )[0];

            const metodos = metodoXForma["metodosXForma"].filter(
                p => p.metodoPagamentoId == this.metodoParcelamentoId,
            )[0];

            this.percentualDescontoMetodo = metodos["desconto"];
            this.percentualJurosMetodo = metodos["juros"];

            if (this.percentualDescontoMetodo > 0) {
                // this.model.desconto = this.valorAbater * this.percentualDescontoMetodo / 100;
            }
            if (this.percentualJurosMetodo > 0) {
                // this.model.juros = this.valorAbater * this.percentualJurosMetodo / 100;
            }

            // this.model.valor = this.valorAbater - this.model.desconto + this.model.juros;
        } else {
            if (
                this.especie != Especie.CartaoCredito &&
                this.especie != Especie.CartaoDebito &&
                this.formaPagamentoId > 0
            ) {
                let juros = this.formaRecebimentoOptionsFiltrado.filter(p => p["value"] == this.formaPagamentoId)[0][
                    "juros"
                ];
                let desconto = this.formaRecebimentoOptionsFiltrado.filter(p => p["value"] == this.formaPagamentoId)[0][
                    "desconto"
                ];

                if (!juros) juros = 0;
                if (!desconto) desconto = 0;

                this.percentualJurosMetodo = juros;
                //    this.model.juros = this.valorAbater * this.percentualJurosMetodo / 100;

                this.percentualDescontoMetodo = desconto;
                //    this.model.desconto = this.valorAbater * this.percentualDescontoMetodo / 100;
            } else {
                if (this.percentualJurosMetodo > 0) {
                    this.percentualJurosMetodo = 0;
                    //     this.model.juros = 0
                }
                if (this.percentualDescontoMetodo > 0) {
                    this.percentualDescontoMetodo = 0;
                    //   this.model.desconto = 0;
                }
            }
            //  this.model.valor = this.valorAbater - this.model.desconto + this.model.juros;
        }
    }

    private calcularJurosMulta() {
        for (let i = 0; i < this.model.length; i++) {
            //parseFloat((this.model.valorTotal / numPar).toFixed(2));
            const saldo = this.model[i].saldo;
            this.model[i].desconto = parseFloat(((saldo * this.percentualDescontoMetodo) / 100).toFixed(2));
            this.model[i].juros = parseFloat(((saldo * this.percentualJurosMetodo) / 100).toFixed(2));
            this.model[i].valorRecebido = saldo + this.model[i].juros - this.model[i].desconto;
        }
    }

    private async loadIdCaixaSegestao() {
        try {
            const data = await new MovimentacaoCaixaService()
                .getIdCaixaSessaoAberta()
                .then(r => r.json() as Promise<number>);
            this.idCaixaSugestao = data;
        } catch {
            this.idCaixaSugestao = 0;
        }
    }

    private mounted() {
        this.nsuObrigatorio = this.GET_CONFIG_FRANQUIA(Configuracoes.NsuObrigatorioFaturamentoCartao).verdadeiro;
        this.loadingModalComponent = this.$refs.loadingModalComponent as LoadingModalComponent;
        this.loadingModalComponent.showLoading();
        Promise.all([
            this.loadIdCaixaSegestao(),
            this.loadContasCorrentes(),
            this.loadFormasRecebimento(),
            this.loadBandeiras(),
            this.loadMetodoPagamentos(),
        ])
            .then(result => {
                if (this.$route.params.ids) this.modelIds = this.$route.params.ids.split(",").map(Number);
                this.load();
            })
            .catch(error => {
                this.loadingModalComponent.hide();
            });
    }
}
