export function getCustomParams(customProperties: object) {
    let custom = "";
    for (const key in customProperties) {
        if (customProperties[key] != undefined) {
            custom += `&${key}=${customProperties[key]}`;
        }
    }
    return custom;
}

export function getPaginationParams(
    filterKey: string,
    sortKey: string,
    sortOrder: string,
    pageIndex: number,
    pageSize: number,
) {
    const encodedFilter = encodeURIComponent(filterKey);

    return `filterKey=${encodedFilter}&sortKey=${sortKey}&sortOrder=${sortOrder}&pageIndex=${pageIndex}&pageSize=${pageSize}`;
}
