import { GridColumn, GridColumnType } from "@/components/child/grid/gridColumn";
import { Component } from "@/decorators";
import VisitadorModel from "@/models/visitadorModel";
import VisitadorService from "@/services/visitadorService";

import ListComponentBase from "../listComponentBase";

@Component
export default class VisitadorListComponent extends ListComponentBase<VisitadorModel> {
    private beforeMount() {
        this.setProps(new VisitadorService(), "visitador", "nome");
    }

    get gridColumns(): Array<GridColumn> {
        return [
            new GridColumn("nome", "Nome", GridColumnType.String, false, false, "", "", true),
            new GridColumn("cpf", "CPF", GridColumnType.String, false, false, "", "", true),
            new GridColumn("celular", "Celular", GridColumnType.String, false, false, "", "", true, false),
        ];
    }
}
