import CurvaABCConsiderar from "../enum/produto/curvaABCConsiderar";
import CurvaABCConsiderarPrescritor from "../enum/produto/curvaABCConsiderarPrescritor";

export default class CurvaABCModel {
    dataInicio = new Date();
    dataFinal = new Date();
    considerar = CurvaABCConsiderar.Consumo;
    considerarPrescritores = CurvaABCConsiderarPrescritor.Valor;
    considerarPrescritor = false;
}
