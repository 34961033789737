import LinkPagamentoModel from "@/models/financeiro/gatewayPagamento/linkPagamentoModel";

import nfetch from "../utils/events/nfetch";

import { IService } from "./base/iService";

export default class LinkPagamentoService extends IService<LinkPagamentoModel> {
    constructor() {
        super("LinkPagamento");
    }

    public getLinkPagamentoAtivoByVendaId(vendaId: number): Promise<Response> {
        return nfetch(`/${this.controllerName}/GetLinkPagamentoAtivoByVendaId?vendaId=${vendaId}`, {
            credentials: "same-origin",
        });
    }

    public criarLinkPagamento(vendaId: number): Promise<Response> {
        return nfetch(`/LinkPagamento/CriarLinkPagamento?vendaId=${vendaId}`, {
            credentials: "same-origin",
            method: "post",
        });
    }
}
