import { mapMutations } from "vuex";

import actionBarComponent from "@/components/child/actionBar/actionBar.vue";
import checkboxComponent from "@/components/child/form/checkbox.vue";
import decimalComponent from "@/components/child/form/decimal.vue";
import { Component, Watch, Prop } from "@/decorators";
import PosologiaModel from "@/models/posologiaModel";
import PosologiaService from "@/services/posologiaService";
import { LoadListProps } from "@/store/loadListStore";
import { Mutations } from "@/store/store";

import SolicitarSenhaComponent from "../../solicitarSenha/view";
import solicitarSenhaComponent from "../../solicitarSenha/view.vue";
import EditComponentBase from "../editComponentBase";

import "../crud.scss";

@Component({
    components: {
        decimalComponent,
        actionBarComponent,
        checkboxComponent,
        solicitarSenhaComponent,
    },
    methods: mapMutations(["LOAD_LIST"] as Mutations),
})
export default class PosologiaEditComponent extends EditComponentBase<PosologiaModel> {
    // State computed props
    LOAD_LIST: (props: LoadListProps) => void;

    private solicitarSenhaComponent: SolicitarSenhaComponent = null;

    model: PosologiaModel = new PosologiaModel();
    modelId = 0;

    @Prop({ type: Boolean, default: false }) CalledByShortCut: boolean;
    @Prop({ type: Boolean, default: false }) isAcabado: boolean;
    @Prop({ type: Number, default: null }) indexComp: number;

    private doseDia = 0;
    private doseMes = 0;
    private semanaMes: number = 30 / 7;
    private dias = 0;

    private async beforeMount() {
        await this.setProps(new PosologiaService(), "posologias", new PosologiaModel(), this.CalledByShortCut);
    }

    public async onLoadModel(model: PosologiaModel) {
        this.doseDia = parseFloat((model.dose / 7).toFixed(4));
        this.doseMes = parseFloat((model.dose * this.semanaMes).toFixed(4));
    }

    public async onSucessoSave() {
        if (this.CalledByShortCut && this.indexComp != null) {
            this.$emit("save-ok-index", this.model, this.indexComp);
        }

        this.LOAD_LIST({ loadPosologia: true });
    }

    private async onChangePosologiaPadrao() {
        if (this.model.padrao) {
            await this.$showWarning(this.$t("__.ts.atencao"), this.$t("__.ts.posologiaPadraoAlerta"));
        }

        return this.solicitarSenhaComponent.show();
    }

    private async onResultSolicitarSenha(sucesso: boolean) {
        if (!sucesso) {
            this.model.padrao = !this.model.padrao;
            await this.$showError(this.$t("__.ts.erro"), "Senha invalida, Posologia não será alterada!");
        }
    }

    private onCancelSolicitarSenha() {
        this.model.padrao = !this.model.padrao;
    }

    @Watch("doseDia")
    private onChangedDoseDia() {
        if (parseFloat((this.model.dose / 7).toFixed(4)) != this.doseDia) {
            this.model.dose = this.doseDia * 7;
        }
    }

    @Watch("doseMes")
    private onChangedDoseMes() {
        if (parseFloat((this.model.dose * this.semanaMes).toFixed(3)) != this.doseMes) {
            this.model.dose = parseFloat((this.doseMes / this.semanaMes).toFixed(4));
        }
    }

    @Watch("model.dose")
    private onChangedDoseSemana() {
        if (this.model.dose / 7 != this.doseDia) {
            this.doseDia = parseFloat((this.model.dose / 7).toFixed(4));
        }

        if (this.doseMes != parseFloat((this.model.dose * this.semanaMes).toFixed(3))) {
            this.doseMes = parseFloat((this.model.dose * this.semanaMes).toFixed(3));
        }
    }

    @Watch("dias")
    private onChangedDiasAuxiliar() {
        if (7 / this.dias != this.model.dose) {
            this.model.dose = parseFloat((7 / this.dias).toFixed(4));
        }
    }

    public async mounted() {
        this.solicitarSenhaComponent = this.$refs.solicitarSenhaComponent as SolicitarSenhaComponent;

        this.doseDia = 0;

        if (this.$route.params.id && !this.CalledByShortCut) {
            this.modelId = +this.$route.params.id;
            this.load();
        }

        if (this.CalledByShortCut) {
            this.model = new PosologiaModel();
        }
    }
}
