var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "crud crud-list" },
    [
      _vm.ShowAddOptions
        ? [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-sm-4" }, [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip",
                        value: _vm.errors.first("modelAdd.produtoId"),
                        expression: "errors.first('modelAdd.produtoId')",
                      },
                    ],
                    staticClass: "form-group",
                  },
                  [
                    _c(
                      "label",
                      {
                        staticClass: "control-label",
                        attrs: {
                          for: "modelAdd.produtoId",
                          title: _vm.$t("__.obrigatorio"),
                        },
                      },
                      [
                        _c("span", { staticStyle: { color: "red" } }, [
                          _vm._v("*"),
                        ]),
                        _vm._v(
                          "\n                        " +
                            _vm._s(
                              _vm.$t(
                                "__.Components.parent.notafiscalentrada.item_vue_html.produto"
                              )
                            ) +
                            "\n                    "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("combo-component", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required",
                          expression: "'required'",
                        },
                      ],
                      attrs: {
                        name: "modelAdd.produtoId",
                        data: _vm.produtosOptions,
                        customView: true,
                        refresh: _vm.loadProdutos,
                      },
                      model: {
                        value: _vm.modelAdd.produtoId,
                        callback: function ($$v) {
                          _vm.$set(_vm.modelAdd, "produtoId", $$v)
                        },
                        expression: "modelAdd.produtoId",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-sm-4" }, [
                _c("div", { staticClass: "form-group" }, [
                  _c(
                    "label",
                    {
                      staticClass: "control-label",
                      attrs: { for: "modelAdd.codigo" },
                    },
                    [
                      _vm._v(
                        "\n                        " +
                          _vm._s(
                            _vm.$t(
                              "__.Components.parent.notafiscalentrada.item_vue_html.codProd"
                            )
                          ) +
                          "\n                    "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.modelAdd.codigo,
                        expression: "modelAdd.codigo",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: {
                      type: "text",
                      id: "modelAdd.codigo",
                      name: "modelAdd.codigo",
                    },
                    domProps: { value: _vm.modelAdd.codigo },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.modelAdd, "codigo", $event.target.value)
                      },
                    },
                  }),
                ]),
              ]),
              _vm._v(" "),
              !(_vm.produto && _vm.produto.ignoraControleEstoque)
                ? _c("div", { staticClass: "col-sm-4" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("modelAdd.produtoLoteId"),
                            expression:
                              "errors.first('modelAdd.produtoLoteId')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: {
                              for: "modelAdd.produtoLoteId",
                              title: _vm.$t("__.obrigatorio"),
                            },
                          },
                          [
                            _c("span", { staticStyle: { color: "red" } }, [
                              _vm._v("*"),
                            ]),
                            _vm._v(
                              "\n                        " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Components.parent.notafiscalentrada.item_vue_html.lote"
                                  )
                                ) +
                                "\n                    "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "combo-component",
                          {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required",
                                expression: "'required'",
                              },
                            ],
                            attrs: {
                              id: "modelAdd.produtoLoteId",
                              name: "modelAdd.produtoLoteId",
                              actionName: "lotes",
                              addEditId: _vm.produtoLoteId,
                              addNew: !_vm.produtoLoteId,
                              customView: true,
                              data: _vm.produtoLotesOptions,
                              formAddNewName:
                                _vm.produtoLoteId > 0
                                  ? _vm.$t(
                                      "__.Components.parent.notafiscalentrada.item_vue_html.editProdLote"
                                    )
                                  : _vm.$t(
                                      "__.Components.parent.notafiscalentrada.item_vue_html.codProd"
                                    ),
                              refresh: _vm.loadProdutoLotes,
                              useCloseModalCallback: true,
                            },
                            on: {
                              addNewItemCombo: function ($event) {
                                _vm.onAddNewProdutoLote()
                              },
                              openComboEditItem: _vm.openComboEditProdutoLote,
                              openComboNewItem: function ($event) {
                                _vm.openComboNewProdutoLote()
                              },
                            },
                            model: {
                              value: _vm.modelAdd.produtoLoteId,
                              callback: function ($$v) {
                                _vm.$set(_vm.modelAdd, "produtoLoteId", $$v)
                              },
                              expression: "modelAdd.produtoLoteId",
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                attrs: { slot: "component" },
                                slot: "component",
                              },
                              [
                                _c("produto-lote-component", {
                                  ref: "produtoLoteComponent",
                                  attrs: { CalledByShortCut: true },
                                  on: {
                                    "save-ok": _vm.onProdutoLoteSaveOk,
                                    "on-load-lote": _vm.onCarregaLote,
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-sm-2" }, [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip",
                        value: _vm.errors.first(
                          "modelAdd.unidadeMedidaOrigemId"
                        ),
                        expression:
                          "errors.first('modelAdd.unidadeMedidaOrigemId')",
                      },
                    ],
                    staticClass: "form-group",
                  },
                  [
                    _c(
                      "label",
                      {
                        staticClass: "control-label",
                        attrs: {
                          for: "modelAdd.unidadeMedidaOrigemId",
                          title: _vm.$t("__.obrigatorio"),
                        },
                      },
                      [
                        _c("span", { staticStyle: { color: "red" } }, [
                          _vm._v("*"),
                        ]),
                        _vm._v(
                          "\n                        " +
                            _vm._s(
                              _vm.$t(
                                "__.Components.parent.notafiscalentrada.item_vue_html.unidadeMedidaOrigem"
                              )
                            ) +
                            "\n                    "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("combo-component", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required",
                          expression: "'required'",
                        },
                      ],
                      attrs: {
                        id: "modelAdd.unidadeMedidaOrigemId",
                        name: "modelAdd.unidadeMedidaOrigemId",
                        data: _vm.unidadeMedidaOptions.filter(function (p) {
                          return p["type"] < 3
                        }),
                      },
                      model: {
                        value: _vm.modelAdd.unidadeMedidaOrigemId,
                        callback: function ($$v) {
                          _vm.$set(_vm.modelAdd, "unidadeMedidaOrigemId", $$v)
                        },
                        expression: "modelAdd.unidadeMedidaOrigemId",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-sm-2" }, [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip",
                        value: _vm.errors.first("modelAdd.quantidadeOrigem"),
                        expression: "errors.first('modelAdd.quantidadeOrigem')",
                      },
                    ],
                    staticClass: "form-group",
                  },
                  [
                    _c(
                      "label",
                      {
                        staticClass: "control-label",
                        attrs: {
                          for: "modelAdd.quantidadeOrigem",
                          title: _vm.$t("__.obrigatorio"),
                        },
                      },
                      [
                        _c("span", { staticStyle: { color: "red" } }, [
                          _vm._v("*"),
                        ]),
                        _vm._v(
                          "\n                        " +
                            _vm._s(
                              _vm.$t(
                                "__.Components.parent.notafiscalentrada.item_vue_html.quantidadeOrigem"
                              )
                            ) +
                            "\n                    "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("decimal-component", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required",
                          expression: "'required'",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: {
                        id: "quantidadeOrigem",
                        name: "quantidadeOrigem",
                      },
                      model: {
                        value: _vm.modelAdd.quantidadeOrigem,
                        callback: function ($$v) {
                          _vm.$set(_vm.modelAdd, "quantidadeOrigem", $$v)
                        },
                        expression: "modelAdd.quantidadeOrigem",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-sm-2" }, [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip",
                        value: _vm.errors.first("modelAdd.quantidade"),
                        expression: "errors.first('modelAdd.quantidade')",
                      },
                    ],
                    staticClass: "form-group",
                  },
                  [
                    _c(
                      "label",
                      {
                        staticClass: "control-label",
                        attrs: {
                          for: "quantidade",
                          title: _vm.$t("__.obrigatorio"),
                        },
                      },
                      [
                        _c("span", { staticStyle: { color: "red" } }, [
                          _vm._v("*"),
                        ]),
                        _vm._v(
                          "\n                        " +
                            _vm._s(
                              _vm.$t(
                                "__.Components.parent.notafiscalentrada.item_vue_html.quantidadeConvertida"
                              )
                            ) +
                            "\n                        "
                        ),
                        _c("data-tooltip-component", {
                          attrs: {
                            text: _vm.$t(
                              "__.Components.parent.notafiscalentrada.item_vue_html.tlpQuantidadeConvertida"
                            ),
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("decimal-com-sinal-custom-component", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required",
                          expression: "'required'",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: {
                        id: "quantidade",
                        name: "quantidade",
                        signal: _vm.modelAdd.unidadeMedidaSigla,
                      },
                      model: {
                        value: _vm.modelAdd.quantidade,
                        callback: function ($$v) {
                          _vm.$set(_vm.modelAdd, "quantidade", $$v)
                        },
                        expression: "modelAdd.quantidade",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-sm-2" }, [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip",
                        value: _vm.errors.first("modelAdd.valorUnitarioOrigem"),
                        expression:
                          "errors.first('modelAdd.valorUnitarioOrigem')",
                      },
                    ],
                    staticClass: "form-group",
                  },
                  [
                    _c(
                      "label",
                      {
                        staticClass: "control-label",
                        attrs: {
                          for: "modelAdd.valorUnitario",
                          title: _vm.$t("__.obrigatorio"),
                        },
                      },
                      [
                        _c("span", { staticStyle: { color: "red" } }, [
                          _vm._v("*"),
                        ]),
                        _vm._v(
                          "\n                        " +
                            _vm._s(
                              _vm.$t(
                                "__.Components.parent.notafiscalentrada.item_vue_html.valorUnitarioOrigem"
                              )
                            ) +
                            "\n                    "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("data-tooltip-component", {
                      attrs: {
                        text: _vm.$t(
                          "__.Components.parent.notafiscalentrada.item_vue_html.tlpValorUnitarioOrigem"
                        ),
                      },
                    }),
                    _vm._v(" "),
                    _c("moeda-component", {
                      directives: [
                        {
                          name: "mask-moeda-decimal",
                          rawName: "v-mask-moeda-decimal",
                        },
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required",
                          expression: "'required'",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: {
                        id: "valorUnitarioOrigem",
                        name: "valorUnitarioOrigem",
                      },
                      model: {
                        value: _vm.modelAdd.valorUnitarioOrigem,
                        callback: function ($$v) {
                          _vm.$set(_vm.modelAdd, "valorUnitarioOrigem", $$v)
                        },
                        expression: "modelAdd.valorUnitarioOrigem",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-sm-4" }, [
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c(
                      "label",
                      {
                        staticClass: "control-label",
                        attrs: { for: "modelAdd.valorUnitario" },
                      },
                      [
                        _vm._v(
                          "\n                        " +
                            _vm._s(
                              _vm.$t(
                                "__.Components.parent.notafiscalentrada.item_vue_html.valorUnitarioConvertido"
                              )
                            ) +
                            "\n                    "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("data-tooltip-component", {
                      attrs: {
                        text: _vm.$t(
                          "__.Components.parent.notafiscalentrada.item_vue_html.tlpValorUnitarioConvertido"
                        ),
                      },
                    }),
                    _vm._v(" "),
                    _c("moeda-component", {
                      directives: [
                        {
                          name: "mask-moeda-decimal",
                          rawName: "v-mask-moeda-decimal",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: {
                        id: "valorUnitario",
                        name: "valorUnitario",
                        disabled: "",
                      },
                      model: {
                        value: _vm.modelAdd.valorUnitario,
                        callback: function ($$v) {
                          _vm.$set(_vm.modelAdd, "valorUnitario", $$v)
                        },
                        expression: "modelAdd.valorUnitario",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-sm-2" }, [
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c("label", { staticClass: "control-label" }, [
                      _vm._v(
                        "\n                        " +
                          _vm._s(
                            _vm.$t(
                              "__.Components.parent.notafiscalentrada.item_vue_html.valorFrete"
                            )
                          ) +
                          "\n                    "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("moeda-component", {
                      directives: [
                        {
                          name: "mask-moeda-decimal",
                          rawName: "v-mask-moeda-decimal",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { id: "valorFrete", name: "valorFrete" },
                      model: {
                        value: _vm.modelAdd.valorFrete,
                        callback: function ($$v) {
                          _vm.$set(_vm.modelAdd, "valorFrete", $$v)
                        },
                        expression: "modelAdd.valorFrete",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-sm-2" }, [
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c("label", { staticClass: "control-label" }, [
                      _vm._v(
                        "\n                        " +
                          _vm._s(
                            _vm.$t(
                              "__.Components.parent.notafiscalentrada.item_vue_html.valorSeguro"
                            )
                          ) +
                          "\n                    "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("moeda-component", {
                      directives: [
                        {
                          name: "mask-moeda-decimal",
                          rawName: "v-mask-moeda-decimal",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { id: "valorSeguro", name: "valorSeguro" },
                      model: {
                        value: _vm.modelAdd.valorSeguro,
                        callback: function ($$v) {
                          _vm.$set(_vm.modelAdd, "valorSeguro", $$v)
                        },
                        expression: "modelAdd.valorSeguro",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-sm-2" }, [
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c("label", { staticClass: "control-label" }, [
                      _vm._v(
                        "\n                        " +
                          _vm._s(
                            _vm.$t(
                              "__.Components.parent.notafiscalentrada.item_vue_html.valorOutraDespesa"
                            )
                          ) +
                          "\n                    "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("moeda-component", {
                      directives: [
                        {
                          name: "mask-moeda-decimal",
                          rawName: "v-mask-moeda-decimal",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: {
                        id: "valorOutraDespesa",
                        name: "valorOutraDespesa",
                      },
                      model: {
                        value: _vm.modelAdd.valorOutraDespesa,
                        callback: function ($$v) {
                          _vm.$set(_vm.modelAdd, "valorOutraDespesa", $$v)
                        },
                        expression: "modelAdd.valorOutraDespesa",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-sm-2" }, [
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c("label", { staticClass: "control-label" }, [
                      _vm._v(
                        "\n                        " +
                          _vm._s(
                            _vm.$t(
                              "__.Components.parent.notafiscalentrada.item_vue_html.valorDesconto"
                            )
                          ) +
                          "\n                    "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("moeda-component", {
                      directives: [
                        {
                          name: "mask-moeda-decimal",
                          rawName: "v-mask-moeda-decimal",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { id: "desconto", name: "valorDesconto" },
                      model: {
                        value: _vm.modelAdd.valorDesconto,
                        callback: function ($$v) {
                          _vm.$set(_vm.modelAdd, "valorDesconto", $$v)
                        },
                        expression: "modelAdd.valorDesconto",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-sm-4" }, [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip",
                        value: _vm.errors.first("valorTotal"),
                        expression: "errors.first('valorTotal')",
                      },
                    ],
                    staticClass: "form-group",
                  },
                  [
                    _c(
                      "label",
                      {
                        staticClass: "control-label",
                        attrs: { for: "valorTotal" },
                      },
                      [
                        _vm._v(
                          "\n                        " +
                            _vm._s(
                              _vm.$t(
                                "__.Components.parent.notafiscalentrada.item_vue_html.vlrTotal"
                              )
                            ) +
                            "\n                    "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("data-tooltip-component", {
                      attrs: {
                        text: _vm.$t(
                          "__.Components.parent.notafiscalentrada.item_vue_html.tlpValorTotal"
                        ),
                      },
                    }),
                    _vm._v(" "),
                    _c("moeda-component", {
                      directives: [
                        {
                          name: "mask-moeda-decimal",
                          rawName: "v-mask-moeda-decimal",
                        },
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required",
                          expression: "'required'",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { id: "valorTotal", name: "valorTotal" },
                      model: {
                        value: _vm.modelAdd.valorTotal,
                        callback: function ($$v) {
                          _vm.$set(_vm.modelAdd, "valorTotal", $$v)
                        },
                        expression: "modelAdd.valorTotal",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("buttonIncluirComponent", {
              attrs: { alterar: _vm.editingModel, cancelar: _vm.editingModel },
              on: { click: _vm.onAdicionarItem, cancelar: _vm.onCancelar },
            }),
          ]
        : _vm._e(),
      _vm._v(" "),
      _c("grid-component", {
        attrs: {
          data: _vm.model,
          columns: _vm.gridColumns,
          subColumns: _vm.gridColumnsImpostos,
          subDataName: _vm.subDataName,
          conditionShowSubData: _vm.conditionShowSubData,
          "page-index": _vm.pageIndex,
          "page-size": _vm.pageSize,
          refreshData: _vm.refreshGrid,
          "show-action-column": _vm.ShowAddOptions,
          "show-edit-item": _vm.ShowAddOptions,
          "show-remove-item": _vm.ShowAddOptions,
          showAddNewButton: _vm.showInfos,
          "show-table-foot": _vm.showInfos,
          "show-table-head": _vm.showInfos,
          showExpand: true,
          total: _vm.total,
        },
        on: {
          "change-filter-key": _vm.onChangeFilterKey,
          "change-page": _vm.onChangePage,
          "change-sort": _vm.onChangeSort,
          "edit-item": _vm.onEditItem,
          "remove-item": _vm.onRemoveItem,
        },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }