interface Pais {
    codigoSPED: string;
    descricao: string;
    codigoSiscomex: string;
}

export default class PaisService {
    public get(): Pais[] {
        const paises = [
            {
                "codigoSPED": "0132",
                "descricao": "AFEGANISTAO",
                "codigoSiscomex": "013"
            },
            {
                "codigoSPED": "7560",
                "descricao": "AFRICA DO SUL",
                "codigoSiscomex": "756"
            },
            {
                "codigoSPED": "0175",
                "descricao": "ALBANIA",
                "codigoSiscomex": "017"
            },
            {
                "codigoSPED": "0230",
                "descricao": "ALEMANHA",
                "codigoSiscomex": "023"
            },
            {
                "codigoSPED": "0370",
                "descricao": "ANDORRA",
                "codigoSiscomex": "037"
            },
            {
                "codigoSPED": "0400",
                "descricao": "ANGOLA",
                "codigoSiscomex": "040"
            },
            {
                "codigoSPED": "0418",
                "descricao": "ANGUILLA",
                "codigoSiscomex": "041"
            },
            {
                "codigoSPED": "0434",
                "descricao": "ANTIGUA E BARBUDA",
                "codigoSiscomex": "043"
            },
            {
                "codigoSPED": "0531",
                "descricao": "ARABIA SAUDITA",
                "codigoSiscomex": "053"
            },
            {
                "codigoSPED": "0590",
                "descricao": "ARGELIA",
                "codigoSiscomex": "059"
            },
            {
                "codigoSPED": "0639",
                "descricao": "ARGENTINA",
                "codigoSiscomex": "063"
            },
            {
                "codigoSPED": "0647",
                "descricao": "ARMENIA",
                "codigoSiscomex": "064"
            },
            {
                "codigoSPED": "0655",
                "descricao": "ARUBA",
                "codigoSiscomex": "065"
            },
            {
                "codigoSPED": "0698",
                "descricao": "AUSTRALIA",
                "codigoSiscomex": "069"
            },
            {
                "codigoSPED": "0728",
                "descricao": "AUSTRIA",
                "codigoSiscomex": "072"
            },
            {
                "codigoSPED": "0736",
                "descricao": "AZERBAIDJAO",
                "codigoSiscomex": "073"
            },
            {
                "codigoSPED": "0779",
                "descricao": "BAHAMAS, ILHAS",
                "codigoSiscomex": "077"
            },
            {
                "codigoSPED": "0817",
                "descricao": "BANGLADESH",
                "codigoSiscomex": "081"
            },
            {
                "codigoSPED": "0833",
                "descricao": "BARBADOS",
                "codigoSiscomex": "083"
            },
            {
                "codigoSPED": "0809",
                "descricao": "BAREIN",
                "codigoSiscomex": "080"
            },
            {
                "codigoSPED": "0850",
                "descricao": "BELARUS",
                "codigoSiscomex": "085"
            },
            {
                "codigoSPED": "0876",
                "descricao": "BELGICA",
                "codigoSiscomex": "087"
            },
            {
                "codigoSPED": "0884",
                "descricao": "BELIZE",
                "codigoSiscomex": "088"
            },
            {
                "codigoSPED": "2291",
                "descricao": "BENIN",
                "codigoSiscomex": "229"
            },
            {
                "codigoSPED": "0906",
                "descricao": "BERMUDAS",
                "codigoSiscomex": "090"
            },
            {
                "codigoSPED": "0973",
                "descricao": "BOLIVIA",
                "codigoSiscomex": "097"
            },
            {
                "codigoSPED": "0990",
                "descricao": "BONAIRE, SAINT EUSTATIUS E SABA",
                "codigoSiscomex": "099"
            },
            {
                "codigoSPED": "0981",
                "descricao": "BOSNIA-HERZEGOVINA",
                "codigoSiscomex": "098"
            },
            {
                "codigoSPED": "1015",
                "descricao": "BOTSUANA",
                "codigoSiscomex": "101"
            },
            {
                "codigoSPED": "1098",
                "descricao": "BOUVET, ILHA",
                "codigoSiscomex": "102"
            },
            {
                "codigoSPED": "1058",
                "descricao": "BRASIL",
                "codigoSiscomex": "105"
            },
            {
                "codigoSPED": "1082",
                "descricao": "BRUNEI",
                "codigoSiscomex": "108"
            },
            {
                "codigoSPED": "1112",
                "descricao": "BULGARIA",
                "codigoSiscomex": "111"
            },
            {
                "codigoSPED": "0310",
                "descricao": "BURKINA FASO",
                "codigoSiscomex": "031"
            },
            {
                "codigoSPED": "1155",
                "descricao": "BURUNDI",
                "codigoSiscomex": "115"
            },
            {
                "codigoSPED": "1198",
                "descricao": "BUTAO",
                "codigoSiscomex": "119"
            },
            {
                "codigoSPED": "1279",
                "descricao": "CABO VERDE",
                "codigoSiscomex": "127"
            },
            {
                "codigoSPED": "1457",
                "descricao": "CAMAROES",
                "codigoSiscomex": "145"
            },
            {
                "codigoSPED": "1414",
                "descricao": "CAMBOJA",
                "codigoSiscomex": "141"
            },
            {
                "codigoSPED": "1490",
                "descricao": "CANADA",
                "codigoSiscomex": "149"
            },
            {
                "codigoSPED": "1546",
                "descricao": "CATAR",
                "codigoSiscomex": "154"
            },
            {
                "codigoSPED": "1376",
                "descricao": "CAYMAN",
                "codigoSiscomex": "137"
            },
            {
                "codigoSPED": "1538",
                "descricao": "CAZAQUISTAO",
                "codigoSiscomex": "153"
            },
            {
                "codigoSPED": "7889",
                "descricao": "CHADE",
                "codigoSiscomex": "788"
            },
            {
                "codigoSPED": "1589",
                "descricao": "CHILE",
                "codigoSiscomex": "158"
            },
            {
                "codigoSPED": "1600",
                "descricao": "CHINA",
                "codigoSiscomex": "160"
            },
            {
                "codigoSPED": "1635",
                "descricao": "CHIPRE",
                "codigoSiscomex": "163"
            },
            {
                "codigoSPED": "5118",
                "descricao": "CHRISTMAS, ILHA (NAVIDAD)",
                "codigoSiscomex": "511"
            },
            {
                "codigoSPED": "7412",
                "descricao": "CINGAPURA",
                "codigoSiscomex": "741"
            },
            {
                "codigoSPED": "1651",
                "descricao": "COCOS (KEELINGS)",
                "codigoSiscomex": "165"
            },
            {
                "codigoSPED": "1694",
                "descricao": "COLOMBIA",
                "codigoSiscomex": "169"
            },
            {
                "codigoSPED": "1732",
                "descricao": "COMORES",
                "codigoSiscomex": "173"
            },
            {
                "codigoSPED": "1775",
                "descricao": "CONGO",
                "codigoSiscomex": "177"
            },
            {
                "codigoSPED": "1830",
                "descricao": "COOK",
                "codigoSiscomex": "183"
            },
            {
                "codigoSPED": "1872",
                "descricao": "COREIA DO NORTE",
                "codigoSiscomex": "187"
            },
            {
                "codigoSPED": "1902",
                "descricao": "COREIA DO SUL",
                "codigoSiscomex": "190"
            },
            {
                "codigoSPED": "1937",
                "descricao": "COSTA DO MARFIM",
                "codigoSiscomex": "193"
            },
            {
                "codigoSPED": "1961",
                "descricao": "COSTA RICA",
                "codigoSiscomex": "196"
            },
            {
                "codigoSPED": "1953",
                "descricao": "CROACIA",
                "codigoSiscomex": "195"
            },
            {
                "codigoSPED": "1996",
                "descricao": "CUBA",
                "codigoSiscomex": "199"
            },
            {
                "codigoSPED": "2003",
                "descricao": "CURACAO",
                "codigoSiscomex": "200"
            },
            {
                "codigoSPED": "2321",
                "descricao": "DINAMARCA",
                "codigoSiscomex": "232"
            },
            {
                "codigoSPED": "7838",
                "descricao": "DJIBUTI",
                "codigoSiscomex": "783"
            },
            {
                "codigoSPED": "2356",
                "descricao": "DOMINICA",
                "codigoSiscomex": "235"
            },
            {
                "codigoSPED": "2402",
                "descricao": "EGITO",
                "codigoSiscomex": "240"
            },
            {
                "codigoSPED": "6874",
                "descricao": "EL SALVADOR",
                "codigoSiscomex": "687"
            },
            {
                "codigoSPED": "2445",
                "descricao": "EMIRADOS ARABES UNIDOS",
                "codigoSiscomex": "244"
            },
            {
                "codigoSPED": "2399",
                "descricao": "EQUADOR",
                "codigoSiscomex": "239"
            },
            {
                "codigoSPED": "2437",
                "descricao": "ERITREIA",
                "codigoSiscomex": "243"
            },
            {
                "codigoSPED": "2470",
                "descricao": "ESLOVAQUIA",
                "codigoSiscomex": "247"
            },
            {
                "codigoSPED": "2461",
                "descricao": "ESLOVENIA",
                "codigoSiscomex": "246"
            },
            {
                "codigoSPED": "2453",
                "descricao": "ESPANHA",
                "codigoSiscomex": "245"
            },
            {
                "codigoSPED": "2496",
                "descricao": "ESTADOS UNIDOS",
                "codigoSiscomex": "249"
            },
            {
                "codigoSPED": "2518",
                "descricao": "ESTONIA",
                "codigoSiscomex": "251"
            },
            {
                "codigoSPED": "7544",
                "descricao": "ESWATINI (ANTIGA SUAZILANDIA",
                "codigoSiscomex": "754"
            },
            {
                "codigoSPED": "2534",
                "descricao": "ETIOPIA",
                "codigoSiscomex": "253"
            },
            {
                "codigoSPED": "2550",
                "descricao": "FALKLAND (MALVINAS)",
                "codigoSiscomex": "255"
            },
            {
                "codigoSPED": "2593",
                "descricao": "FAROE",
                "codigoSiscomex": "259"
            },
            {
                "codigoSPED": "8702",
                "descricao": "FIJI",
                "codigoSiscomex": "870"
            },
            {
                "codigoSPED": "2674",
                "descricao": "FILIPINAS",
                "codigoSiscomex": "267"
            },
            {
                "codigoSPED": "2712",
                "descricao": "FINLANDIA",
                "codigoSiscomex": "271"
            },
            {
                "codigoSPED": "2755",
                "descricao": "FRANCA",
                "codigoSiscomex": "275"
            },
            {
                "codigoSPED": "2810",
                "descricao": "GABAO",
                "codigoSiscomex": "281"
            },
            {
                "codigoSPED": "2852",
                "descricao": "GAMBIA",
                "codigoSiscomex": "285"
            },
            {
                "codigoSPED": "2895",
                "descricao": "GANA",
                "codigoSiscomex": "289"
            },
            {
                "codigoSPED": "2917",
                "descricao": "GEORGIA",
                "codigoSiscomex": "291"
            },
            {
                "codigoSPED": "2933",
                "descricao": "GIBRALTAR",
                "codigoSiscomex": "293"
            },
            {
                "codigoSPED": "2976",
                "descricao": "GRANADA",
                "codigoSiscomex": "297"
            },
            {
                "codigoSPED": "3018",
                "descricao": "GRECIA",
                "codigoSiscomex": "301"
            },
            {
                "codigoSPED": "3050",
                "descricao": "GROENLANDIA",
                "codigoSiscomex": "305"
            },
            {
                "codigoSPED": "3093",
                "descricao": "GUADALUPE",
                "codigoSiscomex": "309"
            },
            {
                "codigoSPED": "3131",
                "descricao": "GUAM",
                "codigoSiscomex": "313"
            },
            {
                "codigoSPED": "3174",
                "descricao": "GUATEMALA",
                "codigoSiscomex": "317"
            },
            {
                "codigoSPED": "1504",
                "descricao": "GUERNSEY, ILHA DO CANAL (INCLUI ALDERNEY E SARK)",
                "codigoSiscomex": "321"
            },
            {
                "codigoSPED": "3379",
                "descricao": "GUIANA",
                "codigoSiscomex": "337"
            },
            {
                "codigoSPED": "3255",
                "descricao": "GUIANA FRANCESA",
                "codigoSiscomex": "325"
            },
            {
                "codigoSPED": "3298",
                "descricao": "GUINE",
                "codigoSiscomex": "329"
            },
            {
                "codigoSPED": "3344",
                "descricao": "GUINE-BISSAU",
                "codigoSiscomex": "334"
            },
            {
                "codigoSPED": "3310",
                "descricao": "GUINE-EQUATORIAL",
                "codigoSiscomex": "331"
            },
            {
                "codigoSPED": "3417",
                "descricao": "HAITI",
                "codigoSiscomex": "341"
            },
            {
                "codigoSPED": "5738",
                "descricao": "HOLANDA (PAISES BAIXOS)",
                "codigoSiscomex": "573"
            },
            {
                "codigoSPED": "3450",
                "descricao": "HONDURAS",
                "codigoSiscomex": "345"
            },
            {
                "codigoSPED": "3514",
                "descricao": "HONG KONG",
                "codigoSiscomex": "351"
            },
            {
                "codigoSPED": "3557",
                "descricao": "HUNGRIA",
                "codigoSiscomex": "355"
            },
            {
                "codigoSPED": "3573",
                "descricao": "IEMEN",
                "codigoSiscomex": "357"
            },
            {
                "codigoSPED": "3611",
                "descricao": "INDIA",
                "codigoSiscomex": "361"
            },
            {
                "codigoSPED": "3654",
                "descricao": "INDONESIA",
                "codigoSiscomex": "365"
            },
            {
                "codigoSPED": "3727",
                "descricao": "IRA",
                "codigoSiscomex": "372"
            },
            {
                "codigoSPED": "3697",
                "descricao": "IRAQUE",
                "codigoSiscomex": "369"
            },
            {
                "codigoSPED": "3751",
                "descricao": "IRLANDA",
                "codigoSiscomex": "375"
            },
            {
                "codigoSPED": "3794",
                "descricao": "ISLANDIA",
                "codigoSiscomex": "379"
            },
            {
                "codigoSPED": "3832",
                "descricao": "ISRAEL",
                "codigoSiscomex": "383"
            },
            {
                "codigoSPED": "3867",
                "descricao": "ITALIA",
                "codigoSiscomex": "386"
            },
            {
                "codigoSPED": "3913",
                "descricao": "JAMAICA",
                "codigoSiscomex": "391"
            },
            {
                "codigoSPED": "3999",
                "descricao": "JAPAO",
                "codigoSiscomex": "399"
            },
            {
                "codigoSPED": "1508",
                "descricao": "JERSEY, ILHA DO CANAL",
                "codigoSiscomex": "393"
            },
            {
                "codigoSPED": "3964",
                "descricao": "JOHNSTON",
                "codigoSiscomex": "396"
            },
            {
                "codigoSPED": "4030",
                "descricao": "JORDANIA",
                "codigoSiscomex": "403"
            },
            {
                "codigoSPED": "4111",
                "descricao": "KIRIBATI",
                "codigoSiscomex": "411"
            },
            {
                "codigoSPED": "1988",
                "descricao": "KUWEIT",
                "codigoSiscomex": "198"
            },
            {
                "codigoSPED": "4200",
                "descricao": "LAOS",
                "codigoSiscomex": "420"
            },
            {
                "codigoSPED": "4260",
                "descricao": "LESOTO",
                "codigoSiscomex": "426"
            },
            {
                "codigoSPED": "4278",
                "descricao": "LETONIA",
                "codigoSiscomex": "427"
            },
            {
                "codigoSPED": "4316",
                "descricao": "LIBANO",
                "codigoSiscomex": "431"
            },
            {
                "codigoSPED": "4340",
                "descricao": "LIBERIA",
                "codigoSiscomex": "434"
            },
            {
                "codigoSPED": "4383",
                "descricao": "LIBIA",
                "codigoSiscomex": "438"
            },
            {
                "codigoSPED": "4405",
                "descricao": "LIECHTENSTEIN",
                "codigoSiscomex": "440"
            },
            {
                "codigoSPED": "4421",
                "descricao": "LITUANIA",
                "codigoSiscomex": "442"
            },
            {
                "codigoSPED": "4456",
                "descricao": "LUXEMBURGO",
                "codigoSiscomex": "445"
            },
            {
                "codigoSPED": "4472",
                "descricao": "MACAU",
                "codigoSiscomex": "447"
            },
            {
                "codigoSPED": "4421",
                "descricao": "MACEDONIA",
                "codigoSiscomex": "449"
            },
            {
                "codigoSPED": "4502",
                "descricao": "MADAGASCAR",
                "codigoSiscomex": "450"
            },
            {
                "codigoSPED": "4553",
                "descricao": "MALASIA",
                "codigoSiscomex": "455"
            },
            {
                "codigoSPED": "4588",
                "descricao": "MALAVI",
                "codigoSiscomex": "458"
            },
            {
                "codigoSPED": "4618",
                "descricao": "MALDIVAS",
                "codigoSiscomex": "461"
            },
            {
                "codigoSPED": "4642",
                "descricao": "MALI",
                "codigoSiscomex": "464"
            },
            {
                "codigoSPED": "4677",
                "descricao": "MALTA",
                "codigoSiscomex": "467"
            },
            {
                "codigoSPED": "3595",
                "descricao": "MAN, ILHA DE",
                "codigoSiscomex": "359"
            },
            {
                "codigoSPED": "4723",
                "descricao": "MARIANAS DO NORTE",
                "codigoSiscomex": "472"
            },
            {
                "codigoSPED": "4740",
                "descricao": "MARROCOS",
                "codigoSiscomex": "474"
            },
            {
                "codigoSPED": "4766",
                "descricao": "MARSHALL, ILHAS",
                "codigoSiscomex": "476"
            },
            {
                "codigoSPED": "4774",
                "descricao": "MARTINICA",
                "codigoSiscomex": "477"
            },
            {
                "codigoSPED": "4855",
                "descricao": "MAURICIO",
                "codigoSiscomex": "485"
            },
            {
                "codigoSPED": "4880",
                "descricao": "MAURITANIA",
                "codigoSiscomex": "488"
            },
            {
                "codigoSPED": "4936",
                "descricao": "MEXICO",
                "codigoSiscomex": "493"
            },
            {
                "codigoSPED": "0930",
                "descricao": "MIANMAR",
                "codigoSiscomex": "093"
            },
            {
                "codigoSPED": "4995",
                "descricao": "MICRONESIA",
                "codigoSiscomex": "499"
            },
            {
                "codigoSPED": "5053",
                "descricao": "MOCAMBIQUE",
                "codigoSiscomex": "505"
            },
            {
                "codigoSPED": "4944",
                "descricao": "MOLDAVIA",
                "codigoSiscomex": "494"
            },
            {
                "codigoSPED": "4952",
                "descricao": "MONACO",
                "codigoSiscomex": "495"
            },
            {
                "codigoSPED": "4979",
                "descricao": "MONGOLIA",
                "codigoSiscomex": "497"
            },
            {
                "codigoSPED": "4985",
                "descricao": "MONTENEGRO",
                "codigoSiscomex": "498"
            },
            {
                "codigoSPED": "5010",
                "descricao": "MONTSERRAT",
                "codigoSiscomex": "501"
            },
            {
                "codigoSPED": "5070",
                "descricao": "NAMIBIA",
                "codigoSiscomex": "507"
            },
            {
                "codigoSPED": "5088",
                "descricao": "NAURU",
                "codigoSiscomex": "508"
            },
            {
                "codigoSPED": "5177",
                "descricao": "NEPAL",
                "codigoSiscomex": "517"
            },
            {
                "codigoSPED": "5215",
                "descricao": "NICARAGUA",
                "codigoSiscomex": "521"
            },
            {
                "codigoSPED": "5258",
                "descricao": "NIGER",
                "codigoSiscomex": "525"
            },
            {
                "codigoSPED": "5282",
                "descricao": "NIGERIA",
                "codigoSiscomex": "528"
            },
            {
                "codigoSPED": "5312",
                "descricao": "NIUE",
                "codigoSiscomex": "531"
            },
            {
                "codigoSPED": "5355",
                "descricao": "NORFOLK, ILHA",
                "codigoSiscomex": "535"
            },
            {
                "codigoSPED": "5380",
                "descricao": "NORUEGA",
                "codigoSiscomex": "538"
            },
            {
                "codigoSPED": "5428",
                "descricao": "NOVA CALEDONIA",
                "codigoSiscomex": "542"
            },
            {
                "codigoSPED": "5487",
                "descricao": "NOVA ZELANDIA",
                "codigoSiscomex": "548"
            },
            {
                "codigoSPED": "5568",
                "descricao": "OMA",
                "codigoSiscomex": "556"
            },
            {
                "codigoSPED": "5665",
                "descricao": "PACIFICO, ILHAS DO (POSSESSAO DOS EUA)",
                "codigoSiscomex": "566"
            },
            {
                "codigoSPED": "5754",
                "descricao": "PALAU",
                "codigoSiscomex": "575"
            },
            {
                "codigoSPED": "5780",
                "descricao": "PALESTINA",
                "codigoSiscomex": "578"
            },
            {
                "codigoSPED": "5800",
                "descricao": "PANAMA",
                "codigoSiscomex": "580"
            },
            {
                "codigoSPED": "5452",
                "descricao": "PAPUA NOVA GUINE",
                "codigoSiscomex": "545"
            },
            {
                "codigoSPED": "5762",
                "descricao": "PAQUISTAO",
                "codigoSiscomex": "576"
            },
            {
                "codigoSPED": "5860",
                "descricao": "PARAGUAI",
                "codigoSiscomex": "586"
            },
            {
                "codigoSPED": "5894",
                "descricao": "PERU",
                "codigoSiscomex": "589"
            },
            {
                "codigoSPED": "5932",
                "descricao": "PITCAIRN",
                "codigoSiscomex": "593"
            },
            {
                "codigoSPED": "5991",
                "descricao": "POLINESIA FRANCESA",
                "codigoSiscomex": "599"
            },
            {
                "codigoSPED": "6033",
                "descricao": "POLONIA",
                "codigoSiscomex": "603"
            },
            {
                "codigoSPED": "6114",
                "descricao": "PORTO RICO",
                "codigoSiscomex": "611"
            },
            {
                "codigoSPED": "6076",
                "descricao": "PORTUGAL",
                "codigoSiscomex": "607"
            },
            {
                "codigoSPED": "6238",
                "descricao": "QUENIA",
                "codigoSiscomex": "623"
            },
            {
                "codigoSPED": "6254",
                "descricao": "QUIRGUISTAO",
                "codigoSiscomex": "625"
            },
            {
                "codigoSPED": "6289",
                "descricao": "REINO UNIDO",
                "codigoSiscomex": "628"
            },
            {
                "codigoSPED": "6408",
                "descricao": "REPUBLICA CENTRO-AFRICANA",
                "codigoSiscomex": "640"
            },
            {
                "codigoSPED": "8885",
                "descricao": "REPUBLICA DEMOCRATICA DO CONGO",
                "codigoSiscomex": "888"
            },
            {
                "codigoSPED": "6475",
                "descricao": "REPUBLICA DOMINICANA",
                "codigoSiscomex": "647"
            },
            {
                "codigoSPED": "7919",
                "descricao": "REPUBLICA TCHECA",
                "codigoSiscomex": "791"
            },
            {
                "codigoSPED": "6602",
                "descricao": "REUNIAO",
                "codigoSiscomex": "660"
            },
            {
                "codigoSPED": "6700",
                "descricao": "ROMENIA",
                "codigoSiscomex": "670"
            },
            {
                "codigoSPED": "6750",
                "descricao": "RUANDA",
                "codigoSiscomex": "675"
            },
            {
                "codigoSPED": "6769",
                "descricao": "RUSSIA",
                "codigoSiscomex": "676"
            },
            {
                "codigoSPED": "6858",
                "descricao": "SAARA OCIDENTAL",
                "codigoSiscomex": "685"
            },
            {
                "codigoSPED": "6777",
                "descricao": "SALOMAO, ILHAS",
                "codigoSiscomex": "677"
            },
            {
                "codigoSPED": "6904",
                "descricao": "SAMOA",
                "codigoSiscomex": "690"
            },
            {
                "codigoSPED": "6912",
                "descricao": "SAMOA AMERICANA",
                "codigoSiscomex": "691"
            },
            {
                "codigoSPED": "6971",
                "descricao": "SAN MARINO",
                "codigoSiscomex": "697"
            },
            {
                "codigoSPED": "7102",
                "descricao": "SANTA HELENA",
                "codigoSiscomex": "710"
            },
            {
                "codigoSPED": "7153",
                "descricao": "SANTA LUCIA",
                "codigoSiscomex": "715"
            },
            {
                "codigoSPED": "6955",
                "descricao": "SAO CRISTOVAO E NEVES",
                "codigoSiscomex": "695"
            },
            {
                "codigoSPED": "6980",
                "descricao": "SAO MARTINHO, ILHA DE (PARTE FRANCESA)",
                "codigoSiscomex": "698"
            },
            {
                "codigoSPED": "6998",
                "descricao": "SAO MARTINHO, ILHA DE (PARTE HOLANDESA)",
                "codigoSiscomex": "699"
            },
            {
                "codigoSPED": "7005",
                "descricao": "SAO PEDRO E MIQUELON",
                "codigoSiscomex": "700"
            },
            {
                "codigoSPED": "7200",
                "descricao": "SAO TOME E PRINCIPE",
                "codigoSiscomex": "720"
            },
            {
                "codigoSPED": "7056",
                "descricao": "SAO VICENTE E GRANADINAS",
                "codigoSiscomex": "705"
            },
            {
                "codigoSPED": "7315",
                "descricao": "SEICHELES",
                "codigoSiscomex": "731"
            },
            {
                "codigoSPED": "7285",
                "descricao": "SENEGAL",
                "codigoSiscomex": "728"
            },
            {
                "codigoSPED": "7358",
                "descricao": "SERRA LEOA",
                "codigoSiscomex": "735"
            },
            {
                "codigoSPED": "7370",
                "descricao": "SERVIA",
                "codigoSiscomex": "737"
            },
            {
                "codigoSPED": "7447",
                "descricao": "SIRIA",
                "codigoSiscomex": "744"
            },
            {
                "codigoSPED": "7480",
                "descricao": "SOMALIA",
                "codigoSiscomex": "748"
            },
            {
                "codigoSPED": "7501",
                "descricao": "SRI LANKA",
                "codigoSiscomex": "750"
            },
            {
                "codigoSPED": "7595",
                "descricao": "SUDAO",
                "codigoSiscomex": "759"
            },
            {
                "codigoSPED": "7600",
                "descricao": "SUDÃO DO SUL",
                "codigoSiscomex": "760"
            },
            {
                "codigoSPED": "7641",
                "descricao": "SUECIA",
                "codigoSiscomex": "764"
            },
            {
                "codigoSPED": "7676",
                "descricao": "SUICA",
                "codigoSiscomex": "767"
            },
            {
                "codigoSPED": "7706",
                "descricao": "SURINAME",
                "codigoSiscomex": "770"
            },
            {
                "codigoSPED": "7552",
                "descricao": "SVALBARD E JAN MAYEN",
                "codigoSiscomex": "755"
            },
            {
                "codigoSPED": "7722",
                "descricao": "TADJIQUISTAO",
                "codigoSiscomex": "772"
            },
            {
                "codigoSPED": "7765",
                "descricao": "TAILANDIA",
                "codigoSiscomex": "776"
            },
            {
                "codigoSPED": "1619",
                "descricao": "TAIWAN",
                "codigoSiscomex": "161"
            },
            {
                "codigoSPED": "7803",
                "descricao": "TANZANIA",
                "codigoSiscomex": "780"
            },
            {
                "codigoSPED": "7820",
                "descricao": "TERRITORIO BRITANICO OCEANO INDICO",
                "codigoSiscomex": "782"
            },
            {
                "codigoSPED": "7951",
                "descricao": "TIMOR LESTE",
                "codigoSiscomex": "795"
            },
            {
                "codigoSPED": "8001",
                "descricao": "TOGO",
                "codigoSiscomex": "800"
            },
            {
                "codigoSPED": "8109",
                "descricao": "TONGA",
                "codigoSiscomex": "810"
            },
            {
                "codigoSPED": "8052",
                "descricao": "TOQUELAU",
                "codigoSiscomex": "805"
            },
            {
                "codigoSPED": "8150",
                "descricao": "TRINIDAD E TOBAGO",
                "codigoSiscomex": "815"
            },
            {
                "codigoSPED": "8206",
                "descricao": "TUNISIA",
                "codigoSiscomex": "820"
            },
            {
                "codigoSPED": "8230",
                "descricao": "TURCAS E CAICOS",
                "codigoSiscomex": "823"
            },
            {
                "codigoSPED": "8249",
                "descricao": "TURCOMENISTAO",
                "codigoSiscomex": "824"
            },
            {
                "codigoSPED": "8273",
                "descricao": "TURQUIA",
                "codigoSiscomex": "827"
            },
            {
                "codigoSPED": "8281",
                "descricao": "TUVALU",
                "codigoSiscomex": "828"
            },
            {
                "codigoSPED": "8311",
                "descricao": "UCRANIA",
                "codigoSiscomex": "831"
            },
            {
                "codigoSPED": "8338",
                "descricao": "UGANDA",
                "codigoSiscomex": "833"
            },
            {
                "codigoSPED": "8451",
                "descricao": "URUGUAI",
                "codigoSiscomex": "845"
            },
            {
                "codigoSPED": "8478",
                "descricao": "UZBEQUISTAO",
                "codigoSiscomex": "847"
            },
            {
                "codigoSPED": "5517",
                "descricao": "VANUATU",
                "codigoSiscomex": "551"
            },
            {
                "codigoSPED": "8486",
                "descricao": "VATICANO",
                "codigoSiscomex": "848"
            },
            {
                "codigoSPED": "8508",
                "descricao": "VENEZUELA",
                "codigoSiscomex": "850"
            },
            {
                "codigoSPED": "8583",
                "descricao": "VIETNA",
                "codigoSiscomex": "858"
            },
            {
                "codigoSPED": "8630",
                "descricao": "VIRGENS, ILHAS (BRITANICAS)",
                "codigoSiscomex": "863"
            },
            {
                "codigoSPED": "8664",
                "descricao": "VIRGENS, ILHAS (EUA)",
                "codigoSiscomex": "866"
            },
            {
                "codigoSPED": "8753",
                "descricao": "WALLIS E FUTUNA, ILHAS",
                "codigoSiscomex": "875"
            },
            {
                "codigoSPED": "8907",
                "descricao": "ZAMBIA",
                "codigoSiscomex": "890"
            },
            {
                "codigoSPED": "6653",
                "descricao": "ZIMBABUE",
                "codigoSiscomex": "665"
            }
        ];

        return paises;
    }
}