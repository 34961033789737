import { ActionTree } from "vuex";

import { getQSPPai, getVolumeTotalAtivos, calculos } from "@/components/parent/manipulacao/manipulacaoUtils";
import TiposCalculoManipulacao from "@/models/enum/tiposCalculoManipulacao";
import TiposItemManipulacao from "@/models/enum/tiposItemManipulacao";
import FormaFarmaceuticaModel from "@/models/formaFarmaceuticaModel";
import ManipulacaoOrdemItemModel from "@/models/manipulacaoOrdemItemModel";
import ManipulacaoOrdemModel from "@/models/manipulacaoOrdemModel";
import ManipulacaoOrdemItemService from "@/services/manipulacaoOrdemItemService";

import { AppState } from "../store";

import {
    ActionItemType,
    ItemTableType,
    ManipulacaoContextMutations,
    ManipulacaoContextState,
    ManipulacaoContextUUID,
    UpdateItemDesmembradoType,
    UpdateItemType,
} from "./types";

const manipulacaoOrdemItemService = new ManipulacaoOrdemItemService();

let qspTimer;
let doseTimer;

const actions: ActionTree<Map<string, ManipulacaoContextState>, AppState> = {
    ADD_NEW_CONTEXT(context, id: string) {
        context.commit(ManipulacaoContextMutations.ADD_NEW_CONTEXT, id);
    },
    RESET_MANIPULACAO(context, data: ManipulacaoContextUUID<null>) {
        context.commit(ManipulacaoContextMutations.RESET_MANIPULACAO, data);
    },
    UPDATE_MANIPULACAO(context, data: ManipulacaoContextUUID<ManipulacaoOrdemModel>) {
        context.commit(ManipulacaoContextMutations.UPDATE_MANIPULACAO, data);
    },
    async ADD_ITEM_MANIPULACAO(context, { uuid, data }: ManipulacaoContextUUID<ActionItemType>) {
        const state = context.state.get(uuid);

        if (state.uploadingEditingModel || state.isDesabilitado) {
            return;
        }

        // Remove a refencia caso tenha
        let itemAdd = { ...data.item } as ManipulacaoOrdemItemModel;

        if (itemAdd) {
            itemAdd = await calculos.valorTotal(itemAdd, data.type, state);

            data.item = { ...itemAdd } as ManipulacaoOrdemItemModel;
        }

        context.commit(ManipulacaoContextMutations.ADD_ITEM_MANIPULACAO, { uuid, data });
    },
    REMOVE_ITEM_MANIPULACAO(context, data: ManipulacaoContextUUID<ActionItemType>) {
        context.commit(ManipulacaoContextMutations.REMOVE_ITEM_MANIPULACAO, data);
    },
    REMOVE_ALL_ITENS_BY_TYPE(context, data: ManipulacaoContextUUID<ItemTableType>) {
        context.commit(ManipulacaoContextMutations.REMOVE_ALL_ITENS_BY_TYPE, data);
    },
    async UPDATE_FORMULA_BASE(context, data: ManipulacaoContextUUID<UpdateItemDesmembradoType>) {
        context.commit(ManipulacaoContextMutations.ADD_ITENS_DESMEMBRADOS, data);
    },
    UPDATE_ITENS_KIT_EMBALAGEM(context, data: ManipulacaoContextUUID<ManipulacaoOrdemItemModel[]>) {
        context.commit(ManipulacaoContextMutations.UPDATE_ITENS_KIT_EMBALAGEM, data);
    },
    async UPDATE_KIT_EMBALAGEM_ID(context, { uuid, data }: ManipulacaoContextUUID<number>) {
        const state = context.state.get(uuid);

        if (
            state.manipulacao.destino == null ||
            data == state.manipulacao.kitEmbalagemId ||
            state.uploadingEditingModel ||
            state.isDesabilitado
        ) {
            return null;
        }

        try {
            const embalagem = await state.manipulacaoOrdemService
                .definirEmbalagem(state.manipulacao, data, state.manipulacao.usaMarkupEquinos, state.isTransferencia)
                .resolveWithJSON<ManipulacaoOrdemModel>();

            context.commit(ManipulacaoContextMutations.REMOVE_EMBALAGENS_BY_KIT_ID, {
                uuid,
                data: state.manipulacao.kitEmbalagemId,
            });

            for (let index = 0; index < embalagem.itens.length; index++) {
                const item = embalagem.itens[index];
                item.tipoItem = TiposItemManipulacao.Embalagem;
                item.kitEmbalagemId = embalagem.kitEmbalagemId;

                await context.dispatch(ManipulacaoContextMutations.ADD_ITEM_MANIPULACAO, {
                    uuid,
                    data: { item, type: ItemTableType.EMBALAGEM, noAddIfExists: true },
                });
            }
            context.commit(ManipulacaoContextMutations.UPDATE_MANIPULACAO_KIT_EMBALAGEM_ID, {
                uuid,
                data: embalagem.kitEmbalagemId,
            });
        } catch {}
    },
    async CALCULAR_QSP(
        context,
        {
            uuid,
            data: { loadDadosLote = true, ignoreDebounce = false },
        }: ManipulacaoContextUUID<{ loadDadosLote: boolean; ignoreDebounce: boolean }>,
    ) {
        clearTimeout(qspTimer);

        if (ignoreDebounce) {
            await calculaQSP();
        } else {
            qspTimer = setTimeout(calculaQSP, 500);
        }

        async function calculaQSP() {
            const state = context.state.get(uuid);

            if (state.uploadingEditingModel || state.isDesabilitado) {
                return;
            }

            const volumeAtivos = await getVolumeTotalAtivos(state);
            const qspPai = getQSPPai(state.ativos);
            let volumeQSP =
                state.manipulacao.volumeTotal >= volumeAtivos ? state.manipulacao.volumeTotal - volumeAtivos : 0;

            if (qspPai) {
                const indiceConversao = await state.indicesConversoesUnidadeMedidasService.getIndiceConversao(
                    state.manipulacao.unidadeMedidaId,
                    qspPai.unidadeMedidaManipulacaoId,
                    qspPai.densidade,
                    qspPai.produtoId,
                    qspPai.produtoLoteId,
                );
                volumeQSP = indiceConversao * volumeQSP;
            }

            let itemQSP = state.ativos.find(p => p.tipoCalculo == TiposCalculoManipulacao.Qsp);
            if (itemQSP) {
                if (itemQSP.produtoId) {
                    let quantidadeEstoque = volumeQSP;
                    const indiceConversao = await state.indicesConversoesUnidadeMedidasService.getIndiceConversao(
                        itemQSP.unidadeMedidaManipulacaoId,
                        itemQSP.unidadeMedidaId,
                        itemQSP.densidade,
                        itemQSP.produtoId,
                        itemQSP.produtoLoteId,
                    );

                    quantidadeEstoque = volumeQSP * indiceConversao;

                    itemQSP.quantidadeDose = 1;
                    itemQSP.quantidade = Math.round(volumeQSP * 10000) / 10000;
                    itemQSP.quantidadePesagem = Math.round(quantidadeEstoque * 10000) / 10000;
                    itemQSP = await calculos.valorTotal(itemQSP, ItemTableType.ATIVO, state);

                    if (loadDadosLote && !itemQSP.produtoLoteId) {
                        const item = await manipulacaoOrdemItemService
                            .loadDadosProdutoLote(
                                itemQSP,
                                state.manipulacao,
                                state.manipulacao.usaMarkupEquinos,
                                state.isTransferencia,
                            )
                            .resolveWithJSON<ManipulacaoOrdemItemModel>();

                        if (state.formaFarmaceutica.desconsiderarDensidade) {
                            item.densidade = 1;
                        }

                        itemQSP.densidade = item.densidade;
                        itemQSP.fatorCorrecao = item.fatorCorrecao;
                        itemQSP.fatorUI = item.fatorUI;
                        itemQSP.fatorUFC = item.fatorUFC;
                        itemQSP.fatorUTR = item.fatorUTR;
                        itemQSP.produtoLoteCalculosId = item.produtoLoteCalculosId;
                    }
                }
            }
            context.commit(ManipulacaoContextMutations.CALCULAR_QSP, { uuid, data: state.ativos });
        }
    },
    async CALCULA_DOSE(context, data: ManipulacaoContextUUID<null>) {
        const state = context.state.get(data.uuid);

        clearTimeout(doseTimer);
        doseTimer = setTimeout(async () => {
            if (!state.concentracaoMaxima || state.uplodingPrevenda || state.uploadingEditingModel) {
                return;
            }
            let quantidadeDose = 0;
            let fitoterapico = false;
            const doses = state.ativos.filter(p => p.tipoCalculo == TiposCalculoManipulacao.Dose);
            for (let index = 0; index < doses.length; index++) {
                const element = doses[index];

                const produto = context.rootState.preLoad.preLoadList.produtos.find(x => x.id == element.produtoId);

                if (produto.fitoterapico) {
                    fitoterapico = true;
                }

                let unidadeMedidaId = element.unidadeMedidaManipulacaoId;
                // Se for ativo associado com a mesma unidade da manipulação deve fazer a conversão a partir da unidade do produto pai
                if (
                    element.unidadeMedidaManipulacaoId == state.manipulacao.unidadeMedidaId &&
                    element.produtoAssociadoPaiId
                ) {
                    const ativoPai = state.ativos.find(p => p.produtoId == element.produtoAssociadoPaiId);
                    unidadeMedidaId = ativoPai.unidadeMedidaManipulacaoId;
                }

                const indice = await state.indicesConversoesUnidadeMedidasService.getIndiceConversao(
                    unidadeMedidaId,
                    state.manipulacao.unidadeMedidaId,
                    element.densidade,
                    element.produtoId,
                    element.produtoLoteId,
                );

                const fatorCorrecao = element.fatorCorrecao ? element.fatorCorrecao : 1;
                quantidadeDose += indice * element.quantidadeDose * fatorCorrecao;
            }

            let volumeDose = 0;
            if (fitoterapico && state.formaFarmaceutica.concentracaoMaximaFitoterapico) {
                volumeDose = Math.ceil(quantidadeDose / state.formaFarmaceutica.concentracaoMaximaFitoterapico);
            } else {
                volumeDose = Math.ceil(quantidadeDose / state.concentracaoMaxima);
            }

            if (volumeDose > 0) {
                context.commit(ManipulacaoContextMutations.REPLICA_DOSE_ATIVOS, { uuid: data.uuid, data: volumeDose });
            }
        }, 200);
    },
    REPLICA_DOSE_ATIVOS(context, data: ManipulacaoContextUUID<number>) {
        context.commit(ManipulacaoContextMutations.REPLICA_DOSE_ATIVOS, data);
    },
    SET_FORMA_FARMACEUTICA(context, data: ManipulacaoContextUUID<FormaFarmaceuticaModel>) {
        context.commit(ManipulacaoContextMutations.SET_FORMA_FARMACEUTICA, data);
    },
    SET_UPLOADING_EDITING_MODEL(context, data: ManipulacaoContextUUID<boolean>) {
        context.commit(ManipulacaoContextMutations.SET_UPLOADING_EDITING_MODEL, data);
    },
    SET_UPLOADING_PREVENDA(context, data: ManipulacaoContextUUID<boolean>) {
        context.commit(ManipulacaoContextMutations.SET_UPLOADING_PREVENDA, data);
    },
    SET_IS_CAPSULA(context, data: ManipulacaoContextUUID<boolean>) {
        context.commit(ManipulacaoContextMutations.SET_IS_CAPSULA, data);
    },
    SET_IS_CONSOLIDANDO(context, data: ManipulacaoContextUUID<boolean>) {
        context.commit(ManipulacaoContextMutations.SET_IS_CONSOLIDANDO, data);
    },
    SET_IS_DESABILITADO(context, data: ManipulacaoContextUUID<boolean>) {
        context.commit(ManipulacaoContextMutations.SET_IS_DESABILITADO, data);
    },
    SET_IS_ITEM_FROM_VENDA(context, data: ManipulacaoContextUUID<boolean>) {
        context.commit(ManipulacaoContextMutations.SET_IS_ITEM_FROM_VENDA, data);
    },
    SET_IS_TRANSFERENCIA(context, data: ManipulacaoContextUUID<boolean>) {
        context.commit(ManipulacaoContextMutations.SET_IS_TRANSFERENCIA, data);
    },
    SET_IS_VENDA_REPLICADA(context, data: ManipulacaoContextUUID<boolean>) {
        context.commit(ManipulacaoContextMutations.SET_IS_VENDA_REPLICADA, data);
    },
    SET_CONCENTRACAO_MAXIMA(context, data: ManipulacaoContextUUID<number>) {
        context.commit(ManipulacaoContextMutations.SET_CONCENTRACAO_MAXIMA, data);
    },
    REMOVE_ATIVOS_ASSOCIADOS(context, data: ManipulacaoContextUUID<null>) {
        context.commit(ManipulacaoContextMutations.REMOVE_ATIVOS_ASSOCIADOS, data);
    },
    REMOVE_ATIVOS_ASSOCIADOS_FORMA_FARMACEUTICA(context, data: ManipulacaoContextUUID<null>) {
        context.commit(ManipulacaoContextMutations.REMOVE_ATIVOS_ASSOCIADOS_FORMA_FARMACEUTICA, data);
    },
    async CALCULAR_QUANTIDADES_ITEM(context, { uuid, data }: ManipulacaoContextUUID<UpdateItemType>) {
        const state = context.state.get(uuid);
        let item: ManipulacaoOrdemItemModel = null;

        switch (data.type) {
            case ItemTableType.ATIVO:
                item = state.ativos[data.index];
                break;
            case ItemTableType.EMBALAGEM:
                item = state.embalagens[data.index];
                break;
            case ItemTableType.CAPSULA:
                item = state.capsulas[data.index];
                break;
        }

        // Se for produto associado não deve recalcular os valores pois irá calcular ao inserir novamente os ativos na consolidação
        if (
            !data.force &&
            (state.uploadingEditingModel || state.isDesabilitado || !item || item.produtoAssociadoPaiId)
        ) {
            return;
        }

        let quantidade = 0;
        let indiceConversao = 1;
        const converter = item.tipoCalculo != TiposCalculoManipulacao.UTRPercentual;

        if (converter) {
            indiceConversao = await state.indicesConversoesUnidadeMedidasService.getIndiceConversao(
                item.unidadeMedidaManipulacaoId,
                item.unidadeMedidaId,
                item.densidade,
                item.produtoId,
                item.produtoLoteId,
            );
        }

        switch (item.tipoCalculo) {
            case TiposCalculoManipulacao.Unitario:
                quantidade = calculos.tipoCalculo.unitario(item, state);
                break;
            case TiposCalculoManipulacao.Percentual:
                quantidade = calculos.tipoCalculo.percentual(item, state.manipulacao.volumeTotal, state);
                break;
            case TiposCalculoManipulacao.UTRPercentual:
                quantidade = calculos.tipoCalculo.percentualUTR(item);
                break;
            case TiposCalculoManipulacao.Qsp:
                quantidade = item.quantidade;
                break;
            case TiposCalculoManipulacao.Dose:
                quantidade = calculos.tipoCalculo.dose(item, state);
                break;
            default:
                quantidade = calculos.tipoCalculo.nenhum(item);
        }

        // Se é QSP o número de formulas será considera no calculo do volume
        if (item.tipoCalculo != TiposCalculoManipulacao.Qsp) {
            quantidade = quantidade * (Number(state.manipulacao.numeroFormulas) ?? 1);
        }
        item.quantidade = Math.round(quantidade * 10000) / 10000;

        const quantidadeUnidadeEstoque = quantidade * indiceConversao;
        item.quantidadePesagem = Math.round(quantidadeUnidadeEstoque * 10000) / 10000;

        // Cenário em que a quantidade ficou menor que 4 decimais
        if (item.quantidadePesagem == 0) {
            item.quantidadePesagem = Math.round(quantidadeUnidadeEstoque * 10000000) / 10000000;
        }

        item = await calculos.valorTotal(item, data.type, state);

        context.commit(ManipulacaoContextMutations.SET_ITEM_DATA, {
            uuid,
            data: { index: data.index, type: data.type, item },
        });
    },
    UPDATE_MANIPULACAO_ITENS(
        context,
        { uuid, data }: ManipulacaoContextUUID<{ itens: ManipulacaoOrdemItemModel[]; atualizaDadosLote: boolean }>,
    ) {
        const state = context.state.get(uuid);
        state.atualizaDadosLote = data.atualizaDadosLote;

        state.ativos.forEach((item, index) => {
            const dado = data.itens.find(p => p.produtoId == item.produtoId);
            if (dado) {
                context.commit(ManipulacaoContextMutations.SET_ITEM_DATA, {
                    uuid,
                    data: { index, type: ItemTableType.ATIVO, item: dado },
                });
            }
        });
        state.embalagens.forEach((item, index) => {
            const dado = data.itens.find(p => p.produtoId == item.produtoId);
            if (dado) {
                context.commit(ManipulacaoContextMutations.SET_ITEM_DATA, {
                    uuid,
                    data: { index, type: ItemTableType.EMBALAGEM, item: dado },
                });
            }
        });
        state.capsulas.forEach((item, index) => {
            const dado = data.itens.find(p => p.produtoId == item.produtoId);
            if (dado) {
                context.commit(ManipulacaoContextMutations.SET_ITEM_DATA, {
                    uuid,
                    data: { index, type: ItemTableType.CAPSULA, item: dado },
                });
            }
        });
    },
};

export default actions;
