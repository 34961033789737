var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "crud crud-list ajusteAcoesPesquisar" },
    [
      !_vm.hideExtras
        ? _c("div", { staticClass: "info-div" }, [
            _c("h1", { staticClass: "title" }, [
              _vm._v(
                _vm._s(
                  _vm.$t("__.Crud.contareceber.list_vue_html.cntReceberAberto")
                )
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "content" }, [
              _c("div", { staticClass: "info-panel" }, [
                _c("span", [_vm._v(_vm._s(_vm.total))]),
                _vm._v(" "),
                _c("span", [
                  _vm._v(
                    _vm._s(
                      _vm.$t("__.Crud.contareceber.list_vue_html.cntReceber")
                    )
                  ),
                ]),
                _vm._v(" "),
                _c("span", [
                  _vm._v(
                    _vm._s(
                      _vm.$t("__.Crud.contareceber.list_vue_html.cadastradas")
                    )
                  ),
                ]),
              ]),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "grid-component",
        {
          ref: "gridcomponent",
          attrs: {
            data: _vm.gridData,
            tituloPagina: _vm.$t(
              "__.Crud.contareceber.list_vue_html.cntReceberAberto"
            ),
            columns: _vm.gridColumns,
            "show-select-checkbox": !_vm.hideExtras,
            subColumns: _vm.gridColumnsVinculos,
            subDataName: _vm.subDataName,
            showExpand: true,
            conditionShowSubData: _vm.conditionShowSubData,
            "page-index": _vm.pageIndex,
            "page-size": _vm.pageSize,
            total: _vm.total,
            showEditItem: !_vm.hideExtras,
            showActionColumn: !_vm.hideExtras,
            showAddNewButton: !_vm.hideExtras,
            showTableFoot: !_vm.hideExtras,
            showTools: !_vm.hideExtras,
            startFilterKey: _vm.startFilterKey,
            showTableHead: !_vm.hideExtras,
          },
          on: {
            "gerar-xls": function ($event) {
              _vm.pags = true
              _vm.tipoDocGerar = 0
            },
            "gerar-pdf": function ($event) {
              _vm.pags = true
              _vm.tipoDocGerar = 1
            },
            "gerar-impressao": function ($event) {
              _vm.pags = true
              _vm.tipoDocGerar = 2
            },
            "change-filter-key": _vm.onChangeFilterKey,
            "change-sort": _vm.onChangeSort,
            "edit-item": _vm.onEditItem,
            "remove-item": _vm.onRemoveItem,
            "create-item": _vm.onCreateItem,
            "change-page": _vm.onChangePage,
            "selected-values-changed": _vm.onSelectedValuesChanged,
          },
        },
        [
          _c("div", { attrs: { slot: "header" }, slot: "header" }, [
            _c("div", { staticClass: "filtro-lista" }, [
              _c(
                "button",
                {
                  staticClass: "filtro-avancado-button",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      _vm.isHidden = !_vm.isHidden
                    },
                  },
                },
                [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.$t("__.Crud.filtroAvancado")) +
                      "\n                    "
                  ),
                  _c("i", { staticClass: "fa fa-caret-down" }),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isHidden,
                      expression: "isHidden",
                    },
                  ],
                  staticClass: "drop-lista",
                },
                [
                  _c(
                    "form",
                    {
                      attrs: { novalidate: "novalidate" },
                      on: {
                        submit: function ($event) {
                          $event.preventDefault()
                          return _vm.getFormValues($event)
                        },
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "radio-group",
                          staticStyle: { "margin-bottom": "15px" },
                        },
                        [
                          _c("b", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "__.Crud.contareceber.list_vue_html.cntGerencial"
                                )
                              )
                            ),
                          ]),
                          _vm._v(" "),
                          _c("combo-component", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required",
                                expression: "'required'",
                              },
                            ],
                            staticStyle: { background: "white" },
                            attrs: {
                              id: "contasId",
                              name: "contasId",
                              data: _vm.contaGerencialOptions,
                              searchable: true,
                              refresh: _vm.loadContasGerencial,
                            },
                            model: {
                              value: _vm.contasFiltro,
                              callback: function ($$v) {
                                _vm.contasFiltro = $$v
                              },
                              expression: "contasFiltro",
                            },
                          }),
                          _vm._v(" "),
                          _c("b", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "__.Crud.contareceber.list_vue_html.vncDe"
                                )
                              )
                            ),
                          ]),
                          _vm._v(" "),
                          _c("date-time-picker-component", {
                            staticStyle: { width: "100%" },
                            attrs: { id: "dataFilter1", name: "dataFilter1" },
                            model: {
                              value: _vm.dataFilter1,
                              callback: function ($$v) {
                                _vm.dataFilter1 = $$v
                              },
                              expression: "dataFilter1",
                            },
                          }),
                          _vm._v(" "),
                          _c("b", [
                            _vm._v(
                              _vm._s(
                                _vm.$t("__.Crud.contareceber.list_vue_html.ate")
                              )
                            ),
                          ]),
                          _vm._v(" "),
                          _c("date-time-picker-component", {
                            attrs: { id: "dataFilter2", name: "dataFilter2" },
                            model: {
                              value: _vm.dataFilter2,
                              callback: function ($$v) {
                                _vm.dataFilter2 = $$v
                              },
                              expression: "dataFilter2",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "form-group" }, [
                        _c(
                          "button",
                          {
                            staticClass: "botao-filtrar",
                            attrs: { type: "submit" },
                          },
                          [
                            _vm._v(
                              "\n                                " +
                                _vm._s(_vm.$t("__.Crud.filtrar")) +
                                "\n                            "
                            ),
                          ]
                        ),
                      ]),
                    ]
                  ),
                ]
              ),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.gridSelectedValues.length > 0,
                  expression: "gridSelectedValues.length > 0",
                },
              ],
              staticClass: "div-receber-selecionados",
              attrs: { slot: "footer-extra-options" },
              slot: "footer-extra-options",
            },
            [
              _c("button", {
                staticClass: "btn btn-primary btn-receber",
                on: { click: _vm.receberSelecionados },
              }),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }