import { GridColumn, GridColumnType } from "@/components/child/grid/gridColumn";
import { Component } from "@/decorators";
import CondicaoModel from "@/models/paciente/condicaoModel";
import CondicaoService from "@/services/paciente/condicaoService";

import ListComponentBase from "../listComponentBase";

@Component
export default class CondicaoListComponent extends ListComponentBase<CondicaoModel> {
    get gridColumns(): Array<GridColumn> {
        return [new GridColumn("descricao", this.$t("__.ts.descricao"), GridColumnType.String)];
    }

    private beforeMount() {
        this.setProps(new CondicaoService(), "condicao", "id");
        this.gridSortOrder = "desc";
    }
}
