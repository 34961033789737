import BaseModel from "../base/baseModel";
import GenerosPessoa from "../enum/generosPessoa";

import PacienteAlergiaModel from "./pacienteAlergiaModel";
import PacienteCondicaoModel from "./pacienteCondicaoModel";

export default class PacienteModel extends BaseModel {
    id?: number = null;
    dataCriacao: Date = null;
    nome: string = null;
    nomeRotulo: string = null;
    observacao: string = null;
    dataNascimento: Date = null;
    genero?: GenerosPessoa = null;
    generoDescricao: string = null;
    peso?: number = null;
    ativo = true;
    especieAnimalId = 0;
    especieAnimalDescricao: string = null;
    clienteId = 0;
    clienteDescricao: string = null;
    racaAnimalId: number = null;
    racaAnimalDescricao: string = null;
    pacienteCliente = false;
    pacienteAlergias?: Array<PacienteAlergiaModel> = [];
    pacienteCondicoes: PacienteCondicaoModel[] = [];
}
