import Vue from "vue";

declare module "vue/types/vue" {
    interface Vue {
        $getInteger: (refName: string) => void;
    }
}

Vue.prototype.$getInteger = (event: any) => {
    const keyCode = event.keyCode ? event.keyCode : event.which;
    if (keyCode < 48 || keyCode > 57) {
        event.preventDefault();
    }
};
