import Vue from "vue";

import { Component, Prop } from "@/decorators";

@Component
export default class CnpjComponent extends Vue {
    @Prop(String) id: string;
    @Prop(String) name: string;
    @Prop(String) value: string;

    private onBlur(value) {
        this.$emit("input", this.formatValueToModel(value));
    }

    private formatValueToModel(value) {
        return value.replace(/[^\d]/g, "");
    }
}
