import arithmeticHelper from "../utils/common/arithmeticHelper";

import BaseModel from "./base/baseModel";
import StatusFracionamento from "./enum/statusFracionamento";
import FracionamentoItemModel from "./fracionamentoItemModel";
import ItemEmbalagemFracionamentoModel from "./itemEmbalagemFracionamentoModel";
import UnidadeMedidaModel from "./unidadeMedidaModel";

export default class FracionamentoModel extends BaseModel {
    id?: number = null;
    observacao: string = null;
    volume?: number = 0;
    volumeTotal = 0;
    unidadeMedidaId?: number = 0;
    unidadeMedida?: UnidadeMedidaModel = new UnidadeMedidaModel();
    quantidade?: number = 0;
    dataValidade: Date = null;
    dataConclusao: Date = null;
    dataEntrega: Date = null;
    valorProdutos = 0;
    valorTotal = 0;
    acrescimoPercentual = 0;
    acrescimo = 0;
    descontoPercentual = 0;
    desconto = 0;
    movimentacaoEstoqueId?: number = null;
    itens: Array<FracionamentoItemModel> = [];
    kitEmbalagemId?: number = null;
    itensEmbalagem: Array<ItemEmbalagemFracionamentoModel> = [];
    statusFracionamento?: StatusFracionamento = null;
    markupItemVenda = 0;

    // Tipo da Nota Fiscal que deve ser emitida para este item
    tipoNF?: number = null;
    movimentaEstoque?: boolean = true;
    separado?: boolean = false;
    considerarEmbalagem?: boolean = false;
    valorEmbalagem?: number = 0;

    calcularValorUnitario(itemFracionamento: FracionamentoItemModel, valorEmbalagem = 0, isTransferencia = false) {
        let valorVenda = 0;

        if (itemFracionamento.produto != null) {
            let vendaProduto = 0;

            const valorProduto = isTransferencia
                ? itemFracionamento.produto.valorCusto
                : itemFracionamento.produto.valorVenda;

            if (itemFracionamento.produto.custoReferencia == null) {
                vendaProduto = arithmeticHelper.round(valorProduto, 4);
            } else {
                vendaProduto = this.aplicaMarkup(itemFracionamento.produto.custoReferencia);
            }

            if (
                itemFracionamento.produto.custoReferencia == null &&
                this.markupItemVenda == itemFracionamento.produto.markup
            ) {
                this.markupItemVenda = 0;
            } else {
                vendaProduto = this.aplicaMarkup(valorProduto);
            }

            const vendaLote = this.aplicaMarkup(itemFracionamento.produtoLote.valorCusto);

            if (
                itemFracionamento.produtoLoteId == 0 ||
                itemFracionamento.produtoLoteId == null ||
                (vendaLote == 0 && vendaProduto > 0)
            ) {
                valorVenda = arithmeticHelper.round(vendaProduto, 4);
            } else {
                valorVenda = arithmeticHelper.round(vendaLote, 4);
            }
        }
        return arithmeticHelper.round(valorVenda + valorEmbalagem, 4);
    }

    aplicaMarkup(value: number) {
        return arithmeticHelper.round((value * this.markupItemVenda) / 100 + value, 4);
    }

    calcularValorTotal(itemFracionamento: FracionamentoItemModel) {
        if (itemFracionamento.quantidade && itemFracionamento.valor) {
            let valorUnitario = 0;

            valorUnitario = this.calcularValorUnitario(
                itemFracionamento,
                this.considerarEmbalagem ? this.valorEmbalagem / Number(itemFracionamento.quantidade) : 0,
            );

            this.valorTotal = Number(itemFracionamento.quantidade) * valorUnitario;
            itemFracionamento.valor = valorUnitario;

            if (this.desconto) {
                this.valorTotal -= this.desconto;
            }

            if (this.acrescimo) {
                this.valorTotal += this.acrescimo;
            }

            itemFracionamento.valorTotal = Number(this.valorTotal.toFixed(4));
        }

        return itemFracionamento;
    }
}
