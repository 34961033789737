var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "crud crud-edit" },
    [
      !_vm.hideActionBar
        ? _c(
            "actionBarComponent",
            {
              attrs: { slots: 1, title: "Clínica - Edição" },
              on: { onSave: _vm.save },
            },
            [
              _c(
                "div",
                {
                  attrs: {
                    slot: "buttonSlot0",
                    title: _vm.$t("__.Crud.imprimir"),
                  },
                  on: { click: _vm.print },
                  slot: "buttonSlot0",
                },
                [
                  _c("i", {
                    staticClass: "fa fa-print",
                    attrs: { "aria-hidden": "true" },
                  }),
                ]
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "form",
        {
          attrs: { novalidate: "novalidate" },
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.save($event)
            },
          },
        },
        [
          _c(
            "fieldset-component",
            { attrs: { title: "Dados Gerais", collapsed: false } },
            [
              _c(
                "div",
                { attrs: { slot: "rows", id: "clinica" }, slot: "rows" },
                [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-sm-4" }, [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: _vm.errors.first("cnpj"),
                              expression: "errors.first('cnpj')",
                            },
                          ],
                          staticClass: "form-group",
                        },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "control-label",
                              attrs: {
                                for: "cnpj",
                                title: "* este campo é obrigatório",
                              },
                            },
                            [
                              _c("span", { staticStyle: { color: "red" } }, [
                                _vm._v("*"),
                              ]),
                              _vm._v(
                                "\n                                CNPJ\n                            "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("cnpj-component", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: {
                                  rules: {
                                    cnpj: true,
                                    required:
                                      _vm.model.tipoPessoa ==
                                      _vm.tiposPessoa.Juridica,
                                  },
                                },
                                expression:
                                  "{\n                                    rules: { cnpj: true, required: model.tipoPessoa == tiposPessoa.Juridica },\n                                }",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: { id: "cnpj", name: "cnpj" },
                            model: {
                              value: _vm.model.cnpj,
                              callback: function ($$v) {
                                _vm.$set(_vm.model, "cnpj", $$v)
                              },
                              expression: "model.cnpj",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-sm-4" }, [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: _vm.errors.first("inscricaoEstadual"),
                              expression: "errors.first('inscricaoEstadual')",
                            },
                          ],
                          staticClass: "form-group",
                        },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "control-label",
                              attrs: { for: "inscricaoEstadual" },
                            },
                            [_vm._v("Insc. Estadual")]
                          ),
                          _vm._v(" "),
                          _c("data-tooltip-component", {
                            attrs: {
                              text: "Para clientes isentos deixe em branco",
                            },
                          }),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.model.inscricaoEstadual,
                                expression: "model.inscricaoEstadual",
                              },
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "inscricaoEstadual|max:20",
                                expression: "'inscricaoEstadual|max:20'",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: {
                              autocomplete: "off",
                              type: "text",
                              id: "inscricaoEstadual",
                              name: "inscricaoEstadual",
                              placeholder: "___-_______",
                            },
                            domProps: { value: _vm.model.inscricaoEstadual },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.model,
                                  "inscricaoEstadual",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-sm-4" }, [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: _vm.errors.first("inscricaoMunicipal"),
                              expression: "errors.first('inscricaoMunicipal')",
                            },
                          ],
                          staticClass: "form-group",
                        },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "control-label",
                              attrs: { for: "inscricaoMunicipal" },
                            },
                            [_vm._v("Insc. Municipal")]
                          ),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.model.inscricaoMunicipal,
                                expression: "model.inscricaoMunicipal",
                              },
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "max:20",
                                expression: "'max:20'",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: {
                              autocomplete: "off",
                              type: "text",
                              id: "inscricaoMunicipal",
                              name: "inscricaoMunicipal",
                              placeholder: "__________",
                            },
                            domProps: { value: _vm.model.inscricaoMunicipal },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.model,
                                  "inscricaoMunicipal",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-sm-12" }, [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: _vm.errors.first("nomeFantasia"),
                              expression: "errors.first('nomeFantasia')",
                            },
                          ],
                          staticClass: "form-group",
                        },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "control-label",
                              attrs: {
                                for: "nomeFantasia",
                                title: "* este campo é obrigatório",
                              },
                            },
                            [
                              _c("span", { staticStyle: { color: "red" } }, [
                                _vm._v("*"),
                              ]),
                              _vm._v(
                                "\n                                Nome fantasia\n                            "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.model.nomeFantasia,
                                expression: "model.nomeFantasia",
                              },
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: {
                                  rules: {
                                    max: 256,
                                    required:
                                      _vm.model.tipoPessoa ==
                                      _vm.tiposPessoa.Juridica,
                                  },
                                },
                                expression:
                                  "{\n                                    rules: { max: 256, required: model.tipoPessoa == tiposPessoa.Juridica },\n                                }",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: {
                              autocomplete: "off",
                              type: "text",
                              id: "nomeFantasia",
                              name: "nomeFantasia",
                            },
                            domProps: { value: _vm.model.nomeFantasia },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.model,
                                  "nomeFantasia",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-sm-12" }, [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: _vm.errors.first("razaoSocial"),
                              expression: "errors.first('razaoSocial')",
                            },
                          ],
                          staticClass: "form-group",
                        },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "control-label",
                              attrs: {
                                for: "razaoSocial",
                                title: "* este campo é obrigatório",
                              },
                            },
                            [
                              _c("span", { staticStyle: { color: "red" } }, [
                                _vm._v("*"),
                              ]),
                              _vm._v(
                                "\n                                Razão Social\n                            "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.model.razaoSocial,
                                expression: "model.razaoSocial",
                              },
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: {
                                  rules: {
                                    max: 256,
                                    required:
                                      _vm.model.tipoPessoa ==
                                      _vm.tiposPessoa.Juridica,
                                  },
                                },
                                expression:
                                  "{\n                                    rules: { max: 256, required: model.tipoPessoa == tiposPessoa.Juridica },\n                                }",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: {
                              autocomplete: "off",
                              type: "text",
                              id: "razaoSocial",
                              name: "razaoSocial",
                            },
                            domProps: { value: _vm.model.razaoSocial },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.model,
                                  "razaoSocial",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.model.tipoPessoa == _vm.tiposPessoa.Fisica,
                          expression: "model.tipoPessoa == tiposPessoa.Fisica",
                        },
                      ],
                      staticClass: "row",
                    },
                    [
                      _c("div", { staticClass: "col-sm-3" }, [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: _vm.errors.first("dataNascimento"),
                                expression: "errors.first('dataNascimento')",
                              },
                            ],
                            staticClass: "form-group",
                          },
                          [
                            _c(
                              "label",
                              {
                                staticClass: "control-label",
                                attrs: { for: "dataNascimento" },
                              },
                              [_vm._v("Data Nascimento")]
                            ),
                            _vm._v(" "),
                            _c("date-time-picker-component", {
                              attrs: {
                                id: "dataNascimento",
                                name: "dataNascimento",
                              },
                              model: {
                                value: _vm.model.dataNascimento,
                                callback: function ($$v) {
                                  _vm.$set(_vm.model, "dataNascimento", $$v)
                                },
                                expression: "model.dataNascimento",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-sm-2" }, [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: _vm.errors.first("genero"),
                                expression: "errors.first('genero')",
                              },
                            ],
                            staticClass: "form-group",
                          },
                          [
                            _c(
                              "label",
                              {
                                staticClass: "control-label",
                                attrs: { for: "genero" },
                              },
                              [_vm._v("Gênero")]
                            ),
                            _vm._v(" "),
                            _c("combo-component", {
                              attrs: {
                                id: "genero",
                                name: "genero",
                                data: _vm.generosPessoaOptions,
                                searchable: false,
                              },
                              model: {
                                value: _vm.model.genero,
                                callback: function ($$v) {
                                  _vm.$set(_vm.model, "genero", $$v)
                                },
                                expression: "model.genero",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-sm-3" }, [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: _vm.errors.first("orgaoExpedidor"),
                                expression: "errors.first('orgaoExpedidor')",
                              },
                            ],
                            staticClass: "form-group",
                          },
                          [
                            _c(
                              "label",
                              {
                                staticClass: "control-label",
                                attrs: { for: "orgaoExpedidor" },
                              },
                              [_vm._v("Orgão Expedidor")]
                            ),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.model.orgaoExpedidor,
                                  expression: "model.orgaoExpedidor",
                                },
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "alpha_spaces|max:256",
                                  expression: "'alpha_spaces|max:256'",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: {
                                autocomplete: "off",
                                type: "text",
                                id: "orgaoExpedidor",
                                name: "orgaoExpedidor",
                              },
                              domProps: { value: _vm.model.orgaoExpedidor },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.model,
                                    "orgaoExpedidor",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-sm-2" }, [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: _vm.errors.first("ufExpedidor"),
                                expression: "errors.first('ufExpedidor')",
                              },
                            ],
                            staticClass: "form-group",
                          },
                          [
                            _c(
                              "label",
                              {
                                staticClass: "control-label",
                                attrs: { for: "ufExpedidor" },
                              },
                              [_vm._v("UF Expedidor")]
                            ),
                            _vm._v(" "),
                            _c("combo-component", {
                              attrs: {
                                id: "ufExpedidor",
                                name: "ufExpedidor",
                                data: _vm.ufOptions,
                                searchable: true,
                              },
                              model: {
                                value: _vm.model.ufExpedidor,
                                callback: function ($$v) {
                                  _vm.$set(_vm.model, "ufExpedidor", $$v)
                                },
                                expression: "model.ufExpedidor",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-sm-2" }, [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: _vm.errors.first("comissao"),
                                expression: "errors.first('comissao')",
                              },
                            ],
                            staticClass: "form-group",
                          },
                          [
                            _c(
                              "label",
                              {
                                staticClass: "control-label",
                                attrs: { for: "comissao" },
                              },
                              [_vm._v("Comissão")]
                            ),
                            _vm._v(" "),
                            _c("decimal-component", {
                              staticClass: "form-control",
                              attrs: { id: "comissao", name: "comissao" },
                              model: {
                                value: _vm.model.comissao,
                                callback: function ($$v) {
                                  _vm.$set(_vm.model, "comissao", $$v)
                                },
                                expression: "model.comissao",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-sm-10" }, [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: _vm.errors.first("observacao"),
                              expression: "errors.first('observacao')",
                            },
                          ],
                          staticClass: "form-group",
                        },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "control-label",
                              attrs: { for: "observacao" },
                            },
                            [_vm._v("Observação")]
                          ),
                          _vm._v(" "),
                          _c("textarea-component", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "max:256",
                                expression: "'max:256'",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: { id: "observacao", name: "observacao" },
                            model: {
                              value: _vm.model.observacao,
                              callback: function ($$v) {
                                _vm.$set(_vm.model, "observacao", $$v)
                              },
                              expression: "model.observacao",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-sm-2" }, [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: _vm.errors.first("pontuarDotz"),
                              expression: "errors.first('pontuarDotz')",
                            },
                          ],
                          staticClass: "form-group",
                        },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "control-label",
                              attrs: { for: "pontuarDotz" },
                            },
                            [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(
                                    _vm.$t(
                                      "__.Crud.clinicas.edit_vue_html.pontuarDotz"
                                    )
                                  ) +
                                  "\n                            "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("checkbox-component", {
                            attrs: { id: "pontuarDotz", name: "pontuarDotz" },
                            model: {
                              value: _vm.model.pontuarDotz,
                              callback: function ($$v) {
                                _vm.$set(_vm.model, "pontuarDotz", $$v)
                              },
                              expression: "model.pontuarDotz",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _c("fieldset-component", { attrs: { title: "Localização" } }, [
            _c(
              "div",
              { attrs: { slot: "rows", id: "localizacao" }, slot: "rows" },
              [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-sm-4" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("cep"),
                            expression: "errors.first('cep')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "cep" },
                          },
                          [_vm._v("CEP")]
                        ),
                        _vm._v(" "),
                        _c("cep-field-component", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "cep",
                              expression: "'cep'",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: { id: "cep", name: "cep" },
                          on: {
                            "cep-changed": _vm.onCepChanged,
                            "cep-invalid": _vm.onCepInvalid,
                          },
                          model: {
                            value: _vm.model.cep,
                            callback: function ($$v) {
                              _vm.$set(_vm.model, "cep", $$v)
                            },
                            expression: "model.cep",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-3" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("estadoId"),
                            expression: "errors.first('estadoId')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "estadoId" },
                          },
                          [_vm._v("Estado")]
                        ),
                        _vm._v(" "),
                        _c("combo-component", {
                          attrs: {
                            id: "estadoId",
                            name: "estadoId",
                            data: _vm.ufOptions,
                            searchable: true,
                          },
                          on: { input: _vm.onLoadCidades },
                          model: {
                            value: _vm.model.estadoId,
                            callback: function ($$v) {
                              _vm.$set(_vm.model, "estadoId", $$v)
                            },
                            expression: "model.estadoId",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-5" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("cidadeId"),
                            expression: "errors.first('cidadeId')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "cidadeId" },
                          },
                          [_vm._v("Cidade")]
                        ),
                        _vm._v(" "),
                        _c("combo-component", {
                          attrs: {
                            id: "cidadeId",
                            name: "cidadeId",
                            data: _vm.cidadesOptions,
                            searchable: true,
                          },
                          model: {
                            value: _vm.model.cidadeId,
                            callback: function ($$v) {
                              _vm.$set(_vm.model, "cidadeId", $$v)
                            },
                            expression: "model.cidadeId",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-sm-4" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("endereco"),
                            expression: "errors.first('endereco')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "endereco" },
                          },
                          [_vm._v("Endereço")]
                        ),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.model.endereco,
                              expression: "model.endereco",
                            },
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "max:256",
                              expression: "'max:256'",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            autocomplete: "off",
                            type: "text",
                            id: "endereco",
                            name: "endereco",
                          },
                          domProps: { value: _vm.model.endereco },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.model,
                                "endereco",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-4" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("numero"),
                            expression: "errors.first('numero')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "numero" },
                          },
                          [_vm._v("Número")]
                        ),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.model.numero,
                              expression: "model.numero",
                            },
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "max:256",
                              expression: "'max:256'",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            autocomplete: "off",
                            type: "text",
                            id: "numero",
                            name: "numero",
                          },
                          domProps: { value: _vm.model.numero },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(_vm.model, "numero", $event.target.value)
                            },
                          },
                        }),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-4" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("bairro"),
                            expression: "errors.first('bairro')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "bairro" },
                          },
                          [_vm._v("Bairro")]
                        ),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.model.bairro,
                              expression: "model.bairro",
                            },
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "max:256",
                              expression: "'max:256'",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            autocomplete: "off",
                            type: "text",
                            id: "bairro",
                            name: "bairro",
                          },
                          domProps: { value: _vm.model.bairro },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(_vm.model, "bairro", $event.target.value)
                            },
                          },
                        }),
                      ]
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-sm-12" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("complemento"),
                            expression: "errors.first('complemento')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "complemento" },
                          },
                          [_vm._v("Complemento")]
                        ),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.model.complemento,
                              expression: "model.complemento",
                            },
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "max:256",
                              expression: "'max:256'",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            autocomplete: "off",
                            type: "text",
                            id: "complemento",
                            name: "complemento",
                          },
                          domProps: { value: _vm.model.complemento },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.model,
                                "complemento",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]
                    ),
                  ]),
                ]),
              ]
            ),
          ]),
          _vm._v(" "),
          _c("fieldset-component", { attrs: { title: "Contato" } }, [
            _c(
              "div",
              { attrs: { slot: "rows", id: "contato" }, slot: "rows" },
              [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-sm-3" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("telefone"),
                            expression: "errors.first('telefone')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "telefone" },
                          },
                          [_vm._v("Telefone")]
                        ),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "mask-telefone",
                              rawName: "v-mask-telefone",
                            },
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.model.telefone,
                              expression: "model.telefone",
                            },
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "telefone",
                              expression: "'telefone'",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            autocomplete: "off",
                            type: "text",
                            id: "telefone",
                            name: "telefone",
                          },
                          domProps: { value: _vm.model.telefone },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.model,
                                "telefone",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-3" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("celular"),
                            expression: "errors.first('celular')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "celular" },
                          },
                          [_vm._v("Celular")]
                        ),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "mask-telefone",
                              rawName: "v-mask-telefone",
                            },
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.model.celular,
                              expression: "model.celular",
                            },
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "telefone",
                              expression: "'telefone'",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            autocomplete: "off",
                            type: "text",
                            id: "celular",
                            name: "celular",
                          },
                          domProps: { value: _vm.model.celular },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.model,
                                "celular",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-6" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("email"),
                            expression: "errors.first('email')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "email" },
                          },
                          [_vm._v("E-mail")]
                        ),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.model.email,
                              expression: "model.email",
                            },
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "email",
                              expression: "'email'",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            autocomplete: "off",
                            type: "email",
                            id: "email",
                            name: "email",
                            placeholder: "exemplo@exemplo.com.br",
                          },
                          domProps: { value: _vm.model.email },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(_vm.model, "email", $event.target.value)
                            },
                          },
                        }),
                      ]
                    ),
                  ]),
                ]),
              ]
            ),
          ]),
          _vm._v(" "),
          _c(
            "fieldset-component",
            { attrs: { title: "Prescritores", collapsed: true } },
            [
              _c(
                "div",
                { attrs: { slot: "rows", id: "contato" }, slot: "rows" },
                [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-sm-12" }, [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: _vm.errors.first("telefone"),
                              expression: "errors.first('telefone')",
                            },
                          ],
                          staticClass: "form-group",
                        },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "control-label",
                              attrs: { for: "prescritorId" },
                            },
                            [_vm._v("Prescritor")]
                          ),
                          _vm._v(" "),
                          _c(
                            "search-combo-component",
                            {
                              attrs: {
                                id: "prescritorId",
                                name: "prescritorId",
                                actionName: "prescritores",
                                addNew: true,
                                advancedSearch: true,
                                advancedSearchCampos: [
                                  { campo: "text", descricao: "Nome" },
                                  {
                                    campo: "numeroRegistro",
                                    descricao: "Número de registro",
                                  },
                                  {
                                    campo: "tipoConselhoRegionalDescricao",
                                    descricao: "Conselho Regional",
                                  },
                                  { campo: "cpf", descricao: "CNPJ/CPF" },
                                ],
                                customComboText: _vm.customComboTextPrescritor,
                                customView: true,
                                formAddNewName: "Novo Prescritor",
                                propDescription: "nome",
                                service: "Prescritor",
                              },
                              on: {
                                addNewItemCombo: _vm.cadastrarNovoPrescritor,
                              },
                              model: {
                                value: _vm.model.prescritorId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.model, "prescritorId", $$v)
                                },
                                expression: "model.prescritorId",
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  attrs: { slot: "component" },
                                  slot: "component",
                                },
                                [
                                  _c("prescritorComponent", {
                                    ref: "prescritorComponent",
                                    attrs: { CalledByShortCut: true },
                                    on: { "save-ok": _vm.onAddPrescritorOk },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("buttonIncluirComponent", {
                    on: { click: _vm.incluirClinicaPrescritor },
                  }),
                  _vm._v(" "),
                  _c("grid-component", {
                    attrs: {
                      data: _vm.model.prescritores,
                      columns: _vm.gridColumns,
                      "show-action-column": true,
                      "show-table-head": false,
                      "show-table-foot": false,
                      "show-remove-item": true,
                      "show-edit-item": false,
                    },
                    on: { "remove-item": _vm.removerPrescritor },
                  }),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c("fieldset", [
            !_vm.hideActionBar
              ? _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-sm-12 newbtn" }, [
                    _c("div", [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-success",
                          attrs: { type: "submit" },
                        },
                        [
                          _c("i", {
                            staticClass: "fa fa-save",
                            attrs: { "aria-hidden": "true" },
                          }),
                          _vm._v(
                            "\n                            " +
                              _vm._s(_vm.$t("__.Crud.salvar")) +
                              "\n                        "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-secondary",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              _vm.load()
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n                            " +
                              _vm._s(_vm.$t("__.Crud.limpar")) +
                              "\n                        "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-secondary",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              _vm.cancel()
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n                            " +
                              _vm._s(_vm.$t("__.Crud.voltar")) +
                              "\n                        "
                          ),
                        ]
                      ),
                    ]),
                  ]),
                ])
              : _vm._e(),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }