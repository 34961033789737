import Vue from "vue";

import fieldsetComponent from "@/components/child/form/fieldset.vue";
import moedaComponent from "@/components/child/form/moeda.vue";
import gridComponent from "@/components/child/grid/grid.vue";
import { GridColumn, GridColumnType } from "@/components/child/grid/gridColumn";
import { Component } from "@/decorators";
import BeneficioModel from "@/models/beneficio/beneficioModel";
import TipoBeneficio from "@/models/enum/tipoBeneficio";
import ManipulacaoOrdemDetalheModel from "@/models/manipulacaoOrdemDetalheModel";
import ManipulacaoOrdemModel from "@/models/manipulacaoOrdemModel";
import PrescritorModel from "@/models/prescritorModel";
import VisitadorModel from "@/models/visitadorModel";
import BeneficioService from "@/services/beneficioService";
import ManipulacaoOrdemService from "@/services/manipulacaoOrdemService";
import PrescritorService from "@/services/prescritorService";
import VisitadorService from "@/services/visitadorService";
import arithmeticHelper from "@/utils/common/arithmeticHelper";

import "./detalhesItem.scss";

@Component({
    components: {
        fieldsetComponent,
        gridComponent,
        moedaComponent,
    },
})
export default class DetalhesItemComponent extends Vue {
    private service = new ManipulacaoOrdemService();
    private prescritorService = new PrescritorService();
    private visitadorService = new VisitadorService();
    private beneficioService = new BeneficioService();
    private model: Array<ManipulacaoOrdemDetalheModel> = [];

    //totais dos tipo
    private comissaoPrescritor = 0;
    private comissaoVisitador = 0;
    private comissaoAtendente = 0;

    private manipulacao: ManipulacaoOrdemModel;

    get gridColumns(): Array<GridColumn> {
        return [
            new GridColumn("tipoDescricao", this.$t("__.ts.tipo") as string, GridColumnType.String),
            new GridColumn("totalValorCusto", this.$t("__.ts.valorCusto") as string, GridColumnType.Money),
            new GridColumn("totalValorTotalItem", this.$t("__.ts.valorVenda") as string, GridColumnType.Money),
            new GridColumn(
                "totalDiferenca",
                this.$t("__.ts.diferencaCustoVenda") as string,
                GridColumnType.MoneyWithSignal,
            ),
        ];
    }

    subDataName = "produtos";

    get gridColumnsVinculos(): Array<GridColumn> {
        return [
            new GridColumn("descricao", this.$t("__.ts.descr") as string, GridColumnType.String),
            new GridColumn("valorCusto", this.$t("__.ts.valorCusto") as string, GridColumnType.Money),
            new GridColumn("valorItem", this.$t("__.ts.valorVenda") as string, GridColumnType.Money),
            new GridColumn("diferenca", this.$t("__.ts.diferencaCustoVenda") as string, GridColumnType.MoneyWithSignal),
        ];
    }

    conditionShowSubData(it) {
        return it["produtos"]["length"];
    }

    public async setManipulacaoOrdem(manipulacao: ManipulacaoOrdemModel) {
        this.manipulacao = manipulacao;
        await this.load().withLoading();
    }

    private async load() {
        try {
            this.model = await this.service
                .getDetalhesManipulacao(this.manipulacao)
                .resolveWithJSON<ManipulacaoOrdemDetalheModel[]>();

            this.setComissoes();
        } catch {}
    }

    private setComissoes() {
        if (this.manipulacao.prescritorId) {
            this.setComissaoPrescritor();
            this.setComissaoVisitador();
        }
        this.setComissaoAtendente();
    }

    private async setComissaoPrescritor() {
        try {
            const data = await this.prescritorService
                .get(this.manipulacao.prescritorId)
                .resolveWithJSON<PrescritorModel>();

            const faixas = data.comissoesFaixa.filter(
                x => this.manipulacao.valorTotal >= x.inicioFaixa && this.manipulacao.valorTotal <= x.fimFaixa,
            );

            if (faixas.length > 0) {
                this.comissaoPrescritor = this.manipulacao.valorTotal * (faixas[0].porcentagemComissao / 100);
                this.comissaoPrescritor = arithmeticHelper.round(this.comissaoPrescritor);
            }
        } catch {}
    }

    private async setComissaoVisitador() {
        try {
            const data = await this.visitadorService
                .GetFromPrescritor(this.manipulacao.prescritorId)
                .resolveWithJSON<VisitadorModel>();

            this.comissaoVisitador = arithmeticHelper.round(this.manipulacao.valorTotal * (data.comissao / 100));
        } catch {}
    }

    private async setComissaoAtendente() {
        this.comissaoAtendente = 0;

        try {
            const beneficio = await this.beneficioService.GetFromUsuarioLogado().resolveWithJSON<BeneficioModel>();

            if (beneficio.beneficioFaixas) {
                const faixa = beneficio.beneficioFaixas
                    .filter(p => p.tipoBeneficio == TipoBeneficio.Comissao)
                    .filter(
                        p => this.manipulacao.valorTotal >= p.inicioFaixa && this.manipulacao.valorTotal <= p.fimFaixa,
                    )[0];

                if (faixa && beneficio.beneficioFaixas.length > 0) {
                    this.comissaoAtendente = this.manipulacao.valorTotal * (faixa.valor / 100);
                }

                this.comissaoAtendente = arithmeticHelper.round(this.comissaoAtendente);
            }
        } catch {}
    }
}
