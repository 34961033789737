import Vue from "vue";
import { mapGetters } from "vuex";

import buttonIncluirComponent from "@/components/child/form/button/buttonIncluir.vue";
import comboComponent from "@/components/child/form/combo.vue";
import fieldsetComponent from "@/components/child/form/fieldset.vue";
import gridComponent from "@/components/child/grid/grid.vue";
import { GridColumn, GridColumnType } from "@/components/child/grid/gridColumn";
import { Component, Prop } from "@/decorators";
import FranquiaModel from "@/models/franquiaModel";
import ModeloImpressaoUsuarioModel from "@/models/modeloImpressao/modeloImpressaoUsuarioModel";
import PaginationModel from "@/models/paginationModel";
import UsuarioModel from "@/models/usuarioModel";
import UsuarioService from "@/services/usuarioService";
import { Getters } from "@/store/store";

@Component({
    components: {
        comboComponent,
        gridComponent,
        fieldsetComponent,
        buttonIncluirComponent,
    },
    computed: {
        ...mapGetters(["GET_FRANQUIA_ATUAL"] as Getters),
    },
})
export default class ModeloImpressaoUsuarioComponent extends Vue {
    // State computed props
    GET_FRANQUIA_ATUAL: () => FranquiaModel;

    private usuarioService = new UsuarioService();

    @Prop({ type: Array, default: () => [] }) impressoraOptions;

    private usuarioOptions = [];

    get gridColumnsUsuarios(): Array<GridColumn> {
        return [
            new GridColumn("usuarioNome", this.$t("__.ts.usuario"), GridColumnType.String),
            new GridColumn("nomeImpressora", this.$t("__.ts.impressora"), GridColumnType.String),
        ];
    }

    private usuarioId: number = null;
    private usuarioImpressora: string = null;
    private usuarios: ModeloImpressaoUsuarioModel[] = [];

    public setUsuarios(usuarios: ModeloImpressaoUsuarioModel[]) {
        this.usuarios = usuarios;
    }

    public getUsuarios() {
        return this.usuarios;
    }

    private async onInclusaoModeloUsuario() {
        const isValid = await this.$validator.validateAll();
        if (isValid) {
            const usuarioComponent = this.$el.querySelector("#usuarioId") as HTMLSelectElement;

            const alreadyExists = this.usuarios.filter(p => p.usuarioId == this.usuarioId).length > 0;

            if (!alreadyExists) {
                const modeloUsuario = new ModeloImpressaoUsuarioModel();

                modeloUsuario.usuarioId = this.usuarioId;
                modeloUsuario.usuarioNome = usuarioComponent.innerText;
                modeloUsuario.nomeImpressora = this.impressoraOptions[this.usuarioImpressora].printerName;

                this.usuarios.push(modeloUsuario);

                this.usuarioId = null;
                this.usuarioImpressora = null;
            }
        }
    }

    private onRemoveModeloUsuario(data: ModeloImpressaoUsuarioModel) {
        this.usuarios = this.usuarios.filter(p => p.usuarioId != data.usuarioId);
    }

    private async mounted() {
        const data = await this.usuarioService
            .listByFranquiaId(this.GET_FRANQUIA_ATUAL().id)
            .resolveWithJSON<PaginationModel<UsuarioModel>>();

        this.usuarioOptions = data.list.map(item => ({ value: item.id, text: item.nome }));
    }
}
