import TiposCapsula from "@/models/enum/tiposCapsula";
import ModeloImpressaoModel from "@/models/modeloImpressao/modeloImpressaoModel";
import { getCustomParams, getPaginationParams } from "@/utils/common/http";

import ManipulacaoOrdemItemModel from "../models/manipulacaoOrdemItemModel";
import ManipulacaoOrdemModel from "../models/manipulacaoOrdemModel";
import nfetch from "../utils/events/nfetch";

import { IServicePrint } from "./base/iServicePrint";
import { HtmlPrintPageInfo } from "./print/printerQueue";

export default class ManipulacaoOrdemService extends IServicePrint<ManipulacaoOrdemModel> {
    constructor() {
        super("ManipulacaoOrdem");
    }

    public listManipulacaoHome(): Promise<Response> {
        return nfetch(`/${this.controllerName}/ListManipulacaoHome`, {
            credentials: "same-origin",
        });
    }

    public listManipulacaoHomePagination(
        filterKey: string,
        sortKey: string,
        sortOrder: string,
        pageIndex: number,
        pageSize: number,
    ): Promise<Response> {
        const pagination = getPaginationParams(filterKey, sortKey, sortOrder, pageIndex, pageSize);

        return nfetch(`/${this.controllerName}/ListManipulacaoHomePagination?${pagination}`, {
            credentials: "same-origin",
        });
    }

    public listManipulacao(
        filterKey: string,
        sortKey: string,
        sortOrder: string,
        pageIndex: number,
        pageSize: number,
        customProperties: { [key: string]: any } = {},
    ): Promise<Response> {
        const custom = getCustomParams(customProperties);
        const pagination = getPaginationParams(filterKey, sortKey, sortOrder, pageIndex, pageSize);

        return nfetch(`/${this.controllerName}/ListManipulacao?${pagination}` + custom, {
            credentials: "same-origin",
        });
    }

    public definirCapsula(
        model: ManipulacaoOrdemModel,
        produtoCapsulaId = 0,
        usaMarkupEquinos = false,
        fatorMultQuantidade = 0,
        isTransferencia = false,
    ): Promise<Response> {
        return nfetch(
            `/${this.controllerName}/DefinirCapsula?produtoCapsulaId=${produtoCapsulaId}&usaMarkupEquinos=${usaMarkupEquinos}&fatorMultQuantidade=${fatorMultQuantidade}&isTransferencia=${isTransferencia}`,
            {
                method: "post",
                credentials: "same-origin",
                headers: {
                    "Content-Type": "application/json; charset=UTF-8",
                    "XSRF-TOKEN": this.getCookie("XSRF-TOKEN"),
                },
                body: JSON.stringify(model),
            },
        );
    }

    public validarFormaFarmaceuticaIncompativel(produtosIds: number[], formaFarmaceuticaId: number): Promise<Response> {
        return nfetch(
            `/${this.controllerName}/ValidarFormaFarmaceuticaIncompativel?formaFarmaceuticaId=${formaFarmaceuticaId}`,
            {
                method: "post",
                credentials: "same-origin",
                headers: {
                    "Content-Type": "application/json; charset=UTF-8",
                },
                body: JSON.stringify(produtosIds),
            },
        );
    }

    public definirEmbalagem(
        model: ManipulacaoOrdemModel,
        kitId = 0,
        usaMarkupEquinos = false,
        isTransferencia: boolean,
    ): Promise<Response> {
        return nfetch(
            `/${this.controllerName}/DefinirEmbalagem?kitId=${kitId}&usaMarkupEquinos=${usaMarkupEquinos}&isTransferencia=${isTransferencia}`,
            {
                method: "post",
                credentials: "same-origin",
                headers: {
                    "Content-Type": "application/json; charset=UTF-8",
                    "XSRF-TOKEN": this.getCookie("XSRF-TOKEN"),
                },
                body: JSON.stringify(model),
            },
        );
    }

    public definirMolde(model: ManipulacaoOrdemModel, moldeId = 0, fatorMultQuantidade = 0): Promise<Response> {
        return nfetch(
            `/${this.controllerName}/DefinirMolde?moldeId=${moldeId}&fatorMultQuantidade=${fatorMultQuantidade}`,
            {
                method: "post",
                credentials: "same-origin",
                headers: {
                    "Content-Type": "application/json; charset=UTF-8",
                    "XSRF-TOKEN": this.getCookie("XSRF-TOKEN"),
                },
                body: JSON.stringify(model),
            },
        );
    }

    public Concluir(model: ManipulacaoOrdemModel): Promise<Response> {
        //@ts-ignore
        return nfetch(`/${this.controllerName}/Concluir`, {
            method: "post",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json; charset=UTF-8",
                "XSRF-TOKEN": this.getCookie("XSRF-TOKEN"),
            },
            body: JSON.stringify(model),
        })
            .then((response: Response) => {
                if (response.ok) {
                    return response;
                } else {
                    return this.mensagemErro(response, "Save");
                }
            })
            .catch(() => {});
    }

    public concluirLista(ids: number[]) {
        return nfetch(`/${this.controllerName}/ConcluirLista`, {
            method: "post",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json; charset=UTF-8",
                "XSRF-TOKEN": this.getCookie("XSRF-TOKEN"),
            },
            body: JSON.stringify(ids),
        });
    }

    public getDetalhesManipulacao(manipulacao: ManipulacaoOrdemModel): Promise<Response> {
        //@ts-ignore
        return nfetch(`/${this.controllerName}/GetDetalhesManipulacao`, {
            method: "post",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json; charset=UTF-8",
                "XSRF-TOKEN": this.getCookie("XSRF-TOKEN"),
            },
            body: JSON.stringify(manipulacao),
        });
    }

    public getByCodigo(codigo: number): Promise<Response> {
        return nfetch(`/${this.controllerName}/GetByCodigo?codigo=${codigo}`, {
            credentials: "same-origin",
        });
    }

    public getObservacao(itens: ManipulacaoOrdemItemModel[], formulaPadraoId: number): Promise<Response> {
        //@ts-ignore
        return nfetch(`/${this.controllerName}/GetObservacao?formulaPadraoId=${formulaPadraoId}`, {
            method: "post",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json; charset=UTF-8",
                "XSRF-TOKEN": this.getCookie("XSRF-TOKEN"),
            },
            body: JSON.stringify(itens),
        });
    }

    public desfazerConclusao(id: number): Promise<Response> {
        //@ts-ignore
        return nfetch(`/${this.controllerName}/DesfazerConclusao?id=${id}`, {
            method: "post",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json; charset=UTF-8",
                "XSRF-TOKEN": this.getCookie("XSRF-TOKEN"),
            },
        });
    }

    public getFormulaPadraoByProdutos(produtosIds: number[]): Promise<Response> {
        //@ts-ignore
        return nfetch(`/${this.controllerName}/GetFormulaPadraoByProdutos`, {
            method: "post",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json; charset=UTF-8",
                "XSRF-TOKEN": this.getCookie("XSRF-TOKEN"),
            },
            body: JSON.stringify(produtosIds),
        });
    }

    public updateDadosAprovarOrcamento(manipulacoes: ManipulacaoOrdemModel[]): Promise<Response> {
        //@ts-ignore
        return nfetch(`/${this.controllerName}/UpdateDadosAprovarOrcamento`, {
            method: "post",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json; charset=UTF-8",
                "XSRF-TOKEN": this.getCookie("XSRF-TOKEN"),
            },
            body: JSON.stringify(manipulacoes),
        });
    }

    public getDataValidade(manipulacao: ManipulacaoOrdemModel): Promise<Response> {
        //@ts-ignore
        return nfetch(`/${this.controllerName}/GetDataValidade`, {
            method: "post",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json; charset=UTF-8",
                "XSRF-TOKEN": this.getCookie("XSRF-TOKEN"),
            },
            body: JSON.stringify(manipulacao),
        });
    }

    public loadDadosProdutoLoteItens(manipulacao: ManipulacaoOrdemModel, isTransferencia: boolean): Promise<Response> {
        //@ts-ignore
        return nfetch(`/${this.controllerName}/LoadDadosProdutoLoteItens?isTransferencia=${isTransferencia}`, {
            method: "post",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json; charset=UTF-8",
                "XSRF-TOKEN": this.getCookie("XSRF-TOKEN"),
            },
            body: JSON.stringify(manipulacao),
        });
    }

    public getCorCapsula(
        capsulas: ManipulacaoOrdemItemModel[],
        itens: ManipulacaoOrdemItemModel[],
        capsulaId: number,
        tipoCapsula: TiposCapsula,
        quantidade: number,
    ): Promise<Response> {
        //@ts-ignore
        return nfetch(`/${this.controllerName}/GetCorCapsula`, {
            method: "post",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json; charset=UTF-8",
                "XSRF-TOKEN": this.getCookie("XSRF-TOKEN"),
            },
            body: JSON.stringify({
                capsulas,
                itens,
                capsulaId,
                tipoCapsula,
                quantidade,
            }),
        });
    }

    public async getManyHTMLPage(
        manipulacoesIds: number[],
        modelImpressaoId: number,
        copias: number,
        usuarioId: number,
    ): Promise<HtmlPrintPageInfo> {
        try {
            const data = await this.getToPrintPersonalizado(manipulacoesIds, modelImpressaoId);

            if (data.length == 0) {
                throw new Error("Não foi possível obter o modelo de impressão");
            }

            let modelos = "";
            let printerName = "";
            for (let i = 0; i < data.length; i++) {
                const modelImpressao = new ModeloImpressaoModel();
                modelImpressao.updateFrom(data[i]);

                printerName = modelImpressao.nomeImpressora;
                if (modelImpressao.usuarios.some(p => p.usuarioId == usuarioId)) {
                    printerName = modelImpressao.usuarios.find(p => p.usuarioId == usuarioId).nomeImpressora;
                }

                const modelo = await modelImpressao.toHTML();
                modelos += modelo;
            }
            return {
                pageTitle: data[0].descricao,
                pageHtml: modelos,
                copies: copias,
                printerName: printerName,
            };
        } catch {}
    }

    public async getToPrintPersonalizado(ids: number[], modeloImpressaoId: number): Promise<ModeloImpressaoModel[]> {
        const response = nfetch(
            `/${this.controllerName}/GetToPrintPersonalizado?modeloImpressaoId=${modeloImpressaoId}`,
            {
                method: "post",
                credentials: "same-origin",
                headers: {
                    "Content-Type": "application/json; charset=UTF-8",
                },
                body: JSON.stringify(ids),
            },
        );

        return await response.resolveWithJSON<ModeloImpressaoModel[]>();
    }
}
