import ManipulacaoOrdemCancelamentoModel from "../models/manipulacaoOrdemCancelamentoModel";
import nfetch from "../utils/events/nfetch";

import { IService } from "./base/iService";

export default class ManipulacaoOrdemCancelamentoService extends IService<ManipulacaoOrdemCancelamentoModel> {
    constructor() {
        super("ManipulacaoOrdemCancelamento");
    }

    public getManipulacaoOrdem(id: number): Promise<Response> {
        return nfetch(`/${this.controllerName}/GetManipulacaoOrdem?id=${id}`, {
            credentials: "same-origin",
        });
    }
}
