import BaseModel from "../base/baseModel";

export default class ProdutoSinonimoModel extends BaseModel {
    id?: number = null;
    produtoId?: number = null;
    produtoDescricao: string = null;
    sinonimo: string = null;
    fatorEquivalencia: number = null;
    descricaoRotulo: string = null;
}
