import { GridColumn, GridColumnType } from "@/components/child/grid/gridColumn";
import { Component } from "@/decorators";
import GrupoProdutoModel from "@/models/produto/grupoProdutoModel";
import GrupoProdutoService from "@/services/produto/grupoProdutoService";

import ListComponentBase from "../listComponentBase";

@Component
export default class GrupoProdutoListComponent extends ListComponentBase<GrupoProdutoModel> {
    get gridColumns(): Array<GridColumn> {
        return [new GridColumn("descricao", this.$t("__.ts.descricao"), GridColumnType.String)];
    }

    private beforeMount() {
        this.setProps(new GrupoProdutoService(), "grupoproduto", "descricao", true);
    }
}
