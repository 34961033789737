import Vue from "vue";
import { mapMutations, mapState } from "vuex";

import gridComponent from "@/components/child/grid/grid.vue";
import { GridAction } from "@/components/child/grid/gridAction";
import { GridColors, GridColumn, GridColumnType } from "@/components/child/grid/gridColumn";
import { Component } from "@/decorators";
import PaginationPackModel from "@/models/auxiliar/paginationPackModel";
import TipoOperacao from "@/models/enum/tipoOperacao";
import NotaFiscalEletronicaEmissaoModel from "@/models/notaFiscalEletronicaEmissaoModel";
import PaginationModel from "@/models/paginationModel";
import NotaFiscalEletronicaEmissaoService from "@/services/emissaoNota/notaFiscalEletronicaEmissaoService";
import { AppState, Mutations } from "@/store/store";
import Delay from "@/utils/common/delay";

import "../crud/crud.scss";

@Component({
    components: {
        gridComponent,
    },
    computed: mapState<AppState>({
        loadingPack: state => state.paginationStatus.loading,
        paginationParams: state => state.paginationStatus.paginationParams,
    }),
    methods: mapMutations(["SET_PARAMS", "GET_PARAMS"] as Mutations),
})
export default class NfeListComponent extends Vue {
    // State computed props
    loadingPack: boolean;
    paginationParams: PaginationPackModel;
    SET_PARAMS: (obj: object) => void;
    GET_PARAMS: (path: string) => Promise<void>;

    private service = new NotaFiscalEletronicaEmissaoService();

    startFilterKey = "";
    gridFilterKey = "";
    gridSortKey = "dataEmissao";
    gridSortOrder = "desc";
    gridData: Array<NotaFiscalEletronicaEmissaoModel> = [];

    get gridColumns(): Array<GridColumn> {
        return [
            new GridColumn("destinatarioNome", "Destinatário", GridColumnType.String),
            new GridColumn("tipoOperacaoDescricao", "Operação ", GridColumnType.String),
            new GridColumn("statusEnvioDescricao", "Status", GridColumnType.String),
            new GridColumn("dataEmissao", "Data da Emissão", GridColumnType.DateTime),
            new GridColumn("numero", "Número", GridColumnType.String),
            new GridColumn("serie", "Série", GridColumnType.String),
            new GridColumn("valorTotal", "Valor", GridColumnType.Money),
        ];
    }

    extraActions: Array<object> = [];
    extraActionsPdf: Array<GridAction> = [];
    extraActionsPdfSimplificado: Array<GridAction> = [];
    extraActionsXml: Array<GridAction> = [];
    extraActionsConsultar: Array<GridAction> = [];
    extraActionsCancelar: Array<GridAction> = [];
    extraActionsGerarCartaCorrecao: Array<GridAction> = [];
    extraActionsCartaCorrecao: Array<GridAction> = [];

    pageIndex = 1;
    pageSize = 20;
    total = 0;

    private async load() {
        try {
            const data = await this.service
                .list(this.gridFilterKey, this.gridSortKey, this.gridSortOrder, this.pageIndex, this.pageSize)
                .withLoading()
                .resolveWithJSON<PaginationModel<NotaFiscalEletronicaEmissaoModel>>();
            this.gridData = data.list;
            this.total = data.total;
            this.pageIndex = data.pageIndex;
            this.pageSize = data.pageSize;

            if (data.total > 0) {
                this.SET_PARAMS({
                    routePath: this.$route.path,
                    filterKey: this.gridFilterKey,
                    sortKey: this.gridSortKey,
                    sortOrder: this.gridSortOrder,
                    pageIndex: this.pageIndex,
                    pageSize: this.pageSize,
                });
            }

            this.extraActions.splice(0);
            this.extraActionsConsultar.splice(0);
            this.extraActionsCancelar.splice(0);
            this.extraActionsPdf.splice(0);
            this.extraActionsPdfSimplificado.splice(0);
            this.extraActionsXml.splice(0);
            this.extraActionsGerarCartaCorrecao.splice(0);
            this.extraActionsCartaCorrecao.splice(0);

            for (let i = 0; i < this.gridData.length; i++) {
                this.extraActionsConsultar.push(
                    new GridAction(
                        "consultar",
                        "Consultar Nota",
                        "fa fa-search",
                        GridColors.BLUE,
                        this.gridData[i].statusEnvioDescricao != "Cancelado",
                    ),
                );

                this.extraActionsCancelar.push(
                    new GridAction(
                        "cancelar",
                        "Cancelar Nota",
                        "fa fa-exclamation-circle",
                        GridColors.LIGHTRED,
                        this.gridData[i].statusEnvioDescricao == "Confirmado",
                    ),
                );

                this.extraActionsPdf.push(
                    new GridAction(
                        "abrir-pdf",
                        "Abrir PDF",
                        "fa fa-file-pdf",
                        GridColors.RED,
                        this.gridData[i].pdfLink != null && this.gridData[i].statusEnvioDescricao == "Confirmado",
                    ),
                );

                this.extraActionsPdfSimplificado.push(
                    new GridAction(
                        "abrir-pdf-simplificado",
                        "Abrir PDF Simplificado",
                        "fa fa-file-pdf",
                        GridColors.LIGHTRED,
                        this.gridData[i].pdfSimplificadoLink && this.gridData[i].statusEnvioDescricao == "Confirmado",
                    ),
                );

                this.extraActionsXml.push(
                    new GridAction(
                        "abrir-xml",
                        "Download do XML",
                        "fa fa-file-code",
                        GridColors.LIGHTGREEN,
                        this.gridData[i].xmlLink != null && this.gridData[i].statusEnvioDescricao == "Confirmado",
                    ),
                );

                this.extraActionsGerarCartaCorrecao.push(
                    new GridAction(
                        "carta-correcao",
                        "Gerar Carta de correção",
                        "fa fa-envelope",
                        GridColors.LIGHTGREEN,
                        this.gridData[i].statusEnvioDescricao == "Confirmado" && this.gridData[i].modelo == 55,
                    ),
                );

                this.extraActionsCartaCorrecao.push(
                    new GridAction(
                        "consulta-carta-correcao",
                        "Consultar última Carta de correção",
                        "fa fa-envelope-open",
                        GridColors.ORANGE,
                        this.gridData[i].temCartaCorrecao,
                    ),
                );
            }

            this.extraActions.push(this.extraActionsConsultar);
            this.extraActions.push(this.extraActionsPdf);
            this.extraActions.push(this.extraActionsPdfSimplificado);
            this.extraActions.push(this.extraActionsXml);
            this.extraActions.push(this.extraActionsCancelar);
            this.extraActions.push(this.extraActionsGerarCartaCorrecao);
            this.extraActions.push(this.extraActionsCartaCorrecao);
        } catch {}
    }

    private onCreateItem() {
        this.$router.push("/nfe-edicao");
    }

    private onChangeFilterKey(filterKey: string) {
        this.gridFilterKey = filterKey;
        this.load();
    }

    private onChangeSort(sortKey: string, sortOrder: string) {
        this.gridSortKey = sortKey;
        this.gridSortOrder = sortOrder;
        this.load();
    }

    private onChangePage(pageIndex: number) {
        this.pageIndex = pageIndex;
        this.load();
    }

    private onExtraAction(name: string, notaFiscalEletronicaEmissaoModel: NotaFiscalEletronicaEmissaoModel) {
        if (name.trim() == "abrir-pdf") {
            this.service.openPdf(notaFiscalEletronicaEmissaoModel.pdfLink);
        } else if (name.trim() == "abrir-pdf-simplificado") {
            this.service.openPdf(notaFiscalEletronicaEmissaoModel.pdfSimplificadoLink);
        } else if (name.trim() == "abrir-xml") {
            this.service.downloadXML(notaFiscalEletronicaEmissaoModel.xmlLink);
        } else if (name.trim() == "cancelar") {
            this.cancelarNota(notaFiscalEletronicaEmissaoModel.chave);
        } else if (name.trim() == "consultar") {
            this.$router.push("/nfe-edicao/" + notaFiscalEletronicaEmissaoModel.id);
        } else if (name.trim() == "carta-correcao") {
            this.cartaCorrecao(notaFiscalEletronicaEmissaoModel);
        } else if (name.trim() == "consulta-carta-correcao") {
            this.service.showCartaCorrecao(
                notaFiscalEletronicaEmissaoModel.id,
                notaFiscalEletronicaEmissaoModel.tipoOperacaoDescricao === "Entrada"
                    ? TipoOperacao.Entrada
                    : TipoOperacao.Saida,
            );
        }
    }

    private async cancelarNota(chave: string) {
        const response = await this.service.cancelamentoSelecao();

        if (response.isConfirmed) {
            try {
                const sucesso = await this.service
                    .cancelamento(chave, response.value)
                    .withLoading()
                    .resolveWithoutJSON();

                if (sucesso) {
                    this.load();
                }
            } catch {}
        }
    }

    private async cartaCorrecao(model: NotaFiscalEletronicaEmissaoModel) {
        const response = await this.service.cartaCorrecaoSelecao();
        if (response.isConfirmed) {
            try {
                const sucesso = await this.service
                    .cartaCorrecao(
                        model.chave,
                        model.id,
                        response.value,
                        model.tipoOperacaoDescricao === "Entrada" ? TipoOperacao.Entrada : TipoOperacao.Saida,
                    )
                    .withLoading()
                    .resolveWithoutJSON();

                if (sucesso) {
                    this.load();
                }
            } catch {}
        }
    }

    private async loadPagination() {
        await this.GET_PARAMS(this.$route.path);
        while (this.loadingPack) await Delay(50);

        if (this.paginationParams != null) {
            this.gridFilterKey = this.paginationParams.filterKey;
            this.startFilterKey = this.gridFilterKey;
            this.gridSortKey = this.paginationParams.sortKey;
            this.gridSortOrder = this.paginationParams.sortOrder;
            this.pageIndex = this.paginationParams.pageIndex;
            this.pageSize = this.paginationParams.pageSize;
        }
    }

    private mounted() {
        Promise.all([this.loadPagination()])
            .withLoading()
            .then(() => {
                this.load();
            })
            .catch(() => {});
    }
}
