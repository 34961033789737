var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "fieldset-component",
    {
      staticStyle: { margin: "15px" },
      attrs: { title: "Adições", collapsed: false },
    },
    [
      _c(
        "div",
        { attrs: { slot: "rows" }, slot: "rows" },
        [
          !_vm.consulta
            ? _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-sm-4" }, [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "tooltip",
                          rawName: "v-tooltip",
                          value: _vm.errors.first("numeroAdicao"),
                          expression: "errors.first('numeroAdicao')",
                        },
                      ],
                      staticClass: "form-group",
                    },
                    [
                      _c(
                        "label",
                        {
                          staticClass: "control-label",
                          attrs: {
                            for: "numeroAdicao",
                            title: _vm.$t("__.obrigatorio"),
                          },
                        },
                        [
                          _c("span", { staticStyle: { color: "red" } }, [
                            _vm._v("*"),
                          ]),
                          _vm._v(
                            "\n                        Número\n                    "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.model.numeroAdicao,
                            expression: "model.numeroAdicao",
                          },
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required",
                            expression: "'required'",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          id: "numeroAdicao",
                          name: "numeroAdicao",
                        },
                        domProps: { value: _vm.model.numeroAdicao },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.model,
                              "numeroAdicao",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-sm-4" }, [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "tooltip",
                          rawName: "v-tooltip",
                          value: _vm.errors.first(
                            "codigoFabricanteEstrangeiro"
                          ),
                          expression:
                            "errors.first('codigoFabricanteEstrangeiro')",
                        },
                      ],
                      staticClass: "form-group",
                    },
                    [
                      _c(
                        "label",
                        {
                          staticClass: "control-label",
                          attrs: {
                            for: "codigoFabricanteEstrangeiro",
                            title: _vm.$t("__.obrigatorio"),
                          },
                        },
                        [
                          _c("span", { staticStyle: { color: "red" } }, [
                            _vm._v("*"),
                          ]),
                          _vm._v(
                            "\n                        Fabricante Estrangeiro\n                    "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("data-tooltip-component", {
                        attrs: {
                          text: "Código do fabricante estrangeiro, usado nos sistemas internos de informação do emitente da NF-e",
                        },
                      }),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.model.codigoFabricanteEstrangeiro,
                            expression: "model.codigoFabricanteEstrangeiro",
                          },
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required",
                            expression: "'required'",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          id: "codigoFabricanteEstrangeiro",
                          name: "codigoFabricanteEstrangeiro",
                        },
                        domProps: {
                          value: _vm.model.codigoFabricanteEstrangeiro,
                        },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.model,
                              "codigoFabricanteEstrangeiro",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-sm-4" }, [
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c(
                        "label",
                        {
                          staticClass: "control-label",
                          attrs: { for: "desconto" },
                        },
                        [_vm._v("Desconto")]
                      ),
                      _vm._v(" "),
                      _c("moeda-component", {
                        staticClass: "form-control",
                        attrs: { id: "desconto", name: "desconto" },
                        model: {
                          value: _vm.model.desconto,
                          callback: function ($$v) {
                            _vm.$set(_vm.model, "desconto", $$v)
                          },
                          expression: "model.desconto",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          !_vm.consulta
            ? _c("buttonIncluirComponent", {
                attrs: {
                  alterar: _vm.editingModel,
                  cancelar: _vm.editingModel,
                },
                on: { click: _vm.onAdicionarItem, cancelar: _vm.clear },
              })
            : _vm._e(),
          _vm._v(" "),
          _c("grid-component", {
            attrs: {
              data: _vm.data,
              columns: _vm.gridColumns,
              "show-action-column": !_vm.consulta,
              "show-remove-item": !_vm.consulta,
              "show-edit-item": !_vm.consulta,
              showAddNewButton: false,
              "show-table-head": false,
              "show-table-foot": false,
            },
            on: {
              "edit-item": _vm.onEditItem,
              "remove-item": _vm.onRemoveItem,
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }