import { mapState } from "vuex";

import comboComponent from "@/components/child/form/combo.vue";
import dateTimePickerComponent from "@/components/child/form/datetimepicker.vue";
import { GridAction } from "@/components/child/grid/gridAction";
import { GridColors, GridColumn, GridColumnType } from "@/components/child/grid/gridColumn";
import { Component } from "@/decorators";
import PontuacaoDotzModel, { PontuacaoCustomParameters } from "@/models/dotz/pontuacaoDotzModel";
import StatusEnvioDotz from "@/models/enum/dotz/statusEnvioDotz";
import EnumExtensions from "@/models/enum/extensions/enumExtensions";
import PaginationModel from "@/models/paginationModel";
import PrescritorModel from "@/models/prescritorModel";
import UsuarioModel from "@/models/usuarioModel";
import PontuacaoDotzService from "@/services/dotz/pontuacaoDotzService";
import PrescritorService from "@/services/prescritorService";
import { AppState } from "@/store/store";

import ListComponentBase from "../listComponentBase";

import VendasPontuadasListComponent from "./vendasPontuadas";
import vendasPontuadasListComponent from "./vendasPontuadas.vue";

import "./list.scss";

@Component({
    components: {
        dateTimePickerComponent,
        comboComponent,
        vendasPontuadasListComponent,
    },
    computed: {
        ...mapState({
            usuarioLogado: (state: AppState) => state.session.usuarioLogado,
        }),
    },
})
export default class HistoricoEnviosListComponent extends ListComponentBase<PontuacaoDotzModel> {
    // State computed props
    usuarioLogado: UsuarioModel;

    private periodo: Date = null;
    private prescritorId?: number = null;
    private prescritoresOptions: Array<Object> = [];
    private statusEnvioDotz: number = null;
    private statusEnvioDotzOptions: Array<Object> = EnumExtensions.getNamesAndValuesOrderedByNames(StatusEnvioDotz);

    private extraActions: Array<object> = [];
    private extraActionsDetalhes: Array<GridAction> = [];
    private vendasPontuadasListComponent: VendasPontuadasListComponent = null;

    service: PontuacaoDotzService = null;
    vendas = [];
    private pontuacaoDotzTotal = 0;
    pageSize = 50;

    private showFilter = false;

    get gridColumns(): Array<GridColumn> {
        return [
            new GridColumn("nomePontuado", "Pontuado", GridColumnType.String),
            new GridColumn("pontuacao", "Pontuação", GridColumnType.Integer),
            new GridColumn("periodoEnvio", "Período de Envio", GridColumnType.Period),
            new GridColumn("statusDescricao", "Situação do Envio", GridColumnType.String),
            new GridColumn("mensagemErro", "Mensagem do Erro", GridColumnType.String),
            new GridColumn("dataEnvio", "Data do Envio", GridColumnType.DateTime),
        ];
    }

    public async loadMethod() {
        try {
            const customParameters: PontuacaoCustomParameters = {
                periodo: this.periodo ? new Date(this.periodo).toISOString() : null,
                idPontuado: this.prescritorId,
                statusEnvioDotz: this.statusEnvioDotz,
            };

            const data = await this.service
                .list(
                    this.gridFilterKey,
                    this.gridSortKey,
                    this.gridSortOrder,
                    this.pags ? 1 : this.pageIndex,
                    this.pags ? 999999 : this.pageSize,
                    customParameters,
                )
                .withLoading()
                .resolveWithJSON<PaginationModel<PontuacaoDotzModel>>();

            this.pontuacaoDotzTotal = data.list.reduce((total, item) => total + item.pontuacao, 0);

            return data;
        } catch {}
    }

    public async afterLoad() {
        this.extraActions.splice(0);
        this.extraActionsDetalhes.splice(0);

        for (let i = 0; i < this.gridData.length; i++) {
            this.extraActionsDetalhes.push(
                new GridAction("detalhes", "Visualizar vendas pontuadas", "fa fa-search", GridColors.BLUE),
            );
        }

        this.extraActions.push(this.extraActionsDetalhes);
    }

    private async onExtraAction(name: string, pontuacao: PontuacaoDotzModel) {
        if (name.trim() == "detalhes") {
            this.vendasPontuadasListComponent = this.$refs.vendasPontuadasListComponent as VendasPontuadasListComponent;
            await this.vendasPontuadasListComponent.show(pontuacao.id);
        }
    }

    private async loadPrescritores() {
        try {
            const prescritores = await new PrescritorService()
                .combo()
                .resolveWithJSON<PaginationModel<PrescritorModel>>();

            this.prescritoresOptions = prescritores.list.map(item => ({
                value: item.id,
                text: item.nome,
            }));
        } catch {}
    }

    private reloadList() {
        this.showFilter = false;
        this.pageIndex = 1;
        this.load();
    }

    private beforeMount() {
        this.setProps(new PontuacaoDotzService(), "", "nomePontuado");

        this.loadPrescritores();
    }
}
