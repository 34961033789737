import Vue from "vue";
import { mapMutations, mapState } from "vuex";

import Moment from "moment";

import comboComponent from "@/components/child/form/combo.vue";
import dateTimePickerComponent from "@/components/child/form/datetimepicker.vue";
import Grid from "@/components/child/grid/grid";
import gridComponent from "@/components/child/grid/grid.vue";
import { GridColumn, GridColumnType } from "@/components/child/grid/gridColumn";
import { Component, Watch, Prop } from "@/decorators";
import PaginationPackModel from "@/models/auxiliar/paginationPackModel";
import StatusContas from "@/models/enum/statusContas";
import ContaGerencialModel from "@/models/financeiro/contaGerencialModel";
import ContaReceberModel, { ContaReceberListParameters } from "@/models/financeiro/contaReceberModel";
import PaginationModel from "@/models/paginationModel";
import ContaGerencialService from "@/services/financeiro/contaGerencialService";
import ContaReceberService from "@/services/financeiro/contaReceberService";
import { AppState, Mutations } from "@/store/store";
import { convertToDateInput, convertToDateInputString } from "@/utils/common/date";
import Delay from "@/utils/common/delay";

import "../crud.scss";
import "./list.scss";

@Component({
    components: {
        gridComponent,
        comboComponent,
        dateTimePickerComponent,
    },
    computed: mapState<AppState>({
        loadingPack: state => state.paginationStatus.loading,
        paginationParams: state => state.paginationStatus.paginationParams,
    }),
    methods: mapMutations(["SET_PARAMS", "GET_PARAMS"] as Mutations),
})
export default class ContaReceberListComponent extends Vue {
    // State computed props
    loadingPack: boolean;
    paginationParams: PaginationPackModel;
    SET_PARAMS: (obj: object) => void;
    GET_PARAMS: (path: string) => Promise<void>;

    private service = new ContaReceberService();
    private gridcomponent: Grid = null;

    @Prop({ type: String, default: "" }) FilterKeyId: string;
    @Prop({ type: Number, default: StatusContas.Aberto }) FilterStatusConta: StatusContas;
    @Prop({ type: Boolean, default: false }) HideExtras: boolean;

    mes = Moment(new Date(), "MMMM").toString();
    semana = Moment(new Date(), "dddd").toString();
    data1 = Moment(new Date(), "DD").toString();

    private hideExtras = false;

    startFilterKey = "";
    gridFilterStatusConta = StatusContas.Aberto;
    gridFilterKeyId = "";
    gridFilterKey = "";
    gridSortKey = "dataVencimento";
    gridSortOrder = "asc";
    gridSelectedValues: Array<ContaReceberModel> = [];
    gridData: Array<ContaReceberModel> = [];

    get gridColumns(): Array<GridColumn> {
        return [
            new GridColumn("dataVencimento", "Vencimento", GridColumnType.Date, false, false, "", "", true),
            new GridColumn("descricao", "Descri\u00e7\u00e3o", GridColumnType.String, false, false, "", "", true),
            new GridColumn("numeroNota", "Nota", GridColumnType.Integer, false, false, "", "", true),
            new GridColumn("clienteDescricao", "Cliente", GridColumnType.String, false, false, "", "", true),
            new GridColumn("valorTotal", "Valor Original", GridColumnType.Money, false, true, "", "", true),
            new GridColumn(
                "contaGerencialDescricao",
                "Conta Gerencial",
                GridColumnType.String,
                false,
                false,
                "",
                "",
                true,
            ),
        ];
    }

    subDataName = "lancamentosVinculados";

    gridColumnsVinculos: Array<GridColumn> = [
        new GridColumn("dataVencimento", "Vencimento", GridColumnType.Date),
        new GridColumn("descricao", "Descri\u00e7\u00e3o", GridColumnType.String),
        new GridColumn("valorTotal", "Valor", GridColumnType.Money),
        new GridColumn("saldo", "Saldo", GridColumnType.Money),
    ];

    pageIndex = 1;
    pageSize = 20;
    total = 0;
    isHidden = false;
    pags = false;
    tipoDocGerar: number = null;

    contaGerencialOptions: Array<Object> = [];
    filtro: Array<Object> = [];
    contasFiltro?: number = null;

    dataFilter1: Date = null;
    dataFilter2: Date = null;

    conditionShowSubData(it) {
        return it["lancamentosVinculados"]["length"] > 0;
    }

    private async load() {
        try {
            this.isHidden = false;

            this.gridFilterKeyId = this.FilterKeyId;
            this.gridFilterStatusConta = this.FilterStatusConta;

            const customParameters: ContaReceberListParameters = {
                statusContas: this.gridFilterStatusConta,
                filterKeyId: this.gridFilterKeyId,
                dataVencimentoIniStr: convertToDateInputString(this.filtro[0]),
                dataVencimentoFinStr: convertToDateInputString(this.filtro[1], true),
                contaCorrenteId: null,
                contaGerencialId: this.filtro[2] ? <number>this.filtro[2] : null,
                onlyClientes: true,
                onlyOperadoras: false,
            };

            const data = await this.service
                .list(
                    this.gridFilterKey,
                    this.gridSortKey,
                    this.gridSortOrder,
                    this.pags ? 1 : this.pageIndex,
                    this.pags ? 999999 : this.pageSize,
                    customParameters,
                )
                .withLoading()
                .resolveWithJSON<PaginationModel<ContaReceberModel>>();

            if (data.total > 0) {
                this.SET_PARAMS({
                    routePath: this.$route.path,
                    filterKey: this.gridFilterKey,
                    sortKey: this.gridSortKey,
                    sortOrder: this.gridSortOrder,
                    pageIndex: this.pageIndex,
                    pageSize: this.pageSize,
                    filtros: this.filtro,
                });
            }

            this.gridData = data.list;
            this.gridData["dataDe"] = this.filtro[0] ? this.filtro[0].toString() : " ";
            this.gridData["dataAte"] = this.filtro[1] ? this.filtro[1].toString() : " ";
            if (this.filtro[2]) {
                this.gridData["caixa"] = data.list[0]["tipoConta"];
            }
            this.total = data.total;

            if (this.pags) {
                if (this.tipoDocGerar == 0) {
                    setTimeout(() => {
                        this.gridcomponent.gerarXls();
                    }, 2000);
                }

                if (this.tipoDocGerar == 1) {
                    setTimeout(() => {
                        this.gridcomponent.gerarPdf();
                    }, 2000);
                }

                if (this.tipoDocGerar == 2) {
                    setTimeout(() => {
                        this.gridcomponent.gerarImpressao();
                    }, 2000);
                }

                setTimeout(() => {
                    this.pags = false;
                }, 2000);
            }
        } catch {}
    }

    private receberSelecionados() {
        if (this.gridSelectedValues.length == 1) {
            this.$router.push("/contareceber-edicao/" + this.gridSelectedValues[0].id);
        } else {
            this.$router.push("/contareceber-multiplas-edicao/" + this.gridSelectedValues.map(p => p.id));
        }
    }

    private onEditItem(model: ContaReceberModel) {
        this.$router.push("/contareceber-edicao/" + model.id);
    }

    private async onRemoveItem(model: ContaReceberModel) {
        const response = await this.$showExclusaoQuestion(`${this.$t("__.ts.desejaExcl")}${model.descricao}?`);

        if (response) {
            try {
                const sucesso = await this.service.delete(model.id).withLoading().resolveWithoutJSON();
                if (sucesso) {
                    this.load();
                    this.$showExclusao();
                }
            } catch {}
        }
    }

    private onCreateItem() {
        this.$router.push("/contareceber-edicao");
    }

    private onChangeFilterKey(filterKey: string) {
        this.gridFilterKey = filterKey;
        this.load();
    }

    private onChangeSort(sortKey: string, sortOrder: string) {
        this.gridSortKey = sortKey;
        this.gridSortOrder = sortOrder;
        this.load();
    }

    private onChangePage(pageIndex: number) {
        this.pageIndex = pageIndex;
        this.load();
    }

    private onSelectedValuesChanged(values: Array<ContaReceberModel>) {
        this.gridSelectedValues = values;
    }

    private getFormValues(listaFiltro) {
        this.filtro = [];

        this.filtro.push(listaFiltro.target.elements.dataFilter1.value);

        this.filtro.push(listaFiltro.target.elements.dataFilter2.value);

        this.filtro.push(this.contasFiltro);

        this.load();
    }

    private async loadContasGerencial() {
        try {
            const data = await new ContaGerencialService()
                .combo()
                .then(r => r.json() as Promise<PaginationModel<ContaGerencialModel>>);
            this.contaGerencialOptions = data.list.map(item => ({ value: item.id, text: item.descricao }));
        } catch {}
    }

    @Watch("FilterKeyId")
    private onFilterChange() {
        this.load();
    }

    @Watch("pags")
    private onPags() {
        this.load();
    }

    private async loadPagination() {
        await this.GET_PARAMS(this.$route.path);
        while (this.loadingPack) await Delay(50);

        if (this.paginationParams != null) {
            this.gridFilterKey = this.paginationParams.filterKey;
            this.startFilterKey = this.gridFilterKey;
            this.gridSortKey = this.paginationParams.sortKey;
            this.gridSortOrder = this.paginationParams.sortOrder;
            this.pageIndex = this.paginationParams.pageIndex;
            this.pageSize = this.paginationParams.pageSize;

            if (this.paginationParams.filtros != null) {
                this.filtro = this.paginationParams.filtros;

                if (this.filtro.length >= 0) {
                    if (this.filtro[0]) {
                        this.dataFilter1 = convertToDateInput(this.filtro[0].toString());
                    }
                    if (this.filtro[1]) {
                        this.dataFilter2 = convertToDateInput(this.filtro[1].toString());
                    }
                    if (this.filtro[2] != null && <number>this.filtro[2] > 0) {
                        this.contasFiltro = <number>this.filtro[2];
                    }
                }
            }
        }
    }

    private mounted() {
        this.gridcomponent = this.$refs.gridcomponent as Grid;
        this.hideExtras = this.HideExtras ? true : false;

        this.loadContasGerencial();

        Promise.all([this.loadPagination()])
            .then(() => {
                this.load();
            })
            .catch(() => {});
    }
}
