import { Validator } from "vee-validate";
import MessagesPtBr from "vee-validate/dist/locale/pt_BR";

MessagesPtBr.messages.alpha = field => "Campo pode conter somente letras";
MessagesPtBr.messages.alpha_dash = field => "Campo deve conter somente letras, n\u00fameros e tra\u00e7os";
MessagesPtBr.messages.alpha_num = field => "Campo deve conter somente letras e n\u00fameros";
MessagesPtBr.messages.alpha_spaces = field => "Campo pode conter apenas letras e espa\u00e7os";
MessagesPtBr.messages.between = (field, [min, max]) => `Valor deve estar entre ${min} e ${max}`;
MessagesPtBr.messages.confirmed = field => "Campo de confirma\u00e7\u00e3o n\u00e3o confere";
MessagesPtBr.messages.credit_card = field => "Campo inv\u00e1lido";
MessagesPtBr.messages.decimal = field => "Campo pode conter somente n\u00fameros e ponto";
MessagesPtBr.messages.default = field => "Campo inv\u00e1lido";
MessagesPtBr.messages.digits = (field, [length]) =>
    `Campo deve ser numérico e conter exatamente ${length} d\u00edgitos`;
MessagesPtBr.messages.dimensions = (field, [width, height]) =>
    `Campo deve ter ${width} pixels de largura por ${height} pixels de altura`;
MessagesPtBr.messages.email = field => "E-mail inv\u00e1lido";
MessagesPtBr.messages.ext = field => "Arquivo inv\u00e1lido";
MessagesPtBr.messages.image = field => "Campo deve ser uma imagem";
MessagesPtBr.messages.in = field => "Valor inv\u00e1lido";
MessagesPtBr.messages.ip = field => "IP inv\u00e1lido";
MessagesPtBr.messages.max = (field, [length]) => `Campo n\u00e3o deve ter mais que ${length} caracteres`;
MessagesPtBr.messages.max_value = (field, [max]) => `Campo precisa ser ${max} ou menor`;
MessagesPtBr.messages.mimes = field => "Campo deve ser um tipo de arquivo v\u00e1lido";
MessagesPtBr.messages.min = (field, [length]) => `Campo deve ter pelo menos ${length} caracteres`;
MessagesPtBr.messages.min_value = (field, [min]) => `Campo precisa ser ${min} ou maior`;
MessagesPtBr.messages.numeric = field => "Campo deve conter apenas n\u00fameros";
MessagesPtBr.messages.regex = field => "Formato do campo \u00e9 inv\u00e1lido";
MessagesPtBr.messages.required = field => "Campo obrigat\u00f3rio";
MessagesPtBr.messages.size = (field, [size]) => `Campo deve ser menor que ${size} KB`;
MessagesPtBr.messages.url = field => "URL inv\u00e1lida";

MessagesPtBr.messages.already_exists = field => "Valor j\u00e1 existe na base de dados";
MessagesPtBr.messages.maior_que_zero = field => "Campo deve ser maior que zero";
MessagesPtBr.messages.maior_igual_a_zero = field => "Campo deve ser maior ou igual \u00e0 zero";
MessagesPtBr.messages.not_exists = field => "Valor n\u00e3o existe na base de dados";
MessagesPtBr.messages.passphrase = field => "Senha deve conter ao menos 5 caracteres";
MessagesPtBr.messages.unexpected = () => "Ocorreu um erro inesperado";

Validator.localize("pt_BR", MessagesPtBr);

Validator.extend("passphrase", {
    getMessage: field => MessagesPtBr.passphrase(field),
    validate: value => value == null || value.length >= 5,
});

Validator.extend("maior_que_zero", {
    getMessage: field => MessagesPtBr.messages.maior_que_zero(field),
    validate: value => {
        if (value == null) return true;

        value = value.toString().replace(/\,/g, ".");
        return value > 0;
    },
});

Validator.extend("maior_igual_a_zero", {
    getMessage: field => MessagesPtBr.messages.maior_igual_a_zero(field),
    validate: value => value == null || value >= 0,
});

Validator.extend("rg", {
    getMessage: field => "RG inv\u00e1lido",
    validate: value => {
        if (value == null) return true;

        // Remove todos os caracteres exceto dígitos
        value = value.replace(/[^\d]/g, "");

        return value.length == 0 || value.length == 10;
    },
});

Validator.extend("cpf", {
    getMessage: field => "CPF inv\u00e1lido",
    validate: value => {
        if (value == null) return true;

        // Remove todos os caracteres exceto dígitos
        value = value.replace(/[^\d]/g, "");

        if (value.length == 0) return true;

        if (value.length != 11) return false;

        // Elimina CPFs invalidos conhecidos
        if (
            value == "00000000000" ||
            value == "11111111111" ||
            value == "22222222222" ||
            value == "33333333333" ||
            value == "44444444444" ||
            value == "55555555555" ||
            value == "66666666666" ||
            value == "77777777777" ||
            value == "88888888888" ||
            value == "99999999999"
        )
            return false;

        // Valida 1o dígito verificador
        let add = 0;

        for (let i = 0; i < 9; i++) {
            add += parseInt(value.charAt(i)) * (10 - i);
        }

        let rev = 11 - (add % 11);

        if (rev == 10 || rev == 11) rev = 0;

        if (rev != parseInt(value.charAt(9))) return false;

        // Valida 2o dígito verificador
        add = 0;

        for (let i = 0; i < 10; i++) {
            add += parseInt(value.charAt(i)) * (11 - i);
        }

        rev = 11 - (add % 11);

        if (rev == 10 || rev == 11) rev = 0;

        if (rev != parseInt(value.charAt(10))) return false;

        return true;
    },
});

Validator.extend("cnpj", {
    getMessage: field => "CNPJ inv\u00e1lido",
    validate: value => {
        if (value == null) return true;

        // Remove todos os caracteres exceto dígitos
        value = value.replace(/[^\d]/g, "");

        if (value.length == 0) return true;

        if (value.length != 14) return false;

        // Elimina CNPJs inválidos conhecidos
        if (
            value == "00000000000000" ||
            value == "11111111111111" ||
            value == "22222222222222" ||
            value == "33333333333333" ||
            value == "44444444444444" ||
            value == "55555555555555" ||
            value == "66666666666666" ||
            value == "77777777777777" ||
            value == "88888888888888" ||
            value == "99999999999999"
        )
            return false;

        // Valida os dígitos verificadores
        let tamanho = value.length - 2;
        let numeros = value.substring(0, tamanho);
        const digitos = value.substring(tamanho);
        let soma = 0;
        let pos = tamanho - 7;

        for (let i = tamanho; i >= 1; i--) {
            soma += +numeros.charAt(tamanho - i) * pos--;

            if (pos < 2) pos = 9;
        }

        let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);

        if (resultado.toString() != digitos.charAt(0)) return false;

        tamanho = tamanho + 1;
        numeros = value.substring(0, tamanho);
        soma = 0;
        pos = tamanho - 7;

        for (let i = tamanho; i >= 1; i--) {
            soma += +numeros.charAt(tamanho - i) * pos--;

            if (pos < 2) pos = 9;
        }

        resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);

        if (resultado != +digitos.charAt(1)) return false;

        return true;
    },
});

Validator.extend("inscricaoEstadual", {
    getMessage: field => "Inscri\u00e7\u00e3o estadual inv\u00e1lida",
    validate: value => /^[\d./-]+$/.test(value),
});

Validator.extend("cep", {
    getMessage: field => "CEP inv\u00e1lido",
    validate: value => {
        if (value == null) return true;

        value = value.replace(/[^\d]/g, "");
        return value.length == 0 || value.length == 8;
    },
});

Validator.extend("telefone", {
    getMessage: field => "Telefone inválido",
    validate: value => {
        if (value == null) return true;

        value = String(value).replace(/[^\d]/g, "");
        return value.length == 0 || value.length == 10 || value.length == 11;
    },
});

Validator.extend("data", {
    getMessage: () => "Data inv\u00e1lida",
    validate: (value: Date) => {
        if (value == null) {
            return true;
        }

        const valueString = value.toISOString().split("T")[0].replace(/[^\d]/g, "").replace(/[^\d]/g, "");

        return valueString.length == 0 || valueString.length == 8;
    },
});

Validator.extend("data-hora", {
    getMessage: field => "Data/hora inv\u00e1lida",
    validate: value => {
        if (value == null) return true;

        value = value.replace(/[^\d]/g, "");
        return value.length == 0 || value.length == 12;
    },
});

Validator.extend("hora", {
    getMessage: () => "Hora inv\u00e1lida",
    validate: value => {
        if (value == null) {
            return true;
        }

        if (value.length == 5) {
            const hora = value.split(":")[0];
            if (hora > 23) {
                return false;
            }
            const minuto = value.split(":")[1];
            if (minuto > 59) {
                return false;
            }
        }

        value = value.replace(/[^\d]/g, "");
        return value.length == 0 || value.length == 4;
    },
});
