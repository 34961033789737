var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "fieldset-component",
    { attrs: { title: "Capa SEI", collapsed: false } },
    [
      _c("div", { attrs: { slot: "rows" }, slot: "rows" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-sm-4" }, [
            _c("div", { staticClass: "form-group" }, [
              _c(
                "label",
                { staticClass: "control-label", attrs: { for: "tipo" } },
                [
                  _vm._v(
                    "\n                        " +
                      _vm._s(
                        _vm.$t(
                          "__.Components.child.form.sei_vue_html.tipoProcesso"
                        )
                      ) +
                      "\n                    "
                  ),
                ]
              ),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.model.tipoProcesso,
                    expression: "model.tipoProcesso",
                  },
                ],
                staticClass: "form-control",
                attrs: {
                  autocomplete: "off",
                  type: "text",
                  id: "tipoProcesso",
                  name: "tipoProcesso",
                },
                domProps: { value: _vm.model.tipoProcesso },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.model, "tipoProcesso", $event.target.value)
                  },
                },
              }),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-sm-4" }, [
            _c("div", { staticClass: "form-group" }, [
              _c(
                "label",
                {
                  staticClass: "control-label",
                  attrs: { for: "especificacoes" },
                },
                [
                  _vm._v(
                    "\n                        " +
                      _vm._s(
                        _vm.$t(
                          "__.Components.child.form.sei_vue_html.especificacoes"
                        )
                      ) +
                      "\n                    "
                  ),
                ]
              ),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.model.especificacoes,
                    expression: "model.especificacoes",
                  },
                ],
                staticClass: "form-control",
                attrs: {
                  autocomplete: "off",
                  type: "text",
                  id: "especificacoes",
                  name: "especificacoes",
                },
                domProps: { value: _vm.model.especificacoes },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.model, "especificacoes", $event.target.value)
                  },
                },
              }),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-sm-4" }, [
            _c("div", { staticClass: "form-group" }, [
              _c(
                "label",
                {
                  staticClass: "control-label",
                  attrs: { for: "classificacao" },
                },
                [
                  _vm._v(
                    "\n                        " +
                      _vm._s(
                        _vm.$t(
                          "__.Components.child.form.sei_vue_html.classificacao"
                        )
                      ) +
                      "\n                    "
                  ),
                ]
              ),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.model.classificacao,
                    expression: "model.classificacao",
                  },
                ],
                staticClass: "form-control",
                attrs: {
                  autocomplete: "off",
                  type: "text",
                  id: "classificacao",
                  name: "classificacao",
                },
                domProps: { value: _vm.model.classificacao },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.model, "classificacao", $event.target.value)
                  },
                },
              }),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-sm-4" }, [
            _c("div", { staticClass: "form-group" }, [
              _c(
                "label",
                { staticClass: "control-label", attrs: { for: "interessado" } },
                [
                  _vm._v(
                    "\n                        " +
                      _vm._s(
                        _vm.$t(
                          "__.Components.child.form.sei_vue_html.interessado"
                        )
                      ) +
                      "\n                    "
                  ),
                ]
              ),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.model.interessado,
                    expression: "model.interessado",
                  },
                ],
                staticClass: "form-control",
                attrs: {
                  autocomplete: "off",
                  type: "text",
                  id: "interessado",
                  name: "interessado",
                },
                domProps: { value: _vm.model.interessado },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.model, "interessado", $event.target.value)
                  },
                },
              }),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-sm-4" }, [
            _c("div", { staticClass: "form-group" }, [
              _c(
                "label",
                { staticClass: "control-label", attrs: { for: "destino" } },
                [
                  _vm._v(
                    "\n                        " +
                      _vm._s(
                        _vm.$t("__.Components.child.form.sei_vue_html.destino")
                      ) +
                      "\n                    "
                  ),
                ]
              ),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.model.destino,
                    expression: "model.destino",
                  },
                ],
                staticClass: "form-control",
                attrs: {
                  autocomplete: "off",
                  type: "text",
                  id: "destino",
                  name: "destino",
                },
                domProps: { value: _vm.model.destino },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.model, "destino", $event.target.value)
                  },
                },
              }),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-sm-4" }, [
            _c("div", { staticClass: "form-group" }, [
              _c(
                "label",
                { staticClass: "control-label", attrs: { for: "observacoes" } },
                [
                  _vm._v(
                    "\n                        " +
                      _vm._s(
                        _vm.$t(
                          "__.Components.child.form.sei_vue_html.obsUnidade"
                        )
                      ) +
                      "\n                    "
                  ),
                ]
              ),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.model.observacoes,
                    expression: "model.observacoes",
                  },
                ],
                staticClass: "form-control",
                attrs: {
                  autocomplete: "off",
                  type: "text",
                  id: "observacoes",
                  name: "observacoes",
                },
                domProps: { value: _vm.model.observacoes },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.model, "observacoes", $event.target.value)
                  },
                },
              }),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-sm-4" }, [
            _c("div", { staticClass: "form-group" }, [
              _c(
                "label",
                { staticClass: "control-label", attrs: { for: "endereco" } },
                [
                  _vm._v(
                    "\n                        " +
                      _vm._s(
                        _vm.$t("__.Components.child.form.sei_vue_html.endereco")
                      ) +
                      "\n                    "
                  ),
                ]
              ),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.model.endereco,
                    expression: "model.endereco",
                  },
                ],
                staticClass: "form-control",
                attrs: {
                  autocomplete: "off",
                  type: "text",
                  id: "endereco",
                  name: "endereco",
                },
                domProps: { value: _vm.model.endereco },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.model, "endereco", $event.target.value)
                  },
                },
              }),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-sm-4" }, [
            _c("div", { staticClass: "form-group" }, [
              _c(
                "label",
                {
                  staticClass: "control-label",
                  attrs: { for: "numeroProcessoAnterior" },
                },
                [
                  _vm._v(
                    "\n                        " +
                      _vm._s(
                        _vm.$t(
                          "__.Components.child.form.sei_vue_html.numProcesso"
                        )
                      ) +
                      "\n                    "
                  ),
                ]
              ),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.model.numeroProcessoAnterior,
                    expression: "model.numeroProcessoAnterior",
                  },
                ],
                staticClass: "form-control",
                attrs: {
                  autocomplete: "off",
                  type: "text",
                  id: "numeroProcessoAnterior",
                  name: "numeroProcessoAnterior",
                },
                domProps: { value: _vm.model.numeroProcessoAnterior },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.model,
                      "numeroProcessoAnterior",
                      $event.target.value
                    )
                  },
                },
              }),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-sm-4" }, [
            _c("div", { staticClass: "form-group" }, [
              _c(
                "label",
                {
                  staticClass: "control-label",
                  attrs: { for: "outrasInformacoes" },
                },
                [
                  _vm._v(
                    "\n                        " +
                      _vm._s(
                        _vm.$t(
                          "__.Components.child.form.sei_vue_html.outrasInfo"
                        )
                      ) +
                      "\n                    "
                  ),
                ]
              ),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.model.outrasInformacoes,
                    expression: "model.outrasInformacoes",
                  },
                ],
                staticClass: "form-control",
                attrs: {
                  autocomplete: "off",
                  type: "text",
                  id: "outrasInformacoes",
                  name: "outrasInformacoes",
                },
                domProps: { value: _vm.model.outrasInformacoes },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.model,
                      "outrasInformacoes",
                      $event.target.value
                    )
                  },
                },
              }),
            ]),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }