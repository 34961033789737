import Vue from "vue";

import cnpjComponent from "@/components/child/form/cnpj.vue";
import cpfComponent from "@/components/child/form/cpf.vue";
import fieldsetComponent from "@/components/child/form/fieldset.vue";
import moedaComponent from "@/components/child/form/moeda.vue";
import gridComponent from "@/components/child/grid/grid.vue";
import { GridColumn, GridColumnType } from "@/components/child/grid/gridColumn";
import { Component } from "@/decorators";
import NotaFiscalEletronicaEmissaoItemModel from "@/models/notaFiscalEletronicaEmissaoItemModel";
import NotaFiscalEletronicaEmissaoModel from "@/models/notaFiscalEletronicaEmissaoModel";
import NotaFiscalEletronicaEmissaoService from "@/services/emissaoNota/notaFiscalEletronicaEmissaoService";
import ValidationErrorWrapper from "@/wrappers/validationErrorWrapper";

import ShortcutComponent from "../../shortcut/shortcut";
import shortcutComponent from "../../shortcut/shortcut.vue";

import enderecoComponent from "./endereco.vue";
import ItemComponent from "./item";
import itemComponent from "./item.vue";

import "../../crud/crud.scss";

@Component({
    components: {
        gridComponent,
        moedaComponent,
        fieldsetComponent,
        cpfComponent,
        cnpjComponent,
        enderecoComponent,
        shortcutComponent,
        itemComponent,
    },
})
export default class ResumoComponent extends Vue {
    private service = new NotaFiscalEletronicaEmissaoService();
    private validationErrorWrapper = new ValidationErrorWrapper(this.$validator);

    private shortcutComponent: ShortcutComponent = null;
    private itemComponent: ItemComponent = null;

    private model = null;
    private loaded = false;
    private gridData = [];

    get gridColumns(): Array<GridColumn> {
        return [
            new GridColumn("item", "Nº", GridColumnType.Integer),
            new GridColumn("xProd", "Produto", GridColumnType.String),
            new GridColumn("NCM", "NCM", GridColumnType.String),
            new GridColumn("origem", "Origem", GridColumnType.String),
            new GridColumn("qCom", "Qtd.", GridColumnType.Integer),
            new GridColumn("uCom", "Un. Med", GridColumnType.String),
            new GridColumn("vUnCom", "Unitário", GridColumnType.Money),
            new GridColumn("vDesc", "Desconto", GridColumnType.Money),
            new GridColumn("vProd", "Valor Produto", GridColumnType.Money),
        ];
    }

    get gridColumnsVinculos(): Array<GridColumn> {
        return [
            new GridColumn("imposto", "Imposto", GridColumnType.String),
            new GridColumn("cst", "CST", GridColumnType.String),
            new GridColumn("base", "Base", GridColumnType.Money),
            new GridColumn("aliquota", "%", GridColumnType.Decimal),
            new GridColumn("valor", "Valor", GridColumnType.Money),
        ];
    }

    private conditionShowSubData(it: NotaFiscalEletronicaEmissaoItemModel) {
        return it["impostos"]["length"] > 0;
    }

    public async load(nota: NotaFiscalEletronicaEmissaoModel) {
        try {
            const response = await this.service.resumoNotaFiscal(nota).withLoading();
            const data = await response.json();
            if (!response.ok) throw data;

            this.model = data.enviNFe.NFe.infNFe;
            Object.keys(this.model.total.ICMSTot).forEach(p => {
                this.model.total.ICMSTot[p] = Number(this.model.total.ICMSTot[p]);
            });

            this.gridData = this.model.det.length > 0 ? this.model.det : [this.model.det];
            this.gridData = this.gridData.map(p => ({
                ...p.prod,
                item: p["@nItem"],
                origem: this.getProp(p.imposto.ICMS).orig,
                impostos: [
                    {
                        ...this.getImposto(p, "ICMS"),
                    },
                    {
                        ...this.getImposto(p, "IPI"),
                    },
                    {
                        ...this.getImposto(p, "PIS"),
                    },
                    {
                        ...this.getImposto(p, "COFINS"),
                    },
                ],
            }));

            this.loaded = true;
            return true;
        } catch (err) {
            if (err) await this.validationErrorWrapper.showErrorsAsync(err);
            return false;
        }
    }

    private getImposto(p: any, imposto: string) {
        if (!p.imposto[imposto]) return { imposto };

        let cst = null;
        if (imposto == "ICMS") {
            cst = this.getProp(p.imposto[imposto]).CST
                ? this.getProp(p.imposto[imposto]).CST
                : this.getProp(p.imposto[imposto]).CSOSN;
        } else {
            cst = this.getProp(p.imposto[imposto]).CST;
        }

        return {
            imposto,
            cst,
            base: this.getNumber(this.getProp(p.imposto[imposto]).vBC),
            aliquota: this.getNumber(this.getProp(p.imposto[imposto])[`p${imposto}`]),
            valor: this.getNumber(this.getProp(p.imposto[imposto])[`v${imposto}`]),
        };
    }

    private getProp(value: any) {
        const key = Object.keys(value);
        if (typeof value[key[0]] == "object") return value[key[0]];
        return value[key[1]];
    }

    private getNumber(value: any) {
        if (value) return Number(value);
        return 0;
    }

    private onEditItem(data) {
        this.shortcutComponent = this.$refs.shortcutComponent as ShortcutComponent;
        this.itemComponent = this.$refs.itemComponent as ItemComponent;

        this.shortcutComponent.show();
        this.shortcutComponent.title = "Resumo da emissão de Nota Fiscal - Item";
        const itens = this.model.det.length > 0 ? this.model.det : [this.model.det];
        this.itemComponent.setData(itens.filter(p => p["@nItem"] == data.item)[0]);
    }

    private mounted() {
        this.shortcutComponent = this.$refs.shortcutComponent as ShortcutComponent;
        this.itemComponent = this.$refs.itemComponent as ItemComponent;
    }
}
