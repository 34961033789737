import BaseModel from './base/baseModel';
import DevolucaoItemModel from './devolucaoItemModel';
import MovimentacaoEstoqueModel from './movimentacaoEstoqueModel';
import NotaFiscalEntradaModel from './notaFiscalEntradaModel';
import NotaFiscalSaidaModel from './notaFiscalSaidaModel';

export default class DevolucaoModel extends BaseModel {
    id: number = null;
    dataDevolucao: Date = null;
    tipoDevolucao = 0;
    notaFiscalEntradaOrigemId: number = null;
    notaFiscalEntradaOrigem: NotaFiscalEntradaModel = new NotaFiscalEntradaModel();
    notaFiscalSaidaDevolucaoId: number = null;
    notaFiscalSaidaDevolucao: NotaFiscalSaidaModel = new NotaFiscalSaidaModel();

    movimentacaoEstoqueId: number = null;
    movimentacaoEstoque: MovimentacaoEstoqueModel = new MovimentacaoEstoqueModel();
    itens: DevolucaoItemModel[] = [];
}