import Vue from "vue";

import dataTooltipComponent from "@/components/child/form/datatooltip.vue";
import gridComponent from "@/components/child/grid/grid.vue";
import { GridColumn, GridColumnType } from "@/components/child/grid/gridColumn";
import loadingModalComponent from "@/components/child/loadingmodal/loadingmodal.vue";
import ShortcutComponent from "@/components/parent/shortcut/shortcut";
import shortcutComponent from "@/components/parent/shortcut/shortcut.vue";
import { Component } from "@/decorators";
import FranquiaModel, { FranquiaListParameters } from "@/models/franquiaModel";
import PaginationModel from "@/models/paginationModel";
import FranquiaService from "@/services/franquiaService";

import "../crud.scss";

@Component({
    components: {
        loadingModalComponent,
        shortcutComponent,
        dataTooltipComponent,
        gridComponent,
    },
})
export default class ReplicarSelecaoFranquiaComponent extends Vue {
    private shortcutComponent: ShortcutComponent = null;
    private showFranquias = false;
    private service: FranquiaService = new FranquiaService();

    gridFilterKey = "";
    gridSortKey = "nomeFantasia";
    gridSortOrder = "asc";
    gridData: Array<FranquiaModel> = [];
    gridSelectedValues: Array<FranquiaModel> = [];
    get gridColumns(): Array<GridColumn> {
        return [
            new GridColumn("nomeFantasia", "Nome Fantasia", GridColumnType.String),
            new GridColumn("cnpj", "Cnpj", GridColumnType.String),
        ];
    }

    pageIndex = 1;
    pageSize = 999999;
    total = 0;

    constructor() {
        super();
    }

    private onConfirm() {
        if (this.gridSelectedValues.length > 0) {
            this.$emit(
                "replicar",
                this.gridSelectedValues.map(p => p.id),
            );
            this.shortcutComponent.hide();
        } else this.shortcutComponent.hide();
    }

    public openFranquias() {
        if (!this.showFranquias) this.load();

        this.showFranquias = true;
        this.shortcutComponent.show();
    }

    private load() {
        const customParameters: FranquiaListParameters = { ignoreCurrentFranquia: true };
        this.service
            .list(
                this.gridFilterKey,
                this.gridSortKey,
                this.gridSortKey,
                this.pageIndex,
                this.pageSize,
                customParameters,
            )
            .withLoading()
            .then(response => {
                return response.json() as Promise<PaginationModel<FranquiaModel>>;
            })
            .then(data => {
                this.gridData = data.list;
                this.total = data.total;
            })
            .catch(() => {});
    }

    private onChangeFilterKey(filterKey: string) {
        this.gridFilterKey = filterKey;
        this.load();
    }

    private onChangeSort(sortKey: string, sortOrder: string) {
        this.gridSortKey = sortKey;
        this.gridSortOrder = sortOrder;
        this.load();
    }

    private onChangePage(pageIndex: number) {
        this.pageIndex = pageIndex;
        this.load();
    }

    private onSelectedValuesChanged(values: Array<FranquiaModel>) {
        this.gridSelectedValues = values;
    }

    private mounted() {
        this.shortcutComponent = this.$refs.shortcutComponent as ShortcutComponent;
        this.shortcutComponent.title = this.$t("__.ts.selecFranq").toString();
    }
}
