import VueI18n from "vue-i18n";

export interface GridColumnParameters {
    value: string;
    text: string | VueI18n.TranslateResult;
    type: GridColumnType;

    // optional
    editable?: boolean;
    hidden?: boolean;
    validation?: string;
    parent?: string;
    searchbleColorControl?: boolean;
    showSortIcon?: boolean;
    comboOptions?: (x: Object) => Array<Object>;
    onChange?: (x: any, index?: number, columnIndex?: number) => void;
    casasDecimais?: number;
    disabled?: boolean;
    defaultValueProp?: string;
}

export class GridColumn implements GridColumnParameters {
    constructor(
        value: string,
        text: string | VueI18n.TranslateResult,
        type: GridColumnType,
        editable = false,
        hidden = false,
        validation = "",
        parent = "",
        searchbleColorControl = false,
        showSortIcon = true,
        casasDecimais = 2,
        disabled = false,
    ) {
        this.value = value;
        this.text = text;
        this.type = type;
        this.editable = editable;
        this.hidden = hidden;
        this.validation = validation;
        this.parent = parent;
        this.searchbleColorControl = searchbleColorControl;
        this.showSortIcon = showSortIcon;
        this.minimumFractionDigits = casasDecimais;
        this.disabled = disabled;
    }

    value: string;
    text: string | VueI18n.TranslateResult;
    type: GridColumnType;
    editable: boolean;
    hidden: boolean;
    validation: string;
    parent: string;
    searchbleColorControl: boolean;
    showSortIcon: boolean;
    minimumFractionDigits: number;
    disabled: boolean;
}

export class GridColumnDecimal extends GridColumn {
    constructor(value: string, text: string | VueI18n.TranslateResult, casasDecimais = 2) {
        super(value, text, GridColumnType.Decimal, false, false, "", "", false, false, casasDecimais, false);
    }
}

export class GridColumnSearchColor extends GridColumn {
    constructor(value: string, text: string | VueI18n.TranslateResult, type: GridColumnType, showSortIcon = true) {
        super(value, text, type, false, false, "", "", true, showSortIcon);
    }
}

export class GridColumnExt extends GridColumn {
    constructor(params: GridColumnParameters) {
        super(
            params.value,
            params.text,
            params.type,
            params.editable,
            params.hidden,
            params.validation,
            params.parent,
            params.searchbleColorControl,
            params.showSortIcon,
            params.casasDecimais,
            params.disabled,
        );
        this.comboOptions = params.comboOptions;
        this.onChange = params.onChange;
        this.defaultValueProp = params.defaultValueProp;
    }

    comboOptions: (x: Object) => Array<Object>;
    onChange?: (x: any, index?: number) => void;
    defaultValueProp?: string;
}

export enum GridColumnType {
    String = 0,
    Boolean = 1,
    Integer = 2,
    Decimal = 3,
    Money = 4,
    Date = 5,
    DateTime = 6,
    MoneyWithSignal = 7,
    Percent = 8,
    Money4Digits = 9,
    Combo = 10,
    DecimalPonto = 11,
    IntegerPonto = 13,
    Period = 14,
    Raw = 15,
}

export const GridColors = {
    DARKBLUE: "#3b5999",
    BLUE: "#318b9a",
    DARKGRAY: "#7f7f7f",
    GRAY: "#a8a8a8",
    GREEN: "#65bb65",
    LIGHTGREEN: "#8bc53d",
    ORANGE: "#f48a06",
    RED: "#b82e24",
    LIGHTRED: "#df7a7a",
    YELLOW: "#e4e41e",
};
