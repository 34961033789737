import Moment from "moment";

const propNamesAsString = ["descricao", "nome", "produtoDescricao", "produtoLoteDescricao"];

export const copyObject = (
    objectfrom: any,
    objectTo: any,
    ignoredProperties = new Array<string>(),
    ignoreEmptyProperties = false,
    objectReference?: any,
) => {
    for (const propName in objectfrom) {
        const object = objectReference ? objectReference : objectTo;

        if (
            !object.hasOwnProperty(propName) ||
            (ignoredProperties.length > 0 && ignoredProperties.indexOf(propName) != -1)
        )
            continue;

        const value = objectfrom[propName];

        if (ignoreEmptyProperties && !value) continue;

        if (object[propName] && typeof object[propName] === "object" && object[propName]["updateFrom"]) {
            object[propName].updateFrom(value, ignoredProperties, ignoreEmptyProperties, object[propName]);
        } else if (object[propName] && typeof object[propName] === "object" && Array.isArray(value)) {
            for (const valName in value) {
                const val = value[valName];
                if (value[valName] && typeof value[valName] === "object") {
                    const valIgnoredProperties = new Array<string>();
                    copyObject(
                        value[valName],
                        value[valName],
                        valIgnoredProperties,
                        ignoreEmptyProperties,
                        value[valName],
                    );
                } else {
                    if (
                        isNaN(val) &&
                        Moment(val, Moment.ISO_8601, true).isValid() &&
                        propNamesAsString.filter(p => p == propName).length == 0
                    )
                        value[valName] = new Date(val);
                    else value[valName] = val;
                }
            }
            object[propName] = value;
        } else if (
            isNaN(value) &&
            Moment(value, Moment.ISO_8601, true).isValid() &&
            propNamesAsString.filter(p => p == propName).length == 0
        )
            object[propName] = new Date(value);
        else object[propName] = value;
    }
};

// Funções para fazer merge de objetos
// ===================================

function mergeAny<Ta, Tb = Partial<Ta>>(a: Ta, b: Tb) {
    return { ...a, ...b };
}

/** Retorna uma cópia do objeto `obj` sobrescrita com as propriedades **explicitamente definidas** no objeto `partial`. */
export function merge<T>(obj: T, partial: Partial<T>): T {
    return mergeAny(obj, partial);
}

/** Retorna uma cópia do objeto `partialA` sobrescrita com as propriedades **explicitamente definidas** no objeto `partialB`. */
export function mergePartial<T>(partialA: Partial<T>, partialB: Partial<T>): Partial<T> {
    return mergeAny(partialA, partialB);
}

export function isEqual(obj1, obj2): boolean {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
}
