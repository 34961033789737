import nfetch from "../../utils/events/nfetch";
import { getCookie as getCookieInternal, mensagemErro as mensagemErroInternal } from "../utils/serviceUtils";

export abstract class IService<T> {
    protected controllerName: string;

    constructor(controllerName: string) {
        this.controllerName = controllerName;
    }

    public list(
        filterKey: string,
        sortKey: string,
        sortOrder: string,
        pageIndex: number,
        pageSize: number,
        customProperties: { [key: string]: any } = {},
    ): Promise<Response> {
        const encodedFilter = encodeURIComponent(filterKey);
        let custom = "";
        for (const key in customProperties) {
            if (customProperties[key] != undefined) {
                custom += `&${key}=${customProperties[key]}`;
            }
        }
        return nfetch(
            `/${this.controllerName}/List?filterKey=${encodedFilter}&sortKey=${sortKey}&sortOrder=${sortOrder}&pageIndex=${pageIndex}&pageSize=${pageSize}` +
                custom,
            {
                credentials: "same-origin",
            },
        );
    }

    public combo(): Promise<Response> {
        return nfetch(`/${this.controllerName}/List?`, {
            credentials: "same-origin",
        });
    }

    public get(id: number): Promise<Response> {
        //@ts-ignore
        return nfetch(`/${this.controllerName}/Get?id=${id}`, {
            credentials: "same-origin",
        })
            .then((response: Response) => {
                if (response.ok) {
                    return response;
                } else {
                    return this.mensagemErro(response, "Get");
                }
            })
            .catch(() => {});
    }

    public delete(id: number): Promise<Response> {
        //@ts-ignore
        return nfetch(`/${this.controllerName}/Delete?id=${id}`, {
            method: "post",
            credentials: "same-origin",
            headers: {
                "XSRF-TOKEN": this.getCookie("XSRF-TOKEN"),
            },
        })
            .then((response: Response) => {
                if (response.ok) {
                    return response;
                } else {
                    return this.mensagemErro(response, "Delete");
                }
            })
            .catch(() => {});
    }

    public insert(model: T): Promise<Response> {
        return this.save(model, "Insert");
    }

    public update(model: T): Promise<Response> {
        return this.save(model, "Update");
    }

    public validateModel(model: T): Promise<Response> {
        return nfetch(`/${this.controllerName}/ValidateModel`, {
            method: "post",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json; charset=UTF-8",
                "XSRF-TOKEN": this.getCookie("XSRF-TOKEN"),
            },
            body: JSON.stringify(model),
        });
    }

    private save(model: T, methodName: string): Promise<Response> {
        //@ts-ignore
        return nfetch(`/${this.controllerName}/${methodName}`, {
            method: "post",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json; charset=UTF-8",
                "XSRF-TOKEN": this.getCookie("XSRF-TOKEN"),
            },
            body: JSON.stringify(model),
        })
            .then((response: Response) => {
                if (response.ok) {
                    return response;
                } else {
                    return this.mensagemErro(response, "Save");
                }
            })
            .catch(() => {});
    }

    public getCookie(name: string): string {
        return getCookieInternal(name);
    }

    public mensagemErro(erro: Response, metodo: string) {
        return mensagemErroInternal(erro, metodo);
    }
}
