import Vue from "vue";
import { mapGetters } from "vuex";

import Grid from "@/components/child/grid/grid";
import gridComponent from "@/components/child/grid/grid.vue";
import { GridAction } from "@/components/child/grid/gridAction";
import { GridColors, GridColumn, GridColumnType } from "@/components/child/grid/gridColumn";
import TentativasEntregaComponent from "@/components/parent/expedicao/tentativasEntrega";
import tentativasEntregaComponent from "@/components/parent/expedicao/tentativasEntrega.vue";
import ShortcutComponent from "@/components/parent/shortcut/shortcut";
import shortcutComponent from "@/components/parent/shortcut/shortcut.vue";
import { Component, Prop, Watch } from "@/decorators";
import SituacoesVenda from "@/models/enum/situacoesVenda";
import PaginationModel from "@/models/paginationModel";
import VendaModel, { VendaListParameters } from "@/models/vendaModel";
import VendaService from "@/services/vendaService";
import { Getters } from "@/store/store";

import "../crud.scss";

@Component({
    components: {
        gridComponent,
        shortcutComponent,
        tentativasEntregaComponent,
    },
    computed: {
        ...mapGetters(["VALIDAR_PERMISSAO_USUARIO"] as Getters),
    },
})
export default class VendasClienteListComponent extends Vue {
    // State computed props
    VALIDAR_PERMISSAO_USUARIO: (telaDescricao: string, acaoController: string) => boolean;

    @Prop({ type: Boolean, default: false }) openedFromVenda: boolean;

    private service = new VendaService();
    private clienteId = 0;
    private gridcomponent: Grid = null;
    private shortcutComponent: ShortcutComponent = null;
    private tentativasEntregaComponent: TentativasEntregaComponent = null;

    gridFilterKey = "";
    gridSortKey = "dataaprovacao";
    gridSortOrder = "asc";
    gridData: Array<VendaModel> = [];

    get gridColumns(): Array<GridColumn> {
        return [
            new GridColumn("codigo", "Código", GridColumnType.String, false, false, "", "", true),
            new GridColumn("situacaoDescricao", "Situação", GridColumnType.String, false, false, "", "", true),
            new GridColumn("listaItensDescricao", "Descrição", GridColumnType.String, false, false, "", "", true),
            new GridColumn("dataInclusao", "Data", GridColumnType.Date, false, false, "", "", true),
            new GridColumn("valorFinal", "Valor", GridColumnType.Money, false, false, "", "", true),
            new GridColumn(
                "quantidadeTentativas",
                "Tentativas entrega",
                GridColumnType.Integer,
                false,
                false,
                "",
                "",
                true,
            ),
        ];
    }

    extraActions: Array<object> = [];
    gridExtraActionsVisualizar: Array<GridAction> = [];
    gridExtraActionsImprimir: Array<GridAction> = [];
    gridExtraActionsRepetir: Array<GridAction> = [];
    gridExtraActionsTentativas: Array<GridAction> = [];

    pageIndex = 1;
    pageSize = 10;
    total = 0;

    pags = false;
    tipoDocGerar: number = null;

    public async load() {
        this.tentativasEntregaComponent = this.$refs.tentativasEntregaComponent as TentativasEntregaComponent;

        try {
            const customParameters: VendaListParameters = {
                clienteId: this.clienteId,
            };

            const data = await this.service
                .listVendasByCliente(
                    this.gridFilterKey,
                    this.gridSortKey,
                    this.gridSortOrder,
                    this.pags ? 1 : this.pageIndex,
                    this.pags ? 999999 : this.pageSize,
                    customParameters,
                )
                .withLoading()
                .resolveWithJSON<PaginationModel<VendaModel>>();

            this.gridData = data.list;
            this.total = data.total;
            this.pageIndex = data.pageIndex;
            this.pageSize = data.pageSize;

            this.gridExtraActionsVisualizar.splice(0);
            this.gridExtraActionsRepetir.splice(0);
            this.gridExtraActionsTentativas.splice(0);
            this.extraActions.splice(0);

            for (let i = 0; i < this.gridData.length; i++) {
                if (this.VALIDAR_PERMISSAO_USUARIO("vendas", "get")) {
                    this.gridExtraActionsVisualizar.push(
                        new GridAction(
                            "visualizar-venda",
                            this.$t("__.ts.visuVenda").toString(),
                            "fa fa-search",
                            GridColors.BLUE,
                        ),
                    );
                }

                if (this.VALIDAR_PERMISSAO_USUARIO("vendas", "CopiarVenda") && !this.openedFromVenda) {
                    this.gridExtraActionsRepetir.push(
                        new GridAction(
                            "repetir-venda",
                            this.$t("__.ts.repEstaVenda").toString(),
                            "fa fa-copy",
                            GridColors.DARKBLUE,
                            this.gridData[i].situacao != SituacoesVenda.Cancelado,
                        ),
                    );
                }

                this.gridExtraActionsTentativas.push(
                    new GridAction(
                        "tentativa-entrega",
                        this.$t("__.ts.tentativasEntrega").toString(),
                        "fa fa-truck",
                        GridColors.GREEN,
                    ),
                );
            }

            this.extraActions.push(this.gridExtraActionsVisualizar);
            this.extraActions.push(this.gridExtraActionsRepetir);
            this.extraActions.push(this.gridExtraActionsTentativas);
            if (this.pags) {
                if (this.tipoDocGerar == 0) {
                    setTimeout(() => {
                        this.gridcomponent.gerarXls();
                    }, 2000);
                }

                if (this.tipoDocGerar == 1) {
                    setTimeout(() => {
                        this.gridcomponent.gerarPdf();
                    }, 2000);
                }

                if (this.tipoDocGerar == 2) {
                    setTimeout(() => {
                        this.gridcomponent.gerarImpressao();
                    }, 2000);
                }

                setTimeout(() => {
                    this.pags = false;
                }, 2000);
            }
        } catch {}
    }

    private onChangeFilterKey(filterKey: string) {
        this.gridFilterKey = filterKey;
        this.load();
    }

    private onChangeSort(sortKey: string, sortOrder: string) {
        this.gridSortKey = sortKey;
        this.gridSortOrder = sortOrder;
        this.load();
    }

    private onChangePage(pageIndex: number) {
        this.pageIndex = pageIndex;
        this.load();
    }

    private onExtraAction(name: string, model: VendaModel) {
        if (name.trim() == "visualizar-venda") {
            window.open("/venda-edicao/" + model.id, "_blank");
        } else if (name.trim() == "repetir-venda") {
            this.replicar(model.id);
        } else if (name.trim() == "tentativa-entrega") {
            this.tentativasEntregaComponent.show(model.id);
        }
    }

    private async replicar(vendaId: number) {
        try {
            const data = await this.service.replicarOrcamento(vendaId).withLoading().resolveWithJSON<VendaModel>();
            if (data.id) {
                this.$router.push("/venda-edicao/" + data.id + "/true");
            }
        } catch {}
    }

    public async show(clienteId: number, clienteNome: string) {
        this.pageIndex = 1;

        this.clienteId = clienteId;

        this.shortcutComponent = this.$refs.shortcutComponent as ShortcutComponent;
        this.shortcutComponent.title = this.$t("__.ts.vendaHist").toString() + " " + clienteNome ?? "";
        this.shortcutComponent.show();

        this.load();
    }

    private onCreateItem() {
        this.$router.push({ name: "venda-edicao", params: { clienteId: this.clienteId.toString() } });
    }

    @Watch("pags")
    private onPags() {
        this.load();
    }

    private mounted() {
        this.gridcomponent = this.$refs.gridcomponent as Grid;
    }

    public onClose() {
        this.shortcutComponent.hide();
    }
}
