import VendaCancelamentoModel from "../models/vendaCancelamentoModel";
import nfetch from "../utils/events/nfetch";

import { IService } from "./base/iService";

export default class VendaCancelamentoService extends IService<VendaCancelamentoModel> {
    constructor() {
        super("VendaCancelamento");
    }

    public getVenda(id: number): Promise<Response> {
        return nfetch(`/${this.controllerName}/GetVenda?id=${id}`, {
            credentials: "same-origin"
        });
    }
}