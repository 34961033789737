import BaseModel from "./base/baseModel";
import Ufs from "./enum/ufs";

export default class ClienteEnderecoEntregaModel extends BaseModel {
    id?: number = null;
    clienteId?: number = null;
    cep: string = null;
    estadoId?: Ufs = null;
    cidadeId?: number = null;
    cidade: string = null;
    estado: string = null;
    endereco: string = null;
    bairro: string = null;
    numero: string = null;
    complemento: string = null;
    enderecoPadrao = false;
}
