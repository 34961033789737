import Dictionary from "../../types/dictionary";
import BaseModel from "../base/baseModel";

import ContaPagarImportacaoModel from "./contaPagarImportacaoModel";
import NotaFiscalEletronicaModel from "./notaFiscalEletronicaModel";

export default class NotaFiscalEletronicaImportacaoModel extends BaseModel {
    // por algum motivo, se NotaFiscalEletronicaModel for instanciada aqui, ocorre um erro ("Final loader didn't return a Buffer or String")
    // sempre que NotaFiscalEletronicaImportacaoModel for usada, NotaFiscalEletronicaModel deve ser instanciada no construtor de quem usar
    notaFiscal: NotaFiscalEletronicaModel = null; // new NotaFiscalEletronicaModel();
    contaPagar: ContaPagarImportacaoModel = new ContaPagarImportacaoModel();
    associacoes: Dictionary<number> = null;
    estoqueDestinoId = 0;
    atualizarFornecedor = true;
    gerarContaPagar = true;
    fracionarLotes = true;
    devolucao = false;
}
