import FranquiaModel from "../models/franquiaModel";
import nfetch from "../utils/events/nfetch";

import { IService } from "./base/iService";

export default class FranquiaAbrangenciaService extends IService<FranquiaModel> {
    constructor() {
        super("Franquia");
    }
    
    public combo(): Promise<Response> {
        return nfetch(`/${this.controllerName}/ListFranquiasForRede?ignoreCurrentFranquia=false&franquiasAbrangencia=true`, {
            credentials: "same-origin"
        })
    }
}