import { Component } from "@/decorators";
import ProdutoDcbModel from "@/models/produto/produtoDcbModel";
import ProdutoDcbService from "@/services/produto/produtoDcbService";

import EditComponentBase from "../editComponentBase";

@Component
export default class ProdutoDcbEditComponent extends EditComponentBase<ProdutoDcbModel> {
    private async beforeMount() {
        await this.setProps(new ProdutoDcbService(), "dcbs", new ProdutoDcbModel());
    }
}
