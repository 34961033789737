import { Module } from "vuex";

import { AppState } from "../store";

import actions from "./actions";
import mutations from "./mutations";
import { AtendimentoContextState } from "./types";

const atendimentoContext: Module<AtendimentoContextState, AppState> = {
    state: {
        atendimentos: [],
        atendimentosEncerrados: [],
        currentAtendimento: null,
        currentMessages: [],
        messagesAtendimentos: [],
        totalUnreadCount: 0,
    },
    actions,
    mutations,
};

export default atendimentoContext;
