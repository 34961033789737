var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.data
    ? _c("div", { staticClass: "crud crud-edit" }, [
        _c(
          "form",
          { attrs: { novalidate: "novalidate" } },
          [
            _c(
              "fieldset-component",
              {
                staticStyle: { "margin-top": "15px" },
                attrs: { title: "Produto", collapsed: false },
              },
              [
                _c("div", { attrs: { slot: "rows" }, slot: "rows" }, [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-sm-2" }, [
                      _c("div", { staticClass: "form-group" }, [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "codigoProduto" },
                          },
                          [_vm._v("Código")]
                        ),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.data.prod.cProd,
                              expression: "data.prod.cProd",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            type: "text",
                            id: "codigoProduto",
                            name: "codigoProduto",
                            disabled: "",
                          },
                          domProps: { value: _vm.data.prod.cProd },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.data.prod,
                                "cProd",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-sm-10" }, [
                      _c("div", { staticClass: "form-group" }, [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "descricaoProduto" },
                          },
                          [_vm._v("Descrição")]
                        ),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.data.prod.xProd,
                              expression: "data.prod.xProd",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            type: "text",
                            id: "descricaoProduto",
                            name: "descricaoProduto",
                            disabled: "",
                          },
                          domProps: { value: _vm.data.prod.xProd },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.data.prod,
                                "xProd",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-sm-2" }, [
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "control-label",
                              attrs: { for: "quantidadeProduto" },
                            },
                            [_vm._v("Quantidade")]
                          ),
                          _vm._v(" "),
                          _c("decimal-component", {
                            staticClass: "form-control",
                            attrs: {
                              id: "quantidadeProduto",
                              name: "quantidadeProduto",
                              disabled: "",
                            },
                            model: {
                              value: _vm.data.prod.qCom,
                              callback: function ($$v) {
                                _vm.$set(_vm.data.prod, "qCom", $$v)
                              },
                              expression: "data.prod.qCom",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-sm-2" }, [
                      _c("div", { staticClass: "form-group" }, [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "unidadeProduto" },
                          },
                          [_vm._v("Unidade")]
                        ),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.data.prod.uCom,
                              expression: "data.prod.uCom",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            type: "text",
                            id: "unidadeProduto",
                            name: "unidadeProduto",
                            disabled: "",
                          },
                          domProps: { value: _vm.data.prod.uCom },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.data.prod,
                                "uCom",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-sm-2" }, [
                      _c("div", { staticClass: "form-group" }, [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "cfopProduto" },
                          },
                          [_vm._v("CFOP")]
                        ),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.data.prod.CFOP,
                              expression: "data.prod.CFOP",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            type: "text",
                            id: "cfopProduto",
                            name: "cfopProduto",
                            disabled: "",
                          },
                          domProps: { value: _vm.data.prod.CFOP },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.data.prod,
                                "CFOP",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-sm-2" }, [
                      _c("div", { staticClass: "form-group" }, [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "ncmProduto" },
                          },
                          [_vm._v("NCM")]
                        ),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.data.prod.NCM,
                              expression: "data.prod.NCM",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            type: "text",
                            id: "ncmProduto",
                            name: "ncmProduto",
                            disabled: "",
                          },
                          domProps: { value: _vm.data.prod.NCM },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.data.prod,
                                "NCM",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-sm-4" }, [
                      _c("div", { staticClass: "form-group" }, [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "eanProduto" },
                          },
                          [_vm._v("EAN")]
                        ),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.data.prod.cEAN,
                              expression: "data.prod.cEAN",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            type: "text",
                            id: "eanProduto",
                            name: "eanProduto",
                            disabled: "",
                          },
                          domProps: { value: _vm.data.prod.cEAN },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.data.prod,
                                "cEAN",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-sm-2" }, [
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "control-label",
                              attrs: { for: "valorUnitarioProduto" },
                            },
                            [_vm._v("Valor Unitário")]
                          ),
                          _vm._v(" "),
                          _c("moeda-component", {
                            staticClass: "form-control",
                            attrs: {
                              readonly: "",
                              id: "valorUnitarioProduto",
                              name: "valorUnitarioProduto",
                              disabled: "",
                            },
                            model: {
                              value: _vm.data.prod.vUnCom,
                              callback: function ($$v) {
                                _vm.$set(_vm.data.prod, "vUnCom", $$v)
                              },
                              expression: "data.prod.vUnCom",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-sm-2" }, [
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "control-label",
                              attrs: { for: "valorDescontoProduto" },
                            },
                            [_vm._v("Valor Desconto")]
                          ),
                          _vm._v(" "),
                          _c("moeda-component", {
                            staticClass: "form-control",
                            attrs: {
                              id: "valorDescontoProduto",
                              name: "valorDescontoProduto",
                              disabled: "",
                            },
                            model: {
                              value: _vm.data.prod.vDesc,
                              callback: function ($$v) {
                                _vm.$set(_vm.data.prod, "vDesc", $$v)
                              },
                              expression: "data.prod.vDesc",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-sm-2" }, [
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "control-label",
                              attrs: { for: "valorFreteProduto" },
                            },
                            [_vm._v("Valor Frete")]
                          ),
                          _vm._v(" "),
                          _c("moeda-component", {
                            staticClass: "form-control",
                            attrs: {
                              id: "valorFreteProduto",
                              name: "valorFreteProduto",
                              disabled: "",
                            },
                            model: {
                              value: _vm.data.prod.vFrete,
                              callback: function ($$v) {
                                _vm.$set(_vm.data.prod, "vFrete", $$v)
                              },
                              expression: "data.prod.vFrete",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-sm-2" }, [
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "control-label",
                              attrs: { for: "valorSeguroProduto" },
                            },
                            [_vm._v("Valor Seguro")]
                          ),
                          _vm._v(" "),
                          _c("moeda-component", {
                            staticClass: "form-control",
                            attrs: {
                              id: "valorSeguroProduto",
                              name: "valorSeguroProduto",
                              disabled: "",
                            },
                            model: {
                              value: _vm.data.prod.vSeg,
                              callback: function ($$v) {
                                _vm.$set(_vm.data.prod, "vSeg", $$v)
                              },
                              expression: "data.prod.vSeg",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-sm-2" }, [
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "control-label",
                              attrs: { for: "valorOutraDespesaProduto" },
                            },
                            [_vm._v("Valor Outras Despesas")]
                          ),
                          _vm._v(" "),
                          _c("moeda-component", {
                            staticClass: "form-control",
                            attrs: {
                              id: "valorOutraDespesaProduto",
                              name: "valorOutraDespesaProduto",
                              disabled: "",
                            },
                            model: {
                              value: _vm.data.prod.vOutro,
                              callback: function ($$v) {
                                _vm.$set(_vm.data.prod, "vOutro", $$v)
                              },
                              expression: "data.prod.vOutro",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-sm-2" }, [
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "control-label",
                              attrs: { for: "valorTotalProduto" },
                            },
                            [_vm._v("Valor Produto")]
                          ),
                          _vm._v(" "),
                          _c("moeda-component", {
                            staticClass: "form-control",
                            attrs: {
                              readonly: "",
                              id: "valorTotalProduto",
                              name: "valorTotalProduto",
                              disabled: "",
                            },
                            model: {
                              value: _vm.data.prod.vProd,
                              callback: function ($$v) {
                                _vm.$set(_vm.data.prod, "vProd", $$v)
                              },
                              expression: "data.prod.vProd",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ]),
              ]
            ),
            _vm._v(" "),
            _c(
              "fieldset-component",
              { attrs: { title: "ICMS", collapsed: false } },
              [
                _c("div", { attrs: { slot: "rows" }, slot: "rows" }, [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-sm-6" }, [
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "control-label",
                              attrs: { for: "origemMercadoriaProduto" },
                            },
                            [_vm._v("Origem Mercadoria")]
                          ),
                          _vm._v(" "),
                          _c("combo-component", {
                            attrs: {
                              id: "origemMercadoriaProduto",
                              name: "origemMercadoriaProduto",
                              data: _vm.origemMercadoriaOptions,
                              disabled: "",
                            },
                            model: {
                              value: _vm.data.origem,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "origem", $$v)
                              },
                              expression: "data.origem",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-sm-6" }, [
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "control-label",
                              attrs: { for: "cstICMSProduto" },
                            },
                            [_vm._v("Situação tributaria")]
                          ),
                          _vm._v(" "),
                          _c("combo-component", {
                            attrs: {
                              id: "cstICMSProduto",
                              name: "cstICMSProduto",
                              data: _vm.cstICMSOptions,
                              disabled: "",
                            },
                            model: {
                              value: _vm.data.cstICMS,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "cstICMS", $$v)
                              },
                              expression: "data.cstICMS",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-sm-4" }, [
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "control-label",
                              attrs: { for: "baseICMSProduto" },
                            },
                            [_vm._v("Base")]
                          ),
                          _vm._v(" "),
                          _c("moeda-component", {
                            staticClass: "form-control",
                            attrs: {
                              id: "baseICMSProduto",
                              name: "baseICMSProduto",
                              disabled: "",
                            },
                            model: {
                              value: _vm.data.baseICMS,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "baseICMS", $$v)
                              },
                              expression: "data.baseICMS",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-sm-4" }, [
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "control-label",
                              attrs: { for: "aliquotaICMSProduto" },
                            },
                            [_vm._v("Alíquota")]
                          ),
                          _vm._v(" "),
                          _c("decimal-com-sinal-custom-component", {
                            staticClass: "form-control",
                            attrs: {
                              signal: "%",
                              id: "aliquotaICMSProduto",
                              name: "aliquotaICMSProduto",
                              disabled: "",
                            },
                            model: {
                              value: _vm.data.aliquotaICMS,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "aliquotaICMS", $$v)
                              },
                              expression: "data.aliquotaICMS",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-sm-4" }, [
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "control-label",
                              attrs: { for: "valorICMSProduto" },
                            },
                            [_vm._v("Valor")]
                          ),
                          _vm._v(" "),
                          _c("moeda-component", {
                            staticClass: "form-control",
                            attrs: {
                              id: "valorICMSProduto",
                              name: "valorICMSProduto",
                              disabled: "",
                            },
                            model: {
                              value: _vm.data.valorICMS,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "valorICMS", $$v)
                              },
                              expression: "data.valorICMS",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _vm.data.pCredSN || _vm.data.vCredICMSSN
                    ? _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-sm-6" }, [
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "control-label",
                                  attrs: {
                                    for: "valorCreditoSimplesNacionalProduto",
                                  },
                                },
                                [
                                  _vm._v(
                                    "Percentual de Crédito Simples Nacional"
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("decimal-com-sinal-custom-component", {
                                staticClass: "form-control",
                                attrs: {
                                  signal: "%",
                                  id: "valorCreditoSimplesNacionalProduto",
                                  name: "valorCreditoSimplesNacionalProduto",
                                  disabled: "",
                                },
                                model: {
                                  value: _vm.data.pCredSN,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.data, "pCredSN", $$v)
                                  },
                                  expression: "data.pCredSN",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-sm-6" }, [
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "control-label",
                                  attrs: {
                                    for: "valorCreditoSimplesNacionalProduto",
                                  },
                                },
                                [
                                  _vm._v(
                                    "Valor de Crédito ICMS Simples Nacional"
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("moeda-component", {
                                staticClass: "form-control",
                                attrs: {
                                  id: "valorCreditoSimplesNacionalProduto",
                                  name: "valorCreditoSimplesNacionalProduto",
                                  disabled: "",
                                },
                                model: {
                                  value: _vm.data.vCredICMSSN,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.data, "vCredICMSSN", $$v)
                                  },
                                  expression: "data.vCredICMSSN",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.data.cstICMS == 51
                    ? _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-sm-4" }, [
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "control-label",
                                  attrs: {
                                    for: "percentualDiferimentoProduto",
                                  },
                                },
                                [_vm._v("Percentual Diferimento")]
                              ),
                              _vm._v(" "),
                              _c("decimal-com-sinal-custom-component", {
                                staticClass: "form-control",
                                attrs: {
                                  signal: "%",
                                  id: "percentualDiferimentoProduto",
                                  name: "percentualDiferimentoProduto",
                                  disabled: "",
                                },
                                model: {
                                  value: _vm.data.percentualDiferimento,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.data,
                                      "percentualDiferimento",
                                      $$v
                                    )
                                  },
                                  expression: "data.percentualDiferimento",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-sm-4" }, [
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "control-label",
                                  attrs: { for: "valorICMSDiferidoProduto" },
                                },
                                [_vm._v("Valor ICMS Diferido")]
                              ),
                              _vm._v(" "),
                              _c("moeda-component", {
                                staticClass: "form-control",
                                attrs: {
                                  id: "valorICMSDiferidoProduto",
                                  name: "valorICMSDiferidoProduto",
                                  disabled: "",
                                },
                                model: {
                                  value: _vm.data.valorICMSDiferido,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.data, "valorICMSDiferido", $$v)
                                  },
                                  expression: "data.valorICMSDiferido",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-sm-4" }, [
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "control-label",
                                  attrs: { for: "valorICMSPagarProduto" },
                                },
                                [_vm._v("Valor ICMS a Pagar")]
                              ),
                              _vm._v(" "),
                              _c("moeda-component", {
                                staticClass: "form-control",
                                attrs: {
                                  id: "valorICMSPagarProduto",
                                  name: "valorICMSPagarProduto",
                                  disabled: "",
                                },
                                model: {
                                  value: _vm.data.valorICMSPagar,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.data, "valorICMSPagar", $$v)
                                  },
                                  expression: "data.valorICMSPagar",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.data.cstICMS == 20
                    ? _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-sm-6" }, [
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "control-label",
                                  attrs: {
                                    for: "percentualReducaoBaseProduto",
                                  },
                                },
                                [_vm._v("Percentual Redução de Base")]
                              ),
                              _vm._v(" "),
                              _c("decimal-com-sinal-custom-component", {
                                staticClass: "form-control",
                                attrs: {
                                  signal: "%",
                                  id: "percentualReducaoBaseProduto",
                                  name: "percentualReducaoBaseProduto",
                                  disabled: "",
                                },
                                model: {
                                  value: _vm.data.percentualReducaoBase,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.data,
                                      "percentualReducaoBase",
                                      $$v
                                    )
                                  },
                                  expression: "data.percentualReducaoBase",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ])
                    : _vm._e(),
                ]),
              ]
            ),
            _vm._v(" "),
            _vm.data.imposto.ICMSUFDest
              ? _c(
                  "fieldset-component",
                  { attrs: { title: "ICMS INTERESTADUAL", collapsed: true } },
                  [
                    _c("div", { attrs: { slot: "rows" }, slot: "rows" }, [
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-sm-2" }, [
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "control-label",
                                  attrs: { for: "baseUFDestinoProduto" },
                                },
                                [_vm._v("Base UF de Destino")]
                              ),
                              _vm._v(" "),
                              _c("moeda-component", {
                                staticClass: "form-control",
                                attrs: {
                                  id: "baseUFDestinoProduto",
                                  name: "baseUFDestinoProduto",
                                  disabled: "",
                                },
                                model: {
                                  value: _vm.data.baseUFDestino,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.data, "baseUFDestino", $$v)
                                  },
                                  expression: "data.baseUFDestino",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-sm-2" }, [
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "control-label",
                                  attrs: { for: "aliquotaUFDestinoProduto" },
                                },
                                [_vm._v("Alíquota UF de Destino")]
                              ),
                              _vm._v(" "),
                              _c("decimal-com-sinal-custom-component", {
                                staticClass: "form-control",
                                attrs: {
                                  signal: "%",
                                  id: "aliquotaUFDestinoProduto",
                                  name: "aliquotaUFDestinoProduto",
                                  disabled: "",
                                },
                                model: {
                                  value: _vm.data.aliquotaUFDestino,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.data, "aliquotaUFDestino", $$v)
                                  },
                                  expression: "data.aliquotaUFDestino",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-sm-2" }, [
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "control-label",
                                  attrs: {
                                    for: "aliquotaInterestadualProduto",
                                  },
                                },
                                [_vm._v("Alíquota interestadual")]
                              ),
                              _vm._v(" "),
                              _c("decimal-com-sinal-custom-component", {
                                staticClass: "form-control",
                                attrs: {
                                  signal: "%",
                                  id: "aliquotaInterestadualProduto",
                                  name: "aliquotaInterestadualProduto",
                                  disabled: "",
                                },
                                model: {
                                  value: _vm.data.aliquotaInterestadual,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.data,
                                      "aliquotaInterestadual",
                                      $$v
                                    )
                                  },
                                  expression: "data.aliquotaInterestadual",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-sm-2" }, [
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "control-label",
                                  attrs: {
                                    for: "aliquotaInterestadualPartilhaProduto",
                                  },
                                },
                                [_vm._v("% da partilha")]
                              ),
                              _vm._v(" "),
                              _c("decimal-com-sinal-custom-component", {
                                staticClass: "form-control",
                                attrs: {
                                  signal: "%",
                                  id: "aliquotaInterestadualPartilhaProduto",
                                  name: "aliquotaInterestadualPartilhaProduto",
                                  disabled: "",
                                },
                                model: {
                                  value: _vm.data.aliquotaInterestadualPartilha,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.data,
                                      "aliquotaInterestadualPartilha",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "data.aliquotaInterestadualPartilha",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-sm-2" }, [
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "control-label",
                                  attrs: { for: "ICMSUFDestinoProduto" },
                                },
                                [_vm._v("Valor ICMS UF de Destino")]
                              ),
                              _vm._v(" "),
                              _c("moeda-component", {
                                staticClass: "form-control",
                                attrs: {
                                  id: "ICMSUFDestinoProduto",
                                  name: "ICMSUFDestinoProduto",
                                  disabled: "",
                                },
                                model: {
                                  value: _vm.data.ICMSUFDestino,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.data, "ICMSUFDestino", $$v)
                                  },
                                  expression: "data.ICMSUFDestino",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-sm-2" }, [
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "control-label",
                                  attrs: { for: "ICMSUFRemetenteProduto" },
                                },
                                [_vm._v("Valor ICMS UF Rementente")]
                              ),
                              _vm._v(" "),
                              _c("moeda-component", {
                                staticClass: "form-control",
                                attrs: {
                                  id: "ICMSUFRemetenteProduto",
                                  name: "ICMSUFRemetenteProduto",
                                  disabled: "",
                                },
                                model: {
                                  value: _vm.data.ICMSUFRemetente,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.data, "ICMSUFRemetente", $$v)
                                  },
                                  expression: "data.ICMSUFRemetente",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-sm-4" }, [
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "control-label",
                                  attrs: { for: "baseFCPUFDestinoProduto" },
                                },
                                [_vm._v("Base FCP UF de Destino")]
                              ),
                              _vm._v(" "),
                              _c("moeda-component", {
                                staticClass: "form-control",
                                attrs: {
                                  id: "baseFCPUFDestinoProduto",
                                  name: "baseFCPUFDestinoProduto",
                                  disabled: "",
                                },
                                model: {
                                  value: _vm.data.baseFCPUFDestino,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.data, "baseFCPUFDestino", $$v)
                                  },
                                  expression: "data.baseFCPUFDestino",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-sm-4" }, [
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "control-label",
                                  attrs: { for: "aliquotaFCPUFDestinoProduto" },
                                },
                                [_vm._v("Alíquota FCP UF de Destino")]
                              ),
                              _vm._v(" "),
                              _c("decimal-com-sinal-custom-component", {
                                staticClass: "form-control",
                                attrs: {
                                  signal: "%",
                                  id: "aliquotaFCPUFDestinoProduto",
                                  name: "aliquotaFCPUFDestinoProduto",
                                  disabled: "",
                                },
                                model: {
                                  value: _vm.data.aliquotaFCPUFDestino,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.data,
                                      "aliquotaFCPUFDestino",
                                      $$v
                                    )
                                  },
                                  expression: "data.aliquotaFCPUFDestino",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-sm-4" }, [
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "control-label",
                                  attrs: { for: "ICMSFCPUFDestinoProduto" },
                                },
                                [_vm._v("Valor FCP UF de Destino")]
                              ),
                              _vm._v(" "),
                              _c("moeda-component", {
                                staticClass: "form-control",
                                attrs: {
                                  id: "ICMSFCPUFDestinoProduto",
                                  name: "ICMSFCPUFDestinoProduto",
                                  disabled: "",
                                },
                                model: {
                                  value: _vm.data.ICMSFCPUFDestino,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.data, "ICMSFCPUFDestino", $$v)
                                  },
                                  expression: "data.ICMSFCPUFDestino",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ]),
                    ]),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.data.imposto.II
              ? _c(
                  "fieldset-component",
                  {
                    attrs: {
                      title: "Imposto de Importação (II)",
                      collapsed: true,
                    },
                  },
                  [
                    _c("div", { attrs: { slot: "rows" }, slot: "rows" }, [
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-sm-3" }, [
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "control-label",
                                  attrs: { for: "baseIIProduto" },
                                },
                                [_vm._v("Base")]
                              ),
                              _vm._v(" "),
                              _c("moeda-component", {
                                staticClass: "form-control",
                                attrs: {
                                  id: "baseIIProduto",
                                  name: "baseIIProduto",
                                  disabled: "",
                                },
                                model: {
                                  value: _vm.data.imposto.II.vBC,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.data.imposto.II, "vBC", $$v)
                                  },
                                  expression: "data.imposto.II.vBC",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-sm-3" }, [
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "control-label",
                                  attrs: { for: "valorIIProduto" },
                                },
                                [_vm._v("Valor")]
                              ),
                              _vm._v(" "),
                              _c("moeda-component", {
                                staticClass: "form-control",
                                attrs: {
                                  id: "valorIIProduto",
                                  name: "valorIIProduto",
                                  disabled: "",
                                },
                                model: {
                                  value: _vm.data.imposto.II.vII,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.data.imposto.II, "vII", $$v)
                                  },
                                  expression: "data.imposto.II.vII",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-sm-3" }, [
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "control-label",
                                  attrs: {
                                    for: "valorDespesasAduaneirasProduto",
                                  },
                                },
                                [_vm._v("Despesas aduaneiras (Taxa SISCOMEX)")]
                              ),
                              _vm._v(" "),
                              _c("moeda-component", {
                                staticClass: "form-control",
                                attrs: {
                                  id: "valorDespesasAduaneirasProduto",
                                  name: "valorDespesasAduaneirasProduto",
                                  disabled: "",
                                },
                                model: {
                                  value: _vm.data.imposto.II.vDespAdu,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.data.imposto.II,
                                      "vDespAdu",
                                      $$v
                                    )
                                  },
                                  expression: "data.imposto.II.vDespAdu",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-sm-3" }, [
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "control-label",
                                  attrs: { for: "valorIOFProduto" },
                                },
                                [_vm._v("Valor IOF")]
                              ),
                              _vm._v(" "),
                              _c("moeda-component", {
                                staticClass: "form-control",
                                attrs: {
                                  id: "valorIOFProduto",
                                  name: "valorIOFProduto",
                                  disabled: "",
                                },
                                model: {
                                  value: _vm.data.imposto.II.vIOF,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.data.imposto.II, "vIOF", $$v)
                                  },
                                  expression: "data.imposto.II.vIOF",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ]),
                    ]),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.data.imposto.IPI
              ? _c(
                  "fieldset-component",
                  { attrs: { title: "IPI", collapsed: true } },
                  [
                    _c("div", { attrs: { slot: "rows" }, slot: "rows" }, [
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-sm-4" }, [
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "control-label",
                                  attrs: { for: "baseIPIProduto" },
                                },
                                [_vm._v("Base")]
                              ),
                              _vm._v(" "),
                              _c("moeda-component", {
                                staticClass: "form-control",
                                attrs: {
                                  id: "baseIPIProduto",
                                  name: "baseIPIProduto",
                                  disabled: "",
                                },
                                model: {
                                  value: _vm.data.baseIPI,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.data, "baseIPI", $$v)
                                  },
                                  expression: "data.baseIPI",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-sm-2" }, [
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "control-label",
                                  attrs: { for: "aliquotaIPIProduto" },
                                },
                                [_vm._v("Alíquota")]
                              ),
                              _vm._v(" "),
                              _c("decimal-com-sinal-custom-component", {
                                staticClass: "form-control",
                                attrs: {
                                  signal: "%",
                                  id: "aliquotaIPIProduto",
                                  name: "aliquotaIPIProduto",
                                  disabled: "",
                                },
                                model: {
                                  value: _vm.data.aliquotaIPI,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.data, "aliquotaIPI", $$v)
                                  },
                                  expression: "data.aliquotaIPI",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-sm-2" }, [
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "control-label",
                                  attrs: { for: "valorIPIProduto" },
                                },
                                [_vm._v("Valor")]
                              ),
                              _vm._v(" "),
                              _c("moeda-component", {
                                staticClass: "form-control",
                                attrs: {
                                  id: "valorIPIProduto",
                                  name: "valorIPIProduto",
                                  disabled: "",
                                },
                                model: {
                                  value: _vm.data.valorIPI,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.data, "valorIPI", $$v)
                                  },
                                  expression: "data.valorIPI",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-sm-2" }, [
                          _c("div", { staticClass: "form-group" }, [
                            _c(
                              "label",
                              {
                                staticClass: "control-label",
                                attrs: { for: "codigoEnquadramentoProduto" },
                              },
                              [_vm._v("C. Enquadramento")]
                            ),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.data.imposto.IPI.cEnq,
                                  expression: "data.imposto.IPI.cEnq",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: {
                                type: "number",
                                id: "codigoEnquadramentoProduto",
                                name: "codigoEnquadramentoProduto",
                                disabled: "",
                              },
                              domProps: { value: _vm.data.imposto.IPI.cEnq },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.data.imposto.IPI,
                                    "cEnq",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-sm-2" }, [
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "control-label",
                                  attrs: { for: "cstIPIProduto" },
                                },
                                [_vm._v("Situação tributaria")]
                              ),
                              _vm._v(" "),
                              _c("combo-component", {
                                attrs: {
                                  id: "cstIPIProduto",
                                  name: "cstIPIProduto",
                                  data: _vm.cstIPIOptions,
                                  disabled: "",
                                },
                                model: {
                                  value: _vm.data.cstIPI,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.data, "cstIPI", $$v)
                                  },
                                  expression: "data.cstIPI",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ]),
                    ]),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "fieldset-component",
              { attrs: { title: "PIS", collapsed: true } },
              [
                _c("div", { attrs: { slot: "rows" }, slot: "rows" }, [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-sm-3" }, [
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "control-label",
                              attrs: { for: "basePISProduto" },
                            },
                            [_vm._v("Base")]
                          ),
                          _vm._v(" "),
                          _c("moeda-component", {
                            staticClass: "form-control",
                            attrs: {
                              id: "basePISProduto",
                              name: "basePISProduto",
                              disabled: "",
                            },
                            model: {
                              value: _vm.data.basePIS,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "basePIS", $$v)
                              },
                              expression: "data.basePIS",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-sm-3" }, [
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "control-label",
                              attrs: { for: "aliquotaPISProduto" },
                            },
                            [_vm._v("Alíquota")]
                          ),
                          _vm._v(" "),
                          _c("decimal-com-sinal-custom-component", {
                            staticClass: "form-control",
                            attrs: {
                              signal: "%",
                              id: "aliquotaPISProduto",
                              name: "aliquotaPISProduto",
                              disabled: "",
                            },
                            model: {
                              value: _vm.data.aliquotaPIS,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "aliquotaPIS", $$v)
                              },
                              expression: "data.aliquotaPIS",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-sm-3" }, [
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "control-label",
                              attrs: { for: "valorPISProduto" },
                            },
                            [_vm._v("Valor")]
                          ),
                          _vm._v(" "),
                          _c("moeda-component", {
                            staticClass: "form-control",
                            attrs: {
                              id: "valorPISProduto",
                              name: "valorPISProduto",
                              disabled: "",
                            },
                            model: {
                              value: _vm.data.valorPIS,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "valorPIS", $$v)
                              },
                              expression: "data.valorPIS",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-sm-3" }, [
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "control-label",
                              attrs: { for: "cstPISProduto" },
                            },
                            [_vm._v("Situação tributaria")]
                          ),
                          _vm._v(" "),
                          _c("combo-component", {
                            attrs: {
                              id: "cstPISProduto",
                              name: "cstPISProduto",
                              data: _vm.cstPISCOFINSOptions,
                              disabled: "",
                            },
                            model: {
                              value: _vm.data.cstPIS,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "cstPIS", $$v)
                              },
                              expression: "data.cstPIS",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ]),
              ]
            ),
            _vm._v(" "),
            _c(
              "fieldset-component",
              { attrs: { title: "COFINS", collapsed: true } },
              [
                _c("div", { attrs: { slot: "rows" }, slot: "rows" }, [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-sm-3" }, [
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "control-label",
                              attrs: { for: "baseCOFINSProduto" },
                            },
                            [_vm._v("Base")]
                          ),
                          _vm._v(" "),
                          _c("moeda-component", {
                            staticClass: "form-control",
                            attrs: {
                              id: "baseCOFINSProduto",
                              name: "baseCOFINSProduto",
                              disabled: "",
                            },
                            model: {
                              value: _vm.data.baseCOFINS,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "baseCOFINS", $$v)
                              },
                              expression: "data.baseCOFINS",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-sm-3" }, [
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "control-label",
                              attrs: { for: "aliquotaCOFINSProduto" },
                            },
                            [_vm._v("Alíquota")]
                          ),
                          _vm._v(" "),
                          _c("decimal-com-sinal-custom-component", {
                            staticClass: "form-control",
                            attrs: {
                              signal: "%",
                              id: "aliquotaCOFINSProduto",
                              name: "aliquotaCOFINSProduto",
                              disabled: "",
                            },
                            model: {
                              value: _vm.data.aliquotaCOFINS,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "aliquotaCOFINS", $$v)
                              },
                              expression: "data.aliquotaCOFINS",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-sm-3" }, [
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "control-label",
                              attrs: { for: "valorCOFINSProduto" },
                            },
                            [_vm._v("Valor")]
                          ),
                          _vm._v(" "),
                          _c("moeda-component", {
                            staticClass: "form-control",
                            attrs: {
                              id: "valorCOFINSProduto",
                              name: "valorCOFINSProduto",
                              disabled: "",
                            },
                            model: {
                              value: _vm.data.valorCOFINS,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "valorCOFINS", $$v)
                              },
                              expression: "data.valorCOFINS",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-sm-3" }, [
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "control-label",
                              attrs: { for: "cstCOFINSProduto" },
                            },
                            [_vm._v("Situação tributaria")]
                          ),
                          _vm._v(" "),
                          _c("combo-component", {
                            attrs: {
                              id: "cstCOFINSProduto",
                              name: "cstCOFINSProduto",
                              data: _vm.cstPISCOFINSOptions,
                              disabled: "",
                            },
                            model: {
                              value: _vm.data.cstCOFINS,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "cstCOFINS", $$v)
                              },
                              expression: "data.cstCOFINS",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ]),
              ]
            ),
            _vm._v(" "),
            _vm.data.prod.DI
              ? _c(
                  "fieldset-component",
                  { attrs: { title: "DI", collapsed: true } },
                  [
                    _c(
                      "div",
                      { attrs: { slot: "rows" }, slot: "rows" },
                      [
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-sm-2" }, [
                            _c("div", { staticClass: "form-group" }, [
                              _c(
                                "label",
                                {
                                  staticClass: "control-label",
                                  attrs: { for: "numeroDI" },
                                },
                                [_vm._v("Número")]
                              ),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.data.prod.DI.nDI,
                                    expression: "data.prod.DI.nDI",
                                  },
                                ],
                                staticClass: "form-control",
                                attrs: {
                                  type: "text",
                                  id: "numeroDI",
                                  name: "numeroDI",
                                  disabled: "",
                                },
                                domProps: { value: _vm.data.prod.DI.nDI },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.data.prod.DI,
                                      "nDI",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-sm-2" }, [
                            _c(
                              "div",
                              { staticClass: "form-group" },
                              [
                                _c(
                                  "label",
                                  {
                                    staticClass: "control-label",
                                    attrs: { for: "dataDI" },
                                  },
                                  [_vm._v("Data do registro DI")]
                                ),
                                _vm._v(" "),
                                _c("date-time-picker-component", {
                                  attrs: {
                                    id: "dataDI",
                                    name: "dataDI",
                                    disabled: "",
                                  },
                                  model: {
                                    value: _vm.data.prod.DI.dDI,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.data.prod.DI, "dDI", $$v)
                                    },
                                    expression: "data.prod.DI.dDI",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-sm-2" }, [
                            _c("div", { staticClass: "form-group" }, [
                              _c(
                                "label",
                                {
                                  staticClass: "control-label",
                                  attrs: { for: "viaTransporteDI" },
                                },
                                [_vm._v("Via de Transporte")]
                              ),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.data.prod.DI.tpViaTransp,
                                    expression: "data.prod.DI.tpViaTransp",
                                  },
                                ],
                                staticClass: "form-control",
                                attrs: {
                                  type: "text",
                                  id: "viaTransporteDI",
                                  name: "viaTransporteDI",
                                  disabled: "",
                                },
                                domProps: {
                                  value: _vm.data.prod.DI.tpViaTransp,
                                },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.data.prod.DI,
                                      "tpViaTransp",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-sm-2" }, [
                            _c("div", { staticClass: "form-group" }, [
                              _c(
                                "label",
                                {
                                  staticClass: "control-label",
                                  attrs: { for: "tipoIntermedioDI" },
                                },
                                [_vm._v("Tipo de Intermédio")]
                              ),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.data.prod.DI.tpIntermedio,
                                    expression: "data.prod.DI.tpIntermedio",
                                  },
                                ],
                                staticClass: "form-control",
                                attrs: {
                                  type: "text",
                                  id: "tipoIntermedioDI",
                                  name: "tipoIntermedioDI",
                                  disabled: "",
                                },
                                domProps: {
                                  value: _vm.data.prod.DI.tpIntermedio,
                                },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.data.prod.DI,
                                      "tpIntermedio",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-sm-4" }, [
                            _c("div", { staticClass: "form-group" }, [
                              _c(
                                "label",
                                {
                                  staticClass: "control-label",
                                  attrs: { for: "codigoExportadorDI" },
                                },
                                [_vm._v("Código Exportador")]
                              ),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.data.prod.DI.cExportador,
                                    expression: "data.prod.DI.cExportador",
                                  },
                                ],
                                staticClass: "form-control",
                                attrs: {
                                  type: "text",
                                  id: "codigoExportadorDI",
                                  name: "codigoExportadorDI",
                                  disabled: "",
                                },
                                domProps: {
                                  value: _vm.data.prod.DI.cExportador,
                                },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.data.prod.DI,
                                      "cExportador",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                            ]),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-sm-4" }, [
                            _c("div", { staticClass: "form-group" }, [
                              _c(
                                "label",
                                {
                                  staticClass: "control-label",
                                  attrs: { for: "localDesembaracoDI" },
                                },
                                [_vm._v("Local desembaraço")]
                              ),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.data.prod.DI.xLocDesemb,
                                    expression: "data.prod.DI.xLocDesemb",
                                  },
                                ],
                                staticClass: "form-control",
                                attrs: {
                                  type: "text",
                                  id: "localDesembaracoDI",
                                  name: "localDesembaracoDI",
                                  disabled: "",
                                },
                                domProps: {
                                  value: _vm.data.prod.DI.xLocDesemb,
                                },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.data.prod.DI,
                                      "xLocDesemb",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-sm-4" }, [
                            _c("div", { staticClass: "form-group" }, [
                              _c(
                                "label",
                                {
                                  staticClass: "control-label",
                                  attrs: { for: "ufDesembaracoDI" },
                                },
                                [_vm._v("UF desembaraço")]
                              ),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.data.prod.DI.UFDesemb,
                                    expression: "data.prod.DI.UFDesemb",
                                  },
                                ],
                                staticClass: "form-control",
                                attrs: {
                                  type: "text",
                                  id: "ufDesembaracoDI",
                                  name: "ufDesembaracoDI",
                                  disabled: "",
                                },
                                domProps: { value: _vm.data.prod.DI.UFDesemb },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.data.prod.DI,
                                      "UFDesemb",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-sm-4" }, [
                            _c(
                              "div",
                              { staticClass: "form-group" },
                              [
                                _c(
                                  "label",
                                  {
                                    staticClass: "control-label",
                                    attrs: { for: "dataDesembaracoDI" },
                                  },
                                  [_vm._v("Data desembaraço")]
                                ),
                                _vm._v(" "),
                                _c("date-time-picker-component", {
                                  attrs: {
                                    id: "dataDesembaracoDI",
                                    name: "dataDesembaracoDI",
                                    disabled: "",
                                  },
                                  model: {
                                    value: _vm.data.prod.DI.dDesemb,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.data.prod.DI, "dDesemb", $$v)
                                    },
                                    expression: "data.prod.DI.dDesemb",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("grid-component", {
                          attrs: {
                            data: _vm.data.prod.DI.adi,
                            columns: _vm.gridColumns,
                            "show-action-column": false,
                            "show-remove-item": false,
                            "show-edit-item": false,
                            showAddNewButton: false,
                            "show-table-head": false,
                            "show-table-foot": false,
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                )
              : _vm._e(),
          ],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }