import BaseModel from "../../../../base/baseModel";
import StatusContas from "../../../../enum/statusContas";

export default class OpcoesLiquidacaoBaseModel extends BaseModel {
    //campos apresentados em tela para usuario escolher como irá nascer e pagar cada parcela
    statusContas: StatusContas = null;
    dataPagamento?: Date = null;
    formaPagamentoId?: number = null;
    contaCorrenteId?: number = null;
}
