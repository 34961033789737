import Vue from "vue";

import { Component } from "@/decorators";
import SepararValoresPor from "@/models/enum/separarValoresPor";
import ValoresSeparadosDataModel from "@/models/financeiro/valoresSeparadosDataModel";
import ContaPagarService from "@/services/financeiro/contaPagarService";
import ContaReceberService from "@/services/financeiro/contaReceberService";
import MetaService from "@/services/metaService";

// Precisa ser assim se não ocorre erro
// eslint-disable-next-line @typescript-eslint/no-var-requires
const echarts = require("echarts");

import "echarts/theme/dark";

@Component
export default class FastActionGraficoComponent extends Vue {
    private serviceReceber: ContaReceberService = new ContaReceberService();
    private servicePagar: ContaPagarService = new ContaPagarService();
    private metaService: MetaService = new MetaService();
    private recebimentosSeparados: Array<ValoresSeparadosDataModel>;
    private pagamentosSeparados: Array<ValoresSeparadosDataModel>;
    private metasSeparadas: Array<ValoresSeparadosDataModel>;

    myChart: any;
    legenda = [];
    labels = [];

    dias = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
    receitaMes: Array<number> = null; //[1000, 2000, 1000, 4000, 5000, 4000, 8000, 4000, 4000, 5000, 4000, 5000];
    despesaMes: Array<number> = null; //[500, 1000, 500, 2000, 2500, 2000, 4000];
    metaMes: Array<number> = null; //[10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000];

    private newChart() {
        this.montaValoresMeses();
        //@ts-ignore
        this.myChart = echarts.init(this.$refs.chart, "dark");
        this.myChart.showLoading("default", { text: this.$t("__.ts.carregando"), color: "transparent" });
        this.myChart.setOption({
            backgroundColor: "transparent",
            tooltip: {
                trigger: "axis",
            },
            toolbox: {
                // y: 'bottom',
                top: 0,
                // orient: 'vertical',
                right: 100,
                zlevel: 0,
                z: 99999,
                padding: 20,

                feature: {
                    dataZoom: {
                        show: true,
                        title: {
                            zoom: "zoom",
                            back: "retornar zoom",
                        },
                    },
                    saveAsImage: {
                        show: true,
                        title: this.$t("__.Crud.banco.edit_vue_html.salvar"),
                        name: "grafico",
                    },
                },

                iconStyle: {
                    emphasis: {
                        textPosition: "top",
                        // textAlign: 'right'
                    },
                },
            },

            //toolbox: {
            //    show: true,
            //    orient: 'vertical',
            //    left: 'right',
            //    top: 'center',
            //    feature: {
            //        dataZoom: {
            //            show: true,
            //            title: {
            //                zoom: 'Zoom',
            //                back: 'Retornar Zoom',
            //            }
            //        },
            //        magicType: {
            //            show: true,
            //            type: ['line', 'bar'],
            //            title: {
            //                line: "Linha",
            //                bar: "Barra"
            //            }
            //        },
            //        saveAsImage: {
            //            show: true,
            //            title: "Salvar",
            //            name: "grafico"
            //        }
            //    }
            //},
            xAxis: {
                type: "category",
                boundaryGap: false,
                axisLine: { onZero: false }, //Fix the bug
                data: [
                    "Janeiro",
                    "Fevereiro",
                    "Março",
                    "Abril",
                    "Maio",
                    "Junho",
                    "Julho",
                    "Agosto",
                    "Setembro",
                    "Outubro",
                    "Novembro",
                    "Dezembro",
                ],
            },
            yAxis: [
                {
                    type: "value",
                    scale: true,
                    axisLabel: {
                        formatter: "R${value}",
                    },
                },
            ],
            dataZoom: [
                {
                    type: "inside",
                    start: 0,
                    end: 100,
                },
                {
                    start: 0,
                    end: 10,
                    handleIcon:
                        "M10.7,11.9v-1.3H9.3v1.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4v1.3h1.3v-1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7V23h6.6V24.4z M13.3,19.6H6.7v-1.4h6.6V19.6z",
                    handleSize: "80%",
                    handleStyle: {
                        color: "#fff",
                        shadowBlur: 3,
                        shadowColor: "rgba(0, 0, 0, 0.6)",
                        shadowOffsetX: 2,
                        shadowOffsetY: 2,
                    },
                },
            ],
            color: ["#8cc63f", "#ff6e6e", "#6ce4ff"],
            series: [
                {
                    name: "Receita",
                    type: "line",
                    data: this.receitaMes,
                    markPoint: {
                        data: [
                            { type: "max", name: "???" },
                            { type: "min", name: "???" },
                        ],
                    },
                    markLine: {
                        data: [{ type: "average", name: "???" }],
                    },
                },
                {
                    name: "Despesa",
                    type: "line",
                    data: this.despesaMes,
                    markPoint: {
                        data: [
                            { type: "max", name: "???" },
                            { type: "min", name: "???" },
                        ],
                    },
                    markLine: {
                        data: [{ type: "average", name: "???" }],
                    },
                },
                {
                    name: "Meta",
                    type: "line",
                    data: this.metaMes,
                    markPoint: {
                        data: [
                            //{ type: 'max', name: '???' }
                        ],
                    },
                    markLine: {
                        data: [{ type: "average", name: "???" }],
                    },
                },
            ],
        });

        this.myChart.hideLoading();
    }

    private async loadRecebimentos() {
        const year = new Date().getFullYear();
        const dataInicio = new Date(year, 0, 1, 0, 0, 0, 0);
        const dataFim = new Date(year, 11, 31, 23, 59, 0, 0);

        try {
            const data = await this.serviceReceber
                .getMovimentacoesByDate(SepararValoresPor.Mes, dataInicio, dataFim)
                .then(r => r.json() as Promise<Array<ValoresSeparadosDataModel>>);
            this.recebimentosSeparados = data;
        } catch {}
    }

    private async loadPagamentos() {
        const year = new Date().getFullYear();
        const dataInicio = new Date(year, 0, 1, 0, 0, 0, 0);
        const dataFim = new Date(year, 11, 31, 23, 59, 0, 0);

        try {
            const data = await this.servicePagar
                .getMovimentacoesByDate(SepararValoresPor.Mes, dataInicio, dataFim)
                .then(r => r.json() as Promise<Array<ValoresSeparadosDataModel>>);
            this.pagamentosSeparados = data;
        } catch {}
    }

    private async loadMetas() {
        const year = new Date().getFullYear();
        const dataInicio = new Date(year, 0, 1, 0, 0, 0, 0);
        const dataFim = new Date(year, 11, 31, 23, 59, 0, 0);

        try {
            const data = await this.metaService
                .getMetas(year)
                .then(r => r.json() as Promise<Array<ValoresSeparadosDataModel>>);
            this.metasSeparadas = data;
        } catch {}
    }

    private montaValoresMeses() {
        this.receitaMes = new Array<number>();
        this.despesaMes = new Array<number>();
        this.metaMes = new Array<number>();

        for (let i = 1; i <= 12; i++) {
            if (this.recebimentosSeparados.filter(p => p.mes == i).length > 0)
                this.receitaMes.push(
                    this.recebimentosSeparados
                        .filter(p => p.mes == i)
                        .map(p => p.valor)
                        .reduce(function (a, b) {
                            return a + b;
                        }),
                );
            else this.receitaMes.push(0);

            if (this.pagamentosSeparados.filter(p => p.mes == i).length > 0)
                this.despesaMes.push(
                    this.pagamentosSeparados
                        .filter(p => p.mes == i)
                        .map(p => p.valor)
                        .reduce(function (a, b) {
                            return a + b;
                        }),
                );
            else this.despesaMes.push(0);

            if (this.metasSeparadas.filter(p => p.mes == i).length > 0)
                this.metaMes.push(
                    this.metasSeparadas
                        .filter(p => p.mes == i)
                        .map(p => p.valor)
                        .reduce(function (a, b) {
                            return a + b;
                        }),
                );
            else this.metaMes.push(0);
        }
    }
    public getMaiorMensal() {
        return Math.max(...this.receitaMes);
    }
    public getTotalReceitas() {
        if (this.receitaMes != null)
            return this.receitaMes.reduce(function (a, b) {
                return a + b;
            });
        else return 0;
    }
    public getMetaMensal() {
        if (this.metasSeparadas != null && this.metasSeparadas.length > 0) {
            const x = this.metasSeparadas.filter(p => p.mes == new Date().getMonth() + 1).map(p => p.valor);
            return x[0] != null ? x[0] : 0; // (x[0]).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
        }
        return 0;
    }

    private carregou() {
        this.$emit("carregou-grafico", true);
    }

    private mounted() {
        // @ts-ignore
        const zoom = document.body.style.zoom;
        const zoomNumb = parseFloat(zoom.substr(0, zoom.length - 1));
        if (zoomNumb != null && zoomNumb != 100) {
            const zoomEq = (100 / zoomNumb) * 100;
            // @ts-ignore
            document.getElementById("chartGraficoFluxo").style.zoom = zoomEq.toString() + "%";
        }

        return Promise.all([this.loadRecebimentos(), this.loadPagamentos(), this.loadMetas()])
            .then(result => {
                this.montaValoresMeses();
                this.newChart();
                this.carregou();
                // this.loadingModalComponent.hide();
            })
            .catch(() => {
                // this.loadingModalComponent.hide();
            });
    }
}
