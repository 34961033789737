import Boot from "@/main";
import VendaContinuoModel from "@/models/vendaContinuoModel";

import WhatsAppUtilsService from "./whatsAppUtilsService";

export default class VendaContinuoUtilsService {
    whatsAppUtilsService: WhatsAppUtilsService;

    constructor() {
        this.whatsAppUtilsService = new WhatsAppUtilsService();
    }

    public async adiar(venda: VendaContinuoModel) {
        const model = new VendaContinuoModel();
        model.updateFrom(venda);

        const response = await Boot.$showAlert({
            title: Boot.$t("__.ts.adiarAviso") as string,
            text: Boot.$t("__.ts.adiarAvisoCont") as string,
            input: "number",
            showCancelButton: true,
            confirmButtonText: Boot.$t("__.ts.adiar") as string,
            inputValue: model.getDiasSugestaoAdiar(),
            inputValidator: result => {
                if (Number(result) > 0) {
                    return "";
                }
                return Boot.$t("__.ts.qtdDiasAdiarMaiorZero") as string;
            },
        });

        if (response.isConfirmed) {
            try {
                const sucesso = model.adiar(response.value).withLoading().resolveWithoutJSON();
                if (sucesso) {
                    await Boot.$showSuccess(Boot.$t("__.ts.alt"), Boot.$t("__.ts.avisoFoiAdiadoSucess"));
                    return true;
                }
            } catch {}
        }
        return false;
    }

    public async arquivar(venda: VendaContinuoModel) {
        const model = new VendaContinuoModel();
        model.updateFrom(venda);

        try {
            const sucesso = model.arquivar().withLoading().resolveWithoutJSON();
            if (sucesso) {
                await Boot.$showSuccess(Boot.$t("__.ts.alt"), Boot.$t("__.ts.avisoFoiArqSucess"));
                return true;
            }
        } catch {}
        return false;
    }

    public async getMessageWhats(venda: VendaContinuoModel) {
        const model = new VendaContinuoModel();
        model.updateFrom(venda);

        try {
            const data = await model.gerarOrcamento().withLoading().resolveWithJSON();

            await Boot.$showSuccess(Boot.$t("__.ts.alt"), Boot.$t("__.ts.orcaCriadoSucess"));

            const message = data["value"];
            this.showWhatsMessage(message, model);

            return true;
        } catch {}
        return "";
    }

    public async showWhatsMessage(message: string, model: VendaContinuoModel) {
        const celularData = await model
            .getCelularClienteByVendaId()
            .withLoading()
            .resolveWithJSON<{ celular: string }>();
        const celular = celularData.celular.replace('"', "").replace('"', "");

        this.whatsAppUtilsService.showMensagemWhats(celular, message);
    }
}
