import Vue from "vue";

import { Component, Prop, Watch } from "@/decorators";

@Component
export default class DecimalComSinalCustomComponent extends Vue {
    @Prop(String) id: string;
    @Prop(String) name: string;
    @Prop(Number) value: number;
    @Prop(String) signal: string;
    @Prop(Number) decimais: number;

    private onBlur(value) {
        this.$emit("input", this.formatValueToModel(value));
    }

    private onChange(value) {
        this.$emit("change", this.formatValueToModel(value));
    }

    private formatValueToModel(value) {
        if (value == "") {
            return null;
        }

        return +value
            .replace(<string>this.signal, "")
            .replace(/\./g, "")
            .replace(",", ".");
    }

    private getFocus(value) {
        value.setSelectionRange(0, 0);
    }

    private setSuffix() {
        const doc = document.getElementById(this.id);
        doc.removeAttribute("suffix");
        doc.setAttribute("suffix", (<string>this.signal).toString());

        this.$forceUpdate();
    }

    @Watch("signal")
    private onSignalChange() {
        this.setSuffix();
    }

    private mounted() {
        this.setSuffix();
    }
}
