import nfetch from "@/utils/events/nfetch";

export default class LogService {
    private controllerName = "Log";

    public getLogLevel(): Promise<Response> {
        return nfetch(`/${this.controllerName}/GetLogLevel`, {
            credentials: "same-origin",
        });
    }

    public setLogLevel(level: number): Promise<Response> {
        return nfetch(`/${this.controllerName}/SetLogLevel?level=${level}`, {
            method: "post",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json; charset=UTF-8",
            },
        });
    }
}
