var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "crud crud-edit" },
    [
      _vm.modelId == null
        ? _c("actionBarComponent", {
            attrs: {
              title: _vm.$t(
                "__.Crud.inutilizacaoNotaFiscal.edit_vue_html.inutilizacaoNotaFiscalEdit"
              ),
            },
            on: { onSave: _vm.save },
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "form",
        {
          attrs: { novalidate: "novalidate" },
          on: {
            submit: function ($event) {
              $event.preventDefault()
              _vm.save()
            },
          },
        },
        [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-sm-4" }, [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "tooltip",
                      rawName: "v-tooltip",
                      value: _vm.errors.first("modelo"),
                      expression: "errors.first('modelo')",
                    },
                  ],
                  staticClass: "form-group",
                },
                [
                  _c(
                    "label",
                    {
                      staticClass: "control-label",
                      attrs: { for: "modelo", title: _vm.$t("__.obrigatorio") },
                    },
                    [
                      _c("span", { staticStyle: { color: "red" } }, [
                        _vm._v("* "),
                      ]),
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "__.Crud.inutilizacaoNotaFiscal.edit_vue_html.modelo"
                          )
                        )
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("combo-component", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'",
                      },
                    ],
                    attrs: {
                      id: "modelo",
                      name: "modelo",
                      data: _vm.modeloOptions,
                      searchable: false,
                      disabled: _vm.modelId > 0 ? true : false,
                    },
                    model: {
                      value: _vm.model.modelo,
                      callback: function ($$v) {
                        _vm.$set(_vm.model, "modelo", $$v)
                      },
                      expression: "model.modelo",
                    },
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-sm-4" }, [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "tooltip",
                      rawName: "v-tooltip",
                      value: _vm.errors.first("ano"),
                      expression: "errors.first('ano')",
                    },
                  ],
                  staticClass: "form-group",
                },
                [
                  _c(
                    "label",
                    {
                      staticClass: "control-label",
                      attrs: { for: "ano", title: _vm.$t("__.obrigatorio") },
                    },
                    [
                      _c("span", { staticStyle: { color: "red" } }, [
                        _vm._v("* "),
                      ]),
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "__.Crud.inutilizacaoNotaFiscal.edit_vue_html.ano"
                          )
                        )
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      { name: "focus", rawName: "v-focus" },
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.model.ano,
                        expression: "model.ano",
                      },
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required|min:4|max:4",
                        expression: "'required|min:4|max:4'",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: {
                      type: "text",
                      id: "ano",
                      name: "ano",
                      disabled: _vm.modelId > 0 ? true : false,
                    },
                    domProps: { value: _vm.model.ano },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.model, "ano", $event.target.value)
                      },
                    },
                  }),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-sm-4" }, [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "tooltip",
                      rawName: "v-tooltip",
                      value: _vm.errors.first("serie"),
                      expression: "errors.first('serie')",
                    },
                  ],
                  staticClass: "form-group",
                },
                [
                  _c(
                    "label",
                    {
                      staticClass: "control-label",
                      attrs: { for: "serie", title: _vm.$t("__.obrigatorio") },
                    },
                    [
                      _c("span", { staticStyle: { color: "red" } }, [
                        _vm._v("* "),
                      ]),
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "__.Crud.inutilizacaoNotaFiscal.edit_vue_html.serie"
                          )
                        )
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      { name: "focus", rawName: "v-focus" },
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.model.serie,
                        expression: "model.serie",
                      },
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required|max:3",
                        expression: "'required|max:3'",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: {
                      type: "text",
                      id: "serie",
                      name: "serie",
                      disabled: _vm.modelId > 0 ? true : false,
                    },
                    domProps: { value: _vm.model.serie },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.model, "serie", $event.target.value)
                      },
                    },
                  }),
                ]
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-sm-6" }, [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "tooltip",
                      rawName: "v-tooltip",
                      value: _vm.errors.first("numeroInicial"),
                      expression: "errors.first('numeroInicial')",
                    },
                  ],
                  staticClass: "form-group",
                },
                [
                  _c(
                    "label",
                    {
                      staticClass: "control-label",
                      attrs: {
                        for: "numeroInicial",
                        title: _vm.$t("__.obrigatorio"),
                      },
                    },
                    [
                      _c("span", { staticStyle: { color: "red" } }, [
                        _vm._v("* "),
                      ]),
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "__.Crud.inutilizacaoNotaFiscal.edit_vue_html.numeroInicial"
                          )
                        )
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.model.numeroInicial,
                        expression: "model.numeroInicial",
                      },
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: {
                      type: "number",
                      id: "numeroInicial",
                      name: "numeroInicial",
                      disabled: _vm.modelId > 0 ? true : false,
                    },
                    domProps: { value: _vm.model.numeroInicial },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.model,
                          "numeroInicial",
                          $event.target.value
                        )
                      },
                    },
                  }),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-sm-6" }, [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "tooltip",
                      rawName: "v-tooltip",
                      value: _vm.errors.first("numeroFinal"),
                      expression: "errors.first('numeroFinal')",
                    },
                  ],
                  staticClass: "form-group",
                },
                [
                  _c(
                    "label",
                    {
                      staticClass: "control-label",
                      attrs: {
                        for: "numeroFinal",
                        title: _vm.$t("__.obrigatorio"),
                      },
                    },
                    [
                      _c("span", { staticStyle: { color: "red" } }, [
                        _vm._v("* "),
                      ]),
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "__.Crud.inutilizacaoNotaFiscal.edit_vue_html.numeroFinal"
                          )
                        )
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.model.numeroFinal,
                        expression: "model.numeroFinal",
                      },
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: {
                      type: "number",
                      id: "numeroFinal",
                      name: "numeroFinal",
                      disabled: _vm.modelId > 0 ? true : false,
                    },
                    domProps: { value: _vm.model.numeroFinal },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.model, "numeroFinal", $event.target.value)
                      },
                    },
                  }),
                ]
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-sm-12" }, [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "tooltip",
                      rawName: "v-tooltip",
                      value: _vm.errors.first("justificativa"),
                      expression: "errors.first('justificativa')",
                    },
                  ],
                  staticClass: "form-group",
                },
                [
                  _c(
                    "label",
                    {
                      staticClass: "control-label",
                      attrs: {
                        for: "justificativa",
                        title: _vm.$t("__.obrigatorio"),
                      },
                    },
                    [
                      _c("span", { staticStyle: { color: "red" } }, [
                        _vm._v("* "),
                      ]),
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "__.Crud.inutilizacaoNotaFiscal.edit_vue_html.justificativa"
                          )
                        )
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.model.justificativa,
                        expression: "model.justificativa",
                      },
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: {
                      autocomplete: "off",
                      type: "text",
                      id: "justificativa",
                      name: "justificativa",
                      disabled: _vm.modelId > 0 ? true : false,
                    },
                    domProps: { value: _vm.model.justificativa },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.model,
                          "justificativa",
                          $event.target.value
                        )
                      },
                    },
                  }),
                ]
              ),
            ]),
            _vm._v(" "),
            _vm.model.status == 2
              ? _c("div", { staticClass: "col-sm-12" }, [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "tooltip",
                          rawName: "v-tooltip",
                          value: _vm.errors.first("rejeicao"),
                          expression: "errors.first('rejeicao')",
                        },
                      ],
                      staticClass: "form-group",
                    },
                    [
                      _c(
                        "label",
                        {
                          staticClass: "control-label",
                          attrs: {
                            for: "rejeicao",
                            title: _vm.$t("__.obrigatorio"),
                          },
                        },
                        [
                          _c("span", { staticStyle: { color: "red" } }, [
                            _vm._v("* "),
                          ]),
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "__.Crud.inutilizacaoNotaFiscal.edit_vue_html.rejeicao"
                              )
                            )
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.model.rejeicao,
                            expression: "model.rejeicao",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          autocomplete: "off",
                          type: "text",
                          id: "rejeicao",
                          name: "rejeicao",
                          disabled: true,
                        },
                        domProps: { value: _vm.model.rejeicao },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(_vm.model, "rejeicao", $event.target.value)
                          },
                        },
                      }),
                    ]
                  ),
                ])
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c("fieldset", [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-sm-12 newbtn" }, [
                _c("div", { staticClass: "form-group" }, [
                  _vm.modelId == null
                    ? _c(
                        "button",
                        {
                          staticClass: "btn btn-success",
                          attrs: { type: "submit" },
                        },
                        [
                          _c("i", {
                            staticClass: "fa fa-save",
                            attrs: { "aria-hidden": "true" },
                          }),
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "__.Crud.inutilizacaoNotaFiscal.edit_vue_html.inutilizar"
                              )
                            )
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-searchable",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          _vm.cancel()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("__.Crud.voltar")))]
                  ),
                ]),
              ]),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _c("loading-modal-component", { ref: "loadingModalComponent" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }