import PrintByModel from "@/components/parent/printModel/printModel";
import ModeloPreDefinido from "@/models/enum/modeloPreDefinido";
import ModeloImpressaoService from "@/services/modeloImpressaoService";

import { Fonte } from "../enum/fonte";
import ModeloEspecifico from "../enum/modeloEspecifico";

import BaseModel from "./../base/baseModel";
import ModeloImpressaoCampos from "./modeloImpressaoCamposModel";
import ModeloImpressaoCondicao from "./modeloImpressaoCondicaoModel";
import ModeloImpressaoSessao from "./modeloImpressaoSessaoModel";
import ModeloImpressaoUsuarioModel from "./modeloImpressaoUsuarioModel";

export default class ModeloImpressaoModel extends BaseModel {
    id?: number = null;
    telaName: string = null;
    padrao: boolean = null;
    descricao: string = null;
    sessoes: Array<ModeloImpressaoSessao> = [];
    condicoes: Array<ModeloImpressaoCondicao> = [];
    usuarios: Array<ModeloImpressaoUsuarioModel> = [];
    modeloPreDefinido: ModeloPreDefinido = ModeloPreDefinido.Personalizado;
    tamanhoFonte = 14;
    fonte = Fonte.Default;
    nomeImpressora: string = null;
    //auxiliar para n impressoes
    idRegistro?: number = null;
    modeloEspecifico?: ModeloEspecifico = null;

    modeloImpressaoService = new ModeloImpressaoService();
    printByModel = new PrintByModel();

    async GetCamposComDisplayName(fullClassName: string) {
        return await this.modeloImpressaoService
            .GetLabelsByClassName(fullClassName)
            .resolveWithJSON<ModeloImpressaoCampos[]>();
    }

    async GetIdByTelaName(telaName: string) {
        try {
            const data = await this.modeloImpressaoService
                .GetPadraoByTelaName(telaName)
                .resolveWithJSON<ModeloImpressaoModel>();
            return data.id;
        } catch {}
        return 0;
    }

    async toHTML() {
        return await this.printByModel.getHtml(this);
    }
}
