import BaseModel from "./base/baseModel";
import TiposFormulaPadrao from "./enum/tiposFormulaPadrao";
import FormaFarmaceuticaModel from "./formaFarmaceuticaModel";
import FormulaPadraoItemModel from "./formulaPadraoItemModel";
import ProdutoModel from "./produto/produtoModel";
import UnidadeMedidaModel from "./unidadeMedidaModel";

export default class FormulaPadraoModel extends BaseModel {
    id?: number = null;
    franquiaId?: number = null;
    descricao: string = null;
    produtoId?: number = null;
    produto: ProdutoModel = null;
    produtoDescricao: string = null;
    tipoFormulaPadrao: TiposFormulaPadrao = TiposFormulaPadrao.Base;
    tipoFormulaPadraoDescricao: string = null;
    validadeDias = 0;
    formaFarmaceuticaId: number = null;
    formaFarmaceutica: FormaFarmaceuticaModel = null;
    volume = 0;
    unidadeMedidaId?: number = 0;
    unidadeMedida: UnidadeMedidaModel = null;
    desmembrarFormula = false;
    observacao: string = null;
    descricaoRotulo: string = null;
    procedimentoProducao: string = null;
    posologiaId: number = null;
    formulaPadraoItens: Array<FormulaPadraoItemModel> = [];
    kitEmbalagemId?: number = null;
    //para quando é capsula
    produtoCapsulaId?: number = null;
    quantidade?: number = null;
    formulaPadraoPaiId?: number = null;
    ativo = true;
    edicaoPreVenda = true;
    permiteQuantidadeDecimal = false;
    posologiaTexto: string = null;
    valorTabelado?: number = null;
}

export interface FormulaPadraoListParameters {
    filtroAtivo?: number;
    tipoFormula?: number;
    filtroNomeAtivo?: boolean;
}
