import { AlinhamentoCss } from "@/models/enum/alinhamento";
import { BasicCssColor } from "@/models/enum/basicColor";
import EnumExtensions from "@/models/enum/extensions/enumExtensions";
import { Fonte } from "@/models/enum/fonte";
import CodigoBarras from "@/models/enum/modeloImpressao/codigoBarras";
import TypeTableLines from "@/models/enum/typeTableLines";
import ModeloImpressaoCampos from "@/models/modeloImpressao/modeloImpressaoCamposModel";
import ModeloImpressao from "@/models/modeloImpressao/modeloImpressaoModel";
import { TypeElement } from "@/models/modeloImpressao/modeloImpressaoSessaoModel";
import CodigosIdentificacaoService from "@/services/codigosIdentificacaoService";
import { mountHtml } from "@/services/print/printerQueue";

export default class PrintModel {
    model: ModeloImpressao = null;

    public async getHtml(model: ModeloImpressao) {
        this.model = new ModeloImpressao();
        this.model.updateFrom(model);

        const html: string[] = [];
        const divs: Array<HTMLElement> = [];

        ////monta formulario
        //passa apenas as sessoes ativar e em ordem de sessao para a variavel
        const sessoes = this.model.sessoes.filter(p => p.ativo).sort((a, b) => a.sessao - b.sessao);

        let grupo = 0;
        if (sessoes.length > 0) {
            grupo = sessoes[0].grupo;
        }

        const dataAtual = new Date();
        let div: HTMLElement = document.createElement("fieldset");
        for (let i = 0; i < sessoes.length; i++) {
            if (sessoes[i].grupo != grupo) {
                divs.push(div);
                div = document.createElement("fieldset");
            }
            if (i == 0) {
                const obj = await this.criaTableElementos(
                    dataAtual.toLocaleString("pt-br"),
                    3,
                    [],
                    0,
                    `text-align:right;font-size: ${this.model.tamanhoFonte}px;`,
                );
                if (obj && obj.hasChildNodes()) {
                    obj.setAttribute("style", "text-align:right;");
                    div.appendChild(obj);
                }
            }
            //quando a sessao possuir labels de formularios
            const descricao = sessoes[i].showDescricao ? sessoes[i].descricao : "";
            if (sessoes[i].tipo == TypeElement.Form) {
                if (sessoes[i].campos.some(p => p.coluna > 0)) {
                    //clonar documento e enviar por parametro para nao afetar a tela
                    const obj = await this.criaTableElementos(
                        descricao,
                        sessoes[i].maxCols,
                        sessoes[i].campos,
                        sessoes[i].typeTableLines,
                        `text-align: center; font-size: ${div.style.fontSize};`,
                    );
                    if (obj && obj.hasChildNodes()) {
                        div.appendChild(obj);
                    }
                }
            }
            //quando a seessao possuir labes de grid(repetições, listas de tabelas e etc...)
            else if (sessoes[i].campos.some(p => p.coluna > 0)) {
                const obj = await this.criaTableGridElements(
                    sessoes[i].maxRows,
                    descricao,
                    sessoes[i].campos,
                    sessoes[i].typeTableLines,
                    this.model.tamanhoFonte,
                );
                if (obj && obj.hasChildNodes()) {
                    div.appendChild(obj);
                }
            }
            grupo = sessoes[i].grupo;
        }
        divs.push(div);
        divs.filter(p => p && p.hasChildNodes()).forEach(obj => html.push(obj.childNodes[0].parentElement.outerHTML));

        return mountHtml(html.join(""), this.getStyleTableBorder(model.fonte));
    }

    public showHTMLOnBrowser(html: string, print = false) {
        const win = window.open("", "Print", "width=1200,height=600");
        win.document.write(html);
        if (print) {
            win.print();
        }
        win.document.close();
    }

    private async criaTableElementos(
        title: string,
        numMaxCols,
        campos: Array<ModeloImpressaoCampos>,
        tipoBorder: TypeTableLines,
        styleTh = "text-align:center;",
    ) {
        const trs: Array<HTMLElement> = [];
        const tds: Array<HTMLElement> = [];

        //revisar nas proximas implementações
        const maxCols = numMaxCols;

        const html = document.createElement("html");

        const table = document.createElement("table");
        table.setAttribute("id", "tableType" + tipoBorder.toString());

        //primeira linha onde será informado o tipo de documento impresso
        const trH = document.createElement("tr");
        const th = document.createElement("th");
        th.setAttribute("style", styleTh);
        th.innerHTML = title;
        trH.appendChild(th);

        if (title && title.trim() != "") table.appendChild(trH);

        html.appendChild(table);

        const maxLinhas = campos.length > 0 ? Math.max(...campos.map(p => p.linha)) : 0;

        //passa pelo filhos dos elementos para montar o html conforme tela
        for (let i = 0; i < maxLinhas; i++) {
            const newTr: HTMLElement = document.createElement("tr");
            if (tds.length > 0) tds.splice(0, tds.length);

            let countValuesValidos = 0;
            //possui colunas ///nao pronto
            const linhas = campos.filter(p => p.linha == i + 1).sort((a, b) => a.coluna - b.coluna);

            if (linhas != null) {
                for (const linha of linhas) {
                    const styles = this.getStyleTd(linha, linha.fontSize ? linha.fontSize : this.model.tamanhoFonte);
                    const label = linha.labelName && linha.labelShowValue ? linha.labelName : "";
                    let value = linha.labelValue || !linha.personalizado ? linha.labelValue : "";
                    //buscar o valor por elemento //todo: buscar do campo valor apenas
                    if (value == null) value = "";

                    if (label.trim() != "" || linha.personalizado || linha.codigoDeBarras > CodigoBarras.Nenhum) {
                        const newTd: HTMLElement = document.createElement("td");
                        const newImg: HTMLElement = document.createElement("img");

                        //remove o loading dos spinners ...rever depoois
                        if (!value) {
                            value = value.replace("Loading...", "");
                        }

                        if (styles != null && styles.trim() != "") {
                            newTd.setAttribute("style", styles);
                        }

                        if (linha.codigoDeBarras > CodigoBarras.Nenhum) {
                            let base64 = "";
                            switch (linha.codigoDeBarras) {
                                case CodigoBarras.CodigoBarra:
                                    base64 = await this.getBarcode(value + "$M");
                                    newImg.setAttribute("style", "height: 40px; width: 150px");
                                    break;
                                case CodigoBarras.QRCode:
                                    base64 = await this.getQRcode(value + "$M");
                                    newImg.setAttribute("style", "height: 100px; width: 100px");
                                    break;
                            }
                            newImg.setAttribute("src", base64);
                            newTd.appendChild(newImg);
                            tds.push(newTd);
                        } else {
                            newTd.innerHTML = (label && label.trim() != "" ? label + ": " : "") + (value ? value : "");
                            tds.push(newTd);
                        }
                        countValuesValidos += 1;
                    }
                }
            }

            tds.forEach(td => {
                //TODO:quando implementado em outras telas, revisar o countValuesValidos e maxCols
                if (countValuesValidos < maxCols)
                    td.setAttribute("colspan", (maxCols - countValuesValidos + 1).toString());

                newTr.appendChild(td);
            });

            trs.push(newTr);
        }

        trs.forEach(tr => {
            table.appendChild(tr);
        });

        th.setAttribute("colspan", maxCols.toString());

        return html;
    }

    private async criaTableGridElements(
        maxRows: number,
        title: string,
        campos: Array<ModeloImpressaoCampos>,
        tipoBorder: TypeTableLines,
        fontSize: number,
    ) {
        const ths: Array<HTMLElement> = [];

        const textarea = document.createElement("textarea");
        const html = document.createElement("html");

        const table = document.createElement("table");
        table.setAttribute("id", "tableType" + tipoBorder.toString());

        const thead = document.createElement("thead");

        const tr = document.createElement("tr");

        //ths monta os labes dos grids, APENAS LINHA 1 ESTA TRATADA CORRETAMENTE
        const colunas = campos.filter(p => p.coluna > 0 && p.linha == 1).sort((a, b) => a.coluna - b.coluna);
        //cores e detalhes do texto de cada campo

        //incluir aqui o title
        if (title && title.trim() != "") {
            const tr = document.createElement("tr");
            const th = document.createElement("th");
            th.innerHTML = title;
            th.style.fontSize = `${fontSize}px`;
            th.setAttribute("colspan", colunas.length.toString());
            tr.appendChild(th);
            thead.appendChild(tr);
        }

        //grava no ths os labels conforme ordem de colunas
        for (const coluna of colunas) {
            const th = document.createElement("th") as HTMLElement;
            th.innerText = coluna.labelName;
            th.style.fontSize = `${coluna.fontSize ? coluna.fontSize : fontSize}px`;
            ths.push(th);
            tr.appendChild(th);
        }

        thead.appendChild(tr);
        table.appendChild(thead);

        //quantidade de registros do grid no for abaixo
        for (let k = 0; k < maxRows; k++) {
            //cria "linha" do registro
            const tbody = document.createElement("tbody");
            const tr = document.createElement("tr");

            //agora busca campo a campo para montar as informacoes na td
            for (let l = 0; l < colunas.length; l++) {
                const td = document.createElement("td");
                td.innerText = "";

                const campo = campos.find(p => p.coluna == l + 1 && p.linha == k + 1);
                if (campo) {
                    const styles = this.getStyleTd(campo, campo.fontSize ? campo.fontSize : this.model.tamanhoFonte);
                    if (styles != null && styles.trim() != "") {
                        td.setAttribute("style", styles);
                    }

                    if (campo.codigoDeBarras > CodigoBarras.Nenhum) {
                        const newImg: HTMLElement = document.createElement("img");

                        let base64 = "";
                        switch (campo.codigoDeBarras) {
                            case CodigoBarras.CodigoBarra:
                                base64 = await this.getBarcode(campo.labelValue + "$M");
                                newImg.setAttribute("style", "height: 40px; width: 150px");
                                break;
                            case CodigoBarras.QRCode:
                                base64 = await this.getQRcode(campo.labelValue + "$M");
                                newImg.setAttribute("style", "height: 100px; width: 100px");
                                break;
                        }
                        newImg.setAttribute("src", base64);
                        td.appendChild(newImg);
                    } else {
                        textarea.innerHTML = campo.labelValue ?? "";
                        td.innerText = textarea.textContent;
                    }
                }

                tr.appendChild(td);
            }
            tbody.appendChild(tr);
            table.appendChild(tbody);
        }

        html.appendChild(table);

        return html;
    }

    private getStyleTd(campo: ModeloImpressaoCampos, fontSize: number) {
        //cores e detalhes do texto de cada campo
        //s cor preta e fundo preto, passa o fundo para branco
        if (campo.colorText == 0 && campo.colorBGText == 0) {
            campo.colorBGText = 14;
        }

        if (!campo.labelValue) {
            campo.labelValue = "";
        }

        if (!campo.labelName) {
            campo.labelName = "";
        }

        if (typeof campo.labelValue == "string" && campo.labelValue.trim() == "" && campo.labelName.trim() == "") {
            campo.colorText = campo.colorBGText;
            campo.labelName = "|"; //para dar tamanho a linha se for o unico label dela
        }
        const corCssText = EnumExtensions.getNameByValue(BasicCssColor, <number>campo.colorText);
        const corBGCssText = EnumExtensions.getNameByValue(BasicCssColor, <number>campo.colorBGText);
        const alignCss = EnumExtensions.getNameByValue(AlinhamentoCss, <number>campo.alinhamento);

        return (
            "font-size:" +
            fontSize +
            "px;" +
            "color:" +
            corCssText +
            "; background-color:" +
            corBGCssText +
            ";" +
            "text-align:" +
            alignCss +
            ";" +
            (campo.bold ? " font-weight: bold" : "")
        );
    }

    private getFontFamily(fonteId: Fonte) {
        switch (fonteId) {
            case 1:
                return "Calibri";
            case 2:
                return "Monospace";
            case 3:
                return "Courier Prime";
            case 4:
                return "Helvetica";
            case 5:
                return "Times";
            default:
                return "Arial";
        }
    }

    private async getBarcode(dataBarcode: string): Promise<string> {
        return await new CodigosIdentificacaoService().getBarCode(dataBarcode).resolveWithText();
    }

    private async getQRcode(dataBarcode: string): Promise<string> {
        return await new CodigosIdentificacaoService().getQrCode(dataBarcode).resolveWithText();
    }

    private getStyleTableBorder(font: Fonte) {
        const fontFamily = this.getFontFamily(font);
        const style = `
            body {
                font-family: ${fontFamily};
            }
            fieldset {
                border-width: 0px;
                page-break-before: always;
            }
            table {
                width: 100%;
                border-collapse: collapse;
            }
            td {
                padding: 4px;
            }
            th {
                padding: 4px;
                text-align: left;
            }
            .branco {
                color: rgba(255, 255, 255, 0);
            }
            table#tableType1 td {
                border: 1px solid #808080;
            }
            table#tableType1 th {
                border: 1px solid #808080;
            }
            table#tableType2 td {
                border-bottom: 1px solid #808080;
                border-top: 1px solid #808080;
            }
            table#tableType2 th {
                border-bottom: 1px solid #808080;
            }
            table#tableType3 td {
                border-right: 1px solid #808080;
                border-left: 1px solid #808080;
            }
            table#tableType3 th {
                border-right: 1px solid #808080;
                border-left: 1px solid #808080;
            }
            table#tableType4 td {
                border-top: 1px solid #808080;
            }
            table#tableType4 th {
                border-top: 1px solid #808080;
            }
            table#tableType5 td {
                border-bottom: 1px solid #808080;
            }
            table#tableType5 th {
                border-bottom: 1px solid #808080;
            }
        `;

        return style;
    }
}
