import AdicoesDIModel from "./adicoesDIModel";
import BaseModel from "./base/baseModel";
import NotaFiscalEntradaItemImpostoModel from "./notaFiscalEntradaItemImpostoModel";
import ProdutoModel from "./produto/produtoModel";
import ProdutoLoteModel from "./produtoLoteModel";

export default class NotaFiscalEntradaItemModel extends BaseModel {
    id?: number = null;
    codigo: string = null;
    dataFabricacao: Date = null;
    dataValidade: Date = null;
    descricao: string = null;
    notaFiscalEntradaId?: number = null;
    produtoId?: number = null;
    produto: ProdutoModel = new ProdutoModel();
    produtoDescricao: string = null;
    quantidadeOrigem = 0;
    quantidade = 0;
    unidadeMedida = "";
    unidadeMedidaId?: number = 0;
    unidadeMedidaOrigemId?: number = 0;
    produtoLoteId?: number = 0;
    produtoLote = new ProdutoLoteModel();
    valorUnitarioOrigem: number = null;
    valorUnitario: number = null;
    valorTotal: number = null;
    notaFiscalEntradaItemImpostos: Array<NotaFiscalEntradaItemImpostoModel> = [];

    codigoNCM: string = null;
    codigoCest: string = null;
    valorProdutos: number = null;
    informacaoAdicional: string = null;
    codigoBeneficio: string = null;
    unidadeMedidaSigla = "";
    EAN: number = null;
    NcmId: number = null;
    valorFrete: number = null;
    valorSeguro: number = null;
    valorOutraDespesa: number = null;
    valorDesconto: number = null;

    baseICMS: number = null;
    aliquotaICMS: number = null;
    valorICMS: number = null;
    percentualDiferimento: number = null;
    valorICMSDiferido: number = null;
    valorICMSPagar: number = null;
    percentualReducaoBase: number = null;
    baseReduzida: number = null;
    OrigemMercadoria: number = null;
    CstICMS: number = null;

    comICMSPartilha = false;
    baseUFDestino: number = null;
    baseFCPUFDestino: number = null;
    aliquotaFCPUFDestino: number = null;
    aliquotaUFDestino: number = null;
    aliquotaInterestadual: number = null;
    aliquotaInterestadualPartilha: number = null;
    iCMSFCPUFDestino: number = null;
    iCMSUFDestino: number = null;
    iCMSUFRemetente: number = null;

    baseII: number = null;
    valorDespesasAduaneiras: number = null;
    valorII: number = null;
    valorIOF: number = null;
    taxaSISCOMEX: number = null;

    sujeitoIPI = false;
    baseIPI: number = null;
    aliquotaIPI: number = null;
    valorIPI: number = null;
    CstIPI: number = null;

    basePIS: number = null;
    aliquotaPIS: number = null;
    valorPIS: number = null;
    CstPIS: number = null;

    baseCOFINS: number = null;
    aliquotaCOFINS: number = null;
    valorCOFINS: number = null;
    CstCOFINS: number = null;

    numeroDI: string = null;
    dataDI: string = null;
    codigoExportador: string = null;
    viaTransporte: number = null;
    valorAFRMM: number = null;
    tipoIntermedio: number = null;
    localDesembaraco: string = null;
    ufDesembaraco: string = null;
    dataDesembaraco: string = null;
    adicoes: AdicoesDIModel[] = [];

    // devolução
    quantidadeADevolver: number = null;
    quantidadeADevolverEstoque: number = null;
}
