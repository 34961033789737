import Vue from "vue";
import { mapGetters, mapState } from "vuex";

import comboComponent from "@/components/child/form/combo.vue";
import dateTimePickerComponent from "@/components/child/form/datetimepicker.vue";
import decimalComponent from "@/components/child/form/decimal.vue";
import decimalComSinalCustomComponent from "@/components/child/form/decimalComSinalCustom.vue";
import moedaComponent from "@/components/child/form/moeda.vue";
import ModalComponent from "@/components/child/modal/modal";
import modalComponent from "@/components/child/modal/modal.vue";
import { Component, Watch } from "@/decorators";
import PreLoadPackModel from "@/models/auxiliar/preLoadPackModel";
import ConfiguracaoFranquiaModel from "@/models/configuracaoFranquia/configuracaoFranquiaModel";
import Configuracoes from "@/models/enum/configuracao/configuracoes";
import OrigemProdutoLote from "@/models/enum/origemProdutoLote";
import StatusProdutoLote from "@/models/enum/statusProdutoLote";
import TiposMovimetacaoEstoque from "@/models/enum/tiposMovimentacaoEstoque";
import MovimentacaoEstoqueItemModel from "@/models/movimentacaoEstoqueItemModel";
import PaginationModel from "@/models/paginationModel";
import ProdutoModel from "@/models/produto/produtoModel";
import ProdutoLoteModel from "@/models/produtoLoteModel";
import ValidationErrorModel from "@/models/validationErrorModel";
import ProdutoService from "@/services/produto/produtoService";
import ProdutoLoteService from "@/services/produtoLoteService";
import { AppState, Getters } from "@/store/store";
import { getProdutoCombo, getProdutoLoteCombo } from "@/utils/common/combo/combotext";
import Delay from "@/utils/common/delay";
import ValidationErrorWrapper from "@/wrappers/validationErrorWrapper";

import ProdutoLoteComponent from "../produtolote/edit";
import produtoLoteComponent from "../produtolote/edit.vue";

@Component({
    components: {
        comboComponent,
        dateTimePickerComponent,
        moedaComponent,
        modalComponent,
        decimalComponent,
        decimalComSinalCustomComponent,
        produtoLoteComponent,
    },
    computed: {
        ...mapState({
            preLoadPack: (state: AppState) => state.preLoad.preLoadList,
            loadedList: (state: AppState) => state.preLoad.loadedList,
        }),
        ...mapGetters(["GET_CONFIG_FRANQUIA"] as Getters),
    },
})
export default class MovimentacaoEstoqueItemComponent extends Vue {
    // State computed props
    preLoadPack: PreLoadPackModel;
    loadedList: boolean;
    GET_CONFIG_FRANQUIA: (configuracao: Configuracoes) => ConfiguracaoFranquiaModel;

    private validationErrorWrapper = new ValidationErrorWrapper(this.$validator);
    private modalComponent: ModalComponent = null;
    private produtoLoteComponent: ProdutoLoteComponent = null;

    private produtoLoteService = new ProdutoLoteService();

    model = new MovimentacaoEstoqueItemModel();
    tipoMovimentacao = TiposMovimetacaoEstoque.Entrada;
    tiposMovimentacao = TiposMovimetacaoEstoque;
    lotesOptions: Array<Object> = [];
    produtosOptions: Array<Object> = [];
    private signal = "";
    private estoqueId: number;
    private alterouLote = false;

    public show(tipoMovimentacao: TiposMovimetacaoEstoque, estoqueId: number) {
        this.estoqueId = estoqueId;
        this.tipoMovimentacao = tipoMovimentacao;
        this.modalComponent.show();
        this.model = new MovimentacaoEstoqueItemModel();

        this.loadLotes();
    }

    public hide() {
        this.modalComponent.hide();
    }

    private async loadLotes() {
        try {
            const data = await this.produtoLoteService
                .combo(this.tipoMovimentacao !== TiposMovimetacaoEstoque.Entrada ? this.estoqueId : 0)
                .withLoading()
                .resolveWithJSON<PaginationModel<ProdutoLoteModel>>();

            this.lotesOptions = data.list.map(item => ({
                ...getProdutoLoteCombo(item),
                text: `${item.descricao} - ${item.fornecedorNome}`,
            }));
        } catch {}
    }

    private async loadProdutos() {
        try {
            const data = await new ProdutoService().combo().resolveWithJSON<PaginationModel<ProdutoModel>>();

            this.produtosOptions = data.list.map(item => ({
                ...getProdutoCombo(item),
                unidadeMdedidaEstoque: item.unidadeMedidaEstoqueId,
            }));
        } catch {}
    }

    private async onBtnOkClick() {
        try {
            const isValid = await this.$validator.validateAll();
            if (!isValid) {
                return;
            }

            const data = await this.produtoLoteService
                .validateModel({ ...this.model.produtoLote, id: null } as ProdutoLoteModel)
                .withLoading()
                .resolveWithJSON<ValidationErrorModel[]>();

            if (data.length == 0)
                if (
                    this.tipoMovimentacao != TiposMovimetacaoEstoque.Entrada &&
                    this.model.produtoLote.quantidadeAtual - this.model.quantidade < 0
                ) {
                    const response = await this.$showQuestion(
                        this.$t("__.ts.qtdDisponivelInsulf"),
                        this.$t("__.ts.oLote") + this.model.produtoLote.descricao + this.$t("__.ts.naoPossuiQtdSufic"),
                    );
                    if (response) {
                        this.$emit("inclusao-item", this.model);
                    }
                } else {
                    this.$emit("inclusao-item", this.model);
                }
            else {
                this.validationErrorWrapper.showErrors(data);
            }

            this.produtosOptions = this.preLoadPack.produtos.map(item => ({
                ...getProdutoCombo(item),
                unidadeMdedidaEstoque: item.unidadeMedidaEstoqueId,
            }));
        } catch {}
    }

    @Watch("model.produtoLoteId")
    private async onChangeProdutoLoteId() {
        if (!this.model.produtoLoteId) {
            return;
        }

        this.alterouLote = true;

        try {
            if (this.estoqueId === this.GET_CONFIG_FRANQUIA(Configuracoes.EstoquePadrao).estoqueId) {
                const loteEmFracionamento = await this.produtoLoteService
                    .verificaLoteEmFracionamento(this.model.produtoLoteId)
                    .withLoading()
                    .resolveWithJSON<boolean>();

                if (
                    loteEmFracionamento &&
                    (this.tipoMovimentacao === TiposMovimetacaoEstoque.Saida ||
                        this.tipoMovimentacao === TiposMovimetacaoEstoque.Transferencia)
                ) {
                    this.model.produtoLoteId = null;
                    this.lotesOptions = [];
                    this.model.produtoId = null;
                    this.produtosOptions = [];
                    await this.$showWarning(this.$t("__.ts.aviso"), this.$t("__.ts.msgLoteEmFracionamento"));
                    return;
                }
            }
            const data = await this.produtoLoteService
                .get(this.model.produtoLoteId)
                .withLoading()
                .resolveWithJSON<ProdutoLoteModel>();

            if (data.statusProdutoLote == StatusProdutoLote.Quarentena) {
                this.model.produtoLoteId = null;
                return this.$showWarning(this.$t("__.ts.atencao"), this.$t("__.ts.msgStatusQuarentena"));
            }
            this.model.produtoLote.updateFrom(data);

            if (this.model.produtoLote.estoqueProdutoLote.some(x => x.estoqueId == this.estoqueId)) {
                this.model.produtoLote.quantidadeAtual = this.model.produtoLote.estoqueProdutoLote.find(
                    x => x.estoqueId == this.estoqueId,
                ).estoqueAtual;
            } else {
                this.model.produtoLote.quantidadeAtual = 0;
            }

            if (this.tipoMovimentacao == TiposMovimetacaoEstoque.Entrada) {
                this.model.valorUnitario = data.valorCusto > 0 ? data.valorCusto : data.produtoValorCusto;
            } else if (this.tipoMovimentacao == TiposMovimetacaoEstoque.Saida) {
                this.model.valorUnitario = data.produtoValorVenda;
            }

            this.model.produtoId = this.model.produtoLote.produtoId;
            this.produtosOptions = [];
            this.produtosOptions[0] = { value: this.model.produtoId, text: this.model.produtoLote.produtoDescricao };
            this.model.loteDescricao = this.model.produtoLote.descricao;
            this.model.produtoDescricao = this.model.produtoLote.produtoDescricao;
        } catch {}
        this.getSignal();
    }

    @Watch("model.produtoId")
    private async onIdProdutoChanged() {
        if (!this.model.produtoId) {
            return;
        }

        this.getSignal();
        if (!this.alterouLote) {
            try {
                const data = await this.produtoLoteService
                    .getByProdutoId(
                        this.model.produtoId,
                        this.tipoMovimentacao !== TiposMovimetacaoEstoque.Entrada ? this.estoqueId : 0,
                    )
                    .withLoading()
                    .resolveWithJSON<PaginationModel<ProdutoLoteModel>>();

                this.lotesOptions = data.list.map(item => ({
                    ...getProdutoLoteCombo(item),
                    text: `${item.descricao} - ${item.fornecedorNome}`,
                }));
            } catch {}
        }

        this.alterouLote = false;
    }

    private getSignal() {
        this.signal = "";

        if (this.model.produtoId > 0) {
            const produto = this.preLoadPack.produtos.find(p => p.id == this.model.produtoId);
            if (produto) {
                this.signal = this.preLoadPack.unidadesMedida.find(p => p.id == produto.unidadeMedidaEstoqueId)?.sigla;
            }
        }

        if (this.model.produtoLote.origemProdutoLote == OrigemProdutoLote.Fracionamento) {
            this.signal = "un";
        }
    }

    private onCarregaLote() {
        this.produtoLoteComponent.modelBasePreencherLote = new ProdutoLoteModel();
        this.produtoLoteComponent.modelBasePreencherLote.produtoId = this.model.produtoId;
        this.produtoLoteComponent.modelBasePreencherLote.descricao = "Lote";
        this.produtoLoteComponent.load();
    }

    private onAddNewProdutoLote() {
        this.produtoLoteComponent.save();
    }

    private async openComboNewProdutoLote() {
        while (!this.$refs.produtoLoteComponent) await Delay(5);
        this.produtoLoteComponent = this.$refs.produtoLoteComponent as ProdutoLoteComponent;

        this.produtoLoteComponent.modelId = 0;
        this.produtoLoteComponent.load();
    }

    private async openComboEditProdutoLote(id) {
        while (!this.$refs.produtoLoteComponent) await Delay(5);
        this.produtoLoteComponent = this.$refs.produtoLoteComponent as ProdutoLoteComponent;

        this.produtoLoteComponent.modelId = id;
        this.produtoLoteComponent.load();
    }

    private async onProdutoLoteSaveOk(modelNovoProdutoLote: ProdutoLoteModel) {
        try {
            const data = await this.produtoLoteService
                .getByProdutoId(modelNovoProdutoLote.produtoId)
                .withLoading()
                .resolveWithJSON<PaginationModel<ProdutoLoteModel>>();
            this.lotesOptions = data.list.map(item => ({
                ...getProdutoLoteCombo(item),
                text: `${item.descricao} - ${item.fornecedorNome}`,
            }));

            setTimeout(() => {
                const lote = data.list.filter(p => p.descricao == modelNovoProdutoLote.descricao);
                if (lote.length > 0) {
                    this.model.produtoLoteId = lote[0].id;
                    this.model.produtoLote.id = lote[0].id;
                }
            }, 250);
        } catch {}
        this.getSignal();

        this.model.produtoId = modelNovoProdutoLote.produtoId;
    }

    private async getPreLoadPack() {
        while (!this.loadedList) await Delay(100);

        this.produtosOptions = this.preLoadPack.produtos.map(item => ({
            ...getProdutoCombo(item),
            unidadeMdedidaEstoque: item.unidadeMedidaEstoqueId,
        }));
    }

    private async mounted() {
        this.modalComponent = this.$refs.modalComponent as ModalComponent;

        this.signal = "";

        await this.getPreLoadPack();
    }
}
