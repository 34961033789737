import BaseModel from "./base/baseModel";
import CompraFornecedorModel from "./compraFornecedorModel";
import CompraItemModel from "./compraItemModel";
import SituacaoCompra from "./enum/situacaoCompra";
import TiposCompra from "./enum/tiposCompra";

export default class CompraModel extends BaseModel {
    id?: number = null;
    tipoCompra: TiposCompra = null;
    dataInicial: Date = null;
    dataFinal: Date = null;
    dias: number = null;
    margemTolerancia = 20;
    codigo: number = null;
    situacao: SituacaoCompra = SituacaoCompra.Orcamento;
    situacaoDescricao: string = null;
    itens: Array<CompraItemModel> = null;
    fornecedores?: Array<CompraFornecedorModel> = null;
}
