import comboComponent from "@/components/child/form/combo.vue";
import { Component } from "@/decorators";
import ListaControladoModel from "@/models/produto/listaControladoModel";
import ListaControladoService from "@/services/produto/listaControladoService";

import EditComponentBase from "../editComponentBase";

@Component({
    components: {
        comboComponent,
    },
})
export default class ListaControladoEditComponent extends EditComponentBase<ListaControladoModel> {
    classeTerapeuticaOptions: Array<Object> = [
        { text: "Antimicrobiano", value: 1 },
        { text: "Sujeito A Controle Especial", value: 2 },
    ];

    private async beforeMount() {
        await this.setProps(
            new ListaControladoService(),
            "listas_de_controlado",
            new ListaControladoModel(),
            false,
            true,
        );
    }
}
