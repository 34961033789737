import BaseModel from "../base/baseModel";

export default class ClinicaPrescritorModel extends BaseModel {
    constructor() {
        super();
    }

    id?: number = null;
    dataCriacao: Date = null;
    dataModificacao: Date = null;
    usuarioCriacaoId: number = null;
    usuarioModificacaoId: number = null;
    prescritorId: number = null;
    prescritorNome: string = null;
    clinicaId: number = null;
}
