import Vue from "vue";

import comboComponent from "@/components/child/form/combo.vue";
import { Component } from "@/decorators";
import EstoqueModel from "@/models/estoqueModel";
import FormulaPadraoManipulacaoModel from "@/models/formulaPadraoManipulacaoModel";
import FormulaPadraoModel from "@/models/formulaPadraoModel";
import ManipulacaoOrdemModel from "@/models/manipulacaoOrdemModel";
import PaginationModel from "@/models/paginationModel";
import EstoqueService from "@/services/estoqueService";
import FormulaPadraoManipulacaoService from "@/services/formulaPadraoManipulacaoService";
import FormulaPadraoService from "@/services/formulaPadraoService";

@Component({
    components: {
        comboComponent,
    },
})
export default class PreVendaComponent extends Vue {
    private service = new FormulaPadraoService();
    private serviceEstoque = new EstoqueService();

    serviceFormPadraoManipulacao = new FormulaPadraoManipulacaoService();
    formulaPadraoOptions: Array<Object> = [];
    modelArray: Array<FormulaPadraoManipulacaoModel> = [];

    public formulapadraoId: number = null;

    public async onAdicionarFormulaPadrao(estoqueId: number) {
        const isValid = await this.$validator.validateAll();
        if (isValid) {
            const response = await this.convertFormPadraoEmManipulacao(estoqueId).withLoading();

            if (response) {
                this.$emit("inclusao-item", response);
                this.clear();
                return true;
            }
        }

        return false;
    }

    private async clear() {
        this.formulapadraoId = null;
    }

    private async loadFormulasPadrao() {
        try {
            const data = await this.service.listPreVendaFormulaPadrao().resolveWithJSON<FormulaPadraoModel[]>();
            this.formulaPadraoOptions = data.map(p => ({ value: p.id, text: p.descricao }));
        } catch {}
    }

    private async convertFormPadraoEmManipulacao(estoqueId: number) {
        try {
            const data = await this.service.get(this.formulapadraoId).resolveWithJSON<FormulaPadraoModel>();

            const manipulacao = await this.serviceFormPadraoManipulacao
                .convertFormulaPadraoEmManipulacao(data, estoqueId)
                .resolveWithJSON<ManipulacaoOrdemModel>();

            manipulacao.isPreVendaEditavel = data.edicaoPreVenda;
            manipulacao.homeopatia = manipulacao.itens.some(s => s.tipoCalculo > 5);
            manipulacao.itens.forEach(p => {
                p.produtoDescricao = p.produto.descricao;
            });
            return manipulacao;
        } catch {}

        return false;
    }

    private async estoqueFranquia() {
        const response = await this.serviceEstoque.listByFranquiaId();
        if (response.ok) {
            const data = (await response.json()) as PaginationModel<EstoqueModel>;
            const estoquePadrao = data.list.filter(x => x.descricao == (this.$t("__.ts.estqPadrao") as string));
            return estoquePadrao[0].id;
        }
    }

    private async onAlertQuantidadeInsuficiente(
        produto: string,
        msgFinal = " foi alterado a quantidade para a maxima disponivel.",
    ) {
        await this.$showError(
            this.$t("__.ts.atencao") as string,
            (this.$t("__.ts.naoHaQtd") as string) + produto + msgFinal,
        );
    }

    async mounted() {
        await this.clear();
        await this.loadFormulasPadrao();
    }
}
