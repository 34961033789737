import ManipulacaoOrdemPCPModel from "@/models/pcp/manipulacaoOrdemPCPModel";
import { convertToDateInputString } from "@/utils/common/date";
import nfetch from "@/utils/events/nfetch";

import { IService } from "../base/iService";

export default class ManipulacaoOrdemPCPService extends IService<ManipulacaoOrdemPCPModel> {
    constructor() {
        super("ManipulacaoOrdemPCP");
    }

    public getByManipulacaoOrdem(manipulacaoId: number): Promise<Response> {
        return nfetch(`/${this.controllerName}/GetByManipulacaoOrdem?id=${manipulacaoId}`, {
            credentials: "same-origin",
        });
    }

    public getByEtapa(etapaId: number, venda: number, dataInicial: Date, dataFinal: Date): Promise<Response> {
        const dataInicialString = convertToDateInputString(dataInicial);
        const dataFinalString = convertToDateInputString(dataFinal);
        return nfetch(
            `/${this.controllerName}/GetByEtapa?etapaId=${etapaId}&vendaCodigo=${venda}&dataInicial=${dataInicialString}&dataFinal=${dataFinalString}`,
            {
                credentials: "same-origin",
            },
        );
    }

    public getByFilter(filterKey: string): Promise<Response> {
        return nfetch(`/${this.controllerName}/GetByFilter?filterKey=${filterKey}`, {
            credentials: "same-origin",
        });
    }

    public updateMany(ids: number[], etapaPcpDestinoId: number): Promise<Response> {
        return nfetch(`/${this.controllerName}/UpdateMany?etapaDestinoId=${etapaPcpDestinoId}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            credentials: "same-origin",
            body: JSON.stringify(ids),
        });
    }
}
