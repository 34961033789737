import Vue from "vue";
import { mapMutations, mapGetters } from "vuex";

import actionBarComponent from "@/components/child/actionBar/actionBar.vue";
import comboComponent from "@/components/child/form/combo.vue";
import decimalComponent from "@/components/child/form/decimal.vue";
import fieldsetComponent from "@/components/child/form/fieldset.vue";
import { Component } from "@/decorators";
import OperacaoesMatematicas from "@/models/enum/operacaoMatematica";
import UnidadesMedida from "@/models/enum/unidadesMedida";
import UnidadeMedidaModel from "@/models/unidadeMedidaModel";
import UnidadeMedidaService from "@/services/unidadeMedidaService";
import { Getters } from "@/store/store";

import "../crud.scss";

@Component({
    components: {
        comboComponent,
        fieldsetComponent,
        decimalComponent,
        actionBarComponent,
    },
    computed: {
        ...mapGetters(["HAS_PERMISSAO_PERFIL"] as Getters),
    },
    methods: mapMutations(["LOAD_LIST"]),
})
export default class UnidadeMedidaEditComponent extends Vue {
    // State computed props
    HAS_PERMISSAO_PERFIL: (isRede: boolean, isFranqueador: boolean, isSuporte: boolean) => Promise<boolean>;

    private service = new UnidadeMedidaService();

    model = new UnidadeMedidaModel();
    modelId = 0;
    desabilitarCampos = false;

    unidadeMedidaOptions: Array<Object> = [
        { text: "Volume", value: UnidadesMedida.Volume },
        { text: "Unidade", value: UnidadesMedida.Unidade },
        { text: "Massa", value: UnidadesMedida.Massa },
        { text: "UI", value: UnidadesMedida.UI },
        { text: "UFC", value: UnidadesMedida.UFC },
        { text: "UTR", value: UnidadesMedida.UTR },
    ];

    operadorOptions: Array<Object> = [
        { text: "Multiplicar", value: OperacaoesMatematicas.Multiplicar },
        { text: "Dividir", value: OperacaoesMatematicas.Dividir },
    ];

    private async load() {
        this.model = new UnidadeMedidaModel();

        if (this.modelId > 0) {
            try {
                const data = await this.service.get(this.modelId).withLoading().resolveWithJSON<UnidadeMedidaModel>();

                this.model.updateFrom(data);
                await this.validarPermissaoEdicao();
            } catch {
                this.$router.back();
            }
        }
    }

    private async validarPermissaoEdicao() {
        this.desabilitarCampos =
            !(await this.HAS_PERMISSAO_PERFIL(false, false, false)) && this.model.franquiaId == null;
    }

    private async save() {
        try {
            const isValid = await this.$validator.validateAll();
            if (isValid) {
                const sucesso = await this.service[!this.model.id ? "insert" : "update"](this.model)
                    .withLoading()
                    .resolveWithoutJSON();

                if (sucesso) {
                    this["LOAD_LIST"]({ loadUnidadeMedida: true });
                    await this.$showInclusaoUpdate(this.model.id);
                    this.$router.back();
                }
            } else {
                this.$focusErrorField();
            }
        } catch {}
    }

    private mostrarMensagemConversao() {
        let tipoUnidade: string;
        let unidadePadrao: string;
        let exemplo: string;

        switch (this.model.unidadeMedida) {
            case UnidadesMedida.Massa:
                tipoUnidade = this.$t("__.ts.massa").toString();
                unidadePadrao = this.$t("__.ts.grama").toString();
                exemplo = this.$t("__.ts.exKg").toString();
                break;
            case UnidadesMedida.Volume:
                tipoUnidade = this.$t("__.ts.volume").toString();
                unidadePadrao = this.$t("__.ts.mililitro").toString();
                exemplo = this.$t("__.ts.exMl").toString();
                break;
            case UnidadesMedida.Unidade:
                tipoUnidade = this.$t("__.ts.unidade").toString();
                unidadePadrao = this.$t("__.ts.un").toString();
                exemplo = this.$t("__.ts.cxUn").toString();
                break;
            case UnidadesMedida.UI:
                tipoUnidade = this.$t("__.ts.ui").toString();
                unidadePadrao = this.$t("__.ts.ui").toString();
                exemplo = this.$t("__.ts.exUI").toString();
                break;
            case UnidadesMedida.UFC:
                tipoUnidade = this.$t("__.ts.ufc").toString();
                unidadePadrao = this.$t("__.ts.ufc").toString();
                exemplo = this.$t("__.ts.exUFC").toString();
                break;
            case UnidadesMedida.UTR:
                tipoUnidade = this.$t("__.ts.utr").toString();
                unidadePadrao = this.$t("__.ts.utr").toString();
                exemplo = this.$t("__.ts.exUTR").toString();
                break;
            default:
                return null;
        }

        return (
            this.$t("__.ts.unMedidaPadrDe") +
            tipoUnidade +
            this.$t("__.ts.eh") +
            unidadePadrao +
            this.$t("__.ts.informeOpEValor") +
            exemplo
        );
    }

    private cancel() {
        this.$router.back();
    }

    private mounted() {
        if (this.$route.params.id) {
            this.modelId = +this.$route.params.id;
            this.load();
        }
    }
}
