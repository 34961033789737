import Vue from "vue";

import Moment from "moment";

import buttonScComponent from "@/components/child/form/buttonSc.vue";
import comboComponent from "@/components/child/form/combo.vue";
import moedaComponent from "@/components/child/form/moeda.vue";
import gridComponent from "@/components/child/grid/grid.vue";
import { GridAction } from "@/components/child/grid/gridAction";
import { GridColors, GridColumn, GridColumnType } from "@/components/child/grid/gridColumn";
import { Component, Prop } from "@/decorators";
import ContaReceberModel from "@/models/financeiro/contaReceberModel";
import { AlertColor } from "@/utils/common/alert";
import arithmeticHelper from "@/utils/common/arithmeticHelper";
import Delay from "@/utils/common/delay";

import RecebimentoComponent from "../../recebimentos/list";
import recebimentoComponent from "../../recebimentos/list.vue";
import ShortcutComponent from "../../shortcut/shortcut";
import shortcutComponent from "../../shortcut/shortcut.vue";
import PagamentoComponent from "../pagamento";
import pagamentoComponent from "../pagamento.vue";

import ParcelaComponent from "./parcela";
import parcelaComponent from "./parcela.vue";
import ParcelamentoComponent from "./parcelamento";
import parcelamentoComponent from "./parcelamento.vue";

import "../../crud/crud.scss";

@Component({
    components: {
        comboComponent,
        moedaComponent,
        buttonScComponent,
        gridComponent,
        shortcutComponent,
        recebimentoComponent,
        pagamentoComponent,
        parcelaComponent,
        parcelamentoComponent,
    },
})
export default class FinanceiroComponent extends Vue {
    private shortcutComponentParcela: ShortcutComponent = null;
    private shortcutComponentParcelamento: ShortcutComponent = null;
    private shortcutComponentRecebimento: ShortcutComponent = null;
    private parcelaComponent: ParcelaComponent = null;
    private parcelamentoComponent: ParcelamentoComponent = null;
    private pagamentoComponent: PagamentoComponent = null;
    private recebimentoComponent: RecebimentoComponent = null;

    model = new ContaReceberModel();

    @Prop(Number) situacaoVenda: number;
    @Prop(Number) codigoVenda: number;
    @Prop({ type: Boolean, default: false }) isConsulta: boolean;
    @Prop({ type: Boolean, default: true }) permiteRemover: boolean;

    private gridData = [];
    private addRecebimentoNaParcelaIndex = null;
    private editingParcelaIndex = null;
    private saldoTotal = 0;
    private desconto = 0;
    private acrescimo = 0;

    private extraActions: Array<GridAction[]> = [];
    private extraActionsPagar: Array<GridAction> = [];

    get gridColumnsParcelas(): Array<GridColumn> {
        return [
            new GridColumn("descricao", this.$t("__.ts.descricao") as string, GridColumnType.String),
            new GridColumn("dataVencimento", this.$t("__.ts.vencimento") as string, GridColumnType.Date),
            new GridColumn("valorTotal", this.$t("__.ts.vlr") as string, GridColumnType.Money),
            new GridColumn("saldoGrid", this.$t("__.ts.saldo") as string, GridColumnType.Money),
        ];
    }

    get gridColumnsRecebimentos(): Array<GridColumn> {
        return [
            new GridColumn("dataMovimento", this.$t("__.ts.recebimento") as string, GridColumnType.Date),
            new GridColumn("formaPagamentoDescricao", this.$t("__.ts.formPgto") as string, GridColumnType.String),
            new GridColumn("desconto", this.$t("__.ts.descont") as string, GridColumnType.Money),
            new GridColumn("juros", this.$t("__.ts.juros") as string, GridColumnType.Money),
            new GridColumn("valor", this.$t("__.ts.vlr") as string, GridColumnType.Money),
            new GridColumn("observacao", this.$t("__.ts.observacao") as string, GridColumnType.String),
        ];
    }

    private conditionShowSubData(data: object) {
        return data["movimentacoes"] && data["movimentacoes"]["length"] > 0;
    }

    private async loadGridData() {
        this.gridData = [];

        this.extraActions.splice(0);
        this.extraActionsPagar.splice(0);

        if (this.model.lancamentosVinculados.length > 0) {
            this.gridData = this.model.lancamentosVinculados;

            this.model.lancamentosVinculados.forEach(p => {
                let action = new GridAction(
                    "add-recebimento",
                    "Adicionar recebimento",
                    "fa fa-money-bill",
                    GridColors.GREEN,
                );
                this.calcularSaldoParcela(p);

                if (p.saldoGrid == 0) {
                    action = new GridAction("", "Pago", "fa fa-check", GridColors.GREEN);
                }

                this.extraActionsPagar.push(action);
            });

            this.extraActions.push(this.extraActionsPagar);
        } else {
            while (!this.$refs.recebimentoComponent) await Delay(5);
            this.recebimentoComponent = this.$refs.recebimentoComponent as RecebimentoComponent;

            this.recebimentoComponent.show(this.model.movimentacoes);
        }
    }

    private onExtraActions(name: string, data: ContaReceberModel) {
        if (name.trim() == "add-recebimento") {
            this.showAddRecebimento();

            const valorJaPago = data.movimentacoes.reduce((acc, p) => acc + p.valor, 0);
            this.pagamentoComponent.load(data["valorTotal"] - valorJaPago);

            this.addRecebimentoNaParcelaIndex = this.gridData.indexOf(data);
        }
    }

    private onEdit(data: object) {
        if (this.model.lancamentosVinculados.length > 0) {
            this.shortcutComponentParcela.show();
            this.shortcutComponentParcela.title = this.$t("__.ts.addParcela") as string;

            this.parcelaComponent.model = { ...data } as ContaReceberModel;
            this.editingParcelaIndex = this.gridData.indexOf(data);
        }
    }

    private onRemovePagamento(model: object) {
        if (this.model) {
            this.model.movimentacoes = this.model.movimentacoes.filter(p => p != model);
            this.loadGridData();
        }
    }

    private async onRemove(data: object) {
        if (data["saldoGrid"] == data["valorTotal"]) {
            const index = this.gridData.indexOf(data);

            if (this.model.lancamentosVinculados.length > 0) {
                this.model.lancamentosVinculados.splice(index, 1);
            } else {
                this.model.movimentacoes.splice(index, 1);
            }

            this.loadGridData();
        } else {
            await this.$showAlert({
                title: "Parcela não pode ser excluída",
                html: `Não é possível excluir esta parcela, pois ela ja foi paga.`,
                icon: "warning",
                confirmButtonColor: AlertColor.GRAY,
                allowOutsideClick: false,
            });
        }
    }

    private showCriarParcelamento() {
        this.shortcutComponentParcelamento.show();
        this.shortcutComponentParcelamento.title = this.$t("__.ts.parcelamento") as string;
    }

    private async onCriarParcelamento() {
        const isValid = await this.parcelamentoComponent.validate();
        if (isValid) {
            const recebimentoParcelado = this.parcelamentoComponent.getMetodoRecebimento();

            const valorTotal = this.saldoTotal - this.desconto + this.acrescimo;

            recebimentoParcelado.itens.forEach((p, index) => {
                const dataVencimento = Moment().add(p.diasVencimento, "days").toDate();
                const conta = new ContaReceberModel();
                conta.contabilizar = false;
                conta.dataLancamento = new Date();
                conta.descricao = `Venda Cód: ${this.codigoVenda} (${index + 1}/${recebimentoParcelado.itens.length})`;
                conta.valorTotal = arithmeticHelper.round((valorTotal * p.percentual) / 100);
                conta.dataVencimento = dataVencimento;

                this.model.lancamentosVinculados.push(conta);
            });

            const valorTotalRateado = this.model.lancamentosVinculados.reduce((acc, p) => acc + p.valorTotal, 0);
            if (valorTotalRateado != valorTotal) {
                const diferenca = arithmeticHelper.round(valorTotal - valorTotalRateado);
                this.model.lancamentosVinculados[this.model.lancamentosVinculados.length - 1].valorTotal += diferenca;
            }

            this.loadGridData();

            this.shortcutComponentParcelamento.hide();
        }
    }

    private showAddParcela() {
        this.shortcutComponentParcela.show();
        this.shortcutComponentParcela.title = this.$t("__.ts.addParcela") as string;

        const valorJaParcelado = this.model.lancamentosVinculados.reduce((acc, p) => acc + p.valorTotal, 0);

        this.parcelaComponent.load(this.saldoTotal - valorJaParcelado, this.desconto);
    }

    private async onAddParcela() {
        const isValid = await this.parcelaComponent.validate();
        if (isValid) {
            if (this.editingParcelaIndex != null) {
                this.model.lancamentosVinculados[this.editingParcelaIndex] = this.parcelaComponent.model;
            } else {
                this.model.lancamentosVinculados.push(this.parcelaComponent.model);
            }

            this.loadGridData();

            this.shortcutComponentParcela.hide();

            this.editingParcelaIndex = null;
        }
    }

    private hideAddParcela() {
        this.editingParcelaIndex = null;
    }

    private showAddRecebimento() {
        this.shortcutComponentRecebimento.show();
        this.shortcutComponentRecebimento.title = this.$t("__.ts.recebimento") as string;

        this.addRecebimentoNaParcelaIndex = null;

        const saldoTotalMovimentacoes = this.model.movimentacoes.reduce((acc, p) => acc + p.valor, 0);
        const saldoTotal = this.saldoTotal >= saldoTotalMovimentacoes ? this.saldoTotal - saldoTotalMovimentacoes : 0;
        // sempre vamos user o valor final da venda e não será possível aplicar descontos ou acrescimos pela tela de pagamento
        this.pagamentoComponent.load(saldoTotal);
    }

    private async onAddRecebimento() {
        const isValid = await this.pagamentoComponent.validate();
        if (isValid) {
            if (this.addRecebimentoNaParcelaIndex != null) {
                const recebimento = this.pagamentoComponent.getModel();
                this.model.lancamentosVinculados[this.addRecebimentoNaParcelaIndex].movimentacoes.push(recebimento);
            } else {
                this.model.movimentacoes.push(this.pagamentoComponent.getModel());
            }

            this.loadGridData();

            this.shortcutComponentRecebimento.hide();
        }
    }

    private onRemovePagamentoRecebimento(model: object) {
        this.$emit("remove-pagamento", model);
    }

    private async calcularSaldoParcela(lancamento: ContaReceberModel) {
        const valorJaPago = lancamento.movimentacoes.reduce((acc, p) => acc + p.valor, 0);
        lancamento.saldoGrid = lancamento.valorTotal - valorJaPago < 0 ? 0 : lancamento.valorTotal - valorJaPago;
    }

    public load(saldo: number, desconto: number, acrescimo: number) {
        this.saldoTotal = saldo;
        this.desconto = desconto;
        this.acrescimo = acrescimo;
    }

    public setModel(data: ContaReceberModel) {
        this.model = data;

        this.loadGridData();
    }

    private mounted() {
        this.shortcutComponentParcela = this.$refs.shortcutComponentParcela as ShortcutComponent;
        this.shortcutComponentParcelamento = this.$refs.shortcutComponentParcelamento as ShortcutComponent;
        this.shortcutComponentRecebimento = this.$refs.shortcutComponentRecebimento as ShortcutComponent;
        this.parcelaComponent = this.$refs.parcelaComponent as ParcelaComponent;
        this.parcelamentoComponent = this.$refs.parcelamentoComponent as ParcelamentoComponent;
        this.pagamentoComponent = this.$refs.pagamentoComponent as PagamentoComponent;
    }
}
