var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "crud crud-edit" },
    [
      _c(
        "actionBarComponent",
        {
          attrs: {
            title: _vm.$t(
              "__.Crud.configuracaodotz.edit_vue_html.tituloPagina"
            ),
          },
          on: { onSave: _vm.save },
        },
        [
          _vm.isAdmin
            ? _c(
                "div",
                {
                  attrs: {
                    slot: "buttonSlot0",
                    title: _vm.$t(
                      "__.Crud.configuracaodotz.edit_vue_html.enviarArquivosVendedores"
                    ),
                  },
                  on: { click: _vm.enviarArquivosVendedores },
                  slot: "buttonSlot0",
                },
                [
                  _c("i", {
                    staticClass: "fa fa-file-invoice",
                    attrs: { "aria-hidden": "true" },
                  }),
                ]
              )
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _c(
        "form",
        {
          attrs: { novalidate: "novalidate" },
          on: {
            submit: function ($event) {
              $event.preventDefault()
              _vm.save()
            },
          },
        },
        [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-sm-3" }, [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "tooltip",
                      rawName: "v-tooltip",
                      value: _vm.errors.first("tipoEnvio"),
                      expression: "errors.first('tipoEnvio')",
                    },
                  ],
                  staticClass: "form-group",
                },
                [
                  _c(
                    "label",
                    {
                      staticClass: "control-label",
                      attrs: {
                        for: "tipoEnvio",
                        title: _vm.$t("__.obrigatorio"),
                      },
                    },
                    [
                      _c("span", { staticStyle: { color: "red" } }, [
                        _vm._v("*"),
                      ]),
                      _vm._v(
                        "\n                        " +
                          _vm._s(
                            _vm.$t(
                              "__.Crud.configuracaodotz.edit_vue_html.tipoEnvio"
                            )
                          ) +
                          "\n                    "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("combo-component", {
                    ref: "tipoEnvio",
                    attrs: {
                      id: "tipoEnvio",
                      name: "tipoEnvio",
                      data: _vm.tiposEnvioOptions,
                    },
                    model: {
                      value: _vm.model.tipoEnvio,
                      callback: function ($$v) {
                        _vm.$set(_vm.model, "tipoEnvio", $$v)
                      },
                      expression: "model.tipoEnvio",
                    },
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-sm-3" }, [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "tooltip",
                      rawName: "v-tooltip",
                      value: _vm.errors.first("storeCode"),
                      expression: "errors.first('storeCode')",
                    },
                  ],
                  staticClass: "form-group",
                },
                [
                  _c(
                    "label",
                    {
                      staticClass: "control-label",
                      attrs: {
                        for: "storeCode",
                        title: _vm.$t("__.obrigatorio"),
                      },
                    },
                    [
                      _c("span", { staticStyle: { color: "red" } }, [
                        _vm._v("*"),
                      ]),
                      _vm._v(
                        "\n                        " +
                          _vm._s(
                            _vm.$t(
                              "__.Crud.configuracaodotz.edit_vue_html.nomeLoja"
                            )
                          ) +
                          "\n                    "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("data-tooltip-component", {
                    attrs: {
                      text: _vm.$t(
                        "__.Crud.configuracaodotz.edit_vue_html.tlpStoreCode"
                      ),
                    },
                  }),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.model.storeCode,
                        expression: "model.storeCode",
                      },
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: {
                      id: "storeCode",
                      "max-length": "3",
                      name: "storeCode",
                    },
                    domProps: { value: _vm.model.storeCode },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.model, "storeCode", $event.target.value)
                      },
                    },
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-sm-6" }, [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "tooltip",
                      rawName: "v-tooltip",
                      value: _vm.errors.first("tipoFiltroDataVenda"),
                      expression: "errors.first('tipoFiltroDataVenda')",
                    },
                  ],
                  staticClass: "form-group",
                },
                [
                  _c(
                    "label",
                    {
                      staticClass: "control-label",
                      attrs: {
                        for: "tipoFiltroDataVenda",
                        title: _vm.$t("__.obrigatorio"),
                      },
                    },
                    [
                      _c("span", { staticStyle: { color: "red" } }, [
                        _vm._v("*"),
                      ]),
                      _vm._v(
                        "\n                        " +
                          _vm._s(
                            _vm.$t(
                              "__.Crud.configuracaodotz.edit_vue_html.considerarVendasPor"
                            )
                          ) +
                          "\n                    "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("combo-component", {
                    ref: "tipoFiltroDataVenda",
                    attrs: {
                      id: "tipoFiltroDataVenda",
                      name: "tipoFiltroDataVenda",
                      data: _vm.tipoFiltroDataVendaOptions,
                    },
                    model: {
                      value: _vm.model.tipoFiltroDataVenda,
                      callback: function ($$v) {
                        _vm.$set(_vm.model, "tipoFiltroDataVenda", $$v)
                      },
                      expression: "model.tipoFiltroDataVenda",
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _vm.model.tipoEnvio == 1
              ? _c("div", { staticClass: "col-sm-3" }, [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "tooltip",
                          rawName: "v-tooltip",
                          value: _vm.errors.first("diaEnvioPontuacao"),
                          expression: "errors.first('diaEnvioPontuacao')",
                        },
                      ],
                      staticClass: "form-group",
                    },
                    [
                      _c(
                        "label",
                        {
                          staticClass: "control-label",
                          attrs: {
                            for: "diaEnvioPontuacao",
                            title: _vm.$t("__.obrigatorio"),
                          },
                        },
                        [
                          _c("span", { staticStyle: { color: "red" } }, [
                            _vm._v("*"),
                          ]),
                          _vm._v(
                            "\n                        " +
                              _vm._s(
                                _vm.$t(
                                  "__.Crud.configuracaodotz.edit_vue_html.diaEnvioPontuacao"
                                )
                              ) +
                              "\n                    "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("data-tooltip-component", {
                        attrs: {
                          text: _vm.$t(
                            "__.Crud.configuracaodotz.edit_vue_html.tlpDiaEnvioPontuacao"
                          ),
                        },
                      }),
                      _vm._v(" "),
                      _c("decimal-component", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required|maior_que_zero",
                            expression: "'required|maior_que_zero'",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          id: "diaEnvioPontuacao",
                          "max-length": "3",
                          name: "diaEnvioPontuacao",
                        },
                        model: {
                          value: _vm.model.diaEnvioPontuacao,
                          callback: function ($$v) {
                            _vm.$set(_vm.model, "diaEnvioPontuacao", $$v)
                          },
                          expression: "model.diaEnvioPontuacao",
                        },
                      }),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "col-sm-3" }, [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "tooltip",
                      rawName: "v-tooltip",
                      value: _vm.errors.first("documentoFuncionario"),
                      expression: "errors.first('documentoFuncionario')",
                    },
                  ],
                  staticClass: "form-group",
                },
                [
                  _c(
                    "label",
                    {
                      staticClass: "control-label",
                      attrs: {
                        for: "documentoFuncionario",
                        title: _vm.$t("__.obrigatorio"),
                      },
                    },
                    [
                      _c("span", { staticStyle: { color: "red" } }, [
                        _vm._v("*"),
                      ]),
                      _vm._v(
                        "\n                        " +
                          _vm._s(
                            _vm.$t(
                              "__.Crud.configuracaodotz.edit_vue_html.documentoFuncionario"
                            )
                          ) +
                          "\n                    "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("data-tooltip-component", {
                    attrs: {
                      text: _vm.$t(
                        "__.Crud.configuracaodotz.edit_vue_html.tlpDocumentoFuncionario"
                      ),
                    },
                  }),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.model.documentoFuncionario,
                        expression: "model.documentoFuncionario",
                      },
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: {
                      id: "documentoFuncionario",
                      name: "documentoFuncionario",
                      "max-length": "14",
                    },
                    domProps: { value: _vm.model.documentoFuncionario },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.model,
                          "documentoFuncionario",
                          $event.target.value
                        )
                      },
                    },
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-sm-6" }, [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "tooltip",
                      rawName: "v-tooltip",
                      value: _vm.errors.first("franquiaEnvioId"),
                      expression: "errors.first('franquiaEnvioId')",
                    },
                  ],
                  staticClass: "form-group",
                },
                [
                  _c(
                    "label",
                    {
                      staticClass: "control-label",
                      attrs: { for: "franquiaEnvioId" },
                    },
                    [
                      _vm._v(
                        "\n                        " +
                          _vm._s(
                            _vm.$t(
                              "__.Crud.configuracaodotz.edit_vue_html.franquiaEnvio"
                            )
                          ) +
                          "\n                    "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("data-tooltip-component", {
                    attrs: {
                      text: _vm.$t(
                        "__.Crud.configuracaodotz.edit_vue_html.franquiaEnvioTooltip"
                      ),
                    },
                  }),
                  _vm._v(" "),
                  _c("combo-component", {
                    attrs: {
                      id: "franquiaEnvioId",
                      name: "franquiaEnvioId",
                      data: _vm.franquiaOptions,
                      refresh: _vm.loadFranquias,
                    },
                    model: {
                      value: _vm.model.franquiaEnvioId,
                      callback: function ($$v) {
                        _vm.$set(_vm.model, "franquiaEnvioId", $$v)
                      },
                      expression: "model.franquiaEnvioId",
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "fieldset-component",
            {
              attrs: {
                title: _vm.$t("__.Crud.configuracaodotz.edit_vue_html.filtros"),
                collapsed: false,
              },
            },
            [
              _c("div", { attrs: { slot: "rows" }, slot: "rows" }, [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-sm-3" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("quantidadeMinimaVendas"),
                            expression:
                              "errors.first('quantidadeMinimaVendas')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: {
                              for: "quantidadeMinimaVendas",
                              title: _vm.$t("__.obrigatorio"),
                            },
                          },
                          [
                            _c("span", { staticStyle: { color: "red" } }, [
                              _vm._v("*"),
                            ]),
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Crud.configuracaodotz.edit_vue_html.qtdMinVendas"
                                  )
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("decimal-com-sinal-custom-component", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required|maior_que_zero",
                              expression: "'required|maior_que_zero'",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            id: "quantidadeMinimaVendas",
                            "max-length": "3",
                            name: "quantidadeMinimaVendas",
                            signal: "",
                          },
                          model: {
                            value: _vm.model.quantidadeMinimaVendas,
                            callback: function ($$v) {
                              _vm.$set(_vm.model, "quantidadeMinimaVendas", $$v)
                            },
                            expression: "model.quantidadeMinimaVendas",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-3" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first(
                              "porcentagemMaximaDesconto"
                            ),
                            expression:
                              "errors.first('porcentagemMaximaDesconto')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: {
                              for: "franquia",
                              title: _vm.$t("__.obrigatorio"),
                            },
                          },
                          [
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Crud.configuracaodotz.edit_vue_html.porcMaxDesconto"
                                  )
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("decimal-com-sinal-custom-component", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required|maior_igual_a_zero",
                              expression: "'required|maior_igual_a_zero'",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            id: "porcentagemMaximaDesconto",
                            "max-length": "3",
                            name: "porcentagemMaximaDesconto",
                            signal: "%",
                          },
                          model: {
                            value: _vm.model.porcentagemMaximaDesconto,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.model,
                                "porcentagemMaximaDesconto",
                                $$v
                              )
                            },
                            expression: "model.porcentagemMaximaDesconto",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  !_vm.isFranquiaModelo
                    ? _c("div", { staticClass: "col-sm-2" }, [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: _vm.errors.first("utilizaFaixasPadroes"),
                                expression:
                                  "errors.first('utilizaFaixasPadroes')",
                              },
                            ],
                            staticClass: "form-group",
                          },
                          [
                            _c(
                              "label",
                              {
                                staticClass: "control-label",
                                attrs: { for: "utilizaFaixasPadroes" },
                              },
                              [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(
                                      _vm.$t(
                                        "__.Crud.configuracaodotz.edit_vue_html.utilizaFaixasRede"
                                      )
                                    ) +
                                    "\n                            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("data-tooltip-component", {
                              attrs: {
                                text: _vm.$t(
                                  "__.Crud.configuracaodotz.edit_vue_html.tlpUtilizaFaixasRede"
                                ),
                              },
                            }),
                            _vm._v(" "),
                            _c("checkbox-component", {
                              attrs: {
                                id: "utilizaFaixasPadroes",
                                name: "utilizaFaixasPadroes",
                              },
                              model: {
                                value: _vm.model.utilizaFaixasPadroes,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.model,
                                    "utilizaFaixasPadroes",
                                    $$v
                                  )
                                },
                                expression: "model.utilizaFaixasPadroes",
                              },
                            }),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                ]),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "fieldset-component",
            {
              attrs: {
                title: _vm.$t(
                  "__.Crud.configuracaodotz.edit_vue_html.faixasTitulo"
                ),
                collapsed: false,
              },
            },
            [
              _c(
                "div",
                { attrs: { slot: "rows" }, slot: "rows" },
                [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-sm-4" }, [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: _vm.errors.first("inicioFaixa"),
                              expression: "errors.first('inicioFaixa')",
                            },
                          ],
                          staticClass: "form-group",
                        },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "control-label",
                              attrs: {
                                for: "inicioFaixa",
                                title: _vm.$t("__.obrigatorio"),
                              },
                            },
                            [
                              _c("span", { staticStyle: { color: "red" } }, [
                                _vm._v("*"),
                              ]),
                              _vm._v(
                                "\n                                " +
                                  _vm._s(
                                    _vm.$t(
                                      "__.Crud.configuracaodotz.edit_vue_html.inicioFaixa"
                                    )
                                  ) +
                                  "\n                            "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("moedaComponent", {
                            staticClass: "form-control",
                            attrs: {
                              type: "text",
                              id: "inicioFaixa",
                              name: "inicioFaixa",
                              autocomplete: "off",
                              disabled: _vm.model.utilizaFaixasPadroes,
                            },
                            model: {
                              value: _vm.faixa.inicioFaixa,
                              callback: function ($$v) {
                                _vm.$set(_vm.faixa, "inicioFaixa", $$v)
                              },
                              expression: "faixa.inicioFaixa",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-sm-4" }, [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: _vm.errors.first("multiplicador"),
                              expression: "errors.first('multiplicador')",
                            },
                          ],
                          staticClass: "form-group",
                        },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "control-label",
                              attrs: {
                                for: "pontos",
                                title: _vm.$t("__.obrigatorio"),
                              },
                            },
                            [
                              _c("span", { staticStyle: { color: "red" } }, [
                                _vm._v("*"),
                              ]),
                              _vm._v(
                                "\n                                " +
                                  _vm._s(
                                    _vm.$t(
                                      "__.Crud.configuracaodotz.edit_vue_html.multiplicador"
                                    )
                                  ) +
                                  "\n                            "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("data-tooltip-component", {
                            attrs: {
                              text: _vm.$t(
                                "__.Crud.configuracaodotz.edit_vue_html.tlpMultiplicador"
                              ),
                            },
                          }),
                          _vm._v(" "),
                          _c("decimal-com-sinal-custom-component", {
                            staticClass: "form-control",
                            attrs: {
                              autocomplete: "off",
                              type: "text",
                              id: "multiplicador",
                              name: "multiplicador",
                              signal: "",
                              disabled: _vm.model.utilizaFaixasPadroes,
                            },
                            model: {
                              value: _vm.faixa.multiplicador,
                              callback: function ($$v) {
                                _vm.$set(_vm.faixa, "multiplicador", $$v)
                              },
                              expression: "faixa.multiplicador",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-sm-4" }, [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: _vm.errors.first("codigoMecanica"),
                              expression: "errors.first('codigoMecanica')",
                            },
                          ],
                          staticClass: "form-group",
                        },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "control-label",
                              attrs: {
                                for: "codigoMecanica",
                                title: _vm.$t("__.obrigatorio"),
                              },
                            },
                            [
                              _c("span", { staticStyle: { color: "red" } }, [
                                _vm._v("*"),
                              ]),
                              _vm._v(
                                "\n                                " +
                                  _vm._s(
                                    _vm.$t(
                                      "__.Crud.configuracaodotz.edit_vue_html.codigoMecanica"
                                    )
                                  ) +
                                  "\n                            "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("data-tooltip-component", {
                            attrs: {
                              text: _vm.$t(
                                "__.Crud.configuracaodotz.edit_vue_html.tlpOfferCode"
                              ),
                            },
                          }),
                          _vm._v(" "),
                          _c("decimal-component", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: {
                                  rules: {
                                    required:
                                      _vm.faixa.multiplicador > 0 &&
                                      _vm.faixa.inicioFaixa > 0,
                                  },
                                },
                                expression:
                                  "{\n                                    rules: {\n                                        required: faixa.multiplicador > 0 && faixa.inicioFaixa > 0,\n                                    },\n                                }",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: {
                              id: "codigoMecanica",
                              "max-length": "3",
                              name: "codigoMecanica",
                            },
                            model: {
                              value: _vm.faixa.codigoMecanica,
                              callback: function ($$v) {
                                _vm.$set(_vm.faixa, "codigoMecanica", $$v)
                              },
                              expression: "faixa.codigoMecanica",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  !_vm.model.utilizaFaixasPadroes
                    ? _c("buttonIncluirComponent", {
                        on: { click: _vm.adicionarFaixa },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("grid-component", {
                    attrs: {
                      data: _vm.model.faixas,
                      columns: _vm.gridFaixaColums,
                      "show-action-column": true,
                      "show-table-head": false,
                      "show-table-foot": false,
                      "show-remove-item": !_vm.model.utilizaFaixasPadroes,
                      "show-edit-item": false,
                    },
                    on: { "remove-item": _vm.onRemoverFaixa },
                  }),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c("fieldset", [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-sm-12 newbtn" }, [
                _c("div", { staticClass: "form-group" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-success",
                      attrs: { type: "submit" },
                    },
                    [
                      _c("i", {
                        staticClass: "fa fa-save",
                        attrs: { "aria-hidden": "true" },
                      }),
                      _vm._v(
                        "\n                            " +
                          _vm._s(_vm.$t("__.Crud.salvar")) +
                          "\n                        "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-secondary",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          _vm.load()
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n                            " +
                          _vm._s(_vm.$t("__.Crud.limpar")) +
                          "\n                        "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-secondary",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          _vm.cancel()
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n                            " +
                          _vm._s(_vm.$t("__.Crud.voltar")) +
                          "\n                        "
                      ),
                    ]
                  ),
                ]),
              ]),
            ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }