var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "crud crud-list" }, [
    _c("div", { staticClass: "info-div" }, [
      _c("h1", { staticClass: "title" }, [
        _vm._v(
          _vm._s(
            _vm.$t(
              "__.Components.parent.statusSistema.statussistema_vue_html.title"
            )
          )
        ),
      ]),
    ]),
    _vm._v(" "),
    _c("form", [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-sm-10 status-content" }, [
          _c("span", { staticStyle: { "font-size": "18px" } }, [
            _vm._v(_vm._s(_vm.status)),
          ]),
          _vm._v(" "),
          _c("span", {
            staticClass: "circle",
            style: { backgroundColor: _vm.circleColor },
          }),
          _vm._v(" "),
          _c("span", { staticStyle: { "font-size": "18px" } }, [
            _vm._v(_vm._s(_vm.connectionStatus)),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-sm-2" }, [
          _c(
            "button",
            {
              staticClass: "buttonRefresh btn-secondary",
              attrs: { type: "button" },
              on: { click: _vm.onPing },
            },
            [
              _c("i", {
                staticClass: "fa fa-sync",
                attrs: { "aria-hidden": "true" },
              }),
              _vm._v("\n                    Atualizar\n                "),
            ]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }