import Vue from "vue";

import cnpjComponent from "@/components/child/form/cnpj.vue";
import cpfComponent from "@/components/child/form/cpf.vue";
import fieldsetComponent from "@/components/child/form/fieldset.vue";
import { Component, Prop, Watch } from "@/decorators";
import TiposPessoa from "@/models/enum/tiposPessoa";
import FornecedorModel from "@/models/fornecedorModel";
import EmitenteNfeModel from "@/models/notafiscaleletronica/dados/informacoes/emitente/emitenteNfeModel";
import FornecedorService from "@/services/fornecedorService";

@Component({
    components: {
        fieldsetComponent,
        cpfComponent,
        cnpjComponent,
    },
})
export default class ImportacaoNfeEmitenteComponent extends Vue {
    tiposPessoa = TiposPessoa;
    fornecedorModel = new FornecedorModel();

    @Prop({ type: EmitenteNfeModel, required: true }) model: EmitenteNfeModel;

    //@ts-ignore
    @Watch("model.nome")
    private async onDataChanged() {
        this.fornecedorModel = new FornecedorModel();

        if (!this.model.nome) {
            return;
        }

        try {
            const data = await new FornecedorService()
                .getByDocumento(this.model.tipoPessoa == TiposPessoa.Juridica ? this.model.cnpj : this.model.cpf)
                .then(r => r.json() as Promise<FornecedorModel>);

            this.fornecedorModel.updateFrom(data);

            this.fornecedorModel.updateFrom(this.model, ["nome", "nomeFantasia", "endereco"], true);
            this.fornecedorModel.updateFrom(this.model.endereco, undefined, true);

            if (this.model.tipoPessoa == TiposPessoa.Juridica) {
                this.fornecedorModel.razaoSocial = this.model.nome;

                if (!this.fornecedorModel.nomeFantasia) {
                    this.fornecedorModel.nomeFantasia = this.model.nome;
                }
            } else if (!this.fornecedorModel.nome) {
                this.fornecedorModel.nome = this.model.nome;
            }
        } catch {}
    }
}
