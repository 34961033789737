import BaseModel from "./base/baseModel";
import TiposCalculoManipulacao from "./enum/tiposCalculoManipulacao";
import TiposItemManipulacao from "./enum/tiposItemManipulacao";
import FormulaPadraoModel from "./formulaPadraoModel";
import ProdutoModel from "./produto/produtoModel";
import ProdutoSinonimoModel from "./produto/produtoSinonimoModel";
import UnidadeMedidaModel from "./unidadeMedidaModel";

export default class FormulaPadraoItemModel extends BaseModel {
    id?: number = null;
    franquiaId?: number = null;
    formulaPadrao?: FormulaPadraoModel = null;
    formulaPadraoId?: number = null;
    produtoId?: number = null;
    produto?: ProdutoModel = null;
    produtoDescricao: string = null;
    produtoDensidade: number = null;
    produtoValor?: number = null;
    produtoUnidadeMedidaEstoqueId: number = null;
    quantidade = 0;
    unidadeMedidaId?: number = null;
    unidadeMedidaDescricao: string = null;
    unidadeMedida: UnidadeMedidaModel = null;
    tipoItem: TiposItemManipulacao = null;
    tipoItemDescricao: string = null;
    tipoCalculo?: TiposCalculoManipulacao = null;
    tipoCalculoDescricao: string = null;
    volumeDose?: number = null;
    quantidadeDose = 0;
    unidadeMedidaManipulacaoId?: number = null;
    unidadeMedidaManipulacaoDescricao: string = null;
    unidadeMedidaManipulacao: UnidadeMedidaModel = null;
    faseUso: string = null;
    fatorCorrecao?: number = null;
    sinonimoId?: number = null;
    sinonimo: ProdutoSinonimoModel = null;
    desmembrado?: boolean = false;
}
