import { mapMutations } from "vuex";

import { GridColumn, GridColumnType } from "@/components/child/grid/gridColumn";
import { Component } from "@/decorators";
import CampanhaModel from "@/models/programafidelidade/campanhaModel";
import CampanhaService from "@/services/campanhaService";
import { LoadListProps } from "@/store/loadListStore";
import { Mutations } from "@/store/store";

import ListComponentBase from "../listComponentBase";

@Component({
    methods: mapMutations(["LOAD_LIST"] as Mutations),
})
export default class CampanhaListComponent extends ListComponentBase<CampanhaModel> {
    // State computed props
    LOAD_LIST: (props: LoadListProps) => void;

    get gridColumns(): Array<GridColumn> {
        return [
            new GridColumn("descricao", "Descrição", GridColumnType.String),
            new GridColumn("dataInicio", "Data Inicio", GridColumnType.Date),
            new GridColumn("dataFim", "Data Fim", GridColumnType.Date),
            new GridColumn("padrao", "Padrao", GridColumnType.Boolean),
        ];
    }

    private beforeMount() {
        this.setProps(new CampanhaService(), "campanha", "descricao", true);
    }

    public async onSucessoExclusao() {
        this.LOAD_LIST({ loadCampanhas: true });
    }
}
