var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("form", [
    _c("div", { attrs: { slot: "rows" }, slot: "rows" }, [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-sm-12" }, [
          _c(
            "div",
            {
              directives: [
                {
                  name: "tooltip",
                  rawName: "v-tooltip",
                  value: _vm.errors.first("cupom"),
                  expression: "errors.first('cupom')",
                },
              ],
              staticClass: "form-group",
            },
            [
              _c(
                "label",
                {
                  staticClass: "control-label",
                  attrs: { for: "cupom", title: _vm.$t("__.obrigatorio") },
                },
                [
                  _c("span", { staticStyle: { color: "red" } }, [_vm._v("*")]),
                  _vm._v(
                    "\n                        Cupom\n                    "
                  ),
                ]
              ),
              _vm._v(" "),
              _c("combo-component", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required",
                    expression: "'required'",
                  },
                ],
                attrs: {
                  id: "cupom",
                  name: "cupom",
                  data: _vm.cupomOptions,
                  disabled: _vm.consulta,
                  consulta: _vm.consulta,
                  refresh: _vm.loadCupons,
                  searchable: true,
                },
                model: {
                  value: _vm.cupomId,
                  callback: function ($$v) {
                    _vm.cupomId = $$v
                  },
                  expression: "cupomId",
                },
              }),
            ],
            1
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }