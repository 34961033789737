import BaseModel from "./../base/baseModel";
import Frequencias from "./../enum/frequencias";
import StatusContas from "./../enum/statusContas";

export default class LancamentoBaseModel extends BaseModel {
    id?: number = null;
    descricao: string = null;
    contaGerencialId = 0;
    contaGerencialDescricao: string = null;
    dataLancamento: Date = null;
    dataVencimento: Date = null;
    valorTotal = 0;
    saldo = 0;
    codigoBarras: string = null;
    observacao: string = null;
    recorrencias = 0;
    frequencia?: Frequencias = null;
    status: StatusContas = 0;
    lancamentoVinculadoId?: number = 0;
}
