import BaseModel from "./base/baseModel";
import TiposMovimentacao from "./enum/tiposMovimentacao";

export default class MovimentacaoContaModel extends BaseModel {
    id?: number = null;
    dataLancamento: Date = null;
    dataPagamento: Date = null;
    contaCorrenteId = 0;
    contaCorrenteDescricao: string = null;
    contaGerencialId = 0;
    contaGerencialDescricao: string = null;
    tipoMovimentacao: TiposMovimentacao = null;
    tipoMovimentacaoDescricao?: string = null;
    formaPagamentoId?: number = null;
    formaRecebimentoId?: number = null;
    valor = 0;
    descricao: string = null;
    observacao: string = null;
}
