import BaseModel from "./base/baseModel";

export default class ProdutoLoteFracionamentoItemEmbalagemModel extends BaseModel {
    id: number = null;
    produtoLoteFracionamentoItemId: number = null;
    produtoId: number = null;
    produtoLoteId: number = null;
    kitEmbalagemItemId: number = null;
    quantidadeOriginal = 0;
    quantidade = 0;
}
