enum OrigemRotulo {
    Lote = 0,
    Manipulacao = 1,
    Fracionamento = 2,
    Secundario = 3,
    Acabado = 4,
    Strips = 5,
    SecundarioPequeno = 6,
}

export default OrigemRotulo;
