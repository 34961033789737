var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "crud crud-list" },
    [
      _c("grid-component", {
        attrs: {
          columns: _vm.gridColumns,
          data: _vm.gridData,
          extraActions: _vm.extraActions,
          "page-index": _vm.pageIndex,
          "page-size": _vm.pageSize,
          "show-select-checkbox": false,
          showAddNewButton: false,
          showEditItem: false,
          showRemoveItem: false,
          total: _vm.total,
        },
        on: {
          "change-filter-key": _vm.onChangeFilterKey,
          "change-page": _vm.onChangePage,
          "change-sort": _vm.onChangeSort,
          "extra-action": _vm.onExtraAction,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }