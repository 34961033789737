import BaseModel from "../../../../base/baseModel";

import TransportadorNfeModel from "./transportador/transportadorNfeModel";
import VolumeNfeModel from "./volume/volumeNfeModel";

export default class TransporteNfeModel extends BaseModel {
    transportador: TransportadorNfeModel = new TransportadorNfeModel();
    volumes: VolumeNfeModel[] = [];
    modalidadeFrete: number = null;
}
