var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "crud crud-list" },
    [
      !_vm.hideExtras
        ? _c("div", { staticClass: "info-div" }, [
            _c("h1", { staticClass: "title" }, [
              _vm._v(
                _vm._s(
                  _vm.$t(
                    "__.Crud.formulapadraovalorproduto.list_vue_html.calcVlrsProdsFrmlPdr"
                  )
                )
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "content" }, [
              _c("div", { staticClass: "info-panel" }, [
                _c("span", [_vm._v(_vm._s(_vm.total))]),
                _vm._v(" "),
                _c("span", [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        "__.Crud.formulapadraovalorproduto.list_vue_html.frmlsRecalcs"
                      )
                    )
                  ),
                ]),
                _vm._v(" "),
                _c("span"),
              ]),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "grid-component",
        {
          ref: "gridcomponent",
          attrs: {
            data: _vm.gridData,
            tituloPagina: "Recalculo de Valores de Produtos",
            columns: _vm.gridColumns,
            showSearchField: false,
            "page-index": _vm.pageIndex,
            "page-size": _vm.pageSize,
            total: _vm.total,
            showEditItem: false,
            showActionColumn: false,
            showAddNewButton: false,
            showTools: false,
            showSortIcons: false,
            showSelectCheckbox: true,
            multipleSelect: true,
            startFilterKey: _vm.startFilterKey,
          },
          on: {
            "selected-values-changed": _vm.onSelectedValuesChanged,
            "change-row-value": _vm.changeValues,
          },
        },
        [
          _c("div", { attrs: { slot: "header" }, slot: "header" }, [
            _c(
              "div",
              {
                staticClass: "filtro-lista",
                staticStyle: { width: "500px!important" },
              },
              [
                _c(
                  "button",
                  {
                    staticClass: "filtro-avancado-button",
                    staticStyle: { width: "500px!important" },
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        _vm.isHidden = !_vm.isHidden
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm.$t("__.Crud.filtroAvancado")) +
                        "\n                    "
                    ),
                    _c("i", { staticClass: "fa fa-caret-down" }),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.isHidden,
                        expression: "!isHidden",
                      },
                    ],
                    staticClass: "drop-lista",
                    staticStyle: { width: "500px!important" },
                  },
                  [
                    _c(
                      "form",
                      {
                        attrs: { novalidate: "novalidate" },
                        on: {
                          submit: function ($event) {
                            $event.preventDefault()
                            return _vm.getFormValues($event)
                          },
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "radio-group",
                            staticStyle: {
                              "margin-bottom": "15px",
                              "font-size": "15px",
                            },
                          },
                          [
                            _c("div", { staticStyle: { display: "grid" } }, [
                              _c("label", { staticStyle: { width: "auto" } }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.radioFilter,
                                      expression: "radioFilter",
                                    },
                                  ],
                                  attrs: {
                                    name: "optradio",
                                    type: "radio",
                                    value: "0",
                                  },
                                  domProps: {
                                    checked: _vm._q(_vm.radioFilter, "0"),
                                  },
                                  on: {
                                    change: function ($event) {
                                      _vm.radioFilter = "0"
                                    },
                                  },
                                }),
                                _vm._v(" "),
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "__.Crud.formulapadraovalorproduto.list_vue_html.tdsRegisRecalcs"
                                      )
                                    )
                                  ),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c("br"),
                              _vm._v(" "),
                              _c("label", { staticStyle: { width: "auto" } }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.radioFilter,
                                      expression: "radioFilter",
                                    },
                                  ],
                                  attrs: {
                                    name: "optradio",
                                    type: "radio",
                                    value: "1",
                                  },
                                  domProps: {
                                    checked: _vm._q(_vm.radioFilter, "1"),
                                  },
                                  on: {
                                    change: function ($event) {
                                      _vm.radioFilter = "1"
                                    },
                                  },
                                }),
                                _vm._v(" "),
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "__.Crud.formulapadraovalorproduto.list_vue_html.apenasComDifVlr"
                                      )
                                    )
                                  ),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c("br"),
                              _vm._v(" "),
                              _c("label", { staticStyle: { width: "auto" } }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.radioFilter,
                                      expression: "radioFilter",
                                    },
                                  ],
                                  attrs: {
                                    name: "optradio",
                                    type: "radio",
                                    value: "2",
                                  },
                                  domProps: {
                                    checked: _vm._q(_vm.radioFilter, "2"),
                                  },
                                  on: {
                                    change: function ($event) {
                                      _vm.radioFilter = "2"
                                    },
                                  },
                                }),
                                _vm._v(" "),
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "__.Crud.formulapadraovalorproduto.list_vue_html.apenasCustMaiorVlr"
                                      )
                                    )
                                  ),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c("br"),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "form-group" }, [
                          _c(
                            "button",
                            {
                              staticClass: "botao-filtrar",
                              attrs: { type: "submit" },
                            },
                            [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(_vm.$t("__.Crud.filtrar")) +
                                  "\n                            "
                              ),
                            ]
                          ),
                        ]),
                      ]
                    ),
                  ]
                ),
              ]
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              attrs: { slot: "footer-extra-options" },
              slot: "footer-extra-options",
            },
            [
              _c(
                "button",
                {
                  staticClass: "btn btn-primary",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      _vm.atualizarValorProduto()
                    },
                  },
                },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(
                        _vm.$t(
                          "__.Crud.formulapadraovalorproduto.list_vue_html.atualiz"
                        )
                      ) +
                      "\n            "
                  ),
                ]
              ),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }