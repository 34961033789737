import comboComponent from "@/components/child/form/combo.vue";
import dateTimePickerComponent from "@/components/child/form/datetimepicker.vue";
import { GridColumn, GridColumnType } from "@/components/child/grid/gridColumn";
import { Component } from "@/decorators";
import StatusContas from "@/models/enum/statusContas";
import ContaCorrenteModel from "@/models/financeiro/contaCorrenteModel";
import ContaReceberModel, { ContaReceberListParameters } from "@/models/financeiro/contaReceberModel";
import PaginationModel from "@/models/paginationModel";
import ContaCorrenteService from "@/services/financeiro/contaCorrenteService";
import ContaReceberService from "@/services/financeiro/contaReceberService";
import { convertToDateInput, convertToDateInputString } from "@/utils/common/date";

import ListComponentBase from "../listComponentBase";

@Component({
    components: {
        comboComponent,
        dateTimePickerComponent,
    },
})
export default class ContaRecebidaListComponent extends ListComponentBase<ContaReceberModel> {
    get gridColumns(): Array<GridColumn> {
        return [
            new GridColumn("dataVencimento", "Venc.", GridColumnType.Date),
            new GridColumn("descricao", "Descri\u00e7\u00e3o", GridColumnType.String),
            new GridColumn("clienteDescricao", "Cliente", GridColumnType.String),
            new GridColumn("totalValor", "Valor Receb.", GridColumnType.Money, false, false, "", "", false, false),
            new GridColumn("tipoConta", "Conta", GridColumnType.String, false, false, "", "", false, false),
            new GridColumn("valorTotal", "Valor Original", GridColumnType.Money),
            new GridColumn("totalData", "Recebimento", GridColumnType.DateTime, false, false, "", "", false, false),
        ];
    }

    subDataName = "movimentacoes";

    get gridColumnsVinculos(): Array<GridColumn> {
        return [
            new GridColumn("dataMovimento", "Recebimento", GridColumnType.Date),
            new GridColumn("valor", "Valor", GridColumnType.Money),
            new GridColumn("observacao", "Observação", GridColumnType.String),
            new GridColumn("contaCorrenteDescricao", "Conta Corrente", GridColumnType.String),
        ];
    }

    isHidden = false;
    contaCorrenteOptions: Array<Object> = [];
    filtro: Array<Object> = [];
    contasFiltro?: number = null;

    dataFilter1: Date = null;
    dataFilter2: Date = null;

    conditionShowSubData(it) {
        return it["movimentacoes"]["length"] > 0;
    }

    private beforeMount() {
        this.setProps(new ContaReceberService(), "contareceber", "dataVencimento");

        this.loadContasCorrentes();
    }

    public async loadMethod() {
        this.isHidden = false;

        const customParameters: ContaReceberListParameters = {
            statusContas: StatusContas.Liquidado,
            filterKeyId: "",
            dataVencimentoIniStr: convertToDateInputString(this.filtro[0]),
            dataVencimentoFinStr: convertToDateInputString(this.filtro[1], true),
            contaCorrenteId: this.filtro[2] ? <number>this.filtro[2] : null,
            contaGerencialId: null,
            onlyClientes: true,
            onlyOperadoras: false,
        };

        return await this.service
            .list(
                this.gridFilterKey,
                this.gridSortKey,
                this.gridSortOrder,
                this.pags ? 1 : this.pageIndex,
                this.pags ? 999999 : this.pageSize,
                customParameters,
            )
            .withLoading()
            .resolveWithJSON<PaginationModel<ContaReceberModel>>();
    }

    public async afterLoad() {
        this.gridData["dataDe"] = this.filtro[0] ? this.filtro[0].toString() : " ";
        this.gridData["dataAte"] = this.filtro[1] ? this.filtro[1].toString() : " ";
        if (this.filtro[2]) {
            this.gridData["caixa"] = this.gridData[0]["tipoConta"];
        }
    }

    private getFormValues(listaFiltro) {
        this.filtro = [];

        this.filtro.push(listaFiltro.target.elements.dataFilter1.value);

        this.filtro.push(listaFiltro.target.elements.dataFilter2.value);

        this.filtro.push(this.contasFiltro);

        this.load();
    }

    private async loadContasCorrentes() {
        try {
            const data = await new ContaCorrenteService()
                .combo()
                .resolveWithJSON<PaginationModel<ContaCorrenteModel>>();
            this.contaCorrenteOptions = data.list.map(item => ({ value: item.id, text: item.descricao }));
        } catch {}
    }

    public async afterLoadPagination() {
        if (this.paginationParams.filtros != null) {
            this.filtro = this.paginationParams.filtros;

            if (this.filtro.length >= 0) {
                if (this.filtro[0]) {
                    this.dataFilter1 = convertToDateInput(this.filtro[0].toString());
                }
                if (this.filtro[1]) {
                    this.dataFilter2 = convertToDateInput(this.filtro[1].toString());
                }
                if (this.filtro[2] != null && <number>this.filtro[2] > 0) {
                    this.contasFiltro = <number>this.filtro[2];
                }
            }
        }
    }
}
