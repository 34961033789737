import { mapMutations } from "vuex";

import { GridAction } from "@/components/child/grid/gridAction";
import { GridColors, GridColumn, GridColumnType } from "@/components/child/grid/gridColumn";
import { Component } from "@/decorators";
import ClienteModel from "@/models/clienteModel";
import genero from "@/models/enum/generosPessoa";
import PacienteModel from "@/models/paciente/pacienteModel";
import PaginationModel from "@/models/paginationModel";
import ClienteService from "@/services/clienteService";
import PacienteService from "@/services/paciente/pacienteService";
import Delay from "@/utils/common/delay";

import ShortcutComponent from "../../shortcut/shortcut";
import shortcutComponent from "../../shortcut/shortcut.vue";
import ListComponentBase from "../listComponentBase";

import verComponent from "./ver.vue";

import "../crud.scss";

@Component({
    components: {
        shortcutComponent,
        verComponent,
    },
    methods: mapMutations(["LOAD_LIST"]),
})
export default class PacienteListComponent extends ListComponentBase<PacienteModel> {
    private serviceCliente = new ClienteService();
    private shortcutComponent: ShortcutComponent = null;

    get gridColumns(): Array<GridColumn> {
        return [
            new GridColumn("nome", "Nome", GridColumnType.String),
            new GridColumn("clienteDescricao", "Cliente", GridColumnType.String),
            new GridColumn("especieAnimalDescricao", "Espécie", GridColumnType.String),
            new GridColumn("racaAnimalDescricao", "Raça", GridColumnType.String),
            new GridColumn("ativo", "Ativo", GridColumnType.Boolean, false, false, "", "", false, false),
        ];
    }

    private beforeMount() {
        this.setProps(new PacienteService(), "paciente", "nome");
    }

    modelVer: PacienteModel = null;
    extraActions: Array<object> = [];
    gridExtraActionsVer: Array<GridAction> = [];
    showVer = false;

    public async loadMethod() {
        return await this.service["listAllStatus"](
            this.gridFilterKey,
            this.gridSortKey,
            this.gridSortOrder,
            this.pags ? 1 : this.pageIndex,
            this.pags ? 999999 : this.pageSize,
            false,
        )
            .withLoading()
            .resolveWithJSON<PaginationModel<PacienteModel>>();
    }

    public async afterLoad() {
        this.gridExtraActionsVer.splice(0);
        this.extraActions.splice(0);

        for (let i = 0; i < this.gridData.length; i++) {
            this.gridExtraActionsVer.push(new GridAction("ver", "Ver Paciente", "fa fa-eye", GridColors.BLUE, true));
        }

        this.extraActions.push(this.gridExtraActionsVer);
    }

    private onExtraAction(name: string, model: PacienteModel) {
        if (name.trim() == "ver") {
            this.onVer(model);
        }
    }

    private async onVer(paciente: PacienteModel) {
        while (!this.$refs.shortcutComponent) await Delay(5);
        this.shortcutComponent = this.$refs.shortcutComponent as ShortcutComponent;
        this.shortcutComponent.title = "Paciente";

        try {
            const data = await this.service.get(paciente.id).withLoading().resolveWithJSON<PacienteModel>();

            this.modelVer = data;
            this.modelVer.generoDescricao = data.genero == genero.Masculino ? "Macho" : "Fêmea";
            const cliente = await this.serviceCliente.get(data.clienteId).withLoading().resolveWithJSON<ClienteModel>();
            this.modelVer.clienteDescricao = cliente.nome;
            this.showVer = true;
        } catch {}

        this.shortcutComponent.show();
    }
}
