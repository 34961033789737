var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "crud crud-list" }, [
    _c("div", { staticClass: "info-div" }, [
      _c("h1", { staticClass: "title" }, [
        _vm._v(
          _vm._s(
            _vm.$t("__.Components.parent.relatorio.list_vue_html.relatorios")
          )
        ),
      ]),
    ]),
    _vm._v(" "),
    _c("br"),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "list-group", attrs: { id: "catComissao" } },
      [
        _c(
          "p",
          {
            staticClass: "list-group-item list-group-item-action headerListas",
          },
          [
            _vm._v(
              "\n            " +
                _vm._s(
                  _vm.$t(
                    "__.Components.parent.relatorio.list_vue_html.relaComissao"
                  )
                ) +
                "\n        "
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "router-link",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.permiteExibir("ComissaoVisitador"),
                expression: "permiteExibir('ComissaoVisitador')",
              },
            ],
            staticClass: "list-group-item list-group-item-action rel",
            attrs: { to: "/relatorio-view/17" },
          },
          [
            _c("span", [
              _vm._v(
                _vm._s(
                  _vm.$t(
                    "__.Components.parent.relatorio.list_vue_html.relatComissaoVisitadorSobreVendas"
                  )
                )
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "router-link",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.permiteExibir("ComissaoVendedor"),
                expression: "permiteExibir('ComissaoVendedor')",
              },
            ],
            staticClass: "list-group-item list-group-item-action rel",
            attrs: { to: "/relatorio-view/18" },
          },
          [
            _c("span", [
              _vm._v(
                _vm._s(
                  _vm.$t(
                    "__.Components.parent.relatorio.list_vue_html.relatComissaoVendedorSobreVendas"
                  )
                )
              ),
            ]),
          ]
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "list-group", attrs: { id: "catOrcamento" } },
      [
        _c(
          "p",
          {
            staticClass: "list-group-item list-group-item-action headerListas",
          },
          [
            _vm._v(
              "\n            " +
                _vm._s(
                  _vm.$t(
                    "__.Components.parent.relatorio.list_vue_html.relaOrcamentos"
                  )
                ) +
                "\n        "
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "router-link",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.permiteExibir("Orcamentos"),
                expression: "permiteExibir('Orcamentos')",
              },
            ],
            staticClass: "list-group-item list-group-item-action rel",
            attrs: { to: "/relatorio-view/62" },
          },
          [
            _c("span", [
              _vm._v(
                _vm._s(
                  _vm.$t(
                    "__.Components.parent.relatorio.list_vue_html.relatorioOrcamento"
                  )
                )
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "router-link",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.permiteExibir("VendaOrcamentoRejeitados"),
                expression: "permiteExibir('VendaOrcamentoRejeitados')",
              },
            ],
            staticClass: "list-group-item list-group-item-action rel",
            attrs: { to: "/relatorio-view/38" },
          },
          [
            _c("span", [
              _vm._v(
                "\n                " +
                  _vm._s(
                    _vm.$t(
                      "__.Components.parent.relatorio.list_vue_html.relatOrcamentosRejeitados"
                    )
                  ) +
                  "\n            "
              ),
            ]),
          ]
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "list-group", attrs: { id: "catFaturamento" } },
      [
        _c(
          "p",
          {
            staticClass: "list-group-item list-group-item-action headerListas",
          },
          [
            _vm._v(
              "\n            " +
                _vm._s(
                  _vm.$t(
                    "__.Components.parent.relatorio.list_vue_html.relaFaturamentos"
                  )
                ) +
                "\n        "
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "router-link",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.permiteExibir("Faturamento"),
                expression: "permiteExibir('Faturamento')",
              },
            ],
            staticClass: "list-group-item list-group-item-action rel",
            attrs: { to: "/relatorio-view/65" },
          },
          [
            _c("span", [
              _vm._v(
                _vm._s(
                  _vm.$t(
                    "__.Components.parent.relatorio.list_vue_html.relatFaturamento"
                  )
                )
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "router-link",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.permiteExibir("VendaPorPagamento"),
                expression: "permiteExibir('VendaPorPagamento')",
              },
            ],
            staticClass: "list-group-item list-group-item-action rel",
            attrs: { to: "/relatorio-view/55" },
          },
          [
            _c("span", [
              _vm._v(
                _vm._s(
                  _vm.$t(
                    "__.Components.parent.relatorio.list_vue_html.relatVendasPorTipoPgto"
                  )
                )
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "router-link",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.permiteExibir("VendasSemFaturamento"),
                expression: "permiteExibir('VendasSemFaturamento')",
              },
            ],
            staticClass: "list-group-item list-group-item-action rel",
            attrs: { to: "/relatorio-view/16" },
          },
          [
            _c("span", [
              _vm._v(
                _vm._s(
                  _vm.$t(
                    "__.Components.parent.relatorio.list_vue_html.relatVendasSemFaturamento"
                  )
                )
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "router-link",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.permiteExibir("FaturamentoFranquias"),
                expression: "permiteExibir('FaturamentoFranquias')",
              },
            ],
            staticClass: "list-group-item list-group-item-action rel",
            attrs: { to: "/relatorio-view/54" },
          },
          [
            _c("span", [
              _vm._v(
                _vm._s(
                  _vm.$t(
                    "__.Components.parent.relatorio.list_vue_html.relatFatuamentoFranquia"
                  )
                )
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "router-link",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.permiteExibir("FaturamentoFracionamento"),
                expression: "permiteExibir('FaturamentoFracionamento')",
              },
            ],
            staticClass: "list-group-item list-group-item-action rel",
            attrs: { to: "/relatorio-view/26" },
          },
          [
            _c("span", [
              _vm._v(
                _vm._s(
                  _vm.$t(
                    "__.Components.parent.relatorio.list_vue_html.relatFatuamentoFracionamento"
                  )
                )
              ),
            ]),
          ]
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "list-group", attrs: { id: "catVendas" } },
      [
        _c(
          "p",
          {
            staticClass: "list-group-item list-group-item-action headerListas",
          },
          [
            _vm._v(
              "\n            " +
                _vm._s(
                  _vm.$t(
                    "__.Components.parent.relatorio.list_vue_html.relatVendas"
                  )
                ) +
                "\n        "
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "router-link",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.permiteExibir("CustoDeVenda"),
                expression: "permiteExibir('CustoDeVenda')",
              },
            ],
            staticClass: "list-group-item list-group-item-action",
            attrs: { to: "/relatorio-view/56" },
          },
          [
            _c("span", [
              _vm._v(
                _vm._s(
                  _vm.$t(
                    "__.Components.parent.relatorio.list_vue_html.relatCustoVenda"
                  )
                )
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "router-link",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.permiteExibir("VendaComDesconto"),
                expression: "permiteExibir('VendaComDesconto')",
              },
            ],
            staticClass: "list-group-item list-group-item-action rel",
            attrs: { to: "/relatorio-view/46" },
          },
          [
            _c("span", [
              _vm._v(
                _vm._s(
                  _vm.$t(
                    "__.Components.parent.relatorio.list_vue_html.relatVendaComDesc"
                  )
                )
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "router-link",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.permiteExibir("VendaCliente"),
                expression: "permiteExibir('VendaCliente')",
              },
            ],
            staticClass: "list-group-item list-group-item-action rel",
            attrs: { to: "/relatorio-view/12" },
          },
          [
            _c("span", [
              _vm._v(
                _vm._s(
                  _vm.$t(
                    "__.Components.parent.relatorio.list_vue_html.relatVendaPorCliente"
                  )
                )
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "router-link",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.permiteExibir("VendaPorEspecie"),
                expression: "permiteExibir('VendaPorEspecie')",
              },
            ],
            staticClass: "list-group-item list-group-item-action rel",
            attrs: { to: "/relatorio-view/60" },
          },
          [
            _c("span", [
              _vm._v(
                _vm._s(
                  _vm.$t(
                    "__.Components.parent.relatorio.list_vue_html.relatVendasPorEspecie"
                  )
                )
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "router-link",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.permiteExibir("VendaPorPrescritor"),
                expression: "permiteExibir('VendaPorPrescritor')",
              },
            ],
            staticClass: "list-group-item list-group-item-action rel",
            attrs: { to: "/relatorio-view/40" },
          },
          [
            _c("span", [
              _vm._v(
                _vm._s(
                  _vm.$t(
                    "__.Components.parent.relatorio.list_vue_html.relatVendasPorPrescr"
                  )
                )
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "router-link",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.permiteExibir("VendaPorProduto"),
                expression: "permiteExibir('VendaPorProduto')",
              },
            ],
            staticClass: "list-group-item list-group-item-action rel",
            attrs: { to: "/relatorio-view/52" },
          },
          [
            _c("span", [
              _vm._v(
                _vm._s(
                  _vm.$t(
                    "__.Components.parent.relatorio.list_vue_html.relatVendasPorProds"
                  )
                )
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "router-link",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.permiteExibir("VendaPorProdutoCliente"),
                expression: "permiteExibir('VendaPorProdutoCliente')",
              },
            ],
            staticClass: "list-group-item list-group-item-action rel",
            attrs: { to: "/relatorio-view/58" },
          },
          [
            _c("span", [
              _vm._v(
                _vm._s(
                  _vm.$t(
                    "__.Components.parent.relatorio.list_vue_html.relatVendasPorProdsClientes"
                  )
                )
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "router-link",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.permiteExibir("VendaPorVendedor"),
                expression: "permiteExibir('VendaPorVendedor')",
              },
            ],
            staticClass: "list-group-item list-group-item-action rel",
            attrs: { to: "/relatorio-view/3" },
          },
          [
            _c("span", [
              _vm._v(
                _vm._s(
                  _vm.$t(
                    "__.Components.parent.relatorio.list_vue_html.relatVendasPorVendedor"
                  )
                )
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "router-link",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.permiteExibir("VendaVisitador"),
                expression: "permiteExibir('VendaVisitador')",
              },
            ],
            staticClass: "list-group-item list-group-item-action rel",
            attrs: { to: "/relatorio-view/4" },
          },
          [
            _c("span", [
              _vm._v(
                _vm._s(
                  _vm.$t(
                    "__.Components.parent.relatorio.list_vue_html.relatVendasPorVisitador"
                  )
                )
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "router-link",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.permiteExibir("VendaFranquiaAbrangencia"),
                expression: "permiteExibir('VendaFranquiaAbrangencia')",
              },
            ],
            staticClass: "list-group-item list-group-item-action rel",
            attrs: { to: "/relatorio-view/7" },
          },
          [
            _c("span", [
              _vm._v(
                _vm._s(
                  _vm.$t(
                    "__.Components.parent.relatorio.list_vue_html.relatVendasFranquiaAbrangencia"
                  )
                )
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "router-link",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.permiteExibir("EvolucaoVendas"),
                expression: "permiteExibir('EvolucaoVendas')",
              },
            ],
            staticClass: "list-group-item list-group-item-action rel",
            attrs: { to: "/relatorio-view/35" },
          },
          [
            _c("span", [
              _vm._v(
                _vm._s(
                  _vm.$t(
                    "__.Components.parent.relatorio.list_vue_html.relatorioEvolucaoVendas"
                  )
                )
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "router-link",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.permiteExibir("VendasPorFormaFarmaceutica"),
                expression: "permiteExibir('VendasPorFormaFarmaceutica')",
              },
            ],
            staticClass: "list-group-item list-group-item-action rel",
            attrs: { to: "/relatorio-view/36" },
          },
          [
            _c("span", [
              _vm._v(
                "\n                " +
                  _vm._s(
                    _vm.$t(
                      "__.Components.parent.relatorio.list_vue_html.relatorioVendasPorFormaFarmaceutica"
                    )
                  ) +
                  "\n            "
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "router-link",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.permiteExibir("VendasPorPeriodo"),
                expression: "permiteExibir('VendasPorPeriodo')",
              },
            ],
            staticClass: "list-group-item list-group-item-action rel",
            attrs: { to: "/relatorio-view/37" },
          },
          [
            _c("span", [
              _vm._v(
                "\n                " +
                  _vm._s(
                    _vm.$t(
                      "__.Components.parent.relatorio.list_vue_html.relatorioVendasPorPeriodo"
                    )
                  ) +
                  "\n            "
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "router-link",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.permiteExibir("VendaCidade"),
                expression: "permiteExibir('VendaCidade')",
              },
            ],
            staticClass: "list-group-item list-group-item-action rel",
            attrs: { to: "/relatorio-view/45" },
          },
          [
            _c("span", [
              _vm._v(
                _vm._s(
                  _vm.$t(
                    "__.Components.parent.relatorio.list_vue_html.relatorioVendaPorCidade"
                  )
                )
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "router-link",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.permiteExibir("VendasComTroco"),
                expression: "permiteExibir('VendasComTroco')",
              },
            ],
            staticClass: "list-group-item list-group-item-action rel",
            attrs: { to: "/relatorio-view/63" },
          },
          [
            _c("span", [
              _vm._v(
                _vm._s(
                  _vm.$t(
                    "__.Components.parent.relatorio.list_vue_html.relatorioVendaComTroco"
                  )
                )
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "router-link",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.permiteExibir("VendasComCupom"),
                expression: "permiteExibir('VendasComCupom')",
              },
            ],
            staticClass: "list-group-item list-group-item-action rel",
            attrs: { to: "/relatorio-view/64" },
          },
          [
            _c("span", [
              _vm._v(
                _vm._s(
                  _vm.$t(
                    "__.Components.parent.relatorio.list_vue_html.relatorioVendaComCupom"
                  )
                )
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "router-link",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.permiteExibir("VendasPorColaborador"),
                expression: "permiteExibir('VendasPorColaborador')",
              },
            ],
            staticClass: "list-group-item list-group-item-action rel",
            attrs: { to: "/relatorio-view/67" },
          },
          [
            _c("span", [
              _vm._v(
                _vm._s(
                  _vm.$t(
                    "__.Components.parent.relatorio.list_vue_html.relatorioVendasPorColaborador"
                  )
                )
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "router-link",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.permiteExibir("VendasPorEstoque"),
                expression: "permiteExibir('VendasPorEstoque')",
              },
            ],
            staticClass: "list-group-item list-group-item-action rel",
            attrs: { to: "/relatorio-view/68" },
          },
          [
            _c("span", [
              _vm._v(
                _vm._s(
                  _vm.$t(
                    "__.Components.parent.relatorio.list_vue_html.relatorioVendasPorEstoque"
                  )
                )
              ),
            ]),
          ]
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "list-group", attrs: { id: "catNotas" } },
      [
        _c(
          "p",
          {
            staticClass: "list-group-item list-group-item-action headerListas",
          },
          [
            _vm._v(
              "\n            " +
                _vm._s(
                  _vm.$t(
                    "__.Components.parent.relatorio.list_vue_html.relaNotas"
                  )
                ) +
                "\n        "
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "router-link",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.permiteExibir("VendasSemNota"),
                expression: "permiteExibir('VendasSemNota')",
              },
            ],
            staticClass: "list-group-item list-group-item-action rel",
            attrs: { to: "/relatorio-view/15" },
          },
          [
            _c("span", [
              _vm._v(
                _vm._s(
                  _vm.$t(
                    "__.Components.parent.relatorio.list_vue_html.relatVendasSemNota"
                  )
                )
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "router-link",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.permiteExibir("NotasSaida"),
                expression: "permiteExibir('NotasSaida')",
              },
            ],
            staticClass: "list-group-item list-group-item-action rel",
            attrs: { to: "/relatorio-view/34" },
          },
          [
            _c("span", [
              _vm._v(
                _vm._s(
                  _vm.$t(
                    "__.Components.parent.relatorio.list_vue_html.relatorioNotasSaida"
                  )
                )
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "router-link",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.permiteExibir("ImpostoEmissoes"),
                expression: "permiteExibir('ImpostoEmissoes')",
              },
            ],
            staticClass: "list-group-item list-group-item-action rel",
            attrs: { to: "/relatorio-view/8" },
          },
          [
            _c("span", [
              _vm._v(
                _vm._s(
                  _vm.$t(
                    "__.Components.parent.relatorio.list_vue_html.relatorioImpostoEmissoes"
                  )
                )
              ),
            ]),
          ]
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "list-group", attrs: { id: "catProdutos" } },
      [
        _c(
          "p",
          {
            staticClass: "list-group-item list-group-item-action headerListas",
          },
          [
            _vm._v(
              "\n            " +
                _vm._s(
                  _vm.$t(
                    "__.Components.parent.relatorio.list_vue_html.relatsProdutos"
                  )
                ) +
                "\n        "
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "router-link",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.permiteExibir("ProdutosSemVenda"),
                expression: "permiteExibir('ProdutosSemVenda')",
              },
            ],
            staticClass: "list-group-item list-group-item-action rel",
            attrs: { to: "/relatorio-view/25" },
          },
          [
            _c("span", [
              _vm._v(
                _vm._s(
                  _vm.$t(
                    "__.Components.parent.relatorio.list_vue_html.relatProdSemVenda"
                  )
                )
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "router-link",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.permiteExibir("Continuos"),
                expression: "permiteExibir('Continuos')",
              },
            ],
            staticClass: "list-group-item list-group-item-action rel",
            attrs: { to: "/relatorio-view/51" },
          },
          [
            _c("span", [
              _vm._v(
                _vm._s(
                  _vm.$t(
                    "__.Components.parent.relatorio.list_vue_html.relatContinuos"
                  )
                )
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "router-link",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.permiteExibir("DadosGeraisProduto"),
                expression: "permiteExibir('DadosGeraisProduto')",
              },
            ],
            staticClass: "list-group-item list-group-item-action rel",
            attrs: { to: "/relatorio-view/19" },
          },
          [
            _c("span", [
              _vm._v(
                _vm._s(
                  _vm.$t(
                    "__.Components.parent.relatorio.list_vue_html.relatDadosGeraisProduto"
                  )
                )
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "router-link",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.permiteExibir("CustoMedioProdutoVendido"),
                expression: "permiteExibir('CustoMedioProdutoVendido')",
              },
            ],
            staticClass: "list-group-item list-group-item-action rel",
            attrs: { to: "/relatorio-view/22" },
          },
          [
            _c("span", [
              _vm._v(
                _vm._s(
                  _vm.$t(
                    "__.Components.parent.relatorio.list_vue_html.relatCustoMedioProdutoVendido"
                  )
                )
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "router-link",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.permiteExibir("ProdutosControlados"),
                expression: "permiteExibir('ProdutosControlados')",
              },
            ],
            staticClass: "list-group-item list-group-item-action rel",
            attrs: { to: "/relatorio-view/32" },
          },
          [
            _c("span", [
              _vm._v(
                _vm._s(
                  _vm.$t(
                    "__.Components.parent.relatorio.list_vue_html.relatorioProdutosControlados"
                  )
                )
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "router-link",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.permiteExibir("ConsumoProdutoPorFranquia"),
                expression: "permiteExibir('ConsumoProdutoPorFranquia')",
              },
            ],
            staticClass: "list-group-item list-group-item-action rel",
            attrs: { to: "/relatorio-view/33" },
          },
          [
            _c("span", [
              _vm._v(
                _vm._s(
                  _vm.$t(
                    "__.Components.parent.relatorio.list_vue_html.relatorioConsumoProdutoPorFranquia"
                  )
                )
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "router-link",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.permiteExibir("ProdutosMaisVendidos"),
                expression: "permiteExibir('ProdutosMaisVendidos')",
              },
            ],
            staticClass: "list-group-item list-group-item-action rel",
            attrs: { to: "/relatorio-view/39" },
          },
          [
            _c("span", [
              _vm._v(
                "\n                " +
                  _vm._s(
                    _vm.$t(
                      "__.Components.parent.relatorio.list_vue_html.relatorioProdutosMaisVendidos"
                    )
                  ) +
                  "\n            "
              ),
            ]),
          ]
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "list-group", attrs: { id: "catFinanceiro" } },
      [
        _c(
          "p",
          {
            staticClass: "list-group-item list-group-item-action headerListas",
          },
          [
            _vm._v(
              "\n            " +
                _vm._s(
                  _vm.$t(
                    "__.Components.parent.relatorio.list_vue_html.relatsFinanceiro"
                  )
                ) +
                "\n        "
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "router-link",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.permiteExibir("ContasAPagar"),
                expression: "permiteExibir('ContasAPagar')",
              },
            ],
            staticClass: "list-group-item list-group-item-action rel",
            attrs: { to: "/relatorio-view/48" },
          },
          [
            _c("span", [
              _vm._v(
                _vm._s(
                  _vm.$t(
                    "__.Components.parent.relatorio.list_vue_html.relatContasAPagar"
                  )
                )
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "router-link",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.permiteExibir("ContasAReceber"),
                expression: "permiteExibir('ContasAReceber')",
              },
            ],
            staticClass: "list-group-item list-group-item-action rel",
            attrs: { to: "/relatorio-view/47" },
          },
          [
            _c("span", [
              _vm._v(
                _vm._s(
                  _vm.$t(
                    "__.Components.parent.relatorio.list_vue_html.relatContasAReceber"
                  )
                )
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "router-link",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.permiteExibir("ContasPagas"),
                expression: "permiteExibir('ContasPagas')",
              },
            ],
            staticClass: "list-group-item list-group-item-action rel",
            attrs: { to: "/relatorio-view/49" },
          },
          [
            _c("span", [
              _vm._v(
                _vm._s(
                  _vm.$t(
                    "__.Components.parent.relatorio.list_vue_html.relatContasPagas"
                  )
                )
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "router-link",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.permiteExibir("ContasRecebidas"),
                expression: "permiteExibir('ContasRecebidas')",
              },
            ],
            staticClass: "list-group-item list-group-item-action rel",
            attrs: { to: "/relatorio-view/50" },
          },
          [
            _c("span", [
              _vm._v(
                _vm._s(
                  _vm.$t(
                    "__.Components.parent.relatorio.list_vue_html.relatContasRecebidas"
                  )
                )
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "router-link",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.permiteExibir("FluxoDeCaixa"),
                expression: "permiteExibir('FluxoDeCaixa')",
              },
            ],
            staticClass: "list-group-item list-group-item-action rel",
            attrs: { to: "/relatorio-view/53" },
          },
          [
            _c("span", [
              _vm._v(
                _vm._s(
                  _vm.$t(
                    "__.Components.parent.relatorio.list_vue_html.relatFluxoCaixa"
                  )
                )
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "router-link",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.permiteExibir("AberturasCaixa"),
                expression: "permiteExibir('AberturasCaixa')",
              },
            ],
            staticClass: "list-group-item list-group-item-action rel",
            attrs: { to: "/relatorio-view/14" },
          },
          [
            _c("span", [
              _vm._v(
                _vm._s(
                  _vm.$t(
                    "__.Components.parent.relatorio.list_vue_html.relatorioAberturasCaixa"
                  )
                )
              ),
            ]),
          ]
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "list-group", attrs: { id: "catEstoque" } },
      [
        _c(
          "p",
          {
            staticClass: "list-group-item list-group-item-action headerListas",
          },
          [
            _vm._v(
              "\n            " +
                _vm._s(
                  _vm.$t(
                    "__.Components.parent.relatorio.list_vue_html.relatEstoque"
                  )
                ) +
                "\n        "
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "router-link",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.permiteExibir("CoberturaEstoque"),
                expression: "permiteExibir('CoberturaEstoque')",
              },
            ],
            staticClass: "list-group-item list-group-item-action rel",
            attrs: { to: "/relatorio-view/2" },
          },
          [
            _c("span", [
              _vm._v(
                _vm._s(
                  _vm.$t(
                    "__.Components.parent.relatorio.list_vue_html.relatCoberturaEstoque"
                  )
                )
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "router-link",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.permiteExibir("Romaneio"),
                expression: "permiteExibir('Romaneio')",
              },
            ],
            staticClass: "list-group-item list-group-item-action rel",
            attrs: { to: "/relatorio-view/5" },
          },
          [
            _c("span", [
              _vm._v(
                _vm._s(
                  _vm.$t(
                    "__.Components.parent.relatorio.list_vue_html.relatorioRomaneio"
                  )
                )
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "router-link",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.permiteExibir("MotivoMovimentacao"),
                expression: "permiteExibir('MotivoMovimentacao')",
              },
            ],
            staticClass: "list-group-item list-group-item-action rel",
            attrs: { to: "/relatorio-view/6" },
          },
          [
            _c("span", [
              _vm._v(
                _vm._s(
                  _vm.$t(
                    "__.Components.parent.relatorio.list_vue_html.relatorioMotivoMovimentacao"
                  )
                )
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "router-link",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.permiteExibir("MovimentacaoLote"),
                expression: "permiteExibir('MovimentacaoLote')",
              },
            ],
            staticClass: "list-group-item list-group-item-action rel",
            attrs: { to: "/relatorio-view/20" },
          },
          [
            _c("span", [
              _vm._v(
                _vm._s(
                  _vm.$t(
                    "__.Components.parent.relatorio.list_vue_html.relatMovimentacaoLote"
                  )
                )
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "router-link",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.permiteExibir("PosicaoEstoque"),
                expression: "permiteExibir('PosicaoEstoque')",
              },
            ],
            staticClass: "list-group-item list-group-item-action rel",
            attrs: { to: "/relatorio-view/9" },
          },
          [
            _c("span", [
              _vm._v(
                _vm._s(
                  _vm.$t(
                    "__.Components.parent.relatorio.list_vue_html.relatPosicaoEstoque"
                  )
                )
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "router-link",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.permiteExibir("Lotes"),
                expression: "permiteExibir('Lotes')",
              },
            ],
            staticClass: "list-group-item list-group-item-action rel",
            attrs: { to: "/relatorio-view/23" },
          },
          [
            _c("span", [
              _vm._v(
                _vm._s(
                  _vm.$t(
                    "__.Components.parent.relatorio.list_vue_html.relatLotes"
                  )
                )
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "router-link",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.permiteExibir("ComprasProduto"),
                expression: "permiteExibir('ComprasProduto')",
              },
            ],
            staticClass: "list-group-item list-group-item-action rel",
            attrs: { to: "/relatorio-view/11" },
          },
          [
            _c("span", [
              _vm._v(
                _vm._s(
                  _vm.$t(
                    "__.Components.parent.relatorio.list_vue_html.relatComprasProduto"
                  )
                )
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "router-link",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.permiteExibir("VendasComprometendoLote"),
                expression: "permiteExibir('VendasComprometendoLote')",
              },
            ],
            staticClass: "list-group-item list-group-item-action rel",
            attrs: { to: "/relatorio-view/21" },
          },
          [
            _c("span", [
              _vm._v(
                _vm._s(
                  _vm.$t(
                    "__.Components.parent.relatorio.list_vue_html.relatVendasCompromentendoLote"
                  )
                )
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "router-link",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.permiteExibir("SaldoProdutos"),
                expression: "permiteExibir('SaldoProdutos')",
              },
            ],
            staticClass: "list-group-item list-group-item-action rel",
            attrs: { to: "/relatorio-view/43" },
          },
          [
            _c("span", [
              _vm._v(
                _vm._s(
                  _vm.$t(
                    "__.Components.parent.relatorio.list_vue_html.relatSaldoProdutos"
                  )
                )
              ),
            ]),
          ]
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "list-group", attrs: { id: "catOutros" } },
      [
        _c(
          "p",
          {
            staticClass: "list-group-item list-group-item-action headerListas",
          },
          [
            _vm._v(
              "\n            " +
                _vm._s(
                  _vm.$t("__.Components.parent.relatorio.list_vue_html.outros")
                ) +
                "\n        "
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "router-link",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.permiteExibir("Beneficio"),
                expression: "permiteExibir('Beneficio')",
              },
            ],
            staticClass: "list-group-item list-group-item-action rel",
            attrs: { to: "/relatorio-view/1" },
          },
          [
            _c("span", [
              _vm._v(
                _vm._s(
                  _vm.$t(
                    "__.Components.parent.relatorio.list_vue_html.relatBeneficios"
                  )
                )
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "router-link",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.permiteExibir("ImpostoEmissoes"),
                expression: "permiteExibir('ImpostoEmissoes')",
              },
            ],
            staticClass: "list-group-item list-group-item-action rel",
            attrs: { to: "/relatorio-view/8" },
          },
          [
            _c("span", [
              _vm._v(
                _vm._s(
                  _vm.$t(
                    "__.Components.parent.relatorio.list_vue_html.relatorioImpostoEmissoes"
                  )
                )
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "router-link",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.permiteExibir("Aniversario"),
                expression: "permiteExibir('Aniversario')",
              },
            ],
            staticClass: "list-group-item list-group-item-action rel",
            attrs: { to: "/relatorio-view/42" },
          },
          [
            _c("span", [
              _vm._v(
                _vm._s(
                  _vm.$t(
                    "__.Components.parent.relatorio.list_vue_html.relatAniversario"
                  )
                )
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "router-link",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.permiteExibir("PontuacaoDotzPorPrescritor"),
                expression: "permiteExibir('PontuacaoDotzPorPrescritor')",
              },
            ],
            staticClass: "list-group-item list-group-item-action rel",
            attrs: { to: "/relatorio-view/13" },
          },
          [
            _c("span", [
              _vm._v(
                _vm._s(
                  _vm.$t(
                    "__.Components.parent.relatorio.list_vue_html.relatorioPontuacoesPorPrescritor"
                  )
                )
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "router-link",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.permiteExibir("PCPEtapas"),
                expression: "permiteExibir('PCPEtapas')",
              },
            ],
            staticClass: "list-group-item list-group-item-action rel",
            attrs: { to: "/relatorio-view/24" },
          },
          [
            _c("span", [
              _vm._v(
                _vm._s(
                  _vm.$t(
                    "__.Components.parent.relatorio.list_vue_html.relatorioPCPEtapas"
                  )
                )
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "router-link",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.permiteExibir("Prescritores"),
                expression: "permiteExibir('Prescritores')",
              },
            ],
            staticClass: "list-group-item list-group-item-action rel",
            attrs: { to: "/relatorio-view/44" },
          },
          [
            _c("span", [
              _vm._v(
                _vm._s(
                  _vm.$t(
                    "__.Components.parent.relatorio.list_vue_html.relatPrescritores"
                  )
                )
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "router-link",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.permiteExibir("Cobranca"),
                expression: "permiteExibir('Cobranca')",
              },
            ],
            staticClass: "list-group-item list-group-item-action rel",
            attrs: { to: "/relatorio-view/57" },
          },
          [
            _c("span", [
              _vm._v(
                _vm._s(
                  _vm.$t(
                    "__.Components.parent.relatorio.list_vue_html.relatCobranca"
                  )
                )
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "router-link",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.permiteExibir("ProdutoVendidosDrogavet"),
                expression: "permiteExibir('ProdutoVendidosDrogavet')",
              },
            ],
            staticClass: "list-group-item list-group-item-action rel",
            attrs: { to: "/relatorio-view/59" },
          },
          [
            _c("span", [
              _vm._v(
                _vm._s(
                  _vm.$t(
                    "__.Components.parent.relatorio.list_vue_html.relatProdutoVendidosDrogavet"
                  )
                )
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "router-link",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.permiteExibir("TaxaEntrega"),
                expression: "permiteExibir('TaxaEntrega')",
              },
            ],
            staticClass: "list-group-item list-group-item-action rel",
            attrs: { to: "/relatorio-view/61" },
          },
          [
            _c("span", [
              _vm._v(
                _vm._s(
                  _vm.$t(
                    "__.Components.parent.relatorio.list_vue_html.relatorioTaxaEntrega"
                  )
                )
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "router-link",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.permiteExibir("RegistroReceituarioGeral"),
                expression: "permiteExibir('RegistroReceituarioGeral')",
              },
            ],
            staticClass: "list-group-item list-group-item-action rel",
            attrs: { to: "/relatorio-stimulsoft/RegistroReceituarioGeral" },
          },
          [
            _c("span", [
              _vm._v(
                _vm._s(
                  _vm.$t(
                    "__.Components.parent.relatorio.list_vue_html.relatRegistroReceituarioGeral"
                  )
                )
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "router-link",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.permiteExibir("RecompensaUtilizadaCampanha"),
                expression: "permiteExibir('RecompensaUtilizadaCampanha')",
              },
            ],
            staticClass: "list-group-item list-group-item-action rel",
            attrs: { to: "/relatorio-view/31" },
          },
          [
            _c("span", [
              _vm._v(
                _vm._s(
                  _vm.$t(
                    "__.Components.parent.relatorio.list_vue_html.relatRecompensaUtilizadaCampanha"
                  )
                )
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "router-link",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.permiteExibir("ControleReclamacao"),
                expression: "permiteExibir('ControleReclamacao')",
              },
            ],
            staticClass: "list-group-item list-group-item-action rel",
            attrs: { to: "/relatorio-view/66" },
          },
          [
            _c("span", [
              _vm._v(
                _vm._s(
                  _vm.$t(
                    "__.Components.parent.relatorio.list_vue_html.relatorioControleReclamacao"
                  )
                )
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "router-link",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.permiteExibir("AcompanhamentoFranqueadora"),
                expression: "permiteExibir('AcompanhamentoFranqueadora')",
              },
            ],
            staticClass: "list-group-item list-group-item-action rel",
            attrs: { to: "/relatorio-view/69" },
          },
          [
            _c("span", [
              _vm._v(
                "\n                " +
                  _vm._s(
                    _vm.$t(
                      "__.Components.parent.relatorio.list_vue_html.relatorioAcompanhamentoFranqueadora"
                    )
                  ) +
                  "\n            "
              ),
            ]),
          ]
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c("br"),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }