import Vue from "vue";
import { VueTabs, VTab } from "vue-nav-tabs";
import { mapMutations, mapState } from "vuex";

Vue.component("vue-tabs", VueTabs);
Vue.component("v-tab", VTab);

import buttonIncluirComponent from "@/components/child/form/button/buttonIncluir.vue";
import checkboxComponent from "@/components/child/form/checkbox.vue";
import comboComponent from "@/components/child/form/combo.vue";
import dataTooltipComponent from "@/components/child/form/datatooltip.vue";
import dateTimePickerComponent from "@/components/child/form/datetimepicker.vue";
import decimalComponent from "@/components/child/form/decimal.vue";
import decimalComSinalCustomComponent from "@/components/child/form/decimalComSinalCustom.vue";
import fieldsetComponent from "@/components/child/form/fieldset.vue";
import moedaComponent from "@/components/child/form/moeda.vue";
import gridComponent from "@/components/child/grid/grid.vue";
import { GridAction } from "@/components/child/grid/gridAction";
import { GridColors, GridColumn, GridColumnType } from "@/components/child/grid/gridColumn";
import searchComboComponent from "@/components/child/searchCombo/search.vue";
import { Component, Prop, Watch } from "@/decorators";
import PreLoadPackModel from "@/models/auxiliar/preLoadPackModel";
import EnumExtensions from "@/models/enum/extensions/enumExtensions";
import TipoOperacao from "@/models/enum/tipoOperacao";
import Ufs from "@/models/enum/ufs";
import NotaFiscalEletronicaEmissaoItemModel from "@/models/notaFiscalEletronicaEmissaoItemModel";
import NotaFiscalEletronicaEmissaoModel from "@/models/notaFiscalEletronicaEmissaoModel";
import PaginationModel from "@/models/paginationModel";
import NcmModel from "@/models/produto/ncmModel";
import ProdutoModel from "@/models/produto/produtoModel";
import ProdutoLoteModel from "@/models/produtoLoteModel";
import UsuarioModel from "@/models/usuarioModel";
import OrigemMercadoriaService from "@/services/origemMercadoriaService";
import NcmService from "@/services/produto/ncmService";
import ProdutoService from "@/services/produto/produtoService";
import ProdutoLoteService from "@/services/produtoLoteService";
import SituacaoTributariaService from "@/services/situacaoTributariaService";
import { AppState, Mutations } from "@/store/store";
import arithmeticHelper from "@/utils/common/arithmeticHelper";
import { getProdutoCombo, getProdutoLoteCombo } from "@/utils/common/combo/combotext";
import Delay from "@/utils/common/delay";

import produtoLoteComponent from "../../parent/crud/produtolote/edit.vue";
import ProdutoLoteEditComponent from "../crud/produtolote/edit";

import adicaoComponent from "./adicao.vue";
import {
    defineTipoCSTCOFINS,
    defineTipoCSTICMS,
    defineTipoCSTIPI,
    defineTipoCSTPIS,
    getImpostos,
    getNewItemByProduto,
    validateItemNota,
} from "./nfeUtils";

import "../crud/crud.scss";

@Component({
    components: {
        adicaoComponent,
        gridComponent,
        searchComboComponent,
        moedaComponent,
        comboComponent,
        checkboxComponent,
        decimalComponent,
        dataTooltipComponent,
        fieldsetComponent,
        dateTimePickerComponent,
        decimalComSinalCustomComponent,
        produtoLoteComponent,
        buttonIncluirComponent,
    },
    computed: {
        valorTotal() {
            return arithmeticHelper.round(
                this.modelItem.valorUnitario * this.modelItem.quantidade +
                    this.modelItem.valorFrete +
                    this.modelItem.valorSeguro +
                    this.modelItem.valorOutraDespesa -
                    this.modelItem.valorDesconto,
            );
        },
        baseICMS() {
            if (this.editingModel) return this.modelItem.baseICMS;
            if (
                this.modelItem.cstICMS != 900 &&
                (this.modelItem.aliquotaICMS == null ||
                    this.modelItem.cstICMS == 40 ||
                    this.modelItem.cstICMS == 41 ||
                    this.modelItem.cstICMS == 50)
            )
                return null;

            let valor =
                this.modelItem.valorUnitario * this.modelItem.quantidade -
                this.modelItem.valorDesconto +
                this.modelItem.valorFrete;
            if (this.nota.notaImportacao)
                return arithmeticHelper.round(
                    (valor +
                        this.modelItem.valorII +
                        (this.modelItem.sujeitoIPI ? this.modelItem.valorIPI : 0) +
                        this.modelItem.valorPIS +
                        this.modelItem.valorCOFINS +
                        this.modelItem.valorDespesasAduaneiras) /
                        (1 - this.modelItem.aliquotaICMS / 100),
                );
            valor = arithmeticHelper.round(valor);
            if (this.modelItem.comICMSPartilha) this.modelItem.baseUFDestino = valor;
            return valor;
        },
        baseIPI() {
            if (!this.modelItem.sujeitoIPI) return null;
            if (this.editingModel) return this.modelItem.baseIPI;
            if (this.modelItem.aliquotaIPI == null) return null;
            if (
                (this.modelItem.cstIPI >= 2 && this.modelItem.cstIPI <= 5) ||
                (this.modelItem.cstIPI >= 52 && this.modelItem.cstIPI <= 55)
            )
                return null;
            return arithmeticHelper.round(
                this.modelItem.valorUnitario * this.modelItem.quantidade + this.modelItem.valorII,
            );
        },
        baseII() {
            if (this.editingModel) return this.modelItem.baseII;
            if (this.nota.notaImportacao) return this.modelItem.valorUnitario * this.modelItem.quantidade;
            return null;
        },
        basePIS() {
            if (this.editingModel) return this.modelItem.basePIS;
            if (this.modelItem.aliquotaPIS == null) return null;
            if (
                (this.modelItem.cstPIS >= 7 && this.modelItem.cstPIS <= 9) ||
                (this.modelItem.cstPIS >= 70 && this.modelItem.cstPIS <= 72) ||
                (this.modelItem.cstPIS >= 74 && this.modelItem.cstPIS <= 75)
            )
                return null;
            return this.modelItem.valorUnitario * this.modelItem.quantidade;
        },
        baseCOFINS() {
            if (this.editingModel) return this.modelItem.baseCOFINS;
            if (this.modelItem.aliquotaCOFINS == null) return null;
            if (
                (this.modelItem.cstCOFINS >= 7 && this.modelItem.cstCOFINS <= 9) ||
                (this.modelItem.cstCOFINS >= 70 && this.modelItem.cstCOFINS <= 72) ||
                (this.modelItem.cstCOFINS >= 74 && this.modelItem.cstCOFINS <= 75)
            )
                return null;
            return this.modelItem.valorUnitario * this.modelItem.quantidade;
        },
        valorICMSDiferido() {
            if (this.modelItem.percentualDiferimento > 0 && this.modelItem.cstICMS == 51)
                return arithmeticHelper.round((this.modelItem.valorICMS * this.modelItem.percentualDiferimento) / 100);
            return null;
        },
        valorICMSPagar() {
            if (this.modelItem.percentualDiferimento > 0 && this.modelItem.cstICMS == 51)
                return arithmeticHelper.round(this.modelItem.valorICMS - this.modelItem.valorICMSDiferido);
            return null;
        },
        baseReduzida() {
            if (this.modelItem.percentualReducaoBase > 0 && this.modelItem.cstICMS == 20)
                return arithmeticHelper.round((this.modelItem.baseICMS * this.modelItem.percentualReducaoBase) / 100);
            return null;
        },
        valorICMS() {
            if (this.modelItem.percentualReducaoBase > 0 && this.modelItem.cstICMS == 20)
                return arithmeticHelper.round((this.baseReduzida * this.modelItem.aliquotaICMS) / 100);
            return null;
        },
        valorUFDestino() {
            const aliquota =
                this.modelItem.aliquotaUFDestino > this.modelItem.aliquotaInterestadual
                    ? this.modelItem.aliquotaUFDestino - this.modelItem.aliquotaInterestadual
                    : this.modelItem.aliquotaInterestadual;

            return arithmeticHelper.round(
                this.modelItem.baseUFDestino * (aliquota / 100) * (this.modelItem.aliquotaInterestadualPartilha / 100),
            );
        },
        valorUFRemetente() {
            const aliquota =
                this.modelItem.aliquotaUFDestino > this.modelItem.aliquotaInterestadual
                    ? this.modelItem.aliquotaUFDestino - this.modelItem.aliquotaInterestadual
                    : this.modelItem.aliquotaInterestadual;

            return arithmeticHelper.round(
                this.modelItem.baseUFDestino *
                    (aliquota / 100) *
                    ((100 - this.modelItem.aliquotaInterestadualPartilha) / 100),
            );
        },
        valorFCPUFDestino() {
            return arithmeticHelper.round(
                (this.modelItem.baseFCPUFDestino * this.modelItem.aliquotaFCPUFDestino) / 100,
            );
        },
        ...mapState({
            nota: (state: AppState) => state.nfeContext.nota,
            consulta: (state: AppState) => state.nfeContext.consulta,
            emissaoVenda: (state: AppState) => state.nfeContext.emissaoVenda,
            preLoadPack: (state: AppState) => state.preLoad.preLoadList,
            loadedList: (state: AppState) => state.preLoad.loadedList,
            usuarioLogado: (state: AppState) => state.session.usuarioLogado,
        }),
    },
    methods: mapMutations([
        "SET_NOTA",
        "ADD_ITEM_NOTA",
        "UPDATE_ITEM",
        "SET_NOTA_CONSULTA",
        "SET_NOTA_EMISSAO_VENDA",
    ] as Mutations),
})
export default class ItemNFEComponent extends Vue {
    // State computed props
    nota: NotaFiscalEletronicaEmissaoModel;
    consulta: boolean;
    emissaoVenda: boolean;
    preLoadPack: PreLoadPackModel;
    loadedList: boolean;
    usuarioLogado: UsuarioModel;
    SET_NOTA: (nota: NotaFiscalEletronicaEmissaoModel) => void;
    ADD_ITEM_NOTA: ({
        item,
        simplesNacional,
    }: {
        item: NotaFiscalEletronicaEmissaoItemModel;
        simplesNacional: boolean;
    }) => void;
    UPDATE_ITEM: (data: object) => void;
    SET_NOTA_CONSULTA: (consulta: boolean) => void;
    SET_NOTA_EMISSAO_VENDA: (emissaoVenda: boolean) => void;

    private modelItem = new NotaFiscalEletronicaEmissaoItemModel();
    private produtoService = new ProdutoService();
    private produtoLoteService = new ProdutoLoteService();
    private ncmService = new NcmService();
    private produtoLoteComponent: ProdutoLoteEditComponent = null;

    private showItem = true;
    private editingModel = false;
    private indexEdditingModel = -1;

    private oldBaseICMS: number = null;
    private alterouBaseICMS = false;
    private oldBaseIPI: number = null;
    private alterouBaseIPI = false;

    private produtosOptions: Array<Object> = [];
    private produtoLoteOptions: Array<Object> = [];
    private unidadeMedidaOptions: Array<Object> = [];
    private ufDesembaracoOptions: Array<Object> = EnumExtensions.getNamesAndValuesOrderedByNames(Ufs);

    private origemMercadoriaOptions: Array<Object> = [];
    private cstICMSOptions: Array<Object> = [];
    private cstIPIOptions: Array<Object> = [];
    private cstPISCOFINSOptions: Array<Object> = [];
    private refreshGrid = false;

    private viaTransporteOptions: Array<Object> = [
        { text: "1-Marítima", value: 1 },
        { text: "2-Fluvial", value: 2 },
        { text: "3-Lacustre", value: 3 },
        { text: "4-Aérea", value: 4 },
        { text: "5-Posta", value: 5 },
        { text: "6-Ferroviária", value: 6 },
        { text: "7-Rodoviária", value: 7 },
        { text: "8-Conduto / Rede Transmissão", value: 8 },
        { text: "9-Meios Próprios", value: 9 },
        { text: "10-Entrada / Saída ficta", value: 10 },
        { text: "11-Courier", value: 11 },
        { text: "12-Handcarry", value: 12 },
    ];
    private tipoIntermedioOptions: Array<Object> = [
        { text: "1-Importação por conta própria", value: 1 },
        { text: "2-Importação por conta e ordem", value: 2 },
        { text: "3-Importação por encomenda", value: 3 },
    ];

    private customComboTextNCM = (p: NcmModel) => ({
        value: p.id,
        text: `${p.codigo} ${!p.ativo ? "[INATIVO] " : ""}- (${p.descricao})`,
    });

    private produtoAlteradosCompra = [];

    @Prop(Boolean) baixaEstoque: boolean;

    get gridColumns(): Array<GridColumn> {
        return [
            new GridColumn("numeroItem", "Nº", GridColumnType.Integer),
            new GridColumn("produtoDescricao", "Produto", GridColumnType.String),
            new GridColumn("unidadeMedidaSigla", "Un. Med.", GridColumnType.String),
            new GridColumn("ncmCodigo", "NCM", GridColumnType.String),
            new GridColumn("origemMercadoria", "Origem", GridColumnType.Integer),
            new GridColumn("quantidade", "Qtd.", GridColumnType.Decimal),
            new GridColumn("valorUnitario", "Valor Unit.", GridColumnType.Money, false, false, "", "", false, true, 4),
            new GridColumn("valorDesconto", "Desconto", GridColumnType.Money),
            new GridColumn("valorFrete", "Frete", GridColumnType.Money),
            new GridColumn("valorSeguro", "Seguro", GridColumnType.Money),
            new GridColumn("valorOutraDespesa", "Outras Despesas", GridColumnType.Money),
            new GridColumn("valorTotal", "Valor Total", GridColumnType.Money),
        ];
    }

    get gridColumnsVinculos(): Array<GridColumn> {
        return [
            new GridColumn("imposto", "Imposto", GridColumnType.String),
            new GridColumn("cst", "CST", GridColumnType.String),
            new GridColumn("base", "Base", GridColumnType.Money),
            new GridColumn("aliquota", "%", GridColumnType.Decimal),
            new GridColumn("valor", "Valor", GridColumnType.Money),
        ];
    }

    extraActions: Array<object> = [];
    extraActionsConsultar: Array<GridAction> = [];

    // @ts-ignore
    @Watch("modelItem", { deep: true })
    private onChangeItem() {
        this.modelItem["impostos"] = getImpostos(this.modelItem);
    }

    // @ts-ignore
    @Watch("nota.itens", { deep: true })
    private onChangeModel() {
        if (this.consulta || this.emissaoVenda) {
            this.nota.itens.forEach((item, index) => {
                item.numeroItem = index + 1;
                item["impostos"] = getImpostos(item);
            });

            if (this.consulta) {
                this.extraActions.splice(0);
                this.extraActionsConsultar.splice(0);

                for (let i = 0; i < this.nota.itens.length; i++) {
                    this.extraActionsConsultar.push(
                        new GridAction("consultar", "Consultar Nota", "fa fa-search", GridColors.BLUE),
                    );
                }

                this.extraActions.push(this.extraActionsConsultar);
            }
        }
    }

    private async onExtraAction(
        name: string,
        notaFiscalEletronicaEmissaoItemModel: NotaFiscalEletronicaEmissaoItemModel,
    ) {
        if (name.trim() == "consultar") {
            this.showItem = true;
            this.editingModel = true;
            this.indexEdditingModel = this.nota.itens.indexOf(notaFiscalEletronicaEmissaoItemModel);

            if (this.indexEdditingModel >= 0) {
                this.modelItem = this.nota.itens[this.indexEdditingModel];
            }
        }
    }

    private conditionShowSubData(it: NotaFiscalEletronicaEmissaoItemModel) {
        return it["impostos"]["length"] > 0;
    }

    private clear() {
        if (this.emissaoVenda) {
            this.showItem = false;
        }

        this.editingModel = false;
        this.indexEdditingModel = -1;
        this.oldBaseICMS = null;
        this.alterouBaseICMS = false;
        this.oldBaseIPI = null;
        this.alterouBaseIPI = false;
        this.produtoAlteradosCompra = [];

        this.modelItem = new NotaFiscalEletronicaEmissaoItemModel();
    }

    private async onAdicionarItem() {
        this.modelItem.unidadeMedidaSigla = this.getUnidadeMedidaSigla(this.modelItem.unidadeMedidaId);

        await this.$validator.validateAll();

        const message = validateItemNota(this.modelItem, this.nota, this.usuarioLogado.franquia.opcaoSimplesNacional);

        if (message != "") {
            return this.$showWarning("NF-e", message);
        }

        if (this.indexEdditingModel >= 0) {
            this.UPDATE_ITEM({ item: this.modelItem, index: this.indexEdditingModel });
            this.refreshGrid = true;
        } else {
            this.ADD_ITEM_NOTA({
                item: this.modelItem,
                simplesNacional: this.usuarioLogado.franquia.opcaoSimplesNacional,
            });
        }
        this.clear();
    }

    public async getProdutoLoteDados(id: number, item: NotaFiscalEletronicaEmissaoItemModel, isDevolucao = false) {
        item.numeroLote = null;
        item.dataFabricacao = null;
        item.dataValidade = null;

        if (id) {
            const lote = await this.produtoLoteService.getLoteEmissaoNota(id).resolveWithJSON<ProdutoLoteModel>();
            if (isDevolucao) {
                item.numeroLote = lote.descricaoFornecedor ?? lote.descricao;
            } else {
                item.numeroLote = lote.descricao;
            }
            item.dataFabricacao = new Date(lote.dataFabricacao.toString());
            item.dataValidade = new Date(lote.dataValidade.toString());
            return { item, lote };
        }
        return { item };
    }

    public getUnidadeMedidaSigla(id: number): string {
        if (id) {
            return this.unidadeMedidaOptions.filter(p => p["value"] == id)[0]["sigla"];
        }
    }

    public getIdUnidadeMedidaUnidade(): number {
        return Number(this.unidadeMedidaOptions.find(p => p["sigla"].toLocaleUpperCase() == "UN")["value"]);
    }

    private onCancelar() {
        this.clear();
    }

    private async onEditItem(item: NotaFiscalEletronicaEmissaoItemModel) {
        this.showItem = true;
        this.editingModel = true;
        this.refreshGrid = false;

        this.indexEdditingModel = this.nota.itens.indexOf(item);

        if (this.indexEdditingModel < 0) {
            const itensFinded = this.nota.itens.filter(
                p =>
                    p.produtoId == item.produtoId &&
                    p.quantidade == item.quantidade &&
                    p.produtoLoteId == item.produtoLoteId,
            );
            if (itensFinded.length > 0) {
                this.indexEdditingModel = this.nota.itens.indexOf(itensFinded[0]);
            }
        }

        if (this.indexEdditingModel >= 0) {
            if (this.nota.itens[this.indexEdditingModel].produtoId > 0) {
                await this.loadProdutoLotes(this.nota.itens[this.indexEdditingModel].produtoId);
            }

            await Delay(1000);

            this.modelItem = new NotaFiscalEletronicaEmissaoItemModel();
            await Delay(1000);
            this.modelItem.updateFrom(this.nota.itens[this.indexEdditingModel]);

            await Delay(2000);
        }
    }

    private onRemoveItem(item: NotaFiscalEletronicaEmissaoItemModel) {
        const index = this.nota.itens.indexOf(item);
        this.nota.itens.splice(index, 1);

        this.nota.itens.forEach((item, index) => {
            item.numeroItem = index + 1;
        });

        this.SET_NOTA(this.nota);
    }

    // @ts-ignore
    @Watch("modelItem.produtoId")
    private async onProdutoIdChanged(id: number, oldId: number) {
        if (!id || this.consulta) return;

        // Editou produto de item já existente, deve recarregar os dados de tributação
        if (this.editingModel) {
            if (oldId && id != oldId) {
                this.modelItem = await getNewItemByProduto(
                    id,
                    this.nota,
                    this.usuarioLogado.franquia,
                    this.baixaEstoque,
                );
            }
            return;
        }

        await this.loadProdutoLotes(id).withLoading();
        this.modelItem = await getNewItemByProduto(
            id,
            this.nota,
            this.usuarioLogado.franquia,
            this.baixaEstoque,
        ).withLoading();
    }

    // @ts-ignore
    @Watch("modelItem.produtoLoteId")
    private async onProdutoLoteIdChanged(id: number) {
        const { item } = await this.getProdutoLoteDados(id, this.modelItem);
        this.modelItem = item;
    }

    // @ts-ignore
    @Watch("modelItem.ncmId")
    private async onNcm(id: number) {
        if (id) {
            this.modelItem.ncmCodigo = (await this.ncmService.get(id).then(r => r.json() as Promise<NcmModel>)).codigo;
        }
    }

    // @ts-ignore
    @Watch("modelItem.baseICMS")
    private onBaseICMSChange() {
        if (this.modelItem.baseICMS != this.oldBaseICMS && this.oldBaseICMS && this.modelItem.baseICMS) {
            this.alterouBaseICMS = true;
        }
        this.calculaValorImposto("ICMS");
    }

    // @ts-ignore
    @Watch("modelItem.aliquotaICMS")
    private onBaseAliquotaICMSChange() {
        this.calculaValorImposto("ICMS");
    }

    // @ts-ignore
    @Watch("modelItem.cstICMS")
    private onCSTICMSChange() {
        this.modelItem = defineTipoCSTICMS(this.modelItem, this.nota, this.usuarioLogado.franquia.opcaoSimplesNacional);
    }

    // @ts-ignore
    @Watch("modelItem.baseII")
    private onBaseIIChange() {
        this.calculaValorImposto("II");
    }

    // @ts-ignore
    @Watch("modelItem.aliquotaII")
    private onBaseAliquotaIIChange() {
        this.calculaValorImposto("II");
    }

    // @ts-ignore
    @Watch("modelItem.baseIPI")
    private onBaseIPIChange() {
        if (this.modelItem.baseIPI != this.oldBaseIPI && this.oldBaseIPI && this.modelItem.baseIPI) {
            this.alterouBaseIPI = true;
        }
        this.calculaValorImposto("IPI");
    }

    // @ts-ignore
    @Watch("modelItem.aliquotaIPI")
    private onBaseAliquotaIPIChange() {
        this.calculaValorImposto("IPI");
    }

    // @ts-ignore
    @Watch("modelItem.cstIPI")
    private onCSTIPIChange() {
        this.modelItem = defineTipoCSTIPI(this.modelItem);
    }

    // @ts-ignore
    @Watch("modelItem.basePIS")
    private onBasePISChange() {
        this.calculaValorImposto("PIS");
        if (!this.modelItem.baseCOFINS) {
            this.modelItem.baseCOFINS = this.modelItem.basePIS;
        }
    }

    // @ts-ignore
    @Watch("modelItem.aliquotaPIS")
    private onBaseAliquotaPISChange() {
        this.calculaValorImposto("PIS");
    }

    // @ts-ignore
    @Watch("modelItem.cstPIS")
    private onCSTPISChange() {
        if (!this.modelItem.cstCOFINS) {
            this.modelItem.cstCOFINS = this.modelItem.cstPIS;
        }
        this.modelItem = defineTipoCSTPIS(this.modelItem);
    }

    // @ts-ignore
    @Watch("modelItem.baseCOFINS")
    private onBaseCOFINSChange() {
        this.calculaValorImposto("COFINS");
    }

    // @ts-ignore
    @Watch("modelItem.aliquotaCOFINS")
    private onBaseAliquotaCOFINSChange() {
        this.calculaValorImposto("COFINS");
    }

    // @ts-ignore
    @Watch("modelItem.cstCOFINS")
    private onCSTCOFINSChange() {
        this.modelItem = defineTipoCSTCOFINS(this.modelItem);
    }

    // @ts-ignore
    @Watch("baseICMS")
    private onCalculateBaseICMS() {
        if (this.alterouBaseICMS && this["baseICMS"] != this.oldBaseICMS) return;

        this.oldBaseICMS = this["baseICMS"];

        if (this.editingModel && this.modelItem.baseICMS != this["baseICMS"] && this.modelItem.baseICMS) {
            this.alterouBaseICMS = true;
        } else {
            this.oldBaseICMS = this["baseICMS"];
            this.modelItem.baseICMS = this["baseICMS"];
            this.alterouBaseICMS = false;
            this.modelItem = defineTipoCSTICMS(
                this.modelItem,
                this.nota,
                this.usuarioLogado.franquia.opcaoSimplesNacional,
            );
        }
    }

    // @ts-ignore
    @Watch("baseIPI")
    private onCalculateBaseIPI() {
        if (this.alterouBaseIPI && this["baseIPI"] != this.oldBaseIPI) return;

        this.oldBaseIPI = this["baseIPI"];

        if (this.editingModel && this.modelItem.baseIPI != this["baseIPI"] && this.modelItem.baseIPI) {
            this.alterouBaseIPI = true;
        } else {
            this.oldBaseIPI = this["baseIPI"];
            this.modelItem.baseIPI = this["baseIPI"];
            this.alterouBaseIPI = false;
            this.modelItem = defineTipoCSTIPI(this.modelItem);
        }
    }

    // @ts-ignore
    @Watch("baseII")
    private onCalculateBaseII() {
        this.modelItem.baseII = this["baseII"];
    }

    // @ts-ignore
    @Watch("basePIS")
    private onCalculateBasePIS() {
        this.modelItem.basePIS = this["basePIS"];
    }

    // @ts-ignore
    @Watch("baseCOFINS")
    private onCalculateBaseCOFINS() {
        this.modelItem.baseCOFINS = this["baseCOFINS"];
    }

    // @ts-ignore
    @Watch("valorTotal")
    private onCalculateValorTotal() {
        this.modelItem.valorTotal = this["valorTotal"];
        this.calculaBases();
    }

    // @ts-ignore
    @Watch("valorICMSDiferido")
    private onCalculateValorICMSDiferido() {
        this.modelItem.valorICMSDiferido = this["valorICMSDiferido"];
    }

    // @ts-ignore
    @Watch("valorICMSPagar")
    private onCalculateValorICMSPagar() {
        this.modelItem.valorICMSPagar = this["valorICMSPagar"];
    }

    // @ts-ignore
    @Watch("valorICMS")
    private onCalculateValorICMS() {
        this.modelItem.valorICMS = this["valorICMS"];
    }

    // @ts-ignore
    @Watch("baseReduzida")
    private onCalculateBaseReduzida() {
        this.modelItem.baseReduzida = this["baseReduzida"];
    }

    // @ts-ignore
    @Watch("valorUFDestino")
    private onCalculateValorUFDestino() {
        this.modelItem.ICMSUFDestino = this["valorUFDestino"];
    }

    // @ts-ignore
    @Watch("valorUFRemetente")
    private onCalculateValorUFRemetente() {
        this.modelItem.ICMSUFRemetente = this["valorUFRemetente"];
    }

    // @ts-ignore
    @Watch("valorFCPUFDestino")
    private onCalculateValorFCPUFDestino() {
        this.modelItem.ICMSFCPUFDestino = this["valorFCPUFDestino"];
    }

    @Watch("consulta")
    private onChangeConsulta() {
        if (this.consulta && this.showItem) this.showItem = false;
    }

    private calculaValorImposto(imposto: string) {
        this.modelItem[`valor${imposto}`] = arithmeticHelper.round(
            (this.modelItem[`base${imposto}`] * this.modelItem[`aliquota${imposto}`]) / 100,
        );
    }

    private calculaBases() {
        this.modelItem = defineTipoCSTICMS(this.modelItem, this.nota, this.usuarioLogado.franquia.opcaoSimplesNacional);
        this.modelItem = defineTipoCSTIPI(this.modelItem);
        this.modelItem = defineTipoCSTPIS(this.modelItem);
        this.modelItem = defineTipoCSTCOFINS(this.modelItem);
    }

    private async loadProdutos() {
        try {
            const data = await this.produtoService
                .combo()
                .then(r => r.json() as Promise<PaginationModel<ProdutoModel>>);
            this.produtosOptions = data.list.map(getProdutoCombo);
        } catch {}
    }

    private async loadProdutoLotes(produtoId: number) {
        try {
            const data = await this.produtoLoteService
                .listByProdutoIdComQuantidade(produtoId, this.nota.tipoOperacao, 1, 999999)
                .resolveWithJSON<PaginationModel<ProdutoLoteModel>>();
            this.produtoLoteOptions = data.list.map(getProdutoLoteCombo);
        } catch {}
    }

    private loadOrigemMercadoria() {
        this.origemMercadoriaOptions = new OrigemMercadoriaService()
            .get()
            .map(item => ({ value: item.codigo, text: `${item.codigo}-${item.descricao}` }));
    }

    // @ts-ignore
    @Watch("nota.tipoOperacao")
    private async onChangeTipoOperacao() {
        this.loadCSTs();
    }

    private loadCSTs() {
        const service = new SituacaoTributariaService();
        if (this.usuarioLogado.franquia.opcaoSimplesNacional) {
            this.cstICMSOptions = service
                .getCSOSN()
                .map(item => ({ value: Number(item.codigo), text: `${item.codigo}-${item.descricao}` }));
        } else {
            this.cstICMSOptions = service
                .getICMS()
                .map(item => ({ value: Number(item.codigo), text: `${item.codigo}-${item.descricao}` }));
        }

        if (this.nota.tipoOperacao == TipoOperacao.Entrada) {
            this.cstIPIOptions = service
                .getIPI()
                .filter(item => Number(item.codigo) < 50)
                .map(item => ({ value: Number(item.codigo), text: `${item.codigo}-${item.descricao}` }));
            this.cstPISCOFINSOptions = service
                .getPISCOFINS()
                .filter(item => Number(item.codigo) >= 50)
                .map(item => ({ value: Number(item.codigo), text: `${item.codigo}-${item.descricao}` }));
        } else {
            this.cstIPIOptions = service
                .getIPI()
                .filter(item => Number(item.codigo) >= 50)
                .map(item => ({ value: Number(item.codigo), text: `${item.codigo}-${item.descricao}` }));
            this.cstPISCOFINSOptions = service
                .getPISCOFINS()
                .filter(item => Number(item.codigo) < 50)
                .map(item => ({ value: Number(item.codigo), text: `${item.codigo}-${item.descricao}` }));
        }
    }

    private async openNewProdutoLote() {
        this.produtoLoteComponent = this.$refs.produtoLoteComponent as ProdutoLoteEditComponent;
        this.produtoLoteComponent.modelId = null;
        this.produtoLoteComponent.load();
    }

    private async onAddProdutoLote(closeModal) {
        if (await this.produtoLoteComponent.save()) {
            closeModal();
        }
    }

    private async openComboEditProdutoLote(id: number) {
        this.produtoLoteComponent = this.$refs.produtoLoteComponent as ProdutoLoteEditComponent;
        this.produtoLoteComponent.modelId = id;
        this.produtoLoteComponent.load();
    }

    private async onProdutoLoteSaveOk() {
        await this.loadProdutoLotes(this.modelItem.produtoId);
    }

    private async getPreLoadPack() {
        while (!this.loadedList) await Delay(100);

        this.produtosOptions = this.preLoadPack.produtosCombo();
        this.unidadeMedidaOptions = this.preLoadPack.unidadeMedidaCombo();
    }

    private mounted() {
        this.produtoLoteComponent = this.$refs.produtoLoteComponent as ProdutoLoteEditComponent;

        Promise.all([this.getPreLoadPack(), this.loadOrigemMercadoria()]).then(() => {
            this.showItem = !this.consulta;
            this.loadCSTs();
        });
    }
}
