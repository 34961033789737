import TelaModel from "../models/telaModel";
import nfetch from "../utils/events/nfetch";

import { IService } from "./base/iService";

export default class TelaService extends IService<TelaModel> {
    constructor() {
        super("Tela");
    }
    
    public getMenu(): Promise<Response> {
        return nfetch(`/${this.controllerName}/GetMenu`, {
            credentials: "same-origin"
        });
    }
}