import Vue from "vue";

import gridComponent from "@/components/child/grid/grid.vue";
import { Component, Prop } from "@/decorators";
import LogEntidadeModel from "@/models/logEntidadeModel";
import LogEntidadeService from "@/services/logEntidadeService";

import { GridColumn, GridColumnType } from "../grid/gridColumn";

@Component({
    components: {
        gridComponent,
    },
})
export default class LogEntidadeComponent extends Vue {
    @Prop({ type: Number, default: null }) registroId: number;
    @Prop({ type: Number, default: null }) entidade: number;
    @Prop({ type: Boolean, default: false }) withoutFranquiaId: boolean;

    data: LogEntidadeModel[] = [];

    private logEntidadeService = new LogEntidadeService();

    private get gridColumns(): Array<GridColumn> {
        return [
            new GridColumn("dataCriacao", this.$t("__.ts.data"), GridColumnType.DateTime),
            new GridColumn("usuarioCriacaoNome", this.$t("__.ts.usuario"), GridColumnType.String),
            new GridColumn("descricao", this.$t("__.ts.descricao"), GridColumnType.String),
        ];
    }

    private async load() {
        if (!this.withoutFranquiaId) {
            this.data = await this.logEntidadeService
                .getList(this.registroId, this.entidade)
                .withLoading()
                .resolveWithJSON<LogEntidadeModel[]>();
        } else {
            this.data = await this.logEntidadeService
                .getListWithoutFranquiaId(this.registroId, this.entidade)
                .withLoading()
                .resolveWithJSON<LogEntidadeModel[]>();
        }
    }

    public async show(registroId: number, entidade: number) {
        this.registroId = registroId;
        this.entidade = entidade;
        await this.load();
    }

    private async mounted() {
        if (this.registroId > 0 && this.entidade > 0) {
            await this.load();
        }
    }
}
