import BaseModel from "./base/baseModel";

export class Adicao {
    numeroAdicao: number;
    codigoFabricanteEstrangeiro: string;
    desconto?: number;
}

export enum TipoCST {
    TRIBUTADO = 1,
    NAOTRIBUTADO = 2,
    ALIQUOTAZERO = 3,
}

export default class NotaFiscalEletronicaEmissaoItemModel extends BaseModel {
    numeroItem = 0;
    produtoId = 0;
    produtoDescricao: string = null;
    informacaoAdicional: string = null;
    codigoBeneficio: string = null;
    quantidade = 0;
    unidadeMedidaId = 0;
    unidadeMedidaSigla: string = null;
    EAN = 0;
    ncmId = 0;
    ncmCodigo: string = null;
    valorUnitario = 0;
    valorDesconto = 0;
    valorFrete = 0;
    valorSeguro = 0;
    valorOutraDespesa = 0;
    valorTotal = 0;

    tipoCSTICMS: TipoCST = TipoCST.NAOTRIBUTADO;
    baseICMS = 0;
    aliquotaICMS = 0;
    valorICMS = 0;
    origemMercadoria = 0;
    percentualDiferimento = 0;
    valorICMSDiferido = 0;
    valorICMSPagar = 0;
    percentualReducaoBase = 0;
    baseReduzida = 0;
    cstICMS = 0;

    comICMSPartilha = false;
    baseUFDestino = 0;
    baseFCPUFDestino = 0;
    aliquotaFCPUFDestino = 0;
    aliquotaUFDestino = 0;
    aliquotaInterestadual = 0;
    aliquotaInterestadualPartilha = 0;
    ICMSFCPUFDestino = 0;
    ICMSUFDestino = 0;
    ICMSUFRemetente = 0;

    baseSTRetido = 0;
    aliquotaSTComFCP = 0;
    valorSTRetido = 0;
    baseFCPSTRetido = 0;
    aliquotaFCPSTRetido = 0;
    valorFCPSTRetido = 0;
    baseICMSEfetivo = 0;
    aliquotaICMSEfetivo = 0;
    valorICMSEfetivo = 0;
    percentualReducaoICMSEfetivo = 0;

    baseII = 0;
    aliquotaII = 0;
    valorDespesasAduaneiras = 0;
    valorII = 0;
    valorIOF = 0;

    tipoCSTIPI: TipoCST = TipoCST.NAOTRIBUTADO;
    sujeitoIPI = false;
    baseIPI = 0;
    aliquotaIPI = 0;
    valorIPI = 0;
    cstIPI = 0;
    codigoEnquadramento = 999;
    percentualDevolvido = 0;
    valorIPIDevolvido = 0;

    tipoCSTPIS: TipoCST = TipoCST.NAOTRIBUTADO;
    basePIS = 0;
    aliquotaPIS = 0;
    valorPIS = 0;
    cstPIS = 0;

    tipoCSTCOFINS: TipoCST = TipoCST.NAOTRIBUTADO;
    baseCOFINS = 0;
    aliquotaCOFINS = 0;
    valorCOFINS = 0;
    cstCOFINS = 0;

    percentualCreditoSimplesNacional = 0;
    valorCreditoSimplesNacional = 0;

    numeroDI: number = null;
    dataDI: Date = new Date();
    codigoExportador: number = null;
    localDesembaraco: string = null;
    ufDesembaraco: string = null;
    ufDesembaracoId: number = null;
    dataDesembaraco: Date = new Date();

    viaTransporte = 6;
    valorAFRMM = 0;
    tipoIntermedio = 1;
    adicoes: Array<Adicao> = [];

    produtoLoteId: number = null;
    numeroLote: string = null;
    dataValidade: Date = null;
    dataFabricacao: Date = null;

    valorDecontoAntesRateio = 0;
    valorFreteAntesRateio = 0;

    codigoAnvisa: string = null;
    precoMaximoConsumidor: number = null;
}
