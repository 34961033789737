var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("form", { attrs: { novalidate: "novalidate" } }, [
    _c("div", { staticClass: "col-sm-12" }, [
      _c(
        "div",
        {
          directives: [
            {
              name: "tooltip",
              rawName: "v-tooltip",
              value: _vm.errors.first("etapa"),
              expression: "errors.first('etapa')",
            },
          ],
          staticClass: "form-group",
        },
        [
          _c(
            "label",
            {
              staticClass: "control-label",
              attrs: { for: "etapa", title: _vm.$t("__.obrigatorio") },
            },
            [
              _c("span", { staticStyle: { color: "red" } }, [_vm._v("*")]),
              _vm._v(
                "\n                " +
                  _vm._s(
                    _vm.$t("__.Components.parent.statusPCP.view_vue_html.etapa")
                  ) +
                  "\n            "
              ),
            ]
          ),
          _vm._v(" "),
          _c("combo-component", {
            directives: [
              {
                name: "validate",
                rawName: "v-validate",
                value: "required",
                expression: "'required'",
              },
            ],
            attrs: {
              id: "etapa",
              name: "etapa",
              data: _vm.etapasOptions,
              searchable: true,
            },
            model: {
              value: _vm.etapaDestinoId,
              callback: function ($$v) {
                _vm.etapaDestinoId = $$v
              },
              expression: "etapaDestinoId",
            },
          }),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-sm-12" }, [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-sm-11" }, [
          _c(
            "div",
            {
              directives: [
                {
                  name: "tooltip",
                  rawName: "v-tooltip",
                  value: _vm.errors.first("codigo"),
                  expression: "errors.first('codigo')",
                },
              ],
              staticClass: "form-group",
            },
            [
              _c(
                "label",
                {
                  staticClass: "control-label",
                  attrs: { for: "codigo", title: _vm.$t("__.obrigatorio") },
                },
                [
                  _c("span", { staticStyle: { color: "red" } }, [_vm._v("*")]),
                  _vm._v(
                    "\n                        " +
                      _vm._s(
                        _vm.$t(
                          "__.Components.parent.statusPCP.view_vue_html.codigo"
                        )
                      ) +
                      "\n                    "
                  ),
                ]
              ),
              _vm._v(" "),
              _c("input", {
                directives: [
                  { name: "focus", rawName: "v-focus" },
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.codigo,
                    expression: "codigo",
                  },
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required",
                    expression: "'required'",
                  },
                ],
                staticClass: "form-control",
                attrs: {
                  autocomplete: "off",
                  type: "text",
                  id: "codigo",
                  name: "codigo",
                },
                domProps: { value: _vm.codigo },
                on: {
                  keydown: function ($event) {
                    if (
                      !("button" in $event) &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    $event.preventDefault()
                    _vm.onSubmitEtapaPcp()
                  },
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.codigo = $event.target.value
                  },
                },
              }),
            ]
          ),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col-sm-1" },
          [
            _c("qrCodeReaderComponent", {
              on: { onReadQrCode: _vm.onReadQrCode },
            }),
          ],
          1
        ),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-sm-12" }, [
      _c("div", { staticClass: "row" }, [
        _vm.etapaDestinoId != null && _vm.isMotivoObrigatorio
          ? _c("div", { staticClass: "col-sm-12" }, [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "tooltip",
                      rawName: "v-tooltip",
                      value: _vm.errors.first("motivoEtapaPCP"),
                      expression: "errors.first('motivoEtapaPCP')",
                    },
                  ],
                  staticClass: "form-group",
                },
                [
                  _c(
                    "label",
                    {
                      staticClass: "control-label",
                      attrs: {
                        for: "motivoEtapaPCP",
                        title: _vm.$t("__.obrigatorio"),
                      },
                    },
                    [
                      _c("span", { staticStyle: { color: "red" } }, [
                        _vm._v("*"),
                      ]),
                      _vm._v(
                        "\n                        " +
                          _vm._s(_vm.$t("__.Crud.pcp.etapas.motivoEtapaPCP")) +
                          "\n                    "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("combo-component", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'",
                      },
                    ],
                    attrs: {
                      id: "motivoEtapaPCP",
                      name: "motivoEtapaPCP",
                      data: _vm.motivosEtapaOptions,
                      searchable: true,
                      refresh: _vm.loadMotivosEtapas,
                    },
                    model: {
                      value: _vm.motivoEtapaPCPId,
                      callback: function ($$v) {
                        _vm.motivoEtapaPCPId = $$v
                      },
                      expression: "motivoEtapaPCPId",
                    },
                  }),
                ],
                1
              ),
            ])
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "btn btn-primary atualizacao-card",
          staticStyle: { "margin-bottom": "12px" },
          attrs: { type: "button" },
          on: {
            click: function ($event) {
              _vm.onSubmitEtapaPcp()
            },
          },
        },
        [
          _vm._v(
            "\n            " + _vm._s(_vm.$t("__.Crud.salvar")) + "\n        "
          ),
        ]
      ),
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.status == 1,
            expression: "status == 1",
          },
        ],
        staticClass: "col-sm-12",
      },
      [
        _c(
          "div",
          { staticClass: "alert alert-success", attrs: { role: "alert" } },
          [
            _vm._v(
              "\n            " +
                _vm._s(_vm.$t("__.ts.registroSalvSucess")) +
                "\n        "
            ),
          ]
        ),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.status == 2,
            expression: "status == 2",
          },
        ],
        staticClass: "col-sm-12",
      },
      [
        _c(
          "div",
          { staticClass: "alert alert-danger", attrs: { role: "alert" } },
          [
            _vm._v(
              "\n            " +
                _vm._s(_vm.$t("__.ts.ocorrErroAoInclRegis")) +
                "\n        "
            ),
          ]
        ),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.status == 3,
            expression: "status == 3",
          },
        ],
        staticClass: "col-sm-12",
      },
      [
        _c(
          "div",
          { staticClass: "alert alert-danger", attrs: { role: "alert" } },
          [
            _vm._v(
              "\n            " +
                _vm._s(_vm.$t("__.ts.motivoObrigatorioEtapaPcp")) +
                "\n        "
            ),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }