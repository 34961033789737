var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "crud crud-edit" },
    [
      _c(
        "shortcutComponent",
        { ref: "shortcutComponent", on: { "selection-item": _vm.onConfirm } },
        [
          _c("div", { attrs: { slot: "component" }, slot: "component" }, [
            _c(
              "div",
              { staticClass: "row" },
              [
                _c("grid-component", {
                  attrs: {
                    data: _vm.gridData,
                    columns: _vm.gridColumns,
                    showTableHead: false,
                    showEditItem: false,
                    showActionColumn: false,
                    showAddNewButton: false,
                    "show-select-checkbox": true,
                  },
                  on: {
                    "change-sort": _vm.onChangeSort,
                    "selected-values-changed": _vm.onSelectedValuesChanged,
                  },
                }),
              ],
              1
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }