import BaseModel from "../base/baseModel";

export default class LocalEntregaModel extends BaseModel {
    id?: number = null;
    codigo: number = null;
    descricao = "";
    taxa = 0;
    franquiaId?: number = null;
    transportadoraId?: number = null;
}
