import BaseModel from "./base/baseModel";

export default class VolumeModel extends BaseModel {
    id?: number = null;
    quantidade: number;
    especie: string;
    marca: string;
    numeracao: string;
    pesoLiquido: number;
    pesoBruto: number;
}