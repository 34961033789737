import Vue from "vue";

import { Component, Prop } from "@/decorators";

@Component
export default class MoedaComponent extends Vue {
    @Prop(String) id: string;
    @Prop(String) name: string;
    @Prop(Number) value: number;

    private onBlur(value) {
        this.$emit("input", this.formatValueToModel(value));
    }

    private onChange(value) {
        this.$emit("change", this.formatValueToModel(value));
    }

    private formatValueToModel(value) {
        return +value.replace("R$ ", "").replace(/\./g, "").replace(",", ".");
    }
}
