var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "crud crud-edit" }, [
    _c(
      "form",
      { attrs: { novalidate: "novalidate" } },
      [
        _c(
          "fieldset-component",
          {
            attrs: {
              title: _vm.$t("__.Crud.produto.verProduto_vue_html.dadosGerais"),
              collapsed: false,
            },
          },
          [
            _c("div", { attrs: { slot: "rows" }, slot: "rows" }, [
              _c(
                "div",
                {
                  staticClass: "row",
                  staticStyle: { "padding-left": "15px !important" },
                },
                [
                  _c("div", { staticClass: "col-sm-12" }, [
                    _c(
                      "table",
                      {
                        staticClass: "table table-striped ajusteVer2",
                        attrs: { id: "t1" },
                      },
                      [
                        _c("tbody", [
                          _c("tr", [
                            _c("th", { attrs: { scope: "row" } }, [
                              _c("h4", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "__.Crud.produto.verProduto_vue_html.descricao"
                                    )
                                  )
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("td", [
                              _c("h4", [_vm._v(_vm._s(_vm.model.descricao))]),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("tr", [
                            _c("th", { attrs: { scope: "row" } }, [
                              _c("h4", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "__.Crud.produto.verProduto_vue_html.descrRotulo"
                                    )
                                  )
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("td", [
                              _c("h4", [
                                _vm._v(_vm._s(_vm.model.descricaoRotulo)),
                              ]),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("tr", [
                            _c("th", { attrs: { scope: "row" } }, [
                              _c("h4", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "__.Crud.produto.verProduto_vue_html.unMedManipu"
                                    )
                                  )
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("td", [
                              _c("h4", [
                                _vm._v(_vm._s(_vm.unidadeManipulacaoDescricao)),
                              ]),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("tr", [
                            _c("th", { attrs: { scope: "row" } }, [
                              _c("h4", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "__.Crud.produto.verProduto_vue_html.unMedEstq"
                                    )
                                  )
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("td", [
                              _c("h4", [
                                _vm._v(_vm._s(_vm.unidadeEstoqueDescricao)),
                              ]),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("tr", [
                            _c("th", { attrs: { scope: "row" } }, [
                              _c("h4", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "__.Crud.produto.verProduto_vue_html.controlaEstq"
                                    )
                                  )
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("td", [
                              _c("h4", [
                                _vm._v(
                                  _vm._s(
                                    _vm.model.ignoraControleEstoque
                                      ? "Não"
                                      : "Sim"
                                  )
                                ),
                              ]),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("tr", [
                            _c("th", { attrs: { scope: "row" } }, [
                              _c("h4", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "__.Crud.produto.verProduto_vue_html.estqMin"
                                    )
                                  )
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("td", [
                              _c("h4", [
                                _vm._v(_vm._s(_vm.model.estoqueMinimo)),
                              ]),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("tr", [
                            _c("th", { attrs: { scope: "row" } }, [
                              _c("h4", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "__.Crud.produto.verProduto_vue_html.obs"
                                    )
                                  )
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("td", [
                              _c("h4", [_vm._v(_vm._s(_vm.model.observacao))]),
                            ]),
                          ]),
                        ]),
                      ]
                    ),
                  ]),
                ]
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _vm.showFinanceiro
          ? _c(
              "fieldset-component",
              {
                attrs: {
                  title: _vm.$t(
                    "__.Crud.produto.verProduto_vue_html.financeiro"
                  ),
                },
              },
              [
                _c("div", { attrs: { slot: "rows" }, slot: "rows" }, [
                  _c(
                    "div",
                    {
                      staticClass: "row",
                      staticStyle: { "padding-left": "15px !important" },
                    },
                    [
                      _c("div", { staticClass: "col-sm-12" }, [
                        _c(
                          "table",
                          {
                            staticClass: "table table-striped ajusteVer2",
                            attrs: { id: "t2" },
                          },
                          [
                            _c("tbody", [
                              _c("tr", [
                                _c("th", { attrs: { scope: "row" } }, [
                                  _c("h4", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "__.Crud.produto.verProduto_vue_html.vlrCusto"
                                        )
                                      )
                                    ),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c("td", [
                                  _c("h4", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("filterMoeda")(
                                          _vm.model.valorCusto
                                        )
                                      )
                                    ),
                                  ]),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c("tr", [
                                _c("th", { attrs: { scope: "row" } }, [
                                  _c("h4", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "__.Crud.produto.verProduto_vue_html.custoRefe"
                                        )
                                      )
                                    ),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c("td", [
                                  _c("h4", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("filterMoeda")(
                                          _vm.model.custoReferencia
                                        )
                                      )
                                    ),
                                  ]),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c("tr", [
                                _c("th", { attrs: { scope: "row" } }, [
                                  _c("h4", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "__.Crud.produto.verProduto_vue_html.custoMedio"
                                        )
                                      )
                                    ),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c("td", [
                                  _c("h4", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("filterMoeda")(
                                          _vm.model.custoMedio
                                        )
                                      )
                                    ),
                                  ]),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c("tr", [
                                _c("th", { attrs: { scope: "row" } }, [
                                  _c("h4", [_vm._v("Markup (%):")]),
                                ]),
                                _vm._v(" "),
                                _c("td", [
                                  _c("h4", [
                                    _vm._v(_vm._s(_vm.model.markup) + "%"),
                                  ]),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c("tr", [
                                _c("th", { attrs: { scope: "row" } }, [
                                  _c("h4", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "__.Crud.produto.verProduto_vue_html.vlrVenda"
                                        )
                                      )
                                    ),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c("td", [
                                  _c("h4", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("filterMoeda")(
                                          _vm.model.valorVenda
                                        )
                                      )
                                    ),
                                  ]),
                                ]),
                              ]),
                            ]),
                          ]
                        ),
                      ]),
                    ]
                  ),
                ]),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "fieldset-component",
          {
            attrs: {
              title: _vm.$t("__.Crud.produto.verProduto_vue_html.manipulacao"),
            },
          },
          [
            _c("div", { attrs: { slot: "rows" }, slot: "rows" }, [
              _c(
                "div",
                {
                  staticClass: "row",
                  staticStyle: { "padding-left": "15px !important" },
                },
                [
                  _c("div", { staticClass: "col-sm-12" }, [
                    _c(
                      "table",
                      {
                        staticClass: "table table-striped ajusteVer2",
                        attrs: { id: "t3" },
                      },
                      [
                        _c("tbody", [
                          _c("tr", [
                            _c("th", { attrs: { scope: "row" } }, [
                              _c("h4", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "__.Crud.produto.verProduto_vue_html.densidadeGml"
                                    )
                                  )
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("td", [
                              _c("h4", [_vm._v(_vm._s(_vm.model.densidade))]),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("tr", [
                            _c("th", { attrs: { scope: "row" } }, [
                              _c("h4", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "__.Crud.produto.verProduto_vue_html.ftrCorrec"
                                    )
                                  )
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("td", [
                              _c("h4", [
                                _vm._v(_vm._s(_vm.model.fatorCorrecao)),
                              ]),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("tr", [
                            _c("th", { attrs: { scope: "row" } }, [
                              _c("h4", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "__.Crud.produto.edit_vue_html.fatorEquivalencia"
                                    )
                                  )
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("td", [
                              _c("h4", [
                                _vm._v(_vm._s(_vm.model.fatorEquivalencia)),
                              ]),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("tr", [
                            _c("th", { attrs: { scope: "row" } }, [
                              _c("h4", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "__.Crud.produto.verProduto_vue_html.ftrUI"
                                    )
                                  )
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("td", [
                              _c("h4", [_vm._v(_vm._s(_vm.model.fatorUI))]),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("tr", [
                            _c("th", { attrs: { scope: "row" } }, [
                              _c("h4", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "__.Crud.produto.verProduto_vue_html.ftrUFC"
                                    )
                                  )
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("td", [
                              _c("h4", [_vm._v(_vm._s(_vm.model.fatorUFC))]),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("tr", [
                            _c("th", { attrs: { scope: "row" } }, [
                              _c("h4", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "__.Crud.produto.verProduto_vue_html.ftrUTR"
                                    )
                                  )
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("td", [
                              _c("h4", [_vm._v(_vm._s(_vm.model.fatorUTR))]),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("tr", [
                            _c("th", { attrs: { scope: "row" } }, [
                              _c("h4", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "__.Crud.produto.verProduto_vue_html.doseMax"
                                    )
                                  )
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("td", [
                              _c("h4", [_vm._v(_vm._s(_vm.model.doseMaxima))]),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("tr", [
                            _c("th", { attrs: { scope: "row" } }, [
                              _c("h4", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "__.Crud.produto.verProduto_vue_html.doseMaxDia"
                                    )
                                  )
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("td", [
                              _c("h4", [
                                _vm._v(_vm._s(_vm.model.doseMaximaDiaria)),
                              ]),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("tr", [
                            _c("th", { attrs: { scope: "row" } }, [
                              _c("h4", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "__.Crud.produto.verProduto_vue_html.doseMaxPorcent"
                                    )
                                  )
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("td", [
                              _c("h4", [
                                _vm._v(_vm._s(_vm.model.doseMaximaPorcentagem)),
                              ]),
                            ]),
                          ]),
                        ]),
                      ]
                    ),
                  ]),
                ]
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "fieldset-component",
          {
            attrs: {
              title: _vm.$t("__.Crud.produto.verProduto_vue_html.embalagem"),
            },
          },
          [
            _c("div", { attrs: { slot: "rows" }, slot: "rows" }, [
              _c(
                "div",
                {
                  staticClass: "row",
                  staticStyle: { "padding-left": "15px !important" },
                },
                [
                  _c("div", { staticClass: "col-sm-12" }, [
                    _c(
                      "table",
                      {
                        staticClass: "table table-striped ajusteVer2",
                        attrs: { id: "t4" },
                      },
                      [
                        _c("tbody", [
                          _c("tr", [
                            _c("th", { attrs: { scope: "row" } }, [
                              _c("h4", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "__.Crud.produto.verProduto_vue_html.volMaxMl"
                                    )
                                  )
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("td", [
                              _c("h4", [
                                _vm._v(_vm._s(_vm.model.volumeMaximo)),
                              ]),
                            ]),
                          ]),
                        ]),
                      ]
                    ),
                  ]),
                ]
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "fieldset-component",
          {
            attrs: {
              title: _vm.$t("__.Crud.produto.verProduto_vue_html.associacoes"),
            },
          },
          [
            _c(
              "div",
              { attrs: { slot: "rows" }, slot: "rows" },
              [
                _c("grid-component", {
                  attrs: {
                    data: _vm.model.produtoAssociacoes,
                    columns: _vm.gridColumnsProdutoAssociacoes,
                    "show-table-head": false,
                    "show-table-foot": false,
                    "show-edit-item": false,
                    showActionColumn: false,
                  },
                }),
              ],
              1
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "fieldset-component",
          {
            attrs: {
              title: _vm.$t("__.Crud.produto.verProduto_vue_html.sinonimos"),
            },
          },
          [
            _c(
              "div",
              { attrs: { slot: "rows" }, slot: "rows" },
              [
                _c("grid-component", {
                  attrs: {
                    data: _vm.model.produtoSinonimos,
                    columns: _vm.gridColumnsProdutoSinonimos,
                    "show-table-head": false,
                    "show-table-foot": false,
                    "show-edit-item": false,
                    showActionColumn: false,
                  },
                }),
              ],
              1
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "fieldset-component",
          {
            attrs: {
              title: _vm.$t(
                "__.Crud.produto.verProduto_vue_html.incompatibilidades"
              ),
            },
          },
          [
            _c(
              "div",
              { attrs: { slot: "rows" }, slot: "rows" },
              [
                _c("grid-component", {
                  attrs: {
                    data: _vm.model.produtosIncompativeis,
                    columns: _vm.gridColumnsProdutosIncompativeis,
                    "show-table-head": false,
                    "show-table-foot": false,
                    "show-edit-item": false,
                    showActionColumn: false,
                  },
                }),
              ],
              1
            ),
          ]
        ),
        _vm._v(" "),
        _vm.modelId > 0
          ? _c(
              "fieldset-component",
              { attrs: { title: _vm.$t("__.ts.lote") } },
              [
                _c(
                  "div",
                  { attrs: { slot: "rows" }, slot: "rows" },
                  [
                    _c("grid-component", {
                      attrs: {
                        data: _vm.gridDataLote,
                        columns: _vm.gridColumnLote,
                        showEditItem: false,
                        showRemoveItem: false,
                        showActionColumn: true,
                        showAddNewButton: false,
                        showTableFoot: false,
                        showTools: false,
                        showTableHead: false,
                        extraActions: _vm.extraActions,
                      },
                      on: { "extra-action": _vm.onExtraAction },
                    }),
                    _vm._v(" "),
                    _c("h4", { staticClass: "total-lote" }, [
                      _vm._v(
                        "\n                    " +
                          _vm._s(
                            _vm.$t(
                              "__.Crud.produto.verProduto_vue_html.qtdTotal"
                            )
                          ) +
                          " " +
                          _vm._s(_vm._f("filterNumber")(_vm.totalLote)) +
                          "\n                    " +
                          _vm._s(_vm.getSiglaEstoque()) +
                          "\n                "
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "shortcutComponent",
                      {
                        ref: "shortcutComponent",
                        attrs: {
                          modalClass: "md-modal",
                          title: _vm.$t(
                            "__.Crud.produto.verProduto_vue_html.visuLote"
                          ),
                          showBtnOk: false,
                          showAddNew: false,
                        },
                      },
                      [
                        _c(
                          "div",
                          { attrs: { slot: "component" }, slot: "component" },
                          [
                            _vm.loadLote
                              ? _c("ver-lote-component", {
                                  staticStyle: { "margin-left": "30px" },
                                  attrs: { loteId: _vm.loteId },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]
            )
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }