import UnidadeMedidaModel from "../models/unidadeMedidaModel";
import nfetch from "../utils/events/nfetch";

import { IService } from "./base/iService";

export default class UnidadeMedidaService extends IService<UnidadeMedidaModel> {
    constructor() {
        super("UnidadeMedida");
    }

    public converterUnidadeMedida(
        unidadeMedidaOrigemId: number,
        unidadeMedidaDestinoId: number,
        densidade = 1,
        produtoId = 0,
        produtoLoteId = 0,
    ): Promise<Response> {
        return nfetch(
            `/${this.controllerName}/ConverterUnidadeMedida?unidadeMedidaOrigemId=${unidadeMedidaOrigemId}&unidadeMedidaDestinoId=${unidadeMedidaDestinoId}&densidade=${densidade}&produtoId=${produtoId}&produtoLoteId=${produtoLoteId}`,
            {
                credentials: "same-origin",
            },
        );
    }

    public getUnidadeMedidabySiglas(unidadeMedidaSigla: string): Promise<Response> {
        return nfetch(`/${this.controllerName}/GetUnidadeMedidabySiglas?sigla=${unidadeMedidaSigla}`, {
            credentials: "same-origin",
        });
    }
}
