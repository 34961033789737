import SepararValoresPor from "../../models/enum/separarValoresPor";
import ContaPagarModel from "../../models/financeiro/contaPagarModel";
import ContaPagarPagamentoModel from "../../models/financeiro/contaPagarPagamentoModel";
import LiquidacaoModel from "../../models/financeiro/pagamentoContasModel";
import nfetch from "../../utils/events/nfetch";

import { IService } from "./../base/iService";

export default class ContaPagarService extends IService<ContaPagarModel> {
    constructor() {
        super("ContaPagar");
    }

    public getMovimentacoesByDate(
        separarPor: SepararValoresPor,
        dataInicial: Date,
        dataFinal: Date,
    ): Promise<Response> {
        return nfetch(
            `/${
                this.controllerName
            }/GetMovimentacoesByDate?separarPor=${separarPor}&dataInicial=${dataInicial.toJSON()}&dataFinal=${dataFinal.toJSON()}`,
            {
                credentials: "same-origin",
            },
        );
    }
    public LiquidarWithModel(model: LiquidacaoModel): Promise<Response> {
        //@ts-ignore
        return nfetch(`/${this.controllerName}/LiquidarWithModel`, {
            method: "post",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json; charset=UTF-8",
                "XSRF-TOKEN": this.getCookie("XSRF-TOKEN"),
            },
            body: JSON.stringify(model),
        })
            .then((response: Response) => {
                if (response.ok) {
                    return response;
                } else {
                    return this.mensagemErro(response, "Liquidado");
                }
            })
            .catch(error => {});
    }
    public LiquidarPorPagamentos(model: Array<ContaPagarPagamentoModel>): Promise<Response> {
        //@ts-ignore
        return nfetch(`/${this.controllerName}/LiquidarPorPagamentos`, {
            method: "post",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json; charset=UTF-8",
                "XSRF-TOKEN": this.getCookie("XSRF-TOKEN"),
            },
            body: JSON.stringify(model),
        })
            .then((response: Response) => {
                if (response.ok) {
                    return response;
                } else {
                    return this.mensagemErro(response, "Liquidado");
                }
            })
            .catch(error => {});
    }
    public ExcluirPagamentoWithModel(model: ContaPagarPagamentoModel): Promise<Response> {
        //@ts-ignore
        return nfetch(`/${this.controllerName}/ExcluirPagamentoWithModel`, {
            method: "post",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json; charset=UTF-8",
                "XSRF-TOKEN": this.getCookie("XSRF-TOKEN"),
            },
            body: JSON.stringify(model),
        })
            .then((response: Response) => {
                if (response.ok) {
                    return response;
                } else {
                    return this.mensagemErro(response, "Excluindo Pagamento");
                }
            })
            .catch(error => {});
    }
}
