var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "shortcutComponent",
    {
      ref: "shortcutComponent",
      attrs: { showAddNew: false, modalClass: "ss-modal" },
      on: { "selection-item": _vm.save, "hide-click": _vm.hide },
    },
    [
      _c("div", { attrs: { slot: "component" }, slot: "component" }, [
        _c(
          "form",
          {
            on: {
              submit: function ($event) {
                $event.preventDefault()
                return _vm.save($event)
              },
            },
          },
          [
            _c("div", { attrs: { slot: "rows" }, slot: "rows" }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-sm-12" }, [
                  _c(
                    "div",
                    {
                      staticClass: "alert alert-warning",
                      attrs: { role: "alert" },
                    },
                    [_vm._v(_vm._s(_vm.title))]
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-sm-12" }, [
                  _c("div", { staticClass: "form-group" }, [
                    _c(
                      "label",
                      {
                        staticClass: "control-label",
                        attrs: {
                          for: "senhaUsuarioAdmin",
                          title: _vm.$t("__.obrigatorio"),
                        },
                      },
                      [
                        _c("span", { staticClass: "color-red" }, [_vm._v("*")]),
                        _vm._v(
                          "\n                                Senha do Administrador\n                            "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.senhaUsuarioAdmin,
                          expression: "senhaUsuarioAdmin",
                        },
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required",
                          expression: "'required'",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: {
                        autofocus: "",
                        autocomplete: "off",
                        type: "password",
                        id: "senhaUsuarioAdmin",
                        name: "senhaUsuarioAdmin",
                      },
                      domProps: { value: _vm.senhaUsuarioAdmin },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.senhaUsuarioAdmin = $event.target.value
                        },
                      },
                    }),
                  ]),
                ]),
              ]),
            ]),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }