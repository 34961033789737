import Vue from "vue";
import { mapGetters, mapMutations, mapState } from "vuex";

import actionBarComponent from "@/components/child/actionBar/actionBar.vue";
import checkboxComponent from "@/components/child/form/checkbox.vue";
import comboComponent from "@/components/child/form/combo.vue";
import dataTooltipComponent from "@/components/child/form/datatooltip.vue";
import fieldsetComponent from "@/components/child/form/fieldset.vue";
import moedaComponent from "@/components/child/form/moeda.vue";
import logEntidadeComponent from "@/components/child/logEntidade/logEntidade.vue";
import RascunhoComponent from "@/components/child/rascunho/rascunho";
import rascunhoComponent from "@/components/child/rascunho/rascunho.vue";
import searchComboComponent from "@/components/child/searchCombo/search.vue";
import { Component, Prop, Watch } from "@/decorators";
import PreLoadPackModel from "@/models/auxiliar/preLoadPackModel";
import ClienteModel from "@/models/clienteModel";
import ConfiguracaoFranquiaModel from "@/models/configuracaoFranquia/configuracaoFranquiaModel";
import Configuracoes from "@/models/enum/configuracao/configuracoes";
import OrigemProdutoLote from "@/models/enum/origemProdutoLote";
import StatusEnvioNF from "@/models/enum/statusEnvioNF";
import TipoCadastro from "@/models/enum/tipoCadastro";
import TipoOperacao from "@/models/enum/tipoOperacao";
import TiposItemManipulacao from "@/models/enum/tiposItemManipulacao";
import TiposMovimentacao from "@/models/enum/tiposMovimentacao";
import TipoVenda from "@/models/enum/tipoVenda";
import FornecedorModel from "@/models/fornecedorModel";
import ManipulacaoOrdemModel from "@/models/manipulacaoOrdemModel";
import NaturezaOperacaoModel from "@/models/naturezaOperacaoModel";
import NotaFiscalEletronicaEmissaoItemModel from "@/models/notaFiscalEletronicaEmissaoItemModel";
import NotaFiscalEletronicaEmissaoModel, { Cobranca, Pagamento } from "@/models/notaFiscalEletronicaEmissaoModel";
import NotaFiscalEntradaItemModel from "@/models/notaFiscalEntradaItemModel";
import NotaFiscalEntradaModel from "@/models/notaFiscalEntradaModel";
import PaginationModel from "@/models/paginationModel";
import NcmModel from "@/models/produto/ncmModel";
import TransportadoraModel from "@/models/transporte/transportadoraModel";
import UsuarioModel from "@/models/usuarioModel";
import VendaItemModel from "@/models/vendaItemModel";
import VendaModel from "@/models/vendaModel";
import VolumeModel from "@/models/volumeModel";
import ClienteService from "@/services/clienteService";
import NotaFiscalEletronicaEmissaoService from "@/services/emissaoNota/notaFiscalEletronicaEmissaoService";
import FornecedorService from "@/services/fornecedorService";
import NaturezaOperacaoService from "@/services/naturezaOperacaoService";
import NcmService from "@/services/produto/ncmService";
import TransportadoraService from "@/services/transporte/transportadoraService";
import VolumeService from "@/services/volumeService";
import { AppState, Getters, Mutations } from "@/store/store";
import arithmeticHelper from "@/utils/common/arithmeticHelper";
import { getFornecedorCombo } from "@/utils/common/combo/combotext";
import Delay from "@/utils/common/delay";
import Bus from "@/utils/events/bus";
import ValidationErrorWrapper from "@/wrappers/validationErrorWrapper";

import clienteComponent from "../../parent/crud/cliente/edit.vue";
import fornecedorComponent from "../../parent/crud/fornecedor/edit.vue";
import naturezaOperacaoComponent from "../../parent/crud/naturezaoperacao/edit.vue";
import ClienteEditComponent from "../crud/cliente/edit";
import FornecedorEditComponent from "../crud/fornecedor/edit";
import NaturezaOperacaoEditComponent from "../crud/naturezaoperacao/edit";
import ShortcutComponent from "../shortcut/shortcut";
import shortcutComponent from "../shortcut/shortcut.vue";

import cobrancaComponent from "./cobranca.vue";
import ExportacaoComponent from "./exportacao";
import exportacaoComponent from "./exportacao.vue";
import ItemNFEComponent from "./item";
import itemListComponent from "./item.vue";
import {
    carregaICMSInterestadual,
    loadImpostosByNaturezaOperacao,
    getNewItemByProduto,
    validateItemNota,
} from "./nfeUtils";
import pagamentoComponent from "./pagamento.vue";
import ResumoComponent from "./resumo/resumo";
import resumoComponent from "./resumo/resumo.vue";
import retencoesComponent from "./retencoes.vue";
import transportadoraComponent from "./transportadora.vue";
import volumesComponent from "./volumes.vue";

import "../crud/crud.scss";

type ComboOptions = {
    value: number;
    text: string;
};

@Component({
    components: {
        transportadoraComponent,
        volumesComponent,
        cobrancaComponent,
        pagamentoComponent,
        retencoesComponent,
        exportacaoComponent,
        itemListComponent,
        checkboxComponent,
        dataTooltipComponent,
        moedaComponent,
        fieldsetComponent,
        comboComponent,
        shortcutComponent,
        naturezaOperacaoComponent,
        clienteComponent,
        fornecedorComponent,
        resumoComponent,
        actionBarComponent,
        searchComboComponent,
        rascunhoComponent,
        logEntidadeComponent,
    },
    computed: {
        ...mapState({
            nota: (state: AppState) => state.nfeContext.nota,
            consulta: (state: AppState) => state.nfeContext.consulta,
            emissaoVenda: (state: AppState) => state.nfeContext.emissaoVenda,
            preLoadPack: (state: AppState) => state.preLoad.preLoadList,
            loadedList: (state: AppState) => state.preLoad.loadedList,
            usuarioLogado: (state: AppState) => state.session.usuarioLogado,
        }),
        ...mapGetters(["GET_CONFIG_FRANQUIA"] as Getters),
    },
    methods: mapMutations([
        "SET_NOTA",
        "ADD_ITEM_NOTA",
        "UPDATE_ITEM",
        "SET_NOTA_CONSULTA",
        "SET_NOTA_EMISSAO_VENDA",
        "CONSOLIDA_NOTA",
    ] as Mutations),
})
export default class NfeEditComponent extends Vue {
    // State computed props
    nota: NotaFiscalEletronicaEmissaoModel;
    consulta: boolean;
    emissaoVenda: boolean;
    preLoadPack: PreLoadPackModel;
    loadedList: boolean;
    usuarioLogado: UsuarioModel;
    SET_NOTA: (nota: NotaFiscalEletronicaEmissaoModel) => void;
    ADD_ITEM_NOTA: ({
        item,
        simplesNacional,
    }: {
        item: NotaFiscalEletronicaEmissaoItemModel;
        simplesNacional: boolean;
    }) => void;
    UPDATE_ITEM: (data: object) => void;
    SET_NOTA_CONSULTA: (consulta: boolean) => void;
    SET_NOTA_EMISSAO_VENDA: (emissaoVenda: boolean) => void;
    CONSOLIDA_NOTA: (venda: VendaModel) => void;
    GET_CONFIG_FRANQUIA: (configuracao: Configuracoes) => ConfiguracaoFranquiaModel;

    private validationErrorWrapper = new ValidationErrorWrapper(this.$validator);
    private itemListComponent: ItemNFEComponent = null;
    private exportacaoComponent: ExportacaoComponent = null;
    private service = new NotaFiscalEletronicaEmissaoService();
    private naturezaOperacaoService = new NaturezaOperacaoService();
    private clienteService = new ClienteService();
    private fornecedorService = new FornecedorService();
    private transportadoraService = new TransportadoraService();

    private modelId = null;
    private vendaModel: VendaModel = null;
    private naturezaOperacaoComponent: NaturezaOperacaoEditComponent = null;
    private clienteComponent: ClienteEditComponent = null;
    private fornecedorComponent: FornecedorEditComponent = null;
    private shortcutComponent: ShortcutComponent = null;
    private resumoComponent: ResumoComponent = null;
    private ncmService = new NcmService();

    private tipoOperacaoOptions: Array<ComboOptions> = [
        { text: `${TipoOperacao.Entrada}-Entrada`, value: TipoOperacao.Entrada },
        { text: `${TipoOperacao.Saida}-Saida`, value: TipoOperacao.Saida },
    ];
    private finalidadeEmissaoOptions: Array<ComboOptions> = [
        { text: "1-NF-e Normal", value: 1 },
        { text: "2-NF-e Complementar", value: 2 },
        { text: "4-Devolução de mercadoria", value: 4 },
    ];
    private modeloOptions: Array<ComboOptions> = [
        { text: "55-NF-e", value: 55 },
        { text: "59-CF-e", value: 59 },
        { text: "65-NFC-e", value: 65 },
    ];
    private indicadorOperacaoOptions: Array<ComboOptions> = [
        { text: "0-Normal", value: 0 },
        { text: "1-Consumidor final", value: 1 },
    ];
    private indicadorPresencaOptions: Array<ComboOptions> = [
        { text: "0-Não se aplica", value: 0 },
        { text: "1-Operação presencial", value: 1 },
        { text: "2-Operação não presencial, pela Internet", value: 2 },
        { text: "3-Operação não presencial, Teleatendimento", value: 3 },
        { text: "4-NFC-e em operação com entrega a domicílio", value: 4 },
        { text: "9-Operação não presencial, outros", value: 9 },
    ];
    private indicativoIntermedioOptions: Array<ComboOptions> = [
        { text: "0-Operação sem intermediador", value: 0 },
        { text: "1-Operação em site ou plataforma de terceiros", value: 1 },
    ];
    private tipoCadastroOptions: Array<ComboOptions> = [
        { text: `${TipoCadastro.Fornecedor}-Fornecedor`, value: TipoCadastro.Fornecedor },
        { text: `${TipoCadastro.Cliente}-Cliente`, value: TipoCadastro.Cliente },
    ];
    private cfopOptions: Array<ComboOptions> = [];
    private fornecedorOptions: Array<ComboOptions> = [];
    private estoqueOptions: Array<ComboOptions> = [];

    private loadingConsulta = false;
    private baixaEstoque = false;
    private clienteId = null;
    private notaTransporte = false;
    private loadingNotaTransporte = false;

    @Prop(Boolean) isEmissaoVenda: boolean;
    @Prop({ type: Boolean, default: false }) isModal: boolean;

    private customComboTextCliente = (p: ClienteModel) => ({
        value: p.id,
        text: `${p.id} - ` + (p.tipoPessoa != 1 ? p.nome : p.razaoSocial),
    });

    private async onSaveRascunho() {
        const rascunhoComponent = this.$refs.rascunhoComponent as RascunhoComponent;
        rascunhoComponent.saveRascunho(this.nota);
    }

    private onLoadRascunho(data: any) {
        this.SET_NOTA(data);
    }

    public async loadById(id: number) {
        this.modelId = id;
        this.SET_NOTA_EMISSAO_VENDA(false);

        await this.load();
    }

    private async load() {
        this.SET_NOTA_EMISSAO_VENDA(this.isEmissaoVenda);
        this.nota.movimentarEstoque = false;

        this.nota.estoqueId = this.GET_CONFIG_FRANQUIA(Configuracoes.EstoquePadrao).estoqueId;

        if (!this.emissaoVenda && this.modelId) {
            this.SET_NOTA_CONSULTA(true);
            this.loadingConsulta = true;

            const data = await this.service.get(this.modelId).resolveWithJSON<NotaFiscalEletronicaEmissaoModel>();
            const destinatarioId = data.destinatarioId;
            const naturezaOperacaoId = data.naturezaOperacaoId;
            this.SET_NOTA(data);

            await this.carregarCfops();
            this.nota.destinatarioId = destinatarioId;
            this.nota.naturezaOperacaoId = naturezaOperacaoId;
            await this.onNaturezaChange();
            this.nota.destinatarioId = destinatarioId;
            this.nota.naturezaOperacaoId = naturezaOperacaoId;
            this.SET_NOTA(this.nota);
            this.loadingConsulta = false;
        } else {
            this.clear();
        }

        this.validationErrorWrapper.clearErrors();
    }

    public async loadNotaDevolucaoCompra(nota: NotaFiscalEntradaModel, itens: NotaFiscalEntradaItemModel[]) {
        this.clear();

        this.nota.finalidadeEmissao = 4;
        this.nota.vendaId = null;
        this.nota.chaveReferenciada = nota.chave;
        this.nota.tipoOperacao = TipoOperacao.Saida;
        this.nota.tipoCadastro = TipoCadastro.Fornecedor;

        this.nota.destinatarioId = nota.fornecedorId;
        this.nota.naturezaOperacaoId = await this.getNaturezaDevolucaoByFornecedor(nota.fornecedorId);
        this.nota.transportadoraId = nota.transportadoraId;
        this.nota.modalidadeFrete = nota.transportadoraId ? 0 : 9;

        this.nota.itens = [];

        this.nota.destinatario = await this.fornecedorService.get(nota.fornecedorId).resolveWithJSON<FornecedorModel>();

        for await (const itemDevolver of itens) {
            let item = new NotaFiscalEletronicaEmissaoItemModel();
            const itemDefault = await getNewItemByProduto(
                itemDevolver.produtoId,
                this.nota,
                this.usuarioLogado.franquia,
            );

            item.updateFrom(itemDevolver);
            item.ncmId = itemDefault.ncmId;
            item.ncmCodigo = itemDefault.ncmCodigo;
            item.quantidade = itemDevolver.quantidadeADevolver;
            item.produtoLoteId = itemDevolver.produtoLoteId;

            if (!item.unidadeMedidaId) item.unidadeMedidaId = itemDefault.unidadeMedidaId;
            if (!item.EAN) item.EAN = Number(item.EAN);

            if (!item.origemMercadoria) item.origemMercadoria = itemDefault.origemMercadoria;
            if (!item.aliquotaICMS) item.aliquotaICMS = itemDefault.aliquotaICMS;
            if (!item.percentualDiferimento) item.percentualDiferimento = itemDefault.percentualDiferimento;
            if (!item.percentualReducaoBase) item.percentualReducaoBase = itemDefault.percentualReducaoBase;
            if (!item.cstICMS) item.cstICMS = itemDefault.cstICMS;

            item.comICMSPartilha = itemDefault.comICMSPartilha;
            if (!item.aliquotaFCPUFDestino) item.aliquotaFCPUFDestino = itemDefault.aliquotaFCPUFDestino;
            if (!item.aliquotaUFDestino) item.aliquotaUFDestino = itemDefault.aliquotaUFDestino;
            if (!item.aliquotaInterestadual) item.aliquotaInterestadual = itemDefault.aliquotaInterestadual;
            if (!item.aliquotaInterestadualPartilha) {
                item.aliquotaInterestadualPartilha = itemDefault.aliquotaInterestadualPartilha;
            }

            if (!item.sujeitoIPI) item.sujeitoIPI = itemDefault.sujeitoIPI;
            if (!item.aliquotaIPI) item.aliquotaIPI = itemDefault.aliquotaIPI;
            if (!item.cstIPI) item.cstIPI = itemDefault.cstIPI;
            if (!item.percentualDevolvido) item.percentualDevolvido = itemDefault.percentualDevolvido;
            if (!item.valorIPIDevolvido) item.valorIPIDevolvido = itemDefault.valorIPIDevolvido;

            if (!item.aliquotaPIS) item.aliquotaPIS = itemDefault.aliquotaPIS;
            if (!item.cstPIS) item.cstPIS = itemDefault.cstPIS;

            if (!item.aliquotaCOFINS) item.aliquotaCOFINS = itemDefault.aliquotaCOFINS;
            if (!item.cstCOFINS) item.cstCOFINS = itemDefault.cstCOFINS;

            if (!item.valorDesconto) item.valorDesconto = 0;
            if (!item.valorFrete) item.valorFrete = 0;
            if (!item.valorOutraDespesa) item.valorOutraDespesa = 0;
            if (!item.valorSeguro) item.valorSeguro = 0;

            if (itemDevolver.valorIPI > 0) {
                item.percentualDevolvido = arithmeticHelper.round(
                    (itemDevolver.quantidadeADevolver / itemDevolver.quantidade) * 100,
                );
                item.valorIPIDevolvido = arithmeticHelper.round(
                    (itemDevolver.valorIPI * item.percentualDevolvido) / 100,
                );
            }

            const IPI = itemDevolver.notaFiscalEntradaItemImpostos.filter(i => i.imposto == "IPI");
            if (IPI.length > 0 && IPI[0].valorImposto > 0) {
                item.percentualDevolvido = arithmeticHelper.round(
                    (itemDevolver.quantidadeADevolver / itemDevolver.quantidade) * 100,
                );
                item.valorIPIDevolvido = arithmeticHelper.round((IPI[0].valorImposto * item.percentualDevolvido) / 100);
            }

            if (item.produtoLoteId) {
                const data = await this.itemListComponent.getProdutoLoteDados(item.produtoLoteId, item, true);
                item = data.item;
            }
            item.unidadeMedidaSigla = this.itemListComponent.getUnidadeMedidaSigla(item.unidadeMedidaId);

            this.ADD_ITEM_NOTA({ item, simplesNacional: this.usuarioLogado.franquia.opcaoSimplesNacional });
        }
    }

    public async loadModelByVenda(venda: VendaModel, isReloading = false) {
        this.clear();

        this.vendaModel = venda;

        this.nota.tipoOperacao = TipoOperacao.Saida;
        this.nota.tipoCadastro = TipoCadastro.Cliente;
        this.nota.vendaId = venda.id;
        this.nota.destinatarioId = venda.clienteId;
        this.clienteId = venda.clienteId;
        if (!isReloading) {
            this.nota.naturezaOperacaoId = await this.getNaturezaByCliente(venda.clienteId);
        }
        this.nota.transportadoraId = venda.transportadoraId;
        this.nota.itens = [];

        const cliente = await this.clienteService.get(venda.clienteId).then(r => r.json() as Promise<ClienteModel>);
        this.nota.destinatario = cliente;

        this.baixaEstoque = await this.perguntaBaixaEstoque(cliente);

        try {
            venda.contaReceber.movimentacoes.forEach(pag => {
                let meioPagamento = 0;
                switch (pag.formaPagamentoDescricao) {
                    case "Dinheiro":
                        meioPagamento = 1;
                        break;
                    case "Cheque":
                        meioPagamento = 2;
                        break;
                    case "Boleto":
                        meioPagamento = 15;
                        break;
                    case "Cartão de Débito":
                        meioPagamento = 4;
                        break;
                    case "Cartão de Crédito":
                        meioPagamento = 3;
                        break;
                    default:
                        meioPagamento = 1;
                        break;
                }
                this.nota.pagamentos.push({ indicadorPagamento: 0, meioPagamento, valorPagamento: pag.valor });
            });

            if (venda.contaReceber.lancamentosVinculados.length > 0) {
                this.nota.cobranca = new Cobranca();
                this.nota.cobranca.numero = "1";
                venda.contaReceber.lancamentosVinculados.forEach((parcela, index) => {
                    this.nota.cobranca.duplicatas.push({
                        numero: (index + 1).toString(),
                        dataVencimento: parcela.dataVencimento,
                        valor: parcela.valorTotal,
                    });
                });
                const totalDuplicatas = this.nota.cobranca.duplicatas.reduce((acc, p) => acc + p.valor, 0);
                this.nota.cobranca.valorOriginal = totalDuplicatas + this.nota.cobranca.valorDesconto;
            }

            if (venda.volumeId) {
                const volume = await new VolumeService()
                    .get(venda.volumeId)
                    .then(r => r.json() as Promise<VolumeModel>);
                if (volume) {
                    this.nota.volumes.push(volume);
                }
            }
        } catch {}

        const produtoAlteradosCompra = [];

        for (let index = 0; index < venda.itens.length; index++) {
            const itemVenda = venda.itens[index];

            if (itemVenda.fracionamento) {
                for (let i = 0; i < itemVenda.fracionamento.itens.length; i++) {
                    let item = await getNewItemByProduto(
                        itemVenda.fracionamento.itens[i].produtoId,
                        this.nota,
                        this.usuarioLogado.franquia,
                        this.baixaEstoque,
                        produtoAlteradosCompra,
                    );
                    item.produtoId = itemVenda.fracionamento.itens[i].produtoId;
                    item.produtoLoteId = itemVenda.fracionamento.itens[i].produtoLoteId;
                    item.quantidade = itemVenda.fracionamento.itens[i].quantidade;
                    item.valorUnitario = itemVenda.fracionamento.itens[i].valor;
                    item.valorDesconto = itemVenda.desconto;
                    item.valorOutraDespesa = itemVenda.acrescimo;
                    item.valorTotal = itemVenda.valorTotal;
                    item.unidadeMedidaSigla = this.itemListComponent.getUnidadeMedidaSigla(item.unidadeMedidaId);

                    if (item.produtoLoteId) {
                        const data = await this.itemListComponent.getProdutoLoteDados(item.produtoLoteId, item);
                        item = data.item;

                        if (data.lote?.origemProdutoLote == OrigemProdutoLote.Fracionamento) {
                            const unidadeMedidaFracionado = this.itemListComponent.getUnidadeMedidaSigla(
                                data.lote.unidadeMedidaFracionadoId,
                            );
                            item.produtoDescricao += ` ${data.lote.volumeFracionado} ${unidadeMedidaFracionado}`;

                            item.unidadeMedidaId = this.itemListComponent.getIdUnidadeMedidaUnidade();
                            item.unidadeMedidaSigla = "UN";
                        }
                    }

                    if (venda.tipoVenda === TipoVenda.Transferencia) {
                        item = this.setValoresTransferencia(item, itemVenda);
                    }

                    this.ADD_ITEM_NOTA({ item, simplesNacional: this.usuarioLogado.franquia.opcaoSimplesNacional });
                }
            } else if (itemVenda.manipulacaoOrdem) {
                const produtoConfiguracao = this.GET_CONFIG_FRANQUIA(Configuracoes.ProdutoPadraoDeManipulacao);

                let item = await getNewItemByProduto(
                    produtoConfiguracao.valor,
                    this.nota,
                    this.usuarioLogado.franquia,
                    this.baixaEstoque,
                    produtoAlteradosCompra,
                );

                item.produtoId = produtoConfiguracao.valor;
                item.quantidade = 1;
                item.valorDesconto = itemVenda.manipulacaoOrdem.desconto;
                item.valorOutraDespesa = itemVenda.manipulacaoOrdem.acrescimo;
                item.valorUnitario = itemVenda.manipulacaoOrdem.valorTotal;
                item.valorTotal = itemVenda.manipulacaoOrdem.valorTotal;

                if (venda.tipoVenda === TipoVenda.Transferencia) {
                    item = this.setValoresTransferencia(item, itemVenda);
                }
                item.unidadeMedidaSigla = this.itemListComponent.getUnidadeMedidaSigla(item.unidadeMedidaId);

                this.ADD_ITEM_NOTA({ item, simplesNacional: this.usuarioLogado.franquia.opcaoSimplesNacional });
            } else {
                let item = await getNewItemByProduto(
                    itemVenda.produtoId,
                    this.nota,
                    this.usuarioLogado.franquia,
                    this.baixaEstoque,
                    produtoAlteradosCompra,
                );
                item.produtoId = itemVenda.produtoId;
                item.quantidade = itemVenda.quantidade;
                item.produtoLoteId = itemVenda.produtoLoteId;
                item.valorDesconto = itemVenda.desconto;
                item.valorOutraDespesa = itemVenda.acrescimo;
                item.valorUnitario = itemVenda.valorUnitario;
                item.valorTotal = itemVenda.valorTotal;

                if (item.produtoLoteId) {
                    const data = await this.itemListComponent.getProdutoLoteDados(item.produtoLoteId, item);
                    item = data.item;
                }
                item.unidadeMedidaSigla = this.itemListComponent.getUnidadeMedidaSigla(item.unidadeMedidaId);

                if (venda.tipoVenda === TipoVenda.Transferencia) {
                    item = this.setValoresTransferencia(item, itemVenda);
                }
                this.ADD_ITEM_NOTA({ item, simplesNacional: this.usuarioLogado.franquia.opcaoSimplesNacional });
            }
        }

        this.CONSOLIDA_NOTA(venda);

        if (this.baixaEstoque) {
            await this.showQtdItensAlteradosCompra(produtoAlteradosCompra);
        }
    }

    public async loadModelByTransporte(venda: VendaModel, isReloading = false) {
        this.clear();
        const descriminarAcrescimo = this.GET_CONFIG_FRANQUIA(
            Configuracoes.DescriminarAcrescimoNotaTransporte,
        )?.verdadeiro;

        this.loadingNotaTransporte = true;

        this.notaTransporte = true;
        this.vendaModel = venda;
        this.nota.tipoOperacao = TipoOperacao.Saida;
        this.nota.tipoCadastro = TipoCadastro.Cliente;
        this.nota.vendaId = venda.id;
        this.nota.destinatarioId = venda.clienteId;
        this.clienteId = venda.clienteId;
        this.nota.transportadoraId = venda.transportadoraId;
        this.nota.itens = [];

        const cliente = await this.clienteService.get(venda.clienteId).resolveWithJSON<ClienteModel>();
        this.nota.destinatario = cliente;

        if (!isReloading) {
            let outroEstado = false;

            const enderecoEntrega = cliente.enderecosEntrega.find(p => p.id === venda.enderecoEntregaId);
            if (enderecoEntrega) {
                outroEstado = this.usuarioLogado.franquia.estadoId !== enderecoEntrega.estadoId;
            } else {
                outroEstado = this.usuarioLogado.franquia.estadoId !== cliente.estadoId;
            }

            this.nota.naturezaOperacaoId = await this.getNaturezaByCodigo(outroEstado ? 6949 : 5949);
        }

        try {
            if (venda.volumeId) {
                const volume = await new VolumeService().get(venda.volumeId).resolveWithJSON<VolumeModel>(false);
                if (volume) {
                    this.nota.volumes.push(volume);
                }
            }
        } catch {}

        for (let index = 0; index < venda.itens.length; index++) {
            const itemVenda = venda.itens[index];

            if (itemVenda.fracionamento) {
                for (let i = 0; i < itemVenda.fracionamento.itens.length; i++) {
                    let item = await loadImpostosByNaturezaOperacao(
                        this.nota,
                        new NotaFiscalEletronicaEmissaoItemModel(),
                    );

                    item.quantidade = itemVenda.fracionamento.itens[i].quantidade;
                    item.valorUnitario = itemVenda.fracionamento.itens[i].valor;
                    item.valorDesconto = itemVenda.desconto;
                    item.valorOutraDespesa = itemVenda.acrescimo;
                    item.valorTotal = itemVenda.valorTotal;
                    item.produtoDescricao =
                        itemVenda.fracionamento.itens[i].descricao != null
                            ? itemVenda.fracionamento.itens[i].descricao
                            : itemVenda.fracionamento.itens[i].produto.descricaoFiscal ??
                              itemVenda.fracionamento.itens[i].produto.descricao;

                    try {
                        if (itemVenda.fracionamento.itens[i].produto.ncmId) {
                            const ncm = await this.ncmService
                                .get(itemVenda.fracionamento.itens[i].produto.ncmId)
                                .resolveWithJSON<NcmModel>(false, false);

                            item.ncmId = ncm?.id;
                            item.ncmCodigo = ncm?.codigo;
                        }
                    } catch {}

                    item.unidadeMedidaSigla = "UN";
                    item.unidadeMedidaId = this.preLoadPack.unidadesMedida.find(x => x.sigla == "un")?.id;

                    if (venda.tipoVenda === TipoVenda.Transferencia || !descriminarAcrescimo) {
                        item = this.setValoresTransferencia(item, itemVenda);
                    }
                    this.ADD_ITEM_NOTA({ item, simplesNacional: this.usuarioLogado.franquia.opcaoSimplesNacional });
                }
            } else {
                let item = await loadImpostosByNaturezaOperacao(this.nota, new NotaFiscalEletronicaEmissaoItemModel());

                if (itemVenda.manipulacaoOrdem) {
                    item.produtoDescricao = this.getDescricaoFiscal(itemVenda.manipulacaoOrdem);
                    item.valorDesconto = itemVenda.manipulacaoOrdem.desconto;
                    item.valorOutraDespesa = itemVenda.manipulacaoOrdem.acrescimo;
                    item.valorUnitario =
                        (itemVenda.manipulacaoOrdem.valorTotal -
                            itemVenda.manipulacaoOrdem.acrescimo +
                            itemVenda.manipulacaoOrdem.desconto) /
                        itemVenda.quantidade;
                    item.valorTotal = itemVenda.manipulacaoOrdem.valorTotal;
                } else {
                    item.produtoDescricao = itemVenda?.produto?.descricaoFiscal ?? itemVenda.produtoDescricao;
                    item.valorDesconto = itemVenda.desconto;
                    item.valorOutraDespesa = itemVenda.acrescimo;
                    item.valorUnitario = itemVenda.valorUnitario;
                    item.valorTotal = itemVenda.valorTotal;
                }

                if (venda.tipoVenda === TipoVenda.Transferencia) {
                    item = this.setValoresTransferencia(item, itemVenda);
                }

                item.quantidade = itemVenda.quantidade;
                item.unidadeMedidaSigla = "UN";

                try {
                    const produtoItem = itemVenda.manipulacaoOrdem.itens.find(
                        x => x.tipoItem == TiposItemManipulacao.MateriaPrima,
                    );
                    if (produtoItem.produto?.ncmId) {
                        const ncm = await this.ncmService
                            .get(produtoItem.produto?.ncmId)
                            .resolveWithJSON<NcmModel>(false, false);

                        item.ncmId = ncm?.id;
                        item.ncmCodigo = ncm?.codigo;
                    }
                } catch {}

                item.unidadeMedidaId = this.preLoadPack.unidadesMedida.find(x => x.sigla == "un")?.id;

                this.ADD_ITEM_NOTA({ item, simplesNacional: this.usuarioLogado.franquia.opcaoSimplesNacional });
            }
        }

        if (this.nota.vendaInterestadual) {
            await this.onLoadICMSInterestadual();
        }

        this.CONSOLIDA_NOTA(venda);

        this.loadingNotaTransporte = false;
    }

    private setValoresTransferencia(item: NotaFiscalEletronicaEmissaoItemModel, itemVenda: VendaItemModel) {
        item.valorDesconto = 0;
        item.valorOutraDespesa = 0;
        item.valorUnitario = arithmeticHelper.round(itemVenda.valorTotal / itemVenda.quantidade, 4);
        item.valorTotal = itemVenda.valorTotal;
        return item;
    }

    private getDescricaoFiscal(manipulacao: ManipulacaoOrdemModel) {
        if (manipulacao.itens.length > 0) {
            const produto = manipulacao.itens[0].produto;

            if (produto && produto.descricaoFiscal) {
                return `${manipulacao.formaFarmaceuticaDescricao ?? manipulacao.formaFarmaceutica?.descricao} Ativo (${
                    produto?.descricaoFiscal
                })`;
            }
        }

        return manipulacao.descricao;
    }

    private async getNaturezaByCliente(clienteId: number): Promise<number> {
        return await this.naturezaOperacaoService.getNaturezaByCliente(clienteId).resolveWithJSON<number>();
    }

    private async getNaturezaByCodigo(codigo: number): Promise<number> {
        try {
            return await this.naturezaOperacaoService.getNaturezaByCodigo(codigo).resolveWithJSON<number>();
        } catch {
            return 0;
        }
    }

    private async getNaturezaDevolucaoByFornecedor(fornecedorId: number): Promise<number> {
        try {
            return await this.naturezaOperacaoService
                .getNaturezaDevolucaoByFornecedor(fornecedorId)
                .resolveWithJSON<number>();
        } catch {
            return null;
        }
    }

    private async validateNFE() {
        let isValid = await this.$validator.validateAll();

        if (this.nota.notaExportacao && isValid) {
            if (!this.exportacaoComponent) {
                this.exportacaoComponent = this.$refs.exportacaoComponent as ExportacaoComponent;
            }

            isValid = await this.exportacaoComponent.validate();
        }

        if (isValid) {
            for (let index = 0; index < this.nota.itens.length; index++) {
                const element = this.nota.itens[index];
                const message = validateItemNota(element, this.nota, this.usuarioLogado.franquia.opcaoSimplesNacional);

                if (message != "") {
                    this.$showWarning("NF-e", `Item ${index + 1} - ${element.produtoDescricao} \n ${message}`);
                    return false;
                }
            }
            return true;
        }
        this.$focusErrorField();
        return false;
    }

    private clear() {
        this.nota.tipoOperacao = TipoOperacao.Saida;
        this.nota.tipoCadastro = TipoCadastro.Cliente;
        this.nota.modelo = 55;
        this.nota.finalidadeEmissao = 1;
        this.nota.indicadorPresenca = 0;
        this.nota.indicadorOperacao = 0;
        this.nota.modalidadeFrete = 9;
        this.nota.itens = new Array<NotaFiscalEletronicaEmissaoItemModel>();
        this.nota.pagamentos = new Array<Pagamento>();
        this.nota.volumes = new Array<VolumeModel>();
        this.nota.destinatarioId = null;
        this.clienteId = null;
    }

    public async emitir(emitirByVenda = true): Promise<NotaFiscalEletronicaEmissaoModel> {
        if (await this.validateNFE()) {
            await this.checkEmailTransportadora();
            try {
                let response = null;
                if (emitirByVenda) {
                    response = await this.service.emitirByVenda(this.nota);
                } else {
                    response = await this.service.emitir(this.nota);
                }

                if (!response.ok) {
                    const error = await response.json();
                    throw error;
                }

                this.nota.id = (await response.json()) as number;
                const nota = await this.service.get(this.nota.id).resolveWithJSON<NotaFiscalEletronicaEmissaoModel>();

                if (nota.statusEnvio == StatusEnvioNF.Pendente) {
                    this.$showWarning(
                        "Enviado!",
                        "Nota Fiscal foi enviada mas ainda não obteve retorno. Consulte a emissão da nota novamente.",
                    );
                } else {
                    this.$showSuccess("Enviado!", "Nota Fiscal foi enviada com sucesso.");
                }

                return this.nota;
            } catch (err) {
                if (err) {
                    this.validationErrorWrapper.showErrorsAsync(err.length ? err : [err]);
                }
                return null;
            }
        }
    }

    private async save() {
        if (await this.validateNFE()) {
            await this.checkEmailTransportadora();
            try {
                const sucesso = await this.service.emitir(this.nota).withLoading().resolveWithoutJSON();

                if (sucesso) {
                    await this.$showSuccess("NF-e", "NF-e enviada com sucesso.");

                    this.$router.back();
                }
            } catch {}
        }
    }

    private async checkEmailTransportadora() {
        if (this.nota.transportadoraId) {
            const transportadora = await this.transportadoraService
                .get(this.nota.transportadoraId)
                .resolveWithJSON<TransportadoraModel>();

            if (transportadora.email) {
                const resposta = await this.$showQuestion("NF-e", "Deseja enviar nota para o email da transportadora?");
                if (resposta) {
                    this.nota.emailTransportadora = transportadora.email;
                }
            }
        }
    }

    private cancel() {
        this.$router.back();
    }

    @Watch("nota")
    private onChangeNota() {
        if (!this.consulta) {
            this.SET_NOTA(this.nota);
        }
    }

    // @ts-ignore
    @Watch("nota.naturezaOperacaoId")
    private async onCFOPChange() {
        if (!this.nota.naturezaOperacaoId) return;

        await this.onNaturezaChange().withLoading();
    }

    // @ts-ignore
    @Watch("nota.vendaInterestadual")
    private async onChangeVendaInterestadual() {
        if (this.nota.vendaInterestadual) {
            await this.onLoadICMSInterestadual();
        }
    }

    private async onLoadICMSInterestadual() {
        for (let index = 0; index < this.nota.itens.length; index++) {
            const item = this.nota.itens[index];
            await carregaICMSInterestadual(item, this.nota, this.usuarioLogado.franquia);
            this.UPDATE_ITEM({ item, index });
        }
    }

    private async onNaturezaChange() {
        if (this.nota.naturezaOperacaoId) {
            const data = await this.naturezaOperacaoService
                .get(this.nota.naturezaOperacaoId)
                .resolveWithJSON<NaturezaOperacaoModel>();

            this.nota.naturezaOperacao = data;
            this.nota.notaImportacao = data.codigo.toString().substring(0, 1) == "3";
            this.nota.notaExportacao = data.codigo.toString().substring(0, 1) == "7";
            this.nota.vendaInterestadual = data.codigo.toString().substring(0, 1) == "6";
            if (data.observacao) {
                this.nota.observacao = data.observacao;
            }
            if (this.nota.tipoCadastro == TipoCadastro.Fornecedor) {
                await this.carregarFornecedores();
            }

            if (
                !this.consulta &&
                (this.nota.notaImportacao || this.nota.notaExportacao) &&
                this.fornecedorOptions.filter(item => item.value == this.nota.destinatarioId).length == 0
            ) {
                this.nota.destinatarioId = null;
            }

            if (data.usaConfiguracaoFiscal && this.vendaModel != null) {
                if (this.notaTransporte) {
                    while (this.loadingNotaTransporte) await Delay(100);

                    await this.loadModelByTransporte(this.vendaModel, true);
                } else {
                    await this.loadModelByVenda(this.vendaModel, true);
                }
            }
            this.nota.movimentarEstoque = this.nota.naturezaOperacao.movimentaEstoque;
        }
    }

    // @ts-ignore
    @Watch("nota.tipoOperacao")
    private onChangeTipoOperacao() {
        if (!this.consulta) {
            this.nota.naturezaOperacaoId = null;
            this.carregarCfops();

            if (this.nota.tipoOperacao == TipoOperacao.Entrada) {
                this.nota.modelo = 55;
                this.nota.tipoCadastro = TipoCadastro.Fornecedor;
            } else {
                this.nota.tipoCadastro = TipoCadastro.Cliente;
            }
        }
    }

    // @ts-ignore
    @Watch("nota.finalidadeEmissao")
    private onChangeFinalidadeEmissao() {
        if (this.nota.finalidadeEmissao == 4) {
            if (this.nota.tipoOperacao == TipoOperacao.Entrada) {
                this.nota.tipoCadastro = TipoCadastro.Cliente;
            } else {
                this.nota.tipoCadastro = TipoCadastro.Fornecedor;
            }
        }
        if (this.nota.finalidadeEmissao == 2) {
            this.nota.indicadorPresenca = 0;
        }
    }

    // @ts-ignore
    @Watch("nota.destinatarioId")
    private async onChangeDestinatarioId() {
        if (this.nota.destinatarioId) {
            if (this.nota.tipoCadastro == TipoCadastro.Fornecedor) {
                this.nota.destinatario = await this.fornecedorService
                    .get(this.nota.destinatarioId)
                    .resolveWithJSON<FornecedorModel>();

                await this.fornecedorService.validarValidadeLicensaSanitaria(this.nota.destinatarioId).withLoading();
            } else {
                this.clienteId = this.nota.destinatarioId;
                this.nota.destinatario = await this.clienteService
                    .get(this.nota.destinatarioId)
                    .resolveWithJSON<ClienteModel>();
            }

            if (!this.emissaoVenda && !this.loadingConsulta) {
                this.baixaEstoque = await this.perguntaBaixaEstoque(this.nota.destinatario);
            }
        }
    }

    // @ts-ignore
    @Watch("clienteId")
    private onChangeClienteId() {
        this.nota.destinatarioId = this.clienteId;
    }

    private async perguntaBaixaEstoque(destinatario: object) {
        if (this.consulta) return;

        if (destinatario["cnpj"] == this.usuarioLogado.franquia.cnpj) {
            const question =
                "O sistema verificou que é uma nota de baixa de estoque, deseja carregar automaticamente os percentuais de ICMS da compra para os itens?";
            try {
                return await this.$showQuestionWithHTML(
                    this.$t("__.ts.atencao"),
                    `<div id="swal2-content" class="swal2-content" style="display: block;">${question}</div>`,
                );
                return true;
            } catch {}
        }
        return false;
    }

    private async showQtdItensAlteradosCompra(produtoAlteradosCompra) {
        if (produtoAlteradosCompra.length == 0) {
            await this.$showWarning(
                "Nenhum item alterado!",
                "Não foram alterados nenhum dos itens com os dados da compra. Isso se deve ao fato de que não foram encontrados registro de compra com os produtos destes itens. É necessário fazer o ajuste manualmente.",
            );
        } else {
            let itensAlterados = 0;
            this.nota.itens.forEach(i => {
                if (!produtoAlteradosCompra.indexOf(i.produtoId)) itensAlterados++;
            });
            await this.$showSuccess(
                "Itens alterados!",
                `Foram alterados ${itensAlterados} de ${this.nota.itens.length} itens.`,
            );
        }
    }

    // @ts-ignore
    @Watch("nota.modelo")
    private onChangeModelo(newValue: number) {
        if (this.consulta) return;

        if (this.nota.naturezaOperacaoId != null && newValue != null) {
            if (this.nota.naturezaOperacaoId.toString().substring(0, 1) != "5") this.nota.naturezaOperacaoId = null;
            this.carregarCfops();
        }

        if (this.nota.modelo == 65) {
            this.nota.tipoOperacao = TipoOperacao.Saida;
            this.nota.tipoCadastro = TipoCadastro.Cliente;
            this.nota.indicadorOperacao = 1;
        } else {
            this.nota.indicadorOperacao = 0;
        }

        if (this.nota.modelo == 55) this.nota.semDestinatario = false;
    }

    private async carregarCfops() {
        const data = await this.naturezaOperacaoService
            .combo()
            .then(r => r.json() as Promise<PaginationModel<NaturezaOperacaoModel>>);
        if (this.nota.modelo == 65) {
            this.cfopOptions = data.list
                .filter(item => item.tipoMovimentacao == TiposMovimentacao.Saida && item.dentroEstado)
                .map(item => ({ value: item.id, text: `${item.codigo} - ${item.descricao} ` }));
        } else {
            this.cfopOptions = data.list
                .filter(
                    item =>
                        item.tipoMovimentacao ==
                        (this.nota.tipoOperacao == TipoOperacao.Entrada
                            ? TiposMovimentacao.Entrada
                            : TiposMovimentacao.Saida),
                )
                .map(item => ({ value: item.id, text: `${item.codigo} - ${item.descricao} ` }));
        }
        this.cfopOptions = this.cfopOptions.sort((a, b) => (a.text > b.text ? 1 : -1));
    }

    private async carregarFornecedores() {
        const data = await this.fornecedorService
            .combo()
            .then(r => r.json() as Promise<PaginationModel<FornecedorModel>>);
        data.list = data.list.filter(item => (this.nota.notaImportacao || this.nota.notaExportacao) == item.exterior);
        this.fornecedorOptions = data.list.map(getFornecedorCombo);
    }

    private async consultaEmissao() {
        try {
            const sucesso = await this.service.consultar(this.nota.recibo, this.nota.modelo).resolveWithoutJSON();

            if (sucesso) {
                this.$showSuccess("Confirmada!", "Consulta de retorno com sucesso, Nota Fiscal foi aprovada");
                this.load();
            }
        } catch {}
    }

    private async openNewNaturezaOperacao() {
        this.naturezaOperacaoComponent = this.$refs.naturezaOperacaoComponent as NaturezaOperacaoEditComponent;
        this.naturezaOperacaoComponent.modelId = null;
        this.naturezaOperacaoComponent.load();
    }

    private async onAddNaturezaOperacao(closeModal) {
        if (await this.naturezaOperacaoComponent.save()) {
            closeModal();
        }
    }

    private async openComboEditNaturezaOperacao(id: number) {
        this.naturezaOperacaoComponent = this.$refs.naturezaOperacaoComponent as NaturezaOperacaoEditComponent;
        this.naturezaOperacaoComponent.modelId = id;
        this.naturezaOperacaoComponent.load();
    }

    private async onNaturezaOperacaoSaveOk() {
        await this.carregarCfops();
    }

    private async openNewCliente() {
        this.clienteComponent = this.$refs.clienteComponent as ClienteEditComponent;
        this.clienteComponent.modelId = null;
        this.clienteComponent.load();
    }

    private async openComboEditCliente(id: number) {
        this.clienteComponent = this.$refs.clienteComponent as ClienteEditComponent;
        this.clienteComponent.modelId = id;
        this.clienteComponent.load();
    }

    private async onAddCliente(closeModal) {
        if (await this.clienteComponent.save()) {
            closeModal();
        }
    }

    private async onClienteSaveOk() {}

    private async openNewFornecedor() {
        this.fornecedorComponent = this.$refs.fornecedorComponent as FornecedorEditComponent;
        this.fornecedorComponent.modelId = null;
        this.fornecedorComponent.load();
    }

    private async onAddFornecedor(closeModal) {
        if (await this.fornecedorComponent.save()) {
            closeModal();
        }
    }

    private async openComboEditFornecedor(id: number) {
        this.fornecedorComponent = this.$refs.fornecedorComponent as FornecedorEditComponent;
        this.fornecedorComponent.modelId = id;
        this.fornecedorComponent.load();
    }

    private async onFornecedorSaveOk() {
        await this.carregarFornecedores();
    }

    public async resumo() {
        if (await this.validateNFE()) {
            this.shortcutComponent = this.$refs.shortcutComponent as ShortcutComponent;
            this.resumoComponent = this.$refs.resumoComponent as ResumoComponent;

            const result = await this.resumoComponent.load(this.nota).withLoading();
            if (result) {
                this.shortcutComponent.show();
                this.shortcutComponent.title = "Resumo da emissão de Nota Fiscal";
            }
        }
    }

    private async loadEstoques() {
        while (!this.loadedList) await Delay(100);

        this.estoqueOptions = this.preLoadPack.estoques.map(p => ({ value: p.id, text: p.descricao }));
    }

    private openPDF() {
        this.service.openPdf(this.nota.pdfLink);
    }

    private openPDFSimplificado() {
        this.service.openPdf(this.nota.pdfSimplificadoLink);
    }

    private openXML() {
        this.service.downloadXML(this.nota.xmlLink);
    }

    private openCartaCorrecao() {
        this.service.showCartaCorrecao(this.modelId, this.nota.tipoOperacao);
    }

    private async onGerarCartaCorrecao() {
        const response = await this.service.cartaCorrecaoSelecao();

        if (response.isConfirmed) {
            try {
                const sucesso = await this.service
                    .cartaCorrecao(this.nota.chave, this.modelId, response.value, this.nota.tipoOperacao)
                    .withLoading()
                    .resolveWithoutJSON();

                if (sucesso) {
                    this.load();
                }
            } catch {}
        }
    }

    private async onCancelar() {
        const response = await this.service.cancelamentoSelecao();

        if (response.isConfirmed) {
            try {
                const sucesso = await this.service
                    .cancelamento(this.nota.chave, response.value)
                    .withLoading()
                    .resolveWithoutJSON();

                if (sucesso) {
                    this.load();
                }
            } catch {}
        }
    }

    private mounted() {
        Bus.$emit("hide-nav-menu", true);

        this.itemListComponent = this.$refs.itemListComponent as ItemNFEComponent;
        this.exportacaoComponent = this.$refs.exportacaoComponent as ExportacaoComponent;

        this.naturezaOperacaoComponent = this.$refs.naturezaOperacaoComponent as NaturezaOperacaoEditComponent;
        this.clienteComponent = this.$refs.clienteComponent as ClienteEditComponent;
        this.fornecedorComponent = this.$refs.fornecedorComponent as FornecedorEditComponent;
        this.shortcutComponent = this.$refs.shortcutComponent as ShortcutComponent;

        this.SET_NOTA(new NotaFiscalEletronicaEmissaoModel());
        this.SET_NOTA_CONSULTA(false);
        this.SET_NOTA_EMISSAO_VENDA(false);

        if (this.$route.params.id) {
            this.modelId = this.$route.params.id;
        }

        Promise.all([this.carregarFornecedores(), this.carregarCfops(), this.loadEstoques()])
            .withLoading()
            .then(() => {
                this.load().withLoading();
            })
            .catch(() => {});
    }
}
