import BaseModel from "./../base/baseModel";

export default class lancamentoMovimentacaoBaseModel extends BaseModel {
    id?: number = 0;
    dataLancamento: Date = null;
    dataMovimento: Date = null;
    contaCorrenteId = 0;
    contaCorrenteDescricao: string = null;
    juros = 0;
    desconto = 0;
    valor = 0;
    formaPagamentoId = 0;
    formaPagamentoDescricao: string = null;
    observacao: string = null;
    lancamentoId?: number = null;
    sessaoCaixaId?: number = null;
}
