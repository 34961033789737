import Vue from "vue";

import actionBarComponent from "@/components/child/actionBar/actionBar.vue";
import gridComponent from "@/components/child/grid/grid.vue";
import { GridColumn, GridColumnExt, GridColumnType } from "@/components/child/grid/gridColumn";
import { Component } from "@/decorators";
import CompraItemModel from "@/models/compraItemModel";
import CompraModel from "@/models/compraModel";
import ValidationErrorModel from "@/models/validationErrorModel";
import CompraService from "@/services/compraService";
import { sortArray } from "@/utils/common/array";

import "../../crud.scss";

@Component({
    components: {
        gridComponent,
        actionBarComponent,
    },
})
export default class CotacaoFornecedorComponent extends Vue {
    private service = new CompraService();

    gridData: Array<CompraItemModel> = [];
    model: CompraModel = new CompraModel();
    key = "";
    unidadeMedidaOptions: Array<Object> = [
        { text: "blh", value: 214 },
        { text: "ctc", value: 220 },
        { text: "g", value: 1 },
        { text: "gt", value: 7 },
        { text: "kg", value: 2 },
        { text: "l", value: 4 },
        { text: "mcg", value: 20 },
        { text: "mg", value: 3 },
        { text: "Mi", value: 10 },
        { text: "mic", value: 210 },
        { text: "ml", value: 5 },
        { text: "mlh", value: 215 },
        { text: "mui", value: 256 },
        { text: "ng", value: 211 },
        { text: "pc", value: 261 },
        { text: "ufc", value: 213 },
        { text: "ui", value: 21 },
        { text: "un", value: 6 },
        { text: "utr", value: 208 },
    ];

    get gridColumns(): Array<GridColumn> {
        return [
            new GridColumn("produtoDescricao", "Descrição", GridColumnType.String, false, false, "", "", true),
            new GridColumn("quantidadeCompra", "Qtd. a Comprar", GridColumnType.Decimal, false, false, "", "", true),
            new GridColumn("unidadeMedidaDescricao", "Und. Med.", GridColumnType.String, false, false, "", "", true),
            new GridColumnExt({
                value: "valorUnitario",
                text: "Valor Unitário",
                type: GridColumnType.Money,
                editable: true,
                onChange: (valor, index) => this.onCalculaValorTotal(valor, index),
            }),
            new GridColumnExt({
                value: "quantidade",
                text: "Quantidade",
                type: GridColumnType.Decimal,
                editable: true,
            }),
            new GridColumnExt({
                value: "valorTotal",
                text: "Valor Total",
                type: GridColumnType.Money,
                editable: true,
                onChange: (valor, index) => this.onCalculaValorUnitario(valor, index),
            }),
            new GridColumnExt({
                value: "unidadeMedidaId",
                text: "Unidade Medida",
                type: GridColumnType.Combo,
                comboOptions: () => this.unidadeMedidaOptions,
                defaultValueProp: "unidadeMedidaId",
                editable: true,
            }),
            new GridColumnExt({
                value: "dataValidade",
                text: "Validade",
                type: GridColumnType.Date,
                editable: true,
            }),
        ];
    }

    private onChangeSort(sortKey: keyof CompraItemModel, sortOrder: "asc" | "desc") {
        sortArray(this.gridData, sortKey, sortOrder);
    }

    private async onCalculaValorUnitario(valor, index) {
        const data = this.gridData[index];
        if (data != null) {
            data.valorUnitario = valor.valorTotal / valor.quantidade;
        }
    }

    private async onCalculaValorTotal(valor, index) {
        const data = this.gridData[index];
        if (data != null) {
            data.valorTotal = valor.valorUnitario * valor.quantidade;
        }
    }

    private async load() {
        await this.service
            .getCotacaoFornecedor(this.key)
            .withLoading()
            .then(async response => {
                if (response.ok) {
                    return response.json() as Promise<CompraModel>;
                } else {
                    const responseErro = (await response.json()) as ValidationErrorModel[];

                    await this.$showError(this.$t("__.ts.erro"), responseErro[0].field);
                    return this.$router.back();
                }
            })
            .then(data => {
                if (data) {
                    this.gridData = data.itens;
                }
            });
    }

    private async save() {
        try {
            if (this.gridData.some(s => s.valorUnitario == null || s.valorUnitario == 0)) {
                const produto = this.gridData.find(x => x.valorUnitario == null || x.valorUnitario == 0);
                return await this.$showError(
                    this.$t("__.ts.erro"),
                    `${this.$t("__.ts.necessarioAddValorUnitario")} ${produto.produtoDescricao}.`,
                );
            }
            if (this.gridData.some(s => s.unidadeMedidaId == null)) {
                const produto = this.gridData.find(x => x.unidadeMedidaId == null);
                return await this.$showError(
                    this.$t("__.ts.erro"),
                    `${this.$t("__.ts.necessarioSelec")} ${produto.produtoDescricao}.`,
                );
            }
            if (this.gridData.some(s => s.dataValidade == null)) {
                const produto = this.gridData.find(x => x.dataValidade == null);
                return await this.$showError(
                    this.$t("__.ts.erro"),
                    `${this.$t("__.ts.necessarioSelecData")} ${produto.produtoDescricao}.`,
                );
            }

            await this.service
                .adicionarCotacaoFornecedor(this.gridData, this.key)
                .withLoading()
                .then(async response => {
                    if (response.ok) {
                        await this.$showInclusaoUpdate(this.model.id);
                        this.$router.back();
                    } else {
                        const responseErro = (await response.json()) as ValidationErrorModel[];

                        await this.$showError(this.$t("__.ts.erro"), responseErro[0].field);
                        return this.$router.back();
                    }
                });
        } catch {}
    }

    private cancel() {
        this.$router.back();
    }

    private async mounted() {
        if (this.$route.params.key) {
            this.key = this.$route.params.key;
            this.load();
        }
    }
}
