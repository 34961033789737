var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "fast-action-grid-panel" },
    [
      _c("div", { staticClass: "fast-action-grid-panel-left marcaDagua" }, [
        _c(
          "div",
          { staticClass: "component-body" },
          [
            _c("div", { staticClass: "component-body-info top-infos" }, [
              _c("span", { staticClass: "spanFinanceiro spanFinanceiro1" }, [
                _c("i", { staticClass: "fa fa-money-bill" }),
                _vm._v(
                  "\n                    " +
                    _vm._s(
                      _vm.$t(
                        "__.Components.parent.home.fastaction.orcamentos.orcamentos_vue_html.orcadosHoje"
                      )
                    ) +
                    "\n                    "
                ),
                _c("b", [
                  _vm._v(_vm._s(_vm._f("filterMoeda")(_vm.totalOrcadosHoje))),
                ]),
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "spanFinanceiro spanFinanceiro2" }, [
                _c("i", { staticClass: "fa fa-money-bill" }),
                _vm._v(
                  "\n                    " +
                    _vm._s(
                      _vm.$t(
                        "__.Components.parent.home.fastaction.orcamentos.orcamentos_vue_html.orcamentosCriadosHj"
                      )
                    ) +
                    "\n                    "
                ),
                _c("b", [_vm._v(_vm._s(_vm.totalOrcamentosCriadosHoje))]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row", attrs: { id: "btnHoverBlock" } }, [
              _c(
                "div",
                {
                  staticClass: "col-sm-4",
                  staticStyle: { "padding-left": "15px", "padding-right": "0" },
                },
                [
                  _c("a", { staticClass: "btnHomeFinanceiro" }, [
                    _vm._m(0),
                    _vm._v(
                      "\n                        " +
                        _vm._s(
                          _vm.$t(
                            "__.Components.parent.home.fastaction.orcamentos.orcamentos_vue_html.orcamentoVenc"
                          )
                        ) +
                        "\n                    "
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-sm-4", staticStyle: { padding: "0" } },
                [
                  _c("a", { staticClass: "btnHomeFinanceiro" }, [
                    _vm._m(1),
                    _vm._v(
                      "\n                        " +
                        _vm._s(
                          _vm.$t(
                            "__.Components.parent.home.fastaction.orcamentos.orcamentos_vue_html.orcamentoAVenc"
                          )
                        ) +
                        "\n                    "
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "col-sm-4",
                  staticStyle: { "padding-left": "0", "padding-right": "15px" },
                },
                [
                  _c(
                    "a",
                    {
                      staticClass: "btnHomeFinanceiro component-body-row-foot",
                    },
                    [
                      _vm._m(2),
                      _vm._v(
                        "\n                        " +
                          _vm._s(
                            _vm.$t(
                              "__.Components.parent.home.fastaction.orcamentos.orcamentos_vue_html.orcamentoEmDia"
                            )
                          ) +
                          "\n                    "
                      ),
                    ]
                  ),
                ]
              ),
            ]),
            _vm._v(" "),
            _vm._l(_vm.orcamentos, function (orcamento) {
              return _c(
                "a",
                {
                  key: orcamento.id,
                  staticClass: "list-group-item",
                  staticStyle: { "padding-left": "46px" },
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: { cursor: "pointer" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          _vm.onSearch(orcamento)
                        },
                      },
                    },
                    [
                      _c("i", {
                        staticClass: "fa fa-exclamation-circle",
                        class: _vm.getClass(orcamento),
                        attrs: { title: _vm.getTitle(orcamento) },
                      }),
                      _vm._v(
                        "\n                    " +
                          _vm._s(
                            _vm.$t(
                              "__.Components.parent.home.fastaction.orcamentos.orcamentos_vue_html.cod"
                            )
                          ) +
                          "\n                    "
                      ),
                      _c("b", { staticClass: "color-yellow" }, [
                        _vm._v(_vm._s(orcamento.codigo)),
                      ]),
                      _vm._v("\n                    /\n                    "),
                      _c("b", { staticClass: "color-yellow" }, [
                        _vm._v(
                          _vm._s(_vm.getFirstName(orcamento.clienteNome, false))
                        ),
                      ]),
                      _vm._v(
                        "\n                    " +
                          _vm._s(
                            _vm.$t(
                              "__.Components.parent.home.fastaction.orcamentos.orcamentos_vue_html.vencOrcamento"
                            )
                          ) +
                          "\n                    "
                      ),
                      _c("b", { staticClass: "color-yellow" }, [
                        _vm._v(
                          _vm._s(
                            _vm.formatDate(orcamento.dataValidadeOrcamento)
                          )
                        ),
                      ]),
                      _vm._v("\n                    /\n                    "),
                      _c("b", { staticClass: "valores color-yellow" }, [
                        _vm._v(
                          _vm._s(_vm._f("filterMoeda")(orcamento.valorFinal))
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "balaoAtivos" },
                        [
                          _c("i", { staticClass: "fa fa-caret-up" }),
                          _vm._v(" "),
                          _c("b", [
                            _vm._v(
                              "\n                            " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Components.parent.home.fastaction.orcamentos.orcamentos_vue_html.ativos"
                                  )
                                ) +
                                "\n                        "
                            ),
                          ]),
                          _vm._v(" "),
                          _vm._l(orcamento.itens, function (itens) {
                            return _c("div", { key: itens.id }, [
                              _vm._v("- " + _vm._s(itens.produtoDescricao)),
                            ])
                          }),
                        ],
                        2
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { attrs: { id: "container-icon" } }, [
                    _c("div", { staticClass: "container-home-icon" }, [
                      _vm.validarVisibilidadeBotao("ImprimirOrcamento")
                        ? _c(
                            "div",
                            {
                              staticClass: "home-icon bg-secondary",
                              attrs: {
                                "data-toggle": "tooltip",
                                title: "Imprimir Or\u00E7amento",
                                "data-placement": "left",
                              },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  _vm.onImprimirOrcamento(orcamento)
                                },
                              },
                            },
                            [
                              _c("i", {
                                staticClass: "fa fa-print",
                                attrs: { "aria-hidden": "true" },
                              }),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.validarVisibilidadeBotao("ArquivarVenda")
                        ? _c(
                            "div",
                            {
                              staticClass: "home-icon bg-orange",
                              attrs: {
                                "data-toggle": "tooltip",
                                title: _vm.$t(
                                  "__.Components.parent.home.fastaction.orcamentos.orcamentos_vue_html.arquivarRejeiOrcamento"
                                ),
                                "data-placement": "left",
                              },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  _vm.onArquivar(orcamento)
                                },
                              },
                            },
                            [
                              _c("i", {
                                staticClass: "fa fa-archive",
                                attrs: { "aria-hidden": "true" },
                              }),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.validarVisibilidadeBotao("AdiarVenda")
                        ? _c(
                            "div",
                            {
                              staticClass: "home-icon bg-dark-red",
                              attrs: {
                                "data-toggle": "tooltip",
                                title: _vm.$t(
                                  "__.Components.parent.home.fastaction.orcamentos.orcamentos_vue_html.adiarValDesteOrcamento"
                                ),
                                "data-placement": "left",
                              },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  _vm.onAdiar(orcamento)
                                },
                              },
                            },
                            [
                              _c("i", {
                                staticClass: "fa fa-clock",
                                attrs: { "aria-hidden": "true" },
                              }),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "home-icon bg-green",
                          attrs: {
                            "data-toggle": "tooltip",
                            title: _vm.$t(
                              "__.Components.parent.home.fastaction.orcamentos.orcamentos_vue_html.compartilharOrcamento"
                            ),
                            "data-placement": "left",
                          },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              _vm.onCompartilharOrcamento(orcamento)
                            },
                          },
                        },
                        [
                          _c("i", {
                            staticClass: "fab fa-whatsapp",
                            attrs: { "aria-hidden": "true" },
                          }),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "home-icon bg-blue",
                          attrs: {
                            "data-toggle": "tooltip",
                            title: _vm.$t(
                              "__.Components.parent.home.fastaction.orcamentos.orcamentos_vue_html.visuOrcamento"
                            ),
                            "data-placement": "left",
                          },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              _vm.onSearch(orcamento)
                            },
                          },
                        },
                        [
                          _c("i", {
                            staticClass: "fa fa-eye",
                            attrs: { "aria-hidden": "true" },
                          }),
                        ]
                      ),
                      _vm._v(" "),
                      _vm.validarVisibilidadeBotao("AprovarOrcamento")
                        ? _c(
                            "div",
                            {
                              staticClass: "home-icon bg-green",
                              attrs: {
                                "data-toggle": "tooltip",
                                title: _vm.$t(
                                  "__.Components.parent.home.fastaction.orcamentos.orcamentos_vue_html.aprovOrcamento"
                                ),
                                "data-placement": "left",
                              },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  _vm.onAprovarOrcamento(orcamento)
                                },
                              },
                            },
                            [
                              _c("i", {
                                staticClass: "fa fa-check",
                                attrs: { "aria-hidden": "true" },
                              }),
                            ]
                          )
                        : _vm._e(),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        attrs: {
                          id: "icon-button",
                          "data-toggle": "tooltip",
                          "data-placement": "left",
                          "data-original-title": "Create",
                        },
                      },
                      [
                        _c("p", { staticClass: "plus" }, [_vm._v("+")]),
                        _vm._v(" "),
                        _c("div", { staticClass: "edit" }, [
                          _c(
                            "p",
                            {
                              staticClass: "icon",
                              on: {
                                click: function ($event) {
                                  _vm.dragAndDrop = true
                                },
                              },
                            },
                            [
                              _c("i", {
                                staticClass: "fa fa-question",
                                attrs: { "aria-hidden": "true" },
                              }),
                            ]
                          ),
                        ]),
                      ]
                    ),
                  ]),
                ]
              )
            }),
          ],
          2
        ),
        _vm._v(" "),
        _c(
          "a",
          {
            staticClass: "ver-mais list-group-item",
            attrs: { type: "button" },
            on: { click: _vm.onVerMais },
          },
          [
            _vm._v(
              "\n            " +
                _vm._s(
                  _vm.$t(
                    "__.Components.parent.home.fastaction.orcamentos.orcamentos_vue_html.verMais"
                  )
                ) +
                "\n        "
            ),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "fast-action-grid-panel-right" }, [
        _c("ul", [
          _c("li", [
            _c("a", { on: { click: _vm.onClientesList } }, [
              _c("i", {
                staticClass: "fa fa-address-card fa-5x",
                attrs: { "aria-hidden": "true" },
              }),
              _vm._v(" "),
              _c("span", [
                _vm._v(
                  "\n                        " +
                    _vm._s(
                      _vm.$t(
                        "__.Components.parent.home.fastaction.orcamentos.orcamentos_vue_html.clientes"
                      )
                    ) +
                    "\n                    "
                ),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "li",
            [
              _c("router-link", { attrs: { to: "/orcamento-lista" } }, [
                _c("i", {
                  staticClass: "fa fa-list fa-5x",
                  attrs: { "aria-hidden": "true" },
                }),
                _vm._v(" "),
                _c("span", [
                  _vm._v(
                    "\n                        " +
                      _vm._s(
                        _vm.$t(
                          "__.Components.parent.home.fastaction.orcamentos.orcamentos_vue_html.orcamentos"
                        )
                      ) +
                      "\n                    "
                  ),
                ]),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _vm.VALIDAR_PERMISSAO_USUARIO("vendas", "insert")
            ? _c(
                "li",
                [
                  _c("router-link", { attrs: { to: "/venda-edicao" } }, [
                    _c("i", {
                      staticClass: "fa orcamento-plus",
                      attrs: { "aria-hidden": "true" },
                    }),
                    _vm._v(" "),
                    _c("span", [
                      _vm._v(
                        "\n                        " +
                          _vm._s(
                            _vm.$t(
                              "__.Components.parent.home.fastaction.orcamentos.orcamentos_vue_html.novoOrcamento"
                            )
                          ) +
                          "\n                    "
                      ),
                    ]),
                  ]),
                ],
                1
              )
            : _vm._e(),
        ]),
      ]),
      _vm._v(" "),
      [
        _c(
          "shortcutComponent",
          {
            ref: "shortCutRevisaoCampos",
            attrs: {
              btOkName: _vm.$t(
                "__.Components.parent.home.fastaction.orcamentos.orcamentos_vue_html.confirmarAlte"
              ),
              showAddNew: false,
            },
            on: { "selection-item": _vm.onConfirmRevisao },
          },
          [
            _vm.showRevisao
              ? _c(
                  "div",
                  { attrs: { slot: "component" }, slot: "component" },
                  [
                    _c("revisao-campos-component", {
                      ref: "revisaoCamposComponent",
                      attrs: { clienteId: _vm.model.clienteId },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ]
        ),
        _vm._v(" "),
        _c(
          "shortcutComponent",
          {
            ref: "shortCutOrcamentoList",
            attrs: { modalClass: "bg-modal", showAddNew: false },
            on: { "selection-item": _vm.onConfirmList },
          },
          [
            _vm.showOrcamentoList
              ? _c(
                  "div",
                  { attrs: { slot: "component" }, slot: "component" },
                  [
                    _c("orcamento-list-component", {
                      ref: "orcamentoListComponent",
                      attrs: {
                        CalledByShortCut: true,
                        pgSize: 10,
                        sortKey: "datavalidadeorcamento",
                        sortOrder: "asc",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ]
        ),
        _vm._v(" "),
        _c(
          "shortcutComponent",
          {
            ref: "shortCutClientesList",
            attrs: {
              modalClass: "bg-modal",
              btOkName: "Ok",
              showAddNew: false,
            },
            on: { "selection-item": _vm.onConfirmClientesList },
          },
          [
            _vm.showClientesList
              ? _c(
                  "div",
                  { attrs: { slot: "component" }, slot: "component" },
                  [
                    _c("clientes-list-component", {
                      ref: "clientesListComponent",
                      attrs: { CalledByShortCut: true },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ]
        ),
        _vm._v(" "),
        _c("impressaoComponent", { ref: "impressaoComponent" }),
        _vm._v(" "),
        _c("arquivar-orcamento-component", {
          ref: "arquivarOrcamentoComponent",
          on: { "orcamento-arquivado": _vm.onOrcamentoArquivado },
        }),
      ],
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "i",
      { staticClass: "fa credit-card2", staticStyle: { position: "relative" } },
      [
        _c("i", {
          staticClass:
            "fa fa-exclamation-circle component-body-row-icon color-light-red",
        }),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "i",
      { staticClass: "fa credit-card2", staticStyle: { position: "relative" } },
      [
        _c("i", {
          staticClass:
            "fa fa-exclamation-circle component-body-row-icon color-yellow",
        }),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "i",
      { staticClass: "fa credit-card2", staticStyle: { position: "relative" } },
      [
        _c("i", {
          staticClass:
            "fa fa-exclamation-circle component-body-row-icon color-light-green",
        }),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }