import { getPaginationParams } from "@/utils/common/http";

import ConfiguracaoIntegracaoModel from "../models/configuracaoIntegracaoModel";
import FranquiaModel from "../models/franquiaModel";
import nfetch from "../utils/events/nfetch";

import { IService } from "./base/iService";

export default class FranquiaService extends IService<FranquiaModel> {
    constructor() {
        super("Franquia");
    }

    public listFranquiasRede(ignoreCurrentFranquia = false, franquiasAbrangencia = false): Promise<Response> {
        return nfetch(
            `/${this.controllerName}/ListFranquiasForRede?ignoreCurrentFranquia=${ignoreCurrentFranquia}&franquiasAbrangencia=${franquiasAbrangencia}`,
            {
                credentials: "same-origin",
            },
        );
    }

    //id eh a franquia selecionada e base eh de onde busca os dados para esta franquai
    public replicar(id: number, franquiaBaseId = 1) {
        return nfetch(`/${this.controllerName}/Replicar?franquiaId=${id}&franquiaBaseId=${franquiaBaseId}`, {
            method: "post",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json; charset=UTF-8",
                "XSRF-TOKEN": this.getCookie("XSRF-TOKEN"),
            },
        });
    }

    public excluirDadosFranquia(franquiaId: number): Promise<Response> {
        return nfetch(`/${this.controllerName}/ExcluirDadosFranquia?franquiaId=${franquiaId}`, {
            method: "post",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json; charset=UTF-8",
                "XSRF-TOKEN": this.getCookie("XSRF-TOKEN"),
            },
        });
    }

    public getIntegracoesFranquia(franquiaId: number): Promise<Response> {
        return nfetch(`/${this.controllerName}/GetIntegracoesFranquia?franquiaId=${franquiaId}`, {
            credentials: "same-origin",
        });
    }

    public updateIntegracao(model: ConfiguracaoIntegracaoModel): Promise<Response> {
        //@ts-ignore
        return nfetch(`/${this.controllerName}/UpdateIntegrador`, {
            method: "post",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json; charset=UTF-8",
                "XSRF-TOKEN": this.getCookie("XSRF-TOKEN"),
            },
            body: JSON.stringify(model),
        })
            .then((response: Response) => {
                if (response.ok) {
                    return response;
                } else {
                    return this.mensagemErro(response, "Save");
                }
            })
            .catch(() => {});
    }

    public listFranquiasFiliais(
        filterKey: string,
        sortKey: string,
        sortOrder: string,
        pageIndex: number,
        pageSize: number,
        showFiliais = false,
    ): Promise<Response> {
        const pagination = getPaginationParams(filterKey, sortKey, sortOrder, pageIndex, pageSize);

        return nfetch(`/${this.controllerName}/ListFranquiasFiliais?${pagination}&showFiliais=${showFiliais}`, {
            credentials: "same-origin",
        });
    }
}
