import ConfiguracaoWhatsAppSituacao from "@/models/enum/configuracaoWhatsAppSituacao";
import ConfiguracaoWhatsAppModel from "@/models/whatsApp/configuracaoWhatsAppModel";

import nfetch from "../../utils/events/nfetch";
import { IService } from "../base/iService";

export default class ConfiguracaoWhatsAppService extends IService<ConfiguracaoWhatsAppModel> {
    constructor() {
        super("ConfiguracaoWhatsApp");
    }

    public async getTokenAtendimento(): Promise<void> {
        try {
            const tokenAtendimento = await nfetch(`/${this.controllerName}/GetTokenAtendimento`, {
                credentials: "same-origin",
            }).resolveWithText();

            localStorage.setItem("@PharmUP:AtendimentoToken", tokenAtendimento);
        } catch {}
    }

    public getByFranquia(): Promise<Response> {
        const tokenAtendimento = localStorage.getItem("@PharmUP:AtendimentoToken");
        return fetch(`${process.env.VUE_APP_ROOT_ATENDIMENTO_API}/Instancia/GetByFranquia`, {
            credentials: "same-origin",
            headers: {
                Authorization: `Bearer ${tokenAtendimento}`,
            },
        });
    }

    public async getStatusIntegracao(): Promise<ConfiguracaoWhatsAppSituacao> {
        const config = await this.getByFranquia()
            .withLoading()
            .resolveWithJSON<ConfiguracaoWhatsAppModel>(false, false);

        return config != null ? config.situacao : ConfiguracaoWhatsAppSituacao.Desconectado;
    }

    public async iniciarConfiguracao(): Promise<Response> {
        const tokenAtendimento = localStorage.getItem("@PharmUP:AtendimentoToken");
        return fetch(`${process.env.VUE_APP_ROOT_ATENDIMENTO_API}/Instancia/IniciarConfiguracao`, {
            method: "post",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json; charset=UTF-8",
                Authorization: `Bearer ${tokenAtendimento}`,
            },
        });
    }

    public async atualizarStatusIntegracao(): Promise<Response> {
        const tokenAtendimento = localStorage.getItem("@PharmUP:AtendimentoToken");
        return fetch(`${process.env.VUE_APP_ROOT_ATENDIMENTO_API}/Instancia/AtualizarStatusIntegracao`, {
            method: "post",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json; charset=UTF-8",
                Authorization: `Bearer ${tokenAtendimento}`,
            },
        });
    }

    public async definirWebhook(): Promise<Response> {
        const tokenAtendimento = localStorage.getItem("@PharmUP:AtendimentoToken");
        return fetch(`${process.env.VUE_APP_ROOT_ATENDIMENTO_API}/Instancia/DefinirWebhook`, {
            method: "post",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json; charset=UTF-8",
                Authorization: `Bearer ${tokenAtendimento}`,
            },
        });
    }

    public async desconectarIntegracao(): Promise<Response> {
        const tokenAtendimento = localStorage.getItem("@PharmUP:AtendimentoToken");
        return fetch(`${process.env.VUE_APP_ROOT_ATENDIMENTO_API}/Instancia/DesconectarIntegracao`, {
            method: "delete",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json; charset=UTF-8",
                Authorization: `Bearer ${tokenAtendimento}`,
            },
        });
    }
}
