var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "crud crud-list" },
    [
      _vm.isFracionamento
        ? _c(
            "div",
            [
              _c("div", { staticClass: "info-div" }, [
                _c("h1", { staticClass: "title" }, [
                  _vm._v(
                    _vm._s(
                      _vm.$t("__.Crud.separacao.list_vue_html.fracionamento")
                    )
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "content" }, [
                  _c("div", { staticClass: "info-panel" }, [
                    _c("span", [_vm._v(_vm._s(_vm.total))]),
                    _vm._v(" "),
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "__.Crud.separacao.list_vue_html.fracionamento"
                          )
                        )
                      ),
                    ]),
                    _vm._v(" "),
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.$t("__.Crud.separacao.list_vue_html.cadastrados")
                        )
                      ),
                    ]),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "grid-component",
                {
                  ref: "gridcomponent",
                  attrs: {
                    data: _vm.gridData,
                    tituloPagina: "Vendas",
                    columns: _vm.gridColumns,
                    "page-index": _vm.pageIndex,
                    "page-size": _vm.pageSize,
                    total: _vm.total,
                    startFilterKey: _vm.startFilterKey,
                    showEditItem: false,
                    showRemoveItem: false,
                    extraActions: _vm.extraActions,
                  },
                  on: {
                    "gerar-xls": function ($event) {
                      _vm.pags = true
                      _vm.tipoDocGerar = 0
                    },
                    "gerar-pdf": function ($event) {
                      _vm.pags = true
                      _vm.tipoDocGerar = 1
                    },
                    "gerar-impressao": function ($event) {
                      _vm.pags = true
                      _vm.tipoDocGerar = 2
                    },
                    "change-filter-key": _vm.onChangeFilterKey,
                    "change-sort": _vm.onChangeSort,
                    "change-page": _vm.onChangePage,
                    "create-item": _vm.onCreateItem,
                    "extra-action": _vm.onExtraAction,
                  },
                },
                [
                  _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                    _c("div", { staticClass: "filtro-lista" }, [
                      _c(
                        "button",
                        {
                          staticClass: "filtro-avancado-button",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              _vm.isHidden = !_vm.isHidden
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(_vm.$t("__.Crud.filtroAvancado")) +
                              "\n                        "
                          ),
                          _c("i", { staticClass: "fa fa-caret-down" }),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.isHidden,
                              expression: "isHidden",
                            },
                          ],
                          staticClass: "drop-lista",
                        },
                        [
                          _c(
                            "form",
                            {
                              attrs: { novalidate: "novalidate" },
                              on: {
                                submit: function ($event) {
                                  $event.preventDefault()
                                  return _vm.load($event)
                                },
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "radio-group",
                                  staticStyle: { "margin-bottom": "15px" },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticStyle: { display: "table" } },
                                    [
                                      _c(
                                        "div",
                                        [
                                          _c(
                                            "label",
                                            {
                                              staticClass: "control-label",
                                              attrs: { for: "setor" },
                                            },
                                            [
                                              _vm._v(
                                                "\n                                            " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "__.Crud.separacao.list_vue_html.setor"
                                                    )
                                                  ) +
                                                  "\n                                        "
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c("combo-component", {
                                            staticStyle: {
                                              background: "white",
                                            },
                                            attrs: {
                                              id: "setor",
                                              name: "setor",
                                              data: _vm.setorOptions,
                                            },
                                            model: {
                                              value: _vm.setor,
                                              callback: function ($$v) {
                                                _vm.setor = $$v
                                              },
                                              expression: "setor",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "form-group" }, [
                                _c(
                                  "button",
                                  {
                                    staticClass: "botao-filtrar",
                                    attrs: { type: "submit" },
                                  },
                                  [
                                    _vm._v(
                                      "\n                                    " +
                                        _vm._s(_vm.$t("__.Crud.filtrar")) +
                                        "\n                                "
                                    ),
                                  ]
                                ),
                              ]),
                            ]
                          ),
                        ]
                      ),
                    ]),
                  ]),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("solicitarSenhaComponent", {
        ref: "solicitarSenhaComponent",
        attrs: { title: _vm.$t("__.ts.necSenhaParaSepararTodosItens") },
        on: { onResult: _vm.onResult },
      }),
      _vm._v(" "),
      _c("impressaoComponent", { ref: "impressaoComponent" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }