import checkboxComponent from "@/components/child/form/checkbox.vue";
import decimalComponent from "@/components/child/form/decimal.vue";
import { Component } from "@/decorators";
import RecompensaModel from "@/models/programafidelidade/recompensaModel";
import RecompensaService from "@/services/recompensaService";

import EditComponentBase from "../editComponentBase";

@Component({
    components: {
        decimalComponent,
        checkboxComponent,
    },
})
export default class RecompensaEditComponent extends EditComponentBase<RecompensaModel> {
    private async beforeMount() {
        await this.setProps(new RecompensaService(), "recompensas", new RecompensaModel());
    }
}
