var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "row" },
    [
      _c(
        "div",
        { staticClass: "col-sm-12", staticStyle: { "padding-left": "0" } },
        [
          _vm.validarPermissaoBotaoVenda("manipulacao")
            ? _c("buttonScComponent", {
                staticStyle: { "margin-right": "3px" },
                attrs: {
                  classButton: "btn btn-primary",
                  classIcon: "fa fa-plus-circle",
                  buttonName: _vm.$t(
                    "__.Components.parent.venda.itemwrapper_vue_html.manipulacao"
                  ),
                  shortKeyBt: _vm.isActiveTab && _vm.inFocus() ? "m" : null,
                },
                on: { click: _vm.onManipulacao },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.validarPermissaoBotaoVenda("acabado")
            ? _c("buttonScComponent", {
                staticStyle: { "margin-right": "3px" },
                attrs: {
                  classButton: "btn btn-primary",
                  classIcon: "fa fa-plus-circle",
                  buttonName: _vm.$t(
                    "__.Components.parent.venda.itemwrapper_vue_html.acabado"
                  ),
                  shortKeyBt: _vm.isActiveTab && _vm.inFocus() ? "a" : null,
                },
                on: { click: _vm.onProduto },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.validarPermissaoBotaoVenda("fracionamento")
            ? _c("buttonScComponent", {
                staticStyle: { "margin-right": "3px" },
                attrs: {
                  classButton: "btn btn-primary",
                  classIcon: "fa fa-plus-circle",
                  buttonName: _vm.$t(
                    "__.Components.parent.venda.itemwrapper_vue_html.fracionamento"
                  ),
                  shortKeyBt: _vm.isActiveTab && _vm.inFocus() ? "r" : null,
                },
                on: { click: _vm.onFracionar },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.validarPermissaoBotaoVenda("homeopatia")
            ? _c("buttonScComponent", {
                staticStyle: { "margin-right": "3px" },
                attrs: {
                  classButton: "btn btn-primary",
                  classIcon: "fa fa-plus-circle",
                  buttonName: _vm.$t(
                    "__.Components.parent.venda.itemwrapper_vue_html.homeopatia"
                  ),
                  shortKeyBt: _vm.isActiveTab && _vm.inFocus() ? "h" : null,
                },
                on: {
                  click: function ($event) {
                    _vm.onManipulacao(true)
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "shortcutComponent",
        {
          ref: "shortcutComponent",
          attrs: {
            showBtnOk: false,
            extraButtons: _vm.extraButtonAction,
            modalClass: _vm.classSC,
          },
          on: {
            "extra-button-click": _vm.onExtraButtonActionClick,
            "hide-click": _vm.onCancelar,
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "bg-white",
              attrs: { slot: "component" },
              slot: "component",
            },
            [
              _c("div", { staticClass: "row" }, [
                _vm.isManipulacao
                  ? _c(
                      "div",
                      {
                        staticClass: "col-sm-12 ajustaManipulacao",
                        staticStyle: { "padding-top": "20px" },
                      },
                      [
                        _c(
                          "keep-alive",
                          [
                            _c("manipulacaoEditComponent", {
                              ref: "manipulacaoEditComponent",
                              attrs: {
                                CalledByShortCut: true,
                                estoqueId: _vm.estoqueId,
                                indexVenda: _vm.indexVenda,
                                itemVenda: true,
                                itensVenda: _vm.itensVenda,
                                manipulacaoId: _vm.model.manipulacaoOrdemId,
                                nomeVendedor: _vm.nomeVendedor,
                                situacaoVenda: _vm.situacaoVenda,
                                tipoVenda: _vm.tipoVenda,
                                vendaFaturada: _vm.vendaFaturada,
                                vendaReplicada: _vm.vendaReplicada,
                              },
                              on: {
                                "atualiza-total-item":
                                  _vm.onAtualizaTotalItemManipulacao,
                                "inclusao-item": _vm.onManipulacaoInsert,
                                "manipulacao-mounted": _vm.onManipulacaoMounted,
                                onChangeMovimentaEstoque:
                                  _vm.onChangeMovimentaEstoque,
                                onLoadPreVenda: _vm.onLoadPreVenda,
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.isVendaItem
                  ? _c(
                      "div",
                      {
                        staticClass: "col-sm-12",
                        staticStyle: { "padding-top": "15px" },
                      },
                      [
                        _c(
                          "keep-alive",
                          [
                            _vm.isVendaItem
                              ? _c("itemComponent", {
                                  ref: "vendaItemComponent",
                                  attrs: {
                                    clienteId: _vm.clienteId,
                                    estoqueId: _vm.estoqueId,
                                    pacienteIdApp: _vm.pacienteId,
                                    tipoVenda: _vm.tipoVenda,
                                    vendaFaturada: _vm.vendaFaturada,
                                  },
                                  on: {
                                    "atualiza-total-item":
                                      _vm.onAtualizaTotalItemProduto,
                                    "edicao-item": _vm.onVendaItemEdit,
                                    "inclusao-item": _vm.onVendaItemInsert,
                                    onChangeMovimentaEstoque:
                                      _vm.onChangeMovimentaEstoque,
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.isVendaEstoque
                  ? _c(
                      "div",
                      {
                        staticClass: "col-sm-12",
                        staticStyle: { "padding-top": "40px" },
                      },
                      [
                        _c("vendaEstoqueComponent", {
                          ref: "vendaEstoqueComponent",
                          attrs: {
                            estoqueId: _vm.estoqueId,
                            fracionamentoId: _vm.model.fracionamentoId,
                            itensVenda: _vm.itensVenda,
                            situacaoVenda: _vm.situacaoVenda,
                            tipoVenda: _vm.tipoVenda,
                            vendaId: _vm.model.vendaId,
                            vendaItemId: _vm.model.id,
                          },
                          on: {
                            "atualiza-total-item":
                              _vm.onAtualizaTotalItemProduto,
                            "desativa-campos": _vm.onDesativaCampos,
                            "edicao-item": _vm.onVendaItemEdit,
                            "inclusao-item": _vm.onVendaItemInsert,
                            onChangeMovimentaEstoque:
                              _vm.onChangeMovimentaEstoque,
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-sm-12" },
                  [
                    !_vm.desativaCampos
                      ? _c(
                          "fieldset-component",
                          {
                            class: { "col-sm-12": !_vm.isManipulacao },
                            attrs: {
                              title: _vm.$t(
                                "__.Components.parent.venda.itemwrapper_vue_html.infoAdic"
                              ),
                              collapsed: true,
                            },
                          },
                          [
                            _c(
                              "div",
                              { attrs: { slot: "rows" }, slot: "rows" },
                              [
                                _c("div", { staticClass: "row" }, [
                                  _c("div", { staticClass: "col-sm-3" }, [
                                    _c(
                                      "div",
                                      { staticClass: "form-group" },
                                      [
                                        _c(
                                          "label",
                                          { attrs: { for: "tipoNF" } },
                                          [
                                            _vm._v(
                                              "\n                                            " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "__.Components.parent.venda.itemwrapper_vue_html.tipoNF"
                                                  )
                                                ) +
                                                "\n                                        "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("combo-component", {
                                          attrs: {
                                            id: "tipoNF",
                                            name: "tipoNF",
                                            data: _vm.tipoNFOptions,
                                          },
                                          model: {
                                            value: _vm.model.tipoNF,
                                            callback: function ($$v) {
                                              _vm.$set(_vm.model, "tipoNF", $$v)
                                            },
                                            expression: "model.tipoNF",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "col-sm-3" }, [
                                    _c(
                                      "div",
                                      { staticClass: "form-group" },
                                      [
                                        _c(
                                          "label",
                                          {
                                            attrs: { for: "movimentaEstoque" },
                                          },
                                          [
                                            _vm._v(
                                              "\n                                            " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "__.Components.parent.venda.itemwrapper_vue_html.movimentaEstq"
                                                  )
                                                ) +
                                                "\n                                        "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("checkbox-component", {
                                          attrs: {
                                            id: "movimentaEstoque",
                                            name: "movimentaEstoque",
                                          },
                                          model: {
                                            value: _vm.model.movimentaEstoque,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.model,
                                                "movimentaEstoque",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "model.movimentaEstoque",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]),
                                ]),
                              ]
                            ),
                          ]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
            ]
          ),
          _vm._v(" "),
          _c("div", { attrs: { slot: "footerRight" }, slot: "footerRight" }, [
            _c("span", { staticClass: "valor-total" }, [
              _c("i", { staticClass: "fa fa-money-bill" }),
              _vm._v(
                "\n                " +
                  _vm._s(
                    _vm.$t(
                      "__.Components.parent.venda.itemwrapper_vue_html.total"
                    )
                  ) +
                  "\n                "
              ),
              _c("b", [_vm._v(_vm._s(_vm._f("filterMoeda")(_vm.total)))]),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }