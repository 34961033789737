export default class EnumExtensions {
    public static getNamesAndValuesOrderedByNames<T extends number>(e: any) {
        const values = EnumExtensions.getNames(e).map(n => ({ text: n, value: e[n] as T }));

        return values.sort(function (objectA, objectB) {
            if (objectA.text < objectB.text) return -1;
            if (objectA.text > objectB.text) return 1;
            return 0;
        });
    }

    public static getNamesAndValuesOrderedByValues<T extends number>(e: any) {
        const values = EnumExtensions.getNames(e).map(n => ({ text: n, value: e[n] as T }));

        return values.sort(function (objectA, objectB) {
            if (objectA.value < objectB.value) return -1;
            if (objectA.value > objectB.value) return 1;
            return 0;
        });
    }
    public static getNameByValue<T extends number>(e: any, value: number) {
        return EnumExtensions.getNames(e)
            .map(n => ({ text: n, value: e[n] as T }))
            .filter(p => p.value == value)
            .map(n => n.text)[0];
    }
    public static getNames(e: any) {
        return EnumExtensions.getObjValues(e).filter(v => typeof v === "string") as string[];
    }

    public static getValues<T extends number>(e: any) {
        return EnumExtensions.getObjValues(e).filter(v => typeof v === "number") as T[];
    }

    private static getObjValues(e: any): (number | string)[] {
        return Object.keys(e).map(k => e[k]);
    }
}
