var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "fieldset-component",
    { attrs: { title: "Volumes", collapsed: true } },
    [
      _c(
        "div",
        { attrs: { slot: "rows" }, slot: "rows" },
        [
          !_vm.consulta
            ? _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-sm-4" }, [
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c(
                        "label",
                        {
                          staticClass: "control-label",
                          attrs: { for: "quantidadeVolume" },
                        },
                        [_vm._v("Quantidade")]
                      ),
                      _vm._v(" "),
                      _c("decimal-component", {
                        staticClass: "form-control",
                        attrs: {
                          id: "quantidadeVolume",
                          name: "quantidadeVolume",
                        },
                        model: {
                          value: _vm.model.quantidade,
                          callback: function ($$v) {
                            _vm.$set(_vm.model, "quantidade", $$v)
                          },
                          expression: "model.quantidade",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-sm-4" }, [
                  _c("div", { staticClass: "form-group" }, [
                    _c(
                      "label",
                      {
                        staticClass: "control-label",
                        attrs: { for: "especie" },
                      },
                      [_vm._v("Especie do volume")]
                    ),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.model.especie,
                          expression: "model.especie",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { type: "text", id: "especie", name: "especie" },
                      domProps: { value: _vm.model.especie },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.model, "especie", $event.target.value)
                        },
                      },
                    }),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-sm-4" }, [
                  _c("div", { staticClass: "form-group" }, [
                    _c(
                      "label",
                      { staticClass: "control-label", attrs: { for: "marca" } },
                      [_vm._v("Marca do volume")]
                    ),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.model.marca,
                          expression: "model.marca",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { type: "text", id: "marca", name: "marca" },
                      domProps: { value: _vm.model.marca },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.model, "marca", $event.target.value)
                        },
                      },
                    }),
                  ]),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          !_vm.consulta
            ? _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-sm-4" }, [
                  _c("div", { staticClass: "form-group" }, [
                    _c(
                      "label",
                      {
                        staticClass: "control-label",
                        attrs: { for: "numeracao" },
                      },
                      [_vm._v("Número do volume")]
                    ),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.model.numeracao,
                          expression: "model.numeracao",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: {
                        type: "text",
                        id: "numeracao",
                        name: "numeracao",
                      },
                      domProps: { value: _vm.model.numeracao },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.model, "numeracao", $event.target.value)
                        },
                      },
                    }),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-sm-4" }, [
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c(
                        "label",
                        {
                          staticClass: "control-label",
                          attrs: { for: "pesoLiquido" },
                        },
                        [_vm._v("Peso Líquido")]
                      ),
                      _vm._v(" "),
                      _c("decimal-component", {
                        staticClass: "form-control",
                        attrs: { id: "pesoLiquido", name: "pesoLiquido" },
                        model: {
                          value: _vm.model.pesoLiquido,
                          callback: function ($$v) {
                            _vm.$set(_vm.model, "pesoLiquido", $$v)
                          },
                          expression: "model.pesoLiquido",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-sm-4" }, [
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c(
                        "label",
                        {
                          staticClass: "control-label",
                          attrs: { for: "pesoBruto" },
                        },
                        [_vm._v("Peso Bruto")]
                      ),
                      _vm._v(" "),
                      _c("decimal-component", {
                        staticClass: "form-control",
                        attrs: { id: "pesoBruto", name: "pesoBruto" },
                        model: {
                          value: _vm.model.pesoBruto,
                          callback: function ($$v) {
                            _vm.$set(_vm.model, "pesoBruto", $$v)
                          },
                          expression: "model.pesoBruto",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          !_vm.consulta
            ? _c("buttonIncluirComponent", {
                attrs: {
                  alterar: _vm.editingModel,
                  cancelar: _vm.editingModel,
                },
                on: { click: _vm.onAdicionarItem, cancelar: _vm.clear },
              })
            : _vm._e(),
          _vm._v(" "),
          _c("grid-component", {
            attrs: {
              data: _vm.nota.volumes,
              columns: _vm.gridColumns,
              "show-remove-item": !_vm.consulta,
              "show-edit-item": !_vm.consulta,
              showAddNewButton: false,
              "show-table-head": false,
              "show-table-foot": false,
            },
            on: {
              "edit-item": _vm.onEditItem,
              "remove-item": _vm.onRemoveItem,
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }