var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "crud crud-edit" },
    [
      _c("actionBarComponent", {
        attrs: {
          title: _vm.$t(
            "__.Crud.configuracaofranquia.edit_vue_html.configFranquia"
          ),
        },
        on: { onSave: _vm.save },
      }),
      _vm._v(" "),
      _c(
        "form",
        {
          attrs: { novalidate: "novalidate" },
          on: {
            submit: function ($event) {
              $event.preventDefault()
              _vm.save()
            },
          },
        },
        [
          _c("div", { attrs: { slot: "rows" }, slot: "rows" }, [
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col-sm-6" },
                [
                  _c("label", { staticClass: "control-label" }, [
                    _vm._v(
                      "\n                        " +
                        _vm._s(
                          _vm.$t(
                            "__.Crud.configuracaofranquia.edit_vue_html.franquia"
                          )
                        ) +
                        "\n                    "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("combo-component", {
                    attrs: {
                      id: "franquiaId",
                      name: "franquiaId",
                      data: _vm.franquiasOptions,
                      searchable: true,
                    },
                    model: {
                      value: _vm.franquiaId,
                      callback: function ($$v) {
                        _vm.franquiaId = $$v
                      },
                      expression: "franquiaId",
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _vm.configs && _vm.configs.length > 0
            ? _vm._l(
                _vm.configs
                  .map(function (p) {
                    return p.modulo
                  })
                  .filter(function (value, index, self) {
                    return self.indexOf(value) === index
                  }),
                function (modulo) {
                  return _c(
                    "fieldset-component",
                    {
                      key: modulo,
                      attrs: {
                        title: _vm.getModuloName(modulo),
                        collapsed: false,
                      },
                    },
                    [
                      _c("div", { attrs: { slot: "rows" }, slot: "rows" }, [
                        _c(
                          "div",
                          { staticClass: "col-sm-12" },
                          _vm._l(
                            _vm.configs.filter(function (p) {
                              return p.modulo == modulo
                            }),
                            function (config, indexRow) {
                              return _c(
                                "div",
                                {
                                  key: indexRow,
                                  class: [(indexRow + 1) % 2 == 0 ? "row" : ""],
                                  staticStyle: {
                                    "margin-right": "auto !important",
                                    "margin-left": "auto !important",
                                  },
                                },
                                [
                                  _c("div", { staticClass: "col-sm-6" }, [
                                    _c(
                                      "div",
                                      {
                                        directives: [
                                          {
                                            name: "tooltip",
                                            rawName: "v-tooltip",
                                            value: _vm.errors.first(
                                              config.descricao
                                            ),
                                            expression:
                                              "errors.first(config.descricao)",
                                          },
                                        ],
                                        staticClass: "form-group",
                                      },
                                      [
                                        _c(
                                          "label",
                                          { staticClass: "control-label" },
                                          [_vm._v(_vm._s(config.descricao))]
                                        ),
                                        _vm._v(" "),
                                        config.tipoCampo == _vm.tipoLong &&
                                        config.codigo != 5 &&
                                        config.codigo != 7 &&
                                        config.codigo != 16 &&
                                        config.codigo != 34 &&
                                        config.codigo != 67
                                          ? _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: config.valor,
                                                  expression: "config.valor",
                                                },
                                              ],
                                              staticClass: "form-control",
                                              attrs: {
                                                type: "number",
                                                id: config.codigo.toString(),
                                                name: config.descricao,
                                                autocomplete: "off",
                                              },
                                              domProps: { value: config.valor },
                                              on: {
                                                input: function ($event) {
                                                  if ($event.target.composing) {
                                                    return
                                                  }
                                                  _vm.$set(
                                                    config,
                                                    "valor",
                                                    $event.target.value
                                                  )
                                                },
                                              },
                                            })
                                          : _vm._e(),
                                        _vm._v(" "),
                                        config.tipoCampo == _vm.tipoString
                                          ? _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: config.valorStr,
                                                  expression: "config.valorStr",
                                                },
                                              ],
                                              staticClass: "form-control",
                                              attrs: {
                                                type: "text",
                                                id: config.codigo.toString(),
                                                name: config.descricao,
                                                autocomplete: "off",
                                              },
                                              domProps: {
                                                value: config.valorStr,
                                              },
                                              on: {
                                                input: function ($event) {
                                                  if ($event.target.composing) {
                                                    return
                                                  }
                                                  _vm.$set(
                                                    config,
                                                    "valorStr",
                                                    $event.target.value
                                                  )
                                                },
                                              },
                                            })
                                          : _vm._e(),
                                        _vm._v(" "),
                                        config.tipoCampo == _vm.tipoPercentual
                                          ? _c(
                                              "decimal-com-sufixo-nullable-component",
                                              {
                                                staticClass: "form-control",
                                                attrs: {
                                                  id: config.codigo.toString(),
                                                  name: config.descricao,
                                                  suffix: "%",
                                                },
                                                on: {
                                                  change: function (newValue) {
                                                    return _vm.onPercentualChange(
                                                      newValue,
                                                      config.codigo
                                                    )
                                                  },
                                                },
                                                model: {
                                                  value: config.valorDecimal,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      config,
                                                      "valorDecimal",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "config.valorDecimal",
                                                },
                                              }
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        config.tipoCampo ==
                                        _vm.tipoContaGerencialSaida
                                          ? _c("combo-component", {
                                              attrs: {
                                                id: config.codigo.toString(),
                                                name: config.descricao,
                                                data: _vm.contasGerenciaisSaidaOptions,
                                                searchable: false,
                                              },
                                              model: {
                                                value: config.contaGerencialId,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    config,
                                                    "contaGerencialId",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "config.contaGerencialId",
                                              },
                                            })
                                          : _vm._e(),
                                        _vm._v(" "),
                                        config.tipoCampo ==
                                        _vm.tipoContaGerencialEntrada
                                          ? _c("combo-component", {
                                              attrs: {
                                                id: config.codigo.toString(),
                                                name: config.descricao,
                                                data: _vm.contasGerenciaisEntradaOptions,
                                                searchable: false,
                                              },
                                              model: {
                                                value: config.contaGerencialId,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    config,
                                                    "contaGerencialId",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "config.contaGerencialId",
                                              },
                                            })
                                          : _vm._e(),
                                        _vm._v(" "),
                                        config.codigo == 5
                                          ? _c("combo-component", {
                                              attrs: {
                                                id: "sessaoCaixa",
                                                name: "sessaoCaixa",
                                                data: _vm.sessoesCaixaOptions,
                                                searchable: false,
                                              },
                                              model: {
                                                value: config.valor,
                                                callback: function ($$v) {
                                                  _vm.$set(config, "valor", $$v)
                                                },
                                                expression: "config.valor",
                                              },
                                            })
                                          : _vm._e(),
                                        _vm._v(" "),
                                        config.codigo == 7
                                          ? _c("combo-component", {
                                              attrs: {
                                                id: "ambienteNFe",
                                                name: "ambienteNFe",
                                                data: _vm.ambientesEnvioNFeOptions,
                                                searchable: false,
                                              },
                                              model: {
                                                value: config.valor,
                                                callback: function ($$v) {
                                                  _vm.$set(config, "valor", $$v)
                                                },
                                                expression: "config.valor",
                                              },
                                            })
                                          : _vm._e(),
                                        _vm._v(" "),
                                        config.tipoCampo == _vm.tipoServico
                                          ? _c("combo-component", {
                                              attrs: {
                                                id: config.codigo.toString(),
                                                name: config.descricao,
                                                data: _vm.servicosFiscaisOptions,
                                                searchable: false,
                                              },
                                              model: {
                                                value: config.servicoId,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    config,
                                                    "servicoId",
                                                    $$v
                                                  )
                                                },
                                                expression: "config.servicoId",
                                              },
                                            })
                                          : _vm._e(),
                                        _vm._v(" "),
                                        config.tipoCampo == _vm.tipoEstoque
                                          ? _c("combo-component", {
                                              attrs: {
                                                id: config.codigo.toString(),
                                                name: config.descricao,
                                                data: _vm.estoquesOptions,
                                                searchable: false,
                                              },
                                              model: {
                                                value: config.estoqueId,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    config,
                                                    "estoqueId",
                                                    $$v
                                                  )
                                                },
                                                expression: "config.estoqueId",
                                              },
                                            })
                                          : _vm._e(),
                                        _vm._v(" "),
                                        config.tipoCampo == _vm.tipoBoolean
                                          ? _c("combo-component", {
                                              attrs: {
                                                id: config.codigo.toString(),
                                                name: config.descricao,
                                                data: _vm.verdadeiroOptions,
                                                searchable: false,
                                              },
                                              on: {
                                                select: function (p) {
                                                  return _vm.onBooleanChange(
                                                    p,
                                                    config.codigo
                                                  )
                                                },
                                              },
                                              model: {
                                                value: config.verdadeiroNum,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    config,
                                                    "verdadeiroNum",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "config.verdadeiroNum",
                                              },
                                            })
                                          : _vm._e(),
                                        _vm._v(" "),
                                        config.codigo == 16
                                          ? _c("combo-component", {
                                              attrs: {
                                                id: config.codigo.toString(),
                                                name: config.descricao,
                                                data: _vm.tiposCalculoMarkupOptions,
                                                searchable: false,
                                              },
                                              model: {
                                                value: config.valor,
                                                callback: function ($$v) {
                                                  _vm.$set(config, "valor", $$v)
                                                },
                                                expression: "config.valor",
                                              },
                                            })
                                          : _vm._e(),
                                        _vm._v(" "),
                                        config.codigo == 67
                                          ? _c("combo-component", {
                                              attrs: {
                                                id: config.codigo.toString(),
                                                name: config.descricao,
                                                data: _vm.considerarValidadeDaManipulacaoOptions,
                                                searchable: false,
                                              },
                                              model: {
                                                value: config.valor,
                                                callback: function ($$v) {
                                                  _vm.$set(config, "valor", $$v)
                                                },
                                                expression: "config.valor",
                                              },
                                            })
                                          : _vm._e(),
                                        _vm._v(" "),
                                        config.tipoCampo ==
                                        _vm.tipoContaCorrente
                                          ? _c("combo-component", {
                                              attrs: {
                                                id: config.codigo.toString(),
                                                name: config.descricao,
                                                data: _vm.contasCorrenteOptions,
                                                searchable: false,
                                              },
                                              model: {
                                                value: config.contaCorrenteId,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    config,
                                                    "contaCorrenteId",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "config.contaCorrenteId",
                                              },
                                            })
                                          : _vm._e(),
                                        _vm._v(" "),
                                        config.tipoCampo ==
                                        _vm.naturezaOperacaoDentroEstado
                                          ? _c("combo-component", {
                                              attrs: {
                                                id: config.codigo.toString(),
                                                name: config.descricao,
                                                data: _vm.naturezaOperacaoDentroOptions,
                                                searchable: false,
                                              },
                                              model: {
                                                value:
                                                  config.naturezaOperacaoId,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    config,
                                                    "naturezaOperacaoId",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "config.naturezaOperacaoId",
                                              },
                                            })
                                          : _vm._e(),
                                        _vm._v(" "),
                                        config.tipoCampo ==
                                        _vm.naturezaOperacaoForaEstado
                                          ? _c("combo-component", {
                                              attrs: {
                                                id: config.codigo.toString(),
                                                name: config.descricao,
                                                data: _vm.naturezaOperacaoForaOptions,
                                                searchable: false,
                                              },
                                              model: {
                                                value:
                                                  config.naturezaOperacaoId,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    config,
                                                    "naturezaOperacaoId",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "config.naturezaOperacaoId",
                                              },
                                            })
                                          : _vm._e(),
                                        _vm._v(" "),
                                        config.tipoCampo == _vm.tipoUsuario
                                          ? _c("combo-component", {
                                              attrs: {
                                                id: config.codigo.toString(),
                                                name: config.descricao,
                                                data: _vm.usuarioOptions,
                                                searchable: false,
                                              },
                                              model: {
                                                value: config.usuarioId,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    config,
                                                    "usuarioId",
                                                    $$v
                                                  )
                                                },
                                                expression: "config.usuarioId",
                                              },
                                            })
                                          : _vm._e(),
                                        _vm._v(" "),
                                        config.tipoCampo ==
                                          _vm.configuracaoRotulo &&
                                        config.codigo == 32
                                          ? _c("combo-component", {
                                              attrs: {
                                                id: config.codigo.toString(),
                                                name: config.descricao,
                                                data: _vm.rotulosLoteOptions,
                                                searchable: false,
                                              },
                                              model: {
                                                value: config.rotuloLoteId,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    config,
                                                    "rotuloLoteId",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "config.rotuloLoteId",
                                              },
                                            })
                                          : _vm._e(),
                                        _vm._v(" "),
                                        config.tipoCampo ==
                                          _vm.configuracaoRotulo &&
                                        config.codigo == 33
                                          ? _c("combo-component", {
                                              attrs: {
                                                id: config.codigo.toString(),
                                                name: config.descricao,
                                                data: _vm.rotulosManipulacaoOptions,
                                                searchable: false,
                                              },
                                              model: {
                                                value:
                                                  config.rotuloManipulacaoId,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    config,
                                                    "rotuloManipulacaoId",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "config.rotuloManipulacaoId",
                                              },
                                            })
                                          : _vm._e(),
                                        _vm._v(" "),
                                        config.tipoCampo == _vm.decimal
                                          ? _c("decimal-component", {
                                              staticClass: "form-control",
                                              attrs: {
                                                id: config.codigo.toString(),
                                                name: config.descricao,
                                              },
                                              model: {
                                                value: config.valorDecimal,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    config,
                                                    "valorDecimal",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "config.valorDecimal",
                                              },
                                            })
                                          : _vm._e(),
                                        _vm._v(" "),
                                        config.tipoCampo == _vm.tipoProduto
                                          ? _c("combo-component", {
                                              attrs: {
                                                id: config.codigo.toString(),
                                                name: config.descricao,
                                                data: _vm.produtoOptions,
                                                searchable: false,
                                              },
                                              model: {
                                                value: config.valor,
                                                callback: function ($$v) {
                                                  _vm.$set(config, "valor", $$v)
                                                },
                                                expression: "config.valor",
                                              },
                                            })
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ]),
                                ]
                              )
                            }
                          )
                        ),
                      ]),
                    ]
                  )
                }
              )
            : _vm._e(),
          _vm._v(" "),
          _c("fieldset", [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-sm-12 newbtn" }, [
                _c("div", { staticClass: "form-group" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-success",
                      attrs: { type: "submit" },
                    },
                    [
                      _c("i", {
                        staticClass: "fa fa-save",
                        attrs: { "aria-hidden": "true" },
                      }),
                      _vm._v(
                        "\n                            " +
                          _vm._s(_vm.$t("__.Crud.salvar")) +
                          "\n                        "
                      ),
                    ]
                  ),
                ]),
              ]),
            ]),
          ]),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }