import RelatorioBaseModel from "../../models/relatorios/base/relatorioBaseModel";

import PDFService from "./PDFService";

export default class RelatorioPdfService extends PDFService {
    public async getRelatorioPdf(model: RelatorioBaseModel, sortKey: string, sortOrder: string) {
        const payload = { ...model, filters: null };

        payload.filters = model.filters.map(p => ({
            ...p,
            comboOptions: p.valor && p.comboOptions ? p.comboOptions.filter(c => c["value"] == p.valor) : null,
            showOn: p.showOn.replaceAll("&", "%26"),
        }));

        const payloadString = JSON.stringify(payload, (key, value) => {
            if (value !== null) {
                return value;
            }
        });

        const url = `/RelatorioPDF/GetRelatorioPdf?filters=${payloadString}&sortKey=${sortKey}&sortOrder=${sortOrder}`;
        this.imprimirPDF(url);
    }
}
