import VueRouter from "vue-router";
import { Module } from "vuex";

import ConfiguracaoFranquiaModel from "@/models/configuracaoFranquia/configuracaoFranquiaModel";
import ConfiguracaoUsuarioModel from "@/models/configuracaoUsuarioModel";
import Configuracoes from "@/models/enum/configuracao/configuracoes";
import ConfiguracoesUsuario from "@/models/enum/configuracao/configuracoesUsuario";
import PerfisUsuario from "@/models/enum/perfisUsuario";
import TiposFranquia from "@/models/enum/tiposFranquia";
import TelaAcaoModel from "@/models/telaAcaoModel";
import UsuarioModel from "@/models/usuarioModel";
import ConfiguracaoFranquiaService from "@/services/configuracaoFranquia/configuracaoFranquiaService";
import ConfiguracaoUsuarioService from "@/services/configuracaoUsuarioService";
import UsuarioService from "@/services/usuarioService";
import Delay from "@/utils/common/delay";

import { AppState } from "./store";

export type SessionState = {
    loadingUsuario: boolean;
    logado: boolean;
    usuarioLogado: UsuarioModel;
    configuracoesFranquia: ConfiguracaoFranquiaModel[];
    configuracaoUsuario: ConfiguracaoUsuarioModel[];
};

export type SessionMutations =
    | "LOAD_CONFIGURACOES_FRANQUIA"
    | "LOAD_CONFIGURACOES_USUARIO"
    | "TROCA_FRANQUIA"
    | "LOAD_PERMISSOES_USUARIO";

export enum SessionActions {
    LOGIN = "LOGIN",
    LOGOUT = "LOGOUT",
    HYDRATE = "HYDRATE",
    LOAD_USUARIO_LOGADO = "LOAD_USUARIO_LOGADO",
    RELOAD_USUARIO_LOGADO = "RELOAD_USUARIO_LOGADO",
    TROCA_FRANQUIA = "TROCA_FRANQUIA",
}

export type SessionGetters =
    | "GET_CONFIG_FRANQUIA"
    | "CONFIG_FRANQUIA_LOADED"
    | "GET_CONFIG_USUARIO"
    | "VALIDAR_PERMISSAO_USUARIO"
    | "VALIDAR_PERMISSAO_SOMENTE_CONSULTA"
    | "GET_IS_FRACIONAMENTO"
    | "HAS_PERMISSAO_PERFIL"
    | "GET_FRANQUIA_ATUAL"
    | "GET_IS_ESTEREIS"
    | "IS_LOGADO";

const usuarioService = new UsuarioService();
const configuracaoFranquiaService = new ConfiguracaoFranquiaService();
const configuracaoUsuarioService = new ConfiguracaoUsuarioService();

const hasPermission = (usuario: UsuarioModel, fn: (p: TelaAcaoModel) => boolean) => {
    if (usuario) {
        if (!usuario.telasAcaoRemover.map(p => p.telaAcao).some(fn)) {
            let permitido = usuario.telasAcao.some(fn);
            if (!permitido) {
                permitido = usuario.grupos.some(p => p.telaAcoes.some(fn));
            }
            return permitido;
        }
    }
    return false;
};

const getUsuarioLogado = async () => {
    const usuarioLogado = await usuarioService.getUsuarioLogado().withLoading().resolveWithJSON<UsuarioModel>();

    const token = localStorage.getItem("@PharmUP:Token");

    return { usuarioLogado, token };
};

const sessionStore: Module<SessionState, AppState> = {
    state: {
        loadingUsuario: false,
        logado: false,
        usuarioLogado: new UsuarioModel(),
        configuracoesFranquia: new Array<ConfiguracaoFranquiaModel>(),
        configuracaoUsuario: new Array<ConfiguracaoUsuarioModel>(),
    },
    actions: {
        LOGIN(context, token: string) {
            localStorage.setItem("@PharmUP:Token", token);

            context.commit("LOGADO", true);
        },
        LOGOUT(context) {
            localStorage.removeItem("@PharmUP:Token");

            context.commit("LOGADO", false);
        },
        HYDRATE(context) {
            const hasToken = !!localStorage.getItem("@PharmUP:Token");
            context.commit("LOGADO", hasToken);
        },
        async LOAD_USUARIO_LOGADO(context, force = false) {
            try {
                while (context.state.loadingUsuario && !force) await Delay(1000);

                if (!context.state.usuarioLogado.nome || force) {
                    context.commit("SET_LOADING_USUARIO", true);

                    const { usuarioLogado, token } = await getUsuarioLogado();

                    context.commit("LOAD_USUARIO_LOGADO", usuarioLogado);
                    context.commit("LOGADO", !!token);
                    context.commit("LOAD_CONFIGURACOES_USUARIO");

                    context.commit("SET_LOADING_USUARIO", false);
                }
            } catch {}
        },
        async RELOAD_USUARIO_LOGADO(context) {
            const { usuarioLogado, token } = await getUsuarioLogado();
            context.commit("LOAD_USUARIO_LOGADO", usuarioLogado);
            context.commit("LOGADO", !!token);
        },
        async TROCA_FRANQUIA(context, { franquiaId, router }: { franquiaId: number; router: VueRouter }) {
            const sessionId = localStorage.getItem("@PharmUP:SessionId");

            const data = await usuarioService
                .trocaFranquiaUsuario(franquiaId, sessionId)
                .resolveWithJSON<{ usuario: UsuarioModel; token: string }>();

            localStorage.setItem("@PharmUP:Token", data.token);

            context.commit("LOAD_USUARIO_LOGADO", data.usuario);
            context.commit("LOAD_CONFIGURACOES_FRANQUIA");

            router.push("/");
            document.location.reload();
        },
    },
    mutations: {
        LOGADO(store, logado: boolean) {
            store.logado = logado;
        },
        SET_LOADING_USUARIO(store, loading: boolean) {
            store.loadingUsuario = loading;
        },
        LOAD_USUARIO_LOGADO(store, usuarioLogado: UsuarioModel) {
            store.usuarioLogado = usuarioLogado;
        },
        async LOAD_CONFIGURACOES_FRANQUIA(store) {
            try {
                const data = await configuracaoFranquiaService
                    .listAll()
                    .then(r => r.json() as Promise<Array<ConfiguracaoFranquiaModel>>);
                store.configuracoesFranquia = data;
            } catch {}
        },
        async LOAD_CONFIGURACOES_USUARIO(store) {
            try {
                const data = await configuracaoUsuarioService.listAll().resolveWithJSON<ConfiguracaoUsuarioModel[]>();
                store.configuracaoUsuario = data;
            } catch {}
        },
    },
    getters: {
        GET_CONFIG_FRANQUIA: store => (configuracao: Configuracoes) => {
            const config = store.configuracoesFranquia.filter(p => p.codigo == configuracao);
            return config.length ? config[0] : null;
        },
        GET_CONFIG_USUARIO: store => (configuracao: ConfiguracoesUsuario) => {
            const config = store.configuracaoUsuario.filter(p => p.codigo == configuracao);
            return config.length ? config[0] : null;
        },
        VALIDAR_PERMISSAO_USUARIO: store => (telaDescricao: string, acaoController: string) => {
            const buscarAcao = p =>
                p.telaDescricao &&
                p.telaDescricao.toLowerCase() == telaDescricao.toLowerCase() &&
                p.acaoController &&
                p.acaoController.toLowerCase() == acaoController.toLowerCase();

            return hasPermission(store.usuarioLogado, buscarAcao);
        },
        VALIDAR_PERMISSAO_SOMENTE_CONSULTA: store => (telaDescricao: string) => {
            const hasGetList = p =>
                p.telaDescricao &&
                p.telaDescricao.toLowerCase() == telaDescricao.toLowerCase() &&
                p.acaoController &&
                (p.acaoController.toLowerCase() == "list" || p.acaoController.toLowerCase() == "get");

            const hasUpdate = p =>
                p.telaDescricao &&
                p.telaDescricao.toLowerCase() == telaDescricao.toLowerCase() &&
                p.acaoController &&
                p.acaoController.toLowerCase() == "update";

            return hasPermission(store.usuarioLogado, hasGetList) && !hasPermission(store.usuarioLogado, hasUpdate);
        },
        GET_IS_FRACIONAMENTO: store => async () => {
            while (store.loadingUsuario) await Delay(100);

            if (store.usuarioLogado.franquia) {
                return store.usuarioLogado.franquia.tipoFranquia == TiposFranquia.Fracionadora;
            }
            return false;
        },
        GET_IS_ESTEREIS: store => async () => {
            while (store.loadingUsuario) await Delay(100);

            if (store.usuarioLogado.franquia) {
                return store.usuarioLogado.franquia.tipoFranquia == TiposFranquia.Estereis;
            }
            return false;
        },
        HAS_PERMISSAO_PERFIL:
            store =>
            async (isRede = false, isFranqueado = false, isSuporte = false) => {
                while (store.loadingUsuario) await Delay(100);

                let isAdmin = store.usuarioLogado.perfil == PerfisUsuario.Admin;

                if (isRede) {
                    isAdmin = isAdmin || store.usuarioLogado.perfil == PerfisUsuario.Rede;
                }

                if (isFranqueado) {
                    isAdmin =
                        isAdmin ||
                        store.usuarioLogado.perfil == PerfisUsuario.Franqueado ||
                        store.usuarioLogado.perfil == PerfisUsuario.Suporte;
                }

                if (isSuporte) {
                    isAdmin = isAdmin || store.usuarioLogado.perfil == PerfisUsuario.Suporte;
                }

                return isAdmin;
            },
        GET_FRANQUIA_ATUAL: store => () => {
            return store.usuarioLogado.franquia;
        },
        CONFIG_FRANQUIA_LOADED: store => () => store.configuracoesFranquia.length > 0,
    },
};

export default sessionStore;
