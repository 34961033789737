var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "crud crud-list" },
    [
      _c("div", { staticClass: "info-div" }, [
        _c("h1", { staticClass: "title" }, [_vm._v("Prescritores duplicados")]),
        _vm._v(" "),
        _c("div", { staticClass: "content" }, [
          _c("div", { staticClass: "info-panel" }, [
            _c("span", [_vm._v(_vm._s(_vm.total))]),
            _vm._v(" "),
            _c("span", [
              _vm._v(
                _vm._s(
                  _vm.$t(
                    "__.Components.parent.duplicacoes.list_vue_html.prescritoresDuplicados"
                  )
                )
              ),
            ]),
            _vm._v(" "),
            _c("span", [
              _vm._v(
                _vm._s(
                  _vm.$t(
                    "__.Components.parent.duplicacoes.list_vue_html.registros"
                  )
                )
              ),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "grid-component",
        {
          ref: "gridcomponent",
          attrs: {
            actionName: "prescritores",
            columns: _vm.gridColumns,
            data: _vm.gridData,
            "page-index": _vm.pageIndex,
            "page-size": _vm.pageSize,
            startFilterKey: _vm.startFilterKey,
            tituloPagina: "Prescritores",
            total: _vm.total,
            showEditItem: false,
            showRemoveItem: false,
            showAddNewButton: false,
            showTools: true,
            showSortIcons: false,
            extraActions: _vm.extraActions,
          },
          on: {
            "gerar-xls": function ($event) {
              _vm.pags = true
              _vm.tipoDocGerar = 0
            },
            "gerar-pdf": function ($event) {
              _vm.pags = true
              _vm.tipoDocGerar = 1
            },
            "gerar-impressao": function ($event) {
              _vm.pags = true
              _vm.tipoDocGerar = 2
            },
            "change-filter-key": _vm.onChangeFilterKey,
            "extra-action": _vm.onExtraAction,
            "change-page": _vm.onChangePage,
          },
        },
        [
          _c(
            "div",
            {
              attrs: { slot: "footer-extra-options" },
              slot: "footer-extra-options",
            },
            [
              _c(
                "button",
                {
                  staticClass: "btn btn-primary",
                  attrs: { type: "button" },
                  on: { click: _vm.onUnificar },
                },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(
                        _vm.$t(
                          "__.Components.parent.duplicacoes.list_vue_html.unificar"
                        )
                      ) +
                      "\n            "
                  ),
                ]
              ),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _c("prescritoresDuplicadosViewComponent", {
        ref: "prescritoresDuplicadosViewComponent",
        on: { onRefresh: _vm.load },
      }),
      _vm._v(" "),
      _c("prescritoresDuplicadosUnificarComponent", {
        ref: "prescritoresDuplicadosUnificarComponent",
        on: { onRefresh: _vm.load },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }