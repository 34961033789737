import Vue from "vue";

import actionBarComponent from "@/components/child/actionBar/actionBar.vue";
import comboComponent from "@/components/child/form/combo.vue";
import dateTimePickerComponent from "@/components/child/form/datetimepicker.vue";
import decimalComSinalCustomComponent from "@/components/child/form/decimalComSinalCustom.vue";
import moedaComponent from "@/components/child/form/moeda.vue";
import LoadingModalComponent from "@/components/child/loadingmodal/loadingmodal";
import loadingModalComponent from "@/components/child/loadingmodal/loadingmodal.vue";
import { Component } from "@/decorators";
import CupomModel from "@/models/cupomModel";
import EnumExtensions from "@/models/enum/extensions/enumExtensions";
import TipoDesconto from "@/models/enum/TipoDesconto";
import ValidationErrorModel from "@/models/validationErrorModel";
import CupomService from "@/services/CupomService";
import ValidationErrorWrapper from "@/wrappers/validationErrorWrapper";

import "../crud.scss";

@Component({
    components: {
        comboComponent,
        loadingModalComponent,
        dateTimePickerComponent,
        decimalComSinalCustomComponent,
        moedaComponent,
        actionBarComponent,
    },
})
export default class CupomEditComponent extends Vue {
    private service = new CupomService();
    private validationErrorWrapper = new ValidationErrorWrapper(this.$validator);
    private loadingModalComponent: LoadingModalComponent = null;

    model: CupomModel = new CupomModel();
    modelId = 0;
    tipoDescontosOptions = EnumExtensions.getNamesAndValuesOrderedByNames(TipoDesconto).filter(
        t => t.value != TipoDesconto.Faixa,
    );

    constructor() {
        super();
    }

    private load() {
        this.model = new CupomModel();
        this.validationErrorWrapper.clearErrors();

        if (this.modelId > 0) {
            this.loadingModalComponent.showLoading();

            this.service
                .get(this.modelId)
                .then(response => {
                    if (response.ok) return response.json() as Promise<CupomModel>;
                })
                .then(data => {
                    this.model.updateFrom(data);
                    this.loadingModalComponent.hide();
                })
                .catch(error => {
                    this.$router.back();
                    this.loadingModalComponent.hide();
                });
        }
    }

    private save() {
        this.$validator
            .validateAll()
            .then(isValid => {
                if (isValid) {
                    this.loadingModalComponent.showSaving();

                    this.service[!this.model.id ? "insert" : "update"](this.model)
                        .then(async response => {
                            this.loadingModalComponent.hide();

                            if (response.ok) {
                                await this.$showInclusaoUpdate(this.model.id);
                                this.$router.back();
                            } else {
                                return response.json() as Promise<ValidationErrorModel[]>;
                            }
                        })
                        .then(data => {
                            this.validationErrorWrapper.showErrors(data);
                        })
                        .catch(error => {
                            this.loadingModalComponent.hide();
                        });
                }
            })
            .catch(error => {});
    }

    private cancel() {
        this.$router.back();
    }

    private mounted() {
        this.loadingModalComponent = this.$refs.loadingModalComponent as LoadingModalComponent;

        if (this.$route.params.id) {
            this.modelId = +this.$route.params.id;
            this.load();
        }
    }
}
