var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "crud crud-edit" },
    [
      !_vm.CalledByShortCut
        ? _c(
            "actionBarComponent",
            {
              attrs: {
                showSave: !_vm.manipulacaoContext.isDesabilitado,
                slots: 4,
                titleHtml: _vm.manipulacaoTitle,
              },
              on: { onSave: _vm.save },
              scopedSlots: _vm._u([
                {
                  key: "buttonSlot3",
                  fn: function (ref) {
                    var open = ref.open
                    return _c(
                      "div",
                      {
                        attrs: {
                          title: _vm.$t(
                            "__.Components.parent.home.fastaction.manipulacao.manipulacao_vue_html.historicomanipulacao"
                          ),
                        },
                        on: {
                          click: function ($event) {
                            open(
                              1,
                              _vm.$t(
                                "__.Components.parent.home.fastaction.manipulacao.manipulacao_vue_html.historicomanipulacao"
                              ),
                              "lg-modal"
                            )
                          },
                        },
                      },
                      [
                        _c("i", {
                          staticClass: "fa fa-history",
                          attrs: { "aria-hidden": "true" },
                        }),
                      ]
                    )
                  },
                },
              ]),
            },
            [
              _vm.validarVisibilidadeBotao("imprimir")
                ? _c(
                    "div",
                    {
                      attrs: {
                        slot: "buttonSlot0",
                        title: _vm.$t(
                          "__.Components.parent.manipulacao.edit_vue_html.imprimirModel"
                        ),
                      },
                      on: {
                        click: function ($event) {
                          _vm.onImprimirManipulacaoModelo(false)
                        },
                      },
                      slot: "buttonSlot0",
                    },
                    [
                      _c("i", {
                        staticClass: "fa fa-print",
                        attrs: { "aria-hidden": "true" },
                      }),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.validarVisibilidadeBotao("imprimirRotulo")
                ? _c(
                    "div",
                    {
                      attrs: { slot: "buttonSlot1", title: "Imprimir Rótulo" },
                      on: {
                        click: function ($event) {
                          _vm.onImprimirManipulacaoModelo(true)
                        },
                      },
                      slot: "buttonSlot1",
                    },
                    [
                      _c("i", {
                        staticClass: "fa fa-tag",
                        attrs: { "aria-hidden": "true" },
                      }),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.model.movimentacaoEstoqueId != null
                ? _c(
                    "div",
                    {
                      attrs: {
                        slot: "buttonSlot2",
                        title: _vm.$t(
                          "__.Components.parent.manipulacao.edit_vue_html.movimentacaoEstq"
                        ),
                      },
                      on: { click: _vm.onMovimentoEstoque },
                      slot: "buttonSlot2",
                    },
                    [
                      _c("i", {
                        staticClass: "fa fa-archive",
                        attrs: { "aria-hidden": "true" },
                      }),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.validarVisibilidadeBotao("cancelar")
                ? _c(
                    "div",
                    {
                      attrs: {
                        slot: "buttonSlot3",
                        title: _vm.$t("__.Crud.cancelar"),
                      },
                      on: { click: _vm.onCancelar },
                      slot: "buttonSlot3",
                    },
                    [
                      _c("i", {
                        staticClass: "fa fa-exclamation-circle",
                        attrs: { "aria-hidden": "true" },
                      }),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("div", { attrs: { slot: "modalSlot1" }, slot: "modalSlot1" }, [
                _c(
                  "div",
                  {
                    staticClass: "col-sm-12",
                    staticStyle: { "text-transform": "none" },
                  },
                  [
                    _c("logEntidadeComponent", {
                      attrs: { registroId: _vm.modelId, entidade: 2 },
                    }),
                  ],
                  1
                ),
              ]),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "form",
        { attrs: { novalidate: "novalidate", id: "form" } },
        [
          _c("div", { attrs: { slot: "rows" }, slot: "rows" }, [
            _c("div", { attrs: { id: "header" } }, [
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.model.situacao == 5,
                        expression: "model.situacao == 5",
                      },
                    ],
                    staticClass: "col-sm-12",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "alert alert-success align-itens-center justify-content-between",
                        attrs: { role: "alert" },
                      },
                      [
                        _vm._v(
                          "\n                            " +
                            _vm._s(
                              _vm.$t(
                                "__.Components.parent.manipulacao.edit_vue_html.manipuJaConcluida"
                              )
                            ) +
                            "\n                            "
                        ),
                        _vm.validarVisibilidadeBotao("desfazerConclusao")
                          ? _c(
                              "button",
                              {
                                staticClass: "btn btn-primary",
                                attrs: { type: "button" },
                                on: {
                                  click: function ($event) {
                                    _vm.onDesfazerConclusao()
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(
                                      _vm.$t(
                                        "__.Components.parent.manipulacao.edit_vue_html.desfazer"
                                      )
                                    ) +
                                    "\n                            "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.model.situacao == 2,
                        expression: "model.situacao == 2",
                      },
                    ],
                    staticClass: "col-sm-12",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "alert alert-danger",
                        attrs: { role: "alert" },
                      },
                      [
                        _vm._v(
                          "\n                            " +
                            _vm._s(
                              _vm.$t(
                                "__.Components.parent.manipulacao.edit_vue_html.manipuCancelada"
                              )
                            ) +
                            "\n                        "
                        ),
                      ]
                    ),
                  ]
                ),
                _vm._v(" "),
                false
                  ? _c("div", { staticClass: "col-sm-6" }, [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: _vm.errors.first("situacao"),
                              expression: "errors.first('situacao')",
                            },
                          ],
                          staticClass: "form-group",
                        },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "control-label",
                              attrs: {
                                for: "situacao",
                                title: _vm.$t("__.obrigatorio"),
                              },
                            },
                            [
                              _c("span", { staticStyle: { color: "red" } }, [
                                _vm._v("*"),
                              ]),
                              _vm._v(
                                "\n                                " +
                                  _vm._s(
                                    _vm.$t(
                                      "__.Components.parent.manipulacao.edit_vue_html.situacao"
                                    )
                                  ) +
                                  "\n                            "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("combo-component", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required",
                                expression: "'required'",
                              },
                            ],
                            attrs: {
                              id: "situacao",
                              name: "situacao",
                              data: _vm.situacoesManipulacaoOrdemOptions,
                            },
                            model: {
                              value: _vm.model.situacao,
                              callback: function ($$v) {
                                _vm.$set(_vm.model, "situacao", $$v)
                              },
                              expression: "model.situacao",
                            },
                          }),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                false
                  ? _c("div", { staticClass: "col-sm-6" }, [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: _vm.errors.first("codigo"),
                              expression: "errors.first('codigo')",
                            },
                          ],
                          staticClass: "form-group",
                        },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "control-label",
                              attrs: { for: "codigo" },
                            },
                            [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(
                                    _vm.$t(
                                      "__.Components.parent.manipulacao.edit_vue_html.codigo"
                                    )
                                  ) +
                                  "\n                            "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.model.codigo,
                                expression: "model.codigo",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: {
                              autocomplete: "off",
                              type: "text",
                              id: "codigo",
                              name: "codigo",
                            },
                            domProps: { value: _vm.model.codigo },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.model,
                                  "codigo",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]
                      ),
                    ])
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  {
                    staticClass: "col-sm-12",
                    staticStyle: { display: "flex" },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "form-group",
                        staticStyle: {
                          display: "flex",
                          "align-items": "center",
                        },
                      },
                      [
                        !_vm.manipulacaoContext.isDesabilitado &&
                        !_vm.modelId &&
                        !_vm.model.homeopatia
                          ? _c("buttonScComponent", {
                              staticClass: "manipulacao-button bg-dark-blue",
                              attrs: {
                                classButton: "btn",
                                classIcon: "fa fa-plus-circle",
                                buttonName: _vm.$t(
                                  "__.Components.parent.manipulacao.edit_vue_html.preVenda"
                                ),
                              },
                              on: {
                                click: function ($event) {
                                  _vm.onPreVenda().withLoading()
                                },
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.model.formulaPadraoId != 0 &&
                    _vm.model.formulaPadraoId != null
                      ? _c(
                          "div",
                          {
                            staticClass: "row form-group",
                            staticStyle: {
                              "padding-top": "4px",
                              "line-height": "35px",
                              flex: "1",
                              "padding-left": "25px",
                            },
                          },
                          [
                            _c("div", { staticClass: "col-sm-11" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.model.formulaPadraoDescricao,
                                    expression: "model.formulaPadraoDescricao",
                                  },
                                ],
                                staticClass: "form-control",
                                attrs: {
                                  autocomplete: "false",
                                  id: "model.formulaPadraoDescricao",
                                  name: "model.formulaPadraoDescricao",
                                  disabled: "",
                                },
                                domProps: {
                                  value: _vm.model.formulaPadraoDescricao,
                                },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.model,
                                      "formulaPadraoDescricao",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                            ]),
                            _vm._v(" "),
                            !_vm.manipulacaoContext.isDesabilitado
                              ? _c("div", { staticClass: "col-sm-1" }, [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "remove-formula-padrao",
                                      attrs: {
                                        href: "#",
                                        title: _vm.$t("__.Crud.excluir"),
                                      },
                                      on: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          return _vm.removeFormulaPadrao($event)
                                        },
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "fa fa-times",
                                        attrs: { "aria-hidden": "true" },
                                      }),
                                    ]
                                  ),
                                ])
                              : _vm._e(),
                          ]
                        )
                      : _vm._e(),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _vm.nomeVendedor
                  ? _c("div", { staticClass: "col-sm-5" }, [
                      _c(
                        "label",
                        {
                          staticClass: "control-label",
                          attrs: { for: "nomeVendedor" },
                        },
                        [
                          _vm._v(
                            "\n                            " +
                              _vm._s(
                                _vm.$t(
                                  "__.Components.parent.manipulacao.edit_vue_html.nomeVendedor"
                                )
                              ) +
                              "\n                        "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.nomeVendedorText,
                            expression: "nomeVendedorText",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          id: "nomeVendedor",
                          name: "nomeVendedor",
                          autocomplete: "off",
                          readonly: "",
                        },
                        domProps: { value: _vm.nomeVendedorText },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.nomeVendedorText = $event.target.value
                          },
                        },
                      }),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.temMarkupEquinos && !_vm.model.homeopatia
                  ? _c(
                      "div",
                      { staticClass: "col-sm-2" },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "usaMarkupEquinos" },
                          },
                          [
                            _vm._v(
                              "\n                            " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Components.parent.manipulacao.edit_vue_html.usaMarkupEquinos"
                                  )
                                ) +
                                "\n                        "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("checkbox-component", {
                          attrs: {
                            id: "usaMarkupEquinos",
                            name: "usaMarkupEquinos",
                            disabled:
                              _vm.manipulacaoContext.isDesabilitado ||
                              _vm.isDisabledEdicaoPreVenda,
                          },
                          on: { change: _vm.onChangeUsaMarkupEquinos },
                          model: {
                            value: _vm.model.usaMarkupEquinos,
                            callback: function ($$v) {
                              _vm.$set(_vm.model, "usaMarkupEquinos", $$v)
                            },
                            expression: "model.usaMarkupEquinos",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-sm-1" }, [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "tooltip",
                          rawName: "v-tooltip",
                          value: _vm.errors.first("numeroFormulas"),
                          expression: "errors.first('numeroFormulas')",
                        },
                      ],
                      staticClass: "form-group",
                    },
                    [
                      _c(
                        "label",
                        {
                          staticClass: "control-label",
                          attrs: {
                            for: "numeroFormulas",
                            title: _vm.$t("__.obrigatorio"),
                          },
                        },
                        [
                          _c("span", { staticStyle: { color: "red" } }, [
                            _vm._v("*"),
                          ]),
                          _vm._v(
                            "\n                                " +
                              _vm._s(
                                _vm.$t(
                                  "__.Components.parent.manipulacao.edit_vue_html.numeroFormulas"
                                )
                              ) +
                              "\n                            "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.model.numeroFormulas,
                            expression: "model.numeroFormulas",
                          },
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required|maior_que_zero",
                            expression: "'required|maior_que_zero'",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          id: "numeroFormulas",
                          type: "number",
                          name: "numeroFormulas",
                          disabled:
                            _vm.manipulacaoContext.isDesabilitado ||
                            _vm.isDisabledEdicaoPreVenda,
                        },
                        domProps: { value: _vm.model.numeroFormulas },
                        on: {
                          keypress: _vm.$getInteger,
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.model,
                              "numeroFormulas",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    class:
                      "col-sm-" +
                      [
                        !_vm.manipulacaoContext.isCapsula &&
                        !_vm.isFormaVolume &&
                        !_vm.utilizaMolde
                          ? 3
                          : (_vm.manipulacaoContext.isItemFromVenda ||
                              _vm.manipulacaoContext.isCapsula ||
                              _vm.isFormaVolume) &&
                            !_vm.utilizaMolde
                          ? 7
                          : 5,
                      ],
                  },
                  [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("formaFarmaceuticaId"),
                            expression: "errors.first('formaFarmaceuticaId')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: {
                              for: "formaFarmaceuticaId",
                              title: _vm.$t("__.obrigatorio"),
                            },
                          },
                          [
                            _c("span", { staticStyle: { color: "red" } }, [
                              _vm._v("*"),
                            ]),
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Components.parent.manipulacao.edit_vue_html.formaFarmaceu"
                                  )
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("combo-component", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required",
                              expression: "'required'",
                            },
                          ],
                          ref: "formaFarmaceuticaId",
                          attrs: {
                            id: "formaFarmaceuticaId",
                            name: "formaFarmaceuticaId",
                            disabled:
                              _vm.manipulacaoContext.isDesabilitado ||
                              _vm.isDisabledEdicaoPreVenda,
                            data: _vm.formaFarmaceuticasOptions,
                            refresh: _vm.loadFormaFarmaceutica,
                          },
                          on: {
                            select: function (p) {
                              return _vm.onChangeFormaFarmaceuticaId(p)
                            },
                          },
                          model: {
                            value: _vm.model.formaFarmaceuticaId,
                            callback: function ($$v) {
                              _vm.$set(_vm.model, "formaFarmaceuticaId", $$v)
                            },
                            expression: "model.formaFarmaceuticaId",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
                _vm._v(" "),
                _vm.utilizaMolde && _vm.model.formaFarmaceuticaId > 0
                  ? _c("div", { staticClass: "col-sm-4" }, [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: _vm.errors.first("moldeId"),
                              expression: "errors.first('moldeId')",
                            },
                          ],
                          staticClass: "form-group",
                        },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "control-label",
                              attrs: {
                                for: "moldeId",
                                title: _vm.$t("__.obrigatorio"),
                              },
                            },
                            [
                              _c("span", { staticStyle: { color: "red" } }, [
                                _vm._v("*"),
                              ]),
                              _vm._v(
                                "\n                                " +
                                  _vm._s(
                                    _vm.$t(
                                      "__.Components.parent.manipulacao.edit_vue_html.molde"
                                    )
                                  ) +
                                  "\n                                "
                              ),
                              _vm.moldeId != _vm.model.moldeId &&
                              _vm.moldeId > 0 &&
                              _vm.model.moldeId > 0
                                ? _c("data-tooltip-component", {
                                    attrs: {
                                      text: _vm.getMsgMolde(false),
                                      icon: "fa-exclamation-triangle",
                                      iconColor: "darkorange",
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("data-tooltip-component", {
                            attrs: {
                              text: _vm.$t(
                                "__.Components.parent.manipulacao.edit_vue_html.oCalculoVolume"
                              ),
                            },
                          }),
                          _vm._v(" "),
                          _c("combo-component", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required",
                                expression: "'required'",
                              },
                            ],
                            ref: "moldeId",
                            attrs: {
                              id: "moldeId",
                              name: "moldeId",
                              disabled:
                                _vm.manipulacaoContext.isDesabilitado ||
                                _vm.isDisabledEdicaoPreVenda,
                              data: _vm.moldeOptions,
                              refresh: _vm.loadMoldes,
                            },
                            model: {
                              value: _vm.model.moldeId,
                              callback: function ($$v) {
                                _vm.$set(_vm.model, "moldeId", $$v)
                              },
                              expression: "model.moldeId",
                            },
                          }),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                !_vm.manipulacaoContext.isCapsula && !_vm.utilizaMolde
                  ? _c("div", { staticClass: "col-sm-2" }, [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: _vm.errors.first("volume"),
                              expression: "errors.first('volume')",
                            },
                          ],
                          staticClass: "form-group",
                        },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "control-label",
                              attrs: {
                                for: "volume",
                                title: _vm.$t("__.obrigatorio"),
                              },
                            },
                            [
                              _c("span", { staticStyle: { color: "red" } }, [
                                _vm._v("*"),
                              ]),
                              _vm._v(
                                "\n                                " +
                                  _vm._s(
                                    _vm.$t(
                                      "__.Components.parent.manipulacao.edit_vue_html.volDose"
                                    )
                                  ) +
                                  "\n                            "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("decimal-component", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required|maior_que_zero",
                                expression: "'required|maior_que_zero'",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: {
                              id: "volume",
                              name: "volume",
                              disabled:
                                _vm.manipulacaoContext.isCapsula ||
                                _vm.manipulacaoContext.isDesabilitado ||
                                _vm.isDisabledEdicaoPreVenda,
                              tabindex: _vm.tabIndex(_vm.model.volume),
                              title: [
                                !_vm.isFormaVolume
                                  ? _vm.$t(
                                      "__.Components.parent.manipulacao.edit_vue_html.tamPlaca"
                                    )
                                  : "",
                              ],
                            },
                            model: {
                              value: _vm.model.volume,
                              callback: function ($$v) {
                                _vm.$set(_vm.model, "volume", $$v)
                              },
                              expression: "model.volume",
                            },
                          }),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value:
                          !_vm.manipulacaoContext.isCapsula &&
                          !_vm.utilizaMolde,
                        expression:
                          "!manipulacaoContext.isCapsula && !utilizaMolde",
                      },
                    ],
                    staticClass: "col-sm-2",
                  },
                  [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("unidadeMedidaId"),
                            expression: "errors.first('unidadeMedidaId')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: {
                              for: "unidadeMedidaId",
                              title: _vm.$t("__.obrigatorio"),
                            },
                          },
                          [
                            _c("span", { staticStyle: { color: "red" } }, [
                              _vm._v("*"),
                            ]),
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.manipulacaoContext.isCapsula
                                    ? _vm.$t("__.ts.unidMedCaps")
                                    : _vm.$t("__.ts.unMedida")
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("combo-component", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required",
                              expression: "'required'",
                            },
                          ],
                          attrs: {
                            tabindex: _vm.tabIndex(_vm.model.unidadeMedidaId),
                            id: "unidadeMedidaId",
                            name: "unidadeMedidaId",
                            data: _vm.unidadeMedidasOptions,
                            refresh: _vm.loadUnidadeMedida,
                            disabled:
                              _vm.manipulacaoContext.isDesabilitado ||
                              _vm.isDisabledEdicaoPreVenda,
                          },
                          model: {
                            value: _vm.model.unidadeMedidaId,
                            callback: function ($$v) {
                              _vm.$set(_vm.model, "unidadeMedidaId", $$v)
                            },
                            expression: "model.unidadeMedidaId",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value:
                          !_vm.isFormaVolume ||
                          (!_vm.manipulacaoContext.isItemFromVenda &&
                            !_vm.isFormaVolume),
                        expression:
                          "!isFormaVolume || (!manipulacaoContext.isItemFromVenda && !isFormaVolume)",
                      },
                    ],
                    class:
                      "col-sm-" +
                      [
                        !_vm.manipulacaoContext.isCapsula
                          ? _vm.model.fatorMultQuantidade > 1
                            ? 1
                            : 2
                          : _vm.model.fatorMultQuantidade > 1
                          ? 2
                          : 4,
                      ],
                  },
                  [
                    _c(
                      "div",
                      {
                        class:
                          "col-sm-" +
                          [
                            _vm.utilizaMolde || _vm.manipulacaoContext.isCapsula
                              ? 11
                              : 12,
                          ],
                        staticStyle: { padding: "0" },
                      },
                      [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: _vm.errors.first(_vm.quantidadeStrId),
                                expression: "errors.first(quantidadeStrId)",
                              },
                            ],
                            staticClass: "form-group",
                          },
                          [
                            _c(
                              "label",
                              {
                                staticClass: "control-label",
                                attrs: {
                                  for: _vm.quantidadeStrId,
                                  title: _vm.$t("__.obrigatorio"),
                                },
                              },
                              [
                                _c("span", { staticStyle: { color: "red" } }, [
                                  _vm._v("*"),
                                ]),
                                _vm._v(
                                  "\n                                    " +
                                    _vm._s(
                                      _vm.manipulacaoContext.isCapsula
                                        ? _vm.$t("__.ts.qtdeCaps")
                                        : _vm.$t("__.ts.quantidade")
                                    ) +
                                    "\n                                "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("decimal-component", {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "required",
                                  expression: "'required'",
                                },
                              ],
                              ref: _vm.quantidadeStrId,
                              staticClass: "form-control",
                              attrs: {
                                readOnly: _vm.isFormaVolume,
                                tabindex: _vm.tabIndex(_vm.model.quantidade),
                                id: _vm.quantidadeStrId,
                                name: _vm.quantidadeStrId,
                                disabled:
                                  _vm.manipulacaoContext.isDesabilitado ||
                                  _vm.isDisabledEdicaoPreVenda,
                              },
                              on: { keyup: _vm.onChangeQuantidadeInput },
                              model: {
                                value: _vm.model.quantidade,
                                callback: function ($$v) {
                                  _vm.$set(_vm.model, "quantidade", $$v)
                                },
                                expression: "model.quantidade",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _vm.utilizaMolde || _vm.manipulacaoContext.isCapsula
                      ? _c("div", { staticClass: "col-sm-1 input-with-icon" }, [
                          _c("div", { staticClass: "form-group" }, [
                            _c("label", { staticClass: "control-label" }),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                staticClass:
                                  "input-group-addon form-control input-with-icon-wrapper",
                                attrs: {
                                  title: _vm.$t(
                                    "__.Components.parent.manipulacao.edit_vue_html.multiplicarNumeroDose"
                                  ),
                                },
                                on: { click: _vm.onMultiplicarDose },
                              },
                              [_c("span", { staticClass: "fab fa-deviantart" })]
                            ),
                          ]),
                        ])
                      : _vm._e(),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value:
                          (!_vm.isFormaVolume ||
                            (!_vm.manipulacaoContext.isItemFromVenda &&
                              !_vm.isFormaVolume)) &&
                          _vm.model.fatorMultQuantidade > 1,
                        expression:
                          "\n                            (!isFormaVolume || (!manipulacaoContext.isItemFromVenda && !isFormaVolume)) &&\n                            model.fatorMultQuantidade > 1\n                        ",
                      },
                    ],
                    class:
                      "col-sm-" + [!_vm.manipulacaoContext.isCapsula ? 1 : 2],
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "fatorMultQuantidadeAplicado" },
                          },
                          [
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Components.parent.manipulacao.edit_vue_html.qtdTotal"
                                  )
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("decimal-component", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required",
                              expression: "'required'",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            readOnly: true,
                            tabindex: -1,
                            id: "fatorMultQuantidadeAplicado",
                            name: "fatorMultQuantidadeAplicado",
                            disabled:
                              _vm.manipulacaoContext.isDesabilitado ||
                              _vm.isDisabledEdicaoPreVenda,
                          },
                          model: {
                            value: _vm.quantidadeCalculada,
                            callback: function ($$v) {
                              _vm.quantidadeCalculada = $$v
                            },
                            expression: "quantidadeCalculada",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value:
                          !_vm.manipulacaoContext.isCapsula &&
                          !_vm.isFormaVolume &&
                          !_vm.utilizaMolde,
                        expression:
                          "!manipulacaoContext.isCapsula && !isFormaVolume && !utilizaMolde",
                      },
                    ],
                    staticClass: "col-sm-2",
                  },
                  [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("volumeTotal"),
                            expression: "errors.first('volumeTotal')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "volumeTotal" },
                          },
                          [
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Components.parent.manipulacao.edit_vue_html.volTotal"
                                  )
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("decimal-component", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required",
                              expression: "'required'",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            id: "volumeTotal",
                            name: "volumeTotal",
                            disabled: "",
                          },
                          model: {
                            value: _vm.model.volumeTotal,
                            callback: function ($$v) {
                              _vm.$set(_vm.model, "volumeTotal", $$v)
                            },
                            expression: "model.volumeTotal",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "fieldset-component",
            {
              attrs: {
                title: _vm.$t(
                  "__.Components.parent.manipulacao.edit_vue_html.itensManipu"
                ),
                collapsed: _vm.collapsedFieldsets,
              },
              on: { collapsedStatus: _vm.collapsedStatus },
            },
            [
              _c(
                "div",
                { attrs: { slot: "rows" }, slot: "rows" },
                [
                  _c(
                    "vue-tabs",
                    {
                      staticStyle: { margin: "15px 0 0" },
                      attrs: { tabindex: "-1" },
                      model: {
                        value: _vm.activeTab,
                        callback: function ($$v) {
                          _vm.activeTab = $$v
                        },
                        expression: "activeTab",
                      },
                    },
                    _vm._l(_vm.tabs, function (tab, index) {
                      return _c(
                        "v-tab",
                        {
                          key: tab[0],
                          attrs: { title: tab[1], tabindex: "-1" },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: { "padding-right": "12px" },
                              attrs: { slot: "title" },
                              slot: "title",
                            },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(tab[1]) +
                                  "\n                            "
                              ),
                              _vm.showWarningEmbalagem && index == 1
                                ? _c("span", {
                                    staticClass: "fa fa-exclamation-triangle",
                                    staticStyle: {
                                      color: "darkorange",
                                      right: "9px",
                                      position: "absolute",
                                      top: "13px",
                                    },
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.showWarningCapsula && index == 2
                                ? _c("span", {
                                    staticClass: "fa fa-exclamation-triangle",
                                    staticStyle: {
                                      color: "darkorange",
                                      right: "9px",
                                      position: "absolute",
                                      top: "13px",
                                    },
                                  })
                                : _vm._e(),
                            ]
                          ),
                          _vm._v(" "),
                          _c("keep-alive", [
                            _c(
                              "div",
                              { attrs: { id: "detail" } },
                              [
                                _c("manipulacaoItemTableComponent", {
                                  ref: "manipulacaoItemTableComponent",
                                  refInFor: true,
                                  staticClass: "ignorePrint",
                                  attrs: {
                                    isActiveTab: _vm.activeTab == tab[1],
                                    itemType: index,
                                    uuid: _vm.uuid,
                                    manipulacaoId: _vm.manipulacaoId,
                                    tipoVenda: _vm.tipoVenda,
                                  },
                                  on: {
                                    addItemCapsula: _vm.addItemCapsula,
                                    addItemEmbalagem: _vm.addItemEmbalagem,
                                    alterarVolumeCapsula:
                                      _vm.alterarVolumeCapsula,
                                    onCorCapsulaChange: _vm.onCorCapsulaChange,
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ],
                        1
                      )
                    })
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        margin: "20px auto 10px",
                        display: "table",
                      },
                    },
                    [
                      _vm.validarVisibilidadeBotao("consolidar")
                        ? _c("buttonScComponent", {
                            staticClass: "manipulacao-button bg-dark-blue",
                            attrs: {
                              classButton: "btn",
                              title: _vm.$t(
                                "__.Components.parent.manipulacao.edit_vue_html.consolidaInfos"
                              ),
                              classIcon: "fa fa-wrench",
                              buttonName: _vm.$t(
                                "__.Components.parent.manipulacao.edit_vue_html.consolidarItens"
                              ),
                              shortKeyBt: "o",
                            },
                            on: {
                              click: function ($event) {
                                _vm.consolidar().withLoading()
                              },
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.validarVisibilidadeBotao("conferirLotes")
                        ? _c("buttonScComponent", {
                            staticStyle: { "margin-left": "3px" },
                            attrs: {
                              classButton: "btn btnCopiar",
                              title: _vm.$t(
                                "__.Components.parent.manipulacao.edit_vue_html.confEstqAjusta"
                              ),
                              classIcon: "fa fa-balance-scale",
                              buttonName: _vm.$t(
                                "__.Components.parent.manipulacao.edit_vue_html.lotesEstq"
                              ),
                              shortKeyBt: "l",
                            },
                            on: {
                              click: function ($event) {
                                _vm.onLotes().withLoading()
                              },
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.validarVisibilidadeBotao("detalhesCusto")
                        ? _c("buttonScComponent", {
                            staticClass: "manipulacao-button bg-primary",
                            attrs: {
                              classButton: "btn",
                              title: _vm.$t(
                                "__.Components.parent.manipulacao.edit_vue_html.detalsDeCustos"
                              ),
                              classIcon: "fa fa-info",
                              buttonName: _vm.$t(
                                "__.Components.parent.manipulacao.edit_vue_html.detalsCusto"
                              ),
                              shortKeyBt: "c",
                            },
                            on: {
                              click: function ($event) {
                                _vm.onDetalhesCusto().withLoading()
                              },
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _vm.manipulacaoContext.isItemFromVenda || _vm.model.destino == 0
            ? _c(
                "fieldset-component",
                {
                  attrs: {
                    title: _vm.$t(
                      "__.Components.parent.manipulacao.edit_vue_html.valores"
                    ),
                    collapsed: _vm.collapsedFieldsets,
                  },
                },
                [
                  _c(
                    "div",
                    { attrs: { slot: "rows", id: "footer" }, slot: "rows" },
                    [
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-sm-3" }, [
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "control-label",
                                  attrs: { for: "valorFormaFarmaceutica" },
                                },
                                [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(
                                        _vm.$t(
                                          "__.Components.parent.manipulacao.edit_vue_html.valorFormFarma"
                                        )
                                      ) +
                                      "\n                            "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("moeda-component", {
                                staticClass: "form-control",
                                attrs: {
                                  disabled: "",
                                  id: "valorFormaFarmaceutica",
                                  name: "valorFormaFarmaceutica",
                                },
                                model: {
                                  value: _vm.manipulacao.valorFormaFarmaceutica,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.manipulacao,
                                      "valorFormaFarmaceutica",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "manipulacao.valorFormaFarmaceutica",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-sm-3" }, [
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "control-label",
                                  attrs: { for: "valorProdutos" },
                                },
                                [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(
                                        _vm.$t(
                                          "__.Components.parent.manipulacao.edit_vue_html.valorProds"
                                        )
                                      ) +
                                      "\n                            "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("moeda-component", {
                                staticClass: "form-control",
                                attrs: {
                                  disabled: "",
                                  id: "valorProdutos",
                                  name: "valorProdutos",
                                },
                                model: {
                                  value: _vm.manipulacao.valorProdutos,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.manipulacao,
                                      "valorProdutos",
                                      $$v
                                    )
                                  },
                                  expression: "manipulacao.valorProdutos",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-sm-3" }, [
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "tooltip",
                                  rawName: "v-tooltip",
                                  value: _vm.errors.first(
                                    "acrescimoPercentual"
                                  ),
                                  expression:
                                    "errors.first('acrescimoPercentual')",
                                },
                              ],
                              staticClass: "form-group",
                            },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "control-label",
                                  attrs: { for: "acrescimoPercentual" },
                                },
                                [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(
                                        _vm.$t(
                                          "__.Components.parent.manipulacao.edit_vue_html.acrescimoPercentual"
                                        )
                                      ) +
                                      "\n                            "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("decimalComSinalCustomComponent", {
                                staticClass: "form-control",
                                attrs: {
                                  id: "acrescimoPercentual",
                                  name: "acrescimoPercentual",
                                  signal: "%",
                                  title: "acrescimoPercentual",
                                  disabled:
                                    _vm.manipulacaoContext.isDesabilitado ||
                                    _vm.vendaFaturada ||
                                    _vm.valorTabelado > 0,
                                },
                                model: {
                                  value: _vm.model.acrescimoPercentual,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.model,
                                      "acrescimoPercentual",
                                      $$v
                                    )
                                  },
                                  expression: "model.acrescimoPercentual",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-sm-3" }, [
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "tooltip",
                                  rawName: "v-tooltip",
                                  value: _vm.errors.first("acrescimo"),
                                  expression: "errors.first('acrescimo')",
                                },
                              ],
                              staticClass: "form-group",
                            },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "control-label",
                                  attrs: { for: "acrescimo" },
                                },
                                [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(
                                        _vm.$t(
                                          "__.Components.parent.manipulacao.edit_vue_html.acrescimo"
                                        )
                                      ) +
                                      "\n                            "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("moeda-component", {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: "required",
                                    expression: "'required'",
                                  },
                                ],
                                staticClass: "form-control",
                                attrs: {
                                  id: "acrescimo",
                                  name: "acrescimo",
                                  disabled:
                                    _vm.manipulacaoContext.isDesabilitado ||
                                    _vm.vendaFaturada ||
                                    _vm.valorTabelado > 0,
                                },
                                on: { change: _vm.onChangeAcrescimo },
                                model: {
                                  value: _vm.model.acrescimo,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.model, "acrescimo", $$v)
                                  },
                                  expression: "model.acrescimo",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "row" }, [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  _vm.model.descontoConvenio > 0 &&
                                  _vm.model.prescritorId > 0,
                                expression:
                                  "model.descontoConvenio > 0 && model.prescritorId > 0",
                              },
                            ],
                            staticClass: "col-sm-3",
                          },
                          [
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "tooltip",
                                    rawName: "v-tooltip",
                                    value: _vm.errors.first("descontoConvenio"),
                                    expression:
                                      "errors.first('descontoConvenio')",
                                  },
                                ],
                                staticClass: "form-group",
                              },
                              [
                                _c(
                                  "label",
                                  {
                                    staticClass: "control-label",
                                    attrs: { for: "descontoConvenio" },
                                  },
                                  [_vm._v("Desconto Convenio")]
                                ),
                                _vm._v(" "),
                                _c("moeda-component", {
                                  directives: [
                                    {
                                      name: "validate",
                                      rawName: "v-validate",
                                      value: "required",
                                      expression: "'required'",
                                    },
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    id: "descontoConvenio",
                                    name: "descontoConvenio",
                                    disabled: "",
                                  },
                                  model: {
                                    value: _vm.model.descontoConvenio,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.model,
                                        "descontoConvenio",
                                        $$v
                                      )
                                    },
                                    expression: "model.descontoConvenio",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-sm-3" }, [
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "tooltip",
                                  rawName: "v-tooltip",
                                  value: _vm.errors.first(
                                    "model.descontoPercentual"
                                  ),
                                  expression:
                                    "errors.first('model.descontoPercentual')",
                                },
                              ],
                              staticClass: "form-group",
                            },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "control-label",
                                  attrs: { for: "descontoPercentual" },
                                },
                                [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(
                                        _vm.$t(
                                          "__.Components.parent.manipulacao.edit_vue_html.descontoPercentual"
                                        )
                                      ) +
                                      "\n                            "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("decimalComSinalCustomComponent", {
                                staticClass: "form-control",
                                attrs: {
                                  id: "descontoPercentual",
                                  name: "descontoPercentual",
                                  signal: "%",
                                  title: "descontoPercentual",
                                  disabled:
                                    _vm.manipulacaoContext.isDesabilitado ||
                                    _vm.vendaFaturada ||
                                    _vm.valorTabelado > 0,
                                },
                                model: {
                                  value: _vm.model.descontoPercentual,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.model,
                                      "descontoPercentual",
                                      $$v
                                    )
                                  },
                                  expression: "model.descontoPercentual",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-sm-3" }, [
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "tooltip",
                                  rawName: "v-tooltip",
                                  value: _vm.errors.first("desconto"),
                                  expression: "errors.first('desconto')",
                                },
                              ],
                              staticClass: "form-group",
                            },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "control-label",
                                  attrs: { for: "desconto" },
                                },
                                [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(
                                        _vm.$t(
                                          "__.Components.parent.manipulacao.edit_vue_html.desconto"
                                        )
                                      ) +
                                      "\n                            "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("moeda-component", {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: "required",
                                    expression: "'required'",
                                  },
                                ],
                                staticClass: "form-control",
                                attrs: {
                                  id: "desconto",
                                  name: "desconto",
                                  disabled:
                                    _vm.manipulacaoContext.isDesabilitado ||
                                    _vm.vendaFaturada ||
                                    _vm.valorTabelado > 0,
                                },
                                on: { change: _vm.onChangeDesconto },
                                model: {
                                  value: _vm.model.desconto,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.model, "desconto", $$v)
                                  },
                                  expression: "model.desconto",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-sm-3" }, [
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "control-label",
                                  attrs: { for: "valorTotal" },
                                },
                                [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(
                                        _vm.$t(
                                          "__.Components.parent.manipulacao.edit_vue_html.valorTotal"
                                        )
                                      ) +
                                      "\n                            "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _vm.utilizandoValorMinimo
                                ? _c("data-tooltip-component", {
                                    attrs: {
                                      text: _vm.$t(
                                        "__.Components.parent.manipulacao.edit_vue_html.utilizMinForma"
                                      ),
                                    },
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _c("moeda-component", {
                                directives: [
                                  {
                                    name: "mask-moeda",
                                    rawName: "v-mask-moeda",
                                  },
                                ],
                                staticClass: "form-control",
                                attrs: {
                                  disabled: "",
                                  id: "valorTotal",
                                  name: "valorTotal",
                                },
                                model: {
                                  value: _vm.model.valorTotal,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.model, "valorTotal", $$v)
                                  },
                                  expression: "model.valorTotal",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ]),
                    ]
                  ),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "fieldset-component",
            {
              attrs: {
                title: _vm.$t(
                  "__.Components.parent.manipulacao.edit_vue_html.complementosManipu"
                ),
                collapsed: _vm.collapsedFieldsets,
              },
            },
            [
              _c("div", { attrs: { slot: "rows" }, slot: "rows" }, [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-sm-4" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("posologiaId"),
                            expression: "errors.first('posologiaId')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "posologiaId" },
                          },
                          [
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Components.parent.manipulacao.edit_vue_html.posologia"
                                  )
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        !_vm.isPosologiaTextoLivre || _vm.model.posologiaId
                          ? _c(
                              "combo-component",
                              {
                                attrs: {
                                  id: "posologiaId",
                                  name: "posologiaId",
                                  actionName: "posologias",
                                  addEditId: _vm.model.posologiaId,
                                  addNew: !_vm.model.posologiaId,
                                  customView: true,
                                  data: _vm.posologiaOptions,
                                  disabled:
                                    _vm.manipulacaoContext.isDesabilitado,
                                  formAddNewName:
                                    _vm.model.posologiaId > 0
                                      ? _vm.$t(
                                          "__.Components.parent.manipulacao.edit_vue_html.editPosologia"
                                        )
                                      : _vm.$t(
                                          "__.Components.parent.manipulacao.edit_vue_html.novaPosologia"
                                        ),
                                  modalClass: "lm-modal",
                                  refresh: _vm.loadPosologia,
                                  useCloseModalCallback: true,
                                },
                                on: {
                                  addNewItemCombo: _vm.onAddNewPosologia,
                                  openComboEditItem: _vm.openComboEditPosologia,
                                  openComboNewItem: _vm.openComboNewPosologia,
                                },
                                model: {
                                  value: _vm.model.posologiaId,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.model, "posologiaId", $$v)
                                  },
                                  expression: "model.posologiaId",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    attrs: { slot: "component" },
                                    slot: "component",
                                  },
                                  [
                                    _c("posologiaComponent", {
                                      ref: "posologiaComponent",
                                      attrs: { CalledByShortCut: true },
                                      on: { "save-ok": _vm.onPosologiaSaveOk },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            )
                          : _c("textarea", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.model.posologiaTexto,
                                  expression: "model.posologiaTexto",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: {
                                id: "posologiaTexto",
                                name: "posologiaTexto",
                                maxlength: "256",
                                rows: "3",
                              },
                              domProps: { value: _vm.model.posologiaTexto },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.model,
                                    "posologiaTexto",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-4" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("pacienteId"),
                            expression: "errors.first('pacienteId')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "pacienteId" },
                          },
                          [
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Components.parent.manipulacao.edit_vue_html.paciente"
                                  )
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "search-combo-component",
                          {
                            attrs: {
                              id: "pacienteId",
                              name: "pacienteId",
                              actionName: "pacientes",
                              addEditId: _vm.model.pacienteId,
                              addNew: !_vm.model.pacienteId,
                              advancedSearch: true,
                              advancedSearchCampos: [
                                {
                                  campo: "text",
                                  descricao: "Nome do paciente",
                                },
                                {
                                  campo: "clienteDescricao",
                                  descricao: "Nome do cliente",
                                },
                                {
                                  campo: "clienteDocumento",
                                  descricao: "Cpf/Cnpj do cliente",
                                },
                                {
                                  campo: "clienteTelefone",
                                  descricao: "Telefone do cliente",
                                },
                                {
                                  campo: "clienteCelular",
                                  descricao: "Celular do cliente",
                                },
                                {
                                  campo: "especieAnimalDescricao",
                                  descricao: "Espécie",
                                },
                              ],
                              customComboText: _vm.customComboTextPaciente,
                              customView: true,
                              disabled: _vm.manipulacaoContext.isDesabilitado,
                              formAddNewName:
                                _vm.model.pacienteId > 0
                                  ? _vm.$t(
                                      "__.Components.parent.manipulacao.edit_vue_html.editPaciente"
                                    )
                                  : _vm.$t(
                                      "__.Components.parent.manipulacao.edit_vue_html.novoPaciente"
                                    ),
                              modalClass: "lm-modal",
                              propDescription: "nome",
                              service: "Paciente",
                              useCloseModalCallback: true,
                            },
                            on: {
                              addNewItemCombo: _vm.onAddNewPaciente,
                              openComboEditItem: _vm.openComboEditPaciente,
                              openComboNewItem: _vm.openComboNewPaciente,
                            },
                            model: {
                              value: _vm.model.pacienteId,
                              callback: function ($$v) {
                                _vm.$set(_vm.model, "pacienteId", $$v)
                              },
                              expression: "model.pacienteId",
                            },
                          },
                          [
                            _vm.showPaciente
                              ? _c(
                                  "div",
                                  {
                                    attrs: { slot: "component" },
                                    slot: "component",
                                  },
                                  [
                                    _c("pacienteComponent", {
                                      ref: "pacienteComponent",
                                      attrs: { CalledByShortCut: true },
                                      on: { "save-ok": _vm.onPacienteSaveOk },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-4" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("prescritorId"),
                            expression: "errors.first('prescritorId')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "prescritorId" },
                          },
                          [
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Components.parent.manipulacao.edit_vue_html.prescritor"
                                  )
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "search-combo-component",
                          {
                            attrs: {
                              id: "prescritorId",
                              name: "prescritorId",
                              actionName: "prescritores",
                              addEditId: _vm.model.prescritorId,
                              addNew: !_vm.model.prescritorId,
                              advancedSearch: true,
                              advancedSearchCampos: [
                                { campo: "text", descricao: "Nome" },
                                {
                                  campo: "numeroRegistro",
                                  descricao: "Número de registro",
                                },
                                {
                                  campo: "tipoConselhoRegionalDescricao",
                                  descricao: "Conselho Regional",
                                },
                                { campo: "cpf", descricao: "CNPJ/CPF" },
                              ],
                              customComboText: _vm.customComboTextPrescritor,
                              customView: true,
                              disabled: _vm.manipulacaoContext.isDesabilitado,
                              formAddNewName:
                                _vm.model.prescritorId > 0
                                  ? _vm.$t(
                                      "__.Components.parent.manipulacao.edit_vue_html.editPrescritor"
                                    )
                                  : _vm.$t(
                                      "__.Components.parent.manipulacao.edit_vue_html.novoPrescritor"
                                    ),
                              propDescription: "nome",
                              service: "Prescritor",
                              useCloseModalCallback: true,
                            },
                            on: {
                              addNewItemCombo: _vm.onAddNewPrescritor,
                              openComboEditItem: _vm.openComboEditPrescritor,
                              openComboNewItem: _vm.openComboNewPrescritor,
                            },
                            model: {
                              value: _vm.model.prescritorId,
                              callback: function ($$v) {
                                _vm.$set(_vm.model, "prescritorId", $$v)
                              },
                              expression: "model.prescritorId",
                            },
                          },
                          [
                            _vm.showPrescritor
                              ? _c(
                                  "div",
                                  {
                                    attrs: { slot: "component" },
                                    slot: "component",
                                  },
                                  [
                                    _c("prescritorComponent", {
                                      ref: "prescritorComponent",
                                      attrs: { CalledByShortCut: true },
                                      on: { "save-ok": _vm.onPrescritorSaveOk },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _vm.manipulacaoContext.isItemFromVenda
                    ? _c("div", { staticClass: "col-sm-3" }, [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: _vm.errors.first("dataValidade"),
                                expression: "errors.first('dataValidade')",
                              },
                            ],
                            staticClass: "form-group",
                          },
                          [
                            _c(
                              "label",
                              {
                                staticClass: "control-label",
                                attrs: {
                                  for: "dataValidade",
                                  title: _vm.$t("__.obrigatorio"),
                                },
                              },
                              [
                                _c("span", { staticStyle: { color: "red" } }, [
                                  _vm._v("*"),
                                ]),
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(
                                      _vm.$t(
                                        "__.Components.parent.manipulacao.edit_vue_html.dataValidadeManipulacao"
                                      )
                                    ) +
                                    "\n                            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("date-time-picker-component", {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "required",
                                  expression: "'required'",
                                },
                              ],
                              attrs: {
                                id: "dataValidade",
                                name: "dataValidade",
                                disabled: _vm.manipulacaoContext.isDesabilitado,
                              },
                              on: { input: _vm.onChangeDataValidadeAlterada },
                              model: {
                                value: _vm.model.dataValidade,
                                callback: function ($$v) {
                                  _vm.$set(_vm.model, "dataValidade", $$v)
                                },
                                expression: "model.dataValidade",
                              },
                            }),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.model.homeopatia
                    ? _c(
                        "div",
                        {
                          class: _vm.manipulacaoContext.isItemFromVenda
                            ? "col-sm-3"
                            : "col-sm-6",
                        },
                        [
                          _c("div", { staticClass: "form-group" }, [
                            _c("label", { attrs: { for: "numeroReceita" } }, [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(
                                    _vm.$t(
                                      "__.Components.parent.manipulacao.edit_vue_html.nroReceita"
                                    )
                                  ) +
                                  "\n                            "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.model.numeroReceita,
                                  expression: "model.numeroReceita",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: {
                                autocomplete: "false",
                                id: "numeroReceita",
                                name: "numeroReceita",
                                maxlength: "100",
                                disabled: _vm.manipulacaoContext.isDesabilitado,
                              },
                              domProps: { value: _vm.model.numeroReceita },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.model,
                                    "numeroReceita",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                          ]),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.model.homeopatia &&
                  _vm.manipulacaoContext.isItemFromVenda
                    ? _c("div", { staticClass: "col-sm-3" }, [
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c(
                              "label",
                              {
                                staticClass: "control-label",
                                attrs: { for: "dataValidade" },
                              },
                              [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(
                                      _vm.$t(
                                        "__.Components.parent.manipulacao.edit_vue_html.dataValidadeReceita"
                                      )
                                    ) +
                                    "\n                            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("date-time-picker-component", {
                              attrs: {
                                id: "dataValidade",
                                name: "dataValidade",
                              },
                              model: {
                                value: _vm.model.dataValidadeReceita,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.model,
                                    "dataValidadeReceita",
                                    $$v
                                  )
                                },
                                expression: "model.dataValidadeReceita",
                              },
                            }),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.model.homeopatia
                    ? _c("div", { staticClass: "col-sm-3" }, [
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c(
                              "label",
                              {
                                staticClass: "control-label",
                                attrs: { for: "manipuladorId" },
                              },
                              [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(
                                      _vm.$t(
                                        "__.Components.parent.manipulacao.edit_vue_html.manipulador"
                                      )
                                    ) +
                                    "\n                            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("combo-component", {
                              attrs: {
                                id: "manipuladorId",
                                name: "manipuladorId",
                                data: _vm.colaboradoresOptions,
                                searchable: true,
                                disabled: _vm.manipulacaoContext.isDesabilitado,
                              },
                              model: {
                                value: _vm.model.manipuladorId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.model, "manipuladorId", $$v)
                                },
                                expression: "model.manipuladorId",
                              },
                            }),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                ]),
                _vm._v(" "),
                !_vm.model.homeopatia
                  ? _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-sm-12" }, [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: _vm.errors.first("observacao"),
                                expression: "errors.first('observacao')",
                              },
                            ],
                            staticClass: "form-group",
                          },
                          [
                            _c(
                              "label",
                              {
                                staticClass: "control-label",
                                attrs: { for: "observacao" },
                              },
                              [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(
                                      _vm.$t(
                                        "__.Components.parent.manipulacao.edit_vue_html.obs"
                                      )
                                    ) +
                                    "\n                            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("textareaComponent", {
                              staticClass: "form-control",
                              attrs: {
                                id: "observacao",
                                name: "observacao",
                                disabled: "",
                                rows: 4,
                              },
                              model: {
                                value: _vm.model.observacao,
                                callback: function ($$v) {
                                  _vm.$set(_vm.model, "observacao", $$v)
                                },
                                expression: "model.observacao",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-sm-12" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("observacao"),
                            expression: "errors.first('observacao')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "observacao" },
                          },
                          [
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Components.parent.manipulacao.edit_vue_html.obsUsuario"
                                  )
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("textareaComponent", {
                          staticClass: "form-control",
                          attrs: {
                            id: "observacaoUsuario",
                            name: "observacaoUsuario",
                            disabled: _vm.manipulacaoContext.isDesabilitado,
                            rows: 4,
                          },
                          model: {
                            value: _vm.model.observacaoUsuario,
                            callback: function ($$v) {
                              _vm.$set(_vm.model, "observacaoUsuario", $$v)
                            },
                            expression: "model.observacaoUsuario",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
            ]
          ),
          _vm._v(" "),
          !_vm.manipulacaoContext.isItemFromVenda && !_vm.CalledByShortCut
            ? _c("fieldset", { staticClass: "ignorePrint" }, [
                _c("div", { staticClass: "row ignorePrint" }, [
                  _c("div", { staticClass: "col-sm-12 newbtn" }, [
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        !_vm.manipulacaoContext.isDesabilitado
                          ? _c("buttonScComponent", {
                              attrs: {
                                classButton: "btn btn-success",
                                buttonName: _vm.manipulacaoContext
                                  .isItemFromVenda
                                  ? this.model.id > 0
                                    ? _vm.$t(
                                        "__.Components.parent.manipulacao.edit_vue_html.salvarAlt"
                                      )
                                    : _vm.$t(
                                        "__.Components.parent.manipulacao.edit_vue_html.add"
                                      )
                                  : _vm.$t("__.Crud.salvar"),
                                shortKeyBt: "s",
                              },
                              on: {
                                click: function ($event) {
                                  _vm.onAdicionarManipulacao().withLoading()
                                },
                              },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.validarVisibilidadeBotao("concluir")
                          ? _c(
                              "button",
                              {
                                staticClass: "btn btn-primary pull-right",
                                staticStyle: {
                                  "background-color": "coral !important",
                                },
                                attrs: { type: "button" },
                                on: {
                                  click: function ($event) {
                                    _vm.onConcluirManipulacao().withLoading()
                                  },
                                },
                              },
                              [
                                _c("i", {
                                  staticClass: "fa fa-check",
                                  staticStyle: {
                                    "font-size": "16px !important",
                                    "margin-right": "3px",
                                  },
                                  attrs: { "aria-hidden": "true" },
                                }),
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(
                                      _vm.$t(
                                        "__.Components.parent.manipulacao.edit_vue_html.concluir"
                                      )
                                    ) +
                                    "\n                        "
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        !_vm.manipulacaoContext.isItemFromVenda
                          ? _c("buttonScComponent", {
                              attrs: {
                                classButton: "btn btn-secondary",
                                buttonName: _vm.$t("__.Crud.voltar"),
                                shortKeyBt: "r",
                              },
                              on: {
                                click: function ($event) {
                                  _vm.back()
                                },
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.manipulacaoContext.isItemFromVenda
            ? _c(
                "fieldset-component",
                {
                  attrs: {
                    title: _vm.$t(
                      "__.Components.parent.venda.itemwrapper_vue_html.continuos"
                    ),
                    collapsed: true,
                  },
                },
                [
                  _c("div", { attrs: { slot: "rows" }, slot: "rows" }, [
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-sm-2" }, [
                        _c("div", { staticClass: "form-group" }, [
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c(
                                "label",
                                { attrs: { for: "chkAtualizarParcelar" } },
                                [
                                  _vm._v(
                                    "\n                                    " +
                                      _vm._s(
                                        _vm.$t(
                                          "__.Components.parent.manipulacao.edit_vue_html.continuo"
                                        )
                                      ) +
                                      "\n                                "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("checkboxComponent", {
                                attrs: {
                                  id: "checkContinuo",
                                  name: "checkContinuo",
                                  disabled:
                                    _vm.manipulacaoContext.isDesabilitado,
                                },
                                model: {
                                  value: _vm.continuo,
                                  callback: function ($$v) {
                                    _vm.continuo = $$v
                                  },
                                  expression: "continuo",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-sm-2" }, [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: _vm.errors.first(
                                  "dataVencimentoContinuo"
                                ),
                                expression:
                                  "errors.first('dataVencimentoContinuo')",
                              },
                            ],
                            staticClass: "form-group",
                          },
                          [
                            _c(
                              "label",
                              {
                                staticClass: "control-label",
                                attrs: { for: "dataVencimentoContinuo" },
                              },
                              [
                                _vm.continuo
                                  ? _c(
                                      "span",
                                      { staticStyle: { color: "red" } },
                                      [_vm._v("*")]
                                    )
                                  : _vm._e(),
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(
                                      _vm.$t(
                                        "__.Components.parent.venda.editSingle_vue_html.dtVencimentoContinuo"
                                      )
                                    ) +
                                    "\n                            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("date-time-picker-component", {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: {
                                    rules: { required: _vm.continuo },
                                  },
                                  expression:
                                    "{\n                                    rules: { required: continuo },\n                                }",
                                },
                              ],
                              attrs: {
                                id: "dataVencimentoContinuo",
                                name: "dataVencimentoContinuo",
                              },
                              model: {
                                value: _vm.dataVencimentoContinuo,
                                callback: function ($$v) {
                                  _vm.dataVencimentoContinuo = $$v
                                },
                                expression: "dataVencimentoContinuo",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]),
                  ]),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      !_vm.manipulacaoContext.isItemFromVenda
        ? _c("impressaoComponent", {
            ref: "impressaoComponent",
            on: { "hide-modal": _vm.hideImpressao },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showLote
        ? [
            _c(
              "shortcutComponent",
              {
                ref: "shortcutLoteComponent",
                attrs: {
                  showAddNew: false,
                  btOkName: _vm.$t(
                    "__.Components.parent.manipulacao.edit_vue_html.atualizDadosLotes"
                  ),
                },
                on: {
                  "hide-click": _vm.hideLote,
                  "selection-item": function ($event) {
                    _vm.onConfirmLote().withLoading()
                  },
                },
              },
              [
                _c(
                  "div",
                  { attrs: { slot: "component" }, slot: "component" },
                  [
                    _c("manipulacaoLoteItemComponent", {
                      ref: "manipulacaoLoteItemComponent",
                      attrs: {
                        volumeTotal: _vm.model.volumeTotal,
                        unidadeMedidaOp: _vm.unidadeMedidasOptions,
                      },
                      on: {
                        close: _vm.onCloseLote,
                        updateProdutoLoteItens: _vm.updateProdutoLoteItens,
                      },
                    }),
                  ],
                  1
                ),
              ]
            ),
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.showDetalhes
        ? [
            _c(
              "shortcutComponent",
              {
                ref: "shortcutDetalhesComponent",
                attrs: {
                  headerColor: "yellow",
                  showAddNew: false,
                  btOkName: "OK",
                },
                on: { "selection-item": _vm.onConfirmDetalhes },
              },
              [
                _c(
                  "div",
                  { attrs: { slot: "component" }, slot: "component" },
                  [
                    _c("detalhesItemComponent", {
                      ref: "detalhesItemComponent",
                    }),
                  ],
                  1
                ),
              ]
            ),
          ]
        : _vm._e(),
      _vm._v(" "),
      [
        _c(
          "shortcutComponent",
          {
            ref: "shortcutPreVendaComponent",
            attrs: {
              modalClass: "preVendaShortCut-modal",
              showAddNew: false,
              btOkName: "Adicionar",
            },
            on: { "selection-item": _vm.onConfirmPreVenda },
          },
          [
            _vm.showPreVenda
              ? _c(
                  "div",
                  { attrs: { slot: "component" }, slot: "component" },
                  [
                    _c("preVendaComponent", {
                      ref: "preVendaComponent",
                      on: { "inclusao-item": _vm.onLoadPreVenda },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ]
        ),
      ],
      _vm._v(" "),
      _c(
        "shortcutComponent",
        {
          ref: "shortcutMultiplicarDoseComponent",
          attrs: {
            modalClass: "ld-modal",
            showAddNew: false,
            title: _vm.$t(
              "__.Components.parent.manipulacao.edit_vue_html.multiplicarNumeroDose"
            ),
          },
          on: { "selection-item": _vm.onCloseMultiplicarDose },
        },
        [
          _c("div", { attrs: { slot: "component" }, slot: "component" }, [
            _c("span", { staticStyle: { color: "red" } }, [
              _vm._v(
                "\n                " +
                  _vm._s(
                    _vm.$t(
                      "__.Components.parent.manipulacao.edit_vue_html.mensagemMultiplicarNumeroDose"
                    )
                  ) +
                  "\n            "
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "row", staticStyle: { "margin-top": "8px" } },
              [
                _c("div", { staticClass: "col-sm-2" }, [
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c("label", { attrs: { for: "forcarMultQuantidade" } }, [
                        _vm._v(
                          "\n                            " +
                            _vm._s(
                              _vm.$t(
                                "__.Components.parent.manipulacao.edit_vue_html.forcarMultQuantidade"
                              )
                            ) +
                            "\n                        "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("checkboxComponent", {
                        attrs: {
                          id: "forcarMultQuantidade",
                          name: "forcarMultQuantidade",
                          disabled: _vm.manipulacaoContext.isDesabilitado,
                        },
                        model: {
                          value: _vm.model.forcarMultQuantidade,
                          callback: function ($$v) {
                            _vm.$set(_vm.model, "forcarMultQuantidade", $$v)
                          },
                          expression: "model.forcarMultQuantidade",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-sm-10" }, [
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c(
                        "label",
                        {
                          staticClass: "control-label",
                          attrs: { for: "fatorMultQuantidade" },
                        },
                        [
                          _vm._v(
                            "\n                            " +
                              _vm._s(
                                _vm.$t(
                                  "__.Components.parent.manipulacao.edit_vue_html.fatorMultQuantidade"
                                )
                              ) +
                              "\n                        "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("decimal-component", {
                        staticClass: "form-control",
                        attrs: {
                          id: "fatorMultQuantidade",
                          name: "fatorMultQuantidade",
                          disabled: _vm.manipulacaoContext.isDesabilitado,
                        },
                        model: {
                          value: _vm.model.fatorMultQuantidade,
                          callback: function ($$v) {
                            _vm.$set(_vm.model, "fatorMultQuantidade", $$v)
                          },
                          expression: "model.fatorMultQuantidade",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]
            ),
          ]),
        ]
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }