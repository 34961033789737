import Vue from "vue";

import { Component, Prop } from "@/decorators";

@Component
export default class TextareaComponent extends Vue {
    @Prop(String) id: string;
    @Prop(String) name: string;
    @Prop(String) value: string;

    objTextArea: any;
    nLinhas = 1;

    private countLinha() {
        if (this.objTextArea["scrollHeight"] > this.objTextArea["offsetHeight"]) {
            if (this.nLinhas >= 10) {
                return;
            }
            this.nLinhas++;
        }
    }

    private mounted() {
        this.objTextArea = this.$refs.textarea;
    }

    private updated() {
        this.countLinha();
    }
}
