import Vue from "vue";
import { mapGetters, mapMutations, mapState } from "vuex";

import comboComponent from "@/components/child/form/combo.vue";
import Grid from "@/components/child/grid/grid";
import gridComponent from "@/components/child/grid/grid.vue";
import { GridColumn, GridColumnType } from "@/components/child/grid/gridColumn";
import { Component, Watch } from "@/decorators";
import PaginationPackModel from "@/models/auxiliar/paginationPackModel";
import PaginationModel from "@/models/paginationModel";
import GrupoProdutoModel from "@/models/produto/grupoProdutoModel";
import ProdutoModel, { ProdutoListParameters } from "@/models/produto/produtoModel";
import GrupoProdutoService from "@/services/produto/grupoProdutoService";
import ProdutoService from "@/services/produto/produtoService";
import { AppState, Getters } from "@/store/store";
import Delay from "@/utils/common/delay";

import "../crud.scss";

@Component({
    components: {
        gridComponent,
        comboComponent,
    },
    computed: {
        ...mapState<AppState>({
            loadingPack: state => state.paginationStatus.loading,
            paginationParams: state => state.paginationStatus.paginationParams,
        }),
        ...mapGetters(["HAS_PERMISSAO_PERFIL"] as Getters),
    },
    methods: mapMutations(["LOAD_LIST", "SET_PARAMS", "CLEAR_LISTSPACK", "GET_PARAMS"]),
})
export default class ProdutoListComponent extends Vue {
    // State computed props
    loadingPack: boolean;
    paginationParams: PaginationPackModel;
    SET_PARAMS: (obj: object) => void;
    GET_PARAMS: (path: string) => Promise<void>;
    HAS_PERMISSAO_PERFIL: (isRede: boolean, isFranqueador: boolean, isSuporte: boolean) => Promise<boolean>;

    private service = new ProdutoService();
    private gridcomponent: Grid = null;

    startFilterKey = "";
    gridFilterKey = "";
    gridSortKey = "descricao";
    gridSortOrder = "asc";
    gridData: Array<ProdutoModel> = [];
    get gridColumns(): Array<GridColumn> {
        return [
            new GridColumn("id", "Código", GridColumnType.String),
            new GridColumn("descricao", "Descri\u00e7\u00e3o", GridColumnType.String),
            new GridColumn("sinonimos", "Sinônimos", GridColumnType.String, false, false, "", "", false, false),
            new GridColumn("grupo", "Grupo", GridColumnType.String),
            new GridColumn("ativo", "Ativo", GridColumnType.Boolean),
        ];
    }

    public hideExtras = false;
    grupoProdutoOptions: Array<Object> = [];
    subGrupoOptions: Array<Object> = [
        { text: "Ativo", value: 0 },
        { text: "Flavorizante", value: 1 },
        { text: " Veículos/Excipientes", value: 2 },
    ];
    situacaoProdutoOptions: Array<Object> = [
        { text: "Produtos Ativos", value: 1 },
        { text: "Produtos Inativos", value: 0 },
        { text: "Todos os Produtos", value: -1 },
    ];
    loadedPagination = false;
    pageIndex = 1;
    pageSize = 20;
    total = 0;
    filtro: Array<Object> = [];
    isHidden = false;
    apareceSubGrupo?: number = null;
    situacaoProdutoFiltro?: number = null;
    grupoProdutoFiltro?: number = null;
    subGrupo?: number = null;
    pags = false;
    tipoDocGerar: number = null;
    isAdmin = false;

    private async load() {
        const customParameters: ProdutoListParameters = {
            combo: this.filtro[0] != null ? this.filtro[0].toString() : "",
            sub: this.filtro[1] != null ? this.filtro[1].toString() : "",
            somenteAtivos: this.filtro[2] == null || this.filtro[2] === -1 ? null : Boolean(this.filtro[2]),
        };
        try {
            const data = await this.service
                .list(
                    this.gridFilterKey,
                    this.gridSortKey,
                    this.gridSortOrder,
                    this.pags ? 1 : this.pageIndex,
                    this.pags ? 999999 : this.pageSize,
                    customParameters,
                )
                .withLoading()
                .resolveWithJSON<PaginationModel<ProdutoModel>>();

            this.gridData = data.list;
            this.total = data.total;
            this.pageIndex = data.pageIndex;
            this.pageSize = data.pageSize;

            //atualiza params no store
            if (data.total > 0) {
                this.SET_PARAMS({
                    routePath: this.$route.path,
                    filterKey: this.gridFilterKey,
                    sortKey: this.gridSortKey,
                    sortOrder: this.gridSortOrder,
                    pageIndex: this.pageIndex,
                    pageSize: this.pageSize,
                    filtros: this.filtro,
                });
            }

            this.isHidden = false;

            if (this.pags) {
                if (this.tipoDocGerar == 0) {
                    setTimeout(() => {
                        this.gridcomponent.gerarXls();
                    }, 2000);
                }

                if (this.tipoDocGerar == 1) {
                    setTimeout(() => {
                        this.gridcomponent.gerarPdf();
                    }, 2000);
                }

                if (this.tipoDocGerar == 2) {
                    setTimeout(() => {
                        this.gridcomponent.gerarImpressao();
                    }, 2000);
                }

                setTimeout(() => {
                    this.pags = false;
                }, 2000);
            }
        } catch {}
    }

    private async loadGruposProduto() {
        try {
            const data = await new GrupoProdutoService().combo().resolveWithJSON<PaginationModel<GrupoProdutoModel>>();

            this.grupoProdutoOptions = data.list.map(item => ({
                value: item.id,
                text: item.descricao,
                tipo: item.tipoProduto,
            }));
        } catch {}
    }

    private getFormValues() {
        this.filtro = [];
        if (this.grupoProdutoFiltro != 2) {
            this.subGrupo = null;
        }
        this.filtro.push(this.grupoProdutoFiltro);
        this.filtro.push(this.subGrupo);
        this.filtro.push(this.situacaoProdutoFiltro);
        this.pageIndex = 1;
        this.load();
    }

    private onEditItem(model: ProdutoModel) {
        this.$router.push("/produto-edicao/" + model.id);
    }

    private async onRemoveItem(model: ProdutoModel) {
        const response = await this.$showExclusaoQuestion(`${this.$t("__.ts.desejaExcl")}${model.descricao}?`);

        if (response) {
            try {
                const sucesso = await this.service.delete(model.id).withLoading().resolveWithoutJSON();
                if (sucesso) {
                    this["LOAD_LIST"]({ loadProdutos: true });
                    this.load();
                    this.$showExclusao();
                }
            } catch {}
        }
    }

    @Watch("pags")
    private onPags() {
        this.load();
    }

    @Watch("grupoProdutoFiltro")
    private onSub() {
        this.apareceSubGrupo = this.grupoProdutoFiltro;
    }

    private onCreateItem() {
        this.$router.push("/produto-edicao");
    }

    private onChangeFilterKey(filterKey: string) {
        this.gridFilterKey = filterKey;
        this.pageIndex = 1;
        this.load();
    }

    private onChangeSort(sortKey: string, sortOrder: string) {
        this.gridSortKey = sortKey;
        this.gridSortOrder = sortOrder;
        this.load();
    }

    private onChangePage(pageIndex: number) {
        this.pageIndex = pageIndex;
        this.load();
    }
    private async loadPagination() {
        await this.GET_PARAMS(this.$route.path);
        while (this.loadingPack) await Delay(50);

        if (this.paginationParams != null) {
            this.gridFilterKey = this.paginationParams.filterKey;
            this.startFilterKey = this.gridFilterKey;

            this.gridSortKey = this.paginationParams.sortKey;
            this.gridSortOrder = this.paginationParams.sortOrder;
            this.pageIndex = this.paginationParams.pageIndex;
            this.pageSize = this.paginationParams.pageSize;
            if (this.paginationParams.filtros != null) {
                this.filtro = this.paginationParams.filtros;
                if (this.filtro.length >= 0) {
                    this.grupoProdutoFiltro = <number>this.filtro[0];
                    if (this.filtro.length >= 1) this.subGrupo = <number>this.filtro[1];
                }
            }
        }
        this.loadedPagination = true;
    }

    private async atualizaMarkup() {
        try {
            const response = await this.service.atualizarValorVenda().withLoading().resolveWithResponse();
            if (response.ok) {
                this.$showSuccess(this.$t("__.ts.atualizacao"), this.$t("__.ts.vlrsVendaAtualizSucess"));
            }
        } catch {}
    }

    private onChangePageSize(newPageSize) {
        this.pageIndex = 1;
        this.pageSize = newPageSize;
        this.load();
    }

    private beforeMount() {
        this.loadPagination();
    }

    private async mounted() {
        this.isAdmin = await this.HAS_PERMISSAO_PERFIL(true, false, false);

        this.gridcomponent = this.$refs.gridcomponent as Grid;

        Promise.all([this.loadGruposProduto()])
            .withLoading()
            .then(() => {
                this.load();
            })
            .catch(() => {});
    }
}
