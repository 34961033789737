import BaseModel from "./base/baseModel";
import TipoCadastro from "./enum/tipoCadastro";
import TipoOperacao from "./enum/tipoOperacao";
import ContaPagarModel from "./financeiro/contaPagarModel";
import MovimentoEstoque from "./movimentacaoEstoqueModel";
import NotaFiscalEntradaTotalICMS from "./notafiscaleletronica/dados/informacoes/totais/icms/totalIcmsNfeModel";
import NotaFiscalEntradaTotalISSQN from "./notafiscaleletronica/dados/informacoes/totais/issqn/totalIssqnNfeModel";
import NotaFiscalEntradaTotalRetTrib from "./notafiscaleletronica/dados/informacoes/totais/retTrib/totalRetTribNfeModel";
import VolumeModel from "./notafiscaleletronica/dados/informacoes/transporte/volume/volumeNfeModel";
import { Pagamento } from "./notaFiscalEletronicaEmissaoModel";
import NotaFiscalEntradaItemModel from "./notaFiscalEntradaItemModel";

export default class NotaFiscalEntradaModel extends BaseModel {
    id?: number = null;
    serie: number = null;
    numero: number = null;
    dataInclusao: Date = null;
    dataEmissao: Date = null;
    importacaoXml: boolean = null;
    franquiaId = 0;
    tipoCadastro: TipoCadastro = 0;
    clienteId = 0;
    clienteNome: string = null;
    fornecedorId = 0;
    fornecedorNome: string = null;
    transportadoraId?: number = 0;
    transportadoraNome?: string = null;
    valorTotal: number = null;
    contaPagarId = 0;
    contaPagar: ContaPagarModel = null;
    notaFiscalEntradaItens: Array<NotaFiscalEntradaItemModel> = [];
    volumes: Array<VolumeModel> = [];
    notaFiscalEntradaTotalICMS: NotaFiscalEntradaTotalICMS = null;
    notaFiscalEntradaTotalISSQN: NotaFiscalEntradaTotalISSQN = null;
    notaFiscalEntradaTotalRetTrib: NotaFiscalEntradaTotalRetTrib = null;
    movimentacaoEstoqueId?: number = null;
    movimentacaoEstoque: MovimentoEstoque = null;
    atualizarCustoProdutos?: boolean = false;

    emitidoAPI = false;
    chave: string = null;

    tipoOperacao: TipoOperacao = 0;
    modelo = 55;
    finalidadeEmissao = 0;
    indicadorPresenca = 0;
    indicativoIntermedio = 0;
    indicadorOperacao = 0;
    observacao: string = null;
    modalidadeFrete = 0;
    transportadoraPlacaVeiculo: string = null;

    pagamentos: Pagamento[] = [];

    valorTroco = 0;

    // Retensões
    valorPIS = 0;
    valorCOFINS = 0;
    valorCSLL = 0;
    valorBaseIRRF = 0;
    valorIRRF = 0;
    valorBasePrev = 0;
    valorPrev = 0;

    chaveReferenciada: string = null;
    gerarContasPagar = true;
}
