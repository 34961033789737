import BaseModel from "./base/baseModel";
import TiposPessoa from "./enum/tiposPessoa";
import Ufs from "./enum/ufs";

export default class FornecedorModel extends BaseModel {
    id?: number = null;
    dataCriacao: Date = null;
    tipoPessoa: TiposPessoa = TiposPessoa.Juridica;
    nome: string = null;
    nomeFantasia: string = null;
    cpf: string = null;
    rg: string = null;
    razaoSocial: string = null;
    cnpj: string = null;
    inscricaoEstadual: string = null;
    cep: string = null;
    estadoId?: Ufs = null;
    cidadeId?: number = null;
    cidade: string = null;
    endereco: string = null;
    bairro: string = null;
    numero: string = null;
    complemento: string = null;
    telefone: string = null;
    celular: string = null;
    fax: string = null;
    email: string = null;
    homepage: string = null;
    contatoNome: string = null;
    contatoTelefone: string = null;
    contaGerencialId?: number = null;
    exterior = false;
    idEstrangeiro: string = null;
    pais?: string = "Brasil";
    codigoPais?: number = 1058;
    contribuinteEstadual = false;
    ativo = true;
    dataValidadeLicencaSanitaria?: Date = null;
    franquiaId?: number = null;
}
