enum StatusProdutoLote {
    Quarentena = 1,
    AnaliseCQ = 2,
    Reprovado = 3,
    Aprovado = 4,
    Fracionamento = 5,
    AguardandoLiberacao = 6,
    Liberado = 7,
}

export default StatusProdutoLote;
