import $ from "jquery";
import "inputmask/dist/inputmask/jquery.inputmask.js";
import "inputmask/dist/inputmask/inputmask.numeric.extensions.js";

const Mask = {
    install(Vue, options) {
        Vue.directive("mask-cpf", {
            inserted: function (el) {
                $(el).inputmask({
                    mask: "999.999.999-99",
                    clearMaskOnLostFocus: false,
                });
            },
        });

        Vue.directive("mask-rg", {
            inserted: function (el) {
                $(el).inputmask({
                    mask: "9999999999",
                    clearMaskOnLostFocus: false,
                });
            },
        });

        Vue.directive("mask-cnpj", {
            inserted: function (el) {
                $(el).inputmask({
                    mask: "99.999.999/9999-99",
                    clearMaskOnLostFocus: false,
                });
            },
        });

        Vue.directive("mask-cep", {
            inserted: function (el) {
                $(el).inputmask({
                    mask: "99999-999",
                    clearMaskOnLostFocus: false,
                });
            },
        });

        Vue.directive("mask-telefone", {
            inserted: function (el) {
                $(el).inputmask({
                    mask: ["(99) 9999-9999", "(99) 99999-9999"],
                    clearMaskOnLostFocus: false,
                });
            },
        });

        Vue.directive("mask-data", {
            inserted: function (el) {
                $(el).inputmask({
                    mask: "99/99/9999",
                    clearMaskOnLostFocus: false,
                });
            },
        });

        Vue.directive("mask-data-hora", {
            inserted: function (el) {
                $(el).inputmask({
                    clearMaskOnLostFocus: false,
                    mask: "99/99/9999 99:99",
                    regex: "^([1-9]|([012][0-9])|(3[01]))-([0]{0,1}[1-9]|1[012])-dddds([0-1]?[0-9]|2?[0-3]):([0-5]d)$",
                });
            },
        });

        Vue.directive("mask-hora", {
            inserted: function (el) {
                $(el).inputmask({
                    clearMaskOnLostFocus: false,
                    mask: "99:99",
                    regex: "^s([0-1]?[0-9]|2?[0-3]):([0-5]d)$",
                });
            },
        });

        Vue.directive("mask-moeda", {
            inserted: function (el) {
                $(el).inputmask({
                    alias: "numeric",
                    allowMinus: false,
                    autoGroup: true,
                    clearMaskOnLostFocus: false,
                    digits: 2,
                    digitsOptional: false,
                    integerDigits: 12,
                    min: 0,
                    placeholder: "0,00",
                    prefix: "R$ ",
                    radixPoint: ",",
                    rightAlign: false,
                });
            },
        });

        Vue.directive("mask-moeda-decimal", {
            inserted: function (el) {
                $(el).inputmask({
                    alias: "numeric",
                    allowMinus: false,
                    autoGroup: true,
                    clearMaskOnLostFocus: false,
                    digits: 4,
                    digitsOptional: false,
                    integerDigits: 12,
                    min: 0,
                    placeholder: "0,0000",
                    prefix: "R$ ",
                    radixPoint: ",",
                    rightAlign: false,
                });
            },
        });

        Vue.directive("mask-moeda-2-decimal", {
            inserted: function (el) {
                $(el).inputmask({
                    alias: "numeric",
                    allowMinus: false,
                    autoGroup: true,
                    clearMaskOnLostFocus: false,
                    digits: 2,
                    digitsOptional: false,
                    integerDigits: 12,
                    min: 0,
                    placeholder: "0,00",
                    prefix: "R$ ",
                    radixPoint: ",",
                    rightAlign: false,
                });
            },
        });

        Vue.directive("mask-moeda-com-sinal", {
            inserted: function (el) {
                $(el).inputmask({
                    alias: "numeric",
                    allowMinus: true,
                    autoGroup: true,
                    clearMaskOnLostFocus: false,
                    digits: 2,
                    digitsOptional: false,
                    groupSeparator: ".",
                    integerDigits: 12,
                    min: -9999999999.99,
                    placeholder: "0,00",
                    prefix: "R$ ",
                    radixPoint: ",",
                    rightAlign: false,
                });
            },
        });

        Vue.directive("mask-decimal", {
            suf: "",
            inserted: function (el, binding, vnode) {
                const digits = binding.value ?? 4;
                const element = $(el);
                const suffix = element.attr("suffix");
                vnode.context.suf = suffix;
                element.inputmask({
                    alias: "numeric",
                    allowMinus: false,
                    clearMaskOnLostFocus: false,
                    digits,
                    integerDigits: 12,
                    placeholder: "0,00",
                    suffix: suffix ? " " + suffix : "",
                    radixPoint: ",",
                    rightAlign: false,
                });
            },
            update: function (el, binding, vnode) {
                const digits = binding.value ?? 4;
                const element = $(el);

                const suffix = element.attr("suffix");

                if (vnode.context.suf != suffix) {
                    vnode.context.suf = suffix;

                    element.inputmask({
                        alias: "numeric",
                        allowMinus: false,
                        clearMaskOnLostFocus: false,
                        digits,
                        integerDigits: 12,
                        placeholder: "0,00",
                        suffix: suffix ? " " + suffix : "",
                        radixPoint: ",",
                        rightAlign: false,
                    });
                }
            },
        });

        Vue.directive("focus", {
            inserted: function (el) {
                $(document).ready(function () {
                    el.focus();
                });
            },
        });
    },
};

export default Mask;
