var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "fast-action-grid-panel" }, [
    _c("div", { staticClass: "fast-action-grid-panel-left bg-background" }, [
      _c("div", [
        _c("div", { staticClass: "top-infos" }, [
          _c("span", { staticClass: "receber" }, [
            _vm._v(_vm._s(_vm._f("filterMoeda")(_vm.totalReceber))),
          ]),
          _vm._v(
            "\n                " +
              _vm._s(
                _vm.$t("__.Components.parent.fluxo.list_vue_html.aReceber")
              ) +
              "\n                "
          ),
          _c("span", { staticClass: "pagar" }, [
            _vm._v(_vm._s(_vm._f("filterMoeda")(_vm.totalPagar))),
          ]),
          _vm._v(
            "\n                " +
              _vm._s(
                _vm.$t("__.Components.parent.fluxo.list_vue_html.aPagar")
              ) +
              "\n                "
          ),
          _c("span", { staticClass: "small" }, [
            _vm._v(_vm._s(_vm._f("filterMoeda")(_vm.saldoFinal))),
          ]),
          _vm._v(
            "\n                " +
              _vm._s(
                _vm.$t("__.Components.parent.fluxo.list_vue_html.saldoFinal")
              ) +
              "\n            "
          ),
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "fast-action",
            staticStyle: {
              width: "100%",
              float: "left",
              height: "auto",
              margin: "17px 0 0",
            },
          },
          [
            _c(
              "div",
              {
                staticClass: "fast-action-panel",
                staticStyle: { "padding-bottom": "5px" },
              },
              [
                _c("h2", { staticClass: "fast-action-panel-title" }, [
                  _vm._v(
                    "\n                        " +
                      _vm._s(
                        _vm.$t(
                          "__.Components.parent.fluxo.list_vue_html.fluxoCaixa"
                        )
                      ) +
                      "\n                    "
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "ajusta-valores-grafico" }, [
                  _c("b", [
                    _vm._v(
                      "\n                            " +
                        _vm._s(
                          _vm.$t(
                            "__.Components.parent.fluxo.list_vue_html.metaMensal"
                          )
                        ) +
                        "\n                            "
                    ),
                    _c("span", [
                      _vm._v(_vm._s(_vm._f("filterMoeda")(_vm.metaMensal))),
                    ]),
                    _vm._v(
                      "\n                            |\n                        "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("b", [
                    _vm._v(
                      "\n                            " +
                        _vm._s(
                          _vm.$t(
                            "__.Components.parent.fluxo.list_vue_html.maiorMensal"
                          )
                        ) +
                        "\n                            "
                    ),
                    _c("span", [
                      _vm._v(_vm._s(_vm._f("filterMoeda")(_vm.maiorMensal))),
                    ]),
                    _vm._v(
                      "\n                            |\n                        "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("b", [
                    _vm._v(
                      "\n                            " +
                        _vm._s(
                          _vm.$t(
                            "__.Components.parent.fluxo.list_vue_html.totalAnual"
                          )
                        ) +
                        "\n                            "
                    ),
                    _c("span", [
                      _vm._v(_vm._s(_vm._f("filterMoeda")(_vm.totalAnual))),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "ajuste-receita-despesa" }, [
                    _c("span", [
                      _c("i", {
                        staticClass: "fa fa-circle color-light-green",
                      }),
                      _vm._v(
                        "\n                                " +
                          _vm._s(
                            _vm.$t(
                              "__.Components.parent.fluxo.list_vue_html.receita"
                            )
                          ) +
                          "\n                            "
                      ),
                    ]),
                    _vm._v(
                      "\n                            |\n                            "
                    ),
                    _c("span", [
                      _c("i", { staticClass: "fa fa-circle color-light-red" }),
                      _vm._v(
                        "\n                                " +
                          _vm._s(
                            _vm.$t(
                              "__.Components.parent.fluxo.list_vue_html.despesa"
                            )
                          ) +
                          "\n                            "
                      ),
                    ]),
                    _vm._v(
                      "\n                            |\n                            "
                    ),
                    _c("span", [
                      _c("i", { staticClass: "fa fa-circle color-light-blue" }),
                      _vm._v(
                        "\n                                " +
                          _vm._s(
                            _vm.$t(
                              "__.Components.parent.fluxo.list_vue_html.meta"
                            )
                          ) +
                          "\n                            "
                      ),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c("fast-action-grafico-component", {
                  ref: "fastActionGraficoComponent",
                  on: { "carregou-grafico": _vm.onCarregouGrafico },
                }),
              ],
              1
            ),
          ]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }