import { Module } from "vuex";

import { AppState } from "./store";

export type LoadDashState = {
    stateFranquias: object[]
}

export type LoadDashMutations = "SET_FRANQUIAS"

const loadDash: Module<LoadDashState, AppState> = {
    state: {
        stateFranquias: []
    },
    mutations: {
        SET_FRANQUIAS(store, obj) {
            store.stateFranquias = obj
        }
    }
}

export default loadDash;

