var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: { portlet: true, fullscreen: _vm.isFullScreen } }, [
    _c("div", { staticClass: "portlet-title" }, [
      _c("div", { staticClass: "caption" }, [
        _c("span", { staticClass: "caption-subject text-uppercase" }, [
          _vm._v(_vm._s(_vm.title)),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "actions" }, [
        _c(
          "a",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showFullScreenButton,
                expression: "showFullScreenButton",
              },
            ],
            staticClass: "btn btn-circle",
            on: { click: _vm.toggleFullScreen },
          },
          [
            !_vm.isFullScreen
              ? _c("i", {
                  staticClass: "fa fa-expand",
                  attrs: { "aria-hidden": "true" },
                })
              : _c("i", {
                  staticClass: "fa fa-compress",
                  attrs: { "aria-hidden": "true" },
                }),
          ]
        ),
        _vm._v(" "),
        _c(
          "a",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showRemoveButton,
                expression: "showRemoveButton",
              },
            ],
            staticClass: "btn btn-circle",
            on: { click: _vm.remove },
          },
          [
            _c("i", {
              staticClass: "fa fa-trash-alt",
              attrs: { "aria-hidden": "true" },
            }),
          ]
        ),
        _vm._v(" "),
        _c(
          "a",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showWarningButton,
                expression: "showWarningButton",
              },
            ],
            staticClass: "btn btn-warning",
            attrs: { title: _vm.titleWarning },
          },
          [
            _c("i", {
              staticClass: "fa fa-exclamation-triangle",
              staticStyle: { color: "darkorange" },
              attrs: { "aria-hidden": "true" },
            }),
          ]
        ),
        _vm._v(" "),
        _c(
          "a",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showErrorButton,
                expression: "showErrorButton",
              },
            ],
            staticClass: "btn btn-error",
            attrs: { title: _vm.titleError },
          },
          [
            _c("i", {
              staticClass: "fa fa-exclamation-circle",
              staticStyle: { color: "red" },
              attrs: { "aria-hidden": "true" },
            }),
          ]
        ),
        _vm._v(" "),
        _c(
          "a",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showCheckedButton,
                expression: "showCheckedButton",
              },
            ],
            staticClass: "btn btn-checked",
          },
          [
            _c("i", {
              staticClass: "fa fa-check",
              staticStyle: { color: "lime" },
              attrs: { "aria-hidden": "true" },
            }),
          ]
        ),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "portlet-body" },
      [
        _c("resize-observer", { on: { notify: _vm.resize } }),
        _vm._v(" "),
        _vm._t("content"),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }