import BaseModel from "./../base/baseModel";
import ContaPagarModel from "./contaPagarModel";
import ContaPagarPagamentoModel from "./contaPagarPagamentoModel";
import ContaReceberModel from "./contaReceberModel";
import ContaReceberRecebimentoModel from "./contaReceberRecebimentoModel";

export default class PagamentoContasModel extends BaseModel {
    contaPagarViewModel: ContaPagarModel = null;
    contaPagarPagamentoViewModel: ContaPagarPagamentoModel = null;

    contaReceberViewModel: ContaReceberModel = null;
    contaReceberRecebimentoViewModel: ContaReceberRecebimentoModel = null;
}
