var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "crud crud-list" }, [
    _c(
      "div",
      { attrs: { slot: "rows" }, slot: "rows" },
      [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-sm-4" }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "tooltip",
                    rawName: "v-tooltip",
                    value: _vm.errors.first("descricao"),
                    expression: "errors.first('descricao')",
                  },
                ],
                staticClass: "form-group",
              },
              [
                _c(
                  "label",
                  {
                    staticClass: "control-label",
                    attrs: {
                      for: "descricao",
                      title: _vm.$t("__.obrigatorio"),
                    },
                  },
                  [
                    _c("span", { staticStyle: { color: "red" } }, [
                      _vm._v("* "),
                    ]),
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "__.Components.parent.notafiscalentrada.financeiro_vue_html.descrDuplicata"
                        )
                      )
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.model.descricao,
                      expression: "model.descricao",
                    },
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required",
                      expression: "'required'",
                    },
                  ],
                  staticClass: "form-control",
                  attrs: { type: "text", id: "descricao", name: "descricao" },
                  domProps: { value: _vm.model.descricao },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.model, "descricao", $event.target.value)
                    },
                  },
                }),
              ]
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-sm-2" }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "tooltip",
                    rawName: "v-tooltip",
                    value: _vm.errors.first("dataVencimento"),
                    expression: "errors.first('dataVencimento')",
                  },
                ],
                staticClass: "form-group",
              },
              [
                _c(
                  "label",
                  {
                    staticClass: "control-label",
                    attrs: {
                      for: "dataVencimento",
                      title: _vm.$t("__.obrigatorio"),
                    },
                  },
                  [
                    _c("span", { staticStyle: { color: "red" } }, [
                      _vm._v("* "),
                    ]),
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "__.Components.parent.notafiscalentrada.financeiro_vue_html.dtVenc"
                        )
                      )
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("date-time-picker-component", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required",
                      expression: "'required'",
                    },
                  ],
                  attrs: { id: "dataVencimento", name: "dataVencimento" },
                  model: {
                    value: _vm.model.dataVencimento,
                    callback: function ($$v) {
                      _vm.$set(_vm.model, "dataVencimento", $$v)
                    },
                    expression: "model.dataVencimento",
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-sm-3" }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "tooltip",
                    rawName: "v-tooltip",
                    value: _vm.errors.first("contaGerencialId"),
                    expression: "errors.first('contaGerencialId')",
                  },
                ],
                staticClass: "form-group",
              },
              [
                _c(
                  "label",
                  {
                    staticClass: "control-label",
                    attrs: {
                      for: "contaGerencial",
                      title: _vm.$t("__.obrigatorio"),
                    },
                  },
                  [
                    _c("span", { staticStyle: { color: "red" } }, [
                      _vm._v("* "),
                    ]),
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "__.Components.parent.notafiscalentrada.financeiro_vue_html.contaGerencial"
                        )
                      )
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("combo-component", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required",
                      expression: "'required'",
                    },
                  ],
                  attrs: {
                    id: "contaGerencialId",
                    name: "contaGerencialId",
                    data: _vm.contasGerenciaisOptions,
                    searchable: false,
                  },
                  model: {
                    value: _vm.model.contaGerencialId,
                    callback: function ($$v) {
                      _vm.$set(_vm.model, "contaGerencialId", $$v)
                    },
                    expression: "model.contaGerencialId",
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-sm-3" }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "tooltip",
                    rawName: "v-tooltip",
                    value: _vm.errors.first("status"),
                    expression: "errors.first('status')",
                  },
                ],
                staticClass: "form-group",
              },
              [
                _c(
                  "label",
                  {
                    staticClass: "control-label",
                    attrs: { for: "status", title: _vm.$t("__.obrigatorio") },
                  },
                  [
                    _c("span", { staticStyle: { color: "red" } }, [
                      _vm._v("* "),
                    ]),
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "__.Components.parent.notafiscalentrada.financeiro_vue_html.status"
                        )
                      )
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("combo-component", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required",
                      expression: "'required'",
                    },
                  ],
                  attrs: {
                    id: "status",
                    name: "status",
                    data: _vm.statusContasOptions,
                    searchable: false,
                  },
                  model: {
                    value: _vm.model.status,
                    callback: function ($$v) {
                      _vm.$set(_vm.model, "status", $$v)
                    },
                    expression: "model.status",
                  },
                }),
              ],
              1
            ),
          ]),
        ]),
        _vm._v(" "),
        _vm.model.status > 0
          ? [
              _c(
                "fieldset-component",
                {
                  attrs: {
                    title: _vm.$t(
                      "__.Components.parent.notafiscalentrada.financeiro_vue_html.dadosPgto"
                    ),
                    collapsed: false,
                  },
                },
                [
                  _c("div", { attrs: { slot: "rows" }, slot: "rows" }, [
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-sm-4" }, [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: _vm.errors.first(
                                  "pagamento.dataMovimento "
                                ),
                                expression:
                                  "errors.first('pagamento.dataMovimento ')",
                              },
                            ],
                            staticClass: "form-group",
                          },
                          [
                            _c(
                              "label",
                              {
                                staticClass: "control-label",
                                attrs: {
                                  for: "pagamento.dataMovimento ",
                                  title: _vm.$t("__.obrigatorio"),
                                },
                              },
                              [
                                _c("span", { staticStyle: { color: "red" } }, [
                                  _vm._v("* "),
                                ]),
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "__.Components.parent.notafiscalentrada.financeiro_vue_html.dtPgto"
                                    )
                                  )
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("date-time-picker-component", {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "required",
                                  expression: "'required'",
                                },
                              ],
                              attrs: {
                                id: "pagamento.dataMovimento ",
                                name: "pagamento.dataMovimento",
                              },
                              model: {
                                value: _vm.model.movimentacoes[0].dataMovimento,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.model.movimentacoes[0],
                                    "dataMovimento",
                                    $$v
                                  )
                                },
                                expression:
                                  "model.movimentacoes[0].dataMovimento",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-sm-4" }, [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: _vm.errors.first(
                                  "pagamento.formaPagamentoId"
                                ),
                                expression:
                                  "errors.first('pagamento.formaPagamentoId')",
                              },
                            ],
                            staticClass: "form-group",
                          },
                          [
                            _c(
                              "label",
                              {
                                staticClass: "control-label",
                                attrs: {
                                  for: "pagamento.formaPagamentoId",
                                  title: _vm.$t("__.obrigatorio"),
                                },
                              },
                              [
                                _c("span", { staticStyle: { color: "red" } }, [
                                  _vm._v("* "),
                                ]),
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "__.Components.parent.notafiscalentrada.financeiro_vue_html.formPgto"
                                    )
                                  )
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("combo-component", {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "required",
                                  expression: "'required'",
                                },
                              ],
                              attrs: {
                                id: "pagamento.formaPagamentoId",
                                name: "pagamento.formaPagamentoId",
                                data: _vm.formaPagamentoOptions,
                                searchable: false,
                              },
                              model: {
                                value:
                                  _vm.model.movimentacoes[0].formaPagamentoId,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.model.movimentacoes[0],
                                    "formaPagamentoId",
                                    $$v
                                  )
                                },
                                expression:
                                  "model.movimentacoes[0].formaPagamentoId",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-sm-4" }, [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: _vm.errors.first(
                                  "cobranca.contaCorrenteId"
                                ),
                                expression:
                                  "errors.first('cobranca.contaCorrenteId')",
                              },
                            ],
                            staticClass: "form-group",
                          },
                          [
                            _c(
                              "label",
                              {
                                staticClass: "control-label",
                                attrs: {
                                  for: "pagamento.contaCorrenteId",
                                  title: _vm.$t("__.obrigatorio"),
                                },
                              },
                              [
                                _c("span", { staticStyle: { color: "red" } }, [
                                  _vm._v("* "),
                                ]),
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "__.Components.parent.notafiscalentrada.financeiro_vue_html.contaCorrente"
                                    )
                                  )
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("combo-component", {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "required",
                                  expression: "'required'",
                                },
                              ],
                              attrs: {
                                id: "pagamento.contaCorrenteId",
                                name: "pagamento.contaCorrenteId",
                                data: _vm.contaCorrenteOptions,
                                searchable: false,
                              },
                              model: {
                                value:
                                  _vm.model.movimentacoes[0].contaCorrenteId,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.model.movimentacoes[0],
                                    "contaCorrenteId",
                                    $$v
                                  )
                                },
                                expression:
                                  "model.movimentacoes[0].contaCorrenteId",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]),
                  ]),
                ]
              ),
            ]
          : _vm._e(),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }