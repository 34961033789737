var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "col-sm-12" }, [
      _c(
        "div",
        {
          directives: [
            {
              name: "tooltip",
              rawName: "v-tooltip",
              value: _vm.errors.first("formulasPadrao"),
              expression: "errors.first('formulasPadrao')",
            },
          ],
          staticClass: "form-group",
        },
        [
          _c(
            "label",
            {
              staticClass: "control-label",
              attrs: { for: "formulasPadrao", title: _vm.$t("__.obrigatorio") },
            },
            [
              _c("span", { staticStyle: { color: "red" } }, [_vm._v("*")]),
              _vm._v(
                "\n                " +
                  _vm._s(
                    _vm.$t(
                      "__.Crud.formulapadraomanipulacao.edit_vue_html.frmlPadr"
                    )
                  ) +
                  "\n            "
              ),
            ]
          ),
          _vm._v(" "),
          _c("combo-component", {
            directives: [
              {
                name: "validate",
                rawName: "v-validate",
                value: "required",
                expression: "'required'",
              },
            ],
            staticStyle: { "background-color": "white" },
            attrs: {
              id: "formulasPadrao",
              name: "formulasPadrao",
              data: _vm.formulaPadraoOptions,
            },
            model: {
              value: _vm.formulapadraoId,
              callback: function ($$v) {
                _vm.formulapadraoId = $$v
              },
              expression: "formulapadraoId",
            },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }