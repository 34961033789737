import Vue from "vue";
import { mapGetters } from "vuex";

import dateTimePickerComponent from "@/components/child/form/datetimepicker.vue";
import { Component } from "@/decorators";
import PreLoadPackModel from "@/models/auxiliar/preLoadPackModel";
import ConfiguracaoFranquiaModel from "@/models/configuracaoFranquia/configuracaoFranquiaModel";
import Configuracoes from "@/models/enum/configuracao/configuracoes";
import ManipulacaoOrdemModel from "@/models/manipulacaoOrdemModel";
import PaginationModel from "@/models/paginationModel";
import PosologiaModel from "@/models/posologiaModel";
import VendaModel from "@/models/vendaModel";
import PosologiaService from "@/services/posologiaService";
import { Getters } from "@/store/store";
import { getPosologiaCombo } from "@/utils/common/combo/combotext";
import Delay from "@/utils/common/delay";
import ValidationErrorWrapper from "@/wrappers/validationErrorWrapper";

import RevisaoCamposItemComponent from "./revisaoCamposItem";
import revisaoCamposItemComponent from "./revisaoCamposItem.vue";

import "../crud/crud.scss";

@Component({
    components: {
        revisaoCamposItemComponent,
        dateTimePickerComponent,
    },
    computed: mapGetters(["GET_CONFIG_FRANQUIA"] as Getters),
})
export default class RevisaoCamposComponent extends Vue {
    // State computed props
    GET_CONFIG_FRANQUIA: (configuracao: Configuracoes) => ConfiguracaoFranquiaModel;

    private validationErrorWrapper = new ValidationErrorWrapper(this.$validator);
    private preloadPack: PreLoadPackModel = null;

    models: Array<ManipulacaoOrdemModel> = new Array<ManipulacaoOrdemModel>();
    modelsOrigin: Array<ManipulacaoOrdemModel> = new Array<ManipulacaoOrdemModel>();
    vendaModel = new VendaModel();

    posologiaOptions: Array<Object> = [];
    revisaoCamposItemComponent: RevisaoCamposItemComponent[] = [];

    private dataEntregaObrigatoria = false;

    private async loadPosologia() {
        try {
            const data = await new PosologiaService().combo().resolveWithJSON<PaginationModel<PosologiaModel>>();

            this.posologiaOptions = data.list.map(getPosologiaCombo);
        } catch {}
    }

    public async loadModels(models: Array<ManipulacaoOrdemModel>, venda: VendaModel) {
        this.models = null;
        this.vendaModel = venda;
        this.models = new Array<ManipulacaoOrdemModel>();
        this.modelsOrigin = new Array<ManipulacaoOrdemModel>();
        this.modelsOrigin = models;
        this.modelsOrigin.forEach(obj => {
            const model = new ManipulacaoOrdemModel();
            model.updateFrom(obj);
            this.models.push(model);
        });

        this.dataEntregaObrigatoria = this.getDataEntregaObrigatoria();

        await Delay(100);
        try {
            this.modelsOrigin.forEach((model, index) => {
                this.revisaoCamposItemComponent[index] = this.$refs.indexModel[index] as RevisaoCamposItemComponent;
            });
        } catch {}
    }

    public getDataEntregaObrigatoria() {
        const config = this.GET_CONFIG_FRANQUIA(Configuracoes.DataEntregaObrigatoria);
        if (config) {
            return config.verdadeiro;
        }
        return false;
    }

    public async validaRevisaoCampos() {
        try {
            for (let i = 0; i < this.revisaoCamposItemComponent.length; i++) {
                const valid = await this.revisaoCamposItemComponent[i].validate();
                if (!valid) {
                    return false;
                }
            }
            const isValid = await this.$validator.validateAll();
            if (isValid) {
                for (let i = 0; i < this.models.length; i++) {
                    this.modelsOrigin[i].posologiaId = this.models[i].posologiaId;
                    this.modelsOrigin[i].posologiaTexto = this.models[i].posologiaTexto;
                    this.modelsOrigin[i].pacienteId = this.models[i].pacienteId;
                    this.modelsOrigin[i].prescritorId = this.models[i].prescritorId;
                    this.modelsOrigin[i].numeroReceita = this.models[i].numeroReceita;
                    this.modelsOrigin[i].aprovouDesconto = this.models[i].aprovouDesconto;
                }
            }
            return isValid;
        } catch {
            return false;
        }
    }

    public async setPreLoadPack(_preloadPack: PreLoadPackModel) {
        this.preloadPack = _preloadPack;
        this.posologiaOptions = await this.preloadPack.posologiaCombo();
    }

    public async hasPreLoadPack() {
        return this.preloadPack != null;
    }

    private mounted() {
        this.validationErrorWrapper.clearErrors();
    }
}
