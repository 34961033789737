var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "crud crud-edit" },
    [
      _c("actionBarComponent", {
        attrs: {
          title: _vm.$t(
            "__.Crud.devolucaoCompra.edit_vue_html.devolucaoCompraEdit"
          ),
          saveText: _vm.$t("__.Crud.devolucaoCompra.edit_vue_html.emitir"),
        },
        on: { onSave: _vm.save },
      }),
      _vm._v(" "),
      _c(
        "form",
        {
          attrs: { novalidate: "novalidate" },
          on: {
            submit: function ($event) {
              $event.preventDefault()
              _vm.save()
            },
          },
        },
        [
          _c(
            "fieldset-component",
            {
              attrs: {
                title: _vm.$t("__.Crud.devolucaoCompra.edit_vue_html.nota"),
                collapsed: false,
              },
            },
            [
              _c("div", { attrs: { slot: "rows" }, slot: "rows" }, [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-sm-3" }, [
                    _c("div", { staticClass: "form-group" }, [
                      _c(
                        "label",
                        {
                          staticClass: "control-label",
                          attrs: { for: "numero" },
                        },
                        [
                          _vm._v(
                            "\n                                " +
                              _vm._s(
                                _vm.$t(
                                  "__.Crud.devolucaoCompra.edit_vue_html.numero"
                                )
                              ) +
                              "\n                            "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.model.numero,
                            expression: "model.numero",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "number",
                          id: "numero",
                          name: "numero",
                          disabled: true,
                        },
                        domProps: { value: _vm.model.numero },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(_vm.model, "numero", $event.target.value)
                          },
                        },
                      }),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-3" }, [
                    _c("div", { staticClass: "form-group" }, [
                      _c(
                        "label",
                        {
                          staticClass: "control-label",
                          attrs: { for: "serie" },
                        },
                        [
                          _vm._v(
                            "\n                                " +
                              _vm._s(
                                _vm.$t(
                                  "__.Crud.devolucaoCompra.edit_vue_html.serie"
                                )
                              ) +
                              "\n                            "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.model.serie,
                            expression: "model.serie",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "number",
                          id: "serie",
                          name: "serie",
                          disabled: true,
                        },
                        domProps: { value: _vm.model.serie },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(_vm.model, "serie", $event.target.value)
                          },
                        },
                      }),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-3" }, [
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "dataEmissao" },
                          },
                          [
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Crud.devolucaoCompra.edit_vue_html.dataEmissao"
                                  )
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("date-time-picker-component", {
                          attrs: {
                            id: "dataInclusao",
                            name: "dataEmissao",
                            disabled: true,
                          },
                          model: {
                            value: _vm.model.dataEmissao,
                            callback: function ($$v) {
                              _vm.$set(_vm.model, "dataEmissao", $$v)
                            },
                            expression: "model.dataEmissao",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-3" }, [
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "dataInclusao" },
                          },
                          [
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Crud.devolucaoCompra.edit_vue_html.dataInclusao"
                                  )
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("date-time-picker-component", {
                          attrs: {
                            id: "dataInclusao",
                            name: "dataInclusao",
                            disabled: true,
                          },
                          model: {
                            value: _vm.model.dataInclusao,
                            callback: function ($$v) {
                              _vm.$set(_vm.model, "dataInclusao", $$v)
                            },
                            expression: "model.dataInclusao",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-sm-6" }, [
                    _c("div", { staticClass: "form-group" }, [
                      _c(
                        "label",
                        {
                          staticClass: "control-label",
                          attrs: { for: "fornecedor" },
                        },
                        [
                          _vm._v(
                            "\n                                " +
                              _vm._s(
                                _vm.$t(
                                  "__.Crud.devolucaoCompra.edit_vue_html.fornecedor"
                                )
                              ) +
                              "\n                            "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.model.fornecedorNome,
                            expression: "model.fornecedorNome",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          id: "fornecedor",
                          name: "fornecedor",
                          disabled: true,
                        },
                        domProps: { value: _vm.model.fornecedorNome },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.model,
                              "fornecedorNome",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-6" }, [
                    _c("div", { staticClass: "form-group" }, [
                      _c(
                        "label",
                        {
                          staticClass: "control-label",
                          attrs: { for: "transportadora" },
                        },
                        [
                          _vm._v(
                            "\n                                " +
                              _vm._s(
                                _vm.$t(
                                  "__.Crud.devolucaoCompra.edit_vue_html.transportadora"
                                )
                              ) +
                              "\n                            "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.model.transportadoraNome,
                            expression: "model.transportadoraNome",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          id: "transportadora",
                          name: "transportadora",
                          disabled: true,
                        },
                        domProps: { value: _vm.model.transportadoraNome },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.model,
                              "transportadoraNome",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                  ]),
                ]),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "fieldset-component",
            {
              attrs: {
                title: _vm.$t("__.Crud.devolucaoCompra.edit_vue_html.itens"),
                collapsed: false,
              },
            },
            [
              _c(
                "div",
                { attrs: { slot: "rows" }, slot: "rows" },
                [
                  _c(
                    "div",
                    {
                      staticClass: "row",
                      staticStyle: { padding: "10px", "font-size": "16px" },
                    },
                    [
                      _c("div", { staticClass: "col-sm-2" }, [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "__.Crud.devolucaoCompra.edit_vue_html.cabecalhoProduto"
                              )
                            )
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-sm-2" }, [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "__.Crud.devolucaoCompra.edit_vue_html.cabecalhoLote"
                              )
                            )
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-sm-1" }, [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "__.Crud.devolucaoCompra.edit_vue_html.cabecalhoQuantidade"
                              )
                            )
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-sm-1" }, [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "__.Crud.devolucaoCompra.edit_vue_html.cabecalhoQuantidadeDevolver"
                              )
                            )
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-sm-1" }, [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "__.Crud.devolucaoCompra.edit_vue_html.cabecalhoUnidadeDevolver"
                              )
                            )
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-sm-1" }, [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "__.Crud.devolucaoCompra.edit_vue_html.cabecalhoQuantidadeEstoque"
                              )
                            )
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-sm-1" }, [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "__.Crud.devolucaoCompra.edit_vue_html.cabecalhoUnidadeEstoque"
                              )
                            )
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-sm-1" }, [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "__.Crud.devolucaoCompra.edit_vue_html.cabecalhoValorDevolver"
                              )
                            )
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-sm-1" }, [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "__.Crud.devolucaoCompra.edit_vue_html.cabecalhoValorUnitario"
                              )
                            )
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-sm-1" }, [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "__.Crud.devolucaoCompra.edit_vue_html.cabecalhoValorTotal"
                              )
                            )
                          ),
                        ]),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _vm._l(
                    _vm.model.notaFiscalEntradaItens,
                    function (item, index) {
                      return [
                        _c("item-component", {
                          key: index,
                          attrs: {
                            indicesConversoesUnidadeMedidasService:
                              _vm.indicesConversoesUnidadeMedidasService,
                            item: item,
                          },
                          on: {
                            onChangeItem: function (p) {
                              return _vm.onChangeItem(p, index)
                            },
                          },
                        }),
                      ]
                    }
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-sm-6" }, [
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "control-label",
                              attrs: { for: "valorTotalADevolver" },
                            },
                            [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(
                                    _vm.$t(
                                      "__.Crud.devolucaoCompra.edit_vue_html.valorTotal"
                                    )
                                  ) +
                                  "\n                            "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("moeda-component", {
                            staticClass: "form-control",
                            attrs: {
                              id: "valorTotalADevolver",
                              name: "valorTotalADevolver",
                              disabled: true,
                            },
                            model: {
                              value: _vm.valorTotalADevolver,
                              callback: function ($$v) {
                                _vm.valorTotalADevolver = $$v
                              },
                              expression: "valorTotalADevolver",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ],
                2
              ),
            ]
          ),
          _vm._v(" "),
          _c("fieldset", [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-sm-12 newbtn" }, [
                _c("div", { staticClass: "form-group" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-success",
                      attrs: { type: "submit" },
                    },
                    [
                      _c("i", {
                        staticClass: "fa fa-save",
                        attrs: { "aria-hidden": "true" },
                      }),
                      _vm._v(
                        "\n                            " +
                          _vm._s(
                            _vm.$t(
                              "__.Crud.devolucaoCompra.edit_vue_html.emitir"
                            )
                          ) +
                          "\n                        "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-secondary",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          _vm.cancel()
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n                            " +
                          _vm._s(_vm.$t("__.Crud.voltar")) +
                          "\n                        "
                      ),
                    ]
                  ),
                ]),
              ]),
            ]),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "shortcutComponent",
        {
          ref: "shortcutComponentEmissao",
          attrs: {
            btOkName: "Emitir Nota",
            extraButtons: _vm.extraButtonActionNfe,
            showAddNew: false,
          },
          on: {
            "selection-item": function ($event) {
              _vm.onEmitirNota().withLoading()
            },
            "extra-button-click": _vm.onExtraButtonActionClickNfe,
          },
        },
        [
          _c(
            "div",
            { attrs: { slot: "component" }, slot: "component" },
            [
              _c("nfe-component", {
                ref: "nfeComponent",
                attrs: { isEmissaoVenda: true },
              }),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }