import Vue from "vue";

import actionBarComponent from "@/components/child/actionBar/actionBar.vue";
import checkboxComponent from "@/components/child/form/checkbox.vue";
import comboComponent from "@/components/child/form/combo.vue";
import { Component, Watch } from "@/decorators";
import BancoModel from "@/models/bancoModel";
import ContaCorrenteModel from "@/models/financeiro/contaCorrenteModel";
import PaginationModel from "@/models/paginationModel";
import BancoService from "@/services/bancoService";
import ContaCorrenteService from "@/services/financeiro/contaCorrenteService";

import "../crud.scss";

@Component({
    components: {
        comboComponent,
        checkboxComponent,
        actionBarComponent,
    },
})
export default class ContaCorrenteEditComponent extends Vue {
    private service = new ContaCorrenteService();

    model = new ContaCorrenteModel();
    modelId = 0;
    bancoOptions: Array<Object> = [];

    private async load() {
        this.model = new ContaCorrenteModel();

        if (this.modelId > 0) {
            try {
                const data = await this.service.get(this.modelId).withLoading().resolveWithJSON<ContaCorrenteModel>();

                this.model.updateFrom(data);
            } catch {
                this.$router.back();
            }
        } else {
            this.model.ativo = true;
            this.model.interna = true;
        }
    }

    private async loadBancos() {
        try {
            const data = await new BancoService().combo().resolveWithJSON<PaginationModel<BancoModel>>();
            this.bancoOptions = data.list.map(item => ({ value: item.id, text: item.nome + " - " + item.codigo }));
        } catch {}
    }

    private async save() {
        try {
            const isValid = await this.$validator.validateAll();
            if (isValid) {
                const sucesso = await this.service[!this.model.id ? "insert" : "update"](this.model)
                    .withLoading()
                    .resolveWithoutJSON();

                if (sucesso) {
                    await this.$showInclusaoUpdate(this.model.id);
                    this.$router.back();
                }
            } else {
                this.$focusErrorField();
            }
        } catch {}
    }

    //@ts-ignore
    @Watch("model.interna")
    private onInternaChanged(interna) {
        if (interna) {
            this.model.bancoId = null;
            this.model.agencia = null;
            this.model.conta = null;
        }
    }

    private cancel() {
        this.$router.back();
    }

    private mounted() {
        Promise.all([this.loadBancos()])
            .then(() => {
                if (this.$route.params.id) {
                    this.modelId = +this.$route.params.id;
                }
                this.load();
            })
            .catch(() => {});
    }
}
