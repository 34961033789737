import moment from "moment";

export function addDays(dias: number, date: Date) {
    return moment(date).add(dias, "days").toDate();
}

export function addSeconds(seconds: number, date: Date) {
    return moment(date).add(seconds, "seconds").toDate();
}

export function addHours(hours: number, date: Date) {
    return moment(date).add(hours, "hours").toDate();
}

export function editDate(date: Date) {
    if (date == null) {
        return "";
    }
    return moment(Date.parse(date.toString())).format("DD/MM/YYYY");
}

export function editDateWithTime(date: Date) {
    if (date == null) {
        return "";
    }
    return moment(Date.parse(date.toString())).format("DD/MM/YYYY HH:mm:ss");
}

export function convertToDateInput(date: string, dataFinal = false) {
    return new Date(convertToDateInputString(date, dataFinal));
}

export function convertToDateInputString(date: string | object, dataFinal = false) {
    if (date) {
        if (dataFinal) {
            return moment(date, "DD/MM/YYYY").endOf("day").format("YYYY-MM-DDTHH:mm:ss");
        }

        return moment(date, "DD/MM/YYYY").format("YYYY-MM-DDTHH:mm:ss");
    }
    return "";
}

export function convertToDateWithTimeInputString(date: string | object) {
    if (date) {
        return moment(date, "DD/MM/YYYY HH:mm:ss").format("YYYY-MM-DDTHH:mm:ss");
    }
    return "";
}

export function editFromNow(date: Date) {
    if (date == null) {
        return "";
    }
    return moment(date).startOf("seconds").fromNow();
}
