import BaseModel from "./base/baseModel";
import SubGrupoProduto from "./enum/subGrupoProduto";
import TiposCalculoManipulacao from "./enum/tiposCalculoManipulacao";

export default class FormaFarmaceuticaItemPadraoModel extends BaseModel {
    id?: number = null;
    formaFarmaceuticaId?: number = null;
    produtoId?: number = null;
    produtoDescricao: string = null;
    quantidade: number = null;
    tipoCalculo: TiposCalculoManipulacao = null;
    tipoCalculoDescricao: string = null;
    subGrupo: SubGrupoProduto = null;
    subGrupoDescricao: string = null;
    desmembrado: boolean = null;
}
