var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _vm.text
      ? _c("span", { class: ["info-badge-text", _vm.badgeColor] }, [
          _vm._v("\n        " + _vm._s(_vm.text) + "\n    "),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }