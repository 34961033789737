import Ufs from "./enum/ufs";

export default class CepModel {
    _cidadeId?: number = null;
    get cidadeId(): number {
        return +this._cidadeId;
    }
    set cidadeId(value: number) {
        this._cidadeId = value;
    }

    cidadeNome: string = null;
    endereco: string = null;
    bairro: string = null;
    complemento: string = null;
    uf?: Ufs = null;
}