import Vue from "vue";

import buttonScComponent from "@/components/child/form/buttonSc.vue";
import { Component, Prop, Watch } from "@/decorators";

import "./modal.scss";

@Component({
    components: {
        buttonScComponent,
    },
})
export default class ModalComponent extends Vue {
    m_showModal = false;

    @Prop({ type: String, default: "sm-modal" }) modalClass: string;
    @Prop({ type: String, default: "" }) title: string;
    @Prop({ type: String, default: "white" }) headerColor: string;

    @Prop({ type: Boolean, default: true }) showBtnOk: boolean;
    @Prop({ type: Boolean, default: false }) showModal: boolean;
    @Prop({ type: Boolean, default: true }) showHeader: boolean;
    @Prop({ type: Boolean, default: true }) showBody: boolean;
    @Prop({ type: Boolean, default: true }) showFooter: boolean;

    @Prop({ type: String, default: "OK" }) btOkName: string;
    @Prop({ type: String, default: "" }) btOkShortKey: string;

    @Prop({ type: Array, default: () => [] }) extraButtons;

    public show(): void {
        this.m_showModal = true;
    }

    public hideWithoudOutputEvent(): void {
        this.m_showModal = false;
    }

    public hide(): void {
        this.$emit("hide-button-click");
        this.m_showModal = false;
    }

    public isShowing() {
        return this.m_showModal;
    }

    private extraButtonClick(name, entry) {
        this.$emit("extra-button-click", name, entry);
    }

    private onEscClick() {
        this.hide();
    }

    private onEnterClick() {
        this.$emit("btn-ok-click");
    }

    @Watch("showModal", { immediate: true })
    private onShowModalChanged(newValue: boolean) {
        this.m_showModal = newValue;
    }
}
