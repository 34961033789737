import { mapState } from "vuex";

import { GridAction } from "@/components/child/grid/gridAction";
import { GridColors, GridColumn, GridColumnType } from "@/components/child/grid/gridColumn";
import { Component } from "@/decorators";
import CompraModel from "@/models/compraModel";
import SituacaoCompra from "@/models/enum/situacaoCompra";
import UsuarioModel from "@/models/usuarioModel";
import CompraService from "@/services/compraService";
import { AppState, SessionActions } from "@/store/store";

import ListComponentBase from "../listComponentBase";

@Component({
    computed: {
        ...mapState({
            usuarioLogado: (state: AppState) => state.session.usuarioLogado,
        }),
    },
})
export default class ComprasListComponent extends ListComponentBase<CompraModel> {
    // State computed props
    usuarioLogado: UsuarioModel;

    extraActions: Array<object> = [];
    gridCotacaoExtraActions: Array<GridAction> = [];
    gridMelhorCompraExtraActions: Array<GridAction> = [];
    gridEnviarEmailExtraActions: Array<GridAction> = [];
    gridCancelarCompraExtraActions: Array<GridAction> = [];
    gridEditarCompraExtraActions: Array<GridAction> = [];

    get gridColumns(): Array<GridColumn> {
        return [
            new GridColumn("codigo", "Cód.", GridColumnType.Integer),
            new GridColumn("situacaoDescricao", "Situação", GridColumnType.String),
            new GridColumn("dataInicial", "Data Inicial", GridColumnType.Date),
            new GridColumn("dataFinal", "Data Final", GridColumnType.Date),
            new GridColumn("totalItens", "Itens", GridColumnType.Integer),
        ];
    }

    get gridSubColumns(): Array<GridColumn> {
        return [
            new GridColumn("produtoDescricao", this.$t("__.ts.descricao"), GridColumnType.String),
            new GridColumn("curvaAbcDescricao", "Curva ABC", GridColumnType.String),
            new GridColumn("grupoProdutoDescricao", this.$t("__.ts.grupo"), GridColumnType.String),
            new GridColumn("estoqueMinimo", this.$t("__.ts.estoqueMin"), GridColumnType.String),
            new GridColumn("estoqueMaximo", this.$t("__.ts.estoqueMax"), GridColumnType.String),
            new GridColumn("quantidadeVendida", this.$t("__.ts.qtdVendida"), GridColumnType.Decimal),
            new GridColumn("valorVendido", this.$t("__.ts.valorVendido"), GridColumnType.Money),
            new GridColumn("consumoDiario", this.$t("__.ts.consumoDiario"), GridColumnType.Decimal),
            new GridColumn("quantidadeAtual", this.$t("__.ts.qtdAtual"), GridColumnType.Decimal),
            new GridColumn("valorCusto", this.$t("__.ts.valorCusto"), GridColumnType.Money),
            new GridColumn("quantidadeCompra", this.$t("__.ts.qtdComprar"), GridColumnType.Decimal),
        ];
    }

    private beforeMount() {
        this.setProps(new CompraService(), "compra", "codigo", false, "desc");
    }

    public async afterMounted() {
        await this.$store.dispatch(SessionActions.LOAD_USUARIO_LOGADO);

        if (!this.usuarioLogado.franquia.moduloCompras) {
            this.$showWarning(this.$t("__.ts.atencao"), this.$t("__.ts.franquiaNaoModuloContratado"));

            return this.$router.back();
        }
    }

    public async afterLoad() {
        this.extraActions.splice(0);
        this.gridCotacaoExtraActions.splice(0);
        this.gridMelhorCompraExtraActions.splice(0);
        this.gridEnviarEmailExtraActions.splice(0);
        this.gridCancelarCompraExtraActions.splice(0);
        this.gridEditarCompraExtraActions.splice(0);

        for (let i = 0; i < this.gridData.length; i++) {
            this.gridCotacaoExtraActions.push(
                new GridAction(
                    "compra-cotacao",
                    "Cotação",
                    "fa fa-money-bill-wave",
                    GridColors.GREEN,
                    this.gridData[i].situacao !== SituacaoCompra.Cancelado,
                ),
            );
            this.gridMelhorCompraExtraActions.push(
                new GridAction(
                    "melhor-compra",
                    "Melhor compra",
                    "fa fa-shopping-cart",
                    GridColors.GREEN,
                    this.gridData[i].situacao !== SituacaoCompra.Cancelado,
                ),
            );
            this.gridEnviarEmailExtraActions.push(
                new GridAction(
                    "enviar-email",
                    this.$t("__.ts.solicitarCotacaoForn") as string,
                    "fa fa-envelope",
                    GridColors.GREEN,
                    this.gridData[i].situacao !== SituacaoCompra.Cancelado &&
                        !this.gridData[i].fornecedores.some(x => x.emailCotacaoEnviado),
                ),
            );
            this.gridCancelarCompraExtraActions.push(
                new GridAction(
                    "cancelar-compra",
                    this.$t("__.ts.cancelarCompra") as string,
                    "fa fa-exclamation-circle",
                    GridColors.RED,
                    this.gridData[i].situacao !== SituacaoCompra.Cancelado,
                ),
            );
            this.gridEditarCompraExtraActions.push(
                new GridAction(
                    "editar-compra",
                    this.$t("__.ts.alterar") as string,
                    "fa fa-edit",
                    GridColors.BLUE,
                    this.gridData[i].situacao !== SituacaoCompra.Cancelado,
                ),
            );

            this.gridData[i].totalItens = this.gridData[i].itens.length;
        }

        this.extraActions.push(this.gridMelhorCompraExtraActions);
        this.extraActions.push(this.gridCotacaoExtraActions);
        this.extraActions.push(this.gridEnviarEmailExtraActions);
        this.extraActions.push(this.gridEditarCompraExtraActions);
        this.extraActions.push(this.gridCancelarCompraExtraActions);
    }

    private async onExtraAction(name: string, model: CompraModel) {
        if (name.trim() == "compra-cotacao") {
            this.$router.push("/compra-cotacao/" + model.id);
        }
        if (name.trim() == "melhor-compra") {
            this.$router.push("/melhor-compra/" + model.id);
        }
        if (name.trim() == "enviar-email") {
            const question = await this.$showQuestion(this.$t("__.ts.atencao"), this.$t("__.ts.desejaEnviarEmail"));

            if (question) {
                const retorno = await new CompraService()
                    .sendEmailFornecedor(model.id)
                    .withLoading()
                    .resolveWithoutJSON();
                if (retorno) {
                    await this.$showSuccess(this.$t("__.ts.sucesso"), this.$t("__.ts.emailEnviadoForn"));
                    this.load();
                }
            }
        }
        if (name.trim() == "cancelar-compra") {
            const response = await this.$showQuestion(
                `${this.$t("__.ts.desejaCancelar")}${model[this.nomeProp] ?? ""}?`,
                this.$t("__.ts.acaoNaoRevert"),
            );
            if (response) {
                await new CompraService().cancelarCompra(model.id).withLoading().resolveWithoutJSON();
                await this.load();
            }
        }
        if (name.trim() == "editar-compra") {
            this.$router.push(`/${this.routePath}-edicao/${model["id"]}`);
        }
    }
}
