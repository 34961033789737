export default class CidadeService {
    public async get(estadoId: number): Promise<Array<{ text: string; value: number }>> {
        try {
            const data = await fetch(
                `https://vettech-api.azurewebsites.net/api/v1/localidades/estados/${estadoId}/municipios`,
            ).resolveWithJSON<{ nome: string; id: number }[]>();

            return data.map(p => ({
                text: p.nome.toUpperCase(),
                value: p.id,
            }));
        } catch {}

        return [];
    }
}
