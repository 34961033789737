import ModeloImpressaoModel from "@/models/modeloImpressao/modeloImpressaoModel";
import nfetch from "@/utils/events/nfetch";

import { HtmlPrintPageInfo } from "../print/printerQueue";

import { IService } from "./iService";

export abstract class IServicePrint<T> extends IService<T> {
    public async getHtmlPage(
        id: number,
        modelImpressaoId: number,
        copias: number,
        usuarioId: number,
    ): Promise<HtmlPrintPageInfo> {
        try {
            const data = await this.getToPrint(id, modelImpressaoId);

            if (!data) {
                throw new Error("Não foi possível obter o modelo de impressão");
            }

            const modelImpressao = new ModeloImpressaoModel();
            modelImpressao.updateFrom(data);

            let printerName = modelImpressao.nomeImpressora;
            if (modelImpressao.usuarios.some(p => p.usuarioId == usuarioId)) {
                printerName = modelImpressao.usuarios.find(p => p.usuarioId == usuarioId).nomeImpressora;
            }

            return {
                pageTitle: data.descricao,
                pageHtml: await modelImpressao.toHTML(),
                printerName,
                copies: copias,
            };
        } catch {}
    }

    public async getToPrint(id: number, modeloImpressaoId: number): Promise<ModeloImpressaoModel> {
        const response = nfetch(`/${this.controllerName}/GetToPrint?id=${id}&modeloImpressaoId=${modeloImpressaoId}`, {
            credentials: "same-origin",
        });

        return await response.resolveWithJSON<ModeloImpressaoModel>();
    }

    public getFullClassName(): Promise<Response> {
        return nfetch(`/${this.controllerName}/GetFullClassName`, {
            credentials: "same-origin",
        });
    }

    public async getImpressoesModels(id: number, tela: string): Promise<ModeloImpressaoModel[]> {
        const response = nfetch(`/${this.controllerName}/GetImpressoesModels?id=${id}&tela=${tela}`, {
            credentials: "same-origin",
        });

        return await response.resolveWithJSON<ModeloImpressaoModel[]>();
    }
}
