import { mapState } from "vuex";

import boxComponent from "@/components/child/containers/box.vue";
import portletComponent from "@/components/child/containers/portlet.vue";
import checkboxComponent from "@/components/child/form/checkbox.vue";
import comboComponent from "@/components/child/form/combo.vue";
import dateTimePickerComponent from "@/components/child/form/datetimepicker.vue";
import gridComponent from "@/components/child/grid/grid.vue";
import { GridColumn, GridColumnType } from "@/components/child/grid/gridColumn";
import multiSelectComponent from "@/components/child/multiSelect/multiSelect.vue";
import { Component, Watch } from "@/decorators";
import DashboardModel from "@/models/dashboard/dashboardModel";
import Graficos from "@/models/enum/dashboard/graficos";
import FranquiaModel from "@/models/franquiaModel";
import PaginationModel from "@/models/paginationModel";
import UsuarioModel from "@/models/usuarioModel";
import FranquiaService from "@/services/franquiaService";
import { AppState, SessionActions } from "@/store/store";
import { getTipoVendaCombo } from "@/utils/common/combo/combotext";

import DashboardComponent from "./dashboardComponent";

// Precisa ser assim se não ocorre erro
// eslint-disable-next-line @typescript-eslint/no-var-requires
const echarts = require("echarts");

import "./dashboardComponent.scss";

@Component({
    components: {
        boxComponent,
        checkboxComponent,
        portletComponent,
        dateTimePickerComponent,
        multiSelectComponent,
        gridComponent,
        comboComponent,
    },
    computed: {
        ...mapState({
            usuarioLogado: (state: AppState) => state.session.usuarioLogado,
        }),
    },
})
export default class Manipulacoes extends DashboardComponent {
    // State computed props
    usuarioLogado: UsuarioModel;

    valorVendas = 0;
    qtdVendas = 0;
    qtdFormulas = 0;
    ticketMedioVenda = 0;
    ticketMedioFormula = 0;
    taxaEntrega = true;

    private manipulacoesFormaFarmaceuticaSoma: any;
    private manipulacoesFormaFarmaceuticaQtde: any;
    private manipulacoesEspecieSoma: any;
    private manipulacoesEspecieQtde: any;
    private gridData: any;
    tipoVenda = 0;

    tipoVendaOptions = getTipoVendaCombo();

    franquiaIds: Array<Object> = [];
    defaultFranquia: Array<Object> = [];
    franquiasOptions: Array<Object> = [];

    get gridColumns(): Array<GridColumn> {
        return [
            new GridColumn("franquia", "Franquia", GridColumnType.String),
            new GridColumn("valorVendas", "Valor Total Vendas", GridColumnType.Money),
            new GridColumn("qtdFormulas", "Qtd. Formula", GridColumnType.Integer),
            new GridColumn("ticketMedioFormula", "Ticket Médio Fórmulas", GridColumnType.Money),
            new GridColumn("qtdVendas", "Qtd. Vendas", GridColumnType.Integer),
            new GridColumn("ticketMedioVenda", "Ticket Médio Vendas", GridColumnType.Money),
        ];
    }

    private graphStyle = {
        width: "100%",
        height: "300px",
        margin: "15px 0px 0px 0px",
        // @ts-ignore
        zoom: this.compensarZoomGrafico(document.body.style.zoom),
    };

    private dataFinal = new Date();
    private dataInicial = new Date(
        this.dataFinal.getFullYear(),
        this.dataFinal.getMonth(),
        this.dataFinal.getDate() - 30,
    );

    private watchesHabilitados = false;

    private async mounted() {
        await this.$store.dispatch(SessionActions.LOAD_USUARIO_LOGADO);

        await this.loadFranquias().withLoading();
        this.defaultFranquia.push(this.franquiasOptions.find(p => p["value"] === this.usuarioLogado.franquiaId));

        //@ts-ignore
        this.manipulacoesFormaFarmaceuticaSoma = echarts.init(this.$refs.manipulacoesPorFormaFarmaceuticaSoma);
        //@ts-ignore
        this.manipulacoesFormaFarmaceuticaQtde = echarts.init(this.$refs.manipulacoesPorFormaFarmaceuticaQtde);
        //@ts-ignore
        this.manipulacoesEspecieSoma = echarts.init(this.$refs.manipulacoesPorEspecieSoma);
        //@ts-ignore
        this.manipulacoesEspecieQtde = echarts.init(this.$refs.manipulacoesPorEspecieQtde);

        this.setGraficos([
            this.manipulacoesFormaFarmaceuticaSoma,
            this.manipulacoesFormaFarmaceuticaQtde,
            this.manipulacoesEspecieSoma,
            this.manipulacoesEspecieQtde,
        ]);

        this.updateDashboard();
    }

    protected async updateDashboard() {
        if (this.dataInicial > this.dataFinal) {
            this.$showWarning("Atenção", "Data Inicial maior que Data Final");
            return;
        }

        this.showLoadingGraficos();

        const data = await this.service
            .getManipulacoes(
                this.franquiaIds.map(p => p["value"]),
                this.dataInicial,
                this.dataFinal,
                this.taxaEntrega,
                this.tipoVenda,
            )
            .resolveWithJSON<{
                cardsData: {
                    valorVendas: number;
                    qtdFormulas: number;
                    qtdVendas: number;
                    ticketMedioFormula: number;
                    ticketMedioVenda: number;
                };
                informacoes: Map<Graficos, DashboardModel[]>;
                franquias: any[];
            }>();

        this.valorVendas = data.cardsData.valorVendas;
        this.qtdVendas = data.cardsData.qtdVendas;
        this.qtdFormulas = data.cardsData.qtdFormulas;
        this.ticketMedioFormula = data.cardsData.ticketMedioFormula;
        this.ticketMedioVenda = data.cardsData.ticketMedioVenda;
        this.qtdVendas = data.cardsData.qtdVendas;
        this.gridData = data.franquias;

        this.manipulacoesFormaFarmaceuticaSoma.setOption(
            this.getOpcoesGrafico({
                titulo: this.$t("__.ts.somaPorFormaFarmaceutica") as string,
                labels: data.informacoes["SomaManipulacoesFormaFarmaceutica"].map(d => d.label),
                dados: data.informacoes["SomaManipulacoesFormaFarmaceutica"].map(d => d.valor.toFixed(2)),
                tipoGrafico: "bar",
                format: "<b>{b}</b><br/>R$ {c}",
                nomeSerie: data.informacoes["SomaManipulacoesFormaFarmaceutica"].map(d => d.serie)[0],
            }),
        );

        this.manipulacoesFormaFarmaceuticaQtde.setOption(
            this.getOpcoesGrafico({
                titulo: this.$t("__.ts.qtdePorFormaFarmaceutica") as string,
                labels: data.informacoes["QtdeManipulacoesFormaFarmaceutica"].map(d => d.label),
                dados: data.informacoes["QtdeManipulacoesFormaFarmaceutica"].map(d => d.valor),
                tipoGrafico: "bar",
                format: "<b>{b}</b><br/>{c}",
                nomeSerie: data.informacoes["QtdeManipulacoesFormaFarmaceutica"].map(d => d.serie)[0],
            }),
        );

        this.manipulacoesEspecieSoma.setOption(
            this.getOpcoesGrafico({
                titulo: this.$t("__.ts.somaPorEspecie") as string,
                labels: data.informacoes["SomaManipulacoesEspecie"].map(d => d.label),
                dados: data.informacoes["SomaManipulacoesEspecie"].map(d => d.valor.toFixed(2)),
                tipoGrafico: "bar",
                format: "<b>{b}</b><br/>R$ {c}",
                nomeSerie: data.informacoes["SomaManipulacoesEspecie"].map(d => d.serie)[0],
            }),
        );

        this.manipulacoesEspecieQtde.setOption(
            this.getOpcoesGrafico({
                titulo: this.$t("__.ts.qtdePorEspecie") as string,
                labels: data.informacoes["QtdeManipulacoesEspecie"].map(d => d.label),
                dados: data.informacoes["QtdeManipulacoesEspecie"].map(d => d.valor),
                tipoGrafico: "bar",
                format: "<b>{b}</b><br/>{c}",
                nomeSerie: data.informacoes["QtdeManipulacoesEspecie"].map(d => d.serie)[0],
            }),
        );

        this.hideLoadingGraficos();

        this.watchesHabilitados = true;
    }

    private async loadFranquias() {
        const data = await new FranquiaService().combo().resolveWithJSON<PaginationModel<FranquiaModel>>();
        this.franquiasOptions = data.list.map(franquia => ({
            value: franquia.id,
            text: franquia.nomeFantasia,
        }));
    }

    private onSelecionarTodas() {
        this.franquiaIds = this.franquiasOptions;
        this.defaultFranquia = this.franquiasOptions;
    }

    private onLimpar() {
        this.franquiaIds = [];
        this.defaultFranquia = [];
    }

    protected convertToString(numero: number) {
        return numero == null ? "0" : numero.toString();
    }

    @Watch("franquiaIds")
    private onFranquiaId() {
        if (this.franquiaIds.length > 0) {
            this.updateDashboard();
        }
    }

    @Watch("dataInicial")
    private onDataInicial(newValue, oldValue) {
        if (newValue != oldValue && this.watchesHabilitados) {
            this.updateDashboard();
        }
    }

    @Watch("dataFinal")
    private onDataFinal(newValue, oldValue) {
        if (newValue != oldValue && this.watchesHabilitados) {
            this.updateDashboard();
        }
    }

    @Watch("taxaEntrega")
    @Watch("tipoVenda")
    private onChangeFiltros() {
        this.updateDashboard();
    }
}
