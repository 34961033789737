import Vue from "vue";
import { mapGetters, mapMutations, mapState } from "vuex";

import comboComponent from "@/components/child/form/combo.vue";
import dateTimePickerComponent from "@/components/child/form/datetimepicker.vue";
import gridComponent from "@/components/child/grid/grid.vue";
import { GridAction } from "@/components/child/grid/gridAction";
import { GridColors, GridColumn, GridColumnType } from "@/components/child/grid/gridColumn";
import { Component } from "@/decorators";
import PaginationPackModel from "@/models/auxiliar/paginationPackModel";
import EnumExtensions from "@/models/enum/extensions/enumExtensions";
import SituacoesVenda from "@/models/enum/situacoesVenda";
import NotaFiscalSaidaEmissaoLoteModel from "@/models/notafiscaleletronica/notaFiscalSaidaEmissaoLoteModel";
import PaginationModel from "@/models/paginationModel";
import ValidationErrorModel from "@/models/validationErrorModel";
import NotaFiscalEmissaoLoteService from "@/services/notaFiscalEmissaoLoteService";
import { AppState, Getters, Mutations } from "@/store/store";
import { convertToDateInputString } from "@/utils/common/date";
import Delay from "@/utils/common/delay";

import "../crud/crud.scss";

@Component({
    components: {
        gridComponent,
        dateTimePickerComponent,
        comboComponent,
    },
    computed: {
        ...mapState({
            loadingPack: (state: AppState) => state.paginationStatus.loading,
            paginationParams: (state: AppState) => state.paginationStatus.paginationParams,
        }),
        ...mapGetters(["GET_IS_FRACIONAMENTO"] as Getters),
    },
    methods: mapMutations(["SET_PARAMS", "GET_PARAMS"] as Mutations),
})
export default class NotaFiscalSaidaEmissaoLoteComponent extends Vue {
    // State computed props
    loadingPack: boolean;
    paginationParams: PaginationPackModel;
    SET_PARAMS: (obj: object) => void;
    GET_PARAMS: (path: string) => Promise<void>;
    GET_IS_FRACIONAMENTO: () => Promise<boolean>;

    private service = new NotaFiscalEmissaoLoteService();

    gridSelectedValues: Array<NotaFiscalSaidaEmissaoLoteModel> = [];
    gridData: Array<NotaFiscalSaidaEmissaoLoteModel> = [];
    private isFracionamento = false;

    get gridColumns(): Array<GridColumn> {
        return [
            new GridColumn("clienteNome", "Cliente", GridColumnType.String, false, false, "", "", true, false),
            new GridColumn("codigo", "Cód. Venda", GridColumnType.Integer, false, false, "", "", true, false),
            new GridColumn("dataAprovacao", "Data Venda", GridColumnType.DateTime, false, false, "", "", true, false),
            new GridColumn("situacaoDescricao", "Situação", GridColumnType.String, false, false, "", "", true, false),
            new GridColumn("valorFinal", "Valor", GridColumnType.Money, false, false, "", "", true, false),
        ];
    }
    extraActions: Array<object> = [];
    extraActionsCancelar: Array<GridAction> = [];
    pageIndex = 1;
    pageSize = 20;
    total = 0;

    isHidden = false;
    situacao = null;
    situacoesVendaOptions = EnumExtensions.getNamesAndValuesOrderedByValues(SituacoesVenda);
    emissaoDe: Date = null;
    emissaoAte: Date = null;
    gridFilterKey = "";

    private async load() {
        this.situacoesVendaOptions = EnumExtensions.getNamesAndValuesOrderedByValues(SituacoesVenda);
        if (!this.isFracionamento) {
            this.situacoesVendaOptions = this.situacoesVendaOptions.slice(0, 6);
        }
        this.situacoesVendaOptions = this.situacoesVendaOptions.filter(p => p["value"] > 1 && p["value"] !== 5);

        try {
            const data = await this.service
                .listVendaEmissaoLote(
                    this.pageIndex,
                    this.pageSize,
                    convertToDateInputString(this.emissaoDe),
                    convertToDateInputString(this.emissaoAte),
                    this.situacao,
                    this.gridFilterKey,
                )
                .withLoading()
                .resolveWithJSON<PaginationModel<NotaFiscalSaidaEmissaoLoteModel>>();

            this.gridData = data.list;
            this.total = data.total;
            this.pageIndex = data.pageIndex;
            this.pageSize = data.pageSize;
            this.extraActions.splice(0);
            this.extraActionsCancelar.splice(0);

            for (let i = 0; i < this.gridData.length; i++) {
                const ultimaTentativaComFalha = this.gridData[i].ultimaEmissaoStatusEnvioDescricao != "";

                if (ultimaTentativaComFalha) {
                    this.extraActionsCancelar.push(
                        new GridAction(
                            "falhaEmissao",
                            ultimaTentativaComFalha ? "Ultima tentativa de emissão falhou" : "",
                            "fa fa-exclamation-circle",
                            GridColors.LIGHTRED,
                            ultimaTentativaComFalha,
                        ),
                    );
                }
            }

            this.extraActions.push(this.extraActionsCancelar);
        } catch {}
    }

    private async emitirNotasLote() {
        const vendaIds = this.gridSelectedValues.map(v => v.vendaId);

        const data = await this.service
            .emitirEmLoteByVendaIds(vendaIds)
            .withLoading()
            .resolveWithJSON<ValidationErrorModel[]>();

        if (data.length == 0) {
            this.$showSuccess(
                "Sucesso",
                "As notas foram enviadas a prefeitura, após 15 minutos serão feitas as consultas automaticamente para verificação do resultado das emissões.",
            );
        } else {
            for (let i = 0; i < data.length; i++) {
                const element = data[i];
                const venda = this.gridData.find(p => element.field == p.vendaId.toString());

                await this.$showError(
                    "Erro",
                    `Não foi possível enviar a nota da venda ${venda?.codigo}: ${element.references}`,
                );
            }
        }

        this.load();
    }

    private onChangePage(pageIndex: number) {
        this.pageIndex = pageIndex;
        this.load();
    }

    private onChangePageSize(newPageSize) {
        this.pageIndex = 1;
        this.pageSize = newPageSize;
        this.load();
    }

    private onChangeFilterKey(filterKey: string) {
        this.gridFilterKey = filterKey;
        this.load();
    }

    private onSelectedValuesChanged(values: Array<NotaFiscalSaidaEmissaoLoteModel>) {
        this.gridSelectedValues = values;
    }

    private async loadPagination() {
        await this.GET_PARAMS(this.$route.path);
        while (this.loadingPack) await Delay(50);

        if (this.paginationParams != null) {
            this.pageIndex = this.paginationParams.pageIndex;
            this.pageSize = this.paginationParams.pageSize;
            this.gridFilterKey = this.paginationParams.filterKey;
        }
    }

    private onExtraAction(name: string, notaFiscalEletronicaEmissaoLote: NotaFiscalSaidaEmissaoLoteModel) {
        switch (name) {
            case "falhaEmissao":
                this.$router.push("/notafiscalsaida-edicao/" + notaFiscalEletronicaEmissaoLote.ultimaNotaFiscalId);
                break;
            default:
                break;
        }
    }

    private async mounted() {
        this.isFracionamento = await this.GET_IS_FRACIONAMENTO();

        Promise.all([this.loadPagination()])
            .withLoading()
            .then(() => {
                this.load();
            })
            .catch(() => {});
    }
}
