import VendaPCPModel from "@/models/pcp/vendaPCPModel";
import { convertToDateInputString } from "@/utils/common/date";
import nfetch from "@/utils/events/nfetch";

import { IService } from "../base/iService";

export default class VendaPCPService extends IService<VendaPCPModel> {
    constructor() {
        super("VendaPCP");
    }

    public getByVenda(vendaId: number): Promise<Response> {
        return nfetch(`/${this.controllerName}/GetByVenda?id=${vendaId}`, {
            credentials: "same-origin",
        });
    }

    public getByEtapa(etapaId: number, dataInicial: Date, dataFinal: Date): Promise<Response> {
        const dataInicialString = convertToDateInputString(dataInicial);
        const dataFinalString = convertToDateInputString(dataFinal);
        return nfetch(
            `/${this.controllerName}/GetByEtapa?etapaId=${etapaId}&dataInicial=${dataInicialString}&dataFinal=${dataFinalString}`,
            {
                credentials: "same-origin",
            },
        );
    }
}
