import { mapGetters, mapMutations } from "vuex";

import buttonIncluirComponent from "@/components/child/form/button/buttonIncluir.vue";
import cepFieldComponent from "@/components/child/form/cepfield.vue";
import checkboxComponent from "@/components/child/form/checkbox.vue";
import cnpjComponent from "@/components/child/form/cnpj.vue";
import comboComponent from "@/components/child/form/combo.vue";
import cpfComponent from "@/components/child/form/cpf.vue";
import dataTooltipComponent from "@/components/child/form/datatooltip.vue";
import dateTimePickerComponent from "@/components/child/form/datetimepicker.vue";
import decimalComponent from "@/components/child/form/decimal.vue";
import fieldsetComponent from "@/components/child/form/fieldset.vue";
import textareaComponent from "@/components/child/form/textarea.vue";
import Grid from "@/components/child/grid/grid";
import gridComponent from "@/components/child/grid/grid.vue";
import { GridColumn, GridColumnType } from "@/components/child/grid/gridColumn";
import infoBagdeComponent from "@/components/child/infoBagde/infoBagde.vue";
import searchComboComponent from "@/components/child/searchCombo/search.vue";
import { Component, Watch } from "@/decorators";
import BeneficioModel from "@/models/beneficio/beneficioModel";
import CepModel from "@/models/cepModel";
import EnumExtensions from "@/models/enum/extensions/enumExtensions";
import GenerosPessoa from "@/models/enum/generosPessoa";
import TiposPessoa from "@/models/enum/tiposPessoa";
import Ufs from "@/models/enum/ufs";
import { TypeElement } from "@/models/modeloImpressao/modeloImpressaoSessaoModel";
import PaginationModel from "@/models/paginationModel";
import PrescritorModel from "@/models/prescritorModel";
import VisitadorModel from "@/models/visitadorModel";
import VisitadorPrescritorModel from "@/models/visitadorPrescritorModel";
import BeneficioService from "@/services/beneficioService";
import CidadeService from "@/services/external/cidadeService";
import PrescritorService from "@/services/prescritorService";
import VisitadorService from "@/services/visitadorService";
import { Getters } from "@/store/store";
import { sortArray } from "@/utils/common/array";
import { editDateWithTime } from "@/utils/common/date";
import Print from "@/utils/common/printForm";

import EditComponentBase from "../editComponentBase";
import PrescritorComponent from "../prescritor/edit";
import prescritorComponent from "../prescritor/edit.vue";
import ReplicarSelecaoFranquiaFiliaisComponent from "../replicarSelecaoFranquiaFiliais/edit";
import replicarSelecaoFranquiaFiliaisComponent from "../replicarSelecaoFranquiaFiliais/edit.vue";
import "../crud.scss";

@Component({
    components: {
        textareaComponent,
        fieldsetComponent,
        comboComponent,
        searchComboComponent,
        checkboxComponent,
        cpfComponent,
        cnpjComponent,
        decimalComponent,
        cepFieldComponent,
        dateTimePickerComponent,
        dataTooltipComponent,
        replicarSelecaoFranquiaFiliaisComponent,
        gridComponent,
        prescritorComponent,
        buttonIncluirComponent,
        infoBagdeComponent,
    },
    computed: {
        infoBagdeText() {
            return "Data criação: " + editDateWithTime(this.model.dataCriacao);
        },
        ...mapGetters(["VALIDAR_PERMISSAO_USUARIO"] as Getters),
    },
    methods: mapMutations(["LOAD_LIST"]),
})
export default class VisitadorEditComponent extends EditComponentBase<VisitadorModel> {
    // State computed props
    VALIDAR_PERMISSAO_USUARIO: (telaDescricao: string, acaoController: string) => boolean;
    infoBagdeText: string;

    private cidadeService = new CidadeService();
    private visitadorService = new VisitadorService();
    private excluidos = new Array<VisitadorPrescritorModel>();
    private prescritorComponent: PrescritorComponent = null;
    private gridcomponent: Grid = null;
    gridData: Array<VisitadorPrescritorModel> = [];
    private replicarSelecaoFranquiaFiliaisComponent: ReplicarSelecaoFranquiaFiliaisComponent = null;

    tipoDocGerar = null;
    pags = false;

    beneficiosOptions: Array<Object> = [];

    private customComboTextPrescritor = (p: PrescritorModel) => ({
        value: p.id,
        text: `${p.ativo ? "" : "[INATIVO]"} ${p.nome} - (${p.ufConselhoRegionalDescricao} ${p.numeroRegistro}) ${
            p.visitadores != null && p.visitadores[0] != null ? ` [VISITADOR: ${p.visitadores[0].visitador.nome}]` : ""
        }`,
        temVisitador: p.visitadores != null && p.visitadores[0] != null,
    });

    tiposPessoa = TiposPessoa;
    tiposPessoaOptions: Array<Object> = [
        { text: "F\u00edsica", value: TiposPessoa.Fisica },
        { text: "Jur\u00eddica", value: TiposPessoa.Juridica },
    ];

    generosPessoaOptions: Array<Object> = [
        { text: "Masculino", value: GenerosPessoa.Masculino },
        { text: "Feminino", value: GenerosPessoa.Feminino },
    ];

    get gridColumns(): Array<GridColumn> {
        return [new GridColumn("nome", "Nome", GridColumnType.String)];
    }

    dadosCep: CepModel = new CepModel();
    ufOptions: Array<Object> = EnumExtensions.getNamesAndValuesOrderedByNames(Ufs);
    cidadesOptions: Array<Object> = [];

    private async beforeMount() {
        await this.setProps(new VisitadorService(), "visitador", new VisitadorModel(), false);

        await this.loadBeneficios().withLoading();
    }

    private onChangeSort(sortKey: keyof VisitadorPrescritorModel, sortOrder: "asc" | "desc") {
        sortArray(this.gridData, sortKey, sortOrder);
    }

    public async onLoadModel(model: VisitadorModel) {
        this.gridData = model.prescritores;

        await this.onLoadCidades(model.estadoId);
    }

    public async onPreSave() {
        this.model.nomeRotulo = this.model.tipoPessoa == TiposPessoa.Fisica ? this.model.nome : this.model.nomeFantasia;

        if (this.model.cidadeId) {
            this.model.cidade = this.cidadesOptions.filter(item => item["value"] == this.model.cidadeId)[0]["text"];
        }

        return true;
    }

    public async loadBeneficios() {
        try {
            const data = await new BeneficioService().combo().resolveWithJSON<PaginationModel<BeneficioModel>>();
            this.beneficiosOptions = data.list.map(item => ({
                value: item.id,
                text: item.descricao,
            }));
        } catch {}
    }

    private onAddNewPrescritor() {
        this.prescritorComponent = this.$refs.PrescritorComponent as PrescritorComponent;
        this.prescritorComponent.save();
    }

    private async adicionarVinculo() {
        const existe = this.model.prescritores.some(p => p.prescritorId == this.model.prescritorId);
        const component = this.$el.querySelector("#prescritorId") as HTMLSelectElement;

        if (this.model.prescritorId == null) {
            this.$showWarning(this.$t("__.ts.atencao"), this.$t("__.ts.prescritorNaoSelecionadoo"));
        } else if (!existe) {
            const prescritor = await new PrescritorService()
                .get(this.model.prescritorId)
                .resolveWithJSON<PrescritorModel>();

            if (prescritor.visitadores.some(v => v.visitador != null)) {
                this.$showWarning(this.$t("__.ts.atencao"), this.$t("__.ts.prescritorJaAssociado"));
            } else {
                const vinculo = new VisitadorPrescritorModel();
                vinculo.prescritorId = this.model.prescritorId;
                vinculo.visitadorId = this.model.id ? this.model.id : null;
                vinculo.nome = prescritor.nome;
                this.model.prescritores.push(vinculo);
            }
        } else {
            this.$showWarning(this.$t("__.ts.atencao"), this.$t("__.ts.prescritorJaInserido"));
        }

        component.value = null;
        this.model.prescritorId = null;
    }

    private async onCepChanged(data: CepModel) {
        this.dadosCep = data;
        this.model.bairro = data.bairro;
        this.model.complemento = data.complemento;
        this.model.endereco = data.endereco;
        this.model.estadoId = data.uf;
        await this.onLoadCidades(this.model.estadoId);
        this.model.cidadeId = data.cidadeId;
    }

    private onCepInvalid() {
        this.$showInfo(this.$t("__.ts.pesqCEP"), this.$t("__.ts.pesqNaoEncResul"));
    }

    private onRemovePrescritor(model: VisitadorPrescritorModel) {
        this.excluidos.push(model);
        this.model.prescritores.splice(this.model.prescritores.indexOf(model), 1);
    }

    private async onAddPrescritorOk(modelPrescritor: PrescritorModel) {
        this.model.prescritorId = modelPrescritor.id;
    }

    private async openComboNewPrescritor() {
        this.prescritorComponent.load().withLoading();
    }

    private onReplicarFiliais() {
        this.replicarSelecaoFranquiaFiliaisComponent = this.$refs
            .replicarSelecaoFranquiaFiliaisComponent as ReplicarSelecaoFranquiaFiliaisComponent;
        this.replicarSelecaoFranquiaFiliaisComponent.openFranquias();
    }

    private validarVisibilidadeBotoes(botao: string): boolean {
        if (botao == "replicar-filiais") {
            return (
                this.model.id > 0 &&
                this.usuarioLogado.franquia.isFranquiaMatriz &&
                this.VALIDAR_PERMISSAO_USUARIO("franquias", "ReplicarItensFiliais")
            );
        }
    }

    private async onConfirmReplicar(franquiasIds: Array<number>) {
        try {
            const sucesso = await this.visitadorService
                .replicarVisitador(this.model.id, franquiasIds)
                .withLoading()
                .resolveWithoutJSON();
            if (sucesso) {
                this["LOAD_LIST"]({ loadProdutos: true });
                this.$showSuccess("Replicou", this.$t("__.ts.regisSalvosSucess"));
            }
        } catch {}
    }

    //@ts-ignore
    @Watch("model.tipoPessoa")
    private onTipoPessoaChanged(tipoPessoa: TiposPessoa) {
        switch (tipoPessoa) {
            case TiposPessoa.Fisica:
                this.model.nomeFantasia = null;
                this.model.cnpj = null;
                this.model.inscricaoEstadual = null;
                this.model.inscricaoMunicipal = null;
                this.model.razaoSocial = null;
                break;
            case TiposPessoa.Juridica:
                this.model.nome = null;
                this.model.cpf = null;
                this.model.rg = null;
                this.model.dataNascimento = null;
                this.model.genero = null;
                this.model.orgaoExpedidor = null;
                this.model.ufExpedidor = null;
                break;
        }
    }

    @Watch("pags")
    private onPags() {
        if (this.pags) {
            this.gridcomponent = this.$refs.gridcomponent as Grid;

            if (this.tipoDocGerar == 0) {
                setTimeout(() => {
                    this.gridcomponent.gerarXls();
                }, 2000);
            }

            if (this.tipoDocGerar == 1) {
                setTimeout(() => {
                    this.gridcomponent.gerarPdf();
                }, 2000);
            }

            setTimeout(() => {
                this.pags = false;
            }, 2000);
        }
    }

    private async onLoadCidades(estadoId: number) {
        try {
            this.cidadesOptions = [];
            if (estadoId) {
                this.cidadesOptions = await this.cidadeService.get(estadoId).withLoading();
            }
        } catch {}
    }

    print() {
        new Print().print(
            [
                {
                    elementId: "visitador",
                    title: "visitador",
                    type: TypeElement.Form,
                },
                {
                    elementId: "localizacao",
                    title: "Localização",
                    type: TypeElement.Form,
                },
                {
                    elementId: "entrega",
                    title: "Endereço de Entrega",
                    type: TypeElement.Form,
                },
                {
                    elementId: "contato",
                    title: "Contato",
                    type: TypeElement.Form,
                },
            ],
            3,
        );
    }
}
