// Espanhol

const __ = {
    obrigatorio: "* este campo es obligatorio",

    ts: {
        macho: "Masculino",
        femea: "Hembra",
        endeInfoJaCadast: "La dirección ingresada ya está registrada",
        preenchCorretCamposAddLista: "Complete los campos correctamente para agregar a la lista",
        endeNaoRemovPoisVendasEntreg: "¡La dirección no se puede eliminar porque ya tiene ventas entregadas!",
        visuVendasClient: "Ver ventas de clientes",
        verClient: "Ver Cliente",
        verPontos: "Ver los puntos acumulados del cliente",
        regisRemovSucess: "El registro se eliminó con éxito",
        parcelando: "Repartiendo...",
        vlrFormaFarmaceu: "Valor de Forma Farmacéutica",
        replicando: "Replicando...",
        vincularKitEmb: "<label>Enlace Kit de Embalaje:</label>",
        atualizacao: "Actualizar!",
        tamanhFontExcedRotulo: "El tamaño de la fuente excede el tamaño de la etiqueta.",
        quantidadeColunasInvalida: "Número de columnas no válidas. Campo: ",
        registNaoInseridExcedRotulo: "Registro no insertado porque excede el tamaño de la etiqueta.",
        copiaa: "¡Copiar!",
        regisCopiadoSucess: "El registro se copió correctamente.",
        foiAltQtdeParaMaxDisp: " se cambió la cantidad al máximo disponible.",
        codVenda: "Cód. de venta",
        obss: "Nota",
        manipuConclSucesso: "La composición concluye con éxito.",
        orcamento: "Presupuesto",
        volPCaps: "Volumen para Caps",
        qtdeCaps: "Cant. de Caps",
        unidMedCaps: "Unidad. Med. Caps. ",
        consolidado: "Consolidado",
        estaComQtdAbaixoMinCad: "es con cantidad de stock por debajo del mínimo registrado.",
        avisEstqBaixo: "Advertencia de Stock Bajo",
        semEstoque: "Falta de Existencias",
        estoqueAtual: "Cantidad actual",
        estoqueMinimo: "Stock Mínimo",
        segProdsQtdsEstqAbaixMin:
            "Los siguientes productos tienen sus cantidades de stock por debajo de su registro mínimo:",
        naoPossQtdEstqSufic: "no dispone de stock suficiente para cubrir esta demanda.",
        segProdsNaoPossQtdEstqSufic:
            "Los siguientes productos no tienen stock suficiente para satisfacer esta demanda:",
        substituirPorFormulaBase: "¿Quiere que el sistema sustituya a la fórmula base",
        printRotuloManipu: "Imprimir Etiqueta de Manipulación",
        puro: "puro",
        diluido: "diluido",
        calc: "Cálculo",
        volDose: "Vol. Dosis",
        concentr: "Concentración",
        cor: "Color",
        estqPadrao: "Stock Predeterminado",
        fabEstrang: "Fabricante Extranjero",
        atencao: "Atención!",
        aprovado: "Aprobado!",
        buscarEm: "Busca en...",
        desejaContinuar: "¿Desea continuar imprimiendo desde el navegador?",
        obrigatorioinformarLoteParaProduto: "Es obligatorio informar el lote del producto",
        dataInvalida: "Fecha no válida",

        // Impressão e impressoras
        // =====================================================================
        imprimir: "Imprimir",
        impressora: "Impresora",
        copias: "Copias",
        selecioneZeroNaoImprimir: 'Seleccione "0" para no imprimir',
        impressoraPadraoFoiUsadaEmUmaOuMaisPaginas:
            "Cualquier impresora está fuera de línea o no se ha definido ninguna impresora en el modelo.",
        impressoraNull: "(impresora no definida)",
        erroNenhumaImpressoraDisponivel: "No hay ninguna impresora disponible.",
        coleta: "Colección",

        // Upload de arquivo
        // =====================================================================
        tipoArquivoInvalido: "Tipo de archivo inválido.",
        exemplosTipoArquivoPermitido: "Tipos de archivos permitidos:",
        arquivosSalvos: "Archivos guardados",

        //..child/form/combo.ts
        addNovo: "Agregar nuevo",

        //..child/grid/grid.ts
        sttsAFaturar: "Estado: Facturación",
        sttsFaturado: "Estado: facturado",
        sttsConcluido: "Estado: Completado",
        sttsCancelado: "Estado: cancelado",
        sttsVencidos: "Estado: vencido",
        conta: "Cuenta",
        categoria: "Categoría",
        de: "De:",
        ate: "Hasta:",
        pgtoDe: "Pago desde:",
        fornecedor: "Proveedor:",

        //..child/impressaoComponent/impressaoComponent.ts
        muitosRotulos:
            "El sistema identificó una gran cantidad de etiquetas, por lo que se imprimirán en forma paginada después de imprimir los modelos",
        salvarRotulo: "Guardar etiqueta",
        salvarRotuloSucesso: "¡Etiqueta guardada exitosamente!",

        //..child/loadingmodal/loadingmodal.ts
        carregandooo: "Cargando ...",
        salvando: "Ahorrando ...",
        excluindo: "Excluyendo ...",

        //..child/logEntidade/logEntidade.ts
        historicoAlteracoes: "Cambia la historia",

        //..parent/app/app.ts
        sessaoExp: "Su sesión caducará a las ",
        seg: " <strong> segundos <br/>",
        pressSimParaLogado: "Pulsa <b>SI</b> para permanecer conectado ",
        pressNaoParaDesconect: "o presione <b>NO</b> para desconectar.<br/><br/>",
        obs: "Obs.: Al desconectar seis dadps de la sesión se perderán.",
        tempoParaExp: "¡Hora de expirar la sesión!",
        sim: "Si",
        nao: "No",
        min: "minutos",
        nfe: "Factura de servicio",
        cf: "Cupón fiscal",
        vendaCod: " - Rebaja Cód: ",
        errReq: "Error al solicitar el registro para consultar la emisión de la factura. ",
        aindaPend: "Aún pendiente, intente ordenar nuevamente más tarde debido a la rutina de ventas.",
        pendente: "Pendiente",
        reprovada: "Rechazado",
        emissaoConfPrefe: "Emisión de factura confirmada por el ayuntamiento.",

        //..parent/atendimento
        franquiaNaoModuloContratado: "La franquicia no tiene contratado este módulo",
        historicoContato: "Historial de contactos",

        //..parent/atualizacaoValores
        grupoProduto: "Grupo de productos",
        markupAtual: "Marcado actual",
        valorMinimoAtual: "Valor Mínimo Actual",
        valorAdicionalAtual: "Valor Adicional Actual",
        informePercentual: "Introduce un porcentaje de actualización!",
        sucessoAtualizacao: "Actualización de valor exitosa!",

        //..parent/crud/all
        inclusao: "Inclusión!",
        alt: "¡Cambiar!",
        erro: "¡Error!",
        camposNaoVazios: "¡Los campos no pueden estar vacíos!",
        senhasDif: "¡Diferentes contraseñas!",
        regisSalvoSucess: "El registro se ha guardado con éxito.",

        //..parent/crud/all
        codigo: "Código",
        nome: "Nombre",
        ativo: "Activo",
        desejaExcl: "¿Quieres eliminar ",
        acaoNaoRevert: "¡Esta acción no se puede revertir!",
        exclusao: "¡Exclusión!",
        regisExcluSucess: "El registro se ha eliminado con éxito.",

        //../parent/crud/beneficio/edit.ts
        faixaInic: "Banner Inicio",
        faixaFim: "Finalizar pista",
        vlr: "Valor",

        //../parent/crud/beneficio/list.ts
        descr: "Descripción",

        //../parent/crud/campanha/edit.ts
        premio: "Premio",
        aviso: "¡Advertencia!",
        dtFimMaiorIncio: "¡La fecha de finalización debe ser mayor que la fecha de inicio!",

        //../parent/crud/campanha/list.ts
        dtInicio: "Fecha de inicio",
        dtFim: "Fecha de finalización",

        //../parent/crud/capacidadeProducao/edit.ts
        sucesso: "Éxito",
        capacidadeProducaoAtualizada: "Capacidad de producción actualizada!",

        //../parent/crud/capsula/list.ts
        nro: "Número",

        //../parent/crud/cliente/edit.ts
        cliente: "Cliente",
        localiz: "Ubicación",
        endeEntrega: "Dirección de entrega",
        contato: "Contacto",
        alertImpressao: "Para imprimir es necesario guardar el registro.",

        //../parent/crud/cliente/pontos.ts
        nenhumaPontuacao: "¡No se encontraron puntajes!",

        //../parent/crud/duplicacoes
        cadastrosNaoResolvidos:
            "Los conflictos NO se han resuelto, será necesario cambiar los datos de registro para que no haya duplicidades en el sistema",
        cadastroResolvidos: "Los conflictos de registro se resolvieron exitosamente",
        unificacaoConfirmacao:
            "A medida que continúe, el sistema eliminará el registro de origen, transfiriendo todos sus enlaces al registro de destino. Esta acción es irreversible, ¿está seguro de que desea continuar?",
        unificacaoErro: "¡Error al unificar registros! ¡Inténtalo de nuevo!",
        unificacaoSucesso: "¡Registros unificados exitosamente!",
        nenhumManterCadastro: "Seleccione un registro para conservar",
        maisDeUmManterCadastro: "Por favor seleccione sólo un registro para conservar",
        razaoSocial: "Razón Social",
        cpf: "CPF",
        cpfExistente: "El CPF ingresado ya está registrado",
        cnpj: "CNPJ",
        email: "Correo Electrónico",
        rg: "RG",
        telefone: "Teléfono",

        //../parent/crud/cliente/enderecoEntregaEdit.ts
        pesqCEP: "Buscar por código postal",
        pesqNaoEncResul: "La búsqueda no encontró ningún resultado",

        //../parent/crud/cliente/list.ts
        celular: "Móvil",
        editCampos: "Editar campos",
        fechar: "Cerca",
        editar: "Editar",
        vendasCliente: "Ventas al cliente ",

        //../parent/crud/cliente/listVendas.ts
        situacao: "Situación",
        data: "Fecha",
        dataConclusao: "Fecha de Terminación",
        dataAprovacao: "Fecha de aprobación",
        dataEntrega: "Fecha de Entrega",

        //../parent/crud/compras/edit.ts
        grupo: "Grupo",
        estoqueMin: "Stock mínimo",
        estoqueMax: "Stock máximo",
        qtdVendida: "Cantidad vendida",
        valorVendido: "Valor Vendido",
        consumoDiario: "Ingesta diaria",
        qtdComprar: "Cantidad a comprar",
        necessarioSelec: "Es necesario seleccionar la unidad de medida del producto:",
        necessarioSelecData: "Es necesario seleccionar la fecha de caducidad del producto:",
        necessarioAddValorUnitario: "Es necesario sumar el valor unitario del producto:",
        solicitarCotacaoForn: "Solicitar cotización a proveedores",
        desejaEnviarEmail: "¿Quieres enviar un correo electrónico solicitando cotización a proveedores?",
        emailEnviadoForn: "Correo electrónico enviado a proveedores.",
        cancelarCompra: "Cancelar Compra",
        desejaCancelar: "¿Quieres cancelar esta compra ",
        alterar: "Alterar",

        //../parent/crud/compras/cotacao.ts
        historicoComprasProduto: "Historial de compra de productos",

        //../parent/crud/compras/melhorCompra.ts
        melhorCompraSucesso: "Mejor compra generada con éxito",
        desejaImprimir: "¿Desea imprimir el PDF?",

        //../parent/crud/configuracaofranquia/edit.ts
        soma: "Suma",
        mult: "Multiplicación",
        nenhum: "Ninguno",
        franq: "Franquicia",
        user: "Usuario",
        homolog: "Homologación",
        prod: "Producto",
        avisoSNGPCControlado:
            "Ten en cuenta que al no proporcionar los datos de RG del cliente en ventas controladas, pueden surgir problemas al enviar automáticamente la información a SNGPC.",
        updateMarkupPadrao:
            "Estás actualizando el margen de beneficio estándar de la franquicia, ¿quieres volver a calcular el valor de ventas de todos los productos que utilizan este margen de beneficio?",
        updateMarkupPadraoSucesso: "Valor de ventas de productos actualizados exitosamente",

        //../parent/crud/configuracaoWhatsApp/edit.ts
        desconectarIntegracao: "Desconectar integración",
        confirmarDesconectarIntegracao: "¿Estás seguro de que deseas desconectar la integración con WhatsApp?",

        //../parent/crud/contagerencial/edit.ts
        entrada: "Entrada",
        saida: "Salida",
        transferecia: "Transferir",

        //../parent/crud/contagerencial/list.ts
        classif: "Clasificación",

        //../parent/crud/coleta/list.ts
        localEntrega: "Lugar de Entrega",

        //../parent/crud/contapaga/list.ts
        venc: "Venc.",
        vlrOrig: "Valor original",
        vlrPago: "Importe pagado",
        pagamento: "Pagamento",

        //../parent/crud/contapagar/edit.ts
        pago: "Pagado",
        descont: "Descuento",
        juros: "Interés",
        forma: "Forma",

        //../parent/crud/contapagarmultiplas/edit.ts
        vencimento: "Vencimento",
        saldo: "Saldo",

        //../parent/crud/contareceber/edit.ts
        tarifa: "Tarifa",
        vlrOriginal: "Valor original",

        //../parent/crud/contareceber/listOperadoras.ts
        operadora: "Operador",
        autoriz: "Autorización",

        //../parent/crud/contareceber/recebimentoconta.ts
        dinheiro: "Dinero",
        cheque: "Cheque",
        boleto: "Boleto",
        cardDebito: "Tarjeta de débito",
        cardCredito: "Tarjeta de crédito",
        vlrNaoPodeSerMaior: "El monto a pagar no puede ser mayor que el saldo por cobrar (R $",
        vlrDescMuitoAlto:
            "El monto del descuento es demasiado alto, el monto del descuento no puede ser mayor que el saldo + intereses.",
        opNaoPermitida: "¡Operación no permitida!",

        //../parent/crud/contarecebermultiplas/edit.ts
        recebido: "Recibido",
        recebimento: "Recibo",

        //../parent/crud/contarecebida/list.ts
        vlrReceb: "Importe recibido",

        //../parent/crud/devolucaCompra/consulta.ts
        notaEntrada: "Nota de Entrada",
        notaDevolucao: "Nota de Devolución",

        //../parent/crud/etapaPCP/edit.ts
        descricao: "Descripción",
        ordem: "Orden",
        obrigatorio: "Obligatorio",

        //../parent/crud/etiquetas/list.ts
        erroGerarEtiq: "Error al generar etiquetas",
        nenhumaEtiqueta: "No hay etiquetas disponibles para imprimir",

        //../parent/crud/formafarmaceutica/edit.ts
        dose: "Dosis",
        percentual: "Porcentaje",
        unitario: "Unitario",
        subGrupo: "Subgrupo",
        tipoCalculo: "Tipo de cálculo",
        quantidade: "Cantidad",
        desmembrado: "desmembrado",
        sistemico: "sistémico",
        topico: "Tema",
        total: "Total",
        homeopatia: "Homeopatía",
        papel: "Papel",
        volQtdeMg: "Volumen x Cant. (mg)",
        volQtdePorcent: "Volumen x Qty (%)",
        volume: "Volumen",
        replicou: "Replicado",
        regisSalvosSucess: "Los registros se han guardado correctamente",
        frmFarmaceutica: "FORMA FARMACÉUTICA",

        //../parent/crud/formafarmaceutica/item.ts
        qsp: "Qsp",
        falvoriz: "Aromatizante",
        veicuExcip: "Vehículos / Excipientes",

        //../parent/crud/formafarmaceutica/list.ts
        vlrMin: "Valor mínimo",
        vlrAdd: "Valor adicional",

        //../parent/crud/formarecebimento/edit.ts
        metodo: "Método",
        bandeiras: "Banderas",
        obgAoMenosUma: "La selección de al menos una bandera es obligatoria.",
        avis: "Advertencia",
        mtdNaoPodeSerRemov: "¡El método no se puede eliminar porque ya tiene cuentas recibidas!",
        desejaReplFrma: "¿Quieres replicar el formulario?",
        casoAltRecomendSalvar: "Si este registro ha cambiado, le recomendamos que guarde antes de continuar",
        replicado: "replicado",
        registroSalvSucess: "El registro se guardó correctamente",
        nenhumaBandeiraAdd: "No se ha añadido ninguna bandera.",

        //../parent/crud/formulapadrao/edit.ts
        tipoProd: "Tipo de producto",
        qtde: "Cantidad",
        qtdePesagem: "Qty de pesaje",
        unMedida: "Un. Medida ",
        unManipulacao: "Un. Manipulación",
        fase: "Fase",
        desejaRealmDesab: "¿Realmente quieres deshabilitar?",
        replicAtualiz: "Replicado / Actualizado",
        qtdeFormPadrao: "Cantidad de fórmula estándar",

        //../parent/crud/formulapadrao/item.ts
        escolhaKitParaVincu:
            "Este producto está vinculado con un kit de empaque, elija el kit que desea vincular a esta fórmula.",
        itensKitAddAuto:
            "Los elementos que componen este kit se agregarán automáticamente a esta fórmula estándar, ¿quieres vincular este kit?",
        casoNaoQueiraKit: "Si no desea vincular un kit a esta fórmula, haga clic en NO para continuar sin vínculos.",
        prodFinal: "Producto final",
        tipoFormula: "Tipo de fórmula",
        verFrmlPadrao: "Ver fórmula estándar",
        frmlPadrao: "Fórmula estándar",
        recalcValores: "Nuevo cálculo de valores",
        nenhumRegisSelec: "Ningún registro seleccionado.",

        //../parent/crud/formulapadraomanipulacao/edit.ts
        acaoRealizSucess: "Acción realizada con éxito.",
        manipulacao: "Manipulación",

        //../parent/crud/formulapadraovalorproduto/list.ts
        formula: "Fórmula",
        custoCalculado: "Costo calculado",
        refeAtual: "Referencia actual",
        valorVendaCalc: "Valor de ventas calculado",
        valorVendaAtual: "Valor de venta actual",

        //../parent/crud/fornecedor/edit.ts
        fisica: "Física",
        juridica: "Legal",

        //../parent/crud/franquia/edit.ts
        replicaFranquia: "¡Franquicia de réplicas!",
        replicasBaseSalvas: "La réplica base se guardó correctamente.",
        msgExcluirDados: "¿Está seguro de que quiere eliminar los registros de la franquicia?",
        msgFalhaExclusao: "Fallo al intentar borrar datos de la franquicia.",

        //../parent/crud/inutilizacaoNotaFiscal/edit.ts
        inutilizacao: "Desactivación",
        inutilizacaoSucesso: "Facturas desactivadas con éxito",

        //../parent/crud/inventario/edit.ts
        cod: "Cod.",
        qtdDisponivel: "Cant. Disponible",
        qtdVencido: "Cant. Vencido ",
        unid: "Unid.",
        lote: "Lot",
        dtFabric: "Fecha de fabricación",
        dtVal: "Fecha de vencimiento",
        qtdAtual: "Cant. Actual",
        qtdMovimentada: "Cant. Ocupado ",
        dtCriacao: "Fecha de creación",
        valLote: "Validez del lote",
        unidade: "Unidad",
        tipoMoviment: "Tipo de movimiento",
        campoVazio: "¡Campo vacío!",
        campoInventDeve: "El campo de tipo de inventario debe ser llenado.",
        campoGrupoProdsDeve: "El campo Grupo de productos debe ser llenado.",
        jaExisteInvent: "¡Ya hay un inventario en progreso!",
        naoPossivIniciarDois: "No es posible iniciar dos inventarios al mismo tiempo.",
        aprovOrcamento: "Aprobar presupuesto.",
        todasAltRevertidas: "Esta opción hará que todos los cambios realizados sean revertidos.",
        desejaReverter: "¿Desea revertir todas las configuraciones?",
        reverter: "Revertir",
        inventCancelado: "¡Inventario cancelado!",
        inventCanceladoSucess: "Inventario cancelado correctamente. Todos los cambios se han revertido ",
        naoPossivCancelar: "No fue posible cancelar el inventario en este momento, inténtelo de nuevo.",
        opcIraFinaliz: "Esta opción finalizará el Inventario.",
        desejaProsseg: " ¿Desea continuar?",
        finalizar: "Finalizar",
        inventFinalizado: "¡Inventario terminado!",
        inventFinalizadoSucess: "Inventario completado con éxito.",
        naoPossivFinalizar: "No fue posible finalizar el inventario en este momento, inténtelo de nuevo.",
        alteraQtdLote: "Esta opción cambiará la cantidad de este lote y no se puede revertir.",
        loteAlterado: "Lot Changed!",
        tenteNovamente: "Intenta de nuevo.",
        inventario: "Inventario",
        inventFinalizadoSoConsul: "Inventario finalizado, solo consulta disponible",
        venda: "Rebaja",

        //../parent/crud/inventario/list.ts
        tipoInventario: "Tipo de inventario",
        status: "Estado",
        desejaExclInventario: "¿Desea eliminar el inventario",

        //../parent/crud/inventario/produtos.ts
        precMaxConsumidor: "Precio máximo al consumidor",
        todosProdsVerifSucess: "Todos los productos verificados correctamente.",
        naoEncontProdsDiverg: "Productos divergentes no encontrados.",

        //../parent/crud/kitembalagem/edit.ts
        embalagem: "Embalaje",
        matPrima: "Materia prima",
        semiAcab: "Semi-terminado",
        acabado: "Terminado",
        capsula: "Cápsula",
        floral: "Floral",
        recipiente: "Contenedor",
        tampa: "Tampa", // ¿dónde está?
        aplicador: "Aplicador",
        outrosEmb: "Otros (embalaje)",
        priorizar: "Priorizar",

        //../parent/crud/kitembalagem/edit.ts
        antimicrobiano: "Antimicrobiano",
        sujeitoControlEsp: "Sujeto a control especial",
        titulo: "Título",
        msg: "Mensaje",
        erroFormaFarmaceutica: "¡Debe tener al menos una Forma Farmacéutica registrada.",
        erroProduto: "¡Debes tener al menos un producto registrado.",

        //../parent/crud/meta/edit.ts
        jan: "enero",
        fev: "febrero",
        mar: "marzo",
        abr: "abril",
        mai: "mayo",
        jun: "Junho",
        jul: "julio",
        ago: "agosto",
        set: "septiembre",
        out: "octubre",
        nov: "noviembre",
        Dec: "diciembre",
        dadosInval: "Datos no válidos",
        dataFimMiorInicio: "¡La fecha de finalización debe ser mayor que la fecha de inicio!",
        periodo: "Periodo",
        diasUteis: "Días hábiles",

        //../parent/crud/metodoPagamento/list.ts
        nroParcelas: "Número de plazos",
        intervalEntreParc: "Intervalo (días) entre paquetes",
        diasPrimeiraParc: "Días para la primera entrega",

        //../parent/crud/molde/edit.ts
        sistApenasMl: "¡El sistema solo está preparado para una unidad de medida igual a Mililitro!",

        //../parent/crud/movimentacaoconta/list.ts
        cntCorrente: "Cuenta corriente",
        cntGerencial: "Cuenta gerencial",

        //../parent/crud/movimentacaoestoque/edit.ts
        vlrUnit: "Valor unitario",
        estqDestinoNaoPodeMesmoOrig: "El stock de destino no puede ser el mismo que el stock de origen",
        incluaUmLote: "Incluir al menos un lote de producto",
        loteJaExist: "Lote existente",
        oLote: "El lote",
        jaInclNessaMoviment: "ya se ha incluido en este movimiento de inventario. ¿Quieres cambiarlo? ",

        //../parent/crud/movimentacaoestoque/edit.ts
        qtdDisponivelInsulf: "Cantidad insuficiente disponible",
        naoPossuiQtdSufic: "no hay suficiente cantidad disponible. ¿Desea continuar?",
        msgStatusQuarentena: "El lote está en cuarentena y no se puede mover",
        msgLoteEmFracionamento: "El lote está en fraccionamiento y no se puede mover.",

        //../parent/crud/anturezaoperacao/list.ts
        dentroEstado: "State State",

        //../parent/crud/paciente/list.ts
        especie: "Especie",

        //../parent/crud/paciente/edit.ts
        atencaoPacientePossuiAlergias: "Atención, este paciente tiene alergias:",
        atencaoPacientePossui: "Atención, este paciente tiene condiciones especiales:",

        //../parent/crud/pcp/edit.ts
        dataInicio: "Fecha de inicio",
        dataFim: "Fecha de finalización",
        motivoEtapaPCP: "Motivo",
        usuarioInicio: "Usuario inicio",
        usuarioFim: "Usuario finalización",
        manipulacaoNaoEncontrada: "Orden de manipulación no encontrada!",

        //../parent/crud/PDV/list.ts
        sldCaixa: "Saldo de caja",
        sldAtulCaixa: "Saldo de caja actual",

        //../parent/crud/posologia/list.ts
        dosesSemana: "Dosis por semana",
        posologiaPadraoAlerta:
            'Al marcar la posología como "Predeterminado", sepa que se cargará automáticamente en todos los artículos terminados y de manipulación. El intercambio se puede hacer en cualquier momento, pero recuerde: Solo una posología debe estar marcada como predeterminada. De lo contrario, el sistema seleccionará el primero que encuentre.',

        //../parent/crud/prescritor/edit.ts
        masc: "Hombre",
        fem: "Feminio",
        faixaFinalMaior: "No se permite un rango final inferior o igual al rango de salida.",

        //../parent/crud/produto/edit.ts
        verPrescr: "Ver Prescritor",
        liberado: "Released",
        formfarmceu: "Forma Farm.",
        excipiente: "Excipiente",
        fc: "F.C.",
        cstUnit: "Coste unitario",
        val: "Validez",
        fab: "Fabricación",
        sinonimo: "Sinónimo",
        ver: "Ver",
        verLote: "Ver Lote",
        printRotulo: "Imprimir etiqueta",
        defLotePadr: "Establecer como lote predeterminado",
        estNaoSeraControl:
            "¡El sistema ya no controlará el stock de este producto y ya no se mostrarán las advertencias al respecto!",
        desejaRealmRemoverContr: " ¿Realmente desea eliminar el control de inventario de este producto?",
        atualizVlrVenda: "¿Actualizar valor de venta?",
        desejRecalcVlrVenda: "¿Desea volver a calcular el valor de venta de este producto?",
        desejRecalcMarkup: "¿Desea volver a calcular el margen de beneficio de este producto?",
        logAltValores: "Registro de cambio de valor financiero del producto",
        jaExisteAssocExcipiente: "Ya existe una asociación de excipientes para esta forma farmacéutica",

        //../parent/crud/produto/list.ts
        vlrsVendaAtualizSucess: "Cifras de ventas actualizadas con éxito.",

        //../parent/crud/produtologfinanceiro/list.ts
        rotina: "Rutina",
        vlrCusto: "Valor de coste",
        cstRefe: "Costo de referencia",
        cstMedio: "Costo promedio",
        vlrVenda: "Valor de venta",
        logAlt: "¡Cambiar registro!",
        naoHouveAltRegis: "¡No hubo cambios registrados en este producto!",

        //../parent/crud/produto/replicarProduto.ts
        selecItensProd: "Selección de artículos del producto",

        //../parent/crud/recompensa/list.ts
        dias: "dias",
        diasVencimento: "Días hasta el vencimiento",
        percentualValorAPagar: "Porcentaje del monto a pagar",
        somaValoresPercentualTotal: "La suma de los porcentajes totales {replace}% no cierra al 100%",

        //../parent/crud/recompensa/list.ts
        ptsNecess: "Puntos necesarios",

        //../parent/crud/rede/edit.ts
        naoInfo: "No informado",
        tributMunicipio: "1 Tributación en el Municipio",
        tributForaMunicipio: "2 Tributación fuera del municipio",
        isencao: "3 Exención",
        imune: "4 Immune",
        exigiSuspensJudic: "5 Requisito Decisión Judicial Suspendida",
        exigiSuspensAdminis: "6 Requisito Suspendido Procedimiento Administrativo",
        baseModelRede: "¡Modelo de red base!",
        replicaBaseSucess: "Réplica basada en modelo generada con éxito, las franquicias se pueden replicar.",

        //../parent/crud/replicarSelecaoFranquia/edit.ts
        nomeFantasia: "Nombre de fantasía",
        selecFranq: "Selección de franquicia",

        //../parent/crud/replicarSelecaoFranquiaFiliais/edit.ts
        selecFranqFiliais: "Selección de franquicias de sucursales",
        replicarFiliais: "Replicar entre ramas",

        //../parent/crud/rotulopersonalizado/edit.ts
        semLogo: "Sin logo",
        esquerda: "Izquierda",
        direita: "Derecho",
        semQR: "Sin código QR",
        esqBaixo: "Izquierda abajo",
        dirBaixo: "Derecha abajo",
        codBarras: "Código de barras",
        loteEstoque: "Stock lote",
        campo: "Campo",
        tamFonte: "Tamaño de fuente",
        negrito: "Negrita",
        confImpress: "Configuración de impresión",
        visuImpress: "Vista previa de impresión",
        tipoUso: "Tipo de uso",
        colocarCima: "Poner",
        colocarBaixo: "Poner",
        dimensoes: "Dimensiones",
        msgLoteNaoEncontrado: "Lote no encontrado, no se puede cargar la plantilla de etiqueta",

        //../parent/crud/separacao/edit.ts
        itensEmb: "Artículos de embalaje",
        unidadeMedida: "Unidad de medida",
        necSepararTodosItens: "Debes separar todos los elementos para continuar",
        necSenhaParaSepararTodosItens: "Se requiere contraseña de administrador para separar todos los elementos.",

        //../parent/statusPcp/view.ts
        apontarEmLote: "Registrar en lote",
        sucessoApontarEmLote: "Los cambios de estado se han guardado con éxito",

        //../parent/crud/separacao/list.ts
        dtInclusao: "Fecha de inclusión",
        dtAprov: "Fecha de aprobación",

        //../parent/crud/servico/list.ts
        atividade: "Actividad",
        aliq: "Alícuota",

        //../parent/crud/transportador/edit.ts
        transport: "Transporter",

        //../parent/crud/transportadora/edit.ts
        transporta: "Transportista",

        //../parent/crud/unidademedida/edit.ts
        massa: "Masa",
        multiplicador: "Multiplicador",
        dividir: "Dividir",
        grama: "Gramo (g)",
        exKg: "KG: Dividir por 1000. Microlitro: Multiplicar por 1000.",
        mililitro: "Mililitro (ml)",
        exMl: "L: dividir por 1000. Microlitro: Multiplicar por 1000",
        un: "Unidad (un)",
        cxUn: "Cx: Multiplicar por 12",
        ui: "Unidad Internacional (UI)",
        exUI: "Millones UI: Multiplicar por 1000000",
        ufc: "Unidad de Formación de Colonias (UFC)",
        exUFC: "Millones UFC: Multiplicar por 1000000",
        utr: "Unidad de Turbidez (UTR)",
        exUTR: "Millones UTR: Multiplicar por 1000000",
        naoNecessiOpConversUI:
            "No necesita un operador de conversión pre-parametrizado, vendrá del campo Factor UI del registro Producto.",
        naoNecessiOpConversUFC:
            "No necesita un operador de conversión pre-parametrizado, vendrá del campo Factor UFC del registro de Lote o Producto.",
        naoNecessiOpConversUTR:
            "No necesita un operador de conversión pre-parametrizado, vendrá del campo Factor UTR del registro de Lote o Producto.",
        unMedidaPadrDe: "La unidad de medida estándar",
        eh: "es",
        informOpEValor: ". Informar al operador y el valor a convertir. Ej .: ",

        //../parent/crud/usuario/edit.ts
        padr: "Estándar",
        franquador: "Franquiciador",
        franqueado: "Franquiciado",
        atendente: "Asistente",
        prescritor: "prescripción",
        adminEstoque: "Gestión de inventario",
        comercial: "Comercial",
        histAlterUsuario: "Historial de cambios del Usuario",

        //../parent/crud/vendaContinuo/list.ts
        vendaContinuos: "Venta continua",
        paciente: "Paciente",
        vlrVendido: "Cantidad vendida",
        vlrAtualizado: "Valor actualizado",
        detVendaOrca: "Detalles de venta / Presupuesto",
        adiarAviso: "Posponer publicación",
        arqAviso: "Aviso de archivo",
        orcarEnviarMsgWpp: "Presupuesta y envía un mensaje por Whatsapp",
        avisoFoiArqSucess: "El aviso se ha presentado correctamente.",
        adiarAvisoCont: "Posponer aviso continuo",
        adiar: "Posponer",
        qtdDiasAdiarMaiorZero: "El número de días para posponer debe ser mayor que cero.",
        avisoFoiAdiadoSucess: "La advertencia se ha pospuesto correctamente.",
        orcaCriadoSucess: "Presupuesto creado con éxito.",
        enviarOrca: "Enviar presupuesto",
        enviarMsgOrcaCliente: "Enviar mensaje de presupuesto al cliente",

        //../parent/dashboard/charts/pedidos.ts
        carregando: "Cargando",

        //../parent/dashboard/charts/previsaoRoyalties.ts
        previsao: "Previsión",
        taxaPublic: "Tarifa de publicidad",

        //../parent/dashboard/evolucaoVendas.ts
        vendasDiario: "Ventas Aprobadas Hoy",
        somaVendasMensal: "Valor de Ventas este Mes",
        qtdeVendasMensal: "Cantidad de Ventas para este Mes",
        somaVendasAnualAprovadas: "Valor de Ventas Aprobadas (período)",
        qtdeVendasAnualAprovadas: "Cantidad de Ventas Aprobadas (período)",
        somaVendasAnualRejeitadas: "Valor de Ventas Rechazadas (período)",
        qtdeVendasAnualRejeitadas: "Cantidad de Ventas Rechazadas (período)",
        qtdeAtendimentos: "Número de Asistencias",
        ticketMedio: "Billete Medio",
        qtdeOrcamentosRejeitados: "Número de Presupuestos Rechazados en %",

        //../parent/dashboard/manipulacoes.ts
        somaPorFormaFarmaceutica: "Valor de Manipulaciones por Forma Farmacéutica",
        qtdePorFormaFarmaceutica: "Cantidad Manipulaciones por Forma Farmacéutica",
        somaPorEspecie: "Valor de Manipulaciones por Especie de Paciente",
        qtdePorEspecie: "Cantidad de Manipulaciones por Especie de Paciente",

        //../parent/dashboard/totaisPorCategoria.ts
        vendasVendedorAnual: "Valor de Ventas por Vendedor",
        percVendasPrescritorMensal: "% de Ventas por Prescriptor",
        percOrigemVendas: "% de fuente de servicio",

        //../parent/fichaTecnica/edit.ts
        ensaios: "Pruebas",
        especific: "Especificaciones",
        validado: "Validado",
        resultados: "Resultados",
        selecLoteAddResult: "Seleccione lote para agregar resultado",
        impFichaTecnica: "Impresión de ficha técnica.",
        desejaImp: "¿Quiere imprimir los resultados de la prueba?",
        nenhumLoteDisp: "No hay lotes disponibles",

        //../parent/fracionaEstoque/kitEmbalagem.ts
        outro: "Otro",
        naoSeraInclVenda: "no se incluirá en la venta.",
        naoHaLotes: "¡No hay lotes de productos disponibles! ",
        naoHaQtd: "¡No hay cantidad de producto disponible en el lote seleccionado! ",
        desejaRemov: "¿Quieres eliminar?",
        ErroAoRemov: "Error al eliminar el elemento, inténtelo de nuevo.",
        desejaAlter: "¿Quieres cambiar?",
        regisAltSucess: "El registro se ha cambiado correctamente.",

        //../parent/historico/vendaHistorico/list.ts
        vendaHist: "Venta histórica",

        //../parent/home/fastaction/continuos/continuos.ts
        enviar: "Enviar",
        avisosContinuo: "Avisos de Continuo",

        //../parent/home/fastaction/entregas/entregas.ts
        entregas: "Entregas",
        vendasEntrega: "Ventas con entregas",
        marcarEntregue: "Marcar como entregado",
        codigoRastreio: "Código de seguimiento",
        desejaGerarCod: "¿Quieres generar el código de seguimiento?",
        temCertezaDesejaGerarCod: "¿Está seguro de que desea generar el código de seguimiento nuevamente?",

        //../parent/home/fastaction/estoqueFracão/estoqueFracão.ts
        separacaoItensVenda: "Separación de artículos de venta",
        conclSeparacItensApagados:
            "Una vez completada la separación, todos los elementos se separarán. ¿Desea continuar?",

        //../parent/home/fastaction/expedicao/expedicao.ts
        infoEntrega: "Información de entrega",
        expedFinalizMesmoSemDados:
            "El envío se completará incluso si no se han completado los detalles del envío. ¿Desea continuar?",
        expedFoiFinaliz: "La expedición ha terminado.",
        ocorrErroAoInclRegis: "Se produjo un error al intentar incluir los registros, inténtelo de nuevo.",
        motivoObrigatorioEtapaPcp: "Razón obligatoria al omitir etapa",
        vendasEmExped: "Envío de ventas",
        tentativasEntrega: "Intentos de entrega",
        tresTentativasEntrega: "El cliente tiene al menos una venta con 3 intentos de entrega",

        //../parent/home/fastaction/financeiro/financeiro.ts
        gerarReforco: "Generar refuerzo",
        gerarSangria: "Generate Sangria",
        reforcoGeradSucess: "Refuerzo generado con éxito.",
        sangriaGeradSucess: "Sangría generada con éxito.",
        caixaFechado: "¡El cajero está cerrado! Primero necesitas abrir el cajero para hacer el movimiento",

        //../parent/home/fastaction/manipulacao/list.ts
        visuManipu: "Ver manipulación",
        confLoteEstqManipu: "Comprobar manipulación de lote / stock",
        printManipu: "Manipulación de impresión",
        conclManipu: "Manipulación completa",
        conclManipuMsg: "Las manipulaciones seleccionadas se completaron con éxito!",
        conclManipuErr: "Error de procesamiento al completar manipulaciones",

        //../parent/home/fastaction/manipulacao/manipulacao.ts
        estqNaoVerif: "Inventario no verificado",
        estqQtdBaixaDiverg: "Inventario con cantidad baja divergente",
        estqVerif: "Inventario verificado",
        estqAindaNaoVerif: "Stock aún no verificado",
        manipulacoes: "Manipulaciones",
        lotesEstqManipul: "Lotes / Manejo de stock",
        modelImpressNaoEncont: "¡Modelo de impresión no encontrado!",
        manipPossuiDiverg: "La manipulación tiene divergencias en su stock.",
        desejaConfLotes: " ¿Quieres comprobar los lotes?",

        //../parent/home/fastaction/orcamentos/orcamentos.ts
        dtValOrcaVenc: "Fecha de vencimiento del presupuesto vencido",
        valOrcaEmDia: "Validez presupuestaria actualizada",
        valOrcaLenos10DiasVenc: "Validez del presupuesto con menos de 10 días para caducar",
        vendas: "Ventas",
        orcamentos: "Presupuestos",
        opcIraArquivOrca:
            "Esta opción archivará este presupuesto y solo se puede acceder a él a través de los filtros avanzados de lista de presupuestos.",
        revPreenchCampos: "Revisión de relleno de campo",
        informeMotivoRejeicaoCampos: "Indique el motivo del rechazo",
        enviarVendParaFrac: "¿Quieres enviar la venta a Fraccionamiento?",
        vendaEmSeparFrac: "La venta está separada por fraccionamiento.",
        codzin: "Código:",
        masOcorrAlert: ", ¡pero se han producido alertas!",
        salvoSucess: "Guardado correctamente",
        adiarOrca: "Posponer presupuesto",
        adiarDtValOrca: "Aplazar la fecha de vencimiento del presupuesto",
        orcaAdiadoSucess: "El presupuesto se pospuso correctamente.",
        rotuloManipu: "Etiqueta de manipulación",
        printRegistros: "Imprimir registros",

        //../parent/home/fastaction/produtos/produtos.ts
        comAlertasEstq: "con alertas de stock",
        comEstqBaixo: "stock bajo",
        semEstq: "agotado",
        proxDaDataVenc: "cerca de la fecha de vencimiento",
        prods: "Productos",

        //../parent/home/fastaction/vendas/list.ts
        cancelarVenda: "Cancelar venta",
        visuVenda: "Ver oferta",
        printVenda: "Venta impresa",
        faturarVenda: "Venta de facturas",

        //../parent/home/fastaction/vendas/vendas.ts
        vendaSemPgtosRealiz: "Venta sin pagos realizados",
        envioNFS: "Envío de factura de servicio",
        envioCFPend: "Envío de cupón fiscal pendiente",
        eEnvioCFPends: "y envío de cupón fiscal pendiente",
        pend: "Pendiente",
        nfEnvAgRetornoPref: "Factura enviada, esperando regreso del ayuntamiento",
        financCntsReceber: "Financiero - Cuentas por cobrar",
        novoPgto: "Nuevo pago",
        pgtos: "Pagos",
        rotulo: "Etiqueta",

        //../parent/importacaonfe/edit.ts
        erroDadosXml: "Error de datos XML",
        regisNaoPodeSalvo: "No se puede guardar el registro.",
        printRotulos: "Imprimir etiquetas",
        itensNaoAssociados: "Todos los elementos de la nota deben estar asociados a un producto.",
        itensNaoConferidos: "Todos los elementos de la nota deben ser revisados.",
        imprimirFichas: "¿Quieres imprimir las fichas técnicas de los lotes importados?",

        //../parent/importacaonfe/produtos.ts
        necessAssociarProds: "Es necesario asociar todos los productos para la importación.",
        necessAssociarLotes: "Es necesario asociar todos los lotes a los productos a importar.",
        necessAssociarUmProd: "Es necesario asociar un producto para importar",
        necessAssociarUmLot: "Es necesario asociar un lote al producto para importar",
        verifics: "Cheques",

        //../parent/manipulacao/detalhesitem.ts
        tipo: "Tipo",
        valorCusto: "Valor Costo",
        valorVenda: "Valor Venta",
        diferencaCustoVenda: "Diferencia de costo / venta",

        //../parent/manipulacao/edit.ts
        qtdDeveMaiorZero: "La cantidad debe ser mayor que cero.",
        naoEncontrAtivosManipu: "No se encontraron activos en esta manipulación,",
        naoForamEncontr: "No encontrado",
        embalagens: "Packaging",
        capsulas: "Capsulas",
        paraEstaManip: "para esta manipulación,",
        desejaConsolid: "¿quieres consolidar antes de continuar?",
        naoEncontrAtivosManipul: "¡No se encontraron activos en esta manipulación!",
        identAltVolumManipu:
            "¡El sistema ha identificado un cambio en el volumen total de esta manipulación y se consolidará antes de continuar!",
        e: " y ",
        osProds: "Los productos <b>",
        naoPossVlr: "</b> no tiene ningún valor definido.",
        naoPossuiVlr: "</b> no tiene un valor definido.",
        desejProssegAssim: "¿Quieres continuar de todos modos?",
        regSalvoMasAlert: "¡El registro se guardó correctamente, pero se produjeron alertas!",
        regSalvoSucess: "El registro se ha guardado correctamente.",
        qtdMinPermitida: "La cantidad mínima permitida para esta forma farmacéutica es",
        qtdMaxPermitida: "La cantidad máxima permitida para esta forma farmacéutica es",
        carregandoAtivos: "Cargando activos",
        carregandoEmbal: "Cargando embalaje",
        carregandoCaps: "Cargando cápsulas",
        selecModelImpress: "Seleccionar un modelo de impresión",
        jaExistCapsula: "Ya hay una cápsula en esta manipulación",
        jaExistEmbal: "Ya hay empaque en este manejo",
        detalhCustosManipu: "Detalles de los costos de manipulación",
        consolidouSucess: "¡Consolidado exitosamente!",
        consolidouSucessEmbalagem: "¡Consolidado exitosamente! Pero es necesario agregar el empaque manualmente",
        consolidNaoSucess: "¡La consolidación no se completó correctamente!",
        validacaoVolume:
            "¡La suma del volumen de activos es mayor que el volumen total de manipulación. Intente consolidar de nuevo o haga los ajustes necesarios para evitar errores!",
        sisteVerifKit: "El sistema ha verificado que el <b> kit de embalaje",
        sisteVerifKitt: "El sistema verificó que el kit de empaque",
        maiorCompatibEmbalagem: "</b> es más compatible con esta forma que su kit de empaque actual (<b>",
        maiorCompatibEmbalagemm: "tiene mayor compatibilidad con esta forma que su kit de empaque actual (",
        maiorCompatibForma: "</b> es más compatible con esta forma de dosificación.",
        maiorCompatibFormaa: "tiene una mayor compatibilidad con esta forma de dosificación.",
        desejSubstKit: "¿Desea que el sistema reemplace el kit de embalaje?",
        sisteVerifCapsula: "El sistema ha verificado que la <b> cápsula",
        systemVerifCapsulaa: "El sistema ha verificado que la cápsula",
        volMaisAdeqCaps: "</b> tiene el volumen más adecuado para esta forma que la cápsula actual (<b>",
        volMaisAdeqCapss: "tiene el volumen más adecuado para esta forma que la cápsula actual (",
        volMaisAdeqForma: "</b> tiene el volumen más adecuado para esta forma de dosificación.",
        volMaisAdeqFormaa: "tiene el volumen más adecuado para esta forma de dosificación.",
        desejSubstCaps: "¿Quiere que el sistema reemplace la cápsula?",
        sisteVerifMolde: "El sistema verificó que el molde <b>",
        sisteVerifMoldee: "El sistema verificó que el molde",
        volMaisAdeqMolde: "</b> tiene el volumen más adecuado para esta forma que el molde actual (<b>",
        volMaisAdeqMoldee: "</b> tiene un volumen más adecuado para esta forma que el molde actual (",
        desejSubstMolde: "¿Quiere que el sistema reemplace el molde?",
        altManualVolDose: "Cambio manual del volumen de la dosis <br/>",
        aoAltSistDeixaCalc:
            "Al cambiar manualmente el volumen de dosis, el sistema deja de calcular automáticamente según la parametrización de la concentración máxima del activo. <br/> ",
        desejProssegAltManual: "<br> ¿Desea continuar con el cambio manual? </br>",
        produtoInativoFormaFarmaceutica:
            "El producto {produto} vinculado a la forma farmacéutica está inactivo y no se agregará a la manipulación!",
        produtoInativoPreVenda:
            "El producto {produto} vinculado a la prevenda está inactivo y no se agregará a la manipulación!",
        produtoInativoMolde:
            "El producto {produto} vinculado a la plantilla está inactivo y no se agregará a la manipulación!",
        necessarioConsolidar: "¡Es necesario consolidar para actualizar valores!",
        desejRemoverAssocAnterior: "¿Quieres eliminar las asociaciones anteriores de Forma Farmacéutica?",
        valorMinimoFormaFarmAtingido:
            "El valor total es menor al valor mínimo de la forma farmacéutica, no se permitirán valores menores al valor mínimo registrado",

        //../parent/manipulacao/list.ts
        qtdTotal: "Cantidad total",
        qtdTotalPesado: "Cantidad pesada total",
        naoHaEstqDisp: "¡No hay suficiente stock para servir! ¿Desea continuar?",
        sisteIdentifProd: "El sistema ha identificado un producto",
        paraEssaQtdDose: "para esa cantidad de dosis! ¿Quieres reemplazar con ",
        fatorUI: "Factor UI!",
        naoHaFtrUI: "¡No hay ningún factor de IU informado en este producto!",
        qtdCapsMultQtdForma:
            "Cantidad de cápsulas multiplicada por el valor del campo 'Cantidad' de la Forma farmacéutica.",
        qtdPercentVolForma: "Cantidad porcentual sobre el volumen total de la forma farmacéutica.",
        qtdSuficComplForma: "Cantidad suficiente para completar el Formulario farmacéutico",
        qtdTotalRepresMultQtd:
            "La cantidad total representa la multiplicación de la cantidad de dosis por el volumen total dividido por la dosis base.",
        semCalcEspecifQtdIgualQtd: "Sin cálculo específico, la cantidad total es igual a la cantidad",
        // Presta atención aquí
        obsPossuiFtrCorrec: " Obs. Tiene factor de corrección igual a ",
        eP: " e t",
        obsP: " Obs. T",
        ossuiFtrIgual: "iene factor UI igual a ",
        //El dio

        //../parent/manipulacao/item.ts
        ocorreuTrocaManual:
            "Se produjo un cambio de dosis manual, verifique la necesidad de cambiar también el volumen",
        trocaDose: "Cambio de dosis manual",

        //../parent/manipulacao/loteItem.ts
        houveAltProdsManiSalv: "Hubo un cambio en el manejo de productos, ¡guarde antes de continuar!",
        loteJaInfo: "¡Este lote ya ha sido informado para este producto!",
        qtdBaixaDeveMenorAtual: "¡La cantidad baja debe ser menor o igual a la cantidad actual en stock!",
        aoConfAltAlteradasNoEstq: "Al confirmar, los cambios se cambiarán directamente en stock. ¿Quieres proceder? ",

        //../parent/nfe/edit.ts
        entrd: "-Entrada",
        said: "-Salida",
        nfeNormal: "1-NF-e Normal",
        pgtoAVist: "0-Pago en efectivo",
        pgtoPrazo: "1-Pago de plazo",
        outros: "2-Otros",
        nfe55: "55-NF-e",
        nfc: "65-NFC-e",
        normal0: "0-Normal",
        consumFinal: "1-Consumidor Final",
        naoSeAplica: "0-No Aplicable",
        opPresenc: "1-Operación en Persona",
        opNaoPresencInternet: "2-Operación no en persona, a través de Internet",
        opNaoPresencTele: "3-Operación no en persona, Teleatendimento",
        nfceOpEntregaDomic: "4-NFC-e en funcionamiento con entrega a domicilio",
        opNaoPresencOutros: "9-Operación no en persona, otros",
        nfee: "NF-e",
        nfeEnvSucess: "NF-e enviada correctamente.",

        //../parent/notafiscalentrada/edit.ts
        descNaoPodeMaiorVlr: "El descuento no puede ser mayor que el monto total",

        //../parent/notafiscalsaida/edit.ts
        erroEmiss: "1 - Error de emisión",
        servNaoPrest: "2 - Servicio no proporcionado",
        erroAss: "3 - Error de firma",
        duplicNota: "4 - Nota Duplicidad",
        servic: "Servicio",
        cupomFisc: "Cupón fiscal",
        precUnit: "Precio unitario",
        nota: "Nota",
        cupom: "Cupón",
        //prestar atención
        a: "a",
        o: "o",
        atualiz: "Actualizando ",
        fiscal: " Fiscal ...",
        enviandReqCancel: "Enviando solicitud de cancelación de ",
        enviandConsultPara: "Enviando consulta a ",
        fiscalEletr: " Impuesto electrónico ...",
        confirmad: "Confirmado!",
        consultDe: "Consulta ",
        fiscalRetornSucess: " Impuesto devuelto correctamente, ",
        fiscalAprov: " Fiscal fue aprobado",
        retorn: "¡Regresa!",
        requisDeAlter: "Solicitud de cambio de ",
        fiscalEnv: " Fiscal enviado",
        comSucess: "con éxito.",
        requisDeCancel: "Solicitud de cancelación ",
        //El dio
        prossegRotinEnviaRequisCancelNFEscolh:
            "Al continuar, la rutina enviará una solicitud de cancelación de factura. Para continuar, elija un motivo de cancelación. ",
        motivCancel: "Motivo de la cancelación:",
        obgInfoMotivoCancel: "Obligatorio informar el motivo de la cancelación.",
        prossegRotinEnviaRequisCancelNFInsira:
            "Al continuar, la rutina enviará una solicitud de cancelación de factura. Para continuar, ingrese un motivo de cancelación. ",
        motivMtLongo: "Razón demasiado larga, use un máximo de 1000 caracteres.",
        motivMtCurto: "Razón demasiado corta, use al menos 15 caracteres para la descripción.",
        prossegAlterSeraoAtualizNF:
            "Al proceder, los cambios realizados en la venta se actualizarán en la factura enviada. ¿Quieres proceder? ",

        //../parent/notafiscalsaida/list.ts
        ambas: "Ambos",

        //../parent/printModel/condicao.ts
        dadosPrinc: "Datos principales",

        //../parent/printModel/edit.ts
        normal: "Normal",
        repeticao: "repetición",
        vlrCampo: "Valor de campo",
        complementVlr: "Complemento de valor",
        posicCompl: "Posición de complemento:",
        anteriorVlr: "Anterior al valor",
        posteriorVlr: "Después del valor",
        corText: "Color del texto:",
        corFundText: "Color de fondo del texto:",
        altLabelExib: "Cambiar etiqueta de visualización",
        altLabelInclInfoPerson: "Cambiar etiqueta para incluir información personalizada en la impresión",
        nomeCampo: "Nombre de campo:",
        criarNovoLabelExib: "Crear nueva etiqueta de visualización",
        criarNovoLabelInclInfos: "Crear nueva etiqueta para incluir información personalizada en la impresión",

        //../parent/printModel/sessao.ts
        modelImpressSalvoSucess: "Modelo de impresión guardado correctamente.",
        novaSessaoModel: "Sesión de nuevo modelo",
        condicaoModel: "Condición del modelo",
        modelAlterSucess: "Modelo cambiado correctamente.",
        copiaDe: "Copiar desde",
        modelImpressPara: "Imprimir modelo para",

        //../parent/produtoLote/edit.ts
        dataMovimentacao: "Fecha de mudanza",
        valorUnitario: "Valor unitario",
        tipoMovimentacao: "Tipo de movimiento",
        quantidadeEfetivaEstoque: "Cantidad efectiva en stock",
        usuario: "Usuario",
        statusAnterior: "Estado anterior",
        statusAtualizado: "Actualizado a",
        sucessArquivo: "¡La carga del archivo fue exitosa!",

        //../parent/crud/pontuacaoManual/list.ts
        alertaTansmIrrever:
            "El sistema transmitirá la puntuación de Dotz, este proceso es IRREVERSIBLE. ¿Desea continuar?",
        franquiaNaoUsaDotz: "Esta franquicia no está configurada para usar Dotz.",
        franquiaConfigOutraFranquia:
            "Esta franquicia está configurada para enviarse con otra franquicia y la puntuación manual no es posible",
        franquiaNaoEnviaManual: "Esta franquicia no está configurada para enviar Manual Dotz.",
        pontuacaoEnviada: "¡Puntuación Dotz enviada!",

        //../parent/sessaoCaixa/aberturaCaixa.ts
        abertCaixa: "¡Apertura de caja!",
        abertCaixaRegSucess: "Cajero registrado satisfactoriamente.",

        //../parent/sessaoCaixa/fechamentoCaixa.ts
        contagem: "Cuenta",
        validacao: "Validación",
        conclusa: "Conclusión",
        contagCaixa: "Cuenta en el Cajero",
        diferencs: "Diferencias",
        clientForneced: "Cliente / Proveedor",
        dtHra: "Fecha / Hora",
        gerarReforcDif: "Generar refuerzo de diferencia",
        gerarSangrDif: "Generar sangría de diferencia",
        devidDifFechCaixa: "debido a la diferencia en el cierre de caja",
        ajustDif: "Ajustar diferencia",
        justif: "Justificar",
        vlrCorretoJustif: "Valor correcto y / o justificado",
        justifiq: "¡Justifica!",
        justifDifVlr: "Justificar diferencias de valor",
        justifDifEntrCalcCaixa: "Justificar la diferencia entre el cálculo del sistema y la suma del efectivo",
        justificativDifVlres: "Justificación de la diferencia de valores",
        justifMtLonga: "Justificación demasiado larga, use un máximo de 1000 caracteres.",
        justifMtCurta: "Justificación demasiado corta, use al menos 15 caracteres para la descripción.",
        fechament: "¡Cierre!",
        fechamentSalvoSucess: "El cierre se guardó correctamente.",
        caixJaFechad: "¡La caja ya está cerrada!",

        //../parent/sessaoCaixa/list.ts
        sucessoReabertura: "Caso reabierto con éxito",

        //../parent/sessaoCaixa/reforco.ts
        reforcSaldoPDV: "Refuerzo del equilibrio para POS:",
        vlrTotalCalcNaoMenorSaldo: "El monto total calculado no puede ser menor o igual al saldo actual.",

        //../parent/sessaoCaixa/sangria.ts
        sangriaPDV: "Sangria de POS",
        sldCalcNaoMenorZero: "El saldo calculado no puede ser menor que cero.",
        sangriaNaoMaiorSaldPDV: "La sangría no puede ser mayor que el saldo actual del POS.",

        //../parent/sessaoCaixa/listMovimentacoesPendente.ts
        pagamentoReprocessado: "Los pagos solicitados se han reprocesado correctamente.",

        //../parent/sngpc/list.ts
        visuProds: "Ver productos",
        enviarXML: "Enviar XML",
        remover: "Eliminar",
        desejExcluirr: "¿Desea excluir?",
        regEnviadoSucess: "El registro se envió correctamente.",

        //../parent/topmenu/topmenu.ts
        conexaoInternet: "Concepción de Internet",
        conexaoRestSucess: "¡Su conexión se ha restaurado correctamente!",
        aparentInternetInterr: "Aparentemente, su conexión a Internet se ha interrumpido",
        conexaoInternetPerd: "Conexión perdida a Internet",

        //../parent/venda/cancelamento.ts
        valorTotal: "Valor total",
        quantidadeADevolver: "Cantidad a devolver",
        quantidadeDevolvida: "Cantidad devuelta",

        //../parent/venda/edit.ts
        criandOrca: "Creando presupuesto ...",
        orcaNovo: "Nuevo presupuesto **",
        copiand: "Copiando ...",
        concl: "Completado",
        faturad: "facturado",
        cancelad: "Cancelado",
        novo: "Nuevo **",
        orcaCod: "Código de presupuesto:",

        //../parent/venda/editSingle.ts
        revisPreenchCampos: "Revisión de relleno de campo",
        acresc: "Adición",
        continu: "Continuo",
        fiscl: "Fiscal",
        dtReceb: "Dt. Recepción ",
        formPgto: "Form Pgto",
        sttsNota: "Estado de la nota",
        tipoNota: "Tipo de nota",
        model: "Modelo",
        visuPDF: "Ver PDF",
        cpfAtingPtsPremio: "¡El CPF alcanzó la puntuación necesaria para ganar un premio!",
        naoReceber: "No recibir",
        abrirPDF: "Abrir PDF",
        abrirPDFSimplificado: "Abrir PDF simplificado",
        detalhNota: "Detalles de la nota",
        aoConclNaoPossivEmit: "Al finalizar, no será posible emitir una Factura Electrónica",
        desejSistemEfetConcl: "¿Quiere que el sistema complete la venta?",
        enviandNF: "Enviando facturas ...",
        enviado: "¡Enviado!",
        foiEnviadSucess: "se envió correctamente.",
        vendCod: "- Código de venta:",
        nfReqSttsEnviad: "Nota Fiscal y solicitud de estado de factura se han enviado.",
        copia: "Copiar",
        doOrca: "Presupuesto",
        daVend: "da Venda",
        emailEnviSucess: "Correo electrónico enviado correctamente.",
        obgSelecEndeEntr: "Obligatorio para seleccionar la dirección de envío",
        editDetalhNF: "Editar detalles de NF-e",
        desejEnviNotifPronto: "¿Desea enviar una notificación de que el presupuesto está listo?",
        orcaConcl: "Presupuesto completado",
        orcaReqFoiConclAcessLink:
            "Se ha completado el presupuesto solicitado. Acceda al enlace de arriba para aprobarlo. ",
        desejEnviarVendaPFracion: "¿Quieres enviar la venta a Fraccionamiento?",
        acaoIraFinalizVenda: "Esta acción finalizará la venta, ¿quieres continuar?",
        concluido: "¡Listo!",
        vendaFoiFinaliz: "La venta ha finalizado.",
        msgCpfNaoInformado: "CPF no informado en el registro del cliente.",
        vendaReplicadaComManipulacao: "Venta replicada con manipulaciones",
        vendaReplicadaComManipulacaoConsolidar:
            "En esta venta hubo una replicación de manipulaciones, por lo que es necesario ingresar cada manipulación y hacer la consolidación para que el sistema actualice correctamente la información.",
        vendaReplicadaComManipulacaoSemConsolidar: "Consolide todos los elementos de manipulación para poder continuar",
        observacao: "observación",
        altVendaFaturada:
            "Los cambios en las ventas facturadas solo se pueden realizar con la aprobación de un supervisor",
        temCertezaSeDeseja: "¿Está seguro de que desea eliminar el artículo de la venta?",
        LinkPagamento: "Enlace de pago",

        //../parent/venda/enderecoEntrega.ts
        criandNovoEndeEntrg: "Creando nueva dirección de envío",
        editEndeEntreg: "Editando dirección de envío",
        jaExistEnderMarcPdr:
            "Ya existe una dirección marcada como la dirección predeterminada. ¿Desea mantener esta dirección como la nueva dirección predeterminada? ",
        naoInfoLoteProd: "¡No se informó un lote del producto! ¿Desea continuar?",
        fracionaEstq: "Franquicia de inventario",

        //../parent/venda/itemwrapper.ts
        estoqueNaoInformado: "Stock no informado, seleccione stock para continuar!",

        //../parent/venda/linkPagamento.ts
        linkCopiado: "¡Enlace copiado al portapapeles!",
        linkPagamentoAtualizado: "Enlace de pago actualizado!",
        desejaGerarNovoLink:
            "¿Quieres generar un nuevo enlace? Al hacer esto, el enlace actual se desactivará, por lo que será imposible realizar el pago a través de él, ¡sólo desde el nuevo!",

        //../parent/venda/list.ts
        repEstaVenda: "Repetir esta venta",
        selecioneUmaVenda: "Seleccione al menos una venta",

        //../parent/venda/listExpedicao.ts
        detalhExpedic: "Detalles del envío",
        finalizExpedic: "Finalizar expedición",
        gridCodigo: "Código",
        gridCliente: "Cliente",

        //../parent/venda/listOrcamento.ts
        orcaSelecAprovSucess: "Presupuestos seleccionados aprobados con éxito.",
        visuAltOrca: "Ver / Cambiar presupuesto",
        adiarValOrca: "Posponer la validez de este presupuesto",
        arqRejOrca: "Archivar / Rechazar presupuesto",
        arqSucesso: "Archivado con éxito.",

        //../parent/venda/uploadReceita.ts
        formatInvalid:
            "Su archivo tiene un formato no válido. Las extensiones aceptadas son: (.jpg), (.png), (.pdf), (.doc), (.txt) ",

        //../parent/venda/vendaEstoque.ts
        prodPossuiContrEstqAtiv: "El producto tiene control de inventario activo y no se incluirá por falta de lotes.",
        naoQtdDispNoLote: "No hay cantidad disponible en el lote de",

        //../parent/venda/financeiro/financeiro.ts
        addParcela: "Agregar Porción",
        parcelamento: "Parcelamento",
        preFaturamento: "Prefacturación",

        //../parent/printModel/list.ts
        gridColumnDescricao: "Descripción",
        gridColumnPadrao: "Estándar",

        //../parent/crud/visitador/edit.ts
        prescritorJaAssociado: "Este prescriptor ya tiene un visitador asociado.",
        prescritorJaInserido: "Este prescriptor ya ha sido insertado.",
        prescritorNaoSelecionadoo: "No se ha seleccionado ningún prescriptor.",

        //../parent/dinamizacao/view.ts
        necessarioSelecionarLote: "Necesidad de seleccionar un lote del producto",
        necessarioSelecionarKit: "Necesidad de seleccionar un kit de embalaje",
        loteSucesso: "Lote generado con éxito",

        //../parent/crud/controlereclamacao/list.ts
        responsavel: "Responsable",
        reclamacao: "Reclamación",
        acao: "Acción",
        setor: "Sector",
        justificativa: "Justificación",

        //..parent/estoqueMinimo
        sucessoEstMin: "Stock mínimo y máximo actualizado con éxito!",
    },

    /////////////////////////////////////////////////////////////////////////

    limpar: "Limpio",
    cancelar: "Cancelar",
    salvar: "Guardar",
    cadastrados: "Registrado",
    Components: {
        parent: {
            atendimento: {
                view_vue_html: {
                    selecContatoComecar:
                        "Seleccione uno de los contactos para comenzar o<br>inicie una conversación con un nuevo contacto",
                    nenhumAtendEncontrado: "No se encontró ningún servicio...",
                    meus: "Mis",
                    meusTitle: "Servicios vinculados a su usuario",
                    emAberto: "En Abierto",
                    emAbertoTitle: "Servicios sin asistente asignado",
                    encerrados: "Cerrado",
                    encerradosTitle: "Servicios cerrados",
                    emAtendimento: "En Atención",
                    emAtendimentoTitle: "En asistencia asignada a otros asistentes",
                    pegarAtendimento: "Asignar servicio",
                    encerrarAtendimento: "Finalizar servicio",
                    anexar: "adjuntar",
                    dadosContato: "Datos de Contacto",
                    historicoAtend: "Historial de servicio",
                },
                historicoAtendimento: {
                    semAtendimento: "¡Este contacto no tiene servicio cerrado!",
                },
                mensagemOptions: {
                    responder: "Responder",
                },
            },

            atualizacaoValores: {
                percentualAtualizacao: "Actualizar porcentaje (%)",
                atualizar: "Actualizar",
                formaFarmaceutica: {
                    atualizacaoValoresProdutos: "Actualización del valor del formas farmacéuticas",
                    encontrados: "Formas Farmacéuticas encontrados",
                    percentualValorMinimo: "Porcentaje Valor Mínimo (%)",
                    percentualValorAdicional: "Porcentaje Valor Adicional (%)",
                },
                produto: {
                    atualizacaoValoresProdutos: "Actualización del valor del producto",
                    encontrados: "Productos encontrados",
                    nenhumSelecionado: "No se han seleccionado registros.",
                },
            },

            duplicacoes: {
                list_vue_html: {
                    clientesDuplicados: "Clientes Duplicados",
                    prescritoresDuplicados: "Prescriptores Duplicados",
                    registros: "Registros Duplicados",
                    resolver: "Resolver",
                    unificar: "Unificar",
                    prescritorOrigem: "Origen prescriptor",
                    prescritorDestino: "Prescriptor de destino",
                    clienteOrigem: "Cliente originario",
                    clienteDestino: "Cliente objetivo",
                },

                edit_vue_html: {
                    edicaoCliente: "Edición Cliente",
                    edicaoPrescritor: "Edición para prescriptor",
                    manter: "Mantener",
                },
            },

            crud: {
                incluir: "Incluir",
                alterar: "Cambia",
                descricao: "Descrição",
                ativo: "Activo",
                salvar: "Guardar",
                limpar: "Limpio",
                cancelar: "Cancelar",
                voltar: "Atras",
                fechar: "Fechar",
                imprimir: "Imprimir",
                replicar: "Replicar",
                copiar: "Copiar",
                excluir: "Eliminar",
                download: "Descargar",
                gerarArquivo: "Generar Archivo",
                adicionarTodos: "Agregar todo",

                filtrar: "Filtro",
                filtroAvancado: "Filtro Avanzado",

                listInputs: {
                    acao: "Acción",
                    adicionar: "Agregar",
                    remover: "Eliminar",
                },

                alterarsenha: {
                    alterarsenha_vue_html: {
                        altSenha: "Cambiar contraseña",
                        novaSenha: "Nueva contraseña",
                        confSenha: "Confirmar Contraseña",
                    },
                },

                balancoCompleto: {
                    view_vue_html: {
                        balancoCompletoGeracao: "Balance completo",
                        ano: "Año",
                        tipoPeriodo: "Tipo de Periodo",
                    },
                },

                mapaSiproquim: {
                    view_vue_html: {
                        mapaSiproquim: "Mapa SIPROQUIM",
                    },
                },

                banco: {
                    edit_vue_html: {
                        bancoEdit: "Bank - Edition",
                        nome: "Nombre",
                        codigo: "Código",
                        utilizCodFEBRABAN: "Use el código proporcionado por FEBRABAN",
                        ativo: "Activo",
                    },

                    list_vue_html: {
                        bancos: "Bancos",
                        cadastrados: "Registrado",
                    },
                },

                controleReclamacao: {
                    edit_vue_html: {
                        controleReclamacaoEdit: "Control de Reclamación - Edición",
                        codVenda: "Código de Venta",
                        cliente: "Cliente",
                        vendedor: "Vendedor",
                        responsavel: "Responsable",
                        dataReclamacao: "Fecha de reclamación",
                        reclamacao: "Reclamación",
                        acao: "Acción",
                        status: "Status",
                        categoriaReclamacao: "Categoría de queja",
                        justificativaReclamacao: "Justificación de la queja",
                    },

                    categoriaControleReclamacao: {
                        edit_vue_html: {
                            categoriaControleReclamacaoEdit: "Categoría de control de quejas: Edición",
                            descricao: "Descripción",
                        },

                        list_vue_html: {
                            categoriaControleReclamacao: "Categorías",
                            categoriasList: "Categorías",
                            categorias: "Categorías",
                            cadastradas: "Registradas",
                        },
                    },

                    justificativaControleReclamacao: {
                        edit_vue_html: {
                            justificativaControleReclamacaoEdit: "Justificación de la queja - Edición",
                            descricao: "Descripción",
                        },

                        list_vue_html: {
                            justificativaControleReclamacao: "Justificaciones",
                            justificativasList: "Justificaciones",
                            justificativas: "Justificaciones",
                            cadastradas: "Registrado",
                        },
                    },

                    list_vue_html: {
                        controleReclamacao: "Control de Reclamación",
                        reclamacoes: "Reclamaciones",
                        cadastradas: "Registradas",
                    },
                },

                bandeira: {
                    edit_vue_html: {
                        bandeiraEdit: "Bandeira - Edición",
                        descricao: "Descripción",
                        ativo: "Activo",
                    },

                    list_vue_html: {
                        banderas: "Banderas",
                        cadastradas: "registrado",
                    },
                },

                beneficio: {
                    edit_vue_html: {
                        beneficiosEdit: "Beneficios - Edición",
                        descricao: "Descripción",
                        descontos: "Descuentos",
                        inicioFaixa: "Inicio Rango",
                        fimFaixa: "Fin Rango",
                        valor: "Valor",
                        comissao: "Comisión",
                    },

                    list_vue_html: {
                        beneficio: "Beneficio",
                        beneficios: "Beneficios",
                        cadastrados: "Registrados",
                    },
                },

                campanha: {
                    list_vue_html: {
                        campanhas: "Campañas",
                        cadastradas: "Registrado",
                    },

                    edit_vue_html: {
                        campanhaEdit: "Campaign - Edition",
                        descricao: "Descripción",
                        dataInicio: "Fecha de inicio",
                        dataFim: "Fecha de finalización",
                        faixas: "Pistas",
                        recompensas: "Recompensas",
                        grupoProduto: "Grupo de productos",
                        valorInicial: "Valor Inicial",
                        valorPorPonto: "Valor por Punto",
                        pontos: "Puntos",
                        pontuar: "Puntuar",
                        valorInicialFaixaGrid: "Valor Inicial",
                        valorFinalFaixaGrid: "Valor Final",
                        faixaSobreposta: "El comienzo de este rango no puede anular el valor final de otro rango.",
                        faixasCampanhaValida:
                            "Para que la campaña sea válida es necesario registrar al menos un track.",
                        brinde: "Usar recompensa",
                        multiplicador: "Multiplicador",
                        valorUtilizadoPntsRecebidos: "Valor en reales por punto recibido",
                        padrao: "Estándar",
                    },
                },

                capacidadeProducao: {
                    edit_vue_html: {
                        capacidadeProducaoEdit: "Capacidad de producción - Edición",
                        descricao: "Descripción",
                        horario: "Calendario",
                        horarioFinalDeSemana: "Calen. Fin de Sem.",
                        capacidadeSegunda: "Cap. Lunes",
                        capacidadeTerca: "Cap. Martes",
                        capacidadeQuarta: "Cap. Miércoles",
                        capacidadeQuinta: "Cap. Jueves",
                        capacidadeSexta: "Cap. Viernes",
                        capacidadeSabado: "Cap. Sábado",
                        capacidadeDomingo: "Cap. Domingo",
                    },
                },

                capsula: {
                    edit_vue_html: {
                        capsulaEdit: "Cápsula - Edición",
                        descricao: "Descripción",
                        nro: "Número",
                        volumeInternoml: "volumen interno (ml)",
                        volumeTotalml: "Volumen total (ml)",
                        pesog: "Peso (g)",
                        tipoCapsAtivo: "Tipo de cápsula activa",
                        capsulas: "Cápsulas",
                        capsulasGastrorresistente: "Cápsulas Gastroresistente",
                    },

                    list_vue_html: {
                        capsulas: "Cápsulas",
                        cadastradas: "Registrada",
                    },
                },

                cest: {
                    edit_vue_html: {
                        cestEdit: "CEST - Edición",
                        descricao: "Descripción",
                        codigo: "Código",
                    },

                    list_vue_html: {
                        cests: "CESTs",
                        ncms: "NCM",
                        cadastrados: "Registrado",
                    },
                },

                farmacopeia: {
                    edit_vue_html: {
                        farmacopeiaEdit: "Farmacopea - Edición",
                        codigo: "Código",
                        descricao: "Descripción",
                    },

                    list_vue_html: {
                        farmacopeia: "Farmacopea",
                        farmacopeias: "Farmacopeas",
                        cadastradas: "Registrado",
                    },
                },

                cliente: {
                    edit_vue_html: {
                        clienteEdit: "Cliente - Edition",
                        dadosGerais: "Datos generales",
                        tipo: "Tipo",
                        cpf: "CPF",
                        rg: "RG",
                        cnpj: "CNPJ",
                        ie: "Insc. Estado",
                        contribuinteEstadual: "Contribuyente del Estado",
                        documentoEstrangeiro: "Documento extranjero",
                        exterior: "Extranjero",
                        clientIsentoEmBranco: "Para clientes exentos déjelo en blanco",
                        nombre: "nombre",
                        MASCULINO: "Nombre de fantasía",
                        nomeRotulo: "Nombre de etiqueta",
                        nomeQueRotulo: "Nombre que se utilizará en la etiqueta para identificar al cliente",
                        razaoSocial: "Razón Social",
                        im: "Insc. Municipal",
                        dataNasc: "Fecha de nacimiento",
                        genero: "Género",
                        orgaoExp: "Agencia emisora",
                        ufExp: "UF Expedidor",
                        obs: "Observación",
                        localizacao: "Ubicación",
                        cep: "código postal",
                        estado: "Estado",
                        cidade: "Ciudad",
                        endereco: "Dirección",
                        nro: "Número",
                        bairro: "Bairro",
                        complemento: "Complemento",
                        enderecoEntrega: "Dirección de entrega",
                        usarMesmoEnde: "Usar la misma dirección",
                        contato: "Contacto",
                        telefone: "Teléfono",
                        celular: "Celda",
                        pacientes: "Pacientes",
                        cpfJACadastradoOutrocliente: "Cpf ya se registró para otro cliente!",
                        ativo: "Activo",
                        emailsParaNotaFiscal: "Correo electrónico(s) para factura",
                        email: "Correo electrónico",
                    },

                    enderecoEntregaEdit_vue_html: {
                        cep: "código postal",
                        estado: "Estado",
                        cidade: "Ciudad",
                        endereco: "Dirección",
                        nro: "Número",
                        bairro: "Bairro",
                        complemento: "Complemento",
                        endecoPdr: "Dirección predeterminada",
                    },

                    list_vue_html: {
                        clientes: "Clientes",
                        cadastrados: "Registrado",
                        visuCliente: "Ver cliente",
                    },

                    ver_vue_html: {
                        dadosGerais: "Datos generales",
                        tipoPessoa: "Tipo de persona:",
                        cpf: "CPF:",
                        rg: "RG:",
                        nome: "Nombre: ",
                        dtNasc: "Fecha de nacimiento:",
                        genero: "Género:",
                        orgaoExp: "Organismo emisor:",
                        ufExp: "Despachador de UF:",
                        obs: "Nota:",
                        localiz: "Ubicación",
                        cep: "código postal",
                        estado: "estado:",
                        cidade: "Ciudad:",
                        endereco: "Dirección: ",
                        nro: "Número:",
                        bairro: "Barrio:",
                        complemt: "Complemento:",
                        contato: "Contacto",
                        tel: "Teléfono:",
                        cel: "Móvil:",
                        pacientes: "Pacientes",
                        paciente: "Paciente:",
                        nomeEspec: "Nombre / Especie:",
                        documentoEstrangeiro: "Documento extranjero:",
                    },
                },

                clinicas: {
                    list_vue_html: {
                        titulo: "Clínicas",
                        cadastradas: "Registradas",
                    },

                    edit_vue_html: {
                        configFranquia: "Registro de clínicas asociadas",
                        prescritorOutraClinica: "Este prescriptor forma parte de otra clínica",
                        prescritorJaInserido: "Este prescriptor ya ha sido insertado",
                        pontuarDotz: "Pontuar Dotz",
                    },
                },

                coleta: {
                    list_vue_html: {
                        coletas: "Colecciones",
                        cadastradas: "Registradas",
                    },

                    edit_vue_html: {
                        coletaEdit: "Colección - Edit",
                        data: "Fecha de recogida",
                        descricao: "Descripción",
                    },
                },

                condicao: {
                    edit_vue_html: {
                        descricao: "Descripción",
                    },
                    list_vue_html: {
                        condicoes: "Condiciones",
                    },
                },

                configuracaofranquia: {
                    edit_vue_html: {
                        configFranquia: "Configuración de franquicia",
                        franquia: "Franquicia",
                        integradorFranquia: "Configuración de integración - Editar",
                        api_Key: "API KEY",
                    },
                },

                configuracaousuario: {
                    edit_vue_html: {
                        configUsuario: "Configuración de usuario",
                        usuario: "Usuario",
                    },
                },

                configuracaowhatsapp: {
                    edit_vue_html: {
                        configWhatsApp: "Configuración de WhatsApp",
                        iniciarConfiguracao: "Iniciar configuración",
                        atualizar: "Actualizar",
                        cliqueAqui: "Haga clic aquí",
                        verComoFazer: "para ver como hacerlo",
                        leiaQrCode:
                            "Usa tu teléfono celular para leer el QrCode a continuación y conectarte a WhatsApp.",
                        integracaoPermite:
                            "La integración con WhatsApp permite enviar mensajes automáticos a los clientes de forma segura y rápida.",
                        paraFazerAConfiguracao:
                            "Para configurar y activar la integración, tenga a la mano el celular de la tienda y haga clic en el botón de abajo para iniciar la configuración",
                        cliqueBotaoAbaixo:
                            "Después de leer el código en su teléfono celular, haga clic en el botón de abajo para continuar",
                        integracaoProntaUso: "La integración con WhatsApp está lista para usar",
                        desconectarIntegracao: "Desconectar integración",
                        definirWebhook: "Configurar Webhook",
                    },
                },

                contacorrente: {
                    edit_vue_html: {
                        contaCorrentEdit: "Cuenta corriente - Edición",
                        descricao: "Descripción",
                        interna: "interno",
                        ativo: "Activo",
                        banco: "Banco",
                        agencia: "Agencia",
                        conta: "Cuenta ",
                    },

                    list_vue_html: {
                        contasCorr: "Cuentas corrientes",
                        cadastradas: "registrado",
                    },
                },

                contagerencial: {
                    edit_vue_html: {
                        contaGerencialEdit: "Cuenta de gestión - Edición",
                        descricao: "Descripción",
                        classif: "Clasificación",
                        tipoMovimen: "Tipo de movimiento",
                        ativo: "Activo",
                    },

                    list_vue_html: {
                        contasGerenc: "Cuentas de administración",
                        cadastradas: "Registrado",
                    },
                },

                contagerencialclassificacao: {
                    edit_vue_html: {
                        classifCntGerencialEdit: "Clasificación de cuentas gerenciales - Edición",
                        descricao: "Descripción",
                    },

                    list_vue_html: {
                        classificCntGerencial: "Clasificaciones de cuentas gerenciales",
                        cadastradas: "registrado",
                    },
                },

                contapaga: {
                    list_vue_html: {
                        contasPagas: "Cuentas pagadas",
                        cadastradas: "Registrado",
                        categ: "Categoría:",
                        fornec: "Proveedor:",
                        venciDe: "Expiration From:",
                        ate: "Hasta:",
                        pagamentDe: "Pago desde:",
                    },
                },

                contapagar: {
                    edit_vue_html: {
                        contAPagarEdit: "Cuenta a pagar - Edición",
                        dadosConta: "Datos de la cuenta",
                        descricao: "Descripción",
                        recorrenc: "Recurrencias",
                        utilizParaLancFuturos:
                            "Utilice esta opción si esta cuenta se repetirá en los próximos meses, para realizar futuros lanzamientos",
                        frequenc: "Frecuencia",
                        fornecedor: "Proveedor",
                        contGerencial: "Cuenta gerencial",
                        dtVenciment: "Fecha de vencimiento",
                        vlrTotal: "Importe total",
                        saldo: "Balance",
                        cdBarras: "Código de barras",
                        obs: "Observación",
                        parcelar: "Dividir",
                        mtdPagament: "Método de pago",
                        parcela: "Porción",
                        vlrParcela: "Valor a plazos",
                        saldoParcela: "Balance a plazos",
                        pgmtRealizados: "Pagos realizados",
                        sldPagar: "SALDO A PAGAR",
                    },

                    list_vue_html: {
                        contasAPagrAberto: "Cuentas por pagar - Abiertas",
                        contasAPagr: "Cuentas a pagar",
                        cadastradas: "Registrado",
                        tudo: "Todo",
                        vencidos: "Caducados",
                        fornecedor: "Proveedor:",
                        vencDe: "Vencimiento de:",
                        ate: "Hasta:",
                    },

                    pagamentoconta_vue_html: {
                        pgto: "Pago",
                        dtPgto: "Fecha de pago",
                        valor: "Valor",
                        contaCorrente: "Cuenta corriente",
                        formaPagamento: "Método de pago",
                        juros: "Interés",
                        desconto: "Descuento",
                        totalPago: "Total pagado",
                        obs: "Observación",
                        incluirPgto: "Incluir pago",
                    },
                },

                contapagarmultiplas: {
                    edit_vue_html: {
                        cntPagarEdit: "Cuentas por pagar - Edición",
                        cntPagar: "Cuentas a pagar",
                        saldoPagar: "BALANCE TO PAY",
                        cifrao: "R $",
                        efetPgtos: "Realizar pagos",
                        dtPgto: "Fecha de pago",
                        cntCorrent: "Cuenta corriente",
                        formaPgto: "Método de pago",
                        juros: "Interés",
                        descontos: "Descuentos",
                        vlrPago: "Importe pagado",
                    },
                },

                contareceber: {
                    edit_vue_html: {
                        cntReceberEdit: "Conta a Receber - Edição",
                        naoPodeAlterarMovimentac:
                            "Conta Receber oriunda de venda! Não é possível alterar movimentações por este cadastro.",
                        dadosConta: "Dados da Conta",
                        descricao: "Descrição",
                        recorrencias: "Recorrências",
                        utilizCasoRepita:
                            "Utilize esta opção caso esta conta for se repetir nos próximos meses, para efetuarmos os lançamentos futuros",
                        freq: "Frequência",
                        cntGerencial: "Conta Gerencial",
                        cliente: "Cliente",
                        operadoraCartoes: "Operadora de Cartões",
                        autoriz: "Autorização",
                        dtVenc: "Data Vencimento",
                        vlrTotal: "Valor Total",
                        sldTotal: "Saldo Total",
                        codBarras: "Código de Barras",
                        obs: "Observação",
                        parcelar: "Parcelar",
                        mtdParcelamento: "Método de Parcelamento",
                        parcela: "Parcela",
                        vlrParcela: "Valor Parcela",
                        sldParcela: "Saldo da Parcela",
                        liquidar: "Liquidar",
                        recebRealizados: "Recebimentos Realizados",
                        sldReceber: "SALDO A RECEBER",
                    },

                    list_vue_html: {
                        cntReceberAberto: "Cuentas por cobrar - Abiertas",
                        cntReceberParcelado: "Cuentas por cobrar - Cuotas",
                        cntReceber: "Cuentas por cobrar",
                        cadastradas: "Registrado",
                        cntGerencial: "Cuenta de administración:",
                        vncDe: "Caducidad de:",
                        ate: "Hasta:",
                        formaPgto: "Forma de pago: ",
                        cntReceberVenda: "Cuenta Recibir Ventas",
                    },

                    listOperadoras_vue_html: {
                        cntReceberOpCartAberto: "Cuentas por cobrar de operadores de tarjetas - Abiertas",
                        cntReceber: "Cuentas por cobrar",
                        cadastradas: "Registrado",
                        cntReceberAberto: "Cuentas por cobrar - Abiertas",
                        cntGerencial: "Cuenta de administración:",
                        vncDe: "Caducidad de:",
                        ate: "Hasta:",
                    },

                    recebimentoconta_vue_html: {
                        recebimento: "Recibo",
                        dtRecebimento: "Fecha de recepción",
                        cntCorrente: "Cuenta corriente",
                        especie: "Especie",
                        bandeira: "Bandera",
                        frmRecebimento: "Formulario de recibo",
                        mtdParcelamento: "Método de pago",
                        autorizNSUCartao: "Autorización de tarjeta / NSU",
                        vlrAbater: "Monto a Pagar",
                        tarifa: "Tarifa",
                        juros: "Interés",
                        desconto: "Descuento",
                        totalRecebido: "Total recibido",
                        obs: "Observación",
                        inclRecebimento: "Incluir recibo",
                        attRecebimento: "Actualizar recibo",
                    },
                },

                contarecebermultiplas: {
                    edit_vue_html: {
                        cntReceberEdit: "Cuentas por cobrar - Edición",
                        cntReceber: "Cuentas por cobrar",
                        sldReceber: "SALDO A RECIBIR",
                        cifrao: "R $",
                        efetRecebimentos: "Hacer recibos",
                        dtRecebimento: "Fecha de recepción",
                        cntCorrente: "Cuenta corriente",
                        especie: "Especie",
                        bandeira: "Bandera",
                        frmRecebimento: "Formulario de recibo",
                        mtdParcelamento: "Método de pago",
                        autorNSUCartao: "Autor / NSU Cartão",
                        juros: "Interés",
                        descontos: "Descuentos",
                        vlrRecebido: "Cantidad recibida",
                    },
                },

                contarecebida: {
                    list_vue_html: {
                        cntReceberRecebidas: "Cuentas por cobrar - Recibidas",
                        cntRecebidas: "Cuentas recibidas",
                        cadastradas: "Registrado",
                        conta: "Cuenta:",
                        vncDe: "Caducidad de:",
                        ate: "Hasta:",
                    },

                    listOperadoras_vue_html: {
                        cntReceberRecebidas: "Cuentas por cobrar - Recibidas",
                        cntRecebidas: "Cuentas recibidas",
                        cadastradas: "Registrado",
                        conta: "Cuenta:",
                        vncDe: "Caducidad de:",
                        ate: "Hasta:",
                    },
                },

                configuracaoFranquiaGatewayPagamento: {
                    edit_vue_html: {
                        gatewayPagamentoEdit: "Pasarela de pago - Editar",
                        gatewayPagamento: "Pasarela de pago",
                        toke: "Token",
                        descFaturaCartao: "Descripción de factura de la tarjeta",
                        cartaoCreditoMaximoParcelasSemJuros: "Número máximo de cuotas SIN INTERÉS a crédito",
                        cartaoCreditoMaximoParcelasComJuros: "Número máximo de cuotas CON INTERÉS a crédito",
                        expLinkpagamentoHoras: "Expiración del enlace de pago (horas)",
                        pixAtivo: "Pix activo",
                        boletoAtivo: "Boleto activo",
                        cartCredAtivo: "Tarjeta de crédito activa",
                        cartDebAtivo: "Tarjeta de débito activa",
                        valorMinimoParcela: "Valor mínimo de la cuota",
                    },
                },

                cor: {
                    edit_vue_html: {
                        corEdit: "Colores - Edición",
                        descricao: "Descripción",
                    },

                    list_vue_html: {
                        cores: "Colores",
                        cadastrados: "Registrado",
                    },
                },

                dadosAdicionaisFichaTecnica: {
                    edit_vue_html: {
                        dadosAdicFTEdit: "Hoja de Datos Adicional - Edición",
                        descricao: "Descripción",
                        padrao: "Estándar",
                    },

                    list_vue_html: {
                        dadosAdicFT: "Hoja de Datos Adicional",
                        cadastrados: "Registrado",
                    },
                },

                declaracaoConteudo: {
                    view_vue_html: {
                        docDeclaraConteudo: "Documento de declaración de contenido",
                    },
                },

                devolucaoCompra: {
                    edit_vue_html: {
                        devolucaoCompraEdit: "Devolución de compra - Edición",
                        nota: "Nota",
                        numero: "Número",
                        serie: "Serie",
                        dataEmissao: "Fecha de emisión",
                        dataInclusao: "Fecha de inclusión",
                        fornecedor: "Proveedor",
                        transportadora: "Transportista",
                        itens: "Artículos a devolver",
                        emitir: "Emitir nota devolución",
                        valorTotal: "Valor total a devolver",
                        cabecalhoProduto: "Producto",
                        cabecalhoLote: "Lote",
                        cabecalhoQuantidade: "Cantidad",
                        cabecalhoQuantidadeDevolver: "Cantidad a devolver",
                        cabecalhoUnidadeDevolver: "Un.Medida a devolver",
                        cabecalhoQuantidadeEstoque: "Cantidad de inventario de descuento",
                        cabecalhoUnidadeEstoque: "Unidad Medida Existencias",
                        cabecalhoValorDevolver: "Valor a devolver",
                        cabecalhoValorUnitario: "Valor unitario",
                        cabecalhoValorTotal: "Valor total",
                    },

                    consulta_vue_html: {
                        devolucaoCompra: "Devolución de compra - Consulta",
                        numero: "Número",
                        serie: "Serie",
                        dataEmissao: "Fecha de emisión",
                        chaveAcesso: "Clave de acceso",
                        consultar: "Consultar",
                        fornecedor: "Proveedor",
                        transportadora: "Transportista",
                        notaEntrada: "Nota de Entrada",
                        notaDevolucao: "Nota de Devolución",
                        movimentacaoEstoque: "Manejo de stock",
                    },

                    list_vue_html: {
                        devolucaoCompra: "Devoluciones de compra",
                        cadastrados: "registrado",
                    },
                },

                downloadXmls: {
                    edit_vue_html: {
                        downloadXmls: "Descargar XML de factura",
                        dataInicial: "Fecha de inicio",
                        dataFinal: "Fecha final",
                        tiposDocumento: "Tipo de documento",
                        tiposNotas: "Tipo de notas",
                        download: "Download",
                    },
                },

                email: {
                    edit_vue_html: {
                        emailEdit: "Correo electrónico - Edición",
                        host: "Host",
                        porta: "Porta",
                        email: "E-mail",
                        senha: "Contraseña",
                    },

                    list_vue_html: {
                        cadastrados: "registrado",
                    },
                },

                ensaio: {
                    edit_vue_html: {
                        ensaioEdit: "Ensayo - Edición",
                        descricao: "Descripción",
                        farmacopeia: "Farmacopea",
                        soludibilidade: "Solubilidad",
                    },

                    list_vue_html: {
                        ensaio: "Ensayo",
                        ensaios: "Ensayos",
                        cadastrados: "Registrado",
                    },
                },

                especialidade: {
                    edit_vue_html: {
                        especialidadeEdit: "Especialidad - Edición",
                        descricao: "Descripción",
                    },

                    list_vue_html: {
                        especialidades: "Especialidades",
                        cadastradas: "registrado",
                    },
                },

                especieanimal: {
                    edit_vue_html: {
                        espAnimalEdit: "Animal Species - Edition",
                        descricao: "Descripción",
                    },

                    list_vue_html: {
                        espAnimal: "Especies animales",
                        cadastradas: "registrado",
                    },
                },

                estoque: {
                    edit_vue_html: {
                        estqEdit: "Inventario - Edición",
                        descricao: "Descripción",
                        permiteVenda: "Permitir Venta",
                    },

                    list_vue_html: {
                        estoques: "Acciones",
                        cadastradas: "registrado",
                    },
                },

                estoqueMinimo: {
                    view_vue_html: {
                        mesesConsiderar: "Meses para considerar",
                        coberturaEstoque: "Cobertura de inventario en días.",
                        calcularEstoque: "Calcular stock",
                    },
                },

                etapaPCP: {
                    edit_vue_html: {
                        etapaPCPEdit: "Etapa PCP - Edición",
                        descricao: "Descripción",
                        ordem: "Orden",
                        ativo: "Activo",
                        obrigatorio: "Obligatorio",
                        tipo: "Tipo",
                    },

                    list_vue_html: {
                        etapasPCP: "Etapas PCP",
                        cadastrados: "Registrado",
                    },
                },

                etiquetas: {
                    list_vue_html: {
                        etiquetas: "Etiquetas",
                        cadastradas: "Registrado",
                        exibirSomenteVendas: "Mostrar solo ventas con notas",
                        imprimirEtiquetas: "Imprimir Etiquetas",
                        gerarEtiquetaLote: "Generar etiquetas en lote",
                    },
                },

                expedicao: {
                    listEntrega_vue_html: {
                        statusEntrega: "Estado de entrega",
                    },
                },

                formafarmaceutica: {
                    edit_vue_html: {
                        formFarmaceutEdit: "Formulario farmacéutico - Edición",
                        franquia: "Franquicia",
                        ativo: "Activo",
                        descricao: "Descripción",
                        utilzMolde: "Usar molde",
                        calcMoldeAdequado:
                            "Si se utiliza molde, y tiene moldes registrados en el registro de modelos, al realizar una manipulación por presupuesto, el sistema buscará el molde más adecuado para la cantidad de activo informado, si no utiliza molde se deberá informar manualmente el volumen de manipulación creación.",
                        desconsiDensid: "Ignorar la densidad",
                        desconsiDensidNoCalc:
                            "Ignorar la densidad al calcular el volumen total de activos que definen el QSP.",
                        descrRotulo: "Etiqueta de descripción",
                        tipCalcAtivo: "Tipo de cálculo de activos",
                        tipCalcPadrao:
                            "Tipo de cálculo estándar para cuando se informa al activo en el manejo de esta forma farmacéutica, si no se informa, el sistema utilizará el tipo de cálculo del registro del producto.",
                        conceMax: "Concentración máxima",
                        conceMaxFito: "Concentración Máxima Fitoterápica",
                        conceMaxDose: "Concentración máxima por dosis del activo principal.",
                        ncmSH: "NCM / SH",
                        codOitoDig:
                            "Código compuesto por 8 números, utilizado para clasificar artículos de acuerdo con la normativa del Mercosur",
                        valDias: "Validez (días)",
                        tipo: "Tipo",
                        calcEmbal: "Cálculo de embalaje",
                        uso: "Usar",
                        tipoUso: "Tipo de uso",
                        qntMin: "Cantidad mínima",
                        qntMax: "Cantidad máxima",
                        unMedVol: "Unidad de medida de volumen",
                        pop: "P.O.P (Procedimiento operativo estándar)",
                        descriDetalhProc:
                            "Descripción detallada de las operaciones necesarias para realizar el procedimiento",
                        exibirPrefixoDoseRotulo: "Mostrar prefijo de dosis en la etiqueta",
                        ocultarExcipienteRotulo: "Ocultar vehículo / excipiente en la etiqueta",
                        ocultarFlavorizanteRotuno: "Ocultar saborizante en la etiqueta",
                        vlrMin: "Valor mínimo",
                        vlrAdd: "Valor adicional",
                        ftrMultQtd: "Multiplicador. Cantidad",
                        ftrAddVlrForm:
                            "El factor que multiplicó la cantidad de la forma farmacéutica se agregará al valor adicional de la forma",
                        ftrCompensacao: "Factor de compensación",
                        ftrSomaMult:
                            "Factor que se suma al multiplicador siempre que sea mayor que cero y disminuya con la tasa de caída",
                        ftrMultDecaimento: "Multifactor. Decay ",
                        ftrCriaTaxDecaimento:
                            "El factor que multiplica la cantidad crea una tasa de caída y crea una curva de precios",
                        itemsAdd: "Artículos agregados",
                        atualizarValores:
                            "Desea actualizar los campos de valor? <BR/><BR/><BR/> Campos que se actualizarán:<BR/> Valor mínimo<BR/> Valor adicional<BR/> Factor de compensación<BR/> Factor multiplicador adicional<BR/> Decaimiento< BR/>",
                        exibirDescRotulo: "Mostrar descripción Etiqueta en etiqueta",
                        capsVegana: "Cápsula Vegana",
                        capsVeganaRevestida: "Cápsula Vegana Revestida",
                    },

                    item_vue_html: {
                        item: "Artículo",
                        subGrup: "SubGroup",
                        prod: "Producto",
                        tipoCalc: "Tipo de cálculo",
                        qnt: "Cantidad",
                        desmemb: "Desmembrado",
                    },

                    list_vue_html: {
                        frmsFarmaceu: "Formas farmacéuticas",
                        cadastradas: "registrado",
                    },
                },

                formapagamento: {
                    edit_vue_html: {
                        frmPagtoEdit: "Método de pago - Edición",
                        descricao: "Descripción",
                        especie: "Especie",
                        ativo: "Activo",
                    },

                    list_vue_html: {
                        frmsPagto: "Métodos de pago",
                        cadastradas: "registrado",
                    },
                },

                formarecebimento: {
                    edit_vue_html: {
                        frmRecebimentoEdit: "Formulario de recibo - Edición",
                        descricao: "Descripción",
                        ativo: "Activo",
                        especie: "Especie",
                        percenDesc: "Porcentaje de descuento",
                        percenJuros: "Porcentaje de interés",
                        operadCartoes: "Operador de tarjeta",
                        cntCorrOrigem: "Origen de la cuenta corriente",
                        cntCorrOrigemVlr: "Cuenta corriente del origen del monto transferido",
                        frmPgtoOrigem: "Origen de la forma de pago",
                        frmPgtoParaSaida:
                            "Método de pago utilizado para generar la salida. Nota: Su especie define el tipo de especie del recibo generado. ",
                        bandeirasSelec: "Banderas seleccionadas",
                        bandeira: "Bandera",
                        mtdPgtoAceitos: "Métodos de pago aceptados",
                        addAltMetodos: "Agregar / Cambiar métodos",
                    },

                    list_vue_html: {
                        frmsRecebimento: "Formas de recibir",
                        cadastradas: "registrado",
                    },

                    metodosXFormaEdit_vue_html: {
                        mtdPgto: "Método de pago",
                        percenDesc: "Porcentaje de descuento",
                        percenJuros: "Porcentaje de interés",
                        percenTarifa: "Tasa de porcentaje",
                    },
                },

                formulapadrao: {
                    edit_vue_html: {
                        frmlaPadraoEdit: "Fórmula estándar - Edición",
                        dadosGerais: "Datos Generales",
                        franquia: "Franquicia",
                        ativo: "Activo",
                        descricao: "Descripción",
                        prodFinal: "Producto final",
                        frmFarmaceu: "Forma farmacéutica",
                        valDias: "Validez (días)",
                        tipoFrmlPadrao: "Tipo de fórmula estándar",
                        capsula: "Cápsula",
                        qtdCapsulas: "Cantidad de cápsulas",
                        volume: "Volumen",
                        unMedida: "Unidad de medida",
                        obs: "Observación",
                        proceProd: "Procedimiento del producto",
                        posologia: "Posología",
                        descrRotulo: "Descripción Descripción",
                        desmemFrml: "Fórmula para desmembrar",
                        produzir: "Producir",
                        revisAjustar: "Revise / Adjust",
                        kitEmbalagem: "Juego de embalaje",
                        qtdPadraoPreVenda: "Cantidad estándar en la preventa",
                        valorTabelado: "Valor Presentado",
                        edicaoPreVenda: "Edición de preventa",
                    },

                    item_vue_html: {
                        tipoProd: "Tipo de producto",
                        prod: "Producto",
                        faseUso: "Fase de uso",
                        unMedida: "Unidad de medida",
                        ftrCorre: "Factor de corrección",
                        tipoCalc: "Tipo de cálculo",
                        volumeDose: "Vol. Dosis",
                        qtd: "Cantidad",
                        unMedidaManipu: "Unidad de medida de manipulación",
                        qtdTotal: "Cantidad total",
                    },

                    list_vue_html: {
                        frmlsPadr: "Fórmulas estándar",
                        frmls: "Fórmulas",
                        cadastradas: "Registrado",
                        ativos: "Activos",
                        desativados: "Desactivado",
                        ambos: "Ambos",
                        buscPorInsumo: "Buscar entrada",
                        tipoFrmlPadr: "Tipo de fórmula estándar",
                        todos: "Todos",
                        produzir: "Producir",
                        concluirManipulacoes: "Completo Seleccionado",
                        imprimirManipulacoes: "Imprimir seleccionado",
                        replAtualizTds: "Replicar / Actualizar todo",
                        calcVlres: "Calcular valores",
                        visuFrmPadr: "Ver fórmula estándar",
                    },

                    ver_vue_html: {
                        dadosGera: "Datos generales",
                        descricao: "Descripción:",
                        prodFinal: "Producto final:",
                        frmFarmaceu: "Forma farmacéutica:",
                        valDias: "Validez (días):",
                        tipoFrmlPadr: "Tipo de fórmula estándar:",
                        vol: "Volumen:",
                        unMedida: "Unidad de medida:",
                        procedimProducao: "Procedimiento de producción:",
                        obs: "Nota:",
                        itens: "Artículos",
                        prod: "Producto",
                        tipoProd: "Tipo de producto",
                        tipoCalc: "Tipo de cálculo",
                        qtde: "Cantidad",
                        qtdePesag: "Qty de pesaje",
                        umMedidaAbv: "Un. Medida ",
                        unManipul: "Un. Manipulación",
                        fase: "Fase",
                    },
                },

                dinamizacao: {
                    edit_vue_html: {
                        fieldsetReferencia: "Referencia del Producto",
                        fieldsetDinamizado: "Producto Optimizado",
                        fieldsetVeículo: "Vehículo",
                        quantidade: "Cantidad",
                        estoque: "Stock",
                        unidadeMedida: "Unidad de medida",
                        kitEmbalagem: "Juego de embalaje",
                        validade: "Validez (días)",
                        produto: "Producto",
                        lote: "Lote",
                        dinamizacao: "Dinamización",
                        metodo: "Método",
                        qtdNecessaria: "Cantidad Necesaria para la Dinamización",
                        gerarDinamizacao: "Generar Dinamización",
                        gerarLote: "Generar lote",
                    },
                },

                pontuacaoManualDotz: {
                    list_vue_html: {
                        pontuacaoManual: "Puntuación Manual",
                        pontuacoesManuais: "Puntuaciones Manuales",
                        gerarPontuacao: "Generar puntuaciones",
                        enviarPontuacao: "Enviar puntuaciones",
                    },
                },

                formulapadraomanipulacao: {
                    edit_vue_html: {
                        frmlPadrGerarManipu: "Fórmula estándar - Generar manipulación",
                        frmlPadr: "Fórmula estándar",
                        gerarManipulacao: "Generar manipulación",
                        gerarOrdemManipu: "Generar orden de manipulación",
                        sair: "Dejar",
                        manipu: "Manipulación",
                    },

                    item_vue_html: {
                        ocorrDiverg:
                            "Manipulación generada con éxito, pero se han producido divergencias, verifíquelas usando el botón de manipulación de vista junto a su código.",
                        manipuNaoGerada:
                            "No se puede crear manipulación, verifique el registro de la fórmula estándar para identificar el problema.",
                        codManipu: "Código de manipulación:",
                        visuManipu: "Ver manipulación",
                        volQtd: "Volumen / Cantidad:",
                        valDias: "Validez (días):",
                        itens: "Artículos:",
                        estq: "Stock",
                        qtd: "Cantidad",
                        totalProduzir: "Total Producir",
                        confEstqEQntd: "Verificar existencias y ajustar cantidades y lotes cuando sea necesario",
                        printOrdem: "Orden de impresión",
                        printRotulo: "Imprimir etiqueta",
                        naoPermiteQuantidadeDecimal:
                            "La fórmula con cápsula o elementos de empaque no permite cantidades decimales",
                        vlrSeraAtualizCustoProd:
                            "Si se selecciona la opción, se cambiará el valor del costo del producto final.",
                        atualizarCustosProduto: "Actualizar costo de productos",
                    },
                },

                formulapadraovalorproduto: {
                    list_vue_html: {
                        calcVlrsProdsFrmlPdr: "Cálculo de valores de producto de fórmula estándar",
                        frmlsRecalcs: "Fórmulas recalculadas",
                        recalcDeVlrProd: "Recálculo del valor del producto",
                        tdsRegisRecalcs: "Todos los registros recalculados",
                        apenasComDifVlr: "Solo con diferencias en los valores calculados",
                        apenasCustMaiorVlr: "Solo el costo calculado es mayor que el valor actual",
                        atualiz: "Actualizar",
                    },
                },

                fornecedor: {
                    edit_vue_html: {
                        fornecedorEdit: "Proveedor - Edición",
                        dadosGerais: "Datos generales",
                        tipo: "Tipo",
                        cpf: "CPF",
                        rg: "RG",
                        cnpj: "CNPJ",
                        ie: "Insc. Estado",
                        contribuinteEstadual: "Contribuyente del Estado",
                        documentoEstrangeiro: "Documento extranjero",
                        exterior: "Extranjero",
                        nomeFants: "Nombre de fantasía",
                        nome: "Nombre",
                        cntGerencialPdr: "Cuenta de gestión estándar",
                        razaoSocial: "Razón Social",
                        localiz: "Ubicación",
                        cep: "código postal",
                        estado: "Estado",
                        cidade: "Ciudad",
                        endereco: "Dirección",
                        nro: "Número",
                        bairro: "Bairro",
                        compl: "Complemento",
                        contato: "Contacto",
                        tel: "Teléfono",
                        cel: "Móvil",
                        fax: "Fax",
                        homepg: "Página de inicio",
                        telContato: "Contacto telefónico",
                    },

                    list_vue_html: {
                        proveedores: "Proveedores",
                        cadastrados: "registrado",
                    },
                },

                franquia: {
                    edit_vue_html: {
                        franquiaEdit: "Franquicia - Edition",
                        dadosGerais: "Datos generales",
                        mapa: "MAPA",
                        veterinarioMapa: "Veterinario MAPA",
                        veterinarioMapaCRMV: "Veterinario MAPA CRMV",
                        autorizacaoMapa: "Autorización MAPA",
                        autorizacaoAnvisa: "Autorización ANVISA",
                        nomeFantasia: "Nombre de fantasía",
                        razaoSocial: "Razón Social",
                        cnpj: "CNPJ",
                        ie: "Insc. Estado",
                        im: "Insc. Municipal",
                        nomeFarmaceu: "Nombre farmacéutico",
                        conselhoReg: "Consejo Regional",
                        nroCR: "número CR",
                        crf: "CRF",
                        crmv: "CRMV",
                        crm: "CRM",
                        areaAtuac: "Área de práctica",
                        cpfSNGPC: "CPF SNGPC",
                        autorSanitaria: "Autoridad Sanitaria",
                        licenca: "Licencia",
                        natOperacao: "Naturaleza de la operación",
                        optanteSN: "Optante pelo Simples Nacional",
                        incentivCult: "Incentivador cultural",
                        rede: "Red",
                        tipoFranquia: "Tipo de franquicia",
                        localiz: "Ubicación",
                        cep: "código postal",
                        estado: "Estado",
                        cidade: "Ciudad",
                        lograd: "Patio trasero",
                        complemento: "Complemento",
                        nro: "Número",
                        bairro: "Bairro",
                        contato: "Contacto",
                        tel: "Teléfono",
                        cel: "Móvil",
                        modulos: "Módulos",
                        atendimentoWpp: "Servicio al cliente (WhatsApp)",
                        chaveAPI: "API Key",
                        diasArmaz: "Días de almacenamiento",
                        excluirDados: "Eliminar Datos",
                        configuracoes: "Ajustes",
                        matriz: "Sede",
                        moduloApp: "Módulo de APP",
                        moduloCompras: "Módulo de Compras",
                        moduloWhatsApp: "Módulo de Atendimentos",
                        moduloAutomatizacaoContinuos: "Módulo de Automatización de Continuos",
                        whatsapp: "Whatsapp",
                        complementoWhatsapp: "Complemento de Presupuesto de Whatsapp",
                        validadeLicensaSanitaria: "Validación de la Licencia Sanitaria",
                    },

                    list_vue_html: {
                        franquias: "Franquicias",
                        cadastradas: "registrado",
                    },
                },

                grupo: {
                    edit_vue_html: {
                        grupoEdit: "Grupo - Edition",
                        descr: "Descripción",
                        ativo: "Activo",
                        acoes: "Acciones",
                        users: "Usuarios",
                    },

                    list_vue_html: {
                        grupos: "Grupos",
                        cadastrados: "registrado",
                        sem_permissao_excluir_grupo: "No tienes permiso para eliminar este grupo!",
                    },
                },

                grupoproduto: {
                    edit_vue_html: {
                        grupoProdEdit: "Grupo de productos - Edición",
                        descr: "Descripción",
                        tipoProd: "Tipo de producto",
                        rotuloPadrao: "Etiqueta estándar",
                    },

                    list_vue_html: {
                        grupoProd: "Grupos de productos",
                        cadastrados: "registrado",
                    },
                },

                historicoenviosdotz: {
                    list_vue_html: {
                        title: "Registros de Envíos Dotz",
                        enviados: "Enviados",
                        titulofiltroAvancado: "Filtro avanzado",
                        filtroDataInicial: "Fecha Inicial",
                        filtroDataFinal: "Fecha Final",
                        filtroPrescritor: "Prescritor",
                        filtroStatusEnvio: "Estado del Envío",
                        btnFiltrar: "Filtrar",
                    },
                },

                inutilizacaoNotaFiscal: {
                    edit_vue_html: {
                        inutilizacaoNotaFiscalEdit: "Desactivación de facturas - Edição",
                        ano: "Año",
                        modelo: "Modelo",
                        numeroInicial: "Número Inicial",
                        numeroFinal: "Número Final",
                        serie: "Serie",
                        justificativa: "Justificación",
                        rejeicao: "Rechazo",
                        inutilizar: "Desactivar",
                    },

                    list_vue_html: {
                        inutilizacaoNotaFiscal: "Desactivación de facturas",
                        cadastrados: "registrado",
                    },
                },

                inventario: {
                    edit_vue_html: {
                        ajusteEstq: "Ajuste de inventario",
                        tipoInvent: "Tipo de inventario",
                        grupoProd: "Grupo de productos",
                        prodLotes: "Productos/Lotes",
                        produtos: "Productos",
                        status: "Estado",
                        movimentacoes: "Movimientos",
                        iniciar: "Inicio",
                        finalizar: "Finalizar",
                        ignoraLotesZerados: "Ignorar lotes puestos a cero",
                    },

                    list_vue_html: {
                        inventarios: "inventarios",
                        gerados: "Generado",
                    },

                    produtoassociacao_vue_html: {
                        addNovo: "Agregar nuevo",
                        cadProd: "Registrar producto",
                    },

                    produtolotes_vue_html: {
                        prod: "Producto",
                        tipo: "Tipo",
                        editProd: "Editar producto",
                        novoProd: "Nuevo producto",
                        ftrCorrec: "Factor de corrección",
                        fc: "F.C.",
                        qtdUnMedOrig: "Cant. / Un.Med. Orig. ",
                        qtd: "Cant.",
                        unMed: "Un.Med",
                        qtdConvert: "Cant. Convertido ",
                        custUnit: "Coste unitario",
                        custRefAtual: "Coste de referencia actual",
                        dadosLote: "Datos por lotes",
                        descr: "Descripción",
                        dataFab: "Fecha de fabricación",
                        dataVal: "Fecha de vencimiento",
                        precoMaxConsu: "Precio máx. al consumidor",
                        importSemLote: "Importar sin lote",
                        preenchLote: "Llenar lote",
                    },

                    produtos_vue_html: {
                        prodLotes: "Productos/Lotes",
                        proximo: "Siguiente",
                        conclAlteAvancProxProd:
                            "Complete los cambios actuales y proceda al siguiente producto divergente",
                    },
                },

                kitembalagem: {
                    edit_vue_html: {
                        kitEmbalEdit: "Packaging Kit - Edition",
                        dadosGerais: "Datos generales",
                        franquia: "Franquicia",
                        descr: "Descripción",
                        descrRotu: "Descripción de etiqueta",
                        unMed: "Unidad de medida",
                        volMax: "Volumen máximo",
                        ativo: "Activo",
                        obs: "Observación",
                        frmFarmace: "Formulario farmacéutico",
                        frmsFarmaces: "Formas farmacéuticas",
                        prioriz: "Priorizar",
                        priorizEssaOpc:
                            "Cuando se selecciona Priorizar y en uso Forma Farmacéutica tener varios kits con el mismo volumen, el sistema priorizará el marcado con esta opción",
                        prod: "Producto",
                        tipoProd: "Tipo de producto",
                        subGrupProd: "Subgrupo de productos",
                        prods: "Productos",
                        qtd: "Cantidad",
                        padrao: "Estándar",
                        prodPadrSeraoASelec:
                            "Los productos marcados como estándar se añadirán automáticamente al manipular este kit de embalaje",
                        rotuloPadrao: "Etiqueta estándar",
                        frmCapacidadeEmbalagemCapsulas: "Capacidad de Empaque (Cápsulas)",
                        capsula: "Cápsula",
                        qtdMaximaBiscoitos: "Cantidad máxima de galletas",
                    },

                    list_vue_html: {
                        kitsEmbal: "Kits de embalaje",
                        cadastrados: "registrado",
                    },
                },

                listacontrolado: {
                    edit_vue_html: {
                        lstControladosEdit: "Lista de controladores - Edición",
                        descricao: "Descripción",
                        codigo: "Código",
                        clssTerapeu: "Clase terapéutica",
                    },

                    list_vue_html: {
                        lstControlados: "Listas controladas",
                        cadastrados: "registrado",
                    },
                },

                livroRegistroEspecifico: {
                    view_vue_html: {
                        livroRegistroEspecificoGeracao: "Libro de Registro Específico",
                        ano: "Año",
                        tipoPeriodo: "Tipo de Periodo",
                        tipoMedicamentoControlado: "Tipo de Droga Controlada",
                    },
                },

                localentrega: {
                    list_vue_html: {
                        titulo: "Lugares de Entrega",
                        cadastrados: "Registrados",
                        taxa: "Tarifa",
                    },

                    edit_vue_html: {
                        titulo: "Lugares de Entrega - Edición",
                        codigo: "Código",
                        descricao: "Descripción",
                        taxa: "Tarifa",
                        transportadora: "Compañía de Envios",
                    },
                },

                manipuladores: {
                    view_vue_html: {
                        manipuladores: "Manipuladores",
                    },
                },

                meta: {
                    edit_vue_html: {
                        metaEdit: "Meta - Edición",
                        mes: "Mes",
                        ano: "Año",
                        vlrTotal: "Importe total",
                        diasUteis: "Días hábiles",
                        vlrDiario: "Valor diario",
                    },

                    list_vue_html: {
                        metas: "Objetivos",
                        cadastradas: "registrado",
                    },
                },

                metodopagamento: {
                    edit_vue_html: {
                        mtdsPgtoEdit: "Métodos de pago - Edición",
                        descricao: "Descripción",
                        ativo: "Activo",
                        nroParcels: "Número de plazos",
                        intervPrimParce: "Intervalo (días) hasta el 1er plazo",
                        intervEntreParce: "Intervalo (días) entre parcelas",
                    },

                    list_vue_html: {
                        mtdsPgto: "Métodos de pago",
                        cadastrados: "registrado",
                    },
                },

                molde: {
                    edit_vue_html: {
                        moldeEdit: "Molde - Edición",
                        dadosGerais: "Datos generales",
                        descricao: "Descripción",
                        ativo: "Activo",
                        unMedida: "Unidad de medida",
                        volInterno: "Volumen interno",
                        volTotal: "Volumen total",
                        volMaxPrinciAtivo: "Principio activo de volumen máximo",
                        volMaxPrinciAtivoFito: "Principio activo fitoterapéutico de volumen máximo",
                        obs: "Observación",
                        frmFarmaceu: "Forma farmacéutica",
                        frmsFarmaceus: "Formas farmacéuticas",
                        qspPadrao: "Producto QSP estándar",
                    },

                    list_vue_html: {
                        moldes: "Moldes",
                        cadastrados: "registrado",
                    },
                },

                motivocancelamento: {
                    edit_vue_html: {
                        mtvCancelEdit: "Motivo de la cancelación - Edición",
                        descricao: "Descripción",
                        exigirObs: "Requerir observación",
                        ativo: "Activo",
                    },

                    list_vue_html: {
                        mtvCancelamento: "Razones para la cancelación",
                        cadastrados: "Registrado",
                    },
                },

                motivoEtapaPCP: {
                    edit_vue_html: {
                        motivoEtapaPCPEdit: "Razones para la Etapa PCP - Edición",
                        descricao: "Descripción",
                    },

                    list_vue_html: {
                        motivoEtapaPCP: "Razones para la Etapa PCP",
                        cadastrados: "Registrado",
                    },
                },

                motivorejeicao: {
                    edit_vue_html: {
                        mtvRejeicaoEdit: "Motivo del rechazo: edición",
                    },

                    list_vue_html: {
                        mtvRejeicao: "Razones del rechazo",
                    },
                },

                motivomovimentacao: {
                    edit_vue_html: {
                        motivoMovimentacaoEdit: "Motivo del Movimiento de inventario - Edición",
                        descricao: "Descripción",
                    },

                    list_vue_html: {
                        motivomovimentacao: "Motivo del Movimiento de inventario",
                        cadastrados: "Registrado",
                    },
                },

                movimentacaoconta: {
                    edit_vue_html: {
                        movimentacContaEdit: "Transacción de cuenta - Edición",
                        tipoMovimenta: "Tipo de movimiento",
                        contaGerenc: "Cuenta gerencial",
                        dataPgto: "Fecha de pago",
                        frmRecebimento: "Formulario de recibo",
                        frmPgto: "Método de pago",
                        contaCorrt: "Cuenta corriente",
                        vlrRecebido: "Cantidad recibida",
                        vlrPago: "Importe pagado",
                        descricao: "Descripción",
                        obs: "Observación",
                    },

                    list_vue_html: {
                        movimentacConta: "Transacciones de cuenta",
                        cadastradas: "registrado",
                    },
                },

                movimentacaoestoque: {
                    edit_vue_html: {
                        movimentaEstqEdit: "Movimiento de inventario - Edición",
                        dadosGera: "Datos generales",
                        tipoMovimenta: "Tipo de movimiento",
                        dataMovimenta: "Fecha de mudanza",
                        estqOrigem: "Origin Stock",
                        estqDestino: "Stock de destino",
                        motivoMovimentacaoEstoque: "Motivo del movimiento",
                        produtos: "Productos",
                        reverterMovimentacao: "Movimiento inverso de existencias",
                    },

                    item_vue_html: {
                        dadosLote: "Datos por lotes",
                        lote: "Lot",
                        editProdLote: "Editar lote de productos",
                        novoProdLote: "Nuevo lote de productos",
                        produto: "Producto",
                        qtd: "Cantidad",
                        vlrUnitario: "Valor unitario",
                        qtdAtual: "Cantidad actual",
                        dataFab: "Fecha de fabricación",
                        dataVal: "Fecha de vencimiento",
                        obs: "Observación",
                    },

                    list_vue_html: {
                        movimentaEstq: "Movimiento de stock",
                        cadastradas: "Registrado",
                        dataInicial: "La fecha de inicio",
                        dataFinal: "Fecha final",
                        tipoMov: "Tipo de movimiento",
                        estoque: "Inventario",
                    },
                },

                naturezaoperacao: {
                    edit_vue_html: {
                        naturezaOpEdit: "Nature of Operation - Edition",
                        descricao: "Descripción",
                        codigo: "Código",
                        tipoMovimento: "Tipo de movimiento",
                        dentroEstado: "Inside State",
                        movimentaEstq: "Move Stock",
                        usaConfiguracaoFiscal: "Usar Configuración de impuestos",
                        observacoes: "Observación",
                        transferencia: "Transferencia",
                        movimentaEstqToolTip:
                            "Al marcar este campo, el sistema considerará automáticamente el movimiento de stock al emitir una factura independiente después de emitir la factura",
                    },

                    list_vue_html: {
                        naturezaOp: "Naturaleza de la operación",
                        cadastradas: "registrado",
                    },
                },

                nbm: {
                    edit_vue_html: {
                        nbmEdit: "NBM - Edición",
                        descricao: "Descripción",
                        codigo: "Código",
                    },

                    list_vue_html: {
                        nbms: "NBMs",
                        cadastrados: "registrado",
                    },
                },

                ncm: {
                    edit_vue_html: {
                        ncmEdit: "NCM - Edición",
                        descricao: "Descripción",
                        codigo: "Código",
                        ativo: "Activo",
                    },

                    list_vue_html: {
                        ncms: "NCM",
                        cadastrados: "registrado",
                    },
                },

                operadoraCartoes: {
                    edit_vue_html: {
                        operadoraCardsEdit: "Card Operator - Edition",
                        dadosGerais: "Datos generales",
                        tipo: "Tipo",
                        cpf: "CPF",
                        rg: "RG",
                        cnpj: "CNPJ",
                        ie: "Insc. Estado",
                        nomeFantasia: "Nombre de fantasía",
                        nome: "Nombre",
                        razaoSocial: "Razón Social",
                        localizacao: "Ubicación",
                        cep: "código postal",
                        estado: "Estado",
                        cidade: "Ciudad",
                        endereco: "Dirección",
                        nro: "Número",
                        bairro: "Bairro",
                        complemento: "Complemento",
                        contato: "Contacto",
                        tel: "Teléfono",
                        cel: "Móvil",
                        telContato: "Contacto telefónico",
                    },

                    list_vue_html: {
                        operadoraCards: "Operador de tarjeta",
                        cadastradas: "registrado",
                    },
                },

                paciente: {
                    edit_vue_html: {
                        pacienteEdit: "Paciente - Edición",
                        nome: "Nombre",
                        nomeRotulo: "Nombre de etiqueta",
                        nomeUtilizRotulo: "Nombre que se utilizará en la etiqueta para la identificación del paciente",
                        ativo: "Activo",
                        dataNasc: "Fecha de nacimiento",
                        genero: "Género",
                        pesoKg: "Peso (Kg)",
                        especie: "Especie",
                        raca: "Raza",
                        cliente: "Cliente",
                        editCliente: "Editar cliente",
                        novoCliente: "Nuevo cliente",
                        obs: "Observación",
                        pacienteCliente: "Paciente Cliente",
                        produto: "Producto",
                        alergias: "Alergias",
                        condicao: "Condición",
                    },

                    list_vue_html: {
                        paciente: "Paciente",
                        pacientes: "Pacientes",
                        cadastrados: "Registrado",
                        visuPaciente: "Ver paciente",
                    },

                    ver_vue_html: {
                        nome: "Nombre:",
                        nomeRotulo: "Nombre de Etiqueta",
                        dtNasc: "Fecha de nacimiento:",
                        genero: "Género:",
                        especie: "Especie:",
                        peso: "Peso:",
                        cliente: "Cliente:",
                    },
                },

                pcp: {
                    pesquisar: "Buscar",
                    manipulacaoOrdem: {
                        pcpEdit: "PCP Manipulación - Edición",
                        manipulacaoOrdemCodigo: "Código Orden de manipulación",
                        manipulacaoOrdemPCP: "Orden de manipulación",
                        manipulacao: "Manipulación",
                        paciente: "Paciente",
                        codigoVenda: "Código Venda",
                        pesquisaAvancada: "Búsqueda Avanzada",
                    },
                    venda: {
                        pcpEdit: "PCP Venta - Edición",
                        vendaCodigo: "Código da Venta",
                        vendaPCP: "Venta",
                        cliente: "Cliente",
                        valorTotal: "Valor total de ventas",
                        quantidadeItens: "Cantidad de artículos",
                    },
                    etapas: {
                        etapas: "Etapas",
                        etapaAtual: "Etapa Actual",
                        etapaDestino: "Etapa Destino",
                        motivoEtapaPCP: "Motivo",
                        observacao: "Observación",
                        dataInicio: "Fecha de inicio",
                        dataFim: "Fecha de finalización",
                    },
                },

                pdv: {
                    edit_vue_html: {
                        pdvEdit: "Pdv - Edición",
                        descricao: "Descripción",
                        saldoInicial: "Saldo inicial",
                        contaCorrente: "Cuenta corriente",
                        gerarSaldoInicial: "Generar saldo inicial (financiero)",
                        formaRecebimento: "Formulario de recibo de transferencia",
                        contaCorrenteInterna:
                            "Las cuentas corrientes para vincular con PDV sólo pueden ser de tipo interno",
                    },

                    list_vue_html: {
                        pdvs: "PDVs",
                        cadastrados: "Registrado",
                    },
                },

                posologia: {
                    edit_vue_html: {
                        posologiaEdit: "Posología - Edición",
                        descricao: "Descripción",
                        dosesDia: "Dosis por día",
                        dosesSemana: "Dosis por semana",
                        dosesMes: "Dosis por mes",
                        duracaoEstimDias: "Duración estimada (días)",
                        padrao: "Estándar",
                        ativo: "Activo",
                    },

                    list_vue_html: {
                        posologias: "Posologías",
                        cadastradas: "registrado",
                    },
                },

                prescritor: {
                    edit_vue_html: {
                        precritorEdit: "Prescritor - Edition",
                        dadosGerais: "Datos generales",
                        cod: "Cod.",
                        numRegProfis: "Nº Registro Profesional",
                        cpf: "CPF",
                        rg: "RG",
                        ativo: "Activo",
                        conselhoRegional: "Consejo Regional",
                        ufConselhoRegional: "UF Consejo Regional",
                        nome: "Nombre",
                        dtNasc: "Fecha de nacimiento",
                        genero: "Género",
                        comissao: "Comisión",
                        obs: "Observación",
                        especialidSelec: "Especialidades seleccionadas",
                        especialid: "Especialidad",
                        localiz: "Ubicación",
                        cep: "código postal",
                        estado: "Estado",
                        cidade: "Ciudad",
                        endereco: "Dirección",
                        nro: "Número",
                        bairro: "Barrio",
                        complement: "Complemento",
                        contato: "Contacto",
                        tel: "Teléfono",
                        cel: "Móvil",
                        comissionamento: {
                            titulo: "Comissionamento",
                            inicioFaixa: "Início Faixa",
                            fimFaixa: "Fim Faixa",
                            porcentagemComissao: "Porcentagem Comissão",
                        },
                        visitadoresNomes: "Visitantes",
                        classPrescritor: "Calificación del prescriptor",
                        sipeAgro: "SipeAgro",
                    },

                    list_vue_html: {
                        prescritores: "Prescriptores",
                        cadastrados: "Registrado",
                        visuPrescritor: "Ver prescritor",
                    },

                    ver_vue_html: {
                        dadosGerais: "Datos generales",
                        codigo: "Código:",
                        numeroRegistro: "Número de Registro:",
                        cpf: "CPF:",
                        rg: "RG:",
                        nome: "Nombre",
                        dtNasc: "Fecha de nacimiento:",
                        genero: "Género:",
                        obs: "Nota:",
                        localiz: "Ubicación",
                        cep: "código postal",
                        estado: "estado:",
                        cidade: "Ciudad:",
                        endereco: "Dirección",
                        nro: "Número:",
                        bairro: "Barrio:",
                        complement: "Complemento:",
                        contato: "Contacto",
                        tel: "Teléfono:",
                        cel: "Móvil:",
                        especialids: "Especialidades",
                        especialid: "Especialidad:",
                        tipoConselhoRegional: "Consejo Regional:",
                        ufConselhoRegional: "UF do Conselho Regional:",
                    },
                },

                curvaABC: {
                    edit_vue_html: {
                        dataInicio: "Fecha de inicio",
                        dataFim: "Fecha final",
                        considerar: "Considerar",
                        curvaABCProdutos: "Productos de curva ABC",
                        curvaABCPrescritores: "Prescriptores de la curva ABC",
                        atualizar: "Actualizar",
                        sucesso: "¡Curva ABC actualizada exitosamente!",
                    },
                },

                produto: {
                    edit_vue_html: {
                        prodEdit: "Producto - Edición",
                        dadosGerais: "Datos generales",
                        codigoFranquia: "Código de franquicia",
                        codigoRede: "Código de red",
                        franquia: "Franquicia",
                        descricao: "Descripción",
                        ativo: "Activo",
                        grupAtivo: "Grupo de productos",
                        subgrupoProd: "Subgrupo de productos",
                        cores: "Colores",
                        codBarras: "Código de barras",
                        tipoCalc: "Tipo de cálculo",
                        unMedManipu: "Unidad de medida de manipulación",
                        unMedEstq: "Unidad de medida de inventario",
                        naoControlEstq: "No Controlar el inventario",
                        estqMin: "Inventario mínimo",
                        estqMax: "Stock máximo",
                        posologia: "Posología",
                        editPosologia: "Editar posología",
                        novaPosologia: "Nueva Posología",
                        tipoObs: "Tipo de observación",
                        obs: "Observación",
                        observacoes: "Observaciones",
                        financeiro: "Financiero",
                        vlrCusto: "Valor de coste",
                        custoRefe: "Coste de referencia",
                        custoMed: "costo promedio",
                        markupPorcent: "Marcado (%)",
                        markupPadrao: "Franquicia de marcado",
                        markupPadraoToolTip:
                            "Al seleccionar el Margen de Franquicia, el sistema considerará el Margen estándar configurado en la franquicia para calcular el valor de ventas",
                        markup: "Marcado",
                        vlrVenda: "Valor de ventas",
                        nbmSH: "NBM / SH",
                        ncmSH: "NCM / SH",
                        cest: "CEST",
                        visuLogVlrs: "Ver valores de registro",
                        manipu: "Manipulación",
                        descrRotulo: "Descripción Descripción",
                        excip: "Excipiente",
                        densidadeGml: "Densidad (g / ml)",
                        diluicao: "Dilución",
                        margemSegur: "Margen de seguridad",
                        ftrCorrecao: "Factor de corrección",
                        ftrUI: "Factor UI",
                        ftrUFC: "Factor UFC",
                        ftrUTR: "Factor UTR",
                        ftrEquivalencia: "Factor de equivalencia",
                        ftrCorrecMolde: "Factor de corrección de moho",
                        doseMax: "Dosis máxima",
                        doseMaxDiaria: "Dosis diaria máxima",
                        doseMaxPorcent: "Dosis máxima (%)",
                        dcb: "DCB",
                        dci: "DCI",
                        listControlados: "Lista de Controlada",
                        cas: "CAS",
                        pesoMolecular: "Peso molecular",
                        formulaMolecular: "Fórmula molecular",
                        prodDiluido: "Producto diluido",
                        prodPuro: "Produto puro que originou o produto diluído",
                        doseMaxProdDiluido: "Dosis máxima de producto diluido",
                        siproquim: "SIPROQUIM",
                        policiaCivil: "Policía Civil",
                        codigoSiproquim: "Código SIPROQUIM",
                        indicaLimitDose:
                            "La dosis máxima de producto diluido indica el límite de dosis en el que el sistema sugerirá cambiar de activos puros a diluidos",
                        embalagem: "Embalaje",
                        volMaxMl: "Volumen máximo (ml)",
                        kitEmbalagem: "Juego de embalaje",
                        validades: "Validez",
                        validadeDias: "Validez en Días",
                        capsula: "Cápsula",
                        tipoCapsula: "Tipo de cápsula",
                        gastrorre: "Gastroresistente",
                        frmFarmaceu: "Forma farmacéutica",
                        frmFarmaceuBloqueada: "Formularios farmacéuticos bloqueados",
                        libBloq: "Liberado",
                        associacoes: "Asociaciones",
                        produto: "Producto",
                        desmembrar: "desmembrar",
                        tipoCalculoAssociacao: "Tipo de cálculo",
                        percentual: "Porcentaje",
                        sinonimos: "Sinónimos",
                        descricaoRotulo: "Descripción de la etiqueta",
                        fatorEquivalencia: "Factor de equivalencia",
                        qtdTotal: "Cantidad total:",
                        visuLote: "Ver lote",
                        incompatibilidade: "Productos incompatibles",
                        embalagemIncompativeis: "Embalaje incompatible",
                        productoRefrigerado: "Producto Refrigerado",
                        produtoIncompativel: "Producto Incompatible",
                        kitEmbalagemIncompativel: "Kit de embalaje incompatible",
                        formaFarmIncompativel: "Forma farmacéutica incompatible",
                        lote: "Lote",
                        fiscal: "Tax",
                        aliquotasInterestaduais: "Tarifas interestatales",
                        uf: "UF",
                        aliquotaInterestadual: "Tarifa",
                        verAliquotasInterestaduais: "Ver tarifas por UF",
                        fichaTecnica: "Ficha técnica",
                        usaMarkupDinamico: "Usar marcado dinámico",
                        markupDinamico: "Marcado dinámico",
                        quantidadeLimite: "Limite la cantidad",
                        acao: "Acción",
                        trocarUnidadeMedidaEstoque: "Cambio unidad medida stock",
                        trocar: "Cambiar",
                        unidadeMedidaEstoqueIdOrigem: "Unidad de Medida de Inventario actual",
                        unidadeMedidaEstoqueIdDestino: "Unidad de Medida Destino de Inventario",
                        corPadraoCapsula: "Color predeterminado de la cápsula",
                        naoUtilizarExcipiente: "No usar excipiente",
                        corManipulacao: "Color de identificación en manipulación",
                        classProduto: "Clasificación del producto",
                        fitoterapico: "Fitoterapéutico",
                        descricaoFiscal: "Descripción del Impuesto",
                    },

                    list_vue_html: {
                        produtos: "Productos",
                        cadastrados: "Registrado",
                        grupProduto: "Grupo de productos",
                        subgrupProd: "Subgrupo de productos",
                        corrigirVlrVenda: "Valor correcto de venta",
                        situacaoProduto: "Estado del producto",
                    },

                    verLote_vue_html: {
                        dadosGerais: "Datos generales",
                        descricao: "Descripción",
                        fornecedor: "Proveedor:",
                        produto: "Producto:",
                        qtdAtual: "Cantidad actual:",
                        dtFabricacao: "Fecha de fabricación:",
                        dtValidade: "Fecha de vencimiento:",
                        ftrCorrecao: "Factor de corrección:",
                        densidade: "Densidad:",
                        fatorUI: "Factor UI:",
                        fatorUFC: "Factor UFC:",
                        fatorUTR: "Factor UTR:",
                        vlrCusto: "Valor de coste:",
                        obs: "Nota:",
                    },

                    verProduto_vue_html: {
                        dadosGerais: "Datos generales",
                        descricao: "Descripción:",
                        descrRotulo: "Descripción Descripción:",
                        unMedManipu: "Manipulación de unidad de medida:",
                        unMedEstq: "Stock de medida de unidad:",
                        controlaEstq: "Inventario de controles:",
                        estqMin: "Inventario mínimo:",
                        obs: "Nota:",
                        financeiro: "Financiero",
                        vlrCusto: "Valor de coste:",
                        custoRefe: "Costo de referencia:",
                        custoMedio: "Coste medio:",
                        markupPorcent: "Marcado (%):",
                        vlrVenda: "Valor de venta:",
                        manipulacao: "Manipulación",
                        densidadeGml: "Densidad (g/ml):",
                        ftrCorrec: "Factor de corrección:",
                        ftrUI: "Factor UI:",
                        ftrUFC: "Factor UFC:",
                        ftrUTR: "Factor UTR:",
                        doseMax: "Dosis máxima:",
                        doseMaxDia: "Dosis diaria máxima:",
                        doseMaxPorcent: "Dosis máxima (%):",
                        embalagem: "Embalaje",
                        volMaxMl: "Volumen máximo (ml):",
                        associacoes: "Asociaciones",
                        sinonimos: "Sinónimos",
                        qtdTotal: "Cantidad total:",
                        visuLote: "Ver lote",
                        capsulaCor: "Colores de las cápsulas",
                        incompatibilidades: "Incompatibilities",
                    },
                },

                produtodcb: {
                    edit_vue_html: {
                        dcbEdit: "DCB - Edición",
                        descricao: "Descripción",
                        codigo: "Código",
                        codigoCAS: "Código CAS",
                    },

                    list_vue_html: {
                        denomComunsBra: "Denominaciones comunes brasileñas",
                        dcbs: "DCB",
                        cadastradas: "registrado",
                    },
                },

                produtodci: {
                    edit_vue_html: {
                        dciEdit: "DCI - Edición",
                        descricao: "Descripción",
                        codigo: "Código",
                    },

                    list_vue_html: {
                        denomComunsInternac: "Nombres comunes internacionales",
                        dcis: "DCIs",
                        cadastradas: "registrado",
                    },
                },

                produtologfinanceiro: {
                    list_vue_html: {
                        logFinanceprod: "Registro de financiación de productos",
                        logProdutos: "Registro de productos",
                        alterados: "Cambiado",
                        valoresAtuais: "Valores actuales",
                        valorCusto: "Valor de coste",
                        custoRefe: "Coste de referencia",
                        custoMedio: "Coste medio",
                        markupPorce: "Marcado (%)",
                        markup: "markup",
                        vlrsVenda: "Valor de ventas",
                        vlrsLogAnterior: "Valores de registro anteriores",
                        logAlteCamposFinanceProd: "Registro de cambio de campo financiero del producto",
                        dataUltimaAlteracao: "Fecha del último cambio",
                        usuarioUltimaAlteracao: "Usuario cambiado por última vez",
                    },
                },

                produtolote: {
                    edit_vue_html: {
                        loteProdEdit: "Product Batch - Edition",
                        descricao: "Descripción",
                        fornecedor: "Proveedor",
                        produto: "Producto",
                        qtdAtual: "Cantidad actual",
                        quantidadeComprometida: "Cantidad comprometida",
                        dataFab: "Fecha de fabricación",
                        dataVal: "Fecha de vencimiento",
                        sequencialCompra: "Secuencia de compra",
                        ftrCorrecao: "Factor de corrección",
                        densidadeGml: "Densidad (g / ml)",
                        fatorUI: "Factor UI",
                        fatorUFC: "Factor UFC",
                        fatorUTR: "Factor UTR",
                        vlrCusto: "Valor de coste",
                        obs: "Observación",
                        preenchLote: "Llenar lote",
                        imprimirRotulo: "Imprimir etiqueta",
                        estoqueComprometido: "Movimiento de stock por lotes",
                        movimentosEstoque: "Movimiento de stock por lotes",
                        campoNull:
                            "Si el valor es informado en este campo aunque sea 0, será considerado en la manipulación. Si desea utilizar el valor registrado en el producto, deje el campo sin ningún valor",
                        conservacao: "Conservación",
                        statusProdutoLote: "Estado del lote",
                        origemProdutoLote: "Origen del lote",
                        descricaoFornecedor: "Lote del proveedor",
                        fracionar: "Fraccionar",
                        imprimirOrdemFracionamento: "Imprimir orden de fraccionamiento",
                        lotesFracionados: "Lotes Fraccionados",
                        atualizarDadosOrdemFracionamento: "Atualização de dados da Ordem de Fracionamento",
                        estoques: "Inventario",
                        dadosNotaEntradaLote: "Datos de la nota de entrada de lote",
                        retornarLoteParaFracionamento: "Lote de retorno para fraccionamiento",
                        anexarLote: "Adjuntar archivo al lote",
                        uploadAnexo: "Cargar archivo adjunto",
                        nomeArqAnexoLote: "Nombre del archivo adjunto",
                    },

                    list_vue_html: {
                        lotesProduto: "Lotes de productos",
                        cadastrados: "registrado",
                        statusProdutoLote: "Estado del lote",
                        origemProdutoLote: "Origen del lote",
                        tipoEstoque: "Inventario",
                        ignorarZerados: "Ignorar ceros",
                    },

                    atualizarStatus_vue_html: {
                        atualizar: "Actualizar",
                        newStatusProdutoLote: "Nuevo estado de lote",
                        atualizadoSucesso: "Estado del lote actualizado con éxito",
                    },

                    fracionamento_vue_html: {
                        quantidade: "Cantidad",
                        quantidadeEmbalagem: "Cantidad kit embalaje",
                        volume: "Volumen",
                        unidadeMedida: "Unidad de medida",
                        total: "Total",
                        quantidadeFracionada: "Cantidad fraccionaria",
                        quantidadePerda: "Cantidad de pérdida",
                        quantidadeTotal: "Cantidad Total",
                        fracionar: "Fraccionar",
                        sucessoFracionamento: "Fraccionamiento generado con éxito",
                        temperatura: "Temperatura",
                        umidade: "Humedad",
                        salaFracionamento: "Sala de fraccionamiento",
                        quantidadeEfetivamenteFracionada: "Cantidad efectivamente fraccionada",
                        quantidadeRetiradaAmostragem: "Cantidad retirada para muestreo",
                        horaInicio: "Fecha de Inicio",
                        horaFim: "Fecha de finalización",
                        embalagensNaoUtilizadas: "Embalaje sin usar",
                        observacao: "Observación",
                        fracionamento: "Fraccionamiento",
                    },

                    fracionamentoKitEmbalagem_vue_html: {
                        kitEmbalagem: "Kit de embalaje",
                        embalagem: "Embalaje",
                        embalagemLote: "Lote",
                        quantidade: "Cantidad Total",
                    },

                    dadosNotaEntradaLote_vue_html: {
                        dataInclusaoNota: "Nota fecha de inclusión",
                        dataEmissaoNota: "Fecha de emisión del billete",
                        numeroNota: "Número",
                        serieNota: "Serie",
                        quantidadeItemRespectivoLotea: "Cantidad del artículo correspondiente al lote",
                        notaDeCompraNaoEncontradaNoSitema: "Nota de compra de lote no encontrada en el sistema",
                    },
                },

                racaAnimal: {
                    list_vue_html: {
                        titulo: "Razas de Animales",
                        cadastradas: "Registradas",
                    },

                    edit_vue_html: {
                        titulo: "Registro de Razas Animales",
                        descricao: "Descripción",
                        especieAnimal: "Especies Animales",
                    },
                },

                recebimentoParcelado: {
                    edit_vue_html: {
                        recebimentoParceladoEdit: "Métodos de recepción de pagos a plazos - Edición",
                        numeroParcelas: "Número de plazos",
                        addParcela: "Agregar parcelas",
                        descricao: "Descripción",
                        ativo: "Activo",
                    },

                    list_vue_html: {
                        recebimentoParcelado: "Métodos de recepción de pagos a plazos",
                        cadastrados: "registrado",
                    },
                },

                recompensa: {
                    edit_vue_html: {
                        recompensaEdit: "Recompensa - Edición",
                        premio: "Premio",
                        pontosNecess: "Puntos necesarios",
                        ativo: "Activo",
                    },

                    list_vue_html: {
                        campanhaRecompensas: "Campaign: Rewards",
                        recompensas: "Recompensas",
                        cadastradas: "registrado",
                    },
                },

                rede: {
                    edit_vue_html: {
                        redeEdit: "Rede - Edition",
                        dadosGerais: "Datos Generales",
                        nomeFantasia: "Nombre de Fantasía",
                        razaoSocial: "Razón Social",
                        cnpj: "CNPJ",
                        ie: "Insc. Estado",
                        im: "Insc. Municipal",
                        respTecnico: "Responsable Técnico",
                        conseRegional: "Consejo Regional",
                        nroCR: "número CR",
                        crf: "CRF",
                        crmv: "CRMV",
                        crm: "CRM",
                        areaAtuacao: "Área de Operación",
                        naturOperacao: "Naturaleza de la operación",
                        optanteSimplNacio: "Optando por Simples Nacional",
                        incentivCultuta: "Incentivador cultural",
                        localizacao: "Ubicación",
                        cep: "código postal",
                        estado: "Estado",
                        cidade: "Ciudad",
                        lograd: "Patio trasero",
                        complemen: "Complemento",
                        nro: "Número",
                        bairro: "barrio",
                        contato: "Contacto",
                        tel: "Teléfono",
                        cel: "Móvil",
                        gerarBaseModel: "Generar base de modelo",
                        usoDoAppPor: "Uso de la aplicación por",
                    },

                    list_vue_html: {
                        redes: "Redes",
                        cadastradas: "registrado",
                    },
                },

                rotulopersonalizado: {
                    edit_vue_html: {
                        rotuloEdit: "Rotulo - Edición",
                        nombre: "Nombre",
                        tipoRotulo: "Tipo de Etiqueta",
                        rotuloUtilizar: "Rotulo para usar",
                        largura: "Ancho",
                        altura: "Altura",
                        espacamentoSuperior: "Espaciado Superior",
                        espacHoriz: "Espaciado Horizontal",
                        posicQRCode: "Posición del código QR/código de barras",
                        larguraCode: "Ancho del código QR/código de barras",
                        alturaCode: "Altura del código QR/código de barras",
                        posLogo: "Logotipo de Posición",
                        largLogo: "Logo Width",
                        altLogo: "Logo Height",
                        printDadosFranq: "Imprimir datos de franquicia",
                        printDadosFranqNegrito: "Datos de franquicia en negrita",
                        dadosFranquiaRespTec: "Responsable técnico y de datos de la franquicia",
                        fonteDadosFranq: "Franquicia de datos de origen",
                        alinhamentoDadosDaFranquia: "Alineación de datos de franquicias",
                        quantidadeColunas: "Número máximo de columnas",
                        fonteRotulo: "Fonte do Rotulo",
                        paddingLine: "Espaciado entre líneas",
                        camposImpressao: "Campos de Impresión",
                        formatoImpressao: "Formato de impresión",
                        imprimirResponsavelTecnico: "Gerente Técnico de Impresión",
                    },

                    imprimirRotuloComModal_vue_html: {
                        rotuloPersonalizado: "Etiqueta Personalizada",
                    },

                    list_vue_html: {
                        rotulos: "Etiquetas",
                        cadastrados: "registrado",
                    },
                },

                separacao: {
                    edit_vue_html: {
                        separacaoVenda: "Separación de venta ",
                        concluir: "Finalizar",
                        botaoCodigo: "Separar",
                        labelCodigo: "Código de Lote",
                        title: "Introducir Código de Lote",
                    },

                    list_vue_html: {
                        fracionamento: "fraccionamiento",
                        vendas: "Ventas",
                        cadastrados: "registrado",
                        setor: "Sector",
                    },
                },

                servico: {
                    edit_vue_html: {
                        servicoEdit: "Servicio - Edición",
                        descricao: "Descripción",
                        servico: "Servicio",
                        detalheServico: "Detalle de servicio",
                        atividade: "Actividad",
                        utilizCodTributPrefeitura:
                            "Use el código de impuestos de servicio proporcionado por el ayuntamiento",
                        codServicLC: "Cod. LC 116/2003 Service",
                        utilizCodLCPrefeitura: "Use el código de artículo LC proporcionado por el ayuntamiento",
                        aliquota: "Alícuota",
                        issRetido: "ISS retenido",
                    },

                    list_vue_html: {
                        servicos: "Servicios",
                        cadastrados: "registrado",
                    },
                },

                sngpcManual: {
                    edit_vue_html: {
                        formularioSngpcRelat: "FORMULARIO SNGPC - INFORME",
                        identificacao: "Identificación",
                        razaoSocial: "Razón Social",
                        endereco: "Dirección",
                        nro: "Número",
                        bairro: "Barrio",
                        cidade: "Ciudad",
                        tel: "Teléfono",
                        cnpj: "CNPJ",
                        nroAutorizAnvisa: "Número de autorización ANVISA",
                        nroAutorizMapa: "Número de autorización MAPA",
                        periodo: "Periodo",
                        tipoBalanco: "Tipo de saldo",
                        periodicBalanco: "Periodicidad del balance",
                        ano: "Año",
                        regiao: "Región",
                        respTecnico: "Responsable técnico",
                        nroCR: "número CR",
                        crf: "CRF",
                        crmv: "CRMV",
                        crm: "CRM",
                        data: "Fecha",
                        download: "Descargar",
                    },
                },

                tela: {
                    edit_vue_html: {
                        telaEdit: "Tela - Edición",
                        chaveTraducao: "Clave de Traducción",
                        rota: "Ruta",
                        iconeMenu: "Menú Icone",
                        rotaAcaoRapida: "Ruta de acción rápida",
                        iconeAcaoRapida: "Icono de acción rápida",
                        controller: "Controlador",
                        ordem: "Orden",
                        pai: "Padre",
                        ocultarMenu: "Ocultar menú",
                        ativo: "Activo",
                    },

                    list_vue_html: {
                        telas: "Pantallas",
                        cadastradas: "registrado",
                    },
                },

                telaacao: {
                    edit_vue_html: {
                        acaoTelaEdit: "Acción de pantalla - Edición",
                        descricao: "Descripción",
                        tela: "Pantalla",
                        acaoController: "Controlador de acción",
                        acaoVue: "Vue Action",
                        ativo: "Activo",
                    },

                    list_vue_html: {
                        acoesTelas: "Acciones de pantalla",
                        acoes: "Acciones",
                        cadastradas: "registrado",
                    },
                },

                transportador: {
                    edit_vue_html: {
                        transportadorEdit: "Transporter - Edición",
                        dadosGerais: "Datos generales",
                        cpf: "CPF",
                        rg: "RG",
                        ie: "Insc. Estado",
                        clienteIsentoEmBranco: "Para clientes exentos déjelo en blanco",
                        nome: "Nombre ",
                        nomeFantasia: "nombre de fantasía",
                        razaoSocial: "Razón Social",
                        im: "Insc. Municipal",
                        dtNasc: "Fecha de nacimiento",
                        genero: "Género",
                        orgaoExpedidor: "Cuerpo de despacho",
                        ufExpedidor: "UF Expedidor",
                        obs: "Observación",
                        transportadora: "Transportista",
                        localizacao: "Ubicación",
                        cep: "código postal",
                        estado: "Estado",
                        cidade: "Ciudad",
                        endereco: "Dirección",
                        nro: "Número",
                        bairro: "BarrIo",
                        complemento: "Complemento",
                        contato: "Contacto",
                        tel: "Teléfono",
                        cel: "Móvil",
                    },

                    list_vue_html: {
                        transportadores: "Transporters",
                        cadastrados: "registrado",
                    },
                },

                transportadora: {
                    edit_vue_html: {
                        transportadoraEdit: "Carrier - Edition",
                        dadosGerais: "Datos generales",
                        tipo: "Tipo",
                        cpf: "CPF",
                        rg: "RG",
                        cnpj: "CNPJ",
                        ie: "Insc. Estado",
                        clienteIsentoEmBranco: "Para clientes exentos déjelo en blanco",
                        nome: "Nombre",
                        nomeFantasia: "nombre de fantasía",
                        razaoSocial: "Razón Social",
                        im: "Insc. Municipal",
                        dtNasc: "Fecha de nacimiento",
                        genero: "Género",
                        orgaoExpedidor: "Cuerpo de despacho",
                        ufExpedidor: "UF Expedidor",
                        obs: "Observación",
                        localizacao: "Ubicación",
                        cep: "código postal",
                        estado: "Estado",
                        cidade: "Ciudad",
                        endereco: "Dirección",
                        nro: "Número",
                        bairro: "Barrio",
                        complemento: "Complemento",
                        contato: "Contacto",
                        tel: "Teléfono",
                        cel: "Móvil",
                    },

                    list_vue_html: {
                        transportadoras: "portadores",
                        cadastrados: "registrado",
                    },
                },

                compras: {
                    edit_vue_html: {
                        consolidar: "Consolidar",
                        compraCod: "Código de compra ",
                        compraEdit: "Compra - Edición",
                        edit: " - Edición",
                        dataInicial: "Fecha de inicio de ventas",
                        dataFinal: "Fecha de finalización de ventas",
                        dias: "Días de cobertura",
                        margemTolerancia: "Margen de tolerancia",
                        margemToleranciaTooltip:
                            "El margen de tolerancia se utilizará para que el sistema identifique si la cantidad presente en el stock actual cubrirá la cantidad calculada para el consumo en el período seleccionado. Por ejemplo, si el stock actual es 13 y la cantidad de compra es 10, con una tolerancia del 20% el sistema considerará que la cantidad requerida es 12, es decir, el stock actual cubrirá la demanda. Cuanto mayor sea la tolerancia, más flexible será la acción.",
                        curvaABC: "Curva ABC",
                        ultFornecedores: "Últimos proveedores",
                        carregarFornecedores: "Cargar proveedores de la última compra realizada en el sistema",
                    },
                    list_vue_html: {
                        compras: "Compras",
                    },
                    cotacao_vue_html: {
                        cotacaoEdit: "Cotización - Edición",
                        compraItens: "Comprar artículos",
                    },
                    melhorCompra_vue_html: {
                        melhorCompra: "La mejor compra",
                    },
                    cotacaoFornecedor_vue_html: {
                        cotacaoFornecedor: "Cotización de proveedores",
                        enviarCotacao: "Enviar Cotización",
                    },
                },

                unidademedida: {
                    edit_vue_html: {
                        unMedidaEdit: "Unidad de medida - Edición",
                        descricao: "Descripción",
                        sigla: "Acrónimo",
                        tipo: "Tipo",
                        conversao: "Conversión",
                        operador: "Operador",
                        valor: "Valor",
                    },

                    list_vue_html: {
                        unMedida: "Unidades de medida",
                        cadastradas: "registrado",
                    },
                },

                usuario: {
                    edit_vue_html: {
                        usuarioEdit: "User - Edition",
                        dadosGerais: "Datos generales",
                        nomeCompleto: "Nombre completo",
                        ativo: "Activo",
                        supervisor: "Supervisor",
                        cpf: "CPF",
                        perfil: "Perfil",
                        beneficio: "Beneficio",
                        permissoes: "Permisos",
                        permissoesRemovidas: "Eliminar permisos",
                        acoes: "Acciones",
                        grupos: "Grupos",
                        franquias: "Franquicias",
                        franquia: "Franquicia",
                        franquiaPadrao: "Franquicia Estándar",
                        padrao: "Estándar",
                        colaborador: "Colaborador",
                        resetarSenha: "Restablecer contraseña de usuario",
                        franquiaDrogavet: "Franquicias Drogavet",
                        atendimento: "Servicio al cliente",
                        atendente: "Asistente",
                    },

                    list_vue_html: {
                        usuarios: "Usuarios",
                        cadastrados: "registrado",
                    },
                },

                vendaContinuo: {
                    list_vue_html: {
                        orcar: "Presupuesto",
                        relatContinuos: "Informe continuo",
                        dataInicio: "Fecha de inicio de validez",
                        dataFinal: "Fecha de caducidad",
                    },
                },

                veterinario: {
                    view_vue_html: {
                        veterinario: "Veterinario",
                        ano: "Año",
                        tipoPeriodo: "Tipo de Periodo",
                        mes: "Mes",
                        autorizacao: "Autorización",
                    },
                },

                visitador: {
                    edit_vue_html: {
                        visitadorEdit: "Visitor - Edition",
                        dadosGerais: "Datos generales",
                        tipo: "Tipo",
                        cpf: "CPF",
                        rg: "RG",
                        cnpj: "CNPJ",
                        ie: "Insc. Estado",
                        clientIsentoBranco: "Para clientes exentos, déjelo en blanco",
                        nome: "Nombre",
                        nomeFantasia: "nombre de fantasía",
                        razaoSocial: "Razón Social",
                        im: "Insc. Municipal",
                        dtNasc: "Fecha de nacimiento",
                        genero: "Género",
                        orgaoExpedidor: "Cuerpo de despacho",
                        ufExpedidor: "UF Expedidor",
                        comissao: "Comisión",
                        obs: "Observación",
                        localizacao: "Ubicación",
                        cep: "código postal",
                        estado: "Estado",
                        cidade: "Ciudad",
                        endereco: "Dirección",
                        nro: "Número",
                        bairro: "Barrio",
                        complemen: "Complemento",
                        contato: "Contacto",
                        tel: "Teléfono",
                        cel: "Móvil",
                        prescritores: "Prescriptores",
                        prescritor: "prescripción",
                        editPrescritor: "Editar Prescriptor",
                        novoPrescritor: "Nuevo Prescriptor",
                    },

                    list_vue_html: {
                        visitadores: "visitantes",
                        cadastrados: "registrado",
                    },
                },

                colaborador: {
                    edit_vue_html: {
                        colaboradorEdicao: "Colaborador - Edición",
                        dadosGerais: "Datos Generales",
                        cpf: "CPF",
                        rg: "RG",
                        nome: "Nombre",
                        franquia: "Franquicia",
                        dataNasc: "Fecha de Nacimiento",
                        genero: "Género",
                        orgaoExpeditor: "Organización Expedidora",
                        ufExpeditor: "UF Expedidora",
                        obs: "Nota",
                        localizacao: "Localización",
                        cep: "CEP",
                        estado: "Estado",
                        cidade: "Ciudad",
                        endereco: "Dirección",
                        nro: "Número",
                        bairro: "Barrio",
                        complemento: "Complemento",
                        contato: "Contacto",
                        telefone: "Teléfono",
                        celular: "Celda",
                        beneficio: "Beneficio",
                        vendedorDotz: "Vendedor Dotz",
                    },
                    list_vue_html: {
                        colaboradores: "Colaboradores",
                        cadastrados: "Registrados",
                    },
                },

                configuracaodotz: {
                    edit_vue_html: {
                        tituloPagina: "Configuración de Dotz - Edición",
                        qtdMinVendas: "Número mínimo de fórmulas",
                        porcMaxDesconto: "Porcentaje máximo de descuento",
                        nomeLoja: "Nombre de la tienda",
                        tlpStoreCode: "Store Code",
                        codigoMecanica: "Código de la mecánica",
                        tlpOfferCode: "Offer Code",
                        diaEnvioPontuacao: "Días hábiles después del comienzo del mes para enviar el puntaje",
                        utilizaFaixasRede: "Utiliza los rangos de la red",
                        envioInstantaneo: "Envío instantáneo",
                        faixasTitulo: "Rangos",
                        filtros: "Filtros",
                        inicioFaixa: "Inicio del Rango",
                        fimFaixa: "Fin del Rango",
                        multiplicador: "Multiplicador",
                        tlpMultiplicador: "Este valor se multiplicará por el valor total de las ventas",
                        faixaInicioGrid: "Inicio Rango",
                        faixaFimGrid: "Fin Rango",
                        naoExistemFaixasPadroes: "No hay rangos estándar registradas en la red.",
                        nenhumaFaixaCadastrada: "No hay ningum rango registrado para esta franquicia.",
                        valorInicialMaiorFinal: "El valor inicial del rango es mayor que el valor final.",
                        faixaSobreposta: "El rango se solapa con otro rango.",
                        documentoFuncionario: "Documento de empleado",
                        tlpDiaEnvioPontuacao:
                            "El número de días hábiles se considerará después del comienzo del mes, si se informa 1 será el primer día útil del mes en enviar el puntaje.",
                        tlpUtilizaFaixasRede:
                            "Al indicar que utiliza pistas, considerará las pistas registradas en la franquicia Modelo.",
                        tlpDocumentoFuncionario:
                            "Documento registrado en Dotz para vincular la partitura. Si el documento no está registrado con Dotz, se producirá un error en la puntuación de crédito. (Debe ser el documento del franquiciado)",
                        franquiaEnvio: "Franquicia de envío",
                        franquiaEnvioTooltip:
                            "Franquicia que recibirá la puntuación. Al informar a la franquicia, el sistema ya no enviará las puntuaciones a la franquicia, ¡sino que las consolidará en la franquicia seleccionada!",
                        enviarArquivosVendedores: "Enviar archivos de vendedor",
                        pontuacaoDotz: "Puntuación de Dotz",
                        enviadoArquivosVendedores: "¡El archivo de vendedores se envió correctamente!",
                        tipoEnvio: "Tipo de envío",
                        considerarVendasPor: "Considerar ventas por:",
                    },
                },
            },

            dashboard: {
                charts: {
                    pedidos_vue_html: {
                        pedidosHoje: "Pedidos de hoy",
                    },

                    previsaoRoyalties_vue_html: {
                        prevRoyaltiesMes: "Pronóstico de regalías del mes",
                    },

                    vendas_vue_html: {
                        vendasHoje: "Ventas de hoy",
                    },

                    vendasAnual_vue_html: {
                        vendasNesteAno: "Ventas este año",
                    },

                    vendasMensal_vue_html: {
                        vendasNesteMes: "Ventas este mes",
                    },
                },

                evolucaoVendas_vue_html: {
                    valorVendasMes: "Valor total de ventas en el período seleccionado",
                    qtdeVendasMes: "Ventas realizadas en el periodo seleccionado",
                    novosClientesMes: "Nuevos Clientes en el periodo seleccionado",
                    franquias: "Franquicias",
                    limpar: "Limpiar",
                    todas: "Todo",
                    taxaEntrega: "Consider Delivery Fee",
                },

                manipulacoes_vue_html: {
                    valorTotalVendas: "Valor de ventas total",
                    qtdFormulas: "Cantidad total de fórmulas",
                    qtdVendas: "Cantidad de ventas",
                    ticketMedioFormula: "Ticket Promedio por fórmula",
                    ticketMedioVenda: "Boleto Promedio por Venta",
                    dataInicial: "Fecha de inicio",
                    dataFinal: "Fecha de finalización",
                    franquias: "Franquicias",
                },

                totaisPorCategoria_vue_html: {
                    dataInicial: "Fecha de Inicio",
                    dataFinal: "Fecha Final",
                    franquias: "Franquicias",
                },
            },

            fichaTecnica: {
                edit_vue_html: {
                    dadosGerais: "Datos generales",
                    catTerapeu: "Categoría terapéutica",
                    conserv: "Conservación",
                    refe: "Referencia",
                    ensaios: "Ensayo",
                    soludibilidade: "Solubilidad",
                    dadosAdicionais: "Datos adicionales",
                    novoDadoAdicional: "Nuevos datos adicionales",
                    obss: "Observaciones",
                },
            },

            fluxo: {
                list_vue_html: {
                    aReceber: "Cuentas por cobrar",
                    aPagar: "Pagadero",
                    saldoFinal: "Saldo final",
                    fluxoCaixa: "Flujo de caja",
                    metaMensal: "Objetivo mensual:",
                    maiorMensal: "Mayor mensual:",
                    totalAnual: "Total anual:",
                    receita: "Receta",
                    despesa: "Gastos",
                    meta: "Gol",
                },
            },

            fracionaEstoque: {
                kitEmbalagens_vue_html: {
                    embalagem: "Embalaje",
                    lote: "Lot",
                    qtd: "Cantidad",
                    total: "Total",
                    acao: "Acción",
                },
            },

            gradePCP: {
                view_vue_html: {
                    gradePCP: "Cuadrícula PCP",
                    producao: "Producción - Lun a Vie / Fin de semana",
                    capacidade: "Capacidad",
                    vendas: "Ventas",
                    pendente: "Pendiente",
                    dataInicial: "Fecha de Inicio",
                    dataFinal: "Fecha Final",
                    formasFarmacIgnorar: "Formas farmacéuticas que se deben ignorar",
                    oSistemaDesconsiFormasSelec:
                        "El sistema ignorará las formas farmacéuticas seleccionadas de la grilla.",
                    tipoVendaAIgnorar: "Tipo de venta a ignorar",
                    oSistemaDesconsiTiposVendasSelec:
                        "El sistema ignorará los tipos de ventas seleccionados de la grilla.",
                },
            },

            historico: {
                vendaHistorico: {
                    list_vue_html: {
                        historicoVendas: "Historial de ventas",
                        vendaHistorico: "Venta histórica",
                        tudo: "Todo",
                        contiuo: "Continuo",
                        dtVenda: "Fecha de venta:",
                        visuVenda: "Ver oferta",
                    },

                    ver_vue_html: {
                        dadosGerais: "Datos generales",
                        cliente: "Cliente:",
                        cpf: "CPF:",
                        dtNasc: "Fecha de nacimiento:",
                        cel: "Móvil:",
                        tel: "Teléfono:",
                        vlrTotal: "Importe total:",
                        dtSale: "Fecha de venta:",
                        obs: "Obs:",
                        itensAcabados: "Artículos terminados",
                        produto: "Producto:",
                        qtd: "Cantidad:",
                        vlr: "Valor:",
                        prescritor: "Prescriptor:",
                        paciente: "Paciente:",
                        itensManipu: "Items Handled",
                        formaFarm: "Forma Farm.:",
                        qtdVol: "Cant. / Vol.:",
                        volBase: "Vol. Base: ",
                        unMed: "Unidades. Med.: ",
                        continuo: "Continuo:",
                        embalagem: "Embalaje:",
                        capsula: "Capsula:",
                        posologia: "Posología:",
                        dose: "Dosis",
                        tipoDose: "Tipo de dosis",
                        lote: "Lote",
                        nr: "Número de registro:",
                    },
                },
            },

            home: {
                dashboard: {
                    dashboard_vue_html: {
                        vendaAno: "Año de venta",
                        vendasMes: "Ventas por mes",
                        vendasDias: "Sales day",
                        pedidosDia: "Día de pedidos",
                    },
                },

                fastaction: {
                    continuos: {
                        continuos_vue_html: {
                            continuos: "Continuos",
                            venc: "/ Win:",
                            arqAvisoContinuo: "Aviso de archivo de este continuo",
                            adiarVencContinuo: "Posponer la madurez de este continuo",
                            visuUltimaVendaOrcContinuo: "Ver la última venta / presupuesto de este continuo",
                            gerarOrcamentWpp: "Generar presupuesto y configurar lo que se va a enviar",
                            verMais: "Ver más",
                        },
                    },

                    expedicao: {
                        expedicao_vue_html: {
                            expedicao: "Expedición",
                            cod: "Cód: ",
                            aprovacao: "/ Aprobar.: ",
                            adicionarInfromacao: "Agregar información de envío",
                            finalizarExpedicao: "Finalizar expedición",
                            finalizar: "Finalizar",
                            verMais: "Ver mais",
                            visualizarVenda: "Ver oferta",
                        },
                    },

                    financeiro: {
                        financeiro_vue_html: {
                            sldInicialCaixa: "Saldo de caja inicial:",
                            sldAtualCaixa: "Saldo de caja actual:",
                            dinheiro: "Dinero",
                            boleto: "Boleto",
                            transferecia: "Transferir",
                            debito: "Débito",
                            credito: "Crédito",
                            cheque: "Cheque",
                            vlr: "Valor:",
                            verMais: "Ver más",
                            fechamCaixa: "Cierre de caja",
                            abertCaixa: "Caja de apertura",
                            gerarReforco: "Generar refuerzo",
                            gerarSangria: "Generate Sangria",
                        },
                    },

                    manipulacao: {
                        manipulacao_vue_html: {
                            emProcesso: "En proceso",
                            verificados: "Verificado",
                            formaFarm: "/ Forma Farm.:",
                            paciente: "/ paciente:",
                            codVenda: "/ nombre en clave:",
                            formula: "/ Fórmula:",
                            imprimirRotulo: "Imprimir etiqueta",
                            imprimirManip: "Manipulación de impresión",
                            confLotesEstqManipu: "Verificar lotes / Manejo de existencias",
                            visuManipu: "Ver manipulación",
                            conclManipu: "Manipulación completa",
                            verMais: "Ver más",
                            manipuFrmlPadrao: "Manipulación de fórmulas base",
                            manipulacoesVenda: "Manipulaciones de ventas",
                            novaManipu: "Nueva manipulación",
                            confirmarAlte: "Confirmar cambios",
                            manipulacao: "Manipulación",
                            receita: "Ver recibos de ventas",
                            historicomanipulacao: "Historial de manejo",
                        },
                    },

                    orcamentos: {
                        orcamentos_vue_html: {
                            orcadosHoje: "Presupuestado hoy:",
                            orcamentosCriadosHj: "Presupuestos creados hoy:",
                            orcamentoVenc: "Presupuesto vencido",
                            orcamentoAVenc: "Budget to Win",
                            orcamentoEmDia: "Presupuesto en día",
                            cod: "Código:",
                            vencOrcamento: "Venc. de este Presupuesto: ",
                            ativos: "Activos:",
                            arquivarRejeiOrcamento: "Archivar / rechazar presupuesto",
                            adiarValDesteOrcamento: "Posponer la validez de este presupuesto",
                            compartilharOrcamento: "Compartir presupuesto",
                            visuOrcamento: "Ver presupuesto",
                            aprovOrcamento: "Aprobar presupuesto",
                            verMais: "Ver más",
                            clientes: "Clientes",
                            orcamentos: "Presupuestos",
                            novoOrcamento: "Nuevo presupuesto",
                            confirmarAlte: "Confirmar cambios",
                        },
                    },

                    produtos: {
                        list_vue_html: {
                            relatorioSituaEstq: "Informe de estado de inventario",
                            relatorioEstqMin: "Informe de stock mínimo",
                        },

                        produtos_vue_html: {
                            prodSemEstq: "Producto agotado",
                            prodVencendo: "Productos ganadores",
                            estqBaixo: "Existencias bajas",
                            produto: "Producto:",
                            lote: "lote:",
                            val: "Validez:",
                            verMais: "Ver más",
                            produtos: "Productos",
                            lotes: "Lotes",
                            novoProd: "Nuevo producto",
                        },
                    },

                    vendas: {
                        list_vue_html: {
                            relatorioVendClient: "Informe de ventas por cliente",
                            setor: "Sector",
                            situacao: "Situación",
                            dtInicEntrega: "Fecha de inicio de entrega",
                            dtFinalEntrega: "Fecha de entrega final",
                        },

                        vendas_vue_html: {
                            vendidosHj: "Vendido hoy:",
                            vendasAprovHj: "Ventas aprobadas hoy:",
                            vendasAprovadas: "Ventas Aprobadas",
                            semFaturamento: "Ventas Sin Facturar",
                            envioNFPend: "Ventas sin Factura",
                            agRetornoPrefe: "Ventas con Factura Pendiente en el Ayuntamiento",
                            cod: "Código:",
                            dtAprov: "Fecha de aprobación:",
                            dtHrEnvio: "Fecha de envío Hora: ",
                            ativos: "Activos:",
                            cancelarVenda: "Cancelar venta",
                            imprimirVenda: "Venta impresa",
                            visuVenda: "Ver oferta",
                            faturarVenda: "Venta de facturas",
                            emitirNF: "Issue NF",
                            verMais: "Ver más",
                            novoCliente: "Nuevo cliente",
                            vendas: "Ventas",
                            novoOrcamento: "Nuevo presupuesto",
                            novoPgto: "Nuevo pago",
                            pagamentos: "Pagos",
                        },
                    },

                    fastaction_vue_html: {
                        orcamentos: "Presupuestos",
                        manipulacao: "Manipulación",
                        vendas: "Ventas",
                        continuos: "Continuo",
                        financeiro: "Financiero",
                        produtos: "Productos",
                        atendimento: "Servicio al cliente",
                        estoque: "Stock",
                        expedicao: "Expedición",
                        fracionamento: "Fraccionam.",
                    },

                    entregas: {
                        entregas_vue_html: {
                            dataHoraEntrega: "Fecha/hora de entrega:",
                            local: "Local:",
                            visualizarVenda: "Ver oferta",
                            entregas: "Entregas",
                            imprimirRomaneio: "Imprimir albaranes",
                            postagens: "Publicaciones",
                        },
                    },
                },
            },

            importacaonfe: {
                contapagar_vue_html: {
                    contaGerenc: "Cuenta gerencial",
                    dtVenc: "Fecha de vencimiento",
                    financeiro: "Financiero",
                    parcela: "Porción",
                    numeracaoDuplic: "Numeración duplicada",
                    dtDeVenc: "Fecha de vencimiento:",
                    vlrDuplic: "Valor duplicado:",
                    status: "Estado",
                    dadosPgto: "Datos de pago",
                    dtPgto: "Fecha de pago:",
                    formaPgto: "Método de pago:",
                    contaCorrente: "Cuenta corriente:",
                    faturamento: "Facturación",
                    vlrLiquido: "Valor neto:",
                },

                dadosgerais_vue_html: {
                    dadosGerais: "Datos generales",
                    chaveAcess: "Clave de acceso",
                    modelo: "Modelo",
                    serie: "Série",
                    nro: "Número",
                    versaoXML: "versión XML",
                    vlrTotalNF: "Importe total de la factura",
                    dtEmissao: "Fecha de emisión",
                    dtHrEntradaSaida: "Fecha y hora de entrada / salida",
                },

                destinatario_vue_html: {
                    destinatario: "destinatario",
                    cnpj: "CNPJ",
                    ie: "Insc. Estado",
                    tel: "Teléfono",
                    cpf: "CPF",
                    cep: "código postal",
                    pais: "País",
                    uf: "UF",
                    cidade: "Ciudad",
                    endereco: "Dirección",
                    nro: "Número",
                    bairro: "Barrio",
                    complemen: "Complemento",
                },

                edit_vue_html: {
                    nfeImport: "NFe - Importar",
                    estqDestino: "Stock de destino",
                    atualizDadosFornecedor: "Actualizar datos del proveedor",
                    gerarCntPagar: "Generar cuenta por pagar",
                    fracionarLotes: "Lotes Fraccionados",
                    devolucao: "Nota de devolución",
                    devolucaoTooltip:
                        "Si se marca, no se incluirá ningún lote en el sistema, solo se moverán los existentes, las descripciones de cada lote deben ser las mismas que las del sistema para que la devolución se realice correctamente",
                    atualizVlrSeMarcado:
                        "Si marca la opción y el valor unitario del artículo de la nota es mayor que el valor de costo de referencia del producto, se actualizará al valor derivado de la nota.",
                    atualizCustos: "Costos de actualización",
                },

                emitente_vue_html: {
                    emitente: "Emisor",
                    nomeFantasia: "Nombre de fantasía",
                    cnpj: "CNPJ",
                    ie: "Insc. Estado",
                    tel: "Teléfono",
                    cpf: "CPF",
                    cep: "código postal",
                    pais: "País",
                    uf: "UF",
                    cidade: "Ciudad",
                    endereco: "Dirección",
                    nro: "Número",
                    bairro: "Barrio",
                    complemen: "Complemento",
                },

                produtoassociacao_vue_html: {
                    addNovo: "Agregar nuevo",
                },

                produtolotes_vue_html: {
                    tipo: "Tipo",
                    produto: "Producto",
                    ftrCorrecao: "Factor de corrección",
                    fc: "F.C.",
                    qtdUnMedOrig: "Cant. / Un.Med. Orig. ",
                    qtd: "Cant.",
                    unMed: "Un.Med.",
                    qtdConvertida: "Cant. Convertido ",
                    custoUnit: "Coste unitario",
                    custoRefeAtual: "Coste de referencia actual",
                    percentMarkupVenda: "Venta de margen de porcentaje (%)",
                    dadosLote: "Datos por lotes",
                    descricao: "Descripción",
                    dtFab: "Fecha de fabricación",
                    dtVal: "Fecha de vencimiento",
                    precoMaxConsumidor: "Precio máx. al consumidor",
                    densidade: "Densidad (g/ml)",
                    importSemLote: "Importar sin lote",
                    preencherLote: "Llenar lote",
                    fracionarLote: "Lote Fraccionado",
                },

                produtos_vue_html: {
                    prodsLotes: "Productos / Lotes",
                    total: "TOTAL",
                    proximo: "Siguiente",
                    conclEAvancProxProd: "Completar los cambios actuales y pasar al siguiente producto divergente",
                },

                transporte_vue_html: {
                    transportador: "Transportista",
                    cnpj: "CNPJ",
                    ie: "Insc. Estado",
                    cpf: "CPF",
                    uf: "UF",
                    cidade: "Ciudad",
                    endereco: "Dirección",
                    qtd: "Cantidad",
                    especie: "Especie",
                    marca: "Marca",
                    numeracao: "Numeración",
                    pesoLiq: "Peso neto",
                    pesoBruto: "Peso bruto",
                    valorFrete: "Valor de Envío",
                },
            },

            manipulacao: {
                cancelamento_vue_html: {
                    manipuOrdemCancelamento: "Gestión de pedidos - Cancelación",
                    manipuCanceladaNaoPossiv: "¡La manipulación ya está cancelada! No puede cambiar los datos. ",
                    deveCancelarPelaTelaCancelamento:
                        "Esta manipulación debe ser cancelada por la pantalla de cancelación de venta",
                    dadosManipu: "Datos de manipulación",
                    dtCancelamento: "Fecha de cancelación",
                    userCancelamento: "Cancelación de usuario",
                    motivoCancelamento: "Motivo de la cancelación",
                    obsCancelamento: "Aviso de cancelación",
                    itensManipu: "Elementos de manipulación",
                    item: "Artículo",
                    qtde: "Cantidad",
                    semMovimentoManipulacao: "¡No hay movimiento de stock de esta manipulación!",
                    reverteLoteCriado:
                        "¡Atención! Al cancelar la manipulación, la cantidad generada del lote producido se revertirá",
                },

                detalhesitem_vue_html: {
                    comissaoPrescritor: "Comisión de Prescripción: ",
                    comissaoVisitador: "Comisión Visitante: ",
                    comissaoAtendente: "Comisión de Asistente: ",
                },

                edit_vue_html: {
                    manipuEdit: "Manipulación - Edición",
                    manipuCod: "Manipulación código ",
                    edit: " - Edición",
                    desfazer: "Deshacer",
                    manipuJaConcluida: "¡La manipulación ya está completa! No puede cambiar los datos. ",
                    manipuCancelada: "¡Manipulación cancelada! No puede cambiar los datos. ",
                    situacao: "Situación",
                    codigo: "Código",
                    numeroFormulas: "Fórmulas numéricas",
                    formaFarmaceu: "Forma farmacéutica",
                    molde: "Molde",
                    tamPlaca: "tamaño del tablero",
                    volDose: "Volumen",
                    qtdTotal: "Cant. Total",
                    volTotal: "Volumen total",
                    consolidaInfos:
                        "Consolida información después de insertar activos para búsquedas automáticas de Cápsulas, Envases y validaciones de inventario",
                    consolidarItens: "Consolidar elementos",
                    todosItensDevemTerProdutoInformado:
                        "Todos los artículos de manipulación deben tener su producto informado!",
                    confEstqAjusta: "Verificar existencias y ajustar cantidades y lotes cuando sea necesario",
                    lotesEstq: "Lotes / Stock",
                    detalsDeCustos: "Detalles de costos",
                    detalsCusto: "Detalles de costo",
                    valores: "Valores",
                    valorFormFarma: "Valor Forma Farm.",
                    valorProds: "Valor Produtos",
                    acrescimo: "Adición",
                    acrescimoPercentual: "Adición (%)",
                    desconto: "Descuento",
                    descontoPercentual: "Descuento (%)",
                    valorTotal: "Valor total",
                    utilizMinForma: "Utilizando el valor mínimo de la forma farmacéutica",
                    itensManipu: "Itens de Manipulación",
                    complementosManipu: "Complementos de Manipulación",
                    dtVal: "Fecha de vencimiento",
                    posologia: "Posología",
                    editPosologia: "Editar posología",
                    novaPosologia: "Nueva Posología",
                    paciente: "Paciente",
                    editPaciente: "Editar paciente",
                    novoPaciente: "Nuevo paciente",
                    prescritor: "prescripción",
                    editPrescritor: "Editar Prescriptor",
                    novoPrescritor: "Nuevo Prescriptor",
                    obs: "Observación",
                    continuo: "Continuo",
                    nroReceita: "Número de ingresos",
                    dataValidadeReceita: "Fecha de vencimiento de la receta",
                    salvarAlt: "Guardar cambio",
                    add: "Agregar",
                    concluir: "Finalizar",
                    movimentacaoEstq: "Movimiento de stock",
                    imprimirModel: "Imprimir desde modelo",
                    voltar: "Atras",
                    atualizDadosLotes: "Actualizar datos de lotes",
                    preVenda: "Preventa",
                    mensagemHabilitaAlteracao:
                        "¡Manipulación con origen en la preventa! Cambio no habilitado en la fórmula estándar.",
                    nomeVendedor: "Nombre del Vendedor",
                    usaMarkupEquinos: "Usar marcado para equinos",
                    multiplicarNumeroDose: "Multiplica el número de porciones",
                    mensagemMultiplicarNumeroDose:
                        "Al informar al multiplicador, este se utilizará para romper el número total de dosis, si se indica forzar la multiplicación, el sistema buscará el molde más adecuado, de lo contrario la rotura no será considerada en la búsqueda de molde",
                    forcarMultQuantidade: "Fuerza de multiplicación",
                    fatorMultQuantidade: "Factor de multiplicación",
                    volumeObrigatorio: "Informar el volumen antes de consolidar la manipulación",
                    quantidadeIgualAZero: "No puede tener una cantidad igual a cero",
                    quantidadeObrigatoria: "Informar la cantidad antes de consolidar el manejo",
                    obsUsuario: "Observación del usuario",
                    msgPrescritorObs: "Atención, este prescriptor tiene una nota",
                    observacoes: "Comentarios",
                    numeroFormulasMaiorQueZero: "Informar el número de fórmulas",
                    naoFoiInformadoRecipienteQSP: "No se informó ningún contenedor QSP en el manejo",
                    numeroReceitaDeveSerInformadoContinuo:
                        "Se debe informar la fecha de caducidad de la prescripción, ya que la manipulación contiene productos continuos",
                    numeroReceitaDeveSerInformadoControlado:
                        "Se debe informar la fecha de caducidad de la prescripción, ya que la manipulación contiene productos controlados",
                    ativosDuplicado:
                        "Los activos {produto1} y {produto2} son el mismo producto. No será posible continuar, ajuste la manipulación.",
                    manipulador: "Manipulador",
                    oCalculoVolume:
                        "El cálculo del volumen de activos se basa en el grupo de materias primas y los subgrupos Activos y Aromatizantes",
                    dataValidadeManipulacao: "Fecha de vencimiento de la manipulación",
                },

                item_vue_html: {
                    visuProduto: "Ver producto",
                    vlrCadaDoseUnMedIguais:
                        "La dosis de volumen es el valor de cada dosis en unidades de medida igual a la unidad de producto",
                },

                itemTable_vue_html: {
                    kitEmbalagem: "Kit de embalaje",
                    lote: "Lot",
                    addAtivo: "Agregar activo",
                    add: "Agregar",
                    addFormBase: "Agregar base de fórmula",
                    addKitEmb: "Agregar kit de empaquetado",
                    addCapsula: "Agregar cápsula",
                },

                list_vue_html: {
                    manipuVenda: "Manejo de venta",
                    manipu: "Manipulación",
                    cadastradas: "Registrado",
                    de: "Fecha de Finalización de:",
                    data: "Fecha:",
                },

                listFormulaPadrao_vue_html: {
                    manipuFrmlPadrao: "Manipulación de fórmulas estándar",
                    manipu: "Manipulación",
                    cadastradas: "registrado",
                    statusSituacao: "Situación",
                    estoque: "Existencias",
                },

                loteitem_vue_html: {
                    produto: "Producto",
                    produtoParametNaoControlEstq: "Producto parametrizado para no controlar stock",
                    lote: "Lot",
                    qntdEstoque: "Cantidad en stock",
                    qntdBaixa: "Cantidad de arrendamiento",
                    val: "Validez",
                    status: "Estado",
                    saldo: "Balance:",
                    total: "Total:",
                    extra: "Extra:",
                    loteManipulacaoItemTooltip: "El primer lote se guardará en el artículo de la orden de manipulación",
                },
            },

            navmenu: {
                abertura_de_caixa: "Caja de apertura",
                atualizacao_valores: "Actualización de valor",
                atualizacao_valores_produto: "Actualización del producto",
                atualizacao_valores_forma_farmaceutica: "Actualización del Formas Farmacéuticas",
                bancos: "Bancos",
                bandeira: "Bandera",
                beneficio: "Beneficio",
                cadastros: "Inscripciones",
                campanhas: "Campañas",
                capacidade_producao: "Capacidad producción",
                capsulas: "Cápsulas",
                categoria_controle_reclamacao: "Categoría",
                cests: "CESTs",
                classificacao_de_conta_gerencial: "Clasificación de cuentas gerenciales",
                cliente: "Cliente",
                clientes: "Clientes",
                clientes_duplicacoes: "Clientes",
                prescritores_duplicacoes: "Prescriptores",
                colaboradores: "Colaboradores",
                configuracao: "Configuración",
                configuracao_dotz: "Configuración Dotz",
                configuracoes_da_franquia: "Configuración de franquicia",
                configuracoes_de_usuario: "Configuración de usuario",
                configuracao_whatsapp: "Configuración de WhatsApp",
                contas_a_pagar: "Cuentas a pagar",
                contas_a_receber: "Cuentas por cobrar",
                contas_correntes: "Cuentas corrientes",
                contas_gerenciais: "Cuentas de gestión",
                contas_pagas: "Cuentas pagadas",
                contas_recebidas: "Cuentas recibidas",
                controle_reclamacao: "Control de quejas",
                convenio: "Convenio",
                cores: "Colores",
                cupom: "Cupón",
                dados_adicionais_ficha_tecnica: "Datos adicionales Registro técnico",
                dashboard: "Tablero",
                dashboards: "Dashboards",
                dashboard_evolucao_de_vendas: "Evolución de Ventas",
                dashboard_manipulacoes: "Manipulaciones",
                dashboard_totais_categoria: "Totales por Categoría",
                devolucoes: "Devoluciones",
                dcbs: "DCB",
                dcis: "DCIs",
                dotz: "Dotz",
                duplicacoes: "Duplicaciones",
                ensaios: "Pruebas",
                especialidades: "Especialidades",
                especie_de_animal: "Especies animales",
                estoque: "Stock",
                estoques: "Stocks",
                etapa_pcp: "Paso de PCP",
                farmacopeia: "Farmacopea",
                fechamento_de_caixa: "Cierre de caja",
                ficha_tecnica: "Registro técnico",
                financeiro: "Financiero",
                fiscal: "Supervisor",
                fluxo_de_caixa: "Flujo de caja",
                formas_de_pagamento: "Métodos de pago",
                formas_de_recebimento: "Formas de recibo",
                formas_farmaceuticas: "Forms Pharmaceuticals",
                formula_padrao: "Fórmula estándar",
                formulas_padrao: "Fórmulas estándar",
                fornecedores: "Proveedores",
                franquias: "Franquicias",
                grupos: "Grupos",
                grade_pcp: "Cuadrícula PCP",
                status_pcp: "Estado del PCP",
                historico: "Histórico",
                historico_envios: "Historial de Envíos",
                importacao_nfe: "importación NFe",
                inutilizacao_nota_fiscal: "Desactivación de facturas",
                download_xmls_nota_fiscal: "Descargar facturas XML",
                emissao_nota_saida_lote: "Emisión de facturas por lotes",
                geracao_siproquim: "SIPROQUIM",
                inventario: "Inventario",
                justificativa_controle_reclamacao: "Justifications",
                kit_embalagem: "Kit de embalaje",
                listas_de_controlado: "Listas controladas",
                localentrega: "Lugares de Entrega",
                lotes: "Lotes",
                curva_abc: "Curva ABC",
                curva_abc_produto: "Productos",
                curva_abc_prescritores: "Prescriptores",
                manipulacao: "Manipulación",
                manipulacoes: "Manipulaciones",
                manipulacoes_venda: "Venta",
                policia_civil: "Policía Civil",
                metas: "Objetivos",
                mapa_siproquim: "Mapa (SIPROQUIM)",
                metodos_de_pagamento_recebimento: "Métodos de pago/ recibo",
                modelos_de_impressao: "Plantillas de impresión",
                moldes: "Moldes",
                motivos_cancelamento: "Razones para la cancelación",
                motivo_rejeicao: "Razones del rechazo",
                motivo_movimentacao_estoque: "Razones del movimientos",
                motivo_etapa_pcp: "Razones del Paso PCP",
                movimentacoes_de_caixa: "Movimientos de efectivo",
                movimentacoes_de_conta: "Movimientos de cuenta",
                movimentacoes_de_estoque: "Movimientos de inventario",
                movimentacoes_pendentes: "Transacciones Pendientes",
                naturezas_de_operacao: "Operación naturalezas",
                nbms: "NBMs",
                ncms: "NCM",
                nfe_danfe_saida: "Notas emitidas",
                nota_fiscal_de_entrada: "Nota Fiscal de Entrada",
                nota_fiscal_de_saida: "Nota Fiscal de Outgoing",
                operadora_de_cartoes: "Operador de tarjeta",
                contas_a_receber_parceladas: "En cuotas",
                operados_de_cartao: "Operadores de tarjetas",
                orcamentos: "Presupuestos",
                pacientes: "Pacientes",
                pcp: "PCP",
                pdv: "PDV",
                posologias: "Posologías",
                prescritores: "Prescriptores",
                produto: "Producto",
                produtos: "Productos",
                programa_fidelidade: "Programa de Fidelidad",
                racas_animais: "Razas de Animales",
                pontuar: "Puntuar",
                pontuar_manual: "Puntuación manual",
                compras: "Compras",
                recebimento_parcelado: "Métodos de recepción de pagos a plazos",
                recompensas: "Recompensas",
                relatorios: "Informes",
                rotulo: "Etiqueta",
                servicos: "Servicios",
                servicos_fiscais: "Servicios de impuestos",
                sngpc: "SNGPC",
                sngpc_manual: "SNGPC_Manual",
                telas: "Pantallas",
                telas_acoes: "Acciones de pantalla",
                transportador: "Transportista",
                transportadora: "Transportista",
                unidades_de_medida: "Unidades de medida",
                usuarios: "Usuarios",
                venda: "Venta",
                vendas: "Ventas",
                visitador: "Visitante",
                caixa: "Caja",
                livro_registro_especifico: "Libro de Registro Específico",
                balanco_completo: "Balance completo",
                veterinario: "Veterinario",
                dinamizacao: "Dinamización",
                estoque_minimo: "Stock mínimo",
                vendas_entregas: "Entregas",
                expedicao: "Expedición",
                coleta: "Colecciones",
                declaracao_conteudo: "Declaración de contenido",
                suporte: "Soporte",
                whatsapp: "WhatsApp",
                tomticket: "TomTicket",
                chatbot: "Chatbot",
                controle_especial: "Control especial",
                manipuladores: "Manipuladores",
                etiqueta: "Etiquetas",
                condicao: "Condiciones",
            },

            nfe: {
                edit_vue_html: {
                    nfeEdit: "NF-e - Edition",
                    dadosNfe: "datos NF-e",
                    operacao: "Operación",
                    dtEmissao: "Fecha de emisión",
                    dtHraEntradaSaida: "Fecha y hora de entrada / salida",
                    finalidadeEmissao: "Propósito de la emisión",
                    indicadorPresenca: "Indicador de presencia",
                    indicativoIntermedio: "Indicador de intermedio",
                    tipoEmissao: "Tipo de emisión",
                    fornecedor: "Proveedor",
                    cliente: "Cliente",
                    cfop: "CFOP",
                    itens: "Artículos",
                    obs: "Observaciones",
                    transportadora: "Transportista",
                    modalFrete: "Modo de envío",
                    cnpj: "CNPJ",
                    endereco: "Dirección",
                    cidade: "Ciudad",
                    uf: "UF",
                    ie: "I.E.",
                    placaVeiculo: "Placa de matrícula del vehículo",
                    salvarEEmitir: "Guardar e emitir",
                    consultaEmissao: "Consultar problema",
                    cartaCorrecao: "Generar carta de corrección",
                    consultarCartaCorrecao: "Consultar última carta de corrección",
                    abrirPDF: "Abrir PDF",
                    abrirPDFSimplificado: "Abrir PDF simplificado",
                    abrirXML: "Abrir XML",
                    movimentaEstoque: "Mover stock",
                    estoque: "Stock",
                },

                item_vue_html: {
                    produto: "Producto",
                    lote: "Lote",
                    infoAdicional: "Información adicional",
                    EAN: "EAN (Codigo de barras)",
                    unMed: "Unidad de medida",
                    qtde: "Cantidad",
                    ncm: "NCM",
                    vlrUnitario: "Valor unitario",
                    desconto: "Descuento",
                    frete: "Flete",
                    seguro: "Seguro",
                    outrasDespesas: "Otros gastos",
                    vlrTotal: "Importe total",
                },

                list_vue_html: {
                    nfe: "NF-e",
                    nf: "Facturas",
                    emitidas: "Emitido",
                    NFE: "NFE",
                },
            },

            notafiscalentrada: {
                edit_vue_html: {
                    nfEntradaEdit: "Factura de entrada - Edición",
                    dataNfEnrtada: "Datos de factura entrante",
                    estqDestino: "Stock de destino",
                    dtEmissao: "Fecha de emisión",
                    dtInclusao: "Fecha de inclusión",
                    vlrSeraAtualizVlrNota:
                        "Si se marca la opción y el valor unitario del artículo del billete es mayor que el valor de costo de referencia del producto, se actualizará al valor derivado del billete.",
                    atualizarCustos: "Costos de actualización",
                    gerarCntPagar: "Generar cuenta por pagar",
                    nroNF: "Número de nota fiscal",
                    serie: "Série",
                    chave: "Clave de acceso",
                    fornecedor: "Proveedor",
                    editFornecedor: "Editar proveedor",
                    novoFornecedor: "Nuevo proveedor",
                    transportadora: "Transportista",
                    itens: "Artículos",
                    financeiro: "Financiero",
                    totais: "Totales",
                    vlrFrete: "Valor de envío",
                    vlrIPI: "Valor de IPI",
                    vlrTotalProd: "Valor total de productos",
                    vlrDescontos: "Value Discounts",
                    vlrTotal: "Importe total",
                    vlrTotalFaturado: "Importe total facturado",
                },

                financeiro_vue_html: {
                    descrDuplicata: "descripción duplicada",
                    dtVenc: "Fecha de vencimiento",
                    contaGerencial: "Cuenta de administración",
                    status: "Estado",
                    dadosPgto: "Datos de pago",
                    dtPgto: "Fecha de pago",
                    formPgto: "Método de pago",
                    contaCorrente: "Cuenta corriente",
                },

                item_vue_html: {
                    produto: "Producto",
                    codProd: "Código de producto",
                    lote: "Lote",
                    editProdLote: "Editar lote de productos",
                    novoProdLote: "Nuevo lote de productos",
                    unidadeMedidaOrigem: "Origen de la unidad de medida",
                    quantidadeOrigem: "Cantidad Origen",
                    valorUnitarioOrigem: "Origen del valor unitario",
                    quantidadeConvertida: "Cantidad convertida",
                    valorUnitarioConvertido: "Valor unitario convertido",
                    vlrTotal: "Importe total",
                    valorFrete: "Importe del Flete",
                    valorSeguro: "Importe Seguro",
                    valorOutraDespesa: "Importe Otros Gastos",
                    valorDesconto: "Importe del Descuento",
                    tlpQuantidadeConvertida: "Cantidad Origen con índice de conversión aplicado",
                    tlpValorUnitarioOrigem: "Valor bruto del elemento de factura",
                    tlpValorTotal: "Valor Líquido calculado",
                    tlpValorUnitarioConvertido: "El valor unitario del movimiento de las acciones tendrá en cuenta",
                },

                list_vue_html: {
                    nfEntrada: "Facturas entrantes",
                    cadastradas: "Registrado",
                    nf: "Facturas",
                },
            },

            notafiscalsaida: {
                edit_vue_html: {
                    nfSaidaEdit: "Factura de salida - Edición",
                    dadosNfSaida: "Datos de facturas salientes",
                    statusEmissao: "Estado de emisión",
                    tipoNF: "Nota Fiscal Type",
                    motivoCancel: "Motivo de la cancelación",
                    nro: "Número",
                    dtEmissao: "Fecha de emisión",
                    dtInclusao: "Fecha de inclusión",
                    serie: "Serie",
                    chaveBusca: "Clave de búsqueda",
                    codVerif: "Código de verificación",
                    chave: "Llave",
                    servico: "Servicio",
                    prods: "Productos",
                    totais: "Totales",
                    baseCalculo: "Base de cálculo",
                    vlrServico: "Valor del servicio",
                    vlrISS: "Valor ISS",
                    vlrTotalServicos: "Valor total de los servicios",
                    baseCalculos: "Base de cálculo",
                    vlrProds: "Valor del producto",
                    valorICMS: "Valor de ICMS",
                    vlrTotal: "Valor total",
                    consultEmissao: "Consultar problema",
                    cancelarEmissao: "Cancelar problema",
                    consultEmissaoCancelamento: "Consultar problema de cancelación",
                    altDadosNF: "Cambiar datos de factura",
                    cartaCorrecao: "Generar carta de corrección",
                    consultarCartaCorrecao: "Consultar última carta de corrección",
                    abrirPDF: "Abrir PDF",
                    abrirPDFSimplificado: "Abrir PDF simplificado",
                    abrirXML: "Abrir XML",
                },

                list_vue_html: {
                    nfSaida: "Facturas salientes",
                    nf: "Facturas",
                    cadastradas: "Registrado",
                    status: "Nota de estado:",
                    tipoNota: "Tipo de nota:",
                    de: "Desde:",
                    ate: "Hasta:",
                    todos: "Todo",
                },
            },

            printModel: {
                campo_vue_html: {
                    nomeCampo: "Nombre de campo",
                    mostrarNomeCampo: "Mostrar nombre de campo",
                    seMarcNaoMostra: "Si no está marcado, no muestra el nombre del campo impreso",
                    origemCampoComplemen: "Origen del campo de complemento",
                    separOndeOriginaInfo: "Separación de donde se origina la información",
                    campoComplemen: "Campo de complemento",
                    utilizOutroCampoComplemen: "Use otro campo para complementar el valor de este campo",
                    complemenCampo: "Complemento de campo",
                    posComplemen: "Posición de complemento",
                    posComplemenRelacaoVlr: "Posición del complemento en relación con los datos del valor impreso",
                    txtNegrito: "Texto en negrita",
                    corTxt: "Color del texto",
                    corFundoTxt: "Color de fondo del texto",
                    origCampoSubstituto: "Origen del campo sustituto",
                    primeiroNomeCampoSubstituto: "Campo sustituto del nombre",
                    campoSubstituto: "Campo sustituto",
                    campoSubstituiInfos: "Campo que reemplaza la información del campo si no tiene valores",
                    utilizLimiteChar: "Usar límite de caracteres",
                    utilizNroLimiteCharVlrCampo: "Use un número limitado de caracteres para el valor del campo",
                    maxChar: "Caracteres máximos",
                    alinTxt: "Alineación de texto",
                    primeiroNome: "Primer nombre",
                    apresTxtAtePrimEspaco: "Muestra texto hasta el primer espacio",
                    quebLinha: "Línea de ruptura",
                    qndoLimitMaxCharLinhaAbaixo:
                        "Cuando está limitado al número máximo de caracteres, puede optar por continuar el texto en la línea de abajo",
                    personalizado: "Personalizado",
                    identificador: "Identificador",
                    exibirCampoBranco: "Campo en blanco",
                    exibirCampoBrancoTooltip:
                        "Al marcar esta opción, cuando el campo tenga valor cero, se mostrará en blanco",
                },

                condicao_vue_html: {
                    condImpressao: "Condición de impresión",
                    origemCampo: "Origen del campo",
                    separOndeOriginInfo: "Separación de donde se origina la información",
                    campoComparar: "Campo para comparar",
                    vlrComparar: "Valor para comparar",
                },

                edit_vue_html: {
                    modelImpressEdit: "Plantilla de impresión - Edición",
                    dadosImpress: "Imprimir datos",
                    descrSessao: "Descripción de sesión",
                    imprimirDesc: "Imprimir descripción",
                    ordem: "Orden",
                    auxOrdenacaoSessoes: "Ayuda a ordenar sesiones",
                    tipoDado: "Tipo de datos",
                    tipoDadoCampo: "Tipo de datos de campo, las sesiones deben estar separadas por tipo de datos",
                    linhas: "Líneas",
                    tiposLinhasTab: "Tipos de filas de tabla",
                    ativo: "Activo",
                    maxCharLinha: "Máximo de caracteres por línea",
                    limitaCharLinha: "Limita el número de caracteres por línea",
                    maxLinhas: "Líneas máximas",
                    limitInfosCampos: "Limita la información del campo a x número de líneas",
                    corpoImpress: "Cuerpo de impresión",
                    separOriginaInfo: "Separación de donde se origina la información",
                },

                list_vue_html: {
                    modeloImpress: "Plantilla de impresión",
                    cadastrados: "registrado",
                },

                sessao_vue_html: {
                    modelImpressEdit: "Plantilla de impresión - Edición",
                    descricao: "Descripción",
                    padrao: "Patrón",
                    tamanhoFonte: "Tamaño de fuente",
                    fonte: "Fuente",
                    modeloPreDefinido: "Plantilla predefinida",
                    modeloPreDefinidoTooltip:
                        "La plantilla personalizada respetará la configuración de esta plantilla de impresión; de lo contrario, utilizará el PDF predefinido sin posibilidad de cambio.",
                    sessoesModelImpress: "Sesiones de plantillas de impresión",
                    condicoes: "Condiciones",
                    usuarios: "Usuarios",
                    tipoModelo: "Tipo Modelo",
                },
            },

            relatorio: {
                list_vue_html: {
                    obsoleto: "obsoleto",
                    relatorios: "Informes",
                    relatVendas: "INFORMES DE VENTAS",
                    relaNotas: "INFORMES DE NOTAS",
                    relaFaturamentos: "INFORMES DE FACTURACIÓN",
                    relaOrcamentos: "INFORMES DE PRESUPUESTO",
                    relaComissao: "INFORMES DE COMISIÓN",
                    relatCustoVenda: "INFORME DE COSTO DE VENTA",
                    relatCustoVendaFracionamento: "INFORME DE COSTO DE VENTA DE FRACCIONAMIENTO",
                    relatProdSemVenda: "INFORME DE VENTA DE PRODUCTO",
                    relatVendaXPrescr: "INFORME LA CANTIDAD DE VENTAS POR RECETA",
                    relatVendaComDesc: "INFORME DE VENTA DE DESCUENTO",
                    relatPrescrDiasSemPrescricoes: "INFORME DE RECETAS: DÍAS SIN RECETAS",
                    relatVendaPorCliente: "INFORME DE VENTAS POR CLIENTE",
                    relatVendasPorEspecie: "INFORME DE VENTAS POR ESPECIE",
                    relatVendasPorPrescr: "INFORME DE VENTAS POR PRESCRITOR",
                    relatVendasPorProds: "INFORME DE VENTAS POR PRODUCTOS",
                    relatVendasPorProdsClientes: "INFORME DE VENTAS POR PRODUCTOS Y CLIENTES",
                    relatVendasPorTipoPgto: "INFORME DE VENTAS POR TIPO DE PAGO",
                    relatVendasPorVendedor: "INFORME DE VENTAS POR VENDEDOR",
                    relatVendasPorVisitador: "INFORME DE VENTAS POR VISITANTE",
                    relatFatuamentoFranquia: "INFORME DE FACTURACIÓN DE FRANQUICIA",
                    relatFatuamentoFracionamento: "INFORME DE FACTURACIÓN DEL FRACCIONAMIENTO",
                    relatFaturamento: "INFORME DE FACTURACIÓN",
                    relatComissaoVisitadorSobreVendas: "INFORME DE VENTAS DEL COMITÉ DE VISITANTES",
                    relatComissaoVendedorSobreVendas: "INFORME DE VENTAS DEL COMITÉ DE EMPLEADO",
                    relatOrcamentosRejeitados: "INFORME DE PRESUPUESTOS RECHAZADOS",
                    relatVendasPorTransportador: "INFORME DE VENTAS POR TRANSPORTISTA",
                    relatVendasSemNota: "INFORME DE VENTAS SIN NOTA",
                    relatVendasSemFaturamento: "INFORME DE VENTAS SIN FACTURACIÓN",
                    relatRegistroReceituarioGeral: "REGISTRO GENERAL DE RECIBO",
                    relatsProdutos: "INFORMES DE PRODUCTOS",
                    relatContinuos: "INFORME CONTINUO",
                    relatRecompensaUtilizadaCampanha: "INFORME DE PUNTUACIÓN/RECOMPENSAS",
                    relatDadosGeraisProduto: "INFORME DE DATOS GENERALES DEL PRODUCTO",
                    relatCustoMedioProdutoVendido: "INFORME DE COSTO MEDIO DE MERCANCÍAS VENDIDAS",
                    relatsGerenciais: "INFORMES DE GESTIÓN",
                    relatCustoMarkupProds: "INFORME DE COSTE Y MARKUP DE PRODUCTOS",
                    relatsFinanceiro: "INFORMES FINANCIEROS",
                    relatContasAPagar: "REPORTACCOUNTS PAYABLE",
                    relatContasAReceber: "REPORTAR CUENTAS POR COBRAR",
                    relatContasPagas: "INFORME de PaysAccounts",
                    relatContasRecebidas: "INFORME DE CUENTAS RECIBIDAS",
                    relatFluxoCaixa: "INFORME DE FLUJOS DE EFECTIVO",
                    relatProdutosFinanceiro: "INFORME DE PRODUCTOS FINANCIEROS",
                    relatEstoque: "INFORMES DE EXISTENCIAS",
                    relatMovimentacaoLote: "INFORME DE MOVIMIENTO DE LOTE",
                    relatLotes: "INFORME DE LOTES",
                    relatComprasProduto: "INFORME DE COMPRA DE PRODUCTOS",
                    relatVendasCompromentendoLote: "INFORME DE VENTAS COMPROMISO LOTE",
                    relatSaldoProdutos: "INFORMES DE SALDO DE PRODUCTOS",
                    relatPrescritores: "INFORME DE PRESCRIPTORES",
                    relatCobranca: "INFORME DE CARGA",
                    relatProdutoVendidosDrogavet: "INFORME DE PRODUCTOS VENDIDOS DROGAVET",
                    outros: "OTROS",
                    relatAniversario: "INFORME DE ANIVERSARIO",
                    relatorioPontuacoesPorPrescritor: "INFORME DE PUNTUACIÓN DOTZ POR EL PRESCRIPTOR",
                    relatorioAberturasCaixa: "INFORME DE APERTURA DE CAJA",
                    relatorioPCPEtapas: "PASOS PARA INFORMAR EL ESTADO DEL PCP",
                    relatorioProdutosControlados: "INFORME SOBRE PRODUCTOS CONTROLADOS (SIPROQUIM)",
                    relatorioConsumoProdutoPorFranquia: "INFORME DE CONSUMO DE PRODUCTO POR FRANQUICIA",
                    relatorioVendasPorFormaFarmaceutica: "INFORME DE VENTAS PARA FORMAS FARMACÉUTICAS",
                    relatorioNotasSaida: "INFORME DE NOTAS DE SALIDA",
                    relatorioEvolucaoVendas: "INFORME DE EVOLUCIÓN DE LAS VENTAS",
                    relatorioVendasPorPeriodo: "INFORME DE VENTAS PARA PERÍODO",
                    relatorioProdutosMaisVendidos: "INFORME PRODUCTOS MÁS VENDIDOS",
                    relatorioVendaPorCidade: "INFORME DE VENTAS POR CIUDAD",
                    relatorioVendaComTroco: "INFORME DE VENTAS CON CAMBIO",
                    relatorioVendaComCupom: "INFORME DE VENTAS CON CUPÓN",
                    relatorioTaxaEntrega: "INFORME DE TARIFAS DE ENVÍO",
                    relatorioOrcamento: "INFORME DE PRESUPUESTO",
                    relatorioControleReclamacao: "INFORME DE CONTROL DE RECLAMACIONES",
                    relatorioVendasPorColaborador: "INFORME DE VENTAS POR COLABORADOR",
                    relatorioVendasPorEstoque: "INFORME DE VENTAS POR STOCK",
                    relatorioAcompanhamentoFranqueadora: "INFORME DE SEGUIMIENTO DEL FRANQUICIADOR",
                },
            },

            sessaoCaixa: {
                aberturaCaixa_vue_html: {
                    pontoVenda: "Punto de venta",
                    saldoInicial: "Saldo inicial",
                    gerarDifSaldoInicial: "Generar diferencia de saldo inicial",
                    formaRecebiTransferencia: "Transfer Receipt Form",
                    formaRecebiOrigemDifCaixa:
                        "Forma de Recibo cuyo origen es diferente al cajero de la Sesión abierta",
                    contaCorrenteDestino: "Cuenta corriente de destino",
                    confirmarAbertura: "Confirmar abierto",
                },

                fechamentoCaixa_vue_html: {
                    fechamentoCaixa: "Cierre de caja",
                    movimentacoesCaixa: "Transacciones en efectivo",
                    realizadas: "Cumplido",
                    saldoInicial: "Saldo inicial",
                    saldoAtual: "Saldo actual",
                    somaEntradas: "Suma de entradas",
                    somaSaidas: "Suma de salidas",
                    somaDiferenc: "Suma de diferencias",
                    totalSaldoGeral: "Total General Balance",
                    somaEntradasSubtraindoSaidas: "Suma de todas las entradas restando todas las salidas",
                    resumoCaixa: "Resumen del cajero",
                    dtHraAbertura: "Fecha / hora de apertura",
                    dtHoraFechamento: "Fecha / Hora de cierre",
                    saldoFechamento: "Saldo final",
                    sangria: "Sangrías",
                    reforcos: "Refuerzos",
                    movimentacoesDinheiro: "Movimientos de Caja",
                    movimentacoesCredito: "Movimientos de Crédito",
                    movimentacoesDebito: "Movimientos de Débito",
                    movimentacoesBoleto: "Movimientos de Boleto",
                    movimentacoesTransferencia: "Movimientos de Transacción",
                    movimentacoesCheque: "Operaciones con Cheques",
                    anterior: "Anterior",
                    proximo: "Siguiente",
                    concluir: "Finalizar",
                },

                listMovimentosCaixa_vue_html: {
                    movimentacoesCaixa: "Transacciones en efectivo",
                    realizadas: "Cumplido",
                    saldoInicial: "Saldo inicial",
                    saldoAtual: "Saldo actual",
                    gerarReforco: "Generar refuerzo",
                    gerarSangria: "Generate Sangria",
                },

                listMovimentacoesPendente_vue_html: {
                    movimentacoesPendente: "Transacciones pendientes",
                    cadastradas: "Registrado",
                    reprocessar: "Reprocesar",
                },

                listCaixa_vue_html: {
                    sessaoCaixa: "Sesión de caja",
                    fechamentoCaixa: "Cierre de caja",
                },

                reforco_vue_html: {
                    descricao: "Descripción",
                    saldoAtual: "Saldo actual",
                    valorReforco: "Valor de refuerzo",
                    totalCalculado: "Total calculado",
                    contaCorrente: "Cuenta corriente",
                    frmRecebimentoTransf: "Formulario de recibo de transferencia",
                    tooltipFormaRecebimento: "Forma de Recibo cuyo origen es DIFERENTE al cajero de Sesión Abierta",
                },

                sangria_vue_html: {
                    descricao: "Descripción",
                    saldoAtual: "Saldo actual",
                    sangría: "Retiro de Efectivo",
                    saldoCalculado: "Saldo calculado",
                    saldoPermanPDV: "Saldo que quedará en el TPV",
                    contaCorrenteDestino: "Cuenta corriente de destino",
                    frmRecebimentoTransf: "Formulario de recibo de transferencia",
                    tooltipContaCorrente: "Cuenta Corriente que no es lo mismo que el cajero de Sesión Abierta",
                    tooltipFormaRecebimento: "Forma de Recibo cuyo origen es IGUAL al cajero de Sesión Abierta",
                },
            },

            atajo: {
                shortcut_vue_html: {
                    addNovo: "Agregar nuevo",
                },
            },

            siproquim: {
                edit_vue_html: {
                    siproquimGeracao: "Siproquim - Generacion",
                    dataInicial: "Fecha de inicio",
                    dataFinal: "Fecha final",
                    geraCadastroProdutos: "Generar registro de registro de producto (PR)",
                    gerarArquivo: "Generar Archivo",
                },
            },

            sngpc: {
                list_vue_html: {
                    sngpc: "SNGPC",
                    gerados: "Generado",
                    gerarNovoRelatorio: "Generar Nuevo Informe",
                    gerarInventario: "Gerar Novo Inventário",
                    visuProdutos: "Ver productos",
                    confirmaGeracaoInventario:
                        "ATENÇÃO. Confira no site da ANVISA as situações para criação de um novo inventário. Confirma a geração de um novo inventário?",
                    dataInicio: "Fecha de inicio",
                    dataFinal: "Fecha final",
                },

                sngpc_vue_html: {
                    funcionalidadeEmDesenvol: "Esta funcionalidad está en desarrollo",
                    aguardeProxAtualiz: "Espere la próxima actualización antes de poder acceder a ella",
                    duvidasEntrarContato: "Si tiene alguna pregunta, por favor contáctenos",
                },

                ver_vue_html: {
                    sngpcProds: "Productos SNGPC",
                },
            },

            statusPCP: {
                view_vue_html: {
                    statusPCP: "Estado del PCP",
                    etapa: "Etapa",
                    venda: "Venta",
                    dataInicial: "Fecha inicial",
                    dataFinal: "Fecha final",
                    codigo: "Codigo",
                    pcpRapido: "PCP rapido",
                },
            },

            topmenu: {
                topmenu_vue_html: {
                    suporte: "Soporte",
                    cidade: "Ciudad",
                    caixaAberto: "Caja Abierto",
                    caixaFechado: "Caja Cerrado",
                    notificacoes: "Notificaciones",
                    semNotificacoesRecentes: "No hay notificaciones recientes",
                    novas: "Nuevo",
                    marcarTodasComoLidas: "Marcar todo como leído",
                    marcarComoLido: "Marcar como leído",
                    antigos: "Viejo",
                    marcarComoNaoLido: "Marcar como no leído",
                    verMais: "Ver más",
                    alterarSenha: "Cambiar contraseña",
                    sair: "Cerrar sesión",
                    alterarIdioma: "Cambiar Idioma",
                    notasDaVersao: "Notas de la versión",
                    emailNotasDaVersao: "Versión Correo electrónico",
                    logLevel: "Log Level",
                    ambienteDesenvolvimento: "Entorno de Desarrollo",
                    ambienteHomologacao: "Entorno de Homologación",
                    ambienteTrainamento: "Entorno de Capacitación",
                    statusSistema: "Estado del Sistema",
                },
            },

            venda: {
                cancelamento_vue_html: {
                    saleCancelCode: "Venta - Cancelación - Código: ",
                    vendaJaCancelada: "¡La venta ya está cancelada! No puede cambiar los datos. ",
                    selecionaItensCancelar:
                        "¡Atención! Seleccione todos los elementos que desea cancelar, no podrá cancelar nuevamente después de guardar",
                    dadosVenda: "Datos de ventas",
                    dtVenda: "Fecha de venta",
                    nomeCliente: "Nombre del cliente",
                    vlrTotal: "Importe total",
                    dtCancelamento: "Fecha de cancelación",
                    userCancelamento: "Cancelación de usuario",
                    motivoCancelamento: "Motivo de la cancelación",
                    obsCancelamento: "Aviso de cancelación",
                    itensVenda: "Artículos en venta",
                    item: "Artículo",
                    qtd: "Cantidad",
                    itensManipulCod: "Artículos para manipular bacalao: ",
                    naoHaMovimentEstq: "¡No hay movimiento de acciones en esta venta!",
                    naoFoiRealizEstornoVenda: "¡No hubo reversión de esta venta!",
                    naoHaMovimentoFinanceiro: "¡No hay movimiento financiero para esta venta!",
                },

                edit_vue_html: {
                    vendaEdit: "Venta - Edición",
                },

                editSingle_vue_html: {
                    dtVencimentoContinuoVazio: "Introduzca una fecha de caducidad para continuo",
                    vendaJaConcluida: "¡La venta ya está concluida! No puede cambiar los datos. ",
                    vendaJaCancelada: "¡Oferta cancelada! No puede cambiar los datos. ",
                    origemAtend: "Servicio de origen",
                    tipoVenda: "Tipo de Venta",
                    situacao: "Situación",
                    codigo: "Código",
                    cliente: "Cliente",
                    editCliente: "Editar cliente",
                    novoCliente: "Nuevo cliente",
                    dtAprov: "Aprobación de fecha/hora",
                    dtConclusao: "Fecha de finalización",
                    dtHraEntrega: "Fecha / hora de entrega",
                    continuo: "Contínuos",
                    dtVencimentoContinuo: "fecha de caducidad continuo",
                    origem: "Origen",
                    obs: "Observación",
                    vlrProds: "Productos de valor",
                    taxaEntrega: "Tarifa de envío",
                    acrescimo: "agrego",
                    desconto: "Descuento",
                    descBeneficio: "Descuento de beneficios",
                    vlrFinal: "Valor final",
                    dadosFiscais: "Datos fiscales",
                    emitiNFEAposSalvarConcluir: "Emitir NFS-e automáticamente después de guardar / completar venta",
                    detalhes: "Detalles",
                    permitirFinalSemEmissao: "Permitir finalizar la venta sin emitir una nota",
                    detalhesNFe: "Detalles NF-e",
                    campanha: "Campaña",
                    cpf: "CPF",
                    recompensas: "Recompensas",
                    ptsAcumulados: "Puntos acumulados",
                    ptsCompra: "Puntos de compra",
                    totalPts: "Puntos totales",
                    dadosComplementOrcamento: "Datos presupuestarios complementarios",
                    dtInclusao: "Fecha de inclusión",
                    dtValOrcamento: "Dt Val. Budget",
                    transportadora: "Compañía de Envios",
                    transportador: "Transportista",
                    salvarImprimir: "Guardar e imprimir",
                    aprovOrcamento: "Aprobar presupuesto",
                    emitirNota: "Nota de problema",
                    faturar: "Bill",
                    concluir: "Finalizar",
                    financeiro: "Financiero",
                    enviarOrcamento: "Compartir presupuesto",
                    imprimirOrcamento: "Imprimir presupuesto",
                    receita: "Adjuntar Archivo",
                    expedicao: "Expedición",
                    confirAlteracoes: "Confirmar cambios",
                    novoPgto: "Nuevo pago",
                    selecione: "Seleccionar",
                    pagamentos: "Pagos",
                    nomeVendedor: "Nombre del Vendedor",
                    movimentacaoEstoque: "Movimiento de stock",
                    nomeSeparador: "Nombre del Separador",
                    identificarClienteNF: "Identificar al cliente en la Factura",
                    tlpIdentificarClienteNF: "El cliente debe tener el CPF informado en el registro",
                    localEntrega: "Lugares de entrega",
                    cashback: "CashBack",
                    cashbackAplicar: "Aplicar Descuento",
                    desconcluirVenda: "Deshacer Finalización",
                    estoqueOrigem: "Inventario de franquicias",
                    setor: "Sector",
                    historicoVendas: "Histórico de Vendas",
                    notaTransporte: "Nota de transporte",
                    entrega: "Entrega",
                    gerarCodigoRastreio: "Generar código de seguimiento",
                    obsDesconto: "Nota de descuento",
                    acrescimoDesc: "Adición/Descuento",
                },

                enderecoEntrega_vue_html: {
                    enderecoEntrega: "Dirección de envío",
                    dadosTransporte: "Datos de Transporte",
                    cep: "código postal",
                    estado: "Estado",
                    cidade: "Ciudad",
                    endereco: "Dirección",
                    nro: "Número",
                    bairro: "Barrio",
                    complemento: "Complemento",
                    enderecoPadrao: "Dirección predeterminada",
                    pesoBrutoKg: "Peso Bruto (kg)",
                    pesoLiqKg: "Peso Neto (kg)",
                    volumesUn: "Volúmenes (Un)",
                    transportadora: "Compañía de Envios",
                    transportador: "Transportador",
                    taxaEntrega: "Tarifa de envío",
                    codigoRastreio: "Código de localización",
                },

                estorno_vue_html: {
                    contaGerencial: "Cuenta de administración",
                    frmPgto: "Método de pago",
                    contaCorrente: "Cuenta corriente",
                    vlrPago: "Importe pagado",
                    obs: "Observación",
                },

                item_vue_html: {
                    produto: "Producto",
                    produtoLote: "Lote de producto",
                    qtde: "Cantidad",
                    vlrUnit: "Unidad de valor.",
                    acrescimo: "Adición",
                    desconto: "Descuento",
                    markupProduto: "Marca del producto",
                    vlrTotal: "Importe total",
                    posologia: "Posología",
                    editPosologia: "Editar posología",
                    novaPosologia: "Nueva Posología",
                    paciente: "Paciente",
                    editPaciente: "Editar paciente",
                    novoPaciente: "Nuevo paciente",
                    prescritor: "prescripción",
                    editPrescritor: "Editar Prescriptor",
                    novoPrescritor: "Nuevo Prescriptor",
                },

                itemwrapper_vue_html: {
                    manipulacao: "Manipulación",
                    acabado: "Terminado",
                    fracionamento: "fraccionamiento",
                    homeopatia: "Homeopatía",
                    alterar: "Cambia",
                    add: "Agregar",
                    naoImplementado: "No implementado...",
                    total: "Total : ",
                    infoAdic: "Informaciones Adicionales",
                    continuos: "Continuos",
                    continuo: "Continuo",
                    tipoNF: "Tipo de Factura",
                    movimentaEstq: "Mueve Stock",
                },

                linkPagamento_vue_html: {
                    linkPagamento: "Enlace de pago",
                    gerarLink: "Generar nuevo enlace",
                    copiarAreaTransf: "Copiar al portapapeles",
                },

                list_vue_html: {
                    cadastrados: "Registrado",
                    cadastradas: "Registrado",
                    vendas: "Ventas",
                    tudo: "Todo",
                    aFaturar: "Una factura",
                    faturado: "facturado",
                    concluido: "Listo",
                    cancelado: "Cancelado",
                    de: "Desde:",
                    ate: "Hasta:",
                    manipulacao: "Manipulación",
                    repetirVendas: "Repetir seleccionadas",
                },

                listExpedicao_vue_html: {
                    title: "Ventas en Expedición",
                    finalizar: "Finalizar",
                    setor: "Sector",
                },

                listOrcamento_vue_html: {
                    cadastrados: "Registrado",
                    orcamento: "Presupuesto",
                    orcamentos: "Presupuestos",
                    rejeitados: "Rechazado",
                    ambos: "Ambos",
                    de: "Desde:",
                    ate: "Hasta:",
                    confirmAlteracoes: "Confirmar cambios",
                },

                pagamento_vue_html: {
                    recebimento: "Recibo",
                    dtRecebimento: "Fecha de recepción",
                    contaCorrente: "Cuenta corriente",
                    especie: "Especie",
                    bandeira: "Bandera",
                    frmaRecebimento: "Formulario de recibo",
                    mtdParcelamento: "Método de pago",
                    autorizNSUCartao: "Autorización de tarjeta / NSU",
                    obs: "Observación",
                    vlrAbater: "Cantidad a restar",
                    juros: "Interés",
                    desconto: "Descuento",
                    totalRecebido: "Total recibido",
                    inclRecebimento: "Incluir recibo",
                },

                revisaoCampos_vue_html: {
                    manipulacao: "Manipulación",
                    ativoPrincipal: "Principal activo",
                    posologia: "Posología",
                    editPosologia: "Editar posología",
                    novaPosologia: "Nueva Posología",
                    paciente: "Paciente",
                    editPaciente: "Editar paciente",
                    novoPaciente: "Nuevo paciente",
                    prescritor: "prescripción",
                    editPrescritor: "Editar Prescritor",
                    novoPrescritor: "Nuevo prescriptor",
                    nroReceita: "Número de ingresos",
                },

                vendaEstoque_vue_html: {
                    produto: "Producto",
                    quantidadeComprometida: "Cantidad de producto comprometida",
                    quantidadeComprometidaTitle: "Cantidad comprometida del producto en fraccionamiento",
                    quantidadeComprometidaLote: "Cantidad de lote comprometida",
                    quantidadeComprometidaLoteTitle: "Cantidad comprometida del lote en fraccionamiento",
                    quantidadeDisponivel: "Cant. Disponible",
                    prodLote: "Producto por lotes",
                    qtd: "Cantidad",
                    vlrUnit: "Unidad de valor.",
                    acrescimo: "Adición",
                    desconto: "Descuento",
                    vlrTotal: "Importe total",
                    embalagemProd: "Embalaje del producto",
                    kitEmbalagem: "Kit de embalaje",
                    unidadeMed: "Unidad de Medida",
                    infoLote: "Información del Lote",
                    dataVal: "Fecha de Validez",
                    qtdEstoque: "Cantidade en Stock",
                    valorCusto: "Valor de Coste",
                    observacaoArmazenamento: "Almacenamiento de notas",
                },

                financeiro: {
                    financeiro_vue_html: {
                        addParcela: "Agregar Parcela",
                        addRecebimento: "Agregar recibir",
                    },

                    parcelamento_vue_html: {
                        descricao: "Descripción",
                        dataVencimento: "Data Vencimento",
                        valor: "Valor",
                    },

                    preFaturamento_vue_html: {
                        valorSerPago: "El monto a pagar",
                        troco: "Cambiar",
                        parcelas: "Cuotas",
                    },
                },
            },

            statusSistema: {
                statussistema_vue_html: {
                    title: "Estado del Sistema",
                },
            },
        },

        child: {
            form: {
                combo_vue_html: {
                    nenhumaOpcEncont: "No se encontraron opciones",
                },

                filepicker_vue_html: {
                    arqSelecionado: "Archivo seleccionado:",
                    cliqueParaSelecArq: "Haga clic aquí para seleccionar el archivo",
                },

                sei_vue_html: {
                    tipoProcesso: "Tipo de Proceso",
                    especificacoes: "Especificaciones",
                    classificacao: "Clasificación",
                    interessado: "Interesado",
                    destino: "Destino",
                    obsUnidade: "Observaciones de la unidad",
                    endereco: "Correo electrónico",
                    numProcesso: "Proceso anterior No.",
                    outrasInfo: "Otras informaciones",
                },
            },

            grid: {
                grid_vue_html: {
                    procurarPor: "Buscar por...",
                    imprimirRegistrosAbaixo: "Imprimir registros que se muestran a continuación",
                    gerarXLSdosArq:
                        "Genera un archivo de tipo Xls a partir de los registros que se muestran a continuación.",
                    gerarPDFdosArq: "Generar archivo PDF de los registros que se muestran a continuación.",
                    cash: "Cuenta",
                    de: "De:",
                    ate: "Hasta:",
                    pgtoDe: "Pago desde:",
                    grupo: "Grupo:",
                    subgrupo: "Subgrupo:",
                    fornecedor: "Proveedor:",
                    categoria: "Categoría:",
                    acoes: "Acciones",
                    exibindo: "Mostrando",
                    dee: "de",
                    registros: "registros",
                    addNovo: "Agregar nuevo",
                },
            },

            rascunho: {
                rascunho_vue_html: {
                    rascunho: "Borrador",
                    salvarRascunho: "Guadar Borrador",
                    selecionar: "Seleccionar",
                    remover: "Eliminar",
                },
            },

            searchCombo: {
                search_vue_html: {
                    digiteParaProcurar: "Escribe para buscar...",
                },
            },
        },
    },

    Crud: null,
};

__.Crud = __.Components.parent.crud;

export default { __ };
