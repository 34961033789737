import { mapMutations } from "vuex";

import { GridColumn, GridColumnType } from "@/components/child/grid/gridColumn";
import { Component } from "@/decorators";
import UnidadeMedidaModel from "@/models/unidadeMedidaModel";
import UnidadeMedidaService from "@/services/unidadeMedidaService";
import { LoadListProps, Mutations } from "@/store/store";

import ListComponentBase from "../listComponentBase";

import "../crud.scss";

@Component({
    methods: mapMutations(["LOAD_LIST"] as Mutations),
})
export default class UnidadeMedidaListComponent extends ListComponentBase<UnidadeMedidaModel> {
    // State computed props
    LOAD_LIST: (props: LoadListProps) => void;

    get gridColumns(): Array<GridColumn> {
        return [
            new GridColumn("descricao", this.$t("__.ts.descricao"), GridColumnType.String),
            new GridColumn("sigla", "Sigla", GridColumnType.String),
        ];
    }

    private beforeMount() {
        this.setProps(new UnidadeMedidaService(), "unidademedida", "descricao", true);
    }

    public async onSucessoExclusao() {
        this.LOAD_LIST({ loadUnidadeMedida: true });
    }
}
