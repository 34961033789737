import { GridColumn, GridColumnType } from "@/components/child/grid/gridColumn";
import { Component } from "@/decorators";
import ColaboradorModel from "@/models/colaboradorModel";
import ColaboradorService from "@/services/colaboradorService";

import ListComponentBase from "../listComponentBase";

@Component
export default class ColaboradorListComponent extends ListComponentBase<ColaboradorModel> {
    get gridColumns(): Array<GridColumn> {
        return [
            new GridColumn("nome", this.$t("__.ts.nome"), GridColumnType.String, false, false, "", "", true),
            new GridColumn("cpf", "CPF", GridColumnType.String, false, false, "", "", true),
        ];
    }

    private beforeMount() {
        this.setProps(new ColaboradorService(), "colaborador", "nome");
    }
}
