var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "dropdownComponent",
    { attrs: { menuSide: _vm.isMensagemPropria ? "LEFT" : "RIGHT" } },
    [
      _c("i", {
        staticClass: "fa fa-chevron-down",
        attrs: { slot: "button", "aria-hidden": "true" },
        slot: "button",
      }),
      _vm._v(" "),
      _c("template", { slot: "itens" }, [
        _c("li", [
          _c("div", { on: { click: _vm.onResponder } }, [
            _vm._v(
              "\n                " +
                _vm._s(
                  _vm.$t(
                    "__.Components.parent.atendimento.mensagemOptions.responder"
                  )
                ) +
                "\n            "
            ),
          ]),
        ]),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }