import Vue from "vue";
import { mapMutations, mapState } from "vuex";

import buttonIncluirComponent from "@/components/child/form/button/buttonIncluir.vue";
import dateTimePickerComponent from "@/components/child/form/datetimepicker.vue";
import fieldsetComponent from "@/components/child/form/fieldset.vue";
import moedaComponent from "@/components/child/form/moeda.vue";
import gridComponent from "@/components/child/grid/grid.vue";
import { GridColumn, GridColumnType } from "@/components/child/grid/gridColumn";
import { Component, Watch } from "@/decorators";
import NotaFiscalEletronicaEmissaoModel, { Duplicata } from "@/models/notaFiscalEletronicaEmissaoModel";
import { AppState, Mutations } from "@/store/store";

import "../crud/crud.scss";

@Component({
    components: {
        gridComponent,
        dateTimePickerComponent,
        moedaComponent,
        fieldsetComponent,
        buttonIncluirComponent,
    },
    computed: mapState<AppState>({
        nota: state => state.nfeContext.nota,
        consulta: state => state.nfeContext.consulta,
    }),
    methods: mapMutations(["SET_NOTA"] as Mutations),
})
export default class CobrancaComponent extends Vue {
    // State computed props
    nota: NotaFiscalEletronicaEmissaoModel;
    consulta: boolean;
    SET_NOTA: (nota: NotaFiscalEletronicaEmissaoModel) => void;

    private model = new Duplicata();
    private editingModel = false;
    private indexEdditingModel = -1;

    private gridColumns: Array<GridColumn> = [
        new GridColumn("numero", "Número", GridColumnType.String),
        new GridColumn("dataVencimento", "Date de vencimento", GridColumnType.Date),
        new GridColumn("valor", "Valor", GridColumnType.Money),
    ];

    private async onAdicionarItem() {
        const isValid = await this.$validator.validateAll();

        if (isValid) {
            if (!this.editingModel) {
                this.model.numero = (this.nota.cobranca.duplicatas.length + 1).toString();
                this.nota.cobranca.duplicatas.push(this.model);
            } else {
                if (this.indexEdditingModel >= 0) {
                    this.nota.cobranca.duplicatas[this.indexEdditingModel].numero = this.model.numero;
                    this.nota.cobranca.duplicatas[this.indexEdditingModel].dataVencimento = this.model.dataVencimento;
                    this.nota.cobranca.duplicatas[this.indexEdditingModel].valor = this.model.valor;
                }
            }
            const totalDuplicatas = this.nota.cobranca.duplicatas.reduce((acc, p) => acc + p.valor, 0);
            this.nota.cobranca.valorOriginal = totalDuplicatas + this.nota.cobranca.valorDesconto;

            this.SET_NOTA(this.nota);
            this.clear();
        }
    }

    private async onEditItem(duplicata: Duplicata) {
        this.editingModel = true;
        this.indexEdditingModel = this.nota.cobranca.duplicatas.findIndex(p => p.numero == duplicata.numero);

        if (this.indexEdditingModel >= 0) {
            this.model.numero = this.nota.cobranca.duplicatas[this.indexEdditingModel].numero;
            this.model.dataVencimento = this.nota.cobranca.duplicatas[this.indexEdditingModel].dataVencimento;
            this.model.valor = this.nota.cobranca.duplicatas[this.indexEdditingModel].valor;
        }
    }

    private onRemoveItem(duplicata: Duplicata) {
        this.nota.cobranca.duplicatas = this.nota.cobranca.duplicatas
            .filter(p => p.numero != duplicata.numero)
            .map((p, index) => ({
                ...p,
                numero: (index + 1).toString(),
            }));
        this.SET_NOTA(this.nota);
    }

    private clear() {
        this.editingModel = false;
        this.indexEdditingModel = -1;

        this.model = new Duplicata();
        this.$validator.reset();
    }

    // @ts-ignore
    @Watch("nota.cobranca.valorOriginal")
    // @ts-ignore
    @Watch("nota.cobranca.valorDesconto")
    private onCalculaValorLiquido() {
        if (this.nota.cobranca) {
            this.nota.cobranca.valorLiquido = this.nota.cobranca.valorOriginal - this.nota.cobranca.valorDesconto;
        }
        this.SET_NOTA(this.nota);
    }
}
