import BaseModel from "./base/baseModel";

export default class NotaFiscalEntradaItemImpostoModel extends BaseModel {
    id?: number = null;
    imposto: string = null;
    notaFiscalEntradaId?: number = null;
    notaFiscalEntradaItemId?: number = null;
    percentual = 0;
    valorBaseCalculo = 0;
    valorImposto = 0;
}
