var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "shortcutComponent",
    { ref: "shortcutComponent", attrs: { showBtnOk: false } },
    [
      _c("div", { attrs: { slot: "component" }, slot: "component" }, [
        _c("form", { attrs: { novalidate: "novalidate" } }, [
          _c("fieldset", [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-sm-12" }, [
                _c("div", { staticClass: "form-group" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.manipulacaoOrdemCodigo,
                        expression: "manipulacaoOrdemCodigo",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: {
                      autocomplete: "off",
                      autofocus: "",
                      type: "text",
                      id: "search",
                      name: "search",
                      placeholder:
                        "Pesquise por Código da Venda, Código da Manipulação, Cliente ou Descrição da formula",
                    },
                    domProps: { value: _vm.manipulacaoOrdemCodigo },
                    on: {
                      keyup: _vm.onChangeManipulacaoOrdemCodigo,
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.manipulacaoOrdemCodigo = $event.target.value
                      },
                    },
                  }),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col-sm-12" },
                [
                  _c("grid-component", {
                    attrs: {
                      data: _vm.gridData,
                      columns: _vm.gridColumns,
                      showActionColumn: false,
                      showSortIcons: false,
                      showTableHead: false,
                      showAddNewButton: false,
                      showTableFoot: false,
                      showTools: false,
                      selectableLine: true,
                      scrol: true,
                    },
                    on: { "selected-line": _vm.onSelectSearchAdvanced },
                  }),
                ],
                1
              ),
            ]),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }