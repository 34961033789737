import Vue from "vue";

import { Component, Prop } from "@/decorators";

@Component
export default class DecimalComponent extends Vue {
    @Prop(String) id: string;
    @Prop(String) name: string;
    @Prop(Number) value: number;

    private onBlur(value) {
        this.$emit("input", this.formatValueToModel(value));
    }

    private onFocus(value) {
        this.$emit("focus", this.formatValueToModel(value));
    }

    private onKeyUp(event) {
        this.$emit("keyup", this.formatValueToModel(event.target.value));
    }

    private formatValueToModel(value) {
        if (value == "") {
            return null;
        }

        return +value.toString().replace(",", ".");
    }
}
