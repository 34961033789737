var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("form", [
    _c(
      "div",
      { staticClass: "row" },
      [
        _c("box-component", {
          attrs: {
            title: _vm.$t(
              "__.Components.parent.dashboard.evolucaoVendas_vue_html.valorVendasMes"
            ),
            dimensoes: ["col-lg-4", "col-md-4", "col-sm-6", "col-xs-12"],
            classes: ["dashboard-stat", "dashboard-stat-v2", "blue"],
            icon: "fa fa-money-bill",
            value: _vm._f("filterMoeda")(_vm.totalVendas),
          },
        }),
        _vm._v(" "),
        _c("box-component", {
          attrs: {
            title: _vm.$t(
              "__.Components.parent.dashboard.evolucaoVendas_vue_html.qtdeVendasMes"
            ),
            dimensoes: ["col-lg-4", "col-md-4", "col-sm-6", "col-xs-12"],
            classes: ["dashboard-stat", "dashboard-stat-v2", "red"],
            icon: "fa fa-cart-arrow-down",
            value: _vm.convertToString(_vm.totalQtdPedidos),
          },
        }),
        _vm._v(" "),
        _c("box-component", {
          attrs: {
            title: _vm.$t(
              "__.Components.parent.dashboard.evolucaoVendas_vue_html.novosClientesMes"
            ),
            dimensoes: ["col-lg-4", "col-md-4", "col-sm-6", "col-xs-12"],
            classes: ["dashboard-stat", "dashboard-stat-v2", "yellow"],
            icon: "fa fa-address-book",
            value: _vm.convertToString(_vm.totalClientesCadastrados),
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-sm-7" }, [
        _c(
          "div",
          { staticClass: "form-group" },
          [
            _c(
              "label",
              { staticClass: "control-label", attrs: { for: "franquias" } },
              [
                _vm._v(
                  "\n                    " +
                    _vm._s(
                      _vm.$t(
                        "__.Components.parent.dashboard.manipulacoes_vue_html.franquias"
                      )
                    ) +
                    "\n                "
                ),
              ]
            ),
            _vm._v(" "),
            _c("multiSelect-component", {
              attrs: {
                id: "franquias",
                name: "franquias",
                options: _vm.franquiasOptions,
                defaultValue: _vm.defaultFranquia,
              },
              model: {
                value: _vm.franquiaIds,
                callback: function ($$v) {
                  _vm.franquiaIds = $$v
                },
                expression: "franquiaIds",
              },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-sm-2" }, [
        _c(
          "button",
          {
            staticClass: "ajuste-button-form",
            attrs: { type: "button" },
            on: {
              click: function ($event) {
                _vm.onSelecionarTodas()
              },
            },
          },
          [
            _vm._v(
              "\n                " +
                _vm._s(
                  _vm.$t(
                    "__.Components.parent.dashboard.evolucaoVendas_vue_html.todas"
                  )
                ) +
                "\n            "
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "ajuste-button-form",
            attrs: { type: "button" },
            on: {
              click: function ($event) {
                _vm.onLimpar()
              },
            },
          },
          [
            _vm._v(
              "\n                " +
                _vm._s(
                  _vm.$t(
                    "__.Components.parent.dashboard.evolucaoVendas_vue_html.limpar"
                  )
                ) +
                "\n            "
            ),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-sm-1" }, [
        _c(
          "div",
          { staticClass: "form-group" },
          [
            _c(
              "label",
              { staticClass: "control-label", attrs: { for: "franquias" } },
              [
                _vm._v(
                  "\n                    " +
                    _vm._s(
                      _vm.$t(
                        "__.Components.parent.dashboard.evolucaoVendas_vue_html.taxaEntrega"
                      )
                    ) +
                    "\n                "
                ),
              ]
            ),
            _vm._v(" "),
            _c("checkbox-component", {
              attrs: { name: "taxaEntrega", id: "taxaEntrega" },
              model: {
                value: _vm.taxaEntrega,
                callback: function ($$v) {
                  _vm.taxaEntrega = $$v
                },
                expression: "taxaEntrega",
              },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-sm-2" }, [
        _c(
          "div",
          { staticClass: "form-group" },
          [
            _c(
              "label",
              {
                staticClass: "control-label",
                attrs: { for: "tipoVenda", title: _vm.$t("__.obrigatorio") },
              },
              [
                _vm._v(
                  "\n                    " +
                    _vm._s(
                      _vm.$t(
                        "__.Components.parent.venda.editSingle_vue_html.tipoVenda"
                      )
                    ) +
                    "\n                "
                ),
              ]
            ),
            _vm._v(" "),
            _c("combo-component", {
              attrs: {
                id: "tipoVenda",
                name: "tipoVenda",
                data: _vm.tipoVendaOptions,
                searchable: true,
              },
              model: {
                value: _vm.tipoVenda,
                callback: function ($$v) {
                  _vm.tipoVenda = $$v
                },
                expression: "tipoVenda",
              },
            }),
          ],
          1
        ),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-sm-6" },
        [
          _c("label", { staticClass: "control-label" }, [
            _vm._v(
              "\n                " +
                _vm._s(
                  _vm.$t(
                    "__.Components.parent.dashboard.manipulacoes_vue_html.dataInicial"
                  )
                ) +
                "\n            "
            ),
          ]),
          _vm._v(" "),
          _c("date-time-picker-component", {
            attrs: { id: "dataInicial", name: "dataInicial" },
            model: {
              value: _vm.dataInicial,
              callback: function ($$v) {
                _vm.dataInicial = $$v
              },
              expression: "dataInicial",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-sm-6" },
        [
          _c("label", { staticClass: "control-label" }, [
            _vm._v(
              "\n                " +
                _vm._s(
                  _vm.$t(
                    "__.Components.parent.dashboard.manipulacoes_vue_html.dataFinal"
                  )
                ) +
                "\n            "
            ),
          ]),
          _vm._v(" "),
          _c("date-time-picker-component", {
            attrs: { id: "dataFinal", name: "dataFinal" },
            model: {
              value: _vm.dataFinal,
              callback: function ($$v) {
                _vm.dataFinal = $$v
              },
              expression: "dataFinal",
            },
          }),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _c("div", {
      ref: "vendasDiario",
      style: _vm.graphStyle,
      attrs: { slot: "content" },
      slot: "content",
    }),
    _vm._v(" "),
    _c("div", {
      ref: "somaVendasMensal",
      style: _vm.graphStyle,
      attrs: { slot: "content" },
      slot: "content",
    }),
    _vm._v(" "),
    _c("div", {
      ref: "qtdeVendasMensal",
      style: _vm.graphStyle,
      attrs: { slot: "content" },
      slot: "content",
    }),
    _vm._v(" "),
    _c("div", {
      ref: "somaVendasAnualAprovadas",
      style: _vm.graphStyle,
      attrs: { slot: "content" },
      slot: "content",
    }),
    _vm._v(" "),
    _c("div", {
      ref: "qtdeVendasAnualAprovadas",
      style: _vm.graphStyle,
      attrs: { slot: "content" },
      slot: "content",
    }),
    _vm._v(" "),
    _c("div", {
      ref: "somaVendasAnualRejeitadas",
      style: _vm.graphStyle,
      attrs: { slot: "content" },
      slot: "content",
    }),
    _vm._v(" "),
    _c("div", {
      ref: "qtdeVendasAnualRejeitadas",
      style: _vm.graphStyle,
      attrs: { slot: "content" },
      slot: "content",
    }),
    _vm._v(" "),
    _c("div", {
      ref: "orcamentosRejeitados",
      style: _vm.graphStyle,
      attrs: { slot: "content" },
      slot: "content",
    }),
    _vm._v(" "),
    _c("div", {
      ref: "qtdeAtendimentos",
      style: _vm.graphStyle,
      attrs: { slot: "content" },
      slot: "content",
    }),
    _vm._v(" "),
    _c("div", {
      ref: "ticketMedio",
      style: _vm.graphStyle,
      attrs: { slot: "content" },
      slot: "content",
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }