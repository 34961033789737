import Vue from "vue";

import comboComponent from "@/components/child/form/combo.vue";
import gridComponent from "@/components/child/grid/grid.vue";
import { GridColumn, GridColumnType } from "@/components/child/grid/gridColumn";
import { Component, Prop, Watch } from "@/decorators";
import PaginationModel from "@/models/paginationModel";
import GrupoProdutoModel from "@/models/produto/grupoProdutoModel";
import ProdutoEstoqueConferenciaModel, {
    ProdutoEstoqueConferenciaListParameters,
} from "@/models/produtoEstoqueConferenciaModel";
import GrupoProdutoService from "@/services/produto/grupoProdutoService";
import ProdutoEstoqueConferenciaService from "@/services/produtoEstoqueConferenciaService";

import "@/components/parent/crud/crud.scss";

@Component({
    components: {
        gridComponent,
        comboComponent,
    },
})
export default class ManipulacaoListComponent extends Vue {
    private service: ProdutoEstoqueConferenciaService = new ProdutoEstoqueConferenciaService();
    private produtosEstoque: Array<ProdutoEstoqueConferenciaModel> = [];

    gridFilterKey = "";
    gridSortKey = "descricao";
    gridSortOrder = "asc";
    gridData: Array<ProdutoEstoqueConferenciaModel> = [];

    get gridColumns(): Array<GridColumn> {
        return [
            new GridColumn(
                "descricao",
                this.$t("__.ts.descr") as string,
                GridColumnType.String,
                false,
                false,
                "",
                "",
                true,
            ),
        ];
    }

    subDataName = "produtoLotes";
    get gridColumnsVinculos(): Array<GridColumn> {
        return [
            new GridColumn("descricao", this.$t("__.ts.lote") as string, GridColumnType.String),
            new GridColumn("fornecedorNome", this.$t("__.ts.fornecedor") as string, GridColumnType.String),
            new GridColumn("dataValidade", this.$t("__.ts.dtVal") as string, GridColumnType.String),
        ];
    }
    conditionShowSubData(it) {
        return it["produtoLotes"]["length"] > 0;
    }

    pageIndex = 1;
    pageSize = 9;
    total = 0;

    grupoProdutoFiltro?: number = null;
    grupoProdutoOptions: Array<Object> = [];
    isHidden = false;
    apareceSubGrupo?: number = null;
    subGrupoOptions: Array<Object> = [
        { text: "Ativo", value: 0 },
        { text: "Flavorizante", value: 1 },
        { text: "Veículos/Excipientes", value: 2 },
    ];

    @Prop({ type: Boolean, required: true }) onsemestoque: boolean;
    @Prop({ type: Boolean, required: true }) onestoquebaixo: boolean;
    @Prop({ type: Boolean, required: true }) onestoquevencendo: boolean;

    @Watch("grupoProdutoFiltro")
    private onSub() {
        this.apareceSubGrupo = this.grupoProdutoFiltro;
    }

    private async loadGruposProduto() {
        try {
            const data = await new GrupoProdutoService().combo().resolveWithJSON<PaginationModel<GrupoProdutoModel>>();

            this.grupoProdutoOptions = data.list.map(item => ({
                value: item.id,
                text: item.descricao,
                tipo: item.tipoProduto,
            }));
        } catch {}
    }

    filtro: Array<Object> = [];
    subGrupo?: number = null;

    private getFormValues() {
        this.filtro = [];
        if (this.grupoProdutoFiltro != 2) {
            this.subGrupo = null;
        }
        this.filtro.push(this.grupoProdutoFiltro);
        this.filtro.push(this.subGrupo);
        this.pageIndex = 1;
        this.load();
    }

    public async load(resetPagaIndex = false) {
        if (resetPagaIndex) {
            this.pageIndex = 1;
        }

        const customParameters: ProdutoEstoqueConferenciaListParameters = {
            semEstoque: this.onsemestoque,
            vencendo: this.onestoquevencendo,
            estoqueBaixo: this.onestoquebaixo,
            combo: this.filtro[0] != null ? this.filtro[0].toString() : "",
            sub: this.filtro[1] != null ? this.filtro[1].toString() : "",
        };

        const data = await this.service
            .list(this.gridFilterKey, "descricao", "asc", this.pageIndex, this.pageSize, customParameters)
            .withLoading()
            .resolveWithJSON<PaginationModel<ProdutoEstoqueConferenciaModel>>();

        this.produtosEstoque = new Array<ProdutoEstoqueConferenciaModel>();
        this.produtosEstoque = data.list;
        this.total = data.total;
    }

    private onChangeFilterKey(filterKey: string) {
        this.gridFilterKey = filterKey;
        this.load();
    }

    private onChangeSort(sortKey: string, sortOrder: string) {
        this.gridSortKey = sortKey;
        this.gridSortOrder = sortOrder;
        this.load();
    }

    private onChangePage(pageIndex: number) {
        this.pageIndex = pageIndex;
        this.load();
    }

    private mounted() {
        Promise.all([
            this.loadGruposProduto(),
            this.load(), //buscar orcamentos e totais
        ])
            .withLoading()
            .then(() => {})
            .catch(() => {});
    }
}
