var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "crud crud-edit" },
    [
      !_vm.hideExtras
        ? _c(
            "actionBarComponent",
            {
              attrs: { slots: 1, title: "Convênio - Edição" },
              on: { onSave: _vm.save },
            },
            [
              _c(
                "div",
                {
                  attrs: {
                    slot: "buttonSlot0",
                    title: _vm.$t("__.Crud.imprimir"),
                  },
                  on: { click: _vm.print },
                  slot: "buttonSlot0",
                },
                [
                  _c("i", {
                    staticClass: "fa fa-print",
                    attrs: { "aria-hidden": "true" },
                  }),
                ]
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "form",
        {
          attrs: { novalidate: "novalidate" },
          on: {
            submit: function ($event) {
              $event.preventDefault()
              _vm.save()
            },
          },
        },
        [
          _c(
            "fieldset-component",
            { attrs: { title: "Dados Gerais", collapsed: false } },
            [
              _c(
                "div",
                { attrs: { slot: "rows", id: "convenio" }, slot: "rows" },
                [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-sm-12" }, [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: _vm.errors.first("descricao"),
                              expression: "errors.first('descricao')",
                            },
                          ],
                          staticClass: "form-group",
                        },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "control-label",
                              attrs: {
                                for: "descricao",
                                title: "* este campo é obrigatório",
                              },
                            },
                            [
                              _c("span", { staticStyle: { color: "red" } }, [
                                _vm._v("*"),
                              ]),
                              _vm._v(
                                "\n                                Descrição\n                            "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.model.descricao,
                                expression: "model.descricao",
                              },
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required",
                                expression: "'required'",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: {
                              type: "text",
                              id: "descricao",
                              name: "descricao",
                            },
                            domProps: { value: _vm.model.descricao },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.model,
                                  "descricao",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-sm-6" }, [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: _vm.errors.first("validadeInicio"),
                              expression: "errors.first('validadeInicio')",
                            },
                          ],
                          staticClass: "form-group",
                        },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "control-label",
                              attrs: {
                                for: "validadeInicio",
                                title: "* este campo é obrigatório",
                              },
                            },
                            [
                              _c("span", { staticStyle: { color: "red" } }, [
                                _vm._v("*"),
                              ]),
                              _vm._v(
                                "\n                                Validade Inicio\n                            "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("date-time-picker-component", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required",
                                expression: "'required'",
                              },
                            ],
                            attrs: {
                              id: "validadeInicio",
                              name: "validadeInicio",
                            },
                            model: {
                              value: _vm.model.validadeInicio,
                              callback: function ($$v) {
                                _vm.$set(_vm.model, "validadeInicio", $$v)
                              },
                              expression: "model.validadeInicio",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-sm-6" }, [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: _vm.errors.first("validadeFim"),
                              expression: "errors.first('validadeFim')",
                            },
                          ],
                          staticClass: "form-group",
                        },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "control-label",
                              attrs: {
                                for: "validadeFim",
                                title: "* este campo é obrigatório",
                              },
                            },
                            [
                              _c("span", { staticStyle: { color: "red" } }, [
                                _vm._v("*"),
                              ]),
                              _vm._v(
                                "\n                                Validade Fim\n                            "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("date-time-picker-component", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required",
                                expression: "'required'",
                              },
                            ],
                            attrs: { id: "validadeFim", name: "validadeFim" },
                            model: {
                              value: _vm.model.validadeFim,
                              callback: function ($$v) {
                                _vm.$set(_vm.model, "validadeFim", $$v)
                              },
                              expression: "model.validadeFim",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "fieldset-component",
            { attrs: { title: "Clinicas", collapsed: true } },
            [
              _c(
                "div",
                { attrs: { slot: "rows", id: "clinicas" }, slot: "rows" },
                [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-sm-12" }, [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: _vm.errors.first("clinicas"),
                              expression: "errors.first('clinicas')",
                            },
                          ],
                          staticClass: "form-group",
                        },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "control-label",
                              attrs: { for: "clinicas" },
                            },
                            [_vm._v("Clinicas")]
                          ),
                          _vm._v(" "),
                          _c(
                            "combo-component",
                            {
                              attrs: {
                                id: "clinicaId",
                                name: "clinicaId",
                                addNew: true,
                                customView: true,
                                data: _vm.clinicasOptions,
                                formAddNewName: "Nova Clinica",
                                modalClass: "lg-modal",
                                refresh: _vm.atualizarClinicas,
                              },
                              on: {
                                addNewItemCombo: _vm.onAddNewClinica,
                                openComboNewItem: _vm.openNewClinica,
                              },
                              model: {
                                value: _vm.model.clinicaId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.model, "clinicaId", $$v)
                                },
                                expression: "model.clinicaId",
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  attrs: { slot: "component" },
                                  slot: "component",
                                },
                                [
                                  _c("clinicaComponent", {
                                    ref: "clinicaComponent",
                                    attrs: { CalledByShortCut: true },
                                    on: { "save-ok": _vm.onClinicaSaveOk },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("buttonIncluirComponent", {
                    on: { click: _vm.onAddClinica },
                  }),
                  _vm._v(" "),
                  _c("grid-component", {
                    attrs: {
                      data: _vm.model.clinicas,
                      columns: _vm.gridClinicaColumns,
                      "show-action-column": true,
                      "show-table-head": false,
                      "show-table-foot": false,
                      "show-remove-item": true,
                      "show-edit-item": false,
                    },
                    on: { "remove-item": _vm.onRemoverClinica },
                  }),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "fieldset-component",
            { attrs: { title: "Precificação", collapsed: true } },
            [
              _c(
                "div",
                { attrs: { slot: "rows", id: "convenio" }, slot: "rows" },
                [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-sm-6" }, [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: _vm.errors.first("tiposDesconto"),
                              expression: "errors.first('tiposDesconto')",
                            },
                          ],
                          staticClass: "form-group",
                        },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "control-label",
                              attrs: {
                                for: "tiposDesconto",
                                title: "* este campo é obrigatório",
                              },
                            },
                            [
                              _c("span", { staticStyle: { color: "red" } }, [
                                _vm._v("*"),
                              ]),
                              _vm._v(
                                "\n                                Tipo de desconto Cliente\n                            "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("combo-component", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required",
                                expression: "'required'",
                              },
                            ],
                            attrs: {
                              id: "tiposDesconto",
                              name: "tiposDesconto",
                              data: _vm.tipoDescontoOptions,
                              customView: true,
                            },
                            model: {
                              value: _vm.model.tipoDescontoCliente,
                              callback: function ($$v) {
                                _vm.$set(_vm.model, "tipoDescontoCliente", $$v)
                              },
                              expression: "model.tipoDescontoCliente",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-sm-6" }, [
                      _vm.model.tipoDescontoCliente != 2
                        ? _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "tooltip",
                                  rawName: "v-tooltip",
                                  value: _vm.errors.first(
                                    "valorDescontoCliente"
                                  ),
                                  expression:
                                    "errors.first('valorDescontoCliente')",
                                },
                              ],
                              staticClass: "form-group",
                            },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "control-label",
                                  attrs: {
                                    for: "valorDescontoCliente",
                                    title: "* este campo é obrigatório",
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    { staticStyle: { color: "red" } },
                                    [_vm._v("*")]
                                  ),
                                  _vm._v(
                                    "\n                                Valor desconto\n                            "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _vm.model.tipoDescontoCliente == 1
                                ? _c("decimal-com-sinal-custom-component", {
                                    staticClass: "form-control",
                                    attrs: {
                                      signal: "%",
                                      id: "valorDescontoCliente",
                                      name: "valorDescontoCliente",
                                      "max-length": "3",
                                    },
                                    model: {
                                      value:
                                        _vm.convenioFaixaCliente.valorDesconto,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.convenioFaixaCliente,
                                          "valorDesconto",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "convenioFaixaCliente.valorDesconto",
                                    },
                                  })
                                : _vm.model.tipoDescontoCliente == 0
                                ? _c("moeda-component", {
                                    staticClass: "form-control",
                                    attrs: {
                                      id: "valorDescontoCliente",
                                      name: "valorDescontoCliente",
                                      "max-length": "3",
                                    },
                                    model: {
                                      value:
                                        _vm.convenioFaixaCliente.valorDesconto,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.convenioFaixaCliente,
                                          "valorDesconto",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "convenioFaixaCliente.valorDesconto",
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.model.tipoDescontoCliente == 2,
                          expression: "model.tipoDescontoCliente == 2",
                        },
                      ],
                    },
                    [
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-sm-4" }, [
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "tooltip",
                                  rawName: "v-tooltip",
                                  value: _vm.errors.first("faixaInicio"),
                                  expression: "errors.first('faixaInicio')",
                                },
                              ],
                              staticClass: "form-group",
                            },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "control-label",
                                  attrs: {
                                    for: "faixaInicio",
                                    title: "* este campo é obrigatório",
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    { staticStyle: { color: "red" } },
                                    [_vm._v("*")]
                                  ),
                                  _vm._v(
                                    "\n                                    Inicio faixa\n                                "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("moeda-component", {
                                staticClass: "form-control",
                                attrs: {
                                  id: "faixaInicio",
                                  name: "faixaInicio",
                                  "max-length": "3",
                                },
                                model: {
                                  value: _vm.convenioFaixaCliente.faixaInicio,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.convenioFaixaCliente,
                                      "faixaInicio",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "convenioFaixaCliente.faixaInicio",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-sm-4" }, [
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "tooltip",
                                  rawName: "v-tooltip",
                                  value: _vm.errors.first("faixaFim"),
                                  expression: "errors.first('faixaFim')",
                                },
                              ],
                              staticClass: "form-group",
                            },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "control-label",
                                  attrs: {
                                    for: "faixaFim",
                                    title: "* este campo é obrigatório",
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    { staticStyle: { color: "red" } },
                                    [_vm._v("*")]
                                  ),
                                  _vm._v(
                                    "\n                                    Fim faixa\n                                "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("moeda-component", {
                                staticClass: "form-control",
                                attrs: {
                                  id: "faixaFim",
                                  name: "faixaFim",
                                  "max-length": "3",
                                },
                                model: {
                                  value: _vm.convenioFaixaCliente.faixaFim,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.convenioFaixaCliente,
                                      "faixaFim",
                                      $$v
                                    )
                                  },
                                  expression: "convenioFaixaCliente.faixaFim",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-sm-4" }, [
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "tooltip",
                                  rawName: "v-tooltip",
                                  value: _vm.errors.first("valorDesconto"),
                                  expression: "errors.first('valorDesconto')",
                                },
                              ],
                              staticClass: "form-group",
                            },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "control-label",
                                  attrs: {
                                    for: "valorDesconto",
                                    title: "* este campo é obrigatório",
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    { staticStyle: { color: "red" } },
                                    [_vm._v("*")]
                                  ),
                                  _vm._v(
                                    "\n                                    Valor desconto\n                                "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("decimal-com-sinal-custom-component", {
                                staticClass: "form-control",
                                attrs: {
                                  signal: "%",
                                  id: "valorDesconto",
                                  name: "valorDesconto",
                                  "max-length": "3",
                                },
                                model: {
                                  value: _vm.convenioFaixaCliente.valorDesconto,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.convenioFaixaCliente,
                                      "valorDesconto",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "convenioFaixaCliente.valorDesconto",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("buttonIncluirComponent", {
                        on: {
                          click: function ($event) {
                            _vm.onAddFaixa(0)
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("grid-component", {
                        attrs: {
                          data: _vm.model.faixas.filter(function (x) {
                            return x.tipoFaixa == 0
                          }),
                          columns: _vm.gridFaixaColums,
                          "show-action-column": true,
                          "show-table-head": false,
                          "show-table-foot": false,
                          "show-remove-item": true,
                          "show-edit-item": false,
                        },
                        on: { "remove-item": _vm.onRemoveFaixa },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-sm-6" }, [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: _vm.errors.first(
                                "tipoDescontoVeterinario"
                              ),
                              expression:
                                "errors.first('tipoDescontoVeterinario')",
                            },
                          ],
                          staticClass: "form-group",
                        },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "control-label",
                              attrs: {
                                for: "tipoDescontoVeterinario",
                                title: "* este campo é obrigatório",
                              },
                            },
                            [
                              _c("span", { staticStyle: { color: "red" } }, [
                                _vm._v("*"),
                              ]),
                              _vm._v(
                                "\n                                Tipo de desconto Veterinario\n                            "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("combo-component", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required",
                                expression: "'required'",
                              },
                            ],
                            attrs: {
                              id: "tipoDescontoVeterinario",
                              name: "tipoDescontoVeterinario",
                              data: _vm.tipoDescontoOptions,
                              customView: true,
                            },
                            model: {
                              value: _vm.model.tipoDescontoVeterinario,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.model,
                                  "tipoDescontoVeterinario",
                                  $$v
                                )
                              },
                              expression: "model.tipoDescontoVeterinario",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-sm-6" }, [
                      _vm.model.tipoDescontoVeterinario != 2
                        ? _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "tooltip",
                                  rawName: "v-tooltip",
                                  value: _vm.errors.first(
                                    "valorDescontoVeterinario"
                                  ),
                                  expression:
                                    "errors.first('valorDescontoVeterinario')",
                                },
                              ],
                              staticClass: "form-group",
                            },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "control-label",
                                  attrs: {
                                    for: "valorDescontoVeterinario",
                                    title: "* este campo é obrigatório",
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    { staticStyle: { color: "red" } },
                                    [_vm._v("*")]
                                  ),
                                  _vm._v(
                                    "\n                                Valor desconto\n                            "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _vm.model.tipoDescontoVeterinario == 1
                                ? _c("decimal-com-sinal-custom-component", {
                                    staticClass: "form-control",
                                    attrs: {
                                      signal: "%",
                                      id: "valorDescontoVeterinario",
                                      name: "valorDescontoVeterinario",
                                    },
                                    model: {
                                      value:
                                        _vm.convenioFaixaVeterinario
                                          .valorDesconto,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.convenioFaixaVeterinario,
                                          "valorDesconto",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "convenioFaixaVeterinario.valorDesconto",
                                    },
                                  })
                                : _vm.model.tipoDescontoVeterinario == 0
                                ? _c("moeda-component", {
                                    staticClass: "form-control",
                                    attrs: {
                                      id: "valorDescontoVeterinario",
                                      name: "valorDescontoVeterinario",
                                    },
                                    model: {
                                      value:
                                        _vm.convenioFaixaVeterinario
                                          .valorDesconto,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.convenioFaixaVeterinario,
                                          "valorDesconto",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "convenioFaixaVeterinario.valorDesconto",
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.model.tipoDescontoVeterinario == 2,
                          expression: "model.tipoDescontoVeterinario == 2",
                        },
                      ],
                    },
                    [
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-sm-4" }, [
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "tooltip",
                                  rawName: "v-tooltip",
                                  value: _vm.errors.first("faixaInicio"),
                                  expression: "errors.first('faixaInicio')",
                                },
                              ],
                              staticClass: "form-group",
                            },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "control-label",
                                  attrs: {
                                    for: "faixaInicio",
                                    title: "* este campo é obrigatório",
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    { staticStyle: { color: "red" } },
                                    [_vm._v("*")]
                                  ),
                                  _vm._v(
                                    "\n                                    Inicio faixa\n                                "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("moeda-component", {
                                staticClass: "form-control",
                                attrs: {
                                  id: "faixaInicio",
                                  name: "faixaInicio",
                                  "max-length": "3",
                                },
                                model: {
                                  value:
                                    _vm.convenioFaixaVeterinario.faixaInicio,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.convenioFaixaVeterinario,
                                      "faixaInicio",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "convenioFaixaVeterinario.faixaInicio",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-sm-4" }, [
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "tooltip",
                                  rawName: "v-tooltip",
                                  value: _vm.errors.first("faixaFim"),
                                  expression: "errors.first('faixaFim')",
                                },
                              ],
                              staticClass: "form-group",
                            },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "control-label",
                                  attrs: {
                                    for: "faixaFim",
                                    title: "* este campo é obrigatório",
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    { staticStyle: { color: "red" } },
                                    [_vm._v("*")]
                                  ),
                                  _vm._v(
                                    "\n                                    Fim faixa\n                                "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("moeda-component", {
                                staticClass: "form-control",
                                attrs: {
                                  id: "faixaFim",
                                  name: "faixaFim",
                                  "max-length": "3",
                                },
                                model: {
                                  value: _vm.convenioFaixaVeterinario.faixaFim,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.convenioFaixaVeterinario,
                                      "faixaFim",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "convenioFaixaVeterinario.faixaFim",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-sm-4" }, [
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "tooltip",
                                  rawName: "v-tooltip",
                                  value: _vm.errors.first("valorDesconto"),
                                  expression: "errors.first('valorDesconto')",
                                },
                              ],
                              staticClass: "form-group",
                            },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "control-label",
                                  attrs: {
                                    for: "valorDesconto",
                                    title: "* este campo é obrigatório",
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    { staticStyle: { color: "red" } },
                                    [_vm._v("*")]
                                  ),
                                  _vm._v(
                                    "\n                                    Valor desconto\n                                "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("decimal-com-sinal-custom-component", {
                                staticClass: "form-control",
                                attrs: {
                                  signal: "%",
                                  id: "valorDesconto1",
                                  name: "valorDesconto",
                                  "max-length": "3",
                                },
                                model: {
                                  value:
                                    _vm.convenioFaixaVeterinario.valorDesconto,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.convenioFaixaVeterinario,
                                      "valorDesconto",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "convenioFaixaVeterinario.valorDesconto",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("buttonIncluirComponent", {
                        on: {
                          click: function ($event) {
                            _vm.onAddFaixa(1)
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("grid-component", {
                        attrs: {
                          data: _vm.model.faixas.filter(function (x) {
                            return x.tipoFaixa == 1
                          }),
                          columns: _vm.gridFaixaColums,
                          "show-action-column": true,
                          "show-table-head": false,
                          "show-table-foot": false,
                          "show-remove-item": true,
                          "show-edit-item": false,
                        },
                        on: { "remove-item": _vm.onRemoveFaixa },
                      }),
                    ],
                    1
                  ),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _c("fieldset", [
            !_vm.hideExtras
              ? _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-sm-12 newbtn" }, [
                    _c("div", { staticClass: "form-group" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-success",
                          attrs: { type: "submit" },
                        },
                        [
                          _c("i", {
                            staticClass: "fa fa-save",
                            attrs: { "aria-hidden": "true" },
                          }),
                          _vm._v(
                            "\n                            " +
                              _vm._s(_vm.$t("__.Crud.salvar")) +
                              "\n                        "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-secondary",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              _vm.load()
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n                            " +
                              _vm._s(_vm.$t("__.Crud.limpar")) +
                              "\n                        "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-secondary",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              _vm.cancel()
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n                            " +
                              _vm._s(_vm.$t("__.Crud.voltar")) +
                              "\n                        "
                          ),
                        ]
                      ),
                    ]),
                  ]),
                ])
              : _vm._e(),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }