import Vue from "vue";

import buttonIncluirComponent from "@/components/child/form/button/buttonIncluir.vue";
import dataTooltipComponent from "@/components/child/form/datatooltip.vue";
import fieldsetComponent from "@/components/child/form/fieldset.vue";
import moedaComponent from "@/components/child/form/moeda.vue";
import gridComponent from "@/components/child/grid/grid.vue";
import { GridColumn, GridColumnType } from "@/components/child/grid/gridColumn";
import { Component, Prop } from "@/decorators";
import { Adicao } from "@/models/notaFiscalEletronicaEmissaoItemModel";

import "../crud/crud.scss";

@Component({
    components: {
        gridComponent,
        dataTooltipComponent,
        moedaComponent,
        fieldsetComponent,
        buttonIncluirComponent,
    },
})
export default class AdicaoComponent extends Vue {
    private model = new Adicao();

    private editingModel = false;
    private indexEdditingModel = -1;

    @Prop({ type: Array, required: true }) data;
    @Prop(Boolean) consulta: boolean;

    get gridColumns(): Array<GridColumn> {
        return [
            new GridColumn("numeroAdicao", this.$t("__.ts.nro") as string, GridColumnType.String),
            new GridColumn("codigoFabricanteEstrangeiro", this.$t("__.ts.fabEstrang") as string, GridColumnType.String),
            new GridColumn("desconto", this.$t("__.ts.descont") as string, GridColumnType.Money),
        ];
    }

    private async onAdicionarItem() {
        const isValid = await this.$validator.validateAll();

        if (isValid) {
            if (!this.editingModel) {
                this.data.push(this.model);
            } else {
                if (this.indexEdditingModel >= 0) {
                    this.data[this.indexEdditingModel].numeroAdicao = this.model.numeroAdicao;
                    this.data[this.indexEdditingModel].codigoFabricanteEstrangeiro =
                        this.model.codigoFabricanteEstrangeiro;
                    this.data[this.indexEdditingModel].desconto = this.model.desconto;
                }
            }

            this.clear();
        }
    }

    private async onEditItem(adicao: Adicao) {
        this.editingModel = true;
        this.indexEdditingModel = this.data.indexOf(adicao);

        if (this.indexEdditingModel >= 0) {
            this.model.numeroAdicao = this.data[this.indexEdditingModel].numeroAdicao;
            this.model.codigoFabricanteEstrangeiro = this.data[this.indexEdditingModel].codigoFabricanteEstrangeiro;
            this.model.desconto = this.data[this.indexEdditingModel].desconto;
        }
    }

    private onRemoveItem(adicao: Adicao) {
        const index = this.data.indexOf(adicao);
        this.data.splice(index, 1);
    }

    private clear() {
        this.editingModel = false;
        this.indexEdditingModel = -1;

        this.model = new Adicao();
        this.$validator.reset();
    }
}
