var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "crud crud-edit" },
    [
      _c(
        "form",
        { attrs: { id: "header", novalidate: "novalidate" } },
        [
          _c(
            "fieldset-component",
            {
              attrs: {
                title: _vm.$t(
                  "__.Components.parent.printModel.edit_vue_html.dadosImpress"
                ),
                collapsed: false,
              },
            },
            [
              _c("div", { attrs: { slot: "rows" }, slot: "rows" }, [
                _c("div", { staticClass: "col-sm-4" }, [
                  _c("div", { staticClass: "form-group" }, [
                    _c(
                      "label",
                      {
                        staticClass: "control-label",
                        attrs: { for: "descricao" },
                      },
                      [
                        _vm._v(
                          "\n                            " +
                            _vm._s(
                              _vm.$t(
                                "__.Components.parent.printModel.edit_vue_html.descrSessao"
                              )
                            ) +
                            "\n                        "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.sessao.descricao,
                          expression: "sessao.descricao",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: {
                        type: "text",
                        id: "descricao",
                        name: "descricao",
                      },
                      domProps: { value: _vm.sessao.descricao },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.sessao, "descricao", $event.target.value)
                        },
                      },
                    }),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-sm-2" }, [
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c("label", { attrs: { for: "checkShowDescricao" } }, [
                        _vm._v(
                          "\n                            " +
                            _vm._s(
                              _vm.$t(
                                "__.Components.parent.printModel.edit_vue_html.imprimirDesc"
                              )
                            ) +
                            "\n                        "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("checkbox-component", {
                        attrs: {
                          id: "checkShowDescricao",
                          name: "checkShowDescricao",
                        },
                        model: {
                          value: _vm.sessao.showDescricao,
                          callback: function ($$v) {
                            _vm.$set(_vm.sessao, "showDescricao", $$v)
                          },
                          expression: "sessao.showDescricao",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-sm-1" }, [
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c(
                        "label",
                        {
                          staticClass: "control-label",
                          attrs: { for: "sessao" },
                        },
                        [
                          _vm._v(
                            "\n                            " +
                              _vm._s(
                                _vm.$t(
                                  "__.Components.parent.printModel.edit_vue_html.ordem"
                                )
                              ) +
                              "\n                        "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("data-tooltip-component", {
                        attrs: {
                          text: _vm.$t(
                            "__.Components.parent.printModel.edit_vue_html.auxOrdenacaoSessoes"
                          ),
                        },
                      }),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.sessao.sessao,
                            expression: "sessao.sessao",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: { type: "number", id: "sessao", name: "sessao" },
                        domProps: { value: _vm.sessao.sessao },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(_vm.sessao, "sessao", $event.target.value)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: true,
                        expression: "true",
                      },
                    ],
                    staticClass: "col-sm-2",
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "tipo" },
                          },
                          [
                            _vm._v(
                              "\n                            " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Components.parent.printModel.edit_vue_html.tipoDado"
                                  )
                                ) +
                                "\n                        "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("combo-component", {
                          attrs: {
                            title: _vm.$t(
                              "__.Components.parent.printModel.edit_vue_html.tipoDadoCampo"
                            ),
                            id: "tipo",
                            name: "tipo",
                            data: _vm.tipoOptions,
                            searchable: false,
                          },
                          model: {
                            value: _vm.sessao.tipo,
                            callback: function ($$v) {
                              _vm.$set(_vm.sessao, "tipo", $$v)
                            },
                            expression: "sessao.tipo",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "col-sm-2" }, [
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c(
                        "label",
                        {
                          staticClass: "control-label",
                          attrs: { for: "typeTableLines" },
                        },
                        [
                          _vm._v(
                            "\n                            " +
                              _vm._s(
                                _vm.$t(
                                  "__.Components.parent.printModel.edit_vue_html.linhas"
                                )
                              ) +
                              "\n                        "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("data-tooltip-component", {
                        attrs: {
                          text: _vm.$t(
                            "__.Components.parent.printModel.edit_vue_html.tiposLinhasTab"
                          ),
                        },
                      }),
                      _vm._v(" "),
                      _c("combo-component", {
                        attrs: {
                          id: "typeTableLines",
                          name: "typeTableLines",
                          data: _vm.typeTablelineOptions,
                          searchable: false,
                        },
                        model: {
                          value: _vm.sessao.typeTableLines,
                          callback: function ($$v) {
                            _vm.$set(_vm.sessao, "typeTableLines", $$v)
                          },
                          expression: "sessao.typeTableLines",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-sm-1" }, [
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c("label", { attrs: { for: "checkAtivo" } }, [
                        _vm._v(
                          "\n                            " +
                            _vm._s(
                              _vm.$t(
                                "__.Components.parent.printModel.edit_vue_html.ativo"
                              )
                            ) +
                            "\n                        "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("checkbox-component", {
                        attrs: { id: "checkAtivo", name: "checkAtivo" },
                        model: {
                          value: _vm.sessao.ativo,
                          callback: function ($$v) {
                            _vm.$set(_vm.sessao, "ativo", $$v)
                          },
                          expression: "sessao.ativo",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
              _vm._v(" "),
              _vm.tipoRotulo
                ? _c("div", { attrs: { slot: "rows" }, slot: "rows" }, [
                    _c("div", { staticClass: "col-sm-3" }, [
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "control-label",
                              attrs: { for: "maxCaracteresLinha" },
                            },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(
                                    _vm.$t(
                                      "__.Components.parent.printModel.edit_vue_html.maxCharLinha"
                                    )
                                  ) +
                                  "\n                        "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("data-tooltip-component", {
                            attrs: {
                              text: "Limita o número de carácteres por linha",
                            },
                          }),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.sessao.maxCaracteresLinha,
                                expression: "sessao.maxCaracteresLinha",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: {
                              type: "number",
                              id: "maxCaracteresLinha",
                              name: "maxCaracteresLinha",
                            },
                            domProps: { value: _vm.sessao.maxCaracteresLinha },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.sessao,
                                  "maxCaracteresLinha",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-sm-3" }, [
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "control-label",
                              attrs: { for: "maxLinhas" },
                            },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(
                                    _vm.$t(
                                      "__.Components.parent.printModel.edit_vue_html.maxLinhas"
                                    )
                                  ) +
                                  "\n                        "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("data-tooltip-component", {
                            attrs: {
                              text: _vm.$t(
                                "__.Components.parent.printModel.edit_vue_html.limitInfosCampos"
                              ),
                            },
                          }),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.sessao.maxLinhas,
                                expression: "sessao.maxLinhas",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: {
                              type: "number",
                              id: "maxLinhas",
                              name: "maxLinhas",
                            },
                            domProps: { value: _vm.sessao.maxLinhas },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.sessao,
                                  "maxLinhas",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ])
                : _vm._e(),
            ]
          ),
          _vm._v(" "),
          _c(
            "fieldset-component",
            {
              staticClass: "crud-model-print",
              attrs: {
                title: _vm.$t(
                  "__.Components.parent.printModel.edit_vue_html.corpoImpress"
                ),
                collapsed: false,
              },
            },
            [
              _c("div", { attrs: { slot: "rows" }, slot: "rows" }, [
                _c("div", { staticClass: "col-sm-12" }, [
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c("combo-component", {
                        attrs: {
                          title: _vm.$t(
                            "__.Components.parent.printModel.edit_vue_html.separOriginaInfo"
                          ),
                          id: "dados",
                          name: "dados",
                          data: _vm.dadosOptions,
                          searchable: true,
                        },
                        model: {
                          value: _vm.dados,
                          callback: function ($$v) {
                            _vm.dados = $$v
                          },
                          expression: "dados",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-sm-12" }, [
                  _c(
                    "div",
                    { staticClass: "containers", attrs: { id: "containers" } },
                    [
                      _c("section", {
                        staticClass: "container menu-lateral ignorePrint",
                      }),
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { attrs: { id: "container-floating" } }, [
                    _c("div", [
                      _c(
                        "div",
                        {
                          staticClass: "action-icon action-icon-2",
                          attrs: {
                            "data-toggle": "tooltip",
                            "data-placement": "left",
                          },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.onAddLabel($event)
                            },
                          },
                        },
                        [
                          _c("img", { staticClass: "reminder" }),
                          _vm._v(" "),
                          _c("p", { staticClass: "icon" }, [
                            _c("i", {
                              staticClass: "fa fa-tasks",
                              attrs: { "aria-hidden": "true" },
                            }),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "action-icon action-icon-1",
                          attrs: {
                            "data-toggle": "tooltip",
                            "data-placement": "left",
                          },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.onAdicionarColuna($event)
                            },
                          },
                        },
                        [
                          _c("img", { staticClass: "reminder" }),
                          _vm._v(" "),
                          _c("p", { staticClass: "icon" }, [
                            _c("i", {
                              staticClass: "fa fa-columns",
                              attrs: { "aria-hidden": "true" },
                            }),
                          ]),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        attrs: {
                          id: "floating-button",
                          "data-toggle": "tooltip",
                          "data-placement": "left",
                          "data-original-title": "Create",
                        },
                      },
                      [
                        _c("p", { staticClass: "plus" }, [_vm._v("+")]),
                        _vm._v(" "),
                        _c("div", { staticClass: "edit" }, [
                          _c("p", { staticClass: "icon" }, [
                            _c("i", {
                              staticClass: "fa fa-edit",
                              attrs: { "aria-hidden": "true" },
                            }),
                          ]),
                        ]),
                      ]
                    ),
                  ]),
                ]),
              ]),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      [
        _c(
          "shortcutComponent",
          {
            ref: "shortcutComponent",
            attrs: { modalClass: "lm-modal", showAddNew: false },
            on: { "selection-item": _vm.onConfirmEdicaoCampo },
          },
          [
            _c(
              "div",
              { attrs: { slot: "component" }, slot: "component" },
              [
                _c("modelo-campo-edit-component", {
                  ref: "modeloCampoEditComponent",
                  attrs: {
                    dadosOptions: _vm.dadosOptions,
                    sessaoCampos: _vm.sessao.campos,
                  },
                }),
              ],
              1
            ),
          ]
        ),
      ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }