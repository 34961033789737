import { GridColumn, GridColumnType } from "@/components/child/grid/gridColumn";
import { Component } from "@/decorators";
import DadosAdicionaisFichaTecnicaModel from "@/models/dadosAdicionaisFichaTecnicaModel";
import DadosAdicionaisFichaTecnicaService from "@/services/dadosAdicionaisFichaTecnicaService";

import ListComponentBase from "../listComponentBase";

@Component
export default class DadosAdicionaisFichaTecnicaListComponent extends ListComponentBase<DadosAdicionaisFichaTecnicaModel> {
    get gridColumns(): Array<GridColumn> {
        return [
            new GridColumn("descricao", this.$t("__.ts.descricao"), GridColumnType.String),
            new GridColumn("padrao", "Padrão", GridColumnType.Boolean),
        ];
    }

    private beforeMount() {
        this.setProps(new DadosAdicionaisFichaTecnicaService(), "dadosadicionaisfichatecnica", "descricao");
    }
}
