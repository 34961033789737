import { GridColumn, GridColumnType } from "@/components/child/grid/gridColumn";
import { Component } from "@/decorators";
import CategoriaControleReclamacaoModel from "@/models/categoriaControleReclamacaoModel";
import CategoriaControleReclamacaoService from "@/services/categoriaControleReclamacaoService";

import ListComponentBase from "../listComponentBase";

@Component
export default class CategoriaControleReclamacaoListComponent extends ListComponentBase<CategoriaControleReclamacaoModel> {
    get gridColumns(): Array<GridColumn> {
        return [new GridColumn("descricao", this.$t("__.ts.descricao"), GridColumnType.String)];
    }

    private beforeMount() {
        this.setProps(new CategoriaControleReclamacaoService(), "categoriaControleReclamacao", "descricao");
    }
}
