import BaseModel from "./base/baseModel";
import TiposFormulaPadrao from "./enum/tiposFormulaPadrao";
import ProdutoLogFinanceiroModel from "./produtoLogFinanceiroModel";

export default class FormulaPadraoValoresProdutoModel extends BaseModel {
    id?: number = null;
    descricao: string = null;
    produtoId?: number = null;
    produtoDescricao: string = null;
    tipoFormulaPadrao?: TiposFormulaPadrao = null;
    tipoFormulaPadraoDescricao: string = null;

    //diz se mudou algo
    hasDifference: boolean = null;
    //atualizados
    valorCusto?: number = null;
    custoMedio?: number = null;
    valorVenda?: number = null;
    custoReferencia?: number = null;
    markup?: number = null;
    //
    custoReferenciaProduto?: number = null;
    valorVendaProduto?: number = null;

    produtoLog?: ProdutoLogFinanceiroModel = null;
    tipoCalcMarkup?: number = 0;
}
