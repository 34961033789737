import { Module } from "vuex";

import {
    calculaImposto,
    totalizaValorNota,
    rateioTaxaEntrega,
    consolidaValoresItens,
    defineTipoCSTICMS,
    defineTipoCSTIPI,
    defineTipoCSTPIS,
    defineTipoCSTCOFINS,
    calculaValoresICMS,
} from "@/components/parent/nfe/nfeUtils";
import EnumExtensions from "@/models/enum/extensions/enumExtensions";
import Ufs from "@/models/enum/ufs";
import NotaFiscalEletronicaEmissaoItemModel, { TipoCST } from "@/models/notaFiscalEletronicaEmissaoItemModel";
import NotaFiscalEletronicaEmissaoModel, { Exportacao, Retencoes } from "@/models/notaFiscalEletronicaEmissaoModel";
import VendaModel from "@/models/vendaModel";
import arithmeticHelper from "@/utils/common/arithmeticHelper";

import { AppState } from "./store";

export type NfeContextState = {
    nota: NotaFiscalEletronicaEmissaoModel;
    consulta: boolean;
    emissaoVenda: boolean;
};

export type NfeContextMutations =
    | "SET_NOTA"
    | "ADD_ITEM_NOTA"
    | "UPDATE_ITEM"
    | "SET_NOTA_CONSULTA"
    | "SET_NOTA_EMISSAO_VENDA"
    | "CONSOLIDA_NOTA";

const nfeContext: Module<NfeContextState, AppState> = {
    state: {
        nota: new NotaFiscalEletronicaEmissaoModel(),
        consulta: false,
        emissaoVenda: false,
    },
    mutations: {
        SET_NOTA(store, nota: NotaFiscalEletronicaEmissaoModel) {
            if (nota.retencoes == null) nota.retencoes = new Retencoes();
            if (nota.exportacao == null) nota.exportacao = new Exportacao();

            nota.itens.forEach(i => {
                if (i.dataValidade) i.dataValidade = new Date(i.dataValidade.toString());
                if (i.dataFabricacao) i.dataFabricacao = new Date(i.dataFabricacao.toString());
                if (nota.notaImportacao) {
                    if (i.dataDI) i.dataDI = new Date(i.dataDI.toString());
                    if (i.dataDesembaraco) i.dataDesembaraco = new Date(i.dataDesembaraco.toString());
                    if (i.ufDesembaraco)
                        i.ufDesembaracoId = EnumExtensions.getNamesAndValuesOrderedByNames(Ufs).filter(
                            p => p.text == i.ufDesembaraco,
                        )[0].value;
                }
            });

            nota = totalizaValorNota(nota, store.emissaoVenda);
            store.nota = nota;
        },
        ADD_ITEM_NOTA(
            store,
            { item, simplesNacional = false }: { item: NotaFiscalEletronicaEmissaoItemModel; simplesNacional: boolean },
        ) {
            item.valorTotal = arithmeticHelper.round(
                item.valorUnitario * item.quantidade +
                    item.valorFrete +
                    item.valorSeguro +
                    item.valorOutraDespesa -
                    item.valorDesconto,
            );
            item = defineTipoCSTICMS(item, store.nota, simplesNacional);
            item = defineTipoCSTIPI(item);
            item = defineTipoCSTPIS(item);
            item = defineTipoCSTCOFINS(item);

            // Calculo das base
            if (store.nota.finalidadeEmissao != 2) {
                let valor = item.valorUnitario * item.quantidade - item.valorDesconto + item.valorFrete;
                if (store.nota.notaImportacao) {
                    valor =
                        (valor +
                            item.valorII +
                            (item.sujeitoIPI ? item.valorIPI : 0) +
                            item.valorPIS +
                            item.valorCOFINS +
                            item.valorDespesasAduaneiras) /
                        (1 - item.aliquotaICMS / 100);
                }
                item.baseICMS = arithmeticHelper.round(valor);

                if (store.nota.notaImportacao) {
                    item.baseII = item.valorUnitario * item.quantidade;
                    item.valorII = arithmeticHelper.round(item.baseII * item.aliquotaII);
                }

                if (item.sujeitoIPI) {
                    item.baseIPI = arithmeticHelper.round(item.valorUnitario * item.quantidade + item.valorII);
                }
                item.basePIS = item.valorUnitario * item.quantidade;
                item.baseCOFINS = item.valorUnitario * item.quantidade;

                if (simplesNacional) {
                    if (store.nota.notaImportacao) {
                        item.baseICMS = null;
                        item.aliquotaICMS = null;
                        item.valorICMS = null;
                    }
                    if (item.cstICMS != 101 && item.cstICMS != 201) {
                        item.percentualCreditoSimplesNacional = null;
                        item.valorCreditoSimplesNacional = null;

                        if (item.cstICMS != 900) {
                            item.baseICMS = null;
                            item.aliquotaICMS = null;
                            item.valorICMS = null;
                        } else {
                            calculaImposto(item, "ICMS");
                        }
                    }
                } else {
                    calculaImposto(item, "ICMS");
                }

                if (item.cstICMS != 20) {
                    item.percentualReducaoBase = null;
                }
                if (item.cstICMS != 51) {
                    item.percentualDiferimento = null;
                }

                item = calculaValoresICMS(item);

                if (item.sujeitoIPI) {
                    calculaImposto(item, "IPI");
                } else {
                    item.baseIPI = null;
                    item.aliquotaIPI = null;
                    item.valorIPI = null;
                }
                calculaImposto(item, "PIS");
                calculaImposto(item, "COFINS");

                if (!item.produtoId) {
                    item.produtoId = 0;
                }
                if (item.ufDesembaracoId) {
                    item.ufDesembaraco = Ufs[item.ufDesembaracoId];
                }
            }

            item.numeroItem = store.nota.itens.length + 1;
            store.nota.itens.push(item);
            store.nota = totalizaValorNota(store.nota, store.emissaoVenda);
            return item;
        },
        UPDATE_ITEM(store, { item, index }: { item: NotaFiscalEletronicaEmissaoItemModel; index: number }) {
            if (!item.produtoId) {
                item.produtoId = 0;
            }

            if (item.ufDesembaracoId) {
                item.ufDesembaraco = Ufs[item.ufDesembaracoId];
            }

            if (!item.sujeitoIPI) {
                item.cstIPI = null;
                item.baseIPI = null;
                item.aliquotaIPI = null;
                item.valorIPI = null;
            }

            if (!item.comICMSPartilha) {
                item.baseUFDestino = null;
                item.aliquotaUFDestino = null;
                item.aliquotaInterestadual = null;
                item.aliquotaInterestadualPartilha = null;
                item.ICMSUFDestino = null;
                item.ICMSUFRemetente = null;
                item.baseFCPUFDestino = null;
                item.aliquotaFCPUFDestino = null;
                item.ICMSFCPUFDestino = null;
            }

            item = calculaValoresICMS(item);

            if (item.sujeitoIPI) {
                calculaImposto(item, "IPI");
            } else {
                item.baseIPI = null;
                item.aliquotaIPI = null;
                item.valorIPI = null;
            }
            calculaImposto(item, "PIS");
            calculaImposto(item, "COFINS");

            store.nota.itens[index] = item;
            store.nota = totalizaValorNota(store.nota, store.emissaoVenda);
        },
        SET_NOTA_CONSULTA(store, newConsulta: boolean) {
            store.consulta = newConsulta;
        },
        SET_NOTA_EMISSAO_VENDA(store, newEmissaoVenda: boolean) {
            store.emissaoVenda = newEmissaoVenda;
        },
        CONSOLIDA_NOTA(store, venda: VendaModel) {
            store.nota.itens.forEach((item, index) => {
                store.nota.itens[index].valorDecontoAntesRateio = item.valorDesconto;
                store.nota.itens[index].valorFreteAntesRateio = item.valorFrete;
            });
            store.nota.itens = consolidaValoresItens(venda, store.nota.itens);
            store.nota.itens = rateioTaxaEntrega(venda, store.nota.itens);

            store.nota.itens.forEach(item => {
                if (item.tipoCSTICMS == TipoCST.TRIBUTADO) {
                    item.baseICMS -= item.valorDesconto - item.valorDecontoAntesRateio;
                    item.baseICMS += item.valorFrete - item.valorFreteAntesRateio;
                    calculaImposto(item, "ICMS");
                    item = calculaValoresICMS(item);
                }
            });

            if (venda.taxaEntrega > 0 || venda.transportadoraId > 0) {
                store.nota.modalidadeFrete = 0;
            }

            if (store.nota?.cobranca?.duplicatas?.length == 0) {
                store.nota.cobranca = null;
            }

            store.nota = totalizaValorNota(store.nota, store.emissaoVenda, venda);
        },
    },
};

export default nfeContext;
