import BaseModel from "../base/baseModel";
import AreaAtuacao from "../enum/areaAtuacao";
import NaturezaOperacoes from "../enum/naturezaOperacoes";
import tipoCr from "../enum/tipoCr";
import UsoApp from "../enum/usoApp";

export default class RedeModel extends BaseModel {
    id?: number = null;
    razaoSocial: string = null;
    nomeFantasia: string = null;
    cnpj: string = null;
    inscricaoEstadual: string = null;
    inscricaoMunicipal: string = null;
    logradouro: string = null;
    complemento: string = null;
    numero?: number = null;
    cep: string = null;
    bairro: string = null;
    cidadeId?: number = null;
    estadoId?: number = null;
    telefone: string = null;
    celular: string = null;
    email: string = null;
    responsavelTecnico: string = null;
    conselhoRegional: string = null;
    naturezaOperacoes: NaturezaOperacoes = null;
    opcaoSimplesNacional = false;
    incentivadorCultural = false;
    areaAtuacao: AreaAtuacao = 0;
    tipoCr: tipoCr = 0;
    quantidadeFranquias?: number = null;
    utilizaDotz = false;
    usoApp: UsoApp = null;
}
