import Vue from "vue";

import actionBarComponent from "@/components/child/actionBar/actionBar.vue";
import checkboxComponent from "@/components/child/form/checkbox.vue";
import comboComponent from "@/components/child/form/combo.vue";
import modalComponent from "@/components/child/modal/modal.vue";
import { Component } from "@/decorators";
import PaginationModel from "@/models/paginationModel";
import TelaAcaoModel from "@/models/telaAcaoModel";
import TelaModel from "@/models/telaModel";
import TelaAcaoService from "@/services/telaAcaoService";
import TelaService from "@/services/telaService";

import telaEditComponent from "../tela/edit.vue";

import "../crud.scss";

@Component({
    components: {
        comboComponent,
        checkboxComponent,
        modalComponent,
        telaEditComponent,
        actionBarComponent,
    },
})
export default class TelaAcaoEditComponent extends Vue {
    private service = new TelaAcaoService();

    model = new TelaAcaoModel();
    modelId = 0;

    telasOptions: Array<Object> = [];

    private async load() {
        this.model = new TelaAcaoModel();

        if (this.modelId > 0) {
            try {
                const data = await this.service.get(this.modelId).withLoading().resolveWithJSON<TelaAcaoModel>();

                this.model.updateFrom(data);
            } catch {
                this.$router.back();
            }
        }
    }

    private async save() {
        try {
            const isValid = await this.$validator.validateAll();
            if (isValid) {
                const sucesso = await this.service[!this.model.id ? "insert" : "update"](this.model)
                    .withLoading()
                    .resolveWithoutJSON();

                if (sucesso) {
                    await this.$showInclusaoUpdate(this.model.id);
                    this.$router.back();
                }
            } else {
                this.$focusErrorField();
            }
        } catch {}
    }

    public async loadTelas() {
        try {
            const data = await new TelaService().combo().then(r => r.json() as Promise<PaginationModel<TelaModel>>);
            this.telasOptions = data.list.map(item => ({ value: item.id, text: item.descricao }));
        } catch {}
    }

    private cancel() {
        this.$router.back();
    }

    private mounted() {
        this.loadTelas();

        if (this.$route.params.id) {
            this.modelId = +this.$route.params.id;
            this.load();
        }
    }
}
