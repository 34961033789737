import ConvenioModel from "../models/convenioModel";
import nfetch from "../utils/events/nfetch";

import { IService } from "./base/iService";
export default class ConvenioService extends IService<ConvenioModel> {
    constructor() {
        super("Convenio");
    }

    public GetFromCliente(clienteId: number): Promise<Response> {
        //@ts-ignore
        return nfetch(`/${this.controllerName}/GetFromCliente?clienteId=${clienteId}`, {
            credentials: "same-origin"
        }).then((response: Response) => {
            if (response.ok) {
                return response;
            } else {
                return this.mensagemErro(response, "getFromPrescritor");
            }
        }).catch((error) => {
        });
    }
    public getFromPrescritor(id: number): Promise<Response> {
        //@ts-ignore
        return nfetch(`/${this.controllerName}/getFromPrescritor?id=${id}`, {
            credentials: "same-origin"
        }).then((response: Response) => {
            if (response.ok) {
                return response;
            } else {
                return this.mensagemErro(response, "getFromPrescritor");
            }
        }).catch((error) => {
        });
    }
}