var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "crud crud-list" },
    [
      !_vm.isModal
        ? _c("div", { staticClass: "info-div" }, [
            _c("h1", { staticClass: "title" }, [
              _vm._v(
                "\n            " +
                  _vm._s(
                    _vm.$t(
                      "__.Components.parent.historico.vendaHistorico.list_vue_html.historicoVendas"
                    )
                  ) +
                  "\n        "
              ),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "grid-component",
        {
          attrs: {
            data: _vm.gridData,
            tituloPagina: _vm.$t(
              "__.Components.parent.historico.vendaHistorico.list_vue_html.vendaHistorico"
            ),
            columns: _vm.gridColumns,
            showTools: false,
            showAddNewButton: false,
            "page-index": _vm.pageIndex,
            "page-size": _vm.pageSize,
            controlaPageSize: true,
            total: _vm.total,
            showEditItem: false,
            showRemoveItem: false,
            extraActions: _vm.extraActions,
          },
          on: {
            "change-filter-key": _vm.onChangeFilterKey,
            "change-sort": _vm.onChangeSort,
            "change-page": _vm.onChangePage,
            "change-page-size": _vm.onChangePageSize,
            "extra-action": _vm.onExtraAction,
          },
        },
        [
          _c("div", { attrs: { slot: "header" }, slot: "header" }, [
            _c("div", { staticClass: "filtro-lista" }, [
              _c(
                "button",
                {
                  staticClass: "filtro-avancado-button",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      _vm.filtro.showFiltroAvancado =
                        !_vm.filtro.showFiltroAvancado
                    },
                  },
                },
                [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.$t("__.Crud.filtroAvancado")) +
                      "\n                    "
                  ),
                  _c("i", { staticClass: "fa fa-caret-down" }),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.filtro.showFiltroAvancado,
                      expression: "filtro.showFiltroAvancado",
                    },
                  ],
                  staticClass: "drop-lista",
                },
                [
                  _c(
                    "form",
                    {
                      attrs: { novalidate: "novalidate" },
                      on: {
                        submit: function ($event) {
                          $event.preventDefault()
                          return _vm.onFiltrarAvancado($event)
                        },
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "radio-group",
                          staticStyle: { "margin-bottom": "15px" },
                        },
                        [
                          _c("div", { staticStyle: { display: "table" } }, [
                            _c("label", [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.filtro.Continuo,
                                    expression: "filtro.Continuo",
                                  },
                                ],
                                attrs: {
                                  name: "optradio",
                                  type: "radio",
                                  value: "",
                                },
                                domProps: {
                                  checked: _vm._q(_vm.filtro.Continuo, ""),
                                },
                                on: {
                                  change: function ($event) {
                                    _vm.$set(_vm.filtro, "Continuo", "")
                                  },
                                },
                              }),
                              _vm._v(" "),
                              _c("span", [
                                _vm._v(
                                  "\n                                        " +
                                    _vm._s(
                                      _vm.$t(
                                        "__.Components.parent.historico.vendaHistorico.list_vue_html.tudo"
                                      )
                                    ) +
                                    "\n                                    "
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("br"),
                            _vm._v(" "),
                            _c("br"),
                            _vm._v(" "),
                            _c("br"),
                            _vm._v(" "),
                            _c("label", [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.filtro.Continuo,
                                    expression: "filtro.Continuo",
                                  },
                                ],
                                attrs: {
                                  name: "optradio",
                                  type: "radio",
                                  value: "Sim",
                                },
                                domProps: {
                                  checked: _vm._q(_vm.filtro.Continuo, "Sim"),
                                },
                                on: {
                                  change: function ($event) {
                                    _vm.$set(_vm.filtro, "Continuo", "Sim")
                                  },
                                },
                              }),
                              _vm._v(" "),
                              _c("span", [
                                _vm._v(
                                  "\n                                        " +
                                    _vm._s(
                                      _vm.$t(
                                        "__.Components.parent.historico.vendaHistorico.list_vue_html.contiuo"
                                      )
                                    ) +
                                    "\n                                    "
                                ),
                              ]),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("br"),
                        ]
                      ),
                      _vm._v(" "),
                      _c("b", [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "__.Components.parent.historico.vendaHistorico.list_vue_html.dtVenda"
                            )
                          )
                        ),
                      ]),
                      _vm._v(" "),
                      _c("date-time-picker-component", {
                        staticStyle: { width: "100%" },
                        model: {
                          value: _vm.filtro.DataVenda,
                          callback: function ($$v) {
                            _vm.$set(_vm.filtro, "DataVenda", $$v)
                          },
                          expression: "filtro.DataVenda",
                        },
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "form-group" }, [
                        _c(
                          "button",
                          {
                            staticClass: "botao-filtrar",
                            attrs: { type: "submit" },
                          },
                          [
                            _vm._v(
                              "\n                                " +
                                _vm._s(_vm.$t("__.Crud.filtrar")) +
                                "\n                            "
                            ),
                          ]
                        ),
                      ]),
                    ],
                    1
                  ),
                ]
              ),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "shortcutComponent",
        {
          ref: "shortcutComponent",
          attrs: {
            title: _vm.$t(
              "__.Components.parent.historico.vendaHistorico.list_vue_html.visuVenda"
            ),
            showAddNew: false,
            showBtnOk: false,
          },
          on: { "selection-item": _vm.onVer },
        },
        [
          _c(
            "div",
            { attrs: { slot: "component" }, slot: "component" },
            [
              _vm.showVer
                ? _c("ver-component", {
                    ref: "verComponent",
                    attrs: { data: _vm.modelVer },
                  })
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }