import BaseModel from "../../../../../base/baseModel";
import TiposPessoa from "../../../../../enum/tiposPessoa";

export default class TransportadorNfeModel extends BaseModel {
    tipoPessoa: TiposPessoa = null;
    nome: string = null;
    cnpj: string = null;
    inscricaoEstadual: string = null;
    cpf: string = null;
    endereco: string = null;
    cidadeNome: string = null;
    uf: string = null;
}
