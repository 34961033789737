import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);
import atendimentoContext from "./atendimentoContext/index";
import { AtendimentoContextState } from "./atendimentoContext/types";
import fastActionStore, { FastActionMutations, FastActionState } from "./fastActionStore";
import loadDashboard, { LoadDashMutations, LoadDashState } from "./loadDashboard";
import loadListStore, { LoadListMutations, LoadListState } from "./loadListStore";
import manipulacaoContext, { ManipulacaoContextGetters } from "./manipulacaoContext/index";
import { ManipulacaoContextState } from "./manipulacaoContext/types";
import nfeContext, { NfeContextMutations, NfeContextState } from "./nfeContext";
import notificationStore, { NotificationStoreMutations, NotificationStoreState } from "./notificationStore";
import paginationStatus, { PaginationStatusMutations, PaginationStatusState } from "./paginationStatusStore";
import sessionStore, { SessionGetters, SessionMutations, SessionState } from "./sessionStore";

export { SessionActions } from "./sessionStore";
export { LoadListProps } from "./loadListStore";

export type AppState = {
    fastAction: FastActionState;
    preLoad: LoadListState;
    loadDash: LoadDashState;
    paginationStatus: PaginationStatusState;
    session: SessionState;
    notification: NotificationStoreState;
    nfeContext: NfeContextState;
    manipulacaoContext: Map<string, ManipulacaoContextState>;
    atendimentoContext: AtendimentoContextState;
};

type Mutation =
    | FastActionMutations
    | LoadListMutations
    | LoadDashMutations
    | PaginationStatusMutations
    | SessionMutations
    | NotificationStoreMutations
    | NfeContextMutations;

export type Mutations = Mutation[];

type Getter = SessionGetters | ManipulacaoContextGetters;

export type Getters = Getter[];

export default new Vuex.Store<AppState>({
    modules: {
        fastAction: fastActionStore,
        preLoad: loadListStore,
        loadDash: loadDashboard,
        paginationStatus: paginationStatus,
        session: sessionStore,
        notification: notificationStore,
        nfeContext: nfeContext,
        manipulacaoContext: manipulacaoContext,
        atendimentoContext: atendimentoContext,
    },
});
