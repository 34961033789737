import Vue from "vue";

import comboComponent from "@/components/child/form/combo.vue";
import { Component } from "@/decorators";
import MotivoRejeicaoModel from "@/models/motivoRejeicaoModel";
import PaginationModel from "@/models/paginationModel";
import MotivoRejeicaoService from "@/services/motivoRejeicaoService";
import VendaService from "@/services/vendaService";

import ShortcutComponent from "./../shortcut/shortcut";
import shortcutComponent from "./../shortcut/shortcut.vue";

@Component({
    components: {
        shortcutComponent,
        comboComponent,
    },
})
export default class ArquivarOrcamentoComponent extends Vue {
    private motivoRejeicaoService = new MotivoRejeicaoService();
    private vendaService = new VendaService();

    private shortcutComponent: ShortcutComponent = null;
    private motivosOptions: Array<Object> = [];
    private motivoRejeicaoId: number = null;
    private orcamentoId = null;

    public async show(id: number) {
        this.shortcutComponent = this.$refs.shortcutComponent as ShortcutComponent;

        this.motivoRejeicaoId = null;

        const msg = this.$t("__.ts.opcIraArquivOrca") as string;
        const question = this.$t("__.ts.desejaProsseg") as string;

        const response = await this.$showQuestion(this.$t("__.ts.atencao"), msg + question);

        if (response) {
            try {
                const response = await this.motivoRejeicaoService
                    .combo()
                    .resolveWithJSON<PaginationModel<MotivoRejeicaoModel>>();

                this.motivosOptions = response.list.map(item => ({
                    value: item.id,
                    text: item.descricao,
                }));
            } catch {}

            this.shortcutComponent.title = this.$t("__.ts.informeMotivoRejeicaoCampos") as string;
            this.shortcutComponent.show();
            this.orcamentoId = id;
        }
    }

    public async onSendMotivoRejeicao() {
        const sucesso = await this.vendaService
            .rejeitar(this.orcamentoId, this.motivoRejeicaoId)
            .withLoading()
            .resolveWithoutJSON();

        if (sucesso) {
            await this.$showSuccess(this.$t("__.ts.sucesso"), this.$t("__.ts.arqSucesso"));

            this.$emit("orcamento-arquivado");

            this.shortcutComponent.hide();
        }
    }
}
