import Vue from "vue";
import { mapGetters } from "vuex";

import buttonIncluirComponent from "@/components/child/form/button/buttonIncluir.vue";
import comboComponent from "@/components/child/form/combo.vue";
import dataTooltipComponent from "@/components/child/form/datatooltip.vue";
import dateTimePickerComponent from "@/components/child/form/datetimepicker.vue";
import fieldsetComponent from "@/components/child/form/fieldset.vue";
import gridComponent from "@/components/child/grid/grid.vue";
import { GridColumn, GridColumnType } from "@/components/child/grid/gridColumn";
import { Component } from "@/decorators";
import DadosAdicionaisFichaTecnicaModel from "@/models/dadosAdicionaisFichaTecnicaModel";
import DadosAdicionaisFichaTecnicaResultadoModel from "@/models/dadosAdicionaisFichaTecnicaResultadoModel";
import EnsaioFichaTecnicaModel from "@/models/ensaioFichaTecnicaModel";
import EnsaioFichaTecnicaResultadoModel from "@/models/ensaioFichaTecnicaResultadoModel";
import EnsaiosModel from "@/models/ensaiosModel";
import FichaTecnicaModel from "@/models/fichaTecnicaModel";
import PaginationModel from "@/models/paginationModel";
import ProdutoLoteModel from "@/models/produtoLoteModel";
import DadosAdicionaisService from "@/services/dadosAdicionaisFichaTecnicaService";
import EnsaioService from "@/services/ensaioService";
import FichaTecnicaService from "@/services/fichaTecnicaService";
import { Getters } from "@/store/store";

import DadosAdicionaisFichaTecnicaComponent from "../crud/dadosAdicionaisFichaTecnica/edit";
import dadosAdicionaisFichaTecnica from "../crud/dadosAdicionaisFichaTecnica/edit.vue";
import ShortcutComponent from "../shortcut/shortcut";
import shortcutComponent from "../shortcut/shortcut.vue";

import gridEnsaiosComponent from "./gridEnsaios.vue";
import GridLoteComponent from "./gridLote";
import gridLoteComponent from "./gridLote.vue";
import GridResultadosComponent from "./gridResultados";
import gridResultadosComponent from "./gridResultados.vue";

import "../crud/crud.scss";

export type Options = {
    text: string;
    value: number;
};

type DadosAdicionaisGrid = {
    id?: number;
    dadosAdicionaisId?: number;
    descricao: string;
    produtoId?: number;
    validado?: boolean;
    observacao?: string;
};

@Component({
    components: {
        dadosAdicionaisFichaTecnica,
        comboComponent,
        gridComponent,
        gridEnsaiosComponent,
        fieldsetComponent,
        dateTimePickerComponent,
        dataTooltipComponent,
        shortcutComponent,
        gridLoteComponent,
        gridResultadosComponent,
        buttonIncluirComponent,
    },
    computed: {
        ...mapGetters(["VALIDAR_PERMISSAO_USUARIO", "VALIDAR_PERMISSAO_SOMENTE_CONSULTA"] as Getters),
    },
})
export default class FichaTecnicaEditComponent extends Vue {
    // State computed props
    VALIDAR_PERMISSAO_USUARIO: (telaDescricao: string, acaoController: string) => boolean;
    VALIDAR_PERMISSAO_SOMENTE_CONSULTA: (telaDescricao: string) => boolean;

    private dadosAdicionaisFichaTecnicaComponent: DadosAdicionaisFichaTecnicaComponent = null;
    private shortcutComponent: ShortcutComponent = null;
    private gridLoteComponent: GridLoteComponent = null;
    private gridResultadosComponent: GridResultadosComponent = null;
    private service = new FichaTecnicaService();
    private ensaioService = new EnsaioService();

    somenteConsulta = false;
    private model: FichaTecnicaModel = new FichaTecnicaModel();

    private ensaios: EnsaioFichaTecnicaModel[] = [];
    private ensaiosSoludibilidade: EnsaioFichaTecnicaModel[] = [];
    private ensaiosInitial: EnsaioFichaTecnicaModel[] = [];
    private ensaiosSoludibilidadeInitial: EnsaioFichaTecnicaModel[] = [];
    private ensaiosOptions: EnsaiosModel[] = [];

    private dadosAdicionaisSelected: number = null;
    private optionsDadosAdicionais: Options[] = [];

    private gridDataDadosAdicionais: DadosAdicionaisGrid[] = [];
    private get gridColumnsDadosAdicionais(): GridColumn[] {
        return [
            new GridColumn("descricao", "Descri\u00e7\u00e3o", GridColumnType.String),
            new GridColumn("validado", "Validado", GridColumnType.Boolean, true),
            new GridColumn("observacao", "Observa\u00e7\u00e3o", GridColumnType.String, true),
        ];
    }

    public async loadFicha(produtoId: number) {
        try {
            const data = await this.service
                .getFichaTecnica(produtoId)
                .withLoading()
                .resolveWithJSON<FichaTecnicaModel>();

            this.model.updateFrom(data);

            this.ensaios = this.model.ensaioFichaTecnica.filter(e => !e.ensaio.soludibilidade);
            this.ensaiosSoludibilidade = this.model.ensaioFichaTecnica.filter(e => e.ensaio.soludibilidade);

            this.ensaiosInitial = this.ensaios;
            this.ensaiosSoludibilidadeInitial = this.ensaiosSoludibilidade;

            await this.loadDadosAdicionais();

            this.gridDataDadosAdicionais = this.model.dadosAdicionaisResultados.map(dadoAdicional => ({
                ...dadoAdicional,
                descricao: dadoAdicional.dadosAdicionais.descricao,
            }));
            this.optionsDadosAdicionais = this.removeOptionsUtilizadas(
                this.optionsDadosAdicionais,
                this.gridDataDadosAdicionais,
            );
        } catch {}
    }

    private async loadDadosAdicionais() {
        try {
            const data = await new DadosAdicionaisService()
                .combo()
                .resolveWithJSON<PaginationModel<DadosAdicionaisFichaTecnicaModel>>();
            this.optionsDadosAdicionais = data.list
                .map(da => ({ value: da.id, text: da.descricao }))
                .filter(da => this.gridDataDadosAdicionais.filter(d => d.descricao === da.text).length == 0);
        } catch {}
    }

    private onChangeEnsaios(model: EnsaioFichaTecnicaModel[]) {
        this.ensaios = model;
    }

    private onChangeEnsaiosSoludibilidade(model: EnsaioFichaTecnicaModel[]) {
        this.ensaiosSoludibilidade = model;
    }

    private async openNewDadosAdicionais() {
        this.dadosAdicionaisFichaTecnicaComponent = this.$refs
            .dadosAdicionaisFichaTecnicaComponent as DadosAdicionaisFichaTecnicaComponent;
        this.dadosAdicionaisFichaTecnicaComponent.load();
    }

    private onAddNewDadosAdicionais() {
        this.dadosAdicionaisFichaTecnicaComponent.save();
    }

    private async onDadosAdicionaisSaveOk() {
        await this.loadDadosAdicionais();
    }

    private onInclusaoDadosAdicionais() {
        const dadosAdicionaisComponent = this.$el.querySelector("#dadosAdicionaisId") as HTMLSelectElement;
        if (this.dadosAdicionaisSelected > 0) {
            const alreadyExists =
                this.gridDataDadosAdicionais.filter(da => da.id == this.dadosAdicionaisSelected).length > 0;

            if (!alreadyExists) {
                this.gridDataDadosAdicionais.push({
                    descricao: dadosAdicionaisComponent.innerText,
                    id: null,
                    dadosAdicionaisId: this.dadosAdicionaisSelected,
                    validado: false,
                    observacao: "",
                });
                this.optionsDadosAdicionais = this.removeOptionsUtilizadas(
                    this.optionsDadosAdicionais,
                    this.gridDataDadosAdicionais,
                );

                this.dadosAdicionaisSelected = -1;
            }
        }
    }

    private async onRemoveDadosAdicionais(model: DadosAdicionaisGrid) {
        this.gridDataDadosAdicionais = this.gridDataDadosAdicionais.filter(da => da != model);

        await this.loadDadosAdicionais();
    }

    private removeOptionsUtilizadas(options: Options[], utilizados: DadosAdicionaisGrid[]): Options[] {
        return options.filter(o => utilizados.filter(u => o.text == u.descricao).length == 0);
    }

    private consolidaDadosModel() {
        this.model.dadosAdicionais = this.gridDataDadosAdicionais.map(da => {
            const dadosAdicionaisModel = new DadosAdicionaisFichaTecnicaModel();
            dadosAdicionaisModel.id = da.id || 0;
            dadosAdicionaisModel.produtoId = this.model.produtoId;
            dadosAdicionaisModel.descricao = da.descricao;
            return dadosAdicionaisModel;
        });

        this.model.dadosAdicionaisResultados = this.gridDataDadosAdicionais.map(r => {
            const resultadoModel = new DadosAdicionaisFichaTecnicaResultadoModel();
            resultadoModel.id = r.id;
            resultadoModel.dadosAdicionaisId = r.dadosAdicionaisId;
            resultadoModel.validado = r.validado;
            resultadoModel.observacao = r.observacao;
            return resultadoModel;
        });
        this.model.ensaioFichaTecnica = [...this.ensaios, ...this.ensaiosSoludibilidade];
    }

    public async imprimir() {
        if (this.VALIDAR_PERMISSAO_USUARIO("produtos", "update")) {
            if (!(await this.save(false))) {
                return;
            }
        }

        this.shortcutComponent = this.$refs.shortcutComponent as ShortcutComponent;
        this.gridLoteComponent = this.$refs.gridLoteComponent as GridLoteComponent;
        this.shortcutComponent.title = "Selecione o lote";
        this.shortcutComponent.show();

        await this.gridLoteComponent.load(this.model.produtoId);
    }

    public async resultados() {
        if (this.VALIDAR_PERMISSAO_USUARIO("produtos", "update")) {
            if (!(await this.save(false))) {
                return;
            }
        }

        this.gridResultadosComponent = this.$refs.gridResultadosComponent as GridResultadosComponent;
        await this.gridResultadosComponent.load(this.model);
    }

    private async onImprimirLote(lote: ProdutoLoteModel) {
        this.shortcutComponent.hide();

        await this.service.geraRelatorio(lote.id);
    }

    private addResultado(item: EnsaioFichaTecnicaModel, loteSelecionado: number) {
        if (!item.ensaioFichaTecnicaResultados) {
            item.ensaioFichaTecnicaResultados = [];
        }
        const ensaioResultado = new EnsaioFichaTecnicaResultadoModel();
        ensaioResultado.ensaioFichaTecnicaId = item.id;
        ensaioResultado.produtoLoteId = loteSelecionado;
        ensaioResultado.resultado = item.resultado;

        return ensaioResultado;
    }

    private async onResultados(ensaios: EnsaioFichaTecnicaModel[], loteSelecionado: number) {
        for (const item of this.ensaios) {
            const ensaio = ensaios.find(x => x.id == item.id);
            if (ensaio) {
                const ensaioResultado = this.addResultado(ensaio, loteSelecionado);
                item.ensaioFichaTecnicaResultados.push(ensaioResultado);
            }
        }

        for (const item of this.ensaiosSoludibilidade) {
            const ensaio = ensaios.find(x => x.id == item.id);
            if (ensaio) {
                const ensaioResultado = this.addResultado(ensaio, loteSelecionado);
                item.ensaioFichaTecnicaResultados.push(ensaioResultado);
            }
        }

        const retorno = await this.save();
        if (retorno) {
            const response = await this.$showQuestion(
                this.$t("__.ts.impFichaTecnica") as string,
                this.$t("__.ts.desejaImp") as string,
            );

            if (response) {
                await this.service.geraRelatorio(loteSelecionado);
            }
        }
    }

    public async save(showOkMessage = true) {
        this.consolidaDadosModel();

        let mensagem = "";
        this.model.ensaioFichaTecnica.forEach(e => {
            if (!e.especificacao) {
                mensagem = `Ensaio ${e.ensaio.descricao} deve conter uma especificação`;
            }
        });

        if (mensagem) {
            this.$showError(this.$t("__.ts.erro"), mensagem);
            return false;
        }

        try {
            const isValid = await this.$validator.validateAll();
            if (isValid) {
                const sucesso = await this.service.salvaFichaTecnica(this.model).withLoading().resolveWithoutJSON();
                if (sucesso) {
                    if (showOkMessage) {
                        await this.$showInclusaoUpdate(this.model.id);
                    }
                    await this.loadFicha(this.model.produtoId);
                    return true;
                }
            }
        } catch {}
        return false;
    }

    private async loadEnsaios() {
        const data = await this.ensaioService.combo().resolveWithJSON<PaginationModel<EnsaiosModel>>();
        this.ensaiosOptions = data.list;
    }

    private cancel() {
        this.$router.back();
    }

    private async mounted() {
        this.shortcutComponent = this.$refs.shortcutComponent as ShortcutComponent;
        this.gridLoteComponent = this.$refs.gridLoteComponent as GridLoteComponent;
        this.gridResultadosComponent = this.$refs.gridResultadosComponent as GridResultadosComponent;

        this.somenteConsulta = this.VALIDAR_PERMISSAO_SOMENTE_CONSULTA("produtos");

        this.loadEnsaios();
    }
}
