enum TipoCampoConfiguracao {
    Long = 0, //long?
    String = 1, //string
    Boolean = 2, //boolean
    Estoque = 3, //id tabela estoque
    ContaCorrente = 4, //id tabela conta corrente
    ContaGerencialEntrada = 5, //id Tabela conta gerencial
    ContaGerencialSaida = 6, //id Tabela conta gerencial
    Servico = 7, //id tabela servico
    Fornecedor = 8, //id tabela fornecedor
    NaturezaOperacaoDentroEstado = 9, //id naturezaOperacao
    NaturezaOperacaoForaEstado = 10, //id naturezaOperacao
    Usuario = 11, //id usaurio
    ConfiguracaoRotulo = 12, //id rotulo
    Decimal = 13, //id decimal
    Percentual = 15, //Percentual
}

export default TipoCampoConfiguracao;
