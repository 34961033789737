var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "crud crud-edit",
      staticStyle: { "max-width": "calc(100vw - 115px)" },
    },
    [
      _c("actionBarComponent", {
        attrs: {
          title: _vm.$t("__.Components.parent.gradePCP.view_vue_html.gradePCP"),
          showSave: false,
        },
      }),
      _vm._v(" "),
      _c("form", { staticStyle: { position: "relative" } }, [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            {
              class:
                _vm.isFracionamento || _vm.isEstereis ? "col-sm-2" : "col-sm-3",
            },
            [
              _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c(
                    "label",
                    {
                      staticClass: "control-label",
                      attrs: { for: "dataInicial" },
                    },
                    [
                      _vm._v(
                        "\n                        " +
                          _vm._s(
                            _vm.$t(
                              "__.Components.parent.gradePCP.view_vue_html.dataInicial"
                            )
                          ) +
                          "\n                    "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("date-time-picker-component", {
                    attrs: { id: "dataInicial", name: "dataInicial" },
                    model: {
                      value: _vm.requestModel.dataInicial,
                      callback: function ($$v) {
                        _vm.$set(_vm.requestModel, "dataInicial", $$v)
                      },
                      expression: "requestModel.dataInicial",
                    },
                  }),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              class:
                _vm.isFracionamento || _vm.isEstereis ? "col-sm-2" : "col-sm-3",
            },
            [
              _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c(
                    "label",
                    {
                      staticClass: "control-label",
                      attrs: { for: "dataFinal" },
                    },
                    [
                      _vm._v(
                        "\n                        " +
                          _vm._s(
                            _vm.$t(
                              "__.Components.parent.gradePCP.view_vue_html.dataFinal"
                            )
                          ) +
                          "\n                    "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("date-time-picker-component", {
                    attrs: { id: "dataFinal", name: "dataFinal" },
                    model: {
                      value: _vm.requestModel.dataFinal,
                      callback: function ($$v) {
                        _vm.$set(_vm.requestModel, "dataFinal", $$v)
                      },
                      expression: "requestModel.dataFinal",
                    },
                  }),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "col-sm-3" }, [
            _c(
              "div",
              { staticClass: "form-group" },
              [
                _c(
                  "label",
                  {
                    staticClass: "control-label",
                    attrs: { for: "formaFarmaceuticaId" },
                  },
                  [
                    _vm._v(
                      "\n                        " +
                        _vm._s(
                          _vm.$t(
                            "__.Components.parent.gradePCP.view_vue_html.formasFarmacIgnorar"
                          )
                        ) +
                        "\n                    "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("data-tooltip-component", {
                  attrs: {
                    text: _vm.$t(
                      "__.Components.parent.gradePCP.view_vue_html.oSistemaDesconsiFormasSelec"
                    ),
                  },
                }),
                _vm._v(" "),
                _c("multiSelect-component", {
                  attrs: {
                    id: "formaFarmaceuticaId",
                    name: "formaFarmaceuticaId",
                    options: _vm.formasFarmaceuticaOptions,
                  },
                  model: {
                    value: _vm.formasFarmaceuticasIds,
                    callback: function ($$v) {
                      _vm.formasFarmaceuticasIds = $$v
                    },
                    expression: "formasFarmaceuticasIds",
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _vm.isFracionamento || _vm.isEstereis
            ? _c("div", { staticClass: "col-sm-3" }, [
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c(
                      "label",
                      { staticClass: "control-label", attrs: { for: "setor" } },
                      [
                        _vm._v(
                          "\n                        " +
                            _vm._s(
                              _vm.$t(
                                "__.Components.parent.venda.editSingle_vue_html.setor"
                              )
                            ) +
                            "\n                    "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("combo-component", {
                      attrs: {
                        id: "setor",
                        name: "setor",
                        data: _vm.setorOptions,
                      },
                      model: {
                        value: _vm.requestModel.setor,
                        callback: function ($$v) {
                          _vm.$set(_vm.requestModel, "setor", $$v)
                        },
                        expression: "requestModel.setor",
                      },
                    }),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            {
              class:
                _vm.isFracionamento || _vm.isEstereis ? "col-sm-2" : "col-sm-3",
            },
            [
              _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c(
                    "label",
                    {
                      staticClass: "control-label",
                      attrs: { for: "tipoVendaId" },
                    },
                    [
                      _vm._v(
                        "\n                        " +
                          _vm._s(
                            _vm.$t(
                              "__.Components.parent.gradePCP.view_vue_html.tipoVendaAIgnorar"
                            )
                          ) +
                          "\n                    "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("data-tooltip-component", {
                    attrs: {
                      text: _vm.$t(
                        "__.Components.parent.gradePCP.view_vue_html.oSistemaDesconsiTiposVendasSelec"
                      ),
                    },
                  }),
                  _vm._v(" "),
                  _c("multiSelect-component", {
                    attrs: {
                      id: "tipoVendaId",
                      name: "tipoVendaId",
                      options: _vm.tiposVendasOptions,
                      defaultValue: _vm.defaultTipoVenda,
                    },
                    model: {
                      value: _vm.tiposVendas,
                      callback: function ($$v) {
                        _vm.tiposVendas = $$v
                      },
                      expression: "tiposVendas",
                    },
                  }),
                ],
                1
              ),
            ]
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "grade-pcp-container" }, [
          _c("table", { staticClass: "grade-pcp" }, [
            _c("thead", { staticClass: "grade-pcp-background-azul" }, [
              _c(
                "tr",
                [
                  _c("th", { staticClass: "grade-pcp-fixed" }, [_vm._v(" ")]),
                  _vm._v(" "),
                  _vm._l(_vm.model.dias, function (dia, index) {
                    return _c("th", { key: index, attrs: { colspan: "3" } }, [
                      _vm._v(_vm._s(dia)),
                    ])
                  }),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "tr",
                { staticStyle: { height: "44px" } },
                [
                  _c("th", { staticClass: "grade-pcp-fixed" }, [
                    _vm._v(
                      "\n                            " +
                        _vm._s(
                          _vm.$t(
                            "__.Components.parent.gradePCP.view_vue_html.producao"
                          )
                        ) +
                        "\n                        "
                    ),
                  ]),
                  _vm._v(" "),
                  _vm._l(_vm.model.dias, function (dia, index) {
                    return [
                      _c(
                        "th",
                        { key: index + "dias-1", staticClass: "bg-primary" },
                        [
                          _vm._v(
                            "\n                                " +
                              _vm._s(
                                _vm.$t(
                                  "__.Components.parent.gradePCP.view_vue_html.capacidade"
                                )
                              ) +
                              "\n                            "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "th",
                        { key: index + "dias-2", staticClass: "bg-primary" },
                        [
                          _vm._v(
                            "\n                                " +
                              _vm._s(
                                _vm.$t(
                                  "__.Components.parent.gradePCP.view_vue_html.vendas"
                                )
                              ) +
                              "\n                            "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "th",
                        { key: index + "dias-3", staticClass: "bg-primary" },
                        [
                          _vm._v(
                            "\n                                " +
                              _vm._s(
                                _vm.$t(
                                  "__.Components.parent.gradePCP.view_vue_html.pendente"
                                )
                              ) +
                              "\n                            "
                          ),
                        ]
                      ),
                    ]
                  }),
                ],
                2
              ),
            ]),
            _vm._v(" "),
            _c(
              "tbody",
              _vm._l(_vm.capacidades, function (capacidade, index) {
                return _c(
                  "tr",
                  { key: index },
                  [
                    _c("th", { staticClass: "grade-pcp-fixed" }, [
                      _vm._v(
                        "\n                            " +
                          _vm._s(capacidade.descricao) +
                          " - " +
                          _vm._s(capacidade.horario) +
                          " /\n                            " +
                          _vm._s(capacidade.horarioFinalDeSemana) +
                          " HS\n                        "
                      ),
                    ]),
                    _vm._v(" "),
                    _vm._l(
                      capacidade.capacidadesDia,
                      function (capacidadeDia, index) {
                        return [
                          _c("td", { key: index + "capacidade-1" }, [
                            _vm._v(_vm._s(capacidadeDia.capacidade)),
                          ]),
                          _vm._v(" "),
                          _c(
                            "td",
                            {
                              key: index + "capacidade-2",
                              class:
                                "grade-pcp-" +
                                [
                                  capacidadeDia.totalVendas >
                                  capacidadeDia.capacidade
                                    ? "vermelho"
                                    : "verde",
                                ],
                            },
                            [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(capacidadeDia.totalVendas) +
                                  "\n                            "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("td", { key: index + "capacidade-3" }, [
                            _vm._v(_vm._s(capacidadeDia.pendente)),
                          ]),
                        ]
                      }
                    ),
                  ],
                  2
                )
              })
            ),
            _vm._v(" "),
            _vm.total != null
              ? _c("tfoot", { staticClass: "grade-total" }, [
                  _c(
                    "tr",
                    [
                      _c("th", { staticClass: "grade-pcp-fixed" }, [
                        _vm._v(_vm._s(_vm.total.descricao)),
                      ]),
                      _vm._v(" "),
                      _vm._l(
                        _vm.total.capacidadesDia,
                        function (capacidadeDia, index) {
                          return [
                            _c("td", { key: index + "capacidade-1" }, [
                              _vm._v(_vm._s(capacidadeDia.capacidade)),
                            ]),
                            _vm._v(" "),
                            _c(
                              "td",
                              {
                                key: index + "capacidade-2",
                                class:
                                  "grade-pcp-" +
                                  [
                                    capacidadeDia.totalVendas >
                                    capacidadeDia.capacidade
                                      ? "vermelho"
                                      : "verde",
                                  ],
                              },
                              [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(capacidadeDia.totalVendas) +
                                    "\n                            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("td", { key: index + "capacidade-3" }, [
                              _vm._v(_vm._s(capacidadeDia.pendente)),
                            ]),
                          ]
                        }
                      ),
                    ],
                    2
                  ),
                ])
              : _vm._e(),
          ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }