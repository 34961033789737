import * as signalR from "@microsoft/signalr";

import Boot from "@/main";
import AtendimentoWhatsAppModel from "@/models/whatsApp/atendimento/atendimentoWhatsAppModel";
import MensagemAtendimentoWhatsAppModel from "@/models/whatsApp/atendimento/mensagemAtendimentoWhatsAppModel";
import { AtendimentoContextActions } from "@/store/atendimentoContext/types";

export default class MensagemAtendimentoHub {
    private connection: signalR.HubConnection = null;

    public async criarConexaoWebSocketMensagemAtendimento() {
        const token = localStorage.getItem("@PharmUP:AtendimentoToken");
        this.connection = new signalR.HubConnectionBuilder()
            .withUrl(`${process.env.VUE_APP_ROOT_WEBSOCKET}/hub/atendimentoHub`, {
                accessTokenFactory: () => token,
            })
            .build();

        this.connection.on("NovaMensagem", async (mensagem: MensagemAtendimentoWhatsAppModel) => {
            Boot.$store.dispatch(AtendimentoContextActions.RECEIVE_MESSAGE, mensagem);
        });

        this.connection.on("UpdateAtendimento", async (atendimento: AtendimentoWhatsAppModel) => {
            Boot.$store.dispatch(AtendimentoContextActions.UPDATE_ATENDIMENTO, atendimento);
        });

        try {
            await this.connection.start();
            await this.connection.invoke("AddGrupo");
        } catch {}
    }
}
