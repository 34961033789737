var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "fieldset-component",
    { attrs: { title: "Transportadora", collapsed: true } },
    [
      _c("div", { attrs: { slot: "rows" }, slot: "rows" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-sm-6" }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "tooltip",
                    rawName: "v-tooltip",
                    value: _vm.errors.first("modalidadeFrete"),
                    expression: "errors.first('modalidadeFrete')",
                  },
                ],
                staticClass: "form-group",
              },
              [
                _c(
                  "label",
                  {
                    staticClass: "control-label",
                    attrs: {
                      for: "modalidadeFrete",
                      title: "* este campo é obrigatório",
                    },
                  },
                  [
                    _c("span", { staticStyle: { color: "red" } }, [
                      _vm._v("*"),
                    ]),
                    _vm._v(
                      "\n                        Modalidade do Frete\n                    "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("combo-component", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required",
                      expression: "'required'",
                    },
                  ],
                  attrs: {
                    id: "modalidadeFrete",
                    name: "modalidadeFrete",
                    data: _vm.modalidadeFreteOptions,
                    searchable: false,
                    disabled: _vm.consulta,
                  },
                  model: {
                    value: _vm.nota.modalidadeFrete,
                    callback: function ($$v) {
                      _vm.$set(_vm.nota, "modalidadeFrete", $$v)
                    },
                    expression: "nota.modalidadeFrete",
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          !_vm.consulta
            ? _c("div", { staticClass: "col-sm-6" }, [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip",
                        value: _vm.errors.first("idTransportadora"),
                        expression: "errors.first('idTransportadora')",
                      },
                    ],
                    staticClass: "form-group",
                  },
                  [
                    _c(
                      "label",
                      {
                        staticClass: "control-label",
                        attrs: {
                          for: "idTransportadora",
                          title: "* este campo é obrigatório",
                        },
                      },
                      [
                        _vm._v(
                          "\n                        Transportadora\n                    "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "combo-component",
                      {
                        attrs: {
                          id: "idTransportadora",
                          name: "idTransportadora",
                          actionName: "transportadora",
                          addEditId:
                            _vm.nota.transportadoraId > 0 && !_vm.consulta
                              ? _vm.nota.transportadoraId
                              : 0,
                          addNew:
                            _vm.nota.transportadoraId > 0 || !_vm.consulta,
                          data: _vm.transportadoraOptions,
                          formAddNewName:
                            _vm.nota.transportadoraId > 0
                              ? "Editar transportadora"
                              : "Nova transportadora",
                          refresh: _vm.consulta
                            ? null
                            : _vm.carregarTransportadoras,
                          searchable: !_vm.consulta,
                          useCloseModalCallback: true,
                        },
                        on: {
                          addNewItemCombo: _vm.onAddTransportadora,
                          openComboEditItem: _vm.openComboEditTransportadora,
                          openComboNewItem: _vm.openNewTransportadora,
                        },
                        model: {
                          value: _vm.nota.transportadoraId,
                          callback: function ($$v) {
                            _vm.$set(_vm.nota, "transportadoraId", $$v)
                          },
                          expression: "nota.transportadoraId",
                        },
                      },
                      [
                        _c(
                          "div",
                          { attrs: { slot: "component" }, slot: "component" },
                          [
                            _c("transportadoraComponent", {
                              ref: "transportadoraComponent",
                              attrs: { CalledByShortCut: true },
                              on: { "save-ok": _vm.onTransportadoraSaveOk },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ])
            : _c("div", { staticClass: "col-sm-6" }, [
                _c("div", { staticClass: "form-group" }, [
                  _c(
                    "label",
                    {
                      staticClass: "control-label",
                      attrs: { for: "transportadoraNome" },
                    },
                    [_vm._v("Transportadora")]
                  ),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.nota.transportadoraNome,
                        expression: "nota.transportadoraNome",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: {
                      type: "text",
                      id: "transportadoraNome",
                      name: "transportadoraNome",
                      disabled: "",
                    },
                    domProps: { value: _vm.nota.transportadoraNome },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.nota,
                          "transportadoraNome",
                          $event.target.value
                        )
                      },
                    },
                  }),
                ]),
              ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-sm-6" }, [
            _c(
              "div",
              { staticClass: "form-group" },
              [
                _c(
                  "label",
                  {
                    staticClass: "control-label",
                    attrs: { for: "cnpjTransportadora" },
                  },
                  [_vm._v("CNPJ")]
                ),
                _vm._v(" "),
                _c("cnpj-component", {
                  staticClass: "form-control",
                  attrs: {
                    id: "cnpjTransportadora",
                    name: "cnpjTransportadora",
                    disabled: _vm.consulta,
                  },
                  model: {
                    value: _vm.nota.transportadoraCnpj,
                    callback: function ($$v) {
                      _vm.$set(_vm.nota, "transportadoraCnpj", $$v)
                    },
                    expression: "nota.transportadoraCnpj",
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-sm-6" }, [
            _c("div", { staticClass: "form-group" }, [
              _c(
                "label",
                {
                  staticClass: "control-label",
                  attrs: { for: "enderecoTransportadora" },
                },
                [_vm._v("Endereço")]
              ),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.nota.transportadoraEndereco,
                    expression: "nota.transportadoraEndereco",
                  },
                ],
                staticClass: "form-control",
                attrs: {
                  type: "text",
                  id: "enderecoTransportadora",
                  name: "enderecoTransportadora",
                  disabled: _vm.consulta,
                },
                domProps: { value: _vm.nota.transportadoraEndereco },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.nota,
                      "transportadoraEndereco",
                      $event.target.value
                    )
                  },
                },
              }),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-sm-3" }, [
            _c("div", { staticClass: "form-group" }, [
              _c(
                "label",
                {
                  staticClass: "control-label",
                  attrs: { for: "cidadeTransportadora" },
                },
                [_vm._v("Cidade")]
              ),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.nota.transportadoraCidade,
                    expression: "nota.transportadoraCidade",
                  },
                ],
                staticClass: "form-control",
                attrs: {
                  type: "text",
                  id: "cidadeTransportadora",
                  name: "cidadeTransportadora",
                  disabled: _vm.consulta,
                },
                domProps: { value: _vm.nota.transportadoraCidade },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.nota,
                      "transportadoraCidade",
                      $event.target.value
                    )
                  },
                },
              }),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-sm-3" }, [
            _c("div", { staticClass: "form-group" }, [
              _c(
                "label",
                {
                  staticClass: "control-label",
                  attrs: { for: "ufTransportadora" },
                },
                [_vm._v("UF")]
              ),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.nota.transportadoraUF,
                    expression: "nota.transportadoraUF",
                  },
                ],
                staticClass: "form-control",
                attrs: {
                  type: "text",
                  id: "ufTransportadora",
                  name: "ufTransportadora",
                  disabled: _vm.consulta,
                },
                domProps: { value: _vm.nota.transportadoraUF },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.nota, "transportadoraUF", $event.target.value)
                  },
                },
              }),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-sm-3" }, [
            _c("div", { staticClass: "form-group" }, [
              _c(
                "label",
                {
                  staticClass: "control-label",
                  attrs: { for: "ieTransportadora" },
                },
                [_vm._v("I.E.")]
              ),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.nota.transportadoraIE,
                    expression: "nota.transportadoraIE",
                  },
                ],
                staticClass: "form-control",
                attrs: {
                  type: "text",
                  id: "ieTransportadora",
                  name: "ieTransportadora",
                  disabled: _vm.consulta,
                },
                domProps: { value: _vm.nota.transportadoraIE },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.nota, "transportadoraIE", $event.target.value)
                  },
                },
              }),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-sm-3" }, [
            _c("div", { staticClass: "form-group" }, [
              _c(
                "label",
                {
                  staticClass: "control-label",
                  attrs: { for: "transportadoraPlacaVeiculo" },
                },
                [_vm._v("Placa do Veículo")]
              ),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.nota.transportadoraPlacaVeiculo,
                    expression: "nota.transportadoraPlacaVeiculo",
                  },
                ],
                staticClass: "form-control",
                attrs: {
                  type: "text",
                  id: "transportadoraPlacaVeiculo",
                  name: "transportadoraPlacaVeiculo",
                  disabled: _vm.consulta,
                },
                domProps: { value: _vm.nota.transportadoraPlacaVeiculo },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.nota,
                      "transportadoraPlacaVeiculo",
                      $event.target.value
                    )
                  },
                },
              }),
            ]),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }