var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "crud-detalhe-item-grid" }, [
    _c(
      "div",
      [
        _c("grid-component", {
          attrs: {
            data: _vm.model,
            columns: _vm.gridColumns,
            "show-select-checkbox": false,
            subColumns: _vm.gridColumnsVinculos,
            subDataName: _vm.subDataName,
            showExpand: true,
            conditionShowSubData: _vm.conditionShowSubData,
            showSearchField: false,
            "page-index": 1,
            "page-size": 5,
            total: 4,
            showEditItem: false,
            showActionColumn: false,
            showAddNewButton: false,
            showTableFoot: false,
            showTools: false,
            showTableHead: true,
          },
        }),
        _vm._v(" "),
        _c("div", { staticClass: "comissoes" }, [
          _c("div", [
            _c("span", [
              _vm._v(
                "\n                    " +
                  _vm._s(
                    _vm.$t(
                      "__.Components.parent.manipulacao.detalhesitem_vue_html.comissaoPrescritor"
                    )
                  ) +
                  "\n                "
              ),
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "destaque-item" }, [
              _vm._v(_vm._s(_vm._f("filterMoeda")(_vm.comissaoPrescritor))),
            ]),
          ]),
          _vm._v(" "),
          _c("div", [
            _c("span", [
              _vm._v(
                "\n                    " +
                  _vm._s(
                    _vm.$t(
                      "__.Components.parent.manipulacao.detalhesitem_vue_html.comissaoVisitador"
                    )
                  ) +
                  "\n                "
              ),
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "destaque-item" }, [
              _vm._v(_vm._s(_vm._f("filterMoeda")(_vm.comissaoVisitador))),
            ]),
          ]),
          _vm._v(" "),
          _c("div", [
            _c("span", [
              _vm._v(
                "\n                    " +
                  _vm._s(
                    _vm.$t(
                      "__.Components.parent.manipulacao.detalhesitem_vue_html.comissaoAtendente"
                    )
                  ) +
                  "\n                "
              ),
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "destaque-item" }, [
              _vm._v(_vm._s(_vm._f("filterMoeda")(_vm.comissaoAtendente))),
            ]),
          ]),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }