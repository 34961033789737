var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "portlet-component",
    {
      style: _vm.box,
      attrs: {
        title: _vm.value.formulaPadrao.descricao,
        showFullScreenButton: false,
        showRemoveButton: !_vm.gerado,
        showCheckedButton: false,
        showWarningButton: false,
        showErrorButton: false,
      },
      on: { remove: _vm.remove },
    },
    [
      _c(
        "div",
        {
          staticClass: "panel-body",
          attrs: { slot: "content" },
          slot: "content",
        },
        [
          _vm.mensagens.length > 0
            ? _c(
                "div",
                { style: [{ marginTop: "-15px" }] },
                _vm._l(_vm.mensagens, function (mensagem) {
                  return _c(
                    "div",
                    { key: mensagem, style: _vm.mensagemStyle },
                    [_vm._v(_vm._s(mensagem))]
                  )
                })
              )
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "col-lg-6" }, [
            _vm.value.manipulacaoOrdem != null
              ? _c("div", { staticClass: "row" }, [
                  _vm.value.manipulacaoOrdemId > 0
                    ? _c("label", { staticClass: "control-label" }, [
                        _vm._v(
                          "\n                    " +
                            _vm._s(
                              _vm.$t(
                                "__.Crud.formulapadraomanipulacao.item_vue_html.codManipu"
                              )
                            ) +
                            "\n                "
                        ),
                      ])
                    : _c("label", { staticClass: "control-label" }, [
                        _vm._v(
                          "\n                    " +
                            _vm._s(
                              _vm.$t(
                                "__.Crud.formulapadraomanipulacao.item_vue_html.manipuNaoGerada"
                              )
                            ) +
                            "\n                "
                        ),
                      ]),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      staticClass: "btn btn-search",
                      attrs: {
                        title: _vm.$t(
                          "__.Crud.formulapadraomanipulacao.item_vue_html.visuManipu"
                        ),
                      },
                      on: {
                        click: function ($event) {
                          _vm.openManipulacao(_vm.value.manipulacaoOrdem.id)
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.value.manipulacaoOrdem.codigo) +
                          "\n                    "
                      ),
                      _c("i", {
                        staticClass: "fa fa-search color-light-blue",
                        attrs: {
                          title: _vm.$t(
                            "__.Crud.formulapadraomanipulacao.item_vue_html.visuManipu"
                          ),
                          "aria-hidden": "true",
                        },
                      }),
                    ]
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("label", { staticClass: "control-label" }, [
                _vm._v(
                  "\n                    " +
                    _vm._s(
                      _vm.$t(
                        "__.Crud.formulapadraomanipulacao.item_vue_html.volQtd"
                      )
                    ) +
                    "\n                "
                ),
              ]),
              _vm._v(
                "\n                " +
                  _vm._s(_vm.value.formulaPadrao.volume) +
                  " " +
                  _vm._s(_vm.value.formulaPadrao.unidadeMedida.sigla) +
                  "\n            "
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("label", { staticClass: "control-label" }, [
                _vm._v(
                  "\n                    " +
                    _vm._s(
                      _vm.$t(
                        "__.Crud.formulapadraomanipulacao.item_vue_html.valDias"
                      )
                    ) +
                    "\n                "
                ),
              ]),
              _vm._v(
                "\n                " +
                  _vm._s(_vm.value.formulaPadrao.validadeDias) +
                  "\n            "
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("label", { staticClass: "control-label" }, [
                _vm._v(
                  "\n                    " +
                    _vm._s(
                      _vm.$t(
                        "__.Crud.formulapadraomanipulacao.item_vue_html.itens"
                      )
                    ) +
                    "\n                "
                ),
              ]),
              _vm._v(" "),
              _c(
                "ul",
                _vm._l(
                  _vm.value.formulaPadrao.formulaPadraoItens,
                  function (it) {
                    return _c("li", { key: it.id }, [
                      _vm._v(
                        "\n                        " +
                          _vm._s(it.produtoDescricao) +
                          " (" +
                          _vm._s(it.quantidade) +
                          " " +
                          _vm._s(it.unidadeMedidaManipulacao.sigla) +
                          ")\n                    "
                      ),
                    ])
                  }
                )
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-lg-6" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-sm-12" }, [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip",
                        value: _vm.errors.first("estoqueId" + _vm.index),
                        expression: "errors.first('estoqueId' + index)",
                      },
                    ],
                    staticClass: "form-group",
                  },
                  [
                    _c(
                      "label",
                      {
                        staticClass: "control-label",
                        attrs: { for: "estoqueId" + _vm.index },
                      },
                      [
                        _vm._v(
                          "\n                            " +
                            _vm._s(
                              _vm.$t(
                                "__.Crud.formulapadraomanipulacao.item_vue_html.estq"
                              )
                            ) +
                            "\n                        "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("combo-component", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required",
                          expression: "'required'",
                        },
                      ],
                      attrs: {
                        id: "estoqueId" + _vm.index,
                        name: "estoqueId" + _vm.index,
                        data: _vm.estoquesOptions,
                        searchable: true,
                        disabled: _vm.gerado,
                      },
                      model: {
                        value: _vm.value.estoqueId,
                        callback: function ($$v) {
                          _vm.$set(_vm.value, "estoqueId", $$v)
                        },
                        expression: "value.estoqueId",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-sm-6" }, [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip",
                        value: _vm.errors.first("quantidadeTotal" + _vm.index),
                        expression: "errors.first('quantidadeTotal' + index)",
                      },
                    ],
                    staticClass: "form-group",
                  },
                  [
                    _c(
                      "label",
                      {
                        staticClass: "control-label",
                        attrs: { for: "quantidadeTotal" + _vm.index },
                      },
                      [
                        _vm._v(
                          "\n                            " +
                            _vm._s(
                              _vm.$t(
                                "__.Crud.formulapadraomanipulacao.item_vue_html.totalProduzir"
                              )
                            ) +
                            "\n                        "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("decimal-com-sinal-custom-component", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: _vm.value.formulaPadrao
                            .permiteQuantidadeDecimal
                            ? "required|maior_que_zero"
                            : "required|maior_que_zero|numeric",
                          expression:
                            "\n                                value.formulaPadrao.permiteQuantidadeDecimal\n                                    ? 'required|maior_que_zero'\n                                    : 'required|maior_que_zero|numeric'\n                            ",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: {
                        signal: _vm.value.formulaPadrao.unidadeMedida.sigla,
                        id: "quantidadeTotal" + _vm.index,
                        name: "quantidadeTotal" + _vm.index,
                        value: _vm.value.volumeTotal,
                        disabled: _vm.gerado,
                      },
                      model: {
                        value: _vm.value.volumeTotal,
                        callback: function ($$v) {
                          _vm.$set(_vm.value, "volumeTotal", $$v)
                        },
                        expression: "value.volumeTotal",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                !_vm.value.formulaPadrao.permiteQuantidadeDecimal
                  ? _c("label", [
                      _vm._v(
                        "\n                        " +
                          _vm._s(
                            _vm.$t(
                              "__.Crud.formulapadraomanipulacao.item_vue_html.naoPermiteQuantidadeDecimal"
                            )
                          ) +
                          "\n                    "
                      ),
                    ])
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-sm-4" }, [
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c(
                      "label",
                      {
                        staticClass: "control-label",
                        attrs: { for: "chkAtualizaCustoProdutos" },
                      },
                      [
                        _vm._v(
                          "\n                            " +
                            _vm._s(
                              _vm.$t(
                                "__.Crud.formulapadraomanipulacao.item_vue_html.atualizarCustosProduto"
                              )
                            ) +
                            "\n                        "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("checkbox-component", {
                      attrs: {
                        id: "chkAtualizaCustoProdutos",
                        name: "chkAtualizaCustoProdutos",
                        title: _vm.$t(
                          "__.Crud.formulapadraomanipulacao.item_vue_html.vlrSeraAtualizCustoProd"
                        ),
                        disabled: _vm.gerado,
                      },
                      model: {
                        value: _vm.value.atualizarValorCusto,
                        callback: function ($$v) {
                          _vm.$set(_vm.value, "atualizarValorCusto", $$v)
                        },
                        expression: "value.atualizarValorCusto",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _vm.value.manipulacaoOrdem != null
                ? _c(
                    "div",
                    { staticClass: "col-lg-12 newbtn" },
                    [
                      _c("buttonScComponent", {
                        staticStyle: { "margin-left": "3px" },
                        attrs: {
                          classButton: "btn btnCopiar",
                          title: _vm.$t(
                            "__.Crud.formulapadraomanipulacao.item_vue_html.confEstqEQntd"
                          ),
                          classIcon: "fa fa-balance-scale",
                          buttonName: "Lotes/Estoque",
                        },
                        on: {
                          click: function ($event) {
                            _vm.onLotes(_vm.value.manipulacaoOrdem.codigo)
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-primary",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              _vm.onImprimirManipulacaoModelo().withLoading()
                            },
                          },
                        },
                        [
                          _c("i", {
                            staticClass: "fa fa-print",
                            staticStyle: {
                              "font-size": "16px !important",
                              "margin-left": "4px",
                            },
                            attrs: { "aria-hidden": "true" },
                          }),
                          _vm._v(
                            "\n                        " +
                              _vm._s(
                                _vm.$t(
                                  "__.Crud.formulapadraomanipulacao.item_vue_html.printOrdem"
                                )
                              ) +
                              "\n                    "
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "shortcutComponent",
            {
              ref: "shortcutComponent",
              attrs: { btOkName: "Ok", modalClass: "bg-modal" },
              on: { "selection-item": _vm.onConfirm },
            },
            [
              _vm.showManipulacao
                ? _c(
                    "div",
                    { attrs: { slot: "component" }, slot: "component" },
                    [
                      _c("manipulacao-edit-component", {
                        ref: "manipulacaoEditComponent",
                        attrs: {
                          CalledByShortCut: true,
                          manipulacaoId: _vm.value.manipulacaoOrdemId,
                          itemVenda: false,
                        },
                        on: {
                          "manipulacao-confirm-lote": _vm.onConfirmLote,
                          "manipulacao-confirm-impressao":
                            _vm.onConfirmImpressao,
                          "manipulacao-mounted": _vm.onManipulacaoMounted,
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ]
          ),
          _vm._v(" "),
          _c("impressaoComponent", { ref: "impressaoComponent" }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }