import checkboxComponent from "@/components/child/form/checkbox.vue";
import comboComponent from "@/components/child/form/combo.vue";
import dataTooltipComponent from "@/components/child/form/datatooltip.vue";
import decimalComSinalCustomComponent from "@/components/child/form/decimalComSinalCustom.vue";
import { Component, Prop, Watch } from "@/decorators";
import TiposMovimentacao from "@/models/enum/tiposMovimentacao";
import NaturezaOperacaoModel from "@/models/naturezaOperacaoModel";
import NaturezaOperacaoService from "@/services/naturezaOperacaoService";
import SituacaoTributariaService from "@/services/situacaoTributariaService";

import EditComponentBase from "../editComponentBase";

@Component({
    components: {
        comboComponent,
        checkboxComponent,
        decimalComSinalCustomComponent,
        dataTooltipComponent,
    },
})
export default class NaturezaOperacaoEditComponent extends EditComponentBase<NaturezaOperacaoModel> {
    tiposMovimentacaoOptions: Array<Object> = [
        { text: "Entrada", value: TiposMovimentacao.Entrada },
        { text: "Saida", value: TiposMovimentacao.Saida },
    ];

    cstICMSOptions: Array<Object> = [];
    cstIPIOptions: Array<Object> = [];
    cstPISCOFINSOptions: Array<Object> = [];

    @Prop({ type: Boolean, default: false }) CalledByShortCut: boolean;

    private async beforeMount() {
        await this.setProps(
            new NaturezaOperacaoService(),
            "naturezas_de_operacao",
            new NaturezaOperacaoModel(),
            this.CalledByShortCut,
        );

        this.loadCSTs();
    }

    public async onPreLoadModel() {
        this.model.tipoMovimentacao = TiposMovimentacao.Entrada;
        this.model.dentroEstado = true;
        this.model.movimentaEstoque = true;
        this.model.transferencia = false;
        return true;
    }

    //@ts-ignore
    @Watch("model.codigo")
    private onChangeCodigo() {
        if (!this.model.id && this.model.codigo.toString().length > 3) {
            switch (Number(this.model.codigo[0])) {
                case 1:
                    this.model.dentroEstado = true;
                    this.model.tipoMovimentacao = TiposMovimentacao.Entrada;
                    break;
                case 2:
                    this.model.dentroEstado = false;
                    this.model.tipoMovimentacao = TiposMovimentacao.Entrada;
                    break;
                case 5:
                    this.model.dentroEstado = true;
                    this.model.tipoMovimentacao = TiposMovimentacao.Saida;
                    break;
                case 6:
                    this.model.dentroEstado = false;
                    this.model.tipoMovimentacao = TiposMovimentacao.Saida;
                    break;
            }
        }
    }

    private async loadCSTs() {
        const service = new SituacaoTributariaService();

        if (this.usuarioLogado.franquia.opcaoSimplesNacional) {
            this.cstICMSOptions = service
                .getCSOSN()
                .map(item => ({ value: Number(item.codigo), text: `${item.codigo}-${item.descricao}` }));
        } else {
            this.cstICMSOptions = service
                .getICMS()
                .map(item => ({ value: Number(item.codigo), text: `${item.codigo}-${item.descricao}` }));
        }

        this.cstIPIOptions = service
            .getIPI()
            .map(item => ({ value: Number(item.codigo), text: `${item.codigo}-${item.descricao}` }));
        this.cstPISCOFINSOptions = service
            .getPISCOFINS()
            .map(item => ({ value: Number(item.codigo), text: `${item.codigo}-${item.descricao}` }));
    }
}
