import Vue from "vue";

import gridComponent from "@/components/child/grid/grid.vue";
import { GridAction } from "@/components/child/grid/gridAction";
import { GridColors, GridColumn, GridColumnType } from "@/components/child/grid/gridColumn";
import { Component, Prop, Watch } from "@/decorators";
import VendaModel from "@/models/vendaModel";
import EstoqueComprometidoService from "@/services/estoqueComprometidoService";

@Component({
    components: {
        gridComponent,
    },
})
export default class EstoqueComprometidoComponent extends Vue {
    private service = new EstoqueComprometidoService();

    private extraActions: Array<object> = [];
    private extraActionsConsultar: Array<GridAction> = [];

    @Prop(Number) loteId: number;
    @Prop(Number) itemFracionamentoId: number;

    private gridData = [];
    private get gridColumns(): Array<GridColumn> {
        return [
            new GridColumn("codigo", this.$t("__.ts.codigo"), GridColumnType.String),
            new GridColumn("situacaoDescricao", this.$t("__.ts.situacao"), GridColumnType.String),
            new GridColumn("clienteNome", this.$t("__.ts.cliente"), GridColumnType.String),
            new GridColumn("dataAprovacao", this.$t("__.ts.data"), GridColumnType.Date),
            new GridColumn("valorFinal", this.$t("__.ts.vlr"), GridColumnType.Money),
        ];
    }

    private get gridColumnsSub(): Array<GridColumn> {
        return [
            new GridColumn("produtoDescricao", "Produto", GridColumnType.String),
            new GridColumn("produtoLoteDescricao", "Lote", GridColumnType.String),
            new GridColumn("quantidade", "quantidade", GridColumnType.Decimal),
            new GridColumn("valorUnitario", "valorUnitario", GridColumnType.Money),
            new GridColumn("valorTotal", "valorTotal", GridColumnType.Money),
        ];
    }

    private conditionShowSubData(data: VendaModel) {
        return data.itens.length > 0;
    }

    @Watch("loteId")
    private async onLoteIdChange() {
        if (this.loteId) {
            this.load();
        }
    }

    private async load() {
        try {
            const vendas = await this.service
                .getVendasComprometendoLote(this.loteId, this.itemFracionamentoId)
                .withLoading()
                .resolveWithJSON<VendaModel[]>();

            this.gridData = vendas;

            this.extraActions.splice(0);
            this.extraActionsConsultar.splice(0);

            for (let i = 0; i < this.gridData.length; i++) {
                this.extraActionsConsultar.push(
                    new GridAction("consultar", "Consultar Venda", "fa fa-search", GridColors.BLUE),
                );
            }

            this.extraActions.push(this.extraActionsConsultar);
        } catch {}
    }

    private async onExtraAction(name: string, venda: VendaModel) {
        if (name.trim() == "consultar") {
            const routeData = this.$router.resolve({ path: `/venda-edicao/${venda.id}` });
            window.open(routeData.href, "_blank");
        }
    }

    private mounted() {
        if (this.loteId) {
            this.load();
        }
    }
}
