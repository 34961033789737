import Vue from "vue";

import actionBarComponent from "@/components/child/actionBar/actionBar.vue";
import comboComponent from "@/components/child/form/combo.vue";
import dateTimePickerComponent from "@/components/child/form/datetimepicker.vue";
import fieldsetComponent from "@/components/child/form/fieldset.vue";
import { Component } from "@/decorators";
import ManipulacaoOrdemCancelamentoItemModel from "@/models/manipulacaoOrdemCancelamentoItemModel";
import ManipulacaoOrdemCancelamentoModel from "@/models/manipulacaoOrdemCancelamentoModel";
import ManipulacaoOrdemModel from "@/models/manipulacaoOrdemModel";
import MotivoCancelamentoModel from "@/models/motivoCancelamentoModel";
import PaginationModel from "@/models/paginationModel";
import ManipulacaoOrdemCancelamentoService from "@/services/manipulacaoOrdemCancelamentoService";
import MotivoCancelamentoService from "@/services/motivoCancelamentoService";

import cancelamentoItemComponent from "./cancelamentoitem.vue";

import "../crud/crud.scss";

@Component({
    components: {
        comboComponent,
        fieldsetComponent,
        cancelamentoItemComponent,
        dateTimePickerComponent,
        actionBarComponent,
    },
})
export default class CancelamentoComponent extends Vue {
    private service = new ManipulacaoOrdemCancelamentoService();

    manipulacaoOrdemId = 0;
    manipulacaoModel = new ManipulacaoOrdemModel();
    cancelamentoModel = new ManipulacaoOrdemCancelamentoModel();
    isCancelado = false;
    temMovimentoEstoque = false;

    motivoCancelamentoOptions: Array<Object> = [];

    private async load() {
        if (this.manipulacaoOrdemId > 0) {
            try {
                const data = await this.service
                    .getManipulacaoOrdem(this.manipulacaoOrdemId)
                    .withLoading()
                    .resolveWithJSON<ManipulacaoOrdemModel>();

                this.manipulacaoModel.updateFrom(data);
                this.cancelamentoModel = new ManipulacaoOrdemCancelamentoModel();
                this.cancelamentoModel.manipulacaoOrdemId = this.manipulacaoModel.id;

                if (this.manipulacaoModel.movimentacaoEstoqueId != null) {
                    this.temMovimentoEstoque = true;
                }

                if (this.manipulacaoModel.manipulacaoOrdemCancelamentoId != null) {
                    this.isCancelado = true;

                    const dataCancelamento = await this.service
                        .get(this.manipulacaoModel.manipulacaoOrdemCancelamentoId)
                        .withLoading()
                        .resolveWithJSON<ManipulacaoOrdemCancelamentoModel>();
                    this.cancelamentoModel.updateFrom(dataCancelamento);
                } else {
                    for (let i = 0; i < this.manipulacaoModel.itens.length; i++) {
                        const itemCancelamento = new ManipulacaoOrdemCancelamentoItemModel();

                        itemCancelamento.manipulacaoOrdemItemId = this.manipulacaoModel.itens[i].id;
                        itemCancelamento.quantidade = this.manipulacaoModel.itens[i].quantidade;
                        itemCancelamento.unidadeMedidaDescricao =
                            this.manipulacaoModel.itens[i].unidadeMedidaManipulacaoDecricao;
                        itemCancelamento.unidadeMedidaId = this.manipulacaoModel.itens[i].unidadeMedidaId;
                        itemCancelamento.descricao = this.manipulacaoModel.itens[i].produtoDescricao;
                        this.cancelamentoModel.itens.push(itemCancelamento);
                    }
                }
            } catch {}
        }
    }

    private async loadMotivoCancelamento() {
        try {
            const data = await new MotivoCancelamentoService()
                .combo()
                .then(r => r.json() as Promise<PaginationModel<MotivoCancelamentoModel>>);
            this.motivoCancelamentoOptions = data.list.map(item => ({ value: item.id, text: item.descricao }));
        } catch {}
    }

    private async save() {
        if (!this.isCancelado) {
            try {
                const isValid = await this.$validator.validateAll();
                if (isValid) {
                    const sucesso = await this.service
                        .insert(this.cancelamentoModel)
                        .withLoading()
                        .resolveWithoutJSON();
                    if (sucesso) {
                        await this.$showSuccess(this.$t("__.ts.inclusao"), this.$t("__.ts.registroSalvSucess"));
                        this.$router.back();
                    }
                }
            } catch {}
        }
    }

    private cancel() {
        this.$router.back();
    }

    private mounted() {
        Promise.all([this.loadMotivoCancelamento()])
            .withLoading()
            .then(() => {
                if (this.$route.params.id) {
                    this.manipulacaoOrdemId = +this.$route.params.id;
                    this.load();
                }
            })
            .catch(() => {});
    }
}
