import BaseModel from "./../base/baseModel";
import TiposPessoa from "./../enum/tiposPessoa";
import Ufs from "./../enum/ufs";

export default class OperadoraCartoesModel extends BaseModel {
    id?: number = null;
    tipoPessoa: TiposPessoa = null;
    nome: string = null;
    nomeFantasia: string = null;
    cpf: string = null;
    rg: string = null;
    razaoSocial: string = null;
    cnpj: string = null;
    inscricaoEstadual: string = null;
    cep: string = null;
    estadoId?: Ufs = null;
    cidadeId?: number = null;
    endereco: string = null;
    bairro: string = null;
    numero: string = null;
    complemento: string = null;
    telefone: string = null;
    celular: string = null;
    email: string = null;
}
