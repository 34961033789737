import RacaAnimalModel from "../../models/paciente/racaAnimalModel";
import nfetch from "../../utils/events/nfetch";
import { IService } from "../base/iService";

export default class RacaAnimalService extends IService<RacaAnimalModel> {
    constructor() {
        super("RacaAnimal");
    }

    public listByEspecie(especieId: number) {
        return nfetch(`/${this.controllerName}/ListByEspecie?especieId=${especieId}`, { credentials: "same-origin" });
    }
}
