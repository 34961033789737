import { TypeElement } from "@/models/modeloImpressao/modeloImpressaoSessaoModel";

class Options {
    elementId: string;
    title: string;
    type: TypeElement;
}

export default class PrintForm {
    //Imprimir formulario
    public print(options: Array<Options>, numMaxCols) {
        const html = [];
        //monta formulario
        for (let i = 0; i < options.length; i++) {
            const obj =
                options[i].type == TypeElement.Grid
                    ? this.tableSemOptionsColsHtml(options[i].elementId, options[i].title)
                    : this.tableHtml(options[i].elementId, options[i].title, numMaxCols);

            if (obj && obj.hasChildNodes()) {
                html.push(obj.childNodes[0].parentElement.outerHTML);
            }
        }

        //cria base para nova pagina
        const pagina = window.open("", "Print", "width=1200,height=600");
        //montando a html para impressao
        pagina.document.write(`<html><head> 
        </head>
        ${html.join(" ")}
        </body>
        </html>`);
        //inicia impressora
        pagina.print();
        //fecha pagina aberta para impressao
        pagina.document.close();
    }

    private getNodeById(elementId: string) {
        const doc = document.getElementById(elementId);
        if (!doc) {
            return null;
        }

        const elementoTableHtml = document.getElementById(elementId).cloneNode(true);

        return elementoTableHtml;
    }

    //função que busca elemento por id e remove as colunas nao necessarias e retorna em formato table os dados adquiridos
    private tableHtml(tableElementId, title: string, numMaxCols) {
        const secao = document.getElementById(tableElementId);
        if (!secao) {
            return null;
        }

        let elementoTableHtml = secao.cloneNode(true);
        let elementosFilhos = elementoTableHtml.childNodes;
        //busca datas que estão com formato não lido pelo html puro
        const dateElement = elementosFilhos[0].parentElement.getElementsByClassName("datetimepicker-component");
        if (dateElement != null) {
            //   em todas os lugares onde a data foi encontrada, o valor será busca e atribuido a um filho
            // que o html leia naturalmente
            for (let i = 0; i < dateElement.length; i++) {
                dateElement[i].lastChild.textContent = dateElement[i].firstChild["value"];
            }
        }

        //remover os short cuts que ficam dentro de varios components incluindo combos
        //@ts-ignore
        elementosFilhos = this.removeClasses(elementosFilhos, "shortcutCombo");

        //ler as outras colunas html que nao são listadas devido ao tipo input
        const forms = elementosFilhos[0].parentElement.getElementsByClassName("form-group");
        for (let l = 0; l < forms.length; l++) {
            //First Node Sempre é o label
            forms[l].firstChild.textContent =
                forms[l].firstChild.textContent +
                ": " +
                (this.buscaValorNode(forms[l]) != null ? this.buscaValorNode(forms[l]).trim() : "");

            //após junção de label com as informações, as outras classe podem ser removidas
            if (forms[l].hasChildNodes && forms[l].childNodes.length > 1) {
                //pula o primeiro porque o primeiro(0) é sempre label
                for (let j = 1; j < forms[l].childNodes.length; j++) {
                    const removerPorDiv = forms[l].getElementsByTagName("div");
                    for (let k = 0; k < removerPorDiv.length; k++) {
                        removerPorDiv[k].remove();
                    }
                }
            }
        }

        //remove form-group(campos editaveis), pois já passou todos seus valores para dentro do label
        //@ts-ignore
        elementosFilhos = this.removeClasses(elementosFilhos, "form-control");
        //@ts-ignore
        elementosFilhos = this.removeClasses(elementosFilhos, "alert");
        //@ts-ignore
        elementosFilhos = this.removeClasses(elementosFilhos, "ignorePrint");

        //por fim remove tambem tudo que tem display como none
        for (let i = 0; i < elementosFilhos.length; i++) {
            if (
                elementosFilhos[i].hasChildNodes &&
                elementosFilhos[i].nodeName == "DIV" &&
                elementosFilhos[i]["getAttribute"]("class") == "Row"
            ) {
                if (elementosFilhos[i]["getAttribute"]("style") == "display: none;") {
                    elementosFilhos[i].firstChild.parentElement.remove();
                }
            } else {
                for (let j = 0; j < elementosFilhos[i].childNodes.length; j++) {
                    if (elementosFilhos[i].childNodes[j].nodeName == "DIV") {
                        if (elementosFilhos[i].childNodes[j]["getAttribute"]("style") == "display: none;") {
                            elementosFilhos[i].removeChild(elementosFilhos[i].childNodes[j]);
                        }
                    }
                }
            }
        }

        //chama função para retornar em forma de tabela
        elementoTableHtml = this.criaTableElementos(elementosFilhos[0].parentElement, title, numMaxCols);
        return elementoTableHtml;
    }

    //Cria table Html baseado nos elementos passados por parametro
    private criaTableElementos(element: HTMLElement, title: string, numMaxCols) {
        const trs: Array<HTMLElement> = [];
        const tds: Array<HTMLElement> = [];
        //revisar nas proximas implementações
        const maxCols = numMaxCols;

        const newElement = document.createElement("html");

        const style = document.createElement("style");
        style.innerHTML = `table, th, td {border: 1px solid black;
                                            border-collapse: collapse;}
                                            table{ width:100%; }`;

        newElement.appendChild(style);

        const table = document.createElement("table");
        //primeira linha onde será informado o tipo de documento impresso
        const trH = document.createElement("tr");
        const th = document.createElement("th");
        th.innerHTML = title;
        trH.appendChild(th);

        if (title && title.trim() != "") {
            table.appendChild(trH);
        }

        newElement.appendChild(table);

        //passa pelo filhos dos elementos para montar o html conforme tela
        for (let i = 0; i < element.childNodes.length; i++) {
            const newTr: HTMLElement = document.createElement("tr");
            if (tds.length > 0) {
                tds.splice(0, tds.length);
            }

            let countValuesValidos = 0;
            if (element.childNodes[i].hasChildNodes) {
                for (let j = 0; j < element.childNodes[i].childNodes.length; j++) {
                    const value = element.childNodes[i].childNodes[j].textContent;
                    if (value && value.trim() != "") {
                        const newTd: HTMLElement = document.createElement("td");
                        newTd.innerHTML = value;
                        tds.push(newTd);

                        countValuesValidos += 1;
                    }
                }
            }
            for (const td in tds) {
                //TODO:quando implementado em outras telas, revisar o countValuesValidos e maxCols
                if (countValuesValidos < maxCols) {
                    tds[td].setAttribute("colspan", (maxCols - countValuesValidos + 1).toString());
                }

                newTr.appendChild(tds[td]);
            }
            trs.push(newTr);
        }
        for (const tr in trs) {
            table.appendChild(trs[tr]);
        }

        th.setAttribute("colspan", maxCols.toString());

        return newElement;
    }

    //Função para remover classes retorna o node sem a classe passada por parametro
    private removeClasses(node: NodeList, classe: string) {
        if (classe && classe.trim() != "") {
            //o Loop abaixo lê os arrays dos elementos remove os elementos por busca do nome de classe = Parametro Classe a Remover
            for (let i = 0; i < node.length; i++) {
                const elemento = node[i].parentElement.getElementsByClassName(classe);

                for (let j = 0; j < elemento.length; j++) {
                    elemento[j].innerHTML = " ";
                    if (classe != "spinner") {
                        elemento[j].remove();
                    }
                }
            }
        }
        return node;
    }

    //Busca valor de child nodes do node passado por parametro
    //chama recursivo até chegar no nivel menor ou achar algum valor.
    private buscaValorNode(node: Node): string {
        if (node.hasChildNodes) {
            for (let i = node.childNodes.length - 1; i > 0; i--) {
                //pula os LABEL para nao pegar valor do nome do campo
                if (node.childNodes[i].nodeName != "LABEL") {
                    //atribui a variavel o textContente ou o value do campo
                    let value = node.childNodes[i].textContent.replace("Loading...", "");

                    //se achou algo retorna aquele valor como string
                    if (value && value.trim() != "") {
                        return value;
                    }
                    //devido a combobox e listas pode trazer o 'loading...' entao tenta pegar primeiro do primeiro filho
                    //para depois pegar do geral
                    value = node.childNodes[i]["value"];

                    if (value && value.trim() != "") {
                        return value;
                    }
                    //se não achou algo, chama a função novamente
                    if (node.childNodes[i].hasChildNodes) {
                        return this.buscaValorNode(node.childNodes[i]);
                    }
                }
            }
        }
        //se passou por todos filhos sem achar nada, retorna null ?
        return null;
    }

    //função que busca elemento por id em listas já tabeladas e remove a coluna de opções
    private tableSemOptionsColsHtml(tableElementId, title: string) {
        const doc = document.getElementById(tableElementId);
        if (!doc) return null;

        const objElement = document.createElement("html");

        //Montanto um title antes do title do grid
        if (title && title.trim() != "") {
            const table = document.createElement("table");
            const trH = document.createElement("tr");
            const th = document.createElement("th");

            th.innerHTML = title;
            trH.appendChild(th);
            table.appendChild(trH);

            objElement.appendChild(table);
        }

        const elementoTableHtml = doc.cloneNode(true);
        //Variavel declarada abaixo para buscar os ChildNodes do elemento
        const classeARemover = "option-column";
        const elemChilds = this.removeClasses(elementoTableHtml.childNodes, classeARemover);

        //o Loop abaixo lê os arrays dos elementos remove os elementos por busca do nome de classe 'option-column'
        for (let i = 0; i < elemChilds.length; i++) {
            const removeElemento = elemChilds[i].parentElement.getElementsByClassName(classeARemover);
            for (let j = 0; j < removeElemento.length; j++) {
                removeElemento[j].remove();
            }
        }

        objElement.appendChild(elementoTableHtml);

        return objElement;
    }
}
