var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "crud crud-edit" },
    [
      _c("actionBarComponent", {
        attrs: {
          title: _vm.$t("__.Crud.dinamizacao.edit_vue_html.dinamizacao"),
        },
        on: { onSave: _vm.save },
      }),
      _vm._v(" "),
      _c(
        "form",
        {
          attrs: { novalidate: "novalidate" },
          on: {
            submit: function ($event) {
              $event.preventDefault()
              _vm.save()
            },
          },
        },
        [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-sm-2" }, [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "tooltip",
                      rawName: "v-tooltip",
                      value: _vm.errors.first("quantidade"),
                      expression: "errors.first('quantidade')",
                    },
                  ],
                  staticClass: "form-group",
                },
                [
                  _c(
                    "label",
                    {
                      staticClass: "control-label",
                      attrs: { for: "quantidade" },
                    },
                    [
                      _c("span", { staticStyle: { color: "red" } }, [
                        _vm._v("*"),
                      ]),
                      _vm._v(
                        "\n                        " +
                          _vm._s(
                            _vm.$t(
                              "__.Crud.dinamizacao.edit_vue_html.quantidade"
                            )
                          ) +
                          "\n                    "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.model.quantidade,
                        expression: "model.quantidade",
                      },
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: {
                      id: "quantidade",
                      name: "quantidade",
                      type: "number",
                      disabled: _vm.disabled,
                    },
                    domProps: { value: _vm.model.quantidade },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.model, "quantidade", $event.target.value)
                      },
                    },
                  }),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-sm-2" }, [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "tooltip",
                      rawName: "v-tooltip",
                      value: _vm.errors.first("unidadeMedidaId"),
                      expression: "errors.first('unidadeMedidaId')",
                    },
                  ],
                  staticClass: "form-group",
                },
                [
                  _c(
                    "label",
                    {
                      staticClass: "control-label",
                      attrs: { for: "unidadeMedidaId" },
                    },
                    [
                      _c("span", { staticStyle: { color: "red" } }, [
                        _vm._v("*"),
                      ]),
                      _vm._v(
                        "\n                        " +
                          _vm._s(
                            _vm.$t(
                              "__.Crud.dinamizacao.edit_vue_html.unidadeMedida"
                            )
                          ) +
                          "\n                    "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("combo-component", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'",
                      },
                    ],
                    attrs: {
                      id: "unidadeMedidaId",
                      name: "unidadeMedidaId",
                      data: _vm.unidadeMedidasOptions,
                      disabled: _vm.disabled,
                    },
                    model: {
                      value: _vm.model.unidadeMedidaId,
                      callback: function ($$v) {
                        _vm.$set(_vm.model, "unidadeMedidaId", $$v)
                      },
                      expression: "model.unidadeMedidaId",
                    },
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-sm-4" }, [
              _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c(
                    "label",
                    {
                      staticClass: "control-label",
                      attrs: { for: "kitEmbalagemId" },
                    },
                    [
                      _vm._v(
                        "\n                        " +
                          _vm._s(
                            _vm.$t(
                              "__.Crud.dinamizacao.edit_vue_html.kitEmbalagem"
                            )
                          ) +
                          "\n                    "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("combo-component", {
                    attrs: {
                      id: "kitEmbalagemId",
                      name: "kitEmbalagemId",
                      data: _vm.kitEmbalagensOptions,
                      disabled: _vm.disabled,
                    },
                    model: {
                      value: _vm.model.kitEmbalagemId,
                      callback: function ($$v) {
                        _vm.$set(_vm.model, "kitEmbalagemId", $$v)
                      },
                      expression: "model.kitEmbalagemId",
                    },
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-sm-2" }, [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "tooltip",
                      rawName: "v-tooltip",
                      value: _vm.errors.first("validade"),
                      expression: "errors.first('validade')",
                    },
                  ],
                  staticClass: "form-group",
                },
                [
                  _c(
                    "label",
                    {
                      staticClass: "control-label",
                      attrs: { for: "validade" },
                    },
                    [
                      _c("span", { staticStyle: { color: "red" } }, [
                        _vm._v("*"),
                      ]),
                      _vm._v(
                        "\n                        " +
                          _vm._s(
                            _vm.$t("__.Crud.dinamizacao.edit_vue_html.validade")
                          ) +
                          "\n                    "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.model.validade,
                        expression: "model.validade",
                      },
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: {
                      id: "validade",
                      name: "validade",
                      type: "number",
                      disabled: _vm.disabled,
                    },
                    domProps: { value: _vm.model.validade },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.model, "validade", $event.target.value)
                      },
                    },
                  }),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-sm-2" }, [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "tooltip",
                      rawName: "v-tooltip",
                      value: _vm.errors.first("estoqueId"),
                      expression: "errors.first('estoqueId')",
                    },
                  ],
                  staticClass: "form-group",
                },
                [
                  _c(
                    "label",
                    {
                      staticClass: "control-label",
                      attrs: {
                        for: "estoqueId",
                        title: _vm.$t("__.obrigatorio"),
                      },
                    },
                    [
                      _c("span", { staticClass: "color-red" }, [_vm._v("*")]),
                      _vm._v(
                        "\n                        " +
                          _vm._s(
                            _vm.$t("__.Crud.dinamizacao.edit_vue_html.estoque")
                          ) +
                          "\n                    "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("combo-component", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'",
                      },
                    ],
                    attrs: {
                      id: "estoqueId",
                      name: "estoqueId",
                      data: _vm.estoqueOptions,
                      searchable: true,
                      disabled: _vm.disabled,
                    },
                    model: {
                      value: _vm.model.estoqueId,
                      callback: function ($$v) {
                        _vm.$set(_vm.model, "estoqueId", $$v)
                      },
                      expression: "model.estoqueId",
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "fieldset-component",
            {
              attrs: {
                title: _vm.$t(
                  "__.Crud.dinamizacao.edit_vue_html.fieldsetReferencia"
                ),
                collapsed: false,
              },
            },
            [
              _c("div", { attrs: { slot: "rows" }, slot: "rows" }, [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-sm-4" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("produtoId"),
                            expression: "errors.first('produtoId')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "produtoId" },
                          },
                          [
                            _c("span", { staticStyle: { color: "red" } }, [
                              _vm._v("*"),
                            ]),
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Crud.dinamizacao.edit_vue_html.produto"
                                  )
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("combo-component", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required",
                              expression: "'required'",
                            },
                          ],
                          attrs: {
                            id: "produtoId",
                            name: "produtoId",
                            data: _vm.produtosOptions,
                            disabled: _vm.disabled,
                          },
                          model: {
                            value: _vm.model.produtoId,
                            callback: function ($$v) {
                              _vm.$set(_vm.model, "produtoId", $$v)
                            },
                            expression: "model.produtoId",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-3" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("produtoLoteId"),
                            expression: "errors.first('produtoLoteId')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "produtoLoteId" },
                          },
                          [
                            _c("span", { staticStyle: { color: "red" } }, [
                              _vm._v("*"),
                            ]),
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Crud.dinamizacao.edit_vue_html.lote"
                                  )
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("combo-component", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required",
                              expression: "'required'",
                            },
                          ],
                          attrs: {
                            id: "produtoLoteId",
                            name: "produtoLoteId",
                            data: _vm.produtoLotesOptions,
                            disabled: _vm.disabled,
                          },
                          model: {
                            value: _vm.model.produtoLoteId,
                            callback: function ($$v) {
                              _vm.$set(_vm.model, "produtoLoteId", $$v)
                            },
                            expression: "model.produtoLoteId",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-1" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("dinamizacaoReferencia"),
                            expression: "errors.first('dinamizacaoReferencia')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "dinamizacaoReferencia" },
                          },
                          [
                            _c("span", { staticStyle: { color: "red" } }, [
                              _vm._v("*"),
                            ]),
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Crud.dinamizacao.edit_vue_html.dinamizacao"
                                  )
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.model.dinamizacaoReferencia,
                              expression: "model.dinamizacaoReferencia",
                            },
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required",
                              expression: "'required'",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            id: "dinamizacaoReferencia",
                            name: "dinamizacaoReferencia",
                            type: "number",
                            disabled: _vm.disabled,
                          },
                          domProps: { value: _vm.model.dinamizacaoReferencia },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.model,
                                "dinamizacaoReferencia",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-1" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("metodo"),
                            expression: "errors.first('metodo')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "metodo" },
                          },
                          [
                            _c("span", { staticStyle: { color: "red" } }, [
                              _vm._v("*"),
                            ]),
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Crud.dinamizacao.edit_vue_html.metodo"
                                  )
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("combo-component", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required",
                              expression: "'required'",
                            },
                          ],
                          attrs: {
                            id: "metodo",
                            name: "metodo",
                            data: _vm.metodoOptions,
                            disabled: _vm.disabled,
                          },
                          model: {
                            value: _vm.model.metodo,
                            callback: function ($$v) {
                              _vm.$set(_vm.model, "metodo", $$v)
                            },
                            expression: "model.metodo",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-1" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("quantidadeReferencia"),
                            expression: "errors.first('quantidadeReferencia')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "quantidadeReferencia" },
                          },
                          [
                            _c("span", { staticStyle: { color: "red" } }, [
                              _vm._v("*"),
                            ]),
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Crud.dinamizacao.edit_vue_html.quantidade"
                                  )
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.model.quantidadeReferencia,
                              expression: "model.quantidadeReferencia",
                            },
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required",
                              expression: "'required'",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            id: "quantidadeReferencia",
                            name: "quantidadeReferencia",
                            type: "number",
                            disabled: _vm.disabled,
                          },
                          domProps: { value: _vm.model.quantidadeReferencia },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.model,
                                "quantidadeReferencia",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-2" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first(
                              "unidadeMedidaIdReferencia"
                            ),
                            expression:
                              "errors.first('unidadeMedidaIdReferencia')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "unidadeMedidaIdReferencia" },
                          },
                          [
                            _c("span", { staticStyle: { color: "red" } }, [
                              _vm._v("*"),
                            ]),
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Crud.dinamizacao.edit_vue_html.unidadeMedida"
                                  )
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("combo-component", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required",
                              expression: "'required'",
                            },
                          ],
                          attrs: {
                            id: "unidadeMedidaIdReferencia",
                            name: "unidadeMedidaIdReferencia",
                            data: _vm.unidadeMedidasOptions,
                            disabled: _vm.disabled,
                          },
                          model: {
                            value: _vm.model.unidadeMedidaIdReferencia,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.model,
                                "unidadeMedidaIdReferencia",
                                $$v
                              )
                            },
                            expression: "model.unidadeMedidaIdReferencia",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "fieldset-component",
            {
              attrs: {
                title: _vm.$t(
                  "__.Crud.dinamizacao.edit_vue_html.fieldsetDinamizado"
                ),
                collapsed: false,
              },
            },
            [
              _c("div", { attrs: { slot: "rows" }, slot: "rows" }, [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-sm-2" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("dinamizacao"),
                            expression: "errors.first('dinamizacao')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "dinamizacao" },
                          },
                          [
                            _c("span", { staticStyle: { color: "red" } }, [
                              _vm._v("*"),
                            ]),
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Crud.dinamizacao.edit_vue_html.dinamizacao"
                                  )
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.model.dinamizacao,
                              expression: "model.dinamizacao",
                            },
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required",
                              expression: "'required'",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            id: "dinamizacao",
                            name: "dinamizacao",
                            type: "number",
                            disabled: _vm.disabled,
                          },
                          domProps: { value: _vm.model.dinamizacao },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.model,
                                "dinamizacao",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-2" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("metodo2"),
                            expression: "errors.first('metodo2')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "metodo2" },
                          },
                          [
                            _c("span", { staticStyle: { color: "red" } }, [
                              _vm._v("*"),
                            ]),
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Crud.dinamizacao.edit_vue_html.metodo"
                                  )
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("combo-component", {
                          attrs: {
                            id: "metodo2",
                            name: "metodo2",
                            data: _vm.metodoOptions,
                            disabled: "",
                          },
                          model: {
                            value: _vm.model.metodo,
                            callback: function ($$v) {
                              _vm.$set(_vm.model, "metodo", $$v)
                            },
                            expression: "model.metodo",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "fieldset-component",
            {
              attrs: {
                title: _vm.$t(
                  "__.Crud.dinamizacao.edit_vue_html.fieldsetVeículo"
                ),
                collapsed: false,
              },
            },
            [
              _c("div", { attrs: { slot: "rows" }, slot: "rows" }, [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-sm-4" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("produtoVeiculoId"),
                            expression: "errors.first('produtoVeiculoId')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "produtoVeiculoId" },
                          },
                          [
                            _c("span", { staticStyle: { color: "red" } }, [
                              _vm._v("*"),
                            ]),
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Crud.dinamizacao.edit_vue_html.produto"
                                  )
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("combo-component", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required",
                              expression: "'required'",
                            },
                          ],
                          attrs: {
                            id: "produtoVeiculoId",
                            name: "produtoVeiculoId",
                            data: _vm.produtosOptions,
                            disabled: _vm.disabled,
                          },
                          model: {
                            value: _vm.model.produtoVeiculoId,
                            callback: function ($$v) {
                              _vm.$set(_vm.model, "produtoVeiculoId", $$v)
                            },
                            expression: "model.produtoVeiculoId",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-3" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("quantidadeNecessaria"),
                            expression: "errors.first('quantidadeNecessaria')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: {
                              for: "quantidadeNecessaria",
                              title: _vm.$t("__.obrigatorio"),
                            },
                          },
                          [
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Crud.dinamizacao.edit_vue_html.qtdNecessaria"
                                  )
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("decimal-com-sinal-custom-component", {
                          staticClass: "form-control",
                          attrs: {
                            id: "quantidadeNecessaria",
                            name: "quantidadeNecessaria",
                            signal: _vm.unidadeMedidaDescricao,
                            disabled: "",
                          },
                          model: {
                            value: _vm.model.quantidadeNecessaria,
                            callback: function ($$v) {
                              _vm.$set(_vm.model, "quantidadeNecessaria", $$v)
                            },
                            expression: "model.quantidadeNecessaria",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-3" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("produtoLoteVeiculoId"),
                            expression: "errors.first('produtoLoteVeiculoId')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "produtoLoteVeiculoId" },
                          },
                          [
                            _c("span", { staticStyle: { color: "red" } }, [
                              _vm._v("*"),
                            ]),
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Crud.dinamizacao.edit_vue_html.lote"
                                  )
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("combo-component", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required",
                              expression: "'required'",
                            },
                          ],
                          attrs: {
                            id: "produtoLoteVeiculoId",
                            name: "produtoLoteVeiculoId",
                            data: _vm.produtoLotesVeiculoOptions,
                            disabled: _vm.disabled,
                          },
                          model: {
                            value: _vm.model.produtoLoteVeiculoId,
                            callback: function ($$v) {
                              _vm.$set(_vm.model, "produtoLoteVeiculoId", $$v)
                            },
                            expression: "model.produtoLoteVeiculoId",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
            ]
          ),
          _vm._v(" "),
          !_vm.disabled
            ? _c("fieldset", [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-sm-12 newbtn" }, [
                    _c("div", { staticClass: "form-group" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-success",
                          attrs: { type: "submit" },
                        },
                        [
                          _vm._v(
                            "\n                            " +
                              _vm._s(
                                _vm.$t(
                                  "__.Crud.dinamizacao.edit_vue_html.gerarDinamizacao"
                                )
                              ) +
                              "\n                        "
                          ),
                        ]
                      ),
                    ]),
                  ]),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm._l(_vm.dinamizacaoCount.length, function (it, index) {
            return _c(
              "fieldset",
              { key: it, staticClass: "portlet-container" },
              [
                _c("div", { staticClass: "portlet-title" }, [
                  _c("div", { staticClass: "caption" }, [
                    _c(
                      "span",
                      { staticClass: "caption-subject text-uppercase" },
                      [
                        _vm._v(
                          _vm._s(index + 1) + " " + _vm._s(_vm.metodoDescricao)
                        ),
                      ]
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-sm-12" }, [
                  _c("div", { staticClass: "row" }, [
                    _c("label", { staticClass: "control-label" }, [
                      _vm._v(
                        "\n                        " +
                          _vm._s(
                            _vm.$t(
                              "__.Crud.formulapadraomanipulacao.item_vue_html.volQtd"
                            )
                          ) +
                          "\n                    "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm.model.quantidade) +
                          " " +
                          _vm._s(_vm.unidadeMedidaDescricao)
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row" }, [
                    _c("label", { staticClass: "control-label" }, [
                      _vm._v(
                        "\n                        " +
                          _vm._s(
                            _vm.$t(
                              "__.Crud.formulapadraomanipulacao.item_vue_html.valDias"
                            )
                          ) +
                          "\n                    "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("span", [_vm._v(_vm._s(_vm.model.validade))]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row" }, [
                    _c("label", { staticClass: "control-label" }, [
                      _vm._v(
                        "\n                        " +
                          _vm._s(
                            _vm.$t(
                              "__.Crud.formulapadraomanipulacao.item_vue_html.itens"
                            )
                          ) +
                          "\n                    "
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "ul",
                      [
                        _vm.model.produtoId != null
                          ? _c("li", [
                              _c("span", [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(_vm.produtoReferencia) +
                                    " (" +
                                    _vm._s(_vm.quantidadeProdutoReferencia) +
                                    "\n                                " +
                                    _vm._s(_vm.unidadeMedidaDescricao) +
                                    ")\n                            "
                                ),
                              ]),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.model.produtoVeiculoId != null
                          ? _c("li", [
                              _c("span", [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(_vm.produtoVeiculo) +
                                    " (" +
                                    _vm._s(_vm.quantidadeDinamizacaoVeiculo) +
                                    "\n                                " +
                                    _vm._s(_vm.unidadeMedidaDescricao) +
                                    ")\n                            "
                                ),
                              ]),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm._l(_vm.kitEmbalagemItem, function (item) {
                          return _c("li", { key: item.key }, [
                            _c("span", [
                              _vm._v(
                                _vm._s(item.produtoDescricao) +
                                  " (" +
                                  _vm._s(item.quantidade) +
                                  " und)"
                              ),
                            ]),
                          ])
                        }),
                      ],
                      2
                    ),
                  ]),
                ]),
              ]
            )
          }),
          _vm._v(" "),
          _vm.disabled
            ? _c("fieldset", [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-sm-12 newbtn" }, [
                    _c("div", { staticClass: "form-group" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-success",
                          attrs: { type: "submit" },
                          on: {
                            click: function ($event) {
                              _vm.gerarLote()
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n                            " +
                              _vm._s(
                                _vm.$t(
                                  "__.Crud.dinamizacao.edit_vue_html.gerarLote"
                                )
                              ) +
                              "\n                        "
                          ),
                        ]
                      ),
                    ]),
                  ]),
                ]),
              ])
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }