var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "crud crud-edit" },
    [
      _c(
        "shortcutComponent",
        {
          ref: "shortcutComponent",
          attrs: {
            showAddNew: false,
            showBtnOk: true,
            btOkName: _vm.$t("__.Crud.salvar"),
          },
          on: { "selection-item": _vm.onConfirm },
        },
        [
          _c("div", { attrs: { slot: "component" }, slot: "component" }, [
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col-sm-12" },
                [
                  _c("grid-component", {
                    attrs: {
                      data: _vm.gridData,
                      columns: _vm.gridColumn,
                      showEditItem: false,
                      showRemoveItem: false,
                      showActionColumn: false,
                      showAddNewButton: false,
                      showTableFoot: false,
                      showTools: false,
                      showTableHead: false,
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }