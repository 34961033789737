import KitEmbalagemModel from "../models/kitEmbalagemModel";
import nfetch from "../utils/events/nfetch";

import { IService } from "./base/iService";

export default class KitEmbalagemService extends IService<KitEmbalagemModel> {
    constructor() {
        super("KitEmbalagem");
    }
    public getKitByRecipiente(produtoRecipienteId: number, formaFarmaceuticaId?: number): Promise<Response> {
        return nfetch(
            `/${this.controllerName}/GetKitByRecipiente?produtoRecipienteId=${produtoRecipienteId}&formaFarmaceuticaId=${formaFarmaceuticaId}`,
            {
                credentials: "same-origin",
            },
        );
    }
    public replicar(id: number, idFranquias: string): Promise<Response> {
        return nfetch(`/${this.controllerName}/Replicar?id=${id}&idFranquias=${idFranquias}`, {
            method: "post",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json; charset=UTF-8",
                "XSRF-TOKEN": this.getCookie("XSRF-TOKEN"),
            },
        });
    }
    public getByParent(parentId: number, franquiaId: number): Promise<Response> {
        //@ts-ignore
        return nfetch(`/${this.controllerName}/GetByParentId?parentId=${parentId}&franquiaId=${franquiaId}`, {
            credentials: "same-origin",
        })
            .then((response: Response) => {
                if (response.ok) {
                    return response;
                } else {
                    return this.mensagemErro(response, "Get");
                }
            })
            .catch(error => {});
    }
}
