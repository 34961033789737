import Vue from "vue";

import cnpjComponent from "@/components/child/form/cnpj.vue";
import cpfComponent from "@/components/child/form/cpf.vue";
import fieldsetComponent from "@/components/child/form/fieldset.vue";
import { Component, Prop } from "@/decorators";
import TiposPessoa from "@/models/enum/tiposPessoa";

@Component({
    components: {
        fieldsetComponent,
        cpfComponent,
        cnpjComponent,
    },
})
export default class ImportacaoNfeDestinatarioComponent extends Vue {
    tiposPessoa = TiposPessoa;

    @Prop({ type: Object, required: true }) model;
}
