var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "crud crud-edit" },
    [
      _c(
        "shortcutComponent",
        {
          ref: "shortcutComponent",
          attrs: {
            showBtnOk: !_vm.somenteConsulta,
            btOkName: _vm.$t("__.Crud.salvar"),
            modalClass: "lg-modal",
          },
          on: { "selection-item": _vm.onFracionar },
        },
        [
          _c("div", { attrs: { slot: "component" }, slot: "component" }, [
            _c(
              "form",
              { attrs: { novalidate: "novalidate" } },
              [
                _vm.model.id
                  ? _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-sm-2" }, [
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c(
                              "label",
                              {
                                staticClass: "control-label",
                                attrs: { for: "quantidadeFracionada" },
                              },
                              [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(
                                      _vm.$t(
                                        "__.Crud.produtolote.fracionamento_vue_html.quantidadeFracionada"
                                      )
                                    ) +
                                    "\n                            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("decimal-com-sinal-custom-component", {
                              staticClass: "form-control",
                              attrs: {
                                id: "quantidadeFracionada",
                                name: "quantidadeFracionada",
                                signal: _vm.unidadeMedidaDescricao,
                                disabled: _vm.somenteConsulta,
                              },
                              model: {
                                value: _vm.model.quantidadeFracionada,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.model,
                                    "quantidadeFracionada",
                                    $$v
                                  )
                                },
                                expression: "model.quantidadeFracionada",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-sm-2" }, [
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c(
                              "label",
                              {
                                staticClass: "control-label",
                                attrs: { for: "quantidadePerda" },
                              },
                              [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(
                                      _vm.$t(
                                        "__.Crud.produtolote.fracionamento_vue_html.quantidadePerda"
                                      )
                                    ) +
                                    "\n                            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("decimal-com-sinal-custom-component", {
                              staticClass: "form-control",
                              attrs: {
                                id: "quantidadePerda",
                                name: "quantidadePerda",
                                signal: _vm.unidadeMedidaDescricao,
                                disabled: _vm.somenteConsulta,
                              },
                              model: {
                                value: _vm.model.quantidadePerda,
                                callback: function ($$v) {
                                  _vm.$set(_vm.model, "quantidadePerda", $$v)
                                },
                                expression: "model.quantidadePerda",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-sm-3" }, [
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c(
                              "label",
                              {
                                staticClass: "control-label",
                                attrs: { for: "quantidadeRetiradaAmostragem" },
                              },
                              [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(
                                      _vm.$t(
                                        "__.Crud.produtolote.fracionamento_vue_html.quantidadeRetiradaAmostragem"
                                      )
                                    ) +
                                    "\n                            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("decimal-com-sinal-custom-component", {
                              staticClass: "form-control",
                              attrs: {
                                id: "quantidadeRetiradaAmostragem",
                                name: "quantidadeRetiradaAmostragem",
                                signal: _vm.unidadeMedidaDescricao,
                                disabled: _vm.somenteConsulta,
                              },
                              model: {
                                value: _vm.model.quantidadeRetiradaAmostragem,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.model,
                                    "quantidadeRetiradaAmostragem",
                                    $$v
                                  )
                                },
                                expression:
                                  "model.quantidadeRetiradaAmostragem",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-sm-3" }, [
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c(
                              "label",
                              {
                                staticClass: "control-label",
                                attrs: {
                                  for: "quantidadeEfetivamenteFracionada",
                                },
                              },
                              [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(
                                      _vm.$t(
                                        "__.Crud.produtolote.fracionamento_vue_html.quantidadeEfetivamenteFracionada"
                                      )
                                    ) +
                                    "\n                            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("decimal-com-sinal-custom-component", {
                              staticClass: "form-control",
                              attrs: {
                                id: "quantidadeEfetivamenteFracionada",
                                name: "quantidadeEfetivamenteFracionada",
                                signal: _vm.unidadeMedidaDescricao,
                                disabled: "",
                              },
                              model: {
                                value:
                                  _vm.model.quantidadeEfetivamenteFracionada,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.model,
                                    "quantidadeEfetivamenteFracionada",
                                    $$v
                                  )
                                },
                                expression:
                                  "model.quantidadeEfetivamenteFracionada",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-sm-2" }, [
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c(
                              "label",
                              {
                                staticClass: "control-label",
                                attrs: { for: "embalagensNaoUtilizadas" },
                              },
                              [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(
                                      _vm.$t(
                                        "__.Crud.produtolote.fracionamento_vue_html.embalagensNaoUtilizadas"
                                      )
                                    ) +
                                    "\n                            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("decimal-component", {
                              staticClass: "form-control",
                              attrs: {
                                id: "embalagensNaoUtilizadas",
                                name: "embalagensNaoUtilizadas",
                                disabled: _vm.somenteConsulta,
                              },
                              model: {
                                value: _vm.model.embalagensNaoUtilizadas,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.model,
                                    "embalagensNaoUtilizadas",
                                    $$v
                                  )
                                },
                                expression: "model.embalagensNaoUtilizadas",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.model.id
                  ? _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-sm-12" }, [
                        _c("div", { staticClass: "form-group" }, [
                          _c(
                            "label",
                            {
                              staticClass: "control-label",
                              attrs: { for: "salaFracionamento" },
                            },
                            [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(
                                    _vm.$t(
                                      "__.Crud.produtolote.fracionamento_vue_html.salaFracionamento"
                                    )
                                  ) +
                                  "\n                            "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.model.salaFracionamento,
                                expression: "model.salaFracionamento",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: {
                              id: "salaFracionamento",
                              name: "salaFracionamento",
                              disabled: _vm.somenteConsulta,
                            },
                            domProps: { value: _vm.model.salaFracionamento },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.model,
                                  "salaFracionamento",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]),
                      ]),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.model.id
                  ? _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-sm-3" }, [
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c(
                              "label",
                              {
                                staticClass: "control-label",
                                attrs: { for: "temperatura" },
                              },
                              [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(
                                      _vm.$t(
                                        "__.Crud.produtolote.fracionamento_vue_html.temperatura"
                                      )
                                    ) +
                                    "\n                            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("decimal-com-sinal-custom-component", {
                              staticClass: "form-control",
                              attrs: {
                                id: "temperatura",
                                name: "temperatura",
                                signal: "ºC",
                                disabled: _vm.somenteConsulta,
                              },
                              model: {
                                value: _vm.model.temperatura,
                                callback: function ($$v) {
                                  _vm.$set(_vm.model, "temperatura", $$v)
                                },
                                expression: "model.temperatura",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-sm-3" }, [
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c(
                              "label",
                              {
                                staticClass: "control-label",
                                attrs: { for: "umidade" },
                              },
                              [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(
                                      _vm.$t(
                                        "__.Crud.produtolote.fracionamento_vue_html.umidade"
                                      )
                                    ) +
                                    "\n                            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("decimal-com-sinal-custom-component", {
                              staticClass: "form-control",
                              attrs: {
                                id: "umidade",
                                name: "umidade",
                                signal: "%",
                                disabled: _vm.somenteConsulta,
                              },
                              model: {
                                value: _vm.model.umidade,
                                callback: function ($$v) {
                                  _vm.$set(_vm.model, "umidade", $$v)
                                },
                                expression: "model.umidade",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-sm-3" }, [
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c(
                              "label",
                              {
                                staticClass: "control-label",
                                attrs: { for: "horaInicio" },
                              },
                              [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(
                                      _vm.$t(
                                        "__.Crud.produtolote.fracionamento_vue_html.horaInicio"
                                      )
                                    ) +
                                    "\n                            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("date-time-picker-component", {
                              attrs: {
                                id: "horaInicio",
                                name: "horaInicio",
                                pickTime: true,
                                disabled: _vm.somenteConsulta,
                              },
                              model: {
                                value: _vm.model.horaInicio,
                                callback: function ($$v) {
                                  _vm.$set(_vm.model, "horaInicio", $$v)
                                },
                                expression: "model.horaInicio",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-sm-3" }, [
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c(
                              "label",
                              {
                                staticClass: "control-label",
                                attrs: { for: "horaFim" },
                              },
                              [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(
                                      _vm.$t(
                                        "__.Crud.produtolote.fracionamento_vue_html.horaFim"
                                      )
                                    ) +
                                    "\n                            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("date-time-picker-component", {
                              attrs: {
                                id: "horaFim",
                                name: "horaFim",
                                pickTime: true,
                                disabled: _vm.somenteConsulta,
                              },
                              model: {
                                value: _vm.model.horaFim,
                                callback: function ($$v) {
                                  _vm.$set(_vm.model, "horaFim", $$v)
                                },
                                expression: "model.horaFim",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.model.id
                  ? _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-sm-12" }, [
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c(
                              "label",
                              {
                                staticClass: "control-label",
                                attrs: { for: "observacao" },
                              },
                              [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(
                                      _vm.$t(
                                        "__.Crud.produtolote.fracionamento_vue_html.observacao"
                                      )
                                    ) +
                                    "\n                            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("textarea-component", {
                              staticClass: "form-control",
                              attrs: {
                                id: "observacao",
                                name: "observacao",
                                rows: 3,
                                disabled: _vm.somenteConsulta,
                              },
                              model: {
                                value: _vm.model.observacao,
                                callback: function ($$v) {
                                  _vm.$set(_vm.model, "observacao", $$v)
                                },
                                expression: "model.observacao",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "fieldset-component",
                  {
                    attrs: {
                      title: _vm.$t(
                        "__.Crud.produtolote.edit_vue_html.fracionamento"
                      ),
                      collapsed: false,
                    },
                  },
                  [
                    _c(
                      "div",
                      { attrs: { slot: "rows" }, slot: "rows" },
                      [
                        _vm._l(_vm.model.itens, function (item, index) {
                          return _c(
                            "div",
                            {
                              key: index,
                              staticClass:
                                "row list-inputs-fracionamento-container",
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "col-sm-11" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "col-sm-3 list-inputs-column",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          directives: [
                                            {
                                              name: "tooltip",
                                              rawName: "v-tooltip",
                                              value: _vm.errors.first(
                                                "quantidade" + index
                                              ),
                                              expression:
                                                "errors.first('quantidade' + index)",
                                            },
                                          ],
                                          staticClass: "form-group",
                                        },
                                        [
                                          index == 0
                                            ? _c(
                                                "label",
                                                {
                                                  staticClass: "control-label",
                                                  attrs: {
                                                    for: "quantidade" + index,
                                                    title:
                                                      _vm.$t("__.obrigatorio"),
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        color: "red",
                                                      },
                                                    },
                                                    [_vm._v("*")]
                                                  ),
                                                  _vm._v(
                                                    "\n                                            " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "__.Crud.produtolote.fracionamento_vue_html.quantidade"
                                                        )
                                                      ) +
                                                      "\n                                        "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _c(
                                            "decimal-com-sinal-custom-component",
                                            {
                                              directives: [
                                                {
                                                  name: "validate",
                                                  rawName: "v-validate",
                                                  value:
                                                    "required|maior_que_zero",
                                                  expression:
                                                    "'required|maior_que_zero'",
                                                },
                                              ],
                                              staticClass: "form-control",
                                              attrs: {
                                                id: "quantidade" + index,
                                                name: "quantidade" + index,
                                                signal: "un",
                                                disabled: _vm.somenteConsulta,
                                              },
                                              on: {
                                                input: function () {
                                                  return _vm.onChangeQuantidade(
                                                    item
                                                  )
                                                },
                                              },
                                              model: {
                                                value: item.quantidade,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    item,
                                                    "quantidade",
                                                    $$v
                                                  )
                                                },
                                                expression: "item.quantidade",
                                              },
                                            }
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "col-sm-2 list-inputs-column",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          directives: [
                                            {
                                              name: "tooltip",
                                              rawName: "v-tooltip",
                                              value: _vm.errors.first(
                                                "volume" + index
                                              ),
                                              expression:
                                                "errors.first('volume' + index)",
                                            },
                                          ],
                                          staticClass: "form-group",
                                        },
                                        [
                                          index == 0
                                            ? _c(
                                                "label",
                                                {
                                                  staticClass: "control-label",
                                                  attrs: {
                                                    for: "volume" + index,
                                                    title:
                                                      _vm.$t("__.obrigatorio"),
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        color: "red",
                                                      },
                                                    },
                                                    [_vm._v("*")]
                                                  ),
                                                  _vm._v(
                                                    "\n                                            " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "__.Crud.produtolote.fracionamento_vue_html.volume"
                                                        )
                                                      ) +
                                                      "\n                                        "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _c(
                                            "decimal-com-sinal-custom-component",
                                            {
                                              directives: [
                                                {
                                                  name: "validate",
                                                  rawName: "v-validate",
                                                  value:
                                                    "required|maior_que_zero",
                                                  expression:
                                                    "'required|maior_que_zero'",
                                                },
                                              ],
                                              staticClass: "form-control",
                                              attrs: {
                                                id: "volume" + index,
                                                name: "volume" + index,
                                                signal:
                                                  _vm.getUnidadeMedidaItem(
                                                    item
                                                  ),
                                                disabled: _vm.somenteConsulta,
                                              },
                                              on: {
                                                input: function () {
                                                  return _vm.onCalcularTotal(
                                                    item
                                                  )
                                                },
                                              },
                                              model: {
                                                value: item.volume,
                                                callback: function ($$v) {
                                                  _vm.$set(item, "volume", $$v)
                                                },
                                                expression: "item.volume",
                                              },
                                            }
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "col-sm-2 list-inputs-column",
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "form-group" },
                                        [
                                          index == 0
                                            ? _c(
                                                "label",
                                                {
                                                  staticClass: "control-label",
                                                  attrs: {
                                                    for: "total" + index,
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                            " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "__.Crud.produtolote.fracionamento_vue_html.total"
                                                        )
                                                      ) +
                                                      "\n                                        "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _c(
                                            "decimal-com-sinal-custom-component",
                                            {
                                              staticClass: "form-control",
                                              attrs: {
                                                id: "total" + index,
                                                name: "total" + index,
                                                disabled: "",
                                                signal:
                                                  _vm.getUnidadeMedidaItem(
                                                    item
                                                  ),
                                              },
                                              model: {
                                                value: item.total,
                                                callback: function ($$v) {
                                                  _vm.$set(item, "total", $$v)
                                                },
                                                expression: "item.total",
                                              },
                                            }
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "col-sm-3 list-inputs-column",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          directives: [
                                            {
                                              name: "tooltip",
                                              rawName: "v-tooltip",
                                              value: _vm.errors.first(
                                                "unidadeMedidaId" + index
                                              ),
                                              expression:
                                                "errors.first('unidadeMedidaId' + index)",
                                            },
                                          ],
                                          staticClass: "form-group",
                                        },
                                        [
                                          index == 0
                                            ? _c(
                                                "label",
                                                {
                                                  staticClass: "control-label",
                                                  attrs: {
                                                    for:
                                                      "unidadeMedidaId" + index,
                                                    title:
                                                      _vm.$t("__.obrigatorio"),
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        color: "red",
                                                      },
                                                    },
                                                    [_vm._v("*")]
                                                  ),
                                                  _vm._v(
                                                    "\n                                            " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "__.Crud.produtolote.fracionamento_vue_html.unidadeMedida"
                                                        )
                                                      ) +
                                                      "\n                                        "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _c("combo-component", {
                                            directives: [
                                              {
                                                name: "validate",
                                                rawName: "v-validate",
                                                value:
                                                  "required|maior_que_zero",
                                                expression:
                                                  "'required|maior_que_zero'",
                                              },
                                            ],
                                            attrs: {
                                              tabindex: -1,
                                              data: _vm.unidadeMedidaOptions,
                                              id: "unidadeMedidaId" + index,
                                              name: "unidadeMedidaId" + index,
                                              disabled: _vm.somenteConsulta,
                                            },
                                            on: {
                                              input: function () {
                                                return _vm.onCalcularTotal(item)
                                              },
                                            },
                                            model: {
                                              value: item.unidadeMedidaId,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  item,
                                                  "unidadeMedidaId",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "item.unidadeMedidaId",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "col-sm-2 list-inputs-column",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          directives: [
                                            {
                                              name: "tooltip",
                                              rawName: "v-tooltip",
                                              value: _vm.errors.first(
                                                "quantidadeEmbalagem" + index
                                              ),
                                              expression:
                                                "errors.first('quantidadeEmbalagem' + index)",
                                            },
                                          ],
                                          staticClass: "form-group",
                                        },
                                        [
                                          index == 0
                                            ? _c(
                                                "label",
                                                {
                                                  staticClass: "control-label",
                                                  attrs: {
                                                    for:
                                                      "quantidadeEmbalagem" +
                                                      index,
                                                    title:
                                                      _vm.$t("__.obrigatorio"),
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        color: "red",
                                                      },
                                                    },
                                                    [_vm._v("*")]
                                                  ),
                                                  _vm._v(
                                                    "\n                                            " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "__.Crud.produtolote.fracionamento_vue_html.quantidadeEmbalagem"
                                                        )
                                                      ) +
                                                      "\n                                        "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _c("decimal-component", {
                                            directives: [
                                              {
                                                name: "validate",
                                                rawName: "v-validate",
                                                value:
                                                  "required|maior_que_zero",
                                                expression:
                                                  "'required|maior_que_zero'",
                                              },
                                            ],
                                            staticClass: "form-control",
                                            attrs: {
                                              id: "quantidadeEmbalagem" + index,
                                              name:
                                                "quantidadeEmbalagem" + index,
                                              disabled: _vm.somenteConsulta,
                                            },
                                            on: {
                                              input:
                                                _vm.onChangeQuantidadeEmbalagem,
                                            },
                                            model: {
                                              value: item.quantidadeEmbalagem,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  item,
                                                  "quantidadeEmbalagem",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "item.quantidadeEmbalagem",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("fracionamentoKitEmbalagemComponent", {
                                    ref: "indexEmbalagem",
                                    refInFor: true,
                                    attrs: {
                                      index: index,
                                      somenteConsulta: _vm.somenteConsulta,
                                      itensEmbalagem: item.itensEmbalagens,
                                    },
                                    on: {
                                      onChangeEmbalagem: function (
                                        itemKitEmbalagem
                                      ) {
                                        return _vm.onChangeEmbalagem(
                                          itemKitEmbalagem,
                                          item
                                        )
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              !_vm.somenteConsulta
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "col-sm-1 list-inputs-column",
                                    },
                                    [
                                      _c("div", { staticClass: "form-group" }, [
                                        index == 0
                                          ? _c(
                                              "label",
                                              { staticClass: "control-label" },
                                              [
                                                _vm._v(
                                                  "\n                                        " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "__.Crud.listInputs.acao"
                                                      )
                                                    ) +
                                                    "\n                                    "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _c("span", [
                                          index > 0
                                            ? _c(
                                                "a",
                                                {
                                                  attrs: {
                                                    title: _vm.$t(
                                                      "__.Crud.listInputs.remover"
                                                    ),
                                                    href: "#",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      $event.preventDefault()
                                                      _vm.onRemoveItem(item)
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass:
                                                      "fa fa-times button-remove",
                                                    attrs: {
                                                      "aria-hidden": "true",
                                                    },
                                                  }),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          index == _vm.model.itens.length - 1
                                            ? _c(
                                                "a",
                                                {
                                                  attrs: {
                                                    title: _vm.$t(
                                                      "__.Crud.listInputs.adicionar"
                                                    ),
                                                    href: "#",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      $event.preventDefault()
                                                      return _vm.onInclusaoItem(
                                                        $event
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass:
                                                      "fa fa-plus button-plus",
                                                    attrs: {
                                                      "aria-hidden": "true",
                                                    },
                                                  }),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]),
                                      ]),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          )
                        }),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "row",
                            staticStyle: { "margin-top": "15px" },
                          },
                          [
                            _c("div", { staticClass: "col-sm-9" }),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "col-sm-3 list-inputs-column" },
                              [
                                _c(
                                  "div",
                                  { staticClass: "form-group" },
                                  [
                                    _c(
                                      "label",
                                      {
                                        staticClass: "control-label",
                                        attrs: { for: "quantidadeTotal" },
                                      },
                                      [
                                        _vm._v(
                                          "\n                                        " +
                                            _vm._s(
                                              _vm.$t(
                                                "__.Crud.produtolote.fracionamento_vue_html.quantidadeTotal"
                                              )
                                            ) +
                                            "\n                                    "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("decimal-com-sinal-custom-component", {
                                      staticClass: "form-control",
                                      attrs: {
                                        id: "quantidadeTotal",
                                        name: "quantidadeTotal",
                                        disabled: "",
                                        signal: _vm.unidadeMedidaDescricao,
                                      },
                                      model: {
                                        value: _vm.quantidadeTotal,
                                        callback: function ($$v) {
                                          _vm.quantidadeTotal = $$v
                                        },
                                        expression: "quantidadeTotal",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ]
                        ),
                      ],
                      2
                    ),
                  ]
                ),
              ],
              1
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }