import { GridColumn, GridColumnType } from "@/components/child/grid/gridColumn";
import { Component } from "@/decorators";
import ProdutoDcbModel from "@/models/produto/produtoDcbModel";
import ProdutoDcbService from "@/services/produto/produtoDcbService";

import ListComponentBase from "../listComponentBase";

@Component
export default class ProdutoDcbListComponent extends ListComponentBase<ProdutoDcbModel> {
    get gridColumns(): Array<GridColumn> {
        return [
            new GridColumn("codigo", this.$t("__.ts.codigo"), GridColumnType.String),
            new GridColumn("descricao", this.$t("__.ts.descricao"), GridColumnType.String),
        ];
    }

    private beforeMount() {
        this.setProps(new ProdutoDcbService(), "produtodcb", "descricao");
    }
}
