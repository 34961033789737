import Vue from "vue";

import comboComponent from "@/components/child/form/combo.vue"
import dateTimePickerComponent from "@/components/child/form/datetimepicker.vue"
import decimalComponent from "@/components/child/form/decimal.vue"
import decimalComSinalCustomComponent from "@/components/child/form/decimalComSinalCustom.vue"
import fieldsetComponent from "@/components/child/form/fieldset.vue"
import moedaComponent from "@/components/child/form/moeda.vue"
import gridComponent from "@/components/child/grid/grid.vue"
import { GridColumn, GridColumnType } from "@/components/child/grid/gridColumn";
import { Component } from "@/decorators";
import OrigemMercadoriaService from "@/services/origemMercadoriaService";
import SituacaoTributariaService from "@/services/situacaoTributariaService";

import "../../crud/crud.scss";

@Component({
    components: {
        dateTimePickerComponent,
        fieldsetComponent,
        moedaComponent,
        gridComponent,
        comboComponent,
        decimalComponent,
        decimalComSinalCustomComponent,
    },
})
export default class ItemComponent extends Vue {
    private data = null;

    private origemMercadoriaOptions = [];
    private cstICMSOptions = [];
    private cstIPIOptions = [];
    private cstPISCOFINSOptions = [];

    get gridColumns(): Array<GridColumn> {
        return [
            new GridColumn("nAdicao", this.$t("__.ts.nro") as string, GridColumnType.String),
            new GridColumn("nSeqAdic", "Sequência" as string, GridColumnType.String),
            new GridColumn("cFabricante", this.$t("__.ts.descont") as string, GridColumnType.String),
            new GridColumn("vDescDI", this.$t("__.ts.descont") as string, GridColumnType.Money),
        ];
    }

    public setData(model) {
        this.data = model;

        this.data.prod.qCom = Number(this.data.prod.qCom);
        this.data.prod.vUnCom = Number(this.data.prod.vUnCom);
        this.data.prod.vProd = Number(this.data.prod.vProd);
        this.data.prod.vFrete = Number(this.data.prod.vFrete);
        this.data.prod.vSeg = Number(this.data.prod.vSeg);
        this.data.prod.vDesc = Number(this.data.prod.vDesc);
        this.data.prod.vOutro = Number(this.data.prod.vOutro);

        if (this.data.prod.DI) {
            this.data.prod.DI.dDI = new Date(this.data.prod.DI.dDI);
            this.data.prod.DI.dDesemb = new Date(this.data.prod.DI.dDesemb);

            this.data.prod.DI.adi = this.data.prod.DI.adi.length > 0 ? this.data.prod.DI.adi : [this.data.prod.DI.adi];
        }

        if (this.data.imposto.II) {
            this.data.imposto.II.vBC = Number(this.data.imposto.II.vBC);
            this.data.imposto.II.vII = Number(this.data.imposto.II.vII);
            this.data.imposto.II.vDespAdu = Number(this.data.imposto.II.vDespAdu);
            this.data.imposto.II.vIOF = Number(this.data.imposto.II.vIOF);
        }

        this.data.origem = Number(this.getProp(this.data.imposto.ICMS).orig);
        this.data.cstICMS = Number(this.getProp(this.data.imposto.ICMS).CST ? this.getProp(this.data.imposto.ICMS).CST : this.getProp(this.data.imposto.ICMS).CSOSN);
        this.data.baseICMS = Number(this.getProp(this.data.imposto.ICMS).vBC);
        this.data.aliquotaICMS = Number(this.getProp(this.data.imposto.ICMS).pICMS);
        this.data.valorICMS = Number(this.getProp(this.data.imposto.ICMS).vICMS);

        if (this.data.cstICMS == 51) {
            this.data.valorICMS = Number(this.getProp(this.data.imposto.ICMS).vICMSOp);
            this.data.percentualDiferimento = Number(this.getProp(this.data.imposto.ICMS).pDif);
            this.data.valorICMSDiferido = Number(this.getProp(this.data.imposto.ICMS).vICMSDif);
            this.data.valorICMSPagar = Number(this.getProp(this.data.imposto.ICMS).vICMS);
        }

        if (this.data.cstICMS == 20) {
            this.data.percentualReducaoBase = Number(this.getProp(this.data.imposto.ICMS).pRedBC);
        }

        if (this.data.imposto.ICMSUFDest) {
            this.data.baseUFDestino = Number(this.data.imposto.ICMSUFDest.vBCUFDest);
            this.data.aliquotaUFDestino = Number(this.data.imposto.ICMSUFDest.pICMSUFDest);
            this.data.aliquotaInterestadual = Number(this.data.imposto.ICMSUFDest.pICMSInter);
            this.data.aliquotaInterestadualPartilha = Number(this.data.imposto.ICMSUFDest.pICMSInterPart);
            this.data.ICMSUFDestino = Number(this.data.imposto.ICMSUFDest.vICMSUFDest);
            this.data.ICMSUFRemetente = Number(this.data.imposto.ICMSUFDest.vICMSUFRemet);
            this.data.baseFCPUFDestino = Number(this.data.imposto.ICMSUFDest.vBCFCPUFDest);
            this.data.aliquotaFCPUFDestino = Number(this.data.imposto.ICMSUFDest.pFCPUFDest);
            this.data.ICMSFCPUFDestino = Number(this.data.imposto.ICMSUFDest.vFCPUFDest);
        }

        this.data.pCredSN = null;
        this.data.vCredICMSSN = null;
        if (this.getProp(this.data.imposto.ICMS).pCredSN)
            this.data.pCredSN = Number(this.getProp(this.data.imposto.ICMS).pCredSN);
        if (this.getProp(this.data.imposto.ICMS).vCredICMSSN)
            this.data.vCredICMSSN = Number(this.getProp(this.data.imposto.ICMS).vCredICMSSN);

        if (this.data.imposto.IPI) {
            this.data.cstIPI = Number(this.getProp(this.data.imposto.IPI).CST);
            this.data.baseIPI = Number(this.getProp(this.data.imposto.IPI).vBC);
            this.data.aliquotaIPI = Number(this.getProp(this.data.imposto.IPI).pIPI);
            this.data.valorIPI = Number(this.getProp(this.data.imposto.IPI).vIPI);
        }

        this.data.cstPIS = Number(this.getProp(this.data.imposto.PIS).CST);
        this.data.basePIS = Number(this.getProp(this.data.imposto.PIS).vBC);
        this.data.aliquotaPIS = Number(this.getProp(this.data.imposto.PIS).pPIS);
        this.data.valorPIS = Number(this.getProp(this.data.imposto.PIS).vPIS);

        this.data.cstCOFINS = Number(this.getProp(this.data.imposto.COFINS).CST);
        this.data.baseCOFINS = Number(this.getProp(this.data.imposto.COFINS).vBC);
        this.data.aliquotaCOFINS = Number(this.getProp(this.data.imposto.COFINS).pCOFINS);
        this.data.valorCOFINS = Number(this.getProp(this.data.imposto.COFINS).vCOFINS);

        this.origemMercadoriaOptions = new OrigemMercadoriaService()
            .get()
            .map(item => ({ value: item.codigo, text: `${item.codigo}-${item.descricao}` }));
        this.loadCSTs();
    }

    private loadCSTs() {
        const service = new SituacaoTributariaService();
        if (this.getProp(this.data.imposto.ICMS).CSOSN) {
            this.cstICMSOptions = service
                .getCSOSN()
                .map(item => ({ value: Number(item.codigo), text: `${item.codigo}-${item.descricao}` }));
        } else {
            this.cstICMSOptions = service
                .getICMS()
                .map(item => ({ value: Number(item.codigo), text: `${item.codigo}-${item.descricao}` }));
        }

        this.cstIPIOptions = service
            .getIPI()
            .map(item => ({ value: Number(item.codigo), text: `${item.codigo}-${item.descricao}` }));
        this.cstPISCOFINSOptions = service
            .getPISCOFINS()
            .map(item => ({ value: Number(item.codigo), text: `${item.codigo}-${item.descricao}` }));
    }

    private getProp(value: any) {
        if (!value) return null;
        const key = Object.keys(value);
        if (typeof value[key[0]] == "object") return value[key[0]];
        return value[key[1]];
    }
}
