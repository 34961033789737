const classError = "has-error";
const classTooltip = "mytooltip";
const classTooltipText = "tooltiptext";
const classTooltipTextShow = "tooltiptextshow";

import "./tooltip.scss";

const mouseOver = function (event) {
    this.childNodes[this.childNodes.length - 1].classList.add(classTooltipTextShow);
};

const mouseOut = function (event) {
    this.childNodes[this.childNodes.length - 1].classList.remove(classTooltipTextShow);
};

const Tooltip = {
    install(Vue, options) {
        Vue.directive("tooltip", {
            update: function (el, binding) {
                let spanElement = null;

                if (binding.value) {
                    if (el.classList.contains(classError)) {
                        spanElement = el.getElementsByClassName(classTooltipText)[0];

                        if (spanElement) spanElement.innerHTML = binding.value;
                    } else {
                        spanElement = document.createElement("span");
                        spanElement.classList.add(classTooltipText);
                        spanElement.innerHTML = binding.value;

                        el.appendChild(spanElement);

                        el.classList.add(classError);
                        el.classList.add(classTooltip);

                        el.addEventListener("mouseover", mouseOver);
                        el.addEventListener("mouseout", mouseOut);
                        el.addEventListener("touchstart", mouseOver);
                        el.addEventListener("touchend", mouseOut);
                    }
                } else if (el.classList.contains(classError)) {
                    el.classList.remove(classError);
                    el.classList.remove(classTooltip);

                    el.removeEventListener("mouseover", mouseOver, false);
                    el.removeEventListener("mouseout", mouseOut, false);
                    el.removeEventListener("touchstart", mouseOver, false);
                    el.removeEventListener("touchend", mouseOut, false);

                    spanElement = el.getElementsByClassName(classTooltipText)[0];

                    if (el != spanElement.parentElement) {
                        const spanElements = el.getElementsByClassName(classTooltipText);
                        for (let i = 0; i < spanElements.length; i++) {
                            const spanEl = spanElements[i];
                            if (spanEl && spanEl.parentElement == el) {
                                el.removeChild(spanEl);
                                break;
                            }
                        }
                    } else if (spanElement) {
                        el.removeChild(spanElement);
                    }
                }
            },
        });
    },
};

export default Tooltip;
