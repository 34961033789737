import Vue from "vue";

import { Component, Prop } from "@/decorators";

import "./checkbox.scss";

@Component
export default class CheckboxComponent extends Vue {
    @Prop(String) id: string;
    @Prop(String) name: string;
    @Prop(Boolean) value: boolean;
    @Prop(Boolean) disabled: boolean;

    private onChange(checked) {
        this.$emit("input", checked);
        this.$emit("change", checked);
    }
}
