var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "fieldset-component",
    { attrs: { title: "Pagamentos", collapsed: false } },
    [
      _c(
        "div",
        { attrs: { slot: "rows" }, slot: "rows" },
        [
          !_vm.consulta
            ? _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-sm-4" }, [
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c(
                        "label",
                        {
                          staticClass: "control-label",
                          attrs: { for: "indicadorPagamento" },
                        },
                        [_vm._v("Tipo de pagamento")]
                      ),
                      _vm._v(" "),
                      _c("combo-component", {
                        attrs: {
                          id: "indicadorPagamento",
                          name: "indicadorPagamento",
                          data: _vm.indicadorPagamentoOptions,
                          searchable: false,
                        },
                        model: {
                          value: _vm.model.indicadorPagamento,
                          callback: function ($$v) {
                            _vm.$set(_vm.model, "indicadorPagamento", $$v)
                          },
                          expression: "model.indicadorPagamento",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-sm-4" }, [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "tooltip",
                          rawName: "v-tooltip",
                          value: _vm.errors.first("meioPagamento"),
                          expression: "errors.first('meioPagamento')",
                        },
                      ],
                      staticClass: "form-group",
                    },
                    [
                      _c(
                        "label",
                        {
                          staticClass: "control-label",
                          attrs: {
                            for: "meioPagamento",
                            title: "* este campo é obrigatório",
                          },
                        },
                        [
                          _c("span", { staticStyle: { color: "red" } }, [
                            _vm._v("*"),
                          ]),
                          _vm._v(
                            "\n                        Meio de pagamento\n                    "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("combo-component", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required",
                            expression: "'required'",
                          },
                        ],
                        attrs: {
                          id: "meioPagamento",
                          name: "meioPagamento",
                          data: _vm.meioPagamentoOptions,
                          searchable: false,
                        },
                        model: {
                          value: _vm.model.meioPagamento,
                          callback: function ($$v) {
                            _vm.$set(_vm.model, "meioPagamento", $$v)
                          },
                          expression: "model.meioPagamento",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _vm.model.meioPagamento == 3 || _vm.model.meioPagamento == 4
                  ? _c("div", [
                      _c("div", { staticClass: "col-sm-4" }, [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: _vm.errors.first("bandeira"),
                                expression: "errors.first('bandeira')",
                              },
                            ],
                            staticClass: "form-group",
                          },
                          [
                            _c(
                              "label",
                              {
                                staticClass: "control-label",
                                attrs: {
                                  for: "bandeira",
                                  title: _vm.$t("__.obrigatorio"),
                                },
                              },
                              [
                                _c("span", { staticStyle: { color: "red" } }, [
                                  _vm._v("*"),
                                ]),
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(
                                      _vm.$t(
                                        "__.Crud.contareceber.recebimentoconta_vue_html.bandeira"
                                      )
                                    ) +
                                    "\n                        "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("combo-component", {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: {
                                    rules: {
                                      required:
                                        _vm.model.meioPagamento == 3 ||
                                        _vm.model.meioPagamento == 4,
                                    },
                                  },
                                  expression:
                                    "{\n                                rules: {\n                                    required: model.meioPagamento == 3 || model.meioPagamento == 4,\n                                },\n                            }",
                                },
                              ],
                              attrs: {
                                id: "bandeira",
                                name: "bandeira",
                                data: _vm.bandeiraOptions,
                                searchable: false,
                              },
                              model: {
                                value: _vm.cartaoModel.bandeira,
                                callback: function ($$v) {
                                  _vm.$set(_vm.cartaoModel, "bandeira", $$v)
                                },
                                expression: "cartaoModel.bandeira",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _vm.model.meioPagamento == 3 ||
                      _vm.model.meioPagamento == 4
                        ? _c("div", { class: "col-sm-4" }, [
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "tooltip",
                                    rawName: "v-tooltip",
                                    value: _vm.errors.first("autorizacao"),
                                    expression: "errors.first('autorizacao')",
                                  },
                                ],
                                staticClass: "form-group",
                              },
                              [
                                _c(
                                  "label",
                                  {
                                    staticClass: "control-label",
                                    attrs: {
                                      for: "autorizacao",
                                      title: _vm.$t("__.obrigatorio"),
                                    },
                                  },
                                  [
                                    _vm.nsuObrigatorio &&
                                    (_vm.model.meioPagamento == 3 ||
                                      _vm.model.meioPagamento == 4)
                                      ? _c(
                                          "span",
                                          { staticStyle: { color: "red" } },
                                          [_vm._v("*")]
                                        )
                                      : _vm._e(),
                                    _vm._v(
                                      "\n                            " +
                                        _vm._s(
                                          _vm.$t(
                                            "__.Crud.contareceber.recebimentoconta_vue_html.autorizNSUCartao"
                                          )
                                        ) +
                                        "\n                        "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.cartaoModel.autorizacao,
                                      expression: "cartaoModel.autorizacao",
                                    },
                                    {
                                      name: "validate",
                                      rawName: "v-validate",
                                      value: {
                                        rules: {
                                          required:
                                            _vm.nsuObrigatorio &&
                                            (_vm.model.meioPagamento == 3 ||
                                              _vm.model.meioPagamento == 4),
                                        },
                                      },
                                      expression:
                                        "{\n                                rules: {\n                                    required: nsuObrigatorio && (model.meioPagamento == 3 || model.meioPagamento == 4),\n                                },\n                            }",
                                    },
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    autocomplete: "off",
                                    type: "text",
                                    id: "autorizacao",
                                    name: "autorizacao",
                                  },
                                  domProps: {
                                    value: _vm.cartaoModel.autorizacao,
                                  },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.cartaoModel,
                                        "autorizacao",
                                        $event.target.value
                                      )
                                    },
                                  },
                                }),
                              ]
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: _vm.errors.first("cnpj"),
                              expression: "errors.first('cnpj')",
                            },
                          ],
                          staticClass: "form-group",
                          class: "col-sm-4",
                        },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "control-label",
                              attrs: {
                                for: "cnpj",
                                title: _vm.$t("__.obrigatorio"),
                              },
                            },
                            [
                              _c("span", { staticStyle: { color: "red" } }, [
                                _vm._v("*"),
                              ]),
                              _vm._v(
                                "\n                        " +
                                  _vm._s(
                                    _vm.$t("__.Crud.cliente.edit_vue_html.cnpj")
                                  ) +
                                  "\n                    "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("cnpj-component", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: {
                                  rules: {
                                    cnpj: true,
                                    required:
                                      _vm.model.meioPagamento == 3 ||
                                      _vm.model.meioPagamento == 4,
                                  },
                                },
                                expression:
                                  "{\n                            rules: {\n                                cnpj: true,\n                                required: model.meioPagamento == 3 || model.meioPagamento == 4,\n                            },\n                        }",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: { id: "cnpj", name: "cnpj" },
                            model: {
                              value: _vm.cartaoModel.cnpjOperadoraCartao,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.cartaoModel,
                                  "cnpjOperadoraCartao",
                                  $$v
                                )
                              },
                              expression: "cartaoModel.cnpjOperadoraCartao",
                            },
                          }),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "col-sm-4" }, [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "tooltip",
                          rawName: "v-tooltip",
                          value: _vm.errors.first("valorPagamento"),
                          expression: "errors.first('valorPagamento')",
                        },
                      ],
                      staticClass: "form-group",
                    },
                    [
                      _c(
                        "label",
                        {
                          staticClass: "control-label",
                          attrs: {
                            for: "valorPagamento",
                            title: "* este campo é obrigatório",
                          },
                        },
                        [
                          _c("span", { staticStyle: { color: "red" } }, [
                            _vm._v("*"),
                          ]),
                          _vm._v(
                            "\n                        Valor\n                    "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("moeda-component", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required",
                            expression: "'required'",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: { id: "valorPagamento", name: "valorPagamento" },
                        model: {
                          value: _vm.model.valorPagamento,
                          callback: function ($$v) {
                            _vm.$set(_vm.model, "valorPagamento", $$v)
                          },
                          expression: "model.valorPagamento",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _vm.model.meioPagamento == 99
                  ? _c("div", { staticClass: "col-sm-12" }, [
                      _c("div", { staticClass: "form-group" }, [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "descricao" },
                          },
                          [_vm._v("Descrição")]
                        ),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.model.descricao,
                              expression: "model.descricao",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            type: "text",
                            id: "descricao",
                            name: "descricao",
                          },
                          domProps: { value: _vm.model.descricao },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.model,
                                "descricao",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]),
                    ])
                  : _vm._e(),
              ])
            : _vm._e(),
          _vm._v(" "),
          !_vm.consulta
            ? _c("buttonIncluirComponent", {
                attrs: {
                  alterar: _vm.editingModel,
                  cancelar: _vm.editingModel,
                },
                on: { click: _vm.onAdicionarItem, cancelar: _vm.clear },
              })
            : _vm._e(),
          _vm._v(" "),
          _c("grid-component", {
            attrs: {
              data: _vm.gridData,
              columns: _vm.gridColumns,
              "show-remove-item": !_vm.consulta,
              "show-edit-item": !_vm.consulta,
              showAddNewButton: false,
              "show-table-head": false,
              "show-table-foot": false,
            },
            on: {
              "edit-item": _vm.onEditItem,
              "remove-item": _vm.onRemoveItem,
            },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-sm-6" }, [
              _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c(
                    "label",
                    {
                      staticClass: "control-label",
                      attrs: { for: "valorTroco" },
                    },
                    [_vm._v("Troco")]
                  ),
                  _vm._v(" "),
                  _c("moeda-component", {
                    staticClass: "form-control",
                    attrs: {
                      id: "valorTroco",
                      name: "valorTroco",
                      disabled: _vm.consulta,
                    },
                    model: {
                      value: _vm.nota.valorTroco,
                      callback: function ($$v) {
                        _vm.$set(_vm.nota, "valorTroco", $$v)
                      },
                      expression: "nota.valorTroco",
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }