import checkboxComponent from "@/components/child/form/checkbox.vue";
import dataTooltipComponent from "@/components/child/form/datatooltip.vue";
import { Component } from "@/decorators";
import MotivoCancelamentoModel from "@/models/motivoCancelamentoModel";
import MotivoCancelamentoService from "@/services/motivoCancelamentoService";

import EditComponentBase from "../editComponentBase";

@Component({
    components: {
        checkboxComponent,
        dataTooltipComponent,
    },
})
export default class MotivoCancelamentoEditComponent extends EditComponentBase<MotivoCancelamentoModel> {
    private async beforeMount() {
        await this.setProps(new MotivoCancelamentoService(), "motivos_cancelamento", new MotivoCancelamentoModel());
    }
}
