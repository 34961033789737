var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "crud crud-edit" },
    [
      _c(
        "actionBarComponent",
        {
          attrs: {
            showSave: !_vm.somenteConsulta,
            slots: 1,
            title: _vm.$t(
              "__.Crud.formarecebimento.edit_vue_html.frmRecebimentoEdit"
            ),
          },
          on: { onSave: _vm.save },
        },
        [
          _vm.model.id &&
          _vm.VALIDAR_PERMISSAO_USUARIO("formas_de_recebimento", "insert")
            ? _c(
                "div",
                {
                  attrs: {
                    slot: "buttonSlot0",
                    title: _vm.$t("__.Crud.copiar"),
                  },
                  on: { click: _vm.onCopiar },
                  slot: "buttonSlot0",
                },
                [
                  _c("i", {
                    staticClass: "fa fa-copy",
                    attrs: { "aria-hidden": "true" },
                  }),
                ]
              )
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _c(
        "form",
        {
          attrs: { novalidate: "novalidate" },
          on: {
            submit: function ($event) {
              $event.preventDefault()
              _vm.save()
            },
          },
        },
        [
          _c(
            "fieldset",
            [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-sm-10" }, [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "tooltip",
                          rawName: "v-tooltip",
                          value: _vm.errors.first("descricao"),
                          expression: "errors.first('descricao')",
                        },
                      ],
                      staticClass: "form-group",
                    },
                    [
                      _c(
                        "label",
                        {
                          staticClass: "control-label",
                          attrs: {
                            for: "descricao",
                            title: _vm.$t("__.obrigatorio"),
                          },
                        },
                        [
                          _c("span", { staticStyle: { color: "red" } }, [
                            _vm._v("*"),
                          ]),
                          _vm._v(
                            "\n                            " +
                              _vm._s(
                                _vm.$t(
                                  "__.Crud.formarecebimento.edit_vue_html.descricao"
                                )
                              ) +
                              "\n                        "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          { name: "focus", rawName: "v-focus" },
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.model.descricao,
                            expression: "model.descricao",
                          },
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required|max:256",
                            expression: "'required|max:256'",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          autocomplete: "off",
                          type: "text",
                          id: "descricao",
                          name: "descricao",
                          disabled: _vm.somenteConsulta,
                        },
                        domProps: { value: _vm.model.descricao },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.model,
                              "descricao",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-sm-2" }, [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "tooltip",
                          rawName: "v-tooltip",
                          value: _vm.errors.first("ativo"),
                          expression: "errors.first('ativo')",
                        },
                      ],
                      staticClass: "form-group",
                    },
                    [
                      _c(
                        "label",
                        {
                          staticClass: "control-label",
                          attrs: { for: "ativo" },
                        },
                        [
                          _vm._v(
                            "\n                            " +
                              _vm._s(
                                _vm.$t(
                                  "__.Crud.formarecebimento.edit_vue_html.ativo"
                                )
                              ) +
                              "\n                        "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("checkbox-component", {
                        attrs: {
                          id: "ativo",
                          name: "ativo",
                          disabled: _vm.somenteConsulta,
                        },
                        model: {
                          value: _vm.model.ativo,
                          callback: function ($$v) {
                            _vm.$set(_vm.model, "ativo", $$v)
                          },
                          expression: "model.ativo",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-sm-4" }, [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "tooltip",
                          rawName: "v-tooltip",
                          value: _vm.errors.first("especie"),
                          expression: "errors.first('especie')",
                        },
                      ],
                      staticClass: "form-group",
                    },
                    [
                      _c(
                        "label",
                        {
                          staticClass: "control-label",
                          attrs: {
                            for: "especie",
                            title: _vm.$t("__.obrigatorio"),
                          },
                        },
                        [
                          _c("span", { staticStyle: { color: "red" } }, [
                            _vm._v("*"),
                          ]),
                          _vm._v(
                            "\n                            " +
                              _vm._s(
                                _vm.$t(
                                  "__.Crud.formarecebimento.edit_vue_html.especie"
                                )
                              ) +
                              "\n                        "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("combo-component", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required",
                            expression: "'required'",
                          },
                        ],
                        attrs: {
                          id: "especie",
                          name: "especie",
                          data: _vm.especiesOptions,
                          searchable: false,
                          disabled: _vm.somenteConsulta,
                        },
                        model: {
                          value: _vm.model.especie,
                          callback: function ($$v) {
                            _vm.$set(_vm.model, "especie", $$v)
                          },
                          expression: "model.especie",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _vm.model.especie != 3 && _vm.model.especie != 4
                  ? _c("div", { staticClass: "col-sm-4" }, [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: _vm.errors.first("desconto"),
                              expression: "errors.first('desconto')",
                            },
                          ],
                          staticClass: "form-group",
                        },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "control-label",
                              attrs: { for: "desconto" },
                            },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(
                                    _vm.$t(
                                      "__.Crud.formarecebimento.edit_vue_html.percenDesc"
                                    )
                                  ) +
                                  "\n                        "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("decimal-component", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "maior_igual_a_zero",
                                expression: "'maior_igual_a_zero'",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: {
                              id: "desconto",
                              name: "desconto",
                              disabled: _vm.somenteConsulta,
                            },
                            model: {
                              value: _vm.model.desconto,
                              callback: function ($$v) {
                                _vm.$set(_vm.model, "desconto", $$v)
                              },
                              expression: "model.desconto",
                            },
                          }),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.model.especie != 3 && _vm.model.especie != 4
                  ? _c("div", { staticClass: "col-sm-4" }, [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: _vm.errors.first("juros"),
                              expression: "errors.first('juros')",
                            },
                          ],
                          staticClass: "form-group",
                        },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "control-label",
                              attrs: { for: "juros" },
                            },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(
                                    _vm.$t(
                                      "__.Crud.formarecebimento.edit_vue_html.percenJuros"
                                    )
                                  ) +
                                  "\n                        "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("decimal-component", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "maior_igual_a_zero",
                                expression: "'maior_igual_a_zero'",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: {
                              id: "juros",
                              name: "juros",
                              disabled: _vm.somenteConsulta,
                            },
                            model: {
                              value: _vm.model.juros,
                              callback: function ($$v) {
                                _vm.$set(_vm.model, "juros", $$v)
                              },
                              expression: "model.juros",
                            },
                          }),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.model.especie == 3 || _vm.model.especie == 4
                  ? _c("div", { staticClass: "col-sm-4" }, [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: _vm.errors.first("operadoraCartoesId"),
                              expression: "errors.first('operadoraCartoesId')",
                            },
                          ],
                          staticClass: "form-group",
                        },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "control-label",
                              attrs: {
                                for: "operadoraCartoesId",
                                title: _vm.$t("__.obrigatorio"),
                              },
                            },
                            [
                              _c("span", { staticStyle: { color: "red" } }, [
                                _vm._v("*"),
                              ]),
                              _vm._v(
                                "\n                            " +
                                  _vm._s(
                                    _vm.$t(
                                      "__.Crud.formarecebimento.edit_vue_html.operadCartoes"
                                    )
                                  ) +
                                  "\n                        "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("combo-component", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required",
                                expression: "'required'",
                              },
                            ],
                            attrs: {
                              id: "operadoraCartoesId",
                              name: "operadoraCartoesId",
                              data: _vm.operadorasCartoesOptions,
                              searchable: true,
                              refresh: _vm.loadOperadorasCartoes,
                              disabled: _vm.somenteConsulta,
                            },
                            model: {
                              value: _vm.model.operadoraCartoesId,
                              callback: function ($$v) {
                                _vm.$set(_vm.model, "operadoraCartoesId", $$v)
                              },
                              expression: "model.operadoraCartoesId",
                            },
                          }),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _vm.model.especie == 5
                ? _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-sm-6" }, [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: _vm.errors.first("contaCorrenteOrigemId"),
                              expression:
                                "errors.first('contaCorrenteOrigemId')",
                            },
                          ],
                          staticClass: "form-group",
                        },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "control-label",
                              attrs: {
                                for: "contaCorrenteOrigemId",
                                title: _vm.$t("__.obrigatorio"),
                              },
                            },
                            [
                              _c("span", { staticStyle: { color: "red" } }, [
                                _vm._v("*"),
                              ]),
                              _vm._v(
                                "\n                            " +
                                  _vm._s(
                                    _vm.$t(
                                      "__.Crud.formarecebimento.edit_vue_html.cntCorrOrigem"
                                    )
                                  ) +
                                  "\n                        "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("data-tooltip-component", {
                            attrs: {
                              text: _vm.$t(
                                "__.Crud.formarecebimento.edit_vue_html.cntCorrOrigemVlr"
                              ),
                            },
                          }),
                          _vm._v(" "),
                          _c("combo-component", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required",
                                expression: "'required'",
                              },
                            ],
                            attrs: {
                              id: "contaCorrenteOrigemId",
                              name: "contaCorrenteOrigemId",
                              data: _vm.contaCorrenteOptions,
                              searchable: true,
                              refresh: _vm.loadContasCorrentes,
                              disabled: _vm.somenteConsulta,
                            },
                            model: {
                              value: _vm.model.contaCorrenteOrigemId,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.model,
                                  "contaCorrenteOrigemId",
                                  $$v
                                )
                              },
                              expression: "model.contaCorrenteOrigemId",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-sm-6" }, [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: _vm.errors.first("formaPagamentoOrigemId"),
                              expression:
                                "errors.first('formaPagamentoOrigemId')",
                            },
                          ],
                          staticClass: "form-group",
                        },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "control-label",
                              attrs: {
                                for: "formaPagamentoOrigemId",
                                title: _vm.$t("__.obrigatorio"),
                              },
                            },
                            [
                              _c("span", { staticStyle: { color: "red" } }, [
                                _vm._v("*"),
                              ]),
                              _vm._v(
                                "\n                            " +
                                  _vm._s(
                                    _vm.$t(
                                      "__.Crud.formarecebimento.edit_vue_html.frmPgtoOrigem"
                                    )
                                  ) +
                                  "\n                        "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("data-tooltip-component", {
                            attrs: {
                              text: _vm.$t(
                                "__.Crud.formarecebimento.edit_vue_html.frmPgtoParaSaida"
                              ),
                            },
                          }),
                          _vm._v(" "),
                          _c("combo-component", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required",
                                expression: "'required'",
                              },
                            ],
                            attrs: {
                              id: "formaPagamentoOrigemId",
                              name: "formaPagamentoOrigemId",
                              data: _vm.formaPagamentoOptions,
                              searchable: true,
                              refresh: _vm.loadFormaPagamento,
                              disabled: _vm.somenteConsulta,
                            },
                            model: {
                              value: _vm.model.formaPagamentoOrigemId,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.model,
                                  "formaPagamentoOrigemId",
                                  $$v
                                )
                              },
                              expression: "model.formaPagamentoOrigemId",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.model.especie == 3 || _vm.model.especie == 4
                ? _c(
                    "fieldset-component",
                    {
                      attrs: {
                        title: _vm.$t(
                          "__.Crud.formarecebimento.edit_vue_html.bandeirasSelec"
                        ),
                        collapsed: false,
                      },
                    },
                    [
                      _c(
                        "div",
                        { attrs: { slot: "rows" }, slot: "rows" },
                        [
                          !_vm.somenteConsulta
                            ? _c("div", { staticClass: "row" }, [
                                _c("div", { staticClass: "col-sm-12" }, [
                                  _c(
                                    "div",
                                    { staticClass: "form-group" },
                                    [
                                      _c(
                                        "label",
                                        {
                                          staticClass: "control-label",
                                          attrs: {
                                            for: "bandeiraId",
                                            title: _vm.$t("__.obrigatorio"),
                                          },
                                        },
                                        [
                                          _c(
                                            "span",
                                            { staticStyle: { color: "red" } },
                                            [_vm._v("*")]
                                          ),
                                          _vm._v(
                                            "\n                                    " +
                                              _vm._s(
                                                _vm.$t(
                                                  "__.Crud.formarecebimento.edit_vue_html.bandeira"
                                                )
                                              ) +
                                              "\n                                "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("combo-component", {
                                        attrs: {
                                          id: "bandeiraId",
                                          name: "bandeiraId",
                                          data: _vm.bandeirasOptions,
                                          searchable: true,
                                          refresh: _vm.loadBandeiras,
                                        },
                                        model: {
                                          value: _vm.model.bandeiraId,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.model,
                                              "bandeiraId",
                                              $$v
                                            )
                                          },
                                          expression: "model.bandeiraId",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          !_vm.somenteConsulta
                            ? _c("buttonIncluirComponent", {
                                on: { click: _vm.onInclusaoBandeira },
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _c("grid-component", {
                            attrs: {
                              data: _vm.model.bandeiraXFormaPagamento,
                              columns: _vm.gridColumnsBandeiras,
                              "show-table-head": false,
                              "show-table-foot": false,
                              "show-edit-item": false,
                              showActionColumn: !_vm.somenteConsulta,
                            },
                            on: { "remove-item": _vm.onRemoveItem },
                          }),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.model.especie == 3 || _vm.model.especie == 4
                ? _c(
                    "fieldset-component",
                    {
                      attrs: {
                        title: _vm.$t(
                          "__.Crud.formarecebimento.edit_vue_html.mtdPgtoAceitos"
                        ),
                        collapsed: false,
                      },
                    },
                    [
                      _c(
                        "div",
                        { attrs: { slot: "rows" }, slot: "rows" },
                        [
                          (_vm.model.especie == 3 || _vm.model.especie == 4) &&
                          !_vm.somenteConsulta
                            ? _c(
                                "fieldset-component",
                                {
                                  attrs: {
                                    title: _vm.$t(
                                      "__.Crud.formarecebimento.edit_vue_html.addAltMetodos"
                                    ),
                                    collapsed: _vm.isCollapsedAdd,
                                  },
                                  on: {
                                    collapsedStatus:
                                      _vm.onChangeCollapsedStatusAdd,
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    { attrs: { slot: "rows" }, slot: "rows" },
                                    [
                                      _c("metodo-pagamento-x-forma-component", {
                                        ref: "metodoPagamentoXFormaComponent",
                                        staticClass: "ignorePrint",
                                        attrs: {
                                          formaRecebimentoId: _vm.model.id,
                                          especie: _vm.model.especie,
                                          metodosXForma:
                                            _vm.model.metodoPagamentoXForma,
                                        },
                                        on: {
                                          "inclusao-metodo":
                                            _vm.onAdicionarMetodo,
                                          "edicao-metodo":
                                            _vm.onEditMetodoSubmit,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.model.especie == 3 || _vm.model.especie == 4
                            ? _c("grid-component", {
                                attrs: {
                                  gridNameId: "MetodosGrid",
                                  data: _vm.model.metodoPagamentoXForma,
                                  columns: _vm.gridColumns,
                                  "show-table-head": false,
                                  "show-table-foot": false,
                                  showActionColumn: !_vm.somenteConsulta,
                                },
                                on: {
                                  "remove-item": _vm.onRemoveMetodo,
                                  "edit-item": _vm.onEditMetodo,
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c("fieldset", [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-sm-12" }, [
                _c("div", { staticClass: "form-group newbtn" }, [
                  !_vm.somenteConsulta
                    ? _c(
                        "button",
                        {
                          staticClass: "btn btn-success",
                          attrs: { type: "submit" },
                        },
                        [
                          _c("i", {
                            staticClass: "fa fa-save",
                            attrs: { "aria-hidden": "true" },
                          }),
                          _vm._v(
                            "\n                            " +
                              _vm._s(_vm.$t("__.Crud.salvar")) +
                              "\n                        "
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.somenteConsulta
                    ? _c(
                        "button",
                        {
                          staticClass: "btn btn-secondary",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              _vm.load()
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n                            " +
                              _vm._s(_vm.$t("__.Crud.limpar")) +
                              "\n                        "
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-secondary",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          _vm.cancel()
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n                            " +
                          _vm._s(_vm.$t("__.Crud.voltar")) +
                          "\n                        "
                      ),
                    ]
                  ),
                ]),
              ]),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }