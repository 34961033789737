var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "crud crud-list" },
    [
      _c("actionBarComponent", {
        attrs: {
          title: _vm.$t("__.Crud.inventario.edit_vue_html.ajusteEstq"),
          showSave: false,
        },
      }),
      _vm._v(" "),
      _c(
        "form",
        {
          attrs: { novalidate: "novalidate" },
          on: {
            submit: function ($event) {
              $event.preventDefault()
              _vm.save()
            },
          },
        },
        [
          !_vm.finalizado
            ? _c("fieldset", [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-sm-5" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("tipoInventarioId"),
                            expression: "errors.first('tipoInventarioId')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: {
                              for: "tipoInventario",
                              title: _vm.$t("__.obrigatorio"),
                            },
                          },
                          [
                            _c("span", { staticStyle: { color: "red" } }, [
                              _vm._v("*"),
                            ]),
                            _vm._v(
                              "\n                            " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Crud.inventario.edit_vue_html.tipoInvent"
                                  )
                                ) +
                                "\n                        "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("combo-component", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required",
                              expression: "'required'",
                            },
                          ],
                          ref: "tipoInventario",
                          attrs: {
                            disabled: _vm.desabilita,
                            id: "tipoInventario",
                            name: "tipoInventario",
                            data: _vm.tipoInventarioOptions,
                            searchable: true,
                          },
                          model: {
                            value: _vm.modelInventario.tipoInventario,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.modelInventario,
                                "tipoInventario",
                                $$v
                              )
                            },
                            expression: "modelInventario.tipoInventario",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _vm.modelInventario.tipoInventario == 1
                    ? _c("div", { staticClass: "col-sm-5" }, [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: _vm.errors.first("grupoProduto"),
                                expression: "errors.first('grupoProduto')",
                              },
                            ],
                            staticClass: "form-group",
                          },
                          [
                            _c(
                              "label",
                              {
                                staticClass: "control-label",
                                attrs: {
                                  for: "GrupoProduto",
                                  title: _vm.$t("__.obrigatorio"),
                                },
                              },
                              [
                                _c("span", { staticStyle: { color: "red" } }, [
                                  _vm._v("*"),
                                ]),
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(
                                      _vm.$t(
                                        "__.Crud.inventario.edit_vue_html.grupoProd"
                                      )
                                    ) +
                                    "\n                        "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("combo-component", {
                              ref: "grupoProduto",
                              attrs: {
                                disabled: _vm.desabilita,
                                id: "grupoProduto",
                                name: "grupoProduto",
                                data: _vm.produtoGrupoOptions,
                                searchable: true,
                              },
                              model: {
                                value: _vm.modelInventario.grupoInventarioId,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.modelInventario,
                                    "grupoInventarioId",
                                    $$v
                                  )
                                },
                                expression: "modelInventario.grupoInventarioId",
                              },
                            }),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  !this.modelId
                    ? _c("div", { staticClass: "col-sm-2" }, [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: _vm.errors.first("ativo"),
                                expression: "errors.first('ativo')",
                              },
                            ],
                            staticClass: "form-group",
                          },
                          [
                            _c(
                              "label",
                              {
                                staticClass: "control-label",
                                attrs: { for: "ativo" },
                              },
                              [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(
                                      _vm.$t(
                                        "__.Crud.inventario.edit_vue_html.ignoraLotesZerados"
                                      )
                                    ) +
                                    "\n                        "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("checkbox-component", {
                              attrs: {
                                id: "ignorarLotesZerados",
                                name: "ignorarLotesZerados",
                              },
                              model: {
                                value: _vm.ignorarLotesZerados,
                                callback: function ($$v) {
                                  _vm.ignorarLotesZerados = $$v
                                },
                                expression: "ignorarLotesZerados",
                              },
                            }),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          !_vm.finalizado
            ? _c(
                "div",
                [
                  _c(
                    "fieldset-component",
                    {
                      attrs: {
                        title: _vm.$t(
                          "__.Crud.inventario.edit_vue_html.ajusteEstq"
                        ),
                        collapsed: _vm.colapsed,
                      },
                    },
                    [
                      _c(
                        "div",
                        { attrs: { slot: "rows" }, slot: "rows" },
                        [
                          _c(
                            "grid-component",
                            {
                              attrs: {
                                data: _vm.produtosFiltrados,
                                columns: _vm.produtoGridDataColumns,
                                subColumns: _vm.loteGridDataColumns,
                                subDataName: _vm.subDataName,
                                showExpand: true,
                                extendedColStartOpen: false,
                                classes: _vm.produtosClasses,
                                conditionShowSubData: _vm.conditionShowSubData,
                                "show-table-head": true,
                                "show-sort-icons": false,
                                showEditItem: false,
                                showRemoveItem: false,
                                showActionColumn: true,
                                showTools: false,
                                showAddNewButton: false,
                                extraSubActions: _vm.extraSubActions,
                              },
                              on: {
                                "extra-sub-action": _vm.onExtraSubAction,
                                "change-filter-key": _vm.onFiltrar,
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "grid-footer",
                                  attrs: { slot: "footer" },
                                  slot: "footer",
                                },
                                [
                                  _c("div", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "__.Crud.inventario.edit_vue_html.produtos"
                                        )
                                      )
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", [
                                    _vm._v(_vm._s(_vm.totalProdutos)),
                                  ]),
                                ]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("loading-modal-component", {
                    ref: "loadingModalComponent",
                  }),
                ],
                1
              )
            : _c(
                "div",
                [
                  _c("div", { staticClass: "content" }, [
                    _c("div", { staticClass: "info-panel" }, [
                      _c("h4", [
                        _vm._v(
                          _vm._s(
                            _vm.$t("__.Crud.inventario.edit_vue_html.status")
                          ) +
                            " " +
                            _vm._s(_vm.statusInventario)
                        ),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "grid-component",
                    {
                      ref: "gridcomponent",
                      attrs: {
                        data: _vm.produtosMovimentacaoFiltrados,
                        columns: _vm.produtoFinalizadoGridColumns,
                        subColumns: _vm.loteFinalizadoGridColumns,
                        subDataName: _vm.subMovimentacaoDataName,
                        showExpand: true,
                        extendedColStartOpen: true,
                        classes: _vm.produtosMovimentacaoClasses,
                        conditionShowSubData:
                          _vm.conditionMovimentacaoShowSubData,
                        "page-size": _vm.pageSize,
                        total: _vm.total,
                        tituloPagina: "Movimentos",
                        "show-table-head": true,
                        "show-sort-icons": false,
                        showEditItem: false,
                        showRemoveItem: false,
                        "show-export-pdf": false,
                        "show-export-xls": false,
                        showAddNewButton: false,
                        showActionColumn: false,
                      },
                      on: {
                        "gerar-impressao": function ($event) {
                          _vm.pags = true
                          _vm.tipoDocGerar = 2
                        },
                        "change-filter-key": _vm.onFiltrarConcluidos,
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "grid-footer",
                          attrs: { slot: "footer" },
                          slot: "footer",
                        },
                        [
                          _c("div", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "__.Crud.inventario.edit_vue_html.movimentacoes"
                                )
                              )
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", [_vm._v(_vm._s(_vm.totalMovimentacoes))]),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("loading-modal-component", {
                    ref: "loadingModalComponent",
                  }),
                ],
                1
              ),
          _vm._v(" "),
          _c("fieldset", [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-sm-12 newbtn" }, [
                _c("div", { staticClass: "form-group" }, [
                  !_vm.iniciado && !_vm.consulta
                    ? _c(
                        "button",
                        {
                          staticClass: "btn btn-success",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              _vm.iniciar()
                            },
                          },
                        },
                        [
                          _c("i", {
                            staticClass: "fa fa-check",
                            attrs: { "aria-hidden": "true" },
                          }),
                          _vm._v(
                            "\n                            " +
                              _vm._s(
                                _vm.$t(
                                  "__.Crud.inventario.edit_vue_html.iniciar"
                                )
                              ) +
                              "\n                        "
                          ),
                        ]
                      )
                    : _vm.iniciado && !_vm.consulta
                    ? _c(
                        "button",
                        {
                          staticClass: "btn btn-success",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              _vm.finalizar()
                            },
                          },
                        },
                        [
                          _c("i", {
                            staticClass: "fa fa-check",
                            attrs: { "aria-hidden": "true" },
                          }),
                          _vm._v(
                            "\n                            " +
                              _vm._s(
                                _vm.$t(
                                  "__.Crud.inventario.edit_vue_html.finalizar"
                                )
                              ) +
                              "\n                        "
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.iniciado && !_vm.consulta
                    ? _c(
                        "button",
                        {
                          staticClass: "btn btn-danger",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              _vm.cancel()
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n                            " +
                              _vm._s(_vm.$t("__.Crud.cancelar")) +
                              "\n                        "
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-secondary",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          _vm.voltarLista()
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n                            " +
                          _vm._s(_vm.$t("__.Crud.voltar")) +
                          "\n                        "
                      ),
                    ]
                  ),
                ]),
              ]),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _c("loading-modal-component", { ref: "loadingModalComponent" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }