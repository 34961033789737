import Vue from "vue";
import { mapState } from "vuex";

import actionBarComponent from "@/components/child/actionBar/actionBar.vue";
import cepFieldComponent from "@/components/child/form/cepfield.vue";
import checkboxComponent from "@/components/child/form/checkbox.vue";
import cnpjComponent from "@/components/child/form/cnpj.vue";
import comboComponent from "@/components/child/form/combo.vue";
import dateTimePickerComponent from "@/components/child/form/datetimepicker.vue";
import fieldsetComponent from "@/components/child/form/fieldset.vue";
import { Component, Watch } from "@/decorators";
import CepModel from "@/models/cepModel";
import EnumExtensions from "@/models/enum/extensions/enumExtensions";
import MesesDoAno from "@/models/enum/mesesDoAno";
import periodicidadeBalanco from "@/models/enum/periodicidadeBalanco";
import tipoBalanco from "@/models/enum/tipoBalanco";
import Ufs from "@/models/enum/ufs";
import GerarRelatorioSngpcManual from "@/models/gerarRelatorioSngpcManualModel";
import SngpcManualModel from "@/models/sngpcManualModel";
import UsuarioModel from "@/models/usuarioModel";
import CidadeService from "@/services/external/cidadeService";
import SngpcManualService from "@/services/SngpcManualService";
import { AppState } from "@/store/store";

import "../crud.scss";

@Component({
    components: {
        comboComponent,
        fieldsetComponent,
        cnpjComponent,
        cepFieldComponent,
        checkboxComponent,
        dateTimePickerComponent,
        actionBarComponent,
    },
    computed: {
        ...mapState({
            usuarioLogado: (state: AppState) => state.session.usuarioLogado,
        }),
    },
})
export default class SngpcManualComponent extends Vue {
    // State computed props
    usuarioLogado: UsuarioModel;

    private service = new SngpcManualService();
    private cidadeService = new CidadeService();

    tipoPeriodoId: number = null;
    mes: number = null;

    private tipoBalancoOptions = EnumExtensions.getNamesAndValuesOrderedByNames(tipoBalanco);
    private periodicidadeBalancoOptions = EnumExtensions.getNamesAndValuesOrderedByNames(periodicidadeBalanco);

    model: SngpcManualModel = new SngpcManualModel();
    modelId = 0;

    dadosCep: CepModel = new CepModel();
    ufOptions: Array<Object> = EnumExtensions.getNamesAndValuesOrderedByNames(Ufs);
    cidadesOptions = [];
    mesesOptions = EnumExtensions.getNamesAndValuesOrderedByValues(MesesDoAno);

    private async load() {
        this.model = new SngpcManualModel();

        try {
            const data = await this.service.getDados().withLoading().resolveWithJSON<SngpcManualModel>();
            this.model = data;
            this.model.dataRelatorio = new Date(data.dataRelatorio);
            this.model.numeroAutorizacaoAnvisa = this.usuarioLogado.franquia.autorizacaoAnvisa;
            this.model.numeroAutorizacaoMapa = this.usuarioLogado.franquia.autorizacaoMapa;
            this.loadCidades();
        } catch {}
    }

    //@ts-ignore
    private loadCidades() {
        this.cidadeService.get(this.model.estadoId).then(data => {
            this.model.cidade = data.filter(x => x.value == this.model.cidadeId)[0].text;
        });
    }

    //@ts-ignore
    @Watch("model.balanco")
    private changeTipoBalanco(newValue: tipoBalanco) {
        switch (newValue) {
            case tipoBalanco.Anual:
                this.tipoPeriodoId = 0;
                this.periodicidadeBalancoOptions = EnumExtensions.getNamesAndValuesOrderedByNames(
                    periodicidadeBalanco,
                ).filter(x => x.value == 0);
                break;
            case tipoBalanco.Semestral:
                this.tipoPeriodoId = 0;
                this.periodicidadeBalancoOptions = EnumExtensions.getNamesAndValuesOrderedByNames(
                    periodicidadeBalanco,
                ).filter(x => x.value > 0 && x.value < 3);
                break;
            case tipoBalanco.Trimestral:
                this.tipoPeriodoId = 0;
                this.periodicidadeBalancoOptions = EnumExtensions.getNamesAndValuesOrderedByValues(
                    periodicidadeBalanco,
                ).filter(x => x.value > 2);
                break;
            case tipoBalanco.Mensal:
                this.model.periodo = periodicidadeBalanco.Mensal;
                this.tipoPeriodoId = 3;
                break;
            default:
                this.periodicidadeBalancoOptions = [];
                break;
        }
    }

    private async save() {
        try {
            const isValid = await this.$validator.validateAll();
            if (isValid) {
                await this.service.pdfService.geraPDFWithFilters(this.montarDadosParaRelatorio());
            } else {
                this.$focusErrorField();
            }
        } catch {}
    }

    private montarDadosParaRelatorio(): GerarRelatorioSngpcManual {
        const dados = new GerarRelatorioSngpcManual();
        dados.Ano = this.model.ano;
        dados.AutorizacaoAnvisa = this.model.numeroAutorizacaoAnvisa;
        dados.AutorizacaoMapa = this.model.numeroAutorizacaoMapa;
        dados.Periodicidade = this.model.periodo;
        dados.Regiao = this.model.regiao;
        dados.TipoBalanco = this.model.balanco;
        dados.Cidade = this.model.cidade;
        dados.Mes = this.mes;

        return dados;
    }

    private cancel() {
        this.$router.back();
    }

    private onCepChanged(data: CepModel) {
        this.dadosCep = data;
        this.model.bairro = data.bairro;
        this.model.rua = data.endereco;
    }

    private onCepInvalid() {
        this.$showInfo(this.$t("__.ts.pesqCEP"), this.$t("__.ts.pesqNaoEncResul"));
    }

    private mounted() {
        this.load();
    }
}
