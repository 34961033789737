import BaseModel from "./base/baseModel";
import TelasModel from "./telaModel";

export default class TelaModel extends BaseModel {
    id?: number = null;
    descricao: string = null;
    subtitle: string = null;
    rota: string = null;
    iconeMenu: string = null;
    rotaAcaoRapida: string = null;
    iconeAcaoRapida: string = null;
    controller: string = null;
    paiId?: number = null;
    pai: TelasModel = null;
    ordem?: number = null;
    ocultarMenu = false;
    ativo = true;
    filhos: Array<TelasModel> = [];
    link: string = null;
}
