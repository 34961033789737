import Vue from "vue";

import comboComponent from "@/components/child/form/combo.vue";
import decimalComponent from "@/components/child/form/decimal.vue";
import decimalComSinalCustomComponent from "@/components/child/form/decimalComSinalCustom.vue";
import { Component } from "@/decorators";
import StatusProdutoLote from "@/models/enum/statusProdutoLote";
import ProdutoLoteModel from "@/models/produtoLoteModel";
import ProdutoLoteService from "@/services/produtoLoteService";
import Delay from "@/utils/common/delay";

import ShortcutComponent from "../../shortcut/shortcut";
import shortcutComponent from "../../shortcut/shortcut.vue";

import "../crud.scss";

@Component({
    components: {
        comboComponent,
        decimalComponent,
        decimalComSinalCustomComponent,
        shortcutComponent,
    },
})
export default class AtualizarStatusEditComponent extends Vue {
    private service = new ProdutoLoteService();
    private shortcutComponent: ShortcutComponent = null;

    produtoLoteModel = new ProdutoLoteModel();
    statusProdutoLoteOptions = [];
    newStatusProdutoLote: StatusProdutoLote = null;

    public async show(model: ProdutoLoteModel) {
        this.produtoLoteModel.updateFrom(model);

        this.statusProdutoLoteOptions = new ProdutoLoteModel().getStatusProdutoLoteOptions();

        while (!this.$refs.shortcutComponent) await Delay(100);
        this.shortcutComponent = this.$refs.shortcutComponent as ShortcutComponent;

        this.shortcutComponent.title = `Atualizar status do lote ${this.produtoLoteModel.descricao}`;

        this.shortcutComponent.show();
    }

    private async onAtulizarStatus() {
        try {
            const isValid = await this.$validator.validateAll();
            if (isValid) {
                const sucesso = await this.service
                    .updateStatus(this.produtoLoteModel.id, this.newStatusProdutoLote)
                    .withLoading()
                    .resolveWithoutJSON();

                if (sucesso) {
                    const usuarioDestinoId = 165; //Renata do fracionamento

                    if (this.newStatusProdutoLote === StatusProdutoLote.Liberado) {
                        await this.$notifyOtherUser(
                            usuarioDestinoId,
                            `Lote ${this.produtoLoteModel.descricao} do produto ${this.produtoLoteModel.produtoDescricao} liberado para venda.`,
                            "Lote liberado",
                        );
                    }

                    await this.$showSuccess(
                        this.$t("__.ts.sucesso"),
                        this.$t("__.Crud.produtolote.atualizarStatus_vue_html.atualizadoSucesso"),
                    );

                    this.$emit("onStatusSucesso");
                    this.shortcutComponent.hide();
                }
            }
        } catch {}
    }
}
