import Vue from "vue";
import { mapGetters } from "vuex";

import dataTooltipComponent from "@/components/child/form/datatooltip.vue";
import fieldsetComponent from "@/components/child/form/fieldset.vue";
import moedaComponent from "@/components/child/form/moeda.vue";
import moedaComSinalComponent from "@/components/child/form/moedaComSinal.vue";
import gridComponent from "@/components/child/grid/grid.vue";
import { GridAction } from "@/components/child/grid/gridAction";
import { GridColors, GridColumn, GridColumnType } from "@/components/child/grid/gridColumn";
import { ModalButtonAction } from "@/components/child/modal/modalButtonAction";
import { Component } from "@/decorators";
import ConfiguracaoFranquiaModel from "@/models/configuracaoFranquia/configuracaoFranquiaModel";
import Configuracoes from "@/models/enum/configuracao/configuracoes";
import Especie from "@/models/enum/especiePagamento";
import EspeciePagamento from "@/models/enum/especiePagamento";
import TipoLancamento from "@/models/enum/tipoLancamento";
import MovimentacaoCaixaModel from "@/models/financeiro/movimentacaoCaixaModel";
import PaginationModel from "@/models/paginationModel";
import SessaoCaixaDetalhesFechamentoModel from "@/models/pdv/sessaoCaixaDetalhesFechamentoModel";
import SessaoCaixaModel from "@/models/pdv/sessaoCaixaModel";
import MovimentacaoCaixaService from "@/services/financeiro/movimentacaoCaixaService";
import PDFService from "@/services/pdf/PDFService";
import SessaoCaixaService from "@/services/pdv/sessaoCaixaService";
import { Getters } from "@/store/store";
import arithmeticHelper from "@/utils/common/arithmeticHelper";
import { editDateWithTime } from "@/utils/common/date";
import Bus from "@/utils/events/bus";

import ShortcutComponent from "../shortcut/shortcut";
import shortcutComponent from "../shortcut/shortcut.vue";

import ReforcoComponent from "./reforco";
import reforcoComponent from "./reforco.vue";
import SangriaComponent from "./sangria";
import sangriaComponent from "./sangria.vue";

import "../crud/crud.scss";
import "./fechamentoCaixa.scss";

@Component({
    components: {
        gridComponent,
        dataTooltipComponent,
        fieldsetComponent,
        shortcutComponent,
        reforcoComponent,
        sangriaComponent,
        moedaComponent,
        moedaComSinalComponent,
    },
    computed: {
        ...mapGetters(["GET_CONFIG_FRANQUIA"] as Getters),
    },
})
export default class FechamentoCaixaListComponent extends Vue {
    // State computed props
    GET_CONFIG_FRANQUIA: (configuracao: Configuracoes) => ConfiguracaoFranquiaModel;

    private service = new MovimentacaoCaixaService();
    private sessaoCaixaService = new SessaoCaixaService();
    private PDFService = new PDFService("SessaoCaixaPDF");

    private shortcutReforcoComponent: ShortcutComponent = null;
    private shortcutSangriaComponent: ShortcutComponent = null;
    private reforcoComponent: ReforcoComponent = null;
    private sangriaComponent: SangriaComponent = null;
    possuiErros = false;

    private concluido = false;
    //id da sessao de caixa que esta aberta
    idSessaoAberta = 0;
    modelSessaoAberta: SessaoCaixaModel = new SessaoCaixaModel();
    saldoInicial = 0;
    saldoAtual = 0;
    contaCorrenteDesc: string = null;
    dataHoraFechamento: string = null;
    dataHoraAbertura: string = null;

    progressbar: Array<string> = ["Contagem", "Validação", "Conclusão"]; //3 etapas ...0,1,2
    progressbarIt = 0;

    //conta corrente id do caixa da sessao aberta
    contaCorrenteId: number = null;

    //Totais Gerais
    totalEntradasGeral = 0;
    totalSaidasGeral = 0;
    totalDiferencaGeral = 0;
    totalSaldoGeral = 0;

    // Saldos Resumo
    saldoSangrias = 0;
    saldoReforcos = 0;
    saldoDinheiro = 0;
    saldoCredito = 0;
    saldoDebito = 0;
    saldoBoleto = 0;
    saldoTransferencia = 0;
    saldoCheque = 0;

    gridFilterKey = "";
    gridSortKey = "especieDescricao";
    gridSortOrder = "asc";

    gridExtraActionsAjustaValor: Array<GridAction> = [];
    multExtraActions: Array<object> = [];
    extraButtonAction: Array<ModalButtonAction> = [new ModalButtonAction("Gerar Reforço", "Gerar Sangria")];

    gridData: Array<MovimentacaoCaixaModel> = [];
    gridColumns: Array<GridColumn> = [
        new GridColumn("especieDescricao", "Especie", GridColumnType.String),
        new GridColumn("totalDifCaixa", "Total", GridColumnType.MoneyWithSignal),
        new GridColumn("totalUsuario", "Contagem no Caixa", GridColumnType.MoneyWithSignal),
        new GridColumn("totalDifUsuario", "Diferenças", GridColumnType.MoneyWithSignal),
    ];

    subDataName = "movimentacoes";

    gridColumnsVinculos: Array<GridColumn> = [
        new GridColumn("descricao", "Descrição", GridColumnType.String),
        new GridColumn("descClienteFornecedor", "Cliente/Fornecedor", GridColumnType.String),
        new GridColumn("contaCorrenteDescricao", "Conta Corrente", GridColumnType.String),
        new GridColumn("tiposMovimentacaoDescr", "Tipo", GridColumnType.String),
        new GridColumn("dataLancamento", "Data/Hora", GridColumnType.DateTime),
        new GridColumn("usuario", "Usuario", GridColumnType.String),
        new GridColumn("valor", "valor", GridColumnType.MoneyWithSignal),
    ];

    conditionShowSubData(it) {
        return it["movimentacoes"]["length"] > 0;
    }

    pageIndex = 1;
    pageSize = 20;
    total = 0;
    totalMov = 0;

    private getDataHoraStr(data) {
        return editDateWithTime(data);
    }

    private async load() {
        this.dataHoraFechamento = this.getDataHoraStr(new Date());

        try {
            const data = await this.service
                .list(
                    this.gridFilterKey,
                    this.gridSortKey,
                    this.gridSortOrder,
                    this.pageIndex,
                    this.pageSize,
                    this.contaCorrenteId,
                )
                .withLoading()
                .resolveWithJSON<PaginationModel<MovimentacaoCaixaModel>>();

            this.gridData = data.list;
            this.total = data.total;
            this.totalMov = data.list.reduce((acc, p) => acc + p.movimentacoes.length, 0);

            const somarSaldo = (
                movimentos: MovimentacaoCaixaModel[],
                especie: EspeciePagamento,
                tipo: TipoLancamento = null,
            ) => {
                let saldo = 0;

                if (especie == EspeciePagamento.Transferencia) {
                    saldo =
                        movimentos
                            .find(p => p.especie == EspeciePagamento.Dinheiro)
                            ?.movimentacoes.filter(m =>
                                tipo != null
                                    ? m.tipoLancamento == tipo
                                    : m.especie == EspeciePagamento.Transferencia && m.tipoLancamento == null,
                            )
                            .reduce((acc, p) => acc + p.valor, 0) ?? 0;
                } else {
                    saldo =
                        movimentos
                            .find(p => p.especie == especie)
                            ?.movimentacoes.filter(m => m.tipoLancamento == null)
                            .reduce((acc, p) => acc + p.valor, 0) ?? 0;
                }

                return Math.abs(saldo);
            };

            this.saldoSangrias = somarSaldo(data.list, EspeciePagamento.Transferencia, TipoLancamento.Sangria);
            this.saldoReforcos = somarSaldo(data.list, EspeciePagamento.Transferencia, TipoLancamento.Reforco);
            this.saldoDinheiro = somarSaldo(data.list, EspeciePagamento.Dinheiro);
            this.saldoCredito = somarSaldo(data.list, EspeciePagamento.CartaoCredito);
            this.saldoDebito = somarSaldo(data.list, EspeciePagamento.CartaoDebito);
            this.saldoBoleto = somarSaldo(data.list, EspeciePagamento.Boleto);
            this.saldoTransferencia = somarSaldo(data.list, EspeciePagamento.Transferencia);
            this.saldoCheque = somarSaldo(data.list, EspeciePagamento.Cheque);

            this.saldoAtual = arithmeticHelper.round(
                this.saldoDinheiro + this.saldoReforcos - this.saldoSangrias + this.saldoInicial,
            );
        } catch {}
    }

    private onChangeFilterKey(filterKey: string) {
        this.gridFilterKey = filterKey;
        this.load();
    }

    private onChangeSort(sortKey: string, sortOrder: string) {
        this.gridSortKey = sortKey;
        this.gridSortOrder = sortOrder;
        this.load();
    }

    private onChangePage(pageIndex: number) {
        this.pageIndex = pageIndex;
        this.load();
    }

    private async loadGetIdSessaoAberta() {
        try {
            const data = await this.service.getIdSessaoAberta().then(r => r.json() as Promise<number>);
            this.idSessaoAberta = data;
        } catch {
            this.idSessaoAberta = 0;
        }
    }

    private async loadSaldoInicial() {
        try {
            const data = await this.service.getSaldoInicial().then(r => r.json() as Promise<number>);
            this.saldoInicial = data;
        } catch {
            this.saldoInicial = 0;
        }
    }

    private async loadGetIdSessaoCaixa() {
        try {
            const data = await this.service.getIdCaixaSessaoAberta().then(r => r.json() as Promise<number>);
            this.contaCorrenteId = data;
        } catch {
            this.contaCorrenteId = 0;
        }
    }

    private async loadDescricaoCaixa() {
        try {
            const data = await this.service.getDescricaoCaixaSessaoAberta().then(r => r.text() as Promise<string>);
            this.contaCorrenteDesc = data;
        } catch {
            this.contaCorrenteDesc = null;
        }
    }

    private async loadModelSessaoAberta() {
        try {
            const data = await this.sessaoCaixaService
                .get(this.idSessaoAberta)
                .withLoading()
                .then(r => r.json() as Promise<SessaoCaixaModel>);

            this.modelSessaoAberta.updateFrom(data);
            this.dataHoraAbertura = this.getDataHoraStr(this.modelSessaoAberta.dataHoraAbertura);
        } catch {
            this.$router.back();
        }
    }

    private cancel() {
        this.$router.back();
    }

    private gerarReforco(valorReforco = 0) {
        this.shortcutReforcoComponent.title = "Gerar Reforço da Diferença";
        this.reforcoComponent.clear(this.contaCorrenteDesc, this.saldoAtual, this.contaCorrenteId, valorReforco);
        this.shortcutReforcoComponent.show();
    }

    private gerarSangria(valorSangria = 0) {
        this.shortcutSangriaComponent.title = "Gerar Sangria da Diferença";
        const contaCorrenteFechamentoCaixaConfig = this.GET_CONFIG_FRANQUIA(
            Configuracoes.ContaCorrenteSangriaFinalizacaoCaixa,
        );

        this.sangriaComponent.clear(
            this.contaCorrenteId,
            this.contaCorrenteDesc,
            this.saldoAtual,
            valorSangria,
            " devido a diferença no fechamento de caixa",
            contaCorrenteFechamentoCaixaConfig ? contaCorrenteFechamentoCaixaConfig.contaCorrenteId : null,
        );
        this.shortcutSangriaComponent.show();
    }

    private async onConfirmReforco() {
        const sucesso = await this.reforcoComponent.onGerarReforco(this.contaCorrenteId);
        if (sucesso) {
            const dinheiro = this.gridData.filter(p => p.especie == Especie.Dinheiro)[0];
            dinheiro.total = dinheiro.total + this.reforcoComponent.valorReforco;
            this.saldoAtual = this.saldoAtual + this.reforcoComponent.valorReforco;
            dinheiro.divergente = true;
            this.atualizaGrid();
            this.shortcutReforcoComponent.hide();

            this.load();
        }
    }

    private async onConfirmSangria() {
        const sucesso = await this.sangriaComponent.onGerarSangria();
        if (sucesso) {
            //atualiza valor total de dinheiro
            const dinheiro = this.gridData.filter(p => p.especie == Especie.Dinheiro)[0];
            dinheiro.total = dinheiro.total - this.sangriaComponent.sangria;
            this.saldoAtual = this.saldoAtual - this.sangriaComponent.sangria;
            //mostra q era divergente e teve q gerar a diferença
            dinheiro.divergente = true;
            this.atualizaGrid();
            this.shortcutSangriaComponent.hide();

            this.load();
        }
    }

    private onProgClick(index: number) {
        if (index < this.progressbarIt) {
            this.progressbarIt = index;
        } else if (this.validationForward()) {
            this.progressbarIt = index;
        }
    }

    private onPrev() {
        this.progressbarIt--;
        if (this.progressbarIt < 0) {
            this.progressbarIt = 0;
        }
    }

    private onForw() {
        if (this.validationForward()) {
            this.progressbarIt++;
            if (this.progressbarIt > 2) {
                this.progressbarIt = 2;
            }
        }
    }
    //chamar antes da troca para frente
    private validationForward() {
        if (this.progressbarIt == 0) {
            this.atualizaGrid();
            return true;
        } else {
            this.atualizaGrid(this.progressbarIt + 1);
            return !this.possuiErros;
        }
    }

    private atualizaGrid(nextPos = 1) {
        //atualiza hora
        this.dataHoraFechamento = this.getDataHoraStr(new Date());

        this.multExtraActions.splice(0);
        this.possuiErros = false;

        this.totalEntradasGeral = 0;
        this.totalSaidasGeral = 0;
        this.totalDiferencaGeral = 0;
        this.totalSaldoGeral = 0;

        for (let i = 0; i < this.gridData.length; i++) {
            if (this.gridData[i].totalUsuario == null) this.gridData[i].totalUsuario = 0;

            const saldo = this.gridData[i].especie == Especie.Dinheiro ? this.saldoInicial : 0;
            this.gridData[i].totalDifCaixa = this.gridData[i].total + saldo;

            this.gridData[i].totalDifUsuario = this.gridData[i].totalUsuario - this.gridData[i].totalDifCaixa;
            this.gridData[i].totalDifUsuario = parseFloat(this.gridData[i].totalDifUsuario.toFixed(2));
            if (
                this.gridData[i].totalDifUsuario != 0 &&
                (this.gridData[i].justificativa == null || this.gridData[i].justificativa.length < 15)
            ) {
                this.gridExtraActionsAjustaValor[i] = new GridAction(
                    this.gridData[i].totalDifUsuario > 0 ? "gerar-reforco" : "gerar-sangria",
                    this.gridData[i].especie == Especie.Dinheiro ? "Ajustar Diferença" : "Justificar",
                    "fa fa-exclamation-circle",
                    nextPos == 2 ? GridColors.RED : GridColors.LIGHTGREEN,
                    true,
                );

                this.possuiErros = true;
            } else {
                this.gridExtraActionsAjustaValor[i] = new GridAction(
                    "ok",
                    "Valor Correto e/ou Justificado",
                    "fa fa-check-circle",
                    GridColors.GREEN,
                    true,
                );
            }

            this.totalEntradasGeral += this.gridData[i].entradas;
            this.totalSaidasGeral -= this.gridData[i].saidas * -1;
            this.totalDiferencaGeral += this.gridData[i].totalDifUsuario;
            this.totalDiferencaGeral += this.gridData[i].totalDifUsuario;

            this.totalDiferencaGeral = parseFloat(this.totalDiferencaGeral.toFixed(2));
        }
        this.totalSaldoGeral = this.totalEntradasGeral + this.totalSaidasGeral;

        if (this.gridExtraActionsAjustaValor.length > 0 && this.multExtraActions.length == 0) {
            this.multExtraActions.push(this.gridExtraActionsAjustaValor);
        }
    }

    private onExtraAction(name: string, model: MovimentacaoCaixaModel) {
        if (model.especie == Especie.Dinheiro) {
            if (name == "gerar-sangria") {
                this.gerarSangria(model.totalDifUsuario * -1);
            }
            if (name == "gerar-reforco") {
                this.gerarReforco(model.totalDifUsuario);
            }
        } else {
            let justificativa = model.justificativa != null ? model.justificativa : "Justifique!";

            this.$showAlert({
                title: "Justificar Diferenças de valor",
                text: "Justificar diferença entre o calculo do sitema e a soma do caixa",
                icon: "info",
                html: `<label>Justificativa da Diferença de valores</label>`,
                showCancelButton: true,
                inputValue: justificativa,
                input: "textarea",
                inputValidator: function (textarea) {
                    return new Promise(function (resolve, reject) {
                        if (textarea.length > 1000) {
                            reject("Justificativa muito longa, use no maximo 1000 caracteres.");
                        } else if (textarea.length < 15) {
                            reject("justificativa muito curta, use no minimo 15 caracteres para descrição.");
                        } else {
                            justificativa = textarea;
                            resolve("");
                        }
                    });
                },
            })
                .then(() => {
                    const index = this.gridData.indexOf(model);
                    this.gridData[index].justificativa = justificativa;
                    this.gridData[index].divergente = true;
                    this.multExtraActions.splice(0);
                    this.atualizaGrid();
                })
                .catch(() => {});
        }
    }

    private getNewModelFechamento() {
        //concluir/fechamento
        //atualiza hora
        this.dataHoraFechamento = this.getDataHoraStr(new Date());
        const model = new SessaoCaixaModel();
        //seta model
        //variaveis q nao serao alteradas pelo update, colocados valores quaisquer
        model.dataHoraAbertura = this.modelSessaoAberta.dataHoraAbertura;
        model.saldoInicial = this.saldoInicial;
        model.pdvId = this.modelSessaoAberta.pdvId;
        model.usuarioId = this.modelSessaoAberta.usuarioId;
        model.id = this.modelSessaoAberta.id;
        //vai ser refeito na cotroller tb
        model.dataHoraFechamento = new Date();
        model.saldoFechamento = this.saldoAtual;
        model.totalEntradas = this.totalEntradasGeral;
        model.totalSaidas = this.totalSaidasGeral * -1;
        //nao gravado ainda  ?
        model.valorDiferenca = this.totalDiferencaGeral;
        model.valorDiferenca = parseFloat(this.totalDiferencaGeral.toFixed(2));
        model.detalhesFechamento = [];
        for (let i = 0; i < this.gridData.length; i++) {
            const detalhes = new SessaoCaixaDetalhesFechamentoModel();
            detalhes.entradas = this.gridData[i].entradas;
            detalhes.saidas = this.gridData[i].saidas;
            detalhes.especie = this.gridData[i].especie;
            detalhes.justificativa = this.gridData[i].justificativa;
            detalhes.totalUsuario = this.gridData[i].totalUsuario;
            detalhes.sessaoCaixaId = model.id;
            detalhes.divergente =
                this.gridData[i].divergente &&
                (this.gridData[i].justificativa != null || this.gridData[i].especie == Especie.Dinheiro);
            model.detalhesFechamento.push(detalhes);
        }
        return model;
    }

    private async onConcluir() {
        if (this.progressbarIt == 2) {
            try {
                const sucesso = await this.sessaoCaixaService
                    .update(this.getNewModelFechamento())
                    .withLoading()
                    .resolveWithoutJSON();

                if (sucesso) {
                    await this.$showSuccess("Fechamento!", "Fechamento foi salvo com sucesso.");

                    const response = await this.$showQuestion(
                        "Fechamento!",
                        "Deseja imprimir o resumo de fechamento de caixa?",
                    );

                    if (response) {
                        await this.PDFService.geraPDF(this.idSessaoAberta);
                    }

                    this.concluido = true;
                    Bus.$emit("sessao-caixa-status", false);
                    this.$router.back();
                }
            } catch {}
        }
    }

    private mounted() {
        this.reforcoComponent = this.$refs.reforcoComponent as ReforcoComponent;
        this.sangriaComponent = this.$refs.sangriaComponent as SangriaComponent;
        this.shortcutReforcoComponent = this.$refs.shortcutReforcoComponent as ShortcutComponent;
        this.shortcutSangriaComponent = this.$refs.shortcutSangriaComponent as ShortcutComponent;

        this.concluido = false;

        Promise.all([
            this.loadSaldoInicial(),
            this.loadDescricaoCaixa(),
            this.loadGetIdSessaoCaixa(),
            this.loadGetIdSessaoAberta(),
        ])
            .withLoading()
            .then(async () => {
                if (this.idSessaoAberta > 0) {
                    this.loadModelSessaoAberta();
                    this.load();
                } else {
                    await this.$showWarning("Fechamento!", "O caixa já está fechado!");

                    this.$router.back();
                }
            })
            .catch(() => {});
    }
}
