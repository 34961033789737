import BaseModel from "./base/baseModel";
import FracionamentoCancelamentoModel from "./fracionamentoCancelamentoModel";
import ManipulacaoOrdemCancelamentoModel from "./manipulacaoOrdemCancelamentoModel";
import VendaItemModel from "./vendaItemModel";

export default class VendaCancelamentoItemModel extends BaseModel {
    id?: number = null;
    vendaCancelamentoId = 0;
    vendaItemId?: number = null;
    vendaItem: VendaItemModel = null;
    quantidade?: number = null;
    unidadeMedidaId?: number = null;
    unidadeMedidaDescricao: string = null;
    manipulacaoOrdemCancelamentoId?: number = null;
    manipulacaoOrdemCancelamento?: ManipulacaoOrdemCancelamentoModel = null;
    fracionamentoCancelamentoId?: number = null;
    fracionamentoCancelamento?: FracionamentoCancelamentoModel = null;

    descricao: string = null;

    itens = [];
    valorTotal = 0;
}
