import BaseModel from "./base/baseModel";

export default class CompraFornecedorModel extends BaseModel {
    id?: number = null;
    compraId?: number = null;
    fornecedorDescricao?: string = null;
    fornecedorId?: number = null;
    unidadeMedidaId?: number = null;
    key?: string = null;
    emailCotacaoEnviado = false;
}
