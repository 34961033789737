var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "shotcut-component crud" },
      [
        _c(
          "modal-component",
          {
            ref: "modalComponent",
            attrs: {
              "modal-class": _vm.modalClass,
              title: _vm.title,
              headerColor: _vm.headerColor,
              extraButtons: _vm.extraButtons,
              showBtnOk: _vm.showBtnOk,
              btOkName: _vm.btOkName,
              btOkShortKey: _vm.btOkShortKey,
            },
            on: {
              "btn-ok-click": _vm.onBtnOkClick,
              "extra-button-click": _vm.extraButtonClick,
              "hide-button-click": _vm.hideBtnClick,
            },
          },
          [
            _c(
              "div",
              {
                staticClass: "col-sm-12",
                attrs: { slot: "body" },
                slot: "body",
              },
              [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.showAddNew,
                        expression: "showAddNew",
                      },
                    ],
                    attrs: { slot: "tools" },
                    slot: "tools",
                  },
                  [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-primary",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            _vm.onBtnAddNewClick()
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n                        " +
                            _vm._s(
                              _vm.$t(
                                "__.Components.parent.shortcut.shortcut_vue_html.addNovo"
                              )
                            ) +
                            "\n                        "
                        ),
                        _c("i", {
                          staticClass: "fa fa-right fa-plus-circle",
                          attrs: { "aria-hidden": "true" },
                        }),
                      ]
                    ),
                  ]
                ),
                _vm._v(" "),
                _vm._t("component"),
              ],
              2
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "footer-right",
                attrs: { slot: "footerRight" },
                slot: "footerRight",
              },
              [_vm._t("footerRight")],
              2
            ),
          ]
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "shotcut-addNewComponent" },
      [
        _c(
          "modal-component",
          {
            ref: "modalAddNewComponent",
            attrs: {
              "modal-class": _vm.modalClass,
              title:
                _vm.$t(
                  "__.Components.parent.shortcut.shortcut_vue_html.addNovo"
                ) + _vm.title,
            },
            on: { "btn-ok-click": _vm.onBtnOkClickAddNew },
          },
          [
            _c(
              "div",
              { attrs: { slot: "body" }, slot: "body" },
              [_vm._t("componentAddNew")],
              2
            ),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }