var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "crud crud-edit" },
    [
      _c("actionBarComponent", {
        attrs: {
          title: _vm.$t(
            "__.Crud.configuracaowhatsapp.edit_vue_html.configWhatsApp"
          ),
          showSave: false,
        },
      }),
      _vm._v(" "),
      _c("form", { attrs: { novalidate: "novalidate" } }, [
        _c("fieldset", [
          _vm.exibirBotaoIniciarConfiguracao
            ? _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-sm-6" }, [
                  _c("h4", [
                    _vm._v(
                      "\n                        " +
                        _vm._s(
                          _vm.$t(
                            "__.Crud.configuracaowhatsapp.edit_vue_html.integracaoPermite"
                          )
                        ) +
                        "\n                    "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("h4", [
                    _vm._v(
                      "\n                        " +
                        _vm._s(
                          _vm.$t(
                            "__.Crud.configuracaowhatsapp.edit_vue_html.paraFazerAConfiguracao"
                          )
                        ) +
                        "\n                    "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-success",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            _vm.iniciarConfiguracao()
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n                            " +
                            _vm._s(
                              _vm.$t(
                                "__.Crud.configuracaowhatsapp.edit_vue_html.iniciarConfiguracao"
                              )
                            ) +
                            "\n                        "
                        ),
                      ]
                    ),
                  ]),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.exibirQrCode
            ? _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-sm-12" }, [
                  _c("h4", [
                    _vm._v(
                      "\n                        " +
                        _vm._s(
                          _vm.$t(
                            "__.Crud.configuracaowhatsapp.edit_vue_html.leiaQrCode"
                          )
                        ) +
                        "\n                        "
                    ),
                    _c(
                      "a",
                      {
                        staticStyle: { color: "blue" },
                        attrs: {
                          href: "https://faq.whatsapp.com/1317564962315842/?helpref=uf_share",
                          target: "_blank",
                        },
                      },
                      [
                        _vm._v(
                          "\n                            " +
                            _vm._s(
                              _vm.$t(
                                "__.Crud.configuracaowhatsapp.edit_vue_html.cliqueAqui"
                              )
                            ) +
                            "\n                        "
                        ),
                      ]
                    ),
                    _vm._v(
                      "\n                        " +
                        _vm._s(
                          _vm.$t(
                            "__.Crud.configuracaowhatsapp.edit_vue_html.verComoFazer"
                          )
                        ) +
                        "\n                    "
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-sm-6" }, [
                  _c("img", { attrs: { src: _vm.qrCode } }),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-sm-12" }, [
                  _c("h4", [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "__.Crud.configuracaowhatsapp.edit_vue_html.cliqueBotaoAbaixo"
                        )
                      )
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-success",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            _vm.atualizarStatus()
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n                            " +
                            _vm._s(
                              _vm.$t(
                                "__.Crud.configuracaowhatsapp.edit_vue_html.atualizar"
                              )
                            ) +
                            "\n                        "
                        ),
                      ]
                    ),
                  ]),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.exibirMensagemSucesso
            ? _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-sm-12" }, [
                  _c("h2", [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "__.Crud.configuracaowhatsapp.edit_vue_html.integracaoProntaUso"
                        )
                      )
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-primary",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            _vm.definirWebhook()
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n                            " +
                            _vm._s(
                              _vm.$t(
                                "__.Crud.configuracaowhatsapp.edit_vue_html.definirWebhook"
                              )
                            ) +
                            "\n                        "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-danger",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            _vm.desconectarIntegracao()
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n                            " +
                            _vm._s(
                              _vm.$t(
                                "__.Crud.configuracaowhatsapp.edit_vue_html.desconectarIntegracao"
                              )
                            ) +
                            "\n                        "
                        ),
                      ]
                    ),
                  ]),
                ]),
              ])
            : _vm._e(),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }