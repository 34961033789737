var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "shortcutComponent",
    {
      ref: "shortCutComponent",
      attrs: {
        showAddNew: false,
        showBtnOk: false,
        modalClass: "lm-h-flex-modal",
      },
    },
    [
      _vm.loadUploadComponent
        ? _c("div", { attrs: { slot: "component" }, slot: "component" }, [
            _c(
              "form",
              { staticClass: "crud" },
              [
                !_vm.somenteConsultaVenda
                  ? _c(
                      "div",
                      {
                        staticClass: "row",
                        staticStyle: { "margin-top": "15px" },
                      },
                      [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: _vm.errors.first("descricao"),
                                expression: "errors.first('descricao')",
                              },
                            ],
                            staticClass: "col-sm-5",
                          },
                          [
                            _c(
                              "label",
                              {
                                staticClass: "control-label",
                                attrs: { for: "descricao" },
                              },
                              [
                                _c("span", { staticClass: "color-red" }, [
                                  _vm._v("*"),
                                ]),
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(
                                      _vm.$t(
                                        "__.Crud.produtolote.edit_vue_html.nomeArqAnexoLote"
                                      )
                                    ) +
                                    "\n                    "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.model.descricao,
                                  expression: "model.descricao",
                                },
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "required|max:256",
                                  expression: "'required|max:256'",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: {
                                type: "text",
                                id: "descricao",
                                name: "descricao",
                                autocomplete: "off",
                              },
                              domProps: { value: _vm.model.descricao },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.model,
                                    "descricao",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "col-sm-4" },
                          [
                            _c("p"),
                            _vm._v(" "),
                            _c("file-picker-component", {
                              attrs: { id: "file", name: "file" },
                              model: {
                                value: _vm.selectedFile,
                                callback: function ($$v) {
                                  _vm.selectedFile = $$v
                                },
                                expression: "selectedFile",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                !_vm.somenteConsultaVenda
                  ? _c("buttonIncluirComponent", {
                      on: { click: _vm.onInclusaoFile },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "row", staticStyle: { "margin-top": "15px" } },
                  [
                    _c("grid-component", {
                      attrs: {
                        data: _vm.gridData,
                        columns: _vm.gridColumns,
                        showTableHead: false,
                        "page-index": _vm.pageIndex,
                        "page-size": _vm.pageSize,
                        total: _vm.total,
                        "show-table-foot": false,
                        "show-action-column": true,
                        showEditItem: false,
                        "show-remove-item": true,
                        showAddNewButton: false,
                        extraActions: _vm.extraActions,
                      },
                      on: {
                        "change-filter-key": _vm.onChangeFilterKey,
                        "change-sort": _vm.onChangeSort,
                        "extra-action": _vm.onExtraActions,
                        "remove-item": _vm.onDelete,
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }