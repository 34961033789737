function editaCpf(cpf: number | string): string {
    let cpfEdited = String(cpf);
    cpfEdited = cpfEdited.replace(/\D/g, ""); //Remove tudo o que não é dígito
    cpfEdited = cpfEdited.replace(/(\d{3})(\d)/, "$1.$2"); //Coloca um ponto entre o terceiro e o quarto dígitos
    cpfEdited = cpfEdited.replace(/(\d{3})(\d)/, "$1.$2"); //Coloca um ponto entre o terceiro e o quarto dígitos
    cpfEdited = cpfEdited.replace(/(\d{3})(\d{1,2})$/, "$1-$2"); //Coloca um hífen entre o terceiro e o quarto dígitos
    return cpfEdited;
}

function editaCnpj(cnpj: number | string): string {
    let cnpjEdited = String(cnpj);
    cnpjEdited = cnpjEdited.replace(/\D/g, ""); //Remove tudo o que não é dígito
    cnpjEdited = cnpjEdited.replace(/^(\d{2})(\d)/, "$1.$2"); //Coloca ponto entre o segundo e o terceiro dígitos
    cnpjEdited = cnpjEdited.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3"); //Coloca ponto entre o quinto e o sexto dígitos
    cnpjEdited = cnpjEdited.replace(/\.(\d{3})(\d)/, ".$1/$2"); //Coloca uma barra entre o oitavo e o nono dígitos
    cnpjEdited = cnpjEdited.replace(/(\d{4})(\d)/, "$1-$2"); //Coloca um hífen depois do bloco de quatro dígitos
    return cnpjEdited;
}

function editaTelefone(telefone: string | number): string {
    let telEdited = String(telefone);
    telEdited = telEdited.replace(/\D/g, ""); // Remove tudo o que não é dígito

    if (telEdited.length === 11) {
        return telEdited.replace(/^(\d{2})(\d{5})(\d{4})$/, "($1) $2-$3"); // Formato com nono dígito
    } else if (telEdited.length === 10) {
        return telEdited.replace(/^(\d{2})(\d{4})(\d{4})$/, "($1) $2-$3"); // Formato sem nono dígito
    }

    return telEdited; // Retorna o número editado ou o original se não atende aos formatos esperados
}

export { editaCpf, editaCnpj, editaTelefone };
