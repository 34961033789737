import { GridColumn, GridColumnType } from "@/components/child/grid/gridColumn";
import { Component } from "@/decorators";
import ServicoModel from "@/models/servicoModel";
import ServicoService from "@/services/servicoService";

import ListComponentBase from "../listComponentBase";

@Component
export default class ServicoListComponent extends ListComponentBase<ServicoModel> {
    get gridColumns(): Array<GridColumn> {
        return [
            new GridColumn("atividade", "Atividade", GridColumnType.String),
            new GridColumn("descricao", "Descrição", GridColumnType.String),
            new GridColumn("aliquota", "Aliquota", GridColumnType.Percent, false, false, "", "", false, true, 4),
        ];
    }

    private beforeMount() {
        this.setProps(new ServicoService(), "servico", "descricao");
    }
}
