import checkboxComponent from "@/components/child/form/checkbox.vue";
import dataTooltipComponent from "@/components/child/form/datatooltip.vue";
import { Component } from "@/decorators";
import CondicaoModel from "@/models/paciente/condicaoModel";
import CondicaoService from "@/services/paciente/condicaoService";

import EditComponentBase from "../editComponentBase";

@Component({
    components: {
        checkboxComponent,
        dataTooltipComponent,
    },
})
export default class CondicaoEditComponent extends EditComponentBase<CondicaoModel> {
    private async beforeMount() {
        await this.setProps(new CondicaoService(), "condicao", new CondicaoModel());
    }
}
