var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("grid-component", {
    attrs: {
      data: _vm.gridData,
      columns: _vm.gridColumn,
      showEditItem: false,
      showRemoveItem: false,
      showActionColumn: false,
      showAddNewButton: false,
      showTableFoot: false,
      showTools: false,
      showTableHead: false,
      selectableLine: true,
    },
    on: { "selected-line": _vm.onSelectLote },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }