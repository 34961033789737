import LocalEntregaModel from "../../models/transporte/localEntregaModel";
import nfetch from "../../utils/events/nfetch";
import { IService } from "../base/iService";

export default class LocalEntregaService extends IService<LocalEntregaModel> {
    constructor() {
        super("LocalEntrega");
    }

    public getProximoCodigo(): Promise<Response> {
        return nfetch(`/${this.controllerName}/GetProximoCodigo`, {
            credentials: "same-origin",
        });
    }
}
