var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "crud crud-edit" },
    [
      _c("actionBarComponent", {
        attrs: {
          title: _vm.$t("__.Crud.compras.melhorCompra_vue_html.melhorCompra"),
          showSave: false,
        },
      }),
      _vm._v(" "),
      _c(
        "form",
        {
          attrs: { novalidate: "" },
          on: {
            submit: function ($event) {
              $event.preventDefault()
              _vm.save()
            },
          },
        },
        [
          _c("div", { attrs: { slot: "rows" }, slot: "rows" }, [
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col-sm-12" },
                [
                  _c("grid-component", {
                    ref: "gridcomponent",
                    attrs: {
                      data: _vm.gridData,
                      columns: _vm.gridColumns,
                      subColumns: _vm.gridSubColumnsitens,
                      showActionColumn: false,
                      subDataName: _vm.subDataName,
                      conditionShowSubData: _vm.conditionShowSubData,
                      showExpand: true,
                      showTableHead: false,
                      showTableFoot: false,
                      showRemoveItem: false,
                      showEditItem: false,
                    },
                    on: { "change-sort": _vm.onChangeSort },
                  }),
                ],
                1
              ),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }