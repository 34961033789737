var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "crud crud-edit" },
    [
      _c("actionBarComponent", {
        attrs: {
          title: _vm.$t("__.Crud.beneficio.edit_vue_html.beneficiosEdit"),
        },
        on: { onSave: _vm.save },
      }),
      _vm._v(" "),
      _c(
        "form",
        {
          attrs: { novalidate: "novalidate" },
          on: {
            submit: function ($event) {
              $event.preventDefault()
              _vm.save()
            },
          },
        },
        [
          _c("fieldset", [
            _c("div", { attrs: { slot: "rows" }, slot: "rows" }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-sm-12" }, [
                  _c("div", { staticClass: "form-group" }, [
                    _c(
                      "label",
                      {
                        staticClass: "control-label",
                        attrs: { for: "nome", title: _vm.$t("__.obrigatorio") },
                      },
                      [
                        _c("span", { staticStyle: { color: "red" } }, [
                          _vm._v("*"),
                        ]),
                        _vm._v(
                          "\n                                " +
                            _vm._s(
                              _vm.$t(
                                "__.Crud.beneficio.edit_vue_html.descricao"
                              )
                            ) +
                            "\n                            "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.model.descricao,
                          expression: "model.descricao",
                        },
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required|max:256",
                          expression: "'required|max:256'",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: {
                        type: "text",
                        id: "nome",
                        name: "nome",
                        autocomplete: "off",
                      },
                      domProps: { value: _vm.model.descricao },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.model, "descricao", $event.target.value)
                        },
                      },
                    }),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "row form-group" },
                [
                  _c(
                    "fieldset-component",
                    {
                      attrs: {
                        title: _vm.$t(
                          "__.Crud.beneficio.edit_vue_html.descontos"
                        ),
                        collapsed: false,
                      },
                    },
                    [
                      _c(
                        "div",
                        { attrs: { slot: "rows" }, slot: "rows" },
                        [
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col-sm-4" }, [
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "tooltip",
                                      rawName: "v-tooltip",
                                      value: _vm.errors.first("inicioFaixa"),
                                      expression: "errors.first('inicioFaixa')",
                                    },
                                  ],
                                  staticClass: "form-group",
                                },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "control-label",
                                      attrs: {
                                        for: "inicioFaixa",
                                        title: _vm.$t("__.obrigatorio"),
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticStyle: { color: "red" } },
                                        [_vm._v("*")]
                                      ),
                                      _vm._v(
                                        "\n                                            " +
                                          _vm._s(
                                            _vm.$t(
                                              "__.Crud.beneficio.edit_vue_html.inicioFaixa"
                                            )
                                          ) +
                                          "\n                                        "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("moeda-component", {
                                    staticClass: "form-control",
                                    attrs: {
                                      type: "text",
                                      id: "inicioFaixa",
                                      name: "inicioFaixa",
                                      autocomplete: "off",
                                    },
                                    model: {
                                      value: _vm.faixaDesconto.inicioFaixa,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.faixaDesconto,
                                          "inicioFaixa",
                                          $$v
                                        )
                                      },
                                      expression: "faixaDesconto.inicioFaixa",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "col-sm-4" }, [
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "tooltip",
                                      rawName: "v-tooltip",
                                      value: _vm.errors.first("fimFaixa"),
                                      expression: "errors.first('fimFaixa')",
                                    },
                                  ],
                                  staticClass: "form-group",
                                },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "control-label",
                                      attrs: {
                                        for: "fimFaixa",
                                        title: _vm.$t("__.obrigatorio"),
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticStyle: { color: "red" } },
                                        [_vm._v("*")]
                                      ),
                                      _vm._v(
                                        "\n                                            " +
                                          _vm._s(
                                            _vm.$t(
                                              "__.Crud.beneficio.edit_vue_html.fimFaixa"
                                            )
                                          ) +
                                          "\n                                        "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("moeda-component", {
                                    staticClass: "form-control",
                                    attrs: {
                                      autocomplete: "off",
                                      type: "text",
                                      id: "fimFaixa",
                                      name: "fimFaixa",
                                    },
                                    model: {
                                      value: _vm.faixaDesconto.fimFaixa,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.faixaDesconto,
                                          "fimFaixa",
                                          $$v
                                        )
                                      },
                                      expression: "faixaDesconto.fimFaixa",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "col-sm-4" }, [
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "tooltip",
                                      rawName: "v-tooltip",
                                      value: _vm.errors.first("valor"),
                                      expression: "errors.first('valor')",
                                    },
                                  ],
                                  staticClass: "form-group",
                                },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "control-label",
                                      attrs: {
                                        for: "valor",
                                        title: _vm.$t("__.obrigatorio"),
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticStyle: { color: "red" } },
                                        [_vm._v("*")]
                                      ),
                                      _vm._v(
                                        "\n                                            " +
                                          _vm._s(
                                            _vm.$t(
                                              "__.Crud.beneficio.edit_vue_html.valor"
                                            )
                                          ) +
                                          "\n                                        "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("decimal-com-sinal-custom-component", {
                                    staticClass: "form-control",
                                    attrs: {
                                      signal: "%",
                                      autocomplete: "off",
                                      type: "text",
                                      id: "valor",
                                      name: "valor",
                                    },
                                    model: {
                                      value: _vm.faixaDesconto.valor,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.faixaDesconto,
                                          "valor",
                                          $$v
                                        )
                                      },
                                      expression: "faixaDesconto.valor",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("buttonIncluirComponent", {
                            on: {
                              click: function ($event) {
                                _vm.onAddFaixa(0)
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c("grid-component", {
                            attrs: {
                              data: _vm.model.beneficioFaixas.filter(function (
                                x
                              ) {
                                return x.tipoBeneficio == 0
                              }),
                              columns: _vm.gridFaixaColums,
                              "show-action-column": true,
                              "show-table-head": false,
                              "show-table-foot": false,
                              "show-remove-item": true,
                              "show-edit-item": false,
                            },
                            on: { "remove-item": _vm.onRemoveFaixa },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "row form-group" },
                [
                  _c(
                    "fieldset-component",
                    {
                      attrs: {
                        title: _vm.$t(
                          "__.Crud.beneficio.edit_vue_html.comissao"
                        ),
                        collapsed: false,
                      },
                    },
                    [
                      _c(
                        "div",
                        { attrs: { slot: "rows" }, slot: "rows" },
                        [
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col-sm-4" }, [
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "tooltip",
                                      rawName: "v-tooltip",
                                      value: _vm.errors.first("inicioFaixa"),
                                      expression: "errors.first('inicioFaixa')",
                                    },
                                  ],
                                  staticClass: "form-group",
                                },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "control-label",
                                      attrs: {
                                        for: "inicioFaixa",
                                        title: _vm.$t("__.obrigatorio"),
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticStyle: { color: "red" } },
                                        [_vm._v("*")]
                                      ),
                                      _vm._v(
                                        "\n                                            " +
                                          _vm._s(
                                            _vm.$t(
                                              "__.Crud.beneficio.edit_vue_html.inicioFaixa"
                                            )
                                          ) +
                                          "\n                                        "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("moedaComponent", {
                                    staticClass: "form-control",
                                    attrs: {
                                      type: "text",
                                      id: "inicioFaixa",
                                      name: "inicioFaixa",
                                      autocomplete: "off",
                                    },
                                    model: {
                                      value: _vm.faixaComissao.inicioFaixa,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.faixaComissao,
                                          "inicioFaixa",
                                          $$v
                                        )
                                      },
                                      expression: "faixaComissao.inicioFaixa",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "col-sm-4" }, [
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "tooltip",
                                      rawName: "v-tooltip",
                                      value: _vm.errors.first("fimFaixa"),
                                      expression: "errors.first('fimFaixa')",
                                    },
                                  ],
                                  staticClass: "form-group",
                                },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "control-label",
                                      attrs: {
                                        for: "fimFaixa",
                                        title: _vm.$t("__.obrigatorio"),
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticStyle: { color: "red" } },
                                        [_vm._v("*")]
                                      ),
                                      _vm._v(
                                        "\n                                            " +
                                          _vm._s(
                                            _vm.$t(
                                              "__.Crud.beneficio.edit_vue_html.fimFaixa"
                                            )
                                          ) +
                                          "\n                                        "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("moedaComponent", {
                                    staticClass: "form-control",
                                    attrs: {
                                      autocomplete: "off",
                                      type: "text",
                                      id: "fimFaixa",
                                      name: "fimFaixa",
                                    },
                                    model: {
                                      value: _vm.faixaComissao.fimFaixa,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.faixaComissao,
                                          "fimFaixa",
                                          $$v
                                        )
                                      },
                                      expression: "faixaComissao.fimFaixa",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "col-sm-4" }, [
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "tooltip",
                                      rawName: "v-tooltip",
                                      value: _vm.errors.first("valor"),
                                      expression: "errors.first('valor')",
                                    },
                                  ],
                                  staticClass: "form-group",
                                },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "control-label",
                                      attrs: {
                                        for: "valor",
                                        title: _vm.$t("__.obrigatorio"),
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticStyle: { color: "red" } },
                                        [_vm._v("*")]
                                      ),
                                      _vm._v(
                                        "\n                                            " +
                                          _vm._s(
                                            _vm.$t(
                                              "__.Crud.beneficio.edit_vue_html.valor"
                                            )
                                          ) +
                                          "\n                                        "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("decimal-com-sinal-custom-component", {
                                    staticClass: "form-control",
                                    attrs: {
                                      signal: "%",
                                      autocomplete: "off",
                                      type: "text",
                                      id: "valor",
                                      name: "valor",
                                    },
                                    model: {
                                      value: _vm.faixaComissao.valor,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.faixaComissao,
                                          "valor",
                                          $$v
                                        )
                                      },
                                      expression: "faixaComissao.valor",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("buttonIncluirComponent", {
                            on: {
                              click: function ($event) {
                                _vm.onAddFaixa(1)
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c("grid-component", {
                            attrs: {
                              data: _vm.model.beneficioFaixas.filter(function (
                                x
                              ) {
                                return x.tipoBeneficio == 1
                              }),
                              columns: _vm.gridFaixaColums,
                              "show-action-column": true,
                              "show-table-head": false,
                              "show-table-foot": false,
                              "show-remove-item": true,
                              "show-edit-item": false,
                            },
                            on: { "remove-item": _vm.onRemoveFaixa },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("fieldset", [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-sm-12 newbtn" }, [
                _c("div", { staticClass: "form-group" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-success",
                      attrs: { type: "submit" },
                    },
                    [
                      _c("i", {
                        staticClass: "fa fa-save",
                        attrs: { "aria-hidden": "true" },
                      }),
                      _vm._v(
                        "\n                            " +
                          _vm._s(_vm.$t("__.Crud.salvar")) +
                          "\n                        "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-secondary",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          _vm.load()
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n                            " +
                          _vm._s(_vm.$t("__.Crud.limpar")) +
                          "\n                        "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-secondary",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          _vm.cancel()
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n                            " +
                          _vm._s(_vm.$t("__.Crud.voltar")) +
                          "\n                        "
                      ),
                    ]
                  ),
                ]),
              ]),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _c("loading-modal-component", { ref: "loadingModalComponent" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }