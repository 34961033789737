var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "fieldset-component",
    {
      attrs: {
        disabled: "disabled",
        title: _vm.$t(
          "__.Components.parent.importacaonfe.dadosgerais_vue_html.dadosGerais"
        ),
      },
    },
    [
      _c("div", { attrs: { slot: "rows" }, slot: "rows" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-sm-12" }, [
            _c("div", { staticClass: "form-group" }, [
              _c(
                "label",
                { staticClass: "control-label", attrs: { for: "chaveAcesso" } },
                [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        "__.Components.parent.importacaonfe.dadosgerais_vue_html.chaveAcess"
                      )
                    )
                  ),
                ]
              ),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.model.chaveAcesso,
                    expression: "model.chaveAcesso",
                  },
                ],
                staticClass: "form-control",
                attrs: { type: "text", id: "chaveAcesso", name: "chaveAcesso" },
                domProps: { value: _vm.model.chaveAcesso },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.model, "chaveAcesso", $event.target.value)
                  },
                },
              }),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-sm-4" }, [
            _c("div", { staticClass: "form-group" }, [
              _c(
                "label",
                { staticClass: "control-label", attrs: { for: "modelo" } },
                [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        "__.Components.parent.importacaonfe.dadosgerais_vue_html.modelo"
                      )
                    )
                  ),
                ]
              ),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.model.identificacao.modelo,
                    expression: "model.identificacao.modelo",
                  },
                ],
                staticClass: "form-control",
                attrs: { type: "text", id: "modelo", name: "modelo" },
                domProps: { value: _vm.model.identificacao.modelo },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.model.identificacao,
                      "modelo",
                      $event.target.value
                    )
                  },
                },
              }),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-sm-4" }, [
            _c("div", { staticClass: "form-group" }, [
              _c(
                "label",
                { staticClass: "control-label", attrs: { for: "serie" } },
                [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        "__.Components.parent.importacaonfe.dadosgerais_vue_html.serie"
                      )
                    )
                  ),
                ]
              ),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.model.identificacao.serie,
                    expression: "model.identificacao.serie",
                  },
                ],
                staticClass: "form-control",
                attrs: { type: "text", id: "serie", name: "serie" },
                domProps: { value: _vm.model.identificacao.serie },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.model.identificacao,
                      "serie",
                      $event.target.value
                    )
                  },
                },
              }),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-sm-4" }, [
            _c("div", { staticClass: "form-group" }, [
              _c(
                "label",
                { staticClass: "control-label", attrs: { for: "numero" } },
                [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        "__.Components.parent.importacaonfe.dadosgerais_vue_html.nro"
                      )
                    )
                  ),
                ]
              ),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.model.identificacao.numero,
                    expression: "model.identificacao.numero",
                  },
                ],
                staticClass: "form-control",
                attrs: { type: "text", id: "numero", name: "numero" },
                domProps: { value: _vm.model.identificacao.numero },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.model.identificacao,
                      "numero",
                      $event.target.value
                    )
                  },
                },
              }),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-sm-6" }, [
            _c("div", { staticClass: "form-group" }, [
              _c(
                "label",
                { staticClass: "control-label", attrs: { for: "versaoXml" } },
                [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        "__.Components.parent.importacaonfe.dadosgerais_vue_html.versaoXML"
                      )
                    )
                  ),
                ]
              ),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.model.versaoXml,
                    expression: "model.versaoXml",
                  },
                ],
                staticClass: "form-control",
                attrs: { type: "text", id: "versaoXml", name: "versaoXml" },
                domProps: { value: _vm.model.versaoXml },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.model, "versaoXml", $event.target.value)
                  },
                },
              }),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-sm-6" }, [
            _c("div", { staticClass: "form-group" }, [
              _c(
                "label",
                {
                  staticClass: "control-label",
                  attrs: { for: "valorTotalNfe" },
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        "__.Components.parent.importacaonfe.dadosgerais_vue_html.vlrTotalNF"
                      )
                    )
                  ),
                ]
              ),
              _vm._v(" "),
              _c("input", {
                directives: [
                  { name: "mask-moeda", rawName: "v-mask-moeda" },
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.model.totais.totalIcms.valorTotalNotaFiscal,
                    expression: "model.totais.totalIcms.valorTotalNotaFiscal",
                  },
                ],
                staticClass: "form-control",
                attrs: {
                  type: "text",
                  id: "valorTotalNfe",
                  name: "valorTotalNfe",
                },
                domProps: {
                  value: _vm.model.totais.totalIcms.valorTotalNotaFiscal,
                },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.model.totais.totalIcms,
                      "valorTotalNotaFiscal",
                      $event.target.value
                    )
                  },
                },
              }),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-sm-6" }, [
            _c(
              "div",
              { staticClass: "form-group" },
              [
                _c(
                  "label",
                  {
                    staticClass: "control-label",
                    attrs: { for: "dataEmissao" },
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "__.Components.parent.importacaonfe.dadosgerais_vue_html.dtEmissao"
                        )
                      )
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("date-time-picker-component", {
                  attrs: {
                    id: "dataEmissao",
                    name: "dataEmissao",
                    "pick-time": true,
                  },
                  model: {
                    value: _vm.model.identificacao.dataEmissao,
                    callback: function ($$v) {
                      _vm.$set(_vm.model.identificacao, "dataEmissao", $$v)
                    },
                    expression: "model.identificacao.dataEmissao",
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-sm-6" }, [
            _c(
              "div",
              { staticClass: "form-group" },
              [
                _c(
                  "label",
                  {
                    staticClass: "control-label",
                    attrs: { for: "dataEntradaSaidaMercadoria" },
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "__.Components.parent.importacaonfe.dadosgerais_vue_html.dtHrEntradaSaida"
                        )
                      )
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("date-time-picker-component", {
                  attrs: {
                    id: "dataEntradaSaidaMercadoria",
                    name: "dataEntradaSaidaMercadoria",
                    "pick-time": true,
                  },
                  model: {
                    value: _vm.model.identificacao.dataEntradaSaidaMercadoria,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.model.identificacao,
                        "dataEntradaSaidaMercadoria",
                        $$v
                      )
                    },
                    expression:
                      "model.identificacao.dataEntradaSaidaMercadoria",
                  },
                }),
              ],
              1
            ),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }