import Moment from "moment";

import VendaContinuoService from "@/services/vendaContinuoService";

import BaseModel from "./base/baseModel";

export default class VendaContinuoModel extends BaseModel {
    id: number = null;
    vendaId: number = null;
    vendaItemId: number = null;
    dataVencimento: Date = null;
    ativo: boolean = null;
    clienteNome: string = null;
    pacienteNome: string = null;
    valor: number = null;
    valorAtualizado: number = null;
    orcado: boolean = null;
    dataConclusaoVenda?: Date = null;
    dataInclusao: Date = null;
    dataValidade?: Date = null;

    vendaContinuoService = new VendaContinuoService();

    //funcoes
    adiar(dias: number) {
        return this.vendaContinuoService.Adiar(this, dias);
    }
    arquivar() {
        return this.vendaContinuoService.Arquivar(this);
    }
    gerarOrcamento() {
        return this.vendaContinuoService.GerarOrcamento(this);
    }
    getCelularClienteByVendaId(vendaId: number = null) {
        return this.vendaContinuoService.GetCelularClienteByVendaId(vendaId != null ? vendaId : this.vendaId);
    }
    getDiasSugestaoAdiar() {
        const dataComparar = this.dataConclusaoVenda != null ? this.dataConclusaoVenda : this.dataInclusao;

        const timeDiff = Moment(this.dataVencimento).diff(dataComparar);
        const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));

        return Math.abs(diffDays);
    }
    getMensagemWhats(produtos: string[], bichos: string[], valor: number, validade: Date) {
        let produtosStr = "";
        const tituloProduto = produtos.length <= 1 ? " Produto: " : " Produtos: ";

        for (let i = 0; i < produtos.length; i++) {
            if (i == 0) {
                produtosStr = produtos[i];
            } else if (i == produtos.length) {
                produtosStr += " e " + produtos[i];
            } else {
                produtosStr += ", " + produtos[i];
            }
        }

        let bichosStr = "";
        const tituloBichos = " /Paciente: ";

        for (let j = 0; j < bichos.length; j++) {
            if (j == 0) {
                bichosStr = bichos[j];
            } else if (j == bichos.length) {
                bichosStr += " e " + bichos[j];
            } else {
                bichosStr += ", " + bichos[j];
            }
        }

        const prefixValor = " /Valor do Orçamento: R$ *" + valor.toFixed(2).toString().replace(".", ",") + "* ";

        const dataStr = Moment(validade).format("DD/MM/YY").toString();
        const validadeStr = " /Válido Até: *" + dataStr + "*";

        return (
            " *Orçamento* - Olá, Tudo bem? Seu orçamento está pronto! Segue os dados para aprovação " +
            +tituloProduto +
            " *" +
            produtosStr +
            "* " +
            tituloBichos +
            bichosStr +
            prefixValor +
            validadeStr
        );
    }
}

export interface VendaContinuoParameters {
    dataInicial?: string;
    dataFinal?: string;
}
