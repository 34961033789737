var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-sm-12" }, [
        _c("table", { staticClass: "table table-striped ajusteVer" }, [
          _c("tbody", [
            _c("h3", [
              _vm._v(
                _vm._s(_vm.$t("__.Crud.produto.verLote_vue_html.dadosGerais"))
              ),
            ]),
            _vm._v(" "),
            _c("tr", [
              _c("th", { attrs: { scope: "row" } }, [
                _c("h4", [
                  _vm._v(
                    _vm._s(_vm.$t("__.Crud.produto.verLote_vue_html.descricao"))
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("td", [_c("h4", [_vm._v(_vm._s(_vm.model.descricao))])]),
            ]),
            _vm._v(" "),
            _c("tr", [
              _c("th", { attrs: { scope: "row" } }, [
                _c("h4", [
                  _vm._v(
                    _vm._s(
                      _vm.$t("__.Crud.produto.verLote_vue_html.fornecedor")
                    )
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("td", [_c("h4", [_vm._v(_vm._s(_vm.model.fornecedorNome))])]),
            ]),
            _vm._v(" "),
            _c("tr", [
              _c("th", { attrs: { scope: "row" } }, [
                _c("h4", [
                  _vm._v(
                    _vm._s(_vm.$t("__.Crud.produto.verLote_vue_html.produto"))
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("td", [
                _c("h4", [_vm._v(_vm._s(_vm.model.produtoDescricao))]),
              ]),
            ]),
            _vm._v(" "),
            _c("tr", [
              _c("th", { attrs: { scope: "row" } }, [
                _c("h4", [
                  _vm._v(
                    _vm._s(_vm.$t("__.Crud.produto.verLote_vue_html.qtdAtual"))
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("td", [_c("h4", [_vm._v(_vm._s(_vm.model.quantidadeAtual))])]),
            ]),
            _vm._v(" "),
            _c("tr", [
              _c("th", { attrs: { scope: "row" } }, [
                _c("h4", [
                  _vm._v(
                    _vm._s(
                      _vm.$t("__.Crud.produto.verLote_vue_html.dtFabricacao")
                    )
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("td", [
                _c("h4", [
                  _vm._v(_vm._s(_vm.getDateString(_vm.model.dataFabricacao))),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("tr", [
              _c("th", { attrs: { scope: "row" } }, [
                _c("h4", [
                  _vm._v(
                    _vm._s(
                      _vm.$t("__.Crud.produto.verLote_vue_html.dtValidade")
                    )
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("td", [
                _c("h4", [
                  _vm._v(_vm._s(_vm.getDateString(_vm.model.dataValidade))),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("tr", [
              _c("th", { attrs: { scope: "row" } }, [
                _c("h4", [
                  _vm._v(
                    _vm._s(
                      _vm.$t("__.Crud.produto.verLote_vue_html.ftrCorrecao")
                    )
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("td", [_c("h4", [_vm._v(_vm._s(_vm.model.fatorCorrecao))])]),
            ]),
            _vm._v(" "),
            _c("tr", [
              _c("th", { attrs: { scope: "row" } }, [
                _c("h4", [
                  _vm._v(
                    _vm._s(_vm.$t("__.Crud.produto.verLote_vue_html.densidade"))
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("td", [_c("h4", [_vm._v(_vm._s(_vm.model.densidade))])]),
            ]),
            _vm._v(" "),
            _c("tr", [
              _c("th", { attrs: { scope: "row" } }, [
                _c("h4", [
                  _vm._v(
                    _vm._s(_vm.$t("__.Crud.produto.verLote_vue_html.fatorUI"))
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("td", [_c("h4", [_vm._v(_vm._s(_vm.model.fatorUI))])]),
            ]),
            _vm._v(" "),
            _c("tr", [
              _c("th", { attrs: { scope: "row" } }, [
                _c("h4", [
                  _vm._v(
                    _vm._s(_vm.$t("__.Crud.produto.verLote_vue_html.fatorUFC"))
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("td", [_c("h4", [_vm._v(_vm._s(_vm.model.fatorUFC))])]),
            ]),
            _vm._v(" "),
            _c("tr", [
              _c("th", { attrs: { scope: "row" } }, [
                _c("h4", [
                  _vm._v(
                    _vm._s(_vm.$t("__.Crud.produto.verLote_vue_html.fatorUTR"))
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("td", [_c("h4", [_vm._v(_vm._s(_vm.model.fatorUTR))])]),
            ]),
            _vm._v(" "),
            _c("tr", [
              _c("th", { attrs: { scope: "row" } }, [
                _c("h4", [
                  _vm._v(
                    _vm._s(_vm.$t("__.Crud.produto.verLote_vue_html.vlrCusto"))
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("td", [
                _c("h4", [
                  _vm._v(_vm._s(_vm.getMoneyString(_vm.model.valorCusto))),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("tr", [
              _c("th", { attrs: { scope: "row" } }, [
                _c("h4", [
                  _vm._v(
                    _vm._s(_vm.$t("__.Crud.produto.verLote_vue_html.obs"))
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("td", [_c("h4", [_vm._v(_vm._s(_vm.model.observacao))])]),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "tbody",
            [
              _c("h3", [_vm._v("Movimentações de Estoque")]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [
                  _c("div", { staticClass: "col-sm-6" }, [
                    _c("h4", [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "__.Crud.produto.verLote_vue_html.dataMovimentacao"
                          )
                        )
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-6" }, [
                    _c("h4", [
                      _vm._v(
                        _vm._s(
                          _vm.$t("__.Crud.produto.verLote_vue_html.quantidade")
                        )
                      ),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c("td", [
                  _c("div", { staticClass: "col-sm-6" }, [
                    _c("h4", [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "__.Crud.produto.verLote_vue_html.valorUnitario"
                          )
                        )
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-6" }, [
                    _c("h4", [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "__.Crud.produto.verLote_vue_html.movimentacao"
                          )
                        )
                      ),
                    ]),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _vm._l(_vm.model.movimentacoesEntradaEstoque, function (item) {
                return _c("tr", { key: item.id }, [
                  _c("td", [
                    _c("div", { staticClass: "col-sm-7" }, [
                      _c("h4", [
                        _vm._v(_vm._s(_vm.getDateTimeString(item.dataCriacao))),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-sm-5" }, [
                      _c("h4", [
                        _vm._v(
                          _vm._s(
                            item.quantidade +
                              " " +
                              item.unidadeMedidaEstoqueDescricao
                          )
                        ),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("td", [
                    _c("div", { staticClass: "col-sm-6" }, [
                      _c("h4", [
                        _vm._v(_vm._s(_vm.getMoneyString(item.valorUnitario))),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-sm-6" }, [
                      _c("h4", [
                        _vm._v(
                          _vm._s(
                            _vm.$t("__.Crud.produto.verLote_vue_html.entrada")
                          )
                        ),
                      ]),
                    ]),
                  ]),
                ])
              }),
              _vm._v(" "),
              _vm._l(_vm.model.movimentacoesSaidaEstoque, function (itemSaida) {
                return _c("tr", { key: itemSaida.id }, [
                  _c("td", [
                    _c("div", { staticClass: "col-sm-7" }, [
                      _c("h4", [
                        _vm._v(
                          _vm._s(_vm.getDateTimeString(itemSaida.dataCriacao))
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-sm-5" }, [
                      _c("h4", [
                        _vm._v(
                          _vm._s(
                            itemSaida.quantidade +
                              " " +
                              itemSaida.unidadeMedidaEstoqueDescricao
                          )
                        ),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("td", [
                    _c("div", { staticClass: "col-sm-6" }, [
                      _c("h4", [
                        _vm._v(
                          _vm._s(_vm.getMoneyString(itemSaida.valorUnitario))
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-sm-6" }, [
                      _c("h4", [
                        _vm._v(
                          _vm._s(
                            _vm.$t("__.Crud.produto.verLote_vue_html.saida")
                          )
                        ),
                      ]),
                    ]),
                  ]),
                ])
              }),
            ],
            2
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }