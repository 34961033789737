import BaseModel from "../base/baseModel";

import EtapaPCPModel from "./etapaPCPModel";
import ManipulacaoOrdemPCPModel from "./manipulacaoOrdemPCPModel";
import MotivoEtapaPCPModel from "./motivoEtapaPCPModel";

export default class ManipulacaoOrdemEtapaPCPModel extends BaseModel {
    id?: number = null;
    manipulacaoOrdemPCPId?: number = null;
    manipulacaoOrdemPCP = new ManipulacaoOrdemPCPModel();
    etapaPCP = new EtapaPCPModel();
    etapaPCPId?: number = null;
    etapaPCPDescricao: string = null;
    usuarioInicioId?: number = null;
    usuarioInicioNome: string = null;
    dataInicio: Date = null;
    observacao: string = null;
    motivoEtapaPCP = new MotivoEtapaPCPModel();
    motivoEtapaPCPId?: number = null;
    motivoEtapaPCPDescricao: string = null;
    finalizado = false;
    usuarioFinalizacaoId?: number = null;
    usuarioFinalizacaoNome: string = null;
    dataFim: Date = null;
}
