function round(value: number, precision = 2): number {
    return parseFloat(Number(value).toFixed(precision));
}

function aplicarCalculoMarkup(
    tipoCalculoMarkup: TipoCalculo,
    valor: number,
    markup: number,
    markupEmPercentual = true,
): number {
    if (markupEmPercentual) {
        markup /= 100;
    }

    const somar = (custo, markup) => custo + custo * markup;
    const multiplicar = (custo, markup) => custo * markup;

    return tipoCalculoMarkup == TipoCalculo.SOMAR ? somar(valor, markup) : multiplicar(valor, markup);
}

enum TipoCalculo {
    SOMAR = 0,
    MULTIPLICAR = 1,
}

export default { round, aplicarCalculoMarkup, TipoCalculo };