import dateTimePickerComponent from "@/components/child/form/datetimepicker.vue";
import { GridAction } from "@/components/child/grid/gridAction";
import { GridColors, GridColumn, GridColumnType } from "@/components/child/grid/gridColumn";
import ShortcutComponent from "@/components/parent/shortcut/shortcut";
import shortcutComponent from "@/components/parent/shortcut/shortcut.vue";
import { Component, Prop } from "@/decorators";
import ClienteModel from "@/models/clienteModel";
import EnumExtensions from "@/models/enum/extensions/enumExtensions";
import generoPessoa from "@/models/enum/generosPessoa";
import tipoPessoa from "@/models/enum/tiposPessoa";
import ufs from "@/models/enum/ufs";
import PaginationModel from "@/models/paginationModel";
import ClienteService from "@/services/clienteService";
import { convertToDateInputString } from "@/utils/common/date";
import Delay from "@/utils/common/delay";

import ListComponentBase from "../listComponentBase";

import VendasClienteListComponent from "./listVendas";
import vendasClienteListComponent from "./listVendas.vue";
import PontosComponent from "./pontos";
import pontosComponent from "./pontos.vue";
import verComponent from "./ver.vue";

import "../crud.scss";

@Component({
    components: {
        vendasClienteListComponent,
        verComponent,
        shortcutComponent,
        pontosComponent,
        dateTimePickerComponent,
    },
})
export default class ClienteListComponent extends ListComponentBase<ClienteModel> {
    get gridColumns(): Array<GridColumn> {
        return [
            new GridColumn("id", "Código", GridColumnType.String, false, false, "", "", true),
            new GridColumn("nome", "Nome", GridColumnType.String, false, false, "", "", true),
            new GridColumn("cpf", "CPF", GridColumnType.String, false, false, "", "", true),
            new GridColumn("celular", "Celular", GridColumnType.String, false, false, "", "", true, false),
            new GridColumn("email", "E-mail", GridColumnType.String, false, false, "", "", true, false),
            new GridColumn("dataCriacao", "Data de Criação", GridColumnType.Date),
        ];
    }

    private beforeMount() {
        this.setProps(new ClienteService(), "cliente", "descricao");

        if (this.CalledByShortCut) {
            this.pageSize = 9;
        }
    }

    private vendasClienteListComponent: VendasClienteListComponent = null;
    private pontosComponent: PontosComponent = null;
    private shortcutComponent: ShortcutComponent = null;
    private isHidden = false;
    private clienteService = new ClienteService();
    modelVer: ClienteModel = null;
    extraActions: Array<object> = [];
    gridExtraActionsVendas: Array<GridAction> = [];
    gridExtraActionsVer: Array<GridAction> = [];
    gridExtraActionsPontos: Array<GridAction> = [];
    emissaoDe: Date = null;
    emissaoAte: Date = null;

    id = 0;
    showVer = false;

    @Prop({ type: Boolean, default: false }) CalledByShortCut: boolean;

    public async load() {
        try {
            this.pageIndex = this.gridFilterKey !== this.filterKey ? 1 : this.pageIndex;
            this.filterKey = this.gridFilterKey;

            const data = await this.clienteService
                .listCliente(
                    this.gridFilterKey,
                    this.gridSortKey,
                    this.gridSortOrder,
                    this.pags ? 1 : this.pageIndex,
                    this.pags ? 999999 : this.pageSize,
                    convertToDateInputString(this.emissaoDe),
                    convertToDateInputString(this.emissaoAte),
                )
                .withLoading()
                .resolveWithJSON<PaginationModel<ClienteModel>>();

            this.gridData = data.list;
            this.total = data.total;
            this.pageIndex = data.pageIndex;
            this.pageSize = data.pageSize;
            this.isHidden = false;

            //atualiza params no store
            if (data.total > 0) {
                this.SET_PARAMS({
                    routePath: this.$route.path,
                    filterKey: this.gridFilterKey,
                    sortKey: this.gridSortKey,
                    sortOrder: this.gridSortOrder,
                    pageIndex: this.pageIndex,
                    pageSize: this.pageSize,
                });
            }
            this.gridExtraActionsVendas.splice(0);
            this.gridExtraActionsVer.splice(0);
            this.gridExtraActionsPontos.splice(0);
            this.extraActions.splice(0);

            for (let i = 0; i < this.gridData.length; i++) {
                this.gridExtraActionsVendas.push(
                    new GridAction(
                        "visualizar-vendas",
                        this.$t("__.ts.visuVendasClient").toString(),
                        "fa fa-clipboard-list",
                        GridColors.GREEN,
                        true,
                    ),
                );
                this.gridExtraActionsVer.push(
                    new GridAction("ver", this.$t("__.ts.verClient").toString(), "fa fa-eye", GridColors.BLUE, true),
                );
                this.gridExtraActionsPontos.push(
                    new GridAction(
                        "pontos",
                        this.$t("__.ts.verPontos").toString(),
                        "fa fa-award",
                        GridColors.YELLOW,
                        true,
                    ),
                );
            }

            this.extraActions.push(this.gridExtraActionsPontos);
            this.extraActions.push(this.gridExtraActionsVer);
            this.extraActions.push(this.gridExtraActionsVendas);

            if (this.pags) {
                if (this.tipoDocGerar == 0) {
                    setTimeout(() => {
                        this.gridcomponent.gerarXls();
                    }, 2000);
                }

                if (this.tipoDocGerar == 1) {
                    setTimeout(() => {
                        this.gridcomponent.gerarPdf();
                    }, 2000);
                }

                if (this.tipoDocGerar == 2) {
                    setTimeout(() => {
                        this.gridcomponent.gerarImpressao();
                    }, 2000);
                }

                setTimeout(() => {
                    this.pags = false;
                }, 2000);
            }
        } catch {}
    }

    private onExtraAction(name: string, model: ClienteModel) {
        if (name.trim() == "visualizar-vendas") {
            this.onVendasCliente(model.id, model.nome ?? model.razaoSocial).withLoading();
        }
        if (name.trim() == "ver") {
            this.onVer(model);
        }
        if (name.trim() == "pontos") {
            this.pontosComponent = this.$refs.pontosComponent as PontosComponent;
            this.pontosComponent.load(model.cpf ?? model.cnpj);
        }
    }

    private async onVer(cliente: ClienteModel) {
        this.showVer = true;

        while (!this.$refs.shortcutComponent) await Delay(5);
        this.shortcutComponent = this.$refs.shortcutComponent as ShortcutComponent;

        this.shortcutComponent.title = this.$t("__.ts.cliente").toString();

        try {
            const data = await this.service.get(cliente.id).withLoading().resolveWithJSON<ClienteModel>();
            this.modelVer = data;
            this.id = this.modelVer.id;
            this.modelVer.generoDescricao = EnumExtensions.getNameByValue(generoPessoa, data.genero);
            this.modelVer.estadoDescricao = EnumExtensions.getNameByValue(ufs, data.estadoId);
            this.modelVer.tipoPessoaDescricao = EnumExtensions.getNameByValue(tipoPessoa, data.tipoPessoa);
            this.modelVer.ufExpedidorDescricao = EnumExtensions.getNameByValue(ufs, data.ufExpedidor);
        } catch {}

        this.shortcutComponent.show();
    }

    private async onVendasCliente(clienteId: number, nomeCliente: string) {
        while (!this.$refs.vendasClienteListComponent) await Delay(5);
        this.vendasClienteListComponent = this.$refs.vendasClienteListComponent as VendasClienteListComponent;
        this.vendasClienteListComponent.show(clienteId, nomeCliente);
    }

    private onConfirmList() {
        this.vendasClienteListComponent.onClose();
    }
}
