var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "modal" } }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.m_showModal,
            expression: "m_showModal",
          },
        ],
        staticClass: "modal-mask",
      },
      [
        _vm.m_showModal
          ? _c("span", {
              directives: [
                {
                  name: "shortkey",
                  rawName: "v-shortkey.once",
                  value: ["esc"],
                  expression: "['esc']",
                  modifiers: { once: true },
                },
                {
                  name: "shortkey",
                  rawName: "v-shortkey.focus",
                  value: true,
                  expression: "true",
                  modifiers: { focus: true },
                },
              ],
              on: { shortkey: _vm.onEscClick },
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.m_showModal
          ? _c("span", {
              directives: [
                {
                  name: "shortkey",
                  rawName: "v-shortkey.once",
                  value: ["alt", "enter"],
                  expression: "['alt', 'enter']",
                  modifiers: { once: true },
                },
                {
                  name: "shortkey",
                  rawName: "v-shortkey.focus",
                  value: true,
                  expression: "true",
                  modifiers: { focus: true },
                },
              ],
              on: { shortkey: _vm.onEnterClick },
            })
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "modal-wrapper", class: this.modalClass }, [
          _c("div", { staticClass: "modal-container" }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.showHeader,
                    expression: "showHeader",
                  },
                ],
                staticClass: "modal-header",
                style: "color:" + _vm.headerColor,
              },
              [
                _vm._t("header", [
                  _vm._v(
                    "\n                        " +
                      _vm._s(_vm.title) +
                      "\n                    "
                  ),
                ]),
                _vm._v(" "),
                _c("i", {
                  staticClass: "fa fa-times",
                  attrs: { "aria-hidden": "true" },
                  on: { click: _vm.hide },
                }),
              ],
              2
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.showBody,
                    expression: "showBody",
                  },
                ],
                staticClass: "modal-body",
              },
              [_vm._t("body")],
              2
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.showFooter,
                    expression: "showFooter",
                  },
                ],
                staticClass: "modal-footer newbtn",
              },
              [
                _vm._t("footer", [
                  _c(
                    "div",
                    { staticClass: "footer-right" },
                    [
                      _c("buttonScComponent", {
                        attrs: {
                          classButton: "btn btn-secondary",
                          buttonName: _vm.$t("__.Crud.fechar"),
                          shortKeyBt: _vm.m_showModal ? "r" : null,
                        },
                        on: { click: _vm.hide },
                      }),
                      _vm._v(" "),
                      _vm.showBtnOk
                        ? _c("buttonScComponent", {
                            attrs: {
                              classButton: "btn btn-primary",
                              buttonName: _vm.btOkName,
                              shortKeyBt: _vm.m_showModal
                                ? _vm.btOkShortKey
                                : null,
                            },
                            on: {
                              click: function ($event) {
                                _vm.$emit("btn-ok-click")
                              },
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm._l(
                        _vm.extraButtons,
                        function (buttonEntry, buttonIndex) {
                          return [
                            _vm.extraButtons[buttonIndex].enabled
                              ? _c("buttonScComponent", {
                                  key: buttonIndex,
                                  attrs: {
                                    title:
                                      _vm.extraButtons[buttonIndex].tooltipText,
                                    classButton: _vm.extraButtons[buttonIndex]
                                      .classe
                                      ? "btn " +
                                        _vm.extraButtons[buttonIndex].classe
                                      : "btn btn-secondary",
                                    buttonName:
                                      _vm.extraButtons[buttonIndex].name,
                                    shortKeyBt: _vm.m_showModal
                                      ? _vm.extraButtons[buttonIndex].shortKey
                                      : null,
                                  },
                                  on: {
                                    click: function ($event) {
                                      _vm.extraButtonClick(
                                        _vm.extraButtons[buttonIndex].name,
                                        buttonEntry
                                      )
                                    },
                                  },
                                })
                              : _vm._e(),
                          ]
                        }
                      ),
                    ],
                    2
                  ),
                ]),
                _vm._v(" "),
                _vm._t("footerRight"),
              ],
              2
            ),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }