import Vue from "vue";

import Grid from "@/components/child/grid/grid";
import gridComponent from "@/components/child/grid/grid.vue";
import { GridColumn, GridColumnType } from "@/components/child/grid/gridColumn";
import LoadingModalComponent from "@/components/child/loadingmodal/loadingmodal";
import loadingModalComponent from "@/components/child/loadingmodal/loadingmodal.vue";
import { Component, Watch } from "@/decorators";
import InutilizacaoNotaFiscalModel from "@/models/inutilizacaoNotaFiscalModel";
import PaginationModel from "@/models/paginationModel";
import InutilizacaoNotaFiscalService from "@/services/inutilizacaoNotaFiscalService";

import "../crud.scss";

@Component({
    components: {
        gridComponent,
        loadingModalComponent,
    },
})
export default class InutilizacaoNotaFiscalListComponent extends Vue {
    private service = new InutilizacaoNotaFiscalService();
    private loadingModalComponent: LoadingModalComponent = null;
    private gridcomponent: Grid = null;

    gridFilterKey = "";
    gridSortKey = "numeroInicial";
    gridSortOrder = "asc";
    gridData: Array<InutilizacaoNotaFiscalModel> = [];
    get gridColumns(): Array<GridColumn> {
        return [
            new GridColumn("dataSolicitacao", "Data", GridColumnType.Date),
            new GridColumn("serie", "Série", GridColumnType.String),
            new GridColumn("numeroInicial", "Número Inicial", GridColumnType.Integer),
            new GridColumn("numeroFinal", "Número Final", GridColumnType.Integer),
            new GridColumn("justificativa", "Justificativa", GridColumnType.String),
            new GridColumn("statusDescricao", "Status", GridColumnType.String),
        ];
    }

    pageIndex = 1;
    pageSize = 20;
    total = 0;

    pags = false;
    tipoDocGerar: number = null;

    private async load() {
        this.loadingModalComponent.showLoading();

        const data = await this.service
            .list(
                this.gridFilterKey,
                this.gridSortKey,
                this.gridSortOrder,
                this.pags ? 1 : this.pageIndex,
                this.pags ? 999999 : this.pageSize,
            )
            .then(r => r.json() as Promise<PaginationModel<InutilizacaoNotaFiscalModel>>);
        this.gridData = data.list;
        this.total = data.total;
        if (this.pags) {
            if (this.tipoDocGerar == 0) {
                setTimeout(() => {
                    this.gridcomponent.gerarXls();
                }, 2000);
            }

            if (this.tipoDocGerar == 1) {
                setTimeout(() => {
                    this.gridcomponent.gerarPdf();
                }, 2000);
            }

            if (this.tipoDocGerar == 2) {
                setTimeout(() => {
                    this.gridcomponent.gerarImpressao();
                }, 2000);
            }

            setTimeout(() => {
                this.pags = false;
            }, 2000);
        }
        this.loadingModalComponent.hide();
    }

    private onEditItem(model: InutilizacaoNotaFiscalModel) {
        this.$router.push("/inutilizacaoNotaFiscal-edicao/" + model.id);
    }

    private onCreateItem() {
        this.$router.push("/inutilizacaoNotaFiscal-edicao");
    }

    private onChangeFilterKey(filterKey: string) {
        this.gridFilterKey = filterKey;
        this.load();
    }

    private onChangeSort(sortKey: string, sortOrder: string) {
        this.gridSortKey = sortKey;
        this.gridSortOrder = sortOrder;
        this.load();
    }

    private onChangePage(pageIndex: number) {
        this.pageIndex = pageIndex;
        this.load();
    }

    @Watch("pags")
    private onPags() {
        this.load();
    }

    private mounted() {
        this.loadingModalComponent = this.$refs.loadingModalComponent as LoadingModalComponent;
        this.gridcomponent = this.$refs.gridcomponent as Grid;
        this.load();
    }
}
