import Vue from "vue";
import { mapGetters, mapState } from "vuex";

import actionBarComponent from "@/components/child/actionBar/actionBar.vue";
import comboComponent from "@/components/child/form/combo.vue";
import dataTooltipComponent from "@/components/child/form/datatooltip.vue";
import dateTimePickerComponent from "@/components/child/form/datetimepicker.vue";
import decimalComSinalCustomComponent from "@/components/child/form/decimalComSinalCustom.vue";
import fieldsetComponent from "@/components/child/form/fieldset.vue";
import moedaComponent from "@/components/child/form/moeda.vue";
import textareaComponent from "@/components/child/form/textarea.vue";
import gridComponent from "@/components/child/grid/grid.vue";
import { GridColumn, GridColumnType } from "@/components/child/grid/gridColumn";
import ImpressaoComponent from "@/components/child/impressao/impressaoComponent";
import impressaoComponent from "@/components/child/impressao/impressaoComponent.vue";
import infoBagdeComponent from "@/components/child/infoBagde/infoBagde.vue";
import logEntidadeComponent from "@/components/child/logEntidade/logEntidade.vue";
import UploadAnexoComponent from "@/components/child/uploadAnexo/uploadAnexo";
import uploadAnexoComponent from "@/components/child/uploadAnexo/uploadAnexo.vue";
import { Component, Prop } from "@/decorators";
import PreLoadPackModel from "@/models/auxiliar/preLoadPackModel";
import ConfiguracaoFranquiaModel from "@/models/configuracaoFranquia/configuracaoFranquiaModel";
import Configuracoes from "@/models/enum/configuracao/configuracoes";
import EnumExtensions from "@/models/enum/extensions/enumExtensions";
import OrigemProdutoLote from "@/models/enum/origemProdutoLote";
import StatusProdutoLote from "@/models/enum/statusProdutoLote";
import TiposMovimentacaoEstoque from "@/models/enum/tiposMovimentacaoEstoque";
import FornecedorModel from "@/models/fornecedorModel";
import MovimentacaoEstoqueItemModel from "@/models/movimentacaoEstoqueItemModel";
import PaginationModel from "@/models/paginationModel";
import ProdutoModel from "@/models/produto/produtoModel";
import ProdutoLoteModel from "@/models/produtoLoteModel";
import EstoqueComprometidoService from "@/services/estoqueComprometidoService";
import FornecedorService from "@/services/fornecedorService";
import IndicesConversoesUnidadeMedidasService from "@/services/indicesConversoesUnidadeMedidaService";
import MovimentacaoEstoqueService from "@/services/movimentacaoEstoqueService";
import PDFService from "@/services/pdf/PDFService";
import ProdutoService from "@/services/produto/produtoService";
import ProdutoLoteService from "@/services/produtoLoteService";
import { AppState, Getters, SessionActions } from "@/store/store";
import { sortArray } from "@/utils/common/array";
import { getFornecedorCombo, getProdutoCombo } from "@/utils/common/combo/combotext";
import { addDays, editDateWithTime } from "@/utils/common/date";
import Delay from "@/utils/common/delay";

import ShortcutComponent from "../../shortcut/shortcut";
import shortcutComponent from "../../shortcut/shortcut.vue";
import estoqueComprometidoComponent from "../../venda/estoqueComprometido.vue";

import DadosNotaEntradaLoteComponent from "./dadosNotaEntradaLote";
import dadosNotaEntradaLoteComponent from "./dadosNotaEntradaLote.vue";
import FracionamentoEditComponent from "./fracionamento";
import fracionamentoComponent from "./fracionamento.vue";

import "../crud.scss";

@Component({
    components: {
        textareaComponent,
        comboComponent,
        dateTimePickerComponent,
        moedaComponent,
        dataTooltipComponent,
        decimalComSinalCustomComponent,
        impressaoComponent,
        actionBarComponent,
        gridComponent,
        shortcutComponent,
        estoqueComprometidoComponent,
        fracionamentoComponent,
        fieldsetComponent,
        dadosNotaEntradaLoteComponent,
        logEntidadeComponent,
        infoBagdeComponent,
        uploadAnexoComponent,
    },
    computed: {
        ...mapState<AppState>({
            preLoadPack: state => state.preLoad.preLoadList,
            loadedList: state => state.preLoad.loadedList,
        }),
        ...mapGetters([
            "GET_IS_FRACIONAMENTO",
            "GET_CONFIG_FRANQUIA",
            "VALIDAR_PERMISSAO_USUARIO",
            "VALIDAR_PERMISSAO_SOMENTE_CONSULTA",
            "HAS_PERMISSAO_PERFIL",
        ] as Getters),
        infoBagdeText() {
            return "Data criação: " + editDateWithTime(this.model.dataCriacao);
        },
    },
})
export default class ProdutoLoteEditComponent extends Vue {
    // State computed props
    loadedList: boolean;
    preLoadPack: PreLoadPackModel;
    GET_IS_FRACIONAMENTO: () => Promise<boolean>;
    GET_CONFIG_FRANQUIA: (configuracao: Configuracoes) => ConfiguracaoFranquiaModel;
    HAS_PERMISSAO_PERFIL: (isRede: boolean, isFranqueador: boolean, isSuporte: boolean) => Promise<boolean>;
    VALIDAR_PERMISSAO_USUARIO: (telaDescricao: string, acaoController: string) => boolean;
    VALIDAR_PERMISSAO_SOMENTE_CONSULTA: (telaDescricao: string) => boolean;
    infoBagdeText: string;

    private service = new ProdutoLoteService();
    private ordemFracionamentoPDFService = new PDFService("OrdemFracionamentoPDF");
    private impressaoComponent: ImpressaoComponent = null;
    private shortcutComponent: ShortcutComponent = null;
    private shortCutUploadUploadAnexoLote: ShortcutComponent = null;
    private fracionamentoComponent: FracionamentoEditComponent = null;
    private dadosNotaEntradaLoteComponent: DadosNotaEntradaLoteComponent = null;
    private uploadAnexoComponent: UploadAnexoComponent = null;

    somenteConsulta = false;
    showFinanceiro = false;
    model = new ProdutoLoteModel();
    modelId = 0;
    statusProdutoLoteOptions = [];

    statusProdutoLote = StatusProdutoLote;
    origemProdutoLote = OrigemProdutoLote;
    origemProdutoLoteOptions = EnumExtensions.getNamesAndValuesOrderedByValues(OrigemProdutoLote);
    isFracionamento = false;
    isAdminOuSuporte = false;
    indicesConversoesUnidadeMedidasService = new IndicesConversoesUnidadeMedidasService();
    private showEstoqueComprometido = false;
    private movimentosEstoque = [];

    produtosOptions: Array<Object> = [];
    fornecedoresOptions: Array<Object> = [];

    private get gridColumns(): Array<GridColumn> {
        return [
            new GridColumn("dataCriacao", this.$t("__.ts.dataMovimentacao"), GridColumnType.Date),
            new GridColumn("quantidadeString", this.$t("__.ts.quantidade"), GridColumnType.String),
            new GridColumn("valorUnitario", this.$t("__.ts.valorUnitario"), GridColumnType.Money),
            new GridColumn("tiposMovimentacaoDescricao", this.$t("__.ts.tipoMovimentacao"), GridColumnType.String),
        ];
    }

    private get gridColumnsProdutoLotesFracionados(): Array<GridColumn> {
        return [
            new GridColumn("descricao", this.$t("__.ts.descricao"), GridColumnType.String),
            new GridColumn("quantidadeAtual", this.$t("__.ts.quantidade"), GridColumnType.Decimal),
            new GridColumn(
                "quatidadeFracionadaTotal",
                this.$t("__.ts.quantidadeEfetivaEstoque"),
                GridColumnType.String,
            ),
        ];
    }

    private get gridColumnsEstoqueProdutoLotes(): Array<GridColumn> {
        return [
            new GridColumn("estoqueDescricao", this.$t("__.ts.descricao"), GridColumnType.String),
            new GridColumn("estoqueAtualUnidadeMedida", this.$t("__.ts.quantidade"), GridColumnType.String),
        ];
    }

    @Prop({ type: Number, default: null }) IdProduto: number;
    @Prop({ type: Boolean, default: false }) CalledByShortCut: boolean;

    private diasValidadePadraoConfig = 1825;
    private hideExtras = false;
    private quantidadeComprometida = 0;
    public modelBasePreencherLote: ProdutoLoteModel = null;

    public async load() {
        if (this.modelBasePreencherLote != null) {
            this.model = this.modelBasePreencherLote;
        } else {
            this.model = new ProdutoLoteModel();
        }

        if (this.model.dataValidade == null) {
            this.model.dataValidade = addDays(this.diasValidadePadraoConfig, new Date());
        }

        this.model.dataFabricacao = new Date();

        this.statusProdutoLoteOptions = this.model.getStatusProdutoLoteOptions();

        if (this.modelId > 0) {
            try {
                const data = await this.service.get(this.modelId).withLoading().resolveWithJSON<ProdutoLoteModel>();

                if (data.produtoLotesFracionados) {
                    for await (const loteFracionado of data.produtoLotesFracionados) {
                        const indice = await this.indicesConversoesUnidadeMedidasService.getIndiceConversao(
                            loteFracionado.unidadeMedidaFracionadoId,
                            data.unidadeMedidaId,
                        );

                        const quantidadeEmEstoque =
                            loteFracionado.volumeFracionado * indice * loteFracionado.quantidadeAtual;
                        loteFracionado.quatidadeFracionadaTotal = `${quantidadeEmEstoque} ${data.unidadeMedidaSigla}`;
                    }
                }

                this.model.updateFrom(data);

                this.model.estoqueProdutoLote.forEach(
                    p => (p.estoqueAtualUnidadeMedida = `${p.estoqueAtual} ${this.model.unidadeMedidaSigla}`),
                );

                this.quantidadeComprometida = await new EstoqueComprometidoService()
                    .getQuantidadeComprometidaLote(
                        this.model.id,
                        null,
                        0,
                        this.model.origemProdutoLote == OrigemProdutoLote.Fracionamento,
                    )
                    .resolveWithJSON<number>();
            } catch {
                this.$router.back();
            }
        } else {
            if (this.IdProduto != null) {
                this.model.produtoId = this.IdProduto;
            }
        }

        this.modelBasePreencherLote = null;
    }

    public async loadProdutos() {
        try {
            const data = await new ProdutoService()
                .combo()
                .then(r => r.json() as Promise<PaginationModel<ProdutoModel>>);
            this.produtosOptions = data.list.map(getProdutoCombo);
        } catch {}
    }

    private async loadFornecedores() {
        try {
            const data = await new FornecedorService()
                .combo()
                .withLoading()
                .resolveWithJSON<PaginationModel<FornecedorModel>>();
            this.fornecedoresOptions = data.list.map(getFornecedorCombo);
        } catch {}
    }

    public async save(closeOnSucess = true) {
        try {
            if (await this.$validator.validateAll()) {
                const response = await this.service[!this.model.id ? "insert" : "update"](this.model)
                    .withLoading()
                    .resolveWithResponse();

                if (response) {
                    this.onSucessoSave(Number(response.headers.get("Id")));
                    await this.$showInclusaoUpdate(this.model.id);

                    if (closeOnSucess) {
                        if (this.CalledByShortCut) {
                            this.$emit("save-ok", this.model);
                        } else {
                            this.$router.back();
                        }
                    }
                    return true;
                }
            }
        } catch {}
        return false;
    }

    private async getConfigDias() {
        const config = this.GET_CONFIG_FRANQUIA(Configuracoes.DiasValidadePadraoLoteNaoInformadoXml);
        this.diasValidadePadraoConfig = config.valor != null ? config.valor : 1825;
    }

    private onloadLote() {
        this.$emit("on-load-lote");
    }

    private cancel() {
        this.$router.back();
    }

    private async getPreLoadPack() {
        while (!this.loadedList) await Delay(50);

        this.produtosOptions = this.preLoadPack.produtosCombo();
    }

    private async removeItem() {
        const response = await this.$showQuestion(
            `${this.$t("__.ts.desejaExcl")}${this.model.descricao}?`,
            this.$t("__.ts.acaoNaoRevert"),
        );

        if (response) {
            try {
                const sucesso = await this.service.delete(this.model.id).withLoading().resolveWithoutJSON();
                if (sucesso) {
                    this.$showExclusao();
                    this.$router.back();
                }
            } catch {}
        }
    }

    private imprimirRotulo() {
        this.impressaoComponent
            .show({
                modelId: this.model.id,
                tipoImpressao: "Lote",
            })
            .withLoading();
    }

    private onChangeSort(sortKey: keyof MovimentacaoEstoqueItemModel, sortOrder: "asc" | "desc") {
        sortArray(this.movimentosEstoque, sortKey, sortOrder);
    }

    private async onShowDadosNotaEntradaLote() {
        await this.dadosNotaEntradaLoteComponent.show(this.model);
    }

    private async onShowEstoqueComprometido() {
        this.showEstoqueComprometido = true;

        while (!this.$refs.shortcutComponent) await Delay(50);
        this.shortcutComponent = this.$refs.shortcutComponent as ShortcutComponent;

        this.shortcutComponent.show();
    }

    private async onShowUploadAnexo() {
        while (!this.$refs.uploadAnexoComponent) await Delay(50);
        this.uploadAnexoComponent = this.$refs.uploadAnexoComponent as UploadAnexoComponent;
        this.uploadAnexoComponent.show();
    }

    private async onSucessoSave(id?: number) {
        if (this.uploadAnexoComponent) {
            this.uploadAnexoComponent.onSucessoSave(id);
        }
    }

    private async onShowMovimentosEstoque(open: (slot: number, title: string, size: string) => {}) {
        open(1, this.$t("__.Crud.produtolote.edit_vue_html.movimentosEstoque") as string, "lg-modal");

        if (this.movimentosEstoque.length == 0) {
            try {
                const data = await new MovimentacaoEstoqueService()
                    .getMovimentacaoLote(this.model.id)
                    .withLoading()
                    .resolveWithJSON<MovimentacaoEstoqueItemModel[]>();

                this.movimentosEstoque = data.map(p => ({
                    ...p,
                    tiposMovimentacaoDescricao:
                        p.tiposMovimentacao == TiposMovimentacaoEstoque.Entrada
                            ? this.$t("__.ts.entrada")
                            : p.tiposMovimentacao == TiposMovimentacaoEstoque.Saida
                            ? this.$t("__.ts.saida")
                            : this.$t("__.ts.transferecia"),
                    quantidadeString: `${p.quantidade} ${p.unidadeMedidaEstoqueDescricao}`,
                }));
            } catch {}
        }
    }

    private async onFracionar() {
        await this.save(false);

        await this.fracionamentoComponent.show(this.model);
    }

    private async onRetornarLoteParaFracionamento() {
        try {
            const sucesso = await this.service
                .retornarLoteFracionamento(this.modelId)
                .withLoading()
                .resolveWithoutJSON();

            if (sucesso) {
                await this.$showSuccess(this.$t("__.ts.sucesso"), this.$t("__.ts.sucesso"));
                this.$router.back();
            }
        } catch {}
    }

    private async onImprimirOrdemFracionamento() {
        await this.ordemFracionamentoPDFService.geraPDF(this.model.id);
    }

    private validarVisibilidadeBotoes(botao: string): boolean {
        if (botao == "remover") {
            return this.VALIDAR_PERMISSAO_USUARIO("lotes", "delete") && this.model.id > 0;
        }
        if (botao == "anexarLote") {
            return this.VALIDAR_PERMISSAO_USUARIO("lotes", "insert") && this.model.id > 0;
        }
    }

    private async mounted() {
        this.hideExtras = this.CalledByShortCut ? true : false;
        this.impressaoComponent = this.$refs.impressaoComponent as ImpressaoComponent;
        this.fracionamentoComponent = this.$refs.fracionamentoComponent as FracionamentoEditComponent;
        this.dadosNotaEntradaLoteComponent = this.$refs.dadosNotaEntradaLoteComponent as DadosNotaEntradaLoteComponent;

        await this.getPreLoadPack();
        await this.loadFornecedores();
        if (this.$route.params.id && !this.hideExtras) {
            this.modelId = +this.$route.params.id;
        }

        this.isFracionamento = await this.GET_IS_FRACIONAMENTO();
        this.isAdminOuSuporte = await this.HAS_PERMISSAO_PERFIL(false, false, true);
        await this.$store.dispatch(SessionActions.LOAD_USUARIO_LOGADO);

        this.somenteConsulta = this.VALIDAR_PERMISSAO_SOMENTE_CONSULTA("lotes");
        this.showFinanceiro = this.VALIDAR_PERMISSAO_USUARIO("manipulacoes_venda", "ConferirCustosManipulacao");

        this.load();
    }
}
