import nfetch from "../utils/events/nfetch";

export default class CodigosIdentificacaoService {
    private controllerName = "CodigosIdentificacao";

    public getQrCode(toEncode: string): Promise<Response> {
        return nfetch(`/${this.controllerName}/GetQrCode`, {
            method: "post",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json; charset=UTF-8",
            },
            body: JSON.stringify(toEncode),
        });
    }

    public getBarCode(toEncode: string): Promise<Response> {
        return nfetch(`/${this.controllerName}/GetBarCode`, {
            method: "post",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json; charset=UTF-8",
            },
            body: JSON.stringify(toEncode),
        });
    }
}
