import { mapGetters, mapState } from "vuex";

import checkboxComponent from "@/components/child/form/checkbox.vue";
import comboComponent from "@/components/child/form/combo.vue";
import { GridAction } from "@/components/child/grid/gridAction";
import { GridColors, GridColumn, GridColumnType } from "@/components/child/grid/gridColumn";
import ImpressaoComponent from "@/components/child/impressao/impressaoComponent";
import impressaoComponent from "@/components/child/impressao/impressaoComponent.vue";
import { Component } from "@/decorators";
import PreLoadPackModel from "@/models/auxiliar/preLoadPackModel";
import EnumExtensions from "@/models/enum/extensions/enumExtensions";
import OrigemProdutoLote from "@/models/enum/origemProdutoLote";
import OrigemRotulo from "@/models/enum/rotulo/origemRotulo";
import StatusProdutoLote from "@/models/enum/statusProdutoLote";
import PaginationModel from "@/models/paginationModel";
import ProdutoLoteModel, { ProdutoLoteListParameters } from "@/models/produtoLoteModel";
import PDFService from "@/services/pdf/PDFService";
import ProdutoLoteService from "@/services/produtoLoteService";
import { AppState, Getters } from "@/store/store";
import { getEstoqueCombo } from "@/utils/common/combo/combotext";

import ListComponentBase from "../listComponentBase";

import AtualizarStatusEditComponent from "./atualizarStatus";
import atualizarStatusComponent from "./atualizarStatus.vue";
import DadosNotaEntradaLoteComponent from "./dadosNotaEntradaLote";
import dadosNotaEntradaLoteComponent from "./dadosNotaEntradaLote.vue";
import FracionamentoEditComponent from "./fracionamento";
import fracionamentoComponent from "./fracionamento.vue";

@Component({
    components: {
        comboComponent,
        impressaoComponent,
        atualizarStatusComponent,
        fracionamentoComponent,
        dadosNotaEntradaLoteComponent,
        checkboxComponent,
    },
    computed: {
        ...mapState({
            preLoadList: (state: AppState) => state.preLoad.preLoadList,
        }),
        ...mapGetters(["GET_IS_FRACIONAMENTO", "GET_IS_ESTEREIS"] as Getters),
    },
})
export default class ProdutoLoteListComponent extends ListComponentBase<ProdutoLoteModel> {
    // State computed props
    preLoadList: PreLoadPackModel;
    GET_IS_FRACIONAMENTO: () => Promise<boolean>;
    GET_IS_ESTEREIS: () => Promise<boolean>;

    private produtoLoteService = new ProdutoLoteService();
    private ordemFracionamentoPDFService = new PDFService("OrdemFracionamentoPDF");

    private impressaoComponent: ImpressaoComponent = null;
    private fracionamentoComponent: FracionamentoEditComponent = null;
    private atualizarStatusComponent: AtualizarStatusEditComponent = null;
    private dadosNotaEntradaLoteComponent: DadosNotaEntradaLoteComponent = null;

    isFracionamento = false;
    isEstereis = false;
    ignorarZerados = false;
    estoqueId?: number = null;

    isHidden = false;

    estoqueOptions: Array<object> = [];
    statusProdutoLote?: StatusProdutoLote = null;
    statusProdutoLoteOptions = new ProdutoLoteModel().getStatusProdutoLoteOptions();
    origemProdutoLote?: OrigemProdutoLote = null;
    origemProdutoLoteOptions = EnumExtensions.getNamesAndValuesOrderedByValues(OrigemProdutoLote);

    extraActions: Array<object> = [];
    gridExtraActionsNotaEntradaLote: Array<GridAction> = [];
    gridExtraActions: Array<GridAction> = [];
    gridExtraActionsStatusProdutoLote: Array<GridAction> = [];
    gridExtraActionsFracionamento: Array<GridAction> = [];
    gridExtraActionsImprimirOrdemFracionamento: Array<GridAction> = [];
    gridExtraActionsSecundFracionamento: Array<GridAction> = [];
    gridExtraActionsSecundPequenoFracionamento: Array<GridAction> = [];

    get gridColumns(): Array<GridColumn> {
        const columns = [];
        columns.push(new GridColumn("descricao", "Descrição", GridColumnType.String));
        columns.push(new GridColumn("produtoDescricao", "Produto", GridColumnType.String));
        columns.push(new GridColumn("fornecedorNome", "Fornecedor", GridColumnType.String));
        columns.push(new GridColumn("quantidadeAtual", "Quantidade", GridColumnType.DecimalPonto));
        columns.push(new GridColumn("unidadeMedidaSigla", "Un.Med", GridColumnType.String));
        columns.push(new GridColumn("dataValidade", "Validade", GridColumnType.Date));
        columns.push(new GridColumn("dataFabricacao", "Fabricação", GridColumnType.Date));
        if (this.isFracionamento) {
            columns.push(new GridColumn("statusProdutoLoteDescricao", "Status", GridColumnType.String));
        }
        return columns;
    }

    private beforeMount() {
        this.setProps(new ProdutoLoteService(), "produtolote", "descricao");
    }

    public async afterMounted() {
        this.impressaoComponent = this.$refs.impressaoComponent as ImpressaoComponent;
        this.atualizarStatusComponent = this.$refs.atualizarStatusComponent as AtualizarStatusEditComponent;
        this.fracionamentoComponent = this.$refs.fracionamentoComponent as FracionamentoEditComponent;
        this.dadosNotaEntradaLoteComponent = this.$refs.dadosNotaEntradaLoteComponent as DadosNotaEntradaLoteComponent;

        this.isFracionamento = await this.GET_IS_FRACIONAMENTO();
        this.isEstereis = await this.GET_IS_ESTEREIS();

        this.estoqueOptions = this.preLoadList.estoques.map(getEstoqueCombo);
    }

    public async loadMethod() {
        const customParameters: ProdutoLoteListParameters = {
            statusProdutoLote: this.filtros[0] != null ? Number(this.filtros[0]) : null,
            origemProdutoLote: this.filtros[1] != null ? Number(this.filtros[1]) : null,
            estoqueId: this.filtros[2] != null ? Number(this.filtros[2]) : null,
            ignorarZerados: this.filtros[3] != null ? Boolean(this.filtros[3]) : null,
        };

        return await this.produtoLoteService
            .listProdutoLote(
                this.gridFilterKey,
                this.gridSortKey,
                this.gridSortOrder,
                this.pags ? 1 : this.pageIndex,
                this.pags ? 999999 : this.pageSize,
                customParameters,
            )
            .withLoading()
            .resolveWithJSON<PaginationModel<ProdutoLoteModel>>();
    }

    public async afterLoad() {
        this.extraActions.splice(0);
        this.gridExtraActionsNotaEntradaLote.splice(0);
        this.gridExtraActions.splice(0);
        this.gridExtraActionsStatusProdutoLote.splice(0);
        this.gridExtraActionsFracionamento.splice(0);
        this.gridExtraActionsImprimirOrdemFracionamento.splice(0);
        this.gridExtraActionsSecundFracionamento.splice(0);
        this.gridExtraActionsSecundPequenoFracionamento.splice(0);

        for (let i = 0; i < this.gridData.length; i++) {
            this.gridExtraActionsNotaEntradaLote.push(
                new GridAction(
                    "nota-entrada-lote",
                    "Dados da Nota de Entrada do Lote",
                    "fa fa-file-invoice",
                    GridColors.YELLOW,
                ),
            );

            this.gridExtraActions.push(
                new GridAction("imprimir-rotulo", "Imprimir Rótulo", "fa fa-tag", GridColors.GREEN),
            );

            if (this.isFracionamento) {
                this.gridExtraActionsSecundFracionamento.push(
                    new GridAction(
                        "imprimir-rotulo-secundario",
                        "Imprimir rótulo secundário",
                        "fa fa-tag",
                        GridColors.GRAY,
                    ),
                );
                this.gridExtraActionsSecundPequenoFracionamento.push(
                    new GridAction(
                        "imprimir-rotulo-secundario-pequeno",
                        "Imprimir rótulo secundário pequeno",
                        "fa fa-tag",
                        GridColors.BLUE,
                    ),
                );

                const produtoLoteModel = new ProdutoLoteModel();
                produtoLoteModel.updateFrom(this.gridData[i]);

                this.gridExtraActionsStatusProdutoLote.push(
                    new GridAction(
                        "status-produto-lote",
                        "Atualizar status do lote",
                        "fa fa-sync",
                        GridColors.BLUE,
                        !produtoLoteModel.getNaoPermiteAlterarStatus(),
                    ),
                );

                this.gridExtraActionsImprimirOrdemFracionamento.push(
                    new GridAction(
                        "imprimir",
                        "Imprimir ordem de fracionamento",
                        "fa fa-print",
                        GridColors.DARKGRAY,
                        produtoLoteModel.getPermiteImprimirOrderFracionamento(),
                    ),
                );

                this.gridExtraActionsFracionamento.push(
                    new GridAction(
                        "fracionar",
                        "Fracionar",
                        "fa fa-box-open",
                        GridColors.ORANGE,
                        produtoLoteModel.getPermiteFracionar(),
                    ),
                );
            }
        }

        this.extraActions.push(this.gridExtraActionsStatusProdutoLote);
        this.extraActions.push(this.gridExtraActionsImprimirOrdemFracionamento);
        this.extraActions.push(this.gridExtraActionsFracionamento);
        this.extraActions.push(this.gridExtraActions);
        this.extraActions.push(this.gridExtraActionsSecundFracionamento);
        this.extraActions.push(this.gridExtraActionsSecundPequenoFracionamento);
        this.extraActions.push(this.gridExtraActionsNotaEntradaLote);
    }

    private async onExtraAction(name: string, model: ProdutoLoteModel) {
        if (name.trim() == "nota-entrada-lote") {
            await this.dadosNotaEntradaLoteComponent.show(model);
        }
        if (name.trim() == "imprimir-rotulo") {
            this.impressaoComponent
                .show({
                    modelId: model.id,
                    tipoImpressao: "Lote",
                })
                .withLoading();
        }
        if (name.trim() == "imprimir-rotulo-secundario") {
            this.impressaoComponent
                .show({
                    modelId: model.id,
                    tipoImpressao: "Lote",
                    origemRotulo: OrigemRotulo.Secundario,
                })
                .withLoading();
        }
        if (name.trim() == "imprimir-rotulo-secundario-pequeno") {
            this.impressaoComponent
                .show({
                    modelId: model.id,
                    tipoImpressao: "Lote",
                    origemRotulo: OrigemRotulo.SecundarioPequeno,
                })
                .withLoading();
        }
        if (name.trim() == "status-produto-lote") {
            await this.atualizarStatusComponent.show(model);
        }
        if (name.trim() == "imprimir") {
            await this.ordemFracionamentoPDFService.geraPDF(model.id);
        }
        if (name.trim() == "fracionar") {
            await this.fracionamentoComponent.show(model);
        }
    }

    private getFormValues() {
        this.filtros = [];
        this.filtros.push(this.statusProdutoLote);
        this.filtros.push(this.origemProdutoLote);
        this.filtros.push(this.estoqueId);
        this.filtros.push(this.ignorarZerados);
        this.pageIndex = 1;
        this.isHidden = false;
        this.load();
    }

    public async afterLoadPagination() {
        if (this.paginationParams.filtros) {
            this.statusProdutoLote =
                this.paginationParams.filtros[0] != null ? Number(this.paginationParams.filtros[0]) : null;
            this.origemProdutoLote =
                this.paginationParams.filtros[1] != null ? Number(this.paginationParams.filtros[1]) : null;
            this.estoqueId = this.paginationParams.filtros[2] != null ? Number(this.paginationParams.filtros[2]) : null;
            this.ignorarZerados =
                this.paginationParams.filtros[3] != null ? Boolean(this.paginationParams.filtros[3]) : null;
            this.getFormValues();
        }
    }
}
