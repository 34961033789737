import Vue from "vue";

import gridComponent from "@/components/child/grid/grid.vue";
import { GridColumn, GridColumnType } from "@/components/child/grid/gridColumn";
import { Component, Watch } from "@/decorators";
import ModeloImpressao from "@/models/modeloImpressao/modeloImpressaoModel";
import PaginationModel from "@/models/paginationModel";
import ModeloImpressaoService from "@/services/modeloImpressaoService";

import "../crud/crud.scss";

@Component({
    components: {
        gridComponent,
    },
})
export default class ModeloImpressaoListComponent extends Vue {
    private service = new ModeloImpressaoService();

    gridFilterKey = "";
    gridSortKey = "nome";
    gridSortOrder = "asc";
    gridData: Array<ModeloImpressao> = [];
    get gridColumns(): Array<GridColumn> {
        return [
            new GridColumn(
                "descricao",
                this.$t("__.ts.gridColumnDescricao") as string,
                GridColumnType.String,
                false,
                false,
                "",
                "",
                false,
                false,
            ),
            new GridColumn(
                "padrao",
                this.$t("__.ts.gridColumnPadrao") as string,
                GridColumnType.Boolean,
                false,
                false,
                "",
                "",
                false,
                false,
            ),
        ];
    }

    pageIndex = 1;
    pageSize = 20;
    total = 0;
    telaName: string = null;

    private onEditItem(model: ModeloImpressao) {
        this.$router.push(`/printModel-edicao/${this.telaName}/${model.id.toString()}`);
    }

    private async onRemoveItem(model: ModeloImpressao) {
        const response = await this.$showExclusaoQuestion(`${this.$t("__.ts.desejaExcl")}${model.descricao}?`);

        if (response) {
            try {
                const sucesso = await this.service.delete(model.id).withLoading().resolveWithoutJSON();
                if (sucesso) {
                    this.load();
                    this.$showExclusao();
                }
            } catch {}
        }
    }

    private onCreateItem() {
        this.$router.push(`/printModel-edicao/${this.telaName}`);
    }

    private onChangeFilterKey(filterKey: string) {
        this.gridFilterKey = filterKey;
        this.load();
    }

    private onChangeSort(sortKey: string, sortOrder: string) {
        this.gridSortKey = sortKey;
        this.gridSortOrder = sortOrder;
        this.load();
    }

    private onChangePage(pageIndex: number) {
        this.pageIndex = pageIndex;
        this.load();
    }

    //@ts-ignore
    @Watch("$route.params.tela")
    private onChangeParamTela() {
        this.load(this.$route.params.tela);
    }

    public async load(tela: string = null) {
        this.telaName = tela || this.$route.params.tela;

        try {
            const data = await this.service
                .GetByTelaName(this.telaName)
                .resolveWithJSON<PaginationModel<ModeloImpressao>>();

            this.gridData = data.list;
            this.total = data.total;
        } catch {}
    }

    private mounted() {
        this.load();
    }
}
