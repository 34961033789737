import StatusProdutoLote from "@/models/enum/statusProdutoLote";
import ProdutoLoteModel from "@/models/produtoLoteModel";
import { getCustomParams, getPaginationParams } from "@/utils/common/http";
import nfetch from "@/utils/events/nfetch";

import { IService } from "./base/iService";

export default class ProdutoLoteService extends IService<ProdutoLoteModel> {
    constructor() {
        super("ProdutoLote");
    }

    public listProdutoLote(
        filterKey: string,
        sortKey: string,
        sortOrder: string,
        pageIndex: number,
        pageSize: number,
        customProperties: { [key: string]: any } = {},
    ): Promise<Response> {
        const custom = getCustomParams(customProperties);
        const pagination = getPaginationParams(filterKey, sortKey, sortOrder, pageIndex, pageSize);

        return nfetch(`/${this.controllerName}/ListProdutoLote?${pagination}` + custom, {
            credentials: "same-origin",
        });
    }

    public listFilterProduto(
        produtoId: number,
        quantidade: number,
        unidadeMedidaId: number,
        pageIndex: number,
        pageSize: number,
    ): Promise<Response> {
        return nfetch(
            `/${this.controllerName}/ListByProdutoId?produtoId=${produtoId}&quantidade=${quantidade}&unidadeMedidaId=${unidadeMedidaId}&pageIndex=${pageIndex}&pageSize=${pageSize}`,
            {
                credentials: "same-origin",
            },
        );
    }

    public listByProdutoIdComQuantidade(
        produtoId: number,
        tipoOperacao: number,
        pageIndex: number,
        pageSize: number,
    ): Promise<Response> {
        return nfetch(
            `/${this.controllerName}/ListByProdutoIdComQuantidade?produtoId=${produtoId}&tipoOperacao=${tipoOperacao}&pageIndex=${pageIndex}&pageSize=${pageSize}`,
            {
                credentials: "same-origin",
            },
        );
    }

    public listByProdutoIdAndEstoqueId(
        produtoId: number,
        estoqueId: number,
        quantidade: number,
        unidadeMedidaId: number,
        pageIndex: number,
        pageSize: number,
    ): Promise<Response> {
        return nfetch(
            `/${this.controllerName}/ListByProdutoIdAndEstoqueId?produtoId=${produtoId}&estoqueId=${estoqueId}&quantidade=${quantidade}&unidadeMedidaId=${unidadeMedidaId}&pageIndex=${pageIndex}&pageSize=${pageSize}`,
            {
                credentials: "same-origin",
            },
        );
    }

    public listFracionamentoHome(statusProdutoLote: StatusProdutoLote): Promise<Response> {
        return nfetch(`/${this.controllerName}/ListFracionamentoHome?statusProdutoLote=${statusProdutoLote}`, {
            credentials: "same-origin",
        });
    }

    public totalFracionamentoHome(): Promise<Response> {
        return nfetch(`/${this.controllerName}/TotalFracionamentoHome`, {
            credentials: "same-origin",
        });
    }

    public getListDinamizado(produtoId: number): Promise<Response> {
        return nfetch(`/${this.controllerName}/GetListDinamizado?produtoId=${produtoId}`, {
            credentials: "same-origin",
        });
    }

    public verificaLoteEmFracionamento(id: number): Promise<Response> {
        return nfetch(`/${this.controllerName}/VerificaLoteEmFracionamento?id=${id}`, {
            credentials: "same-origin",
        });
    }

    public getByProdutoId(produtoId: number, estoqueId?: number): Promise<Response> {
        return nfetch(`/${this.controllerName}/GetByProduto?produtoId=${produtoId}&estoqueId=${estoqueId}`, {
            credentials: "same-origin",
        });
    }

    public getLoteEmissaoNota(id: number): Promise<Response> {
        return nfetch(`/${this.controllerName}/GetLoteEmissaoNota?id=${id}`, {
            credentials: "same-origin",
        });
    }

    public combo(estoqueId = 0): Promise<Response> {
        return nfetch(`/${this.controllerName}/ListCombo?estoqueId=${estoqueId}`, {
            credentials: "same-origin",
        });
    }

    public updateStatus(id: number, statusProdutoLote: StatusProdutoLote): Promise<Response> {
        return nfetch(`/${this.controllerName}/UpdateStatus?id=${id}&statusProdutoLote=${statusProdutoLote}`, {
            method: "post",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json; charset=UTF-8",
                "XSRF-TOKEN": this.getCookie("XSRF-TOKEN"),
            },
        });
    }

    public retornarLoteFracionamento(id: number): Promise<Response> {
        return nfetch(`/${this.controllerName}/RetornarLoteFracionamento?id=${id}`, {
            method: "post",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json; charset=UTF-8",
                "XSRF-TOKEN": this.getCookie("XSRF-TOKEN"),
            },
        });
    }

    public geraDescricaoLote(): Promise<Response> {
        return nfetch(`/${this.controllerName}/GeraDescricaoLote?`, {
            credentials: "same-origin",
        });
    }

    public insertProdutoDinamizado(model: ProdutoLoteModel): Promise<Response> {
        //@ts-ignore
        return nfetch(`/${this.controllerName}/InsertDinamizacao`, {
            method: "post",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json; charset=UTF-8",
            },
            body: JSON.stringify(model),
        });
    }
}
