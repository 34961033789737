import BaseModel from "./base/baseModel";
import SubGrupoProduto from "./enum/subGrupoProduto";
import TipoCalculoAssociacao from "./enum/tipoCalculoAssociacao";
import TiposCalculoManipulacao from "./enum/tiposCalculoManipulacao";
import TiposCapsula from "./enum/tiposCapsula";
import TiposItemManipulacao from "./enum/tiposItemManipulacao";
import FormulaPadraoModel from "./formulaPadraoModel";
import Produto from "./produto/produtoModel";
import ProdutoSinonimoModel from "./produto/produtoSinonimoModel";

export default class ManipulacaoOrdemItemModel extends BaseModel {
    id?: number = null;
    manipulacaoOrdemId?: number = null;
    observacao: string = null;
    produtoId = 0;
    produto: Produto = null;
    produtoDescricao: string = null;
    produtoRotulo: string = null;
    produtoLoteId?: number = null;
    produtoLoteDescricao: string = null;
    quantidade = 0;
    quantidadePesagem = 0;
    unidadeMedidaId = 0;
    unidadeMedidaDescricao: string = null;
    tipoItem: TiposItemManipulacao = TiposItemManipulacao.MateriaPrima;
    tipoCalculo?: TiposCalculoManipulacao = null;
    subGrupoItem?: SubGrupoProduto = null;

    formulaPadraoId?: number = null;
    formulaPadrao?: FormulaPadraoModel = null;
    produtoPai?: number = null;
    produtoListaControladoId?: number = null;
    valor = 0;
    quantidadeDose = 0;
    quantidadeDoseFormulaPadrao = 0;
    unidadeMedidaManipulacaoId: number = null;
    unidadeMedidaManipulacaoDecricao: string = null;
    fatorEquivalencia?: number = null;
    fatorCorrecao?: number = null;
    fatorUI?: number = null;
    valorTotal = 0;

    volumeDose?: number = null;
    unidadeMedidaDoseId?: number = null;
    unidadeMedidaDoseDecricao: string = null;

    kitEmbalagemId?: number = null;
    kitItemPadrao?: boolean = null;
    movimentaEstoque = true;

    //auxio da troca de item por maximo de diluicao
    produtoDiluidoId?: number = null;
    doseMaximaDiluido?: number = null;
    capsulaId?: number = null;
    tipoCapsula: TiposCapsula = null;
    buscouCorCapsula = false;
    produtoAssociadoPaiId?: number = null;

    densidade?: number = null;
    fatorUFC?: number = null;
    fatorUTR?: number = null;

    sinonimoId: number = null;
    sinonimo: ProdutoSinonimoModel = null;
    tipoCalculoAssociacao: TipoCalculoAssociacao = null;

    produtoLoteCalculosId: number = null;
    formaFarmaceuticaId?: number = null;
    // Campo utilizado para saber quais desmembrados pertencem ao item da manipulação, distinguindo em casos de mais de um desmembramento na mesma manipulação
    manipulacaoOrdemItemProdutoPaiId?: number = null;

    formulaPadraoItemId?: number = null;
    formulaPadraoItemQuantidadeDose?: number = null;

    isItemFromPreVenda = false;
}
