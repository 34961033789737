import Vue from "vue";

import ModalComponent from "@/components/child/modal/modal";
import modalComponent from "@/components/child/modal/modal.vue";
import imprimirRotuloPersonalizadoComponent from "@/components/parent/crud/rotulopersonalizado/imprimirRotulo.vue";
import { Component } from "@/decorators";
import PrinterService from "@/services/print/printerService";
import Delay from "@/utils/common/delay";

import ImprimirRotuloPersonalizadoComponent, { PrintOptionsType } from "../rotulopersonalizado/imprimirRotulo";

@Component({
    components: {
        modalComponent,
        imprimirRotuloPersonalizadoComponent,
    },
})
export default class ImprimirRotuloPersonalizadoComModalComponent extends Vue {
    private printerService = new PrinterService();

    private pageControl = {
        page: 0,
        totalPages: 0,
        rotulos: [],
    };

    private modalComponent: ModalComponent = null;
    private imprimirRotuloPersonalizadoComponent: ImprimirRotuloPersonalizadoComponent = null;

    public async showRotuloLote(
        id: number,
        { quantidadesLotes = [], vendaId = 0, origemRotulo = null, checkImprimir = true }: PrintOptionsType,
    ) {
        if (
            await this.imprimirRotuloPersonalizadoComponent
                .showRotuloLote(id, { quantidadesLotes, vendaId, origemRotulo, checkImprimir })
                .withLoading()
        ) {
            this.modalComponent.show();
        }
    }

    public async showRotuloManipulacao(
        id: number,
        { quantidadesLotes = [], vendaId = 0, origemRotulo = null, checkImprimir = true }: PrintOptionsType,
    ) {
        if (
            await this.imprimirRotuloPersonalizadoComponent
                .showRotuloManipulacao(id, { quantidadesLotes, vendaId, origemRotulo, checkImprimir })
                .withLoading()
        ) {
            this.modalComponent.show();
        }
    }

    public async show({
        quantidadesLotes = [],
        vendaId = 0,
        origemRotulo = null,
        checkImprimir = true,
    }: PrintOptionsType) {
        const rotulos = await this.imprimirRotuloPersonalizadoComponent
            .getRotulos({ quantidadesLotes, vendaId, origemRotulo, checkImprimir })
            .withLoading();

        if (rotulos) {
            this.resetPageControl();

            if (rotulos.length > 100) {
                this.pageControl.totalPages = Math.ceil(rotulos.length / 100);
                this.pageControl.rotulos = rotulos;

                await this.printPage();
            } else {
                await this.imprimirRotuloPersonalizadoComponent
                    .loadRotulosComponents(rotulos, { quantidadesLotes, vendaId, origemRotulo, checkImprimir })
                    .withLoading();

                this.modalComponent.show();
            }
        }
    }

    public addRotuloLoteByIds(ids: number[]) {
        return this.imprimirRotuloPersonalizadoComponent.addRotuloLoteByIds(ids);
    }

    public addRotuloLote(id: number) {
        return this.imprimirRotuloPersonalizadoComponent.addRotuloLote(id);
    }

    public addRotuloManipulacaoByIds(ids: number[]) {
        return this.imprimirRotuloPersonalizadoComponent.addRotuloManipulacaoByIds(ids);
    }

    public addRotuloManipulacao(id: number) {
        return this.imprimirRotuloPersonalizadoComponent.addRotuloManipulacao(id);
    }

    private async onClickImprimirRotuloPersonalizado() {
        const html = await this.imprimirRotuloPersonalizadoComponent.getHtmlPages().withLoading();

        try {
            await this.printerService.enqueuePages(html);
        } catch (e) {
            return this.$showError(this.$t("__.ts.erroNenhumaImpressoraDisponivel"), e.message);
        }

        if (await this.printerService.verifyErrors(html)) {
            return;
        }

        await this.printerService.flushAllQueues();

        this.modalComponent.hide();

        if (this.pageControl.totalPages > 0 && this.pageControl.page != this.pageControl.totalPages) {
            await this.printPage();
        }
    }

    private async printPage() {
        const sucesso = await this.$showQuestion(
            `Impressão de multiplos rótulos página ${this.pageControl.page + 1}/${this.pageControl.totalPages}`,
            "Deseja seguir com a impressão?",
        );
        if (sucesso) {
            this.pageControl.page++;
            const startIndex = (this.pageControl.page - 1) * 100;
            const lastIndex =
                this.pageControl.page * 100 > this.pageControl.rotulos.length
                    ? this.pageControl.rotulos.length
                    : this.pageControl.page * 100;

            const selectedRotuloes = this.pageControl.rotulos.slice(startIndex, lastIndex);

            this.imprimirRotuloPersonalizadoComponent.inicializaDados(true);

            await this.imprimirRotuloPersonalizadoComponent.loadRotulosComponents(selectedRotuloes, {}).withLoading();

            this.modalComponent.show();

            await Delay(1000);

            this.$forceUpdate();
        }
    }

    private resetPageControl() {
        this.pageControl = {
            page: 0,
            totalPages: 0,
            rotulos: [],
        };
    }

    private mounted() {
        this.modalComponent = this.$refs.modalComponent as ModalComponent;
        this.imprimirRotuloPersonalizadoComponent = this.$refs
            .imprimirRotuloPersonalizadoComponent as ImprimirRotuloPersonalizadoComponent;
    }
}
