import InventarioModel from "../models/inventarioModel";
import MovimentacaoEstoqueItemModel from "../models/movimentacaoEstoqueItemModel";
import MovimentacaoEstoqueModel from "../models/movimentacaoEstoqueModel";
import nfetch from "../utils/events/nfetch";

import { IService } from "./base/iService";

export default class InventarioService extends IService<InventarioModel> {
    constructor() {
        super("Inventario");
    }

    public insertInventario(model: InventarioModel): Promise<Response> {
        return nfetch(`/${this.controllerName}/Insert`, {
            method: "post",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json; charset=UTF-8",
                "XSRF-TOKEN": this.getCookie("XSRF-TOKEN"),
            },
            body: JSON.stringify(model),
        });
    }

    public insertAjusteLote(model: MovimentacaoEstoqueItemModel): Promise<Response> {
        return nfetch(`/${this.controllerName}/InsertAjusteLote`, {
            method: "post",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json; charset=UTF-8",
                "XSRF-TOKEN": this.getCookie("XSRF-TOKEN"),
            },
            body: JSON.stringify(model),
        });
    }

    public finalizarInventario(model: InventarioModel): Promise<Response> {
        return nfetch(`/${this.controllerName}/FinalizarInventario`, {
            method: "post",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json; charset=UTF-8",
                "XSRF-TOKEN": this.getCookie("XSRF-TOKEN"),
            },
            body: JSON.stringify(model),
        });
    }

    public cancelarInventario(model: InventarioModel): Promise<Response> {
        return nfetch(`/${this.controllerName}/CancelaInventario`, {
            method: "post",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json; charset=UTF-8",
                "XSRF-TOKEN": this.getCookie("XSRF-TOKEN"),
            },
            body: JSON.stringify(model),
        });
    }

    public getFinalizado(id: number): Promise<Response> {
        return nfetch(`/${this.controllerName}/GetFinalizado?inventarioId=${id}`, {
            credentials: "same-origin",
        });
    }

    public listProdutoLotes(ignorarLotesZerados = false, filtroGrupoProdutoId): Promise<Response> {
        return nfetch(
            `/${this.controllerName}/ListProdutoLote?ignorarLotesZerados=${ignorarLotesZerados}&filtroGrupoProdutoId=${filtroGrupoProdutoId}`,
            {
                credentials: "same-origin",
            },
        );
    }
}
