var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "col-sm-12" },
    [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-sm-6" }, [
          _c(
            "div",
            {
              directives: [
                {
                  name: "tooltip",
                  rawName: "v-tooltip",
                  value: _vm.errors.first("produtoId"),
                  expression: "errors.first('produtoId')",
                },
              ],
              staticClass: "form-group",
            },
            [
              _c(
                "label",
                {
                  staticClass: "control-label",
                  attrs: { for: "produtoId", title: _vm.$t("__.obrigatorio") },
                },
                [
                  _c("span", { staticClass: "color-red" }, [_vm._v("*")]),
                  _vm._v(
                    "\n                    " +
                      _vm._s(
                        _vm.$t(
                          "__.Components.parent.venda.vendaEstoque_vue_html.produto"
                        )
                      ) +
                      "\n                "
                  ),
                ]
              ),
              _vm._v(" "),
              _c("combo-component", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required",
                    expression: "'required'",
                  },
                ],
                ref: "produtoId",
                attrs: {
                  id: "produtoId",
                  name: "produtoId",
                  data: _vm.produtosOptions,
                  refresh: _vm.loadProdutosEstoque,
                  customView: true,
                },
                model: {
                  value: _vm.model.produtoId,
                  callback: function ($$v) {
                    _vm.$set(_vm.model, "produtoId", $$v)
                  },
                  expression: "model.produtoId",
                },
              }),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-sm-2" }, [
          _c("div", { staticClass: "form-group" }, [
            _c(
              "label",
              {
                staticClass: "control-label",
                attrs: {
                  for: "quantidadeComprometida",
                  title: _vm.$t(
                    "__.Components.parent.venda.vendaEstoque_vue_html.quantidadeComprometidaTitle"
                  ),
                },
              },
              [
                _vm._v(
                  "\n                    " +
                    _vm._s(
                      _vm.$t(
                        "__.Components.parent.venda.vendaEstoque_vue_html.quantidadeComprometida"
                      )
                    ) +
                    "\n                "
                ),
              ]
            ),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.quantidadeComprometidaProdutoString,
                  expression: "quantidadeComprometidaProdutoString",
                },
              ],
              staticClass: "form-control",
              attrs: {
                disabled: "",
                type: "text",
                id: "quantidadeComprometida",
                name: "quantidadeComprometida",
              },
              domProps: { value: _vm.quantidadeComprometidaProdutoString },
              on: {
                input: function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.quantidadeComprometidaProdutoString = $event.target.value
                },
              },
            }),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-sm-4" }, [
          _c(
            "div",
            {
              directives: [
                {
                  name: "tooltip",
                  rawName: "v-tooltip",
                  value: _vm.errors.first("produtoLoteId"),
                  expression: "errors.first('produtoLoteId')",
                },
              ],
              staticClass: "form-group",
            },
            [
              _c(
                "label",
                {
                  staticClass: "control-label",
                  attrs: {
                    for: "produtoLoteId",
                    title: _vm.$t("__.obrigatorio"),
                  },
                },
                [
                  _c("span", { staticClass: "color-red" }, [_vm._v("*")]),
                  _vm._v(
                    "\n                    " +
                      _vm._s(
                        _vm.$t(
                          "__.Components.parent.venda.vendaEstoque_vue_html.prodLote"
                        )
                      ) +
                      "\n                "
                  ),
                ]
              ),
              _vm._v(" "),
              _c("combo-component", {
                attrs: {
                  id: "produtoLoteId",
                  name: "produtoLoteId",
                  disabled: _vm.desativaCampos,
                  data: _vm.produtosLoteOptions,
                },
                model: {
                  value: _vm.itemFracionamento.produtoLote.id,
                  callback: function ($$v) {
                    _vm.$set(_vm.itemFracionamento.produtoLote, "id", $$v)
                  },
                  expression: "itemFracionamento.produtoLote.id",
                },
              }),
            ],
            1
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-sm-2" }, [
          _c(
            "div",
            {
              directives: [
                {
                  name: "tooltip",
                  rawName: "v-tooltip",
                  value: _vm.errors.first("quantidade"),
                  expression: "errors.first('quantidade')",
                },
              ],
              staticClass: "form-group",
            },
            [
              _c(
                "label",
                { staticClass: "control-label", attrs: { for: "quantidade" } },
                [
                  _c("span", { staticClass: "color-red" }, [_vm._v("*")]),
                  _vm._v(
                    "\n                    " +
                      _vm._s(
                        _vm.$t(
                          "__.Components.parent.venda.vendaEstoque_vue_html.qtd"
                        )
                      ) +
                      "\n                "
                  ),
                ]
              ),
              _vm._v(" "),
              _c("decimal-component", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required|maior_que_zero",
                    expression: "'required|maior_que_zero'",
                  },
                ],
                ref: "quantidade",
                staticClass: "form-control",
                attrs: {
                  id: "quantidade",
                  name: "quantidade",
                  disabled: _vm.desativaCampos,
                },
                model: {
                  value: _vm.itemFracionamento.quantidade,
                  callback: function ($$v) {
                    _vm.$set(_vm.itemFracionamento, "quantidade", $$v)
                  },
                  expression: "itemFracionamento.quantidade",
                },
              }),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-sm-1" }, [
          _c(
            "div",
            {
              directives: [
                {
                  name: "tooltip",
                  rawName: "v-tooltip",
                  value: _vm.errors.first("unidade"),
                  expression: "errors.first('unidade')",
                },
              ],
              staticClass: "form-group",
            },
            [
              _c(
                "label",
                { staticClass: "control-label", attrs: { for: "quantidade" } },
                [
                  _vm._v(
                    "\n                    " +
                      _vm._s(
                        _vm.$t(
                          "__.Components.parent.venda.vendaEstoque_vue_html.unidadeMed"
                        )
                      ) +
                      "\n                "
                  ),
                ]
              ),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.unidadeMedidaDescricao,
                    expression: "unidadeMedidaDescricao",
                  },
                ],
                ref: "unidade",
                staticClass: "form-control",
                attrs: {
                  type: "text",
                  id: "unidade",
                  name: "unidade",
                  disabled: "",
                },
                domProps: { value: _vm.unidadeMedidaDescricao },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.unidadeMedidaDescricao = $event.target.value
                  },
                },
              }),
            ]
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-sm-1" }, [
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c(
                "label",
                { staticClass: "control-label", attrs: { for: "markupVenda" } },
                [_vm._v("Markup")]
              ),
              _vm._v(" "),
              _c("decimal-com-sinal-custom-component", {
                staticClass: "form-control",
                attrs: {
                  id: "markupVenda",
                  name: "markupVenda",
                  signal: "%",
                  disabled: _vm.desativaCampos,
                },
                model: {
                  value: _vm.modelFracionamento.markupItemVenda,
                  callback: function ($$v) {
                    _vm.$set(_vm.modelFracionamento, "markupItemVenda", $$v)
                  },
                  expression: "modelFracionamento.markupItemVenda",
                },
              }),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-sm-2" }, [
          _c(
            "div",
            {
              directives: [
                {
                  name: "tooltip",
                  rawName: "v-tooltip",
                  value: _vm.errors.first("valorUnitario"),
                  expression: "errors.first('valorUnitario')",
                },
              ],
              staticClass: "form-group",
            },
            [
              _c(
                "label",
                {
                  staticClass: "control-label",
                  attrs: {
                    for: "valorUnitario",
                    title: _vm.$t("__.obrigatorio"),
                  },
                },
                [
                  _c("span", { staticClass: "color-red" }, [_vm._v("*")]),
                  _vm._v(
                    "\n                    " +
                      _vm._s(
                        _vm.$t(
                          "__.Components.parent.venda.vendaEstoque_vue_html.vlrUnit"
                        )
                      ) +
                      "\n                "
                  ),
                ]
              ),
              _vm._v(" "),
              _c("moeda-component", {
                directives: [
                  {
                    name: "mask-moeda-decimal",
                    rawName: "v-mask-moeda-decimal",
                  },
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required",
                    expression: "'required'",
                  },
                ],
                staticClass: "form-control",
                attrs: {
                  id: "valorUnitario",
                  disabled: "",
                  name: "valorUnitario",
                },
                model: {
                  value: _vm.itemFracionamento.valor,
                  callback: function ($$v) {
                    _vm.$set(_vm.itemFracionamento, "valor", $$v)
                  },
                  expression: "itemFracionamento.valor",
                },
              }),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-sm-1" }, [
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c(
                "label",
                {
                  staticClass: "control-label",
                  attrs: { for: "acrescimoPercentual" },
                },
                [_vm._v("Acréscimo %")]
              ),
              _vm._v(" "),
              _c("decimal-com-sinal-custom-component", {
                staticClass: "form-control",
                attrs: {
                  id: "acrescimoPercentual",
                  name: "acrescimoPercentual",
                  signal: "%",
                  disabled: _vm.desativaCampos,
                },
                model: {
                  value: _vm.modelFracionamento.acrescimoPercentual,
                  callback: function ($$v) {
                    _vm.$set(_vm.modelFracionamento, "acrescimoPercentual", $$v)
                  },
                  expression: "modelFracionamento.acrescimoPercentual",
                },
              }),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-sm-1" }, [
          _c(
            "div",
            {
              directives: [
                {
                  name: "tooltip",
                  rawName: "v-tooltip",
                  value: _vm.errors.first("acrescimo"),
                  expression: "errors.first('acrescimo')",
                },
              ],
              staticClass: "form-group",
            },
            [
              _c(
                "label",
                {
                  staticClass: "control-label",
                  attrs: { for: "acrescimo", title: _vm.$t("__.obrigatorio") },
                },
                [
                  _c("span", { staticClass: "color-red" }, [_vm._v("*")]),
                  _vm._v(
                    "\n                    " +
                      _vm._s(
                        _vm.$t(
                          "__.Components.parent.venda.vendaEstoque_vue_html.acrescimo"
                        )
                      ) +
                      "\n                "
                  ),
                ]
              ),
              _vm._v(" "),
              _c("moeda-component", {
                directives: [
                  {
                    name: "mask-moeda-decimal",
                    rawName: "v-mask-moeda-decimal",
                  },
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required",
                    expression: "'required'",
                  },
                ],
                staticClass: "form-control",
                attrs: {
                  id: "acrescimo",
                  disabled: _vm.desativaCampos,
                  name: "acrescimo",
                },
                model: {
                  value: _vm.modelFracionamento.acrescimo,
                  callback: function ($$v) {
                    _vm.$set(_vm.modelFracionamento, "acrescimo", $$v)
                  },
                  expression: "modelFracionamento.acrescimo",
                },
              }),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-sm-1" }, [
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c(
                "label",
                {
                  staticClass: "control-label",
                  attrs: { for: "descontoPercentual" },
                },
                [_vm._v("Desconto %")]
              ),
              _vm._v(" "),
              _c("decimal-com-sinal-custom-component", {
                staticClass: "form-control",
                attrs: {
                  id: "descontoPercentual",
                  name: "descontoPercentual",
                  signal: "%",
                  disabled: _vm.desativaCampos,
                },
                model: {
                  value: _vm.modelFracionamento.descontoPercentual,
                  callback: function ($$v) {
                    _vm.$set(_vm.modelFracionamento, "descontoPercentual", $$v)
                  },
                  expression: "modelFracionamento.descontoPercentual",
                },
              }),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-sm-1" }, [
          _c(
            "div",
            {
              directives: [
                {
                  name: "tooltip",
                  rawName: "v-tooltip",
                  value: _vm.errors.first("desconto"),
                  expression: "errors.first('desconto')",
                },
              ],
              staticClass: "form-group",
            },
            [
              _c(
                "label",
                {
                  staticClass: "control-label",
                  attrs: { for: "desconto", title: _vm.$t("__.obrigatorio") },
                },
                [
                  _c("span", { staticClass: "color-red" }, [_vm._v("*")]),
                  _vm._v(
                    "\n                    " +
                      _vm._s(
                        _vm.$t(
                          "__.Components.parent.venda.vendaEstoque_vue_html.desconto"
                        )
                      ) +
                      "\n                "
                  ),
                ]
              ),
              _vm._v(" "),
              _c("moeda-component", {
                directives: [
                  {
                    name: "mask-moeda-decimal",
                    rawName: "v-mask-moeda-decimal",
                  },
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required",
                    expression: "'required'",
                  },
                ],
                staticClass: "form-control",
                attrs: {
                  id: "desconto",
                  name: "desconto",
                  disabled: _vm.desativaCampos,
                },
                model: {
                  value: _vm.modelFracionamento.desconto,
                  callback: function ($$v) {
                    _vm.$set(_vm.modelFracionamento, "desconto", $$v)
                  },
                  expression: "modelFracionamento.desconto",
                },
              }),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-sm-2" }, [
          _c(
            "div",
            {
              directives: [
                {
                  name: "tooltip",
                  rawName: "v-tooltip",
                  value: _vm.errors.first("valorTotal"),
                  expression: "errors.first('valorTotal')",
                },
              ],
              staticClass: "form-group",
            },
            [
              _c(
                "label",
                {
                  staticClass: "control-label",
                  attrs: { for: "valorTotal", title: _vm.$t("__.obrigatorio") },
                },
                [
                  _c("span", { staticClass: "color-red" }, [_vm._v("*")]),
                  _vm._v(
                    "\n                    " +
                      _vm._s(
                        _vm.$t(
                          "__.Components.parent.venda.vendaEstoque_vue_html.vlrTotal"
                        )
                      ) +
                      "\n                "
                  ),
                ]
              ),
              _vm._v(" "),
              _c("moeda-component", {
                directives: [
                  {
                    name: "mask-moeda-decimal",
                    rawName: "v-mask-moeda-decimal",
                  },
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required",
                    expression: "'required'",
                  },
                ],
                staticClass: "form-control",
                attrs: {
                  id: "valorTotal",
                  disabled: _vm.desativaCampos,
                  name: "valorTotal",
                },
                on: { input: _vm.onBlurValorTotal },
                model: {
                  value: _vm.itemFracionamento.valorTotal,
                  callback: function ($$v) {
                    _vm.$set(_vm.itemFracionamento, "valorTotal", $$v)
                  },
                  expression: "itemFracionamento.valorTotal",
                },
              }),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.observacaoArmazenamento,
                expression: "observacaoArmazenamento",
              },
            ],
            staticClass: "col-sm-6",
          },
          [
            _c("div", { staticClass: "form-group" }, [
              _c(
                "label",
                {
                  staticClass: "control-label",
                  attrs: { for: "observacaoArmazenamento" },
                },
                [
                  _vm._v(
                    "\n                    " +
                      _vm._s(
                        _vm.$t(
                          "__.Components.parent.venda.vendaEstoque_vue_html.observacaoArmazenamento"
                        )
                      ) +
                      "\n                "
                  ),
                ]
              ),
              _vm._v(" "),
              _c("textarea", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.observacaoArmazenamento,
                    expression: "observacaoArmazenamento",
                  },
                ],
                staticClass: "form-control",
                attrs: {
                  id: "observacaoArmazenamento",
                  name: "observacaoArmazenamento",
                  disabled: true,
                },
                domProps: { value: _vm.observacaoArmazenamento },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.observacaoArmazenamento = $event.target.value
                  },
                },
              }),
            ]),
          ]
        ),
      ]),
      _vm._v(" "),
      _c(
        "fieldset-component",
        {
          attrs: {
            title: _vm.$t(
              "__.Components.parent.venda.vendaEstoque_vue_html.infoLote"
            ),
            collapsed: false,
          },
        },
        [
          _c("div", { attrs: { slot: "rows" }, slot: "rows" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-sm-3" }, [
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c("label", { staticClass: "control-label" }, [
                      _vm._v(
                        "\n                            " +
                          _vm._s(
                            _vm.$t(
                              "__.Components.parent.venda.vendaEstoque_vue_html.dataVal"
                            )
                          ) +
                          "\n                        "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("date-time-picker-component", {
                      attrs: { name: "dataValidade", disabled: "" },
                      model: {
                        value: _vm.itemFracionamento.produtoLote.dataValidade,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.itemFracionamento.produtoLote,
                            "dataValidade",
                            $$v
                          )
                        },
                        expression:
                          "itemFracionamento.produtoLote.dataValidade",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-sm-2" }, [
                _c("div", { staticClass: "form-group" }, [
                  _c(
                    "label",
                    {
                      staticClass: "control-label",
                      attrs: { for: "quantidadeAtual" },
                    },
                    [
                      _vm._v(
                        "\n                            " +
                          _vm._s(
                            _vm.$t(
                              "__.Components.parent.venda.vendaEstoque_vue_html.qtdEstoque"
                            )
                          ) +
                          "\n                        "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.quantidadeEstoque,
                        expression: "quantidadeEstoque",
                      },
                      { name: "mask-decimal", rawName: "v-mask-decimal" },
                    ],
                    staticClass: "form-control",
                    attrs: {
                      id: "quantidadeAtual",
                      name: "quantidadeAtual",
                      autocomplete: "off",
                      type: "text",
                      disabled: "",
                    },
                    domProps: { value: _vm.quantidadeEstoque },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.quantidadeEstoque = $event.target.value
                      },
                    },
                  }),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-sm-2" }, [
                _c(
                  "div",
                  { staticClass: "col-sm-10", staticStyle: { padding: "0" } },
                  [
                    _c("div", { staticClass: "form-group" }, [
                      _c(
                        "label",
                        {
                          staticClass: "control-label",
                          attrs: {
                            for: "quantidadeComprometidaLote",
                            title: _vm.$t(
                              "__.Components.parent.venda.vendaEstoque_vue_html.quantidadeComprometidaLoteTitle"
                            ),
                          },
                        },
                        [
                          _vm._v(
                            "\n                                " +
                              _vm._s(
                                _vm.$t(
                                  "__.Components.parent.venda.vendaEstoque_vue_html.quantidadeComprometidaLote"
                                )
                              ) +
                              "\n                            "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.quantidadeComprometidaLoteString,
                            expression: "quantidadeComprometidaLoteString",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          id: "quantidadeComprometidaLote",
                          name: "quantidadeComprometidaLote",
                          autocomplete: "off",
                          type: "text",
                          disabled: "",
                        },
                        domProps: {
                          value: _vm.quantidadeComprometidaLoteString,
                        },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.quantidadeComprometidaLoteString =
                              $event.target.value
                          },
                        },
                      }),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "col-sm-2 input-with-icon" }, [
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", { staticClass: "control-label" }),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        staticClass:
                          "input-group-addon form-control input-with-icon-wrapper",
                        attrs: { title: "Vendas com estoque comprometido" },
                        on: { click: _vm.onShowEstoqueComprometido },
                      },
                      [_c("span", { staticClass: "fa fa-list" })]
                    ),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-sm-2" }, [
                _c("div", { staticClass: "form-group" }, [
                  _c(
                    "label",
                    {
                      staticClass: "control-label",
                      attrs: { for: "quantidadeDisponivel" },
                    },
                    [
                      _vm._v(
                        "\n                            " +
                          _vm._s(
                            _vm.$t(
                              "__.Components.parent.venda.vendaEstoque_vue_html.quantidadeDisponivel"
                            )
                          ) +
                          "\n                        "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.quantidadeDisponivelString,
                        expression: "quantidadeDisponivelString",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: {
                      id: "quantidadeDisponivel",
                      name: "quantidadeDisponivel",
                      autocomplete: "off",
                      type: "text",
                      disabled: "",
                    },
                    domProps: { value: _vm.quantidadeDisponivelString },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.quantidadeDisponivelString = $event.target.value
                      },
                    },
                  }),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-sm-3" }, [
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c("label", { staticClass: "control-label" }, [
                      _vm._v(
                        "\n                            " +
                          _vm._s(
                            _vm.$t(
                              "__.Components.parent.venda.vendaEstoque_vue_html.valorCusto"
                            )
                          ) +
                          "\n                        "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("moeda-component", {
                      staticClass: "form-control",
                      attrs: { name: "valorUnitario", disabled: "" },
                      model: {
                        value: _vm.itemFracionamento.produtoLote.valorCusto,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.itemFracionamento.produtoLote,
                            "valorCusto",
                            $$v
                          )
                        },
                        expression: "itemFracionamento.produtoLote.valorCusto",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "fieldset-component",
        {
          attrs: {
            title: _vm.$t(
              "__.Components.parent.venda.vendaEstoque_vue_html.embalagemProd"
            ),
            collapsed: false,
          },
        },
        [
          _c("div", { attrs: { slot: "rows" }, slot: "rows" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-sm-9" }, [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip",
                        value: _vm.errors.first("kitEmbalagem"),
                        expression: "errors.first('kitEmbalagem')",
                      },
                    ],
                    staticClass: "form-group",
                  },
                  [
                    _c(
                      "label",
                      {
                        staticClass: "control-label",
                        attrs: { for: "kitEmbalagem" },
                      },
                      [
                        _vm._v(
                          "\n                            " +
                            _vm._s(
                              _vm.$t(
                                "__.Components.parent.venda.vendaEstoque_vue_html.kitEmbalagem"
                              )
                            ) +
                            "\n                        "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("combo-component", {
                      attrs: {
                        id: "kitEmbalagem",
                        name: "kitEmbalagem",
                        data: _vm.kitEmbalagensOptions,
                        disabled: _vm.desativaCampos,
                      },
                      model: {
                        value: _vm.modelFracionamento.kitEmbalagemId,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.modelFracionamento,
                            "kitEmbalagemId",
                            $$v
                          )
                        },
                        expression: "modelFracionamento.kitEmbalagemId",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.modelFracionamento.kitEmbalagemId != null,
                      expression: "modelFracionamento.kitEmbalagemId != null",
                    },
                  ],
                  staticClass: "col-sm-1",
                },
                [
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c(
                        "label",
                        {
                          staticClass: "control-label",
                          attrs: { for: "considerarEmbalagem" },
                        },
                        [
                          _vm._v(
                            "\n                            " +
                              _vm._s(
                                _vm.$t(
                                  "__.Components.parent.venda.vendaEstoque_vue_html.considerarEmbalagem"
                                )
                              ) +
                              "\n                        "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("checkbox-component", {
                        attrs: {
                          title:
                            "Considerar valor do kit de embalagem no fracionamento",
                          id: "considerarEmbalagem",
                          name: "considerarEmbalagem",
                          disabled: _vm.desativaCampos,
                        },
                        model: {
                          value: _vm.modelFracionamento.considerarEmbalagem,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.modelFracionamento,
                              "considerarEmbalagem",
                              $$v
                            )
                          },
                          expression: "modelFracionamento.considerarEmbalagem",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.modelFracionamento.considerarEmbalagem,
                      expression: "modelFracionamento.considerarEmbalagem",
                    },
                  ],
                  staticClass: "col-sm-2",
                },
                [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "tooltip",
                          rawName: "v-tooltip",
                          value: _vm.errors.first("valorTotal"),
                          expression: "errors.first('valorTotal')",
                        },
                      ],
                      staticClass: "form-group",
                    },
                    [
                      _c(
                        "label",
                        {
                          staticClass: "control-label",
                          attrs: { for: "valorEmbalagem" },
                        },
                        [
                          _vm._v(
                            "\n                            " +
                              _vm._s(
                                _vm.$t(
                                  "__.Components.parent.venda.vendaEstoque_vue_html.valorEmbalagem"
                                )
                              ) +
                              "\n                        "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("moeda-component", {
                        directives: [
                          {
                            name: "mask-moeda-decimal",
                            rawName: "v-mask-moeda-decimal",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          id: "valorEmbalagem",
                          name: "valorEmbalagem",
                          disabled: _vm.desativaCampos,
                        },
                        model: {
                          value: _vm.modelFracionamento.valorEmbalagem,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.modelFracionamento,
                              "valorEmbalagem",
                              $$v
                            )
                          },
                          expression: "modelFracionamento.valorEmbalagem",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.modelFracionamento.kitEmbalagemId != null,
                      expression: "modelFracionamento.kitEmbalagemId != null",
                    },
                  ],
                  staticClass: "col-sm-12",
                },
                [
                  _c("kit-embalagens-component", {
                    ref: "kitEmbalagemComponent",
                    attrs: {
                      desativaCampos: _vm.desativaCampos,
                      alteracao: _vm.editingModel,
                      itensVenda: _vm.itensVenda,
                    },
                    on: { "change-embalagens": _vm.onChangeEmbalagens },
                  }),
                ],
                1
              ),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "shortcutComponent",
        {
          ref: "shortcutComponent",
          attrs: { showBtnOk: false, modalClass: "lg-modal" },
        },
        [
          _c(
            "div",
            { attrs: { slot: "component" }, slot: "component" },
            [
              _c("estoque-comprometido-component", {
                ref: "estoqueComprometidoComponent",
                attrs: {
                  loteId: _vm.loteComprometidoId,
                  itemFracionamentoId: _vm.itemFracionamento.id,
                },
              }),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c("loading-modal-component", { ref: "loadingModalComponent" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }