var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "crud crud-list" },
    [
      !_vm.CalledByShortCut
        ? _c("div", { staticClass: "info-div" }, [
            _c("h1", { staticClass: "title" }, [
              _vm._v(_vm._s(_vm.$t("__.Crud.cliente.list_vue_html.clientes"))),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "content" }, [
              _c("div", { staticClass: "info-panel" }, [
                _c("span", [_vm._v(_vm._s(_vm.total))]),
                _vm._v(" "),
                _c("span", [
                  _vm._v(
                    _vm._s(_vm.$t("__.Crud.cliente.list_vue_html.clientes"))
                  ),
                ]),
                _vm._v(" "),
                _c("span", [
                  _vm._v(
                    _vm._s(_vm.$t("__.Crud.cliente.list_vue_html.cadastrados"))
                  ),
                ]),
              ]),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "grid-component",
        {
          ref: "gridcomponent",
          attrs: {
            actionName: "clientes",
            columns: _vm.gridColumns,
            data: _vm.gridData,
            extraActions: _vm.extraActions,
            "page-index": _vm.pageIndex,
            "page-size": _vm.pageSize,
            startFilterKey: _vm.startFilterKey,
            tituloPagina: "Clientes",
            total: _vm.total,
            controlaPageSize: true,
          },
          on: {
            "change-filter-key": _vm.onChangeFilterKey,
            "change-page": _vm.onChangePage,
            "change-page-size": _vm.onChangePageSize,
            "change-sort": _vm.onChangeSort,
            "create-item": _vm.onCreateItem,
            "edit-item": _vm.onEditItem,
            "extra-action": _vm.onExtraAction,
            "gerar-impressao": function ($event) {
              _vm.pags = true
              _vm.tipoDocGerar = 2
            },
            "gerar-pdf": function ($event) {
              _vm.pags = true
              _vm.tipoDocGerar = 1
            },
            "gerar-xls": function ($event) {
              _vm.pags = true
              _vm.tipoDocGerar = 0
            },
            "remove-item": _vm.onRemoveItem,
          },
        },
        [
          _c("div", { attrs: { slot: "header" }, slot: "header" }, [
            _c("div", { staticClass: "filtro-lista" }, [
              _c(
                "button",
                {
                  staticClass: "filtro-avancado-button",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      _vm.isHidden = !_vm.isHidden
                    },
                  },
                },
                [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.$t("__.Crud.filtroAvancado")) +
                      "\n                    "
                  ),
                  _c("i", { staticClass: "fa fa-caret-down" }),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isHidden,
                      expression: "isHidden",
                    },
                  ],
                  staticClass: "drop-lista",
                },
                [
                  _c(
                    "form",
                    {
                      attrs: { novalidate: "novalidate" },
                      on: {
                        submit: function ($event) {
                          $event.preventDefault()
                          return _vm.load($event)
                        },
                      },
                    },
                    [
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-sm-12" }, [
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "control-label",
                                  attrs: { for: "emissaoDe" },
                                },
                                [_vm._v("Data Inicial")]
                              ),
                              _vm._v(" "),
                              _c("date-time-picker-component", {
                                attrs: { id: "emissaoDe", name: "emissaoDe" },
                                model: {
                                  value: _vm.emissaoDe,
                                  callback: function ($$v) {
                                    _vm.emissaoDe = $$v
                                  },
                                  expression: "emissaoDe",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-sm-12" }, [
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "control-label",
                                  attrs: { for: "emissaoAte" },
                                },
                                [_vm._v("Data Final")]
                              ),
                              _vm._v(" "),
                              _c("date-time-picker-component", {
                                attrs: { id: "emissaoAte", name: "emissaoAte" },
                                model: {
                                  value: _vm.emissaoAte,
                                  callback: function ($$v) {
                                    _vm.emissaoAte = $$v
                                  },
                                  expression: "emissaoAte",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "form-group" }, [
                        _c(
                          "button",
                          {
                            staticClass: "botao-filtrar",
                            attrs: { type: "submit" },
                          },
                          [
                            _vm._v(
                              "\n                                " +
                                _vm._s(_vm.$t("__.Crud.filtrar")) +
                                "\n                            "
                            ),
                          ]
                        ),
                      ]),
                    ]
                  ),
                ]
              ),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _c("vendas-cliente-list-component", {
        ref: "vendasClienteListComponent",
      }),
      _vm._v(" "),
      _vm.showVer
        ? _c(
            "shortcutComponent",
            {
              ref: "shortcutComponent",
              attrs: {
                showAddNew: false,
                btOkName: "Editar",
                showBtnOk: false,
                title: _vm.$t("__.Crud.cliente.list_vue_html.visuCliente"),
              },
            },
            [
              _c(
                "div",
                { attrs: { slot: "component" }, slot: "component" },
                [
                  _c("ver-component", {
                    ref: "verComponent",
                    attrs: { data: _vm.modelVer },
                  }),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("pontosComponent", { ref: "pontosComponent" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }