import Vue from "vue";

import actionBarComponent from "@/components/child/actionBar/actionBar.vue";
import LoadingModalComponent from "@/components/child/loadingmodal/loadingmodal";
import loadingModalComponent from "@/components/child/loadingmodal/loadingmodal.vue";
import { Component } from "@/decorators";
import ContaGerencialClassificacaoModel from "@/models/financeiro/contaGerencialClassificacaoModel";
import ValidationErrorModel from "@/models/validationErrorModel";
import ContaGerencialClassificacaoService from "@/services/financeiro/contaGerencialClassificacaoService";
import ValidationErrorWrapper from "@/wrappers/validationErrorWrapper";

import "../crud.scss";

@Component({
    components: {
        loadingModalComponent,
        actionBarComponent,
    },
})
export default class ContaGerencialClassificacaoEditComponent extends Vue {
    private service = new ContaGerencialClassificacaoService();
    private validationErrorWrapper = new ValidationErrorWrapper(this.$validator);
    private loadingModalComponent: LoadingModalComponent = null;

    model: ContaGerencialClassificacaoModel = new ContaGerencialClassificacaoModel();
    modelId = 0;

    private load() {
        this.model = new ContaGerencialClassificacaoModel();
        this.validationErrorWrapper.clearErrors();

        if (this.modelId > 0) {
            this.loadingModalComponent.showLoading();

            this.service
                .get(this.modelId)
                .then(response => {
                    return response.json() as Promise<ContaGerencialClassificacaoModel>;
                })
                .then(data => {
                    this.model.updateFrom(data);
                    this.loadingModalComponent.hide();
                })
                .catch(error => {
                    this.$router.back();
                    this.loadingModalComponent.hide();
                });
        }
    }

    private save() {
        this.$validator
            .validateAll()
            .then(isValid => {
                if (isValid) {
                    this.loadingModalComponent.showSaving();

                    this.service[!this.model.id ? "insert" : "update"](this.model)
                        .then(async response => {
                            this.loadingModalComponent.hide();

                            if (response.ok) {
                                await this.$showInclusaoUpdate(this.model.id);
                                this.$router.back();
                            } else {
                                return response.json() as Promise<ValidationErrorModel[]>;
                            }
                        })
                        .then(data => {
                            this.validationErrorWrapper.showErrors(data);
                        })
                        .catch(error => {
                            this.loadingModalComponent.hide();
                        });
                }
            })
            .catch(error => {});
    }

    private cancel() {
        this.$router.back();
    }

    private mounted() {
        this.loadingModalComponent = this.$refs.loadingModalComponent as LoadingModalComponent;

        if (this.$route.params.id) {
            this.modelId = +this.$route.params.id;
            this.load();
        }
    }
}
