var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "crud crud-list" }, [
    _c(
      "form",
      { attrs: { novalidate: "novalidate" } },
      [
        _c(
          "fieldset-component",
          {
            attrs: {
              title: _vm.$t(
                "__.Crud.produtologfinanceiro.list_vue_html.valoresAtuais"
              ),
              collapsed: false,
            },
          },
          [
            _c("div", { attrs: { slot: "rows" }, slot: "rows" }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-sm-2" }, [
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c(
                        "label",
                        {
                          staticClass: "control-label",
                          attrs: { for: "dataUltimaAlteracao" },
                        },
                        [
                          _vm._v(
                            "\n                                " +
                              _vm._s(
                                _vm.$t(
                                  "__.Crud.produtologfinanceiro.list_vue_html.dataUltimaAlteracao"
                                )
                              ) +
                              "\n                            "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("date-time-picker-component", {
                        attrs: {
                          id: "dataUltimaAlteracao",
                          name: "dataUltimaAlteracao",
                          pickTime: true,
                          disabled: "",
                        },
                        model: {
                          value: _vm.dataUltimaAlteracao,
                          callback: function ($$v) {
                            _vm.dataUltimaAlteracao = $$v
                          },
                          expression: "dataUltimaAlteracao",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-sm-2" }, [
                  _c("div", { staticClass: "form-group" }, [
                    _c(
                      "label",
                      {
                        staticClass: "control-label",
                        attrs: { for: "usuarioAlteracao" },
                      },
                      [
                        _vm._v(
                          "\n                                " +
                            _vm._s(
                              _vm.$t(
                                "__.Crud.produtologfinanceiro.list_vue_html.usuarioUltimaAlteracao"
                              )
                            ) +
                            "\n                            "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.usuarioUltimaAlteracao,
                          expression: "usuarioUltimaAlteracao",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: {
                        id: "usuarioAlteracao",
                        name: "usuarioAlteracao",
                        disabled: "",
                      },
                      domProps: { value: _vm.usuarioUltimaAlteracao },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.usuarioUltimaAlteracao = $event.target.value
                        },
                      },
                    }),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-sm-1" }, [
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c(
                        "label",
                        {
                          staticClass: "control-label",
                          attrs: { for: "valorCusto" },
                        },
                        [
                          _vm._v(
                            "\n                                " +
                              _vm._s(
                                _vm.$t(
                                  "__.Crud.produtologfinanceiro.list_vue_html.valorCusto"
                                )
                              ) +
                              "\n                            "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("moeda-component", {
                        directives: [
                          {
                            name: "mask-moeda-decimal",
                            rawName: "v-mask-moeda-decimal",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          id: "valorCusto",
                          name: "valorCusto",
                          disabled: "",
                        },
                        model: {
                          value: _vm.model.valorCusto,
                          callback: function ($$v) {
                            _vm.$set(_vm.model, "valorCusto", $$v)
                          },
                          expression: "model.valorCusto",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-sm-1" }, [
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c(
                        "label",
                        {
                          staticClass: "control-label",
                          attrs: { for: "custoReferencia" },
                        },
                        [
                          _vm._v(
                            "\n                                " +
                              _vm._s(
                                _vm.$t(
                                  "__.Crud.produtologfinanceiro.list_vue_html.custoRefe"
                                )
                              ) +
                              "\n                            "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("moeda-component", {
                        directives: [
                          {
                            name: "mask-moeda-decimal",
                            rawName: "v-mask-moeda-decimal",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          id: "custoReferencia",
                          name: "custoReferencia",
                          disabled: "",
                        },
                        model: {
                          value: _vm.model.custoReferencia,
                          callback: function ($$v) {
                            _vm.$set(_vm.model, "custoReferencia", $$v)
                          },
                          expression: "model.custoReferencia",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-sm-2" }, [
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c(
                        "label",
                        {
                          staticClass: "control-label",
                          attrs: { for: "custoMedio" },
                        },
                        [
                          _vm._v(
                            "\n                                " +
                              _vm._s(
                                _vm.$t(
                                  "__.Crud.produtologfinanceiro.list_vue_html.custoMedio"
                                )
                              ) +
                              "\n                            "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("moeda-component", {
                        directives: [
                          {
                            name: "mask-moeda-decimal",
                            rawName: "v-mask-moeda-decimal",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          id: "custoMedio",
                          name: "custoMedio",
                          disabled: "",
                        },
                        model: {
                          value: _vm.model.custoMedio,
                          callback: function ($$v) {
                            _vm.$set(_vm.model, "custoMedio", $$v)
                          },
                          expression: "model.custoMedio",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-sm-2" }, [
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c(
                        "label",
                        {
                          staticClass: "control-label",
                          attrs: { for: "markup" },
                        },
                        [_vm._v("Markup (%)")]
                      ),
                      _vm._v(" "),
                      _c("decimal-com-sinal-custom-component", {
                        staticClass: "form-control",
                        attrs: {
                          disabled: "",
                          signal: "%",
                          title: "markup",
                          id: "markup",
                          name: "markup",
                        },
                        model: {
                          value: _vm.model.markup,
                          callback: function ($$v) {
                            _vm.$set(_vm.model, "markup", $$v)
                          },
                          expression: "model.markup",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-sm-2" }, [
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c(
                        "label",
                        {
                          staticClass: "control-label",
                          attrs: { for: "valorVenda" },
                        },
                        [
                          _vm._v(
                            "\n                                " +
                              _vm._s(
                                _vm.$t(
                                  "__.Crud.produtologfinanceiro.list_vue_html.vlrsVenda"
                                )
                              ) +
                              "\n                            "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("moeda-component", {
                        directives: [
                          {
                            name: "mask-moeda-decimal",
                            rawName: "v-mask-moeda-decimal",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          id: "valorVenda",
                          name: "valorVenda",
                          disabled: "",
                        },
                        model: {
                          value: _vm.model.valorVenda,
                          callback: function ($$v) {
                            _vm.$set(_vm.model, "valorVenda", $$v)
                          },
                          expression: "model.valorVenda",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "fieldset-component",
          {
            attrs: {
              title: _vm.$t(
                "__.Crud.produtologfinanceiro.list_vue_html.vlrsLogAnterior"
              ),
              collapsed: false,
            },
          },
          [
            _c(
              "div",
              { attrs: { slot: "rows" }, slot: "rows" },
              [
                _c("grid-component", {
                  ref: "gridcomponent",
                  attrs: {
                    columns: _vm.gridColumns,
                    data: _vm.gridData,
                    multipleSelect: false,
                    "page-index": _vm.pageIndex,
                    "page-size": _vm.pageSize,
                    showActionColumn: false,
                    showAddNewButton: false,
                    showEditItem: false,
                    showSearchField: false,
                    showSelectCheckbox: false,
                    showTableFoot: false,
                    showTools: false,
                    showSortIcons: false,
                    startFilterKey: _vm.startFilterKey,
                  },
                  on: {
                    "change-filter-key": _vm.onChangeFilterKey,
                    "change-page": _vm.onChangePage,
                    "change-sort": _vm.onChangeSort,
                  },
                }),
              ],
              1
            ),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }