import DevolucaoModel from "../models/devolucaoModel";
import nfetch from "../utils/events/nfetch";

import { getCookie } from "./utils/serviceUtils";

export default class DevolucaoService {
    private controllerName = "Devolucao";

    public list(
        filterKey: string,
        sortKey: string,
        sortOrder: string,
        pageIndex: number,
        pageSize: number,
    ): Promise<Response> {
        const encodedFIlter = encodeURIComponent(filterKey);
        return nfetch(
            `/${this.controllerName}/List?filterKey=${encodedFIlter}&sortKey=${sortKey}&sortOrder=${sortOrder}&pageIndex=${pageIndex}&pageSize=${pageSize}`,
            {
                credentials: "same-origin",
            },
        );
    }

    public get(id: number): Promise<Response> {
        return nfetch(`/${this.controllerName}/Get?id=${id}`, {
            credentials: "same-origin",
        });
    }

    public insertDevolucaoCompra(model: DevolucaoModel): Promise<Response> {
        return nfetch(`/${this.controllerName}/InsertDevolucaoCompra`, {
            method: "post",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json; charset=UTF-8",
                "XSRF-TOKEN": getCookie("XSRF-TOKEN"),
            },
            body: JSON.stringify(model),
        });
    }
}
