var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "crud crud-edit" },
    [
      _c("actionBarComponent", {
        attrs: { title: _vm.$t("__.ts.estoqueMinimo"), showSave: false },
      }),
      _vm._v(" "),
      _c("form", [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-sm-3" }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "tooltip",
                    rawName: "v-tooltip",
                    value: _vm.errors.first("mesesConsiderar"),
                    expression: "errors.first('mesesConsiderar')",
                  },
                ],
                staticClass: "form-group",
              },
              [
                _c(
                  "label",
                  {
                    staticClass: "control-label",
                    attrs: { for: "mesesConsiderar" },
                  },
                  [
                    _c("span", { staticStyle: { color: "red" } }, [
                      _vm._v("*"),
                    ]),
                    _vm._v(
                      "\n                        " +
                        _vm._s(
                          _vm.$t(
                            "__.Crud.estoqueMinimo.view_vue_html.mesesConsiderar"
                          )
                        ) +
                        "\n                    "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.mesesConsiderar,
                      expression: "mesesConsiderar",
                    },
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required",
                      expression: "'required'",
                    },
                  ],
                  ref: "mesesConsiderar",
                  staticClass: "form-control",
                  attrs: {
                    id: "mesesConsiderar",
                    name: "mesesConsiderar",
                    type: "number",
                  },
                  domProps: { value: _vm.mesesConsiderar },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.mesesConsiderar = $event.target.value
                    },
                  },
                }),
              ]
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-sm-3" }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "tooltip",
                    rawName: "v-tooltip",
                    value: _vm.errors.first("coberturaEmDias"),
                    expression: "errors.first('coberturaEmDias')",
                  },
                ],
                staticClass: "form-group",
              },
              [
                _c(
                  "label",
                  {
                    staticClass: "control-label",
                    attrs: { for: "coberturaEmDias" },
                  },
                  [
                    _c("span", { staticStyle: { color: "red" } }, [
                      _vm._v("*"),
                    ]),
                    _vm._v(
                      "\n                        " +
                        _vm._s(
                          _vm.$t(
                            "__.Crud.estoqueMinimo.view_vue_html.coberturaEstoque"
                          )
                        ) +
                        "\n                    "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.coberturaEmDias,
                      expression: "coberturaEmDias",
                    },
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required",
                      expression: "'required'",
                    },
                  ],
                  ref: "coberturaEmDias",
                  staticClass: "form-control",
                  attrs: {
                    id: "coberturaEmDias",
                    name: "coberturaEmDias",
                    type: "number",
                  },
                  domProps: { value: _vm.coberturaEmDias },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.coberturaEmDias = $event.target.value
                    },
                  },
                }),
              ]
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-sm-3" }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "tooltip",
                    rawName: "v-tooltip",
                    value: _vm.errors.first("grupoProdutoId"),
                    expression: "errors.first('grupoProdutoId')",
                  },
                ],
                staticClass: "form-group",
              },
              [
                _c(
                  "label",
                  {
                    staticClass: "control-label",
                    attrs: { for: "grupoProduto" },
                  },
                  [
                    _vm._v(
                      "\n                        " +
                        _vm._s(
                          _vm.$t("__.Crud.produto.list_vue_html.grupProduto")
                        ) +
                        "\n                    "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("combo-component", {
                  staticStyle: { background: "white" },
                  attrs: {
                    id: "grupoProdutoId",
                    name: "grupoProdutoId",
                    data: _vm.grupoProdutoOptions,
                    refresh: _vm.loadGruposProduto,
                  },
                  model: {
                    value: _vm.grupoProdutoId,
                    callback: function ($$v) {
                      _vm.grupoProdutoId = $$v
                    },
                    expression: "grupoProdutoId",
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-sm-12 newbtn" }, [
            _c("div", { staticClass: "form-group" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-success",
                  attrs: { type: "button" },
                  on: { click: _vm.onAtualizar },
                },
                [
                  _vm._v(
                    "\n                        " +
                      _vm._s(
                        _vm.$t(
                          "__.Crud.estoqueMinimo.view_vue_html.calcularEstoque"
                        )
                      ) +
                      "\n                    "
                  ),
                ]
              ),
            ]),
          ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }