import Vue from "vue";
import { mapState, mapMutations, mapGetters } from "vuex";

import Moment from "moment";

import { Component } from "@/decorators";
import PaginationModel from "@/models/paginationModel";
import VendaContinuoModel from "@/models/vendaContinuoModel";
import VendaContinuoUtilsService from "@/services/utils/vendaContinuoUtilsService";
import VendaContinuoService from "@/services/vendaContinuoService";
import { FastActionLastPack, FastActionList, FastActionPack } from "@/store/fastActionStore";
import { AppState, Getters, Mutations } from "@/store/store";

import { getDateString } from "../fastactionUtils";

import VendaContinuoListComponent from "./../../../crud/vendaContinuo/list";
import vendaContinuoListComponent from "./../../../crud/vendaContinuo/list.vue";
import ShortcutComponent from "./../../../shortcut/shortcut";
import shortcutComponent from "./../../../shortcut/shortcut.vue";

import "./../fastactionsgrid.scss";

@Component({
    components: {
        vendaContinuoListComponent,
        shortcutComponent,
    },
    computed: {
        ...mapState<AppState>({
            fastActionLists: state => state.fastAction.fastActionLists,
            fastActionPack: state => state.fastAction.fastActionPack,
        }),
        ...mapGetters(["VALIDAR_PERMISSAO_USUARIO"] as Getters),
    },
    methods: {
        ...mapMutations(["SET_LISTSPACK", "SET_COUNTPACK", "SET_LASTCOUNTPACK"] as Mutations),
        getDateString: getDateString,
    },
})
export default class FastActionContinuosComponent extends Vue {
    // State computed props
    fastActionLists: FastActionList;
    fastActionPack: FastActionPack;
    SET_LISTSPACK: (data: FastActionList) => void;
    SET_COUNTPACK: (data: FastActionLastPack) => void;
    SET_LASTCOUNTPACK: (data: FastActionLastPack) => void;
    VALIDAR_PERMISSAO_USUARIO: (telaDescricao: string, acaoController: string) => boolean;

    private vendaContinuoListComponent: VendaContinuoListComponent = null;
    private shortcutComponent: ShortcutComponent = null;

    private vendasContinuos: Array<VendaContinuoModel> = [];

    private vendaContinuoUtilsService = new VendaContinuoUtilsService();

    gridFilterKey = "";
    gridSortKey = "dataVencimento";
    gridSortOrder = "desc";
    pageIndex = 1;
    pageSize = 10;
    total = 0;

    private async loadVendaContinuo(forcarLoad = true) {
        const continuosPreLoads = this.fastActionLists.continuos;
        if (continuosPreLoads.length > 0 && !forcarLoad) {
            this.vendasContinuos = continuosPreLoads as Array<VendaContinuoModel>;
        } else {
            try {
                const data = await new VendaContinuoService()
                    .list("", "dataVencimento", "asc", this.pageIndex, this.pageSize)
                    .withLoading()
                    .resolveWithJSON<PaginationModel<VendaContinuoModel>>();

                this.vendasContinuos = data.list;
                this.SET_LISTSPACK({ continuos: data.list });
            } catch {}
        }
    }

    private getClass(venda: VendaContinuoModel) {
        const timeDiff = Moment(venda.dataVencimento).diff(new Date());
        const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
        if (diffDays <= 1) {
            return "ExcRed";
        }
        if (venda.orcado) {
            return "ExcGreen";
        }
        return "";
    }

    private getFirstName(str: string, entreParenteses: boolean) {
        const strFirstName = str.substr(0, str.indexOf(" "));
        if (strFirstName.trim() != "") {
            return (entreParenteses ? "(" : "") + strFirstName.trim() + (entreParenteses ? ")" : "");
        }
        return "";
    }

    private onSearch(venda: VendaContinuoModel) {
        this.$router.push("/venda-edicao/" + venda.vendaId);
    }

    private async onAdiar(venda: VendaContinuoModel) {
        const sucesso = await this.vendaContinuoUtilsService.adiar(venda);
        if (sucesso) {
            this.loadVendaContinuo();
        }
    }

    private async onArquivar(venda: VendaContinuoModel) {
        const sucesso = await this.vendaContinuoUtilsService.arquivar(venda);
        if (sucesso) {
            this.loadVendaContinuo();
            const continuos = Number(this.fastActionPack.countPack.continuos) - 1;
            this.SET_COUNTPACK({ continuos });
            this.SET_LASTCOUNTPACK({ continuos });
        }
    }

    private async onWhats(venda: VendaContinuoModel) {
        const sucesso = await this.vendaContinuoUtilsService.getMessageWhats(venda);
        if (sucesso) {
            this.loadVendaContinuo();
        }
    }

    private onVerMais() {
        this.vendaContinuoListComponent.load();
        this.shortcutComponent.title = this.$t("__.ts.avisosContinuo") as string;
        this.shortcutComponent.show();
    }

    private onConfirmShortContinuos() {
        this.shortcutComponent.hide();
    }

    private validarVisibilidadeBotao(acao: string) {
        return this.VALIDAR_PERMISSAO_USUARIO("vendas", acao);
    }

    private mounted() {
        this.shortcutComponent = this.$refs.shortcutComponent as ShortcutComponent;
        this.vendaContinuoListComponent = this.$refs.vendaContinuoListComponent as VendaContinuoListComponent;

        Promise.all([this.loadVendaContinuo(false)])
            .then(() => {
                this.SET_LASTCOUNTPACK({ continuos: Number(this.fastActionPack.countPack.continuos) });
            })
            .catch(() => {});
    }
}
