import { GridColumn, GridColumnType } from "@/components/child/grid/gridColumn";
import { Component } from "@/decorators";
import MotivoEtapaPCPModel from "@/models/pcp/motivoEtapaPCPModel";
import MotivoEtapaPCPService from "@/services/motivoEtapaPCPService";

import ListComponentBase from "../listComponentBase";

@Component
export default class MotivoEtapaPCPListComponent extends ListComponentBase<MotivoEtapaPCPModel> {
    get gridColumns(): Array<GridColumn> {
        return [new GridColumn("descricao", this.$t("__.ts.descricao") as string, GridColumnType.String)];
    }

    private beforeMount() {
        this.setProps(new MotivoEtapaPCPService(), "motivoetapaPCP", "descricao");
    }
}
