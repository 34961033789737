import BaseModel from "./base/baseModel";

export default class CompraItemFornecedorModel extends BaseModel {
    id?: number = null;
    compraItemId?: number = null;
    fornecedorId?: number = null;
    fornecedorDescricao?: string = null;
    valorUnitario?: number = null;
    valorTotal?: number = null;
    quantidade?: number = null;
    unidadeMedidaId: number = null;
    dataValidade?: Date = null;
}
