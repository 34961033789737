var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.model != null
        ? _c(
            "fieldset-component",
            {
              attrs: {
                title: "Produto " + _vm.model.descricao,
                collapsed: false,
              },
            },
            [
              _c("div", { attrs: { slot: "rows" }, slot: "rows" }, [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-sm-3" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("tipoProdutoId"),
                            expression: "errors.first('tipoProdutoId')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "tipoProdutoId" },
                          },
                          [
                            _vm._v(
                              "\n                            " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Components.parent.importacaonfe.produtolotes_vue_html.tipo"
                                  )
                                ) +
                                "\n                        "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("combo-component", {
                          attrs: {
                            id: "tipoProdutoId",
                            name: "tipoProdutoId",
                            data: _vm.tipoProdutoOptions,
                          },
                          model: {
                            value: _vm.tipoProdutoId,
                            callback: function ($$v) {
                              _vm.tipoProdutoId = $$v
                            },
                            expression: "tipoProdutoId",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { class: [_vm.isFracionamento ? "col-sm-6" : "col-sm-8"] },
                    [
                      _c("span", {
                        directives: [
                          {
                            name: "shortkey",
                            rawName: "v-shortkey.once",
                            value: ["alt", "1"],
                            expression: "['alt', '1']",
                            modifiers: { once: true },
                          },
                          {
                            name: "shortkey",
                            rawName: "v-shortkey.focus",
                            value: true,
                            expression: "true",
                            modifiers: { focus: true },
                          },
                        ],
                        on: {
                          shortkey: function ($event) {
                            _vm.cursorFocus("produtoId")
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: _vm.errors.first("produtoId"),
                              expression: "errors.first('produtoId')",
                            },
                          ],
                          staticClass: "form-group",
                        },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "control-label",
                              attrs: {
                                for: "produtoId",
                                title: _vm.$t("__.obrigatorio"),
                              },
                            },
                            [
                              _c("span", { staticStyle: { color: "red" } }, [
                                _vm._v("*"),
                              ]),
                              _vm._v(
                                "\n                            " +
                                  _vm._s(
                                    _vm.$t(
                                      "__.Components.parent.importacaonfe.produtolotes_vue_html.produto"
                                    )
                                  ) +
                                  "\n                        "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "combo-component",
                            {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "required",
                                  expression: "'required'",
                                },
                              ],
                              ref: "produtoId",
                              attrs: {
                                id: "produtoId",
                                name: "produtoId",
                                actionName: "produtos",
                                addEditId: _vm.model.produtoId,
                                addNew: !_vm.model.produtoId,
                                customView: true,
                                data: _vm.produtosOptions,
                                formAddNewName:
                                  _vm.model.produtoId > 0
                                    ? "Editar Produto"
                                    : "Novo Produto",
                                modalClass: "bg-modal",
                                refresh: _vm.loadProdutos,
                                useCloseModalCallback: true,
                              },
                              on: {
                                addNewItemCombo: _vm.onAddNewProduto,
                                openComboEditItem: _vm.openComboEditProduto,
                                openComboNewItem: _vm.openComboNewProduto,
                              },
                              model: {
                                value: _vm.model.produtoId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.model, "produtoId", $$v)
                                },
                                expression: "model.produtoId",
                              },
                            },
                            [
                              _vm.showProduto
                                ? _c(
                                    "div",
                                    {
                                      attrs: { slot: "component" },
                                      slot: "component",
                                    },
                                    [
                                      _c("produto-component", {
                                        ref: "produtoComponent",
                                        attrs: {
                                          isModal: true,
                                          descricaoSugestao:
                                            _vm.model.descricao,
                                        },
                                        on: { "save-ok": _vm.onProdutoSaveOk },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-1" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("fatorCorrecao"),
                            expression: "errors.first('fatorCorrecao')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: {
                              for: "fatorCorrecao",
                              title: _vm.$t(
                                "__.Components.parent.importacaonfe.produtolotes_vue_html.fc"
                              ),
                            },
                          },
                          [
                            _vm._v(
                              "\n                            " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Components.parent.importacaonfe.produtolotes_vue_html.fc"
                                  )
                                ) +
                                "\n                        "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("decimal-com-sinal-custom-component", {
                          staticClass: "form-control",
                          attrs: {
                            signal: "",
                            id: "fatorCorrecao",
                            name: "fatorCorrecao",
                          },
                          model: {
                            value: _vm.model.fatorCorrecao,
                            callback: function ($$v) {
                              _vm.$set(_vm.model, "fatorCorrecao", $$v)
                            },
                            expression: "model.fatorCorrecao",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _vm.isFracionamento
                    ? _c("div", { staticClass: "col-sm-2" }, [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: _vm.errors.first("quantidadeOriginal"),
                                expression:
                                  "errors.first('quantidadeOriginal')",
                              },
                            ],
                            staticClass: "form-group",
                          },
                          [
                            _c(
                              "label",
                              {
                                staticClass: "control-label",
                                attrs: { for: "quantidadeOriginal" },
                              },
                              [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(
                                      _vm.$t(
                                        "__.Components.parent.importacaonfe.produtolotes_vue_html.qtdUnMedOrig"
                                      )
                                    ) +
                                    "\n                        "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                { name: "focus", rawName: "v-focus" },
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.unOriginal,
                                  expression: "unOriginal",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: {
                                autocomplete: "off",
                                readonly: "",
                                type: "text",
                                id: "quantidadeOriginal",
                                name: "quantidadeOriginal",
                              },
                              domProps: { value: _vm.unOriginal },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.unOriginal = $event.target.value
                                },
                              },
                            }),
                          ]
                        ),
                      ])
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  !_vm.isFracionamento
                    ? _c("div", { staticClass: "col-sm-2" }, [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: _vm.errors.first("quantidadeOriginal"),
                                expression:
                                  "errors.first('quantidadeOriginal')",
                              },
                            ],
                            staticClass: "form-group",
                          },
                          [
                            _c(
                              "label",
                              {
                                staticClass: "control-label",
                                attrs: { for: "quantidadeOriginal" },
                              },
                              [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(
                                      _vm.$t(
                                        "__.Components.parent.importacaonfe.produtolotes_vue_html.qtdUnMedOrig"
                                      )
                                    ) +
                                    "\n                        "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                { name: "focus", rawName: "v-focus" },
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.unOriginal,
                                  expression: "unOriginal",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: {
                                autocomplete: "off",
                                readonly: "",
                                type: "text",
                                id: "quantidadeOriginal",
                                name: "quantidadeOriginal",
                              },
                              domProps: { value: _vm.unOriginal },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.unOriginal = $event.target.value
                                },
                              },
                            }),
                          ]
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-2" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("quantidadeNf"),
                            expression: "errors.first('quantidadeNf')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: {
                              for: "quantidadeNf",
                              title: _vm.$t("__.obrigatorio"),
                            },
                          },
                          [
                            _c("span", { staticStyle: { color: "red" } }, [
                              _vm._v("*"),
                            ]),
                            _vm._v(
                              "\n                            " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Components.parent.importacaonfe.produtolotes_vue_html.qtd"
                                  )
                                ) +
                                "\n                        "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("decimal-com-sinal-custom-component", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required|maior_que_zero",
                              expression: "'required|maior_que_zero'",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            signal: "",
                            id: "quantidadeNf",
                            name: "quantidadeNf",
                          },
                          model: {
                            value: _vm.model.quantidade,
                            callback: function ($$v) {
                              _vm.$set(_vm.model, "quantidade", $$v)
                            },
                            expression: "model.quantidade",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { class: [_vm.isFracionamento ? "col-sm-4" : "col-sm-2"] },
                    [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: _vm.errors.first("unidadeMedidaNfId"),
                              expression: "errors.first('unidadeMedidaNfId')",
                            },
                          ],
                          staticClass: "form-group",
                        },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "control-label",
                              attrs: {
                                for: "unidadeMedidaNfId",
                                title: _vm.$t("__.obrigatorio"),
                              },
                            },
                            [
                              _c("span", { staticStyle: { color: "red" } }, [
                                _vm._v("*"),
                              ]),
                              _vm._v(
                                "\n                            " +
                                  _vm._s(
                                    _vm.$t(
                                      "__.Components.parent.importacaonfe.produtolotes_vue_html.unMed"
                                    )
                                  ) +
                                  "\n                        "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("combo-component", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required",
                                expression: "'required'",
                              },
                            ],
                            attrs: {
                              id: "unidadeMedidaNfId",
                              name: "unidadeMedidaNfId",
                              data: _vm.unidadeMedidaNfOptions.filter(function (
                                p
                              ) {
                                return p["type"] < 3
                              }),
                            },
                            model: {
                              value: _vm.model.unidadeMedidaNfId,
                              callback: function ($$v) {
                                _vm.$set(_vm.model, "unidadeMedidaNfId", $$v)
                              },
                              expression: "model.unidadeMedidaNfId",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-2" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("quantidadeConvertida"),
                            expression: "errors.first('quantidadeConvertida')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: {
                              for: "quantidadeConvertida",
                              title: _vm.$t("__.obrigatorio"),
                            },
                          },
                          [
                            _c("span", { staticStyle: { color: "red" } }, [
                              _vm._v("*"),
                            ]),
                            _vm._v(
                              "\n                            " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Components.parent.importacaonfe.produtolotes_vue_html.qtdConvertida"
                                  )
                                ) +
                                "\n                        "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("decimal-com-sinal-custom-component", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required|maior_que_zero",
                              expression: "'required|maior_que_zero'",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            readonly: "",
                            signal: _vm.getSignalQuantidade(),
                            id: "quantidadeConvertida",
                            name: "quantidadeConvertida",
                          },
                          model: {
                            value: _vm.model.quantidadeConvertida,
                            callback: function ($$v) {
                              _vm.$set(_vm.model, "quantidadeConvertida", $$v)
                            },
                            expression: "model.quantidadeConvertida",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { class: [_vm.isFracionamento ? "col-sm-4" : "col-sm-2"] },
                    [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: _vm.errors.first("precoUnitarioNf"),
                              expression: "errors.first('precoUnitarioNf')",
                            },
                          ],
                          staticClass: "form-group",
                        },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "control-label",
                              attrs: { for: "precoUnitarioNf" },
                            },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(
                                    _vm.$t(
                                      "__.Components.parent.importacaonfe.produtolotes_vue_html.custoUnit"
                                    )
                                  ) +
                                  "\n                        "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("moeda-component", {
                            directives: [
                              {
                                name: "mask-moeda-decimal",
                                rawName: "v-mask-moeda-decimal",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: {
                              id: "precoUnitarioNf",
                              readonly: "",
                              name: "precoUnitarioNf",
                            },
                            model: {
                              value: _vm.precoUnitarioNf,
                              callback: function ($$v) {
                                _vm.precoUnitarioNf = $$v
                              },
                              expression: "precoUnitarioNf",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  !_vm.isFracionamento
                    ? _c("div", { staticClass: "col-sm-2" }, [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: _vm.errors.first(
                                  "custoReferenciaProduto"
                                ),
                                expression:
                                  "errors.first('custoReferenciaProduto')",
                              },
                            ],
                            staticClass: "form-group",
                          },
                          [
                            _c(
                              "label",
                              {
                                staticClass: "control-label",
                                attrs: { for: "custoReferenciaProduto" },
                              },
                              [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(
                                      _vm.$t(
                                        "__.Components.parent.importacaonfe.produtolotes_vue_html.qtdUnMedOrig"
                                      )
                                    ) +
                                    "\n                        "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("moeda-component", {
                              directives: [
                                {
                                  name: "mask-moeda-decimal",
                                  rawName: "v-mask-moeda-decimal",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: {
                                id: "custoReferenciaProduto",
                                readonly: "",
                                name: "custoReferenciaProduto",
                              },
                              model: {
                                value: _vm.model.custoReferenciaProduto,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.model,
                                    "custoReferenciaProduto",
                                    $$v
                                  )
                                },
                                expression: "model.custoReferenciaProduto",
                              },
                            }),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                ]),
              ]),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.model != null
        ? _c(
            "fieldset-component",
            {
              attrs: {
                title: _vm.$t(
                  "__.Components.parent.importacaonfe.produtolotes_vue_html.dadosLote"
                ),
                collapsed: false,
              },
            },
            [
              _c("div", { attrs: { slot: "rows" }, slot: "rows" }, [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-sm-4" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("descricaoLote"),
                            expression: "errors.first('descricaoLote')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: {
                              for: "descricaoLote",
                              title: _vm.$t("__.obrigatorio"),
                            },
                          },
                          [
                            _c("span", { staticStyle: { color: "red" } }, [
                              _vm._v("*"),
                            ]),
                            _vm._v(
                              "\n                            " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Components.parent.importacaonfe.produtolotes_vue_html.descricao"
                                  )
                                ) +
                                "\n                        "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            { name: "focus", rawName: "v-focus" },
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.model.lote.descricao,
                              expression: "model.lote.descricao",
                            },
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required|max:256",
                              expression: "'required|max:256'",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            autocomplete: "off",
                            disabled: _vm.model.importarSemLote,
                            type: "text",
                            id: "descricaoLote",
                            name: "descricaoLote",
                          },
                          domProps: { value: _vm.model.lote.descricao },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.model.lote,
                                "descricao",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-2" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("dataFabricacao"),
                            expression: "errors.first('dataFabricacao')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: {
                              for: "dataFabricacao",
                              title: _vm.$t("__.obrigatorio"),
                            },
                          },
                          [
                            _c("span", { staticStyle: { color: "red" } }, [
                              _vm._v("*"),
                            ]),
                            _vm._v(
                              "\n                            " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Components.parent.importacaonfe.produtolotes_vue_html.dtFab"
                                  )
                                ) +
                                "\n                        "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("date-time-picker-component", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required",
                              expression: "'required'",
                            },
                          ],
                          attrs: {
                            disabled: _vm.model.importarSemLote,
                            id: "dataFabricacao",
                            name: "dataFabricacao",
                          },
                          model: {
                            value: _vm.model.lote.dataFabricacao,
                            callback: function ($$v) {
                              _vm.$set(_vm.model.lote, "dataFabricacao", $$v)
                            },
                            expression: "model.lote.dataFabricacao",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-2" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("dataValidade"),
                            expression: "errors.first('dataValidade')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: {
                              for: "dataValidade",
                              title: _vm.$t("__.obrigatorio"),
                            },
                          },
                          [
                            _c("span", { staticStyle: { color: "red" } }, [
                              _vm._v("*"),
                            ]),
                            _vm._v(
                              "\n                            " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Components.parent.importacaonfe.produtolotes_vue_html.dtVal"
                                  )
                                ) +
                                "\n                        "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("date-time-picker-component", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required",
                              expression: "'required'",
                            },
                          ],
                          attrs: {
                            disabled: _vm.model.importarSemLote,
                            id: "dataValidade",
                            name: "dataValidade",
                          },
                          model: {
                            value: _vm.model.lote.dataValidade,
                            callback: function ($$v) {
                              _vm.$set(_vm.model.lote, "dataValidade", $$v)
                            },
                            expression: "model.lote.dataValidade",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-2" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("densidade"),
                            expression: "errors.first('densidade')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "densidade" },
                          },
                          [
                            _vm._v(
                              "\n                            " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Components.parent.importacaonfe.produtolotes_vue_html.densidade"
                                  )
                                ) +
                                "\n                        "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("decimal-component", {
                          staticClass: "form-control",
                          attrs: { id: "densidade", name: "densidade" },
                          model: {
                            value: _vm.model.lote.densidade,
                            callback: function ($$v) {
                              _vm.$set(_vm.model.lote, "densidade", $$v)
                            },
                            expression: "model.lote.densidade",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-2" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("precoMaximoConsumidor"),
                            expression: "errors.first('precoMaximoConsumidor')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "precoMaximoConsumidor" },
                          },
                          [
                            _vm._v(
                              "\n                            " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Components.parent.importacaonfe.produtolotes_vue_html.precoMaxConsumidor"
                                  )
                                ) +
                                "\n                        "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("moeda-component", {
                          directives: [
                            {
                              name: "mask-moeda-decimal",
                              rawName: "v-mask-moeda-decimal",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            id: "precoMaximoConsumidor",
                            disabled: _vm.model.importarSemLote,
                            name: "precoMaximoConsumidor",
                          },
                          model: {
                            value: _vm.model.lote.precoMaximoConsumidor,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.model.lote,
                                "precoMaximoConsumidor",
                                $$v
                              )
                            },
                            expression: "model.lote.precoMaximoConsumidor",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _vm.isFracionamento
                  ? _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-sm-2" }, [
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c("label", { attrs: { for: "fracionarLote" } }, [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(
                                    _vm.$t(
                                      "__.Components.parent.importacaonfe.produtolotes_vue_html.fracionarLote"
                                    )
                                  ) +
                                  "\n                        "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("checkbox-component", {
                              attrs: {
                                id: "fracionarLote",
                                name: "fracionarLote",
                              },
                              model: {
                                value: _vm.model.lote.fracionarLote,
                                callback: function ($$v) {
                                  _vm.$set(_vm.model.lote, "fracionarLote", $$v)
                                },
                                expression: "model.lote.fracionarLote",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                !_vm.model.lote.automaticoNfe
                  ? _c(
                      "div",
                      { staticClass: "row" },
                      [
                        _c("div", { staticClass: "col-sm-2" }, [
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c(
                                "label",
                                { attrs: { for: "chkImportarSemLote" } },
                                [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(
                                        _vm.$t(
                                          "__.Components.parent.importacaonfe.produtolotes_vue_html.importSemLote"
                                        )
                                      ) +
                                      "\n                        "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("checkbox-component", {
                                attrs: {
                                  id: "chkImportarSemLote",
                                  name: "chkImportarSemLote",
                                },
                                model: {
                                  value: _vm.model.importarSemLote,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.model, "importarSemLote", $$v)
                                  },
                                  expression: "model.importarSemLote",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _vm._v(" "),
                        !_vm.model.importarSemLote &&
                        (_vm.model.lote.dataFabricacao == null ||
                          _vm.model.lote.dataValidade == null ||
                          _vm.model.lote.descricao.trim() == "")
                          ? [
                              _c(
                                "button",
                                {
                                  staticClass: "btn btnCopiar pull-right",
                                  staticStyle: {
                                    "margin-top": "28px !important",
                                  },
                                  attrs: { type: "button" },
                                  on: { click: _vm.autoCompleteLote },
                                },
                                [
                                  _c("i", {
                                    staticClass: "fa fa-edit",
                                    staticStyle: {
                                      "font-size": "16px !important",
                                      "margin-right": "3px",
                                    },
                                    attrs: { "aria-hidden": "true" },
                                  }),
                                  _vm._v(
                                    "\n                        " +
                                      _vm._s(
                                        _vm.$t(
                                          "__.Components.parent.importacaonfe.produtolotes_vue_html.preencherLote"
                                        )
                                      ) +
                                      "\n                    "
                                  ),
                                ]
                              ),
                            ]
                          : _vm._e(),
                      ],
                      2
                    )
                  : _vm._e(),
              ]),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }