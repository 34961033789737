import Vue from "vue";
import { MutationTree } from "vuex";

import {
    getUltimaMensagem,
    setAtendimentoNome,
    sortDataUltimaMensagem,
} from "@/components/parent/atendimento/atendimentoUtils";
import AtendimentoWhatsAppModel from "@/models/whatsApp/atendimento/atendimentoWhatsAppModel";
import MensagemAtendimentoWhatsAppModel from "@/models/whatsApp/atendimento/mensagemAtendimentoWhatsAppModel";

import { AtendimentoContextState } from "./types";

const mutations: MutationTree<AtendimentoContextState> = {
    CREATE_ATENDIMENTO_BY_ID(store, id: string) {
        const newAtendimento = new AtendimentoWhatsAppModel();
        newAtendimento.id = id;
        Vue.set(store.atendimentos, store.atendimentos.length, newAtendimento);
    },
    CREATE_ATENDIMENTO_BY_NUMERO(store, atendimento: AtendimentoWhatsAppModel) {
        atendimento = setAtendimentoNome(atendimento);
        Vue.set(store.atendimentos, store.atendimentos.length, atendimento);
    },
    NOTIFICATION_ADD(store, id: string) {
        const messages = store.messagesAtendimentos.find(x => x.id == id);
        if (messages) {
            const atendimento = store.atendimentos.find(p => p.id == id);
            if (atendimento) {
                atendimento.unreadCount = messages.messages.length;
            }
        }
    },
    NOTIFICATION_CLEAN(store, id: string) {
        const messages = store.messagesAtendimentos.find(x => x.id == id);
        if (messages) {
            store.messagesAtendimentos = store.messagesAtendimentos.filter(x => x.id != messages.id);
            const atendimento = store.atendimentos.find(p => p.id == id);
            if (atendimento) {
                atendimento.unreadCount = 0;
            }
        }
    },
    NOTIFICATION_SET_ALL(store) {
        store.totalUnreadCount = store.atendimentos.reduce((total, atendimento) => total + atendimento.unreadCount, 0);
    },
    ORDER_ATENDIMENTOS(store) {
        store.atendimentos = sortDataUltimaMensagem(store.atendimentos);
    },
    RECEIVE_MESSAGE(store, message: MensagemAtendimentoWhatsAppModel) {
        const mensagem = store.currentMessages.find(x => x.id == message.id);

        if (mensagem) {
            if (message.mensagemExcluida) {
                mensagem.mensagemExcluida = true;
            } else {
                mensagem.texto = message.texto;
                mensagem.statusMensagem = message.statusMensagem;
                mensagem.mensagemEditada = message.mensagemEditada;
                mensagem.imagem = message.imagem;
                mensagem.audio = message.audio;
                mensagem.documento = message.documento;
                mensagem.figura = message.figura;
                mensagem.video = message.video;
            }
        } else {
            if (
                store.currentMessages.length > 0 &&
                store.currentMessages.some(p => p.atendimentoId == message.atendimentoId)
            ) {
                store.currentMessages.push(message);
            } else {
                const atendimento = store.messagesAtendimentos.find(x => x.id == message.atendimentoId);
                if (atendimento) {
                    atendimento.messages.push(message);
                } else {
                    store.messagesAtendimentos.push({ id: message.atendimentoId, messages: [message] });
                }
            }
        }
    },
    SEND_MESSAGE(store, mensagem: MensagemAtendimentoWhatsAppModel) {
        store.currentMessages.push(mensagem);
        const atendimento = store.atendimentos.find(p => p.id == mensagem.atendimentoId);
        if (atendimento) {
            atendimento.ultimaMensagem = getUltimaMensagem(mensagem);
            atendimento.dataInicio = mensagem.data;
        }
    },
    SET_ATENDIMENTOS(store, atendimentos: AtendimentoWhatsAppModel[]) {
        store.atendimentos = atendimentos;
    },
    SET_ATENDIMENTOS_ENCERRADOS(store, atendimentos: AtendimentoWhatsAppModel[]) {
        store.atendimentosEncerrados = atendimentos;
    },
    SET_CURRENT_ATENDIMENTO(store, atendimentoId: string) {
        if (store.atendimentos.some(p => p.id === atendimentoId)) {
            store.currentAtendimento = store.atendimentos.find(p => p.id === atendimentoId);
        } else {
            store.currentAtendimento = store.atendimentosEncerrados.find(p => p.id === atendimentoId);
        }
    },
    SET_CURRENT_MESSAGES(store, messages: MensagemAtendimentoWhatsAppModel[]) {
        store.currentMessages = messages.sort((a, b) => (a.data > b.data ? 1 : -1));
    },
    UPDATE_ATENDIMENTO(store, atendimento: AtendimentoWhatsAppModel) {
        const index = store.atendimentos.findIndex(p => p.id == atendimento.id);
        Vue.set(store.atendimentos, index, atendimento);
    },
    UPDATE_ATENDIMENTO_LAST_MESSAGE(store, mensagem: MensagemAtendimentoWhatsAppModel) {
        if (mensagem) {
            if (store.currentAtendimento && store.currentAtendimento.id == mensagem.atendimentoId) {
                store.currentAtendimento.dataInicio = mensagem.dataExclusao ?? mensagem.dataInclusao ?? mensagem.data;
                store.currentAtendimento.ultimaMensagem = getUltimaMensagem(mensagem);
                store.currentAtendimento.dataUltimaMensagem = mensagem.data;
            } else {
                const atendimento = store.atendimentos.find(p => p.id == mensagem.atendimentoId);
                atendimento.dataInicio = mensagem.dataExclusao ?? mensagem.dataInclusao ?? mensagem.data;
                atendimento.ultimaMensagem = getUltimaMensagem(mensagem);
                atendimento.dataUltimaMensagem = mensagem.data;
            }
        }
    },
};

export default mutations;
