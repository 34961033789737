import { mapMutations } from "vuex";

import checkboxComponent from "@/components/child/form/checkbox.vue";
import { Component } from "@/decorators";
import EstoqueModel from "@/models/estoqueModel";
import EstoqueService from "@/services/estoqueService";
import { LoadListProps, Mutations } from "@/store/store";

import EditComponentBase from "../editComponentBase";

import "../crud.scss";

@Component({
    components: {
        checkboxComponent,
    },
    methods: mapMutations(["LOAD_LIST"] as Mutations),
})
export default class EstoqueEditComponent extends EditComponentBase<EstoqueModel> {
    // State computed props
    LOAD_LIST: (props: LoadListProps) => void;

    private async beforeMount() {
        await this.setProps(new EstoqueService(), "estoque", new EstoqueModel());
    }

    public async onSucessoSave() {
        this.LOAD_LIST({ loadEstoque: true });
    }
}
