import Vue from "vue";

import checkboxComponent from "@/components/child/form/checkbox.vue";
import comboComponent from "@/components/child/form/combo.vue";
import dateTimePickerComponent from "@/components/child/form/datetimepicker.vue";
import decimalComponent from "@/components/child/form/decimal.vue";
import fieldsetComponent from "@/components/child/form/fieldset.vue";
import moedaComponent from "@/components/child/form/moeda.vue";
import gridComponent from "@/components/child/grid/grid.vue";
import { Component, Prop } from "@/decorators";
import EnumExtensions from "@/models/enum/extensions/enumExtensions";
import StatusConta from "@/models/enum/statusContas";
import TipoMovimentacao from "@/models/enum/tiposMovimentacao";
import ContaCorrenteModel from "@/models/financeiro/contaCorrenteModel";
import ContaGerencialModel from "@/models/financeiro/contaGerencialModel";
import FormaPagamentoModel from "@/models/financeiro/formaPagamentoModel";
import PaginationModel from "@/models/paginationModel";
import ContaCorrenteService from "@/services/financeiro/contaCorrenteService";
import ContaGerencialService from "@/services/financeiro/contaGerencialService";
import FormaPagamentoService from "@/services/financeiro/formaPagamentoService";

import "../crud/crud.scss";

@Component({
    components: {
        gridComponent,
        comboComponent,
        checkboxComponent,
        moedaComponent,
        decimalComponent,
        fieldsetComponent,
        dateTimePickerComponent,
    },
})
export default class FinaceiroListComponent extends Vue {
    statusContasOptions: Array<Object> = EnumExtensions.getNamesAndValuesOrderedByNames(StatusConta).filter(
        p => p["value"] != StatusConta.ParcialmenteLiquidado,
    );
    contasGerenciaisOptions: Array<Object> = [];
    formaPagamentoOptions: Array<Object> = [];
    contaCorrenteOptions: Array<Object> = [];

    @Prop({ type: Object, required: true }) model;
    @Prop({ type: Boolean, default: false }) ShowAddOptions: boolean;

    private async loadContasGerenciais() {
        try {
            const data = await new ContaGerencialService()
                .listByTipoMovimentacao("", 1, 999999, TipoMovimentacao.Saida)
                .then(response => response.json() as Promise<PaginationModel<ContaGerencialModel>>);

            this.contasGerenciaisOptions = data.list.map(item => {
                return {
                    value: item.id,
                    text: item.descricao,
                };
            });
        } catch {}
    }

    private async loadFormasPagamento() {
        try {
            const data = await new FormaPagamentoService()
                .combo()
                .then(response => response.json() as Promise<PaginationModel<FormaPagamentoModel>>);

            this.formaPagamentoOptions = data.list.map(item => {
                return {
                    value: item.id,
                    text: item.descricao,
                };
            });
        } catch {}
    }

    private async loadContaCorrente() {
        try {
            const data = await new ContaCorrenteService()
                .combo()
                .then(response => response.json() as Promise<PaginationModel<ContaCorrenteModel>>);

            this.contaCorrenteOptions = data.list.map(item => {
                return {
                    value: item.id,
                    text: item.descricao,
                };
            });
        } catch {}
    }

    public async validate(): Promise<boolean> {
        return await this.$validator.validateAll();
    }

    private mounted() {
        this.loadContasGerenciais();
        this.loadFormasPagamento();
        this.loadContaCorrente();
    }
}
