import checkboxComponent from "@/components/child/form/checkbox.vue";
import comboComponent from "@/components/child/form/combo.vue";
import { Component } from "@/decorators";
import MotivoEtapaPCPModel from "@/models/pcp/motivoEtapaPCPModel";
import MotivoEtapaPCPService from "@/services/motivoEtapaPCPService";

import EditComponentBase from "../editComponentBase";

@Component({
    components: {
        checkboxComponent,
        comboComponent,
    },
})
export default class MotivoEtapaEditComponent extends EditComponentBase<MotivoEtapaPCPModel> {
    private async beforeMount() {
        await this.setProps(new MotivoEtapaPCPService(), "motivo_etapa_pcp", new MotivoEtapaPCPModel());
    }
}
