declare global {
    interface Promise<T> {
        /** Adiciona um timeout em segundos que rejeitará a Promise ao expirado. */
        withTimeout(seconds: number): Promise<T>;
    }
}

Promise.prototype.withTimeout = function <T>(this: Promise<T>, seconds: number): Promise<T> {
    return new Promise<T>((resolve, reject) => {
        const timeout = setTimeout(() => reject(new Error("Timeout")), seconds * 1000);
        return this.then(value => {
            clearTimeout(timeout);
            resolve(value);
        }).catch(exception => {
            console.log(exception);
            clearTimeout(timeout);
            reject(exception);
        });
    });
};

export {};
