var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "crud crud-edit" },
    [
      !_vm.hideExtras
        ? _c(
            "actionBarComponent",
            {
              attrs: {
                showSave: !_vm.somenteConsulta,
                slots: 11,
                title: _vm.$t("__.Crud.produtolote.edit_vue_html.loteProdEdit"),
              },
              on: { onSave: _vm.save },
              scopedSlots: _vm._u([
                {
                  key: "buttonSlot1",
                  fn: function (ref) {
                    var open = ref.open
                    return _vm.modelId > 0
                      ? _c(
                          "div",
                          {
                            attrs: {
                              title: _vm.$t(
                                "__.Crud.produtolote.edit_vue_html.movimentosEstoque"
                              ),
                            },
                            on: {
                              click: function ($event) {
                                _vm.onShowMovimentosEstoque(open)
                              },
                            },
                          },
                          [
                            _c("i", {
                              staticClass: "fa fa-archive",
                              attrs: { "aria-hidden": "true" },
                            }),
                          ]
                        )
                      : _vm._e()
                  },
                },
                {
                  key: "buttonSlot9",
                  fn: function (ref) {
                    var open = ref.open
                    return _vm.modelId
                      ? _c(
                          "div",
                          {
                            attrs: {
                              title: _vm.$t("__.ts.historicoAlteracoes"),
                            },
                            on: {
                              click: function ($event) {
                                open(
                                  9,
                                  _vm.$t("__.ts.historicoAlteracoes"),
                                  "lg-modal"
                                )
                              },
                            },
                          },
                          [
                            _c("i", {
                              staticClass: "fa fa-history",
                              attrs: { slot: "button", "aria-hidden": "true" },
                              slot: "button",
                            }),
                          ]
                        )
                      : _vm._e()
                  },
                },
              ]),
            },
            [
              _vm.modelId > 0
                ? _c(
                    "div",
                    {
                      attrs: {
                        slot: "buttonSlot0",
                        title: _vm.$t(
                          "__.Crud.produtolote.edit_vue_html.estoqueComprometido"
                        ),
                      },
                      on: { click: _vm.onShowEstoqueComprometido },
                      slot: "buttonSlot0",
                    },
                    [
                      _c("i", {
                        staticClass: "fa fa-list",
                        attrs: { "aria-hidden": "true" },
                      }),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.modelId > 0
                ? _c(
                    "div",
                    {
                      attrs: {
                        slot: "buttonSlot2",
                        title: _vm.$t(
                          "__.Crud.produtolote.edit_vue_html.imprimirRotulo"
                        ),
                      },
                      on: { click: _vm.imprimirRotulo },
                      slot: "buttonSlot2",
                    },
                    [
                      _c("i", {
                        staticClass: "fa fa-tag",
                        attrs: { "aria-hidden": "true" },
                      }),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.validarVisibilidadeBotoes("remover")
                ? _c(
                    "div",
                    {
                      attrs: {
                        slot: "buttonSlot4",
                        title: _vm.$t("__.Crud.excluir"),
                      },
                      on: { click: _vm.removeItem },
                      slot: "buttonSlot4",
                    },
                    [
                      _c("i", {
                        staticClass: "fa fa-times",
                        attrs: { "aria-hidden": "true" },
                      }),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.isFracionamento &&
              _vm.model.getPermiteImprimirOrderFracionamento()
                ? _c(
                    "div",
                    {
                      attrs: {
                        slot: "buttonSlot5",
                        title: _vm.$t(
                          "__.Crud.produtolote.edit_vue_html.imprimirOrdemFracionamento"
                        ),
                      },
                      on: { click: _vm.onImprimirOrdemFracionamento },
                      slot: "buttonSlot5",
                    },
                    [
                      _c("i", {
                        staticClass: "fa fa-print",
                        attrs: { "aria-hidden": "true" },
                      }),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.isFracionamento && _vm.model.getPermiteFracionar()
                ? _c(
                    "div",
                    {
                      attrs: {
                        slot: "buttonSlot6",
                        title: _vm.$t(
                          "__.Crud.produtolote.edit_vue_html.fracionamento"
                        ),
                      },
                      on: { click: _vm.onFracionar },
                      slot: "buttonSlot6",
                    },
                    [
                      _c("i", {
                        staticClass: "fa fa-box-open",
                        attrs: { "aria-hidden": "true" },
                      }),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.modelId > 0
                ? _c(
                    "div",
                    {
                      attrs: {
                        slot: "buttonSlot7",
                        title: _vm.$t(
                          "__.Crud.produtolote.edit_vue_html.dadosNotaEntradaLote"
                        ),
                      },
                      on: { click: _vm.onShowDadosNotaEntradaLote },
                      slot: "buttonSlot7",
                    },
                    [
                      _c("i", {
                        staticClass: "fa fa-file-invoice",
                        attrs: { "aria-hidden": "true" },
                      }),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.modelId > 0 &&
              _vm.isFracionamento &&
              _vm.isAdminOuSuporte &&
              _vm.model.statusProdutoLote == 7
                ? _c(
                    "div",
                    {
                      attrs: {
                        slot: "buttonSlot8",
                        title: _vm.$t(
                          "__.Crud.produtolote.edit_vue_html.retornarLoteParaFracionamento"
                        ),
                      },
                      on: { click: _vm.onRetornarLoteParaFracionamento },
                      slot: "buttonSlot8",
                    },
                    [
                      _c("i", {
                        staticClass: "fa fa-undo",
                        attrs: { "aria-hidden": "true" },
                      }),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                { attrs: { slot: "modalSlot9" }, slot: "modalSlot9" },
                [
                  _c("logEntidadeComponent", {
                    attrs: { registroId: _vm.modelId, entidade: 3 },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _vm.validarVisibilidadeBotoes("anexarLote")
                ? _c(
                    "div",
                    {
                      attrs: {
                        slot: "buttonSlot10",
                        title: _vm.$t(
                          "__.Crud.produtolote.edit_vue_html.anexarLote"
                        ),
                      },
                      on: { click: _vm.onShowUploadAnexo },
                      slot: "buttonSlot10",
                    },
                    [
                      _c("i", {
                        staticClass: "fa fa-paperclip",
                        attrs: { "aria-hidden": "true" },
                      }),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("div", { attrs: { slot: "modalSlot1" }, slot: "modalSlot1" }, [
                _c(
                  "div",
                  {
                    staticClass: "col-sm-12",
                    staticStyle: { "text-transform": "none" },
                  },
                  [
                    _c("grid-component", {
                      attrs: {
                        data: _vm.movimentosEstoque,
                        columns: _vm.gridColumns,
                        showRemoveItem: false,
                        showEditItem: false,
                        showTableHead: false,
                        showTableFoot: false,
                        showActionColumn: false,
                      },
                      on: { "change-sort": _vm.onChangeSort },
                    }),
                  ],
                  1
                ),
              ]),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "form",
        {
          attrs: { novalidate: "novalidate" },
          on: {
            submit: function ($event) {
              $event.preventDefault()
              _vm.save()
            },
          },
        },
        [
          _c("fieldset", [
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { class: _vm.isFracionamento ? "col-sm-8" : "col-sm-12" },
                [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "tooltip",
                          rawName: "v-tooltip",
                          value: _vm.errors.first("descricao"),
                          expression: "errors.first('descricao')",
                        },
                      ],
                      staticClass: "form-group",
                    },
                    [
                      _c(
                        "label",
                        {
                          staticClass: "control-label",
                          attrs: {
                            for: "descricao",
                            title: _vm.$t("__.obrigatorio"),
                          },
                        },
                        [
                          _c("span", { staticStyle: { color: "red" } }, [
                            _vm._v("*"),
                          ]),
                          _vm._v(
                            "\n                            " +
                              _vm._s(
                                _vm.$t(
                                  "__.Crud.produtolote.edit_vue_html.descricao"
                                )
                              ) +
                              "\n                        "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          { name: "focus", rawName: "v-focus" },
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.model.descricao,
                            expression: "model.descricao",
                          },
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required|max:256",
                            expression: "'required|max:256'",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          autocomplete: "off",
                          type: "text",
                          id: "descricao",
                          name: "descricao",
                          disabled: _vm.somenteConsulta,
                        },
                        domProps: { value: _vm.model.descricao },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.model,
                              "descricao",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]
                  ),
                ]
              ),
              _vm._v(" "),
              _vm.isFracionamento
                ? _c("div", { staticClass: "col-sm-4" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("statusProdutoLote"),
                            expression: "errors.first('statusProdutoLote')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: {
                              for: "descricao",
                              title: _vm.$t("__.obrigatorio"),
                            },
                          },
                          [
                            _c("span", { staticStyle: { color: "red" } }, [
                              _vm._v("*"),
                            ]),
                            _vm._v(
                              "\n                            " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Crud.produtolote.edit_vue_html.statusProdutoLote"
                                  )
                                ) +
                                "\n                        "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("combo-component", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required",
                              expression: "'required'",
                            },
                          ],
                          attrs: {
                            id: "statusProdutoLote",
                            name: "statusProdutoLote",
                            data: _vm.statusProdutoLoteOptions,
                            disabled:
                              !_vm.isAdminOuSuporte &&
                              _vm.model.getNaoPermiteAlterarStatus(),
                          },
                          model: {
                            value: _vm.model.statusProdutoLote,
                            callback: function ($$v) {
                              _vm.$set(_vm.model, "statusProdutoLote", $$v)
                            },
                            expression: "model.statusProdutoLote",
                          },
                        }),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("div", { class: _vm.model.id ? "col-sm-6" : "col-sm-12" }, [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip",
                        value: _vm.errors.first("fornecedorId"),
                        expression: "errors.first('fornecedorId')",
                      },
                    ],
                    staticClass: "form-group",
                  },
                  [
                    _c(
                      "label",
                      {
                        staticClass: "control-label",
                        attrs: {
                          for: "descricao",
                          title: _vm.$t("__.obrigatorio"),
                        },
                      },
                      [
                        _c("span", { staticStyle: { color: "red" } }, [
                          _vm._v("*"),
                        ]),
                        _vm._v(
                          "\n                            " +
                            _vm._s(
                              _vm.$t(
                                "__.Crud.produtolote.edit_vue_html.fornecedor"
                              )
                            ) +
                            "\n                        "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("combo-component", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required",
                          expression: "'required'",
                        },
                      ],
                      attrs: {
                        id: "fornecedorId",
                        name: "fornecedorId",
                        data: _vm.fornecedoresOptions,
                        disabled: _vm.somenteConsulta,
                      },
                      model: {
                        value: _vm.model.fornecedorId,
                        callback: function ($$v) {
                          _vm.$set(_vm.model, "fornecedorId", $$v)
                        },
                        expression: "model.fornecedorId",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _vm.model.id
                ? _c("div", { staticClass: "col-sm-3" }, [
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "origemProdutoLote" },
                          },
                          [
                            _vm._v(
                              "\n                            " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Crud.produtolote.edit_vue_html.origemProdutoLote"
                                  )
                                ) +
                                "\n                        "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("combo-component", {
                          attrs: {
                            id: "origemProdutoLote",
                            name: "origemProdutoLote",
                            data: _vm.origemProdutoLoteOptions,
                            disabled: !_vm.isAdminOuSuporte,
                          },
                          model: {
                            value: _vm.model.origemProdutoLote,
                            callback: function ($$v) {
                              _vm.$set(_vm.model, "origemProdutoLote", $$v)
                            },
                            expression: "model.origemProdutoLote",
                          },
                        }),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.isFracionamento && _vm.model.id
                ? _c("div", { staticClass: "col-sm-3" }, [
                    _c("div", { staticClass: "form-group" }, [
                      _c(
                        "label",
                        {
                          staticClass: "control-label",
                          attrs: { for: "descricao" },
                        },
                        [
                          _vm._v(
                            "\n                            " +
                              _vm._s(
                                _vm.$t(
                                  "__.Crud.produtolote.edit_vue_html.descricaoFornecedor"
                                )
                              ) +
                              "\n                        "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.model.descricaoFornecedor,
                            expression: "model.descricaoFornecedor",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          id: "descricao",
                          name: "descricao",
                        },
                        domProps: { value: _vm.model.descricaoFornecedor },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.model,
                              "descricaoFornecedor",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                  ])
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("div", { class: !_vm.model.id ? "col-sm-12" : "col-sm-6" }, [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip",
                        value: _vm.errors.first("produtoId"),
                        expression: "errors.first('produtoId')",
                      },
                    ],
                    staticClass: "form-group",
                  },
                  [
                    _c(
                      "label",
                      {
                        staticClass: "control-label",
                        attrs: {
                          for: "produtoId",
                          title: _vm.$t("__.obrigatorio"),
                        },
                      },
                      [
                        _c("span", { staticStyle: { color: "red" } }, [
                          _vm._v("*"),
                        ]),
                        _vm._v(
                          "\n                            " +
                            _vm._s(
                              _vm.$t(
                                "__.Crud.produtolote.edit_vue_html.produto"
                              )
                            ) +
                            "\n                        "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("combo-component", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required",
                          expression: "'required'",
                        },
                      ],
                      attrs: {
                        id: "produtoId",
                        name: "produtoId",
                        data: _vm.produtosOptions,
                        disabled: _vm.model.id > 0 || _vm.somenteConsulta,
                        searchable: _vm.model.id == null,
                        customView: true,
                        refresh: _vm.loadProdutos,
                      },
                      model: {
                        value: _vm.model.produtoId,
                        callback: function ($$v) {
                          _vm.$set(_vm.model, "produtoId", $$v)
                        },
                        expression: "model.produtoId",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.model.id > 0,
                      expression: "model.id > 0",
                    },
                  ],
                  staticClass: "col-sm-3",
                },
                [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "tooltip",
                          rawName: "v-tooltip",
                          value: _vm.errors.first("quantidadeAtual"),
                          expression: "errors.first('quantidadeAtual')",
                        },
                      ],
                      staticClass: "form-group",
                    },
                    [
                      _c(
                        "label",
                        {
                          staticClass: "control-label",
                          attrs: { for: "quantidadeAtual" },
                        },
                        [
                          _vm._v(
                            "\n                            " +
                              _vm._s(
                                _vm.$t(
                                  "__.Crud.produtolote.edit_vue_html.qtdAtual"
                                )
                              ) +
                              "\n                        "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("decimalComSinalCustomComponent", {
                        staticClass: "form-control",
                        attrs: {
                          id: "quantidadeAtual",
                          name: "quantidadeAtual",
                          disabled: "",
                          signal: _vm.model.unidadeMedidaSigla
                            ? _vm.model.unidadeMedidaSigla
                            : "",
                        },
                        model: {
                          value: _vm.model.quantidadeAtual,
                          callback: function ($$v) {
                            _vm.$set(_vm.model, "quantidadeAtual", $$v)
                          },
                          expression: "model.quantidadeAtual",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.model.id > 0,
                      expression: "model.id > 0",
                    },
                  ],
                  staticClass: "col-sm-3",
                },
                [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "tooltip",
                          rawName: "v-tooltip",
                          value: _vm.errors.first("quantidadeComprometida"),
                          expression: "errors.first('quantidadeComprometida')",
                        },
                      ],
                      staticClass: "form-group",
                    },
                    [
                      _c(
                        "label",
                        {
                          staticClass: "control-label",
                          attrs: { for: "quantidadeComprometida" },
                        },
                        [
                          _vm._v(
                            "\n                            " +
                              _vm._s(
                                _vm.$t(
                                  "__.Crud.produtolote.edit_vue_html.quantidadeComprometida"
                                )
                              ) +
                              "\n                        "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("decimalComSinalCustomComponent", {
                        staticClass: "form-control",
                        attrs: {
                          id: "quantidadeComprometida",
                          name: "quantidadeComprometida",
                          disabled: "",
                          signal: _vm.model.unidadeMedidaSigla
                            ? _vm.model.unidadeMedidaSigla
                            : "",
                        },
                        model: {
                          value: _vm.quantidadeComprometida,
                          callback: function ($$v) {
                            _vm.quantidadeComprometida = $$v
                          },
                          expression: "quantidadeComprometida",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { class: _vm.model.id != null ? "col-sm-5" : "col-sm-6" },
                [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "tooltip",
                          rawName: "v-tooltip",
                          value: _vm.errors.first("dataFabricacao"),
                          expression: "errors.first('dataFabricacao')",
                        },
                      ],
                      staticClass: "form-group",
                    },
                    [
                      _c(
                        "label",
                        {
                          staticClass: "control-label",
                          attrs: {
                            for: "dataFabricacao",
                            title: _vm.$t("__.obrigatorio"),
                          },
                        },
                        [
                          _c("span", { staticStyle: { color: "red" } }, [
                            _vm._v("*"),
                          ]),
                          _vm._v(
                            "\n                            " +
                              _vm._s(
                                _vm.$t(
                                  "__.Crud.produtolote.edit_vue_html.dataFab"
                                )
                              ) +
                              "\n                        "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("date-time-picker-component", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required",
                            expression: "'required'",
                          },
                        ],
                        attrs: {
                          id: "dataFabricacao",
                          name: "dataFabricacao",
                          disabled: _vm.somenteConsulta,
                        },
                        model: {
                          value: _vm.model.dataFabricacao,
                          callback: function ($$v) {
                            _vm.$set(_vm.model, "dataFabricacao", $$v)
                          },
                          expression: "model.dataFabricacao",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { class: _vm.model.id != null ? "col-sm-5" : "col-sm-6" },
                [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "tooltip",
                          rawName: "v-tooltip",
                          value: _vm.errors.first("dataValidade"),
                          expression: "errors.first('dataValidade')",
                        },
                      ],
                      staticClass: "form-group",
                    },
                    [
                      _c(
                        "label",
                        {
                          staticClass: "control-label",
                          attrs: {
                            for: "dataValidade",
                            title: _vm.$t("__.obrigatorio"),
                          },
                        },
                        [
                          _c("span", { staticStyle: { color: "red" } }, [
                            _vm._v("*"),
                          ]),
                          _vm._v(
                            "\n                            " +
                              _vm._s(
                                _vm.$t(
                                  "__.Crud.produtolote.edit_vue_html.dataVal"
                                )
                              ) +
                              "\n                        "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("date-time-picker-component", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required",
                            expression: "'required'",
                          },
                        ],
                        attrs: {
                          id: "dataValidade",
                          name: "dataValidade",
                          disabled: _vm.somenteConsulta,
                        },
                        model: {
                          value: _vm.model.dataValidade,
                          callback: function ($$v) {
                            _vm.$set(_vm.model, "dataValidade", $$v)
                          },
                          expression: "model.dataValidade",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _vm.model.id != null
                ? _c("div", { staticClass: "col-sm-2" }, [
                    _c("div", { staticClass: "form-group" }, [
                      _c(
                        "label",
                        {
                          staticClass: "control-label",
                          attrs: { for: "sequencialCompra" },
                        },
                        [
                          _vm._v(
                            "\n                            " +
                              _vm._s(
                                _vm.$t(
                                  "__.Crud.produtolote.edit_vue_html.sequencialCompra"
                                )
                              ) +
                              "\n                        "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.model.sequencialCompra,
                            expression: "model.sequencialCompra",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          id: "sequencialCompra",
                          name: "sequencialCompra",
                          disabled: "",
                        },
                        domProps: { value: _vm.model.sequencialCompra },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.model,
                              "sequencialCompra",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                  ])
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-sm-2" }, [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip",
                        value: _vm.errors.first("fatorCorrecao"),
                        expression: "errors.first('fatorCorrecao')",
                      },
                    ],
                    staticClass: "form-group",
                  },
                  [
                    _c(
                      "label",
                      {
                        staticClass: "control-label",
                        attrs: { for: "fatorCorrecao" },
                      },
                      [
                        _vm._v(
                          "\n                            " +
                            _vm._s(
                              _vm.$t(
                                "__.Crud.produtolote.edit_vue_html.ftrCorrecao"
                              )
                            ) +
                            "\n                        "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("data-tooltip-component", {
                      attrs: {
                        text: _vm.$t(
                          "__.Crud.produtolote.edit_vue_html.campoNull"
                        ),
                      },
                    }),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.model.fatorCorrecao,
                          expression: "model.fatorCorrecao",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: {
                        id: "fatorCorrecao",
                        name: "fatorCorrecao",
                        type: "number",
                        disabled: _vm.somenteConsulta,
                      },
                      domProps: { value: _vm.model.fatorCorrecao },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.model,
                            "fatorCorrecao",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-sm-2" }, [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip",
                        value: _vm.errors.first("densidade"),
                        expression: "errors.first('densidade')",
                      },
                    ],
                    staticClass: "form-group",
                  },
                  [
                    _c(
                      "label",
                      {
                        staticClass: "control-label",
                        attrs: { for: "densidade" },
                      },
                      [
                        _vm._v(
                          "\n                            " +
                            _vm._s(
                              _vm.$t(
                                "__.Crud.produtolote.edit_vue_html.densidadeGml"
                              )
                            ) +
                            "\n                        "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("data-tooltip-component", {
                      attrs: {
                        text: _vm.$t(
                          "__.Crud.produtolote.edit_vue_html.campoNull"
                        ),
                      },
                    }),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.model.densidade,
                          expression: "model.densidade",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: {
                        id: "densidade",
                        name: "densidade",
                        type: "number",
                        disabled: _vm.somenteConsulta,
                      },
                      domProps: { value: _vm.model.densidade },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.model, "densidade", $event.target.value)
                        },
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _vm.showFinanceiro
                ? _c("div", { staticClass: "col-sm-2" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("valorCusto"),
                            expression: "errors.first('valorCusto')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "valorCusto" },
                          },
                          [
                            _vm._v(
                              "\n                            " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Crud.produtolote.edit_vue_html.vlrCusto"
                                  )
                                ) +
                                "\n                        "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("moeda-component", {
                          directives: [
                            {
                              name: "mask-moeda-decimal",
                              rawName: "v-mask-moeda-decimal",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            id: "valorCusto",
                            name: "valorCusto",
                            disabled: _vm.somenteConsulta,
                          },
                          model: {
                            value: _vm.model.valorCusto,
                            callback: function ($$v) {
                              _vm.$set(_vm.model, "valorCusto", $$v)
                            },
                            expression: "model.valorCusto",
                          },
                        }),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "col-sm-2" }, [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip",
                        value: _vm.errors.first("fatorUI"),
                        expression: "errors.first('fatorUI')",
                      },
                    ],
                    staticClass: "form-group",
                  },
                  [
                    _c(
                      "label",
                      {
                        staticClass: "control-label",
                        attrs: { for: "fatorUI" },
                      },
                      [
                        _vm._v(
                          "\n                            " +
                            _vm._s(
                              _vm.$t(
                                "__.Crud.produtolote.edit_vue_html.fatorUI"
                              )
                            ) +
                            "\n                        "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("data-tooltip-component", {
                      attrs: {
                        text: _vm.$t(
                          "__.Crud.produtolote.edit_vue_html.campoNull"
                        ),
                      },
                    }),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.model.fatorUI,
                          expression: "model.fatorUI",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: {
                        id: "fatorUI",
                        name: "fatorUI",
                        type: "number",
                        disabled: _vm.somenteConsulta,
                      },
                      domProps: { value: _vm.model.fatorUI },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.model, "fatorUI", $event.target.value)
                        },
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-sm-2" }, [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip",
                        value: _vm.errors.first("fatorUFC"),
                        expression: "errors.first('fatorUFC')",
                      },
                    ],
                    staticClass: "form-group",
                  },
                  [
                    _c(
                      "label",
                      {
                        staticClass: "control-label",
                        attrs: { for: "fatorUFC" },
                      },
                      [
                        _vm._v(
                          "\n                            " +
                            _vm._s(
                              _vm.$t(
                                "__.Crud.produtolote.edit_vue_html.fatorUFC"
                              )
                            ) +
                            "\n                        "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("data-tooltip-component", {
                      attrs: {
                        text: _vm.$t(
                          "__.Crud.produtolote.edit_vue_html.campoNull"
                        ),
                      },
                    }),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.model.fatorUFC,
                          expression: "model.fatorUFC",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: {
                        id: "fatorUFC",
                        name: "fatorUFC",
                        type: "number",
                        disabled: _vm.somenteConsulta,
                      },
                      domProps: { value: _vm.model.fatorUFC },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.model, "fatorUFC", $event.target.value)
                        },
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-sm-2" }, [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip",
                        value: _vm.errors.first("fatorUTR"),
                        expression: "errors.first('fatorUTR')",
                      },
                    ],
                    staticClass: "form-group",
                  },
                  [
                    _c(
                      "label",
                      {
                        staticClass: "control-label",
                        attrs: { for: "fatorUTR" },
                      },
                      [
                        _vm._v(
                          "\n                            " +
                            _vm._s(
                              _vm.$t(
                                "__.Crud.produtolote.edit_vue_html.fatorUTR"
                              )
                            ) +
                            "\n                        "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("data-tooltip-component", {
                      attrs: {
                        text: _vm.$t(
                          "__.Crud.produtolote.edit_vue_html.campoNull"
                        ),
                      },
                    }),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.model.fatorUTR,
                          expression: "model.fatorUTR",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: {
                        id: "fatorUI",
                        name: "fatorUTR",
                        type: "number",
                        disabled: _vm.somenteConsulta,
                      },
                      domProps: { value: _vm.model.fatorUTR },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.model, "fatorUTR", $event.target.value)
                        },
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-sm-12" }, [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip",
                        value: _vm.errors.first("observacao"),
                        expression: "errors.first('observacao')",
                      },
                    ],
                    staticClass: "form-group",
                  },
                  [
                    _c(
                      "label",
                      {
                        staticClass: "control-label",
                        attrs: { for: "observacao" },
                      },
                      [
                        _vm._v(
                          "\n                            " +
                            _vm._s(
                              _vm.$t("__.Crud.produtolote.edit_vue_html.obs")
                            ) +
                            "\n                        "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("textarea-component", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "max:1024",
                          expression: "'max:1024'",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: {
                        id: "observacao",
                        name: "observacao",
                        disabled: _vm.somenteConsulta,
                      },
                      model: {
                        value: _vm.model.observacao,
                        callback: function ($$v) {
                          _vm.$set(_vm.model, "observacao", $$v)
                        },
                        expression: "model.observacao",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-sm-12" }, [
                _c("div", { staticClass: "form-group" }, [
                  _c(
                    "label",
                    {
                      staticClass: "control-label",
                      attrs: { for: "conservacao" },
                    },
                    [
                      _vm._v(
                        "\n                            " +
                          _vm._s(
                            _vm.$t(
                              "__.Crud.produtolote.edit_vue_html.conservacao"
                            )
                          ) +
                          "\n                        "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("textarea", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.model.conservacao,
                        expression: "model.conservacao",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: {
                      autocomplete: "off",
                      type: "text",
                      id: "conservacao",
                      rows: 5,
                      name: "conservacao",
                      disabled: _vm.somenteConsulta,
                    },
                    domProps: { value: _vm.model.conservacao },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.model, "conservacao", $event.target.value)
                      },
                    },
                  }),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _vm.CalledByShortCut
              ? _c(
                  "button",
                  {
                    staticClass: "btn btnCopiar pull-right",
                    staticStyle: { "margin-top": "28px !important" },
                    attrs: { type: "button" },
                    on: { click: _vm.onloadLote },
                  },
                  [
                    _c("i", {
                      staticClass: "fa fa-edit",
                      staticStyle: {
                        "font-size": "16px !important",
                        "margin-right": "3px",
                      },
                      attrs: { "aria-hidden": "true" },
                    }),
                    _vm._v(
                      "\n                " +
                        _vm._s(
                          _vm.$t(
                            "__.Crud.produtolote.edit_vue_html.preenchLote"
                          )
                        ) +
                        "\n            "
                    ),
                  ]
                )
              : _vm._e(),
          ]),
          _vm._v(" "),
          _vm.model.id
            ? _c(
                "fieldset-component",
                {
                  attrs: {
                    title: _vm.$t("__.Crud.produtolote.edit_vue_html.estoques"),
                    collapsed: false,
                  },
                },
                [
                  _c(
                    "div",
                    { attrs: { slot: "rows" }, slot: "rows" },
                    [
                      _c("grid-component", {
                        attrs: {
                          data: _vm.model.estoqueProdutoLote,
                          columns: _vm.gridColumnsEstoqueProdutoLotes,
                          showRemoveItem: false,
                          showEditItem: false,
                          showTableHead: false,
                          showTableFoot: false,
                          showSortIcons: false,
                          showActionColumn: false,
                        },
                      }),
                    ],
                    1
                  ),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.model.id &&
          _vm.model.produtoLotesFracionados &&
          _vm.model.origemProdutoLote == _vm.origemProdutoLote.Interno &&
          _vm.model.statusProdutoLote == _vm.statusProdutoLote.Liberado
            ? _c(
                "fieldset-component",
                {
                  attrs: {
                    title: _vm.$t(
                      "__.Crud.produtolote.edit_vue_html.lotesFracionados"
                    ),
                    collapsed: false,
                  },
                },
                [
                  _c(
                    "div",
                    { attrs: { slot: "rows" }, slot: "rows" },
                    [
                      _c("grid-component", {
                        attrs: {
                          data: _vm.model.produtoLotesFracionados,
                          columns: _vm.gridColumnsProdutoLotesFracionados,
                          showRemoveItem: false,
                          showEditItem: false,
                          showTableHead: false,
                          showTableFoot: false,
                          showSortIcons: false,
                          showActionColumn: false,
                        },
                      }),
                    ],
                    1
                  ),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.model.id
            ? _c("infoBagdeComponent", { attrs: { text: _vm.infoBagdeText } })
            : _vm._e(),
          _vm._v(" "),
          _c("fieldset", [
            !_vm.hideExtras
              ? _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-sm-12 newbtn" }, [
                    _c("div", { staticClass: "form-group" }, [
                      !_vm.somenteConsulta
                        ? _c(
                            "button",
                            {
                              staticClass: "btn btn-success",
                              attrs: { type: "submit" },
                            },
                            [
                              _c("i", {
                                staticClass: "fa fa-save",
                                attrs: { "aria-hidden": "true" },
                              }),
                              _vm._v(
                                "\n                            " +
                                  _vm._s(_vm.$t("__.Crud.salvar")) +
                                  "\n                        "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.somenteConsulta
                        ? _c(
                            "button",
                            {
                              staticClass: "btn btn-secondary",
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  _vm.load()
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(_vm.$t("__.Crud.limpar")) +
                                  "\n                        "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-secondary",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              _vm.cancel()
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n                            " +
                              _vm._s(_vm.$t("__.Crud.voltar")) +
                              "\n                        "
                          ),
                        ]
                      ),
                    ]),
                  ]),
                ])
              : _vm._e(),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _vm.showEstoqueComprometido
        ? _c(
            "shortcutComponent",
            {
              ref: "shortcutComponent",
              attrs: { showBtnOk: false, modalClass: "lg-modal" },
            },
            [
              _c(
                "div",
                { attrs: { slot: "component" }, slot: "component" },
                [
                  _c("estoque-comprometido-component", {
                    attrs: { loteId: _vm.model.id, itemFracionamentoId: null },
                  }),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("uploadAnexoComponent", {
        ref: "uploadAnexoComponent",
        attrs: { id: _vm.model.id, serviceUpload: "ProdutoLoteAnexo" },
      }),
      _vm._v(" "),
      _c("impressaoComponent", { ref: "impressaoComponent" }),
      _vm._v(" "),
      _c("dadosNotaEntradaLoteComponent", {
        ref: "dadosNotaEntradaLoteComponent",
      }),
      _vm._v(" "),
      _c("fracionamentoComponent", {
        ref: "fracionamentoComponent",
        on: { onSucessoFracionamento: _vm.cancel },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }