import Vue from "vue";
import { mapState, mapMutations, mapGetters } from "vuex";

import { Component, Watch } from "@/decorators";
import CountPackModel from "@/models/auxiliar/countPackModel";
import UsuarioModel from "@/models/usuarioModel";
import { AppState, Getters } from "@/store/store";

import FastActionContinuosComponent from "./continuos/continuos";
import fastActionContinuosComponent from "./continuos/continuos.vue";
import fastActionEntregasComponent from "./entregas/entregas.vue";
import fastActionEstoqueFracionamentoComponent from "./estoqueFracionamento/estoqueFracionamento.vue";
import fastActionExpedicaoComponent from "./expedicao/expedicao.vue";
import fastActionFinanceiroPanelComponent from "./financeiro/financeiro.vue";
import fastActionFracionamentoComponent from "./fracionamento/fracionamento.vue";
import fastActionManipulacaoPanelComponent from "./manipulacao/manipulacao.vue";
import fastActionOrcamentosPanelComponent from "./orcamentos/orcamentos.vue";
import fastActionProdutosPanelComponent from "./produtos/produtos.vue";
import fastActionVendasPanelComponent from "./vendas/vendas.vue";

import "./fastaction.scss";

@Component({
    components: {
        fastActionVendasPanelComponent,
        fastActionOrcamentosPanelComponent,
        fastActionFinanceiroPanelComponent,
        fastActionProdutosPanelComponent,
        fastActionManipulacaoPanelComponent,
        fastActionContinuosComponent,
        fastActionEntregasComponent,
        fastActionFracionamentoComponent,
        fastActionEstoqueFracionamentoComponent,
        fastActionExpedicaoComponent,
    },
    computed: {
        ...mapState<AppState>({
            fastActionMenuPos: state => state.fastAction.fastActionMenuPos,
            fastActionPack: state => state.fastAction.fastActionPack,
            usuarioLogado: state => state.session.usuarioLogado,
        }),
        ...mapGetters(["GET_IS_FRACIONAMENTO", "VALIDAR_PERMISSAO_USUARIO"] as Getters),
    },
    methods: mapMutations(["SET_FASTACTIONMENUPOS", "LOAD_COUNTPACK", "CLEAR_LISTSPACK"]),
})
export default class FastActionComponent extends Vue {
    // State computed props
    usuarioLogado: UsuarioModel;
    GET_IS_FRACIONAMENTO: () => Promise<boolean>;
    VALIDAR_PERMISSAO_USUARIO: (telaDescricao: string, acaoController: string) => boolean;

    selectedMenu = "";

    private showContinuos = false;
    private showEntregas = false;
    private showManipulacoes = false;
    private showOrcamentos = false;
    private showVendas = false; // iniciado aqui
    private showFinanceiro = false;
    private showProdutos = false;
    private showEstoqueFracionamento = false;
    private showExpedicao = false;
    private showFracionamento = false;

    private isFracionamento = false;

    private countDiff: CountPackModel = null;

    private fastActionContinuosComponent: FastActionContinuosComponent = null;

    constructor() {
        super();
    }

    private showMenu(menu: string) {
        if (this.selectedMenu != menu) {
            this.selectedMenu = menu;
        }
        this.setShowMenu(menu);
        this["SET_FASTACTIONMENUPOS"](menu);
    }

    private setShowMenu(menu: string) {
        //seta true nas variaveis para carregar so quando clicado a primeira vez
        switch (menu.trim().toLowerCase()) {
            case "orcamentos":
                this.showOrcamentos = true;
                if (this.countDiff != null) {
                    this.countDiff.orcamentos = 0;
                }
                break;
            case "manipulacao":
                this.showManipulacoes = true;
                if (this.countDiff != null) {
                    this.countDiff.manipulacoesAConcluir = 0;
                }
                break;
            case "financeiro":
                this.showFinanceiro = true;
                break;
            case "produtos":
                this.showProdutos = true;
                if (this.countDiff != null) {
                    this.countDiff.estoqueDivergencias = 0;
                }
                break;
            case "vendas":
                this.showVendas = true;
                if (this.countDiff != null) {
                    this.countDiff.vendasAFaturar = 0;
                }
                break;
            case "continuos":
                this.showContinuos = true;
                if (this.countDiff != null) {
                    this.countDiff.continuos = 0;
                }
                break;
            case "entregas":
                this.showEntregas = true;
                if (this.countDiff != null) {
                    this.countDiff.entregas = 0;
                }
                break;
            case "estoquefracionamento":
                this.showEstoqueFracionamento = true;
                if (this.countDiff != null) {
                    this.countDiff.estoqueFracionamento = 0;
                }
                break;
            case "fracionamento":
                this.showFracionamento = true;
                if (this.countDiff != null) {
                    this.countDiff.fracionamento = 0;
                }
                break;
            case "expedicao":
                this.showExpedicao = true;
                if (this.countDiff != null) {
                    this.countDiff.expedicao = 0;
                }
                break;
        }
    }

    private atualizaDiffCounts() {
        const lastPack = this["fastActionPack"].lastCountPack as CountPackModel;
        this.countDiff = new CountPackModel();
        this.countDiff.updateFrom(this["fastActionPack"].countPack as CountPackModel);

        this.countDiff.orcamentos -= lastPack.orcamentos;
        this.countDiff.estoqueDivergencias -= lastPack.estoqueDivergencias;
        this.countDiff.continuos -= lastPack.continuos;
        this.countDiff.manipulacoesAConcluir -= lastPack.manipulacoesAConcluir;
        this.countDiff.vendasAFaturar -= lastPack.vendasAFaturar;
        this.countDiff.estoqueFracionamento -= lastPack.estoqueFracionamento;
        this.countDiff.expedicao -= lastPack.expedicao;
        this.countDiff.fracionamento -= lastPack.fracionamento;
    }
    //@ts-ignore
    @Watch("fastActionPack.countPack")
    private onChangeFastActionPackCount() {
        //depois colocar as difs aqui
        this.atualizaDiffCounts();
    }
    //passar tratamento individual depois
    //@ts-ignore
    @Watch("fastActionPack.lastCountPack.orcamentos")
    private onChangeFastActionPackLastCountOrc() {
        this.atualizaDiffCounts();
    }
    //@ts-ignore
    @Watch("fastActionPack.lastCountPack.estoqueDivergencias")
    private onChangeFastActionPackLastCountEst() {
        this.atualizaDiffCounts();
    }
    //@ts-ignore
    @Watch("fastActionPack.lastCountPack.manipulacoesAConcluir")
    private onChangeFastActionPackLastCountMan() {
        this.atualizaDiffCounts();
    }
    //@ts-ignore
    @Watch("fastActionPack.lastCountPack.vendasAFaturar")
    private onChangeFastActionPackLastCountVen() {
        this.atualizaDiffCounts();
    }
    //@ts-ignore
    @Watch("fastActionPack.lastCountPack.continuos")
    private onChangeFastActionPackLastCountCon() {
        this.atualizaDiffCounts();
    }
    //@ts-ignore
    @Watch("fastActionPack.lastCountPack.estoqueFracionamento")
    private onChangeFastActionPackLastCountFra() {
        this.atualizaDiffCounts();
    }
    //@ts-ignore
    @Watch("fastActionPack.lastCountPack.expedicao")
    //@ts-ignore
    @Watch("fastActionPack.lastCountPack.fracionamento")
    private onChangeFastActionPackLastCountExp() {
        this.atualizaDiffCounts();
    }

    private async loadPermissao() {
        this.isFracionamento = await this.GET_IS_FRACIONAMENTO();

        if (this.isFracionamento) {
            setTimeout(() => {
                this.selectedMenu = "vendas";
                this.setShowMenu(this.selectedMenu);
            }, 1000);

            const root = document.documentElement;
            root.style.setProperty("--largura", "125px");
        }
    }

    private mounted() {
        Promise.all([this.loadPermissao(), this["LOAD_COUNTPACK"](), this["CLEAR_LISTSPACK"]()])
            .then(() => {
                this.selectedMenu = this["fastActionMenuPos"];
                this.setShowMenu(this.selectedMenu);
                this.atualizaDiffCounts();

                if (!this.VALIDAR_PERMISSAO_USUARIO("financeiro", "")) {
                    this.selectedMenu = "manipulacao";
                    this.setShowMenu(this.selectedMenu);
                }
            })
            .catch(() => {});
    }
}
