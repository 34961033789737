import BaseModel from "../base/baseModel";
import GrupoProdutoModel from "../produto/grupoProdutoModel";

export default class CampanhaGrupoProdutoModel extends BaseModel {
    id?: number = null;
    grupoProdutoId?: number = null;
    grupoProduto: GrupoProdutoModel = null;
    grupoProdutoDescricao?: string = null;
    subGrupo?: number = null;
    subGrupoDescricao?: string = null;
}
