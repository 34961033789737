var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "custom-dropdown" }, [
    _c(
      "div",
      { staticClass: "custom-dropdown-button", on: { click: _vm.onOpen } },
      [_vm._t("button")],
      2
    ),
    _vm._v(" "),
    _vm.open
      ? _c(
          "ul",
          {
            class: [
              "custom-dropdown-menu",
              _vm.menuSide == "LEFT" ? "side-left" : "side-right",
              _vm.dropMode == "UP" ? "mode-up" : "",
            ],
            on: { click: _vm.onClose },
          },
          [_vm._t("itens")],
          2
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.open
      ? _c("div", {
          staticClass: "custom-dropdown-overlay",
          on: { click: _vm.onClose },
        })
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }