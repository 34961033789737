import { mapState } from "vuex";

import portletComponent from "@/components/child/containers/portlet.vue";
import checkboxComponent from "@/components/child/form/checkbox.vue";
import comboComponent from "@/components/child/form/combo.vue";
import dateTimePickerComponent from "@/components/child/form/datetimepicker.vue";
import multiSelectComponent from "@/components/child/multiSelect/multiSelect.vue";
import { Component, Watch } from "@/decorators";
import DashboardModel from "@/models/dashboard/dashboardModel";
import Graficos from "@/models/enum/dashboard/graficos";
import FranquiaModel from "@/models/franquiaModel";
import PaginationModel from "@/models/paginationModel";
import UsuarioModel from "@/models/usuarioModel";
import FranquiaService from "@/services/franquiaService";
import { AppState, SessionActions } from "@/store/store";
import { getTipoVendaCombo } from "@/utils/common/combo/combotext";

import DashboardComponent from "./dashboardComponent";

// Precisa ser assim se não ocorre erro
// eslint-disable-next-line @typescript-eslint/no-var-requires
const echarts = require("echarts");

@Component({
    components: {
        portletComponent,
        checkboxComponent,
        dateTimePickerComponent,
        multiSelectComponent,
        comboComponent,
    },
    computed: {
        ...mapState({
            usuarioLogado: (state: AppState) => state.session.usuarioLogado,
        }),
    },
})
export default class Vendas extends DashboardComponent {
    // State computed props
    usuarioLogado: UsuarioModel;

    private graficoVendasVendedorMensal: any;
    private graficoPercVendasPrescritorMensal: any;
    private graficoPercOrigemAtendimento: any;

    franquiaIds: Array<Object> = [];
    defaultFranquia: Array<Object> = [];
    franquiasOptions: Array<Object> = [];
    taxaEntrega = true;
    tipoVenda = 0;

    tipoVendaOptions = getTipoVendaCombo();

    private graphStyle = {
        width: "100%",
        height: "500px",
        margin: "25px 0px 0px 0px",
        // @ts-ignore
        zoom: this.compensarZoomGrafico(document.body.style.zoom),
    };

    private dataFinal = new Date();
    private dataInicial = new Date(
        this.dataFinal.getFullYear(),
        this.dataFinal.getMonth(),
        this.dataFinal.getDate() - 30,
    );

    private watchesHabilitados = false;

    private async mounted() {
        await this.$store.dispatch(SessionActions.LOAD_USUARIO_LOGADO);

        await this.loadFranquias().withLoading();
        this.defaultFranquia.push(this.franquiasOptions.find(p => p["value"] === this.usuarioLogado.franquiaId));

        //@ts-ignore
        this.graficoVendasVendedorMensal = echarts.init(this.$refs.vendasVendedorAnual);
        //@ts-ignore
        this.graficoPercVendasPrescritorMensal = echarts.init(this.$refs.percVendasPrescritorMensal);
        this.graficoPercOrigemAtendimento = echarts.init(this.$refs.percOrigemVendas);

        this.setGraficos([
            this.graficoVendasVendedorMensal,
            this.graficoPercVendasPrescritorMensal,
            this.graficoPercOrigemAtendimento,
        ]);

        this.updateDashboard();
    }

    protected async updateDashboard() {
        if (this.dataInicial > this.dataFinal) {
            this.$showWarning("Atenção", "Data Inicial maior que Data Final");
            return;
        }

        this.showLoadingGraficos();

        const data = await this.service
            .getTotaisPorCategoria(
                this.franquiaIds.map(p => p["value"]),
                this.dataInicial,
                this.dataFinal,
                this.taxaEntrega,
                this.tipoVenda,
            )
            .resolveWithJSON<{
                informacoes: Map<Graficos, DashboardModel[]>;
            }>();

        this.graficoVendasVendedorMensal.setOption(
            this.getOpcoesGrafico({
                titulo: this.$t("__.ts.vendasVendedorAnual") as string,
                labels: data.informacoes["VendasVendedor"].map(d => this.getMonthName(d.label)),
                dados: data.informacoes["VendasVendedor"],
                tipoGrafico: "line",
                format: "<b>{b}</b><br/>{a}<br/>R$ {c}",
                multiSerie: true,
                mostrarLegenda: true,
            }),
        );

        this.graficoPercVendasPrescritorMensal.setOption(
            this.getOpcoesGraficoPie(
                this.$t("__.ts.percVendasPrescritorMensal") as string,
                data.informacoes["PercManipulacoesPrescritorMensal"],
            ),
        );

        this.graficoPercOrigemAtendimento.setOption(
            this.getOpcoesGraficoPie(
                this.$t("__.ts.percOrigemVendas") as string,
                data.informacoes["OrigemAtendimento"],
            ),
        );

        this.hideLoadingGraficos();
        this.watchesHabilitados = true;
    }

    protected convertToString(numero: number) {
        throw new Error("Method not implemented.");
    }

    private async loadFranquias() {
        const data = await new FranquiaService().combo().resolveWithJSON<PaginationModel<FranquiaModel>>();
        this.franquiasOptions = data.list.map(franquia => ({
            value: franquia.id,
            text: franquia.nomeFantasia,
        }));
    }

    private onSelecionarTodas() {
        this.franquiaIds = this.franquiasOptions;
        this.defaultFranquia = this.franquiasOptions;
    }

    private onLimpar() {
        this.franquiaIds = [];
        this.defaultFranquia = [];
    }

    @Watch("franquiaIds")
    private onFranquiaId() {
        if (this.franquiaIds.length > 0) {
            this.updateDashboard();
        }
    }

    @Watch("dataInicial")
    private onDataInicial(newValue, oldValue) {
        if (newValue != oldValue && this.watchesHabilitados) {
            this.updateDashboard();
        }
    }

    @Watch("dataFinal")
    private onDataFinal(newValue, oldValue) {
        if (newValue != oldValue && this.watchesHabilitados) {
            this.updateDashboard();
        }
    }

    @Watch("taxaEntrega")
    @Watch("tipoVenda")
    private onChangeFiltros() {
        this.updateDashboard();
    }
}
