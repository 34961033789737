import Moment from "moment";

export function getFirstName(str: string, entreParenteses: boolean) {
    if (str != null) {
        const strFirstName = str.substr(0, str.indexOf(" ") > 0 ? str.indexOf(" ") : str.length);
        if (strFirstName.trim() != "")
            return (entreParenteses ? "(" : "") + strFirstName.trim() + (entreParenteses ? ")" : "");
        else (entreParenteses ? "(" : "") + str.trim() + (entreParenteses ? ")" : "");
    }
    return "";
}

export function getDateString(dt: Date) {
    return Moment(dt).format("DD/MM/YY");
}

export function getDateHourString(dt: Date) {
    return Moment(dt).format("DD/MM/YY hh:mm");
}
