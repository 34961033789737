import { mapState } from "vuex";

import comboComponent from "@/components/child/form/combo.vue";
import dateTimePickerComponent from "@/components/child/form/datetimepicker.vue";
import { GridAction } from "@/components/child/grid/gridAction";
import { GridColors, GridColumn, GridColumnType } from "@/components/child/grid/gridColumn";
import ImpressaoComponent from "@/components/child/impressao/impressaoComponent";
import impressaoComponent from "@/components/child/impressao/impressaoComponent.vue";
import { Component } from "@/decorators";
import PreLoadPackModel from "@/models/auxiliar/preLoadPackModel";
import StatusColeta from "@/models/enum/statusColeta";
import ColetaModel, { ColetaListParameters } from "@/models/expedicao/coletaModel";
import PaginationModel from "@/models/paginationModel";
import ColetaService from "@/services/expedicao/coletaService";
import { AppState } from "@/store/store";
import { convertToDateWithTimeInputString } from "@/utils/common/date";

import ListComponentBase from "../listComponentBase";

@Component({
    components: {
        impressaoComponent,
        dateTimePickerComponent,
        comboComponent,
    },
    computed: {
        ...mapState({
            preLoadList: (state: AppState) => state.preLoad.preLoadList,
        }),
    },
})
export default class ColetaListComponent extends ListComponentBase<ColetaModel> {
    // State computed props
    preLoadList: PreLoadPackModel;

    private impressaoComponent: ImpressaoComponent = null;
    private coletaService = new ColetaService();

    locaisEntregaOptions: Array<Object> = [];
    filtro: Array<Object> = [];
    isHidden = false;
    dataFilter1: Date = null;
    dataFilter2: Date = null;
    status = StatusColeta.Pendente;
    localEntrega: number = null;

    extraActions: GridAction[][] = [];
    extraActionsImprimir: GridAction[] = [];
    extraActionsColetado: GridAction[] = [];

    get gridColumns(): Array<GridColumn> {
        return [
            new GridColumn("data", this.$t("__.ts.data"), GridColumnType.DateTime),
            new GridColumn("descricao", this.$t("__.ts.descr"), GridColumnType.String),
            new GridColumn("clienteNome", this.$t("__.ts.cliente"), GridColumnType.String),
            new GridColumn("statusDescricao", this.$t("__.ts.status"), GridColumnType.String),
            new GridColumn(
                "vendedorNome",
                this.$t("__.Crud.controleReclamacao.edit_vue_html.vendedor"),
                GridColumnType.String,
            ),
            new GridColumn("localEntregaDescricao", this.$t("__.ts.localEntrega"), GridColumnType.String),
        ];
    }

    statusFiltroOptions: Array<Object> = [
        { text: "Pendente", value: StatusColeta.Pendente },
        { text: "Coletado", value: StatusColeta.Coletado },
    ];

    private beforeMount() {
        this.setProps(new ColetaService(), "coleta", "nome");
        this.locaisEntregaOptions = this.preLoadList.locaisEntregaCombo();
    }

    public async afterLoad() {
        this.extraActions.splice(0);
        this.extraActionsImprimir.splice(0);
        this.extraActionsColetado.splice(0);

        for (let i = 0; i < this.gridData.length; i++) {
            this.extraActionsImprimir.push(
                new GridAction("imprimir", "Imprimir Coleta", "fa fa-print", GridColors.DARKGRAY),
            );
            this.extraActionsColetado.push(
                new GridAction("coletado", "Marcar como coletado", "fa fa-check", GridColors.GREEN),
            );
        }

        this.extraActions.push(this.extraActionsColetado);
        this.extraActions.push(this.extraActionsImprimir);
    }

    private getFormValues(listaFiltro) {
        this.filtro = [];
        this.filtro.push(listaFiltro.target.elements.dataFilter1.value);
        this.filtro.push(listaFiltro.target.elements.dataFilter2.value);

        this.load();
    }

    public async loadMethod() {
        const customParameters: ColetaListParameters = {
            dataInicial: convertToDateWithTimeInputString(this.filtro[0]),
            dataFinal: convertToDateWithTimeInputString(this.filtro[1]),
            status: this.status,
            localEntregaId: this.localEntrega,
        };

        return await this.coletaService
            .listColeta(
                this.gridFilterKey,
                this.gridSortKey,
                this.gridSortOrder,
                this.pags ? 1 : this.pageIndex,
                this.pags ? 999999 : this.pageSize,
                customParameters,
            )
            .withLoading()
            .resolveWithJSON<PaginationModel<ColetaModel>>();
    }

    private async onExtraAction(name: string, coleta: ColetaModel) {
        if (name.trim() == "imprimir") {
            this.impressaoComponent = this.$refs.impressaoComponent as ImpressaoComponent;

            await this.impressaoComponent
                .show({
                    modelId: coleta.id,
                    tipoImpressao: "Coleta",
                    impressaoRotulo: false,
                    impressaoSelecionarModelo: true,
                })
                .withLoading();
        }
        if (name.trim() == "coletado") {
            const sucesso = await this.coletaService.marcarComoColetado(coleta.id).withLoading().resolveWithoutJSON();

            if (sucesso) {
                await this.load();
                await this.$showInclusaoUpdate(coleta.id);
            }
        }
    }
}
