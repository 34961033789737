import Vue from "vue";

import buttonIncluirComponent from "@/components/child/form/button/buttonIncluir.vue";
import checkboxComponent from "@/components/child/form/checkbox.vue";
import comboComponent from "@/components/child/form/combo.vue";
import decimalComponent from "@/components/child/form/decimal.vue";
import moedaComponent from "@/components/child/form/moeda.vue";
import { Component, Watch, Prop } from "@/decorators";
import Especie from "@/models/enum/especiePagamento";
import MetodoPagemento from "@/models/financeiro/metodoPagamentoModel";
import MetodoXFormaPagamento from "@/models/financeiro/metodoPagamentoXFormaModel";
import PaginationModel from "@/models/paginationModel";
import MetodoPagamentoService from "@/services/financeiro/metodoPagamentoService";

@Component({
    components: {
        comboComponent,
        checkboxComponent,
        moedaComponent,
        decimalComponent,
        buttonIncluirComponent,
    },
})
export default class MetodoXFormaEditComponent extends Vue {
    private model = new MetodoXFormaPagamento();
    private metodosPagamentosOptions: Array<Object> = [];

    private editingModel = false;

    @Prop({ type: Number, required: false, default: null }) formaRecebimentoId: number;
    @Prop({ type: Array, required: false, default: () => [] }) metodosXForma;
    @Prop({ type: Number, required: false, default: null }) especie: number;

    private async loadMetodoPagamentos(idFiltro = 0) {
        try {
            const data = await new MetodoPagamentoService().combo().resolveWithJSON<PaginationModel<MetodoPagemento>>();

            this.metodosPagamentosOptions = data.list.map(item => ({
                value: item.id,
                text: item.descricao,
                numParcelas: item.numeroParcelas,
            }));
            this.filtrarOptions(idFiltro);
        } catch {}
    }

    public async load(model: MetodoXFormaPagamento) {
        this.clear();
        await this.loadMetodoPagamentos(model.metodoPagamentoId);
        this.model = model;
        this.editingModel = true;
    }

    public clear() {
        this.model = new MetodoXFormaPagamento();
        this.model.metodoPagamentoId = -1;
        this.model.juros = 0;
        this.model.desconto = 0;
        this.model.tarifa = 0;
        this.model.formaRecebimentoId = this.formaRecebimentoId;

        this.loadMetodoPagamentos();
        this.editingModel = false;
    }

    private async onAdicionarItem() {
        await this.$validator.validateAll();

        this.model.metodoPagamentoDescricao = this.metodosPagamentosOptions.find(
            p => p["value"] == this.model.metodoPagamentoId,
        )["text"];

        if (this.editingModel) {
            this.$emit("edicao-metodo", this.model);
        } else {
            this.$emit("inclusao-metodo", this.model);
        }

        this.clear();
    }

    private onCancelar() {
        this.clear();
    }

    @Watch("metodosXForma")
    private onChangedMetodosXForma() {
        this.loadMetodoPagamentos();
    }

    @Watch("especie")
    private onChangeEspecie() {
        this.loadMetodoPagamentos();
    }

    private filtrarOptions(idFiltroConsiderar) {
        if (this.metodosXForma.length > 0) {
            this.metodosPagamentosOptions = this.metodosPagamentosOptions.filter(p =>
                this.filterMetodoFunc(p["value"], p["numParcelas"], idFiltroConsiderar),
            );
        }
    }

    private filterMetodoFunc(id, numParcelas, idFiltroConsiderar) {
        if (this.especie == Especie.CartaoDebito && numParcelas > 1) {
            return false;
        }

        if (id == this.model.metodoPagamentoId || (idFiltroConsiderar > 0 && idFiltroConsiderar == id)) {
            return true;
        }

        return !(this.metodosXForma.filter(p => p.metodoPagamentoId == id).length > 0);
    }

    private mounted() {
        Promise.all([this.loadMetodoPagamentos()])
            .withLoading()
            .then(() => {
                this.clear();
            })
            .catch(() => {});
    }
}
