import Vue from "vue";
import { mapGetters } from "vuex";

import cepFieldComponent from "@/components/child/form/cepfield.vue";
import checkboxComponent from "@/components/child/form/checkbox.vue";
import comboComponent from "@/components/child/form/combo.vue";
import decimalComponent from "@/components/child/form/decimal.vue";
import fieldsetComponent from "@/components/child/form/fieldset.vue";
import moedaComponent from "@/components/child/form/moeda.vue";
import gridComponent from "@/components/child/grid/grid.vue";
import { GridColumn, GridColumnType } from "@/components/child/grid/gridColumn";
import { Component, Watch } from "@/decorators";
import CepModel from "@/models/cepModel";
import ClienteEnderecoEntregaModel from "@/models/clienteEnderecoEntregaModel";
import ClienteModel from "@/models/clienteModel";
import EnumExtensions from "@/models/enum/extensions/enumExtensions";
import Ufs from "@/models/enum/ufs";
import PaginationModel from "@/models/paginationModel";
import TransportadoraModel from "@/models/transporte/transportadoraModel";
import TransportadorModel from "@/models/transporte/transportadorModel";
import ValidationErrorModel from "@/models/validationErrorModel";
import VendaModel from "@/models/vendaModel";
import ClienteService from "@/services/clienteService";
import CidadeService from "@/services/external/cidadeService";
import TransportadoraService from "@/services/transporte/transportadoraService";
import TransportadorService from "@/services/transporte/transportadorService";
import { Getters } from "@/store/store";
import ValidationErrorWrapper from "@/wrappers/validationErrorWrapper";

import ShortcutComponent from "../shortcut/shortcut";
import shortcutComponent from "../shortcut/shortcut.vue";

@Component({
    components: {
        decimalComponent,
        comboComponent,
        cepFieldComponent,
        checkboxComponent,
        gridComponent,
        shortcutComponent,
        fieldsetComponent,
        moedaComponent,
    },
    computed: {
        ...mapGetters(["VALIDAR_PERMISSAO_SOMENTE_CONSULTA"] as Getters),
    },
})
export default class enderecoEntregaComponent extends Vue {
    // State computed props
    VALIDAR_PERMISSAO_SOMENTE_CONSULTA: (telaDescricao: string) => boolean;

    private shortcutComponent: ShortcutComponent = null;
    private service = new ClienteService();
    private cidadeService = new CidadeService();
    private transportadoraService = new TransportadoraService();
    private validationErrorWrapper = new ValidationErrorWrapper(this.$validator);

    private transportadoraOptions: Array<Object> = [];
    private transportadorOptions: Array<Object> = [];

    gridData = [];
    initialRowsChecked = [];
    model = new ClienteEnderecoEntregaModel();
    vendaModel = new VendaModel();
    vendaModelOriginal = new VendaModel();
    enderecosModel: Array<ClienteEnderecoEntregaModel> = [];
    dadosCep = new CepModel();
    somenteConsultaVenda = false;

    private editingModel = false;
    private addNewModel = false;

    enderecosEntregaOptions: Array<Object> = [];
    cidadesEntregaOptions: Array<Object> = [];
    ufOptions: Array<Object> = EnumExtensions.getNamesAndValuesOrderedByNames(Ufs);

    gridColumnsEndereco: Array<GridColumn> = [
        new GridColumn("id", "Cód.", GridColumnType.Integer),
        new GridColumn("cep", "CEP", GridColumnType.String),
        new GridColumn("cidade", "Cidade", GridColumnType.String),
        new GridColumn("estado", "UF", GridColumnType.String),
        new GridColumn("bairro", "Bairro", GridColumnType.String),
        new GridColumn("endereco", "Endere\u00e7o", GridColumnType.String),
        new GridColumn("numero", "numero", GridColumnType.String),
        new GridColumn("complemento", "Complemento", GridColumnType.String),
        new GridColumn("enderecoPadrao", "Padr\u00e3o", GridColumnType.Boolean),
    ];

    private async loadTransportadora() {
        try {
            const data = await this.transportadoraService
                .combo()
                .then(r => r.json() as Promise<PaginationModel<TransportadoraModel>>);
            this.transportadoraOptions = data.list.map(p => ({ value: p.id, text: p.nome }));
        } catch {}
    }

    private async loadTransportador(id = 0) {
        try {
            const data = await new TransportadorService()
                .getByTransportadora(id)
                .resolveWithJSON<TransportadorModel[]>();
            this.transportadorOptions = data.map(p => ({ value: p.id, text: p.nome }));
        } catch {}
    }

    // @ts-ignore
    @Watch("vendaModel.transportadoraId")
    private onChangeTransportadoraId() {
        if (this.vendaModel.transportadoraId) {
            this.loadTransportador(this.vendaModel.transportadoraId);
        }
    }

    // @ts-ignore
    @Watch("enderecosModel", { deep: true })
    private onChangeEnderecosModel() {
        if (this.enderecosModel.length > 0) {
            const enderecos = [...this.enderecosModel];

            this.gridData = enderecos;
            if (this.vendaModel.enderecoEntregaId) {
                this.initialRowsChecked = enderecos.filter(p => p.id == this.vendaModel.enderecoEntregaId);
            } else {
                this.initialRowsChecked = [enderecos[0]];
            }
        }
    }

    private async loadEnderecos(clienteId: number) {
        try {
            const data = await this.service.get(clienteId).then(r => r.json() as Promise<ClienteModel>);
            this.model = null;
            this.enderecosModel = new Array<ClienteEnderecoEntregaModel>();

            if (data.enderecosEntrega != null && data.enderecosEntrega.length > 0) {
                if (this.vendaModel.enderecoEntregaId > 0) {
                    const enderecoPadrao = data.enderecosEntrega.filter(p => p.id == this.vendaModel.enderecoEntregaId);
                    if (enderecoPadrao.length > 0) {
                        this.model = new ClienteEnderecoEntregaModel();
                        const endereco = new ClienteEnderecoEntregaModel();
                        endereco.updateFrom(enderecoPadrao[0]);
                        this.model.updateFrom(endereco);
                        this.enderecosModel.push(endereco);
                    }

                    data.enderecosEntrega
                        .filter(p => p.id != this.vendaModel.enderecoEntregaId)
                        .forEach(item => {
                            const endereco = new ClienteEnderecoEntregaModel();
                            endereco.updateFrom(item);
                            this.enderecosModel.push(endereco);
                        });
                } else {
                    const enderecoPadrao = data.enderecosEntrega.filter(p => p.enderecoPadrao);
                    if (enderecoPadrao.length > 0) {
                        this.model = new ClienteEnderecoEntregaModel();
                        const endereco = new ClienteEnderecoEntregaModel();
                        endereco.updateFrom(enderecoPadrao[0]);
                        this.model.updateFrom(endereco);
                        this.enderecosModel.push(endereco);
                    }

                    data.enderecosEntrega
                        .filter(p => !p.enderecoPadrao)
                        .forEach(item => {
                            const endereco = new ClienteEnderecoEntregaModel();
                            endereco.updateFrom(item);
                            this.enderecosModel.push(endereco);
                        });
                }
            }
        } catch {}
    }

    public clear() {
        this.vendaModel = new VendaModel();
        this.model = new ClienteEnderecoEntregaModel();
        this.enderecosModel = [];
        this.gridData = [];
        this.editingModel = false;
        this.validationErrorWrapper.clearErrors();
    }

    public replicar(model: ClienteModel) {
        this.model.cep = model.cep;
        this.model.bairro = model.bairro;
        this.model.endereco = model.endereco;
        this.model.cidadeId = model.cidadeId;
        this.model.cidade = model.cidade;
        this.model.complemento = model.complemento;
        this.model.estadoId = model.estadoId;
        this.model.numero = model.numero;
    }

    private onCancelar() {
        this.clear();
    }

    private onCepInvalid() {
        this.$showInfo(this.$t("__.ts.pesqCEP"), this.$t("__.ts.pesqNaoEncResul"));
    }

    private onCepChanged(data: CepModel) {
        this.dadosCep = data;
        this.model.bairro = data.bairro;
        this.model.cidadeId = data.cidadeId;
        this.model.complemento = data.complemento;
        this.model.endereco = data.endereco;
        this.model.estadoId = data.uf;
    }

    private onSelectedModel(values: Array<ClienteEnderecoEntregaModel>) {
        if (values.length > 0) {
            this.vendaModel.enderecoEntregaId = values[0].id;
        }
    }

    public getVenda() {
        return this.vendaModel;
    }

    public resetVenda() {
        this.clear();
        this.vendaModel = { ...this.vendaModelOriginal } as VendaModel;
        this.loadEnderecos(this.vendaModel.clienteId).withLoading();
    }

    public setVenda(venda: VendaModel) {
        this.clear();
        this.vendaModel = venda;
        this.vendaModelOriginal = { ...venda } as VendaModel;
        this.loadEnderecos(this.vendaModel.clienteId).withLoading();
    }

    //@ts-ignore
    @Watch("model.estadoId")
    private async onChangeEstadoId(newValue: number) {
        this.onChangeEstadoIdInternal(newValue).withLoading();
    }

    private async onChangeEstadoIdInternal(estadoId: number) {
        const cidadeId = this.model != null ? this.model.cidadeId : null;
        try {
            const data = await this.cidadeService.get(estadoId);

            if (this.model != null) {
                this.model.cidadeId = null;

                if (this.dadosCep.cidadeId) {
                    this.model.cidadeId = this.dadosCep.cidadeId;
                } else {
                    this.model.cidadeId = cidadeId;
                }
            }

            this.cidadesEntregaOptions = data;
        } catch {}
    }

    private onCreateEndereco() {
        this.validationErrorWrapper.clearErrors();
        this.model = new ClienteEnderecoEntregaModel();
        this.model.enderecoPadrao = false;
        this.shortcutComponent.title = this.$t("__.ts.criandNovoEndeEntrg") as string;
        this.shortcutComponent.show();

        this.addNewModel = true;
    }

    private onEditEndereco(item: ClienteEnderecoEntregaModel) {
        this.model = new ClienteEnderecoEntregaModel();

        this.model.updateFrom(item);
        this.shortcutComponent.title = this.$t("__.ts.editEndeEntreg") as string;
        this.shortcutComponent.show();
        this.editingModel = true;
    }

    private onSaveEndereco() {
        if (this.model != null) {
            this.saveEndereco();
        }
    }

    private async saveEndereco() {
        const isValid = await this.$validator.validateAll();
        if (!isValid) {
            return;
        }

        if (this.model.cidadeId) {
            this.model.cidade = this.cidadesEntregaOptions.filter(item => item["value"] == this.model.cidadeId)[0][
                "text"
            ];
        }

        this.model.clienteId = this.vendaModel.clienteId;

        if (this.model.enderecoPadrao) {
            if (this.enderecosModel.filter(p => p.id != this.model.id && p.enderecoPadrao).length > 0) {
                try {
                    const response = await this.$showQuestion(
                        this.$t("__.ts.atencao"),
                        this.$t("__.ts.jaExistEnderMarcPdr"),
                    );

                    if (response) {
                        const oldIdPadrao = this.enderecosModel.filter(
                            p => p.id != this.model.id && p.enderecoPadrao,
                        )[0];

                        oldIdPadrao.enderecoPadrao = false;

                        try {
                            const sucesso = await this.service
                                .insertUpdateEnderecoEntrega(oldIdPadrao)
                                .withLoading()
                                .resolveWithoutJSON();
                            if (!sucesso) {
                                this.model.enderecoPadrao = false;
                            }
                        } catch {
                            this.model.enderecoPadrao = false;
                        }
                    } else {
                        this.model.enderecoPadrao = false;
                    }
                } catch {
                    this.model.enderecoPadrao = false;
                }
            }
        }

        try {
            const response = await this.service.insertUpdateEnderecoEntrega(this.model).withLoading();
            const nId = Number(response.headers.get("Id"));

            if (response.ok) {
                await this.$showInclusaoUpdate(this.model.id);

                if (!this.model.id) {
                    this.model.id = nId;
                }

                const endereco = new ClienteEnderecoEntregaModel();
                endereco.updateFrom(this.model);

                if (this.enderecosModel.filter(p => p.id == this.model.id).length == 0) {
                    this.enderecosModel.splice(0, 0, endereco);
                } else {
                    this.enderecosModel
                        .filter(p => p.id == this.model.id)
                        .forEach(item => {
                            item.updateFrom(endereco);
                        });
                }

                this.addNewModel = false;
                this.editingModel = false;

                this.shortcutComponent.hide();
            } else {
                const data = (await response.json()) as ValidationErrorModel[];
                this.validationErrorWrapper.showErrors(data);
            }
        } catch {}
    }

    private mounted() {
        this.shortcutComponent = this.$refs.shortcutComponent as ShortcutComponent;

        this.clear();

        this.somenteConsultaVenda = this.VALIDAR_PERMISSAO_SOMENTE_CONSULTA("vendas");

        Promise.all([this.loadTransportadora()])
            .withLoading()
            .then(() => {})
            .catch(() => {});
    }
}
