import { GridAction } from "@/components/child/grid/gridAction";
import { GridColors, GridColumn, GridColumnType } from "@/components/child/grid/gridColumn";
import { Component } from "@/decorators";
import FranquiaModel from "@/models/franquiaModel";
import FranquiaService from "@/services/franquiaService";
import { SessionActions } from "@/store/store";

import ListComponentBase from "../listComponentBase";

import "../crud.scss";

@Component
export default class FranquiaListComponent extends ListComponentBase<FranquiaModel> {
    get gridColumns(): Array<GridColumn> {
        return [
            new GridColumn("nomeFantasia", "Franquia", GridColumnType.String),
            new GridColumn("cnpj", "CNPJ", GridColumnType.String, false, false, "", "", false, false),
        ];
    }

    private beforeMount() {
        this.setProps(new FranquiaService(), "franquia", "nomeFantasia");
    }

    public async afterLoad() {
        this.isAdmin = await this.HAS_PERMISSAO_PERFIL(true, false, false);
        this.isAdminFranqueador = await this.HAS_PERMISSAO_PERFIL(false, true, false);

        this.extraActions.splice(0);
        this.extraActionsTrocaFranquia.splice(0);
        this.extraActionsIntegracoesFranquia.splice(0);
        this.extraActionsConfigGatewayPagamento.splice(0);

        for (let i = 0; i < this.gridData.length; i++) {
            this.extraActionsTrocaFranquia.push(
                new GridAction("troca-franquia", "Trocar para franquia", "fa fa-undo", GridColors.GREEN),
            );
            if (this.isAdmin) {
                this.extraActionsIntegracoesFranquia.push(
                    new GridAction("integracoes", "Configuração de Integrações", "fa fa-cog", GridColors.GRAY),
                );
            }

            if (this.isAdmin || this.isAdminFranqueador) {
                this.extraActionsConfigGatewayPagamento.push(
                    new GridAction(
                        "configGatewayPagamento",
                        "Configuração de Gateway de pagamento",
                        "fa fa-money-bill",
                        GridColors.RED,
                    ),
                );
            }
        }

        if (this.extraActionsTrocaFranquia.length > 0) {
            this.extraActions.push(this.extraActionsConfigGatewayPagamento);
            this.extraActions.push(this.extraActionsIntegracoesFranquia);
            this.extraActions.push(this.extraActionsTrocaFranquia);
        }
    }

    extraActions: Array<object> = [];
    extraActionsTrocaFranquia: Array<GridAction> = [];
    extraActionsIntegracoesFranquia: Array<GridAction> = [];
    extraActionsConfigGatewayPagamento: Array<GridAction> = [];
    isAdmin = false;
    isAdminFranqueador = false;

    private async onExtraAction(name: string, franquia: FranquiaModel) {
        if (name.trim() == "troca-franquia") {
            await this.$store
                .dispatch(SessionActions.TROCA_FRANQUIA, { franquiaId: franquia.id, router: this.$router })
                .withLoading();
        }
        if (name.trim() == "integracoes") {
            this.$router.push(`/franquia-integracao/${franquia.id.toString()}`);
        }

        if (name.trim() == "configGatewayPagamento") {
            this.$router.push(`/configuracaogatewaypagamento-edicao/${franquia.id.toString()}`);
        }
    }
}
