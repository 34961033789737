export function formatMoney(value: number, digits = 2) {
    return new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
        minimumFractionDigits: digits,
    }).format(value);
}

export function formatNumber(value: number, digits = 2) {
    return new Intl.NumberFormat("pt-BR", {
        currency: "BRL",
        minimumFractionDigits: digits,
    }).format(value);
}

export function formatWhatsAppString(input: string) {
    if (input.startsWith("55")) {
        input = input.substring(2);
    }

    const atIndex = input.indexOf("@");
    if (atIndex !== -1) {
        input = input.substring(0, atIndex);
    }

    return input;
}
