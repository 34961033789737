import BaseModel from "./base/baseModel";
import EnsaioFichaTecnicaResultadoModel from "./ensaioFichaTecnicaResultadoModel";
import EnsaiosModel from "./ensaiosModel";
import FichaTecnicaModel from "./fichaTecnicaModel";

export default class EnsaioFichaTecnicaModel extends BaseModel {
    id?: number = null;
    ensaioId?: number;
    ensaio: EnsaiosModel;
    descricaoEnsaio: string = null;
    especificacao = "";
    fichaTecnicaId?: number;
    fichaTecnica: FichaTecnicaModel;
    resultado?: string = "";
    ensaioFichaTecnicaResultados: EnsaioFichaTecnicaResultadoModel[] = [];
}
