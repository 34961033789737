var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "shortcutComponent",
    {
      ref: "shortcutComponent",
      attrs: { showAddNew: false, showBtnOk: false, modalClass: "ss-modal" },
    },
    [
      _vm.currentAtendimento !== null
        ? _c("div", { attrs: { slot: "component" }, slot: "component" }, [
            _c("form", [
              _c("div", { staticClass: "row-dados" }, [
                _c("img", {
                  attrs: { src: _vm.currentAtendimento.fotoContato },
                }),
                _vm._v(" "),
                _c("div", [
                  _c(
                    "label",
                    {
                      staticClass: "control-label",
                      attrs: { for: "nomeContato" },
                    },
                    [
                      _vm._v(
                        "\n                        " +
                          _vm._s(_vm.$t("__.Crud.banco.edit_vue_html.nome")) +
                          "\n                    "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.currentAtendimento.nomeContato,
                        expression: "currentAtendimento.nomeContato",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: {
                      autocomplete: "off",
                      type: "text",
                      id: "nomeContato",
                      name: "nomeContato",
                      disabled: "",
                    },
                    domProps: { value: _vm.currentAtendimento.nomeContato },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.currentAtendimento,
                          "nomeContato",
                          $event.target.value
                        )
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "label",
                    {
                      staticClass: "control-label",
                      attrs: { for: "numeroContato" },
                    },
                    [
                      _vm._v(
                        "\n                        " +
                          _vm._s(
                            _vm.$t("__.Crud.cliente.edit_vue_html.telefone")
                          ) +
                          "\n                    "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.numeroContato,
                        expression: "numeroContato",
                      },
                      { name: "mask-telefone", rawName: "v-mask-telefone" },
                    ],
                    staticClass: "form-control",
                    attrs: {
                      id: "numeroContato",
                      type: "text",
                      name: "numeroContato",
                      autocomplete: "off",
                      disabled: "",
                    },
                    domProps: { value: _vm.numeroContato },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.numeroContato = $event.target.value
                      },
                    },
                  }),
                ]),
              ]),
            ]),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }