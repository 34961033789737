import Vue from "vue";
import { mapGetters, mapState } from "vuex";

import buttonScComponent from "@/components/child/form/buttonSc.vue";
import checkboxComponent from "@/components/child/form/checkbox.vue";
import comboComponent from "@/components/child/form/combo.vue";
import decimalComponent from "@/components/child/form/decimal.vue";
import moedaComponent from "@/components/child/form/moeda.vue";
import { Component, Prop, Watch } from "@/decorators";
import PreLoadPackModel from "@/models/auxiliar/preLoadPackModel";
import EnumExtensions from "@/models/enum/extensions/enumExtensions";
import TiposCalculoManipulacao from "@/models/enum/tiposCalculoManipulacao";
import TiposItemManipulacao from "@/models/enum/tiposItemManipulacao";
import TiposProduto from "@/models/enum/tiposProduto";
import ManipulacaoOrdemItemModel from "@/models/manipulacaoOrdemItemModel";
import ManipulacaoOrdemModel from "@/models/manipulacaoOrdemModel";
import ProdutoModel from "@/models/produto/produtoModel";
import ProdutoService from "@/services/produto/produtoService";
import { ItemTableType, ManipulacaoContextState } from "@/store/manipulacaoContext/types";
import { ManipulacaoContextActions, ManipulacaoContextMutations } from "@/store/manipulacaoContext/types";
import { AppState, Getters } from "@/store/store";
import { getProdutoComboText } from "@/utils/common/combo/combotext";
import Delay from "@/utils/common/delay";

import ManipulacaoItemComponent from "./item";
import manipulacaoItemComponent from "./item.vue";

import "./item.scss";

@Component({
    components: {
        comboComponent,
        checkboxComponent,
        moedaComponent,
        decimalComponent,
        manipulacaoItemComponent,
        buttonScComponent,
    },
    computed: {
        ...mapState<AppState>({
            loadedList: state => state.preLoad.loadedList,
            preLoadList: state => state.preLoad.preLoadList,
        }),
        ...mapGetters(["GET_MANIPULACAO_CONTEXT"] as Getters),
    },
})
export default class ManipulacaoItemTableComponent extends Vue {
    // State computed props
    loadedList: boolean;
    preLoadList: PreLoadPackModel;
    GET_MANIPULACAO_CONTEXT: (uuid: string) => ManipulacaoContextState;

    private produtoService = new ProdutoService();

    private theader = [];
    private theader2 = [];
    private theaderColSpan = [];
    private theaderColSpan2 = [];

    private listaItens: ManipulacaoItemComponent[] = [];
    public itens: ManipulacaoOrdemItemModel[] = [];

    private model: ManipulacaoOrdemItemModel = new ManipulacaoOrdemItemModel();

    private produtosOptions: Array<Object> = [];
    private produtosList: ProdutoModel[] = [];
    private kitEmbalagensOptions: Array<Object> = [];
    private formulaPadraoOptions: Array<Object> = [];
    private tipoItemOptions: Array<Object> = EnumExtensions.getNamesAndValuesOrderedByNames(TiposItemManipulacao);
    private tiposCalculoOptions: Array<Object> =
        EnumExtensions.getNamesAndValuesOrderedByNames(TiposCalculoManipulacao);
    private unidadeMedidaOptions: Array<Object> = [];
    private capsulasOptions: Array<Object> = [];

    private manipulacao = new ManipulacaoOrdemModel();
    private manipulacaoContext = new ManipulacaoContextState();

    private unsubscribe = null;
    private unsubscribeActions = null;

    @Prop({ type: Boolean, default: false }) isActiveTab: boolean;

    //0 = ativos, 1 = embalagens,  2 = capsulas
    @Prop(Number) itemType: number;
    @Prop({ type: String, required: true }) uuid: string;
    @Prop({ type: Number, default: 0 }) manipulacaoId: number;

    //@ts-ignore
    @Watch("manipulacaoContext.ativos", { deep: true })
    //@ts-ignore
    @Watch("manipulacaoContext.embalagens", { deep: true })
    //@ts-ignore
    @Watch("manipulacaoContext.capsulas", { deep: true })
    private loadItensByType() {
        this.itens = [];

        switch (this.itemType) {
            case ItemTableType.ATIVO:
                this.itens = this.manipulacaoContext.ativos;
                break;
            case ItemTableType.EMBALAGEM:
                this.itens = this.manipulacaoContext.embalagens;
                break;
            case ItemTableType.CAPSULA:
                this.itens = this.manipulacaoContext.capsulas;
                break;
        }
    }

    public clear() {
        this.onRefazerHeader();

        for (const item of this.listaItens) {
            item.clear();
        }
    }

    public async clearItensAssociados() {
        this.onRefazerHeader();

        this.itens = this.itens.filter(p => p.produtoAssociadoPaiId == null);
        await this.loadListaItens();
    }

    public async consolidaItens(manipulacao: ManipulacaoOrdemModel = null) {
        await this.loadListaItens();

        for (const item of this.listaItens) {
            await item.consolidaItem(manipulacao);
        }
    }

    public async getNaoUtilizarExcipiente() {
        await this.loadListaItens();

        return this.listaItens.filter(p => p.produto).some(p => p.produto.naoUtilizarExcipiente);
    }

    public async validateAll() {
        for (const item of this.listaItens) {
            const isValid = await item.validateAll();
            if (!isValid) {
                return false;
            }
        }
        return true;
    }

    private loadKitEmbalagem() {
        if (this.itemType == ItemTableType.EMBALAGEM) {
            this.kitEmbalagensOptions = this.preLoadList.kitEmbalagemCombo();
        }
    }

    private loadCapsulas() {
        if (this.itemType == ItemTableType.CAPSULA) {
            this.capsulasOptions = this.preLoadList.capsulasCombo();
        }
    }

    public addEmptyRow() {
        this.addNewRow(null);
    }

    private onAdicionarFormulaBase() {
        const itemModel = new ManipulacaoOrdemItemModel();
        itemModel.tipoItem = TiposItemManipulacao.FormulaPadrao;

        this.addNewRow(itemModel);
    }

    private async addNewRow(item: ManipulacaoOrdemItemModel) {
        await this.dispatch(ManipulacaoContextActions.ADD_ITEM_MANIPULACAO, { item, type: this.itemType });

        await this.loadListaItens();

        this.onRefazerHeader();
    }

    private async onAdicionarCapsula() {
        this.$emit("addItemCapsula");
    }

    private async onAdicionarEmbalagem() {
        this.$emit("addItemEmbalagem");
    }

    private async removeItem(index: number) {
        this.dispatch(ManipulacaoContextActions.REMOVE_ITEM_MANIPULACAO, { type: this.itemType, index });

        this.onRefazerHeader();

        await this.dispatch(ManipulacaoContextActions.CALCULAR_QSP, { loadDadosLote: false, ignoreDebounce: false });
    }

    private onRefazerHeader() {
        this.theader = [];
        this.theaderColSpan = [];
        this.theader2 = [];
        this.theaderColSpan2 = [];

        switch (this.itemType) {
            case ItemTableType.ATIVO:
                this.theader.push(this.$t("__.ts.prod") as string);
                this.theader.push(this.$t("__.ts.calc") as string);
                if (this.itens.some(p => p.tipoCalculo == TiposCalculoManipulacao.Dose)) {
                    this.theader.push(this.$t("__.ts.volDose") as string);
                    this.theaderColSpan = [4, 1, 1, 2, 1];
                } else {
                    this.theaderColSpan = [4, 2, 2, 1];
                }
                this.theader.push(this.$t("__.ts.concentr") as string);
                this.theader.push(this.$t("__.ts.unMedida") as string);

                this.theader2 = [this.$t("__.ts.qtdTotal") as string, this.$t("__.ts.total") as string];
                this.theaderColSpan2 = [6, 6];
                break;
            case ItemTableType.EMBALAGEM:
                this.theader = [
                    this.$t("__.ts.prod") as string,
                    this.$t("__.ts.quantidade") as string,
                    this.$t("__.ts.qtdTotal") as string,
                    this.$t("__.ts.total") as string,
                ];
                this.theaderColSpan = [6, 2, 2, 2];
                break;
            case ItemTableType.CAPSULA:
                this.theader = [
                    this.$t("__.ts.capsula") as string,
                    this.$t("__.ts.cor") as string,
                    this.$t("__.ts.quantidade") as string,
                    this.$t("__.ts.qtdTotal") as string,
                    this.$t("__.ts.total") as string,
                ];
                this.theaderColSpan = [2, 4, 2, 2, 2];
                break;
        }
    }

    private alterarVolumeCapsula(volume: number, descricao: string) {
        this.$emit("alterarVolumeCapsula", volume, descricao);
    }

    //@ts-ignore
    @Watch("formaFarmaceuticaId")
    private async onChangedFormaFarmaceutica() {
        this.loadManipulacaoContext();

        if (this.manipulacao.formaFarmaceuticaId > 0 && this.itemType == 0) {
            this.loadProdutosPreLoad();
        }
    }

    private loadProdutosPreLoad() {
        let tipoProduto = TiposProduto.MateriaPrima;

        switch (this.itemType + 1) {
            case TiposItemManipulacao.MateriaPrima:
                tipoProduto = TiposProduto.MateriaPrima;
                break;
            case TiposItemManipulacao.Embalagem:
                tipoProduto = TiposProduto.Embalagem;
                break;
            case TiposItemManipulacao.KitEmbalagem:
                tipoProduto = TiposProduto.Embalagem;
                break;
            case TiposItemManipulacao.Capsula:
                tipoProduto = TiposProduto.Capsula;
                break;
            default:
        }

        this.loadManipulacaoContext();

        let produtos = [];

        //se for de materia prima e sub grupo for ativo
        if (
            tipoProduto == TiposProduto.MateriaPrima &&
            this.manipulacao.formaFarmaceuticaId > 0 &&
            this.itemType == 0
        ) {
            produtos = this.preLoadList.produtos.filter(
                p =>
                    (p.tipoProduto == tipoProduto ||
                        (tipoProduto == TiposProduto.MateriaPrima && p.tipoProduto == TiposProduto.SemiAcabado)) &&
                    (p.formasFarmaceuticas == null ||
                        p.formasFarmaceuticas.length == 0 ||
                        p.formasFarmaceuticas.some(p => p.id == this.manipulacao.formaFarmaceuticaId) ||
                        !p.formasFarmaceuticas.some(p => p.id == this.manipulacao.formaFarmaceuticaId)),
            );
        } else {
            produtos = this.preLoadList.produtos.filter(
                p =>
                    p.tipoProduto == tipoProduto ||
                    p.tipoProduto == TiposProduto.Homeopatia ||
                    (tipoProduto == TiposProduto.MateriaPrima && p.tipoProduto == TiposProduto.SemiAcabado),
            );
        }

        if (!this.manipulacaoId) {
            produtos = produtos.filter(p => p.ativo);
        }

        this.loadProdutosOptions(produtos);
        this.produtosList = produtos;
    }

    private async loadPreLoad() {
        while (!this.loadedList) await Delay(10);

        this.loadProdutosPreLoad();
        this.loadKitEmbalagem();
        this.loadCapsulas();

        this.unidadeMedidaOptions = this.preLoadList.unidadesMedida.map(item => ({
            value: item.id,
            text: item.sigla,
            type: item.unidadeMedida,
        }));

        this.formulaPadraoOptions = this.preLoadList.formulasPadrao.map(item => ({
            value: item.id,
            text: `${item.produtoId} - ${item.descricao}`,
        }));
    }

    private onCorCapsulaChange(model: ManipulacaoOrdemItemModel) {
        this.$emit("onCorCapsulaChange", model);
    }

    private async loadProdutosOptions(produtos: ProdutoModel[]) {
        const produtosCompletos = [];

        produtos.forEach(p => {
            produtosCompletos.push({
                value: p.id,
                text: getProdutoComboText(p, false),
                subGrupo: p.subGrupo,
            });

            p.produtoSinonimos.forEach(s => {
                const produtoFake = { ...p } as ProdutoModel;
                produtoFake.descricao = s.sinonimo;
                produtosCompletos.push({
                    value: p.id * s.id,
                    produtoId: p.id,
                    sinonimoId: s.id,
                    text: getProdutoComboText(produtoFake, false),
                    subGrupo: p.subGrupo,
                });
            });
        });

        this.produtosOptions = produtosCompletos;
    }

    private async loadListaItens() {
        this.listaItens = [];

        if (this.itens.length > 0) {
            while (!this.$refs.manipulacaoItemComponent) await Delay(100);
            for (let index = 0; index < this.$refs.manipulacaoItemComponent["length"]; index++) {
                this.listaItens[index] = this.$refs.manipulacaoItemComponent[index] as ManipulacaoItemComponent;
            }
        }
    }

    private onChangeKitEmbalagem(id: number) {
        if (id) {
            this.dispatch(ManipulacaoContextActions.UPDATE_KIT_EMBALAGEM_ID, id);
        }
    }

    public forceUpdate() {
        this.$forceUpdate();

        this.loadListaItens();

        for (const item of this.listaItens) {
            item.forceUpdate();
        }
    }

    private async dispatch(action: ManipulacaoContextActions, data: unknown) {
        await this.$store.dispatch(action, { uuid: this.uuid, data });

        this.loadManipulacaoContext();
    }

    public refreshManipulacaoContext() {
        this.loadManipulacaoContext();

        for (const item of this.listaItens) {
            item.loadManipulacaoContext();
        }
    }

    private loadManipulacaoContext() {
        this.manipulacaoContext = this.GET_MANIPULACAO_CONTEXT(this.uuid);
        this.manipulacao = this.manipulacaoContext.manipulacao;
    }

    public unsubscribeAll() {
        this.unsubscribe();
        this.unsubscribeActions();

        for (const item of this.listaItens) {
            item.unsubscribeAll();
        }
    }

    @Watch("manipulacaoId")
    private onChangeManipulacaoId() {
        this.loadProdutosPreLoad();
    }

    private remontarFormulaPadraoDesmembrada() {
        this.$forceUpdate();

        this.loadListaItens();

        for (const item of this.listaItens.filter(p => p.isFormulaPadrao)) {
            item.remontarFormulaPadraoDesmembrada();
        }
    }

    private mounted() {
        this.unsubscribe = this.$store.subscribe(mutation => {
            switch (mutation.type) {
                case ManipulacaoContextMutations.RESET_MANIPULACAO:
                    this.itens = [];
                    this.loadProdutosOptions(this.produtosList);
                    break;
                case ManipulacaoContextMutations.UPDATE_MANIPULACAO_KIT_EMBALAGEM_ID:
                    this.model.kitEmbalagemId = this.manipulacao.kitEmbalagemId;
                    break;
                case ManipulacaoContextMutations.ADD_ITEM_MANIPULACAO:
                case ManipulacaoContextMutations.REMOVE_ALL_ITENS_BY_TYPE:
                case ManipulacaoContextMutations.REMOVE_ITEM_MANIPULACAO:
                case ManipulacaoContextMutations.UPDATE_MANIPULACAO:
                case ManipulacaoContextMutations.REMOVE_EMBALAGENS_BY_KIT_ID:
                case ManipulacaoContextMutations.REMOVE_ATIVOS_ASSOCIADOS:
                    this.$forceUpdate();
                    this.loadManipulacaoContext();
                    break;
                case ManipulacaoContextMutations.CALCULAR_QSP:
                    this.$forceUpdate();
                    this.loadManipulacaoContext();
                    this.remontarFormulaPadraoDesmembrada();
                    break;
            }
        });

        // @ts-ignore
        this.unsubscribeAll = this.$store.subscribeAction({
            after: action => {
                switch (action.type) {
                    case ManipulacaoContextActions.ADD_ITEM_MANIPULACAO:
                    case ManipulacaoContextActions.CALCULAR_QSP:
                    case ManipulacaoContextActions.REMOVE_ATIVOS_ASSOCIADOS:
                        this.$forceUpdate();
                        break;
                }
            },
        });

        Promise.all([this.loadPreLoad()])
            .then(() => {
                this.onRefazerHeader();
                this.loadManipulacaoContext();
            })
            .catch(() => {});
    }
}
