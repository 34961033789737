import Vue from "vue";

import { Component, Prop, Watch } from "@/decorators";

import "./fieldset.scss";

@Component
export default class FieldSetComponent extends Vue {
    isCollapsed = true;
    isCheckedInternal = true;
    isValid: boolean = null;

    @Prop({ type: String, required: true }) title: string;
    @Prop({ type: Boolean, default: true }) collapsed: boolean;
    @Prop({ type: Boolean, default: true }) validate: boolean;
    @Prop({ type: String, default: "white" }) headerColor: string;
    @Prop({ type: Boolean, default: false }) withCheckBox: boolean;
    @Prop({ type: String, default: "" }) checkBoxLiteral: string;
    @Prop({ type: Boolean, default: true }) isChecked: boolean;

    @Watch("collapsed")
    private onCollapsedChanged() {
        this.isCollapsed = this.collapsed.valueOf();
    }

    @Watch("isCollapsed")
    private onIsCollapsedChanged() {
        this.$emit("collapsedStatus", this.isCollapsed);
    }

    @Watch("isChecked")
    private onIsCheckedChanged() {
        this.isCheckedInternal = this.isChecked;
    }

    @Watch("isCheckedInternal")
    private onIsCheckedInternalChanged() {
        this.$emit("onChangeChecked", this.isCheckedInternal);
    }

    private mounted() {
        this.isCollapsed = this.collapsed.valueOf();
        this.isCheckedInternal = this.isChecked;

        if (this.validate) {
            setTimeout(() => {
                this.$watch("$parent.$validator.errorBag.errors.length", function () {
                    this.isValid = this.$el.querySelector("div.has-error") == null;
                });
            }, 1000);
        }
    }
}
