import { GridAction } from "@/components/child/grid/gridAction";
import { GridColors, GridColumn, GridColumnType } from "@/components/child/grid/gridColumn";
import ListComponentBase from "@/components/parent/crud/listComponentBase";
import { Component } from "@/decorators";
import PaginationModel from "@/models/paginationModel";
import PrescritorModel from "@/models/prescritorModel";
import PrescritorService from "@/services/prescritorService";

import PrescritoresDuplicadosUnificarComponent from "./unificar";
import prescritoresDuplicadosUnificarComponent from "./unificar.vue";
import PrescritoresDuplicadosViewComponent from "./view";
import prescritoresDuplicadosViewComponent from "./view.vue";

@Component({
    components: {
        prescritoresDuplicadosUnificarComponent,
        prescritoresDuplicadosViewComponent,
    },
})
export default class PrescritoresDuplicadosListComponent extends ListComponentBase<PrescritorModel> {
    private prescritorService = new PrescritorService();

    private prescritoresDuplicadosViewComponent: PrescritoresDuplicadosViewComponent = null;
    private prescritoresDuplicadosUnificarComponent: PrescritoresDuplicadosUnificarComponent = null;

    extraActions: Array<object> = [];
    gridExtraActionsAjustar: Array<GridAction> = [];
    prescritorId = null;

    get gridColumns(): Array<GridColumn> {
        return [
            new GridColumn("id", this.$t("__.ts.codigo"), GridColumnType.String),
            new GridColumn("nome", this.$t("__.ts.nome"), GridColumnType.String),
            new GridColumn(
                "numeroRegistro",
                this.$t("__.Crud.franquia.edit_vue_html.crmv"),
                GridColumnType.String,
                false,
                false,
                "",
                "",
                true,
            ),
            new GridColumn("telefone", this.$t("__.ts.telefone"), GridColumnType.String),
            new GridColumn("celular", this.$t("__.ts.celular"), GridColumnType.String),
        ];
    }

    private beforeMount() {
        this.setProps(this.prescritorService, "prescritor", "nome");
    }

    public async load() {
        try {
            const data = await this.prescritorService
                .getPrescritoresDuplicados(
                    this.gridFilterKey,
                    this.pags ? 1 : this.pageIndex,
                    this.pags ? 999999 : this.pageSize,
                )
                .withLoading()
                .resolveWithJSON<PaginationModel<PrescritorModel>>();

            this.gridData = data.list;
            this.total = data.total;
            this.pageIndex = data.pageIndex;
            this.pageSize = data.pageSize;

            this.extraActions.splice(0);
            this.gridExtraActionsAjustar.splice(0);

            this.gridData.forEach(() => {
                this.gridExtraActionsAjustar.push(
                    new GridAction("visualizar", "Visualizar duplicações", "fa fa-eye", GridColors.BLUE),
                );
            });

            this.extraActions.push(this.gridExtraActionsAjustar);
            if (this.pags) {
                if (this.tipoDocGerar == 0) {
                    setTimeout(() => {
                        this.gridcomponent.gerarXls();
                    }, 2000);
                }

                if (this.tipoDocGerar == 1) {
                    setTimeout(() => {
                        this.gridcomponent.gerarPdf();
                    }, 2000);
                }

                if (this.tipoDocGerar == 2) {
                    setTimeout(() => {
                        this.gridcomponent.gerarImpressao();
                    }, 2000);
                }
            }
        } catch {}
    }

    private onExtraAction(name: string, prescritor: PrescritorModel) {
        if (name.trim() == "visualizar") {
            this.prescritoresDuplicadosViewComponent = this.$refs
                .prescritoresDuplicadosViewComponent as PrescritoresDuplicadosViewComponent;
            this.prescritoresDuplicadosViewComponent.show(prescritor);
        }
    }

    private onUnificar() {
        this.prescritoresDuplicadosUnificarComponent = this.$refs
            .prescritoresDuplicadosUnificarComponent as PrescritoresDuplicadosUnificarComponent;
        this.prescritoresDuplicadosUnificarComponent.show();
    }
}
