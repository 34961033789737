var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.active,
          expression: "active",
        },
      ],
      staticClass: "crud",
    },
    [
      _c("chatBotReceitaComponent", { ref: "chatBotReceitaComponent" }),
      _vm._v(" "),
      _c(
        "actionBarComponent",
        {
          attrs: {
            showSave: _vm.validarVisibilidadeBotao("salvarImprimir"),
            slots: 5,
            title: _vm.$t("__.Components.parent.venda.edit_vue_html.vendaEdit"),
          },
          on: {
            onSave: function ($event) {
              _vm.save().withLoading()
            },
          },
          scopedSlots: _vm._u([
            {
              key: "buttonSlot3",
              fn: function (ref) {
                var open = ref.open
                return _vm.model.id && _vm.model.id !== 0
                  ? _c(
                      "div",
                      {
                        attrs: { title: _vm.$t("__.ts.historicoAlteracoes") },
                        on: {
                          click: function ($event) {
                            _vm.onShowHistoricoStatus(open)
                          },
                        },
                      },
                      [
                        _c("i", {
                          staticClass: "fa fa-history",
                          attrs: { "aria-hidden": "true" },
                        }),
                      ]
                    )
                  : _vm._e()
              },
            },
            {
              key: "buttonSlot4",
              fn: function (ref) {
                var open = ref.open
                return _vm.validarVisibilidadeBotao("linkPagamento")
                  ? _c(
                      "div",
                      {
                        attrs: { title: _vm.$t("__.ts.LinkPagamento") },
                        on: {
                          click: function ($event) {
                            _vm.showLinkPagamento(open)
                          },
                        },
                      },
                      [
                        _c("i", {
                          staticClass: "fa fa-link",
                          attrs: { "aria-hidden": "true" },
                        }),
                      ]
                    )
                  : _vm._e()
              },
            },
          ]),
        },
        [
          _vm.validarVisibilidadeBotao("imprimirVenda")
            ? _c(
                "div",
                {
                  attrs: { slot: "buttonSlot0", title: "Imprimir Venda" },
                  on: {
                    click: function ($event) {
                      _vm.imprimirVenda(true)
                    },
                  },
                  slot: "buttonSlot0",
                },
                [
                  _c("i", {
                    staticClass: "fa fa-print",
                    attrs: { "aria-hidden": "true" },
                  }),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.validarVisibilidadeBotao("cancelarVenda")
            ? _c(
                "div",
                {
                  attrs: { slot: "buttonSlot1", title: "Cancelar Venda" },
                  on: { click: _vm.onCancelarVenda },
                  slot: "buttonSlot1",
                },
                [
                  _c("i", {
                    staticClass: "fa fa-exclamation-circle",
                    attrs: { "aria-hidden": "true" },
                  }),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            {
              attrs: { slot: "buttonSlot2", title: "Mais Opções" },
              slot: "buttonSlot2",
            },
            [
              _c(
                "dropdownComponent",
                { attrs: { menuSide: "RIGHT" } },
                [
                  _c("i", {
                    staticClass: "fa fa-ellipsis-v",
                    attrs: { slot: "button", "aria-hidden": "true" },
                    slot: "button",
                  }),
                  _vm._v(" "),
                  _c("template", { slot: "itens" }, [
                    _vm.validarVisibilidadeBotao("financeiro")
                      ? _c("li", { on: { click: _vm.showTelaPagamentos } }, [
                          _c("div", [_vm._v("Financeiro")]),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.validarVisibilidadeBotao("copiar")
                      ? _c("li", { on: { click: _vm.onCopiar } }, [
                          _c("div", [_vm._v(_vm._s(_vm.$t("__.Crud.copiar")))]),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.validarVisibilidadeBotao("enviarOrcamento")
                      ? _c("li", { on: { click: _vm.onSelecionar } }, [
                          _c("div", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "__.Components.parent.venda.editSingle_vue_html.enviarOrcamento"
                                )
                              )
                            ),
                          ]),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "li",
                      {
                        on: {
                          click: function ($event) {
                            _vm.toggleUploadReceita(true)
                          },
                        },
                      },
                      [
                        _c("div", [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "__.Components.parent.venda.editSingle_vue_html.receita"
                              )
                            )
                          ),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _vm.validarVisibilidadeBotao("expedicao")
                      ? _c(
                          "li",
                          { on: { click: _vm.toggleEntregaComponent } },
                          [
                            _c("div", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "__.Components.parent.venda.editSingle_vue_html.expedicao"
                                  )
                                )
                              ),
                            ]),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.validarVisibilidadeBotao("movimentacaoEstoque")
                      ? _c(
                          "li",
                          {
                            on: {
                              click: function ($event) {
                                _vm.onMovimentoEstoque()
                              },
                            },
                          },
                          [
                            _c("div", [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(
                                    _vm.$t(
                                      "__.Components.parent.venda.editSingle_vue_html.movimentacaoEstoque"
                                    )
                                  ) +
                                  "\n                        "
                              ),
                            ]),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.validarVisibilidadeBotao("desconcluirVenda")
                      ? _c(
                          "li",
                          {
                            on: {
                              click: function ($event) {
                                _vm.onDesconcluirVenda()
                              },
                            },
                          },
                          [
                            _c("div", [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(
                                    _vm.$t(
                                      "__.Components.parent.venda.editSingle_vue_html.desconcluirVenda"
                                    )
                                  ) +
                                  "\n                        "
                              ),
                            ]),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.validarVisibilidadeBotao("historicoVendas")
                      ? _c("li", { on: { click: _vm.toggleHistoricoVenda } }, [
                          _c("div", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "__.Components.parent.venda.editSingle_vue_html.historicoVendas"
                                )
                              )
                            ),
                          ]),
                        ])
                      : _vm._e(),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { attrs: { slot: "modalSlot3" }, slot: "modalSlot3" },
            [
              _c("logEntidadeComponent", {
                attrs: { registroId: _vm.model.id, entidade: 10 },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { attrs: { slot: "modalSlot4" }, slot: "modalSlot4" },
            [
              _c("linkPagamentoComponent", {
                attrs: { vendaId: _vm.model.id },
              }),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "form",
        {
          attrs: { novalidate: "novalidate", disabled: "", id: "form" },
          on: {
            submit: function ($event) {
              $event.preventDefault()
              _vm.save().withLoading()
            },
          },
        },
        [
          _c("div", { staticClass: "row", staticStyle: { display: "flex" } }, [
            _c(
              "div",
              { staticClass: "col-sm-12", staticStyle: { width: "100%" } },
              [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.situacaoVenda == 6,
                        expression: "situacaoVenda == 6",
                      },
                    ],
                    staticClass: "col-sm-12",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "alert alert-success",
                        attrs: { role: "alert" },
                      },
                      [
                        _vm._v(
                          "\n                        " +
                            _vm._s(
                              _vm.$t(
                                "__.Components.parent.venda.editSingle_vue_html.vendaJaConcluida"
                              )
                            ) +
                            "\n                    "
                        ),
                      ]
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.situacaoVenda == 5,
                        expression: "situacaoVenda == 5",
                      },
                    ],
                    staticClass: "col-sm-12",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "alert alert-danger",
                        attrs: { role: "alert" },
                      },
                      [
                        _vm._v(
                          "\n                        " +
                            _vm._s(
                              _vm.$t(
                                "__.Components.parent.venda.editSingle_vue_html.vendaJaCancelada"
                              )
                            ) +
                            "\n                    "
                        ),
                      ]
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    {
                      class:
                        "col-sm-" +
                        [
                          _vm.isFracionamento && _vm.model.nomeSeparador
                            ? "2"
                            : "4",
                        ],
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _vm.isFranqueador
                            ? _c("span", { staticClass: "color-red" }, [
                                _vm._v("*"),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "label",
                            {
                              staticClass: "control-label",
                              attrs: { for: "nomeVendedor" },
                            },
                            [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(
                                    _vm.$t(
                                      "__.Components.parent.venda.editSingle_vue_html.nomeVendedor"
                                    )
                                  ) +
                                  "\n                            "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _vm.isFranqueador
                            ? _c("combo-component", {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: "required",
                                    expression: "'required'",
                                  },
                                ],
                                attrs: {
                                  id: "nomeVendedor",
                                  name: "nomeVendedor",
                                  data: _vm.usuariosOptions,
                                  searchable: true,
                                },
                                model: {
                                  value: _vm.model.usuarioId,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.model, "usuarioId", $$v)
                                  },
                                  expression: "model.usuarioId",
                                },
                              })
                            : _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.model.nomeVendedor,
                                    expression: "model.nomeVendedor",
                                  },
                                ],
                                staticClass: "form-control",
                                attrs: {
                                  type: "text",
                                  id: "nomeVendedor",
                                  name: "nomeVendedor",
                                  autocomplete: "off",
                                  disabled: "",
                                },
                                domProps: { value: _vm.model.nomeVendedor },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.model,
                                      "nomeVendedor",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                        ],
                        1
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _vm.isFracionamento && _vm.model.nomeSeparador
                    ? _c("div", { staticClass: "col-sm-2" }, [
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _vm.isFranqueador
                              ? _c("span", { staticClass: "color-red" }, [
                                  _vm._v("*"),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "label",
                              {
                                staticClass: "control-label",
                                attrs: { for: "nomeSeparador" },
                              },
                              [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(
                                      _vm.$t(
                                        "__.Components.parent.venda.editSingle_vue_html.nomeSeparador"
                                      )
                                    ) +
                                    "\n                            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _vm.isFranqueador
                              ? _c("combo-component", {
                                  directives: [
                                    {
                                      name: "validate",
                                      rawName: "v-validate",
                                      value: "required",
                                      expression: "'required'",
                                    },
                                  ],
                                  attrs: {
                                    id: "nomeSeparador",
                                    name: "nomeSeparador",
                                    data: _vm.usuariosOptions,
                                    searchable: true,
                                  },
                                  model: {
                                    value: _vm.model.usuarioSeparacaoId,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.model,
                                        "usuarioSeparacaoId",
                                        $$v
                                      )
                                    },
                                    expression: "model.usuarioSeparacaoId",
                                  },
                                })
                              : _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.model.nomeSeparador,
                                      expression: "model.nomeSeparador",
                                    },
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    type: "text",
                                    id: "nomeSeparador",
                                    name: "nomeSeparador",
                                    autocomplete: "off",
                                    disabled: "",
                                  },
                                  domProps: { value: _vm.model.nomeSeparador },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.model,
                                        "nomeSeparador",
                                        $event.target.value
                                      )
                                    },
                                  },
                                }),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-2" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("tipoVenda"),
                            expression: "errors.first('tipoVenda')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: {
                              for: "tipoVenda",
                              title: _vm.$t("__.obrigatorio"),
                            },
                          },
                          [
                            _c("span", { staticClass: "color-red" }, [
                              _vm._v("*"),
                            ]),
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Components.parent.venda.editSingle_vue_html.tipoVenda"
                                  )
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("combo-component", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required",
                              expression: "'required'",
                            },
                          ],
                          attrs: {
                            id: "tipoVenda",
                            name: "tipoVenda",
                            disabled:
                              _vm.requisicaoApp || _vm.somenteConsultaVenda,
                            data: _vm.tipoVendaOptions,
                            searchable: true,
                          },
                          model: {
                            value: _vm.model.tipoVenda,
                            callback: function ($$v) {
                              _vm.$set(_vm.model, "tipoVenda", $$v)
                            },
                            expression: "model.tipoVenda",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-2" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("origemAtendimento"),
                            expression: "errors.first('origemAtendimento')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: {
                              for: "origemAtendimento",
                              title: _vm.$t("__.obrigatorio"),
                            },
                          },
                          [
                            _c("span", { staticClass: "color-red" }, [
                              _vm._v("*"),
                            ]),
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Components.parent.venda.editSingle_vue_html.origemAtend"
                                  )
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("combo-component", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required",
                              expression: "'required'",
                            },
                          ],
                          attrs: {
                            id: "origemAtendimento",
                            name: "origemAtendimento",
                            disabled:
                              _vm.requisicaoApp || _vm.somenteConsultaVenda,
                            data: _vm.origemAtendimentoOptions,
                            searchable: true,
                          },
                          model: {
                            value: _vm.model.origem,
                            callback: function ($$v) {
                              _vm.$set(_vm.model, "origem", $$v)
                            },
                            expression: "model.origem",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-2" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("dataEntrega"),
                            expression: "errors.first('dataEntrega')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "dataEntrega" },
                          },
                          [
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Components.parent.venda.editSingle_vue_html.dtHraEntrega"
                                  )
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("date-time-picker-component", {
                          attrs: {
                            id: "dataEntrega",
                            name: "dataEntrega",
                            pickTime: true,
                            disabled:
                              _vm.situacaoVenda == 6 ||
                              _vm.somenteConsultaVenda,
                          },
                          model: {
                            value: _vm.model.dataEntrega,
                            callback: function ($$v) {
                              _vm.$set(_vm.model, "dataEntrega", $$v)
                            },
                            expression: "model.dataEntrega",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-2" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("estoqueId"),
                            expression: "errors.first('estoqueId')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: {
                              for: "estoqueId",
                              title: _vm.$t("__.obrigatorio"),
                            },
                          },
                          [
                            _c("span", { staticClass: "color-red" }, [
                              _vm._v("*"),
                            ]),
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Components.parent.venda.editSingle_vue_html.estoqueOrigem"
                                  )
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("combo-component", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required",
                              expression: "'required'",
                            },
                          ],
                          attrs: {
                            id: "estoqueId",
                            name: "estoqueId",
                            data: _vm.estoqueOptions,
                            searchable: true,
                            disabled: _vm.situacaoVenda != 0,
                          },
                          model: {
                            value: _vm.model.estoqueId,
                            callback: function ($$v) {
                              _vm.$set(_vm.model, "estoqueId", $$v)
                            },
                            expression: "model.estoqueId",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _vm.franquiaAbrangencia
                  ? _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-sm-6" }, [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: _vm.errors.first("franquiaAbrangencia"),
                                expression:
                                  "errors.first('franquiaAbrangencia')",
                              },
                            ],
                            staticClass: "form-group",
                          },
                          [
                            _c(
                              "label",
                              {
                                staticClass: "control-label",
                                attrs: { for: "franquiaAbrangencia" },
                              },
                              [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(
                                      _vm.$t(
                                        "__.Components.parent.venda.editSingle_vue_html.franquiaAbrangencia"
                                      )
                                    ) +
                                    "\n                            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("combo-component", {
                              attrs: {
                                id: "franquiaAbrangencia",
                                name: "franquiaAbrangencia",
                                data: _vm.franquiaAbrangenciaOptions,
                                searchable: true,
                                disabled: _vm.somenteConsultaVenda,
                              },
                              model: {
                                value: _vm.model.franquiaAbrangenciaId,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.model,
                                    "franquiaAbrangenciaId",
                                    $$v
                                  )
                                },
                                expression: "model.franquiaAbrangenciaId",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.situacaoVenda != 0,
                        expression: "situacaoVenda != 0",
                      },
                    ],
                    staticClass: "row",
                  },
                  [
                    _c("div", { staticClass: "col-sm-4" }, [
                      _c(
                        "div",
                        {
                          class:
                            _vm.model.clienteId > 0 ? "col-sm-11" : "col-sm-12",
                          staticStyle: { padding: "0" },
                        },
                        [
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "tooltip",
                                  rawName: "v-tooltip",
                                  value: _vm.errors.first("clienteId"),
                                  expression: "errors.first('clienteId')",
                                },
                              ],
                              staticClass: "form-group",
                            },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "control-label",
                                  attrs: {
                                    for: "clienteId",
                                    title: _vm.$t("__.obrigatorio"),
                                  },
                                },
                                [
                                  _c("span", { staticClass: "color-red" }, [
                                    _vm._v("*"),
                                  ]),
                                  _vm._v(
                                    "\n                                    " +
                                      _vm._s(
                                        _vm.$t(
                                          "__.Components.parent.venda.editSingle_vue_html.cliente"
                                        )
                                      ) +
                                      "\n                                "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "search-combo-component",
                                {
                                  directives: [
                                    {
                                      name: "validate",
                                      rawName: "v-validate",
                                      value: "required",
                                      expression: "'required'",
                                    },
                                  ],
                                  attrs: {
                                    id: "clienteId",
                                    name: "clienteId",
                                    actionName: "clientes",
                                    addEditId: this.model.clienteId,
                                    addNew:
                                      !this.model.clienteId &&
                                      !_vm.requisicaoApp,
                                    advancedSearch: !_vm.requisicaoApp,
                                    advancedSearchCampos: [
                                      { campo: "text", descricao: "Nome" },
                                      { campo: "cpf", descricao: "CNPJ/CPF" },
                                      {
                                        campo: "celular",
                                        descricao: "Celular",
                                      },
                                    ],
                                    customComboText: _vm.customComboTextCliente,
                                    customView: true,
                                    disabled:
                                      _vm.requisicaoApp ||
                                      _vm.somenteConsultaVenda,
                                    formAddNewName: _vm.$t(
                                      "__.Components.parent.venda.editSingle_vue_html." +
                                        (this.model.clienteId
                                          ? "editCliente"
                                          : "novoCliente")
                                    ),
                                    filterable: false,
                                    propDescription: "nome",
                                    service: "Cliente",
                                    useCloseModalCallback: true,
                                  },
                                  on: {
                                    addNewItemCombo: _vm.onAddCliente1,
                                    openComboEditItem:
                                      _vm.openComboEditCliente1,
                                    openComboNewItem: _vm.openNewCliente1,
                                  },
                                  model: {
                                    value: _vm.model.clienteId,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.model, "clienteId", $$v)
                                    },
                                    expression: "model.clienteId",
                                  },
                                },
                                [
                                  _vm.loadClienteComponent1
                                    ? _c(
                                        "div",
                                        {
                                          attrs: { slot: "component" },
                                          slot: "component",
                                        },
                                        [
                                          _c("clienteComponent", {
                                            ref: "clienteComponent1",
                                            attrs: { CalledByShortCut: true },
                                            on: {
                                              "save-ok": _vm.onClienteSaveOk,
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _vm.model.clienteId > 0
                        ? _c(
                            "div",
                            { staticClass: "col-sm-1 input-with-icon" },
                            [
                              _c("div", { staticClass: "form-group" }, [
                                _c("label", {
                                  staticClass: "control-label",
                                  attrs: { for: "" },
                                }),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "input-group-addon form-control input-with-icon-wrapper",
                                    attrs: {
                                      title: "Histórico de vendas do cliente",
                                    },
                                    on: { click: _vm.onOpenVendasModal },
                                  },
                                  [
                                    _c("span", {
                                      staticClass: "fa fa-clipboard-list",
                                    }),
                                  ]
                                ),
                              ]),
                            ]
                          )
                        : _vm._e(),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              _vm.permiteNotaSemTomador &&
                              _vm.model.clienteId &&
                              !_vm.requisicaoApp,
                            expression:
                              "permiteNotaSemTomador && model.clienteId && !requisicaoApp",
                          },
                        ],
                        staticClass: "col-sm-2",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "identificarClienteNF" },
                          },
                          [
                            _vm._v(
                              "\n                            " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Components.parent.venda.editSingle_vue_html.identificarClienteNF"
                                  )
                                ) +
                                "\n                        "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("data-tooltip-component", {
                          attrs: {
                            text: _vm.$t(
                              "__.Components.parent.venda.editSingle_vue_html.tlpIdentificarClienteNF"
                            ),
                          },
                        }),
                        _vm._v(" "),
                        _c("checkbox-component", {
                          key: _vm.flagReload,
                          attrs: {
                            id: "identificarClienteNF",
                            name: "identificarClienteNF",
                            disabled: _vm.somenteConsultaVenda,
                          },
                          on: { change: _vm.onChangeFlagIdentificarClienteNF },
                          model: {
                            value: _vm.model.identificarClienteNF,
                            callback: function ($$v) {
                              _vm.$set(_vm.model, "identificarClienteNF", $$v)
                            },
                            expression: "model.identificarClienteNF",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.situacaoVenda != 6,
                            expression: "situacaoVenda != 6",
                          },
                        ],
                        staticClass: "col-sm-3",
                      },
                      [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: _vm.errors.first("dataAprovacao"),
                                expression: "errors.first('dataAprovacao')",
                              },
                            ],
                            staticClass: "form-group",
                          },
                          [
                            _c(
                              "label",
                              {
                                staticClass: "control-label",
                                attrs: { for: "dataAprovacao" },
                              },
                              [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(
                                      _vm.$t(
                                        "__.Components.parent.venda.editSingle_vue_html.dtAprov"
                                      )
                                    ) +
                                    "\n                            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("date-time-picker-component", {
                              attrs: {
                                id: "dataAprovacao",
                                name: "dataAprovacao",
                                disabled: _vm.somenteConsultaVenda,
                                pickTime: true,
                              },
                              model: {
                                value: _vm.model.dataAprovacao,
                                callback: function ($$v) {
                                  _vm.$set(_vm.model, "dataAprovacao", $$v)
                                },
                                expression: "model.dataAprovacao",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.situacaoVenda == 6,
                            expression: "situacaoVenda == 6",
                          },
                        ],
                        staticClass: "col-sm-3",
                      },
                      [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: _vm.errors.first("dataConclusao"),
                                expression: "errors.first('dataConclusao')",
                              },
                            ],
                            staticClass: "form-group",
                          },
                          [
                            _c(
                              "label",
                              {
                                staticClass: "control-label",
                                attrs: { for: "dataConclusao" },
                              },
                              [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(
                                      _vm.$t(
                                        "__.Components.parent.venda.editSingle_vue_html.dtConclusao"
                                      )
                                    ) +
                                    "\n                            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("date-time-picker-component", {
                              attrs: {
                                id: "dataConclusao",
                                name: "dataConclusao",
                                disabled: _vm.somenteConsultaVenda,
                              },
                              model: {
                                value: _vm.model.dataConclusao,
                                callback: function ($$v) {
                                  _vm.$set(_vm.model, "dataConclusao", $$v)
                                },
                                expression: "model.dataConclusao",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]
                ),
                _vm._v(" "),
                _vm.isFracionamento || _vm.isEstereis
                  ? _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-sm-2" }, [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: _vm.errors.first("setor"),
                                expression: "errors.first('setor')",
                              },
                            ],
                            staticClass: "form-group",
                          },
                          [
                            _c(
                              "label",
                              {
                                staticClass: "control-label",
                                attrs: {
                                  for: "setor",
                                  title: _vm.$t("__.obrigatorio"),
                                },
                              },
                              [
                                _c("span", { staticClass: "color-red" }, [
                                  _vm._v("*"),
                                ]),
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(
                                      _vm.$t(
                                        "__.Components.parent.venda.editSingle_vue_html.setor"
                                      )
                                    ) +
                                    "\n                            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("combo-component", {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "required",
                                  expression: "'required'",
                                },
                              ],
                              attrs: {
                                id: "setor",
                                name: "setor",
                                data: _vm.setorOptions,
                              },
                              model: {
                                value: _vm.model.setor,
                                callback: function ($$v) {
                                  _vm.$set(_vm.model, "setor", $$v)
                                },
                                expression: "model.setor",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.situacaoVenda != 0,
                        expression: "situacaoVenda != 0",
                      },
                    ],
                    staticClass: "row",
                  },
                  [
                    _c("div", { staticClass: "col-sm-12" }, [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: _vm.errors.first("observacao"),
                              expression: "errors.first('observacao')",
                            },
                          ],
                          staticClass: "form-group",
                        },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "control-label",
                              attrs: { for: "observacao" },
                            },
                            [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(
                                    _vm.$t(
                                      "__.Components.parent.venda.editSingle_vue_html.obs"
                                    )
                                  ) +
                                  "\n                            "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("textarea-component", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "max:1000",
                                expression: "'max:1000'",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: {
                              id: "observacao",
                              name: "observacao",
                              disabled: _vm.somenteConsultaVenda,
                              rows: 3,
                            },
                            model: {
                              value: _vm.model.observacao,
                              callback: function ($$v) {
                                _vm.$set(_vm.model, "observacao", $$v)
                              },
                              expression: "model.observacao",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { attrs: { slot: "rows" }, slot: "rows" },
                  [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: true,
                            expression: "true",
                          },
                        ],
                        staticClass: "col-md-12",
                        staticStyle: { "padding-right": "0" },
                      },
                      [
                        _c("div", { attrs: { slot: "rows" }, slot: "rows" }, [
                          _c(
                            "div",
                            { staticClass: "ajusta-titulo-editar" },
                            [
                              _c("itemwrapper-Component", {
                                ref: "vendaItemWrapperComponent",
                                attrs: {
                                  itensVenda: _vm.model.itens,
                                  situacaoVenda: _vm.model.situacao,
                                  tipoVenda: _vm.model.tipoVenda,
                                  indexVenda: _vm.indexVenda,
                                  isActiveTab: _vm.isActiveTab,
                                  clienteId: _vm.model.clienteId,
                                  pacienteId: _vm.pacienteId,
                                  estoqueId: _vm.model.estoqueId,
                                  tipoNFOptionsCallback: _vm.getNFOptions,
                                  nomeVendedor: _vm.model.nomeVendedor,
                                  vendaFaturada: _vm.vendaFaturada,
                                  vendaReplicada: _vm.model.vendaPaiId > 0,
                                },
                                on: {
                                  "inclusao-item": _vm.onAdicionarItem,
                                  "edicao-item": _vm.onEditSubmit,
                                  onUpdateItensFracionamento:
                                    _vm.onUpdateItensFracionamento,
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c("grid-component", {
                      attrs: {
                        columns: _vm.gridColumns,
                        data: _vm.model.itens,
                        "show-edit-item": true,
                        "show-remove-item":
                          _vm.etapaSelecionada < 1 ||
                          (_vm.model.notasFiscaisSaida.length == 0 &&
                            _vm.isFracionamento),
                        "show-table-foot": false,
                        "show-table-head": false,
                        showActionColumn: !_vm.somenteConsultaVenda,
                        startFilterKey: _vm.startFilterKey,
                      },
                      on: {
                        "edit-item": _vm.onEditItem,
                        "remove-item": _vm.onRemoveItem,
                        "change-sort": _vm.onChangeSort,
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "fieldset-component",
                  {
                    attrs: {
                      title: _vm.$t(
                        "__.Components.parent.venda.editSingle_vue_html.acrescimoDesc"
                      ),
                      collapsed: false,
                    },
                  },
                  [
                    _c("div", { attrs: { slot: "rows" }, slot: "rows" }, [
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-sm-1" }, [
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "control-label",
                                  attrs: { for: "acrescimoPercentual" },
                                },
                                [_vm._v("Acréscimo %")]
                              ),
                              _vm._v(" "),
                              _c("decimal-com-sinal-custom-component", {
                                staticClass: "form-control",
                                attrs: {
                                  id: "acrescimoPercentual",
                                  name: "acrescimoPercentual",
                                  signal: "%",
                                  disabled:
                                    _vm.somenteConsultaVenda ||
                                    _vm.vendaFaturada,
                                },
                                model: {
                                  value: _vm.model.acrescimoPercentual,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.model,
                                      "acrescimoPercentual",
                                      $$v
                                    )
                                  },
                                  expression: "model.acrescimoPercentual",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-sm-2" }, [
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "tooltip",
                                  rawName: "v-tooltip",
                                  value: _vm.errors.first("acrescimo"),
                                  expression: "errors.first('acrescimo')",
                                },
                              ],
                              staticClass: "form-group",
                              staticStyle: { padding: "0" },
                            },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "control-label",
                                  attrs: { for: "acrescimo" },
                                },
                                [
                                  _vm._v(
                                    "\n                                        " +
                                      _vm._s(
                                        _vm.$t(
                                          "__.Components.parent.venda.editSingle_vue_html.acrescimo"
                                        )
                                      ) +
                                      "\n                                    "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("moeda-component", {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: "required",
                                    expression: "'required'",
                                  },
                                ],
                                staticClass: "form-control",
                                attrs: {
                                  id: "acrescimo",
                                  name: "acrescimo",
                                  disabled:
                                    _vm.somenteConsultaVenda ||
                                    _vm.vendaFaturada,
                                },
                                on: { change: _vm.onChangeAcrescimo },
                                model: {
                                  value: _vm.model.acrescimo,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.model, "acrescimo", $$v)
                                  },
                                  expression: "model.acrescimo",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-sm-1" }, [
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "tooltip",
                                  rawName: "v-tooltip",
                                  value: _vm.errors.first("descontoPercentual"),
                                  expression:
                                    "errors.first('descontoPercentual')",
                                },
                              ],
                              staticClass: "form-group",
                            },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "control-label",
                                  attrs: { for: "descontoPercentual" },
                                },
                                [_vm._v("Desconto %")]
                              ),
                              _vm._v(" "),
                              _c("decimal-com-sinal-custom-component", {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: "max_value:100",
                                    expression: "'max_value:100'",
                                  },
                                ],
                                staticClass: "form-control",
                                attrs: {
                                  id: "descontoPercentual",
                                  name: "descontoPercentual",
                                  signal: "%",
                                  disabled:
                                    _vm.somenteConsultaVenda ||
                                    _vm.vendaFaturada,
                                },
                                model: {
                                  value: _vm.model.descontoPercentual,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.model,
                                      "descontoPercentual",
                                      $$v
                                    )
                                  },
                                  expression: "model.descontoPercentual",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-sm-2" }, [
                          _c(
                            "div",
                            {
                              staticClass: "col-sm-10",
                              staticStyle: { padding: "0" },
                            },
                            [
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "tooltip",
                                      rawName: "v-tooltip",
                                      value: _vm.errors.first("desconto"),
                                      expression: "errors.first('desconto')",
                                    },
                                  ],
                                  staticClass: "form-group",
                                },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "control-label",
                                      attrs: { for: "desconto" },
                                    },
                                    [
                                      _vm._v(
                                        "\n                                            " +
                                          _vm._s(
                                            _vm.$t(
                                              "__.Components.parent.venda.editSingle_vue_html.desconto"
                                            )
                                          ) +
                                          "\n                                        "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("moeda-component", {
                                    directives: [
                                      {
                                        name: "validate",
                                        rawName: "v-validate",
                                        value: "required",
                                        expression: "'required'",
                                      },
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      id: "desconto",
                                      name: "desconto",
                                      disabled:
                                        _vm.somenteConsultaVenda ||
                                        _vm.vendaFaturada,
                                    },
                                    on: { change: _vm.onChangeDesconto },
                                    model: {
                                      value: _vm.model.desconto,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.model, "desconto", $$v)
                                      },
                                      expression: "model.desconto",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "col-sm-1 input-with-icon" },
                            [
                              _c("div", { staticClass: "form-group" }, [
                                _c("label", {
                                  staticClass: "control-label",
                                  attrs: { for: "detalhesTaxaEntrega" },
                                }),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "input-group-addon form-control input-with-icon-wrapper",
                                    attrs: { title: "Cupom de desconto" },
                                    on: { click: _vm.toggleCupomComponent },
                                  },
                                  [
                                    _c("span", {
                                      staticClass: "fa fa-ticket-alt",
                                    }),
                                  ]
                                ),
                              ]),
                            ]
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-sm-6" }, [
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "tooltip",
                                  rawName: "v-tooltip",
                                  value: _vm.errors.first("observacaoDesconto"),
                                  expression:
                                    "errors.first('observacaoDesconto')",
                                },
                              ],
                              staticClass: "form-group",
                            },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "control-label",
                                  attrs: { for: "observacaoDesconto" },
                                },
                                [
                                  _vm.model.desconto > 0 &&
                                  _vm.observacaoDesconto
                                    ? _c("span", { staticClass: "color-red" }, [
                                        _vm._v(
                                          "\n                                            *\n                                        "
                                        ),
                                      ])
                                    : _vm._e(),
                                  _vm._v(
                                    "\n                                        " +
                                      _vm._s(
                                        _vm.$t(
                                          "__.Components.parent.venda.editSingle_vue_html.obsDesconto"
                                        )
                                      ) +
                                      "\n                                    "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.modelExtra.observacaoDesconto,
                                    expression: "modelExtra.observacaoDesconto",
                                  },
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: {
                                      rules: {
                                        max: 256,
                                        required:
                                          _vm.model.desconto > 0 &&
                                          _vm.observacaoDesconto,
                                      },
                                    },
                                    expression:
                                      "{\n                                            rules: {\n                                                max: 256,\n                                                required: model.desconto > 0 && observacaoDesconto,\n                                            },\n                                        }",
                                  },
                                ],
                                staticClass: "form-control",
                                attrs: {
                                  type: "text",
                                  id: "observacaoDesconto",
                                  name: "observacaoDesconto",
                                  autocomplete: "off",
                                },
                                domProps: {
                                  value: _vm.modelExtra.observacaoDesconto,
                                },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.modelExtra,
                                      "observacaoDesconto",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                            ]
                          ),
                        ]),
                        _vm._v(" "),
                        _vm.model.descontoFuncionario != null
                          ? _c("div", { staticClass: "col-sm-6" }, [
                              _c(
                                "div",
                                { staticClass: "form-group" },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "control-label",
                                      attrs: { for: "descontoFuncionario" },
                                    },
                                    [
                                      _vm._v(
                                        "\n                                        " +
                                          _vm._s(
                                            _vm.$t(
                                              "__.Components.parent.venda.editSingle_vue_html.descBeneficio"
                                            )
                                          ) +
                                          "\n                                    "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("moeda-component", {
                                    staticClass: "form-control",
                                    attrs: {
                                      id: "descontoFuncionario",
                                      name: "descontoFuncionario",
                                      disabled: "",
                                    },
                                    model: {
                                      value: _vm.model.descontoFuncionario,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.model,
                                          "descontoFuncionario",
                                          $$v
                                        )
                                      },
                                      expression: "model.descontoFuncionario",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.model.descontoPrescritor != null
                          ? _c("div", { staticClass: "col-sm-6" }, [
                              _c(
                                "div",
                                { staticClass: "form-group" },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "control-label",
                                      attrs: { for: "descontoPrescritor" },
                                    },
                                    [
                                      _vm._v(
                                        "\n                                        Desconto Prescritor\n                                    "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("moeda-component", {
                                    staticClass: "form-control",
                                    attrs: {
                                      id: "descontoPrescritor",
                                      name: "descontoPrescritor",
                                      disabled: "",
                                    },
                                    model: {
                                      value: _vm.model.descontoPrescritor,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.model,
                                          "descontoPrescritor",
                                          $$v
                                        )
                                      },
                                      expression: "model.descontoPrescritor",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ])
                          : _vm._e(),
                      ]),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "fieldset-component",
                  {
                    attrs: {
                      title: _vm.$t(
                        "__.Components.parent.notafiscalentrada.edit_vue_html.totais"
                      ),
                      collapsed: false,
                    },
                  },
                  [
                    _c("div", { attrs: { slot: "rows" }, slot: "rows" }, [
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-sm-1" }, [
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "control-label",
                                  attrs: { for: "entrega" },
                                },
                                [
                                  _vm._v(
                                    "\n                                        " +
                                      _vm._s(
                                        _vm.$t(
                                          "__.Components.parent.venda.editSingle_vue_html.entrega"
                                        )
                                      ) +
                                      "\n                                    "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("checkbox-component", {
                                attrs: {
                                  id: "Entrega",
                                  name: "Entrega",
                                  disabled: _vm.somenteConsultaVenda,
                                },
                                on: { change: _vm.onChangeEntrega },
                                model: {
                                  value: _vm.entrega,
                                  callback: function ($$v) {
                                    _vm.entrega = $$v
                                  },
                                  expression: "entrega",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-sm-3" }, [
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "tooltip",
                                  rawName: "v-tooltip",
                                  value: _vm.errors.first("localEntregaId"),
                                  expression: "errors.first('localEntregaId')",
                                },
                              ],
                              staticClass: "form-group",
                            },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "control-label",
                                  attrs: { for: "localEntregaId" },
                                },
                                [
                                  _vm.entrega
                                    ? _c("span", { staticClass: "color-red" }, [
                                        _vm._v("*"),
                                      ])
                                    : _vm._e(),
                                  _vm._v(
                                    "\n                                        " +
                                      _vm._s(
                                        _vm.$t(
                                          "__.Components.parent.venda.editSingle_vue_html.localEntrega"
                                        )
                                      ) +
                                      "\n                                    "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("combo-component", {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: {
                                      rules: {
                                        required:
                                          _vm.entrega && "maior_que_zero",
                                      },
                                    },
                                    expression:
                                      "{\n                                            rules: {\n                                                required: entrega && 'maior_que_zero',\n                                            },\n                                        }",
                                  },
                                ],
                                attrs: {
                                  id: "localEntregaId",
                                  name: "localEntregaId",
                                  data: _vm.locaisEntregaOptions,
                                  searchable: true,
                                  customView: true,
                                  disabled:
                                    _vm.somenteConsultaVenda || !_vm.entrega,
                                },
                                model: {
                                  value: _vm.model.localEntregaId,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.model, "localEntregaId", $$v)
                                  },
                                  expression: "model.localEntregaId",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-sm-2" }, [
                          _c(
                            "div",
                            {
                              class:
                                _vm.model.clienteId > 0
                                  ? "col-sm-10"
                                  : "col-sm-12",
                              staticStyle: { padding: "0" },
                            },
                            [
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "tooltip",
                                      rawName: "v-tooltip",
                                      value: _vm.errors.first("taxaEntrega"),
                                      expression: "errors.first('taxaEntrega')",
                                    },
                                  ],
                                  staticClass: "form-group",
                                },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "control-label",
                                      attrs: { for: "taxaEntrega" },
                                    },
                                    [
                                      _vm._v(
                                        "\n                                            " +
                                          _vm._s(
                                            _vm.$t(
                                              "__.Components.parent.venda.editSingle_vue_html.taxaEntrega"
                                            )
                                          ) +
                                          "\n                                        "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("moeda-component", {
                                    directives: [
                                      {
                                        name: "validate",
                                        rawName: "v-validate",
                                        value: "required",
                                        expression: "'required'",
                                      },
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      id: "taxaEntrega",
                                      name: "taxaEntrega",
                                      disabled: _vm.somenteConsultaVenda,
                                    },
                                    on: { change: _vm.onChangeTaxaEntrega },
                                    model: {
                                      value: _vm.model.taxaEntrega,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.model, "taxaEntrega", $$v)
                                      },
                                      expression: "model.taxaEntrega",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _vm.model.clienteId > 0
                            ? _c(
                                "div",
                                { staticClass: "col-sm-2 input-with-icon" },
                                [
                                  _c("div", { staticClass: "form-group" }, [
                                    _c("label", {
                                      staticClass: "control-label",
                                      attrs: { for: "detalhesTaxaEntrega" },
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "input-group-addon form-control input-with-icon-wrapper",
                                        on: {
                                          click: _vm.toggleEntregaComponent,
                                        },
                                      },
                                      [
                                        _c("span", {
                                          staticClass: "fa fa-edit",
                                        }),
                                      ]
                                    ),
                                  ]),
                                ]
                              )
                            : _vm._e(),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-sm-3" }, [
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "control-label",
                                  attrs: { for: "valorProdutos" },
                                },
                                [
                                  _vm._v(
                                    "\n                                        " +
                                      _vm._s(
                                        _vm.$t(
                                          "__.Components.parent.venda.editSingle_vue_html.vlrProds"
                                        )
                                      ) +
                                      "\n                                    "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("moeda-component", {
                                staticClass: "form-control",
                                attrs: {
                                  id: "valorProdutos",
                                  name: "valorProdutos",
                                  disabled: "",
                                },
                                model: {
                                  value: _vm.model.valorProdutos,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.model, "valorProdutos", $$v)
                                  },
                                  expression: "model.valorProdutos",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-sm-3" }, [
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "control-label",
                                  attrs: { for: "valorFinal" },
                                },
                                [
                                  _vm._v(
                                    "\n                                        " +
                                      _vm._s(
                                        _vm.$t(
                                          "__.Components.parent.venda.editSingle_vue_html.vlrFinal"
                                        )
                                      ) +
                                      "\n                                    "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("moeda-component", {
                                staticClass: "form-control",
                                attrs: {
                                  id: "valorFinal",
                                  name: "valorFinal",
                                  disabled: "",
                                },
                                model: {
                                  value: _vm.model.valorFinal,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.model, "valorFinal", $$v)
                                  },
                                  expression: "model.valorFinal",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ]),
                    ]),
                  ]
                ),
                _vm._v(" "),
                (_vm.modelId > 0 && _vm.model.notasFiscaisSaida.length > 0) ||
                (_vm.model.notasFiscaisSaida.length == 0 &&
                  _vm.isFracionamento &&
                  _vm.situacaoVenda >= 8) ||
                (_vm.exibirNF && _vm.model.situacao == 2) ||
                _vm.model.situacao == 4 ||
                _vm.model.situacao == 6
                  ? _c(
                      "fieldset-component",
                      { attrs: { title: "Dados Fiscais", collapsed: false } },
                      [
                        _c("div", { attrs: { slot: "rows" }, slot: "rows" }, [
                          _vm.modelId > 0 &&
                          _vm.model.notasFiscaisSaida.length > 0
                            ? _c(
                                "div",
                                [
                                  _c("grid-component", {
                                    attrs: {
                                      gridNameId: "DadosfiscaisVendaGrid",
                                      data: _vm.model.notasFiscaisSaida,
                                      columns: _vm.gridColumnsNotas,
                                      "show-table-head": false,
                                      "show-table-foot": false,
                                      showActionColumn:
                                        !_vm.somenteConsultaVenda ||
                                        _vm.VALIDAR_PERMISSAO_USUARIO(
                                          "vendas",
                                          "EmitirNotaVenda"
                                        ),
                                      "show-edit-item": false,
                                      "show-remove-item": false,
                                      extraActions: _vm.extraActions,
                                    },
                                    on: { "extra-action": _vm.onExtraAction },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.VALIDAR_PERMISSAO_USUARIO(
                            "vendas",
                            "EmitirNotaVenda"
                          ) &&
                          ((_vm.exibirNF && _vm.model.situacao == 2) ||
                            _vm.model.situacao == 4)
                            ? _c(
                                "div",
                                { staticClass: "row" },
                                _vm._l(_vm.NFops, function (ctx, tipo) {
                                  return _c(
                                    "div",
                                    { key: tipo, staticClass: "col-sm-4" },
                                    [
                                      !_vm.isFracionamento &&
                                      ctx.totalItens &&
                                      !ctx.foiEmitida
                                        ? _c(
                                            "div",
                                            { staticClass: "form-group" },
                                            [
                                              _vm._v(
                                                "\n                                    " +
                                                  _vm._s(
                                                    _vm.NFinfo[tipo].desc
                                                  ) +
                                                  "\n                                    "
                                              ),
                                              _c(
                                                "label",
                                                {
                                                  attrs: {
                                                    for:
                                                      "emitir" +
                                                      _vm.NFinfo[tipo].name +
                                                      "Automatico",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                        " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "__.Components.parent.venda.editSingle_vue_html.emitiNFEAposSalvarConcluir"
                                                        )
                                                      ) +
                                                      "\n                                    "
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c("checkbox-component", {
                                                attrs: {
                                                  id: "'emitir' + NFinfo[tipo].name + 'Automatico'",
                                                  name: "'emitir' + NFinfo[tipo].name + 'Automatico'",
                                                  disabled:
                                                    _vm.somenteConsultaVenda,
                                                },
                                                model: {
                                                  value:
                                                    ctx.emitirAutomaticamente,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      ctx,
                                                      "emitirAutomaticamente",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "ctx.emitirAutomaticamente",
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ]
                                  )
                                })
                              )
                            : _vm._e(),
                        ]),
                        _vm._v(" "),
                        _vm.model.notasFiscaisSaida.length == 0 &&
                        _vm.isFracionamento &&
                        _vm.situacaoVenda >= 8
                          ? _c(
                              "div",
                              { attrs: { slot: "rows" }, slot: "rows" },
                              [
                                _c("div", { staticClass: "col-sm-6" }, [
                                  _c(
                                    "div",
                                    { staticClass: "form-group" },
                                    [
                                      _vm.model.notasFiscaisSaida.length == 0
                                        ? _c(
                                            "label",
                                            {
                                              attrs: {
                                                for: "permitirFinalizarVenda",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                                    " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "__.Components.parent.venda.editSingle_vue_html.permitirFinalSemEmissao"
                                                    )
                                                  ) +
                                                  "\n                                "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.model.notasFiscaisSaida.length == 0
                                        ? _c("checkbox-component", {
                                            attrs: {
                                              id: "finalizarSemNota",
                                              name: "finalizarSemNota",
                                              disabled:
                                                _vm.somenteConsultaVenda,
                                            },
                                            model: {
                                              value: _vm.finalizarSemNota,
                                              callback: function ($$v) {
                                                _vm.finalizarSemNota = $$v
                                              },
                                              expression: "finalizarSemNota",
                                            },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ]),
                              ]
                            )
                          : _vm._e(),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "fieldset-component",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.situacaoVenda == 0,
                        expression: "situacaoVenda == 0",
                      },
                    ],
                    staticClass: "ignorePrint",
                    attrs: {
                      title: _vm.$t(
                        "__.Components.parent.venda.editSingle_vue_html.dadosComplementOrcamento"
                      ),
                      collapsed: false,
                    },
                  },
                  [
                    _c("div", { attrs: { slot: "rows" }, slot: "rows" }, [
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-sm-6" }, [
                          _c(
                            "div",
                            {
                              class:
                                _vm.model.clienteId > 0
                                  ? "col-sm-11"
                                  : "col-sm-12",
                              staticStyle: { padding: "0" },
                            },
                            [
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "tooltip",
                                      rawName: "v-tooltip",
                                      value: _vm.errors.first("clienteId"),
                                      expression: "errors.first('clienteId')",
                                    },
                                  ],
                                  staticClass: "form-group",
                                },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "control-label",
                                      attrs: {
                                        for: "clienteId",
                                        title: _vm.$t("__.obrigatorio"),
                                      },
                                    },
                                    [
                                      _c("span", { staticClass: "color-red" }, [
                                        _vm._v("*"),
                                      ]),
                                      _vm._v(
                                        "\n                                            " +
                                          _vm._s(
                                            _vm.$t(
                                              "__.Components.parent.venda.editSingle_vue_html.cliente"
                                            )
                                          ) +
                                          "\n                                        "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "search-combo-component",
                                    {
                                      directives: [
                                        {
                                          name: "validate",
                                          rawName: "v-validate",
                                          value: "required",
                                          expression: "'required'",
                                        },
                                      ],
                                      attrs: {
                                        id: "clienteId",
                                        name: "clienteId",
                                        actionName: "clientes",
                                        addEditId: this.model.clienteId,
                                        addNew:
                                          !this.model.clienteId &&
                                          !_vm.requisicaoApp,
                                        advancedSearch: !_vm.requisicaoApp,
                                        advancedSearchCampos: [
                                          { campo: "text", descricao: "Nome" },
                                          {
                                            campo: "cpf",
                                            descricao: "CNPJ/CPF",
                                          },
                                          {
                                            campo: "celular",
                                            descricao: "Celular",
                                          },
                                        ],
                                        customComboText:
                                          _vm.customComboTextCliente,
                                        customView: true,
                                        disabled:
                                          _vm.requisicaoApp ||
                                          _vm.somenteConsultaVenda,
                                        formAddNewName: _vm.$t(
                                          "__.Components.parent.venda.editSingle_vue_html." +
                                            (this.model.clienteId
                                              ? "editCliente"
                                              : "novoCliente")
                                        ),
                                        filterable: false,
                                        propDescription: "nome",
                                        service: "Cliente",
                                        useCloseModalCallback: true,
                                      },
                                      on: {
                                        addNewItemCombo: _vm.onAddCliente2,
                                        openComboEditItem:
                                          _vm.openComboEditCliente2,
                                        openComboNewItem: _vm.openNewCliente2,
                                      },
                                      model: {
                                        value: _vm.model.clienteId,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.model, "clienteId", $$v)
                                        },
                                        expression: "model.clienteId",
                                      },
                                    },
                                    [
                                      _vm.loadClienteComponent2
                                        ? _c(
                                            "div",
                                            {
                                              attrs: { slot: "component" },
                                              slot: "component",
                                            },
                                            [
                                              _c("clienteComponent", {
                                                ref: "clienteComponent2",
                                                attrs: {
                                                  CalledByShortCut: true,
                                                },
                                                on: {
                                                  "save-ok":
                                                    _vm.onClienteSaveOk,
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _vm.model.clienteId > 0
                            ? _c(
                                "div",
                                { staticClass: "col-sm-1 input-with-icon" },
                                [
                                  _c("div", { staticClass: "form-group" }, [
                                    _c("label", {
                                      staticClass: "control-label",
                                      attrs: { for: "" },
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "input-group-addon form-control input-with-icon-wrapper",
                                        attrs: {
                                          title:
                                            "Histórico de vendas do cliente",
                                        },
                                        on: { click: _vm.onOpenVendasModal },
                                      },
                                      [
                                        _c("span", {
                                          staticClass: "fa fa-clipboard-list",
                                        }),
                                      ]
                                    ),
                                  ]),
                                ]
                              )
                            : _vm._e(),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  _vm.permiteNotaSemTomador &&
                                  _vm.model.clienteId &&
                                  !_vm.requisicaoApp,
                                expression:
                                  "permiteNotaSemTomador && model.clienteId && !requisicaoApp",
                              },
                            ],
                            staticClass: "col-sm-2",
                          },
                          [
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "tooltip",
                                    rawName: "v-tooltip",
                                    value: _vm.errors.first(
                                      "identificarClienteNF"
                                    ),
                                    expression:
                                      "errors.first('identificarClienteNF')",
                                  },
                                ],
                                staticClass: "form-group",
                              },
                              [
                                _c(
                                  "label",
                                  {
                                    staticClass: "control-label",
                                    attrs: { for: "identificarClienteNF" },
                                  },
                                  [
                                    _vm._v(
                                      "\n                                        " +
                                        _vm._s(
                                          _vm.$t(
                                            "__.Components.parent.venda.editSingle_vue_html.identificarClienteNF"
                                          )
                                        ) +
                                        "\n                                    "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c("data-tooltip-component", {
                                  attrs: {
                                    text: _vm.$t(
                                      "__.Components.parent.venda.editSingle_vue_html.tlpIdentificarClienteNF"
                                    ),
                                  },
                                }),
                                _vm._v(" "),
                                _c("checkbox-component", {
                                  key: _vm.flagReload,
                                  attrs: {
                                    id: "identificarClienteNF",
                                    name: "identificarClienteNF",
                                    disabled: _vm.somenteConsultaVenda,
                                  },
                                  on: {
                                    change:
                                      _vm.onChangeFlagIdentificarClienteNF,
                                  },
                                  model: {
                                    value: _vm.model.identificarClienteNF,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.model,
                                        "identificarClienteNF",
                                        $$v
                                      )
                                    },
                                    expression: "model.identificarClienteNF",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            class: _vm.model.clienteId
                              ? "col-sm-2"
                              : "col-sm-3",
                          },
                          [
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "tooltip",
                                    rawName: "v-tooltip",
                                    value: _vm.errors.first("dataInclusao"),
                                    expression: "errors.first('dataInclusao')",
                                  },
                                ],
                                staticClass: "form-group",
                              },
                              [
                                _c(
                                  "label",
                                  {
                                    staticClass: "control-label",
                                    attrs: { for: "dataInclusao" },
                                  },
                                  [
                                    _vm._v(
                                      "\n                                        " +
                                        _vm._s(
                                          _vm.$t(
                                            "__.Components.parent.venda.editSingle_vue_html.dtInclusao"
                                          )
                                        ) +
                                        "\n                                    "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c("date-time-picker-component", {
                                  attrs: {
                                    id: "dataInclusao",
                                    name: "dataInclusao",
                                    disabled: "disabled",
                                  },
                                  model: {
                                    value: _vm.model.dataInclusao,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.model, "dataInclusao", $$v)
                                    },
                                    expression: "model.dataInclusao",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            class: _vm.model.clienteId
                              ? "col-sm-2"
                              : "col-sm-3",
                          },
                          [
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "tooltip",
                                    rawName: "v-tooltip",
                                    value: _vm.errors.first(
                                      "dataValidadeOrcamento"
                                    ),
                                    expression:
                                      "errors.first('dataValidadeOrcamento')",
                                  },
                                ],
                                staticClass: "form-group",
                              },
                              [
                                _c(
                                  "label",
                                  {
                                    staticClass: "control-label",
                                    attrs: { for: "dataValidadeOrcamento" },
                                  },
                                  [
                                    _vm._v(
                                      "\n                                        " +
                                        _vm._s(
                                          _vm.$t(
                                            "__.Components.parent.venda.editSingle_vue_html.dtValOrcamento"
                                          )
                                        ) +
                                        "\n                                    "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c("date-time-picker-component", {
                                  attrs: {
                                    id: "dataValidadeOrcamento",
                                    name: "dataValidadeOrcamento",
                                    disabled: _vm.somenteConsultaVenda,
                                    pickTime: true,
                                  },
                                  model: {
                                    value: _vm.model.dataValidadeOrcamento,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.model,
                                        "dataValidadeOrcamento",
                                        $$v
                                      )
                                    },
                                    expression: "model.dataValidadeOrcamento",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-sm-12" }, [
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "tooltip",
                                  rawName: "v-tooltip",
                                  value: _vm.errors.first("observacao"),
                                  expression: "errors.first('observacao')",
                                },
                              ],
                              staticClass: "form-group",
                            },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "control-label",
                                  attrs: { for: "observacao" },
                                },
                                [
                                  _vm._v(
                                    "\n                                        " +
                                      _vm._s(
                                        _vm.$t(
                                          "__.Components.parent.venda.editSingle_vue_html.obs"
                                        )
                                      ) +
                                      "\n                                    "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("textarea-component", {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: "max:1000",
                                    expression: "'max:1000'",
                                  },
                                ],
                                staticClass: "form-control",
                                attrs: {
                                  id: "observacao",
                                  name: "observacao",
                                  disabled: _vm.somenteConsultaVenda,
                                  rows: 3,
                                },
                                model: {
                                  value: _vm.model.observacao,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.model, "observacao", $$v)
                                  },
                                  expression: "model.observacao",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ]),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _vm.campanhasOptions.length > 0 &&
                this.model.clienteCpfCnpj != null &&
                _vm.situacaoVenda == 0
                  ? _c(
                      "fieldset-component",
                      {
                        attrs: {
                          title: "Dados Promocionais",
                          collapsed: false,
                        },
                      },
                      [
                        _c("div", { attrs: { slot: "rows" }, slot: "rows" }, [
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col-sm-4" }, [
                              _c(
                                "div",
                                { staticClass: "form-group" },
                                [
                                  _c(
                                    "label",
                                    { staticClass: "control-label" },
                                    [
                                      _vm._v(
                                        "\n                                        " +
                                          _vm._s(
                                            _vm.$t(
                                              "__.Components.parent.venda.editSingle_vue_html.campanha"
                                            )
                                          ) +
                                          "\n                                    "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("combo-component", {
                                    attrs: {
                                      id: "campanhaId",
                                      name: "campanhaId",
                                      data: _vm.campanhasOptions,
                                      searchable: false,
                                      disabled: _vm.somenteConsultaVenda,
                                    },
                                    model: {
                                      value: _vm.model.campanhaId,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.model, "campanhaId", $$v)
                                      },
                                      expression: "model.campanhaId",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "col-sm-4" }, [
                              _c(
                                "div",
                                { staticClass: "form-group" },
                                [
                                  _c(
                                    "label",
                                    { staticClass: "control-label" },
                                    [
                                      _vm._v(
                                        "\n                                        " +
                                          _vm._s(
                                            _vm.$t(
                                              "__.Components.parent.venda.editSingle_vue_html.ptsAcumulados"
                                            )
                                          ) +
                                          "\n                                    "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("decimal-component", {
                                    staticClass: "form-control",
                                    attrs: {
                                      id: "pontosAcumulados",
                                      name: "pontosAcumulados",
                                      disabled: "",
                                    },
                                    model: {
                                      value: _vm.pontosAcumulados,
                                      callback: function ($$v) {
                                        _vm.pontosAcumulados = $$v
                                      },
                                      expression: "pontosAcumulados",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                            _vm._v(" "),
                            _vm.pontosAcumulados > 0
                              ? _c("div", { staticClass: "col-sm-2" }, [
                                  _c(
                                    "div",
                                    { staticClass: "form-group" },
                                    [
                                      _c(
                                        "label",
                                        {
                                          staticClass: "control-label",
                                          attrs: { for: "cashback" },
                                        },
                                        [
                                          _vm._v(
                                            "\n                                        " +
                                              _vm._s(
                                                _vm.$t(
                                                  "__.Components.parent.venda.editSingle_vue_html.cashback"
                                                )
                                              ) +
                                              "\n                                    "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("moeda-component", {
                                        staticClass: "form-control",
                                        attrs: {
                                          id: "cashback",
                                          name: "cashback",
                                          disabled: "",
                                        },
                                        model: {
                                          value: _vm.model.cashback,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.model, "cashback", $$v)
                                          },
                                          expression: "model.cashback",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.recompensasOptions.length > 1
                              ? _c("div", { staticClass: "col-sm-4" }, [
                                  _c(
                                    "div",
                                    { staticClass: "form-group" },
                                    [
                                      _c(
                                        "label",
                                        { staticClass: "control-label" },
                                        [
                                          _vm._v(
                                            "\n                                        " +
                                              _vm._s(
                                                _vm.$t(
                                                  "__.Components.parent.venda.editSingle_vue_html.recompensas"
                                                )
                                              ) +
                                              "\n                                    "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("combo-component", {
                                        attrs: {
                                          id: "recompensas",
                                          name: "recompensas",
                                          data: _vm.recompensasOptions,
                                          searchable: false,
                                          disabled: _vm.somenteConsultaVenda,
                                        },
                                        model: {
                                          value: _vm.model.recompensaId,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.model,
                                              "recompensaId",
                                              $$v
                                            )
                                          },
                                          expression: "model.recompensaId",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ])
                              : _vm._e(),
                          ]),
                        ]),
                      ]
                    )
                  : _vm._e(),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticStyle: {
                  display: "flex",
                  "justify-content": "center",
                  width: "200px",
                },
              },
              [
                _c(
                  "ul",
                  { staticClass: "progressbar" },
                  [
                    _vm._l(_vm.etapasProgressbar, function (etapa, indexEtapa) {
                      return _c(
                        "li",
                        {
                          key: etapa,
                          class: {
                            active: indexEtapa < _vm.etapaSelecionada,
                            pos: indexEtapa == _vm.etapaSelecionada,
                            disabled: _vm.somenteConsultaVenda,
                          },
                          on: {
                            click: function ($event) {
                              _vm.atualizarEtapaVenda(indexEtapa)
                            },
                          },
                        },
                        [_c("span", [_vm._v(_vm._s(etapa))])]
                      )
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "total-lateral-bar" }, [
                      _vm._m(0),
                      _vm._v(" "),
                      _c("b", [
                        _vm._v(
                          _vm._s(_vm._f("filterMoeda")(_vm.model.valorFinal))
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticStyle: { "margin-top": "20px" } }, [
                      _c("img", {
                        attrs: {
                          width: "60px",
                          src: require("../../../image/ia.png"),
                        },
                        on: { click: _vm.toggleOverlay },
                      }),
                    ]),
                  ],
                  2
                ),
              ]
            ),
          ]),
          _vm._v(" "),
          _c("fieldset", { staticClass: "ignorePrint" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-sm-12" }, [
                _c(
                  "div",
                  { staticClass: "newbtn" },
                  [
                    _c("buttonScComponent", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.validarVisibilidadeBotao("salvarImprimir"),
                          expression:
                            "validarVisibilidadeBotao('salvarImprimir')",
                        },
                      ],
                      attrs: {
                        classButton: "btn btn-success",
                        classIcon: "fa fa-save",
                        buttonName: _vm.imprimirVendaAoSalvar
                          ? _vm.$t(
                              "__.Components.parent.venda.editSingle_vue_html.salvarImprimir"
                            )
                          : _vm.$t("__.Crud.salvar"),
                        shortKeyBt:
                          _vm.isActiveTab && _vm.inFocus() ? "s" : null,
                        type: "submit",
                      },
                    }),
                    _vm._v(" "),
                    _vm.validarVisibilidadeBotao("aprovarOrcamento")
                      ? _c("buttonScComponent", {
                          attrs: {
                            classButton: "btn btn-primary",
                            classIcon: "fa fa-check",
                            buttonName: _vm.$t(
                              "__.Components.parent.venda.editSingle_vue_html.aprovOrcamento"
                            ),
                          },
                          on: { click: _vm.avancarEtapa },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.validarVisibilidadeBotao("emitirNota")
                      ? _c("buttonScComponent", {
                          attrs: {
                            classButton: "btn btn-warning",
                            buttonName: _vm.$t(
                              "__.Components.parent.venda.editSingle_vue_html.emitirNota"
                            ),
                          },
                          on: {
                            click: function ($event) {
                              _vm.save(false, true).withLoading()
                            },
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.validarVisibilidadeBotao("emitirNotaTransporte")
                      ? _c("buttonScComponent", {
                          attrs: {
                            classButton: "btn btn-info",
                            buttonName: _vm.$t(
                              "__.Components.parent.venda.editSingle_vue_html.notaTransporte"
                            ),
                          },
                          on: {
                            click: function ($event) {
                              _vm.emitirNotaTransporte().withLoading()
                            },
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.validarVisibilidadeBotao("concluir")
                      ? _c("buttonScComponent", {
                          staticClass: "btn btn-primary pull-right",
                          staticStyle: {
                            "background-color": "coral !important",
                          },
                          attrs: {
                            classIcon: "fa fa-check",
                            buttonName: _vm.$t(
                              "__.Components.parent.venda.editSingle_vue_html.concluir"
                            ),
                          },
                          on: {
                            click: function ($event) {
                              _vm.concluir()
                            },
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.validarVisibilidadeBotao("faturar")
                      ? _c("buttonScComponent", {
                          attrs: {
                            classButton: "btn btn-primary",
                            classIcon: "fa fa-check",
                            buttonName: _vm.$t(
                              "__.Components.parent.venda.editSingle_vue_html.faturar"
                            ),
                          },
                          on: { click: _vm.avancarEtapa },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _c("buttonScComponent", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.validarVisibilidadeBotao("preFaturamento"),
                          expression:
                            "validarVisibilidadeBotao('preFaturamento')",
                        },
                      ],
                      attrs: {
                        classButton: "btn btn-pay",
                        buttonName: _vm.$t("__.ts.preFaturamento"),
                        shortKeyBt:
                          _vm.isActiveTab && _vm.inFocus() ? "p" : null,
                      },
                      on: { click: _vm.onPreFaturamento },
                    }),
                    _vm._v(" "),
                    _c("buttonScComponent", {
                      attrs: {
                        classButton: "btn btn-secondary",
                        buttonName: _vm.$t("__.Crud.voltar"),
                        shortKeyBt:
                          _vm.isActiveTab && _vm.inFocus() ? "f" : null,
                      },
                      on: {
                        click: function ($event) {
                          _vm.cancel()
                        },
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      [
        _c(
          "shortcutComponent",
          {
            ref: "shortCutRevisaoCampos",
            attrs: {
              btOkName: _vm.$t(
                "__.Components.parent.venda.editSingle_vue_html.confirAlteracoes"
              ),
              btOkShortKey: _vm.isActiveTab && !_vm.inFocus() ? "a" : null,
              showAddNew: false,
            },
            on: { "selection-item": _vm.onConfirmRevisao },
          },
          [
            _vm.loadRevisaoComponent
              ? _c(
                  "div",
                  { attrs: { slot: "component" }, slot: "component" },
                  [
                    _c("revisao-campos-component", {
                      ref: "revisaoCamposComponent",
                      attrs: {
                        indexVenda: _vm.indexVenda,
                        clienteId: _vm.model.clienteId,
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ]
        ),
        _vm._v(" "),
        _c(
          "shortcutComponent",
          {
            ref: "shortCutUploadReceita",
            attrs: {
              btOkName: "Ok",
              showAddNew: false,
              modalClass: "lm-modal",
            },
            on: {
              "selection-item": function ($event) {
                _vm.toggleUploadReceita(false)
              },
            },
          },
          [
            _vm.loadUploadReceitaComponent
              ? _c(
                  "div",
                  { attrs: { slot: "component" }, slot: "component" },
                  [
                    _c("uploadReceitaComponent", {
                      ref: "uploadReceitaComponent",
                      attrs: { vendaId: _vm.model.id },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ]
        ),
        _vm._v(" "),
        _c(
          "shortcutComponent",
          {
            ref: "shortcutComponentEntrega",
            attrs: { btOkName: "Ok", showAddNew: false },
            on: {
              "selection-item": _vm.onConfirmEndereco,
              "hide-click": _vm.hideEnderecoEntrega,
            },
          },
          [
            _vm.loadEnderecoEntrega && _vm.model.clienteId > 0
              ? _c(
                  "div",
                  { attrs: { slot: "component" }, slot: "component" },
                  [
                    _c("endereco-entrega-component", {
                      ref: "enderecoEntregaComponent",
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ]
        ),
        _vm._v(" "),
        _c(
          "shortcutComponent",
          {
            ref: "shortcutComponentFinanceiro",
            attrs: { modalClass: "lg-modal" },
            on: { "selection-item": _vm.onConfirmFinanceiro },
          },
          [
            _vm.loadPagamentoComponent && _vm.permiteParcelamentoVenda
              ? _c(
                  "div",
                  { attrs: { slot: "component" }, slot: "component" },
                  [
                    _c("financeiro-component", {
                      ref: "financeiroComponent",
                      attrs: {
                        situacaoVenda: _vm.model.situacao,
                        codigoVenda: _vm.model.codigo,
                      },
                      on: { "remove-pagamento": _vm.onRemovePagamento },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ]
        ),
        _vm._v(" "),
        _c(
          "shortcutComponent",
          {
            ref: "shortcutComponent",
            attrs: { modalClass: "lm-modal" },
            on: { "selection-item": _vm.onConfirmFinanceiro },
          },
          [
            _vm.loadPagamentoComponent && !_vm.permiteParcelamentoVenda
              ? _c(
                  "div",
                  { attrs: { slot: "component" }, slot: "component" },
                  [
                    _c(
                      "vue-tabs",
                      {
                        ref: "tabFinanceiro",
                        model: {
                          value: _vm.tabFinanceiro,
                          callback: function ($$v) {
                            _vm.tabFinanceiro = $$v
                          },
                          expression: "tabFinanceiro",
                        },
                      },
                      [
                        _c(
                          "v-tab",
                          {
                            attrs: {
                              title: _vm.$t(
                                "__.Components.parent.venda.editSingle_vue_html.novoPgto"
                              ),
                            },
                          },
                          [
                            _c("div", { staticClass: "row" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "col-sm-12",
                                  attrs: { id: "inclusaoPagamento" },
                                },
                                [
                                  _c("pagamento-component", {
                                    ref: "pagamentoComponent",
                                    on: {
                                      "inclusao-recebimento":
                                        _vm.onAdicionarPagamento,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "v-tab",
                          {
                            attrs: {
                              title: _vm.$t(
                                "__.Components.parent.venda.editSingle_vue_html.pagamentos"
                              ),
                            },
                          },
                          [
                            _c("div", { staticClass: "row" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "col-sm-12",
                                  attrs: { id: "gridPagamento" },
                                },
                                [
                                  _c("recebimento-component", {
                                    ref: "recebimentoComponent",
                                    attrs: {
                                      permiteRemover:
                                        _vm.situacaoVenda != 6 &&
                                        _vm.situacaoVenda != 5,
                                    },
                                    on: {
                                      "remove-pagamento": _vm.onRemovePagamento,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ]
        ),
        _vm._v(" "),
        _c("impressaoComponent", {
          ref: "impressaoComponent",
          on: { "hide-modal": _vm.onHideImpressao },
        }),
        _vm._v(" "),
        _c(
          "shortcutComponent",
          {
            ref: "shortcutComponentCupom",
            attrs: {
              modalClass: "cupomShortCut-modal",
              btOkName: "Validar cupom",
              showBtnOk: !_vm.somenteConsultaVenda && !_vm.vendaFaturada,
              showAddNew: false,
            },
            on: { "selection-item": _vm.onConfirmCupom },
          },
          [
            _vm.loadCupomComponent
              ? _c(
                  "div",
                  { attrs: { slot: "component" }, slot: "component" },
                  [
                    _c("adicionar-cupom-component", {
                      ref: "adicionarCupomComponent",
                      attrs: {
                        indexVenda: _vm.indexVenda,
                        venda: _vm.model,
                        cupomId: _vm.model.cupomId,
                        consulta: _vm.somenteConsultaVenda || _vm.vendaFaturada,
                      },
                      on: { "adicionar-cupom": _vm.onAdicionarCupom },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ]
        ),
        _vm._v(" "),
        _c(
          "shortcutComponent",
          {
            ref: "shortcutComponentEmissao",
            attrs: {
              btOkName: "Emitir Nota",
              extraButtons: _vm.extraButtonActionNfe,
              showAddNew: false,
            },
            on: {
              "selection-item": function ($event) {
                _vm.onEmitirNota().withLoading()
              },
              "extra-button-click": _vm.onExtraButtonActionClickNfe,
            },
          },
          [
            _vm.loadNfeComponent
              ? _c(
                  "div",
                  { attrs: { slot: "component" }, slot: "component" },
                  [
                    _c("nfe-component", {
                      ref: "nfeComponent",
                      attrs: { isEmissaoVenda: true },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ]
        ),
      ],
      _vm._v(" "),
      _c(
        "shortcutComponent",
        {
          ref: "shortcutComponent2",
          attrs: {
            title: _vm.$t(
              "__.Components.parent.venda.editSingle_vue_html.selecione"
            ),
            modalClass: "col-sm-4",
          },
        },
        [
          _c("div", { attrs: { slot: "component" }, slot: "component" }, [
            _c(
              "a",
              {
                staticClass: "iconeCompartilhar bg-green",
                attrs: { title: "WhatsApp" },
                on: { click: _vm.onWhats },
              },
              [
                _c("i", {
                  staticClass: "fab fa-whatsapp",
                  attrs: { "aria-hidden": "true" },
                }),
              ]
            ),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "iconeCompartilhar bg-light-red",
                attrs: { title: "Imprimir" },
                on: { click: _vm.onImprimirOrcamento },
              },
              [
                _c("i", {
                  staticClass: "fa fa-print",
                  attrs: { "aria-hidden": "true" },
                }),
              ]
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _c("vendas-cliente-list-component", {
        ref: "vendasClienteListComponent",
        attrs: { openedFromVenda: true },
      }),
      _vm._v(" "),
      _c(
        "shortcutComponent",
        {
          ref: "shortcutComponentDataContinuo",
          attrs: { modalClass: "col-sm-4" },
          on: {
            "selection-item": _vm.onSaveModalDataVencimento,
            "hide-click": _vm.onCloseModalDataVencimento,
          },
        },
        [
          _c("div", { attrs: { slot: "component" }, slot: "component" }, [
            _vm.model.itens[_vm.itemModelIndex]
              ? _c("form", [
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c(
                        "label",
                        {
                          staticClass: "control-label",
                          attrs: { for: "dataVencimentoContinuo" },
                        },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(
                                _vm.$t(
                                  "__.Components.parent.venda.editSingle_vue_html.dtVencimentoContinuo"
                                )
                              ) +
                              "\n                    "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("date-time-picker-component", {
                        attrs: {
                          id: "dataVencimentoContinuo",
                          name: "dataVencimentoContinuo",
                        },
                        model: {
                          value:
                            _vm.model.itens[_vm.itemModelIndex]
                              .dataVencimentoContinuo,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.model.itens[_vm.itemModelIndex],
                              "dataVencimentoContinuo",
                              $$v
                            )
                          },
                          expression:
                            "model.itens[itemModelIndex].dataVencimentoContinuo",
                        },
                      }),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { attrs: { slot: "component" }, slot: "component" },
        [
          _c("clientesDuplicadosViewComponent", {
            ref: "clientesDuplicadosViewComponent",
            on: {
              onRefresh: _vm.onRefreshClientesDuplicados,
              "hide-click": _vm.onHideShortcutClientesDuplicados,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "shortcutComponent",
        {
          ref: "shortcutHistoricoVenda",
          attrs: { btOkName: "Ok", showAddNew: false, modalClass: "bg-modal" },
        },
        [
          _c(
            "div",
            { attrs: { slot: "component" }, slot: "component" },
            [
              _c("vendaHisticoComponent", {
                ref: "vendaHisticoComponent",
                attrs: { isModal: true },
              }),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c("preFaturamentoComponent", {
        ref: "preFaturamentoComponent",
        on: { onResult: _vm.onResultPreFaturamento },
      }),
      _vm._v(" "),
      _c("solicitarSenhaComponent", {
        ref: "solicitarSenhaComponent",
        attrs: { title: _vm.title },
        on: { onResult: _vm.onResult },
      }),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [
      _c("i", { staticClass: "fa fa-money-bill" }),
      _vm._v(
        "\n                            Total\n                            "
      ),
      _c("br"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }