import nfetch from "../../utils/events/nfetch";
import { getCookie } from "../utils/serviceUtils";

export default class NotaFiscalEletronicaService {
    private controllerName = "NotaFiscalEletronica";
    
    public getXmlData(file: File): Promise<Response> {
        const data = new FormData();
        data.append(file.name, file);
        
        return nfetch(`/${this.controllerName}/GetXmlData`, {
            method: "post",
            credentials: "same-origin",
            headers: {
                "XSRF-TOKEN": getCookie("XSRF-TOKEN")
            },
            body: data
        });
    }
}