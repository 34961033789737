var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("textarea", {
    ref: "textarea",
    attrs: {
      autocomplete: "off",
      type: "text",
      id: _vm.id,
      rows: _vm.nLinhas,
      name: _vm.name,
    },
    domProps: { value: _vm.value },
    on: {
      input: function ($event) {
        _vm.$emit("input", $event.target.value)
      },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }