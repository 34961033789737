import BaseModel from "./base/baseModel";

export default class PosologiaModel extends BaseModel {
    id?: number = null;
    franquiaId?: number = null;
    descricao: string = null;
    dose = 0;
    padrao: boolean;
    ativo = true;
}
